import {Injectable, OnDestroy} from '@angular/core';
import {concatAll, map, tap} from "rxjs/operators";
import {concat, forkJoin, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api/api.service";
import {deepCopy} from "../lib/utils";
import {AppScope} from "./app_scope.service";
import {PlantDataService} from "./plant-data/plant_data.service";
import {FlowchartData} from "../_models/flowchart-data";
import {SearchQueryOptions} from "./api/search-query-options";
import {getRelationWithIdFilter} from "./api/filter_utils";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {RawData} from "../_models/raw-data";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Injectable({
    providedIn: 'root',
})
export class InputDataService implements OnDestroy {
    dateCounterKey: number[];
    times: any[];
    shifts: any[];
    raw_data_map: { [series_id: string]: string };
    series_data: any[];
    times_map: any[];
    series_list_map: {};
    sample_hours: number;
    shift_starts: any[];
    shift_spans: any[];
    shift_names: any[];
    shift_length: any;
    show_shifts: boolean;
    process: any;
    access: any;
    flowSheet: FlowchartData;
    series_list: any;
    series_name_map: any;
    // for storing the changes to be committed
    inserts: any;
    deletes: any;
    updates: any;
    private readonly onDestroy = new Subject<void>();

    constructor(public api: ApiService,
                public plantData: PlantDataService,
                public appScope: AppScope) {
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    /**
     * Sets the initial state for this service.
     * @param process
     * @param access
     * @param flowSheet
     */
    // TODO this could be organised in a better way.
    //  For now wait in the component and pass through to set the state of the service.
    initialize(process, access, flowSheet: FlowchartData) {
        this.dateCounterKey = [];
        this.process = process;
        this.access = access;
        this.flowSheet = flowSheet;
        this.series_list = this.flowSheet.selected_series;

        // TODO should these be cleared when changing the dtp?
        this.updates = {};
        this.inserts = {};
        this.deletes = {};
    }

    getRawData(series, start, end): Observable<any> {
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('series', series.id), {
                name: 'time_stamp',
                op: 'le',
                val: end
            }, {
                name: 'time_stamp',
                op: 'ge',
                val: start
            }];
        return this.api.raw_data.searchMany(options);
    }

    refresh(dtp: IDateTimePeriod) {
        this.shift_length = this.appScope.config_name_map.date_period.value.default_shift_length;

        const sources: Observable<any>[] = [this.refreshShifts(dtp), this.refreshRawData(dtp)];

        this.updates = {};
        this.inserts = {};
        this.deletes = {};

        return forkJoin([concat(sources).pipe(concatAll())]).pipe(map(arr => {
            return arr[0];
        }));
    }

    /**
     * Get the list of existing raw_data entries in a given time range
     * @param series_id
     * @param start
     * @param end
     */
    getRawDataLocal(series_id: string, start: number, end: number): string[] {
        const id_length: number = 36; // length of a GUID string
        return Object.keys(this.raw_data_map).filter(key => {
            const s_id = key.substring(0, id_length);
            const time = Number(key.substring(id_length));
            return s_id === series_id && time > start && time <= end;
        });
    }

    /**
     * Clears the raw data entries for a given series within a specified time range.
     *
     * This method retrieves all raw data points for the specified series between the start and end times.
     * It then sets the value of each data point to zero. If the data point already exists in the raw data map,
     * it updates the entry; otherwise, it inserts a new entry. Any deletions for the data points in this range
     * are also removed from the delete list.
     *
     * @param seriesData - The series data object containing the series ID.
     * @param startTime - The start time of the range to clear data.
     * @param endTime - The end time of the range to clear data.
     */
    clearHourlyRawData(seriesData: any, startTime: number, endTime: number): void {
        const idLength: number = 36; // length of a GUID string
        const dataPointsKeys: string[] = this.getRawDataLocal(seriesData.id, startTime, endTime);

        dataPointsKeys.forEach((key: string): void => {
            const timeStamp: string = new Date(Number(key.substring(idLength))).toISOString();
            const rawData = new RawData(seriesData.id, timeStamp, 0);

            if (this.raw_data_map.hasOwnProperty(key)) {
                this.updates[key] = { id: this.raw_data_map[key], ...rawData };
                delete this.inserts[key];
            } else {
                this.inserts[key] = rawData;
                delete this.updates[key];
            }
            delete this.deletes[key];
        });
    }

    populate_changes(data_row, timestamp, value, dtp: IDateTimePeriod) {
        timestamp = timestamp.substr(0, timestamp.length - '.attributes.value'.length);
        // TODO change to use getTime as key
        const key = data_row.id + timestamp;
        try {

            timestamp = new Date(Number(timestamp)).toISOString();
        } catch (e) {
            console.log('invalid time, ', timestamp);
        }
        // TODO reimplement this using the service
        if (isNaN(parseFloat(value))) {
            // TODO In month view delete does not seem to delete all hours
            const end = new Date(timestamp).getTime();
            const start = new Date(timestamp)['addHours'](-dtp.sample_period.hours).getTime();
            const raw_datas = this.getRawDataLocal(data_row.id, start, end);
            raw_datas.forEach(key => {
                if (this.raw_data_map.hasOwnProperty(key)) {
                    this.deletes[key] = this.raw_data_map[key];
                    delete this.inserts[key];
                    delete this.updates[key];
                    // });
                } else {
                    // deleting an element which never existed
                    delete this.inserts[key];
                    delete this.updates[key];
                    delete this.deletes[key];
                }
            });
        } else {
            if (this.raw_data_map.hasOwnProperty(key)) {
                this.updates[key] = {
                    id: this.raw_data_map[key],
                    type: 'raw_data',
                    attributes: {
                        value: value,
                        time_stamp: timestamp
                    },
                    relationships: {
                        series: {
                            data: {
                                id: data_row.id,
                                type: 'series'
                            }
                        }
                    }
                };
                delete this.inserts[key];
                delete this.deletes[key];
            } else {
                // adding a new element
                this.inserts[key] = {
                    type: 'raw_data',
                    attributes: {
                        value: value,
                        time_stamp: timestamp,
                    },
                    relationships: {
                        series: {
                            data: {
                                id: data_row.id,
                                type: 'series'
                            }
                        }
                    }
                };
                delete this.updates[key];
                delete this.deletes[key];
            }
        }
    }

    saveAll() {
        // TODO add back CUD operations
        const all_saves = [];

        Object.keys(this.inserts).map(key => {
            const obj = this.inserts[key];
            all_saves.push(this.api.raw_data.save(obj).then(item => {
                const timestamp_key = new Date(obj.attributes.time_stamp).getTime();

                this.raw_data_map[obj.attributes.series + timestamp_key] = item.data.id;
                delete this.inserts[key];
            }, item => {
                delete this.inserts[key];
            }));
        });

        Object.keys(this.deletes).map(key => {
            const obj = this.deletes[key];
            all_saves.push(this.api.raw_data.delete(obj).then(item => {
                delete this.raw_data_map[key];
                delete this.deletes[key];
            }, () => {
                delete this.deletes[key];
            }));
        });

        Object.keys(this.updates).map(key => {
            const obj = this.updates[key];
            all_saves.push(this.api.raw_data.patch(obj).then(() => {
                delete this.updates[key];
            }, () => {
                delete this.updates[key];
            }));
        });

        return forkJoin([all_saves]).pipe(map(() => all_saves.length));
    }

    getInputData(dtp: IDateTimePeriod): Observable<any> {
        const $raw_datas: Observable<any>[] = [];

        this.series_list.forEach(series => {
            // Always showing data, regardless if the series is editable
            // if (series.attributes.can_edit == true) {
            const $raw_data: Observable<any> = this.getRawData(series, dtp.start, dtp.end).pipe(tap((response => {
                let data_fetched = response.data;
                data_fetched.map(col => {
                    const timestamp_key = new Date(col.attributes.time_stamp).getTime();
                    this.raw_data_map[series.id + timestamp_key] = col.id;
                    series[timestamp_key] = col;
                });
                this.fillRow(series, dtp);
            })));
            $raw_datas.push($raw_data);

            this.series_data.push(series);
        });

        // TODO for now just emit subject when finished
        const finishedSubject = new Subject();

        let $GetData: Observable<any> = this.api.get_series_data({
            process: this.process.id,
            deepness: 2,
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
            sample_period: dtp.sample_period.wire_sample
        }).pipe(tap(data => {
            const calc_data: {
                [series_name: string]: {
                    [timestamp_iso: string]: number
                }[]
            } = data.data;
            const missing_values: { [series_name: string]: { [isostring: string]: boolean } } = data.missing_values;
            this.series_name_map = {};
            this.series_list.map(series => {
                // TODO why only for sample_hours > 1?
                this.series_name_map[series.attributes.name] = series;
            });

            Object.entries(calc_data).forEach(entry => {
                const series_name = entry[0];
                const timestamp_values = entry[1];
                Object.entries(timestamp_values).forEach(timestampValue => {
                    const iso_string = timestampValue[0];
                    const value = timestampValue[1];
                    const key = new Date(iso_string).getTime();
                    const series = this.series_name_map[series_name];
                    if (series === undefined) {
                        console.log('Ignoring series from GetData', series_name);
                    } else {
                        series[key] = {
                            attributes: {
                                value: value,
                                time_stamp: iso_string
                            },
                            relationships: {
                                series: {
                                    data: {
                                        id: series.id,
                                        type: 'series'
                                    }
                                }
                            }, type: 'raw_data',
                            is_missing: missing_values[series_name][iso_string]
                        };
                    }
                });
            });

            this.series_data.forEach(series => {
                let date_counter = new Date(dtp.start)['addHours'](this.sample_hours);
                let series_name = series.attributes.name;

                while (date_counter <= new Date(dtp.end)) {
                    if (this.series_name_map.hasOwnProperty(series_name)) {
                        this.setCalcData(series, date_counter, calc_data);
                    }
                    if (dtp.sample_period.name === 'month') {
                        date_counter.setMonth(date_counter.getMonth() + 1);
                    } else {
                        date_counter['addHours'](dtp.sample_period.hours);
                    }
                }
            });
            finishedSubject.next('');
            finishedSubject.complete();
            finishedSubject.unsubscribe();
        }));

        const $first_order_raw_data: Observable<any> = forkJoin($raw_datas).pipe();

        const sources = [$first_order_raw_data, $GetData];

        return forkJoin([concat(sources).pipe(concatAll())]).pipe(map(arr => {
            return arr[0];
        }));
    }

    /**
     *
     * @param series series object with additional timestamp keys (corresponding to columns in table)
     * @param dtp
     */
    fillRow(series, dtp: IDateTimePeriod) {
        const date_counter = (new Date(dtp.start))['addHours'](this.sample_hours);
        while (date_counter <= new Date(dtp.end)) {
            if (!series.hasOwnProperty(date_counter.getTime()) || !series[date_counter.getTime()]) {
                series[date_counter.getTime()] = {
                    attributes: {
                        value: null,
                        time_stamp: date_counter.toISOString()
                    },
                    relationships: {
                        series: {
                            data: {
                                id: series.id,
                                type: 'series'
                            }
                        }
                    },
                    type: 'raw_data',
                };

            } else if (!series[date_counter.getTime()].hasOwnProperty('type')) {
                series[date_counter.getTime()] = {
                    attributes: {
                        value: null,
                        series: series.id,
                        time_stamp: date_counter.toISOString()
                    },
                    relationships: {
                        series: {
                            data: {
                                id: series.id,
                                type: 'series'
                            }
                        }
                    },
                    type: 'raw_data'
                };
            }
            if (dtp.sample_period.name === 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1);
            } else {
                date_counter['addHours'](dtp.sample_period.hours);
            }
        }
        return series;
    }

    setCalcData(series, date_counter, calc_data) {
        let timestamp = date_counter.toISOString();
        let timestamp_key = date_counter.getTime();
        if (calc_data[series.attributes.name][timestamp_key] !== null
            && calc_data[series.attributes.name][timestamp_key] !== undefined &&
            series[timestamp_key] !== calc_data[series.attributes.name][timestamp_key]) {
            series[timestamp_key] = {
                attributes: {
                    value: calc_data[series.attributes.name][timestamp_key],
                    time_stamp: timestamp
                },
                relationships: {
                    series: {
                        data: {
                            id: series.id,
                            type: 'series'
                        }
                    }
                },
                type: 'raw_data', source: 'get_data',
            };
        }
    }

    private refreshShifts(dtp: IDateTimePeriod): Observable<any> {
        this.times = [];
        this.shifts = [];
        const options = new SearchQueryOptions();
        options.sort = '-start';
        options.filters = [{
            and: [{
                name: 'end',
                op: 'gt',
                val: dtp.start
            }, {
                name: 'start',
                op: 'lt',
                val: dtp.end
            }]
        }];
        return this.api.shift.searchMany(options).pipe(tap(response => {
            this.shifts = response.data;
            if (!this.shifts) {
            }
            if (this.shifts.length > 0) { // 1,2,4,(6),(8),(12)
                this.shifts = this.shifts.sort((a, b) => {
                    return new Date(a.attributes.start).valueOf() - new Date(b.attributes.start).valueOf();
                });
            }
        }));
    }

    private refreshRawData(dtp: IDateTimePeriod) {
        const permissions = this.access.permissions;

        this.series_data = [];
        this.series_list_map = {};
        this.raw_data_map = {};
        this.times = [];
        this.times_map = [];
        this.sample_hours = dtp.sample_period.hours;

        this.shift_starts = [];
        this.shift_spans = [];
        this.shift_names = [];

        let date_counter = deepCopy(dtp.start);
        if (dtp.sample_period.name === 'month') {
            date_counter.setMonth(date_counter.getMonth() + 1);
        } else {
            date_counter['addHours'](this.sample_hours);
        }
        let span_counter = 0;
        let period_start;
        let period_end;
        let shift_idx = 0;

        let shift_headers = () => {
            if (this.shifts[shift_idx] !== undefined) {
                let shift_end = new Date(this.shifts[shift_idx].attributes.end);
                let shift_start = new Date(this.shifts[shift_idx].attributes.start)['addHours'](dtp.sample_period.hours);

                if (shift_start < deepCopy(new Date(dtp.start))['addHours'](dtp.sample_period.hours)) {
                    shift_start = (new Date(dtp.start))['addHours'](dtp.sample_period.hours);
                }
                if (shift_end > new Date(dtp.end)) {
                    shift_end = new Date(dtp.end);
                }
                if (shift_end <= date_counter) {
                    let shift_span = (shift_end.getTime() - shift_start.getTime()) / 3600000 / dtp.sample_period.hours;
                    this.shift_spans[new Date(shift_start).toISOString()] = shift_span + 1;
                    this.shift_starts.push(new Date(shift_start).toISOString());
                    this.shift_names[new Date(shift_start).toISOString()] = this.shifts[shift_idx].attributes.description;
                    shift_idx++;
                }
            }
        };

        while (date_counter <= dtp.end) {
            if (dtp.sample_period.name === 'month') {
                date_counter.setMonth(date_counter.getMonth() - 1);
            } else {
                date_counter['addHours'](this.sample_hours * -1);
            }
            period_start = date_counter.toISOString();
            if (dtp.sample_period.name === 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1);
            } else {
                date_counter['addHours'](this.sample_hours);
            }
            period_end = date_counter.toISOString();
            this.times.push({
                period_start: period_start,
                period_end: period_end
            });

            if (dtp.sample_period.name === 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1);
            } else {
                date_counter['addHours'](this.sample_hours);
            }
            if (this.shifts.length > 0 && Number.isInteger(this.shift_length / dtp.sample_period.hours)) { // 1,2,4,(6),(8),(12)
                shift_headers();
                this.show_shifts = true;
            } else {
                this.show_shifts = false;
            }
            span_counter++;
        }

        this.times_map = this.times.map(time => time.period_end);

        if (this.appScope.current_user.restricted_access !== true || permissions.view_process_data) {
            return this.getInputData(dtp);
        } else {
            return of();
        }
    }
}
