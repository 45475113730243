import {Component} from "@angular/core";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {HeaderDataService} from "../../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {BaseComponent} from "../../shared/base.component";
import {takeUntil, first, tap, switchMap} from "rxjs/operators";
import {FlowchartData} from "../../_models/flowchart-data";
import {ModelID} from "../../_typing/generic-types";

@Component({
    selector: 'series-component-sheet-focus-view',
    template: '<series-component-sheet-view *ngIf="sheet_ready"  ' +
        '[components]="components" ' +
        '[seriesComponents]="series_components" ' +
        '[process]="process"></series-component-sheet-view>',
    standalone: false
})
export class SeriesComponentSheetFocusViewComponent extends BaseComponent {
    process: any;
    components: any[];
    series_components: any;
    sheet_ready: boolean;
    processID: ModelID;

    constructor(private plantData: PlantDataService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.params.pipe(switchMap(params => {
                this.sheet_ready = false;
                this.processID = params.processID;
                return this.refresh();
            }),
            takeUntil(this.onDestroy)).subscribe();
    }

    refresh(): Observable<FlowchartData> {
        this.sheet_ready = false;
        return this.plantData.getFlowchartDataForProcess(this.processID).pipe(
            first(),
            takeUntil(this.onDestroy),
            tap(flowchart_data => {
                this.setupEditSheet(flowchart_data);
            }));
    }

    setupEditSheet(flowSheet: FlowchartData) {
        this.process = flowSheet.process_focus;
        this.components = flowSheet.components;
        this.series_components = flowSheet.series_components;
        this.plantData.setActiveProcess(this.process);
        this.buildHeader();
        this.sheet_ready = true;
    }

    buildHeader() {
        this.headerData.title = 'Report Configuration';
        this.headerData.buttons = [];
    }
}
