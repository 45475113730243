<style>
    .selected-pink {
        background: #efefef !important;
    }
</style>
<div *ngIf="changes_flag==true" class="calc-warning">Please remember to update calculations.</div>
<ng-container *ngIf="appScope.isNotMobile">
  <table mat-table id="shift_table" class="shift_table table-bordered" cellspacing="0" *ngIf="sheet_ready && (!restricted_access ||
  (restricted_access===true && permissions.view_process_data))" [dataSource]="dataSource"
         [ngClass]="{'editing-layout':editingLayout}">

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'editing-layout':editingLayout}" style="width:30px;" sticky>
        <div></div>
      </th>
      <!--<td class="th" [attr.rowspan]="report_groups[series.attributes.report_group].length"-->
      <ng-container *matCellDef="let series;">
        <td mat-cell *ngIf="report_groups[series.attributes.report_group].indexOf(series)===0" style="width:30px;"
            [rowSpan]="report_groups[series.attributes.report_group].length"
            [ngClass]="{'alt': report_groups_names.indexOf(series.attributes.report_group) % 2 == 0}">
          <div>
            <div
                [ngStyle]="changeWidth(report_groups[series.attributes.report_group].length)">{{series.attributes.report_group}}</div>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th *matHeaderCellDef mat-header-cell sticky [ngClass]="{'editing-layout':editingLayout}">
      </th>
      <td *matCellDef="let series" mat-cell>
        <div class="row center">
          <i class="edit-inline hide-xs" (click)="editSeries(series)"></i>
        </div>
      </td>
    </ng-container>

    <!--Name-->
    <ng-container matColumnDef="name" *ngIf="row_headers.includes('name')">
      <th *matHeaderCellDef mat-header-cell sticky
          [ngClass]="{'is-sticky-left':isSticky.name, 'editing-layout':editingLayout}"
          [style.width]="format_dict['name'].width + 'px'"
          [resizable]="format_dict['name']" (resizable)="format_dict['name'].width=$event">
        <div>Series</div>
      </th>
      <td *matCellDef="let series"
          [ngClass]="{'bold': hovering_events.includes(series.id),'is-sticky-left':isSticky.name,}"
          class="align-left" mat-cell>
        <div class="row center"
             [style.width]="format_dict['name'].width + 'px'">
          <span style="white-space: nowrap;padding:2px 4px 2px 2px;"
                [dblClickCopy]="series.attributes.name"
                [matTooltip]="series.attributes | nameOrDescription"
                [matTooltipShowDelay]="tooltipShowDelay">{{series.attributes.name}}</span>
        </div>
      </td>
    </ng-container>

    <!--Alias-->
    <ng-container *ngIf="row_headers.includes('alias')" matColumnDef="alias">
      <th *matHeaderCellDef [ngClass]="{'is-sticky-left':isSticky.alias, 'editing-layout':editingLayout}"
          mat-header-cell
          [style.width]="format_dict['alias'].width + 'px'"
          [resizable]="format_dict['alias']" (resizable)="format_dict['alias'].width=$event">
        <div>Alias</div>
      </th>
      <td mat-cell *matCellDef="let series" class="align-left" (focus)="setComment($event, series, null)"
          [ngClass]="{'bold': hovering_events.includes(series.id),'is-sticky-left':isSticky.alias}">
        <div [style.width]="format_dict['alias'].width + 'px'"
             [matTooltip]="series.attributes | nameOrDescription"
             [dblClickCopy]="series.attributes.name"
             [matTooltipShowDelay]="tooltipShowDelay">{{series.attributes.alias}}</div>
      </td>
    </ng-container>

    <!--Description-->
    <ng-container *ngIf="row_headers.includes('description')" matColumnDef="description">
      <th *matHeaderCellDef [ngClass]="{'is-sticky-left':isSticky.description, 'editing-layout':editingLayout}"
          mat-header-cell
          [style.width]="format_dict['description'].width + 'px'" [matTooltip]=""
          [resizable]="format_dict['description']" (resizable)="format_dict['description'].width=$event">
        <div>Description</div>
      </th>
      <td mat-cell *matCellDef="let series" class="align-left" (focus)="setComment($event, series, null)"
          [ngClass]="{'bold': hovering_events.includes(series.id),'is-sticky-left':isSticky.description}"
          [style.width]="format_dict['description'].width + 'px'">
        <div [matTooltip]="series.attributes | nameOrDescription"
             [dblClickCopy]="series.attributes.name"
             [matTooltipShowDelay]="tooltipShowDelay">{{series.attributes.description}}</div>
      </td>
    </ng-container>

    <!-- Comments Column -->
    <ng-container matColumnDef="comment">`
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'editing-layout':editingLayout}"
          [style.width]="format_dict['comment'].width + 'px'"
          [resizable]="format_dict['comment']" (resizable)="format_dict['comment'].width=$event">
        <div><i class="icon-comment-invert" style="padding-top:4px;"></i></div>
      </th>
      <td mat-cell *matCellDef="let series" class="align-center" (focus)="setComment($event, series, null)">
        <div *ngIf="series_events[series.id]" (mouseenter)="commentIconHover(series_events[series.id])"
             (mouseleave)="cellMouseLeave()"
             style="padding:2px 4px 2px 2px;"><i class="icon-inline-comment" (click)="toggleComments(true)"></i>
        </div>
        <div *ngIf="!series_events[series.id]" style="padding:2px 4px 2px 2px;">
          <i (click)="toggleComments(true)" class="icon-comment-invert" style="width:28px;"></i>
        </div>
      </td>
    </ng-container>

    <!-- Extra columns (Value/Aggregate, Unit, Status) -->
    <ng-container *ngFor="let column of columns; trackBy: trackByFunction" matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'editing-layout':editingLayout}"
          [style.width]="format_dict[column].width + 'px'"
          [resizable]="format_dict[column]" (resizable)="format_dict[column].width=$event">
        <div *ngIf="column === 'Value'">Aggregate</div>
        <div *ngIf="column !== 'Value'">{{column}}</div>
      </th>
      <td mat-cell *matCellDef="let series" (focus)="setComment($event, series, null)" tabindex="0">
        <!--[ngClass]="{'warning': series.status==2, 'error': series.status==3}"-->
        <ng-container *ngIf="column === 'Value'">
          <!--<div *ngIf="!(editAggregation && !series.saving && saveAllowed(series, dtp.start.toISOString()))"-->
          <div *ngIf="!editAggregation || series.saving || !saveAllowed(series, dtp.start.toISOString())"
               [ngClass]="{'warning': series.status==2, 'error': series.status==3}">
            <div class="align-right">{{series.total | significantNumbers:significant_numbers}}</div>
          </div>
          <input *ngIf="editAggregation && !series.saving && saveAllowed(series, dtp.start.toISOString())"
                 type="number" step="any"
                 (mousewheel)="$event.preventDefault();"
                 (change)="aggregationChange(series,series.total);"
                 [(ngModel)]="series.total" style="border:2px; width: 4em;"
                 matTooltip="Aggregation: {{series.attributes.aggregation}}, Sample period:{{series.attributes.sample_period}}">
        </ng-container>
        <ng-container *ngIf="column === 'Status'">
        <span class="fa flag flag--status fa-warning"
              [ngClass]="{'warning-none': series.status==1, 'warning' : series.status==2, 'error': series.status==3}">
        </span>
        </ng-container>
        <ng-container *ngIf="column === 'Units'">
          <div>{{series.attributes.engineering_unit_name}}</div>
        </ng-container>
        <ng-container *ngIf="column !== 'Value' && column !=='Status'">
          <div class="align-right">{{series[column] | significantNumbers:significant_numbers}}</div>
        </ng-container>
      </td>
    </ng-container>

    <!-- Timestamps row -->
    <ng-container *ngFor="let time of times; trackBy: trackByFunction; let c=index;"
                  [matColumnDef]="timeColumnsCache[time.period_end]">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'editing-layout':editingLayout}"
          [style.width]="format_dict['time'].width + 'px'"
          [style.min-width]="c===0?'':format_dict['time'].width + 'px'"
          [resizable]="c===0?format_dict['time']:{allow_resize:false}" (resizable)="format_dict['time'].width=$event"
          [title]="(time.period_start | stringDate) + ' - ' + ( time.period_end | stringDate)">
        <div style="margin-left:4px;">{{(time.period_start | stringDate : {date_separator: '-'})}} -</div>
        <div>{{(timeColumnsCache[time.period_end]) }}</div>
      </th>
      <td (click)="setFocus($event)" (focus)="setComment($event, series, time);"
          *matCellDef="let series; let r=index;" [id]="'td_' + r + '_' + c + '_' + tileId"
          class="align-right values" mat-cell tabindex="0">
        <input *ngIf="shouldShowInput(series,time)"
               (focus)="setComment($event, series, time)"
               [id]="'inputtd_' + r + '_' + c + '_' + tileId"
               step="any" tabindex="1"
               type="number"
               (mousewheel)="$event.preventDefault();"
               (change)="dataChange(series, time, $event)"
               [value]="series[time.period_end].attributes.value"
               [ngClass]="{'warning': series[time.period_end] && series[time.period_end].warning,
                'error': series[time.period_end] && series[time.period_end].error}"
               style="border:0; width: 100%; "/>
        <div style="display: none;">{{series.attributes.value | formatNumber:null:significant_numbers:null:!significant_numbers:series}}</div>

        <div
            *ngIf="series[time.period_end] && (series[time.period_end].saving || !saveAllowed(series, time.period_end) || editAggregation)">
        <span *ngIf="shouldShowFirst(series, time)"
              [ngClass]=" missing_values[series.attributes.name] && missing_values[series.attributes.name][time.period_end] ?'missing-values' :''">
          {{calc_data[series.attributes.name][time.period_end] | formatNumber:null:significant_numbers:null:!significant_numbers:series}}</span>
          <span
              *ngIf="series.attributes.can_edit && !dtpCoarserGranularity(series) && !series.saving && series[time.period_end]">
          {{series[time.period_end].attributes.value | formatNumber:null:significant_numbers:null:!significant_numbers:series}}</span>
          <span
              *ngIf="series.attributes.can_edit && dtpCoarserGranularity(series) && calc_data[series.attributes.name]">
          {{calc_data[series.attributes.name][time.period_end] | formatNumber:null:significant_numbers:null:!significant_numbers:series}}</span>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="row_headers;" mat-header-row></tr>
    <tr (focus)="setComment($event, row, null)" *matRowDef="let row; columns: row_headers" mat-row
        tabindex="999"></tr>
  </table>
</ng-container>

<!-- Extra columns -->
<ng-container *ngIf="!appScope.isNotMobile" class="mobile-log">
  <div *ngIf="restricted_access===true && !permissions.view_process_data">You do not have permission to
    view this data.
  </div>
  <div
      *ngIf="sheet_ready && times.length > 0 && !restricted_access || (restricted_access===true && permissions.view_process_data)"
      style="margin-bottom:30px;">

    <div *ngFor="let series of series_data" class="row"
         style="align-items:center; margin-top:10px; border-bottom: 1px solid #f1f1f1;">
      <label style="width: 54%;text-align:right;margin-right:10px;margin-left:10px;">
        <span>{{series.attributes | nameOrDescription}}</span>
      </label><br/>
      <div tabindex="0" (focus)="setComment($event, series, null)">
        <input type="number" step="any" [(ngModel)]="series[dtp.end.toISOString()].attributes.value"
               [ngModelOptions]="{updateOn: 'blur'}"
               (change)="dataChange(series, {period_start: dtp.start.toISOString(),period_end: dtp.end.toISOString()},$event);"
               [ngClass]="{warning: series[dtp.end.toISOString()] && series[dtp.end.toISOString()].warning,
               error: series[dtp.end.toISOString()] && series[dtp.end.toISOString()].error}"
               style="text-align:right; margin-bottom:10px; border:1px solid #ccc; width: 8em; height: 3em; padding:4px;cursor:pointer;"
               [ngStyle]="{'background-color': (series[dtp.end.toISOString()] && series[dtp.end.toISOString()].saving || !saveAllowed(series, dtp.end))? '#cccccc' : 'white'}"
               [disabled]="(series[dtp.end.toISOString()] && series[dtp.end.toISOString()].saving) || !saveAllowed(series, dtp.end)"/>

      </div>
      <div *ngIf="series_events[series.id]"
           style="padding:2px 4px 2px 2px;"><i class="icon-inline-comment" (click)="toggleComments(true)"></i>
      </div>
    </div>
  </div>
</ng-container>
