import * as Handsontable from "handsontable";
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HeaderDataService} from "../../services/header_data.service";
import {HotInstance} from "../../services/hot-instance";
import {tap} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";

@Component({
    selector: 'shift-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class ShiftSheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    title: string;
    schema: any;
    search: string;
    shifts: any[];
    users: any[];

    process: any;
    column_list: any[];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        this.hot = new HotInstance();
        this.title = 'Shift';

        const observables = [];
        // Filter for resource types
        observables.push(this.api.shift.searchMany().pipe(tap(response => this.shifts = response.data)));
        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));

        forkJoin([observables]).subscribe(this.createTable.bind(ctrl));

        this.buildHeader();
    }

    createTable() {
        this.schema = {
            id: null,
            type: 'shift',
            attributes: {
                start: null,
                end: null,
                created_on: null,
                changed_on: null,
                name: null,
                description: null
            },
            relationships: {
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        if (this.process !== undefined) {
            this.schema.relationships.process.id = this.process.id;
        }

        this.column_list = [{
            data: 'attributes.name',
            title: 'Name'
        }, {
            data: 'attributes.description',
            title: 'Description'
        }, {
            data: 'attributes.start',
            title: 'Start'
        }, {
            data: 'attributes.end',
            title: 'End'
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        this.hot = this.handsontableGenerator.generateTable(this.api.shift, this.schema, this.column_list);
        this.hot.ready = true;
        this.hot.settings.data = this.shifts;
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    save() {
        let results = this.hot.save();
        this.shifts = results.data;
    }

    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = 'Shifts';
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}},
        ];
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
