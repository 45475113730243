<ng-container *ngIf="item">
  <mat-list-item
      [ngClass]="{'parent':item.top===true}">
    <div class='pull-left'>
      <i (click)="getChildren(item);" [ngClass]="{'chevron-up': item.expanded , 'chevron-down' : !item.expanded }"
         class="chevron-down" role="button"></i>
      <span (click)="getChildren(item);">{{item.name}}</span>
      <div [matMenuTriggerFor]="menu"><b class="plant-menu-icon"></b></div>
    </div>

    <mat-menu #menu="matMenu" class="tree-node-menu">
      <a (click)="entry.action()"
         *ngFor="let entry of menu_entries"
         [routerLink]="entry.link"
         [textContent]="entry.name"
         mat-menu-item></a>
      <!--TODO disabling this until the component has been migrated-->
      <!--<button mat-menu-item ><a routerLink="{{'/view/calculation_graph_view/' + item.id }}" >-->
      <!--Calculation Tree-->
      <!--</a></button>-->
    </mat-menu>
    <div class="stage" *ngIf="loadingChildren===item.id">
      <div class="dot-flashing"></div>
    </div>
  </mat-list-item>

  <ng-container *ngIf="item.expanded">
    <mat-list [hidden]="!item.expanded" flex>
      <ng-container *ngFor="let childItem of item.children">
        <treenode [item]="appScope.processTreeDataDict[childItem]" class="process-child-node"></treenode>
      </ng-container>
    </mat-list>
  </ng-container>
</ng-container>
