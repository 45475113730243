import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {HeaderDataService} from '../../services/header_data.service';
import {ApiService} from '../../services/api/api.service';
import {COMPONENT_EVENTS_CONFIG} from "../../forms/component-events-table-form/component-events-table-form.component";
import {ConstantProperty} from "../../_models/constant-property";

import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {Tile as TileModel} from "../../_models/tile";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {getPropertyColumns} from '../custom-events-table/utils';

import {IDateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'component-events-table-view',
    templateUrl: './component-events-table-view.component.html',
    styleUrls: ['./component-events-table-view.component.less'],
    standalone: false
})

export class ComponentEventsTableViewComponent implements OnInit, OnDestroy {
    config: COMPONENT_EVENTS_CONFIG;
    public dtp: IDateTimePeriod;
    public tile: TileModel;

    properties_filter: any[];
    date_properties_filter: any[];
    readonly default_cols: EventConfigColumn[] = [
        this.getCol('name'),
        this.getCol('start_time')
    ];
    private readonly onDestroy = new Subject<void>();
    public getPropertyColumns = getPropertyColumns;

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private headerData: HeaderDataService,
                private api: ApiService,
                private propertyData: ConstantPropertyDataService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.config = {
            selected_component_type: undefined,
            selected_component_relationship_types: [],
            selected_cols: {
                component_type: this.default_cols,
                event: [],
                component: {}
            },
            printout: true,
            search: true,
            constant_property_time: false,
            enable_export_component: [],
            page_size: 20,
            enable_row_selection: false
        };
        ctrl.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dateInst.dtp = this.dateTimePeriodService.setDefault(this.dateInst.dtp);
            this.dtp = this.dateInst.dtp;
            this.headerData.title = 'Component Events';
            this.buildHeader();

        });
    }

    getCol(id: string): EventConfigColumn {
        return {id: id, title: id, type: 'attribute', disabled: true, format: null};
    }

    getPropertyCol(cp: ConstantProperty): EventConfigColumn {
        return {id: cp.id, title: cp.attributes?.name, type: 'constant_property', disabled: true, format: null};
    }

    getTile(): TileModel {
        let tile = new TileModel();
        tile.attributes.content = 'component-events-table';
        tile.attributes.parameters = this.config;
        tile.attributes.title = this.config.selected_component_type.attributes.name + ' components';
        return tile;
    }

    createPropertyFilters(event) {
        this.tile = null;
        this.config.selected_component_type = event;
        this.config.selected_cols.component_type = this.default_cols;
        this.properties_filter = this.propertyData.generatePropertiesByRelationshipIdsFilter(
            'component_types', [event.id])
        this.date_properties_filter = this.propertyData.generatePropertiesByRelationshipIdsFilter(
            'component_types', [event.id], null, null, ['datetime'])
    }

    propertySelectionChanged(event: ConstantProperty[]) {
        const prop_cols = [];
        event.forEach(cp => {
            prop_cols.push(this.getPropertyCol(cp));
        })
        this.config.selected_cols.component_type = this.default_cols.concat(prop_cols);
    }

    search() {
        this.tile = null;
        setTimeout(() => {
            const tile = this.getTile();
            tile.attributes.parameters = this.config;
            this.tile = tile;
        })
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Component Events Table';
        ctrl.headerData.show_dtp = true;
    }

}
