import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldMap, isRule, isRuleSet, QueryBuilderConfigType, Rule, RuleSet} from "../../../_typing/query-builder";
import {deepCopy} from "../../../lib/utils";
import {QueryBuilderFormService} from "../../../services/query-builder-form.service";
import {take} from "rxjs/operators";

@Component({
    selector: 'rule-set',
    templateUrl: './rule-set.component.html',
    styleUrls: ['./rule-set.component.less', '../../../forms/query-builder-form/query-builder-form.component.less'],
    standalone: false
})
export class RuleSetComponent implements OnInit {
    @Input() ruleSet: RuleSet;
    @Input() readonly fields: FieldMap;
    defaultRule: Rule;
    @Input() config: QueryBuilderConfigType;
    @Input() allowRemoveRuleSet: boolean = true;
    @Input() isQueryBuilder: boolean = true;
    @Output() ruleSetChange: EventEmitter<RuleSet> = new EventEmitter<RuleSet>();
    @Output() removeRuleSetRequest = new EventEmitter();
    isTopLevel:boolean = false;
    isRule = isRule;
    isRuleSet = isRuleSet;

    constructor(private queryBuilderFormService: QueryBuilderFormService) {
        this.queryBuilderFormService.defaultRuleChanged.pipe(take(1))
            .subscribe(rule => {
                this.defaultRule = rule;
            });
    }

    ngOnInit() {
        this.isTopLevel = !this.allowRemoveRuleSet;
    }

    addRuleSet(): void {
        const ruleSet = new RuleSet();
        ruleSet.rules.push(deepCopy(this.defaultRule));
        this.ruleSet.rules.push(new RuleSet);
    }

    addRule(): void {
        this.ruleSet.rules.push(deepCopy(this.defaultRule));
        this.ruleSetChanged();
    }

    removeRuleSet(i: number): void {
        this.ruleSet.rules.splice(i, 1);
        this.ruleSetChanged();
    }

    removeRule(i: number): void {
        this.ruleSet.rules.splice(i, 1);
        this.ruleSetChanged();
    }

    ruleSetChanged($event?: RuleSet, i?: number): void {
        if ($event) {
            this.ruleSet.rules[i] = $event;
        }
        this.ruleSetChange.next(this.ruleSet);
    }

    requestRemoveRuleSet(): void {
        this.removeRuleSetRequest.emit();
    }
}
