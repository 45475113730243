import {Component, Input, ViewEncapsulation} from '@angular/core';
import {SearchQueryOptions} from "../../../services/api/search-query-options";
import {catchError, concatMap, finalize, mergeMap, takeUntil, tap} from "rxjs/operators";
import {Process} from "../../../_models/process";
import {Feature} from "../../../_models/feature";
import {ProcessAccess} from "../../../_models/process-access";
import {
    getManyRelationWithIdsFilter,
    getRelationWithIdFilter,
    getRelationWithManyIdsFilter
} from "../../../services/api/filter_utils";
import {forkJoin, from, Observable, Subject} from "rxjs";
import {ApiService} from '../../../services/api/api.service';
import {ProcessAccessFeatureConfig} from "../process-access-feature-config";
import {SaveableBase, SaveService} from '../../../services/save/save.service';
import {User} from "../../../_models/users";
import {NotificationService} from "../../../services/notification.service";
import {ModelID} from "../../../_typing/generic-types";
import {UserService} from "../../../services/user.service";
import {deepCopy} from "../../../lib/utils";

@Component({
    selector: 'user-form-process-access-features',
    templateUrl: './user-form-process-access-features.component.html',
    styleUrls: ['./user-form-process-access-features.component.scss'],
    standalone: false,
    encapsulation: ViewEncapsulation.None
})
export class UserFormProcessAccessFeaturesComponent extends SaveableBase {
    private _user: User;
    @Input() set user(user: User) {
        this._user = user;
        this.getUserAccounts();
    };

    get user(): User {
        return this._user;
    }

    /**
     * Features with process_access_feature set to True and associated relationships to this user.
     */
    process_access_features: ProcessAccessFeatureConfig[] = [];
    processes: Process[];
    isSaving = false;
    accountIds: ModelID[];
    allowedAccountIds: ModelID[];

    constructor(private api: ApiService,
                private notification: NotificationService,
                private saveService: SaveService,
                private userService: UserService) {
        super()
    }

    ngOnInit(): void {
        this.refreshProcessAccessFeatures()
        this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe(() => {
            if (this.isSaving) return;
            this.publishStatus({key: 'user-form-process-access-features', value: 'saving', errors: []});
            this.save();
        });
    }

    getUserAccounts() {
        this.accountIds = this.userService.getUserAccountIds(this.user);
        this.allowedAccountIds = deepCopy(this.accountIds);
    }

    save() {
        const $change_sets = [];
        let errors = [];
        const $all_changes: Observable<any>[] = [];
        this.process_access_features.forEach(paf => {
            paf.changes = new Subject<Observable<any>[]>();
            $change_sets.push(paf.changes.pipe(tap(changes => {
                $all_changes.push(...changes);
            })));
        });

        if ($change_sets.length > 0) {
            this.isSaving = true;
        }
        forkJoin($change_sets).pipe(concatMap(body => {
            const concurrentRequests = 20;
            if ($all_changes.length > 200) {
                this.notification.openSuccess(`Making ${$all_changes.length} updates. This could take a while.`, 10000);
            }
            return from($all_changes).pipe(
                mergeMap(obj => obj, concurrentRequests),
                catchError(e => {
                    errors.push(e);
                    return e;
                }),
                finalize(() => {
                    this.isSaving = false;
                    console.log('Sequence complete');
                    //this.snackbar.open('User successfully updated', null, {duration: 5000});
                    if (errors.length > 0) {
                        errors = errors.map(e => e.error?.errors?.[0]?.detail || e);
                        this.notification.openError('There was a problem saving some process access features.', 5000);
                        errors.forEach(e => console.log('ERROR: UserForm (save) ', e));
                        this.publishStatus({key: 'user-form-process-access-features', value: 'error', errors: errors});
                    } else {
                        this.publishStatus({key: 'user-form-process-access-features', value: 'success', errors: []});
                    }
                }))
        })).subscribe();

    }

    refreshProcessAccessFeatures() {
        const sources = [];
        const componentOptions = new SearchQueryOptions();

        componentOptions.filters = [{
            name: 'base_type',
            op: "eq",
            val: "process"
        }, getRelationWithManyIdsFilter('account', this.accountIds)];

        sources.push(this.api.component.searchMany(componentOptions).pipe(tap((result) => {
            // we're aware that we may cast to process here.
            this.processes = result.data as Process[];
        })));

        let features: Feature[];

        const featureOptions = new SearchQueryOptions();
        featureOptions.filters = [{
            name: 'process_access_feature',
            op: 'eq',
            val: true
        }];
        sources.push(this.api.feature.searchMany(featureOptions).pipe(tap(results => {
            features = results.data;
        })));

        let process_access: ProcessAccess[] = [];

        forkJoin(sources).pipe(concatMap(() => {
                const $obs = [];
                let noAccess: boolean = false;
                features.forEach(f => {
                    const processAccessOptions = new SearchQueryOptions();
                    processAccessOptions.filters = [
                        getRelationWithIdFilter('user', this.user.id),
                        getRelationWithIdFilter('feature', f.id)
                    ];
                    $obs.push(this.api.process_access.searchMany(processAccessOptions).pipe(tap(result => {
                        if (result.data) {
                            process_access = process_access.concat(result.data);
                            (this.process_access_features || []).push(this.setupProcessAccessFeatures(result.data, f, this.processes));
                        } else {
                            noAccess = true;
                        }
                    })));
                })
                if (noAccess) {
                    this.notification.openError('User does not have account access to process access can not be loaded.');
                }
                return forkJoin($obs);
            }),
            takeUntil(this.onDestroy))
            .subscribe();
    }

    getAccountIds($event) {
        this.accountIds = $event?.map(a => a.id);
        if (!this.accountIds?.length) {
            this.notification.openError('Cannot load processes if no accounts are selected.');
        }
    }

    private setupProcessAccessFeatures(process_access: ProcessAccess[], feature: Feature, processes: Process[]): ProcessAccessFeatureConfig {
        const process_access_features_map: { [feature_id: string]: ProcessAccessFeatureConfig } = {};
        let process_access_features: ProcessAccessFeatureConfig;

        const config = new ProcessAccessFeatureConfig();
        config.feature = feature;

        process_access_features = config;
        process_access_features_map[feature.id] = config;

        const invalid_account_process_access: Set<string> = new Set<string>();

        const process_id_map = {};
        processes.forEach(process => {
            process_id_map[process.id] = process;
        });

        process_access.forEach((item) => {
            const feature = item.relationships.feature;
            const process = item.relationships.top_process;

            if (feature && feature.data && feature.data.id &&
                process && process.data && process.data.id &&
                process_id_map[process.data.id] &&
                process_access_features_map[feature.data.id]) {

                process_access_features_map[feature.data.id].process_access.push(item);
            } else {
                if (!process || process.data == null) {
                    invalid_account_process_access.add(item.id);
                }
            }
        });

        process_access_features.all_processes_list = processes;

        if (invalid_account_process_access.size > 0) {
            // This could also be temporary, if the users does not have access to the linked account for a short time.
            console.warn('Ignoring ProcessAccess with invalid linked Process (user might have lost access ' +
                'to these accounts, the account for the Process is different than that of the ProcessAccess) setup with ' +
                'following ids: ', Array.from(invalid_account_process_access));
        }

        return process_access_features;
    }
}
