import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject} from 'rxjs';
import {SessionState} from "../_models/session-state";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {ApiService} from '../services/api/api.service';
import {take} from "rxjs/operators";
import {Event as WireEvent} from '../_models/event';
import {ListResponse} from '../services/api/response-types';
import {EventType} from '../_models/event-type';
import {getManyRelationWithIdsFilter, getRelationWithIdFilter} from "../services/api/filter_utils";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {ConfigStub} from "../_typing/config-stub";
import {ModelID} from "../_typing/generic-types";

export interface BasicQuery {
    name: string;
    op: string;
    val?: string | string[] | null;
}

/**
 * This class is for shared functions relating to data access on the event api
 **/
@Injectable({
    providedIn: 'root'
})
export class EventDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService) {
    }

    /**
     * getEvents: returns all events (base type event) for the given params
     */
    getEvents(start?: Date, end?: Date, series_list?, components?, session_state_list?: SessionState[]): Observable<ListResponse<WireEvent>> {
        return this.getEventsByBaseType('event', start, end, series_list, components, session_state_list);
    }

    /**
     * generateEventDateFilter: the standard date filter for getting events used as part of many other filters
     */
    generateEventDateFilter(start, end): any {
        return {
            or: [{and: [{name: 'start', op: 'ge', val: start}, {name: 'start', op: 'le', val: end}]},
                {and: [{name: 'end', op: 'ge', val: start}, {name: 'end', op: 'le', val: end}]},
                {and: [{name: 'start', op: 'lt', val: start}, {name: 'end', op: 'gt', val: end}]}
            ]
        };
    }

    /**
     * generateWithinRangeEventDateFilter: makes sure that the full event falls within range or spans across entire range
     */
    generateWithinRangeEventDateFilter(start, end): any {
        return {
            or: [{
                and: [{and: [{name: 'start', op: 'ge', val: start}, {name: 'start', op: 'lt', val: end}]},
                    {and: [{name: 'end', op: 'gt', val: start}, {name: 'end', op: 'le', val: end}]}
                ]
            },
                {and: [{name: 'start', op: 'lt', val: start}, {name: 'end', op: 'gt', val: end}]}
            ]
        };
    }

    generateCreatedOnDateFilter(start, end): any {
        return {
            and: [
                {op: 'ge', name: 'created_on', val: start},
                {op: 'lt', name: 'created_on', val: end}
            ]
        }
    }

    /**
     * generateEventConstantDateFilter: filter for returning events that have an event_constant with a given
     * constant_property_id and a (date) value that falls between start and end
     */
    generateEventConstantDateFilter(constant_property_id, start, end) {
        return {
            and: [
                {
                    name: 'event_constants', op: 'any', val: {
                        and: [
                            getRelationWithIdFilter('constant_property', constant_property_id),
                            {name: 'value', op: 'ne', val: 'None'},
                            {name: 'value', op: 'ge', val: start},
                            {name: 'value', op: 'le', val: end}
                        ]
                    }
                }
            ]
        };
    }

    /**
     * generateEventByTypeFilter: filter for getting a list of <events> for a given list of event_type_ids
     */
    generateEventByTypesFilter(event_type_ids: string[]): any {
        return {
            name: 'event_type',
            op: 'has',
            val: {name: 'id', op: 'in', val: event_type_ids}
        };
    }

    generateEventsForComponentFilter(dtp?: IDateTimePeriod, component?: any, constant_properties_ids = []): any[] {
        const options = new SearchQueryOptions();

        options.filters = [];
        if (dtp) {
            options.filters = [this.generateEventDateFilter(dtp.start, dtp.end)];
        }
        if (component) {
            options.filters.push({name: 'components', op: 'any', val: {name: 'id', op: 'in', val: [component.id]}});
        }

        return options.filters;
    }

    //FIXME temp until flask is merged
    getRelationWithAttributeFilter(relation_name: string, relation_value: string | string[] | BasicQuery, attribute: string, operator = 'eq') {
        return {
            name: relation_name,
            op: 'has',
            val: {name: attribute, op: operator, val: relation_value}
        };
    }

    getSingleWithAttributeFilter(attribute: string, value: string | string[], operator = 'eq') {
        return {name: attribute, op: operator, val: value};
    }

    getEventConstantCollationQuery(event_type_id: string, constant_property_id: string, time_property_id: string, page_size: number,
                                   page: number, dtp: IDateTimePeriod): SearchQueryOptions {
        const options = new SearchQueryOptions(page_size, page, '-start');
        const date_filter = time_property_id ? this.generateEventConstantDateFilter(time_property_id, dtp.start, dtp.end) :
            this.generateEventDateFilter(dtp.start, dtp.end);
        const internal_val = this.getSingleWithAttributeFilter('id', constant_property_id);
        options.filters = [{
            and: [
                date_filter,
                this.generateEventByTypesFilter([event_type_id]),
                this.getRelationWithAttributeFilter('event_type', internal_val, 'constant_properties', 'any')]
        }];
        return options;
    }

    /**
     * getEventsFilter: returns the searchQueryOptions filters to fetch events by given params
     */
    generateEventsFilter(start: Date, end: Date, series_list?, components?, session_state_list?: ConfigStub<SessionState>[], dateFilters?: any[]): any[] {
        const options = new SearchQueryOptions();

        options.filters = dateFilters ? [dateFilters] : [this.generateEventDateFilter(start, end)];

        let query: { or: any[] } = {or: []};

        if (series_list?.length && series_list.length < 100) {
            query.or.push(
                {
                    name: 'series_list', op: 'any', 'val': {
                        op: 'in', name: 'id', 'val': series_list.map(item => item.id)
                    }
                });
        }

        if (components?.length) {
            query.or.push({
                name: 'components', op: 'any', val: {
                    op: 'in', name: 'id', val: components.map(item => item.id)
                }
            });
        }
        if (session_state_list?.length) {
            query.or.push({
                name: 'session_state',
                op: 'has',
                val: {op: 'in', name: 'id', val: session_state_list.map(ss => ss.id)}
            });
        }

        options.filters.push(query);

        return options.filters;
    }

    /**
     * getEventsByBaseType: returns all events for a given base_type and params
     */
    getEventsByBaseType(base_type: 'event' | 'comment' | 'downtime' | 'correction_factor', start: Date, end: Date, series_list?, components?, session_state_list?: SessionState[]): Observable<any> {
        let options: SearchQueryOptions = new SearchQueryOptions();
        if (base_type !== 'comment') {
            session_state_list = undefined;
        }

        options.filters = this.generateEventsFilter(start, end, series_list, components, session_state_list);
        options.sort = 'start';

        return this.getEventsByBaseTypeQuery(base_type, options);
    }

    /**
     * getEventsByBaseTypeQuery: as per getEventsByBaseType but the options are supplied
     * This is used for paging, filtering and sorting where the options are determined in the front-end
     */
    getEventsByBaseTypeQuery(base_type: 'event' | 'comment' | 'downtime' | 'correction_factor', options: SearchQueryOptions) {
        return this.api[base_type].searchMany(options);
    }

    /**
     * getCommentsBySessionId: Gets the comments for a given session_state
     */
    getCommentsBySessionId(start: Date, end: Date, session: SessionState): Observable<any> {
        const options = new SearchQueryOptions();
        options.filters = [{
            and: [{
                or: [{and: [{name: 'start', op: 'ge', val: start}, {name: 'start', op: 'le', val: end}]},
                    {and: [{name: 'end', op: 'ge', val: start}, {name: 'end', op: 'le', val: end}]}]
            },
                {name: 'session_state', op: 'has', val: {op: 'eq', name: 'id', val: session.id}}
            ]
        }];
        options.sort = 'start';
        return this.api.comment.searchMany(options);
    }

    addEvent(ev): Observable<any> {
        return this.api[ev.type].obsSave(ev).pipe(take(1));
    }

    /**------------------------------------------------------------------------------------------------------- **/
    /**This section is for the event_types api**/
    getEventTypesByRelationshipIds(rel_ids: string[], rel_name: string, etIds?: ModelID[]): Observable<ListResponse<EventType>> {
        const options = new SearchQueryOptions();
        options.filters = [getManyRelationWithIdsFilter(rel_name, rel_ids)]
        if (etIds?.length > 0) {
            options.filters.push({name: 'id', op: 'in', val: etIds})
        }
        return this.api.event_type.searchMany(options);
    }

    getEventTypesByName(event_type_names: string[]): Observable<ListResponse<EventType>> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'name', op: 'in', val: event_type_names}];
        return this.api.event_type.searchMany(options);
    }

    getEventTypes(event_type_ids: string[]): Observable<ListResponse<EventType>> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: event_type_ids}];
        return this.api.event_type.searchMany(options);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
