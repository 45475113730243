import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {TileParameters} from "../../services/tile_data.service";
import {ModelID} from "../../_typing/generic-types";
import {JsonContextService} from "./json-context.service";
import {ReportParameters} from "./type/report-parameters";
import {BaseComponent} from "../../shared/base.component";
import {BeforeSaveAction} from "../../_typing/utils/before-save";

@Component({
    selector: 'custom-html-form',
    templateUrl: './custom-html-form.component.html',
    styleUrls: ['./custom-html-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomHtmlFormComponent extends BaseComponent implements BeforeSaveAction {
    @Input() config: TileParameters;
    @Input() tileId: ModelID;
    @Output() configChange: EventEmitter<TileParameters> = new EventEmitter<TileParameters>();
    isLegacy: boolean = false;
    reportParameters: ReportParameters;

    constructor(private jsonContextService: JsonContextService) {
        super();
    }

    ngOnInit() {
        if (this.config.hasOwnProperty("paragraph_body")) {
            this.isLegacy = false;
        } else {
            this.isLegacy = true;
            this.jsonContextService.configSubjectChanged(new ReportParameters(this.config))
        }
    }

    relayConfigChange(event) {
        this.config = Object.assign(this.config, event);
        this.configChange.emit(this.config);
    }

    beforeSave(): boolean {
        if (!this.config.hasOwnProperty("paragraph_body")) {
            const reportParameters = new ReportParameters(this.config);
            this.jsonContextService.configSubjectChanged(reportParameters);
            this.config = this.jsonContextService.getJsonContext(reportParameters);
            this.relayConfigChange(this.config);
            console.log("Context JSON", this.config.jsonContext);
        }
        return true;
    }
}
