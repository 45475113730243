import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuditPivotComponent} from "../reports/audit-pivot/audit-pivot.component";
import {CalculationCheckTableComponent} from "../tables/calculation-check-table/calculation-check-table.component";
import {EngineeringUnitViewComponent} from "../handson-sheets/engineering-unit-sheet-view/engineering-unit-sheet-view.component";
import {RegisterUserView} from "../user_control/register.component";
import {CollectorEventsTableComponent} from "../tables/collector-events-table/collector-events-table.component";
import {QuickChartsComponent} from "../charts/quick-charts.component";
import {StreamSheetFocusViewComponent} from "../handson-sheets/stream-sheet-focus-view/stream-sheet-focus-view.component";
import {CollectorMapperSheetViewComponent} from "../handson-sheets/collector-mapper-sheet-view/collector-mapper-sheet-view.component";
import {EquipmentSheetFocusViewComponent} from "../handson-sheets/equipment-sheet-focus-view/equipment-sheet-focus-view.component";
import {PageViewComponent} from "../page-view/page-view/page-view.component";
import {ShiftSheetViewComponent} from "../handson-sheets/shift-sheet-view/shift-sheet-view.component";
import {OreBodySheetViewComponent} from "../handson-sheets/ore-body-sheet-view/ore-body-sheet-view.component";
import {
    SeriesComponentSheetFocusViewComponent
} from "../handson-sheets/series-component-sheet-focus-view/series-component-sheet-focus-view.component";
import {SeriesTableFocusViewComponent} from "../tables/series-table-focus-view/series-table-focus-view.component";
import {CustomProcessViewComponent} from "../handson-sheets/custom-process-sheet-view/custom-process-sheet-view.component";
import {ProcessSheetFocusViewComponent} from "../handson-sheets/process-sheet-focus-view/process-sheet-focus-view.component";
import {EstimateSheetViewComponent} from "../handson-sheets/estimate-sheet-view/estimate-sheet-view.component";
import {LogSheetFocusViewComponent} from "../data-sheets/log-sheet/log-sheet-focus-view.component";
import {ResourceSheetViewComponent} from "../handson-sheets/resource-sheet-view/resource-sheet-view.component";
import {ProcessAccessSheetViewComponent} from "../handson-sheets/process-access-sheet-view/process-access-sheet-view.component";
import {SeriesSheetFocusViewComponent} from "../handson-sheets/series-sheet-focus-view/series-sheet-focus-view.component";
import {SeriesSheetViewComponent} from "../handson-sheets/series-sheet-view/series-sheet-view.component";
import {FlowchartFocusViewComponent} from "../flowchart/flowchart-focus-view/flowchart-focus-view.component";
import {WaterBalanceComponent} from "../reports/water-balance-view/water-balance-view.component";
import {EstimateDataSheetViewComponent} from "../data-sheets/estimate-data-sheet-view.component";
import {WelcomeComponent} from "../user_control/welcome.component";
import {InputDataSheetFocusViewComponent} from "../data-sheets/input-data-sheet-focus-view.component";
import {ProcessSheetViewComponent} from "../handson-sheets/process-sheet-view/process-sheet-view.component";
import {SeriesComponentSheetViewComponent} from "../handson-sheets/series-component-sheet-view/series-component-sheet-view.component";
import {EventPivotComponent} from "../events/event-pivot.component";
import {EquipmentSheetViewComponent} from "../handson-sheets/equipment-sheet-view/equipment-sheet-view.component";
import {StreamSheetViewComponent} from "../handson-sheets/stream-sheet-view/stream-sheet-view.component";
import {EstimateDataSheetFocusViewComponent} from "../data-sheets/estimate-data-sheet-focus-view.component";
import {EstimateSheetFocusViewComponent} from "../data-sheets/estimate-sheet-focus-view.component";
import {RecoveryGradeForecastComponent} from "../forecasts/recovery-grade-forecast/recovery-grade-forecast.component";
import {PageListViewComponent} from "../page-view/page-list-view/page-list-view.component";
import {AuditHistoryComponent} from "../handson-sheets/audit-history/audit-history.component";
import {HelpPageComponent} from "../components/help-page/help-page.component";
import {ExceptionOnDemandComponent} from "../debugging/exception-on-demand/exception-on-demand.component";
import {EventTypesTableComponent} from "../tables/event-types-table/event-types-table.component";
import {OreBodyTypesTableComponent} from "../tables/ore-body-types-table/ore-body-types-table.component";
import {OreBodyGroupViewComponent} from "../lib/flowchart/ore-body-group-vew/ore-body-group-view.component";
import {PageViewGuard} from "../services/route-guards/page-view-guard.guard";
import {FocusViewGuard} from "../services/route-guards/focus-view-guard.guard";
import {UrlGuard} from "../services/route-guards/url-guard.guard";
import {AnalysisToolsViewComponent} from "../reports/analysis-tools-view/analysis-tools-view.component";
import {ComponentTypesTableComponent} from '../tables/component-types-table/component-types-table.component';
import {ForbiddenViewComponent} from "../user_control/forbidden-view/forbidden-view.component";
import {ValueDriverTreeViewComponent} from '../reports/value-driver-tree-view/value-driver-tree-view.component';
import {ComponentEventsTableViewComponent} from '../tables/component-events-table-view/component-events-table-view.component';
import {DataExceptionsTableViewComponent} from "../tables/data-exceptions-table-view/data-exceptions-table-view.component";
import {
    SingleComponentEventsTableViewComponent
} from "../tables/single-component-events-table-view/single-component-events-table-view.component";
import {ValidateComponentTemplatesComponent} from "../tables/validate-component-templates/validate-component-templates.component";
import {NotFoundViewComponent} from "../user_control/not-found-view/not-found-view.component";
import {LockTemplateTableViewComponent} from "../tables/lock-template-table-view/lock-template-table-view.component";
import {ComponentPanelStackComponent} from "../modals/component-panel-stack/component-panel-stack.component";
import {SolverTemplateTableComponent} from "../tables/solver-template-table/solver-template-table.component";
import {MySecurityComponent} from "../user_control/my-security.component";
import {ResetPasswordComponent} from "../user_control/reset-password.component";
import {LoginComponent} from "../user_control/login.component";
import {CheckLoginGuard} from "../services/route-guards/legacy-keycloak-guard";
import {GenerateAuthTokenComponent} from "../user_control/generate-auth-token/generate-auth-token.component";
import {ThemeViewComponent} from "../shared/scss/theme-component/theme-view.component";
import {SubroutePageOutletComponent} from "./subroute-page-outlet/subroute-page-outlet.component";
import {ManageUsersComponent} from "../user_control/manage-users/manage-users.component";

const routes: Routes = [
    {
        path: 'view', children: [
            {path: 'login', pathMatch: 'full', component: LoginComponent},
            {path: 'welcome', pathMatch: 'full', component: WelcomeComponent, canActivate: [CheckLoginGuard]},
            {path: 'forbidden', pathMatch: 'full', component: ForbiddenViewComponent, canActivate: [CheckLoginGuard]},
            {path: 'notfound', pathMatch: 'full', component: NotFoundViewComponent, canActivate: [CheckLoginGuard]},
            {path: 'audit_pivot', pathMatch: 'full', component: AuditPivotComponent, canActivate: [CheckLoginGuard]},
            {
                path: 'calculation_check',
                pathMatch: 'full',
                component: CalculationCheckTableComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'validate_component_templates',
                pathMatch: 'full',
                component: ValidateComponentTemplatesComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'engineering_unit',
                pathMatch: 'full',
                component: EngineeringUnitViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {path: 'user_list', pathMatch: 'full', component: ManageUsersComponent, canActivate: [CheckLoginGuard]},
            {path: 'access_token', pathMatch: 'full', component: GenerateAuthTokenComponent, canActivate: [CheckLoginGuard]},
            {path: 'register_user', pathMatch: 'full', component: RegisterUserView, canActivate: [CheckLoginGuard]},
            {path: 'quick_charts', pathMatch: 'full', component: QuickChartsComponent, canActivate: [CheckLoginGuard]},
            {
                path: 'collection_events',
                pathMatch: 'full',
                component: CollectorEventsTableComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'collector_mapper_sheet',
                pathMatch: 'full',
                component: CollectorMapperSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'shift_sheet',
                pathMatch: 'full',
                component: ShiftSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'ore_body_sheet',
                pathMatch: 'full',
                component: OreBodySheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'edit_series_components',
                pathMatch: 'full',
                component: SeriesComponentSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {path: 'event_pivot', pathMatch: 'full', component: EventPivotComponent, canActivate: [CheckLoginGuard]},
            {
                path: 'edit_process',
                pathMatch: 'full',
                component: ProcessSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'estimate_sheet',
                pathMatch: 'full',
                component: EstimateSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'estimate_sheet/:processID',
                pathMatch: 'full',
                component: EstimateSheetFocusViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'resource_sheet',
                pathMatch: 'full',
                component: ResourceSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'process_access_sheet',
                pathMatch: 'full',
                component: ProcessAccessSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'edit_series',
                pathMatch: 'full',
                component: SeriesSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'water_balance',
                pathMatch: 'full',
                component: WaterBalanceComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'analysis_tools',
                pathMatch: 'full',
                component: AnalysisToolsViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'estimate_data_sheet',
                pathMatch: 'full',
                component: EstimateDataSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'equipment_sheet',
                pathMatch: 'full',
                component: EquipmentSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {path: 'my_security', pathMatch: 'full', component: MySecurityComponent, canActivate: [CheckLoginGuard]},
            {
                path: 'reset_password',
                pathMatch: 'full',
                component: ResetPasswordComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'edit_streams',
                pathMatch: 'full',
                component: StreamSheetViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'value_driver_trees',
                pathMatch: 'full',
                component: ValueDriverTreeViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'data_exceptions',
                pathMatch: 'full',
                component: DataExceptionsTableViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'recovery_grade_forecast',
                pathMatch: 'full',
                component: RecoveryGradeForecastComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'audit_history',
                pathMatch: 'full',
                component: AuditHistoryComponent,
                canActivate: [CheckLoginGuard]
            },
            {path: 'help-page', pathMatch: 'full', component: HelpPageComponent, canActivate: [CheckLoginGuard]},
            {
                path: 'event_types',
                pathMatch: 'full',
                component: EventTypesTableComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'component_types',
                pathMatch: 'full',
                component: ComponentTypesTableComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'component_events',
                pathMatch: 'full',
                component: ComponentEventsTableViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'single_component_events',
                pathMatch: 'full',
                component: SingleComponentEventsTableViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'ore_body_types',
                pathMatch: 'full',
                component: OreBodyTypesTableComponent,
                canActivate: [CheckLoginGuard]
            },

            {
                path: 'page_list_view',
                pathMatch: 'full',
                component: PageListViewComponent,
                canActivate: [CheckLoginGuard]
            },

            {
                path: 'custom_process_sheet/:category',
                pathMatch: 'full',
                component: CustomProcessViewComponent,
                canActivate: [CheckLoginGuard]
            },

            {
                path: 'series_table/:processID',
                pathMatch: 'full',
                component: SeriesTableFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'log_sheet/:processID',
                pathMatch: 'full',
                component: LogSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'flowchart/:processID',
                pathMatch: 'full',
                component: FlowchartFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'input_data_sheet/:processID',
                pathMatch: 'full',
                component: InputDataSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'edit_series_components/:processID',
                pathMatch: 'full',
                component: SeriesComponentSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'edit_series/:processID',
                pathMatch: 'full',
                component: SeriesSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'edit_sub_process/:processID',
                pathMatch: 'full',
                component: ProcessSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'edit_stream/:processID',
                pathMatch: 'full',
                component: StreamSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'estimate_data_sheet/:processID',
                pathMatch: 'full',
                component: EstimateDataSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'ore-body-group-view/:oreBodyGroupID',
                pathMatch: 'full',
                component: OreBodyGroupViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'ore-body-group-view',
                pathMatch: 'full',
                component: OreBodyGroupViewComponent,
                canActivate: [CheckLoginGuard]
            },
            {
                path: 'equipment_sheet/:processID',
                pathMatch: 'full',
                component: EquipmentSheetFocusViewComponent,
                canActivate: [CheckLoginGuard, FocusViewGuard]
            },
            {
                path: 'on_demand_exception',
                pathMatch: 'full',
                component: ExceptionOnDemandComponent
            },
            {
                path: 'lock_template_table_view',
                pathMatch: 'full',
                component: LockTemplateTableViewComponent,
                canActivate: [CheckLoginGuard]
            }, {
                path: 'solver_templates',
                pathMatch: 'full',
                component: SolverTemplateTableComponent,
                canActivate: [CheckLoginGuard]
            }, {
                path: 'theme',
                pathMatch: 'full',
                component: ThemeViewComponent,
                canActivate: [CheckLoginGuard]
            }, {
                path: 'page_view/:sessionID',
                component: PageViewComponent,
                canActivate: [CheckLoginGuard, PageViewGuard],
                children: [
                    {
                        path: 'component/:tileID',
                        pathMatch: 'full',
                        component: ComponentPanelStackComponent,
                        canActivate: [CheckLoginGuard],
                        outlet: 'component_panel',
                    }, {
                        path: 'component-detail/:componentID/:tileID',
                        pathMatch: 'full',
                        component: ComponentPanelStackComponent,
                        canActivate: [CheckLoginGuard],
                        outlet: 'component_panel'
                    },
                ]
            },
            {
                path: 'jobs', component: SubroutePageOutletComponent, canActivate:[CheckLoginGuard], loadChildren: () => import('../jobs/jobs.module').then(m => m.JobsModule)
            },
        ],
    },
    {
        path: '**',
        component: PageViewComponent,
        canActivate: [CheckLoginGuard, UrlGuard]
    }
];

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: "reload",
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
