
<div class="page-list-view page">
    <div *ngIf="!restricted_access && users_ready===true">
      <page-list [header]="'Public Pages and Reports'" [list]="all_dashboards" [user_dict]="user_dict"></page-list>
    </div>
    <div *ngIf="restricted_access && users_ready===true">
      <page-list [header]="'My Pages'" [list]="actual_restricted_dashboards" [user_dict]="user_dict"></page-list>
    </div>

</div>
