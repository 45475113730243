<div class="row relative-dtp" [ngClass]="{'disabled' : disabled}" *ngIf="config">

  <mat-label>From</mat-label>
  <input matInput [(ngModel)]="config.from.duration" type="number" step="1" min="0" [disabled]="disabled"
         [placeholder]="'Select from duration no. before ' + config.relative_to">
  <mat-select [(ngModel)]="config.from.key" [disabled]="disabled">
    <mat-option *ngFor="let option of duration_options" [value]="option">{{option}}s</mat-option>
  </mat-select>
  <div style="margin-left:4px;display: flex;align-items: center;">ago</div>

  <mat-label>to</mat-label>


  <input matInput [(ngModel)]="config.to.duration" type="number" step="1" min="0" [disabled]="disabled"
         [placeholder]="'Select no. of ' + config.from.key + 's from start duration'">
  <mat-select [(ngModel)]="config.to.key" [disabled]="disabled">
    <mat-option *ngFor="let option of duration_options" [value]="option">{{option}}s</mat-option>
  </mat-select>
  <div style="margin-left:4px;display: flex;align-items: center;">ago</div>

  <mat-label>from</mat-label>


  <mat-select [(ngModel)]="config.relative_to" [disabled]="disabled">
    <mat-option value="start" matTooltip="Time traveler start date" matTooltipPosition="right">TT start date</mat-option>
    <mat-option value="end" matTooltip="Time traveler end date" matTooltipPosition="right">TT end date</mat-option>
    <mat-option value="today" matTooltip="The date on the day the tile is viewed.">Current date</mat-option>
  </mat-select>

  <mat-label>in</mat-label>


  <mat-select [(ngModel)]="config.sample_period" [disabled]="disabled">
    <mat-option *ngFor="let option of sample_periods" [value]="option">{{option}}s</mat-option>
  </mat-select>
  <div style="margin-left:4px;display: flex;align-items: center;">(sample period)</div>


</div>
