import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EventModelUploadDataService} from "../event-model-upload-data.service";
import {Subject} from "rxjs";
import {UploadModelTemplateService} from "../../upload-model-template.service";

@Component({
    selector: 'event-model-upload-select-constant-property',
    templateUrl: './event-model-upload-select-constant-property.component.html',
    styleUrls: ['../extract-event-options.component.less'],
    standalone: false
})
export class EventModelUploadSelectConstantPropertyComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() readonly = false;
    @Input() constantPropertyID;
    @Output() constantPropertyIDChange = new EventEmitter<string>();

    /* Select search help method */
    compareValueIds: (a: { id: string }, b: string) => boolean;

    constructor(public dataService: EventModelUploadDataService,
                public templateService: UploadModelTemplateService) {
        this.compareValueIds = (a: { id: string }, b: string) => {
            return a.id === b;
        };
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setConstantProperty(value) {
        this.constantPropertyID = value.id;
        this.constantPropertyIDChange.next(value.id);
    }

}
