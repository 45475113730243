import {BaseSeriesToken} from "./series-token";

export class SeriesDataStatusToken extends BaseSeriesToken {
    /**
     * Class for managing series statuses that are add via the data-status HTML tag attribute. *
     * @param full_token
     * @param series_name
     */
    constructor(
        // Full qualifier for this token in the original text
        public readonly full_token: string,
        public readonly series_name: string
    ) {
        super(full_token);
    }

    public get data_key(): string {
        return `${this.series_name}@Value`;
    }

    public static extractTextTokens(text: string): SeriesDataStatusToken[] {
        // TODO replace all this with [...text.matchAll(/(\[([ \w]+)@([ \w]+)\])/gm)]
        //  needs update to ES2020 compile target.
        const regexp = new RegExp(/data-status={([ \w.,_-]+)}/gm);
        let match;

        const tokens = [];

        // index 1: full token
        // index 2: series name
        while ((match = regexp.exec(text)) !== null) {
            let token = new SeriesDataStatusToken(match[0], match[1]);
            tokens.push(token);
        }

        return tokens;
    }
}
