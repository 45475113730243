import {Injectable} from "@angular/core";
import {from, Observable, of} from "rxjs";
import {compare_ids} from "../lib/utils";
import {differenceWith as _differenceWith, differenceBy as _differenceBy} from "lodash-es";
import {catchError, concatMap, mergeMap, toArray} from "rxjs/operators";
import {ApiService} from '../services/api/api.service';
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class RelationshipApiMappingService {
    //FIXME Move this to the data folder when that branch has been merged
    constructor(private api: ApiService,
                private http: HttpClient) {
    }

    /**Generic function for updating an object relationship list based on its previous value
     * Uses terminology of parent-child because the list of 'children' is updated for a single parent_id at a time
     * although strictly these are many to many mapping tables
     * @param mapping_model_name: the api string name for the mapping table
     * @param rel_type_name: model type of the 'by'/'parent'/single side of the relationship for this instance
     * (e.g. event_type in get properties by event_type)
     * @param rel_id: id for the model instance of relationship type above
     * @param model_type: model type of 'many' side of the relationship for this instance
     * (e.g. constant_properties in get properties by event_type)
     * @param new_object_list: the currently selected models of model_type
     * @param old_object_list: the previously selected models of model_type
     * @param new_mapping_list: the list of existing linkage objects of type mapping_model_name
     * (e.g. event_component, constant_property_component_type etc.)
     * @param old_mapping_list: the list of previous linkage objects of type mapping_model_name
     * required when comparing by a combination of ids rather than a row id
     */
    saveMany<T>(mapping_model_name: string, rel_type_name: string, rel_id: string, model_type: string, new_object_list: any[],
                old_object_list: any[], old_mapping_list: any[], new_mapping_list?: any[], update_by_id_combo = false, self_type?: string): Observable<any> {
        const deleteObservables: Observable<any>[] = [];
        const saveObservables: Observable<any>[] = [];

        const to_delete: T[] = _differenceWith<T, T>(old_object_list, new_object_list, compare_ids);
        const to_add: T[] = _differenceWith<T, T>(new_object_list, old_object_list, compare_ids);

        const relationships_to_delete = this.getRelationshipsToDelete(to_delete, old_mapping_list, model_type);
        const relationships_to_add = this.getRelationshipsToAdd(to_add, mapping_model_name, rel_type_name, rel_id, model_type, self_type);
        console.log('To delete\n', relationships_to_delete, '\nTo add\n', relationships_to_add);

        relationships_to_delete.forEach(link_id => {
            deleteObservables.push(this.api[mapping_model_name].obsDelete(link_id));
        });

        relationships_to_add.forEach(obj => {
            saveObservables.push(this.api[mapping_model_name].obsSave(obj).pipe(catchError(e => {
                console.log('Caught error in relationship mapping saveMany: ', e);
                //this.snackbar.open('Error saving the sources for this series: ' + e, "Hide", {duration:10000});
                return of(e)
            })))
        });

        if (deleteObservables.length === 0 && saveObservables.length === 0) {
            return of(null);
        }

        const concurrentRequests = 10;
        return from(deleteObservables).pipe(
            mergeMap(obj => obj, concurrentRequests), // Execute deletes concurrently. This must be done first in case there are unique constraints to item relationships being added
            toArray(), // Wait for all deletes to finish
            concatMap(() =>
                saveObservables.length > 0
                    ? from(saveObservables).pipe(
                        mergeMap(obj => obj, concurrentRequests) // Execute saves concurrently
                    ) : of(null) // Emit null if there are no saves
            )
        );
    }

    /**As above but for custom apis when using a combination of ids to match old to new items**/
    saveManyCustomApi<T>(model_type: string, old_mapping_list: any[], new_mapping_list: any[], add_api: string, delete_api: string): Observable<any> {
        const $changes: Observable<T>[] = [];

        const to_delete: T[] = _differenceBy(old_mapping_list, new_mapping_list, model_type + '_id')
        const to_add: T[] = _differenceBy(new_mapping_list, old_mapping_list, model_type + '_id');

        const relationships_to_delete = to_delete.forEach(item => {
            $changes.push(this.http.post<any>(delete_api, item))
        })
        const relationships_to_add = to_add.forEach(item => {
            $changes.push(this.http.post<any>(add_api, item))
        })

        console.log('To delete\n', to_delete, '\nTo add\n', to_add);

        if ($changes.length < 1) {
            return of("No items to change");
        }

        const concurrentRequests = 10;
        return from($changes).pipe(
            mergeMap(obj => obj, concurrentRequests));
    }

    getRelationshipsToDelete(to_delete: any[], relationship_list: any[], child_type: string): string[] {
        const ids = [];
        to_delete.forEach(item => {
            const link = relationship_list.find(r => r.relationships[child_type].data.id === item.id);
            if (link) {
                ids.push(link.id);
            }
        })
        return ids;
    }

    getRelationshipsToAdd(to_add: any[], model_name: string, rel_type_name: string, rel_id: string, child_type: string, self_type?: string): any[] {
        const objects = [];
        const child_att_type = self_type ? child_type + '_' + self_type + '_id' : child_type + '_id';
        const rel_type_att_name = self_type ? rel_type_name + '_' + self_type + '_id' : rel_type_name + '_id';
        to_add.forEach(child => {
            objects.push({
                attributes: {
                    [child_type + '_id']: child.id,
                    [rel_type_name + '_id']: rel_id,
                },
                relationships: {
                    [child_type]: {data: {id: child.id, type: self_type || child_type}},
                    [rel_type_name]: {data: {id: rel_id, type: self_type || rel_type_name}}
                },
                'type': model_name
            })
        })
        return objects;
    }

}
