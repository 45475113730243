<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Properties">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row" style="flex-wrap:wrap;">
            <mat-form-field (mouseenter)="hint='Name'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Name</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.name" matInput name="name" placeholder="">
              <mat-hint>The name of the process or plant section.</mat-hint>
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Description'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Description</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.description" matInput name="description" placeholder="">
              <mat-hint>A more lengthy description of the process.</mat-hint>
            </mat-form-field>
          </div>

          <div class="row medium-height">
            <mat-form-field (mouseenter)="hint='Code'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Code</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.code" matInput name="code" placeholder="">
              <mat-hint>An alpha-numerical code that sets the sort order of a group of processes.</mat-hint>
            </mat-form-field>

          </div>
        </div>
      </form>
    </mat-tab>

    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Display">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <div class="spacer select-search">
              <select-search-component
                  (selectionChange)="pageSelected($event)"
                  [value]="efc.component_data.attributes.json.pages[0]"
                  [api_model]="'session_state_light'"
                  [compareFunctionName]="'compareById'"
                  [filter_by]="['name']"
                  [label]="'Link to page'"
                  [classes]="'fill'">
              </select-search-component>
            </div>
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input [disabled]="!efc.component_data.attributes.json.pages?.length"
                     (change)="pageTitleChanged()"
                     [(ngModel)]="linked_page_title" matInput name="page_title"
                     placeholder=""
                     style="box-sizing: border-box;">
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label style="display:flex;align-items:center;margin-top:-6px;">Select Icon <img
                  height="30px" src="{{ efc.component_data.attributes.icon }}" width="30px"/></mat-label>
              <!--<label>Select Icon<img src="{{ efc.component_data.attributes.icon }}" width="30px" height="30px"/></label>-->
              <mat-select [(ngModel)]="efc.component_data.attributes.icon" name="process_icon">
                <mat-option *ngFor="let img of efc.images" [value]="img" class="image-options">
                  <img height="30px" src="{{ img }}"
                       width="30px"/><span>{{img.split("/")[img.split("/").length - 1].split('.')[0]}}</span>
                </mat-option>
              </mat-select>
              <mat-hint>The icon that will be shown with this process in the flowcharts and in the application header.
              </mat-hint>
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='FontSize'">
              <mat-label>Font Size</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.json.fontsize" matInput max="32" name="font_size"
                     type="number">
              <mat-hint> The size of the font displayed for this process in the flowchart. (A maximum of 32px is
                allowed.)
              </mat-hint>
            </mat-form-field>

          </div>

          <div *ngIf="efc.is_parent==true" class="row">
            <mat-form-field>
              <mat-label>Screen Width ({{efc.component_data.attributes.json.windowWidth}})</mat-label>
              <input (input)="holdConnectors()" [(ngModel)]="efc.component_data.attributes.json.windowWidth"
                     [max]="5000"
                     [min]="100"
                     [step]="10" matInput
                     name="window_width"
                     type="number">
              <mat-hint> The outer width of the entire flowchart on screen (shown by the orange box). This option is
                only available when editing the flowchart's parent process.
              </mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Screen Height ({{efc.component_data.attributes.json.windowHeight}})</mat-label>
              <input (input)="holdConnectors()" [(ngModel)]="efc.component_data.attributes.json.windowHeight"
                     [max]="5000"
                     [min]="100"
                     [step]="10" matInput
                     name="window_height"
                     type="number">
              <mat-hint> The outer height of the entire flowchart on screen (shown by the orange box). This option is
                only available when editing the flowchart's parent process.
              </mat-hint>
            </mat-form-field>
          </div>
          <div *ngIf="efc.is_parent==true">

            <label>Select standard page size:</label>
            <div class="row">
              <mat-form-field name="page_size">
                <mat-label>Page Size</mat-label>
                <mat-select (selectionChange)="efc.setPageSize()" [(ngModel)]="efc.page_size" name="page_size">
                  <mat-option [value]="'A4'">A4</mat-option>
                  <mat-option [value]="'A3'">A3</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field name="page_layout">
                <mat-label>Page Size</mat-label>
                <mat-select (selectionChange)="efc.setPageSize()" [(ngModel)]="efc.page_layout" name="page_layout">
                  <mat-option [value]="'portrait'">Portrait</mat-option>
                  <mat-option [value]="'landscape'">Landscape</mat-option>
                  <mat-option [value]="'auto'">Auto</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="efc.is_parent==false" class="row">
            <!--//TODO mat-slider not sliding properly-->
            <mat-form-field>
              <mat-label>Width</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.json.width" [max]="2000" [min]="10"
                     [step]="10"
                     matInput
                     name="width"
                     style="width:300px"
                     type="number">
              <mat-hint> The width of the process rectangle that appears on the flowchart.</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Height</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.json.height" [max]="1000"
                     [min]="10"
                     [step]="10"
                     matInput
                     name="height"
                     type="number">
              <mat-hint> The height of the process rectangle that appears on the flowchart.</mat-hint>
            </mat-form-field>
            <div>
              <chart-process-detail
                  style="min-height:200px; margin: 0 10px -120px 10px;padding:12px;"
                  [process]="efc.component_view">
              </chart-process-detail>
            </div>

          </div>
        </div>
      </form>
    </mat-tab>

    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Relationships">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <mat-form-field (mouseenter)="hint='ProcessType'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Process Type</mat-label>
              <mat-select
                  (selectionChange)="efc.fillConstantTemplate(efc.component_data.relationships.component_type.data)"
                  [(ngModel)]="efc.component_data.relationships.component_type.data"
                  name="process_type">
                <mat-option *ngFor="let component_type of efc.component_types" [value]="component_type">
                  {{component_type.attributes.name}} - {{component_type.attributes.description}}
                </mat-option>
              </mat-select>
              <mat-hint>This will be used to identify the template of series types that are applicable for this process.
                After creating the process, the 'Fill series template' button can be used to add these series types to
                this process.
              </mat-hint>
            </mat-form-field>


            <!--                <div class="hint">-->
            <!--                  This will be used to identify the template of series types that are applicable for this process.-->
            <!--                  After creating the process, the 'Fill series template' button can be used to add these series types to-->
            <!--                  this process.-->
            <!--                </div>-->
          </div>

          <ng-container>
            <h4>Custom Properties</h4>
            <component-constant-table [constant_property_dict]="efc.constant_property_dict"
                                      [component]="efc.component_data"
                                      [component_constants]="efc.constant_components"
                                      [flowchart]="efc.chart"
                                      class="row">
            </component-constant-table>
          </ng-container>

          <h4>Series</h4>
          <div class="row end">
            <select-many-search [(value)]="efc.selected_series"
                                [classes]="'fill'"
                                [data]="efc.plantData.all_series"
                                [fill_stubs]="true"
                                [label]="'Select a series'"
                                class="select-search">
            </select-many-search>
            <!--<div class="hint">-->
            <!--Select a series and use the Add to process button to add it to this process. The Add new series button can be used to create a new series which will be added to this process.-->
            <!--</div>-->
            <div class="row end">
              <button (click)='efc.addSeries()' class="icon" matTooltip="Add to process">
                <i class="fa fa-arrow-down"></i></button>
              <button (click)="efc.showSeriesForm()" class="icon-new" matTooltip="Add new series">
              </button>
              <!--<button class="btn" (click)="efc.setTemplate()" matTooltip="Set Template"><i class="fa fa-tasks" ></i></button>-->
            </div>

          </div>


          <h4 *ngIf="efc.series_permissions" style="margin-top:20px;">Constant Series Data </h4>
          <!--<div class="hint" ng-if="$parent.$parent.showHints && show_constants">-->
          <!--Manage constant values for a series in the form(s) below. Use the 'Add new' button to add a new constant value for a series. To remove an entry, delete the value at the required date-time. Use the 'Save' button to save all changes.-->
          <!--</div>-->

          <series-component-table *ngIf="efc.component_data" [all_series]="efc.plantData.all_series"
                                  [component]="efc.component_data"
                                  [deleted_component_ids]="efc.deleted_component_ids"
                                  [flowchart]="efc.chart"
                                  class="row">
          </series-component-table>

          <ng-container *ngIf="efc.series_permissions">
            <div *ngFor="let series_component of efc.component_data.relationships.series_components.data;"
                 style="min-width:650px;">
              <ng-container *ngIf="series_component.relationships.series.data && (
                                series_component.relationships.series.data.attributes.fill_method=='Constant'
                                || series_component.relationships.series.data.attributes.fill_method=='Constant Monthly Flatten')
                                && efc.series_permissions[series_component.relationships.series.data.id]
                                && efc.series_permissions[series_component.relationships.series.data.id].indexOf('view_process_data') > -1">

                <div class="row">
                  <label
                      (click)="series_component.relationships.series.data.hide_constants=!series_component.relationships.series.data.hide_constants"
                      style="color:gray; text-decoration: underline;padding-top:20px;cursor:pointer;">
                    {{series_component.relationships.series.data.attributes.name}}
                    <i [ngClass]="{'fa-angle-up': !series_component.relationships.series.data.hide_constants, 'fa-angle-down': series_component.relationships.series.data.hide_constants}"
                       aria-label="Hide data values"
                       class="fa fa-angle-up"
                       matTooltip="Hide data values" role="button" style="border:none; margin-left:12px;" tabindex="0">
                    </i></label>
                </div>
                <constant-series-data *ngIf="!series_component.relationships.series.data.hide_constants"
                                      [series]="series_component.relationships.series.data"
                                      [series_permissions]="efc.series_permissions">
                </constant-series-data>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>
