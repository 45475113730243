<div class="dashboard-tile" *ngIf="tile && tile_ready"
     [ngClass]="{'text-editing': tileData.editingSubject.value && (tile.attributes.content==='paragraph' || tile.attributes.content==='custom' ),
     'showing-comments': headerData.showing_comments,
     'pivot-ng-editing': pivotEditing && tile.attributes.content === 'pivot-tile-ng',
     'tile-editing': tileData.editingSubject.value, 'tile-dtp': tile.attributes?.parameters?.show_dtp,
     'no-format': tile.attributes.show_header===false}"
     [ngStyle]="{'border-color':tile.attributes.category ? tile.attributes.category.colour : '' }">
  <div class="row tile-header" (dblclick)="editConditional()"
       [ngStyle]="{'background-color':tile.attributes.category ? tile.attributes.category.colour : '' }">
    <div class="row">
      <audit-mixin-indicator [model]="tile"></audit-mixin-indicator>
      <h2>
        <page-tile-menu [tileContent]="tile.attributes.content" [tileId]="tile.id" [sessionStateId]="current_session_id"></page-tile-menu>
        <div [appProcessMenu]="tile.attributes?.parameters?.process">{{title}}</div>
        <span>{{sub_title}}</span>
      </h2>
    </div>
    <div *ngIf="!tile.attributes.hide_edit" [class.hidden-header]="tile.attributes.show_header===false"
         class="tile-header-right">
      <div *ngIf="dateInst.dtp"  class="date-period-range-select">
        <mat-select *ngIf="tile.attributes.custom_dtp && !tile.attributes.relative_dtp"
                    (selectionChange)="selectCustomDtp()" [(value)]="dateInst.dtp.range"
                    [disabled]="tile.attributes.custom_dtp!==true"
                    [matTooltipDisabled]="tile.attributes.custom_dtp!==true"
                    [matTooltip]="dateInst.dtp.calendar + ': ' + dateInst.dtp.range"
                    class="range-select hideonprint"
                    placeholder="Select Range">
          <mat-option *ngFor="let range of dateTimePeriodService.ranges"
                      [value]="range.range">{{range.range | removeUnderscore | upperfirst}}
          </mat-option>
          <mat-option (click)="selectCustomDtp()" value="custom">Custom</mat-option>
        </mat-select>
        <div *ngIf="tile.attributes.custom_dtp &&  !tile.attributes.relative_dtp"
             class="displayonprint">{{dateInst.dtp | dateTimePeriod}}
        </div>
        <div *ngIf="tile.attributes.custom_dtp && tile.attributes.relative_dtp"
             [matTooltip]="dateInst.dtp | dateTimePeriod">Relative
        </div>
      </div>

      <ng-container *ngIf="!tile.attributes.hide_edit && allowEdit(tile.attributes.content) && edit_mode">
        <div [matMenuTriggerFor]="menu" class="hide-xs hideonprint"><i class="edit-no-text"></i></div>
        <mat-menu #menu="matMenu" class="tile-menu">
          <button
              (click)="tileData.setEditing(false);openTextFormDialog(tile.attributes.content)"
              *ngIf="(['paragraph','custom'].includes(tile.attributes.content)) && allowEdit(tile.attributes.content) &&
              tile.attributes.parameters?.hasOwnProperty('paragraph_body')"
              mat-menu-item>Edit text<i class="fa fa-paragraph"></i></button>
          <button
              (click)="tileData.setEditing(true);"
              *ngIf="(['series-table', 'series-summary', 'component-events-table', 'custom-events-table','comment-tile','page-comment-table', 'log-sheet'].includes(tile.attributes.content)) && !editing && allowEdit(tile.attributes.content)"
              mat-menu-item>Edit layout<i class="fa fa-paragraph"></i></button>
          <button (click)="buttons[0].func();pivotEditing=!pivotEditing"
                  *ngIf="((tile.attributes.content==='pivot-tile' || tile.attributes.content==='pivot-tile-ng') && !pivotEditing)"
                  mat-menu-item>Edit pivot<i class="fa fa-edit"></i></button>
          <button (click)="buttons[1].func();pivotEditing=!pivotEditing"
                  *ngIf="((tile.attributes.content==='pivot-tile' || tile.attributes.content==='pivot-tile-ng') && pivotEditing)"
                  mat-menu-item>Save pivot<i class="fa fa-save"></i></button>

          <button (click)="buttons[0].func();modelPivotEditing=!modelPivotEditing"
                  *ngIf="(tile.attributes.content==='model-pivot-tile' && !modelPivotEditing)"
                  mat-menu-item>Edit pivot<i class="fa fa-edit"></i></button>
          <button (click)="buttons[1].func();modelPivotEditing=!modelPivotEditing"
                  *ngIf="(tile.attributes.content==='model-pivot-tile' && modelPivotEditing)"
                  mat-menu-item>Save pivot<i class="fa fa-save"></i></button>
          <button (click)="buttons[0].func();saveContent(tile);"
                  *ngIf="tile.attributes.content==='value-driver-tree'"
                  mat-menu-item>Save zoom<i class="fa fa-save"></i></button>
          <button (click)="tileData.setEditing(false);saveContent(tile);"
                  *ngIf="(['series-table', 'series-summary','component-events-table', 'custom-events-table','comment-tile','page-comment-table', 'log-sheet'].includes(tile.attributes.content)) && editing"
                  mat-menu-item>Save layout<i class="fa fa-save"></i></button>
          <button
              (click)="tileData.setEditing(false);"
              *ngIf="(['series-table', 'series-summary', 'component-events-table', 'custom-events-table', 'log-sheet'].includes(tile.attributes.content)) && editing"
              mat-menu-item>Cancel<i class="fa fa-ban"></i></button>
          <button (click)="openTileFormDialog();" [disabled]="appScope.current_user.restricted_access" mat-menu-item
                  title="{{appScope.current_user.restricted_access? 'You do not have rights to edit this tile':'Edit tile'}}"
          >Edit tile<i class="fa fa-edit"></i></button>
          <button (click)="deleteTile()" [disabled]="appScope.current_user.restricted_access"
                  mat-menu-item
                  title="{{appScope.current_user.restricted_access? 'You do not have rights to edit this tile':'Delete tile'}}"
          >Delete tile<i class="fa fa-trash"></i></button>
        </mat-menu>
      </ng-container>
    </div>

    <div #dtp_picker *ngIf="show_date_picker" class="dtp_picker form-dialog">
      <date-time-period [disabled]="!tile.attributes.custom_dtp" [immutable]="false" [no_url]="true"
                   class="date-period inline"></date-time-period>
      <div class="action-buttons">
        <button (click)="onCloseDtpSelector()" class="btn-close">Close</button>
        <button (click)="useCustomDtp()" class="btn-save">Select</button>
      </div>
    </div>
  </div>

  <!--Tile Content-->

  <div overlay-scrollbars [defer] [options]="scrollbarOptions" [ngClass]="tile.attributes.content"
       class="tile-content">
    <div *ngIf="show_no_data" class="tile-no-data" [ngStyle]="show_no_data.styles ? show_no_data.styles : ''">
      {{show_no_data.message || 'No data to display.'}}
      <i class="fa fa-times hideonprint" matTooltip="Hide" (click)="show_no_data='';"></i></div>
    <date-time-period *ngIf="tile.attributes.parameters && tile.attributes.parameters.show_dtp"
                 class="date-period inline align-bottom"></date-time-period>

    <div *ngIf="tile.attributes.content=='paragraph'" style="height: 100%; width: 100%; min-height:50px;">
      <paragraph [config]="tile.attributes.parameters"
                 [show_header]="tile.attributes.show_header"
      >
      </paragraph>
    </div>
    <print-header-tile *ngIf="tile.attributes.content==='print-header-tile'"></print-header-tile>
    <print-footer-tile *ngIf="tile.attributes.content==='print-footer-tile'"
                       [config]="tile.attributes.parameters"></print-footer-tile>

    <comments-table *ngIf="tile.attributes.content==='comment-tile'"
                    [config]="tile.attributes.parameters" [current_session_id]="current_session_id"></comments-table>

    <page-comment-table *ngIf="tile.attributes.content==='page-comment-table'"
                        [config]="tile.attributes.parameters"
                        [current_session_id]="current_session_id"></page-comment-table>

    <custom-html (tileContentChange)="tile.attributes.parameters = $event" *ngIf="tile.attributes.content==='custom'"
                 [config]="tile.attributes.parameters"
                 [show_header]="tile.attributes.show_header"></custom-html>

    <flow-chart *ngIf="tile.attributes.content==='flowchart' && flowchartReady"
                title="{{tile.attributes.parameters.process.attributes.name}}"
                (context_menu)="contextMenu($event)" [chart]="flowchart" class="flowchart-tile">
    </flow-chart>
    <div #context_menu *ngIf="tile.attributes.content==='flowchart' && flowchartReady"
         [ngStyle]="{'display':menuVisible? 'block':'none'}"
         class="menu flowchart-context-menu">
      <ul class="menu-options">
        <li *ngIf="selected_flowchart_components[0]?.data.attributes?.base_type==='process' &&
			selected_flowchart_components[0]?.is_parent !== true" class="menu-option" style="cursor:default;padding:0;">
          <a (click)="changeProcess(selected_flowchart_components[0])">
            Zoom In
          </a>
        </li>
        <li *ngIf="selected_flowchart_components[0]?.data.attributes?.base_type==='process' &&
			selected_flowchart_components[0]?.is_parent === true && selected_flowchart_components[0]?.data.relationships.parent.data"
            class="menu-option" style="cursor:default;padding:0;">
          <a (click)="changeProcess(selected_flowchart_components[0]?.data.relationships.parent)">
            Zoom Out
          </a>
        </li>
        <li (click)="menuVisible=false;"
            *ngIf="selected_flowchart_components[0]?.data.attributes?.base_type==='process'"
            class="menu-option" style="cursor:default;padding:0;">
          <a (click)="openFlowchart(selected_flowchart_components[0].data)">
            Open flowchart
          </a>
        </li>
        <ng-container
            *ngIf="selected_flowchart_components[0]?.data?.type==='process' && selected_flowchart_components[0]?.pagelinks().length">
          <li *ngFor="let page of selected_flowchart_components[0].pagelinks()"
              class="menu-option" style="cursor:default;padding:0;">
            <a [routerLink]="'/view/page_view/' + page.id">
              Link to {{page.title}}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <generic-chart *ngIf="(tile.attributes.content==='custom-chart' || tile.attributes.content==='spc-chart')
                   && !['stacked bar', 'rar'].includes(tile.attributes.parameters.chart_type)"
                   [config]="tile.attributes.parameters"
                   class="chart">
    </generic-chart>

    <bar-chart *ngIf="tile.attributes.content==='custom-chart' && tile.attributes.parameters.chart_type==='stacked bar'"
               [config]="tile.attributes.parameters"
               class="chart"></bar-chart>


    <rar-chart *ngIf="tile.attributes.content==='custom-chart' && tile.attributes.parameters.chart_type==='rar'"
               [config]="tile.attributes.parameters"
               class="plotly-chart"></rar-chart>

    <comparison-chart *ngIf="tile.attributes.content==='comparison-chart'" [config]="tile.attributes.parameters"
                      class="chart">
    </comparison-chart>

    <component-events-table *ngIf="tile.attributes.content==='component-events-table'"
                            [config]="tile.attributes.parameters"
                            [is_tile]="true"
                            (needToEdit)="openTileFormDialog($event)">
    </component-events-table>

    <julian-date *ngIf="tile.attributes.content==='julian-date'" [config]="tile.attributes.parameters"></julian-date>

    <div *ngIf="tile.attributes.content==='context'" style="height:100%;width:100%;">
      <context *ngIf="tile.attributes.content==='context'" [config]="tile.attributes.parameters"
               [show_header]="tile.attributes.show_header" [title]="tile.attributes.title">
      </context>
    </div>

    <div *ngIf="tile.attributes.content==='series-summary'" style="height:100%;width:100%;">
      <series-summary *ngIf="tile.attributes.content==='series-summary'"
                      [config]="tile.attributes.parameters"
                      [show_header]="tile.attributes.show_header"
                      [title]="tile.attributes.title">
      </series-summary>
    </div>

    <log-sheet *ngIf="tile.attributes.content==='log-sheet'"
               [config]="tile.attributes.parameters">
    </log-sheet>

    <series-data-table *ngIf="tile.attributes.content==='series-data-table'"
                       [config]="tile.attributes.parameters">
    </series-data-table>

    <series-adjustment *ngIf="tile.attributes.content==='series-adjustment'"
                       [config]="tile.attributes.parameters">
    </series-adjustment>

    <event-sheet *ngIf="tile.attributes.content==='custom-events' || tile.attributes.content==='ore-body-events'"
                 [config]="tile.attributes.parameters">
    </event-sheet>

    <custom-events-table *ngIf="tile.attributes.content==='custom-events-table'" [config]="tile.attributes.parameters">
    </custom-events-table>

    <component-creator *ngIf="tile.attributes.content==='component-creator'" [config]="tile.attributes.parameters">
    </component-creator>

    <component-form *ngIf="tile.attributes.content==='component-form'" [config]="tile.attributes.parameters">
    </component-form>

    <default-chart *ngIf="tile.attributes.content=='default-chart' || tile.attributes.content=='budget-bar-chart'"
                   class="chart" style="width: 90%; height: 90%;"
                   [config]="tile.attributes.parameters" [type]="tile.attributes.content"
                   [hide_comments]="tile.attributes.parameters.hide_comments"
                   [show_data_labels]="tile.attributes.parameters.show_data_labels"
                   [hide_cumulative]="tile.attributes.parameters.hide_cumulative">
    </default-chart>

    <series-table *ngIf="tile.attributes.content==='series-table'"
                  [config]="tile.attributes.parameters"
                  [ngClass]="tile.attributes.parameters.adjust_row_height? 'adjust-row-height':''">
    </series-table>


    <data-exceptions-table *ngIf="tile.attributes.content==='data-exceptions-table'"
                           [config]="tile.attributes.parameters">
    </data-exceptions-table>

    <weather-widget *ngIf="tile.attributes.content==='weather-widget'"></weather-widget>

    <input-data-sheet *ngIf="tile.attributes.content==='input-sheet'"
                      [config]="tile.attributes.parameters"></input-data-sheet>

    <events *ngIf="tile.attributes.content==='events'" [config]="tile.attributes.parameters"></events>

    <pending-context *ngIf="tile.attributes.content==='pending-context'"
                     [config]="tile.attributes.parameters"></pending-context>

    <model-pivot-tile (tileContentChange)="saveState($event)" *ngIf="tile.attributes.content==='model-pivot-tile'"
                      [config]="tile.attributes.parameters"
                      [showUI]="false"></model-pivot-tile>

    <pivot-tile *ngIf="tile.attributes.content==='pivot-tile'" [config]="tile.attributes.parameters" [showUI]="false"
                (tileContentChange)="saveState($event)"></pivot-tile>
    <pivot-tile-ng (tileContentChange)="saveState($event)" *ngIf="tile.attributes.content==='pivot-tile-ng'"
                   [config]="tile.attributes.parameters"
                   [showUI]="false"></pivot-tile-ng>

    <upload-model *ngIf="tile.attributes.content==='upload-model'" [config]="tile.attributes.parameters"></upload-model>

    <value-driver-tree *ngIf="tile.attributes.content==='value-driver-tree' && dateInst.dtp"
                       [selected_calculation]="tile.attributes.parameters.selected_calculation"
                       [config]="tile.attributes.parameters"
                       [is_tile]="true"></value-driver-tree>

    <waterfall-chart *ngIf="tile.attributes.content==='waterfall-chart'" [config]="tile.attributes.parameters"
                     class="plotly-chart">
    </waterfall-chart>

    <!--//TODO allow comments on series-table and input-sheet??-->
    <div
        *ngIf="tile.attributes.show_header!==false && !edit_mode && hideCommentButton(tile.attributes.content)"
        [button_class]="tile.attributes.content === 'series-table' ||
                tile.attributes.content === 'log-sheet' ||
                tile.attributes.content === 'input-sheet'"
        class="comment-button">
      <button (click)="toggleComments()"
              [ngClass]="{'show-drawer': headerData.showing_comments && tileData.selected === true}"
              class="icon-comment">
      </button>
    </div>

    <category-chart *ngIf="tile.attributes.content==='category-chart'"
                    [config]="tile.attributes.parameters" class="chart"></category-chart>

    <printout-mapper *ngIf="tile.attributes.content=='printout-mapper'"
                     [config]="tile.attributes.parameters"></printout-mapper>

  </div><!--end tile-content-->

</div>
