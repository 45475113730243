<div class="page">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Table" [hidden]="!hot || !hot.ready">
      <div class="row" style="justify-content: space-between;width:calc(100% - 10px);">
        <div class="row" [style]="'align-items: center;'">
          <form (submit)="updateSearchFilter()">
            <input [(ngModel)]="filter_string" placeholder="Search..." name="search" style="height:28px;"/>
            <button type="submit" mat-button class="bordered-button"
                    style="height:30px;margin:1px 20px 0 4px;min-width:70px;">Search
            </button>
          </form>
        </div>
        <div *ngIf="filter_warning" class="row hideonprint warning info" [style]="'padding: 4px;'">
          {{filter_warning}}
        </div>
        <mat-paginator [pageSizeOptions]="page_size_options.concat(equipment_total)"
                       [showFirstLastButtons]="true"
                       [style]="'justify-self: flex-end;'"
        >
        </mat-paginator>
      </div>

      <div class="md-padding" style="position:relative;" [appLdsRoller]="loading">
        <div (window:resize)="hot.onResize($event)" [style.height.px]="hot.window_height"
             [style]="'width: 100%; overflow:hidden; border: 1px red'">
          <div #hot_anchor [id]="hot.handson_id">
          </div>
        </div>
      </div>
      <!--</ng-template>-->
    </mat-tab>
    <mat-tab *ngIf="hot && hot.ready && hot.instance" label="Pivot">
      <ng-template matTabContent>
        <!--TODO make height dynamic?-->
        <pivot-view [hot]="hot"></pivot-view>
      </ng-template>
    </mat-tab>
    <!--<mat-tab *ngIf="hot && hot.ready && hot.instance"-->
    <mat-tab *ngIf="hot && hot.ready && hot.instance"
             label="History">
      <ng-template matTabContent>
        <div style="width: 100%; overflow:auto;">
          <!--TODO make height dynamic?-->
          <history-table [hot]="hot"></history-table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
