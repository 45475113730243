import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, ModelRelationCleanup} from "./shared-types";

export type EVENT_TYPE_BASE_TYPES = 'event' | 'comment' | 'downtime' | 'correction_factor';

export class EventTypeAttributes<T extends EVENT_TYPE_BASE_TYPES = 'event'> extends AuditMixinAttributes {
    name: string;
    behaviour?: string;
    icon?: string;
    update_function?: string;
    hide_end?: boolean;
    base_type?: string = 'event';
    severity: number;
}

export class EventTypeRelationships<T extends EVENT_TYPE_BASE_TYPES = 'event'> extends AuditMixinRelationships {
    series_list: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    ore_body_types: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    component_types?: LIST_RELATIONSHIP_TYPE;

    public static cleanup(o: EventTypeRelationships<any>) {
        if (!o) return;
        ModelRelationCleanup.List(o.series_list);
        ModelRelationCleanup.List(o.ore_body_types);
        ModelRelationCleanup.List(o.component_types);
    }
}

export class EventType extends AuditMixin {
    attributes: EventTypeAttributes<'event'>;
    id: string;
    type: string = 'event_type';
    relationships: EventTypeRelationships<'event'>;

    constructor() {
        super();
        this.attributes = new EventTypeAttributes<'event'>();
        this.relationships = new EventTypeRelationships<'event'>();
    }

    public static cleanup(o: EventType) {
        EventTypeRelationships.cleanup(o.relationships);
    }
}


export class EventTypeLightAttributes<T extends EVENT_TYPE_BASE_TYPES = 'event'> {
    name: string;
    base_type?: string = 'event';
}

export class EventTypeLight {
    attributes: EventTypeAttributes<'event'>;
    id: string;
    type: string = 'event_type';

}
