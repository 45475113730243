import * as Handsontable from "handsontable";
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {HotInstance} from "../../services/hot-instance";
import { SearchQueryOptions } from "../../services/api/search-query-options";
import {tap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";

@Component({
    selector: 'resource-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class ResourceSheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance = new HotInstance();
    resources: any[];
    title: string;
    component_types: any[];
    revisions: any[];
    schema: any;
    column_list: any;
    search: string = '';

    users: any[];

    constructor(
        private api: ApiService,
        private headerData: HeaderDataService,
        private appScope: AppScope,
        private handsontableGenerator: HandsontableGenerator) {
    }

    ngOnInit(): void {
        this.title = 'Resources';
        this.headerData.buttons = [];
        this.appScope.page_title = "Resources";

        // Filter for resource types
        const observables: Observable<any>[] = [];

        const componentTypeOptions = new SearchQueryOptions();
        componentTypeOptions.filters = [{
            val: 'resource',
            name: 'base_type',
            op: 'eq'
        }];
        observables.push(this.api.component_type.searchMany(componentTypeOptions).pipe(
            tap(response => this.component_types = response.data))
        );

        observables.push(this.api.revision.searchMany().pipe(tap(response => this.revisions = response.data)));
        observables.push(this.api.resource.searchMany().pipe(tap(response => this.resources = response.data)));
        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));

        forkJoin(observables).subscribe(this.createTable.bind(this));

        this.buildHeader();
    }

    createTable() {
        const componentTypeLookups = this.handsontableGenerator.gen_lookups(this.component_types);
        const revisionLookups = this.handsontableGenerator.gen_lookups(this.revisions, function (item) {
            return item.attributes.name;
        });
        const userLookup = this.handsontableGenerator.gen_lookups(this.users, function (item) {
            return item.attributes.name;
        });
        this.schema = {
            id: null,
            type: 'resource',
            attributes: {
                base_type: 'resource',
                name: null,
                description: null,
                created_on: null,
                changed_on: null
            },
            relationships: {
                revision: {data: {id: null, type: 'revision'}},
                component_type: {data: {id: null, type: 'component_type'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        this.column_list = [{
            data: 'attributes.name',
            title: 'Name'
        }, {
            data: 'attributes.description',
            title: 'Description'

        }, {
            data: this.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        this.hot = this.handsontableGenerator.generateTable(this.api.resource, this.schema, this.column_list, this.hot);
        this.hot.ready = true;
        this.hot.settings.data = this.resources;
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    save() {
        const results = this.hot.save();
        this.resources = results.data;
    }

    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = this.appScope.page_title;
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save'},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download'}
        ];
    }
}
