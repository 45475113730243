import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {HeaderDataService} from "../services/header_data.service";

// Fallback page for general errors  - no default pages set per site, permissions errors etc
// Why were you sent to this page
export enum RedirectType {
    FORBIDDEN,
    DEFAULT,
    ERROR
}

@Component({
    selector: 'welcome',
    templateUrl: 'welcome.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class WelcomeComponent implements OnInit {

    constructor(private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.headerData.title = 'Welcome to WIRE!';
        this.headerData.add_new_page_form = true;
    }
}
