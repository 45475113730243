import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output, ViewEncapsulation
} from '@angular/core';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {OreBody} from '../../_models/ore-body';
import {AppScope} from '../../services/app_scope.service';
import {Subject} from "rxjs";
import {tap, take, takeUntil} from 'rxjs/operators';
import {findIndex as _findIndex} from "lodash-es";
import {ConstantProperty} from "../../_models/constant-property";
import {getManyRelationWithIdsFilter} from "../../services/api/filter_utils";
import {IDMap, KeyMap, ModelID, ModelName} from '../../_typing/generic-types';
import {EventDataService} from "../../data/event-data.service";
import {PivotTileConfig} from "../../_typing/config/pivot-tile";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {filterUnfetchedIds, deepCopy} from "../../lib/utils";
import {PivotService} from "../../services/pivot.service";
import {BeforeSaveAction} from "../../_typing/utils/before-save";
import {AttributeTimeConfig, ConstantPropertyTimeConfig} from "../../_typing/config/config-type-date-filter";
import {ConfigDateFilterService} from "../../services/config-date-filter.service";

@Component({
    selector: 'pivot-tile-form',
    templateUrl: './pivot-tile-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PivotTileFormComponent implements OnInit, OnDestroy, BeforeSaveAction {
    @Input('type')
    pivot_tile_type: string | null; //pivot or advanced pivot

    @Input()
    config: PivotTileConfig;
    ore_bodies_list: OreBody[];

    @Output() configChange = new EventEmitter<PivotTileConfig>();

    selected_component_ids: ModelID[] = [];
    selected_ore_bodies: OreBody[] = [];
    fuse_search_attributes: any[] = [
        "attributes.name",
        "attributes.description",
        "attributes.full_path_names"
    ]; //passed to select search to allow fuse search by full_path_name

    timeFilterConstantProperty: IDMap<ConstantProperty> = {};
    cp_date_filter: KeyMap<any[]> = {};
    cp_filter: any[];
    component_type_names: KeyMap<string> = {};
    event_type_names: KeyMap<string> = {};
    account_id: ModelID;
    customised_property_ids: ModelID[];
    cp_name_dict: KeyMap<ModelName> = {};
    labelMap: KeyMap<{ label?: string; decimal_places?: number }> = {};

    private readonly onDestroy = new Subject<void>();

    constructor(public dateTimePeriodService: DateTimePeriodService,
                private appScope: AppScope,
                private eventDataService: EventDataService,
                private componentData: ComponentTypeDataService,
                private pivotService: PivotService,
                private configDateFilterService: ConfigDateFilterService,
                private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.config = PivotTileConfig.initialiseProperties(this.config);
        this.configChange.emit(this.config);
        this.account_id = this.appScope.active_account_id;
        this.setLabelMap();

        if (this.config.component_type_ids && this.config.component_type_ids.length > 0) {
            this.getComponentTypeNames(this.config.component_type_ids);
            this.setComponentTypeConstantPropertyFilter(this.config.component_type_ids);
        }

        if (this.config.event_type_ids && this.config.event_type_ids.length > 0) {
            this.getEventTypeNames(this.config.event_type_ids);
            this.setEventTypeConstantPropertyFilter(this.config.event_type_ids);
        }
        this.getCustomisedPropertyIds();
    }

    private getComponentTypeNames(ids: ModelID[]) {
        ids = filterUnfetchedIds(ids, this.component_type_names);
        this.componentData.getComponentTypes(ids).pipe(
            tap(result => {
                result.data.forEach(e => {
                    this.component_type_names[e.id] = e.attributes.name;
                })
            }), takeUntil(this.onDestroy))
            .subscribe(() => this.cdr.markForCheck())
    }

    private getEventTypeNames(ids: ModelID[]) {
        ids = filterUnfetchedIds(ids, this.event_type_names);
        this.eventDataService.getEventTypes(ids).pipe(
            tap(result => {
                result.data.forEach(e => {
                    this.event_type_names[e.id] = e.attributes.name;
                })
            }), takeUntil(this.onDestroy))
            .subscribe(() => this.cdr.markForCheck())
    }

    stringFunction(obj: any) {
        //Show OBG name next to Ore Body
        let string = '';

        let groups = obj.attributes.full_path_names;
        if (groups && groups.length > 0) {
            string = groups;
        } else {
            string = obj.attributes.name;
        }
        if (obj.attributes.type_name) {
            string = string + " (" + obj.attributes.type_name + ")";
        }
        return string;
    };

    updateConfig($event, list_type) {
        const ctrl = this;
        if (list_type === 'component_type') {
            ctrl.config.component_type_ids = $event.map((component_type) => {
                    if (typeof component_type === 'object') {
                        this.component_type_names[component_type.id] = component_type.attributes.name;
                    }
                    return component_type.id || component_type;
                }
            );

            ctrl.setComponentTypeConstantPropertyFilter(ctrl.config.component_type_ids);
            this.config.event_type_ids = []
            this.config.series_list = [];
            this.selected_ore_bodies = [];
            ctrl.config.ore_bodies_list = [];
        }
        if (list_type === 'event') {
            ctrl.config.event_type_ids = $event.map((event_type) => {
                    if (typeof event_type === 'object') {
                        this.event_type_names[event_type.id] = event_type.attributes.name;
                    }
                    return event_type.id || event_type;
                }
            );

            ctrl.setEventTypeConstantPropertyFilter(ctrl.config.event_type_ids);
            this.config.component_type_ids = [];
            this.config.series_list = [];
            this.selected_ore_bodies = [];
            ctrl.config.ore_bodies_list = [];
        }
        if (list_type === 'series') {
            ctrl.config.series_list = $event.map((series) => {
                    return {name: series.attributes?.name || series.name, id: series.id};
                }
            );
            this.config.event_type_ids = [];
            this.config.component_type_ids = [];
            this.selected_ore_bodies = [];
            ctrl.config.ore_bodies_list = [];
        }
        if (list_type === 'ore_body') {
            ctrl.config.ore_bodies_list = $event.map((ob) => {
                    return {name: ob.attributes?.name || ob.name, id: ob.id};
                }
            );
            ctrl.config.event_type_ids = [];
            this.config.component_type_ids = [];
            ctrl.config.series_list = [];
        }
        this.updateDateFilters();
        this.configChange.emit(this.config);
        this.cdr.markForCheck();
    }

    isSelected(cpId: ModelID): boolean {
        if (!this.config.pivot_state) return false;
        let titles = [this.cp_name_dict[cpId], this.config.property_customisations[cpId]?.label, this.labelMap[cpId]?.label];
        titles = titles.filter(t => t != null);
        const selected = (this.config.pivot_state.cols || []).concat(this.config.pivot_state.rows || []).concat(this.config.pivot_state.vals || []);
        return titles.some(s => selected.includes(s));
    }

    private setComponentTypeConstantPropertyFilter(component_type_ids: ModelID[]): void {
        this.cp_filter = [getManyRelationWithIdsFilter('component_types', component_type_ids)];
        this.cp_date_filter = this.configDateFilterService.getTypeConstantPropertyFilter(component_type_ids, 'component_types');
    }

    private setEventTypeConstantPropertyFilter(event_type_ids: ModelID[]): void {
        this.cp_filter = [getManyRelationWithIdsFilter('event_types', event_type_ids)];
        this.cp_date_filter = this.configDateFilterService.getTypeConstantPropertyFilter(event_type_ids, 'event_types');
    }

    private getCustomisedPropertyIds() {
        this.customised_property_ids = Object.keys(this.config.property_customisations);
        this.pivotService.getConstantPropertyNameDict(this.customised_property_ids, this.cp_name_dict)
            .pipe(takeUntil(this.onDestroy), take(1))
            .subscribe((cp_name_dict: KeyMap<ModelName>) => {
                this.cp_name_dict = cp_name_dict;
                this.cdr.markForCheck();
            });
    }

    propertiesChanged($event: (ConstantProperty | ModelID)[]): void {
        const eventKeys = new Set($event.map(cp => typeof cp === 'object' ? (cp as ConstantProperty).id : cp));
        $event.forEach(cp => {
            const key = typeof cp === 'object' ? (cp as ConstantProperty).id : cp;
            if (!this.config.property_customisations.hasOwnProperty(key)) {
                this.config.property_customisations[key] = {};
            }
            if (typeof cp === 'object') {
                this.cp_name_dict[key] = (cp as ConstantProperty).attributes.name;
            }
        });
        Object.keys(this.config.property_customisations).forEach(id => {
            if (!eventKeys.has(id)) {
                this.config.property_customisations[id].label = this.cp_name_dict[id];
                this.replacePivotStateStrings(id);
                delete this.config.property_customisations[id];
            }
        });
        this.getCustomisedPropertyIds();
    }

    setLabelMap() {
        this.labelMap = deepCopy(this.config.property_customisations);
    }

    private updateLabelMap(): void {
        /**This needs to be done just before saving so only the final change is recorded*/
        Object.keys(this.config.property_customisations || {}).forEach(cp_id => {
            this.replacePivotStateStrings(cp_id);
        })
    }

    private replacePivotStateStrings(cp_id: ModelID): void {
        ['rows', 'cols', 'vals'].forEach(s => {
            let index = _findIndex(this.config.pivot_state?.[s] || [],
                (r) => r === (this.labelMap[cp_id]?.label || this.cp_name_dict[cp_id]));
            if (index !== -1) {
                this.config.pivot_state[s][index] = this.config.property_customisations[cp_id].label || this.cp_name_dict[cp_id];
            }
        })
    }

    disableBypassDateFilters(): boolean {
        return Object.values(this.config.constant_property_time || {}).some(val => val.enabled)
            || Object.values(this.config.attribute_time || {}).some(val => val.enabled);
    }

    constantPropertyDateFieldChanged = ($event: KeyMap<Partial<ConstantPropertyTimeConfig>>) => {
        this.config.constant_property_time = $event;
        this.updateDateFilters();
    }

    attributeDateFieldChanged = ($event: KeyMap<Partial<AttributeTimeConfig>>) => {
        this.config.attribute_time = $event;
        this.updateDateFilters();
    }

    private updateDateFilters() {
        if (this.disableBypassDateFilters()) {
            this.config.bypass_date_filters = false;
        }
        const typeIds: ModelID[] = this.config.event_type_ids?.length ? this.config.event_type_ids : this.config.component_type_ids;
        this.configDateFilterService.updateConfigDateFilters(this.config, typeIds);
        this.cdr.markForCheck();
    }

    beforeSave(): boolean {
        this.updateLabelMap();
        return true;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
