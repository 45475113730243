<div class="page">
  <section>
    <div class="row utils-bar" style="justify-content: space-between">
      <div *ngIf="dataSource" class="table-filter">
        <input (keyup)="applyFilter($event.target.value)" placeholder="Search calculations..."/>
      </div>
      <div>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"
                   showFirstLastButtons
                   style="justify-self: flex-end;">
    </mat-paginator>
      </div>
    </div>

    <div class="table-container">
      <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="'name'"
             class="table table-striped calculation-check-table" mat-table matSort matSortDirection="asc">

        <ng-container *ngFor="let column of calcColumns;" [matColumnDef]="column">
          <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
            <ng-container>{{column | upperfirst | removeUnderscore}}</ng-container>
          </th>
          <td *matCellDef="let element;" [ngStyle]="{ 'background-color': getRowColor(element, column) }" mat-cell
              style="text-align: left;border:none;">
            <div *ngIf="column === 'calculation'" class="row center">
              <i (click)="editSeries(element)" class="edit-inline hide-xs"></i>
              <a (click)="openChartDialog(element.attributes.name)" [textContent]="element.attributes.name" style="cursor:pointer;"></a>
            </div>
            <div *ngIf="['description', 'name_formula'].includes(column)" class="row center">
              <span>{{element.attributes[column]}}</span>
            </div>
            <div *ngIf="column==='status'" class="row center">
              <span>{{statusDict[element.id]}}</span>
            </div>
            <div *ngIf="column==='message'" class="row center">
              <span>{{messageDict[element.id]}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container>
          <tr *matHeaderRowDef="calcColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: calcColumns;" mat-row></tr>
        </ng-container>
      </table>
    </div>
  </section>
</div>
