<div class="audit-mixin">
  <div class="row">
    <div>Created on:</div>
    <div>{{model.attributes.created_on | stringDate}}</div>
  </div>
  <div class="row">
    <div>Created by:</div>
    <div>{{model.attributes.created_by_name}} {{model.attributes.created_by_lastname!== null ? model.attributes.created_by_lastname : ""}}</div>
  </div>
  <div class="row" style="margin-top:6px;">
    <div>Changed on:</div>
    <div>{{model.attributes.changed_on | stringDate}}</div>
  </div>
  <div class="row">
    <div>Changed by:</div>
    <div>{{model.attributes.changed_by_name }} {{model.attributes.changed_by_lastname!== null ? model.attributes.changed_by_lastname : ""}}</div>
  </div>
</div>
