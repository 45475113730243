import {Component, OnInit, Input} from '@angular/core';
import {ConstantPropertyDataService} from "../../../data/constant-property-data.service";
import {ModelRelationshipNameDict} from '../../../services/api/filter_utils';
import {BaseModel} from "../../../_typing/generic-types";

@Component({
    selector: 'constant-property-list',
    template: '<table-cell-relationship-list [parent]="parent" [filter]="filter" [model_api]="api" [mapFunction]="mapFunction" [editFunction]="editFunction"></table-cell-relationship-list>',
    standalone: false
})
export class ConstantPropertyListComponent implements OnInit {
    @Input() parent: BaseModel<any>;
    @Input() editFunction: () => void;

    filter: any;
    api = 'constant_property_light';
    mapFunction = (curr) => {
        if (!curr) return;
        return curr.map(item => item.attributes?.name);
    }

    constructor(private propertyData: ConstantPropertyDataService) {
    }

    ngOnInit(): void {
        this.filter = this.propertyData.generatePropertiesByRelationshipIdsFilter(
            ModelRelationshipNameDict.constant_property[this.parent.type], [this.parent.id]);
    }

}
