import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Subject} from "rxjs";
import {Component as WireComponent} from "../../_models/component";
import {first, takeUntil} from 'rxjs/operators';
import {COMPONENT_EVENTS_CONFIG} from "../../forms/component-events-table-form/component-events-table-form.component";
import {HeaderDataService} from "../../services/header_data.service";
import {NotificationService} from "../../services/notification.service";


@Component({
    selector: 'src-single-component-events-table-view',
    templateUrl: './single-component-events-table-view.component.html',
    styleUrls: ['./single-component-events-table-view.component.less'],
    standalone: false
})
export class SingleComponentEventsTableViewComponent implements OnInit {
    private readonly onDestroy = new Subject<void>();
    components: WireComponent[];
    selected_component: WireComponent;
    selected_component_name: string;
    config: COMPONENT_EVENTS_CONFIG;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private notification: NotificationService) {
        this.api.component.searchMany().pipe(first(), takeUntil(this.onDestroy))
            .subscribe(results => {
                this.components = results.data;
                this.components = this.components.filter(item => item.attributes.name)
            })
        this.buildHeader();
    }

    ngOnInit(): void {
    }

    componentScanned(event) {
        let found = this.components.find(item => item.attributes.name.toLowerCase() === this.selected_component_name.toLowerCase());
        if (found) {
            this.selected_component = found;
            this.componentSelected();
        } else {
            this.notification.openError(event + ' not found. Please check the name and try again.', 10000);
        }
    }

    componentSelected() {
        this.selected_component_name = this.selected_component?.attributes?.name;
        this.config = {component: null};
        const base = this.selected_component.attributes?.base_type || this.selected_component.type;
        this.api[base].getById(this.selected_component.id).pipe(first(), takeUntil(this.onDestroy))
            .subscribe(result => {
                this.config.component = result.data;
            })
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Component Events Table';
        ctrl.headerData.show_dtp = true;

    };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
