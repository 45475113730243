import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {DateTimePickerComponent} from "../../date-time/date-time-picker/date-time-picker.component";
import {MaterialModule} from "../../sub-modules/material.module";
import {SharedPipesModule} from "./shared-pipes.module";

@NgModule({
    declarations: [
        DateTimePickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        SharedPipesModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        DateTimePickerComponent,
    ]
})
export class SharedModule {
}
