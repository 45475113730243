import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {ApiService} from "../services/api/api.service";
import {UserPreference} from "../_models/user-preference";
import {ListResponse} from '../services/api/response-types';
import {getAccountFilter, getRelationWithAttributeFilter} from "../services/api/filter_utils";
import {AppScope} from '../services/app_scope.service';
import {GenericDataService} from './generic-data.service';
import {ModelID} from '../_typing/generic-types';

@Injectable({
    providedIn: 'root'
})
export class UserPreferenceDataService {

    constructor(private api: ApiService,
                private appScope: AppScope,
                private genericData: GenericDataService
    ) {
    }

    getUserPreferencesByUserAndAccount(user_id: string): Observable<ListResponse<UserPreference>> {
        const options: SearchQueryOptions = new SearchQueryOptions();
        options.ignore_routing = true;
        options.filters = [
            getRelationWithAttributeFilter('user', user_id, 'id'),
            getAccountFilter(this.appScope.active_account_id)
        ];
        return this.api.user_preference.searchMany(options);
    }

    getUserPreferencesByUser(user_id: string): Observable<ListResponse<UserPreference>> {
        const options = this.generateUserPreferencesByUserOptions(user_id);
        return this.api.user_preference.searchMany(options);
    }

    generateUserPreferencesByUserOptions(user_id: ModelID): SearchQueryOptions {
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithAttributeFilter('user', user_id, 'id')
        ];
        return options;
    }

    saveUserPreferences(user_preference: UserPreference): Observable<any> {
        return this.genericData.upsertModel<UserPreference>('user_preference', user_preference);
    }

}
