<div class="alerts-table-component">
  <div class="utils-bar" >
    <div class="table-filter" style="align-items: center;">
      <form (submit)="updateSearchFilter()">
        <input [(ngModel)]="filter_string" placeholder="Search..." name="search"/>
        <button type="submit" mat-button class="bordered-button search-button" [disabled]="!filter_string">
          <i class="fa fa-search"></i>
        </button>
      </form>
    </div>
    <mat-paginator [pageSizeOptions]="[5,10,15,20]"
                   showFirstLastButtons
                   style="justify-self: flex-end;">
    </mat-paginator>
  </div>

  <div class="table-container">
    <table class="table alerts-table"
           mat-table matSort multiTemplateDataRows (matSortChange)="updateSort($event)"
           *ngIf="dataSource!==undefined" [dataSource]="dataSource">

      <ng-container *ngFor="let column of columns;">

        <ng-container *ngIf="['data_exception_type','data_exception'].includes(column)"
                      matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let alert" mat-cell >
            <div [textContent]="alert.attributes[column]"></div>
          </td>
        </ng-container>

        <ng-container *ngIf="['is_disabled','check_hi_hi','check_low_low'].includes(column)" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let alert" mat-cell style="text-align: center;">
            <mat-checkbox [(ngModel)]="alert.attributes.is_disabled"
                          [disabled]="true"
                          style="width:20px;"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="column==='users'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let alert" mat-cell class="large-cell">
            <div>
              <div *ngFor="let user of alert.relationships.users.data">{{user_dict[user.id]?.attributes.name}}</div>
            </div>
        </ng-container>

      </ng-container>


      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let element; columns: columns;" mat-row style="cursor:pointer;"></tr>

    </table>
  </div>
</div>
