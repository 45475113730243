<div style="height:100%;"
     [matTooltip]="tooltip"
     [matTooltipShowDelay]="tooltipShowDelay"
     [matTooltipDisabled]="elementForConstant!=='div'"
     [ngClass]="auto_class"
     [ngStyle]="formats?.style"
     [appFloatingInput]="show_full && (elementForConstant==='textarea' || elementForConstant==='input') && data_type==='string'"
     [FloatingInputValue]="constant_value"
     (FloatingInputValueChanged)="constantChanged($event);"
     (FloatingInputMouseLeave)="hideFull($event)"
     [appFitContent]="constant_value"
     [allowFit]="formats?.allow_fit"
     [className]="data_type"
     class="lock-indicator"
     [dblClickCopy]="disabled?constant_value:null"
>
  <i *ngIf="locked" (click)="lockClicked()" class="fa fa-lock lock-indicator-icon"
     [style.cursor]="can_unlock? 'pointer':'default'"></i>
  <date-time-picker *ngIf="elementForConstant==='date-time-picker'"
                    (date_changed)="constantChangedComplete($event);"
                    [input_date]="constant_value"
                    [disabled]="disabled"
                    (click)="$event.stopPropagation()"
                    [allowFit]="allow_fit"
  >
  </date-time-picker>

  <!--div to show full value on mouseover for disabled values (which don't have a mouseover event-->
  <div *ngIf="elementForConstant==='div'"
       class="float disabled-float">
    <div
        [hoverDelay]="500"
        (mouseIn)="mouseover=true;"
        (mouseOut)="mouseover=false;"
        [textContent]="!mouseover ? (constant_value | formatNumber:formats?.decimals:show_sig | thousandsSeparate) :
       constant_value"></div>
  </div>

  <input
      *ngIf="elementForConstant==='input'"
      (change)="changeComplete($event);"
      (ngModelChange)="constantChanged($event);"
      (focus)="is_focussed = true;"
      (focusout)="is_focussed = false;"
      [matTooltip]="(data_type==='float' && !is_focussed && !mouseover && constant_value != null)
                      ? (constant_value | formatNumber:formats?.decimals:show_sig | thousandsSeparate) : constant_value"
      [matTooltipShowDelay]="tooltipShowDelay"
      [hoverDelay]="500"
      (mouseIn)="mouseover=true;"
      (mouseOut)="mouseover=false;"
      (click)="showFull($event)"
      [ngModel]="(data_type==='float' && !is_focussed && !mouseover && constant_value != null)
                      ? (constant_value | formatNumber:formats?.decimals:show_sig | thousandsSeparate) : constant_value"
      [disabled]="disabled"
      [type]="(data_type!=='float' || !is_focussed) ?'text':'number'"
      [placeholder]="placeholder"
      [className]="data_type"
      [ngClass]="{'showing-placeholder' : !constant_value}"
      (mousewheel)="$event.preventDefault();"
      step="any"
      tabindex="1"/>

  <textarea
      *ngIf="elementForConstant==='textarea'"
      (change)="changeComplete($event);"
      (ngModelChange)="constantChanged($event);"
      (focus)="is_focussed = true;"
      (focusout)="is_focussed = false;"
      (mouseenter)="mouseover=true;"
      (mouseleave)="mouseover=false;"
      (click)="showFull($event)"
      [ngModel]="constant_value"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [className]="data_type"
      [matTooltip]="tooltip"
      [matTooltipShowDelay]="tooltipShowDelay"
      [attr.rows]="textarea_rows"
      step="any"
      matTooltipPosition="above"
      tabindex="1"></textarea>
  <div *ngIf="show_ellipsis" class="textarea-ellipsis" (click)="showFull($event)"><i class="fas fa-ellipsis"></i></div>

  <select-filter
      *ngIf="elementForConstant==='mat-select'"
      (constant_changed)="constantChangedComplete($event);"
      [options]="constant_property.attributes.json"
      [disabled]="disabled"
      [modelValue]="constant_value"
      [allow_fit]="allow_fit"
      [conditional_formats]="conditional_formats"
      [formats]="formats"
      [placeholder]="placeholder"
      [tab_index]="1"
      [classes]="'fill show-select-arrow'">
  </select-filter>
</div>
