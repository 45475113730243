import {deepCopy} from "../utils";
import {ProcessViewModel} from "./view-models/process-viewmodel";
import {StreamViewModel} from "./view-models/stream-viewmodel";
import {EquipmentViewModel} from "./view-models/equipment-viewmodel";
import {SeriesViewModel} from "./view-models/series-viewmodel";
import {ConstantViewModel} from "./view-models/constant-viewmodel";
import {CustomChartViewModel} from "./view-models/custom-chart-viewmodel";
import {OreBodyGroupViewModel} from "./view-models/ore-body-group-viewmodel";
import {ImageViewModel} from "./view-models/image-viewmodel";
import {ConnectorViewModel} from "./view-models/connector-viewmodel";
import {FlowchartData} from "../../_models/flowchart-data";
import {Process} from "../../_models/process";
import {ChartViewModelParameters} from "./types/chart-view-model-parameters";
import {ReportGroup, ReportGroups} from "./types/report-group";
import {Connector} from "../../_models/connector";
import {Equipment} from "../../_models/equipment";
import {ConstantComponent} from "../../_models/constant-component";
import {SeriesComponent} from "../../_models/series-component";
import {SeriesSummaryViewModel} from './view-models/series-summary-viewmodel';

export class ChartViewModel {
    data: FlowchartData;

    parent_process: ProcessViewModel;
    streams: any = [];

    processes: ProcessViewModel[];
    series: SeriesViewModel [] = [];
    equipment: any = [];
    custom_charts: any = [];
    ore_body_groups: any = [];
    contexts: any = [];
    config: ChartViewModelParameters;
    constants: ConstantViewModel[] = [];
    report_groups: any = {};

    _showgrid: boolean = false;
    _showpoints: boolean = false;
    _showconnectors: boolean = false;
    streammode: boolean = false;
    editmode: boolean = false;

    selectedGroups: any;

    constructor(flowchart_data: FlowchartData, config: ChartViewModelParameters) {
        this.data = flowchart_data;
        this.selectedGroups = {};
        this.config = config;

        if (this.data.parent_process == null) {
            this.parent_process = this.createParentProcess(null);
        } else {
            this.parent_process = this.createParentProcess(this.data.parent_process, this.data.connectors);
        }

        // Create a view-model for processes.
        this.processes = this.createProcessViewModels(this.data.processes, this.data.connectors);

        // Create a view-model for streams.
        this.streams = this.createStreamViewModels(this.data.streams);

        // Create a view-model for equipment.
        this.equipment = this.createEquipmentViewModels(this.data.equipment);

        // Create a view-model for series_components.
        this.series = this.createComponentViewModels(this.data.series_components, 'series_components');

        // Create a view-model for constant_components.
        this.constants = this.createComponentViewModels(this.data.constant_components, 'constant_components');

        this.report_groups = this.createReportGroups();

        // this.ore_body_groups = this.createOreBodyGroupViewModels(this.data.ore_body_groups, this.parent_process)
    }

    private createParentProcess(process: Process | null, connectors: any = []): ProcessViewModel {

        const parent_process: any = process ? process : {};

        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        const height = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        let process_model = new ProcessViewModel(parent_process, true, connectors);
        // @ts-ignore
        if (!parent_process.attributes.json.windowWidth > 0.0) {
            parent_process.attributes.json.windowWidth = width - 225;
        }
        // @ts-ignore
        if (!parent_process.attributes.json.windowHeight > 0) {
            parent_process.attributes.json.windowHeight = height - 225;
        }

        return process_model;
    }

    private createProcessViewModels(processesDataModel: Process[], connectorDataModel: Connector[]): ProcessViewModel[] {
        const processesViewModel = [];
        if (processesDataModel) {
            for (let i = 0; i < processesDataModel.length; ++i) {
                processesViewModel.push(new ProcessViewModel(processesDataModel[i], false, connectorDataModel));
            }
        }
        return processesViewModel;
    }

    private createStreamViewModel(streamDataModel): StreamViewModel | null {
        if (!streamDataModel.relationships.end_connector.data || !streamDataModel.relationships.start_connector.data) {
            window.console.log("Stream has no connectors: " + streamDataModel.attributes.name);
            return null;
        }

        const startConnector =
            this.findConnector(streamDataModel.relationships.start.data.id, streamDataModel.relationships.start_connector.data.id);
        const endConnector =
            this.findConnector(streamDataModel.relationships.end.data.id, streamDataModel.relationships.end_connector.data.id);
        if (startConnector === false || endConnector === false) {
            return null;
        }

        return new StreamViewModel(streamDataModel, startConnector, endConnector);
    }

    private createStreamViewModels(streamsDataModel): StreamViewModel[] {
        const streamsViewModel = [];

        const missing_connectors = [];

        if (streamsDataModel) {
            for (let i = 0; i < streamsDataModel.length; ++i) {
                const new_stream = this.createStreamViewModel(streamsDataModel[i]);
                if (new_stream) {
                    streamsViewModel.push(new_stream);
                } else {
                    missing_connectors.push(streamsDataModel[i].id);
                    // window.alert(streamsDataModel[i].attributes.name + ' has a missing connector');*********************************
                }

            }
        }
        if (missing_connectors.length > 0) {
            console.warn('Streams with missing connector found:', missing_connectors);
        }

        return streamsViewModel;
    }

    private createEquipmentViewModels(equipmentDataModel: Equipment[]): EquipmentViewModel[] {
        const equipmentViewModels = [];

        if (equipmentDataModel) {
            for (let i = 0; i < equipmentDataModel.length; ++i) {
                let parent_component;
                parent_component = this.findProcess(equipmentDataModel[i].relationships.component.data.id);
                if (!parent_component) {
                    parent_component = this.findStream(equipmentDataModel[i].relationships.component.data.id);
                }

                const new_equipment = new EquipmentViewModel(equipmentDataModel[i], parent_component);
                if (new_equipment) {
                    equipmentViewModels.push(new_equipment);
                }
            }
        }

        return equipmentViewModels;
    }

    // Add a series component to the data and view models.
    addConstantComponent(constantComponentDataModel, parent_process, parent_component) {
        const chart_component = this.findComponent(parent_component.id, parent_component.type);

        // Update the data model.
        this.data.constant_components.push(constantComponentDataModel);

        // Update the view model.
        const temp = this.constants.push(new ConstantViewModel(constantComponentDataModel, parent_process, chart_component));

    }

    // Add OreBodyGroup view model (after api save)
    addOreBodyGroup(ore_body_group, parent_process) {
        const oreBodyGroupViewModel = new OreBodyGroupViewModel(
            ore_body_group, parent_process, parent_process.data.attributes.json.ore_body_groups.length - 1);

        // Add to process's view model and data model.
        parent_process.ore_body_groups.push(oreBodyGroupViewModel);
        return oreBodyGroupViewModel;
    }

    // Find a specific stream within the chart.
    findStream(streamID) {

        for (let i = 0; i < this.streams.length; ++i) {
            const stream = this.streams[i];
            if (stream.data.id === streamID) {
                return stream;
            }
        }
    }

    //#region Parent svg positioning (dashboards)-----------------------------------------------------------------------
    scale(): number {
        if (!this.config.scale) {
            return 1;
        } else {
            if (this.config.scale <= 0 || this.config.scale > 500) {
                return 1;
            }
            return this.config.scale / 100;
        }
    }

    left_offset(): number {
        return this.config.left_offset || 0
    }

    top_offset(): number {
        return this.config.top_offset || 0
    }

    //#endregion Parent svg positioning---------------------------------------------------------------------------------

    //#region Toggle the connector circles------------------------------------------------------------------------------
    showconnectors() {
        this._showconnectors = true;
    }

    hideconnectors() {
        this._showconnectors = false;
    }

    toggleConnectors() {
        this._showconnectors = !this._showconnectors;
    }

    connectorsShown() {
        return this._showconnectors;
    }

    //#endregion Toggle the connector circles---------------------------------------------------------------------------

    //#region Toggle the point circles----------------------------------------------------------------------------------
    showpoints() {
        this._showpoints = true;
    }

    hidepoints() {
        this._showpoints = false;
    }

    //#endregion Toggle the point circles-------------------------------------------------------------------------------

    //#region Toggle the background grid--------------------------------------------------------------------------------
    showgrid() {
        this._showgrid = true;
    }

    hidegrid() {
        this._showgrid = false;
    }

    gridShown() {
        return this._showgrid;
    }


    //#endregion  Toggle the background grid----------------------------------------------------------------------------

    // Add Connector  view model (after api save)
    addConnector(connector, process, is_parent) {
        let connectorViewModel = new ConnectorViewModel(connector, process, is_parent);

        // Add to process's view model and data model.
        process.connectors.push(connectorViewModel);
        process.data.relationships.connectors.data.push({id: connector.id, type: 'connector'});

        connector.attributes.percent = connectorViewModel.data.attributes.percent;

        // Add connectors to data model
        this.data.connectors.push(connector);
        connectorViewModel.select();
    }

    // Add Custom chart view model (after api save)
    addCustomChart(chart, parent_process) {
        const customChartViewModel = new CustomChartViewModel(
            chart, parent_process, parent_process.data.attributes.json.charts.length - 1);

        // Add to process's view model and data model.
        parent_process.custom_charts.push(customChartViewModel);
    }

    // Add image view model (after api save)
    addImage(image, component, is_parent) {
        if (is_parent) {
            let imageViewModel = new ImageViewModel(
                image, component, component.data.attributes.json.images.length - 1);

            if (!component.images) {
                component.images = [];
            }
            // Add to process's view model and data model.
            component.images.push(imageViewModel);
        } else {
            // Add to component's view model and data model.
            component.image = new ImageViewModel(
                image, component);
            return component.image;
        }
    }

    // Add Context view model (after api save)
    addContext(context, parent_process) {
        const contextViewModel = new SeriesSummaryViewModel(
            context, parent_process, parent_process.data.attributes.json.contexts.length - 1);

        // Add to process's view model and data model.
        parent_process.contexts.push(contextViewModel);
    }

    // Create a view model for a new stream.
    createNewStream(stream, startConnector, endConnector) {

        if (!this.data.streams) {
            this.data.streams = [];
        }

        const streamViewModel = new StreamViewModel(stream, startConnector, endConnector);
        this.streams.push(streamViewModel);
        this.data.streams.push(streamViewModel.data);
    }

    // Add a process view model.
    addProcess(processDataModel) {
        // Update the data model.
        this.data.processes.push(processDataModel);

        // Update the view model.
        const new_process = new ProcessViewModel(processDataModel);
        this.processes.push(new_process);

        // Update the parent data model
        this.parent_process.data.relationships.children.data.push({id: processDataModel.id, type: 'process'});

        return new_process;
    }

    // Add a series component to the data and view models.
    addSeries(seriesComponentDataModel, parent_process, parent_component) {
        //var exists = this.findSeriesComponent(seriesComponentDataModel.id);

        //if (exists == null){
        // Update the chart data model.
        this.data.series_components.push(seriesComponentDataModel);

        // Update the chart view model.
        this.series.push(new SeriesViewModel(seriesComponentDataModel, parent_process, parent_component));

        this.report_groups = this.createReportGroups();
        //}
    }

    // Add equipment to the view model.
    addEquipment(equipmentDataModel: Equipment) {
        // Update the data model.
        this.data.equipment.push(equipmentDataModel);

        // Update the view model.
        let component_type;
        let parent_component;
        parent_component = this.findProcess(equipmentDataModel.relationships.component.data.id);
        if (!parent_component) {
            parent_component = this.findStream(equipmentDataModel.relationships.component.data.id);
        }
        parent_component.data.relationships.equipment.data.push({id: equipmentDataModel.id, type: 'equipment'});

        const new_equipment = new EquipmentViewModel(equipmentDataModel, parent_component);
        this.equipment.push(new_equipment);

        return new_equipment;

    }

    // Find specific equipment within the chart.
    findEquipment(equipmentID) {

        for (let i = 0; i < this.equipment.length; ++i) {
            const equipment = this.equipment[i];
            if (equipment.data.id === equipmentID) {
                return equipment;
            }
        }
    }

    //#endregion Adding viewModels--------------------------------------------------------------------------------------

    //#region Moving viewModels-----------------------------------------------------------------------------------------
    // Update location data for viewModel.data.attributes.json...
    updateSelectedViewModelsLocation(deltaX, deltaY, selectedViewModels) {
        for (let i = 0; i < selectedViewModels.length; ++i) {
            let viewModel = selectedViewModels[i];
            viewModel.data.attributes.json.x += deltaX;
            viewModel.data.attributes.json.y += deltaY;
        }
    }

    // Update location data for viewModel.data...
    updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedViewModels) {
        for (let i = 0; i < selectedViewModels.length; ++i) {
            let viewModel = selectedViewModels[i];
            viewModel.data.x += deltaX;
            viewModel.data.y += deltaY;
        }
    }

    // Update location data for viewModel.data.attributes.json[viewModel.tree_position]...
    updateSelectedViewModelsLocationByTree(deltaX, deltaY, selectedViewModels) {
        for (let i = 0; i < selectedViewModels.length; ++i) {
            let viewModel = selectedViewModels[i];
            viewModel.data.attributes.json[viewModel.tree_position].x += deltaX;
            viewModel.data.attributes.json[viewModel.tree_position].y += deltaY;
        }
    }

    // Update the location of the process.
    updateSelectedProcessesLocation(deltaX, deltaY) {
        let selectedProcesses = this.getSelectedProcesses();
        this.updateSelectedViewModelsLocation(deltaX, deltaY, selectedProcesses);
    }

    // Update the location of the equipment.
    updateSelectedEquipmentLocation(deltaX, deltaY) {
        let selectedEquipment = this.getSelectedEquipment();
        this.updateSelectedViewModelsLocation(deltaX, deltaY, selectedEquipment);
    }

    // Update the location of the custom chart.
    updateSelectedCustomChartsLocation(deltaX, deltaY) {
        let selectedCustomCharts = this.getSelectedCustomCharts();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedCustomCharts);
    }

    // Update the location of the orebody group.
    updateSelectedOreBodyGroupsLocation(deltaX, deltaY) {
        let selectedOreBodyGroups = this.getSelectedOreBodyGroups();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedOreBodyGroups);
    }

    // Update the location of the images.
    updateSelectedImagesLocation(deltaX, deltaY) {
        let selectedImages = this.getSelectedImages();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedImages);
    }

    // Update the location of the context.
    updateSelectedContextsLocation(deltaX, deltaY) {
        let selectedContexts = this.getSelectedContexts();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedContexts);
    }

    // Update the location of the process text.
    updateSelectedTextLocation(deltaX, deltaY) {
        let selectedText = this.getSelectedText();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedText);
    }

    // Update the location of the points.
    updateSelectedPointsLocation(deltaX, deltaY) {
        let selectedPoints = this.getSelectedPoints();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedPoints);
    }

    // Update report group table location (uses the top most series)
    updateSelectedGroupLocation(group) {
        // Set the starting location of the group based on the highest (lowest x()) series in the group
        // Set the bottom extent of the group
        let x = null;
        let y = null;
        group.show_status = false;
        if (group.series) {
            group.series.forEach(function (series) {
                if (y === null || series.y() < y) {
                    x = series.x();
                    y = series.y();
                }
                if (series.show_status() === true) {
                    group.show_status = true;
                }
                if (series.view_on_flowchart() === true) {
                    group.view_on_flowchart = true;
                }
            });
            group.x = x;
            group.y = y;
            group.xEnd = group.x + group.series[0].width();
            group.yEnd = (group.series[0].height() * group.series.length) + group.y;
        }
    }

    // Update the location of the process and its connectors.
    updateSelectedSeriesLocation(deltaX, deltaY, group) {
        let selectedSeries = this.getSelectedSeries();
        this.updateSelectedViewModelsLocationByTree(deltaX, deltaY, selectedSeries);
        if (group) {
            this.updateSelectedGroupLocation(group);
        }
    }

    // Update the location of the constant_component.
    updateSelectedConstantsLocation(deltaX, deltaY) {
        const selectedConstants = this.getSelectedConstants();
        this.updateSelectedViewModelsLocationByTree(deltaX, deltaY, selectedConstants);
    }

    // Update the location of the connectors.
    updateSelectedConnectorsLocation(deltaP) {

        const selectedConnectors = this.getSelectedConnectors();

        selectedConnectors.map(function (connector) {
            if (connector.is_parent) {
                connector.data.attributes.json.parent.percent = Number(connector.data.attributes.json.parent.percent) + deltaP;
            } else {
                connector.data.attributes.percent = Number(connector.data.attributes.percent) + deltaP;
            }
            // to prevent the circle from sticking if slightly overdragged...
            if (connector.data.attributes.percent < 0) {
                connector.data.attributes.percent = 0;
            }
            if (connector.data.attributes.percent > 100) {
                connector.data.attributes.percent = 0;
            }
            if (connector.is_parent && connector.data.attributes.json.parent.percent < 0) {
                connector.data.attributes.json.parent.percent = 0;
            }
            if (connector.is_parent && connector.data.attributes.json.parent.percent > 100) {
                connector.data.attributes.json.parent.percent = 0;
            }
        });

    }

    //#endregion  Moving viewModels-------------------------------------------------------------------------------------

    //#region Finding viewModels------------------------------------------------------------------------------
    // Find a specific process within the chart.
    findProcess(processID) {

        for (let i = 0; i < this.processes.length; ++i) {
            const process = this.processes[i];
            if (process.data.id === processID) {
                return process;
            }
        }

        if (this.parent_process.data.id === processID) {
            return this.parent_process;
        }
        // throw new Error("Failed to find process " + processID);
    }

    // Find a specific process, stream or equipment within the chart.
    findComponent(component_id, type) {
        if (type === 'process') {
            return this.findProcess(component_id);
        }
        if (type === 'stream') {
            return this.findStream(component_id);
        }
        if (type === 'equipment') {
            return this.findEquipment(component_id);
        }
        return null;
    }

    // TODO This function is out of date - images, charts, contexts
    deleteSelected() {
        const newProcessViewModels = [];
        const newProcessDataModels = [];
        const newConnectorDataModels = [];
        const newSeriesComponentDataModel = [];

        const deletedProcessIds = [];
        const deletedSeriesComponentIds = [];
        const deletedConstantComponentIds = [];

        //
        // Sort into:
        // items to keep and
        // items to delete.
        //

        // Update series models
        const newSeriesComponentViewModels = [];
        const newSeriesComponentDataModels = [];
        const newConstantComponentViewModels = [];
        const newConstantComponentDataModels = [];

        for (let seriesIndex = 0; seriesIndex < this.series.length; ++seriesIndex) {
            const series = this.series[seriesIndex];
            if (!series.selected()) {
                // Only retain non-selected series.
                newSeriesComponentViewModels.push(series);
                newSeriesComponentDataModels.push(series.data);
            } else {
                // Delete the relationship off the parent
                // let parent = this.findParentComponent(series.data.id, 'series_components');
                let parent = series.parent_component;
                let scIndex = parent.data.relationships.series_components.data.length;
                while (scIndex--) {
                    if (parent.data.relationships.series_components.data[scIndex].id === series.data.id) {
                        parent.data.relationships.series_components.data.splice(scIndex, 1);
                    }
                }
            }
        }

        for (let constantIndex = 0; constantIndex < this.constants.length; ++constantIndex) {
            const constant = this.constants[constantIndex];
            if (!constant.selected()) {
                // Only retain non-selected series.
                newConstantComponentViewModels.push(constant);
                newConstantComponentDataModels.push(constant.data);
            } else {
                // Delete the relationship off the parent
                // let parent = this.findParentComponent(constant.data.id, 'constant_components');
                let parent = constant.parent_component;
                let ccIndex = parent.data.relationships.constant_components.data.length;
                while (ccIndex--) {
                    if (parent.data.relationships.constant_components.data[ccIndex].id == constant.data.id) {
                        parent.data.relationships.constant_components.data.splice(ccIndex, 1);
                    }
                }
            }
        }

        // Update process models
        for (let processIndex = 0; processIndex < this.processes.length; ++processIndex) {
            const process = this.processes[processIndex];
            let newProcessConnectorsDataModel = [];
            let newProcessConnectorsViewModel = [];
            if (!process.selected()) {
                for (let connectorIndex = 0; connectorIndex < process.connectors.length; ++connectorIndex) {
                    let connector = process.connectors[connectorIndex];

                    if (!connector.selected() || connector.data.relationships.input_stream.data !== null ||
                        connector.data.relationships.output_stream.data !== null) {
                        newProcessConnectorsViewModel.push(connector);
                        newProcessConnectorsDataModel.push({id: connector.data.id, type: 'connector'});
                        newConnectorDataModels.push(connector.data);
                    }
                }
                process.connectors = newProcessConnectorsViewModel;
                process.data.relationships.connectors.data = newProcessConnectorsDataModel;

                // Only retain non-selected processes.
                newProcessViewModels.push(process);
                newProcessDataModels.push(process.data);
            } else {
                // Keep track of processes that were deleted, so their streams can also
                // be deleted.
                process.data.relationships.series_components?.data.map(function (sc) {
                    deletedSeriesComponentIds.push(sc.id);
                });
                process.data.relationships.constant_components?.data.map(function (cc) {
                    deletedConstantComponentIds.push(cc.id);
                });
                deletedProcessIds.push(process.data.id);
            }
        }

        let newProcessConnectorsDataModel = [];
        let newProcessConnectorsViewModel = [];

        for (let connectorIndex = 0; connectorIndex < this.parent_process.connectors.length; ++connectorIndex) {
            let connector = this.parent_process.connectors[connectorIndex];

            if (!connector.selected() || connector.data.relationships.input_stream.data !== null ||
                connector.data.relationships.output_stream.data !== null) {
                newProcessConnectorsViewModel.push(connector);
                newProcessConnectorsDataModel.push({id: connector.data.id, type: 'connector'});
                newConnectorDataModels.push(connector.data);
            }
        }
        this.parent_process.connectors = newProcessConnectorsViewModel;
        this.parent_process.data.relationships.connectors.data = newProcessConnectorsDataModel;

        const newStreamViewModels = [];
        const newStreamDataModels = [];

        // Remove streams that are selected.
        // Also remove streams for processes that have been deleted.
        // Remove selected points (for streams that have more than 2 points?)
        for (let streamIndex = 0; streamIndex < this.streams.length; ++streamIndex) {
            const stream = this.streams[streamIndex];
            const newPointsViewModel = [];
            const newPointsDataModel = [];

            if (!stream.selected() &&
                deletedProcessIds.indexOf(stream.data.relationships.start.data.id) === -1 &&
                deletedProcessIds.indexOf(stream.data.relationships.end.data.id) === -1) {

                for (let pointIndex = 0; pointIndex < stream.points.length; ++pointIndex) {
                    const point = stream.points[pointIndex];
                    if (!point.selected()) {
                        newPointsViewModel.push(point);
                        newPointsDataModel.push(point.data);
                    }
                }
                stream.points = newPointsViewModel;
                stream.data.attributes.json.points = newPointsDataModel;

                //
                // The processes this stream is attached to, were not deleted,
                // so keep the stream.
                //
                newStreamViewModels.push(stream);
                newStreamDataModels.push(stream.data);
            } else {
                // Add to list of series_components to delete
                stream.data.relationships.series_components.data.map(function (sc) {
                    deletedSeriesComponentIds.push(sc.id);
                });
                stream.data.relationships.constant_components.data.map(function (cc) {
                    deletedConstantComponentIds.push(cc.id);
                });

                // Update connectors for input output streams
                this.updateStreamConnectors(stream);

                // Update process data model for deleted streams
                this.updateProcessesStreams(stream, newProcessDataModels);

            }
        }

        // Update equipment models
        const newEquipmentViewModels = [];
        const newEquipmentDataModels = [];

        for (let equipmentIndex = 0; equipmentIndex < this.equipment.length; ++equipmentIndex) {
            const equipment = this.equipment[equipmentIndex];
            if (!equipment.selected()) {
                // Only retain non-selected equipment.
                newEquipmentViewModels.push(equipment);
                newEquipmentDataModels.push(equipment.data);
            } else {
                // Add to list of series_components to delete
                equipment.parent_component.data.relationships.equipment.data =
                    equipment.parent_component.data.relationships.equipment.data.filter(function (item) {
                        return item.id !== equipment.data.id;
                    });
                equipment.data.relationships.series_components.data.map(function (sc) {
                    deletedSeriesComponentIds.push(sc.id);
                });
                equipment.data.relationships.constant_components.data.map(function (cc) {
                    deletedSeriesComponentIds.push(cc.id);
                });
            }
        }

        // Update parent data model
        if (deletedProcessIds.length > 0) {
            const newProcessChildrenDataModels = [];
            for (let childIndex = 0; childIndex < newProcessDataModels.length; ++childIndex) {
                const child = {id: newProcessDataModels[childIndex].id, type: "process"};
                newProcessChildrenDataModels.push(child);
            }
            this.parent_process.data.relationships.children.data = newProcessChildrenDataModels;
        }

        // Update processes, connectors and streams.
        this.data.series_components = newSeriesComponentDataModels;
        this.series = newSeriesComponentViewModels;
        this.deleteSeriesComponents(deletedSeriesComponentIds);
        // this.report_groups = this.createReportGroups(); - called in deleteSeriesComponents

        this.data.constant_components = newConstantComponentDataModels;
        this.constants = newConstantComponentViewModels;
        this.deleteConstantComponents(deletedConstantComponentIds);

        this.data.connectors = newConnectorDataModels;
        this.processes = newProcessViewModels;
        this.data.processes = newProcessDataModels;
        this.streams = newStreamViewModels;
        this.data.streams = newStreamDataModels;
        this.data.equipment = newEquipmentDataModels;
        this.equipment = newEquipmentViewModels;

    }

    // Find a specific input connector within the chart.
    findConnector(processID, connectorID) {

        const process = this.findProcess(processID);

        for (let i = 0; i < process.connectors.length; ++i) {
            const connector = process.connectors[i];
            if (connector.data.id === connectorID) {
                return connector;
            }
        }

        return false;
    }

    // Find a specific component, parent to series_component, within the chart.
    findParentComponent(child_component_id, type) {
        let component;
        let process = this.parent_process;
        process.data.relationships[type].data.forEach(function (child_component) {
            if (child_component.id === child_component_id) {
                component = process;
            }
        });
        for (let i = 0; i < this.processes.length; ++i) {
            let process = this.processes[i];
            process.data.relationships[type].data.forEach(function (child_component) {
                if (child_component.id === child_component_id) {
                    component = process;
                }
            });
        }
        for (let i = 0; i < this.streams.length; ++i) {
            const stream = this.streams[i];
            stream.data.relationships[type].data.forEach(function (child_component) {
                if (child_component.id === child_component_id) {
                    component = stream;
                }
            });
        }
        for (let i = 0; i < this.equipment.length; ++i) {
            const equipment = this.equipment[i];
            equipment.data.relationships[type].data.forEach(function (child_component) {
                if (child_component.id === child_component_id) {
                    component = equipment;
                }
            });
        }
        return component;
    }

    private createComponentViewModels(componentsDataModel: SeriesComponent[], type: 'series_components'): SeriesViewModel[] ;
    private createComponentViewModels(componentsDataModel: ConstantComponent [], type: 'constant_components'): ConstantViewModel[] ;
    private createComponentViewModels(componentsDataModel: (ConstantComponent | SeriesComponent)[], type: 'series_components' | 'constant_components'): (SeriesViewModel | ConstantViewModel)[] {
        // For series_components, constant_components
        const componentViewModels: (SeriesViewModel | ConstantViewModel)[] = [];
        if (componentsDataModel) {
            componentsDataModel.forEach(model => {
                const parent_component =
                    this.findComponent(model.relationships['component'].data.id, model.relationships['component'].data.type);
                let newComponentModel;
                if (type === 'series_components') {
                    newComponentModel = new SeriesViewModel(model, this.parent_process, parent_component);
                }
                if (type === 'constant_components') {
                    newComponentModel = new ConstantViewModel(model, this.parent_process, parent_component);
                }
                if (newComponentModel) {
                    componentViewModels.push(newComponentModel);
                }
            });
        }

        return componentViewModels;
    }

    // Find a specific series_component within the chart.
    findSeriesComponent(series_componentID) {

        for (let i = 0; i < this.series.length; ++i) {
            const series_component = this.series[i];
            if (series_component.data.id === series_componentID) {
                return series_component;
            }
        }
    }

    //#endregion Finding viewModels--------------------------------------------------------------------------------

    //#region Getting selected viewModels----------------------------------------------------------------------
    // Get the array of processes that are currently selected.
    getSelectedProcesses() {
        return this.getSelectedViewModels(this.processes);
    }

    // Get the array of series that are currently selected.
    getSelectedSeries() {
        return this.getSelectedViewModels(this.series);
    }

    // Get the array of series that are currently selected.
    getSelectedConstants() {
        return this.getSelectedViewModels(this.constants);
    }

    // Get the array of equipment that are currently selected.
    getSelectedEquipment() {
        return this.getSelectedViewModels(this.equipment);
    }

    // Get the array of custom charts that are currently selected.
    getSelectedCustomCharts() {
        return this.getSelectedViewModels(this.parent_process.custom_charts);
    }

    // Get the array of orebody groups that are currently selected.
    getSelectedOreBodyGroups() {
        return this.getSelectedViewModels(this.parent_process.ore_body_groups);
    }

    // Get the array of custom charts that are currently selected.
    getSelectedImages() {
        let selectedImages = this.getSelectedViewModels(this.parent_process.images);
        for (let i = 0; i < this.processes.length; ++i) {
            const process = this.processes[i];
            if (process.image && process.image.selected()) {
                selectedImages.push(process.image);
            }
        }
        return selectedImages;
    }

    // Get the array of custom charts that are currently selected.
    getSelectedContexts() {
        return this.getSelectedViewModels(this.parent_process.contexts);
    }

    getSelectedText() {
        const selectedText = [];

        for (let i = 0; i < this.processes.length; ++i) {
            const process = this.processes[i];
            const processtext = this.processes[i].text;
            if (processtext.selected()) {
                selectedText.push(processtext);
            }
            for (let c = 0; c < process.connectors.length; ++c) {
                const connectortext = process.connectors[c].text;
                if (connectortext.selected()) {
                    selectedText.push(connectortext);
                }
            }
        }
        for (let i = 0; i < this.streams.length; ++i) {
            const streamtext = this.streams[i].text;
            if (streamtext.selected()) {
                selectedText.push(streamtext);
            }
        }
        for (let i = 0; i < this.equipment.length; ++i) {
            const equipmenttext = this.equipment[i].text;
            if (equipmenttext.selected()) {
                selectedText.push(equipmenttext);
            }
        }

        return selectedText;
    }

    // Get the array of points that are currently selected.
    getSelectedPoints() {
        const selectedPoints = [];
        for (let i = 0; i < this.streams.length; ++i) {
            const stream = this.streams[i];
            stream.points.map(function (point) {
                if (point.selected()) {
                    selectedPoints.push(point);
                }
            });
        }
        return selectedPoints;
    }

    // Get the array of connectors that are currently selected.
    getSelectedConnectors() {
        const selectedConnectors = [];
        for (let i = 0; i < this.processes.length; ++i) {
            const process = this.processes[i];
            process.connectors.map(function (connector) {
                if (connector.selected()) {
                    selectedConnectors.push(connector);
                }
            });
        }
        const parent_process = this.parent_process;
        parent_process.connectors.map(function (connector) {
            if (connector.selected()) {
                selectedConnectors.push(connector);
            }
        });
        return selectedConnectors;
    }

    // Get the array of streams that are currently selected.
    getSelectedStreams() {
        return this.getSelectedViewModels(this.streams);
    }

    // Generic function got getSelected - should replace std getSelected functions
    getSelectedViewModels(parent_array) {
        const selectedViewModels = [];
        for (let i = 0; i < parent_array.length; ++i) {
            const viewModel = parent_array[i];
            if (viewModel.selected()) {
                selectedViewModels.push(viewModel);
            }
        }
        return selectedViewModels;
    }

    getSelected() {
        const processes = this.getSelectedProcesses();
        const streams = this.getSelectedStreams();
        const equipment = this.getSelectedEquipment();

        // let connectors = this.chartViewModel.getSelectedConnectors().map(deleteItem);
        // let series_components = this.chartViewModel.getSelectedSeries().map(deleteItem);
        return processes.concat(streams.concat(equipment));
    }

    // Select all processes and streams in the chart.
    selectAll() {
        const processes = this.processes;
        for (let i = 0; i < processes.length; ++i) {
            const process = processes[i];
            process.select();
        }

        const streams = this.streams;
        for (let i = 0; i < streams.length; ++i) {
            const stream = streams[i];
            stream.select();
            stream.points.map(function (point) {
                point.selected();
            });
            stream.text.select();
        }
    }

    //
    // Deselect all processes and streams in the chart.
    //
    deselectAll() {
        this.processes.map(function (process) {
            process.deselect();
            process.text.deselect();
            process.unhighlight();
            if (process.image) {
                process.image.deselect();
            }
            process.connectors.map(function (connector) {
                connector.deselect();
                connector.text.deselect();
            });
        });

        const streams = this.streams;
        for (let i = 0; i < streams.length; ++i) {
            const stream = streams[i];
            stream.deselect();
            stream.text.deselect();
            stream.unhighlight();
            stream.points.map(function (point) {
                point.deselect();
            });
        }

        const equipments = this.equipment;
        for (let i = 0; i < equipments.length; ++i) {
            const equipment = equipments[i];
            equipment.deselect();
            equipment.text.deselect();
        }

        for (let i = 0; i < this.series.length; ++i) {
            const series = this.series[i];
            series.deselect();
            // series.text.deselect();
        }
        for (let i = 0; i < this.constants.length; ++i) {
            const constant = this.constants[i];
            constant.deselect();
        }

        this.selectedGroups = {};
        this.parent_process.connectors.map(function (connector) {
            connector.deselect();
            connector.text.deselect();
        });
        this.parent_process.custom_charts.map(function (chart) {
            chart.deselect();
        });
        this.parent_process.ore_body_groups.map(function (ore_body_group) {
            ore_body_group.deselect();
        });
        if (this.parent_process.images) {
            this.parent_process.images.map(function (image) {
                image.deselect();
            });
        }
        this.parent_process.contexts.map(function (context) {
            context.deselect();
        });
    }

    //#endregion Get selected viewModels--------------------------------------------------------------------------------

    //#region Apply, update and delete selected-------------------------------------------------------------------------

    // Delete all processes and streams that are selected.

    private createReportGroups(): ReportGroups {
        let report_groups: { [key: string]: ReportGroup } = {};
        this.series.forEach(item => {
            if (!item.parent_component) {
                console.log("WARNING: The following series has no parent: ", item);
                return;
            }
            if (report_groups.hasOwnProperty(item.parent_component.data.id + "_" + item.data.attributes.report_group)) {
                report_groups[item.parent_component.data.id + "_" + item.data.attributes.report_group].series.push(item);
            } else {
                if (item.data.attributes.report_group === '') {
                    item.data.attributes.report_group = null;
                }
                report_groups[item.parent_component.data.id + "_" + item.data.attributes.report_group] = {
                    series: [item],
                    name: item.data.attributes.report_group,
                    component_id: item.parent_component.data.id,
                    x: item.x(),
                    y: item.y()
                };
            }
        });

        let parent = deepCopy(this);
        Object.keys(report_groups).forEach(function (key) {
            let group = report_groups[key];
            parent.updateSelectedGroupLocation(group);
        });
        return report_groups;
    }

    // Update stream info on connectorViewModel after deleting a stream
    updateStreamConnectors(stream) {
        const endConnector = this.findConnector(stream.data.relationships.end.data.id, stream.data.relationships.end_connector.data.id);
        const startConnector =
            this.findConnector(stream.data.relationships.start.data.id, stream.data.relationships.start_connector.data.id);

        // Update the connectors for stream input output rules
        startConnector.data.relationships.output_stream.data = null;
        endConnector.data.relationships.input_stream.data = null;

    }

    // Update process data model after stream is deleted
    updateProcessesStreams(stream, newProcesses) {
        for (let processIndex = 0; processIndex < newProcesses.length; ++processIndex) {
            const process = newProcesses[processIndex];
            for (let index = 0; index < process.relationships.output_streams.data.length; ++index) {
                let processStream = process.relationships.output_streams.data[index];
                if (stream.data.id === processStream.id) {
                    process.relationships.output_streams.data.splice(index, 1);
                }
            }
            for (let index = 0; index < process.relationships.input_streams.data.length; ++index) {
                let processStream = process.relationships.input_streams.data[index];
                if (stream.data.id === processStream.id) {
                    process.relationships.input_streams.data.splice(index, 1);
                }
            }
        }
    }

    deleteSeriesComponents(deleted_ids) {
        for (let deletedIndex = 0; deletedIndex < deleted_ids.length; ++deletedIndex) {
            const deleted_id = deleted_ids[deletedIndex];
            // Remove from data model
            for (let seriesIndex = 0; seriesIndex < this.data.series_components.length; ++seriesIndex) {
                if (this.data.series_components[seriesIndex].id === deleted_id) {
                    this.data.series_components.splice(seriesIndex, 1);
                    break;
                }
            }
            // Remove from view model
            for (let seriesIndex = 0; seriesIndex < this.series.length; ++seriesIndex) {
                if (this.series[seriesIndex].data.id === deleted_id) {
                    this.series.splice(seriesIndex, 1);
                    break;
                }
            }
        }

        this.report_groups = this.createReportGroups();
    }

    deleteConstantComponents(deleted_ids) {
        for (let deletedIndex = 0; deletedIndex < deleted_ids.length; ++deletedIndex) {
            const deleted_id = deleted_ids[deletedIndex];
            // Remove from data model
            for (let constantIndex = 0; constantIndex < this.data.constant_components.length; ++constantIndex) {

                if (this.data.constant_components[constantIndex].id === deleted_id) {
                    this.data.constant_components.splice(constantIndex, 1);
                    break;
                }
            }
            // Remove from view model
            for (let constantIndex = 0; constantIndex < this.constants.length; ++constantIndex) {
                if (this.constants[constantIndex].data.id === deleted_id) {
                    this.constants.splice(constantIndex, 1);
                    break;
                }
            }
        }
    }

    // Generic function to replace separate applySelectionRect sections
    getSelectedRect(selectionRect, parent_array) {

        for (let i = 0; i < parent_array.length; ++i) {
            let viewModel = parent_array[i];
            if (viewModel.x() >= selectionRect.x &&
                viewModel.y() >= selectionRect.y &&
                viewModel.x() + viewModel.width() <= selectionRect.x + selectionRect.width &&
                viewModel.y() + viewModel.height() <= selectionRect.y + selectionRect.height) {
                // Select processes that are within the selection rect.
                viewModel.select();
            }
        }
    }

    // Select viewModels that fall within the selection rect.
    applySelectionRect(selectionRect) {
        this.deselectAll();

        for (let i = 0; i < this.processes.length; ++i) {
            let process = this.processes[i];
            if (process.x() >= selectionRect.x &&
                process.y() >= selectionRect.y &&
                process.x() + process.width() <= selectionRect.x + selectionRect.width &&
                process.y() + process.height() <= selectionRect.y + selectionRect.height) {
                // Select processes that are within the selection rect.
                process.select();
            }
            if (process.image) {
                this.getSelectedRect(selectionRect, [process.image]);
            }
        }

        for (let i = 0; i < this.streams.length; ++i) {
            let stream = this.streams[i];
            if (stream.start.parentProcess().selected() &&
                stream.end.parentProcess().selected()) {
                // Select the stream if both its parent processes are selected.
                stream.select();
                stream.text.select();
            }

            stream.points.map(function (point) {

                if (point.x() >= selectionRect.x &&
                    point.y() >= selectionRect.y &&
                    point.x() <= selectionRect.x + selectionRect.width &&
                    point.y() <= selectionRect.y + selectionRect.height) {
                    point.select();
                }

            });
            if (stream.text.x() >= selectionRect.x &&
                stream.text.y() >= selectionRect.y &&
                stream.text.x() <= selectionRect.x + selectionRect.width &&
                stream.text.y() <= selectionRect.y + selectionRect.height) {
                // Select processes that are within the selection rect.
                stream.text.select();
            }
        }

        this.getSelectedRect(selectionRect, this.equipment);

        this.getSelectedRect(selectionRect, this.parent_process.custom_charts);

        this.getSelectedRect(selectionRect, this.parent_process.ore_body_groups);

        if (this.parent_process.images) {
            this.getSelectedRect(selectionRect, this.parent_process.images);
        }

        this.getSelectedRect(selectionRect, this.parent_process.contexts);

        this.getSelectedRect(selectionRect, this.constants);

        this.getSelectedRect(selectionRect, this.series);

        let report_groups = this.report_groups;
        this.selectedGroups = {};
        let selectedGroups = this.selectedGroups;
        Object.keys(report_groups).forEach(function (key) {
            let group = report_groups[key];
            if (group.series.length > 1 && group.name !== null) {
                // Deselect all individual series that may have fallen into the selection rectangle
                for (let i = 0; i < group.series.length; ++i) {
                    let series = group.series[i];
                    series.deselect();
                }
                if (group.x >= selectionRect.x &&
                    group.y >= selectionRect.y &&
                    group.xEnd <= selectionRect.x + selectionRect.width &&
                    group.yEnd <= selectionRect.y + selectionRect.height) {
                    // Select all series within the group.
                    for (let i = 0; i < group.series.length; ++i) {
                        let series = group.series[i];
                        // Select all series within the group.
                        series.select();
                    }
                    // And indicate the group as selected
                    selectedGroups[group.name] = true;
                }
            }
        });
        let all = this.getSelected();
        if (all.length < 1) {
            all = [this.parent_process];
        }
        // TODO use EventEmitter
    }

    // #endregion Apply, update and delete selected----------------------------------------------------------------------
}
