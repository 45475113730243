import {Injectable} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {Field, QueryBuilderConfigType, Rule} from "../_typing/query-builder";

@Injectable({
    providedIn: 'root'
})
export class QueryBuilderFormService {
    defaultRuleChanged: ReplaySubject<Rule> = new ReplaySubject(1);

    operatorMap = {
        string: ['ilike', 'not ilike', 'like', 'not like', '=', '!=', 'blank', 'not blank'],
        number: ['=', '!=', '>', '>=', '<', '<=', 'blank', 'not blank'],
        time: ['=', '!=', '>', '>=', '<', '<=', 'blank', 'not blank'],
        date: ['=', '!=', '>', '>=', '<', '<=', 'blank', 'not blank'],
        datetime: ['>', '>=', '<', '<=', '=', '!=', 'blank', 'not blank'],
        category: ['=', '!=', 'in', 'not in', 'blank', 'not blank'],
        boolean: ['=', '!=', 'blank', 'not blank'],
        dropdown: ['=', '!=', 'blank', 'not blank']
    };
    operatorDesc = {
        '=': 'Equals to',
        '!=': 'Not equal to',
        '>': 'Greater than',
        '<': 'Less than',
        '>=': 'Greater than or equal to',
        '<=': 'Less than or equal to',
        'like': 'Contains (case sensitive)',
        'ilike': 'Contains (case insensitive)',
        'not like': 'Does not contain (case sensitive)',
        'not ilike': 'Does not contain (case insensitive)',
        'in': 'Contains',
        'not in': 'Does Not Contain',
        'blank': 'Must be blank',
        'not blank': 'Must not be blank'
    };

    constructor() {
    }
}
