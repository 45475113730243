import {Component, Input, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {CustomDialogComponent} from "../../../forms/custom-dialog/custom-dialog.component";
import {AuditMixin} from "../../../_models/audit-mixin";
import {FormDialogService} from "../../../services/form-dialog.service";

@Component({
    selector: 'audit-mixin-indicator',
    templateUrl: './audit-mixin-indicator.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./audit-mixin-indicator.component.less'],
    standalone: false
})
export class AuditMixinIndicatorComponent implements OnInit {
    @Input() model: AuditMixin & { id: string | number; type: string };

    constructor(private formDialogService: FormDialogService) {
    }

    ngOnInit(): void {
    }

    openModal(event: any) {
        const config = {
            component: 'AuditMixinComponent',
            parameters: {
                model: this.model
            }
        }

        const panelClass = 'audit-mixin-dialog';
        this.formDialogService.openCustomDialog(event, config, panelClass)
    }
}
