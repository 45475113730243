<div class="sidenav" tabindex="1" [ngClass]="{'edit-mode': editMode}">
  <div class="search-container">
    <input (input)="searchSessionStates()" [(ngModel)]="session_search" class="process-search"
           matTooltip="Search pages by name" placeholder="Search pages..."/>
    <mat-icon (click)="session_search='';searchSessionStates()"
              [ngClass]="{'clear-disabled': session_search.length < 1}"
              class="process-search-clear">clear
    </mat-icon>
  </div>
  <ng-container *ngFor="let item of session_results">
    <li class="mat-tree-node menu-node"> <!--session state-->
      <a [routerLink]="item.attributes.url"
         (click)="closeSidenav()">{{item.attributes.name}}</a>
    </li>
  </ng-container>
  <mat-divider *ngIf="session_results && session_results.length > 0" style="margin:10px;"></mat-divider>

  <static-menu-tree *ngIf="pinnedItems" [staticFolders]="pinnedItems"></static-menu-tree>

  <dragdrop-tree *ngIf="topLevelFolderNode" [node]="topLevelFolderNode"></dragdrop-tree>

  <static-menu-tree *ngIf="staticFolders" [staticFolders]="staticFolders"></static-menu-tree>


  <div (click)="show_plant = !show_plant;" *ngIf="appScope.isNotMobile && !restricted"
       class="mat-tree-node parent-folder-node static">
    <button class="folder-toggle static-folder">
      <i [ngClass]="{'chevron-up': show_plant , 'chevron-down' : !show_plant }"
         class="chevron-down" role="button"></i>
      <span>Plant Menu</span>
    </button>
  </div>

  <mat-list *ngIf="show_plant && !restricted" class="sidetree" style="overflow-y: visible;">
    <div class="search-container">
      <input (input)="searchProcesses()" [(ngModel)]="process_search" class="process-search"
             matTooltip="Please enter at least 3 characters" placeholder="search processes..."/>
      <mat-icon (click)="process_search='';searchProcesses()" [ngClass]="{'clear-disabled': process_search.length < 1}"
                class="process-search-clear">clear
      </mat-icon>
    </div>

    <ng-container *ngFor="let item of search_results">
      <treenode [item]="item" class="search-result-node"></treenode>
    </ng-container>
    <mat-divider *ngIf="search_results && search_results.length > 0" style="margin:10px;"></mat-divider>

    <ng-container *ngIf="!appScope.isNotMobile">
      <mat-list-item *ngFor="let item of appScope.mobile_sidenav" class="mobile_sidenav">
        <a *ngIf="item.angular_page && item.link" [routerLink]="item.link">{{item.name}}</a>
      </mat-list-item>
    </ng-container>

    <ng-container *ngIf="plantData.process_focus?.id">
      <div class="row active-account-row">
        <treenode [item]="appScope.processTreeDataDict[plantData.process_focus.id]"
                  class="process-focus-node"></treenode>
        <div class="active-account-indicator">Active</div>
      </div>
    </ng-container>

    <ng-container *ngIf="selected_plant">
      <treenode [item]="appScope.processTreeDataDict[selected_plant.id]" class="selected-process-node"></treenode>
    </ng-container>

    <ng-container *ngIf="reports">
      <ng-container *ngFor="let item of reports">
        <treenode [item]="appScope.processTreeDataDict[item.id]" class="report-node"></treenode>
      </ng-container>
    </ng-container>
  </mat-list>
</div>