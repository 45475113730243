import {Component, Input} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {take, takeUntil, tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SolverTemplate} from "../../_models/solver";
import {SeriesType} from "../../_models/series-type";
import {BackgroundJobService, JobPollResponse} from "../../services/background-job.service";
import {getManyRelationWithIdFilter} from "../../services/api/filter_utils";
import {httpParamSerializer} from "../../lib/utils";
import {BaseComponent} from "../../shared/base.component";
import {ModelName, KeyMap, ModelID} from '../../_typing/generic-types';
import {DateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";


export interface RunSolverResponse {
    message: string;
    job: string;
}

export interface SolverRunData {
    name: string;
    description: string;
    measured: number;
    error: number;
    adjusted: number;
}

export interface SolverRunResponse extends JobPollResponse {
    data?: SolverRunOutput;
}

export interface SolverRunOutput {
    data: KeyMap<SolverRunData>;
    result: { series_id: ModelID, value: number }[];
}

@Component({
    selector: 'solver-runner',
    templateUrl: './solver-runner.component.html',
    styleUrls: ['./solver-runner.component.less'],
    standalone: false
})
export class SolverRunnerComponent extends BaseComponent {
    dtp: DateTimePeriod;
    @Input() solverTemplate: SolverTemplate;
    adjustedSeriesType: SeriesType;
    solverRunID: string;
    displayData: SolverRunOutput;  // measured series names mapped to their solved data
    adjustedTypesFilter: any[];
    status: string;
    running = false;

    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private dateInst: DateTimeInstanceService,
        private jobService: BackgroundJobService
    ) {
        super();
    }

    ngOnInit() {
        this.dtp = this.dateInst.dtp;
    }

    onSelectSolverTemplate(event) {
        this.solverTemplate = event.value;
        this.adjustedTypesFilter = [getManyRelationWithIdFilter('solver_template', this.solverTemplate.id)];
    }

    runSolver() {
        this.displayData = null;
        this.status = null;
        const solver_data = {
            start: this.dtp.start.toISOString(),
            end: this.dtp.end.toISOString(),
            solver_template: this.solverTemplate.id,
            adjusted_series_type: this.adjustedSeriesType.id
        };

        let runSolverURL = this.http.get('api/RunSolver?' + httpParamSerializer(solver_data));
        runSolverURL.pipe(takeUntil(this.onDestroy))
            .subscribe((result: RunSolverResponse) => {
                this.snackBar.open(result.message + '. Running solver...', 'hide', {duration: 100000});
                this.solverRunID = result.job;
                this.running = true;
                this.pollSolverRun();
            });
    }

    pollSolverRun() {
        this.jobService.createJobPoll(this.solverRunID)
            .pipe(takeUntil(this.onDestroy))
            .subscribe((completedJob: SolverRunResponse) => {
                if (completedJob.status) {
                    // Successful run
                    this.snackBar.open('Solver run successful', 'hide', {duration: 10000});
                    this.displayData = completedJob.data;
                    this.running = false;
                } else {
                    if (completedJob.processing) {
                        console.log('Job ' + this.solverRunID + ': ' + completedJob.message);
                        return;
                    }
                    this.running = false;
                    this.snackBar.open('Error running solver: ' + completedJob.message, 'hide', {duration: 10000});
                }
            });
    }

    saveSolvedData() {
        this.http.get('/api/SaveSolver?solver_run=' + this.solverRunID).pipe(take(1)).subscribe({
            next: (resp: { message: string }) => {
                this.snackBar.open(resp.message);
            }
        });
    }
}
