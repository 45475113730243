import {Job, JobAttributes} from "./job";
import {ConfigStub} from "../../_typing/config-stub";
import {Series} from "../series";
import {ModelID} from '../../_typing/generic-types';
import {ComponentType} from "../component-type";

export abstract class SeriesCorrectionBase {
    series_id: string;
    target_series_id?: string;
    comment?: string;
    abstract adjustment_type: string;
}

export class SeriesConstantCorrection extends SeriesCorrectionBase {
    adjustment_type: "constant";
    value: number;
}

export class SeriesFactorCorrection extends SeriesCorrectionBase {
    adjustment_type: string = "factor";
    target_value: number;
    factor: number;
    offset?: number;
}

export class SeriesFindFactorCorrection extends SeriesCorrectionBase {
    adjustment_type: string = "find_factor";
    target_value: number;
    offset?: number;
}

export type SeriesAdjustmentRequest = (SeriesConstantCorrection | SeriesFactorCorrection | SeriesFindFactorCorrection);

export class SeriesCorrectionInputData {
    start_time: string;
    end_time: string;
    series_to_adjust: SeriesAdjustmentRequest[];
    update_dependent_calculations: boolean;
    user_id: string;
}

export type SeriesCorrectionOutputData = SeriesCorrectionOutputCompleted | SeriesCorrectionOutputError;

export class SeriesCorrectionOutputBase {
    message: string;
}

export interface ICorrectedSeriesReport {
    series_id: ModelID;
    update_count: number;
    correction_factor?: number;
    series_value?: number;
}

export class SeriesCorrectionOutputCompleted extends SeriesCorrectionOutputBase {
    corrected: ICorrectedSeriesReport[];
    updated_series: ModelID[];
}

export class SeriesCorrectionOutputError extends SeriesCorrectionOutputBase {
    code: number;
}

export function isCompletedOutput(object): object is SeriesCorrectionOutputCompleted {
    return object?.hasOwnProperty("corrected");
}

/*{export type SeriesCorrectionOutputData = { code: number, message: string }[];/*{
    // TODO add spec for expected output repotr format
}*/

export class SeriesCorrectionJobAttributes extends JobAttributes<SeriesCorrectionInputData, SeriesCorrectionOutputData> {
    type: string = 'series_correction';
}

export class SeriesCorrectionJob extends Job<SeriesCorrectionInputData, SeriesCorrectionOutputData, SeriesCorrectionJobAttributes> {
}

export const seriesAdjustmentTypeArray = ['find_factor'] as const;
export type SeriesAdjustmentType = typeof seriesAdjustmentTypeArray[number];

interface ISeriesAdjustmentConfig {
    adjustments: SeriesAdjustmentConfigAdjustment[];
}

export class SeriesAdjustmentConfigAdjustment {
    target_series: ConfigStub<Series> = null;
    adjust: ConfigStub<Series> = null;
    adjustment_type: SeriesAdjustmentType = 'find_factor';

    constructor() {
    }

}

export type SeriesAdjustmentConfig = Partial<ISeriesAdjustmentConfig>;
