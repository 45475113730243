<div class="comments-table-form">
  <ng-container *ngIf="config.event_type">
    <div class="row center" *ngIf="base_type==='event'">
      <select-many-search-component [value]="config.process_list"
                                    [api_model]="'process_light'"
                                    (selectionChange)="updateProcesses($event)"
                                    [label]="'Select a process'"
                                    name="process-list"
                                    class="select-search select-many-search-wrapper">
      </select-many-search-component>

      <div class="form-element-align"
           style="text-align: center;font-weight: bold;padding:0 10px; justify-self: center;">and<br>or
      </div>
      <div (mouseenter)="hint='Series'" class="select-search select-many-search-wrapper">
        <select-many-series [value]="config.series_list"
                            (selectionChange)="updateSeries($event)"
                            [label]="'Select series'"
                            [classes]="'fill'"
                            name="series-list"
        >
        </select-many-series>
      </div>

    </div>
    <div class="row center page-select-row" *ngIf="base_type==='comment'">
      <select-many-pages (selectionChange)="updatePages($event)"
                         [value]="config.session_state_list"
                         [label]="'Select session states'"
                         [filter_by]="['name']"
                         [classes]="'fill'"
                         class="select-many-search-wrapper flex-half"
                         name="session-state-list">
      </select-many-pages>
      <div class="row center flex-half">
        <mat-checkbox [checked]="isCurrentPageSelected()" (change)="updateCurrentSessionState($event)"
                      class="form-element-align">Select current page
        </mat-checkbox>
        <mat-checkbox (mouseenter)="hint='show search input'" [(ngModel)]="config.search"
                      name="Show search input" class="form-element-align">Show search
        </mat-checkbox>
      </div>
    </div>
    <div class="row center medium-height">
      <div class=" flex-half alone">
        <mat-checkbox [(ngModel)]="config.match_range" (change)="updateFilterConfig($event, 'match_range')">Match range
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="config.filter_by_created_on" (change)="updateFilterConfig($event)">Filter by Created
          On
        </mat-checkbox>
      </div>
    </div>
  </ng-container>

  <event-column-selector
      [available_columns]="availableColumns"
      [selected_columns]="this.config.columns || []"
      [model_dict]="{}"
      [colMenuOptions]="['bold', 'italic', 'size', 'resize', 'colour', 'background_colour', 'align','underline', 'strikethrough']"
      (columns_changed)="columnsChanged($event)"
  ></event-column-selector>
  <ng-container *ngIf="!config.event_type">
    Loading...
  </ng-container>

  <div class="row">
    <select-many-filter
        [label]="'Non editable columns'"
        (constant_changed)="updateDisabled($event)"
        [modelValue]="disabledColumns"
        [options]="disabledColumnOptions"
        [placeholder]="'Please select disabled columns'"
        [classes]="'fill'"
        class="flex-half alone"
    >
    </select-many-filter>
  </div>
</div>
