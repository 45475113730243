<style>
    .node {
        cursor: pointer;
    }

    .node text {
        font: 12px sans-serif;
    }

    .link {
        fill: none;
        stroke: #ccc;
        stroke-width: 2px;
    }

</style>

<div *ngIf="!selected_calculation?.attributes?.is_calculation" class="info"><i
    class="fa fa-info-circle"></i>
  You have selected a series. To view a value driver tree, please select a calculation.
</div>
<!--    <div #value_driver_tree id="value_driver_tree" >-->
<!--    </div>-->
<div [ngClass]="is_tile? 'vdt-tile':'vdt-page'">
  <ng-container *ngIf="is_tile" [ngTemplateOutlet]="vdtTemplate"></ng-container>

  <as-split *ngIf="!is_tile" #split="asSplit" (dragEnd)="dragEnd('percent', $event)" [direction]="direction"
            unit="percent">
    <as-split-area #area1="asSplitArea" [size]="sizes.percent.area1" class="page-vdt-area">
      <ng-container [ngTemplateOutlet]="vdtTemplate"></ng-container>
    </as-split-area>

    <as-split-area #area2="asSplitArea" class="background:white;" [size]="sizes.percent.area2">
      <value-driver-tree-table *ngIf="selected_calculation"
                               [mouseoverNode]="mouseovernode"
                               [tableData]="table_data">
      </value-driver-tree-table>
    </as-split-area>

  </as-split>
</div>

<ng-template #vdtTemplate let-node>
  <div (mousewheel)="scrollHandler($event)"
       *ngIf="selected_calculation"
       [id]="selected_calculation?.attributes.name">
    <svg [attr.viewBox]="'-10 -250 ' + vb_width + ' ' + vb_height" [attr.width]="'100%'"
         class="value-driver-tree" id="static_svg"
         x="0" xmlns:xhtml="http://www.w3.org/1999/xhtml"
         y="0">
      <defs>
        <clipPath id="circle-clip">
          <rect [attr.x]="box_width-1" y="0" [attr.width]="box_width + 11" [attr.height]="box_height" rx="2"
                ry="2"/>
        </clipPath>
      </defs>
      <g cdkDrag (cdkDragStarted)="dragging=true;" id="static_svg_g" #pan_state>
        <rect [attr.height]="height" [attr.width]="width" fill="transparent" x="0" y="-250" #svgRect></rect>
        <!--This enables cdkDrag and zooming on the whole box rather than just the nodes-->

        <path *ngFor="let link of links"
              [attr.d]="link.path"
              class="link"
              marker-end='url(#head)'
              marker-start='url(#origin)'>
        </path>

        <ng-container *ngIf="selected_calculation">
          <g (mouseenter)="mouseEnter(node)"
             (mouseleave)="mouseLeave(node)"
             (click)="toggle(node)"
             *ngFor="let node of treeNodes"
             [attr.transform]="'translate(' + node.y + ',' + node.x + ')'"
             class="node">
            <rect [ngClass]="{'rect-node-children':node._children, 'rect-node':!node._children}"
                  rx="5" ry="5"
                  [attr.height]="box_height"
                  [attr.width]="box_width"
            >
            </rect>
            <!---->
            <foreignObject
                [attr.height]="box_height"
                [attr.width]="box_width"
            >

              <xhtml:div (contextmenu)="contextMenu($event, node.data.series, node)" *ngIf="formula_view === true"
                         [ngStyle]="{height:box_height + 'px', width:box_width + 'px'}">
                <xhtml:div class="vdt-formula-title">
                  {{node.data.series.attributes | nameOrDescription}}
                </xhtml:div>
                <xhtml:div class="vdt-formula">
                  {{node.data.series.attributes.name_formula}}
                </xhtml:div>
              </xhtml:div>

              <xhtml:div *ngIf="formula_view === false;"
                         [ngStyle]="{height:box_height + 'px', width:box_width + 'px'}">
                <xhtml:context (contextmenu)="contextMenu($event, node.data.series, node)"
                               [is_vdt]="true"
                               [config]="{
                                               'selected_series':node.data.series,
                                               'value_only':true,
                                               'series_unit':node.data.series?.attributes.engineering_unit_name,
                                               'show_unit':true,
                                               'cache': true}"
                               [ngStyle]="{height:box_height + 'px', width:box_width + 'px'}">
                </xhtml:context>
              </xhtml:div>
            </foreignObject>

            <g *ngIf="node.data.series?.attributes?.is_calculation">
              <circle [attr.cx]="box_width" [attr.cy]="box_height/2" r="10" class="circle-node"
                      clip-path="url(#circle-clip)"
              />
              <line [attr.x1]="box_width - 5" [attr.y1]="box_height/2" [attr.x2]="box_width + 5"
                    [attr.y2]="box_height/2" class="minus-line"/>
              <line [attr.x1]="box_width" [attr.y1]="box_height/2 - 5" [attr.x2]="box_width"
                    [attr.y2]="box_height/2 + 5" *ngIf="node._children" class="plus-line"/>
            </g>
          </g>

        </ng-container>
      </g>

    </svg>
  </div>
</ng-template>
