import {BaseAuditMixin, BaseAuditMixinAttributes, BaseAuditMixinRelationships} from "./base-audit-mixin";
import {SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class AuditMixinAttributes extends BaseAuditMixinAttributes {
    account_name?: string;
}

export class AuditMixinRelationships extends BaseAuditMixinRelationships {
    account: SINGLE_RELATIONSHIP_TYPE;
}

export class AuditMixin extends BaseAuditMixin {
    attributes: AuditMixinAttributes;
    relationships: AuditMixinRelationships;
}
