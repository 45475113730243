<div class="form-dialog conditional-formatting-form">
  <div class="row" style="justify-content: space-between;">
    <div><span *ngIf="!has_key">Please select a component type or event type</span></div>
    <button mat-flat-button matTooltip="Add new conditional format" matTooltipPosition="above"
            style="margin:0;" (click)="addFormatRule()" [disabled]="!has_key">
      <i class="fa fa-plus" style="margin-right:10px;"></i>Add conditional format
    </button>
  </div>

  <ng-container *ngIf="available_columns?.length > 0">
    <div class="row formatting-rule" *ngFor="let condition of config.conditional_formats; let c = index;">
        <mat-form-field>
          <mat-label>Apply to</mat-label>
          <mat-select [(ngModel)]="condition.column" style="width:100%;" [compareWith]="columnCompare">
            <mat-option *ngFor="let column of available_columns"
                        [value]="column">{{column.name | upperfirst}}</mat-option>
          </mat-select>
        </mat-form-field>

      <div class="column">
        <mat-form-field class="full-width">
          <mat-label>Format cells if...</mat-label>
          <mat-select [(ngModel)]="condition.comparer">
            <mat-option *ngFor="let comparer of operator_dict | keyvalue : originalOrder"
                        [value]="comparer.key">{{comparer.value}}</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container>
          <ng-container *ngIf="!['<>', '><', 'auto', 'empty', 'not_empty'].includes(condition.comparer)">
            <input matInput step="any" [(ngModel)]="condition.value" placeholder="value" class="formatting-value"/>
          </ng-container>

          <ng-container *ngIf="['<>', '><'].includes(condition.comparer)">
            <input matInput type="number" step="any" [(ngModel)]="condition.lower" placeholder="lower limit"
                   class="formatting-value"/>
            <input matInput type="number" step="any" [(ngModel)]="condition.upper" placeholder="upper limit"
                   class="formatting-value"
                   style="margin-top:10px;"/>
          </ng-container>
        </ng-container>

      </div>

      <div class="column conditional-formatting-styles">
        <label>Formatting style</label>
        <div *ngIf="condition.comparer === 'auto'"> Auto formatting will be applied.</div>
        <div *ngIf="condition.comparer !== 'auto'" class="conditional-formats-column-menu">
          <div>
            <h3 [ngClass]="{'active': !condition.named_format}" (click)="condition.named_format = null">Custom</h3>
            <h3 [ngClass]="{'active': condition.named_format}"
                (click)="condition.named_format ='ok';openNamedSelect(c)">Named</h3>
          </div>
          <table-column-menu *ngIf="!condition.named_format"
                             [item]="conditional_formats_dict[c]"
                             [options]="col_menu_options"
                             (formatsChanged)="condition.format = $event">
          </table-column-menu>
          <mat-form-field *ngIf="condition.named_format">
            <mat-select [value]="condition.named_format" style="width:100%;" #select_named_format
                        [ngStyle]="named_format_styles[condition.named_format]"
                        (selectionChange)="namedFormat(condition, $event)" [compareWith]="namedFormatCompare">
              <mat-option *ngFor="let format of named_formats | keyvalue"
                          [value]="format" [ngStyle]="named_format_styles[format.key]">{{format.key}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="remove-format-container">
        <button mat-button (click)="removeFormat(c)" class="remove-format">
          <i class="fa fa-trash" style="margin-right:10px;"></i>
          Remove rule
        </button>
      </div>
    </div>

  </ng-container>
</div>
