<div class="print-header" [class.printing-mode]="printing">
  <div class="header-content">
    <div>
      <img class="client-logo" [src]='clientLogoSrc'/>
      <div class="report-title"><h1>{{headerData.titleSubject | async}}</h1></div>
      <img [src]="wireLogoSrc"/>
    </div>
    <div *ngIf="dateInst.dtp" class="print-header-dtp">Production period: from
      <b>{{dateInst.dtp.start | stringDate : {date_only: true} }}</b>
      at <b>{{dateInst.dtp.start | stringDate : {time_only: true} }}</b>
      to <b>{{dateInst.dtp.end | stringDate : {date_only: true} }}</b>
      at <b>{{dateInst.dtp.end | stringDate : {time_only: true} }}</b>
    </div>
  </div>
</div>

<div class="print-footer" id="print-footer" [class.printing-mode]="printing">
  <div class="footer-content">
    <div *ngIf="dateInst.dtp">Created by {{appScope.current_user.name}} on {{currentDate()  | stringDate}}
    </div>
    <div class="right"></div>
    <!--  <div class="right">Powered by MMS WIRE. https://www.metalmanagementsolutions.com</div>-->
  </div>
</div>
