import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserData} from "../../../services/user_data.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'user-otp',
    templateUrl: './user-otp.component.html',
    styleUrls: ['./user-otp.component.less'],
    standalone: false
})

export class UserOtpComponent {
  otpForm: FormGroup;
  returnUrl: string;
  @Input() username: string;
  @Input() password: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private userData: UserData,
              private notification: NotificationService) {
  }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get formControl() {
    return this.otpForm.controls.otp;
  }

  onOTPSubmit() {
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    }

    const vars = {
        email: this.username,
        password: this.password,
        token: this.formControl.value
    };

    this.userData.attemptLogin(vars).subscribe();
  }
}
