<div class="chart-card">

  <div class='md-card-content'>
    <div #chart_anchor [ngClass]="{'hide-tick-labels': tile_config.hide_tick_labels}"
         class="{{id_tag}} chart-tile">

    </div>

    <ng-container *ngIf="graph_data_stats.stats_table">
      <ng-container
          *ngIf="tile_config.statistics.averages || tile_config.statistics.pooled || tile_config.statistics.std_dev || tile_config.statistics.correlation_coeff">

        <div class="row statistics">
          <ng-container
              *ngIf="tile_config.statistics.averages || tile_config.statistics.pooled || tile_config.statistics.std_dev">

            <table [dataSource]="graph_data_stats.stats_table" class="statistics-table" mat-table>

              <ng-container matColumnDef="name">
                <th *matHeaderCellDef [attr.title]="'Statistics'" mat-header-cell></th>
                <td *matCellDef="let series" mat-cell> {{series.name}} </td>
              </ng-container>

              <ng-container matColumnDef="average">
                <th *matHeaderCellDef [attr.title]="'Average'" mat-header-cell>&sigma;</th>
                <td *matCellDef="let series" mat-cell matTooltip="Average"> {{series.average.toFixed(3)}} </td>
              </ng-container>

              <ng-container matColumnDef="std_dev">
                <th *matHeaderCellDef [attr.title]="'Standard deviation'" mat-header-cell>x&#772;</th>
                <td *matCellDef="let series" mat-cell
                    matTooltip="Standard deviation"> {{series.std_dev.toFixed(3)}} </td>
              </ng-container>

              <tr *matHeaderRowDef="statsColumns" mat-header-row></tr>
              <tr *matRowDef="let myRowData; columns: statsColumns" mat-row></tr>

            </table>

          </ng-container>

          <div>
            <ng-container *ngIf="tile_config.statistics.correlation_coeff&&graph_data_stats.r2">
              <div class="row list">
                <label>Correlation coefficient: </label>
                {{graph_data_stats.r2.toFixed(3)}}
              </div>
            </ng-container>

            <ng-container *ngIf="tile_config.regression_line.toLowerCase() !== 'none'">
              <div class="row list">
                <label>{{tile_config.regression_line| titlecase}} regression:</label>
                {{regression_result.string}}
              </div>
              <div class="row list">
                <label>r<sup>2</sup> :</label>
                {{regression_result.r2}}
              </div>
            </ng-container>
          </div>

        </div>
      </ng-container>

    </ng-container>

  </div>
</div>
