import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../services/notification.service";

export interface DialogData {
    form_result: string;
    config: any;
}

@Component({
    selector: 'src-context-form-dialog',
    templateUrl: './context-form-dialog.component.html',
    styleUrls: ['./context-form-dialog.component.less'],
    standalone: false
})

export class ContextFormDialogComponent implements OnInit {

    context_config: any;
    title: string = '';
    showing_hints: boolean;
    hint: string;

    constructor(private dialogRef: MatDialogRef<ContextFormDialogComponent>,
                private notification: NotificationService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
    }

    ngOnInit() {
        this.context_config = this.dialogData.config;

        if (!this.context_config) {
            this.context_config = {
                title: this.title,
                height: 160,
                width: 260,
                type: 'series-summary',
                config: {
                    'selected_series': null,
                    'value_only': false,
                    'series_unit': null,
                    'show_unit': true
                }
            };
        }
    }

    save() {
        if (this.context_config.config.selected_series) {

        } else {
            this.notification.openError('Please select a series.');
            return;
        }

        this.dialogRef.close(this.context_config);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
