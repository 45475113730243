import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject, Subscription, combineLatest, of, BehaviorSubject} from "rxjs";
import {takeUntil, tap, take, switchMap, catchError} from "rxjs/operators";
import * as utils from "../lib/utils";
import {UserPreferenceDataService} from "../data/user-preference-data.service";
import {AppScope} from './app_scope.service';
import {UserPreference} from "../_models/user-preference";
import {ListResponse} from './api/response-types';

import {TimezoneOption} from "../default-configuration";
import * as moment_timezone_ from "moment-timezone";
import * as moment_ from 'moment';
import {AccountService} from "./account.service";
import {NotificationService} from './notification.service';

export const moment = moment_["default"];
export const moment_timezone = moment_timezone_;

@Injectable({
    providedIn: 'root'
})
export class TimezoneSelectorService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    public active_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    /**Tells the rest of the app, DateTimePeriodService gets reset**/
    public activeTimezoneChanged = new ReplaySubject<{ timezone: string }>(1);

    /**Called by timezone selector component**/
    public timezoneSelectionChanged = new Subject<{ timezone: string }>();

    public timezoneOptionsReady = new ReplaySubject<TimezoneOption[]>(1);

    public timezoneClockMin: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private $timezoneSubscription: Subscription;

    private user_preference: UserPreference;

    private local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    private mine_time_message = "Your working timezone has been set to match the mine's timezone. You can change this by selecting the" +
        " clock on the left side of the Time Traveler and selecting your preferred working timezone.";

    constructor(private userPreferenceData: UserPreferenceDataService,
                private appScope: AppScope,
                private accountService: AccountService,
                private notification: NotificationService) {
        /**From timezone-selector.component**/
        this.timezoneSelectionChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                if (value.timezone !== this.active_timezone) {
                    this.setTimezone(value.timezone);
                    this.saveUserTimezone(this.user_preference, this.active_timezone);
                }
            });
        this.refresh();
    }

    refresh(): void {
        this.$timezoneSubscription = utils.refreshSubscription(this.$timezoneSubscription);
        this.$timezoneSubscription = combineLatest(
            this.appScope.currentUserValue,
            this.accountService.activeAccountChanged,
            this.appScope.configDictChanged
        )
            .pipe(switchMap(() => {
                    const user = this.appScope.current_user;
                    const mine_timezone = this.appScope.config_name_map?.timezone?.value || this.local_timezone;
                    return this.userPreferenceData.getUserPreferencesByUserAndAccount(user.id).pipe(
                        tap((response: ListResponse<UserPreference>) => {
                            this.user_preference = response?.data[0] || new UserPreference({
                                user_id: user.id,
                                account_id: this.appScope.active_account_id
                            });
                            const timezone = response?.data[0]?.attributes.timezone;
                            if (timezone) {
                                this.setTimezone(timezone);
                            } else {
                                if (this.getTimezoneOffset(mine_timezone) !== this.getTimezoneOffset(this.local_timezone)) {
                                    this.notification.openError(this.mine_time_message, 10000);
                                }
                                this.setTimezone(mine_timezone);
                                this.saveUserTimezone(this.user_preference, this.active_timezone, true);
                            }
                        }));
                }),
                takeUntil(this.onDestroy)
            ).subscribe();
    }

    saveUserTimezone(user_preference: UserPreference, timezone: string, skip_message = false): void {
        user_preference.attributes.timezone = timezone;
        // FIXME how to manage userprefs correctly
        delete user_preference.relationships.session_state;
        this.userPreferenceData.saveUserPreferences(user_preference).pipe(
            take(1),
            tap(result => this.user_preference.id = result.data.id),
            catchError(e => {
                // Fail silently
                console.log(e);
                skip_message = true;
                return of(e);
            })).subscribe(result => {
            if (skip_message) {
                return;
            }
            this.notification.openSuccess('Timezone preference saved.', 3000);
        });
    }

    getConfiguredTimezones(): void {
        /**If there is not a list of user configured timezones (admin) then use the default list
         * Add user's local time
         * **/
        let timezoneConfig: TimezoneOption[] = [];
        this.appScope.currentUserValue.pipe(
            takeUntil(this.onDestroy),
            tap(() => {
                timezoneConfig = this.appScope.config_name_map.timezone_options?.value || [];
                if (timezoneConfig.findIndex(c => c.alias === 'Local') < 0) {
                    timezoneConfig.unshift({
                        alias: 'Local',
                        name: this.local_timezone,
                        value: this.local_timezone
                    });
                }
                timezoneConfig.forEach(zone => {
                    zone.offset = moment.tz(zone.value).format('Z');
                });
            })
        ).subscribe(() => {
            this.timezoneOptionsReady.next(timezoneConfig);
        });
    }

    setTimezone(timezone: string): void {
        this.active_timezone = timezone;
        moment_timezone.tz.setDefault(timezone);
        this.activeTimezoneChanged.next({timezone: timezone});
    }

    getTimezoneOffset(timeZone = 'UTC', date = new Date()) {
        const utcDate: Date = new Date(date.toLocaleString('en-US', {timeZone: 'UTC'}));
        const tzDate: Date = new Date(date.toLocaleString('en-US', {timeZone}));
        return (tzDate.getTime() - utcDate.getTime()) / 6e4;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
