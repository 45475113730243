import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {SearchQueryOptions} from "./search-query-options";
import {Observable} from "rxjs";
import {ListResponse, SingleResponse} from "./response-types";
import {LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "../../_models/shared-types";
import {differenceWith as _differenceWith} from "lodash-es";
import {compare_ids, deepCopy} from "../../lib/utils";
import {SearchOptions} from "./searchOptions";
import {tap} from "rxjs/operators";

export interface ChangesOptions {
    api: string;
    model?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ModelChangesService<T = any> {
    singles: { [key: string]: { [key: string]: SINGLE_RELATIONSHIP_TYPE } } = {};
    lists: { [key: string]: { [key: string]: LIST_RELATIONSHIP_TYPE } } = {};
    attributes: { [key: string]: any } = {}

    // rel_model_dict: any = {
    //     'component_type': {
    //         'components': '*',
    //         'constant_properties': 'constant_property_component'
    //     }
    // }; //might need something to map the api model to the model object class

    constructor(private api: ApiService) {

    }

    storeItem(i) {
        this.attributes[i.id] = i.attributes;
        if (!i.relationships) return;
        Object.keys(i.relationships).forEach(key => {
            if (this.isList(i.relationships[key])) {
                if (!this.lists[i.id]) {
                    this.lists[i.id] = {};
                }
                this.lists[i.id][key] = i.relationships[key];//{data:[{id:string,type:string}]}
            } else {
                if (!this.singles[i.id]) {
                    this.singles[i.id] = {};
                }
                this.singles[i.id][key] = i.relationships[key];
            }
        })
    }

    getById(c_options: ChangesOptions, id: string, options: SearchOptions = {}): Observable<SingleResponse<T>> {
        return this.api[c_options.api].getById(id).pipe(tap((result: SingleResponse<any>) => {
            this.storeItem(deepCopy(result.data))
        }))
    }

    searchMany(c_options: ChangesOptions, options?: SearchQueryOptions): Observable<ListResponse<T>> {

        return this.api[c_options.api].searchMany(options).pipe(tap((result: ListResponse<any>) => {
            result.data.forEach(i => {
                this.storeItem(deepCopy(i))
            })
        }))
    }

    isList(rel: SINGLE_RELATIONSHIP_TYPE | LIST_RELATIONSHIP_TYPE) {
        return rel.data && Array.isArray(rel.data)
    }

    obsPatch(c_options: ChangesOptions, item_to_save: any): Observable<any> {
        let item = deepCopy(item_to_save);
        this.removeUnchangedAttributes(item);
        this.removeUnchangedSingles(item);
        this.removeUnchangedLists(item);

        return this.api[c_options.api].obsPatch(item).pipe(tap((result)=>{
            //TODO update store with changed values
        }));
    }

    removeUnchangedAttributes(item) {
        if (item.attributes && this.attributes[item.id]) {
            Object.keys(this.attributes[item.id]).forEach(key => {
                if (item.attributes[key] === this.attributes[item.id][key]) {
                    delete item.attributes[key];
                }
                if(Array.isArray(item.attributes[key])){
                    if(_differenceWith(item.attributes[key], this.attributes[item.id][key]).length<1){
                        delete(item.attributes[key])
                    }
                }
            })
        }
    }

    removeUnchangedSingles(item) {
        if (!this.singles[item.id]) return;
        Object.keys(this.singles[item.id]).forEach(key => {
            if (item.relationships[key]?.data?.id === this.singles[item.id][key]?.data?.id) {
                delete item.relationships[key];
            }
        })
    }

    // deletionObservables(item, rel_name, $changes, to_delete) {
    //     const rel_api = this.rel_model_dict[item.type][rel_name];
    //     to_delete.forEach(obj => {
    //         //$changes.push(this.api[rel_api].obsDeleteByObjectIds({[item.type]: item.id, [obj.type]: obj.id}));
    //     })
    //     return $changes;
    // }
    //
    // additionObservables(item, rel_name, $changes, to_add) {
    //     console.log('type - rel_name: ', item.type, rel_name);
    //     const rel_api = this.rel_model_dict[item.type][rel_name];
    //     console.log('rel_api: ', rel_api);
    //     to_add.forEach(obj => {
    //         console.log('obj - : ', obj);
    //         //let new_object = Object.create(window[rel_api].prototype);
    //         let new_object = {[item.type]: item.id, [obj.type]: obj.id, id: null, type: rel_api}
    //         console.log('ModelChangesService - : ', new_object);
    //         //new_object.relationships[rel_name].id = obj.id;
    //         $changes.push(this.api[rel_api].obsSave(new_object));
    //     })
    //     return $changes;
    // }

    removeUnchangedLists(item) {
        //let $changes: Observable<any>[] = [];
        const to_leave = [];
        if (!this.lists[item.id]) return;
        for(const key of Object.keys(this.lists[item.id])) {
             if (!item.relationships.hasOwnProperty(key)) continue;

            const to_delete = _differenceWith(this.lists[item.id][key].data, item.relationships[key].data, compare_ids);
            const to_add: SINGLE_RELATIONSHIP_TYPE['data'][] = _differenceWith<SINGLE_RELATIONSHIP_TYPE['data'], SINGLE_RELATIONSHIP_TYPE['data']>(item.relationships[key].data, this.lists[item.id][key].data, compare_ids);


            if (to_delete?.length > 0 || to_add?.length > 0) {
                to_leave.push(key);
            }
        }

        this.removeLists(item, to_leave)
    }

    removeLists(item, to_leave) {
        const keys = Object.keys(item.relationships)
        keys.forEach(key => {
            if (this.isList(item.relationships[key]) && !to_leave.includes(key)) {
                delete item.relationships[key];
            }
        })
    }
}
