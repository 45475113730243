import {Component, Input} from '@angular/core';
import {Series, SeriesSeriesData} from "../../_models/series";
import {SeriesEstimateFormService} from "../../components/series-estimate-form/series-estimate-form.service";
import {WireComponent} from '../../_models/component';
import {SeriesType} from "../../_models/series-type";
import {SeriesSeries} from '../../_models/series-series';

@Component({
    selector: 'series-series-table',
    templateUrl: './series-series-table.component.html',
    styleUrls: ['./series-series-table.component.less'],
    standalone: false
})
export class SeriesSeriesTableComponent {
    private _series_data: SeriesSeriesData[]
    @Input() set series_data(val: SeriesSeriesData[]) {
        this._series_data = val;
    }

    get series_data(): SeriesSeriesData[] {
        return this._series_data;
    };

    @Input() series_series: SeriesSeries[];
    @Input() series: Series;
    @Input() type: string = 'targets';

    @Input() component: WireComponent;
    @Input() selected_series_type: SeriesType;

    constructor(private cps: SeriesEstimateFormService) {
    }

    updateTargetSeries(target_series: Series) {
        this.cps.updateTargetSeries(target_series, this.series);
    }

    updateSourceSeries(source_series: Series) {
        this.cps.updateSourceSeries(source_series, this.series);
    }

    saveSeriesSeries(series: SeriesSeriesData) {
        if (!series.id) return;
        let series_series = this.series_series.find(ss => {
            return this.type !== 'targets' && ss.relationships.source_series.data.id === series.id
                || this.type === 'targets' && ss.relationships.target_series.data.id === series.id
        });
        series_series.attributes.positive_variance = series.positive_variance;
        this.cps.updateSeriesSeries(series_series);
    }

    isUnsaved(series: SeriesSeriesData) {
        return (this.type !== 'targets' && !this.series_series?.find(s => s.relationships.source_series.data.id === series.id))
            || (this.type === 'targets' && !this.series_series?.find(s => s.relationships.target_series.data.id === series.id))
    }
}
