<h4>Use template</h4>
<div style="display: flex; align-items: center">
  <div class="row select-search" style="flex: 1">
    <select-search-component
        [api_model]="'parser_config_template'"
        [custom_filters]="templateService.refreshTemplateQueryFilters(templateService.parserType)"
        [value]="selected_template"
        (selectionChange)="selectionChanged($event.value)"
        [compareFunctionName]="'compareById'"
        [filter_by]="['name']"
        [label]="'Select existing template'"
        [classes]="'fill'">
    </select-search-component>
  </div>

  <div class="row" style="flex: 1">
    <mat-form-field>
      <mat-label>Template name</mat-label>
      <input [(ngModel)]="selected_template.attributes.name" [ngModelOptions]="{standalone: true}" matInput
             type="text">
    </mat-form-field>
  </div>

  <!--  TODO assert that user may edit templates -->
  <div style="padding-right: 8px">
    <button (click)="add()"
            [disabled]="!current_options"
            mat-raised-button>
      Add
    </button>
  </div>
  <div style="padding-right: 8px">
    <button (click)="update()"
            [disabled]="!current_options || !selected_template.id"
            mat-raised-button>
      Update
    </button>
  </div>
  <div>
    <button (click)="remove()"
            [disabled]="!selected_template.id"
            mat-raised-button>
      Remove
    </button>
  </div>
</div>
