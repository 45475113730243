import {SearchQueryOptions} from "../api/search-query-options";
import {ApiService} from "../api/api.service";
import {Stream} from "../../_models/stream";
import {ListResponse} from "../api/response-types";
import {Observable} from "rxjs";
import {Process} from "../../_models/process";
import {Connector} from "../../_models/connector";
import {Equipment} from "../../_models/equipment";
import {Series} from "../../_models/series";
import {SeriesComponent} from "../../_models/series-component";
import {ConstantComponent} from "../../_models/constant-component";

export function get_process_children(api: ApiService, process_id: string): Observable<ListResponse<Process>> {
    return api.process.getRelatedMany(process_id, 'children')
}

export function get_streams(api: ApiService, process_ids: string[]): Observable<ListResponse<Stream>> {
    const options = new SearchQueryOptions();
    // TODO discuss why this is an and, and if an or might make more sense
    options.filters = [{
        and: [
            {
                'name': 'end',
                'op': 'has',
                val: {
                    name: 'id',
                    op: 'in',
                    val: process_ids
                }
            },
            {
                'name': 'start',
                'op': 'has',
                val: {
                    name: 'id',
                    op: 'in',
                    val: process_ids
                }
            }
        ]
    }];

    return api.stream.searchMany(options);
}

export function get_connectors(api: ApiService, process_ids: string[]): Observable<ListResponse<Connector>> {
    const options = new SearchQueryOptions();
    options.filters = [{
        and: [
            {
                'name': 'process',
                'op': 'has',
                val: {
                    name: 'id',
                    op: 'in',
                    val: process_ids
                }
            }
        ]
    }];

    return api.connector.searchMany(options);
}

export function get_equipment(api: ApiService, process_ids: string[]): Observable<ListResponse<Equipment>> {
    const options = new SearchQueryOptions();
    options.filters = [{
        or: [
            {
                'name': 'component',
                'op': 'has',
                val: {
                    name: 'id',
                    op: 'in',
                    val: process_ids
                }
            }
        ]
    }];

    return api.equipment.searchMany(options)
}

export function get_ore_body_groups(api: ApiService, ore_body_groups: { data?: { id?: string } }[]): Observable<ListResponse<any>> {
    let options = new SearchQueryOptions();
    options.filters = [{
        op: 'in',
        name: 'id',
        val: ore_body_groups.map(obg => {
            if (obg && obg.data) {
                return obg.data.id
            }
        })
    }];

    return api.ore_body_group.searchMany(options)
}

export function get_series_for_components(api: ApiService, component_ids: string[]): Observable<ListResponse<Series>> {
    const options = new SearchQueryOptions();
    options.filters = [{
        name: 'series_components',
        op: 'any',
        val: {
            name: 'component',
            op: 'has',
            val: {
                name: 'id',
                op: 'in',
                val: component_ids
            }
        }
    }];

    return api.series.searchMany(options);
}

export function get_series_components_for_components(api: ApiService, component_ids: string[]): Observable<ListResponse<SeriesComponent>> {
    const options = new SearchQueryOptions();
    options.filters = [{
        name: 'component',
        op: 'has',
        val: {
            name: 'id',
            op: 'in',
            val: component_ids
        }
    }];

    return api.series_component.searchMany(options);
}

export function get_constant_components_for_components(api: ApiService, component_ids: string[]): Observable<ListResponse<ConstantComponent>> {
    const options = new SearchQueryOptions();
    options.filters = [{
        name: 'component',
        op: 'has',
        val: {
            name: 'id',
            op: 'in',
            val: component_ids
        }
    }];

    return api.constant_component.searchMany(options);
}
