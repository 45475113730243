import * as Handsontable from "handsontable";
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {HotInstance} from "../../services/hot-instance";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {tap} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Component({
    selector: 'process-access-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class ProcessAccessSheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    title: string;
    process_accesses: any[];
    processes: any[];
    features: any[];
    schema: any;
    column_list: any;
    search: string = '';
    users: any[];

    constructor(
        private api: ApiService,
        private headerData: HeaderDataService,
        private handsontableGenerator: HandsontableGenerator) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        this.headerData.buttons = [];
        this.title = 'Process Access';

        const observables = [];
        observables.push(this.api.process_light.searchMany().pipe(tap(response => this.processes = response.data)));
        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));

        const parameters = new SearchQueryOptions();
        parameters.filters = [
            {'name': 'process_access_feature', val: true, op: 'eq'}
        ];

        observables.push(this.api.feature.searchMany(parameters).pipe(tap(response => this.features = response.data)));
        observables.push(this.api.process_access.searchMany().pipe(tap(response => this.process_accesses = response.data)));
        // this.roles = api.role.list;

        forkJoin([observables]).subscribe(this.createTable.bind(this));

        this.buildHeader();
    }

    createTable() {
        const userLookup = this.handsontableGenerator.gen_lookups(this.users, item => item.attributes.name);
        const processLookup = this.handsontableGenerator.gen_lookups(this.processes, item => item.attributes.name);
        const featureLookup = this.handsontableGenerator.gen_lookups(this.features, item => item.attributes.name);

        this.schema = {
            id: null,
            type: 'process_access',
            attributes: {
                created_on: null,
                changed_on: null
            },
            relationships: {
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}},
                top_process: {data: {id: null, type: 'process'}},
                user: {data: {id: null, type: 'users'}},
                feature: {data: {id: null, type: 'feature'}}
            }
        };

        this.column_list = [{
            data: this.handsontableGenerator.genLookupDataSource(processLookup, 'top_process'),
            title: 'Process',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: processLookup.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(userLookup, 'user'),
            title: 'User',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: userLookup.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(featureLookup, 'feature'),
            title: 'Feature',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: featureLookup.source,
            allowInvalid: false
        }];

        this.hot = this.handsontableGenerator.generateTable(this.api.process_access, this.schema, this.column_list, this.hot);
        this.hot.settings.data = this.process_accesses;
        this.hot.ready = true;
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    save() {
        const results = this.hot.save();
        this.process_accesses = results.data;
    }

    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = 'Access';
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save'},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download'}
        ];
    }
}
