import {ConnectorViewModel} from "./connector-viewmodel";
import {TextViewModel} from "./text-viewmodel";
import {PointViewModel} from "./point-viewmodel";
import {BaseViewModel} from "./base-viewmodel";

export class StreamViewModel extends BaseViewModel {
    start: ConnectorViewModel;
    end: ConnectorViewModel;
    points: PointViewModel[];
    text: TextViewModel;

    constructor(streamDataModel, startConnector, endConnector) {
        super(streamDataModel)
        this.start = startConnector;
        this.end = endConnector;

        if (this.data.attributes == null) {
            this.data.attributes = {};
        }
        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (this.start == null) {
            this.data.attributes.json.startConnectorID = null;
            this.data.attributes.json.endConnectorID = null;
        } else {
            this.data.attributes.json.startConnectorID = this.start.data.id;
            this.data.attributes.json.endConnectorID = this.end.data.id;
        }

        if (this.data.attributes.json.points) {
            this.points = this.data.attributes.json.points.map(function (item) {
                return new PointViewModel(item)
            })
        } else {
            if (this.start instanceof ConnectorViewModel && this.end instanceof ConnectorViewModel) {
                this.data.attributes.json.points = [{x: this.startTangentX(), y: this.startTangentY()},
                    {x: this.endTangentX(), y: this.endTangentY()}];

                this.points = this.data.attributes.json.points.map(function (item) {
                    return new PointViewModel(item)
                });

            } else {
                this.data.attributes.json.points = [];
                this.points = [];
            }
        }

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }

        if (this.data.attributes.json.points.length > 1) {
            if (!this.data.attributes.json['text'].x || this.data.attributes.json['text'].x < 0) {
                this.data.attributes.json['text'].x = (this.points[0].x() + this.points[1].x()) / 2;
            }
            if (!this.data.attributes.json['text'].y || this.data.attributes.json['text'].y < 0) {
                this.data.attributes.json['text'].y = (this.points[0].y() + this.points[1].y()) / 2;
            }
        } else {
            if (!this.data.attributes.json['text'].x || this.data.attributes.json['text'].x < 0) {
                this.data.attributes.json['text'].x = (this.endCoordX() + this.startCoordX()) / 2;
            }
            if (!this.data.attributes.json['text'].y || this.data.attributes.json['text'].y < 0) {
                this.data.attributes.json['text'].y = (this.endCoordY() + this.startCoordY()) / 2;
            }
        }

        if (!this.data.attributes.json['text'].rotate) {
            this.data.attributes.json['text'].rotate = 0;
        }

        this.text = new TextViewModel(this.data.attributes.json.text);

    }

    name() {
        return this.data.attributes.name || "";
    };

    colour() {
        if (!this.data.attributes.json['colour']) {
            this.data.attributes.json['colour'] = "gray";
        }
        return this.data.attributes.json['colour'];
    };

    fontsize() {
        return parseFloat(this.data.attributes.json.fontsize || 12);
    };

    addPoint() {
        var new_point = {x: this.middleX(), y: this.middleY()};
        this.data.attributes.json.points.push(new_point);
        this.points.push(new PointViewModel(new_point))
    };

    //#region Define path-----------------------------------------------------------------------------------------------
    genPath() {
        let path = 'M' + (this.startCoordX()) + ',' + (this.startCoordY()) + ' L ';
        this.points.forEach(function (point) {
            path = path + point.x() + ',' + point.y() + ' ';
        });
        return path + this.endCoordX() + ',' + this.endCoordY();
    };

    startCoordX() {
        return this.start.parentProcess().x() + this.start.x();
    };

    startCoordY() {
        return this.start.parentProcess().y() + this.start.y();
    };

    startCoord() {
        return {
            x: this.startCoordX(),
            y: this.startCoordY()
        };
    };

    startTangentX() {
        return this.computeStreamStartTangentX(this.startCoord(), this.endCoord());
    };

    startTangentY() {
        return this.computeStreamStartTangentY(this.startCoord(), this.endCoord());
    };

    endCoordX() {
        return this.end.parentProcess().x() + this.end.x();
    };

    endCoordY() {
        return this.end.parentProcess().y() + this.end.y();
    };

    endCoord() {
        return {
            x: this.endCoordX(),
            y: this.endCoordY()
        };
    };

    endTangentX() {
        return this.computeStreamEndTangentX(this.startCoord(), this.endCoord());
    };

    endTangentY() {
        return this.computeStreamEndTangentY(this.startCoord(), this.endCoord());
    };

    middleX(scale?) {
        if (!scale || typeof (scale) == "undefined")
            scale = 0.5;
        return this.startCoordX() * (1 - scale) + this.endCoordX() * scale;
    };

    middleY(scale?) {
        if (!scale || typeof (scale) == "undefined")
            scale = 0.5;
        return this.startCoordY() * (1 - scale) + this.endCoordY() * scale;
    };

    computeStreamTangentOffset(pt1, pt2) {
        return (pt2.x - pt1.x) / 2;
    };

    // Compute the tangent for the bezier curve.
    computeStreamStartTangentX(pt1, pt2) {
        return pt1.x + this.computeStreamTangentOffset(pt1, pt2);
    };

    // Compute the tangent for the bezier curve.
    computeStreamStartTangentY(pt1, pt2) {
        return pt1.y;
    };

    // // Compute the tangent for the bezier curve.
    // computeStreamStartTangent (pt1, pt2) {
    //     return {
    //         x: this.computeStreamStartTangentX(pt1, pt2),
    //         y: this.computeStreamStartTangentY(pt1, pt2)
    //     };
    // };

    // Compute the tangent for the bezier curve.
    computeStreamEndTangentX(pt1, pt2) {
        return pt2.x - this.computeStreamTangentOffset(pt1, pt2);
    };

    // Compute the tangent for the bezier curve.
    computeStreamEndTangentY(pt1, pt2) {
        return pt2.y;
    };

    // Compute the tangent for the bezier curve.
    computeStreamEndTangent(pt1, pt2) {
        return {
            x: this.computeStreamEndTangentX(pt1, pt2),
            y: this.computeStreamEndTangentY(pt1, pt2),
        };
    };

    //#endregion Define path--------------------------------------------------------------------------------------------
}
