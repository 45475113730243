<div class="registration-wrapper">
  <div class="container form-dialog register-form">
    <form [formGroup]="registerForm" class="form-section column space-between">
      <div class="row">
        <mat-form-field class="form-padding-right">
          <mat-label class="form_group_label">First Name</mat-label>
          <input matInput formControlName="name" type="text" id="name" name="name"
                 [ngClass]="registerForm.get('name').dirty? !registerForm.get('name').invalid ? 'valid': 'error' : ''"
                 [errorStateMatcher]="matcher"/>
          <mat-error *ngIf="registerForm.get('name').invalid && !registerForm.get('name').hasError('required')">
            Please enter a valid first name
          </mat-error>
          <mat-error *ngIf="registerForm.get('name').hasError('required')">
            {{requiredMessage}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="form_group_label">Last Name</mat-label>
          <input matInput formControlName="last_name" type="text" id="last_name" name="last_name"
                 [ngClass]="registerForm.get('last_name').dirty? !registerForm.get('last_name').invalid ? 'valid': 'error' : ''"
                 [errorStateMatcher]="matcher"/>
          <mat-error
              *ngIf="registerForm.get('last_name').invalid && !registerForm.get('last_name').hasError('required')">
            Please enter a valid last name
          </mat-error>
          <mat-error *ngIf="registerForm.get('last_name').hasError('required')">
            {{requiredMessage}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="form-padding-right">
          <mat-label class="form_group_label">Username</mat-label>
          <input matInput formControlName="username" type="text" id="username" name="username" (blur)="trimUsername()"
                 [ngClass]="registerForm.get('username').dirty ? !registerForm.get('username').invalid ? 'valid': 'error' : ''"
                 [errorStateMatcher]="matcher"/>
          <mat-error *ngIf="registerForm.get('username').invalid
            && registerForm.get('username').hasError('uniqueInput')
            && !registerForm.get('username').hasError('required')">
            This username is already used
          </mat-error>
          <mat-error *ngIf="registerForm.get('username').invalid
            && registerForm.get('username').hasError('minlength')
            && !registerForm.get('username').hasError('required')">
            Please enter a valid username
          </mat-error>
          <mat-error *ngIf="registerForm.get('username').hasError('required')">
            {{requiredMessage}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="form_group_label">Preferred Name</mat-label>
          <input matInput formControlName="alias" type="text" id="alias" name="alias"
                 [errorStateMatcher]="matcher"/>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label class="form_group_label">Email</mat-label>
          <input matInput formControlName="email" type="text" id="email" name="email"
                 [ngClass]="registerForm.get('email').dirty ? !registerForm.get('email').invalid ? 'valid': 'error' : ''"
                 [errorStateMatcher]="matcher"/>
          <mat-error *ngIf="registerForm.get('email').invalid
            && registerForm.get('email').hasError('uniqueInput')
            && !registerForm.get('email').hasError('required')">
            This email is already used
          </mat-error>

          <mat-error *ngIf="registerForm.get('email').invalid
            && (registerForm.get('email').hasError('email')
            || registerForm.get('email').hasError('pattern'))
            && !registerForm.get('email').hasError('required')">
            Please enter a valid email
          </mat-error>
          <mat-error *ngIf="registerForm.get('email').hasError('required')">
            {{requiredMessage}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row" *ngIf="accounts">
        <mat-form-field>
          <mat-label for="roles" class="form_group_label">Accounts</mat-label>
          <mat-select (selectionChange)="accountsChanged($event)" formControlName="accounts"
                      id="accounts"
                      multiple>
            <mat-option *ngFor="let account of accounts"
                        [value]="account"
                        [disabled]="account.view_only">
              <span>{{account.attributes.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row" *ngIf="roles">
        <mat-form-field>
          <mat-label for="roles" class="form_group_label">Roles</mat-label>
          <mat-select (selectionChange)="rolesChanged($event)" formControlName="roles"
                      id="roles"
                      multiple>
            <ng-container *ngFor="let role of roles">
              <mat-option *ngIf="allowRoleSelection(role)"
                          [value]="role"
                          class="with-account-row">
                <span>{{role.attributes.name}}</span>
                <span *ngIf="role.attributes?.account_name"
                      class="account-name">
                {{role.attributes.account_name.trim()}}
              </span>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row form-group-checkbox col-md-6">
        <mat-checkbox formControlName="restricted" style="flex-grow:0;">Restricted access</mat-checkbox>
      </div>

    </form>
    <div class="row" style="justify-content: space-between;align-items: flex-end;height:40px;">
      <button (click)="openDialog()" [disabled]="!user" [ngClass]="{'disabled': !user}" mat-raised-button>Assign user
        rights
      </button>
      <button (click)="submit()" [disabled]="loading" mat-raised-button color="warn" type='submit'>Submit</button>
    </div>
  </div>
</div>
