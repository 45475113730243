import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {SeriesDataService} from '../../services/series_data.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EventType} from "../../_models/event-type";
import {EventTypeService} from '../../services/event_type.service';
import {catchError, concatMap, finalize, first, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {forkJoin, Observable, of, Subject} from "rxjs";
import * as utils from "../../lib/utils";
import {EventService} from '../../services/event.service';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {FormDialogService} from "../../services/form-dialog.service";
import {ComponentType} from "../../_models/component-type";
import {OreBodyType} from "../../_models/ore-body-type";
import {Series} from "../../_models/series";
import {SingleResponse} from "../../services/api/response-types";
import {SaveService} from "../../services/save/save.service";
import {EventTypeFormDialogService} from "./event-type-form-dialog.service";
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {getManyRelationWithIdFilter} from "../../services/api/filter_utils";
import {NotificationService} from "../../services/notification.service";

export interface EventTypeDialogData {
    event_type: any;
    series_list: any[];
    ore_body_types_list: any[];
    component_types_list: ComponentType[];
    constant_property: any;
}

@Component({
    selector: 'event-type-form',
    templateUrl: './event-type-form-dialog.component.html',
    styleUrls: ['./event-type-form-dialog.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SaveService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class EventTypeFormDialogComponent implements OnInit, AfterViewInit {
    ore_body_types_list: OreBodyType[] = [];
    added_component_types: ComponentType[];
    removed_component_types: ComponentType[];
    et_ct_list: any[] = [];
    select_component_types: ComponentType[];
    selected_component_types_filter: any[];
    selected_component_types_reverse_filter: any[];
    ore_body_type_dict: { [id: string]: OreBodyType } = {};
    series_dict: { [id: string]: Series } = {};

    series_list: any[];
    saved_event_type: EventType;
    event_type: EventType;
    et_id: string;
    constant_property: any;
    sd_ore_body_types: OreBodyType[];

    @ViewChild('event_type_tabs') event_type_tabs: MatTabGroup;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                public dialogRef: MatDialogRef<EventTypeFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: EventTypeDialogData,
                private seriesData: SeriesDataService,
                private eventTypeService: EventTypeService,
                public eventService: EventService,
                public dialog: MatDialog,
                private notification: NotificationService,
                private formDialogService: FormDialogService,
                private saveService: SaveService,
                private cps: EventTypeFormDialogService,
                private relMapping: RelationshipApiMappingService,
                private changeDetection: ChangeDetectorRef) {

        this.et_id = this.data.event_type?.id;
    }

    ngOnInit() {
        const ctrl = this;

        this.selected_component_types_filter = [getManyRelationWithIdFilter('event_types', this.et_id)];
        this.selected_component_types_reverse_filter = [{not: getManyRelationWithIdFilter('event_types', this.et_id)}];
        // TODO:page
        const $series = this.api.series_light.searchMany().pipe(
            tap(result => {
                this.series_list = result.data;
                this.series_list.forEach(s => this.series_dict[s.id] = s);
            })
        );

        let $obs = [$series];

        const $lists = forkJoin($obs).pipe(tap(() => {
                this.updateSourceDestOreBodyTypes();
            }),
            first(), takeUntil(this.onDestroy)
        );
        if (this.et_id) {
            this.api.event_type.getById(this.et_id).pipe(
                switchMap(result => {
                    this.event_type = result.data;
                    // Keep a copy to pass back to the table on close without save
                    this.saved_event_type = utils.deepCopy(this.event_type);
                    return $lists;
                }), first(), takeUntil(this.onDestroy))
                .subscribe(() => this.changeDetection.markForCheck());
            // Note that dialog opening from event_type table has event relationships already mapped
        } else {
            this.event_type = new EventType();
            this.saved_event_type = utils.deepCopy(this.event_type);
            $lists.subscribe(() => this.changeDetection.markForCheck());
        }

    }

    ngAfterViewInit() {
        if (this.data.constant_property && this.event_type_tabs) {
            setTimeout(() => {
                this.event_type_tabs.selectedIndex = 2;
                this.changeDetection.markForCheck();
            }, 500)
        }
    }

    editSeries(series) {
        const ctrl = this;
        let $series_full = this.api.series.getById(series.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            ctrl.seriesData.upsertSeries(null, series_full).afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                    ctrl.event_type.relationships.series_list.data.map(s => {
                        if (s.id === series.id) {
                            s = updated_series;
                        }
                    });
                    this.changeDetection.markForCheck();
                }
            });
        });
    }

    editComponentType(component_type) {
        const dialogRef = this.formDialogService.editComponentType(component_type);
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                if (component_type) {
                    component_type = result;
                }
                this.changeDetection.markForCheck();
            }
        });
    }

    getOreBodyTypes() {
        if (this.ore_body_types_list.length > 0) {
            return;
        }
        this.eventTypeService.getOreBodyTypes().pipe(
            first(),
            tap(result => {
                this.ore_body_types_list = result.data;
                this.ore_body_types_list.forEach(obt => this.ore_body_type_dict[obt.id] = obt);
            })
        ).subscribe(() => {
            this.changeDetection.markForCheck();
        });
    }

    onTabChanged(event: MatTabChangeEvent) {
        if (event.index === 3) {
            this.getOreBodyTypes();
        }
    }

    updateSourceDestOreBodyTypes() {
        this.sd_ore_body_types = this.ore_body_types_list
            .filter(obt => this.saved_event_type.relationships.ore_body_types.data.map(sob => sob.id).includes(obt.id));
        this.changeDetection.markForCheck();
    }

    save() {
        this.saveEventType().pipe(tap(result => {
            this.onCloseClick();
        }), catchError(err => err)).subscribe();
    }

    apply() {
        this.saveEventType().subscribe(() => this.changeDetection.markForCheck());
    }

    saveEventType(): Observable<any> {
        let $promise;
        let data = utils.deepCopy(this.event_type);
        EventType.cleanup(data);
        if (this.event_type.id) {
            $promise = this.api.event_type.obsPatch(data);
        } else {
            $promise = this.api.event_type.obsSave(data);
        }
        return $promise.pipe(first(), takeUntil(this.onDestroy),
            tap((result: SingleResponse<EventType>) => {
                if (result) {
                    this.event_type.id = result.data.id;

                    // this allows table to reset event_type if user clicks apply then close and/or close without saving
                    this.saved_event_type = utils.deepCopy(this.event_type);
                    this.updateSourceDestOreBodyTypes();
                    this.notification.openSuccess('Event type saved', 2000);
                }
                this.saveManyComponentTypes();
                this.saveService.emitSave();
            }), catchError(err => {
                console.log('error', err);
                return err;
            })
        );
    }

    trackComponentTypes(added?: ComponentType[], removed?: ComponentType[]): Observable<any> {
        const ids = this.removed_component_types?.map(ct => ct.id);
        if (!ids) {
            return of([]);
        }
        return this.cps.getEventTypeComponentTypesByComponentTypesandEventType(this.et_id, this.removed_component_types.map(ct => ct.id))
            .pipe(tap(result => {
                this.et_ct_list = result.data;
            }));
    }

    saveManyComponentTypes() {
        this.trackComponentTypes()
            .pipe(concatMap(() => {
                    return this.relMapping.saveMany('event_type_component_type_map', 'event_type', this.event_type.id, 'component_type',
                        this.added_component_types || [], this.removed_component_types || [], this.et_ct_list)
                        .pipe(tap((result) => {
                            if (result?.data) {
                                this.et_ct_list.push(result.data)
                            }
                        }));
                }),
                /**Dont close subscription when form closes (onDestroy), only closes on finalise**/
                finalize(() => {
                })
            )
            .subscribe();
    }

    goToTab(tabGroup: MatTabGroup, tab) {
        tabGroup.selectedIndex = tab;
    }

    onCloseClick(): void {
        this.dialogRef.close(this.saved_event_type);
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
