
import {Component, inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatSortModule, Sort} from "@angular/material/sort";
import {Model} from "../../services/api/model";
import {forkJoin} from 'rxjs';
import {MatTabGroup, MatTabsModule} from '@angular/material/tabs';
import {User} from "../../_models/users";
import {NotificationService} from "../../services/notification.service";
import {CommonModule} from "@angular/common";
import {CapitalisePipe, RemoveUnderscorePipe} from "../../shared/pipes";
import {HistoryTableComponent} from "../../handson-sheets/history-table/history-table.component";
import {UserListComponent} from "../user-list.component";
import {GroupListComponent} from "./group-list/group-list.component";
import {tap} from "rxjs/operators";

@Component({
    selector: 'manage-users',
    templateUrl: './manage-users.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ManageUsersComponent implements OnInit {
    private appScope: AppScope = inject(AppScope);
    private api: ApiService = inject(ApiService);
    private headerData: HeaderDataService = inject(HeaderDataService);
    private notification: NotificationService = inject(NotificationService);

    canEdit: boolean;
    canView: boolean;
    users: User[];
    user_meta: any[];
    user_meta_dict: any = {};
    client_emails: { [key: string]: string[] };
    name: string;
    mms_user: any;
    tabs: string[] = ['Users', 'MMS Users', 'Inactive Users'];
    filterValue: string = '';
    resource_list: Model[] = [this.api.users];
    dataSource: { [tab in 'Users' | 'MMS Users' | 'Inactive Users']: MatTableDataSource<any> };
    private sort;

    @ViewChild("tabGroup", {static: false}) tabGroup: MatTabGroup;

    constructor() {
        this.dataSource = {
            'Users': new MatTableDataSource<any>(),
            'MMS Users': new MatTableDataSource<any>(),
            'Inactive Users': new MatTableDataSource<any>(),
        };
    }

    ngOnInit(): void {
        // TODO remove this .promise when auth_complete is no longer a defer
        this.appScope.authComplete$.pipe(tap(() => {
            this.headerData.show_dtp = true;
            let $users = this.api.users.searchMany();
            let $user_meta = this.api.user_meta.searchMany();
            forkJoin([$users, $user_meta]).subscribe(response => {
                if (!response) {
                    return;
                }

                this.users = response[0]['data'];
                this.user_meta = response[1]['data'];
                this.fillUserMeta();
                this.mms_user = this.users.find(user => user.id === this.appScope.current_user.id);

                this.dataSource['Users'].data = response[0]['data'].filter(row => !this.isMMS(row) && row.attributes.active);
                this.dataSource['MMS Users'].data = response[0]['data'].filter(row => this.isMMS(row));
                this.dataSource['Inactive Users'].data = response[0]['data'].filter(row => !this.isMMS(row) &&
                    row.attributes.active !== true);

                this.client_emails = this.appScope.config_name_map['client_emails']?.value;
                if (this.client_emails) {
                    Object.keys(this.client_emails).forEach(key => {
                        this.dataSource[key] = new MatTableDataSource<any>();
                        this.dataSource[key].data = response[0]['data'].filter(row => this.isClient(row, key) && row.attributes.active);
                        if (this.dataSource[key].data?.length > 0) {
                            this.tabs.unshift(key);
                        }
                    });
                }

                this.tabs.forEach((tab) => {
                    this.dataSource[tab].filterPredicate = (data, filter) => {
                        if (data.attributes.name.toLowerCase().includes(filter)
                            || data.attributes.email.toLowerCase().includes(filter)
                            || data.attributes.role_names.join().toLowerCase().includes(filter)
                        ) {
                            return true;
                        }
                        return false;
                    };
                    this.dataSource[tab].sortingDataAccessor = (data, sortHeaderId) => {
                        return data.attributes[sortHeaderId];
                    };
                });

            });

            const cur_user = this.appScope.current_user;
            this.canEdit = (cur_user.feature_names.indexOf("edit users") > -1
                || cur_user.role_names.indexOf('Administrator') > -1
                || cur_user.is_super);
            this.canView = this.canEdit || cur_user.feature_names.indexOf("view manage users") > -1;
            this.buildHeader();
        })).subscribe();
    }

    fillUserMeta() {
        this.users.forEach(user => {
            const meta = this.user_meta.find(um => {
                try {
                    return um.relationships.user.data.id === user.id;
                } catch {
                    return false;
                }
            });
            if (meta) {
                this.user_meta_dict[user.id] = meta;
            }
        });
    }

    isMMS(row) {
        return /@metalmanagementsolutions.com\s*$/.test(row.attributes.email);
    }

    isClient(row, tab) {
        if (!this.client_emails[tab]) { return false; }
        return this.client_emails[tab].some(email => row.attributes.email.toLowerCase().indexOf(email) > -1);
    }

    applySort(event: Sort) {
        this.sort = event;
        this.tabs.forEach((tab) => {
            if (this.sort && this.dataSource[tab]) {
                this.dataSource[tab].sort = this.sort;
            }
        });
    }

    applyFilter(filterValue: any) {
        this.filterValue = filterValue;
        filterValue = filterValue.trim().toLowerCase();
        this.tabs.forEach((tab) => this.dataSource[tab].filter = filterValue);
    }

    buildHeader() {
        this.headerData.title = 'Manage Users';
    }

    userChanged(user: User) {
        if (user.relationships.changed_by) {
            delete user.relationships.changed_by;
        }
        if (user.relationships.created_by) {
            delete user.relationships.created_by;
        }
        this.api.users.obsPatch(user).subscribe({
            next() {
                this.notification.openSuccess('Updated user', 2000);
            }, error(err) {
                this.notification.openError('Failed to update user: ' + err.errors[0].detail, );
            }
        });
    }
}
