<form [formGroup]="advancedForm" class="form-section">
  <div class="row">

    <report-tag-generator class="flex-third" [config]="config"></report-tag-generator>

    <div class="full-width column">
      <mat-form-field class="full-width">
        <mat-label>HTML Template</mat-label>
        <textarea class="template-textarea"
                  matInput
                  formControlName="template"
                  [ngClass]="{'invalid-input': advancedForm.get('template').invalid && (advancedForm.get('template').dirty || advancedForm.get('template').touched)}">
      </textarea>
        <mat-error *ngIf="advancedForm.get('template').hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>

      <div class="json-context-container">
        <div class="row min end space-between">
          <mat-label>Context</mat-label>
          <button mat-raised-button (click)="copyJson()">Copy</button>
        </div>
        <div class="json-wrapper">
          <pre>{{jsonContext}}</pre>
        </div>
      </div>
    </div>
  </div>

</form>
