import {Component, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {HeaderDataService} from "../../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {BaseComponent} from "../../shared/base.component";
import {concatMap, takeUntil, tap, switchMap} from "rxjs/operators";
import {FlowchartData} from "../../_models/flowchart-data";

@Component({
    selector: 'process-sheet-focus-view',
    templateUrl: 'process-sheet-focus-view.component.html',
    standalone: false
})
export class ProcessSheetFocusViewComponent extends BaseComponent {
    process: any;
    children: any;
    sheet_ready: boolean;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private plantData: PlantDataService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {

        this.params_subscription = this.route.params.pipe(switchMap(params => {
                this.sheet_ready = false;
                const process_id = params.processID;
                return this.api.process.getById(process_id).pipe(concatMap(response => {
                    return this.plantData.getFlowchartDataForProcess(process_id)
                        .pipe(tap(flowchart_data => {
                            this.process = flowchart_data.process_focus;
                            this.plantData.setActiveProcess(this.process);
                            this.setupEditSheet(flowchart_data);
                        }));
                }))
            }),
            takeUntil(this.onDestroy)).subscribe();
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
        super.ngOnDestroy();
    }

    setupEditSheet(flowSheet: FlowchartData) {
        this.children = flowSheet.processes;
        this.sheet_ready = true;
    }
}
