<div class="row center">
  <select-search *ngIf="event_types"
                 [(value)]="selected_event_type"
                 [classes]="'fill'"
                 [data]="event_types"
                 [fill_stubs]="true"
                 [label]="'Please select an Event Type'"
                 style="width: 250px;margin-right:10px;"
  >
  </select-search>

  <select-search *ngIf="event_types"
                 [(value)]="selected_estimate_event_type"
                 [classes]="'fill'"
                 [data]="event_types"
                 [fill_stubs]="true"
                 [label]="'Please select an Estimate Event Type'"
                 style="width: 250px;"
  >
  </select-search>
  <button (click)="getOreBodySummary()" class="inline-refresh">Refresh table<i class="fa fa-refresh"></i></button>
</div>

<div *ngIf="summary_data && summary_columns" style="overflow-x:scroll;margin-bottom:40px;display:flex;">
  <table class="ore-body-summary-table">
    <thead>
    <tr>
      <th class="cp-header" rowspan="3"></th>
      <ng-container *ngFor="let t of summary_columns">
        <th *ngIf="displayed[t.key]" [attr.colspan]="col_keys.length" style="padding:4px;">
          <div style="display:flex; justify-content: space-between;">
            <i (click)="displayed[t.key] = !displayed[t.key]" class="fa fa-minus hider"></i>
            <div>{{t.description | upperfirst}}</div>
            <i (click)="displayed[t.key] = !displayed[t.key]" class="fa fa-minus hider"></i>
          </div>
        </th>
        <th *ngIf="!displayed[t.key]" [attr.rowspan]="3" style="height:120px;white-space:nowrap;">
          <div style="transform:rotate(270deg) translate(48px, 0px);width:20px;">
            <div style="display:flex;align-items:center;justify-content: flex-end;">{{t.key | upperfirst}}
              <i (click)="displayed[t.key] = !displayed[t.key]" class="fa fa-plus hider"></i>
            </div>
          </div>

        </th>
      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let t of summary_columns">
        <ng-container *ngIf="displayed[t.key]">
          <th *ngFor="let e of t.data" [attr.colspan]="e.data.length">{{e.key}}</th>
        </ng-container>
      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let t of summary_columns">
        <ng-container *ngIf="displayed[t.key]">
          <ng-container *ngFor="let e of t.data">
            <th *ngFor="let col of e.data">{{col.description}}</th>
          </ng-container>
        </ng-container>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let cp of cps">
      <th class="cp-header">{{cp | upperfirst}}</th>
      <ng-container *ngFor="let t of summary_columns">
        <td *ngIf="cp === cps[0]" [attr.rowspan]="cps.length"
            [style.display]="displayed[t.key] ? 'none' : 'table-cell'"></td>
        <ng-container *ngFor="let et of t.data">
          <td *ngFor="let header of et.data"
              [style.display]="displayed[t.key] ? 'table-cell' : 'none'"
              style="text-align: right;">{{summary_data[t.key][et.key][header.key][cp] | percentage : cp : true}}</td>
        </ng-container>
      </ng-container>
    </tr>

    </tbody>
  </table>

</div>
