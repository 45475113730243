<div class="form-dialog event-type-form-dialog">
  <h1 cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane"><span
      [textContent]="component_type && component_type.id ? 'Edit component type: ' + component_type.attributes.name :  'Add component type'"></span>
  </h1>
  <mat-dialog-content>
    <mat-tab-group #component_type_tabs [disableRipple]="true">
      <mat-tab label="Basic">
        <form *ngIf="component_type" class="form-section column space-between basic">
          <div class="row medium-height">
            <mat-form-field class="inline flex-half alone">
              <label>Type</label>
              <mat-select [(ngModel)]="component_type.attributes.base_type"
                          name="base_type">
                <mat-option *ngFor="let base of base_types" [value]="base">{{base}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row medium-height">
            <mat-form-field class="inline flex-half alone" >
              <label>Name</label>
              <input [(ngModel)]="component_type.attributes.name" matInput name="name" placeholder="">
            </mat-form-field>
          </div>
          <div class="row medium-height">
            <mat-form-field class="inline flex-half alone" >
              <label>Description</label>
              <input [(ngModel)]="component_type.attributes.description" matInput name="description" placeholder="">
            </mat-form-field>
          </div>

          <ng-container
              *ngIf="(component_type.attributes.base_type === 'equipment' || component_type.attributes.base_type === 'resource')">
            <div class="row">
              <!--              <div style="width:50%;margin-right:20px;">-->
              <!--                &lt;!&ndash;                <label style="width:90px;">Selected {{component_type.attributes.base_type | upperfirst}} (view&ndash;&gt;-->
              <!--                &lt;!&ndash;                  only)</label>&ndash;&gt;-->
              <select-many-search-component #componentSelect
                                            [custom_filters]="component_options_filter"
                                            [custom_selected_filters]="selected_components_filter"
                                            [api_model]="'component'"
                                            [classes]="'fill'"
                                            [label]="'Selected ' + (component_type.attributes.base_type | upperfirst)  +' (view only)'"
                                            [value]="null"
                                            [disable_items]="true"
                                            class="select-search flex-half alone">
              </select-many-search-component>
              <!--              </div>-->
            </div>

            <div class="row">
              <button (click)="goToOreBodyTab(component_type_tabs)" *ngIf="component_type.id" class="bordered-button"
                      mat-button>
                Edit {{component_type.attributes.base_type}} constants
                <i class="fa fa-caret-right" style="margin-left:10px;height: 21px;font-size: 18px;"></i>
              </button>
            </div>
          </ng-container>
        </form>
      </mat-tab>

      <mat-tab label="Auto-generation Config" *ngIf="component_type?.id">
        <ng-template matTabContent>
          <component-name-formula-builder [componentType]="component_type"
                                          [config]="component_type.attributes.json"
                                          (configChanged)="component_type.attributes.json=$event;">

          </component-name-formula-builder>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="component_type?.id" label="Constant Properties" style="width:210px;">

        <ng-template matTabContent>
          <form *ngIf="component_type" class="form-section column space-between">

            <div style="height:470px;max-height:470px;">
              <constant-property-table-form
                  [parent_component]="component_type"
              ></constant-property-table-form>
            </div>
          </form>
        </ng-template>
      </mat-tab>

      <mat-tab label="Custom Constants" *ngIf="component_type?.id">
        <ng-template matTabContent>
          <form class="form-section column space-between">

            <label style="margin-top:20px;"> Use the table(s) below to edit the constant property values for your
              selected {{component_type.attributes.base_type}}</label>
            <component-constants-table [component_type]="component_type"></component-constants-table>

          </form>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save">Save</button>
    <button (click)="apply()" class="btn-save">Apply</button>
  </div>
</div>
