import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, ReplaySubject, Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {ApiService} from "../../../services/api/api.service";
import {SearchQueryOptions} from "../../../services/api/search-query-options";

@Injectable({
    providedIn: 'root'
})
export class EventModelUploadDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    private event_type$ = new Subject();
    private loadDataSubscription: Subscription;

    public event_type_id$: Subject<string> = new ReplaySubject(1);

    public component_types$ = new BehaviorSubject([]);
    public isLoading$ = new BehaviorSubject(true);
    public event_type_property_filter;

    // This is needed so that other components can reload themselves after a template has been selected
    public options$ = new Subject();

    constructor(private api: ApiService) {
    }

    changeEventType(event_type_id: string) {
        this.event_type_id$.next(event_type_id);

        const s$ = [];
        this.event_type_property_filter = [{
            name: 'event_types',
            op: 'any',
            val: {name: 'id', op: 'eq', val: event_type_id}
        }];

        const options = new SearchQueryOptions();
        options.filters = this.event_type_property_filter;

        s$.push(this.api.component_type.searchMany(options).pipe(tap(response => {
            this.component_types$.next(response.data);
        })));

        if (this.loadDataSubscription) {
            this.loadDataSubscription.unsubscribe();
        }

        this.isLoading$.next(true);

        this.loadDataSubscription = combineLatest(s$).pipe(takeUntil(this.onDestroy)).subscribe(r => {
            this.isLoading$.next(false);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
