import { EventConfigColumn } from "../../../_typing/config/config-column";

interface ISelectedColumnsType {
    componentType: EventConfigColumn[];
    event: EventConfigColumn[];
    component: Record<string, EventConfigColumn[]>;
  }

export class SelectedColumns implements ISelectedColumnsType {
    componentType: EventConfigColumn[] = [];
    event: EventConfigColumn[] = [];
    component: Record<string, EventConfigColumn[]> = {};
  }
