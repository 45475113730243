import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {NotificationComponentBase} from '../../_typing/notification';
import {take, tap} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {Series} from "../../_models/series";
import {SeriesDataService} from "../../services/series_data.service";

@Component({
    selector: 'calculation-error',
    templateUrl: './calculation-error-notification.component.html',
    styleUrls: ['./calculation-error-notification.component.less'],
    standalone: false
})
export class CalculationErrorNotificationComponent extends NotificationComponentBase {
    errorDetails: string;
    errorMessage: string;
    series: Series;

    constructor(public snackBarRef: MatSnackBarRef<any>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any,
                private seriesData: SeriesDataService) {
        super(snackBarRef, data);
        const seriesName = data.seriesName;
        this.errorMessage = data.errorMessage;
        this.errorDetails = data.errorDetails;
        console.log(data.seriesName);
        this.seriesData.getSeriesLightListByName([seriesName]).pipe(tap((seriesList: ListResponse<Series>) => {
            this.series = seriesList.data?.[0];
        }),take(1)).subscribe();
    }

    editSeries() {
        this.seriesData.upsertSeries(null, this.series)
    }
}
