import {BaseViewModel} from "./base-viewmodel";

export class PointViewModel extends BaseViewModel {
    constructor(pointDataModel) {
        super(pointDataModel)
    }

    x() {
        return this.data.x
    };

    y() {
        return this.data.y
    };
}
