import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {takeUntil, first, take, switchMap, tap} from "rxjs/operators";
import {DateTimePeriodService} from '../../services/date-time-period.service';
import {CommentsTableService} from "../comments-table/comments-table.service";
import {CommentsTableConfig} from '../../forms/comments-table-form/comments-table-form.component';
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {TableUtilsService} from "../../tables/table-utils.service";
import {TileDataService} from "../../services/tile_data.service";
import {CommentTableComment} from "../../_models/api/comment-table";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {BaseCommentTable} from "../comments-table/base-comment-table";
import {ApiService} from "../../services/api/api.service";
import {NotificationService} from "../../services/notification.service";
import {CustomEventsService} from "../../services/custom-events.service";
import {ListResponse} from "../../services/api/response-types";
import {ErrorBankService} from "../../services/error-bank.service";

@Component({
    selector: 'page-comment-table',
    templateUrl: '../comments-table/comments-table.component.html',
    styleUrls: ['../comments-table/comments-table.component.less'],
    providers: [CommentsTableService, ErrorBankService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PageCommentTableComponent extends BaseCommentTable {


    protected _config: CommentsTableConfig;
    get config(): CommentsTableConfig {
        return this._config
    }

    @Input()
    set config(config: CommentsTableConfig) {
        this.cps.config = config;
        this._config = config;
    }

    @Input() current_page_id: string | null;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private cps: CommentsTableService,
                tableUtils: TableUtilsService,
                tileData: TileDataService,
                api: ApiService,
                notification: NotificationService,
                customEventsService: CustomEventsService,
                public errorBank:ErrorBankService) {
        super(tableUtils, tileData, customEventsService, api, notification,errorBank);
    }

    ngOnInit(): void {
        this.cps.dataReady.pipe(takeUntil(this.onDestroy)).subscribe(data => {
            /**When the series list changes (e.g. on analysis tools), this runs when new series has been fetched**/
            this.emitFilterQuery();
        });
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dtp = this.dateInst.dtp;
            const initialQuery = this.cps.getInitialSearchOptions(this.config, 'comment');
            this.columns = this.cps.defineColumns(this.config, 'comment');
            this.columnIds = Object.keys(this.columns);
            if (this.config.allow_delete && !this.columnIds.includes('select')) {
                this.columnIds.unshift('select');
            }
            this.paginator.pageSize = this.pageSize;
            this.dataSource = new PaginationDataSource<CommentTableComment>(
                (query: SearchQueryOptions) => this.cps.pageComments(query),
                initialQuery,
                this.paginator,
                this.sort
            )
            this.dataSource.$page.pipe(tap((result: ListResponse<CommentTableComment>) => {
                this.comments = result.data;
                }), takeUntil(this.onDestroy)).subscribe();

        });

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
            this.dtp = dtp;
            this.emitFilterQuery();
        });
        this.setConfigData(this.config.columns);

    }

    emitFilterQuery() {
        const filters = this.cps.getEventsFilter(this.config, 'comment');
        this.dataSource.filterBy(filters);
    }

    updateSearchFilter() {
        let filters: any = this.cps.getEventsFilter(this.config, 'comment');
        if (!this.filterString) {
            this.dataSource.filterBy(filters);
            return;
        }
        filters = filters.concat(this.cps.getPageSearchFilters(this.filterString));
        this.dataSource.filterBy(filters);
    }

    updateSort(event) {
        const allowed = ['start', 'end', 'comment'];
        if (!allowed.includes(this.sort.active)) return;
        this.dataSource.sortBy(this.sort)
    }
}
