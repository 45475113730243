import {Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, ElementRef, Input} from '@angular/core';
import {NotificationService} from "../../services/notification.service";


@Component({
    selector: 'drop-file-selector',
    templateUrl: './drop-file-selector.component.html',
    styleUrls: ['./drop-file-selector.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DropFileSelectorComponent implements OnInit {
    @ViewChild('fileDropInput') fileDropInput: ElementRef;

    private _file = null;

    @Input() set file(value: File) {
        this._file = value;
        if (!value && this.fileDropInput?.nativeElement) {
            this.fileDropInput.nativeElement.value = null;
        }
    }

    get file(): File {
        return this._file;
    }

    @Input() instruction = "Drag and drop to upload a CSV file"
    @Output() selectionChanged: EventEmitter<File> = new EventEmitter();
    preview = null;

    constructor(private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    clear() {
        this.preview = null;
        this.file = null;
    }

    handleFileInput(target: HTMLInputElement | EventTarget) {
        if ("files" in target) {
            const files = target.files;
            this.file = files.item(0);
            this.selectionChanged.emit(this.file);
        } else {
            this.notification.openError('Selected input element did not have files property.', 10000);
            console.error('Selected input element did not have files property.');
        }
    }

}
