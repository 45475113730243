import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DateTimePeriodService} from '../../services/date-time-period.service';
import {IRelativeDtpConfig} from "../../_typing/date-time-period";

@Component({
    selector: 'relative-date-period',
    templateUrl: './relative-date-period.component.html',
    styleUrls: ['./relative-date-period.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RelativeDatePeriodComponent implements OnInit {
    @Input() config: IRelativeDtpConfig;
    @Input() disabled: boolean = false;

    duration_options: string[] = ['hour', 'day', 'week', 'month', 'year'];
    sample_periods: string[];

    constructor(private dateTimePeriodService: DateTimePeriodService) {

    }

    ngOnInit(): void {
        if (!this.config) {
            this.config = {
                from: {duration: 1, key: 'day'},
                to: {duration: 0, key: 'day'},
                relative_to: 'start',
                sample_period: 'hour'
            };
        }
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.sample_periods = this.dateTimePeriodService.sample_periods.map(sp => sp.name);
        });
    }

}
