import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from "../../../../shared/base.component";
import {ModelID} from "../../../../_typing/generic-types";
import {ComponentTypeFieldOptionService} from "../component-type-field-option.service";
import {EventTypeFieldOptionService} from "../event-type-field-option.service";
import {FieldMap, Option, QueryBuilderConfigType, Rule} from "../../../../_typing/query-builder";


@Component({
    selector: 'field-option',
    templateUrl: './field-option.component.html',
    styleUrls: ['./field-option.component.less'],
    standalone: false
})
export class FieldOptionComponent extends BaseComponent {
    @Input() modelTypeId: ModelID;
    @Input() rule: Rule;
    @Input() value: string;
    @Input() readonly fields: FieldMap;
    @Output() selectedFieldChanged: EventEmitter<string> = new EventEmitter();

    fieldOptions: Option[];

    constructor() {
        super();
    }

    ngOnInit() {
        //For now only allow validation on datetime
        this.fieldOptions = Object.values(this.fields)?.filter(f=>f.type==='datetime')?.map(f => {
            return {name: f.name, value: f.value};
        });
    }

    setSelectedField(event: string): any {
        this.selectedFieldChanged.next(event);
    }

}
