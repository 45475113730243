import {Component, Inject, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../services/api/api.service";
import * as utils from "../../lib/utils";
import {Series} from "../../_models/series";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {CsvDownloadService} from "../../services/csv-download.service";
import {ConstantProperty} from "../../_models/constant-property";
import {NotificationService} from "../../services/notification.service";
import {ListResponse} from "../../services/api/response-types";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'FileDownloadForm',
    templateUrl: 'file_download_form.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class DownloadFormDialogComponent implements OnInit {
    public selected_data: any = {process: null};
    public process_list: any[] = [];
    showing_hints: boolean = false;
    hint: string = 'Name';
    full_series_list: Series[] = [];
    @Input()
    selected_series: Series[] = [];
    componentTypes = [];
    selectAllConstantProperties: boolean;
    selectedConstantProperties: ConstantProperty[] = [];
    selectedComponentType;
    allComponentTypeConstantProperties: ConstantProperty[] = [];
    selectedTabIndex = 0;

    constructor(
        public api: ApiService,
        public dialogRef: MatDialogRef<DownloadFormDialogComponent>,
        private dateInst: DateTimeInstanceService,
        public csvDownloadService: CsvDownloadService,
        private notification: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
        const ctrl = this;

        const searchQuery = new SearchQueryOptions();
        let filters = [];
        // this option is to filter out only process with series data in by assuming that these processes will appear in the mapping table
        let filter = [
            {
                'and':
                    [
                        {name: 'base_type', op: "eq", val: "process"},
                        {name: 'custom_series', op: '<>', val: []}
                    ]
            }
        ];

        filters.push(filter);

        this.api.process_light.searchMany(searchQuery).subscribe(
            (result) => {
                this.process_list = result.data;
            }
        );

        ctrl.api.series_light.searchMany().subscribe((result: ListResponse<any>) => {
            ctrl.full_series_list = result.data;
        });

        if (this.data) {
            ctrl.selected_series = ctrl.data;
        }

        this.fetchComponentTypes();
    }

    fetchComponentTypes() {
        this.api.component_type.searchMany().subscribe((response) => {
            this.componentTypes = response.data;
        });
    }

    selectComponentType(componentTypeId) {
        this.fetchConstantProperties(componentTypeId);
    }

    fetchConstantProperties(component_type) {
        const options = new SearchQueryOptions();
        options.filters = [
            {
                name: 'component_types',
                op: 'any',
                val: {name: 'id', op: 'in', val: [component_type]}
            }
        ];

        this.api.constant_property_light.searchMany(options).subscribe((response) => {
            this.allComponentTypeConstantProperties = response.data;
        });
    }

    clearTabData(event = null) {
        if (event.index === 0) {
            this.selectedComponentType = [];
        } else if (event.index === 1) {
            this.selected_data.process = '';
            this.selected_series = [];
        }
    }

    downloadComponentTypeData() {
        if (this.selectedComponentType) {
            if (this.selectAllConstantProperties) {
                this.csvDownloadService.downloadComponentDataCsv(
                    this.dateInst.dtp.start.toISOString(),
                    this.dateInst.dtp.end.toISOString(), this.selectedComponentType.id);
            } else {
                if (this.selectedConstantProperties.length === 0) {
                    this.notification.openError('Please select a Component Type', 2000);
                } else {
                    this.csvDownloadService.downloadComponentDataCsv(
                        this.dateInst.dtp.start.toISOString(),
                        this.dateInst.dtp.end.toISOString(),
                        this.selectedComponentType.id,
                        this.selectedConstantProperties.map(constantProperties => constantProperties.id)
                    );
                }
            }
        } else {
            this.notification.openError('Please select constant properties or select all', 2000);
        }
    }

    downloadData() {
        let process_id;
        if (this.selected_data && this.selected_data.process && this.selected_data.process.id) {
            process_id = this.selected_data.process.id;
        }
        let params = {
            start: this.dateInst.dtp.start.toISOString(),
            end: this.dateInst.dtp.end.toISOString(),
            sample_period: this.dateInst.dtp.sample_period.wire_sample
        };
        if (process_id) {
            params['process'] = process_id;
        }
        if (this.selected_series) {
            params['series_list'] = this.selected_series.map(series => {
                return series.id;
            });
        }
        window.open("/api/GetExcel" + '?' + utils.httpParamSerializer(params));
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}
