import {Injectable, OnDestroy} from '@angular/core';
import {ApiService} from "./api/api.service";
import {Subject} from "rxjs";
import {Rule, RuleSet} from 'ngx-angular-query-builder';
import {deepCopy} from '../lib/utils';

@Injectable()
export class QueryBuilderService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    operatorMap = {
        '=': 'eq',
        '!=': 'ne',
        '>': 'gt',
        '>=': 'ge',
        '<': 'lt',
        '<=': 'le',
        'not in': 'notin_',
        'not like': 'notlike',
        'not ilike': 'notilike'
    };

    inverseOperator = {
        '!=': '=',
        'not in': 'in',
        'not like': 'like',
        'not ilike': 'ilike',
    };

    singleRelationships = ["changed_by", "created_by"];

    constructor(private api: ApiService) {
    }

    toJsonQuery(query: RuleSet, modelType = 'COMPONENT') {
        let rules = deepCopy(query).rules.map((rule: RuleSet | Rule) => {
            if ('field' in rule) {
                return this.toJsonQueryRule(rule, modelType);
            } else if ('condition' in rule) {
                return this.toJsonQuery(rule, modelType);
            }
        });
        rules = rules.filter(rule => rule);
        return {[query.condition]: rules};
    }

    toJsonQueryRule(rule: Rule, modelType: string) {
        let cp_match = rule.field.match(/\[(.*?)@(.*?)\]/);
        rule.value = this.wrapILikeValue(rule);
        rule = this.checkIsBlank(rule);
        if (!cp_match) {
            return this.attributeQuery(rule);
        } else if (cp_match[1] === 'constant_property') {
            if (!rule.value && rule.value !== 0) {
                return this.constantPropertyQueryNull(rule, cp_match[2], modelType);
            } else {
                return this.constantPropertyQuery(rule, cp_match[2], modelType);
            }
        } else if (modelType === 'COMPONENT') {
            if (cp_match[1] === 'component_type') {
                return this.componentComponentTypeQuery(rule, cp_match[2]);
            } else if (cp_match[1] === 'event_type') {
                return this.componentEventTypeQuery(rule, cp_match[2]);
            }
        } else if (modelType === 'EVENT') {
            if (cp_match[1] === 'component_type') {
                return this.eventComponentTypeQuery(rule, cp_match[2]);
            }
        }
    }

    checkIsBlank(rule) {
        if (rule.operator === 'blank') {
            rule.operator = '=';
            rule.value = null;
        } else if (rule.operator === 'not blank') {
            rule.operator = '!=';
            rule.value = null;
        }
        return rule;
    }

    wrapILikeValue(rule) {
        if (rule.operator.includes('like') && !rule.value?.includes('%')) {
            rule.value = `%${rule.value}%`;
        }
        return rule.value;
    }

    attributeQuery(rule: Rule) {
        let op = this.operatorMap[rule.operator] || rule.operator;
        if (this.singleRelationships.includes(rule.field)) return this.relationshipQuery(rule)
        if (rule.value) {
            return {'name': rule.field, 'op': op, 'val': rule.value};
        } else {
            op = rule.operator === '=' ? 'eq' : 'ne';
            return {'name': rule.field, 'op': op, val: null};
        }
    }

    relationshipQuery(rule) {
        let op = this.operatorMap[rule.operator] || rule.operator;
        if (rule.value) {
            return {name: rule.field, op: 'has', val: {name: 'id', op: op, val: rule.value}}
        } else {
            op = rule.operator === '=' ? 'eq' : 'ne';
            return {'name': rule.field, 'op': op, val: null};
        }
    }

    constantPropertyQuery(rule: Rule, constant_property_id: string, modelType: string) {
        let isInverse = false;
        let op = this.operatorMap[rule.operator] || rule.operator;
        if (rule.operator in this.inverseOperator) {
            op = this.operatorMap[this.inverseOperator[rule.operator]] || this.inverseOperator[rule.operator];
            isInverse = true;
        }

        let value_column = '';
        if (typeof (rule.value) === "number") {
            value_column = 'value_float';
        } else if (rule.value.match(/\d{4}-\d{1,2}-\d{1,2}/) && Date.parse(rule.value)) {
            value_column = 'value_date';
        } else {
            value_column = 'value';
        }
        let tableName = modelType === 'COMPONENT' ? 'constant_components' : 'event_constants';
        let query: any = {
            'name': tableName,
            'op': 'any',
            'val': {
                'and': [
                    {
                        'and': [
                            {
                                'name': 'value',
                                'op': 'isnot',
                                'val': null
                            },
                            {
                                'name': 'value',
                                'op': 'ne',
                                'val': ''
                            },
                            {
                                'name': 'value',
                                'op': 'notin_',
                                'val': ['None', 'nan']
                            }
                        ]
                    },
                    {
                        'name': value_column,
                        'op': op,
                        'val': rule.value ?? null
                    },
                    {
                        'name': 'constant_property',
                        'op': 'has',
                        'val': {'name': 'id', 'op': 'eq', 'val': constant_property_id}
                    }
                ]
            }
        };

        if (isInverse) {
            query = {'not': query};
        }

        return query;
    }

    constantPropertyQueryNull(rule: Rule, constant_property_id: string, modelType: string) {
        let isNull = rule.operator === '=' ? true : false;
        let tableName = modelType === 'COMPONENT' ? 'constant_components' : 'event_constants';
        let query: any = {
            'name': tableName,
            'op': 'any',
            'val': {
                'and': [
                    {
                        'and': [
                            {
                                'name': 'value',
                                'op': 'isnot',
                                'val': null
                            },
                            {
                                'name': 'value',
                                'op': 'ne',
                                'val': ''
                            },
                            {
                                'name': 'value',
                                'op': 'notin_',
                                'val': ['None', 'nan']
                            }
                        ]
                    },
                    {
                        'name': 'constant_property',
                        'op': 'has',
                        'val': {'name': 'id', 'op': 'eq', 'val': constant_property_id}
                    }
                ]
            }
        };

        if (isNull) {
            query = {'not': query};
        }
        return query;
    }

    componentComponentTypeQuery(rule: Rule, component_type: string) {
        let query: any = {
            name: 'component_component',
            op: 'any',
            val: {
                or: [
                    {
                        name: 'first_component',
                        op: 'has',
                        val: {name: 'component_type', op: 'has', val: {name: 'id', op: 'eq', val: component_type}}
                    },
                    {
                        name: 'second_component',
                        op: 'has',
                        val: {name: 'component_type', op: 'has', val: {name: 'id', op: 'eq', val: component_type}}
                    }
                ]
            }
        };

        if (!rule.value) {
            query = {'not': query};
        }
        return query;
    }

    componentEventTypeQuery(rule: Rule, event_type: string) {
        let query: any = {
            name: 'event_components',
            op: 'any',
            val: {
                name: 'event',
                op: 'has',
                val: {name: 'event_type', op: 'has', val: {name: 'id', op: 'eq', val: event_type}}
            }
        };

        if (!rule.value) {
            query = {'not': query};
        }
        return query;
    }

    eventComponentTypeQuery(rule: Rule, component_type: string) {
        let query: any = {
            name: 'event_components',
            op: 'any',
            val: {
                name: 'component',
                op: 'has',
                val: {name: 'component_type', op: 'has', val: {name: 'id', op: 'eq', val: component_type}}
            }
        };

        if (!rule.value) {
            query = {'not': query};
        }
        return query;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
