import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

interface EventComponentParameters {
    event_id?: string,
    component_id?: string
}

export class EventComponentAttributes extends AuditMixinAttributes {
    locked?: boolean;
}

export class EventComponentRelationships extends AuditMixinRelationships {
    event: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    component: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;

    constructor({event_id = null, component_id = null}: EventComponentParameters = null) {
        super();
        this.event.data = {id: event_id, type: 'event'};
        this.component.data = {id: component_id, type: 'component'};
    }
}

export class EventComponent extends AuditMixin {
    attributes: EventComponentAttributes;
    id: string;
    type: string = 'event_component';
    relationships: EventComponentRelationships;

    constructor({event_id = null, component_id = null}: EventComponentParameters) {
        super();
        this.attributes = new EventComponentAttributes();
        this.relationships = new EventComponentRelationships({event_id: event_id, component_id: component_id});
    }
}
