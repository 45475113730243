<div *ngIf="available_columns && model_dict" class="row column-selector">
  <div class="draggable-list-container">
    <h2>Selected Columns</h2>
    <option-list-search
        [placeholder]="'Search columns'"
        (inputChanged)="selectedColumnFilterValue=$event"
        (inputCleared)="selectedColumnFilterValue=''"
    ></option-list-search>
    <div
        id="selectedList"
        #selectedList="cdkDropList"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[availableList]"
        [cdkDropListData]="selected_columns"
        cdkDropList
        class="draggable-list">

      <div
          *ngFor="let column of selected_columns | filterByValue: columnFilter.bind(this) : selectedColumnFilterValue"
          cdkDrag [cdkDragData]="column" class="draggable-list-item"
          style="display:flex; justify-content: space-between;">
        <div [ngStyle]="{'font-weight':column.format?.bold? 'bold' : '',
            'font-style':column.format?.italic? 'italic' : '',
            'font-size': column.format?.size ? column.format.size + '%' : '100%'}"
             class="column-name" cdkDragHandle
             [dblClickCopy]="model_dict[column.id]?.attributes.name || column.id">
          <div>{{(model_dict[column.id]?.attributes.name || column.id) | upperfirst}}</div>
        </div>
        <div class="column-title">
          <input [(ngModel)]="column.title"
                 matInput
                 placeholder="Column name"
                 name="Column name" style="width: auto"/>
        </div>
        <ng-container *ngIf="allowDefaults">
          <default-value-field
              [field]="column"
              [constantProperty]="model_dict[column.id]"
              [panelClass]="panelClass"
              class="default-value-field">
          </default-value-field>
        </ng-container>

        <div *ngIf="colMenuOptions?.length" style="display: flex; justify-content: right;">
          <div style="display: flex; align-items: center;">
            <div style="margin-left:10px;">
              <button [matMenuTriggerFor]="columnMenu" class="formatting-button" mat-button
                      matTooltip="Format column" matTooltipPosition="above"
                      style="margin:0;background-color:#8b8c8e;" type="button">
                <i class="fa fa-bars"></i>
              </button>

              <mat-menu #columnMenu="matMenu" class="column-menu dark series-formatting-menu">
                <table-column-menu [item]="column.format"
                                   [options]="colMenuOptions"
                                   (formatsChanged)="column.format = $event;formatsChanged($event)"
                                   [default_values]="default_format_values">
                </table-column-menu>

              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="column" style="margin:50px 0 0 0;cursor:pointer;">
    <i (click)="moveColumns('select')"
       class="fas fa-angle-double-left" matTooltip="Select all available columns"
       style="padding:10px;border: 1px solid #ccc;border-radius: 4px;margin-bottom:10px;"></i>
    <i (click)="moveColumns('remove')"
       class="fas fa-angle-double-right" matTooltip="Remove all selected columns"
       style="padding:10px;border: 1px solid #ccc;border-radius: 4px;"></i>
  </div>

  <div class="draggable-list-container">
    <h2>Available Columns</h2>
    <option-list-search
        [placeholder]="'Search columns'"
        (inputChanged)="availableColumnFilterValue=$event"
        (inputCleared)="availableColumnFilterValue=''"
    ></option-list-search>
    <div
        id="availableList"
        #availableList="cdkDropList"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[selectedList]"
        [cdkDropListData]="available_columns"
        cdkDropList
        class="draggable-list">
      <div
          *ngFor="let column of available_columns | filterByValue: columnFilter.bind(this) : availableColumnFilterValue"
          cdkDrag
          [cdkDragData]="column"
          class="draggable-list-item"
          matTooltip="{{model_dict[column.id]?.description ? model_dict[column.id].description:''}}"
          style="display:flex; justify-content: space-between;"
          [dblClickCopy]="model_dict[column.id]?.attributes.name || column.id">
        <div>{{(model_dict[column.id]?.attributes.name || column.id) | upperfirst}}</div>
        <div *ngIf="!column.type?.includes('attribute')" style="text-align:right;">
          ({{column.type | removeUnderscore | upperfirst}}
          <span *ngIf="model_dict[column.id]?.attributes.is_calculation">, calculation</span>
          <span>)</span>
        </div>
      </div>
    </div>
  </div>
</div>
