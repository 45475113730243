import {Component, OnDestroy, OnInit} from "@angular/core";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {ApiService} from "../../services/api/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Subject, timer} from "rxjs";
import {switchMap, takeUntil, tap} from "rxjs/operators";
import {Tile as TileModel} from "../../_models/tile";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'log-sheet-focus-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="sheet_ready" [dtp]="dateInst.dtp" [tile]="tile" (tileChange)="tileChange($event)" class="column-tile tile-dtp"></page-tile>' +
        '</div>',
    standalone: false
})
export class LogSheetFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    tile: TileModel;
    sheet_ready: boolean;
    process: any;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            this.sheet_ready = false;
            ctrl.api.process.getById(params.processID).toPromise().then(response => {
                ctrl.process = response.data;
                this.plantData.setActiveProcess(this.process);
                ctrl.buildHeader();

                ctrl.tile = new TileModel();
                ctrl.tile.attributes.content = 'log-sheet';
                ctrl.tile.attributes.hide_edit = true;
                ctrl.tile.attributes.parameters = {
                    process: {id: ctrl.process.id}, series_list: [],
                    columns: ['Value', 'Forecast'],
                    mobile_columns: ['Description', 'Value', 'MTD'],
                    title: ctrl.process.attributes.name
                };

                ctrl.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
                    this.dateInst.dtp = this.dateTimePeriodService.setDefault(this.dateInst.dtp);
                    this.sheet_ready = true;
                });
            });
        });
        this.headerData.refreshTileSubject.pipe(switchMap(id => {
            this.sheet_ready = false;
            return timer(100).pipe(tap(() => {
                this.sheet_ready = true;
            }))
        }), takeUntil(this.onDestroy)).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    addSeries() {
        this.seriesData.upsertSeries(this.process).afterClosed().subscribe((series) => {
            this.dateInst.dateTimePeriodRefreshed$.next(this.dateInst.dtp)
        })
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Log Sheet: ' + ctrl.process.attributes.name;
        ctrl.headerData.setPath(ctrl.process);
        ctrl.headerData.show_dtp = true;

        ctrl.headerData.buttons = [{
            name: "Add",
            func: this.addSeries.bind(ctrl),
            params: {ctrl: this},
            class: 'icon-add'
        }];
        ctrl.headerData.addDownload();
    }
}
