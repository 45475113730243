import {Injectable, OnDestroy} from "@angular/core";
import {Subject, ReplaySubject, Observable, forkJoin, EmptyError, EMPTY} from "rxjs";
import {ApiService} from "./api/api.service";
import {deepCopy, filterUnfetchedIds, httpParamSerializer} from "../lib/utils";
import {takeUntil, tap, map, concatMap, catchError} from "rxjs/operators";
import {ListResponse} from "./api/response-types";
import {ConstantProperty} from "../_models/constant-property";
import {ConstantPropertyDataService} from "../data/constant-property-data.service";
import {ModelID, KeyMap, ModelName, IDMap} from '../_typing/generic-types';
import {PivotTileConfig} from "../_typing/config/pivot-tile";
import {SeriesDataService} from "./series_data.service";
import {FormatNumberPipe} from "../shared/pipes";
import {CustomEventsService} from "./custom-events.service";
import {DateTimeInstanceService} from "./date-time-instance.service";
import {ITileButton} from "../_typing/tile-button";

@Injectable({
    providedIn: 'root'
})
export class PivotService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private cp_dict: IDMap<ConstantProperty> = {};
    cp_name_id_map: KeyMap<string> = {};

    constructor(private propertyData: ConstantPropertyDataService,
                private dateInst: DateTimeInstanceService,
                private api: ApiService, private seriesData: SeriesDataService,
                private formatNumber: FormatNumberPipe,
                private customEventsService: CustomEventsService) {
    }

    getConstantPropertyNameDict(ids: ModelID[], dict: KeyMap<ModelName>) {
        const new_prop_ids = filterUnfetchedIds(ids, dict);
        if (!new_prop_ids) return;
        let cpDict: KeyMap<ModelName> = {};
        return this.propertyData.getConstantProperties(new_prop_ids)
            .pipe(takeUntil(this.onDestroy), map((result: ListResponse<ConstantProperty>) => {
                result.data.map(cp => {
                    this.cp_dict[cp.id] = cp;
                    this.cp_name_id_map[cp.attributes.name] = cp.id;
                    cpDict[cp.id] = cp.attributes.name;
                });
                return Object.assign(cpDict, dict);
            }))
    }

    getComponentOrEventData(config: PivotTileConfig, fields): Observable<any> {
        let ids = config.event_type_ids?.length? config.event_type_ids : config.component_type_ids;
        let relation:'event_type' | 'component_type' = config.event_type_ids?.length? 'event_type' : 'component_type';
        let qbFilter = (config.query && Object.keys(config.query)?.length) ? this.customEventsService.generateQueryBuilderFilter(ids, config.query, relation, "or") : null;

        let vars = {
            start: this.dateInst.dtp.start.toISOString(),
            end: this.dateInst.dtp.end.toISOString(),
            event_type_ids: config.event_type_ids || null,
            component_type_ids: config.component_type_ids || null,
            fields: fields,
            constant_property_time: JSON.stringify(config.constant_property_time || {}),
            attribute_time: JSON.stringify(config.attribute_time || {}),
            bypass_date: config.bypass_date_filters,
            filter: JSON.stringify(qbFilter)
        };

        Object.keys(vars).forEach(key => {
            if (!vars[key]) {
                delete vars[key];
            }
        });

        return this.api.get('/api/data/pivot?' + httpParamSerializer(vars)).pipe(map(data => {
                let fieldsDefault = data['fields'].reduce((row, field) => {
                    row[field] = null;
                    return row;
                }, {});
                let rows = data['rows'];
                // only setting the fields for the first row will do the trick, no need to fill all the missing fields for each row
                rows[0] = Object.assign({...fieldsDefault}, rows[0] || []);
                let pivotData = rows;
                this.formatNumberValues(pivotData, config);
                this.formatNulls(pivotData);
                this.formatDateTime(pivotData);
                return pivotData;
            }),
            catchError(e => {
                console.log("ERROR: PivotTileComponent (getData) \n Error fetching pivot data", e);
                return EMPTY
            }))
    }

    getTimeSeriesDataObs(config: PivotTileConfig, include_gss = false): Observable<any> {
        let series_list = config.series_list.map((series) => {
            return series.id;
        });

        let $time_series_data: Observable<any>[] = [];
        config.series_list.forEach(series => {
            let wire_sample_period = null;

            let data_set: Observable<any> = this.api.get_series_data({
                series_list: [series.id],
                start: this.dateInst.dtp.start.toISOString(),
                end: this.dateInst.dtp.end.toISOString(),
                sample_period: wire_sample_period ? wire_sample_period : this.dateInst.dtp.sample_period.wire_sample,
                pivot: true
            });
            $time_series_data.push(data_set);
        });

        if (include_gss) {
            const $gss = this.seriesData.getSeriesSummary(this.dateInst.dtp, config.series_list.map(s => s.id), null, ['MTD']);
            $time_series_data.unshift($gss);
        }
        return forkJoin($time_series_data).pipe(takeUntil(this.onDestroy),
            map((data: any[]) => {
                let seriesPivotData = [];
                data.forEach(series_get_data_data => {
                    if (series_get_data_data === data[0]) {
                        return;
                    }
                    let pivotData = series_get_data_data.data;
                    let missing_values_array = [];
                    let series_names = Object.keys(series_get_data_data['missing_values']);

                    series_names.map(name => {
                        let times = Object.keys(series_get_data_data['missing_values'][name]);
                        times.map(time => {
                                let pivot_object = {};
                                pivot_object['timestamp'] = time;
                                pivot_object['series'] = name;
                                pivot_object['missing_values'] = series_get_data_data['missing_values'][name][time];
                                missing_values_array.push(pivot_object);
                            }
                        );
                    });
                    missing_values_array.map(el => {
                            if (el.missing_values === false) {
                                el.missing_values = 'Existing';
                            } else {
                                el.missing_values = 'Missing';
                            }
                        }
                    );
                    pivotData = pivotData.concat(missing_values_array);
                    seriesPivotData = seriesPivotData.concat(pivotData);
                });
                this.formatDateTime(seriesPivotData);
                return seriesPivotData;
            })
        )
    }

    private formatDateTime(pivotData): void {
        pivotData.map((item) => {
            Object.keys(item).forEach((prop) => {
                if (prop === 'Start' || prop === 'End') {
                    item[prop + '_Time'] = item[prop] ? this.dateInst.dtp.sample_period.format(
                        deepCopy(item[prop]), this.dateInst.dtp, null, true) : "";
                }
                if (prop === 'timestamp') {
                    item['Timestamp'] = item[prop] ? this.dateInst.dtp.sample_period.format(
                        deepCopy(item[prop]), this.dateInst.dtp, null, true) : "";
                }
            });
        });
    }

    private formatNulls(pivotData): void {
        const headers = Object.keys(pivotData[0]);

        for (let i = 0; i < pivotData.length; i++) {
            const item = pivotData[i];
            const item_props = Object.keys(item);

            headers.forEach(h => {
                if (!item_props.includes(h)) {
                    pivotData[i][h] = "";
                }
            });

            item_props.forEach((prop) => {
                if (!item[prop] && item[prop] !== 0) {
                    pivotData[i][prop] = "";
                }
            });
        }
    }

    private formatNumberValues(pivotData, config: PivotTileConfig) {
        pivotData.map((item) => {
            Object.keys(item).forEach((prop) => {
                const places = config.property_customisations?.[this.cp_name_id_map[prop]]?.decimal_places;
                if (places) {
                    item[prop] = this.formatNumber.transform(item[prop], places);
                }
            });
        });
        // let places = (this.config.decimal_places || this.config.decimal_places === 0) ? this.config.decimal_places :
        //     (series?.attributes.decimal_places || series?.attributes.decimal_places === 0) ? series?.attributes.decimal_places : 2;
    }

    getPivotTileButtons(): ITileButton[] {
        return [
            {
                name: 'Edit',
                func: null,
                params: {},
                class: 'fa small fa-edit hide-xs',
                HoverOverHint: 'Edit Pivot Table '
            },
            {
                name: 'Save',
                func: null,
                params: {},
                class: 'fa small fa-save hide-xs',
                HoverOverHint: 'Save Pivot Table'
            }
        ]
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
