import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import { HttpClient } from "@angular/common/http";
import {tap} from "rxjs/operators";

import {ListResponse} from "../../services/api/response-types";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'file-upload',
    templateUrl: 'upload-form.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UploadFormDialogComponent implements OnInit {
    file: any;
    add_mappers: boolean = false;
    non_time_series: boolean = false;
    add_series: boolean = false;
    row_major: boolean = false;
    override: boolean = false;
    collector: any;
    fileToUpload: File = null;
    showing_hints: boolean = false;
    hint: string = 'Name';

    constructor(
        public api: ApiService,
        public http: HttpClient,
        public dialogRef: MatDialogRef<UploadFormDialogComponent>,
        public dateTimePeriodService: DateTimePeriodService,
        private notification: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
    }

    handleFileInput(file: File) {
        this.fileToUpload = file;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    uploadData() {
        const formData: FormData = new FormData();
        formData.append('file', this.fileToUpload, this.fileToUpload.name);
        formData.append('create_mappers', this.add_mappers.toString());
        formData.append('create_series_list', this.add_series.toString());
        formData.append('row_major', this.row_major.toString());
        formData.append('override', this.override.toString());
        formData.append('name', this.collector.attributes.name);
        formData.append('filename', this.fileToUpload.name);
        formData.append('non_time_series', this.non_time_series.toString());

        this.http.post('/CollectData', formData).pipe(
            tap({
                next: (response: any): void => {
                    if (Array.isArray(response.errors)) {
                        this.notification.openError(response.errors.join(''));
                    } else {
                        this.notification.openSuccess('File collected successfully.', 3000);
                    }
                }, error: (err: any): void => {
                    console.error('Error uploading collector data', err);
                    this.notification.openError('An error occurred while collecting the file.', 3000);
                }
            })
        ).subscribe();
    }
}
