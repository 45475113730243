<div class="pending-context-form">

  <div class="row">
    <mat-form-field>
      <mat-label>Select Link Type</mat-label>
      <mat-select [(ngModel)]="config.type" (selectionChange)="linkTypeSelected()"
                  [disabled]="static">
        <mat-option *ngFor="let link_type of link_types | keyvalue"
                    [value]="link_type.key">{{link_type.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <select-search-component
        [api_model]="'component_type'"
        [value]="config.type==='event_to_component' ? config.context.component_type : config.context.component_type_from"
        (selectionChange)="componentTypeSelected($event, config.type==='event_to_component' ? 'component_type' : 'component_type_from')"
        [disabled]="static"
        [classes]="'fill'"
        [label]="'Select Component Type'"
        class="select-search">
    </select-search-component>
  </div>

  <!--Customisation and Validation component-->
  <ng-container
      *ngIf="config.type==='event_to_component' && config.validation.component_type?.hasOwnProperty(config.context.component_type.id)">
    <custom-form-field
        [config]="config.customisation.component_type[config.context.component_type.id]"
        (configChanged)="config.customisation.component_type[config.context.component_type.id] = $event"
        [title]="'Add customisation for this ' + ct_dict[config.context.component_type.id]?.attributes.name || ''">
    </custom-form-field>
    <pending-context-validation
        [config]="config.validation.component_type[config.context.component_type.id]"
        (configChanged)="config.validation.component_type[config.context.component_type.id] = $event"
        [title]="'Add validation for this ' + ct_dict[config.context.component_type.id]?.attributes.name || ''">
    </pending-context-validation>
  </ng-container>
  <ng-container
      *ngIf="config.type==='component_to_component' && config.validation.component_type_from?.hasOwnProperty(config.context.component_type_from.id)">
    <custom-form-field
        [config]="config.customisation.component_type_from[config.context.component_type_from.id]"
        (configChanged)="config.customisation.component_type_from[config.context.component_type_from.id] = $event"
        [title]="'Add customisation for  this ' + ct_dict[config.context.component_type_from.id]?.attributes.name">
    </custom-form-field>
    <pending-context-validation
        [config]="config.validation.component_type_from[config.context.component_type_from.id]"
        (configChanged)="config.validation.component_type_from[config.context.component_type_from.id] = $event"
        [title]="'Add validation for this ' + ct_dict[config.context.component_type_from.id]?.attributes.name">
    </pending-context-validation>
  </ng-container>

  <!--If component_to_component --------------------------------------------------------------------------------------->
  <div class="row" *ngIf="config.type==='component_to_component'">
    <select-search-component
        [api_model]="'component_type'"
        [value]="config.context.component_type_to"
        (selectionChange)="componentTypeSelected($event, 'component_type_to')"
        [classes]="'fill'"
        [disabled]="static"
        [label]="'Select Component Type'"
        class="select-search"
        [matTooltip]="ct_dict[config?.context?.component_type_to?.id]?.attributes?.name">
    </select-search-component>
  </div>
  <!--Validation component-->
  <ng-container
      *ngIf="config.type==='component_to_component' && config.validation.component_type_to?.hasOwnProperty(config.context.component_type_to.id)">
    <custom-form-field
        [config]="config.customisation.component_type_to[config.context.component_type_to.id]"
        (configChanged)="config.customisation.component_type_to[config.context.component_type_to.id] = $event"
        [include_options]="['label']"
        [title]="'Add customisation for this ' + ct_dict[config.context.component_type_to.id]?.attributes.name || ''">
    </custom-form-field>
    <pending-context-validation
        [config]="config.validation.component_type_to[config.context.component_type_to.id]"
        (configChanged)="config.validation.component_type_to[config.context.component_type_to.id] = $event"
        [title]="'Add validation for this ' + ct_dict[config.context.component_type_to.id]?.attributes.name || ''">
    </pending-context-validation>
  </ng-container>

  <!--If event_to_component ------------------------------------------------------------------------------------------->
  <ng-container *ngIf="config.type==='event_to_component'">
    <div class="row center">
      <ng-container *ngIf="config.context.component_type.id">
        <div *ngIf="!event_types">Loading Event Types...</div>
        <ng-container *ngIf="event_types">
          <select-search
              (selectChange)="eventTypeSelected($event)"
              [data]="event_types"
              [value]="config.context.event_type"
              [compareWith]="eventsService.compareById"
              label="Select Event Type" class="select-search">
          </select-search>

          <select-many-search-component
              [api_model]="'constant_property_light'"
              (selectionChange)="propertiesSelected($event)"
              [value]="config.context.event_type.constant_properties"
              [custom_filters]="getEventTypeConstantPropertyFilter()"
              [classes]="'fill'"
              [label]="'Select Constant Properties'"
              class="select-search">
          </select-many-search-component>
        </ng-container>
      </ng-container>
    </div>

    <div class="row" *ngIf="config.context?.event_type?.id && config.context.event_type.constant_properties
    && config.context.event_type.constant_properties.length > 0" style="width:100%;">
      <mat-form-field>
        <mat-label>Select Constant Property with unique values</mat-label>
        <mat-select [(ngModel)]="config.context.event_type.unique_constant_property">
          <mat-option *ngFor="let constant_property of config.context.event_type.constant_properties"
                      [value]="constant_property.id">{{cp_dict[constant_property.id]?.attributes | nameOrDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--Customisation and Validation options for selected constant properties----------------------------------------------------------->
    <ng-container *ngIf="config.context.event_type.constant_properties">
      <ng-container *ngFor="let cp of config.context.event_type.constant_properties">
        <custom-form-field [config]="config.customisation.constant_properties[cp.id]"
                           (configChanged)="config.customisation.constant_properties[cp.id] = $event"
                           [title]="'Add customisation for ' + cp_dict[cp.id]?.attributes?.name || ''">
        </custom-form-field>
        <pending-context-validation [config]="config.validation.constant_properties[cp.id]"
                                    (configChanged)="config.validation.constant_properties[cp.id] = $event"
                                    [title]="'Add validation for ' + cp_dict[cp.id]?.attributes?.name">
        </pending-context-validation>
      </ng-container>
    </ng-container>

  </ng-container>

</div>
