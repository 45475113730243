<div class="page traveler">

  <div class="single-component-events-table-view">
    <div class="row select-component form-dialog">
      <mat-form-field>
        <mat-label>Enter Component Name</mat-label>
        <input matInput [(ngModel)]="selected_component_name" (change)="componentScanned(selected_component_name)"/>
      </mat-form-field>

      <div style="margin:0 20px;">OR</div>

      <div class="select-search" style="max-width:400px; width:400px;">
        <select-search
            (selectChange)="componentSelected()"
            *ngIf="components"
            [(value)]="selected_component"
            [data]="components"
            label="Select Component">
        </select-search>
      </div>
    </div>


    <component-events-table *ngIf="config?.component?.id"
                            [config]="config"></component-events-table>
  </div>

</div>
