import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DateTimePeriodService} from "../../../services/date-time-period.service";
import {Event, Event as WireEvent} from "../../../_models/event";
import {ConstantProperty} from "../../../_models/constant-property";
import {MatTable} from "@angular/material/table";
import {forkJoin, Observable, of, Subject, Subscription} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import * as utils from "../../../lib/utils";
import {catchError, concatMap, first, map, take, takeUntil} from "rxjs/operators";
import {CustomEventsService} from "../../../services/custom-events.service";
import {CachingService} from "../../../services/caching.service";
import {
    COMPONENT_EVENTS_CONFIG
} from "../../../forms/component-events-table-form/component-events-table-form.component";
import {ApiService} from '../../../services/api/api.service';
import {TableUtilsService} from "../../table-utils.service";
import {TileDataService} from "../../../services/tile_data.service";
import {SearchQueryOptions} from "../../../services/api/search-query-options";
import {PropByRelationshipResponse} from "../../../data/constant-property-data.service";
import {PaginationDataSource} from '../../../services/api/pagination-data-source';
import {EventDataService} from '../../../data/event-data.service';
import {CustomEventsDataService} from "../../custom-events-table/custom-events-data.service";
import {IDMap, KeyMap, ModelID} from '../../../_typing/generic-types';
import {GenericConstantApiResponse, GenericField} from "../../../_models/api/generic-constant";
import {GenericConstantDataService} from "../../../data/generic-constant-data.service";
import {EventConfigColumn} from "../../../_typing/config/config-column";
import {getColIds, getPropertyIds} from '../../custom-events-table/utils';
import {ListResponse} from "../../../services/api/response-types";
import {EventComponent} from "../../../_models/event-component";
import {IDateTimePeriod} from "../../../_typing/date-time-period";
import {NotificationService} from "../../../services/notification.service";
import {ConstantLockedDict, LockDataService} from "../../../services/lock-data.service";
import {HttpErrorHandler} from "../../../services/http-error-handler.service";
import {BaseComponent} from '../../../shared/base.component';
import {UserService} from "../../../services/user.service";
import {RarChartService} from "../../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";

@Component({
    selector: 'events-table',
    templateUrl: './events-table.component.html',
    styleUrls: ['../component-events-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TableUtilsService],
    standalone: false
})
export class EventsTableComponent extends BaseComponent {
    @Input() component: any;
    @Input() config: COMPONENT_EVENTS_CONFIG;
    @Input() save_content: Subject<any>;
    @Input() link_only = false;

    event_status: string | undefined;
    event_columns: string[];
    dtp: IDateTimePeriod;
    events: WireEvent[] | undefined;
    event_constants: IDMap<IDMap<GenericField>>;
    event_components: IDMap<EventComponent> = {};
    locked_dict: ConstantLockedDict = {};

    event_properties: string[];
    extra_cols: string[] = ['start', 'end', 'comment'];
    format_dict: any;
    selected_cols_event_dict: { [key: string]: EventConfigColumn } = {};
    constant_properties: ConstantProperty[];
    cp_dict: IDMap<ConstantProperty> = {};
    cp_name_dict: KeyMap<ConstantProperty> = {};
    $save_subscription: Subscription | null = new Subscription();

    page_size = 10;
    private paginator: MatPaginator;

    @ViewChild(MatPaginator) eventPaginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) events_table: MatTable<any>;

    eventDataSource: PaginationDataSource<WireEvent>;
    events_total = 0;
    loading_next = false;

    constructor(public customEventsService: CustomEventsService,
                public customEventsDataService: CustomEventsDataService,
                private api: ApiService,
                private cache: CachingService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private changeDetectorRef: ChangeDetectorRef,
                private notification: NotificationService,
                public tableUtils: TableUtilsService,
                private tileData: TileDataService,
                private eventData: EventDataService,
                private genericConstantData: GenericConstantDataService,
                private lockDataService: LockDataService,
                private userService: UserService,
                httpErrorHandler: HttpErrorHandler
    ) {
        super();
        this.handleError = httpErrorHandler.createHandleError('Events Table');
    }

    ngOnInit(): void {
        this.event_status = 'Loading events...';
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            this.cache.clearCache();
            this.emitFilterQuery();
        });
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            this.dtp = this.dateInst.dtp;
            this.openEvents();
        });

        if (this.save_content) {
            this.save_content.subscribe(event => {
                if (!this.tileData.tile) {
                    return;
                }
                this.tileData.tile.attributes.parameters.selected_cols.event =
                    this.tableUtils.updateColumnFormats(this.config.selected_cols.event, this.format_dict);
                this.tableUtils.emitSaveTile(this.tileData);
            });
        }
        this.config.selected_cols.event.forEach(item => this.selected_cols_event_dict[item.id] = item);
        this.event_properties = getPropertyIds(this.config.selected_cols.event);
    }

    private openEvents(): void {
        this.event_status = 'Loading events...';
        this.events = undefined;
        this.constant_properties = [];

        setTimeout(() => {
            this.setPaginationQuery();
            this.setPaginationPage();
        }, 100);
    }

    private setPaginationQuery(): void {
        this.loading_next = true;
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'start';
        this.eventPaginator.pageSize = this.page_size;

        initialQuery.filters = this.getDataSourceFilter();
        this.eventDataSource = new PaginationDataSource<WireEvent>(
            (query) => this.customEventsDataService.pageEventLight(query),
            initialQuery,
            this.eventPaginator,
            this.sort
        );
    }

    private setPaginationPage(): void {
        const ctrl = this;
        this.eventDataSource.$page.pipe(
            concatMap((response) => {
                this.events = response.data;
                this.events_total = response.meta.count;
                this.event_status = '';
                const atts = ['start', 'end'];

                const constant_property_ids = getPropertyIds(this.config.selected_cols?.event);
                if (this.events.length < 1) {
                    this.loading_next = false;
                    return of([]);
                }
                const event_ids = this.events.map(e => e.id);

                const event_type_ids = ctrl.events.map(event => {
                    return event.relationships.event_type.data?.id;
                }).filter(e => ![undefined, null].includes(e));

                return ctrl.customEventsService.getStreamedConstantPropertiesByEventTypeIds(utils.uniqueList(event_type_ids),
                    constant_property_ids)
                    .pipe(concatMap((prop_response: PropByRelationshipResponse) => {
                            this.constant_properties = prop_response.constant_properties;
                            this.cp_dict = prop_response.cp_dict;
                            this.constant_properties.forEach(cp => this.cp_name_dict[cp.attributes.name] = cp);

                            const $eventConstants =
                                this.customEventsDataService.getApiEventConstants(event_ids, constant_property_ids, atts);
                            let $eventComponents: Observable<ListResponse<EventComponent> | { data: null }>;
                            if (this.config.allow_unlink) {
                                $eventComponents = this.customEventsDataService.getFullEventComponents(event_ids, [this.component.id]);
                            } else {
                                $eventComponents = of({data: null});
                            }
                            return forkJoin([$eventConstants, $eventComponents]).pipe(
                                map(([eventConstants, eventComponents]) => {
                                    this.event_constants = (eventConstants as GenericConstantApiResponse)?.data;
                                    eventComponents.data?.forEach(ec => {
                                        this.event_components[ec.relationships.event.data.id] = ec;
                                    });
                                    this.locked_dict = this.customEventsService.updateLockedProperties({},
                                        this.events, this.constant_properties, eventConstants, atts);
                                    this.customEventsService.updateEventComponentLinksLocked(this.locked_dict, this.event_components);
                                    this.loading_next = false;
                                })
                            );
                        }), catchError(this.handleError<ListResponse<any>>('getStreamedConstantPropertiesByEventTypeIds')),
                        takeUntil(this.onDestroy)
                    );
            })
        ).subscribe(() => {
            if (this.events && this.events.length < 1) {
                this.event_status = 'No events for the component and date range.';
                console.log('No events for the component and date range.');
                this.changeDetectorRef.markForCheck();
            } else {
                this.event_columns = ['Unlink'].concat(getColIds(this.config.selected_cols.event));
                if (this.link_only) {
                    const unique = [];
                    Object.keys(this.config.relationships).forEach(r => {
                        if (this.config.relationships[r].unique_property) {
                            unique.push(this.config.relationships[r].unique_property);
                        }
                    });
                    if (!unique.length) {
                        const u = this.config.selected_cols.event.find(c => c.type === 'constant_property')?.id || 'start';
                        unique.push(this.config.selected_cols.event.find(c => c.type === 'constant_property')?.id);
                    }
                    this.event_columns = this.event_columns.filter(c => c === 'Unlink' || unique.includes(c));
                }
                this.initialiseColumnFormats();
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    getDataSourceFilter() {
        const date_filter = this.config.bypass_date_filters ? null : this.dtp;
        const constant_properties_ids = this.config.selected_cols?.event.map(item => item.id).filter(id => id);
        return this.eventData.generateEventsForComponentFilter(date_filter, this.component, constant_properties_ids);
    }

    emitFilterQuery() {
        this.loading_next = true;
        const filters = this.getDataSourceFilter();
        this.eventDataSource.filterBy(filters);
    }

    initialiseColumnFormats() {
        this.format_dict = {};
        this.event_columns.forEach(column => {
            this.format_dict[column] = this.tableUtils.getColumnFormats(this.config.event_column_formats?.[column]);
        });
    }

    saveEvent(event: Event, $event) {
        /**Only a comment can be saved**/
        let event_to_save = {id: event.id, type: event.type, attributes: {comment: ''}};

        event_to_save.attributes.comment = $event;
        let $sub = this.customEventsService.patchEvent(event_to_save).pipe(take(1)).subscribe(result => {
            this.changeDetectorRef.markForCheck();
        });
        this.$save_subscription.add($sub);
    }

    saveConstant(event: Event, prop_id: ModelID, $event) {
        let $sub;
        $sub = this.genericConstantData.saveEventConstant(
            event.id,
            prop_id,
            $event
        ).pipe(catchError(err => {
            console.log('Event constant not saved: ', err);
            this.customEventsService.event_state[event.id + prop_id] = {state: 'error', message: err.statusText};
            return of(null);
        }));
        $sub.subscribe(result => {
            if (result) {
                this.notification.openSuccess("Value saved.", 2000);
            }
            this.changeDetectorRef.markForCheck();
        });
        this.$save_subscription.add($sub);
    }

    canUnlink(event_id): boolean {
        // FIXME when no cp cols are specified, lock status is not returned for the component itself so don't allow unlinking
        if (!this.event_constants) { return false; }

        return this.customEventsService.event_state[event_id]?.state !== 'dirty' && this.config.allow_unlink &&
            this.locked_dict[event_id]?.component !== true && this.userService.canUnlinkEvent();
    }

    unlinkEvent(event) {
        if (!confirm("Are you sure you want to unlink this event?")) {
            return;
        }
        // First get the event_component to get the id of the relationship table to delete
        if (!this.event_components[event.id]) {
            this.notification.openError('Could not find event component to unlink.', 3000);
            return;
        }
        this.notification.openSuccess('Removing event...', 3000);

        this.api.event_component.obsDelete(this.event_components[event.id].id).pipe(
            first(),
            catchError(e => {
                this.customEventsService.event_state[event.id] = {
                    state: 'error',
                    message: 'Unable to unlink event.'
                };
                console.log("ERROR: EventsTableComponent (unlinkEvent).", e);
                this.notification.openError('Unable to unlink event.', 3000);
                return of(false);
            })
        ).subscribe(result => {
            if (result === false) {
                this.customEventsService.event_state[event.id] = {
                    state: 'error',
                    message: 'Unable to unlink event.'
                };
                return;
            }
            console.log("Event unlinked from component.", result);
            this.notification.openError('Event successfully unlinked.', 3000);
            this.emitFilterQuery();
            this.changeDetectorRef.markForCheck();
        });
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.eventDataSource.filterBy = filterValue;
    }

    ngOnDestroy(): void {
        this.$save_subscription = utils.refreshSubscription(this.$save_subscription);
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
