import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {SeriesDataService} from "../services/series_data.service";
import * as utils from '../lib/utils';
import {tap} from "rxjs/operators";
import {SingleResponse} from "../services/api/response-types";
import {Series} from "../_models/series";
import {MatDialogRef} from "@angular/material/dialog";
import {SeriesFormComponent} from "../forms/series-form/series-form.component";
import { Observable } from "rxjs";
import {takeUntilDestroyed} from "../shared/utils/utils";

@Component({
    selector: 'series-component-table',
    templateUrl: 'series-component-table.component.html',
    styleUrls: ['series-component-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesComponentTableComponent implements OnInit {
    @Input() component: any;
    @Input() deleted_component_ids: any;
    @Input() all_series: Series[];
    @Input() flowchart: any;
    @Input() showHints: any;

    comp: any;
    tree_position: string = 'child';
    objectKeys = Object.keys;

    constructor(private api: ApiService, private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        if (this.component.is_parent) {
            this.tree_position = 'parent';
        } else {
            this.tree_position = 'child';
        }
    }

    showSeriesForm(series_component) {
        let series = utils.deepCopy(series_component.relationships.series.data);
        // series.type here is correct
        let $series_full: Observable<SingleResponse<Series>> = this.api.series.getById(series.id);
        $series_full.pipe(
            takeUntilDestroyed(this),
            tap((returned: SingleResponse<Series>): void => {
                if (returned) {
                    let series_full: Series = returned.data;
                    const dialogRef: MatDialogRef<SeriesFormComponent> = this.seriesData.upsertSeries(this.component, series_full);
                    dialogRef.afterClosed().pipe(
                        tap((): void => {
                        if (series) {
                            const updated_series = series.series || series;

                            series_component.relationships.series.data = updated_series;
                            this.updateSeriesList(updated_series);
                        }
                    })).subscribe();
                }
        })).subscribe();
    }

    updateSeriesList(series: Series): void {
        this.all_series.forEach((seriesItem: Series): void => {
            if (series.id === seriesItem.id) {
                seriesItem.attributes = series.attributes;
                seriesItem.relationships = series.relationships;
            }
        });
    }

    deleteSeriesComponent(item) {
        if (!confirm("Please confirm to delete this series component.")) {
            return;
        }
        let delete_item: Observable<any> = this.api.series_component.obsDelete(item.id);
        delete_item.pipe(
            takeUntilDestroyed(this),
            tap({
                    next: (): void => {
                        this.component.relationships.series_components.data =
                            this.component.relationships.series_components.data.filter(
                                (deletedComponent): boolean => deletedComponent.id !== item.id
                            );
                        this.component.attributes.custom_series = {};
                        this.flowchart.deleteSeriesComponents([item.id]);
                    },
                    error: (error): void => {
                        console.log(`message = Failed to delete, check dependent objects, error = ${error}`);
                    }
                }
            )).subscribe();
    }

   ngOnDestroy(): void {
  }
}
