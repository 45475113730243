import {Component, Input, OnInit} from "@angular/core";
import {ApiService} from "../services/api/api.service";

@Component({
    selector: 'comparison-chart-form',
    templateUrl: 'comparison_chart_form.component.html',
    styleUrls: ['../charts/comparison-chart/comparison-chart.component.less'],
    standalone: false
})

export class ComparisonChartFormComponent implements OnInit {
    public ready: boolean = false;
    public name_description_map: any = {};
    public name_list: any[] = [];

    public hint: string;
    public series_config: {
        axis: string;
        colour: any;
        name: any;
    };

    public regression_types = ['None', 'linear', 'exponential', 'power'];

    descriptionFunc = (name) => this.name_description_map[name];

    @Input()
    config: {
        labels: {
            title: string;
            sub_title?: string,
            y_axis?: string,
            x_axis?: string
        },
        hide_comments: boolean,
        colour: string,
        type: string,
        chart_type: string,
        series_list: {
            axis: string,
            colour?: any,
            name: string
        }[],
        statistics: {
            averages: boolean,
            std_dev: boolean,
            pooled: boolean,
            correlation_coeff: boolean,
        },
        diagonal_line: boolean,
        regression_line: string

    };
    series_light_full: any = [];
    types = ['scatter', 'line'];
    axes = ['x', 'y'];
    title: { y: string, vs: string, x: string } = {y: null, vs: null, x: null};

    constructor(
        public api: ApiService
    ) {
    }

    ngOnInit(): void {
        const ctrl = this;

        if (ctrl.config.labels === undefined) {
            this.config = {
                labels: {
                    title: null,
                    sub_title: null
                },
                hide_comments: true,
                colour: null,
                type: 'scatter',
                chart_type: 'comparison',
                series_list: [{
                    axis: 'x',
                    name: null
                }, {
                    axis: 'y',
                    name: null
                }],
                statistics: {
                    averages: true,
                    std_dev: true,
                    pooled: true,
                    correlation_coeff: true
                },
                diagonal_line: true,
                regression_line: null
            };

        }

        ctrl.api.series_light.searchMany().toPromise().then((response) => {
            ctrl.series_light_full = response.data;
            response.data.map((series) => {
                ctrl.name_list.push(series.attributes.name);
                ctrl.name_description_map[series.attributes.name] = series.attributes.name + '-' + series.attributes.description
            })
        });
        ctrl.ready = true;
    }

    addNameToConfig(series_config, event, axis) {
        const ctrl = this;
        series_config.name = event.value.attributes.name;

        if (axis == 'x') {
            ctrl.title.x = event.value.attributes.description;
            ctrl.title.vs = ' vs '
        } else if (axis == 'y') {
            ctrl.title.y = event.value.attributes.description;
        }

        if (ctrl.title.y && !ctrl.title.x) {
            ctrl.config.labels.title = ctrl.title.y
        } else if (ctrl.title.y && ctrl.title.x) {
            ctrl.config.labels.title = ctrl.title.y + ctrl.title.vs + ctrl.title.x
        } else if (ctrl.title.x && !ctrl.title.y) {
            ctrl.config.labels.title = ctrl.title.x
        }

    }

    getValue(name: string) {
        return this.series_light_full.find(series => series.attributes.name == name)
    }

}
