import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ApiService} from "../../services/api/api.service";
import {TileDataService} from "../../services/tile_data.service";
import * as utils from "../../lib/utils";
import {SeriesDataService} from "../../services/series_data.service";
import {AppScope} from "../../services/app_scope.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {forkJoin, Subject} from "rxjs";

import {isEqual, isObject, transform} from "lodash-es";
import {ConstantProperty} from "../../_models/constant-property";
import {NotificationService} from "../../services/notification.service";
import {IDMap, KeyMap, ModelName} from "../../_typing/generic-types";
import {take, takeUntil, tap} from "rxjs/operators";
import {compareValueToId} from "../../lib/utils";
import {PrintoutComponentType, PrintoutComponentTypeRelationships} from "../../_models/printout-component-type";
import {ComponentType} from "../../_models/component-type";
import {NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {PrintoutDataService} from "../../data/printout-data.service";

function difference(object, base): any {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
        }
    });
}

export interface DialogData {
    printouts: any[];
    printout_component_type: PrintoutComponentType;
    component_types: ComponentType[];
    component_types_map: IDMap<ComponentType>;
}

interface IComponentTypeConditions {
    parent: any,
    conditions: any;
    component_type: ComponentType
}

@Component({
    selector: 'printout-mapper-form',
    templateUrl: 'printout-mapper-form.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [DateTimePeriodService],
    standalone: false
})

export class PrintoutMapperFormComponent implements OnInit, OnDestroy {
    public show: boolean;
    public showing_hints: boolean = false;
    public hint: string = 'Name';

    private readonly onDestroy = new Subject<void>();

    selectedPrintout: string;

    printouts: any[];

    componentTypes: ComponentType[] = [];
    ctDict: IDMap<ComponentType> = {};

    componentTypesConditions: IComponentTypeConditions[] = [];

    componentTypeComponentTypes: any[] = [];
    loadingConfig: boolean = true;

    printoutComponentType: PrintoutComponentType;
    savedPrintoutComponentType: PrintoutComponentType;
    isNew: boolean;

    protected readonly compareValueToId = compareValueToId;

    constructor(private api: ApiService,
                protected seriesData: SeriesDataService,
                private dialogRef: MatDialogRef<PrintoutMapperFormComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
                public tileData: TileDataService,
                public appScope: AppScope,
                private notification: NotificationService,
    private printoutDataService:PrintoutDataService) {

        this.printoutComponentType = dialogData.printout_component_type;
        if (!this.printoutComponentType.attributes.conditions) {
            this.printoutComponentType.attributes.conditions = {};
        }

        this.componentTypes = dialogData.component_types;
        this.ctDict = dialogData.component_types_map;
        this.isNew = !this.printoutComponentType.relationships.printout?.data?.id && !this.printoutComponentType.attributes.printout_id;
    }

    ngOnInit(): void {
        const ctrl = this;
        // Keep a copy to pass back to the table on close without save
        this.savedPrintoutComponentType = utils.deepCopy(this.printoutComponentType);

        this.printoutDataService.getPrintouts().subscribe(result => {
            this.printouts = result;
        });

        this.selectedPrintout = this.printoutComponentType.attributes.printout_id || this.printoutComponentType.relationships.printout.data?.id;
        this.getConfig();
    }

    getConfig() {
        const $component_type_component_types = this.api.component_type_component_type.searchMany();
        const $component_types = this.api.component_type.searchMany();
        forkJoin([$component_type_component_types, $component_types])
            .subscribe(([  component_type_component_types, component_types]) => {
                this.componentTypeComponentTypes = component_type_component_types.data;
                this.componentTypes = component_types.data;
                this.componentTypes.forEach(ct => this.ctDict[ct.id] = ct)

                if (this.printoutComponentType.relationships.component_type.data?.id) {
                    this.addComponentType(
                        this.ctDict[this.printoutComponentType.relationships.component_type.data.id],
                        this.printoutComponentType.attributes.conditions,
                        []);
                }
                this.loadingConfig = false;
            });
    }

    onCloseClick(): void {
        this.dialogRef.close(this.savedPrintoutComponentType);
    }

    formatString(p: any): string {
        return `[${p.type.replace(/_/g, ' ').toUpperCase()}@${p.attributes.name}]`;
    }

    save() {
        this.apply().then(result => {
            this.onCloseClick();
        });
    }

    apply() {
        if (!this.selectedPrintout) {
            this.notification.openError("Printout must be selected. ", NOTIFICATION_DURATION_MS, 'Close');
            return;
        }
        if (this.isLegacy()) {
            this.printoutComponentType.attributes.printout_id = this.selectedPrintout;
        } else {
            this.printoutComponentType.relationships.printout.data = {
                id: this.selectedPrintout,
                type: 'printout'
            };
        }


        let $promise;
        let data = utils.deepCopy(this.printoutComponentType);
        if (this.printoutComponentType && this.printoutComponentType.id) {
            $promise = this.api.printout_component_type.patch(data);
        } else {
            $promise = this.api.printout_component_type.save(data);
        }
        return $promise.then(result => {
            if (result) {
                this.printoutComponentType = result.data;

                this.savedPrintoutComponentType = utils.deepCopy(this.printoutComponentType);
                this.notification.openSuccess("Printout Mapping Type saved. ", 2000);
            }
            return result;
        });
    }

    updateSelectedComponentType(componentType: ComponentType) {
        this.printoutComponentType.relationships.component_type.data = {
            id: componentType.id,
            type: 'component_type'
        };
        // dont create a new object, use same object
        for (let attr in this.printoutComponentType.attributes.conditions) {
            delete this.printoutComponentType.attributes.conditions[attr];
        }
        this.addComponentType(componentType, this.printoutComponentType.attributes.conditions, [], true);
    }

    addComponentType(ct: ComponentType, conditions, parent, clear: boolean = false) {
        if (clear) {
            this.componentTypesConditions = [];
        }
        this.componentTypesConditions.push({
            parent: parent,
            conditions: conditions || {},
            component_type: ct
        });

        for (let key in conditions) {
            let match = key.match(/\[component_type@(.+?)\]/);
            if (match && conditions[key].is_set) {
                this.addComponentType(
                    this.ctDict[match[1]],
                    conditions[key].conditions,
                    [...parent, ct]
                );
            }
        }
    }

    removeComponentType(component_id) {
        let index = this.componentTypesConditions.findIndex(c => c.component_type.id === component_id);
        if (index !== -1) {
            // remove all conditions set
            for (let attr in this.componentTypesConditions[index].conditions) {
                delete this.componentTypesConditions[index].conditions[attr];
            }
            this.componentTypesConditions.splice(index, 1);
        }
    }

    conditionsChanged(event, component_type) {
        let conditions = event.value;
        // check for removed [component_type@<id>] conditions
        Object.keys(difference(event.prev, event.value)).forEach(key => {
            let match = key.match(/\[component_type@(.+?)\]/);
            if (match) {
                this.removeComponentType(match[1]);
            }
        });

        // check if condition [component_type@<id>].is_set has changed
        for (let key in conditions) {
            let match = key.match(/\[component_type@(.+?)\]/);
            if (match) {
                let component_conditions = this.componentTypesConditions.find(c => c.component_type.id === match[1]);
                if (conditions[key].is_set && !component_conditions) {
                    let parent = this.componentTypesConditions.find(c => c.component_type.id === component_type);
                    this.addComponentType(
                        this.ctDict[match[1]],
                        parent.conditions[key].conditions,
                        [...parent.parent, parent.component_type]
                    );
                } else if (!conditions[key].is_set && component_conditions) {
                    this.removeComponentType(match[1]);
                }
            }
        }
    }

    private isLegacy(): boolean {
        const printout = this.printouts.find(p => p.id == this.selectedPrintout); //id is string or number
        return !('relationships' in printout);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
