import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as c3 from "c3";
import {ChartAPI} from "c3";
import {forkJoin, Subject, take} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {moment} from "../../services/timezone-selector.service";
import {ApiService} from "../../services/api/api.service";
import {CategoryChartTileConfiguration} from "../../forms/category-chart-form/category-chart-tile.configuration";
import {C3ChartConfiguration} from "../chart-config/c3-chart.configuration";
import {significantNumber} from "../../lib/utils";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'category-chart',
    templateUrl: './category-chart.component.html',
    styleUrls: ['./category-chart.component.less'],
    standalone: false
})
export class CategoryChartComponent implements OnInit, OnDestroy {
    @ViewChild('categoryChart', {read: ElementRef}) categoryChart: ElementRef;
    chart: ChartAPI;
    full_series_list = [];
    columns: any[] = [];
    categories: string[] = [];
    data_dictionary: any = {};
    final_config: C3ChartConfiguration = {
        bindto: undefined,
        axis: {
            x: {type: 'category', label: '', tick: {rotate: -45, multiline: false}},
            y: {type: undefined, label: '', max: undefined, min: undefined}
        },
        data: {
            columns: [],
            labels: false,
            x: 'x',
            type: 'bar',
            types: {},
            colors: {}
        },
        zoom: {enabled: true},
        point: {show: false},
        padding: {bottom: 20, top: 30, right: 15},
        tooltip: {}

    };

    @Input()
    config: CategoryChartTileConfiguration;
    private readonly onDestroy = new Subject<void>();

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private api: ApiService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit() {
        const ctrl = this;

        ctrl.final_config.axis.x.type = 'category';
        ctrl.final_config.axis.x.label = {'text': ctrl.config.labels.x_axis, 'position': 'outer-center'};
        ctrl.final_config.axis.y.label = {'text': ctrl.config.labels.y_axis, 'position': 'outer-middle'};

        if (ctrl.config.set_range) {
            ctrl.final_config.axis.y.padding = {top: 0, bottom: 0};
            if (ctrl.config.y_min) {
                ctrl.final_config.axis.y.min = ctrl.config.y_min;
            }
            if (ctrl.config.y_max) {
                ctrl.final_config.axis.y.max = ctrl.config.y_max;
            }
        }
        ctrl.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.getChartData();
        });

        this.dateInst.dateTimePeriodChanged$.pipe(take(1))
            .subscribe(dtp => {
                this.getChartData();
            })
        ctrl.columns.push(ctrl.config.labels.x_axis);

    }

    getChartData() {
        const ctrl = this;
        let $time_series_data = [];
        ctrl.config.series_list.forEach(config_series => {
            let wire_sample_period = null;

            if (config_series.sample_period && config_series.number_of_periods) {
                wire_sample_period = config_series.sample_period.wire_sample;
            }

            let start = ctrl.dateTimePeriodService.determineStartFromEndAndPeriod(ctrl.dateInst.dtp, config_series.sample_period, config_series.number_of_periods);

            const params = {
                series_list: [config_series.series_id],
                start: start.toISOString(),
                end: ctrl.dateInst.dtp.end.toISOString(),
                sample_period: wire_sample_period ? wire_sample_period : ctrl.dateInst.dtp.sample_period.wire_sample,
                pivot: false
            };
            let data_set = ctrl.api.get_series_data(params);
            $time_series_data.push(data_set);
        });

        forkJoin($time_series_data).pipe(takeUntil(this.onDestroy)).subscribe((data: any[]) => {
            ctrl.categories = [];
            let i = 0;
            let chart_series_list = [];

            ctrl.config.series_list.map(series_ => {
                chart_series_list.push(series_.name);
            });

            data.forEach(series_data => {
                // data entries = list of keys(series name) and entries(dictionary of times and values)
                let data_entries = Object.entries(series_data.data);

                data_entries.forEach(([series_name, entry]) => {
                    if (!(chart_series_list.includes(series_name))) {
                        return;
                    }
                    if (!(ctrl.data_dictionary.hasOwnProperty(series_name))) {
                        ctrl.data_dictionary[series_name] = [series_name];
                    }
                    ctrl.final_config.data.types[series_name] = ctrl.config.series_list[i].chart_type;
                    ctrl.final_config.data.colors[series_name] = ctrl.config.series_list[i].color;
                    let number_of_data_points = Object.values(entry).length;
                    // probably should call number_of_data_points something like 'number in chain'
                    Object.entries(entry).forEach(([time_stamp, value]) => {
                        ctrl.data_dictionary[series_name].push(value);
                        let category = '';
                        if (number_of_data_points == 1) {
                            if (ctrl.config.series_list[i].sample_period.name == 'month') {
                                category = 'MTD';
                            } else if (ctrl.config.series_list[i].sample_period.name == 'week') {
                                category = 'WTD';

                            }

                        } else if (ctrl.config.series_list[i].sample_period.name == 'day') {
                            category = moment(time_stamp).format('DD-MMM HH:mm');
                            // For more options: https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/03-string-format/
                        } else {
                            category = '@ ago'.replace('@', number_of_data_points - 1 + ' ' + ctrl.config.series_list[i].sample_period.name + 's');
                            category = category.charAt(0).toUpperCase() + category.slice(1);

                        }

                        if (!(ctrl.categories.includes(category))) {
                            ctrl.categories.push(category);
                        }

                        number_of_data_points--;
                    });
                    i++;
                });
            });

            ctrl.final_config.tooltip = {
                format: {
                    value: function (value, ratio, id, index) {
                        return significantNumber(value);
                    }
                }
            };
            ctrl.categories.unshift('x');
            ctrl.final_config.data.columns = [ctrl.categories, ...Object.values(ctrl.data_dictionary)];
            ctrl.final_config.data.type = 'bar';
            ctrl.final_config.axis.x.categories = ctrl.categories;
            ctrl.final_config.zoom = {enabled: true};
            ctrl.final_config.padding = {bottom: 20, top: 30, right: 15};
            ctrl.final_config.axis.x.tick.rotate = -45;
            ctrl.final_config.axis.x.tick.multiline = false;
            ctrl.renderChart();
        });
    }

    renderChart() {
        const ctrl = this;
        ctrl.final_config.bindto = ctrl.categoryChart.nativeElement;
        this.chart = c3.generate(ctrl.final_config);

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
