<div class="form-dialog">
  <h1>Event Model upload parse warnings and errors</h1>
  <mat-dialog-content>
    <mat-tab-group>
      <mat-tab [label]="'Errors (' + parse_response.parsed_count.error + ')'">
        <ng-container *ngIf="parse_response">
          <h2>These rows were completely skipped.</h2>
          <table>
            <tr>
              <th>Row Index</th>
              <th *ngFor="let h of parse_response.columns">{{h}}</th>
            </tr>
            <ng-container *ngFor="let err of parse_response.errors">
              <tr>
                <td>{{err.row_index}}</td>
                <td *ngFor="let d of err.row_data">{{d}}</td>
              </tr>
              <tr>
                <td [colSpan]="parse_response.columns.length + 1">      {{err.error_message}}</td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
      </mat-tab>
      <mat-tab [label]="'Warnings (' + parse_response.parsed_count.warning + ')'">
        <ng-container *ngIf="parse_response">
          <h2>These rows were partially updated.</h2>
          <table>
            <tr>
              <th>Row Index</th>
              <th *ngFor="let h of parse_response.columns">{{h}}</th>
            </tr>
            <ng-container *ngFor="let warn of parse_response.warnings">
              <tr>
                <td>{{warn.row_index}}</td>
                <td *ngFor="let d of warn.row_data">{{d}}</td>
              </tr>
              <tr>
                <td [colSpan]="parse_response.columns.length + 1">
                  <ul>
                    <li *ngFor="let m of warn.warning_messages">{{m.message}}</li>
                  </ul>
                </td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="closeDialog()" class="btn-close"> Close</button>
  </div>
</div>
