import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {first, map, takeUntil} from "rxjs/operators";
import {CustomEventsService} from "../../services/custom-events.service";
import {ConstantProperty} from '../../_models/constant-property';
import {ColumnFormatsConfigDict} from "../table-column-menu/table-column-menu.component";
import {ConditionalFormattingService} from '../../tables/conditional-formatting.service';
import {ListResponse} from '../../services/api/response-types';
import {getColIds, getPropertyIds} from '../../tables/custom-events-table/utils';

@Injectable()
export class ConditionalFormattingFormService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private constant_properties: ConstantProperty[];
    private cp_dict: { [key: string]: ConstantProperty } = {};

    private default_component_events_cols = ['name', 'start_time', 'end_time'];
    private default_custom_events_cols = ['start', 'end', 'comment', 'duration', 'source', 'destination']

    named_formats: ColumnFormatsConfigDict;
    operator_dict = {
        'auto': 'Auto',
        'empty': 'Is Empty',
        'not_empty': 'Is Not Empty',
        '=': 'Equals',
        '!=': 'Not Equal to',
        '>': 'Greater than',
        '<': 'Less than',
        '>=': 'Greater than or Equal to',
        '<=': 'Less than or Equal to',
        '<>': 'Is between',
        '><': 'Is outside of',
        'case_includes': 'Contains/matches, Case sensitive',
        '!_case_includes': 'Does not contain/match, Case sensitive',
        'includes': 'Contains/matches, Case insensitive',
        '!_includes': 'Does not contain/match, Case insensitive'
    }

    constructor(private customEventsService: CustomEventsService,
                private formattingService: ConditionalFormattingService) {
        this.named_formats = formattingService.named_formats;

    }

    getColumns(config, key): Observable<{ name: string, id: string, type: string }[]> {
        if (key === 'selected_component_type') {
            return this.getComponentTypeColumns(config);
        }
        if (key === 'event_types') {
            return this.getEventTypeColumns(config)
        }
    }

    private getComponentTypeColumns(config) {
        let selected_cols = getColIds(config.selected_cols?.component_type);
        if (!selected_cols || selected_cols.length < 1) return of([]);
        const default_cols = this.getSelectedDefaultComponentTypeColumns(selected_cols);
        return this.addColumnsObservablePipe(this.getComponentTypeProperties(config), default_cols);
    }

    private getSelectedDefaultComponentTypeColumns(selected_cols) {
        return this.default_component_events_cols.filter(col => selected_cols.includes(col)).map(col => {
            return {name: col, id: null, type: null}
        })
    }

    private addColumnsObservablePipe($obs: Observable<any>, default_cols) {
        return $obs.pipe(
            first(),
            map(result => {
                let columns = result.data || [];
                (result.data || []).forEach(cp => this.cp_dict[cp.id] = cp);
                columns = default_cols.concat(columns.map(d => {
                    return {name: d.attributes.name, id: d.id || d.attributes.name, type: d.type}
                }));
                return columns;
            }),
            takeUntil(this.onDestroy))
    }

    private getComponentTypeProperties(config): Observable<ListResponse<ConstantProperty>> {
        return this.customEventsService.getComponentTypeProperties(
            [config.selected_component_type.id], getPropertyIds(config.selected_cols?.component_type));
    }

    private getEventTypeColumns(config) {
        let selected_cols = getColIds(config.columns);
        if (!selected_cols || selected_cols.length < 1) return of([]);
        const default_cols = this.getSelectedDefaultEventTypeColumns(selected_cols);
        return this.addColumnsObservablePipe(this.getEventTypeProperties(config), default_cols);
    }

    private getSelectedDefaultEventTypeColumns(selected_cols) {
        return this.default_custom_events_cols.filter(col => selected_cols.includes(col)).map(col => {
            return {name: col, id: null, type: null}
        })
    }

    private getEventTypeProperties(config): Observable<ListResponse<ConstantProperty>> {
        return this.customEventsService.getConstantPropertiesByEventTypeIds(config.event_types.map(e => e.id), getPropertyIds(config.columns));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
