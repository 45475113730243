<div class="spc-form-container">
  <div class="row center">
    <select-search (selectChange)="getSelected()"
                   *ngIf="name_list?.length>0"
                   [data]="full_series_list"
                   [fill_stubs]="true"
                   [(value)]="selectedSeries"
                   [label]="'Select a series'"
                   class="select-search"></select-search>

    <mat-form-field>
      <mat-label>Y axis label</mat-label>
      <input [(ngModel)]="config.labels.y_axis" matInput>
    </mat-form-field>
  </div>

  <div class="row center medium-height">
    <mat-checkbox [(ngModel)]="config.hide_legend"
                  [disabled]="config.hide_axes===true"
                  class="self-center">
      Hide axis labels
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_tick_labels"
                  [disabled]="config.hide_axes===true"
                  class="self-center">
      Hide value labels
    </mat-checkbox>
    <mat-checkbox (change)="updateAxes($event)"
                  [(ngModel)]="config.hide_axes"
                  class="self-center">
      Hide axes
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_comments"
                  class="self-center">
      Hide comments
    </mat-checkbox>
  </div>
  <div class="row">
    <mat-checkbox [(ngModel)]="config.show_moving_average" class="self-center">
      Show simple moving average
    </mat-checkbox>
  </div>

  <div class="row center">
    <mat-form-field>
      <mat-label>MA Period: 0 - 14</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="config.period_moving_average" name="period_moving_average"
             [disabled]="disabled" min="0" max="14" [formControl]="period_ma_validator"
             (focusout)="imposeMinMax(period_ma_validator, 'period_ma_validator')">
    </mat-form-field>
    <mat-form-field>
      <mat-label>&plusmn; 1 Sigma</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="config.one_sigma" name="first_sigma"
             [disabled]="disabled" [formControl]="oneSigma" (focusout)="imposeMinMax(oneSigma,'first_sigma')">
    </mat-form-field>
    <mat-form-field>
      <mat-label>&plusmn; 2 Sigma</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="config.two_sigma" name="second_sigma"
             [disabled]="disabled" [formControl]="twoSigma" (focusout)="imposeMinMax(twoSigma,'second_sigma')">
    </mat-form-field>
    <mat-form-field>
      <mat-label>&plusmn; 3 Sigma</mat-label>
      <input matInput required type="number" step="any" [(ngModel)]="config.three_sigma" name="max_control_line"
             [disabled]="disabled" [formControl]="threeSigma" (focusout)="imposeMinMax(threeSigma,'max_control_line')">
    </mat-form-field>
  </div>

  <div class="row center">
    <mat-form-field class="md-block">
      <mat-label>HiHi</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="limits.hihi" name="hihi" [disabled]="disabled">
    </mat-form-field>
    <mat-form-field class="md-block">
      <mat-label>Hi</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="limits.hi" name="hi" [disabled]="disabled">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Low</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="limits.low" name="low" [disabled]="disabled">
    </mat-form-field>
    <mat-form-field>
      <mat-label>LowLow</mat-label>
      <input matInput type="number" step="any" [(ngModel)]="limits.lowlow" name="lowlow" [disabled]="disabled">
    </mat-form-field>
  </div>
</div>
<div class="row auto full-width">
  <div class="spc-chart-container">
    <generic-chart [config]="config" class="chart"></generic-chart>
  </div>
  <div class="spc-chart-media-container">
    <generic-chart [config]="config" class="chart"></generic-chart>
  </div>
</div>