<div class="form-dialog">
  <h1>Upload {{property.attributes.name}} for {{component.attributes.name}}</h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Upload file">
        <drop-file-selector style="width:100%;" [file]="file"
                            [instruction]="'Drag and drop to upload a file'"
                            (selectionChanged)="changeEvent($event)">
        </drop-file-selector>

        <ng-container *ngIf="file">
          <h3>Select file details:</h3>
          <ul>
            <li>File name: {{file.name}}</li>
            <li>Last Modified: {{file.lastModifiedDate | date:'yyyy-MM-ddThh:mm'}}</li>
            <li>Mime type: {{file.type}}</li>
          </ul>
          <h4 *ngIf="!isCorrectFormat" class="error">{{errorMessage}}</h4>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save" [disabled]="!file || !isCorrectFormat">Upload</button>
  </div>
</div>
