<div class="rule">
  <div class="row auto">
  <field-option [rule]="rule" [value]="rule.field" [fields]="fields" (selectedFieldChanged)="rule.field=$event"></field-option>
  <query-operator [rule]="rule" [field]="fields[rule.field]" (selectedOperatorChanged)="rule.operator=$event"></query-operator>
  <query-value [fields]="fields" [rule]="rule" [operator]="rule.operator" [selectedField]="fields[rule.field]"></query-value>
    </div>
    <button type="button" mat-icon-button color="accent" (click)="removeRule()">
      <mat-icon class="button-icon"><i class="fa fa-trash"></i></mat-icon>
    </button>
</div>

