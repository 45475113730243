<div class="component-detail" style="width:100%;margin-bottom:200px;">
  <h2>{{component?.attributes.name}}</h2>
  <div>Changed by {{component?.attributes?.changed_by_name}} on {{component?.attributes.changed_on | stringDate}}</div>
  <div>Created by {{component?.attributes?.created_by_name}} on {{component?.attributes.created_on | stringDate}}</div>
  <component-form *ngIf="component_form_config"
                  [config]="component_form_config">

  </component-form>

  <h2 style="font-size:1.3rem;margin-bottom:10px;"
      *ngIf="related_component_types?.length || related_event_types?.length">Linked Items</h2>
  <div *ngFor="let rct of related_component_types">
    <div
        class="row link-components-container" (click)="showLinkedItemDetails(rct, !show_linked_details[rct.id])">
      <button class="link-button text-btn">
        <span class="material-symbols-outlined arrow" matTooltip="Expand linked items">
              {{show_linked_details[rct.id] ? 'arrow_drop_down' : 'arrow_right'}}</span>
      </button>
      <div style="font-size:1.2rem;">{{rct.attributes.name}}</div>
    </div>
    <ng-container *ngIf="show_linked_details[rct.id]">
      <components-table [parent_component]="component"
                        [selected_component_relationship_type]="rct"
                        [config]="data.config"
                        [link_only]="true">
      </components-table>
      <ng-container *ngIf="data.config.relationships && data.config.relationships[rct.id]?.show">
        <div style="margin:10px 0 0 4px;">
          <i class="fa fa-link" style="margin-right:6px;"></i>Link <b>{{component?.attributes.name}}</b> to a
          <b>{{rct.attributes.name}}</b>
        </div>
        <div *ngIf="pending_context_config[rct.id]" class="modal-pending-context">
          <pending-context [config]="pending_context_config[rct.id]"></pending-context>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div *ngFor="let ret of related_event_types" style="margin-bottom:20px;">
    <div
        class="row link-components-container" (click)="showLinkedItemDetails(ret, !show_linked_details[ret.id])">
      <button class="link-button text-btn">
        <span class="material-symbols-outlined arrow" matTooltip="Expand linked items">
              {{show_linked_details[ret.id] ? 'arrow_drop_down' : 'arrow_right'}}</span>
      </button>
      <div style="font-size:1.2rem;">{{ret.attributes.name}}</div>
    </div>
    <ng-container *ngIf="show_linked_details[ret.id]">
      <events-table [component]="component" [config]="data.config" [link_only]="true"></events-table>
    </ng-container>
  </div>
</div>
