<div class="field-option">
  <div class="select-search-field" *ngIf="fieldOptions">
    <select-filter
        (constant_changed)="setSelectedField($event);"
        [modelValue]="value"
        [options]="fieldOptions"
        [class]="'select-filter-inline'">
    </select-filter>
  </div>
</div>
