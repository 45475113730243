<div class="form-dialog">
  <h1>Printout Mapping
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">

      <mat-tab label="Mapping">
        <div class="row center">

          <select-search *ngIf="printouts?.length>0"
                         [data]="printouts"
                         [fill_stubs]="true"
                         [value]="selectedPrintout"
                         [disabled]="!isNew"
                         (selectChange)="selectedPrintout=$event.value.id"
                         [compareWith]="compareValueToId"
                         class="select-search"
                         label="Select Printout">
          </select-search>

          <select-search-component (selectionChange)="updateSelectedComponentType($event.value)"
                                   [api_model]="'component_type'"
                                   [value]="printoutComponentType.relationships.component_type.data"
                                   class="select-search"
                                   [classes]="'fill'"
                                   label="Select Component Type">
          </select-search-component>
        </div>

        <ng-container *ngIf="!loadingConfig">
          <ng-container *ngFor="let item of componentTypesConditions">
            <component-type-conditions
                [component_types_map]="ctDict"
                [component_type_component_types]="componentTypeComponentTypes"
                [component_type]="item.component_type"
                [parent]="item.parent"
                [(conditions)]="item.conditions"
                (conditionsChanged)="conditionsChanged($event, item.component_type.id)"
            ></component-type-conditions>
          </ng-container>
        </ng-container>


      </mat-tab>
    </mat-tab-group>


  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-close" (click)="onCloseClick()">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button class="btn-save" (click)="save()">Save</button>
  </div>
</div>
