import {Injectable} from '@angular/core';
import {deepCopy, isNullOrUndefined} from "../lib/utils";
import {QueryBuilderService} from "./query_builder.service";
import {Rule, RuleSet, ValidationConstant, ValidatorRule} from "../_typing/query-builder";
import { EventConfigColumn} from "../_typing/config/config-column";
import {KeyMap} from "../_typing/generic-types";
import {NotificationService} from "./notification.service";
import {EXTENDED_NOTIFICATION_DURATION_MS} from "../shared/globals";

@Injectable({
    providedIn: 'root'
})
export class ValidationBuilderService {

    constructor(private queryBuilderService: QueryBuilderService,
                private notification: NotificationService) {
    }


    getValidationDict(validationConfig: RuleSet, columnDict: KeyMap<EventConfigColumn>, modelType = 'COMPONENT', validationDict = {}) {
        if(!validationConfig) return {};
        deepCopy(validationConfig).rules.map((rule: RuleSet | Rule) => {
            if ('field' in rule) {
                validationDict = this.toValidationRule(rule, columnDict, modelType, validationDict);
            } else if ('condition' in rule) {
                validationDict = this.getValidationDict(rule, columnDict, modelType, validationDict);
            }
        });
        return validationDict;
    }

    private getFieldId(value: string): string[] {
        return value?.match(/\[(.*?)@(.*?)\]/);
    }

    toValidationRule(rule: Rule, columnDict: KeyMap<EventConfigColumn>, modelType: string, dict) {
        let cpMatch: string[] = this.getFieldId(rule.field);
        let key: string = cpMatch ? cpMatch[2] : rule.field;
        if (!dict[key]) {
            dict[key] = [];
        }
        dict[key].push(this.getRule(key, rule, columnDict))
        return dict
    }

    private getRule(field: string, rule: Rule, columnDict: KeyMap<EventConfigColumn>): ValidatorRule {
        let cpCompare = this.getFieldId(rule.value);
        let value = cpCompare ? cpCompare[2] : rule.value;
        return {
            field_value: undefined,
            field_name: columnDict[field]?.title,
            op: rule.operator,
            value: value,
            value_name: columnDict[value]?.title || value,
            data_type: undefined,
            allow_null_compare: rule.allow_null_compare,
            value_compare_to: rule.value_compare_to
        };
    }


    validateValue(columnId: string, constants: KeyMap<ValidationConstant>, validationDict) {
        if (!validationDict?.[columnId]) return true;

        const rules: ValidatorRule[] = deepCopy(validationDict[columnId]);
        const value = constants[columnId]?.value;
        const dataType = constants[columnId]?.data_type || 'string';
        rules.forEach(rule => {
            rule.field_value = value;
            rule.data_type = dataType;
            if (rule.value_compare_to === 'field') {
                rule.value = constants[rule.value]?.value;
            }
        });

        for (let rule of rules) {
            if (!rule.allow_null_compare && (isNullOrUndefined(rule.value) && rule.op.indexOf('blank') < 0)) {
                this.notification.openError(`${rule.field_name} may not be compared to a null value for ${rule.value_name} (value not saved)`, EXTENDED_NOTIFICATION_DURATION_MS, "Close")
                return false;
            }
            if (rule.allow_null_compare && (isNullOrUndefined(rule.value))) {
                return true;
            }

            let err = this.evaluateRule(rule);
            if (err) {
                this.notification.openError(`${err} (value not saved)`, EXTENDED_NOTIFICATION_DURATION_MS, "Close")
                return false;
            }
        }
        return true;
    }

    private valueAsType(value, dataType): string | number | Date {
        switch (dataType) {
            case 'datetime':
                return !isNullOrUndefined(value) ? new Date(value).getTime() : null;
            case 'string':
                return !isNullOrUndefined(value) ? String(value) : null;
            case 'number':
                return !isNullOrUndefined(value) ? Number(value) : null;
        }
    }

    evaluateRule(rule: ValidatorRule) {
        let value1: any = this.valueAsType(rule.field_value, rule.data_type);
        let value2: any = this.valueAsType(rule.value, rule.data_type);
        const fieldName = rule.field_name;
        const valueName = rule.value_name;
        let errMessage;

        //Currently only checking data_type==='datetime' ['>', '>=', '<', '<=', '=', '!=', 'blank', 'not blank']
        switch (rule.op) {
            case '>':
                if (isNullOrUndefined(value2) || value1 <= value2) {
                    errMessage = `${fieldName} must be ${rule.data_type === 'datetime' ? 'later' : 'greater'} than ${valueName}`
                }
                break;
            case '>=':
                if (isNullOrUndefined(value2) || value1 < value2) {
                    errMessage = `${fieldName} must be ${rule.data_type === 'datetime' ? 'later' : 'greater'} than or equal to ${valueName}`
                }
                break;
            case '<':
                if (isNullOrUndefined(value2) || value1 >= value2) {
                    errMessage = `${fieldName} must be ${rule.data_type === 'datetime' ? 'earlier' : 'smaller'} than ${valueName}`
                }
                break;
            case '<=':
                if (isNullOrUndefined(value2) || value1 > value2) {
                    errMessage = `${fieldName} must be ${rule.data_type === 'datetime' ? 'earlier' : 'smaller'} than or equal to ${valueName}`
                }
                break;
            case '=':
                if (value1 !== value2) {
                    errMessage = `${fieldName} must be equal to ${valueName}`
                }
                break;
            case '!=':
                if (value1 == value2) {
                    errMessage = `${fieldName} can not be equal to ${valueName}`
                }
                break;
            case 'blank':
                if (!isNullOrUndefined(value1)) {
                    errMessage = `${fieldName} can not be set.`
                }
                break;
            case 'not blank':
                if (isNullOrUndefined(value1)) {
                    errMessage = `${fieldName} can not be blank`
                }
                break;
            default:
                errMessage = `No operator selected for validating this constant.`
                break;
        }
        return errMessage;
    }
}
