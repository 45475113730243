import {IDMap, ModelID} from "../generic-types";
import {ColumnFormatsConfig} from "../../forms/table-column-menu/table-column-menu.component";
import {ConstantValue} from "../../_models/api/generic-constant";
import {ConstantProperty, isConstantProperty} from "../../_models/constant-property";
import {ComponentType} from "../../_models/component-type";
import {ConditionalFormattingConfig} from "../../tables/conditional-formatting.service";
import {isAttributeColumn} from "../../forms/custom-events-form/custom-events-form.component";


export class ConfigColumn {
    id: ModelID;
    name?: string;
    title?: string;
    type: string;
    format?: ColumnFormatsConfig;
    column_name?: string;
    disabled?: boolean;
    required?: boolean;
    default_value?: ConstantValue;
    filters?: string[];
}

export class EventConfigColumn extends ConfigColumn {
    conditional_format?: ConditionalFormattingConfig[];
    index?: number;

    constructor(object: ConstantProperty | ComponentType | string, type: string) {
        super();
        this.type = type;
        this.required = false;
        this.disabled = false;
        this.format = {};
        if (isAttributeColumn(object)) {
            this.id = object;
            this.title = object;
        } else if (isConstantProperty(object)) {
            this.id = object.id;
            this.title = object.attributes.alias ? object.attributes.alias : object.attributes.name.replace(/_/g, " ");
        } else {
            this.id = object.id;
            this.title = object.attributes.name.replace(/_/g, " ");
        }
    }
}
