import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

export interface CustomDialogData {
    form_result: string; // pass user interaction back to calling component
    config: CUSTOM_DIALOG_CONFIG; // component : name of component, params: input params for component
    positionRelativeToElement: HTMLElement | null; // see below
    setStyles(event: any): any; // A custom event that can be handled in the calling component
    selectionChanged(event: any): any;
}

interface IPositionType {
    left: number;
    top: number;
    adjust_left: number;
}

type OptionalPositionType = Partial<IPositionType>;

export interface ICUSTOM_DIALOG_CONFIG {
    component: string;
    parameters: any;
    position: OptionalPositionType;
    show_close: boolean;
}

export type CUSTOM_DIALOG_CONFIG = Partial<ICUSTOM_DIALOG_CONFIG>;

/*
This is a generic dialog which takes advantage of angular dialog functionality and can be used to
hold any custom popup needed. Positioning is handled by angular but can be overwritten. Functions can be added
to the CustomDialogData to run in the containing component.
 */

@Component({
    selector: 'custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.less'],
    standalone: false
})
export class CustomDialogComponent implements OnInit, AfterViewInit {
    config: CUSTOM_DIALOG_CONFIG;
    private positionRelativeToElement: HTMLElement
    @ViewChild('custom_dialog_container') container: ElementRef;

    constructor(public dialogRef: MatDialogRef<any>,
                @Inject(MAT_DIALOG_DATA) private dialogData: CustomDialogData) {
    }

    ngOnInit() {
        this.config = this.dialogData.config;
    }

    ngAfterViewInit() {
        //This will position the dialog relative to the clicked element (clicked element must be passed as part of the dialog data)
        //Can potentially also pass in the actual position params to override the defaults in updateDialogPosition()
        this.positionRelativeToElement = this.dialogData.positionRelativeToElement;
        if (this.positionRelativeToElement) {
            this.updateDialogPosition();
        }
    }

    updateDialogPosition() {
        const matDialogConfig = new MatDialogConfig();
        const rect: DOMRect = this.positionRelativeToElement.getBoundingClientRect();
        let left = 2;
        let top = 2;
        let adjust_left = 0;
        let screenWidth = window.innerWidth;
        let screenHeight = window.innerHeight;

        if (this.config.position) {
            left = this.config.position.left || 0;
            top = this.config.position.top || 0;
            adjust_left = this.config.position.adjust_left || 0;
        }
        if ((left + this.container.nativeElement.clientWidth + rect.left) >= screenWidth) {
            left = screenWidth - this.container.nativeElement.clientWidth - rect.left;
        } else if (left < 0) {
            left = 10;
        }
        if ((top + this.container.nativeElement.clientHeight + rect.top) >= screenHeight) {
            top = screenHeight - this.container.nativeElement.clientHeight - rect.top;
        } else if (top < 0) {
            top = 10;
        }

        matDialogConfig.position = {left: `${rect.left + left + adjust_left}px`, top: `${rect.top + top}px`};
        this.dialogRef.updatePosition(matDialogConfig.position);
    }

    /* Used by series-summary.component */
    updateSeriesSummary(event) {
        this.dialogData.setStyles(event);
    }

    /* Used with select-search-component from handsontables */
    updateSelectedValue(event, close = true) {
        this.dialogData.selectionChanged(event);
        if (!close) return;
        this.onCloseClick();
    }

    updateText(event) {
        this.dialogData.setStyles(event.target.value);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
