<div *ngIf="config.selected_component_type || config.component" class="component-events-table-component"
     [appLdsRoller]="loading_next" [style.height]="table_height">

  <ng-container *ngIf="is_tile !== true" class="row table-options" style="justify-content: space-between;">
    <div *ngIf="dataSource" class="table-filter">
      <i (click)="editComponentType(config.selected_component_type)" class="edit-inline hide-xs"
         matTooltip="Edit Component Type" style="margin-right:20px;"></i>
      <input (keyup)="applyFilter($event.target.value)" placeholder="Search component types"/>
    </div>
  </ng-container>

  <div #utils_bar class="row center hideonprint utils-bar">
    <div class="row justify-start table-filter">

      <div class="row center" *ngIf="config.search_by_name===true">
        <input matInput [(ngModel)]="search_by_name" placeholder="Find by name" name="search_by_name">
        <button (click)="refreshComponents()" mat-flat-button color="accent" class="search-button">
          <i class="fa fa-search"></i>
        </button>
      </div>
      <utils-bar [searchByName]="config.search_by_name"
                 (searchByNameValueSubmitted)="search_by_name=$event;refreshComponents();"
                 [search]="config.search" (searchValueChanged)="applyFilter($event)"
                 (searchValueSubmitted)="filter_string=$event;createUserFilters()"
                 (searchCleared)="clearSearch()"
                 [userSearchFields]="user_search_fields"
                 (searchSelectionsChanged)="user_search_selections=$event"
                 [sortByArray]="columns"
      >
      </utils-bar>

      <button *ngIf="showCreateComponent()" [disabled]="!canCreateComponent()"
              [matTooltip]="'Click here to create a new ' + ((component_type?.attributes?.name || 'component') | removeUnderscore | upperfirst)"
              mat-flat-button color="accent" class="search-button" matTooltip="Insert new row"
              (click)="newComponent()">
        <i class="fa fa-plus"></i>
      </button>

      <filter-table-button [config]="filter_button_config" [query]="user_filters"
                           (queryChanged)="updateFilterQuery($event)"></filter-table-button>


    </div>
    <mat-paginator [length]="component_total" [pageSize]="page_size"
                   [pageSizeOptions]="page_size_options.concat(component_total)"
                   [pageIndex]="page_index"
                   showFirstLastButtons
                   (page)="pageData($event)"
                   [disabled]="loading_next===true">
    </mat-paginator>
  </div>
  <div *ngIf="filter_warning" class="row hideonprint warning info" style="padding: 4px;border-left: 1px solid #ccc;">
    {{filter_warning}}
  </div>

  <div *ngIf="config.scroll_top" style="overflow-x: auto;overflow-y:hidden; width:100%;"
       (scroll)="mimicScrollBottom($event)">
    <div #top_scroll style="height:1px;"></div>
  </div>

  <div overlay-scrollbars [defer] [options]="{scrollbars: {autoHide: 'leave'}}"
       [ngClass]="{'component-events-table-tile': is_tile===true, 'render-pdf': isRenderingPdf}"
       class="table-container" #component_events_table (scroll)="mimicScrollTop($event)"
       [attr.id]="'pdf-' + tileData.id" tabindex="1">
    <app-pdf-header [pdfHeader]="tileData.tile.attributes.title"></app-pdf-header>
    <table [dataSource]="dataSource"
           [matSortActive]="columns[0]"
           class="component-events-table" mat-table matSort
           matSortDirection="desc" multiTemplateDataRows>

      <ng-container *ngFor="let column of columns;">

        <!--Aggregation Header Row-->
        <ng-container matColumnDef="{{'aggregation'+column}}">
          <ng-container *ngIf="column==='name'">
            <th *matHeaderCellDef sticky class="aggregation-cell-header"
                [ngClass]="{'select-offset' : columns.includes('Select')}">Aggregation
            </th>
          </ng-container>
          <ng-container
              *ngIf="selected_cols_dict[column]?.type!=='constant_property' && column !=='name' && column !=='changed_by_name'">
            <th *matHeaderCellDef
                [ngClass]="{'aggregation-select' : column==='Select','aggregation-expand' : column==='Expand',
                'select-offset' : column==='Expand' && columns.includes('Select'),  'hideonprint':column==='Expand'}">{{aggregation_row[column]}}
            </th>
          </ng-container>
          <ng-container *ngIf="selected_cols_dict[column]?.type==='constant_property'">
            <th *matHeaderCellDef>{{aggregation_row[column]|formatNumber:format_dict[column].decimals:true:3 | thousandsSeparate}}</th>
          </ng-container>
        </ng-container>

        <!--Select-->
        <ng-container *ngIf="column==='Select'" (click)="$event.stopPropagation()" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell style="text-align:center;width:20px;padding-left:0;">
            <mat-checkbox (change)="selectAll($event)" (click)="$event.stopPropagation()"
                          matTooltip="Select/Clear all"
                          [matTooltipShowDelay]="tooltipShowDelay"></mat-checkbox>
          </th>
          <td (click)="$event.stopPropagation()" *matCellDef="let component;" class="select-column" mat-cell
              style="text-align:center;">
            <mat-checkbox (change)="selectEvent(component, $event)"
                          [checked]="rows_selection_status[component.id]"></mat-checkbox>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!--Expand-->
        <ng-container *ngIf="column==='Expand'" (click)="$event.stopPropagation()" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [ngClass]="{'select-offset' : columns.includes('Select')}"
              class="hideonprint">
          </th>
          <td *matCellDef="let component;" mat-cell class="hideonprint"
              [ngClass]="{'select-offset' : columns.includes('Select')}">
            <div>
              <span (click)="editComponent(component, $event)" class="material-symbols-outlined expand"
                    matTooltip="Expand side panel">open_in_full</span>
              <span class="material-symbols-outlined arrow" matTooltip="Expand row">
              {{component.id == expandedElement?.id ? 'arrow_drop_down' : 'arrow_right'}}</span>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!--Name-->
        <ng-container *ngIf="column==='name'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell sticky mat-sort-header [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event" [constrainWidth]="config.constrain_width"
              [ngClass]="{'select-offset' : columns.includes('Select')}" class="outer-table-name">
            {{selected_cols_dict[column]?.title || 'name' | upperfirst | removeUnderscore}}
          </th>
          <td *matCellDef="let component;" mat-cell sticky
              class="lock-indicator attribute-field"
              [matTooltipDisabled]="!eventsService.event_state[component.id + column]"
              [matTooltip]="eventsService.event_state[component.id + column]? eventsService.event_state[component.id + column].message :
                   component.attributes.description"
              [ngClass]="[eventsService.event_state[component.id + column]? eventsService.event_state[component.id + column].state: '',
columns.includes('Select')?'select-offset':'']">

            <i *ngIf="generic_constants[component.id]?.name?.locked" class="fa fa-lock lock-indicator-icon"
               (click)="checkLock(component, column)" [style.cursor]="can_unlock? 'pointer':'default'"></i>
            <audit-mixin-indicator *ngIf="config.show_audit" [model]="component"></audit-mixin-indicator>

            <div class="row center" style="height:100%;justify-content: space-between;"
                 [ngStyle]="formats ? formats[column][component.id].style : null">
              <div class="row center" style="width:100%;height:100%;">
                <input matInput
                       [(ngModel)]="component.attributes.name"
                       (click)="$event.stopPropagation()"
                       (change)="saveComponent(component, column, $event);"
                       [disabled]="selected_cols_dict[column].disabled || generic_constants[component.id]?.name?.locked || !canEditComponent()"
                       [appFitContent]="component.attributes.name"
                       [allowFit]="format_dict[column].allow_fit"
                       style="width:auto;max-width:250px;min-width:calc(100% - 28px);"/>
              </div>

              <!-- PDF Printouts - printouts list fetched via pipe componentPrintouts -->
              <ng-container *ngIf="config.printout">
                <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="right_mat_menu2"
                        style="display: inline;min-width:unset;padding:0 10px;line-height:unset;"
                        class="user-menu combo" mat-button>
                  <i class="fas fa-print"></i>
                </button>
                <mat-menu #right_mat_menu2="matMenu" backdropClass="header-menu-backdrop"
                          class="tree-node-menu">
                  <ng-template matMenuContent>
                    <ng-container
                        *ngIf="(component.id | componentPrintouts : (config.printouts || null) | async) as printoutItems">

                      <!-- Show search only if more than 5 items -->
                      <option-list-search
                          *ngIf="printoutItems.length > 5"
                          [placeholder]="'Search printouts'"
                          (inputChanged)="printoutFilter=$event"
                          (inputCleared)="printoutFilter=''"
                      ></option-list-search>

                      <!-- Display the filtered printouts -->
                      <ng-container
                          *ngFor="let item of printoutItems | filterByValue: filterPrintouts.bind(this) : printoutFilter; trackBy: trackByPrintout">
                        <span *ngIf="!item.url" style="color:white;font-size:1.1rem;padding: 0 6px">{{item.name}}</span>
                        <a *ngIf="item.url" [href]="item.url" target="_blank" mat-menu-item>{{item.name}}</a>
                        <div *ngIf="!item" style="pointer-events: none; cursor: default; padding: 0 6px">
                          <hr [className]="item.class" style="border: 0; height: 1px; background-color: #dbd3d2"/>
                        </div>
                      </ng-container>

                    </ng-container>
                  </ng-template>
                </mat-menu>
              </ng-container>
              <ng-container
                  *ngIf="config.enable_export_component?.length > 0 ">
                <ng-container *ngFor="let export_component of config.enable_export_component">
                  <ng-container *ngIf="export_component.supports_row_export">
                    <button (click)="$event.stopPropagation(); push_selected_components_to_client(
                      export_component.export_name, [component.id])" style="position: relative; left: 5px;">
                      {{export_component.display_text}}
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef sticky>Aggregation</td>
        </ng-container>

        <ng-container *ngIf="attribute_cols.includes(column) && column !=='name'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event"
              [constrainWidth]="config.constrain_width">
            {{selected_cols_dict[column].title || selected_cols_dict[column].id | upperfirst | removeUnderscore}}
          </th>
          <ng-container *ngIf="column ==='changed_by'; else datePickerTemplate">
            <td *matCellDef="let component;" mat-cell class="attribute-field">
              <div [ngStyle]="formats ? formats[column][component.id]?.style : null">
                {{component.attributes.changed_by_name}}
              </div>
            </td>
          </ng-container>
          <ng-template #datePickerTemplate>
            <td *matCellDef="let component;" class="date-picker lock-indicator" mat-cell>
              <i *ngIf="generic_constants[component.id] && generic_constants[component.id][column]?.locked"
                 (click)="checkLock(component, column)" class="fa fa-lock lock-indicator-icon"></i>
              <date-time-picker
                  (date_changed)="component.attributes.start_time = $event;saveComponent(component, column, $event)"
                  *ngIf="column==='start_time'"
                  [default]="dtp.start"
                  [input_date]="component.attributes.start_time"
                  [set_default]="false"
                  [disabled]="selected_cols_dict[column].disabled || generic_constants[component.id]?.start_time?.locked || !canEditComponent()"
                  [styles]="formats ? formats[column][component.id].style : null"
              ></date-time-picker>
              <date-time-picker
                  (date_changed)="component.attributes.end_time = $event;saveComponent(component, column, $event)"
                  *ngIf="column==='end_time'"
                  [default]="dtp.end"
                  [input_date]="component.attributes.end_time"
                  [set_default]="false"
                  [disabled]="selected_cols_dict[column].disabled || generic_constants[component.id]?.end_time?.locked || !canEditComponent()"
                  [styles]="formats ? formats[column][component.id].style : null"
              ></date-time-picker>
            </td>
            <td mat-footer-cell
                *matFooterCellDef>{{aggregation_row[column]|formatNumber:format_dict[column].decimals:true:3}}
            </td>
          </ng-template>
        </ng-container>


        <!--Constant Properties-->
        <ng-container *ngIf="selected_cols_dict[column]?.type === 'constant_property'"
                      matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event"
              [constrainWidth]="config.constrain_width"
              (dblclick)="getTree(column)"
              mat-sort-header>
            {{selected_cols_dict[column]?.title || cp_dict[column]?.attributes.alias || cp_dict[column]?.attributes.name | upperfirst | removeUnderscore}}
          </th>
          <td (click)="$event.stopPropagation()" *matCellDef="let component" mat-cell
              [matTooltipDisabled]="!eventsService.event_state[component.id + column]"
              [matTooltip]="eventsService.event_state[component.id + column]? eventsService.event_state[component.id + column].message : ''"
              [ngClass]="[eventsService.event_state[component.id + column]? eventsService.event_state[component.id + column].state : '',
 (cp_dict[column]?.attributes.data_type || '')]"
              style="position:relative;">
            <audit-mixin-indicator *ngIf="config.show_audit && audit_mixin_dict && audit_mixin_dict[component.id]
            && audit_mixin_dict[component.id][column]"
                                   [model]="audit_mixin_dict[component.id][column]"></audit-mixin-indicator>

            <ng-container *ngIf="generic_constants[component.id] && generic_constants[component.id].hasOwnProperty(column)
             && cp_dict && cp_dict.hasOwnProperty(column)">
              <ng-container>
                <generic-component-field
                    [constant_property]="cp_dict[column]"
                    [constant]="generic_constants[component.id][column]"
                    [component]="component"
                    [tooltip]="cp_class_dict[component.id + column]?.message"
                    [auto_class]="cp_class_dict[component.id + column]?.class"
                    [formats]="formats[column][component.id]"
                    (relayConstantChangedEmitter)="generic_constants[component.id][column].value = $event;"
                    (relayChangeCompleteEmitter)="saveConstant(component, column, $event)"
                    [disabled]="cp_dict[column].attributes.is_calculation || selected_cols_dict[column].disabled || !canEditComponent()"
                    (relayLockClickedEmitter)="checkLock(component, column)"
                ></generic-component-field>
              </ng-container>
            </ng-container>
          </td>
          <td mat-footer-cell
              *matFooterCellDef>{{aggregation_row[column]|formatNumber:format_dict[column].decimals:true:3 | thousandsSeparate}}
          </td>
        </ng-container>

        <!--Component Types-->
        <ng-container *ngIf="selected_cols_dict[column]?.type === 'component_type'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event"
              [constrainWidth]="config.constrain_width"
              mat-sort-header>
            {{selected_cols_dict[column]?.title || ctDict[column]?.attributes.name | upperfirst | removeUnderscore}}
          </th>
          <td (click)="$event.stopPropagation()" *matCellDef="let component;" mat-cell class="component-type-field">
            <div [ngStyle]="formats ? formats[column][component.id]?.style : null"
                 *ngIf="componentComponentTypeMap?.components?.[component.id]?.[column]">
              <select-search-component *ngIf="ctCtDict[column]?.isUnique"
                                       (selectionChange)="updateComponentComponentRelationship(component, column, $event);"
                                       [disabled]="selected_cols_dict[column].disabled"
                                       [component_types]=[column]
                                       [api_model]="'component'"
                                       [compareFunctionName]="'compareById'"
                                       [custom_filters]="componentComponentTypeMap.filters?.[component.id][column]"
                                       [allow_none]="true"
                                       [sort_by]="'-created_on'"
                                       [classes]="'full-width min-max-inherit table-row show-select-arrow'"
                                       [value]="componentComponentDict[component.id][column]?.[0]">
              </select-search-component>
              <select-many-search-component *ngIf="ctCtDict[column] && !ctCtDict[column].isUnique"
                                            (selectionChangeComplete)="updateComponentComponentRelationship(component, column, $event);"
                                            [disabled]="selected_cols_dict[column].disabled"
                                            [api_model]="'component'"
                                            [custom_filters]="componentComponentTypeMap.filters?.[component.id][column]"
                                            [custom_selected_filters]="componentComponentTypeMap.selectedFilters?.[component.id][column]"
                                            [compareFunctionName]="'compareById'"
                                            [sort_by]="'-created_on'"
                                            [classes]="'auto-width min-max-inherit table-row'"
                                            [value]="componentComponentDict[component.id][column]"
              >
              </select-many-search-component>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>{{aggregation_row[column]}}</td>
        </ng-container>

      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td *matCellDef="let component" [attr.colspan]="columns.length" mat-cell>
          <div [@detailExpand]="component.id == expandedElement?.id ? 'expanded' : 'collapsed'"
               class="resource-detail">
            <div class="expand-inner-table"
                 (click)="expandedEvents = expandedEvents?.id === component.id ? null : component;">
              <i class="fa"
                 [ngClass]="expandedEvents?.id === component.id ? 'fa-caret-up' : 'fa-caret-down'"></i>
              <span *ngIf="expandedEvents?.id !== component.id">Show</span>
              <span *ngIf="expandedEvents?.id === component.id">Hide</span> Events
            </div>
            <div *ngIf="component.id === expandedElement?.id && expandedEvents?.id === component.id">
              <events-table [component]="component" [config]="config"
                            [save_content]="save_content"></events-table>
            </div>
            <ng-container *ngIf="true">
              <div class="expand-inner-table"
                   (click)="expandedComponents = expandedComponents?.id === component.id ? null : component;">
                <i class="fa"
                   [ngClass]="expandedComponents?.id === component.id ? 'fa-caret-up' : 'fa-caret-down'"></i>
                <span *ngIf="expandedComponents?.id !== component.id">Show</span>
                <span *ngIf="expandedComponents?.id === component.id">Hide</span> Components
              </div>
              <div *ngIf="component.id === expandedElement?.id && expandedComponents?.id === component.id">
                <div style="width:100%;">
                  <div *ngFor="let component_type of config.selected_component_relationship_types"
                       style="display:block;width:100%;margin-top:1px;">
                    <components-table [parent_component]="component"
                                      [selected_component_relationship_type]="component_type"
                                      [save_content]="save_content"
                                      [config]="config">
                    </components-table>
                  </div>
                </div>
              </div>
            </ng-container>

          </div>

        </td>
      </ng-container>

      <!--Row defs-->
      <tr *matHeaderRowDef="columns;" sticky mat-header-row></tr>
      <ng-container *ngIf="config.show_aggregations_top && columns.length > 0">
        <tr *matHeaderRowDef="agg_columns" mat-footer-row style="height:40px;"></tr>
      </ng-container>
      <tr (click)="expandedElement = expandedElement?.id === element.id ? null : element;"
          *matRowDef="let element; columns: columns;"
          [class.component-expanded-row]="expandedElement && expandedElement.id === element.id"
          mat-row>
      </tr>
      <ng-container *ngIf="config.show_aggregations">
        <tr *matFooterRowDef="columns" mat-footer-row></tr>
      </ng-container>
      <tr *matRowDef="let row; columns: ['expandedDetail']"
          [class.detail-not-expanded]="!expandedElement || expandedElement.id !== row.id "
          class="event-detail-row"
          mat-row></tr>
    </table>

    <app-pdf-footer></app-pdf-footer>

  </div>
</div>
