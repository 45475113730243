import * as utils from '../lib/utils';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit
} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {PlantDataService} from "../services/plant-data/plant_data.service";
import {AppScope} from "../services/app_scope.service";
import {ActivatedRoute} from "@angular/router";
import {Subject, Subscription, forkJoin, from} from "rxjs";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBarRef} from "@angular/material/snack-bar";
import {EstimateDialogData, EstimateFormComponent} from "../forms/estimate-form.component";
import {SeriesDataService} from "../services/series_data.service";
import {takeUntil, tap} from "rxjs/operators";
import {Series} from "../_models/series";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {
    getManyRelationWithIdsFilter,
    getRelationWithIdFilter,
    getRelationWithManyIdsFilter
} from "../services/api/filter_utils";
import {NotificationService} from "../services/notification.service";
import {SnackbarComponent} from "../notifications/snackbar/snackbar.component";

@Component({
    selector: 'estimate-data-sheet-focus-view',
    templateUrl: 'estimate-data-sheet-focus-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EstimateDataSheetFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    input_sheet_ready: boolean;
    estimate_series: { attributes: any, id: string, relationships: any, type: string }[];
    estimate_types_list: { attributes: any, id: string, relationships: any, type: string }[];
    forecast_year: number;
    kpis: string[];
    start: Date;
    end: Date;
    sample_period: number;
    series_list: Series[];
    permissions: any;
    selected_estimate: { attributes: any, id: string, relationships: any, type: string };
    selected_kpis: string[];
    selected_series_ids: string[];
    process: any;
    params_subscription: Subscription;

    private $estimate_refresh: Subscription;
    private loading_snackbar: MatSnackBarRef<SnackbarComponent>;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private plantData: PlantDataService,
                private appScope: AppScope,
                private route: ActivatedRoute,
                private notification: NotificationService,
                private dialog: MatDialog,
                private changeDetectorRef: ChangeDetectorRef,
                private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        this.params_subscription = this.route.params.subscribe(params => {
            if (this.loading_snackbar) {
                this.loading_snackbar.dismiss();
                this.loading_snackbar = null;
            }
            this.loading_snackbar = this.notification.openSuccess('Loading estimates');
            this.headerData.title = 'Estimates Input';
            this.changeDetectorRef.markForCheck();

            const processID = params.processID;

            this.forecast_year = new Date().getFullYear();
            this.kpis = ['Level 1', 'Level 2', 'Level 3', 'Level 4', '', null];
            this.selected_kpis = ['Level 1', 'Level 2'];

            this.start = new Date();
            this.start = utils.setToHour(this.start, 0);
            this.end = new Date();
            this.end = utils.setToHour(this.end, 23);
            this.sample_period = 2;

            forkJoin([from([this.appScope.auth_complete.promise])]).subscribe(() => {
                const $isoPermissions = this.plantData.getProcessPermissions(processID).pipe(tap(response => {
                    this.permissions = response.permissions;
                }));
                const $flowsheet = this.plantData.getFlowchartDataForProcess(processID).pipe(tap(response => {
                    this.process = response.process_focus;
                    this.plantData.process_focus = response.process_focus;
                    this.series_list = response.selected_series;
                    this.selected_series_ids = response.selected_series_ids;
                    this.buildHeader();
                }));
                const $estimate_types = this.api.series_type.searchMany().pipe(tap(response => {
                    this.estimate_types_list = response.data;
                }));
                forkJoin([$isoPermissions, $flowsheet, $estimate_types]).pipe(takeUntil(this.onDestroy)).subscribe(() => {
                    this.estimate_types_list.forEach(function (estimate) {
                        if (estimate.attributes.name === "Forecast") {
                            this.selected_estimate = estimate;
                        }
                    });
                    this.refreshEstimates();
                });
            });
        });
    }

    ngOnDestroy(): void {
        if (this.params_subscription) {
            this.params_subscription.unsubscribe();
        }
        if (this.loading_snackbar) {
            this.loading_snackbar.dismiss();
        }
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    refreshEstimates() {
        this.input_sheet_ready = false;
        this.changeDetectorRef.markForCheck();

        const parent_series_ids = this.selected_series_ids ? this.selected_series_ids : [];

        const query = new SearchQueryOptions();
        query.filters = [
            getManyRelationWithIdsFilter('source_series', parent_series_ids),
            getRelationWithIdFilter('estimate_type', this.selected_estimate.id)
        ];

        if (this.$estimate_refresh) {
            this.$estimate_refresh.unsubscribe();
            this.$estimate_refresh = null;
        }

        this.$estimate_refresh = this.api.series.searchMany(query).subscribe(response => {
            this.loading_snackbar.dismiss();
            this.estimate_series = response.data;
            if (this.estimate_series.length === 0) {
                this.notification.openError('No estimates found for process', );
                this.changeDetectorRef.markForCheck();
            } else {
                this.refreshDates();

            }
        });
    }

    showEstimateForm() {
        const dialogConfig = new MatDialogConfig<EstimateDialogData>();

        dialogConfig.data = {
            // estimate_types: this.estimate_types_list,
            selectedEstimateType: this.selected_estimate.attributes.name,
            seriesList: this.series_list,
            estimate: null,
            seriesData: this.seriesData
        };

        dialogConfig.panelClass = ['default-form-dialog', 'estimate-form-dialog'];
        let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);

    }

    refreshDates() {
        this.input_sheet_ready = true;
        this.changeDetectorRef.markForCheck();
    }

    buildHeader() {
        this.headerData.title = 'Estimates: ' + this.process.attributes.name;
        this.headerData.setPath(this.process);
        this.headerData.buttons = [
            {name: 'Refresh', func: this.refreshEstimates.bind(this), class: 'icon-refresh', params: {}},
            {name: 'Add Estimate', func: this.showEstimateForm.bind(this), class: 'icon-add', params: {}}
        ];
    }

}
