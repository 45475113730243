import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {
    AbstractControl, FormArray,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ApiService} from "../services/api/api.service";
import {catchError, map, takeUntil} from "rxjs/operators";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {UserFormComponent} from "../forms/user-form/user-form.component";
import {HeaderDataService} from "../services/header_data.service";
import {combineLatest, ReplaySubject, Subject, throwError} from "rxjs";
import {AccountService} from "../services/account.service";
import {Account} from "../_models/account";
import {User} from "../_models/users";
import {ErrorStateMatcher} from "@angular/material/core";
import {ListResponse} from "../services/api/response-types";
import {Role} from "../_models/role";
import {NotificationService} from "../services/notification.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {WIRE_BASE_CONFIG_NAME} from "../shared/globals";
import {UserService} from "../services/user.service";
import {partition as _partition} from "lodash-es";

const httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
    })
};

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted: boolean = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'register',
    templateUrl: 'register.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RegisterUserView implements OnInit, OnDestroy {
    requiredMessage: string = "This field cannot be left blank";
    active_account: Account;
    registerForm: FormGroup;
    submittedRegister: boolean = false;
    user: User;
    loading: boolean = false;
    roles: Role[];
    accounts: Account[];

    private readonly onDestroy: Subject<void> = new Subject<void>();
    matcher: CustomErrorStateMatcher = new CustomErrorStateMatcher();

    constructor(private notification: NotificationService,
                private api: ApiService,
                private http: HttpClient,
                private appScope: AppScope,
                public dialog: MatDialog,
                private accountService: AccountService,
                private headerData: HeaderDataService,
                private snackbar: MatSnackBar,
                private userService: UserService) {
    }

    ngOnInit() {
        this.registerForm = new FormGroup({
            name: new FormControl('', Validators.required),
            username: new FormControl('', [Validators.required, Validators.minLength(1)]),
            middle_name: new FormControl(''),
            last_name: new FormControl('', Validators.required),
            alias: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.email,
                Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,8}$")]),
            roles: new FormControl([], Validators.required),
            accounts: new FormControl([], Validators.required),
            restricted: new FormControl(false),
        });
        this.headerData.title = 'Register new user';
        this.appScope.auth_complete.promise.then(() => {

        });

        const accounts_source: ReplaySubject<Account[]> = this.accountService.accountsChanged;
        const active_account_source: ReplaySubject<{ account_id: string }> = this.accountService.activeAccountChanged;
        const $roles = this.api.role.searchMany().pipe(map((item: ListResponse<Role>) => item.data))
        combineLatest([accounts_source, active_account_source, $roles])
            .pipe(
                takeUntil(this.onDestroy),
                map(([accounts, active_account, roles]) => {
                    return ({accounts, active_account, roles});
                }))
            .subscribe(response => {
                this._setAccountsStatus(response.accounts)
                const active_account = response.active_account;
                this.active_account = this.accounts.find(account => account.id === active_account.account_id);
                this.registerForm.get('accounts').setValue([this.active_account]);

                this.roles = response.roles.sort((a: Role, b: Role): number => (a.attributes.account_name > b.attributes.account_name) ? 1 : -1);
                let selected_roles: Role[] = [];
                const baseConfigUserRole: Role = this.roles.find(role => role.attributes.name === 'User' && role.attributes.account_name === WIRE_BASE_CONFIG_NAME);
                const userRole = this.userService.getUserRole(active_account.account_id, this.roles);
                selected_roles = userRole ? [userRole] : baseConfigUserRole ? [baseConfigUserRole] : [];
                this.registerForm.get('roles').setValue(selected_roles);
            });
    }

    private _setAccountsStatus(accounts: Account[]) {
        this.accounts = this.accountService.getAccountsStatus(accounts);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    get f() {
        return this.registerForm.controls;
    }

    rolesChanged(event) {
        this.validateRoles();
    }

    validateRoles(): boolean {
        const accountIds = this.f.accounts.value.map(account => account.id);
        return this.userService.validateUserRoles(accountIds, this.f.roles.value)
    }

    accountsChanged() {
        this.validateAccounts();
    }

    validateAccounts() {
        const accountIds = this.f.accounts.value.map(account => account.id);
        if (!this.userService.validateUserAccounts(accountIds)) return;

        const roles = this.registerForm.get('roles') as FormArray;
        const updatedRoles: Role[] = this.userService.updateDefaultRoleForAllAccounts(accountIds, roles.value, this.roles)
        this.registerForm.get('roles').setValue(updatedRoles)
    }

    allowRoleSelection(role) {
        const accountIds = this.f.accounts.value.map(account => account.id);
        return accountIds?.includes(role.relationships.account.data.id) || role.attributes.account_name === WIRE_BASE_CONFIG_NAME;
    }

    submit(): void {
        if (!this.registerForm.get('alias').value || !this.registerForm.get('alias').value?.length) {
            this.registerForm.get('alias').setValue(this.registerForm.get('name').value);
        }
        if (this.registerForm.valid) {
            this.submittedRegister = true;
            const vars = {
                name: this.f.name.value,
                username: this.f.username.value,
                middle_name: this.f.middle_name.value,
                last_name: this.f.last_name.value,
                alias: this.f.alias.value,
                email: this.f.email.value,
                roles: this.f.roles.value?.map(r => r.id),
                restricted: this.f.restricted.value,
                accounts: this.f.accounts.value?.map(a => a.id)
            };

            this.http.post('/auth/register', vars, httpOptions).pipe(map((data: { message: string, user: User }):
                { consoleMessage: string, snackbarMessage: string, UserHttpError?: HttpErrorResponse } => {
                    this.loading = false;
                    this.user = data.user;
                    return {snackbarMessage: 'User added successfully', consoleMessage: ''};
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err?.error?.message) {
                        this.snackbar.open(err.error.message, "Dismiss");
                    }
                    if (err?.status == 409) {
                        Object.keys(err.error['user']).forEach((errorFormControlName: string): void => {
                            const uniqueUserFilter: RegExp = new RegExp(`^${this.escapeRegExp(this.registerForm.get(errorFormControlName).value)}$`, 'g');
                            this.registerForm.get(errorFormControlName).setValidators([Validators.required,
                                this.uniqueInputValidator(uniqueUserFilter),
                                ...(this.registerForm.get(errorFormControlName).hasValidator(Validators.email) ?
                                    [Validators.email] : []),
                            ]);
                            this.registerForm.get(errorFormControlName).updateValueAndValidity();
                        });
                    }
                    this.loading = false;
                    console.log('Error posting user', err);
                    return throwError(() => err.error);
                })
            ).subscribe({
                next: (): void => {
                    this.notification.openSuccess('User successfully added', 3000);
                },
                error: (err: any): void => {
                    if (typeof err.message === 'string') {
                        this.notification.openError(err.message);
                    } else if (!Object.keys(err).includes('user')) {
                    } else {
                        console.log(err);
                    }

                }
            });
        } else {
            this.registerForm.markAllAsTouched();
            this.notification.openError('There was an error with your registration. Please check the form and try again.');
        }
    }

    uniqueInputValidator(uniqueUserDetails: RegExp): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const result: boolean = uniqueUserDetails.test(control.value);
            return result ? {uniqueInput: {value: control.value}} : null;
        };
    }

    escapeRegExp(replaceString: string) {
        return replaceString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    }

    openDialog(): void {
        if (this.user) {
            const dialogConfig: MatDialogConfig<User> = new MatDialogConfig<User>();
            dialogConfig.data = this.user;
            dialogConfig.panelClass = ['default-form-dialog', 'user-form-dialog'];
            this.dialog.open(UserFormComponent, dialogConfig);
        }
    }

    trimUsername() {
        const usernameValue = this.f.username.value;
        this.registerForm.patchValue({username: usernameValue.trim()});
    }
}
