import {KeyMap, ModelID} from "../generic-types";
import {Series} from "../../_models/series";
import {OreBody} from "../../_models/ore-body";
import {AttributeTimeConfig, ConstantPropertyTimeConfig} from "./config-type-date-filter";
import {RuleSet} from "ngx-angular-query-builder";

export interface IPivotState {
    report?: { slice?: any }
    menuLimit?: number;
    showUI?: boolean;
    onRefresh?: any;
    derivedAttributes?: any;
    sorters?: any;
    rendererOptions?: any;
    rows: string[];
    cols: string[];
    vals: string[];
}


export class PivotTileConfig {
    event_type_ids: ModelID[] = [];
    component_type_ids: ModelID[] = [];
    series_list: Series[] = [];
    ore_bodies_list: OreBody[] = [];
    constant_property_time: KeyMap<Partial<ConstantPropertyTimeConfig>> = {};
    attribute_time: KeyMap<Partial<AttributeTimeConfig>> = {};
    bypass_date_filters: boolean = false;
    pivot_state?: Partial<IPivotState>;
    hide_totals_rows: boolean = false;
    hide_totals_columns: boolean = false;
    decimal_places: number = 2; // assuming a default value
    property_customisations: KeyMap<Partial<{ label: string; decimal_places: number }>> = {};
    query: { [key: string]: RuleSet | null } = {};

    //label_map: KeyMap<{ curr: string; prev: string; }> = {};

    constructor() {
    }

    static initialiseProperties(config: PivotTileConfig): PivotTileConfig {
        const defaultConfig = new PivotTileConfig();
        return {...defaultConfig, ...config};
    }
}
