import {Injectable} from '@angular/core';
import {RarChartConfiguration, RarRangeTypeArray} from "../charts/chart-config/chart-configuration";
import {
    PlotlyChartConfigTranslationService,
    PlotlyChartConfiguration, PlotlyConfigConfiguration,
    PlotlyDataConfiguration,
    PlotlyLayoutConfiguration
} from "./plotly-chart-config-translation.service";
import {Subject} from "rxjs";
import {targetSVG} from "../../../images/material-svgs/target";
import {Annotations, PlotlyHTMLElement} from 'plotly.js';
import {deepCopy} from '../lib/utils';

declare var Plotly: any;

@Injectable()
export class PlotlyRarChartConfigTranslationService extends PlotlyChartConfigTranslationService {
    target_visibility = false;

    constructor(private plotlyTranslate: PlotlyChartConfigTranslationService) {
        super();
    }

    configureChart(config: RarChartConfiguration): Partial<PlotlyChartConfiguration> {
        let plotyChart: PlotlyChartConfiguration = {
            data: this.configurePlotlyData(config),
            layout: this.configurePlotlyLayout(config),
            config: this.configurePlotlyConfig(config)
        }
        return plotyChart;
    }

    configurePlotlyData(config: RarChartConfiguration): Partial<PlotlyDataConfiguration>[] {
        let data: Partial<PlotlyDataConfiguration>[] = [];
        config.key_values.forEach(key => {
            const x = config.x_values[key];
            const y = config.y_values[key];
            let trace: Partial<PlotlyDataConfiguration> = this.plotlyTranslate.configureBasePlotlyData(config, x, y, key);
            trace.type = config.series_chart_types[key];
            trace.name = config.names[key];
            trace.mode = 'lines+text' + (config.line_styles[key]?.markers ? '+markers' : '');
            const group = data.filter(t => t.legendgroup === trace.name);
            if (trace.type === 'line') {
                trace.legendgroup = trace.name;
                trace.showlegend = group?.length === 0 ? true : false;
                trace.visible = 'legendonly';
                trace.textposition = 'top';
                if (trace.text && trace.textangle !== 0) {
                    config.target_annotations[key] = this.getVerticalLabelAnnotations(trace);
                    trace.text = null;
                }
                trace.line = {
                    width: config.line_styles[key]?.width,
                    dash: config.line_styles[key]?.style,
                    color: config.colours[key]
                }
            }
            data.push(trace);
        })
        return data;
    }

    configurePlotlyLayout(config: RarChartConfiguration): Partial<PlotlyLayoutConfiguration> {
        let layout: Partial<PlotlyLayoutConfiguration> = this.plotlyTranslate.configureBasePlotlyLayout(config);
        layout.xaxis.type = 'category';
        layout.xaxis.tickmode = 'array';
        layout.barmode = config.barmode;
        layout.legend = {
            orientation: "h",
            x: 0.5,
            xanchor: 'center',
            font: config.styles.x_axis_font
        }
        layout.annotations = [].concat.apply([], Object.values(config.target_annotations));
        //layout.annotations = this.getAnnotations(config);
        return layout;
    }

    configurePlotlyConfig(config: RarChartConfiguration): Partial<PlotlyConfigConfiguration> {
        let plot: Partial<PlotlyConfigConfiguration> = this.plotlyTranslate.configurePlotlyConfig(config);
        plot.modeBarButtonsToAdd = [
            {
                title: 'Toggle targets',
                name: 'toggle_targets',
                icon: {
                    svg: targetSVG
                },
                toggle: true,
                click: (gd) => {
                    this.toggleTraces(gd)
                }
            }]
        return plot;
    }

    private getVerticalLabelAnnotations(trace: Partial<PlotlyDataConfiguration>): Partial<Annotations>[] {
        let annotations = [];
        for (let i = 0; i < trace.text.length; i++) {
            annotations.push({
                x: trace.x[i],
                y: trace.y[i],
                text: ' ' + trace.text[i],
                textangle: -90,
                yanchor: "bottom",
                showarrow: false,
                visible: false,
                name: trace.name
            })
        }
        return annotations;
    }

    private getAnnotations(config: RarChartConfiguration) {
        const key = Object.keys(config.names)[0];
        const id = key.slice(0, key.indexOf(':'))

        const base_annotation = {
            xref: 'x',
            yref: 'paper',
            y: -0.16,
            xanchor: 'center',
            yanchor: 'bottom',
            showarrow: false,
            font: config.styles.x_axis_font
        }
        const yesterday = {
            ...base_annotation,
            ...{x: config.x_values[id + ':yesterday'][0], text: config.names[id + ':yesterday']}
        }
        const seven_days = {
            ...base_annotation,
            ...{
                x: config.x_values[id + ':the past seven days'][2],
                xanchor: 'left', text: config.names[id + ':the past seven days']
            }
        }
        const three_months = {
            ...base_annotation,
            ...{x: config.x_values[id + ':the past three months'][1], text: config.names[id + ':the past three months']}
        }

        return [
            yesterday,
            seven_days,
            three_months
        ]
    }

    private toggleTraces(plot: PlotlyHTMLElement, show: boolean = false) {
        let data = plot.data as unknown as PlotlyDataConfiguration[];
        this.target_visibility = !this.target_visibility;
        const visibility = this.target_visibility === false ? 'legendonly' : true;
        const targetTraces = [];
        const annotations = [];

        let traceName;
        for (let i = 0; i < data.length; i++) {
            const trace = data[i];
            if (trace.legendgroup || trace.type === 'line') {
                targetTraces.push(i);
                traceName = plot.data[i].name;
                plot.layout.annotations.forEach(anno => {
                    if (anno['name'] === traceName) {
                        anno.visible = this.target_visibility;
                    }
                });
            }
        }


        if (!targetTraces.length) return;
        Plotly.restyle(plot, {'visible': visibility, 'annotations': annotations}, targetTraces);
    }

    private splitArrayAtIndices(array, indices) {
        const result = [];
        let prevIndex = 0;

        for (const index of indices) {
            result.push(array.slice(prevIndex, index));
            prevIndex = index;
        }
        result.push(array.slice(prevIndex));

        return result;
    }
}
