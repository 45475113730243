import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import * as utils from "../../lib/utils";
import {catchError, map} from "rxjs/operators";
import {Series} from "../../_models/series";
import {NotificationService} from "../notification.service";
import {SeriesDataService} from '../series_data.service';

@Injectable()
export class SeriesService {
    constructor(private notification: NotificationService,
                private seriesData: SeriesDataService) {
    }

    validateSeries(series: Series): boolean {
        const seriesName = series.attributes.name;
        if (series.attributes.fill_method === 'Default value' && (series.attributes.default_value === null || series.attributes.default_value === undefined)) {
            this.notification.openError(`Please add a default value to use this series fill method before saving (${seriesName}).`)
            return false;
        }
        if (series.attributes.aggregation === 'calculation' && !series.attributes.is_calculation) {
            this.notification.openError(`Series can not have Aggregation set to 'calculation' (${seriesName}).`)
            return false;
        }
        if (series.attributes.is_calculation && !(series.attributes.name_formula)) {
            this.notification.openError(`All calculations must have a formula. (${seriesName})`)
            return false;
        }
        if (series.attributes.fill_method === 'Rolling average' && (series.attributes.rolling_average_hours === undefined || series.attributes.rolling_average_hours === null)) {
            this.notification.openError(`Please add a rolling average window period in hours to this series fill method before saving (${seriesName}).`);
            return false;
        }
        return true;
    }

    sortBySeriesOrder(data:Series[]):Series[] {
        data.sort((a, b) => {
            const group1 = a.attributes.report_group || '';
            const order1 = a.attributes.series_order || Infinity;
            const group2 = b.attributes.report_group || '';
            const order2 = b.attributes.series_order || Infinity;
            return group1 !== group2 ? (group1).localeCompare(group2) : (order1 === order2 ? 0 : order1 < order2 ? -1 : 1);
        })
        return data;
    }


    getCalcCheckRequest(series: Series): string {
        let value = series.attributes.name_formula;
        if (!value) {
            this.notification.openError('Please add a formula for this series.');
            return '';
        }
        if (!series.attributes.name) {
            this.notification.openError('Please add a series name.');
            return '';
        }
        //DO NOT USE THE HTTP PARAMSERIALISER ON THIS! IT FAILS FOR THE CASE OF [name]+[name] WITH THE PLUS!
        let get_url = '/api/CheckCalc?name_formula=' + encodeURIComponent(series.attributes.name_formula)
            + '&name=' + series.attributes.name;
        if (series.id) {
            get_url = get_url + '&series_id=' + series.id;
        }
        if (series.attributes.aggregation) {
            get_url = get_url + '&aggregation=' + series.attributes.aggregation;
        }
        if (series.relationships.engineering_unit.data && series.relationships.engineering_unit.data.id) {
            get_url = get_url + '&engineering_unit_id=' + series.relationships.engineering_unit.data.id;
        }
        if (series.attributes.specialised_function) {
            get_url = get_url + '&specialised_function=' + series.attributes.specialised_function;
        }

        return get_url;
    }
}
