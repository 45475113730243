import {Injectable, OnDestroy} from '@angular/core';
import {BasePaginatedTableService} from "../../tables/base-paginated-table.service";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {ApiService} from "../../services/api/api.service";
import {GenericDataService} from "../../data/generic-data.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {catchError, concatMap, finalize, map, mergeMap, take, takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {getBaseFilter, getRelationWithIdFilter, getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {from, Observable, of} from "rxjs";
import {UserPreference} from '../../_models/user-preference';
import {UserPreferenceDataService} from "../../data/user-preference-data.service";
import {IDMap, ModelID} from '../../_typing/generic-types';
import {PageDataService} from "../../data/page-data.service";
import {SessionState} from '../../_models/session-state';
import {Account} from "../../_models/account";
import {User} from "../../_models/users";

@Injectable({
    providedIn: 'root'
})
export class UserPreferenceService extends BasePaginatedTableService<UserPreference> implements OnDestroy {

    dataSource: PaginationDataSource<UserPreference>;
    api_name: string = 'account';
    // TODO add timezone column with timezone selector dropdown?
    columns: string[] = ['account_name', 'default_dashboard'].concat(this.audit_columns.filter(c => c !== 'account_name'));
    search_keys: string[] = [];
    user_preferences: ListResponse<UserPreference>;
    ups_changed_dict: IDMap<UserPreference> = {};
    userAccounts: Account[];

    constructor(private userPreferenceData: UserPreferenceDataService,
                private pageData: PageDataService,
                api: ApiService,
                genericData: GenericDataService) {
        super(genericData, api);
    }

    initialise(user: User) {
        let user_accounts = [user.relationships.default_account.data];
        if (user.relationships.accounts.data.length > 0) {
            user_accounts = user.relationships.accounts.data;
        }
        const initialQuery: SearchQueryOptions = new SearchQueryOptions();
        const account_ids = user_accounts.map(a => a.id);
        initialQuery.filters = [getBaseFilter(account_ids, "id", "in"),
            getBaseFilter("WIREBaseConfig", "name", "ne")];

        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<UserPreference>(
            (query) => this.page(query, user),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            takeUntil(this.onDestroy))
            .subscribe(() => {
                this.dataSourceSubject.next(this.dataSource);
            });

    }

    public hasChanges() {
        return Object.keys(this.ups_changed_dict)?.length && !Object.values(this.ups_changed_dict)?.every(up => up === null);
    }

    public save(): void {
        let $ups: Observable<any>[] = [];
        let errors: string[] = [];

        Object.values(this.ups_changed_dict).forEach((up: UserPreference) => {
            if (!up) {
                return;
            }
            $ups.push(this.userPreferenceData.saveUserPreferences(up).pipe(
                tap(() => {
                    this.ups_changed_dict[up.relationships.account.data.id] = null;
                })));
        });

        if ($ups.length > 0) {
            from($ups).pipe(
                mergeMap(obj => obj, 20),
                catchError(e => {
                    errors.push(e);
                    return of(e);
                }),
                finalize(() => {
                    if (errors.length < 1) {
                        this.saveSubject.next([]);
                    } else {
                        this.saveSubject.next(errors);
                    }
                }))
                .subscribe();
        } else {
            this.saveSubject.next([]);
        }
    }

    updateDefaultPage(up: UserPreference, event: SessionState) {
        up.relationships.session_state.data = {id: event.id, type: 'session_state'};
        this.ups_changed_dict[up.relationships.account.data.id] = up;
    }

    createUserPreferenceObjects(user: User, accounts: Account[]): Observable<ListResponse<UserPreference>> {
        const options: SearchQueryOptions = new SearchQueryOptions();
        const account_ids = accounts.map(a => a.id);
        options.filters = [getRelationWithManyIdsFilter('account', account_ids),
            getRelationWithIdFilter('user', user.id)];
        return this.api.user_preference.searchMany(options)
            .pipe(map(results => {
                this.user_preferences = results;
                accounts.forEach(a => {
                    let up = this.user_preferences.data.find(u => u.relationships.account.data.id === a.id);
                    if (!up) {
                        up = new UserPreference({
                            user_id: user.id,
                            account_id: a.id
                        });
                        up.attributes.account_name = a.attributes.name;
                        this.user_preferences.data.push(up);
                        this.user_preferences.meta.count += 1;
                    }
                });
                return this.user_preferences;
            }), take(1));
    }

    getAllUserPagesFilter(user_id: ModelID) {
        return this.pageData.generateAllUserPagesFilter(user_id);
    }

    page(query: SearchQueryOptions, user: User): Observable<ListResponse<UserPreference>> {
        return super.page(query, this.api_name).pipe(concatMap((result: ListResponse<Account>) => {
            this.userAccounts = result?.data || [];
            return this.createUserPreferenceObjects(user, result.data);
        }));
    }

}
