<mat-paginator showFirstLastButtons style="justify-self: flex-end;visibility: visible;"></mat-paginator>
<div class="table-container">
  <table [dataSource]="dataSource"
         [matSortActive]="'created_on'"
         class="table user-table table-striped"
         mat-table matSort matSortDirection="asc">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
      <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
        <ng-container>{{column | titlecaseForWire | removeUnderscore}}</ng-container>
      </th>
      <td *matCellDef="let element;"
          mat-cell
          style="text-align: left;border:none;">
        <ng-container *ngIf="['account_name'].includes(column)">
          {{element.attributes.account_name}}
        </ng-container>
        <ng-container *ngIf="['default_dashboard'].includes(column)">
          <select-search-component
              [api_model]="'session_state_light'"
              [classes]="'show-select-arrow fill'"
              [custom_filters]="default_dashboard_options_filter"
              (selectionChange)="updateDefaultPage(element, $event.value)"
              [value]="element.relationships.session_state.data"
              [accountId]="element.relationships.account.data.id"
              [allowAccountSelection]="false"
              [filter_by]="['name']"
              [stringFunction]="pagesInfoFunction">

          </select-search-component>
        </ng-container>
        <ng-container
            *ngIf="['created_on','changed_on'].includes(column)">{{element.attributes[column] | stringDate }}
        </ng-container>
        <ng-container
            *ngIf="['created_by_name','changed_by_name'].includes(column)">{{element.attributes[column]}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container>
      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </ng-container>
  </table>
</div>
