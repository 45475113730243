<div class="page">
  <section>

    <div #utils_bar class="row hideonprint utils-bar">
      <div class="table-filter">
        <div class="row" style="position:relative;">
          <input [(ngModel)]="filter_string" placeholder="Search table"/>
          <i class="fa fa-times" matTooltip="Clear database search" (click)="filter_string='';updateSearchFilter();"
             style="position: absolute; right: 10px;font-size: 1.8rem;padding-top: 3px;color: #888888;cursor:pointer;"></i>
        </div>
        <button (click)="updateSearchFilter()" [disabled]="!filter_string" mat-button class="bordered-button search-button">
          <i class="fa fa-search"></i>
        </button>
        <account-filter (accountFilterEmitter)="filterByAccount($event)" [allow_blank]="true"></account-filter>
      </div>

      <mat-paginator [pageSizeOptions]="page_size_options.concat(event_types_total)"
                     showFirstLastButtons
                     style="justify-self: flex-end;">
      </mat-paginator>

    </div>

    <div class="table-container">
      <table [dataSource]="dataSource" [matSortActive]="columns[0]"
             class="event-types-table table-striped" mat-table (matSortChange)="updateSort($event)"
             matSort [matSortDirection]="'desc'">

        <!--Name-->
        <ng-container matColumnDef="{{columns[0]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[0] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type;" mat-cell style="text-align: left;cursor:pointer;border:none;">
            <div (click)="editEventType(event_type)" class="row center">
              <i class="edit-inline hide-xs"></i>
              <div [textContent]="event_type.attributes.name" style="padding-top:2px;"></div>
            </div>
          </td>
        </ng-container>

        <!--Base Type-->
        <ng-container matColumnDef="{{columns[1]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[1] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.base_type}} </td>
        </ng-container>

        <!--Severity-->
        <ng-container matColumnDef="{{columns[2]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[2] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.severity}} </td>
        </ng-container>

        <!--Series List-->
        <ng-container matColumnDef="{{columns[3]}}">
          <th *matHeaderCellDef mat-header-cell>{{columns[3] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell style="text-align: left;border:none;">
            <div *ngIf="series_dict" class="row center">
              <table-cell-relationship-list
                  [parent]="event_type"
                  [filter]="getSeriesFilter(event_type.id)"
                  [model_api]="'series_light'"
                  [editFunction]="editSeries.bind(this)">
              </table-cell-relationship-list>
            </div>
          </td>
        </ng-container>

        <!--Constant Properties-->
        <ng-container matColumnDef="{{columns[4]}}">
          <th *matHeaderCellDef mat-header-cell>{{columns[4] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell style="text-align: left;border:none;">
            <div class="row center">
              <constant-property-list [parent]="event_type"
                                      [editFunction]="editConstantProperty.bind(this)"
              ></constant-property-list>
            </div>
          </td>
        </ng-container>

        <!--Ore Body Types-->
        <ng-container matColumnDef="{{columns[5]}}">
          <th *matHeaderCellDef mat-header-cell>{{columns[5] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell style="text-align: left;border:none;">
            <div *ngIf="obt_dict" class="row center">
              <table-cell-relationship-list
                  [parent]="event_type"
                  [filter]="getOreBodyTypeFilter(event_type.id)"
                  [model_api]="'ore_body_type'"
                  [editFunction]="editOreBodyType.bind(this)">
              </table-cell-relationship-list>
            </div>
          </td>
        </ng-container>

        <!--Component Types-->
        <ng-container matColumnDef="{{columns[6]}}">
          <th *matHeaderCellDef mat-header-cell>{{columns[6] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell style="text-align: left;border:none;">
            <div *ngIf="ct_dict">
              <table-cell-relationship-list
                  [parent]="event_type"
                  [filter]="getComponentTypeFilter(event_type.id)"
                  [model_api]="'component_type'"
                  [editFunction]="editComponentType.bind(this)">
              </table-cell-relationship-list>
            </div>
          </td>
        </ng-container>

        <!--Behaviour-->
        <ng-container matColumnDef="{{columns[7]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[7] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.behaviour}} </td>
        </ng-container>

        <!--Account Name-->
        <ng-container matColumnDef="{{columns[8]}}">
          <th *matHeaderCellDef mat-header-cell>{{columns[8] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.account_name}} </td>
        </ng-container>

        <!--Created On-->
        <ng-container matColumnDef="{{columns[9]}}">
          <th *matHeaderCellDef mat-header-cell>Created</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.created_on | stringDate}} </td>
        </ng-container>

        <!--Created By-->
        <ng-container matColumnDef="{{columns[10]}}">
          <th *matHeaderCellDef mat-header-cell>Created By</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.created_by_name}} </td>
        </ng-container>

        <!--Changed On-->
        <ng-container matColumnDef="{{columns[11]}}">
          <th *matHeaderCellDef mat-header-cell>Changed</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.changed_on | stringDate}} </td>
        </ng-container>

        <!--Changed By-->
        <ng-container matColumnDef="{{columns[12]}}">
          <th *matHeaderCellDef mat-header-cell>Changed By</th>
          <td *matCellDef="let event_type" mat-cell> {{event_type.attributes.changed_by_name}} </td>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>
    </div>
  </section>
</div>
