import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {NotificationService} from "../../services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Subject, Subscription, forkJoin} from "rxjs";
import {takeUntil, tap, first, finalize} from "rxjs/operators";
import {FlowchartData} from "../../_models/flowchart-data";
import {ChartViewModel} from "../../lib/flowchart/flowchart_viewmodel";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {AppScope} from "../../services/app_scope.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {deepCopy} from "../../lib/utils";
import {FlowchartFormComponent} from "../flowchart-form/flowchart-form.component";
import {CustomChartFormDialogComponent} from "../../forms/custom-chart-form-dialog/custom-chart-form-dialog.component";
import {ContextFormDialogComponent} from "../../forms/context-form-dialog/context-form-dialog.component";
import {Process} from "../../_models/process";
import {Connector} from '../../_models/connector';
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'flowchart-focus-view',
    templateUrl: 'flowchart-focus-view.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FlowchartFocusViewComponent implements OnInit, AfterViewInit, OnDestroy {
    chartDataModel: FlowchartData;

    formSize: number = 0;
    chartViewModel: ChartViewModel;
    title: string;
    flowchartReady = false;
    process_schema = {
        data: {
            attributes: {
                base_type: 'process',
                name: null,
                description: null,
                code: null
            }, relationships: {
                parent: {
                    data: {
                        id: this.route.snapshot.params.processID,
                        type: 'process'
                    }
                }
            }, type: 'process'
        }
    };
    equipment_schema = {
        data: {
            attributes: {
                base_type: 'equipment',
                name: null,
                icon: null,
            },
            relationships: {
                component: {data: null}
            },
            type: 'equipment'
        }
    };
    image_schema = {
        title: '',
        src: '',
        height: null,
        width: null,
        x: null,
        y: null,
        constrain_proportions: true,
        type: 'image'
    };
    menu: any;
    menuVisible: boolean = false;
    enableContext: boolean = true;
    selected_components: any[] = [];
    expandSize: number = 30;
    dtp_subscription: Subscription;

    params_subscription: Subscription;
    current_user: any;
    listener: any;
    private readonly onDestroy = new Subject<void>();
    private loadingSubscription: Subscription;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService,
                public appScope: AppScope,
                private plantData: PlantDataService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private notification: NotificationService,
                public dialog: MatDialog,
                private router: Router) {
    }

    ngOnInit(): void {
        this.listener = this.clickListener.bind(this);
        document.addEventListener("click", this.listener);

        this.params_subscription = this.route.params.subscribe(params => {
            this.appScope.authComplete$.subscribe(() => {
                this.headerData.buttons = [];
                this.loadPage();
            });
        });
        this.dtp_subscription = this.dateInst.dateTimePeriodRefreshed$.subscribe((dtp) => {
            this.loadPage();
            this.headerData.page_edit_toggle.next(this.chartViewModel?.editmode || false);
        });
    }

    ngOnDestroy(): void {
        if (this.params_subscription) {
            this.params_subscription.unsubscribe();
        }
        if (this.dtp_subscription) {
            this.dtp_subscription.unsubscribe();
        }

        this.onDestroy.next();
        this.onDestroy.unsubscribe();

        document.removeEventListener("click", this.listener);
    }

    loadPage() {
        this.current_user = this.appScope.current_user;
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = null;
        }

        this.loadingSubscription = this.plantData.getFlowchartDataForProcess(this.route.snapshot.params.processID)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(data => {
                this.chartDataModel = data;
                this.loadChartModel();
            });
    }

    loadChartModel() {
        this.chartDataModel.parent_process = this.chartDataModel.process_focus;
        this.plantData.setActiveProcess(this.chartDataModel.parent_process);

        this.title = this.chartDataModel.parent_process.attributes.name;
        this.buildHeader();
        this.chartViewModel = new ChartViewModel(this.chartDataModel, {});

        if (!this.chartDataModel.process_focus.attributes.json.images) {
            this.chartDataModel.process_focus.attributes.json.images = [];
        }
        if (!this.chartViewModel.parent_process.images) {
            this.chartViewModel.parent_process.images = [];
        }

        this.selected_components = [this.chartViewModel.findProcess(this.chartDataModel.process_focus.id)];
        this.flowchartReady = true;

        this.plantData.getSeriesSummary(this.chartDataModel, this.dateInst.dtp);
    }

    clickListener() {
        if (this.menuVisible === true) {
            this.toggleMenu('hide');
        }
    }

    contextMenu(e) { // emitted event
        if (this.enableContext) {
            e.event.preventDefault();
            this.menu = document.querySelector(".flowchart-context-menu");
            this.selected_components = [e.element];
            const origin = {
                left: e.event.pageX,
                top: e.event.pageY - 36
            };
            this.setPosition(origin);
            return false;
        }
    }

    toggleMenu(command) {
        this.menu = document.querySelector(".flowchart-context-menu");
        this.menu.style.display = command === "show" ? "block" : "none";
        this.menuVisible = command === "show";
    }

    setPosition({top, left}) {
        this.menu.style.left = `${left}px`;
        this.menu.style.top = `${top}px`;
        this.toggleMenu("show");
    }

    buildHeader() {
        this.headerData.title = 'Flowchart: ' + this.chartDataModel.parent_process.attributes.name;
        this.headerData.show_dtp = true;
        this.headerData.setPath(this.chartDataModel.parent_process);
        // this.headerData.add_refresh = false;
        // this.headerData.add_new = false;

        this.headerData.buttons = [
            {name: 'New Process', func: this.newProcess.bind(this), class: 'icon-new hide-xs'},
            {name: 'Edit Mode', func: this.toggleEditMode.bind(this), class: 'fa fa-arrows-alt hide-xs'},
            {name: 'Stream Mode', func: this.toggleStreamMode.bind(this), class: 'fa fa-external-link hide-xs'},
            // {name: 'Show Side Panel', func: this.showSidePanel.bind(this), class: 'fa fa-columns'},
            {name: 'Save All', func: this.saveAll.bind(this), class: 'icon-save hide-xs'},
            {name: 'Refresh', func: this.loadPage.bind(this), class: 'icon-refresh'}];
    }

    ngAfterViewInit(): void {
    }

    expand() {
        if (this.expandSize === 70) {
            this.expandSize = 30;
        } else {
            this.expandSize = 70;
        }
        this.formSize = deepCopy(this.expandSize);
    }

    openDialog(data: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = data;
        dialogConfig.panelClass = ['default-form-dialog', 'flowchart-form-dialog'];
        const dialogRef = this.dialog.open(FlowchartFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.saveAll();
            }
        });
    }

    newProcess() { // called in buildHeader
        this.editSelected(null, true);
    }

    editCustomChart(chart: any = null, config: any = null, new_chart: boolean = true): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {'chart_component': 'custom-chart', 'config': config};
        dialogConfig.panelClass = ['default-form-dialog', 'custom-chart-form-dialog'];
        const dialogRef = this.dialog.open(CustomChartFormDialogComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                let process = this.chartDataModel.process_focus;
                console.log("close", result, process, process.attributes.json);
                if (!process.attributes.json["charts"]) {
                    process.attributes.json["charts"] = [];
                }
                if (new_chart === true) {
                    process.attributes.json["charts"].push(result);
                    this.plantData.saveItem(process);
                    this.chartViewModel.addCustomChart(result, this.chartViewModel.parent_process);
                } else {
                    chart.data = result;
                    process.attributes.json.charts[chart.index] = result;
                    this.plantData.saveItem(process);
                    this.chartViewModel.parent_process.custom_charts[chart.index] = chart;
                }
            }
        });
    }

    editSelectedOreBodyGroup(ore_body_group) {
        this.editOreBodyGroup(ore_body_group, ore_body_group.data, false);
    }

    editOreBodyGroup(ore_body_group: any = null, config: any = null, new_ore_body_group: boolean = true): void {
        const dialogConfig = new MatDialogConfig();
        if (new_ore_body_group) {
            // ore_body_group = {data: {type: 'ore_body_group', data: {id: null, type: 'ore_body_group'}}};
            ore_body_group = {data: {id: null, type: 'ore_body_group'}};
            dialogConfig.data = {component: ore_body_group, flowchart: this.chartViewModel};
        } else {
            dialogConfig.data = {component: ore_body_group.data, flowchart: this.chartViewModel};
        }
        dialogConfig.panelClass = ['default-form-dialog', 'flowchart-form-dialog'];
        const dialogRef = this.dialog.open(FlowchartFormComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                let process = this.chartDataModel.process_focus;
                if (!process.attributes.json["ore_body_groups"]) {
                    process.attributes.json["ore_body_groups"] = [];
                }
                if (new_ore_body_group === true) {
                    const obg_viewmodel = this.chartViewModel.addOreBodyGroup(result, this.chartViewModel.parent_process);
                    process.attributes.json["ore_body_groups"].push(obg_viewmodel.data);
                    this.plantData.saveItem(process);
                } else {
                    ore_body_group.data = result;
                    process.attributes.json.ore_body_groups[ore_body_group.index] = result;
                    this.plantData.saveItem(process);
                    this.chartViewModel.parent_process.ore_body_groups[ore_body_group.index] = ore_body_group;
                }
            }
        });
    }

    editSelectedCustomChart(chart) {
        this.editCustomChart(chart, chart.data, false);
    }

    editImage(image: any = null, config: any = null, new_image: boolean = true, selected_component: any = null): void {
        const dialogConfig = new MatDialogConfig();
        if (config === null) {
            config = deepCopy(this.image_schema);
            image = {data: config};
        }
        dialogConfig.data = {component: image, flowchart: this.chartViewModel};
        dialogConfig.panelClass = ['default-form-dialog', 'flowchart-form-dialog'];
        const dialogRef = this.dialog.open(FlowchartFormComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            let is_parent = false;
            let component; // Parent of the image
            if (result && result.data) {
                config = result.data;
                if (selected_component) { // New image
                    component = selected_component;
                    component.data.attributes.icon = config.src; // Set for backward compatibility/use elsewhere
                } else { // Editing existing
                    component = image.parent_component;
                }
                is_parent = component.is_parent;

                if (!component.data.attributes.json["images"] && is_parent) {
                    component.data.attributes.json["images"] = [];
                }
                if (new_image === true) {
                    // The parent process can have many images but child components can only have one
                    // The same component will use a different attribute (array vs object) depending on
                    // whether it is currently showing as a parent or child on the flowchart
                    if (is_parent) {
                        component.data.attributes.json["images"].push(config);
                    } else {
                        config.x = component.x() + (component.width() / 2 - 40);
                        config.y = component.y() + (component.height() / 2 - 34);
                        component.data.attributes.json.image = config;
                    }
                    this.chartViewModel.addImage(config, component, is_parent);
                } else {
                    image.data = result.data;
                    if (is_parent) {
                        component.data.attributes.json.images[image.index] = image.data;
                    } else {
                        component.data.attributes.json.image = image.data;
                    }
                }
                this.plantData.saveItem(component.data);
            }
        });
    }

    editSelectedImage(image) {
        this.editImage(image, image.data, false);
    }

    editContext(context: any = null, config: any = null, new_context: boolean = true): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {'title': context ? context.title : '', 'config': config};
        dialogConfig.panelClass = ['default-form-dialog', 'context-form-dialog'];
        if (!new_context && config?.type === 'context') {
            if (confirm("This tile type has been deprecated. Would you like to upgrade it to a Series-Summary tile (you may need to make some " +
                "adjustments in Edit Mode after upgrading).")) {
                context.type = 'series-summary';
                dialogConfig.data.config.type = 'series-summary';
                this.notification.openSuccess("Tile updated.", 2000);
            }
        }
        const dialogRef = this.dialog.open(ContextFormDialogComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                let process = this.chartDataModel.process_focus;
                if (!process.attributes.json["contexts"]) {
                    process.attributes.json["contexts"] = [];
                }
                if (new_context === true) {
                    result.type = 'series-summary';
                    process.attributes.json["contexts"].push(result);
                    this.plantData.saveItem(process);
                    this.chartViewModel.addContext(result, this.chartViewModel.parent_process);
                } else {
                    context.data = result;
                    context.data.refresh += 1;
                    process.attributes.json.contexts[context.index] = result;
                    this.plantData.saveItem(process);
                    this.chartViewModel.parent_process.contexts[context.index] = context;
                }
            }
        });
    }

    editSelectedContext(context) {
        this.editContext(context, context.data, false);
    }

    editSelected(process: Process, new_process: boolean) {
        let component = {};
        if (process == null) { // parent_process can be passed in via hotkey
            if (new_process === true) {
                this.process_schema.data.relationships.parent.data.id = this.route.snapshot.params.processID;
                component = deepCopy(this.process_schema);
            } else if (this.chartViewModel.getSelectedProcesses().length > 0) {
                component = this.chartViewModel.getSelectedProcesses()[0];
            } else {
                if (this.chartViewModel.getSelectedStreams().length > 0) {
                    component = this.chartViewModel.getSelectedStreams()[0];
                    // return null
                } else if (this.chartViewModel.getSelectedEquipment().length > 0) {
                    component = this.chartViewModel.getSelectedEquipment()[0];
                } else {
                    component = this.chartViewModel.parent_process;
                    // alert('Make sure a process is selected')
                }
            }
        }
        // this.selected_components = [process];
        this.openDialog({component: component, flowchart: this.chartViewModel});
    }


    addNewEquipment() {
        let equipment = deepCopy(this.equipment_schema);
        let parent_component = null;
        if (this.chartViewModel.getSelectedProcesses().length > 0) {
            parent_component = this.chartViewModel.getSelectedProcesses()[0];
        } else {
            if (this.chartViewModel.getSelectedStreams().length > 0) {
                parent_component = this.chartViewModel.getSelectedStreams()[0];
            } else {
                parent_component = this.chartViewModel.parent_process;
            }
        }
        equipment.data.relationships.component.data = parent_component.data;
        this.selected_components = [equipment];
        this.openDialog({component: equipment, flowchart: this.chartViewModel});
    }

    //
    saveAll() {
        this.chartDataModel.processes.forEach(item => this.plantData.saveItem(item));
        this.chartDataModel.streams.forEach(item => this.plantData.saveItem(item));
        this.chartDataModel.connectors.forEach(item => this.plantData.saveItem(item));
        this.chartDataModel.equipment.forEach(item => this.plantData.saveItem(item));
        this.chartDataModel.series_components.forEach(item => this.plantData.saveItem(item));
        this.chartDataModel.constant_components.forEach(item => this.plantData.saveItem(item));

        this.plantData.saveItem(this.chartDataModel.parent_process);
    }

    // Add an input connector to selected processes.
    addNewConnector() {
        if (this.chartViewModel.editmode === false) {
            this.chartViewModel.showconnectors();
        }
        let connectorName = "";
        let selectedProcesses = this.chartViewModel.getSelectedProcesses();
        if (selectedProcesses.length === 0) {
            selectedProcesses = [this.chartViewModel.parent_process];
        }

        let $saveConnectors = [];
        for (let i = 0; i < selectedProcesses.length; ++i) {

            let process = selectedProcesses[i];
            let percent = 10;
            if (process.connectors.length > 0) {
                percent = (process.connectors[process.connectors.length - 1].percent + 0.1) * 100;
                if (percent >= 100) {
                    percent = percent - 100;
                }
            }

            // Template for a new connector.
            let new_connector = new Connector();
            new_connector.attributes.percent = percent;
            new_connector.attributes.name = connectorName;
            new_connector.relationships.process.data = {id: process.data.id, type: 'process'};

            $saveConnectors.push(this.api.connector.obsSave(new_connector).pipe(tap(newConnectorDataModel => {
                this.chartViewModel.addConnector(newConnectorDataModel.data, process, process.is_parent);
            })));

        }
        forkJoin($saveConnectors).pipe(first()).subscribe();
    }

    addPoint(stream) {
        stream.addPoint();
    }

    // Edit mode - show all connectors, points and grid?
    toggleEditMode() {
        if (this.chartViewModel.editmode === true) {
            this.chartViewModel.editmode = false;
            this.hideEdit();
        } else {
            this.chartViewModel.editmode = true;
            this.showEdit();
        }
        this.headerData.page_edit_toggle.next(this.chartViewModel?.editmode || false);
    }

    showEdit() {
        this.chartViewModel.showconnectors();
        this.chartViewModel.showpoints();
        this.chartViewModel.showgrid();
    }

    hideEdit() {
        this.chartViewModel.hideconnectors();
        this.chartViewModel.hidepoints();
        this.chartViewModel.hidegrid();
        if (this.chartViewModel.streammode === true) {
            this.chartViewModel.streammode = false;
        }
    }

    toggleStreamMode() {
        if (this.chartViewModel.streammode === true) {
            this.chartViewModel.streammode = false;
            if (this.chartViewModel.editmode === false) {
                this.hideEdit();
            }
        } else {
            this.showEdit();
            this.chartViewModel.streammode = true;
        }
    }

    // Delete custom_chart json from process
    deleteSelectedCustomChart(chart) {
        // delete it from the process data
        this.chartViewModel.parent_process.data.attributes.json.charts.splice(chart.index);
        // delete it from the parent process viewModels
        this.chartViewModel.parent_process.custom_charts = this.chartViewModel.parent_process.custom_charts.filter(
            chart_model => chart_model.index !== chart.index
        );
        this.plantData.saveItem(this.chartViewModel.parent_process.data);
    }

    // Delete custom_chart json from process
    deleteSelectedImage(image) {
        // delete it from the process data
        if (image.parent_component === this.chartViewModel.parent_process) {
            this.chartViewModel.parent_process.data.attributes.json.images.splice(image.index);
            // delete it from the parent process viewModels
            this.chartViewModel.parent_process.images = this.chartViewModel.parent_process.images.filter(
                image_model => image_model.index !== image.index
            );
            this.plantData.saveItem(this.chartViewModel.parent_process.data);
        } else {
            let parent_component = image.parent_component;
            parent_component.data.attributes.json.image = null;
            parent_component.data.attributes.icon = null;
            // delete it from the parent process viewModels
            parent_component.image = null;
            this.plantData.saveItem(parent_component.data);
        }
    }

    // Delete context json from process
    deleteSelectedOreBodyGroup(ore_body_group) {
        console.log('flowchartFocusViewCtrl - deleteSelectedOreBodyGroup: ', ore_body_group);
        // delete it from the process data
        this.chartViewModel.parent_process.data.attributes.json.ore_body_groups.splice(ore_body_group.index);
        // delete it from the parent process viewModels
        this.chartViewModel.parent_process.ore_body_groups = this.chartViewModel.parent_process.ore_body_groups.filter(
            obg_model => obg_model.index !== ore_body_group.index
        );
        this.plantData.saveItem(this.chartViewModel.parent_process.data);
    }

    // Delete context json from process
    deleteSelectedContext(context) {
        // delete it from the process data
        this.chartViewModel.parent_process.data.attributes.json.contexts.splice(context.index);
        // delete it from the parent process viewModels
        this.chartViewModel.parent_process.contexts = this.chartViewModel.parent_process.contexts.filter(
            context_model => context_model.index !== context.index
        );
        this.plantData.saveItem(this.chartViewModel.parent_process.data);
    }

    // Delete selected processes and streams.
    deleteSelected(selected_component?) {
        if (!confirm("Please confirm to delete.")) {
            return;
        }
        if (selected_component && selected_component.data && selected_component.data.type) {
            switch (selected_component.data.type) {
                case 'image':
                    this.deleteSelectedImage(selected_component);
                    break;
                case 'ore_body_group':
                    this.deleteSelectedOreBodyGroup(selected_component);
                    break;
                case 'custom_chart' :
                    this.deleteSelectedCustomChart(selected_component);
                    break;
                case 'context' :
                    this.deleteSelectedContext(selected_component);
                    break;
                case 'series-summary' :
                    this.deleteSelectedContext(selected_component);
                    break;
                default:
                    return;
            }
        }

        let deleteItem = item => {
            return this.api[item.data.type].obsDelete(item.data.id);
        };
        let deleted_process = this.chartViewModel.getSelectedProcesses().map(deleteItem);
        let deleted_streams = this.chartViewModel.getSelectedStreams().map(deleteItem);
        let deleted_equipment = this.chartViewModel.getSelectedEquipment().map(deleteItem);
        let deleted_connectors = this.chartViewModel.getSelectedConnectors().map(deleteItem);
        let deleted_series_components = this.chartViewModel.getSelectedSeries().map(deleteItem);
        const allDeleted = [...deleted_connectors, ...deleted_equipment, ...deleted_process, ...deleted_series_components, ...deleted_streams];

        /**Code in finalize as it needs to run for other selected items that don't need to be deleted via api, e.g. points**/
        forkJoin(allDeleted).pipe(finalize(() => {
            this.chartViewModel.deleteSelected();
        })).subscribe();

    }
}
