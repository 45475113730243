import * as Handsontable from "handsontable";
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {HotInstance} from "../../services/hot-instance";
import {tap} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Component({
    selector: 'ore-body-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class OreBodySheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    title: string;
    ore_bodies: any[];
    event_types: any[];
    schema: any;
    column_list: any;
    search: string;
    users: any[];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService,
                private appScope: AppScope) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.appScope.page_title = "Ore Bodies";
        this.hot = new HotInstance();
        this.title = 'Ore Body';

        const promises = [];
        promises.push(this.api.ore_body.searchMany().pipe(tap(response => {
            this.ore_bodies = response.data;
            this.validateOreBodys();
        })));

        promises.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));

        forkJoin(promises).subscribe(this.createTable.bind(ctrl));

        this.buildHeader();
    }

    createTable() {
        // let eventLookups = this.handsontableGenerator.gen_lookups(this.event_types);
        let userLookup = this.handsontableGenerator.gen_lookups(this.users, item => item.attributes.name);

        this.schema = {
            id: null,
            type: 'ore_body',
            attributes: {
                name: null,
                description: null,
                opening_date: null,
                closing_date: null,
                type_name: null,
                points: null,
                input_event_ids: null,
                output_event_ids: null,
                created_on: null,
                changed_on: null
            },
            relationships: {
                input_events: {data: {id: null, type: 'event'}},
                output_events: {data: {id: null, type: 'event'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        this.column_list = [{
            data: 'attributes.name',
            type: 'text',
            title: 'Name',
            allowEmpty: false
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }, {
            data: 'attributes.opening_date',
            title: 'Opening Date',
            type: 'date',
            width: 160,
            // editor: DateTimeEditor,
            dateFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
            correctFormat: true,
            defaultDate: new Date(),
            allowEmpty: false,
            renderer: 'date_formatter'
        }, {
            data: 'attributes.closing_date',
            title: 'Closing Date',
            type: 'date',
            width: 160,
            // editor: DateTimeEditor,
            dateFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
            correctFormat: true,
            defaultDate: new Date(),
            allowEmpty: false,
            renderer: 'date_formatter'
        }, {
            data: 'attributes.type_name',
            type: 'text',
            title: 'Type'
        }, {
            data: 'attributes.points',
            type: 'text',
            title: 'Points',
            width: 300,
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];
        this.hot = this.handsontableGenerator.generateTable(this.api.ore_body, this.schema, this.column_list);
        this.hot.ready = true;
        this.hot.settings.data = this.ore_bodies;
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    save() {
        let results = this.hot.save();
        this.ore_bodies = results.data;
    }

    download() {
        this.hot.download();
    }

    validateOreBodys() {
       // TODO ?
    }

    buildHeader() {
        this.headerData.title = this.appScope.page_title;
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}}
        ];
    }
}
