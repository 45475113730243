<div class="form-dialog event-type-form-dialog">
    <h1 cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane"><span
            [textContent]="solverTemplate && solverTemplate.id ? 'Edit component type: ' + solverTemplate.attributes.name :  'Add component type'"></span>
    </h1>
    <mat-dialog-content>
        <mat-tab-group [disableRipple]="true">
            <!-- Basic Tab -->
            <mat-tab label="Basic" id="basic-tab">
                <form class="form-section column space-between basic">
                    <div id="name" class="row medium-height">
                        <mat-form-field class="inline basic-tab-field">
                            <label>Name</label>
                            <input [(ngModel)]="solverTemplate.attributes.name" matInput name="name" placeholder="">
                        </mat-form-field>
                    </div>
                    <div id="description" class="row medium-height">
                        <mat-form-field class="inline basic-tab-field">
                            <label>Description</label>
                            <input [(ngModel)]="solverTemplate.attributes.description" matInput name="description" placeholder="">
                        </mat-form-field>
                    </div>
                    <div id="error-series" class="row">
                        <select-search-component class="basic-tab-field"
                                [label]="'Error Series Type'"
                                [api_model]="'series_type'"
                                (selectionChange)="setErrorSeriesType($event)"
                                [value]="solverTemplate.relationships.error_series_type?.data"
                                [classes]="'fill'">
                        </select-search-component>
                    </div>
                    <div id="adjusted-series" class="row">
                        <select-many-search-component class="basic-tab-field"
                                [label]="'Adjusted Series Types'"
                                [api_model]="'series_type'"
                                (selectionChange)="setAdjustedSeriesTypes($event);"
                                [value]="solverTemplate.relationships.adjusted_series_types?.data"
                                [classes]="'fill'">
                        </select-many-search-component>
                    </div>
                    <div id="lower-bound" class="row">
                        <select-search-component class="basic-tab-field"
                                [label]="'Lower Bounds Series Type'"
                                [api_model]="'series_type'"
                                (selectionChange)="setBoundsSeriesType($event, 'lower_bounds_series_type');"
                                [value]="solverTemplate.relationships.lower_bounds_series_type?.data"
                                [classes]="'fill'">
                        </select-search-component>
                    </div>
                    <div id="upper-bound" class="row">
                        <select-search-component class="basic-tab-field"
                                [label]="'Upper Bounds Series Type'"
                                [api_model]="'series_type'"
                                (selectionChange)="setBoundsSeriesType($event, 'upper_bounds_series_type');"
                                [value]="solverTemplate.relationships.upper_bounds_series_type?.data"
                                [classes]="'fill'">
                        </select-search-component>
                    </div>
                </form>
            </mat-tab>

            <!-- Constraints Tab -->
            <mat-tab *ngIf="solverTemplate?.id" label="Constraints" id="constraint-tab">
                <ng-template matTabContent>
                    <form class="form-section column space-between">
                        <div class="constraints-container">
                            <constraint-table-form [solverTemplate]="solverTemplate"></constraint-table-form>
                        </div>
                    </form>
                </ng-template>
            </mat-tab>

        </mat-tab-group>
    </mat-dialog-content>
    <div class="action-buttons">
        <button (click)="onCloseClick()" class="btn-close">Close</button>
        <button (click)="apply(true)" class="btn-save">Save</button>
        <button (click)="apply()" class="btn-save">Apply</button>
    </div>
</div>
