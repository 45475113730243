<div class="prompt comment-prompt">
  <h2 class="comment">COMMENT</h2>
  <div [innerHTML]="promptMessage" class="prompt-message"></div>
  <div [innerHTML]="warningMessage" class="warning-message" [ngClass]="{'invisible':!showWarning}"></div>
  <input matInput [(ngModel)]="comment" type="text" (input)="showWarning=false;">
  <div class="action-buttons action-class">
    <button mat-raised-button disableRipple (click)="cancel()" (escToAction)="cancel()" class="cancel-button">{{cancelText}}</button>
    <button class="ok-button" disableRipple mat-raised-button (click)="ok()" (enterToAction)="ok()">{{okText}}</button>
  </div>
</div>
