import {Injectable} from "@angular/core";
import {IDMap, ModelID} from "../_typing/generic-types";
import {getBaseFilter, getManyRelationWithIdFilter} from "./api/filter_utils";
import {ConfigTypeDateFilter, ConfigTypeDateFilterKey} from "../_typing/config/config-type-date-filter";

@Injectable({
    providedIn: 'root'
})

export class ConfigDateFilterService {

    updateConfigDateFilters(configObject: Partial<ConfigTypeDateFilter>, typeIds: ModelID[]) {
        ['constant_property_time', 'attribute_time'].forEach((configProp: ConfigTypeDateFilterKey) => this._cleanKeys(configObject, configProp, typeIds));
    }

    private _cleanKeys(configObject: any, configProp: ConfigTypeDateFilterKey, keysToKeep: ModelID[]): void {
        Object.keys(configObject[configProp])?.forEach(key => {
            if (!keysToKeep.includes(key)) {
                delete configObject[configProp][key];
            }
        });
    }

    getTypeConstantPropertyFilter(typeIds: ModelID[], relationName: 'component_types' | 'event_types'): IDMap<any[]> {
        let cpDateFilter = {};
        typeIds.forEach(id => {
            cpDateFilter[id] = [getManyRelationWithIdFilter(relationName, id),
                getBaseFilter('datetime', 'data_type')];
        })
        return cpDateFilter;
    }

}