import * as moment_ from 'moment';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {forkJoin, Subject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {takeUntil, tap} from "rxjs/operators";
import {SearchQueryOptions} from '../../services/api/search-query-options';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export const moment = moment_["default"];

@Component({
    selector: 'collector-events-table',
    templateUrl: 'collector-events-table.component.html',
    styleUrls: ['collector-events-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CollectorEventsTableComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    private sort;

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    columns: string[] = ["Collector Name", "Execution Time", "Start Time", "End Time", "Message", "Status", "Errors",
        "Entries Collected", "Collected By"];
    page_ready: boolean = false;
    buttons = [];

    collectors: {};
    collectorEvents: any[];
    dtp: IDateTimePeriod;

    dataSource: MatTableDataSource<any>;

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private headerData: HeaderDataService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.dateTimePeriodService.show_timespan = false;
        this.dateTimePeriodService.show_time = true;
        this.headerData.title = 'Collection Events';
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dtp = this.dateInst.dtp;
            this.headerData.show_dtp = true;
            this.buildHeader();
            this.refreshEvents();
        });

        this.dateInst.dateTimePeriodRefreshed$
            .pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.dtp = dtp;
                this.refreshEvents();
            });

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    refreshEvents() {
        const collectorsObservable = this.getCollectors();
        const collectorEventsObservable = this.getCollectorEvents();
        forkJoin([collectorsObservable, collectorEventsObservable]).subscribe(() => {
            this.mapCollectorEvents();
        });
    }

    getCollectors() {
        return this.api.collector.searchMany().pipe(tap(result => {
            this.collectors = {};
            if (!result) { return; }

            result.data.map((item) => {
                this.collectors[item.id] = item;
            });
            return result;
        }));
    }

    getCollectorEvents() {
        const options = new SearchQueryOptions();
        options.filters = [
            {name: 'execution_time', op: 'gt', val: this.dtp.start},
            {name: 'execution_time', op: 'lt', val: this.dtp.end}
        ];
        return this.api.collector_event.searchMany(options).pipe(tap(response => {
            if (!response) { return; }

            this.collectorEvents = response.data;
        }));
    }

    /**
     * Map the collectors to their collector events
     */
    mapCollectorEvents() {
        this.collectorEvents.forEach((event) => {
            event.attributes.collector = this.collectors[event.relationships.collector.data.id];
            if (event.attributes.errors) {
            }
        });
        this.dataSource = new MatTableDataSource(this.collectorEvents);
        this.dataSource.filterPredicate = (data, filter) => {
            return (data.attributes.collector.attributes.name && data.attributes.collector.attributes.name.toLowerCase().includes(filter))
                || (data.attributes.execution_time && data.attributes.execution_time.toLowerCase().includes(filter))
                || (data.attributes.min_date_collected && data.attributes.min_date_collected.toLowerCase().includes(filter))
                || (data.attributes.max_date_collected && data.attributes.max_date_collected.toLowerCase().includes(filter))
                || (data.attributes.message && data.attributes.message.toLowerCase().includes(filter))
                || (data.attributes.entries_collected && data.attributes.entries_collected.length > 1 && data.attributes.entries_collected.includes(filter))
                || (data.attributes.status && data.attributes.status.toLowerCase().includes(filter))
                || (data.attributes.errors && data.attributes.errors.toLowerCase().includes(filter));
        };
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case "Collector Name":
                    return data.attributes.collector.attributes.name;
                case "Execution Time":
                    return data.attributes.execution_time;
                case "Start Time":
                    return data.attributes.min_date_collected;
                case "End Time":
                    return data.attributes.max_date_collected;
                case "Message":
                    return data.attributes.message;
                case "Status":
                    return data.attributes.status;
                case "Entries Collected":
                    return data.attributes.entries_collected;
                case "Errors":
                    return data.attributes.errors;
                default:
                    return "";
            }
        };
        this.dataSource.paginator = this.paginator;
        this.changeDetectorRef.markForCheck();
    }

    buildHeader() {
        this.headerData.title = 'Collection Events';
        console.log("adding download",);
        this.headerData.addDownload();
        this.headerData.add_upload = true;
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }
}
