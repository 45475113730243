import {ComponentType} from "../_models/component-type";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {ApiService} from "../services/api/api.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {map} from "rxjs/operators";
import {ListResponse, SingleResponse} from "../services/api/response-types";
import {GenericDataService, ObjectListByRelationship} from './generic-data.service';
import {
    getManyRelationWithIdsFilter,
    getManyRelationWithNamesFilter,
    getRelationWithIdFilter, getRelationWithManyIdsFilter
} from "../services/api/filter_utils";
import {ModelID} from "../_typing/generic-types";
import {ConstantProperty} from "../_models/constant-property";
import {ComponentTypeComponentType} from "../_models/component-type-component-type";
import {ConfigTypeDateFilter} from "../_typing/config/config-type-date-filter";

export interface ComponentTypeByRelationshipResponse {
    component_types: ComponentType[],
    ct_dict: { [key: string]: ComponentType },
    ct_list_dict: { [key: string]: string[] }
}

/**
 * This class is for shared functions relating to data access on the component_type api
 **/
@Injectable({
    providedIn: 'root'
})
export class ComponentTypeDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private dataService: GenericDataService,
                private api: ApiService) {
    }

    /**Streams component_types by parent_ids
     * Returns an object of type ComponentTypeByParentResponse
     * @param parent_ids:string[]
     * @param parent_type:string
     */
    streamComponentTypesByRelationshipIds(rel_ids: string[], rel_name: string, ids?: string[], names?: string[]): Observable<ComponentTypeByRelationshipResponse> {
        return this.dataService.streamObjectListByRelationshipIds(rel_ids, rel_name, 'component_type', ids, names,
            null, 'name').pipe(
            map((response: ObjectListByRelationship<ComponentType>) => {
                return {
                    component_types: response?.object_list || [],
                    ct_dict: response?.object_dict || {},
                    ct_list_dict: response?.relationship_object_list_dict || {}
                };
            }));
    }

    getComponentTypesByRelationshipIds(rel_ids: string[], rel_name: string, ids?: ModelID[]): Observable<ListResponse<ComponentType>> {
        const options = new SearchQueryOptions();
        options.filters = [getManyRelationWithIdsFilter(rel_name, rel_ids)];
        if (ids && ids.length > 0) {
            options.filters.push({name: 'id', op: 'in', val: ids});
        }
        return this.api.component_type.searchMany(options);
    }

    getComponentTypes(component_type_ids: string[]): Observable<any> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: component_type_ids}];
        return this.api.component_type.searchMany(options);
    }

    getComponentTypesByFirstComponentType(selectedComponentTypeId: ModelID): Observable<ListResponse<ComponentType>> {
        const options = new SearchQueryOptions();
        options.filters = this.getComponentRelationshipByFirstFilters(selectedComponentTypeId);
        return this.api.component_type.searchMany(options);
    }

    getComponentTypesByComponentType(selectedComponentTypeId: ModelID): Observable<ListResponse<ComponentType>> {
        const options = new SearchQueryOptions();
        options.filters = this.getComponentRelationshipFilters(selectedComponentTypeId);
        return this.api.component_type.searchMany(options);
    }

    getComponentTypeComponentTypes(selectedComponentTypeId: ModelID, relatedComponentTypeIds?: ModelID[]): Observable<ListResponse<ComponentTypeComponentType>> {
        const options = new SearchQueryOptions();
        const filter1 =
            {
                and: [
                    getRelationWithIdFilter('first_component_type', selectedComponentTypeId),
                    getRelationWithManyIdsFilter('second_component_type', relatedComponentTypeIds)
                ]
            }
        const filter2 =
            {
                and: [
                    getRelationWithIdFilter('second_component_type', selectedComponentTypeId),
                    getRelationWithManyIdsFilter('first_component_type', relatedComponentTypeIds)
                ]
            }
        options.filters = [{or: [filter1, filter2]}]
        return this.api.component_type_component_type.searchMany(options);
    }

    getComponentRelationshipFilters(selectedComponentTypeId: ModelID): any[] {
        return Object.assign([{
            name: 'component_type_component_type', op: 'any', val: {
                or: [
                    getRelationWithIdFilter('first_component_type', selectedComponentTypeId),
                    getRelationWithIdFilter('second_component_type', selectedComponentTypeId),
                ]
            }
        },
            {not: {name: 'id', op: 'eq', val: selectedComponentTypeId}}])
    }

    getComponentRelationshipByFirstFilters(selectedComponentTypeId: ModelID): any[] {
        return Object.assign([{
            name: 'component_type_component_type', op: 'any', val:
                getRelationWithIdFilter('first_component_type', selectedComponentTypeId),
        },
            {not: {name: 'id', op: 'eq', val: selectedComponentTypeId}}])
    }

    getComponentType(id: ModelID): Observable<SingleResponse<ComponentType>> {
        return this.dataService.getModelById('component_type', id)
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
