import * as d3 from 'd3';
import * as $ from 'jquery';
import { HttpParams } from "@angular/common/http";
import * as Handsontable from "handsontable";
import * as moment from 'moment';
import {moment_timezone} from '../services/timezone-selector.service';
import {Model} from "../services/api/model";
import {forkJoin, from, Observable, of, Subscription} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {BaseModel, KeyMap, ModelID, ModelWithLimits, Stub} from "../_typing/generic-types";
import {ConstantValue} from "../_models/api/generic-constant";
import {
    difference as _difference,
    snakeCase as _snakeCase,
    cloneDeep as _cloneDeep,
    sum as _sum,
    map as _map,
    camelCase as _camelCase
} from "lodash-es";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

//Cycle through an array in ngFor e.g. ngFor="0 to utils.counter(10); let i=index"
export function counter(i: number) {
    return new Array(i);
}

export function fontSizeFromPercent(pixels: number, percent: number) {
    if (!percent) return pixels;
    return (percent / 100 * pixels);
}

export function julianDate(selected_date: Date): string {
    //2-digit year number plus day_of_year plus added 0s to make day of year portion always 3 digits
    let year: string = (selected_date.getFullYear()).toString().substr(2)

    let day: string = moment(selected_date).dayOfYear().toString();
    let julian_day_part: string
    julian_day_part = '0'.repeat(3 - day.length) + day

    return year + julian_day_part;
}

export interface dateFormattingConfig {
    date_separator?: string;
    date_only?: boolean;
    seconds?: boolean;
    milliseconds?: boolean;
    time_zone?: boolean;
    time_only?: boolean;
}

export function stringDate(value: any, args: dateFormattingConfig = {
    date_separator: '/',
    date_only: false,
    seconds: false,
    milliseconds: false,
    time_zone: false,
    time_only: false
}): string {

    //Format returned if no args: YYYY/MM/DD HH:mm e.g. 2020/12/01 14:27
    if (!value) {
        return '';
    }
    let date_separator = args.date_separator || '/';
    let format: string = 'YYYY' + date_separator + 'MM' + date_separator + 'DD';
    if (args.date_only === true) {
        return moment_timezone(value).format(format);
    }
    if (args.time_only === true) {
        format = 'HH:mm';
    } else {
        format += ' HH:mm';
    }
    if (args.seconds === true) {
        format += ':ss';
    }
    if (args.milliseconds === true) {
        format += '.S';
    }
    if (args.time_zone === true) {
        format += 'Z';
    }

    return moment_timezone(value).format(format);
}


export function printDocTitle(title: string, start: Date, end: Date) {
    if (title) {
        title = title.replace(/ /g, "_");
    }
    let print_title = title;
    let string_start = stringDate(start);
    let string_end = stringDate(end);
    if (string_start && string_end) {
        print_title = (title + '_' + string_start + '_' + string_end)
            .replace(new RegExp('\\/', 'g'), '') //Remove /
            .replace(new RegExp(':', 'g'), '') //Remove :
            .replace(new RegExp(' ', 'g'), ''); //Remove spaces
    }
    return print_title;
}

export function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function deepCopy(obj: any) {
    return _cloneDeep(obj); // return unlinked clone.
}

/**
 * Simple Promise that does not force you to resolve inside the function itself.
 */
export class FlatPromise {
    /**
     * Only the promise should be sent to calling code.
     *
     * E.g. a service should return the promise and not the whole FlatPromise.
     */
    promise: Promise<any>;
    resolve: (response?: any) => void;
    reject: (response?: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

export function httpParamSerializer(vars: object): HttpParams {
    let params = new HttpParams();
    Object.keys(vars).forEach(key => {
        if (Array.isArray(vars[key])) {

            vars[key].forEach(val => {
                params = params.append(key, String(val))
            })
        } else {

            params = params.set(key, String(vars[key]))
        }
    });

    return params
}

export function setToHour(date: Date, hour: number) {
    date.setHours(hour);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

export function label(input) {
    let label = upperfirst(input);
    label = label.replace(/_/g, " "); // replace all occurrences
    return label;
}

export function camelLabel(input) {
    if (!input) return;
    return _camelCase(input)
}

export function upperfirst(input) {
    if (!input) return;
    return input.charAt(0).toUpperCase() + input.slice(1);
}

export function decimalNumber(value, decimals) {
    /**
     * Format series value and precision. *
     * *
     * The method has two parts: *
     * 1. Adds thousands seperator to values *
     * 2. Adds precision to decimals *
     */
    if (isNaN(parseFloat(value))) {
        return value;
    }
    if (decimals < 0) {
        return thousandsSeparate(parseFloat(value));
    }
    return thousandsSeparate(parseFloat(value).toFixed(decimals));
}

export function significantNumber(value, precision: number = 3): number | null {
    /**
     *
     */
    if (isNaN(parseFloat(value))) {
        return null;
    }
    if (precision === null || precision === undefined) {
        precision = 3;
    }
    if (value === 0) {
        //  NOTE: there doesn't seem to be a good reason why toPrecision would return something with an "e"?
        return value.toPrecision(precision).includes('e') ? parseFloat(value.toPrecision(precision + 2)) : value.toPrecision(precision);
    }

    if (value && !isNaN(parseFloat(value))) {
        value = parseFloat(value);

        const number_order = String(value).split('.')[0].length;
        let significantValue;
        //Add thousands comma
        // Count of numbers before the decimal that are not fractional (eg. 123.56 has order of 3)
        if (number_order >= 5) {
            // removes fractional part, only returns integer part
            significantValue = parseFloat(value.toPrecision(number_order));
        } else {
            // returns the precision as defined by function...
            significantValue = value.toPrecision(precision).includes('e') ? parseFloat(value.toPrecision(precision + 2)) :
                value.toPrecision(precision);
        }
        const decimalDivide = significantValue.toString().split('.');
        decimalDivide[0] = decimalDivide[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalDivide.join(".");
    }
    return value;
}

export function thousandsSeparate(value) {
    if (isNaN(parseFloat(value))) {
        return value;
    }
    const decimalDivide = value.toString().split('.');
    decimalDivide[0] = decimalDivide[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalDivide.join(".");
}

export function median(arr: number[]) {
    if (arr.length === 0) {
        return 0;
    }
    if (arr.length === 1) {
        return arr[0];
    }
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export function getColorMap(thresholds, colours) {
    const d3_list = colours.map(col => d3.rgb(col));

    return d3.scaleLinear().domain(thresholds)
        .interpolate(d3.interpolate)
        .range(d3_list);
}

export function dayDifference(a, b) {
    return Math.ceil(Math.abs(a.getTime() - b.getTime()) / (1000 * 3600 * 24));
}

export function uniqueList(list: any[]): any[] {
    return [...new Set(list)];
}

export function uniqueObjectList(list: any[], filter_prop?): any[] {
    /**Returns an array set for a list of objects, compared by id**/
    const uniqueArray = list.filter((obj, index) => {
        return index === list.findIndex(item => {
                return (item.id && item.id === obj.id) || (filter_prop && item[filter_prop]?.id === obj[filter_prop]?.id);
            }
        );
    });
    return uniqueArray;
}

//Potentially can use this in the future, unfortunately it's very buggy, units such as tons return as kiloliters.
export function abbreviateNumber(value, unit?) {
    const lazy_abbr = () => {
        let newValue = value;
        const suffixes = ["", " k", " M", " B", " T"];
        let suffixNum = 0;
        while (newValue >= 1000) {
            newValue /= 1000;
            suffixNum++;
        }

        newValue = significantNumber(newValue, 4);
        if ((suffixNum) > suffixes.length) {
            return value
        }
        newValue += suffixes[suffixNum];
        return newValue;
    };
    return lazy_abbr()
    // if (unit) {
    //     if (value == 0) {
    //         return 0.0 + " " + unit
    //     }
    //     let units_list_to_exclude = convert().list().filter(unit_definition => {
    //         if (unit_definition.system === 'imperial' && unit_definition.abbr !== unit) {
    //             if (unit_definition.abbr) {
    //                 return !!unit_definition.abbr
    //             }
    //         }
    //         return false;
    //     }).map(unit_definition => unit_definition.abbr);
    //
    //     if (convert().list().find(unit_definition => {
    //         return unit_definition.abbr == unit
    //     })) {
    //         if (unit == 'm3') {
    //             if (value > 10000000000) {
    //                 let newVal = convert(value).from('m3').to('km3');
    //                 return significantNumber(newVal.val, 4) + ' ' + newVal.unit;
    //             } else {
    //                 return significantNumber(value, 1) +' ' + unit;
    //             }
    //
    //         } else {
    //             let newVal = convert(value).from('m3').toBest({exclude: units_list_to_exclude});
    //             return significantNumber(newVal.val, 4) + ' ' + newVal.unit;
    //         }
    //     } else {
    //         return lazy_abbr()
    //     }
    // } else {
    //     return lazy_abbr()
    // }

}

export function isNullOrUndefined(value) {
    return value === null || value === undefined;
}

export function validURL(str) {
    const pattern = new RegExp(
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);

    return pattern.test(str);
}

export function filterByID(obj) {
    return obj && 'id' in obj && obj.id !== null && obj.id !== '' && obj.id !== undefined;
}

export function getByID(list, id) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
            return list[i]
        }
    }
}

export function getByName(list, name) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].attributes.name === name) {
            return list[i]
        }
    }
}

export function sortObjectsByRelationshipName(objList, objDict, rel, order) {
    if (order !== false) {
        objList.sort((a, b) => (objDict[a.relationships[rel].data.id].attributes.name < objDict[b.relationships[rel].data.id].attributes.name) ? -1 :
            (objDict[a.relationships[rel].data.id].attributes.name > objDict[b.relationships[rel].data.id].attributes.name) ? 1 : 0)
    } else {
        objList.sort((a, b) => (objDict[a.relationships[rel].data.id].attributes.name > objDict[b.relationships[rel].data.id].attributes.name) ? -1 :
            (objDict[a.relationships[rel].data.id].attributes.name < objDict[b.relationships[rel].data.id].attributes.name) ? 1 : 0)
    }
}

export function sortObjectsByFlatProperty(objList, prop, order?): typeof objList {
    /**NOTE: .sort is mutable. The objList is returned here to allow for different syntax options**/
    if (order !== false) {
        objList.sort((a, b) => (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0)
    } else {
        objList.sort((a, b) => (a[prop] > b[prop]) ? -1 : (a[prop] < b[prop]) ? 1 : 0)
    }
    return objList;
}

export function sortObjectsByProperty(objList: any[], prop: string, order?: boolean, nullsLast?: boolean): void {
    objList.sort((a, b) => {
        const aValue = a.attributes[prop];
        const bValue = b.attributes[prop];

        if (nullsLast) {
            if (aValue === null || aValue === undefined) return 1;
            if (bValue === null || bValue === undefined) return -1;
        }

        if (order !== false) {
            return (aValue < bValue) ? -1 : (aValue > bValue) ? 1 : 0;
        } else {
            return (aValue > bValue) ? -1 : (aValue < bValue) ? 1 : 0;
        }
    });
}

export function sortObjectsByDateProperty(objList, prop, order?) {
    if (order !== false) {
        objList.sort((a, b) => (new Date(a.attributes[prop]) < new Date(b.attributes[prop])) ? -1 : (new Date(a.attributes[prop]) > new Date(b.attributes[prop])) ? 1 : 0)
    } else {
        objList.sort((a, b) => (new Date(a.attributes[prop]) > new Date(b.attributes[prop])) ? -1 : (new Date(a.attributes[prop]) < new Date(b.attributes[prop])) ? 1 : 0)
    }
}

// Removes all properties of an object that are not in a give template object
export function match_schema(element, template) {
    if (template == undefined) { //if there is an error with the schema then throw the actual error, not template undefined
        return;
    }
    for (let item in element) {
        if (element.hasOwnProperty(item)) {
            if (template.hasOwnProperty(item)) {
                if (Object.prototype.toString.call(element[item]) === '[object Array]') {
                    for (let i = 0; i < element[item].length; i++) {

                        match_schema(element[item][i], template[item][i])
                    }
                    element[item] = element[item].filter(filterByID)

                } else {
                    if (typeof (element[item]) === 'object' && element[item] !== null && (!element[item].data || element[item].data.id || Array.isArray(element[item].data))) {
                        match_schema(element[item], template[item]);
                    }

                    if (typeof (element[item]) === 'object' && element[item] !== null && element[item].data && !element[item].data.id && !Array.isArray(element[item].data)) {
                        element[item].data = null;
                    }

                    if (typeof (template[item]) === 'object' && template[item] !== null && element[item] === null) {
                        element[item] = $.extend({}, template[item]);
                    }

                    if (element[item] == null && template[item] !== null) {
                        element[item] = template[item]
                    }
                }
            } else {
                delete element[item];
            }
        }
    }
    for (let item in template) {
        if (template.hasOwnProperty(item) && template[item] != null && !element.hasOwnProperty(item)) {
            element[item] = template[item]
        }
    }
}

export function create_stubs(element, relationship) {
    if (element.relationships[relationship].data) {
        element.relationships[relationship].data = element.relationships[relationship].data.map(rel_item => {
            return {'id': rel_item.id, 'type': rel_item.type}
        });
    }
}

export function stub<T>(obj: BaseModel<T> | Stub<T>) {
    if (!obj) return null;
    return {id: obj.id, type: obj.type};
}

export function recursively_null(element) {
    let counter = 0;
    let all_null = true;
    const check_null = sub_element => {
        for (let i in sub_element) {
            if (sub_element.hasOwnProperty(i)) {
                const test_item = sub_element[i];
                if (typeof (test_item) !== null) {
                    if (typeof (test_item) === 'object') {
                        if (counter < 50) {
                            check_null(test_item);
                            counter += 1;
                        }
                    } else if (test_item !== '') {
                        all_null = false;
                    }
                }
            }
        }
    };
    check_null(element);
    return all_null
}

export function cum_sum(list) {
    const sum = [];
    let val = 0;
    for (let i = 1; i < list.length; i++) {
        sum.push(val);
        val += list[i];
    }
    sum.push(val);
    return sum
}

export function genCallback(objs, create_name) {

    return (search, options) => {
        const type_map = [];

        objs.forEach(item => {
            type_map.push({value: item.id, name: create_name(item), description: ''})
        });
        return type_map.filter(item => (true));
    }
}

export function gen_xTick_values(xValues, ticks) {
    let max = xValues.reduce((max, num) => {
        return max > num ? max : num
    });

    let min = xValues.reduce((min, num) => {
        return min < num ? min : num
    });

    let range = max - min;
    let step = range / ticks;

    let x_axis_ticksActual = ((min, max) => {
        const arr = [];

        for (let i = 0; i <= ticks; i++) {
            arr.push((i * step + min).toFixed(3))
        }
        return arr;
    })(min, max);
    return x_axis_ticksActual;
}

export function gen_graph_id(len) {
    let text = "";

    const charset = "abcdefghijklmnopqrstuvwxyz";

    for (let i = 0; i < len; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}

export function gen_col_headers(columns, name_mapper) {
    return columns.map(element => {
        const name = element.data;
        if (name in name_mapper) {
            return name_mapper[name];
        } else {
            return name
        }
    })
}

/**
 * Deletes rows in Handson sheets
 *
 * @param pk
 * @param resource
 * @param notification
 * @param hotInstance
 * @param extra
 */
export function gen_row_deletion(pk, resource: any, notification, hotInstance, extra?, row_locked_dict?) {
    // Returns a method for deleting a handsontable row from server
    return (key, options) => {
        if (key === 'delete_row') {
            setTimeout(() => {
                // timeout is used to make sure the menu collapsed before alert is shown
                if (!confirm("Please confirm to delete this row.")) {
                    return;
                }
                const hot = hotInstance.instance;

                const remove_hot_row = curr_id => {
                    hot.alter('remove_row', curr_id);
                };
                options = options[0];
                let i = options.start.row;
                let deleted_item_obs: Observable<any>[] = [];
                let to_remove_rows = [];

                for (i; i <= options.end.row; i++) {

                    const curr_id = i;

                    const id_data = {};
                    id_data[pk] = curr_id;

                    let inner_resource: any | Model = {};

                    if (resource.hasOwnProperty('query') || resource.hasOwnProperty('baseUrl')) {
                        inner_resource = resource;
                    } else {
                        const type_resource = hot.getDataAtRowProp(curr_id, 'type');

                        inner_resource = resource[type_resource];
                    }
                    const actual_id = hot.getDataAtRowProp(curr_id, 'id');

                    if (actual_id === undefined || actual_id === null) {
                        // remove_hot_row(curr_id);
                        to_remove_rows.push(curr_id);
                    } else if (row_locked_dict && row_locked_dict[actual_id]) {
                        // if no row_locked_dict was provided, then assume the model is not lockable and skip check
                        notification.openError('Row ' + (curr_id + 1) + ' has locked properties and cannot be deleted.', 3000);
                        console.log('Row ' + (curr_id + 1) + '(id: ' + actual_id + ') has locked properties and cannot be deleted.');
                    } else {
                        console.log(`Deleting row with cur_id ${curr_id} & actualId ${actual_id}`);

                        deleted_item_obs.push(from(inner_resource.delete(actual_id)).pipe(map(() => {
                            notification.openSuccess(`Deleted  ${actual_id} row from server`, 3000);
                            to_remove_rows.push(curr_id);
                        }), catchError((err: any, caught: any) => {
                            notification.open('Failed to delete row from server, check dependent objects', "Hide");
                            return of(false);
                        })));
                    }
                }

                forkJoin(deleted_item_obs).pipe(take(1)).subscribe(results => {
                    // make sure the indices to delete are numerically sorted then delete rows in reverse order,
                    // that way the index values are guaranteed not to change from when originally added
                    to_remove_rows.sort((a, b) => {
                        return a - b;
                    });
                    for (let indx = to_remove_rows.length - 1; indx >= 0; indx--) {
                        remove_hot_row(to_remove_rows[indx]);
                    }
                });
            }, 100);
        }
        if (extra != null) {
            extra(key, options);
        }
    };
}

export function dRenderer(instance, td, row, col, prop, value, cellProperties) {
    let escaped = Handsontable.helper.stringify(value);
    escaped = ((escaped != null) && (escaped !== "")) ? moment(escaped, 'YYYY/MM/DDThh:mm').format('YYYY/MM/DD HH:mm') : "";
    arguments[5] = escaped;
    // @ts-ignore
    Handsontable.renderers.DateRenderer.apply(this, arguments);
}

export function hotInfiniteScrollRenderer(instance, td, row, col, prop, value, cellProperties) {
    const stringifiedValue = Handsontable.helper.stringify(value);
    const div = document.createElement('DIV');
    const inner = document.createElement('DIV');
    const i = document.createElement('I');
    div.className = 'hot-infinite-scroll';
    i.className = 'fa fa-caret-down hot-infinite-scroll-click';
    if (cellProperties.readOnly) {
        i.className += ' disabled';
        div.className += ' disabled';
        td.style.backgroundColor = '#f3f3f3';
    }
    inner.textContent = stringifiedValue;
    div.appendChild(inner);
    div.appendChild(i);
    Handsontable.dom.empty(td);
    td.appendChild(div);
}

export function hotDateRenderer(instance, td, row, col, prop, value, cellProperties) {
    const div = document.createElement('DIV');
    const inner = document.createElement('DIV');
    const i = document.createElement('I');
    div.className = 'custom-hot-date-renderer';
    i.className = 'fa fa-caret-down hot-date-renderer-click';
    if (cellProperties.readOnly) {
        td.className += ' disabled htDimmed';
        div.className += ' disabled htDimmed';
        i.className += ' disabled';
    }
    inner.innerText = stringDate(value) || null;
    div.appendChild(inner);
    div.appendChild(i);
    Handsontable.dom.empty(td);
    td.appendChild(div);
}

// TODO don't think this is actually used (it's called but the value isn't used?)
export function match_stubs(object, stubs) {
    stubs.forEach(stub => {
        if (object.relationships[stub.rel] && object.relationships[stub.rel].data && object.relationships[stub.rel].data.id) {
            object.relationships[stub.rel].data = getByID(stub.list, object.relationships[stub.rel].data.id);
        }
    });
    return object;
}

export function fill_relations(model_objects, relation_list, relation) {
    model_objects.forEach(
        item => {
            relation_list.forEach(series => {
                if (item.relationships[relation].data !== null && item.relationships[relation].data.id === series.id) {
                    item.relationships[relation].data = series;
                }
            })
        }
    )
}

/**
 * @deprecated This adds a lot of code smell (polluting what data actually exists on db data objects) and should not be
 * used anymore. Rather manage related data in an adjacent dictionary or create a container class for multiple related
 * models.
 *
 * @param object_list
 * @param relation_list
 * @param relation
 */
export function fill_object_relations(object_list, relation_list, relation) {
    for (let s = 0; s < object_list.length; ++s) {
        const model_object = object_list[s];
        if (model_object.relationships[relation].data !== null) {
            for (let i = 0; i < model_object.relationships[relation].data.length; ++i) {
                const rel_stub = model_object.relationships[relation].data[i];
                relation_list.forEach(full_object => {
                    if (rel_stub.id === full_object.id) {
                        object_list[s].relationships[relation].data[i] = full_object;
                    }
                })
            }
        }
    }
}

//TODO how to add strong/matching types to calling function/list
// export function createObjectDict(list: any[], prop: string, type: Type<T>) {
//     let dict: {[key:string]: typeof type};
//     list.forEach(item => {
//         dict[item[prop] = item]
//     })
//     console.log('createObjectDict - createObjectDict: ', dict);
//     return dict;
// }

export function zip(x, y) {

    return x.map((item, i) => [item, y[i]])
}

export function accumulateData(data) {
    const data_cum = [];
    data.reduce((a, b, i) => data_cum[i] = a + b, 0);
    return data_cum
}

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function isGuid(value) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
}

//* Use this function to sort an array of objects by another array of names
export function mapOrder(array, order, key) {
    if (!order) return array;
    array.sort((a, b) => {
        let A = a[key], B = b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        } else {
            return -1;
        }

    });

    return array;
}

export function getRoundedDate(seconds, d = new Date()) {

    let ms = 1000 * 60 * seconds; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate
}

export function bestDTP(seconds, d = new Date()) {

    let ms = 1000 * 60 * seconds; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate
}

export function scaleLimits(val, dtp, series) {
    if (series.attributes.aggregation === 'total') {
        return dtp.sample_period.hours * val;
    }

    return val;
}

export function removeItem(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array
}

export function removeItems(array, items: number[]) {
    //Removes items from an array in reverse order of their position in the array.
    items.sort(function (a, b) {
        return a - b
    });
    for (var i = items.length - 1; i >= 0; i--) {
        array.splice(items[i], 1);
    }
}

export function nestedProp(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    const a = s.split('.');
    const n = a.length;
    for (let i = 0; i < n; ++i) {
        const k = a[i];
        if (o !== null) {
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
    }
    return o;
}

export function std_dev(array) {
    var avg = _sum(array) / array.length;
    return Math.sqrt(_sum(_map(array, (i) => Math.pow((i - avg), 2))) / array.length);
}

export function pearsonCorrelationCoefficient(d1, d2) {
    let {min, pow, sqrt} = Math;
    let add = (a, b) => a + b;
    let n = min(d1.length, d2.length);
    if (n === 0) {
        return 0
    }
    [d1, d2] = [d1.slice(0, n), d2.slice(0, n)];
    let [sum1, sum2] = [d1, d2].map(l => l.reduce(add));
    let [pow1, pow2] = [d1, d2].map(l => l.reduce((a, b) => a + pow(b, 2), 0));
    let mulSum = d1.map((n, i) => n * d2[i]).reduce(add);
    let dense = sqrt((pow1 - pow(sum1, 2) / n) * (pow2 - pow(sum2, 2) / n));
    if (dense === 0) {
        return 0
    }
    return (mulSum - (sum1 * sum2 / n)) / dense
}

export function compare_ids(item1?: { id?: string }, item2?: { id?: string }): boolean {
    return item1 && item2 && item1.id == item2.id;
}

export function compare_two_ids(item1?: any, item2?: any, rel1_name?: string, rel2_name?: string): boolean {
    return item1?.relationships && item1.relationships[rel1_name]?.data?.id && item1.relationships[rel2_name]?.data?.id &&
        item2?.relationships && item2.relationships[rel1_name]?.data?.id && item2.relationships[rel2_name]?.data?.id &&
        item1.relationships[rel1_name].data.id === item2.relationships[rel1_name].data.id &&
        item1.relationships[rel2_name].data.id === item2.relationships[rel2_name].data.id
}

export function compareById(option, value): boolean {
    if (value) {
        return option.id == value.id; //Numbers and string should be comparable ('2' == 2 : true)
    }
    return false;
}

export function compareByName(option, value): boolean {
    if (value) {
        return option === value;
    }
    return false;
}

export function compareValueToName(option, value): boolean {
    if (value) {
        return option.attributes.name === value;
    }
    return false;
}

export function compareValueToId(option, value): boolean {
    if (value) {
        return option.id == value; //Numbers and string should be comparable ('2' == 2 : true)
    }
    return false;
}

export function compareWith(name, option, value): boolean {
    switch (name) {
        case 'compareById':
            return compareById(option, value);
        case 'compareByName':
            return compareByName(option, value);
        case 'compareValueToName':
            return compareValueToName(option, value);
        case 'compareValueToId':
            return compareValueToId(option, value);
        default:
            return compareById(option, value);
    }
}

export function filterUnfetchedIds(ids: ModelID[], fetchedNames: KeyMap<any>): ModelID[] {
    return _difference(ids, Object.keys(fetchedNames));
}

export function checkDown(obj: any, prop_list: string[], len_0_returns_false = false, empty_returns_false = false) {
    let keep_going: boolean = true;
    if (!obj) {
        return false;
    }
    let object = deepCopy(obj);
    for (let prop of prop_list) {
        if (object[prop] !== undefined && object[prop] !== null) {
            if (Array.isArray(object[prop]) && len_0_returns_false === true && object[prop].length < 1) {
                keep_going = false;
            } else {
                keep_going = !(!Object.keys(object[prop]).length && empty_returns_false === true);
            }
        } else {
            keep_going = false;
        }
        if (keep_going === false) {
            break;
        } else {
            object = object[prop];
        }
    }
    return keep_going;
}

export function refreshSubscription(sub: Subscription | null) {
    if (sub) {
        sub.unsubscribe();
        sub = null;
    }
    return sub;
}

export function countLineBreaks(value): number {
    const match = matchRegex(value, /[\r\n]/g);
    if (match) {
        return match.length + 1 || 1;
    }
    return 1
}

export function matchRegex(value: any, regex: RegExp): string[] {
    if (!value) return;
    return value.toString().match(regex);
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function cssEscape(string) {
    // This matches any special character not allowed in CSS class name (e.g. .selectAll)
    const regex = /[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g;
    return string.replace(regex, '\\$&');
}

export function replaceAll(str: string, find: string, replace: string): string {
    if (!str) return str;
    /**
     * TODO This function invocations should be replaced with the native `replaceAll` function in ES2020 after upgrade to ng 14. *
     */
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function formulaRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    if (instance.getDataAtRowProp(row, 'attributes.name')) {
        if (instance.getDataAtRowProp(row, 'attributes.is_calculation') == false ||
            instance.getDataAtRowProp(row, 'attributes.is_calculation') == null) {
            td.style.background = '#EEE';
            cellProperties.readOnly = true;
        } else {
            cellProperties.readOnly = false;
        }
    } else {
        td.style.background = '#EEE';
        cellProperties.readOnly = true;
    }

}

export function detectLimits(limits: Partial<ModelWithLimits<any>>, value: ConstantValue): {
    warning: boolean,
    error: boolean
} {
    const hi = limits.attributes.hi;
    const hihi = limits.attributes.hihi;
    const low = limits.attributes.low;
    const lowlow = limits.attributes.lowlow;
    let dataValidation = {warning: false, error: false};

    if (value === "" || value === null || isNaN(Number(value))) {
        dataValidation.warning = false;
        dataValidation.error = false;
    }

    value = Number(value);

    if (((hi || hi === 0) && value > hi) || ((low || low === 0) && value < low)) {
        dataValidation.warning = true;
        dataValidation.error = false;
    }

    if (((hihi || hihi === 0) && value > hihi) || ((lowlow || lowlow === 0) && value < lowlow)) {
        dataValidation.error = true;
        dataValidation.warning = false;
    }
    return dataValidation;
}

export function getLimitsPrompt(limits: ModelWithLimits<any>, html: boolean = false): string {
    const hi = limits.attributes.hi || 'not set';
    const hihi = limits.attributes.hihi || 'not set';
    const low = limits.attributes.low || 'not set';
    const lowlow = limits.attributes.lowlow || 'not set';
    if (html) {
        return `Limits exceeded please type number again<br/>
      <span>(LowLow: ${lowlow}, Low: ${low}, Hi: ${hi}, HiHi: ${hihi})</span>`;
    }

    return `Limits exceeded please type number again
(LowLow: ${lowlow}, Low: ${low}, Hi: ${hi}, HiHi: ${hihi})`;

}

export function allStringFilter(filterString: string, options: any[], modelDict?: KeyMap<any>, id?: string) {
    return options.filter(option => filterBy(filterString, option, modelDict, id));
}

export function filterBy(filterString: string, option: any, modelDict?: KeyMap<any>, id?: string) {
    if (!filterString) return true;
    let lcFilters = [_snakeCase(filterString), label(filterString), filterString].map(f => f.toLowerCase());
    return lcFilters.some(f => option.attributes?.name?.toLowerCase().includes(f)
        || option.value?.toLowerCase().includes(f)
        || option.title?.toLowerCase().includes(f)
        || option.name?.toLowerCase().includes(f)
        || (option.id && String(option.id).toLowerCase().includes(f)) //for attribute columns
        || (typeof option === 'string' && option.toLowerCase().includes(f))
        || (modelDict && id && modelDict[option[id]]?.attributes?.name?.toLowerCase().includes(f)));
}
