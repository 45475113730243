<notice-board></notice-board>
<router-outlet class="logged-out" *ngIf="!(appScope.isAuthenticated$ | async);else signedIn"></router-outlet>

<ng-template #signedIn>
  <div appUserInteraction class="body" [ngClass]="currentTheme">
    <ngx-loading-bar *ngIf="(appScope.isAuthenticated$ | async) || !headerData.hidePageLoader"
                     [color]="loadingBarColour"></ngx-loading-bar>

    <main-header></main-header>
    <main-sidenav></main-sidenav>
    <comment-drawer [ngClass]="{'showing_comments_panel': showing_comments_panel}" class="hideonprint"></comment-drawer>
    <print-content></print-content>
  </div>
  <p>{{null | getUser}}</p>
</ng-template>
