import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormDialogService} from "../../services/form-dialog.service";
import {ApiService} from "../../services/api/api.service";
import {SeriesDataService} from "../../services/series_data.service";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {SeriesConstantPermissions} from "../../_typing/series-constant-permissions";
import {EXTENDED_NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {catchError, concatMap, map, take, takeUntil, tap} from "rxjs/operators";
import {from, Observable, of} from "rxjs";
import {ModelID} from "../../_typing/generic-types";
import {NotificationService} from "../../services/notification.service";
import {BaseComponent} from "../../shared/base.component";
import {HttpErrorHandler} from "../../services/http-error-handler.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'series-context-menu',
    templateUrl: './series-context-menu.component.html',
    styleUrls: ['./series-context-menu.component.less'],
    standalone: false
})
export class SeriesContextMenuComponent extends BaseComponent {
    @Input() selected_series: any;
    @Input() seriesPermissionIds: ModelID[];
    @Output() emitRefreshRequest: EventEmitter<void> = new EventEmitter();

    constructor(private api: ApiService,
                private seriesData: SeriesDataService,
                private headerData: HeaderDataService,
                private formDialogService: FormDialogService,
                private dateInst: DateTimeInstanceService,
                private notification: NotificationService,
                httpErrorHandler: HttpErrorHandler,) {
        super();
        this.handleError = httpErrorHandler.createHandleError('SeriesContextMenu');
    }


    openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name);
    }

    editSeries(series) {
        const ctrl = this;
        this.api.series.getById(series.id).toPromise().then((full_series) => {
            ctrl.seriesData.upsertSeries(null, full_series.data);
        })
    }

    private allowOverrideCalcs(seriesIds): Observable<boolean> {
        return this.seriesData.getSeriesPermissions(seriesIds).pipe(map(permissions => {
            if (!permissions) return false;
            return Object.keys(permissions).every(id => {
                    return permissions[id].includes(SeriesConstantPermissions.override_calculations)
                }
            );
        }));
    }

    updateCalculations(series) {
        this.seriesPermissionIds = this.seriesPermissionIds || [this.selected_series.id];
        this.allowOverrideCalcs(this.seriesPermissionIds).pipe(concatMap(allow => {
            if (!allow) {
                this.notification.openWarning("You don't have permissions to override calculations on all the selected nodes", EXTENDED_NOTIFICATION_DURATION_MS);
                return of(null);
            } else {
                return from(this.headerData.getCalculations(this.dateInst.dtp, [series.id], 'hour', 1))
                    .pipe(tap(() => {
                            this.emitRefreshRequest.next();
                        }),
                        catchError(this.handleError<any>('Get calculations')),
                        take(1),
                        takeUntil(this.onDestroy))
            }
        })).subscribe();
    }
}
