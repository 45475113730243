import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {RuleSet} from 'ngx-angular-query-builder';
import {ApiService} from "./api/api.service";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {AppScope} from "./app_scope.service";
import {catchError, concatMap, first, last, map, takeUntil, tap} from "rxjs/operators";
import {DateTimePeriodService} from "./date-time-period.service";
import {Event as WireEvent} from "../_models/event";
import * as utils from "../lib/utils";
import {uniqueList} from "../lib/utils";
import {EventType} from '../_models/event-type';
import {OreBody} from '../_models/ore-body';
import {SearchQueryOptions} from "./api/search-query-options";
import {EventComponent} from "../_models/event-component";
import {ComponentType} from "../_models/component-type";
import {QueryBuilderService} from './query_builder.service';
import {ModelChangesService} from "./api/model-changes.service";
import {ConstantPropertyDataService, PropByRelationshipResponse} from "../data/constant-property-data.service";
import {ListResponse} from "./api/response-types";
import {ConstantProperty} from "../_models/constant-property";
import {ComponentTypeDataService} from "../data/component-type-data.service";
import {ComponentDataService} from '../data/component-data.service';
import {EventDataService} from "../data/event-data.service";
import {CustomEventsDataService} from '../tables/custom-events-table/custom-events-data.service';
import {KeyMap, ModelAttribute, ModelID, IDMap} from '../_typing/generic-types';
import {
    getRelationWithIdFilter,
    getRelationWithManyIdsFilter
} from "./api/filter_utils";
import {ConstantComponent} from '../_models/constant-component';
import {GenericConstantApiResponse, GenericField, isConstant, ConstantField} from "../_models/api/generic-constant";
import {ConstantLockedDict} from './lock-data.service';
import {OreBodyDataService} from "../data/ore-body-data.service";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {NotificationService} from "./notification.service";
import {moment} from './timezone-selector.service';
import {WireComponent} from "../_models/component";
import {Comment} from "../_models/comment";
import {EventTypeComponentTypeMap} from "../_models/event-type-component-type-map";

@Injectable({
    providedIn: 'root'
})
export class CustomEventsService implements OnDestroy {
    schema = {
        id: null,
        type: 'event', // ***
        attributes: {
            base_type: 'event',
            comment: null,
            start: null,
            end: null,
            created_on: null,
            changed_on: null,
            event_type_name: null,
            custom_constants: {},
        },
        relationships: {
            components: {data: []},
            series_list: {data: []},
            created_by: {data: {id: null, type: 'users'}},
            changed_by: {data: {id: null, type: 'users'}},
            input_ore_body: {data: {id: null, type: 'ore_body'}},
            output_ore_body: {data: {id: null, type: 'ore_body'}}

        }
    };
    event_state: { [key: string]: { state: string, message: string } };
    error_list: any;

    private readonly onDestroy = new Subject<void>();

    modelChanges: ModelChangesService;

    constructor(private appScope: AppScope,
                private http: HttpClient,
                private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private queryBuilderService: QueryBuilderService,
                private propertyDataService: ConstantPropertyDataService,
                private componentTypeData: ComponentTypeDataService,
                private componentData: ComponentDataService,
                private eventDataService: EventDataService,
                private customEventsDataService: CustomEventsDataService,
                private oreBodyDataService: OreBodyDataService,
                private notification: NotificationService
    ) {
    }

    getMapConstantsToEvents(event_response: ListResponse<WireEvent>, constant_properties_ids: string[]): Observable<ListResponse<WireEvent>> {

        let events = event_response.data;
        const $constants = this.getEventConstants(events.map(e => e.id), constant_properties_ids);
        const $constant_properties = this.propertyDataService.getConstantProperties(constant_properties_ids);

        return forkJoin([$constants, $constant_properties]).pipe(
            map(([constants, constant_properties]) => {
                let cp_id_dict = constant_properties.data.reduce((dict, cp) => {
                    dict[cp.id] = cp;
                    return dict;
                }, {});
                this.mapApiEventConstants(event_response.data, constants, cp_id_dict);
                return event_response;
            })
        );

    }

    checkStartAndEndDates(toSave: WireEvent | WireComponent | Partial<Comment>, startAtt: 'start' | 'start_time', endAtt: 'end' | 'end_time', changeIds?: string[]): boolean {
        if (toSave.id !== null && (changeIds && !changeIds.includes(toSave.id))) return true;

        if (toSave.attributes[startAtt] && toSave.attributes[endAtt] && moment(toSave.attributes[endAtt]).isBefore(moment(toSave.attributes[startAtt]))) {
            this.notification.openError(`End date must be later than start date`);
            return false;
        }
        return true;
    }

    getEventConstants(event_ids: ModelID[], constant_properties_ids: ModelID[], attributes?: ModelAttribute[]) {
        return this.customEventsDataService.getApiEventConstants(event_ids, constant_properties_ids, attributes);
    }

    mapApiEventConstants(events, eventConstants: GenericConstantApiResponse, cp_dict: KeyMap<ConstantProperty>): void {
        events.forEach(event => {
            let constants: { [key: string]: any } = eventConstants?.data[event.id] || {};
            event.attributes.custom_constants = Object.keys(constants).reduce((prevValue, key) => {
                if (cp_dict[key]) {
                    if (isConstant(constants[key])) {
                        prevValue[cp_dict[key].attributes.name] = constants[key].value;
                    } else {
                        prevValue[cp_dict[key].attributes.name] = null;
                    }
                }
                return prevValue;
            }, {});
        });
    }

    mapEventConstants(events, eventConstants, cp_dict): void {
        events.forEach(event => {
            let constants = eventConstants['data'][event.id] || {};
            event.attributes.custom_constants = Object.keys(constants).reduce((prevValue, value) => {
                prevValue[cp_dict[value].attributes.name] = constants[value];
                return prevValue;
            }, {});
        });
    }

    updateLockedProperties(locked_dict: ConstantLockedDict, events: WireEvent[], properties: ConstantProperty[], constant_data: GenericConstantApiResponse, attributes?: string[]): ConstantLockedDict {
        const constants = constant_data?.data || [];
        events.forEach(e => {
            locked_dict[e.id] = attributes.reduce((locked, att) => {
                return Object.assign(locked, {[att]: constants[e.id]?.[att]?.locked})
            }, {})
            properties.forEach(p => {
                let ec = constants[e.id]?.[p.id];
                locked_dict[e.id][p.id] = ec ? ec.locked : false;
            })
        })
        return locked_dict;
    }

    updateEventComponentLinksLocked(locked_dict: ConstantLockedDict, event_components: IDMap<EventComponent>) {
        Object.values(event_components).forEach(ec => {
            locked_dict[ec.relationships.event.data.id] = Object.assign(locked_dict[ec.relationships.event.data.id] || {},
                {component: ec.attributes.locked});
        })
        return locked_dict;
    }

    getConstantPropertiesByEventTypeIds(event_type_ids: string[], cp_ids?: string[], names?: string[]): Observable<ListResponse<ConstantProperty>> {
        return this.propertyDataService.getConstantPropertiesByRelationshipIds(event_type_ids, 'event_types', cp_ids, names);

    }

    getStreamedConstantPropertiesByEventTypeIds(event_type_ids: string[], cp_ids?: string[], column_names?: string[]): Observable<PropByRelationshipResponse> {
        return this.propertyDataService.streamPropertiesByRelationshipIds(event_type_ids, 'event_types', cp_ids, column_names)
            .pipe(last());
    }

    getComponentsByEvent(event_ids: string[], component_type_ids?: string[]): Observable<any> {
        let filters;
        if (component_type_ids) {
            filters = [
                getRelationWithManyIdsFilter('component_type', component_type_ids)
            ];
        }

        return this.componentData.streamComponentsByParentIds(event_ids, 'events', null, null, filters)
            .pipe(last());
    }

    getComponentTypeProperties(component_type_ids: string[], prop_ids?: ModelID[]): Observable<ListResponse<ConstantProperty>> {
        return this.propertyDataService.getConstantPropertiesByRelationshipIds(component_type_ids, 'component_types', prop_ids);
    }

    getOreBodiesFilter(event_type_ids: string[], dtp: IDateTimePeriod, direction: 'source' | 'destination'): any[] {
        return this.oreBodyDataService.generateOreBodiesByEventTypeFilter(event_type_ids, dtp, direction);
    }

    getEventTypeComponentTypes(event_type_ids: string[], ctIds?: ModelID[]): Observable<any> {
        return this.componentTypeData.getComponentTypesByRelationshipIds(event_type_ids, 'event_types', ctIds);
    }

    getComponentTypesByEventTypes(event_type_ids: string[], columns?: string[], ids?: string[]): Observable<any> {
        return this.componentTypeData.streamComponentTypesByRelationshipIds(event_type_ids, 'event_types', ids, columns)
            .pipe(last());
    }

    getEventTypePropertiesForComponentTypes(component_type_ids: string[]): Observable<ListResponse<ConstantProperty>> {
        return this.propertyDataService.getChildPropertiesByParentIds(component_type_ids, 'component_types', 'event_types');
    }

    getComponentTypeEventTypes(component_type_ids: string[], etIds?: ModelID[]): Observable<ListResponse<EventType>> {
        return this.eventDataService.getEventTypesByRelationshipIds(component_type_ids, 'component_types', etIds);
    }

    getEventComponents(event_ids, component_ids): Observable<any> {
        return this.customEventsDataService.getEventComponents(event_ids, component_ids);
    }

    getEventTypeComponentTypeRelationships(eventTypeIds?: ModelID[], componentTypeIds?: ModelID[]): Observable<ListResponse<EventTypeComponentTypeMap>> {
        if (!eventTypeIds?.length && !componentTypeIds?.length) return of(null);
        const options = new SearchQueryOptions();

        options.filters = [];
        if (eventTypeIds?.length) {
            options.filters.push(getRelationWithManyIdsFilter('event_type', eventTypeIds));
        }

        if (componentTypeIds?.length) {
            options.filters.push(getRelationWithManyIdsFilter('component_type', componentTypeIds));
        }
        return this.api.event_type_component_type_map.searchMany(options);
    }

    getEvenTypeComponentTypeQueryMap(etCtDict: EventTypeComponentTypeMap[]): KeyMap<KeyMap<any>> {
        const singleQuery = (componentTypeId: ModelID, eventTypeId: ModelID) => {
            return [{
                and: [getRelationWithIdFilter('component_type', componentTypeId),
                    {
                        not: {
                            and: [
                                {
                                    name: 'event_components', op: 'any', val:
                                        {
                                            name: 'component',
                                            op: 'has',
                                            val: getRelationWithIdFilter('component_type', componentTypeId)
                                        }
                                }, {
                                    name: 'event_components', op: 'any', val:
                                        {
                                            name: 'event',
                                            op: 'has',
                                            val: getRelationWithIdFilter('event_type', eventTypeId)
                                        }
                                }
                            ]
                        }

                    }]
            }]
        }

        let etCtQueryDict = {};
        etCtDict.forEach(item => {
            const etId = item.relationships.event_type.data.id;
            const ctId = item.relationships.component_type.data.id;
            if (!etCtQueryDict[etId]) {
                etCtQueryDict[etId] = {};
            }
            etCtQueryDict[etId][ctId] = item.attributes.has_one_component ? singleQuery(ctId, etId) : null;
        })
        return etCtQueryDict;
    }


    getEventTypeOreBodyMap(event_types: EventType[], ore_bodies: OreBody[]): Observable<any> {
        const et_ids = uniqueList(event_types.map(et => et.id));
        // ore_body_type is required on ore_body
        const obt_ids = uniqueList(ore_bodies.map(ob => ob.relationships.type.data!.id));

        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithManyIdsFilter('event_type', et_ids),
            getRelationWithManyIdsFilter('ore_body_type', obt_ids)
        ];
        return this.api.event_type_ore_body_type_map.searchMany(options);
    }

    getComponentTypeComponentTypes(component_type_id: string): Observable<ListResponse<ComponentType>> {
        const options = new SearchQueryOptions();
        options.filters = [{
            and: [
                {name: 'id', op: 'ne', val: component_type_id},
                {
                    name: 'component_type_component_type',
                    op: 'any',
                    val: {
                        'or': [
                            getRelationWithIdFilter('first_component_type', component_type_id),
                            getRelationWithIdFilter('second_component_type', component_type_id)
                        ]
                    }
                }]
        }];
        return this.api.component_type.searchMany(options);
    }

    generateQueryBuilderFilter(typedIds: ModelID[], query: KeyMap<RuleSet | null>, relation: 'event_type' | 'component_type' = 'event_type', op: 'and' | 'or' = 'and') {
        let filter: { [key: string]: any[] } = {[op]: []};
        if (query) {
            let typesWithQuery = typedIds.filter(typeId => query[typeId]);
            // construct filter for event_types with query
            for (let typeId of typesWithQuery) {
                let typeQuery: any = [
                    getRelationWithIdFilter(relation, typeId),
                    this.queryBuilderService.toJsonQuery(query[typeId], relation === 'event_type' ? 'EVENT' : 'COMPONENT')
                ];
                filter[op].push({"and": typeQuery});
            }

            // include all event_types that don't have any query
            let typeWithoutQuery: string[] = typedIds.filter(eventTypeId => !query[eventTypeId]);
            if (typeWithoutQuery.length) {
                filter[op].push(getRelationWithManyIdsFilter(relation, typeWithoutQuery));
            }
        }
        return [filter];
    }

    calculateDuration(row) {
        if (row.attributes.start && row.attributes.end) {
            let rHours, rMin, rSec;
            if (new Date(row.attributes.start).getTime() < new Date(row.attributes.end).getTime()) {
                // @ts-ignore
                const dur_ms = (new Date(row.attributes.end)) - (new Date(row.attributes.start));

                const hours = Math.floor(dur_ms / 3600000);
                const minutes = Math.floor(dur_ms % 3600000 / 60000);
                const seconds = dur_ms / 1000 - hours * 3600 - minutes * 60;
                rHours = (hours >= 10) ? hours : "0" + hours;
                rMin = (minutes >= 10) ? minutes : "0" + minutes;
                rSec = (seconds >= 10) ? seconds : "0" + seconds;
            } else {
                rHours = '00';
                rMin = '00';
                rSec = '00';
            }

            return rHours + ':' + rMin + ':' + rSec;
        }
    }

    saveEvent(event_to_save, event): Observable<any> {
        const ctrl = this;
        ctrl.event_state[event.id] = {state: 'dirty', message: 'Not yet saved'};

        return this.api.event.obsSave(event_to_save).pipe(map(result => {
            result.temp_id = utils.deepCopy(event.id);
            event.id = result.data.id;
            ctrl.event_state[event.id] = {state: 'success', message: 'Event saved successfully'};
            return result;
        }), catchError((err: any, caught: any) => {
            console.log('ERROR: CustomEventsService (saveEvent)', err, caught);
            ctrl.event_state[event.id] = {state: 'error', message: err.statusText};
            ctrl.error_list.push({event: event.id, error: err});
            return of({event: event.id, error: err});
        }));
    }

    patchEvent(event: Partial<WireEvent>, event_component_changes = null): Observable<any> {
        const ctrl = this;
        ctrl.event_state[event.id] = {state: 'dirty', message: 'Not yet saved'};

        delete event.attributes.properties_data_map;
        //let $patch = this.modelChanges.obsPatch({api: 'event'}, event);
        let $patch = this.api.event.obsPatch(event);
        let flat_event_components = [];
        if (event_component_changes) {
            Object.values(event_component_changes).map(item => flat_event_components = flat_event_components.concat(item));
        }

        return $patch.pipe(concatMap(result => {
            ctrl.event_state[event.id] = {state: 'success', message: 'Event saved successfully'};
            if (flat_event_components.length > 0) {
                return forkJoin(flat_event_components).pipe(map(() => result));
            } else {
                return of(result);
            }
        }), catchError((err: any, caught: any) => {
            console.log('ERROR: CustomEventsService (saveEvent), event not saved', err, caught);
            ctrl.event_state[event.id] = {state: 'error', message: err.statusText};
            ctrl.error_list.push({event: event.id, error: err});
            return of({event: event.id, error: err});
        }));
    }

    mapEventComponentTypes(data, event_ct_component_map, component_type_map, component_types, components, ct_name_dict) {
        data.forEach(event => {
            //The event_component_t_map object is used to hold the component object selected for each component_type on the event
            event_ct_component_map[event.id] = {};
            component_types.forEach(ct => {
                if (component_type_map[ct.id] && component_type_map[ct.id].length > 0) {
                    event_ct_component_map[event.id][ct.id] = {id: null, type: 'component'};
                }
            });
            //Store the original values
            event.relationships.components.data?.forEach(component => {
                const full_component = components.find(c => c.id === component.id);
                if (!full_component) {
                    return;
                }
                event_ct_component_map[event.id][full_component.relationships.component_type.data.id] = full_component;
            });
        });
    }

    fetchAndReplaceComponentEvents(event_components): Observable<any> {
        let $upserts = [];

        for (let i = 0; i < event_components.length; i++) {
            const event_component = event_components[i];
            const ec = event_component.ec;
            if (!ec.relationships.event.data.id) {
                continue;
            }

            const $delete = this.http.delete('/api/relation/event_component/' + ec.relationships.event.data.id + '/' + event_component.old_c);
            const $post_event_component = this.http.post('/api/relation/event_component',
                {event_id: ec.relationships.event.data.id, component_id: ec.relationships.component.data.id});

            if (!event_component.old_c) {
                //New ec
                $upserts.push($post_event_component);
            } else if (event_component.old_c && !ec.relationships.component.data.id) {
                //Delete ec
                $upserts.push($delete);
            } else {
                //Replace ec
                $upserts.push($delete.pipe(concatMap(() => $post_event_component)));
            }

        }

        if ($upserts.length > 0) {
            return forkJoin($upserts).pipe(first(), takeUntil(this.onDestroy));
        } else {
            return of([]);
        }
    }

    updateNewEventComponentIds(data, ec_to_save, components, event_ct_component_map) {
        data.forEach(item => {
            if (item.temp_id) {
                ec_to_save.forEach(ec => {
                    if (ec.ec.relationships.event.data.id === item.temp_id) {
                        ec.ec.relationships.event.data.id = item.id;
                        const component = components.find(c => c.id === ec.ec.relationships.component.data.id);
                        event_ct_component_map[item.id] = {[ec.ct]: component};
                    }
                });
                delete item.temp_id;
            }
        });
        //Remove ecs for events that weren't saved and ones with empty components
        ec_to_save = ec_to_save.filter(ec => ec.ec.relationships.event.data.id !== null);
        ec_to_save = ec_to_save.filter(ec => (ec.ec.relationships.component.data.id !== null && ec.ec.relationships.component.data.id !== '')
            || ec.old_c);
        return ec_to_save;
    }

    setEventComponentToSave(item, ct: ComponentType, ec_to_save, event_ct_component_map) {
        if (item.id && event_ct_component_map[item.id][ct.id].id === item.relationships[ct.id].data.id) {
            return;
        }
        let event_component = new EventComponent({
            event_id: item.id ? item.id : item.temp_id,
            component_id: item.relationships[ct.id].data.id,
        });
        ec_to_save.push({
            ec: event_component,
            ct: ct.id,
            old_c: item.id ? event_ct_component_map[item.id][ct.id].id : null
        });
    }

    deleteEvent(event_id, index): Observable<any> {
        const ctrl = this;
        return this.api.event.obsDelete(event_id)
            .pipe(tap(result => result),
                catchError((err: any, caught: any) => {
                    console.log('ERROR: CustomEventsService (deleteEvent)', err, caught);
                    ctrl.event_state[event_id] = {state: 'error', message: err.statusText};
                    ctrl.error_list.push({event: event_id, error: err});
                    return of({event: event_id, index: index, error: err});
                })
            );
    }

    // patchComponent(component): Observable<any> {
    //     const ctrl = this;
    //     return this.api[component.type].obsPatch(component).pipe(tap(result => {
    //         ctrl.event_state[component.id] = {state: 'success', message: 'Component saved successfully'};
    //     }), catchError((err: any, caught: any) => {
    //         console.log('Rejected', err, caught);
    //         ctrl.event_state[component.id] = {state: 'error', message: err.statusText};
    //         return of({component: component.id, error: err});
    //     }));
    // }

    patchConstant(constant_component: ConstantComponent, parent, prop, data_type: string): Observable<any> {
        const ctrl = this;
        ctrl.event_state[parent.id + prop] = {state: 'dirty', message: 'Not yet saved'};
        return this.api.constant_component.obsPatch(constant_component)
            .pipe(tap(result => {
                ctrl.event_state[parent.id + prop] = {state: 'success', message: 'Property saved successfully'};
            }), catchError((err: any, caught: any) => {
                console.log('ERROR: CustomEventsService (patchConstant)', err, caught);
                ctrl.event_state[parent.id + prop] = {state: 'error', message: 'Not saved. ' + err.statusText};
                return of({component: parent.id + prop, error: err});
            }));
    }

    saveConstant(constant_component, parent, prop, data_type: string): Observable<any> {
        const ctrl = this;
        ctrl.event_state[parent.id + prop] = {state: 'dirty', message: 'Not yet saved'};
        return this.api.constant_component.obsSave(constant_component)
            .pipe(tap(result => {
                ctrl.event_state[parent.id + prop] = {state: 'success', message: 'Property saved successfully'};
            }), catchError((err: any, caught: any) => {
                console.log('ERROR: CustomEventsService (saveConstant)', err, caught);
                ctrl.event_state[parent.id + prop] = {state: 'error', message: 'Not saved. ' + err.statusText};
                return of({component: parent.id + prop, error: err});
            }));
    }

    // TODO put these somewhere generic, maybe appScope or utils?
    compareById(option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
        return false;
    }

    compareByName(option, value): boolean {
        if (value) {
            return option === value;
        }
        return false;
    }

    compareValueToName(option, value): boolean {
        if (value) {
            return option.attributes.name === value;
        }
        return false;
    }

    compareValueToId(option, value): boolean {
        if (value) {
            return option.id === value;
        }
        return false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
