import {Injectable} from '@angular/core';
import {FieldOptionService} from "./field-option.service";
import {CustomEventsService} from "../../../services/custom-events.service";
import {
    COMPONENT_EVENTS_CONFIG
} from "../../../forms/component-events-table-form/component-events-table-form.component";
import {FieldMap, Rule} from "../../../_typing/query-builder";
import {Observable, of} from "rxjs";
import {QueryBuilderFormService} from "../../../services/query-builder-form.service";

@Injectable({
    providedIn: 'root'
})
export class ComponentTypeFieldOptionService extends FieldOptionService {
    config: COMPONENT_EVENTS_CONFIG;
    defaultRule: Rule;

    get defaultComponentTypeFields() {
        return {
            ...this.getDefaultColumn('Name', 'string', 'name'),
            ...this.getDefaultColumn('Start Time', 'datetime', 'start_time'),
            ...this.getDefaultColumn('End Time', 'datetime', 'end_time'),
            ...this.getDefaultColumn('Created On', 'datetime', 'created_on')
        };
    }


    constructor(public customEventsService: CustomEventsService,
                private queryBuilderFormService: QueryBuilderFormService) {
        super(customEventsService);
    }

    loadComponentType(config: COMPONENT_EVENTS_CONFIG, isQueryBuilder:boolean = true): Observable<FieldMap> {
        this.config = config;
        if (!this.config.selected_component_type?.id) {
            return of(null);
        }
        this.selectedColsDict = {};
        this.config.selected_cols.component_type.forEach(col => this.selectedColsDict[col.id] = col);

        this.defaultRule = new Rule(this.defaultComponentTypeFields['start_time'].value,
            this.queryBuilderFormService.operatorMap[this.defaultComponentTypeFields['start_time'].type][0]);
        this.queryBuilderFormService.defaultRuleChanged.next(this.defaultRule);

        return this.getComponentTypeFields(this.config.selected_component_type.id, this.defaultComponentTypeFields, this.config.selected_cols.component_type);

    }
}
