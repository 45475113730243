import {Component} from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {takeUntil} from "rxjs";
import {BaseComponent} from "../../shared/base.component";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'print-header-tile',
    templateUrl: './print-header-tile.component.html',
    styleUrls: ['./print-header-tile.component.less'],
    standalone: false
})
export class PrintHeaderTileComponent extends BaseComponent {
    clientLogoSrc: string = '/admin/myfiles/download/right-logo.png';
    wireLogoSrc: string = '/static/images/wire-logo-black.png';
    clientLogoStyles = {};
    wireLogoStyles = {};
    hideWireLogo = false;
    printing = false;

    constructor(public headerData: HeaderDataService,
                public dateInst: DateTimeInstanceService,
                public appScope: AppScope) {
        super()
    }

    ngOnInit() {
        const styles = this.appScope.config_name_map?.print_styles?.value;
        if (styles?.client_logo?.height) {
            this.clientLogoStyles = {'height': styles.client_logo.height};
        }
        if (styles?.wire_logo?.height) {
            this.wireLogoStyles = {'height': styles.wire_logo.height};
        }
        this.headerData.pagePrinting.pipe(takeUntil(this.onDestroy)).subscribe((value => {
            this.printing = value;
        }));
        this.headerData.hideWireLogo.pipe(takeUntil(this.onDestroy)).subscribe((value => {
            this.hideWireLogo = value;
        }));
    }

    currentDate(): Date {
        return new Date();
    }
}
