<div class="page traveler ore-body-group-view" style="padding-right:0;">
  <div style="width:calc(100vw - 36px);height:calc(100vh - 68px - 48px - 36px);position:relative;">
    <as-split #ore_body_view="asSplit" [direction]="'horizontal'" unit="pixel">

      <as-split-area #left_panel="asSplitArea" [size]="'300'" maxSize="300">

        <div class="row" style="margin-bottom:20px;">
          <button (click)="current_view='select'" [disabled]="current_view==='select'" style="margin-right:8px;">Select
            View
          </button>
          <button (click)="current_view='tree'" [disabled]="current_view==='tree'">Tree View</button>
        </div>

        <ng-container *ngIf="current_view==='select'">

          <div *ngIf="ore_body_group_types && ore_body_group_types.length > 0 && ore_bodies_list"
               style="width:290px;position:relative;">
            <select-search (selectChange)="filterGroups(0)"
                           [(value)]="selected_group_type"
                           [classes]="'fill'"
                           [data]="ore_body_group_types"
                           [fill_stubs]="true"
                           [label]="'select-ore-body-group-type' | clientTranslator">
            </select-search>
          </div>

          <ng-container *ngIf="selected_group_type">
            <ng-container *ngFor="let level of filtered_groups | keyvalue; let i = index">
              <div *ngIf="filtered_groups[i] && filtered_groups[i].length < 1">
                No options available for your selection.
              </div>
              <div *ngIf="filtered_groups[i] && filtered_groups[i].length > 0" class="select-with-clear"
              >
                <select-search (selectChange)="filterGroups(i + 1);filterOreBodiesList($event.value)"
                               [(value)]="selected_group[i]"
                               [classes]="'fill'"
                               [data]="filtered_groups[i]"
                               [fill_stubs]="true"
                               [label]="'Please select a ' + group_select_label[i]"
                               style="width: 100%;"
                >
                </select-search>
                <button (click)="clearSelection(i)" *ngIf="i !==0"
                        [disabled]="!selected_group[i] || (filtered_groups[level + 1] && filtered_groups[level + 1].length > 0)"
                        matTooltip="Clear selection">
                  <i [class.disabled]="!selected_group[i] || (filtered_groups[level + 1] && filtered_groups[level + 1].length > 0)"
                     class="fas fa-minus-circle"></i>
                </button>
              </div>

            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="current_view==='tree'">
          <ng-container *ngIf="group_tree_data">

            <div class="filter-builder">

            </div>

            <div class="ore-body-tree-search">
              <div class="row filter">
                <label>Search by</label>
                <mat-select [(ngModel)]="group_search_by">
                  <mat-option [value]="'name'">Name</mat-option>
                  <mat-option [value]="'path'">Full path</mat-option>
                  <mat-option [value]="'type'">Type</mat-option>
                </mat-select>
              </div>
              <div class="row">
                <input (input)="searchGroups()" [(ngModel)]="group_search" class="process-search"
                       matTooltip="Please enter at least 3 characters" placeholder="search ore body groups..."/>
                <mat-icon (click)="group_search='';searchGroups()"
                          [ngClass]="{'clear-disabled': group_search.length < 1}"
                          class="process-search-clear" matTooltip="Clear search">clear
                </mat-icon>
              </div>
            </div>

            <mat-list class="ore-body-tree" style="overflow-y: visible;">

              <!--Search Results-->
              <ng-container *ngFor="let item of search_results">
                <ore-body-group-node (itemSelected)="levelSelected($event)"
                                     [group_tree_data]="group_tree_data"
                                     [item]="item"
                                     class="ore-body-group-node"></ore-body-group-node>
              </ng-container>

              <mat-divider *ngIf="search_results && search_results.length > 0" style="margin:10px;"></mat-divider>

              <ng-container *ngFor="let parent of parent_gts">
                <ore-body-group-node (itemSelected)="levelSelected($event)"
                                     *ngFor="let group of groups[parent.attributes.name]"
                                     [group_tree_data]="group_tree_data"
                                     [item]="group"
                                     class="ore-body-group-node"></ore-body-group-node>
              </ng-container>
            </mat-list>
          </ng-container>

        </ng-container>

        <mat-divider></mat-divider>
        <h3>Ore Bodies</h3>
        <div *ngIf="!filtered_ore_bodies_list">Loading Ore Bodies</div>
        <div *ngIf="filtered_ore_bodies_list && filtered_ore_bodies_list.length < 1">No Ore Bodies available for your
          selected filters.
        </div>

        <ng-container *ngIf="filtered_ore_bodies_list && filtered_ore_bodies_list.length > 0">
          <!--          <div *ngIf="ore_body_types" class="form-section column space-between" style="width:290px;margin-bottom:20px;">-->
          <!--            <mat-form-field>-->
          <!--              <label>Filter the Ore Body list by type</label>-->
          <!--              <mat-select (selectionChange)="changeFilter($event)" [(ngModel)]="selected_ore_body_type"-->
          <!--                          class="select-search">-->
          <!--                <mat-option value=""></mat-option>-->
          <!--                <mat-option *ngFor="let ore_body_type of ore_body_types"-->
          <!--                            [value]="ore_body_type">{{ore_body_type}}</mat-option>-->
          <!--              </mat-select>-->
          <!--            </mat-form-field>-->
          <!--          </div>-->

          <div><label style="font-weight:bold;">Select the Ore Bodies you would like to view</label></div>
          <div style="width:290px;position:relative;">
            <select-many-search (selectChange)="oreBodiesSelected()"
                                [data]="filtered_ore_bodies_list"
                                [(value)]="selected_ore_bodies"
                                [classes]="'fill'"
                                [fill_stubs]="true"
                                [data_schema]="fuse_search_attributes"
                                [stringFunction]="stringFunction">
            </select-many-search>
          </div>
        </ng-container>


      </as-split-area>

      <as-split-area #right_panel="asSplitArea" [size]="'*'" style="padding-right:36px;">
        <div class="svg-ore-bodies column" style="position:relative">
          <div class="row">
            <div (contextmenu)="contextMenu($event)" [ngStyle]="{'display':svg_hidden ? 'none' : 'block'}"
                 class="ore-body-geometry">
              <h3 style="display:flex;">
                <div style="width: 100%; text-align: center; font-size: 2em; font-weight:bold;">
                  <span *ngFor="let obg of selected_ore_body_groups">{{obg.attributes.name}} </span>
                </div>
              </h3>
              <div class="row">
                <div class="coords-y">
                  <div>{{axis_display[1][0] | number: '1.0-3'}}</div>
                  <div>y</div>
                  <div>{{axis_display[1][1] | number: '1.0-3'}}</div>
                </div>

                <svg (mousewheel)="scrollHandler($event)" [attr.height]="500"
                     [attr.viewBox]="(x_min) + ' ' + (y_min) + ' ' + ' ' + (scale_x) + ' ' + (scale_y)"
                     [attr.width]="600"
                     [style.transform]="'rotateX(' + rotate_x + 'deg) rotateY(' + rotate_y + 'deg)'"
                >
                  <g #draggable (cdkDragEnded)="onDragEnded($event)" cdkDrag
                     *ngIf="selected_ore_bodies && selected_ore_bodies.length > 0">
                    <defs>
                      <pattern height="4.5" id="diagonalHatchMissingColor" patternTransform="rotate(45)"
                               patternUnits="userSpaceOnUse"
                               width="4.5">
                        <line stroke="#AAAAAA" stroke-width="1" x1="0" x2="0" y="0" y2="4.5"/>
                      </pattern>
                    </defs>
                    <g *ngFor="let ob of selected_ore_bodies">

                      <pattern [id]="'diagonalHatch' + ob.id" height="4" patternUnits="userSpaceOnUse" width="4">
                        <path [ngStyle]="{'stroke': ob.attributes.colour}"
                              d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style="stroke-width:1"/>
                      </pattern>

                      <polygon (click)="selected_ore_body = ob;sendToBack(ob);"
                               [attr.fill]="!ob.attributes.name ? 'none': ob.actuals ? 'url(#diagonalHatch' + ob.id + ')':
                             (ob.attributes.colour ? ob.attributes.colour : 'url(#diagonalHatchMissingColor)')"
                               [attr.points]="getPoints(ob)"

                               [ngClass]="{'selected' : selected_ore_body === ob}"
                               vector-effect="non-scaling-stroke"></polygon>
                    </g>
                    <!-- Draw black rectangle aroudn all the polygons-->
                    <!-- <rect-->
                    <!--     [attr.height]="y_diff"-->
                    <!--     [attr.width]="x_diff"-->
                    <!--     [attr.x]="x_min"-->
                    <!--     [attr.y]="y_min"-->
                    <!--     style="stroke-width:3;stroke:rgb(0,0,0); fill:transparent"-->
                    <!-- >-->
                    <!-- </rect>-->
                  </g>


                </svg>
              </div>

              <!--    [attr.fill]="ob.actuals ? 'url(#diagonalHatch' + ob.id + ')': (ob.colour ? ob.colour : 'url(#diagonalHatchMissingColor)')"-->
              <div class="coords">
                <div>{{axis_display[0][0] | number: '1.0-3'}}</div>
                <div>x</div>
                <div>{{axis_display[0][1] | number: '1.0-3'}}</div>
              </div>
              <div *ngIf="nonGeoOreBodies.length > 0" class="non-geo-ore-bodies">

                <svg [attr.height]="110" [attr.width]="nonGeoOreBodies[nonGeoOreBodies.length - 1].x + 90"
                     style="border:none;"
                >
                  <g *ngFor="let ob_model of nonGeoOreBodies" class="ore-body-group-icon">
                    <image
                        [attr.height]="76"
                        [attr.width]="76"
                        [attr.x]="ob_model.x"
                        [attr.xlink:href]="ob_model.src"
                        [attr.y]="ob_model.y"
                    ></image>

                    <text [attr.width]="80"
                          [attr.x]="ob_model.x + 38"
                          [attr.y]="ob_model.y + 76"
                          class="ore-body-group-name"
                          text-anchor="middle"
                    >{{ob_model.name}}
                    </text>
                    <text [attr.width]="80"
                          [attr.x]="ob_model.x + 38"
                          [attr.y]="100"
                          class="ore-body-title"
                          text-anchor="middle"
                    >{{ob_model.title}}
                    </text>

                    <rect
                        (click)="selected_ore_body = ob_model.ore_body"
                        [attr.height]="76"
                        [attr.width]="76"
                        [attr.x]="ob_model.x"
                        [attr.y]="ob_model.y + 10"
                        fill="transparent"
                        rx="8"
                    >
                    </rect>
                  </g>
                </svg>
              </div>
              <div class="coords zoom-box">
                <div>Zoom: {{600 / scale_x | percentage: '%':true:2}}%</div>
                <div>Pan x: {{drag_end ? drag_end.distance.x : 0  | significantNumbers: true: 1}}
                  y: {{drag_end ? drag_end.distance.y : 0  | significantNumbers: true: 1}}
                </div>

                <div>
                  <button (click)="resetSVG()"
                          class="reset-button" mat-button matTooltip="Resize and centre" matTooltipPosition="above">
                    Reset
                  </button>
                </div>
              </div>
              <div class="coords zoom-box">
                <div>X : {{rotate_x}}, Y : {{rotate_y}}, Z: {{rotate_z_count % 4 * 90}}</div>
                <button (click)="rotateX()"
                        class="reset-button" mat-button matTooltip="Flip image along the x-axis"
                        matTooltipPosition="above">
                  Invert X
                </button>
                <button (click)="rotateY()"
                        class="reset-button" mat-button matTooltip="Flip image along the y-axis"
                        matTooltipPosition="above">
                  Invert Y
                </button>
                <button (click)="rotateZ()"
                        class="reset-button" mat-button matTooltip="Rotate image around the z-axis"
                        matTooltipPosition="above">
                  Rotate Z
                </button>

              </div>

            </div>

            <button (click)="svg_hidden=!svg_hidden" *ngIf="selected_ore_body || svg_hidden" class="hide-left">
              <i [ngClass]="svg_hidden ? 'fa-caret-right':'fa-caret-left'" class="fa"></i>
            </button>

            <div *ngIf="selected_ore_body" class="ore-body-data" style="overflow-x:auto;">
              <h3 style="font-size: 2em; font-weight: bold;">
                <!-- TODO convert this to a type -->
                <div
                    style="text-align: center;width: 100%;">{{stringFunction(selected_ore_body)}}</div>
              </h3>
              <ore-body-data-table [ore_body]="selected_ore_body"></ore-body-data-table>
            </div>

          </div>

          <div *ngIf="selected_ore_body" class="ore-body-data">
            <h3 style="font-size: 2em; font-weight: bold;justify-content:center;margin-top:20px; border-top: 1px solid #ccc;padding-top: 20px;">
              Data Summary
            </h3>
            <ore-body-summary-table [ore_body]="selected_ore_body"></ore-body-summary-table>
          </div>
        </div>
      </as-split-area>
    </as-split>
    <div>
    </div>

  </div>

</div>

<div #context_menu [ngStyle]="{'display':menuVisible? 'block':'none'}" class="menu flowchart-context-menu">
  <ul class="menu-options">
    <li (click)="backToFlowchart()" class="menu-option">Back to Flowchart</li>
  </ul>
</div>
