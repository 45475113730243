import {Component, Input} from '@angular/core';
import {Subject} from "rxjs";
import {ConfigStub} from "../../_typing/config-stub";
import {Process} from "../../_models/process";
import {Series} from "../../_models/series";

@Component({
    selector: 'model-pivot-form',
    templateUrl: './model-pivot-form.component.html',
    styleUrls: ['./model-pivot-form.component.css'],
    standalone: false
})
export class ModelPivotFormComponent {
    hint: string;
    model_types = ['series', 'process']
    private readonly onDestroy = new Subject<void>();

    constructor() {
    }

    private _config: {
        series_list: any[],
        component_list: any[],
        model_type: string,
        pivot_state: {},
    };

    get config(): { series_list: any[], component_list: any[], model_type: string, pivot_state: any } {
        return this._config
    }

    @Input()
    set config(config: { series_list: any[], component_list: any[], model_type: string, pivot_state: any }) {
        this._config = config;
    }

    processChange(event: Process[]) {
        if (!event) return;
        this.config.component_list = ConfigStub.getConfigStubs(event);
    }

    seriesChange(event: Series[]) {
        if (!event) return;
        this.config.series_list = ConfigStub.getConfigStubs(event);
    }

}
