import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    standalone: false
})
export class NotificationsComponent implements OnInit {
    i_class = 'fa-minus';
    show_min = false;
    tooltip = 'Minimise this notification';
    nt_production_max = "Please note that this is not a production site and is not meant for production usage. \n" +
        "This site is meant for quality assurance and testing purposes. Please do not log information here that is needed for production.";
    nt_production_min = "This is not a production site.";
    nt_production = this.nt_production_max;
    closed = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    close(){
        this.closed = true;
    }

    toggleMinMax() {
        this.show_min = !this.show_min;
        this.nt_production = this.show_min ? this.nt_production_min : this.nt_production_max;
        this.i_class = this.show_min ? 'fa-plus' : 'fa-minus';
    }
}
