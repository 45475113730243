export type VALID_ISSUE_TYPE = 'Bug' | 'Feature';
export const ISSUE_TYPES: VALID_ISSUE_TYPE[] = ['Bug', 'Feature'];

export class ReportIssue {
    issue_title: string = '';
    issue_description: string = '';
    issue_type: VALID_ISSUE_TYPE = "Bug";

    page_link: string;
    page_title: string;
    active_account: string;
    time_start: string;
    time_end: string;
}
