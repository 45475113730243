import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ComponentType} from '../../_models/component-type';
import {EventType} from '../../_models/event-type';
import {ConstantProperty} from '../../_models/constant-property';
import {Subject} from 'rxjs';
import {ApiService} from '../../services/api/api.service';
import {first, takeUntil, tap} from 'rxjs/operators';
import {
    PENDING_COMPONENT_COMPONENT_CONTEXT,
    PENDING_CONTEXT_TYPES,
    PENDING_EVENT_COMPONENT_CONTEXT
} from '../../_models/pending-context';
import {CustomEventsService} from '../../services/custom-events.service';
import {ListResponse} from '../../services/api/response-types';
import {IDMap, ModelID} from '../../_typing/generic-types';
import {CustomFormFieldConfig} from "../custom-form-field/custom-form-field.component";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {WireComponent} from '../../_models/component';
import {WireEvent} from '../../_models/event';

export interface PendingContextValidationOptions extends CustomFormFieldConfig {
    max_length?: number;
    min_length?: number;
    only_text?: boolean;
    only_numeric?: boolean;
    must_contain?: string[];
}

export interface IPendingContextConfig {
    type: PENDING_CONTEXT_TYPES;
    context: PENDING_COMPONENT_COMPONENT_CONTEXT & PENDING_EVENT_COMPONENT_CONTEXT;
    customisation: PendingContextRelationshipConfig;
    validation: PendingContextRelationshipConfig;
    component?: WireComponent;
    event?: WireEvent;
}

export class PendingContextEventConfig implements IPendingContextConfig {
    type: PENDING_CONTEXT_TYPES = 'event_to_component';
    context: PENDING_COMPONENT_COMPONENT_CONTEXT & PENDING_EVENT_COMPONENT_CONTEXT = {
        component_type: {id: null, value: null},
        event_type: {
            id: null, value: null,
            constant_properties: [],
            unique_constant_property: null
        }
    };
    customisation: PendingContextRelationshipConfig = {
        component_type: {},
        constant_properties: {}
    };
    validation: PendingContextRelationshipConfig = {
        component_type: {},
        constant_properties: {}
    };
    component?: WireComponent;
    event?: WireEvent;

    constructor() {
    }
}

export class PendingContextComponentConfig implements IPendingContextConfig {
    type: PENDING_CONTEXT_TYPES = 'component_to_component';
    context: PENDING_COMPONENT_COMPONENT_CONTEXT & PENDING_EVENT_COMPONENT_CONTEXT = {
        component_type_from: {id: null, value: null},
        component_type_to: {id: null, value: null},
    };
    customisation: PendingContextRelationshipConfig = {
        component_type_from: {},
        component_type_to: {}
    };
    validation: PendingContextRelationshipConfig = {
        component_type_from: {},
        component_type_to: {}
    };
    component?: WireComponent;

    constructor() {
    }
}

export class PendingContextRelationshipConfig {
    component_type?:
        { [id: string]: PendingContextValidationOptions };
    component_type_from?:
        { [id: string]: PendingContextValidationOptions };
    component_type_to?:
        { [id: string]: PendingContextValidationOptions };
    constant_properties?:
        { [id: string]: PendingContextValidationOptions };

    public static getConfigRelationshipKeys(keys: string[]): PendingContextRelationshipConfig {
        const conf = {};
        keys.forEach(key => conf[key] = {})
        return conf;
    }
}

@Component({
    selector: 'pending-context-form',
    templateUrl: './pending-context-form.component.html',
    styleUrls: ['./pending-context-form.component.less'],
    standalone: false
})
export class PendingContextFormComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    component_types: ComponentType[];
    event_types: EventType[];
    constant_properties: ConstantProperty[];
    cp_dict: IDMap<ConstantProperty> = {};
    ct_dict: IDMap<ComponentType> = {};

    // Api filter fo the selected event type
    event_type_constant_property_filters: any[] = [];

    @Input() config: IPendingContextConfig;
    @Input() static = false;

    link_types = {
        'event_to_component': 'Event To Component',
        'component_to_component': 'Component To Component'
    };

    constructor(private api: ApiService,
                public eventsService: CustomEventsService,
                private componentTypeDataService: ComponentTypeDataService,
                private propertyDataService: ConstantPropertyDataService) {
    }

    ngOnInit(): void {
        console.log('PendingContextFormComponent - ngOnInit: ', this.config);
        const ctrl = this;
        // As 'usual' initial config is set by TileFormComponent contentChange function (uses event_to_component config as default);
        const ct_ids = this.getComponentTypeIdsFromConfig();
        this.checkConfig(ct_ids);
        this.componentTypeDataService.getComponentTypes(ct_ids).pipe(first(), takeUntil(this.onDestroy),
            tap(result => {
                this.component_types = result.data;
                this.component_types.forEach(ct => this.ct_dict[ct.id] = ct);
            }))
            .subscribe();


        //Get initial properties
        if (this.config.type === 'event_to_component' && this.config.context?.component_type?.id) {
            const cp_ids = this.config.context.event_type.constant_properties.map(cp => cp.id);
            this.propertyDataService.getConstantProperties(cp_ids).pipe(first(), takeUntil(this.onDestroy),
                tap((result: ListResponse<ConstantProperty>) => {
                    this.constant_properties = result.data;
                    this.constant_properties.forEach(cp => this.cp_dict[cp.id] = cp);
                    this.propertiesSelected(this.constant_properties);
                })).subscribe();
            this.getEventTypes();
        }
    }

    checkConfig(ct_ids: ModelID[]) {
        if (!ct_ids[0]) return;
        if (!this.config.customisation) {
            if (this.config.type === 'event_to_component') {
                this.config.customisation = PendingContextRelationshipConfig.getConfigRelationshipKeys(['component_type', 'constant_properties']);
                this.setCustomFieldConfig('customisation', 'component_type', ct_ids[0]);
            } else {
                this.config.customisation = PendingContextRelationshipConfig.getConfigRelationshipKeys(['component_type_from', 'component_type_to']);
                ['component_type_from', 'component_type_to'].forEach(key => {
                    this.setCustomFieldConfig('customisation', key, this.config.context[key].id);
                })
            }
        }
    }

    getComponentTypeIdsFromConfig(): ModelID[] {
        if (this.config.type === 'event_to_component') {
            return [this.config.context.component_type.id]
        } else {
            return [this.config.context.component_type_from.id, this.config.context.component_type_to.id]
        }
    }

    linkTypeSelected() {
        // Reset config
        if (this.config.type === 'event_to_component') {
            this.config.context = {
                component_type: {id: null, value: null},
                event_type: {
                    id: null,
                    constant_properties: [],
                    unique_constant_property: null
                }
            };
            this.config.validation = PendingContextRelationshipConfig.getConfigRelationshipKeys(['component_type', 'constant_properties'])
            this.config.customisation = PendingContextRelationshipConfig.getConfigRelationshipKeys(['component_type', 'constant_properties'])

        } else {
            this.config.context = {
                component_type_from: {id: null, value: null},
                component_type_to: {id: null, value: null}
            };
            this.config.validation = PendingContextRelationshipConfig.getConfigRelationshipKeys(['component_type_from', 'component_type_to'])
            this.config.customisation = PendingContextRelationshipConfig.getConfigRelationshipKeys(['component_type_from', 'component_type_to'])
        }
    }

    componentTypeSelected(event, key) {
        this.ct_dict[event.value.id] = event.value;
        this.config.context[key].id = event.value.id;
        this.config.context[key].value = null;
        this.setCustomFieldConfig('validation', key, event.value.id);
        this.setCustomFieldConfig('customisation', key, event.value.id);
        if (this.config.type === 'event_to_component') {
            this.getEventTypes();
        }
    }

    eventTypeSelected($event) {
        this.config.context.event_type.id = $event.value.id;
    }

    getEventTypeConstantPropertyFilter() {
        if (!this.config.context?.event_type?.id) return [];
        return [{name: 'event_types', op: 'any', val: {name: 'id', op: 'eq', val: this.config.context.event_type.id}}]
    }

    propertiesSelected(event) {
        this.config.context.event_type.constant_properties = event.map(cp => {
            ['validation', 'customisation'].forEach(key => {
                if (!this.config[key].constant_properties[cp.id]) {
                    this.config[key].constant_properties[cp.id] = {};
                }
                if (!this.cp_dict[cp.id]) {
                    this.cp_dict[cp.id] = cp;
                }
            })
            return {id: cp.id, value: null};
        });
        // Remove unselected prop validation objects (keep the json cleaner)
        ['validation', 'customisation'].forEach(key => {
            const arr = Object.keys(this.config[key].constant_properties);
            arr.forEach(id => {
                if (!event.map(cp => cp.id).includes(id)) {
                    delete this.config[key].constant_properties[id];
                }
            });
        })
    }

    getEventTypes() {
        this.event_types = null;
        this.eventsService.getComponentTypeEventTypes([this.config.context.component_type.id])
            .pipe(first(), takeUntil(this.onDestroy), tap((result: ListResponse<EventType>) => {
                this.event_types = result.data;
            })).subscribe();
    }

    setCustomFieldConfig(parent_key: 'validation' | 'customisation', key, id) {
        if (!this.config[parent_key][key]) {
            this.config[parent_key][key] = {};
        }
        if (!this.config[parent_key][key][id]) {
            this.config[parent_key][key][id] = {};
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
