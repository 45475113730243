import {Injectable, OnDestroy} from "@angular/core";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {ApiService} from "../services/api/api.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {map, take, takeUntil} from "rxjs/operators";
import {ListResponse} from "../services/api/response-types";
import {ModelID} from '../_typing/generic-types';
import {
    getManyRelationWithIdFilter,
    getRelationWithIdFilter
} from "../services/api/filter_utils";
import {Printout} from "../_models/utils/printout";
import {PrintoutComponentType} from "../_models/printout-component-type";

/**
 * This class is for shared functions relating to data access on the printout api
 **/
@Injectable({
    providedIn: 'root'
})
export class PrintoutDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService) {
    }

    getPrintouts(componentTypeId?: ModelID): Observable<Printout[]> {
        let $printoutComponentType: Observable<ListResponse<PrintoutComponentType>> = of(null);

        const options = new SearchQueryOptions();
        options.filters = [];
        const PCTOptions = new SearchQueryOptions();
        if (componentTypeId) {
            options.filters.push(getManyRelationWithIdFilter('component_type', componentTypeId));
            PCTOptions.filters = [getRelationWithIdFilter('component_type', componentTypeId)];
            $printoutComponentType = this.api.printout_component_type.searchMany(PCTOptions);
        }
        const $legacy = this.api.printout.searchMany()
        const $printouts = this.api.printout_v2.searchMany(options);
        return forkJoin([$legacy, $printouts, $printoutComponentType])
            .pipe(
                map(([legacy, printouts, printoutComponentTypes]) => {
                    if (componentTypeId) {
                        const legacyIds: string[] = printoutComponentTypes?.data?.filter(pct => pct.attributes.printout_id)
                            .map(pct => pct.attributes.printout_id);
                        legacy.data = legacy.data?.filter(legacy_printout => {
                            return legacyIds.includes(legacy_printout.id);
                        })
                    }
                    return (printouts.data || []).concat(legacy.data || []);
                }),
                take(1),
                takeUntil(this.onDestroy)
            )
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
