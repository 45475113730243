import {Observable} from "rxjs";
import {CacheEntry} from "./cacheEntry";

export class CacheManager {

    cache: { [key: string]: CacheEntry } = {};

    // TODO timeout per cache entry
    constructor() {
    }

    getCacheEntry(cacheName: string): Observable<any> {
        const now = performance.now();
        const entry = this.cache[cacheName];
        if (entry) {
            if (entry.lastUpdated > 0 && now - entry.lastUpdated <= entry.timeout) {
                return entry.response;
            } else {
                // TODO cleanup? cannot unsubscribe since publish last is used
                return null;
            }
        } else {
            return null;
        }
    }

    setCacheEntry(cacheName: string, response: Observable<any>, timeout: number): void {
        const now = performance.now();
        this.cache[cacheName] = {response: response, lastUpdated: now, timeout: timeout};
    }

    /**
     * Clears a specified cache
     * @param cacheName
     */
    invalidateCache(cacheName: string) {
        // TODO add relations map to recursively clear caches of relationship as well
        const entry = this.cache[cacheName];
        if (entry) {
            entry.lastUpdated = -1;
            entry.response = null;
        }
    }

    /**
     * Clears all caches
     */
    clear() {
        this.cache = {}
    }
}
