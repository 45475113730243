<backdrop-overlay *ngIf="(appScope.noticeBoardOpen$ | async)" [hidePage]="accepted"></backdrop-overlay>
<mat-card *ngIf="(appScope.noticeBoardOpen$ | async)" class="notice-board-card">
    <ng-container *ngIf="!accepted;else hasAccepted">
        <mat-card-title><h2 class="card-title">Attention WIRE user</h2></mat-card-title>
        <mat-card-content>
            <p>
                We're excited to announce that we are enhancing WIRE's authentication system to bolster the security of user accounts,
                introduce new features, and streamline the login experience on the WIRE platform.
            </p>
            <p *ngIf="appScope.isAuthenticated$ | async;else forgotPasswordUser">
                To take advantage of these improvements and to continue accessing WIRE, please update your login credentials.
            </p>
            <ng-template #forgotPasswordUser>
                <p>
                    You will be required to update your login credentials.
                </p>
            </ng-template>
            <h4 class="card-title">To update your credentials, please follow these steps:</h4>
            <ol>
                <li>Click the "{{ action }}" button below</li>
                <li>Check your inbox for a "Reset Password" email from {{noreplyEmail}}</li>
                <li>Follow the instructions in the email to set a new password</li>
                <li>Once your new password is set, you will be prompted to set up your OTP</li>
            </ol>
            <p *ngIf="(appScope.isAuthenticated$ | async)">
                You have <strong>{{formatTime()}}</strong> remaining to complete these steps.
                If not done by <strong>{{targetDate | date: 'dd MMMM yyyy'}}</strong>, you will be logged out and prompted to reset your password and OTP.
                You'll receive this reminder again in <strong>{{nextReminder}} hours</strong>.
            </p>
            <p>Thank you for your cooperation.</p>
            <p>For any assistance, please reach out to us at
                <a class="link" href="mailto:{{serviceDeskEmail}}">{{serviceDeskEmail}}</a>
            </p>
        </mat-card-content>
        <mat-card-actions class="action-container" >
            <wire-button class="fullwidth" [btnContent]="action"
                         [btnStyle]="(appScope.isAuthenticated$ | async) ? 'btn-default' : 'btn-default-full'"
                         (buttonClicked)="onAccept()">
            </wire-button>
            <wire-button *ngIf="appScope.isAuthenticated$ | async" [btnContent]="'Not now'" [btnStyle]="'btn-default'"
                         (buttonClicked)="onCancel()">
            </wire-button>
        </mat-card-actions>
    </ng-container>
    <ng-template #hasAccepted>
        <mat-card-title><h2 class="card-title no-padding-top">You will be logged out in {{timerDisplay}} seconds</h2></mat-card-title>
        <p>Next step: <strong><span class="link">check your inbox for a "Reset Password" email from {{noreplyEmail}}</span></strong>,
            to update your credentials and regain WIRE access.</p>
        <p>If you do not receive the "Reset Password" email, please click on the "Forgot Password" button once you have been logged out.</p>
    </ng-template>
</mat-card>
