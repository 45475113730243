import {Feature} from "../../_models/feature";
import {Process} from "../../_models/process";
import {ProcessAccess} from "../../_models/process-access";
import {Observable} from "rxjs";
import {Subject} from "rxjs/index";

export class ProcessAccessFeatureConfig {
    all_processes_list: Process[] = [];
    feature: Feature;
    process_access: ProcessAccess[] = [];
    changes: Subject<Observable<any>[]>;
}
