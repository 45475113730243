<div class="constant-property-table-form form-paginator">
  <div class="row center" *ngIf="!hide_select_list">
    <select-many-search-component
        [value]="selected_properties"
        (selectionChange)="selected_properties=$event;"
        [classes]="'fill'"
        [custom_selected_filters]="selected_properties_filter"
        [custom_filters]="selected_properties_reverse_filter"
        [api_model]="'constant_property_light'"
        [label]="'Constant Properties'"
        class="select-search">
    </select-many-search-component>

    <button (click)="newConstantProperty()" class="form-element-align" mat-raised-button color="primary"
    ><i class="fa fa-plus" style="margin-right:10px;"></i> Constant Property
    </button>
  </div>

  <div class="constant-property-table-container">
    <div class="row space-between utils-bar">
      <utils-bar [search]="true"
                 (searchValueSubmitted)="filter_string=$event;updateSearchFilter()"
                 (searchCleared)="filter_string='';updateSearchFilter();">
      </utils-bar>
      <mat-paginator [pageSizeOptions]="[5,10,15,20]"
                     style="justify-self: flex-end;">
      </mat-paginator>
    </div>
    <div class="table-container">
      <table class="table constant-properties-table" id="constant-properties-table"
             mat-table matSort multiTemplateDataRows (matSortChange)="updateSort($event)"
             [dataSource]="dataSource">

        <ng-container *ngFor="let column of columns;">

          <ng-container *ngIf="column==='edit'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let constant_property" class="icon-cell" mat-cell>
              <audit-mixin-indicator [model]="constant_property"></audit-mixin-indicator>
              <i (click)="editConstantProperty(constant_property)"
                 *ngIf="!constant_property.id?.includes('temp') && !cp_editing_dict[constant_property.id]"
                 class="fa fa-edit hideonprint"></i>
              <i (click)="removeConstantProperty(constant_property)" *ngIf="constant_property.id?.includes('temp')"
                 class="fa fa-times hideonprint"></i>
              <i (click)="saveConstantProperty(constant_property)" *ngIf="cp_editing_dict[constant_property.id]"
                 class="fa fa-save hideonprint"></i>
            </td>
          </ng-container>

          <ng-container *ngIf="['name', 'description', 'alias'].includes(column)" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell>
              <input [(ngModel)]="constant_property.attributes[column]"
                     [disabled]="!cp_editing_dict[constant_property.id]"
                     [matTooltip]="constant_property.attributes.name"
                     [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY"
                     style="text-align: left;"/>
            </td>
          </ng-container>

          <ng-container *ngIf="column==='formula'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell>
              <div style="display:flex;justify-content:space-between;align-items:center;">
                <div>{{constant_property.attributes.name_formula}}</div>
                <i (click)="expandedElement = expandedElement?.id === constant_property.id ? null : constant_property;"
                   *ngIf="cp_editing_dict[constant_property.id] && ['event_type','component_type'].includes(parent_component.type) &&
                    constant_property.attributes.is_calculation"
                   class="fas fa-plus-square hideonprint"
                   style="margin-right: 4px;background:white;padding-left: 2px;"></i>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="column==='is_drop_down_list'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell style="text-align: center;">
              <mat-checkbox [(ngModel)]="constant_property.attributes.is_drop_down_list"
                            [disabled]="!cp_editing_dict[constant_property.id]"
                            aria-label="Is Dropdown List"
                            style="width:20px;"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container *ngIf="column==='data_type'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell>
              <mat-form-field class="show-select-arrow no-label">
                <mat-select [(ngModel)]="constant_property.attributes.data_type"
                            [disabled]="!cp_editing_dict[constant_property.id]"
                            name="data_type" class="show-select-arrow"
                            aria-label="Data Type" style="width:100%">
                  <mat-option *ngFor="let data_type of constant_property_types"
                              [value]="data_type">{{data_type}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </ng-container>

          <ng-container *ngIf="column==='is_calculation'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell style="text-align: center;">
              <mat-checkbox [(ngModel)]="constant_property.attributes.is_calculation"
                            [disabled]="!cp_editing_dict[constant_property.id]"
                            aria-label="Is Calculation"
                            style="width:20px;"></mat-checkbox>
          </ng-container>

          <ng-container *ngIf="column==='aggregation'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell>
              <mat-form-field class="show-select-arrow no-label">
                <mat-select [(ngModel)]="constant_property.attributes.aggregation"
                            [disabled]="!cp_editing_dict[constant_property.id]"
                            aria-label="Aggregation Type" class="show-select-arrow"
                            name="aggregation_type" style="width:100%;">
                  <mat-option *ngFor="let aggregation of constant_property_aggregations"
                              [value]="aggregation">{{aggregation}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </ng-container>

          <ng-container *ngIf="column==='json'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let constant_property" mat-cell>
            <textarea [(ngModel)]="constant_property.attributes.json"
                      [disabled]="!cp_editing_dict[constant_property.id]"
                      [rows]="cp_editing_dict[constant_property.id]? 3 : 1" style="text-align: left;min-width:120px;">
            </textarea>
            </td>
          </ng-container>

        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td *matCellDef="let constant_property" [attr.colspan]="columns.length" mat-cell class="formula-builder-cell">
            <div [@detailExpand]="constant_property.id == expandedElement?.id ? 'expanded' : 'collapsed'"
                 class="formula-detail">
              <formula-builder
                  *ngIf="expandedElement && expandedElement.id === constant_property.id && selected_properties"
                  [selected_properties]="selected_properties"
                  [constant_property]="constant_property"
                  [parent_component]="parent_component"></formula-builder>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns;" mat-header-row></tr>

        <tr *matRowDef="let element; columns: columns;"
            [ngClass]="{'disabled':!cp_editing_dict[element.id],'new-row':element.id?.includes('temp')}"
            [class.component-expanded-row]="expandedElement && expandedElement.id === element.id"
            mat-row
            style="cursor:pointer;">
        </tr>

        <tr *matRowDef="let row; columns: ['expandedDetail'];"
            [class.detail-not-expanded]="!expandedElement || expandedElement.id !== row.id " class="event-detail-row"
            mat-row></tr>
      </table>
    </div>
  </div>
</div>
