<table [dataSource]="dataSource"
       class="mat-elevation-z8 ore-body-data-table" mat-table
       multiTemplateDataRows>
  <ng-container *ngFor="let column of columnsToDisplay" matColumnDef="{{column}}">
    <th *matHeaderCellDef mat-header-cell style="min-width:100px;"> {{columnLookupKeys[column] | clientTranslator}}</th>
    <td *matCellDef="let element" mat-cell style="padding-top:16px;">
      <ng-container *ngIf="column=='name'">{{element[column] }}</ng-container>
      <ng-container
          *ngIf="column!='name' && element['name']!='Color'">{{element[column]| percentage:element[column]:true }}</ng-container>
      <ng-container *ngIf="column=='initial' && element['name']=='Color'">
        <div [style.background]="element[column]" class="colour-block"></div>
      </ng-container>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" mat-cell>
      <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
           class="resource-detail">
        <div class="example-element-diagram">
          <div class="example-element-position"> {{element.position}} </div>
          <div class="example-element-symbol"> {{element.symbol}} </div>
          <div class="example-element-name"> {{element.name}} </div>
          <div class="example-element-weight"> {{element.weight}} </div>
        </div>
        <div class="example-element-description">
          {{element.description}}
          <span class="example-element-description-attribution"> </span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
  <tr *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      mat-row>
    <!--    <tr (click)="expandedElement = expandedElement === element ? null : element"-->
    <!--      *matRowDef="let element; columns: columnsToDisplay;"-->
    <!--      [class.example-expanded-row]="expandedElement === element"-->
    <!--      class="example-element-row"-->
    <!--      mat-row>-->
  </tr>
  <tr *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" mat-row></tr>
</table>
