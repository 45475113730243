import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AppScope} from "../services/app_scope.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
    selector: 'colour-palette',
    templateUrl: 'colour_palette.html',
    styleUrls: ['colour_palette.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ColourPaletteComponent implements OnInit {
    private palette1 = [];
    private palette2 = [];
    paletteColours = [];
    palette_dict = {};
    preset_colours: string[];

    @Input() model: any;
    @Input() show_text: boolean;
    @Input() palette: any;
    @Input() use_picker = false;
    @Input() label: string;

    emitSelect(newSelection: MatSelectChange) {
        this.valueChange.emit(newSelection.value);
        this.selectChange.emit(newSelection);
    }

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    selectChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

    @Input() value: any;


    constructor(private appScope: AppScope) {

    }

    ngOnInit() {
        const ctrl = this;
        ctrl.appScope.auth_complete.promise.then(() => {
            if (ctrl.appScope.config_name_map.hasOwnProperty("palette1")) {
                ctrl.palette1 = ctrl.appScope.config_name_map.palette1.value;
            } else {
                ctrl.palette1 = [{"colour": "#000", "name": "Black"}, {
                    "colour": "#333", "name": "Dark Gray"
                }, {"colour": "#ff5722", "name": "Orange"}, {"colour": "#4286f4", "name": "Blue"}, {
                    "colour": "#54bf7d", "name": "Green"
                }];
            }
            this.palette1.forEach(p => this.palette_dict[p.colour] = p.name);

            if (ctrl.appScope.config_name_map.hasOwnProperty("palette2")) {
                ctrl.palette2 = ctrl.appScope.config_name_map.palette2.value;
            } else if (ctrl.appScope.config_name_map.hasOwnProperty("pallette2")) {
                ctrl.palette2 = ctrl.appScope.config_name_map.pallette2.value;
            } else {
                ctrl.palette2 = [
                    {"colour": "teal", "name": "Teal"},
                    {"colour": "dodgerblue", "name": "Dodger Blue"},
                    {"colour": "darkslateblue", "name": "Dark Slate Blue"},
                    {"colour": "darkturquoise", "name": "Dark Turquoise"},
                    {"colour": "orangered", "name": "Orange Red"},
                    {"colour": "darkorange", "name": "Dark Orange"},
                    {"colour": "orange", "name": "Orange"},
                    {"colour": "yellow", "name": "Yellow"},
                    {"colour": "green", "name": "Green"},
                    {"colour": "olive", "name": "Olive"},
                    {"colour": "black", "name": "Black"}
                ];
            }
            this.palette2.forEach(p => this.palette_dict[p.colour] = p.name);

            if (this.palette === 1) {
                this.paletteColours = this.palette1;
                this.preset_colours = this.palette1.map(colour => colour.colour);
            } else {
                this.paletteColours = this.palette2;
                this.preset_colours = this.palette2.map(colour => colour.colour);
            }
        });
    }
}
