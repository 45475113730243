<div class="custom-events-form">
  <div class="row full-height">
    <select-many-event-types *ngIf="(content_type==='custom-events' || content_type==='custom-events-table')"
                             (selectionChange)="updateSelected($event);"
                             [value]="selected_types"
                             class="select-many-search-wrapper">
    </select-many-event-types>
    <select-many-event-types *ngIf="content_type==='ore-body-events'"
                             (selectionChange)="updateSelected($event);"
                             [value]="selected_types"
                             class="select-many-search-wrapper">
    </select-many-event-types>
  </div>
  <div class="row center">
    <mat-checkbox [(ngModel)]="config.allow_delete"
                  matTooltip="Allow deletion of events on this table"
                  name="allow_delete"
                  style="margin-top:20px; margin-bottom: 20px;max-width: 360px;">Allow delete
    </mat-checkbox>
    <ng-container *ngIf="content_type==='custom-events-table'">
      <mat-checkbox [(ngModel)]="config.bypass_limits_formatting"
                    matTooltip="Bypass conditional formatting when constants values are out of limits"
                    name="bypass_formatting"
                    style="margin-top:20px; margin-bottom: 20px;max-width: 360px;">Disable auto conditional formatting
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.show_calc_update_status"
                    matTooltip="Show calculation queue status bell icon"
                    name="show_status"
                    style="margin-top:20px; margin-bottom: 20px;max-width: 360px;">Show calculation queue status
      </mat-checkbox>
      <mat-checkbox [disabled]="!date_properties || date_properties.length < 1"
                    [(ngModel)]="config.constant_property_time"
                    (ngModelChange)="resetConstantPropertyTime($event)"
                    name="Filter Component by Selected Constant Property Time"
                    style="margin-top:20px; margin-bottom: 20px;max-width: 360px;">Use Constant Property Time
      </mat-checkbox>
      <mat-checkbox [disabled]="!config.constant_property_time"
                    [(ngModel)]="config.include_cp_nulls"
                    name="Include null values for selected Constant Property Time filter"
                    style="margin-top:20px; margin-bottom: 20px;max-width: 360px;">Include null values
      </mat-checkbox>
    </ng-container>
  </div>

  <ng-container *ngIf="content_type==='custom-events-table'">
    <div class="row full-height justify-center">
      <mat-form-field style="margin-top:3px;">
        <mat-label>Select a start date property</mat-label>
        <mat-select [(ngModel)]="config.start_prop"
                    [disabled]="!date_properties || date_properties.length < 1 || !config.constant_property_time"
        >
          <mat-option [value]=""><i>None</i></mat-option>
          <mat-option *ngFor="let prop of date_properties"
                      [value]="prop.id">{{prop.attributes.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="margin-top:3px;">
        <mat-label>Select an end date property</mat-label>
        <mat-select [(ngModel)]="config.end_prop"
                    [disabled]="!date_properties || date_properties.length < 1 || !config.constant_property_time"
        >
          <mat-option [value]=""><i>None</i></mat-option>
          <mat-option *ngFor="let prop of date_properties"
                      [value]="prop.id">{{prop.attributes.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <!-- Download options -->
  <div class="row full-height">
    <mat-form-field *ngIf="content_type==='custom-events-table'">
      <mat-label>Download file type</mat-label>
      <mat-select [(ngModel)]="config.download_file_type">
        <mat-option *ngFor="let option of event_file_download_options" [value]="option.value">
          {{option.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <select-search-component matTooltip="Select the template used to lockdown event data"
                             (selectionChange)="lockTemplateChanged($event)"
                             [api_model]="'lock_template'"
                             [custom_filters]="lock_template_filter"
                             [value]="config.lock_template"
                             [classes]="'fill'"
                             class="select-search"
                             label="Select Lock Template">
    </select-search-component>
  </div>

  <div class="row full-height">
    <mat-form-field style="margin-top:2px;">
      <mat-label>Default number of rows</mat-label>
      <input matInput [(ngModel)]="config.page_size" placeholder="Default number of rows to fetch"
             autocomplete="false" type="number" step="1" min="10" max="500" style="box-sizing: border-box;">
    </mat-form-field>

  </div>

  <h3 style="font-size:1.33rem;">Custom events columns
    <mat-checkbox *ngIf="content_type==='custom-events-table'" [(ngModel)]="config.constrain_width" style="width:500px;"
                  matTooltip="Table will be constrained to 100% of the width of the tile.
                  Columns can only be resized within this width (up to a point), but 'fit_content' will override this setting where applicable."
    >Constrain content to tile width
    </mat-checkbox>
  </h3>

  <ng-container *ngIf="config && col_id_name_map">

    <event-column-selector
        [available_columns]="available_column_options"
        [selected_columns]="config.columns || []"
        [model_dict]="col_info_dict"
        [colMenuOptions]="content_type==='custom-events-table'? undefined:[]"
        (columns_changed)="config.columns=$event.selected_columns;columnsChanged()"
    ></event-column-selector>

    <div class="row" style="margin-top:20px;">
      <select-many-filter
          [label]="'Required columns'"
          (constant_changed)="updateRequired($event)"
          [modelValue]="required_columns"
          [options]="config.columns"
          [placeholder]="'Please select disabled columns'"
          [stringFunction]="columnNameFunction"
          [disableItemFunction]="disableRequiredFunction.bind(this)"
          [classes]="'fill'"
          class="select-search"
      >
      </select-many-filter>
      <select-many-filter
          [label]="'Non editable columns'"
          (constant_changed)="updateDisabled($event)"
          [modelValue]="disabled_columns"
          [options]="config.columns"
          [placeholder]="'Please select disabled columns'"
          [stringFunction]="columnNameFunction"
          [disableItemFunction]="disableItemFunction.bind(this)"
          class="select-search"
          [classes]="'fill'"
      >
      </select-many-filter>
    </div>
  </ng-container>
</div>
