import {AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {EventType} from './event-type';
import {ComponentType} from './component-type';
import {ModelID} from '../_typing/generic-types';
import {ConstantProperty} from './constant-property';

export class LockTemplateAttributes extends AuditMixinAttributes {
    name: string;
    description?: string;
    definition: {
        attributes?: string[],
        constant_properties?: ModelID[]
    } = {
        attributes: [],
        constant_properties: []
    };
}

export class LockTemplateRelationships extends AuditMixinRelationships {
    versions: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    event_type?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    component_type?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class LockTemplate {
    attributes: LockTemplateAttributes;
    relationships: LockTemplateRelationships;
    id: string;
    type: 'lock_template';

    constructor() {
        this.type = 'lock_template';
        this.attributes = new LockTemplateAttributes();
        this.relationships = new LockTemplateRelationships();
    }
}


export interface LockTemplateTableData {
    lock_templates: LockTemplate[];
    event_types?: EventType[];
    component_types?: ComponentType[];
    constant_properties: ConstantProperty[];
}
