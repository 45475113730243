import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SolverTemplate} from '../../_models/solver';
import {SingleResponse} from "../../services/api/response-types";
import {DEFAULT} from "../../_models/shared-types";
import {GenericDataService} from '../../data/generic-data.service';


export interface SolverTemplateDialogData {
  solverTemplate: SolverTemplate;
}

@Component({
    selector: 'solver-template-form',
    templateUrl: './solver-template-form.component.html',
    styleUrls: ['./solver-template-form.component.less'],
    standalone: false
})
export class SolverTemplateFormComponent {
  solverTemplate: SolverTemplate;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: SolverTemplateDialogData,
      public dialogRef: MatDialogRef<SolverTemplateFormComponent>,
      public dialog: MatDialog,
      private snackBar: MatSnackBar,
      private genericData: GenericDataService
  ) {
    this.solverTemplate = this.data.solverTemplate;
  }

  ngOnInit() {
    if (!this.solverTemplate) {
      // i.e. from 'add SolverTemplate' button
      this.solverTemplate = new SolverTemplate();
      this.solverTemplate.attributes.method = 'SLSQP';
    }
  }

  setErrorSeriesType(event) {
    const item = event.value;
    this.solverTemplate.relationships.error_series_type.data = {type: item.type, id: item.id};
  }

  setAdjustedSeriesTypes(event) {
    let adjustedSeriesTypes: {type: string, id: string}[] = [];
    event.forEach(item => {
      adjustedSeriesTypes.push({type: item.type, id: item.id});
    });
    this.solverTemplate.relationships.adjusted_series_types.data = adjustedSeriesTypes;
  }

  setBoundsSeriesType(event, boundType) {
    const item = event.value;
    this.solverTemplate.relationships[boundType].data = {type: item.type, id: item.id};
  }

  apply(close?: boolean) {
    let errMsg: string;
    if (!this.solverTemplate.attributes.name) {
      errMsg = 'Please specify Name before saving';
    } else if (!this.solverTemplate.attributes.method) {
      errMsg = 'Please specify Method before saving';
    } else if (this.solverTemplate.relationships.error_series_type === DEFAULT.SINGLE) {
      errMsg = 'Please specify Error Series Type before saving';
    } else if (this.solverTemplate.relationships.adjusted_series_types === DEFAULT.LIST) {
      errMsg = 'Please enter Adjusted Series Types before saving';
    }

    if (errMsg) {
      this.snackBar.open(errMsg, 'hide', {duration: 10000});
      return;
    }

    this.genericData.upsertModel<SolverTemplate>('solver_template', this.solverTemplate)
        .subscribe({
          next: (response: SingleResponse<SolverTemplate>) => {
            this.solverTemplate = response.data;
            if (close) {
              this.onCloseClick();
            }
          }, error: err => {
            const message = err.error?.errors ? err.error.errors[0].detail : 'Failed to save Solver Template';
            this.snackBar.open(message, 'Hide', {duration: 10000});
          }
        });
  }

  matSelectCompare(option, value): boolean {
    if (value) {
      return option === value;
    }
  }

  onCloseClick(): void {
    this.dialogRef.close(this.solverTemplate);
  }
}
