import {Injectable} from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root'
})
export class TilePdfGeneratorService {

    constructor() {
    }

    downloadPdf(elementId, pdfName, orientation: "p" | "portrait" | "l" | "landscape"='p') {
        const selectedElement = document.getElementById(elementId);

        if (!selectedElement) {
          return
        }

        return html2canvas(selectedElement).then((canvas) => {
          const img = canvas.toDataURL("image/PNG");
          const doc = new jsPDF(orientation, 'pt', 'a4', true);

          // Add image Canvas to PDF
          const imgProps = (<any>doc).getImageProperties(img);
          const pdfWidth = doc.internal.pageSize.getWidth();
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
          let heightLeft = imgHeight;
          let position = 0;
          const pageHeight = doc.internal.pageSize.getHeight();
          doc.addImage(img, 'PNG', 2, position, pdfWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(img, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          return doc;
        }).then((doc) => {
          doc.save(`${pdfName}.pdf`);
        })

    }

}
