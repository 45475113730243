import {Injectable, OnDestroy} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import { HttpClient } from "@angular/common/http";
import {NotificationService} from "../../services/notification.service";
import {Observable, of, Subject, throwError} from "rxjs";
import {ListResponse} from "../../services/api/response-types";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {AppScope} from "../../services/app_scope.service";
import {catchError, first, map, takeUntil} from "rxjs/operators";
import {httpParamSerializer} from "../../lib/utils";
import {ConstantProperty} from '../../_models/constant-property';
import {CUSTOM_EVENTS_CONFIG} from "../../forms/custom-events-form/custom-events-form.component";
import {EventDataService} from '../../data/event-data.service';
import {ConstantPropertyEventTypeAttributes} from "../../_models/constant-property-event-type";
import {EventComponent} from '../../_models/event-component';
import {IDList, IDMap, KeyMap, ModelAttribute, ModelID} from "../../_typing/generic-types";
import {getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {GenericConstantApiResponse} from "../../_models/api/generic-constant";
import {Event} from "../../_models/event";
import {IDateTimePeriod} from "../../_typing/date-time-period";

@Injectable({
    providedIn: 'root'
})
export class CustomEventsDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private http: HttpClient,
                private appScope: AppScope,
                public eventDataService: EventDataService,
                private notification: NotificationService) {
    }

    pageEventLight(query: SearchQueryOptions): Observable<ListResponse<Event>> {
        return this.api.event_light.searchMany(query).pipe(map(listResponse => {
            return listResponse;
        }));
    }

    generateEventsFilter(dtp: IDateTimePeriod, event_type_ids?: string[]): any[] {
        const eventFilters: any[] = this.eventDataService.generateEventsFilter(dtp.start, dtp.end);

        if (event_type_ids && event_type_ids.length > 0) {
            eventFilters.push(this.eventDataService.generateEventByTypesFilter(event_type_ids));
        }

        return eventFilters;
    }

    generateDateFilterConstantProperty(config: CUSTOM_EVENTS_CONFIG, dtp: IDateTimePeriod, event_type_ids?: string[]): any[] {
        const ctrl = this;
        let start, end;
        if (config.constant_property_time) {
            start = config.start_prop;
            end = config.end_prop;
        }

        let no_property = config.start_prop;

        // For including constant properties with null values
        const null_query = {
            "or": [
                {"name": "value", "op": 'eq', val: null},
                {"name": "value", "op": "eq", "val": "None"},
            ]
        };

        // For including events that don't have the Constant
        const doesnt_have_property = {
            "not": {
                "name": "event_constants",
                "op": "any",
                "val": {
                    "name": "constant_property",
                    "op": "has",
                    "val": {"name": "id", "op": "eq", "val": no_property}
                }
            }
        };

        /**NOTE: the 'or' and 'and' arrays here may have further filters pushed to them**/
        const start_in_range: any = {
            "or": [
                // filter events that have the Constant that falls within dtp start date
                {
                    "name": "event_constants",
                    "op": "any",
                    "val": {
                        "and": [
                            {
                                "name": "constant_property",
                                "op": "has",
                                "val": {"name": "id", "op": "eq", "val": config.start_prop}
                            },
                            {
                                "or": [
                                    {
                                        "and": [
                                            {"name": "value", "op": "ne", "val": "None"},
                                            {"name": "value_date", "op": "ge", "val": dtp.start},
                                            {"name": "value_date", "op": "le", "val": dtp.end}
                                        ]
                                    },
                                ]
                            }
                        ]
                    }
                }
            ]
        };

        if (config.include_cp_nulls) {
            start_in_range.or.push(doesnt_have_property);
            start_in_range.or[0].val.and[1].or.push(null_query);
        }

        no_property = config.end_prop;
        /**NOTE: the 'or' and 'and' arrays here may have further filters pushed to them**/
        const end_in_range: any = {
            "or": [
                // filter component that have the Constant that fall within the end date
                {
                    "name": "event_constants",
                    "op": "any",
                    "val": {
                        "and": [
                            {
                                "name": "constant_property",
                                "op": "has",
                                "val": {"name": "id", "op": "eq", "val": config.end_prop}
                            },
                            {
                                "or": [
                                    {
                                        "and": [
                                            {"name": "value", "op": "ne", "val": "None"},
                                            {"name": "value_date", "op": "ge", "val": dtp.start},
                                            {"name": "value_date", "op": "le", "val": dtp.end}
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                }
            ]
        };

        if (config.include_cp_nulls) {
            end_in_range.or.push(doesnt_have_property);
            end_in_range.or[0].val.and[1].or.push(null_query);
        }

        // Neither start or end fall within dtp but dtp falls within custom_constant range
        const overlap = {
            "and": [
                {
                    "name": "event_constants",
                    "op": "any",
                    "val": {
                        "and": [
                            {
                                "name": "constant_property",
                                "op": "has",
                                "val": {"name": "id", "op": "eq", "val": config.start_prop}
                            },
                            {"name": "value", "op": "ne", "val": "None"},
                            {"name": "value_date", "op": "lt", "val": dtp.start}
                        ]
                    }
                },
                {
                    "name": "event_constants",
                    "op": "any",
                    "val": {
                        "and": [
                            {
                                "name": "constant_property",
                                "op": "has",
                                "val": {"name": "id", "op": "eq", "val": config.end_prop}
                            },
                            {"name": "value", "op": "ne", "val": "None"},
                            {"name": "value_date", "op": "gt", "val": dtp.end}
                        ]
                    }
                }
            ]
        };

        let date_filter_logic;
        if (start && end) {
            date_filter_logic = [{or: [start_in_range, end_in_range]}];
        } else if (start) {
            date_filter_logic = [start_in_range];
        } else if (end) {
            date_filter_logic = [end_in_range];
        }

        if (event_type_ids && event_type_ids.length > 0) {
            date_filter_logic.push({name: 'event_type', op: 'has', val: {name: 'id', op: 'in', val: event_type_ids}});
        }

        return date_filter_logic;
    }

    getFullEventComponents(event_ids: ModelID[], component_ids: ModelID[]): Observable<ListResponse<EventComponent>> {
        return this.getEventComponents(event_ids, null, component_ids, true);
    }

    getEventComponents(event_ids: string[], component_type_ids?: string[],
                       component_ids?: ModelID[], full = false): Observable<ListResponse<EventComponent>> {
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithManyIdsFilter('event', event_ids)
        ];
        if (component_type_ids) {
            options.filters.push({
                name: 'component',
                op: 'has',
                val: {name: 'component_type', op: 'has', val: {name: 'id', op: 'in', val: component_type_ids}}
            });
        }
        if (component_ids) {
            options.filters.push({
                name: 'component',
                op: 'has',
                val: {name: 'id', op: 'in', val: component_ids}
            });
        }
        if (full) {
            return this.api.event_component.searchMany(options);
        }
        return this.api.event_component_light.searchMany(options);
    }

    generateComponentSearchFilter(componentIds: ModelID[], filterString, user_includes?): any[] {
        const componentFilters = [];
        componentIds.forEach(id => {
            if (!user_includes || user_includes.includes(id)) {
                componentFilters.push({
                    and: [{
                        "name": "event_components",
                        "op": "any",
                        "val": {
                            "and": [
                                {
                                    "name": "component",
                                    "op": "has",
                                    "val": {
                                        "name": "component_type",
                                        "op": "has",
                                        "val": {
                                            "name": "id",
                                            "op": "eq",
                                            "val": id
                                        }
                                    }
                                },
                                {
                                    "name": "component",
                                    "op": "has",
                                    "val": {
                                        "name": "name",
                                        "op": "ilike",
                                        "val": `%${filterString}%`
                                    }
                                }
                            ]
                        }
                    },
                    ]
                });
            }
        });

        return componentFilters;
    }

    generateConstantPropertiesSearchFilter(constantPropertyIds, cp_dict, filterString, user_includes?): any[] {
        const constantPropertiesFilters = [];
        constantPropertyIds.forEach(id => {
            if (cp_dict[id]?.attributes.data_type !== 'datetime' && (!user_includes || user_includes.includes(id))) {
                constantPropertiesFilters.push({
                    and: [
                        {
                            "name": "event_constants",
                            "op": "any",
                            "val": {
                                "and": [
                                    {
                                        "name": "constant_property",
                                        "op": "has",
                                        "val": {"name": "id", "op": "eq", "val": id}
                                    },
                                    {
                                        "name": "_data",
                                        "op": "ilike",
                                        "val": `%${filterString}%`
                                    }]
                            }
                        }]
                });
            }
        });

        return constantPropertiesFilters;
    }

    updateEventConstantCalculations(eventIds: string[]): Observable<any> {
        return this.http.patch<any>('/api/UpdateEventConstantCalculations', {
            'event_id_list': eventIds
        }).pipe(catchError(({error}) => {
            console.log('error', error);
            if (error && error.message) {
                if (typeof error.message === 'object') {
                    Object.values(error.message).forEach((errorMessage: string) => {
                        this.notification.openError(errorMessage,  5000);
                    });
                }
            }

            return throwError(error);
        }));
    }

    getConstantPropertyEventTypes(event_type_ids: IDList, constant_property_ids: IDList,
                                  cp_dict: IDMap<ConstantProperty>): Observable<KeyMap<ConstantPropertyEventTypeAttributes>> {
        const ctrl = this;
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithManyIdsFilter('event_type', event_type_ids)
        ];
        if (constant_property_ids && constant_property_ids.length > 0) {
            options.filters.push(getRelationWithManyIdsFilter('constant_property', constant_property_ids));
        }

        return this.api.constant_property_event_type.searchMany(options).pipe(
            first(),
            takeUntil(this.onDestroy),
            map(response => {
                const constant_property_event_types = response.data;
                const limits_map = {};

                constant_property_event_types.forEach(cpct => {
                    limits_map[cpct.relationships.constant_property.data.id] = cpct;
                });
                return limits_map;
            }));
    }

    getApiEventConstants(event_ids: ModelID[], constant_properties_ids: ModelID[],
                         attributes?: ModelAttribute[]): Observable<GenericConstantApiResponse> {
        if (!event_ids.length || (constant_properties_ids.length <= 0 && (!attributes || attributes.length <= 0))) {
            return of({data: {}});
        }
        // FIXME: Temp check until backend accepts attributes only
        if (!constant_properties_ids?.length) {
            this.notification.openError("Unable to show lock status for these events.", 10000);
            return of(null);
        }

        const params = {
            'event_ids': event_ids
        };
        if (constant_properties_ids) {
            params['constant_property_ids'] = constant_properties_ids;
        }
        if (attributes) {
            params['attributes'] = attributes;
        }
        const serializedParams = httpParamSerializer(params);
        return this.http.get('/api/constant/event', {params: serializedParams});
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
