import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {moment, TimezoneSelectorService} from "../../services/timezone-selector.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {TimeZoneService} from "../../services/time-zone.service";
import {
    MatCalendarCellCssClasses,
    MatDatepicker,
} from "@angular/material/datepicker";
import * as utils from "../../lib/utils";
import {Subject, take} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.less'],
    providers: [
        { provide: DateAdapter, useClass: TimeZoneService, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DateTimePickerComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private _input_date;
    @Input() set input_date(input_date) {
        if (input_date) {
            this._input_date = new Date(input_date);
        } else {
            this._input_date = null;
        }
    };

    get input_date() {
        return this._input_date;
    }

    @Input() show_time = true;
    @Input() default: Date;
    @Input() set_default = true;
    @Input() max = new Date(3000, 0, 0, 0, 0, 0, 0);
    @Input() min = new Date(1970, 0, 0, 0, 0, 0, 0);
    @Input() disabled = false;
    // ToDo: add this option on component-events and pass down to here
    @Input() show_now_button: boolean = false;
    @Input() openOnInit = false;
    @Input() placeholder = ""
    @Input() classes = ""

    @Input() set styles(styles) {
        this._styles = styles;
    };

    private _styles;
    get styles() {
        return this._styles;
    }

    @Output() date_changed = new EventEmitter();

    @ViewChild('startPicker') date_picker: MatDatepicker<any>;
    @ViewChild('timePicker') time_picker: ElementRef;
    dateClass: any;
    close_on_click = false;
    @Input() nowButton = true;
    hide_now_button = false;

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private timezoneSelectorService: TimezoneSelectorService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.hide_now_button = !this.nowButton;
        if (this.input_date) {
            this.input_date = new Date(this.input_date);
        } else {
            if (this.set_default) {
                if (this.default) {
                    this.input_date = new Date(this.default);
                } else {
                    this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe((dtp) => {
                        this.default = new Date(this.dateInst.dtp.start);
                        this.cdr.markForCheck();
                    })
                }
            }
        }
        this.timezoneSelectorService.activeTimezoneChanged.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(value => {
            if (this.input_date) {
                this.input_date = new Date(this.input_date);
                this.cdr.markForCheck();
            }
        })
        this.addCalendarClasses();
    }

    ngAfterViewInit(): void {
        if (this.date_picker && this.openOnInit === true) {
            this.date_picker.open();
            this.close_on_click = true;
        }
    }

    opened(event) {
        setTimeout(() => {
            this.date_picker['_componentRef'].instance._calendar._userSelection.pipe(takeUntil(this.date_picker.closedStream))
                .subscribe((event) => {
                    this.changeDate({target: {value: event.value}});
                    this.date_picker.close();
                })
        }, 0)
    }

    inputDate(evt) {
        /**This fires when the client types in a date, but it is interpreted as the client's tz so needs to be offset**/
        if (evt.target.value) {
            let tzOffset = this.dateTimePeriodService.getClientTimezoneOffset(new Date());
            evt.target.value.add(tzOffset, 'hours');
        }
    }

    changeDate(evt) {
        /**If the user has purposely deleted the date then clear full date and time**/
        if (!evt.target.value) {
            this.clearDateTime();
            return;
        }
        this.checkBlankDateOnChange();
        if (!evt.target.value) {
            this._input_date = null;
        } else {
            const i = evt.target.value._i;
            const momentTZDate = moment.tz(this.input_date, this.timezoneSelectorService.active_timezone);
            let momentDate = this.dateTimePeriodService.setMomentDate(i, this.dateInst.dtp, momentTZDate.hour(), momentTZDate.minute());
            this._input_date = momentDate.toDate();
        }
        this.date_changed.emit(this.input_date);
        if (this.close_on_click) {
            this.date_picker.close();
        }
    }

    changeTime(evt) {
        this.checkBlankDateOnChange();
        this.input_date = this.dateTimePeriodService.setMomentTime(evt, new Date(this.input_date));
        this.date_changed.emit(this.input_date);
    }

    checkBlankDateOnChange() {
        /**This function makes sure that there is a starting datetime against which to adjust the user-selected date or time**/
        if (!this.input_date) {
            this.input_date = new Date();
        }
    }

    addCalendarClasses() {
        this.dateClass = (d: any): MatCalendarCellCssClasses => {
            let date_class = '';
            const d1 = utils.stringDate(d, {date_only: true})
            const d2 = utils.stringDate(this.input_date, {date_only: true})
            if (d1 === d2) {
                date_class = 'selected-date';
            }
            return date_class;
        }
    }

    clearDateTime() {
        this._input_date = null;
        this.dateChanged()
    }

    setToNow() {
        event.stopPropagation();
        this.input_date = new Date();
        this.dateChanged();
    }

    private dateChanged() {
        this.addCalendarClasses();
        this.date_changed.emit(this.input_date);
        this.date_picker?.close();
    }

    private dateValue(date) {
        return new Date(moment(date).startOf('day')._d).getTime();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
