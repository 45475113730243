import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

/**
 * This service exists purely to resolve the circular dependency between {@class CheckOnlineStateService} and
 * {@class OfflineDialogComponent}.
 */
@Injectable({
    providedIn: 'root'
})
export class CheckOnlineStateBridgeService {
    $disable: Subject<void> = new Subject();
}
