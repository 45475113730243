import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {Observable, Subject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {first, map, take, takeUntil, tap} from "rxjs/operators";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SeriesDataService} from "../../services/series_data.service";
import {
    EventTypeDialogData,
    EventTypeFormDialogComponent
} from '../../forms/event-type-form-dialog/event-type-form-dialog.component';
import {
    openUploadModelFormComponent,
    UploadModelFormData
} from "../../forms/upload-model-form/upload-model-form-dialog.component";
import {FormDialogService} from "../../services/form-dialog.service";
import {EventType} from "../../_models/event-type";
import {ConstantProperty} from "../../_models/constant-property";
import {OreBodyType} from '../../_models/ore-body-type';
import {Series} from '../../_models/series';
import {ComponentType} from '../../_models/component-type';
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ListResponse} from "../../services/api/response-types";
import {EventTypesTableService} from './event-types-table.service';
import {DateTimePeriodService} from '../../services/date-time-period.service';

import {ModelID} from '../../_typing/generic-types';
import {Account} from "../../_models/account";

@Component({
    selector: 'event-types-table',
    templateUrl: 'event-types-table.component.html',
    styleUrls: ['event-types-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None, //Global Styles
    providers: [EventTypesTableService],
    standalone: false
})
export class EventTypesTableComponent implements OnInit, OnDestroy {
    filter_string = '';
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<EventType>;
    page_size_options = [10, 20, 50, 100];
    event_types_total = 0;

    buttons = [];

    account: Account;
    event_types: any[];
    series_list: any[];
    constant_properties_list: any[];
    ore_body_types_list: any[];
    component_types_list: any[];
    cp_dict: { [key: string]: ConstantProperty } = {};
    series_dict: { [key: string]: Series } = {};
    obt_dict: { [key: string]: OreBodyType } = {};
    ct_dict: { [key: string]: ComponentType } = {};
    et_props_list_dict: { [key: string]: string[] } = {};
    et_ct_list_dict: { [key: string]: string[] } = {};

    columns: string[] = ["name", "base_type", "severity", "series_list", "constant_properties",
        "ore_body_types", "component_types", "behaviour", "account", "created_on", "created_by_name", "change_on", "changed_by_name"];

    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private dateTimePeriodService: DateTimePeriodService,
                private changeDetectorRef: ChangeDetectorRef,
                public dialog: MatDialog,
                private seriesData: SeriesDataService,
                private formDialogService: FormDialogService,
                private cps: EventTypesTableService) {
    }

    ngOnInit(): void {
        this.cps.$linkedData.pipe(takeUntil(this.onDestroy)).subscribe(data => {
            this.series_list = data.series;
            this.series_dict = data.series_dict;
            this.ore_body_types_list = data.ore_body_types
            this.obt_dict = data.obt_dict;
            this.component_types_list = data.component_types;
            this.ct_dict = data.ct_dict;
            this.et_props_list_dict = data.et_props_list_dict;
            this.et_ct_list_dict = data.et_ct_list_dict;
            this.changeDetectorRef.markForCheck();
        });

        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';
        setTimeout(() => {
            this.paginator.pageSize = 10;
            initialQuery.filters = this.cps.getEventTypesFilter();

            this.dataSource = new PaginationDataSource<EventType>(
                (query) => this.page(query),
                initialQuery,
                this.paginator,
                this.sort
            );
            this.dataSource.$page.pipe(
                map((result) => this.cps.refreshLinkedTypes(this.event_types)),
                takeUntil(this.onDestroy),
            ).subscribe(() => {
                //this.mapEventTypes();
            });
        })
        this.headerData.title = 'Event Types';
        this.buildHeader();
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.event_type.searchMany(query).pipe(
            first(), takeUntil(this.onDestroy),
            tap(result => {
                this.event_types = result.data;
                this.event_types_total = result.meta.count;
            })
        )
    }

    emitFilterQuery() {
        const filters = this.cps.getEventTypesFilter(this.account?.id)
        this.dataSource.filterBy(filters);
    }

    updateSearchFilter() {
        const filters = this.cps.updateSearchFilter(this.filter_string, this.account?.id);
        this.dataSource.filterBy(filters)
    }

    filterByAccount(event:Account) {
        this.account = event;
        this.emitFilterQuery();
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort)
    }

    openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name);
    }

    editSeries(element) {
        const ctrl = this;
        let $series_full = this.api[element.attributes?.base_type || element.type].getById(element.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            ctrl.seriesData.upsertSeries(null, series_full).afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                }
            })
        })
    }

    editConstantProperty(constant_property: ConstantProperty, event_type: EventType) {
        this.editEventType(event_type, true);
    }

    editEventType(event_type?: EventType, property_tab = false) {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        const event_data: EventTypeDialogData = {
            event_type: event_type ? event_type : new EventType(),
            series_list: ctrl.series_list,
            ore_body_types_list: this.ore_body_types_list,
            component_types_list: this.component_types_list,
            constant_property: property_tab
        };
        dialogConfig.data = event_data;
        dialogConfig.panelClass = ['default-form-dialog', 'event-type-form-dialog'];
        const dialogRef = this.dialog.open(EventTypeFormDialogComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                //TODO make sure event_type on the table is updated properly
                if (event_type) {
                    this.event_types[this.event_types.findIndex(et => et.id === event_type.id)] = result;
                } else {
                    this.event_types.push(result);
                }
                this.updateSearchFilter();
            } else {
            }
        });
    }

    editOreBodyType(ore_body_type: OreBodyType, event_type: EventType) {
        const dialogRef = this.formDialogService.editOreBodyType(ore_body_type);
        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                this.emitFilterQuery();
            }
        });
    }

    editComponentType(component_type: ComponentType, event_type: EventType) {
        //utils.fill_object_relations([component_type], this.constant_properties_list, 'constant_properties');
        const dialogRef = this.formDialogService.editComponentType(component_type, this.constant_properties_list);
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.emitFilterQuery();
            }
        });
    }

    getSeriesFilter(id: ModelID) {
        return this.cps.getSingleEventTypeFilter(id);
    }

    getOreBodyTypeFilter(id: ModelID) {
        return this.cps.getManyEventTypeFilter(id);
    }

    getComponentTypeFilter(id: ModelID) {
        return this.cps.getManyEventTypeFilter(id);
    }

    uploadModels() {
        const dialogData: UploadModelFormData = {parser_type: 'event'};
        openUploadModelFormComponent(this.dialog, dialogData);
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Event Types';
        ctrl.headerData.show_dtp = false;
        ctrl.headerData.buttons = [
            {name: 'Add Event Type', func: ctrl.editEventType.bind(ctrl), class: 'icon-plus'},
            // {name: 'Add Ore Body', func: ctrl.editOreBody.bind(ctrl), class: 'icon-plus'},
            {name: 'Upload Model Definitions', func: ctrl.uploadModels.bind(ctrl), class: 'icon-plus'},
        ];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
