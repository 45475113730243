import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as c3 from "c3";
import {ChartAPI, ChartConfiguration} from "c3";
import {forkJoin, Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {ApiService} from "../../services/api/api.service";
import {ChartSeriesConfiguration} from "../chart-config/chart-series.configuration";
import {GenericChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {TileDataService} from "../../services/tile_data.service";
import {EventService} from "../../services/event.service";
import {PresentationService} from "../../services/presentation.service";
import {ChartService} from "../../services/chart.service";
import {ChartEventsService} from "../../services/chart-events.service";
import * as utils from "../../lib/utils";
import * as moment_ from "moment";
import * as regression from 'regression';
import {IDateTimePeriod, ISamplePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export const moment = moment_["default"];

const colorCache: { [key: string]: string } = {};

@Component({
    selector: 'comparison-chart',
    templateUrl: './comparison-chart.component.html',
    styleUrls: ['./comparison-chart.component.less'],
    providers: [ChartEventsService, ChartService],
    standalone: false
})
export class ComparisonChartComponent implements OnInit, OnDestroy {
    @ViewChild('chart_anchor') chart_anchor: ElementRef;
    config_ready = false;
    chart: ChartAPI;
    id_tag: string;
    config_series_list: ChartSeriesConfiguration[];
    rendered: boolean = false;
    @Input('config')
    tile_config: GenericChartTileConfiguration;
    // TODO make interface
    chart_config: any | ChartConfiguration = {};

    series_full: any[];
    series_name_list: any[];
    series_config: any;
    labels: any;
    series_list: any[];
    estimate_config: any[];
    target_names: any[];
    series_description_list: any[];
    series_ids: string[];
    tag_class_map: any = {};
    /// Formatting and config/////////////////
    legend = true;
    show_limits = false;
    hide_axes = false;
    hide_comments: boolean;
    defaultTitle: string;
    defaultSubtitle: any;
    chart_type: string = '';
    is_budget = false;
    opsdata: boolean;
    multi_dtp: boolean = false;
    // /Time////////////////////////
    period: ISamplePeriod;
    // /End Formatting//////////////////
    dtp: IDateTimePeriod;
    zoom_start: any;
    zoom_end: any;

    $events: any;

    // /Comparison chart///////////////////
    graph_data_stats: any = {};

    time_dict: any = {}; // comparison chart tooltip
    y_axis_series_name: string; // ****comparison chart but can't see how it is used
    x_axis_series_name: string;
    statsColumns: string[] = []; // comparison stats table
    regression_result: any; // comparison stats table
    yRegressionData: any[] = []; // comparison stats table
    xIndex: number; // comparison chart labels
    yIndex: number; // comparison chart labels
    data: any = {};
    /// End Comparison chart///////////////////

    types: any = {};
    axes: any = {};
    colour_pattern: string[] = [];

    estimates = {};
    columns: any = [];
    missing_values: any = {};
    private readonly onDestroy = new Subject<void>();
    private presenting: boolean = false;
    private presentationSubscription: Subscription;


    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private tileData: TileDataService,
                private eventService: EventService,
                private renderer: Renderer2,
                private presentationService: PresentationService,
                private chartService: ChartService,
                private chartEvents: ChartEventsService,
                private dateInst: DateTimeInstanceService) {
        this.chartEvents.eventService = this.eventService;
        this.chartEvents.tileData = this.tileData;

    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.id_tag = utils.gen_graph_id(32); // Used by custom legend

        this.addSubscriptions();

        // Making default titles for charts
        this.defaultTitle = this.tile_config.labels.title;
        this.defaultSubtitle = this.tile_config.labels.sub_title;

        // Setting/sending the default titles for the charts to tileData
        this.tileData.setDefaultTitle(ctrl.defaultTitle);
        this.tileData.setDefaultSubtitle(ctrl.defaultSubtitle);
        if (this.tile_config.chart_type) {
            this.chart_type = this.tile_config.chart_type;
        }

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            ctrl.period = utils.deepCopy(ctrl.dateTimePeriodService.defaultPeriod);
            this.dtp = this.dateInst.dtp;
            this.chartEvents.dtp = this.dtp;
            this.buildChart();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();

        try {
            if (this.chart) {
                this.chart.destroy();
            }
        } catch (e) {

        }
    }

    addSubscriptions() {
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            try {

                if (this.chart) {
                    this.chart.destroy();
                }
                this.buildChart();

            } catch (e) {
                console.log('ERROR: ComparisonChart (addSubscription) \n ', e);
            }
        });
    }

    buildChart() {
        try {
            this.setUp();
        } catch (err) {
            console.log('ERROR: ComparisonChart (buildChart) \n', err);
        }
    }

    setUp() {
        let ctrl = this;
        let series_full_promise;
        ctrl.config_series_list = utils.deepCopy(ctrl.tile_config.series_list);

        if (ctrl.config_series_list === undefined) {
            console.log("ERROR: ComparisonChart (setUp) \nIncorrect number of series");
            return;
        }
        if (!ctrl.config_series_list[0] || !ctrl.config_series_list[1] || !ctrl.config_series_list[0].name || !ctrl.config_series_list[1].name) {
            console.log("ERROR: ComparisonChart (setUp) \nIncorrect number of series");
            return;
        }

        ctrl.labels = utils.deepCopy(ctrl.tile_config.labels);
        ctrl.tileData.title = ctrl.labels.title;
        ctrl.tileData.sub_title = ctrl.labels.sub_title;

        ctrl.series_list = [];
        ctrl.target_names = [];
        // ctrl.series_ids = [];
        ctrl.series_description_list = [];

        ctrl.period = utils.deepCopy(ctrl.dtp.sample_period);

        let series_name_list = ctrl.config_series_list.map(series => series.name);

        ctrl.chartService.getChartSeries(series_name_list)
            .subscribe(response => {
                ctrl.series_full = response.data;
                ctrl.$events = ctrl.chartEvents.getEvents(ctrl.tile_config, ctrl.series_full);

                ctrl.config_series_list.forEach(config_item => {
                    ctrl.series_full.forEach(series => {
                        if (config_item.name === series.attributes.name) {
                            const full_series = utils.deepCopy(series);
                            full_series['config'] = config_item;
                            ctrl.series_list.push(full_series);
                        }
                    });
                });

                ctrl.getData();
            });
    }

    getData() {
        let ctrl = this;
        const ops_periods = ['points', 'second', 'minute', '5 minute', '10 minute', '15 minute', '30 minute'];
        if (ctrl.period) {
            let $time_series_data = [];
            const is_ops = ops_periods.includes(ctrl.dtp.sample_period.name);
            const params = {
                series_list: ctrl.series_list.map(series => series.id),
                start: ctrl.dtp.start.toISOString(),
                end: ctrl.dtp.end.toISOString(),
                sample_period: ctrl.dtp.sample_period.name,
                period_type: ctrl.dtp.calendar
            };
            if (is_ops) {
                $time_series_data.push(ctrl.api.get('/api/OpsData', {
                        params: params
                    })
                );
            } else {
                $time_series_data.push(ctrl.api.get_series_data(params));
            }

            // TODO get events working here on all iterations
            forkJoin($time_series_data).pipe(takeUntil(this.onDestroy)).subscribe((data) => {
                ctrl.data = data;

                ctrl.calculateStats();

                if (this.presentationSubscription) {
                    this.presentationSubscription.unsubscribe();
                    this.presentationSubscription = null;
                }

                this.presentationSubscription = this.presentationService.presentationStateChanged.pipe(takeUntil(this.onDestroy)).subscribe(state => {
                    if (this.chart) {
                        try {
                            this.chart.destroy();
                        } catch (e) {
                            console.log('WARNING: ComparisonChart (getData) \n Can\'t destroy chart, component was likely destroyed with ngIf');
                        }
                        // this.chart = null;
                    }
                    this.presenting = state;

                    this.prepareData();
                    // TODO events needs to be checked but can't get this right in all instances of events response
                    ctrl.$events.pipe(takeUntil(ctrl.onDestroy)).subscribe((events) => {
                        // Why is this needed?
                        ctrl.tileData.events = events ? events.data : [];
                        this.generateChart();
                        this.renderChart();
                    });
                });
            });
        }

    }

    calculateStats() {
        const ctrl = this;

        let series_name_list = [];
        let dataComparisonSet = ctrl.data[0]['data'];
        let value_arrays = {};
        ctrl.graph_data_stats = {};
        ctrl.graph_data_stats['stats_table'] = [];
        ctrl.statsColumns = [];
        let series_input_names = [];
        ctrl.tile_config.series_list.map(element => {
            series_input_names.push(element.name);
        });
        for (let series in dataComparisonSet) {
            if (series_input_names.includes(series)) {
                series_name_list.push(series);
                let values = [];
                for (let series_data_time in dataComparisonSet[series]) {
                    let value = dataComparisonSet[series][series_data_time];
                    values.push(value);
                }
                value_arrays[series] = values;
                let average = values.reduce((a, b) => a + b, 0) / values.length;
                let std_dev = utils.std_dev(values);
                let stats_object = {name: series, average: average, std_dev: std_dev, values: values};
                ctrl.graph_data_stats['stats_table'].push(stats_object);
            }
        }

        let radicand_numerator = Math.pow(ctrl.graph_data_stats.stats_table[0].std_dev, 2) + Math.pow(ctrl.graph_data_stats.stats_table[1].std_dev, 2);
        let radicand_denominator = value_arrays[series_name_list[0]].length + value_arrays[series_name_list[1]].length;
        let radicand = radicand_numerator / radicand_denominator;

        let pooled_std_dev = Math.sqrt(radicand);

        let pooled_average = ctrl.graph_data_stats.stats_table[0].average * ctrl.graph_data_stats.stats_table[0].values.length / (
            2 * ctrl.graph_data_stats.stats_table[0].values.length - 2);

        ctrl.graph_data_stats['r2'] = utils.pearsonCorrelationCoefficient(value_arrays[series_name_list[0]], value_arrays[series_name_list[1]]);
        let pooled = {};

        pooled['name'] = 'Pooled';
        pooled['average'] = pooled_average;
        pooled['std_dev'] = pooled_std_dev;
        ctrl.graph_data_stats.stats_table.push(pooled);

        ctrl.statsColumns.push('name');

        if (ctrl.tile_config.statistics.averages) {
            ctrl.statsColumns.push('average');
        }
        if (ctrl.tile_config.statistics.std_dev) {
            ctrl.statsColumns.push('std_dev');
        }

        if (ctrl.tile_config.regression_line && ctrl.tile_config.regression_line.toLowerCase() !== 'none') {
            let zip = (x, y) => x.map((x, i) => [x, y[i]]);
            let xValues;
            let yValues;
            ctrl.tile_config.series_list.map(axisObj => {
                if (axisObj['axis'] === 'x') {
                    xValues = value_arrays[axisObj['name']];
                } else if (axisObj['axis'] === 'y') {
                    yValues = value_arrays[axisObj['name']];
                }
            });

            let regressionData = zip(xValues, yValues);
            let result;
            switch (ctrl.tile_config.regression_line.toLowerCase()) {
                case 'linear':
                    result = regression.linear(regressionData);
                    break;
                case 'power':
                    result = regression.power(regressionData);
                    break;
                case 'exponential':
                    result = regression.exponential(regressionData);
                    break;
            }
            ctrl.regression_result = result;
            let yPredictions = [];
            xValues.map(x => {
                yPredictions.push(result.predict(x));
            });

            let yRegression = [];
            yRegression.push('regression');
            yPredictions.map(y => {
                yRegression.push(y[1]);
            });

            ctrl.yRegressionData = yRegression;
        }
    }

    prepareData() {
        const ctrl = this;
        ctrl.tag_class_map = {};
        let time_list = [];
        ctrl.xIndex = this.series_list[0].config.axis === 'x' ? 0 : 1;
        ctrl.yIndex = this.series_list[0].config.axis === 'y' ? 0 : 1;
        let seriesX = ctrl.data[0].data[this.series_list[ctrl.xIndex].attributes.name];
        let seriesY = ctrl.data[0].data[this.series_list[ctrl.yIndex].attributes.name];
        let x = [], y = [];

        for (let time_stamp in seriesX) {
            if (seriesX.hasOwnProperty(time_stamp)) {
                x.push(seriesX[time_stamp]);
                y.push(seriesY[time_stamp]);
                time_list.push(new Date(time_stamp));
                if (!ctrl.time_dict[seriesX[time_stamp] + '_' + seriesY[time_stamp]]) {
                    ctrl.time_dict[seriesX[time_stamp] + '_' + seriesY[time_stamp]] = [new Date(time_stamp)];
                } else {
                    ctrl.time_dict[seriesX[time_stamp] + '_' + seriesY[time_stamp]].push(new Date(time_stamp));
                }
            }
        }
        ctrl.types['comparison'] = 'line';
        ctrl.axes['comparison'] = 'y';
        ctrl.columns.push(['x'].concat(x));
        ctrl.columns.push(['comparison'].concat(y));

        this.legend = false;

        let getLabel = function (i) {
            if (ctrl.series_list[i].attributes.description !== null && ctrl.series_list[i].attributes.description !== undefined) {
                return ctrl.series_list[i].attributes.description;
            } else {
                return ctrl.series_list[i].attributes.name;
            }
        };
        if (this.tile_config.labels.x_axis) {
            ctrl.labels['x_axis'] = ctrl.tile_config.labels.x_axis;
        } else {
            ctrl.labels['x_axis'] = getLabel(ctrl.xIndex);
        }
        if (this.tile_config.labels.y_axis) {
            ctrl.labels['y_axis'] = ctrl.tile_config.labels.y_axis;
        } else {
            ctrl.labels['y_axis'] = getLabel(ctrl.yIndex);
        }

        ctrl.tag_class_map['diagonal'] = 'diagonal';
        ctrl.tag_class_map['regression'] = 'regression';
    }

    generateChart() {
        const ctrl = this;
        ctrl.chart_config = ctrl.chartService.getStartingConfig(ctrl.columns, ctrl.types,
            ctrl.axes, {}, ctrl.colour_pattern, ctrl.hide_axes);

        ctrl.chart_config.data.x = 'x';
        ctrl.chart_config.data.color = function getColor(color, d, e) {
            // Use the color set in the tile_config only if it's one of the comparison points. Lines use fixed colours.
            if (d && d.id && d.id === 'comparison' && ctrl.tile_config.colour) {
                return ctrl.tile_config.colour;
            }
            return color;
        };

        let custom_tool_tip = {
            show: true,
            contents: function (d) {
                let arrTimes = ctrl.time_dict[d[0].x + '_' + d[0].value];
                let text;
                text = "<table class='chart-tooltip'><tr><th colspan='2'>Time stamp</th></tr>";
                arrTimes.forEach(time => {
                    text += "<tr><td colspan='2'>" + moment(time).format('YYYY-MM-DD hh:mm') + "</td></tr>";
                });
                text += "<tr><td>" + ctrl.labels['x_axis'] + " value" + "</td><td>" + d[0].x.toFixed(3) + "</td></tr>";
                text += "<tr><td>" + ctrl.labels['y_axis'] + " value" + "</td><td>" + d[0].value.toFixed(3) + "</td></tr>";

                return text + "</table>";
            }
        };
        ctrl.chart_config.tooltip = custom_tool_tip;

        let xValues;
        if (ctrl.columns[0]) {
            xValues = utils.deepCopy(ctrl.columns[0]);
            xValues.shift();
        }
        ctrl.chart_config.axis.x.tick['values'] = utils.gen_xTick_values(xValues, ctrl.chartService.x_max_ticks);

        if (ctrl.tile_config.diagonal_line) {
            let yValues = utils.deepCopy(ctrl.columns[0]);
            yValues.shift();
            let diagonal_data = ['diagonal'].concat(yValues);
            ctrl.chart_config.data.columns.push(diagonal_data);
            ctrl.chart_config.data.types['diagonal'] = 'line';
        }

        if (ctrl.tile_config.regression_line.toLowerCase() !== 'none') {
            ctrl.chart_config.data.columns.push(ctrl.yRegressionData);
            ctrl.chart_config.data.types['regression'] = 'line';
        }

        ctrl.chartService.configureAxisLabels(ctrl.chart_config, ctrl.labels);

        ctrl.chartService.progressiveDisable(ctrl.columns, ctrl.chart_config);

        ctrl.config_ready = true;
    }

    renderChart() {
        const ctrl = this;
        ctrl.chart_config.bindto = ctrl.chart_anchor.nativeElement;

        setTimeout(() => {
            ctrl.tile_config.size = {};
            try {
                ctrl.chart = c3.generate(ctrl.chart_config);
                ctrl.chartService.rendered_chart = ctrl.chart;
                ctrl.chartEvents.rendered_chart = ctrl.chart;
                ctrl.postRenderChart();
            } catch (err) {
                console.log("ERROR: ComparisonChart (renderChart) \n ", err);
            }
        }, 100);
    }

    // ********Post chart render functions/////////////////////////////////////
    postRenderChart() {
        const ctrl = this;
        if (ctrl.chart_type === 'comparison') {
            ctrl.setHeight();
            ctrl.chart.transform('scatter');
            if (ctrl.tile_config.diagonal_line) {
                ctrl.chart.transform('line', 'diagonal');
            }
            if (ctrl.tile_config.regression_line.toLowerCase() !== 'none') {
                ctrl.chart.transform('line', 'regression');
            }
        }

        ctrl.rendered = true;
    }

    setHeight() {
        // Only used by comparison chart
        // TODO is there a less micromanagey way to do this??
        let el = this.chart_anchor.nativeElement;
        let maxHeight = utils.deepCopy(parseFloat(el.style.maxHeight));

        if (!isNaN(maxHeight) && maxHeight > 300 && !this.rendered &&
            (this.tile_config.statistics.averages || this.tile_config.statistics.pooled ||
                this.tile_config.statistics.std_dev)) {
            if (this.tile_config.statistics.correlation_coeff) {
                maxHeight -= 130;
            } else {
                maxHeight -= 170;
            }
        } else if (!isNaN(maxHeight) && maxHeight > 300 && this.tile_config.statistics.correlation_coeff) {
            maxHeight -= 30;
        }
        this.renderer.setStyle(el, 'maxHeight', maxHeight + 'px');
    }
}
