<div class="row" style="margin-top:16px;">
  <select-search-component (selectionChange)="applySeriesToConfigList($event.value)"
                           [api_model]="'series_light'"
                           [classes]="'fill custom-chart-series'"
                           placeholder="Select series"
                           [value]="series"
                           label="Select series"
                           class="select-search"
                           style="width:180px;"
  ></select-search-component>
  <select-many-series style="flex-grow:1;width:180px;" [disabled]="!series_list.length"
                      (selectionChange)="setTargets($event)"
                      [value]="getTargets()"
                      [custom_filters]="target_series_filter"
                      [label]="'Select target series' + (!getTargets().length ? ' (no targets associated with this series)':'')"
                      [classes]="'fill custom-chart-series'"
                      label="Select target series">
  </select-many-series>
</div>

<div class="row">
  <mat-form-field matTooltip="Add a new range group to the rar chart" style="margin-right:0;">
    <mat-label>Ranges</mat-label>
    <mat-select [(value)]="selected_groups" (selectionChange)="updateGroups($event.value)" multiple>
      <mat-option *ngFor="let group of rar_group_types"
                  [value]="group">{{group | removeUnderscore | upperfirst}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<ng-container *ngIf="series_list!==undefined">
  <div class="custom-chart-series-table">
    <div class="draggable-list-container" style="margin-top:-2px;flex-grow:1;max-width: 100%;">
      <h3>Chart Series Configuration:
        <b *ngIf="!series" class="warning" style="margin-left:20px;">Please select a series</b>
        <b *ngIf="series" style="margin-left:20px;">{{series.attributes | nameAndDescription}}</b>
      </h3>
      <div #selectedList="cdkDropList"
           (cdkDropListDropped)="drop($event)"
           [cdkDropListData]="series_list"
           cdkDropList style="margin-top:6px;"
           class="draggable-list">

        <div *ngFor="let series_config of series_list; let i = index" cdkDrag>

          <div class="row center outer-row" style="justify-content: space-between;">
            <div class="row center" style="flex-grow:1;">
              <div class="row center" style="width:240px;margin-right: 20px;">
                <div class="row expand-icon center">
                  <div (click)="expanded[i]=!expanded[i]" class="caret">
                    <i *ngIf="!expanded[i]" [title]="'Click here for more customisation options'"
                       class="fas fa-caret-down"></i>
                    <i *ngIf="expanded[i]" [title]="'Click here to close the detail options drawer'"
                       class="fas fa-caret-up"></i>
                  </div>
                </div>
                <div *ngIf="!series_config.is_target">{{series_config.group_name | titlecaseForWire}}</div>
                <div *ngIf="series_config.is_target">{{series_config.group_name | uppercase}}</div>
              </div>

              <ng-container *ngIf="!series_config.is_target">
                <mat-form-field class="inline" matTooltip="Select custom period for this series"
                                style="max-width:250px;">
                  <label>Period:</label>
                  <mat-select [(value)]="series_config.sample_period" [compareWith]="matSelectCompare" disabled>
                    <mat-option *ngFor="let sample_period of sample_periods"
                                [value]="sample_period">{{sample_period.name | removeUnderscore | upperfirst}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="inline" matTooltip="Select a number of periods for this series"
                                style="max-width:250px;">
                  <label>Number:</label>
                  <mat-select [(value)]="series_config.number_of_periods" disabled>
                    <mat-option *ngFor="let no of period_number_options" [value]="no">
                      {{no}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="series_config.is_target">
                <mat-form-field class="inline" matTooltip="Line and area charts only.">
                  <label>Line type:</label>
                  <mat-select [(value)]="series_config.line_type">
                    <mat-option value="solid">Solid</mat-option>
                    <mat-option value="dotted">Dotted</mat-option>
                    <mat-option value="dashed">Dashed</mat-option>
                    <mat-option value="dashdot">Dash dot</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="inline" matTooltip="Line and area charts only.">
                  <label>Thickness:</label>
                  <mat-select [(value)]="series_config.line_thickness">
                    <mat-option value="thickness-thin">Thin</mat-option>
                    <mat-option value="normal">Normal</mat-option>
                    <mat-option value="thickness-thick">Thick</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="inline" matTooltip="Line and area charts only.">
                  <label>Shape:</label>
                  <mat-select [(value)]="series_config.line_shape">
                    <mat-option value="linear">Linear</mat-option>
                    <mat-option value="spline">Spline</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <div class="row center" style="width:80px;">
                <label style="margin-right:10px;">Colour:</label>
                <colour-palette [value]="series_config.colour || 'rgba(10,10,10,0.1)'"
                                (selectChange)="series_config.colour=$event"
                                palette="2"
                                show_text="true"
                                [use_picker]="true">
                </colour-palette>
                <div class="spacer"></div>
              </div>
            </div>

            <div style="padding-right:10px;">
              <button class="text-btn" (click)="removeRow(i);" matTooltip="Remove series">
                <span class="material-symbols-outlined" style="cursor:pointer;">close</span>
              </button>
            </div>
          </div>

          <div class="expanded" *ngIf="expanded[i]">

            <div class="inner-content row">
              <div style="width:6%;display:flex;justify-content: start;padding-left:0.5%;">
                <div class="expanded-icon"></div>
              </div>

              <div class="column" style="width:94%;">

                <div class="row" style="height:30px;margin-top:20px;">

                  <mat-checkbox [(ngModel)]="series_config.show_data_labels">Show data labels
                  </mat-checkbox>

                  <mat-checkbox [(ngModel)]="series_config.vertical_data_labels"
                                [disabled]="!series_config.show_data_labels && !['pie', 'gauge', 'donut'].includes(series_config.type)"
                  > Vertical data labels
                  </mat-checkbox>

                  <!--                  <mat-checkbox [(ngModel)]="series_config.show_legend">Show legend-->
                  <!--                  </mat-checkbox>-->

                  <mat-checkbox *ngIf="series_config.is_target" [(ngModel)]="series_config.hide_markers">Hide markers
                  </mat-checkbox>
                </div>

                <div class="row" style="align-items: flex-end;margin-top: -4px;margin-bottom: 6px;">
                  <mat-form-field>
                    <label>Custom legend</label>
                    <input [(ngModel)]="series_config.custom_legend_name" matInput>
                  </mat-form-field>

                  <mat-checkbox [(ngModel)]="series_config.use_alias" *ngIf="series_config.is_target"
                                [disabled]="series_config.custom_legend_name?.length > 0">OR Use series alias
                  </mat-checkbox>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</ng-container>
