<div class="formula-builder">
  <h3>Formula Builder</h3>
  <div *ngIf="!builder_ready" class="row center">Loading constant properties...</div>
  <div *ngIf="builder_ready" class="row center">

    <!--parent component constant properties (currently could be 'Event Type' or 'Component Type'-->
    <div class="row center" style="flex-basis:49%;margin-right:1%;">
      <div class="select-search" style="flex-grow:2;margin-right:0;">
        <select-search
            (selectChange)="formula_constant_property"
            [(value)]="formula_constant_property"
            [classes]="'fill'"
            [data]="constant_properties_list"
            [label]="parent_component.attributes.name + ' (' + [parent_component.type | removeUnderscore] + ') Properties'"
        >
        </select-search>
      </div>
      <button (click)="addToFormula(formula_constant_property)"
              class="btn-addtotext form-element-align" style="height:26px;width:26px;" title="Add to formula">
      </button>
    </div>

    <!--Ore body constant properties-->
    <div *ngIf="parent_component.type !== 'component_type'" class="row center" style="flex-basis:49%;margin-left:1%;">
      <div class="select-search" style="flex-grow:2;margin-right:0;">
        <select-search
            (selectChange)="ob_formula_constant_property"
            [(value)]="ob_formula_constant_property"
            [classes]="'fill'"
            [data]="ob_type_constant_properties_list"
            [label]="'Ore Body Properties'"
        >
        </select-search>
      </div>
      <button (click)="addToFormula(ob_formula_constant_property,'input')"
              class="btn-in-out form-element-align">
        <mat-icon matTooltip="Add as 'Destination'">archive</mat-icon>
      </button>
      <button (click)="addToFormula(ob_formula_constant_property, 'output')"
              class="btn-in-out form-element-align">
        <mat-icon matTooltip="Add as 'Source'">unarchive</mat-icon>
      </button>
    </div>

    <!--Component Type constant properties-->
    <div *ngIf="parent_component.type !== 'component_type'" class="row center" style="flex-basis:49%;margin-left:1%;">
      <div class="select-search" style="flex-grow:2;margin-right:0;">
        <select-search
            (selectChange)="ct_formula_constant_property"
            [(value)]="ct_formula_constant_property"
            [classes]="'fill'"
            [data]="ct_constant_properties_list"
            [label]="'Component Type Properties'"
            [stringFunction]="typePropertyString"
        >
        </select-search>
      </div>
      <button (click)="addToFormula(ct_formula_constant_property, 'component')"
              class="btn-addtotext form-element-align" style="height:26px;width:26px;" title="Add to formula">
      </button>
    </div>

    <!--Event Type constant properties-->
    <div *ngIf="parent_component.type !== 'event_type'" class="row center" style="flex-basis:49%;margin-left:1%;">
      <div class="select-search" style="flex-grow:2;margin-right:0;">
        <select-search
            (selectChange)="et_formula_constant_property"
            [(value)]="et_formula_constant_property"
            [classes]="'fill'"
            [data]="et_constant_properties_list"
            [label]="'Event Type Properties'"
            [stringFunction]="typePropertyString"
        >
        </select-search>
      </div>
      <button (click)="addToFormula(et_formula_constant_property, 'component@component_type')"
              class="btn-addtotext form-element-align" style="height:26px;width:26px;" title="Add to formula">
      </button>
    </div>

    <!--Component Type "constants" constant properties-->
    <div *ngIf="parent_component.type === 'component_type'" class="row center" style="flex-basis:49%;margin-left:1%;">
      <div class="select-search" style="flex-grow:2;margin-right:0;">
        <select-search
            (selectChange)="constant_formula_constant_property"
            [(value)]="constant_formula_constant_property"
            [classes]="'fill'"
            [data]="ct_constants_list"
            [label]="'Component Type Constants'"
            [stringFunction]="typePropertyString"
        >
        </select-search>
      </div>
      <button (click)="addToFormula(constant_formula_constant_property, 'component_type')"
              class="btn-addtotext form-element-align" style="height:26px;width:26px;" title="Add to formula">
      </button>
    </div>

  </div>

  <div class="row">
      <textarea [(ngModel)]="constant_property.attributes.name_formula" name="name_formula"
                rows="3" style="flex-grow:2;">
      </textarea>
  </div>
  <!--   <div class="row action-buttons">-->
  <!--     <button class="save" (click)="submitFormula()">Submit</button>-->
  <!--   </div>-->
</div>
