<mat-form-field class="select-filter override-hide-placeholder" [matTooltip]="displayTooltip ? value.value : null"
                [matTooltipShowDelay]="1100" [ngClass]="classes">
  <mat-label>{{label}}</mat-label>
  <mat-select #filterSelect
              panelClass="select-search-dropdown-panel"
              (selectionChange)="emitConstantChangedComplete($event.value);"
              (click)="$event.stopPropagation()"
              (openedChange)="onOpen($event)"
              [formControl]="value"
              [compareWith]="filter_compare || compareByName"
              [allowFit]="allow_fit"
              [appFitContent]="app_fit_content"
              [ngStyle]="conditional_formats || formats?.style"
              [tabindex]="tab_index"
              [placeholder]="getPlaceholder()"
  >

    <div class="dropdown-container">
      <ng-container *ngIf="options?.length > 5" class="row center sticky">
        <option-list-search (keydown.space)="$event.stopPropagation()"
                            [placeholder]="placeholder || 'Search'"
                            [selectElementRef]="filterSelect"
                            (inputChanged)="filterOptionList($event)"
                            (inputCleared)="onClear()"
                            [delay]="300">
        </option-list-search>
      </ng-container>
      <div *ngIf="filteredOptions?.length === 0" class="row error">
        <span>"{{errorMessage}}"</span>
      </div>
      <ng-container *ngFor="let option of filteredOptions; let i = index" class="row options">
        <mat-option *ngIf="!option?.attributes && !filterValue && i === 0 " [value]="null"></mat-option>
        <mat-option *ngIf="option?.name && option?.value else defaultTemplate"
                    [value]="option.value">{{ option.name | upperfirst}}</mat-option>
        <ng-template #defaultTemplate>
          <mat-option [value]="option">{{stringFunction(option) | upperfirst}}</mat-option>
        </ng-template>
      </ng-container>
    </div>
  </mat-select>
</mat-form-field>
