import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MenuTreeService} from "../../services/menu-tree.service";

@Component({
    selector: 'process-quick-menu',
    templateUrl: './process-quick-menu.component.html',
    styleUrls: ['./process-quick-menu.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProcessQuickMenuComponent implements OnInit {
    @Input() process;
    @Output() onNav = new EventEmitter();
    menu;
    menu_items = ['Series', 'Report Configuration', 'Review Data', 'Estimates'];

    constructor(private menuService: MenuTreeService) {
    }

    ngOnInit(): void {
        this.menu = this.menuService.getProcessMenu(this.process.id, this.onClick.bind(this), this.menu_items)
    }

    onClick(event) {
        this.onNav.emit();
    }
}
