<ng-container>
  <ng-container *ngIf="isFile(constant)">
    <generic-component-file-field
        [value]="$any(constant)"
        [constant_property]="constant_property"
        [component]="component"
        [disabled]="disabled"
        (relayConstantChangedEmitter)="relayConstantChanged($event)"
    >
    </generic-component-file-field>
  </ng-container>
  <ng-container *ngIf="isConstant(constant) || isInvalid(constant)">
    <generic-constant-field
        [constant]="constant"
        [constant_property]="constant_property"
        [tooltip]="tooltip"
        [auto_class]="auto_class"
        [formats]="formats"
        (constant_changed)="relayConstantChanged($event)"
        (change_complete)="relayChangeComplete($event)"
        (lock_clicked)="relayLockClicked()"
        [disabled]="disabled"
        [defaultValue]="defaultValue"
        [show_sig]="show_sig">
    </generic-constant-field>
  </ng-container>
</ng-container>
