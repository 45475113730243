import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Subject, Subscription} from 'rxjs';
import {DateTimePeriodService} from '../../services/date-time-period.service';
import {Series} from '../../_models/series';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {takeUntil, tap} from 'rxjs/operators';
import {EventService} from "../../services/event.service";
import {Event as WireEvent} from "../../_models/event";
import {SeriesAuditHistoryService} from "./series-audit-history.service";
import {refreshSubscription} from "../../lib/utils";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export interface TableRowData {
    type: string | null
    value: any | null,
    title?: string,
    name: string
}

export interface SeriesAuditHistoryTable {
    timestamp: Date,
    value: number | null,
    old_value: number | null,
    changed_on: Date,
    changed_by: string,
    comments: string[]
}

@Component({
    selector: 'series-audit-history',
    templateUrl: './series-audit-history.component.html',
    styleUrls: ['./series-audit-history.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesAuditHistoryComponent implements OnInit {
    private onDestroy: Subject<void> = new Subject();
    $audit_history_sub: Subscription;
    page_number: number = 0;
    page_size = 10;
    paged_history: any[];
    current_page: any;
    times: any = []

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private eventService: EventService,
                private cps: SeriesAuditHistoryService,
                private dateInst: DateTimeInstanceService,
    ) {
    }

    _series: Series;

    get series(): Series {
        return this._series;
    }

    @Input()
    set series(_series: Series) {
        this._series = _series;
    }

    @Input() dtp: IDateTimePeriod;
    @Input() table_view: boolean = true;
    data: SeriesAuditHistoryTable[];
    dataSource: MatTableDataSource<SeriesAuditHistoryTable>;
    columns = ['timestamp', 'value', 'old_value', 'changed_by', 'changed_on', 'comments'];
    comments: WireEvent[];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    private sort;

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    ngOnInit(): void {
        this.page_number = 0;
        this.dataSource = new MatTableDataSource();

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dateInst.dtp = dtp;
            this.cps.onSeriesChanged(this.series, dtp, this.table_view);
        })

        this.$audit_history_sub = refreshSubscription(this.$audit_history_sub)
        this.$audit_history_sub = this.cps.$auditHistoryData.pipe(takeUntil(this.onDestroy),
            tap(data => {
                if (!this.table_view) {
                    this.paged_history = data;
                } else {
                    this.dataSource.data = data;
                    this.setupTable();
                }
            })).subscribe();

        this.cps.onSeriesChanged(this.series, this.dateInst.dtp, this.table_view);
    }

    setupTable() {
        this.dataSource.data.sort((a, b) => {
            return a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0;
        });
        this.dataSource.filterPredicate = (data, filter) => {
            if ((data.changed_by && data.changed_by.toLowerCase().includes(filter))
                || (data.value && data.value.toString().includes(filter))
                || (data.old_value && data.old_value.toString().includes(filter))
            ) {
                return true;
            }
            return false;
        };

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                default:
                    return data[sortHeaderId];
                    break;
            }
        };

        this.dataSource.paginator = this.paginator;
    }

    getNext() {
        this.current_page = this.paged_history[this.page_number];
    }

    page(dir) {
        const ctrl = this;
        ctrl.page_number = ctrl.page_number + (dir * 1);
        ctrl.getNext();
    }

    showPrevious() {
        return this.page_number == 1 ? false : true;
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }
}
