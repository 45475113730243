<p *ngIf="error && error.length > 0">error</p>
<ng-container *ngIf="had_file; else missingFileBlock">
  <div style="display: flex; flex-direction: column">
    <ng-container *ngIf="required_configurations.length > 0">
      <h2>Required configurations</h2>
      <div class="row auto required-configurations">
        <ul>
          <li *ngFor="let c of required_configurations">{{c}}</li>
        </ul>
      </div>
    </ng-container>

    <h2 style="margin-bottom:0;">Upload options</h2>
    <div class="row medium-height center">
      <mat-checkbox [ngModel]="displayOptions.update_only" (change)="setUpdateOnly($event.checked)"
                    [disabled]="readonly"
                    name="update_only"
                    title="Select to exclude Components which do
                         not yet exist in WIRE">Update Only
      </mat-checkbox>
    </div>

    <div class="row">
      <component-model-upload-select-component-type class="select-search" (componentTypeIDChange)="setComponentType($event)"
                                                    [readonly]="readonly">
      </component-model-upload-select-component-type>

      <mat-form-field>
        <mat-label>Select upsert type</mat-label>
        <mat-select [ngModel]="displayOptions.upsert_type" (selectionChange)="setUpsertType($event.value)"
                    [disabled]="readonly">
          <mat-option
              *ngFor="let upsert_type of upsert_types"
              [value]="upsert_type.value"
              [disabled]="upsert_type.disabled">
            {{upsert_type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field title="Trim this number of rows from the top of the csv file.">
        <mat-label>Skip Rows</mat-label>
        <input (ngModelChange)="setSkipRows($event)"
               [min]="0"
               [ngModelOptions]="{standalone: true}" [ngModel]="displayOptions.skip_rows"
               [disabled]="readonly"
               matInput
               type="number">
      </mat-form-field>

      <mat-checkbox class="self-center"
          (change)="setUseColumnIndexes($event.checked)"
          [checked]="displayOptions.use_column_indexes"
          [disabled]="readonly"
          title="Named columns expect header names in first row.&#013;Index headers expect data in the first row.">
        Use column indexes for header names
      </mat-checkbox>
    </div>

    <h2>Header associations</h2>

    <div class="row auto">
      <h4 style="flex: 1">Column Headers</h4>
      <h4 style="flex: 2">Parser Feature</h4>
      <h4 style="flex: 2">Assign Type</h4>
    </div>
    <div *ngFor="let header of header_associations" class="row medium-height">
      <div [title]="header.source" class="column-header" >
        {{header.source}}
      </div>
      <div class="row medium-height" style="flex: 2">
        <mat-form-field class="no-label">
          <mat-select [ngModel]="header.target_type" (selectionChange)="setHeaderTarget(header, $event)"
                      [disabled]="readonly">
            <mat-option *ngFor="let option of model_upload_features" [value]="option">
              {{DESCRIPTIONS[option]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row medium-height" style="flex: 2">
        <!-- Components -->
        <component-model-upload-select-component-type-column
            class="full-width full-height"
            *ngIf="header.target_type == HEADER_TYPES.COMPONENT"
            [readonly]="readonly"
            [componentTypeID]="header.override_id"
            (componentTypeIDChange)="setOverride(header, $event)"
        ></component-model-upload-select-component-type-column>
        <!-- Constant Properties -->
        <component-model-upload-select-constant-property
            class="full-width full-height"
            *ngIf="header.target_type == HEADER_TYPES.PROPERTY"
            [readonly]="readonly"
            [constantPropertyID]="header.override_id"
            (constantPropertyIDChange)="setOverride(header, $event)"
        ></component-model-upload-select-constant-property>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #missingFileBlock>
  <p>Please select a file to upload.</p>
</ng-template>
