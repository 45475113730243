import {Injectable} from '@angular/core';
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {Observable} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from '../../services/api/api.service';
import {getRelationWithIdFilter, getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {ModelID} from '../../_typing/generic-types';

@Injectable({
    providedIn: 'root'
})
export class EventTypeFormDialogService {

    constructor(private relMapping: RelationshipApiMappingService,
                private api: ApiService) {
    }

    getComponentTypesByEventType(parent_id: string): Observable<any> {
        const model = 'event_type_component_type_map';
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('event_type', parent_id)
        ];
        return this.api[model].searchMany(options);
    }

    getEventTypeComponentTypesByComponentTypesandEventType(event_type_id: ModelID, component_type_ids: ModelID[]): Observable<any> {
        const model = 'event_type_component_type_map';
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithManyIdsFilter('component_type', component_type_ids),
            getRelationWithIdFilter('event_type', event_type_id)
        ];
        return this.api[model].searchMany(options);
    }

}
