import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import {HeaderDataService} from "../../services/header_data.service";
import {takeUntil} from "rxjs/operators";
import {BehaviorSubject, combineLatest, ReplaySubject, Subject} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatSelectChange} from "@angular/material/select";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'validate-component-templates',
    templateUrl: './validate-component-templates.component.html',
    styleUrls: ['./validate-component-templates.component.css'],
    standalone: false
})
export class ValidateComponentTemplatesComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<InvalidGraphNodeSummary>;

    displayedColumns: string[] = ['type', 'property', 'messages'];

    summary: InvalidGraphSummary;

    message_filters: Subject<MessageFilter[]> = new Subject<MessageFilter[]>();

    public selected_codes$ = new BehaviorSubject([]);
    public summary$ = new ReplaySubject(1);

    constructor(private appScope: AppScope,
                private headerDataService: HeaderDataService,
                private http: HttpClient,
                public notification: NotificationService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.headerDataService.title = 'Validate Component Templates';

        this.dataSource = new MatTableDataSource();
        this.dataSource.paginator = this.paginator;

        this.loadFilters();

        combineLatest([this.summary$, this.selected_codes$]).pipe(takeUntil(this.onDestroy)).subscribe((r) => {
            const summary = r[0] as InvalidGraphSummary;
            const code_filters = r[1] as number[];
            if (code_filters.length === 0) {
                ctrl.dataSource.data = summary.entries;
            } else {
                const entries = summary.entries.filter(e => {
                    const entry_codes = new Set(e.error_messages.map(m => m.code));
                    return !!code_filters.find(f => entry_codes.has(f));
                });
                ctrl.dataSource.data = entries;
            }
            ctrl.dataSource.paginator = ctrl.paginator;

        });

        this.headerDataService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            ctrl.refreshSummary();
        });

        ctrl.refreshSummary();
    }

    private loadFilters() {
        this.http.get('/api/utils/component_template_message_filters').subscribe((response: MessageFilter[]) => {
            this.message_filters.next(response);
        }, error => {
            this.notification.openError("Failed to load filters. Please see the console.");
            console.error("Failed to load filters:\n", error);
        });
    }

    private refreshSummary() {
        const ctrl = this;

        this.http.get('/api/utils/validate_component_templates').subscribe((response: InvalidGraphSummary) => {
            ctrl.summary = response;
            this.summary$.next(ctrl.summary);
        }, error => {
            this.notification.openError("Failed to load the summary. Please see the console.");
            console.error("Failed to load the summary:\n", error);
        });

    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

    codeFilterChange(event: MatSelectChange) {
        const values: number[] = event.value;
        this.selected_codes$.next(values);
    }
}

export class MessageFilter {
    code: number;
    message: string;
}

export class ErrorMessageObj {
    code: number;
    message: string;
}

export class InvalidGraphSummary {
    event_type: { [id: string]: { id: string, name: string, } };
    component_type: { [id: string]: { id: string, name: string, } };
    constant_properties: { [id: string]: { id: string, name: string, } };

    entries: InvalidGraphNodeSummary[];
}

export class InvalidGraphNodeSummary {
    type: 'component_type' | 'event_type';
    type_id: string;
    property_id: string;
    error_messages: ErrorMessageObj[];
}
