<style>
  .icon {
    background-color: unset !important;
    width: 16px !important;
    height: 16px !important;
  }
</style>

<div #pivotAnchor (window:resize)="buildPivot()" class="wire-pivot"
     [ngClass]="{'hide-pivot-totals-columns': config.hide_totals_columns,'hide-pivot-totals-rows':config.hide_totals_rows}">
</div>
