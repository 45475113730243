import {Injectable} from '@angular/core';
import {CONSTANT_PROPERTY_RELATED_TYPE} from "./constant-property-table-form.component";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {EventType} from "../../_models/event-type";
import {BehaviorSubject, forkJoin, Observable, of, Subject, Subscription} from "rxjs";
import {concatMap, last, switchMap, takeUntil, tap} from "rxjs/operators";
import {ApiService} from '../../services/api/api.service';
import {ComponentType} from "../../_models/component-type";
import {ConstantProperty} from "../../_models/constant-property";
import * as utils from "../../lib/utils";
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {getRelationWithAttributeFilter} from "../../services/api/filter_utils";

@Injectable({
    providedIn: 'root'
})
export class ConstantPropertyTableFormService {
    private readonly onDestroy = new Subject<void>();

    ct_constant_properties_list: { id: any, type: ComponentType; constant_property: ConstantProperty }[] = [];
    et_constant_properties_list: { id: any, type: EventType; constant_property: ConstantProperty }[] = [];
    ct_constants_list: { id: any, type: ComponentType; constant_property: ConstantProperty }[] = [];

    constant_properties_list: ConstantProperty[] = [];
    ob_type_constant_properties_list: ConstantProperty[] = [];

    constant_property_types = ['string', 'float', 'datetime', 'calculation'];
    $lists_subscription: Subscription;
    $builder_ready = new BehaviorSubject(false)

    constructor(private api: ApiService,
                private relMapping: RelationshipApiMappingService,
                private propData: ConstantPropertyDataService) {
    }

    getConstantPropertiesForParent(parent: CONSTANT_PROPERTY_RELATED_TYPE) {
        //parent can be component_type, event_type, ore_body_type
        const options = new SearchQueryOptions();
        options.filters = [{
            name: parent.type + 's',
            op: 'any',
            val: {name: 'id', op: 'eq', val: parent.id}
        }];
        return options.filters;
    }

    getConstantPropertiesForParentReverseFilter(parent: CONSTANT_PROPERTY_RELATED_TYPE) {
        //parent can be component_type, event_type, ore_body_type
        const options = new SearchQueryOptions();
        options.filters = [{
            not: {
                name: parent.type + 's',
                op: 'any',
                val: {name: 'id', op: 'eq', val: parent.id}
            }
        }];
        return options.filters;
    }

    refreshPropertyQuery(parent: CONSTANT_PROPERTY_RELATED_TYPE) {
        return this.getConstantPropertiesForParent(parent);
    }

    updateSearchFilter(parent: CONSTANT_PROPERTY_RELATED_TYPE, filter_string: string) {
        const filters = this.refreshPropertyQuery(parent);
        const atts = ['name', 'description', 'formula']
        const string_filters = {or: []};
        atts.forEach(att => {
            string_filters.or.push({op: 'ilike', name: att, val: '%' + filter_string + '%'});
        })

        filters.push(string_filters)
        return filters;
    }

    getConstantPropertyParentTypes(parent: CONSTANT_PROPERTY_RELATED_TYPE): Observable<any> {
        /**This is mapped out so that the apis used are easier to find in future**/
        let model = 'constant_property_component_type';
        if (parent.type === 'ore_body_type') {
            model = 'ore_body_type_constant_property';
        } else if (parent.type === 'event_type') {
            model = 'constant_property_event_type';
        }
        const options = new SearchQueryOptions();
        options.filters = [getRelationWithAttributeFilter(parent.type, parent.id, 'id')];
        return this.api[model].searchMany(options);
    }

    getParentConstantPropertyRelationshipObject(parent: CONSTANT_PROPERTY_RELATED_TYPE, constant_property: ConstantProperty): any {
        const model = parent.type === 'ore_body_type' ? 'ore_body_type_constant_property' : 'constant_property_' + parent.type;
        let obj = this.relMapping.getRelationshipsToAdd([constant_property], model, parent.type, parent.id, 'constant_property');
        return obj[0];
    }

    /** Formula builder select lists **/
    loadFormulaConstantPropertyLists(parent: CONSTANT_PROPERTY_RELATED_TYPE, selected_list) {
        //I'm not sure what this is for TODO
        if (parent.type !== 'event_type' && parent.type !== 'component_type') {
            this.constant_property_types.filter(type => type !== 'calculation');
        }
        //Get the constant properties that are directly on the parent
        this.constant_properties_list = selected_list;
        let $obs = [];
        if (parent.type === 'event_type') {
            $obs.push(this.getFormulaComponentTypeProperties()); //Only equipment right now
            this.ob_type_constant_properties_list = this.getFormulaOreBodyConstantPropertiesList(parent);
        } else if (parent.type === 'component_type') {
            $obs.push(this.getFormulaEventTypeProperties(parent)); //Only equipment right now
            $obs.push(this.getFormulaComponentTypeConstants(parent));
        } else {
            this.$builder_ready.next(true);
        }
        this.$lists_subscription = utils.refreshSubscription(this.$lists_subscription);
        this.$lists_subscription = forkJoin($obs).pipe(takeUntil(this.onDestroy)).subscribe(() => {
            // this.et_constant_properties_list = this.et_constant_properties_list;
            // this.ct_constant_properties_list = this.ct_constant_properties_list;
            // this.ct_constants_list = this.ct_constants_list;
            this.$builder_ready.next(true);
        })
    }

    getFormulaOreBodyConstantPropertiesList(parent: CONSTANT_PROPERTY_RELATED_TYPE) {
        const ob_type_constant_properties_list = [];
        if (parent.type === 'event_type') {
            const options = new SearchQueryOptions();
            options.filters = [{
                name: 'ore_body_types', op: 'any', val:
                    {op: 'any', name: 'event_types', val: {name: 'id', op: 'eq', val: parent.id}}

            }];
            this.api.constant_property_light.searchMany(options).pipe(tap(result => {
                const cp_list = result.data;
                //Used by formula builder
                const et = parent as EventType;
                cp_list.forEach(cp => {
                    et.relationships.ore_body_types.data.forEach(ore_body_type => {
                        if (!this.ob_type_constant_properties_list.includes(cp)) {
                            ob_type_constant_properties_list.push(cp);
                        }
                    })
                })
                return ob_type_constant_properties_list;
            }))

        } else {
            return [];
        }
    }

    getFormulaComponentTypeProperties(): Observable<any> {
        //Gets all equipment types with constant properties
        let cp_list: ConstantProperty[];
        const options = new SearchQueryOptions();
        options.filters = [{
            and: [
                {op: 'eq', name: 'base_type', val: 'equipment'},
                {op: 'ne', name: 'constant_properties', val: null}
            ]
        }]
        const $component_types = this.api.component_type.searchMany(options);

        const cp_options = new SearchQueryOptions();
        cp_options.filters = [{
            name: 'component_types', op: 'any', val: {
                and: [
                    {op: 'eq', name: 'base_type', val: 'equipment'},
                    {op: 'ne', name: 'constant_properties', val: null}
                ]
            }
        }]
        const $props = this.api.constant_property_light.searchMany(cp_options).pipe(tap(cps => {
            cp_list = cps.data;
        }));
        return forkJoin([$component_types, $props]).pipe(tap(result => {
            const component_types_list = result[0].data;
            this.ct_constant_properties_list = [];
            component_types_list.forEach(ct => {
                cp_list.forEach(cp => {
                    if (!this.ct_constant_properties_list?.map(item => item.id).includes(ct.id + cp.id)) {
                        this.ct_constant_properties_list.push({
                            id: ct.id + cp.id,
                            type: ct,
                            constant_property: cp_list.find(full => full.id === cp.id)
                        })
                    }
                })
            })
        }))
    }

    getFormulaEventTypeProperties(parent: CONSTANT_PROPERTY_RELATED_TYPE): Observable<any> {
        if (!parent.relationships || parent.relationships['event_types']?.data?.length < 1) return of([]);
        // Gets all event types associated with this component_type, that have constant properties
        const event_type_ids = parent.relationships['event_types']?.data?.map(item => item.id);
        let event_types_list;
        let cp_list: ConstantProperty[];
        const options = new SearchQueryOptions();
        options.filters = [{
            and: [{name: 'id', op: 'in', val: event_type_ids || []},
                {op: 'ne', name: 'constant_properties', val: null}
            ]
        }];
        const $event_types = this.api.event_type.searchMany(options).pipe(concatMap(result => {
            event_types_list = result.data;
            return this.propData.streamPropertiesByRelationshipIds(event_types_list.map(et => et.id), 'event_types').pipe(
                last(),
                tap(prop_result => {
                    event_types_list.forEach(et => {
                        prop_result.props_list_dict[et.id].forEach(cp_id => {
                            const cp = prop_result.cp_dict[cp_id];
                            if (!this.et_constant_properties_list?.map(item => item.id).includes(et.id + cp.id)) {
                                this.et_constant_properties_list.push({
                                    id: et.id + cp.id,
                                    type: et,
                                    constant_property: cp
                                })
                            }
                        })
                    })
                })
            )
        }))
        return $event_types;
    }

    getFormulaComponentTypeConstants(parent): Observable<any> {
        let cp_list = [];
        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'component_types', op: 'any', val: {
                name: 'component_type_constants', op: 'ne', val: null
            }
        }];
        return this.api.constant_property_light.searchMany(options).pipe(switchMap(result => {
            cp_list = result.data;
            options.filters = [{name: 'component_type', op: 'has', val: {name: 'id', op: 'eq', val: parent.id}}];
            return this.api.component_type_constant.searchMany()
                .pipe(tap(result => {
                    let constants_list = result.data;
                    this.ct_constants_list = [];
                    constants_list.forEach(constant => {
                        const cp = cp_list.find(full => full.id === constant.relationships.component_type.data.id);
                        if (cp && !this.ct_constants_list?.map(item => item.id).includes(constant.id)) {
                            this.ct_constants_list.push({
                                id: constant.id,
                                type: parent,
                                constant_property: cp_list.find(
                                    full => full.id === constant.relationships.constant_property.data.id)
                            })
                        }
                    })
                    return result;
                }))
        }))
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
