  <select-search-component
      [api_model]="'event_type_light'"
      [value]="eventTypeID"
      [compareFunctionName]="'compareValueToId'"
      (selectionChange)="setEventType($event.value.id)"
      [disabled]="readonly"
      [classes]="'fill'"
      [filter_by]="['name']"
      [label]="'Select Event Type'"
      [noItemsAvailableMessage]="'No results. Please create at least one Event Type to use this upload type.'"
  ></select-search-component>
