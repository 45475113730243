import {ApiService} from './api/api.service';
import {Injectable} from '@angular/core';
import {AppScope} from './app_scope.service';
import {Observable, of} from "rxjs";
import {SessionState} from "../_models/session-state";
import {SearchQueryOptions} from "./api/search-query-options";
import { HttpClient, HttpStatusCode } from "@angular/common/http";
import {catchError, concatMap, tap} from "rxjs/operators";
import {KeycloakService} from "keycloak-angular";
import {NotificationService} from "./notification.service";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class UserData {
    report;
    dashboards = [];

    constructor(private appScope: AppScope,
                private api: ApiService,
                private http: HttpClient,
                private notification: NotificationService,
                protected readonly keycloak: KeycloakService) {
    }

    checkSuperUser(user) {
        return user.role_names.indexOf('Super_User') > -1;
    }

    get current_user(): any {
        return this.appScope.current_user;
    }

    getRestrictedDashboards(user?): Observable<any> {
        let restricted_list;
        if (user) {
            restricted_list = user.relationships.dashboards.data.map(d => d.id);
        } else {
            restricted_list = this.appScope.current_user.dashboards;
        }

        const query = new SearchQueryOptions();
        query.filters = [
            {'op': 'in', 'name': 'id', val: restricted_list}
        ];

        return this.api.session_state_light.searchMany(query);
    }

    /**
     * Get the list of restricted dashboard ids for the current or given user.
     * @param session_states
     * @param user
     */
    getRestrictedDashboardsQuick(session_states: SessionState[], user?): SessionState[] {
        let restricted_list;
        if (user) {
            restricted_list = user.relationships.dashboards.data.map(d => d.id);
        } else {
            restricted_list = this.appScope.current_user.dashboards;
        }

        return session_states.filter(state => !!restricted_list.find(item => item === state.id));
    }

    // TODO to be removed after keycloak migration
    async redirectToKeyCloak(userDetail: string) {
        const domain = window.location.origin;
        const redirectUri = domain + (this.appScope.keycloak_redirect || this.appScope.route_before_login || '/');
        await this.keycloak.login({loginHint: userDetail, redirectUri: redirectUri});
        return this.appScope.isKeycloakUser;
    }

    getUserMigrationStatus(userDetail: string): Observable<{ migrated: boolean }> {
        return this.http.get(`/auth/KeycloakUserStatus/${userDetail}`).pipe(
            concatMap((response: { status: boolean }) => {
                if (response.status) {
                    // FIXME why is a query function also setting state?
                    this.appScope.isKeycloakUser = response.status;
                    return fromPromise(this.redirectToKeyCloak(userDetail)).pipe(
                        concatMap(() => {
                            return of({migrated: true});
                        }));
                } else {
                    return of({migrated: false});
                }
            }),
            catchError(err => {
                if (err.status === HttpStatusCode.TooManyRequests) {
                    this.notification.openError("Please wait a few minutes before retrying.");
                } else if (err.status === HttpStatusCode.Forbidden) {
                    this.appScope.userName = userDetail;
                    this.appScope.noticeBoardOpenSubject.next(true);
                }
                return of({migrated: false});
            })
        );
    }

    attemptLogin(vars) {
        return this.http.post('/auth/login', vars).pipe(tap((response) => {
            this.appScope.current_user = response;

            if (this.appScope.route_before_login) {
                window.location.href = this.appScope.route_before_login;
            } else {
                window.location.href = response['default_dashboard'];
            }
        }), catchError(err => {
            console.log(err);
            console.log('login failed', err);
            if (err.error.error === 'OTP required') {
                console.log('OTP required', err.error);
                this.notification.openError('Please enter your OTP');
                throw ({requiresOTP: true});
            } else if (err.status === HttpStatusCode.TooManyRequests) {
                this.notification.openError('Please wait a few minutes before retrying');
            } else {
                this.notification.openError(`Username or password is not valid`);
            }
            throw ({requiresOTP: false});
        }));
    }
}
