<div class="row process-access-account-filter">
  <account-filter (accountFilterEmitter)="getAccountIds($event)"
                  [allowedAccountIds]="allowedAccountIds"
                  [initialAccountIds]="accountIds"
                  [multiple]="true"
                  label="Filter by accounts"
                  class="row flex-third alone">
  </account-filter>
</div>

<div class="row" style="flex-wrap:wrap;">
  <div *ngFor="let config of process_access_features" class="row flex-third alone">
    <user-form-process-access-feature [accountIds]="accountIds"
                                      [config]="config"
                                      [user]="user"
                                      (onChange)="setCanSave($event)"
                                      class="full-width">
    </user-form-process-access-feature>
  </div>
</div>
