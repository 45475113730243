import {Directive, ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
    selector: '[notifications]',
    standalone: false
})
export class NotificationsDirective implements OnInit {
    url_includes = ['.test', '.qa', '-qa'];

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.getNotifications();
    }

    getNotifications() {
        if (this.url_includes.some(v => window.location.hostname.toLowerCase().includes(v))) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'block');
        }
    }

}
