import { Component } from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import { HttpClient } from "@angular/common/http";
import {tap} from "rxjs/operators";
import {KeycloakService} from "keycloak-angular";
import {interval, Observable, Subscription} from "rxjs";

@Component({
    selector: 'notice-board',
    templateUrl: './notice-board.component.html',
    styleUrls: ['./notice-board.component.less'],
    standalone: false
})
export class NoticeBoardComponent {
  private intervalId: Subscription;

  targetDateString: string;
  targetDate: Date;
  displayDateTime: string;
  action: string = 'Update credentials';
  nextReminder: number = 24;
  timeRemaining: number;
  timerDisplay: number;
  accepted: boolean;

  constructor(public appScope: AppScope, private http: HttpClient, private keycloak: KeycloakService) {}

  noreplyEmail: string = 'noreply@metalmanagementsolutions.com';
  serviceDeskEmail: string = 'servicedesk@metalmanagementsolutions.com';
  ngOnInit(): void {
    this.accepted = false;
    this.http.get('/auth/getKeycloakMigrationDate').subscribe((response: {'date': string}): void => {
      const yearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      this.targetDateString = response.date;
      this.targetDate = this.targetDateString ? new Date(this.targetDateString) : yearFromNow;
      this.displayDateTime = this.targetDate.toLocaleDateString();
      const currentDate: Date = new Date();

      this.calculateTimeRemaining();
      this.intervalId = interval(1000).pipe(tap(() => {
           this.calculateTimeRemaining();
      })).subscribe();

      if (this.appScope.noticeBoardReminder) {
        if (this.appScope.noticeBoardReminder >= currentDate) {
          this.appScope.noticeBoardOpenSubject.next(true);
        } else {
          this.appScope.noticeBoardOpenSubject.next(false);
        }
      }
    });
  }

  onAccept(): void {
    this.accepted = true;
    this.countdownTimer(1);
  }

  redirectUser(): Observable<any> {
    let userIdentifier: string;
    if (this.appScope.current_user) {
        userIdentifier = this.appScope.current_user.id;
    } else {
        userIdentifier = this.appScope.userName;
    }
    return this.http.get(`/auth/EnableKeycloak/${userIdentifier}`).pipe(
        tap((response: any) => {
          this.http.get('/auth/logout').subscribe();
          this.keycloak.login({loginHint: userIdentifier, redirectUri: this.appScope.keycloak_redirect});
        }));
  }

  onCancel(): void {
    this.appScope.setNoticeBoardCountdown(this.nextReminder);
    this.appScope.noticeBoardOpenSubject.next(false);
  }

  calculateTimeRemaining(): void {
    const now: Date = new Date();
    const difference: number = this.targetDate.getTime() - now.getTime();
    this.timeRemaining = Math.max(0, difference);
  }

  formatTime(): string {
    const days: number = Math.floor(this.timeRemaining / 1000 / 60 / 60 / 24);
    if (days < 1) {
      const minutes: number = Math.floor(this.timeRemaining / 1000 / 60) % 60;
      const hours: number = Math.floor(this.timeRemaining / 1000 / 60 / 60) % 24;
      return `${days} days ${hours} hours ${minutes} minutes`;
    }
    return `${days} days`;
  }

  countdownTimer(minute: number = 1 ) {
    let seconds: number = 30;
    // let seconds: number = minute * 60;
    const timer: Subscription = interval(1000).pipe(tap( () => {
      seconds--;
      this.timerDisplay = seconds;

      if (seconds === 0) {
        this.accepted = true;
        this.redirectUser().subscribe();
        timer.unsubscribe();
      }
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.intervalId.unsubscribe();
  }
}
