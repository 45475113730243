import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {SearchQueryOptions} from "./search-query-options";
import {ListResponse} from "./response-types";
import {debounceTime, map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {PaginationDataSource, PaginationEndpoint} from "./pagination-data-source";

// Extension of PaginationDataSource used for mat tables with create new rows feature.
export class PaginationDataSourceWithAddRow<T> extends PaginationDataSource<T> {
    private tempRows$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private $_page: Observable<ListResponse<T>>;

    constructor(endpoint: PaginationEndpoint<T, SearchQueryOptions>,
        initialQuery: SearchQueryOptions,
        paginator: MatPaginator,
        sort?: MatSort
    ) {
        super(endpoint, initialQuery, paginator, sort);

        this.$_page = this.$searchQueryOptions.pipe(
            debounceTime(200),
            switchMap((query: SearchQueryOptions) => endpoint(query)),
            tap(() => {
                // when refetching data we should clear temp rows
                this.tempRows$.next([]);
            }),
            // shareReplay(1) // not sure needed
        );

        this.$page = combineLatest([this.tempRows$, this.$_page]).pipe(
            debounceTime(200),
            map(([tempRows, originalPage]: [any, any]) => {
                // we merge the paginated api data with tempRows
                return { ...originalPage, data: [...tempRows, ...originalPage.data]};
            }),
            shareReplay(1)
        );
    }

    addRow(newRow: T) {
        this.tempRows$.pipe(take(1)).subscribe(tempRows => {
            this.tempRows$.next([newRow, ...tempRows]);
        });
    }

    clearTempRows() {
        this.tempRows$.next([]);
    }
}
