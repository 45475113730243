import {Injectable} from '@angular/core';
import {take, takeUntil, tap, shareReplay, first} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {Event as WireEvent} from "../../_models/event";
import * as utils from "../../lib/utils";
import {forkJoin, Subject} from "rxjs";
import {Series} from '../../_models/series';
import {ApiService} from '../../services/api/api.service';
import {EventService} from "../../services/event.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";

@Injectable({
    providedIn: 'root'
})
export class SeriesAuditHistoryService {
    private onDestroy: Subject<void> = new Subject();

    constructor(private api: ApiService,
                private eventService: EventService) {
    }

    $commentsSubject = new Subject<ListResponse<WireEvent>>();
    $comments = this.$commentsSubject.asObservable();
    comments: WireEvent[];

    $auditHistorySubject = new Subject<any[]>();
    $auditHistoryData = this.$auditHistorySubject.asObservable();

    onSeriesChanged(series: Series, dtp: IDateTimePeriod, table_view = true) {
        forkJoin(this.getSeriesAuditHistoryRequest(series, dtp), this.getCommentsRequest(series, dtp))
            .pipe(first(), takeUntil(this.onDestroy))
            .subscribe(([audit_data, comment_data]) => {
                if (!audit_data[series.id]) {
                    return;
                }
                const audit_history_data = this.processSeriesAuditHistoryData(audit_data[series.id]);
                this.$auditHistorySubject.next(audit_history_data);
            })
    }


    private getSeriesAuditHistoryRequest(series: Series, dtp: IDateTimePeriod) {
        let params = {
            series_id: series.id,
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
        }
        return this.api.get('/api/seriesAuditHistory?' + utils.httpParamSerializer(params));
    }

    private getCommentsRequest(series: Series, dtp: IDateTimePeriod) {
        return this.eventService.getEventsByBaseType('event', dtp.start, dtp.end, [series])
            .pipe(first(), takeUntil(this.onDestroy),
                tap((comments: ListResponse<WireEvent>) => {
                    this.comments = comments.data;
                }));
    }

    private processSeriesAuditHistoryData(times, table_view = true) {
        if (table_view === true) {
            let data: any = [];
            Object.keys(times).map(key => {
                for (let index = 0; index < times[key].length; index++) {
                    let item = times[key][index];
                    item['timestamp'] = key;
                    item['comments'] = this.getItemComments(item['changed_on']);
                    data.push(item);
                }
            });
            return data;
        } else {
            let paged_history = [];
            Object.keys(times).forEach(time => {
                paged_history.push(times[time]);
            });
            return this.getPaged(paged_history)
        }
    }

    private getItemComments(changed_on) {
        return this.comments.filter(c => c.attributes.start <= changed_on && c.attributes.end >= changed_on)
            .map(comment => comment.attributes.comment);
    }

    private getPaged(paged_history) {
        const ctrl = this;
        for (let i = 0; i < paged_history.length; i++) {
            let item = paged_history[i][0];
            let prev = paged_history[i - 1];
            if (prev) {
                prev = prev[0];
            }
            let str = '';
            if (!prev && item.value) {
                str = "Added <b>" + item.value + "</b>";
            }
            if (prev && prev.value && item.value) {
                str = "Replaced <b>" + prev.value + "</b> with <b>" + item.value + "</b>";
            }
            if (prev && prev.value && !item.value) {
                str = "Deleted <b>" + prev.value + "</b>";
            }
            item['value_string'] = str;
        }
        return paged_history;
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }
}
