import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api/api.service";
import {EditFlowchartComponent} from "../edit_component";
import {ComponentConstantsTableService} from "../../tables/component-constants-table/component-constants-table.service";
import {BaseComponent} from "../../shared/base.component";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'flowchart-equipment-form',
    templateUrl: 'flowchart-equipment-form.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ComponentConstantsTableService],
    standalone: false
})

export class FlowchartEquipmentFormComponent extends BaseComponent {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event: EventEmitter<any> = new EventEmitter();
    @Output() close_event: EventEmitter<any> = new EventEmitter();

    efc: EditFlowchartComponent;
    hint: string;
    showing_hints: any;

    constructor(private plantData: PlantDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private http: HttpClient,
                private componentConstantTableService: ComponentConstantsTableService) {
        super();
    }

    ngOnInit(): void {
        this.efc = new EditFlowchartComponent(this.flowchart, this.component, this.plantData, this.seriesData, this.api,
            this.http, this.componentConstantTableService);
        this.efc.loadForm();
        this.efc.saveComplete.pipe(takeUntil(this.onDestroy)).subscribe((saved: boolean) => {
            this.save_event.emit(this.efc.component_view);
        });
    }

    save(): void {
        this.efc.save();
    }

    close(): void {
        this.close_event.emit();
    }
}
