import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {MatSelectChange} from "@angular/material/select";
import * as utils from "../../../lib/utils";
import {ApiService} from "../../../services/api/api.service";
import {SearchQueryOptions} from '../../../services/api/search-query-options';
import {SingleResponse} from '../../../services/api/response-types';
import {ModelID} from '../../../_typing/generic-types';
import {AppScope} from "../../../services/app_scope.service";
import {getAccountFilter, getRelationWithManyIdsFilter} from "../../../services/api/filter_utils";

/**
 * SelectSearchComponentComponent
 *
 * A paginated, searchable mat-form-field with mat-select. Pass in the api to use and any custom filters
 *
 * **Inputs:**
 * - `classes`: A string of classes to add to mat-form-field
 * - `label`: A label string for the mat-form-field. Add 'no-label' to the classes string for no-label styling
 * - `custom_filters`: An array of custom filter objects for the selected api_model
 * - `api_model`: The API model to query
 * - `value`: The currently selected value
 * - add more here
 *
 * **Outputs:**
 * - `selectionChange`: access the model selected with $event.value
 */
@Component({
    selector: 'select-search-component',
    templateUrl: 'select-search-component.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SelectSearchComponentComponent implements OnInit, OnDestroy {

    /**A string of classes to add to mat-form-field**/
    @Input() classes: string;
    @Input() label: string = '';
    @Input() custom_filters: any[];
    @Input() sort_by: string = 'name';
    @Input() filter_by: string[] = ['name', 'description'];
    @Input() openOnInit: boolean = false;
    @Input() accountId: ModelID;
    @Input() allowAccountSelection: boolean = true;
    @Input() allowedAccountIds: ModelID[]; //Used by option-list-search > account-filter
    private _value: any;
    @Input() set value(value: any) {
        this._value = value;
        this.loadSelected();
    }

    get value(): any {
        return this._value;
    }

    @Input() allow_none: boolean = false;
    @Input() noItemsAvailableMessage: string = "";

    @Input() action: { icon_class: string };
    @Input() allow_navigate: boolean = true;
    @Input() selector: 'search-api';
    @Input() api_model: any = 'component';
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() component_types: String[];
    @Input() compareFunctionName;
    /**Defaults for these functions are set in the select-search-api.service class**/
    @Input() stringFunction;
    @Input() valueFunction;

    @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
    @Output() closeWithoutChange: EventEmitter<any> = new EventEmitter();
    @Output() showUpdate: EventEmitter<null> = new EventEmitter();
    @Output() actionClicked: EventEmitter<any> = new EventEmitter();

    private onDestroy: Subject<void> = new Subject();
    public init_value;
    page_size: number = 10;
    //Tracks account ids selected by the user in the dropdown, as opposed to the account_id which is passed in by the component
    private accountIds: ModelID[];

    constructor(private api: ApiService,
                private changeDetection: ChangeDetectorRef,
                private appScope: AppScope) {
    }

    ngOnInit(): void {
        if (!this.accountId) {
            this.accountId = this.appScope.active_account_id;
        }
    }

    loadSelected(): void {
        /**If the current value is just an id, get the actual object so that it can be added to the initial list
         * of options for the dropdown list - this is necessary for mat-select to bind (show) the current value.
         * This only seems to be necessary when using a custom compareWith function??
         */
        if (this.value && !this.value.attributes && (utils.isGuid(this.value) || (this.value.id && utils.isGuid(this.value.id)))) {
            const id = this.value.id || this.value;
            this.api[this.api_model].getById(id).pipe(
                take(1),
                tap((result: SingleResponse<any>) => {
                    if (result) {
                        console.log("result",result.data);
                        this.init_value = result.data;
                    }
                })
            ).subscribe(() => this.changeDetection.markForCheck());
        }
    }

    fetchData = (page_number, filterValue): Observable<any> => {
        const options: SearchQueryOptions = new SearchQueryOptions();
        options.page_number = page_number;
        options.page_size = this.page_size;
        options.sort = this.sort_by;
        let filters: any[] = [];

        if (this.custom_filters) {
            filters = utils.deepCopy(this.custom_filters);
        } else {
            if (this.component_types && this.component_types.length) {
                filters.push({
                    "name": "component_type",
                    "op": "has",
                    "val": {"name": "id", "op": "in", "val": this.component_types}
                });
            }
        }
        if (this.accountIds) {
            filters.push(getRelationWithManyIdsFilter('account', this.accountIds));
        } else if (this.accountId) {
            filters.push(getAccountFilter(this.accountId));
        }

        if (filterValue) {
            const att_filters = {or: []};
            this.filter_by.forEach(f => {
                att_filters.or.push({name: f, op: "ilike", val: `%${filterValue}%`});
            });
            filters = filters.concat(att_filters);
        }

        if (filters.length) {
            options.filters = filters;
        }
        return this.api[this.api_model].searchMany(options).pipe(map(res => {
            return res;
        }));
    }

    filterByAccount($event) {
        this.accountIds = $event?.map(a => a.id);
    }

    emitEvent(event): void {
        this.selectionChange.emit(event);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
