<div class="row center">
  <select-many-filter
      [label]="'Estimate type'"
      (constant_changed)="updateColumns($event)"
      [modelValue]="config.estimate_type"
      [disabled]="disabled"
      [options]="estimate_types" class="flex-half"
      [classes]="'fill'"
  >
  </select-many-filter>
  <div class="row center flex-half form-element-align">
    <mat-checkbox style="min-width:50%;" [disabled]="disabled"
                  [(ngModel)]="config.bypass_estimate_formatting" [ngModelOptions]="{standalone:true}">Bypass estimate
      favourability formatting
    </mat-checkbox>
    <mat-checkbox style="min-width:50%;" [disabled]="disabled"
                  [(ngModel)]="config.show_comments" [ngModelOptions]="{standalone:true}">Show comments
    </mat-checkbox>
  </div>
</div>
<div class="row end" style="width:50%;">
  <select-search-component
      [label]="'Shift type'"
      [api_model]="'shift_type'"
      (selectionChange)="shiftTypeChanged($event)"
      [value]="config.shift_type_id" class="select-search"
      [compareFunctionName]="'compareValueToId'"
      [allow_none]="true"
      [classes]="'fill'"
  >
  </select-search-component>
</div>
<div class="row center">

</div>

<div class="row column-selector" *ngIf="all_columns">
  <div class="draggable-list-container">
    <h2>Selected Columns</h2>
    <option-list-search
        [placeholder]="'Search columns'"
        (inputChanged)="selectedColumnFilterValue=$event"
        (inputCleared)="selectedColumnFilterValue=''"
    ></option-list-search>
    <div #selectedList="cdkDropList"
         (cdkDropListDropped)="drop($event)"
         [cdkDropListConnectedTo]="[availableList]"
         [cdkDropListData]="config.columns"
         [cdkDropListDisabled]="disabled"
         cdkDropList
         class="draggable-list">

      <div *ngFor="let item of config.columns | filterByValue: columnFilter.bind(this) : selectedColumnFilterValue"
           cdkDrag
           class="draggable-list-item" style="display:flex; justify-content: space-between; padding:5px 10px;">
        <div [ngStyle]="{'font-weight': config.column_formats[item]?.bold? 'bold' : '',
          'font-style': config.column_formats[item]?.italic? 'italic' : '',
          'font-size': config.column_formats[item]?.size ? config.column_formats[item]?.size + '%' : '100%'}"
             matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
        >{{col_dict[item] ? col_dict[item].title : item + 'had no key'}}
        </div>
        <div class="row center">
          <mat-form-field *ngIf="config.estimate_type && allowReverseFavourability && shouldShowFavourabilityEstimateSelect(item)"
                          class="no-label"
                          matTooltip="Show favourability formatting against the selected estimate type">
            <mat-label></mat-label>
            <mat-select [(value)]="config.actuals_favourability_estimate_dict[item]"
                        [disabled]="disabled || !config.estimate_type"
                        placeholder="Show favourability against...">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let estimate of config.estimate_type"
                          [value]="estimate">
                {{estimate | upperfirst}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button [matMenuTriggerFor]="columnMenu" class="formatting-button" mat-button
                    matTooltip="Format column" matTooltipPosition="above" style="margin:0;background-color:#8b8c8e;">
              <i class="fa fa-bars"></i>
            </button>

            <mat-menu #columnMenu="matMenu" class="column-menu dark series-formatting-menu">
              <table-column-menu [item]="config.column_formats[item]"
                                 [options]="col_menu_options"
                                 (formatsChanged)="config.column_formats[item]=$event"></table-column-menu>

            </mat-menu>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="draggable-list-container">
    <h2 >Available Columns</h2>
    <option-list-search
        [placeholder]="'Search columns'"
        (inputChanged)="availableColumnFilterValue=$event"
        (inputCleared)="availableColumnFilterValue=''"
    ></option-list-search>
    <div
        #availableList="cdkDropList"
        [cdkDropListDisabled]="disabled"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[selectedList]"
        [cdkDropListData]="all_columns.all"
        cdkDropList
        class="draggable-list">
      <div *ngFor="let item of all_columns.all | filterByValue: columnFilter.bind(this) : availableColumnFilterValue"
           cdkDrag
           matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
           class="draggable-list-item">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}
      </div>
    </div>
  </div>
</div>

<div class="row column-selector" *ngIf="all_columns">
  <div class="draggable-list-container">
    <h2>Selected Mobile Columns</h2>
    <option-list-search
        [placeholder]="'Search columns'"
        (inputChanged)="selectedMobileColumnFilterValue=$event"
        (inputCleared)="selectedMobileColumnFilterValue=''"
    ></option-list-search>
    <div
        #selectedListMobile="cdkDropList"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[availableListMobile]"
        [cdkDropListData]="config.mobile_columns"
        [disabled]="disabled"
        id="selectedMobileColumns"
        cdkDropList
        class="draggable-list">

      <div
          *ngFor="let item of config.mobile_columns | filterByValue: mobileColumnFilter.bind(this) : selectedMobileColumnFilterValue"
          cdkDrag
          matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
          class="draggable-list-item">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}
        <!--<mat-checkbox style="text-align:right" (change)="mobileSelect($event)"></mat-checkbox>-->
      </div>
    </div>
  </div>

  <div class="draggable-list-container">
    <h2>Available Mobile Columns</h2>
    <option-list-search
        [placeholder]="'Search columns'"
        (inputChanged)="availableMobileColumnFilterValue=$event"
        (inputCleared)="availableMobileColumnFilterValue=''"
    ></option-list-search>
    <div
        #availableListMobile="cdkDropList"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[selectedListMobile]"
        [cdkDropListData]="all_columns.mobile"
        [disabled]="disabled"
        id="availableMobileColumns"
        cdkDropList
        class="draggable-list">
      <div
          *ngFor="let item of all_columns.mobile | filterByValue: mobileColumnFilter.bind(this) : availableMobileColumnFilterValue"
          cdkDrag
          matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
          class="draggable-list-item">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}
      </div>
    </div>
  </div>
</div>
