/**Standardise delay time on elements where matTooltipShowDelay is set**/
export const TOOLTIP_SHOW_DELAY: number = 600;

export const POLLING_INTERVAL_MS: number = 1000;
export const MAX_POLLING_DURATION_MS: number = 10000;
// 3 SECONDS in MS = 180000
export const JOB_POLLING_INTERVAL_MS = POLLING_INTERVAL_MS * 3;
// 3 minutes in MS = 180000
export const BATCH_CORRECTION_POLLING_INTERVAL_MS = 180000;
export const JOB_MAX_NO_POLLS = 40;

export const NOTIFICATION_DURATION_MS: number = 4000;
export const EXTENDED_NOTIFICATION_DURATION_MS: number = 10000;

export const TABLE_PAGE_SIZE = 20;

export const SMALL_TABLE_PAGE_SIZE = 10;

export const TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 200];
export const ALLOWED_TAGS = [
    'style', 'body', 'div', 'table', 'tr', 'td', 'th', 'col', 'colgroup',
    'span', 'p', 'b', 'i', 'strong', 'em', 'u', 'br', 'iframe', 'img'];
export const ALLOWED_ATTR = [
    'name', 'href', 'class', 'style', 'id', 'src', 'frameborder', 'allowfullscreen',
    'border', 'cellpadding', 'cellspacing', 'width', 'height', 'colspan', 'rowspan', 'align'];
export const ALLOW_DATA_ATTR = false;
export const ALLOWED_URI_REGEXP =  /^https?:\/\/|^data:image\/.*$/

export const REPORT_COLUMN_FILTER_TRIM_LENGTH = 20;

export const WIRE_BASE_CONFIG_NAME = 'WIREBaseConfig';

export const DEFAULT_CALENDAR_NAME = 'default';

export enum FontAwesomeIcons {
    User = 'fa small fa-user',
    FloppyO = 'fa small fa-floppy-o',
    Trash = 'fa small fa-trash',
    Calculator = 'fa small fa-calculator',
    Eraser = 'fa small fa-eraser',
}

export const JOB_TYPE_BATCH_CORRECTION: string = "batchCorrection";
