import * as utils from '../../lib/utils';
import {Component, HostListener, Input, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import { HttpClient } from "@angular/common/http";
import {EventService} from "../../services/event.service";
import {HeaderDataService} from "../../services/header_data.service";
import {TileDataService} from "../../services/tile_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {memoize as _memoize} from "lodash-es";
import {catchError, concatAll, first, takeUntil, tap, toArray, concatMap, switchMap} from "rxjs/operators";
import {HttpErrorHandler} from "../../services/http-error-handler.service";
import {forkJoin, Observable, of, ReplaySubject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {BaseComponent} from '../../shared/base.component';
import {FlowchartData} from "../../_models/flowchart-data";
import {ProcessPermissions} from "../../_models/process-permissions";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {getRelationWithIdFilter} from "../../services/api/filter_utils";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {NotificationService} from "../../services/notification.service";
import {ListResponse} from "../../services/api/response-types";
import {RawData} from "../../_models/raw-data";
import {ColumnFormatsDict, TableUtilsService} from "../../tables/table-utils.service";
import {ColumnFormatsConfig} from "../../forms/table-column-menu/table-column-menu.component";
import {ILogSheetConfig} from "../../_typing/log-sheet-config";
import {DEFAULT_CALENDAR_NAME, TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {ConfigColumn} from "../../_typing/config/config-column";
import {TimePeriod} from "../../_typing/components/time-period";
import {ModelID} from "../../_typing/generic-types";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Component({
    selector: 'log-sheet',
    templateUrl: './log-sheet.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LogSheetComponent extends BaseComponent {
    shift: any;
    process: any;
    flowSheet: FlowchartData;
    editAggregation: boolean = false;
    report_groups: {};
    @Input()
    config: Partial<ILogSheetConfig>;
    series_ready: utils.FlatPromise;
    series_list_sorted: any[] = [];
    report_groups_names: string[] = [];
    series_list: any[] = [];
    dtp: IDateTimePeriod;

    series_data: any[] = [];
    dataSource: MatTableDataSource<any>;
    selection: SelectionModel<any>;

    series_list_map: {};
    raw_data_map: {};
    times: TimePeriod[] = [];
    row_headers: any[] = [];
    permissions: any;
    sample_hours: any;
    shift_starts: any[] = [];
    shift_spans: any[] = [];
    shift_names: any[] = [];
    shifts: any;
    show_shifts: boolean;
    restricted_access: boolean;
    sample_period: any;
    shift_length: any;
    columns: string[] = [];
    events: any[];
    super_user: boolean;
    calc_data: any = [];
    missing_values: any = [];
    edit_day: any;
    sheet_ready = false;
    value_error: boolean;
    raw_data_error: boolean;
    series_events: {};
    significant_numbers: boolean = true;
    changes_flag: boolean = false;

    timeColumnsCache: { [key: string]: string } = {};

    buttons: { name: string; func: any; params: {}; class: string; HoverOverHint: string; }[];
    applyCorrFac: { name: string; func: () => void; params: {}; class: string; HoverOverHint: string; };
    OverCalcs: { name: string; func: () => void; params: {}; class: string; HoverOverHint: string; };
    hovering_events: any[] = [];
    tileId: string;
    format_dict: ColumnFormatsDict = {};
    resizeable_columns: string[] = [];
    column_formats: Partial<ConfigColumn>[];
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;
    editingLayout = false;

    constructor(public appScope: AppScope,
                private api: ApiService,
                private plantData: PlantDataService,
                private http: HttpClient,
                private eventService: EventService,
                private headerData: HeaderDataService,
                private datetimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private notification: NotificationService,
                private tileDataService: TileDataService,
                private seriesDataService: SeriesDataService,
                public tableUtils: TableUtilsService,
                httpErrorHandler: HttpErrorHandler) {
        super();
        this.handleError = httpErrorHandler.createHandleError('Logsheet');
        this.tileDataService.addCommentClicked
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.saveComment(value);
            });
        this.tileDataService.commentHover
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.commentHover(value);
            });
        this.tileDataService.commentLeave
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.commentLeave(value);
            });
        this.headerData.tile_edit_mode
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.editingLayout = value;
            });
        if (this.tileDataService?.save_content) {
            this.tileDataService.save_content.pipe(takeUntil(this.onDestroy)).subscribe(params => {
                if (!this.tileDataService.tile) {
                    return;
                }
                this.tileDataService.tile.attributes.parameters.column_formats =
                    this.tableUtils.updateColumnFormats(this.config.column_formats, this.format_dict);
                //this.tableUtils.emitSaveTile(this.tileDataService);
            });
        }
    }

    ngOnInit(): void {
        this.shift = null;
        this.tileId = this.tileDataService.id;
        const $access_replay: ReplaySubject<ProcessPermissions> = new ReplaySubject<ProcessPermissions>(1);

        this.datetimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            this.dtp = this.dateInst.dtp || dtp;
            this.process = this.config.process;
            const $access: Observable<ProcessPermissions> = this.plantData.getProcessPermissions(this.process.id);

            this.series_ready = new utils.FlatPromise();
            this.tileDataService.title = this.config.title;
            this.editAggregation = false;
            this.report_groups = {};
            this.events = [];

            if (this.config.columns) {
                this.columns = this.config.columns;
            }
            if (!this.config.hidden_columns) {
                this.config.hidden_columns = [];
            }

            const $flowsheet = this.plantData.getFlowchartDataForProcess(this.process.id);
            $flowsheet.pipe(first(), takeUntil(this.onDestroy),
                concatMap((flowsheet: any) => {
                    this.flowSheet = flowsheet;
                    this.super_user = this.appScope.current_user.is_super;

                    this.shift_length = this.appScope.config_name_map.date_period.value.default_shift_length;

                    if (!this.appScope.isNotMobile) {
                        // this.datetimePeriodService.show_timespan = false;
                        this.dtp.start = utils.setToHour(new Date(), new Date().getHours() - 1);
                        this.dtp.end = utils.setToHour(new Date(), new Date().getHours());
                        this.dtp.range = 'custom';
                        this.dtp.sample_period = this.datetimePeriodService.sample_dict['hour'];
                        this.dateInst.emitDateTimePeriodChanged(this.dtp);
                    }
                    this.timeColumnsCache = {};

                    return $access.pipe(takeUntil(this.onDestroy), tap((access: ProcessPermissions) => {
                        this.refreshShifts(access);
                        this.setButtons(access);
                        $access_replay.next(access);
                    }))

                    if (this.config.process.attributes && this.config.process.attributes.name) {
                        this.tileDataService.setDefaultTitle(this.config.process.attributes.name);
                    }
                })).subscribe();
        });

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy),
            switchMap((dtp) => {
                this.dtp = dtp;
                this.timeColumnsCache = {};
                this.resetSaveAllowedMemoized();
                return $access_replay.pipe(first(), tap(access => {
                    this.refreshShifts(access);
                }))
            })).subscribe();
    }

    resetSeriesList() {
        this.report_groups = {};
        this.series_list = utils.deepCopy(this.flowSheet.selected_series);
        this.series_list.forEach(item => {
            if (this.report_groups.hasOwnProperty(item.attributes.report_group)) {
                this.report_groups[item.attributes.report_group].push(item);
            } else {
                this.report_groups[item.attributes.report_group] = [item];
            }
        });
        let is_numeric = true;
        this.report_groups_names = Object.keys(this.report_groups).sort();
        this.report_groups_names.forEach((name) => {
            if (isNaN(Number(name))) {
                is_numeric = false;
                return;
            }
        });
        if (is_numeric) {
            this.report_groups_names = Object.keys(this.report_groups).sort((a: any, b: any) => a - b);
        }
        this.series_list_sorted = [];
        this.report_groups_names.forEach((group) => {
            this.report_groups[group] = this.report_groups[group].sort((a, b) => a.attributes.series_order - b.attributes.series_order);
            this.series_list_sorted = this.series_list_sorted.concat(this.report_groups[group]);
        });

        this.series_list = this.series_list_sorted;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    showDecimals() {
        this.significant_numbers = !this.significant_numbers;
    }

    setButtons(access: ProcessPermissions) {
        this.buttons = [
            {
                name: 'Edit Aggregation',
                func: () => this.toggleAggregation(),
                params: {},
                class: 'fa small fa-wrench hide-xs',
                HoverOverHint: 'Edit aggregation'
            },
            //TODO come back to this
            {
                name: 'Decimals',
                func: () => this.showDecimals(),
                params: {},
                class: 'fa small fa-percent',
                HoverOverHint: 'Show more decimals'
            }
        ];

        if (access.permissions.override_calculations) {
            this.OverCalcs =
                {
                    name: 'Update Calculations',
                    func: () => this.overrideCalculations(access),
                    params: {},
                    class: 'fa small fa-calculator hide-xs',
                    HoverOverHint: 'Update calculations'
                };

            this.buttons.unshift(this.OverCalcs);
        }

        if (access.permissions.apply_correction_factor) {
            this.applyCorrFac = {
                name: 'Apply Correction Factor',
                func: () => this.correctionFactor(),
                params: {},
                class: 'fa small fa-eraser hide-xs',
                HoverOverHint: 'Apply correction factor'
            };
            this.buttons.unshift(this.applyCorrFac);
        }
        this.tileDataService.buttonsChanged.next(this.buttons);
    }

    correctionFactor() {
        const dialogRef = this.headerData.correctionFactor();
        dialogRef.afterClosed().pipe(tap(response => {
                this.getInputData();
            },
        )).subscribe();
    }

    refreshShifts(access) {
        this.sample_period = this.dtp.sample_period;
        this.shifts = [];
        const query = new SearchQueryOptions();
        query.filters = [{
            and: [{
                name: 'end',
                op: 'gt',
                val: this.dtp.start
            }, {
                name: 'start',
                op: 'lt',
                val: this.dtp.end
            }]
        }];
        query.sort = 'start';
        this.api.shift.searchMany(query).pipe(takeUntil(this.onDestroy)).subscribe(response => {
            this.shifts = response.data;
            if (!this.shifts) {
            }
            if (this.shifts.length > 0) { // 1,2,4,(6),(8),(12)
                this.shifts = this.shifts.sort((a, b) => {
                    return new Date(a.attributes.start).valueOf() - new Date(b.attributes.start).valueOf();
                });
            }

            this.loadTable(access);
        });
    }

    getColumnNameForTime(time) {
        let value = this.timeColumnsCache[time.period_end];
        if (!value) {
            // value = this.dtp.sample_period.format(time.period_end, this.dtp);
            value = utils.stringDate(time.period_end, {date_separator: '-'});
            this.timeColumnsCache[time.period_end] = value;
        }
        return value;
    }

    isSticky = {name: true, alias: false, description: false};

    getRowHeaders() {
        let headers = ['group', 'edit', 'name', 'alias', 'description', 'comment'].concat(this.columns);
        for (let i = headers.length - 1; i >= 0; i--) {
            const h = headers[i];
            if (this.config.hidden_columns.includes(h)) {
                headers.splice(i, 1);
            }
        }
        this.resizeable_columns = utils.deepCopy(headers);
        this.isSticky.name = !this.config.hidden_columns.includes("name");
        this.isSticky.alias = !this.config.hidden_columns.includes("alias") && this.config.hidden_columns.includes("name");
        this.isSticky.description = !this.config.hidden_columns.includes("description") && (
            this.config.hidden_columns.includes("name") && this.config.hidden_columns.includes("alias"));

        this.times.forEach(time => {
            const column = this.getColumnNameForTime(time);
            headers.push(column);
        });
        if (this.times.length > 0) {
            this.row_headers = headers;
        } else {
            this.row_headers = [];
        }
        this.initialiseColumnFormats();
    }

    trackByFunction(index, item) {
        return index;
    }

    loadTable(access) {
        this.dataSource = null;
        this.series_data = [];
        this.resetSeriesList();
        this.series_list_map = {};
        this.raw_data_map = {};
        this.times = [];
        this.row_headers = [];
        this.permissions = access.permissions;
        this.sample_hours = this.sample_period.hours;

        this.shift_starts = [];
        this.shift_spans = [];
        this.shift_names = [];

        let date_counter = utils.deepCopy(this.dtp.start);
        let span_counter = 0;
        let period_start;
        let period_end;
        let shift_idx = 0;

        let shift_headers = () => {
            if (this.shifts[shift_idx] !== undefined) {
                let shift_end = new Date(this.shifts[shift_idx].attributes.end);
                let shift_start = new Date(this.shifts[shift_idx].attributes.start)['addHours'](this.dtp.sample_period.hours);

                if (shift_start < utils.deepCopy(new Date(this.dtp.start))['addHours'](this.dtp.sample_period.hours)) {
                    shift_start = (new Date(this.dtp.start))['addHours'](this.dtp.sample_period.hours);
                }
                if (shift_end > new Date(this.dtp.end)) {
                    shift_end = new Date(this.dtp.end);
                }
                if (shift_end <= date_counter) {
                    let shift_span = (shift_end.getTime() - shift_start.getTime()) / 3600000 / this.dtp.sample_period.hours;
                    this.shift_spans[new Date(shift_start).toISOString()] = shift_span + 1;
                    this.shift_starts.push(new Date(shift_start).toISOString());
                    this.shift_names[new Date(shift_start).toISOString()] = this.shifts[shift_idx].attributes.description;
                    shift_idx++;
                }
            }
        };

        while (date_counter < this.dtp.end && span_counter < 1000) {
            period_start = date_counter.toISOString();
            // Add 1 * sample period
            if (this.dtp.sample_period.name === 'month') {
                if (this.dtp.calendar && this.dtp.calendar !== DEFAULT_CALENDAR_NAME) {
                    date_counter = this.datetimePeriodService.getNextMonth(date_counter, this.dtp);
                } else {
                    date_counter = this.datetimePeriodService.addMonth(date_counter);
                }
            } else if (this.dtp.sample_period.name === 'week' && this.dtp.calendar !== DEFAULT_CALENDAR_NAME) {
                date_counter = this.datetimePeriodService.getNextWeek(date_counter, this.dtp);
            } else {
                date_counter['addHours'](this.sample_hours);
            }
            period_end = date_counter.toISOString();
            this.times.push({
                period_start: period_start,
                period_end: period_end
            });
            if (this.shifts.length > 0 && Number.isInteger(this.shift_length / this.dtp.sample_period.hours)) { // 1,2,4,(6),(8),(12)
                shift_headers();
                this.show_shifts = true;
            } else {
                this.show_shifts = false;
            }
            span_counter++;
            if (span_counter > 999) {
                console.log("WARNING: loop check count exceeded in LogSheet (loadTable)")
            }
        }

        this.getRowHeaders();
        if (this.restricted_access !== true || this.permissions.view_process_data) {
            this.getInputData();
        }

        this.getEvents();
    }

    fillRow(row) {
        let count = 0;
        let date_counter = (new Date(this.dtp.start));
        if (this.dtp.sample_period.name === 'month') {
            date_counter = this.datetimePeriodService.addMonth(date_counter);
        } else if (this.dtp.sample_period.name === 'week' && this.dtp.calendar !== DEFAULT_CALENDAR_NAME) {
            date_counter = this.datetimePeriodService.getNextWeek(date_counter, this.dtp);
        } else {
            date_counter['addHours'](this.sample_hours);
        }
        if (this.dtp.sample_period.name === 'month') {
            date_counter = this.datetimePeriodService.addMonth(this.dtp.start);
        }
        while (date_counter <= new Date(this.dtp.end) && count < 1000) {
            count++;
            if (!row.hasOwnProperty(date_counter.toISOString()) || !row[date_counter.toISOString()]) {
                row[date_counter.toISOString()] = {
                    attributes: {
                        value: null,
                        time_stamp: date_counter.toISOString()
                    }, type: 'raw_data',
                    relationships: {
                        series: {
                            data: {
                                id: row.id,
                                type: 'series'
                            }
                        }
                    }
                };

            } else if (!row[date_counter.toISOString()].hasOwnProperty('type')) {
                row[date_counter.toISOString()] = {
                    attributes: {
                        value: null,
                        time_stamp: date_counter.toISOString()
                    }, type: 'raw_data',
                    relationships: {
                        series: {
                            data: {
                                id: row.id,
                                type: 'series'
                            }
                        }
                    }
                };
            }
            if (this.dtp.sample_period.name === 'month') {
                date_counter = this.datetimePeriodService.addMonth(date_counter);
            } else if (this.dtp.sample_period.name === 'week' && this.dtp.calendar !== DEFAULT_CALENDAR_NAME) {
                date_counter = this.datetimePeriodService.getNextWeek(date_counter, this.dtp, false);
            } else {
                date_counter['addHours'](this.dtp.sample_period.hours);
            }
            if (count > 999) {
                console.log("WARNING: loop check count exceeded in LogSheet (fillRow)")
            }
        }
        return row;
    }

    getInputData() {
        let raw_data_fetched: Observable<any>[] = [];
        let counter = 0;
        const summary_series = [];

        this.series_list.forEach(item => {
            let data_fetched;
            const attr = this.flowSheet.series_component_atts_dict[item.id];
            if (attr.attributes.can_edit === true) {

                const query = new SearchQueryOptions();
                query.filters = [getRelationWithIdFilter('series', item.id), {
                    name: 'time_stamp',
                    op: 'le',
                    val: this.dtp.end
                }, {
                    name: 'time_stamp',
                    op: 'ge',
                    val: this.dtp.start
                }];
                query.sort = 'time_stamp';

                const $raw_data: Observable<ListResponse<RawData>> = this.api.raw_data.searchMany(query).pipe(tap(response => {
                    data_fetched = response.data;
                    data_fetched.map(col => {
                        const iso_date = (new Date(col.attributes.time_stamp)).toISOString();
                        this.raw_data_map[item.id + iso_date] = col.id;
                        item[iso_date] = this.detectLimit(item, col.attributes.value, col);
                    });
                    item = this.fillRow(item);
                }));
                raw_data_fetched.push($raw_data);
            } else {
                item = this.fillRow(item);
                summary_series.push(item.id);
            }
            this.series_data.push(item);
            this.series_list_map[counter] = item;
            counter++;
        });

        forkJoin(raw_data_fetched.length ? raw_data_fetched : [of(null)]).pipe(concatMap(() => {
            let summary = [], data = [];

            /**Dont call getSeriesSummary if we aren't actually going to use the values**/
            let $getsummary = ['Value', 'Status'].some(item => this.columns.includes(item)) ?
                this.getSeriesSummary(this.series_list.map(series => series.id)) : of([]);
            $getsummary = $getsummary.pipe(
                tap((response) => {
                    summary = response;
                }));

            const $getdata: Observable<any> = this.seriesDataService.getSeriesData(this.dtp, null, this.process.id, [], this.config.shift_type_id).pipe(tap(response => {
                data = response;
            }));

            return forkJoin([$getdata, $getsummary]).pipe(
                first(), takeUntil(this.onDestroy),
                tap(() => {
                    // @ts-ignore
                    const calc_data = data.data;
                    this.calc_data = calc_data;
                    // @ts-ignore
                    this.missing_values = data.missing_values;
                    const series_name_map = {};
                    this.series_list.map(series => {
                        if (!series.attributes.can_edit) {
                            series_name_map[series.attributes.name] = series.id;
                        }
                    });

                    this.series_data.forEach(row => {
                        let date_counter = new Date(this.dtp.start);
                        if (this.sample_period === 'month') {
                            date_counter = this.datetimePeriodService.addMonth(date_counter);
                        } else {
                            date_counter['addHours'](this.sample_hours);
                        }
                        let series_name = row.attributes.name;
                        while (date_counter <= new Date(this.dtp.end)) {
                            let timestamp = date_counter.toISOString();
                            if (series_name_map.hasOwnProperty(series_name)) {
                                if (calc_data[series_name]?.[timestamp] !== null && calc_data[series_name]?.[timestamp] !== undefined &&
                                    row[timestamp] !== calc_data[series_name][timestamp]) {
                                    row[timestamp] = calc_data[series_name][timestamp];
                                    row[timestamp] = this.detectLimit(row, row[timestamp], {'attributes': {'value': row[timestamp]}});
                                }
                            }
                            if (this.sample_period === 'month') {
                                date_counter = this.datetimePeriodService.addMonth(date_counter);
                            } else {
                                date_counter['addHours'](this.dtp.sample_period.hours);
                            }
                        }

                        // @ts-ignore
                        summary.forEach(summary => {
                            if (series_name === summary.Name) {
                                if (this.columns) {
                                    this.columns.forEach(col => {
                                        row[col] = summary[col];
                                    });
                                }
                                row.total = summary.Value;
                                row.status = summary.Status;
                            }
                        });
                    });
                    this.sheet_ready = true;
                    this.dataSource = new MatTableDataSource(this.series_data);
                    this.selection = new SelectionModel<any>(false, null);
                }));
        })).subscribe();
    }

    setFocus(e) {
        let id = e.target.id.split('_');
        this.setSelection(event, id);
    }

    preventDefaults(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
        }
        if (event.target.tagName === "INPUT") {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
            }
        }
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event) {

        let id = event.target.id.split('_');
        this.preventDefaults(event);
        let action_keys = ['ArrowDown', 'Enter', 'ArrowUp', 'ArrowLeft', 'Tab', 'ArrowRight'];

        if (event.target.tagName === "INPUT" && !action_keys.includes(event.key)) {
            return;
        }
        if (event.key === 'ArrowDown' || event.key === 'Enter') {
            id[1] = parseInt(id[1]) + 1;
        }
        if (event.key === 'ArrowUp') {
            id[1] = parseInt(id[1]) - 1;
        }
        if (event.key === 'ArrowLeft') { // && event.target.tagName !== "INPUT"
            id[2] = parseInt(id[2]) - 1;
        }
        if (event.key === 'Tab') {
            id[2] = parseInt(id[2]) + 1;
        }
        if (event.key === 'ArrowRight') { // && event.target.tagName !== "INPUT"
            id[2] = parseInt(id[2]) + 1;
        }

        this.setSelection(event, id);
    }

    setSelection(event, id) {
        let el = document.getElementById('td_' + id[1] + '_' + id[2] + '_' + this.tileDataService.id);
        if (el) {
            el.focus();
            if (el.firstElementChild.tagName === "INPUT") {
                let e = document.getElementById('inputtd_' + id[1] + '_' + id[2] + '_' + this.tileDataService.id);
                e.focus();
            }
        }
    }

    overrideCalculations(access) {
        // Appending dialog to document.body to cover sidenav in docs app
        this.getCalculations(access);

    }

    getCalculations(access) {
        let series_list = this.series_list.map((series) => {
            return series.id;
        });
        this.headerData.getCalculations(this.dtp, series_list, 'hour', 1).then((data: { message?: string }) => {
            this.refreshShifts(access);
            this.changes_flag = false;
        }).catch(e => {
        });
    }

    getSeriesSummary(ids): Observable<any> {
        let calendar = null;
        if (this.dtp && this.dtp && this.dtp.calendar) {
            calendar = this.dtp.calendar;
        }

        let columns = utils.deepCopy(this.columns);
        if (!columns) {
            columns = ['Value', 'Status'];
        } else {
            if (!columns.includes('Status')) {
                columns.push('Status');
            }
            if (!columns.includes('Value')) {
                columns.push('Value');
            }
        }
        const shiftTypeId: number = this.seriesDataService.getShiftTypeFromDtp(this.dtp);

        return this.seriesDataService.getSeriesSummary(this.dtp, null, this.config.process.id, columns, null, shiftTypeId, true).pipe(
            // TODO We want to catch error and continue - is this function not working in the same way for Observables??
            // catchError(this.handleError('LogSheetComponent.getSeriesSummary', [])),
            catchError((err) => {
                console.log('ERROR: LogSheetComponent (getSeriesSummary: ', err);
                return of([]);
            }),
            takeUntil(this.onDestroy)
        );
    }

    addTotal(series) {

        let summary = this.getSeriesSummary([series.id]);
        summary.pipe(
            first(),
            tap((stats: {}[]) => {
                stats.forEach(summary => {
                    if (series.attributes.name === summary['Name']) {
                        if (this.columns) {
                            this.columns.forEach(col => {
                                series[col] = summary[col];
                            });
                        }
                        series.total = summary['Value'];
                        series.status = summary['Status'];
                    }
                });
            })).subscribe();
    }

    detectLimit(series: any, value: string, raw_data) {
        raw_data = Object.assign(raw_data, utils.detectLimits(series, value));
        return raw_data;
    }

    setupCommentTimes(new_comment, time) {

        new_comment.attributes.start = new Date(time);
        if (this.dtp.sample_period.name === 'month') {
            const temp_date = new Date(time);
            temp_date.setMonth(temp_date.getMonth() + 1);
            new_comment.attributes.end = temp_date;
        } else {
            new_comment.attributes.end = (new Date(time))['addHours'](this.dtp.sample_period.hours);
        }
        new_comment.attributes.end.setTime(new_comment.attributes.end.getTime() + (-1000));
        return new_comment;
    }

    editSeries(series, event?) {
        if (event) {
            event.stopPropagation();
        }
        let $series_full = this.api.series.getById(series.id);
        $series_full.pipe(switchMap(returned => {
            let series_full = returned.data;
            const dialogRef = this.seriesDataService.upsertSeries(this.process, series_full);
            return dialogRef.afterClosed().pipe(tap(response => {
                if (response) {
                    let updated_series;
                    if (response.series) {
                        updated_series = response.series;
                    } else {
                        updated_series = response;
                    }

                    Object.keys(series.attributes).forEach(att => {
                        if (updated_series.attributes[att] !== undefined && updated_series.attributes[att] !== null) {
                            series.attributes[att] = updated_series.attributes[att];
                        }
                    });
                    this.report_groups[series.attributes.report_group].forEach(item => {
                        if (item.id === series.id) {
                            item = series;
                        }
                    });
                }
            }))
        })).subscribe();
    }

    checkUserEvents(series) {
        let userEvent = false;
        this.events.forEach((event) => {
            // if there is an event for this user
            if (this.appScope.current_user.id === event.relationships.created_by.data.id) {
                event.relationships.series_list.data.forEach(s => {
                    // and this series
                    if (s.id === series.id && s.type === series.type) {
                        // within the dtp period
                        if (new Date(event.attributes.start) >= new Date(this.dtp.start) &&
                            new Date(event.attributes.start) <= new Date(this.dtp.end)) {
                            // get the comment
                            userEvent = event.attributes.comment;
                        }
                    }
                });
            }
        });

        return userEvent;
    }

    /**
     * Fired when an change is made to a cell of a series-time pair
     * @param series The series?
     * @param time
     * @param event
     */
    dataChange(series: any, time: TimePeriod, event) {
        const ctrl = this;
        const magic_temp = '-'; // FIXME this is used because angular did not detect the updated value;
        const time_period_end = time.period_end;
        let new_value = event.target.value;
        let raw_data = series[time_period_end]; // the cell that was edited
        const original_value = raw_data.attributes.value;

        let cancelled: boolean = false;
        // This guy should supply a reason for changing data regardless of its value
        let autoComment = (comment) => {
            let new_comment = this.setupCommentTimes({attributes: {}}, time_period_end);
            this.eventService.addInlineComment(comment, time_period_end, new_comment.attributes.end, [series])
                .subscribe((new_comment) => {
                    ctrl.eventService.toggleComments.next(true);
                    ctrl.getEvents();
                });
        };
        let p = this.permissions;
        if (p.apply_correction_factor && !(p.edit_process_data || p.edit_todays_data ||
            this.super_user || this.appScope.current_user.role_names.includes('Administrator'))) {

            let old_comment = this.checkUserEvents(series);
            if (old_comment !== false) {
                autoComment(old_comment);
            } else {
                let comment = prompt("Please enter a reason for changing this value.");
                if (!comment) {
                    raw_data.attributes.value = magic_temp;
                    setTimeout(() => {
                        raw_data.attributes.value = original_value;
                    });
                    return;
                } else {
                    autoComment(comment);
                }
            }
        } else {
            if ((series.attributes.hihi == null && series.attributes.hi == null) ||
                (series.attributes.lowlow == null && series.attributes.low == null)) {
                raw_data.error = false;
                raw_data.warning = false;
                raw_data.attributes.value = new_value;
            } else if (new_value === null || new_value.trim() === '') { // allow delete
                raw_data.attributes.value = null;
                this.detectLimit(series, null, raw_data);
            } else {
                this.detectLimit(series, new_value, raw_data);

                if (raw_data.error) {
                    let promptMessage = utils.getLimitsPrompt(series, false);
                    new_value = prompt(promptMessage);
                    while (new_value !== null && new_value !== '' && isNaN(parseFloat(new_value))) {
                        new_value = prompt(`${promptMessage}\nInvalid number, please type a valid number`);
                    }

                    if (new_value === null || new_value === '') { // prompt was cancelled
                        // raw_data.attributes.value = original_value;
                        // this.detectLimit(series, original_value, raw_data);
                        cancelled = true;
                    } else {
                        raw_data.attributes.value = Number(new_value);
                        this.detectLimit(series, raw_data.attributes.value, raw_data);
                        if (raw_data.error) {
                            if (!this.submitCommentEvent(series, time, "Validation error.")) {
                                cancelled = true;
                            }
                        }
                    }
                } else {
                    raw_data.attributes.value = new_value;
                }
            }
        }

        raw_data.saving = true;
        const new_raw_data = {
            id: raw_data.id,
            type: 'raw_data',
            attributes: raw_data.attributes,
            relationships: raw_data.relationships
        };

        if (raw_data.hasOwnProperty('id') && !cancelled) { // value has previously existed
            if (Number.isNaN(parseFloat(raw_data.attributes.value))) {
                this.api.raw_data.delete(raw_data.id).then(() => {
                    delete raw_data.id;
                    delete raw_data.error;
                    delete raw_data.warning;
                    raw_data.saving = false;
                    this.changes_flag = true;
                    this.addTotal(series);
                    // this.detectLimit(series, raw_data.attributes.value, raw_data);
                }, error => {
                    this.tryLogErrorForDataMutation('delete', error, new_raw_data);
                });
            } else {
                this.api.raw_data.patch(new_raw_data).then((result) => {
                    raw_data.saving = false;
                    this.changes_flag = true;
                    this.detectLimit(series, raw_data.attributes.value, raw_data);
                    this.addTotal(series);
                }, error => {
                    this.tryLogErrorForDataMutation('update', error, new_raw_data);
                });
            }
        } else {
            if (Number.isNaN(parseFloat(raw_data.attributes.value)) || cancelled) {
                raw_data.saving = false;
                console.log('No option', new_raw_data);
                raw_data.attributes.value = magic_temp;
                setTimeout(() => {
                    raw_data.attributes.value = original_value;
                    this.detectLimit(series, original_value, raw_data);
                });
            } else {
                this.api.raw_data.save(new_raw_data).then((result) => {
                    raw_data = result.data;
                    series[time_period_end] = raw_data;
                    raw_data.saving = false;
                    this.changes_flag = true;
                    this.addTotal(series);
                    this.detectLimit(series, raw_data.attributes.value, raw_data);
                }, error => {
                    this.tryLogErrorForDataMutation('save', error, new_raw_data);
                });
            }
        }
    }

    private tryLogErrorForDataMutation(method: string, error, point,): void {


        console.warn('Failed to ' + method + '\n', point, '\nerror\n', error);

        try {
            let error_message = error.error.errors[0];
            const title = error_message.title;
            const description = error_message.detail;
            this.notification.openError('Failed to ' + method + '. ' + title + ': ' + description,);
        } catch {
            this.notification.openError('Failed to ' + method,);
        }
    }

    toggleAggregation() {
        // TODO fix the way this is set for this button (same button which is set in the AfterViewInit)

        this.editAggregation = !this.editAggregation;
    }

    private getNonEmptyComment(time: string, initMessage?: string): string {
        let comment: string;
        if (!initMessage) {
            initMessage = '';
        } else {
            initMessage += '\n';
        }

        comment = prompt(initMessage + "Please provide a comment");
        while (!comment || !comment.trim()) {
            if (comment == null) {
                this.notification.openError('Data entry cancelled', 3000);
                return undefined;
            } else {
                comment = prompt(initMessage + "Please provide a comment. You have to type a message.");
            }
        }
        return comment.trim();
    }

    dtpCoarserGranularity(series) {
        return series.attributes.sample_period &&
            (this.datetimePeriodService.sample_dict[series.attributes.sample_period].hours < this.sample_period.hours);
    }

    private saveAllowedSimple(can_edit: boolean, sample_period: string, time: string) {
        // check for apply correction factor permissions

        this.edit_day = new Date(this.datetimePeriodService.range_dict['since yesterday'].start)['addHours'](this.sample_period.hours);

        let series_time = new Date(time);

        let series_sample_period = sample_period;
        if (!series_sample_period) {
            series_sample_period = this.appScope.config_name_map.default_sample_period.value;
        }

        let allowShift: boolean = false;

        // If sample period doesn't call for data entry at this point
        if (this.datetimePeriodService.sample_dict[series_sample_period].hours < this.sample_period.hours && !allowShift) {
            return false;
        }

        // Before checking weekday and day of month, must correct the time for the tz difference
        const client_mine_tz_offset = this.datetimePeriodService.getClientMineUTCOffset(series_time);
        let series_time_in_tz = utils.deepCopy(series_time);
        series_time_in_tz['addHours'](-1 * client_mine_tz_offset); // Subtracting, to convert back to mine tz

        // Weekly series may only have data entered on the week start day
        if (series_sample_period === 'week' && this.dtp.calendar === DEFAULT_CALENDAR_NAME &&
            series_time_in_tz.getDay() != this.appScope.config_name_map.week_start.value) {
            return false;
        }

        if (series_sample_period === 'week' && this.dtp.calendar !== DEFAULT_CALENDAR_NAME &&
            this.datetimePeriodService.sample_dict[series_sample_period].hours > this.sample_period.hours &&
            series_time_in_tz.getDay() != this.datetimePeriodService.getCurrentWeekStartDay(series_time, this.dtp)) {
            return false;
        }

        // Check if day is on the first day of the month (or custom calendar start), in the mine's tz
        let month_start_day = 1;
        if (this.dtp.calendar !== DEFAULT_CALENDAR_NAME) {
            month_start_day = this.datetimePeriodService.calendar_dict[this.dtp.calendar].month_start_day();
        }

        if (series_sample_period === 'month') {
            if (series_time_in_tz.getDate() !== month_start_day) {
                return false;
            }
            if (series_time_in_tz.getHours() !== this.datetimePeriodService.defaultStartHour) {
                return false;
            }
        } else {
            const samplePeriodInHours = this.datetimePeriodService.sample_dict[series_sample_period].hours;
            let startHour: number = this.datetimePeriodService.getDefaultStartHour(this.dtp);
            if (this.sample_period.parent === 'shift') {
                startHour = this.datetimePeriodService.getStartHourForShiftType(this.dateInst.dtp);
            }
            const remainder = (series_time_in_tz.getHours() - startHour) % samplePeriodInHours;
            if (remainder !== 0) {
                return false;
            }
        }

        // check permissions
        if (can_edit && series_time <= (new Date())['addHours'](this.dtp.sample_period.hours)) {
            if (this.permissions.edit_process_data || this.permissions.apply_correction_factor || this.super_user) {
                return true;
            }
            return series_time >= new Date(this.edit_day) && this.permissions.edit_todays_data;
        }
        return false;
    }

    resolver = (can_edit, sample_period, time) => {
        return '' +
            (can_edit ? can_edit : 'SUB_KEY:can_edit') + '\n' +
            (sample_period ? sample_period : 'SUB_KEY:sample_period') + '\n' +
            (time ? time : 'SUB_KEY:time');
    }

    private saveAllowedMemoized = _memoize(this.saveAllowedSimple.bind(this), this.resolver);

    saveAllowed(series, time: string): boolean {
        const can_edit = series.attributes.can_edit;
        const sample_period = series.attributes.sample_period;
        return this.saveAllowedMemoized(can_edit, sample_period, time);
    }

    resetSaveAllowedMemoized() {
        this.saveAllowedMemoized = _memoize(this.saveAllowedSimple.bind(this), this.resolver);
    }

    shouldShowInput(series, time) {
        //console.log("series",series, time, series[time.period_end]);
        let a = series && series[time.period_end];
        let b = !(series[time.period_end] && series[time.period_end].saving);
        let c = this.saveAllowed(series, time.period_end);
        let d = !this.editAggregation;
        let r = a && b && c && d;
        return r;
    }

    shouldShowFirst(series, time) {
        let b = !series.attributes.can_edit && this.missing_values[series.attributes.name]
            && this.calc_data[series.attributes.name]?.[time.period_end];
        return b;
    }

    saveComment(comment) {
        this.eventService.addInlineComment(comment, this.tileDataService.comment.start, this.tileDataService.comment.end,
            [this.tileDataService.comment.series]).pipe(tap((new_comment) => {
            this.eventService.toggleComments.next(true);
            this.getEvents();
        })).subscribe();
    }

    aggregationChange(series, orig_value) {
        this.value_error = false;
        this.raw_data_error = false;

        let sample_period = this.datetimePeriodService.sample_dict[this.appScope.config_name_map.default_sample_period.value].hours;

        if (series.attributes.sample_period) {
            sample_period = this.datetimePeriodService.sample_dict[series.attributes.sample_period].hours;
        }
        let agg = series.attributes.aggregation;
        let value = utils.deepCopy(series.total);
        let total_hours = Math.abs(this.dtp.end.valueOf() - this.dtp.start.valueOf()) / 36e5;

        if (agg === 'total') {
            value = value / (total_hours / sample_period);
        }
        let limits = this.detectLimit(series, value, {'attributes': {'value': value}});
        if (series.total === null || series.total === '' || isNaN(series.total)) {
            this.value_error = true;
        } else {
            let observables;
            let date_counter = utils.deepCopy(this.dtp.start)['addHours'](sample_period);
            series.saving = true;
            let end = new Date();
            if (end > this.dtp.end) {
                end = utils.deepCopy(this.dtp.end);
            }
            while (date_counter <= end) {
                let save_data = () => {
                    let raw_data = {
                        id: undefined, type: 'raw_data', attributes: {
                            value: value,
                            time_stamp: utils.deepCopy(date_counter)
                        },
                        relationships: {
                            series: {
                                data: {
                                    id: series.id,
                                    type: 'series'
                                }
                            }
                        }
                    };
                    let obs;
                    let updateSeries = (series, result?) => {
                        if (result) {
                            series[raw_data.attributes.time_stamp.toISOString()].id = result.data.id;
                        }
                        series[raw_data.attributes.time_stamp.toISOString()].attributes.value = value;
                        series[raw_data.attributes.time_stamp.toISOString()].warning = limits.warning;
                        series[raw_data.attributes.time_stamp.toISOString()].error = limits.error;
                    }

                    if (series[raw_data.attributes.time_stamp.toISOString()].hasOwnProperty('id')) {
                        raw_data.id = series[raw_data.attributes.time_stamp.toISOString()].id;
                        obs = this.api.raw_data.obsPatch(raw_data).pipe(tap({
                            next: () => {
                                updateSeries(series);
                            }, error: () => {
                                this.raw_data_error = true;
                                console.log("Error: Data not saved for: " + raw_data.attributes.time_stamp);
                            }
                        }));
                    } else {
                        obs = this.api.raw_data.obsSave(raw_data).pipe(tap({
                            next: result => {
                                updateSeries(series, result);
                            }, error: () => {
                                this.raw_data_error = true;
                                console.log("Error: Data not saved for: " + raw_data.attributes.time_stamp);
                            }
                        }));
                    }

                    date_counter['addHours'](sample_period);
                    return observables;
                };
                observables = save_data();
            }

            forkJoin([observables]).subscribe(() => {
                series.saving = false;
                if (this.raw_data_error === true) {
                    this.notification.openError("Some values were not saved, please check the console.");
                } else {
                    this.changes_flag = true;
                }
            });
        }
    }

    setComment(e, series, time) {
        this.tileDataService.comment.series = series;
        if (time) {
            this.tileDataService.comment.start = time.period_start;
            this.tileDataService.comment.end = time.period_end;
        } else {
            this.tileDataService.comment.start = this.dtp.start;
            this.tileDataService.comment.end = this.dtp.end;
        }
    }

    toggleComments(show) {
        this.headerData.toggleCommentPanel(true, {tileData: this.tileDataService, eventService: this.eventService});
        this.eventService.setShowComments(show);
    }

    private submitCommentEvent(series: any, time: TimePeriod, initMessage?: string): boolean {

        const comment = this.getNonEmptyComment(time.period_end, initMessage);
        if (!comment) {
            return false;
        }
        if (series['warned'] === true) {
            series['warned'] = comment;
        }
        this.eventService.addInlineComment(comment, time.period_start, time.period_end, [series]).toPromise().then(() => {
            this.eventService.toggleComments.next(true);
            this.getEvents();
        });
        return true;
    }

    commentIconHover(events) {
        this.tileDataService.commentIconHover.next(events);
    }

    commentHover(value) {
        value.event.relationships.series_list.data.forEach((series) => {
            this.hovering_events.push(series.id);
        });
    }

    commentLeave(value) {
        this.hovering_events = [];
    }

    private getEvents() {
        this.series_events = {};
        this.eventService.getEvents(new Date(this.dtp.start), new Date(this.dtp.end), this.series_list, [this.process])
            .pipe(takeUntil(this.onDestroy)
            )
            .subscribe(data => {
                this.tileDataService.events = data.data;
                data.data.forEach(event => {
                    event.relationships.series_list.data.forEach(series => {
                        if (this.series_events[series.id]) {
                            this.series_events[series.id].push(event);
                        } else {
                            this.series_events[series.id] = [event];
                        }
                    });
                });
            });
    }

    changeWidth(rows): any {
        return {
            'width': ((rows * 30.4) - 10) + 'px', // no rows * row height - 2*5px padding
            'transform': 'translateX(' + String(25.4 - ((rows - 1) * 15.2)) + 'px)' // row height plus padding - rows above 1 * half row height
        };
    }

    cellMouseLeave() {
        this.tileDataService.commentIconLeave.next()
    }

    initialiseColumnFormats() {
        this.resizeable_columns.push('time');
        this.config.column_formats = this.config.column_formats || [];
        this.resizeable_columns.forEach(r => {
            if (!this.config.column_formats.map(c => c.id).includes(r)) {
                this.config.column_formats.push({id: r, type: 'custom', format: {resize: true, width: 'auto'}});
            }
        })
        this.format_dict = {};
        this.config.column_formats.forEach(column => {
            this.format_dict[column.id] = this.tableUtils.getColumnFormats(column.format);
        });
    }
}
