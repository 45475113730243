import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import * as d3 from 'd3';
import {TreeLayout} from 'd3';
import * as utils from '../../lib/utils';
import {SeriesDataService} from "../../services/series_data.service";
import {take, takeUntil, tap, concatMap} from "rxjs/operators";
import {CachingService} from "../../services/caching.service";
import {SplitAreaDirective, SplitComponent} from 'angular-split';
import {TreeNode} from "./types/tree.node";
import {Series} from "../../_models/series";
import {VDTTileConfig} from '../../forms/value-driver-tree-form/value-driver-tree-form.component';
import {TileDataService} from "../../services/tile_data.service";
import {FormDialogService} from "../../services/form-dialog.service";
import {KeyMap, ModelID, ModelName} from '../../_typing/generic-types';
import {BaseComponent} from '../../shared/base.component';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {NotificationService} from "../../services/notification.service";
import {SingleResponse} from '../../services/api/response-types';
import {HeaderDataService} from "../../services/header_data.service";
import {Notification, Observable} from "rxjs";
import {SeriesConstantPermissions, SeriesPermissions} from "../../_typing/series-constant-permissions";
import {EXTENDED_NOTIFICATION_DURATION_MS, NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'value-driver-tree',
    templateUrl: './value-driver-tree.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [CachingService, SeriesDataService],
    standalone: false
})
export class ValueDriverTreeComponent extends BaseComponent {
    @Input() formula_view: boolean;
    @Input() treeNodeMap: KeyMap<TreeNode>;
    @Input() config: VDTTileConfig;
    @Input() is_tile: boolean;
    @Input() updateCalculationsRequest!: Observable<void>;

    @Input()
    set selected_calculation(selected_calculation: Series) {
        this._selected_calculation = selected_calculation;
        if (selected_calculation) {
            this.seriesData.getSeriesById(selected_calculation.id).pipe(
                concatMap((series: SingleResponse<Series>) => {
                    this._selected_calculation = series.data;
                    return this.seriesData.getCalculationTreeNodeMap(this._selected_calculation)
                }),
                take(1),
                concatMap((result: KeyMap<TreeNode>) => {
                    this.treeNodeMap = result;
                    this.allTreeSeries = this.seriesData.calculation_tree_list;
                    //this.removeTree();
                    this.determineTree(this._selected_calculation);
                    if (this.config?.state) {
                        this.setHeight();
                        this.setVDTState();
                    }
                    return this.seriesData.getSeriesPermissions(this.allTreeSeries.map(s => s.id)).pipe(tap(permissions => {
                        this.seriesPermissions = permissions;
                    }));
                    takeUntil(this.onDestroy);
                }))
                .subscribe();
        }
    }

    _selected_calculation: Series;

    get selected_calculation(): Series {
        return this._selected_calculation;
    }

    private seriesPermissions: SeriesPermissions;
    series_full: Series[] = [];
    treemap: TreeLayout<any>;
    allTreeSeries: Series[]
    table_data: any = [];
    dtp: IDateTimePeriod;
    tree: d3.TreeLayout<{}>;
    g: any;
    svg: any;
    width: number = 800;
    duration: number;
    i: number;
    root: any;
    margin = {top: 20, right: 20, bottom: 30, left: 20};
    height: number = 600;
    vb_width: number = 1150;
    vb_height: number = 500;
    box_width: number = 220;
    box_height: number = 80;
    depth: number = 280;
    current_depth: number = 0;
    selected_series: any;
    private nodes_expanded = 0;
    private max_depth = 0;
    private series_expanded_map: KeyMap<number[]> = {};
    public dragging = false;

    menuVisible: boolean = false;
    treeNodes: any;
    links: any;
    mouseovernode: any = null;
    pan_state: ElementRef;
    direction: 'horizontal' | 'vertical' = 'vertical';
    sizes: { percent?: any, pixel?: any } = {
        percent: {
            area1: 75,
            area2: 25,
        },
        pixel: {
            area1: 120,
            area2: '*',
            area3: 160,
        },
    };
    state = null;

    @ViewChild('pan_state') set content(content: ElementRef) {
        this.pan_state = content;
    };

    @ViewChild('split') split: SplitComponent;
    @ViewChild('area1') area1: SplitAreaDirective;
    @ViewChild('area2') area2: SplitAreaDirective;

    @HostListener('click', ['$event'])
    onClick() {
        if (this.menuVisible === true) {
            this.menuVisible = false;
        }
    }

    constructor(private api: ApiService,
                private renderer: Renderer2,
                private tileRef: ElementRef,
                private seriesData: SeriesDataService,
                private dateInst: DateTimeInstanceService,
                private tileData: TileDataService,
                private changeDetector: ChangeDetectorRef,
                private formDialogService: FormDialogService,
                private headerData: HeaderDataService,
                private notification: NotificationService) {
        super();
        this.dateInst.dateTimePeriodChanged$.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.dtp = dateInst.dtp;
        })
    }

    ngOnInit() {
        const ctrl = this;

        if (this.config && !this.treeNodeMap) {
            this.formula_view = this.config.formula_view;
            this.sizes.percent = {area1: 100, area2: 0};
            this.setButtons();
        }
        if (this.updateCalculationsRequest) {
            this.updateCalculationsRequest.pipe(takeUntil(this.onDestroy)).subscribe(() => {
                this.updateCalculations();
            })
        }
    }


    setHeight() {
        const content = this.tileRef.nativeElement.closest(".tile-content");
        if (this.is_tile && content?.clientHeight) {
            this.height = content.clientHeight;
            this.width = content.clientWidth;
        }
    }

    setVDTState() {
        this.state = this.config?.state;
        if (!this.state) return;
        setTimeout(() => {
            if (this.state.height) {
                this.vb_height = this.state.height;
            }
            if (this.state.width) {
                this.vb_width = this.state.width;
            }
            if (this.state.pan) {
                this.renderer.setStyle(this.pan_state.nativeElement, 'transform', this.state.pan);
            }
            if (this.state.depth) {
                this.expandBySeriesId(this.root, this, this.state.depth);
            }
            this.changeDetector.detectChanges();
        })
    }

    emitSaveTile(): void {
        this.tileData.tileChange.next(this.tileData.tile);
    }

    saveVDTState() {
        this.nodes_expanded = 0;
        this.max_depth = 0;
        this.series_expanded_map = {};
        const ctrl = this;
        const allowed = this.getExpandedNodes(this.root, ctrl);
        if (allowed === false) {
            return;
        }

        this.config.state = {
            height: this.vb_height,
            width: this.vb_width,
            pan: this.pan_state.nativeElement.style.transform,
            depth: Object.keys(this.series_expanded_map)?.length ? this.series_expanded_map : null
        };
        this.tileData.tile.attributes.parameters = this.config;
        this.emitSaveTile();
    }

    setButtons() {
        const buttons = [
            {
                name: 'Save',
                func: () => {
                    this.saveVDTState();
                },
                params: {},
                class: 'fa small fa-save hide-xs show-on-edit',
                HoverOverHint: 'Save VDT state'
            }
        ];
        this.tileData.buttonsChanged.next(buttons);
    }

    scrollHandler(event) {
        if (event.wheelDelta > 0) {
            this.vb_height += 50;
            this.vb_width += 50;
        } else {
            if (this.vb_height > 50) {
                this.vb_height -= 50;
            }
            if (this.vb_width > 50) {
                this.vb_width -= 50;
            }
        }
        event.preventDefault();
    }

    determineTree(event): void {
        const ctrl = this;
        let newID = ctrl.selected_calculation.id;

        // This is the selected calculation
        let rootNode = this.treeNodeMap[ctrl.selected_calculation.id];
        ctrl.generateTree(newID, rootNode);
    }

    mouseEnter(node: MouseEvent) {
        this.mouseovernode = utils.deepCopy(node);
    }

    mouseLeave(node: MouseEvent) {
        this.mouseovernode = null;
    }

    addRow(node, depth, index) {
        let row = {
            series: node.data.series,
            depth: depth,
            parent: null,
            index: index,
            parent_index: node.parent_index
        };
        // row.series = utils.deepCopy(node.data.series);
        if (node.parent) {
            row.parent = node.parent.data.series;
        }
        return row;
    }

    flattenTree(tree, name) {
        let node;
        let stack = utils.deepCopy(tree);
        let i: number = 0;
        stack = stack.map(n => {
            n.parent_index = utils.deepCopy(i);
            return n;
        });
        //All the indexing here is to be able to highlight rows in table when hovering on node or child
        while (stack.length > 0) {
            i += 1;
            node = stack[stack.length - 1];
            node.index = utils.deepCopy(i);
            this.table_data.push(this.addRow(node, node.depth, i));
            stack.pop();
            if (node.data.name === name) {
                return node;
            }
            if (node.children) {
                node.children.map(n => {
                    n.parent_index = i;
                    return n;
                });
                stack.push(...node.children);
            } else if (node._children) {
                node._children.map(n => {
                    n.parent_index = i;
                    return n;
                });
                stack.push(...node._children);
            }
        }
    }

    buildTable(rootNode) {
        let stop = 0;
        const ctrl = this;
        ctrl.table_data = [];
        ctrl.table_data.push(ctrl.addRow(rootNode, 0, 0));
        if (rootNode.children) {
            ctrl.flattenTree(rootNode.children ? rootNode.children : rootNode._children, rootNode.data.name);
        }
    }

    generateTree(selector, rootNode) {
        const ctrl = this;
        ctrl.i = 0;

        //  assigns the data to a hierarchy using parent-child relationships
        ctrl.root = d3.hierarchy(rootNode, function (d) {
            return d.children;
        });

        ctrl.root.x = ctrl.height / 2 - 200;
        ctrl.root.y = 0;

        // declares a tree layout and assigns the size
        ctrl.treemap = d3.tree()
            .nodeSize([ctrl.box_height + 30, ctrl.box_width + 30]);
        if (ctrl.root.children) {
            ctrl.root.children.forEach((child) => {
                ctrl.collapse(child, this)
            });
            // Just in case the user only wants to show the root node
            if (ctrl.config?.state?.depth && Object.keys(ctrl.config.state.depth)?.length) {
                ctrl.collapse(this.root, this);
            }
        }
        ctrl.createSVG(ctrl.root);
        ctrl.buildTable(ctrl.root);
    }

    createSVG(source) {
        const ctrl = this;
        let treeData = ctrl.treemap(ctrl.root);
        ctrl.treeNodes = treeData.descendants();
        ctrl.links = treeData.descendants().slice(1);
        ctrl.treeNodes.forEach(function (d) {
            d.y = d.depth * ctrl.depth;
        });

        // Store the old positions for transition.
        ctrl.treeNodes.forEach(function (d) {
            d.x0 = d.x;
            d.y0 = d.y;
        });
        ctrl.links.forEach(function (d) {
            d.path = ctrl.genPath(d, d.parent);
        })
    }

    toggle(d) {
        if (this.dragging) {
            this.dragging = false;
            return;
        }
        this.current_depth = utils.deepCopy(d.depth);
        if (d.children) {
            d._children = d.children;
            d.children = null;
        } else {
            d.children = d._children;
            d._children = null;
        }
        this.createSVG(d);
    }

    genPath(s, d): string {
        const xc = (this.depth - this.box_width) / 2;
        const y = this.box_height / 2;
        const border_allowance = 1;
        return `M ${s.y - border_allowance} ${s.x + y}
            C ${((d.y) + this.box_width) + xc} ${s.x + y},
              ${((d.y) + this.box_width) + xc} ${d.x + y},
              ${d.y + this.box_width + border_allowance} ${d.x + y}`;
    }

    openChartDialog(series_name: ModelName): void {
        this.formDialogService.openChartDialog(series_name);
    }

    editSeries(series: Series): void {
        const ctrl = this;
        this.api.series.getById(series.id).toPromise().then((full_series) => {
            ctrl.seriesData.upsertSeries(null, full_series.data);
        });
    }

    removeTree() {
        d3.select('#value_driver_tree').selectAll('div').remove()
    }

    collapse(d, ctrl) {
        if (d.children) {
            d._children = d.children;
            d._children.forEach((child) => {
                ctrl.collapse(child, ctrl);
            });
            d.children = null;
        }
    }

    expandBySeriesId(d, ctrl, map: KeyMap<number[]>) {
        const series_id = d.data.series.id;
        if (map[series_id]?.includes(d.depth)) {
            if (!d.children) {
                ctrl.toggle(d);
            }
            d.children.forEach(child => {
                ctrl.expandBySeriesId(child, ctrl, map);
            });
        }
    }

    getExpandedNodes(d, ctrl): boolean {
        if (d.data.series.id === ctrl.root.data.series.id && !d.children) {
            ctrl.series_expanded_map[d.data.series.id] = [-1];
            return true;
        }
        if (d.children) {
            ctrl.series_expanded_map[d.data.series.id] = (ctrl.series_expanded_map[d.data.series.id] || []).concat([d.depth]);
            ctrl.max_depth = Math.max(ctrl.max_depth, d.depth + 1);
            ctrl.nodes_expanded += 1;

            if (ctrl.nodes_expanded > 3) {
                ctrl.notification.openError("You have too many nodes open. Please close some to save this value driver tree's state.");
                this.series_expanded_map = {};
                return false;
            }

            for (let child of d.children) {
                let result = ctrl.getExpandedNodes(child, ctrl);
                if (!result) {
                    return false;
                }
            }
        }
        return true;
    }

    private allowOverrideCalcs(seriesIds): boolean {
        console.log("seriesIds ", seriesIds, this.seriesPermissions);
        return Object.keys(this.seriesPermissions).filter(s => seriesIds.includes(s))
            .every(id => {
                    return this.seriesPermissions[id].includes(SeriesConstantPermissions.override_calculations)
                }
            );
    }

    updateCalculations() {
        const ids = this.allTreeSeries.map(s => s.id);
        if (!this.allowOverrideCalcs(ids)) {
            this.notification.openWarning("You don't have permissions to override calculations on all the selected nodes", EXTENDED_NOTIFICATION_DURATION_MS);
            return;
        }
        this.headerData.getCalculations(this.dateInst.dtp, ids, 'hour', 1)
            .then((data: { message?: string }) => {
                this.refreshNodes(this.allTreeSeries.map(s => s.id));
            }).catch(e => {
        });
    }

    private getParentIds(node, seriesIds: ModelID[]): ModelID[] {
        const id = node.data.series.id;
        if (!seriesIds.includes(id)) {
            seriesIds.push(node.data.series.id);
        }
        if (node.parent) {
            this.getParentIds(node.parent, seriesIds);
        }
        return seriesIds;
    }

    private refreshNodes(ids: ModelID[]) {
        this.headerData.refreshVDTNodeSubject.next(ids);
    }

    contextMenu(e, series, node) {
        let seriesIds: ModelID[] = this.getParentIds(node, []);
        const selectionChanged = ($event): void => {
            this.refreshNodes(seriesIds);
        }
        const data_config = {
            component: 'series-context-menu',
            position: {left: 10, top: 40},
            parameters: {
                selected_model: node.data.series,
                series_permission_ids: seriesIds
            }
        };
        const panelClass = 'series-context-menu';
        const modalDialog = this.formDialogService.openCustomDialog(e, data_config, panelClass, null, selectionChanged);

        return false;
    };

    dragEnd(unit, {sizes}) {
        if (unit === 'percent') {
            this.sizes.percent.area1 = sizes[0];
            this.sizes.percent.area2 = sizes[1];
        } else if (unit === 'pixel') {
            this.sizes.pixel.area1 = sizes[0];
            this.sizes.pixel.area2 = sizes[1];
        }
    }

}
