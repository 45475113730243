import {Component, Input} from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AppScope} from "../../services/app_scope.service";

@Component({
    selector: 'backdrop-overlay',
    templateUrl: './backdrop-overlay.component.html',
    styleUrls: ['./backdrop-overlay.component.less'],
    standalone: false
})
export class BackdropOverlayComponent {
  showOverlay$: Observable<boolean>;
  @Input() hidePage: boolean = false;

  constructor(private notification: NotificationService, private appScope: AppScope) {
    this.showOverlay$ = combineLatest([this.notification.snackbarOpen$, this.appScope.noticeBoardOpen$]).pipe(
        map(([snackbarOpen, noticeBoardOpen]) => snackbarOpen || noticeBoardOpen)
    );
  }
}
