import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AppScope} from "../app_scope.service";
import {AccountService} from '../account.service';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, take} from "rxjs/operators";
import {ApiService} from '../api/api.service';
import {NotificationService} from "../notification.service";
import {SingleResponse} from "../api/response-types";
import {Process} from "../../_models/process";
import { HttpStatusCode } from "@angular/common/http";

export const FocusViewGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const appScope: AppScope = inject(AppScope);
    const api: ApiService = inject(ApiService);
    const router: Router = inject(Router);
    const accountService: AccountService = inject(AccountService);
    const notification: NotificationService = inject(NotificationService);

    let account_id;
    if (appScope.navigate_inside === true) {
        return of(true);
    }

    if (!route.params.processID) {
        return of(false);
    }
    return appScope.currentUserValue.pipe(take(1),
        concatMap(() => api.process.getById(route.params.processID)),
        map((result: SingleResponse<Process>): boolean => {
            if (result['data'] && result['data'].relationships.account.data.id) {
                account_id = result['data'].relationships.account.data.id;
                return true;
            } else {
                console.log('FocusViewGuard - : error caught in map section, navigating to blank, let url-guard handle');
                notification.openError('You dont have access to this page. Redirecting.');
                router.navigate(['view', 'page_view', appScope.current_user.default_dashboard_id]);
                return false;
            }}
        ),
        concatMap((): Observable<boolean> => {
            if (account_id !== appScope.active_account_id) {
                return accountService.setActiveAccount(account_id).pipe(map(() => true));
            } else {
                return of(true);
            }
        }),
        catchError((err) => {
            console.log('caughtError: ', err, err.status === HttpStatusCode.NotFound);
            if (err.status === HttpStatusCode.NotFound) {
                notification.openError('Page not found. Redirecting.');
            } else {
                console.log('FocusViewGuard: You dont have access to this page: ');
                notification.openError('Unauthorised. Redirecting');
            }
            router.navigate(['view', 'page_view', appScope.current_user.default_dashboard_id]);
            return of(false);
        })
    );
};
