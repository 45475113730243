import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import * as utils from "../../lib/utils";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {catchError, map, takeUntil, tap, toArray} from "rxjs/operators";
import {concat, Observable, of, Subject, take} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {getBaseFilter, getRelationWithIdFilter} from "../../services/api/filter_utils";
import {Series} from '../../_models/series';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RawData} from "../../_models/raw-data";
import {ListResponse} from "../../services/api/response-types";
import {OptionalSnackbarParams} from "../../_typing/notification";
import {NotificationService} from "../../services/notification.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'apply-correction',
    templateUrl: './apply-correction.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ApplyCorrectionComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public series_list: any[] = [];
    public correctionMethods = [
        {value: "constant", display: "Apply constant value"},
        {value: "factor", display: "Apply correction factor"}];
    public selectedCorrectionMethod: string = 'constant';
    public seriesOrCalc: 'series' | 'calculation';
    public comment: string = null;
    public target_series: Series;
    public series: any;
    public events: any[];
    public use_offset: boolean = false;
    public target_value: number;
    public correction_factor_required: number;
    public correction_message: string;
    public offset: number;
    public factor: number;
    public value: number;
    public showing_hints: boolean = false;
    public hint: string = 'series';
    public applyHourly: boolean = false;
    public response: any;
    permissions: any = {};
    dtp: IDateTimePeriod;
    any: any;
    data_map: any = {};
    raw_data: any;
    working: boolean = false;
    initialise_factor: number = undefined;
    calculation_list: any[] = [];
    base_types: string[] = ['series', 'calculation'];
    is_calculation: boolean;
    non_calc_series_list: any[] = [];
    display_value: number = null;
    start_time: any;
    end_time: any;
    calculation_series_filter = [getBaseFilter(true, 'is_calculation')];
    series_filter = [{or: [getBaseFilter(false, 'is_calculation'), getBaseFilter(null, 'is_calculation')]}];

    constructor(
        private api: ApiService,
        private notification: NotificationService, public http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dateInst: DateTimeInstanceService,
        private seriesDataService: SeriesDataService,
        private headerData: HeaderDataService
    ) {
    }

    ngOnInit() {
        this.api.series_light.searchMany().subscribe((response) => {
            this.series_list = response.data;
            this.calculation_list = response.data.filter(series => series.attributes?.is_calculation);
            this.non_calc_series_list = response.data.filter(series => !series.attributes.is_calculation);
        });

        if (this.data && this.data.hasOwnProperty('series') && this.data['series'] && this.data['series'].attributes.is_calculation) {
            this.target_series = this.data['series'];
            this.is_calculation = true;
            this.seriesOrCalc = 'calculation';
        } else {
            this.series = this.data['series'];
            this.is_calculation = false;
            this.seriesOrCalc = 'series';
        }
        this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe((dtp) => {
            this.dtp = this.dateInst.dtp;
            console.log("dtp first set", this.dtp?.start);
        })

        if (this.series && this.series.id) {
            this.seriesDataService.getSeriesPermissions([this.series.id]).subscribe(permissions => {
                this.permissions = permissions;
            });
        }

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            this.display_value = null;
            if (this.selectedCorrectionMethod === 'factor') {
                this.getValue();
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

getResponse() {
    const response: any = {
        start: this.dtp.start.toISOString(),
        end: this.dtp.end.toISOString(),
        adjustment_type: this.selectedCorrectionMethod
    };

    const optionalFields = {
        series: this.series?.id,
        factor: this.factor,
        offset: this.offset,
        initialise_factor: this.initialise_factor,
        apply_hourly: this.applyHourly,
        value: this.value,
        use_offset: this.use_offset,
        comment: this.comment,
        target_value: this.target_value,
        target_series_id: this.target_series?.id,
    };

    for (const [key, value] of Object.entries(optionalFields)) {
        if (value !== undefined && value !== null) {
            response[key] = value;
        }
    }

    return response;
}

    getCorrectionHistory(series: Series) {
        const ctrl = this;
        ctrl.series = series;

        ctrl.api.event.search(ctrl.api.prep_q([{
            or: [{
                and: [
                    {name: 'start', op: 'ge', val: this.dtp.start},
                    {name: 'start', op: 'le', val: this.dtp.end}
                ]
            }, {
                and: [
                    {name: 'end', op: 'ge', val: this.dtp.start},
                    {name: 'end', op: 'le', val: this.dtp.end}
                ]
            }]
        },
            {name: 'series_list', op: 'any', 'val': {op: 'in', name: 'id', 'val': [this.series.id]}},
            {name: 'base_type', op: 'eq', val: 'correction_factor'}
        ])).toPromise().then((response) => {
            this.events = response.data;
        });

    }

    clearVariables() {
        this.use_offset = null;
        this.value = null;
        this.factor = null;
        this.offset = null;
        this.target_value = null;
    }

    applyCorrectionFactor() {
        this.correction_factor_required = this.factor;
        this.applyHourly = this.selectedCorrectionMethod === "constant";

        if (!(this.value) && this.selectedCorrectionMethod === "constant") {
            this.notification.openError('Please enter a value to apply to correction');
            return;
        }

        if (this.comment) {
            if (this.correction_factor_required || this.correction_factor_required === 0 || this.use_offset) {

                this.http.put('/api/applyCorrectionFactor' + '?' + utils.httpParamSerializer(
                    this.getResponse()
                ), null).pipe(map((data): OptionalSnackbarParams => {
                    this.getValue();
                    this.getCorrectionHistory(this.series);
                    return {
                        message: data['message'] + '\n' + data['raw_data_entries'] +
                            ' raw data entries adjusted, ' + data['updated_calculations'] + ' calculation entries updated, '
                            + data['total_changed'] + ' total entries updated'
                    };
                }), catchError((error): Observable<OptionalSnackbarParams> => {
                    throw new Error(`Could not apply correction - please check correction methodology ${error}`);
                })).subscribe({
                    next: (response) => {
                        this.notification.openSuccess(response.message);
                    }, error: (err) => {
                        this.notification.openError(err);
                    }
                });
            } else {
                this.notification.openError('Please add a correction factor or use an offset before applying');
                return;
            }

        } else {
            this.notification.openError('Please add a comment before applying a correction factor');
            return;
        }
    }

    getCorrectionFactor() {
        const ctrl = this;
        if (!this.series) {
            this.notification.openError('Please select a series to determine a correction factor for');
            return;
        }

        if (this.target_value) {
            this.api.get("/api/findCorrectionFactor" + '?' + utils.httpParamSerializer(
                this.getResponse()
            )).subscribe((data: any) => {
                this.correction_factor_required = data.correction_factor_required;
                this.correction_message = data.message;
                this.getValue();

                if (this.use_offset) {
                    this.offset = this.correction_factor_required;
                } else {
                    this.factor = this.correction_factor_required;
                }
                this.notification.openError(data.message);
            });
        } else {
            let message;

            if (!this.is_calculation) {
                if (this.series) {
                    message = `Please provide a target value for "${this.series.attributes.description}"`;
                } else if (!this.series) {
                    message = 'Please select a series to correct and provide a target value for this series';
                }
            } else if (this.is_calculation) {
                if (this.target_series) {
                    if (this.series) {
                        message = 'Please provide a target value for "' + this.target_series.attributes.description +
                            '" to determine the correction factor required for "' + this.series.attributes.description + '"';
                    } else {
                        message = 'Please provide a target value for this calculation.' +
                            ' Please also select a series to apply the correction to for this calculation';
                    }
                } else if (!this.target_series) {
                    message = 'Please select a calculation to correct and provide a target value for this calculation. ' +
                        'Please also select a series to apply the correction to for this calculation';
                }
            }
            this.notification.openError(message);
        }

    }

    overrideCalculations() {
        this.getCalculations();
    }

    getCalculations() {
        this.headerData.getCalculations(this.dtp, this.series.id, 'hour', 1).then(data => {
        }).catch();
    }

    checkCorrectionReady(): boolean {
        if (this.selectedCorrectionMethod && this.series && this.target_series) {
            return true;
        }
    }

    checkDayValueReady(): boolean {
        if (this.series && this.permissions[this.series.id] && this.permissions[this.series.id].includes('edit_process_data')) {
            return true;
        }
    }

    fetchRawData() {
        let observableRawData$: Observable<any>;
        this.data_map[this.series.id] = {};

        let date_counter = utils.deepCopy(this.dtp.start);
        while (date_counter <= this.dtp.end) {
            this.data_map[this.series.id][date_counter.toISOString()] = {};
            date_counter['addHours'](1);
        }
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('series', this.series.id),
            {
                name: 'time_stamp',
                op: 'le',
                val: this.dtp.end
            },
            {
                name: 'time_stamp',
                op: 'ge',
                val: this.dtp.start
            }
        ];
        observableRawData$ = this.api.raw_data.searchMany(options).pipe(map((data: ListResponse<RawData>) => {
            data.data.map((col: RawData) => {
                let iso_date: string = (new Date(col.attributes.time_stamp)).toISOString();
                this.data_map[this.series.id][iso_date] = col;
            });
        }), catchError((err) => {
            throw (err);
        }));
        return observableRawData$;
    }

    getValue() {
        const ctrl = this;
        let value_series;
        if (!ctrl.is_calculation) {
            value_series = ctrl.series
        } else if (ctrl.is_calculation) {
            value_series = ctrl.target_series
        }
        let calendar = null;

        if (this.dtp?.calendar) {
            calendar = this.dtp.calendar;
        }

        this.seriesDataService.getSeriesSummary(this.dtp, [value_series.id], null, ['Value']).pipe(
            tap(data => {
                ctrl.display_value = data[0]['Value'];
            }), take(1)
        ).subscribe();

    }

    applyDayValue() {
        if (!this.value && this.value !== 0) {
            this.notification.openError('Please enter an hourly value to apply');
            return;
        }
        let saved: number = 0;
        let updated: number = 0;

        if (this.value === null || isNaN(this.value)) {
            this.notification.openError('Value is null or not a number.');
        } else if (this.series === null) {
            this.notification.openError('No series selected');
        } else {
            this.notification.openSuccess('Working...', 2000);
            this.working = true;

            this.fetchRawData().subscribe(() => {
                let date_counter = utils.deepCopy(this.dtp.start);
                let obs$: Observable<any>[] = [];
                while (date_counter <= this.dtp.end) {
                    let raw_data = {
                        id: undefined, type: 'raw_data', attributes: {
                            value: this.value,
                            time_stamp: utils.deepCopy(date_counter)
                        }, relationships: {
                            series: {
                                data: {
                                    id: this.series.id,
                                    type: 'series'
                                }
                            }
                        }
                    };
                    if (this.data_map[this.series.id][raw_data.attributes.time_stamp.toISOString()].hasOwnProperty('id')) {
                        raw_data.id = this.data_map[this.series.id][raw_data.attributes.time_stamp.toISOString()].id;
                        obs$.push(this.api.raw_data.obsPatch(raw_data).pipe(map((result) => {
                            updated += 1;
                        }), catchError((err) => {
                            throw new Error(`Error: Data not saved for: ${raw_data.attributes.time_stamp}`);
                        })));
                    } else {
                        obs$.push(this.api.raw_data.obsSave(raw_data).pipe(map((result) => {
                            saved += 1;
                        }), catchError((err) => {
                            throw new Error(`Error: Data not saved for: ${raw_data.attributes.time_stamp}`);
                        })));
                    }
                    date_counter['addHours'](1);
                }
                concat(...obs$).pipe(toArray()).subscribe({
                    next: (response) => {
                        this.notification.openSuccess(
                            `${saved} new values saved. ${updated} values updated.`, 3000);
                        this.getCalculations();
                    }, error: (err) => {
                        console.log(err);
                        this.notification.openError('Some values were not saved, please check the console.');
                    }
                });
            });
        }
    }

    getPermissions(series: Series) {
        this.series = series;
        const ctrl = this;
        if (this.series.id) {
            this.seriesDataService.getSeriesPermissions([this.series.id]).subscribe(permissions => {
                this.permissions = permissions;
            });
        }
    }

    configure(series: Series) {
        let m = '';
        let desc_or_name = (series.attributes.description ? series.attributes.description :
            series.attributes.name);
        if (series.attributes?.is_calculation) {
            if (confirm('The series you selected: "' + desc_or_name + '" is a calculation.' +
                ' Would you like to apply a correction factor to this calculation instead?')) {
                this.target_series = undefined;
                this.target_series = series;
                m = ' Updated the calculation to : "' + series.attributes.description + '"';
            }
            this.series = undefined;
            this.notification.openError('You selected a calculation ' + desc_or_name + m + ' ' +
                ' Please re-select a series to apply a correction factor to');
        } else {
            this.series = series;
        }
    }
}
