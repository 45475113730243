import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IUserDetails} from "./wire-login-forms/user-details/user-details.component";
import {IUserPassword} from "./wire-login-forms/user-password/user-password.component";
import {KeycloakService} from "keycloak-angular";
import {Subject, Subscription} from "rxjs";

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LoginComponent implements OnInit {
    private readonly onDestroy: Subject<void> = new Subject<void>();
    isAuthenticated: Subscription;
    loginForm: FormGroup;
    forgotPasswordForm: FormGroup;
    detailsSubmitted: boolean = false;
    hide: boolean = true;

    returnUrl: string;
    require_otp: boolean;
    username: string;
    password: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public appScope: AppScope,
        protected readonly keycloak: KeycloakService
    ) {}

    ngOnInit() {
        this.appScope.no_header = true;
        this.isAuthenticated = this.appScope.isAuthenticated$.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.router.navigate(['view', 'page_view', this.appScope.current_user.default_dashboard_id]);
            }
        });
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            otp: ['']
        });

        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    }

    checkDetailsSubmitted(userDetail: IUserDetails): void {
        this.detailsSubmitted = userDetail.successfulSubmit;
        this.username = userDetail.userName;
    }

    checkPasswordSubmitted(userDetail: IUserPassword): void {
        this.require_otp = userDetail.requiresOTP;
        this.password = userDetail.password;
    }

    ngOnDestroy(): void {
        this.isAuthenticated.unsubscribe();
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
