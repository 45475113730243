import * as utils from '../lib/utils';
import {deepCopy, FlatPromise, uniqueList} from '../lib/utils';
import {Injectable} from "@angular/core";
import {ApiService} from "./api/api.service";
import {AppScope} from "./app_scope.service";
import { HttpClient } from "@angular/common/http";
import {DateTimePeriodService} from "./date-time-period.service";
import {NotificationService} from "../services/notification.service";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {SeriesFormComponent} from "../forms/series-form/series-form.component";
import {EstimateFormComponent} from "../forms/estimate-form.component";
import {SearchQueryOptions} from "./api/search-query-options";
import {Series, SeriesLight, SeriesSeriesData} from "../_models/series";
import {from, Observable, of, ReplaySubject, Subject} from "rxjs";
import {EventType} from "../_models/event-type";
import {concatMap, last, map, mergeMap, combineLatestWith, catchError} from "rxjs/operators";
import {TreeNode} from "../components/value-driver-tree/types/tree.node";
import {ListResponse, SingleResponse} from './api/response-types';
import {getRelationWithIdFilter, getManyRelationWithIdFilter} from "./api/filter_utils";
import {SeriesType} from '../_models/series-type';
import {ModelID, KeyMap} from "../_typing/generic-types";
import {RawData} from '../_models/raw-data';
import {
    SeriesSummary,
    SeriesSummaries,
    SeriesSummarySeries,
    GetSeriesData,
    SeriesGetSeriesData
} from "../_models/api/series-summary";
import {GenericDataService} from "../data/generic-data.service";
import {SeriesSeries} from "../_models/series-series";
import {RelationshipApiMappingService} from "../data/relationship-api-mapping.service";
import {HandleError, HttpErrorHandler} from "./http-error-handler.service";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {DateTimeInstanceService} from "./date-time-instance.service";

export interface SeriesColumnList {
    all?: string[];
    default?: string[];
    small?: string[];
    columns?: SeriesColumn[];
}

export interface SeriesColumn {
    name: string;
    contentFilter?: string;
    description?: string;
    title: string;
    deprecated?: boolean;
    abbr?: string;
    original?: string;
    estimate_name?: string;
}

@Injectable()
export class SeriesDataService {
    // TODO uplift the places where these 5 fields are called to wait for the promise to complete
    engineering_units: any[] = [];
    series_type_list: any[] = [];
    series_list: any[] = [];
    event_type_list: any[] = [];
    estimate_types_list: SeriesType[] = [];

    readonly eventTypesChanged: Subject<EventType[]> = new ReplaySubject<EventType[]>(1);
    readonly estimateTypesChanged: ReplaySubject<SeriesType[]> = new ReplaySubject<SeriesType[]>(1);
    // TODO this should only be the Promise, ie. store the FlatPromise.promise
    $estimate_types: FlatPromise;

    columnsAll: any[];
    readonly base_types: string[] = ['series', 'calculation',];
    readonly chart_types: { value: string; title: string; }[] = [
        {value: 'Line', title: 'Line'},
        {value: 'SPC', title: 'SPC'},
        {value: 'Bar', title: 'Bar'},
        {value: 'Budget Bar', title: 'Target Bar'},
        {value: '', title: ''}];
    readonly KPI_levels: string[] = ['Level 1', 'Level 2', 'Level 3', 'Level 4', '', null];
    readonly aggregation_types: string[] = ['max', 'min', 'mean', 'mean_nan', 'count', 'total', 'weighted_average',
        'latest_value', 'calculation'];
    readonly aggregation_definitions = {
        'max': 'Returns the largest series value of the chosen sample period',
        'min': 'Returns the smallest series value of the chosen period',
        'mean': 'Returns the average (arithmetic mean) of the series values of the chosen period',
        'mean_nan': 'Returns the average (arithmetic mean) of the series values of the chosen period and ignores missing values',
        'count': 'Returns the number of series values of the chosen period',
        'total': 'Returns the sum of the series values of the chosen period',
        // 'total_with_hourly': 'Total with hourly returns the total of the specified sample period divided by the number of hours between the start and the end',
        'weighted_average': 'Returns an average resulting from the multiplication of the specified series and the weighted average series for the chosen period',
        'latest_value': 'Returns the last value of the series for the chosen time period',
        'calculation': 'Returns a re-calculated series value for the chosen time period by inferring the aggregation type from calculation variables in real time.'
    };
    /**
     * Total with hourly returns the total of the specified sample period divided by the number of hours between the start and the end, thus it will display as hourly - but only for visualisation purposes e.g series table
     *
     */

    readonly sample_period_names: string[] = ['day', 'shift', 'two_hour', 'three_hour', 'four_hour', 'hour', 'week', 'month']; //fall back
    readonly fill_methods: object = {
        'Forward Fill': 'Fills missing values by propagating the last valid observation forward in time',
        'Backfill': 'Fills missing values by propagating the last valid observation backward in time',
        'Constant': 'Fills missing values by propagating the last known entry forward and backward,' +
            ' thus generating a constant value. Constant values can change over time by adjusting this constant at ' +
            'the specified time stamp',
        'Average': 'Fills missing values by using the average of the known valid observations in the time period, if no ' +
            'known values are present in the chosen period, fills values with last known value, else 0 if no values exist.',
        'Rolling average': 'Fills missing values by using the average of the known valid observations rolling average' +
            ' window (hours). If no valid data entries are found, this method defaults to backfill',
        'Default value': 'Fills missing values by using a provided default value',
        'Interpolate': 'Fills missing values by using linear interpolation. At the edges of a time period,' +
            ' known values are forward filled and backfilled if required.',
        'Constant Monthly Flatten': 'Flattens all valid entries within a month period',
        'backfill_padded_with_default_value': 'Backfills all known values and forward fills (pads)' +
            ' with the specified default value',
        'backfill_with_forward_fill_rolling_average': 'Backfills all known values and forward fills unknown hourly values with a rolling average' +
            ' as specified in the rolling average hours',
        'backfill_with_calculated_forward_fill_average_by_hours': 'Backfills all known values and forward fills unknown hourly values with a calculated average' +
            ' as specified in the look back hours',
        'interpolated_with_forward_fill_rolling_average': 'Interpolates between all known values' +
            ' up to the last valid known value.' +
            ' Forward fills with a rolling average of the rolling average window.',
        'interpolated_with_calculated_forward_fill_average_by_hours': 'Interpolates between all known values up to the last valid' +
            ' known value. Forward fills with an average value calculated from the average of all known valid values between the ' +
            'timespan of the last valid known value and the specified look back hours as specified in the rolling average window',
        'interpolated_with_calculated_forward_fill_average_by_count': 'Interpolates between all known values up to the last valid' +
            ' known value. Forward fills with an average value calculated from the average of all known valid values between the ' +
            'last valid known value and the specified look back number of values as specified by the average count',
        'backfill_with_limit': 'Backfills data by the defined number of hours, and forward fills thereafter . If no limit is set, this method backfill all unknown hourly values, and thereafter forwardfill ',
        null: 'No specified fill method'

    };

    readonly fuse_options: object = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 50,
        maxPatternLength: 32,
        minMatchCharLength: 2,
        keys: [
            "attributes.name",
            "attributes.description"
        ]
    };

    readonly readonly_event_columns = ['Duration', 'Changed On', 'Changed By'];

    get event_columns(): string[] {
        return ['Type', 'Start', 'End', 'Duration', 'Comment', 'Changed On', 'Changed By', 'Source', 'Destination'];
    }

    readonly aggregation_descriptions: { [key: string]: string } = {
        'max': 'Maximum',
        'min': 'Minimum',
        'mean': 'Mean',
        'mean_nan': 'Mean nan',
        'count': 'Count',
        'total': 'Total',
        'total_with_hourly': 'Total with hourly',
        'weighted_average': 'Weighted average',
        'latest_value': 'Latest value',
        'calculation': 'Calculation'
    };
    column_dict: { [key: string]: SeriesColumn } = {};

    readonly baseColumns: SeriesColumn[] =
        [
            {
                'name': 'Status',
                'contentFilter': 'status',
                'title': 'Status',
                'description': 'The status of the series: warning, alert or good.'
            },
            {'name': 'Name', 'title': 'Name', 'description': 'The WIRE name of a series'},
            {
                'name': 'Description',
                'title': 'Description',
                'description': 'The WIRE description of a series',
                'abbr': 'Descr'
            },
            {
                'name': 'Alias',
                'title': 'Alias',
                'description': 'The WIRE alias of a series',
                'abbr': 'Alias'
            },
            {
                'name': 'Average',
                'description': 'The average for the range queried',
                'contentFilter': 'significantNumber',
                'title': 'Average',
                'abbr': 'Avg.'
            },
            {
                'name': 'Count',
                'description': 'The number of actual data points for the range queried',
                'title': 'Count'
            },
            {
                'name': 'Current Value',
                'contentFilter': 'significantNumber',
                'title': 'Current Value',
                'description': 'The latest actual hourly value of a series',
                'abbr': 'Cur. Val.'
            },
            {
                'name': 'Value',
                'contentFilter': 'significantNumber',
                'title': 'Value',
                'description': 'The actual value of a series for the chosen time period',
                abbr: 'Val.'
            },
            {
                'name': 'Value Origin',
                'title': 'Value Origin',
                'description': 'This is no longer supported by WIRE.',
                deprecated: true,
                abbr: 'Val. Orig.'
            },
            {
                'name': 'Previous Value',
                'title': 'Previous Value',
                'description': 'The actual value of the previous sample period',
                'abbr': 'Pre. Val.'
            },
            {
                'name': 'Daily',
                'contentFilter': 'significantNumber',
                'title': 'Daily',
                'description': 'The actual value of the previous production day relative to the end of the time range specified'
            },
            {
                'name': 'Daily Variance',
                'contentFilter': 'significantNumber',
                'title': 'Daily Variance',
                'description': 'The difference between the Daily actual value and the estimate for the specified series',
                deprecated: true,
                abbr: 'Daily Var.'
            },
            {
                'name': 'Daily Variance %',
                'contentFilter': 'percentage',
                'title': 'Daily Variance %',
                'description': 'The difference between the Daily actual value and the estimate for the specified series',
                deprecated: true,
                abbr: 'Daily Var. %'
            },
            {
                'name': 'Latest Entry Date',
                'contentFilter': 'date',
                'title': 'Latest Entry Date',
                'description': 'The date of the lasted data entry',
                abbr: 'LTST ENT DT',
                deprecated: false,
            },
            {
                'name': 'Latest Entry Origin',
                'title': 'Latest Entry Origin',
                'description': 'The origin of the latest raw data entry',
                abbr: 'LTST ENT OR',
                deprecated: true
            },
            {
                'name': 'Latest Entry Value',
                'contentFilter': 'significantNumber',
                'title': 'Latest Entry Value',
                'description': 'The value of the lasted raw data entry for the specific series',
                abbr: 'LTST ENT VAL.',
                deprecated: true
            },
            {
                'name': 'WTD',
                'contentFilter': 'significantNumber',
                'title': 'WTD',
                'description': 'The Week to Date ' +
                    'actual value for the series'
            },
            {
                'name': 'Full Week',
                'contentFilter': 'significantNumber',
                'title': 'Full Week',
                'description': 'The actual value of the specified series from the first day of ' +
                    'the week until the end of the week.'
            },
            {
                'name': 'MTD',
                'contentFilter': 'significantNumber',
                'title': 'MTD',
                'description': 'The actual value of the specified series from the first day of ' +
                    'the month until the end of the chosen time range.'
            },
            {
                'name': 'Full Month',
                'contentFilter': 'significantNumber',
                'title': 'Full Month',
                'description': 'The actual value of the specified series from the first day of ' +
                    'the month until the end of the month.'
            },
            {
                'name': 'Full Year',
                'contentFilter': 'significantNumber',
                'title': 'Full Year',
                'description': 'The actual value of the specified series from the first day of ' +
                    'the year until the end of the year.'
            },
            {
                'name': 'MTD Variance',
                'contentFilter': 'significantNumber',
                'title': 'MTD Variance',
                'description': 'The difference between the MTD actual value and the estimate of the specified series',
                deprecated: true
            },
            {
                'name': 'MTD Variance %',
                'contentFilter': 'percentage',
                'title': 'MTD Variance %',
                'description': 'The % difference between the MTD actual value and the specified series estimate ' +
                    'for the chosen time range',
                deprecated: true,
                abbr: 'MTD Var. %'
            },
            {
                'name': 'QTD',
                'contentFilter': 'significantNumber',
                'title': 'QTD',
                'description': 'The actual value of the specified series from the start of ' +
                    'the current Quarter until the end specified time range.'
            },
            {
                'name': 'Full Quarter',
                'contentFilter': 'significantNumber',
                'title': 'Full Quarter',
                'description': 'The actual value of the specified series from the first day of ' +
                    'the quarter until the end of the quarter.'
            },
            {
                'name': 'Max',
                'contentFilter': 'significantNumber',
                'title': 'Max',
                'description': 'The largest numerical value for the chosen time range'
            },
            {
                'name': 'Min',
                'contentFilter': 'significantNumber',
                'title': 'Min',
                'description': 'The smallest numerical value for the chosen time range'
            },
            {
                'name': 'Median',
                'contentFilter': 'significantNumber',
                'title': 'Median',
                'description': 'The median is the value separating the higher half from the lower ' +
                    'half of the ordered data for the specified time range chosen. ',
                abbr: 'Med.'
            },
            {
                'name': '75 %',
                'contentFilter': 'percentage',
                'title': '75 %',
                'description': 'The third quartile. 75% of the data set is below the third quartile and 25% of ' +
                    'the data set is above the third quartile. The data set is all the data in the chosen time range'
            },
            {
                'name': '25 %',
                'contentFilter': 'percentage',
                'title': '25 %',
                'description': 'The first quartile. 25% ' +
                    'of the data set is below quartile 1 and 75% of ' +
                    'the data set is above quartile 1. The data set is all the data in the chosen time range'
            },
            {
                'name': 'Shift A',
                'contentFilter': 'significantNumber',
                'title': 'Shift A',
                'description': 'The actual value for the chosen period for shift A'
            },
            {
                'name': 'Shift B',
                'contentFilter': 'significantNumber',
                'title': 'Shift B',
                'description': 'The actual value for the chosen period for shift B'
            },
            {
                'name': 'Shift C',
                'contentFilter': 'significantNumber',
                'title': 'Shift C',
                'description': 'The actual value for the chosen period for shift C'
            },
            {
                'name': 'Shift D',
                'contentFilter': 'significantNumber',
                'title': 'Shift D',
                'description': 'The actual value for the chosen period for shift D'
            },
            {
                'name': 'Shift shift1',
                'contentFilter': 'significantNumber',
                'title': 'First shift',
                'description': 'The actual value for the first shift of the chosen period/day for the selected custom shift type'
            },
            {
                'name': 'Shift shift2',
                'contentFilter': 'significantNumber',
                'title': 'Second shift',
                'description': 'The actual value for the second shift of the chosen period/day for the selected custom shift type'
            },
            {
                'name': 'Shift shift3',
                'contentFilter': 'significantNumber',
                'title': 'Third shift',
                'description': 'The actual value for the third shift of the chosen period/day for the selected custom shift type'
            },
            {
                'name': 'Shift shift4',
                'contentFilter': 'significantNumber',
                'title': 'Fourth shift',
                'description': 'The actual value for the fourth shift of the chosen period/day for the selected custom shift type'
            },
            {
                'name': 'Shift shift5',
                'contentFilter': 'significantNumber',
                'title': 'Fifth shift',
                'description': 'The actual value for the fifth shift of the chosen period/day for the selected custom shift type'
            },
            {
                'name': 'Shift shift6',
                'contentFilter': 'significantNumber',
                'title': 'Sixth shift',
                'description': 'The actual value for the sixth shift of the chosen period/day for the selected custom shift type'
            },
            {
                'name': 'Sum', 'contentFilter': 'significantNumber', 'title': 'Sum', 'description': 'The numerical' +
                    ' total (sum) of all the data points for the chosen period'
            },
            {'name': 'Unit', 'title': 'Unit', 'description': 'The engineering unit specified for a series'},
            {
                'name': 'YTD',
                'contentFilter': 'significantNumber',
                'title': 'YTD',
                'description': 'The Year to Date ' +
                    'actual value for the series'
            },
            {
                'name': 'YTD Variance',
                'contentFilter': 'significantNumber',
                'title': 'YTD Variance',
                'description': 'The numerical difference between the actual value of the series and the estimated ' +
                    'value of the series year to date',
                deprecated: true,
                abbr: 'YTD Var.'

            },
            {
                'name': 'YTD Variance %',
                'contentFilter': 'percentage',
                'title': 'YTD Variance %',
                'description': 'The percentage difference between the actual value and the estimated value for the ' +
                    'YTD values the specified series',
                deprecated: true,
                abbr: 'YTD Var. %'
            },
            {
                'name': 'Sparkline',
                'contentFilter': 'significantNumber',
                'title': 'Sparkline',
                'description': 'A small trend-line like graph indicating the overall trend of the series for the ' +
                    'chosen time period',
                abbr: ''
            }
        ];

    readonly schema: Series = {
        id: null,
        type: 'series',
        attributes: {
            base_type: 'series',
            account_name: null,
            accumulation: null,
            aggregation: null,
            alias: null,
            allow_edit: null,
            alternate_names: null,
            assumptions: null,
            budget: null,
            changed_on: null,
            collector_names: null,
            created_on: null,
            decimal_places: null,
            default_chart: null,
            default_value: null,
            delete_hihilowlow: null,
            description: null,
            extra_arguments_string: '{}',
            fill_method: null,
            hi: null,
            hihi: null,
            is_calculation: false,
            json: {comment: null},
            kpi_level: null,
            linked_components: null,
            low: null,
            lowlow: null,
            mean: null,
            name: null,
            name_formula: null,
            rolling_average_hours: null,
            sample_offset: null,
            sample_period: null,
            specialised_function: null,
            std: null,
        },
        relationships: {
            engineering_unit: {data: {id: null, type: 'engineering_unit'}},
            series_type: {data: {id: null, type: 'series_type'}},
            event_type: {data: {id: null, type: 'event_type'}},
            weighted_average_series: {data: {id: null, type: 'series'}},
            source_series: {data: [{id: null, type: 'series'}]},
            target_series: {data: [{id: null, type: 'series'}]},
            created_by: {data: {id: null, type: 'users'}},
            changed_by: {data: {id: null, type: 'users'}},
            account: {data: null},
        }
    };
    sample_periods: string[];
    readonly fore_cast_cols: SeriesColumn[] =
        [
            {
                name: '@',
                title: '@',
                abbr: '@',
                description: 'The @ (estimate) value for a specific series for a chosen period of time',
                contentFilter: ''
            },
            {
                name: '@ Variance',
                title: '@ Variance',
                abbr: '@ Var',
                contentFilter: '',
                description: 'The difference between the @ (estimate) and the actual value for a specific series for a chosen period'
            },
            {
                name: '@ Variance %',
                title: '@ Variance %',
                abbr: '@ Var %',
                contentFilter: '',
                description: 'The percentage difference between the @ (estimate) and the actual value for a specific series for the chosen period'
            },
            {
                name: 'Daily @',
                title: 'Daily @',
                abbr: 'Daily @',
                contentFilter: '',
                description: 'The Daily @ (estimate) for a specific series for the previous production day relative to the end date of the chosen time period'
            },
            {
                name: 'Daily @ Variance',
                title: 'Daily @ Variance',
                abbr: 'Daily @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series and the @ (estimate) for the previous production day '
            },
            {
                name: 'Daily @ Variance %',
                title: 'Daily @ Variance %',
                abbr: 'Daily @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series and the @ (estimate) for the previous production day'
            },
            {
                name: 'Daily @ Trend',
                title: 'Daily @ Trend %',
                abbr: 'Daily @ Trend',
                contentFilter: '',
                description: 'The favourability for the series actual value and the @ (estimate)'
            },
            {
                name: 'WTD @',
                title: 'WTD @',
                abbr: 'WTD @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the week to date relative to the end date of the chosen period'
            },
            {
                name: 'WTD @ Variance',
                title: 'WTD @ Variance',
                abbr: 'WTD @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series month to date and the @ (estimate)'
            },
            {
                name: 'WTD @ Trend',
                title: 'WTD @ Trend %',
                abbr: 'WTD @ Trend',
                contentFilter: '',
                description: 'The favourability for the series week to date and the @ (estimate)'
            },
            {
                name: 'Full Week @',
                title: 'Full Week @',
                abbr: 'Full Week @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the last full week of the chosen period'
            },
            {
                name: 'Full Week @ Variance',
                title: 'Full Week @ Variance',
                abbr: 'Full Week @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series for the full week and the @ (estimate)'
            },
            {
                name: 'Full Week @ Variance %',
                title: 'Full Week @ Variance %',
                abbr: 'Full Week @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series for the full week and the @ (estimate)'
            },
            {
                name: 'Full Week @ Trend',
                title: 'Full Week @ Trend %',
                abbr: 'Full Week @ Trend',
                contentFilter: '',
                description: 'The favourability for the series full week and the @ (estimate)'
            },
            {
                name: 'WTD @ Variance %',
                title: 'WTD @ Variance %',
                abbr: 'WTD @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series week to date and the @ (estimate)'
            },
            {
                name: 'MTD @',
                title: 'MTD @',
                abbr: 'MTD @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the month to date relative to the end date of the chosen period'
            },
            {
                name: 'MTD @ Variance',
                title: 'MTD @ Variance',
                abbr: 'MTD @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series month to date and the @ (estimate)'
            },
            {
                name: 'MTD @ Variance %',
                title: 'MTD @ Variance %',
                abbr: 'MTD @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series month to date and the @ (estimate)'
            },
            {
                name: 'MTD @ Trend',
                title: 'MTD @ Trend %',
                abbr: 'MTD @ Trend',
                contentFilter: '',
                description: 'The favourability for the series month to date and the @ (estimate)'
            },
            {
                name: 'Full Month @',
                title: 'Full Month @',
                abbr: 'Full Month @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the last full month of the chosen period'
            },
            {
                name: 'Full Month @ Variance',
                title: 'Full Month @ Variance',
                abbr: 'Full Month @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series for the full month and the @ (estimate)'
            },
            {
                name: 'Full Month @ Variance %',
                title: 'Full Month @ Variance %',
                abbr: 'Full Month @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series for the full month and the @ (estimate)'
            },
            {
                name: 'Full Month @ Trend',
                title: 'Full Month @ Trend %',
                abbr: 'Full Month @ Trend',
                contentFilter: '',
                description: 'The favourability for the series full month and the @ (estimate)'
            },
            {
                name: 'Full Quarter @',
                title: 'Full Quarter @',
                abbr: 'Full Quarter @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the last full quarter of the chosen period'
            },
            {
                name: 'Full Quarter @ Variance',
                title: 'Full Quarter @ Variance',
                abbr: 'Full Quarter @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series for the full quarter and the @ (estimate)'
            },
            {
                name: 'Full Quarter @ Variance %',
                title: 'Full Quarter @ Variance %',
                abbr: 'Full Quarter @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series for the full quarter and the @ (estimate)'
            },
            {
                name: 'Full Quarter @ Trend',
                title: 'Full Quarter @ Trend %',
                abbr: 'Full Quarter @ Trend',
                contentFilter: '',
                description: 'The favourability for the series full quarter and the @ (estimate)'
            },

            {
                name: 'Full Year @',
                title: 'Full Year @',
                abbr: 'Full Year @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the last full year of the chosen period'
            },
            {
                name: 'Full Year @ Variance',
                title: 'Full Year @ Variance',
                abbr: 'Full Year @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series for the full year and the @ (estimate)'
            },
            {
                name: 'Full Year @ Variance %',
                title: 'Full Year @ Variance %',
                abbr: 'Full Year @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series for the full year and the @ (estimate)'
            },
            {
                name: 'Full Year @ Trend',
                title: 'Full Year @ Trend %',
                abbr: 'Full Year @ Trend',
                contentFilter: '',
                description: 'The favourability for the series full year and the @ (estimate)'
            },
            {
                name: 'QTD @',
                title: 'QTD @',
                abbr: 'QTD @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the quarter to date relative to the end date of the chosen period'
            },
            {
                name: 'QTD @ Variance',
                title: 'QTD @ Variance',
                abbr: 'QTD @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series quarter to date and the @ (estimate)'
            },
            {
                name: 'QTD @ Variance %',
                title: 'QTD @ Variance %',
                abbr: 'QTD @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series quarter to date and the @ (estimate)'
            },
            {
                name: 'QTD @ Trend',
                title: 'QTD @ Trend %',
                abbr: 'QTD @ Trend',
                contentFilter: '',
                description: 'The favourability for the series quarter to date and the @ (estimate)'
            },
            {
                name: 'YTD @',
                title: 'YTD @',
                abbr: 'YTD @',
                contentFilter: '',
                description: 'The value for @ (estimate) for a series for the year to date relative to the end date of the chosen period'
            },
            {
                name: 'YTD @ Variance',
                title: 'YTD @ Variance',
                abbr: 'YTD @ Var',
                contentFilter: '',
                description: 'The difference between the actual value for a series year to date and the @ (estimate)'
            },
            {
                name: 'YTD @ Variance %',
                title: 'YTD @ Variance %',
                abbr: 'YTD @ Var %',
                contentFilter: '',
                description: 'The percentage difference between the actual value for a series year to date and the @ (estimate)'
            },
            {
                name: 'YTD @ Trend',
                title: 'YTD @ Trend %',
                abbr: 'YTD @ Trend',
                contentFilter: '',
                description: 'The favourability for the series year to date and the @ (estimate)'
            }
        ];

    est_favourability_dict = {'Value': 'Favourable Value'};

    //columns = angular.copy(seriesData.baseColumns);
    readonly columnsDefault: string[] = ['Status', 'Name', 'Description', 'Value', 'Forecast', 'MTD', 'MTD Forecast'];
    readonly columnsDetail: string[] = ['Daily Variance', 'MTD Variance', 'Shift A', 'Shift B', 'Count', 'Average', 'Min', 'Max', 'Sum'];
    readonly columnsSmall: string[] = ['Description', 'Value', 'Forecast']; //Default for small screens/contexts
    private readonly handleError: HandleError;

    loop_check = 0;
    calculation_tree_list: Series[];

    constructor(private api: ApiService,
                private appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private http: HttpClient,
                private notification: NotificationService,
                private dialog: MatDialog,
                private genericData: GenericDataService,
                private mappingService: RelationshipApiMappingService,
                private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('HeroesService');
        const ctrl = this;
        this.baseColumns.forEach(bc => {
            this.column_dict[bc.name] = bc;
        });

        this.$estimate_types = new FlatPromise();
        let $col_dict = new FlatPromise();

        api.series_type.searchMany().toPromise().then(response => {
            ctrl.estimate_types_list = response.data;

            ctrl.estimate_types_list.forEach(est => {
                ctrl.fore_cast_cols.forEach(function (col) {
                    let name = col.name.replace('@', est.attributes.name);
                    let description = col.description.replace('@', est.attributes.name);
                    let abbreviation = est.attributes.abbreviation;
                    let abbr = abbreviation ?
                        col.abbr.replace('@', abbreviation) :
                        col.name.replace('@', est.attributes.name);
                    let contentFilter = col.contentFilter;
                    if (!contentFilter) {
                        contentFilter = name.includes('%') ? 'percentage' : 'significantNumber';
                    }
                    ctrl.column_dict[name] = {
                        'name': name,
                        contentFilter: contentFilter,
                        title: name,
                        description: description,
                        abbr: abbr
                    };

                    // Creating a mapping between estimate calcs from GSS and their favourability
                    if (name.includes('Daily')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Daily ' + est.attributes.name;
                    } else if (name.includes('YTD')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'YTD ' + est.attributes.name;

                    } else if (name.includes('MTD')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'MTD ' + est.attributes.name;

                    } else if (name.includes('WTD')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'WTD ' + est.attributes.name;

                    } else if (name.includes('Full Month')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Full Month ' + est.attributes.name;

                    } else if (name.includes('QTD')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'QTD ' + est.attributes.name;

                    } else if (name.includes('Full Quarter')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Full Quarter ' + est.attributes.name;

                    } else if (name.includes('Full Year')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Full Year ' + est.attributes.name;

                    } else if (name === est.attributes.name) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Value';

                    } else {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Value';
                    }

                });
            });
            ctrl.$estimate_types.resolve(ctrl.estimate_types_list);
            ctrl.estimateTypesChanged.next(ctrl.estimate_types_list);
            ctrl.getColumnMap().promise.then(() => $col_dict.resolve());

        });

        dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.sample_periods = dateTimePeriodService.sample_periods
                .filter(sp => !sp.parent)
                .map(sp => sp.name);
        })
    }

    fillColumns(estimate_type_names: string[] = ['Forecast']): SeriesColumnList {
        const ctrl = this;

        //for backwards compatibablity
        if (!(Array.isArray(estimate_type_names))) {
            estimate_type_names = [estimate_type_names];
        }

        let columnsDefault = ['Status', 'Name', 'Description', 'Value', 'MTD'];

        let columnsSmall = ['Description', 'Value']; //Default for small screens/contexts

        estimate_type_names.forEach(est_name => {
            columnsDefault.push(est_name);
            columnsDefault.push('MTD ' + est_name);
            columnsSmall.push(est_name);
        });

        let columns = utils.deepCopy(this.baseColumns);
        estimate_type_names.forEach(est_name => {
            ctrl.fore_cast_cols.forEach(col => {
                let name = col.name.replace('@', est_name);
                let description = col.description.replace('@', est_name);
                let original = col.name.replace(" @", "").replace("@", "Value");

                if (name.includes('%')) {
                    columns.push({
                        'name': name,
                        'contentFilter': 'percentage',
                        'title': name,
                        description: description,
                        original: original,
                        estimate_name: est_name
                    });
                } else {
                    columns.push({
                        'name': name,
                        'contentFilter': 'significantNumber',
                        'title': name,
                        description: description,
                        original: original,
                        estimate_name: est_name
                    });
                }

            });
        });

        let columnsAll = columns.map(function (column) {
            // @ts-ignore
            return column.name;
        });

        //TODO update dashboard to use this return value instead of old seriesData
        return {'all': columnsAll, 'default': columnsDefault, 'small': columnsSmall, 'columns': columns};
    };

    filterColumns(toFilter, remove) {
        if (toFilter) {
            toFilter = toFilter.filter(function (c) {
                return !c.includes(remove);
            });
        } else {
            toFilter = [];
        }
        return toFilter;
    };

    getSeriesLightListByName(series_list: string[]): Observable<ListResponse<SeriesLight>> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'name', op: 'in', val: series_list}];
        return this.api.series_light.searchMany(options);
    }

    getSeriesLightListById(series_ids: ModelID[]): Observable<ListResponse<SeriesLight>> {
        if (!series_ids || series_ids.length < 1) {
            return of(null);
        }
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: series_ids}];
        return this.api.series_light.searchMany(options);
    }

    getSeriesListByIds(series_ids: string[]): Observable<ListResponse<Series>> {
        if (!series_ids || series_ids.length < 1) {
            return of(null);
        }
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: series_ids}];
        return this.api.series.searchMany(options);
    }

    getSeriesPermissions(series_ids): Observable<any> {
        return this.api.get("/auth/get_series_permissions?" + utils.httpParamSerializer({series: series_ids}));
    }

    upsertSeries(component?, series?: Series, source?: Series, tab_index = 0): MatDialogRef<SeriesFormComponent> {
        const dialogConfig = new MatDialogConfig();

        if (series == null) {
            series = new Series();
        }
        dialogConfig.panelClass = ['default-form-dialog', 'series-form-dialog'];
        dialogConfig.data = {
            series: series,
            component: component,
            seriesData: this,
            tab: tab_index,
            dtp: this.dateInst.dtp,
            source_series: source ? [source] : null
        };

        return this.dialog.open(SeriesFormComponent, dialogConfig);
    };

    estimateForm(selected_estimate_type, series_list, estimate = null): MatDialogRef<EstimateFormComponent, any> {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            selectedEstimateType: selected_estimate_type,
            seriesList: series_list,
            estimate: estimate,
            seriesData: this
        };

        dialogConfig.panelClass = ['default-form-dialog', 'estimate-form-dialog'];
        return this.dialog.open(EstimateFormComponent, dialogConfig);
        // let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);
        // dialogRef.afterClosed().toPromise().then(response => {
        //})

    }

    getSeriesSeriesData(series_id: ModelID, rel_name = 'source_series'): Observable<SeriesSeriesData[]> {
        let $series_estimates: Observable<RawData>;
        let estimate_series_data: SeriesSeriesData[] = [];
        const estimateOptions = new SearchQueryOptions();
        estimateOptions.filters = [
            getManyRelationWithIdFilter(rel_name, series_id)
        ];
        const $estimate_series = this.api.series.searchMany(estimateOptions);

        return this.estimateTypesChanged.pipe(combineLatestWith($estimate_series),
            concatMap(([estimate_types, estimate_series]) => {
                if (!estimate_series?.data?.length) {
                    return of([]);
                }
                const options = new SearchQueryOptions();
                const est_ids = estimate_series.data.map(e => e.id);

                return this.getSeriesSummary(this.dateInst.dtp, est_ids, null, ['Value'])
                    .pipe(
                        map((sdata: SeriesSummaries) => {
                            estimate_series.data.map(estimate => {
                                const value = sdata?.find(s => s.ID === estimate.id)?.Value || null;
                                estimate_series_data.push(Object.assign(estimate, {
                                    current_value: value,
                                    positive_variance: true
                                }));
                            })
                            return estimate_series_data;
                        }))
            }))

    };

    getColumnMap() {
        const ctrl = this;
        let $p = new FlatPromise();
        ctrl.appScope.auth_complete.promise.then(() => {
            let column_mappings = ctrl.appScope.config_name_map['series_summary_column_map'] ?
                ctrl.appScope.config_name_map['series_summary_column_map'].value : [];
            column_mappings?.forEach(col_map => {
                if (ctrl.column_dict[col_map.name]) {
                    if (col_map.title) {
                        ctrl.column_dict[col_map.name].title = col_map.title;
                    }
                    if (col_map.abbr) {
                        ctrl.column_dict[col_map.name].abbr = col_map.abbr;
                    }
                }
            });
            $p.resolve();
        });
        return $p;
    }

    getEstimateTypesList(columnList, estimateTypesList): string[] {
        // Return set of Estimate Type names that exist in the column list
        let list = [];
        estimateTypesList.sort((a, b) => b.attributes.name.length - a.attributes.name.length);
        columnList.forEach(col => {
            let col_array = col.split(" ");
            for (let e = 0; e < estimateTypesList.length; e++) {
                const est_type = estimateTypesList[e];
                if (col.indexOf(est_type.attributes.name) > -1) {
                    list.push(est_type.attributes.name);
                    break;
                } else if (col_array.includes(est_type.attributes.name)) {
                    list.push(est_type.attributes.name);
                    break;
                }
            }
        });
        return uniqueList(list);
    }

    getCalculationsLoop(dependencies): Observable<any> {
        this.loop_check += 1;
        if (this.loop_check > 1000) {
            return;
        }
        return this.getCalculationsById(dependencies).pipe(
            mergeMap((calc_list: any) => {
                if (this._checkCircularRefsExist(calc_list)) {
                    return;
                }

                this.calculation_tree_list = this.calculation_tree_list.concat(calc_list);
                return from(calc_list).pipe(
                    mergeMap((calc: Series) => {
                        const vars = calc.attributes.dependencies;
                        if (calc.attributes?.is_calculation && vars?.length > 0) {
                            return this.getCalculationsLoop(vars);
                        } else {
                            return of(calc);
                        }
                    })
                );
            }), map(result => this.calculation_tree_list));
    }

    private _checkCircularRefsExist(calcList: Series[]): boolean {
        // build adjacency list
        const newCalcList = this.calculation_tree_list.concat(calcList);
        const graph = newCalcList.reduce<Record<string, string[]>>((acc, calc) => {
            if (calc.id in acc) {
                acc[calc.id] = acc[calc.id].concat(calc.attributes.dependencies || []);
            } else {
                acc[calc.id] = calc.attributes.dependencies || [];
            }
            return acc;
        }, {});

        const visited: Record<string, boolean> = {};
        const recursionStack: Record<string, boolean> = {};
        const pathStack: string[] = [];
        let cyclePath: string[] = [];

        // if we're recursing this deep something else is wrong.
        const maxCycleDepthCheck = 10_000;

        // perform dfs to check for cycles
        const hasCycle = (nodeId: string, depth = 0): boolean => {
            if (depth >= maxCycleDepthCheck) {
                // TODO: investigate realistic depth range
                this.notification.openError("Circular reference checking reached a depth limit.");
                return true;
            }
            if (!visited[nodeId]) {
                visited[nodeId] = true;
                recursionStack[nodeId] = true;
                pathStack.push(nodeId);

                for (const depId of graph[nodeId] || []) {
                    if (!visited[depId] && hasCycle(depId, depth + 1)) {
                        return true;
                    } else if (recursionStack[depId]) {
                        // Found a cycle - capture the path
                        const cycleStartIndex = pathStack.indexOf(depId);
                        cyclePath = pathStack.slice(cycleStartIndex).concat(depId);
                        return true;
                    }
                }
                pathStack.pop();
            }
            recursionStack[nodeId] = false;
            return false;
        };

        // check each node in the adjacency list for cycles
        for (const nodeId of Object.keys(graph)) {
            if (!visited[nodeId] && hasCycle(nodeId)) {
                // Get series names for the cycle path
                const cycleWithNames = cyclePath.map(id => {
                    const series = newCalcList.find(s => s.id === id);
                    return series ? series.attributes.name : id;
                });

                const cycleStr = cycleWithNames.join(" → ");
                this.notification.openError(`Circular references were detected in the calculations: ${cycleStr}`);
                return true;
            }
        }

        return false;
    }

    getCalculationTreeNodeMap(series: Series): Observable<KeyMap<TreeNode>> {
        this.calculation_tree_list = [series];
        let source: Observable<any>;
        if (series.attributes?.is_calculation) {
            source = this.api.calculation_light.getById(series.id)
                .pipe(concatMap((calculation: SingleResponse<Series>) => {
                    this.calculation_tree_list = [calculation.data];
                    const dependencies = calculation.data.attributes.dependencies;
                    if (dependencies && dependencies.length > 0) {
                        return this.getCalculationsLoop(dependencies);
                    } else {
                        return of([]);
                    }
                }));
        } else {
            source = of(this.calculation_tree_list);
        }
        return source.pipe(last(),
            map((calc_tree: Series[]) => this.generateTreeMap(calc_tree)
            ));
    }

    getCalculationsById(series_list: ModelID[]): Observable<any> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: series_list}];
        return this.api.calculation_light.searchMany(options)
            .pipe(map((series: ListResponse<Series>) => {
                    return series.data.map(s => s)
                })
            );
    }

    generateTreeMap(list: Series[]): { [key: string]: TreeNode } {
        const map: { [key: string]: TreeNode } = {};
        list.forEach(series => {
            let node = map[series.id];
            if (!node) {
                // node was not yet created by a child
                node = new TreeNode();
                map[series.id] = node;
            }
            // assign series to node and assign children to this node
            node.series = series;

            if (series.attributes.is_calculation) {
                const variables = series.attributes.dependencies;
                if (variables && variables.length > 0) {
                    node.children = variables.map(variable => {
                        // find child node and create & add to map if not exist
                        let child_node = map[variable];
                        if (!child_node) {
                            child_node = new TreeNode();
                            map[variable] = child_node;
                        }
                        // TODO check for duplicate children (maybe calcs that reference series multiple times?)
                        return child_node;
                    });
                }
            }
        });
        return map;
    }

    getSeriesById(series_id: ModelID): Observable<any> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'eq', val: series_id}];

        return this.api.series.searchSingle(options);
    }

    getShiftTypeFromDtp(dtp: IDateTimePeriod): number {
        const shiftDict: KeyMap<number> = this.dateTimePeriodService.shiftNameIdDict;
        if (!shiftDict) {
            return 0;
        }
        let shiftId: number = 0;
        Object.keys(shiftDict).forEach((name: string): void => {
            if (dtp.range.includes(name) || dtp.sample_period.name.includes(name)) {
                shiftId = shiftDict[name];
            }
        });
        return shiftId;
    }

    getSeriesSummary(dtp: IDateTimePeriod, seriesIds?: ModelID[], processId?: ModelID, columns?: string[], estimateTypes?: string[], shiftTypeId?: number, single: boolean = false): Observable<SeriesSummaries> {
        const sample_period = dtp.sample_period.name === 'month' ? dtp.sample_period.name : dtp.sample_period.wire_sample;
        shiftTypeId = shiftTypeId || this.getShiftTypeFromDtp(dtp);
        const params = {
            return_type: 'json',
            format: 'records',
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
            period_type: dtp.calendar,
            deepness: 2,
            sample_period: sample_period,
            estimate: estimateTypes,
            columns: columns,
            single: single
        };

        if (processId) {
            params['process'] = processId;
        }

        if (seriesIds?.[0]) {
            params['series_list'] = seriesIds;
        }

        if (shiftTypeId) {
            params['shift_type_id'] = shiftTypeId;
        }
        return this.api.get_series_summary(params);
    }

    getSeriesData(dtp: IDateTimePeriod, seriesIds?: ModelID[], processId?: ModelID, columns?: string[], shiftTypeId?: number, format?: string): Observable<SeriesGetSeriesData> {
        const sample_period = dtp.sample_period.name === 'month' ? dtp.sample_period.name : dtp.sample_period.wire_sample;
        shiftTypeId = shiftTypeId || this.getShiftTypeFromDtp(dtp);
        const params = {
            return_type: 'json',
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
            period_type: dtp.calendar,
            deepness: 2,
            sample_period: sample_period,
            columns: columns
        };
        if (format) {
            params['format'] = 'records';
        }
        if (processId) {
            params['process'] = processId;
        }

        if (seriesIds?.[0]) {
            params['series_list'] = seriesIds;
        }

        if (shiftTypeId) {
            params['shift_type_id'] = shiftTypeId;
        }

        return this.api.get_series_data(params);
    }

    mapSeriesToSeriesSummary(series: Series[] | SeriesLight[], series_summary: SeriesSummary[]): KeyMap<SeriesSummarySeries> {
        const dict: KeyMap<SeriesSummarySeries> = {};
        series.forEach(s => {
            const gss = series_summary.find(gss => gss.ID === s.id);
            dict[s.id] = Object.assign(s, gss);
        })
        return dict;
    }

    mapSeriesToSeriesData(series: Series[], series_data: GetSeriesData): KeyMap<SeriesGetSeriesData> {
        const dict: KeyMap<SeriesGetSeriesData> = {};
        let seriesDict = {};
        series.map(s => seriesDict[s.id] = s);
        series.forEach(s => {
            const name = s.attributes.name;
            const data = {data: series_data.data[name]};
            const missing_values = {missing_values: series_data.missing_values[name]};
            dict[s.id] = Object.assign(s, data, missing_values);
        })
        return dict;
    }

    saveSeries(series: Series) {
        return this.genericData.upsertModel<Series>('series', series);
    }

    saveSeriesSeries(series_series: SeriesSeries) {
        return this.genericData.upsertModel<Series>('series_series', series_series);
    }

    saveSourceSeries(target: Series, source: Series, positive_variance = true, id: ModelID = null): Observable<SingleResponse<SeriesSeries>> {
        let series_series = new SeriesSeries();
        series_series.relationships.source_series.data.id = source.id;
        series_series.relationships.target_series.data.id = target.id;
        series_series.attributes.source_series_id = source.id;
        series_series.attributes.target_series_id = target.id;
        series_series.attributes.positive_variance = positive_variance;
        series_series.id = id;

        return this.genericData.upsertModel<SeriesSeries>('series_series', series_series);
    }

    saveManySourceSeries(current_series: Series, old_source_series, new_source_series, series_series_list) {
        return this.mappingService.saveMany('series_series', 'target_series', current_series.id, 'source_series',
            new_source_series, old_source_series, series_series_list, undefined, undefined, 'series')
            .pipe(catchError(this.handleError<SingleResponse<any>>('save Many Source series ')), last());
    }

    saveManyTargetSeries(current_series: Series, old_target_series, new_target_series, series_series_list) {
        return this.mappingService.saveMany('series_series', 'source_series', current_series.id, 'target_series',
            new_target_series, old_target_series, series_series_list, undefined, undefined, 'series')
            .pipe(catchError(this.handleError<SingleResponse<any>>('save Many Target Series')), last());
    }

    getTargetSeriesByType(source_series_id: ModelID, series_type_id: ModelID): Observable<ListResponse<Series>> {
        const options = new SearchQueryOptions()
        options.filters = [getManyRelationWithIdFilter('source_series', source_series_id),
            getRelationWithIdFilter('series_type', series_type_id)];
        return this.api.series.searchMany(options);
    }

    getTargetSeries(series_id: ModelID): Observable<ListResponse<SeriesSeries>> {
        const options = new SearchQueryOptions()
        options.filters = [getRelationWithIdFilter('source_series', series_id)];
        return this.api.series_series.searchMany(options);
    }

    getSourceSeries(series_id: ModelID): Observable<ListResponse<SeriesSeries>> {
        const options = new SearchQueryOptions()
        options.filters = [getRelationWithIdFilter('target_series', series_id)];
        return this.api.series_series.searchMany(options);
    }

    getSeriesBySource(series_id: ModelID): Observable<ListResponse<Series>> {
        const options = new SearchQueryOptions()
        options.filters = [getManyRelationWithIdFilter('source_series', series_id)]
        return this.api.series.searchMany(options);
    }
}
