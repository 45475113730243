import {
    Directive,
    HostListener,
    EventEmitter,
    Renderer2,
    OnInit,
    OnDestroy,
    ElementRef,
    Input,
    AfterViewInit,
    Output
} from '@angular/core';
import {AppScope} from '../services/app_scope.service';
import * as utils from '../lib/utils';
import {takeUntil, debounceTime, tap, distinctUntilChanged, filter, withLatestFrom} from "rxjs/operators";
import {Subject, fromEvent, combineLatest, race, Subscription} from "rxjs";
import {matchRegex, refreshSubscription} from "../lib/utils";
import {ClearConstantService} from "../components/constant-field/clear-constant.service";

@Directive({
    selector: '[appFloatingInput]',
    standalone: false
})
export class FloatingInputDirective implements AfterViewInit, OnDestroy {
    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
        this.loadTextAreaValueFromInput();
    }

    private readonly onDestroy = new Subject<void>();

    private textArea: HTMLTextAreaElement;
    _on = false;
    private position = {left: 0, top: 0};
    private textarea_rows = 2;
    private is_dirty = false;
    private disabled = false;
    private $leaveCellSub: Subscription;

    @Input('appFloatingInput') set on(on: boolean) {
        if (on !== this._on) {
            this._on = on;
            this.toggleInput(on);
        }
    }

    @Input('FloatingInputValue') private _value: string;
    @Output() FloatingInputValueChanged = new EventEmitter<string>();
    @Output() FloatingInputMouseLeave = new EventEmitter();

    constructor(private _el: ElementRef,
                private renderer: Renderer2,
                private clearConstantService: ClearConstantService) {

        this.textArea = this.renderer.createElement('textarea');
        this.renderer.addClass(this.textArea, 'app-floating-input');

        fromEvent(window, 'resize').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this.onDestroy))
            .subscribe((event) => {
                this.setPosition();
            });

        fromEvent(this.textArea, 'input').pipe(
            tap(event => {
                    this.is_dirty = true;
                    const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
                    this.countRows();
                    this.FloatingInputValueChanged.emit(target.value);
                },
                takeUntil(this.onDestroy))
        ).subscribe();

        fromEvent(this.textArea, 'mousedown').pipe(
            filter(() => this.disabled),
            tap(event => event.preventDefault())
        ).subscribe();

        this.clearConstantService.clearRequested.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.value = null;
        });

    }

    ngAfterViewInit() {
        this.loadTextAreaValueFromInput();
    }

    private loadTextAreaValueFromInput() {
        this.textArea.value = this._value || '';
        setTimeout(() => {
            this.setPosition();
            this.countRows();
            this.setInheritedProperties();
        }, 100);
    }

    private setPosition() {
        if (!this._el) {
            return;
        }
        const bc = this._el.nativeElement.getBoundingClientRect();
        this.renderer.setStyle(this.textArea, 'left', `${bc.left - 2}px`);
        this.renderer.setStyle(this.textArea, 'top', `${bc.top - 3}px`);
        this.renderer.setStyle(this.textArea, 'width', `${bc.width + 4}px`);
    }

    private setInheritedProperties() {
        if (!this._el) {
            return;
        }
        const el = this._el.nativeElement.firstElementChild;
        this.disabled = el.disabled;
        const style = window.getComputedStyle(el);
        if (style.getPropertyValue("background-color") !== 'rgba(0, 0, 0, 0)') {
            this.renderer.setStyle(this.textArea, 'background', style.getPropertyValue("background"));
        }
        this.renderer.setStyle(this.textArea, 'color', style.getPropertyValue("color"));
        this.renderer.setStyle(this.textArea, 'font-size', style.getPropertyValue("font-size"));
        this.renderer.setStyle(this.textArea, 'font-weight', style.getPropertyValue("font-weight"));
        this.renderer.setStyle(this.textArea, 'font-style', style.getPropertyValue("font-style"));
        this.renderer.setStyle(this.textArea, 'text-align', style.getPropertyValue("text-align"));
        this.renderer.setStyle(this.textArea, 'text-decoration', style.getPropertyValue("text-decoration"));
    }

    private countRows(): void {
        /**Called on constant_value changed to expand textarea if necessary**/
        if (!this._value) {
            return;
        }
        this.textarea_rows = utils.countLineBreaks(this._value) + 1;
        this.renderer.setAttribute(this.textArea, 'rows', this.textarea_rows.toString());
    }

    private setLeaveCellSubscription() {
        let $leave_cell = [fromEvent(this.textArea, 'blur'),
            fromEvent(window, 'wheel').pipe(
                distinctUntilChanged())];

        if (this.disabled) {
            $leave_cell.push(fromEvent(this.textArea, 'mouseleave'));
        }
        this.$leaveCellSub = refreshSubscription(this.$leaveCellSub);
        this.$leaveCellSub = race($leave_cell).pipe(
            tap(event => {
                    this.FloatingInputMouseLeave.emit(this.is_dirty);
                    this.is_dirty = false;
                },
                takeUntil(this.onDestroy))
        ).subscribe();
    }

    toggleInput(on = false) {
        if (on) {
            this.setPosition();
            this.setLeaveCellSubscription();
            this.renderer.removeChild(document.body, this.textArea);
            this.renderer.appendChild(document.body, this.textArea);
            if (this.disabled) {
                return;
            }
            this.textArea.focus();
        } else {
            this.renderer.removeChild(document.body, this.textArea);
            this.$leaveCellSub = refreshSubscription(this.$leaveCellSub);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
