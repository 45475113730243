import {Component, OnInit} from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import {HeaderDataService} from "../../services/header_data.service";

@Component({
    selector: 'not-found-view',
    templateUrl: './not-found-view.component.html',
    styleUrls: ['./not-found-view.css'],
    standalone: false
})
export class NotFoundViewComponent implements OnInit {

    constructor(private appScope: AppScope,
                private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.headerData.title = 'Page not found';
        this.headerData.add_new_page_form = true;
    }
}
