<div class="form-dialog">
  <h1>Correction
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content class="mat-dialog-content" [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <date-time-period [no_url]="true" [immutable]="true" class="date-period inline align-top"></date-time-period>
        <div style="margin:10px 0;">Select the time period here for which to apply a correction to</div>

        <apply-correction *ngIf="dtp"></apply-correction>

      </mat-tab>

    </mat-tab-group>

  </mat-dialog-content>

  <div class="action-buttons">
    <button class="btn-close" (click)="closeDialog()">Close</button>
  </div>
</div>

