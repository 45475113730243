import {Injectable, OnDestroy} from "@angular/core";
import {forkJoin, from, Observable, of, Subject} from "rxjs";
import {ApiService} from "../services/api/api.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {map,  tap} from "rxjs/operators";
import {ListResponse} from "../services/api/response-types";
import {Component, Component as WireComponent, COMPONENT_BASE_TYPES} from "../_models/component";
import {GenericDataService, ObjectListByRelationship} from './generic-data.service';
import {ConstantComponent} from '../_models/constant-component';
import {IDMap, ModelID} from '../_typing/generic-types';
import {SeriesComponent} from '../_models/series-component';
import {
    getBaseFilter,
    getManyRelationWithIdsFilter,
    getRelationWithIdFilter, getRelationWithManyIdsFilter
} from "../services/api/filter_utils";
import {Process} from '../_models/process';
import {ComponentComponent} from "../_models/component-component";
import {ConfigTypeDateFilter} from "../_typing/config/config-type-date-filter";
import {ComponentEventsTableService} from "../tables/component-events-table/component-events-table.service";
import {IDateTimePeriod} from "../_typing/date-time-period";

export interface ComponentByParentResponse {
    components: WireComponent[],
    component_dict: { [key: string]: WireComponent },
    component_list_dict: { [key: string]: string[] }
}

export interface ComponentConstantByComponentResponse {
    component_constants: ConstantComponent[],
    component_constants_dict: { [key: string]: ConstantComponent },
    component_constants_list_dict: { [key: string]: string[] }
}

export interface ComponentComponentDataResponse {
    components: IDMap<IDMap<Component[] | { id: ModelID }[]>>;
    componentComponents: IDMap<IDMap<ComponentComponent[]>>;
    filters: IDMap<IDMap<any[]>>;
    selectedFilters?: IDMap<IDMap<any[]>>;
}

/**
 * This class is for shared functions relating to data access on the component_type api
 **/
@Injectable({
    providedIn: 'root'
})
export class ComponentDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private dataService: GenericDataService,
                private api: ApiService,
                private componentEventsTableService: ComponentEventsTableService) {
    }

    /**Streams components by parent_ids
     * Returns an object of type ComponentByParentResponse
     * @param parent_ids:string[]
     * @param parent_type:string
     */
    streamComponentsByParentIds(parent_ids: string[], parent_type: string, ids?: string[], names?: string[], filters?: any[]): Observable<ComponentByParentResponse> {
        return this.dataService.streamObjectListByRelationshipIds(parent_ids, parent_type, 'component', ids, names, filters, 'name').pipe(
            map((response: ObjectListByRelationship<WireComponent>) => {
                return {
                    components: response.object_list,
                    component_dict: response.object_dict,
                    component_list_dict: response.relationship_object_list_dict
                };
            }));
    }

    /**Streams constant_components by component_ids
     * Returns an object of type ComponentConstantByComponentResponse
     * @param parent_ids:string[]
     * @param parent_type: component
     */
    streamComponentConstantsByComponentIds(component_ids: string[], filters?: any[]): Observable<ComponentConstantByComponentResponse> {
        return this.dataService.streamObjectListByRelationshipIds(component_ids, 'component', 'constant_component_light',
            null, null, filters, null, 'has').pipe(
            map((response: ObjectListByRelationship<ConstantComponent>) => {
                return {
                    component_constants: response?.object_list,
                    component_constants_dict: response?.object_dict,
                    component_constants_list_dict: response?.relationship_object_list_dict
                };
            }));
    }

    getSeriesComponentsByComponentIds(component_id: ModelID, component_type?: COMPONENT_BASE_TYPES): Observable<ListResponse<SeriesComponent>> {
        const options = new SearchQueryOptions();
        options.filters = [getRelationWithIdFilter('component', component_id)];
        if (component_type) {
            const val = options.filters[0].val;
            options.filters[0].val = {and: [val, getBaseFilter(component_type, 'base_type', 'eq')]};
        }
        return this.api.series_component.searchMany(options);
    }

    getSeriesComponents(series_component_ids: ModelID[]): Observable<ListResponse<SeriesComponent>> {
        const options = new SearchQueryOptions();
        options.filters = [getBaseFilter(series_component_ids, 'id', 'in')];
        return this.api.series_component.searchMany(options);
    }

    getProcessesBySeriesComponentIds(series_component_ids: ModelID[]): Observable<ListResponse<Process>> {
        const options = new SearchQueryOptions();
        options.filters = [getManyRelationWithIdsFilter('series_components', series_component_ids)];
        return this.api.process.searchMany(options);
    }

    getComponentsByComponentIdsAndType(componentIds: ModelID[], componentTypeId: ModelID): Observable<ListResponse<Component>> {
        const options = new SearchQueryOptions();
        options.filters = [{
            or: [
                {
                    and: [
                        getRelationWithIdFilter('component_type', componentTypeId),
                        {
                            name: 'component_component',
                            op: 'any',
                            val: getRelationWithManyIdsFilter('first_component', componentIds)
                        }
                    ]
                },
                {
                    and: [
                        getRelationWithIdFilter('component_type', componentTypeId),
                        {
                            name: 'component_component',
                            op: 'any',
                            val: getRelationWithManyIdsFilter('second_component', componentIds)
                        }
                    ]
                }

            ]
        }

        ]
        return this.api.component.searchMany(options);

    }

    generateComponentsByComponentIdAndTypeFilters(componentId: ModelID, componentTypeId: ModelID): any[] {
        return [{
            and: [
                getRelationWithIdFilter('component_type', componentTypeId),
                this.generateComponentsByComponentIdFilters(componentId)
            ]
        }
        ];
    }

    generateComponentsByComponentIdFilters(componentId: ModelID): any {
        return {
            or: [
                {
                    name: 'component_component',
                    op: 'any',
                    val: getRelationWithIdFilter('first_component', componentId)
                },
                {
                    name: 'component_component',
                    op: 'any',
                    val: getRelationWithIdFilter('second_component', componentId)
                }
            ]
        }
    }

    generateUnlinkedComponentsFilters(componentTypeId: ModelID, firstComponentTypeId) {

        /**select name from component where (component.id not in
         (select component_component.first_component_id from component_component join component on component_component.first_component_id = component.id
         )
         and component.id not in
         (select component_component.second_component_id from component_component join component on component_component.second_component_id = component.id
         ))
         and component.component_type_id = xxx
         **/
        return {
            and: [getRelationWithIdFilter('component_type', componentTypeId),
                {
                    not: {
                        or: [
                            {
                                and: [
                                    {
                                        name: 'component_component', op: 'any', val:
                                            {
                                                name: 'first_component', op: 'has', val:

                                                    {
                                                        name: 'component_type', op: 'has', val:
                                                            {name: 'id', op: 'eq', val: firstComponentTypeId}
                                                    }

                                            }
                                    }, {
                                        name: 'component_component', op: 'any', val:
                                            {
                                                name: 'second_component', op: 'has', val:

                                                    {
                                                        name: 'component_type', op: 'has', val:
                                                            {name: 'id', op: 'eq', val: componentTypeId}
                                                    }

                                            }
                                    }
                                ]
                            },
                            {
                                and: [
                                    {
                                        name: 'component_component', op: 'any', val:
                                            {
                                                name: 'second_component', op: 'has', val:

                                                    {
                                                        name: 'component_type', op: 'has', val:
                                                            {name: 'id', op: 'eq', val: firstComponentTypeId}
                                                    }

                                            }
                                    }, {
                                        name: 'component_component', op: 'any', val:
                                            {
                                                name: 'first_component', op: 'has', val:

                                                    {
                                                        name: 'component_type', op: 'has', val:
                                                            {name: 'id', op: 'eq', val: componentTypeId}
                                                    }

                                            }
                                    }
                                ]
                            }
                        ]

                    }
                }]
        }


    }

    generateComponentComponentFilters(componentIds: ModelID[], componentTypeId: ModelID) {
        return [{
            or: [{
                and: [getRelationWithManyIdsFilter('first_component', componentIds),
                    {
                        name: 'second_component',
                        op: 'has',
                        val: {name: 'component_type', op: 'has', val: {name: 'id', op: 'eq', val: componentTypeId}}
                    }
                ]
            },
                {
                    and: [getRelationWithManyIdsFilter('second_component', componentIds),
                        {
                            name: 'first_component',
                            op: 'has',
                            val: {name: 'component_type', op: 'has', val: {name: 'id', op: 'eq', val: componentTypeId}}
                        }
                    ]
                }
            ]
        }

        ]
    }

    private generateDateFilters(dateFilterConfig: Partial<ConfigTypeDateFilter>, ctId: ModelID, dtp: IDateTimePeriod): any {
        let startProp: ModelID, endProp: ModelID, dateFilters: any;
        let startInRange, endInRange, overlap;

        if (dateFilterConfig.constant_property_time?.[ctId]?.enabled) {
            startProp = dateFilterConfig.constant_property_time?.[ctId].start_prop;
            endProp = dateFilterConfig.constant_property_time?.[ctId].end_prop;
            startInRange = startProp ? this.componentEventsTableService.generateComponentConstantDateFilter(startProp, dtp) : null;
            endInRange = endProp ? this.componentEventsTableService.generateComponentConstantDateFilter(endProp, dtp) : null;
            overlap = startProp && endProp ? this.componentEventsTableService.generateDtpBetweenDatesFilter(startProp, endProp, dtp) : null;
        } else if (dateFilterConfig.attribute_time?.[ctId]?.enabled) {
            startProp = dateFilterConfig.attribute_time?.[ctId].attribute; //either 'start_time' or 'end_time'
            startInRange = {
                "and": [
                    {"name": startProp, "op": "ge", "val": dtp.start},
                    {"name": startProp, "op": "le", "val": dtp.end}
                ]
            }
        }

        if (startProp && endProp) {
            dateFilters = {or: [startInRange, endInRange, overlap]};
        } else if (startProp) {
            dateFilters = startInRange;
        } else if (endProp) {
            dateFilters = endInRange;
        }

        return dateFilters;


    }

    getComponentComponentData(firstComponentIds: ModelID[], componentTypeIds: ModelID[], ctCtDict: IDMap<{
        isFirstComponent: boolean;
        isUnique: boolean; isListUnique: boolean
    }>, firstComponentTypeId, dateFilterConfig: Partial<ConfigTypeDateFilter>, dtp?: IDateTimePeriod): Observable<ComponentComponentDataResponse> {
        const $obs = [];
        let componentComponentTypeMap: ComponentComponentDataResponse = {
            components: {},
            componentComponents: {},
            filters: {},
            selectedFilters: {}
        }
        firstComponentIds.forEach(id => {
            componentComponentTypeMap.components[id] = {};
            componentComponentTypeMap.componentComponents[id] = {};
            componentComponentTypeMap.filters[id] = {};
            componentComponentTypeMap.selectedFilters[id] = {};
        });

        componentTypeIds.forEach(ctId => {
            let dateFilters: any;
            if (dateFilterConfig?.constant_property_time?.[ctId] || dateFilterConfig?.attribute_time?.[ctId]) {
                dateFilters = this.generateDateFilters(dateFilterConfig, ctId, dtp);
            }
            const options = new SearchQueryOptions();
            const $components = ctCtDict[ctId].isUnique ? this.getComponentsByComponentIdsAndType(firstComponentIds, ctId) : of({data: []});

            options.filters = this.generateComponentComponentFilters(firstComponentIds, ctId);
            const $componentComponents = this.api.component_component.searchMany(options);

            $obs.push(forkJoin([$componentComponents, $components]).pipe(tap(([resComponentComponents, resComponents]) => {
                /**Map components and componentComponent to firstComponent (components for the table's componentType) and componentTypeId (id of the column's component type)**/
                firstComponentIds.forEach(componentId => {
                    const mappedComponentComponents = resComponentComponents.data?.filter(cc => cc.relationships.first_component.data.id === componentId || cc.relationships.second_component.data.id === componentId) || [];
                    const firstComponentIds = mappedComponentComponents.map(cc => cc.relationships.first_component.data.id);
                    const secondComponentIds = mappedComponentComponents.map(cc => cc.relationships.second_component.data.id);
                    const mappedComponents = resComponents?.data?.filter(c => ((firstComponentIds || []).concat(secondComponentIds || [])).includes(c.id));
                    componentComponentTypeMap.componentComponents[componentId][ctId] = mappedComponentComponents;
                    componentComponentTypeMap.components[componentId][ctId] = mappedComponents || [];
                    if (!ctCtDict[ctId].isUnique) {
                        componentComponentTypeMap.components[componentId][ctId] = mappedComponentComponents.map(cc => {
                            return {id: cc.relationships.first_component.data.id === componentId ? cc.relationships.second_component.data.id : cc.relationships.first_component.data.id}
                        })
                    }
                    componentComponentTypeMap.selectedFilters[componentId][ctId] = this.generateComponentsByComponentIdAndTypeFilters(componentId, ctId);
                    let availableFilters: any = {
                        and: [getRelationWithIdFilter('component_type', ctId),
                            {not: this.generateComponentsByComponentIdFilters(componentId)}]
                    };
                    if (ctCtDict[ctId].isListUnique) {
                        //The child component can only belong to a single parent
                        availableFilters = this.generateUnlinkedComponentsFilters(ctId, firstComponentTypeId)
                    }
                    componentComponentTypeMap.filters[componentId][ctId] = [availableFilters];
                    if (dateFilters) {
                        componentComponentTypeMap.filters[componentId][ctId].push(dateFilters);
                    }
                })
            })))

        })
        return forkJoin($obs).pipe(map(results => {
            return componentComponentTypeMap
        }))
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
