<div class="page">
  <ng-container *ngIf="canView;else noPermission">
    <mat-tab-group disableRipple class="manage-user-list dialog-table" #tabGroup mat-stretch-tabs="false">
      <mat-tab label="Groups">
        <ng-template matTabContent>
          <div class='user-list-groups-tab'>
            <group-list></group-list>
          </div>
        </ng-template>
      </mat-tab>

      <ng-container *ngFor="let tab of tabs">

        <mat-tab [label]="tab | upperfirst | removeUnderscore">
          <user-list [dataSource]="dataSource[tab]"
                     [user_meta_dict]="user_meta_dict"
                     [can_edit]="canEdit"
                     [filterValue]="filterValue"
                     (onUserChanged)="userChanged($event)"
                     (onSort)="applySort($event)"
                     (onFilter)="applyFilter($event)">
          </user-list>
        </mat-tab>

      </ng-container>

      <mat-tab label="Audit History">
        <ng-template matTabContent>
          <history-table [resource_list]="resource_list"></history-table>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
    <ng-template #noPermission>
    You do not have permission to view this page.
  </ng-template>
</div>
