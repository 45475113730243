import {Component, Inject} from '@angular/core';
import {ConstantProperty} from "../../_models/constant-property";
import {WireComponent} from "../../_models/component";
import {NotificationService} from "../../services/notification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "../../shared/base.component";
import {ModelFileFieldService} from "../../services/model-file-field.service";
import {of, timer} from "rxjs";
import {delay, scan, takeUntil, takeWhile} from "rxjs/operators";

export interface ComponentFileDeleteDialogData {
    component: WireComponent;
    property: ConstantProperty;
}

@Component({
    selector: 'src-component-file-delete-dialog',
    templateUrl: './component-file-delete-dialog.component.html',
    styleUrls: ['./component-file-delete-dialog.component.less'],
    standalone: false
})
export class ComponentFileDeleteDialogComponent extends BaseComponent {

    property: ConstantProperty;
    component: WireComponent;
    isCorrectFormat = true;
    file: {
        name: string;
        lastModifiedDate: Date;
        type: string;
    } = null;

    is_deleting: boolean = false;

    delay_time = 5000;
    time_remaining = 5;
    deletable: boolean = false;
    serviceDeskEmail: string = 'servicedesk@metalmanagementsolutions.com';
    constructor(private notification: NotificationService,
                private modelFileField: ModelFileFieldService,
                private dialogRef: MatDialogRef<ComponentFileDeleteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: ComponentFileDeleteDialogData) {
        super();
        this.property = dialogData.property;
        this.component = dialogData.component;
    }

    ngOnInit() {
        const initial_time = this.delay_time / 1000 + 1;
        timer(0, 1000).pipe(
            scan(acc => --acc, initial_time),
            takeWhile(x => x >= 0)).subscribe(x => {
            this.time_remaining = x;
        });

        of(null).pipe(delay(this.delay_time), takeUntil(this.onDestroy)).subscribe(() => {
            this.deletable = true;
        });
    }

    // save() {
    //     if (!this.file) {
    //         return;
    //     }
    //     this.dialogRef.close({file: this.file});
    // }

    deleteFile() {
        this.is_deleting = true;
        this.modelFileField.deleteFile(this.component.id, this.property.id)
            .subscribe({
                next: () => {
                    // Note: no response message is supported for 204 responses
                    this.notification.openSuccess('File deleted', 3000);
                    this.dialogRef.close();
                }, error: (err) => {
                console.error('Failed deleting file: ', err);
                this.notification.openError(`upload error: ${err.message}`);
            }, complete: () => {
                this.is_deleting = false;
            }});
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
