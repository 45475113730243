import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../services/api/api.service";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as utils from "../lib/utils";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {DateTimeInstanceService} from "../services/date-time-instance.service";
import {take} from "rxjs";

@Component({
    selector: 'src-edit-history',
    templateUrl: 'edit-history.component.html',
    providers: [DateTimeInstanceService],
    encapsulation: ViewEncapsulation.None //Global Styles
    ,
    standalone: false
})
export class EditHistoryComponent implements OnInit {
    public showing_hints: boolean = false;
    public hint: string = 'Name';
    dtp: IDateTimePeriod;

    constructor(
        public dialogRef: MatDialogRef<any>,
        public dateInst: DateTimeInstanceService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe(() => {
            this.dtp = utils.deepCopy(this.dateInst.dtp);
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
