import {Inject, Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {CdkDragMove, CdkDragRelease, CdkDropList} from "@angular/cdk/drag-drop";
import {DOCUMENT} from "@angular/common";
import {DraggableTreeNodeEntry} from "../../services/menu-tree.service";

@Injectable({
  providedIn: 'root',
})
export class DragdropTreeService {
  dropLists: CdkDropList[] = [];
  currentHoverDropListId?: string;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public register(dropList: CdkDropList) {
    this.dropLists.push(dropList);
  }

  dragMoved(event: CdkDragMove<DraggableTreeNodeEntry>) {
    let elementFromPoint = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );

    if (!elementFromPoint) {
      this.currentHoverDropListId = undefined;
      return;
    }

    let dropList = elementFromPoint.classList.contains('cdk-drop-list')
      ? elementFromPoint
      : elementFromPoint.closest('.cdk-drop-list');

    if (!dropList) {
      this.currentHoverDropListId = undefined;
      return;
    }

    this.currentHoverDropListId = dropList.id;
  }

  dragReleased(event: CdkDragRelease) {
    this.currentHoverDropListId = undefined;
  }
}
