<div class="chart-card">
  <div class="zoom-data">
    <label *ngIf="show_reset === true">Data sampled by: {{dtp.sample_period.name}}</label>
    <i (click)="getZoomData()" *ngIf="can_zoom_in"
       [matTooltip]="'Increase Quality (' + new_period.name + ')'" class="fa fa-binoculars hideonprint">
    </i>
    <i (click)="resetZoom()" *ngIf="show_reset === true" class="fa fa-undo hideonprint"
       matTooltip="Reset" style="margin-left:2px;">
    </i>
  </div>

  <div class='md-card-content'>
    <div #chart_anchor class="{{id_tag}} chart-tile"
         [ngClass]="{'hide-tick-labels': tile_config.hide_tick_labels,
         'legend-right':tile_config.legend_position==='right',
         'showing-title':tile_config.show_title}">
    </div>
    <div>
      <mat-checkbox (change)="chartService.showOpsPoints($event.checked)"
                    *ngIf="opsdata && columns[0] && columns[0].length > chartService.ops_min_cuttoff"
                    [checked]="false" class="show-ops-points">Show points
      </mat-checkbox>
    </div>
  </div>
</div>
