<div class="form-dialog">
  <h1></h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Calculation value driver tree">
        <div style="background:white;padding:20px;">
          <lock-template-dependency-tree
              [component_type_id]="component_type_id"
              [event_type_id]="event_type_id"
              [constant_property_id]="constant_property_id">
          </lock-template-dependency-tree>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
  </div>
</div>
