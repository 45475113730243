import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {ConstantProperty} from '../../../_models/constant-property';
import {KeyMap, ModelID} from '../../../_typing/generic-types';
import {BaseComponent} from '../../../shared/base.component';
import {snakeCase} from 'lodash-es';
import {EventConfigColumn} from '../../../_typing/config/config-column';
import type {IColumnSelectorEmitter, IFilterEmitter, ISelectRemoveButton} from '../type/selector-emitter';
import type {IMappedOptions} from '../type/mapped-options';
import {JsonContextService} from "../../../forms/custom-html-form/json-context.service";

@Component({
    selector: 'column-selector',
    templateUrl: './column-selector.component.html',
    styleUrls: ['../../event-column-selector/event-column-selector.component.less', './column-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ColumnSelectorComponent extends BaseComponent {
    @Input() modelDict: KeyMap<ConstantProperty>;
    @Input() availableColumns: EventConfigColumn[];
    @Input() selectedColumns: EventConfigColumn[] = [];
    @Input() disabledByDefault: boolean = true;
    @Input() selectedFilters: Record<string, string[]> = {};

    @Output() columnsChanged = new EventEmitter<Partial<IColumnSelectorEmitter>>();
    @Output() filtersValueChanged = new EventEmitter<Record<string, string[]>>();
    @Output() aliasValueChanged = new EventEmitter<{ aliasValue: string, constantPropertyId: string }>();

    availableColumnFilterValue: string = '';
    selectedColumnFilterValue: string = '';
    selectRemoveButtons: ISelectRemoveButton[] = [];

    aliasValues: Record<string, string> = {};

    constructor(private changeDetectorRef: ChangeDetectorRef, private jsonContextService: JsonContextService) {
        super();

        this.selectRemoveButtons = [
            {type: 'select', iconDirection: 'left'},
            {type: 'remove', iconDirection: 'right'}
        ];
    }

    moveColumns(move: 'select' | 'remove'): void {
        if (move === 'select') {
            this.selectedColumns = [...this.selectedColumns, ...this.availableColumns];
            this.availableColumns = [];
        } else {
            this.availableColumns = [...this.availableColumns, ...this.selectedColumns];
            this.selectedColumns = [];
        }
        this.columnsChanged.emit({availableColumns: this.availableColumns, selectedColumns: this.selectedColumns});
        this.changeDetectorRef.markForCheck();
    }

    drop(event: CdkDragDrop<EventConfigColumn[]>): void {
        let addedColumnId: string | ModelID;
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            // use item.index set by columnFilter instead of event.previousIndex which reflects index of item in the displayed list
            // and may not be the actual index in the container.data list
            let prevIndex = event.item.data.index || event.previousIndex;

            event.item.data.disabled = this.disabledByDefault;
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                prevIndex,
                event.currentIndex);
            addedColumnId = event.container.data[event.currentIndex]['id'];
        }

        // update objects reference so that filter pipe is called
        this.availableColumns = [...this.availableColumns];
        this.selectedColumns = [...this.selectedColumns];
        this.columnsChanged.emit({
            availableColumns: this.availableColumns,
            selectedColumns: this.selectedColumns,
            newColumnId: addedColumnId
        });
        this.changeDetectorRef.markForCheck();
    }

    onFiltersChange(values: string[], columnId: string) {
        let emitFilter: Record<string, string[]> = {};
        emitFilter[columnId] = values;
        this.filtersValueChanged.emit(emitFilter);
    }

    onTextFiltersChange(value: string, columnId: string) {
         let emitFilter: Record<string, string[]> = {};
        emitFilter[columnId] = value? value.split(",") : null;
        this.filtersValueChanged.emit(emitFilter);
    }

    onAliasChange(value: string, columnId: string) {

        // this.aliasValues[columnId] = this.jsonContextService.updateAlias(value);
        // this.aliasValueChanged.emit({aliasValue: value, constantPropertyId: columnId});
    }

    isDropDownList(columnId: string): boolean {
        return this.modelDict?.[columnId]?.attributes?.is_drop_down_list;
    }

    isDateTime(columnId: string): boolean {
        return this.modelDict?.[columnId]?.attributes?.data_type === 'datetime';
    }

    isText(columnId: string): boolean {
        return !this.modelDict?.[columnId]?.attributes?.is_drop_down_list && this.modelDict?.[columnId]?.attributes?.data_type !== 'datetime';
    }

    getDropDownListOptions(columnId: string): string[] {
        return this.modelDict?.[columnId]?.attributes?.json || [];
    }

    toSnakeCase(value: string): string {
        return snakeCase(value);
    }

    stringFunction: (item: IMappedOptions) => string = (item: IMappedOptions) => {
        return item.name;
    }

    setDefaultFilter(filters: string[], columnId: string): string[] {
        if (filters === undefined || filters?.length === 0) {
            this.selectedFilters[columnId] = [];
            return [];
        }
        return filters;
    }

    columnFilter = (item, value, index): boolean => {
        // store item index for use in determine actual position in the items list
        item.index = index;
        const searchValue = value.toLowerCase();

        if (item.type === 'attribute') {
            return item.id.toLowerCase().includes(searchValue) || item.title?.toLowerCase().includes(searchValue);
        }

        return this.modelDict?.[item.id]?.attributes.name.toLowerCase().includes(searchValue) || false;
    }

    isTextOverflowing(element: HTMLElement): boolean {
        return element.scrollWidth > element.clientWidth;
    }

    preventKeys(event: KeyboardEvent) {
        if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();  // Prevent space, tab, and enter keys
        }
    }
}
