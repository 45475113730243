import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {ConstantProperty} from '../../../_models/constant-property';
import {FileField, GenericConstantApiResponse} from "../../../_models/api/generic-constant";
import {Component as WireComponent} from '../../../_models/component';
import {
    ComponentFileUploadDialogComponent,
    ComponentFileUploadDialogData, UploadedFile
} from "../../../forms/component-file-upload-dialog/component-file-upload-dialog.component";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ModelFileFieldService} from "../../../services/model-file-field.service";
import {ComponentFieldService} from "../../../services/component-field.service";
import {AppScope} from "../../../services/app_scope.service";
import {BaseComponent} from "../../../shared/base.component";
import {takeUntil, tap, concatMap} from "rxjs/operators";
import {
    ComponentFileDeleteDialogComponent,
    ComponentFileDeleteDialogData
} from "../../../forms/component-file-delete-dialog/component-file-delete-dialog.component";
import {NotificationService} from "../../../services/notification.service";
import {SaveService} from "../../../services/save/save.service";
import {Observable, of} from 'rxjs';
import {ComponentEventsTableService} from "../../../tables/component-events-table/component-events-table.service";
import {ClearConstantService} from "../../constant-field/clear-constant.service";
import {TOOLTIP_SHOW_DELAY} from '../../../shared/globals';

@Component({
    selector: 'generic-component-file-field',
    templateUrl: './generic-component-file-field.component.html',
    styleUrls: ['./generic-component-file-field.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GenericComponentFileFieldComponent extends BaseComponent {
    @Input() value: FileField;
    @Input() constant_property: ConstantProperty;
    @Input() component: WireComponent;
    @Input() disabled: boolean;

    @Output() relayConstantChangedEmitter: EventEmitter<any> = new EventEmitter();
    may_upload: boolean;
    may_download: boolean;
    may_delete: boolean;
    uploadedFile: UploadedFile & File;
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private notification: NotificationService,
        public dialog: MatDialog,
        private modelFileField: ModelFileFieldService,
        private componentField: ComponentFieldService,
        private appScope: AppScope,
        private clearConstantService: ClearConstantService,
        private componentEventsService: ComponentEventsTableService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.appScope.currentUserValue.pipe(takeUntil(this.onDestroy)).subscribe(user => {
            if (user.is_super) {
                this.may_download = true;
                this.may_delete = true;
                this.may_upload = true;
                return;
            }
            if (this.disabled) {
                this.may_upload = false;
                this.may_delete = false;
            } else {
                this.may_upload = user.feature_names.indexOf('create component_file') >= 0;
                this.may_delete = user.feature_names.indexOf('delete component_file') >= 0;
            }
            this.may_download = user.feature_names.indexOf('view component_file') >= 0;
        });

        if (!this.component?.id) {
            this.componentEventsService.$componentSaved.pipe(takeUntil(this.onDestroy)).pipe(concatMap(value => {
                this.component = value.component;
                if (this.component?.id && this.uploadedFile) {
                    return this.uploadFile();
                }
                return of(null);
            })).subscribe();

            this.clearConstantService.clearRequested.pipe(takeUntil(this.onDestroy)).subscribe(() => {
                this.component = null;
                this.value = new FileField();
                this.uploadedFile = null;
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    selectFile(): void {
        const dialogConfig: MatDialogConfig<ComponentFileUploadDialogData> = new MatDialogConfig<ComponentFileUploadDialogData>();
        dialogConfig.data = {
            component: this.component,
            property: this.constant_property
        };
        let dialogRef: MatDialogRef<ComponentFileUploadDialogComponent> =
            this.dialog.open(ComponentFileUploadDialogComponent, dialogConfig);
        dialogRef.afterClosed().pipe(concatMap((result: ({ file: UploadedFile } & File) | null) => {
            if (result?.file) {
                this.uploadedFile = result.file;
                this.relayConstantChangedEmitter.emit(this.uploadedFile.name);
                this.changeDetectorRef.markForCheck();
                if (this.component?.id) {
                    return this.uploadFile();
                }
            }
            return of(null);
        })).subscribe();
    }

    uploadFile(): Observable<any> {
        return this.modelFileField.uploadFile('component', this.component.id,
            this.constant_property.id, this.uploadedFile).pipe(tap({
            next: (response: { file_name: string, message: string }) => {
                this.notification.openSuccess(`${response.file_name} ${response.message}`, 3000);
                this.reloadField();
            }, error: (err) => {
                this.notification.openError(`upload error: ${err.message}`, 3000);
            }
        }));
    }

    reloadField(): void {
        let component_id: string = this.component.id;
        let field: string = this.constant_property.id;
        this.componentField.getFields([component_id], [field])
            .pipe(tap((response: GenericConstantApiResponse): void => {
                this.value = response.data[component_id][field] as FileField;
                this.changeDetectorRef.markForCheck();
            })).subscribe();
    }

    downloadFile(fileField: FileField): void {
        this.modelFileField.downloadFile('component', this.component.id, this.constant_property.id).pipe(tap((response: Blob) => {
            let file: File = new File([response], fileField.filename, {type: fileField.mimetype});
            let objectURL: string = URL.createObjectURL(file);
            if (fileField.mimetype === 'application/pdf') {
                window.open(objectURL, '_blank');
            } else {
                let anchor: HTMLAnchorElement = document.createElement('a');
                anchor.download = fileField.filename;
                anchor.href = objectURL;
                anchor.click();
                URL.revokeObjectURL(objectURL);
                anchor.remove();
            }
        })).subscribe();
    }

    openConfirmWindow() {
        if (!this.component?.id) {
            this.uploadedFile = null;
            return;
        }
        const dialogConfig: MatDialogConfig<ComponentFileDeleteDialogData> = new MatDialogConfig<ComponentFileDeleteDialogData>();
        dialogConfig.data = {
            component: this.component,
            property: this.constant_property
        };
        let dialogRef: MatDialogRef<ComponentFileDeleteDialogComponent> =
            this.dialog.open(ComponentFileDeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => this.reloadField());
    }
}
