import {Directive, Renderer2, ElementRef, Input} from '@angular/core';


@Directive({
    selector: '[appLdsRoller]',
    standalone: false
})
export class LdsRollerDirective {
    roller: HTMLElement;
    _on = false;

    @Input('appLdsRoller') set on(on: boolean) {
        if (on !== this._on) {
            this._on = on;
            this.toggleRoller(on);
        }
    }

    constructor(private _el: ElementRef,
                private renderer: Renderer2) {

        this.roller = this.renderer.createElement('div');
        this.renderer.addClass(this.roller, 'app-loading');
        this.renderer.addClass(this.roller, 'app-loading-component');
        const loader = this.renderer.createElement('div');
        this.renderer.addClass(loader, 'lds-roller');
        this.renderer.appendChild(this.roller, loader);
        for (let i = 0; i < 8; i++) {
            let child = this.renderer.createElement('div');
            this.renderer.appendChild(loader, child);
        }
    }

    toggleRoller(on = false) {
        if (on) {
            this.renderer.removeChild(this._el.nativeElement, this.roller);
            this.renderer.appendChild(this._el.nativeElement, this.roller)
        } else {
            this.renderer.removeChild(this._el.nativeElement, this.roller);
        }
    }
}
