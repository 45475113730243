import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {DateTimePeriodService} from '../../services/date-time-period.service';
import {MatTableDataSource} from '@angular/material/table';
import {forkJoin, from, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import {ApiService} from '../../services/api/api.service';
import {HeaderDataService} from '../../services/header_data.service';
import {FormDialogService} from '../../services/form-dialog.service';
import {AppScope} from '../../services/app_scope.service';
import {MatSort} from '@angular/material/sort';
import {
    catchError,
    concatMap,
    debounceTime,
    finalize,
    first,
    map,
    mergeMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import * as utils from '../../lib/utils';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component as WireComponent} from '../../_models/component';
import {ConstantProperty} from '../../_models/constant-property';
import {CachingService} from "../../services/caching.service";
import {CustomEventsService} from "../../services/custom-events.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {COMPONENT_EVENTS_CONFIG} from "../../forms/component-events-table-form/component-events-table-form.component";
import {ListResponse} from "../../services/api/response-types";
import {ColumnFormats, ColumnFormatsDict, TableUtilsService} from '../table-utils.service';
import {TileDataService} from '../../services/tile_data.service';
import {TilePdfGeneratorService} from '../../services/tile-pdf-generator.service';
import {ConstantPropertyComponentType} from "../../_models/constant-property-component-type";
import {ComponentEventsTableService} from "./component-events-table.service";
import {CsvDownloadService} from "../../services/csv-download.service";
import {ConditionalFormattingConfig, ConditionalFormattingService} from "../conditional-formatting.service";
import {BaseModel, IDMap, KeyMap, KeyMapMap, ModelID, RESTFilter, ModelWithLimits} from "../../_typing/generic-types";
import {getBaseFilter, getRelationWithIdFilter, getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {LockDataService} from "../../services/lock-data.service";
import {
    ConstantField,
    ConstantValue,
    GenericConstantApiResponse,
    GenericConstantData,
    GenericField,
    isConstant
} from "../../_models/api/generic-constant";
import {GenericConstantDataService} from "../../data/generic-constant-data.service";
import {getAttributeIds, getColId, getColIds, getComponentTypeIds, getPropertyIds} from '../custom-events-table/utils';
import {EventConfigColumn} from "../../_typing/config/config-column";
import {ActivatedRoute, Router} from '@angular/router';
import {CalcGraphStatusService, ConstantCalculationsStatusResponse} from "../../services/api/calcGraphStatus.service";
import {FilterTableButtonConfig} from "../../components/filter-table-button/filter-table-button.component";
import {NotificationService} from "../../services/notification.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {FormControl} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {NOTIFICATION_DURATION_MS, TABLE_PAGE_SIZE, TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {FILE_DOWNLOAD_OPTIONS, FileDownloadOption} from "../../_typing/download-filetype";
import {ValidationBuilderService} from "../../services/validation-builder.service";
import {ValidatorRule} from "../../_typing/query-builder";
import {compare_ids, deepCopy} from "../../lib/utils";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {ComponentType} from "../../_models/component-type";
import {ComponentTypeComponentType} from "../../_models/component-type-component-type";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {ComponentComponent} from "../../_models/component-component";
import {ComponentComponentDataResponse, ComponentDataService} from "../../data/component-data.service";
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import * as _ from "lodash-es";
import {camelLabel} from "../../lib/utils";

@Component({
    selector: 'component-events-table',
    templateUrl: './component-events-table.component.html',
    styleUrls: ['./component-events-table.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TableUtilsService],
    standalone: false
})
export class ComponentEventsTableComponent implements OnInit, OnDestroy {
    // region All Variable
    // region Public Variable
    expandedElement: any | null = null;
    expandedEvents: any | null = null;
    expandedComponents: any | null = null;
    formats: KeyMap<ColumnFormatsDict>;
    buttons: any[] = [];
    columns: string[] = [];
    agg_columns: string[];
    dtp: IDateTimePeriod;
    componentTypes: ComponentType[];
    componentTypeComponentTypes: ComponentTypeComponentType[];
    ctDict: IDMap<ComponentType> = {};
    ctCtDict: IDMap<{ isFirstComponent: boolean; isUnique: boolean; isListUnique: boolean }> = {};
    componentComponents: ComponentComponent[];
    componentComponentTypeMap: Partial<ComponentComponentDataResponse> = {};
    linkedComponents: WireComponent[];
    linkedComponentDict: IDMap<WireComponent> = {};
    componentComponentDict: IDMap<WireComponent>;
    constant_properties: ConstantProperty[];
    cp_dict: IDMap<ConstantProperty>;
    cp_class_dict: Record<string, { class: string, message: string }> = {};
    component_type: any;
    components: WireComponent[];
    components_properties: string[];
    attribute_cols: string[];
    attribute_cols_name_dict: { [key: string]: string } = {};
    format_dict: ColumnFormatsDict;
    conditional_dict: KeyMapMap<Partial<ColumnFormats>>;
    conditions: ConditionalFormattingConfig[];
    aggregation_row: any = {};
    limits_map: Partial<ModelWithLimits<ConstantPropertyComponentType>> = {};
    selected_cols_dict: Record<string, EventConfigColumn> = {};
    audit_mixin_dict: KeyMap<KeyMap<BaseModel<WireComponent>>> = {};
    generic_constants: KeyMap<KeyMap<GenericField>> = {};
    dataSource: MatTableDataSource<any>;
    $components: Subscription | null;
    $constant_properties: Subscription | null;
    page_size: number = TABLE_PAGE_SIZE;
    page_size_options: number[] = [10, 20, 50, 100, 200];
    page_index: number = 0;
    component_total;
    loading_next: boolean = false;
    search_by_name: string;
    table_height: string = 'calc(100% - 41px)';
    user_filters: any;
    query_builder_filters: any; // Used to store the part of user_filters that comes from the quick-edit query builder
    filter_string: string = '';
    filter_button_config: FilterTableButtonConfig;
    user_search_selections: string[] = [];
    user_search_fields: { name: string; value: string }[] = [];

    filter_warning: string;
    messages: string[] = [];
    isRenderingPdf: boolean = false;
    constant_property_component_types: ConstantPropertyComponentType[];
    rows_selection_status: { [key: string]: boolean } = {};
    component_state: { [key: string]: { state: 'error' | 'success', message: string } } = {};

    doValidation: boolean;
    doFormatting: boolean;
    autoFormattingIds: ModelID[];
    validationDict: KeyMap<ValidatorRule[]>;
    // endregion
    // region Private Variable
    private sort;
    private readonly onDestroy = new Subject<void>();
    private _calc_updating_message: string = "";
    private _calculationCurrentlyUpdatingSubscription: Subscription;
    private filteredData: Subject<string> = new Subject<string>();
    public can_unlock = false;
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;
    printoutFilter: string = '';

    // endregion
    // region Decorators
    @Input() config: COMPONENT_EVENTS_CONFIG;
    @Input() is_tile: boolean;

    @Output() needToEdit: EventEmitter<string> = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('component_events_table') component_events_table: ElementRef;
    @ViewChild('top_scroll') top_scroll: ElementRef;
    @ViewChild('utils_bar') utils_bar;

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        setTimeout(() => this.setTableHeight(), 1000);
    }

    // endregion
    // endregion

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                public router: Router,
                private activatedRoute: ActivatedRoute,
                private headerData: HeaderDataService,
                private changeDetectorRef: ChangeDetectorRef,
                private formDialogService: FormDialogService,
                private appScope: AppScope,
                private notification: NotificationService,
                private cache: CachingService,
                public eventsService: CustomEventsService,
                public componentEventsTableService: ComponentEventsTableService,
                public tableUtils: TableUtilsService,
                public tileData: TileDataService,
                public renderer: Renderer2,
                public tilePdfGeneratorService: TilePdfGeneratorService,
                private csvExporter: CsvDownloadService,
                private conditionalService: ConditionalFormattingService,
                private lockDataService: LockDataService,
                private genericConstantData: GenericConstantDataService,
                private calcGraphStatusService: CalcGraphStatusService,
                private userService: UserService,
                private validationService: ValidationBuilderService,
                private componentTypeDataService: ComponentTypeDataService,
                private componentDataService: ComponentDataService,
                private relationshipMappingService: RelationshipApiMappingService
    ) {
    }

    ngOnInit(): void {
        this.eventsService.event_state = {};
        this.dataSource = new MatTableDataSource<any>();
        this.dataSource.paginator = this.paginator;
        this.cache.clearCache(); // Make sure the cache is cleared for events-table when form is updated
        if (this.config.component) {
            if (!this.config.component.relationships.component_type?.data?.id) {
                this.notification.openError('This Component does not have a Component Type.', 10000);
                return;
            }
            this.component_type = this.config.component.relationships.component_type.data;
        } else {
            this.component_type = this.config.selected_component_type;
        }

        if (this.config.page_size && !isNaN(this.config.page_size)) {
            this.page_size = this.config.page_size;
            if (!this.page_size_options.includes(this.page_size)) {
                this.page_size_options.push(this.page_size);
            }
        }
        if (this.config.selected_cols) {
            this.config.selected_cols.component_type.forEach((item, index) => {
                this.selected_cols_dict[item.id] = {...item, index};
            });
        }

        this.validationDict = this.validationService.getValidationDict(this.config.validation, this.selected_cols_dict);
        this.conditions = this.config.conditional_formats || [];

        if (this.config.show_aggregations) {
            this.filteredData.pipe(debounceTime(1000)).subscribe(() => {
                if (this.columns && this.dataSource.filteredData) {
                    this.addAggregationRow();
                    this.changeDetectorRef.markForCheck();
                }
            });
        }

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            this.dtp = this.dateInst.dtp;
            if (!this.config.search_by_name) {
                this.refreshComponents();
            }
        });
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            this.cache.clearCache();
            this.refreshComponents();
        });

        if (this.tileData?.save_content) {
            this.tileData.save_content.pipe(takeUntil(this.onDestroy)).subscribe(event => {
                if (!this.tileData.tile) {
                    return;
                }
                this.tileData.tile.attributes.parameters.selected_cols.component_type =
                    this.tableUtils.updateColumnFormats(this.config.selected_cols.component_type, this.format_dict);
                this.tableUtils.emitSaveTile(this.tileData);
            });
        }
        fromEvent(window, 'resize').pipe(debounceTime(250), takeUntil(this.onDestroy))
            .subscribe(() => this.table_height = this.tableUtils.setTableHeight(this.utils_bar));

        this.componentEventsTableService.$componentSaved.pipe(takeUntil(this.onDestroy))
            .subscribe((data: { component: WireComponent; custom_constant_dict: KeyMap<ConstantValue> }) => {
                this.updateComponentValues(data.component, data.custom_constant_dict);
            });
        this.componentEventsTableService.$refreshComponentEventsTable.pipe(takeUntil(this.onDestroy))
            .subscribe(() => this.refreshComponents());

        this.can_unlock = this.userService.canUnlockData();
    }

    updateComponentValues(component: WireComponent, custom_constant_dict: KeyMap<ConstantValue>) {
        if (this.components.findIndex(c => c.id === component.id) < 0) {
            return;
        }
        Object.keys(custom_constant_dict).forEach(cc => {
            if (this.generic_constants[component.id][cc] && isConstant(this.generic_constants[component.id][cc])) {
                (this.generic_constants[component.id][cc] as ConstantField).value = custom_constant_dict[cc];
            }
        });
        this.updateConditionalColumnFormats();
    }

    ngAfterViewInit() {
        this.setButtons();
        this.checkComponentConstantCalculationsStatusPoll();
    }

    refreshComponents() {
        this.$components = utils.refreshSubscription(this.$components);
        let $component_obs;
        this.tileData.noTileData.next('');

        if (this.config.component) {
            this.components = [this.config.component];
            $component_obs = this.getComponentProperties();
        } else {
            $component_obs = forkJoin([this.getComponents(), this.getComponentProperties(), this.getComponentTypeComponentTypes()]);
        }

        this.$components = $component_obs.pipe(takeUntil(this.onDestroy),
            concatMap(() => {
                this.filter_warning = '';
                this.setButtons();
                this.doQueryWarning();
                if (!this.components || this.components.length <= 0) {
                    this.tileData.noTileData.next({styles: {top: '70px'}});
                    this.loading_next = false;
                    this.changeDetectorRef.markForCheck();
                    this.columns = [];
                    return of([]);
                }
                return this.getComponentRelationshipData().pipe(tap(() => {
                    this.autoFormattingIds = this.conditions.map(condition => {
                        if (condition.comparer === 'auto' && condition.column.type === 'constant_property') {
                            return condition.column.id;
                        }
                    });
                    this.doFormatting = !this.config.bypass_limits_formatting;
                    this.doValidation = !this.config.bypass_limits_validation;

                    if (this.doFormatting || this.doValidation || this.autoFormattingIds?.length) {
                        const propertyIds = this.constant_properties?.map(item => item.id);
                        this.getConstantPropertyComponentTypes(this.component_type.id, propertyIds);
                    }
                    this.createComponentsTable();
                    this.setFilterTableButtonConfig();

                    if (this.columns && this.config.show_aggregations || this.config.show_aggregations_top) {
                        this.addAggregationRow();
                    }
                    if (this.page_size > 100 && this.components?.length >= 100) {
                        this.notification.openSuccess("Table is ready for editing.", 2000);
                    }
                }));
            })
        ).subscribe(() => {
            this.changeDetectorRef.markForCheck();
        });
    }

    getComponents() {
        const options = new SearchQueryOptions();
        this.loading_next = true;

        options.filters = this.componentEventsTableService.getComponentsFilter(this.config, this.dateInst.dtp,
            this.search_by_name, this.user_filters, this.query_builder_filters);
        options.page_size = this.page_size;
        options.page_number = this.page_index + 1;
        options.sort = 'name';

        let base = this.config.selected_component_type.attributes.base_type;
        if (base && ['resource', 'equipment'].includes(base)) {
            base = base + '_light';
        }
        if (base) {
            return this.api[base].searchMany(options)
                .pipe(map((result: ListResponse<WireComponent>) => {
                        if (!result) {
                            this.component_total = 0;
                            return of([]);
                        }
                        this.components = result.data;
                        this.component_total = result.meta.count;
                    })
                );
        } else {
            // Not sure if this should be a nested observable, though we really should just enforce a base_type on
            // component types.
            return of([]);
        }
    }

    filterPrintouts(item, filter) {
        if (!filter) return true;
        return item?.name?.toLowerCase().includes(filter.toLowerCase());
    }

    pageData(event) {
        this.page_index = event.pageIndex;
        this.page_size = event.pageSize;
        this.refreshComponents();
    }

    getComponentProperties(): Observable<any> {
        let prop_ids = getPropertyIds(this.config.selected_cols?.component_type);
        prop_ids = this.componentEventsTableService.addTimeFilterProperties(this.config, prop_ids);
        const $properties = this.eventsService.getComponentTypeProperties([this.component_type.id], prop_ids)
            .pipe(tap(result => {
                this.constant_properties = result.data;
                this.mapComponentTypeProperties();
            }));
        return $properties;
    }

    getComponentTypeComponentTypes() {
        let ctIds = getComponentTypeIds(this.config.selected_cols?.component_type);

        const $componentTypeComponentTypes = this.componentTypeDataService.getComponentTypeComponentTypes(this.component_type.id, ctIds)
            .pipe(tap(result => {
                this.componentTypeComponentTypes = result.data || [];
                this.componentTypeComponentTypes.forEach(ctCt => {
                    const isFirstComponent = ctIds.includes(ctCt.relationships.first_component_type.data.id);
                    const ctColumnId = isFirstComponent ? ctCt.relationships.first_component_type.data.id : ctCt.relationships.second_component_type.data.id;
                    const isUnique = isFirstComponent ? ctCt.attributes.second_is_unique : ctCt.attributes.first_is_unique;
                    const isListUnique = isFirstComponent ? ctCt.attributes.first_is_unique : ctCt.attributes.second_is_unique;
                    this.ctCtDict[ctColumnId] = {
                        isFirstComponent: isFirstComponent,
                        isUnique: isUnique, //single-select vs multi-select
                        isListUnique: isListUnique //each component of this type can only belong to one component of the table component_type
                    };
                });
            }));

        const $componentTypes = this.componentTypeDataService.getComponentTypes(ctIds).pipe(tap(result => {
            this.componentTypes = result.data;
            this.componentTypes.forEach(ct => this.ctDict[ct.id] = ct);
        }))
        return forkJoin([$componentTypeComponentTypes, $componentTypes]);
    }

    getComponentRelationshipData(): Observable<any> {
        this.audit_mixin_dict = {};
        this.generic_constants = {};
        utils.sortObjectsByProperty(this.components, 'name');
        const component_ids = this.components.map(component => component.id);

        let $constant_components = [];
        let $componentComponents = [];
        let i, j, constant_chunk, chunk = 50;
        for (i = 0, j = component_ids.length; i < j; i += chunk) {
            constant_chunk = component_ids.slice(i, i + chunk);
            $constant_components.push(this.getConstantComponents(constant_chunk));
            $componentComponents.push(this.getComponentComponents(constant_chunk))
        }

        const concurrentRequests = 10;
        return from($constant_components.concat($componentComponents)).pipe(
            mergeMap(obj => obj, concurrentRequests));
    }

    mapComponentTypeProperties(): void {
        this.cp_dict = {};
        this.components_properties = [];
        this.constant_properties.forEach(cp => {
            this.cp_dict[cp.id] = cp;
            if (cp.attributes.name) {
                // This is here for the view
                if (!this.components_properties.includes(cp.attributes.name)) {
                    this.components_properties.push(cp.attributes.name);
                }
            }
        });
        this.attribute_cols = this.config.selected_cols?.component_type.filter(c => c.type === 'attribute').map(c => c.id) || ['name'];
        this.attribute_cols_name_dict = {};
        if (this.attribute_cols.includes('end_time')) {
            let date_query = this.componentEventsTableService.getComponentFilterByConstantValueLogic(this.config, this.dtp);
        }
        if (this.config.selected_cols?.component_type) {
            this.components_properties = this.config.selected_cols.component_type.map(item => {
                return this.cp_dict[item.id]?.attributes.name || item.id;
            });
        } else {
            this.components_properties = this.attribute_cols.concat(this.components_properties);
        }
    }


    getComponentComponents(componentIds: ModelID[]) {
        return this.componentDataService.getComponentComponentData(componentIds, this.componentTypes.map(ct => ct.id), this.ctCtDict,
            this.component_type.id, this.config.component_type_date_filters, this.dtp).pipe(
            map(componentData => {
                this.componentComponentTypeMap = Object.assign({}, this.componentComponentTypeMap || {}, componentData);
                this.componentComponentDict = deepCopy(this.componentComponentTypeMap.components);
                this.changeDetectorRef.markForCheck();
            })
        )

    }

    getConstantComponents(ids: ModelID[]): Observable<GenericConstantApiResponse> {
        // TODO limit this to only selected attributes when config gets updated
        const atts = getAttributeIds(this.config.selected_cols.component_type).filter(a => ['start_time', 'end_time', 'name'].includes(a));
        return this.componentEventsTableService.getApiComponentConstants(ids, this.constant_properties.map(c => c.id), atts)
            .pipe(tap((response: GenericConstantApiResponse) => {
                this.generic_constants = Object.assign({}, this.generic_constants, response.data);
                this.setAuditData(ids, response.data);
                this.changeDetectorRef.markForCheck();
            }));
    }

    setAuditData(ids: ModelID[], constants: GenericConstantData): void {
        ids.forEach(id => {
            this.audit_mixin_dict[id] = {};
            Object.keys(constants[id]).forEach(key => {
                if (this.cp_dict[key]) {
                    this.audit_mixin_dict[id][key] = {
                        id: id,
                        type: 'component',
                        attributes: constants[id][key],
                        relationships: null
                    };
                }
            });
        });
    }

    saveComponent(component: WireComponent, column: ModelID, $event) {
        if (!(component.type && component.id)) {
            return;
        }

        const save_me = {id: component.id, type: component.type, attributes: {}};
        if (column === 'start_time') {
            save_me.attributes['start_time'] = $event?.toISOString() || null;
        } else if (column === 'end_time') {
            save_me.attributes['end_time'] = $event?.toISOString() || null;
        } else if (column === 'name') {
            save_me.attributes['name'] = component.attributes.name;
        }

        if ((column === 'start_time' || column === 'end_time') && !this.eventsService.checkStartAndEndDates(component, 'start_time', 'end_time')) {
            return;
        }
        const mylist = ['start_time', 'end_time', 'name'];

        if (mylist.includes(column) && component && component.type) {

            let constants = deepCopy(this.generic_constants[component.id]) || {[column]: {}};
            constants[column].value = save_me.attributes[column];
            if (!this.validationService.validateValue(column, constants, this.validationDict)) {
                return;
            }

            this.api[component.type].obsPatch(save_me).pipe(take(1)).subscribe(result => {
                if (result.data) {
                    this.generic_constants[component.id][column]['value'] = result.data.attributes?.[column];
                    this.notification.openSuccess("Component saved.", 2000);
                }
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    updateComponentComponentRelationship(component: WireComponent, componentTypeId: ModelID, $event) {
        if (!$event) return;
        try {
            const oldMappingList = this.componentComponentTypeMap.componentComponents[component.id][componentTypeId];
            const oldSelectedValues = this.componentComponentTypeMap.components[component.id][componentTypeId];
            const isFirst = this.ctCtDict[componentTypeId].isFirstComponent;

            let relationshipName: string, modelName: string;
            if (isFirst) {
                relationshipName = 'second_component';
                modelName = 'first_component';
            } else {
                relationshipName = 'first_component';
                modelName = 'second_component';
            }

            let newSelectedValues;
            if (Array.isArray($event)) {
                newSelectedValues = $event || [];
            } else {
                const newSelection = $event.value ? [$event.value] : [];
                newSelectedValues = newSelection;
            }
            const toDelete: ModelID[] = _.differenceWith(oldSelectedValues, newSelectedValues, compare_ids)?.map(d => d?.id);

            this.relationshipMappingService.saveMany('component_component', relationshipName, component.id, modelName,
                newSelectedValues || [], oldSelectedValues || [], oldMappingList || [], null, null, 'component')
                .pipe(tap((result) => {
                    this.notification.openSuccess("Components updated successfully")
                    if (result?.data) {
                        (this.componentComponentTypeMap.componentComponents[component.id][componentTypeId] || []).push(result.data);
                    }

                    if (!this.ctCtDict[componentTypeId].isUnique) {
                        this.componentComponentTypeMap.componentComponents[component.id][componentTypeId] = this.componentComponentTypeMap.componentComponents[component.id][componentTypeId].filter(cc => {
                            return !toDelete.includes(cc.relationships.first_component.data.id) && !toDelete.includes(cc.relationships.second_component.data.id)
                        });
                        this.componentComponentTypeMap.components[component.id][componentTypeId] = this.componentComponentTypeMap.componentComponents[component.id][componentTypeId].map(cc => {
                            return {id: cc.relationships.first_component.data.id === component.id ? cc.relationships.second_component.data.id : cc.relationships.first_component.data.id}
                        })
                        this.componentComponentDict[component.id][componentTypeId] = deepCopy(this.componentComponentTypeMap.components[component.id][componentTypeId]);
                    } else {
                        this.componentComponentTypeMap.componentComponents[component.id][componentTypeId] = result?.data ?? [result?.data];
                        this.componentComponentTypeMap.components[component.id][componentTypeId] = [$event.value];
                        this.componentComponentDict[component.id][componentTypeId] = [$event.value];
                    }
                    this.changeDetectorRef.markForCheck();
                }), catchError(e => {
                    this.notification.openError("Error updating components.")
                    return of(e);
                })).subscribe();
        } catch (e) {
            this.notification.openError(`Error updating components: ${e}`)
        }
    }

    createUserFilters() {
        if (this.filter_string === '') {
            // Search clicked with no substring to search on
            this.notification.openError('Please enter text to search on before searching', 2000);
            return;
        }
        this.filter_warning = '';
        if (!this.filter_string) {
            this.user_filters = null;
            this.applyFilter('');
            this.refreshComponents();
            return;
        }
        this.user_filters = {or: []};
        // Always filter on name unless "name" is not a selected column
        let name_selected = this.config.selected_cols.component_type.map(col => col.id).includes('name');
        if (name_selected) {
            this.user_filters.or.push({op: 'ilike', name: 'name', val: '%' + this.filter_string + '%'});
        }
        this.user_search_selections.forEach(column => {
            if (this.cp_dict[column]?.attributes.data_type !== 'datetime') {
                this.user_filters.or.push({
                    and: [{
                        "name": "constant_components",
                        "op": "any",
                        "val": {
                            "and": [
                                {
                                    "name": "constant_property",
                                    "op": "has",
                                    "val": getBaseFilter(column, 'id')
                                },
                                getBaseFilter('%' + this.filter_string + '%', 'value', 'ilike')
                            ]
                        }
                    }]
                });
            }
        });
        if (this.user_filters.or.length === 0) {
            this.notification.openError('Please select a column to search on before searching', 2000);
            return;
        }
        this.page_index = 0;
        this.paginator.pageIndex = 0;
        this.refreshComponents();
    }

    clearSearch() {
        this.generic_constants = {};
        this.filter_string = '';
        this.user_search_selections = [];
        this.user_filters = null;
        this.page_index = 0;
        this.paginator.pageIndex = 0;
        this.applyFilter('');
        this.refreshComponents();
        this.changeDetectorRef.markForCheck();
    }

    getTree(column) {
        this.formDialogService.openConstantPropertyCalcTreeDialog(this.config.selected_component_type, column);
    }

    checkLock(component, column) {
        if (!this.can_unlock) return;
        this.lockDataService.getLocks('component', component, this.cp_dict?.[column] || column, this.tileData.tile.id)
    }

    createComponentsTable(): void {
        this.columns = getColIds(this.config.selected_cols.component_type).filter(c => {
            return this.selected_cols_dict[c] && (
                (this.selected_cols_dict[c].type === 'attribute' && ['name', 'start_time', 'end_time', 'changed_by'].includes(c)) ||
                (this.selected_cols_dict[c].type === 'constant_property' && this.cp_dict[c]) ||
                (this.selected_cols_dict[c].type === 'component_type' && this.ctDict[c]));
        })
        this.columns = ["Expand"].concat(this.columns);

        if (this.config.enable_row_selection) {
            this.columns = ["Select"].concat(this.columns);
        }
        this.changeDetectorRef.markForCheck();
        this.user_search_fields = [];
        this.columns.forEach(c => {
            if (this.cp_dict[c]?.attributes.data_type !== 'datetime' && !['Select', 'name', 'Expand'].includes(c)) {
                this.user_search_fields.push({
                    name: this.selected_cols_dict[c]?.title || this.cp_dict[c]?.attributes.name || c,
                    value: c
                });
            }
        });

        this.agg_columns = this.columns.map(c => 'aggregation' + c);
        this.initialiseColumnFormats();

        this.dataSource.data = this.components;
        this.dataSource.filterPredicate = (data, filter) => {
            const columnMatched = this.config.selected_cols.component_type.reduce((boolean, column) => {
                const constant = this.generic_constants[data.id]?.[getColId(column)];
                return (boolean || (constant && isConstant(constant) &&
                    constant.value?.toString().toLowerCase().includes(filter.toLowerCase())));
            }, false);
            return (data.attributes.name && data.attributes.name.toLowerCase().includes(filter.toLowerCase())) || columnMatched;
        };

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            const column = this.selected_cols_dict[sortHeaderId];
            if (!column) {
                return;
            } // Select and Index columns
            const col_id = getColId(column);
            switch (true) {
                case col_id === "name":
                    return data.attributes.name;
                case column.type === 'constant_property':
                    if (!isConstant(this.generic_constants[data.id][col_id])) {
                        return;
                    }
                    if (this.cp_dict[col_id].attributes.data_type === 'float') {
                        return (this.generic_constants[data.id][col_id] as ConstantField).value;
                    } else if (this.cp_dict[col_id].attributes.data_type === 'datetime') {
                        return new Date((this.generic_constants[data.id][col_id] as ConstantField).value);
                    } else {
                        return (this.generic_constants[data.id][col_id] as ConstantField).value;
                    }
                case col_id === "start_time":
                    return new Date(data.attributes.start_time);
                case col_id === "end_time":
                    return new Date(data.attributes.end_time);
                default:
                    return "";
            }
        };
        // this.dataSource.paginator = this.paginator;

        this.eventsService.event_state = {};
        this.eventsService.error_list = {};
        this.expandedElement = null;
        this.loading_next = false;
        this.changeDetectorRef.markForCheck();
        setTimeout(() => {
            this.setTopScroll();
            this.setTableHeight();
            this.changeDetectorRef.markForCheck();
        });
    }

    selectEvent(row, checked) {
        this.rows_selection_status[row.id] = checked.checked;
    }

    selectAll(checked) {
        this.rows_selection_status = {};
        this.components.forEach(row => {
            this.rows_selection_status[row.id] = checked.checked;
        });
    }

    addAggregationRow() {
        let row = {};
        this.columns.forEach(col_id => {
            if (!['Select', 'Index', 'Expand'].includes(col_id)) {
                row = this.aggregateColumn(col_id, row);
            }
        });
        this.aggregation_row = row;
    }

    aggregateColumn(col_id, row) {
        let agg_type: string = (this.selected_cols_dict[col_id]?.format?.aggregation_type !== 'none' &&
                this.selected_cols_dict[col_id]?.format?.aggregation_type)
            || this.cp_dict[col_id]?.attributes?.aggregation
            || 'total';
        let is_numeric = this.cp_dict.hasOwnProperty(col_id) &&
            this.cp_dict[col_id].attributes.data_type === 'float';

        let count: number = 0;
        let total: number = 0;
        this.dataSource.filteredData.forEach(comp => {
            const gc = this.generic_constants[comp.id]?.[col_id];
            if (is_numeric && isConstant(gc)) {
                let val = (this.generic_constants[comp.id][col_id] as ConstantField).value;
                val = parseFloat(val?.toString());
                if (val !== null && val !== undefined && !isNaN(val)) {
                    total += val;
                    count += 1;
                }
            } else {
                // Count if it has a value
                if (((gc && (isConstant(gc) && gc.value) || !isConstant(gc)) || comp.attributes[col_id]) && agg_type !== 'none') {
                    count += 1;
                }
            }
        });
        if (count !== 0) {
            switch (true) {
                case agg_type === 'total' && is_numeric === true:
                    row[col_id] = utils.deepCopy(total);
                    break;
                case (agg_type === 'average' || agg_type === 'mean') && is_numeric === true:
                    row[col_id] = utils.deepCopy(total) / utils.deepCopy(count);
                    break;
                case agg_type === 'count' || !is_numeric:
                    row[col_id] = utils.deepCopy(count);
                    break;
                case agg_type === 'none':
                    break;
                default:
                    return "";
            }
        } else if ((agg_type !== 'none' && is_numeric) || agg_type === 'count') {
            row[col_id] = 0;
        }
        return row;
    }

    saveConstant(component, cpId: ModelID, $event) {
        let $sub;
        if (!isConstant(this.generic_constants[component.id][cpId])) {
            return;
        }

        let constant_value = (this.generic_constants[component.id][cpId] as ConstantField).value;

        this.componentEventsTableService.$validateConstantValue.pipe(
            take(1),
            concatMap(newValue => {
                if (!newValue.value && newValue.validated) {
                    return of(false);
                }
                if (!this.validationService.validateValue(cpId, this.generic_constants[component.id], this.validationDict)) {
                    return of(null);
                }
                constant_value = newValue.value;
                (this.generic_constants[component.id][cpId] as ConstantField).value = constant_value;
                return this.genericConstantData.saveComponentConstant(
                    component.id,
                    cpId,
                    constant_value
                );
            })).subscribe(result => {
            if (result) {
                this.notification.openSuccess("Value saved.", NOTIFICATION_DURATION_MS);
                this.audit_mixin_dict[component.id][cpId].attributes = result;
                if (this.config.show_aggregations || this.config.show_aggregations_top) {
                    this.aggregation_row = this.aggregateColumn(cpId, this.aggregation_row);
                }
                this.updateConditionalColumnFormats();
                const constantProperty = this.cp_dict[cpId];

                if (this.doFormatting || this.autoFormattingIds.includes(cpId)) {
                    this.componentEventsTableService.getConstantConditionalFormat(
                        component.id, this.cp_class_dict, constantProperty, this.selected_cols_dict[cpId], this.limits_map, constant_value, !this.doValidation);
                }
            }
            if (result === false) {
                //Specifically user failed to reenter a correct value when prompted to do so
                this.notification.openError("Value not saved.", NOTIFICATION_DURATION_MS, 'Close');
            }
            this.changeDetectorRef.markForCheck();
        });

        this.componentEventsTableService.detectComponentConstantLimits(null, constant_value, this.limits_map, this.selected_cols_dict[cpId], component, this.doValidation);
    }

    // Component Type Form
    editComponentType(component_type) {
        // FIXME put call for full list onto the constant properties form after formula builder merge
        this.notification.openSuccess("Loading component type form...", 3000);
        this.$constant_properties = utils.refreshSubscription(this.$constant_properties);
        this.$constant_properties = this.api.constant_property.searchMany().pipe(takeUntil(this.onDestroy)).subscribe(results => {
            const constant_properties_list = results.data;
            utils.fill_object_relations([component_type], constant_properties_list, 'constant_properties');
            const dialogRef = this.formDialogService.editComponentType(component_type, constant_properties_list);
            dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
                if (result) {
                    if (component_type) {
                        component_type = result;
                    }
                    this.createComponentsTable();
                }
            });
        });
    }

    initialiseColumnFormats() {
        this.format_dict = {};
        this.config.selected_cols.component_type.forEach(column => {
            this.format_dict[column.id] = this.tableUtils.getColumnFormats(column.format);
        });
        this.updateConditionalColumnFormats();
    }

    canEditComponent() {
        return this.userService.canEditComponent(this.component_type?.attributes?.base_type);
    }

    showCreateComponent() {
        return this.config.can_create_component;
    }

    canCreateComponent() {
        return this.userService.canCreateComponent(this.component_type?.attributes?.base_type);
    }

    newComponent() {
        this.router.navigate([{outlets: {component_panel: ['component', this.tileData.tile.id]}}],
            {relativeTo: this.activatedRoute, queryParams: this.activatedRoute.snapshot.queryParams});
    }

    editComponent(component, $event) {
        $event.stopPropagation();
        this.router.navigate([{outlets: {component_panel: ['component-detail', component.id, this.tileData.tile.id]}}],
            {relativeTo: this.activatedRoute, queryParams: this.activatedRoute.snapshot.queryParams});

    }

    updateConditionalColumnFormats() {
        const data = {
            constant_property: {data: this.generic_constants, dict: this.cp_dict}
        };
        this.conditional_dict = this.conditionalService.extractConditions(this.conditions, this.components, this.columns,
            data);
        this.formats = this.tableUtils.combineStyles(this.format_dict, this.conditional_dict, this.components);
        this.formats = this.tableUtils.updateStylesForDisabled(
            this.config.selected_cols.component_type, this.components, this.cp_dict, this.generic_constants, this.formats
        );

        this.changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        this.tableUtils.removeListeners();
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
        if (filterValue) {
            this.filter_warning = 'Displaying current Page filter results. Please click Search to display the full table results.';
        }
        this.filteredData.next(filterValue);
    }

    trackByPrintout(index, printout) {
        return printout.name;
    }

    getEventDataFileType($event) {
        this.componentEventsTableService.openFileFormatModal($event, this.downloadComponent, this.config.download_file_type,
            'component_types', [this.component_type.id]);
    }

    downloadComponentData(filetype: FileDownloadOption = 'formatted_csv', filters?: RESTFilter) {
        if (!filters) {
            filters = this.componentEventsTableService.getComponentsFilter(this.config,
                this.dateInst.dtp,
                this.search_by_name,
                this.user_filters,
                this.query_builder_filters);
        }

        let download_title = this.tileData?.tile?.attributes?.title;
        if (!download_title) {
            download_title = this.headerData.title;
        } else {
            if (this.headerData.title) {
                download_title = utils.deepCopy(this.headerData.title).concat("_", download_title);
            }
        }

        const print_title = utils.printDocTitle(download_title, this.dateInst.dtp.start,
            this.dateInst.dtp.end);

        this.csvExporter.downloadModelData('Component', print_title, filters, this.config.selected_cols.component_type,
            this.cp_dict, undefined, undefined, filetype);
    }

    downloadComponent = (filetype: FileDownloadOption = 'formatted_csv', fileConstantPropertyIDs: ModelID[]) => {
        const filters = this.componentEventsTableService.getComponentsFilter(this.config,
            this.dateInst.dtp,
            this.search_by_name,
            this.user_filters,
            this.query_builder_filters);

        const componentIds = Object.keys(this.rows_selection_status).filter(key => this.rows_selection_status[key]);
        const fileTypes: FileDownloadOption[] = FILE_DOWNLOAD_OPTIONS.map(opt => opt.value);
        if (filetype === 'files') {
            this.componentEventsTableService.downloadComponentFiles(filters, fileConstantPropertyIDs, componentIds);
        } else if (fileTypes.includes(filetype)) {
            const ft = filetype as FileDownloadOption;
            this.downloadComponentData(ft, filters);
        }
    }

    /** Sends an invoice request to JDE
     * @param component_ids: list of components being invoiced
     * @param client_exporter_name: type of invoice request being sent (e.g. debit, credit)
     */
    push_data_to_client(component_ids: string[], client_exporter_name: string) {
        this.componentEventsTableService.push_data_to_client(component_ids, client_exporter_name).subscribe((resp) => {
            this.messages.push(resp);
            component_ids.forEach(component_id => {
                this.component_state[component_id] = {state: 'success', message: resp['message']};
            });
        }, (err) => {
            console.error("Error while exporting data to client:", err);
            component_ids.forEach(component_id => {
                this.component_state[component_id] = {state: 'error', message: err.error.message ?? err.message};
            });
            console.log("event_status:", this.component_state);
        });
    }

    push_selected_components_to_client(client_exporter_name, selected_rows?) {
        if (selected_rows === undefined) {
            selected_rows = [];
            for (let key in this.rows_selection_status) {
                if (this.rows_selection_status[key]) {
                    selected_rows.push(key);
                }
            }
        }
        if (selected_rows.length < 1) {
            this.notification.openError('No record selected');
        } else {
            this.push_data_to_client(selected_rows, client_exporter_name);
            const row_count = selected_rows.length;
            if (client_exporter_name.includes('credit')) {
                this.notification.openSuccess(`${row_count} of ${selected_rows.length} Sales order reversal(s) received and will be processed shortly`, 3000);
            } else {
                this.notification.openSuccess(`${row_count} of ${selected_rows.length} Sales order(s) received and will be processed shortly`, 3000);
            }
        }
    }

    getConstantPropertyComponentTypes(componentTypeId: ModelID, constantPropertyIds: ModelID[] = [], autoConditions: ModelID[] = []) {
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('component_type', componentTypeId)
        ];
        if (constantPropertyIds && constantPropertyIds.length > 0) {
            options.filters.push(getRelationWithManyIdsFilter('constant_property', constantPropertyIds));
        }
        this.api.constant_property_component_type.searchMany(options).pipe(
            first(), takeUntil(this.onDestroy),
            map(response => {
                    this.constant_property_component_types = response.data;
                    this.limits_map = {};
                    this.constant_property_component_types.map(
                        cpct => {
                            this.limits_map[cpct.relationships.constant_property.data.id] = cpct;
                        }
                    );
                }
            )
        ).subscribe(() => {
            this.components.forEach(component => {
                for (let cpId of constantPropertyIds) {
                    if (!this.doFormatting && !this.autoFormattingIds.includes(cpId)) continue;
                    const cp = this.cp_dict[cpId];
                    if (this.generic_constants[component.id]?.hasOwnProperty(cpId) &&
                        isConstant(this.generic_constants[component.id][cpId])) {
                        this.componentEventsTableService.getConstantConditionalFormat(
                            component.id, this.cp_class_dict, cp, this.selected_cols_dict[cpId], this.limits_map,
                            (this.generic_constants[component.id][cpId] as ConstantField).value, false);
                    }
                }
            });
            this.changeDetectorRef.markForCheck();
        });

    }

    downloadPdf(orientation) {
        if (this.dateInst.hasChanges) {
            if (!confirm('The Time Traveler has been changed but not refreshed. The wrong date/data may reflect on your report. Continue anyway?')) {
                return;
            }
        }
        this.isRenderingPdf = true;
        this.changeDetectorRef.markForCheck();

        let pdfName = this.tileData?.tile?.attributes?.title;
        if (!pdfName) {
            pdfName = this.headerData.title;
        } else {
            if (this.headerData.title) {
                pdfName = utils.deepCopy(this.headerData.title).concat("_", pdfName);
            }
        }
        let pdfName_download = utils.printDocTitle(pdfName, this.dateInst.dtp.start, this.dateInst.dtp.end);
        this.notification.openError('Cannot download, tile has no title', 5000);

        this.notification.openSuccess('Pdf is downloading', 5000);
        setTimeout(() => {
            this.tilePdfGeneratorService.downloadPdf(`pdf-${this.tileData.id}`, pdfName_download, orientation).finally(() => {
                this.isRenderingPdf = false;
                this.changeDetectorRef.markForCheck();
            });
        }, 1000);
    }

    updateComponentConstantCalculations() {
        if (!confirm('This operations only performs a shallow update and might not update calculations as expected.\n\n' +
            'Have you reviewed the Component Templates for errors and refreshed your pages data?\n\n' +
            'Please confirm this action if you still want to perform this operation:')) {
            return;
        }

        const allSelectedComponentIds = Object.entries(this.rows_selection_status)
            .filter(([k, v]) => v)
            .map(([k, v]) => k);
        this.notification.openSuccess(`Starting update of calculations linked to ${allSelectedComponentIds.length} component(s)`, 2000);

        if (allSelectedComponentIds.length) {
            this.componentEventsTableService.updateComponentConstantCalculations(allSelectedComponentIds).subscribe((response) => {
                this.notification.openSuccess(response.message, 5000);
                this.refreshComponents();
            });
        } else {
            this.notification.openError("No rows selected", 5000);
        }
        this.checkComponentConstantCalculationsStatusPoll();
    }

    openEdit() {
        this.needToEdit.emit('Query Builder');
    }

    doQueryWarning() {
        if (!(this.config.query || this.config.constant_property_time)) {
            return;
        }
        // reset warning class
        this.buttons.find(btn => btn.name === 'Query Used').class =
            this.buttons.find(btn => btn.name === 'Query Used').class.replaceAll(' warning', '');

        this.buttons.find(btn => btn.name === 'Query Used').HoverOverHint =
            this.componentEventsTableService.getQueryWarningTooltip(this.config, this.cp_dict);

        if (this.components?.length < 1) {
            this.buttons.find(btn => btn.name === 'Query Used').class += ' warning';
        }

    }

    lockData($event) {
        if (this.components?.length < 1) {
            return;
        }
        const filters = this.componentEventsTableService.getComponentsFilter(this.config, this.dateInst.dtp,
            this.search_by_name, this.user_filters, this.query_builder_filters);

        const selected_components = Object.keys(this.rows_selection_status).filter(item => this.rows_selection_status[item] === true);
        this.lockDataService.lockData('component', this.config.lock_template.id, this.component_total, filters, selected_components, this.component_type.attributes.name);
    }

    setButtons(): void {
        this.buttons = [
            {
                name: 'Generate PDF Portrait',
                func: () => this.downloadPdf('p'),
                class: 'fa small fa-file-pdf-o hide-xs',
                HoverOverHint: 'Generate portrait pdf for the tile'
            },
            {
                name: 'Generate PDF Landscape',
                func: () => this.downloadPdf('l'),
                class: 'fa small fa-file-pdf-o hide-xs',
                HoverOverHint: 'Generate landscape pdf for the tile'
            },
            {
                name: 'Download Data',
                func: ($event) => this.getEventDataFileType($event),
                class: 'small fas fa-file-download hide-xs',
                HoverOverHint: 'Download table data as a .csv or .xlsx file'
            }
        ];

        if (this.config.lock_template?.id) {
            if (["create event_lock", 'create component_lock', 'create lock_template_version']
                .every(f => this.appScope.current_user.feature_names.includes(f))) {
                this.buttons.push(
                    {
                        name: 'Lock Data',
                        func: ($event) => this.lockData($event),
                        class: 'small fas fa-lock hide-xs',
                        HoverOverHint: 'Lock data with the selected template'
                    }
                );
            }
        }
        if (this.config.enable_export_component?.length > 0) {
            this.config.enable_export_component.forEach(item => {
                if (item.supports_bulk_export === true) {
                    this.buttons.push(
                        {
                            name: camelLabel(item.display_text) || camelLabel(item.export_name),
                            func: () => this.push_selected_components_to_client(item.export_name),
                            class: 'fa small fa-arrow-up',
                            HoverOverHint: item.display_text
                        },
                    );
                }
            });
        }

        if (this.config.enable_row_selection) {
            this.buttons.push(
                {
                    name: 'Update Calculations',
                    func: () => this.updateComponentConstantCalculations(),
                    class: 'fa small fa-repeat hide-xs',
                    HoverOverHint: 'Manually re-run component calculations'
                },
            );
        }

        if (this.config.query || (this.config.constant_property_time && (this.config.start_prop || this.config.end_prop))) {
            this.buttons.push({
                name: 'Query Used',
                func: () => this.openEdit(),
                class: 'small fas fa-info-circle hide-xs',
                HoverOverHint: 'This table is using a custom query. Click to open the query builder.'
            });
        }
        this.tileData.buttonsChanged.next(this.buttons);
    }

    setFilterTableButtonConfig() {
        this.filter_button_config = {
            cp_dict: this.cp_dict,
            constant_properties: this.constant_properties.filter(cp => getColIds(this.config.selected_cols.component_type).includes(cp.id)),
            component_type: this.component_type,
            column_dict: this.selected_cols_dict,
            time_properties: [this.cp_dict[this.config.start_prop], this.cp_dict[this.config.end_prop]]
        };
    }

    mimicScrollBottom(event) {
        if (!this.config.scroll_top) {
            return;
        }
        this.component_events_table.nativeElement.scrollLeft = event.target.scrollLeft;
    }

    mimicScrollTop(event) {
        if (!this.config.scroll_top) {
            return;
        }
        this.top_scroll.nativeElement.parentElement.scrollLeft = event.target.scrollLeft;
    }

    setTopScroll() {
        if (!this.config.scroll_top) {
            return;
        }
        const e = this.component_events_table.nativeElement;
        const width = e.scrollWidth + (e.offsetWidth - e.clientWidth);
        if (width) {
            this.renderer.setStyle(this.top_scroll.nativeElement, 'width', width + 'px');
        }
    }

    setTableHeight() {
        const elRef = this.config.scroll_top ? this.component_events_table?.nativeElement : undefined;
        this.table_height = this.tableUtils.setTableHeight(this.utils_bar, elRef);
        this.changeDetectorRef.markForCheck();
    }

    updateFilterQuery(filters): void {
        this.query_builder_filters = filters;
        this.page_index = 0;
        this.paginator.pageIndex = 0;
        this.refreshComponents();
    }

    public checkComponentConstantCalculationsStatusPoll(): void {
        if (this.config.show_calc_update_status) {
            // Check if there are Dirty Models and poll the API if there are.
            this._calculationCurrentlyUpdatingSubscription =
                this.calcGraphStatusService.startPolling().subscribe((response: ConstantCalculationsStatusResponse) => {
                    this.buttons = this.tableUtils.refreshCalculationStatus(this.buttons, response);
                    this.headerData.hidePageLoader = response.is_updating;
                    if (!response.is_updating) {
                        this._calculationCurrentlyUpdatingSubscription.unsubscribe();
                    }
                });
        }
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;

    protected readonly deepCopy = deepCopy;
}
