import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {MatDialogRef, MatDialog} from "@angular/material/dialog";
import {
    FilterBuilderFormComponent,
    FilterData
} from "../../forms/query-builder-form/filter-builder-form/filter-builder-form.component";
import {TileDataService} from "../../services/tile_data.service";
import {ComponentType} from "../../_models/component-type";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {KeyMap, IDMap} from '../../_typing/generic-types';
import {ConstantProperty} from '../../_models/constant-property';
import {EventType} from '../../_models/event-type';
import {ConfigStub} from '../../_typing/config-stub';
import {QueryBuilderService} from "../../services/query_builder.service";
import {NotificationService} from "../../services/notification.service";


export interface FilterTableButtonConfig {
    cp_dict: IDMap<ConstantProperty>;
    constant_properties: ConstantProperty[];
    component_type?: ComponentType;
    component_types?: ComponentType[];
    event_types?: ConfigStub<EventType>[];
    column_dict: KeyMap<EventConfigColumn>;
    time_properties?: [ConstantProperty, ConstantProperty];
}

@Component({
    selector: 'filter-table-button',
    templateUrl: './filter-table-button.component.html',
    styleUrls: ['./filter-table-button.component.less'],
    standalone: false
})
export class FilterTableButtonComponent implements OnInit {
    @Input() config: FilterTableButtonConfig;
    @Output() queryChanged = new EventEmitter<any>();

    private user_filters: any; //Processed filters sent back to the parent component
    query: any; //Query received from the query-builder and in play for the life of this component
    query_tooltip = "This table has a filter applied. Click here to view the applied filter(s).";
    no_query_tooltip = "Click here to apply temporary filters.";

    constructor(private tileData: TileDataService,
                private queryBuilderService: QueryBuilderService,
                private dialog: MatDialog,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    openFilterDialog(target): void {
        if (!this.config) {
            this.notification.openError("No data to filter", 3000);
            return;
        }
        let data: FilterData = {
            cp_dict: this.config.cp_dict,
            constant_properties: this.config.constant_properties,
            positionRelativeToElement: target,
            selected_cols_dict: this.config.column_dict,
            query: this.query
        };
        let max_index = Math.max(...Object.values(this.config.column_dict)?.map(obj => obj.index));
        this.config.time_properties.forEach(cp => {
            if (cp) {
                data.selected_cols_dict[cp.id] = {
                    id: cp.id,
                    index: max_index,
                    type: 'constant_property',
                    title: this.config.cp_dict[cp.id]?.attributes.name
                }
                max_index += 1;
                data.constant_properties.push(cp);
            }
        })

        if (this.config.component_type) {
            data.component_type = this.config.component_type;
        } else if (this.config.event_types) {
            data.event_types = this.config.event_types;
            data.component_types = this.config.component_types
        }
        const dialogRef: MatDialogRef<FilterBuilderFormComponent> = this.dialog.open(FilterBuilderFormComponent, {
            data: data,
            panelClass: 'filter-dialog-panel'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result?.refresh) {
                this.query = result.query;
                if (result.query) {
                    const model_type = this.config.component_type ? 'COMPONENT' : 'EVENT';
                    this.user_filters = this.queryBuilderService.toJsonQuery(result.query, model_type);
                    this.queryChanged.next(this.user_filters);
                } else {
                    this.queryChanged.next(null);
                }
            }
        });
    }
}
