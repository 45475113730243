import {Component, ViewEncapsulation, ViewChild, Input} from "@angular/core";

import {LockTemplateDependencyTreeService} from "./lock-template-dependency-tree.service";
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {LockTemplateVersion, LockVersionDependencyNode} from '../../_models/lock-template-version';
import {takeUntil, tap} from 'rxjs/operators';
import {ModelID} from '../../_typing/generic-types';
import { HttpClient } from "@angular/common/http";
import {BaseComponent} from '../../shared/base.component';
import {ConstantProperty, isConstantProperty} from '../../_models/constant-property';

@Component({
    selector: 'lock-template-dependency-tree',
    templateUrl: 'lock-template-dependency-tree.component.html',
    styleUrls: ['../../tables/lock-template-version-table/lock-template-version-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [LockTemplateDependencyTreeService],
    standalone: false
})

export class LockTemplateDependencyTree extends BaseComponent {
    selected: string[] = [];
    prop_expanded = {};
    _template_version: LockTemplateVersion;
    @Input() set template_version(template_version: LockTemplateVersion) {
        if (template_version && template_version?.id !== this._template_version?.id) {
            this._template_version = template_version;
            let tree = this.cps.generateTreeSchema(this.template_version.attributes.schema);
            this.loadTree(tree);
        }
    }

    get template_version(): LockTemplateVersion {
        return this._template_version;
    }

    @Input() component_type_id: ModelID;
    @Input() event_type_id: ModelID;
    @Input() constant_property_id: ModelID;
    @Input() selected_constant_property: ConstantProperty;
    private api = '/api/constant_property_calc_tree/';

    @ViewChild('lockTree') lockTree: { treeControl: { collapseAll: () => void; expandAll: () => void; }; };
    isExpanded = false;
    search_string: string = '';
    treeControl = new NestedTreeControl((node) => node['children']);
    dataSource = new MatTreeNestedDataSource();

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    constructor(public cps: LockTemplateDependencyTreeService,
                private http: HttpClient) {
        super();
    }

    ngOnInit() {
        this.cps.highlightNodes.pipe(
            takeUntil(this.onDestroy),
            tap(result => {
                this.selected = result;
                if (this.constant_property_id && this.selected?.length < 1) {
                    this.selected = [this.constant_property_id]
                }
            })).subscribe();

        if (this.component_type_id && this.constant_property_id) {
            this.api += 'component/';
            this.loadCalcTree(this.component_type_id);
        } else if (this.event_type_id && this.constant_property_id) {
            this.api += 'event/';
            this.loadCalcTree(this.event_type_id);
        }

        if (this.constant_property_id) {
            this.selected = [this.constant_property_id]
        }
        if (this.selected_constant_property && isConstantProperty(this.selected_constant_property)) {
            this.search_string = this.selected_constant_property.attributes?.name;
        }
    }

    loadCalcTree(type_id: ModelID) {
        this.http.get(this.api + type_id + '/' + this.constant_property_id)
            .pipe(takeUntil(this.onDestroy))
            .subscribe((result: { data: LockVersionDependencyNode }) => {
                let tree = this.cps.generateTreeSchema(result.data);
                this.loadTree(tree);
            });
    }

    loadTree(tree) {
        this.dataSource.data = [tree];
        this.treeControl.dataNodes = [tree];
        this.isExpanded = false;
        if (this.lockTree) {
            this.toggleFullTree();
        }
        this.cps.findNodesFromFilter(this.search_string);
    }

    toggleFullTree() {
        if (this.isExpanded) {
            this.lockTree.treeControl.collapseAll();
            this.isExpanded = false;
        } else {
            this.lockTree.treeControl.expandAll();
            this.isExpanded = true;
        }
    }
}
