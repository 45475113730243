import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE} from "./shared-types";
import {TileParameters} from '../services/tile_data.service';

export interface BeforeSaveAction {
    beforeSave(): boolean;
}

interface ITileCategory {
    colour: string;
    name: string;
}

export class TileAttributes extends AuditMixinAttributes {
    parameters: TileParameters;
    content: string;
    title: string | null;
    category: ITileCategory | null;
    custom_dtp: boolean;
    show_header: boolean;
    hide_edit: boolean;
    hide_scroll: boolean;
    range: string | null;
    sample_period: string | null;
    calendar: string | null;
    start: string | null;
    end: string | null;
    relative_dtp : any | null;
}

export class TileRelationships extends AuditMixinRelationships {
    session_states: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
}

export class DefaultTileAttributes extends TileAttributes {
    show_header: boolean = true;
    hide_edit: boolean = true;
    custom_dtp: boolean = false;
    content: string | null = null;
    parameters: TileParameters = {};
    title: string | null = "";
    category: ITileCategory = {
        colour: "#3a3a3c",
        name: "Graphite"
    };
    calendar: string | null = null;
    range: string | null = null;
    start: string | null = null;
    end: string | null = null;
    sample_period: string | null = null;
    hide_scroll: boolean = false;
    relative_dtp: boolean = false;
    account_name: string | null = null;
}

export class Tile extends AuditMixin {
    attributes: TileAttributes;
    id: string;
    type: string = 'tile';
    relationships: TileRelationships;

    constructor() {
        super();
        this.attributes = new TileAttributes();
        this.relationships = new TileRelationships();
    }
}
