import {Component, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Subject, interval} from 'rxjs';
import {tap, takeUntil, filter} from 'rxjs/operators';
import {moment, TimezoneSelectorService} from '../../services/timezone-selector.service';
import {TimezoneOption} from '../../default-configuration';

@Component({
    selector: 'timezone-selector',
    templateUrl: './timezone-selector.component.html',
    styleUrls: ['./timezone-selector.component.less', '../../components/account-picker/account-picker.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TimezoneSelectorComponent implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    active_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    active_timezone_offset_string;

    timezone_options: TimezoneOption[];

    show_clock: boolean = false;
    clock = moment.tz();
    clock_min = false;
    $timer = interval(1000);

    constructor(private timezoneSelectorService: TimezoneSelectorService) {

        this.timezoneSelectorService.activeTimezoneChanged.pipe(tap(value => {
            this.active_timezone = value.timezone;
            this.active_timezone_offset_string = (moment.tz(this.active_timezone).format('Z'));
            this.show_clock = (this.active_timezone !== this.local_timezone);
        }), takeUntil(this.onDestroy))
            .subscribe();

        this.timezoneSelectorService.timezoneOptionsReady.pipe(tap((value: TimezoneOption[]) => {
            this.timezone_options = value;
        }), takeUntil(this.onDestroy))
            .subscribe();

        this.timezoneSelectorService.timezoneClockMin.pipe(tap((value: boolean) => {
            this.clock_min = value;
        }), takeUntil(this.onDestroy)).subscribe();

        /**Callback subject = timezoneSelectorService.timezoneOptionsReady**/
        this.timezoneSelectorService.getConfiguredTimezones();
        this.startClock();
    }

    startClock() {
        this.$timer.pipe(
            filter(() => this.show_clock === true),
            takeUntil(this.onDestroy))
            .subscribe(x => {
                this.clock = moment.tz();
            });
    }

    changeTimezone(value) {
        this.timezoneSelectorService.timezoneSelectionChanged.next({timezone: value});
    }

    toggleTimezoneClock(value: boolean) {
        this.timezoneSelectorService.timezoneClockMin.next(value);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
