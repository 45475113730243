<div class="form-dialog user">
  <h1>User: {{user.attributes.name}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true" style="min-width:800px;" (selectedTabChange)="onTabClick($event)">
      <mat-tab label="Basic">
        <form class="form-section column">
          <div *ngIf="user.attributes.restricted_access===true" class="warning"><b>This user has restricted access.</b>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="user.attributes.name" name="name">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Middle Name</mat-label>
              <input matInput [(ngModel)]="user.attributes.middle_name" name="middle name">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput [(ngModel)]="user.attributes.last_name" name="last name">
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>Alias</mat-label>
              <input matInput [(ngModel)]="user.attributes.alias" name="alias">
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="user.attributes.email" name="email">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Sms</mat-label>
              <input matInput [(ngModel)]="user.attributes.sms" name="sms">
            </mat-form-field>
          </div>

          <div class="row" style="margin-top: 20px">
            <mat-checkbox [(ngModel)]="user.attributes.email_disabled" name="email_disabled">Email disabled
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="user.attributes.sms_disabled" name="sms_disabled">Sms disabled
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="user.attributes.require_otp" name="require_otp"
                          [disabled]="!canDisableOTP || !user.attributes.require_otp"
                          [matTooltip]="user.attributes.require_otp? 'You must be an Administrator to disable OTP authentication' :
'Please use the My Account page to enable OTP authentication'">Require OTP
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="user.attributes.active" name="active">Active</mat-checkbox>
          </div>

          <div class="row">
            <account-filter *ngIf="userAccountIds"
                            [initialAccountIds]="userAccountIds"
                            [multiple]="true"
                            [restrictManageUsers]="true"
                            (accountFilterEmitter)="accountsChanged($event)"
                            label="Accounts"
                            class="row flex-half alone">
            </account-filter>

            <select-many-search-component
                [api_model]="'role'"
                [custom_filters]="roleFilters"
                [allowedAccountIds]="userAccountIds"
                [value]="userRoles"
                (selectionChange)="rolesChanged($event)"
                compareFunctionName="compareValueToId"
                label="Roles"
                class="select-search"
            >
            </select-many-search-component>
          </div>

          <div class="row">
            <div *ngIf="alerts_list && alerts_list.length >= 0 && series_list && series_list.length > 0"
                 class="select-search" (mouseenter)="hint='method'">
              <select-many-search [label]="'Alert Series'"
                                  [fill_stubs]="true"
                                  [(value)]="user.relationships.alerts.data"
                                  [data]="alerts_list"
                                  [stringFunction]="alertsFunction">
              </select-many-search>
            </div>

            <div *ngIf="series_list && series_list.length>0" class="select-search" (mouseenter)="hint='method'">
              <select-many-search [label]="'SMS Series'"
                                  [fill_stubs]="true"
                                  [(value)]="user.relationships.series.data"
                                  [data]="series_list">
              </select-many-search>
            </div>
          </div>

        </form>
      </mat-tab>
      <mat-tab label="Access">
        <form class="form-section column" *ngIf="tab_status[tabs.ACCESS]">

          <h4>Process Access Features</h4>
          <user-form-process-access-features [user]="user" #saveable></user-form-process-access-features>

        </form>
      </mat-tab>
      <mat-tab label="Groups">
        <ng-container *ngIf="tab_status[tabs.GROUPS]">
          <relationship-group-selector [model]="user" #saveable></relationship-group-selector>
        </ng-container>
      </mat-tab>
      <mat-tab label="Pages">
        <ng-container *ngIf="tab_status[tabs.PAGES]">
          <h3>Select default pages for this user</h3>
          <user-preference [user]="user" #saveable></user-preference>
          <div class="row"></div>
          <relationship-page-selector [model]="user" #saveable></relationship-page-selector>
          <div class="row"></div>
          <relationship-page-selector [model]="user" [by_owner]="true"></relationship-page-selector>
          <div class="row"></div>
          <relationship-page-selector [model]="user" [by_group_user]="true"></relationship-page-selector>
        </ng-container>
      </mat-tab>
      <mat-tab label="Restricted">
        <form class="form-section column">
          <h4>Restricted access users views</h4>
          <div class="row">
            <mat-checkbox [(ngModel)]="user.attributes.restricted_access" name="restricted_access">Restricted access
            </mat-checkbox>
          </div>

          <mat-label matTooltip="Pages can now be shared via the PAGES tab">Please use the PAGES tab to share pages with
            this user.
          </mat-label>

          <div>
            <h4>Custom pages</h4>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="customRestrictedPages.name"
                     [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field>
              <mat-label>URL</mat-label>
              <input matInput [(ngModel)]="customRestrictedPages.url" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <button (click)="addPage()" class="bordered-button" mat-button style="align-self:center"> Add to user
              profile
              <i class="fa fa-arrow-down"></i>
            </button>

          </div>

          <div class="row" style="justify-content: space-between;">
            <mat-label>Accessible pages</mat-label>
            <button mat-button class="bordered-button" (click)="clear()">Clear all
              <i class="fa fa-minus" style="margin-left:20px;"></i>
            </button>
          </div>

          <div>
            <ng-container *ngIf="user.attributes.restricted_access">
              <!--this is now redundant-->
              <!--              <div class="row" *ngFor="let view of restrictedUserViews, let i = index">-->
              <!--                <input matInput readonly value="{{view.attributes.name}}">-->
              <!--                <i class="fa fa-trash" (click)="removeView(i, restrictedUserViews)" style="padding:0 20px;font-size:14px;"></i>-->
              <!--              </div>-->

              <div class="row" *ngFor="let view of customRestrictedPagesList, let i = index">
                <input matInput readonly value="{{view.name+' - '+view.url}}">
                <i class="fa fa-trash" (click)="removeView(i)" style="padding:0 20px;font-size:14px;"></i>
              </div>

            </ng-container>
            <ng-container *ngIf="customRestrictedPagesList.length===0">
              No custom views selected.
            </ng-container>
            <ng-container *ngIf="!user.attributes.restricted_access">

             <span>
              <h4>Please select the "Restricted Access" tick box above to make the selected user a restricted access user, and select views for the user</h4>
             </span>

            </ng-container>
          </div>

        </form>

      </mat-tab>
      <ng-container>
        <mat-tab label="Activity">
          <ng-template matTabContent>
            <user-activity-chart [user]="user"></user-activity-chart>
            <date-time-period *ngIf="current_tab===2" [immutable]="true" [no_url]="true"
                              class="date-period inline align-bottom"></date-time-period>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-close" (click)="onCloseClick()">Close</button>
    <button (click)="apply()" [disabled]="!canSave()" class="btn-save">Apply</button>
    <button (click)="save()" [disabled]="!canSave()" class="btn-save">Save</button>
  </div>
</div>
