import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ConstantPropertyDataService} from "../../../data/constant-property-data.service";
import {ComponentNameFormulaBuilderService} from "../../../services/component-name-formula-builder.service";
import {IDMap, KeyMap} from "../../../_typing/generic-types";
import {ConstantProperty} from "../../../_models/constant-property";
import {FormulaPart, NameFormula} from "../../../_typing/config/component-form-config";
import {FormDialogService} from "../../../services/form-dialog.service";
import {isEmpty as _isEmpty} from "lodash-es";

@Component({
    selector: 'component-name-formula-table',
    templateUrl: './component-name-formula-table.component.html',
    styleUrls: ['./component-name-formula-table.component.less', '../../../shared/styles/shared-table-styles.less'],
    standalone: false
})
export class ComponentNameFormulaTableComponent {
    @Input() nameFormulas: KeyMap<NameFormula>;
    @Input() cpDict: IDMap<ConstantProperty>;
    @Input() selectedKey = 0;

    @Output() nameFormulasChange = new EventEmitter<KeyMap<NameFormula>>();
    @Output() formulaSelected = new EventEmitter<string>();
    @Output() formulaDeleted = new EventEmitter<string>();
    public isEmpty = _isEmpty;

    constructor(private propertyData: ConstantPropertyDataService,
                private formulaBuilder: ComponentNameFormulaBuilderService,
                private formDialog: FormDialogService) {
    }

    nameString(formula: FormulaPart[]) {
        if (formula) {
            return this.formulaBuilder.getNameString(formula, this.cpDict)
        }
    }

    selectFormula(i) {
        this.selectedKey = i;
        this.formulaSelected.emit(i);
    }

    async removeItem(key: string): Promise<void> {
        if (await this.formDialog.confirm("Are you sure you want to delete this formula?", "No", "Yes, I'm sure")) {
            delete this.nameFormulas[key];
            this.nameFormulasChange.emit(this.nameFormulas);
            this.formulaDeleted.emit(key);
        }
    }
}
