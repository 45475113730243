import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {ActivatedRoute} from "@angular/router";
import {DateTimePeriodService} from "../../../services/date-time-period.service";
import {HeaderDataService} from "../../../services/header_data.service";
import {debounceTime, first, map, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {SplitAreaDirective, SplitComponent} from "angular-split";
import {Location} from '@angular/common';
import * as utils from '../../utils';
import {CdkDragEnd} from '@angular/cdk/drag-drop';
import {OreBody} from "../../../_models/ore-body";
import {OreBodyGroup} from "../../../_models/ore-body-group";
import {OreBodyGroupType} from "../../../_models/ore-body-group-type";
import {intersection as _intersection} from "lodash-es";
import {AccountService} from "../../../services/account.service";
import {AppScope} from "../../../services/app_scope.service";
import {Vector3D, Vector3D_ORIGIN, Vector3DOnPlane} from "../../vector3d";
import {Vector2D} from "../../Vector2D";
import {SearchQueryOptions} from '../../../services/api/search-query-options';
import {getAccountFilter} from "../../../services/api/filter_utils";
import {IDateTimePeriod} from "../../../_typing/date-time-period";

export interface OreBodyIcon {
    x: number;
    y: number;
    name: string;
    title: string;
    src: string;
    ore_body: OreBody;
}

@Component({
    selector: 'ore-body-group-view',
    templateUrl: './ore-body-group-view.component.html',
    styleUrls: ['./ore-body-group-view.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OreBodyGroupViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    dtp: IDateTimePeriod;
    ore_body_groups: any[]; //All Ore Body Groups in the db
    ore_body_group: OreBodyGroup; //The Ore Body Group zoomed in from the Flowchart
    ore_bodies_list: OreBody[]; //Full list of Ore Bodies belonging to the Ore Body Group
    filtered_ore_bodies_list: OreBody[]; //Full list of Ore Bodies belonging to the Ore Body Group
    selected_ore_bodies: OreBody[] = []; //List of Ore Bodies currently selected by the user for viewing
    selected_ore_body: OreBody; //Single Ore Body selected by the user for viewing table data?
    selected_ore_body_groups: OreBodyGroup[] = []; //List of Ore Bodies currently selected by the user for viewing in Tree mode
    ore_body_group_types: OreBodyGroupType[];

    selected_group_type: OreBodyGroupType; //Top level filter
    filtered_groups: any = {};  //Associative array of all filtered groups for each group type level
    selected_group: { [level: number]: OreBodyGroup } = {}; //Associative array of all selected groups for each group type level
    ore_body_types: string[] = []; //List of Ore Body Type names for the 'filter by type' select box from the list of Filtered Ore Bodies
    selected_ore_body_type: string; //Model binding variable for the Ore Body Type filter selection
    group_select_label: any = {}; //Stores the label (name based on type) for each group level
    ore_bodies_by_group_list: OreBody[]; //Stores filter applied to Ore Bodies by selecting a Group (to enable resetting when changing the Ore Body Type filter)

    current_view: string = 'select';
    group_search: string = '';
    search_results: any[] = [];
    group_search_by: string = 'name';
    group_tree_data: any[];

    parent_gts: any[] = []; //Group type object at the top of the group type hierarchy (parent = null)
    group_types: any = {}; //The group types in ascending order from parent_gt.attributes.ore_body_group_type_children.
    groups: any = {}; //Associative array of all groups for each group type level
    selected_groups: any = {}; //Associative array of all selected groups for each group type level
    name_map: any = {}; //Maps the level of a group type to its name (just for ease of use)

    fuse_search_attributes: any[] = [
        "attributes.name",
        "attributes.description",
        "attributes.full_path_names"
    ]; //passed to select search to allow fuse search by full_path_name

    private groupSearchSubject: Subject<string> = new Subject<string>();
    menuVisible: boolean = false;
    @ViewChild('context_menu') context_menu: ElementRef;
    @ViewChild('draggable') draggable: ElementRef;

    @ViewChild('ore_body_view') ore_body_view: SplitComponent;
    @ViewChild('left_panel') left_panel: SplitAreaDirective;
    @ViewChild('right_panel') right_panel: SplitAreaDirective;

    sizes = {
        percent: {
            left_panel: 30,
            right_panel: 70,
        }
    };

    ore_body_points: { [id: string]: Vector3D[] } = {};
    all_points: Vector3D[] = [];
    // first index: 0 'x-axis', 1 'y-axis'
    // second index: 0 'min', 1 'max'
    axis_display: [Vector2D, Vector2D] = [[0, 1], [0, 1]];
    model_centre: Vector3D = Vector3D_ORIGIN;

    y_min: number = 1000;
    y_max: number;
    x_min: number = 1000;
    x_max: number = 600;
    y_diff: number = 500;
    x_diff: number;

    scale_x: number = 600; //1 to 1
    scale_y: number = 500;
    drag_end: any;
    rotate_x: number = 0;
    rotate_y: number = 0;
    z_index: number = 1;
    rotate_z_count: number = 0;
    config_rotate_count = 0;

    nonGeoOreBodies: OreBodyIcon[] = [];
    icon_src: string = '/static/icons/custom_icons/mining icon pit.svg';
    svg_hidden: boolean = false; //Show or hide the svg image section

    constructor(private api: ApiService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private headerData: HeaderDataService,
                private location: Location,
                private renderer: Renderer2,
                private accountService: AccountService,
                private appScope: AppScope) {
    }

    ngOnInit(): void {
        const ctrl = this;
        const $config = ctrl.appScope.currentUserValue.pipe(first(), takeUntil(this.onDestroy), tap(result => {
            if (ctrl.appScope.config_name_map?.orebody_rotate_count?.value) {
                ctrl.config_rotate_count = ctrl.appScope.config_name_map.orebody_rotate_count.value;
            }
        }))
        combineLatest([ctrl.route.params, $config])
            .subscribe(params => {
                ctrl.headerData.buttons = [];
                ctrl.headerData.show_dtp = true;
                ctrl.dtp = this.dateTimePeriodService.getDTP();
                ctrl.loadPage();
            });
    }

    hasChildren = (group) => {
        return group.relationships.children.data && group.relationships.children.data.length > 0
    };

    @HostListener('click', ['$event'])
    onClick() {
        if (this.menuVisible === true) {
            this.menuVisible = false;
        }
    }

    getOreBodyGroup(): Observable<any> {
        const ctrl = this;
        const obg_id = ctrl.route.snapshot.params.oreBodyGroupID;
        if (obg_id) {
            return ctrl.api.ore_body_group.getById(obg_id).pipe(map(result => {
                this.ore_body_group = result.data;
                return this.ore_body_group;
            }))
        } else {
            return of(null);
        }
    }

    loadPage() {
        const ctrl = this;

        const $ore_body = this.getOreBodyGroup();
        const account_filter = new SearchQueryOptions();
        account_filter.filters = [
            getAccountFilter(ctrl.appScope.active_account_id)
        ];
        const $group_types = ctrl.api.ore_body_group_type.searchMany().pipe(
            map(result => this.ore_body_group_types = result.data)
        );
        const $groups = ctrl.api.ore_body_group.searchMany(account_filter).pipe(
            map(result => {
                this.ore_body_groups = result.data;
                this.group_tree_data = result.data;
            })
        );
        const $ore_bodies = ctrl.api.ore_body_light.searchMany(account_filter).pipe(
            map(result => this.ore_bodies_list = result.data)
        );
        forkJoin([$ore_body, $group_types, $groups, $ore_bodies]).pipe(
            map(results => {
                //Get the parent of all group types, then create the hierarchy
                const all_parents = this.ore_body_group_types.filter(group_type => group_type.relationships.parent.data === null);
                this.parent_gts = this.ore_body_group_types.filter(group_type => group_type.relationships.parent.data === null);
                if (all_parents) {
                    this.group_types = {};
                    all_parents.forEach(parent => {
                        parent.attributes.ore_body_group_type_children.forEach(child => {
                            if (this.group_types[parent.attributes.ore_body_group_type_children.indexOf(child)]) {
                                this.group_types[parent.attributes.ore_body_group_type_children.indexOf(child)].push(child);
                            } else {
                                this.group_types[parent.attributes.ore_body_group_type_children.indexOf(child)] = [child]
                            }
                        })
                    })
                }
                //Create associative array of groups based on their group type
                Object.keys(this.group_types).forEach(key => {
                    this.group_types[key].forEach(gt => {
                        this.groups[gt.name] = [];
                        this.name_map[this.group_types[key].indexOf(gt)] = gt.name;
                        this.selected_groups[gt.name] = [];
                    });
                });
                this.ore_body_groups.forEach(obg => {
                    const obgt = this.ore_body_group_types.find(gt => gt.id === obg.relationships.type.data.id);
                    if (obgt) {
                        const type_name = obgt.attributes.name;
                        this.groups[type_name].push(obg);
                    }
                });
                this.filtered_ore_bodies_list = []; //utils.deepCopy(this.ore_bodies_list);

            })
        ).subscribe(() => {
                this.setCurrentOBG();
            }
        );

        this.groupSearchSubject.pipe(
            debounceTime(300)
        ).subscribe(search_string => {
            if (this.group_search.length < 1) {
                this.search_results = [];
                return;
            }
            this.search_results = utils.deepCopy(this.group_tree_data);
            this.search_results = this.search_results.filter(item => {
                if (this.group_search_by === 'name') {
                    return item.attributes.name.toLowerCase().indexOf(this.group_search.toLowerCase()) > -1
                } else if (this.group_search_by === 'type') {
                    const group_type = this.ore_body_group_types.find(gt => gt.id === item.relationships.type.data.id);
                    if (group_type) {
                        return group_type.attributes.name.toLowerCase().indexOf(this.group_search.toLowerCase()) > -1
                    }
                } else if (this.group_search_by === 'path') {
                    const paths = item.attributes.full_path_names.map(pn => pn.toLowerCase());
                    let found: boolean = false;
                    paths.forEach(path => {
                        if (path.indexOf(this.group_search.toLowerCase()) > -1) {
                            found = true
                        }
                    });
                    return found;
                }
            });
            this.search_results = this.search_results.slice(0, 50);
        })
    }

    setCurrentOBG() {
        if (this.ore_body_group) {
            let group_map = [];
            let obg = utils.deepCopy(this.ore_body_group);
            const group_type = this.ore_body_group_types.find(gt => gt.id === obg.relationships.type.data.id);
            this.selected_group_type = group_type;
            this.filterGroups(0);
            this.selected_group[0] = this.ore_body_group;
            this.filterGroups(1);
            this.filterOreBodiesList(this.ore_body_group);
        }
    }

    filterGroups(level) {
        if (level === 0) {
            this.clearDown(level);
            this.group_select_label[level] = this.selected_group_type.attributes.name;
            this.filtered_groups[level] = this.ore_body_groups.filter(g => g.relationships.type.data.id === this.selected_group_type.id);
            this.selected_group_type.attributes.ore_body_group_type_children.forEach(child => {
                this.group_select_label[this.selected_group_type.attributes.ore_body_group_type_children.indexOf(child)] = child.name;
            })
        } else {
            this.filtered_groups[level] = this.ore_body_groups.filter(g =>
                this.selected_group[level - 1].relationships.children.data.map(child => child.id).includes(g.id));
        }
    }

    clearSelection(level) {
        this.selected_group[level] = null;
        if (this.filtered_groups[level + 1]) {
            this.filtered_groups[level + 1] = null;
        }
        this.filterOreBodiesList(this.selected_group[level - 1]);
    }

    changeFilter(ore_body_type) {
        this.filtered_ore_bodies_list = utils.deepCopy(this.ore_bodies_by_group_list);
        if (ore_body_type.value !== '') {
            this.filtered_ore_bodies_list = this.filtered_ore_bodies_list.filter(item => item.attributes.type_name === ore_body_type.value);
            this.selected_ore_bodies = _intersection(this.selected_ore_bodies, this.filtered_ore_bodies_list);
        }
    }

    //Tree methods
    searchGroups() {
        this.groupSearchSubject.next(this.group_search);
    }

    levelSelected(event, filter = true) {
        if (event.checked === true && !this.selected_ore_body_groups.map(obg => obg.id).includes(event.item.id)) {
            this.selected_ore_body_groups.push(event.item);
            event.item.checked = true;
        } else if (event.checked === false) {
            this.selected_ore_body_groups = this.selected_ore_body_groups.filter(obg => obg.id !== event.item.id);
            event.item.checked = false;
        }
        //Do we want selecting parent to select children?
        if (this.hasChildren(event.item)) {
            event.item.relationships.children.data.forEach(child => {
                child = this.ore_body_groups.find(obg => obg.id === child.id);
                this.levelSelected({item: child, checked: event.checked}, false);
            })
        }
        //Parent already includes all ore bodies its of children
        if (filter) {
            this.filterOreBodiesList(this.selected_ore_body_groups);
        }
    }

    //End Tree methods

    clearDown(level) {
        if (level === 0) {
            this.filtered_groups = {};
            this.group_select_label = {};
            this.selected_group = {};
            this.filtered_ore_bodies_list = [];
            this.selected_ore_bodies = [];
        }
    }

    filterOreBodiesList(selected_groups: OreBodyGroup[] | OreBodyGroup) {
        let groups: OreBodyGroup[];
        if (!Array.isArray(selected_groups)) {
            groups = [selected_groups];
        } else {
            groups = selected_groups;
        }
        this.filtered_ore_bodies_list = [];
        groups.forEach(sg => {
            if (sg.attributes.group_ore_bodies) {
                sg.attributes.group_ore_bodies.forEach(gob => {
                    const full_ore_body = this.ore_bodies_list.find(ob => ob.id === gob.id);
                    if (full_ore_body && !this.filtered_ore_bodies_list.includes(full_ore_body)) {
                        this.filtered_ore_bodies_list.push(full_ore_body);
                    }
                })
            }
        });
        if (groups.length < 1) {
            this.selected_ore_bodies = [];
        } else {
            if (!this.hasChildren(groups[0])) {
                this.selected_ore_bodies = utils.deepCopy(this.filtered_ore_bodies_list);
                this.oreBodiesSelected();
            }
        }
        this.ore_body_types = this.filtered_ore_bodies_list.map(ob => ob.attributes.type_name);
        this.ore_body_types = [...new Set(this.ore_body_types)];
        this.ore_bodies_by_group_list = utils.deepCopy(this.filtered_ore_bodies_list);
    }

    stringFunction(obj: any) {
        //Show OBG name next to Ore Body
        let string = '';

        let groups = obj.attributes.full_path_names;
        if (groups && groups.length > 0) {
            string = groups;
        } else {
            string = obj.attributes.name
        }
        if (obj.attributes.type_name) {
            string = string + " (" + obj.attributes.type_name + ")";
        }
        return string;
    };

    oreBodiesSelected() {
        // points for each ore body polygon
        this.ore_body_points = this.getOreBodyPoints(this.selected_ore_bodies);
        // points of all the polygons
        this.all_points = this.getFlatPointData();
        this.setBoundingBox(this.all_points);
        this.axis_display[0][0] = this.x_min;
        this.axis_display[0][1] = this.x_max;
        this.axis_display[1][0] = this.y_min;
        this.axis_display[1][1] = this.y_max;
        for (let i = 1; i <= this.config_rotate_count && i <= 4; i++) {
            console.log('Ore Body Rotating ', utils.deepCopy(i));
            this.rotateZ();
        }
    }

    private getOreBodyPoints(ore_bodies: OreBody[]): { [id: string]: Vector3D[] } {
        const response = {};
        ore_bodies.forEach(ob => {
            const points = ob.attributes.points;
            if (points && points.length > 2) {
                response[ob.id] = points;
            } else {
                response[ob.id] = [1]
            }
        });
        return response;
    }

    sendToBack(ob) {
        const index = this.selected_ore_bodies.indexOf(ob);
        this.selected_ore_bodies.unshift(ob);
        this.selected_ore_bodies.splice(index + 1, 1);
    }

    setBoundingBox(points: Vector3D[]) {
        // let points = this.getFlatPointData();
        this.x_min = 0; //resets the viewbox 'panning'
        this.y_min = 0;

        this.model_centre = Vector3DOnPlane.findPolygonCentre(points);

        if (points.length > 0) {
            this.y_min = points.reduce((min, p) => p[1] < min ? p[1] : min, points[0][1]);
            this.y_max = points.reduce((max, p) => p[1] > max ? p[1] : max, points[0][1]);
            this.x_min = points.reduce((min, p) => p[0] < min ? p[0] : min, points[0][0]);
            this.x_max = points.reduce((max, p) => p[0] > max ? p[0] : max, points[0][0]);

            this.x_diff = this.x_max - this.x_min;
            this.y_diff = this.y_max - this.y_min;
            this.scale_x = this.x_diff;
            this.scale_y = this.y_diff;
            // this.nonGeoOreBodies.forEach(ob => {
            //     ob.x += this.x_min;
            //     ob.y += this.y_min;
            // })
        }
    }

    getFlatPointData(): Vector3D[] {
        let all_points: Vector3D[] = [];
        this.nonGeoOreBodies = [];
        let x: number = 10;
        let y: number = 0;

        this.selected_ore_bodies.map(ob => {
            if (ob.attributes.points && ob.attributes.points.length > 2) {
                ob.attributes.points.map(points => {
                    all_points.push(points);
                })
            } else {
                this.nonGeoOreBodies.push({
                    x: x,
                    y: y,
                    title: ob.attributes.full_path_names[0],
                    name: ob.attributes.name,
                    src: this.icon_src,
                    ore_body: ob
                });
                x += 90; //move the next one up
                // if (this.nonGeoOreBodies.length % 5 === 0) {
                //     y -= 80; //after 5 in a row, start a new row
                // }
            }
        });
        return all_points;
    }

    getPoints(ob) {
        let points_string: string = '';
        const points = this.ore_body_points[ob.id];
        if (points) {
            points.forEach(point => {
                points_string += point[0] + ',' + point[1] + ' ';
            });
        }
        return points_string;
    }

    scrollHandler(event) {
        if (event.wheelDelta < 0) {
            this.scale_y += 30;
            this.scale_x += 30;
        } else {
            if (this.scale_y > 30 && this.scale_x > 30) {
                this.scale_y -= 30;
                this.scale_x -= 30;
            }
        }
        event.preventDefault();
    }

    resetSVG() {
        if (this.drag_end) {
            this.drag_end.source._dragRef.reset();
            this.drag_end.distance = {x: 0, y: 0};
        }
        this.renderer.removeAttribute(this.draggable.nativeElement, 'transform');
        this.scale_x = this.x_diff;
        this.scale_y = this.y_diff;
        this.rotate_x = 0;
        this.rotate_y = 0;
    }

    onDragEnded(event: CdkDragEnd) {
        this.drag_end = event;
    }

    rotateX() {
        this.rotate_x += 180;
        if (this.rotate_x === 360) {
            this.rotate_x = 0;
        }
    }

    rotateY() {
        this.rotate_y += 180;
        if (this.rotate_y === 360) {
            this.rotate_y = 0;
        }
    }

    rotateZ() {
        const r = Vector3DOnPlane.rotateClockwisePolygonAroundSelf(this.all_points);
        this.all_points = r[0];
        const displacement = r[1];

        // rotate each polygon and translate
        Object.entries(this.ore_body_points).forEach((e: [string, Vector3D[]]) => {
            let new_polygons = Vector3DOnPlane.bulkRotate90(e[1]);
            new_polygons = Vector3DOnPlane.bulkDisplacePoints(new_polygons, displacement);
            this.ore_body_points[e[0]] = new_polygons;
        });
        this.model_centre = Vector3DOnPlane.findPolygonCentre(this.all_points);
        this.rotate_z_count += 1;
        this.setBoundingBox(this.all_points);
        // Store the x-axis, since it is flipped during rotation
        const prev_axis = this.axis_display[0];
        this.axis_display[0] = this.axis_display[1];
        // this keeps the correct rotation, though they most likely want the smaller number near the origin
        // this.axis_display[1] = [prev_axis[1], prev_axis[0]]
        this.axis_display[1] = prev_axis;
    }

    contextMenu(e) {
        if (!this.route.snapshot.params.oreBodyGroupID) {
            return;
        }
        const origin = {
            left: e.pageX,
            top: e.pageY - 48
        };
        this.setPosition(origin);
        return false;
    };

    setPosition({top, left}) {
        this.renderer.setStyle(this.context_menu.nativeElement, 'left', `${left}px`);
        this.renderer.setStyle(this.context_menu.nativeElement, 'top', `${top}px`);
        this.menuVisible = true;
    };

    backToFlowchart() {
        this.location.back();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = this.ore_body_group.attributes.name;

        ctrl.headerData.buttons.push(...[
            {
                name: 'Back to Flowchart',
                func: ctrl.backToFlowchart.bind(ctrl),
                params: 'current',
                class: 'icon-edit hide-xs'
            }])

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
