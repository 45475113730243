import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {IFileOptions} from "../../../services/form-dialog.service";
import {takeUntil, tap} from "rxjs/operators";
import {ModelID, RESTFilter} from "../../../_typing/generic-types";
import {
    getBaseFilter,
    getManyRelationWithIdsFilter
} from "../../../services/api/filter_utils";
import {ConstantProperty} from "../../../_models/constant-property";
import {BaseComponent} from "../../../shared/base.component";

interface IValueDisplay {
    value: string;
    display: string;
}

interface IOutputEvent {
    fileType: string;
    fileConstantPropertyIDs: ModelID[];
}

@Component({
    selector: 'component-events-download-format-menu',
    templateUrl: './component-events-download-format-menu.component.html',
    styleUrls: ['./component-events-download-format-menu.component.less'],
    standalone: false
})
export class ComponentEventsDownloadFormatMenuComponent extends BaseComponent {
    @Input() fileOptions: IFileOptions[];
    @Input() format: string;
    @Input() relationshipType: 'event_types' | 'component_types';
    @Input() modelIDs: ModelID[];
    @Output() handleDownload: EventEmitter<IOutputEvent> = new EventEmitter();

    fileConstantProperties: ConstantProperty[] = [];
    public readonly filePropertyFilter: RESTFilter = [getBaseFilter("file", "data_type")];

    get bdForm() {
        return this.bulkDownloadForm.controls;
    }

    filteredFileOptions: IFileOptions[];
    fileDownloadTypes: IValueDisplay[] = [
        {value: 'excel', display: 'Excel'},
        {value: 'csv', display: 'CSV'},
        {value: 'files', display: 'Files'},
    ];

    bulkDownloadForm: FormGroup<{
        selectedFileType: FormControl<string | null>;
        selectedFileFormat: FormControl<string | null>
    }>;

    ngOnInit() {
        const defaultFileType: string = this.format.includes('excel') || this.format.includes('xlsx') ? 'excel' : 'csv';

        this.bulkDownloadForm = new FormGroup({
            selectedFileType: new FormControl<string>(defaultFileType),
            selectedFileFormat: new FormControl<string>(this.format)
        });

        this.filePropertyFilter.push(getManyRelationWithIdsFilter(this.relationshipType, this.modelIDs));
        this.setFileFormat();
        this.bulkDownloadForm.controls.selectedFileType.valueChanges.pipe(takeUntil(this.onDestroy), tap(() => {
            this.setFileFormat();
        })).subscribe();
    }

    setFileFormat() {
        this.filteredFileOptions = this.fileOptions.filter((x: IFileOptions) => x.title.toLowerCase()
            .includes(this.bulkDownloadForm.controls.selectedFileType.value));

        if (this.filteredFileOptions.some(option => option.value === this.format)) {
            this.bulkDownloadForm.controls.selectedFileFormat.setValue(this.format);
        } else {
            this.bulkDownloadForm.controls.selectedFileFormat.setValue(this.filteredFileOptions[1]?.value || '');

        }
    }

    downloadClicked() {
        let fileType: string;
        if (this.bdForm.selectedFileType.value === 'files') {
            fileType = 'files';
        } else if (!this.bdForm.selectedFileFormat.value) {
            // incomplete form
            return;
        } else {
            fileType = this.bdForm.selectedFileFormat.value;
        }
        this.handleDownload.emit({
            fileType: fileType,
            fileConstantPropertyIDs: this.fileConstantProperties.map(cp => cp.id)
        });
    }
}
