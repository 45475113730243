import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Observable, of} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {first, map, takeUntil, tap} from "rxjs/operators";
import {SelectSearchApiService} from "../select-search-api/select-search-api.service";
import {BaseComponent} from '../../shared/base.component';
import {ListResponse} from '../../services/api/response-types';
import {ApiService} from '../../services/api/api.service';
import {deepCopy} from '../../lib/utils';
import {BaseModel} from '../../_typing/generic-types';
import {NameOrDescriptionPipe} from "../../shared/pipes";

@Component({
    selector: 'table-cell-relationship-list',
    templateUrl: './table-cell-relationship-list.component.html',
    styleUrls: ['./table-cell-relationship-list.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SelectSearchApiService],
    standalone: false
})
export class TableCellRelationshipListComponent extends BaseComponent {
    @Input() parent: BaseModel<any>;
    @Input() filter: any;
    @Input() model_api: string;
    @Input() mapFunction = (curr) => {
        if (!curr) return;
        return curr.map(item => NameOrDescriptionPipe.prototype.transform(item.attributes));
    }
    @Input() editFunction: () => void;

    list: any[];
    private options$: Observable<any[]>;

    constructor(public sapi: SelectSearchApiService,
                private api: ApiService,
                private changeDetector: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.options$ = this.sapi.connect(this.mapFunction);
        this.options$.pipe(
            tap(list => {
                this.list = list;
                this.changeDetector.markForCheck()
            })
        ).subscribe();
        this.getNextBatch();
    }

    getNextBatch() {
        this.sapi.getNextBatch(this.fetchData, this.filter);
    }

    fetchData = (page_number, filters = []): Observable<any> => {
        const ctrl = this;
        const options = new SearchQueryOptions(this.sapi.page_size, page_number, 'name');

        if (filters.length) {
            options.filters = filters;
        }
        return ctrl.api[this.model_api].searchMany(options).pipe(first(), takeUntil(this.onDestroy), map((response: ListResponse<any>) => {
            if (!response) return of([]);
            return response;
        }))

    }
}
