<div #custom_dialog_container>
  <ng-container *ngIf="config">

    <ng-container *ngIf="config.component==='SeriesAuditHistoryComponent'">
      <div style="width:300px;border:1px solid orange;">
        <series-audit-history [series]="config.parameters.series"></series-audit-history>
      </div>
    </ng-container>

    <ng-container *ngIf="config.component==='CommentComponent'">
      <textarea [innerText]="config.parameters || ''" (change)="updateText($event)" placeholder="Comment..."></textarea>
    </ng-container>

    <ng-container *ngIf="config.component==='SeriesSummaryComponent'">
      <div style="width:470px;border:1px solid orange;background:white;height:310px;border-radius:4px;">
        <series-summary-menu (paramsChanged)="updateSeriesSummary($event)"
                             [config]="config.parameters"></series-summary-menu>
      </div>
    </ng-container>

    <ng-container *ngIf="config.component==='ProcessQuickMenuComponent'">
      <process-quick-menu [process]="config.parameters.process" (onNav)="onCloseClick()"></process-quick-menu>
    </ng-container>

    <ng-container *ngIf="config.component==='series-context-menu'">
      <series-context-menu [selected_series]="config.parameters.selected_model" [seriesPermissionIds]="config.parameters.series_permission_ids" (emitRefreshRequest)="updateSelectedValue($event)"></series-context-menu>
    </ng-container>

    <ng-container *ngIf="config.component==='ColumnWidth'">
      <div class="column-width-input">
        <label>Column Width (px):</label>
        <input matInput (input)="updateSeriesSummary(config.parameters.width)"
               [(ngModel)]="config.parameters.width"
               [max]="1200" [min]="20" name="width"
               step="1" type="number"
        >
      </div>
    </ng-container>

    <ng-container *ngIf="config.component==='LimitsPrompt'">
      <div class="limits-prompt-container">
        <constant-limits-prompt [limits]="config.parameters.limits"
                [value]="config.parameters.value"
                [dialogRef]="dialogRef"
                [cpColumn]="config.parameters.cp_column"
                [component]="config.parameters.component"
        ></constant-limits-prompt>
      </div>
    </ng-container>
     <ng-container *ngIf="config.component==='CommentPrompt'">
      <div class="limits-prompt-container">
        <comment-prompt [promptMessage]="config.parameters.prompt_message"
                [commentAppend]="config.parameters.comment_append"
                [componentList]="config.parameters.component_list"
        ></comment-prompt>
      </div>
    </ng-container>

    <ng-container *ngIf="config.component==='InfiniteScroller'">
      <div style="background:transparent;">
        <select-search-component #componentSelect
                                 [custom_filters]="config.parameters.custom_filters"
                                 (selectionChange)="updateSelectedValue( $event)"
                                 (closeWithoutChange)="onCloseClick()"
                                 [openOnInit]="true"
                                 [api_model]="'component'"
                                 [compareFunctionName]="'compareByName'"
                                 [sort_by]="'-created_on'"
                                 [component_types]=config.parameters.component_types
                                 [value]="config.parameters.current_value"></select-search-component>
      </div>
    </ng-container>

    <ng-container *ngIf="config.component==='DateTimePicker'">
      <date-time-picker [input_date]="config.parameters.value" (date_changed)="updateSelectedValue($event, false)"
                        [openOnInit]="true">
      </date-time-picker>
    </ng-container>

    <ng-container *ngIf="config.component === 'ComponentEventsDownloadFormatMenu'">
      <component-events-download-format-menu
          [format]="config.parameters.format"
          [fileOptions]="config.parameters.file_options"
          [relationshipType]="config.parameters.relationship_type"
          [modelIDs]="config.parameters.model_ids"
          (handleDownload)="updateSelectedValue($event)">
      </component-events-download-format-menu>
    </ng-container>

    <ng-container *ngIf="config.component === 'AuditMixinComponent'">
      <audit-mixin [model]="config.parameters.model"></audit-mixin>
    </ng-container>

    <div (click)="onCloseClick()" *ngIf="config.show_close" class="action-buttons">
      <div class="btn-close">Close</div>
    </div>
  </ng-container>
</div>
