<div class="container" *ngIf="component_ready">
  <h2>
    <div>{{header}}</div>
    <div>
      <mat-select (selectionChange)="doFilter();" [(ngModel)]="search_field" multiple>
        <mat-option value="attributes.account_name">Account</mat-option>
        <mat-option value="attributes.name">Name</mat-option>
        <mat-option value="attributes.created_by_name">Owner</mat-option>
        <mat-option value="attributes.visibility">Visibility</mat-option>
      </mat-select>
      <input (input)="doFilter();" [(ngModel)]="filter" matInput placeholder="search">
    </div>
  </h2>
  <div class="box-header row">
    <div (click)="sortList(filtered_list, 'account_name', sort_direction);" class="account">Account</div>
    <div (click)="sortList(filtered_list, 'name', sort_direction);" class="name">Name</div>
    <div (click)="sortListByRelationship(filtered_list, user_dict, 'user', sort_direction);" class="owner">Owner</div>
    <div (click)="sortList(filtered_list, 'changed_on', sort_direction);" class="changed">Last edited</div>
    <div (click)="sortList(filtered_list, 'visibility', sort_direction);" class="visibility">Visibility</div>
    <div class="folder">Folder
    </div>
    <!--<div class="action">Delete</div>-->
  </div>
  <div
      cdkDropList
      #selectedList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListData]="filtered_list"
      class="list">
    <ng-container *ngFor="let item of filtered_list">
      <!--//TODO readd cdkDrag to div below when ready to do something with the ordered list-->
      <div *ngIf="allowUser([],true,false,item,[],[])" class="box row">
        <div class="account">{{item.attributes.account_name}}
        </div>
        <div class="name">
          <a *ngIf="allowUser([],true,false,item,['public','report','workflow'],[])"
             [routerLink]="item.attributes.url"
             [textContent]="item.attributes.name"
          ></a>
          <span
              *ngIf="!allowUser([],true,false,item,['public','report','workflow'],[])">{{item.attributes.name}} </span>
        </div>
        <div class="owner">{{user_dict[item.relationships.user.data?.id]?.attributes.name}}</div>
        <div class="changed">{{item.attributes.changed_on | stringDate : {date_only: true} }}</div>
        <div class="visibility">{{item.attributes.visibility | upperfirst}}</div>
        <div class="folder"><span *ngIf="folder_dict">
          <ng-container *ngFor="let folder of item.relationships.folders.data">
            {{folder_dict[folder.id]?.folder?.attributes.name | upperfirst}}<br
              *ngIf="item.relationships.folders.data.length > 1"/>
          </ng-container>
          </span></div>
        <!--<div class="action">-->
        <!--<i class="fa fa-times" (click)="doDelete(item)"-->
        <!--[hidden]="!allowUser(['Administrator'], true, false, item, [],['private'])"></i>-->
        <!--</div>-->
      </div>
    </ng-container>
  </div>
</div>
