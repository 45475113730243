<svg height="100%" width="100%" style="position:fixed;top:0;" *ngIf="show_grid"
     xmlns="http://www.w3.org/2000/svg">
  <defs>
    <pattern height="5" id="smallGrid" patternUnits="userSpaceOnUse" width="5">
      <path d="M 5 0 L 0 0 0 5" fill="none" stroke="gray" stroke-width="0.5"/>
    </pattern>
    <pattern height="50" id="grid" patternUnits="userSpaceOnUse" width="50">
      <rect fill="url(#smallGrid)" height="50" width="50"/>
      <path d="M 50 0 L 0 0 0 50" fill="none" stroke="gray" stroke-width="1"/>
    </pattern>
  </defs>

  <rect fill="url(#grid)" height="100%" width="100%"></rect>
</svg>

<div [class.editing-mode]="editing" [class.printing-mode]="printing" [class.printing-sections-mode]="printing_sections"
     [class.fixed-width-landscape]="printing && session.attributes.json.print_orientation==='landscape'"
     [class.fixed-width-portrait]="printing && session.attributes.json.print_orientation==='portrait'"
     [class.portrait-widescreen]="printing && session.attributes.json.print_orientation==='portrait-widescreen'"
     [class.widescreen-60]="fixed_row_height === 60"
     #page_sizer class="page traveler" [appLdsRoller]="loading_printview">
  <div *ngIf="editing" class="editing-button-holder hideonprint">
    <mat-slide-toggle (change)="toggleGridMode()" [checked]="show_grid"
                      class="form-element-align" labelPosition="before">Toggle grid
    </mat-slide-toggle>
    <mat-slide-toggle (change)="toggleLayoutMode()" [checked]="show_layout"
                      class="form-element-align" labelPosition="before">Toggle layout mode
    </mat-slide-toggle>
    <mat-checkbox (ngModelChange)="modelChanged($event, 'enableEmptyCellClick', false)"
                  [(ngModel)]="options.enableEmptyCellClick" class="form-element-align">
      Enable click in cell to add tile
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="modelChanged($event, 'swap', false)" [(ngModel)]="options.swap"
                  class="form-element-align">
      Enable swapping tiles
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="modelChanged($event, 'pushItems', false)" [(ngModel)]="options.pushItems"
                  class="form-element-align">
      Enable pushing tiles
    </mat-checkbox>
    <!--    <button (click)="fit()" class="add-button cols-2" mat-mini-fab>-->
    <!--      <mat-icon matTooltip="Fit to screen width">sync_alt</mat-icon>-->
    <!--    </button>-->
    <mat-form-field class="bordered-select">
      <mat-label>Compact Type</mat-label>
      <mat-select (ngModelChange)="modelChanged($event, 'compactType', true)" [(ngModel)]="options.compactType"
                  aria-label="Compact type"
                  placeholder="Compact Type">
        <mat-option value="none">None</mat-option>
        <mat-option value="compactUp">Compact Up</mat-option>
        <mat-option value="compactLeft">Compact Left</mat-option>
        <mat-option value="compactRight">Compact Right</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="row">
      <button (click)="json.grid_options.page_breaks={}" class="add-button form-element-align" mat-mini-fab color="warn"
              matTooltip="Clear all page breaks">
        <mat-icon>power_input</mat-icon>
      </button>
      <button (click)="addSection()" class="form-element-align add-button" mat-mini-fab color="warn"
              matTooltip="Add group for mobile layout">
        <mat-icon>border_inner</mat-icon>
      </button>
      <button (click)="addTile()" class="form-element-align add-button" mat-mini-fab color="warn" matTooltip="Add tile">
        <mat-icon>add</mat-icon>
      </button>
      <button (click)="save_sections(sections)" class="add-button form-element-align"
              mat-mini-fab color="warn" matTooltip="Save layout">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>

  <gridster #gridster_parent [options]="options" class="section-grid" id="gridster_parent" tabindex="1">
    <div *ngIf="editing" class="page-break-buttons" style="position:relative;">
      <ng-container *ngFor="let r of utils.counter(gridster_parent.rows + 1);let i=index">
        <div (click)="managePageBreak(i)" *ngIf="allowBreak(i) || json.grid_options.page_breaks?.[i]===true"
             [ngStyle]="{'top': (i*fixed_row_height)-10 + 'px'}" style="position:absolute;left:-16px;">
          <mat-icon
              [matTooltip]="json.grid_options.page_breaks && json.grid_options.page_breaks[i]===true? 'Remove page break' : 'Add page break'"
              [ngClass]="json.grid_options.page_breaks && json.grid_options.page_breaks[i]===true ?
           'remove-break' : 'insert-break'">power_input
          </mat-icon>
        </div>
      </ng-container>
    </div>
    <ng-container *ngFor="let section of dashboard; let i = index;">
      <gridster-item [id]=section.id [item]="section"
                     [ngClass]="{'hideonprint': section.hide_on_print, 'break-before': section.page_break, 'single-row':section.rows === 1}"
                     class="section-grid-item">
        <div *ngIf="editing && show_layout" class="section-button-holder">
          <button class="drag-handler" mat-mini-fab matTooltip="Drag section" color="primary">
            <mat-icon>open_with</mat-icon>
          </button>
          <button (mousedown)="openTileDialog(section)" class="add-button cols-2" mat-mini-fab
                  matTooltip="Add tile to mobile group" color="primary">
            <mat-icon>add</mat-icon>
          </button>
          <button (mousedown)="deleteSection(section)" *ngIf="section.tiles.length !== 1" class="drag-handler"
                  mat-mini-fab matTooltip="Delete section" color="primary">
            <mat-icon>delete</mat-icon>
          </button>
          <button (mousedown)="section.hide_on_print = !section.hide_on_print"
                  [matTooltip]="!section.hide_on_print? 'Click to hide tile when printing': 'Click to display tile when printing'"
                  *ngIf="section.cols === options.maxCols" class="drag-handler" mat-mini-fab color="primary">
            <mat-icon *ngIf="!section.hide_on_print">print</mat-icon>
            <mat-icon *ngIf="section.hide_on_print">print_disabled</mat-icon>
          </button>
          <button (click)="pasteTileToSection($event, section)" *ngIf="clipboard" mat-mini-fab
                  matTooltip="Paste tile" color="primary">
            <mat-icon>content_paste</mat-icon>
          </button>
        </div>

        <div *ngIf="section.tiles" class="gridster-item-content">
          <gridster [ngStyle]="{'background:#555555' : editing}"
                    [options]="subOptions" class="tile-grid">
            <ng-container *ngFor="let t of section.tiles;  let j = index">
              <gridster-item #tile_gridster_item
                             [id]="t.id" [item]="t"
                             [ngClass]="{'title-paragraph': t.is_title===true, 'hideonprint': t.hide_on_print,
                              'see-through': show_grid === true && editing===true}"
                             class="tile-grid-item">

                <div *ngIf="editing && show_layout" class="tile-button-holder">
                  <button *ngIf="section.tiles.length > 1" class="drag-handler" mat-mini-fab
                          matTooltip="Drag tile" color="primary">
                    <mat-icon>open_with</mat-icon>
                  </button>
                  <button (click)="deleteTile(section, t)" mat-mini-fab
                          matTooltip="Delete tile" color="primary">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button (click)="copyToClipboard($event, t)" class="cut-handler"
                          mat-mini-fab matTooltip="Cut tile from section" color="primary">
                    <mat-icon>content_cut</mat-icon>
                  </button>
                  <button
                      (click)="t.is_title =!t.is_title"
                      [matTooltip]="'This is a title tile (paragraph tiles only)'"
                      class="drag-handler" mat-mini-fab color="primary">
                    <mat-icon *ngIf="!t.is_title">title</mat-icon>
                    <mat-icon *ngIf="t.is_title">format_clear</mat-icon>
                  </button>
                  <button
                      (click)="t.hide_on_print =!t.hide_on_print"
                      *ngIf="section.tiles.length > 1"
                      [matTooltip]="t.hide_on_print? 'Click to hide tile when printing': 'Click to display tile when printing'"
                      class="drag-handler" mat-mini-fab color="primary">
                    <mat-icon *ngIf="!t.hide_on_print">print</mat-icon>
                    <mat-icon *ngIf="t.hide_on_print">print_disabled</mat-icon>
                  </button>
                  <div style="color:white">Tile y:{{section.y}}, x:{{section.x}}</div>
                  <div class="tile-dimensions">{{section.rows}} x {{section.cols}}</div>
                </div>
                <page-tile *ngIf="t.id !==refreshId"
                           (delete)="removeTile(section, t.id)"
                           (removeMissingTile)="removeTile(section, t.id)"
                           (tileChange)="save_tile($event)"
                           [dtp]="dateInst.dtp"
                           [ngClass]="{'tile-dtp': tile_dict[t.id]?.attributes?.parameters?.show_dtp,
                           'no-format': tile_dict[t.id]?.attributes.show_header===false,
                           'see-through': show_grid === true}"
                           [tile_id]="header_tiles_dict[t.id]?null:t.id"
                           [tile]="header_tiles_dict[t.id]?tile_dict[t.id]:null"
                           [current_session_id]="session.id"
                           [grid_options]="t">
                </page-tile>
              </gridster-item>
            </ng-container>
          </gridster>
        </div>

      </gridster-item>
    </ng-container>
  </gridster>

  <ng-container *ngIf="isEmpty() === true && !editing">
    <div class="empty-dashboard">
      <h1>This page is a blank canvas for you to build out.</h1>

      <p>
        <b>Try adding graphs or charts, paragraph tiles, log sheets, series tables or flowcharts to help you better
          understand your operations.</b>
      </p>
      <p>
        A <b>graph</b> is a <b>diagram</b> of a mathematical function.<br/>
        A <b>chart</b> is a graphic representation of data, where a line <b>chart</b> is one form.
      </p>
      <p>
        For further assistance visit our help centre or email
        <a href="mailto:{{serviceDeskEmail}}" target="_blank">{{serviceDeskEmail}}</a>
        for
        guidance.
      </p>
    </div>
  </ng-container>

  <div class="page-view-edit-spacer"></div>
</div>

<router-outlet name="component_panel"></router-outlet>

<div class="confirm-print-box" [ngClass]="{'confirm-print': print_ready}">
  <h2>Document ready</h2>
  Your print view is ready. Once you are happy that the dashboard has finished resizing, please click Print.
  <div class="row button-row">
    <wire-button (buttonClicked)="onAfterPrint($event)" [btnContent]="'Cancel'" btnStyle="btn-cancel"></wire-button>
    <wire-button (buttonClicked)="print()" [btnContent]="'Print'" btnStyle="btn-default"></wire-button>
  </div>
</div>
