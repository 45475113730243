<ng-container>
  <h3>Source Series for {{current_series.attributes | nameOrDescription}}</h3>
  <div class="row center min-height">
      <select-many-series [label]="''"
                          [value]="source_data"
                          [compareFunctionName]="'compareById'"
                          [classes]="'fill'"
                          class="select-search select-many-search-wrapper"
                          (selectionChange)="source_data=$event"
                          [disabled]="!current_series.relationships.series_type.data?.id"
                          [matTooltip]="current_series.relationships.series_type.data?.id? 'Select one or more sources for this series.':
                           'Only series with a series type already set can have Source series.'">
      </select-many-series>
    <button mat-raised-button color="primary" class="form-element-button add-to-table"
            [disabled]="!(can_edit && source_data?.length && current_series.relationships.series_type.data?.id)"
            (click)="saveSourceSeries()"
            matTooltip="Save the selected series as Source series for {{current_series.attributes.name}}">
      <i class="fa fa-arrow-down" style="margin-right:10px;"></i>Save selected sources
    </button>
  </div>

  <div class="row end space-between medium-height">
    <button mat-raised-button color="primary" class="form-element-button"
            [disabled]="!(can_edit && current_series.relationships.series_type.data?.id)"
            (click)="newSourceSeries()"
            matTooltip="Save the selected series as Source series for {{current_series.attributes.name}}">
      <i class="fa fa-plus" style="margin-right:10px;"></i>Add new source series
    </button>
    <button mat-raised-button color="primary" class="form-element-button" (click)="refresh()">
      <i class="fa fa-refresh" matTooltip="Refresh table"></i>
    </button>
  </div>
  <series-series-table [series_data]="source_data"
                       [series_series]="source_series_series"
                       [series]="current_series"
                       [type]="'sources'"></series-series-table>
</ng-container>


<ng-container>
  <h3>Target Series for {{current_series.attributes | nameOrDescription}}</h3>
  <div class="row center min-height">
      <select-many-series [label]="''"
                          [value]="target_data"
                          [custom_filters]="target_series_filter"
                          [compareFunctionName]="'compareById'"
                          [classes]="'fill'"
                          class="select-search select-many-search-wrapper"
                          (selectionChange)="target_data=$event">
      </select-many-series>
    <button mat-raised-button color="primary" class="form-element-button add-to-table"
            [disabled]="!can_edit || !target_data?.length"
            (click)="saveTargetSeries()"
            matTooltip="Save the selected series as Target series for {{current_series.attributes.name}}">
      <i class="fa fa-arrow-down" style="margin-right:10px;"></i>Save selected targets
    </button>
  </div>
  <div class="row end space-between medium-height">
    <div class="row end medium-height">
      <button mat-raised-button color="primary" class="form-element-button"
              [disabled]="!can_edit"
              (click)="newTargetSeries()"
              matTooltip="Add a target series against which to compare the current series">
        <i class="fa fa-plus" style="margin-right:10px;"></i>Add new target series
      </button>
      <div style="margin:0 10px;">of type</div>
      <select-filter class="select-filter-inline"
                     (constant_changed)="setSeriesType($event)"
                     [options]="series_type_names"
                     [modelValue]="selected_series_type.attributes.name">
      </select-filter>
    </div>
    <button mat-raised-button color="primary" class="form-element-button refresh-button" (click)="refresh()">
      <i class="fa fa-refresh" matTooltip="Refresh table"></i>
    </button>
  </div>
  <series-series-table [series_data]="target_data"
                       [series_series]="target_series_series"
                       [series]="current_series"
                       [type]="'targets'"></series-series-table>
</ng-container>
