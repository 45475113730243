import {Component, Inject, Renderer2} from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {MatomoTracker} from "ngx-matomo-client";
import {AccountService} from "../../services/account.service";
import { takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {Menu} from "../../menu-bars/header.component";

@Component({
    selector: 'main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.less'],
    standalone: false
})
export class MainHeaderComponent {
  private readonly onDestroy: Subject<void> = new Subject<void>();
  loginImgSrc: string = '/static/images/wire-logo-lightgrey-login.png';
  rightLogoSrc: string = '/admin/myfiles/download/right-logo.png';
  right_menu: Menu = {};

  constructor(public headerData: HeaderDataService,
              public appScope: AppScope,
              private renderer: Renderer2,
              @Inject('Window') private window: Window,
              private matomoTracker: MatomoTracker,
              public accountService: AccountService) {
  }

  ngOnInit(): void {
      this.appScope.authComplete$.subscribe((isAuth) => {
          if (isAuth) {
              this.appScope.currentUserValue.pipe(
                  tap(() => {
                      this.setup_matomo_analytics(this.window);
                      if (this.appScope.config_name_map.hasOwnProperty("theme")) {
                          let theme = this.appScope.config_name_map.theme.value.name;
                          this.renderer.addClass(document.body, theme);
                      }
                      this.right_menu = this.headerData.buildRightMenu();
                  }),
                  takeUntil(this.onDestroy)).subscribe();

              this.accountService.activeAccountChanged.pipe(takeUntil(this.onDestroy))
                  .subscribe(response => {
                      this.appScope.refreshConfigurationSettings(response.account_id);
                      this.right_menu = this.headerData.buildRightMenu();
                  });
          }});
  }

  private setup_matomo_analytics(window: Window) {
    try {
        this.matomoTracker.setUserId(this.appScope.current_user.id);
        this.matomoTracker.setDocumentTitle('ngxMatomo appComponent');
    } catch (e) {
        console.error('Had exception with setting up analytics');
    }
  }

  ngOnDestroy(): void {
      this.onDestroy.next();
      this.onDestroy.unsubscribe();
  }

}
