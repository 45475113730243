import {Injectable, OnDestroy} from '@angular/core';
import {PaginationDataSource} from "../../../services/api/pagination-data-source";
import {Group} from "../../../_models/group";
import {SearchQueryOptions} from "../../../services/api/search-query-options";
import { takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../../../services/api/response-types";
import {Observable, ReplaySubject} from "rxjs";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {ApiService} from '../../../services/api/api.service';
import {GroupDataService} from "../../../data/group-data.service";
import {AppScope} from '../../../services/app_scope.service';
import {BasePaginatedTableService} from "../../../tables/base-paginated-table.service";
import {GenericDataService} from "../../../data/generic-data.service";
import {NotificationService} from "../../../services/notification.service";

@Injectable({
    providedIn: 'root'
})
export class GroupListService extends BasePaginatedTableService<Group> implements OnDestroy {

    page_size = 10;
    sort: MatSort;
    paginator: MatPaginator;
    dataSource: PaginationDataSource<Group>;
    can_edit: boolean;
    columns = ['name', 'description', 'users', 'pages', 'created_on', 'created_by_name', 'changed_on', 'changed_by_name'];

    public readonly groupDataSourceSubject: ReplaySubject<PaginationDataSource<Group>> = new ReplaySubject<PaginationDataSource<Group>>(1);

    constructor(private groupDataService: GroupDataService,
                private appScope: AppScope,
                api: ApiService,
                notification: NotificationService,
                genericData: GenericDataService) {
        super(genericData, api, notification);
        this.checkPermissions();
    }

    initialise() {
        const initialQuery: SearchQueryOptions = this.getInitialSearchOptions();
        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<Group>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            tap((result: ListResponse<Group>) => {
                this.groupDataSourceSubject.next(this.dataSource)
            }),
            takeUntil(this.onDestroy)).subscribe();
    }

    checkPermissions() {
        const cur_user = this.appScope.current_user;
        this.can_edit = (cur_user.feature_names.indexOf("edit group") > -1
            || cur_user.role_names.indexOf('Administrator') > -1
            || cur_user.is_super);
        if (this.can_edit) {
            this.columns.unshift('edit');
        }
    }

    getInitialSearchOptions(): SearchQueryOptions {
        const options = new SearchQueryOptions(this.page_size, 1, 'name');
        options.filters = [];
        return options;
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return super.page(query, 'group');
    }

}
