import {Component, Input} from '@angular/core';
import {model_selection_options, ModelSelectionUploadOption} from './upload-model-form-dialog.component';
import {UploadModelTemplateService} from "./upload-model-template.service";
import {BaseComponent} from '../../shared/base.component';
import {ParserConfigTemplate} from "../../_models/parser-config-template";
import {AppScope} from '../../services/app_scope.service';
import {ConfigStub} from '../../_typing/config-stub';
import {compareById} from '../../lib/utils';

export interface UploadModelTileConfig {
    model_selected: ModelSelectionUploadOption;
    selected_templates: ConfigStub<ParserConfigTemplate>[];
    default_template: ConfigStub<ParserConfigTemplate>;
}

@Component({
    selector: 'upload-model-form',
    templateUrl: './upload-model-form.component.html',
    styleUrls: ['./upload-model-form.component.less'],
    providers: [UploadModelTemplateService],
    standalone: false
})
export class UploadModelFormComponent extends BaseComponent {
    @Input() config: UploadModelTileConfig;

    model_selection_options = model_selection_options.filter(ms => ['component', 'event'].includes(ms.parser_type));
    template_filter = [];
    compareById = compareById;

    constructor(private templateService: UploadModelTemplateService,
                private appScope: AppScope) {
        super()
    }

    ngOnInit(): void {
        if (this.config.model_selected) {
            this.changedSelectedModel();
        }
    }

    resetConfig() {
        this.config.selected_templates = [];
        this.config.default_template = null;
    }

    changedSelectedModel() {
        this.template_filter = this.templateService.refreshTemplateQueryFilters(
            this.config.model_selected.parser_type,
            this.appScope.active_account_id);
    }

    templatesSelected($event) {
        this.config.selected_templates = $event.map(t => new ConfigStub(t))
    }

    defaultTemplateSelected($event) {
        this.config.default_template = new ConfigStub($event)
    }

    compareParserType(option, value) {
        return option?.name === value?.name;
    }
}
