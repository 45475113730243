<ng-container *ngIf="processes && processes.length>=0">
  <select-many-search [value]="new_processes"
                      [numberSelected]="new_processes?.length || 0"
                      (selectChange)="onProcessesChanged($event)"
                      [data]="processes"
                      [label]="feature.attributes.name | removeUnderscore | titlecase"
                      [classes]="'fill'"
                      class="select-search">
  </select-many-search>
</ng-container>
