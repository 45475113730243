import {Component, ViewEncapsulation} from "@angular/core";
import {SelectManySearchBaseComponent} from "../select-many-search-base.component";
import {ApiService} from "../../../../services/api/api.service";
import {AppScope} from "../../../../services/app_scope.service";

@Component({
    selector: 'select-many-pages',
    templateUrl: '../select-many-search-base.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SelectManyPagesComponent extends SelectManySearchBaseComponent {

    constructor(api: ApiService,
                appScope: AppScope) {
        super(api,appScope);
        this.api_model = 'session_state_light';
        this.label = 'Select Pages';
        this.classes = "'fill'";
    }
}
