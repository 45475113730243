<div class="form-dialog">
  <h1><span>{{form_title}}</span>
  </h1>
  <mat-dialog-content class="lock-template-details">
    <mat-tab-group [disableRipple]="true" #lock_template_tabs
                   animationDuration="0ms"
                   (selectedIndexChange)="tabChanged($event)">
      <mat-tab label="Lock template details" *ngIf="selected_lock_template">
        <div class="row auto info" >
          Please select the constant properties and attributes associated with one component type or one event type
          that you would like
          to form part of the lock template. This template can then be used by WIRE to identify and lock the associate
          data per user request.
        </div>
        <div class="row">

          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="selected_lock_template.attributes.name" [disabled]="disabled"
                   style="box-sizing: border-box;">
          </mat-form-field>


          <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput [(ngModel)]="selected_lock_template.attributes.description" [disabled]="disabled"
                   style="box-sizing: border-box;">
          </mat-form-field>
        </div>

        <div class="row">
          <select-search-component [api_model]="'event_type'"
                                   [disabled]="selected_lock_template.id || disabled"
                                   [value]="selected_lock_template.relationships.event_type.data?.id || undefined"
                                   [compareFunctionName]="'compareValueToId'"
                                   (selectionChange)="templateTypeChanged($event.value)"
                                   [label]="'Select event type'"
                                   [classes]="'fill'"
                                   class="select-search">
          </select-search-component>

          <div style="margin-top:30px;font-weight: bold;">OR</div>

          <select-search-component [api_model]="'component_type'"
                                   [disabled]="selected_lock_template.id || disabled"
                                   [value]="selected_lock_template.relationships.component_type.data?.id || undefined"
                                   [compareFunctionName]="'compareValueToId'"
                                   (selectionChange)="templateTypeChanged($event.value)"
                                   [label]="'Select component type'"
                                   [classes]="'fill'"
                                   class="select-search">
          </select-search-component>
        </div>
        <div class="row auto info">
          Please note that altering the selected attributes and constant properties of an existing Lock Template
          (Component Type or Event Type) will update the definition of the lock template.
          This will cause a new lock template version to be created, and will lock all appropriate properties and data
          of all new events and or components for each new lock request.
          Please note that this will not retrospectively lock events or components data according to the new
          template. Please lodge a service request for more help or information.
        </div>
        <div class="row">
          <mat-form-field class="flex-half">
            <mat-label>Select attributes</mat-label>
            <mat-select (selectionChange)="attributesChanged($event)" multiple
                        placeholder="(+{{attributes?.length || 0}} selected)"
                        [ngModel]="attributes"
                        [disabled]="!(selected_lock_template.relationships.event_type.data || selected_lock_template.relationships.component_type.data)">
              <mat-option
                  *ngFor="let attribute of (selected_lock_template.relationships.event_type.data?.id? event_attribute_options : component_attribute_options)"
                  [value]="attribute" [disabled]="disabled">{{attribute|capitalise|removeUnderscore}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <select-many-constant-properties
              [disabled]="!(selected_lock_template.relationships.event_type.data || selected_lock_template.relationships.component_type.data)"
              [value]="property_ids"
              [disable_items]="disabled"
              [custom_filters]="property_filter"
              (selectionChange)="constantPropertiesChanged($event)"
              [matTooltipDisabled]="'Please select an event_type or component_type'"
              [classes]="'fill'"
                                     class="select-many-search-wrapper flex-half">
          </select-many-constant-properties>

        </div>
      </mat-tab>

      <mat-tab label="Lock template versions" *ngIf="selected_lock_template?.id">
        <ng-container *ngIf="version_tab_loaded">
          <div style="margin-bottom:30px;">
            <lock-template-version-table *ngIf="refresh_id"
                                         [lock_template]="selected_lock_template"
                                         [lock_templates]="lock_templates"
                                         [lock_template_versions]="lock_template_versions"
                                         [lock_ids]="lock_ids"
                                         [component_name]="component?.attributes.name"
                                         (selectedVersionChanged)="selected_version=$event;"
                                         (selectedTemplateChanged)="selected_lock_template=$event;"
                                         (relayUnlockTemplate)="unlockTemplate($event)"
                                         style="width:100%;">
            </lock-template-version-table>
          </div>

          <h3 class="row center" style="justify-content: space-between;">
            <div>Template Version Dependency Tree
              <span *ngIf="component"> for component <b>{{component.attributes.name}}</b></span>
              <span *ngIf="event"> for event</span></div>
          </h3>
          <div style="width:100%;background-color:white;">
            <lock-template-dependency-tree [template_version]="selected_version"
                                           [selected_constant_property]="constant_property"></lock-template-dependency-tree>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save">Save</button>
  </div>
</div>
