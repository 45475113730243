import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";

export type PARSER_CONFIG_TYPES = 'orebody' | 'event' | 'component';

export class ParserConfigTemplateAttributes extends AuditMixinAttributes {
    name: string;
    json: any;
    type_name: PARSER_CONFIG_TYPES;
}

export class ParserConfigTemplateRelationships extends AuditMixinRelationships {
}

export class ParserConfigTemplate extends AuditMixin {
    attributes: ParserConfigTemplateAttributes;
    id: string;
    relationships: ParserConfigTemplateRelationships;
    type: string = 'parser_config_template';
}
