<div>

  <div class="row">
    <select-search-component class="select-search flex-third"
                             (selectionChange)="processChange($event.value)"
                             [api_model]="'process_light'"
                             [value]="config.process"
                             [label]="'Select a process'"
                             [classes]="'fill'"
    >
    </select-search-component>
    <select-search-component
        [label]="'Shift type'"
        [api_model]="'shift_type'"
        (selectionChange)="config.shift_type_id =$event?.value?.id"
        [value]="config.shift_type_id" class="select-search flex-third"
        [compareFunctionName]="'compareValueToId'"
        [allow_none]="true"
        [classes]="'fill'"
    >
    </select-search-component>
    <div class="row flex-third">
    <mat-form-field>
      <mat-label>Hidden Columns</mat-label>
      <mat-select [(value)]="config.hidden_columns" multiple>
        <mat-option value="name">Name</mat-option>
        <mat-option value="alias">Alias</mat-option>
        <mat-option value="description">Description</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="logsheet_columns && logsheet_columns.length>0">
      <mat-form-field>
        <mat-label>Extra Columns</mat-label>
        <mat-select [(value)]="config.columns"
                    multiple>
          <mat-option *ngFor="let column of logsheet_columns" [value]="column">
            {{column| upperfirst}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>

</div>
