import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {ModelID} from '../_typing/generic-types';
import {SeriesSeries} from './series-series';


export class SeriesAttributes extends AuditMixinAttributes {
    accumulation?: any;
    aggregation?: any;
    alias?: string;
    allow_edit?: any;
    alternate_names?: any;
    assumptions?: string;
    base_type?: 'series';
    budget?: any;
    collector_names?: any;
    default_chart?: any;
    default_value?: any;
    delete_hihilowlow?: any;
    description: string;
    dependencies?: ModelID[]; // ids of series used in a calculation
    event_type_name?: string;
    target_names?: string;
    extra_arguments_string?: string;
    fill_method?: any;
    hi?: any;
    hihi?: any;
    is_calculation?: boolean;
    json?: any;
    kpi_level?: string;
    linked_components?: any;
    low?: any;
    lowlow?: any;
    mean?: any;
    name: string;
    name_formula?: any;
    positive_variance?: boolean;
    rolling_average_hours?: number;
    sample_offset?: any;
    sample_period?: any;
    series_type_name?: string;
    specialised_function?: any;
    std?: any;
    /**
     * Pseudo fields added by plant_data service for flowcharts [legacy].
     */
    parent_name?: string;
    report_group?: any;
    series_order?: any;
    can_edit?: any;
    latest_value?: any;
    value?: number | null;
    status?: string;
    decimal_places?: number;

    /**Hybrid properties**/
    engineering_unit_name?: string;
    decimals?: number | null;
}

export class SeriesRelationships extends AuditMixinRelationships {
    calculations?: any;
    constant_property_event_type?: SINGLE_RELATIONSHIP_TYPE;
    component_constant_collation_series_export?: SINGLE_RELATIONSHIP_TYPE;
    engineering_unit?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    estimate_type?: SINGLE_RELATIONSHIP_TYPE;
    estimates?: LIST_RELATIONSHIP_TYPE;
    event_type: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    event_constant_collation_series_export?: SINGLE_RELATIONSHIP_TYPE;
    mappers?: any;
    source_series?: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    series_components?: LIST_RELATIONSHIP_TYPE;
    series_type?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    series_users?: LIST_RELATIONSHIP_TYPE;
    target_series?: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    weighted_average_series?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;

}

export class Series extends AuditMixin {
    attributes: SeriesAttributes;
    id: string;
    type: string = 'series';
    relationships: SeriesRelationships;

    constructor() {
        super();
        this.attributes = new SeriesAttributes();
        this.attributes.is_calculation = false;
        this.type = 'series';
        this.relationships = new SeriesRelationships();
    }
}

export class SeriesLightAttributes {
    name: string;
    description?: string;
    base_type?: 'series';
    kpi_level?: string;
    account_name?: string;
}

export class SeriesLight {
    attributes: SeriesAttributes;
    id: string;
    type: string = 'series';
}

export type  SeriesSeriesData = Partial<Series> & {
    current_value: number;
    positive_variance: boolean;
}

export type SeriesSeriesTableData = {
    series: SeriesSeriesData[];
    series_series: SeriesSeries[];
}
