import {BaseViewModel} from "./base-viewmodel";
import {ProcessViewModel} from "./process-viewmodel";
import {NameOrDescriptionPipe} from "../../../shared/pipes";

export class ContextViewModel extends BaseViewModel {
    parent_component: ProcessViewModel;
    index: number = 0;
    //title: string;
    config: any;

    constructor(contextData, process, index) {
        super(contextData)
        //TODO remove one
        this.parent_component = process;
        this.chart_parent_process = process;
        //TODO fix this, 'context' should be .data.type not .type, for consistency with other view models,
        //could be an issue with interfering with the model?
        this.data.type = 'context';
        this.type = 'context';
        this.index = index;
        //this.title = this.data.title;
        this.config = this.data.config;

        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }
    }

    title() {
        if (!this.data.title) {
            return new NameOrDescriptionPipe().transform(this.config.selected_series.attributes);
        }
        return this.data.title;
    }

    do_refresh() {
        return this.data.refresh;
    }

    height() {
        if (this.data.config.value_only === true) {
            return 100;
        } else {
            return 160;
        }
    };

    width() {
        if (this.data.config.value_only === true) {
            return 200;
        } else {
            return 260;
        }
    };

    x() {
        return this.data.x;
    };

    y() {
        return this.data.y;
    };

}
