import {Injectable} from '@angular/core';
import {GenerateTableUploadResponse, ModelUploadOptions} from "./upload-model-form-dialog.component";
import {PARSER_CONFIG_TYPES, ParserConfigTemplate} from "../../_models/parser-config-template";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {ApiService} from "../../services/api/api.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ModelID} from '../../_typing/generic-types';
import {BaseFlaskFilter, getRelationWithIdFilter} from '../../services/api/filter_utils';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
    GenerateTableParseResponseDialogComponent
} from "./response-display/generate-table-parse-response-dialog/generate-table-parse-response-dialog.component";
import { HttpClient, HttpStatusCode } from "@angular/common/http";
import {catchError, finalize, first, map} from 'rxjs/operators';
import {SingleResponse} from '../../services/api/response-types';
import {IUnmatchedHeadersType} from "../../_typing/headers";
import {NotificationService} from "../../services/notification.service";
import {OptionalSnackbarParams} from "../../_typing/notification";
import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * TODO implement this service to be responsible for emitting template selection to child components and for
 *  receiving updated options or null (depending if valid or not)
 */


@Injectable({
    providedIn: 'root'
})

export class UploadModelTemplateService {
    // For when an existing template has been selected.
    public templateChanged$: Subject<ParserConfigTemplate> = new ReplaySubject<ParserConfigTemplate>(1);
    // For when then WIP options are changed by form updates. This will either be valid or null.
    public stateChanged$: Subject<ModelUploadOptions> = new Subject<ModelUploadOptions>();
    // For when generating header associations is run and some items do not have a match between template and file
    public unmatchedHeadersFound$: Subject<IUnmatchedHeadersType[]> = new Subject();

    public property_filter: [BaseFlaskFilter];
    // public templates$ = new BehaviorSubject<ParserConfigTemplate[]>([]);
    public parserType: PARSER_CONFIG_TYPES;
    public uploadComplete$: Subject<any> = new Subject();
    error_json: any = null;
    update_only: boolean = true;

    constructor(private api: ApiService,
                public dialog: MatDialog, private snackbar: MatSnackBar,
                private notification: NotificationService, private http: HttpClient) {
    }

    getTemplateById(template_id: ModelID): Observable<SingleResponse<ParserConfigTemplate>> {
        return this.api.parser_config_template.getById(template_id).pipe(first());
    }

    selectTemplate(parser_template: ParserConfigTemplate) {
        this.updatePropertyFilter(parser_template);
        this.update_only = parser_template.attributes.json.update_only || false;
        this.templateChanged$.next(parser_template);
    }

    loadTemplateForType(parserType: PARSER_CONFIG_TYPES) {
        //  Emit the selected template to relevant child components
        this.parserType = parserType;
        this.refreshTemplateList();
    }

    refreshTemplateQueryFilters(parserType: PARSER_CONFIG_TYPES, account_id?: ModelID): any[] {
        const options = new SearchQueryOptions();
        let filters: any[] = [{
            name: 'type_name',
            op: 'eq',
            val: parserType
        }];
        if (account_id) {
            filters.push(getRelationWithIdFilter('account', account_id));
        }
        return filters;
    }

    refreshTemplateList() {
        this.refreshTemplateQueryFilters(this.parserType);
    }

    reportUnmatchedHeaders(unmatched: IUnmatchedHeadersType[]): void {
        this.unmatchedHeadersFound$.next(unmatched);
    }

    updatePropertyFilter(parser_template: ParserConfigTemplate) {
        const selected_cp_ids = parser_template.attributes.json.property_headers.map(ph => ph[1]).filter(h => !!h);
        this.property_filter = selected_cp_ids?.length > 0 ? [{name: 'id', op: 'in', val: selected_cp_ids}] : null;
    }

    updateWIPTemplate(options: ModelUploadOptions) {
        this.stateChanged$.next(options);
    }

    add(template: ParserConfigTemplate): Observable<any> {
        template.attributes.type_name = this.parserType;
        delete template.id;
        return this.api.parser_config_template.obsSave(template);
    }

    update(template: ParserConfigTemplate): Observable<any> {
        template.attributes.type_name = this.parserType;
        return this.api.parser_config_template.obsPatch(template);
    }

    delete(template: ParserConfigTemplate): Observable<void> {
        return this.api.parser_config_template.obsDelete(template.id);
    }

    uploadData(parser_type: string, formData: FormData) {
        this.http.post('/api/utils/upload_model', formData).pipe(map((response: any): OptionalSnackbarParams => {
            if (Array.isArray(response.errors)) {
                throw new Error(response.errors.join(''));
                // this.cf.openSnackbar(response.errors.join(''), 'Hide');
            } else {
                if (parser_type === 'event' || parser_type === 'component') {
                    const data: GenerateTableUploadResponse = response as GenerateTableUploadResponse;
                    if (data.parse_response.parsed_count.warning > 0 || data.parse_response.parsed_count.error > 0) {
                        const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
                        dialogConfig.data = {parse_response: data.parse_response};
                        dialogConfig.panelClass = ['default-form-dialog', 'model-upload-response-dialog'];
                        this.dialog.open(GenerateTableParseResponseDialogComponent, dialogConfig);
                    } else {
                        return {message: data.message, duration: 5000};
                    }
                } else {
                    return {message: 'File collected successfully.', duration: 5000};
                }
            }
        }), catchError((error): Observable<OptionalSnackbarParams> => {
            if (error.status === HttpStatusCode.BadRequest) {
                let data = error.error;
                if (data.hasOwnProperty('error')) {
                    data = data.error;
                }
                this.error_json = JSON.stringify(data, undefined, 2);
                throw new Error('Please see the Errors section at the top of this form.');
            } else {
                console.error('Error uploading collector data\n', error);
                throw new Error('An error occurred while collecting the file.');
            }
        }), finalize(() => {
            this.uploadComplete$.next(this.error_json);
        })).subscribe({
            next: (response: OptionalSnackbarParams) => {
                this.notification.openSuccess(response.message, response.duration);
            }, error: (err) => {
                this.notification.openError(err);
            }
        });
    }

    showUnmatched(unmatched: IUnmatchedHeadersType[]) {
        if (unmatched.length < 1) {
            return;
        }
        let message = 'The following items could not be matched and will be ignored:</div><br/><br/>';
        unmatched.forEach(u => {
            message += '<div class="font-normal">' + u.name + ' (' + u.type + ') </div>';
        });
        message += '<br/><div class="font-normal" style="font-size:1.2rem;">Please check for spelling mistakes in the column heading name or contact ' +
            '<a href="mailto:servicedesk@metalmanagementsolutions.com">servicedesk@metalmanagementsolutions.com</a> for ' +
            'further support to ensure a complete upload will be possible.</div>';
        this.notification.openError(message);
    }
}
