import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as utils from "../../lib/utils";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {JsonPipe} from "@angular/common";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {AppScope} from '../../services/app_scope.service';
import {FolderEntry, MenuTreeService, OptionalFolderEntry, TreeEntry} from "../../services/menu-tree.service";
import {ApiService} from '../../services/api/api.service';
import {combineLatest, forkJoin, Observable, of, Subject} from "rxjs";
import {catchError, concatMap, first, map, take, takeUntil, tap} from "rxjs/operators";
import {AccountService} from "../../services/account.service";
import {UserPreference} from "../../_models/user-preference";
import {SessionState} from '../../_models/session-state';
import {UserPreferenceDataService} from "../../data/user-preference-data.service";
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {Group} from '../../_models/group';
import {SingleResponse} from '../../services/api/response-types';
import {PageDataService} from "../../data/page-data.service";
import {NotificationService} from "../../services/notification.service";
import {OptionalCalendar} from "../../_typing/date-time-period";
import {ModelID} from "../../_typing/generic-types";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {ConfirmSnackbarComponent} from "../../notifications/snackbar/confirm-snackbar/confirm-snackbar.component";
import {FormDialogService} from '../../services/form-dialog.service';
import {UserService} from "../../services/user.service";
import {deepCopy} from "../../lib/utils";

@Component({
    selector: 'page-view-form',
    templateUrl: 'page-view-form.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class PageViewFormComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();

    show: boolean;
    json: any;
    json_string: string;
    title: string;
    showing_hints: boolean = false;
    hint: string = 'Name';
    is_editing: boolean;
    is_default_dashboard: boolean = false;
    user_preference: UserPreference;

    public ranges: any[];
    public calendars: OptionalCalendar[];
    public layouts: string[] = ['tile'];
    // visibility_options: string[];
    samplePeriods: any[];
    json_ready: boolean = false;
    json_stringify_error: boolean = null;
    filteredFolderOptions: OptionalFolderEntry[] = [];
    private folder_options: OptionalFolderEntry[] = [];
    default_folder_options: OptionalFolderEntry[] = [];
    session_id: any;
    groups: Group[];
    disableActionButtons: boolean = false;

    constructor(public dialogRef: MatDialogRef<PageViewFormComponent>,
                @Inject(MAT_DIALOG_DATA) public data: SessionState,
                private headerData: HeaderDataService,
                public dateTimeService: DateTimePeriodService,
                public appScope: AppScope,
                private menuTreeService: MenuTreeService,
                private accountService: AccountService,
                private api: ApiService,
                private notification: NotificationService,
                private userPreferenceData: UserPreferenceDataService,
                private relMapping: RelationshipApiMappingService,
                private pageDataService: PageDataService,
                private formDialogService: FormDialogService,
                public userService: UserService) {
    }

    ngOnInit(): void {
        this.json = utils.deepCopy(this.data.attributes);
        this.session_id = this.data.id;
        this.show = false;
        this.title = this.headerData.title;
        this.dateTimeService.dtpInitialisedPromise.promise.then(() => {
            this.ranges = this.dateTimeService.ranges.map(r => r.range);
            this.calendars = this.dateTimeService.calendars;
            this.samplePeriods = this.dateTimeService.sample_periods.map(sp => sp.name);
            this.setupDefaultDashboard();
        });
        this.json_ready = true;

        this.folder_options = [];
        const folders_source = this.menuTreeService.menuTreeChangedFull;
        const active_account_source = this.accountService.activeAccountChanged;

        combineLatest([folders_source, active_account_source])
            .pipe(
                takeUntil(this.onDestroy),
                map(([folders, active_account]) => {
                    return ({folders, active_account});
                }))
            .subscribe(response => {
                const folders: Record<string, TreeEntry[]> = response.folders;
                const active_account = response.active_account;
                this.flatten(folders[active_account.account_id], this);
                if (!(this.data.relationships.folders.data && this.data.relationships.folders.data.length > 0
                    && this.folder_options.length > 0)) {
                    this.data.relationships.folders.data = [{id: this.folder_options[0].folder.id, type: "folder"}];
                    this.data.relationships.default_folder.data = {
                        id: this.folder_options[0].folder.id,
                        type: "folder"
                    };
                }
                this.resetFolderOptions();
                this.getDefaultFolderOptions();
            });
    }

    groupsSelected($event) {
        /**Only for new session_states. Updates can be done via the 'Share' dialog**/
        this.groups = $event;
    }

    /**
     * Flattens the folder tree for display in the page creation dialog.
     * @param contents
     * @param ctrl
     */
    flatten(contents: TreeEntry[], ctrl) {
        if (contents && contents.length > 0) {
            contents.forEach((folder: TreeEntry): void => {
                if (folder.type === 0) {
                    let folderEntry: FolderEntry = folder as FolderEntry;
                    this.folder_options.push({
                        name: folderEntry.folder.attributes.name, folder: folderEntry.folder, parent: folderEntry.parent
                    });
                    this.flatten(folderEntry.contents, ctrl);
                }
            });
        }
    }

    setupDefaultDashboard() {
        this.userPreferenceData.getUserPreferencesByUserAndAccount(this.appScope.current_user.id).pipe(take(1)).subscribe(result => {
            if (result?.data[0]?.id) {
                this.user_preference = result.data[0];
                this.is_default_dashboard = this.user_preference.relationships.session_state.data?.id === this.session_id;
            } else {
                this.user_preference = new UserPreference({
                    user_id: this.appScope.current_user.id,
                    account_id: this.appScope.active_account_id
                });
                this.is_default_dashboard = false;
            }

        });
    }

    tabChange(event: MatTabChangeEvent) {
        if (event.tab.textLabel === 'Advanced') {
            try {
                this.json_string = new JsonPipe().transform(this.json);
                this.json_stringify_error = false;
            } catch (e) {
                this.json_stringify_error = true;
            }
        } else {
            this.updateJson(this.json_string);
        }
    }

    getDefaultFolderOptions() {
        this.default_folder_options = this.folder_options.filter(f => {
            return this.data.relationships.folders.data.map(d => d.id).includes(f.folder.id);
        });
    }

    async save() {
        const confirm = await this.formDialogService.confirm('Please note: "Save" will push the attribute changes you have made into production.')
        if (!confirm) {
            return;
        }
        const ctrl = this;
        ctrl.data.attributes = ctrl.json;
        ctrl.pageDataService.savePage(this.data)
            .pipe(
                concatMap((result: SingleResponse<SessionState>) => {
                    this.notification.openSuccess('Page saved', 3000);
                    this.data.id = result.data.id;
                    return forkJoin([this.saveUserPreferences(), this.saveGroups(this.data.id)]);
                }),
                first(),
                takeUntil(this.onDestroy),
                catchError(e => {
                    this.notification.openError('There was a problem saving this page.', 3000);
                    console.log("ERROR: PageViewForm (save)", e);
                    return of(e != null);
                })
            ).subscribe((err) => {
            if (err === true) {
                return;
            }
            this.dialogRef.close(ctrl.data);
        });
    }

    saveUserPreferences(): Observable<any> {
        let $obs;
        if (this.is_default_dashboard) {
            if (this.user_preference.relationships.session_state.data
                && this.user_preference.relationships.session_state.data.id !== this.session_id) {
                this.user_preference.relationships.session_state.data.id = this.session_id;
            } else {
                this.user_preference.relationships.session_state.data = {id: this.session_id, type: 'session_state'};
            }
            $obs = this.userPreferenceData.saveUserPreferences(this.user_preference);
        } else if (!this.is_default_dashboard && this.user_preference.relationships.session_state.data?.id === this.session_id
            && this.session_id) {
            this.user_preference.relationships.session_state.data = null;
            $obs = this.api.user_preference.obsPatch(this.user_preference);
        } else {
            return of({});
        }
        return $obs.pipe(take(1),
            tap((result: SingleResponse<UserPreference>) => {
                // TODO proper state management of user??
                this.appScope.current_user.default_dashboard_id = result?.data ? this.session_id :
                    this.appScope.current_user.default_dashboard_id;
            }),
            catchError(err => {
                    console.log('Error updating User Preferences - : ', err);
                    this.notification.openError('Error saving user preferences: ', 3000);
                    return err;
                }
            ));
    }

    saveGroups(session_id: ModelID) {
        let new_mapping_list = [];
        if (!this.groups || this.groups.length < 1) {
            return of(null);
        }
        this.groups.forEach(g => {
            new_mapping_list.push({page_id: session_id, group_id: g.id});
        });
        return this.relMapping.saveManyCustomApi('group', [], new_mapping_list, 'api/upsert/group-page', 'api/delete/group-page')
            .pipe(
                catchError(e => {
                    console.log("ERROR: PageViewForm (saveGroups)", e);
                    this.notification.openError('Error saving groups for this page.', 3000);
                    return of(e);
                })
            );
    }

    onCloseClick(): void {
        let confirmNotification: MatSnackBarRef<ConfirmSnackbarComponent>;
        confirmNotification = this.notification.openConfirm('You have unsaved changes. Click OK to discard them, or Cancel to go back.');
        confirmNotification.onAction().subscribe((): void => {
            this.dialogRef.close();
        });
    }


    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
    };

    canHideLogo(): boolean {
        return this.userService.isAdminUser() && this.userService.isMMSUser();
    }

    filterFolders(filterString: string) {
        this.filteredFolderOptions = this.folder_options.filter(f =>
            f.name.toLowerCase().includes(filterString.toLowerCase())
        );
    }

    resetFolderOptions() {
        this.filteredFolderOptions = deepCopy(this.folder_options);
    }

    private updateJson(json_string) {
        this.json = JSON.parse(json_string);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
