import {Component, EventEmitter, OnDestroy, OnInit, Output, Input} from '@angular/core';
import {EventType} from "../../../../_models/event-type";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ApiService} from "../../../../services/api/api.service";
import {EventModelUploadDataService} from "../event-model-upload-data.service";
import {sortBy} from "lodash-es";

@Component({
    selector: 'event-model-upload-select-event-type',
    templateUrl: './event-model-upload-select-event-type.component.html',
    styleUrls: ['../extract-event-options.component.less'],
    standalone: false
})
export class EventModelUploadSelectEventTypeComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    event_types: EventType[] = [];
    eventTypeID: string;

    @Input() readonly = false;
    @Output() eventTypeIDChange = new EventEmitter<string>();
    compare_event: (a, b) => boolean;

    constructor(private api: ApiService,
                public dataService: EventModelUploadDataService) {
        this.compare_event = (a, b) => {
            return a === b;
        };
    }

    ngOnInit(): void {
        this.dataService.event_type_id$.pipe(takeUntil(this.onDestroy)).subscribe(type_id => {
            this.eventTypeID = type_id;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setEventType(event_type_id: string) {
        this.dataService.changeEventType(event_type_id);
        this.eventTypeID = event_type_id;
        this.eventTypeIDChange.next(event_type_id);
    }
}
