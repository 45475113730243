<div class="rule-set">
  <div style="float:right;">
    <button #addFilter type="button" mat-button class="text-btn" (click)="addRule()">
      +Add filter
    </button>
    <button *ngIf="isQueryBuilder" type="button" mat-button class="text-btn" (click)="addRuleSet()">
      +Add group
    </button>
    <button *ngIf="allowRemoveRuleSet && isQueryBuilder" type="button" mat-button class="text-btn"
            (click)="requestRemoveRuleSet()">
      -Clear group
    </button>
  </div>


  <button *ngIf="!isQueryBuilder && isTopLevel" mat-raised-button color="accent" class="text-toggle" value="">RULES</button>


  <ul style="list-style:none;margin:4px 0 2px;">
    <li *ngFor="let rule of ruleSet.rules; let i=index" class="connector">
      <rule-set *ngIf="isRuleSet(rule)" [ruleSet]="rule" [fields]="fields" (removeRuleSetRequest)="removeRuleSet(i)"
                (ruleSetChange)="ruleSetChanged($event, i);" [isQueryBuilder]="false" class="ruleset-item"></rule-set>
      <rule *ngIf="isRule(rule)" [rule]="rule" [fields]="fields" (removeRuleRequest)="removeRule(i)"></rule>
    </li>
  </ul>
</div>
