<div class="constant-property-events">
  <label *ngIf="dtp?.start && dtp?.end" class="row" style="color:black;"><b>Time: </b>{{dtp.start  | stringDate}}
    - {{dtp.end  | stringDate}}</label>
  <div class="comment-inner">
    <div style="display: flex;justify-content: flex-end;">
      <mat-paginator [length]="event_total" [pageSize]="page_size"
                     [pageSizeOptions]="page_size_options.concat(event_total)"
                     [pageIndex]="page_index"
                     showFirstLastButtons
                     (page)="pageData($event)"
                     [disabled]="loading_next===true">
      </mat-paginator>
    </div>

    <div class="table-container" style="position:relative;">
      <table *ngIf="dataSource!==undefined && dataSource.data.length > 0" [dataSource]="dataSource"
             class="constant-property-events-table" mat-table
      >
        <ng-container *ngFor="let column of columns;">

          <!--Start-->
          <ng-container *ngIf="column==='start'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell sticky>{{ column | upperfirst | removeUnderscore}}
            </th>
            <td *matCellDef="let event;" mat-cell sticky>
              {{event.attributes.start | stringDate}}
            </td>
          </ng-container>
          <!--End-->
          <ng-container *ngIf="column==='end'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell sticky>{{ column | upperfirst | removeUnderscore}}
            </th>
            <td *matCellDef="let event;" mat-cell sticky>
              {{event.attributes.end | stringDate}}
            </td>
          </ng-container>
          <!--Constant Property-->
          <ng-container *ngIf="column===constant_property.attributes.name" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell sticky>{{ column | upperfirst | removeUnderscore}}
            </th>
            <td *matCellDef="let event;" mat-cell sticky
                [ngStyle]="{'text-align':constant_property.attributes.data_type==='float'?'right':'left'}">
              {{event.attributes.custom_constants[column]}}
            </td>
          </ng-container>

        </ng-container>

        <!--Row defs-->
        <tr *matHeaderRowDef="columns;" sticky mat-header-row></tr>
        <tr *matRowDef="let element; columns: columns;" mat-row>
        </tr>

      </table>


      <div [ngStyle]="{'display': loading_next? 'flex' : 'none'}" class="app-loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
