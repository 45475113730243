<div class="error" *ngIf="hasPermissions === false" style="text-align: left;padding:10px 20px 0;">
  You do not have permissions to apply corrections to one or more of these series.
</div>
<form *ngIf="form" class="form-dialog series-adjustment-tile" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="full-width full-height">
    <table style="text-align: left;" formArrayName="adjustments">
      <ng-container *ngFor="let adjustment of adjustments.controls; let i = index" [formGroupName]="i">
        <tr>
          <th style="width:30%;">Target series</th>
          <th style="width:30%;">Series to adjust</th>
          <th class="adjustment-input merged" *ngIf="adjustment.get('adjustment_type').value==='constant'" colspan="2">
            Value
          </th>
          <th style="width:15%" *ngIf="adjustment.get('adjustment_type').value==='find_factor'">Current Value</th>
          <th style="width:25%;" class="adjustment-input" *ngIf="adjustment.get('adjustment_type').value==='find_factor'">Target Value</th>
          <th style="width: 50px;"></th><!--Comment-->
        </tr>
        <tr>
          <td>{{seriesDict[adjustment.get('target_series_id').value]?.attributes | nameOrDescription}}</td>
          <td>{{seriesDict[adjustment.get('series_id').value]?.attributes | nameOrDescription}}</td>
          <td *ngIf="adjustment.get('adjustment_type').value==='constant'" colspan="2">
            <input formControlName="value" matInput type="number"/></td>
          <td *ngIf="adjustment.get('adjustment_type').value === 'find_factor'">
            {{seriesValues[adjustment.get('target_series_id').value] | formatNumber:seriesDict[adjustment.get('target_series_id').value]?.attributes?.decimal_places:true}}
          </td>
          <td *ngIf="adjustment.get('adjustment_type').value === 'find_factor'">
            <input formControlName="target_value" matInput type="number" [ngClass]="{'input-error': adjustment.get('target_value').invalid && adjustment.get('target_value').touched}"/></td>
          <td style="text-align: center;">
            <button type="button" class="icon-comment" [ngClass]="{'has-comment' : adjustment.value.comment}"
                    (click)="$event.stopPropagation();openModal($event, adjustment);"></button>
          </td>
        </tr>
      </ng-container>
    </table>
    <div class="row min-height">
      <mat-checkbox formControlName="updateCalculations">Tick to update all relevant calculations</mat-checkbox>
    </div>
  </div>
  <div class="align-bottom">
    <button type="submit" [disabled]="!form.valid || !isFormChanged()" class="bordered-button big-btn save" mat-button
            matTooltip="Submit adjustments" [matTooltip]="submitTooltip()">Submit
    </button>

  </div>
</form>
