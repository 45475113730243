import {ChangeDetectionStrategy, Component, ViewEncapsulation, Input} from '@angular/core';
import {BaseComponent} from "../../shared/base.component";
import {ConfigStub} from "../../_typing/config-stub";
import {Series} from "../../_models/series";
import {getBaseFilter} from "../../services/api/filter_utils";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {BeforeSaveAction} from "../../_models/tile";
import {
    SeriesAdjustmentConfig,
    SeriesAdjustmentConfigAdjustment,
    seriesAdjustmentTypeArray
} from "../../_models/jobs/series-correction-job";

@Component({
    selector: 'series-adjustment-form',
    templateUrl: './series-adjustment-form.component.html',
    styleUrls: ['./series-adjustment-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesAdjustmentFormComponent extends BaseComponent implements BeforeSaveAction {
    @Input() config: SeriesAdjustmentConfig;
    targetFilters = [getBaseFilter(true, 'is_calculation', 'eq')];
    adjustFilters = [
        {
            or: [getBaseFilter(false, 'is_calculation', 'eq'),
                getBaseFilter(null, 'is_calculation', 'eq')]
        }
    ];
    //Constant should be readded to this array once it is supported
    seriesAdjustmentTypeArray = seriesAdjustmentTypeArray;
    error = '';

    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.config.adjustments?.length) {
            this.addAdjustment();
        }
    }

    addAdjustment() {
        this.config.adjustments.push(new SeriesAdjustmentConfigAdjustment());
    }

    removeAdjustment(index) {
        this.config.adjustments.splice(index, 1);
    }

    targetSelected(event, configRow: SeriesAdjustmentConfigAdjustment) {
        configRow.target_series = new ConfigStub(event.value);
    }

    adjustSelected(event, configRow, i) {
        if (!this.validateAdjustment(event.value, 'adjust', i)) { return; }
        configRow.adjust = event.value;

    }

    validateAdjustment(value: Series, key: 'adjust' | 'target_series', index: number): boolean {
        this.error = "";
        const adjustsSelected = this.config.adjustments.map(a => a[key]?.id);
        if (adjustsSelected.includes(value.id) && adjustsSelected[index] !== value.id) {
            this.error = "ERROR: Duplicate series selected.";
            return false;
        }
        return true;
    }

    beforeSave(): boolean {
        if (this.error) {
            return false;
        }
        let valid = true;
        this.config.adjustments.forEach(a => {
            if (!a.target_series || !a.adjust) {
                this.error = "ERROR: Some adjustment rows are incomplete.";
                valid = false;
            }
        });
        return valid;
    }

    drop(event: CdkDragDrop<EventConfigColumn[]>): void {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
}
