import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Constraint} from "../../_models/solver";
import {Series} from "../../_models/series";

@Component({
    selector: 'series-formula-builder',
    templateUrl: './series-formula-builder.component.html',
    styleUrls: ['./series-formula-builder.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesFormulaBuilderComponent {
    @Input() constraint: Constraint;
    insert_series: Series;

    addToFormula() {
        if (!this.constraint.attributes.name_formula) {
            this.constraint.attributes.name_formula = '';
        }
        this.constraint.attributes.name_formula += ' [' + this.insert_series.attributes.name + '] ';
    }
}
