import moment from "moment-timezone";

export interface TimezoneOption {
    alias?: string; //A user-specific name for the timezone e.g. the name of the mine
    name: string; //A generic name for the timezone
    value: string; //The string identifier used by moment.js
    offset?: string //The current time offset from UTC
}

/**This should be expanded, but what the client wants to see can be added to configuration settings, key = timezone_options
 * Use alias to enter a user friendly name such as the name of the mine at that timezone.
 * Offset is worked out at runtime based on today's date (offset might differ on another date due to DLS,
 * it is just an indicator to the user, not actually used.**/
export const default_timezones: TimezoneOption[] = [
    {name: 'Pacific/Midway', value: 'Pacific/Midway'},
    {name: 'US/Hawaii', value: 'US/Hawaii'},
    {name: 'Etc/GMT+9', value: 'Etc/GMT+9'},
    {name: 'US/Alaska', value: 'US/Alaska'},
    {name: 'Canada/Pacific (PST PDT), Vancouver', value: 'Canada/Pacific'},
    {name: 'Canada/Mountain', value: 'Canada/Mountain'},
    {name: 'US/Central', value: 'US/Central'},
    {name: 'Canada/Eastern (EST EDT), Toronto', value: 'Canada/Eastern'},
    {name: 'America/Buenos_Aires', value: 'America/Buenos_Aires'},
    {name: 'America/Noronha', value: 'America/Noronha'},
    {name: 'Atlantic/Azores', value: 'Atlantic/Azores'},
    {name: 'Etc/GMT+1', value: 'Etc/GMT+1'},
    {name: 'UTC', value: 'Etc/UTC'},
    {name: 'Europe/Lisbon', value: 'Europe/Lisbon'},
    {name: 'Europe/Amsterdam', value: 'Europe/Amsterdam'},
    {name: 'South African Standard Time', value: 'Africa/Johannesburg'},
    {name: 'Europe/Istanbul', value: 'Europe/Istanbul'},
    {name: 'Asia/Dubai', value: 'Asia/Dubai'},
    {name: 'Asia/Tashkent', value: 'Asia/Tashkent'},
    {name: 'Asia/Dhaka', value: 'Asia/Dhaka'},
    {name: 'Asia/Saigon', value: 'Asia/Saigon'},
    {name: 'Asia/Singapore', value: 'Asia/Singapore'},
    {name: 'Asia/Tokyo', value: 'Asia/Tokyo'},
    {name: 'Australia/Melbourne (AEST AEDT)', value: 'Australia/Melbourne'},
    {name: 'Pacific/Fiji', value: 'Pacific/Fiji'},
];

export interface ConfigNameMap {
    [key: string]: { id: string, value: any }
}

export const default_config_map: ConfigNameMap = setDefaultConfigMap();

//TODO replace individual checks in code with added defaults here
export function setDefaultConfigMap(): ConfigNameMap {
    const default_config: ConfigNameMap = {
        refresh_timeout: {id: null, value: "120"},
        default_sample_period: {id: null, value: "hour"},
        week_start: {id: null, value: "1"},
        date_period: {
            id: null, value: {
                default_period: 1,
                default_range: "yesterday",
                default_shift_length: 8,
                default_start_hour: 8
            }
        },
        timezone: {id: null, value: "Africa/Johannesburg"},
        timezone_options: {id: null, value: default_timezones}
    }
    return default_config;
}
