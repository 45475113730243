<div class="series-report-builder">
  <div class="row">
    <select-many-series [label]="'Select series'"
                        [value]="config.series"
                        [classes]="'fill'"
                        (selectionChange)="updateSeries($event)"
                        class="select-search"
    >
    </select-many-series>
  </div>

  <h3>Please select the columns for each series</h3>
  <div class="row center series-column-row" *ngFor="let series of config.series">
    <select-many-filter [label]="series.attributes | nameOrDescription"
                        [matTooltip]="series.attributes | nameAndDescription"
                        [options]="columnOptions"
                        [modelValue]="config.series_column_dict?.[series.id]"
                        (constant_changed)="updateSeriesColumns(series, $event)"
                        [placeholder]="'Please select columns'"
                        [listType]="'columns'"
                        class="flex-half alone" [classes]="'fill'"
    >
    </select-many-filter>
  </div>
</div>
