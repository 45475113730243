import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {TileDataService} from "../../services/tile_data.service";
import {SeriesColumnList, SeriesDataService} from "../../services/series_data.service";
import * as utils from "../../lib/utils";
import * as QuillNamespace from 'quill';
import {QuillEditorComponent} from "ngx-quill";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {catchError, map} from "rxjs/operators";
import {NotificationService} from "../../services/notification.service";
import {OptionalSnackbarParams} from "../../_typing/notification";

let Quill: any = QuillNamespace;

export interface DialogData {
    form_result: string;
    config: { paragraph_body: any };
}

@Component({
    selector: 'paragraph-form',
    templateUrl: './paragraph-form.component.html',
    styleUrls: ['./paragraph-form.component.less'],
    standalone: false
})
export class ParagraphFormComponent implements OnInit {
    paragraph: any;
    @ViewChild('imageUpload', {static: true}) fileUpload: ElementRef<HTMLInputElement>;
    showing_hints: boolean = false;
    private readonly onDestroy = new Subject<void>();
    private quill: QuillEditorComponent;
    insert_series: any;
    insert_column: any;
    all_columns: SeriesColumnList;
    gss_insert: any;
    FontAttributor: any;

    constructor(private http: HttpClient,
                public dateTimePeriodService: DateTimePeriodService,
                public tileData: TileDataService,
                private seriesData: SeriesDataService,
                private notification: NotificationService,
                private dialogRef: MatDialogRef<ParagraphFormComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
                private renderer: Renderer2) {
        this.paragraph = utils.deepCopy(this.dialogData.config.paragraph_body);
    }

    @ViewChild('quillEditorComponent', {static: true}) set setQuill(content: any) {
        if (!content) {
            return;
        }
        this.quill = content;
    }

    ngOnInit() {
        const ctrl = this;
        this.seriesData.$estimate_types.promise.then(response => {
            ctrl.all_columns = ctrl.seriesData.fillColumns(response?.map(et => et.attributes.name));
            ctrl.insert_column = ctrl.all_columns.columns.find(col => col.name === "Value");
        });

        this.FontAttributor = Quill.import('attributors/class/font');
        this.FontAttributor.whitelist = ['Rajdhani', 'sans-serif', 'serif', 'monospace'];
        Quill.register(this.FontAttributor, true);
    }

    adjustTooltip(event) {
        let tooltips = document.querySelectorAll('div.ql-tooltip:not(.ql-hidden)');

        if (tooltips && tooltips.length > 0) {
            let parent = tooltips[0].closest(".ql-container.ql-snow");

            let t = tooltips[0].getBoundingClientRect();
            let p = parent.getBoundingClientRect();
            // @ts-ignore
            let left = t.x;
            // @ts-ignore
            if ((left + tooltips[0].offsetWidth) > (p.x + p.width)) {
                let diff = p.width - t.width + 10;
                this.renderer.setStyle(tooltips[0], 'left', `${diff}px`);
            } else if ((left < p.x) || (left < 0)) {
                this.renderer.setStyle(tooltips[0], 'left', "-10px");
            }
        }
    }

    customImageHandler: any = () => {
        const quill = this.quill.quillEditor;
        quill.enable(false);
        const range = quill.getSelection();

        this.fileUpload.nativeElement.onchange = (): void => {
            const files: FileList = this.fileUpload.nativeElement.files;
            if (!files || files.length < 1) {
                return;
            }
            const file: File = files[0];

            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            this.notification.openSuccess('Uploading image');

            this.http.post('/api/BlobData', formData).pipe(
                map((response: { file_name: string }): OptionalSnackbarParams => {
                    quill.enable(true);
                    quill.insertEmbed(range.index, 'image', 'admin/myfiles/download' + response.file_name, Quill.sources.USER);
                    return {message: 'Image uploaded successfully.', duration: 1500};
                }), catchError((error): Observable<OptionalSnackbarParams> => {
                    quill.enable(true);
                    console.error('Error uploading collector data', error);
                    throw new Error('An error occurred while collecting the file.');
                })).subscribe({
                next: (response: OptionalSnackbarParams) => {
                    this.notification.openSuccess(response.message, response.duration);
                }, error: (err) => {
                    this.notification.openError(err);
                }
            });
            this.fileUpload.nativeElement.click();
        };
    }

    options = {
        clipboard: {
            matchVisual: false
        },
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                [{"header": 1}, {"header": 2}],
                [{"list": "ordered"}, {"list": "bullet"}],
                [{"script": "sub"}, {"script": "super"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"direction": "rtl"}],
                [{"size": ["small", false, "large", "huge"]}],
                [{"header": [1, 2, 3, 4, 5, 6, false]}],
                [{"color": []}, {"background": []}],
                [{"font": ['Rajdhani', 'sans-serif', 'serif', 'monospace']}],
                ["link", "image"],
                ["clean"]
            ],
            handlers: {
                image: this.customImageHandler
            }
        }
    };

    addToFormula(event) {
        if (event) {
            this.insert_series = event.value;
        }
        if (this.insert_series && this.insert_column) {
            this.gss_insert = `[${this.insert_series.attributes.name}@${this.insert_column.name}]`;
        }
    }

    async copyToClipboard(el: HTMLInputElement) {
        try {
            await navigator.clipboard.writeText(el.value);
            this.notification.openSuccess('Copied to clipboard.', 1500);
        } catch (err) {
            this.notification.openError('Unable to copy to clipboard.');
        }
    }

    save() {
        this.dialogRef.close({paragraph_body: this.paragraph});
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
