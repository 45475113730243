import {Component, Input, OnInit} from '@angular/core';
import {SeriesDataTableFormService} from "./series-data-table-form.service";
import {TileDataService} from "../../services/tile_data.service";
import {ColumnFormatsConfigDict} from "../table-column-menu/table-column-menu.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ConfigStub} from "../../_typing/config-stub";
import {Process} from "../../_models/process";
import {Series} from '../../_models/series';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {IDMap, KeyMap, ModelID, ModelName} from "../../_typing/generic-types";
import {deepCopy} from "../../lib/utils";

export interface ISeriesDataTableHeaderRows {
    process: boolean,
    custom_groups: boolean,
    name: boolean,
    description: boolean,
    alias: boolean,
    unit: boolean,
    edit: boolean
}

export type SeriesDataTableHeaderRows = Partial<ISeriesDataTableHeaderRows>;

export type SeriesGroupConfig = Partial<Series & { group: string; }>

export type SeriesDataSeriesGroupDict = KeyMap<SeriesGroupConfig[]>;

export interface SeriesDataTableGssConfig {
    columns: string[];
    column_formats: ColumnFormatsConfigDict,
    mobile_columns: string[],
    estimate_type: string[];
    shift_type_id: number;

}

export interface SeriesDataTableConfig {
    process_list: ConfigStub<Process>[];
    process_series_map: SeriesDataSeriesGroupDict;
    header_rows: SeriesDataTableHeaderRows;
    column_formats?: ColumnFormatsConfigDict;
    show_gss?: string; // top, bottom, none
    gss?: Partial<SeriesDataTableGssConfig>;
    orientation: 'vertical' | 'horizontal';
}

@Component({
    selector: 'series-data-table-form',
    templateUrl: './series-data-table-form.component.html',
    styleUrls: ['./series-data-table-form.component.less'],
    providers: [SeriesDataTableFormService],
    standalone: false
})
export class SeriesDataTableFormComponent implements OnInit {
    @Input() config: SeriesDataTableConfig;
    process_filters: { [key: string]: any[] } = {};
    col_menu_options: string[] = ['bold', 'italic', 'size', 'fit_content', 'colour', 'background_colour', 'decimals', 'align'];
    seriesDict: IDMap<Series> = {};

    constructor(private tileDataService: TileDataService,
                private cps: SeriesDataTableFormService,
    ) {
    }

    ngOnInit(): void {
        this.config = this.cps.setDefaults(this.config);
        if (this.config.process_list) {
            this.processSelected(this.config.process_list);
        }
    }

    processSelected($event) {
        this.config.process_list = ConfigStub.getConfigStubs<Process>($event);
        this.config.process_series_map = this.cps.getProcessSeriesMap($event, this.config.process_series_map);
        $event.forEach(s => this.seriesDict[s.id] = s);
        this.process_filters = this.cps.getSeriesLightFilters($event);
    }

    seriesSelected($event, process_id) {
        let seriesGroupDict = {};
        this.config.process_series_map[process_id].forEach(s => seriesGroupDict[s.id] = s.group || '');
        this.config.process_series_map[process_id] = $event?.map((s: Series) => {
            const group = {group: seriesGroupDict[s.id] || ''}
            return Object.assign(new ConfigStub<Series>(s), group);
        });
    }

    updateGroups(event: MatCheckboxChange, type) {
        if (!event.checked) return;
        if (type === 'custom') {
            this.config.header_rows.process = false;
        } else {
            this.config.header_rows.custom_groups = false;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

}
