import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AppScope} from '../../services/app_scope.service';
import {takeUntil} from "rxjs";
import {BaseComponent} from "../../shared/base.component";
import {HeaderDataService} from "../../services/header_data.service";
import {TimezoneSelectorService} from "../../services/timezone-selector.service";

@Component({
    selector: 'subroute-page-outlet',
    templateUrl: './subroute-page-outlet.component.html',
    styleUrls: ['./subroute-page-outlet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubroutePageOutletComponent extends BaseComponent {
    /**
     * This Component is used to wrap the router output, when loading a submodule module, in a standard WIRE page view.
     */
    currentTheme: string;

    constructor(private appScope: AppScope,
                public headerData: HeaderDataService,
                public timezoneSelectorService: TimezoneSelectorService) {
        super();
        this.appScope.currentTheme.pipe(takeUntil(this.onDestroy)).subscribe((theme: string) => {
            this.currentTheme = theme;
        })
    }

    ngOnInit() {
    }
}
