import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {MatMenuTrigger} from "@angular/material/menu";
import * as utils from "../lib/utils";

@Component({
    selector: 'inline-style-menu',
    templateUrl: 'inline_style_menu.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class InlineStyleMenuComponent implements OnInit {
    @Input() value_component: any;
    @Input() tree_position: any;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;


    constructor(private api: ApiService) {
    }

    closeMenu() {
        const cleaned = this.cleanSchema()
        this.api[this.value_component.type].patch(cleaned);
        this.trigger.closeMenu();
    }

    cleanSchema(): any {
        let cleaned = utils.deepCopy(this.value_component);
        if (cleaned.type === 'constant_component') {
            if (cleaned.attributes.data_type) {
                delete cleaned.attributes.data_type;
            }
            if (cleaned.attributes.name) {
                delete cleaned.attributes.name;
            }
        }
        return cleaned;
    }

    ngOnInit(): void {
    }
}
