import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DialogPosition, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ConstantProperty} from "../../../_models/constant-property";
import {ComponentType} from "../../../_models/component-type";
import {EventType} from "../../../_models/event-type";
import {RuleSet} from "ngx-angular-query-builder";
import {EventConfigColumn} from "../../../_typing/config/config-column";
import {IDMap, KeyMap} from '../../../_typing/generic-types';
import {ConfigStub} from '../../../_typing/config-stub';

export interface FilterData {
    cp_dict: { [key: string]: ConstantProperty };
    constant_properties: ConstantProperty[];
    component_type?: ComponentType;
    component_types?: ComponentType[];
    event_types?: ConfigStub<EventType>[];
    selected_cols_dict: { [key: string]: EventConfigColumn };
    query: RuleSet | null;
    positionRelativeToElement: HTMLElement | null;
}

@Component({
    selector: 'filter-builder-form',
    templateUrl: './filter-builder-form.component.html',
    styleUrls: ['./filter-builder-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterBuilderFormComponent implements OnInit, AfterViewInit {
    @ViewChild('filterDialogContainer') filterDialogContainer: ElementRef;
    public cp_dict: IDMap<ConstantProperty>;
    public constant_properties: ConstantProperty[];
    public component_type?: ComponentType;
    public component_types?: ComponentType[];
    public event_types?: ConfigStub<EventType>[];
    public selected_cols_dict: KeyMap<EventConfigColumn>;
    public query?: RuleSet;
    public applyDisabled: boolean;
    private _positionRelativeToElement: HTMLElement;
    private _initPosition: number;
    private _isStickyBottom: boolean;
    private position: DialogPosition;
    @Input() public filterQuery: RuleSet | null;
    defaultQuery = {
        condition: 'and',
        rules: []
    };

    constructor(@Inject(MAT_DIALOG_DATA) public filterData: FilterData,
                private matDialogRef: MatDialogRef<FilterBuilderFormComponent>, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.cp_dict = this.filterData.cp_dict;
        this.component_type = this.filterData.component_type;
        this.component_types = this.filterData.component_types;
        this.event_types = this.filterData.event_types;
        this.constant_properties = this.filterData.constant_properties;
        this.filterQuery = this.filterData.query;
        this.selected_cols_dict = this.filterData.selected_cols_dict;
    }

    ngAfterViewInit(): void {
        this._positionRelativeToElement = this.filterData.positionRelativeToElement;
        if (this._positionRelativeToElement) {
            this.updateDialogPosition();
            this.changeDetectorRef.detectChanges();
        }
    }

    updateDialogPosition(isInit: boolean = true): void {
        const matDialogConfig: MatDialogConfig = new MatDialogConfig();
        const rect: DOMRect = this._positionRelativeToElement.getBoundingClientRect();
        let distanceFromBottom: number = window.innerHeight - this.filterDialogContainer.nativeElement.getBoundingClientRect().bottom;

        if (isInit) {
            let distanceFromRight: number;
            this.position = {top: rect.y + rect.height + 'px', left: rect.x + 'px'};
            matDialogConfig.position = this.position;
            this.matDialogRef.updatePosition(matDialogConfig.position);
            this._initPosition = this.filterDialogContainer.nativeElement.getBoundingClientRect().y;
            distanceFromRight = window.innerWidth - this.filterDialogContainer.nativeElement.getBoundingClientRect().right;
            if (distanceFromRight < 50) {
                this.position.right = 0 + 'px';
                this.position.left = undefined;
                this.matDialogRef.updatePosition(matDialogConfig.position);
            }
        }
        if (distanceFromBottom < 50 && !this._isStickyBottom) {
            this.position.bottom = 0 + 'px';
            this.position.top = undefined;
            matDialogConfig.position = this.position;
            this.matDialogRef.updatePosition(matDialogConfig.position);
            this._isStickyBottom = true;
        }
        if (this.filterDialogContainer.nativeElement.getBoundingClientRect().y > this._initPosition && this._isStickyBottom) {
            this.position.top = rect.y + rect.height + 'px';
            this.position.bottom = undefined;
            matDialogConfig.position = this.position;
            this.matDialogRef.updatePosition(matDialogConfig.position);
            this._isStickyBottom = false;
        }
    }

    onQueryChange(updatedQuery) {
        this.filterQuery = updatedQuery;
        if (updatedQuery && this.rulesInvalid(updatedQuery)) {
            this.applyDisabled = true;
        } else {
            this.applyDisabled = false;
        }
        this.filterData.query = this.filterQuery;
        setTimeout(() => {
            this.updateDialogPosition(false);
        }, 10);
    }

    rulesInvalid(query: RuleSet): boolean {
        let valid = false;
        let rules = query.rules;
        rules.forEach(rule => {
            if ("rules" in rule) {
                valid = this.rulesInvalid(rule);
            } else {
                let fieldValid = "field" in rule && rule.field;
                let operatorValid = "operator" in rule && rule.operator;
                // "value" is not required if using the "Is Blank" or "Is Not Blank" operators
                let valueValid = (operatorValid && rule.operator).includes("blank") ||
                    ("value" in rule && (rule.value || rule.value === 0));
                valid = !(fieldValid && valueValid && operatorValid);
            }
            if (!valid) {
                return;
            }
        });
        return valid;
    }

    clearFilter() {
        this.onQueryChange(null);
    }

    onClose(): void {
        this.matDialogRef.close({query: this.filterData.query, refresh: false});
    }
}
