import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ClearConstantService {

    public clearRequested: Subject<void> = new Subject();

    constructor() {
    }

    public clearConstants() {
        this.clearRequested.next();
    }
}
