import * as moment_ from 'moment';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {forkJoin, Subject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {map, take, takeUntil, tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import * as utils from "../../lib/utils";
import {EventTypeService} from '../../services/event_type.service';
import {
    openUploadModelFormComponent,
    UploadModelFormData
} from "../../forms/upload-model-form/upload-model-form-dialog.component";
import {OreBodyType} from "../../_models/ore-body-type";
import {FormDialogService} from '../../services/form-dialog.service';
import {ConstantProperty} from '../../_models/constant-property';
import {IDateTimePeriod} from "../../_typing/date-time-period";

export const moment = moment_["default"];

@Component({
    selector: 'ore-body-types-table',
    templateUrl: 'ore-body-types-table.component.html',
    styleUrls: ['ore-body-types-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OreBodyTypesTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    page_ready: boolean = false;
    buttons = [];

    ore_body_types: any[];
    constant_properties_list: any[];
    cp_dict: { [key: string]: ConstantProperty } = {};

    columns: string[] = ["name", "description", "constant_properties", "account"];

    dtp: IDateTimePeriod;

    dataSource: MatTableDataSource<OreBodyType>;
    private readonly onDestroy = new Subject<void>();
    private sort;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private changeDetectorRef: ChangeDetectorRef,
                public dialog: MatDialog,
                private eventTypeService: EventTypeService,
                private formDialogService: FormDialogService) {
    }

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    ngOnInit(): void {

        const ctrl = this;
        this.headerData.title = 'Ore Body Types';
        this.buildHeader();
        this.refreshOreBodyTypes();

    }

    refreshOreBodyTypes() {
        const ctrl = this;
        const $constant_properties = ctrl.eventTypeService.getConstantProperties().pipe(tap(result => {
            ctrl.constant_properties_list = result.data;
            ctrl.constant_properties_list.map(cp => ctrl.cp_dict[cp.id] = cp);
        }));
        const $ore_body_types = ctrl.eventTypeService.getOreBodyTypes();

        forkJoin([$constant_properties, $ore_body_types]).pipe(
            take(1),
            map(results => {
                //TODO fix result type??
                ctrl.ore_body_types = results[1]['data'];
            })).subscribe(() => {
            ctrl.mapOreBodyTypes();
        });
    }

    mapOreBodyTypes() {
        const ctrl = this;
        //utils.fill_object_relations(ctrl.ore_body_types, ctrl.constant_properties_list, 'constant_properties');

        this.dataSource = new MatTableDataSource(this.ore_body_types);
        this.dataSource.filterPredicate = (data, filter) => {
            return (data.attributes.name && data.attributes.name.toLowerCase().includes(filter))
                || (data.attributes.description && data.attributes.description.toLowerCase().includes(filter))
                || (utils.checkDown(data, ['relationships', 'constant_properties', 'data'], true, true)
                    && data.relationships.constant_properties.data.map(prop => {
                        // @ts-ignore
                        return prop.attributes.name.toLowerCase()
                    }).includes(filter))
        };
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case "name":
                    return data.attributes.name;
                case "description":
                    return data.attributes.description;
                case "account":
                    return data.attributes.account_name;
                default:
                    return "";
            }
        };
        this.dataSource.paginator = this.paginator;
        this.changeDetectorRef.markForCheck();
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    editOreBodyType(ore_body_type?) {
        const dialogRef = this.formDialogService.editOreBodyType(ore_body_type);
        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                if (ore_body_type) {
                    ore_body_type = result.data;
                } else {
                    this.ore_body_types.push(result.data);
                }
                this.refreshOreBodyTypes();
            } else {
            }
        });
    }

    openUploadModelsForm() {
        const dialogData: UploadModelFormData = {parser_type: 'orebody'};
        openUploadModelFormComponent(this.dialog, dialogData);
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Ore Body Types';
        ctrl.headerData.show_dtp = false;
        ctrl.headerData.buttons = [
            {name: 'Add Ore Body Type', func: ctrl.editOreBodyType.bind(ctrl), class: 'icon-plus', params: {}},
            {name: 'Upload Model Definitions', func: ctrl.openUploadModelsForm.bind(ctrl), class: 'icon-plus'},
        ];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
