import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {WaterfallChartService} from "./waterfall-chart.service";
import {GenericChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {ChartService} from "../../services/chart.service";
import {
    PlotlyConfigConfiguration,
    PlotlyDataConfiguration,
    PlotlyLayoutConfiguration
} from '../../services/plotly-chart-config-translation.service';
import {takeUntil, take} from 'rxjs/operators';
import {BaseComponent} from "../../shared/base.component";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'waterfall-chart',
    templateUrl: './waterfall-chart.component.html',
    styleUrls: ['./waterfall-chart.component.less'],
    providers: [WaterfallChartService, ChartService],
    standalone: false
})
export class WaterfallChartComponent extends BaseComponent {
    @Input('config') tile_config: GenericChartTileConfiguration;

    data: PlotlyDataConfiguration[];
    layout: PlotlyLayoutConfiguration;
    config: PlotlyConfigConfiguration;

    @ViewChild('plotly_container') plotly_container: ElementRef<HTMLDivElement>;

    constructor(private cps: WaterfallChartService,
                private dateInst: DateTimeInstanceService) {
        super();
    }

    ngOnInit(): void {
        this.cps.$waterfall_config_ready.pipe(takeUntil(this.onDestroy))
            .subscribe(chart_config => {
                this.data = chart_config.data;
                this.layout = chart_config.layout;
                this.config = chart_config.config;
            })


        if (this.plotly_container?.nativeElement.clientHeight) {
            this.tile_config.set_size = {height: this.plotly_container.nativeElement.clientHeight}
        }

        this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe((dtp) => {
            this.cps.getChart(this.tile_config);
        });

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.cps.getChart(this.tile_config)
            })

    }

}
