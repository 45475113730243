<div class="form-dialog ore-body-form-dialog">
  <h1 cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane"><span
      [textContent]="ore_body_type && ore_body_type.id ? 'Edit ore body type: ' + ore_body_type.attributes.name :  'Add ore body type'"></span>
  </h1>
  <mat-dialog-content>
    <mat-tab-group #ore_body_type_tabs [disableRipple]="true">
      <mat-tab label="Basic">
        <form *ngIf="ore_body_type" class="form-section column space-between basic">
          <div class="row">
            <mat-form-field style="flex-basis:50%;">
              <mat-label>Name</mat-label>
              <input [(ngModel)]="ore_body_type.attributes.name" matInput name="name" placeholder="">
            </mat-form-field>

            <mat-form-field style="flex-basis:50%;">
              <mat-label>Description</mat-label>
              <input [(ngModel)]="ore_body_type.attributes.description" matInput name="description" placeholder="">
            </mat-form-field>
          </div>

          <div class="row center">
            <div class="select-search-many">
              <select-many-search *ngIf="event_types_list" [(value)]="ore_body_type.relationships.event_types.data"
                                  [classes]="'fill'"
                                  [data]="event_types_list"
                                  [fill_stubs]="true"
                                  [label]="'Event Types'">
              </select-many-search>
            </div>
            <button (click)="goToTab(ore_body_type_tabs, 2)" *ngIf="ore_body_type.id" class="bordered-button"
                    mat-button>
              Edit Source and Destination mappings
              <i class="fa fa-caret-right" style="margin-left:10px;height: 21px;font-size: 18px;"></i>
            </button>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Constant Properties" *ngIf="ore_body_type?.id">
        <form class="form-section column space-between">
          <div class="info" style="margin:20px 0 0 0;"><i class="fa fa-info-circle"></i>
            Editing these Constant Properties will affect all Event Types associated with the Ore Body Type.
          </div>
          <div style="height:470px;max-height:470px;">
            <constant-property-table-form
                [parent_component]="ore_body_type"
            ></constant-property-table-form>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Source/Destination Mappings" *ngIf="ore_body_type?.id">
        <ng-template matTabContent>
          <form class="form-section column space-between">
            <ng-container *ngIf="ore_body_type && event_types_list">
              <div
                  *ngIf="saved_ore_body_type.relationships.event_types.data !== ore_body_type.relationships.event_types.data"
                  class="info" style="margin-bottom:10px;">
                Please remember to apply your changes to see the relevant data in this table.
              </div>
              <source-destination-table-form [event_types]="saved_ore_body_type.relationships.event_types.data"
                                             [ore_body_types]="[saved_ore_body_type]">
              </source-destination-table-form>
            </ng-container>
          </form>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="apply()" class="btn-save">Apply</button>
    <button (click)="save()" class="btn-save">Save</button>
  </div>
</div>
