import {ProcessViewModel} from "./process-viewmodel";

export class BaseViewModel {
    data: any;
    chart_parent_process: ProcessViewModel;
    type: string;
    styles: {
        font_size: string;
        bold: boolean;
        italic: boolean;
        underline: boolean;
        background: string;
        colour: string;
        border_colour: string;
        border_style: string;
        border_width: string;
    }
    private _selected = false;
    private _highlighted = false;

    constructor(dataModel: any) {
        this.data = dataModel;
        //this.type = this.data.type;
    }

    // Select the viewModel.
    select() {
        this._selected = true;
    };

    // Deselect the viewModel.
    deselect() {
        this._selected = false;
    };

    // Toggle the selection state of the viewModel.
    toggleSelected() {
        this._selected = !this._selected;
    };

    // Returns true if the viewModel is selected.
    selected() {
        return this._selected;
    };

    //Highlight
    highlight() {
        this._highlighted = true;
    };

    unhighlight() {
        this._highlighted = false;
    };

    highlighted() {
        return this._highlighted;
    };

}
