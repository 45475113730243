import {Component} from "@angular/core";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {ApiService} from "../../services/api/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../../services/header_data.service";
import {Subscription} from "rxjs";
import {BaseComponent} from "../../shared/base.component";
import {takeUntil, tap} from "rxjs/operators";
import {FlowchartData} from "../../_models/flowchart-data";
import {Component as ModelComponent} from "../../_models/component";


@Component({
    selector: 'equipment-sheet-focus-view',
    templateUrl: 'equipment-sheet-focus-view.component.html',
    standalone: false
})
export class EquipmentSheetFocusViewComponent extends BaseComponent {
    process: any;
    flowSheet: any;
    components: ModelComponent[];
    equipment: any;
    sheet_ready: boolean;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.params_subscription = this.route.params.subscribe(params => {
            this.sheet_ready = false;
            const process_id = params.processID;

            this.api.process.getById(process_id).pipe(tap(response => {
                this.process = response.data;
                this.plantData.process_focus = this.process;
                this.buildHeader();
            }));
            this.plantData.getFlowchartDataForProcess(process_id).pipe(takeUntil(this.onDestroy)).subscribe(flowchart_data => {
                this.setupEditSheet(flowchart_data);
            });
        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
        super.ngOnDestroy();
    }

    setupEditSheet(flowchart_data: FlowchartData) {
        this.components = [];
        this.components =
            this.components.concat(flowchart_data.process_focus).concat(flowchart_data.processes).concat(flowchart_data.streams);
        this.equipment = this.flowSheet.equipment;
        this.sheet_ready = true;
    }

    buildHeader() {
        this.headerData.title = 'Equipment: ' + this.process.attributes.name;
    }
}
