import {Injectable} from '@angular/core';
import {CustomEventsConfig} from "../../../forms/custom-events-form/custom-events-form.component";
import {FieldOptionService} from "./field-option.service";
import {CustomEventsService} from "../../../services/custom-events.service";
import {Observable, of} from "rxjs";
import {Field, FieldMap, Rule} from "../../../_typing/query-builder";
import {ModelID} from "../../../_typing/generic-types";
import {QueryBuilderFormService} from "../../../services/query-builder-form.service";

@Injectable({
    providedIn: 'root'
})
export class EventTypeFieldOptionService extends FieldOptionService {
    config: CustomEventsConfig;
    defaultRule: Rule;

    get defaultEventTypeFields() {
        return {
            ...this.getDefaultColumn('Start Time', 'datetime', 'start'),
            ...this.getDefaultColumn('End Time', 'datetime', 'end'),
            ...this.getDefaultColumn('Created On', 'datetime', 'created_on'),
            ...this.getDefaultColumn('Changed By', 'string', 'changed_by')
        };
    }


    constructor(public customEventsService: CustomEventsService,
                private queryBuilderFormService:QueryBuilderFormService) {
        super(customEventsService);
    }

    loadEventType(config: CustomEventsConfig, modelTypeId: ModelID, isQueryBuilder:boolean = true): Observable<FieldMap> {
        this.config = config;
        if (!modelTypeId ) {
            return of(null);
        }
        this.selectedColsDict = {};
        this.config.columns.forEach(col => this.selectedColsDict[col.id] = col);

        this.defaultRule = new Rule(this.defaultEventTypeFields['start'].value,
            this.queryBuilderFormService.operatorMap[this.defaultEventTypeFields['start'].type][0]);
        this.queryBuilderFormService.defaultRuleChanged.next(this.defaultRule);

        return this.getEventTypeFields(modelTypeId, this.defaultEventTypeFields, this.config.columns);
    }

}
