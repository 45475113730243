<div class="column series-table-form">
  <div class="row center">
    <ng-container *ngIf="config.process">
      <select-search-component
          [value]="selected_process"
          [classes]="'fill'"
          [api_model]="'process_light'"
          (selectionChange)="selected_process = $event.value;selectionChange($event, 'process')"
          [label]="'Select a process'"
          class="select-search">
      </select-search-component>
    </ng-container>
    <ng-container *ngIf="config?.series_list">
      <select-many-search-component
          [value]="selected_series"
          [classes]="'fill'"
          [api_model]="'series_light'"
          (selectionChange)="selected_series = $event;selectionChange($event, 'series')"
          [label]="'or Select a series'"
          class="select-search">
      </select-many-search-component>
    </ng-container>

  </div>
  <div class="row center">
    <mat-form-field class="flex-half">
      <mat-label>KPI level</mat-label>
      <mat-select multiple [(value)]="config.kpis">
        <mat-option *ngFor="let column of seriesData.KPI_levels" [value]="column">
          {{column| upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="row center flex-half">
      <mat-checkbox [(ngModel)]="config.constrain_width" class="form-element-align"
                    matTooltip="Table will be constrained to 100% of the width of the tile.
                  Columns can only be resized within this width (up to a point), but 'fit_content' will override this setting where applicable."
      >Constrain table width to 100%
      </mat-checkbox>
      <mat-checkbox class="form-element-align"
                    [(ngModel)]="config.adjust_row_height"
                    [ngModelOptions]="{standalone:true}">Increase row height for widescreen
      </mat-checkbox>
    </div>
  </div>

  <h3 style="font-size:1.33rem;">Series table columns</h3>
  <series-summary-column-selector [config]="config" [allowReverseFavourability]="true"></series-summary-column-selector>


</div>
