import {
    Component,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
    ViewEncapsulation, ViewChild, AfterViewInit, Input
} from "@angular/core";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {TileDataService, TileParameters} from "../../services/tile_data.service";
import {HandleError, HttpErrorHandler} from "../../services/http-error-handler.service";
import {Observable, of, Subject} from "rxjs";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {PrintoutComponentType} from "../../_models/printout-component-type";
import {PrintoutMapperFormComponent} from "../../forms/printout-mapper-form/printout-mapper-form.component";
import {forkJoin} from "rxjs";
import {concatMap, first, map, take, takeUntil, tap} from "rxjs/operators";
import {ConstantProperty} from '../../_models/constant-property';
import {ComponentType} from '../../_models/component-type';
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ListResponse} from "../../services/api/response-types";
import {IDMap, KeyMap, ModelID} from "../../_typing/generic-types";
import {deepCopy, filterUnfetchedIds, uniqueList} from "../../lib/utils";
import {SMALL_TABLE_PAGE_SIZE, TABLE_PAGE_SIZE_OPTIONS} from "../../shared/globals";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {NotificationService} from "../../services/notification.service";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Component({
    selector: 'printout-mapper',
    templateUrl: 'printout-mapper.component.html',
    styleUrls: ['printout-mapper.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PrintoutMapperComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private readonly handleError: HandleError;

    @Input() config: TileParameters;

    dataSource: PaginationDataSource<PrintoutComponentType>;
    filterString = "";

    columns: string[] = ['printout_name', 'component_type_name', 'conditions'];
    columnDict: KeyMap<string> = {
        printout_name: 'Printout',
        component_type_name: 'Component Type',
        conditions: 'Conditions',
        delete: 'Delete'
    };

    buttons: { name: string; func: any; params: {}; class: string; HoverOverHint: string; }[];

    componentTypes: ComponentType[] = [];
    ctDict: IDMap<ComponentType> = {};
    constantProperties: ConstantProperty[] = [];
    cpDict: IDMap<ConstantProperty> = {};
    printouts: any[] = [];
    printoutComponentTypes: any[] = [];
    printoutComponentTypeFlatten: any = {}

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public appScope: AppScope,
                public api: ApiService,
                private changeDetectorRef: ChangeDetectorRef,
                public tileDataService: TileDataService,
                httpErrorHandler: HttpErrorHandler,
                private dialog: MatDialog,
                private propertyData: ConstantPropertyDataService,
                private componentTypeData: ComponentTypeDataService,
                private notification: NotificationService) {
        this.handleError = httpErrorHandler.createHandleError('HeroesService');

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    ngOnInit(): void {
        this.setButtons();
        if(this.config.allow_delete && !this.columns.includes('delete')){
            this.columns.push('delete');
        }
    }

    ngAfterViewInit() {
        const initialQuery: SearchQueryOptions = this.getInitialSearchOptions();
        this.paginator.pageSize = SMALL_TABLE_PAGE_SIZE;
        this.paginator.pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
        this.dataSource = new PaginationDataSource<PrintoutComponentType>(
            (query: SearchQueryOptions) => this.pagePrintouts(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            concatMap(results => {
                this.printoutComponentTypes = results.data;

                //Get all cps and cts for the conditions in these results
                const cpIds = this.getConditionSearchOptions('constant_property');
                const $constant_properties = cpIds.length ?
                    this.propertyData.getConstantProperties(cpIds).pipe(
                        tap(response => {
                            this.constantProperties = response.data;
                            response.data.forEach(cp => this.cpDict[cp.id] = cp)
                        })
                    ) : of([]);

                const ctIds = this.getConditionSearchOptions('component_type');
                const $component_types = ctIds.length ?
                    this.componentTypeData.getComponentTypes(ctIds).pipe(
                        tap(response => {
                            this.componentTypes = response.data;
                            response.data.forEach(ct => this.ctDict[ct.id] = ct);
                        })
                    ) : of([]);

                return forkJoin([$constant_properties, $component_types]).pipe(
                    tap(() => {
                            this.printoutComponentTypes?.forEach(printout => {
                                this.printoutComponentTypeFlatten[printout.id] = this.flattenComponentConditions(printout.attributes.conditions);
                            })
                        }
                    ), take(1))
            }), takeUntil(this.onDestroy)).subscribe()
    }

    getInitialSearchOptions(): SearchQueryOptions {
        const options = new SearchQueryOptions();
        options.filters = this.gePrintoutFilters();
        options.page_number = 1;
        return options;
    }

    pagePrintouts(options: SearchQueryOptions): Observable<ListResponse<PrintoutComponentType>> {
        return this.api.printout_component_type.searchMany(options)
            .pipe(map(listResponse => {
                return listResponse;
            }));
    }

    gePrintoutFilters() {
        return [];
    }

    updateSearchFilter() {
        let filters: any = this.gePrintoutFilters();
        if (!this.filterString) {
            this.dataSource.filterBy(filters);
            return;
        }
        filters = filters.concat(this.getSearchFilters(this.filterString));
        this.dataSource.filterBy(filters);
    }

    updateSort(event) {
        const allowed = ['component_type_name'];
        if (!allowed.includes(this.sort.active)) return;
        this.dataSource.sortBy(this.sort)
    }

    private getSearchFilters(filterString) {
        const filters = {or: []};
        filters.or.push({name: 'component_type_name', op: 'ilike', val: `%${filterString}%`});

        //Removed until all templates have been migrated
        //filters.or.push({name: 'printout_name', op: 'ilike', val: `%${filterString}%`});
        return filters;
    }

    setButtons() {
        const ctrl = this;

        ctrl.buttons = [
            {
                name: 'Add Mapping',
                func: () => ctrl.addEditMapper(),
                params: {},
                class: 'fa small fa-plus hide-xs',
                HoverOverHint: 'Add Mapping'
            }
        ];

        ctrl.tileDataService.buttonsChanged.next(ctrl.buttons);
    }

    flattenComponentConditions(filter, base = ''): any {
        let flat = {}
        for (let key in filter) {
            let name = base + ' ' + key;
            let match = key.match(/\[(.+?)@(.+?)\]/);
            if (match) {
                if (match[1] === 'component_type') {
                    if (this.ctDict[match[2]]) {
                        name = base + ' [ComponentType@' + this.ctDict[match[2]].attributes.name + ']';
                    }
                    flat[name] = filter[key].is_set;
                    Object.assign(flat, this.flattenComponentConditions(filter[key].conditions, name))
                } else if (match[1] == 'constant_property') {
                    if (this.cpDict[match[2]]) {
                        name = base + ' [ConstantProperty@' + this.cpDict[match[2]].attributes.name + ']';
                    }
                    flat[name] = filter[key]
                }
            } else {
                flat[name] = filter[key]
            }
        }
        return flat;
    }

    /**These match the ids in the conditions before calling the relevant apis so only the necessary objects are fetched**/
    private getConditionSearchOptions(type: 'component_type' | 'constant_property'): ModelID[] {
        let ids: ModelID[] = [];
        this.printoutComponentTypes.forEach(printout => {
            this.iterateThroughConditions(printout.attributes.conditions, ids, type);
        })
        ids = filterUnfetchedIds(uniqueList(ids), (type === 'component_type' ? this.ctDict : this.cpDict));

        return ids;
    }

    private iterateThroughConditions(conditionKeys, ids: ModelID[], type: 'component_type' | 'constant_property'): void {
        for (let key in conditionKeys) {
            if (conditionKeys[key].hasOwnProperty("conditions")) {
                this.iterateThroughConditions(conditionKeys[key].conditions, ids, type);
            }
            let match = key.match(/\[(.+?)@(.+?)\]/);
            if (match) {
                if (match[1] === type) {
                    ids.push(match[2]);
                }
            }
        }
    }

    addEditMapper(printout_component_type?) {
        const dialogRef = this.upsertMapping(printout_component_type);
        dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
                if (!printout_component_type) {
                    this.printoutComponentTypes.push(result);
                }
                this.dataSource.data = this.printoutComponentTypes;
                this.printoutComponentTypeFlatten[result.id] = this.flattenComponentConditions(result.attributes.conditions)
                this.changeDetectorRef.markForCheck();
            } else {
            }
        });
    }


    upsertMapping(printout_component_type?): MatDialogRef<PrintoutMapperFormComponent, any> {
        printout_component_type = printout_component_type ? printout_component_type : new PrintoutComponentType();
        const dialogConfig = new MatDialogConfig();

        dialogConfig.panelClass = ['default-form-dialog', 'series-form-dialog'];
        dialogConfig.data = {
            printout_component_type,
            printouts: this.printoutComponentTypes,
            component_types: this.componentTypes,
            component_types_map: this.ctDict
        };

        return this.dialog.open(PrintoutMapperFormComponent, dialogConfig);

    };

    deleteMapper(item) {
        this.notification.openConfirm('Are you sure you want to delete this mapping?', 'No', "Yes, I'm sure")
            .onAction().pipe(
            concatMap(() => {
                return this.api.printout_component_type.obsDelete(item.id).pipe(tap(this.updateSearchFilter.bind(this)))
            }), take(1)
        ).subscribe();
    }
}
