import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {GridItem, TileDataService, TileParameters} from "../../services/tile_data.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EventService} from "../../services/event.service";
import {PageTileFormComponent} from "../../forms/page-tile-form/page-tile-form";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Subject, Subscription, from, of} from "rxjs";
import {catchError, concatMap, filter, first, take, takeUntil, tap} from "rxjs/operators";
import {ChartViewModel} from '../../lib/flowchart/flowchart_viewmodel';
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {AppScope} from "../../services/app_scope.service";
import * as utils from "../../lib/utils";
import {ParagraphFormComponent} from '../../forms/paragraph-form/paragraph-form.component';
import {FlowchartData} from "../../_models/flowchart-data";
import {ChartViewModelParameters} from "../../lib/flowchart/types/chart-view-model-parameters";
import {Tile as TileModel} from "../../_models/tile";
import {environment} from "../../environments/environment";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {UserService} from "../../services/user.service";
import {NotificationService} from "../../services/notification.service";
import {
    CustomHtmlTextFormComponent
} from "../../forms/custom-html-form/custom-html-text-form/custom-html-text-form.component";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    selector: 'page-tile',
    templateUrl: 'page-tile.component.html',
    providers: [TileDataService, EventService, DateTimeInstanceService],
    encapsulation: ViewEncapsulation.None,
    host: {
        // TODO do we really want this to listen for a click anywhere on the document. Trying to be conservative for the
        //  amount of event listeners for clicks.
        '(document:click)': 'windowClick($event)', //This gets destroyed when component using this is destroyed
    },
    standalone: false
})

export class PageTileComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    //TODO nested component?
    @Input() tile: TileModel;
    @Input() grid_item: GridItem;
    @Input('dtp') parent_dtp: IDateTimePeriod; //The dtp passed in from the parent
    @Input() current_session_id: string; //Id of the session_state where the tile is being displayed (since its a MtoM relationship)

    @Output() tileChange: EventEmitter<{ tile: TileModel }> = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() removeMissingTile: EventEmitter<void> = new EventEmitter();

    @Input() tile_id: string;

    public title: string;
    public sub_title: string;
    $title: Subscription;
    $sub_title: Subscription;
    //public show_drawer: boolean = false;

    show_date_picker: boolean = false;
    hasOwnTitle: boolean = false;
    buttons: ITileButton[];
    editing: boolean; //Tile is being edited (e.g. column widths)
    edit_mode: boolean; //Parent page is in Edit Mode (layout)
    pivotEditing: boolean = false;
    modelPivotEditing: boolean = false;
    current_user: any;

    menuVisible: boolean = false;
    flowchart: ChartViewModel;
    flowchartReady: boolean = false;
    selected_flowchart_components: any[] = [];
    enableContext: boolean = true; //determines whether user can see flowchart context menu (can be used for permission checking later)
    show_no_data = '';
    scrollbarOptions: any;

    tile_ready = false;
    private context_menu: ElementRef;
    private buildFlowchartSubscription: Subscription;

    constructor(public api: ApiService,
                public userService: UserService,
                public tileData: TileDataService,
                public plantData: PlantDataService,
                public headerData: HeaderDataService,
                public eventService: EventService,
                public dialog: MatDialog,
                public dateTimePeriodService: DateTimePeriodService,
                public dateInst: DateTimeInstanceService,
                private appScope: AppScope,
                private renderer: Renderer2,
                private notification: NotificationService
    ) {
        this.tileData.editing.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            this.editing = newBool;
        });
        this.tileData.noTileData.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.show_no_data = value;
        })
    }

    //This gets set when the element is within an *ngIf and ngIf becomes true
    @ViewChild('context_menu') set content(content: ElementRef) {
        this.context_menu = content;
    }

    ngOnInit(): void {
        const ctrl = this;
        this.current_user = this.appScope.current_user;

        if (this.tile_id) {
            this.api.tile.getById(this.tile_id).pipe(
                first(), takeUntil(this.onDestroy),
                tap(result => {
                    this.tile = result.data;
                    this.loadTile();
                }), catchError(e => {
                    if (e.status === 404) {
                        this.removeMissingTile.next();
                        console.log(`Tile with id ${this.tile_id} was missing and has been removed from this page`)
                    }
                    return of(e);
                })).subscribe();

        } else if (this.tile) {
            //This will generally be used by pseudo tile created by views and insight tools
            this.tile_id = utils.deepCopy(utils.guid());
            this.tile.id = this.tile_id;
            this.loadTile();
        }
    }

    loadTile() {
        const ctrl = this;
        this.tileData.id = this.tile_id;
        this.tileData.tile = this.tile;

        this.tileSubscriptions();

        if (this.tile.attributes.content === 'flowchart') {
            this.buildFlowchart(this.tile.attributes.parameters);
        }

        this.setTitle();
        this.updateScrollbarOptions();
        this.tile_ready = true;
    }

    tileSubscriptions() {
        const ctrl = this;
        this.tileData.tileChange.pipe(takeUntil(this.onDestroy)).subscribe(tile => {
            this.tile.attributes.parameters = tile.attributes.parameters;
            this.saveTile(this.tile)
        });

        this.headerData.showingCommentPanel.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            //Used for styling the comment button to show which tile is currently selected for commenting
            if (value.comment_data?.tileData?.tile && value.comment_data.tileData.tile.id === this.tile.id) {
                this.tileData.selected = true;
            } else {
                this.tileData.selected = false;
            }
        })

        this.headerData.page_edit_toggle.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.edit_mode = value;
            if (value === false) {
                this.tileData.setEditing(false);
            }
        })

        this.headerData.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            let tileDtp: IDateTimePeriod = utils.deepCopy(this.dateInst.dtp);
            if (this.tile.attributes.custom_dtp !== true || this.tile.attributes.relative_dtp) {
                tileDtp = utils.deepCopy(dtp);
                if (this.tile.attributes.relative_dtp) {
                    tileDtp = ctrl.dateTimePeriodService.getRelativeDTP(ctrl.tile.attributes.relative_dtp);
                }
            }
            //Emit refresh to child components
            this.dateInst.emitDateTimePeriodRefreshed(tileDtp);
        });

        this.headerData.dtpChanged.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            if (this.tile.attributes.custom_dtp !== true) {
                this.dateInst.emitDateTimePeriodChanged(dtp);
            }
        });

        this.dateTimePeriodService.dtpInitialised$.pipe(
            take(1),
            tap((dtp: IDateTimePeriod) => {
                this.setDateInst();
            })).subscribe();
    }

    setTitle() {
        if ((this.tile.attributes.parameters && this.tile.attributes.parameters.title) || this.tile.attributes.title) {
            if (this.tile.attributes.title) {
                setTimeout(() => this.title = this.tile.attributes.title);
            } else if (this.tile.attributes.parameters.title) {
                setTimeout(() => this.title = this.tile.attributes.parameters.title);
            }
            this.hasOwnTitle = true;
        } else {
            this.$title = utils.refreshSubscription(this.$title);
            this.$sub_title = utils.refreshSubscription(this.$sub_title);
            this.$title = this.tileData.tileTitle.pipe(takeUntil(this.onDestroy)).subscribe(tileTitle => {
                setTimeout(() => this.title = this.tile.attributes.title ? this.tile.attributes.title : tileTitle);
            });
            this.$sub_title = this.tileData.tileSubtitle.pipe(takeUntil(this.onDestroy)).subscribe(tileSubtitle => {
                setTimeout(() => this.sub_title = this.tile.attributes.title ? this.tile.attributes.title : tileSubtitle);
            });
        }
    }

    updateScrollbarOptions() {
        this.scrollbarOptions = this.tile.attributes?.hide_scroll
            ? {scrollbars: {autoHide: 'never', visibility: 'hidden'}}
            : {scrollbars: {autoHide: 'leave'}};
    }

    setDateInst(): void {
        let dtp = utils.deepCopy(this.parent_dtp) || this.dateInst.dtp;
        if (this.tile.attributes.custom_dtp === true) {
            if (this.tile.attributes.relative_dtp) {
                dtp = this.dateTimePeriodService.getRelativeDTP(this.tile.attributes.relative_dtp);
            } else {
                dtp = this.dateTimePeriodService.constructDtp(this.tile.attributes);
            }
        }
        this.dateInst.emitDateTimePeriodChanged(dtp);
    }

    windowClick(event) {
        if (this.menuVisible === true) {
            this.menuVisible = false;
        }
    }

    ngAfterViewInit(): void {
        const ctrl = this;
        this.tileData.buttonsChanged.pipe(takeUntil(this.onDestroy)).subscribe(new_buttons => {
            setTimeout(() => this.buttons = new_buttons);
        });
    }

    toggleComments() {
        if (this.headerData.showing_comments && this.tileData.selected === true) {
            this.headerData.toggleCommentPanel(false, this);
        } else {
            this.headerData.toggleCommentPanel(true, this);
        }
    }

    selectCustomDtp() {
        //For inline changes to tile dtp. This doesn't get saved.
        const ctrl = this;
        if (ctrl.dateInst.dtp.range === 'custom') {
            ctrl.show_date_picker = true;
        } else {
            const dtp = ctrl.dateTimePeriodService.getDTP(ctrl.dateInst.dtp.range, false, ctrl.dateInst.dtp.calendar)
            ctrl.dateInst.emitDateTimePeriodChanged(dtp);
        }
    }

    useCustomDtp() {
        this.show_date_picker = false;
    }

    onCloseDtpSelector(): void {
        //Reset to custom tile dtp if closing without selecting (selector only enabled when tile.custom_dtp===true)
        this.dateInst.emitDateTimePeriodChanged(this.dateTimePeriodService.constructDtp(this.tile.attributes));
        this.show_date_picker = false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    allowEdit(tile_content) {
        //Using a switch here because I'm sure we're going to be adding on editing permissions coming up
        switch (tile_content) {
            case 'custom':
                return this.userService.isAdminUser();
                break;
            default:
                return true;
        }
    }

    editConditional() {
        if (!environment.production || this.userService.isMMSUser()) {
            this.openTileFormDialog();
        }
    }

    openTileFormDialog(tab = ''): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            tile: this.tile,
            title: this.title,
            dtp: this.dateInst.dtp,
            tab: tab,
            grid_parameters: {is_title: this.grid_item?.is_title},
            session_id: this.current_session_id
        };

        dialogConfig.panelClass = ['tile-form-dialog', 'tile-form-dialog-' + this.tile.attributes.content];
        const dialogRef = this.dialog.open(PageTileFormComponent, dialogConfig);
        this.appScope.closeDialogOnNavigate(this.dialog, dialogRef);

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.tile) {
                this.tile_ready = false;
                //Tile saved in form
                setTimeout(() => {
                    this.tile = result.tile;
                    this.setTitle();
                    this.setDateInst();
                    this.tileData.tile = this.tile;
                    if (this.tile.attributes.content === 'flowchart') {
                        this.buildFlowchart(this.tile.attributes.parameters);
                    }
                    this.tile_ready = true;
                })
                //this.tileChange.emit({'tile': this.tile});
            }
        });
    }

    openTextFormDialog(tile_content): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            config: this.tile.attributes.parameters,
        };
        let dialogRef;
        if (tile_content === 'custom') {
            dialogConfig.panelClass = ['default-form-dialog', 'custom-html-form-dialog'];
            dialogRef = this.dialog.open(CustomHtmlTextFormComponent, dialogConfig);
        } else {
            dialogConfig.panelClass = ['default-form-dialog', 'paragraph-form-dialog'];
            dialogRef = this.dialog.open(ParagraphFormComponent, dialogConfig);
        }

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tile.attributes.parameters = result;
                this.saveContent(this.tile);
            }
        });
    }

    saveContent(tile: TileModel, save = true) {
        //Used by paragraph, custom html and series-table (tiles that can have their content edited from the tile menu options)
        const ctrl = this;
        this.tileData.save_content.next(tile.attributes.parameters); //- goes to child to update trustedHTML
        if (save === true) {
            this.saveTile(tile);
        }
    }

    saveState(tile_parameters: TileParameters) {
        //Used by pivot tiles
        const ctrl = this;
        ctrl.tile.attributes.parameters = tile_parameters;
        this.saveTile(ctrl.tile);
    }

    saveTile(tile: TileModel) {
        this.tileData.saveTile(tile).pipe(take(1)).subscribe(result => {
            this.notification.openSuccess("Tile saved")
        }, (err) => {
            console.log('ERROR: PageTileComponent (saveTile) - Tile Not Saved: ', err);
        })
    }

    deleteTile() {
        this.tileData.confirmDeleteTile(this.tile).pipe(
            concatMap(() => {
                return this.tileData.deleteTile(this.tile.id).pipe(
                    first(), takeUntil(this.onDestroy),
                    catchError(error => {
                        if (error.status === 404) {
                            //If the tile didn't exist, make sure tile is still deleted from section and relationships is deleted from session
                            this.delete.emit(this.tile);
                        }
                        return error;
                    }))
            })).subscribe(() => {
            this.delete.emit(this.tile);
        });
    }

    buildFlowchart(parameters: ChartViewModelParameters) {
        const ctrl = this;
        ctrl.flowchartReady = false;
        this.tileData.noTileData.next('');

        let $chartDataModel = ctrl.plantData.getFlowchartDataForProcess(parameters.process.id);
        let $permissions = ctrl.plantData.getProcessPermissions(parameters.process.id);

        if (this.buildFlowchartSubscription) {
            this.buildFlowchartSubscription.unsubscribe();
            this.buildFlowchartSubscription = null;
        }

        this.buildFlowchartSubscription = $permissions.pipe(
            tap(result => {
                if (!(result.permissions['edit_process_data'] || result.permissions['view_process_data'])) {
                    this.tileData.noTileData.next({message: 'You do not have permissions to view this process.'});
                }
            }),
            filter(result => result.permissions && (result.permissions['edit_process_data'] || result.permissions['view_process_data'])),
            concatMap(() => $chartDataModel),
            takeUntil(this.onDestroy),
        ).subscribe(response => {
            const chartDataModel: FlowchartData = response;

            chartDataModel.parent_process = chartDataModel.process_focus;
            ctrl.flowchart = new ChartViewModel(chartDataModel, parameters);
            ctrl.selected_flowchart_components = [ctrl.flowchart['parent_process']];
            ctrl.flowchartReady = true;
            ctrl.plantData.getSeriesSummary(chartDataModel, ctrl.dateInst.dtp);
        });
    }

    changeProcess(process) {
        let new_params = utils.deepCopy(this.tile.attributes.parameters);
        new_params.process = utils.deepCopy(process.data);
        this.buildFlowchart(new_params);
    }

    openFlowchart(process) {
        this.menuVisible = false;
        window.open('view/flowchart/' + process.id, "_blank");
    }

    contextMenu(e) { //emitted event
        let fc_tile: Element = e.event.target.closest(".tile-content");
        const boundingRect = fc_tile.getBoundingClientRect();
        const x = e.event.clientX - boundingRect.left;
        const y = e.event.clientY - boundingRect.top + window.scrollY + 40;
        if (this.enableContext && e.element.data.type === 'process') {
            e.event.preventDefault();
            this.selected_flowchart_components = [e.element];
            const origin = {
                left: x,
                top: y   //Y relative to first positioned element which is the tile, + tile-header height
            };
            this.setPosition(origin);
            return false;
        } else {
            this.menuVisible = false;
        }
    }

    setPosition({top, left}) {
        this.renderer.setStyle(this.context_menu.nativeElement, 'left', `${left}px`);
        this.renderer.setStyle(this.context_menu.nativeElement, 'top', `${top}px`);
        this.menuVisible = true;
    }

    hideCommentButton(content) {
        return ['default-chart', 'custom-chart', 'budget-chart', 'budget-bar-chart', 'comparison-chart', 'category-chart', 'input-sheet',
            'log-sheet', 'series-table', 'spc-chart'].includes(content)

    }
}
