import {BaseViewModel} from "./base-viewmodel";

export class TextViewModel extends BaseViewModel {
    current_rotate: number = 0;

    constructor(textData) {
        super(textData);
        this.current_rotate = this.data.rotate;
    }

    public rotate() {
        this.current_rotate = this.data.rotate;
        return this.data.rotate
    };

    //rotate working relative to window..think it has to do with cdkDrag hijacking the transform property
    x() {
        if (this.current_rotate == 90) {
            return this.data.y
        }
        if (this.current_rotate == 180) {
            return this.data.x * -1
        }
        if (this.current_rotate == 270) {
            return this.data.y * -1
        }
        return this.data.x
    };

    y() {
        if (this.current_rotate == 90) {
            return this.data.x * -1
        }
        if (this.current_rotate == 180) {
            return this.data.y * -1
        }
        if (this.current_rotate == 270) {
            return this.data.x
        }
        return this.data.y
    };

    height() {
        //if (this.data.h) {
        return this.data.h
        //}
    };

    width() {
        //if (this.data.w) {
        return this.data.w
        //}
    };

};
