<div class="lock-template-table">
  <div class="row"
       style="justify-content: space-between;  border: 2px solid #ccc;  padding-left: 4px; border-bottom: none;  background: white;">
    <utils-bar [search]="true"
               (searchValueSubmitted)="filter_string=$event;applyFilter.next(filter_string);"
               (searchCleared)="filter_string='';applyFilter.next(filter_string);">
    </utils-bar>
    <mat-paginator [length]="cps.total"
                   showFirstLastButtons
                   style="justify-self: flex-end;">
    </mat-paginator>
  </div>


  <div class="table-container">
    <table [dataSource]="dataSource"
           [matSortActive]="'name'"
           (matSortChange)="updateSort($event)"
           class="table simple-table"
           mat-table matSort matSortDirection="asc">

      <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
        <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
          <ng-container *ngIf="column === 'edit'"></ng-container>
          <ng-container *ngIf="column !== 'edit'">{{column | upperfirst | removeUnderscore}}</ng-container>
        </th>
        <td *matCellDef="let template;"
            mat-cell
            style="text-align: left;border:none;">
          <ng-container *ngIf="column === 'edit'">
            <i (click)="openDialog(template)" class="fa fa-edit" style="cursor:pointer;"></i>
          </ng-container>

          <ng-container *ngIf="column==='attributes'">
            <div *ngFor="let att of template?.attributes?.definition?.attributes">
              {{att|capitalise}}
            </div>
          </ng-container>

          <ng-container *ngIf="column==='constant_properties'">
            <div *ngFor="let prop_id of template?.attributes?.definition?.constant_properties">
              {{constant_property_dict[prop_id]?.attributes.name}}
            </div>
          </ng-container>

          <ng-container *ngIf="column==='event_type'">
            {{event_type_dict[template.relationships?.event_type?.data?.id]?.attributes.name}}
          </ng-container>

          <ng-container *ngIf="column==='component_type'">
            {{component_type_dict[template.relationships?.component_type?.data?.id]?.attributes.name}}
          </ng-container>

          <ng-container
              *ngIf="['created_on', 'changed_on'].includes(column)">{{template.attributes[column] | stringDate}}
          </ng-container>
          <ng-container
              *ngIf="['name','description','created_by_name', 'changed_by_name', 'account_name'].includes(column)">{{template.attributes[column]}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container>
        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </ng-container>
    </table>
  </div>
</div>
