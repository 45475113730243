import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModelUploadOptions} from "../upload-model-form-dialog.component";
import {BehaviorSubject, combineLatest, ReplaySubject, Subject} from "rxjs";
import {Papa} from "ngx-papaparse";
import {takeUntil} from "rxjs/operators";
import {ComponentModelUploadDataService} from "./component-model-upload-data.service";
import {ConstantProperty} from "../../../_models/constant-property";
import {UploadModelTemplateService} from "../upload-model-template.service";
import {IUnmatchedHeadersType} from "../../../_typing/headers";

type ComponentModelUpsertType = 'by_name';

export class ComponentModelOptions extends ModelUploadOptions {
    update_only: boolean = true;
    // parser and file option fields
    upsert_type: ComponentModelUpsertType = 'by_name';
    component_type: string;
    use_column_indexes: boolean = false;
    skip_rows: number = 0;

    // fields associated for headers
    start_date_header: string;
    end_date_header: string;
    name_header: string;
    property_headers: string[][] = [];
    component_type_headers: string[][] = [];
}

class HeaderType {
    // NOTE: renaming any of these values will break templates that use them!
    // TODO add order to these (ie. can change the order in the options list)

    // Header keys

    // single use headers
    public readonly START_DATE: string = 'start-date';
    public readonly END_DATE: string = 'end-date';

    // multiple use headers
    public readonly UNUSED: string = 'ignore';
    public readonly PROPERTY: string = 'property';
    public readonly COMPONENT: string = 'component';
    public readonly NAME: string = 'name';

    public static descriptions(): { [key: string]: string } {
        const ht = new HeaderType();
        const d: any = {};
        d[ht.START_DATE] = 'Start Date';
        d[ht.END_DATE] = 'End Date';

        d[ht.UNUSED] = 'Ignore this column';
        d[ht.PROPERTY] = 'Property';
        d[ht.COMPONENT] = 'Component';
        d[ht.NAME] = 'Name';
        return d;
    }
}

export class HeaderAssociation {
    // csv column name
    source: string;
    // target feature type
    target_type: string;
    // id associated with the given target type (eg. ComponentType or ConstantProperty id )
    override_id: string | null;
}

@Component({
    selector: 'extract-component-options',
    templateUrl: './extract-component-options.component.html',
    styleUrls: ['./extract-component-options.component.less'],
    providers: [ComponentModelUploadDataService],
    standalone: false
})
export class ExtractComponentOptionsComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    // instantiate this constant class rather make properties static to make it work in the template.
    public readonly HEADER_TYPES = new HeaderType();
    readonly REQUIRED_HEADERS: string[] = [
        this.HEADER_TYPES.NAME,
    ];

    // headers that may at most have one association
    readonly SINGLE_HEADERS: string[] = [
        this.HEADER_TYPES.START_DATE,
        this.HEADER_TYPES.END_DATE,
        this.HEADER_TYPES.NAME,
    ];
    // headers that can have multiple column associations
    readonly MULTI_HEADERS = [
        this.HEADER_TYPES.UNUSED,
        this.HEADER_TYPES.PROPERTY,
        this.HEADER_TYPES.COMPONENT
    ];
    readonly DESCRIPTIONS: { [key: string]: string } = HeaderType.descriptions();

    upsert_types: { name: string, value: ComponentModelUpsertType, disabled?: boolean }[] = [
        {name: 'By Name', value: "by_name"},
    ];

    requires_id = [
        this.HEADER_TYPES.PROPERTY,
        this.HEADER_TYPES.COMPONENT,
    ];

    model_upload_features: string[] = [];

    available_properties: ConstantProperty[] = [];

    header_associations: HeaderAssociation[] = [];
    had_file: boolean = null;
    // List of available Constant Properties. Used for add overrides
    constant_properties: { id: string, name: string }[] = [];
    // actions still required to make configuration valid
    required_configurations: string[] = [];
    error: string = '';
    // subject containing data from the current file
    fileDataSubject: Subject<string[][]> = new ReplaySubject(1);
    // If a template is user to populate the local options, this subject will emit the template options
    templateOptionsSubject: Subject<ComponentModelOptions> = new ReplaySubject(1);
    // Emits when changes are made to the local options
    localOptionsSubject: BehaviorSubject<ComponentModelOptions>;
    // If either the template or local options has changed
    finalOptionsSubject: Subject<ComponentModelOptions> = new Subject<ComponentModelOptions>();
    displayOptions: ComponentModelOptions;
    // these two properties are used for changing the order of select Ore Body Group Type
    ore_body_group_types: { id: string, name: string }[] = [];
    ore_body_group_type_ids: { [id: string]: string } = {};
    component_types: { id: string, name: string }[] = [];

    @Output()
    preview: EventEmitter<any> = new EventEmitter<any>();

    constructor(private papa: Papa,
                private templateService: UploadModelTemplateService,
                public dataService: ComponentModelUploadDataService) {
        this.model_upload_features = this.MULTI_HEADERS.concat(this.SINGLE_HEADERS);
        this.localOptionsSubject = new BehaviorSubject<ComponentModelOptions>(new ComponentModelOptions());
        this.displayOptions = this.localOptionsSubject.getValue();
        this.templateService.templateChanged$.pipe(takeUntil(this.onDestroy)).subscribe(options => {
            this.templateOptionsSubject.next(options.attributes.json as ComponentModelOptions);
        });
    }

    @Input() readonly = false;

    @Input()
    set file(file) {
        if (!file) {
            if (this.had_file) {
                this.fileDataSubject.next(file);
            }
            this.had_file = false;
            return;
        }

        this.papa.parse(file, {
            worker: true,
            preview: 50,
            complete: results => {
                try {
                    let data = results.data;
                    const previewData = data.slice(0, 5);
                    this.preview.next(previewData);
                    this.fileDataSubject.next(data);
                    this.had_file = true;
                } catch {
                    this.preview.next(null);
                    this.error = 'Failed to parse the file. Please provide a valid csv file.';
                }
            },
            error: error => {
                console.warn('error parsing file\n', error);
            }
        });
    }

    ngOnInit(): void {
        const templateRefresh$ = [];
        templateRefresh$.push(this.templateOptionsSubject);
        templateRefresh$.push(this.fileDataSubject);

        combineLatest(templateRefresh$).pipe(takeUntil(this.onDestroy)).subscribe((r: any) => {
            let options: ComponentModelOptions = r[0] as ComponentModelOptions;
            let file_data: string[][] = r[1] as string[][];

            if (!options) {
                options = new ComponentModelOptions();
            }
            if (options.component_type) {
                this.dataService.changeComponentType(options.component_type);
            }

            let headers: string[] = this.getFileHeadersForOptions(file_data, options);
            this.header_associations = this.generateHeaderAssociations(options, headers);

            this.localOptionsSubject.next(options);

        });

        const localRefresh$ = [];
        localRefresh$.push(this.localOptionsSubject);
        localRefresh$.push(this.fileDataSubject);

        combineLatest(localRefresh$).pipe(takeUntil(this.onDestroy)).subscribe((r: any) => {
            const options: ComponentModelOptions = r[0] as ComponentModelOptions;
            let file_data: string[][] = r[1] as string[][];

            let headers: string[] = this.getFileHeadersForOptions(file_data, options);
            const delta_length = Math.abs(this.header_associations.length - headers.length);
            if (this.header_associations.length < headers.length) {
                // Add empty header associations if new file has more columns
                for (let i = 0; i < delta_length; i++) {
                    this.header_associations.push(this.getHeaderAssociationForHeader(''));
                }
            } else if (this.header_associations.length > headers.length) {
                // Remove extra header associations if new file has fewer columns
                this.header_associations.splice(headers.length, delta_length);
            }

            // Change the source header to match the current options
            headers.forEach((header, i) => {
                const ha = this.header_associations[i];
                ha.source = header;
            });

            this.finalOptionsSubject.next(options);
        });

        this.finalOptionsSubject.pipe(takeUntil(this.onDestroy)).subscribe(options => {
            this.setOptionsHeaderFields(this.header_associations, options);

            const selected_cp_ids = options.property_headers.map(ph => ph[1]).filter(h => !!h);

            this.displayOptions = options;
            this.dataService.options$.next(options);
            if (this.isOptionsValid(options)) {
                this.templateService.updateWIPTemplate(options);
            } else {
                this.templateService.updateWIPTemplate(null);
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setOverride(header: HeaderAssociation, change) {
        header.override_id = change;
        const options = this.localOptionsSubject.getValue();
        this.localOptionsSubject.next(options);
    }

    setHeaderTarget(header: HeaderAssociation, $event) {
        header.target_type = $event.value;
        header.override_id = null;
        const options = this.localOptionsSubject.getValue();
        this.localOptionsSubject.next(options);
    }

    setUseColumnIndexes(use_column_indexes: boolean) {
        const options = this.localOptionsSubject.getValue();
        options.use_column_indexes = !!use_column_indexes;
        this.localOptionsSubject.next(options);
    }

    setSkipRows(skip_rows: number) {
        const options = this.localOptionsSubject.getValue();
        options.skip_rows = skip_rows;
        this.localOptionsSubject.next(options);
    }

    setUpdateOnly(update_only: boolean) {
        const options = this.localOptionsSubject.getValue();
        options.update_only = update_only;
        this.localOptionsSubject.next(options);
    }

    setComponentType(component_type_id: string) {
        const options = this.localOptionsSubject.getValue();
        options.property_headers.forEach(ph => ph[1] = null);
        options.component_type_headers.forEach(cth => cth[1] = null);
        options.component_type = component_type_id;
        this.localOptionsSubject.next(options);
    }

    setUpsertType(upsert_type: ComponentModelUpsertType) {
        const options = this.localOptionsSubject.getValue();
        options.upsert_type = upsert_type;
        this.localOptionsSubject.next(options);
    }

    /**
     * Update options if some of the header_associations have changed.
     */
    updateHeaders() {
        const options = this.localOptionsSubject.getValue();
        this.localOptionsSubject.next(options);
    }

    private getFileHeadersForOptions(file_data: string[][], options: ComponentModelOptions): string[] {
        let headers: string[];
        if (options.skip_rows) {
            file_data = file_data.slice(options.skip_rows);
        }
        if (options.use_column_indexes) {
            // generate the index headers from the max row length
            const max_row = file_data.reduce((max, curr) => max > curr.length ? max : curr.length, 0);
            headers = Array.from(Array(max_row).keys()).map(i => '' + i);
        } else {
            // extract the name headers from the file data
            headers = file_data[0];
        }
        return headers;
    }

    private getHeaderAssociationForHeader(header: string): HeaderAssociation {
        return {
            source: header.trim(),
            target_type: this.HEADER_TYPES.UNUSED,
            override_id: null,
        };
    }

    private generateHeaderAssociations(options: ComponentModelOptions, headers: string[]): HeaderAssociation[] {
        let unmatched: IUnmatchedHeadersType[] = [];
        // first generate unassociated headers
        const header_associations: HeaderAssociation[] = headers.map(header => {
            return this.getHeaderAssociationForHeader(header);
        });

        if (options.property_headers) {
            options.property_headers.forEach(ph => {
                // ph either [source, id] or [source]
                const header = header_associations.find(h => h.source === ph[0]);
                if (header) {
                    header.target_type = this.HEADER_TYPES.PROPERTY;
                    header.override_id = ph[1] ? ph[1] : null;
                } else {
                    unmatched.push({type: 'constant_property', name: ph[0]});
                }
            });
        }
        if (options.component_type_headers) {
            options.component_type_headers.forEach(ct => {
                const header = header_associations.find(h => h.source === ct[0]);
                if (header) {
                    header.target_type = this.HEADER_TYPES.COMPONENT;
                    header.override_id = ct[1] ? ct[1] : null;
                } else {
                    unmatched.push({type: 'component_type', name: ct[0]});
                }
            });
        }


        if (options.start_date_header) {
            const header = header_associations.find(h => h.source === options.start_date_header);
            if (header) {
                header.target_type = this.HEADER_TYPES.START_DATE;
            } else {
                unmatched.push({type: 'attribute', name: 'start_date'});
            }
        }
        if (options.end_date_header) {
            const header = header_associations.find(h => h.source === options.end_date_header);
            if (header) {
                header.target_type = this.HEADER_TYPES.END_DATE;
            } else {
                unmatched.push({type: 'attribute', name: 'end_date'});
            }
        }
        if (options.name_header) {
            const header = header_associations.find(h => h.source === options.name_header);
            if (header) {
                header.target_type = this.HEADER_TYPES.NAME;
            } else {
                unmatched.push({type: 'attribute', name: 'name'});
            }
        }

        if (this.readonly) {
            this.templateService.reportUnmatchedHeaders(unmatched);
        }
        return header_associations;
    }

    /**
     * Evaluate if the options is valid and emit the null or valid options to optionsChange.
     */
    private isOptionsValid(options: ComponentModelOptions): boolean {
        let has_upsert_type = options.upsert_type != null;

        let has_component_type = options.component_type != null;

        let has_name = options.name_header != null;

        this.required_configurations = [];

        if (has_upsert_type &&
            has_name &&
            has_component_type) {
            return true;
        } else {
            if (!has_upsert_type) {
                this.required_configurations.push('Choose the upsert type');
            }
            if (!has_component_type) {
                this.required_configurations.push('Choose the Component Type');
            }
            if (!has_name) {
                this.required_configurations.push('Associate the Name header');
            }
            return false;
        }
    }

    /**
     * Generate the model options from this header associations.
     *
     * All and only features that are assigned using the column features selection should call this method.
     */
    private setOptionsHeaderFields(header_associations: HeaderAssociation[], options: ComponentModelOptions) {
        const all_current_assigned = new Set(header_associations.map(h => h.target_type));

        // extract the start date header
        options.start_date_header = null;
        if (all_current_assigned.has(this.HEADER_TYPES.START_DATE)) {
            options.start_date_header = header_associations
                .find(h => h.target_type === this.HEADER_TYPES.START_DATE).source;
        }
        // extract the end date header
        options.end_date_header = null;
        if (all_current_assigned.has(this.HEADER_TYPES.END_DATE)) {
            options.end_date_header = header_associations
                .find(h => h.target_type === this.HEADER_TYPES.END_DATE).source;
        }

        // extract the name header
        options.name_header = null;
        if (all_current_assigned.has(this.HEADER_TYPES.NAME)) {
            options.name_header = header_associations
                .find(h => h.target_type === this.HEADER_TYPES.NAME).source;
        }

        // extract property headers and optional overrides
        const property_headers = this.header_associations.filter(h => {
            return h.target_type === this.HEADER_TYPES.PROPERTY;
        }).map(h => {
            return [h.source, h.override_id];
        });
        options.property_headers = property_headers;

        const component_headers = this.header_associations.filter(h => {
            return h.target_type === this.HEADER_TYPES.COMPONENT;
        }).map(h => {
            return [h.source, h.override_id];
        });
        options.component_type_headers = component_headers;
    }
}
