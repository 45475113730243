<div class="row min-height">
  <mat-checkbox [(ngModel)]="config.show_title" class="child-tile-form">
    Show chart title
  </mat-checkbox>
</div>
<div>
  <div class="row full-height">
    <mat-form-field>
      <mat-label>Chart Title</mat-label>
      <input [(ngModel)]="config.labels.title" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Chart Type</mat-label>
      <mat-select [(value)]="config.chart_type" (selectionChange)="updateChartType($event)">
        <mat-option *ngFor="let type of chart_types" [value]="type">
          {{type | titlecaseForWire}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!round_charts.includes(config.chart_type)" class="row full-height">
    <mat-form-field>
      <mat-label>Y Axis Label</mat-label>
      <input [(ngModel)]="config.labels.y_axis" matInput>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Y2 Axis Label</mat-label>
      <input [(ngModel)]="config.labels.y2_axis" matInput>
    </mat-form-field>
  </div>

  <div class="row full-height" *ngIf="config.chart_type==='donut'">
    <mat-form-field>
      <mat-label>Centre Title</mat-label>
      <input [(ngModel)]="config.chart_title" matInput>
    </mat-form-field>
  </div>

  <div class="row full-height">

    <mat-form-field *ngIf="config.chart_type==='stacked bar'">
      <mat-label>Orientation</mat-label>
      <mat-select [(value)]="config.orientation">
        <mat-option [value]="'h'">Horizontal</mat-option>
        <mat-option [value]="'v'">Vertical</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="config.hide_legend"
                  [disabled]="config.hide_axes===true"
                  class="self-center">
      Hide axis labels
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_tick_labels"
                  [disabled]="config.hide_axes===true"
                  class="self-center">
      Hide value labels
    </mat-checkbox>
    <mat-checkbox (change)="updateAxes($event)"
                  [(ngModel)]="config.hide_axes"
                  class="self-center">
      Hide axes
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_comments"
                  class="self-center">
      Hide Comments
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_now_line"
                  class="self-center">
      Hide Now Label
    </mat-checkbox>

    <mat-form-field *ngIf="['pie','donut','gauge'].includes(config.chart_type)">
      <mat-label>Legend position</mat-label>
      <mat-select [(value)]="config.legend_position">
        <mat-option *ngFor="let position of legend_positions" [value]="position">
          {{position | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div *ngIf="config.orientation !== 'v'" class="options-group form-ctrl-margin-top">
    <h3>X Axis options </h3>
    <div class="row full-height">

      <mat-checkbox [(ngModel)]="config.x_label_nowrap"
                    [disabled]="config.hide_axes===true"
                    class="self-center flex-quarter">
        Don't wrap labels
      </mat-checkbox>

      <mat-checkbox [(ngModel)]="config.show_all_x_ticks"
                    [disabled]="config.hide_axes===true"
                    (change)="setShowAllXTicks($event)"
                    class="self-center flex-quarter">
        Show all labels
      </mat-checkbox>

      <mat-form-field class="self-center flex-quarter">
        <mat-label>X-axis label rotation</mat-label>
        <mat-select [(value)]="config.x_label_rotation">
          <mat-option *ngFor="let rotate of labelOrientationOptions" [value]="rotate">{{rotate}}&deg;</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <div class="row start auto">

    <div class="options-group" style="flex-grow: 1;">
      <h3>{{config.orientation === 'v' ? 'X' : 'Y'}} Axis options
      </h3>
      <div class="y-axis-options">
        <div class="row">
          <mat-label>Y range min:</mat-label>
          <mat-radio-group [(ngModel)]="config.y_min_set" aria-label="Select an option">
            <mat-radio-button [value]="false" (click)="config.y_min=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y_min" [disabled]="!config.y_min_set" matInput>
        </div>

        <div class="row">
          <mat-label>Y range max:</mat-label>
          <mat-radio-group [(ngModel)]="config.y_max_set" aria-label="Select an option">
            <mat-radio-button [value]="false" (click)="config.y_max=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y_max" [disabled]="!config.y_max_set" matInput>
        </div>

        <div class="row">
          <mat-label
              matTooltip="Set the no. of values to show on the y-axis. This option can not be used when Spacing is set to fixed.">
            No. of values:
          </mat-label>
          <mat-radio-group [(ngModel)]="config.y_max_ticks_set" aria-label="Select an option"
                           [disabled]="config.y_spacing_set">
            <mat-radio-button [value]="false" (click)="config.y_max_ticks=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y_max_ticks" [disabled]="!config.y_max_ticks_set || config.y_spacing_set"
                 type="number" step="1" min="0"
                 max="50" matInput>
        </div>

        <div class="row">
          <mat-label
              matTooltip="Set the minimum no. of units between y values. This option can not be used when No. of values is set to fixed.">
            Min Spacing:
          </mat-label>
          <mat-radio-group [(ngModel)]="config.y_spacing_set" aria-label="Select an option"
                           [disabled]="config.y_max_ticks_set">
            <mat-radio-button [value]="false" (click)="config.y_spacing=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y_spacing" [disabled]="!config.y_spacing_set || config.y_max_ticks_set"
                 type="number" min="0" matInput>
        </div>
        <div class="row">
          <div class="spacer">
            <mat-label matTooltip="Set the no. of decimal places to show on the y-axis.">
              Decimals:
            </mat-label>
            <input [(ngModel)]="config.y_decimals" [disabled]="config.y_condensed_numbers" type="number" min="0"
                   max="5" matInput>
          </div>
          <mat-checkbox [(ngModel)]="config.y_condensed_numbers" (change)="resetDecimals($event)"
                        [disabled]="config.y_decimals && config.y_decimals!==0">Use condensed numbers
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="options-group" style="width:50%;margin-right:20px;">
      <h3>Y2 Axis options
      </h3>
      <div class="y-axis-options">
        <div class="row">
          <mat-label>Y2 range min:</mat-label>
          <mat-radio-group [(ngModel)]="config.y2_min_set" aria-label="Select an option">
            <mat-radio-button [value]="false" (click)="config.y2_min=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y2_min" [disabled]="!config.y2_min_set" matInput>
        </div>

        <div class="row">
          <mat-label>Y2 range max:</mat-label>
          <mat-radio-group [(ngModel)]="config.y2_max_set" aria-label="Select an option">
            <mat-radio-button [value]="false" (click)="config.y2_max=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y2_max" [disabled]="!config.y2_max_set" matInput>
        </div>

        <div class="row">
          <mat-label>No. of values:</mat-label>
          <mat-radio-group [(ngModel)]="config.y2_max_ticks_set" aria-label="Select an option"
                           [disabled]="config.y2_spacing_set">
            <mat-radio-button [value]="false" (click)="config.y2_max_ticks=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y2_max_ticks" [disabled]="!config.y2_max_ticks_set || config.y2_spacing_set"
                 type="number" step="1" min="0"
                 max="50" matInput>
        </div>

        <div class="row">
          <mat-label
              matTooltip="Set the minimum no. of units between y2 values. This option can not be used when No. of values is set to fixed.">
            Min spacing:
          </mat-label>
          <mat-radio-group [(ngModel)]="config.y2_spacing_set" aria-label="Select an option"
                           [disabled]="config.y2_max_ticks_set">
            <mat-radio-button [value]="false" (click)="config.y2_spacing=null;">Auto</mat-radio-button>
            <mat-radio-button [value]="true">Fixed</mat-radio-button>
          </mat-radio-group>
          <input [(ngModel)]="config.y2_spacing" [disabled]="!config.y2_spacing_set || config.y2_max_ticks_set"
                 type="number" min="0" matInput>
        </div>

        <div class="row">
          <div class="spacer">
            <mat-label matTooltip="Set the no. of decimal places to show on the y2-axis.">
              Decimals:
            </mat-label>
            <input [(ngModel)]="config.y2_decimals" [disabled]="config.y2_condensed_numbers" type="number" min="0"
                   max="5" matInput>
          </div>
          <mat-checkbox [(ngModel)]="config.y2_condensed_numbers" (change)="resetDecimals($event)"
                        [disabled]="config.y2_decimals && config.y2_decimals!==0">Use condensed numbers
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="row center" *ngIf="config.chart_type==='stacked bar' || config.chart_type==='rar'"
       style="justify-content: space-between;">
    <div class="row center spacer" *ngFor="let key of style_keys">
      <label style="margin-right:10px;">{{key | removeUnderscore | upperfirst}}: </label>
      <table-column-menu (formatsChanged)="config.styles[key]=$event"
                         [item]="config.styles[key]"
                         [options]="col_menu_options"
                         class="inline">
      </table-column-menu>
    </div>
  </div>

</div>
<rar-chart-form-series-table *ngIf="config.chart_type && config.chart_type === 'rar'"
                             [(series_list)]="config.series_list">

</rar-chart-form-series-table>
<custom-chart-form-series-table *ngIf="config.chart_type && config.chart_type !== 'rar'"
                                [chart_type]="config.chart_type"
                                [(series_list)]="config.series_list">
</custom-chart-form-series-table>

<div style="height:400px;min-height:400px;margin-right:20px;margin-top:20px;">
  <generic-chart *ngIf="config.chart_type!=='rar'" [config]="config" style="height: 320px;"
                 class="chart"></generic-chart>
  <rar-chart *ngIf="config.chart_type==='rar'"
             [config]="config" style="height: 320px;"
             class="chart plotly-chart"></rar-chart>
</div>
