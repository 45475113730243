import * as utils from '../lib/utils';
import {match_schema, recursively_null} from '../lib/utils';
import * as moment_ from 'moment';
import {Injectable} from '@angular/core';
import {NotificationService} from "./notification.service";
import {remove as _remove, get as _get, uniq as _uniq} from "lodash-es";
import {HotInstance} from "./hot-instance";
import {Model} from "./api/model";
import {MatDialogConfig} from "@angular/material/dialog";
import {SearchQueryOptions} from "./api/search-query-options";
import MouseDownEvent = JQuery.MouseDownEvent;
import {FormDialogService} from "./form-dialog.service";
import {KeyMap} from '../_typing/generic-types';
import {IDateTimePeriod} from "../_typing/date-time-period";

// TODO what is this used for? If it is for setting a type of Global variable,
export const moment = moment_["default"];

export interface HOTLookup {
    source?: string[];
    valueLookup?: { [key: string]: string };
    idLookup?: { [key: string]: string };
    nameLookup?: { [key: string]: string };
}

// TODO make work with auth complete
@Injectable()
export class HandsontableGenerator {
    col_widths: any = {};

    constructor(private notification: NotificationService,
                private formDialogService: FormDialogService) {
    }

    window_height() {
        return window.innerHeight - 240;
    }

    matchLookupData(hot: HotInstance, hot_data, lookups, rel_name, paste_map) {
        try {
            Object.keys(paste_map).forEach((r) => {
                const col = paste_map[r][0];
                const name = paste_map[r][1];
                const id = lookups.valueLookup[name] ? lookups.valueLookup[name] : lookups.valueLookup[lookups.nameLookup[name]];
                hot_data[r].relationships[rel_name].data = {id: id, type: 'component'};
            })
        } catch (e) {
            console.log("Some data could not be matched. Please refresh the table.", e);
            this.notification.openError("Some data could not be matched. Please refresh the table and try pasting again.");
        }
    }

    getFiltersFromPasted(pasted, config: { startRow: number, startCol: number, endRow: number, endCol: number },
                         hot: HotInstance, lookups: HOTLookup, headers: string[]): {
        options: SearchQueryOptions,
        paste_map: { [key: number]: any[] };
    } {
        const ctrl = this;
        const names = [];
        let paste_map = {};

        pasted.forEach(p => {
            for (let i = config.startCol; i <= config.endCol; i++) {
                const name = p[i - config.startCol];
                if (headers.includes(hot.instance.getColHeader(i).toString()) && name) {
                    if (!lookups.valueLookup[name]) {
                        names.push(name);
                    }
                    paste_map[pasted.indexOf(p) + config.startRow] = [i, name];
                }
            }
        })

        if (names.length < 1) return {options: undefined, paste_map: paste_map};
        const options = new SearchQueryOptions();
        options.filters = [{
            or: names.map(name => {
                return {name: 'name', op: 'ilike', val: '%' + name + '%'};
            })
        }]
        return {options: options, paste_map: paste_map};
    }

    gen_lookups(resource_list: any[], nameFunction?, not_null?: boolean) {
        if (nameFunction == null) {
            nameFunction = item => {
                if (!item.hasOwnProperty('attributes')) {
                    return 'Unknown'
                }
                if (item.attributes.name == null) {
                    return 'No Name'
                }
                if (item.attributes.description == null) {
                    return String(item.attributes.name).trim();
                }
                return item.attributes.name.trim() + '-' + item.attributes.description.trim();
            }
        }
        const valueLookup = {};
        const idLookup = {};
        const nameLookup = {};
        const sourceList = resource_list.map(item => {
            const str_item = String(nameFunction(item)).trim();
            valueLookup[str_item] = item.id;
            idLookup[item.id] = str_item;
            nameLookup[str_item] = item.attributes.name;
            return str_item;
        });
        if (!not_null) {
            sourceList.push(' ');
            // @ts-ignore
            valueLookup[null] = '';
            idLookup[''] = null;
        }
        sourceList.sort();
        return {
            source: sourceList,
            valueLookup: valueLookup,
            idLookup: idLookup,
            nameLookup: nameLookup
        }
    }

    getValueTitleLookup(lookups: { value: string; title: string }[], attribute:string) {
        return (row, title) => {
            if (row == null) {
                return null
            }
            if (title === undefined) {
                if (row.attributes?.[attribute]) {
                    return lookups.find(t => t.value === row.attributes[attribute])?.title;
                }
            } else {
                row.attributes[attribute] = lookups.find(t => t.title === title)?.value;
            }
            return title
        }
    }

    genLookupDataSource(lookUps, relationship, index?, extra_function?) {
        return (row, value) => {

            if (row == null) {
                return null
            }
            if (row.relationships == null) {
                row.relationships = {};
            }
            if (!row.relationships[relationship]) {
                row.relationships[relationship] = {};
            }
            if (!row.relationships[relationship].data) {
                row.relationships[relationship].data = null;
            }

            if (value === undefined) {
                if (row.relationships[relationship]?.data?.id) {
                    return lookUps.idLookup[row.relationships[relationship].data.id];
                }
            } else {
                if (!row.relationships[relationship].data) {
                    row.relationships[relationship].data = {};
                }
                row.relationships[relationship].data.id = lookUps.valueLookup[value];
            }

            if (extra_function) {
                extra_function(row, value);
            }
            return value;
        };
    }

    genLookupCustomDataSource(lookUps, relationship) {
        return (row, value) => {

            if (row == null) {
                return null
            }
            if (row.attributes == null) {
                row.attributes = {}
            }
            if (row.attributes.custom_series == null) {
                row.attributes.custom_series = {};
            }
            if (row.attributes.custom_series[relationship] == null) {
                row.attributes.custom_series[relationship] = null
            }

            if (value === undefined) {

                return lookUps.idLookup[row.attributes.custom_series[relationship]]

            } else {

                row.attributes.custom_series[relationship] = lookUps.valueLookup[value];

            }

            return value;
        };
    }

    generateTable(resource, schema, column_list, hotInstance?: HotInstance, extraAfterChange?, paginate?: Boolean, row_locked_dict?: KeyMap<boolean>) {
        /**
         * Generates handsontable for display*
         * *
         * The method has three parts: *
         * 1. Creates a new hot instance if one does not exist *
         * 2. Maps column data and configurations/ settings *
         */
        const ctrl = this;

        if (hotInstance == null) {
            hotInstance = new HotInstance();
        }

        const cols_hide_dropdown = [];
        column_list.map(element => {
            if (typeof element.data === 'function') {
                cols_hide_dropdown.push(column_list.indexOf(element));
            }
            if (element.type === 'numeric' && element.numericFormat === undefined) {
                element.numericFormat = {pattern: '0,0'};
            }
            // if (element['hideMenu']) {
            //     cols_hide_dropdown.push(column_list.indexOf(element));
            // }
        });

        hotInstance.column_list = column_list;

        hotInstance.resource = resource;

        hotInstance.change_ids = [];
        hotInstance['new_rows'] = [];

        hotInstance.save = () => {
            ctrl.notification.openInfo('Updating ' + hotInstance.change_ids.length +
                ' items, and saving ' + hotInstance['new_rows'].length + ' new items.', 3000);

            const sourceData = hotInstance.instance.getSourceData();
            const result = this.setup_save(sourceData, resource, hotInstance.change_ids, schema,
                hotInstance.required_keys, hotInstance['new_rows']);
            let errorCount = 0;
            let errorStr = '';
            const promises = result.deferreds;

            promises.forEach(promise => {
                promise.then(response => {
                    _remove(hotInstance.change_ids, item => item === response.id);

                    this.notification.openSuccess('Saved: ' + response.data.id, 2000);
                    return response;
                }).catch(reason => {
                    errorCount += 1;
                    // errorStr += errorCount + ": " + reason.data +  ": " + reason.error.errors[0].detail + ' \n';
                    if (reason.error.errors && reason.error.errors.length > 0) {
                        errorStr += errorCount + ": " + reason.error.errors[0].title + ' \n';
                    } else if (reason.statusText) {
                        errorStr += errorCount + ": " + reason.statusText + ' \n';
                    }
                    console.log("errorStr", errorStr, reason);
                });

            });

            Promise.all(result.deferreds).then(responses => {
                responses.forEach(response => {
                    // TODO validate in which case there will be a data field and which not.
                    //  Potentially when there was an HTTP error.
                    hotInstance.change_ids = utils.removeItem(hotInstance.change_ids, response.data.id);
                    hotInstance['new_rows'] = utils.removeItem(hotInstance['new_rows'], responses.indexOf(response));
                });
            });

            // For catching all errors
            // This will wait until all promises are resolved/rejected and not just complete on first fail
            Promise.all(result.deferreds.map((promise) => {
                    return promise.catch(error => {
                        return error;
                    });
                })
            ).then(() => {
                if (errorStr) {
                    ctrl.notification.openError('Could not save some items: check unsaved items ' + ' \n' + errorStr);
                }
            });

            return result;
        };

        hotInstance.settings = {
            dataSchema: schema,
            colHeaders: utils.gen_col_headers(hotInstance.column_list, {}),
            minSpareRows: 3,
            selectState: true,
            rowHeaders: true,
            renderAllRows: false,
            rowHeights: 30,
            // startRows: 1,
            dropdownMenu: true,
            filters: true,
            sortIndicator: true,
            selectionMode: 'range',
            manualColumnResize: true,
            maxCols: hotInstance.column_list.length,
            columns: hotInstance.column_list,
            columnSorting: true,
            contextMenu: {
                callback: utils.gen_row_deletion('id', resource, this.notification, hotInstance, null, row_locked_dict),
                items: {
                    "delete_row": {
                        name: 'Delete Row Permanently'
                    }
                }
            },
            afterColumnResize: (col_index, width) => {
                let title = hotInstance.instance.getColHeader(col_index);
                title = title.toString().replace("*", "");
                this.col_widths[title] = width;
                this.notification.openInfo("Please click Save Tile, in edit mode, to store column width.", 3000);

            },
            afterChange: (changes: [number, string | number, any, any][], source: string) => {
                if (changes && ['edit', 'CopyPaste.paste', 'Autofill.fill'].includes(source)) {
                    let hot = hotInstance.instance;
                    for (const change in changes) {
                        let row = changes[change][0];
                        // TODO the wrong id is potentially retrieved here
                        let id = hot.getDataAtRowProp(row, 'id');

                        if (id !== '' && id && !(hotInstance.change_ids.indexOf(id) >= 0)) {
                            hotInstance.change_ids.push(id);
                        } else {
                            if (hotInstance['new_rows'].indexOf(changes[change][0]) < 0) {
                                hotInstance['new_rows'].push(changes[change][0]);
                            }
                        }
                    }
                }
                if (extraAfterChange) {
                    extraAfterChange(changes, source);
                }
            }
        };
        hotInstance.settings.autoRowSize = true;
        hotInstance.settings.rowHeaders = index => {
            if (hotInstance.instance == null) {
                return index;
            } else {
                const description = hotInstance.instance.getDataAtRowProp(index, 'attributes.description');
                const id_ = hotInstance.instance.getDataAtRowProp(index, 'id');

                let prefix = '';
                if (hotInstance.change_ids.indexOf(id_) >= 0) {
                    prefix = '(unsaved) ';
                }

                if (description) {
                    return prefix + description;
                } else {
                    return prefix + hotInstance.instance.getDataAtRowProp(index, 'attributes.name');
                }
            }
        };

        if (paginate) {
            hotInstance.settings.afterScrollVertically = () => {
                const firstVisibleIndex = hotInstance.instance.rowOffset();
                const lastVisibleIndex = firstVisibleIndex + hotInstance.instance.countVisibleRows();
                const size = hotInstance.instance.countRows();
                if (size > 0 && firstVisibleIndex > hotInstance.last_visible_row && size - lastVisibleIndex < 30) {
                    hotInstance.getNextPage();
                }
                hotInstance.last_visible_row = firstVisibleIndex;
            };
        }

        hotInstance.settings.rowHeaderWidth = 180;
        hotInstance.ready = true;
        hotInstance.never_searched = true;

        return hotInstance;
    }

    /**
     * Saves or patches rows in Handson sheets
     *
     * @param hot_data
     * @param resource
     * @param change_ids
     * @param schema
     * @param required_keys
     */
    private setup_save(hot_data, resource, change_ids, schema, required_keys: string[], new_rows?: number[]) {
        const ctrl = this;

        const deferreds: Promise<any>[] = [];
        const savePromises: Promise<any>[] = [];
        const errors: any[] = [];

        hot_data = hot_data.map(item => {
            if (resource[item.attributes?.base_type]) {
                item.type = item.attributes.base_type;
            }
            let inner_resource: Model = resource.hasOwnProperty('baseUrl') ? resource : resource[item.type];
            const index = hot_data.indexOf(item);
            // Store this as it gets removed by match_schema
            const temp_id = item.temp_id ? item.temp_id : null;

            if (!recursively_null(item)) {
                match_schema(item, schema);

                let had_required_keys: boolean = null;
                for (let i = 0; i < required_keys.length; i++) {
                    const path = required_keys[i];
                    let required_item = _get(item, path);
                    if (required_item) {
                        had_required_keys = true;
                    } else {
                        had_required_keys = false;
                        break;
                    }
                }

                if (!item['id'] && had_required_keys === true) { // new item with required keys
                    let promise = inner_resource.save(item);
                    promise.then(result => {
                        item.id = result.data.id;
                        if (temp_id) item.temp_id = temp_id;
                    });
                    deferreds.push(promise);
                    savePromises.push(promise)
                } else if (!item['id'] && had_required_keys === false) {
                    if (Array.isArray(new_rows)) {
                        if (new_rows.includes(index)) {
                            ctrl.notification.openError("Required data missing at row " + (parseInt(index) + 1) + ", index: " + index);
                            errors.push({
                                message: "Required data missing at row " + (parseInt(index) + 1),
                                index: index
                            });
                            // removeItem(new_rows, index);
                        }
                    }
                    if (temp_id) item.temp_id = temp_id;
                } else {
                    if (Array.isArray(change_ids)) { // changed item
                        if (change_ids.includes(item['id'])) {
                            try {
                                delete item.attributes.changed_on;
                                delete item.attributes.created_on;
                                delete item.relationships.created_by;
                                delete item.relationships.changed_by;
                            } catch {
                            }
                            deferreds.push(inner_resource.patch(item))
                        }
                    }
                }
            }
            return item;
        });

        return {deferreds: deferreds, data: hot_data, savePromises, errors: errors};
    }

    checkEmptyRows(hot) {
        if (!hot['new_rows'] || hot['new_rows'].length < 1) return [];

        try {
            for (let r = hot['new_rows'].length - 1; r == 0; r--) {
                let delete_row = true;
                const row = hot.instance.getDataAtRow(r);
                for (let item of row) {
                    if (item && item !== '') {
                        delete_row = false;
                        break;
                    }
                }
                if (delete_row) {
                    hot['new_rows'].splice(r);
                }
            }
        } catch (e) {
            console.log('ERROR: HandsontableGeneratorService (checkEmptyRows). ', e)
        } finally {
            return hot['new_rows'];
        }
    }

    getUserFilterForItems(data: any[]): SearchQueryOptions {
        const user_ids = Array.from(new Set(data.map(item => item.relationships.created_by.data?.id))).filter(id => id);

        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: user_ids}];
        return options;
    }

    genScrollerCallback(event: MouseDownEvent, coords: {
                            col: number,
                            row: number
                        }, hot: HotInstance, data: any[], lookup: HOTLookup,
                        list: any[], relationship: string, type: string, filters?: any[], tracker_list?: any[], sort?) {
        const ctrl = this;
        const open_custom = event.target.classList.contains('hot-infinite-scroll-click');
        if (open_custom) {
            const column = hot.instance.getColHeader(coords.col).toString();
            const col_index = hot.settings.columns.findIndex(item => item.title === column);
            const selectionChanged = ($event): void => {
                const exists = list.find(c => c.id === $event.value.id);
                if (!exists) {
                    list.push($event.value);
                    tracker_list?.push($event.value);
                }
                lookup = ctrl.gen_lookups(list, null, true);
                let actual_row = hot.instance.getCellMeta(coords.row, coords.col);
                data[actual_row['row']].relationships[relationship] = {data: {id: $event.value.id, type: type}};
                hot.settings.columns[col_index].data = ctrl.genLookupDataSource(lookup, relationship);
                hot.change_ids = _uniq(hot.change_ids.concat([data[actual_row['row']].id]));
                hot.instance.updateSettings(hot.settings, false);
                if (sort) {
                    hot.instance.getPlugin('columnSorting').sort(sort);
                }
            }

            return ctrl.openScroller(hot.instance, data, lookup, filters, event, coords, [relationship], selectionChanged);
        }
        return null;
    }

    genDatePickerCallback(event: MouseDownEvent, coords: {
        col: number,
        row: number
    }, hot: HotInstance, data: any[], dtp: IDateTimePeriod) {
        const ctrl = this;
        const open_custom = event.target.classList.contains('hot-date-renderer-click');
        const width = event.target.parentElement?.clientWidth || 160;
        const disabled = event.target.classList.contains('disabled');

        if (open_custom && !disabled) {
            let value = hot.instance.getDataAtCell(coords.row, coords.col);
            if (!value) {
                value = dtp.start;
                hot.instance.setDataAtCell(coords.row, coords.col, dtp.start.toString());
            }
            const config = {
                component: 'DateTimePicker',
                position: {top: -6, adjust_left: (width * -1)},
                parameters: {
                    value: value
                }
            }
            const selectionChanged = ($event): void => {
                hot.instance.setDataAtCell(coords.row, coords.col, $event);
            }
            return this.formDialogService.openCustomDialog(event, config, 'date-renderer', null, selectionChanged);
        }
        return null;
    }

    openScroller(instance: Handsontable, hot_data, lookup: HOTLookup, filters: string[], event: MouseDownEvent,
                 coords: { col: number, row: number }, component_types: string[], selectionChanged: ($event) => void) {
        const ctrl = this;
        const width = event.target.parentElement?.clientWidth || 160;
        const value = instance.getDataAtCell(coords.row, coords.col);

        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'hot-infinite-scroll-dialog';
        const config = {
            position: {top: 10, left: width * -1},
            component: 'InfiniteScroller',
            parameters: {
                custom_filters: filters,
                component_types: component_types,
                current_value: {id: lookup.valueLookup[value]}
            }
        };
        return this.formDialogService.openCustomDialog(event, config, 'hot-infinite-scroll-dialog', null, selectionChanged);
    }
}
