import {Component} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {BaseComponent} from "../../shared/base.component";
import {first, switchMap, takeUntil, tap} from "rxjs/operators";
import {FlowchartData} from "../../_models/flowchart-data";
import {ModelID} from "../../_typing/generic-types";

@Component({
    selector: 'series-sheet-focus-view',
    templateUrl: 'series-sheet-focus-view.component.html',
    standalone: false
})
export class SeriesSheetFocusViewComponent extends BaseComponent {
    components: any[];
    series: any;
    sheet_ready: boolean = false;
    process: any;

    processID: ModelID;

    constructor(private headerData: HeaderDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.params.pipe(switchMap(params => {
            this.sheet_ready = false;
            this.processID = params.processID;
            return this.refresh();
        }), takeUntil(this.onDestroy)).subscribe();
    }

    onSheetRefresh(){
        this.refresh().pipe(first(), takeUntil(this.onDestroy)).subscribe();
    }

    refresh(): Observable<FlowchartData> {
        this.sheet_ready = false;
        return this.plantData.getFlowchartDataForProcess(this.processID).pipe(
            first(),
            takeUntil(this.onDestroy),
            tap(flowchart_data => {
                this.setupEditSheet(flowchart_data);
            }));
    }

    setupEditSheet(flowchart_data: FlowchartData) {
        this.process = flowchart_data.process_focus;
        this.components = this.plantData.components;
        this.series = flowchart_data.selected_series;
        this.plantData.setActiveProcess(this.process);
        this.buildHeader();
        this.sheet_ready = true;
    }


    buildHeader() {
        this.headerData.title = 'Series List';
        this.headerData.buttons = [];
    }
}
