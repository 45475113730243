import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {IDMap, KeyMap, ModelID} from "../../_typing/generic-types";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {CommentTableComment} from "../../_models/api/comment-table";
import {ConfigColumn} from "../../_typing/config/config-column";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {ColumnFormatsDict, TableUtilsService} from "../../tables/table-utils.service";
import {catchError, finalize, take, takeUntil, tap} from "rxjs/operators";
import {TileDataService} from "../../services/tile_data.service";
import {ApiService} from "../../services/api/api.service";
import {NotificationService} from "../../services/notification.service";
import {CustomEventsService} from "../../services/custom-events.service";
import {EXTENDED_NOTIFICATION_DURATION_MS, NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {ErrorBankService} from "../../services/error-bank.service";
import {removeItems} from "../../lib/utils";
import {ITileButton} from "../../_typing/tile-button";

@Injectable()
export abstract class BaseCommentTable implements OnInit, OnDestroy {
    protected readonly onDestroy = new Subject<void>();

    dataSource: PaginationDataSource<CommentTableComment>;
    columns: KeyMap<ConfigColumn>;
    columnIds: string[] = [];
    columnDict: IDMap<ConfigColumn> = {};
    page_ready: boolean = false;
    pageSize = 20;
    filterString = "";
    dtp: IDateTimePeriod;
    formatDict: ColumnFormatsDict = {};
    rowsSelectionStatus: KeyMap<boolean> = {};
    comments: CommentTableComment[];
    buttons: ITileButton[];
    customTemplateColumns = ['start', 'end', 'created_on', 'changed_on', 'select', 'comment'];

    constructor(protected tableUtils: TableUtilsService,
                protected tileData: TileDataService,
                protected customEventsService: CustomEventsService,
                protected api: ApiService,
                protected notification: NotificationService,
                public errorBank: ErrorBankService) {
    }

    ngOnInit(): void {
    }

    setConfigData(columns: ConfigColumn[]) {
        if (this.tileData?.save_content) {
            this.tileData.save_content.pipe(takeUntil(this.onDestroy)).subscribe(event => {
                if (!this.tileData.tile) return;
                this.tileData.tile.attributes.parameters.columns = this.tableUtils.updateColumnFormats(columns, this.formatDict);
                this.tableUtils.emitSaveTile(this.tileData);
            });
        }
        this.formatDict = {};
        columns?.forEach(column => {
            this.formatDict[column.id] = this.tableUtils.getColumnFormats(column.format);
            this.columnDict[column.id] = column;
        });

        this.setButtons();
    }

    selectComment(row, checked) {
        this.rowsSelectionStatus[row.id] = checked.checked;
    }

    selectAll(checked) {
        this.rowsSelectionStatus = {};
        this.comments.forEach(row => {
            this.rowsSelectionStatus[row.id] = checked.checked;
        });
    }

    saveComment(comment: CommentTableComment, columnId: ModelID, $event) {
        const newComment = {
            id: comment.id,
            attributes: {start: comment.start, end: comment.end, comment: comment.comment, base_type: 'comment'},
            type: 'comment'
        };
        if ((columnId === 'start' || columnId === 'end') && !this.customEventsService.checkStartAndEndDates(newComment, 'start', 'end')) {
            return;
        }
        if (columnId === 'start') {
            delete newComment.attributes.end;
            delete newComment.attributes.comment;
        } else if (columnId === 'end') {
            delete newComment.attributes.start;
            delete newComment.attributes.comment;
        } else if (columnId === 'comment') {
            delete newComment.attributes.start;
            delete newComment.attributes.end;
        }

        this.errorBank.clear();
        this.api.comment.obsPatch(newComment).pipe(take(1)).subscribe({
            next: (result) => {
                this.notification.openSuccess("Comment saved.", 2000);
                const oldCommentIndex = this.comments.findIndex(c => c.id === result.data.id);
                if (oldCommentIndex) {
                    this.comments[oldCommentIndex] = Object.assign(this.comments[oldCommentIndex], result.data)
                }
            },
            error: (err) => {
                this.errorBank.addError('CommentTable', 'saveComment', 'patch',
                    `There was an error updating your comment: ${err.error?.errors?.[0]?.detail}`, err, newComment.id);
                this.notification.openError(this.errorBank.getMessageString(), EXTENDED_NOTIFICATION_DURATION_MS);
            }
        });
    }

    deleteSelectedRows() {
        const toDelete = Object.keys(this.rowsSelectionStatus)?.filter(k => this.rowsSelectionStatus[k]);
        if (!toDelete?.length) {
            this.notification.openInfo("No rows selected.", NOTIFICATION_DURATION_MS)
            return;
        }
        this.errorBank.clear();
        let $deleteComments: Observable<any>[] = [];
        toDelete.forEach(commentId => {
            $deleteComments.push(
                this.api.event.obsDelete(commentId)
                    .pipe(tap(result => {
                            removeItems(this.comments, [this.comments.findIndex(c => c.id === commentId)]);
                            delete this.rowsSelectionStatus[commentId];
                        }),
                        catchError(e => {
                            this.errorBank.addError('CommentTable', 'Delete rows', 'delete',
                                `Error deleting comment: ${e.error?.errors}`, e, commentId);
                            return of(e);
                        })
                    )
            )
        })
        forkJoin($deleteComments).pipe(
            take(1),
            finalize(() => {
                this.dataSource.refresh();
            })).subscribe(() => {
            if (this.errorBank.errors.length) {
                this.notification.openError("Some errors occurred: " + this.errorBank.getMessageString());
            } else {
                this.notification.openSuccess("Comments deleted successfully");
            }
        })
    }

    setButtons(): void {
        this.buttons = [
            {
                name: 'Delete',
                func: () => this.deleteSelectedRows(),
                class: 'fa fa-trash',
                HoverOverHint: 'Delete the selected comments'
            },
        ];


        this.tileData.buttonsChanged.next(this.buttons);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}