import {Injectable} from '@angular/core';
import {WaterfallChartConfiguration} from "../charts/chart-config/chart-configuration";
import {
    IChartConfigTranslationService,
    PlotlyChartConfigTranslationService,
    PlotlyChartConfiguration,
    PlotlyDataConfiguration
} from "./plotly-chart-config-translation.service";

@Injectable({
    providedIn: 'root'
})
export class PlotlyWaterfallChartConfigTranslationService extends PlotlyChartConfigTranslationService implements IChartConfigTranslationService {

    constructor(private plotlyTranslate: PlotlyChartConfigTranslationService) {
        super();
    }

    configureChart(config: WaterfallChartConfiguration): Partial<PlotlyChartConfiguration> {
        let plotlyChart: PlotlyChartConfiguration = {
            data: this.configurePlotlyData(config),
            layout: this.plotlyTranslate.configureBasePlotlyLayout(config),
            config: this.plotlyTranslate.configurePlotlyConfig(config)
        }
        return plotlyChart;
    }

    private configurePlotlyData(config: WaterfallChartConfiguration): Partial<PlotlyDataConfiguration>[] {
        const x = config.orientation === 'v' ? this.plotlyTranslate.getXValues(config) : config.y_values['single'];
        const y = config.orientation === 'h' ? this.plotlyTranslate.getXValues(config) : config.y_values['single'];
        let data: Partial<PlotlyDataConfiguration> = this.plotlyTranslate.configureBasePlotlyData(config, x, y);
        data.text = config.text_values['single'];
        data.hovertemplate = config.hover_template;
        data.measure = config.measure_values;
        data.orientation = config.orientation || 'h';
        data.decreasing = {marker: {color: config.colours?.negative}};
        data.increasing = {marker: {color: config.colours?.positive}};
        data.totals = {marker: {color: config.colours?.total}};
        data.connector = {
            line: {
                color: "#666",
                width: 1
            }
        }

        return [data];
    }
}
