import {
    ChartSeriesConfiguration,
    RarChartSeriesConfiguration,
    WaterfallChartSeriesConfiguration
} from "./chart-series.configuration";
import {BudgetPalette} from "./budget-palette";
import {CategoryChartTileConfiguration} from "../../forms/category-chart-form/category-chart-tile.configuration";
import {ChartLibraryType} from "../../services/chart-config-translation.service";
import {ColumnFormatsConfig} from '../../forms/table-column-menu/table-column-menu.component';
import {Series} from '../../_models/series';
import {DateTimePeriodService} from "../../services/date-time-period.service";

export interface IChartLabels {
    title: string;
    sub_title: string;
    y_axis: string;
    x_axis: string;
    y2_axis: string;
}

/**These are the types for the various tile configuration options for chart - i.e. what the user selects **/
export class GenericChartTileConfiguration {
    library?: ChartLibraryType;
    budget_palette?: BudgetPalette;
    chart_type?: string;
    colour?: string;
    chart_title?: string;
    diagonal_line?: boolean;
    group_series?: boolean;
    hide_tick_labels?: boolean;
    hide_axes?: boolean;
    hide_legend?: boolean;
    hide_comments?: boolean;
    hide_now_line?: boolean;
    show_moving_average?: boolean;
    labels?: Partial<IChartLabels>;
    limits?: {
        hi: number;
        hihi: number;
        lowlow: number;
        low: number;
        mean: number;
        std: number;
    };
    legend_position?: string;
    orientation?: string;
    regression_line?: string;
    set_range?: boolean;
    size?: {};
    set_size?: {
        height?: number;
        width?: number;
    };
    statistics?: {
        pooled?: boolean;
        correlation_coeff?: boolean;
        std_dev?: boolean;
        averages?: boolean;
    };
    series_list?: (ChartSeriesConfiguration | RarChartSeriesConfiguration)[];
    show_data_labels?: boolean;
    show_data_label_units?: boolean;
    show_title?: boolean;
    styles?: {
        x_axis_font?: ColumnFormatsConfig;
        y_axis_font?: ColumnFormatsConfig;
        data_font?: ColumnFormatsConfig;
        title_font?: ColumnFormatsConfig;
    };
    y_max_set?: boolean;
    y2_max_set?: boolean;
    y_min_set?: boolean;
    y2_min_set?: boolean;
    y_min?: number;
    y2_min?: number;
    y_max?: number;
    y2_max?: number;
    y_max_ticks_set?: boolean;
    y2_max_ticks_set?: boolean;
    y_max_ticks?: number;
    y2_max_ticks?: number;
    y_decimals?: number;
    y2_decimals?: number;
    y_condensed_numbers?: boolean;
    y2_condensed_numbers?: boolean;
    y_spacing_set?: boolean;
    y2_spacing_set?: boolean;
    y_spacing?: number;
    y2_spacing?: number;
    period_moving_average?: number;
    width?: number;
    max?: number;
    min?: number;
    show_all_x_ticks?: boolean;
    x_label_rotation?: number;
    x_label_nowrap?: boolean;

    static newCustomChartConfig(): GenericChartTileConfiguration {
        return {
            labels: {
                title: null,
                sub_title: null
            },
            chart_type: 'custom',
            hide_comments: true,
            hide_legend: false,
            hide_tick_labels: false,
            hide_axes: false,
            set_range: false,
            y_min: null,
            y_max: null,
            y2_min: null,
            y2_max: null,
            min: null,
            max: null,
            width: null,
            chart_title: null,
            series_list: [{
                axis: 'y',
                colour: null,
                cumulative: false,
                name: null,
                show_limits: false,
                type: 'line',
                line_type: null,
                line_thickness: null,
                range: null,
                sample_period: null,
                custom_legend_name: null,
                vertical_data_labels: true
            }]
        };
    }

    static newCustomChartSeriesConfig(chart_type = 'custom', series?: Series, DTP?: DateTimePeriodService): ChartSeriesConfiguration {
        let series_config: ChartSeriesConfiguration = {
            id: series ? series.id : null,
            axis: "y",
            colour: null,
            cumulative: false,
            name: series ? series.attributes.name : null,
            show_limits: false,
            type: "line",
            line_type: 'solid',
            line_thickness: 'normal',
            range: null,
            sample_period: null,
            show_legend: true,
            vertical_data_labels: true
        };
        if (chart_type === 'stacked bar') {
            series_config.type = 'bar';
        }
        return series_config;
    }

    static newRarChartSeriesConfig(series?: Series, DTP?: DateTimePeriodService): RarChartSeriesConfiguration {
        let series_config = GenericChartTileConfiguration
            .newCustomChartSeriesConfig('rar', series, DTP) as RarChartSeriesConfiguration;
        series_config.type = 'bar';
        (series_config as RarChartSeriesConfiguration).number_of_periods = 1;
        series_config.range = 'yesterday';
        series_config.sample_period = DTP.sample_dict['day'];
        series_config.show_data_labels = true;
        series_config.vertical_data_labels = false;
        if (series) {
            series_config.name = series.attributes.name;
            series_config.id = series.id;
        }
        return series_config;
    }

    static newRarChartSeriesListConfig(groups: string[], series: Series, DTP: DateTimePeriodService): RarChartSeriesConfiguration[] {
        const newItems = groups.map(group => {
            let series_config = GenericChartTileConfiguration
                .newRarChartSeriesConfig(series, DTP) as RarChartSeriesConfiguration;
            series_config.group_name = group;
            series_config.sample_period = ['yesterday', 'the past seven days'].includes(group) ?
                DTP.sample_dict['day'] : DTP.sample_dict['month'];
            series_config.number_of_periods = group === 'yesterday' ? 1 : group === 'the past seven days' ? 7 : 3;
            return series_config;
        });
        return newItems;
    }

    static newRarTargetSeriesConfig(series: Series, DTP: DateTimePeriodService): RarChartSeriesConfiguration {
        const add_config = GenericChartTileConfiguration
            .newRarChartSeriesConfig(series, DTP) as RarChartSeriesConfiguration;
        add_config.type = 'line';
        add_config.group_name = series.attributes.name;
        add_config.is_target = true;
        add_config.show_legend = false;
        add_config.show_data_labels = false;
        add_config.line_type = 'dashed';
        add_config.line_shape = 'linear';
        return add_config;

    }
}

export interface YAxisLimit {
    y: number,
    y2?: number
};

export interface WaterfallChartTileConfiguration extends GenericChartTileConfiguration {
    negative_colour?: string;
    orientation?: string;
    positive_colour?: string;
    total_colour?: string;
    show_flux_label_units?: boolean;
    series_list?: WaterfallChartSeriesConfiguration[];
}

export interface SPCChartTileConfiguration extends GenericChartTileConfiguration {
    one_sigma?: number;
    two_sigma?: number;
    three_sigma: number;
}

export type UnionChartTileConfiguration = WaterfallChartTileConfiguration | CategoryChartTileConfiguration;
