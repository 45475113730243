import {Component, Input, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import {BaseComponent} from "../../../shared/base.component";
import {KeyMap, ModelID, ModelName} from "../../../_typing/generic-types";
import {get as _get, set as _set} from 'lodash';
import {AttributeTimeConfig, ConstantPropertyTimeConfig} from "../../../_typing/config/config-type-date-filter";

@Component({
    selector: 'date-property-filter-selector',
    templateUrl: './date-property-filter-selector.component.html',
    styleUrls: ['./date-property-filter-selector.component.less'],
    standalone: false
})
export class DatePropertyFilterSelectorComponent extends BaseComponent {
    @Input() customFilters: any[];
    @Input() typeId: ModelID;
    @Input() typeName: ModelName;
    @Input() propertyDict: KeyMap<Partial<ConstantPropertyTimeConfig>> = {};
    @Input() attributeDict: KeyMap<Partial<AttributeTimeConfig>> = {};
    @Input() attributeOptions: string[] = ['created_on','changed_on'];

    @Output() propertyDictChanged:EventEmitter<KeyMap<Partial<ConstantPropertyTimeConfig>>> = new EventEmitter();
    @Output() attributeDictChanged:EventEmitter< KeyMap<Partial<AttributeTimeConfig>>> = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
    }

    constantPropertyDateFieldChanged = ($event, name: string, typeId: ModelID): void => {
        const id = $event?.id || $event;
        _set(this.propertyDict, [typeId, name], id);
        if ($event) {
            this.disableAttributeTime(typeId);
        }
        this.emitChanges();
    }
    attributeDateFieldChanged = ($event, name: string, typeId: ModelID): void => {
        const id = $event?.value || $event;
        _set(this.attributeDict, [typeId, name], id);
        if ($event === true) {
            this.disableConstantPropertyTime(typeId)
        }
        this.emitChanges();
    }

    private emitChanges(){
        this.propertyDictChanged.emit(this.propertyDict);
        this.attributeDictChanged.emit(this.attributeDict);
    }

    private disableAttributeTime(typeId: ModelID) {
        _set(this.attributeDict, [typeId, 'enabled'], false);
    }

    private disableConstantPropertyTime(typeId: ModelID) {
        _set(this.propertyDict, [typeId, 'enabled'], false);
    }

    getConstantPropertyDateFieldValue = (name, typeId: ModelID) => {
        return _get(this.propertyDict, [typeId, name])
    }
}
