import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Field, FieldMap, Rule} from "../../../_typing/query-builder";
import {BaseComponent} from "../../../shared/base.component";
import {QueryBuilderFormService} from "../../../services/query-builder-form.service";
import {deepCopy} from "../../../lib/utils";

@Component({
    selector: 'rule',
    templateUrl: './rule.component.html',
    styleUrls: ['./rule.component.less', '../rule-set/rule-set.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RuleComponent extends BaseComponent {
    @Input() rule: Rule;
    @Input() readonly fields: FieldMap;
    @Output() removeRuleRequest: EventEmitter<void> = new EventEmitter();

    selectedField: Field;

    //config: QueryBuilderConfigType;

    constructor(private queryBuilderFormService: QueryBuilderFormService) {
        super();
    }

    ngOnInit() {
        this.selectedField = deepCopy(this.fields[this.rule.field]);
    }

    removeRule() {
        this.removeRuleRequest.next();
    }
}
