import * as utils from '../lib/utils';
import {median, replaceAll, std_dev} from '../lib/utils';
import * as d3 from 'd3';
import * as c3 from 'c3';
import {ChartAPI, ChartConfiguration} from 'c3';
import * as moment_ from 'moment';

import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {EventService} from "../services/event.service";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {TileDataService} from "../services/tile_data.service";
import {forkJoin, Subject, Subscription} from "rxjs";
import {concatMap, takeUntil, tap, take} from "rxjs/operators";
import {PresentationService} from "../services/presentation.service";
import {
    GenericChartTileConfiguration,
    SPCChartTileConfiguration,
    YAxisLimit
} from "./chart-config/generic-chart-tile.configuration";
import {
    ChartLabelFormatConfig,
    ChartSeriesConfiguration,
    ChartTargetData
} from "./chart-config/chart-series.configuration";
import {BudgetPalette} from "./chart-config/budget-palette";
import {ChartService} from '../services/chart.service';
import {ChartEventsService} from "../services/chart-events.service";
import {HeaderDataService} from "../services/header_data.service";
import {IDateTimePeriod, OptionalSamplePeriod} from "../_typing/date-time-period";
import {DateTimeInstanceService} from "../services/date-time-instance.service";
import {KeyMap} from "../_typing/generic-types";

export const moment = moment_["default"];

const colorCache: { [key: string]: string } = {};

@Component({
    selector: 'generic-chart',
    templateUrl: 'generic-chart.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ChartEventsService, ChartService],
    standalone: false
})
export class GenericChartComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @ViewChild('chart_anchor') chart_anchor: ElementRef<HTMLDivElement>;

    config_ready = false;
    chart: ChartAPI;
    id_tag: string;
    config_series_list: ChartSeriesConfiguration[];
    rendered: boolean = false;

    _tile_config: GenericChartTileConfiguration;

    get tile_config() {
        return this._tile_config;
    }

    ///Zooming in (higher quality data)//////
    can_zoom_in: boolean = null;

    //TODO make interface
    chart_config: any | ChartConfiguration = {};
    comment: string;

    series_full: any[];
    series_name_list: any[];
    series_config: any;
    labels: any;
    series_list: any[];
    estimate_config: any[];
    targetNames: string[];
    series_description_list: any[];

    range_map: { [key: string]: { series_list: any[], dtp: IDateTimePeriod } } = {};
    name_tag_map: any = {};
    tag_type_map: any = {};
    tag_class_map: any = {};

    ///Formatting and config/////////////////
    show_legend = true;
    show_limits = false;
    hide_axes = false;
    hide_comments: boolean;

    defaultTitle: string;
    defaultSubtitle: any;
    budget_palette: BudgetPalette;
    chart_type: string = '';
    is_budget = false;
    budgetName: string = 'Budget';
    opsdata: boolean;
    //multi_dtp: boolean = false;
    ///End Formatting//////////////////

    ///Time////////////////////////
    period: OptionalSamplePeriod;
    dtp: IDateTimePeriod;
    ///End time/////////////////////
    show_reset: boolean = false;
    zoom_start: Date;
    zoom_end: Date;
    new_period: OptionalSamplePeriod;
    sample_names: string[];
    current_sample_name: string; //For tooltip
    ma_title: string = 'moving_average';
    period_ma_title: string = 'period_moving_average';

    constructor(private api: ApiService,
                public dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private headerData: HeaderDataService,
                private tileData: TileDataService,
                private eventService: EventService,
                private renderer: Renderer2,
                private presentationService: PresentationService,
                public chartService: ChartService,
                private chartEvents: ChartEventsService) {

        this.chartEvents.eventService = this.eventService;
        this.chartEvents.tileData = this.tileData;
    }

    ///End zooming in///////////////////////

    ///Events////////////////////
    $events: any;
    ///End Events////////////////

    private presenting: boolean = false;
    private presentationSubscription: Subscription;
    data: any = {};
    custom_legend_names: {} = {};
    custom_names_to_series_tag_names: {} = {};

    @Input('config')
    set tile_config(tile_config: GenericChartTileConfiguration) {
        //Only update if the series list has changed
        if (this._tile_config && tile_config.series_list.map(series => series.name) !== this._tile_config.series_list.map(series => series.name)) {
            this._tile_config = tile_config;
            this.loadPage();
        }
        this._tile_config = tile_config;
    }

    groups: any = {};
    types: any = {};
    axes: any = {};
    show_y2 = false;
    data_labels: ChartLabelFormatConfig = {};
    colour_pattern: string[] = [];
    line_list: {
        value: number,
        class: string,
        text?: string,
        axis?: string,
        position?: string
    }[];
    y_max: YAxisLimit = {y: null, y2: null};
    y_min: YAxisLimit = {y: null, y2: null};
    y_med: YAxisLimit = {y: null, y2: null};
    estimates = {};
    range_matrix: { xs: any, data_columns: any };
    tag_key_map: any = {};
    series_key_to_name_map: {} = {};
    columns: any = [];
    time_list: any;
    missing_values: any = {};

    seriesTargetDict: KeyMap<string> = {}
    private task_complete: Subject<boolean>;

    @HostListener('window:resize', ['$event']) // for window scroll events
    onResize(event) {
        if (this.chart_anchor && this.chart_anchor.nativeElement) {
            //This property doesn't 'stick' but forces the chart to set a new max-height on resize
            this.renderer.setStyle(this.chart_anchor.nativeElement, 'max-height', 'none');
            this.doResize();
        }
    }

    doResize() {
        if (!this.chart) return;
        setTimeout(() => {
            this.renderChart();
            this.chart.flush();
        }, 100)
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.id_tag = utils.gen_graph_id(32); //Used by custom legend

        this.addSubscriptions();

        //Making default titles for charts
        this.defaultTitle = this.tile_config.labels.title;
        this.defaultSubtitle = this.tile_config.labels.sub_title;

        //Setting/sending the default titles for the charts to tileData
        this.tileData.setDefaultTitle(ctrl.defaultTitle);
        this.tileData.setDefaultSubtitle(ctrl.defaultSubtitle);
        if (this.tile_config.chart_type) {
            this.chart_type = this.tile_config.chart_type;
        }

        this.dateInst.dateTimePeriodChanged$.pipe(tap((dtp: IDateTimePeriod) => {
            this.dtp = dtp;
            this.period = utils.deepCopy(ctrl.dateTimePeriodService.defaultPeriod);
            this.chartEvents.dtp = this.dtp;
            this.sample_names = this.dateTimePeriodService.sample_periods.map(sp => sp.name);
            this.sample_names = this.sample_names.filter(name => !["two_hour", "three_hour", "four_hour", "six_hour", "eight_hour"].includes(name));

            this.loadPage();
        }), take(1)).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();

        try {
            if (this.chart) {
                this.chart.destroy();
            }
        } catch (e) {

        }
    }

    addSubscriptions() {
        const ctrl = this;

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy))
            .subscribe((dtp) => {
                this.dtp = dtp;
                this.chartEvents.dtp = this.dtp;
                try {
                    if (this.chart) {
                        this.chart.destroy();
                    }
                    this.show_reset = false;
                    this.loadPage(); //series_configs need to be reset for time ranges

                } catch (e) {
                    console.log('this error was caught', e);
                }
            });

        this.tileData.tileResize.pipe(takeUntil(this.onDestroy)).subscribe((size) => {
            if (ctrl.chart) {
                ctrl.chart.resize(size);
                ctrl.chart.flush();
            }
        });

        this.headerData.pagePrinting.pipe(tap(val => {
            this.doResize();
        }), takeUntil(this.onDestroy)).subscribe()
    }

    getDataOptimal() {
        const ctrl = this;
        ctrl.dtp.start = ctrl.zoom_start;
        ctrl.dtp.end = ctrl.zoom_end;
        ctrl.dtp.sample_period = this.dateTimePeriodService.sample_dict.points;
        ctrl.getData();
    }

    addRechartOnZoom() {
        const ctrl = this;
        ctrl.chart_config.zoom.onzoom = (function (params) {
            if (["two_hour", "three_hour", "four_hour", "six_hour", "eight_hour"].includes(ctrl.dtp.sample_period.name)) {
                ctrl.new_period = ctrl.dateTimePeriodService.sample_dict["hour"];
            } else {
                let i = ctrl.sample_names.indexOf(ctrl.dtp.sample_period.name);
                ctrl.new_period = ctrl.dateTimePeriodService.sample_dict[ctrl.sample_names[i - 1]];
            }
            ctrl.can_zoom_in = true;
            ctrl.zoom_start = params[0];
            ctrl.zoom_end = params[1];
        }).bind(ctrl);
    }

    getZoomData() {
        const ctrl = this;

        ctrl.show_reset = true;
        ctrl.dtp = utils.deepCopy(this.dateInst.dtp);
        ctrl.dtp.start = ctrl.zoom_start;
        ctrl.dtp.end = ctrl.zoom_end;
        ctrl.dtp.sample_period = ctrl.new_period;
        ctrl.period = utils.deepCopy(ctrl.dtp.sample_period);

        ctrl.range_map = {};
        ctrl.range_map['custom'] = {
            series_list: ctrl.series_list,
            dtp: ctrl.dtp
        };

        //ctrl.checkSamplePeriod();

        this.chartEvents.dtp = this.dtp;
        ctrl.chartEvents.getEvents(ctrl.tile_config, ctrl.series_full);
        ctrl.getData();
        this.can_zoom_in = false;
    }

    resetZoom() {
        this.show_reset = false;
        this.dtp = utils.deepCopy(this.dateInst.dtp);
        this.buildChart();
    }

    loadPage() {
        //Reloads all data including the series list
        try {
            this.setUp().pipe(takeUntil(this.onDestroy)).subscribe(() => {
                this.buildChart();
            });
        } catch (err) {
            console.log('ERROR: ', err);
        }

    }

    buildChart() {
        //Series already fetched and configured, just reload time data
        this.period = utils.deepCopy(this.dtp.sample_period);
        this.getTimeRanges();
        this.checkSamplePeriod();
        this.getData();
    }

    setUp() {
        let ctrl = this;

        ctrl.config_series_list = utils.deepCopy(ctrl.tile_config.series_list);

        if (ctrl.config_series_list === undefined) {
            console.log("ERROR: Incorrect number of series");
            return;
        }

        ctrl.labels = utils.deepCopy(ctrl.tile_config.labels);
        ctrl.tileData.title = ctrl.labels.title;
        ctrl.tileData.sub_title = ctrl.labels.sub_title;

        ctrl.series_list = [];
        const estimate_ids = [];
        ctrl.targetNames = [];
        ctrl.series_description_list = [];

        let series_name_list = ctrl.config_series_list.map(series => series.name);
        return ctrl.chartService.getChartSeries(series_name_list)
            .pipe(concatMap(response => {
                ctrl.series_full = response.data;
                ctrl.$events = ctrl.chartEvents.getEvents(ctrl.tile_config, ctrl.series_full);

                ctrl.config_series_list.forEach(config_item => {
                    ctrl.series_full.forEach(series => {
                        if (config_item.name === series.attributes.name) {
                            const full_series = utils.deepCopy(series);
                            full_series['config'] = config_item;
                            if (config_item.tag) {
                                ctrl.is_budget = true;
                            }
                            ctrl.series_list.push(full_series);
                        }
                    });
                });

                ctrl.estimate_config = ctrl.series_list.filter(item => item.config.type === 'budget');
                if (ctrl.estimate_config) {
                    ctrl.estimate_config.forEach(estimate => {
                        estimate_ids.push(estimate.id);
                    });
                }
                return this.chartService.getTargetSeries(this.config_series_list).pipe(
                    tap((targetResult: ChartTargetData) => {
                        this.targetNames = targetResult.targetNames;
                        this.seriesTargetDict = targetResult.seriesTargetDict;
                    }))
            }));
    }

    checkSamplePeriod() {
        const ctrl = this;
        // Used for single series and budget charts only
        const setPeriod = (series) => {
            if (series.attributes.sample_period) {
                if (ctrl.dateTimePeriodService.sample_dict[series.attributes.sample_period].hours > ctrl.period.hours) {
                    ctrl.period = ctrl.dateTimePeriodService.sample_dict[series.attributes.sample_period];
                }
            }
        };
        ctrl.series_list.forEach(series => {
            if (ctrl.config_series_list.length === 1 || (ctrl.is_budget && series.config.tag === 'Actual')) {
                setPeriod(series);
            }
        });
    }

    /**
     * Populates the this.range_map.
     **/
    getTimeRanges() {
        const ctrl = this;

        ctrl.range_map = {};

        ctrl.series_list.forEach(series => {
            let dtp = utils.deepCopy(ctrl.dtp);
            let series_config = series.config;
            if (series_config.range === undefined || series_config.range === null) {
                series_config.range = dtp.range;
                series_config.sample_period = dtp.sample_period;
            }
            if (series_config.range !== 'custom') {
                //Custom only applies to the page dtp so would have been set already
                dtp = ctrl.dateTimePeriodService.getDTP(series_config.range, false, dtp ? dtp.calendar : null);
            }

            if (series_config.sample_period) {
                dtp.sample_period = ctrl.dateTimePeriodService.sample_dict[series_config.sample_period.name];
            }

            if (['week', 'month'].includes(dtp.sample_period.name)) {
                this.dateTimePeriodService.changePeriod(dtp);
            }
            let key = series_config.range + ' (' + series_config.sample_period.name + ')';
            if (!ctrl.range_map[key]) {
                ctrl.range_map[key] = {
                    series_list: [series],
                    dtp: utils.deepCopy(dtp)
                };
            } else {
                ctrl.range_map[key].series_list.push(series);
            }
        });
    }

    getData() {
        let ctrl = this;
        const ops_periods = ['points', 'second', 'minute', '5 minute', '10 minute', '15 minute', '30 minute'];
        if (ctrl.period) {
            let $time_series_data = [];
            this.opsdata = ops_periods.includes(ctrl.dtp.sample_period.name);
            if (this.opsdata) {
                const params = {
                    series_list: ctrl.series_list.map(series => series.id),
                    start: ctrl.dtp.start.toISOString(),
                    end: ctrl.dtp.end.toISOString(),
                    sample_period: ctrl.dtp.sample_period.name,
                    period_type: ctrl.dtp.calendar
                };

                $time_series_data.push(ctrl.api.get('/api/OpsData', {
                        params: params
                    })
                );

            } else {
                Object.values(ctrl.range_map).forEach(function (range: { series_list: any[], dtp: IDateTimePeriod }) {
                    const params = {
                        series_list: range.series_list.map(s => s.id),
                        start: typeof (range.dtp.start) === 'string' ? range.dtp.start : range.dtp.start.toISOString(),
                        end: typeof (range.dtp.end) === 'string' ? range.dtp.end : range.dtp.end.toISOString(),
                        sample_period: range.dtp.sample_period.wire_sample,
                        period_type: range.dtp.calendar
                    };
                    let sub = ctrl.api.get_series_data(params);
                    $time_series_data.push(sub);
                });
            }

            //TODO get events working here on all iterations
            forkJoin($time_series_data).pipe(takeUntil(this.onDestroy)).subscribe((data) => {
                ctrl.data = data;
                let i = 0;
                data.forEach(range => {
                    ctrl.data[i].range = ctrl.range_map[Object.keys(ctrl.range_map)[i]].dtp.range;
                    ctrl.data[i].sample_period = ctrl.range_map[Object.keys(ctrl.range_map)[i]].dtp.sample_period;
                    ctrl.data[i].series_list = ctrl.range_map[Object.keys(ctrl.range_map)[i]].series_list;
                    i++;
                });

                if (this.presentationSubscription) {
                    this.presentationSubscription.unsubscribe();
                    this.presentationSubscription = null;
                }

                this.presentationSubscription = this.presentationService.presentationStateChanged
                    .pipe(takeUntil(this.onDestroy)).subscribe(state => {
                        if (this.chart) {
                            try {
                                this.chart.destroy();
                            } catch (e) {
                            }
                            // this.chart = null;
                        }
                        this.presenting = state;

                        this.prepareData();
                        //TODO events needs to be checked but can't get this right in all instances of events response
                        ctrl.$events.pipe(takeUntil(ctrl.onDestroy)).subscribe((events) => {
                            //ctrl.tileData.events = events ? events.data : [];
                            this.generateChart();
                            this.renderChart();
                        });
                    });
            });
        }

    }

    calculateMovingAveragePoint(point_list) {
        let ma_pointes = point_list.slice();
        let cal_days = ma_pointes.length;
        return ma_pointes.reduce((sum, val) => sum + val, 0) / cal_days;
    }

    calculatePeriodMovingAveragePoint(point_list, period_moving_average) {
        let dtes = point_list.slice();
        let cal_days = dtes.length;

        if (dtes.length > period_moving_average) {
            dtes.fill(0, 0, dtes.length - period_moving_average);
            cal_days = period_moving_average;
        }
        return dtes.reduce((sum, val) => sum + val, 0) / cal_days;
    }

    prepareData() {
        const ctrl = this;
        ctrl.groups = {};
        ctrl.types = {};
        ctrl.axes = {};
        ctrl.name_tag_map = {};
        ctrl.tag_type_map = {};
        ctrl.tag_class_map = {};
        ctrl.show_y2 = false;
        ctrl.colour_pattern = [];
        ctrl.line_list = [];
        ctrl.y_max = {y: null, y2: null};
        ctrl.y_min = {y: null, y2: null};
        ctrl.y_med = {y: null, y2: null};
        ctrl.estimates = {};
        ctrl.data_labels = {};

        ctrl.range_matrix = {
            xs: {
                'x1': {
                    label: 'x1',
                    time_column: [],
                    columns: []
                }
            },
            data_columns: [],
            ...(ctrl.tile_config.show_moving_average && {moving_average: []}),
            ...(ctrl.tile_config.period_moving_average > 0 && {period_moving_average: []}),
        };

        for (let i = 0; i < ctrl.data.length; i++) {
            let xi = 'x' + (i + 1);
            ctrl.range_matrix.xs[xi] = {label: xi, time_column: [], columns: []};
            ctrl.range_matrix.xs[xi].columns = ctrl.data[i].series_list.map(s => {
                return {
                    name: s.attributes.name + ctrl.data[i].range,
                    label: s.attributes.name
                };
            });
        }

        for (let range_data of ctrl.data) {
            let xi = 'x' + (ctrl.data.indexOf(range_data) + 1);
            let got_time: boolean = false;
            let time_list = [];
            let columns = [];
            range_data.series_list.forEach(series => {
                let series_key = series.attributes.name + ' ' + range_data.range + ' (' + range_data.sample_period.name + ')';
                let series_tag = series.attributes.description ? series.attributes.description : series.attributes.name;

                //For default chart - labels set in the preconfig (default-chart.component)
                if (ctrl.series_list.length === 1 && ctrl.labels.title) {
                    ctrl.labels['y_axis'] = ctrl.labels.y_axis || ctrl.labels.title;
                } else {
                    ctrl.labels['y_axis'] = ctrl.labels.y_axis;
                }
                if (series.config.cumulative) {
                    series_tag = series_tag + ' (Cum)';
                    series_key = series_key + ' (Cum)';
                }

                ctrl.custom_legend_names[series_key] = series.config.custom_legend_name ? series.config.custom_legend_name : series_tag;

                //For budget charts set up in default-chart.component
                if (series.config.tag) {
                    series_tag = series.config.tag;
                    series_key = series.config.tag;
                    this.custom_legend_names[series_key] = series_tag;
                    this.budgetName = series_tag;
                    this.is_budget = true;
                }
                ctrl.missing_values[series_key] = range_data.missing_values;

                ctrl.name_tag_map[series_key] = series.attributes.name;
                ctrl.series_key_to_name_map[series.attributes.name] = series_key;
                //series key has the custom date string
                ctrl.tag_key_map[series_key] = series_tag;

                if (series.config.type === 'budget') {
                    series.config.type = 'bar';

                    let estimate_name = null;
                    (series.attributes.target_names.split(',')).forEach(name => {
                        if (ctrl.targetNames.indexOf(name) > -1) {
                            estimate_name = name;
                        }
                    });
                    ctrl.estimates[series_key] = range_data.data[estimate_name];
                }

                ctrl.tag_type_map[series_key] = series.config.type;
                ctrl.tag_class_map[series_key] = series.config.line_type + ' ' + series.config.line_thickness;

                ctrl.types[series_key] = series.config.type;
                ctrl.axes[series_key] = series.config.axis;

                ctrl.data_labels[series_key] = ctrl.chartService.setDataFormat(series, series.config);

                const current_axis = series.config.axis;
                if (current_axis === 'y2') {
                    ctrl.show_y2 = true;
                }

                let series_data = [];
                let moving_average = [];
                let period_moving_average_data_list = [];
                for (let time_stamp in range_data['data'][series.attributes.name]) {
                    if (range_data['data'][series.attributes.name].hasOwnProperty(time_stamp)) {
                        if (!got_time) {
                            time_list.push(new Date(time_stamp));
                        }
                        series_data.push(range_data['data'][series.attributes.name][time_stamp]);
                        if (series_data.length > 0) {
                            if (this.tile_config.show_moving_average) {
                                moving_average.push(
                                    this.calculateMovingAveragePoint(series_data)
                                );
                            }
                            if (this.tile_config.period_moving_average && this.tile_config.period_moving_average > 0) {
                                period_moving_average_data_list.push(
                                    this.calculatePeriodMovingAveragePoint(series_data, this.tile_config.period_moving_average)
                                );
                                if (period_moving_average_data_list.length > this.tile_config.period_moving_average) {
                                    period_moving_average_data_list.fill(null, 0, this.tile_config.period_moving_average);
                                }
                            }
                        }
                    }
                }
                if (series.config.colour) {
                    ctrl.colour_pattern.push(series.config.colour);
                } else {
                    ctrl.colour_pattern.push('#111');
                    if (ctrl.tile_config.show_moving_average) {
                        ctrl.colour_pattern.push('maroon');
                    }
                    if (this.tile_config.period_moving_average && this.tile_config.period_moving_average > 0) {
                        ctrl.colour_pattern.push('green');
                    }
                }
                if (series.config.cumulative === true) {
                    series_data = utils.accumulateData(series_data);
                }

                const max_val = series_data.reduce((a, b) => Math.max(a, b));
                const min_val = series_data.reduce((a, b) => Math.min(a, b));
                ctrl.y_max[current_axis] = Math.max(max_val, ctrl.y_max[current_axis]);
                ctrl.y_min[current_axis] = Math.min(min_val, ctrl.y_min[current_axis]);
                ctrl.y_med[current_axis] = median(series_data);

                if (series.config.show_limits) {
                    ctrl.show_limits = true;
                    if (series.attributes.hihi !== null) {
                        ctrl.line_list.push({
                            value: utils.scaleLimits(series.attributes.hihi, ctrl.dtp, series),
                            class: 'hihi',
                            text: 'V/HIGH',
                            axis: current_axis
                        });
                    }
                    if (series.attributes.hi !== null) {
                        ctrl.y_max[current_axis] = Math.max(max_val, ctrl.y_max[current_axis], utils.scaleLimits(series.attributes.hi, ctrl.dtp, series));
                        ctrl.line_list.push({
                            value: utils.scaleLimits(series.attributes.hi, ctrl.dtp, series),
                            class: 'hi',
                            text: 'HIGH',
                            axis: current_axis
                        });
                    }
                    if (series.attributes.low !== null) {
                        ctrl.y_min[current_axis] = Math.min(min_val, ctrl.y_min[current_axis], utils.scaleLimits(series.attributes.low, ctrl.dtp, series));

                        ctrl.line_list.push({
                            value: utils.scaleLimits(series.attributes.low, ctrl.dtp, series),
                            class: 'lo',
                            text: 'LOW',
                            axis: current_axis
                        });
                    }
                    if (series.attributes.lowlow !== null) {
                        ctrl.line_list.push({
                            value: utils.scaleLimits(series.attributes.lowlow, ctrl.dtp, series),
                            class: 'lolo',
                            text: 'V/LOW',
                            axis: current_axis
                        });
                    }
                }

                if (series.config.group) {
                    if (ctrl.groups[series.config.group]) {
                        ctrl.groups[series.config.group].push(series_key);
                    } else {
                        ctrl.groups[series.config.group] = [series_key];
                    }
                }

                if (!got_time) {
                    this.range_matrix.xs[xi].time_column = [xi.toString()].concat(time_list);
                    got_time = true;
                }
                this.range_matrix.data_columns.push({
                    data: [series_key].concat(series_data),
                    x: xi
                });
                if (this.tile_config.period_moving_average > 0) {
                    this.range_matrix[this.period_ma_title].push({
                        data: [this.period_ma_title].concat(period_moving_average_data_list),
                        x: xi
                    });
                }
                if (this.tile_config.show_moving_average) {
                    this.range_matrix[this.ma_title].push({data: [this.ma_title].concat(moving_average), x: xi});
                }
            });

            for (const [key, value] of Object.entries(ctrl.custom_legend_names)) {
                // @ts-ignore
                ctrl.custom_names_to_series_tag_names[value] = key;
            }

        }
        this.chartService.fillColourPattern(ctrl.colour_pattern);

    }

    generateChart() {
        const ctrl = this;

        let c3_type = '';
        ctrl.config_series_list.map(config => {
            c3_type = config.type;
        });

        let xs = {};
        ctrl.range_matrix.data_columns.forEach(col => {
            xs[col.data[0]] = col.x;
        });
        if (ctrl.tile_config.show_moving_average) {
            ctrl.range_matrix[this.ma_title].forEach(col => {
                xs[this.ma_title] = col.x;
            });
        }
        if (ctrl.tile_config.period_moving_average > 0) {
            ctrl.range_matrix[this.period_ma_title].forEach(col => {
                xs[this.period_ma_title] = col.x;
            });
        }
        ctrl.columns = (Object.values(ctrl.range_matrix.xs)).map(col => col['time_column']).concat(
            ctrl.range_matrix.data_columns.map(col => col.data));

        if (ctrl.tile_config.show_moving_average) {
            ctrl.columns = ctrl.columns.concat(ctrl.range_matrix[this.ma_title].map(col => col.data));
        }
        if (ctrl.tile_config.period_moving_average > 0) {
            ctrl.columns = ctrl.columns.concat(ctrl.range_matrix[this.period_ma_title].map(col => col.data));
        }
        this.chartService.setChartDefaultTicks(this.chart_anchor, this.chart_config.type);

        ctrl.chart_config = ctrl.chartService.getStartingConfig(ctrl.columns, ctrl.types,
            ctrl.axes, ctrl.groups, ctrl.colour_pattern, ctrl.hide_axes, ctrl.data_labels);

        if (ctrl.show_y2) {
            ctrl.chart_config.axis.y2 = {
                show: !ctrl.hide_axes,
                label: {}
            };
        }

        ctrl.chart_config.data.xs = xs;
        ctrl.chart_config.axis.x.type = 'timeseries';
        ctrl.chart_config.axis.x.tick.format = function (time) {
            return ctrl.dtp.sample_period.format(time, ctrl.dtp, ctrl.time_list);
        };

        ctrl.addXAxisTickCulling(this.tile_config);

        ctrl.addRechartOnZoom();

        if (ctrl.tile_config.show_title) {
            ctrl.chart_config.title = {text: ctrl.tile_config.labels.title};
        }

        switch (c3_type) {
            case 'pie':
                ctrl.chart_config.type = 'pie';
                break;
            case 'donut':
                ctrl.chart_config.type = 'donut';
                ctrl.chart_config['donut'] = {
                    title: ctrl.tile_config.chart_title, //TODO migration to change this name
                    width: ctrl.tile_config.width
                };
                break;
            case 'gauge':
                ctrl.chart_config.type = 'gauge';
                ctrl.chart_config['gauge'] = {
                    width: ctrl.tile_config.width
                };
                if (ctrl.tile_config.y_min && ctrl.tile_config.y_max) {
                    ctrl.chart_config['gauge'].min = ctrl.tile_config.y_min || null;
                    ctrl.chart_config['gauge'].max = ctrl.tile_config.y_max || null;
                }
                break;
        }
        if (this.tile_config.show_moving_average) {
            ctrl.custom_legend_names[this.ma_title] = 'Simple Moving Average';
        }
        if (this.tile_config.period_moving_average && this.tile_config.period_moving_average > 0) {
            ctrl.custom_legend_names[this.period_ma_title] = `${this.tile_config.period_moving_average} Day Moving Average`;
        }
        ctrl.chart_config.data.names = ctrl.custom_legend_names,
            ctrl.chart_config.data.onclick = e => {
                ctrl.chartEvents.getSelected(e, this.name_tag_map, ctrl.series_full);
            };
        ctrl.chart_config.data.color = function getColor(color, d, e) {
            const key = typeof d === "string" ? `${color}:${d}` : color;
            let cachedColor = colorCache[key];
            if (cachedColor && typeof d !== "object") {
                return cachedColor;
            }
            let rgb_color = d3.rgb(color);
            let tag = null;
            let value;
            if (d.id === 'Actual' && ctrl.name_tag_map[ctrl.budgetName]) {
                tag = ctrl.name_tag_map[ctrl.budgetName];
                value = ctrl.data[0].data[tag][Object.keys(ctrl.data[0].data[tag])[d.index]];
            } else if (d.id && ctrl.estimates[d.id]) {
                tag = ctrl.name_tag_map[d.id];
                value = ctrl.estimates[d.id][Object.keys(ctrl.estimates[d.id])[d.index]];
            }
            if (d.id === 'Actual' || (d.id && ctrl.estimates[d.id])) {
                if (value > (ctrl.data[0].data[ctrl.name_tag_map[d.id]])
                    [Object.keys(ctrl.data[0].data[ctrl.name_tag_map[d.id]])[d.index]]) {
                    let c = ctrl.budget_palette?.actual_below
                    let t = ctrl.seriesTargetDict?.[ctrl.name_tag_map[d.id]];
                    rgb_color = d3.rgb(c || t);
                }
            }
            let missing;
            if (d) {
                missing = ctrl.missing_values[d.id]?.[ctrl.name_tag_map[d.id]];
            }
            if (d && missing && missing[Object.keys(missing)?.[d.index]]) {
                if (ctrl.tag_type_map[d.id] === 'bar') {
                    cachedColor = 'rgba(' + d3.rgb(color).r + ', ' + d3.rgb(color).g + ', ' + d3.rgb(color).b + ', 0.3)';
                } else {
                    cachedColor = 'rgba(' + d3.rgb(color).r + ', ' + d3.rgb(color).g + ', ' + d3.rgb(color).b + ', 0.01)';
                }
            } else {
                cachedColor = 'rgba(' + rgb_color.r + ', ' + rgb_color.g + ', ' + rgb_color.b + ', 1.0)';
            }
            colorCache[key] = cachedColor;
            return cachedColor;
        };

        /**Context tile settings?**/
        if (this.tile_config.hide_legend === true) {
            ctrl.show_legend = false;
            ctrl.chart_config.padding = 10;
        }
        ctrl.hide_axes = this.tile_config.hide_axes;

        if (this.tile_config.set_size) {
            ctrl.chart_config.size = {
                height: this.tile_config.set_size.height,
                width: this.tile_config.set_size.width
            };
        }
        /**End context tile**/

        if (ctrl.is_budget) {
            ctrl.budget_palette = ctrl.tile_config.budget_palette;
        }

        ctrl.chartService.setColumnClasses(ctrl.chart_config, ctrl.columns, ctrl.tag_class_map, ctrl.tag_type_map);

        if (ctrl.show_limits === true) {
            ctrl.chartService.setYLimits(ctrl.chart_config, ctrl.y_max, ctrl.y_min, ctrl.show_y2);
        }

        /**if NONE of the following options are set then find happy defaults**/
        if (!this.chartService.checkCustomYSet(this.tile_config)) {
            this.chartService.setYAxisDefaults(this.chart_config, this.y_min, this.y_max, 'y', ctrl.types);
        }
        if (!this.chartService.checkCustomY2Set(this.tile_config) && ctrl.show_y2) {
            this.chartService.setYAxisDefaults(this.chart_config, this.y_min, this.y_max, 'y2', ctrl.types);
        }
        this.chartService.setPaddingForVerticalLabels(this.chart_config, this.tile_config, ctrl.show_y2);

        /**These functions do their own checks on y and y2**/
        if (this.chartService.checkCustomYSet(this.tile_config) || this.chartService.checkCustomY2Set(this.tile_config)) {
            // Setting y_min and max will override show_limits
            ctrl.chartService.setYRange(ctrl.chart_config, ctrl.tile_config);
            this.chartService.setYTicks(this.chart_config, this.tile_config, this.chart_anchor.nativeElement.offsetHeight < 220);
            this.chartService.setYSpacing(this.chart_config, this.tile_config, ctrl.y_min, ctrl.y_max, ctrl.show_y2);
        }
        this.chartService.setYFormats(this.chart_config, this.tile_config, ctrl.y_med, ctrl.show_y2);

        ctrl.chartService.configureAxisLabels(ctrl.chart_config, ctrl.labels);

        ctrl.chart_config.grid = {
            y: {
                lines: ctrl.line_list
            }
        };

        if (this.tile_config.chart_type?.toLowerCase() === 'spc') {
            ctrl.setUpLimits();
        }

        if (!this.tile_config.hide_now_line) {
            ctrl.chartService.addNowLine(ctrl.chart_config);
        }

        if (ctrl.tile_config.hide_comments !== true && !['pie', 'donut', 'gauge'].includes(ctrl.chart_config.type)) {
            ctrl.chartEvents.addCommentLines(ctrl.chart_config);
        }

        //Hide points for missing values
        if (!['pie', 'donut', 'gauge'].includes(ctrl.chart_config.type)) {
            ctrl.chart_config.point = {
                show: function showPoint(a) {
                    if (a.id !== ctrl.ma_title && a.id !== ctrl.period_ma_title) {
                        return ctrl.missing_values[a.id][ctrl.name_tag_map[a.id]]?.[a.x.toISOString()] !== true;
                    }
                }
            };
        }

        /**
         * Custom tooltip and legend enable us to show the series icons formatted according to their class (colour, line thickness etc.)
         * We have to manually track which legend item have been toggled and their state since we overwrite the legend
         * item onclick event.
         * Note that c3 makes space for the legend so we leave the legend in and simply hide it, then
         * position the custom legend on top of the hidden legend
         **/
        const clicked_legend: { [key: string]: boolean } = {};

        ctrl.chart_config.legend = {
            show: ctrl.show_legend,
            position: ctrl.tile_config.legend_position,
            item: {
                onclick: function (d) {
                    let isClicked = clicked_legend[d];
                    if (isClicked === undefined) {
                        isClicked = true;
                    }
                    clicked_legend[d] = !isClicked;
                    ctrl.chart.show();
                    Object.keys(clicked_legend).forEach(key => {
                        if (!clicked_legend[key]) {
                            ctrl.chart.hide(key);
                        }
                    });
                    ctrl.chartService.formatX(!ctrl.tile_config.x_label_nowrap, ctrl.tile_config.x_label_rotation);
                }
            }
        };

        if (this.presenting) {
            ctrl.chart_config.legend.padding = 35;
        }

        let custom_tool_tip = {
            format: {
                title: function (name) {
                    return name;
                },
                value: function (value, ratio, id, index) {
                    if (c3_type === 'pie' || c3_type === 'gauge' || c3_type === 'donut') {
                        return utils.significantNumber(ratio * 100).toString() + '%, ' + utils
                            .significantNumber(value).toString();
                    } else {
                        return utils.significantNumber(value);
                    }

                }
            }
        };
        if (c3_type !== 'pie' && c3_type !== 'gauge' && c3_type !== 'donut' && c3_type) {
            custom_tool_tip['contents'] = function (d, titleFormat, valueFormat, color) {
                let text;
                let header = '';
                if (d[0] && d[0].x) {
                    header = ctrl.dateTimePeriodService.sample_dict[ctrl.dtp.sample_period.name].format(d[0].x, ctrl.dtp);
                }
                text = "<table class='c3-tooltip c3-tooltip-custom'><tbody><tr><th colspan='2'>" + header + "</th></tr>";
                d.forEach(s => {
                    let cachedColor = 'rgba(' + d3.rgb(color(s)).r + ', ' + d3.rgb(color(s)).g + ', ' + d3.rgb(color(s)).b + ', 1)';
                    text += "<tr class='c3-tooltip-name'>";
                    text += "<td class='name'><span style='border-color:" + cachedColor + ";background-color:" + cachedColor + ";' class='" + ctrl.tag_type_map[ctrl.custom_names_to_series_tag_names[s.name]] + " " + ctrl.tag_class_map[ctrl.custom_names_to_series_tag_names[s.name]] + "'></span>" + s.name + "</td>";
                    text += "<td class='value'>" + s.value.toFixed(3) + "</td></tr>";
                });
                return text + "</tbody></table>";
            };
        }
        ctrl.chart_config.tooltip = custom_tool_tip;
        //*End custom tooltip and legend*//
        ctrl.chartService.progressiveDisable(ctrl.columns, ctrl.chart_config);
        ctrl.config_ready = true;
    };

    // set up control limits
    setUpLimits() {
        const ctrl = this;
        const limits = this.tile_config.limits;
        const array = this.columns[1].slice(1);
        const n = array.length;
        const mean = array.reduce((a, b) => a + b) / n;
        limits.std = std_dev(array);
        const spc_config = this.tile_config as SPCChartTileConfiguration;

        if (limits) {
            if (limits.hihi !== null) {
                this.line_list.push({value: limits.hihi, text: 'HH', class: 'hihi', position: 'end'});
            }
            if (limits.hi !== null) {
                this.line_list.push({value: limits.hi, text: 'H', class: 'hi', position: 'end'});
            }
            if (limits.low !== null) {
                this.line_list.push({value: limits.low, text: 'L', class: 'lo', position: 'end'});
            }
            if (limits.lowlow !== null) {
                this.line_list.push({value: limits.lowlow, text: 'LL', class: 'lolo', position: 'end'});
            }
            this.line_list.push({value: mean, text: 'CL', class: 'cl', position: 'start'});
            if (this.tile_config.chart_type.toLowerCase() === 'spc') {
                if (spc_config.three_sigma !== null) {
                    this.line_list.push({
                        value: mean + (limits.std * spc_config.three_sigma),
                        text: `UCL (${spc_config.three_sigma} σ)`, class: 'ucl-lcl', position: 'start'
                    });
                    this.line_list.push({
                        value: mean - (limits.std * spc_config.three_sigma),
                        text: `LCL (-${spc_config.three_sigma} σ)`, class: 'ucl-lcl', position: 'start'
                    });
                }
                if (spc_config.one_sigma !== null && spc_config.one_sigma > 0) {
                    this.line_list.push({
                        value: mean + (limits.std * spc_config.one_sigma),
                        text: `${spc_config.one_sigma} σ`, class: 'first-sigma', position: 'start'
                    });
                    this.line_list.push({
                        value: mean - (limits.std * spc_config.one_sigma),
                        text: `-${spc_config.one_sigma} σ`, class: 'first-sigma', position: 'start'
                    });
                }
                if (spc_config.two_sigma !== null && spc_config.two_sigma > 0) {
                    this.line_list.push({
                        value: mean + (limits.std * spc_config.two_sigma),
                        text: `${spc_config.two_sigma} σ`, class: 'second-sigma', position: 'start'
                    });
                    this.line_list.push({
                        value: mean - (limits.std * spc_config.two_sigma),
                        text: `-${spc_config.two_sigma} σ`, class: 'second-sigma', position: 'start'
                    });
                }
            }
        }

        this.chart_config.grid['y'] = {
            lines: this.line_list
        };
        this.chart_config.axis.y.max = mean + spc_config.three_sigma * limits.std;
        this.chart_config.axis.y.min = mean - spc_config.three_sigma * limits.std;

        this.chart_config.axis.y.tick = {
            count: ctrl.chartService.y_max_ticks,
            format: (v, id, i, j) => utils.significantNumber(v),
        };
    }

    renderChart() {
        const ctrl = this;
        ctrl.chart_config.bindto = ctrl.chart_anchor.nativeElement;
        setTimeout(() => {
            ctrl.tile_config.size = {};
            try {
                ctrl.chart = c3.generate(ctrl.chart_config);
                ctrl.chartService.rendered_chart = ctrl.chart;
                ctrl.chartEvents.rendered_chart = ctrl.chart;
                ctrl.postRenderChart();
            } catch (err) {
                console.log('ERROR: ', err);
            }
        }, 100);
    }

    //********Post chart render functions/////////////////////////////////////
    postRenderChart() {
        const ctrl = this;
        if (ctrl.tile_config.set_size) {
            // @ts-ignore C3 types incorrect
            ctrl.chart.transform('width', ctrl.tile_config.set_size.width);
            // @ts-ignore C3 types incorrect
            ctrl.chart.transform('height', ctrl.tile_config.set_size.height);
        }
        if (ctrl.show_legend) {
            ctrl.addCustomLegend();
        }
        ctrl.rotateDataLabels();
        ctrl.setZeroLabelClass();
        ctrl.chartService.formatX(!this.tile_config.x_label_nowrap, ctrl.tile_config.x_label_rotation);

        ctrl.rendered = true;
    }

    rotateDataLabels() {
        const ctrl = this;

        ctrl.config_series_list.map(config => {
            let key = replaceAll(Object.keys(ctrl.name_tag_map).find(key => ctrl.name_tag_map[key] === config.name), " ", "-");
            if (key && config.vertical_data_labels === true) {
                key = utils.cssEscape(key);
                const selector = ".c3-chart-texts .c3-target-" + key + " text.c3-text";
                d3.selectAll(selector)
                    .attr("transform", function (d) {
                        let textSel = d3.select(this);
                        return "rotate(-90, " + (Number(textSel.attr("x")) + 2) + ", " + (Number(textSel.attr("y")) - 2) + ")";
                    })
                    .style("text-anchor", function (d: { id: number, index: number, value: number }) {
                        return "start" //(d.value && d.value > 0) ? "start" : "centre";
                    });
                //Hide 0 ?
                // .style("display", function (d: { id: number, index: number, value: number }) {
                //     return d.value === 0 ? "none" : "static";
                // })
            }
        });

    }

    setZeroLabelClass() {
        d3.selectAll('.c3-texts text').each(function () {
            let textElement = d3.select(this);
            const textContent = textElement.text();
            const numericValue = parseFloat(textContent.replace(',', '.')); // Convert to float, handle commas as decimal separators

            // Check if the parsed value is zero (consider floating-point precision issues)
            if (numericValue === 0 || Math.abs(numericValue) < 1e-12) { // Using a small epsilon for comparison
                textElement.classed('zero-value', true); // Apply the 'zero-value' class
            }
        });
    }

    addCustomLegend() {
        const ctrl = this;
        let c3_type = '';
        ctrl.config_series_list.map(config => {
            c3_type = config.type;
        });
        // @ts-ignore
        let legend_bottom = this.chart.element.offsetHeight - this.chart.internal.height;

        if (!['pie', 'gauge', 'donut'].includes(c3_type)) {
            legend_bottom = legend_bottom - 40;
            if (ctrl.tile_config.x_label_rotation) {
                legend_bottom = legend_bottom - 30;
            }
        }
        d3.select('.' + this.id_tag + '.chart-tile')
            .insert('div', '.chart')
            .attr('class', 'legend')
            .style('bottom', legend_bottom + 'px')
            .selectAll('span')
            .data(Object.keys(ctrl.name_tag_map))
            .enter().append('div')
            .attr('data-id', function (name) {
                return name;
            })

            .each(function (name) {
                let colour = ctrl.chart.color(name);
                if (ctrl.tag_type_map[name] === 'area') {
                    colour = colour.replace('1.0', '0.5');
                }
                d3.select(this).append('div')
                    .style('background-color', colour)
                    .style('border-color', ctrl.chart.color(name))
                    .attr('class', 'image ' + ctrl.tag_type_map[name] + " " + ctrl.tag_class_map[name]);

            })
            .on('mouseover', function (id) {
                ctrl.chart.focus(id);
            })
            .on('mouseout', function (id) {
                ctrl.chart.revert();
            })
            .on('click', function (id) {
                ctrl.chart.toggle(id);
            })
            .append('div').html(function (name) {
            return ctrl.custom_legend_names[name];
        })
            .attr('class', 'legend-name');

    }

    addXAxisTickCulling(tileConfig: GenericChartTileConfiguration) {
        const ctrl = this;
        const rotate = tileConfig.x_label_rotation || 0;
        const multiline = tileConfig.x_label_nowrap !== true;
        const xMax = ctrl.getXAxisMaxCulling(tileConfig);
        ctrl.chart_config.axis.x.tick.culling = xMax ? {max: xMax} : false;
        if (ctrl.chart_anchor.nativeElement.offsetWidth < 300 && !rotate) {
            ctrl.chart_config.axis.x.tick.rotate = -45;
            ctrl.chart_config.axis.x.tick.multiline = false;
        }
        if (rotate) {
            ctrl.chart_config.axis.x.tick.multiline = multiline;
            ctrl.chart_config.axis.x.tick.rotate = rotate;
        }

        ctrl.chart_config.onresized = (() => {
            if (!ctrl.chart?.internal?.config) return;
            if (rotate) {
                ctrl.chart.internal.config.axis_x_tick_multiline = multiline;
                ctrl.chart.internal.config.axis_x_tick_rotate = rotate;
                ctrl.chart.flush();
                return;
            }

            ctrl.chart.internal.config.axis_x_tick_culling_max = ctrl.getXAxisMaxCulling(tileConfig);
            const isSmallTile = ctrl.chart_anchor.nativeElement.offsetWidth < 300;
            ctrl.chart.internal.config.axis_x_tick_rotate = isSmallTile ? -45 : 0;
            ctrl.chart.internal.config.axis_x_tick_multiline = !isSmallTile;
            //ctrl.chartService.formatX(multiline);
            ctrl.chart.flush();
        }).bind(this);
    }

    getXAxisMaxCulling(tileConfig: GenericChartTileConfiguration): number {
        const ctrl = this;
        const width = ctrl.chart_anchor.nativeElement.offsetWidth;
        if (tileConfig.show_all_x_ticks) return null;
        return width < 170 ? 3 :
            width < 300 ? 4 :
                width < 500 ? 6 :
                    width < 700 ? 8 :
                        10;
    }
}
