import * as $ from 'jquery'
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {HeaderDataService} from "../../services/header_data.service";

@Component({
    selector: 'audit-pivot',
    templateUrl: 'audit-pivot.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class AuditPivotComponent implements OnInit {

    $: any;

    constructor(
        private http: HttpClient, private headerData: HeaderDataService
    ) {

    }

    ngOnInit() {
        this.headerData.title = 'Permission Pivot';

        this.http.get('/auth/audit').toPromise().then(
            function (data) {
                // @ts-ignore
                $("#pivot_output").pivotUI(
                    data,
                    {
                        renderers: $.extend(
                            // @ts-ignore
                            $.pivotUtilities.renderers,
                            // @ts-ignore
                            $.pivotUtilities.c3_renderers,
                            // @ts-ignore
                            $.pivotUtilities.d3_renderers,
                            // @ts-ignore
                            $.pivotUtilities.export_renderers
                        ),
                        hiddenAttributes: [""],
                        aggregatorName: 'Count',
                        menuLimit: 1000
                    }
                ).show();
            }, function () {
                console.log("error");
            }
        )
    }
}

//     {templateUrl:'static/app/reports/audit_pivot.html',
//         controller:auditPivotViewCtrl});
