import {Injectable} from '@angular/core';
import {map, mergeMap, tap} from "rxjs/operators";
import {WaterfallChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from '../../services/api/api.service';
import {Series} from '../../_models/series';
import {Observable, Subject} from 'rxjs';
import {ChartConfigTranslationService, ChartLibraryType} from "../../services/chart-config-translation.service";
import {WaterfallChartConfiguration} from "../chart-config/chart-configuration";
import {SeriesSummary} from '../../_models/api/series-summary';
import {FormatNumberPipe} from "../../shared/pipes";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {KeyMap} from '../../_typing/generic-types';
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Injectable()
export class WaterfallChartService {
    dtp: IDateTimePeriod;
    series_full: Series[];
    $waterfall_config_ready_subject: Subject<any> = new Subject();
    $waterfall_config_ready = this.$waterfall_config_ready_subject.asObservable();

    constructor(private dateInst: DateTimeInstanceService,
                private api: ApiService,
                private seriesData: SeriesDataService,
                private chartConfigService: ChartConfigTranslationService,
                private formatNumber: FormatNumberPipe) {

    }

    getChart(tile_config: WaterfallChartTileConfiguration) {
        this.getChartData(tile_config);
    }

    private getChartData(tile_config: WaterfallChartTileConfiguration) {
        this.dtp = this.dateInst.dtp;
        const series_ids = tile_config.series_list.map(series => series.series.id);
        this.seriesData.getSeriesListByIds(series_ids)
            .pipe(tap(response => {
                    this.series_full = response.data;
                }),
                mergeMap(() => {
                    return this.getSeriesSummary(series_ids).pipe(map((gss: SeriesSummary[]) => {
                        return this.seriesData.mapSeriesToSeriesSummary(this.series_full, gss)
                    }))
                })
            ).subscribe((series_dict) => {
            this.configureWaterfallChartConfig(tile_config, series_dict)
        })
    }

    private getSeriesSummary(series_ids: string[]): Observable<SeriesSummary[]> {
        return this.seriesData.getSeriesSummary(this.dtp, series_ids, null, ['Value', 'Unit']);
    }

    private configureWaterfallChartConfig(tile_config: WaterfallChartTileConfiguration, series_dict) {
        const config = this.getWaterfallChartConfig(tile_config.library, tile_config, series_dict);
        this.$waterfall_config_ready_subject.next(config);
    }

    /**Gather all configurable options necessary for a waterfall chart**/
    private getWaterfallChartConfig(library: ChartLibraryType, tile_config: WaterfallChartTileConfiguration, series_dict) {
        let chart_config: WaterfallChartConfiguration = {
            key_values: ['single'],
            type: 'waterfall',
            x_values: this.chartConfigService.getSingleXValues(tile_config, series_dict),
            y_values: this.chartConfigService.getSingleYValues(tile_config, series_dict),
            y_max: tile_config.y_max || null,
            y_min: tile_config.y_min || null,
            group_values: tile_config.group_series ? this.chartConfigService.getGroupNames(tile_config) : null,
            orientation: 'v',
            measure_values: this.getWaterfallMeasureValues(tile_config),
            flux: null,
            hidden: this.chartConfigService.getHidden(tile_config),
            hover_template: null,
            x_axis_type: 'category',
            y_axis_type: 'linear',
            labels: tile_config.labels,
            colours: this.getWaterfallColours(tile_config),
            size: tile_config.set_size ? tile_config.set_size : null,
            styles: this.chartConfigService.getStyles(tile_config)
        }
        chart_config.hidden.legend = true;
        const cumsum = this.getCumSumValues(chart_config.y_values['single'], chart_config.measure_values);
        const flux = this.getFlux(tile_config, chart_config.y_values['single'], chart_config.measure_values);
        const hover_template = this.getHoverTemplate(tile_config, series_dict, cumsum, chart_config.x_values['single'],
            chart_config.measure_values, flux.flux);
        const text_values = this.getTextValues(tile_config, series_dict, cumsum, flux.flux);
        const range = this.getRange(tile_config, cumsum);
        chart_config = Object.assign(chart_config, text_values, range, flux, hover_template);
        return this.chartConfigService.getConfiguredWaterfallChart('plotly', chart_config);
    }

    private getWaterfallMeasureValues(tile_config): string[] {
        let measures = [];
        tile_config.series_list.forEach(s => {
            measures.push(s.measure)
        })
        if (measures[0] === 'total') measures[0] = 'absolute';
        return measures;
    }

    private getFlux(tile_config: WaterfallChartTileConfiguration, y_values: string[], measure_values: string[]): {
        flux: string[]
    } {
        const flux = [];
        for (let i = 0; i < tile_config.series_list.length; i++) {
            let symbol = parseFloat(y_values[i]) >= 0 ? '&#9650;' : '&#9660;';
            if (measure_values[i] === 'total') {
                flux.push('');
            } else {
                flux.push(`${symbol} ${this.formatNumber.transform(Math.abs(parseFloat(y_values[i])), 2)}`);
            }
        }
        return {flux: flux};
    }

    private getHoverTemplate(tile_config: WaterfallChartTileConfiguration, series_dict, cumsum: number[], x_values: string[], measure_values: string[], flux_values: string[]): {
        hover_template: string[]
    } {
        let template = [];
        const format = (val) => {
            return this.formatNumber.transform(parseFloat(val), 3)
        }
        for (let i = 0; i < tile_config.series_list.length; i++) {
            const s = tile_config.series_list[i];
            const ss = series_dict[tile_config.series_list[i].series.id];
            const unit = ' ' + ss.Unit || '';
            const y = format(cumsum[i]) + unit;
            const x = x_values[i];
            const flux = flux_values[i] + (flux_values[i].includes(unit) ? '' : unit);
            const start = measure_values.slice(0, i + 1).lastIndexOf('total');
            const initial = start < 0 ? cumsum[0] : cumsum[start];
            template.push(`${x} (${y})${flux ? `<br>${flux}` : ''}<br>Initial: ${format(initial) + unit}<extra></extra>`);
        }
        return {hover_template: template};
    }

    private getTextValues(tile_config: WaterfallChartTileConfiguration, series_dict, cumsum: number[], flux: string[]): {
        text_values: KeyMap<string[]>
    } {
        let text = [];
        let item = [];
        for (let i = 0; i < tile_config.series_list.length; i++) {
            item = [];
            const s = tile_config.series_list[i];
            const ss = series_dict[tile_config.series_list[i].series.id];
            if (s.show_data_labels) {
                const decimals = ss.DecimalPlaces || 2;
                const unit = tile_config.show_data_label_units ? ' ' + ss.Unit || '' : '';
                item.push(this.formatNumber.transform(cumsum[i], decimals) + unit);
            }
            if (s.show_flux_labels) {
                const unit = tile_config.show_flux_label_units ? ' ' + ss.Unit || '' : '';
                item.push(flux[i] + unit);
            }
            text.push(item.join('<br>'))

        }

        return {
            text_values: {single: text}
        };
    }

    private getRange(tile_config: WaterfallChartTileConfiguration, cumsum: number[]): { y_max: number, y_min: number } {
        let min = Math.min(...cumsum);
        let max = Math.max(...cumsum);
        if (min > 0) {
            min = 0;
        }
        const range = max - min;

        /*Add a buffer for the data labels based on range of data*/
        if (tile_config.series_list.findIndex(s => s.show_data_labels) > -1) {
            //const buffer = ((((1 / tile_config.set_size.height) * range) / 1000) * range);
            const buffer = 0.2 * range;
            if (min !== 0) {
                min -= buffer;
            }
            max += buffer;
        }
        return {
            y_max: tile_config.y_max || max,
            y_min: tile_config.y_min || min
        };
    }

    private getCumSumValues(y: string[], measure: string[]): number[] {
        let cumsum = [];
        let sum = 0;
        for (let i = 0; i < y.length; i++) {
            if (i === 0) {
                sum = parseFloat(y[i]);
            } else if (measure[i] == "total") {
                sum = cumsum[i - 1];
            } else {
                sum += parseFloat(y[i]);
            }
            cumsum.push(sum);
        }
        return cumsum;
    }

    private getWaterfallColours(tile_config) {
        let colours = {};
        if (tile_config.positive_colour) {
            colours['positive'] = tile_config.positive_colour
        }
        if (tile_config.negative_colour) {
            colours['negative'] = tile_config.negative_colour
        }
        if (tile_config.total_colour) {
            colours['total'] = tile_config.total_colour
        }
        return colours;
    }
}
