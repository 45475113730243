import {NgModule} from '@angular/core';
import {CapitalisePipe, NameOrDescriptionPipe, RemoveUnderscorePipe, StringDatePipe, UpperFirstPipe} from "../pipes";
import {GetUserPipe} from "../pipes/get-user.pipe";
import {UserFullnamePipePipe} from "../pipes/user-fullname.pipe";


@NgModule({
    declarations: [
        RemoveUnderscorePipe,
        NameOrDescriptionPipe,
        StringDatePipe,
        CapitalisePipe,
        UpperFirstPipe,
    ],
    imports: [
        GetUserPipe,
        UserFullnamePipePipe
    ],
    exports: [
        GetUserPipe,
        RemoveUnderscorePipe,
        UserFullnamePipePipe,
        NameOrDescriptionPipe,
        StringDatePipe,
        CapitalisePipe,
        UpperFirstPipe,
    ]
})
export class SharedPipesModule {
}
