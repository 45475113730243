<table (matSortChange)="sortData($event)" *ngIf="summarySorted?.length > 0 && series_events && statusColumns &&
extraColumns" [dataSource]="dataSource" class="series-table" mat-table matSort>
  <ng-container *ngFor="let column of statusColumns;" [matColumnDef]="column" sticky>

    <th *matHeaderCellDef [id]="column" [mat-sort-header]="column"
        [style.width]="format_dict[column].width + 'px'" [ngClass]="{'hideonprint': hide_on_print.includes(column)}"
        class="freeze-pane" [resizable]="format_dict[column]" (resizable)="format_dict[column].width=$event"
        mat-header-cell [constrainWidth]="config.constrain_width">
      <ng-container *ngIf="column !== 'Status'">{{format_dict[column].title}}</ng-container>
      <ng-container *ngIf="column === 'Status'"><i class="icon-status"></i></ng-container>
    </th>
    <td *matCellDef="let element;" mat-cell
        [ngClass]="{'bold': hovering_events.includes(element.ID), 'hideonprint': hide_on_print.includes(column)}"
        [ngStyle]="format_dict[column].style" style="alignment: left; text-align: left">
      <ng-container *ngIf="column === 'Status'">
        <span class="fa flag flag--status fa-warning"
              [ngClass]="{'warning-none': !element[column] || element[column]==1, 'warning' : element[column]==2, 'error': element[column]==3}">
        </span>
        <!--<i [ngClass]="{'status-warn': element[column] === 2, 'status-action': element[column] === 3}"></i>-->
      </ng-container>

      <div *ngIf="column === 'Name'" class="row center" [style.width]="format_dict[column].width + 'px'"
           [style.justify-content]="format_dict[column].style['text-align']==='right' ? 'flex-end' : format_dict[column].style['text-align']==='center' ? 'center':'flex-start'">
        <i (click)="editSeries(element)" class="edit-inline hide-xs hideonprint" *ngIf="isSticky[column]"></i>
        <a (debounceClick)="openChartDialog(element[column])" [dblClickCopy]="element[column]"
           [textContent]="element[column]" style="cursor:pointer;"></a>
        <span *ngIf="element['kpi_level'] === 'Level 1'"
              class="kpi">KPI {{(element['kpi_level']).replace('Level', '')}}</span>
      </div>

      <div *ngIf="column === 'Alias'" [ngClass]="{'editable':isSticky[column] } " class="row center" [style.width]="format_dict[column].width + 'px'"
           [style.justify-content]="format_dict[column].style['text-align']==='right' ? 'flex-end' : format_dict[column].style['text-align']==='center' ? 'center':'flex-start'">
        <i (click)="editSeries(element)" *ngIf="isSticky[column]" class="edit-inline hide-xs hideonprint"></i>
        <a (debounceClick)="openChartDialog(element[column])" [dblClickCopy]="element[column]"
           [textContent]="element[column]" style="cursor:pointer;"></a>
        <span *ngIf="element['kpi_level'] === 'Level 1' && isSticky[column]"
              class="kpi">KPI {{(element['kpi_level']).replace('Level', '')}}</span>
      </div>

      <div *ngIf="column === 'Description'" [ngClass]="{'editable':isSticky[column] } "
           class="row center">
        <i (click)="editSeries(element)" *ngIf="isSticky[column]"
           class="edit-inline hide-xs hideonprint"></i>
        <a (debounceClick)="openChartDialog(element['Name'])" [dblClickCopy]="element['Name']"
           [textContent]="element[column]"
           [style.width]="tableUtils.setDescriptorWidth(column, element, format_dict, statusColumns)"></a>
        <span *ngIf="element['kpi_level'] === 'Level 1' && isSticky[column]"
              class="kpi">KPI {{(element['kpi_level']).replace('Level', '')}}</span>
      </div>
    </td>
  </ng-container>

  <series-detail-table [series_ids]="series_ids" [table_extract]="true"
                       (seriesDetails)="series_details=$event"></series-detail-table>

  <ng-container *ngFor="let column of extraColumns;" [matColumnDef]="column">
    <ng-container *ngIf="column !== 'Sparkline'">
      <ng-container *ngIf="!customColumns.includes(column)">
        <th *matHeaderCellDef [mat-sort-header]="column"
            [style.width]="format_dict[column].width + 'px'" [resizable]="format_dict[column]"
            (resizable)="format_dict[column].width=$event" [constrainWidth]="config.constrain_width"
            [matTooltip]="col_dict[column]?.description?col_dict[column]?.description:''"
            mat-header-cell>
          <div [textContent]="format_dict[column].title"></div>
        </th>
      </ng-container>

      <ng-container *ngIf="customColumns.includes(column)">
        <th *matHeaderCellDef [mat-sort-header]="column" [style.width]="format_dict[column].width + 'px'"
            [textContent]="column" [resizable]="format_dict[column]" (resizable)="format_dict[column].width=$event"
            mat-header-cell [constrainWidth]="config.constrain_width">
          <div [textContent]="format_dict[column].title"></div>
        </th>
      </ng-container>

      <td (debounceClick)="openChartDialog(element['Name'])" [dblClickCopy]="element[column]"
          *matCellDef="let element;" [ngClass]="{'favourable': favourabilityFormats?.[element.ID]?.[column]===true,
                'unfavourable': favourabilityFormats?.[element.ID]?.[column]===false,'trend-cell' : column.includes('Trend')}"
          [ngStyle]="format_dict[column].style" mat-cell
      >
        <ng-container *ngIf="!customColumns.includes(column) && !column.toString().includes(trendColumn)">
          <ng-container
              *ngIf="column.toString().includes('%')">{{element[column] | percent:'1.2-2' |
              formatNumber:((significant_numbers ? ((element['DecimalPlaces'] || element['DecimalPlaces'] === 0) ? element['DecimalPlaces'] : format_dict[column].decimals) : null)):significant_numbers | thousandsSeparate}}
          </ng-container>
          <ng-container
              *ngIf="!column.toString().includes('%') && column!='Unit'">{{element[column] |
              formatNumber:((significant_numbers ? ((element['DecimalPlaces'] || element['DecimalPlaces'] === 0) ? element['DecimalPlaces'] : format_dict[column].decimals) : null)):significant_numbers
              | thousandsSeparate}}
          </ng-container>
          <ng-container (debounceClick)="openChartDialog(element['Name'])" [dblClickCopy]="element[column]"
                        *ngIf="column=='Unit'">{{element[column]}}
          </ng-container>
        </ng-container>

        <ng-container *ngIf="column.toString().includes(trendColumn)">
          <i class="fa" [ngClass]="{'fa-caret-up': element[column]==='positive','fa-caret-down':element[column]==='negative'}"
             ></i>
        </ng-container>

        <ng-container *ngIf="customColumns.includes(column)">
          <input (click)="$event.stopPropagation();" *ngIf="editing"
                 [(ngModel)]="config.column_text[column][element['Name']]">
          <div *ngIf="!editing">
            {{config.column_text?.[column]?.[element['Name']]}}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngIf="column == 'Sparkline'">
      <th *matHeaderCellDef [mat-sort-header]="column" [textContent]="format_dict[column].title" mat-header-cell></th>
      <td *matCellDef="let element;" mat-cell (debounceClick)="openChartDialog(element['Name'])"
          [dblClickCopy]="element[column]">
        <sparkline [data_points]="element.Sparkline" [series_name]="element[column]"></sparkline>
      </td>
    </ng-container>

  </ng-container>

  <ng-container matColumnDef="Comments">
    <th *matHeaderCellDef mat-header-cell style="width: 40px;min-width: 40px;" class="hideonprint">
      <div style="padding:0;"><i class="icon-comment-invert" style="padding-top:0;"></i></div>
    </th>
    <td *matCellDef="let element;" mat-cell style="text-align: center;" class="hideonprint">
      <div (mouseenter)="commentIconHover(series_events[element.ID])" (mouseleave)="commentIconLeave()"
           *ngIf="series_events[element.ID]" style="padding:2px 4px 2px 2px;">
        <i (click)="toggleComments(true)" class="icon-inline-comment"></i>
      </div>
      <div *ngIf="!series_events[element.ID]">
        <i (click)="toggleComments(true)" class="icon-comment-invert" style="width:28px;"></i>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="star" stickyEnd>
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element;" mat-cell>
      <mat-icon>more_vert</mat-icon>
    </td>
  </ng-container>

  <!--Row Defs-->
  <ng-container *ngIf="appScope.isNotMobile">
    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr (focus)="setComment($event, row, null)" *matRowDef="let row; columns: columns;" mat-row
        tabindex="0"></tr>
  </ng-container>

  <ng-container *ngIf="!appScope.isNotMobile">
    <tr *matHeaderRowDef="config.mobile_columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: config.mobile_columns;" mat-row></tr>
  </ng-container>

</table>
<div *ngIf="config?.columns?.length < 1">
  <!--TODO find another solution to providing feedback to user-->
  No columns have been set
</div>
<!--<series-detail-table [hidden]="!show_series_details" [series_ids]="series_ids" [config]="config" [format_dict]="format_dict"></series-detail-table>-->
