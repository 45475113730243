import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {ModelID} from "../_typing/generic-types";

export class SeriesSeriesAttributes extends AuditMixinAttributes {
    positive_variance?: boolean;
    source_series_id?: ModelID;
    target_series_id?: ModelID;
}

export class SeriesSeriesRelationships extends AuditMixinRelationships {
    source_series: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    target_series: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;


    constructor() {
        super();
        this.source_series.data = {id: null, type: 'series'};
        this.target_series.data = {id: null, type: 'series'};
    }
}

export class SeriesSeries extends AuditMixin {
    attributes: SeriesSeriesAttributes;
    id: string;
    relationships: SeriesSeriesRelationships;
    type: 'series_series';

    constructor() {
        super();
        this.type = 'series_series';
        this.attributes = new SeriesSeriesAttributes();
        this.relationships = new SeriesSeriesRelationships();
    }
}
