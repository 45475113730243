import {Injectable, ComponentRef, Type} from '@angular/core';
import {SnackbarComponent} from "../notifications/snackbar/snackbar.component";
import {DynamicNotificationComponent, OptionalSnackbarParams} from "../_typing/notification";
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";
import {ConfirmSnackbarComponent} from "../notifications/snackbar/confirm-snackbar/confirm-snackbar.component";
import {BehaviorSubject, Observable} from "rxjs";
import {EXTENDED_NOTIFICATION_DURATION_MS, NOTIFICATION_DURATION_MS} from "../shared/globals";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private snackbar: MatSnackBar) {
    }

    public isOpen: boolean = false;
    private snackbarOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public snackbarOpen$: Observable<boolean> = this.snackbarOpenSubject.asObservable();

    public openSuccess(message: string, duration?: number, action?: string): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackbar({
            message: message,
            duration: duration || NOTIFICATION_DURATION_MS,
            styleType: 'success',
            action: action
        });
    }

    public openError(message: string, duration?: number, action?: string): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackbar({
            message: message,
            duration: duration,
            styleType: 'error',
            action: action,
        });
    }

    public openWarning(message: string, duration?: number): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackbar({
            message: message,
            duration: duration || NOTIFICATION_DURATION_MS,
            styleType: 'warning',
        });
    }

    public openInfo(message: string, duration?: number): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackbar({
            message: message,
            duration: duration || NOTIFICATION_DURATION_MS,
            styleType: 'info',
        });
    }

    public openConfirm(message: string, cancelText?: string, okText?: string): MatSnackBarRef<ConfirmSnackbarComponent> {
        return this.openConfirmationSnackbar(message, cancelText, okText);
    }

    public openDynamicSnackbar(component: Type<DynamicNotificationComponent>, data: any): MatSnackBarRef<DynamicNotificationComponent> {
        return this.snackbar.openFromComponent(component, {
            data: data,
            panelClass: [`snack-bar-${data.styleType || 'info'}`]
        });
    }

    private openSnackbar(_snackbarParams: OptionalSnackbarParams): MatSnackBarRef<SnackbarComponent> {
        this.isOpen = true;
        return this.snackbar.openFromComponent(SnackbarComponent, {
            data: {
                ...(_snackbarParams.action && {action: _snackbarParams.action}),
                ...(_snackbarParams.duration && {contains_duration: true}),
                message: _snackbarParams.message
            },
            ...(_snackbarParams.duration && {duration: _snackbarParams.duration}),
            panelClass: [`snack-bar-${_snackbarParams.styleType}`]
        });
    }

    private openConfirmationSnackbar(message: string, cancelText: string, okText: string): MatSnackBarRef<ConfirmSnackbarComponent> {
        let snackbarRef: MatSnackBarRef<ConfirmSnackbarComponent> = this.snackbar.openFromComponent(ConfirmSnackbarComponent, {
            data: {
                message: message,
                isOpen: this.isOpen,
                cancelText: cancelText,
                okText: okText,
                disableClose: true
            },
            panelClass: ['snack-bar-confirm']
        });
        snackbarRef.afterDismissed().subscribe(() => {
            this.snackbarOpenSubject.next(false);
        });
        snackbarRef.afterOpened().subscribe(() => {
            this.snackbarOpenSubject.next(true);
        });
        return snackbarRef;
    }
}
