<div class="row" style="margin-top:20px;">
  <mat-form-field (mouseenter)="hint='Model_Type'">
    <mat-label>Model Type</mat-label>
    <mat-select [(ngModel)]="config.model_type" name="model_types">
      <mat-option *ngFor="let model_type of model_types" [value]="model_type">
        {{model_type| upperfirst}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="config.model_type" class="row">
  <ng-container *ngIf="config?.component_list && ['series','process'].includes(config.model_type)">
    <div (mouseenter)="hint='Process'" class="select-search" style="margin-right:0;">
      <select-many-search-component
            [api_model]="'process_light'"
            [value]="config.component_list"
            [classes]="'fill'"
            (selectionChange)="processChange($event)"
            [label]="'Select a process'">
        </select-many-search-component>
    </div>
  </ng-container>
  <div *ngIf="['series'].includes(config.model_type)"
       style="align-self: center;font-weight: bold;padding:0 10px; justify-self: center;">and/or
  </div>
  <ng-container *ngIf="config?.series_list && ['series'].includes(config.model_type)">
    <div (mouseenter)="hint='Series'" class="select-search" style="position:relative;">
      <select-many-series
      [value]="config.series_list"
            [classes]="'fill'"
            (selectionChange)="seriesChange($event)"
      ></select-many-series>
    </div>
  </ng-container>
</div>
