import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {Model} from "../services/api/model";
import { HttpClient } from "@angular/common/http";
import {HttpErrorHandler} from "../services/http-error-handler.service";
import {CacheManager} from "../services/api/caching/cacheManager";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {Observable} from "rxjs";
import {ListResponse, SingleResponse} from "../services/api/response-types";
import {concatMap} from "rxjs/operators";
import {deepCopy} from "../lib/utils";
import {Role} from "./role";


export interface UserJson {
    name: string;
    url: string;
}

export class UserV1Attributes extends AuditMixinAttributes {
    keycloak_id: string;
    keycloak_user:  boolean;
}

export class UserV2Attributes extends AuditMixinAttributes {
    username: string;
    email: string;
    name: string;
    alias?: string;
    middle_name?: string;
    last_name?: string;
    restricted_access?: boolean;
    is_super?: boolean;
    active?: boolean;
    json?: UserJson[];
    require_otp?: boolean;
    sms?: string;
    email_disabled?: boolean;
    sms_disabled?: boolean;
    role_names?: string[];
    feature_names?: string[];
    is_active?: boolean;
}

export class UserRelationships extends AuditMixinRelationships {
    default_account: SINGLE_RELATIONSHIP_TYPE;
    user_meta: SINGLE_RELATIONSHIP_TYPE;
    roles: LIST_RELATIONSHIP_TYPE;
    series: LIST_RELATIONSHIP_TYPE;
    alerts: LIST_RELATIONSHIP_TYPE;
    pages: LIST_RELATIONSHIP_TYPE;
    groups: LIST_RELATIONSHIP_TYPE;
    accounts: LIST_RELATIONSHIP_TYPE;
    default_dashboards: LIST_RELATIONSHIP_TYPE;
}

export class UserV1 extends AuditMixin {
    id: string;
    type: string = 'users';
    attributes: UserV1Attributes;
    relationships: UserRelationships;
    roles?: Role[];

    constructor() {
        super();
        this.attributes = new UserV1Attributes();
        this.relationships = new UserRelationships();
    }
}


class UserAttributes extends UserV2Attributes {
    keycloak_id?: string;
    keycloak_user?: boolean;
}


/**
 * Combined User object returned from a combined request on /v2/users then /api/users
 */
export class User extends AuditMixin {
    id: string;
    type: string = 'users';
    attributes: UserAttributes;
    relationships: UserRelationships;
    roles?: Role[];

    constructor() {
        super();
        this.attributes = new UserAttributes();
        this.relationships = new UserRelationships();
    }
}


export class UserModel extends Model {
    private user_v1: Model<UserV1>;
    private user_v2: Model<User>;
    private attributes_list = ['email', 'name', 'alias', 'middle_name', 'last_name', 'restricted_access',
        'is_super', 'json', 'require_otp', 'sms', 'email_disabled', 'sms_disabled', 'active'];

    constructor(http: HttpClient,
                httpErrorHandler: HttpErrorHandler,
                cacheManager: CacheManager,
                shouldCache: boolean = true,
                cacheTimeout: number = 43200000) {
        super(http, httpErrorHandler, '', 'users_full', cacheManager, 'users_full', shouldCache, cacheTimeout);
        this.user_v1 = new Model<UserV1>(http, httpErrorHandler, '/api/users', 'users', cacheManager, 'users', shouldCache, cacheTimeout);
        this.user_v2 = new Model<User>(http, httpErrorHandler, '/api/v2/users', 'users_v2', cacheManager,
            'users_v2', shouldCache, cacheTimeout);
    }

    searchMany(options?: SearchQueryOptions): Observable<ListResponse<User>> {
        return this.user_v2.searchMany(options);
    }

    searchSingle(options?: SearchQueryOptions): Observable<SingleResponse<User>> {
        return this.user_v2.searchSingle(options);
    }

    getById(id: string, options?: SearchQueryOptions): Observable<SingleResponse<User>> {
        return this.user_v2.getById(id, options);
    }

    patch(item: User): Promise<any> {
        return this.obsPatch(item).toPromise();
    }

    obsPatch(item: User): Observable<SingleResponse<User>> {
        // id is included for the routing of the PATCH request
        let relationships = {id: item.id};
        let patch_user = deepCopy(item);
        Object.keys(patch_user.relationships).forEach(relation => {
            if (!['created_by', 'changed_by', 'created_on', 'changed_on'].includes(relation)) {
                // remove audit fields before saving
                relationships[relation] = item.relationships[relation];
            }
        });
        delete patch_user.attributes;

        // Removing  non-keycloak fields by type casting
        let attributes = {id: item.id};
        this.attributes_list.forEach(user_attr => {
            attributes[user_attr] = item.attributes[user_attr];
        });
        return this.user_v2.obsPatch(attributes).pipe(
            concatMap(() => {
                return this.user_v1.obsPatch(patch_user) as Observable<SingleResponse<User>>;
            })
        );
    }
}
