import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModelUploadOptions} from "../upload-model-form-dialog.component";
import {
    PARSER_CONFIG_TYPES,
    ParserConfigTemplate,
    ParserConfigTemplateAttributes
} from "../../../_models/parser-config-template";
import {UploadModelTemplateService} from "../upload-model-template.service";
import {takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {cloneDeep} from "lodash-es";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'upload-model-form-template',
    templateUrl: './upload-model-form-template.component.html',
    styleUrls: ['./upload-model-form-template.component.css'],
    standalone: false
})
export class UploadModelFormTemplateComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public _model_type: PARSER_CONFIG_TYPES;

    // templates: ParserConfigTemplate[] = [];

    // The template from either the predefined templates or the current form configuration
    selected_template: ParserConfigTemplate;
    current_options: any;

    // Helper for select component
    idCompare: (a, b) => boolean;

    @Input()
    set model_type(value: PARSER_CONFIG_TYPES) {
        /* load templates with this type */
        this._model_type = value;
        this.clearSelectedTemplate();
    }

    constructor(
        private notification: NotificationService,
        public templateService: UploadModelTemplateService
    ) {
        this.idCompare = (a, b) => {
            try {
                return a.id === b.id;
            } catch {
                return a === b;
            }
        };
    }

    ngOnInit(): void {
        this.templateService.templateChanged$.pipe(takeUntil(this.onDestroy)).subscribe(template => {
            this.selected_template = template;
        });
        this.templateService.stateChanged$.pipe(takeUntil(this.onDestroy)).subscribe(options => {
            this.current_options = options;
            const value: ModelUploadOptions = options as ModelUploadOptions;
            if (!this.selected_template) {
                this.clearSelectedTemplate();
            }
            this.selected_template.attributes.json = value;
        });
    }

    public selectionChanged(value: any) {
        // this.selected_template = value;
        // const options = this.selected_template.attributes.json;
        value = cloneDeep(value);
        let msg: string = `Loaded template ${this.selected_template.attributes.name}`;
        if (!value.attributes.json) {
            msg = `${msg}, although it had no options to load.`;
        }
        this.notification.openSuccess(msg, 5000);
        this.templateService.selectTemplate(value);
    }

    public add() {
        /* create a new template with the current name, type and options */
        this.templateService.add(this.selected_template).pipe(tap({
        next: (response) => {
            this.selected_template = response.data;
            this.notification.openSuccess(`Created template ${this.selected_template.attributes.name}`, 5000);
            this.templateService.refreshTemplateList();
        }, error: (error) => {
            this.parseDetailError(error, 'Add');
        }})).subscribe();
    }

    private parseDetailError(error, action: string) {
        try {
            const detail: string = error.error.errors[0].detail;
            if (detail.includes('UniqueViolation')) {
                this.notification.openError('A template already exists with this name, please choose another name.');
            } else {
                console.warn(`Had detail error for template ${action}: ${detail}`);
                this.notification.openError(`An error occurred with template ${action}.`);
            }
        } catch (e) {
            console.error(`Unknown error when ${action}ing template.`, e);
            this.notification.openError(`Unknown error when ${action}ing template.`);
        }
        console.warn('Error adding template', error);
    }

    public update() {
        /* update the name or options of the selected template */
        this.templateService.update(this.selected_template).pipe(tap({
            next: (response) => {
                this.selected_template = response.data;
                this.notification.openSuccess(`Updated template ${this.selected_template.attributes.name}`, 5000);
                this.templateService.refreshTemplateList();
        }, error: (err) => {
            this.parseDetailError(err, 'Update');
        }})).subscribe();
    }

    public remove() {
        /* delete the selected template */
        this.templateService.delete(this.selected_template).pipe(tap({
            next: () => {
                this.notification.openSuccess(`Removed template ${this.selected_template.attributes.name}`, 5000);
                const json_tmp = this.selected_template.attributes.json;
                this.clearSelectedTemplate();
                this.selected_template.attributes.json = json_tmp;
                this.templateService.refreshTemplateList();
        }, error: (err) => {
            console.warn('Error deleting template', err);
        }})).subscribe();
    }

    private clearSelectedTemplate() {
        this.selected_template = new ParserConfigTemplate();
        this.selected_template.attributes = new ParserConfigTemplateAttributes();
        this.selected_template.attributes.name = 'New ' + this._model_type + ' Template';
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
