import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ApiService} from '../../services/api/api.service';
import {Series} from '../../_models/series';
import {KeyMap} from '../../_typing/generic-types';
import {getBaseFilter} from "../../services/api/filter_utils";

export interface VDTState {
    height?: number;
    width?: number;
    pan?: string;
    depth?: KeyMap<number[]>
}

export class VDTTileConfig {
    selected_calculation: Series;
    state: VDTState;
    formula_view: boolean;

    static resetState(): VDTState {
        return {height: null, width: null, pan: null, depth: null};
    }
}

@Component({
    selector: 'value-driver-tree-form',
    templateUrl: './value-driver-tree-form.component.html',
    styleUrls: ['./value-driver-tree-form.component.less'],
    standalone: false
})
export class ValueDriverTreeFormComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() config: VDTTileConfig = {
        selected_calculation: null,
        state: VDTTileConfig.resetState(),
        formula_view: false
    };
    @Input() series_list: Series[];
    calculation_list_filter: any[];

    constructor(private api: ApiService) {
    }

    ngOnInit(): void {
        this.calculation_list_filter = [getBaseFilter('calculation', 'base_type')];
    }

    updateSelectedCalc($event) {
        this.config.selected_calculation = $event.value;
        this.config.state = VDTTileConfig.resetState();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
