<div class="page">
  <section>
    <div #utils_bar class="row hideonprint utils-bar" style="align-items: center;justify-content: space-between;">
      <div class="table-filter">
        <div class="row" style="position:relative;">
          <input [(ngModel)]="filterString" placeholder="Search table" style="height:30px;"/>
          <i class="fa fa-times" matTooltip="Clear database search" (click)="filterString='';updateSearchFilter();"
             style="position: absolute; right: 10px;font-size: 1.8rem;padding-top: 3px;color: #888888;cursor:pointer;"></i>
        </div>
        <button (click)="updateSearchFilter()"
                mat-button class="bordered-button" style="height:30px;margin:0 20px 0 4px;min-width:70px;">Search
        </button>
      </div>

      <mat-paginator [pageSizeOptions]="pageSizeOptions.concat(numSolverTemplates)"
                     showFirstLastButtons
                     style="justify-self: flex-end;">
      </mat-paginator>

    </div>

    <div class="table-container">
      <table [dataSource]="dataSource" [matSortActive]="columns[0]"
             class="event-types-table" mat-table matSort [matSortDirection]="'asc'">
        <ng-container *ngFor="let column of columns;">
          <!--Name-->
          <ng-container *ngIf="column==='name'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let solverTemplate;" mat-cell style="text-align: left;cursor:pointer;">
              <div class="row center">
                <i class="edit-inline hide-xs" (click)="editSolverTemplate(solverTemplate)"></i>
              <span class="material-symbols-outlined" (click)="openRunSolver(solverTemplate)" style="color: gray;margin-right: 2px;">play_circle</span>
                <div [textContent]="solverTemplate.attributes.name" style="padding-top:2px;"></div>
              </div>
            </td>
          </ng-container>

          <!--Description-->
          <ng-container *ngIf="column==='description'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let solverTemplate" mat-cell> {{solverTemplate.attributes.description}} </td>
          </ng-container>

          <!-- ToDo Constraints -->
          <!--Constant Properties-->
<!--          <ng-container *ngIf="column==='constant_properties'" matColumnDef="{{column}}">-->
<!--            <th *matHeaderCellDef mat-header-cell>{{column | upperfirst | removeUnderscore}}</th>-->
<!--            <td *matCellDef="let solverTemplate" class="large-cell" mat-cell style="text-align: left;border:none;">-->
<!--              <constant-property-list [parent]="component_type"></constant-property-list>-->
<!--            </td>-->
<!--          </ng-container>-->

          <!--Account Name-->
          <ng-container *ngIf="column==='account'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let solverTemplate" mat-cell> {{solverTemplate.attributes.account_name}} </td>
          </ng-container>

          <!--Created On-->
          <ng-container *ngIf="column==='created_on'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Created</th>
            <td *matCellDef="let solverTemplate" mat-cell> {{solverTemplate.attributes.created_on | stringDate}} </td>
          </ng-container>

          <!--Created By-->
          <ng-container *ngIf="column==='created_by_name'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Created By</th>
            <td *matCellDef="let solverTemplate" mat-cell> {{solverTemplate.attributes.created_by_name}} </td>
          </ng-container>

          <!--Changed On-->
          <ng-container *ngIf="column==='changed_on'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Changed</th>
            <td *matCellDef="let solverTemplate" mat-cell> {{solverTemplate.attributes.changed_on| stringDate}} </td>
          </ng-container>

          <!--Changed By-->
          <ng-container *ngIf="column==='changed_by_name'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Changed By</th>
            <td *matCellDef="let solverTemplate" mat-cell> {{solverTemplate.attributes.changed_by_name}} </td>
          </ng-container>

        </ng-container>
        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>
    </div>
  </section>
</div>
