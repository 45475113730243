import {Component, Input} from '@angular/core';
import {JsonContextService} from "../json-context.service";
import {KeyMap} from "../../../_typing/generic-types";
import {ReportParameters} from "../type/report-parameters";
import {takeUntil} from "rxjs";
import {BaseComponent} from "../../../shared/base.component";
import {JsonContextTagService} from "../json-context-tag.service";
import {deepCopy} from "../../../lib/utils";

@Component({
    selector: 'report-tag-generator',
    templateUrl: './report-tag-generator.component.html',
    styleUrls: ['./report-tag-generator.component.less'],
    standalone: false
})
export class ReportTagGeneratorComponent extends BaseComponent {
    @Input() config: ReportParameters;

    public seriesTags: KeyMap<KeyMap<string>> = {};
    public functionTags: KeyMap<string> = {};
    public componentTypeTags: KeyMap<KeyMap<string>> = {};
    public componentTags: KeyMap<KeyMap<string>> = {};
    public componentTypePropertyTags: KeyMap<string> = {};
    public componentPropertyTags: KeyMap<KeyMap<string>> = {};

    public showSeriesTags: boolean = false;
    public showComponentTypeTag: boolean = false;
    public showComponentRelationshipTags: boolean = false;
    public showComponentTypePropertyTags: boolean = false;
    public showComponentPropertyTags: boolean = false;
    public showFunctionTags: boolean = false;

    constructor(private jsonTagService: JsonContextTagService,
                private jsonContextService:JsonContextService) {
        super();

    }

    ngOnInit() {
        this.jsonContextService.config$.pipe(takeUntil(this.onDestroy)).subscribe((config) => {
            this.config = Object.assign((this.config || {}), config);
            this.getTags();
        });
        this.getTags();
    }

    getTags() {
        this.seriesTags = this.jsonTagService.getSeriesTags(this.config);
        this.functionTags = this.jsonTagService.getFunctionTags(this.config);
        this.componentTypeTags = this.jsonTagService.getComponentTypeTags([this.config.selectedComponentType], this.config.selectedColumns?.componentType);
        this.componentTags = this.jsonTagService.getComponentTypeTags(this.config.selectedComponentRelationshipTypes, this.config.selectedColumns?.component);
        console.log("this.componentTags",this.componentTags);
        this.componentTypePropertyTags = this.jsonTagService.getPropertyTags(this.config.selectedColumns.componentType);
        this.componentPropertyTags = this.jsonTagService.getComponentPropertyTags(this.config);
    }

    copyToClipboard(tagValue: string) {
        this.jsonTagService.copyToClipboard(tagValue);
    }
}
