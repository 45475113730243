import {Injectable} from "@angular/core";
import DOMPurify, {HookEvent} from "dompurify";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ALLOWED_TAGS, ALLOWED_ATTR} from "../shared/globals";
import {isAttributeHook} from "../_typing/utils/sanitise";

@Injectable({
    providedIn: 'root'
})

export class SanitiseService {

    disallowedTags: Set<string> = new Set();

    constructor(private domSanitizer: DomSanitizer) {
        // Hook into DOMPurify to capture disallowed tags
        DOMPurify.addHook('uponSanitizeElement', (node: Element, data: HookEvent) => {
            if (!isAttributeHook(data) && !data.allowedTags[node.nodeName.toLowerCase()]) {
                this.disallowedTags.add(node.nodeName.toLowerCase());
            }
        });

        // Hook into DOMPurify to capture disallowed attributes
        DOMPurify.addHook('uponSanitizeAttribute', (node: Element, data: HookEvent) => {
            if (isAttributeHook(data) && !data.allowedAttributes[data.attrName]) {
                this.disallowedTags.add(`${node.nodeName.toLowerCase()}[${data.attrName}]`);
            }
        });
        DOMPurify.addHook('beforeSanitizeAttributes', (node: Element) => {
            // console.log("Tag ", node.tagName);
            if (!this.isSafeSrc(node)) {
                node.removeAttribute('src');
            }
        });
    }

    private isSafeSrc(node: Element): boolean {
        const tag = node.tagName?.toLowerCase();
        if (!(tag === 'iframe' || tag === 'img')) return true;

        const src = node.getAttribute('src');
        //console.log("Src: ", src, DOMPurify.isValidAttribute(tag, 'src', src), this.isSafeUrl(src));
        if (src &&
            !(DOMPurify.isValidAttribute(tag, 'src', src) && this.isSafeUrl(src))) {
            return false;
        }
        return true;
    }

    sanitise(htmlString: string): string {
        this.disallowedTags.clear();
        const cleanHtml: string = DOMPurify.sanitize(htmlString, {
            ALLOWED_TAGS: ALLOWED_TAGS,
            ALLOWED_ATTR: ALLOWED_ATTR,
            WHOLE_DOCUMENT: true,  // Allow sanitizing of the whole document, including <head>
        });
        return cleanHtml;
    }

    htmlToSave(htmlString: string) {
        return this.sanitise(htmlString);
    }

    htmlToDisplay(htmlString: string): SafeHtml {
        const cleanHtml: string = this.sanitise(htmlString);
        console.log("cleanHtml", cleanHtml);
        return this.domSanitizer.bypassSecurityTrustHtml(cleanHtml);
    }

    getUnsafeHtml(htmlString: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(htmlString);
    }

    getDisallowedTags(): string[] {
        return Array.from(this.disallowedTags);
    }

    private isSafeUrl(url: string): boolean {
        const allowedProtocols = ['http:', 'https:', 'data:'];
        try {
            const parsedUrl = new URL(url, window.location.href);

            // Check if the protocol is allowed
            if (!allowedProtocols.includes(parsedUrl.protocol)) {
                return false;
            }

            // Additional check to ensure the URL does not contain any script
            const scriptPattern = /<script[\s\S]*?>[\s\S]*?<\/script\s*>/gi;
            if (scriptPattern.test(url)) {
                return false;
            }

            // Ensure the URL does not contain `javascript:` scheme
            if (parsedUrl.href.toLowerCase().startsWith('javascript:')) {
                return false;
            }

            return true;
        } catch {
            return false;
        }
    }
}
