import {JobNotificationComponent} from '../notifications/job-notification/job-notification.component';
import {Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {
    CalculationErrorNotificationComponent
} from "../notifications/calculation-error-notification/calculation-error-notification.component";

export interface ISnackbarParams {
    message: string;
    styleType: string;
    duration: number;
    action: string;
}

export type OptionalSnackbarParams = Partial<ISnackbarParams>;

export interface INotificationComponent {
    data: any;
}

export interface INotificationComponentConstructor {
    new(...args: any[]): INotificationComponent;
}

export type DynamicNotificationComponent = JobNotificationComponent | CalculationErrorNotificationComponent;

export abstract class NotificationComponentBase {
    constructor(public snackBarRef: MatSnackBarRef<any>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }
}
