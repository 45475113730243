import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {first, map, takeUntil} from "rxjs/operators";
import {ListResponse, SingleResponse} from '../services/api/response-types';
import {ApiService} from '../services/api/api.service';
import {ConstantProperty} from '../_models/constant-property';
import {GenericDataService, ObjectListByRelationship} from "./generic-data.service";
import {getManyRelationWithIdFilter, getManyRelationWithIdsFilter} from "../services/api/filter_utils";
import {ModelID} from '../_typing/generic-types';

export interface PropByRelationshipResponse {
    constant_properties: ConstantProperty[],
    cp_dict: { [key: string]: ConstantProperty },
    props_list_dict: { [key: string]: string[] }
}

/**
 * This class is for shared functions relating to data access on the constant_property api
 **/
@Injectable({
    providedIn: 'root'
})
export class ConstantPropertyDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private dataService: GenericDataService) {
    }

    /**Streams constant_properties by parent_ids
     * Returns an object of type PropByParentResponse
     * @param parent_ids:string[]
     * @param parent_type:string
     * @param names:string[] (optional) e.g. when column names are provided
     * e.g. Get all properties for given component_type ids
     */
    streamPropertiesByRelationshipIds(rel_ids: string[], rel_name: string, ids?: string[], names?: string[]): Observable<PropByRelationshipResponse> {
        return this.dataService.streamObjectListByRelationshipIds(rel_ids, rel_name, 'constant_property_light', ids, names,
            null, 'name').pipe(
            map((response: ObjectListByRelationship<ConstantProperty>) => {
                return {
                    constant_properties: response?.object_list || [],
                    cp_dict: response?.object_dict || {},
                    props_list_dict: response?.relationship_object_list_dict || {}
                };
            }));
    }

    /**Gets constant_properties for mmRelationship children by parent_ids
     * e.g. get all properties for all event_types belonging to given component_types
     * Returns ListResponse<ConstantProperty>
     * @param parent_ids:string[]
     * @param parent_type:string
     * @param child_type:string
     * e.g. Get all properties for event_types where event_type is linked to given component_type ids
     */
    getChildPropertiesByParentIds(parent_ids: string[], parent_type: string, child_type: string): Observable<ListResponse<ConstantProperty>> {
        const options = new SearchQueryOptions();
        options.filters = [{
            and: [
                {
                    name: child_type,
                    op: 'any',
                    val: {
                        and: [
                            {
                                name: parent_type,
                                op: 'any',
                                val: {name: 'id', op: 'in', val: parent_ids}
                            }]
                    }
                }]
        }];
        return this.api.constant_property_light.searchMany(options).pipe(
            first(), takeUntil(this.onDestroy)
        );
    }

    generatePropertiesByRelationshipIdsFilter(rel_name: string, rel_ids: ModelID[], ids?: string[], names?: string[], data_types?: string[]): any[] {
        let filters = [];
        /**Use 'eq' comparison if searching by single id as it's quicker**/
        if (rel_ids.length === 1) {
            filters = [{
                and: [getManyRelationWithIdFilter(rel_name, rel_ids[0])]
            }];
        } else {
            filters = [{
                and: [getManyRelationWithIdsFilter(rel_name, rel_ids)]
            }];
        }
        if (ids && ids.length > 0) {
            filters.push({name: 'id', op: 'in', val: ids});
        }
        if (names) {
            filters.push({name: 'name', op: 'in', val: names});
        }
        if (data_types) {
            filters.push({name: 'data_type', op: 'in', val: data_types});
        }
        return filters;
    }

    /**Gets constant_properties for a single parent and optionally a constant_property name or type (float, string, datetime)
     * Returns ListResponse<ConstantProperty>
     * @param parent_id:string
     * @param parent_type:string
     */
    getPropertiesByRelationshipId(rel_id: string, rel_type: string, ids?: string[], names?: string[], data_types?: string[]): Observable<ListResponse<ConstantProperty>> {
        return this.getConstantPropertiesByRelationshipIds([rel_id], rel_type, ids, names, data_types);
    }

    /**Gets constant_properties for a multiple parents of specific type
     *  and optionally a constant_property name or type (float, string, datetime)
     *  This is essentially the same as "streamPropertiesByParentIds" but without the streaming and sorting data by parent
     * Returns ListResponse<ConstantProperty>
     * @param parent_id:string
     * @param parent_type:string
     */
    getConstantPropertiesByRelationshipIds(rel_ids: string[], rel_name: string, ids?: string[], names?: string[], data_types?: string[]): Observable<ListResponse<ConstantProperty>> {
        const options = new SearchQueryOptions();
        options.filters = this.generatePropertiesByRelationshipIdsFilter(rel_name, rel_ids, ids, names, data_types);
        return this.api.constant_property_light.searchMany(options).pipe(
            first(),
            takeUntil(this.onDestroy)
        );
    }

    getConstantProperties(property_ids: ModelID[]): Observable<ListResponse<ConstantProperty>> {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: property_ids}];
        return this.api.constant_property_light.searchMany(options);
    }

    getUniquePropertiesForEventType(event_type_id: ModelID): Observable<ListResponse<ConstantProperty>> {
        const options = new SearchQueryOptions();
        options.filters = [
            {
                name: 'constant_property_event_types', op: 'any', val: {
                    and: [
                        {name: 'event_type', op: 'has', val: {name: 'id', op: 'eq', val: event_type_id}},
                        {op: 'eq', name: 'unique_values', val: true}
                    ]
                }
            }
        ]
        return this.api.constant_property.searchMany(options).pipe(
            first(),
            takeUntil(this.onDestroy)
        );
    }

    getPropertyLightById(cpId:ModelID):Observable<SingleResponse<ConstantProperty>>{
        return this.dataService.getModelById<ConstantProperty>('constant_property_light',cpId);
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
