<div class="page traveler">
  <div class="row" style="justify-content: center">
    <select-search style="margin-right: 20px;width:300px;"
                   *ngIf="process_data_ready"
                   label="Parent Process"
                   [data]="process_data"
                   (valueChange)="selected_process = $event">
    </select-search>
    <select-search style="width:200px;"
                   *ngIf="forecasts"
                   label="Forecast"
                   [data]="forecasts"
                   (valueChange)="forecast = $event">
    </select-search>
  </div>

  <weather-widget></weather-widget>

  <table *ngIf="dataSource!==undefined && data_ready && columns" mat-table
         [dataSource]="dataSource"
         style="min-width: 100%;"
         class="water-balance-table padded-water-table table-sortable table-striped table-bordered table-hover table-responsive">
    <ng-container [matColumnDef]="columns[0]">
      <th mat-header-cell *matHeaderCellDef
          [textContent]="columns[0]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Name']" style="text-align: left"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[1]">
      <th mat-header-cell *matHeaderCellDef
          title="The last manually entered level"
          [textContent]="columns[1]"></th>
      <td mat-cell *matCellDef="let row"
          [ngStyle]="{'background-color': row['LevelColorMap'](row['Lvl']), 'color': row['TextColorMap'](row['Lvl'])}"
          [textContent]="row['Lvl'] | number:'1.0-1'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[2]">
      <th mat-header-cell *matHeaderCellDef
          title="The current calculated level (with all level changes since last manual entry taken into account)"
          [textContent]="columns[2]"></th>
      <td mat-cell *matCellDef="let row"
          [ngStyle]="{'background-color': row['LevelColorMap'](row['CalculatedLevel']), 'color': row['TextColorMap'](row['CalculatedLevel'])}"
          [textContent]="(row['CalculatedLevel'] | number:'1.0-1') + ' %'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[3]">
      <th mat-header-cell *matHeaderCellDef
          title="The volume of the water body, based on the calculated level"
          [textContent]="columns[3]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Volume'] | number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[4]">
      <th mat-header-cell *matHeaderCellDef
          title="The total volume that flowed into the water body since the start of the date range"
          [textContent]="columns[4]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['In'] | number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[5]">
      <th mat-header-cell *matHeaderCellDef
          title="The total volume that flowed out of the water body since the start of the date range"
          [textContent]="columns[5]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Out'] | number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[6]">
      <th mat-header-cell *matHeaderCellDef
          title="The nett change in volume since the start of the date range"
          [textContent]="columns[6]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Change']| number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[7]">
      <th mat-header-cell *matHeaderCellDef
          title="The total forecasted volume that will flow into the water body until the end of the date range"
          [textContent]="columns[7]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['In Fcast'] | number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[8]">
      <th mat-header-cell *matHeaderCellDef
          title="The total forecasted volume that will flow out of the water body until the end of the date range"
          [textContent]="columns[8]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Out Fcast'] | number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[9]">
      <th mat-header-cell *matHeaderCellDef
          title="The total forecasted volume of fluid that will overflow until the end of the date range"
          [textContent]="columns[9]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Overflow FCast'] | number:'1.0-0'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[10]">
      <th mat-header-cell *matHeaderCellDef
          title="The nett change in volume until the end of the date range"
          [textContent]="columns[10]"></th>
      <td mat-cell *matCellDef="let row" [textContent]="row['Change Fcast'] | number:'1.0-1'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[11]">
      <th mat-header-cell *matHeaderCellDef
          title="The water body level based on the forecasted change until the end of the date range"
          [textContent]="columns[11]"></th>
      <td mat-cell *matCellDef="let row"
          [ngStyle]="{'background-color': row['LevelColorMap'](row['Lvl-Fcast']), 'color': row['TextColorMap'](row['Lvl-Fcast'])}"
          [textContent]="(row['Lvl-Fcast'] ? (row['Lvl-Fcast'] | number:'1.0-1'):'') + ' %'"></td>
    </ng-container>

    <ng-container [matColumnDef]="columns[12]">
      <th mat-header-cell *matHeaderCellDef
          [title]="sample_period<=24*3 ?
              'The water body level in the event of three forecasted storm days totalling 246mm of rainfall' :
              'The water body level in the event of a 1 in 100 wet year forecast'"
          [textContent]="columns[12]"></th>
      <td mat-cell *matCellDef="let row"
          [ngStyle]="{'background-color': row['LevelColorMap'](row['Lvl-XFcast']), 'color': row['TextColorMap'](row['Lvl-XFcast'])}"
          [textContent]="(row['Lvl-XFcast'] ? (row['Lvl-XFcast'] | number:'1.0-1') : '') + ' %'"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;" [detailRow]="row" [detailRowTemplate]="tpl"></tr>
  </table>

  <ng-template #tpl let-row>
    <div style="height:4px;"></div>
    <tr [@detailExpand] class="mat-row detail-row" style="overflow: hidden; padding-left: 2px">
      <td class="expand-detail" [colSpan]="5">
        <water-balance-detail-view [row]="row" (DFsUpdated)="updateDFs($event.changed_row, $event.changed_stream, $event.parent_stream)"></water-balance-detail-view>
      </td>
      <td class="expand-chart" [colSpan]="8">
        <water-balance-chart style="width:30em"
                             [config]="row.chart.config"
                             [columns]="row.chart.columns">
        </water-balance-chart>
      </td>
    </tr>
    <div style="height:8px;"></div>
  </ng-template>
</div>
