import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {map, take} from "rxjs/operators";
import {AppScope} from '../../services/app_scope.service';
import {EventTypeService} from '../../services/event_type.service';
import {forkJoin, Subject} from 'rxjs';
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'ore-body-group-form',
    templateUrl: './ore-body-group-form.component.html',
    styleUrls: ['./ore-body-group-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class OreBodyGroupFormComponent implements OnInit {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event = new EventEmitter();
    @Output() close_event = new EventEmitter();

    @ViewChild('imageUpload', {static: true}) fileUpload: ElementRef<HTMLInputElement>;
    @ViewChild('selectedImage') selectedImage: ElementRef;

    ore_body_groups: any[];
    filtered_obgs: any[];
    ore_body_group: any;
    ore_bodies: any[];
    ore_body_group_types: any[];
    selected_obgt: any;
    selected_ore_body: any = {};

    images: any[];
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private appScope: AppScope,
                private eventTypeService: EventTypeService,
                private http: HttpClient) {
        const ctrl = this;

    }

    ngOnInit() {
        const ctrl = this;
        this.ore_body_group = this.component.data;

        this.getOreBodyGroups();
    }

    getOreBodyGroups() {
        const $ore_body_groups = this.api.ore_body_group.searchMany().pipe(take(1),
            map(result => {
                this.ore_body_groups = result.data;
                return result;
            }));
        const $ore_body_group_types = this.api.ore_body_group_type.searchMany().pipe(take(1),
            map(result => {
                this.ore_body_group_types = result.data;
                return result;
            }));

        forkJoin([$ore_body_groups, $ore_body_group_types]).subscribe(result => {
            if (this.ore_body_group.id && this.ore_body_group.relationships.type) {
                this.selected_obgt = this.ore_body_group_types.find(obgt => this.ore_body_group.relationships.type.data.id === obgt.id);
            } else if (this.ore_body_group_types.length > 0) {
                this.selected_obgt = this.ore_body_group_types[0];
            }
            this.obgtChanged();
        })
    }


    obgtChanged() {
        this.filtered_obgs = this.ore_body_groups.filter(obg => obg.relationships.type.data.id === this.selected_obgt.id);
    }

    save() {
        this.save_event.emit(this.ore_body_group);
    }

    close(): void {
        this.close_event.emit();
    }


    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
