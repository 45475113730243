<div class="custom-events-table-tile" [appLdsRoller]="loading_next" [style.height]="table_height">

  <div #utils_bar class="row hideonprint utils-bar">
    <div class="row justify-start table-filter">

      <utils-bar [search]="true"
                 (searchValueSubmitted)="filter_string=$event;updateSearchFilter()"
                 (searchCleared)="clearSearch()"
                 [userSearchFields]="user_search_fields"
                 (searchSelectionsChanged)="user_search_selections=$event"
                 [sortByArray]="column_ids"
      >
      </utils-bar>
      <button mat-flat-button class="search-button" matTooltip="Insert new row" (click)="addEmptyRow()">
        <i class="fa fa-plus"></i>
      </button>
      <filter-table-button [config]="filter_button_config"
                           (queryChanged)="updateFilterQuery($event)"></filter-table-button>
    </div>

    <mat-paginator [pageSizeOptions]="page_size_options.concat(events_total)"
                   showFirstLastButtons
                   style="justify-self: flex-end;">
    </mat-paginator>

  </div>

  <div class="table-container" overlay-scrollbars [defer] [options]="{scrollbars: {autoHide: 'leave'}}">
    <table [dataSource]="dataSource" matSort class="custom-events-table" mat-table [matSortActive]="'Start'"
           [matSortDirection]="'asc'">

      <ng-container *ngFor="let column of column_ids;">

        <!--Index-->
        <ng-container *ngIf="column==='Index'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let event;index as i;" class="index-column" mat-cell>
            <div [matTooltipDisabled]="!customEventsService.event_state[event.id]"
                 [matTooltip]="customEventsService.event_state[event.id]? customEventsService.event_state[event.id].message : isTempId(event)? 'New Event' : ''"
                 [ngClass]="customEventsService.event_state[event.id]? customEventsService.event_state[event.id].state: ''">
              <i class="fas fa-asterisk" [ngClass]="{'new-row':isTempId(event) }"></i>
            </div>
          </td>
        </ng-container>

        <!--Select-->
        <ng-container *ngIf=" column==='Select'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell
              style="padding:0;text-align:center;width:40px;max-width:40px;">
            <mat-checkbox (change)="selectAll($event)" (click)="$event.stopPropagation()"
                          matTooltip="Select/Clear all"></mat-checkbox>
          </th>
          <td *matCellDef="let event;index as i;" class="select-column" mat-cell>
            <mat-checkbox (change)="selectEvent(event, $event)" [checked]="selection_dict[event.id]"></mat-checkbox>
          </td>
        </ng-container>

        <!--Type-->
        <ng-container *ngIf="column==='type'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event"
              [constrainWidth]="config.constrain_width">{{column_dict[column].title || column | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" mat-cell class="attribute-field">
            <div class="row center" [ngStyle]="formats ? formats[column][event.id]?.style : null">
              <select-filter
                  [disabled]="config.event_types.length <= 1 || !isTempId(event) || column_dict[column].disabled"
                  (constant_changed)="updateSelectedEventType($event, event)"
                  [modelValue]="event.relationships.event_type.data"
                  [options]="event_types"
                  [formats]="formats ? formats[column][event.id] : null"
                  [filter_compare]="customEventsService.compareById"
                  [classes]="'fill show-select-arrow'">
              </select-filter>
            </div>
          </td>
        </ng-container>

        <!--Start/End-->
        <ng-container *ngIf="column==='start' || column==='end'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-sort-header mat-header-cell [constrainWidth]="config.constrain_width"
              [resizable]="format_dict[column]" (resizable)="format_dict[column].width=$event">
            {{column_dict[column].title || column | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" class="date-picker lock-indicator" mat-cell style="position:relative;">
            <audit-mixin-indicator *ngIf="column==='start'"
                                   [model]="event"></audit-mixin-indicator>
            <i *ngIf="locked_dict[event.id] && locked_dict[event.id][column.toLowerCase()]"
               (click)="checkLock(event, column)" class="fa fa-lock lock-indicator-icon"
               [style.cursor]="can_unlock? 'pointer':'default'"></i>
            <date-time-picker (date_changed)="event.attributes.start = $event;detectChange(event, column)"
                              *ngIf="column==='start'"
                              [disabled]="column_dict[column].disabled || locked_dict[event.id]?.start"
                              [default]="dtp.start"
                              [input_date]="event.attributes.start"
                              [set_default]="true"
                              [appFitContent]="event.attributes.start"
                              [allowFit]="format_dict[column].allow_fit"
                              [styles]="formats ? formats[column][event.id]?.style : null"
            ></date-time-picker>
            <date-time-picker (date_changed)="event.attributes.end = $event;detectChange(event, column)"
                              *ngIf="column==='end'"
                              [disabled]="column_dict[column].disabled || locked_dict[event.id]?.end"
                              [input_date]="event.attributes.end"
                              [set_default]="false"
                              [appFitContent]="event.attributes.end"
                              [allowFit]="format_dict[column].allow_fit"
                              [styles]="formats ? formats[column][event.id]?.style : null"
            ></date-time-picker>
          </td>
        </ng-container>

        <!--Duration-->
        <ng-container *ngIf="column==='duration'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell
              [constrainWidth]="config.constrain_width"
              [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event">{{column_ids[column] || column | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" mat-cell class="attribute-field"
              [appFitContent]="duration_dict[event.id]"
              [allowFit]="format_dict[column].allow_fit">
            <div [ngStyle]="formats ? formats[column][event.id]?.style : null">{{duration_dict[event.id]}}</div>
          </td>
        </ng-container>

        <!--Comment-->
        <ng-container *ngIf="column==='comment'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event" [constrainWidth]="config.constrain_width"
          >{{column_ids[column] || column | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" mat-cell class="attribute-field">
            <div [ngStyle]="formats ? formats[column][event.id]?.style : null">
              <input (input)="detectChange(event, column)"
                     [(ngModel)]="event.attributes.comment"
                     [disabled]="column_dict[column].disabled"
                     [appFitContent]="event.attributes.comment"
                     [allowFit]="format_dict[column].allow_fit"/>
            </div>
          </td>
        </ng-container>

        <!--Constant Properties-->
        <ng-container *ngIf="prop_columns.includes(column)" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event"
              (dblclick)="config.event_types.length === 1 ? getTree(column) : null"
              [constrainWidth]="config.constrain_width">
            {{column_dict[column].title || cp_dict[column]?.attributes.alias || cp_dict[column]?.attributes.name | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" mat-cell>
            <ng-container *ngIf="event.attributes.custom_constants && cp_dict[column]">
              <constant-field [constant_value]="event.attributes.custom_constants[cp_dict[column].attributes.name]"
                              [constant_property]="cp_dict[column]"
                              (constant_changed)="event.attributes.custom_constants[cp_dict[column].attributes.name] = $event;detectChange(event, column)"
                              [disabled]="cp_dict[column].attributes.is_calculation || column_dict[column].disabled || !belongsToEventType(event, column, 'constant_property')"
                              [tooltip]="cp_class_dict[event.id + column]?.message"
                              [auto_class]="cp_class_dict[event.id + column]?.class"
                              [locked]="locked_dict[event.id] ? locked_dict[event.id][column] : false"
                              (lock_clicked)="checkLock(event, column)"
                              [formats]="formats[column][event.id]"
                              class="constant-field">
              </constant-field>
            </ng-container>
          </td>
        </ng-container>

        <!--Component Types-->
        <ng-container *ngIf="component_columns.includes(column)" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event" [constrainWidth]="config.constrain_width"
          >{{column_dict[column].title || component_types_dict[column]?.attributes.name | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" mat-cell class="component-type-field">
            <div [ngStyle]="formats ? formats[column][event.id]?.style : null">
              <select-search-component *ngIf="event_component_t_map[event.id]"
                                       [api_model]="'component'"
                                       (selectionChange)="updateComponentRelationship(event, column, $event);detectChange(event, column)"
                                       (showUpdate)="showChange()"
                                       [disabled]="column_dict[column].disabled || !belongsToEventType(event, column, 'component_type')"
                                       [component_types]=[column]
                                       [custom_filters]="etCtQueryDict[event.relationships?.event_type?.data?.id]?.[column]"
                                       [compareFunctionName]="'compareById'"
                                       [sort_by]="'-created_on'"
                                       [classes]="'auto-width min-max-inherit table-row show-select-arrow'"
                                       [value]="event_component_t_map[event.id][column]"
                                       [appFitContent]="event_component_t_map[event.id][column]?.attributes?.name + '20' || '20'"
                                       [allowFit]="format_dict[column].allow_fit">
              </select-search-component>
            </div>
          </td>
        </ng-container>

        <!--Source and Destination-->
        <ng-container *ngIf="['source', 'destination'].includes(column)" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[column]"
              (resizable)="format_dict[column].width=$event" [constrainWidth]="config.constrain_width"
          >{{column_ids[column] || column | upperfirst | removeUnderscore}}
            <span *ngIf="required_columns?.includes(column)"> *</span>
          </th>
          <td *matCellDef="let event;" mat-cell class="attribute-field">
            <div *ngIf="column === 'source'" [ngStyle]="formats ? formats[column][event.id]?.style : null">
              <select-search-component (selectionChange)="updateSelectedOreBody($event, event, 'output_ore_body')"
                                       [disabled]="column_dict[column].disabled"
                                       [custom_filters]="source_ob_filter"
                                       [api_model]="'ore_body_light'"
                                       [compareFunctionName]="'compareById'"
                                       [filter_by]="['name']"
                                       [classes]="'auto-width min-max-inherit table-row show-select-arrow'"
                                       [value]="event.relationships.output_ore_body.data"
                                       [allow_fit]="format_dict[column].allow_fit"
                                       [stringFunction]="oreBodyNameFunction">
              </select-search-component>
            </div>
            <div *ngIf="column === 'destination'" [ngStyle]="formats ? formats[column][event.id]?.style : null">
              <select-search-component (selectionChange)="updateSelectedOreBody($event, event, 'input_ore_body')"
                                       [disabled]="column_dict[column].disabled"
                                       [custom_filters]="destination_ob_filter"
                                       [api_model]="'ore_body_light'"
                                       [filter_by]="['name']"
                                       [compareFunctionName]="'compareById'"
                                       [classes]="'auto-width min-max-inherit table-row show-select-arrow'"
                                       [value]="event.relationships.input_ore_body.data"
                                       [allow_fit]="format_dict[column].allow_fit"
                                       [stringFunction]="oreBodyNameFunction">
              </select-search-component>
            </div>
          </td>
        </ng-container>

        <!--Changed On-->
        <ng-container *ngIf="column==='changed on'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell>{{column_ids[column] || column | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event" mat-cell class="attribute-field">
            <div [ngStyle]="formats ? formats[column][event.id]?.style : null">
              {{event.attributes.changed_on| stringDate}}
            </div>
          </td>
        </ng-container>

        <!--Changed By-->
        <ng-container *ngIf="column==='changed by'" matColumnDef="{{column}}">
          <th *matHeaderCellDef mat-header-cell>{{column_ids[column] || column | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let event" mat-cell class="attribute-field">
            <div [ngStyle]="formats ? formats[column][event.id]?.style : null">
              {{event.attributes.changed_by_name}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="column_ids;" sticky mat-header-row></tr>
      <tr *matRowDef="let row; columns: column_ids;" [ngClass]="{'selected': selection_dict[row.id]} "
          mat-row></tr>
    </table>
  </div>
</div>
