import {ApiService} from "../../services/api/api.service";
import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {takeUntil, tap} from "rxjs/operators";
import {HotInstance} from "../../services/hot-instance";
import {Model} from "../../services/api/model";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {BaseComponent} from "../../shared/base.component";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {forkJoin, Observable} from "rxjs";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'history-table',
    templateUrl: 'history-table.component.html',
    standalone: false
})
export class HistoryTableComponent extends BaseComponent {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    private sort;

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    columns: string[] = ["Datetime", "User", "Name", "Action", "Model", "Changed Data", "Old Data", "Created by", "Changed by"];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    @Input()
    hot: HotInstance;
    private _resourceList: Model[];
    @Input() set resourceList(model: Model[]) {
        this._resourceList = model;
        if (model && this.dtp) {
            this.refreshHistory();
        }
    }

    get resourceList(): Model[] {
        return this._resourceList;
    }

    private table_names: string[] = [];
    history: any[];

    dtp: IDateTimePeriod;

    constructor(protected api: ApiService,
                private changeDetectorRef: ChangeDetectorRef,
                private headerData: HeaderDataService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
        super();
    }

    ngOnInit(): void {

        setTimeout(() => {
            this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
                this.dtp = dtp;
                this.refreshHistory();
            });

            this.dateTimePeriodService.dtpInitialisedPromise.promise.then(dtp => {
                this.dtp = dtp;
                this.refreshHistory();
            });
        });
    }

    refreshHistory() {
        if (this.hot) {
            if (this.hot.resource.hasOwnProperty('baseUrl')) {
                this.table_names = [this.hot.resource.modelName];
            } else {
                this.table_names = Object.keys(this.hot.resource);
            }
        } else {
            this.table_names = this.resourceList.map(item => item.modelName);
        }

        this.getHistory().subscribe(() => {
            this.dataSource.data = this.history;

            this.dataSource.filterPredicate = (data, filter) => {
                return !!(data.attributes.issued_at.toLowerCase().includes(filter) ||
                    data.changed_by.toLowerCase().includes(filter) ||
                    data.attributes.verb.toLowerCase().includes(filter) ||
                    data.attributes.table_name.toLowerCase().includes(filter) ||
                    data.old_json_string.includes(filter) ||
                    data.new_json_string.includes(filter));
            };

            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
                switch (sortHeaderId) {
                    case "Datetime":
                        return data.attributes.issued_at;
                    case "User":
                        return data.changed_by;
                    case "Action":
                        return data.attributes.verb;
                    case "Model":
                        return data.attributes.table_name;
                    default:
                        return "";
                }
            };

            this.dataSource.paginator = this.paginator;
            this.changeDetectorRef.markForCheck();
        });
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    private getHistory(): Observable<any> {
        let today = new Date();
        let priorDate = new Date(new Date().setDate(today.getDate() - 30));

        const q: any[] = [{
            name: 'verb',
            op: 'in',
            val: ['insert', 'update', 'delete']
        }];

        if (this.table_names?.length) {
            q.push({
                name: 'table_name', op: 'in', val: this.table_names
            });
        }

        q.push({
            name: 'issued_at',
            op: 'gt',
            val: this.dtp.start.toISOString()
        });

        q.push({
            name: 'issued_at',
            op: 'lt',
            val: this.dtp.end.toISOString()
        });

        const query = new SearchQueryOptions();
        query.sort = '-issued_at';
        query.page_size = 5000;
        if (!this.hot) {
            query.filters = q;
        }

        const activityObservable = this.api.activity.searchMany(query).pipe(tap(response => {
            this.history = response.data;
        }));

        const user_dict = {};
        const userObservable = this.api.users.searchMany().pipe(tap(response => {
            response.data.map(item => {
                user_dict[item.id] = item;
            });
        }));
        return forkJoin([activityObservable, userObservable]).pipe(tap(() => {
            this.history.map((item) => {
                let new_changed_by_fk;
                let created_by_fk;
                if (item.attributes.changed_data) {
                    new_changed_by_fk = item.attributes.changed_data.changed_by_fk;
                }
                let old_changed_by_fk;
                if (item.attributes.old_data) {
                    old_changed_by_fk = item.attributes.old_data.changed_by_fk;
                    created_by_fk = item.attributes.old_data.created_by_fk;
                }

                // for searching the events
                item.old_json_string = JSON.stringify(item.attributes.old_data).toLowerCase();
                item.new_json_string = JSON.stringify(item.attributes.changed_data).toLowerCase();

                item.changed_by = new_changed_by_fk ?
                    user_dict[new_changed_by_fk].attributes.name :
                    old_changed_by_fk ? user_dict[old_changed_by_fk].attributes.name : '';

                item.created_by = created_by_fk ? user_dict[created_by_fk].attributes.name : '';
            });
        }));
    }
}
