import {Component, Input} from '@angular/core';
import {PendingContextValidationOptions} from '../pending-context-form.component';
import {
    CustomFormFieldComponent,
    CustomFormFieldConfigurationOptions
} from '../../custom-form-field/custom-form-field.component';

@Component({
    selector: 'pending-context-validation',
    templateUrl: '../../custom-form-field/custom-form-field.component.html',
    styleUrls: ['../../custom-form-field/custom-form-field.component.less'],
    standalone: false
})
export class PendingContextValidationComponent extends CustomFormFieldComponent {
    @Input() config: PendingContextValidationOptions;
    @Input() title: string;
    include_options: string[];

    configuration_options: CustomFormFieldConfigurationOptions[] = [
        {
            name: 'min_length',
            field_type: 'input',
            type: 'number',
            help: 'Minimum number of characters/digits allowed for this value'
        },
        {
            name: 'max_length',
            field_type: 'input',
            type: 'number',
            help: 'Maximum number of characters/digits allowed for this value'
        },
        {name: 'only_numeric', field_type: 'checkbox', type: 'boolean', help: 'Only allow numeric values'},
        {name: 'only_text', field_type: 'checkbox', type: 'boolean', help: 'Only allow text values'},
        {name: 'must_contain', field_type: 'input', type: 'text', help: 'A comma-separated list of strings'}
    ]

    constructor() {
        super();
        this.include_options = this.configuration_options.map(c=>c.name);
    }

}
