import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tile as TileModel} from '../../../_models/tile';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {CustomHTMLParameters, ICustomHTMLParameters, TileParameters} from '../../../services/tile_data.service';
import {JsonContextService} from "../../custom-html-form/json-context.service";
import {takeUntil} from "rxjs/operators";
import {BaseComponent} from "../../../shared/base.component";
import {deepCopy} from "../../../lib/utils";
import {ReportParameters} from "../../custom-html-form/type/report-parameters";
import {JsonContextTagService} from "../../custom-html-form/json-context-tag.service";

@Component({
    selector: 'advanced-tab',
    templateUrl: './advanced-tab.component.html',
    styleUrls: ['./advanced-tab.component.less'],
    standalone: false
})
export class AdvancedTabComponent extends BaseComponent {

    constructor(private formBuilder: FormBuilder,
                private jsonContextService: JsonContextService,
                private jsonTagService: JsonContextTagService) {
        super();
    }

    @Input() json: TileModel;
    @Output() updateJsonContextChange = new EventEmitter<any>();

    public advancedForm: FormGroup;
    public config: TileParameters;
    public jsonContext: any;

    static validateJson(control: AbstractControl): { [key: string]: any } | null {
        try {
            JSON.parse(control.value);
            return null;
        } catch (e: any) {
            if (e instanceof SyntaxError) {
                return {
                    invalidJson: {
                        message: e.message
                    }
                };
            }
            return {invalidJson: true};
        }
    }

    ngOnInit() {
        this.config = this.json.attributes.parameters;
        this.initForm();

        const config = this.jsonContextService.getJsonContext(new ReportParameters(deepCopy(this.config)));
        this.jsonContext = config.jsonContext;
    }

    initForm() {
        const template = this.config.template;

        this.advancedForm = this.formBuilder.group({
            template: [template, [Validators.required]]
        });

        // Subscribe to value changes
        this.advancedForm.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
            this.updateCustomHtml(value);
        });
    }

    updateCustomHtml(newContext: any) {
        const customHtml: ICustomHTMLParameters = new CustomHTMLParameters(
            this.config.isReport, newContext.context, newContext.template);
        const tileParameters: TileParameters = {...this.config, ...customHtml};
        this.updateJsonContextChange.emit(tileParameters);
    }

    copyJson() {
        this.jsonTagService.copyToClipboard(this.jsonContext);
    }
}
