<ul *ngIf="component_constants?.length > 0" style="list-style: none;padding-left: 0;display:flex;flex-wrap:wrap;">
  <li *ngFor="let component_constant of component_constants" class="flex-item" style="margin-right:10px;">
    <table>
      <thead>
      <tr>
        <th style="max-width:200px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width:200px;">
          <label title="id: {{ component_constant.id }}"
                 *ngIf="constant_property_dict[component_constant.relationships.constant_property.data.id]?.attributes.name">
            {{ constant_property_dict[component_constant.relationships.constant_property.data.id]?.attributes.name}}
            : </label>
          <label style="color:red;font-weight: bold;"
                 matTooltip="This constant has a value saved for a property that no longer belongs to the selected component type."
                 *ngIf="constant_property_dict && !constant_property_dict[component_constant.relationships.constant_property.data.id]">
            {{component_constant.attributes.name}} not on selected type!
          </label>
        </th>
        <th *ngIf="component_constant.hasOwnProperty('id') && flowchart">Show</th>
        <th *ngIf="component_constant.hasOwnProperty('id') && !flowchart"></th><!--delete-->
      </tr>
      </thead>
      <tbody>
      <tr>
        <td style="width:180px;max-width:250px;border:1px solid #999999 !important;">
          <constant-field [constant_value]="component_constant.attributes.value"
                          [constant_property]="constant_property_dict[component_constant.relationships.constant_property.data.id]"
                          (constant_changed)="component_constant.attributes.value = $event;"
                          (change_complete)="addConstantComponent(component_constant)"
                          [disabled]="constant_property_dict[component_constant.relationships.constant_property.data.id]?.attributes.is_calculation"
                          class="constant-field"
          ></constant-field>
        </td>
        <td *ngIf="component_constant.hasOwnProperty('id') && flowchart" style="vertical-align: middle;">
          <mat-checkbox *ngIf="component_constant.hasOwnProperty('id') && tree_position == 'parent'"
                        style="width:20px;margin:0;min-width:unset;"
                        [(ngModel)]="component_constant.attributes.view_on_parent_flowchart"
                        (change)="addConstantComponent(component_constant)"
                        aria-label="Show on flowchart"></mat-checkbox>

          <mat-checkbox *ngIf="component_constant.hasOwnProperty('id') && tree_position == 'child'"
                        style="width:20px;margin:0;min-width:unset;"
                        [(ngModel)]="component_constant.attributes.view_on_flowchart"
                        (change)="addConstantComponent(component_constant)"
                        aria-label="Show on flowchart"></mat-checkbox>
        </td>
        <td style="width:20px;min-width:20px;">
          <i *ngIf="component_constant.id" (click)="deleteConstantComponent(component_constant)"
             class="fa fa-trash hideonprint" style="font-size:18px;cursor:pointer;margin-top:2px;">
          </i>
        </td>
      </tr>
      </tbody>
    </table>

  </li>
</ul>
<div *ngIf="component_constants?.length < 1">No component constants to display.</div>
