<div class="analysis-tools">
  <div class="second-row-options">
    <select-filter (constant_changed)="changeEstimate($event)"
                   [options]="series_type_names"
                   [modelValue]="selected_series_type.attributes.name"
                   [label]="'Type'"
                   [classes]="'bordered-select fill'">
    </select-filter>
    <mat-slide-toggle [(ngModel)]="formula_view">Switch
      to {{formula_view ? 'Value view' : 'Formula view'}}
    </mat-slide-toggle>
  </div>

  <ng-container *ngIf="selected_series && !!dateInst.dtp">
    <div *ngIf="config.series_table">
      <page-tile
          [dtp]="dateInst.dtp"
          [tile]="config.series_table"
          class="page-tile off-dashboard">
      </page-tile>
    </div>

    <div class="row tools-row">

      <div *ngIf="config.budget_chart" [ngClass]="config.performance_chart ? 'half-left':'full-width'">
        <page-tile
            [dtp]="dateInst.dtp"
            [tile]="config.budget_chart"
            class="page-tile off-dashboard">
        </page-tile>
      </div>

      <div *ngIf="config.performance_chart" style="width:50%;margin-left:1%;">
        <page-tile
            [dtp]="dateInst.dtp"
            [tile]="config.performance_chart"
            class="page-tile off-dashboard">
        </page-tile>
      </div>

    </div>

    <div *ngIf="config.model_pivot_tile" class="row tools-row">
      <page-tile
          [dtp]="dateInst.dtp"
          [tile]="config.model_pivot_tile"
          class="page-tile off-dashboard">
      </page-tile>
    </div>

    <div class="row tools-row">
      <div *ngIf="config.comments_tile" [ngClass]="config.category_chart? 'half-left':'full-width'">
        <page-tile
            [dtp]="dateInst.dtp"
            [tile]="config.comments_tile"
            class="page-tile off-dashboard">
        </page-tile>
      </div>

      <div *ngIf="config.category_chart" style="width:50%;margin-left:1%;">
        <page-tile
            [dtp]="dateInst.dtp"
            [tile]="config.category_chart"
            class="page-tile off-dashboard">
        </page-tile>
      </div>
    </div>

    <div style="margin-top:30px;">
      <value-driver-tree *ngIf="selected_series"
                         [formula_view]="formula_view"
                         [selected_calculation]="selected_series"
                         [treeNodeMap]="treeNodeMap"
                         [is_tile]="false"
      >
      </value-driver-tree>
    </div>
  </ng-container>
</div>
