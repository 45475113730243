import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, HostListener, Inject, Input, OnInit, Output, Renderer2} from '@angular/core';
import {distinctUntilChanged, filter, map, switchMap, takeUntil, tap,} from 'rxjs/operators';
import {fromEvent, Subject} from 'rxjs';
import {TileDataService} from "../services/tile_data.service";
import * as utils from "../lib/utils";
import {FormDialogService} from "../services/form-dialog.service";

@Directive({
    selector: '[resizable]',
    standalone: false
})
export class ColumnResizeDirective implements OnInit {
    private readonly onDestroy = new Subject<void>();

    //TODO change formats to strong type after https://gitlab.com/mmswire/wire/-/merge_requests/3819 is merged
    @Input('resizable') formats: any;
    @Input('constrainWidth') constrain_width: boolean;
    editing = false;
    handle: Element;

    @HostListener('click', ['$event'])
    clickEvent(event) {
        if (!this.editing || !this.formats.allow_resize) return;
        event.preventDefault();
        event.stopPropagation();
        this.openModal(event);
    }

    @Output()
    readonly resizable = fromEvent<MouseEvent>(
        this.elementRef.nativeElement,
        'mousedown'
    ).pipe(
        filter(event => event['which'] === 1 && this.editing),
        tap((e) => e.preventDefault()),
        switchMap(() => {
            this.removeLimits();
            const {width, right} = this.elementRef.nativeElement
                .closest('th')!
                .getBoundingClientRect();

            return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
                map(({clientX}) => width + clientX - right),
                tap(() => this.setLimits(true)),
                distinctUntilChanged(),
                takeUntil((fromEvent(this.documentRef, 'mouseup') ||
                    fromEvent(this.elementRef.nativeElement.closest('thead'), 'mouseout')).pipe(
                    //tap(()=>this.setLimits(this.editing))
                    )
                )
            );
        }),
        takeUntil(this.onDestroy)
    );

    constructor(
        @Inject(DOCUMENT) private readonly documentRef: Document,
        @Inject(ElementRef)
        private readonly elementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2, private tileData: TileDataService,
        private formDialogService: FormDialogService
    ) {
        this.handle = this.renderer.createElement('div');
        this.renderer.addClass(this.handle, 'resize-handle');
        const grip = this.renderer.createElement('i');
        this.renderer.addClass(grip, 'fas')
        this.renderer.addClass(grip, 'fa-grip-vertical')
        this.renderer.appendChild(this.handle, grip);

        this.tileData.editing.pipe(takeUntil(this.onDestroy))
            .subscribe((on: boolean) => {
                this.editing = on;
                this.toggleResizeHandles(on)
            })
    }

    ngOnInit(): void {
        this.setLimits(true);
    }

    //TODO Why does mat-table ignore these when resizing another column
    setLimits(on) {
        if (!this.formats || !this.formats.width || (!this.formats.allow_resize && this.editing)) return;
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', this.formats.width + 'px');

        if (this.constrain_width === true) return;
        this.renderer.setStyle(this.elementRef.nativeElement, 'max-width', this.formats.width + 'px');
        this.renderer.setStyle(this.elementRef.nativeElement, 'min-width', this.formats.width + 'px');
    }

    removeLimits() {
        if (!this.formats || !this.formats.width || !this.editing || (!this.formats.allow_resize && this.editing)) return;
        this.renderer.removeStyle(this.elementRef.nativeElement, 'max-width');
        this.renderer.removeStyle(this.elementRef.nativeElement, 'min-width');
    }

    toggleResizeHandles(on = false) {
        /* Just displays and hides the resizing handles on Edit Layout */
        if (this.formats && this.formats.allow_resize !== true) return;
        if (on) {
            this.renderer.removeChild(this.elementRef.nativeElement, this.handle);
            this.renderer.appendChild(this.elementRef.nativeElement, this.handle)
        } else {
            this.renderer.removeChild(this.elementRef.nativeElement, this.handle);
        }
    }

    openModal(event: any) {
        const config = {
            component: 'ColumnWidth',
            parameters: {width: this.formats.width},
            position: {top: 2, left: 60, max_right: 200},
        }
        const setStyles: (event) => void = (event) => {
            this.formats.width = event;
            this.setLimits(true);
        }
        const panelClass = 'series-summary-menu-dialog';
        this.formDialogService.openCustomDialog(event, config, panelClass, setStyles);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
