<div class="form-dialog tile-form-dialog">
  <h1>{{tile?.id ? 'Update' : 'Add'}} Tile: {{title| upperfirst}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints,'show-audit-mixin': tile.id}">
    <mat-tab-group #tile_form_tabs mat-stretch-tabs="false" [disableRipple]="true"
                   (selectedTabChange)="tabChanged($event)">

      <mat-tab label="Friendly">
        <form class="form-section column space-between">
          <div class="row center">
            <mat-form-field (mouseenter)="hint='Tile_Type'">
              <mat-label>Type</mat-label>
              <mat-select #tileContentSelect
                          (selectionChange)="contentChange();updateJson($event);"
                          [(value)]="json.attributes.content"
                          [disabled]="!!tile.id"
                          (opened)="tileContentSearch.focusInput()"
                          panelClass="TEST other"
                          name="tile_content">
                <option-list-search
                    #tileContentSearch
                    [placeholder]="'Search tiles'"
                    (inputChanged)="filterTiles($event)"
                    [selectElementRef]="tileContentSelect"
                    (inputCleared)="resetTileOptions()"
                ></option-list-search>
                <mat-option *ngFor="let content of tileOptions" [value]="content.value"
                            [disabled]="content.deprecated"
                            matTooltipPosition="right" [matTooltip]="content.tooltip || content.name">
                  {{content.name| upperfirst}}
                </mat-option>
              </mat-select>
              <mat-hint>Identifies this section according to the order it appears on the page.</mat-hint>
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Tile_Title'">
              <mat-label>Title</mat-label>
              <input [(ngModel)]="json.attributes.title"
                     matInput style="box-sizing: border-box;"
                     name="tile_title"/>
              <mat-hint>This is the main header for the section.
                Leave this blank if you do not want to see a header.
              </mat-hint>
            </mat-form-field>

          </div>

          <div class="row center">
            <mat-form-field (mouseenter)="hint='Tile_Category'" class="half">
              <mat-label>Category</mat-label>
              <mat-select #tileCategorySelect
                          [(ngModel)]="json.attributes.category"
                          [compareWith]="matSelectCompare"
                          data-cy="category-select"
                          name="category">
                <option-list-search
                    #tileContentSearch
                    [placeholder]="'Search tiles'"
                    (inputChanged)="filterCategories($event)"
                    [selectElementRef]="tileCategorySelect"
                    (inputCleared)="resetCategoryOptions()"
                ></option-list-search>
                <mat-option *ngFor="let category of tile_categories" [value]="category">
                  {{category.name| upperfirst}}
                </mat-option>
                <mat-option value="">Default</mat-option>
              </mat-select>
              <mat-hint></mat-hint>
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Tile_Class'" *ngIf="!tile.id">
              <mat-label>Tile Width</mat-label>
              <mat-select [(ngModel)]="tile_class" name="tile_class">
                <mat-option *ngFor="let class_name of tileData.tile_classes"
                            [value]="class_name">{{class_name | titlecase}}
                </mat-option>
              </mat-select>
              <mat-hint>The width of the tile in relation to the page.</mat-hint>
            </mat-form-field>
          </div>

          <div class="row medium-height">
            <mat-checkbox (mouseenter)="hint='Show_Header'"
                          [(ngModel)]="json.attributes.show_header"
                          name="show_header"
                          matTooltip="Tile headers are hidden by default for paragraph tiles. Check this box if you'd like
              to show the header and bounding box.">Show header bar
            </mat-checkbox>
          </div>

          <div *ngIf="json.attributes.content==='default-chart'||json.attributes.content==='budget-bar-chart'">
            <default-chart-form [config]="json.attributes.parameters"
                                [content]="json.attributes.content"></default-chart-form>
          </div>

          <!-------Flowchart---------------------------------------------------------------------------------------------->
          <ng-container *ngIf="json.attributes.content==='flowchart'">
            <div class="row">
                <select-search-component
                    [api_model]="'process'"
                    [label]="'Process'"
                    [value]="json.attributes.parameters.process"
                    (selectionChange)="json.attributes.parameters.process=$event.value"
                    [classes]="'fill'" class="select-search"
                ></select-search-component>
            </div>

            <div class="row center">
              <mat-form-field>
                <mat-label>Scale (%)</mat-label>
                <input [(ngModel)]="json.attributes.parameters.scale" matInput max="500" min="10" step="1" type="number"
                       name="scale">
                <mat-hint>Adjust the zoom level for the view of the flowchart. This works together with the rowspan and
                    colspan to determine the size and proportion of the flowchart that will be seen on the dashboard.
                    (A scale of 100 will show the full flowchart in the dashboard tile created.)</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Left Offset (px)</mat-label>
                <input [(ngModel)]="json.attributes.parameters.left_offset" matInput max="1000" min="0" type="number"
                       name="left_offset">
                <mat-hint>Adjust the left hand starting point when viewing a zoomed in proportion of a flowchart.</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Top Offset (px)</mat-label>
                <input [(ngModel)]="json.attributes.parameters.top_offset" matInput max="1000" min="0" type="number"
                       name="top_offset">
                <mat-hint>Adjust the top starting point when viewing a zoomed in proportion of a flowchart.</mat-hint>
              </mat-form-field>
              <mat-checkbox (mouseenter)="hint='hide_scroll'" [(ngModel)]="json.attributes.hide_scroll"
                            name="hide_scroll" class="form-element-align">Hide scroll bar
              </mat-checkbox>

            </div>
          </ng-container>

          <!----------Paragraph---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='paragraph'" class="row">
            <mat-checkbox (mouseenter)="hint='hide_scroll'" [(ngModel)]="json.attributes.hide_scroll"
                          name="hide_scroll">Hide scroll bar
            </mat-checkbox>
          </div>

          <!----------Julian Date--------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='julian-date'" class="row">
              <label>Font Size: &nbsp;</label>
              <input type="number" min="1" max="50" name="julian_font_size"
                     [(ngModel)]="json.attributes.parameters.font_size"/>
              <div [style.font-size]="json.attributes.parameters.font_size + 'vmin'"
                   style="margin-left:20px;">12345
              </div>
          </div>

          <!----------Custom HTML--------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='custom' && json.attributes.parameters" class="column">
           <custom-html-form [(config)]="json.attributes.parameters" [tileId]="json.id" #beforeSaveAction></custom-html-form>
          </div>

          <!----------Context Component---------------------------------------------------------------------------------------------->
          <div
              *ngIf="(json.attributes.content==='context' || json.attributes.content==='series-summary') && json.attributes.parameters"
              class="column">
            <context-form [config]="json.attributes.parameters"></context-form>
          </div>

          <!----------Comment Tile---------------------------------------------------------------------------------------------->
          <div
              *ngIf="(json.attributes.content==='comment-tile' || json.attributes.content==='page-comment-table') && json.attributes.parameters"
              class="column">
            <comments-table-form [config]="json.attributes.parameters"
                                 [base_type]="json.attributes.content==='page-comment-table'?'comment':'event'"
                                 [current_session_id]="session_id"></comments-table-form>
          </div>

          <!----------Component Events Table Tile---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='component-events-table' && json.attributes.parameters" class="column">
            <component-events-table-form [config]="json.attributes.parameters"
                                         #beforeSaveAction></component-events-table-form>
          </div>

          <!----------Series Table---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='series-table' && json.attributes.parameters" class="column">
            <series-table-form [config]="json.attributes.parameters"></series-table-form>
          </div>

          <!----------Series Data Table---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='series-data-table' && json.attributes.parameters" class="column">
            <series-data-table-form [config]="json.attributes.parameters"></series-data-table-form>
          </div>

          <!----------Series Correction Form---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='series-adjustment' && json.attributes.parameters" class="column">
            <series-adjustment-form [config]="json.attributes.parameters" #beforeSaveAction></series-adjustment-form>
          </div>

          <!----------Log Sheet---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='log-sheet' && json.attributes.parameters" class="column">
            <log-sheet-form [config]="json.attributes.parameters"></log-sheet-form>
          </div>

          <!----------Input Data Sheet---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='input-sheet' && json.attributes.parameters">
            <input-data-sheet-form [config]="json.attributes.parameters"></input-data-sheet-form>
          </div>

          <!--Custom Chart----------------------------------------------------------------------------------------------------->

          <div *ngIf="json.attributes.content==='custom-chart'">
            <ng-container *ngIf="json.attributes.parameters">
              <custom-chart-form [config]="json.attributes.parameters"></custom-chart-form>
            </ng-container>
          </div>

          <!--SPC Chart----------------------------------------------------------------------------------------------------->

          <ng-container *ngIf="json.attributes.content==='spc-chart' && json.attributes.parameters">
            <spc-chart-form [config]="json.attributes.parameters"></spc-chart-form>
          </ng-container>

          <!--Comparison Chart----------------------------------------------------------------------------------------------------->

          <div *ngIf="json.attributes.content==='comparison-chart'" class="row">
            <ng-container *ngIf="json.attributes.parameters">
              <comparison-chart-form [config]="json.attributes.parameters" style="width:100%"></comparison-chart-form>
            </ng-container>
          </div>

          <!--CSV Upload Model Form---------------------------------------------------------------------------------------------->
          <div
              *ngIf="json.attributes.content==='upload-model'">
            <ng-container>
              <upload-model-form [config]="json.attributes.parameters">
              </upload-model-form>
            </ng-container>
          </div>


          <!--Custom events----------------------------------------------------------------------------------------------------->
          <div
              *ngIf="json.attributes.content==='custom-events' || json.attributes.content==='custom-events-table' || json.attributes.content==='ore-body-events'">
            <ng-container>
              <custom-events-form [config]="json.attributes.parameters"
                                  [content_type]="json.attributes.content">
              </custom-events-form>
            </ng-container>
          </div>

          <!--Category chart------------------------------------------------------------------------------------------------------>
          <div *ngIf="json.attributes.content==='category-chart'">
            <ng-container *ngIf="true">
              <category-chart-form [config]="json.attributes.parameters"></category-chart-form>
            </ng-container>
          </div>

          <!--Component creator tile----------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='component-creator'">
            <component-creator-form [config]="json.attributes.parameters"></component-creator-form>
          </div>

          <!--Component form tile----------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='component-form'">
            <component-form-form [config]="json.attributes.parameters" #beforeSaveAction></component-form-form>
          </div>

          <!----------Pending Context Tile---------------------------------------------------------------------------------------------->

          <pending-context-form [config]="json.attributes.parameters"
                                *ngIf="json.attributes.content==='pending-context' && json.attributes.parameters">
          </pending-context-form>


          <!----------Model Pivot Tile---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='model-pivot-tile' && json.attributes.parameters" class="column">
            <model-pivot-form [config]="json.attributes.parameters" ></model-pivot-form>
          </div>

          <!--Pivot table tile----------------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='pivot-tile' || json.attributes.content==='pivot-tile-ng'">
            <pivot-tile-form [config]="json.attributes.parameters"
                             (configChange)="updatePivotParams($event)"
                             [type]="json.attributes.content" #beforeSaveAction>
            </pivot-tile-form>
          </div>

          <!----------Value Driver Tree Tile---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='value-driver-tree' && json.attributes.parameters" class="column">
            <value-driver-tree-form [config]="json.attributes.parameters">
            </value-driver-tree-form>
          </div>

          <!----------Waterfall Chart Tile---------------------------------------------------------------------------------------------->
          <div *ngIf="json.attributes.content==='waterfall-chart' && json.attributes.parameters" class="column">
            <waterfall-chart-form [config]="json.attributes.parameters">
            </waterfall-chart-form>
          </div>

          <!----------Printout Mapper---------------------------------------------------------------------------------------->
          <div class="column" *ngIf="json.attributes.content==='printout-mapper'">
            <mat-checkbox [(ngModel)]="json.attributes.parameters.allow_delete"
                          name="hide_scroll">Allow deleting conditions
            </mat-checkbox>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Relationships"
               *ngIf="json.attributes.content==='component-events-table'"
               [disabled]="!(json.attributes.parameters.selected_component_type?.id)">

        <component-side-panel-form [config]="json.attributes.parameters">
        </component-side-panel-form>
      </mat-tab>

      <mat-tab label="Query Builder"
               *ngIf="json.attributes.content==='component-events-table' && json.attributes.parameters && json.attributes.parameters.selected_component_type?.id">

        <ng-template matTabContent>
          <query-builder-form [content]="json.attributes.content" [tileConfig]="json.attributes.parameters"
                              [(query)]="json.attributes.parameters.query"
                              [modelTypeId]="json.attributes.parameters.selected_component_type?.id"></query-builder-form>
        </ng-template>
      </mat-tab>

      <mat-tab label="Constant Validation"
               *ngIf="json.attributes.content==='component-events-table' && json.attributes.parameters && json.attributes.parameters.selected_component_type?.id">

        <ng-template matTabContent>
          <validation-builder [config]="json.attributes.parameters"
                              [modelTypeId]="json.attributes.parameters.selected_component_type?.id"
                              [(validationQuery)]="json.attributes.parameters.validation"></validation-builder>
        </ng-template>
      </mat-tab>

      <mat-tab label="Query Builder" *ngIf="json.attributes.content==='custom-events-table'">
        <ng-template matTabContent>
          <div class='event-query-builder-tab'>
            <mat-tab-group [disableRipple]="true" class="custom-events-form-tabs">
              <ng-container *ngFor="let event_type of json.attributes.parameters.event_types">
                <mat-tab
                    [label]="event_type.id | getName:'event_type_light' | async">
                  <query-builder-form [content]="json.attributes.content" [tileConfig]="json.attributes.parameters"
                                      [modelTypeId]="event_type.id"
                                      [query]="json.attributes.parameters.query ? json.attributes.parameters.query[event_type.id] : null"
                                      (queryChange)="json.attributes.parameters.query ? json.attributes.parameters.query[event_type.id]=$event : (json.attributes.parameters.query={}) && (json.attributes.parameters.query[event_type.id]=$event)">
                  </query-builder-form>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Constant Validation" *ngIf="json.attributes.content==='custom-events-table-disabled'">
        <ng-template matTabContent>
          <div class='event-constant-validation-tab'>
            <mat-tab-group [disableRipple]="true">
              <ng-container *ngFor="let event_type of json.attributes.parameters.event_types">
                <mat-tab
                    [label]="event_type.id | getName:'event_type_light' | async">
                  <validation-builder [modelTypeId]="event_type.id"
                                      [config]="json.attributes.parameters"
                                      [validationQuery]="json.attributes.parameters.validation? json.attributes.parameters.validation[event_type.id]:null"
                                      (validationQueryChange)="json.attributes.parameters.validation ? json.attributes.parameters.validation[event_type.id]=$event : (json.attributes.parameters.validation={}) && (json.attributes.parameters.validation[event_type.id]=$event)">
                  </validation-builder>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Query Builder" *ngIf="['pivot-tile','pivot-tile-ng'].includes(json.attributes.content) &&
      (json.attributes.parameters.component_type_ids?.length || json.attributes.parameters.event_type_ids?.length)">
        <ng-template matTabContent>
          <div class='event-query-builder-tab'>
            <mat-tab-group [disableRipple]="true" class="custom-events-form-tabs">
              <ng-container *ngFor="let type_id of pivotIds">
                <mat-tab [label]="type_id | getName:pivotModel | async">
                  <query-builder-form
                      [content]="json.attributes.parameters.component_type_ids?.length? 'component' : 'event'"
                      [tileConfig]="json.attributes.parameters"
                      [modelTypeId]="type_id"
                      [query]="json.attributes.parameters.query ? json.attributes.parameters.query[type_id] : null"
                      (queryChange)="json.attributes.parameters.query ? json.attributes.parameters.query[type_id]=$event : (json.attributes.parameters.query={}) && (json.attributes.parameters.query[type_id]=$event)"></query-builder-form>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Formatting"
               *ngIf="['component-events-table', 'custom-events-table'].includes(json.attributes.content)"
               [disabled]="!(json.attributes.parameters.selected_component_type?.id || json.attributes.parameters.event_types?.length)">
        <ng-template matTabContent>
          <div class='conditional-formatting-tab'>
            <conditional-formatting-form [content]="json.attributes.content" [config]="json.attributes.parameters">
            </conditional-formatting-form>

          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Template Editor" class="advanced" *ngIf="json.attributes.content==='custom' && !json.attributes.parameters.hasOwnProperty('paragraph_body')">
        <ng-template matTabContent style="height:100%;" class="advanced">
            <advanced-tab *ngIf="json.attributes.content==='custom'" [json]="json"
                          (updateJsonContextChange)="onUpdateJsonContextChange($event)"></advanced-tab>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="userService.isMMSUser()" label="Advanced" class="advanced">
        <ng-template matTabContent style="height:100%;" class="advanced">
          <div class="row stretch" style="width:100%;height:100%;">
            <textarea (ngModelChange)="updateJson($event)" *ngIf="json && json.attributes.parameters"
                      [ngModel]="json.attributes.parameters | json"
                      class="full"
                      name="jsontext" style="width:100%;height:100%;min-height:500px;">
            </textarea>
          </div>

        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="align-bottom" *ngIf="current_tab===0">

      <div class="row min-height" [ngClass]="{'dtp-showing': json.attributes.custom_dtp || is_relative_dtp}">
        <!-- This is for ALL tile types (not to be confused with multiple dtp option on custom chart) -->
        <mat-checkbox (mouseenter)="hint='custom_dtp'" [(ngModel)]="json.attributes.custom_dtp"
                      [disabled]="is_relative_dtp"
                      name="custom_dtp">Custom date period
        </mat-checkbox>
        <mat-checkbox [checked]="json.attributes.relative_dtp ? true : false" (change)="toggleRelativeDtp($event)"
                      name="relative">Relative date period
        </mat-checkbox>
      </div>
      <relative-date-period [config]="json.attributes.relative_dtp"
                            class="date-period inline align-bottom show-relative slide"
                            [ngClass]="{'showing': is_relative_dtp}"></relative-date-period>

      <date-time-period
          [immutable]="true" [no_url]="true" class="date-period inline align-bottom slide"
          [ngClass]="{'showing': json.attributes.custom_dtp && !is_relative_dtp}"></date-time-period>
    </div>
  </mat-dialog-content>

  <div class="audit-text">
    <div>Changed on {{tile.attributes.changed_on | stringDate}} by {{tile.attributes.changed_by_name}}</div>
    <audit-mixin-indicator *ngIf="tile.id" [model]="tile"></audit-mixin-indicator>
  </div>
  <div class="action-buttons">
    <button class="btn-close" [disabled]="disableActionButtons" (click)="onCloseClick()">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button class="btn-save" [disabled]="disableActionButtons" (click)="save()">Save</button>
  </div>
</div>
