import {Component, Input, OnInit, ViewChild, ViewEncapsulation,} from '@angular/core';
import {ComponentConstantsTableService} from "./component-constants-table.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {ComponentType} from "../../_models/component-type";
import {Observable, Subject} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {first, switchMap, takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {ApiService} from "../../services/api/api.service";
import {Component as WireComponent} from "../../_models/component";
import {ConstantProperty} from '../../_models/constant-property';
import {ConstantComponent} from '../../_models/constant-component';

export type ComponentConstantTableData = Record<string, ConstantComponent[]>;


@Component({
    selector: 'component-constants-table',
    templateUrl: './component-constants-table.component.html',
    styleUrls: ['./component-constants-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [ComponentConstantsTableService],
    standalone: false
})
export class ComponentConstantsTableComponent implements OnInit {
    private readonly onDestroy = new Subject<void>();
    @Input() component_type: ComponentType;

    component_dict: { [key: string]: WireComponent } = {};
    component_component_constants_dict: ComponentConstantTableData = {};
    constant_properties: ConstantProperty[];
    cp_dict: { [key: string]: ConstantProperty } = {};
    filter_string = '';
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<WireComponent>;
    page_size_options = [5, 10, 20];

    component_total = 0;

    constructor(private cps: ComponentConstantsTableService,
                private api: ApiService) {
    }

    ngOnInit(): void {
        this.cps.getSelectedConstantProperties(this.component_type.id).subscribe(properties => {
            this.constant_properties = properties.data
            this.constant_properties.forEach(cp => this.cp_dict[cp.id] = cp);
            this.setUpPaginatedDataSource();
        })
    }

    setUpPaginatedDataSource() {
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        this.paginator.pageSize = 5;
        initialQuery.filters = this.cps.getSelectedComponentsFilter(this.component_type.id);

        this.dataSource = new PaginationDataSource<WireComponent>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            switchMap((result) => {
                const components = result.data;
                components.forEach(c => this.component_dict[c.id] = c);
                this.component_total = result.meta.count;

                return this.cps.getComponentConstants(components.map(c => c.id), this.constant_properties).pipe(
                    tap((response: ComponentConstantTableData) => {
                        this.component_component_constants_dict = response;
                    })
                )
            })).subscribe();
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.component.searchMany(query).pipe(
            takeUntil(this.onDestroy ), first()
        )
    }

    emitFilterQuery() {
        const filters = this.cps.getSelectedComponentsFilter(this.component_type.id);
        this.dataSource.filterBy(filters);
    }

    updateSearchFilter() {
        const filters = this.cps.updateSearchFilter(this.component_type.id, this.constant_properties, this.filter_string);
        this.dataSource.filterBy(filters)
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort)
    }
}
