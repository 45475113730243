import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {julianDate} from '../../lib/utils';

@Component({
    selector: 'julian-date',
    templateUrl: './julian-date.component.html',
    styleUrls: ['./julian-date.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class JulianDateComponent implements OnInit {
    julian_date: string;
    @Input() config: { font_size: number } = {font_size: 6};

    constructor() {
    }

    ngOnInit(): void {
        this.julian_date = julianDate(new Date());
        if (!this.config.font_size) {
            this.config.font_size = 6;
        }
    }

}
