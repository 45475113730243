<mat-label>Tags (click to copy)</mat-label>

<div class="row center report-tag-header" (click)="showComponentTypeTag=!showComponentTypeTag">
  <mat-icon>{{showComponentTypeTag ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
  Component Type Wrapper Tag
</div>
<div class="report-tag-group" [ngClass]="{'show': showComponentTypeTag}">
  <div *ngFor="let tag of componentTypeTags | keyvalue">
    <button mat-raised-button (click)="copyToClipboard(tag.value[tag.key])"> {{tag.key}}</button>
    <br>
    <label>Filter by:</label>
    <div *ngFor="let wrapper of tag.value | keyvalue">
      <button *ngIf="wrapper.key !==tag.key" mat-raised-button
              (click)="copyToClipboard(wrapper.value)"> {{wrapper.key}}
      </button>
    </div>
  </div>
</div>

<div class="row center report-tag-header" (click)="showComponentTypePropertyTags=!showComponentTypePropertyTags">
  <mat-icon>{{showComponentTypePropertyTags ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
  Component Type Property Tags
</div>
<div class="report-tag-group" [ngClass]="{'show': showComponentTypePropertyTags}">
  <div *ngFor="let tag of componentTypePropertyTags | keyvalue">
    <button mat-raised-button (click)="copyToClipboard(tag.value)"> {{tag.key}}</button>
  </div>
</div>

<div class="row center report-tag-header" (click)="showComponentRelationshipTags=!showComponentRelationshipTags">
  <mat-icon>{{showComponentRelationshipTags ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
  Component Relationship Type Wrapper Tags
</div>
<div class="report-tag-group" [ngClass]="{'show': showComponentRelationshipTags}">
  <div *ngFor="let tag of componentTags | keyvalue">
    <ng-container *ngIf="tag.key">
      <label>{{tag.key}}:</label><br>
      <button mat-raised-button (click)="copyToClipboard(tag.value[tag.key])"> {{tag.key}}</button>
      <br>
      <label>Filter by:</label>
      <div *ngFor="let wrapper of tag.value | keyvalue" style="margin-left:10px;">
        <button *ngIf="wrapper.key !==tag.key" mat-raised-button
                (click)="copyToClipboard(wrapper.value)"> {{wrapper.key}}
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div class="row center report-tag-header" (click)="showComponentPropertyTags=!showComponentPropertyTags">
  <mat-icon>{{showComponentPropertyTags ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
  Component Relationship Property Tags
</div>
<div class="report-tag-group" [ngClass]="{'show': showComponentPropertyTags}">
  <div *ngFor="let componentType of componentPropertyTags | keyvalue">
    <label>{{componentType.key}}</label>
    <div *ngFor="let tag of componentType.value | keyvalue">
      <button mat-raised-button (click)="copyToClipboard(tag.value)"> {{tag.key}}</button>
    </div>
  </div>
</div>

<div class="row center report-tag-header" (click)="showSeriesTags=!showSeriesTags">
  <mat-icon>{{showSeriesTags ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
  Series Tags
</div>
<div class="report-tag-group" [ngClass]="{'show': showSeriesTags}">
  <div *ngFor="let series of seriesTags | keyvalue">
    <label>{{series.key}}</label>
    <div *ngFor="let tag of series.value | keyvalue">
      <button mat-raised-button (click)="copyToClipboard(tag.value)"> {{tag.key}}</button>
    </div>
  </div>
</div>

<div class="row center report-tag-header" (click)="showFunctionTags=!showFunctionTags">
  <mat-icon>{{showFunctionTags ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
  Function Wrapper Tags
</div>
<div class="report-tag-group" [ngClass]="{'show': showFunctionTags}">
  <div *ngFor="let tag of functionTags | keyvalue">
    <button mat-raised-button (click)="copyToClipboard(tag.value)"> {{tag.key}}</button>
  </div>
</div>