<div class="upload-model-form-container">

  <div class="row">
    <mat-form-field class="flex-half alone">
      <mat-label>Model Type</mat-label>
      <mat-select *ngIf="model_selection_options.length>0"
                  [(ngModel)]="config.model_selected" (ngModelChange)="resetConfig();changedSelectedModel($event)"
                  name="model_selected" [compareWith]="compareParserType">
        <mat-option *ngFor="let model of model_selection_options" [value]="model">
          {{model.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row center">
      <select-many-search-component matTooltip="Select Templates"
                                    (selectionChange)="templatesSelected($event)"
                                    [api_model]="'parser_config_template'"
                                    [value]="config.selected_templates"
                                    [disabled]="!config.model_selected"
                                    [custom_filters]="template_filter"
                                    [classes]="'fill'"
                                    [filter_by]="['name']"
                                    class="select-search"
                                    style="position: relative; top: -2px;"
                                    label="Select Allowed Templates">
      </select-many-search-component>
      <mat-form-field class="select-template">
        <mat-label>Select template</mat-label>
        <mat-select [(ngModel)]="config.default_template"
                    name="selected_templates"
                    [compareWith]="compareById">
          <mat-option *ngFor="let template of config.selected_templates" [value]="template">
            {{template.attributes.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
</div>
