import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator, HOTLookup} from "../../services/handsontable-generator.service";
import {HeaderDataService} from "../../services/header_data.service";
import {remove as _remove, uniq as _uniq} from "lodash-es";
import {HotInstance} from "../../services/hot-instance";
import {MatDialog} from "@angular/material/dialog";
import {CustomDialogComponent} from "../../forms/custom-dialog/custom-dialog.component";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {first, map, takeUntil, tap, concatMap} from 'rxjs/operators';
import {firstValueFrom, forkJoin, Observable, of, Subject} from "rxjs";
import {ListResponse} from "../../services/api/response-types";
import {SeriesComponent} from "../../_models/series-component";
import {User} from "@sentry/browser";
import {WireComponent} from "../../_models/component";
import {takeUntilDestroyed} from "../../shared/utils/utils";
import {getBaseFilter} from "../../services/api/filter_utils";
import {SeriesLight} from "../../_models/series";

/**
 * This is called 'Report configuration' on the front-end
 */
@Component({
    selector: 'series-component-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    styleUrls: ['../handson-sheet.less'],
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class SeriesComponentSheetViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy: Subject<void> = new Subject<void>();
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    @Input()
    process: any;
    @Input()
    components: WireComponent[];
    @Input()
    seriesComponents: SeriesComponent[];
    search: string;

    title: string;
    data: any;
    schema: any;
    column_list: any;

    series_light: any[];
    users: any[];

    component_filters: any[];
    component_lookups: HOTLookup;

    pageSize: number = 100;

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService,
                private matDialog: MatDialog) {
        this.hot = new HotInstance(["relationships.series.data.id"]);
    }

    ngOnInit(): void {
        this.headerData.title = 'Report Configuration';

        const observables = [];

        if (this.seriesComponents == null) {
            observables.push(this.loadNextSeriesComponents());
        } else {
            this.data = this.seriesComponents;
            this.pageSize = 0;
        }

        if (this.components == null) {
            this.component_filters = [];
        } else {
            const filter: any = {
                name: 'id',
                op: 'in',
                val: this.components.map(
                    (component: WireComponent) => component.id
                )
            };
            this.component_filters = [filter];
        }
        //TODO this api is cached so doesn't return new series. Get from ngxs once installed
        observables.push(this.api.series_light.searchMany().pipe(concatMap(response => {
            this.series_light = response.data
            return this.fetchMissingSeries();
        })));
        // TODO Filter for resource types
        const component_options: SearchQueryOptions = new SearchQueryOptions();
        forkJoin(observables).pipe(concatMap(() => {
                const options: SearchQueryOptions = this.handsontableGenerator.getUserFilterForItems(this.data);
                const users$: Observable<ListResponse<User>> = this.api.users.searchMany(options);

                if (this.seriesComponents == null) {
                    const filters: any = {
                        name: 'series_component',
                        op: 'ne',
                        val: null
                    };
                    component_options.filters = [filters];
                } else {
                    this.components = null;
                    const nestedFilter: any = {
                        name: 'id',
                        op: 'in',
                        val: this.seriesComponents.map((seriesComponents: SeriesComponent) => seriesComponents.id)
                    };
                    const parentFilters: any = {
                        name: 'series_components',
                        op: 'any',
                        val: nestedFilter
                    };
                    component_options.filters = [parentFilters];
                }
                return forkJoin([users$, this.api.component.searchMany(component_options)])
                    .pipe(
                        takeUntilDestroyed(this),
                        tap((response: [ListResponse<User>, ListResponse<WireComponent>]): void => {
                            this.users = response[0].data;
                            this.components = response[1].data;
                            this.createTable();
                        })
                    )
            }), takeUntilDestroyed(this)
        ).subscribe();
        this.buildHeader();
    }

    loadNextSeriesComponents = (pageNumber: number = 0, pageSize?: number, filters?: object[]): Promise<SeriesComponent[]> => {
        // TODO Filter for resource types
        const options: SearchQueryOptions = new SearchQueryOptions();
        options.page_size = pageSize || this.pageSize;
        options.page_number = pageNumber;
        options.filters = filters;

        return firstValueFrom(this.api.series_component.searchMany(options).pipe(
            concatMap((response: ListResponse<SeriesComponent>) => {
                this.seriesComponents = (this.seriesComponents || []).concat(response.data);
                this.data = [...this.seriesComponents];
                return this.fetchMissingSeries().pipe(map(() => response.data));
            })));
    }

    fetchMissingSeries(): Observable<ListResponse<SeriesLight>> {
        const seriesComponentIds = this.seriesComponents.map(sc => sc.relationships.series.data.id);
        const seriesLightIds = new Set(this.series_light.map(s => s.id));

        const missingSeriesIds = seriesComponentIds.filter(id => !seriesLightIds.has(id));
        if (!missingSeriesIds?.length) return of(null);
        let options = new SearchQueryOptions();
        options.filters = [getBaseFilter(missingSeriesIds, 'id', 'in')];
        return this.api.series_light.searchMany(options).pipe(tap(result => {
            if (result.data) {
                this.series_light = Object.assign(this.series_light, result.data);
            }
        }));
    }

    createTable(): void {
        this.component_lookups = this.handsontableGenerator.gen_lookups(this.components);
        const seriesLookups = this.handsontableGenerator.gen_lookups(this.series_light);
        this.schema = {
            id: null,
            type: 'series_component',
            attributes: {
                created_on: null,
                changed_on: null,
                report_group: null,
                can_edit: null,
                view_on_flowchart: null,
                series_order: null,
                latest_value: null
            },
            relationships: {
                series: {data: {id: null, type: 'series'}},
                component: {data: {id: null, type: 'component'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        if (this.schema.relationships.process && this.process !== undefined) {
            this.schema.relationships.process.id = this.process.id;
        }

        this.column_list = [{
            data: 'attributes.report_group',
            title: 'Group',
        }, {
            data: 'attributes.series_order',
            title: 'Order',
            type: 'numeric',
        }, {
            data: 'attributes.can_edit',
            title: 'Edit',
            type: 'checkbox',
        }, {
            data: 'attributes.view_on_flowchart',
            title: 'FlowChart',
            type: 'checkbox',
        }, {
            data: this.handsontableGenerator.genLookupDataSource(seriesLookups, 'series'),
            title: 'Series',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(this.component_lookups, 'component'),
            title: 'Component',
            renderer: 'infinite_scroller',
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By',
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By',
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        const extraAfterChange = (changes: [number, string | number, any, any][], source: string) => {
            // This will fire after every afterChange event, if there is a noticeable performance hit, it can be limited with
            //      if (!['edit', 'CopyPaste.paste'].includes(source)) return;
            // though a few cases might be missing.
            this.refreshTable();
        };

        this.hot = this.handsontableGenerator.generateTable(
            this.api.series_component,
            this.schema,
            this.column_list,
            this.hot,
            extraAfterChange,
            Boolean(this.pageSize)
        );

        this.setupInfiniteScroll();
        this.hot.ready = true;
        this.hot.settings.data = this.data;
        this.hot.settings.rowHeaders = false;
        this.hot.query_handler = this.loadNextSeriesComponents;
        this.hot.page_size = this.pageSize;

        if (this.pageSize) {
            const defaultLookup = ['name', 'description'];
            this.hot.query_lookup = {
                attributes: [],
                relationships: {
                    series: defaultLookup,
                    component: defaultLookup,
                    created_by: ['name'],
                    changed_by: ['name']
                }
            };
        }
        this.hot.initializeInstance(this.hot_anchor.nativeElement);
    }

    getByName(pasted, config: { startRow: number, startCol: number, endRow: number, endCol: number }) {
        const from_pasted =
            this.handsontableGenerator.getFiltersFromPasted(pasted, config, this.hot, this.component_lookups, ['Component']);
        const ids: string[] = this.hot.change_ids;
        this.api.component.searchMany(from_pasted.options).pipe(first(), takeUntil(this.onDestroy),
            tap(result => {
                this.components = _uniq(this.components.concat(result.data));
                this.component_lookups = this.handsontableGenerator.gen_lookups(this.components);
                this.handsontableGenerator.matchLookupData(this.hot, this.data, this.component_lookups, 'component', from_pasted.paste_map);
                const col_index = this.hot.instance.getColHeader().indexOf('Component');
                this.hot.settings.columns[col_index].data = this.handsontableGenerator.genLookupDataSource(this.component_lookups, 'component');
            })).subscribe(() => {
            this.hot.change_ids = ids;
            this.hot.instance.updateSettings(this.hot.settings, false);
        });
    }

    refreshTable = (): void => {
        if (this.hot.instance) {
            // @ts-ignore
            _remove(this.data, item => !(item.relationships.series.data && item.relationships.series.data.id));
            this.hot.instance.alter('insert_row', this.data.length, 0);
            this.hot.instance.render();
        }
    }

    setupInfiniteScroll(): void {
        this.hot.settings.afterPaste = (pasted, config) => {
            this.getByName(pasted, config[0]);
        };
        this.hot.settings.afterOnCellMouseDown = (event, coords) => {
            const dialogConfig = this.handsontableGenerator.genScrollerCallback(event, coords, this.hot, this.data, this.component_lookups,
                this.components, 'component', 'component', this.component_filters, null);
            if (dialogConfig) {
                const modalDialog = this.matDialog.open(CustomDialogComponent, dialogConfig);
                return false;
            }
        };
    }

    save = (): void => {
        // TODO figure out why when this saves everything goes blank, but it actually saves
        // It does not do this on the series_sheet
        const results = this.hot.save();
        this.data = results.data;
        this.refreshTable();
    }

    download = (): void => {
        this.hot.download();
    }

    buildHeader(): void {
        if (this.process) {
            this.headerData.title = 'Report Configuration: ' + this.process.attributes.name;
            this.headerData.setPath(this.process);
        } else {
            this.headerData.title = 'Report Configuration';
        }
        this.headerData.buttons = [
            {name: 'Save', func: this.save, class: 'icon-save', params: {}},
            {name: 'Download', func: this.download, class: 'icon-download', params: {}}
        ];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
