import {Component, Input, OnInit} from '@angular/core';
import {Process} from '../../_models/process';
import {ConfigStub} from "../../_typing/config-stub";

@Component({
    selector: 'log-sheet-form',
    templateUrl: 'log-sheet-form.component.html',
    standalone: false
})
export class LogSheetFormComponent implements OnInit {

    private _config: any;
    @Input()
    set config(config) {
        if (!config.hidden_columns) {
            config.hidden_columns = [];
        }
        this._config = config;
        this.inputUpdated();
    }

    get config() {
        return this._config;
    }

    logsheet_columns: string[] = [];

    constructor() {
    }

    ngOnInit() {
        const ctrl = this;
        // TODO add the full list of columns
        ctrl.logsheet_columns = ['Value', 'Status', 'Units'];
    }

    private inputUpdated() {
        const ctrl = this;
        if (!ctrl._config.title) {
            ctrl._config.title = '';
        }
    }

    private slimProcess(process) {
        return {
            id: process.id,
            attributes: {
                name: process.attributes.name
            }
        }
    }

    processChange(process: Process) {
        this.config.process = new ConfigStub(process);
    }

}
