<ng-container *ngIf="value">
  <ng-container *ngIf="value.is_set || (uploadedFile && !component?.id); else uploadFileBlock">
    <div class="uploaded-document-wrapper ">
      <input class="file-text" disabled [matTooltip]="value.filename || uploadedFile.name"
             matTooltipClass="full-value-tooltip"
             [matTooltipShowDelay]="tooltipShowDelay" value="{{value.filename || uploadedFile.name}}">
      <div class="button-container">
        <button (click)="downloadFile(value)" mat-fab color="warn" class="button"
                *ngIf="component?.id"
                [matTooltip]="may_download ? 'Download file.' : 'You\'re not authorised to download file attachments.'"
                [disabled]="!may_download">
          <mat-icon>download</mat-icon>
        </button>
        <button
            (click)="openConfirmWindow()" mat-fab color="warn" class="button"
            [matTooltip]="may_delete ? 'Delete file.' : 'You\'re not authorised to delete file attachments.'"
            [disabled]="!may_delete">
          <div class="fa fa-trash"></div>
        </button>
      </div>
    </div>

  </ng-container>
  <ng-template #uploadFileBlock>
    <button
        class="file-upload-button"
        mat-button (click)="selectFile()"
        [matTooltip]="may_upload ? 'Upload file.' : 'You\'re not authorised to upload file attachments.'"
        [disabled]="!may_upload">
      Upload Document
    </button>
  </ng-template>
</ng-container>
