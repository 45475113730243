import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModelID} from '../../_typing/generic-types';
import {ComponentType} from "../../_models/component-type";
import {EventType} from "../../_models/event-type";

export interface DialogData {
    component_or_event: ComponentType | EventType;
    constant_property_id: ModelID;
}

@Component({
    selector: 'constant-property-calc-tree-dialog',
    templateUrl: './constant-property-calc-tree-dialog.component.html',
    styleUrls: ['./constant-property-calc-tree-dialog.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ConstantPropertyCalcTreeDialogComponent implements OnInit {
    component_or_event: ComponentType | EventType;
    constant_property_id: ModelID;

    component_type_id: ModelID;
    event_type_id: ModelID;

    constructor(private dialogRef: MatDialogRef<ConstantPropertyCalcTreeDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
    }

    ngOnInit(): void {
        if (this.dialogData.component_or_event.type === 'component_type') {
            this.component_type_id = this.dialogData.component_or_event.id;
        } else {
            this.event_type_id = this.dialogData.component_or_event.id;
        }
        this.constant_property_id = this.dialogData.constant_property_id;
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}
