import {Injectable} from '@angular/core';
import {guid} from "../lib/utils";
import {ModelID} from "../_typing/generic-types";

export interface Error {
    component_name: string;
    function_name: string;
    api_function?: string;
    custom_message?: string;
    error_object?: any;
    timestamp?: Date;
    object_id: ModelID;
}

@Injectable()
export class ErrorBankService {
    public errors = [];
    public errorDict = {};

    constructor() {
    }

    public addError(component_name: string,
                    function_name: string,
                    api_function?: string,
                    custom_message?: string,
                    error_object?: any,
                    object_id?: any): void {
        const error = {
            component_name: component_name,
            function_name: function_name,
            api_function: api_function,
            custom_message: custom_message,
            error_object: error_object,
            timestamp: new Date(),
            object_id: object_id,
            id: guid()
        }
        this.errors.push(error);
        if (error.object_id) {
            this.errorDict[object_id] = error;
        }
    }

    public deleteError(id: string) {
        this.errors.splice(this.errors.indexOf(e => e.id === id));
    }

    public getMessageString() {
        console.log('ErrorBankService - getMessageString: ', this.errors);
        return this.errors.map(e => e.custom_message).join('. ');
    }

    public clear() {
        this.errors = [];
        this.errorDict = {};
    }
}
