import {Injectable, OnDestroy} from '@angular/core';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {Subject} from "rxjs";
import {AppScope} from '../../services/app_scope.service';
import {getAccountFilter} from "../../services/api/filter_utils";
import {ModelID} from "../../_typing/generic-types";

@Injectable()
export class ComponentTypesTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private appScope: AppScope) {
    }

    getComponentTypesFilter(accountId?: ModelID) {
        const options = new SearchQueryOptions();
        options.filters = [];
        if (accountId) options.filters = [getAccountFilter(accountId)];
        return options.filters;
    }

    updateSearchFilter(filter_string: string, accountId?: ModelID) {
        const filters = this.getComponentTypesFilter(accountId);
        const atts = ['name', 'description'];
        let string_filters = {or: []};
        atts.forEach(att => {
            string_filters.or.push({op: 'ilike', name: att, val: '%' + filter_string + '%'});
        });

        const cp_filters = this.constantPropertiesSearchFilter(filter_string, accountId);
        string_filters.or = string_filters.or.concat(cp_filters);

        filters.push(string_filters);
        return filters;
    }


    constantPropertiesSearchFilter(filter_string, accountId?: ModelID) {
        const constantPropertiesFilters = [];

        constantPropertiesFilters.push({
            name: "constant_properties",
            op: "any",
            val: {name: 'name', op: 'ilike', val: '%' + filter_string + '%'}
        });
        if (accountId) constantPropertiesFilters.push(getAccountFilter(accountId));
        return constantPropertiesFilters;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
