<ng-container *ngIf="selectedField?.type==='string'">
  <div class="select-search-field" *ngIf="!compareBlank">
    <input matInput [(ngModel)]="rule.value" (change)="rule.value = rule.value"
           placeholder="Input criteria"/>
  </div>
</ng-container>

<ng-container *ngIf="selectedField?.type==='number'">
  <div class="select-search-field" style="text-align: right;" *ngIf="!compareBlank">
    <input matInput type="number" step="any" [(ngModel)]="rule.value" />
  </div>
</ng-container>

<ng-container *ngIf="selectedField?.type==='category'">
  <div class="select-search-field" *ngIf="!compareBlank">
    <mat-form-field class="no-label">
      <mat-select [(ngModel)]="rule.value" >
        <mat-option *ngFor="let opt of selectedField.options" [value]="opt.value">
          {{opt.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="selectedField?.type==='dropdown'">
  <div class="select-search-field" *ngIf="!compareBlank">
    <select-filter
        (constant_changed)="rule.value = $event; "
        [modelValue]="rule.value"
        [placeholder]="'Select option'"
        [options]="selectedField.options"
        [class]="'select-filter-inline'">
    </select-filter>
  </div>
</ng-container>

<ng-container *ngIf="selectedField?.type==='datetime'">
  <div class="row auto" *ngIf="!compareBlank">
    <mat-form-field class="select-inline no-label">
      <mat-select [(ngModel)]="rule.value_compare_to" (ngModelChange)="onChangeCompareType($event)">
        <mat-option *ngFor="let opt of compareTypeDict[selectedField.type]" [value]="opt">
          {{ValidationCompareType[opt] | upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="rule.value_compare_to==='field'" class="row">
      <field-option [rule]="rule" [value]="rule.value" [fields]="fieldsByType['datetime']"
                    (selectedFieldChanged)="rule.value=$event"></field-option>
      <mat-checkbox matTooltip="Mark this field as valid when the comparison field is null"
                    [(ngModel)]="rule.allow_null_compare">Allow when null
      </mat-checkbox>
    </div>
    <div *ngIf="rule.value_compare_to==='input'" class="date-picker-container">
      <date-time-picker
          (date_changed)="rule.value = $event.toISOString();"
          [input_date]="rule.value"
      ></date-time-picker>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="selectedField?.type==='boolean'">
  <div class="select-search-field" *ngIf="!compareBlank">
    <mat-checkbox [(ngModel)]="rule.value" ></mat-checkbox>
  </div>
</ng-container>
