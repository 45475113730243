import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {SelectSearchApiService} from '../select-search-api/select-search-api.service';
import {uniqueObjectList} from "../../lib/utils";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DblClickCopyDirective} from "../../directives/dbl-click-copy.directive";
import {DivInfiniteScrollDirective} from "../../directives/div-infinite-scroll.directive";
import {MatInputModule} from "@angular/material/input";
import {StringFunction, ValueFunction} from "../select-search-api/types";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     MatFormFieldModule,
    //     ReactiveFormsModule,
    //     DragDropModule,
    //     MatTooltipModule,
    //     MatInputModule,
    //     DblClickCopyDirective,
    //     DivInfiniteScrollDirective
    // ],
    selector: 'search-api',
    templateUrl: 'search-api.component.html',
    styleUrls: ['search-api.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SelectSearchApiService],
    standalone: false
})
export class SearchApiComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();
    private _value: any[];

    @ViewChild('search') searchTextBox: ElementRef;
    searchTextboxControl: FormControl<any> = new FormControl();
    @Input() action: { icon_class: string; };
    @Input() placeholder: string = 'Search components';
    @Input() disabled: boolean = false;
    /**Entire selection element group**/
    @Input() disable_items: boolean = false;
    /**Individual items**/
    @Input() openOnInit: boolean = false;
    @Input() classes: string = 'fill';
    @Input() label: string = '';
    @Input() allow_navigate: boolean;
    @Input() stringFunction: StringFunction;
    @Input() valueFunction: ValueFunction;
    @Input() compareFunctionName; /**Default = 'compareById'**/

    /**The api call that actually fetches data on the Input() api_model, defined in parent**/
    @Input() fetchData: (index: Number, filterValue: String, items_selected?: any[], items_removed?: any[]) => Observable<any[]>

    /**The api call that fetches selected data, defined in parent**/
    @Input() fetchSelected: (index: Number, filterValue: String, items_selected?: any[], items_removed?: any[]) => Observable<any[]>

    /**
     * Preselect items in the data list.
     * @param value
     */
    @Input()
    set value(value: any[]) {
        this._value = value;
    }

    get value(): any[] {
        return this._value;
    }

    /**
     * For running a function when the selection was changed.
     */
    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectChangeComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeWithoutChange: EventEmitter<any> = new EventEmitter();
    @Output() itemsAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemsRemoved: EventEmitter<any> = new EventEmitter();
    @Output() actionClicked: EventEmitter<any> = new EventEmitter();
    $options_available: Observable<{ text: string, value: any }[]>;

    selectedItems: { text: string, value: any }[] = [];
    availableItems: { text: string, value: any }[] = [];

    initialised: boolean = false;
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;

    constructor(public sapi: SelectSearchApiService,
                private changeDetection: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.menuOpened();
    }

    menuOpened() {
        console.log('SearchApiComponent - menuOpened: ', this.placeholder);
        if (!this.initialised) {
            this.initialised = true;

            this.$options_available = this.sapi.connect();
            this.$options_available.pipe(
                tap(list => {
                    list = uniqueObjectList(list, 'value');
                    this.availableItems = list;
                }),
                takeUntil(this.onDestroy)
            ).subscribe(() => this.changeDetection.markForCheck());


            this.searchTextboxControl.valueChanges
                .pipe(takeUntil(this.onDestroy),
                    debounceTime(300),
                    takeUntil(this.onDestroy)
                ).subscribe(values => {
                // reset dropdown list when searching
                this.sapi.resetList();
                this.getNextBatch();
            });
        }
        this.clearSearch();
    }

    getNextBatch(page?) {
        this.getNextAvailable(page);
    }

    getNextAvailable(page?) {
        this.sapi.getNextBatch(this.fetchData, this.searchTextboxControl.value, page);
    }

    clearSearch() {
        this.searchTextboxControl.patchValue('');
    }

    select(event) {
        this.value = event;
        this.selectionChange.next(event.value);
    }

    relayAction(option) {
        this.actionClicked.next(option);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
