import {Event as WireEvent} from "../../_models/event";
import {KeyMap, ModelID} from '../../_typing/generic-types';
import {ConfigColumn, EventConfigColumn} from "../../_typing/config/config-column";

export function sortDates(a, b, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function filterOutTempEvents(allEvents: WireEvent[]): WireEvent[] {
    return allEvents.filter(event => !event.id.includes('temp'));
}

export function getColId(column: EventConfigColumn | ConfigColumn): ModelID | string {
    return column.type === 'attribute' ? column.id.toLowerCase() : column.id;
}

export function getColIds(columns: EventConfigColumn[] | ConfigColumn[]): ModelID[] | string[] {
    if (!columns?.length) return [];
    return columns.map(c => getColId(c));
}

export function getPropertyIds(columns: EventConfigColumn[] | ConfigColumn[]): ModelID[] {
    return getIdsForAttributeValue(columns, 'type', 'constant_property');
}

export function getPropertyColumns(columns: EventConfigColumn[]): EventConfigColumn[] {
    return columns.filter(c => c.type === 'constant_property');
}

export function getComponentTypeIds(columns: EventConfigColumn[] | ConfigColumn[]): ModelID[] {
    return getIdsForAttributeValue(columns, 'type', 'component_type');
}
export function getEventTypeIds(columns: EventConfigColumn[] | ConfigColumn[]): ModelID[] {
    return getIdsForAttributeValue(columns, 'type', 'event_type');
}

export function getAttributeIds(columns: EventConfigColumn[] | ConfigColumn[]): ModelID[] {
    return getIdsForAttributeValue(columns, 'type', 'attribute');
}

export function getIdsForAttributeValue(columns: EventConfigColumn[] | ConfigColumn[], att: string, value: string | boolean): ModelID[] | string[] {
    if (!columns?.length) return [];
    return (columns.filter(c => c[att] === value) || []).map(c => c.type === 'attribute' ? c.id.toLowerCase() : c.id)
}

export function belongsToEventType(row: WireEvent, col_id: string, type: 'constant_property' | 'component_type',
                                   et_cp_dict?: KeyMap<string[]>, et_ct_dict?: KeyMap<string[]>): boolean {
    const et_id = row.relationships.event_type?.data?.id;
    if (!et_id) return false;
    if ((type === 'constant_property' && et_cp_dict?.[et_id]?.includes(col_id)) ||
        type === 'component_type' && et_ct_dict?.[et_id]?.includes(col_id)) {
        return true;
    }
    return false;
}

export function isTempId(row) {
    return row.id?.includes('temp_');
}

export function oreBodyName(item): string | undefined {
    if (!item || !item.attributes) {
        return;
    }
    if (item.attributes.full_path_names?.[0]) {
        return item.attributes.full_path_names[0];
    } else {
        return item.attributes.name;
    }
}
