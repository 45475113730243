import {significantNumber} from "../../utils";
import {BaseComponentViewModel} from "./base-component-viewmodel";
import {ProcessViewModel} from "./process-viewmodel";

export class SeriesViewModel extends BaseComponentViewModel {

    constructor(SeriesDataModel, parent_process: ProcessViewModel, parent_component) {
        super(SeriesDataModel, parent_process, parent_component)
    }

    has_series() {
        if (!this.data.relationships.series.data || !this.data.relationships.series.data.id) {
            return false;
        } else {
            return true;
        }
    };

    view_on_flowchart() {
        if (((this.data.attributes.view_on_flowchart && this.tree_position == "child")
            || (this.data.attributes.view_on_parent_flowchart && this.tree_position == "parent"))
            && this.has_series() === true) {
            return true;
        }
        return false;
    };

    name() {
        if (!this.data.attributes.json[this.tree_position].name) {
            if (this.has_series() === false) {
                this.data.attributes.json[this.tree_position].name = "";
            } else {
                this.data.attributes.json[this.tree_position].name = this.data.relationships.series.data.attributes.name + " - " + this.data.relationships.series.data.attributes.description;
            }
        }

        return this.data.attributes.json[this.tree_position].name;
    };

    value() {
        if (this.has_series() === true) {
            return significantNumber(this.data.relationships.series.data.attributes.value);
        } else {
            return "";
        }
    };

    unit() {
        return this.data.attributes.json[this.tree_position].unit;
    };

    status() {
        if (this.has_series() === true) {
            return this.data.relationships.series.data.attributes.status;
        } else {
            return null;
        }
    };
}
