import {Component, Input, OnInit} from '@angular/core';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {ApiService} from "../../services/api/api.service";
import {SPCChartTileConfiguration} from "../../charts/chart-config/generic-chart-tile.configuration";
import {FormControl, Validators} from "@angular/forms";
import {Series} from "../../_models/series";

@Component({
    selector: 'spc-chart-form',
    templateUrl: './spc-chart-form.component.html',
    styleUrls: ['./spc-chart-form.component.less'],
    standalone: false
})
export class SpcChartFormComponent implements OnInit {

    @Input() config: SPCChartTileConfiguration;
    full_series_list: any[];
    disabled: boolean;
    public name_description_map: any = {};
    public name_list: any[] = [];
    public selectedSeries: Series;
    limits: {
        hi: number,
        hihi: number,
        lowlow: number,
        low: number,
        mean: number,
        std: number,
    };
    period_ma_validator = new FormControl("", [Validators.max(14), Validators.min(0)]);
    oneSigma = new FormControl("");
    twoSigma = new FormControl("");
    threeSigma = new FormControl("");
    constructor(public dateTimePeriodService: DateTimePeriodService,
                public api: ApiService,
    ) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.limits = {
            hi: null,
            hihi: null,
            lowlow: null,
            low: null,
            mean: null,
            std: null,
        };
        if (ctrl.config.limits !== undefined) {
            ctrl.limits = ctrl.config.limits;
        }
        if (ctrl.config.limits === undefined) {
            ctrl.config.three_sigma = 1.96;
        }
        ctrl.api.series_light.searchMany().toPromise().then((response) => {
            ctrl.full_series_list = response.data;
            response.data.map((series) => {
                ctrl.name_list.push(series.attributes.name);

                ctrl.name_description_map[series.attributes.name] = series.attributes.name + '-' + series.attributes.description;
            });
            if (ctrl.config.series_list[0]?.name) {
                const id = ctrl.config.series_list[0].id;
                ctrl.selectedSeries = ctrl.full_series_list.find(s => s.id === id);
            }
        });
    }
    imposeMinMax(val, name) {
        if (!val.value) {
            val.value = 0;
            this.config[name] = 0;
        }
        if (val.errors) {
            if (val.errors.min) {
                if (parseInt(val.value, null) < parseInt(val.error.min.min, null)) {
                    this[name].setValue(val.errors.min.min);
                    this.config[name] = this[name].value;
                }
            }
            if (val.errors.max) {
                if (parseInt(val.value, null) > parseInt(val.errors.max.max, null)) {
                    this[name].setValue(val.errors.max.max);
                    this.config[name] = this[name].value;
                }
            }
        }
    }

    getSelected() {
        this.config.series_list[0].id = this.selectedSeries.id;
        this.config.series_list[0].name = this.selectedSeries.attributes.name;
        this.config.limits = this.limits;
        this.config.labels.title = !!this.config.labels.y_axis ? this.config.labels.y_axis : this.selectedSeries.attributes.description;
        this.config.labels.sub_title = this.selectedSeries.attributes.name;
    }

    updateAxes(event) {
        if (event.checked === true) {
            this.config.hide_legend = true;
            this.config.hide_tick_labels = true;
        }
    }

    changeColour($event, key) {
        this.config[key] = $event;
    }

}
