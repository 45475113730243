import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export const COMPONENT_BASE_TYPE_ARRAY = ['component', 'process', 'equipment', 'stream', 'part', 'resource'] as const;
export type COMPONENT_BASE_TYPES = typeof COMPONENT_BASE_TYPE_ARRAY[number];


export class ComponentAttributes<T extends COMPONENT_BASE_TYPES> extends AuditMixinAttributes {
    name: string;
    description: string;
    base_type: T;
    json: any;
    code?: string;
    custom_constants?: any;
    custom_series: any;
    start_time?: string;
    end_time?: string;
    component_type_id?: string;
}

export class ComponentRelationships extends AuditMixinRelationships {
    component_type: SINGLE_RELATIONSHIP_TYPE;
    constant_components: LIST_RELATIONSHIP_TYPE;
    equipment: LIST_RELATIONSHIP_TYPE;
    series: LIST_RELATIONSHIP_TYPE;
    series_components: LIST_RELATIONSHIP_TYPE;
    revision: SINGLE_RELATIONSHIP_TYPE;
}

/**
 * NOTE the rest interface for components is broken though you most likely want a child type of component.
 */
export class Component extends AuditMixin {
    attributes: ComponentAttributes<COMPONENT_BASE_TYPES>;
    id: string;
    relationships: ComponentRelationships;
    type: string;

    // legacy used for flowcharts
    is_parent: boolean;

    constructor(base_type: COMPONENT_BASE_TYPES = 'component') {
        super();
        this.attributes = new ComponentAttributes();
        this.attributes.base_type = base_type;
        this.type = base_type;
        this.relationships = new ComponentRelationships();
    }
}

export interface WireComponent extends Component {
}

export function isComponent(object): object is Component {
    return COMPONENT_BASE_TYPE_ARRAY.includes(object.type);
}
