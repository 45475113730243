import {Directive, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from "rxjs";
import {ApiService} from './api/api.service';
import {SeriesStringParser} from "./series_string_parser.service";
import {ComponentType} from '../_models/component-type';
import {Series} from '../_models/series';

import {Tile, Tile as TileModel} from "../_models/tile";

import {ConditionalFormattingConfig} from '../tables/conditional-formatting.service';
import {HeaderDataService} from "./header_data.service";
import {EventConfigColumn} from "../_typing/config/config-column";
import {ChartLibraryType} from "./chart-config-translation.service";
import {ColumnFormatsConfigDict} from "../forms/table-column-menu/table-column-menu.component";
import {ConfigStub} from '../_typing/config-stub';
import {ParserConfigTemplate} from "../_models/parser-config-template";
import {ModelSelectionUploadOption} from "../forms/upload-model-form/upload-model-form-dialog.component";
import {IDMap, KeyMap} from '../_typing/generic-types';
import {SeriesType} from '../_models/series-type';
import {IDateTimePeriod} from "../_typing/date-time-period";
import {ConfigColumn} from "../_typing/config/config-column";
import {TileContentType} from "../_typing/config/tile";
import {CustomFunction} from "../_models/custom-function";
import {WireRuleSet} from "../_typing/query-builder";
import {ITileButton} from "../_typing/tile-button";
import {NotificationService} from "./notification.service";

export class CONFIG_STUB {
    id: string = '';
    attributes?: {
        name: string;
        description?: string;
        alias?: string;
        base_type: string;
    } = {
        name: '',
        description: '',
        alias: '',
        base_type: ''
    };
    type: string = '';

    constructor() {
    }
}

@Directive()
@Injectable({
    providedIn: 'root'
})
export class TileDataService {
    private buttonsSubject = new BehaviorSubject<ITileButton[]>([]);
    buttons$: Observable<ITileButton[]> = this.buttonsSubject.asObservable();

    public config_stub_schema = {
        id: '',
        attributes: {
            name: '',
            description: '',
            base_type: ''
        },
        type: ''
    };
    public readonly tile_classes = ['quarter', 'third', 'half', 'two-thirds', 'full'];
    public readonly content_types: TileContentType[] = [
        {name: 'Advanced Pivot Table', value: 'pivot-tile-ng', tooltip: ''},
        {name: 'Batch Series Correction', value: 'series-adjustment', tooltip: ''},
        {name: 'Comparison Chart', value: 'comparison-chart', tooltip: ''},
        {name: 'Category Chart', value: 'category-chart', tooltip: ''},
        {name: 'Comment Table', value: 'comment-tile', tooltip: ''},
        {name: 'Component Creator', value: 'component-creator', tooltip: ''},
        {name: 'Component Form Tile', value: 'component-form', tooltip: ''},
        {name: 'Component Events Table', value: 'component-events-table', tooltip: ''},
        {
            name: 'Context Component',
            value: 'context',
            tooltip: 'Deprecated, please use Series Summary instead.',
            deprecated: true
        },
        {name: 'CSV Uploader (data models)', value: 'upload-model', tooltip: ''},
        {name: 'Custom Chart', value: 'custom-chart', tooltip: ''},
        {name: 'Custom Events', value: 'custom-events', tooltip: ''},
        {name: 'Custom Events Table', value: 'custom-events-table', tooltip: ''},
        {name: 'Custom HTML', value: 'custom', tooltip: ''},
        {name: 'Data Exceptions Table', value: 'data-exceptions-table', tooltip: ''},
        {name: 'Data Review Sheet', value: 'input-sheet', tooltip: ''},
        {name: 'Default Chart', value: 'default-chart', tooltip: ''},
        {name: 'Events', value: 'events', tooltip: ''},
        {name: 'Flowchart', value: 'flowchart', tooltip: ''},
        {name: 'Julian Date', value: 'julian-date', tooltip: ''},
        {name: 'Log Sheet', value: 'log-sheet', tooltip: ''},
        {name: 'Model Pivot Table', value: 'model-pivot-tile', tooltip: ''},
        {name: 'OreBody Events', value: 'ore-body-events', tooltip: ''},
        {
            name: 'Page Comment Table',
            value: 'page-comment-table',
            tooltip: 'A table for showing comments made on selected pages'
        },
        {name: 'Paragraph', value: 'paragraph', tooltip: ''},
        {name: 'Pending Context', value: 'pending-context', tooltip: ''},
        {name: 'Pivot Table', value: 'pivot-tile', tooltip: ''},
        {name: 'Printout Mapper', value: 'printout-mapper', tooltip: ''},
        {name: 'Series Data Table', value: 'series-data-table', tooltip: ''},
        {name: 'Series Summary', value: 'series-summary', tooltip: ''},
        {name: 'Series Table', value: 'series-table', tooltip: ''},
        {name: 'SPC Chart', value: 'spc-chart', tooltip: ''},
        {name: 'Target Bar Chart', value: 'budget-bar-chart', tooltip: ''},
        {name: 'Value Driver Tree', value: 'value-driver-tree', tooltip: ''},
        {name: 'Waterfall Chart', value: 'waterfall-chart', tooltip: ''},
        {name: 'Weather Widget', value: 'weather-widget', tooltip: ''}];

    events: any[];
    title: string;
    sub_title: string;
    comment: any = {};
    show_comment_dtp: boolean = true;
    id: string;

    [x: string]: any;

    readonly buttonsChanged: Subject<ITileButton[]> = new ReplaySubject(1);
    private permissions: any;
    public tile: TileModel;
    public tile_dtp: IDateTimePeriod; //for holding the constructed dtp for a tile

    // Observable sources
    private _tileTitle = new BehaviorSubject<string>('Please set the tile title');
    private _subtileTitle = new BehaviorSubject<string>('');
    public editingSubject: BehaviorSubject<boolean>;
    public save_content: Subject<any> = new Subject();
    //Used by tile components to indicate to page_tile whether or not there is data available to display
    public noTileData: BehaviorSubject<any> = new BehaviorSubject('');

    // Observable streams
    tileTitle = this._tileTitle.asObservable();
    tileSubtitle = this._subtileTitle.asObservable();
    editing: Observable<boolean>;

    selected: boolean = false;
    addCommentClicked = new Subject();
    commentHover = new Subject();
    commentLeave = new Subject();
    commentIconHover = new Subject<void>();
    commentIconLeave = new Subject<void>();

    //The generic chart on the context tile needs a manual event to resize.
    //tileResize is called by the contextComponent/seriesSummary when onWindowResize and  when 'pagePrinting'(headerData) is true
    tileResize: Subject<any> = new Subject();
    readonly tileChange: Subject<TileModel>;

    constructor(private api: ApiService,
                private seriesStringParser: SeriesStringParser,
                private headerData: HeaderDataService,
                private notification: NotificationService
    ) {
        this.editingSubject = new BehaviorSubject<boolean>(false);
        this.editing = this.editingSubject.asObservable();
        this.tileChange = new Subject();
    }

    updateButtons(buttons: ITileButton[]): void {
        this.buttonsSubject.next(buttons);
        this.buttonsChanged.next(buttons);
    }

    createButton(name: string, func: () => any, icon: string, hint: string, params: object = {}): ITileButton {
        return { name, func, params: params, class: icon, HoverOverHint: hint };
    }


    //Service title commands
    setDefaultTitle(title: string) {
        this._tileTitle.next(title);
    }

    setDefaultSubtitle(subtitle: string) {
        this._subtileTitle.next(subtitle);
    }

    public setEditing(newValue: boolean): void {
        this.editingSubject.next(newValue);
        this.headerData.toggleGridMode.next(newValue);
        this.headerData.tile_edit_mode.next(newValue);
    }

    parseForSeriesValues(text: string, dtp: IDateTimePeriod): Observable<string> {
        return this.seriesStringParser.parseSeriesString(text, dtp);
    }

    translateClass(class_name: string, cols: number = 12) {
        const obj = {cols: 4, rows: 5};

        switch (class_name) {
            case 'quarter':
                obj.cols = Math.floor(cols / 4);
                break;
            case 'third':
                obj.cols = Math.floor(cols / 3);
                break;
            case 'half':
                obj.cols = Math.floor(cols / 2);
                break;
            case 'two-thirds':
                obj.cols = Math.floor(cols / 3 * 2);
                break;
            case 'full':
                obj.cols = cols;
                break;
            default:
                break;
        }

        return obj;
    }

    public getDefaultTileContent() {
        return 'default-chart';
    }

    public getDefaultTileParameters() {
        return {
            "title": "",
            "hide_comments": false,
            // "series": null,
            // "estimate_type": "Forecast"
        };
    }

    saveTile(tile: TileModel): Observable<any> {
        const ctrl = this;
        if (tile.hasOwnProperty('id')) {
            return ctrl.api.tile.obsPatch(tile);
        } else {
            return ctrl.api.tile.obsSave(tile);
        }
    }

    confirmDeleteTile(tile: Tile) {
        let deleteWarning = 'Are you sure you want to delete this tile';
        if (tile.relationships?.session_states?.data?.length > 1) {
            deleteWarning = `This tile is referenced on more than one page. ${deleteWarning}`;
        }
        return this.notification.openConfirm(deleteWarning).onAction()
    }

    deleteTile(tile_id: string): Observable<any> {
        if (!tile_id) {
            return of(null)
        }
        return this.api.tile.obsDelete(tile_id);
    }
}

export interface Session {
    attributes: {
        name: string,
        json: {
            sections?: Section[],
            sample_period?: any,
            calendar?: string,
            layout?: string,
            ore_bodies?: any,
            print_orientation?: string,
            auto_refresh?: boolean,
            refresh_time?: number,
            extracted?: boolean,
            grid_options?: {
                max_cols?: number,
                min_cols?: number
            }
        },
        range: any,
        visibility: any
    },
    relationships: {
        folders: { data: any[] }
    }
}

export interface Section {
    id?: string;
    cols?: number;
    rows?: number;
    y?: number;
    x?: number;
    title?: string;
    class?: string;
    tiles: GridItem[];
    page_break?: string;
    drag_orientation?: string; //This value is not saved
    hide_on_print?: boolean;
    printing?: boolean;
    height?: number;
    fixed_height?: boolean;
    string_height?: string;
}

export interface GridItem {
    id?: string;
    parent_id?: string;
    cols?: number;
    rows?: number;
    y?: number;
    x?: number;
    is_title?: boolean;
    hide_on_print?: boolean;
    relative_dtp?: any;
}

interface IComponentTypeConstantPropertiesAttributes {
    component_type: {};
    constant_properties: {};
}

interface IIdValueAttribute {
    id: null;
    value: null;
}

interface IEventTypeAttributes extends IIdValueAttribute {
    constant_properties: [];
    unique_constant_property: null;
}

interface IContextAttributes {
    component_type: IIdValueAttribute;
    event_type: IEventTypeAttributes;
}

interface ISelectedColumns {
    component_type: EventConfigColumn[];
    event: EventConfigColumn[];
    component: KeyMap<EventConfigColumn[]>;
}

type SelectedColumnsAttributes = Partial<ISelectedColumns>;


interface IState {
    height: number;
    width: number;
    pan: string;
    depth: KeyMap<number[]>;
}

type StateAttributes = Partial<IState>;

export interface ICustomHTMLParameters {
    template: string;
    jsonContext: string;
    isReport: boolean;
}

export class CustomHTMLParameters implements ICustomHTMLParameters {
    template: string;
    jsonContext: string;
    isReport: boolean;

    constructor(
        isReport: boolean = true,
        jsonContext: string = '',
        template: string = '',
    ) {
        this.template = template;
        this.jsonContext = jsonContext;
        this.isReport = isReport;
    }
}

interface ITileParameters extends ICustomHTMLParameters {
    adjustments: any[];
    allow_delete: boolean;
    chart_type: string;
    component_type_ids: string[];
    colspan: string;
    columns: EventConfigColumn[] | string[];
    column_order: string[];
    column_widths: string[];
    column_formats: ColumnFormatsConfigDict | ConfigColumn[];
    column_text: any;
    colour: string;
    component_list: any[];
    component_types: ComponentType[] | ConfigStub<ComponentType>[];
    conditional_formats: ConditionalFormattingConfig[];
    constant_property_time: false | any;
    context: IContextAttributes;
    current_page: number;
    customisation: IComponentTypeConstantPropertiesAttributes;
    diagonal_line: boolean;
    detail_columns: string[];
    default_template: ParserConfigTemplate;
    estimate_type: string | string[];
    event_type: any;
    event_types: any[] | "";
    event_type_names: string[];
    event_type_ids: string[];
    font_size: number;
    formula_view: boolean;
    functions: ConfigStub<CustomFunction>[];
    header_rows: any;
    hide_totals_rows: boolean;
    hide_totals_columns: boolean;
    hide_comments: boolean;
    hide_cumulative: boolean;
    hidden_columns: string[];
    hide_now_line: boolean;
    hide_on_print: boolean;
    is_title: boolean;
    kpis: string[];
    labels: any;
    library: ChartLibraryType;
    mobile_columns: string[];
    model_selected: ModelSelectionUploadOption;
    model_type: string;
    name_must_contain: string;
    negative_colour: string;
    non_editable_columns: string[];
    normal_columns: string[];
    ore_bodies_list: any[];
    positive_colour: string;
    paragraph_body: any;
    pivot_state: any;
    printout: boolean;
    process: any;
    process_list: any[];
    query: any;
    filter: any;
    process_series_map: Record<string, CONFIG_STUB>;
    regression_line: string;
    rowspan: string;
    series_list: any[];
    series: any;
    session_state_list: any[];
    show_comments: boolean;
    show_dtp: boolean;
    search: boolean;
    selected_calculation: Series;
    selected_component_type: Partial<ComponentType> | ConfigStub<ComponentType>;
    selected_component_relationship_types: ComponentType[];
    selected_cols?: SelectedColumnsAttributes;
    selected_series?: ConfigStub<Series>[];
    series_column_dict?: KeyMap<string[]>;
    show_data_labels: boolean;
    statistics: any;
    state: StateAttributes;
    size: any;
    target_series?: Series;
    target_series_type?: SeriesType;
    title: string;
    templates: ParserConfigTemplate[];
    total_colour: string;
    type: string;
    update_only: boolean;
    three_sigma: number;
    validation: IComponentTypeConstantPropertiesAttributes | { [key: string]: WireRuleSet | null } | WireRuleSet;
}

export type TileParameters = Partial<ITileParameters>;
