import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UploadModelTileConfig} from "../../forms/upload-model-form/upload-model-form.component";
import {ParserConfigTemplate} from "../../_models/parser-config-template";
import {first, takeUntil} from "rxjs/operators";
import {UploadModelTemplateService} from "../../forms/upload-model-form/upload-model-template.service";
import {ConfigStub} from '../../_typing/config-stub';
import {compareById, refreshSubscription} from '../../lib/utils';
import {Subscription} from 'rxjs';
import {BaseComponent} from "../../shared/base.component";
import {DropFileSelectorComponent} from "../drop-file-selector/drop-file-selector.component";

@Component({
    selector: 'upload-model',
    templateUrl: './upload-model.component.html',
    styleUrls: ['./upload-model.component.less', '../../forms/upload-model-form/upload-model-form-dialog.component.less'],
    providers: [UploadModelTemplateService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UploadModelComponent extends BaseComponent {
    @ViewChild(DropFileSelectorComponent) fileSelectorRef: DropFileSelectorComponent;

    @Input() config: UploadModelTileConfig;
    selected_template: ParserConfigTemplate;
    file: File = null;

    public show_options = false;
    preview: any;
    error_json: any;
    uploading = false;
    compareById = compareById;
    $templateSubscription: Subscription;

    constructor(private templateService: UploadModelTemplateService) {
        super();
    }

    ngOnInit(): void {
        this.templateService.unmatchedHeadersFound$.pipe(takeUntil(this.onDestroy)).subscribe(unmatched => {
            this.templateService.showUnmatched(unmatched);
        });
        if (this.config?.default_template) {
            this.templateSelected(this.config.default_template);
        }
    }

    clearTile() {
        this.file = null;
        this.fileSelectorRef.clear();
        this.preview = undefined;
        this.show_options = false;
        this.selected_template = undefined;
        if (this.config?.default_template) {
            this.templateSelected(this.config.default_template);
        }
    }

    templateSelected(event: ConfigStub<ParserConfigTemplate>) {
        this.$templateSubscription = refreshSubscription(this.$templateSubscription);
        this.$templateSubscription = this.templateService.getTemplateById(event.id).subscribe(template => {
            this.templateService.selectTemplate(template.data);
            this.selected_template = template.data;
        });
    }

    fileSelected($event) {
        this.file = $event;
    }

    uploadData() {
        const parser_type = this.config.model_selected.parser_type;
        const formData: FormData = new FormData();
        formData.append('file', this.file, this.file.name);
        formData.append('type', this.config.model_selected.parser_type);
        if (this.selected_template) {
            formData.append('template_id', this.selected_template.id);//use template.id
        }
        if (!this.selected_template.attributes.json.hasOwnProperty('update_only')) {
            this.selected_template.attributes.json.update_only = false;
        }
        formData.append('update_only', this.selected_template.attributes.json.update_only.toString());

        this.uploading = true;
        this.templateService.uploadComplete$.pipe(first())
            .subscribe(result => {
                if (result) {
                    this.error_json = result;
                }
                this.uploading = false;
            });
        this.templateService.uploadData(parser_type, formData);

    }
}
