<query-builder *ngIf="config_ready" [formControl]="queryBuilderCtrl" [config]="config" [operatorMap]="operatorMap"
               [(ngModel)]="query"
               [parentChangeCallback]="parentChangeCallback" class="query-builder">
  <ng-container
      *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
    <button #addFilter type="button" mat-button class="text-btn" (click)="addRule()">
      +Add filter
    </button>
    <button type="button" mat-button class="text-btn" (click)="addRuleSet()">
      +Add group
    </button>
    <button *ngIf="removeRuleSet" type="button" mat-button class="text-btn" (click)="removeRuleSet()">
      -Clear group
    </button>
  </ng-container>
  <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
    <button *ngIf="removeRule" type="button" mat-icon-button color="primary" class="trash-button"
            (click)="removeRule(rule)">
      <mat-icon class="button-icon"><i class="fa fa-trash"></i></mat-icon>
    </button>
  </ng-container>
  <ng-container *querySwitchGroup="let ruleset">
    <mat-button-toggle-group *ngIf="ruleset" [(ngModel)]="ruleset.condition" class="query-builder-toggle-buttons">
      <mat-button-toggle class="text-toggle" value="and">AND</mat-button-toggle>
      <mat-button-toggle class="text-toggle" value="or">OR</mat-button-toggle>
    </mat-button-toggle-group>
  </ng-container>

  <div class="row min-height">
    <ng-container *queryField="let rule; let fields=fields; let onChange=onChange; let getFields = getFields">
      <div class="select-search-field" [ngClass]="isFilterBuilder ? '' : 'lg'">
        <select-filter
            (constant_changed)="rule.field = setSelectedField($event, fields).value ; onChange(rule.field, rule)"
            [modelValue]="config?.fields[rule.field]?.name"
            [options]="getOptionsListByName(fields | arrayMap: addIdtoField) | async"
            [classes]="'fill'">
        </select-filter>
      </div>
    </ng-container>

    <ng-container *queryOperator="let rule; let operators=operators; let onChange=onChange">
      <div class="select-search-field">
        <mat-form-field class="no-label full-width">
          <mat-select [(ngModel)]="rule.operator" (ngModelChange)="onChange(rule)">
            <mat-option *ngFor="let value of operators" [value]="value" [matTooltip]="operatorDesc[value]"
                        matTooltipPosition="right">{{operatorDesc[value]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *queryInput="let rule; let onChange=onChange; type: 'string'">
      <div class="select-search-field" *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <input matInput [(ngModel)]="rule.value" (change)="rule.value = rule.value" (ngModelChange)="onChange()"
               placeholder="Input criteria"/>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; let onChange=onChange; type: 'number'">
      <div class="select-search-field" style="text-align: right;"
           *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <input matInput type="number" step="any" [(ngModel)]="rule.value" (ngModelChange)="onChange()"/>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; let field=field; let options=options; let onChange=onChange; type: 'category'">
      <div class="select-search-field" *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <mat-form-field>
          <mat-select [(ngModel)]="rule.value" (ngModelChange)="onChange()">
            <mat-option *ngFor="let opt of options" [value]="opt.value">
              {{opt.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container
        *queryInput="let rule; let field=field; let options=options; let onChange=onChange; type: 'multiselect'">
      <div class="select-search-field" *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <mat-form-field>
          <mat-select [(ngModel)]="rule.value" multiple (ngModelChange)="onChange()">
            <mat-option *ngFor="let opt of options" [value]="opt.value">
              {{opt.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container
        *queryInput="let rule; let field=field; let options=options; let onChange=onChange; type: 'dropdown'">
      <div class="select-search-field" *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <select-filter
            (constant_changed)="rule.value = $event; onChange($event, rule)"
            [modelValue]="rule.value"
            [placeholder]="'Select option'"
            [options]="getOptionsListByValue(options, field) | async"
            [classes]="'fill'">
        </select-filter>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; let onChange=onChange; type: 'datetime'">
      <div class="select-search-field" *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <date-time-picker
            (date_changed)="rule.value = $event.toISOString(); onChange()"
            [input_date]="rule.value"
        ></date-time-picker>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; let onChange=onChange; type: 'boolean'">
      <div class="select-search-field" *ngIf="!rule.operator.toLowerCase().includes('blank')">
        <mat-checkbox [(ngModel)]="rule.value" (ngModelChange)="onChange()"></mat-checkbox>
      </div>
    </ng-container>
  </div>
</query-builder>
