<table class="simple-table">
  <thead>
  <tr>
    <th>Series</th>
    <th>Description</th>
    <th>Measured</th>
    <th>Adjusted</th>
    <th>Error</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of data?.data | keyvalue">
    <td>{{row.value.name}}</td>
    <td>{{row.value.description}}</td>
    <td>{{row.value.measured | significantNumbers:3}}</td>
    <td>{{row.value.adjusted | significantNumbers:3}}</td>
    <td>{{row.value.error | significantNumbers:3}}</td>
  </tr>
  </tbody>
</table>
