import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {ComponentTypeJsonConfig} from "../_typing/config/component-form-config";

export type COMPONENT_TYPE_BASE_TYPES = 'component' | 'process' | 'equipment' | 'stream' | 'part' | 'resource';

export class ComponentTypeAttributes<T extends COMPONENT_TYPE_BASE_TYPES> extends AuditMixinAttributes {
    name: string;
    description?: string;
    json?: ComponentTypeJsonConfig;
    base_type?: string;
}

export class ComponentTypeRelationships extends AuditMixinRelationships {
    constant_properties: LIST_RELATIONSHIP_TYPE;
    property_categories: LIST_RELATIONSHIP_TYPE;
    component_category?: SINGLE_RELATIONSHIP_TYPE;
    components?: LIST_RELATIONSHIP_TYPE;
    event_types?: LIST_RELATIONSHIP_TYPE;
}

export class ComponentType extends AuditMixin {
    attributes: ComponentTypeAttributes<'component'>;
    id: string;
    type: string = 'component_type';
    relationships: ComponentTypeRelationships;
}

export function isComponentType(object): object is ComponentType {
    return (object as ComponentType)?.type === 'component_type';
}
