<!--Font Resize Context Menu-->
<div #font_context_menu (click)="$event.stopPropagation();" *ngIf="config"
     class="menu series-summary-menu">
  <ng-container *ngIf="config.selected_model">
    <h3 cdkDrag [cdkDragDisabled]="!draggable"
        cdkDragRootElement=".cdk-overlay-pane">{{config.selected_model.data.type | removeUnderscore}}</h3>

    <ng-container *ngIf="!['custom-chart', 'sparkline'].includes(config.selected_model.data.type)">
      <div class="row center space-between">
        <div class="row center">
          <label class="inline-label">Font Size:</label>
          <input (input)="draggable=false;setFontSize($event);"
                 (focusin)="draggable=false;" (focusout)="draggable=true;"
                 [(ngModel)]="config.font_size"
                 [max]="10" [min]="0.5" name="font_size"
                 step="0.1"
                 style="width:200px;" type="range">
        </div>
        <div class="info">{{config.font_size}}</div>
      </div>

      <div class="row center space-between" style="margin-top:10px;">
        <div>
          <label class="inline-label">Colour:</label>
          <colour-palette
              (selectChange)="setStyle($event, 'colour')"
              [use_picker]="true"
              [value]="config.selected_model.styles.colour"
              palette="2"
              show_text="true"
              style="width:40px;">
          </colour-palette>
        </div>
        <a (click)="config.selected_model.styles.colour = null"
           style="color:darkorange;font-weight:bold;cursor:pointer;">Unset</a>
      </div>

      <div class="row" style="margin-top:20px;">
        <button (click)="setStyle($event, 'bold')"
                [ngStyle]="{'background-color': config.selected_model.styles.bold? 'orange' : ''}"
                class="formatting-button" mat-raised-button matTooltip="Bold" matTooltipPosition="above">
          <i class="fa fa-bold"></i>
        </button>
        <button (click)="setStyle($event, 'italic')"
                [ngStyle]="{'background-color':  config.selected_model.styles.italic? 'orange' : ''}"
                class="formatting-button" mat-raised-button matTooltip="Italic" matTooltipPosition="above">
          <i class="fa fa-italic"></i>
        </button>
        <button (click)="setStyle($event, 'underline')"
                [ngStyle]="{'background-color':  config.selected_model.styles.underline? 'orange' : ''}"
                class="formatting-button" mat-raised-button matTooltip="Underline" matTooltipPosition="above">
          <i class="fa fa-underline"></i>
        </button>
      </div>

    </ng-container>

    <div style=";margin-top:20px;min-width:unset;">
      <div class="row center">
        <mat-form-field>
          <mat-label>Top (%):</mat-label>
          <input matInput
                 [(ngModel)]="config.selected_model.data.y"
                 [max]="100" [min]="0.5" name="top"
                 step="1" type="number"  >
        </mat-form-field>
        <mat-form-field>
          <mat-label>Left (%):</mat-label>
          <input matInput
                 [(ngModel)]="config.selected_model.data.x"
                 [max]="100" [min]="0.5" name="top"
                 step="1" type="number">
        </mat-form-field>
      </div>
      <div *ngIf="['custom-chart', 'sparkline'].includes(config.selected_model.data.type)" class="row center">
        <mat-form-field>
          <mat-label>Width (%):</mat-label>
          <input matInput
                 [(ngModel)]="config.selected_model.data.w"
                 [max]="100" [min]="0.5" name="top"
                 step="1" type="number"
                 style="width:200px;">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Height (%):</mat-label>
          <input matInput
                 [(ngModel)]="config.selected_model.data.h"
                 [max]="100" [min]="0.5" name="top"
                 step="1" type="number"
                 style="width:200px;">
        </mat-form-field>
      </div>
    </div>

  </ng-container>
</div>
