<div class="offline-overlay-dialog">
  <h1>Unable to connect to WIRE</h1>
  <h2>Or a high amount of requests are being processed.</h2>
  <br>
  <h2>Please wait to be reconnected or for the page to finish loading.</h2>
  <br>
  <h2>If you think this is an error, please click this button to hide this warning for the rest of this session.</h2>
  <br>
  <button mat-flat-button (click)="hideOfflineMessages()">Hide offline notification</button>
  <br>
</div>
