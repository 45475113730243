<div class="row" *ngIf="item && rows[1]">
  <button *ngIf="options.includes('bold')" (click)="$event.stopPropagation();formatColumn('bold')"
          [ngClass]="{'active': item.bold}"
          class="formatting-button" mat-button matTooltip="Bold" matTooltipPosition="above">
    <i class="fa fa-bold"></i>
  </button>
  <button *ngIf="options.includes('italic')" (click)="$event.stopPropagation();formatColumn('italic')"
          [ngClass]="{'active': item.italic}"
          class="formatting-button" mat-button matTooltip="Italic" matTooltipPosition="above">
    <i class="fa fa-italic"></i>
  </button>
  <button *ngIf="options.includes('underline')" (click)="$event.stopPropagation();formatColumn('underline')"
          [ngClass]="{'active': item?.underline}"
          class="formatting-button" mat-button matTooltip="Underline" matTooltipPosition="above">
    <i class="fas fa-underline"></i>
  </button>
  <button *ngIf="options.includes('strikethrough')"
          (click)="$event.stopPropagation();formatColumn('strikethrough')"
          [ngClass]="{'active': item?.strikethrough}"
          class="formatting-button" mat-button matTooltip="Strike through" matTooltipPosition="above">
    <i class="fas fa-strikethrough"></i>
  </button>
  <button *ngIf="options.includes('resize')" (click)="$event.stopPropagation();formatColumn('resize')"
          [ngClass]="{'active': item?.resize}"
          class="formatting-button" mat-button matTooltip="Allow custom resize" matTooltipPosition="above">
    <i class="fas fa-arrows-alt-h"></i>
  </button>
  <button *ngIf="options.includes('fit_content')" (click)="$event.stopPropagation();formatColumn('fit_content')"
          [ngClass]="{'active': item?.fit_content}"
          class="formatting-button" mat-button matTooltip="Fit column to content" matTooltipPosition="above">
    <i class="fas fa-text-width"></i>
  </button>
  <button *ngIf="options.includes('colour')"
          [ngClass]="{'active': item?.colour}"
          class="formatting-button colour" mat-button
          [matTooltip]="item?.colour? 'Click outside the brush to reset to default colour': 'Click the brush to set custom font colour'"
          matTooltipPosition="above"
          (click)="$event.stopPropagation();formatColumn('colour');">
    <i class="fas fa-paint-brush"
       [matMenuTriggerFor]="colourMenu"
       #nestedColourMenuTrigger="matMenuTrigger"
       (click)="$event.stopPropagation();showNestedMenu(nestedColourMenuTrigger)"
       [style.color]="item?.colour ? item.colour : 'inherit'"></i>
  </button>
  <mat-menu #colourMenu="matMenu" class="column-menu dark aggregation-menu">
    <div class="color-picker-menu-container color-picker-container"
         [matTooltip]="'Please click to select a custom colour'">
      <span [(colorPicker)]="item.colour"
            [style.background]="item.colour"
            [cpPresetColors]="preset_colours"
            (colorPickerChange)="item.colour = $event;formatChanged();"
            (click)="formatColumn('colour');"
            [cpToggle]="true"
            [cpDialogDisplay]="'inline'"
      ></span>
    </div>
  </mat-menu>

  <button *ngIf="options.includes('background_colour')"
          [ngClass]="{'active': item?.background_colour}"
          class="formatting-button colour" mat-button
          [matTooltip]="item?.background_colour?  'Click outsiee the paint tin to reset to default colour': 'Click the paint tin to set custom font colour'"
          matTooltipPosition="above"
          (click)="$event.stopPropagation();formatColumn('background_colour');">
    <i class="fas fa-fill"
       [matMenuTriggerFor]="backgroundColourMenu"
       (click)="$event.stopPropagation();showNestedMenu(nestedBackgroundColourMenuTrigger)"
       #nestedBackgroundColourMenuTrigger="matMenuTrigger"
       [style.color]="item?.background_colour ? item.background_colour : 'inherit'"></i>
  </button>
  <mat-menu #backgroundColourMenu="matMenu" class="column-menu dark aggregation-menu">
    <div class="color-picker-menu-container color-picker-container"
         [matTooltip]="'Please click to select a custom colour'">
      <span [(colorPicker)]="item.background_colour"
            [style.background]="item.background_colour"
            [cpPresetColors]="preset_colours"
            (colorPickerChange)="item.background_colour = $event;formatChanged();"
            (click)="formatColumn('background_colour');"
            [cpToggle]="true"
            [cpDialogDisplay]="'inline'"
      ></span>
    </div>
  </mat-menu>

  <button *ngIf="options.includes('aggregations')" [matMenuTriggerFor]="aggregationMenu"
          (click)="$event.stopPropagation();showNestedMenu(nestedMenuTrigger)" #nestedMenuTrigger="matMenuTrigger"
          [ngClass]="{'active': (item?.aggregation_type && item.aggregation_type !== 'none')}"
          class="formatting-button" mat-button matTooltip="Select aggregation type" matTooltipPosition="above">
    <i class="fas fa-calculator"></i>
  </button>
  <mat-menu #aggregationMenu="matMenu" class="column-menu dark aggregation-menu">
    <button (click)="$event.stopPropagation();item.aggregation_type = 'total';"
            [ngClass]="{'active': (item?.aggregation_type==='total' || !item?.aggregation_type)}">
      Total
    </button>
    <button (click)="$event.stopPropagation();item.aggregation_type = 'average'"
            [ngClass]="{'active': item?.aggregation_type==='average'}">Average
    </button>
    <button (click)="$event.stopPropagation();item.aggregation_type = 'count'"
            [ngClass]="{'active': item?.aggregation_type==='count'}">Count
    </button>
    <button (click)="$event.stopPropagation();item.aggregation_type = 'none'">Unset</button>
  </mat-menu>

  <button *ngIf="options.includes('decimals')" [matMenuTriggerFor]="decimalMenu"
          (click)="$event.stopPropagation();showNestedMenu(nestedMenuTrigger)" #nestedMenuTrigger="matMenuTrigger"
          [ngClass]="{'active': (item?.decimals || item?.decimals === 0)}"
          class="formatting-button" mat-button matTooltip="Select decimal places" matTooltipPosition="above">
    <i>0.00</i>
  </button>
  <mat-menu #decimalMenu="matMenu" class="column-menu dark aggregation-menu">
    <div style="display:flex;justify-content: flex-end;">
      <div style="color:white;width:24px;font-size:16px;padding-top: 9px;">
        {{!item.decimals && item['decimals'] !== 0 ? '' : item['decimals']}}</div>
      <button (click)="$event.stopPropagation();setDecimals(item, -1)"
              style="width:40px;height:40px;padding-left:12px;"><i class="fa fa-minus"></i>
      </button>
      <button (click)="$event.stopPropagation();setDecimals(item, 1)"
              style="width:40px;height:40px;padding-left:12px;"><i class="fa fa-plus"></i>
      </button>
    </div>
    <button (click)="$event.stopPropagation();item.decimals = null">Unset</button>
  </mat-menu>
</div>

<div class="row center" *ngIf="item && rows[2]">
  <button *ngIf="options.includes('abbreviate')" (click)="$event.stopPropagation();formatColumn('abbreviate')"
          [ngClass]="{'active': item.abbreviate}"
          class="formatting-button" mat-button matTooltip="Abbreviate" matTooltipPosition="above">
    Abbr.
  </button>
  <button *ngIf="options.includes('size')" (click)="$event.stopPropagation();formatColumn('minus')"
          [ngClass]="{'active': item.size && item?.size < 100}"
          class="formatting-button" mat-button matTooltip="Decrease font size" matTooltipPosition="above">
    <i class="fa fa-minus"></i>
  </button>
  <div class="row center" style="color:white;height:100%;" *ngIf="options.includes('size')">{{(item.size || '100') }}%
  </div>
  <button *ngIf="options.includes('size')" (click)="$event.stopPropagation();formatColumn('plus')"
          [ngClass]="{'active': item.size > 100}"
          class="formatting-button" mat-button matTooltip="Increase font size" matTooltipPosition="above">
    <i class="fa fa-plus"></i>
  </button>
  <button *ngIf="options.includes('align')" (click)="$event.stopPropagation();setAlignment('left')"
          [ngClass]="{'active': item.align === 'left'}"
          class="formatting-button" mat-button matTooltip="Align text left" matTooltipPosition="above">
    <i class="fas fa-align-left"></i>
  </button>
  <button *ngIf="options.includes('align')" (click)="$event.stopPropagation();setAlignment('center')"
          [ngClass]="{'active': item.align === 'center'}"
          class="formatting-button" mat-button matTooltip="Align text centre" matTooltipPosition="above">
    <i class="fas fa-align-center"></i>
  </button>
  <button *ngIf="options.includes('align')" (click)="$event.stopPropagation();setAlignment('right')"
          [ngClass]="{'active': item.align === 'right'}"
          class="formatting-button" mat-button matTooltip="Align text right" matTooltipPosition="above">
    <i class="fas fa-align-right"></i>
  </button>
</div>
