import {Injectable, OnDestroy} from '@angular/core';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {switchMap, takeUntil, map, concatMap, first} from "rxjs/operators";
import {ListResponse, SingleResponse} from "../../services/api/response-types";
import {ConstantPropertyEventType} from "../../_models/constant-property-event-type";
import {Observable, of, Subject} from "rxjs";
import {ConstantProperty} from "../../_models/constant-property";
import {Event as WireEvent} from "../../_models/event";
import {ApiService} from '../../services/api/api.service';
import {Series} from '../../_models/series';
import {EventDataService} from "../../data/event-data.service";
import {EventConstantCollationSeriesExport} from "../../_models/event-constant-collation-series-export";
import {CustomEventsDataService} from "../../tables/custom-events-table/custom-events-data.service";
import {CustomEventsService} from '../../services/custom-events.service';
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export interface EventConstantCollationSeriesData {
    event_constant_collation_series_export?: EventConstantCollationSeriesExport,
    time_constant_property_id?: string,
    constant_property_event_type?: ConstantPropertyEventType,
    constant_property?: ConstantProperty
}

@Injectable({
    providedIn: 'root'
})
export class ConstantPropertyEventsService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    collation_series_data: { [key: string]: EventConstantCollationSeriesData } = {};

    private $collationDataSubject: Subject<{ events: ListResponse<WireEvent>, constant_property: ConstantProperty }> = new Subject<any>();
    $collationData = this.$collationDataSubject.asObservable();

    constructor(private api: ApiService,
                private dateInst: DateTimeInstanceService,
                private eventDataService: EventDataService,
                private customEventsDataService: CustomEventsDataService,
                private customEventsService: CustomEventsService) {
    }

    getEventData(series: Series, page_size = 5, page = 1) {
        let $obs: Observable<ListResponse<WireEvent>>;
        if (this.collation_series_data[series.id]) {
            $obs = this.getEventObs(this.collation_series_data[series.id].constant_property_event_type,
                this.collation_series_data[series.id].time_constant_property_id, page_size, page)
        } else {
            $obs = this.getCollationSeriesData(series).pipe(
                concatMap(collation_series_data => {
                    return this.getEventObs(collation_series_data[series.id].constant_property_event_type,
                        this.collation_series_data[series.id].time_constant_property_id, page_size, page);
                })
            )
        }
        $obs.pipe(first(), takeUntil(this.onDestroy))
            .subscribe((event_results) => {
                this.$collationDataSubject.next({
                        events: event_results,
                        constant_property: this.collation_series_data[series.id].constant_property
                    }
                )
            })
    }

    getCollationSeriesData(series: Series): Observable<{ [key: string]: EventConstantCollationSeriesData }> {
        this.collation_series_data[series.id] = {};
        const ec_cs_id = series.relationships.event_constant_collation_series_export.data?.id;

        let cp_et_id;
        const $ec_cs = this.api.event_constant_collation_series_export.getById(ec_cs_id).pipe(
            switchMap(result => {
                this.collation_series_data[series.id].event_constant_collation_series_export = result.data;
                this.collation_series_data[series.id].time_constant_property_id = result.data.relationships.time_constant_property.data?.id;
                cp_et_id = result.data.relationships.constant_property_event_type.data.id;

                return this.api.constant_property_event_type.getById(cp_et_id)
            }),

            switchMap((result: SingleResponse<ConstantPropertyEventType>) => {
                if (!result) return of(null);
                this.collation_series_data[series.id].constant_property_event_type = result.data;
                const cp_id = result.data.relationships.constant_property.data.id;

                return this.api.constant_property_light.getById(cp_id).pipe(
                    map((cp: SingleResponse<ConstantProperty>) => {
                        this.collation_series_data[series.id].constant_property = cp.data;
                        return this.collation_series_data;
                    }));

            }),
            first(),
            takeUntil(this.onDestroy)
        )
        return $ec_cs;
    }

    getEventObs(constant_property_event_type: ConstantPropertyEventType, time_property_id, page_size: number, page: number): Observable<any> {
        const constant_property_id = constant_property_event_type.relationships.constant_property.data.id;
        const event_type_id = constant_property_event_type.relationships.event_type.data.id;
        const query_options = this.eventDataService.getEventConstantCollationQuery(event_type_id, constant_property_id,
            time_property_id, page_size, page, this.dateInst.dtp);
        const $events = this.api.event.searchMany(query_options).pipe(concatMap(response => {
            return this.customEventsService.getMapConstantsToEvents(response, [constant_property_id]);
        }))
        return $events;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
