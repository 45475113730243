import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {SeriesDetailTableService} from "./series-detail-table.service";
import {Series} from '../../_models/series';
import {takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {MatColumnDef, MatTable, MatTableDataSource} from "@angular/material/table";
import {FormDialogService} from "../../services/form-dialog.service";
import {ColumnFormatsDict, TableUtilsService} from "../table-utils.service";

@Component({
    selector: 'series-detail-table',
    templateUrl: './series-detail-table.component.html',
    styleUrls: ['./series-detail-table.component.less'],
    providers: [SeriesDetailTableService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesDetailTableComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private _series_ids: string[];
    private _format_dict: ColumnFormatsDict;

    @Input() set series_ids(series_ids: string[]) {
        this._series_ids = series_ids;
        if (series_ids?.length > 0) {
            this.cps.fetchSeries(series_ids);
        }
    };

    @Input() table_extract = true;
    @Input() config: any;

    @Input() set format_dict(format_dict: ColumnFormatsDict) {
        this._format_dict = format_dict;
        if (format_dict) {
            this.title_columns.forEach(t => this.format_dict[t] = this.format_dict[t.charAt(0).toUpperCase() + t.slice(1)])
        }
    }

    get format_dict(): ColumnFormatsDict {
        return this._format_dict;
    }

    @Output() seriesDetails = new EventEmitter();

    dataSource: MatTableDataSource<Series>;
    columns: string[] = this.cps.columns;
    column_name_map: { [key: string]: string } = this.cps.column_name_map;
    title_columns = this.cps.title_columns;
    series_details: { [key: string]: Series } = {};
    data_ready = false;
    private sort;

    @ViewChild('detailsSort') set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    @ViewChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

    constructor(private cps: SeriesDetailTableService,
                public tableUtils: TableUtilsService,
                private formDialogService: FormDialogService,
                @Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        if (this.table_extract === false) {
            this.columns = this.title_columns.filter(col => {
                return this.config.columns.map(c => c.toLowerCase()).includes(col)
            }).concat(this.columns);
        }
        this.cps.dataReady.pipe(takeUntil(this.onDestroy)).subscribe((data: Series[]) => {
            data.forEach(s => this.series_details[s.id] = s);
            this.dataSource = new MatTableDataSource(data);
            this.data_ready = true;
            this.seriesDetails.emit(data);

            /**This is needed to bind the columns defined here to the table in the parent component**/
            if (this.table) {
                this.cdRef.detectChanges();
                this.columnDefs.forEach(def => {
                    this.table.addColumnDef(def);
                })
            }
        })

    }

    openChartDialog(series_name): void {
        this.formDialogService.openChartDialog(series_name);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
