import {Component, Input} from '@angular/core';
import {tap, take, first, takeUntil} from "rxjs/operators";
import {ApiService} from '../../services/api/api.service';
import {ComponentType} from '../../_models/component-type';
import * as utils from "../../lib/utils";
import {difference as _difference} from "lodash-es";
import {BaseComponent} from "../../shared/base.component";
import {ConfigStub} from "../../_typing/config-stub";
import {of, ReplaySubject, Subject, Subscription} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {getManyRelationWithIdFilter} from "../../services/api/filter_utils";
import {ConstantProperty} from "../../_models/constant-property";
import {ConfigColumn} from "../../_typing/config/config-column";
import {CustomEventsFormService} from "../custom-events-form/custom-events-form.service";
import {ModelID} from '../../_typing/generic-types';
import {ComponentFormConfig} from "../../_typing/config/component-form-config";
import {Observable} from 'rxjs';
import {SingleResponse} from "../../services/api/response-types";
import {BeforeSaveAction} from "../../_typing/utils/before-save";
import {ComponentNameFormulaBuilderService} from "../../services/component-name-formula-builder.service";
import {EventColumnSelectorEmitter} from "../../components/event-column-selector/event-column-selector.component";

@Component({
    selector: 'component-form-form',
    templateUrl: './component-form-form.component.html',
    styleUrls: ['./component-form-form.component.less'],
    standalone: false
})
export class ComponentFormFormComponent extends BaseComponent implements BeforeSaveAction {
    @Input() config: ComponentFormConfig;

    component_types: ComponentType[];
    selected_component_type: ComponentType;

    avail_cols: ConfigColumn[];
    requiredCols: ConfigColumn[] = [];
    disabledCols: ConfigColumn[] = [];
    cpDict: { [id: string]: ConstantProperty } = {};

    private time_cols: ConfigColumn[] = [{
        name: 'End Time',
        id: 'end_time',
        title: 'End Time',
        type: 'attribute'
    },
        {
            name: 'Start Time',
            id: 'start_time',
            title: 'Start Time',
            type: 'attribute'
        }]
    compareById = utils.compareById;

    constructor(
        private api: ApiService,
        private customEventsFormService: CustomEventsFormService,
        private formulaBuilder: ComponentNameFormulaBuilderService
    ) {
        super();
    }

    ngOnInit() {
        const ctrl = this;
        if (!this.config.selected_cols) {
            this.config.selected_cols = [];
        }
        if (this.config.selected_cols.findIndex(c => c.id?.toLowerCase() === 'name') === -1) {
            this.config.selected_cols.unshift({
                name: 'Name',
                id: 'name',
                title: 'Name',
                type: 'attribute'
            })
        }
        let $obs: Observable<SingleResponse<ComponentType> | null> = of(null);
        if (this.config.selected_component_type?.id) {
            this.api.component_type.getById(ctrl.config.selected_component_type.id)
                .pipe(take(1), tap(result => {
                    this.selected_component_type = result.data;
                    this.componentTypeChanged(this.selected_component_type);
                })).subscribe();
        }

    }

    componentTypeChanged(component_type: ComponentType) {
        this.selected_component_type = component_type;
        this.config.selected_component_type = new ConfigStub(component_type);
        this.avail_cols = null;
        this.getColumns(this.selected_component_type);
    }

    private getColumns(comp_type: ConfigStub<ComponentType>) {
        const ctrl = this;

        const query = new SearchQueryOptions();
        query.filters = [getManyRelationWithIdFilter('component_types', comp_type.id)];
        ctrl.api.constant_property.searchMany(query)
            .pipe(first(), takeUntil(this.onDestroy)).subscribe(response => {
            const type_cps = response.data;
            //Remove any columns not still associated with the component_type
            ctrl.config.selected_cols = ctrl.config.selected_cols.filter(col => {
                return type_cps.map(c => c.id).includes(col.id) || ['name', 'start_time', 'end_time'].includes(col.id);
            });

            ctrl.cpDict = {};
            type_cps.forEach(cp => ctrl.cpDict[cp.id] = cp);

            this.requiredCols = this.config.selected_cols.filter(c => c.required);
            this.disabledCols = this.config.selected_cols.filter(c => c.disabled);
            const all_cols: (ConstantProperty | ConfigColumn)[] = [...type_cps, ...this.time_cols];
            ctrl.avail_cols = _difference(all_cols.map(i => i.id), ctrl.config.selected_cols.map(i => i.id))
                .map(item => {
                    // defaulting column name to the CP name
                    return ctrl.cpDict[item] ? {
                        name: ctrl.cpDict[item].attributes.name,
                        id: item,
                        title: ctrl.cpDict[item].attributes.name,
                        type: 'constant_property'
                    } : this.time_cols.find(c => c.id === item);
                });
            utils.sortObjectsByFlatProperty(ctrl.avail_cols, 'name');
        });
    }

    columnsChanged($event: Partial<EventColumnSelectorEmitter>): void {
        this.config.selected_cols = $event.selected_columns;
        this.avail_cols = $event.available_columns;
        if ($event.new_column_id && this.config.selected_cols?.map(c => c.id).includes($event.new_column_id)) {
            this.updateRequiredOptionsAndDefault($event.new_column_id);
        }
    }

    private updateRequiredOptionsAndDefault(new_column_id: ModelID) {
        const cpIds = this.config.selected_name_formula?.formula?.filter(p => p.type === 'constant_property').map(p => p.content);
        if (this.config.selected_name_formula?.formula && cpIds?.includes(new_column_id)) {
            this.config.selected_cols.find(c => c.id === new_column_id).required = true;
        }
        this.requiredCols = this.config.selected_cols.filter(c => c.required);
    }

    updateRequired(event:ConfigColumn[]) {
        this.requiredCols = event;
        this.config.selected_cols = this.customEventsFormService.updateRequired(event, this.config.selected_cols);
    }

    updateDisabled(event:ConfigColumn[]) {
        this.disabledCols = event;
        this.config.selected_cols = this.customEventsFormService.updateDisabled(event, this.config.selected_cols);
    }

    columnNameFunction(column) {
        if (!column) return column;
        return column.title || column.column_name || this.cpDict?.[column.id]?.attributes.name
    }

    beforeSave(): boolean {
        const colCpIds = this.config.selected_cols.filter(col => col.type === 'constant_property')?.map(c => c.id);
        const requiredCpIds = this.requiredCols.map(c => c.id);
        return this.formulaBuilder.checkComponentNameFields(this.config, colCpIds, requiredCpIds, this.cpDict);
    }
}
