import {AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class ConnectorAttributes extends AuditMixinAttributes {
    name?: string;
    input_stream_name?: string;
    output_stream_name?: string;
    json?: any;
    percent?: number;
}

export class ConnectorRelationships extends AuditMixinRelationships {
    //parent: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    process: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    input_stream: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    output_stream: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class Connector {
    attributes: ConnectorAttributes;
    relationships: ConnectorRelationships;
    id: string;
    type: 'connector';

    constructor() {
        this.type = 'connector';
        this.attributes = new ConnectorAttributes();
        this.relationships = new ConnectorRelationships();
    }
}
