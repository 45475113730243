import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {ConstantProperty} from '../../_models/constant-property';
import {countLineBreaks} from '../../lib/utils';
import {ColumnFormats} from '../../tables/table-utils.service';
import {ClearConstantService} from "./clear-constant.service";
import {takeUntil} from "rxjs/operators";
import {BaseComponent} from "../../shared/base.component";
import {ConstantValue} from "../../_models/api/generic-constant";
import {UserService} from '../../services/user.service';
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";

@Component({
    selector: 'constant-field',
    templateUrl: './constant-field.component.html',
    styleUrls: ['./constant-field.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConstantFieldComponent extends BaseComponent implements OnChanges {
    private _constant_value: ConstantValue;
    get constant_value(): ConstantValue {
        return this._constant_value;
    }

    @Input() set constant_value(value: ConstantValue) {
        if (typeof value === 'string') {
            this._constant_value = value.trim();
        } else {
            this._constant_value = value;
        }
        this._constant_value = value;
        this.changeDetectorRef.markForCheck();
    }

    @Input() constant_property: ConstantProperty;
    @Input() disabled: boolean;
    @Input() locked: boolean;
    @Input() is_focussed: boolean;
    @Input() auto_class: string;
    @Input() tooltip: string;
    @Input() formats: ColumnFormats;
    /**This (conditional_formats) is necessary because text-decoration can not be inherited from non text elements (e.g. div/td)**/
    @Input() conditional_formats: any;
    @Input() show_sig: boolean = true;
    @Input() placeholder: string = '';
    @Input() allow_fit: boolean;
    @Input() defaultValue: ConstantValue = null;

    @Output() constant_changed: EventEmitter<any> = new EventEmitter();
    @Output() change_complete: EventEmitter<any> = new EventEmitter();
    @Output() lock_clicked: EventEmitter<void> = new EventEmitter();

    public data_type = 'string';
    public mouseover = false;
    public textarea_rows = 1;
    public elementForConstant = 'input';
    public show_full = false;
    public show_ellipsis = false;
    public can_unlock = false;
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private clearConstantService: ClearConstantService,
                private userService: UserService) {
        super();
    }

    ngOnInit(): void {
        this.clearConstantService.clearRequested.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.constant_value = this.defaultValue;
            this.show_ellipsis = false;
            this.changeDetectorRef.markForCheck();
        });
        this.can_unlock = this.userService.canUnlockData();
        this.constant_value = this.constant_value || (this.constant_value === 0 ? 0 : this.defaultValue);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.data_type = this.constant_property?.attributes.data_type;
        if (this._constant_value && this.data_type === 'string') {
            this.show_ellipsis = countLineBreaks(this._constant_value) > 1;
        }
        if (this.locked) {
            this.disabled = true;
        }
        this.getElementForConstant();
        this.changeDetectorRef.markForCheck();
    }

    showFull(event): void {
        this.mouseover = true;
        if (this.data_type !== 'string') {
            return;
        }
        this.show_full = true;
        this.show_ellipsis = false;
    }

    hideFull(value_changed: boolean): void {
        this.mouseover = false;
        this.show_full = false;
        this.show_ellipsis = countLineBreaks(this._constant_value) > 1;
        this.getElementForConstant();
        if (value_changed === true) {
            this.changeComplete();
        }
    }

    constantChanged(event): void {
        this.constant_changed.emit(event);
        this.changeDetectorRef.markForCheck();
    }

    changeComplete(event?): void {
        this.change_complete.emit(this.constant_value);
        this.changeDetectorRef.markForCheck();
    }

    constantChangedComplete(event): void {
        this.constant_value = event;
        /*  Input event emitted when change is complete does not contain the value so using a separate function for
            datetime-picker and mat-select which emits the completed changed value in this case
        */
        this.constantChanged(event);
        this.changeComplete(event);
    }

    getElementForConstant(): void {
        /**Sets which element to display based on data_type, is_drop_down_list and disabled**/
        const disabled_float = this.disabled && this.data_type === 'float';
        if (this.constant_property.attributes.is_drop_down_list === true && !disabled_float) {
            this.elementForConstant = 'mat-select';
            return;
        }
        if (disabled_float) {
            this.elementForConstant = 'div';
            return;
        }
        if ((this.data_type === 'string' && !this.show_ellipsis) ||
            (this.data_type === 'float' && !disabled_float)) {
            this.elementForConstant = 'input';
            return;
        }
        if (this.data_type === 'string' && this.show_ellipsis) {
            this.elementForConstant = 'textarea';
            return;
        }
        if (this.data_type === 'datetime') {
            this.elementForConstant = 'date-time-picker';
            return;
        }
    }

    lockClicked(): void {
        if (!this.can_unlock) return;
        this.lock_clicked.next();
    }
}
