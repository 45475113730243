<div class="page traveler">
  <div class="row justify-center form-dialog">
    <div class="select-search" style="max-width:500px; width:500px;">
      <select-search-component
          (selectionChange)="createPropertyFilters($event.value)"
          [value]="config.selected_component_type"
          [api_model]="'component_type'"
          [classes]="'fill'"
          label="Select Component Type">
      </select-search-component>
    </div>

    <div class="select-search" style="max-width:500px; width:500px;">
      <select-many-constant-properties
          (selectionChange)="propertySelectionChanged($event)"
          [value]="getPropertyColumns(config.selected_cols.component_type)"
          [custom_filters]="properties_filter"
          [classes]="'fill'"
          label="Select columns (optional)">
      </select-many-constant-properties>
    </div>
    <!--      <div class="row justify-center" style="height:60px;">-->
    <!--        <select-search-component-->
    <!--            [api_model]="'constant_property_light'"-->
    <!--            (selectionChange)="config.start_prop = $event.value"-->
    <!--            [custom_filters]="properties_filter"-->
    <!--            [label]="'Select a start date property'">-->
    <!--        </select-search-component>-->
    <!--          <mat-form-field style="width:220px;margin-top:3px;">-->
    <!--            <label style="font-weight:600;">Select an end date property</label>-->
    <!--            <mat-select [(ngModel)]="config.end_prop" class="select-search"-->
      <!--                        style="top: 6px;">-->
      <!--              <mat-option [value]=""><i>None</i></mat-option>-->
      <!--              <mat-option *ngFor="let prop of filtered_properties" [value]="prop">{{prop.attributes.name}}</mat-option>-->
      <!--            </mat-select>-->
      <!--          </mat-form-field>-->

      <!--          <mat-form-field style="width:220px;margin-top:3px;">-->
      <!--            <label matTooltip="How would you like to filter your results?" style="font-weight:600;">Filter type</label>-->
      <!--            <mat-select [(ngModel)]="config.prop_date_filter_option" class="select-search"-->
      <!--                        style="top: 6px;">-->
      <!--              <mat-option value="and">Both dates fall within dtp range</mat-option>-->
      <!--              <mat-option value="or">Either start or end date falls within dtp range</mat-option>-->
      <!--            </mat-select>-->
      <!--          </mat-form-field>-->
      <!--      </div>-->

    <button (click)="search()" class="bordered-button" mat-button style="height: 36px;align-self: center;">
      <i class="fa fa-search" style="margin:0 0 2px 8px;"></i></button>
  </div>

  <page-tile *ngIf="tile"
             [dtp]="dtp"
             [tile]="tile"
             class="page-tile off-dashboard">
  </page-tile>
  <!--  <component-events-table-->
  <!--                          [config]="config"></component-events-table>-->
</div>
