import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './sub-modules/app-routing.module';
import {AppComponent} from './app.component';
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {SeriesStringParser} from "./services/series_string_parser.service";
import {ApiService} from "./services/api/api.service";
import {AppScope} from "./services/app_scope.service";
import {HttpErrorHandler} from "./services/http-error-handler.service";
import {UserData} from "./services/user_data.service";
import {PlantDataService} from "./services/plant-data/plant_data.service";
import {DateTimePeriodService} from "./services/date-time-period.service";
import {LoginComponent} from "./user_control/login.component";
import {SideNavigation} from "./menu-bars/side-nav.component";
import {TreeNodeComponent} from "./menu-bars/tree_node.componen";
import {AuditPivotComponent} from "./reports/audit-pivot/audit-pivot.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ScrollDirective} from "./shared/scroll.directive";
import {LdsRollerDirective} from "./directives/lds-roller.directive";
import {ReactiveFormsModule} from '@angular/forms';
import {GridsterModule} from 'angular-gridster2';
import {QuillModule} from 'ngx-quill';
import {HandsontableGenerator} from "./services/handsontable-generator.service";
import {CalculationCheckTableComponent} from "./tables/calculation-check-table/calculation-check-table.component";
import {
    EngineeringUnitViewComponent
} from "./handson-sheets/engineering-unit-sheet-view/engineering-unit-sheet-view.component";
import {HeaderDataService} from "./services/header_data.service";
import {ChartService} from "./services/chart.service";
import {ChartEventsService} from "./services/chart-events.service";
import {UserListComponent} from "./user_control/user-list.component";
import {ManageUsersComponent} from "./user_control/manage-users/manage-users.component";
import {RegisterUserView} from "./user_control/register.component";
import {SelectManySearchComponent} from "./components/select-search/select-many-search.component";
import {SelectSearchComponent} from "./components/select-search/select-search.component";
import {SelectSearchApiComponent} from "./components/select-search-api/single/select-search-api.component";
import {SelectManySearchApiComponent} from "./components/select-search-api/many/select-many-search-api.component";
import {SelectSearchComponentComponent} from "./components/select-search-api/single/select-search-component.component";
import {SelectManySearchBaseComponent} from "./components/select-search-api/many/select-many-search-base.component";
import {UserFormComponent} from "./forms/user-form/user-form.component";
import {PageTileFormComponent} from "./forms/page-tile-form/page-tile-form";
import {ChartDialog} from "./charts/chart-dialog/chart-dialog.component";
import {PageViewFormComponent} from "./forms/page-view-form/page-view-form.component";
import {CollectorEventsTableComponent} from "./tables/collector-events-table/collector-events-table.component";
import {ValueDriverTreeTableComponent} from "./tables/value-driver-tree-table/value-driver-tree-table.component";
import {ValueDriverTreeViewComponent} from './reports/value-driver-tree-view/value-driver-tree-view.component';
import {PivotViewComponent} from "./handson-sheets/pivot-view/pivot-view.component";
import {DateTimePeriodComponent} from "./date-time/date-time-period.component";
import {HeaderComponent} from "./menu-bars/header.component";
import {HistoryTableComponent} from "./handson-sheets/history-table/history-table.component";
import {QuickChartsComponent} from "./charts/quick-charts.component";
import {StreamSheetViewComponent} from "./handson-sheets/stream-sheet-view/stream-sheet-view.component";
import {
    StreamSheetFocusViewComponent
} from "./handson-sheets/stream-sheet-focus-view/stream-sheet-focus-view.component";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {EventService} from "./services/event.service";
import {EventTypeService} from "./services/event_type.service";
import {FormDialogService} from "./services/form-dialog.service";
import {GenericChartComponent} from "./charts/generic-chart.component";
import {
    CollectorMapperSheetViewComponent
} from "./handson-sheets/collector-mapper-sheet-view/collector-mapper-sheet-view.component";
import {
    CustomEquipmentViewComponent
} from "./handson-sheets/custom-equipment-sheet-view/custom-equipment-sheet-view.component";
import {EquipmentSheetViewComponent} from "./handson-sheets/equipment-sheet-view/equipment-sheet-view.component";
import {
    EquipmentSheetFocusViewComponent
} from "./handson-sheets/equipment-sheet-focus-view/equipment-sheet-focus-view.component";
import {ShiftSheetViewComponent} from "./handson-sheets/shift-sheet-view/shift-sheet-view.component";
import {OreBodySheetViewComponent} from "./handson-sheets/ore-body-sheet-view/ore-body-sheet-view.component";
import {ScheduleSheetViewComponent} from "./handson-sheets/schedule-sheet-view/schedule-sheet-view.component";
import {
    ScheduleSheetFocusViewComponent
} from "./handson-sheets/schedule-sheet-focus-view/schedule-sheet-focus-view.component";
import {SpcChartComponent} from "./charts/spc-chart.component";
import {DefaultChartComponent} from "./charts/default-chart.component";
import {ComparisonChartFormComponent} from "./forms/comparison_chart_form.component";
import {DownloadFormDialogComponent} from "./forms/file-download-form/file_download_form.component";
import {PageTileComponent} from "./page-view/page-tile/page-tile.component";
import {
    SeriesComponentSheetViewComponent
} from "./handson-sheets/series-component-sheet-view/series-component-sheet-view.component";
import {
    SeriesComponentSheetFocusViewComponent
} from "./handson-sheets/series-component-sheet-focus-view/series-component-sheet-focus-view.component";
import {SeriesTableComponent} from "./tables/series-table/series-table.component";
import {SeriesTableFocusViewComponent} from "./tables/series-table-focus-view/series-table-focus-view.component";
import {DataExceptionsTableComponent} from "./tables/data-exceptions-table/data-exceptions-table.component";
import {
    DataExceptionsTableViewComponent
} from "./tables/data-exceptions-table-view/data-exceptions-table-view.component";
import {SeriesDataService} from "./services/series_data.service";
import {CachingService} from "./services/caching.service";
import {SeriesFormComponent} from "./forms/series-form/series-form.component";
import {UploadFormDialogComponent} from "./forms/upload-form/upload-form.component";
import {
    CustomProcessViewComponent
} from "./handson-sheets/custom-process-sheet-view/custom-process-sheet-view.component";
import {ProcessSheetViewComponent} from "./handson-sheets/process-sheet-view/process-sheet-view.component";
import {
    ProcessSheetFocusViewComponent
} from "./handson-sheets/process-sheet-focus-view/process-sheet-focus-view.component";
import {EstimateSheetViewComponent} from "./handson-sheets/estimate-sheet-view/estimate-sheet-view.component";
import {LogSheetFocusViewComponent} from "./data-sheets/log-sheet/log-sheet-focus-view.component";
import {LogSheetComponent} from "./data-sheets/log-sheet/log-sheet.component";
import {EventSheetViewComponent} from "./handson-sheets/event-sheet-view/event-sheet-view.component";
import {ResourceSheetViewComponent} from "./handson-sheets/resource-sheet-view/resource-sheet-view.component";
import {
    ProcessAccessSheetViewComponent
} from "./handson-sheets/process-access-sheet-view/process-access-sheet-view.component";
import {
    SeriesSheetFocusViewComponent
} from "./handson-sheets/series-sheet-focus-view/series-sheet-focus-view.component";
import {SeriesSheetViewComponent} from "./handson-sheets/series-sheet-view/series-sheet-view.component";
import {FlowChartComponent} from "./lib/flowchart/flowchart.component";
import {SeriesAuditHistoryDirective} from "./directives/series-audit-history.directive";
import {SeriesAuditHistoryService} from "./components/series-audit-history/series-audit-history.service";
import {ColumnResizeDirective} from "./directives/column-resize.directive";
import {FlowchartFocusViewComponent} from "./flowchart/flowchart-focus-view/flowchart-focus-view.component";
import {WaterBalanceComponent} from "./reports/water-balance-view/water-balance-view.component";
import {ColourPaletteComponent} from "./components/colour_palette";
import {FlowchartFormComponent} from "./flowchart/flowchart-form/flowchart-form.component";
import {SeriesComponentTableComponent} from "./components/series-component-table.component";
import {FlowchartSeriesComponentComponent} from "./lib/flowchart/flowchart_series_component.component";
import {InlineStyleMenuComponent} from "./components/inline_style_menu.component";
import {EstimateDataSheetComponent} from "./data-sheets/estimate-data-sheet.component";
import {EstimateDataSheetViewComponent} from "./data-sheets/estimate-data-sheet-view.component";
import {EstimateDataSheetFocusViewComponent} from "./data-sheets/estimate-data-sheet-focus-view.component";
import {EstimateSheetFocusViewComponent} from "./data-sheets/estimate-sheet-focus-view.component";
import {
    AbbreviateNumber,
    ArrayMapPipe,
    ClientTranslatorPipe,
    ComponentPrintoutsPipe,
    DateTimePeriodPipe,
    FilterByNamePipe,
    FilterByValuePipe,
    FormatNumberPipe,
    MatchTitleToCount,
    NameAndDescriptionPipe,
    PercentagePipe,
    SafeURIPipe,
    SignificantNumberPipe,
    SnakeCasePipe,
    SnakeCaseToTitleCasePipe,
    SortObjectByArrayPipe,
    ThousandsSeparatePipe,
    TitlecaseForWirePipe,
} from "./shared/pipes";
import {EditHistoryComponent} from "./forms/edit-history.component";
import {EstimateFormComponent} from "./forms/estimate-form.component";
import {InputDataSheetFocusViewComponent} from "./data-sheets/input-data-sheet-focus-view.component";
import {InputDataSheetComponent} from "./data-sheets/input-data-sheet.component";
import {CommentDrawerComponent} from './components/comment-drawer.component';
import {EventPivotComponent} from "./events/event-pivot.component";
import {CustomChart} from "./forms/custom-chart-form/custom-chart-form.component";
import {LogSheetFormComponent} from './forms/log-sheet-form/log-sheet-form.component';
import {SeriesTableFormComponent} from './forms/series-table-form.component';
import {WeatherWidgetComponent} from './components/weather-widget/weather-widget.component';
import {CustomEventsFormComponent} from './forms/custom-events-form/custom-events-form.component';
import {MySecurityComponent} from "./user_control/my-security.component";
import {ResetPasswordComponent} from "./user_control/reset-password.component";
import {InputDataSheetFormComponent} from './forms/input-data-sheet-form/input-data-sheet-form.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {EventsComponent} from './events/events/events.component';
import {ConstantSeriesDataComponent} from "./components/constant_series_data.component";
import {ComponentConstantTableComponent} from "./components/component-constant-table.component";
import {DetailRowDirective} from './directives/detail-row.directive';
import {WaterBalanceDetailViewComponent} from './reports/water-balance-detail-view/water-balance-detail-view.component';
import {WaterBalanceChartComponent} from './charts/water-balance-chart/water-balance-chart.component';
import {ValueDriverTreeComponent} from './components/value-driver-tree/value-driver-tree.component';
import {ApplyCorrectionComponent} from './components/apply-correction/apply-correction.component';
import {PivotTileComponent} from './components/pivot-tile/pivot-tile.component';
import {PivotTileNgComponent} from './components/pivot-tile-ng/pivot-tile-ng.component';
import {PivotTileFormComponent} from './forms/pivot-tile-form/pivot-tile-form.component';
import {SparklineComponent} from './tables/sparkline/sparkline.component';
import {RecoveryGradeForecastComponent} from './forecasts/recovery-grade-forecast/recovery-grade-forecast.component';
import {PageListViewComponent} from './page-view/page-list-view/page-list-view.component';
import {PageListComponent} from './components/page-list/page-list.component';
import {AppPasswordDirective} from './directives/app-password.directive';
import {
    RecoveryGradeForecastFormComponent
} from './forecasts/recovery-grade-forecast/recovery-grade-forecast-form/recovery-grade-forecast-form.component';
import {JsonTableComponent} from './components/json-table/json-table.component';
import {AuditHistoryComponent} from './handson-sheets/audit-history/audit-history.component';
import {PresentationService} from "./services/presentation.service";
import {ParagraphComponent} from "./components/paragraph/paragraph.component";
import {CustomHtmlComponent} from "./components/custom-html/custom-html.component";
import {MenuTreeService} from "./services/menu-tree.service";
import {WelcomeComponent} from "./user_control/welcome.component";
import {ContextComponent} from './components/context/context.component';
import {ContextFormComponent} from './forms/context-form/context-form.component';
import {InputDataService} from "./services/input-data.service";
import {HelpPageComponent} from './components/help-page/help-page.component';
import {MentionDirective, ScrollableDirective} from './directives/mention.directive';
import {AtWhoComponent} from './components/at-who/at-who.component';
import {CustomChartFormDialogComponent} from './forms/custom-chart-form-dialog/custom-chart-form-dialog.component';
import {ContextFormDialogComponent} from './forms/context-form-dialog/context-form-dialog.component';
import {PrintFormatterComponent} from './components/print-formatter/print-formatter.component';
import {AccountPickerComponent} from './components/account-picker/account-picker.component';
import {ParagraphFormComponent} from './forms/paragraph-form/paragraph-form.component';
import {CustomHtmlFormComponent} from './forms/custom-html-form/custom-html-form.component';
import {ExceptionOnDemandComponent} from './debugging/exception-on-demand/exception-on-demand.component';
import {FlowchartImageFormComponent} from './flowchart/flowchart-image-form/flowchart-image-form.component';
import {AccountFilterComponent} from "./components/account-filter/account-filter.component";
import {
    CustomChartFormSeriesTableComponent
} from './forms/custom-chart-form/custom-chart-form-series-table/custom-chart-form-series-table.component';
import {CategoryChartComponent} from './charts/category-chart/category-chart.component';
import {ComparisonChartComponent} from './charts/comparison-chart/comparison-chart.component';
import {CategoryChartFormComponent} from './forms/category-chart-form/category-chart-form.component';
import {AngularSplitModule} from 'angular-split';
import {CommentsTableFormComponent} from './forms/comments-table-form/comments-table-form.component';
import {CommentsTableComponent} from './components/comments-table/comments-table.component';
import {
    UserFormProcessAccessComponent
} from './forms/user-form/user-form-process-access/user-form-process-access.component';
import {PageViewComponent} from './page-view/page-view/page-view.component';
import {ReportIssueFormDialogComponent} from './forms/report-issue-form-dialog/report-issue-form-dialog.component';
import {GroupListComponent} from "./user_control/manage-users/group-list/group-list.component";
import {ModelPivotFormComponent} from './forms/model-pivot-form/model-pivot-form.component';
import {ModelPivotTileComponent} from './components/model-pivot-tile/model-pivot-tile.component';
import {EventTypesTableComponent} from "./tables/event-types-table/event-types-table.component";
import {ComponentTypesTableComponent} from "./tables/component-types-table/component-types-table.component";
import {OreBodyTypesTableComponent} from "./tables/ore-body-types-table/ore-body-types-table.component";
import {EventTypeFormDialogComponent} from "./forms/event-type-form-dialog/event-type-form-dialog.component";
import {
    ComponentTypeFormDialogComponent
} from "./forms/component-type-form-dialog/component-type-form-dialog.component";
import {OreBodyFormDialogComponent} from "./forms/ore-body-form-dialog/ore-body-form-dialog.component";
import {OreBodyTypeFormDialogComponent} from "./forms/ore-body-type-form-dialog/ore-body-type-form-dialog.component";
import {OreBodyGroupFormComponent} from "./forms/ore-body-group-form/ore-body-group-form.component";
import {
    ConstantPropertyTableFormComponent
} from "./forms/constant-property-table-form/constant-property-table-form.component";
import {
    SourceDestinationTableFormComponent
} from "./forms/source-destination-table-form/source-destination-table-form.component";
import {OreBodyGroupViewComponent} from './lib/flowchart/ore-body-group-vew/ore-body-group-view.component';
import {UploadModelFormDialogComponent} from "./forms/upload-model-form/upload-model-form-dialog.component";
import {
    ExtractOreBodyOptionsComponent
} from './forms/upload-model-form/extract-ore-body-options/extract-ore-body-options.component';
import {OreBodyDataTableComponent} from './tables/ore-body-data-table/ore-body-data-table.component';
import {SpcChartFormComponent} from './forms/spc-chart-form/spc-chart-form.component';
import {OreBodySummaryTableComponent} from './tables/ore-body-summary-table/ore-body-summary-table.component';
import {UserInteractionDirective} from './directives/user-interaction.directive';
import {UserActivityChartComponent} from './forms/user-form/user-activity-chart/user-activity-chart.component';
import {AnalysisToolsComponent} from './reports/analysis-tools/analysis-tools.component';
import {OreBodyGroupNodeComponent} from "./lib/flowchart/ore-body-group-node/ore-body-group-node.component";
import {
    UploadModelFormTemplateComponent
} from './forms/upload-model-form/upload-model-form-template/upload-model-form-template.component';
import {FlowchartProcessFormComponent} from "./flowchart/flowchart-process-form/flowchart-process-form.component";
import {FlowchartStreamFormComponent} from "./flowchart/flowchart-stream-form/flowchart-stream-form.component";
import {FlowchartEquipmentFormComponent} from "./flowchart/flowchart-equipment-form/flowchart-equipment-form.component";
import {ChartProcessDetailComponent} from "./flowchart/chart-process-detail/chart-process-detail.component";
import {
    ExtractEventOptionsComponent
} from './forms/upload-model-form/extract-event-options/extract-event-options.component';
import {ForbiddenViewComponent} from './user_control/forbidden-view/forbidden-view.component';
import {SeriesAuditHistoryComponent} from './components/series-audit-history/series-audit-history.component';
import {CustomDialogComponent} from "./forms/custom-dialog/custom-dialog.component";
import {ComponentEventsTableComponent} from './tables/component-events-table/component-events-table.component';
import {
    ComponentEventsTableViewComponent
} from './tables/component-events-table-view/component-events-table-view.component';
import {ContextService} from "./services/context.service";
import {SeriesSummaryComponent} from './components/series-summary/series-summary.component';
import {
    SeriesSummaryMenuComponent
} from './components/series-summary/series-summary-menu/series-summary-menu.component';
import {
    ComponentEventsTableFormComponent
} from './forms/component-events-table-form/component-events-table-form.component';
import {CustomEventsTableComponent} from "./tables/custom-events-table/custom-events-table.component";
import {CustomEventsService} from "./services/custom-events.service";
import {EventsTableComponent} from './tables/component-events-table/events-table/events-table.component';
import {ComponentsTableComponent} from './tables/component-events-table/components-table/components-table.component';
import {FormulaBuilderComponent} from './components/formula-builder/formula-builder.component';
import {SeriesEditButtonComponent} from './components/series-edit-button/series-edit-button.component';
import {ComponentCreatorComponent} from './components/component-creator/component-creator.component';
import {ComponentCreatorFormComponent} from './forms/component-creator-form/component-creator-form.component';
import {ComponentFormFormComponent} from './forms/component-form-form/component-form-form.component';
import {PendingContextComponent} from './components/pending-context/pending-context.component';
import {PendingContextFormComponent} from './forms/pending-context-form/pending-context-form.component';
import {
    PendingContextValidationComponent
} from './forms/pending-context-form/pending-context-validation/pending-context-validation.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {PrintoutMapperComponent} from './components/printout-mapper/printout-mapper.component';
import {PrintoutMapperFormComponent} from "./forms/printout-mapper-form/printout-mapper-form.component";
import {ComponentTypeConditionsComponent} from "./forms/printout-mapper-form/component-type-conditions.component";

import {
    SingleComponentEventsTableViewComponent
} from './tables/single-component-events-table-view/single-component-events-table-view.component';
import {ComponentFormComponent} from './components/component-form/component-form.component';
import {OreBodyDataChartComponent} from "./charts/ore-body-data-chart/ore-body-data-chart.component";
import {TableColumnMenuComponent} from './forms/table-column-menu/table-column-menu.component';
import {TableUtilsService} from "./tables/table-utils.service";
import {PdfHeaderComponent} from './tables/component-events-table/pdf-header/pdf-header.component';
import {PdfFooterComponent} from './tables/component-events-table/pdf-footer/pdf-footer.component';
import {JulianDateComponent} from './components/julian-date/julian-date.component';
import {SocketIoModule} from "ngx-socket-io";
import {FullscreenOverlayContainer, OverlayContainer} from "@angular/cdk/overlay";
import {OfflineDialogComponent} from './components/offline-dialog/offline-dialog.component';

import {NgxAngularQueryBuilderModule} from "ngx-angular-query-builder";
import {QueryBuilderFormComponent} from "./forms/query-builder-form/query-builder-form.component";
import {QueryBuilderService} from "./services/query_builder.service";
import {AnalysisToolsViewComponent} from './reports/analysis-tools-view/analysis-tools-view.component';
import {ExportComponentDialogComponent} from "./forms/export-component-dialog/export-component-dialog.component";
import {
    MatSelectInfiniteScrollDirective
} from './components/select-search-api/mat-infinite/mat-select-infinite-scroll.directive';
import {DivInfiniteScrollDirective} from './directives/div-infinite-scroll.directive';
import {
    ConstantPropertyEventsComponent
} from './components/constant-property-events/constant-property-events.component';
import {DblClickCopyDirective} from './directives/dbl-click-copy.directive';
import {ValueDriverTreeFormComponent} from './forms/value-driver-tree-form/value-driver-tree-form.component';
import {
    EventModelUploadSelectComponentTypeComponent
} from './forms/upload-model-form/extract-event-options/event-model-upload-select-component-type/event-model-upload-select-component-type.component';
import {
    EventModelUploadSelectConstantPropertyComponent
} from './forms/upload-model-form/extract-event-options/event-model-upload-select-constant-property/event-model-upload-select-constant-property.component';
import {
    EventModelUploadSelectEventTypeComponent
} from './forms/upload-model-form/extract-event-options/event-model-upload-select-event-type/event-model-upload-select-event-type.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {
    EventModelUploadSelectUniquePropertyComponent
} from './forms/upload-model-form/extract-event-options/event-model-upload-select-unique-property/event-model-upload-select-unique-property.component';
import {
    GenerateTableParseResponseDialogComponent
} from './forms/upload-model-form/response-display/generate-table-parse-response-dialog/generate-table-parse-response-dialog.component';
import {
    ConstantPropertyTableFormService
} from "./forms/constant-property-table-form/constant-property-table-service.service";
import {ProcessMenuDirective} from './directives/process-menu.directive';
import {ProcessQuickMenuComponent} from './components/process-quick-menu/process-quick-menu.component';
import {ModelChangesService} from "./services/api/model-changes.service";
import {RelativeDatePeriodComponent} from './date-time/relative-date-period/relative-date-period.component';

import {
    ExtractComponentOptionsComponent
} from './forms/upload-model-form/extract-component-options/extract-component-options.component';
import {
    ComponentModelUploadSelectConstantPropertyComponent
} from './forms/upload-model-form/extract-component-options/component-model-upload-select-constant-property/component-model-upload-select-constant-property.component';
import {
    ComponentModelUploadSelectComponentTypeComponent
} from './forms/upload-model-form/extract-component-options/component-model-upload-select-component-type/component-model-upload-select-component-type.component';
import {
    ComponentModelUploadSelectComponentTypeColumnComponent
} from './forms/upload-model-form/extract-component-options/component-model-upload-select-component-type-column/component-model-upload-select-component-type-column.component';
import {BreadcrumbsComponent} from './menu-bars/breadcrumbs/breadcrumbs.component';
import {BreadcrumbsService} from "./menu-bars/breadcrumbs/breadcrumbs.service";
import {ConstantFieldComponent} from './components/constant-field/constant-field.component';
import {AlertsTableComponent} from './tables/alerts-table/alerts-table.component';
import {AlertsTableService} from "./tables/alerts-table/alerts-table.service";
import {CommentsTableService} from "./components/comments-table/comments-table.service";
import {FitContentDirective} from './directives/fit-content.directive';
import {NotificationsDirective} from "./directives/notifications.directive";
import {NotificationsComponent} from './notifications/notifications/notifications.component';
import {RelationshipApiMappingService} from "./data/relationship-api-mapping.service";
import {EventTypeFormDialogService} from "./forms/event-type-form-dialog/event-type-form-dialog.service";
import {
    ValidateComponentTemplatesComponent
} from './tables/validate-component-templates/validate-component-templates.component';
import {SelectManySeriesComponent} from "./components/select-search-api/many/wrappers/select-many-series.component";
import {
    SelectManyEventTypesComponent
} from "./components/select-search-api/many/wrappers/select-many-event-types.component";
import {
    SelectManyComponentTypesComponent
} from "./components/select-search-api/many/wrappers/select-many-component-types.component";
import {
    SelectManyConstantPropertiesComponent
} from "./components/select-search-api/many/wrappers/select-many-constant-properties.component";
import {ConstantPropertyDataService} from "./data/constant-property-data.service";
import {ComponentTypeDataService} from "./data/component-type-data.service";
import {GenericDataService} from "./data/generic-data.service";
import {ComponentDataService} from "./data/component-data.service";
import {CollationSeriesTableComponent} from './tables/collation-series-table/collation-series-table.component';
import {SeriesDataTableComponent} from './tables/series-data-table/series-data-table.component';
import {SeriesDataTableFormComponent} from './forms/series-data-table-form/series-data-table-form.component';
import {ComponentConstantsTableComponent} from './tables/component-constants-table/component-constants-table.component';
import {RangeSelectorComponent} from './date-time/range-selector/range-selector.component';
import {SeriesEstimateFormComponent} from './components/series-estimate-form/series-estimate-form.component';
import {WaterfallChartComponent} from './charts/waterfall-chart/waterfall-chart.component';
import {WaterfallChartFormComponent} from './forms/waterfall-chart-form/waterfall-chart-form.component';
import {PlotlyChartConfigTranslationService} from "./services/plotly-chart-config-translation.service";
import {PlotlyBarChartConfigTranslationService} from "./services/plotly-bar-chart-config-translation.service";
import {ChartConfigTranslationService} from "./services/chart-config-translation.service";
import {PlotlyViaWindowModule} from 'angular-plotly.js';
import {analytic_domain, analytic_sites} from './environments/analytics';
import {ConditionalFormattingService} from "./tables/conditional-formatting.service";
import {
    ConditionalFormattingFormComponent
} from './forms/conditional-formatting-form/conditional-formatting-form.component';
import {TimeZoneService} from "./services/time-zone.service";
import {TimezoneSelectorComponent} from './date-time/timezone-selector/timezone-selector.component';
import {UserPreferenceDataService} from "./data/user-preference-data.service";
import {TimezoneSelectorService} from "./services/timezone-selector.service";
import {SeriesDetailTableComponent} from "./tables/series-detail-table/series-detail-table.component";
import {
    SeriesSummaryColumnSelectorComponent
} from './components/series-summary-column-selector/series-summary-column-selector.component';
import {EventDataService} from "./data/event-data.service";
import {AuditMixinComponent} from './components/audit-mixin/audit-mixin.component';
import {
    AuditMixinIndicatorComponent
} from './components/audit-mixin/audit-mixin-indicator/audit-mixin-indicator.component';
import {CustomFormFieldComponent} from "./forms/custom-form-field/custom-form-field.component";
import {CustomChartComponent} from './charts/chart-dialog/custom-chart/custom-chart.component';
import {GroupDataService} from "./data/group-data.service";
import {
    UserGroupPageFormDialogComponent
} from "./forms/user-group-page-form-dialog/user-group-page-form-dialog.component";
import {
    RelationshipGroupSelectorComponent
} from './components/relationship-group-selector/relationship-group-selector.component';
import {
    RelationshipUserSelectorComponent
} from './components/relationship-user-selector/relationship-user-selector.component';
import {UserDataService} from "./data/user-data.service";
import {PageDataService} from "./data/page-data.service";
import {BasePaginatedTableService} from "./tables/base-paginated-table.service";
import {SelectManyUsersComponent} from "./components/select-search-api/many/wrappers/select-many-users.component";
import {
    RelationshipPageSelectorComponent
} from "./components/relationship-page-selector/relationship-page-selector.component";
import {SelectManyPagesComponent} from "./components/select-search-api/many/wrappers/select-many-pages.component";
import {SelectManyGroupsComponent} from "./components/select-search-api/many/wrappers/select-many-groups.component";
import {FloatingInputDirective} from './directives/floating-input.directive';
import {LockDataService} from "./services/lock-data.service";
import {NotFoundViewComponent} from "./user_control/not-found-view/not-found-view.component";
import {LockTemplateTableViewComponent} from './tables/lock-template-table-view/lock-template-table-view.component';
import {LockTemplateTableComponent} from './tables/lock-template-table/lock-template-table.component';
import {
    LockTemplateVersionTableComponent
} from './tables/lock-template-version-table/lock-template-version-table.component';
import {LockTemplateFormDialogComponent} from './forms/lock-template-form-dialog/lock-template-form-dialog.component';
import {
    LockTemplateDependencyTree
} from "./menu-bars/lock-template-dependency-tree/lock-template-dependency-tree.component";
import {GenericComponentFieldComponent} from "./components/generic-component-field/generic-component-field.component";
import {
    ComponentFileUploadDialogComponent
} from "./forms/component-file-upload-dialog/component-file-upload-dialog.component";
import {
    GenericConstantFieldComponent
} from './components/generic-component-field/generic-constant-field/generic-constant-field.component';
import {
    GenericComponentFileFieldComponent
} from "./components/generic-component-field/generic-component-file-field/generic-component-file-field.component";
import {SeriesContextMenuComponent} from './components/series-context-menu/series-context-menu.component';
import {UploadModelFormComponent} from './forms/upload-model-form/upload-model-form.component';
import {UploadModelComponent} from './components/upload-model/upload-model.component';
import {DropFileSelectorComponent} from './components/drop-file-selector/drop-file-selector.component';
import {SnackbarComponent} from './notifications/snackbar/snackbar.component';
import {ClearConstantService} from "./components/constant-field/clear-constant.service";
import {
    TableCellRelationshipListComponent
} from './components/table-cell-relationship-list/table-cell-relationship-list.component';
import {
    ConstantPropertyListComponent
} from './components/table-cell-relationship-list/constant-property-list/constant-property-list.component';
import {GenericConstantDataService} from "./data/generic-constant-data.service";
import {EventColumnSelectorComponent} from './components/event-column-selector/event-column-selector.component';
import {
    UserFormProcessAccessFeaturesComponent
} from './forms/user-form/user-form-process-access-features/user-form-process-access-features.component';
import {SaveService} from "./services/save/save.service";
import {UserPreferenceComponent} from './forms/user-form/user-preference/user-preference.component';
import {
    ComponentFileDeleteDialogComponent
} from './forms/component-file-delete-dialog/component-file-delete-dialog.component';
import {ConfirmDialogComponent} from './forms/confirm-dialog/confirm-dialog.component';
import {SelectFilterComponent} from './components/select-filter/select-filter.component';
import {FilterBuilderFormComponent} from './forms/query-builder-form/filter-builder-form/filter-builder-form.component';
import {
    ConstantPropertyCalcTreeDialogComponent
} from './forms/constant-property-calc-tree-dialog/constant-property-calc-tree-dialog.component';
import {OreBodyDataService} from "./data/ore-body-data.service";
import {FilterTableButtonComponent} from './components/filter-table-button/filter-table-button.component';
import {HoverDelayDirective} from "./directives/hover-delay.directive";
import {ErrorBankService} from "./services/error-bank.service";
import {ComponentPanelStackComponent} from './modals/component-panel-stack/component-panel-stack.component';
import {ComponentPanelStackService} from "./modals/component-panel-stack/component-panel-stack.service";
import {ComponentDetailComponent} from "./components/component-detail/component-detail.component";
import {NewComponentComponent} from "./components/component/new-component.component";
import {SearchApiComponent} from './components/search-api/search-api.component';
import {ResizableDirective} from './directives/resizable.directive';
import {UserService} from "./services/user.service";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {initKeycloak} from "./factories/keycloak-init.factory";
import {
    EventComponentDeleteCheckDialogComponent
} from './forms/event-component-delete-check-dialog/event-component-delete-check-dialog.component';
import {DefaultChartFormComponent} from './forms/default-chart-form/default-chart-form.component';
import {ComponentSidePanelFormComponent} from './forms/component-side-panel-form/component-side-panel-form.component';
import {BackgroundJobService} from "./services/background-job.service";
import {SolverTemplateTableComponent} from './tables/solver-template-table/solver-template-table.component';
import {SolverTemplateFormComponent} from './forms/solver-template-form/solver-template-form.component';
import {ConstraintTableFormComponent} from './forms/constraint-table-form/constraint-table-form.component';
import {SolverRunnerComponent} from './components/solver-runner/solver-runner.component';
import {SeriesFormulaBuilderComponent} from './components/series-formula-builder/series-formula-builder.component';
import {SolverFormComponent} from "./forms/solver-form/solver-form.component";
import {SeriesSeriesTableComponent} from './forms/series-series-table/series-series-table.component';
import {
    LockTemplateDependencyTreeService
} from "./menu-bars/lock-template-dependency-tree/lock-template-dependency-tree.service";
import {BarChartComponent} from './charts/bar-chart/bar-chart.component';
import {
    PlotlyWaterfallChartConfigTranslationService
} from "./services/plotly-waterfall-chart-config-translation.service";
import {PlotlyRarChartConfigTranslationService} from "./services/plotly-rar-chart-config-translation.service";
import {RarChartComponent} from './charts/rar-chart/rar-chart.component';
import {
    SelectManySeriesTypesComponent
} from "./components/select-search-api/many/wrappers/select-many-series-types.component";
import {
    RarChartFormSeriesTableComponent
} from './forms/custom-chart-form/rar-chart-form-series-table/rar-chart-form-series-table.component';
import {SolverDataTableComponent} from './components/solver-data-table/solver-data-table.component';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {GenerateAuthTokenComponent} from './user_control/generate-auth-token/generate-auth-token.component';
import {BackdropOverlayComponent} from './components/backdrop-overlay/backdrop-overlay.component';
import {ConfirmSnackbarComponent} from './notifications/snackbar/confirm-snackbar/confirm-snackbar.component';
import {UserDetailsComponent} from './user_control/wire-login-forms/user-details/user-details.component';
import {UserPasswordComponent} from './user_control/wire-login-forms/user-password/user-password.component';
import {UserOtpComponent} from './user_control/wire-login-forms/user-otp/user-otp.component';
import {MainSidenavComponent} from './components/main-sidenav/main-sidenav.component';
import {MainHeaderComponent} from './components/main-header/main-header.component';
import {PrintContentComponent} from './components/print-content/print-content.component';
import {NoticeBoardComponent} from './components/notice-board/notice-board.component';
import {WireButtonComponent} from './shared/buttons/wire-button/wire-button.component';
import {PageCommentTableComponent} from './components/page-comment-table/page-comment-table.component';
import {KeycloakCheckLoginGuard} from "./services/route-guards/check-login-guard.guard";
import {PrintHeaderTileComponent} from './print/print-header-tile/print-header-tile.component';
import {PrintFooterTileComponent} from './print/print-footer-tile/print-footer-tile.component';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import {
    ComponentEventsDownloadFormatMenuComponent
} from './forms/custom-dialog/events-download-format-menu/component-events-download-format-menu.component';
import {PivotService} from "./services/pivot.service";
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";
import {SeriesService} from "./services/models/series.service";
import {
    ComponentNameFormulaBuilderComponent
} from './forms/component-name-formula-builder/component-name-formula-builder.component';
import {ComponentNameFormulaBuilderService} from "./services/component-name-formula-builder.service";
import {
    ComponentNameFormulaTableComponent
} from './forms/component-name-formula-builder/component-name-formula-table/component-name-formula-table.component';
import {
    ComponentNameFormulaSelectorComponent
} from './forms/component-name-formula-builder/component-name-formula-selector/component-name-formula-selector.component';
import {ComponentTypeService} from "./services/models/component-type.service";
import {SubroutePageOutletComponent} from './sub-modules/subroute-page-outlet/subroute-page-outlet.component';
import {GetUserPipe} from "./shared/pipes/get-user.pipe";
import {SeriesAdjustmentFormComponent} from './forms/series-adjustment-form/series-adjustment-form.component';
import {SeriesAdjustmentComponent} from './components/series-adjustment/series-adjustment.component';
import {JobNotificationComponent} from './notifications/job-notification/job-notification.component';
import {
    CalculationErrorNotificationComponent
} from "./notifications/calculation-error-notification/calculation-error-notification.component";
import {SharedModule} from "./shared/modules/shared-module.module";
import {SharedPipesModule} from "./shared/modules/shared-pipes.module";
import {DefaultValueFieldComponent} from './components/default-value-field/default-value-field.component';
import {GetNamePipe} from "./shared/pipes/get-name.pipe";
import {
    DatePropertyFilterSelectorComponent
} from "./forms/pivot-tile-form/date-property-filter-selector/date-property-filter-selector.component";
import {DateTimeInstanceService} from "./services/date-time-instance.service";
import {
    ConstantLimitsPromptComponent
} from "./components/snackbar/constant-limits-prompt/constant-limits-prompt.component";
import {OptionListSearchComponent} from './components/option-list-search/option-list-search.component';
import {CommentPromptComponent} from "./components/snackbar/comment-prompt/comment-prompt.component";
import {EnterToActionDirective} from "./directives/enter-to-action.directive";
import {EscToActionDirective} from "./directives/esc-to-action.directive";
import {ThemeViewComponent} from "./shared/scss/theme-component/theme-view.component";
import {WebdatarocksPivotModule} from "@webdatarocks/ngx-webdatarocks";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {UtilsBarComponent} from "./tables/utils-bar/utils.bar.component";
import {SeriesDataTableDownloadService} from "./services/series-data-table-download.service";
import {
    ValidationBuilderComponent
} from './components/validation-builder/validation-builder/validation-builder.component';
import {RuleSetComponent} from './components/validation-builder/rule-set/rule-set.component';
import {RuleComponent} from './components/validation-builder/rule/rule.component';
import {FieldOptionComponent} from './components/validation-builder/rule/field-option/field-option.component';
import {FieldOptionService} from "./components/validation-builder/rule/field-option.service";
import {
    ComponentTypeFieldOptionService
} from "./components/validation-builder/rule/component-type-field-option.service";
import {EventTypeFieldOptionService} from "./components/validation-builder/rule/event-type-field-option.service";
import {QueryOperatorComponent} from './components/validation-builder/rule/query-operator/query-operator.component';
import {QueryBuilderFormService} from "./services/query-builder-form.service";
import {QueryValueComponent} from './components/validation-builder/rule/query-value/query-value.component';
import {ValidationBuilderService} from "./services/validation-builder.service";
import {AdvancedTabComponent} from './forms/page-tile-form/advanced-tab/advanced-tab.component';
import {ColumnSelectorComponent} from './components/event-column-selector/column-selector/column-selector.component';
import {SelectManyFilterComponent} from "./components/select-many-filter/select-many-filter.component";
import {SanitiseService} from "./services/sanitise.service";
import {SeriesHtmlFormComponent} from './forms/custom-html-form/series-html-form/series-html-form.component';
import {
    ComponentReportBuilderComponent
} from './forms/custom-html-form/component-report-builder/component-report-builder.component';
import {
    SeriesReportBuilderComponent
} from './forms/custom-html-form/series-report-builder/series-report-builder.component';
import {
    CustomHtmlTextFormComponent
} from "./forms/custom-html-form/custom-html-text-form/custom-html-text-form.component";
import {PageTileMenuComponent} from './menu-bars/page-tile-menu/page-tile-menu.component';
import {PrintoutDataService} from "./data/printout-data.service";
import {ConfigTypeDateFilter} from "./_typing/config/config-type-date-filter";
import {
    ReportTagGeneratorComponent
} from './forms/custom-html-form/report-tag-generator/report-tag-generator.component';
import {JsonContextService} from "./forms/custom-html-form/json-context.service";
import {JsonContextTagService} from "./forms/custom-html-form/json-context-tag.service";
import {FunctionBuilderComponent} from './forms/custom-html-form/function-builder/function-builder.component';
import {MatomoModule, MatomoRouterModule} from "ngx-matomo-client";
import {CopyTileFormDialogComponent} from "./forms/copy-tile-form-dialog/copy-tile-form-dialog.component";
import {DragdropTreeComponent} from "./menu-bars/dragdrop-tree/dragdrop-tree.component";
import {DragdropTreeService} from "./menu-bars/dragdrop-tree/dragdrop-tree.service";
import {StaticMenuTreeComponent} from "./menu-bars/static-menu-tree/static-menu-tree.component";

export const WireOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    position: 'below',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};

@NgModule({
    declarations: [
        LockTemplateDependencyTree,
        SelectManyUsersComponent,
        SelectManyPagesComponent,
        DragdropTreeComponent,
        DatePropertyFilterSelectorComponent,
        SelectManyGroupsComponent,
        SelectManySeriesTypesComponent,
        RelationshipPageSelectorComponent,
        UserGroupPageFormDialogComponent,
        OreBodyDataChartComponent,
        SelectManySeriesComponent,
        SelectManyEventTypesComponent,
        SelectManyConstantPropertiesComponent,
        SelectManyComponentTypesComponent,
        AppComponent,
        LoginComponent,
        AuditPivotComponent,
        SideNavigation,
        TreeNodeComponent,
        CalculationCheckTableComponent,
        DataExceptionsTableComponent,
        DataExceptionsTableViewComponent,
        JobNotificationComponent,
        CalculationErrorNotificationComponent,
        EngineeringUnitViewComponent,
        RegisterUserView,
        UserFormComponent,
        ComponentEventsTableFormComponent,
        ComponentPrintoutsPipe,
        MatchTitleToCount,
        DateTimePeriodPipe,
        SortObjectByArrayPipe,
        SnakeCasePipe,
        SnakeCaseToTitleCasePipe,
        PageTileFormComponent,
        ChartDialog,
        PageViewFormComponent,
        SeriesFormComponent,
        CollectorEventsTableComponent,
        ValueDriverTreeTableComponent,
        DateTimePeriodComponent,
        HeaderComponent,
        EnterToActionDirective,
        EscToActionDirective,
        ScrollDirective,
        NotificationsDirective,
        StreamSheetFocusViewComponent,
        StreamSheetViewComponent,
        QuickChartsComponent,
        SelectSearchComponentComponent,
        ClientTranslatorPipe,
        FormatNumberPipe,
        SignificantNumberPipe,
        PercentagePipe,
        AbbreviateNumber,
        ThousandsSeparatePipe,
        NameAndDescriptionPipe,
        SafeURIPipe,
        FilterByNamePipe,
        FilterByValuePipe,
        ArrayMapPipe,
        UtilsBarComponent,
        GenericChartComponent,
        CollectorMapperSheetViewComponent,
        EquipmentSheetFocusViewComponent,
        CustomEquipmentViewComponent,
        ShiftSheetViewComponent,
        OreBodySheetViewComponent,
        ScheduleSheetViewComponent,
        ScheduleSheetFocusViewComponent,
        SpcChartComponent,
        SpcChartFormComponent,
        ComparisonChartFormComponent,
        PageViewComponent,
        PageTileComponent,
        DownloadFormDialogComponent,
        SeriesComponentSheetViewComponent,
        SeriesComponentSheetFocusViewComponent,
        SeriesTableComponent,
        SeriesTableFocusViewComponent,
        UploadFormDialogComponent,
        ProcessSheetViewComponent,
        CustomProcessViewComponent,
        ProcessSheetFocusViewComponent,
        EstimateSheetViewComponent,
        LogSheetFocusViewComponent,
        LogSheetComponent,
        EventSheetViewComponent,
        ResourceSheetViewComponent,
        ProcessAccessSheetViewComponent,
        SeriesSheetViewComponent,
        SeriesSheetFocusViewComponent,
        SeriesSheetFocusViewComponent,
        FlowChartComponent,
        FlowchartFocusViewComponent,
        FlowchartSeriesComponentComponent,
        WaterBalanceComponent,
        EstimateDataSheetComponent,
        EstimateDataSheetViewComponent,
        EstimateSheetFocusViewComponent,
        EstimateDataSheetFocusViewComponent,
        EditHistoryComponent,
        EstimateFormComponent,
        InputDataSheetFocusViewComponent,
        InputDataSheetComponent,
        CommentDrawerComponent,
        EventPivotComponent,
        CustomChart,
        LogSheetFormComponent,
        SeriesTableFormComponent,
        WeatherWidgetComponent,
        ColourPaletteComponent,
        InlineStyleMenuComponent,
        CustomEventsFormComponent,
        CopyTileFormDialogComponent,
        ColourPaletteComponent,
        MySecurityComponent,
        ResetPasswordComponent,
        InputDataSheetFormComponent,
        FlowchartFormComponent,
        FlowchartProcessFormComponent,
        FlowchartStreamFormComponent,
        FlowchartEquipmentFormComponent,
        ChartProcessDetailComponent,
        SeriesComponentTableComponent,
        ComponentConstantTableComponent,
        ConstantSeriesDataComponent,
        EventsComponent,
        DetailRowDirective,
        SeriesAuditHistoryDirective,
        ColumnResizeDirective,
        WaterBalanceDetailViewComponent,
        WaterBalanceChartComponent,
        ValueDriverTreeComponent,
        ValueDriverTreeViewComponent,
        ApplyCorrectionComponent,
        PivotTileComponent,
        PivotTileNgComponent,
        PivotTileFormComponent,
        SparklineComponent,
        RecoveryGradeForecastComponent,
        PageListViewComponent,
        PageListComponent,
        AppPasswordDirective,
        RecoveryGradeForecastFormComponent,
        ParagraphComponent,
        AuditHistoryComponent,
        SeriesAuditHistoryComponent,
        CustomHtmlComponent,
        WelcomeComponent,
        HelpPageComponent,
        ContextComponent,
        ContextFormComponent,
        MentionDirective,
        UserInteractionDirective,
        ScrollableDirective,
        AtWhoComponent,
        CustomChartFormDialogComponent,
        ContextFormDialogComponent,
        SeriesSummaryMenuComponent,
        PrintFormatterComponent,
        AccountFilterComponent,
        ParagraphFormComponent,
        CustomHtmlFormComponent,
        CustomHtmlTextFormComponent,
        ExceptionOnDemandComponent,
        FlowchartImageFormComponent,
        CustomChartFormSeriesTableComponent,
        CategoryChartComponent,
        ComparisonChartComponent,
        CategoryChartFormComponent,
        CommentsTableFormComponent,
        CommentsTableComponent,
        UserFormProcessAccessComponent,
        ReportIssueFormDialogComponent,
        ModelPivotFormComponent,
        ModelPivotTileComponent,
        EventTypesTableComponent,
        ComponentTypesTableComponent,
        EventTypeFormDialogComponent,
        ComponentTypeFormDialogComponent,
        OreBodyTypesTableComponent,
        OreBodyFormDialogComponent,
        OreBodyTypeFormDialogComponent,
        OreBodyGroupFormComponent,
        ConstantPropertyTableFormComponent,
        OreBodyGroupViewComponent,
        SourceDestinationTableFormComponent,
        UploadModelFormDialogComponent,
        ExtractOreBodyOptionsComponent,
        OreBodyDataTableComponent,
        OreBodySummaryTableComponent,
        UserActivityChartComponent,
        OreBodyGroupNodeComponent,
        UploadModelFormTemplateComponent,
        ExtractEventOptionsComponent,
        ForbiddenViewComponent,
        NotFoundViewComponent,
        AnalysisToolsComponent,
        CustomDialogComponent,
        ComponentEventsTableComponent,
        ComponentEventsTableViewComponent,
        SeriesSummaryComponent,
        CustomEventsTableComponent,
        ComponentEventsTableComponent,
        ComponentEventsTableViewComponent,
        EventsTableComponent,
        ComponentsTableComponent,
        FormulaBuilderComponent,
        SeriesEditButtonComponent,
        ComponentCreatorComponent,
        ComponentCreatorFormComponent,
        ComponentFormFormComponent,
        ConstantLimitsPromptComponent,
        CommentPromptComponent,
        PendingContextComponent,
        PendingContextFormComponent,
        PendingContextValidationComponent,
        CustomFormFieldComponent,
        PrintoutMapperComponent,
        PrintoutMapperFormComponent,
        ComponentTypeConditionsComponent,
        ComponentFormComponent,
        SingleComponentEventsTableViewComponent,
        TableColumnMenuComponent,
        QueryBuilderFormComponent,
        PdfHeaderComponent,
        PdfFooterComponent,
        JulianDateComponent,
        OfflineDialogComponent,
        ExportComponentDialogComponent,
        AnalysisToolsViewComponent,
        ConstantPropertyEventsComponent,
        HoverDelayDirective,
        ValueDriverTreeFormComponent,
        EventModelUploadSelectComponentTypeComponent,
        EventModelUploadSelectConstantPropertyComponent,
        EventModelUploadSelectEventTypeComponent,
        EventModelUploadSelectUniquePropertyComponent,
        ProcessQuickMenuComponent,
        GenerateTableParseResponseDialogComponent,
        RelativeDatePeriodComponent,
        ExtractComponentOptionsComponent,
        ComponentModelUploadSelectComponentTypeComponent,
        ComponentModelUploadSelectComponentTypeColumnComponent,
        ComponentModelUploadSelectConstantPropertyComponent,
        BreadcrumbsComponent,
        ConstantFieldComponent,
        AlertsTableComponent,
        FitContentDirective,
        NotificationsComponent,
        ValidateComponentTemplatesComponent,
        CollationSeriesTableComponent,
        RangeSelectorComponent,
        ConditionalFormattingFormComponent,
        SeriesDataTableComponent,
        SeriesDataTableFormComponent,
        SeriesDetailTableComponent,
        WaterfallChartComponent,
        WaterfallChartFormComponent,
        ComponentConstantsTableComponent,
        AuditMixinComponent,
        AuditMixinIndicatorComponent,
        TimezoneSelectorComponent,
        SeriesSummaryColumnSelectorComponent,
        SeriesEstimateFormComponent,
        CustomChartComponent,
        RelationshipGroupSelectorComponent,
        RelationshipUserSelectorComponent,
        FloatingInputDirective,
        LockTemplateTableViewComponent,
        LockTemplateTableComponent,
        LockTemplateFormDialogComponent,
        LockTemplateVersionTableComponent,
        GenericComponentFieldComponent,
        GenericComponentFileFieldComponent,
        ComponentFileUploadDialogComponent,
        GenericConstantFieldComponent,
        SeriesContextMenuComponent,
        UploadModelFormComponent,
        UploadModelComponent,
        DropFileSelectorComponent,
        SnackbarComponent,
        TableCellRelationshipListComponent,
        ConstantPropertyListComponent,
        EventColumnSelectorComponent,
        SelectFilterComponent,
        SelectManyFilterComponent,
        ComponentFileDeleteDialogComponent,
        ConfirmDialogComponent,
        UserFormProcessAccessFeaturesComponent,
        UserPreferenceComponent,
        FilterBuilderFormComponent,
        ConstantPropertyCalcTreeDialogComponent,
        ComponentPanelStackComponent,
        ComponentDetailComponent,
        NewComponentComponent,
        FilterTableButtonComponent,
        EventComponentDeleteCheckDialogComponent,
        GenerateAuthTokenComponent,
        BackdropOverlayComponent,
        ConfirmSnackbarComponent,
        UserPasswordComponent,
        UserOtpComponent,
        MainSidenavComponent,
        MainHeaderComponent,
        PrintContentComponent,
        NoticeBoardComponent,
        ResizableDirective,
        ComponentSidePanelFormComponent,
        DefaultChartFormComponent,
        PageCommentTableComponent,
        SeriesSeriesTableComponent,
        BarChartComponent,
        RarChartComponent,
        RarChartFormSeriesTableComponent,
        SolverFormComponent,
        SolverRunnerComponent,
        SolverTemplateTableComponent,
        SolverTemplateFormComponent,
        ConstraintTableFormComponent,
        SeriesFormulaBuilderComponent,
        SolverDataTableComponent,
        PrintHeaderTileComponent,
        ComponentEventsDownloadFormatMenuComponent,
        ComponentNameFormulaBuilderComponent,
        ComponentNameFormulaTableComponent,
        ComponentNameFormulaSelectorComponent,
        SelectManySearchComponent,
        SelectSearchComponent,
        SelectManySearchApiComponent,
        SelectManySearchBaseComponent,
        SelectSearchApiComponent,
        SearchApiComponent,
        UserListComponent,
        WireButtonComponent,
        UserDetailsComponent,
        ManageUsersComponent,
        NameAndDescriptionPipe,
        GroupListComponent,
        JsonTableComponent,
        DefaultChartComponent,
        EquipmentSheetViewComponent,
        HistoryTableComponent,
        TitlecaseForWirePipe,
        AccountPickerComponent,
        PivotViewComponent,
        MatSelectInfiniteScrollDirective,
        LdsRollerDirective,
        DblClickCopyDirective,
        DivInfiniteScrollDirective,
        PageTileMenuComponent,
        AdvancedTabComponent,
        ComponentEventsDownloadFormatMenuComponent,
        PrintFooterTileComponent,
        SubroutePageOutletComponent,
        SeriesAdjustmentFormComponent,
        SeriesAdjustmentComponent,
        ThemeViewComponent,
        DefaultValueFieldComponent,
        ColumnSelectorComponent,
        SeriesHtmlFormComponent,
        ComponentReportBuilderComponent,
        SeriesReportBuilderComponent,
        FunctionBuilderComponent,
        ValidationBuilderComponent,
        RuleSetComponent,
        RuleComponent,
        FieldOptionComponent,
        QueryOperatorComponent,
        QueryValueComponent,
        OptionListSearchComponent,
        ReportTagGeneratorComponent
    ],
    bootstrap: [AppComponent],
    exports: [], imports: [SharedModule,
        SharedPipesModule,
        ProcessMenuDirective,
        WebdatarocksPivotModule,
        BrowserModule,
        OverlayscrollbarsModule,
        PlotlyViaWindowModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        DragDropModule,
        GridsterModule,
        ScrollingModule,
        QuillModule.forRoot(),
        ColorPickerModule,
        AngularSplitModule,
        NgxAngularQueryBuilderModule,
        NgxSkeletonLoaderModule,
        KeycloakAngularModule,
        MatomoModule.forRoot({
            scriptUrl: analytic_domain,
            trackers: [
                {
                    trackerUrl: window.location.origin,
                    siteId: analytic_sites[window.location.origin] || 0
                }
            ],
        }),
        MatomoRouterModule,
        SocketIoModule.forRoot({
            url: window.location.origin, options: {
                timeout: 5000,
                reconnectionDelayMax: 2000,
                transports: [/*"websocket", */ "polling"] // disabling websockets as we can't get past Caddy at the moment.
            }
        }),
        SocketIoModule.forRoot({
            url: window.location.origin, options: {
                timeout: 5000,
                reconnectionDelayMax: 2000,
                transports: [/*"websocket", */ "polling"] // disabling websockets as we can't get past Caddy at the moment.
            }
        }),
        GetUserPipe,
        GetNamePipe,
        StaticMenuTreeComponent,
    ], providers: [
        KeycloakCheckLoginGuard,
        BasePaginatedTableService,
        DragdropTreeService,
        ComponentNameFormulaBuilderService,
        GenericConstantDataService,
        ComponentPanelStackService,
        PrintoutDataService,
        LockDataService,
        ValidationBuilderService,
        ComponentTypeService,
        UserDataService,
        UserService,
        PageDataService,
        FieldOptionService,
        QueryBuilderFormService,
        ComponentTypeFieldOptionService,
        EventTypeFieldOptionService,
        OreBodyDataService,
        ErrorBankService,
        SeriesAuditHistoryDirective,
        SanitiseService,
        SeriesDataTableDownloadService,
        HttpErrorHandler,
        ApiService,
        GroupDataService,
        AlertsTableService,
        ConfigTypeDateFilter,
        PlotlyChartConfigTranslationService,
        PlotlyBarChartConfigTranslationService,
        PlotlyRarChartConfigTranslationService,
        PlotlyWaterfallChartConfigTranslationService,
        AppScope,
        UserData,
        SeriesStringParser,
        PlantDataService,
        ChartConfigTranslationService,
        DateTimePeriodService,
        DateTimeInstanceService,
        CommentsTableService,
        HandsontableGenerator,
        HeaderDataService,
        EventService,
        EventDataService,
        EventTypeService,
        PivotService,
        SeriesService,
        SeriesDataService,
        PresentationService,
        MenuTreeService,
        LockTemplateDependencyTreeService,
        InputDataService,
        UserService,
        CustomEventsService,
        ModelChangesService,
        CachingService,
        ChartService,
        FormatNumberPipe,
        ChartEventsService,
        FormDialogService,
        ContextService,
        TableUtilsService,
        UserPreferenceDataService,
        TimezoneSelectorService,
        ConstantPropertyDataService,
        ComponentTypeDataService,
        ComponentDataService,
        QueryBuilderService,
        RelationshipApiMappingService,
        BreadcrumbsService,
        GenericDataService,
        ConditionalFormattingService,
        EventTypeFormDialogService,
        ConstantPropertyTableFormService,
        SeriesAuditHistoryService,
        TimeZoneService,
        SaveService,
        JsonContextService,
        JsonContextTagService,
        ClearConstantService,
        BackgroundJobService,
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: WireOptions},
        {provide: 'Window', useValue: window},
        [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}],
        {provide: APP_INITIALIZER, useFactory: initKeycloak, multi: true, deps: [KeycloakService]},
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic'}},
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class AppModule {
    constructor() {
    }
}
