<ng-container *ngIf="!isEmpty(series_dict)">
  <ng-container *ngIf="showVertical" [ngTemplateOutlet]="verticalTable"></ng-container>

  <ng-container *ngIf="!showVertical && timeColumns?.length" [ngTemplateOutlet]="horizontalTable"></ng-container>

  <ng-template #verticalTable>
    <table class="series-data-table simple-table">
      <thead>

      <tr *ngIf="config.header_rows.process || config.header_rows.custom_groups">
        <th *ngIf="show_time_header_dict['process']" class="time-column" [attr.rowspan]="header_row_count">Time</th>
        <th *ngFor="let group of groupList; let i = index;"
            class="group-header"
            [attr.colspan]="groupDict[group]?.length || 1"
            [ngClass]="(i % 2 == 0)? 'process-group': 'process-group-alt'">
          {{group}}
          <i *ngIf="!groupDict?.length" class="info" matTooltip="No series selected for this process"></i>
        </th>
      </tr>

      <tr *ngIf="config.header_rows.name || config.header_rows.description">
        <th *ngIf="show_time_header_dict['name']" class="time-column" [attr.rowspan]="header_row_count">Time</th>
        <ng-container *ngFor="let group of groupList">
          <th *ngFor="let series of groupDict[group]" class="vertical-cell">
            <div
                title="{{series_dict[series.id]?.attributes.name}} - {{series_dict[series.id]?.attributes.description}}">
              <div *ngIf="config.header_rows.name">
                {{(series_dict[series.id]?.attributes.name).substring(0, 30)}}
              </div>
              <div *ngIf="config.header_rows.description">
                {{(series_dict[series.id]?.attributes.description).substring(0, 30)}}
              </div>
            </div>
          </th>
        </ng-container>
      </tr>

      <tr *ngIf="config.header_rows.alias">
        <th *ngIf="show_time_header_dict['alias']" class="time-column" [attr.rowspan]="header_row_count">Time</th>
        <ng-container *ngFor="let group of groupList">
          <th *ngFor="let series of groupDict[group]">
            <div class="series-edit-button-container">
              <div>{{series_dict[series.id]?.attributes.alias}}</div>
              <series-edit-button [series_id]="series.id" *ngIf="!config.header_rows.edit"></series-edit-button>
            </div>
          </th>
        </ng-container>
      </tr>

      <tr *ngIf="config.header_rows.unit">
        <th *ngIf="show_time_header_dict['unit']" class="time-column" [attr.rowspan]="header_row_count">Time</th>
        <ng-container *ngFor="let group of groupList">
          <th *ngFor="let series of groupDict[group]">
            <div>
              {{series_dict[series.id]?.attributes.engineering_unit_name}}
            </div>
          </th>
        </ng-container>
      </tr>

      <tr *ngIf="config.header_rows.edit">
        <th *ngIf="show_time_header_dict['edit']" class="time-column" [attr.rowspan]="header_row_count">Time</th>
        <ng-container *ngFor="let group of groupList">
          <th *ngFor="let series of groupDict[group]">
            <series-edit-button [series_id]="series.id"></series-edit-button>
          </th>
        </ng-container>
      </tr>

      </thead>

      <tbody>

      <ng-container *ngIf="series_summary && config.show_gss === 'top'" [ngTemplateOutlet]="series_summary_rows"
                    [ngTemplateOutletContext]="{ $implicit: series_summary, config: config }">
      </ng-container>

      <tr *ngFor="let time of timeDict | keyvalue" class="data-row">

        <td class="time-column" style="white-space: nowrap;"
            [matTooltip]="(time.value.period_start | stringDate) + ' - ' + (time.value.period_end | stringDate) ">
          {{dtp.sample_period.format(time.value.period_end, dtp)}}
        </td>

        <ng-container *ngFor="let item of groupList">
          <td *ngFor="let series of groupDict[item]"
              (click)="openChartDialog(series_dict[series.id].attributes.name)" style="cursor:pointer;"
              [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY"
              [matTooltip]="series_data[time.value.period_end][series_dict[series.id]?.attributes?.name]">
            <div *ngIf="series_data[time.value.period_end]"
                 [ngStyle]="format_dict[series.id]?.style"
                 [ngClass]="{'warning': series[time.value.period_end]?.warning, 'error': series[time.value.period_end]?.error,
                'missing': series_missing[time.value.period_end][series_dict[series.id].attributes.name]}">
              {{series_data[time.value.period_end][series_dict[series.id]?.attributes?.name] |
                formatNumber : format_dict[series.id]?.decimals : true : 3 : false : series_dict[series.id]}}
            </div>

          </td>
        </ng-container>
      </tr>

      <ng-container *ngIf="series_summary && config.show_gss === 'bottom'" [ngTemplateOutlet]="series_summary_rows"
                    [ngTemplateOutletContext]="{ $implicit: series_summary, config: config }">
      </ng-container>
      </tbody>

    </table>

  </ng-template>
  <!--**Template for Get Series Summary Rows**-->
  <ng-template #series_summary_rows let-series_summary let-config="config">
    <tr *ngFor="let column of config.gss?.columns">
      <td class="time-column"
          style="white-space: nowrap;font-weight: bold;">{{gssColumnDict?.[column]?.title || column | upperfirst}}
      </td>
      <ng-container *ngFor="let item of groupList">
        <td *ngFor="let series of groupDict[item]"
            (click)="openChartDialog(series.attributes.name)" style="cursor:pointer;">
          <div *ngIf="series_summary[series.id]" [ngStyle]="format_dict[column]?.style">
            {{series_summary[series.id][column] |
              formatNumber : format_dict[series.id]?.decimals : true : 3 : false : series_dict[series.id] : column}}
          </div>

        </td>
      </ng-container>
    </tr>
  </ng-template>


  <ng-template #horizontalTable>
    <div class="table-container">
      <table class="series-data-table series-data-table-horizontal simple-table">
        <thead>
        <tr>
          <ng-container *ngFor="let column of columns;">
            <ng-container *ngIf="column ==='edit' || column==='group'">
              <th class="edit-column-left" [ngClass]="column">
              </th>
            </ng-container>
            <ng-container *ngIf="!timeColumns.includes(column) && !['edit','group'].includes(column)">
              <th
                  [ngClass]="{'is-sticky-left':isSticky[column]}">
                <ng-container>{{column | upperfirst | removeUnderscore}}</ng-container>
              </th>
            </ng-container>
            <ng-container *ngIf="timeColumns.includes(column)">
              <th class="time-column-vertical" style="font-weight:normal;"
                  [matTooltip]="(timeDict[column]?.period_start | stringDate) + ' - ' + (timeDict[column]?.period_end | stringDate) ">
                <ng-container>{{timeDict[column]?.title}}</ng-container>
              </th>
            </ng-container>
          </ng-container>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let group of groupList;let groupIndex = index">
          <tr *ngFor="let series of groupDict[group];let seriesIndex = index">
            <ng-container *ngFor="let column of columns;">

              <td *ngIf="column!=='group'"
                  [ngClass]="{'is-sticky-left':isSticky[column], 'edit-column-left': column==='edit','with-edit':columns.includes('edit')}">
                <div *ngIf="column==='edit'">
                  <series-edit-button [series_id]="series.id"></series-edit-button>
                </div>
                <ng-container *ngIf="['name','description','alias'].includes(column)">
                  <div class="series-column">{{series_dict[series.id]?.attributes[column]}}</div>
                </ng-container>
                <ng-container *ngIf="column==='unit'">
                  <div class="series-column">{{series_dict[series.id]?.attributes.engineering_unit_name}}</div>
                </ng-container>
                <ng-container *ngIf="gssColumns?.includes(column) && series_summary?.[series.id]">
                  <div class="gss-column" [ngStyle]="format_dict[column]?.style"
                       (click)="openChartDialog(series.attributes.name)"
                       style="cursor:pointer;">{{series_summary[series.id][column] |
                      formatNumber : format_dict[column]?.decimals : true : 3 : false : series_dict[series.id] : column}}
                  </div>
                </ng-container>
                <div *ngIf="timeColumns.includes(column)" [ngStyle]="format_dict[series.id]?.style"
                     (click)="openChartDialog(series.attributes.name)"
                     [ngClass]="{'warning': series[column]?.warning, 'error': series[column]?.error,
                'missing': series_missing[column][series_dict[series.id].attributes.name]}"
                     style="cursor:pointer;">
                  {{series_data[column][series.attributes.name]|
                    formatNumber : format_dict[series.id]?.decimals : true : 3 : false : series_dict[series.id]}}
                </div>
              </td>

              <td *ngIf="column==='group' && seriesIndex===0"
                  class="group-header vertical-group-header vertical-cell"
                  [attr.rowspan]="groupDict?.[group]?.length || 1"
                  [ngClass]="(groupIndex % 2 == 0)? 'process-group': 'process-group-alt'"
                  [style.height]="24 * groupDict?.[group]?.length + 'px'">
                <div [matTooltip]="group">
                  <div [style.width]="24 * groupDict?.[group]?.length + 'px'">{{group}}
                    <i *ngIf="!groupDict?.[group]?.length" class="info"
                       matTooltip="No series selected for this process"></i>
                  </div>
                </div>

              </td>

            </ng-container>
          </tr>
        </ng-container>
        </tbody>


      </table>
    </div>
  </ng-template>

</ng-container>


<ng-container *ngIf="isEmpty(series_dict) && tileReady">
  Configuration for this tile is incomplete or there was an error fetching your data.
</ng-container>
