import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import * as utils from "../../lib/utils";
import {concat, Observable, of, Subject, Subscription} from "rxjs";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {NotificationService} from "../../services/notification.service";
import {concatMap, finalize, first, map, takeUntil, tap} from "rxjs/operators";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from '../../services/api/api.service';
import {ListResponse, SingleResponse} from "../../services/api/response-types";
import {ComponentType} from "../../_models/component-type";
import {Component as WireComponent} from "../../_models/component";
import {CustomEventsService} from '../../services/custom-events.service';
import {ComponentCreatorConfig} from '../../forms/component-creator-form/component-creator-form.component';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {ConfigStub} from '../../_typing/config-stub';
import {SelectedNameFormula} from "../../_typing/config/component-form-config";
import {ComponentNameFormulaBuilderService} from "../../services/component-name-formula-builder.service";
import {TimezoneSelectorService} from "../../services/timezone-selector.service";
import {moment} from "../../services/timezone-selector.service";
import {deepCopy} from "../../lib/utils";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'component-creator',
    templateUrl: './component-creator.component.html',
    styleUrls: ['./component-creator.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ComponentCreatorComponent implements OnInit, OnDestroy {
    component_type: ComponentType | ConfigStub<ComponentType>;
    $component_type: Subscription;

    @Input() config: ComponentCreatorConfig;
    name = '';
    components: WireComponent[] = [];
    api_base: string;
    progress = '';
    dtp: IDateTimePeriod;

    no_of_components = 1;
    selected_date;
    selected_julian: string;
    batch_size = 17;
    batch_type = 'BAG';
    prefix: string;
    saving = false;
    error = '';
    count = 0;
    db_component_count = 0;

    min_date: Date = new Date();
    max_date: Date = new Date();
    selectedNameFormula: SelectedNameFormula;
    nameFormulaString: string;
    nameFormulaMatch: string;
    timezone: string;
    countFormat: number;
    countResetFrequency: string;
    manualCount: number;

    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private notification: NotificationService,
                public eventsService: CustomEventsService,
                private nameBuilder: ComponentNameFormulaBuilderService,
                private timezoneService: TimezoneSelectorService) {
    }

    ngOnInit(): void {
        if (!this.config || !this.config.component_types || this.config.component_types.length < 1) {
            this.notification.openError("Please select Component Types for this form.", 10000);
            return;
        }
        this.setCurrentDateLimits();
        if (this.config.default_type) {
            this.component_type = this.config.default_type;
            this.setUpForm(this.component_type);
        }
        this.api_base = this.component_type?.attributes?.base_type ?? "component";
    }

    setCurrentDateLimits() {
        this.timezone = this.timezoneService.active_timezone
        const startHour = this.dateTimePeriodService.getDefaultStartHour(this.dateInst.dtp);
        const momentDate = moment.tz(moment(), this.timezone).startOf('day').add(startHour, 'hours');
        this.selected_date = momentDate.toDate();

        let maxDays, minDays = 2;
        if (this.config.days_in_advance && !isNaN(this.config.days_in_advance)) {
            maxDays = this.config.days_in_advance;
        }
        if (this.config.days_in_advance && !isNaN(this.config.days_in_advance)) {
            minDays = this.config.days_in_arrears;
        }
        this.max_date = deepCopy(momentDate).add('days', maxDays).toDate();
        this.min_date = deepCopy(momentDate).add('days', -minDays).toDate();
        this.selected_julian = utils.julianDate(this.selected_date);
    }

    setUpForm($event) {
        this.component_type = $event;
        this.batch_type = this.setBatchType();
        this.batch_size = this.setBatchSize();
        this.prefix = this.config.prefix;

        this.manualCount = null;
        this.nameFormulaString = null;
        this.nameFormulaMatch = null;
        this.selectedNameFormula = this.config.component_type_config_map?.[this.component_type?.id]?.formula;
        this.setNameFormulaString();
        this.countFormat = this.config.component_type_config_map?.[this.component_type?.id]?.leading_zeros || 0;
        this.countResetFrequency = this.config.component_type_config_map?.[this.component_type?.id]?.count_reset_frequency;

        this.components = [];
        this.error = '';
        this.progress = '';

        // Get full component_type from config id
        let $component_type_obs = this.api.component_type.getById(this.component_type.id)
            .pipe(first(), tap(result => {
                    this.component_type = result.data;
                    this.api_base = this.component_type.attributes.base_type;
                    this.name = this.component_type.attributes.name;
                }),
                concatMap(result => {
                    if (result) {
                        return this.getCount();
                    } else {
                        this.notification.openError(`Component Type ${this.name} not found. Please check your config settings.`)
                        return of([]);
                    }
                }), takeUntil(this.onDestroy));

        this.$component_type = utils.refreshSubscription(this.$component_type);
        this.$component_type = $component_type_obs.subscribe(() => {
        });
    }

    // Gets all components of component_type that have been created for selected date
    private getCount(count_only = true): Observable<ListResponse<WireComponent>> {
        this.progress = "Getting current count...";
        let nameMatch: string = `%${this.selected_julian}%${this.batch_type}%`;
        if (this.nameFormulaString) {
            nameMatch = this._getNameFormulaMatch();
        }

        let options = new SearchQueryOptions();
        options.filters = [
            {
                and: [
                    {
                        name: 'component_type',
                        op: 'has',
                        val: {name: 'id', op: 'eq', val: this.component_type.id}
                    },
                    {
                        name: 'name',
                        op: 'ilike',
                        val: nameMatch
                    }
                ]
            }
        ];
        options.sort = "-name";
        if (count_only === true) {
            options.page_size = 1;
        } else {
            options.page_size = 20;
        }

        return this.api[this.api_base].searchMany(options)
            .pipe(first(), takeUntil(this.onDestroy),
                tap((result: ListResponse<WireComponent>) => {
                    if (result) {
                        this.db_component_count = result.meta.count ? result.meta.count : 0;
                        this.progress = "Count: " + this.db_component_count;
                        if (count_only === false) {
                            this.components = result.data;
                        }
                    }
                }));
    }

    startCreateComponents(): void {
        if (this.saving === true
        ) {
            this.notification.openError("Already saving. Please be patient.");
            return;
        }
        if (this.useManualCountStart() && !this.manualCount) {
            this.notification.openError("Automatic count for this component type is turned off. Please enter a count starting value.");
            return;
        }
        if (!this.batch_size || isNaN(this.batch_size)) {
            if (!confirm("Are you sure about the Batch Size?")) return
        }

        this.saving = true;
        this.error = '';

        this.components = [];
        this.getCount().subscribe(() => {
            if (this.db_component_count !== undefined && this.no_of_components > 0) {
                this.count = utils.deepCopy(this.db_component_count);
                if (this.useManualCountStart()) {
                    this.count = Number(this.manualCount) - 1;
                }
                this.createComponents();
            }
        })
    }

    useManualCountStart(): boolean {
        return this.nameFormulaString && !this.config.component_type_config_map?.[this.component_type.id]?.automatic_count;
    }

    private _getNameFormulaMatch(): string {
        let matchString: string = "";
        this.selectedNameFormula.formula.forEach(part => {
            if (part.type === 'text') {
                matchString += part.value;
            }
            if (part.type === 'date' && part.content === 'JULIAN' && !this.useManualCountStart()) {
                if (this.countResetFrequency === 'yearly') {
                    matchString += `${String(part.value).substring(0, 2)}___`
                } else if (this.countResetFrequency === 'daily') {
                    matchString += `${part.value}`;
                }
            }
        })
        return matchString + '%';
    }

    private createComponents() {
        const ctrl = this;
        let $save_obs = [];

        for (let i = 1; i <= this.no_of_components; i++) {
            $save_obs.push(this.saveComponent(this.count + utils.deepCopy(i)));
        }

        concat(...$save_obs).pipe(finalize(() => {
            this.saving = false;
        }))
            .subscribe({
                next: (result) => {
                    this.progress = (this.no_of_components) + " " + this.name + " components created successfully."
                },
                error: err => {
                    this.progress = (this._countProgress() - 1) + " " + this.name + " components created successfully."
                    this.error = `An error occurred at component ${(this._countProgress())}. Please see console for details.`
                    console.log('An error occurred at component : ', this._countProgress(), err);
                }
            });
    }

    private _countProgress(): number {
        return Number(this.useManualCountStart() ? this.manualCount : this.db_component_count) - this.count;
    }

// An observable for saving the component to the db, results are push to the component list and component count is updated
    private saveComponent(count): Observable<any> {
        //Note: Do not add a catch to this obs or the concat sequence will continue after error
        let $obs = this.api[this.api_base].obsSave(this.componentObject(this.uniqueId(count), count))
            .pipe(map((result: SingleResponse<WireComponent>) => {
                    this.db_component_count += 1;
                    this.manualCount = +this.manualCount + 1;
                    this.progress = "Saving " + this.name + " components: " + this._countProgress();
                    this.components.push(result.data);
                    return result;
                }),
                first(), takeUntil(this.onDestroy));
        return $obs;
    }

    public setJulianDay(event) {
        if (event) {
            this.db_component_count = null;
            this.selected_date = event;
            this.selected_julian = utils.julianDate(event);
            if (this.selectedNameFormula) {
                this.setNameFormulaString();
            }
        }
    }

//The component object that will be saved to the db
    private componentObject(id, count?) {
        let component = {
            id: null,
            type: this.api_base,
            relationships: {
                component_type: {data: {id: this.component_type.id, type: 'component_type'}}
            },
            attributes: {
                name: id,
                base_type: this.api_base,
                custom_constants: {
                    'Product_Type': this.batch_type,
                    'Production_Day': this.selected_date.toISOString(),
                    'Product_Day_Count': count,
                    'Product_Batch_Size': this.batch_size
                }

            }
        }
        return component;
    }

//Gets the very custom uniqueId value for the component's 'unique_value' custom constant
    private uniqueId(count) {
        let uid: string;
        if (!this.nameFormulaString) {
            uid = `${this.selected_julian}_${count}_${this.batch_type}_${this.batch_size}`;
            if (this.prefix) {
                uid = `${this.prefix}_${uid}`;
            }
            return uid;
        }
        return `${this.nameFormulaString}${count.toString().padStart(this.countFormat, '0')}`
    }

    setNameFormulaString(): void {
        if (!this.selectedNameFormula?.formula) return;
        let t = this.nameBuilder.getInitialFormula(this.selectedNameFormula.formula);
        t = this.nameBuilder.updateDateValue(t, this.selected_date);
        this.nameFormulaString = (this.prefix || '') + this.nameBuilder.getNameString(t, {});

        const nameMatchArray = this.selectedNameFormula.formula.filter(formulaPart => formulaPart.type !== 'date');
        this.nameFormulaMatch = (this.prefix || '') + this.nameBuilder.getNameString(nameMatchArray, {});
    }

    private setBatchType(): string {
        if (!(this.config.component_type_config_map?.[this.component_type.id]?.abbr)) {
            return this.batch_type;
        }
        if (this.config.component_type_config_map[this.component_type.id]?.abbr) {
            return this.config.component_type_config_map[this.component_type.id].abbr;
        }
    }

    private setBatchSize(): number {
        if (!(this.config.component_type_config_map && this.config.component_type_config_map[this.component_type.id]?.size)) {
            return this.batch_size;
        }
        if (this.config.component_type_config_map[this.component_type.id]?.size) {
            return this.config.component_type_config_map[this.component_type.id].size;
        }
    }

    public seeCount() {
        this.getCount(false).pipe(first(), takeUntil(this.onDestroy)).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
