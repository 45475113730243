<div class="form-dialog">
  <h1>Estimate*
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true" style="min-width:800px;">
      <mat-tab label="Basic">
        <form class="form-section column">
          <ng-container *ngIf="estimate">
            <div class="row">
              <div class="select-search" style="width:100%;">
                <select-search (selectChange)="estimateTypeSelected()"
                               [(value)]="selectedEstimateType"
                               [data]="estimateTypes"
                               [disabled]="estimate && estimate.hasOwnProperty('id')"
                               [label]="'Estimate Type'"
                >
                </select-search>

              </div>

              <div class="select-search" style="width:100%;">
                <select-search-component [label]="'Series'"
                                         [api_model]="'series_light'"
                                         [(value)]="selectedSeries"
                                         (selectionChange)="selectedSeries=$event.value;seriesSelected()"
                                         [disabled]="estimate.hasOwnProperty('id')"
                                         [classes]="'fill'"
                >
                </select-search-component>
              </div>
            </div>

            <div class="row">
              <mat-form-field>
                <mat-label>Name*</mat-label>

                <input (change)="nameChanged()"
                       [(ngModel)]="estimate.attributes.name"
                       matInput
                       name="name">
              </mat-form-field>

              <mat-form-field>
                <mat-label>Description</mat-label>

                <input (change)="descriptionChanged()"
                       [(ngModel)]="estimate.attributes.description"
                       matInput
                       name="description"/>

              </mat-form-field>

            </div>

            <div class="row">
              <mat-form-field>
                <mat-label>Fill Method</mat-label>

                <mat-select
                    (selectionChange)="fillMethodChange()"
                    *ngIf="fillMethods.length > 0"
                    [(ngModel)]="estimate.attributes.fill_method"
                    name="fillMethod">
                  <mat-option *ngFor="let fillMethod of fillMethods" [title]="seriesData.fill_methods[fillMethod]"
                              [value]="fillMethod"
                  >{{fillMethod}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Sample Period</mat-label>

                <mat-select (selectionChange)="samplePeriodChanged()"
                            *ngIf="samplePeriods.length > 0"
                            [(ngModel)]="estimate.attributes.sample_period"
                            name="samplePeriod">
                  <mat-option *ngFor="let samplePeriod of samplePeriods"
                              [value]="samplePeriod">{{samplePeriod | titlecase | removeUnderscore}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Aggregation Type</mat-label>
                <mat-select (selectionChange)="aggregationTypeChanged()"
                            *ngIf="aggregationTypes.length > 0"
                            [(ngModel)]="estimate.attributes.aggregation"
                            name="aggregationType">
                  <mat-option *ngFor="let aggregationType of aggregationTypes"
                              [title]="seriesData.aggregation_definitions[aggregationType]"
                              [value]="aggregationType">{{aggregationType | titlecase| removeUnderscore}}</mat-option>
                </mat-select>

              </mat-form-field>

            </div>
            <div class="row">

              <mat-form-field style="max-width:150px ">
                <mat-label>Variance favourability</mat-label>

                <mat-select [(value)]="estimate.attributes.positive_variance"
                            title="Defines the favourability of a variance between target (estimate)  and actual values">
                  <mat-option [value]="true"
                              title="A positive difference between actual and target (estimate) values is favourable">Positive
                    variance
                  </mat-option>
                  <mat-option [value]="false"
                              title="A negative difference between actual and  target (estimate) values is favourable">Negative
                    variance
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </ng-container>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-save" (click)="save()">Save</button>
    <button class="btn-close" (click)="close()">Close</button>
  </div>
</div>
