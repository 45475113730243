import {BaseViewModel} from "./base-viewmodel";
import {TextViewModel} from "./text-viewmodel";
import {ImageViewModel} from "./image-viewmodel";
import {CustomChartViewModel} from "./custom-chart-viewmodel";
import {ContextViewModel} from "./context-viewmodel";
import {OreBodyGroupViewModel} from "./ore-body-group-viewmodel";
import {ConnectorViewModel} from "./connector-viewmodel";
import {SeriesSummaryViewModel} from './series-summary-viewmodel';
import {ModelID} from '../../../_typing/generic-types';

export class ProcessViewModel extends BaseViewModel {
    is_parent: boolean;
    connectorDataModel: any;
    //Children of process
    text: any;
    connectors: any[];
    image: any;
    //Children of parent_process
    images: any[];
    custom_charts: any[];
    ore_body_groups: any[];
    contexts: any[];

    private defaultProcessWidth = 175;
    private defaultProcessHeight = 100;
    private defaultX = 50;
    private defaultY = 50;
    private defaultPercent = 0;

    constructor(processDataModel, is_parent: boolean = false, connectorDataModel: any = null) {
        super(processDataModel);
        this.is_parent = is_parent;

        if (this.data == null) {
            this.data = {};
        }
        if (this.data.attributes == null) {
            this.data.attributes = {};
        }
        if (this.data.attributes.json == null) {
            this.data.attributes.json = {
                x: this.defaultX, y: this.defaultY,
                width: this.defaultProcessWidth, height: this.defaultProcessHeight,
                text: {x: this.defaultProcessWidth / 2, y: 12},
                connectors: [{
                    name: 'Output', id: 0,
                    percent: 100 * (this.defaultProcessWidth + 0.5 * this.defaultProcessHeight) /
                        (2 * this.defaultProcessWidth + 2 * this.defaultProcessHeight)
                }, {
                    name: 'Input', id: 1,
                    percent: 100 * (2 * this.defaultProcessWidth + 1.5 * this.defaultProcessHeight) /
                        (2 * this.defaultProcessWidth + 2 * this.defaultProcessHeight)
                }],
                pages: []
            };
        }
        if (!this.data.attributes.json.width || this.data.attributes.json.width < 0) {
            this.data.attributes.json.width = this.defaultProcessWidth;
        }
        if (!this.data.attributes.json.height || this.data.attributes.json.height < 0) {
            this.data.attributes.json.height = this.defaultProcessHeight;
        }

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }
        if (!this.data.attributes.json['text'].x) {
            this.data.attributes.json['text'].x = this.defaultProcessHeight / 2;
        }
        if (!this.data.attributes.json['text'].y) {
            this.data.attributes.json['text'].y = 12;
        }
        this.data.attributes.json['text'].rotate = 0;

        if (is_parent) {
            if (this.data.attributes.json.charts) {
                this.custom_charts = this.createCustomChartViewModels(this.data.attributes.json.charts, this.data);
            } else {
                this.custom_charts = [];
            }
            if (this.data.attributes.json.ore_body_groups) {
                this.ore_body_groups = this.createOreBodyGroupViewModels(this.data.attributes.json.ore_body_groups, this.data);
            } else {
                this.ore_body_groups = [];
            }
            if (this.data.attributes.json.contexts) {
                this.contexts = this.createContextViewModels(this.data.attributes.json.contexts, this.data);
            } else {
                this.contexts = [];
            }
            if (this.data.attributes.json.images) {
                this.images = this.createImageViewModels(this.data.attributes.json.images, this);
            }
        } else {
            if (this.data.attributes.json.image) {
                this.image = new ImageViewModel(this.data.attributes.json.image, this);
            } else {
                //Backward compatibility - convert old icons into editable images
                let image = this.createImageFromIcon(this);
                this.data.attributes.json.image = image;
                this.image = new ImageViewModel(image, this);
            }
        }

        if (!this.data.attributes.json.pages) {
            this.data.attributes.json.pages = [];
        }
        this.text = new TextViewModel(this.data.attributes.json.text);
        this.connectors = this.createConnectorViewModels(connectorDataModel, this, is_parent);
    }

    createImageFromIcon(parent) {
        let image = {};
        image['width'] = parent.data.attributes.json.pad_image ? 200 : 80;
        image['height'] = parent.data.attributes.json.pad_image ? 200 : 80;
        image['x'] = parent.data.attributes.json.pad_image ? parent.x() + (parent.width() / 2 - 100) : parent.x() + (parent.width() / 2 - 40);
        image['y'] = parent.data.attributes.json.pad_image ? parent.y() + (parent.height() / 2 - 94) : parent.y() + (parent.height() / 2 - 34);
        image['src'] = parent.data.attributes.icon;
        image['constrain_proportions'] = true;
        image['type'] = 'image';
        image['title'] = '';
        return image;
    }

    public name() {
        return this.data.attributes.name || "";
    };

    x() {
        if (this.is_parent === true) {
            return 0;
        }
        return this.data.attributes.json.x;
    };

    y() {
        if (this.is_parent === true) {
            return 0
        }
        return this.data.attributes.json.y;
    };

    width() {
        if (this.is_parent === true) {
            return parseFloat(this.data.attributes.json.windowWidth)
        }
        return parseFloat(this.data.attributes.json.width);
    };

    height() {
        if (this.is_parent === true) {
            return parseFloat(this.data.attributes.json.windowHeight)
        }
        return parseFloat(this.data.attributes.json.height);
    };

    fontsize() {
        return parseFloat(this.data.attributes.json.fontsize);
    };

    perimeter() {
        return this.width() * 2 + this.height() * 2;
    };

    //Overrides class viewModel
    select() {
        //this._selected = true;
        super.select()
        if (this.image) {
            this.image.select();
        }
    };

    pagelinks(): { id: ModelID, title: string }[] {
        return this.data.attributes.json.pages;
    }

    // Create view model for a list of data models.
    createConnectorViewModels(connectorsDataModel, process, is_parent) {
        var viewModels = [];
        if (connectorsDataModel) {
            for (var i = 0; i < connectorsDataModel.length; ++i) {
                for (var c = 0; c < process.data.relationships.connectors.data.length; ++c) {
                    if (connectorsDataModel[i]?.id === process.data.relationships.connectors.data[c].id) {
                        var connectorViewModel = new ConnectorViewModel(connectorsDataModel[i], process, is_parent);
                        viewModels.push(connectorViewModel);
                    }
                }
            }
        }

        return viewModels;
    };

    // Create view model for a list of charts.
    createImageViewModels(imagesDataModel, process) {
        var viewModels = [];
        if (imagesDataModel) {
            for (var i = 0; i < imagesDataModel.length; ++i) {
                if (imagesDataModel[i]) {
                    var imageViewModel = new ImageViewModel(imagesDataModel[i], process, i);
                    viewModels.push(imageViewModel);
                }
            }
        }
        return viewModels;
    };

    // Create view model for a list of charts.
    createCustomChartViewModels(customChartsDataModel, process) {
        var viewModels = [];
        if (customChartsDataModel) {
            for (var i = 0; i < customChartsDataModel.length; ++i) {
                if (customChartsDataModel[i]) {
                    var customChartViewModel = new CustomChartViewModel(customChartsDataModel[i], process, i);
                    viewModels.push(customChartViewModel);
                }
            }
        }

        return viewModels;
    };

    // Create view model for a list of ore_body_groups.
    createOreBodyGroupViewModels(oreBodyGroupsDataModel, process) {
        var viewModels = [];
        if (oreBodyGroupsDataModel) {
            for (var i = 0; i < oreBodyGroupsDataModel.length; ++i) {
                if (oreBodyGroupsDataModel[i]) {
                    var oreBodyGroupViewModel = new OreBodyGroupViewModel(oreBodyGroupsDataModel[i], process, i);
                    viewModels.push(oreBodyGroupViewModel);
                }
            }
        }

        return viewModels;
    };

    // Create view model for a list of context tiles.
    createContextViewModels(contextsDataModel, process) {
        var viewModels = [];
        if (contextsDataModel) {
            for (var i = 0; i < contextsDataModel.length; ++i) {
                if (contextsDataModel[i]?.type === 'series-summary') {
                    var seriesSummaryViewModel = new SeriesSummaryViewModel(contextsDataModel[i], process, i);
                    viewModels.push(seriesSummaryViewModel);
                } else if (contextsDataModel[i]) {
                    var contextViewModel = new ContextViewModel(contextsDataModel[i], process, i);
                    viewModels.push(contextViewModel);
                }
            }
        }

        return viewModels;
    };
};
