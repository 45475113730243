import {Injectable} from '@angular/core';
import {Observable, of, Subject, Subscription, from} from "rxjs";
import {debounceTime, takeUntil, tap, map, take, concatMap, first} from "rxjs/operators";
import * as d3 from "d3";
import * as utils from "../lib/utils";
import {TileDataService} from "./tile_data.service";
import {EventService} from "./event.service";

import {ApiService} from './api/api.service';
import {ChartAPI} from "c3";
import {AppScope} from './app_scope.service';
import {HeaderDataService} from './header_data.service';
import {IDateTimePeriod} from "../_typing/date-time-period";

@Injectable({
    providedIn: 'root'
})
export class ChartEventsService {
    //These must be set by the chart component using them so that they share data/functions
    public tileData: TileDataService;
    public eventService: EventService;
    rendered_chart: ChartAPI; //A reference to the rendered chart created by c3.generate(ctrl.chart_config)
    points_set: Set<string> = new Set<string>(); // keep track of points clicked, does not take into account what series was clicked
    eventStart: any;
    eventEnd: any;
    eventSeries: any;
    points_selected: any[] = [];
    private readonly onDestroy = new Subject<void>();
    public dtp: IDateTimePeriod;

    $addCommentClicked: Subscription | null;
    $commentHover: Subscription | null;
    $commentLeave: Subscription | null;

    constructor(
        private api: ApiService,
        private appScope: AppScope,
        private headerData: HeaderDataService) {
        const ctrl = this;
        //tileData and eventService are set to the services provided inside the chart component
        //dtp is set after dtp.resolved in chart component
    }

    addListenersForComments(): void {
        const ctrl = this;
        this.$addCommentClicked = utils.refreshSubscription(this.$addCommentClicked);
        this.$addCommentClicked = this.tileData.addCommentClicked.pipe(
            concatMap(value => this.saveComment(value)),
            takeUntil(ctrl.onDestroy))
            .subscribe();

        this.$commentHover = utils.refreshSubscription(this.$commentHover);
        this.$commentHover = this.tileData.commentHover.pipe(takeUntil(ctrl.onDestroy), debounceTime(50)).subscribe(value =>
            this.commentHover(value));

        this.$commentLeave = utils.refreshSubscription(this.$commentLeave);
        this.$commentLeave = this.tileData.commentLeave.pipe(takeUntil(ctrl.onDestroy), debounceTime(50)).subscribe(value =>
            this.commentLeave(value));
    }

    getEvents(input_config, full_chart_series_list): Observable<any> {
        const ctrl = this;
        ctrl.tileData.events = [];
        this.tileData.show_comment_dtp = false;
        //Add the listeners once we know that the component wants to use events and tileData has been set
        ctrl.addListenersForComments();

        //TODO we might want to still load events for availability in comment panel even if they're not shown on chart
        //i.e. difference between 'hide comment lines' and don't load comments for speed
        if (input_config.hide_comments === true) {
            ctrl.tileData.events = [];
            return of(ctrl.tileData.events);
        } else {
            return ctrl.eventService.getEvents(ctrl.dtp.start, ctrl.dtp.end, full_chart_series_list).pipe(
                takeUntil(this.onDestroy),
                tap(events => {
                    ctrl.tileData.events = events ? events.data : [];
                })
            );
        }
    }

    addCommentLines(c3_config) {
        const ctrl = this;
        ctrl.tileData.events.forEach(event => {
            c3_config.grid.x.lines.push({
                value: new Date(event.attributes.start),
                text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(event)) + " start",
                class: 'event-start event-' + event.id + '_' + ctrl.tileData.events.indexOf(event)
            });
            c3_config.grid.x.lines.push({
                value: new Date(event.attributes.end),
                text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(event)) + " end",
                class: 'event-end event-' + event.id + '_' + ctrl.tileData.events.indexOf(event)
            });
        });

    }

    getSelected(e: { x: string, id: string }, name_tag_map, full_chart_series_list) {
        if (!this.appScope.isNotMobile && this.eventService.show_comments.value !== true) {
            d3.selectAll('.c3-selected-circles').remove();
            return;
        }
        if (this.points_set.has(e.x)) {
            this.points_set.delete(e.x);
            this.rendered_chart.regions([]);
        } else {
            // reset
            if (this.points_set.size >= 2) {
                this.points_set = new Set<string>();
                this.rendered_chart.regions([]);
                d3.selectAll('.c3-selected-circles').remove();
            }
            this.points_set.add(e.x);
        }

        let dates = Array.from(this.points_set);
        dates = dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        // the id is the description of the series
        this.addRegion(dates, name_tag_map[e.id], full_chart_series_list);
    }

    addRegion(dates: string[], series_name: string, full_chart_series_list) {
        let ctrl = this;
        if (dates.length > 0) {
            if (new Date(ctrl.eventStart) !== new Date(dates[0])) {
                // @ts-ignore
                ctrl.rendered_chart.xgrids.remove([{class: 'start-line'}]);
            }
            if (new Date(ctrl.eventEnd) !== new Date(dates[dates.length - 1])) {
                // @ts-ignore
                ctrl.rendered_chart.xgrids.remove([{class: 'end-line'}]);
            }
            ctrl.eventStart = utils.deepCopy(dates[0]);
            ctrl.eventEnd = utils.deepCopy(dates[dates.length - 1]);
            ctrl.tileData.comment.start = utils.deepCopy(dates[0]);
            ctrl.tileData.comment.end = utils.deepCopy(dates[dates.length - 1]);

            ctrl.eventSeries = [];
            full_chart_series_list.forEach(series => {
                // for now ignoring if multiple series was selected
                if (series.attributes.name == series_name) {
                    ctrl.eventSeries = [series];
                }
            });
            this.eventService.series_list = ctrl.eventSeries;

            setTimeout(() => {
                //checking dates.length prevents start and end line being added on top of each other
                if (dates.length === 1) {
                    ctrl.rendered_chart.xgrids.add([
                        {value: new Date(ctrl.eventStart), text: 'Event start', class: 'start-line'}
                    ]);
                }
                if (dates.length === 2) {
                    ctrl.rendered_chart.xgrids.add([
                        {value: new Date(ctrl.eventStart), text: 'Event start', class: 'start-line'},
                        {value: new Date(ctrl.eventEnd), text: 'Event end', class: 'end-line'}
                    ]);
                    ctrl.rendered_chart.regions([{
                        axis: 'x',
                        start: ctrl.eventStart,
                        end: ctrl.eventEnd,
                        class: 'event-region'
                    }]);

                    this.headerData.toggleCommentPanel(true, {
                        tileData: this.tileData,
                        eventService: this.eventService
                    });
                    this.eventService.setShowComments(true); //This one is for styling using observable
                }
            }, 400);


        } else {
            //@ts-ignore
            ctrl.rendered_chart.xgrids.remove([{class: 'start-line'}]);
        }
    }

    commentHover(input) {
        let event = input.event;
        let index = input.index;
        let lines = document.getElementsByClassName('event-' + event.id + '_' + index);
        if (lines.length > 0) {
            for (let i = 0; i < lines.length; ++i) {
                lines[i].classList.add("event-line-hover");
            }
        }
    }

    commentLeave(input) {
        let event = input.event;
        let index = input.index;
        let lines = document.getElementsByClassName('event-' + event.id + '_' + index);
        if (lines.length > 0) {
            for (let i = 0; i < lines.length; ++i) {
                lines[i].classList.remove("event-line-hover");
            }
        }
    }

    resetCommentDates() {
        const ctrl = this;
        this.points_selected.forEach(point => {
            d3.select(point).classed("_selected_", false);
        });
        d3.selectAll('.c3-selected-circles').remove();

        // @ts-ignore
        ctrl.rendered_chart.xgrids.remove([{class: 'start-line'}]);
        // @ts-ignore
        ctrl.rendered_chart.xgrids.remove([{class: 'end-line'}]);
        ctrl.eventStart = null;
        ctrl.eventEnd = null;
        ctrl.rendered_chart.regions([]);

        this.points_selected = [];

        this.points_set = new Set<string>();
    }

    saveComment(comment): Observable<any> {
        const ctrl = this;

        return ctrl.eventService.addInlineComment(comment, ctrl.eventStart, ctrl.eventEnd, ctrl.eventSeries).pipe(
            first(), takeUntil(this.onDestroy),
            tap((new_comment) => {
                if (!new_comment) return;
                ctrl.tileData.events.push(new_comment.data);

                ctrl.resetCommentDates();
                setTimeout(() => {
                    ctrl.rendered_chart.xgrids.add({
                        value: new Date(new_comment.data.attributes.start),
                        text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(new_comment.data)) + " start",
                        class: 'event-start event-' + new_comment.data.id + '_' + ctrl.tileData.events.indexOf(new_comment.data)
                    });
                    ctrl.rendered_chart.xgrids.add({
                        value: new Date(new_comment.data.attributes.end),
                        text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(new_comment.data)) + " end",
                        class: 'event-end event-' + new_comment.data.id + '_' + ctrl.tileData.events.indexOf(new_comment.data)
                    });
                }, 500);
            }));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
