import {Component, Input, OnInit} from '@angular/core';
import {DateTimePeriodService} from '../../../services/date-time-period.service';
import {HeaderDataService} from '../../../services/header_data.service';
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";

@Component({
    selector: 'app-pdf-header',
    templateUrl: './pdf-header.component.html',
    styleUrls: ['./pdf-header.component.less'],
    standalone: false
})
export class PdfHeaderComponent implements OnInit {
    @Input() pdfHeader: string;

    constructor(public dateInst: DateTimeInstanceService,
                public headerData: HeaderDataService) {
    }

    ngOnInit(): void {
    }

}
