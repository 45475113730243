<div class="series-adjustment-form">
  <button (click)="addAdjustment()" mat-raised-button color="primary"
          matTooltip="Add Series" style="text-transform: uppercase;">Add Adjustment
    <i class="fa fa-plus" style="margin-left:6px;"></i>
  </button>
  <div *ngIf="config.adjustments?.length">
    <div class="draggable-list-container full-width">
      <h2>Adjustment Series <span class="error">{{error}}</span></h2>
      <div
          id="selectedList"
          #selectedList="cdkDropList"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListData]="config.series"
          cdkDropList
          class="draggable-list adjustment-series-list">

      <div *ngFor="let adjustment of config.adjustments; let i=index" cdkDrag [cdkDragData]="adjustment">
        <div class="adjustment-series-container">
          <select-search-component (selectionChange)="targetSelected($event, adjustment);"
                                   [classes]="'fill'"
                                   [api_model]="'series_light'"
                                   [value]="adjustment.target_series"
                                   [custom_filters]="targetFilters"
                                   class="select-search"
                                   label="Calculation to reconcile">
          </select-search-component>
          <select-search-component (selectionChange)="adjustSelected($event, adjustment, i);"
                                   [classes]="'fill'"
                                   [api_model]="'series_light'"
                                   [value]="adjustment.adjust"
                                   [compareFunctionName]="'compareById'"
                                   [custom_filters]="adjustFilters"
                                   class="select-search"
                                   label="Series to be adjusted">
          </select-search-component>

          <div>
            <mat-form-field>
              <mat-label>Correction Type</mat-label>
              <mat-select [(ngModel)]="adjustment.adjustment_type">
                <mat-option *ngFor="let type of seriesAdjustmentTypeArray"
                            [value]="type">{{type | snakeCaseToTitleCase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-element-align">
            <button class="text-btn" (click)="removeAdjustment(i);" matTooltip="Remove series adjustment"
                    style="border:none;">
              <span class="material-symbols-outlined" style="cursor:pointer;">close</span>
            </button>
          </div>

        </div>


    </div>
  </div>
</div>
