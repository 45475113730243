<mat-toolbar *ngIf="appScope.isAuthenticated$ | async" class="header-toolbar">
  <div class="brand">
    <div class="header-left">
      <button (click)="appScope.toggleSideNav();"
              class="sidenav-closed" mat-button>
        <i class="fa fa-bars"></i>
      </button>
      <div style="height:100%;">
        <a [routerLink]="accountService.default_url" matTooltip="Home page"><img [src]="loginImgSrc" alt="login"/></a>
      </div>
      <account-picker class="account-picker"></account-picker>
    </div>
    <div class="header-center">
      <h1 class="page-title-header">{{headerData.titleSubject | async}}</h1>
    </div>
    <div class="right-mat-menu header-right">
      <button *ngIf="headerData.show_dtp" class="toggle-dtp" mat-button>
        <i (click)="headerData.dtp_visible=!headerData.dtp_visible" class="fa fa-clock"></i>
      </button>
      <button *ngIf=" appScope.current_user && appScope.isNotMobile" [matMenuTriggerFor]="right_mat_menu"
              class="user-menu combo" mat-button>
        {{appScope.current_user.name}}
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
      </button>
      <button *ngIf="appScope.current_user && !appScope.isNotMobile" [matMenuTriggerFor]="right_mat_menu"
              mat-button><i class="fas fa-user"></i></button>
      <mat-menu #right_mat_menu="matMenu" backdropClass="header-menu-backdrop" class="header-menu tree-node-menu dark">
        <ng-container *ngFor="let item of right_menu.items">
          <a (click)="item.func()"
             *ngIf="item.func && !item.link && item.name" mat-menu-item>{{item.name}}</a>
          <a *ngIf="item.angular_page && item.link && item.name"
             [routerLink]="item.link" mat-menu-item>{{item.name}}</a>
          <a *ngIf="!item.angular_page && item.link && item.name && item.name !=='Logout'"
             [href]="item.link" mat-menu-item target="{{item.name==='My Account' ? '_self':'_blank'}}">{{item.name}}</a>
          <div *ngIf="!item.link && !item.name"
               style="pointer-events: none; cursor: default; padding: 0 6px">
            <hr [className]="item.style_class" style="border: 0; height: 1px; background-color: #dbd3d2"/>
          </div>
        </ng-container>
      </mat-menu>
      <img [src]='rightLogoSrc' class="client_logo"/>
    </div>
  </div>
</mat-toolbar>
