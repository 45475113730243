<div [appLdsRoller]="loading_next">
  <div class="row component-component-table" [ngClass]="{'table-min': link_only}"
       style="justify-content: space-between;">
    <div *ngIf="componentDataSource">
      <utils-bar [search]="true"
                 (searchValueSubmitted)="filter_string=$event;updateSearchFilter()"
                 (searchCleared)="filter_string='';updateSearchFilter();">
      </utils-bar>
    </div>

    <mat-paginator [pageSizeOptions]="page_size_options"
                   style="justify-self: flex-end;">
    </mat-paginator>

  </div>
  <!--  <div *ngIf="components?.length<1">No data</div>-->
  <table *ngIf="components && components.length > 0" (matSortChange)="updateSort($event)"
         [dataSource]="componentDataSource" [matSortActive]="component_columns[1]"
         class="table inner-component-events-table" mat-table matSort>
    <ng-container *ngFor="let col of component_columns;">

      <ng-container *ngIf="col==='Unlink'" matColumnDef="{{col}}">
        <th *matHeaderCellDef mat-header-cell [disabled]>
          {{col | upperfirst | removeUnderscore}}
        </th>
        <td *matCellDef="let component;" mat-cell
            [matTooltipDisabled]="!eventsService.event_state[component.id]"
            [matTooltip]="eventsService.event_state[component.id]? eventsService.event_state[component.id].message : ''"
            [ngClass]="eventsService.event_state[component.id]? eventsService.event_state[component.id].state: ''">
          <button mat-button (click)="unlinkComponent(component)" class="unlink-component-btn table-button"
                  [matTooltip]="'Remove this ' + selected_component_relationship_type?.attributes.name + ' from ' + parent_component?.attributes.name"
                  matTooltipPosition="above"
                  [disabled]="!canUnlink(component.id)">
            <i class="fas fa-unlink"></i>
          </button>
        </td>
      </ng-container>

      <!--Name-->
      <ng-container *ngIf="col==='name'" matColumnDef="{{col}}">
        <th *matHeaderCellDef mat-header-cell mat-sort-header [style.width]="format_dict[col].width + 'px'">
          {{selected_cols_component_dict[col].title || col | upperfirst | removeUnderscore}}
          <div (mousedown)="tableUtils.startDrag($event, col, format_dict)"
               *ngIf="tableUtils.editing && format_dict[col].allow_resize===true" class="resize-col">
            <i class="fas fa-grip-vertical"></i>
          </div>
        </th>
        <td *matCellDef="let component;" [matTooltip]="component.attributes.description" mat-cell
            [ngClass]="eventsService.event_state[component.id]? eventsService.event_state[component.id].state: ''"
            [ngStyle]="format_dict[col].style" class="attribute-field">
          <input (change)="saveComponent(component, col, $event)"
                 [(ngModel)]="component.attributes.name"
                 [disabled]="selected_cols_component_dict[col].disabled || link_only" style="padding-top:2px;">
        </td>
      </ng-container>

      <!--Start, End-->
      <ng-container *ngIf="extra_cols && extra_cols.includes(col) && col !=='name'" matColumnDef="{{col}}">
        <th *matHeaderCellDef mat-header-cell [style.width]="format_dict[col].width + 'px'"
            mat-sort-header>{{selected_cols_component_dict[col].title || col | upperfirst | removeUnderscore}}
          <div (mousedown)="tableUtils.startDrag($event, col, format_dict)"
               *ngIf="tableUtils.editing && format_dict[col].allow_resize===true" class="resize-col">
            <i class="fas fa-grip-vertical"></i>
          </div>
        </th>
        <td *matCellDef="let component;" mat-cell style="text-align: left;"
            [ngClass]="eventsService.event_state[component.id]? eventsService.event_state[component.id].state: ''"
            [ngStyle]="format_dict[col].style">
          <div *ngIf="col==='start_time'" class="row center">
            <date-time-picker
                (date_changed)="component.attributes.start_time = $event;saveComponent(component, col, $event)"
                [disabled]="selected_cols_component_dict[col].disabled"
                [input_date]="component.attributes.start_time">
            </date-time-picker>
          </div>
          <div *ngIf="col==='end_time'" class="row center">
            <date-time-picker
                (date_changed)="component.attributes.end_time = $event;saveComponent(component, col, $event)"
                [disabled]="selected_cols_component_dict[col].disabled"
                [input_date]="component.attributes.end_time">
            </date-time-picker>
          </div>
        </td>
      </ng-container>

      <!--Constant Properties-->
      <ng-container *ngIf="property_columns.includes(col) && !extra_cols.includes(col)" matColumnDef="{{col}}">
        <th *matHeaderCellDef mat-header-cell [style.width]="format_dict[col].width + 'px'"
        >{{selected_cols_component_dict[col].title || cp_dict[col]?.attributes.name | upperfirst | removeUnderscore}}
          <div (mousedown)="tableUtils.startDrag($event, col, format_dict)"
               *ngIf="tableUtils.editing && format_dict[col].allow_resize===true" class="resize-col">
            <i class="fas fa-grip-vertical"></i>
          </div>
        </th>
        <td *matCellDef="let component" class="input-cell" mat-cell style="text-align: left;"
            [matTooltipDisabled]="!eventsService.event_state[component.id]"
            [matTooltip]="eventsService.event_state[component.id]? eventsService.event_state[component.id].message : ''"
            [ngClass]="eventsService.event_state[component.id]? eventsService.event_state[component.id].state: ''"
            [ngStyle]="format_dict[col].style">
          <ng-container *ngIf="component_constants[component.id]?.hasOwnProperty(col)">
            <generic-constant-field [constant]="component_constants[component.id][col]"
                                    [constant_property]="cp_dict[col]"
                                    (constant_changed)="component_constants[component.id][col].value = $event;"
                                    (change_complete)="saveConstant(component, col, $event)"
                                    [disabled]="cp_dict[col].attributes.is_calculation || selected_cols_component_dict[col]?.disabled"
                                    [formats]="selected_cols_component_dict[col]?.format"
                                    class="constant-field"
            ></generic-constant-field>
          </ng-container>
        </td>
      </ng-container>

    </ng-container>
    <tr *matHeaderRowDef="component_columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let component; columns: component_columns;" mat-row tabindex="1"></tr>
  </table>
</div>
<div *ngIf="!loading_next && (!components || components?.length < 1)"
     class="inner-component-events-table-status">{{event_status}}
</div>
