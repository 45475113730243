import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {HandleError} from "../services/http-error-handler.service";

@Component({
    template: '',
    standalone: false
})
export abstract class BaseComponent implements OnInit, OnDestroy {
    protected readonly onDestroy = new Subject<void>();
    protected handleError: HandleError;
    @Input() panelClass: string = '';

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
