import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {MenuTreeService} from "../../services/menu-tree.service";
import {concatMap, filter, first, takeUntil, tap, switchMap, withLatestFrom} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, forkJoin, of, Subject, Subscription, timer} from 'rxjs';
import * as utils from "../../lib/utils";
import {deepCopy} from "../../lib/utils";
import {GridItem, Section, TileDataService} from "../../services/tile_data.service";
import {PageViewFormComponent} from "../../forms/page-view-form/page-view-form.component";
import {PageTileFormComponent} from "../../forms/page-tile-form/page-tile-form";
import {DisplayGrid, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType} from 'angular-gridster2';
import {FormDialogService} from "../../services/form-dialog.service";
import {SessionState} from "../../_models/session-state";
import {EventService} from "../../services/event.service";
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Tile as TileModel} from "../../_models/tile";
import {
    UserGroupPageFormDialogComponent
} from "../../forms/user-group-page-form-dialog/user-group-page-form-dialog.component";
import {PageDataService} from "../../data/page-data.service";
import {ModelID, KeyMap} from '../../_typing/generic-types';
import {NotificationService} from "../../services/notification.service";
import {TimezoneSelectorService} from "../../services/timezone-selector.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";

@Component({
    selector: 'page-view',
    templateUrl: './page-view.component.html',
    styleUrls: ['./page-view.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PageViewComponent implements OnInit, OnDestroy {
    @ViewChild("page_sizer") page_sizer: ElementRef;
    @ViewChild("gridster_parent") gridster_parent: GridsterConfig;
    utils = utils;
    session: SessionState = null;
    tiles: TileModel[];
    tile_dict: KeyMap<TileModel> = {};
    header_tiles_dict: KeyMap<string> = {};
    print_ready = false;
    editing: boolean = false;
    show_grid: boolean = false;
    show_layout: boolean = false;
    // TODO cull old code
    printPageHeight: string;
    printing_sections: boolean = false; // This is the mode for viewing the print section buttons
    printing: boolean = false; // This is used to assign a temporary class to each section to show/hide during print mode
    loading_printview: boolean = false; // Used to show app-loading lds-roller while formatting for print

    countDown: Subscription;
    counter: number = 3600; // 1h default
    tick: number = 1000;
    refresh_paused: boolean = false;

    options: GridsterConfig = {};
    subOptions: GridsterConfig = {};
    dashboard: Array<Section>;
    clipboard: any;
    fixed_row_height: number = 50;
    loaderProgress$ = new BehaviorSubject(0);
    serviceDeskEmail: string = 'servicedesk@metalmanagementsolutions.com';
    public refreshId: ModelID;

    @ViewChildren("tile_gridster_item") tile_items: QueryList<any>;
    private readonly onDestroy = new Subject<void>();

    constructor(public api: ApiService,
                private route: ActivatedRoute,
                public headerData: HeaderDataService,
                public tileData: TileDataService,
                public appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,
                public dateInst: DateTimeInstanceService,
                public dialog: MatDialog,
                private router: Router,
                private menuTreeService: MenuTreeService,
                private notification: NotificationService,
                private renderer: Renderer2,
                private formDialogService: FormDialogService,
                private loader: LoadingBarService,
                private eventService: EventService,
                private pageDataService: PageDataService,
                private timezoneService: TimezoneSelectorService) {

    }

    get json() {
        if (this.session) {
            return this.session.attributes.json;
        }
    }

    set json(value: any) {
        this.session.attributes.json = value;
    }

    get sections(): Section[] { // this function runs 1000 times, find better way?
        try {
            if (this.session) {
                return this.session.attributes.json.sections;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    ngOnInit(): void {
        this.loader.value$.pipe(takeUntil(this.onDestroy)).subscribe(progress => {
            this.loaderProgress$.next(progress);
        });

        const ctrl = this;

        this.headerData.refreshTileSubject.pipe(switchMap(id => {
            this.refreshId = id;
            return timer(100).pipe(tap(() => {
                this.refreshId = null;
            }))
        }), takeUntil(this.onDestroy)).subscribe();

        this.pageDataService.pageSectionsUpdated.pipe(takeUntil(this.onDestroy))
            .subscribe((sections: Section[]) => {
                this.session.attributes.json.sections = sections;
                this.setupSections();
            })

        this.tileData.addCommentClicked
            .pipe(
                filter((value) => this.headerData.page_selected === true),
                takeUntil(this.onDestroy))
            .subscribe(value => {
                this.saveComment(value);
            });
        this.headerData.toggleGridMode.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.show_grid = value;
        });
        this.headerData.toggleLayoutMode.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.show_layout = value;
        });
        this.headerData.pageCommentButtonClicked.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.toggleCommentPanel(value);
        });

        this.headerData.showingCommentPanel.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            // Used for styling the comment button to show which component is currently selected for commenting
            if (value.comment_data && value.comment_data.tileData && value.comment_data === this) {
                this.tileData.selected = true;
                this.headerData.page_selected = true;
                if (value.toggle === true) {
                    this.setupComments();
                }
            } else {
                this.tileData.selected = false;
                this.headerData.page_selected = false;
            }
        });

        this.dateInst.dateTimePeriodRefreshed$.pipe(
            filter(value => this.headerData.page_selected === true && this.headerData.showing_comments === true),
            takeUntil(this.onDestroy)
        ).subscribe(() => {
            this.setupComments();
        })
        this.headerData.printToPdfClicked.pipe(takeUntil(this.onDestroy)).subscribe(() => this.printToPdf());

        combineLatest([this.dateTimePeriodService.dtpInitialised$,
            ctrl.route.params]).pipe(
            switchMap(([dtp, params]) => {
                ctrl.headerData.add_new_page_form = true;

                this.print_ready = false;
                if (this.countDown) {
                    // counter needs to be manually unsubscribed when browsing to another dashboard as ngDestroy is not called.
                    this.countDown.unsubscribe();
                }
                // this is repeated, cause otherwise the array is empty when going to another page
                ctrl.headerData.add_new_page_form = true;
                ctrl.headerData.show_dtp = true;
                ctrl.headerData.add_present = true;

                const $session_state = ctrl.api.session_state.getById(params.sessionID).pipe(
                    tap(response => {
                        ctrl.session = response.data;
                    }),
                );

                return $session_state.pipe(
                    takeUntil(this.onDestroy),
                    tap(() => {
                        if (!this.session.attributes.json || !this.session.attributes.json.sections || !Array.isArray(this.session.attributes.json.sections)) {
                            this.session.attributes.json = {"sections": [{"tiles": []}]};
                        }
                        ctrl.toggleEditMode(null, false);
                        // TODO add this to migration script when updating to gridstack!!
                        if (!ctrl.session.attributes.json.calendar) {
                            ctrl.session.attributes.json.calendar = ctrl.dateTimePeriodService.defaultCalendar;
                        }
                        if (this.dateTimePeriodService.read_dtp_from_url === true) {
                            // Make the next page_view use their respective default dtp settings
                            this.dateTimePeriodService.read_dtp_from_url = null;
                        } else {
                            let dtp: IDateTimePeriod;
                            if (ctrl.session.attributes.range) {
                                dtp =
                                    ctrl.dateTimePeriodService.getDTP(ctrl.session.attributes.range, false,
                                        ctrl.session.attributes.json.calendar);
                            } else {
                                dtp = ctrl.dateTimePeriodService.setDefault(ctrl.dateInst.dtp);
                            }

                            if (ctrl.session.attributes.json.sample_period) {
                                dtp.sample_period =
                                    deepCopy(ctrl.dateTimePeriodService.sample_dict[ctrl.session.attributes.json.sample_period]);
                            } else if (this.appScope.config_name_map['default_sample_period']) {
                                const default_period = this.appScope.config_name_map['date_period'].value.default_period;
                                dtp.sample_period =
                                    deepCopy(ctrl.dateTimePeriodService.sample_hours_dict[default_period]);
                            } else {
                                dtp.sample_period = deepCopy(ctrl.dateTimePeriodService.sample_dict['hour']);
                            }
                            ctrl.dateInst.emitDateTimePeriodChanged(dtp);
                            ctrl.setupComments();
                        }
                        ctrl.setupSections();
                        setTimeout(() => {
                            ctrl.windowResized();
                        }, 100);
                        this.setCountdown();
                        ctrl.buildHeader();
                    }));
            }),
            takeUntil(this.onDestroy))
            .subscribe();
    };

    setOptions() {
        const ctrl = this;
        if (!this.session.attributes.json.grid_options) {
            this.session.attributes.json.grid_options = {};
        }
        this.windowResized();
        this.options = {
            itemChangeCallback: ctrl.itemChanged.bind(ctrl),
            gridType: GridType.VerticalFixed,
            fixedRowHeight: this.fixed_row_height,
            compactType: 'none',
            minCols: 12,
            minRows: 12,
            maxRows: 500,
            maxItemRows: 100,
            outerMarginRight: 20,
            outerMarginLeft: 20,
            outerMarginBottom: 20,
            outerMarginTop: 36,
            margin: 0,
            mobileBreakpoint: 800,
            keepFixedHeightInMobile: true,
            displayGrid: DisplayGrid.None,
            enableEmptyCellDrop: true,
            emptyCellClickCallback: ctrl.pasteTile.bind(this),
            // pushItems: false,
            scrollToNewItems: true,
            draggable: {
                delayStart: 0,
                enabled: false,
                stop: ctrl.dragResizeStop.bind(ctrl),
            },
            resizable: {
                delayStart: 0,
                enabled: false,
                handles: {
                    s: true,
                    e: true,
                    n: true,
                    w: true,
                    se: true,
                    ne: true,
                    sw: true,
                    nw: true
                },
                stop: ctrl.dragResizeStop.bind(ctrl),
            },
        };

        // Session stored options
        if (this.session.attributes.json.grid_options.max_cols && this.session.attributes.json.grid_options.max_cols > 0) {
            this.options.maxCols = this.session.attributes.json.grid_options.max_cols;
        } else {
            this.options.maxCols = 12;
        }
        // if (this.session.attributes.json.grid_options.min_cols && this.session.attributes.json.grid_options.min_cols > 0) {
        //     this.options.minCols = this.session.attributes.json.grid_options.min_cols;
        // } else {
        this.options.minCols = 12;
        // }

        // Mobile Grid Options
        this.subOptions = {
            itemChangeCallback: ctrl.itemChanged.bind(ctrl),
            gridType: GridType.Fit,
            maxCols: 1,
            minRows: 1,
            compactType: 'none',
            mobileBreakpoint: 0,
            keepFixedHeightInMobile: true,
            pushItems: true,
            draggable: {
                stop: ctrl.dragResizeStop.bind(ctrl),
                delayStart: 0,
                enabled: false
            },
            resizable: {
                stop: ctrl.dragResizeStop.bind(ctrl),
                delayStart: 0,
                enabled: false,
                handles: {
                    s: true,
                    e: true,
                    n: true,
                    w: true,
                    se: true,
                    ne: true,
                    sw: true,
                    nw: true
                }
            },
            displayGrid: DisplayGrid.None,
        };

        if (this.editing) {
            this.timezoneService.timezoneClockMin.next(true);
            this.options.displayGrid = DisplayGrid.Always;
            this.subOptions.gridType = GridType.Fit;
            this.subOptions.displayGrid = DisplayGrid.Always;
            if (this.show_layout) {
                this.options.draggable.enabled = true;
                this.options.resizable.enabled = true;
                this.subOptions.draggable.enabled = true;
                this.subOptions.resizable.enabled = true;
            } else {
                this.preventDrag();
            }
        } else {
            // These can be set by the user during edit
            this.options.pushItems = false;
            this.options.enableEmptyCellClick = false;
            this.options.compactType = "none";
            this.options.swap = false;
            // ------------------------------------
            this.options.displayGrid = DisplayGrid.None;
            this.subOptions.displayGrid = DisplayGrid.None;
            this.preventDrag();
        }
        ctrl.changedOptions();
    }

    preventDrag(): void {
        this.options.draggable.enabled = false;
        this.options.resizable.enabled = false;
        this.subOptions.draggable.enabled = false;
        this.subOptions.resizable.enabled = false;
    }

    sortSections() {
        // sorts the json so that it's Non-absolute flow (order of sections in the sections array)
        // matches the Absolute flow of the gridster page (by y then x) - so that mobile stacks in correct order
        this.dashboard.forEach(section => {
            return section.tiles.sort(function (a, b) {
                return a.y < b.y ? -1 : a.y > b.y ? 1 : 0;
            });
        });
        this.dashboard.sort(function (a, b) {
            // TODO make the read more straight forward ie. low y always ordered before higher y
            // equal ys: low x ordered before higher x
            return a.x < b.x ? (a.y <= b.y ? -1 : 1) :
                (a.x > b.x) ? (a.y < b.y ? -1 : 1) :
                    (a.y < b.y) ? -1 : (a.y > b.y) ? 1 : 0;
        });
    }

    setupSections() {
        const ctrl = this;
        this.dashboard = [];
        this.setOptions();

        this.session.attributes.json.sections = this.session.attributes.json.sections.map(section => {
            section.id = utils.guid();
            section.tiles = section.tiles.filter(tile => tile.id);
            section.tiles.map(tile => {
                tile.parent_id = section.id;
                return tile;
            });
            return section;
        });
        this.dashboard = this.sections;
        this.sortSections();
    }

    windowResized() {
        // NOTE: Changing heights here affects the print layout calculations
        if (this.page_sizer.nativeElement && this.page_sizer.nativeElement.clientWidth) {
            if (this.page_sizer.nativeElement.clientWidth > 1880) {
                this.fixed_row_height = 60;
            } else if (this.page_sizer.nativeElement.clientWidth >= 1200 && this.page_sizer.nativeElement.clientWidth <= 1880) {
                this.fixed_row_height = 50;
            } else if (this.page_sizer.nativeElement.clientWidth < 1200) {
                this.fixed_row_height = 50;
            }
        }
        this.modelChanged(this.fixed_row_height, 'fixedRowHeight', false);
    }

    changedOptions(): void {
        if (this.options.api && this.options.api.optionsChanged) {
            this.options.api.optionsChanged();
        }
    }

    modelChanged(event, prop, resize) {
        this.options[prop] = event;
        this.changedOptions();
        if (resize === true) {
            this.appScope.resizeWindowEvent();
        }
    }

    itemChanged(item, itemComponent) {
        // console.info('itemChanged', item, itemComponent);
    }

    setCountdown() {
        const ctrl = this;
        if (ctrl.countDown) {
            ctrl.countDown.unsubscribe();
        }
        if (ctrl.session.attributes.json.auto_refresh === true) {
            const refresh_period = ctrl.session.attributes.json.refresh_time ? ctrl.session.attributes.json.refresh_time * 60 : 3600;
            ctrl.counter = refresh_period;

            this.countDown = timer(0, this.tick).pipe(takeUntil(this.onDestroy)).subscribe(() => {
                if (this.refresh_paused === false && this.printing_sections === false && this.editing === false) {
                    --this.counter;
                }
                if (this.counter <= 0) {
                    this.counter = refresh_period;
                    // Uses the latest dtp, not original url or dashboard dtp (these will be the same if dtp hasn't been changed)
                    this.headerData.dtpReset.emit(this.dateInst.dtp);
                }
            });
        }
    }

    save_sections(event) {
        this.session.attributes.json.sections = event;
        this.sortSections();
        this.pageDataService.savePage(this.session).pipe(
            tap(result => {
                this.notification.openSuccess("Sections Saved. ", 3000);
            }), first()
        ).subscribe();
    }

    save_tile(event?: { tile: TileModel }) {
        // tileChange emit when tile is changed. Filters from components => page-tile => here
        const ctrl = this;
        // Force tile refresh
        if (event) {
            this.tile_dict[event.tile.id] = null;
            setTimeout(() => {
                this.tile_dict[event.tile.id] = event.tile;
            });
        }
    }

    openPageViewDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = this.session;
        this.refresh_paused = true;
        dialogConfig.panelClass = ['default-form-dialog', 'page-form-dialog'];
        const dialogRef = this.dialog.open(PageViewFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            this.refresh_paused = false;
            if (result) {
                this.session.attributes = result.attributes;
                this.session.relationships.folders = result.relationships.folders;
                this.setCountdown();
                // this.session.attributes.visibility = result.visibility;
                this.menuTreeService.refresh();
            }
        });
    }

    addTile() {
        this.openTileDialog();
    }

    openTileDialog(section = null): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            "tile": null, "dtp": this.dateInst.dtp, session_id: this.session.id,
            grid_parameters: {is_title: false}
        };
        this.refresh_paused = true;
        dialogConfig.panelClass = 'tile-form-dialog';
        const dialogRef = this.dialog.open(PageTileFormComponent, dialogConfig);
        this.appScope.closeDialogOnNavigate(this.dialog, dialogRef);
        dialogRef.afterClosed().pipe(concatMap(result => {
            this.refresh_paused = false;
            if (result && result.tile) {
                this.tile_dict[result.tile.id] = result.tile;
                if (!section) {
                    let size: any;
                    if (result.options && result.options.class) {
                        size = this.tileData.translateClass(result.options.class);
                    }
                    section = this.addSection(size);
                } else {
                    section = ctrl.session.attributes.json.sections.find(sec => sec.id === section.id);
                }
                let tile: GridItem = {
                    id: result.tile.id,
                    parent_id: section.id,
                    x: result.tile.x,
                    y: result.tile.y
                };

                ctrl.session.attributes.json.sections.find(sec => sec.id === section.id).tiles.push(tile);
                ctrl.session.relationships.tiles.data.push({type: 'tile', id: result.tile.id});
                return this.pageDataService.savePage(this.session).pipe(
                    tap(result => {
                        this.menuTreeService.refresh();
                    }), first()
                );
            } else {
                return of(null);
            }
        })).subscribe();
    }

    openSharePageForm() {
        const dialogRef: MatDialogRef<UserGroupPageFormDialogComponent> = this.formDialogService.openUserGroupPageFormDialog(this.session);
    }

    openNewPageForm() {
        const dialogRef = this.formDialogService.openNewPageDialog();

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.menuTreeService.refresh();
                if (result?.id) {
                    this.router.navigateByUrl('/view/page_view/' + result.id);
                }
            }
        });
    }

    addSection(size?: { cols: number, rows: number }) {
        let new_section = this.headerData.addSection(utils.guid());
        if (size) {
            new_section.cols = size.cols;
            new_section.rows = size.rows;
        }
        this.sections.push(new_section);
        this.dashboard = this.sections;
        return new_section;
    }

    public deleteSection(section_to_delete, check: boolean = true) {
        // Called from this page and from HTML
        const ctrl = this;
        if (ctrl.confirmSectionDelete(check)) {
            let $subs = [];
            section_to_delete.tiles.forEach(tile => $subs.push(this.tileData.deleteTile(tile.id)));
            if ($subs.length > 0) {
                // Technically this shouldn't need to succeed in order to filter the tiles from the section but neater this way
                forkJoin($subs).subscribe({
                    next: (result) => {
                        ctrl.filterSections(section_to_delete);
                    }, error: (error) => {
                        console.log('PageViewComponent - report error: ', error);
                    }
                });
            } else {
                ctrl.filterSections(section_to_delete);
            }
        }
    }

    deleteTile(section: Section, tile_to_delete: TileModel) {
        console.log("tile_to_delete", tile_to_delete);
        this.tileData.confirmDeleteTile(tile_to_delete).pipe(
            concatMap(() => {
                return this.tileData.deleteTile(tile_to_delete.id)
            }))
            .subscribe({
                next: (result) => {
                    this.removeTile(section, tile_to_delete.id);
                },
                error: (error) => {
                    if (error.status === 404) {
                        // If the tile didn't exist, make sure tile is still deleted from section and relationships is deleted from session
                        this.removeTile(section, tile_to_delete.id);
                    }
                    console.log("Error deleting tile: ", error);
                }
            });
    }

    private filterSections(section_to_delete) {
        this.dashboard = this.dashboard.filter(section => section.id !== section_to_delete.id);
        this.sections.splice(this.sections.indexOf(this.sections.find(section => section.id === section_to_delete.id)), 1);
        this.save_sections(this.sections);
    }

    private confirmSectionDelete(check: boolean = true) {
        // Depending on where the delete function is called from the check may have already run
        // check variable determines whether the confirmation box should be shown
        let confirmed = true;
        if (check) {
            if (!confirm("Please confirm before removing this section")) {
                confirmed = false;
            }
        }
        return confirmed;
    }

    private removeTile(section: Section, tile_id) {
        // From deleteTile (grid editing view) and from tile emitter
        this.session.relationships.tiles.data = this.session.relationships.tiles.data.filter(tile => tile.id !== tile_id);
        section.tiles = section.tiles.filter(tile => tile.id !== tile_id);
        if (section.tiles.length < 1) {
            this.deleteSection(section, false);
        } else {
            this.save_sections(this.sections);
        }
    }

    copyToClipboard(e, item) {
        this.clipboard = utils.deepCopy(item);
        let tile_group = this.dashboard.find(section => section.id === item.parent_id);
        tile_group.tiles = tile_group.tiles.filter(tile => tile.id !== item.id);

        if (tile_group.tiles.length < 1) {
            this.dashboard = this.dashboard.filter(section => section.id !== tile_group.id);
            this.sections.splice(this.sections.indexOf(this.sections.find(section => section.id === tile_group.id)), 1);
        }

        this.options.enableEmptyCellClick = true;
        this.changedOptions();
    }

    pasteTileToSection(event, group: any): void {
        if (!this.editing) {
            return;
        }
        if (this.clipboard) {
            this.clipboard.parent_id = group.id;
            this.dashboard.find(section => section.id === group.id).tiles.push(utils.deepCopy(this.clipboard));
            this.clipboard = null;
        }
    }

    pasteTile(event, item: any): void {
        const ctrl = this;
        if (!ctrl.editing) {
            return;
        }

        if (this.clipboard) {
            let section = ctrl.headerData.addSection(utils.guid());
            section.x = item.x;
            section.y = item.y;
            section.rows = item.rows;
            section.cols = item.cols;
            this.clipboard.parent_id = section.id;
            section.tiles = [utils.deepCopy(this.clipboard)];
            this.sections.push(section);
            this.dashboard = this.sections;
            this.clipboard = null;
            this.options.enableEmptyCellClick = false;
        } else {
            if (this.options.enableEmptyCellClick === true) {
                this.addTile();
            }
        }
    }

    dragResizeStop(item: GridsterItem, gridItem: GridsterItemComponentInterface, event: MouseEvent): void {
        const pos = gridItem.$item;
        gridItem.item.y = pos.y;
        gridItem.item.x = pos.x;
        gridItem.item.cols = pos.cols;
        gridItem.item.rows = pos.rows;
        // window.dispatchEvent(new Event('resize'))
        this.appScope.resizeWindowEvent();
        // this.sortSections();
    }

    @HostListener('window:afterprint', ['$event'])
    onAfterPrint(event) {
        const ctrl = this;

        this.print_ready = false;
        ctrl.printing = false;
        let orientation = this.session.attributes.json.print_orientation || 'portrait-widescreen';
        ctrl.renderer.removeClass(document.body, orientation);
        this.renderer.removeStyle(this.page_sizer.nativeElement, 'height');
        this.headerData.pagePrinting.next(false);
        document.title = ctrl.headerData.title;
        window.dispatchEvent(new Event('resize'));

        this.removePrintHeaders();
        ctrl.sections.forEach(section => {
            section['printing'] = false;
        });
        this.gridster_parent.grid.forEach(item => {
            item.item.y = item['original_y'];
            item.$item.y = item['original_y'];
            this.renderer.setStyle(item.el, 'transform', 'translate3d(' + item.left + 'px, ' + item.top + 'px, 0px)');
            this.renderer.removeStyle(item.el, 'top');
            this.renderer.removeStyle(item.el, 'left');
        });

        this.changedOptions();
        // window.dispatchEvent(new Event('resize'));
        this.appScope.resizeWindowEvent();
        setTimeout(() => {
            ctrl.windowResized();
        }, 500);
    }

    managePageBreak(index) {
        if (!this.json.grid_options.page_breaks) {
            this.json.grid_options.page_breaks = {};
        }
        this.json.grid_options.page_breaks[index] =
            !(this.json.grid_options.page_breaks[index] && this.json.grid_options.page_breaks[index] === true);
    }

    allowBreak(row) {
        // If any item in the grid has a y value matching the current row
        // This needs to be a dynamic check so that it is updated as tiles move around
        return this.gridster_parent.grid.some(item => item.$item.y === row);
    }

    removePrintHeaders() {
        Object.keys(this.header_tiles_dict).forEach(t => {
            this.dashboard = this.dashboard.filter(section => section.id !== this.header_tiles_dict[t]);
            this.sections.splice(this.sections.indexOf(this.sections.find(section => section.id === this.header_tiles_dict[t])), 1);
            delete this.tile_dict[t];
        })
        this.header_tiles_dict = {};
        this.headerData.hideWireLogo.next(false);
    }

    insertPrintHeaders() {
        const orientation = this.session.attributes.json.print_orientation;
        const rows = orientation === 'portrait' ? 42 : orientation === 'landscape' ? 25 : 48;
        const total_rows = Math.max(...this.gridster_parent.grid.map(g => Number(g.$item.y)));
        let id_count = 1;

        for (let i = 0; i < Number(total_rows); i += rows) {
            this.insertTile(id_count.toString(), i, true);
            id_count++;
            this.insertTile(id_count.toString(), i + rows - 1, false);
            id_count++;
        }
        this.headerData.hideWireLogo.next(this.json.hide_wire_logo || false);
    }

    insertTile(id: string, y: number, header = true) {
        let tile = new TileModel();
        tile.attributes.content = header === true ? 'print-header-tile' : 'print-footer-tile';
        tile.attributes.show_header = false;
        tile.attributes.parameters = {current_page: Number(id) / 2};
        const size = {rows: header === true ? 3 : 1, cols: 12};
        let section = this.addSection(size);
        section.x = 0;
        section.y = y;

        let gridItem: GridItem = {
            id: id.toString(),
            parent_id: section.id,
            x: 0,
            y: y,
            is_title: true
        };
        this.sections.find(sec => sec.id === section.id).tiles.push(gridItem);
        this.tile_dict[id] = tile;
        this.header_tiles_dict[id] = section.id;
    }

    printToPdf() {
        // The page_size variable works in conjunction with the width set in print-styles for print-orientation portrait/landscape
        // These numbers can be adjusted(together) to fit more/less in a page
        const ctrl = this;
        const currentLoaderProgress = ctrl.loaderProgress$.getValue();

        if (currentLoaderProgress !== 0) {
            this.notification.openError("Please wait until dashboard has finished loading before printing", 3000);
            return;
        }

        let page_size = 42;
        let orientation = this.session.attributes.json.print_orientation || 'portrait-widescreen';

        ctrl.loading_printview = true; // show loading circle

        if (orientation === 'landscape') {
            // set page  width  and page_size for landscape
            page_size = 25;
        }
        if (orientation === 'portrait-widescreen') {
            page_size = 48;
        }
        ctrl.renderer.addClass(document.body, orientation);

        //** ctrl.printing = true sets a class on the gridster parent to define a fixed width
        //** Resize window to fixed width 1500px works with 42 rows for portrait, 2200px and 25 rows for landscape
        //** If we work out the ratio exactly this could be adjusted dynamically possibly
        ctrl.printing = true;
        this.headerData.pagePrinting.next(true);

        let header_height = 3; // top header
        let footer_height = 1;
        let push = 3;
        let page_end = utils.deepCopy(page_size);
        let item_index = 0;

        // The uptake of the window resize event seems to take some time therefore need to use timeout
        setTimeout(() => {
            ctrl.windowResized();
            let previous_item;
            if (!this.json.grid_options.page_breaks) {
                this.json.grid_options.page_breaks = {};
            } // Makes for easier reading later
            this.gridster_parent.grid.forEach(item => {
                item['original_y'] = item.item.y;
                if (item.item.y && this.json.grid_options.page_breaks[item.item.y] &&
                    this.json.grid_options.page_breaks[item.item.y] === true &&
                    (!previous_item || previous_item.item.y !== item.item.y)) {
                    push = push + (page_end - item.item.y);
                    previous_item = utils.deepCopy(item);
                }

                item.item.y = item.item.y + push;
                item.$item.y = item.$item.y + push;

                let next_item = this.gridster_parent.grid[item_index + 1] ? this.gridster_parent.grid[item_index + 1].item : {
                    y: 0,
                    rows: 0
                };
                let is_title = false;
                if (item.item.tiles?.[0]?.is_title === true) {
                    is_title = true;
                }

                if ((item.item.y + item.item.rows > (page_end - footer_height) ||
                        (is_title === true && next_item.y + push + next_item.rows > (page_end - footer_height) &&
                            !this.json.grid_options.page_breaks[next_item.y])) &&
                    item.item.rows <= page_size - header_height - footer_height) {
                    push = push + (page_end - item.item.y) + header_height;
                    item.item.y = page_end + header_height;
                    item.$item.y = page_end + header_height;
                    page_end += page_size;
                }
                item_index += 1;
            });
            const pageHeight = `${(page_end - 1) * this.fixed_row_height}px`;
            this.renderer.setStyle(this.page_sizer.nativeElement, 'height', pageHeight);
            this.insertPrintHeaders()
            this.changedOptions();
            this.appScope.resizeWindowEvent();
            ctrl.setPrintDocTitle();
            ctrl.loading_printview = false; // hide loading circle
            ctrl.print_ready = true;
        }, 100);
    }

    print() {
        this.print_ready = false;
        window.print();
    }

    setPrintDocTitle() {
        document.title = utils.printDocTitle(this.headerData.title, this.dateInst.dtp.start, this.dateInst.dtp.end);
    }

    printSection(section) {
        const ctrl = this;
        section.printing = true;
        this.printing = true;
        setTimeout(() => window.print());
    }

    togglePrintSectionToPdf() {
        this.printing_sections = !this.printing_sections;
        this.headerData.page_print_toggle.next(this.editing);
    }

    toggleEditMode($event, value?) {
        if (this.headerData.tile_edit_mode.getValue() === true && !value) {
            alert("You are in the process of editing a tile's layout. Please save or cancel before exiting edit mode.");
            return;
        }

        this.editing = (value || value === false) ? value : !this.editing;

        this.headerData.page_edit_toggle.next(this.editing);
        if (this.editing === false) {
            this.headerData.toggleGridMode.next(false);
            this.headerData.toggleLayoutMode.next(false);
        }
        this.setOptions();
    }

    toggleGridMode() {
        this.headerData.toggleGridMode.next(!this.show_grid);
    }

    toggleLayoutMode() {
        console.log("thi", this.show_layout);
        this.headerData.toggleLayoutMode.next(!this.show_layout);
        this.setOptions();
    }

    toggleCommentPanel(value) {
        this.headerData.toggleCommentPanel(value, this);
    }

    setupComments() {
        const ctrl = this;

        this.eventService.getSessionComments(
            this.dateInst.dtp.start,
            this.dateInst.dtp.end,
            this.session
        )
            .pipe(first(), takeUntil(this.onDestroy)).subscribe(events => {
            if (!events) {
                return;
            }
            this.tileData.events = events.data;
        });

        ctrl.tileData.comment = {
            session_state: ctrl.session,
            start: this.dateInst.dtp.start,
            end: this.dateInst.dtp.end
        }
        ctrl.tileData.show_comment_dtp = false;
    }

    saveComment(comment) {
        if (!comment) {
            this.notification.openError("Please enter a comment.");
            return;
        }
        const ctrl = this;
        this.api.comment.obsSave({
            type: 'comment',
            attributes: {
                comment: comment,
                start: this.dateInst.dtp.start,
                end: this.dateInst.dtp.end,
            },
            relationships: {
                session_state: {data: {id: this.session.id, type: 'session_state'}}
            }
        }).pipe(first()).subscribe(
            (new_comment) => {
                ctrl.tileData.events.push(new_comment.data);
                ctrl.eventService.eventAdded.next(new_comment.data);
            });
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = this.session.attributes.name;
        ctrl.headerData.setFolder(ctrl.session);
        ctrl.headerData.add_comment = true;
        ctrl.headerData.add_print = true;
        ctrl.headerData.addDownload();
        // if(this.showIfOwner() && this.appScope.isNotMobile){

        if (!ctrl.appScope.current_user.restricted_access) {
            ctrl.headerData.buttons.unshift({
                name: 'Add Tile',
                func: ctrl.addTile.bind(ctrl),
                params: {},
                class: 'icon-add hide-xs'
            });
        }
        ctrl.headerData.buttons.push(...[

            {
                name: 'Edit Page',
                func: ctrl.openPageViewDialog.bind(ctrl),
                params: 'current',
                class: 'icon-edit hide-xs'
            }]);

        if (this.headerData.add_new_page_form) {
            ctrl.headerData.buttons.push(...[

                {
                    name: 'New Page',
                    func: ctrl.openNewPageForm.bind(ctrl),
                    params: 'current',
                    class: 'icon-edit hide-xs'
                }]);
        }
        ctrl.headerData.buttons.push(
            {
                name: 'Share Page',
                func: ctrl.openSharePageForm.bind(ctrl),
                params: 'current',
                class: 'icon-edit hide-xs'
            });

        // If the user only has the User role assigned to them, don't allow Edit Mode
        if (!(this.appScope.current_user.role_names.length === 1 && this.appScope.current_user.role_names[0] === 'User')) {
            ctrl.headerData.buttons.push({
                name: 'Toggle Edit Mode',
                func: ctrl.toggleEditMode.bind(ctrl),
                params: {value: ctrl.headerData.page_edit_mode},
                class: '',
                toggle: true
            });
        }
        ctrl.headerData.buttons.push(
            {
                name: 'Refresh Window Size',
                func: ctrl.emitResize.bind(ctrl),
                params: '',
                class: ''
            });
    }

    emitResize() {
        this.appScope.resizeWindowEvent();
        setTimeout(() => {
            this.windowResized();
        }, 100);
    }

    isEmpty() {
        let bln = false;
        if (this.dashboard?.length < 1) {
            return true;
        }
        if (this.sections && this.sections.length > 0) {
            bln = true;
            this.sections.forEach(section => {
                if (section.tiles && section.tiles.length > 0) {
                    bln = false;
                }
            });
            return bln;
        }
        return false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
