import * as utils from '../lib/utils';
import {PlantDataService} from "../services/plant-data/plant_data.service";
import {ApiService} from "../services/api/api.service";
import { HttpClient } from "@angular/common/http";
import {SeriesDataService} from "../services/series_data.service";
import {Component as ComponentModel} from '../_models/component';
import {Equipment} from "../_models/equipment";
import {Process} from "../_models/process";
import {ComponentConstantsTableService} from "../tables/component-constants-table/component-constants-table.service";
import {catchError, first, switchMap, tap} from "rxjs/operators";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {SingleResponse} from '../services/api/response-types';
import {Stream} from '../_models/stream';
import {forkJoin, Observable, of, Subject} from 'rxjs';

// All the common properties and methods used by various element types on the flowchart (process/stream/equipment)
// TODO make this form take in a generic type of Component
export class EditFlowchartComponent {
    chart: any;
    component_view: any;
    component_data: ComponentModel;

    existing_component: any = {};
    is_parent: boolean;
    page_size: string = 'A4';
    page_layout: string = 'landscape';

    all_series: any[];
    component_types: any[];
    component_types_promise: any;
    constant_properties: any[];
    constant_property_dict: any = {};
    deleted_component_ids: any = [];
    constant_components: any[];
    series_components: any[];

    show_series: boolean = false;

    images: any[];
    // avail_equipment: any[];
    custom_equipment_filters: any[];
    parent_list: any[];
    selected_parent: any;
    selected_series: any[] = [];
    series_permissions: any;
    addEquipmentType: string = 'new';
    saveComplete: Subject<boolean> = new Subject<boolean>();

    constructor(public flowchart: any,
                public component: any,
                public plantData: PlantDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private http: HttpClient,
                private cps: ComponentConstantsTableService) {
        this.chart = flowchart;
        this.component_view = component; // view model for the component
        this.component_data = component.data; // data for the component
        this.all_series = plantData.all_series;
    }

    loadForm(): void {
        if (this.component_data.hasOwnProperty('id') && this.component_data.id === this.chart.parent_process.data.id) {
            this.is_parent = true;
            this.component_data.is_parent = true;
        } else {
            this.is_parent = false;
        }
        this.http.get("/api/get_icon_list").subscribe((images: any) => {
            this.images = images;
        });
        const componentTypeOptions: SearchQueryOptions = new SearchQueryOptions();
        componentTypeOptions.filters = [{
            name: 'base_type',
            val: this.component_data.attributes?.base_type,
            op: 'eq'
        }];
        this.component_types_promise = this.api.component_type.searchMany(componentTypeOptions).subscribe(result => {
            if (!result) {
                return;
            }
            this.component_types = result.data;
        });

        if (this.component_data.type === 'equipment') {
            const equipment: Equipment = this.component_data as Equipment;
            this.selected_parent = equipment.relationships.component.data;
            this.parent_list = this.chart.data.processes.concat(
                this.chart.data.streams.concat([this.chart.data.process_focus]));

            // TODO cleanup if this is still used?
            this.custom_equipment_filters = [{'op': 'eq', 'name': 'component', 'val': null}];
        }

        if (this.component_data.relationships.series && this.component_data.relationships.series.data &&
            this.component_data.relationships.series.data.length > 0) {
            this.seriesData.getSeriesPermissions(this.component_data.relationships.series.data.map((series) => {
                return series.id;
            })).subscribe((permissions) => {
                this.series_permissions = permissions;
            });
        }
        this.mapRelationships();
        if (this.component_data.hasOwnProperty('id') && this.component_data.relationships.component_type.data) {
            this.fillConstantTemplate(this.component_data.relationships.component_type.data);
        }
    }

    resetEquipment() {
        if (this.addEquipmentType === 'new') {
            this.component_data = new Equipment();
        }
    }

    selectExisting() {
        this.component_data = utils.deepCopy(this.existing_component);
        this.mapRelationships();
        if (this.component_data.hasOwnProperty('id') && this.component_data.relationships.component_type.data) {
            this.fillConstantTemplate(this.component_data.relationships.component_type.data);
        }
    }

    mapRelationships() {
        if (this.component_data.hasOwnProperty('id')) {
            this.component_data.relationships.series_components = {data: []};
            this.chart.data.series_components.forEach((series_component) => {
                if (series_component.relationships.component.data.id === this.component_data.id) {
                    this.component_data.relationships.series_components.data.push(series_component);
                }
            });

        }
    }

    getSeriesComponents() {
        let series_component_ids = this.component_data.relationships.series_components.data.map(item => {
            return item.id;
        });
        let series_ids = this.component_data.relationships.series.data.map(item => {
            return item.id;
        });

        let series_components;
        let series;
        if (series_component_ids.length > 0) {
            const seriesComponentOptions = new SearchQueryOptions();
            seriesComponentOptions.filters = [
                {op: 'in', name: 'id', val: series_component_ids}
            ];
            let seriesComponentsObservables =
                this.api.series_component.searchMany(seriesComponentOptions).pipe(tap(result => {
                    if (!result) {
                        return;
                    }
                    series_components = result.data;
                }));
            const seriesOptions = new SearchQueryOptions();
            seriesOptions.filters = [
                {op: 'in', name: 'id', val: series_ids}
            ];
            let seriesObservables = this.api.series.searchMany(seriesOptions).pipe(tap(result => {
                if (!result) {
                    return;
                }
                series = result.data;
            }));
            forkJoin([seriesComponentsObservables, seriesObservables]).subscribe(() => {
                utils.fill_relations(series_components.data, series.data, 'series');
                this.component_data.relationships.series_components.data = series_components;
                series_components.forEach((sc) => {
                    this.chart.addSeries(sc, this.chart.parent_process, this.component_view);
                });
            });
        }
    }

    fillConstantTemplate(component_type) {
        this.constant_components = null;
        /**Subscription closed in service (take(1))**/
        this.cps.getSelectedConstantProperties(component_type.id).pipe(
            switchMap(properties => {
                this.constant_properties = properties.data;
                this.constant_properties.forEach(cp => this.constant_property_dict[cp.id] = cp);

                return this.cps.getComponentConstants([this.component_data.id], this.constant_properties).pipe(
                    tap((response) => {
                        this.constant_components = response[this.component_data.id];
                    })
                );
            })).subscribe();
    }

    showSeriesForm() { // adding new series
        let series_edit = this.seriesData.upsertSeries(this.component_data);
        series_edit.afterClosed().subscribe(result => {
            let series = result.series;
            let seriesComponent = result.series_component;
            seriesComponent.relationships.series.data = utils.deepCopy(series);
            series.relationships.series_components.data.push(seriesComponent);
            this.component_data.relationships.series_components.data.push(seriesComponent);
            this.component_data.relationships.series.data.push(series);
            this.chart.addSeries(seriesComponent, this.chart.parent_process, this.component_view);
            this.all_series.push(series);
        });
    }

    addSeries() {
        if (this.selected_series === undefined) {
            this.selected_series = [{id: null, type: 'series'}];
        }
        let observables: Observable<any>[] = [];
        this.selected_series.forEach((selected) => {

            let comp_data = {
                type: 'series_component', relationships: {
                    component: {data: {id: this.component_data.id, type: 'component'}},
                    series: {data: {id: selected.id, type: 'series'}}
                }
            };

            let new_series_component = this.api.series_component.obsSave(comp_data).pipe(
                tap((new_sc) => {
                    new_sc.data.relationships.series.data = selected;
                    this.component_data.relationships.series_components.data.push(new_sc.data);
                    // Add to the independent series_component viewmodel and datamodel lists;
                    this.chart.addSeries(new_sc.data, this.chart.parent_process, this.component_view);
                    // chart_process here = the viewmodel of the process
                }));
            observables.push(new_series_component);
        });
        forkJoin(observables).subscribe(() => {
            this.selected_series = [];
        });
    }

    addNewComponent(): Observable<SingleResponse<Process | Equipment | Stream>> {
        return this.api[this.component_data.attributes?.base_type].obsSave(this.component_data)
            .pipe(tap((result: SingleResponse<Process | Equipment | Stream>) => {
                let new_component = result.data;
                this.component_data = new_component;
                if (this.component_data.attributes?.base_type === "equipment") {
                    this.component_view = this.chart.addEquipment(new_component);
                    // this.fillConstantTemplate();
                } else {
                    this.component_view = this.chart.addProcess(new_component);
                }
                this.mapRelationships();
            }), catchError(e => {
                console.warn('Failed to add component:\n', e);
                return of(false);
            }));
    }

    save(): void {
        try {
            if (this.component_data.relationships.component_type && this.component_data.relationships.component_type.data) {
                this.component_data.relationships.component_type.data.type = 'component_type';
            }
            if (this.component_data.attributes?.base_type === 'equipment') {
                const equipment = this.component_data as Equipment;
                equipment.relationships.component.data = {
                    id: this.selected_parent.id,
                    type: 'component'
                };
            }
            delete this.component_data.relationships.constant_components;

            if (!this.component_data.hasOwnProperty('id')) {
                this.addNewComponent().pipe(first()).subscribe((result) => {
                    this.saveComplete.next(!!result);
                });
            } else {
                if (['equipment', 'process'].includes(this.component_data.attributes?.base_type)) {
                    const c = this.component_data as Equipment | Process;
                    if (c.attributes.icon && !this.component_view.is_parent) {
                        if (this.component_view.image) {
                            c.attributes.json.image.src = c.attributes.icon;
                            this.component_view.data.attributes.json.image.src = c.attributes.icon;
                        }
                    }
                }
                if (this.component_data.attributes?.base_type === 'equipment' && this.addEquipmentType === 'existing') {
                    this.component_view = this.chart.addEquipment(this.component_data);
                    this.getSeriesComponents();
                }
                this.chart.report_groups = this.chart.createReportGroups();
                this.plantData.saveItem(this.component_data);
                this.chart.data.connectors.forEach(connector => this.plantData.saveItem(connector));
                this.saveComplete.next(true);
            }
        } catch (e) {
            console.warn("Error saving " + this.component_data.attributes?.base_type, e);
            this.saveComplete.next(false);
        }
    }

    matSelectCompare(option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
    }

    setPageSize() {
        if (this.page_size === 'A4') {
            if (this.page_layout === 'landscape') {
                this.component_data.attributes.json.windowWidth = 842;
                this.component_data.attributes.json.windowHeight = 595;
            } else {
                this.component_data.attributes.json.windowWidth = 595;
                this.component_data.attributes.json.windowHeight = 842;
            }
        } else {
            if (this.page_layout === 'landscape') {
                this.component_data.attributes.json.windowWidth = 1191;
                this.component_data.attributes.json.windowHeight = 842;
            } else {
                this.component_data.attributes.json.windowWidth = 842;
                this.component_data.attributes.json.windowHeight = 1191;
            }
        }
    }
}
