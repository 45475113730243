import {DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {SearchQueryOptions} from "./search-query-options";
import {ListResponse} from "./response-types";
import {debounceTime, map, shareReplay, switchMap, tap} from "rxjs/operators";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";

export type PaginationEndpoint<T, Q> = (query: Q) => Observable<ListResponse<T>>;

/***
 * Data Source for paginating a REST API.
 *
 * Not using a MatTableDataSource as it's only for simple data arrays. Official recommendation is to implement
 * custom data source if requiring server side interaction, see:
 * {@link https://material.angular.io/components/table/api#MatTableDataSource MatTableDataSource}
 *
 * Adapted from {@link https://nils-mehlhorn.de/posts/angular-material-pagination-datasource Angular Material Pagination Datasource}
 */
export class PaginationDataSource<T> implements DataSource<T> {

    // for storing our current query state
    protected $searchQueryOptions: BehaviorSubject<SearchQueryOptions>;
    // observable will hold current api page for the table
    public $page: Observable<ListResponse<T>>;
    private paginatorSubscription: Subscription;
    private sortSubscription: Subscription;
    public data: T[];
    constructor(
        endpoint: PaginationEndpoint<T, SearchQueryOptions>,
        initialQuery: SearchQueryOptions,
        private paginator: MatPaginator,
        private sort?: MatSort
    ) {

        if (sort) {
            this.sorter = sort;
        }

        this.paginatorSubscription = paginator.page.subscribe((pageEvent) => this.pageChanged(pageEvent));

        initialQuery.page_size = paginator.pageSize;

        this.$searchQueryOptions = new BehaviorSubject<SearchQueryOptions>(initialQuery);

        this.$page = this.$searchQueryOptions.pipe(
            debounceTime(200),
            switchMap((query: SearchQueryOptions) => endpoint(query)),
            tap(response => this.data = response.data),
            shareReplay(1)
        );
    }

    public set sorter(sorter: MatSort) {
        this.sortSubscription = sorter.sortChange.subscribe((sortEvent) => this.sortChange(sortEvent));
    }

    private pageChanged(pageEvent: PageEvent) {
        const options = this.$searchQueryOptions.getValue();
        options.page_size = pageEvent.pageSize;
        options.page_number = pageEvent.pageIndex + 1;
        this.$searchQueryOptions.next(options);
    }

    private sortChange(sortChange: Sort) {
        const options = this.$searchQueryOptions.getValue();
        if (sortChange.direction === '') {
            options.sort = '';
        } else if (sortChange.direction === 'desc') {
            options.sort = `-${sortChange.active.toLowerCase()}`;
        } else if (sortChange.direction === 'asc') {
            options.sort = `${sortChange.active.toLowerCase()}`;
        }
        this.$searchQueryOptions.next(options);
    }

    filterBy(filters: any[]) {
        const options = this.$searchQueryOptions.getValue();
        options.filters = filters;
        options.page_number = 1;
        this.$searchQueryOptions.next(options);
    }

    sortBy(sort: MatSort): void {
        this.sortChange(sort)
    }

    getQuery(): SearchQueryOptions {
        return this.$searchQueryOptions.getValue();
    }

    refresh() {
        const options = this.$searchQueryOptions.getValue();
        this.$searchQueryOptions.next(options);
    }

    connect(): Observable<T[]> {
        return this.$page.pipe(
            tap(response => this.paginator.length = response.meta.count),
            map(page => page.data));
    }

    disconnect(): void {
    }

    // this.gatherEventData();

}
