import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SeriesColumn, SeriesColumnList, SeriesDataService} from "../../../services/series_data.service";
import {NotificationService} from "../../../services/notification.service";
import {BaseComponent} from "../../../shared/base.component";
import {TileParameters} from "../../../services/tile_data.service";

@Component({
    selector: 'series-html-form',
    templateUrl: './series-html-form.component.html',
    styleUrls: ['./series-html-form.component.less'],
    standalone: false
})
export class SeriesHtmlFormComponent extends BaseComponent {
    @Input() htmlString: string;
    @Input() config: TileParameters;
    insertSeries: any;
    insertColumn: any;
    allColumns: SeriesColumnList;
    gssInsert: any;
    @Input() isLegacy: boolean = false;

    @Output() htmlStringChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() configChange: EventEmitter<TileParameters> = new EventEmitter<TileParameters>();


    constructor(private notification: NotificationService,
                private seriesData: SeriesDataService,) {
        super();
    }

    ngOnInit() {
        this.seriesData.$estimate_types.promise.then(response => {
            this.allColumns = this.seriesData.fillColumns(response?.map(et => et.attributes.name));
            this.insertColumn = this.allColumns.columns.find((col: SeriesColumn): boolean => col.name === "Value");
        });
    }

    configUpdated(event) {
        this.config = Object.assign(this.config, event);
        this.configChange.next(this.config);
    }

    htmlStringUpdated(event) {
        this.htmlStringChange.next(event.target.value);
    }

    addToFormula(event?) {
        if (event) {
            this.insertSeries = event.value;
        }
        if (this.insertSeries && this.insertColumn) {
            this.gssInsert = `[${this.insertSeries.attributes.name}@${this.insertColumn.name}]`;
        }
    }

    copyToClipboard(el: HTMLInputElement) {
        el.select();
        document.execCommand("copy");
        this.notification.openSuccess('Copied to clipboard.', 1500);
    }

}
