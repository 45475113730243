import * as utils from "../../lib/utils";

import {TextViewModel} from "../../lib/flowchart/view-models/text-viewmodel";

export class SeriesSummaryViewModel {
    data: any;

    model_keys = ['title', 'primary_value', 'secondary_value1', 'secondary_value1_title', 'secondary_value2', 'secondary_value2_title',
        'sparkline', 'custom_chart']
    offset_percent = 0;
    title: TextViewModel;
    sparkline: TextViewModel;
    custom_chart: TextViewModel;
    primary_value: TextViewModel;
    secondary_value1: TextViewModel;
    secondary_value2: TextViewModel;
    secondary_value1_title: TextViewModel;
    secondary_value2_title: TextViewModel;

    defaultSparklineSize = {w: 60, h: 60, x: 0, y: 20};
    defaultChartSize = {w: 60, h: 80, x: 0, y: 12};
    defaultPrimarySize = {w: null, h: null, x: 96, y: 30};
    defaultSecondarySize = {w: null, h: null, x: 96, y: 60};
    defaultSecondaryTitleSize = {w: null, h: null, x: null, y: 60};
    defaultTitleSize = {w: null, h: null, x: 2, y: 10};
    defaultStyles = {
        font_size: '2.6vw',
        bold: true,
        italic: false,
        underline: false,
        background: 'transparent',
        colour: null,
        border_colour: 'black',
        border_style: 'solid',
        border_width: '0'
    }

    constructor(context_data: any, container: any, offset_height = 0) {
        this.data = this.setDefaults(context_data, container);
        this.title = new TextViewModel(this.data.svg.title);
        this.title.styles = this.data.svg.title.styles;

        this.primary_value = new TextViewModel(this.data.svg.primary_value);
        this.primary_value.styles = this.data.svg.primary_value.styles;

        if (!context_data.value_only) {
            this.secondary_value1 = new TextViewModel(this.data.svg.secondary_value1);
            this.secondary_value1.styles = this.data.svg.secondary_value1.styles;

            this.secondary_value1_title = new TextViewModel(this.data.svg.secondary_value1_title);
            this.secondary_value1_title.styles = this.data.svg.secondary_value1_title.styles;

            this.secondary_value2 = new TextViewModel(this.data.svg.secondary_value2);
            this.secondary_value2.styles = this.data.svg.secondary_value2.styles;

            this.secondary_value2_title = new TextViewModel(this.data.svg.secondary_value2_title);
            this.secondary_value2_title.styles = this.data.svg.secondary_value2_title.styles;

        }
        if (context_data.chart_type === 'custom-chart') {
            context_data.chart_config['set_size'] = {
                height: (container.scale_h * this.data.svg.custom_chart['h'] / 100) - offset_height,
                width: (container.scale_w * this.data.svg.custom_chart['w'] / 100),
            }
            this.custom_chart = new TextViewModel(this.data.svg.custom_chart);
        } else {
            this.sparkline = new TextViewModel(this.data.svg.sparkline);
        }
    }

    setDefaults(data, container: any, offset_height = 0) {
        const ctrl = this;
        this.offset_percent = 0;
        if (offset_height) {
            this.offset_percent = offset_height / container.scale_h * 100;
        }
        if (!data.svg['title']) {
            data.svg['title'] = {}
        }
        if (!data.svg.title['x']) {
            data.svg.title['x'] = this.defaultTitleSize.x;
        }
        if (!data.svg.title['y']) {
            data.svg.title['y'] = this.defaultTitleSize.y;
        }
        if (!data.svg.title['styles']) {
            data.svg.title['styles'] = this.defaultStyles;
            data.svg.title['styles'].font_size = '2.6vh';
        }
        data.svg.title.rotate = 0;

        //#region Default primary_value values--------------------------------------------------------------------------
        if (!data.svg['primary_value']) {
            data.svg['primary_value'] = {}
        }
        data.svg.primary_value.type = 'primary_value';
        if (!data.svg.primary_value['x']) {
            data.svg.primary_value['x'] = this.defaultPrimarySize.x;
        }
        if (!data.svg.primary_value['y']) {
            data.svg.primary_value['y'] = this.defaultPrimarySize.y + +this.offset_percent;
        }
        if (!data.svg.primary_value['h']) {
            data.svg.primary_value['h'] = this.defaultPrimarySize.h;
        }
        if (!data.svg.primary_value['w']) {
            data.svg.primary_value['w'] = this.defaultPrimarySize.w;
        }
        if (!data.svg.primary_value['styles']) {
            data.svg.primary_value['styles'] = utils.deepCopy(this.defaultStyles);
            data.svg.primary_value.styles.font_size = '5vh';
        }
        data.svg.primary_value.rotate = 0;
        //#endregion Default primary_value values

        //#region Default secondary_value1 values-----------------------------------------------------------------------
        if (!data.svg['secondary_value1']) {
            data.svg['secondary_value1'] = {is_init: true}
            data.svg['secondary_value1_title'] = {};
        }
        data.svg.secondary_value1_title.type = 'secondary_value1_title';

        data.svg.secondary_value1.type = 'secondary_value1';
        if (!data.svg.secondary_value1['x']) {
            data.svg.secondary_value1['x'] = this.defaultSecondarySize.x;
            data.svg.secondary_value1_title['x'] = this.defaultSecondarySize.x - 20;
        }
        if (!data.svg.secondary_value1['y']) {
            data.svg.secondary_value1['y'] = this.defaultSecondarySize.y + this.offset_percent;
            data.svg.secondary_value1_title['y'] = this.defaultSecondarySize.y + this.offset_percent;
        }
        if (!data.svg.secondary_value1['styles']) {
            data.svg.secondary_value1['styles'] = utils.deepCopy(this.defaultStyles);
            data.svg.secondary_value1_title['styles'] = utils.deepCopy(this.defaultStyles);
        }
        data.svg.secondary_value1.rotate = 0;
        data.svg.secondary_value1_title.rotate = 0;

        //#endregion Default secondary_val_1 va

        //#region Default secondary_value2 values-----------------------------------------------------------------------
        if (!data.svg['secondary_value2']) {
            data.svg['secondary_value2'] = {is_init: true};
            data.svg['secondary_value2_title'] = {};
        }
        data.svg.secondary_value2_title.type = 'secondary_value2_title';

        data.svg.secondary_value1.type = 'secondary_value2';
        if (!data.svg.secondary_value2['x']) {
            data.svg.secondary_value2['x'] = this.defaultSecondarySize.x;
            data.svg.secondary_value2_title['x'] = this.defaultSecondarySize.x - 20;
        }
        if (!data.svg.secondary_value2['y']) {
            data.svg.secondary_value2['y'] = this.defaultSecondarySize.y + 20 + this.offset_percent;
            ;
            data.svg.secondary_value2_title['y'] = this.defaultSecondarySize.y + 20 + this.offset_percent;
            ;
        }

        if (!data.svg.secondary_value2['styles']) {
            data.svg.secondary_value2['styles'] = utils.deepCopy(this.defaultStyles);
            data.svg.secondary_value2_title['styles'] = utils.deepCopy(this.defaultStyles);
        }
        data.svg.secondary_value2.rotate = 0;
        data.svg.secondary_value2_title.rotate = 0;

        //#endregion Default secondary_value2 value

        //#region Default sparkline values------------------------------------------------------------------------------
        if (!data.svg['sparkline']) {
            data.svg['sparkline'] = {}
        }
        data.svg.sparkline.type = 'sparkline';
        if (!data.svg.sparkline['x']) {
            data.svg.sparkline['x'] = this.defaultSparklineSize.x;
        }
        if (!data.svg.sparkline['y']) {
            data.svg.sparkline['y'] = this.defaultSparklineSize.y + this.offset_percent;
            ;
        }
        if (!data.svg.sparkline['h']) {
            data.svg.sparkline['h'] = this.defaultSparklineSize.h;
        }
        if (!data.svg.sparkline['w']) {
            data.svg.sparkline['w'] = this.defaultSparklineSize.w;//container.scale_w * .7;
        }
        //#endregion Default sparkline values----------

        //#region Default custom_chart values---------------------------------------------------------------------------
        if (!data.svg['custom_chart']) {
            data.svg['custom_chart'] = {}
        }
        data.svg.custom_chart.type = 'custom_chart';
        if (!data.svg.custom_chart['x']) {
            data.svg.custom_chart['x'] = this.defaultChartSize.x;
        }
        if (!data.svg.custom_chart['y']) {
            data.svg.custom_chart['y'] = this.defaultChartSize.y + this.offset_percent;
        }
        if (!data.svg.custom_chart['h']) {
            data.svg.custom_chart['h'] = this.defaultChartSize.h;
        }
        if (!data.svg.custom_chart['w']) {
            data.svg.custom_chart['w'] = this.defaultChartSize.w;
        }
        //#endregion Default custom_chart values

        return data;
    }

    getSelected() {
        let selected = [];
        this.model_keys.forEach(key => {
            if (this[key] && this[key].selected()) {
                selected.push(this[key])
            }
        })
        return selected;
    }

    // Update the location of the process text.
    updateSelectedTitleLocation(deltaX, deltaY, model) {
        let selectedViewModels = this.getSelected();
        this.updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedViewModels);
    }

    //Update location data for viewModel.data...
    updateSelectedViewModelsLocationByData(deltaX, deltaY, selectedViewModels) {
        for (let i = 0; i < selectedViewModels.length; ++i) {
            let viewModel = selectedViewModels[i];
            viewModel.data.x += deltaX;
            viewModel.data.y += deltaY;
        }
    }
}
