import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as $ from 'jquery';
import * as d3 from 'd3';
import {difference} from 'lodash';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {HeaderDataService} from "../../services/header_data.service";
import {TileDataService, TileParameters} from "../../services/tile_data.service";
import {Observable, Subject, of, take} from "rxjs";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api/api.service";
import {AppScope} from "../../services/app_scope.service";
import {takeUntil, tap, concatMap} from "rxjs/operators";
import {deepCopy, guid, stringDate} from "../../lib/utils";
import {moment_timezone} from '../../services/timezone-selector.service';
import {PivotTileConfig} from "../../_typing/config/pivot-tile";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {IDMap, KeyMap, ModelID, ModelName} from "../../_typing/generic-types";
import {PivotService} from "../../services/pivot.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    selector: 'pivot-tile',
    templateUrl: './pivot-tile.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PivotTileComponent implements OnInit, AfterViewInit, OnDestroy {
    buttons: ITileButton[];

    @ViewChild('pivotAnchor', {read: ElementRef, static: true}) pivotAnchor: ElementRef;
    range_map: { [key: string]: { series_list: any[], dtp: IDateTimePeriod } } = {};
    $: any;
    private readonly onDestroy = new Subject<void>();
    stateData: any = {};
    pivotID: string;
    pivotParameters: any;
    pivotData: any;
    colour_pattern: string[];
    cpDict: IDMap<ModelName> = {};
    private _config: PivotTileConfig = new PivotTileConfig();

    @Input()
    set config(config: PivotTileConfig) {
        this._config = config;
    }

    get config(): PivotTileConfig {
        return this._config;
    }

    @Output()
    tileContentChange: EventEmitter<TileParameters> = new EventEmitter();

    @Input()
    showUI: boolean = false;

    current_fields = null;
    fieldLabelMap: KeyMap<{ name: string, curr: string }> = {};


    constructor(
        public dateTimePeriodService: DateTimePeriodService,
        private dateInst: DateTimeInstanceService,
        private headerData: HeaderDataService,
        private seriesData: SeriesDataService,
        public tileData: TileDataService,
        private api: ApiService,
        private appScope: AppScope,
        private pivotService: PivotService,
        private cdr: ChangeDetectorRef) {
        this.pivotID = guid();
    }

    ngOnInit() {
        this.headerData.show_dtp = true;

        this.buttons = this.pivotService.getPivotTileButtons();
        this.buttons.find(b => b.name === 'Edit').func = () => {
            this.showUI = true;
            this.tileData.noTileData.next('');
            this.buildPivot();
        }
        this.buttons.find(b => b.name === 'Save').func = () => {
            this.savePivotState();
        }

        this.tileData.buttonsChanged.next(this.buttons);

        this.appScope.currentUserValue.pipe(take(1)).subscribe(() => {
            this.colourPattern();
        });

        this.dateInst.dateTimePeriodRefreshed$
            .pipe(takeUntil(this.onDestroy))
            .subscribe((dtp: IDateTimePeriod) => {
                this.getData();
            });

    }

    ngAfterViewInit() {
        this.dateInst.dateTimePeriodChanged$.pipe(take(1))
            .subscribe(() => {
                this.pivotParams();
                this.getData();
            });
    }

    colourPattern() {
        let default_pattern = ['teal', 'orangered', 'dodgerblue', 'darkslateblue', 'darkorange',
            'darkturquoise', 'green', 'olive', 'gray', 'black', 'maroon'];

        if (this.appScope.config_name_map.hasOwnProperty("palette2")) {
            this.colour_pattern = (this.appScope.config_name_map.palette2.value.map(colour => colour.colour)).concat(default_pattern);
        } else {
            this.colour_pattern = default_pattern;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    pivotParams() {
        // @ts-ignore
        let dateFormat = $.pivotUtilities.derivers.dateFormat;
        // @ts-ignore
        let sortAs = $.pivotUtilities.sortAs;

        let pivotParams = this.config.pivot_state;

        // @ts-ignore
        let derivers = $.pivotUtilities.derivers;

        // @ts-ignore
        let inspector = $.pivotUtilities;

        // @ts-ignore
        pivotParams.renderers = $.extend(
            // @ts-ignore
            $.pivotUtilities.renderers,

            // @ts-ignore
            $.pivotUtilities.c3_renderers,
            // @ts-ignore
            $.pivotUtilities.plotly_renderers,
            // @ts-ignore
            $.pivotUtilities.d3_renderers,
            // @ts-ignore
            $.pivotUtilities.export_renderers
        );

        pivotParams.menuLimit = 1000;
        pivotParams.showUI = this.showUI;
        pivotParams.onRefresh = (config) => {
            let state = JSON.parse(JSON.stringify(config));

            // Remove the original field from the list of available fields
            Object.values(this.fieldLabelMap).forEach(f => {
                if (f.name === f.curr) return;
                $("td.pvtUnused li span.pvtAttr:contains('" + f.name + "')").parent().remove()
                $("td.pvtRows li span.pvtAttr:contains('" + f.name + "')").html(f.curr + '<span class="pvtTriangle"> ▾</span>');
                $(".pvtAxisLabel:contains('" + f.name + "')").text(f.curr);
            })

            // *delete some values which are functions*//
            delete state["aggregators"];
            delete state["renderers"];
            // *delete some bulky default values*//
            delete state["rendererOptions"];
            // delete state["localeStrings"];

            this.stateData = state;

            let new_fields = this.getFields(config);
            let current_fields = this.current_fields || this.getFields(this.config.pivot_state);

            if (difference(new_fields.sort(), current_fields.sort()).length) {
                this.getData(new_fields);
            }
            this.current_fields = new_fields;
        };
        let element = $(this.pivotAnchor.nativeElement);

        if (this.config.series_list.length > 0) {
            pivotParams.derivedAttributes = Object.assign(this.getDerivedDates({
                m: "Month",
                d: "Day",
                h: "Hour"
            }, "Start"), {
                "Short Date": record => stringDate(record['timestamp'])
            });
        } else {
            pivotParams.derivedAttributes = Object.assign(this.getDerivedDates({
                m: "Month",
                d: "Day",
                h: "Hour"
            }, 'Start'), {
                "Short Start": record => stringDate(record['Start']),
                "Short End": record => stringDate(record['End'])
            });
            const ids = this.config.event_type_ids?.length ? this.config.event_type_ids : this.config.component_type_ids;
            for (let id of ids) {
                let obj = this.config.constant_property_time?.[id];
                if (!obj) continue;
                if (obj && obj.start_prop) {
                    pivotParams.derivedAttributes = this.createDerivedCpDates(obj.start_prop, pivotParams.derivedAttributes);
                }
                if (obj && obj.end_prop && obj.start_prop !== obj.end_prop) {
                    pivotParams.derivedAttributes = this.createDerivedCpDates(obj.end_prop, pivotParams.derivedAttributes);
                }
            }
        }

        Object.keys(this.fieldLabelMap).forEach(key => {
            const propName = this.fieldLabelMap[key].name;
            let userLabel = this.fieldLabelMap[key].curr;
            pivotParams.derivedAttributes[userLabel] = (record) => {
                return deepCopy(record[propName]);
            };
        });

        pivotParams.sorters = {
            "Month": sortAs(["Jan", "Feb", "Mar", "Apr", "May",
                "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]),
            "Day": sortAs(["Mon", "Tue", "Wed", "Thu", "Fri",
                "Sat", "Sun"])
        };

        pivotParams.rendererOptions = {
            c3: {
                size: {
                    height: element.height() - 48,
                    width: element.width() - 18
                },
                color: {
                    pattern: this.colour_pattern
                },
                axis: {
                    x: {
                        tick: {
                            culling: {max: 12},
                        }
                    }
                }
            },
            heatmap: {
                colorScaleGenerator: function (values) {
                    // @ts-ignore
                    return d3.scaleLinear().domain([d3.min(values), d3.max(values)]).range(['white', '#a10c2f']);
                }
            }
        };
        this.pivotParameters = pivotParams;
    }

    private createDerivedCpDates(cpId: ModelID, derivedAttributes) {
        let userLabel = this.fieldLabelMap[cpId]?.curr || this.cpDict[cpId];
        return Object.assign(derivedAttributes,
            this.getDerivedDates({
                m: userLabel + " Month",
                d: userLabel + " Day",
                h: userLabel + " Hour"
            }, this.cpDict[cpId])
        );
    }

    private getDerivedDates(mdf: KeyMap<string>, field: string) {
        return {
            [mdf.m]: record => record[field] ? moment_timezone(record[field])?.format('MMM') : "",
            [mdf.d]: record => record[field] ? moment_timezone(record[field]).format('ddd') : "",
            [mdf.h]: record => record[field] ? moment_timezone(record[field]).format('HH') : ""
        }
    }

    getData(fields = null) {
        this.tileData.noTileData.next('');

        if (this.config.event_type_ids?.length > 0 || this.config.component_type_ids?.length > 0) {
            let override = !Boolean(fields);
            this.getComponentOrEventData(fields).subscribe(() => this.buildPivot(override));

        } else if (this.config.series_list.length > 0) {
            this.getSeriesData().subscribe(() => this.buildPivot());
        }

    }

    private getComponentOrEventData(fields): Observable<any> {
        const $pivotData = this.getConstantPropertyNameObs().pipe(concatMap(() => {
            fields = fields || this.getFields(this.config.pivot_state);
            return this.pivotService.getComponentOrEventData(this.config, fields)
                .pipe(takeUntil(this.onDestroy),
                    tap((data: any[]) => {
                        if (this.showUI !== true && !data?.length) {
                            this.tileData.noTileData.next('pivot event data');
                        }
                        this.pivotData = data;
                    }))
        }))

        return $pivotData;
    }

    private getSeriesData(): Observable<any> {
        return this.pivotService.getTimeSeriesDataObs(this.config, true)
            .pipe(takeUntil(this.onDestroy),
                tap((data: any[]) => {
                    this.checkForData(data)
                    this.pivotData = data;
                }))
    }

    private checkForData(data): void {
        if (!data.length) {
            this.tileData.noTileData.next('pivot series data');
            this.cdr.markForCheck();
        }
    }

    getConstantPropertyNameObs(): Observable<KeyMap<ModelName> | null> {
        let prop_ids = [];
        if (Object.keys(this.config.property_customisations || {}).length) {
            prop_ids = Object.keys(this.config.property_customisations);
        }
        let time_cps;
        if (this.config.constant_property_time && typeof this.config.constant_property_time === 'object') {
            time_cps = Object.values(this.config.constant_property_time)
                .map(cpt => [cpt.start_prop, cpt.end_prop])
                .reduce((acc, props) => acc.concat(props.filter(Boolean)), []);

            prop_ids = prop_ids.concat(time_cps)
        }

        if (!prop_ids) return of(null);

        return this.pivotService.getConstantPropertyNameDict(prop_ids, this.cpDict).pipe(takeUntil(this.onDestroy),
            tap((cp_name_dict: KeyMap<ModelName>) => {
                this.cpDict = cp_name_dict;
                const filtered_cp_name_dict = Object.keys(cp_name_dict).filter((key) => {
                    return this.config.property_customisations?.[key] || this.cpDict[key];
                });
                filtered_cp_name_dict.forEach(key => {
                    this.fieldLabelMap[key] = {
                        name: this.cpDict[key],
                        curr: this.config.property_customisations?.[key]?.label || this.cpDict[key]
                    };
                })
            }));
    }

    getFields(config) {
        // Return fields to fetch
        let fields = [];
        if (!config) {
            return fields;
        }

        if (config.rows) {
            fields = fields.concat(config.rows);
        }

        if (config.cols) {
            fields = fields.concat(config.cols);
        }

        if (config.vals) {
            fields = fields.concat(config.vals);
        }

        let startDerivedValues = ["Month", "Day", "Hour", "Short Start", "Start_Time"];
        let startRequired = startDerivedValues.reduce(
            (required, value) => required || fields.indexOf(value) !== -1,
            false
        );
        if (startRequired && fields.indexOf('Start') === -1) {
            fields.push('Start');
        }

        let endDerivedValues = ["Short End", "End_Time"];
        let endRequired = endDerivedValues.reduce(
            (required, value) => required || fields.indexOf(value) !== -1,
            false
        );
        if (endRequired && fields.indexOf('End') === -1) {
            fields.push('End');
        }

        let cpTimeDerivedValues = [];
        const ids = this.config.event_type_ids?.length ? this.config.event_type_ids : this.config.component_type_ids;
        for (let id of ids) {
            let obj = this.config.constant_property_time?.[id];
            if (!obj) continue;
            ['start_prop', 'end_prop'].forEach(prop => {
                let userLabel = this.fieldLabelMap[obj[prop]]?.curr || this.cpDict[obj[prop]];
                if (obj && obj[prop]) {
                    let objDerivedValues = [
                        userLabel + " Month",
                        userLabel + " Day",
                        userLabel + " Hour"
                    ];
                    cpTimeDerivedValues = cpTimeDerivedValues.concat(objDerivedValues);
                    let objRequired = objDerivedValues.reduce(
                        (required, value) => required || fields.indexOf(value) !== -1,
                        false
                    );
                    if (objRequired && fields.indexOf(this.cpDict[obj[prop]]) === -1) {
                        fields.push(this.cpDict[obj[prop]]);
                    }
                }
            })
        }

        if (Object.keys(this.fieldLabelMap).length) {
            let propDerivedValues = Object.values(this.fieldLabelMap);
            propDerivedValues.forEach(key => {
                const propRequired = fields.indexOf(key.curr) !== -1;
                if (propRequired && fields.indexOf(key.name) === -1) {
                    fields.push(key.name);
                }
            })
        }

        // exclude derived columns from fields
        fields = fields.filter(v => {
            return startDerivedValues.indexOf(v) === -1 && endDerivedValues.indexOf(v) === -1
                && cpTimeDerivedValues.indexOf(v) === -1
                && (Object.values(this.fieldLabelMap)?.filter(cp => cp.name !== cp.curr)?.map(cp => cp.curr).indexOf(v) === -1)
        });

        return fields;
    }

    buildPivot(override = true) {
        this.pivotParams();
        // @ts-ignore
        $(this.pivotAnchor.nativeElement).pivotUI(
            this.pivotData, this.pivotParameters, override
        ).show();

    }

    savePivotState() {
        this.config.pivot_state = this.stateData;
        this.tileContentChange.emit(this.config);
        this.showUI = false;
        if (!this.pivotData || this.pivotData.length <= 1) {
            this.tileData.noTileData.next('pivot event data');
            this.cdr.markForCheck();
        }
        this.buildPivot();
    }
}
