<div class="form-dialog">
  <h1>Configure Grade Recovery Model</h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Configuration">
        <form class="form-section column stretch">
          <mat-label>Select Series</mat-label>

          <!--{{selected_data.process}}-->
        </form>
        <select-search *ngIf="full_series_list.length>0"
                       [value]="getValue('x')"
                       (valueChange)="mapTo($event, 'x')"
                       [data]="full_series_list"
                       [classes]="'fill'"
                       label="Select series for X axis">
        </select-search>
        <select-search *ngIf="full_series_list.length>0"
                       [value]="getValue('y')"
                       (valueChange)="mapTo($event, 'y')"
                       [data]="full_series_list"
                       [classes]="'fill'"
                       label="Select series for Y axis">
        </select-search>


      </mat-tab>


    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-close" (click)="onClose()">Close</button>

    <button class="btn-save" (click)="onSave()">Save</button>
  </div>
</div>
