<div class="range-selector row center">
  <div>
    <div class="row">
      <div *ngFor="let option of range_options" (click)="emitRangeChanged(option)"
           class="range-option">
        <div [ngClass]="{'active': isCurrent(option)}"
             [matTooltip]="option.range + ' by ' + option.period">{{option.text}}</div>
      </div>
    </div>
    <div class="range-description">{{selected_range | upperfirst}} by {{selected_period}}</div>
  </div>
</div>
