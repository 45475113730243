<div class="row medium-height">
  <button (click)="addChartSeries()" class="self-center" mat-raised-button
          matTooltip="Add Series" color="primary">Add Series
    <i class="fa fa-plus" style="margin-left:6px;"></i>
  </button>
</div>
<ng-container *ngIf="series_list!==undefined">
  <div class="custom-chart-series-table">
    <div class="draggable-list-container">
      <h3>Chart Series Configuration</h3>
      <div #selectedList="cdkDropList"
           (cdkDropListDropped)="drop($event)"
           [cdkDropListData]="series_list"
           cdkDropList
           class="draggable-list">

        <div *ngFor="let series_config of series_list; let i = index" cdkDrag>

          <div class="row center medium-height outer-row">
            <div class="row center medium-height">
              <div class="row center medium-height" style="margin-right: 20px;">
                <div class="row expand-icon center">
                  <div (click)="expanded[i]=!expanded[i]" class="caret">
                    <i *ngIf="!expanded[i]" [title]="'Click here for more customisation options'"
                       class="fas fa-caret-down"></i>
                    <i *ngIf="expanded[i]" [title]="'Click here to close the detail options drawer'"
                       class="fas fa-caret-up"></i>
                  </div>
                </div>
                  <ng-container *ngIf="series_name_dict">
                    <select-search-component (selectionChange)="addNameToConfig(series_config, $event.value)"
                                             [api_model]="'series_light'"
                                             [classes]="'fill custom-chart-series'"
                                             [disabled]="chart_type==='rar'"
                                             [matTooltip]="series_name_dict[series_config.name]?.attributes | nameOrDescription"
                                             [matTooltipClass]="'custom-chart-series-tooltip'"
                                             placeholder="Select series"
                                             [value]="series_name_dict[series_config.name]"
                                             class="select-search"
                    ></select-search-component>
                  </ng-container>
              </div>

              <div class="row center medium-height">
                <mat-form-field class="inline full-width">
                  <label>Type:</label>
                  <mat-select [(value)]="series_config.type" (selectionChange)="updateTargetFilters()">
                    <mat-option *ngFor="let type of chart_type_dict[chart_type] | keyvalue" [value]="type.key">
                      {{type.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="row center medium-height">
                <mat-form-field class="inline full-width">
                  <label>Axis:</label>
                  <mat-select [(value)]="series_config.axis">
                    <mat-option *ngFor="let axis of axes" [value]="axis">
                      {{axis | titlecase}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="row center medium-height" style="width:80px;">
                <label style="margin-left:10px;">Colour:</label>
                <colour-palette [value]="series_config.colour || 'rgba(10,10,10,1)'"
                                (selectChange)="series_config.colour=$event"
                                palette="2"
                                show_text="true"
                                [use_picker]="true">
                </colour-palette>
                <div class="spacer"></div>
              </div>
            </div>
            <div style="padding-right:10px;">
              <button class="text-btn" (click)="removeRow(i);" matTooltip="Remove series">
                <span class="material-symbols-outlined" style="cursor:pointer;">close</span>
              </button>
            </div>
          </div>

          <div *ngIf="series_config.type === 'budget'" class="row center">
            <select-search-component (selectionChange)="addTargetToConfig(series_config, $event.value)"
                                     [api_model]="'series_light'"
                                     [custom_filters]="targetSeriesFilter[series_name_dict[series_config.name]?.id]"
                                     placeholder="Select target series"
                                     [value]="series_config.target_series"
                                     compareFunctionName="'compareById"
                                     matTooltip="Target series for comparison with {{series_config.name}}"
                                     [classes]="'fill custom-chart-series'"
                                     class="select-search custom-chart-target"
            ></select-search-component>
            <label class="budget-colour-label">Colour when under target:</label>
            <colour-palette [value]="series_config.target_series?.actual_below || 'rgba(10,10,10,1)'"
                            (selectChange)="addColourToTarget(series_config, $event)"
                            palette="2"
                            show_text="true"
                            [use_picker]="true">
            </colour-palette>
          </div>

          <div class="expanded" *ngIf="expanded[i]">

            <div class="inner-content row auto">
              <div style="width:6%;display:flex;justify-content: start;padding-left:0.5%;">
                <div class="expanded-icon"></div>
              </div>

              <div class="column" style="width:94%;">
                <div class="row" style="height:30px;margin-top:20px;">

                  <mat-checkbox [(ngModel)]="series_config.cumulative">Cummulative
                  </mat-checkbox>

                  <mat-checkbox [(ngModel)]="series_config.show_data_labels">Show data labels
                  </mat-checkbox>

                  <mat-checkbox [(ngModel)]="series_config.vertical_data_labels"
                                [disabled]="!series_config.show_data_labels && !['pie', 'gauge', 'donut'].includes(series_config.type)"
                  > Vertical data labels
                  </mat-checkbox>

                  <mat-checkbox [(ngModel)]="series_config.uncondensed_labels">Don't condense labels
                  </mat-checkbox>

                  <mat-checkbox [(ngModel)]="series_config.show_limits">Show limits</mat-checkbox>
                </div>

                <div class="row" style="align-items: flex-end;margin-top: -4px;margin-bottom: 6px;">
                  <mat-form-field>
                    <mat-label>Custom legend</mat-label>
                    <input [(ngModel)]="series_config.custom_legend_name" matInput>
                  </mat-form-field>

                  <div *ngIf="chart_type==='stacked bar'" class="mat-form-field" style="margin-right:0;">
                    <mat-checkbox [(ngModel)]="series_config.use_alias"
                                  [disabled]="series_config.custom_legend_name?.length > 0">OR Use series alias
                    </mat-checkbox>
                  </div>
                </div>

                <div class="row">
                  <mat-form-field matTooltip="Line and area charts only.">
                    <mat-label>Line Type</mat-label>
                    <mat-select [(value)]="series_config.line_type"
                                [disabled]="series_config.type !== 'line' && series_config.type !== 'area'">
                      <mat-option value="solid">Solid</mat-option>
                      <mat-option value="dotted">Dotted</mat-option>
                      <mat-option value="dashed">Dashed</mat-option>
                      <mat-option value="longdashed">Long dashed</mat-option>
                    </mat-select>
                  </mat-form-field>


                  <mat-form-field matTooltip="Line and area charts only.">
                    <mat-label>Line Thickness</mat-label>
                    <mat-select [(value)]="series_config.line_thickness"
                                [disabled]="series_config.type !== 'line' && series_config.type !== 'area'">
                      <mat-option value="thickness-thin">Thin</mat-option>
                      <mat-option value="normal">Normal</mat-option>
                      <mat-option value="thickness-thick">Thick</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="row">
                  <mat-form-field matTooltip="Line and area and bar charts only.">
                    <mat-label>Custom Range</mat-label>
                    <mat-select (selectionChange)="changeRange($event, i, series_config)"
                                [(value)]="series_config.range">
                      <mat-option [value]="null"></mat-option>
                      <mat-option *ngFor="let range of ranges"
                                  [value]="range.range">{{range.range | removeUnderscore | upperfirst}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field matTooltip="Line and area and bar charts only.">
                    <mat-label>Custom Period</mat-label>
                    <mat-select (selectionChange)="checkPeriod($event, i)" [(value)]="series_config.sample_period"
                                [compareWith]="matSelectCompare">
                      <mat-option *ngFor="let period of sample_periods"
                                  [value]="period">{{period.name | removeUnderscore | upperfirst}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</ng-container>
