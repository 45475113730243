<!--Full Table Component-->
<table [dataSource]="dataSource" class="series-table" mat-table #detailsSort="matSort"
       *ngIf="data_ready && !table_extract">
  <ng-container *ngFor="let column of columns;" [matColumnDef]="column" sticky>

    <ng-container *ngIf="title_columns.includes(column)">
      <th *matHeaderCellDef mat-sort-header
          [style.width]="format_dict[column]?.width + 'px'"
          class="freeze-pane" mat-header-cell [constrainWidth]="config.constrain_width">
        <ng-container *ngIf="column !== 'status'">{{format_dict[column]?.title}}</ng-container>
        <ng-container *ngIf="column === 'status'"><i class="icon-status"></i></ng-container>
      </th>
      <td *matCellDef="let series;"
          mat-cell [ngStyle]="format_dict[column]?.style" style="alignment: left; text-align: left">
        <ng-container *ngIf="column === 'Status'">
        <span class="fa flag flag--status fa-warning"
              [ngClass]="{'warning-none': !element[column] || element[column]==1, 'warning' : element[column]==2, 'error': element[column]==3}">
        </span>
          <!--<i [ngClass]="{'status-warn': element[column] === 2, 'status-action': element[column] === 3}"></i>-->
        </ng-container>
        <div *ngIf="column === 'name'" class="row center" [style.width]="format_dict[column]?.width + 'px'"
             [style.justify-content]="format_dict[column]?.style['text-align']==='right' ? 'flex-end' : format_dict[column].style['text-align']==='center' ? 'center':'flex-start'">
          <series-edit-button [series_id]="series.id" class="edit-inline hide-xs hideonprint"></series-edit-button>
          <a (debounceClick)="openChartDialog(series.attributes.name)" [dblClickCopy]="series.attributes[column]"
             [textContent]="series.attributes[column]" style="cursor:pointer;"></a>
          <span *ngIf="series.attributes.kpi_level === 'Level 1'"
                class="kpi">KPI {{series.attributes.kpi_level.replace('Level', '')}}</span>
        </div>
        <div *ngIf="column === 'description'"
             class="row center">
          <series-edit-button *ngIf="!columns.includes('name')" [series_id]="series.id"
                              class="edit-inline hide-xs hideonprint"></series-edit-button>
          <a (debounceClick)="openChartDialog(series.attributes.name)" [dblClickCopy]="series.attributes.name"
             [textContent]="series.attributes[column]"
             [style.width]="tableUtils.setDescriptorWidth(column, series, format_dict, columns)"></a>
          <span *ngIf="!columns.includes('name') && series.attributes.kpi_level === 'Level 1'"
                class="kpi">KPI {{series.attributes.kpi_level.replace('Level', '')}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="!title_columns.includes(column)">
      <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell style="text-align:center;white-space: nowrap;">
        {{column | removeUnderscore | upperfirst}}
      </th>
      <td *matCellDef="let series;" mat-cell style="padding:0 4px;text-align:left;vertical-align: middle;">
        {{series.attributes[column] | removeUnderscore | upperfirst}}
      </td>
    </ng-container>

  </ng-container>

  <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
  <tr *matRowDef="let row; columns: columns;" mat-row></tr>
</table>

<!--Table extract that slots into parent-->
<ng-container *ngIf="data_ready && table_extract">
  <ng-container *ngFor="let column of columns">
    <ng-container [matColumnDef]="column">
      <th *matHeaderCellDef mat-header-cell style="text-align:center;white-space: nowrap;" [mat-sort-header]="column">
        {{(column_name_map[column] || column) | removeUnderscore | upperfirst}}
      </th>
      <!--Note that this series comes from the parent table, in this case a row of GSS data, hence the capital 'ID'-->
      <td *matCellDef="let series" style="padding:0 4px;text-align:left;vertical-align: middle;">
        {{series_details[series.ID].attributes[column] | removeUnderscore | upperfirst}}
      </td>
    </ng-container>
  </ng-container>
</ng-container>
