export const analytic_sites = {
    'https://sibanye.test.mmswire.com': 1,
    'https://sibanye.mmswire.com': 3,
    'https://demo4.mmswire.com': 4,
    'https://dallaglio.mmswire.com': 5,
    'https://dallaglio.test.mmswire.com': 6,
    'https://kamoa.mmswire.com': 7,
    'https://kamoa.test.mmswire.com': 8,
    'https://miningplus.mmswire.com': 9,
    'https://aeci.mmswire.com': 10,
    'https://centerra.mmswire.com': 11,
    'https://centerra.test.mmswire.com': 12,
    "https://metalkol.mmswire.com": 13,
    "https://metalkol.test.mmswire.com": 14,
    "https://goldvale.mmswire.com": 15,
    "https://goldvale.test.mmswire.com": 16,
    "https://sylvaniaplatinum.mmswire.com": 17,
    "https://sylvaniaplatinum.test.mmswire.com": 18,
    "https://pmc.mmswire.com": 19,
    "https://pmc.test.mmswire.com": 20,
    "https://tungstenwest.mmswire.com": 21,
    "https://tungstenwest.test.mmswire.com": 22,
    "https://mining.mmswire.com": 23,
    "https://kico.mmswire.com": 24,
    "https://kico.test.mmswire.com": 25,
    "https://platreef.mmswire.com": 26,
    "https://platreef.test.mmswire.com": 27,
    "https://lspconnect.mmswire.com": 28,
    "https://lspconnect.test.mmswire.com": 29,
    "https://swakopuranium.mmswire.com": 30,
    "https://ivanhoemarketing.mmswire.com": 31,
    "https://ivanhoemarketing.test.mmswire.com": 32
};

export const analytic_domain = 'https://analytics.mmswire.com/';
