import {Component, Input, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import {FormulaPart, NameFormula, SelectedNameFormula} from "../../../_typing/config/component-form-config";
import {tap, take, concatMap, takeUntil} from "rxjs/operators";
import {ComponentTypeService} from "../../../services/models/component-type.service";
import {ComponentType} from "../../../_models/component-type";
import {ComponentNameFormulaBuilderService} from "../../../services/component-name-formula-builder.service";
import {IDMap, KeyMap} from "../../../_typing/generic-types";
import {ConstantProperty} from "../../../_models/constant-property";
import {BaseComponent} from "../../../shared/base.component";
import {of} from "rxjs";

@Component({
    selector: 'component-name-formula-selector',
    templateUrl: './component-name-formula-selector.component.html',
    styleUrls: ['./component-name-formula-selector.component.less'],
    standalone: false
})
export class ComponentNameFormulaSelectorComponent extends BaseComponent {
    private _componentType: Partial<ComponentType>;
    @Input() set componentType(componentType: Partial<ComponentType>) {
        if (componentType?.id !== this._componentType?.id) {
            this._componentType = componentType;
            this.loadForm();
        }
        this._componentType = componentType;
    };

    get componentType(): Partial<ComponentType> {
        return this._componentType;
    }

    @Input() label: string = 'Formula options';
    @Input() fixedOnly: boolean = false;
    @Input() useNameFormula: boolean;
    @Output() useNameFormulaChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectedNameFormula: SelectedNameFormula;
    @Output() selectedNameFormulaChange = new EventEmitter<SelectedNameFormula>();

    nameFormulaOptions: KeyMap<NameFormula> = {};
    cpDict: IDMap<ConstantProperty> = {};
    formula: NameFormula;
    formulaKey: number;

    constructor(private componentTypeService: ComponentTypeService,
                private formulaBuilder: ComponentNameFormulaBuilderService,
                private cdr: ChangeDetectorRef) {
        super();

    }

    ngOnInit() {
        this.compareFormula = this.compareFormula.bind(this);
        this.formula = this.selectedNameFormula?.formula;
        this.formulaKey = this.selectedNameFormula?.key;
    }

    loadForm() {
        this.componentTypeService.getComponentType(this.componentType.id)
            .pipe(concatMap(result => {
                this.componentType = result.data;
                this.nameFormulaOptions = this.componentType.attributes.json?.name_formulas || {};

                if (this.fixedOnly) {
                    this.nameFormulaOptions = this.filterFixedOptions();
                    this.cdr.markForCheck();
                    return of(null)
                }
                return this.formulaBuilder.getProperties(this.nameFormulaOptions)
                    .pipe(tap(result => {
                        result.data.forEach(cp => this.cpDict[cp.id] = cp);
                        this.cpDict = Object.assign({}, this.cpDict);
                        this.cdr.markForCheck();
                    }))
            }), take(1), takeUntil(this.onDestroy))
            .subscribe();
    }

    filterFixedOptions(): KeyMap<NameFormula> {
        let filteredOptions = {};
        /*Formulas can not have constant_property types and if they have date types the date format must be JULIAN*/
        Object.keys(this.nameFormulaOptions).forEach(option => {
            if (!this.nameFormulaOptions[option].map(opt => opt.type).includes("constant_property") &&
                (!this.nameFormulaOptions[option].map(opt => opt.type).includes("date") ||
                    (this.nameFormulaOptions[option].filter(opt => opt.type==='date').every(opt => opt.content==='JULIAN'))) ) {
                filteredOptions[option] = this.nameFormulaOptions[option];
            }
        })
        return filteredOptions;
    }

    formulaSelected(event): void {
        this.selectedNameFormula = {key: event.value, formula: this.nameFormulaOptions[event.value] || null};
        this.selectedNameFormulaChange.emit(this.selectedNameFormula);
    }

    getNameString(formula: FormulaPart[]): string {
        return this.formulaBuilder.getNameString(formula, this.cpDict);
    }

    compareFormula(option, selected) {
        return option == selected ||
            this.formulaBuilder.getFormulaString(this.nameFormulaOptions[option]) === this.formulaBuilder.getFormulaString(this.selectedNameFormula?.formula);
    }
}
