<form class="form-section column">
  <!--TODO improve user flow and apply the hints that have been provided here-->
  <div class="column">

    <div class="row full-height">
      <mat-form-field (mouseenter)="hint='method'">
        <mat-label>Correction method</mat-label>
        <mat-select (selectionChange)="clearVariables()" [(value)]="selectedCorrectionMethod"
                    placeholder="Select correction method here">
          <mat-option *ngFor="let item of correctionMethods" [value]="item.value">
            {{item.display}}
          </mat-option>
        </mat-select>
        <mat-hint>Please see detailed options below.</mat-hint>
      </mat-form-field>
      <div class="tip-box">
        <div [ngClass]="{'showing': hint=='method'}" class="tip">
          <label>Correction method</label>
          <div *ngIf="selectedCorrectionMethod === 'factor'">
            Please see detailed options below.
          </div>
          <div *ngIf="selectedCorrectionMethod === 'constant'">
            Please see detailed options below.
          </div>
        </div>
      </div>
    </div>

    <div class="column" *ngIf="selectedCorrectionMethod === 'factor'">
      <mat-label>Apply correction factor to a series or a calculation</mat-label>
      <mat-radio-group [(ngModel)]="is_calculation" name="is_calculation" style="margin-top:10px;">
        <mat-radio-button [value]="false">Series</mat-radio-button>
        <mat-radio-button [value]="true" style="margin-left:10px;">Calculation</mat-radio-button>
      </mat-radio-group>

    </div>

    <ng-container *ngIf="selectedCorrectionMethod === 'factor' && dtp">
      <button (click)="getValue()"
              style="margin: 20px 0; width:fit-content;"
              [disabled]="!(target_series||series)"
              [ngStyle]="{'opacity': !(target_series||series) ? 0.5 :  1}"
              class="bordered-button" mat-button>Get current value for {{seriesOrCalc}}
      </button>

      <ng-container *ngIf="display_value !== null">
        <div class="info"><i class="fa fa-info-circle"></i> The current value
          for {{(series && seriesOrCalc == 'series') ? series.attributes.description : target_series.attributes.description}}
          is {{display_value.toFixed(5)}} for the time period from {{dtp.start | stringDate}}</div>
      </ng-container>
    </ng-container>


    <ng-container *ngIf="selectedCorrectionMethod === 'factor'">

      <div class="inline-tip">To correct the calculation value, select a series to apply a correction factor to</div>
      <div *ngIf="is_calculation">
        <div>Apply correction to a calculation</div>
        <div class="row full-height">
          <select-search-component
              (selectionChange)="target_series = $event.value"
              [api_model]="'series'"
              [custom_filters]="calculation_series_filter"
              [value]="target_series"
              [classes]="'fill'"
              [label]="'Select the calculation here to correct'"
              class="select-search">
          </select-search-component>
        </div>

      </div>

    </ng-container>

    <div class="row">
      <div (mouseenter)="hint='series'" class="select-search">
        <select-search-component
            (selectionChange)="getPermissions($event.value);getCorrectionHistory($event.value);configure($event.value)"
            [api_model]="'series'"
            [custom_filters]="series_filter"
            [value]="series"
            [classes]="'fill'"
            [label]="selectedCorrectionMethod===correctionMethods[1].value ? (
is_calculation ?'Select the series to apply correction factor to':'Select the series to adjust to correct the calculation'):
                     'Select series to apply hourly value to'"
            class="select-search">
        </select-search-component>
      </div>
    </div>

    <div class="row full-height">
      <mat-form-field (mouseenter)="hint='comment'">
        <mat-label>Comment</mat-label>
        <input [(ngModel)]="comment" [ngModelOptions]="{standalone: true}" matInput
               placeholder="Provide a reason here for editing historical data" type="text">
        <mat-hint>Provide a reason here for editing historical data.</mat-hint>
      </mat-form-field>

    </div>

    <div class="row" *ngIf="selectedCorrectionMethod !== 'Apply constant value'">
      <mat-form-field *ngIf="selectedCorrectionMethod === 'Apply correction factor'" style="max-width:200px;"
                      (mouseenter)="hint='Initialise Correction Factor'">
        <mat-label>Initialise Correction Factor</mat-label>
        <input maxlength="100px" [(ngModel)]="initialise_factor" matInput name="initialise_factor"
               placeholder="Initialise Correction Factor" type="number">
        <mat-hint>Provide an initialistion value here for the correction factor. This increases the speed and accuracy of the
            correction factor that WIRE determines.</mat-hint>
      </mat-form-field>

      <mat-form-field (mouseenter)="hint='Correction_factor'">
        <mat-label>Correction factor</mat-label>
        <input [(ngModel)]="factor" matInput name="factor" placeholder="Correction factor" type="number">
        <mat-hint> This value will alter the value of the series at each timestamp for the chosen time period by multiplying
            this value to the current series values.</mat-hint>
      </mat-form-field>

      <mat-form-field (mouseenter)="hint='Target'">
        <mat-label>Target</mat-label>
        <input [(ngModel)]="target_value" matInput name="target_value" placeholder="Target" type="number">
      </mat-form-field>

    </div>

    <div class="row" *ngIf="selectedCorrectionMethod !== 'Apply constant value'">
      <mat-checkbox (mouseenter)="hint='Auto_Refresh'" [(ngModel)]="use_offset"
                    class="form-element-align"
                    [ngModelOptions]="{standalone: true}"
                    style="min-width:unset;font-weight:bold;flex-grow:0.4;">Use offset
      </mat-checkbox>
      <div class="mat-hint">
        Use this tick box to apply a correction by using an off set, allowing the calculation engine to alter the
        values by a set value for the chosen time period.
      </div>
      <mat-form-field (mouseenter)="hint='Offset_value'">
        <mat-label>Offset value</mat-label>
        <input [(ngModel)]="offset" matInput name="offset" placeholder="Offset value" type="number">
        <mat-hint>This value will alter the value of the series at each timestamp for the chosen time period by adding this
            value to the current series value.</mat-hint>
      </mat-form-field>

    </div>

    <div class="row" *ngIf="selectedCorrectionMethod === 'Apply constant value'">
      <mat-form-field style="max-width:200px;"
                      (mouseenter)="hint='Value'">
        <mat-label>Value</mat-label>
        <input [(ngModel)]="value" matInput name="value" placeholder="Value" type="number">
        <mat-hint>This value overrides the current values in WIRE for the *selected series* over the
            selected timespan and sample period and replaces it with the constant value you have provided.</mat-hint>
      </mat-form-field>

      <button (click)="applyDayValue()"
              [disabled]="!checkDayValueReady() || !dtp"
              [ngStyle]="{'opacity': !checkDayValueReady() ? 0.5 :  1}"
              style="align-self: center;"
              class="bordered-button" mat-button>Update hourly value
      </button>

    </div>

    <div class="row" style="margin-top:20px;" *ngIf="selectedCorrectionMethod !== 'Apply constant value' && dtp">

      <button (click)="getCorrectionFactor()"
              [disabled]="!(checkCorrectionReady() && selectedCorrectionMethod === 'factor')"
              class="bordered-button" mat-button
              [ngStyle]="{'opacity': checkCorrectionReady() && selectedCorrectionMethod === 'factor' ? 1 :  0.5}"
              matTooltip="Determine correction factor">Determine correction factor
      </button>

      <button (click)="applyCorrectionFactor()" [disabled]="!checkCorrectionReady()" class="bordered-button" mat-button
              [ngStyle]="{'opacity': checkCorrectionReady() ? 1 :  0.5}"
              matTooltip="Apply correction factor">Apply correction factor
      </button>

      <button (click)="overrideCalculations()"
              [disabled]="!(series && permissions[series.id] && permissions[series.id].includes('override_calculations'))"
              [ngStyle]="{'opacity': series && permissions[series.id] && permissions[series.id].includes('override_calculations') ? 1 :  0.5}"
              class="bordered-button" mat-button>Update calculations
      </button>
    </div>

    <div class="row tip-box detail" *ngIf="selectedCorrectionMethod === 'factor'">
      <div class="tip">
        <label>Apply correction factor</label>
        <div>
          This method of WIRE data correction can be applied in two ways.
          <p>
            1. By *series* selection: <br/>
            Provide a value a total that this series should be for a given time period (correction total). This then
            allows WIRE to calculate and apply
            a correction factor to the series for the selected time period.
          </p>
          <p>
            Alternatively, you can proved a correction factor that you have pre-determined (in the correction total
            input
            section) and WIRE will apply
            the correction factor to the selected time period.
          </p>
          <p>
            2. By *calculation* selection: <br/>
            If the series that you wish to correct is a calculation, and you know the total that this calculation should
            be, you can use this method.
            You can select one of the series that this calculation depends on "here" that you wish to correct for the
            selected time period and WIRE will change
            the selected series to achieve the correct calculation total that you provided. -
          </p>
        </div>
      </div>
    </div>

    <div class="row display"
         *ngIf="series&&permissions&&permissions[series.id] && !permissions[series.id].includes('apply_correction_factor')">
      You do not have user rights to apply a correction factor to this series
    </div>

    <div class="row display"
         *ngIf="series && permissions[series.id] && !permissions[series.id].includes('override_calculations')">
      You do not have user rights to override this calculation
    </div>

  </div>
</form>
