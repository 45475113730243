import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBarRef} from "@angular/material/snack-bar";
import {DateTimePeriodService} from "../services/date-time-period.service";
import * as utils from '../lib/utils';
import {cloneDeep as _cloneDeep} from "lodash-es";
import {EstimateDialogData, EstimateFormComponent} from "../forms/estimate-form.component";
import {SeriesDataService} from "../services/series_data.service";
import {HeaderDataService} from "../services/header_data.service";
import {Series} from "../_models/series";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {getRelationWithIdFilter} from "../services/api/filter_utils";
import {NotificationService} from "../services/notification.service";
import {SnackbarComponent} from "../notifications/snackbar/snackbar.component";
import {forkJoin} from "rxjs";

@Component({
    selector: 'estimate-data-sheet',
    templateUrl: 'estimate-data-sheet.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EstimateDataSheetComponent implements OnInit, OnDestroy {
    @Input()
    seriesList: Series[];

    private _estimateList: any[];

    @Input()
    set estimateList(estimateList: any[]) {
        this._estimateList = estimateList;
    }

    get estimateList() {
        return this._estimateList;
    }

    @Input()
    permissions: any;
    @Input()
    forecastYear: number;
    @Input()
    selectedEstimate;
    @Input()
    kpis;

    @Input()
    process: any;

    dataSource: MatTableDataSource<any>;
    series_list: Series[] = [];
    private sort;

    all_headers: string[];
    col_headers: string[];
    date: Date;
    events: any;
    events_ready: boolean;
    forecast_year: number;
    full_series_list: {
        attributes: {
            description: string,
            event_type_name?: string,
            kpi_level: string,
            name: string
        }, id: string, type: string
    }[];
    minimum_headers: string[];
    months: any[];
    raw_data_map: {};
    selected_estimate: {
        attributes: {
            allow_calculations: boolean
            changed_by_name: string
            changed_on: string,
            created_by_name: string,
            created_on: string,
            description: string,
            name: string
        },
        id: string,
        relationships: any,
        type: string
    };

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    series_parents: any[];
    series_summary: any[];
    show_detail: boolean;

    private loading_snackbar: MatSnackBarRef<SnackbarComponent>;

    constructor(private api: ApiService,
                private dialog: MatDialog,
                private dateTimePeriod: DateTimePeriodService,
                private changeDetectorRef: ChangeDetectorRef,
                private notification: NotificationService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        this.headerData.title = 'Estimate Input Sheet';
        this.loading_snackbar = this.notification.openInfo('Loading sheet');

        this.full_series_list = utils.deepCopy(this.seriesList);
        this.series_list = utils.deepCopy(this.estimateList);
        this.forecast_year = this.forecastYear;
        this.selected_estimate = this.selectedEstimate;
        this.date = new Date();
        this.series_summary = [];
        this.series_parents = [];

        this.events = null;
        this.events_ready = false;
        this.months = [];

        const estimateName = this.selected_estimate.attributes.name;

        this.col_headers = ['Average', 'Max', 'Min', 'Count', 'Sum',
            'Daily', 'Daily ' + estimateName,
            'MTD', 'MTD ' + estimateName, 'MTD Variance',
            'YTD', 'YTD ' + estimateName, 'YTD Variance'];
        this.all_headers = utils.deepCopy(this.col_headers);
        this.minimum_headers = [
            'Daily', 'Daily ' + estimateName,
            'MTD', 'MTD ' + estimateName,
            'YTD', 'YTD ' + estimateName];
        this.col_headers = this.minimum_headers;
        this.show_detail = false;
        this.raw_data_map = {};

        this.dateTimePeriod.dtpInitialisedPromise.promise.then(() => {
            for (let index = 0; index < 12; index++) {
                const time_stamp = new Date(this.forecast_year, index, 1, this.dateTimePeriod.defaultStartHour + 1);
                this.months.push(time_stamp.toISOString());
            }
            const promises = [];
            this.series_list.forEach(item => promises.push(this.getSeriesData(item)));
            forkJoin([promises]).subscribe(() => {
                this.changeDetectorRef.markForCheck();
                this.events_ready = true;
                this.loading_snackbar.dismiss();
                this.dataSource = new MatTableDataSource(this.series_list);
            });

            let i: number, j: number, series_chunk, chunk = 5;
            for (i = 0, j = Object.keys(this.series_parents).length; i < j; i += chunk) {
                series_chunk = Object.keys(this.series_parents).slice(i, i + chunk);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.loading_snackbar) {
            this.loading_snackbar.dismiss();
        }
    }

    getSeriesData(item) {
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('series', item.id), {
                name: 'time_stamp',
                op: 'in',
                val: this.months
            }];
        return this.api.raw_data.searchMany(options).subscribe(response => {
            response.data.map((row) => {
                const iso_date = (new Date(row.attributes.time_stamp)).toISOString();
                this.raw_data_map[item.id + iso_date] = row.id;
                item[iso_date] = this.detectLimit(item, row);
            });

            item = this.fillRow(item);
        });
    }


    fillRow(row) {
        let i = 0;
        let date_counter = new Date(this.months[i]);

        while (i <= 11) {
            if (!row.hasOwnProperty(date_counter.toISOString())) {
                row[date_counter.toISOString()] = {
                    attributes: {value: null, time_stamp: date_counter.toISOString()},
                    relationships: {series: {data: {id: row.id, type: 'series'}}},
                    type: 'raw_data'
                };

            } else if (!row[date_counter.toISOString()].hasOwnProperty('type')) {
                row[date_counter.toISOString()] = {
                    attributes: {value: null, time_stamp: date_counter.toISOString()},
                    relationships: {series: {data: {id: row.id, type: 'series'}}},
                    type: 'raw_data'
                };
            }
            i++;
            date_counter = new Date(this.months[i]);
        }
        return row;
    }

    detectLimit(row, raw_data) {
        // reset to enable update
        raw_data.warning = false;
        raw_data.error = false;

        if (raw_data.attributes.value > row.attributes.hi || raw_data.attributes.value < row.attributes.low) {
            raw_data.warning = true;
            raw_data.error = false;
        }

        if (raw_data.attributes.value > row.attributes.hihi || raw_data.attributes.value < row.attributes.lowlow) {
            raw_data.error = true;
            raw_data.warning = false;
        }

        return raw_data;
    }

    dataChange(value: string, row: any, time: string) {
        let raw_data = row[time];
        raw_data = this.detectLimit(row, row[time]);

        raw_data.saving = true;
        const new_raw_data = {
            id: raw_data.id,
            type: 'raw_data',
            attributes: _cloneDeep(raw_data.attributes),
            relationships: _cloneDeep(raw_data.relationships)
        };
        new_raw_data.attributes.value = value;

        if (raw_data.hasOwnProperty('id')) {
            if (isNaN(parseFloat(raw_data.attributes.value))) {
                this.api.raw_data.delete(raw_data.id).then(() => {
                    delete raw_data.id;
                    row[time].saving = false;
                    this.detectLimit(row, row[time]);
                    this.changeDetectorRef.markForCheck();
                });
            } else {
                this.api.raw_data.patch(new_raw_data).then(result => {
                    // this.api.raw_data.patch(raw_data.id, {data: new_raw_data}).$promise.then(function (result) {
                    raw_data.saving = false;
                    this.detectLimit(row, row[time]);
                    this.changeDetectorRef.markForCheck();
                });
            }
        } else {
            if (isNaN(parseFloat(value))) {
                console.log('Data change: Not a number');
            } else {
                this.api.raw_data.save(new_raw_data).then(result => {
                    row[time] = result.data;
                }, reason => {
                    console.log('Failed to save data change', reason);
                }).finally(() => {
                    raw_data.saving = false;
                    this.detectLimit(row, row[time]);
                    this.changeDetectorRef.markForCheck();
                });
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    getRowHeaders() {
        return ['Series', 'Estimate'].concat(this.col_headers).concat(this.months);
    }

    openEstimateDialog(event, series) {
        const dialogConfig = new MatDialogConfig<EstimateDialogData>();

        dialogConfig.data = {
            selectedEstimateType: this.selected_estimate.attributes.name,
            seriesList: this.full_series_list,
            estimate: series,
            seriesData: this.seriesData
        };

        dialogConfig.panelClass = ['default-form-dialog', 'estimate-form-dialog'];
        let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);

    }

    sortData(event: Sort) {
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case "Series":
                    return data.attributes.name;
                case "Estimate":
                    return data.attributes.description;

            }
        };
    }
}
