import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import { HttpClient } from "@angular/common/http";
import {PARSER_CONFIG_TYPES} from "../../_models/parser-config-template";
import {UploadModelTemplateService} from "./upload-model-template.service";
import {
    GenerateTableParseResponse,
} from "./response-display/generate-table-parse-response-dialog/generate-table-parse-response-dialog.component";
import {first, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

export class ModelSelectionUploadOption {
    name: string;
    parser_type: PARSER_CONFIG_TYPES;
    require_options: boolean;
}

export interface UploadModelFormData {
    parser_type: PARSER_CONFIG_TYPES;
}

export abstract class ModelUploadOptions {
    // placeholder for models that require options.
    // options generation is delegated to a component tailored for the model type.
}

export interface GenerateTableUploadResponse {
    message: string;
    parse_response: GenerateTableParseResponse;
}

export const model_selection_options: ModelSelectionUploadOption[] = [{
    name: 'Ore Body', parser_type: 'orebody', require_options: true,
}, {
    name: 'Event', parser_type: 'event', require_options: true,
}, {
    name: 'Component', parser_type: 'component', require_options: true,
}
];

@Component({
    selector: 'file-model-upload',
    templateUrl: 'upload-model-form-dialog.component.html',
    styleUrls: ['upload-model-form-dialog.component.less', '../../components/upload-model/upload-model.component.less'],
    encapsulation: ViewEncapsulation.None, // Global Styles
    providers: [UploadModelTemplateService],
    standalone: false
})
export class UploadModelFormDialogComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    override: boolean = false;
    file: File = null;
    showing_hints: boolean = false;
    hint: string = 'Name';
    model_selection_options = model_selection_options;
    model_selected: ModelSelectionUploadOption = null;
    upload_options: ModelUploadOptions = null;

    preview: any;

    error_json: any = null;

    uploading: boolean = false;

    constructor(
        public api: ApiService,
        public http: HttpClient,
        public dialogRef: MatDialogRef<UploadModelFormDialogComponent>,
        public dateTimePeriodService: DateTimePeriodService,
        private templateService: UploadModelTemplateService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public dialogData: Record<string, string>) {
        if (dialogData && dialogData.parser_type) {
            this.model_selected = this.model_selection_options.find(ms => ms.parser_type === dialogData.parser_type)
            this.changedSelectedModel();
        }
    }

    ngOnInit(): void {
        this.templateService.stateChanged$.pipe(takeUntil(this.onDestroy)).subscribe(options => {
            this.upload_options = options;
        });
        this.templateService.unmatchedHeadersFound$.pipe(takeUntil(this.onDestroy)).subscribe(unmatched => {
            this.templateService.showUnmatched(unmatched);
        });
    }

    handleFileInput(file:File) {
        if (file) {
            this.file = file;
        } else {
            console.error('Selected input element did not have files property.');
        }

    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    uploadData() {
        const parser_type = this.model_selected.parser_type;
        const formData: FormData = new FormData();
        formData.append('file', this.file, this.file.name);
        formData.append('type', this.model_selected.parser_type);
        if (this.upload_options) {
            formData.append('options', JSON.stringify(this.upload_options));
        }
        formData.append('update_only', (this.upload_options['update_only'] || false).toString());

        this.uploading = true;
        this.templateService.uploadComplete$.pipe(first())
            .subscribe(result => {
                if (result) {
                    this.error_json = result;
                }
                this.uploading = false;
            });
        this.templateService.uploadData(parser_type, formData);
    }

    changedSelectedModel() {
        this.templateService.loadTemplateForType(this.model_selected.parser_type);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}

export function openUploadModelFormComponent(dialog: MatDialog, data: UploadModelFormData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-form-dialog', 'model-upload-form-dialog'];
    dialogConfig.data = data
    dialog.open(UploadModelFormDialogComponent, dialogConfig);
}
