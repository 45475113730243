import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
    flowchart: any,
    component: any
}

@Component({
    selector: 'flowchart-form',
    templateUrl: 'flowchart-form.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class FlowchartFormComponent implements OnInit {

    flowchart: any;
    component: any;
    showHints: boolean = false;
    title: string;
    showing_hints: boolean;

    constructor(public dialogRef: MatDialogRef<FlowchartFormComponent>,
                @Inject(MAT_DIALOG_DATA)
                private dialogData: DialogData) {

    }

    ngOnInit(): void {
        this.flowchart = this.dialogData.flowchart;
        this.component = this.dialogData.component;
    }

    save(event) {
        this.dialogRef.close(this.component);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
