import {Component, ElementRef, Input, Renderer2, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {WireComponent, isComponent} from '../../_models/component';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {forkJoin, Observable, of} from "rxjs";
import {takeUntil, tap, take, concatMap, catchError} from "rxjs/operators";
import {ComponentEventsTableService} from "../../tables/component-events-table/component-events-table.service";
import {ApiService} from '../../services/api/api.service';
import {BaseComponent} from '../../shared/base.component';
import {ActivatedRoute, Router} from "@angular/router";
import {
    ComponentPanelStackConfig,
    ComponentPanelStackConfigData
} from "../../modals/component-panel-stack/component-panel-stack.component";
import {CONFIG_STUB} from "../../services/tile_data.service";
import {ComponentPanelStackService} from "../../modals/component-panel-stack/component-panel-stack.service";
import {ConfigStub} from "../../_typing/config-stub";
import {ComponentType, isComponentType} from "../../_models/component-type";
import {IPendingContextConfig} from "../../forms/pending-context-form/pending-context-form.component";
import {KeyMap} from '../../_typing/generic-types';
import {ComponentFormConfig} from "../../_typing/config/component-form-config";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EventDataService} from "../../data/event-data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {EventType} from "../../_models/event-type";
import {CustomEventsService} from '../../services/custom-events.service';
import {getRelationWithIdFilter} from "../../services/api/filter_utils";
import {ListResponse, SingleResponse} from '../../services/api/response-types';
import {ConstantValue} from "../../_models/api/generic-constant";
import {WireEvent} from "../../_models/event";
import {UserService} from "../../services/user.service";
import {HttpErrorHandler} from "../../services/http-error-handler.service";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export interface RelatedComponentConfig {
    current: WireComponent,
    related: WireComponent
}

@Component({
    selector: 'component-detail',
    templateUrl: './component-detail.component.html',
    styleUrls: ['./component-detail.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ComponentDetailComponent extends BaseComponent {
    @Input() data: ComponentPanelStackConfigData;
    component: WireComponent;
    components: WireComponent[];
    related_component_types: ComponentType[];
    related_component_type_filters: KeyMap<any[]> = {};
    component_form_config: ComponentFormConfig;
    pending_context_config: KeyMap<IPendingContextConfig> = {};
    related_event_types: EventType[];
    related_event_type_filters: KeyMap<any[]> = {};
    show_linked_details: KeyMap<boolean> = {};
    action = {icon_class: 'fa fa-unlink'};
    att_cols = ['name', 'start_time', 'end_time'];
    @Output() loadRelatedComponent = new EventEmitter<RelatedComponentConfig>();

    constructor(private panelStackService: ComponentPanelStackService,
                private componentEventsService: ComponentEventsTableService,
                private api: ApiService, private renderer: Renderer2, private el: ElementRef,
                private snackbar: MatSnackBar,
                private eventData: EventDataService,
                private componentTypeData: ComponentTypeDataService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private userService: UserService,
                httpErrorHandler: HttpErrorHandler) {
        super();
        this.handleError = httpErrorHandler.createHandleError('Component Details');
    }

    ngOnInit(): void {
        this.renderer.addClass(this.el.nativeElement, 'show');
        this.loadComponentData();
    }

    loadComponentData(): void {
        this.api.component.getById(this.data.component_id).pipe(
            concatMap(result => {
                this.component = result.data;
                this.component.type = this.component?.attributes?.base_type || 'component';
                this.component_form_config = {
                    selected_component_type: new ConfigStub<ComponentType>(this.data.config.selected_component_type),
                    selected_cols: this.data.config.selected_cols.component_type.filter(c => c.type === 'constant_property' ||
                        this.att_cols.includes(c.id.toLowerCase())),
                    component: this.component,
                    group_columns: true,
                    selected_name_formula: this.data.config.selected_name_formula
                }
                const rel_ids = this.data.config.selected_component_relationship_types?.map(c => c.id);
                let $component_types: Observable<ListResponse<ComponentType>> = rel_ids ? this.componentTypeData.getComponentTypes(rel_ids) : of([]);
                let $event_types: Observable<ListResponse<EventType>> = this.eventData.getEventTypesByRelationshipIds([this.component.relationships.component_type.data.id], 'component_types')
                return forkJoin($component_types, $event_types).pipe(
                    tap(result => {
                        this.related_component_types = result[0]?.data || [];
                        this.related_event_types = result[1]?.data || [];
                        this.setRelatedEventsFilter();
                        this.setRelatedComponentsFilter();
                    })
                )
            }),
            take(1), takeUntil(this.onDestroy)).subscribe();
    }

    private setRelatedEventsFilter(): void {
        const date_filter = this.data.config.bypass_date_filters ? null : this.dateInst.dtp;
        this.related_event_types.forEach(ret => {
            this.related_event_type_filters[ret.id] = this.eventData.generateEventsForComponentFilter(date_filter, this.component);
            this.related_event_type_filters[ret.id].push(getRelationWithIdFilter('event_type', ret.id))
        })
    }

    private setRelatedComponentsFilter(): void {
        this.related_component_types.forEach(rct => {
            this.related_component_type_filters[rct.id] = this.componentEventsService.getComponentsForComponentFilters(
                this.component.id, rct);
            this.show_linked_details[rct.id] = false;
        })
    }

    canUnlinkComponent() {
        return this.data.config.allow_unlink && this.userService.canUnlinkComponent();
    }

    actionClicked(option: { text: string, value: WireComponent | WireEvent }): void {
        let $obsDelete: Observable<any>;
        if (isComponent(option.value)) {
            $obsDelete = this.componentEventsService.unlinkComponent(option.value, this.component);
        } else {
            $obsDelete = this.componentEventsService.unlinkEvent(option.value, this.component);
        }
        $obsDelete.pipe(take(1), takeUntil(this.onDestroy),
            catchError(this.handleError<SingleResponse<WireComponent>>('Save component')))
            .subscribe(result => {
                if (!result) return; //User cancelled
                if (result === false) {
                    this.snackbar.open('Unable to unlink ' + option.text + '.', 'Hide', {duration: 10000});
                    return;
                }
                if (isComponent(option.value)) {
                    this.related_component_type_filters[option.value.id] = [];
                    this.setRelatedComponentsFilter();
                } else {
                    this.related_event_type_filters[option.value.id] = [];
                    this.setRelatedEventsFilter();
                }
                this.snackbar.open(option.text + ' unlinked succesfully.', undefined, {duration: 3000});
            })

    }

    openRelatedComponent(component: WireComponent): void {
        this.loadRelatedComponent.next({current: this.component, related: component});
    }

    showLinkedItemDetails(rct: ComponentType | EventType, show = true): void {
        if (isComponentType(rct)
        ) {
            this.setPendingContextConfig(rct);
        }
        this.show_linked_details[rct.id] = show;
    }

    private setPendingContextConfig(rct: ComponentType): void {
        if (this.data.config.relationships?.[rct.id]?.pending_context) {
            this.pending_context_config[rct.id] = this.data.config.relationships?.[rct.id].pending_context;
            this.pending_context_config[rct.id].component = this.component
            return;
        }
        const ct_id = this.component.relationships.component_type.data.id;
        this.pending_context_config[rct.id] = {
            type: 'component_to_component',
            context: {
                component_type_from: {id: ct_id, value: null},
                component_type_to: {id: rct.id, value: null},
            },
            customisation: {component_type_to: {}, component_type_from: {}},
            validation: {component_type_from: {[ct_id]: {}}, component_type_to: {[rct.id]: {}}},
            component: this.component
        }
    }
}
