import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TileParameters} from "../../../services/tile_data.service";
import {ConfigStub} from "../../../_typing/config-stub";
import {CustomFunction} from "../../../_models/custom-function";
import {JsonContextService} from "../json-context.service";
import {ReportParameters} from "../type/report-parameters";
import {takeUntil} from "rxjs";
import {BaseComponent} from "../../../shared/base.component";

@Component({
    selector: 'function-builder',
    templateUrl: './function-builder.component.html',
    styleUrls: ['./function-builder.component.less'],
    standalone: false
})
export class FunctionBuilderComponent extends BaseComponent {
    private _config: ReportParameters;

    @Input() set config(config: ReportParameters) {
        this._config = config;
    }

    get config(): ReportParameters {
        return this._config;
    }

    @Output() configChanged = new EventEmitter<ReportParameters>();

    constructor(private jsonContextService: JsonContextService) {
        super();
        // this.jsonContextService.config$.pipe(takeUntil(this.onDestroy)).subscribe((config) => {
        //     this.config = config;
        //     this.configChanged.emit(this.config);
        // });
    }

    updateFunctions(event) {
        this.config.functions = ConfigStub.getConfigStubs<CustomFunction>(event);
        //this.jsonContextService.updateFunctionJsonContext(this.config);
    }
}
