<mat-form-field [ngClass]="classes" class="select-filter override-hide-placeholder" [matTooltip]=getTooltip()
                [matTooltipShowDelay]="1100">
  <mat-label>{{label}}</mat-label>
  <mat-select #filterSelect multiple
              panelClass="select-search-dropdown-panel"
              (selectionChange)="emitConstantChangedComplete($event.value);"
              (click)="$event.stopPropagation()"
              (openedChange)="onOpen($event)"
              [compareWith]="filter_compare || compareByName"
              [allowFit]="allow_fit"
              [appFitContent]="app_fit_content"
              [ngStyle]="conditional_formats || formats?.style"
              [tabindex]="tab_index"
              [placeholder]="getPlaceholder()"
              [value]="modelValue"
  >
    <mat-select-trigger *ngIf="modelValue?.length > 0">
      <span class="additional-selection">
        (+{{modelValue.length}} {{listType}} selected)
      </span>
    </mat-select-trigger>
    <div class="dropdown-container">
      <ng-container *ngIf="options?.length > 5" class="row center sticky">
        <option-list-search (keydown.space)="$event.stopPropagation()"
                            [placeholder]="'Search ' + (listType ?? '')"
                            [selectElementRef]="filterSelect"
                            (inputChanged)="filterOptionList($event)"
                            (inputCleared)="onClear()"
                            [delay]="300">
        </option-list-search>
      </ng-container>
      <div *ngIf="filteredOptions?.length === 0" class="row error">
        <span>"{{errorMessage}}"</span>
      </div>
      <ng-container *ngFor="let option of filteredOptions; let i = index" class="row options">
        <mat-option *ngIf="option?.name && option?.value else defaultTemplate"
                    [value]="option.value" [disabled]="disableItemFunction(option)">{{stringFunction(option) | upperfirst}}
        </mat-option>
        <ng-template #defaultTemplate>
          <mat-option [value]="option"
                      [disabled]="disableItemFunction(option)">{{stringFunction(option) | upperfirst | removeUnderscore}}
          </mat-option>
        </ng-template>
      </ng-container>
    </div>
  </mat-select>
</mat-form-field>
