import {KeyMap} from "../generic-types";

export class ConfigTypeDateFilter {
    constant_property_time: KeyMap<Partial<ConstantPropertyTimeConfig>>;
    attribute_time: KeyMap<Partial<AttributeTimeConfig>>;

    constructor() {
        this.constant_property_time = {};
        this.attribute_time = {};
    }

    static checkKeys(configObject: Partial<ConfigTypeDateFilter>): Partial<ConfigTypeDateFilter> {
        ['constant_property_time', 'attribute_time'].forEach(key => {
            if (!configObject[key]) {
                configObject[key] = {};
            }
        })
        return configObject;
    }
}

export type ConfigTypeDateFilterKey = 'constant_property_time' | 'attribute_time';

export class ConstantPropertyTimeConfig {
    start_prop: string;
    end_prop: string;
    enabled: boolean;

    constructor() {
        this.enabled = false;
    }
}

export class AttributeTimeConfig {
    attribute: string;
    enabled: boolean;

    constructor() {
        this.enabled = false;
    }
}