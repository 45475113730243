import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {MatSelectChange} from "@angular/material/select";
import {CategoryChartTileConfiguration} from "./category-chart-tile.configuration";
import {Series} from "../../_models/series";
import {OptionalSamplePeriod} from "../../_typing/date-time-period";

@Component({
    selector: 'category-chart-form',
    templateUrl: './category-chart-form.component.html',
    styleUrls: ['./category-chart-form.component.less'],
    standalone: false
})
export class CategoryChartFormComponent implements OnInit {
    @Input()
    config: CategoryChartTileConfiguration;

    @Output() configChange = new EventEmitter();
    full_series_list: Series[];

    public chart_types: string[] = ['bar', 'line'];

    constructor(private api: ApiService,
                public dateTimePeriodService: DateTimePeriodService,) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.api.series_light.searchMany().toPromise().then((result) => {
            ctrl.full_series_list = result.data;
        });
    }

    compareSamplePeriod(a: OptionalSamplePeriod, b: OptionalSamplePeriod): boolean {
        return a.seconds === b.seconds;
    }

    addSeriesToConfig() {
        this.config.series_list.push({
            name: null,
            series_id: null,
            type: "category",
            sample_period: null,
            number_of_periods: null,
            color: null,
            chart_type: 'bar'
        });
    }

    updateConfig(event: MatSelectChange, i: number) {
        this.config.series_list[i].name = event.value.attributes.name;
        this.config.series_list[i].series_id = event.value.id;
    }

    deleteSeries(i: number) {
        this.config.series_list.splice(i, 1);
    }
}
