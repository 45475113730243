import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class ConstantComponentAttributes extends AuditMixinAttributes {
    name?: string;
    value?: string | number | Date;
    json?: any = {};
}

export class ConstantComponentRelationships extends AuditMixinRelationships {
    component: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    constant_property: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class ConstantComponent extends AuditMixin {
    id: string;
    attributes: ConstantComponentAttributes;
    relationships: ConstantComponentRelationships;
    type: string = 'constant_component';

    constructor() {
        super();
        this.attributes = new ConstantComponentAttributes();
        this.relationships = new ConstantComponentRelationships();
    }
}
