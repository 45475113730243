import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from "rxjs";
import {Series} from "../../_models/series";
import {ConstantProperty} from "../../_models/constant-property";
import {takeUntil} from "rxjs/operators";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from "@angular/material/table";
import {Event as WireEvent} from "../../_models/event";
import {ConstantPropertyEventsService} from "./constant-property-events.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'constant-property-events',
    templateUrl: './constant-property-events.component.html',
    styleUrls: ['./constant-property-events.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConstantPropertyEventsService],
    standalone: false
})
export class ConstantPropertyEventsComponent implements OnInit, OnChanges, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() series: Series;
    @Input() dtp: IDateTimePeriod;
    events: WireEvent[];
    constant_property: ConstantProperty;
    columns: string[] = [];

    @ViewChild(MatPaginator) event_paginator: MatPaginator;
    pageEvent: PageEvent;
    private sort;
    dataSource: MatTableDataSource<any>;
    page_size = 5;
    page_size_options = [5, 10, 20, 50];
    page_index = 0;
    event_total;
    loading_next = false;
    filter_warning = '';

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private cps: ConstantPropertyEventsService) {
    }

    ngOnInit(): void {
        this.cps.$collationData.pipe(
            takeUntil(this.onDestroy)
        ).subscribe((collation_data) => {
            this.events = collation_data.events.data;
            this.event_total = collation_data.events.meta.count;
            this.constant_property = collation_data.constant_property;
            this.createEventsTable();
        })

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dateInst.dtp = dtp;
            this.cps.getEventData(this.series, this.page_size, 1);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.series && changes.series.currentValue) {
            this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.event_paginator;
            this.cps.getEventData(this.series, this.page_size, 1);
        }
        /**Different dates can be selected on the chart for e.g., as apposed to dtpReset**/
        if (changes.dtp && changes.dtp.previousValue) {
            this.dateInst.dtp = changes.dtp.currentValue;
            this.cps.getEventData(this.series, this.page_size, 1);
        }
    }

    pageData(event) {
        this.page_index = event.pageIndex;
        this.page_size = event.pageSize;
        this.cps.getEventData(this.series, this.page_size, this.page_index + 1);
    }

    createEventsTable(): void {
        this.columns = ['start', 'end'].concat(this.constant_property.attributes.name);

        this.dataSource.data = this.events;
        this.dataSource.filterPredicate = (data, filter) => {
            return (data.attributes.name && data.attributes.name.toLowerCase().includes(filter.toLowerCase()));
        };

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (true) {
                case sortHeaderId === "name":
                    return data.attributes.name;
                default:
                    return "";
            }
        };

        this.loading_next = false;
        //this.changeDetectorRef.markForCheck();
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
        if (filterValue) {
            this.filter_warning = 'Displaying current Page filter results. Please click Search to display the full table results.'
        }
        //this.filteredData.next();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
