import {Injectable} from '@angular/core';
import {
    BarChartConfiguration,
    isBarChartConfiguration,
    UnionChartConfiguration
} from "../charts/chart-config/chart-configuration";
import {
    PlotlyChartConfigTranslationService,
    PlotlyChartConfiguration,
    PlotlyDataConfiguration, PlotlyLayoutConfiguration
} from "./plotly-chart-config-translation.service";

@Injectable({
    providedIn: 'root'
})
export class PlotlyBarChartConfigTranslationService extends PlotlyChartConfigTranslationService {

    constructor() {
        super()
    }

    configureChart(config: BarChartConfiguration): Partial<PlotlyChartConfiguration> {
        let plotlyChart: PlotlyChartConfiguration = {
            data: this.configurePlotlyData(config),
            layout: this.configurePlotlyLayout(config),
            config: this.configurePlotlyConfig(config)
        }
        return plotlyChart;
    }

    private configurePlotlyData(config: BarChartConfiguration): PlotlyDataConfiguration[] {
        let data = [];
        config.key_values.forEach(key => {
            const x = config.orientation === 'h' ? config.y_values[key] : config.x_values['times'];
            const y = config.orientation === 'h' ? config.x_values['times'] : config.y_values[key];
            let trace: Partial<PlotlyDataConfiguration> = this.configureBasePlotlyData(config, x, y, key);
            trace.name = config.names[key];
            trace.orientation = config.orientation;
            trace.textposition = "inside";
            trace.insidetextanchor = "middle";
            data.push(trace);
        })
        return data;
    }

    configurePlotlyLayout(config: UnionChartConfiguration): PlotlyLayoutConfiguration {
        let layout: PlotlyLayoutConfiguration = this.configureBasePlotlyLayout(config);
        if (isBarChartConfiguration(config)) {
            layout.barmode = config.barmode;
            layout.showlegend = true;
            layout.legend = {
                orientation: "h",
                x: 0.5,
                xanchor: 'center',
                traceorder: 'normal'
            }
        }
        return layout;

    }
}
