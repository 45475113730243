<div class="form-dialog event-type-form-dialog">
  <h1 cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane"><span
      [textContent]="event_type && event_type.id ? 'Edit event type: ' + event_type.attributes.name :  'Add event type'"></span>
  </h1>
  <mat-dialog-content>
    <mat-tab-group #event_type_tabs (selectedTabChange)="onTabChanged($event)" [disableRipple]="true">
      <mat-tab label="Basic">
        <form *ngIf="event_type" class="form-section column space-between basic">
          <div class="row medium-height">
            <mat-form-field class="inline" style="flex-basis:50%;flex-grow:0;">
              <label>Type</label>
              <mat-select [(ngModel)]="event_type.attributes.base_type" [compareWith]="matSelectCompare"
                          name="base_type">
                <mat-option *ngFor="let base of eventService.base_types" [value]="base">{{ base }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row medium-height">
            <mat-form-field class="inline" style="flex-basis:50%;flex-grow:0;">
              <label>Name</label>
              <input [(ngModel)]="event_type.attributes.name" matInput name="name" placeholder="">
            </mat-form-field>
          </div>
          <div class="row medium-height">
            <mat-form-field class="inline" style="flex-basis:50%;flex-grow:0;">
              <label>Severity</label>
              <input [(ngModel)]="event_type.attributes.severity" matInput name="severity" placeholder=""
                     type=number>
            </mat-form-field>
          </div>
          <div class="row medium-height">
            <mat-form-field class="inline" style="flex-basis:50%;flex-grow:0;">
              <label>Behaviour</label>
              <input [(ngModel)]="event_type.attributes.behaviour" matInput name="behaviour" placeholder="">
            </mat-form-field>
          </div>
          <div class="row center">
            <div class="select-search-many" style="width:50%;;overflow:unset !important;margin-right:10px;">
              <select-many-search-component (selectionChange)="event_type.relationships.ore_body_types.data=$event;"
                                            [(value)]="event_type.relationships.ore_body_types.data"
                                            [classes]="'fill'"
                                            [api_model]="'ore_body_type'"
                                            [label]="'Ore Body Types'">
              </select-many-search-component>
            </div>
            <button (click)="goToTab(event_type_tabs, 3)" *ngIf="event_type.id" class="bordered-button" mat-button>
              Select and edit Ore Body Types relationship
              <i class="fa fa-caret-right" style="margin-left:10px;height: 21px;font-size: 18px;"></i>
            </button>
          </div>

          <div class="row center">
            <div class="select-search-many" style="width:50%;overflow:unset !important;margin-right:10px;">
              <select-many-search-component
                  (itemsRemoved)="removed_component_types=$event;"
                  (itemsAdded)="added_component_types=$event;"
                  [custom_selected_filters]="selected_component_types_filter"
                  [custom_filters]="selected_component_types_reverse_filter"
                  [value]="select_component_types"
                  [classes]="'fill'"
                  [api_model]="'component_type'"
                  [label]="'Component Types'">
              </select-many-search-component>
            </div>
            <button (click)="goToTab(event_type_tabs, 4)" *ngIf="event_type.id" class="bordered-button" mat-button>
              Select and edit Component Types relationship
              <i class="fa fa-caret-right" style="margin-left:10px;height: 21px;font-size: 18px;"></i>
            </button>
          </div>
        </form>
      </mat-tab>

      <mat-tab *ngIf="event_type?.id" label="Series">
        <form class="form-section column space-between series">
          <div class="select-search-many" style="flex-grow:2">
            <select-many-search (selectChange)="event_type.relationships.series_list.data=$event"
                                *ngIf="series_list && series_list.length>0"
                                [(value)]="event_type.relationships.series_list.data"
                                [classes]="'fill'"
                                [data]="series_list"
                                [fill_stubs]="true"
                                [label]="'Series'">
            </select-many-search>
          </div>
          <table
              *ngIf="event_type?.relationships.series_list.data && event_type.relationships.series_list.data.length > 0"
              class="table event-type-series-table">
            <thead>
            <tr>
              <th>Edit</th>
              <th>Name</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let series of event_type.relationships.series_list.data">
              <td class="edit"><i (click)="editSeries(series)" class="edit-inline hide-xs"></i></td>
              <td>{{series_dict[series.id]?.attributes.name}} </td>
              <td>{{series_dict[series.id]?.attributes.description}}</td>
            </tr>
            </tbody>
          </table>
        </form>
      </mat-tab>

      <mat-tab *ngIf="event_type?.id" label="Constant Properties" style="width:210px;">
        <ng-template matTabContent>
          <form class="form-section column space-between">

            <div style="height:470px;max-height:470px;">
              <constant-property-table-form
                  [parent_component]="event_type"
              ></constant-property-table-form>
            </div>
          </form>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="event_type?.id" label="Ore Body Associations">
        <ng-template matTabContent>
          <form *ngIf="event_type" class="form-section column space-between">
            <div class="row">
              <div class="select-search" style="flex-grow:2">
                <select-many-search-component (selectionChange)="event_type.relationships.ore_body_types.data=$event;"
                                              [(value)]="event_type.relationships.ore_body_types.data"
                                              [classes]="'fill'"
                                              [api_model]="'ore_body_type'"
                                              [label]="'Ore Body Types'">
                </select-many-search-component>
              </div>
            </div>
            <div class="info">Editing these constant properties will change the constant properties on all Event Types
              associated with these Ore Body Types
            </div>
            <ng-container *ngFor="let obt of event_type.relationships.ore_body_types.data">
              <h3 class="constant-property-header" style="margin-bottom:-10px;">Ore Body
                Type: {{ore_body_type_dict[obt.id]?.attributes?.name}}</h3>
              <constant-property-table-form
                  [parent_component]="obt"
                  [hide_select_list]="true"
                  style="margin-top:20px;"
              ></constant-property-table-form>
            </ng-container>

            <a (click)="onCloseClick()" [routerLink]="'/view/ore_body_types/'"
               style="color:darkorange;margin-top:20px;">Go to Ore Body Types table
            </a>

          </form>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="event_type?.id" label="Component Types">
        <ng-template matTabContent>

          <form *ngIf="event_type" class="form-section column space-between component-type">
            <div class="row">
              <div class="select-search" style="flex-grow:2">
                <select-many-search-component (selectionChange)="selected_component_types=$event;"
                                              [(value)]="selected_component_types"
                                              [classes]="'fill'"
                                              [api_model]="'component_type'"
                                              [label]="'Component Types'">
                </select-many-search-component>
              </div>
            </div>
            <table
                *ngIf="selected_component_types?.length > 0"
                class="table event-type-series-table" st-table="rowCollection">
              <thead>
              <tr>
                <th>Edit</th>
                <th>Name</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let component_type of selected_component_types">
                <td class="edit"><i (click)="editComponentType(component_type)" class="edit-inline hide-xs"></i></td>
                <td>{{component_type.attributes.name}} </td>
                <td>{{component_type.attributes.description}}</td>
              </tr>
              </tbody>
            </table>

            <a (click)="onCloseClick()" [routerLink]="'/view/component_types/'"
               style="color:darkorange;margin-top:20px;">Go to Component Types table
            </a>

          </form>
        </ng-template>
      </mat-tab>

      <mat-tab label="Source/Destination Mappings">
        <ng-template matTabContent>
          <form class="form-section column space-between">
            <ng-container *ngIf="event_type && sd_ore_body_types">
              <div
                  *ngIf="saved_event_type.relationships.ore_body_types.data !== event_type.relationships.ore_body_types.data"
                  class="info"
                  style="margin-bottom:10px;">
                Please remember to apply your changes to see the relevant data in this table.
              </div>
              <source-destination-table-form [event_types]="[saved_event_type]"
                                             [ore_body_types]="sd_ore_body_types"
                                             [pivot]="true">
              </source-destination-table-form>
            </ng-container>
          </form>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save">Save</button>
    <button (click)="apply()" class="btn-save">Apply</button>
  </div>
</div>
