import {Component, Input, ViewEncapsulation} from '@angular/core';
import {SolverRunOutput} from '../solver-runner/solver-runner.component';

@Component({
    selector: 'solver-data-table',
    templateUrl: './solver-data-table.component.html',
    styleUrls: ['./solver-data-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolverDataTableComponent {
    private _data: SolverRunOutput;
    @Input() set data(data: SolverRunOutput) {
        this._data = data;
    }

    get data(): SolverRunOutput {
        return this._data;
    }
}
