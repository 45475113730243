<div #user_list class="at-who">
  <mat-action-list appScrollable id="at_who_user_list" tabindex="1" (infiniteScroll)="getNextBatch()"
                   [complete]="!sapi.loadNext" msDivInfiniteScroll>
    <button (click)="addWho(user)" (mouseover)="updateCurrent(user)" *ngFor="let user of filtered_users"
            [ngClass]="{'current': user===filtered_users[list_index]}"
            mat-list-item> {{user.attributes.name + ' ' + (user.attributes.last_name || '')}}<br>
            {{'@' + user.attributes.username.trim()}} </button>
    <div *ngIf="sapi.loadingAvailable" class="stage option-loading">
      <div class="dot-flashing"></div>
    </div>
    <div *ngIf="!sapi.loadingAvailable && filtered_users?.length == 0" class="row center option-loading">
      No results found
    </div>
  </mat-action-list>
</div>
