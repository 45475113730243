import * as Handsontable from "handsontable"
import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {HotInstance} from "../../services/hot-instance";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {tap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";

/**
 * Assigned from construction <code>locals</code> options...
 */
@Component({
    selector: 'stream-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None //Global Styles
    ,
    standalone: false
})
export class StreamSheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    title: string = 'Streams';
    search: string;
    // TODO add setters and cater for when the inputs change
    @Input() processes: any;
    @Input() process: any;
    @Input() streams: any;

    users: any;
    revisions: any;
    component_types: any;
    series_light: any;

    schema: any = {
        id: null,
        type: 'stream',
        attributes: {
            base_type: 'stream',
            name: null,
            description: null,
            code: null,
            created_on: null,
            changed_on: null
        },
        relationships: {
            start: {data: {id: null, type: 'process'}},
            end: {data: {id: null, type: 'process'}},
            created_by: {data: {id: null, type: 'users'}},
            changed_by: {data: {id: null, type: 'users'}},
            component_type: {data: {id: null, type: 'component_type'}}
        }
    };
    column_list: any[] = [{
        data: 'attributes.code',
        title: 'Code'
    }, {
        data: 'attributes.name',
        title: 'Name'
    }, {
        data: 'attributes.description',
        title: 'Description'
    }];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        this.headerData.buttons = [];

        const observables: Observable<any>[] = [];

        // TODO test both cases in this if (ie. when this.processes are not data bound from another component)
        if (this.processes == null) {
            observables.push(this.api.process_light.searchMany().pipe(tap(processes => this.processes = processes.data)));
        }

        // TODO test both cases in this if (ie. when this.streams are not data bound from another component)
        if (!this.streams) {
            observables.push(this.api.stream.searchMany().pipe(tap(response => this.streams = response.data)));
        }

        const componentTypeOptions = new SearchQueryOptions();
        componentTypeOptions.filters = [{
            name: 'base_type',
            op: 'eq',
            val: 'stream'
        }];

        observables.push(this.api.component_type.searchMany(componentTypeOptions).pipe(
            tap(response => this.component_types = response.data)));

        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));
        observables.push(this.api.revision.searchMany().pipe(tap(response => this.revisions = response.data)));
        observables.push(this.api.series_light.searchMany().pipe(tap(response => this.series_light = response.data)));

        forkJoin([observables]).subscribe(this.createTable.bind(this));

        this.buildHeader();
    }

    createTable() {
        let componentTypeLookups = this.handsontableGenerator.gen_lookups(this.component_types || [], item => item.attributes.name);
        let revisionLookups = this.handsontableGenerator.gen_lookups(this.revisions || [], item => item.attributes.name);
        let processLookups =
            this.handsontableGenerator.gen_lookups(this.processes || [], item => item.attributes.name + '-' + item.attributes.description);

        this.column_list.push.apply(this.column_list, [{
            data: this.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(processLookups, 'start'),
            title: 'Start',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: processLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(processLookups, 'end'),
            title: 'End',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: processLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On'
        }]);

        setTimeout(() => {
            this.hot = this.handsontableGenerator.generateTable(this.api.stream, this.schema, this.column_list);
            this.hot.ready = true;
            this.hot.settings.data = this.streams;
            this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
        })
    }

    save() {
        let results = this.hot.save();
        this.streams = results.data;
    }

    download() {
        // TODO what is this download doing without specifying a file to download?
        // @ts-ignore
        this.hot.download();
    }

    buildHeader() {
        if (this.process) {
            this.headerData.title = 'Streams: ' + this.process.attributes.name;
        } else {
            this.headerData.title = 'Streams';
        }
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}}
        ];
    }
}
