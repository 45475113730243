<div class="row hideonprint utils-bar">
  <div class="table-filter">
    <form (submit)="relaySearchSubmitted()" *ngIf="search" class="row center">
      <div class="row center" style="position:relative;">
        <input [(ngModel)]="searchValue" name="filter" class="table-filter-input" placeholder="Search table"
               (keyup)="relaySearchValueChanged($event.target.value)"/>
        <mat-icon (click)="relaySearchCleared();"
                  [ngClass]="{'clear-disabled': searchValue?.length < 1}"
                  class="search-clear" matTooltip="Clear database search" [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY">
          clear
        </mat-icon>
      </div>
      <div class="row center">
        <select-many-filter *ngIf="userSearchFields && sortByArray"
                            [disabled]="!userSearchFields?.length"
                            (constant_changed)="relaySearchSelectionsChanged($event)"
                            [modelValue]="userSearchSelections"
                            [options]="userSearchFields"
                            [placeholder]="'Select columns'"
                            [listType]="'columns'"
        >
        </select-many-filter>
      </div>
      <button *ngIf="search" type="submit" [disabled]="!searchValue" mat-flat-button
              class="search-button" color="accent">
        <i class="fa fa-search"></i>
      </button>
    </form>

  </div>
</div>
