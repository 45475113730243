import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {EventAttributes, EventRelationships} from "./event";
import {ModelID} from "../_typing/generic-types";

export class FolderSessionStateAttributes extends AuditMixinAttributes {
    order?: number;
}

export class FolderSessionStateRelationships extends AuditMixinRelationships {
    session_state: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    folder: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class FolderSessionState extends AuditMixin {
    id: string;
    type: string = 'folder_session_state';
    attributes: FolderSessionStateAttributes;
    relationships: FolderSessionStateRelationships;

    constructor() {
        super();
        this.attributes = new FolderSessionStateAttributes();
        this.relationships = new FolderSessionStateRelationships();
    }
}