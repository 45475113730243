import {Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output} from '@angular/core';
import * as utils from "../../lib/utils";
import {difference as _difference} from "lodash-es";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {SeriesDataService} from "../../services/series_data.service";
import {ColumnFormatsConfig} from "../../forms/table-column-menu/table-column-menu.component";
import {KeyMap, ModelID} from "../../_typing/generic-types";
import {SeriesTableConfig} from "../../_typing/config/series-table-config";
import {ShiftType} from "../../_models/shift_type";
import {ConfigStub} from "../../_typing/config-stub";

/**A Component for selecting series columns (Value, MTD etc), used on forms such as series-table-form
 * and series-data-table-form
 */
@Component({
    selector: 'series-summary-column-selector',
    templateUrl: './series-summary-column-selector.component.html',
    styleUrls: ['./series-summary-column-selector.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesSummaryColumnSelectorComponent implements OnInit {
    @Input() include_descriptor_columns: boolean = true;
    @Input() disabled = false;
    @Input() allowReverseFavourability: boolean = false;

    @Input()
    set config(config: SeriesTableConfig) {
        this._config = config;
        if (!this._config) {
            this._config = {columns: []};
        }
        if (!this._config.column_formats) {
            this._config.column_formats = {};
        }
        if (!this._config.mobile_columns) {
            this._config.mobile_columns = [];
        }
        if (!this._config.actuals_favourability_estimate_dict) {
            this._config.actuals_favourability_estimate_dict = {};
        }
    }

    @Output() columnsChanged: EventEmitter<SeriesTableConfig> = new EventEmitter();

    get config(): SeriesTableConfig {
        return this._config;
    }

    private _config: SeriesTableConfig;

    estimate_types: any[];
    estimateColumns: string[];
    col_dict: any;
    all_columns: any;
    preset_colours: string[];
    col_menu_options: string[] = ['bold', 'abbreviate', 'italic', 'size', 'resize', 'fit_content', 'colour', 'background_colour', 'decimals', 'align'];
    private colFilter: any;
    availableColumnFilterValue: string = '';
    selectedColumnFilterValue: string = '';
    availableMobileColumnFilterValue: string = '';
    selectedMobileColumnFilterValue: string = '';
    indexDict: KeyMap<number> = {};
    mobileIndexDict: KeyMap<number> = {};

    constructor(private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        this.seriesData.$estimate_types.promise.then(response => {
            this.estimate_types = response.map(e => e.attributes.name);
            this.estimateColumns = this.seriesData.fore_cast_cols.map(col => col.name.replace(" @", ""));
            this.estimateColumns = this.estimateColumns.map(col => col.replace("@", "Value"));
            if (!ctrl.config.estimate_type) {
                ctrl.config.estimate_type = ['Forecast'];
            }
            if (!ctrl.config.column_formats) {
                ctrl.config.column_formats = {};
            }
            ctrl.colFilter = utils.deepCopy(ctrl._config.estimate_type);
            ctrl.col_dict = ctrl.seriesData.column_dict;
            ctrl.updateColumns();
        })
    }

    shiftTypeChanged($event) {
        this.config.shift_type_id = $event ? $event.value?.id : 0;
    }

    updateColumns(event?) {
        if (event) this.config.estimate_type = event;

        let filter_out = null;
        this.all_columns = this.seriesData.fillColumns(this.config.estimate_type);

        //Remove selected, deprecated and excluded columns from the available list
        this.all_columns.all = this.all_columns.columns.filter(el => {
            return !this.config.columns.includes(el.name) && !el.deprecated === true &&
                !(this.include_descriptor_columns === false && (el.name === 'Name' || el.name === 'Description'))
        }).map(el => el.name);

        this.all_columns.mobile = utils.deepCopy(this.all_columns.all).filter((el) => {
            return !this.config.mobile_columns.includes(el)
        });

        //Remove the old estimate_type ones from the selected_list
        if (_difference(this.colFilter, this.config.estimate_type).length > 0) {
            //One has been removed
            filter_out = _difference(this.colFilter, this.config.estimate_type)
        }

        if (filter_out) {
            this.config.columns = this.seriesData.filterColumns(this.config.columns, filter_out);
            this.config.mobile_columns = this.seriesData.filterColumns(this.config.mobile_columns, filter_out);
            //ctrl._config.detail_columns = ctrl.seriesData.filterColumns(ctrl._config.detail_columns, ctrl.colFilter);
        }
        this.colFilter = utils.deepCopy(this.config.estimate_type);
    };

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        } else {
            const mobile = event.container.id.includes("Mobile");
            let prevIndex = (!mobile && this.indexDict[event.item.element.nativeElement.innerText]) ||
                (mobile && this.mobileIndexDict[event.item.element.nativeElement.innerText]) || event.previousIndex;
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                prevIndex,
                event.currentIndex);
        }
        this.columnsChanged.emit(this.config);
    }

    shouldShowFavourabilityEstimateSelect(columnName) {
        const column = this.all_columns.columns.find(c => c.name === columnName);
        return !column?.estimate_name && this.estimateColumns.includes(columnName);
    }

    columnFilter(item, value, index): boolean {
        // store item index for use in determine actual position in the items list
        const i = this.col_dict[item] ? this.col_dict[item].title : item
        this.indexDict[i] = index;

        return i.toLowerCase().includes(value.toLowerCase());
    }

    mobileColumnFilter(item, value, index): boolean {
        // store item index for use in determine actual position in the items list
        const i = this.col_dict[item] ? this.col_dict[item].title : item
        this.mobileIndexDict[i] = index;

        return item.toLowerCase().includes(value.toLowerCase());
    }
}
