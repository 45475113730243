import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormBuilder, FormControl} from '@angular/forms';
import {ApiService} from "../../services/api/api.service";
import {ConstantProperty} from '../../_models/constant-property';
import {ComponentType} from "../../_models/component-type";
import {EventType} from "../../_models/event-type";
import {FieldMap, Option, QueryBuilderConfig, RuleSet} from 'ngx-angular-query-builder';
import {CustomEventsService} from "../../services/custom-events.service";
import {map, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject} from "rxjs";
import {COMPONENT_EVENTS_CONFIG} from "../component-events-table-form/component-events-table-form.component";
import {ListResponse} from '../../services/api/response-types';
import {MatButton} from "@angular/material/button";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {mapOrder, sortObjectsByFlatProperty} from '../../lib/utils';
import {KeyMap} from '../../_typing/generic-types';
import {User} from "../../_models/users";

@Component({
    selector: 'query-builder-form',
    templateUrl: 'query-builder-form.component.html',
    styleUrls: ['./query-builder-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class QueryBuilderFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('addFilter') addFilter: MatButton;
    @Input() tileConfig: COMPONENT_EVENTS_CONFIG;
    @Input() content: string;
    @Input() modelTypeId: string;

    @Input() query: RuleSet | null;
    @Output() queryChange: EventEmitter<RuleSet | null> = new EventEmitter<RuleSet | null>();

    private readonly onDestroy = new Subject<void>();
    public usersOptions: Option[] = [];
    @Input() filter_cp_dict: { [key: string]: ConstantProperty };
    @Input() filter_constant_properties: ConstantProperty[] = [];
    @Input() filter_component_type: ComponentType;
    @Input() filter_event_types: EventType[];
    @Input() filter_event_type_component_types: ComponentType[];
    @Input() isFilterBuilder: Boolean = false;
    @Input() selected_cols_dict: KeyMap<EventConfigColumn>;
    cp_dict: { [key: string]: ConstantProperty };
    constant_properties: ConstantProperty[] = [];
    component_types: ComponentType[] = [];
    event_type_component_types: ComponentType[] = [];
    event_types: EventType[] = [];
    ordered_column_options: string[];
    public queryBuilderCtrl: FormControl;
    public config_ready = false;

    selected_field: any;
    // Operators used by the query UI library
    operatorMap = {
        string: ['ilike', 'not ilike', 'like', 'not like', '=', '!=', 'blank', 'not blank'],
        number: ['=', '!=', '>', '>=', '<', '<=', 'blank', 'not blank'],
        time: ['=', '!=', '>', '>=', '<', '<=', 'blank', 'not blank'],
        date: ['=', '!=', '>', '>=', '<', '<=', 'blank', 'not blank'],
        datetime: ['>', '>=', '<', '<=', '=', '!=', 'blank', 'not blank'],
        category: ['=', '!=', 'in', 'not in', 'blank', 'not blank'],
        boolean: ['=', '!=', 'blank', 'not blank'],
        dropdown: ['=', '!=', 'blank', 'not blank']
    };

    // Operator descriptions only for use on UI
    operatorDesc = {
        '=': 'Equals to',
        '!=': 'Not equal to',
        '>': 'Greater than',
        '<': 'Less than',
        '>=': 'Greater than or equal to',
        '<=': 'Less than or equal to',
        'like': 'Contains (case sensitive)',
        'ilike': 'Contains (case insensitive)',
        'not like': 'Does not contain (case sensitive)',
        'not ilike': 'Does not contain (case insensitive)',
        'in': 'Contains',
        'not in': 'Does Not Contain',
        'blank': 'Is blank',
        'not blank': 'Is not blank'
    };

    // Searchable attributes on a Component, alongside Constant Properties
    get defaultComponentTypeFields() {
        return {
            ...(!this.isFilterBuilder || this.selected_cols_dict?.name) && ({name: {name: 'Name', type: 'string'}}),
            ...(!this.isFilterBuilder || this.selected_cols_dict?.start_time) && ({
                start_time: {
                    name: 'Start Time',
                    type: 'datetime'
                }
            }),
            ...(!this.isFilterBuilder || this.selected_cols_dict?.end_time) && ({
                end_time: {
                    name: 'End Time',
                    type: 'datetime'
                }
            }),
            ...(!this.isFilterBuilder || this.selected_cols_dict?.created_on) && ({
                created_on: {
                    name: 'Created On',
                    type: 'datetime'
                }
            }),
            ...this.userAuditFields
        };
    }

    // Searchable attributes on an Event, alongside Constant Properties
    get defaultEventTypeFields() {
        return {
            ...(!this.isFilterBuilder || this.selected_cols_dict?.start) && ({
                start: {
                    name: 'Start Time',
                    type: 'datetime'
                }
            }),
            ...(!this.isFilterBuilder || this.selected_cols_dict?.end) && ({
                end: {
                    name: 'End Time',
                    type: 'datetime'
                }
            }),
            ...(!this.isFilterBuilder || this.selected_cols_dict?.created_on) && ({
                created_on: {
                    name: 'Created On',
                    type: 'datetime'
                }
            }),
            ...this.userAuditFields
        };
    }

    get userAuditFields() {
        //TODO make these and start, end etc constants, update event table configs to use the correct backend keys
        const changedBy: EventConfigColumn = this.selected_cols_dict?.['changed by'] || this.selected_cols_dict?.['changed_by'];
        return {
            ...(!this.isFilterBuilder || changedBy) &&
            ({changed_by: {name: changedBy?.title || 'Changed By', type: 'dropdown', options: this.usersOptions}})
        };
    }

    // Initial state for the form builder
    defaultQuery = {
        condition: 'and',
        rules: []
    };

    baseConfig: QueryBuilderConfig = {
        fields: {}
    };

    config: QueryBuilderConfig = this.baseConfig;

    data_schema = ['name'];

    constructor(private api: ApiService,
                public customEventsService: CustomEventsService,
                private formBuilder: FormBuilder) {

        this.queryBuilderCtrl = this.formBuilder.control(this.defaultQuery);
    }

    ngAfterViewInit(): void {
        if (this.isFilterBuilder && !this.query) {
            this.addFilter._elementRef.nativeElement.click();
        }
    }

    ngOnInit(): void {
        if (this.isFilterBuilder) {
            this.component_types = [this.filter_component_type];
            this.cp_dict = this.filter_cp_dict;
            this.constant_properties = this.filter_constant_properties;
            this.event_type_component_types = this.filter_event_type_component_types;
            this.ordered_column_options = sortObjectsByFlatProperty(Object.values(this.selected_cols_dict), 'index')
                .map(c => this.mapToFieldFormat(c));
            let fields = {
                ...(this.filter_component_type ? this.defaultComponentTypeFields : this.defaultEventTypeFields),
                ...this.fieldsFromConstantProperty(this.constant_properties)
            };
            //TODO component_type filters not working here and event_types are not available on component_events table
            //so not sure if adding to fields is actually necessary here.
            if (this.filter_event_types) {
                this.event_types = this.filter_event_types;
                fields = Object.assign(fields, this.fieldsFromComponentType(this.event_type_component_types));
            } else {
                this.customEventsService.getComponentTypeEventTypes([this.filter_component_type.id])
                    .pipe(map(res => {
                        this.event_types = res.data;
                        fields = Object.assign(fields, this.fieldsFromEventType(this.event_types));
                    }));
            }

            if (this.selected_cols_dict?.name?.title) {
                fields['name'].name = this.selected_cols_dict.name.title;
            }

            this.config = {...this.baseConfig, fields};
            this.config_ready = true;

        } else {
            if ((this.content === 'component-events-table' || this.content === 'component') && this.modelTypeId) {
                this.loadComponentType();
            } else if ((this.content === 'custom-events-table' || this.content === 'event') && this.modelTypeId) {
                this.loadEventType();
            }
        }
        this.getUserOptionList();
    }

    loadComponentType() {
        let ctrl = this;
        let $fields = this.getComponentTypeFields(this.modelTypeId);

        $fields.pipe(takeUntil(this.onDestroy)).subscribe(fields => {
            ctrl.config = {...ctrl.baseConfig, fields};
            this.config_ready = true;
            if (ctrl.query) {
                setTimeout(() => {
                    ctrl.queryBuilderCtrl.setValue(ctrl.query);
                });
            }
        });
    }

    getComponentTypeFields(component_type_id): Observable<FieldMap> {
        const ctrl = this;

        const $componentTypes = ctrl.customEventsService.getComponentTypeComponentTypes(component_type_id)
            .pipe(map(res => {
                ctrl.component_types = res.data;
                return this.fieldsFromComponentType(ctrl.component_types);
            }));

        const $eventTypes = ctrl.customEventsService.getComponentTypeEventTypes([component_type_id])
            .pipe(map(res => {
                ctrl.event_types = res.data;
                return this.fieldsFromEventType(ctrl.event_types);
            }));

        const $properties = ctrl.customEventsService.getComponentTypeProperties([component_type_id])
            .pipe(map(res => {
                ctrl.constant_properties = res.data;
                return this.fieldsFromConstantProperty(ctrl.constant_properties);
            }));

        return forkJoin([$properties, $componentTypes, $eventTypes]).pipe(
            map(([propertiesField, componentTypesField, eventTypesField]) => {
                return {...this.defaultComponentTypeFields, ...this.userAuditFields, ...propertiesField, ...componentTypesField, ...eventTypesField};
            })
        )
    }

    loadEventType() {
        let ctrl = this;
        let $fields = this.getEventTypeFields(this.modelTypeId);

        $fields.pipe(takeUntil(this.onDestroy)).subscribe(fields => {
            ctrl.config = {...ctrl.baseConfig, fields};
            this.config_ready = true;
            if (ctrl.query) {
                setTimeout(() => {
                    ctrl.queryBuilderCtrl.setValue(ctrl.query);
                })
            }
        });
    }

    getEventTypeFields(event_type_id): Observable<FieldMap> {
        const ctrl = this;

        const $componentTypes = ctrl.customEventsService.getEventTypeComponentTypes([event_type_id])
            .pipe(map(res => {
                ctrl.component_types = res.data;
                return this.fieldsFromComponentType(ctrl.component_types);
            }));

        const $properties = ctrl.customEventsService.getConstantPropertiesByEventTypeIds([event_type_id])
            .pipe(map((res: ListResponse<ConstantProperty>) => {
                ctrl.constant_properties = res.data;
                return this.fieldsFromConstantProperty(ctrl.constant_properties);
            }));

        return forkJoin([$properties, $componentTypes]).pipe(
            map(([propertiesField, componentTypesField]) => {
                return {...this.defaultEventTypeFields, ...this.userAuditFields, ...propertiesField, ...componentTypesField};
            })
        )
    }

    fieldsFromConstantProperty(constant_properties: ConstantProperty[]): FieldMap {
        return constant_properties.reduce((fields, cp) => {
            if (cp.attributes.data_type === 'file') return fields;
            let type = '';
            let options: Option[] = [];
            switch (cp.attributes.data_type) {
                case 'float':
                    type = 'number';
                    break;
                default:
                    type = cp.attributes.data_type;
            }
            if (this.isFilterBuilder) {
                if (cp.attributes.is_drop_down_list) {
                    type = 'dropdown';
                    options = cp.attributes.json.map(option => {
                        return {
                            name: option,
                            value: option};
                    });
                }
                fields[`[constant_property@${cp.id}]`] = {
                    name: (this.selected_cols_dict?.[cp.id]?.title || cp.attributes.name),
                    type, ...(type === 'dropdown') && {options: options}
                };
            } else {
                fields[`[constant_property@${cp.id}]`] = {name: cp.attributes.name, type};
            }
            return fields;
        }, {});
    }

    fieldsFromComponentType(component_types: ComponentType[]): FieldMap {
        return component_types.reduce((fields, ct) => {
            fields[`[component_type@${ct.id}]`] = {name: ct.attributes.name, type: 'boolean'};
            return fields;
        }, {})
    }

    fieldsFromEventType(event_types: EventType[]): FieldMap {
        return event_types.reduce((fields, et) => {
            fields[`[event_type@${et.id}]`] = {name: et.attributes.name, type: 'boolean'};
            return fields;
        }, {})
    }

    stringFunction(obj, value_map): string {
        return obj.name || value_map?.[obj]?.name;
    }

    compareWith(obj1, obj2): boolean {
        return obj1.value === obj2;
    }

    addIdtoField(field) {
        return ({id: field.value, ...field})
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    parentChangeCallback: () => void = () => {
        if (this.queryBuilderCtrl.touched) {
            this.queryBuilderCtrl.updateValueAndValidity();
            if (this.queryBuilderCtrl.valid) {
                this.query = this.queryBuilderCtrl.value;
            } else if (!this.queryBuilderCtrl.value.rules.length) {
                this.query = null;
            }

            this.queryChange.emit(this.query)
        }
    }

    setSelectedField(selected_field, fields): any {
        let current_field: any = '';
        let fields$: Observable<any[]> = of(fields);
        fields$.pipe(map(fieldsList => fieldsList.filter(field => field.name === selected_field)))
            .subscribe(current => {
                current_field = current;
            });
        return current_field[0];
    }

    getOptionsListByName(options: any[]): Observable<any[]> {
        let options$: Observable<any[]> = of(options);
        return options$.pipe(map((optionsList: any[]) => {
            if (this.ordered_column_options) {
                return mapOrder(optionsList, this.ordered_column_options, 'id').map(option => {
                    return option.name;
                });
            }
            return optionsList.map(option => option.name);
        }));
    }

    getUserOptionList(): void {
        this.api.users.searchMany().pipe(
            takeUntil(this.onDestroy),
            map((response: ListResponse<User>) => {
            return response.data.map((user: User): Option => ({
                name: user.attributes.name,
                value: user.id
            }));
        })).subscribe((options: Option[]): void => {
            this.usersOptions = options;
            }
        );
    }

    getOptionsListByValue(options: Option[], field: any): Observable<any[]> {
       let options$: Observable<Option[]> = of(options);
       let isChangedBy: boolean = field?.value === "changed_by";
       if (isChangedBy) {
           return of(this.usersOptions);
       }
    return options$.pipe(map(optionsList => optionsList.map(option => option.value)));
    }

    private mapToFieldFormat(column: EventConfigColumn): string {
        if (column.type === 'attribute') {
            return column.id.toLowerCase();
        }
        return `[${column.type}@${column.id}]`;
    }
}
