import {Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {TileDataService} from "../../services/tile_data.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import * as utils from "../../lib/utils";
import {isEmpty as _isEmpty} from "lodash-es";
import {SeriesStringParser} from "../../services/series_string_parser.service";
import {FormDialogService} from "../../services/form-dialog.service";
// import * as QuillNamespace from 'quill';
import {refreshSubscription} from "../../lib/utils";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

// let Quill: any = QuillNamespace;
// let Inline = Quill.import('blots/inline');
// let Parchment = Quill.import('parchment');
// const IDAttribute = new Parchment.Attributor.Attribute('id-attribute', 'id', {
//     scope: Parchment.Scope.INLINE,
// });
// Quill.register({'attributors/attribute/id': IDAttribute}, true);
// Quill.register({'formats/id': IDAttribute}, true);
// Quill.debug('error');

/**Suppress warnings. See: https://github.com/quilljs/quill/issues/2559, only this method seems to work **/
//
// class SeriesElement extends Inline {
//
//     static get ATTRIBUTES() {
//         return ['class', 'id'];
//     }
//
//     static formats(domNode) {
//         return this.ATTRIBUTES.reduce(function (formats, attribute) {
//             if (domNode.hasAttribute(attribute)) {
//                 formats[attribute] = domNode.getAttribute(attribute);
//             }
//             return formats;
//         }, {});
//     }
// }


@Component({
    selector: 'paragraph',
    templateUrl: './paragraph.component.html',
    styleUrls: ["../../shared/styles/quill.less"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ParagraphComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    private $loadDisplayText: Subscription;

    @Input() config: { paragraph_body: any };
    @Input() show_header: boolean;

    paragraph: any = {};
    place_holder: string = '';

    constructor(public dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                public tileData: TileDataService,
                private stringParser: SeriesStringParser,
                private elementRef: ElementRef,
                private formDialogService: FormDialogService) {
        // SeriesElement.blotName = 'series-element';
        // SeriesElement.tagName = 'span';
        // SeriesElement.className = 'series-element';
        // SeriesElement.scope = Parchment.Scope.INLINE;
        // Quill.register(SeriesElement);

    }

    ngOnInit() {
        const ctrl = this;

        this.tileData.save_content.pipe(takeUntil(this.onDestroy)).subscribe(event => {
            this.setText(event.paragraph_body, this.dateInst.dtp);
            ctrl.paragraph.edit_text = event.paragraph_body;
        });
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.setText(ctrl.paragraph.edit_text, dtp);
        });

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            ctrl.paragraph.edit_text = ctrl.config.paragraph_body;
            this.setText(ctrl.paragraph.edit_text, this.dateInst.dtp);
        });
    }

    openChartDialog(event) {
        const dialogRef = this.formDialogService.openChartDialog(event.srcElement.id, this.dateInst.dtp);
    }

    setText(text, dtp) {
        const ctrl = this;
        ctrl.place_holder = '';
        ctrl.paragraph.display_text = null;

        if (!text || _isEmpty(text)) return;
        ctrl.place_holder = ctrl.stringParser.simpleParse(utils.deepCopy(text));

        this.$loadDisplayText = refreshSubscription(this.$loadDisplayText);
        this.$loadDisplayText = this.tileData.parseForSeriesValues(text, dtp).pipe(takeUntil(this.onDestroy)).subscribe((display) => {
            ctrl.paragraph.display_text = display;
            setTimeout(() => {
                const series_elements = this.elementRef.nativeElement.querySelectorAll('.series-element');
                series_elements.forEach(el => {
                    el.addEventListener('click', this.openChartDialog.bind(this));
                });
            });
        });
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.querySelectorAll('.series_element').forEach(el => {
            el.removeEventListener('click', this.openChartDialog.bind(this));
        });
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
