import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {AppScope} from "../../services/app_scope.service";
import { HttpClient } from "@angular/common/http";
import {AccountService} from '../../services/account.service';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ISSUE_TYPES, ReportIssue, VALID_ISSUE_TYPE} from "../../_models/report-issue";
import {Account} from "../../_models/account";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {NotificationService} from "../../services/notification.service";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export interface DialogData {
    page_title: string;
}

@Component({
    selector: 'report-issue-form-dialog',
    templateUrl: './report-issue-form-dialog.component.html',
    styleUrls: ['./report-issue-form-dialog.component.less'],
    standalone: false
})
export class ReportIssueFormDialogComponent implements OnInit, OnDestroy {
    config: ReportIssue;
    issue_types: VALID_ISSUE_TYPE[] = ISSUE_TYPES;
    showing_hints: boolean;
    hint: string;
    active_account: Account;
    saving: boolean;
    had_error_sending: boolean = false;
    private readonly onDestroy = new Subject<void>();
    private dtp: IDateTimePeriod;
    serviceDeskEmail: string = 'servicedesk@metalmanagementsolutions.com';

    constructor(private http: HttpClient,
                private dialogRef: MatDialogRef<ReportIssueFormDialogComponent>,
                private notification: NotificationService,
                public dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                public appScope: AppScope,
                public accountService: AccountService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
        this.config = new ReportIssue();
        this.config.page_title = this.dialogData.page_title;
        this.config.page_link = window.location.href;
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.accountService.activeAccountChanged.pipe(takeUntil(ctrl.onDestroy))
            .subscribe(response => {
                this.active_account = ctrl.appScope.accounts.find(account => account.id === response.account_id);
                if (this.active_account) {
                    ctrl.config.active_account = this.active_account.id;
                }
            });

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
            this.dtp = dtp;
        });
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(dtp => {
            this.dtp = dtp;
        });
        //https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    // customImageHandler: any = () => {
    //     // const quill = this.quill.quillEditor;
    //     // quill.enable(false);
    //     const range = quill.getSelection();
    //
    //     this.fileUpload.nativeElement.onchange = () => {
    //         const files = this.fileUpload.nativeElement.files;
    //         if (!files || files.length < 1) {
    //             return;
    //         }
    //         const file = files[0];
    //
    //         const formData: FormData = new FormData();
    //         formData.append('file', file, file.name);
    //
    //         this.notification.open('Uploading image');
    //
    //         this.http.post('/api/BlobData', formData).subscribe((response: { file_name: string }) => {
    //             quill.enable(true);
    //             this.notification.open('Image uploaded successfully.', undefined, {duration: 1500});
    //             quill.insertEmbed(range.index, 'image', 'admin/myfiles/download' + response.file_name, Quill.sources.USER);
    //         }, error => {
    //             quill.enable(true);
    //             console.error('Error uploading collector data', error);
    //             this.notification.open('An error occurred while collecting the file.', "Hide");
    //         });
    //     };
    //     this.fileUpload.nativeElement.click();
    // };

    // options = {
    //     clipboard: {
    //         matchVisual: false
    //     },
    //     toolbar: {
    //         container: [
    //             ["bold", "italic", "underline", "strike"],
    //             ["blockquote", "code-block"],
    //             [{"header": 1}, {"header": 2}],
    //             [{"list": "ordered"}, {"list": "bullet"}],
    //             [{"script": "sub"}, {"script": "super"}],
    //             [{"indent": "-1"}, {"indent": "+1"}],
    //             [{"direction": "rtl"}],
    //             [{"size": ["small", false, "large", "huge"]}],
    //             [{"header": [1, 2, 3, 4, 5, 6, false]}],
    //             [{"color": []}, {"background": []}],
    //             [{"font": []}], [{"align": []}],
    //             ["link", "image"],
    //             ["clean"]
    //         ],
    //         handlers: {
    //             image: this.customImageHandler
    //         }
    //     }
    // };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
