import {Component, Input, Output, OnDestroy, OnInit, EventEmitter} from '@angular/core';
import {ProcessAccessFeatureConfig} from "../process-access-feature-config";
import {SaveService} from "../../../services/save/save.service";
import {Observable, of, Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {Feature} from "../../../_models/feature";
import {Process} from "../../../_models/process";
import {ProcessAccess} from "../../../_models/process-access";
import {differenceWith as _differenceWith} from "lodash-es";
import {compare_ids} from "../../../lib/utils";
import {ApiService} from "../../../services/api/api.service";
import {User} from "../../../_models/users";
import {ModelID} from "../../../_typing/generic-types";

@Component({
    selector: 'user-form-process-access-feature',
    templateUrl: './user-form-process-access.component.html',
    styleUrls: ['./user-form-process-access.component.less'],
    standalone: false
})
export class UserFormProcessAccessComponent implements OnInit, OnDestroy {
    // private process_access_id_map: { [pa_id: string]: ProcessAccess } = {};
    feature: Feature;
    @Input() user: User;
    // Source list of processes from which to pick.
    processes: Process[] = [];
    // All selected processes currently selected.
    new_processes: Process[] = [];
    hint: string;
    private readonly onDestroy = new Subject<void>();
    // Processes that the user had before making changes in this selection.
    private old_processes: Process[] = [];
    private _accountIds: ModelID[]
    @Input() set accountIds(accountIds: ModelID[]) {
        this._accountIds = accountIds;

        if (this.config) {
            this.processes = this.config.all_processes_list.filter(p => this.accountIds.includes(p.relationships.account.data.id));
        }
    };

    get accountIds(): ModelID[] {
        return this._accountIds;
    }

    constructor(private api: ApiService,
                private saveService: SaveService) {
    }

    _config: ProcessAccessFeatureConfig;

    @Input() set config(c: ProcessAccessFeatureConfig) {
        this._config = c;
        this.refreshState(c);
    }

    get config(): ProcessAccessFeatureConfig {
        return this._config;
    }

    @Output() onChange = new EventEmitter();

    ngOnInit() {
        if (!this.user) {
            console.warn('User not assigned to UserFormProcessAccessComponent. Changes will not be saved.')
        }

        this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe(() => {
            console.log('Saving changes made in process access for feature: ' + this.feature.attributes.name);
            this.save();
        });
        this.saveService.setCanSave(() => this.hasChanges());
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    hasChanges() {
        return JSON.stringify(this.old_processes) !== JSON.stringify(this.new_processes);
    }

    onProcessesChanged($event) {
        const selected = $event;
        const hiddenSelected = this.new_processes?.filter(
            value => !this.processes.includes(value)
        ) || [];
        this.new_processes = [...new Set([...selected, ...hiddenSelected])];

        this.onChange.next(this.hasChanges())
    }

    private refreshState(config: ProcessAccessFeatureConfig) {
        this.feature = config.feature;
        this.processes = config.all_processes_list.filter(p => this.accountIds.includes(p.relationships.account.data.id));
        this.old_processes = this.getProcessesForProcessAccess(config.all_processes_list, config.process_access);
        // On load, the newly selected is exactly what was previously selected.
        this.new_processes = this.old_processes.slice();
    }

    /**
     * Maps the entries in process_access_list to the corresponding processes in all_process_list.
     *
     * ProcessAccess without a process is ignored.
     * @param all_processes_list
     * @param process_access_list
     */
    private getProcessesForProcessAccess(all_processes_list: Process[], process_access_list: ProcessAccess[]): Process[] {
        let processes = [];
        const process_id_map = {};

        all_processes_list.forEach(process => {
            process_id_map[process.id] = process;
        });

        process_access_list.forEach(pa => {
            const process = process_id_map[pa.relationships.top_process.data.id];
            if (process) {
                processes.push(process);
            }
        });

        return processes;
    }

    /**
     * Maps the entries in process_list to the corresponding entries in process_access_list linked to the respective
     * process.
     *
     * Processes without a matching ProcessAccess will be ignored.
     * @param process_access_list List of ProcessAccess from which to filter
     * @param process_list
     */
    private getProcessAccessForProcesses(process_access_list: ProcessAccess[], process_list: Process[]): ProcessAccess[] {
        const found_process_accesses = [];

        const process_access_process_id_map = {};
        process_access_list.forEach(pa => {
            process_access_process_id_map[pa.relationships.top_process.data.id] = pa;
        });

        process_list.forEach(process => {
            const process_access = process_access_process_id_map[process.id];
            if (process_access) {
                found_process_accesses.push(process_access);
            }
        });

        return found_process_accesses;
    }

    private save() {
        if (!this.user) {
            this._config.changes.next([of()]);
            return;
        }
        const ctrl = this;

        const to_delete: Process[] = _differenceWith<Process, Process>(this.old_processes, this.new_processes, compare_ids);
        const to_add: Process[] = _differenceWith<Process, Process>(this.new_processes, this.old_processes, compare_ids);

        this.old_processes = this.new_processes.slice();

        const $changes: Observable<any>[] = [];

        const process_access_to_delete = this.getProcessAccessForProcesses(this._config.process_access, to_delete);

        //console.log('To delete\n', to_delete, '\nTo add\n', to_add);

        process_access_to_delete.forEach(pa => {
            $changes.push(ctrl.api.process_access.obsDelete(pa.id).pipe(tap(del => {
                const i = this.config.process_access.findIndex(item => item.id === pa.id);
                this.config.process_access = this.config.process_access.splice(i, 1);
            })));
        });

        to_add.forEach(process => {
            $changes.push(ctrl.api.process_access.obsSave({
                type: 'process_access',
                attributes: {},
                relationships: {
                    top_process: {
                        data: {
                            type: 'process',
                            id: process.id
                        }
                    },
                    feature: {
                        data: {
                            type: 'feature',
                            id: ctrl._config.feature.id
                        }
                    },
                    user: {
                        data: {
                            type: 'users',
                            id: ctrl.user.id
                        }
                    }
                }
            }).pipe(tap(pa => {
                this.config.process_access.push(pa.data);
            })));
        });

        this._config.changes.next($changes);
        this._config.changes.complete();

        this.onChange.next(this.hasChanges())
    }
}
