<label *ngIf="label" style="position:relative;">{{label}}</label>
<button class="select-many-search-component-button" [matMenuTriggerFor]="selectManyMenu" (menuOpened)="menuOpened();"
        (menuClosed)="menuClosed()" [class.no-label]="!label" [disabled]="disabled" [ngClass]="classes">
  <div class="select-many-search-component-trigger">({{value ? '+' + (value?.length || '0') : 'Open to view '}}
    selected)
  </div>
  <div class="mat-select-arrow-wrapper">
    <div class="mat-select-arrow"></div>
  </div>
</button>

<mat-menu #selectManyMenu="matMenu" hasBackdrop="true" class="select-many-menu-panel">
  <div (click)="$event.stopPropagation();">
    <option-list-search (keydown.space)="$event.stopPropagation()"
                        [placeholder]="placeholder || 'Search'"
                        (inputChanged)="filterOptions($event)"
                        (inputCleared)="clearSearch()"
                        [allowAccountSelection]="allowAccountSelection"
                        [accountIds]="allowedAccountIds"
                        (relayAccountsSelected)="onAccountsChanged($event)"
                        [delay]="300">
    </option-list-search>

    <div class="row" style="justify-content: space-between;" (click)="$event.stopPropagation()">
      <div class="row_container">
        <h2 style="display:flex;justify-content: space-between;margin-bottom:0;">
          <div>Selected ({{sapi.selectedTotal}})</div>
          <mat-checkbox [(ngModel)]="sapi.keep_selected" (ngModelChange)="updateSearchSelected()"
                        matTooltip="Show ALL items that are currently selected when searching"
                        style="font-size:1.1rem;font-weight:500;">Show all when searching
          </mat-checkbox>
        </h2>
        <label style="margin-bottom:4px;">Showing {{selectedItems?.length}}</label>
        <div class="select-many-list-container"
             [items_loaded]="selectedItems?.length" [total_items]="sapi.selectedTotal" [threshold]="'50%'"
             (infiniteScroll)="getNextSelected()" [complete]="!sapi.loadNextSelected" msDivInfiniteScroll>
          <div id="selectedList"
               #selectedList="cdkDropList"
               (cdkDropListDropped)="drop($event)"
               [cdkDropListConnectedTo]="[availableList]"
               [cdkDropListData]="selectedItems"
               [cdkDropListDisabled]="disabled"
               cdkDropList
               class="select-many-list">
            <div *ngFor="let option of selectedItems" cdkDrag class="select-many-item"
                 style="justify-content: space-between;padding-right:0;" [cdkDragDisabled]="disable_items">
              <div>
                <div [matTooltip]="option['text']" [matTooltipShowDelay]="tooltipShowDelay"
                     class="option-text" [dblClickCopy]="option.text">{{option.text}}
                </div>
                <div *ngIf="option.value?.attributes?.account_name"
                     class="account-name select-item-account-name"
                     [matTooltip]="option.value.attributes.account_name.trim()"
                     [matTooltipShowDelay]="tooltipShowDelay"
                >
                  <div>{{option.value.attributes.account_name.trim()}}</div>
                </div>
              </div>
              <button class="move" (click)="move(option, availableList, selectedList);"
                      [disabled]="disabled || disable_items">
                <i class="fa fa-caret-right"></i></button>
            </div>

            <div class="stage-item stage" [hidden]="!sapi.loadingSelected">
              <div class="dot-flashing"></div>
            </div>

          </div>
        </div>
      </div>

      <div class="row_container">
        <h2 style="margin-bottom:0;">Available ({{sapi.availableTotal}})</h2>
        <label style="margin-bottom:4px;">Showing {{availableItems?.length}}</label>
        <div class="select-many-list-container"
             [items_loaded]="availableItems?.length" [total_items]="sapi.availableTotal" [threshold]="'50%'"
             (infiniteScroll)="getNextAvailable()" [complete]="!sapi.loadNext" msDivInfiniteScroll>
          <div id="availableList"
               #availableList="cdkDropList"
               (cdkDropListDropped)="drop($event)"
               [cdkDropListConnectedTo]="[selectedList]"
               [cdkDropListData]="availableItems"
               [cdkDropListDisabled]="disabled"
               cdkDropList
               class="select-many-list">
            <div *ngFor="let option of availableItems" cdkDrag class="select-many-item"
                 [cdkDragDisabled]="disable_items">
              <button class="move" (click)="move(option, selectedList, availableList);"
                      [disabled]="disabled || disable_items">
                <i class="fa fa-caret-left"></i></button>
              <div>
                <div [matTooltip]="option['text']" [matTooltipShowDelay]="tooltipShowDelay"
                     class="option-text" [dblClickCopy]="option.text">{{option.text}}
                </div>
                <div *ngIf="option.value?.attributes?.account_name"
                     class="account-name select-item-account-name"
                     [matTooltip]="option.value.attributes.account_name.trim()"
                     [matTooltipShowDelay]="tooltipShowDelay"
                >
                  <div>{{option.value.attributes.account_name.trim()}}</div>
                </div>
              </div>
            </div>
            <div
                *ngIf="!sapi.loadingAvailable && !availableItems?.length && !selectedItems?.length">{{noItemsAvailableMessage}}
            </div>
            <div class="stage-item stage" [hidden]="!sapi.loadingAvailable">
              <div class="dot-flashing"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</mat-menu>
