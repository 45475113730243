import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as utils from '../../lib/utils'
import {MatTableDataSource} from "@angular/material/table";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import { HttpClient } from '@angular/common/http';
import {OreBody} from "../../_models/ore-body";
import {take, takeUntil} from "rxjs/operators";
import {Observable, Subject, Subscription} from "rxjs";
import {MassBalanceData, MassBalanceResponse} from "../../_models/mass-balance-response";
import {ApiService} from '../../services/api/api.service';
import {SingleResponse} from '../../services/api/response-types';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
    description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {
        position: 1,
        name: 'Hydrogen',
        weight: 1.0079,
        symbol: 'H',
        description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
        atomic weight of 1.008, hydrogen is the lightest element on the periodic table.`
    }, {
        position: 2,
        name: 'Helium',
        weight: 4.0026,
        symbol: 'He',
        description: `Helium is a chemical element with symbol He and atomic number 2. It is a
        colorless, odorless, tasteless, non-toxic, inert, monatomic gas, the first in the noble gas
        group in the periodic table. Its boiling point is the lowest among all the elements.`
    }];

@Component({
    selector: 'ore-body-data-table',
    templateUrl: './ore-body-data-table.component.html',
    styleUrls: ['./ore-body-data-table.component.less', '../../components/colour_palette.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class OreBodyDataTableComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    dtp: IDateTimePeriod;
    dataSource: MatTableDataSource<any>;
    columnsToDisplay = ['name', 'initial', 'inputs', 'outputs', 'content'];
    columnLookupKeys = {
        'name': 'ore-body-mass-balance-col-name',
        'initial': 'ore-body-mass-balance-col-initial',
        'inputs': 'ore-body-mass-balance-col-inputs',
        'outputs': 'ore-body-mass-balance-col-outputs',
        'content': 'ore-body-mass-balance-col-content'
    };
    expandedElement: PeriodicElement | null;
    // property_table_dict: any = {};
    // property_table: any[] = [];
    row_schema = {name: '', initial: null, inputs: null, outputs: null, content: null};
    private $mass_balance_subject: Subscription;

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private http: HttpClient,
                private api: ApiService) {
    }

    _ore_body: OreBody;

    get ore_body(): OreBody {
        return this._ore_body;
    }

    @Input()
    set ore_body(ore_body: OreBody) {
        if (ore_body) {
            if (this._ore_body && (ore_body.id !== this._ore_body.id)) {
                this.api.cancelActiveQueries();
            }
            this.getOreBody(ore_body).subscribe(result => {
                this._ore_body = result.data;
                this.getData();
            })
        }
    }

    ngOnInit(): void {
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dtp = this.dateInst.dtp;
        });
        this.dateInst.dateTimePeriodRefreshed$
            .pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.dtp = dtp;
                this.getData();
            });
    }

    getOreBody(ore_body): Observable<SingleResponse<OreBody>> {
        return this.api.ore_body.getById(ore_body.id).pipe(take(1))
    }

    getData() {
        const ctrl = this;
        ctrl.getMassBalance();
    }

    getMassBalance() {
        const ctrl = this;
        let params = {
            ore_body_id: this.ore_body.id,
            start: ctrl.dtp.start.toISOString(),
            end: ctrl.dtp.end.toISOString()
        };

        if (this.$mass_balance_subject) {
            this.$mass_balance_subject.unsubscribe();
            this.$mass_balance_subject = null;
        }

        this.$mass_balance_subject = ctrl.http.get("api/OreBody/MassBalance" + '?' + utils.httpParamSerializer(params)).subscribe((data: MassBalanceResponse) => {
            ctrl.getTable(data.data);
        }, error => {
            console.log('Error - : ', error);
        });

    }

    getTimeSeries() {
        const ctrl = this;

        let params = {
            ore_body_id: this.ore_body.id,
            start: ctrl.dtp.start.toISOString(),
            end: ctrl.dtp.end.toISOString()
        };

        ctrl.http.get("/api/Events/TimeSeries" + '?' + utils.httpParamSerializer(params)).toPromise().then(data => {
        }, error => {
            console.log('Error - : ', error);
        });

    }

    getTable(mass_balance_data: MassBalanceData) {
        const property_table_dict = {};
        const property_table = [];

        Object.keys(mass_balance_data).forEach(obp => this.mapProperties(mass_balance_data, property_table_dict, obp));

        this._ore_body.attributes.constant_property_ordering.forEach(cp_name => {
            if (property_table_dict.hasOwnProperty(cp_name)) {
                property_table.push(property_table_dict[cp_name]);
            }
        });
        this.dataSource = new MatTableDataSource(property_table);
    }

    mapProperties(mass_balance_data, property_table_dict, prop_key) {
        if (!mass_balance_data[prop_key]) {
            return;
        }

        for (const [key, value] of Object.entries(mass_balance_data[prop_key])) {
            if (!property_table_dict[key]) {
                property_table_dict[key] = utils.deepCopy(this.row_schema);
                property_table_dict[key].name = key;
            }
            property_table_dict[key][prop_key] = value;
        }

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
