import {Moment} from 'moment';
import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {stringDate} from '../lib/utils';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TimeZoneService extends MomentDateAdapter implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    private active_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);

    }

    format(date: Moment, displayFormat: any): string {
        //console.log('format: ', date, moment_timezone.tz.guess(true));
        //return moment_timezone(date).format('YYYY/MM/DD HH:mm');
        //return moment_timezone.tz(date.toISOString(), 'YYYY/MM/DD HH:mm', true, this.active_timezone).format('YYYY/MM/DD HH:mm');
        return stringDate(date, {date_only: true});
    }

    setLocale(locale: string) {
        super.setLocale(locale);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
