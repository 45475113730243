<div class="row utils-bar" style="justify-content: space-between">
  <div *ngIf="dataSource" class="table-filter">
    <input (keyup)="applyFilter.next($event.target.value)" [(value)]="filter_string" placeholder="Search groups"/>
  </div>
  <mat-paginator [showFirstLastButtons]="true"
                 style="justify-self: flex-end;">
  </mat-paginator>
</div>
<div class="table-container">
  <table [dataSource]="dataSource"
         [matSortActive]="'name'"
         (matSortChange)="updateSort($event)"
         class="table table-striped user-table"
         mat-table matSort matSortDirection="asc">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
      <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
        <ng-container *ngIf="column === 'edit'"></ng-container>
        <ng-container *ngIf="column !== 'edit'">{{column | titlecaseForWire | removeUnderscore}}</ng-container>
      </th>
      <td *matCellDef="let group;"
          mat-cell
          style="text-align: left;border:none;">
        <ng-container *ngIf="column === 'edit'">
          <i (click)="openDialog(group)" class="fa fa-edit" style="cursor:pointer;"></i>
        </ng-container>

        <ng-container
            *ngIf="['created_on', 'changed_on'].includes(column)">{{group.attributes[column] | stringDate }}
        </ng-container>
        <ng-container
            *ngIf="!['created_on', 'changed_on'].includes(column)">{{group.attributes[column]}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container>
      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </ng-container>
  </table>
</div>
