<div class="category-chart-form">

  <div class="row center">
    <mat-form-field matTooltip="Label for Y axis">
      <mat-label>Y Axis Label</mat-label>
      <input [(ngModel)]="config.labels.y_axis" matInput>
    </mat-form-field>

    <mat-form-field class="custom" matTooltip="label for Y axis">
      <mat-label>X Axis Label</mat-label>
      <input [(ngModel)]="config.labels.x_axis" matInput>
    </mat-form-field>
  </div>

  <div class="row center">
    <div class="row center  flex-half alone">
      <mat-checkbox [(ngModel)]="config.set_range" class="form-element-align">Set Chart Range</mat-checkbox>
      <mat-form-field>
        <mat-label>Y Min</mat-label>
        <input [(ngModel)]="config.y_min" [disabled]="config.set_range !== true" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Y Max</mat-label>
        <input [(ngModel)]="config.y_max" [disabled]="config.set_range !== true" matInput>
      </mat-form-field>
    </div>
  </div>

  <mat-divider></mat-divider>
  <button (click)="addSeriesToConfig()" mat-raised-button color="primary">Add series</button>

  <div class="column">
    <ng-container *ngFor="let series of config.series_list; let i = index">
      <div class="row center">
        <select-search-component (selectionChange)="updateConfig($event,i);"
                                 [classes]="'fill'"
                                 [api_model]="'series_light'"
                                 [value]="series.series_id"
                                 [compareFunctionName]="'compareValueToId'"
                                 class="select-search"
                                 label="Select Series">
        </select-search-component>

        <div>
          <mat-form-field class="custom" matTooltip="Select a number of periods for this series">
            <mat-label>Number of periods</mat-label>
            <input [(ngModel)]="series.number_of_periods" matInput step="1" type="number">
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="custom" matTooltip="Select custom period for this series">
            <mat-label>Custom Period</mat-label>

            <mat-select [(value)]="series.sample_period" [compareWith]="compareSamplePeriod">
              <mat-option *ngFor="let sample_period of dateTimePeriodService.sample_periods"
                          [value]="sample_period">{{sample_period.name | removeUnderscore | upperfirst}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="custom" matTooltip="Select chart type for this series and period">
            <mat-label>Chart type</mat-label>
            <mat-select [(value)]="series.chart_type">
              <mat-option *ngFor="let chart_type of chart_types"
                          [value]="chart_type">{{chart_type | upperfirst}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <colour-palette [(value)]="series.color"
                        [palette]="1"
                        [label]="'Colour'"
                        [show_text]="true">
        </colour-palette>

        <div>
          <i (click)="deleteSeries(i)" class="fa fa-trash form-element-align"
             style="margin-left:8px;font-size:1.2rem"></i>
        </div>
      </div>

    </ng-container>

  </div>

</div>
