<!--<button class="md-fab md-mini md-raised md-mini md-primary" *ngIf="!show_detail" mat-raised-button (click)="showDetail()">Show Detail-->
<!--<i class="fa fa-expand"></i></button>-->
<!--<button class="md-fab md-mini md-raised md-mini md-primary" *ngIf="show_detail" mat-raised-button (click)="hideDetail()">Hide-->
<!--Detail<i class="fa fa-compress"></i></button>-->

<table (matSortChange)="sortData($event)" *ngIf="events_ready && dataSource &&series_list && series_list.length > 0"
       [dataSource]="dataSource"
       cellspacing="0" class="table-bordered table-hover" id="forecast_table" mat-table
       matSort>

  <!--TODO somehow make this row fill the screen width, but not go offscreen. So that the title is always centre onscreen-->
  <!--FIXME when this collumn is shown, the sticky columns become buggy-->
  <!--<ng-container matColumnDef="toprow">-->
  <!--<th [colSpan]="getRowHeaders().length" style="width: 100%" mat-header-cell *matHeaderCellDef>{{forecast_year}} :-->
  <!--{{selected_estimate.attributes.name}}</th>-->
  <!--</ng-container>-->

  <ng-container matColumnDef="Series" sticky>
    <th *matHeaderCellDef mat-header-cell mat-sort-header
        style='width: 200px; padding: 0 6px;'>
      Series
    </th>
    <td mat-cell
        style="text-align: left;min-width: 16em "
        *matCellDef="let element"
        [title]="(series_parents && series_parents[element.relationships.parent.data.id]) ?
          series_parents[element.relationships.parent.data.id].name + ' - ' +
          series_parents[element.relationships.parent.data.id].description : ''">
        <span>{{(series_parents && series_parents[element.relationships.parent.data.id]) ?
            series_parents[element.relationships.parent.data.id].name : ''}} </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="Estimate" sticky>
    <th mat-header-cell mat-sort-header
        style='min-width:16em; max-width:340px; padding: 0 6px; background: white'
        *matHeaderCellDef>Estimate
    </th>
    <td mat-cell
        *matCellDef="let element"
        [title]="element.attributes.name + ' - ' + element.attributes.description"
        style='padding: 0px; text-align:left;min-width: 200px;'>
      <ng-container *ngIf="series_parents && series_parents[element.relationships.parent.data.id]">
        <span>
          <i class="fa fa-edit hideonprint" style="margin-right: 6px;" (click)="openEstimateDialog($event, element)"></i>
        </span>
        <span *ngIf="element.attributes.description" style="text-align: left;">{{element.attributes.description}}</span>
        <span *ngIf="!element.attributes.description" style="text-align: left;">{{element.attributes.name}}</span>
      </ng-container>
    </td>
  </ng-container>

  <ng-container *ngFor="let column of col_headers" matColumnDef="{{column}}">
    <th mat-header-cell
        *matHeaderCellDef>{{column}}</th>
    <td mat-cell *matCellDef="let element" style="text-align:right;padding-right:2px;">
      <!--TODO add option to show all decimal places, perhaps with the 'toggle detail' option-->
      {{(series_parents && series_summary[element.relationships.parent.data.id]) ? (series_summary[element.relationships.parent.data.id][column]
         | significantNumbers:true) : ''}}</td>
  </ng-container>

  <ng-container *ngFor="let month of months" matColumnDef="{{month}}">
    <th mat-header-cell *matHeaderCellDef>{{month| date: 'MMM'}}</th>
    <ng-container *matCellDef="let element">
      <td mat-cell *ngIf="series_parents && series_parents[element.relationships.parent.data.id]" class="month">
        <input *ngIf="element[month]" type="number" step="any"
               [disabled]="element[month].saving ||(!permissions.edit_process_data && process !== undefined)"
               [ngModelOptions]="{updateOn: 'blur'}" (change)="dataChange($event.target.value, element, month);"
               [ngModel]="element[month].attributes.value" style="border:0; text-align: right;width: 6em">
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getRowHeaders(); sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: getRowHeaders();"></tr>
</table>
