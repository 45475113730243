import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {ApiService} from "../../services/api/api.service";
import {forkJoin, Subject} from "rxjs";
import * as $ from 'jquery'
import {deepCopy, guid} from "../../lib/utils";
import {TileDataService} from "../../services/tile_data.service";
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {get_series_for_components} from "../../services/plant-data/helper";
import {SearchQueryOptions} from "../../services/api/search-query-options";

@Component({
    selector: 'model-pivot-tile',
    templateUrl: './model-pivot-tile.component.html',
    styleUrls: ['./model-pivot-tile.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModelPivotTileComponent implements OnInit, OnDestroy {
    buttons: { name: string; func: () => any; params: {}; class: string; HoverOverHint: string; }[];
    $: any;

    full_series_list: any[];
    page_ready: boolean = false;
    stateData: any = {};
    pivotID: string;
    pivotParameters: any;
    pivotData: any;
    @Output() tileContentChange = new EventEmitter();
    @Input() showUI: boolean = false;
    @ViewChild('pivotAnchor', {read: ElementRef, static: true}) pivotAnchor: ElementRef;
    private readonly onDestroy = new Subject<void>();

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private api: ApiService,
                private tileData: TileDataService,
                private headerData: HeaderDataService,
                private appScope: AppScope) {
        this.pivotID = guid();
    }

    private _config: {
        series_list: any[],
        component_list: any[],
        model_type: string,
        pivot_state: {},
    };

    get config(): { series_list: any[], component_list: any[], model_type: string, pivot_state: any } {
        return this._config
    }

    @Input()
    set config(config: { series_list: any[], component_list: any[], model_type: string, pivot_state: any }) {
        //Only update if the series list has changed
        //Add component_list to this check to check for changes to config.component_list
        if (this._config && this._config.series_list !== config.series_list) {
            this._config = config;
            this.getSeriesList();
        }
        this._config = config;
    }

    ngOnInit(): void {
        const ctrl = this;

        this.headerData.show_dtp = true;
        ctrl.buttons = [
            {
                name: 'Edit',
                func: () => {
                    ctrl.showUI = !ctrl.showUI;
                    ctrl.buildPivot()
                },
                params: {},
                class: 'fa small fa-edit hide-xs',
                HoverOverHint: 'Edit Pivot Table '
            },
            {
                name: 'Save',
                func: () => {
                    ctrl.savePivotState()
                },
                params: {},
                class: 'fa small fa-save hide-xs',
                HoverOverHint: 'Save Pivot Table'
            }
        ];
        ctrl.tileData.buttonsChanged.next(ctrl.buttons);

        if (this.config.model_type === 'series') {
            this.getSeriesList();
        } else if (this.config.model_type === 'process') {
            this.getComponentList();
        }


    }

    getSeriesList() {
        const ctrl = this;

        ctrl.full_series_list = ctrl.config.series_list;

        const options = new SearchQueryOptions();
        options.filters = [{
            op: 'in',
            name: 'id',
            val: ctrl.full_series_list.map(series => series.id)
        }];
        let $full_series = ctrl.api.series.searchMany(options)
            .pipe(takeUntil(this.onDestroy));

        const component_ids = [];
        if (this.config.component_list.length > 0) {
            this.config.component_list.map(process => {
                component_ids.push(process.id)
            });
            const $series_components = get_series_for_components(ctrl.api, component_ids).pipe(takeUntil(this.onDestroy));
            forkJoin($full_series, $series_components).subscribe((result: any) => {
                if (result && result[1]) {
                    ctrl.full_series_list = result[0].data.concat(result[1].data);
                } else {
                    ctrl.full_series_list = result[0].data;
                }
                ctrl.pivotData = deepCopy(ctrl.full_series_list);
                ctrl.buildPivot();
            });
        } else {
            $full_series.subscribe(result => {
                ctrl.full_series_list = result.data;
                ctrl.pivotData = deepCopy(ctrl.full_series_list);
                ctrl.buildPivot();
            });
        }
    }

    getComponentList() {
        const ctrl = this;
        const options = new SearchQueryOptions();
        options.filters = [{
            op: 'in',
            name: 'id',
            val: ctrl.config.component_list.map(component => component.id)
        }];
        const $full_component_list = ctrl.api[ctrl.config.model_type].searchMany(options)
            .pipe(takeUntil(this.onDestroy)).subscribe((result: { data: Component[] }) => {
                ctrl.pivotData = result.data;
                ctrl.buildPivot();
            })
    }

    flattenData() {
        const ctrl = this;
        ctrl.pivotData.forEach(item => {
            if (item.attributes) {
                Object.keys(item.attributes).forEach(att_name => {
                    let att_title = deepCopy(att_name);
                    try {
                        att_title = att_title.charAt(0).toUpperCase() + att_title.slice(1);
                        att_title = att_title.replace(/_/g, ' ');
                    } catch (e) {
                        att_title = att_name;
                    }
                    item[att_title] = item.attributes[att_name];
                });
                delete item.attributes;
                delete item.relationships;
            }
        });
    }

    pivotParams() {
        const ctrl = this;
        let pivotParams = ctrl.config.pivot_state;
        // @ts-ignore
        pivotParams.renderers = $.extend(
            // @ts-ignore
            $.pivotUtilities.renderers,
            // @ts-ignore
            $.pivotUtilities.c3_renderers,
            // @ts-ignore
            $.pivotUtilities.d3_renderers,
            // @ts-ignore
            $.pivotUtilities.plotly_renderers,
            // @ts-ignore
            $.pivotUtilities.export_renderers
        );
        pivotParams.hiddenAttributes = [""];
        pivotParams.aggregatorName = 'Count';
        pivotParams.menuLimit = 1000;
        pivotParams.showUI = ctrl.showUI;

        pivotParams.onRefresh = function (config) {
            let state = JSON.parse(JSON.stringify(config));

            //delete some values which are functions
            delete state["aggregators"];
            delete state["renderers"];
            //delete some bulky default values
            delete state["rendererOptions"];
            //delete state["localeStrings"];

            ctrl.stateData = state;
        };

        ctrl.pivotParameters = pivotParams;

    }

    buildPivot() {
        const ctrl = this;
        ctrl.flattenData();
        ctrl.pivotParams();

        // @ts-ignore
        $(ctrl.pivotAnchor.nativeElement).pivotUI(
            ctrl.pivotData, ctrl.pivotParameters, true
        ).show();

    }

    savePivotState() {
        const ctrl = this;

        ctrl.config.pivot_state = ctrl.stateData;
        ctrl.tileContentChange.emit(ctrl.config);
        ctrl.showUI = false;
        ctrl.buildPivot()

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
