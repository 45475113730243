import {Directive, ElementRef, NgZone, OnChanges, OnInit, Renderer2} from '@angular/core';
import {take} from 'rxjs/operators';

@Directive({
    selector: '[appPassword]',
    standalone: false
})

export class AppPasswordDirective implements OnInit{
    private _shown = false;

    constructor(private el: ElementRef, private renderer: Renderer2,private zone: NgZone) {

    }

    ngOnInit() {
        this.setup();
    }
    toggle(event) {
        this._shown = !this._shown;
        if (this._shown) {
            this.renderer.setProperty(this.el.nativeElement, 'type', 'text');
            this.renderer.setAttribute(event.target, 'class','fa fa-eye-slash' )
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'type', 'password');
            this.renderer.setAttribute(event.target, 'class','fa fa-eye' );
        }
    }
    setup() {
        const parent = this.el.nativeElement.parentNode;
        const span = this.renderer.createElement('span');
        const i = this.renderer.createElement('i');
        //I assume that using setAttribute here is fine since it the element hasn't been applied to the DOM yet
        span.setAttribute('class', 'app-password');
        i.setAttribute('class', 'fa fa-eye');
        i.addEventListener('click', (event) => {
            this.toggle(event);
        });
        this.renderer.appendChild(parent, span);
        this.renderer.appendChild(span, i);

    }
}
