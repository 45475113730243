import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {Event, EventAttributes, EventRelationships} from "./event";

export class CommentRelationships extends EventRelationships {
    parent?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    session_state?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class CommentAttributes extends EventAttributes {
    series_names?: string[];
    base_type: string = 'comment';
}

export class Comment extends Event {
    attributes: CommentAttributes;
    id: string;
    type: string = 'comment';
    relationships: CommentRelationships;

    constructor() {
        super();
        this.type = 'comment';
        this.attributes = new CommentAttributes();
        this.relationships = new CommentRelationships();
    }
}
