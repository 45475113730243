<div class="form-dialog series-form">
  <div class="form-title">

    <!--    <h1 style="flex-grow:0;"-->
    <!--        [textContent]="(series && series.id ? series.attributes.name + ':&nbsp;' : 'Add series &nbsp;') ">-->
    <!--    </h1>-->

    <h1 class="tab-group-menu">
      <div (click)="tabGroupChange(tabGroups.OPERATIONS)" [ngClass]="{'active':tab_group===tabGroups.OPERATIONS}">
        Operations
      </div>
      <div>|</div>
      <div (click)="tabGroupChange(tabGroups.REPORTING)" [ngClass]="{'active':tab_group===tabGroups.REPORTING}">
        Reporting
      </div>
      <div>|</div>
      <div (click)="tabGroupChange(tabGroups.CONFIGURATION)" [ngClass]="{'active':tab_group===tabGroups.CONFIGURATION}">
        Configuration
      </div>
    </h1>

    <h1 class="hint" (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </h1>
  </div>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints,'show-audit-mixin': showAudit()}">
    <mat-tab-group [disableRipple]="true" #series_tabs (selectedTabChange)="onTabClick($event)">

      <ng-container *ngIf="series?.relationships && seriesData">
        <mat-tab *ngIf="tab_group===tabGroups.CONFIGURATION" label="Details">

          <form class="form-section">
            <div class="row">
              <mat-radio-group [(ngModel)]="series.attributes.is_calculation" name="is_calculation"
                               style="width:260px;flex-grow:0;padding-left:8px;" class="inline"
                               (mouseenter)="hint='Base_Type'">
                <label>Type</label>
                <mat-radio-button [value]="false">Series</mat-radio-button>
                <mat-radio-button [value]="true">Calculation</mat-radio-button>
              </mat-radio-group>

              <div class="info" *ngIf="show_collation_type">
                <i class="fa fa-info-circle"
                   matTooltip="This is a collation series. Please see details on the Links tab."></i>
              </div>
            </div>

            <div class="row center full-height">
              <mat-form-field style="margin-right:5px;flex-grow:1;">
                <mat-label>Name</mat-label>
                <input matInput placeholder="" [(ngModel)]="series.attributes.name" name="name"
                       [dblClickCopy]="series.attributes.name" matTooltip="Double click to copy">
                <mat-hint>The shorthand code name for the series. Recommended naming convention:
                  [Three Letter Area Code]_[Three digit sequence number'] + 3 X _[Three digit acronym]
                  Example: CCD_021_PLS_FEE_CU
                </mat-hint>
              </mat-form-field>

              <mat-form-field style="margin-right:5px;flex-grow:1;">
                <mat-label>Description</mat-label>
                <input matInput [(ngModel)]="series.attributes.description" name="description"
                       [dblClickCopy]="series.attributes.description" matTooltip="Double click to copy">
                <mat-hint></mat-hint>
              </mat-form-field>

              <mat-form-field style="margin-right:5px;flex-grow:1;">
                <mat-label>Alias</mat-label>
                <input matInput [(ngModel)]="series.attributes.alias" name="alias">
                <mat-hint>A SHORT name for this series.</mat-hint>
              </mat-form-field>
            </div>

            <div class="column text-area-element">
              <label class="standalone">Assumptions</label>
              <textarea [(ngModel)]="series.attributes.assumptions" [disabled]="disabled" class="full-width"
                        name="assumptions" rows="2"
                        placeholder="Add assumptions related to this series."></textarea>
            </div>

            <ng-container *ngIf="series.attributes?.is_calculation ">
              <h3>Formula</h3>
              <div class="row center">
                <div class="select-search" style="flex-grow:2;" (mouseenter)="hint='formula_series'">
                  <select-search-component [label]="'Select a series'"
                                           [api_model]="'series_light'"
                                           [value]="formula_series"
                                           [classes]="'fill'"
                                           (selectionChange)="formula_series = $event.value;"
                  >
                  </select-search-component>
                </div>
                <button (click)="addToFormula(formula_series)" title="Add to formula" class="btn-addtotext form-element-align">
                </button>
              </div>

              <div class="row center text-area-element">
              <textarea [(ngModel)]="series.attributes.name_formula" name="name_formula" class="full-width" rows="2"
                        [disabled]="disabled">
              </textarea>

                <button class="btn-save-comment" (click)="calcValidator()" title="Check formula"></button>
              </div>
              <div class="row" *ngIf="checkCalcMessage">{{checkCalcMessage}}</div>

            </ng-container>

            <div class="row">
              <mat-checkbox (mouseenter)="hint='accumulation'" [(ngModel)]="series.attributes.accumulation"
                            name="accumulation"
                            [disabled]="disabled">
                Accumulation
              </mat-checkbox>
              <mat-checkbox (mouseenter)="hint='delete_limits'"
                            [disabled]="!series.attributes.is_calculation || disabled"
                            [(ngModel)]="series.attributes.delete_hihilowlow"
                            name="delete_limits"
                            matTooltip="If calculated value outside of the hihi and lowlow limits should be deleted and not
                    save in the database.">
                Delete Out of Limits
              </mat-checkbox>

              <mat-checkbox (mouseenter)="hint='specialised_function'"
                            [(ngModel)]="series.attributes.specialised_function"
                            name="specialised_function"
                            [disabled]="!series.attributes.is_calculation || disabled"
                            matTooltip="If the formula includes a custom function written in python, the following custom functions cannot
                    have an aggregation type of
                    calculation:'cumulativeSumByGroup','lagSeries','calculateDifference','cumulativeSum','cumulativeMax','lagSeries','calculateConditionalTransfer','flattenTotaliser','useIfElse',
                    and 'difference'.">
                Custom Function
              </mat-checkbox>
            </div><!--/row-->

            <div class="row center full-height" *ngIf="series.type=='calculation'">
              <mat-form-field (mouseenter)="hint='extra_arguments'">
                <mat-label>Extra Arguments</mat-label>
                <input matInput placeholder="" [(ngModel)]="series.attributes.extra_arguments_string"
                       name="extra_arguments" [disabled]="disabled">
              </mat-form-field>
              <div class="tip-box">
                <div class="tip" [ngClass]="{'showing': hint=='extra_arguments'}">
                  <label>Extra Arguments</label>
                  <div>Extra arguments to pass into the function</div>
                </div>
              </div>
            </div><!--/row-->

            <div class="row center full-height" *ngIf="!series.id">
              <select-many-series [label]="'Source series'"
                                  [value]="source_series"
                                  [compareFunctionName]="'compareById'"
                                  [classes]="'fill'"
                                  (selectionChange)="source_series = $event;"
              >
              </select-many-series>
            </div>

            <div class="row center full-height">

              <select-search-component [label]="'Series Type'"
                                       [api_model]="'series_type'"
                                       [value]="series.relationships.series_type.data"
                                       [classes]="'fill'"
                                       (selectionChange)="series.relationships.event_type.data=$event.value"
                                       [disabled]="series.id && has_series_type"
                                       [matTooltip]="(series.id && has_series_type) ? 'Series type can not be changed':'Select a series type'"
                                       class="select-search">
              </select-search-component>

              <select-search-component [label]="'Event Type'"
                                       [api_model]="'event_type'"
                                       [value]="series.relationships.event_type.data"
                                       [classes]="'fill'"
                                       (selectionChange)="series.relationships.event_type.data=$event.value"
                                       class="select-search">
              </select-search-component>
            </div><!--/row-->

            <div class="row center full-height">
              <select-search-component class="select-search"
                                       (selectionChange)="engineeringUnitChanged($event.value)"
                                       [api_model]="'engineering_unit'"
                                       [value]="series.relationships.engineering_unit.data"
                                       [compareFunctionName]="'compareById'"
                                       [placeholder]="'Please select'"
                                       [label]="'Engineering Unit'"
                                       [classes]="'fill'"
              >
              </select-search-component>
              <div class="mat-hint">The engineering unit of the series in question.</div>

              <div class="select-search">
                <select-filter
                    [label]="'Sample Period'"
                    (constant_changed)="series.attributes.sample_period=$event"
                    [modelValue]="series.attributes.sample_period"
                    [options]="seriesData.sample_periods"
                    [placeholder]="'Please select (default: Hour)'"
                    [classes]="'fill'"
                >
                </select-filter>
                <div class="mat-hint">The sample period that the value is normally viewed in.</div>
              </div>
              <div class="select-search">
                <select-filter
                    [label]="'Aggregation Type'"
                    (constant_changed)="series.attributes.aggregation=$event"
                    [modelValue]="series.attributes.aggregation"
                    [options]="seriesData.aggregation_types"
                    [placeholder]="'Please select (default: Mean)'"
                    [classes]="'fill'"
                >
                </select-filter>
                <div class="mat-hint">How the value should be aggregated. Flow rates and mostly total, grade values
                  should be weighted. Level and quantities should be latest.
                </div>
              </div>

            </div><!--/row-->

            <div class="row center full-height">

              <mat-form-field (mouseenter)="hint='Default_Chart_Type'" class="override-hide-placeholder">
                <mat-label>Default Chart Type</mat-label>
                <mat-select [(ngModel)]="series.attributes.default_chart"
                            placeholder="Please select (default: Line)" name="default_chart"
                            [disabled]="disabled">
                  <mat-option *ngFor="let ctype of seriesData.chart_types"
                              [value]="ctype.value">{{ctype.title | upperfirst | removeUnderscore}}
                  </mat-option>
                </mat-select>
                <mat-hint>When a series is shown in default charts or in a series table
                  this is how the chart is viewed.
                </mat-hint>
              </mat-form-field>
              <mat-form-field (mouseenter)="hint='KPI_Level'" class="override-hide-placeholder">
                <mat-label>KPI Level</mat-label>
                <mat-select [(ngModel)]="series.attributes.kpi_level"
                            mat-selected-text="series.attributes.kpi_level"
                            [disabled]="disabled" name="kpi_level" placeholder="Please select">
                  <mat-option *ngFor="let level of seriesData.KPI_levels"
                              [value]="level">{{level ? (level | upperfirst | removeUnderscore) : level}}
                  </mat-option>
                </mat-select>
                <mat-hint>On a scale of 1 to 4 how important is this series.
                  KPI level receives special user interface
                  treatment and other KPI levels can be used to filter out unimportant series.
                </mat-hint>
              </mat-form-field>

                <select-search-component [label]="'Weighted Average Series'"
                                         [api_model]="'series_light'"
                                         [value]="series.relationships.weighted_average_series.data"
                                         [classes]="'fill'"
                                         (selectionChange)="series.relationships.weighted_average_series.data=$event.value"
                 class="select-search">
                </select-search-component>

            </div>

            <div class="row center">
              <div class="flex-third alone">
                <select-filter
                    [label]="'Fill Method'"
                    (constant_changed)="series.attributes.fill_method=$event"
                    [modelValue]="series.attributes.fill_method"
                    [options]="fill_methods"
                    [placeholder]="'Please select (default: Forward Fill)'"
                    [classes]="'fill'"
                >
                </select-filter>
              </div>

              <ng-container
                  *ngIf="series.attributes.fill_method==='Default value' || series.attributes.fill_method ==='backfill_padded_with_default_value'">

                <mat-form-field style="flex-grow:1;">
                  <mat-label>Default value</mat-label>

                  <input [(ngModel)]="series.attributes.default_value" [disabled]="disabled" matInput
                         name="default_value"
                         step="any"
                         type="number"
                         style="box-sizing:border-box;">
                </mat-form-field>

              </ng-container>

              <ng-container
                  *ngIf="series.attributes.fill_method==='Rolling average'||
                series.attributes.fill_method === 'interpolated_with_forward_fill_rolling_average' ||
                series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_hours' ||
                series.attributes.fill_method === 'backfill_with_forward_fill_rolling_average' ||
                series.attributes.fill_method === 'backfill_with_calculated_forward_fill_average_by_hours' ||
                series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_count'">

                <mat-form-field>

                  <mat-label *ngIf="series.attributes.fill_method === 'Rolling average'||
                              series.attributes.fill_method === 'interpolated_with_forward_fill_rolling_average' ||
                              series.attributes.fill_method === 'backfill_with_forward_fill_rolling_average'"
                  >Rolling average window (hours)
                  </mat-label>

                  <mat-label *ngIf="series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_hours'||
                              series.attributes.fill_method === 'backfill_with_calculated_forward_fill_average_by_hours'"
                  >Calculated average window (hours)
                  </mat-label>

                  <mat-label
                      *ngIf="series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_count'"
                  >Average count (valid entries)
                  </mat-label>


                  <input [(ngModel)]="series.attributes.rolling_average_hours" [disabled]="disabled"
                         matInput
                         name="rolling_average_hours"
                         step="any">

                </mat-form-field>
              </ng-container>

            </div>

            <h3>
              Limits and stats for the series.
            </h3>
            <p>Please note that limits are hourly limits, regardless of the series sample period. Please specify
              accordingly</p>
            <div class="row full-height">
              <mat-form-field class="md-block">
                <mat-label>HiHi</mat-label>
                <input matInput type="number" step="any" [(ngModel)]="series.attributes.hihi" name="hihi"
                       [disabled]="disabled">
              </mat-form-field>
              <mat-form-field class="md-block">
                <mat-label>Hi</mat-label>
                <input matInput type="number" step="any" [(ngModel)]="series.attributes.hi" name="hi"
                       [disabled]="disabled">
              </mat-form-field>
              <mat-form-field class="md-block">
                <mat-label>LowLow</mat-label>
                <input matInput type="number" step="any" [(ngModel)]="series.attributes.lowlow"
                       name="lowlow"
                       [disabled]="disabled">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Low</mat-label>
                <input matInput type="number" step="any" [(ngModel)]="series.attributes.low" name="low"
                       [disabled]="disabled">
              </mat-form-field>
            </div><!--/row-->

            <div class="row">
              <mat-form-field>
                <mat-label>Mean</mat-label>
                <input matInput type="number" step="any" [(ngModel)]="series.attributes.mean" name="mean"
                       [disabled]="disabled">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Std</mat-label>
                <input matInput type="number" step="any" [(ngModel)]="series.attributes.std" name="std"
                       [disabled]="disabled">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Decimal Places</mat-label>
                <input matInput matTooltip="The number of decimal places associated with this series"
                       type="number"
                       step="any" [(ngModel)]="series.attributes.decimal_places"
                       name="decimal_places"
                       [disabled]="disabled">
                <mat-hint>Decimal places are by default calculated according to significant
                  numbers.
                  This parameter is used to
                  customise default functionality with this set number of decimal places
                </mat-hint>
              </mat-form-field>
              <div class="spacer"></div>
            </div><!--/row-->
            <!--            <div class="info">Decimal Places <i matTooltip="Click for more information on decimal places"-->
            <!--                                                class="fa fa-info-circle"-->
            <!--                                                (click)="showDecimalDescription=!showDecimalDescription"></i></div>-->

            <div class="row">


            </div><!--/row-->

          </form>
        </mat-tab>

        <mat-tab *ngIf="series?.id && tab_group===tabGroups.CONFIGURATION" label="Chart">
          <ng-template matTabContent>
            <div class="row medium-height space-between">
              <mat-form-field (mouseenter)="hint='Default_Chart_Type'" class="flex-half alone">
                <mat-label>Default Chart Type</mat-label>
                <mat-select [(ngModel)]="series.attributes.default_chart" placeholder="Please select (default: Line)"
                            name="default_chart" [disabled]="disabled">
                  <mat-option *ngFor="let ctype of seriesData.chart_types"
                              [value]="ctype.value">{{ctype.title | upperfirst | removeUnderscore}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="overrideCalculations()" class="self-center"
                      *ngIf="permissions[series.id] && permissions[series.id].includes('override_calculations')"
                      color="primary" mat-raised-button>Update calculations
              </button>
            </div>
            <default-chart [series]="series" style="max-height:440px;"></default-chart>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="series?.id && tab_group===tabGroups.CONFIGURATION" label="Links">
          <ng-template matTabContent>
            <form class="form-section column">
              <div class="column">

                <ng-container *ngIf="series_components">
                  <h3>Allow editing series for these processes</h3>
                  <ng-container *ngFor="let sc of series_components">
                    <div class="row list" style="justify-content: flex-start"
                         *ngIf="sc_process_dict[sc.id]">
                      <mat-checkbox [(ngModel)]="sc.attributes.can_edit"
                                    (change)="saveSeriesComponent(sc)"
                                    [ngModelOptions]="{standalone:true}"
                                    labelPosition="after"> {{sc_process_dict[sc.id].attributes | nameOrDescription}}
                      </mat-checkbox>
                      <div style="color:#666;margin-left:20px;" *ngIf="sc.result">{{sc.result}}</div>
                      <div style="margin-left:20px;">
                        <a [routerLink]="'/view/edit_series_components/' + sc.relationships.component.data.id"
                           style="color:darkorange;text-decoration:none;"
                           matTooltip="Got to Report Configuration" (click)="onCloseClick()">
                          Report Configuration<i class="fa fa-arrow-right"
                                                 style="margin-left:10px;"></i></a>
                      </div>
                      <div style="margin-left:20px;">
                        <a [routerLink]="'/view/log_sheet/' + sc.relationships.component.data.id"
                           style="color:darkorange;text-decoration:none;"
                           matTooltip="Go to Log Sheet" (click)="onCloseClick()">
                          Log Sheet<i class="fa fa-arrow-right" style="margin-left:10px;"></i></a>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <div *ngIf="permissions[series.id] && permissions[series.id].includes('view_process_data')">
                  <mat-divider style="margin: 20px 0 0;border: 1px inset #fff;width:100%;"></mat-divider>

                  <series-estimate-form [current_series]="series"
                                        [component]="component"></series-estimate-form>
                </div>

                <mat-divider style="margin: 20px 0 0;border: 1px inset #fff;width:100%;"></mat-divider>
                <h3>Other properties</h3>
                <div class="row list">
                  <label>Linked Components:</label>
                  {{series.attributes.linked_components| upperfirst}}
                </div>
                <div class="row list">
                  <label>Alternate Names:</label>
                  {{series.attributes.alternate_names| upperfirst}}
                </div>
                <div class="row list">
                  <label>Estimate Names:</label>
                  {{series.attributes.target_names| upperfirst}}
                </div>

                <div class="row list">
                  <label>Collector Names:</label>
                  {{series.attributes.collector_names| upperfirst}}
                </div>
              </div>

              <div *ngIf="series.id">
                <mat-divider style="margin: 20px 0 0;border: 1px inset #fff;width:100%;"></mat-divider>
                <h3>Constant Collation Series Export Details:</h3>
                <collation-series-table [series]="series"></collation-series-table>
              </div>

            </form>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="series?.id && tab_group===tabGroups.REPORTING" label="Correction">
          <ng-template matTabContent>
            <date-time-period [no_url]="true" [immutable]="true" class="date-period inline align-top"
                              style="top:0;"></date-time-period>
            <div style="margin-top:28px;"></div>
            <apply-correction></apply-correction>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="series?.id && tab_group===tabGroups.REPORTING" label="Audit History">
          <ng-template matTabContent>
            <series-audit-history [series]="series" [table_view]="true"></series-audit-history>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="!!dtp && series?.id && tab_group===tabGroups.OPERATIONS" label="Analysis Tools">
          <ng-template matTabContent>
            <analysis-tools *ngIf="series && !!dtp" [selected_series]="series"></analysis-tools>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="series?.id && tab_group===tabGroups.OPERATIONS" label="Exceptions">
          <ng-template matTabContent>
            <div>
              <p>Please note that limits are hourly limits, regardless of the series sample period. Please specify
                accordingly</p>
              <div class="row">
                <mat-form-field class="md-block">
                  <mat-label>HiHi</mat-label>
                  <input matInput type="number" step="any" [(ngModel)]="series.attributes.hihi" name="hihi"
                         [disabled]="disabled">
                </mat-form-field>
                <mat-form-field class="md-block">
                  <mat-label>Hi</mat-label>
                  <input matInput type="number" step="any" [(ngModel)]="series.attributes.hi" name="hi"
                         [disabled]="disabled">
                </mat-form-field>
                <mat-form-field class="md-block">
                  <mat-label>LowLow</mat-label>
                  <input matInput type="number" step="any" [(ngModel)]="series.attributes.lowlow"
                         name="lowlow"
                         [disabled]="disabled">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Low</mat-label>
                  <input matInput type="number" step="any" [(ngModel)]="series.attributes.low" name="low"
                         [disabled]="disabled">
                </mat-form-field>
              </div><!--/row-->
              <generic-chart *ngIf="spc_config" [config]="spc_config" class="chart"></generic-chart>
              <alerts-table [series_list]="[series]"></alerts-table>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
    <div class="align-bottom">
      <date-time-period *ngIf="(tab_group==tabGroups.CONFIGURATION && current_tab==seriesConfigurationTabs.CHART) ||
    (tab_group==tabGroups.REPORTING && current_tab==seriesReportingTabs.AUDIT) ||
    (tab_group==tabGroups.OPERATIONS)"
                   [immutable]="true"
                   [no_url]="true"
                   class="date-period inline align-bottom"></date-time-period>
    </div>
  </mat-dialog-content><!--/mat-dialog-content-->

  <div class="audit-text" *ngIf="tab_group==tabGroups.CONFIGURATION && current_tab==seriesConfigurationTabs.DETAILS">
    <div>Changed on {{series.attributes.changed_on | stringDate}} by {{series.attributes.changed_by_name}}</div>
    <audit-mixin-indicator *ngIf="showAudit()"
                           [model]="series"></audit-mixin-indicator>
  </div>
  <div class="action-buttons">
    <button class="btn-close" [disabled]="applying " (click)="onCloseClick()">Close</button>
    <button class="btn-save" [disabled]="applying" (click)="saveOrApply('save')">Save</button>
    <button class="btn-save" [disabled]="applying" (click)="saveOrApply('apply')">Apply</button>
  </div>
</div>
