<div class="validation-builder" *ngIf="fields && validationQuery">
  <rule-set [ruleSet]="validationQuery" (ruleSetChange)="ruleSetChanged()" [fields]="fields"
            [allowRemoveRuleSet]="false" [isQueryBuilder]="false"></rule-set>

  <div class="info row center">
    <mat-icon>info</mat-icon>
    Please note that rules only apply to the column being changed.
    To create the same rule for the comparison field, please add another filter with the rule reversed.
  </div>
</div>
