import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit, ElementRef,
    Output,
    ViewChild,
    ViewEncapsulation,
    Renderer2, AfterViewInit
} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HeaderDataService} from "../../services/header_data.service";
import {TileDataService, TileParameters} from "../../services/tile_data.service";
import {forkJoin, Observable, Subject, take} from "rxjs";
import {AppScope} from "../../services/app_scope.service";
import {takeUntil, tap} from "rxjs/operators";
import {MassBalanceResponse} from "../../_models/mass-balance-response";
import {PivotTileConfig} from "../../_typing/config/pivot-tile";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {PivotService} from "../../services/pivot.service";
import {WebdatarocksComponent} from "@webdatarocks/ngx-webdatarocks";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {httpParamSerializer} from "../../lib/utils";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    selector: 'pivot-tile-ng',
    templateUrl: './pivot-tile-ng.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PivotTileNgComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    buttons: ITileButton[];

    @ViewChild('pivotAnchor') pivotAnchor: WebdatarocksComponent;
    @ViewChild('pivotWrap') pivotWrap: ElementRef;

    pivotComponent: any;
    pivotParameters: any;
    pivotData: any;
    showToolbar = true;

    @Output()
    tileContentChange: EventEmitter<TileParameters> = new EventEmitter();

    constructor(
        private http: HttpClient,
        private dateInst: DateTimeInstanceService,
        private headerData: HeaderDataService,
        public tileData: TileDataService,
        private appScope: AppScope,
        private pivotService: PivotService,
        private renderer: Renderer2) {
    }

    private _config: PivotTileConfig = new PivotTileConfig();

    get config(): PivotTileConfig {
        return this._config;
    }

    @Input()
    set config(config: PivotTileConfig) {
        this._config = config;
    }

    ngOnInit() {
        this.headerData.show_dtp = true;
        this.buttons = this.pivotService.getPivotTileButtons();
        this.buttons.find(b => b.name === 'Edit').func = function () {
            this.refreshPivot(true);

        }.bind(this);
        this.buttons.find(b => b.name === 'Save').func = function () {
            this.savePivotState();
        }.bind(this);

        this.tileData.buttonsChanged.next(this.buttons);

        this.dateInst.dateTimePeriodRefreshed$
            .pipe(takeUntil(this.onDestroy))
            .subscribe((dtp: IDateTimePeriod) => {
                this.pivotParams();
                this.getData();
            });
    }

    ngAfterViewInit() {
        this.dateInst.dateTimePeriodChanged$.pipe(take(1))
            .subscribe(() => {
                this.pivotParams();
                this.getData();
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getData() {
        this.tileData.noTileData.next('');

        if (this.config.event_type_ids?.length > 0 || this.config.component_type_ids?.length > 0) {
            this.getComponentOrEventData().subscribe(() => this.buildPivot());
        } else if (this.config.ore_bodies_list.length > 0) {
            let $subs = [];
            this.config.ore_bodies_list.forEach(ob => {
                let params = {
                    ore_body_id: ob.id,
                    start: this.dateInst.dtp.start.toISOString(),
                    end: this.dateInst.dtp.end.toISOString(),
                    pivot: true
                };

                $subs.push(this.http.get("api/OreBody/MassBalance" + '?' + httpParamSerializer(params)));
            });
            forkJoin($subs).pipe(tap({
                next: (data: MassBalanceResponse[]) => {
                    this.pivotData = data.map(ob => ob.data);
                    this.buildPivot();
                }, error: error => {
                    console.log('Error - : ', error);
                }
            })).subscribe();

        } else if (this.config.series_list.length > 0) {
            this.getSeriesData().subscribe(() => this.buildPivot());
        }

    }

    getComponentOrEventData(): Observable<any> {
        let fields, selected;

        if (this.config.pivot_state?.report?.slice) {
            const slice = this.config.pivot_state.report.slice;
            selected = [...slice.rows || [], ...slice.columns || [], ...slice.reportFilters || [], ...slice.measures || []];
        }
        fields = selected?.map(item => item.uniqueName) || undefined;

        return this.pivotService.getComponentOrEventData(this.config, fields)
            .pipe(takeUntil(this.onDestroy),
                tap((data: any[]) => {
                    this.checkForData(data)
                    this.pivotData = data;
                }))
    }

    private getSeriesData(): Observable<any> {
        return this.pivotService.getTimeSeriesDataObs(this.config, false)
            .pipe(takeUntil(this.onDestroy),
                tap((data: any[]) => {
                    this.checkForData(data)
                    this.pivotData = data;
                }))
    }

    private checkForData(data): void {
        if (!data.length) {
            this.tileData.noTileData.next('pivot series data');
        }
    }


    buildPivot() {
        this.pivotComponent = this.pivotAnchor.webDataRocks;
        this.pivotParameters.report.dataSource = {data: this.pivotData}
        this.pivotComponent.setReport(this.pivotParameters.report);
    }

    pivotParams() {
        let params = {
            report: {}
        };
        let state = this.config.pivot_state;
        if (state.report) {
            state.report.slice.rows.forEach(r => {
                r.filter = {members: [r.uniqueName], negation: true}
            })
            state.report.slice.columns.forEach(r => {
                r.filter = {members: [r.uniqueName], negation: true}
            })
            params.report = state.report;
        }
        params.report = this.addShowHeaders(params.report, false);
        this.pivotParameters = params;

    }

    addShowHeaders(report, value: boolean) {
        // NB, please be aware that webdatarocks on saves manually configured items to the report
        // showHeaders doesn't seem to be added to the report (maybe because it has been set programmatically)
        // so needs to be added without replacing user-configured values.
        if (report['options']) {
            if (report['options']['grid']) {
                report['options']['grid'].showHeaders = value;
            } else {
                report['options']['grid'] = {showHeaders: value};
            }
        } else {
            report['options'] = {grid: {showHeaders: value}};
        }
        return report;
    }

    updatePopupStyles(revert: boolean = false) {
        let tile_grid_item = this.pivotWrap.nativeElement.closest(".tile-grid-item");
        let section_grid_item = this.pivotWrap.nativeElement.closest(".section-grid-item");
        let gridster = this.pivotWrap.nativeElement.closest("gridster.fit");
        if (revert === true) {
            this.renderer.setStyle(tile_grid_item, 'overflow', 'hidden');
            this.renderer.setStyle(section_grid_item, 'overflow', 'hidden');
            this.renderer.setStyle(section_grid_item, 'z-index', '10');
            this.renderer.setStyle(gridster, 'overflow', 'hidden');

        } else {
            this.renderer.setStyle(tile_grid_item, 'overflow', 'unset');
            this.renderer.setStyle(section_grid_item, 'overflow', 'unset');
            this.renderer.setStyle(section_grid_item, 'z-index', '20');
            this.renderer.setStyle(gridster, 'overflow', 'unset');
        }
    }

    customizeToolbar(toolbar: any) {
        // If we need to adjust the popup style of the Fields popup, something like this could be an option
        let tabs = toolbar.getTabs(); // get all tabs from the toolbar
        toolbar.getTabs = () => {
            delete tabs[0]; // connect
            delete tabs[1]; // open
            delete tabs[2]; // save - this is actually an event to download the full json config
            return tabs;
        };
    }

    savePivotState(): void {
        //this.pivotComponent = this.pivotAnchor.webDataRocks;
        this.config.pivot_state.report = this.pivotComponent.getReport();
        this.tileContentChange.emit(this.config);
        this.showToolbar = false;
        this.refreshPivot();
    }

    refreshPivot(showToolbar = false): void {
        this.showToolbar = showToolbar;

        // Get the current report
        let report = this.pivotComponent.getReport();

        // Ensure the report is an object
        if (typeof report === 'string') {
            console.error('Expected report to be an object, but got a string.');
            return;
        }

        // Update the showHeaders and toolbar settings in the report
        const updatedReport = this.addShowHeaders(report, this.showToolbar);
        this.pivotComponent.setReport(updatedReport);

        this.pivotComponent.refresh();
        this.updatePopupStyles(!this.showToolbar);
    }

}
