<div class="options-override-row" style="display:block;">
  <ng-container *ngIf="!(dataService.isLoading$ | async)">
    <h3>
      Choose at least one constant property header to select unique property</h3>
    <select-search-component
        (selectionChange)="setConstantProperty($event.value)"
        [api_model]="'constant_property_light'"
        [custom_filters]="dataService.event_type_property_filter || templateService?.property_filter"
        [value]="constantPropertyID"
        [compareFunctionName]="'compareValueToId'"
        [label]="'Select Unique property to use for upsert'"
        [classes]="'fill'"
        [disabled]="readonly"
        style="width:100%;"
    ></select-search-component>
  </ng-container>
  <ngx-skeleton-loader
      *ngIf="dataService.isLoading$ | async" count="1"
      title="Select Event Type to show Constant Properties"
      [theme]="{'margin-bottom': '0'}"
      class="progress-skeleton"
      animation="pulse"
      appearance="line"
  ></ngx-skeleton-loader>
</div>
