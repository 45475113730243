import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { HttpClient, HttpStatusCode } from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {ApiService} from "../services/api/api.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'reset-password',
    templateUrl: 'reset-password.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    private days: number;
    password: string;
    password_check: string;
    errors: any[];
    default_session_state: any;
    account_id: string;
    specialCharacters: string = '# ? ! @ $ % ^ & * -';
    private readonly onDestroy = new Subject<void>();

    constructor(private http: HttpClient,
                private appScope: AppScope,
                private snackBar: MatSnackBar,
                private router: Router,
                private api: ApiService) {
    }

    ngOnInit(): void {
        this.appScope.no_header = false;
        this.days = 90;
        this.account_id = this.appScope.current_user.default_account_id

        this.api.session_state_light.searchMany().pipe(takeUntil(this.onDestroy)).subscribe(response => {
            const session_states = response.data;
            this.default_session_state = session_states.find(s => {
                return s.relationships.account.data.id === this.account_id && !!s.attributes.is_default;
            });

        });

    }

    submit() {
        const ctrl = this;
        if (ctrl.password === ctrl.password_check) {
            ctrl.http.post('/auth/reset', {password: ctrl.password}).toPromise().then((result: any) => {
                if (result.hasOwnProperty('errors')) {
                    ctrl.errors = [result.errors];
                    ctrl.snackBar.open('Error occurred while resetting password', 'Hide');
                    //    TODO alert user that password has been changed successfully
                } else {
                    ctrl.appScope.no_header = false;
                    ctrl.appScope.current_user.password_expiry = 120;
                    ctrl.snackBar.open("Your password has been reset", 'Hide');

                    if (ctrl.default_session_state) {
                        ctrl.router.navigateByUrl('view/page_view/' + ctrl.default_session_state.id);
                    } else {
                        ctrl.router.navigateByUrl('view/welcome');
                    }

                }
            }, result => {
                if (result.status == HttpStatusCode.BadRequest) {
                    ctrl.snackBar.open('Please enter a valid password: see guidelines', 'Hide');
                } else {
                    ctrl.errors = [result.error.error];
                    ctrl.snackBar.open(result.error.error, 'Hide');
                }

            });
        } else {
            ctrl.snackBar.open('Passwords do not match', 'Hide');
            ctrl.errors = ['Passwords do not match'];
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
