import {Component, ViewEncapsulation, OnInit, ViewChild} from "@angular/core";
import {GroupListService} from "./group-list.service";
import {PaginationDataSource} from "../../../services/api/pagination-data-source";
import {Alert} from "../../../_models/alert";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {Group} from '../../../_models/group';
import {takeUntil, tap, debounceTime, distinctUntilChanged} from "rxjs/operators";
import {BaseComponent} from "../../../shared/base.component";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {UserFormComponent} from "../../../forms/user-form/user-form.component";
import {UserGroupPageFormDialogComponent} from "../../../forms/user-group-page-form-dialog/user-group-page-form-dialog.component";
import {FormDialogService} from "../../../services/form-dialog.service";
import {Subject, ReplaySubject} from 'rxjs';
import {User} from "../../../_models/users";
import {MatTableModule} from "@angular/material/table";
import {CommonModule} from "@angular/common";
import {RemoveUnderscorePipe, StringDatePipe, TitlecaseForWirePipe} from "../../../shared/pipes";

@Component({
    providers: [GroupListService],
    selector: 'group-list',
    templateUrl: './group-list.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GroupListComponent extends BaseComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    applyFilter = this.cps.applyFilter;
    dataSource: PaginationDataSource<Group>;
    columns = this.cps.columns;
    filter_string = '';

    constructor(private cps: GroupListService,
                private formDialogService: FormDialogService) {
        super();
        this.cps.groupDataSourceSubject.pipe(
            tap(ds => {
                this.dataSource = ds;
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.cps.sort = this.sort;
            this.cps.paginator = this.paginator;
            this.cps.initialise();
        });

    }

    updateSort(event) {
        this.cps.updateSort(this.sort);
    }

    openDialog(model: User | Group): void {
        this.formDialogService.openUserGroupPageFormDialog(model);
    }
}
