import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, ReplaySubject, Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {ApiService} from "../../../services/api/api.service";
import {SearchQueryOptions} from "../../../services/api/search-query-options";

@Injectable({
    providedIn: 'root'
})
export class ComponentModelUploadDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    private component_type$ = new Subject();
    private loadDataSubscription: Subscription;

    public component_type_id$: Subject<string> = new ReplaySubject(1);

    public component_types$ = new BehaviorSubject([]);
    public isLoading$ = new BehaviorSubject(true);
    public component_type_property_filter;
    // This is needed so that other components can reload themselves after a template has been selected
    public options$ = new Subject();

    constructor(private api: ApiService) {
    }

    changeComponentType(component_type_id: string) {
        this.component_type_id$.next(component_type_id);

        const s$ = [];
        this.component_type_property_filter = [{
            name: 'component_types',
            op: 'any',
            val: {name: 'id', op: 'eq', val: component_type_id}
        }];
        const component_type_options = new SearchQueryOptions();
        component_type_options.filters = [
            {
                "name": "component_type_component_type",
                "op": "any",
                "val": {
                    "or": [
                        {
                            "name": "first_component_type",
                            "op": "has",
                            "val": {"name": "id", "op": "eq", "val": component_type_id}
                        },
                        {
                            "name": "second_component_type",
                            "op": "has",
                            "val": {"name": "id", "op": "eq", "val": component_type_id}
                        }
                    ]
                }
            }
        ];
        s$.push(this.api.component_type.searchMany(component_type_options).pipe(tap(response => {
            this.component_types$.next(response.data.filter(v => v.id !== component_type_id));
        })));

        if (this.loadDataSubscription) {
            this.loadDataSubscription.unsubscribe();
        }

        this.isLoading$.next(true);

        this.loadDataSubscription = combineLatest(s$).pipe(takeUntil(this.onDestroy)).subscribe(r => {
            this.isLoading$.next(false);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
