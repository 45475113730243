<div class="form-dialog">
  <h1>Edit Custom HTML
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">

      <mat-tab label="Edit HTML">
        <series-html-form [(htmlString)]="customText" [isLegacy]="true"></series-html-form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save">Save</button>
  </div>
</div>
