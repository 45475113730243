<div class="form-dialog">
  <h1>Delete {{property.attributes.name}} on {{component.attributes.name}}</h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Confirm delete?">
        <p>Please make sure you are deleting the correct file attachment.</p>
        <ng-container *ngIf="time_remaining > 0; else mayDelete">
          <p>Please wait {{time_remaining}} seconds for the the delete button to be actived.</p>
        </ng-container>
        <ng-template #mayDelete>
          <p>The {{delay_time / 1000}} second time has lapsed. You may now delete the file.</p>
        </ng-template>
        <br>
        <p>To ensure this file is securely stored, it will be saved in an archive. If you need to retrieve the file at
          any time, we kindly ask that you reach out to our service desk at
          <a href="mailto:{{serviceDeskEmail}}" target="_blank">
            {{serviceDeskEmail}}
          </a>
        </p>

      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <!--    TODO make the close button embossed -->
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="deleteFile()" class="btn-save" [disabled]="!deletable">Delete</button>
  </div>
</div>
