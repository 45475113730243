import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
    HostListener
} from '@angular/core';
import {Subject} from "rxjs";
import {ApiService} from '../../services/api/api.service';
import {first, take, takeUntil, tap} from "rxjs/operators";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {SeriesDataService} from "../../services/series_data.service";
import * as utils from "../../lib/utils";
import {NameOrDescriptionPipe} from "../../shared/pipes";
import {MatDialog} from "@angular/material/dialog";
import {AppScope} from "../../services/app_scope.service";
import {TileDataService} from '../../services/tile_data.service';
import {CachingService} from '../../services/caching.service';
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesSummaryConfig} from '../../services/context.service';
import {FormDialogService} from "../../services/form-dialog.service";
import {SeriesSummary} from "../../_models/api/series-summary";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";

@Component({
    selector: 'context',
    templateUrl: './context.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ContextComponent implements OnInit {
    private readonly onDestroy = new Subject<void>();
    @Input() is_vdt = false;
    @Input() config: SeriesSummaryConfig;
    @Input() show_header: boolean;
    @Input() title: string = '';
    private from_cache: boolean = false;

    dtp: IDateTimePeriod;
    summary: SeriesSummary;

    @ViewChild('context_tile', {static: true}) context_tile: ElementRef;
    @ViewChild('context_content', {static: true}) context_content: ElementRef;
    @ViewChild('generic') generic_chart: ElementRef;
    @ViewChild('primary_value', {static: true}) primary_value: ElementRef;
    viewHeight: number;
    viewWidth: number;
    est_favourability_mapping: {};
    red: string = 'red';
    green: string = 'green';
    category_colour: string = 'default';
    content_height = 200;
    revert_size: any;
    private is_printing = {current: false, previous: false};

    constructor(private api: ApiService,
                private dialog: MatDialog,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private renderer: Renderer2,
                public seriesData: SeriesDataService,
                private AppScope: AppScope,
                private tileData: TileDataService,
                private cache: CachingService,
                private headerData: HeaderDataService,
                private formDialogService: FormDialogService,
                private el: ElementRef) {
    }

    ngOnInit() {
        const ctrl = this;
        if (!this.config.selected_series) {
            return
        }
        this.seriesData.$estimate_types.promise.then(response => {
            ctrl.est_favourability_mapping = ctrl.seriesData.est_favourability_dict;
        });
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.getSeriesSummary();
        });

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {

            ctrl.dtp = ctrl.dateInst.dtp;
            if (ctrl.cache.vdt_context_cache[ctrl.config.selected_series.id + ctrl.dtp.start + ctrl.dtp.end] &&
                ctrl.config.cache === true) {
                ctrl.getCachedContext(ctrl.config.selected_series.id + ctrl.dtp.start + ctrl.dtp.end);
            } else {
                ctrl.getSeriesSummary();
            }
        });

        this.headerData.refreshVDTNodeSubject.pipe(tap(ids => {
            if (!ids.includes(this.config.selected_series.id)) return;
            this.getSeriesSummary();
        }), takeUntil(this.onDestroy)).subscribe();

        this.headerData.pagePrinting.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            this.is_printing = {current: newBool, previous: this.is_printing.current};
        });
        if (ctrl.tileData.tile && ctrl.tileData.tile.attributes.category) {
            ctrl.category_colour = ctrl.tileData.tile.attributes.category.colour
        }
        if (!ctrl.title) {
            ctrl.title = new NameOrDescriptionPipe().transform(ctrl.config.selected_series.attributes);
        }
        if (this.tileData.tile?.attributes.show_header) {
            ctrl.tileData.setDefaultTitle(ctrl.title);
        }
        if (ctrl.AppScope.config_name_map.hasOwnProperty('palette2')) {
            if (ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Green')) {
                ctrl.green = ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Green').colour
            } else {
                ctrl.green == 'green'
            }
            if (ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Red')) {
                ctrl.red = ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Red').colour
            } else {
                ctrl.red = 'red'
            }
        }

    }

    getRenderedSize() {
        const ctrl = this;
        let h = 30;
        if (this.tileData.tile?.attributes.show_header) {
            h = 0;
        }
        this.viewHeight = this.context_tile.nativeElement.offsetHeight; //(height minus title)
        this.viewWidth = this.context_tile.nativeElement.offsetWidth;

        this.content_height = this.viewHeight - h - 55 - 18; //Heights of primary value, title and chart padding
        if (ctrl.config.chart_type === 'custom_chart') {
            ctrl.config.chart_config['set_size'] = {
                height: ctrl.viewHeight,
                width: ctrl.viewWidth
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        const ctrl = this;
        if (ctrl.config.chart_type !== 'custom-chart') return;
        //Row size doesn't currently (04/2021) change unless printing / finishing printing
        if (!(this.is_printing.current === true || this.is_printing.previous === true)) return;

        setTimeout(() => {
            if (this.is_printing.current === true) {
                this.revert_size = utils.deepCopy(ctrl.config.chart_config['set_size'])
                this.getRenderedSize();
                ctrl.tileData.tileResize.next(ctrl.config.chart_config['set_size']);
            } else if (this.is_printing.current === false && this.is_printing.previous === true) {
                this.is_printing.previous = false;
                ctrl.config.chart_config['set_size'] = utils.deepCopy(this.revert_size);
                ctrl.tileData.tileResize.next(ctrl.config.chart_config['set_size']);
            }
        }, 100);
    }

    getCachedContext(id) {
        this.from_cache = true;
        let cached_context = this.cache.vdt_context_cache[id];
        this.config = this.cache.getCachedContextConfig(id);
        this.est_favourability_mapping = cached_context.est_favourability_dict;
        this.summary = this.cache.getCachedContextSummary(id, this.est_favourability_mapping[this.config.primary_value]);
        setTimeout(() => {
            this.resizeToFit();
        })
    }

    cacheContext() {
        let cached_context_id = this.cache.cacheContext(this.config, this.summary, this.est_favourability_mapping);
    }

    getSeriesSummary() {
        const ctrl = this;
        if (!ctrl.config.selected_series) {
            return;
        }
        //Set value defaults
        if (!ctrl.config.primary_value) {
            ctrl.config.primary_value = 'Value'
        }
        if (!ctrl.config.secondary_val_1) {
            ctrl.config.secondary_val_1 = 'Forecast'
        }
        if (!ctrl.config.secondary_val_2) {
            ctrl.config.secondary_val_2 = 'MTD'
        }
        let selected_columns = [ctrl.config.primary_value, ctrl.config.secondary_val_1, ctrl.config.secondary_val_2];

        if (!ctrl.config.chart_type || ctrl.config.chart_type === 'sparkline') {
            selected_columns.push('Sparkline');
        }
        selected_columns.push('Unit');

        this.seriesData.$estimate_types.promise.then(list => {
            let estTypeList = this.seriesData.getEstimateTypesList(selected_columns, list);
            let summary = this.seriesData.getSeriesSummary(this.dtp, [this.config.selected_series.id], null, selected_columns, estTypeList)
            summary.pipe(take(1), takeUntil(this.onDestroy), tap(result => {
                this.getRenderedSize();
                if (!result) {
                    console.warn('ContextComponent: GSS Query cancelled. Replace this call with a subscription.');
                    return;
                }
                ctrl.summary = result[0];

                if (ctrl.summary['Unit']) ctrl.config.series_unit = ctrl.summary['Unit']

                if (!ctrl.from_cache) {
                    ctrl.cacheContext();
                }
                this.AppScope.resizeWindowEvent();
                setTimeout(() => {
                    this.resizeToFit();
                })
            })).subscribe();
        });
    };

    openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name, this.dateInst.dtp);
    };

    resizeToFit() {
        const vdt_value = this.renderer.selectRootElement(this.el.nativeElement, true).querySelector('div.vdt-value');
        if (!vdt_value) return;
        const computedStyle = getComputedStyle(vdt_value);
        const fontSize = parseInt(computedStyle.fontSize);
        if ((vdt_value.offsetWidth / vdt_value.parentElement.offsetWidth > 0.5) && fontSize >= 10) {
            this.renderer.setStyle(vdt_value, 'font-size', fontSize - 1 + 'px');
            this.resizeToFit();
        } else {
            this.renderer.setStyle(vdt_value, 'visibility', 'visible');
        }
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
