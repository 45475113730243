<div class="page traveler" style="padding-left:0;padding-right:0;">
  <div>
    <!--<div flex layout="row">-->
    <div *ngIf="flowchartReady" class="flowchart-container">
      <flow-chart (context_menu)="contextMenu($event)" [chart]="chartViewModel">
      </flow-chart>
    </div>
    <!--</div>	-->
  </div>
  <div [hidden]="formSize === 0"
       [ngClass]="{'side-min':formSize==30,'side-max':formSize==70}" class="flowcharts hideonprint side-panel">
    <div *ngFor="let selected_component of selected_components" class="form-dialog">
      <h5 style="text-transform: uppercase;">
        <i (click)="expand()" [ngClass]="{'fa-arrow-right': expandSize==70, 'fa-arrow-left': expandSize==30}"
           class="fa" style="margin-right: 10px;"></i> Selected: {{selected_component.data.attributes?.base_type}}
      </h5>
    </div>
  </div>
  <!--TODO:remove this, just a pain to keep adding and removing while I'm working on it on and off!!-->
  <button (click)="enableContext=!enableContext" *ngIf="current_user && current_user.name==='Jules'"
          class="context-button">Context {{enableContext}}
  </button>

  <div [ngStyle]="{'display':menuVisible? 'block':'none'}" class="flowchart-context-menu">
    <ul class="menu-options">
      <!--Edit processes, streams and equipment-->
      <li (click)="editSelected(null, null)"
          *ngIf="selected_components[0]?.data.attributes?.base_type==='process' || selected_components[0]?.data.attributes?.base_type==='stream'
 		|| selected_components[0]?.data.attributes?.base_type==='equipment'"
          class="menu-option">Edit
      </li>
      <!--Edit custom chart-->
      <li (click)="editSelectedCustomChart(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='custom_chart'"
          class="menu-option">Edit Custom Chart
      </li>
      <!--Edit ore body group-->
      <li (click)="editSelectedOreBodyGroup(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='ore_body_group'"
          class="menu-option">Edit Ore Body Group
      </li>
      <!--Edit parent process of an image-->
      <li (click)="selected_components[0]?.parent_component.select(); editSelected(null, null)"
          *ngIf="selected_components[0]?.data.type==='image' && selected_components[0]?.parent_component"
          class="menu-option">Edit Process
      </li>
      <!--Edit image-->
      <li (click)="editSelectedImage(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='image'"
          class="menu-option">Edit Image
      </li>
      <!--Edit context tile (allow editing of existing old context tiles)-->
      <li (click)="editSelectedContext(selected_components[0])"
          *ngIf="selected_components[0]?.type==='context'"
          class="menu-option">Edit Context
      </li>
      <!--Edit series summary tile-->
      <li (click)="editSelectedContext(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='series-summary'"
          class="menu-option">Edit Series Summary
      </li>

      <!--New Process-->
      <li (click)="newProcess()"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">New Process
      </li>
      <!--New Custom Chart-->
      <li (click)="editCustomChart(null, null, true)"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">New Custom Chart
      </li>
      <!--New Image-->
      <li (click)="editImage(null, null, true, selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='process'"
          class="menu-option">New Image
      </li>

      <!--New Series Summary Tile-->
      <li (click)="editContext(null, null, true)"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">New Series Summary
      </li>
      <!--Add OBG-->
      <li (click)="editOreBodyGroup(null, null, true)"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">Add Ore Body Group
      </li>
      <!--      New Connector-->
      <li (click)="addNewConnector()"
          *ngIf="selected_components[0]?.data.type==='process'"
          class="menu-option">Add Connector
      </li>
      <!--New Equipment-->
      <li (click)="toggleMenu('hide');addNewEquipment()"
          *ngIf="selected_components[0]?.data.type==='process' || selected_components[0]?.data.type==='stream'"
          class="menu-option">Add Equipment
      </li>
      <!--New Point-->
      <li (click)="addPoint(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='stream'"
          class="menu-option">Add Point
      </li>

      <!--Delete Process, Stream, Equipment, Connector, Point-->
      <li (click)="deleteSelected()" *ngIf="selected_components[0]?.type!=='custom_chart' &&
      selected_components[0]?.type!=='context' && selected_components[0]?.data.type!=='image'
      && selected_components[0]?.data.type!=='ore_body_group' && selected_components[0]?.data.type!=='series-summary' &&
selected_components[0]?.is_parent !==true"
          class="menu-option">Delete
      </li>
      <!--Delete Custom Chart, Context, Image, Ore Body Group-->
      <li (click)="deleteSelected(selected_components[0])"
          *ngIf="['custom_chart', 'context', 'ore_body_group', 'image'].includes(selected_components[0]?.type) ||
['custom_chart', 'context', 'image', 'series-summary'].includes(selected_components[0]?.data?.type)"
          class="menu-option">Delete
      </li>
      <!--Remove Ore Body Group, this does not delete the OBG-->
      <li (click)="deleteSelected(selected_components[0])"
          *ngIf="['ore_body_group'].includes(selected_components[0]?.data?.type)"
          class="menu-option">Remove
      </li>
      <li *ngIf="selected_components[0]?.data.type==='image' && selected_components[0]?.parent_component
      && selected_components[0]?.parent_component.is_parent !== true"
          class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');"
           routerLink="{{'/view/flowchart/' + selected_components[0].parent_component.data.id }}">
          Zoom In To Process
        </a>
      </li>
      <li *ngIf="selected_components[0]?.data.type==='process' &&	selected_components[0]?.is_parent !== true"
          class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');" routerLink="{{'/view/flowchart/' + selected_components[0].data.id }}">
          Zoom In
        </a>
      </li>
      <li *ngIf="selected_components[0]?.data.type==='process' &&
			selected_components[0]?.is_parent === true && selected_components[0]?.data.relationships.parent.data"
          class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');"
           routerLink="{{'/view/flowchart/' + selected_components[0].data.relationships.parent.data.id }}">
          Zoom Out
        </a>
      </li>
      <li *ngIf="selected_components[0]?.data.type==='ore_body_group'" class="menu-option"
          style="cursor:default;padding:0;">
        <a routerLink="{{'/view/ore-body-group-view/' + selected_components[0].data.data.id}}">
          Zoom In
        </a>
      </li>
      <!--Links to other process pages-->
      <li *ngIf="selected_components[0]?.data.type==='process'"
          class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');"  target="_blank"
           routerLink="{{'/view/edit_series/' + selected_components[0].data.id }}">
          Open Series Sheet
        </a>
      </li>
      <li *ngIf="selected_components[0]?.data.type==='process'"
          class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');" target="_blank"
           routerLink="{{'/view/edit_series_components/' + selected_components[0].data.id }}">
          Open Report Configuration
        </a>
      </li>
      <ng-container *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.pagelinks().length">
        <li *ngFor="let page of selected_components[0].pagelinks()"
            class="menu-option" style="cursor:default;padding:0;">
          <a (click)="toggleMenu('hide');"
             [routerLink]="'/view/page_view/' + page.id">
            Link to {{page.title}}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>

</div>
