import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BreadcrumbsService} from "./breadcrumbs.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    private readonly onDestroy = new Subject<void>();
    breadcrumbs: string[] = [];

    constructor(private cps: BreadcrumbsService) {
        this.cps.breadcrumbsChanged.pipe(takeUntil(this.onDestroy)).subscribe(bc => this.breadcrumbs = bc);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
