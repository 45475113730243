import {BaseViewModel} from "./base-viewmodel";
import {TextViewModel} from "./text-viewmodel";
import {ProcessViewModel} from "./process-viewmodel";

export class ConnectorViewModel extends BaseViewModel {
    process: ProcessViewModel;
    is_parent: boolean;
    text: TextViewModel;
    percent: any;

    constructor(connectorDataModel, process, is_parent) {
        super(connectorDataModel);

        this.process = process;
        this.is_parent = is_parent;

        if (this.data.attributes.json == null || this.data.attributes.json == undefined) {
            this.data.attributes.json = {};
        }
        if (this.is_parent) {
            if (this.data.attributes.json['parent'] == undefined) {
                this.data.attributes.json['parent'] = {"percent": this.data.attributes.percent};
            }
            if (this.data.attributes.json.parent['percent'] == undefined) {
                this.data.attributes.json.parent['percent'] = this.data.attributes.percent;
            }
        }
        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }
        if (!this.data.attributes.json['text'].x) {
            this.data.attributes.json['text'].x = this.x() + 20;
        }
        if (!this.data.attributes.json['text'].y) {
            this.data.attributes.json['text'].y = this.y();
        }

        this.text = new TextViewModel(this.data.attributes.json.text);
    }

    // The process that the connector is attached to.
    parentProcess() {
        return this.process;
    };

    name() {
        return this.data.attributes.name;
    };

    input_stream() {
        if (this.data.relationships.input_stream.data == null) {
            return null
        } else {
            return this.data.relationships.input_stream.data.id
        }
    };

    output_stream() {
        if (this.data.relationships.output_stream.data == null) {
            return null
        } else {
            return this.data.relationships.output_stream.data.id
        }
    };

    input_stream_name() {
        return this.data.attributes.input_stream_name
    };

    output_stream_name() {
        return this.data.attributes.output_stream_name
    };

    hover_text() {
        var hover = "";
        if (this.input_stream_name()) {
            hover = "Input stream: " + this.input_stream_name();
        }
        if (this.output_stream_name()) {

            hover += "Output stream: " + this.output_stream_name();

        }
        return hover;
    };

    allow_stream() {
        if (this.output_stream() == null && this.input_stream() == null) {
            return 'all';
        } else if (this.output_stream() !== null && this.input_stream() == null) {
            return 'input';
        } else if (this.output_stream() == null && this.input_stream() !== null) {
            return 'output';
        } else if (this.output_stream() !== null && this.input_stream() !== null) {
            return 'none';
        }
    };

    connectorClass() {
        return 'connector-' + this.allow_stream();
    };

    x() {
        if (this.is_parent) {
            this.percent = this.data.attributes.json.parent.percent / 100;
        } else {
            this.percent = this.data.attributes.percent / 100;
        }
        if (this.percent <= this.process.width() / this.process.perimeter()) {
            return this.percent * this.process.perimeter();
        } else if (this.percent <= ((this.process.width() + this.process.height()) / this.process.perimeter())) {
            return this.process.width();
        } else if (this.percent <= (this.process.height() + this.process.width() * 2) / this.process.perimeter()) {
            return this.process.width() - this.process.perimeter() * (this.percent - (this.process.width() + this.process.height()) / this.process.perimeter());
        } else {
            return 0
        }
    };

    y() {
        if (this.is_parent) {
            this.percent = this.data.attributes.json.parent.percent / 100;
        } else {
            this.percent = this.data.attributes.percent / 100;
        }
        if (this.percent <= this.process.width() / this.process.perimeter()) {
            return 0
        } else if (this.percent <= (this.process.width() + this.process.height()) / this.process.perimeter()) {
            return this.process.perimeter() * (this.percent - (this.process.width()) / this.process.perimeter())
        }
        if (this.percent <= (this.process.height() + this.process.width() * 2) / this.process.perimeter()) {
            return this.process.height();

        } else {
            return this.process.height() - this.process.perimeter() * (this.percent - (this.process.height() + this.process.width() * 2) / this.process.perimeter())
        }
    };

    deltaP(deltaPX, deltaPY) {
        if (this.is_parent) {
            this.percent = this.data.attributes.json.parent.percent / 100;
            //this.percent = this.data.attributes.percent/100;
        } else {
            this.percent = this.data.attributes.percent / 100;
        }
        var p = this.percent;
        if (p <= (this.process.width() / this.process.perimeter()) && p >= 0) {
            return deltaPX;
        } else if (p > (this.process.width() / this.process.perimeter()) && p <= 0.5) {
            return deltaPY;
        } else if (p > 0.5 && p <= ((this.process.width() * 2 + this.process.height()) / this.process.perimeter())) {
            return deltaPX * -1;
        } else if (p > ((this.process.width() * 2 + this.process.height()) / this.process.perimeter()) && p <= (100 - this.process.height() / this.process.perimeter()) && p <= 1) {
            return deltaPY * -1;
        } else {
            return 0;
        }
    };

    x_abs() {
        return this.x() + this.process.x();
    };

    y_abs() {
        return this.y() + this.process.y();
    };

    hover_text_x(r) {
        r = Number(r);
        if (this.x() < r) {
            return this.x() + 10 + r;
        }
        if (this.x() >= r && this.x() <= this.process.data.attributes.json.windowWidth * 3 / 4) {
            return this.x() + r;
        }
        if (this.x() > this.process.data.attributes.json.windowWidth * 3 / 4) {
            return this.x() - 10 - r;
        }
    };

    hover_text_y(r) {
        r = Number(r);
        if (this.y() >= 0 && this.y() <= r) {
            return this.y() + r;
        }
        if (this.y() > r && this.y() < this.process.data.attributes.json.windowHeight - r) {
            return this.y() - 10;
        }
        if (this.y() >= this.process.data.attributes.json.windowHeight - r) {
            return this.y() - r / 2;
        }
    };

    hover_text_anchor() {

        if (this.x() >= 0 && this.x() <= this.process.data.attributes.json.windowWidth * 3 / 4) {
            return "start";
        }
        if (this.x() > this.process.data.attributes.json.windowWidth * 3 / 4) {
            return "end";
        }

    };
};
