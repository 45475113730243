<div class="custom-form-field" [ngClass]="{'display-inline':display_inline}">
  <h3 *ngIf="title && !display_inline">{{title}}</h3>
  <div>
    <div class="row center full-width">
    <label *ngIf="title && display_inline">{{title}}:</label>
    <ng-container *ngFor="let opt of getConfigurationOptions()">
      <mat-form-field *ngIf="opt.field_type === 'input' || opt.field_type==='div'">
        <mat-label>{{opt.name | removeUnderscore | upperfirst}}</mat-label>
        <input matInput [type]="opt.type"
               [(ngModel)]="config[opt.name]"
               (ngModelChange)="configChanged.emit(config)"
               [disabled]="opt.field_type==='div'">
      </mat-form-field>
      <mat-checkbox *ngIf="opt.field_type === 'checkbox'"
                    [(ngModel)]="config[opt.name]"
                    (ngModelChange)="configChanged.emit(config)"
                      class="form-element-align">
        {{opt.name | removeUnderscore | upperfirst}}</mat-checkbox>
    </ng-container>
  </div>
</div>
