import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, ModelRelationCleanup, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class OreBodyTypeAttributes extends AuditMixinAttributes {
    name: string;
    description: string;

    // NOTE: delete this key before posting
    properties_data_map: { [key: string]: any };
    constant_property_ordering: string[];

    public static cleanup(o: OreBodyTypeAttributes) {
        if (!o) return;
        delete o['properties_data_map'];
        delete o['constant_property_ordering'];
    }
}

export class OreBodyTypeRelationships extends AuditMixinRelationships {
    parent: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    children: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    event_types: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    constant_properties: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    ore_body_type_constants: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;

    public static cleanup(o: OreBodyTypeRelationships) {
        if (!o) return;
        ModelRelationCleanup.Single(o.parent);
        ModelRelationCleanup.List(o.children);
        ModelRelationCleanup.List(o.event_types);
        ModelRelationCleanup.List(o.constant_properties);
        ModelRelationCleanup.List(o.ore_body_type_constants);
    }
}

/**
 * NOTE the rest interface for components is broken though you most likely want a child type of component.
 */
export class OreBodyType extends AuditMixin {
    attributes: OreBodyTypeAttributes;
    id: string;
    type: string = 'ore_body_type';
    relationships: OreBodyTypeRelationships;

    constructor() {
        super();
        this.attributes = new OreBodyTypeAttributes();
        this.relationships = new OreBodyTypeRelationships();
    }

    public static cleanup(obt: OreBodyType) {
        OreBodyTypeAttributes.cleanup(obt.attributes);
        OreBodyTypeRelationships.cleanup(obt.relationships);
    }
}
