<div class="utils-bar" *ngIf="dataSource">
  <div class="table-filter">
    <input (keyup)="applyFilter($event.target.value)" placeholder="Search events"/>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="10"
                 [showFirstLastButtons]="true"></mat-paginator>

</div>
<table [dataSource]="dataSource" [matSortActive]="columns[0]" mat-table matSort
       matSortDirection="desc" class="table-striped table-bordered" style="min-width: 100%;">

  <!-- Datetime column -->
  <ng-container matColumnDef="{{columns[0]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[0]}}</th>
    <td *matCellDef="let element" mat-cell> {{element.attributes.issued_at | date:'yyyy-MM-dd HH:mm:ss'}} </td>
  </ng-container>

  <!-- User column -->
  <ng-container matColumnDef="{{columns[1]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[1]}}</th>
    <td *matCellDef="let element" mat-cell> {{element.changed_by}} </td>
  </ng-container>

  <!-- Name column -->
  <ng-container matColumnDef="{{columns[2]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[2]}}</th>
    <td *matCellDef="let element"
        mat-cell> {{element.attributes.changed_data.name ? element.attributes.changed_data.name : element.attributes.old_data.name ? element.attributes.old_data.name : ''}}</td>
  </ng-container>

  <!-- Action column -->
  <ng-container matColumnDef="{{columns[3]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[3]}}</th>
    <td *matCellDef="let element" mat-cell> {{element.attributes.verb}} </td>
  </ng-container>

  <!-- Model column -->
  <ng-container matColumnDef="{{columns[4]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[4]}}</th>
    <td *matCellDef="let element" mat-cell> {{element.attributes.table_name}} </td>
  </ng-container>

  <!-- Changed Data column -->
  <ng-container matColumnDef="{{columns[5]}}">
    <th *matHeaderCellDef mat-header-cell> {{columns[5]}}</th>
    <td *matCellDef="let element" mat-cell>
      <json-table [json_data]="element.attributes.changed_data"></json-table>
    </td>
  </ng-container>

  <!-- Old Data column -->
  <ng-container matColumnDef="{{columns[6]}}">
    <th *matHeaderCellDef mat-header-cell> {{columns[6]}}</th>
    <td *matCellDef="let element" mat-cell style="padding: 0">
      <json-table [compare_data]="element.attributes.changed_data"
                  [json_data]="element.attributes.old_data"></json-table>
    </td>
  </ng-container>

  <!-- Created by column -->
  <ng-container matColumnDef="{{columns[7]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[7]}}</th>
    <td *matCellDef="let element" mat-cell> {{element.created_by}} </td>
  </ng-container>

  <!-- Changed by column -->
  <ng-container matColumnDef="{{columns[8]}}">
    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[8]}}</th>
    <td *matCellDef="let element" mat-cell> {{element.changed_by}} </td>
  </ng-container>

  <tr *matHeaderRowDef="columns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: columns;" mat-row></tr>
</table>

<div *ngIf="history && history.length === 0">No history was found for this time period. Please ensure that you have
  selected the appropriate time period to search the audit history and that you have selected the appropriate audit
  history models.
</div>
