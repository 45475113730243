import {ElementRef, Injectable} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {map, takeUntil, tap} from "rxjs/operators";
import * as d3 from "d3";
import * as utils from "../lib/utils";
import {Series} from '../_models/series';
import {ApiService} from './api/api.service';
import {ChartAPI, ChartConfiguration} from "c3";
import {GenericChartTileConfiguration, YAxisLimit} from "../charts/chart-config/generic-chart-tile.configuration";
import {AppScope} from './app_scope.service';
import {WaterfallChartConfiguration} from "../charts/chart-config/chart-configuration";
import {SearchQueryOptions} from "./api/search-query-options";
import {NotificationService} from "./notification.service";
import {SeriesDataService} from "./series_data.service";
import {KeyMap, ModelID} from "../_typing/generic-types";
import {
    ChartLabelFormatConfig,
    ChartSeriesConfiguration,
    ChartTargetData
} from "../charts/chart-config/chart-series.configuration";


@Injectable({
    providedIn: 'root'
})
export class ChartService {
    // These must be set by the chart component using them so that they share data/functions
    rendered_chart: ChartAPI; // A reference to the rendered chart created by c3.generate(ctrl.chart_config)
    id_tag: string; // was the chart html anchor, now only used by custom legend

    // the c3 config built to render the chart

    full_chart_series_list: Series[]; // List of series used by the chart with all properties (not series_light)

    x_max_ticks = 5;
    y_max_ticks = 5;
    show_ops_points: boolean;
    ops_min_cuttoff: number = 300;

    private readonly onDestroy = new Subject<void>();

    // input_config: GenericChartConfiguration; //config json with all configurable parameters, set after @Input received in component

    constructor(private api: ApiService,
                private appScope: AppScope,
                private notification: NotificationService,
                private seriesData: SeriesDataService) {
        const ctrl = this;
        // *tileData and eventService are set to the services provided inside the chart component
        ctrl.id_tag = utils.gen_graph_id(32);
    }

    configureWaterfallChart(config: WaterfallChartConfiguration) {
        console.log("This chart is not configured for c3.")
    }

    getChartSeries(series_name_list): Observable<any> {
        const ctrl = this;
        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'name',
            op: 'in',
            val: series_name_list
        }];
        return this.api.series.searchMany(options).pipe(
            takeUntil(this.onDestroy),
            tap(response => ctrl.full_chart_series_list = response.data)
        )
    }

    getTargetSeries(seriesList: Partial<ChartSeriesConfiguration>[]): Observable<ChartTargetData> {
        let seriesTargetDict: KeyMap<string> = {};
        const ids: ModelID[] = seriesList.reduce((t: ModelID[], s: Partial<ChartSeriesConfiguration>) => {
            if (s.type === 'budget' && s.target_series?.id) {
                t.push(s.target_series.id);
            }
            return t;
        }, []);

        if (!ids.length) return of({
            targetNames: [],
            seriesTargetDict: {}
        })

        return this.seriesData.getSeriesLightListById(ids).pipe(
            map(result => {
                result.data?.forEach((t: Series) => {
                        const s = seriesList.find(cs => cs.target_series?.id === t.id);
                        seriesTargetDict[s.name] = s.target_series.actual_below || null;
                    }
                )
                return {
                    targetNames: result.data?.map(t => t.attributes.name),
                    seriesTargetDict: seriesTargetDict
                }
            }))
    }

    setChartDefaultTicks(chart_anchor: ElementRef<HTMLDivElement>, chart_type: string) {
        if (['pie', 'donut', 'gauge'].includes(chart_type)) return;
        const height = chart_anchor.nativeElement.offsetHeight;
        const width = chart_anchor.nativeElement.offsetWidth;
        if (width < 500) {
            this.x_max_ticks = 4;
        }
        if (width < 300) {
            this.x_max_ticks = 3;
        }
        if (width > 1000) {
            this.x_max_ticks = 8;
        }

        if (height && height < 800) {
            this.y_max_ticks = 4;
        }
        if (height && height < 220) {
            this.y_max_ticks = 3;
        }

    }

    getStartingConfig(columns, types, axes, groups, colour_pattern, hide_axes, labels?: ChartLabelFormatConfig) {
        return {
            data: {
                selection: {
                    enabled: true,
                    draggable: true,
                    multiple: true,
                    // change: this.handleClick()
                },
                columns: columns,
                classes: {},
                types: types,
                axes: axes,
                groups: Object.keys(groups).map(key => groups[key]),
                labels:
                    {
                        format: function (a, b, c, d) {
                            if (b && labels?.[b]?.show) {
                                if ((a >= 1000 || a <= -1000) && !labels?.[b]?.uncondensed) {
                                    // @ts-ignore
                                    return Intl.NumberFormat('en-us', {notation: 'compact'}).format(a);
                                }
                                return d3.format(",." + labels[b].format)(a);
                            }
                            return null;
                        }
                    }
            },
            bar: {
                width: {
                    ratio: 0.8
                },
                space: 0.1
            },
            color: {
                pattern: colour_pattern
            },
            zoom: {
                enabled: this.appScope.isNotMobile,
                rescale: true,
            },
            axis: {
                x: {
                    show: !hide_axes,
                    tick: {
                        multiline: true,
                        multilineMax: 2,
                        culling: {max: this.x_max_ticks},
                    },
                    label: {},
                    labelMaxWidth: 10,
                    labelWrap: true
                },
                y: {
                    show: !hide_axes,
                    label: {}

                }
            }
        };
    }

    setDataFormat(series: Series, config: ChartSeriesConfiguration): ChartLabelFormatConfig {
        if (!config.show_data_labels) return {'show': false, 'format': null, 'uncondensed': false};

        let decimals = series.attributes?.decimal_places;
        let uncondensed = config.uncondensed_labels === true;
        if (decimals || decimals === 0) {
            return {'show': true, 'format': decimals + 'f', 'uncondensed': uncondensed};
        }
        /**Else show significant numbers, precision 3**/
        return {'show': true, 'format': '3r', 'uncondensed': uncondensed};
    }

    addNowLine(chart_config) {
        if (!['pie', 'donut', 'gauge'].includes(chart_config.type)) {
            chart_config.grid['x'] = {
                lines: [
                    {
                        value: new Date(),
                        text: 'NOW'
                    }
                ]
            }
        }
    }

    configureAxisLabels(chart_config, labels) {
        chart_config.axis.y.label.text = labels.y_axis;
        chart_config.axis.y.label.position = 'outer-middle';
        if (chart_config.axis.y2) {
            chart_config.axis.y2.label.text = labels.y2_axis;
            chart_config.axis.y2.label.position = 'outer-middle';
        }
        if (labels['x_axis'] !== '') {
            chart_config.axis.x.label.text = labels.x_axis;
            chart_config.axis.x.label.position = 'outer-center';
        }
    }

    setYLimits(chart_config, y_max, y_min, show_y2) {
        const ctrl = this;
        chart_config.axis.y.max = y_max['y'];
        if (y_min['y'] !== 0) {
            chart_config.axis.y.min = y_min['y'];
        }
        if (show_y2) {
            chart_config.axis.y2.max = y_max['y2'];
            if (y_min['y'] !== 0) {
                chart_config.axis.y2.min = y_min['y2'];
            }
        }
    }

    checkCustomYSet(tile_config: GenericChartTileConfiguration) {
        return (tile_config.y_spacing_set && tile_config.y_spacing) || (tile_config.y_max_set && tile_config.y_max)
            || (tile_config.y_min_set && (tile_config.y_min || tile_config.y_min === 0));
    }

    checkCustomY2Set(tile_config: GenericChartTileConfiguration) {
        return (tile_config.y2_spacing_set && tile_config.y2_spacing) || (tile_config.y2_max_ticks_set, tile_config.y2_max_ticks)
            || (tile_config.y2_min_set && (tile_config.y2_min || tile_config.y2_min === 0));
    }

    setYRange(chart_config, tile_config: GenericChartTileConfiguration) {
        if (tile_config.y_min_set && (tile_config.y_min || tile_config.y_min == 0)) {
            chart_config.axis.y.padding = Object.assign(chart_config.axis.y.padding || {}, {bottom: 0});
            chart_config.axis.y.min = Number(tile_config.y_min);
        }
        if (tile_config.y_max_set && (tile_config.y_max || tile_config.y_max == 0)) {
            chart_config.axis.y.padding = Object.assign(chart_config.axis.y.padding || {}, {top: 0});
            chart_config.axis.y.max = Number(tile_config.y_max);
        }
        if (tile_config.y2_min_set && (tile_config.y2_min || tile_config.y2_min == 0) && chart_config.axis.y2) {
            chart_config.axis.y2.padding = Object.assign(chart_config.axis.y2.padding || {}, {bottom: 0});
            chart_config.axis.y2.min = Number(tile_config.y2_min);
        }
        if (tile_config.y2_max_set && (tile_config.y2_max || tile_config.y2_max == 0) && chart_config.axis.y2) {
            chart_config.axis.y2.padding = Object.assign(chart_config.axis.y2.padding || {}, {top: 0});
            chart_config.axis.y2.max = Number(tile_config.y2_max);
        }
    }

    setYTicks(chart_config, tile_config: GenericChartTileConfiguration, limit_by_height = false) {
        this.yTicks(chart_config, tile_config.y_max_ticks_set, tile_config.y_max_ticks, 'y', limit_by_height);
        if (!chart_config.axis.y2) return;
        this.yTicks(chart_config, tile_config.y2_max_ticks_set, tile_config.y2_max_ticks, 'y2', limit_by_height);
    }

    private yTicks(chart_config, ticks_set: boolean, tick_count_value: number, axis = 'y', limit_by_height = false) {
        let tick_count = undefined;
        if (limit_by_height && !['pie', 'donut', 'gauge'].includes(chart_config.type) && !ticks_set) {
            tick_count = this.y_max_ticks;
        }
        if (ticks_set && tick_count_value) {
            tick_count = tick_count_value;
        }
        chart_config.axis[axis].tick = Object.assign(chart_config.axis[axis].tick || {}, {
            count: tick_count,
            format: (v, id, i, j) => utils.significantNumber(v)
        });
    }

    setYSpacing(chart_config, tile_config: GenericChartTileConfiguration, data_y_min: {
        y?: number,
        y2?: number
    }, data_y_max: { y?: number, y2?: number }, show_y2: boolean) {
        let y_min = tile_config.y_min_set ? tile_config.y_min : null;
        let y_max = tile_config.y_max_set ? tile_config.y_max : null;
        this.ySpacing(chart_config, tile_config.y_spacing_set, tile_config.y_spacing, y_min, y_max, 'y', data_y_min, data_y_max);

        if (!show_y2) return;
        let y2_min = tile_config.y2_min_set ? tile_config.y2_min : null;
        let y2_max = tile_config.y2_max_set ? tile_config.y2_max : null;
        this.ySpacing(chart_config, tile_config.y2_spacing_set, tile_config.y2_spacing, y2_min, y2_max, 'y2', data_y_min, data_y_max);
    }

    private ySpacing(chart_config: ChartConfiguration, spacing_set: boolean, spacing_value: number, axis_min: number, axis_max: number, axis = 'y', data_y_min: {
        y?: number,
        y2?: number
    }, data_y_max: { y?: number, y2?: number }) {
        if (!(spacing_set && spacing_value)) return;
        let from: number = Number(axis_min || data_y_min?.[axis] || 0);
        let to: number = Number(axis_max || data_y_max?.[axis]);
        if (!(spacing_value > 0 && (from || from == 0) && to && from < to)) return;

        let y_values = [];
        let check_count = 0;
        do {
            check_count += 1;
            y_values.push(from);
            from = Number(from);
            from += Number(spacing_value);
        } while (from <= to && check_count < 52);
        if (y_values.length > 50) {
            this.notification.openError("Too many values specified. Please adjust spacing between y-values.", 3000);
            return;
        }
        if (y_values.length > 0) {
            const ticks = Object.assign(chart_config.axis[axis].tick, {count: undefined});
            chart_config.axis[axis].tick = Object.assign(chart_config.axis[axis].tick || {}, {
                values: y_values,
                tick: ticks,
                culling: true
            });
        }
    }

    setYAxisDefaults(chart_config: ChartConfiguration, min: YAxisLimit, max: YAxisLimit, axis = 'y', chart_types = {default: 'line'}): void {
        if (max[axis] == min[axis]) {
            min[axis] = (min[axis] || 0) - 1;
            max[axis] = (max[axis] || 0) + 1;
        }

        const nice_ticks = this.nicestTicks(min[axis], max[axis], this.y_max_ticks);
        if (nice_ticks.ticks.length > 0) {
            chart_config.axis[axis].tick = Object.assign(chart_config.axis[axis].tick || {}, {
                values: nice_ticks.ticks,
                culling: true
            });
            const padding = ['bar', 'area'].some(t => Object.values(chart_types).includes(t)) ? 0 : 10;
            chart_config.axis[axis].padding = Object.assign(chart_config.axis[axis].padding || {}, {bottom: padding});
            chart_config.axis[axis].min = Number(nice_ticks.adjustedRange[0]);
            chart_config.axis[axis].padding = Object.assign(chart_config.axis[axis].padding || {}, {top: 0});
            chart_config.axis[axis].max = Number(nice_ticks.adjustedRange[1]);
        }
    }

    nicestTicks(minValue: number, maxValue: number, desiredTicks = 3): {
        ticks: number[],
        adjustedRange: [number, number]
    } {
        const dataRange = Number(maxValue) - Number(minValue);
        const roughStep = Number(dataRange) / Number(desiredTicks);
        const magnitude = Math.pow(10, Math.floor(Math.log10(roughStep)));
        const stepSizes = [magnitude, magnitude * 2, magnitude * 5];
        const bestStep = stepSizes.reduce((prev, curr) => {
            return Math.abs(dataRange / curr - desiredTicks) < Math.abs(dataRange / prev - desiredTicks) ? curr : prev;
        });
        const minTick = Math.floor(minValue / bestStep) * bestStep;
        const maxTick = Math.ceil(maxValue / bestStep) * bestStep;
        const adjustedRange: [number, number] = [minTick, maxTick];
        let ticks = Array.from({length: Math.ceil((maxTick - minTick) / bestStep) + 1}, (_, i) => minTick + i * bestStep);
        if (ticks.length / 2 >= desiredTicks) {
            ticks = ticks.filter((_, index) => index % 2 === 0);
        }

        return {ticks, adjustedRange};
    }

    setPaddingForVerticalLabels(chart_config: ChartConfiguration, tile_config: GenericChartTileConfiguration, show_y2 = false) {
        let y_padding, y2_padding;
        const current_padding = chart_config.axis.y.padding?.top || 0;
        const current_y2_padding = chart_config.axis.y2?.padding?.top || 0;

        tile_config.series_list.forEach(sc => {
            if ((sc.show_data_labels && sc.vertical_data_labels) && !tile_config.y_max_set) {
                y_padding = 20;
            }
            if ((sc.show_data_labels && sc.vertical_data_labels) && !tile_config.y2_max_set) {
                y2_padding = 20;
            }
        })

        chart_config.axis.y.padding = Object.assign(chart_config.axis.y.padding || {}, {top: Number(current_padding) + y_padding});
        if (show_y2) {
            chart_config.axis.y2.padding = Object.assign(chart_config.axis.y2.padding || {}, {top: Number(current_y2_padding) + y2_padding});
        }
    }

    setYFormats(chart_config, tile_config: GenericChartTileConfiguration, med: YAxisLimit, show_y2: boolean) {
        const axis: string[] = ['y'];
        if (show_y2) {
            axis.push('y2');
        }
        axis.forEach(a => {
            chart_config.axis[a].tick = Object.assign(chart_config.axis[a].tick || {}, {
                /**(notation:compact is defined, see
                 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
                 * but not defined in type)**/
                format: (v, id, i, j) => {
                    if (tile_config[a + '_condensed_numbers']) {
                        // @ts-ignore
                        return Intl.NumberFormat('en-us', {notation: 'compact'}).format(v);
                    } else if (med[a] >= 100000 || med[a] <= -100000) {
                        // @ts-ignore
                        return Intl.NumberFormat('en-us', {notation: 'compact'}).format(v);
                    } else {
                        if (((v > 0 && v < 10) || (v > -10 && v < 0)) && (!tile_config[a + '_decimals'] && tile_config[a + '_decimals'] !== 0)) {
                            return d3.format(",." + "1f")(v);
                        }
                        return d3.format(",." + (tile_config[a + '_decimals'] || 0) + "f")(v);
                    }
                }
            });

        })

    }

    setColumnClasses(chart_config, columns, tag_class_map, tag_type_map) {
        columns.forEach(col => {
            let data_class = tag_class_map[col[0]];
            if (data_class && col[0] !== 'x' && (tag_type_map[col[0]] || tag_type_map[col[0]] === 'line')
                && !Object.keys(chart_config.data.classes).includes(col[0])) {
                chart_config.data.classes[col[0]] = data_class;
            }
        });
    }

    progressiveDisable(columns, chart_config) {
        const ctrl = this;
        // TODO progressively disable features of charts as the amount of data points increase
        if (columns[0] && columns[0].length > ctrl.ops_min_cuttoff) {
            this.show_ops_points = false;
            chart_config.point = {
                show: function () {
                    return ctrl.show_ops_points
                }
            };

            chart_config.axis.x.tick.fit = true;
            chart_config.axis.x.tick.count = this.x_max_ticks + 5;
        }

        if (columns[0] && columns[0].length > 4000) {
            alert("Your query is too large, please increase the sample period and refresh.");
            return;
        }
    }

    showOpsPoints(checked) {
        this.show_ops_points = checked;
        this.rendered_chart.flush();
    }

    fillColourPattern(colour_pattern) {
        let default_pattern = ['teal', 'orangered', 'dodgerblue', 'darkslateblue', 'darkorange', 'darkturquoise', 'green', 'olive', 'gray', 'black', 'maroon'];
        if (this.appScope.config_name_map.hasOwnProperty("palette2")) {
            default_pattern = (this.appScope.config_name_map.palette2.value.map(colour => colour.colour)).concat(default_pattern);
        } else if (this.appScope.config_name_map.hasOwnProperty("pallette2")) {
            default_pattern = (this.appScope.config_name_map.pallette2.value.map(colour => colour.name)).concat(default_pattern);
        }

        for (let i = 0; i < colour_pattern.length; ++i) {
            if (colour_pattern[i] === "#111") {
                for (let c = 0; c < default_pattern.length; ++c) {
                    if (colour_pattern.indexOf(default_pattern[c]) < 0) {
                        colour_pattern[i] = default_pattern[c];
                        break;
                    }
                }
            }
        }
    }

    //********Post chart render functions/////////////////////////////////////
    formatX(multiline: boolean = true, rotate: number = 0) {
        let ctrl = this;
        let ticks = d3.selectAll(".c3-axis-x .tick text").call(t => {
            t.each(function (d, i, nodes) {
                let self = d3.select(this);
                if (i === nodes.length - 1 && !rotate && !multiline) {
                    self.classed('last-x-tick-label', true);
                }
                if (!multiline) {
                } else {
                    let s = self.text().split(' ');
                    if (s.length === 1) return;

                    self.text(null);
                    let dy1 = rotate ? rotate < 0 ? "0em" : rotate > 45 ? "0" : ".8em" : ".8em";
                    let dy2 = rotate ? rotate < 0 ? "1em" : rotate > 45 ? "1.2em" : "1em" : "1em";
                    let x = rotate ? rotate < 0 ? "-0.8em" : rotate > 45 ? "0.4em" : 1 : 0;
                    let x1 = rotate ? rotate < 0 ? "-0.8em" : rotate > 45 ? "0.4em" : 1 : 0;

                    self.append("tspan")
                        .attr("x", x)
                        .attr("dy", dy1)
                        .text(s[0]);
                    self.append("tspan")
                        .attr("x", x)
                        .attr("dy", dy2)
                        .text(s[1]);

                }
            })
        });

    }
}
