import {Injectable} from '@angular/core';
import {ApiService} from "./api/api.service";
import {forkJoin, ReplaySubject, Subscription} from "rxjs";
import {AppScope} from './app_scope.service';
import {UserData} from "./user_data.service";
import {SessionState} from "../_models/session-state";
import {map} from "rxjs/operators";
import {ModelID} from "../_typing/generic-types";

export enum MenuNodeType {
    FOLDER,
    MENU,
    STATIC_FOLDER,
    STATIC
}

export interface TreeEntry {
    readonly type: MenuNodeType;
    readonly order: number;
}

/**
 * Folders that are configured per customer
 */
interface IFolderEntry extends TreeEntry {
    readonly type: MenuNodeType;
    readonly folder: Folder;
    readonly order: number;
    readonly id: string;
    readonly name: string;
    parent: FolderEntry;
    contents: TreeEntry[];
    menu_entry_count: number;
}

export function isFolderEntry(obj: any): obj is FolderEntry {
    return obj && typeof obj === 'object' && 'id' in obj && 'type' in obj && obj.type === 0;
}

export type OptionalFolderEntry = Partial<IFolderEntry>;

export class FolderEntry implements OptionalFolderEntry {
    readonly type: MenuNodeType = MenuNodeType.FOLDER;

    readonly folder: Folder;
    readonly order: number;

    readonly id: string;
    readonly name: string;

    parent: FolderEntry;
    contents: TreeEntry[] = [];
    menu_entry_count: number = 0;

    constructor(folder: Folder) {
        this.folder = folder;
        this.order = folder.attributes.order;
        this.id = folder.id;
        this.name = folder.attributes.name;
    }

    getParentId(): string | null {
        if (this.folder.relationships.parent.data) {
            return this.folder.relationships.parent.data.id;
        }
        return null;
    }

    toString(): string {
        return self.name;
    }
}

/**
 * Folders that only exist in the client and exist for all customers
 */
export class StaticFolderEntry implements TreeEntry {
    readonly type: MenuNodeType = MenuNodeType.STATIC_FOLDER;

    title: string;
    contents: (TreeEntry | StaticFolderEntry)[] = [];

    menu_entry_count: number = 0;
    order;

    constructor(title: string, contents: (TreeEntry | StaticFolderEntry)[] = []) {
        this.title = title;
        if (contents.length > 0) {
            this.contents = contents;
            this.menu_entry_count = contents.length;
        }
    }
}

export class MenuEntry implements TreeEntry {
    session_state: any;
    type: MenuNodeType = MenuNodeType.MENU;
    order;
    visible: boolean = true;
    private: boolean = false;
    folder_session_state_id: ModelID;
}

export class StaticEntry implements TreeEntry {
    title: string;
    link: any;
    type: MenuNodeType = MenuNodeType.STATIC;
    order;

    constructor(title: string, link: any) {
        this.title = title;
        this.link = link;
    }
}

export type DraggableTreeNodeEntry = FolderEntry | MenuEntry;

@Injectable({
    providedIn: 'root'
})
export class MenuTreeService {
    // empty trees pruned from tree
    readonly menuTreeChanged: ReplaySubject<{ [account_id: string]: TreeEntry[] }> = new ReplaySubject<any>(1);
    // full folders list, including empty folders
    readonly menuTreeChangedFull: ReplaySubject<{ [account_id: string]: TreeEntry[] }> = new ReplaySubject<any>(1);
    readonly sessionStatesChanged: ReplaySubject<{ [account_id: string]: SessionState[] }> = new ReplaySubject<any>(1);
    private refresh_subscription: Subscription;

    constructor(private api: ApiService,
                private appScope: AppScope,
                private userData: UserData) {
        // this.refresh();
    }

    // TODO add method to add session_states without needing to refresh
    public refresh() {
        this.appScope.authComplete$.pipe(map(() => {
            if (this.refresh_subscription) {
                this.refresh_subscription.unsubscribe();
                this.refresh_subscription = null;
            }

            const $session_states = this.api.session_state_light.searchMany();
            const $folders = this.api.folder.searchMany();
            const $folderSessionState = this.api.folder_session_state.searchMany();

            this.refresh_subscription = forkJoin([$session_states, $folders, $folderSessionState]).subscribe(response => {
                let session_states = response[0].data as SessionState[];
                const folders = response[1].data;
                const folderSessionStates = response[2].data;
                const current_user = this.appScope.current_user;
                const folderNodes = this.extractFoldersFromResponse(folders);
                const generated = this.generateTreeMap(folderNodes);
                const treeMap = generated.all;
                const roots = generated.roots;

                const session_state_map: { [id: string]: any } = {};

                if (current_user.restricted_access) {
                    session_states = this.userData.getRestrictedDashboardsQuick(session_states);
                }

                session_states.forEach(item => {
                    session_state_map[item.id] = item;
                });
                Object.values(treeMap).forEach(node => {
                    node.contents = node.contents.concat(node.folder.relationships.session_states.data
                        .filter(session_state => !!session_state_map[session_state.id])
                        .map(session_state => {
                            const menuEntry = new MenuEntry();
                            const folderSessionState = folderSessionStates.find(fss => fss.relationships.session_state.data.id === session_state.id &&
                                fss.relationships.folder.data.id === node.folder.id);
                            menuEntry.session_state = session_state_map[session_state.id];
                            menuEntry.order = folderSessionState?.attributes?.order;
                            menuEntry.private = session_state_map[session_state.id].attributes.visibility === 'private';
                            menuEntry.visible = (session_state_map[session_state.id].attributes.visibility !== 'private' ||
                                session_state_map[session_state.id].relationships.user.data.id === current_user.id);
                            menuEntry.folder_session_state_id = folderSessionState?.id;
                            return menuEntry;
                        }));
                });

                this.populateMenuEntryCount(treeMap);

                // Add root folders for each account
                const account_sessions = {};
                const account_folders = {};
                const account_folders_all = {};

                Object.values(session_states).forEach(session => {
                    const account_id = session.relationships.account.data.id;
                    if (!account_sessions[account_id]) {
                        account_sessions[account_id] = [];
                    }
                    account_sessions[account_id].push(session);
                });
                this.sessionStatesChanged.next(account_sessions);

                Object.values(roots).forEach(node => {
                    if (node.menu_entry_count <= 0) {
                        return;
                    }

                    const linked_account = node.folder.relationships.account;
                    if (linked_account.data) {
                        const account_id = linked_account.data.id;
                        let account_menus = account_folders[account_id];
                        if (!account_menus) {
                            account_menus = [];
                            account_folders[account_id] = account_menus;
                        }
                        account_menus.push(node);
                    }
                });

                Object.values(roots).forEach(node => {
                    const linked_account = node.folder.relationships.account;
                    if (linked_account.data) {
                        const account_id = linked_account.data.id;
                        let account_menus = account_folders_all[account_id];
                        if (!account_menus) {
                            account_menus = [];
                            account_folders_all[account_id] = account_menus;
                        }
                        account_menus.push(node);
                    }
                });

                const folder_sort = (a: FolderEntry, b: FolderEntry) => {
                    const order_a = a.order;
                    const order_b = b.order;
                    if (order_a == null) {
                        return order_b == null ? 0 : 1;
                    } else {
                        if (order_b == null) {
                            return -1;
                        } else {
                            return order_a > order_b ? 1 : order_a < order_b ? -1 : 0;
                        }
                    }
                };

                // sorts the root folders
                Object.keys(account_folders).forEach(account_id => {
                    account_folders[account_id] = account_folders[account_id].sort(folder_sort);
                });
                Object.keys(account_folders_all).forEach(account_id => {
                    account_folders_all[account_id] = account_folders_all[account_id].sort(folder_sort);
                });

                // sorts all other folder and their enclosed items
                Object.values(treeMap).forEach(node => {
                    node.contents = node.contents.sort(folder_sort);
                });

                this.appScope.folder_dict = treeMap;

                this.menuTreeChanged.next(account_folders);
                this.menuTreeChangedFull.next(account_folders_all);
            });
        })).subscribe();
    }

    /**
     * Create a forest of folders.
     *
     * @param list
     * @returns map of
     */
    private generateTreeMap(list: FolderEntry[]): {
        roots: { [folder_id: string]: FolderEntry },
        all: { [folder_id: string]: FolderEntry }
    } {
        const treeMap: { [folder_id: string]: FolderEntry } = {};
        list.forEach(folder => treeMap[folder.id] = folder);

        // Construct the forest of graphs by linking children to parents
        Object.values(treeMap).forEach(node => {
            const parent_id = node.getParentId();
            if (!parent_id) {
                // Root node
                return;
            }
            // Folders only have a single parent folder
            const parent_node = treeMap[parent_id];
            if (!parent_node) {
                console.error('Folder referenced parent which did not exist.');
                return;
            }
            parent_node.contents.push(node);
            node.parent = parent_node;
        });

        // Find and remove folders causing cycles
        const explored_nodes = new Set<string>();
        let to_explore: FolderEntry[] = Object.values(treeMap).filter(node => !node.parent);
        const root_ids = to_explore.map(node => node.folder.id);
        while (to_explore.length > 0) {
            const node = to_explore.shift();
            explored_nodes.add(node.folder.id);
            const tmp: FolderEntry[] = <FolderEntry[]>node.contents.filter((item: FolderEntry) => !explored_nodes.has(item.folder.id));
            node.contents = tmp;
            to_explore = to_explore.concat(tmp);
        }

        // Remove folders that are in cycles
        const not_explored: FolderEntry[] = [];
        Object.keys(treeMap).forEach(id => {
            if (!explored_nodes.has(id)) {
                const node = treeMap[id];
                not_explored.push(node);
                delete treeMap[id];
            }
        });

        if (not_explored.length > 0) {
            console.warn('Some folders have been disabled due to cycles:', not_explored);
            const circularReferences: FolderEntry[] = [];

            not_explored.forEach(not_explored_node => {
                let node_explored: FolderEntry | null = not_explored_node;
                while (node_explored && node_explored.folder.relationships.parent) {
                    if (node_explored.folder.relationships.parent.data.id === not_explored_node.id) {
                        circularReferences.push(not_explored_node);
                        break;
                    }
                    const parentNode = not_explored.find(
                        node => node.id === node_explored.folder.relationships.parent.data.id
                    );
                    if (parentNode) {
                        node_explored = parentNode;
                    } else {
                        node_explored = null;
                    }
                }
            });

            // Construct the unexplored message
            const unexplored_message = circularReferences.map(node =>
                `child: ${node.folder.attributes.name}`
            ).join(', ');

            // Log circular references if any
            if (unexplored_message) {
                console.warn(`Circular reference folders: ${unexplored_message}`);
            } else {
                console.warn("No circular references detected.");
            }
        }

        const root_map: { [folder_id: string]: FolderEntry } = {};
        root_ids.forEach(id => {
            root_map[id] = treeMap[id];
        });

        return {roots: root_map, all: treeMap};

    }

    private populateMenuEntryCount(treeMap: { [folder_id: string]: FolderEntry }) {
        const countNode = (node: FolderEntry) => {
            node.menu_entry_count = node.contents.filter(item => item.type === MenuNodeType.MENU).length;

            const folders: FolderEntry[] = <FolderEntry[]>node.contents.filter(item => item.type === MenuNodeType.FOLDER);
            if (folders.length > 0) {
                node.menu_entry_count += folders.map(item => {
                    return countNode(item);
                }).reduce((a, b) => a + b);
            }
            return node.menu_entry_count;
        };

        Object.values(treeMap).filter(node => !node.parent).forEach(node => {
            countNode(node);
        });
    }

    public extractMenuEntries(node: TreeEntry): MenuEntry[] {
        let entries = [];
        switch (node.type) {
            case MenuNodeType.FOLDER:
            case MenuNodeType.STATIC_FOLDER:
                const folder = <FolderEntry>node;
                folder.contents.forEach(entry => {
                    entries = entries.concat(this.extractMenuEntries(entry));
                });
                break;
            case MenuNodeType.MENU:
                entries = [<MenuEntry>node];
                break;
            case MenuNodeType.STATIC:
                break;
        }
        return entries;
    }

    private extractFoldersFromResponse(folders: Folder[]): FolderEntry[] {
        return folders.map(folder => new FolderEntry(folder));
    }

    public getProcessMenu(process_id: string, action: any, items?: string[]) {
        return [
            {
                link: '/view/series_table/' + process_id,
                action: action,
                name: "Series Table"
            },
            {
                link: '/view/log_sheet/' + process_id,
                action: action,
                name: "Log Sheet  "
            },
            {
                link: '/view/flowchart/' + process_id,
                action: action,
                name: "Flowchart  "
            },
            {
                link: '/view/input_data_sheet/' + process_id,
                action: action,
                name: "Review Data  "
            },
            {
                link: '/view/edit_series_components/' + process_id,
                action: action,
                name: "Report Configuration  "
            },
            {
                link: '/view/edit_series/' + process_id,
                action: action,
                name: "Series  "
            },
            {
                link: '/view/estimate_sheet/' + process_id,
                action: action,
                name: "Estimates  "
            },
            {
                link: '/view/edit_sub_process/' + process_id,
                action: action,
                name: "Sub Processes  "
            },
            {
                link: '/view/edit_stream/' + process_id,
                action: action,
                name: "Streams  "
            },
        ]
            .filter(item => {
                return items?.map(i => i.toLowerCase()).includes(item.name.trim().toLowerCase()) || !items;
            });
    }
}

export class Folder {
    id: string;
    attributes: {
        name: string;
        description?: string;
        order?: number;
    };
    relationships: {
        account: {
            data: { id: string }
        },
        children?: {
            data: { id: string }[]
        },
        parent?: {
            data: { id: string }
        },
        session_states?: {
            data: { id: string }[]
        }
    };
}
