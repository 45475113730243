<div class="page-tile-menu-trigger hideonprint">

  <i class="fa fa-bars hideonprint" [matMenuTriggerFor]="pageTileMenu"
     [ngClass]="{ 'disabled': buttons?.length < 1 && !tileId }"></i>

</div>

<mat-menu #pageTileMenu="matMenu" class="page-tile-menu tree-node-menu" [ngClass]="{'editing-mode':editMode}">
  <ng-container *ngFor="let button of buttons">
    <ng-container *ngIf="editMode || !button.class.includes('show-on-edit')">
      <ng-container *ngIf="!['pivot-tile','model-pivot-tile', 'pivot-tile-ng'].includes(tileContent)">
        <a *ngIf="button.link===true" [routerLink]="button.func" mat-menu-item class="menu-item">
          <i [class]="button.class" [matTooltip]=button.HoverOverHint></i>
          {{button.name}}
        </a>
        <button (click)="button.func($event)" *ngIf="!button.link" [disabled]="button.disabled" mat-menu-item
                class="menu-item">
          <i [class]="button.class" [matTooltip]=button.HoverOverHint></i>
          {{button.name}}
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
  <button (click)="copyTileClicked()" *ngIf="tileId && editMode" mat-menu-item class="menu-item">
    <i [class]="'fa fa-copy'" [matTooltip]="'Make a copy of this tile'"></i>
    Copy Tile
  </button>
  <button (click)="refreshTileClicked()" *ngIf="tileId" mat-menu-item class="menu-item">
    <i [class]="'fa fa-refresh'" [matTooltip]="'Refresh all the data for this tile'"></i>
    Refresh Tile
  </button>
</mat-menu>
