import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {of, Subject, switchMap} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {MatSelectChange, MatSelect, MatSelectModule} from "@angular/material/select";
import {compareWith, deepCopy} from "../../../lib/utils";
import {Observable} from 'rxjs';
import {SelectSearchApiService} from '../select-search-api.service';
import {TOOLTIP_SHOW_DELAY} from "../../../shared/globals";
import {ConfigStub} from '../../../_typing/config-stub';
import {OptionListSearchComponent} from "../../option-list-search/option-list-search.component";
import {Account} from "../../../_models/account";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {NameAndDescriptionPipe, NameOrDescriptionPipe} from "../../../shared/pipes";
import {FormsModule} from "@angular/forms";
import {MatSelectInfiniteScrollDirective} from "../mat-infinite/mat-select-infinite-scroll.directive";
import {DblClickCopyDirective} from "../../../directives/dbl-click-copy.directive";
import {ProcessMenuDirective} from "../../../directives/process-menu.directive";
import {MatTooltipModule} from "@angular/material/tooltip";
import {StringFunction, ValueFunction} from "../types";
import {ModelID} from "../../../_typing/generic-types";

@Component({
    selector: 'select-search-api',
    templateUrl: 'select-search-api.component.html',
    styleUrls: ['../select-search-api.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SelectSearchApiService],
    standalone: false
})
export class SelectSearchApiComponent implements OnInit, AfterViewInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();
    private _init_value: any;

    @ViewChild('matSelect') matSelect: MatSelect;
    @ViewChild(OptionListSearchComponent) searchComponent!: OptionListSearchComponent;

    @Input() placeholder: string = 'Search';
    @Input() openOnInit = false;
    @Input() fetchData: (index: Number, filterValue: String) => Observable<any[]>;
    @Input() label: string = '';
    @Input() classes: string;
    @Input() disabled = false;
    @Input() allowAccountSelection: boolean = true;
    @Input() allowedAccountIds: ModelID[];

    @Input() stringFunction: StringFunction;
    @Input() valueFunction: ValueFunction;
    @Input() compareFunctionName;
    @Input() value;
    @Input() allow_none = false;
    @Input() noItemsAvailableMessage: string = "";

    @Input() set init_value(value: any) {
        if (value?.id) {
            this._init_value = new ConfigStub(value);
            this.sapi.options_available.next([this._init_value]);
        }
    }

    get init_value(): any {
        return this._init_value;
    }

    /**
     * For running a function when the selection was changed.
     */
    @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
    @Output() closeWithoutChange: EventEmitter<any> = new EventEmitter();
    // For components using onPush change detection when loadedItems list changes
    @Output() showUpdate: EventEmitter<null> = new EventEmitter<null>();
    @Output() accountsChange = new EventEmitter<Account[]>();

    selected_value_id: String = '';
    options$: Observable<{ text: string, value: any }[]>;
    loadedItems: { text: string, value: any }[] = [];
    initialised = false;
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;
    searchText = "";

    constructor(public sapi: SelectSearchApiService,
                private changeDetection: ChangeDetectorRef) {
        this.options$ = this.sapi.connect();
        this.options$.pipe(
            switchMap(list => of(list)),
            tap(list => {
                this.loadedItems = list;
                // For components using onPush change detection
                this.showUpdate.emit();
            })
        ).subscribe(() => {
            this.changeDetection.markForCheck();
        });
    }

    ngOnInit(){
        if(!this.label){
            this.classes += ' no-label';
        }
    }

    /**Called on onOpenChanged()**/
    initSelectSearch() {
        this.setupItemFunctions();
        this.filterOptions("");
    }

    filterOptions(filterString: string) {
        this.searchText = filterString;
        this.sapi.resetList(this.init_value? [this.init_value] :[]);
        this.getNextBatch();
    }

    ngAfterViewInit() {
        if (this.matSelect && this.openOnInit === true) {
            this.matSelect.open();
        }
    }

    emitSelect(newSelection: MatSelectChange) {
        this.selectionChange.emit(newSelection);
    }

    getNextBatch() {
        this.sapi.getNextBatch(this.fetchData, this.searchText);
    }

    openedChange(e: boolean) {
        if (!this.initialised) {
            this.initialised = true;
            this.initSelectSearch();
        }
        if (e === true) {
            this.searchText = "";
        } else {
            this.closeWithoutChange.emit();
            const value = this.value ? this.loadedItems.find(i => this.compareWith(i.value, this.value)) : null;
            if (value?.value || this.init_value) {
                this.sapi.resetList([value?.value || this.init_value]);
            }
        }
    }

    clearSearch(event) {
        this.filterOptions("");
    }

    onAccountsChanged($event) {
        this.accountsChange.emit($event);
        this.filterOptions(this.searchText);
    }

    setupItemFunctions() {
        /**If value is function is passed in**/
        if (this.stringFunction) {
            this.sapi.stringFunction = this.stringFunction;
        }
        if (this.valueFunction) {
            this.sapi.valueFunction = this.valueFunction;
        }
    }

    compareWith: (option, value) => boolean = (option, value) => {
        return compareWith(this.compareFunctionName, option, value); // utils.compareWith
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
