import {Component, Input, ViewChild} from '@angular/core';
import {Group} from '../../_models/group';
import {SessionState} from '../../_models/session-state';
import {RelationshipPageSelectorService} from "./relationship-page-selector.service";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {takeUntil, tap} from "rxjs/operators";
import {ModelID} from '../../_typing/generic-types';
import {ModelRelationshipNameDict} from '../../services/api/filter_utils';
import {SaveableBase, SaveService} from '../../services/save/save.service';
import {User} from "../../_models/users";

@Component({
    selector: 'relationship-page-selector',
    templateUrl: './relationship-page-selector.component.html',
    styleUrls: ['./relationship-page-selector.component.less'],
    providers: [RelationshipPageSelectorService],
    standalone: false
})
export class RelationshipPageSelectorComponent extends SaveableBase {
    @Input() model: Group | User;
    @Input() by_group_user = false;
    @Input() by_owner = false;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<SessionState>;
    columns = this.cps.columns;
    filter_string = '';
    applyFilter = this.cps.applyFilter;
    selected_pages: { id: ModelID }[];
    custom_selected_filters = [];
    reverse_custom_selected_filters = [];
    title = '';

    constructor(private cps: RelationshipPageSelectorService,
                private saveService: SaveService) {
        super();
        this.cps.dataSourceSubject.pipe(
            tap(ds => {
                this.dataSource = ds;
                this.columns = this.cps.columns;
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.cps.selectedChangedSubject.pipe(
            tap(selected => {
                this.selected_pages = selected || [];
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.cps.saveSubject.pipe(
            tap((errors) => {
                this.publishStatus({
                    key: 'relationship-page-selector',
                    value: errors.length > 0 ? 'error' : 'success',
                    errors: errors
                });
                this.setCanSave(this.cps.hasChanges(this.selected_pages));
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
        this.saveService.setCanSave(() => this.cps.hasChanges(this.selected_pages));
    }

    ngOnInit(): void {
        if (this.model.type === 'group') {
            this.title = 'Pages in this group';
        } else {
            this.title = 'Pages shared with this user';
            if (this.by_group_user) {
                this.title = 'Pages shared with groups to which this user belongs';
            }
            if (this.by_owner) {
                this.title = 'Pages owned by this user';
            }
        }

        if (!this.by_group_user) {
            this.custom_selected_filters = this.cps.generatePagesByRelationshipFilter(this.model.id, ModelRelationshipNameDict.session_state[this.model.type]);
            this.reverse_custom_selected_filters = [{not: this.custom_selected_filters[0]}];
        }
        if (!(this.by_group_user || this.by_owner)) {
            this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe((refresh_after_save) => {
                this.publishStatus({key: 'relationship-page-selector', value: 'saving', errors: []});
                this.cps.save(this.selected_pages, this.model, refresh_after_save);
            });
        }

        setTimeout(() => {
            this.cps.sort = this.sort;
            this.cps.paginator = this.paginator;
            this.cps.initialise(this.model, this.by_group_user, this.by_owner);
        });
    }

    pagesSelected(event: SessionState[]) {
        this.selected_pages = event;
        this.cps.pageListChanged(event);
        this.setCanSave(this.cps.hasChanges(this.selected_pages));
    }

    updateSort(event: Sort): void {
        this.cps.updateSort(this.sort);
    }
}
