<div class="form-dialog report-issue">
  <h1><b>Please complete the form to report an issue.</b>
    (Please be patient while the form loads)</h1>
  <div style="background:transparent;">
    <iframe class="cu-dashboard-form__container-form-embed"
            src="https://forms.clickup.com/7272257/f/6xxu1-12484/COWYCG5PPVSSLGATZR"
            sandbox="allow-same-origin allow-forms allow-scripts"></iframe>

  </div>
  <div class="error-message-container">
    <h4>Alternatively, please email your issue via email to :
      <a href="mailto:{{serviceDeskEmail}}" target="_blank">
        {{serviceDeskEmail}}
      </a>
    </h4>
  </div>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
  </div>
</div>
