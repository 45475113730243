<div [ngClass]="{'disabled' : disabled}" tabindex="-1">
  <div class="row dtp-container">
    <label class="row time-traveler-label">
      <div *ngIf="icons">
        <timezone-selector></timezone-selector>
      </div>
      <div *ngIf="icons" class="text">Time traveler</div>
      <i class="chevron"></i>
    </label>
    <ng-container *ngIf="dtp">

      <div class="mobile-container row">
        <div class="time-traveler-in-mobile-container">
          <timezone-selector></timezone-selector>
        </div>

        <div *ngIf="dateTimePeriodService.show_calendar && calendars && calendars.length > 0" class="calendar">
          <label class="text">Calendar</label>
          <mat-select (selectionChange)="changeCalendar()" [(value)]="dtp.calendar" [disabled]="disabled"
                      class="calendar-select" matTooltip="Select a calendar / period type."
                      panelClass="list-below-select-panel full-height-select-panel" placeholder="Select Calendar"
                      disableOptionCentering>
            <ng-container *ngFor="let calendar of calendars">
              <mat-option
                  *ngIf="calendar.account_id === appScope.active_account_id || calendar.name === dateTimePeriodService.defaultCalendar"
                  [value]="calendar.name">
                <span>{{calendar.name | removeUnderscore | upperfirst}}</span>
                <!--<span *ngIf="calendar.account_name">{{calendar.account_name | upperfirst}}</span>-->
              </mat-option>
            </ng-container>
          </mat-select>
        </div>
        <div *ngIf="dateTimePeriodService.show_timespan && showTimespan !== false" class="range">
          <img *ngIf="icons" class="range-icon" src='/static/icons/timespan.png'/>
          <label class="text">Time Span</label>

          <!-- Trigger button for main menu -->
          <button mat-button [matMenuTriggerFor]="mainRangeMenu" [disabled]="disabled"
                  class="date-select-button range-select"
                  matTooltip="Select a predefined date range.">
            <span>{{dtp.range ? (dtp.range | upperfirst | removeUnderscore) : 'Range'}}</span>
            <span class="button-icon"><mat-icon>arrow_drop_down</mat-icon></span>
          </button>

          <!-- Main Menu -->
          <mat-menu #mainRangeMenu="matMenu" class="nested-range-menu-panel">
            <!-- Loop through main menu options -->
            <ng-container *ngFor="let range of ranges">
              <!-- Regular options -->
              <ng-container *ngIf="!(range.is_parent || rangeMenuMap[range.range]?.length) && !range.parent">
                <button mat-menu-item (click)="changeRange(range.range)">
                  {{range.range | upperfirst | removeUnderscore}}
                </button>
              </ng-container>

              <ng-container *ngIf="range.is_parent && rangeMenuMap[range.range]?.length">
                <button mat-menu-item [matMenuTriggerFor]="getMenu(range.range)">
                  {{range.range | upperfirst | removeUnderscore}}
                </button>
              </ng-container>

            </ng-container>
          </mat-menu>

          <!-- Submenus -->
          <mat-menu #yesterdayByHalfShift="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subRangeMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'yesterday by half shift'}"></ng-container>
          </mat-menu>
          <mat-menu #thisShift="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subRangeMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'this shift'}"></ng-container>
          </mat-menu>
          <mat-menu #week="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subRangeMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'week'}"></ng-container>
          </mat-menu>
          <mat-menu #month="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subRangeMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'month'}"></ng-container>
          </mat-menu>
          <mat-menu #quarter="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subRangeMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'quarter'}"></ng-container>
          </mat-menu>
          <mat-menu #year="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subRangeMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'year'}"></ng-container>
          </mat-menu>

          <ng-template #subRangeMenus let-menuGroup>
            <ng-container *ngFor="let option of rangeMenuMap[menuGroup]">
              <button mat-menu-item (click)="changeRange(option.range)">
                {{option.range  | upperfirst | removeUnderscore}}
              </button>
            </ng-container>
          </ng-template>


        </div>


        <div *ngIf="dateTimePeriodService.show_timespan && showTimespan !== false && sample_periods" class="period">
          <label class="text">By</label>
          <!-- Trigger button for main menu -->
          <button mat-button [matMenuTriggerFor]="mainMenu" class="date-select-button period-select"
                  matTooltip="Select the sample period for your date range.">
            <span>{{dtp.sample_period ? (dtp.sample_period.name | upperfirst | removeUnderscore) : 'Sample Period'}}</span>
            <span class="button-icon"><mat-icon>arrow_drop_down</mat-icon></span>
          </button>

          <!-- Main Menu -->
          <mat-menu #mainMenu="matMenu" class="nested-range-menu-panel">
            <!-- Loop through main menu options -->
            <ng-container *ngFor="let sample_period of sample_periods">
              <!-- Regular options -->
              <ng-container *ngIf="!sample_period.is_parent && !sample_period.parent">
                <button mat-menu-item (click)="selectPeriod(sample_period)">
                  {{sample_period.name | upperfirst | removeUnderscore}}
                </button>
              </ng-container>

              <ng-container *ngIf="sample_period.is_parent && periodMenuMap[sample_period.name]?.length">
                <button mat-menu-item [matMenuTriggerFor]="getMenu(sample_period.name)">
                  {{sample_period.name | upperfirst | removeUnderscore}}
                </button>
              </ng-container>

            </ng-container>
          </mat-menu>

          <!-- Submenus -->
          <mat-menu #shift="matMenu" class="nested-range-menu-panel">
            <ng-container [ngTemplateOutlet]="subMenus"
                          [ngTemplateOutletContext]="{ $implicit: 'shift'}"></ng-container>
          </mat-menu>

          <ng-template #subMenus let-menuGroup>
            <ng-container *ngFor="let option of periodMenuMap[menuGroup]">
              <button mat-menu-item (click)="selectPeriod(option)">
                {{option.name  | upperfirst | removeUnderscore}}
              </button>
            </ng-container>
          </ng-template>

        </div>


      </div>

      <div class="datepicker row">
        <div class="start-date">
          <label class="text start-date">Start</label>
          <div *ngIf="dtp_ready" class="row date-part">
            <input (dateChange)="changeDate($event)"
                   (focusout)="checkDate()"
                   [matDatepicker]="startPicker"
                   [value]="dtp.start"
                   tabindex="-1"
                   class="datetime"
                   name="start"
                   matTooltip="Type or click the calendar to select a custom start date.">
            <mat-datepicker-toggle [for]="startPicker" matSuffix>
              <span class="icon-arrow-down" matDatepickerToggleIcon></span>
            </mat-datepicker-toggle>
            <mat-datepicker #startPicker (opened)="streamOpened('start')"
                            (monthSelected)="streamOpened('start')"
                            [dateClass]="dateClass"
                            panelClass="start-picker"></mat-datepicker>
          </div>
          <div *ngIf="dateTimePeriodService.show_time && showTime !== false" class="row center">
            <input (change)="changeTime($event)"
                   (focusout)="checkDate()"
                   [value]="dtp.start | stringDate: {time_only:true}"
                   tabindex="-1"
                   class="datetime time"
                   name="start_time"
                   matTooltip="Click the clock to select a custom start time."
                   type="time"
                   pattern="[0-9]{2}:[0-9]{2}"
                   inputmode="numeric"
            >
          </div>
        </div>

        <div class="end-date">
          <label class="text end-date">End</label>
          <label class="text end-date-min">To</label>

          <div *ngIf="dtp_ready" class="row date-part">
            <input (dateChange)="changeDate($event)"
                   (focusout)="checkDate()"
                   [matDatepicker]="endPicker"
                   [value]="dtp.end"
                   tabindex="-1"
                   class="datetime"
                   name="end"
                   matTooltip="Type or click the calendar to select a custom end date.">
            <mat-datepicker-toggle [for]="endPicker" matSuffix>
              <span class="icon-arrow-down" matDatepickerToggleIcon></span>
            </mat-datepicker-toggle>
            <mat-datepicker #endPicker (opened)="streamOpened('end')"
                            (monthSelected)="streamOpened('end')"
                            [dateClass]="dateClass"
                            panelClass="end-picker"></mat-datepicker>
          </div>

          <div *ngIf="dateTimePeriodService.show_time && showTime !== false" class="row center">
            <input (change)="changeTime($event)"
                   (focusout)="checkDate()"
                   [value]="dtp.end | stringDate: {time_only:true}"
                   tabindex="-1"
                   class="datetime time"
                   name="end_time"
                   matTooltip="Click the clock to select a custom end time."
                   type='time'
            >
          </div>
        </div>
      </div>
      <div class="datetime-pager hide-xs">
        <i (click)="travel('backwards')" class="fa fa-arrow-circle-o-left"
           matTooltip="Travel one sample period backwards."></i>
        <i (click)="travel('forward')" class="fa fa-arrow-circle-o-right"
           matTooltip="Travel one sample period forwards."></i>
      </div>
      <div [ngClass]="{'dtp-immutable': immutable}" class="dtp-refresh">
        <i (click)="refreshDTP(immutable)" class="fa fa-refresh"></i>
      </div>

    </ng-container>
  </div>
</div>
