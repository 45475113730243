import {Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {ConditionalFormattingFormService} from "./conditional-formatting-form.service";
import {MatSelect} from '@angular/material/select';
import {TableUtilsService} from "../../tables/table-utils.service";
import {KeyValue} from '@angular/common';
import {ColumnFormatsConfigDict} from "../table-column-menu/table-column-menu.component";
import {CUSTOM_EVENTS_CONFIG} from "../custom-events-form/custom-events-form.component";
import {COMPONENT_EVENTS_CONFIG} from "../component-events-table-form/component-events-table-form.component";

@Component({
    selector: 'conditional-formatting-form',
    templateUrl: './conditional-formatting-form.component.html',
    styleUrls: ['./conditional-formatting-form.component.less'],
    providers: [ConditionalFormattingFormService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ConditionalFormattingFormComponent implements OnInit {
    @Input() config: CUSTOM_EVENTS_CONFIG | COMPONENT_EVENTS_CONFIG;
    @Input() content: string;

    @ViewChildren('select_named_format') select_named_format: QueryList<MatSelect>;

    available_columns: { name: string, id: string, type: string }[];
    named_formats: ColumnFormatsConfigDict = this.cps.named_formats;
    operator_dict = this.cps.operator_dict;
    originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
        return 0;
    }
    conditional_formats_dict = {};
    named_format_styles = {};
    col_menu_options: string[] = ['bold', 'italic', 'underline', 'strikethrough', 'colour', 'background_colour'];
    has_key = false;

    constructor(public cps: ConditionalFormattingFormService,
                private tableUtils: TableUtilsService) {
    }

    ngOnInit(): void {
        let key = 'selected_component_type';
        if (this.content === 'custom-events-table') {
            key = 'event_types';
        }
        if (!(this.config[key]?.id || this.config[key].length > 0)) return;
        this.has_key = true;

        this.cps.getColumns(this.config, key).pipe().subscribe(result => {
            this.available_columns = result;
        });
        /**Show the named_formats with formats applied**/
        Object.keys(this.named_formats).forEach(key => {
            this.named_format_styles[key] = this.tableUtils.getStyles(this.named_formats, key);
        })
        this.columnChanged()
    }

    addFormatRule() {
        if (!this.config.conditional_formats) {
            this.config.conditional_formats = [];
        }
        const new_format = {
            column: {id: null, name: null, type: null},
            comparer: ''
        }
        this.config.conditional_formats.push(new_format)
    }

    columnChanged() {
        this.config.conditional_formats?.filter(c => !c.named_format).forEach(condition => {
            this.conditional_formats_dict[this.config.conditional_formats.indexOf(condition)] = condition.format || null;
        })
    }

    namedFormat(condition, event) {
        condition.named_format = event.value.key;
        condition.format = event.value.value;
    }

    removeFormat(index) {
        this.config.conditional_formats.splice(index, 1);
    }

    columnCompare(a, b) {
        return a?.name === b?.name;
    }

    namedFormatCompare(a, b) {
        return a?.key === b;
    }

    openNamedSelect(index) {
        setTimeout(() => {
            this.select_named_format?.toArray()[index]?.open();
            this.namedFormat(this.config.conditional_formats[index], {
                value: {
                    key: 'ok',
                    value: this.named_formats['ok']
                }
            });
        })
    }
}
