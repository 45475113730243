import {Component, Input} from '@angular/core';
import {ConfigStub} from "../../_typing/config-stub";

@Component({
    selector: 'input-data-sheet-form',
    templateUrl: 'input-data-sheet-form.component.html',
    standalone: false
})
export class InputDataSheetFormComponent {
    private _config: any;
    @Input()
    set config(config) {
        this._config = config;
    }

    get config() {
        return this._config;
    }

    hint: string = '';

    constructor() {
    }

    processChange(event) {
        if (!event) return;
        this.config.process = new ConfigStub(event.value)
    }
}
