<div class="form-dialog">
    <mat-dialog-content class="export-dialog space-between">
        <div *ngIf="action != 'Delete'; else elseTemplate">
            <p>
                <mat-form-field *ngIf="action == 'Add'" appearance="standard">
                    <mat-label>Select export name</mat-label>
                    <mat-select [(value)]="local_data.export_name">
                        <mat-option [value]="handler" *ngFor="let handler of supported_export_handlers">
                            {{handler}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="standard">
                    <mat-label>Display text</mat-label>
                    <input matInput placeholder="Display text" [(ngModel)]="local_data.display_text">
                </mat-form-field>
            </p>

            <p>
                <mat-checkbox [(ngModel)]="local_data.supports_row_export"  [checked]="local_data.supports_row_export">Supports exporting per row</mat-checkbox>
            </p>
            <p>
                <mat-checkbox (change)="onChangeBulkExport($event);" [checked]="local_data.supports_bulk_export">Supports exporting in bulk </mat-checkbox>
            </p>

        </div>
        <ng-template #elseTemplate>
            Sure to delete <b>{{local_data.name}}</b>?
        </ng-template>
    </mat-dialog-content>

    <div class="action-buttons">
        <button class="btn-close" mat-button (click)="closeDialog()">Cancel</button>
        <button class="btn-save" mat-button (click)="doAction()">Save</button>
    </div>
</div>
