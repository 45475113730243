import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {MatCalendarCellCssClasses} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateTimePeriodService} from "../services/date-time-period.service";
import {TimeZoneService} from '../services/time-zone.service';
import {TimezoneSelectorService} from "../services/timezone-selector.service";
import * as utils from '../lib/utils';
import {AppScope} from "../services/app_scope.service";
import {takeUntil, tap} from "rxjs/operators";
import {Subject, combineLatest} from "rxjs";
import {HeaderDataService} from '../services/header_data.service';
import {ApiService} from "../services/api/api.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {
    IDateTimePeriod,
    OptionalCalendar,
    OptionalDateTimePeriod,
    OptionalSamplePeriod
} from "../_typing/date-time-period";
import {NotificationService} from "../services/notification.service";
import {FormDialogService} from "../services/form-dialog.service";
import {DateTimeInstanceService} from "../services/date-time-instance.service";
import {IDMap, KeyMap} from "../_typing/generic-types";
import {MatMenu} from "@angular/material/menu";
import {camelCase, snakeCase} from "lodash-es";
import {deepCopy} from "../lib/utils";

import {moment} from './../services/timezone-selector.service';

// export const moment = moment_["default"];
interface IParseType {
    dateInput: string[];
}

interface IDisplayType {
    dateInput: string;
    monthYearLabel: string;
    dateA11yLabel: string;
    monthYearA11yLabel: string;
}

interface IWireFormatsType {
    parse: IParseType;
    display: IDisplayType;
}

export const WIRE_FORMATS: IWireFormatsType = {
    parse: {
        dateInput: ['YYYY/MM/DD HH:mm'],
    },
    display: {
        dateInput: 'YYYY/MM/DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

interface ILookupType {
    date: string;
    start: boolean;
    end: boolean;
    calendar: string;
    start_name: string;
    end_name: string;
    text: string;
    account_id: string;
}

@Component({
    selector: 'date-time-period',
    templateUrl: 'date-time-period.component.html',
    providers: [
        { provide: DateAdapter, useClass: TimeZoneService, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DateTimePeriodComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() disabled: boolean = false;
    sample_periods: OptionalSamplePeriod[];
    hasCustomShifts: boolean = false;
    shiftDict: IDMap<OptionalSamplePeriod>;
    ranges: any[];
    calendars: OptionalCalendar[];
    dtp_ready: boolean;
    dtp: IDateTimePeriod;
    dateClass: any;
    dc_lookup: Record<number, ILookupType> = {};
    periodMenuMap: KeyMap<OptionalSamplePeriod[]> = {};
    rangeMenuMap: KeyMap<IDateTimePeriod[]> = {};

    @Input() icons: boolean = true;
    @Input() immutable: boolean = false;
    @Input() no_url: boolean = false; // set this to true if a change in date should not trigger a url update
    @Input() showTimespan: boolean = true;
    @Input() showTime: boolean = true;

    @ViewChild('shift', {static: false}) shift: MatMenu;
    @ViewChild('yesterdayByHalfShift', {static: false}) yesterdayByHalfShift: MatMenu;
    @ViewChild('thisShift', {static: false}) thisShift: MatMenu;
    @ViewChild('week', {static: false}) week: MatMenu;
    @ViewChild('month', {static: false}) month: MatMenu;
    @ViewChild('quarter', {static: false}) quarter: MatMenu;
    @ViewChild('year', {static: false}) year: MatMenu;

    constructor(public dateTimePeriodService: DateTimePeriodService,
                public dateInst: DateTimeInstanceService,
                private notification: NotificationService,
                public appScope: AppScope,
                private headerData: HeaderDataService,
                private api: ApiService,
                private activatedRoute: ActivatedRoute,
                private renderer: Renderer2,
                private router: Router,
                private tz: TimeZoneService,
                private timezoneSelectorService: TimezoneSelectorService,
                private formDialog: FormDialogService) {
    }

    getMenu(name) {
        return this[camelCase(name)];
    }

    ngOnInit(): void {

        this.dateInst.dateTimePeriodInitialised$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(value => {
            this.dtp = utils.deepCopy(this.dateInst.dtp);
            this.dtp.start = new Date(this.dtp.start);
            this.dtp.end = new Date(this.dtp.end);

            this.sample_periods = utils.deepCopy(this.dateTimePeriodService.sample_periods);
            this.sample_periods.forEach(sp => {
                if (sp.is_parent) {
                    this.periodMenuMap[sp.name] = this.sample_periods.filter(p => {
                        return p.parent === sp.name
                    });
                }
            })

            this.ranges = utils.deepCopy(this.dateTimePeriodService.ranges);
            this.ranges.forEach(r => {
                if (r.is_parent) {
                    this.rangeMenuMap[r.range] = this.ranges.filter(p => {
                        return p.parent === r.range
                    });
                }
            })

            //console.log("sub", this.sample_periods, this.periodMenuMap, this.ranges, this.rangeMenuMap);
            this.calendars = this.dateTimePeriodService.calendars;
            this.dtp_ready = true;
            this.buildLookup();
        });

        this.dateInst.dateTimePeriodChanged$.pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.dtp = utils.deepCopy(this.dateInst.dtp);
                this.dtp.start = new Date(this.dtp.start);
                this.dtp.end = new Date(this.dtp.end);
            })
    }

    emitDtpChanged() {
        // Only affects components that subscribe to dtpChanged
        this.dateInst.emitDateTimePeriodChanged(this.dtp);
        if (this.immutable !== true) {
            this.headerData.dtpChanged.emit(this.dtp); // For tiles (which have an indie dtp provider)
        }
    }

    refreshDTP(immutable: boolean = false) {
        this.checkDate();
        this.api.cancelActiveQueries();
        // TODO update all subscriptions/naming to avoid confusion between these two
        this.dateInst.dateTimePeriodRefreshed$.next(this.dtp);
        if (immutable !== true) {
            this.headerData.dtpReset.emit(this.dtp);
        }
    }

    changeCalendar() {
        this.ranges = utils.deepCopy(this.dateTimePeriodService.calendar_dict[this.dtp.calendar].ranges);
        this.changeRange();
    }

    changeRange(range?: string) {
        if (range) this.dtp.range = range;
        if (this.dtp.range !== "custom") {
            this.dtp = this.dateTimePeriodService.getDTP(this.dtp.range, null, this.dtp.calendar);
        }
        this.dtp = this.dateTimePeriodService.validateDTP((this.dtp));
        if (!this.dtp.start || !this.dtp.end) {
            this.notification.openError('Warning: no dates defined for this calendar range.');
        }
        this.emitDtpChanged();

        if (!this.no_url) {
            this.updateURL();
        }
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    checkDate() {
        const ctrl = this;
        if (new Date(this.dtp.start).valueOf() >= new Date(this.dtp.end).valueOf()) {
            this.dtp.end = new Date(this.dtp.start)['addHours'](this.dtp.sample_period.hours);
            this.dateInst.emitDateTimePeriodChanged(this.dtp);
            this.updateURL();
            this.notification.openError('Warning: start date cannot be greater than end date. End date reset.');
        }
    }

    changeDate(evt) {
        const i = evt.target.value._i;
        const momentDate = this.dateTimePeriodService.setMomentDate(i,this.dtp);

        if (evt.targetElement.name === 'start') {
            this.dtp.start = momentDate.toDate();
        } else if (evt.targetElement.name === 'end') {
            this.dtp.end = momentDate.toDate();
        }

        this.dtp.range = "custom";
        this.emitDtpChanged();

        if (!this.no_url) {
            this.updateURL();
        }
    }

    changeTime(evt) {
        const ctrl = this;
        if (evt.target.name === 'start_time') {
            this.dtp.start = this.dateTimePeriodService.setMomentTime(evt, new Date(this.dtp.start));
        } else if (evt.target.name === 'end_time') {
            this.dtp.end = this.dateTimePeriodService.setMomentTime(evt, new Date(this.dtp.end));
        }
        this.dtp.range = "custom";
        this.emitDtpChanged();

        if (!this.no_url) {
            this.updateURL();
        }
    }

    selectPeriod(period: any) {
        this.dtp.sample_period = period;
        this.changePeriod();
    }

    changePeriod() {
        const dtp = this.dateTimePeriodService.changePeriod(this.dtp);
        this.dtp = Object.assign({}, dtp);
        this.emitDtpChanged();

        if (!this.no_url) {
            this.updateURL();
        }
    }

    travel(direction) {
        const ctrl = this;
        if (direction === 'forward') {
            this.dtp.start = new Date(this.dtp.start)['addHours'](this.dtp.sample_period.hours);
            this.dtp.end = new Date(this.dtp.end)['addHours'](this.dtp.sample_period.hours);
        } else {
            this.dtp.start = new Date(this.dtp.start)['addHours'](-this.dtp.sample_period.hours);
            this.dtp.end = new Date(this.dtp.end)['addHours'](-this.dtp.sample_period.hours);
        }

        this.emitDtpChanged();
    }

    showDatePickerOnly() {
        const ctrl = this;
        if (!this.dtp) {
            return false;
        }
        return this.dtp.sample_period.name === 'day' || this.dtp.sample_period.name === 'week' || this.dtp.sample_period.name === 'month';
    }

    dateFilter(date) {
        const ctrl = this;

        if (this.dtp.sample_period.name === 'day') {
            return true;
        }

        if (this.dtp.sample_period.name === 'week') {
            return date.getDay() === this.appScope.config_name_map.week_start.value;
        }

        if (this.dtp.sample_period.name === 'month') {
            return date.getDate() === 1;
        }
    }

    streamOpened(event) {
        const ctrl = this;
        this.addCalendarClasses();
        setTimeout(() => {

            let nav_buttons = document.querySelectorAll("button.mat-calendar-next-button, button.mat-calendar-previous-button");
            nav_buttons.forEach(btn => {
                this.renderer.listen(btn, "click", (event) => {
                    this.updateButtonLabels(event)
                })
            })
            this.updateButtonLabels(event);
        });

    }

    updateButtonLabels(event) {
        const ctrl = this;
        let buttons = document.querySelectorAll("button.mat-calendar-body-cell");

        buttons.forEach(btn => {
            const dateSearch = this.dateValue(btn.getAttribute("aria-label"));
            if (this.dc_lookup[dateSearch]) {
                btn.children[0].setAttribute("aria-label", this.dc_lookup[dateSearch].text);
            }
            if ((btn.classList.contains('selected-start') && event === 'start') || (btn.classList.contains('selected-end') && event === 'end')) {
                btn.children[0].setAttribute("aria-label", 'Current selection');
            }

            this.renderer.listen(btn, "click", (event) => {
                setTimeout(async () => {
                    const dateSearch = this.dateValue(event.target.parentElement.getAttribute("aria-label"));
                    if (this.dc_lookup[dateSearch] && this.dc_lookup[dateSearch].calendar !== this.dtp.calendar) {
                        const confirmResult = await this.formDialog.confirm(
                            "You've selected a date on the " + this.dc_lookup[dateSearch].calendar + " calendar. " +
                            "Would you like to change calendars?", "No", "Yes"
                        );

                        if (confirmResult) {
                            this.dtp.calendar = this.dc_lookup[dateSearch].calendar;
                            this.changeCalendar();
                        }
                    }
                });
            });
        });
    }

    buildLookup() {
        const ctrl = this;
        // This combines start and end dates into single object with text and calendar for easy access when opening the calendar
        for (let period of this.dateTimePeriodService.time_periods) {
            const calendar = this.dateTimePeriodService.calendars.find(cal => cal.id === period.relationships.period_type.data.id);

            let date = period.attributes.start ? period.attributes.start : null;
            if (date) {
                if (this.dc_lookup[this.dateValue(date)]) {
                    this.dc_lookup[this.dateValue(date)].start = true;
                    this.dc_lookup[this.dateValue(date)].start_name = period.attributes.name;
                } else {
                    this.dc_lookup[this.dateValue(date)]
                        = this.newLookupObject(date.toString(), true, null, calendar?.name, period.attributes?.name, null,
                        calendar?.account_id ? calendar.account_id : this.dateTimePeriodService.defaultCalendar);
                }
            }

            date = period.attributes.end ? period.attributes.end : null;
            if (date) {
                if (this.dc_lookup[this.dateValue(date)]) {
                    this.dc_lookup[this.dateValue(date)].end = true;
                    this.dc_lookup[this.dateValue(date)].end_name = period.attributes.name;
                } else {
                    this.dc_lookup[this.dateValue(date)]
                        = this.newLookupObject(date.toString(), null, true, calendar?.name, null, period.attributes.name,
                        calendar?.account_id ? calendar.account_id : this.dateTimePeriodService.defaultCalendar);
                }
            }
        }
        Object.keys(this.dc_lookup).forEach(key => {
            const period = this.dc_lookup[key];
            let text = period.calendar;
            if (period.end) {
                text += ` end ${period.end_name}`;
                if (period.start) {
                    text += " /";
                }
            }
            if (period.start) {
                text += ` start ${period.start_name}`;
            }
            this.dc_lookup[key].text = text;
        });
    }

    addCalendarClasses() {
        this.dateClass = (d: any): MatCalendarCellCssClasses => {
            const d1: string = utils.stringDate(d, {date_only: true});

            const dstart: string = utils.stringDate(utils.deepCopy(this.dtp.start), {date_only: true});
            const dend: string = utils.stringDate(utils.deepCopy(this.dtp.end), {date_only: true});
            const date = d._d;
            const date_value = this.dateValue(utils.deepCopy(date));
            let date_class = [];
            if (this.dc_lookup[date_value] && (this.dc_lookup[date_value].account_id === this.appScope.active_account_id ||
                this.dc_lookup[date_value].account_id === this.dateTimePeriodService.defaultCalendar)) {
                const period = this.dc_lookup[date_value];
                date_class.push(
                    period.start ? 'start' : null,
                    period.end ? 'end' : null,
                    period.calendar === this.dtp.calendar ? 'current-calendar' : 'other-calendar'
                );
            }

            if (d1 === dstart) {
                date_class.push('selected-start');
            }
            if (d1 === dend) {
                date_class.push('selected-end');
            }
            return date_class.join(' ');
        };

    }

    updateURL() {
        let search: OptionalDateTimePeriod = {
            start: new Date(this.dtp.start),
            end: new Date(this.dtp.end),
            range: this.dtp.range,
            sample_period: this.dtp.sample_period,
            calendar: this.dtp.calendar
        };

        const queryParams: Params = {
            dtp: JSON.stringify(search)
        };

        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // prevents replacing other params (e.g. series_list)
            });
    }

    private newLookupObject(date: string, start: boolean | null, end: boolean | null, calendar: string,
                            start_name: string | null, end_name: string | null, account_id: string) {
        return {
            date: date,
            start: start,
            end: end,
            calendar: calendar,
            start_name: start_name,
            end_name: end_name,
            text: null,
            account_id: account_id
        };
    }

    private dateValue(date) {
        let dt = utils.deepCopy(new Date(date));
        dt.setHours(0, 0, 0, 0);
        return dt.valueOf();
    }

    enabled(sample_period: any) {
        // TODO disable these options on sites that don't have their own historians
        // let disabled = ['points', 'minute', '5 minute', '10 minute', '30 minute'];
        return true;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
