import {Injectable} from '@angular/core';
import {FormulaPart, FormulaPartType, NameFormula, ComponentFormConfig} from "../_typing/config/component-form-config";
import {moment} from "./timezone-selector.service";
import {julianDate, stringDate} from "../lib/utils";
import {SearchQueryOptions} from "./api/search-query-options";
import {IDMap, KeyMap, ModelID} from "../_typing/generic-types";
import {ConstantValue} from "../_models/api/generic-constant";
import {ConstantProperty} from "../_models/constant-property";
import {take} from "rxjs";
import {ConstantPropertyDataService} from "../data/constant-property-data.service";
import {ListResponse} from "./api/response-types";
import {Observable} from 'rxjs';
import {union as _union, difference as _difference} from "lodash-es";
import {COMPONENT_EVENTS_CONFIG} from "../forms/component-events-table-form/component-events-table-form.component";
import {NotificationService} from "./notification.service";

@Injectable({
    providedIn: 'root'
})
export class ComponentNameFormulaBuilderService {

    constructor(private propertyData: ConstantPropertyDataService,
                private notification: NotificationService) {
    }

    getFormulaString(formulaParts: FormulaPart[]): string {
        if (!formulaParts) return;
        let string = []
        formulaParts.forEach(p => {
            switch (p.type) {
                case "date":
                    string.push('[d:' + p.content + ']');
                    break;
                case "constant_property":
                    string.push('[cp:' + p.content + ']');
                    break;
                case "separator":
                    string.push(p.content);
                    break;
                case "text":
                    string.push(p.content);
                    break;
                default:
                    break
            }
        })
        return string.join('');
    }

    getNameString(formulaParts: FormulaPart[], cpDict: IDMap<ConstantProperty>): string {
        let string = [];

        formulaParts.forEach(p => {
            let value = p.value || p.content;
            let notSet = p.type === 'constant_property' && !p.value;
            if (notSet) {
                value = cpDict?.[p.content]?.attributes.name || '...';
            }
            string.push(notSet ? '[' + value + ']' : value);
        });
        return string.join('');
    }

    updateValue(formulaParts: FormulaPart[], value: ConstantValue, propertyId: ModelID): FormulaPart[] {
        if (this.hasProperty(formulaParts, propertyId)) {
            formulaParts.find(p => p.content === propertyId).value = value;
        }
        return formulaParts;
    }

    getInitialFormula(formulaParts: FormulaPart[], defaultValueDict?: KeyMap<ConstantValue>): FormulaPart[] {
        formulaParts.forEach(p => {
            switch (p.type) {
                case "date":
                    p.value = this.getDateFromFormula(p);
                    break;
                case "text":
                case "separator":
                    p.value = p.content;
                    break;
                case "constant_property":
                    p.value = defaultValueDict?.[p.content];
                    break;
                default:
                    break;
            }
        })
        return formulaParts;
    }

    updateDateValue(formulaParts: FormulaPart[], dt: Date): FormulaPart[] {
        formulaParts.forEach(p => {
            if (p.type === 'date') {
                p.value = this.getDateFromFormula(p, dt)
            }
        })
        return formulaParts;
    }

    getDateFromFormula(p: FormulaPart, dt?: Date) {
        if (p.content === 'JULIAN') {
            return julianDate(dt || new Date())
        } else {
            try {
                return moment(dt).format(p.content);
            } catch (error) {
                let args = {date_separator: ''}
                return stringDate(dt || new Date(), args);
            }
        }
    }

    private hasProperty(formulaParts: FormulaPart[], propertyId: ModelID) {
        return formulaParts.map(f => f.content).includes(propertyId);
    }

    getFormulaPropertyIds(formulaParts: FormulaPart[]) {
        return formulaParts.filter(p => p.type === 'constant_property')?.map(cp => cp.content);
    }

    getProperties(nameFormulas: KeyMap<NameFormula>): Observable<ListResponse<ConstantProperty>> {
        let ids: ModelID[];
        const cpIds = Object.values(nameFormulas).reduce((ids: ModelID[], formula: NameFormula) => {
            return ids.concat(this.getFormulaPropertyIds(formula));
        }, [])

        if (!cpIds) return;
        return this.propertyData.getConstantProperties(cpIds)
            .pipe(take(1))

    }

    checkComponentNameFields(config: ComponentFormConfig | COMPONENT_EVENTS_CONFIG, colCpIds, requiredCpIds, cpDict): boolean {
        if (!config.selected_name_formula?.key) return true;
        const nameFormula = config.selected_name_formula;

        const cpIds = nameFormula?.formula?.filter(p => p.type === 'constant_property').map(p => p.content);
        const allIncluded = cpIds.every(item => colCpIds.includes(item) && requiredCpIds.includes(item));

        if (allIncluded) return true
        const missingCps = _union(_difference(cpIds, colCpIds).concat(_difference(cpIds, requiredCpIds)));

        this.notification.openError("The following constant properties are required by the component name formula but " +
            "are missing from your column selection or not set as 'required': " + missingCps.map(cp => cpDict[cp]?.attributes.name).join());
        return false;
    }
}
