import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient, HttpStatusCode } from "@angular/common/http";
import {ApiService} from "./api/api.service";
import {combineLatest, Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {AppScope} from "./app_scope.service";
import {map, takeUntil, tap} from "rxjs/operators";
import {Account} from "../_models/account";
import {SearchQueryOptions} from "./api/search-query-options";
import {getAccountFilter, getBaseFilter} from "./api/filter_utils";
import {ListResponse} from './api/response-types';
import {SessionState} from '../_models/session-state';
import {UserPreferenceDataService} from "../data/user-preference-data.service";
import {UserPreference} from "../_models/user-preference";
import {WIRE_BASE_CONFIG_NAME} from "../shared/globals";
import {partition as _partition} from "lodash-es";
import {UserService} from "./user.service";

@Injectable({
    providedIn: 'root'
})
export class AccountService implements OnDestroy {
    private readonly onDestroy: Subject<void> = new Subject<void>();
    /**
     * List of accounts this user has been assigned to.
     */
    public readonly accountsChanged: ReplaySubject<Account[]> = new ReplaySubject<any>(1);
    /**
     * Active account_id assigned to this session.
     */
    public readonly activeAccountChanged: ReplaySubject<{ account_id: string }> = new ReplaySubject<{ account_id: string }>(1);
    public default_url = '';

    // Active account subscription
    private refresh_aa_subscription: Subscription;
    // Accounts subscription
    private refresh_as_subscription: Subscription;

    constructor(private appScope: AppScope,
                private http: HttpClient,
                private api: ApiService,
                private userPreferenceData: UserPreferenceDataService,
                private userService: UserService) {
        this.activeAccountChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                appScope.active_account_id = response.account_id;
            });
        this.refresh().subscribe();
    }

    /**
     * Refreshes the list of accounts the user has access to as well as what the active account is.
     */
    public refresh() {
        return this.appScope.authComplete$.pipe(map( () => {
            if (this.refresh_as_subscription) {
                this.refresh_as_subscription.unsubscribe();
                this.refresh_as_subscription = null;
            }
            if (this.refresh_aa_subscription) {
                this.refresh_aa_subscription.unsubscribe();
                this.refresh_aa_subscription = null;
            }

            this.refresh_aa_subscription = this.http.get('/api/ActiveAccount').subscribe({
            next: (response: { account_id: string }) => {
                this.activeAccountChanged.next(response);
            }, error: (error) => {
                if (error.status === HttpStatusCode.NotFound) {
                    try {
                        const default_account_id: string = this.appScope.current_user.default_account_id;
                        this.activeAccountChanged.next({account_id: default_account_id});
                    } catch (e) {
                    }
                } else {
                    console.log("Error determining current active account for session", error);
                }
            }});
            let options: SearchQueryOptions = new SearchQueryOptions();
            options.sort = 'order,name';
            this.refresh_as_subscription = this.api.account.searchMany(options).subscribe((response: { data: Account[] }) => {
                let user_linked_accounts: string[];
                try {
                    user_linked_accounts = this.appScope.current_user.accounts;
                } catch (e) {
                    user_linked_accounts = [];
                }
                try {
                    user_linked_accounts.push(this.appScope.current_user.default_account_id);
                } catch (e) {
                }
                this.appScope.WIREBaseConfigId = response.data.find(account => account.attributes.name === WIRE_BASE_CONFIG_NAME)?.id;
                const accounts: Account[] = response.data.filter(account => account.attributes.name !== 'WIREBaseConfig');
                this.appScope.accounts = accounts;
                accounts.forEach(account => {
                    if (!user_linked_accounts.find(id => id === account.id)) {
                        account.view_only = true;
                    }
                });
                this.accountsChanged.next(accounts);
            });
        }));
    }

    /**
     * Sets the active account for this client session.
     * The active account will be used to set the account of created resources. This will be done on the backend,
     * therefore clients should never specify an account when creating or editing resources.
     */
    public setActiveAccount(account_id: string): Observable<any> {
        return this.http.post('/api/ActiveAccount', { account_id: account_id }).pipe(
            tap(() => {
                this.activeAccountChanged.next({ account_id: account_id });
        }));
    }

    public getAccountsStatus(accounts: Account[]) {
        accounts.forEach(account => {
            if (!this.userService.canManageUsers(undefined, account.id)) {
                account.view_only = true;
            }
        });
        const account_parts = _partition(accounts, a => !a.view_only);
        accounts = account_parts[0].concat(account_parts[1]);

        return accounts;
    }

    public getDefaultSessionState(account_id: string): Observable<ListResponse<SessionState>> {
        const options: SearchQueryOptions = new SearchQueryOptions();
        options.filters = [getAccountFilter(account_id), getBaseFilter(true, 'is_default', 'eq')];
        return this.api.session_state_light.searchMany(options);
    }

    public getDefaultUserSessionState(account_id: string, user_id: string): Observable<string> {
        /**Returns default dashboard for current user and account else default for account**/
        let default_session_state_id: string;
        let user_default_session_id: string;
        const $defaultSessionState: Observable<ListResponse<SessionState>> = this.getDefaultSessionState(account_id)
            .pipe(tap((ss: ListResponse<SessionState>) => {
                    default_session_state_id = ss?.data?.[0]?.id;
                })
            );
        const $userPrefs: Observable<ListResponse<UserPreference>> =
            this.userPreferenceData.getUserPreferencesByUserAndAccount(this.appScope.current_user.id)
            .pipe(tap((up: ListResponse<UserPreference>): void => {
                user_default_session_id = up?.data?.[0]?.relationships.session_state.data?.id;
            }));
        return combineLatest([$userPrefs, $defaultSessionState]).pipe(map(() => {
            if (user_default_session_id) {
                default_session_state_id = user_default_session_id;
            }
            return default_session_state_id;
        }));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
