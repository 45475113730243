import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DateTimePeriodService} from "../../../services/date-time-period.service";
import {Component as WireComponent} from "../../../_models/component";
import {ConstantProperty} from "../../../_models/constant-property";
import {of, Subject, Subscription} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import * as utils from "../../../lib/utils";
import {catchError, concatMap, map, take, takeUntil, tap} from "rxjs/operators";
import {CustomEventsService} from "../../../services/custom-events.service";
import {
    COMPONENT_EVENTS_CONFIG
} from "../../../forms/component-events-table-form/component-events-table-form.component";
import {ListResponse} from "../../../services/api/response-types";
import {ApiService} from '../../../services/api/api.service';
import {SearchQueryOptions} from "../../../services/api/search-query-options";
import {TableUtilsService} from "../../table-utils.service";
import {TileDataService} from "../../../services/tile_data.service";
import {PaginationDataSourceWithAddRow} from "../../../services/api/pagination-data-source-with-add-row";
import {ComponentEventsTableService} from "../component-events-table.service";
import {HandleError, HttpErrorHandler} from "../../../services/http-error-handler.service";
import {EventConfigColumn} from "../../../_typing/config/config-column";
import {getColIds, getPropertyIds} from "../../custom-events-table/utils";
import {IDMap, ModelID} from '../../../_typing/generic-types';
import {GenericConstantApiResponse, GenericField} from "../../../_models/api/generic-constant";
import {GenericConstantDataService} from "../../../data/generic-constant-data.service";
import {IDateTimePeriod} from "../../../_typing/date-time-period";
import {NotificationService} from "../../../services/notification.service";
import {UserService} from "../../../services/user.service";
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";
import {NOTIFICATION_DURATION_MS} from "../../../shared/globals";

@Component({
    selector: 'components-table',
    templateUrl: './components-table.component.html',
    styleUrls: ['../component-events-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TableUtilsService],
    standalone: false
})
export class ComponentsTableComponent implements OnInit, OnDestroy {
    @Input() parent_component: any;
    @Input() selected_component_relationship_type: any;
    @Input() config: COMPONENT_EVENTS_CONFIG;
    @Input() save_content: Subject<any>;
    @Input() link_only = false;

    event_status: string | undefined;
    component_columns: string[];
    dtp: IDateTimePeriod;
    components: WireComponent[] | undefined;

    component_constants: IDMap<IDMap<GenericField>>;
    property_columns: string[];
    extra_cols: string[];
    format_dict: any;
    selected_cols_component_dict: Record<string, EventConfigColumn> = {};


    constant_properties: ConstantProperty[];
    cp_name_dict: any;
    cp_dict: IDMap<ConstantProperty>;

    $save_subscription: Subscription | null = new Subscription();

    componentDataSource: PaginationDataSourceWithAddRow<WireComponent>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    page_size = 10;
    page_size_options = [10, 20, 50, 100, 200];
    filter_string;
    loading_next = false;

    private readonly onDestroy = new Subject<void>();
    private readonly handleError: HandleError;

    constructor(public eventsService: CustomEventsService,
                private componentEventsService: ComponentEventsTableService,
                private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private changeDetectorRef: ChangeDetectorRef,
                private notification: NotificationService,
                public tableUtils: TableUtilsService,
                public tileData: TileDataService,
                public renderer: Renderer2,
                private userService: UserService,
                private genericConstantData: GenericConstantDataService,
                httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ComponentsTableComponent');
    }


    ngOnInit(): void {
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';
        this.loading_next = true;

        if (!this.parent_component) {
            this.notification.openError("No component type selected. Please check your configuration.", 10000);
            return;
        }
        this.event_status = 'Loading components...';
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            this.emitFilterQuery();
        });
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            this.dtp = this.dateInst.dtp;
            const base_type = this.selected_component_relationship_type.attributes.base_type;
            const ct_id = this.selected_component_relationship_type.id;
            setTimeout(() => {
                this.paginator.pageSize = 10;
                initialQuery.filters = this.componentEventsService.getComponentsForComponentFilters(this.parent_component.id,
                    this.selected_component_relationship_type);
                this.componentDataSource = new PaginationDataSourceWithAddRow<WireComponent>(
                    (query) => this.page(query),
                    initialQuery,
                    this.paginator,
                    this.sort
                );

                this.componentDataSource.$page.pipe(
                    takeUntil(this.onDestroy),
                    tap(result => {
                        this.components = result.data;
                    }),
                    concatMap((response) => {
                        let prop_ids = getPropertyIds(this.config.selected_cols?.component[ct_id]);
                        return this.eventsService.getComponentTypeProperties([ct_id], prop_ids)
                            .pipe(concatMap((prop_response: ListResponse<ConstantProperty>) => {
                                this.constant_properties = prop_response.data;
                                const prop_ids = this.constant_properties.map(cp => cp.id);
                                this.mapComponentProperties(ct_id);
                                const component_ids = this.components.map(c => c.id);
                                const $componentConstants = this.componentEventsService.getApiComponentConstants(component_ids, prop_ids);
                                return $componentConstants.pipe(
                                    map((componentConstants: GenericConstantApiResponse) => {
                                        this.component_constants = componentConstants.data;
                                    }),
                                    catchError(e => {
                                        console.log("ERROR: Components Table (ngOnInit) \n", e)
                                        return of(e);
                                    })
                                )
                            }));
                    })
                )
                    .subscribe(() => {
                        this.loading_next = false;
                        if (!this.components || this.components?.length < 1) {
                            this.event_status = 'No components for the component type and date range.';
                            console.log('No components for the component type and filters.');
                            this.changeDetectorRef.markForCheck();
                        } else {
                            this.event_status = undefined;
                            this.createComponentsTable();
                        }
                    });
            }, 100);
        });

        this.tileData.editing.pipe(takeUntil(this.onDestroy))
            .subscribe((newBool: boolean) => {
                this.tableUtils.editing = newBool;
                // add and remove listeners conditionally
                if (newBool === true) {
                    this.tableUtils.mousemove_handler = this.renderer.listen(document, "mousemove", event => {
                        this.tableUtils.onMouseMove(event, this.format_dict);
                    });
                    this.tableUtils.mouseup_handler = this.renderer.listen(document, "mouseup", event => {
                        this.tableUtils.mouseUp(event);
                    });
                } else {
                    this.tableUtils.removeListeners();
                }
            });
        if (this.save_content) {
            this.save_content.subscribe(event => {
                if (!this.tileData.tile) {
                    return;
                }
                const c_id = this.selected_component_relationship_type.id;
                this.tileData.tile.attributes.parameters.selected_cols.component[c_id] =
                    this.tableUtils.updateColumnFormats(this.config.selected_cols.component[c_id], this.format_dict);
                this.tableUtils.emitSaveTile(this.tileData);
            });
        }
        this.config.selected_cols.component[this.selected_component_relationship_type.id]?.forEach(item => {
            this.selected_cols_component_dict[item.id] = item;
        });
    }

    emitFilterQuery() {
        this.loading_next = true;
        const filters = this.componentEventsService.getComponentsForComponentFilters(this.parent_component.id,
            this.selected_component_relationship_type);
        this.componentDataSource.filterBy(filters);
    }

    page(query) {
        this.loading_next = true;
        const base_type = this.selected_component_relationship_type.attributes.base_type;
        return this.api[base_type].searchMany(query);
    }

    mapComponentProperties(ct_id: string): void {
        this.cp_name_dict = {};
        this.cp_dict = {};
        this.property_columns = [];
        this.constant_properties.forEach((cp: ConstantProperty) => {
            this.cp_name_dict[cp.attributes.name] = cp;
            this.cp_dict[cp.id] = cp;
            this.eventsService.schema.attributes.custom_constants[cp.attributes.name.trim()] = null;
        });
        this.extra_cols = ["name"];
        if (this.config.selected_cols?.component[ct_id]?.map(item => item.id).includes('start_time')) {
            this.extra_cols.push('start_time');
        }
        if (this.config.selected_cols?.component[ct_id]?.map(item => item.id).includes('end_time')) {
            this.extra_cols.push('end_time');
        }
        // Get the list of constant property columns selected by the user
        if (this.config.selected_cols?.component[ct_id]) {
            this.property_columns = getPropertyIds(this.config.selected_cols.component[ct_id]);
        }
    }

    createComponentsTable(): void {
        const ctrl = this;
        this.component_columns =
            ['Unlink'].concat(getColIds(this.config.selected_cols.component[this.selected_component_relationship_type.id]));
        if (this.link_only) {
            this.component_columns = this.component_columns.filter(c => c === 'Unlink' || c === 'name');
        }

        this.initialiseColumnFormats();

        this.event_status = undefined;
        this.changeDetectorRef.markForCheck();
        this.eventsService.event_state = {};
        this.eventsService.error_list = {};
    }

    initialiseColumnFormats(): void {
        this.format_dict = {};
        this.config.selected_cols.component[this.selected_component_relationship_type.id].forEach(column => {
            this.format_dict[column.id] = this.tableUtils.getColumnFormats(column.format);
        });
    }

    saveConstant(component: WireComponent, prop_id: ModelID, $event) {
        let $sub;
        console.log('ComponentsTableComponent - saveConstant: ', $event);
        $sub = this.genericConstantData.saveComponentConstant(
            component.id,
            prop_id,
            $event
        ).pipe(catchError(err => {
            console.log('Component constant not saved: ', err);
            return of(null);
        }));
        $sub.subscribe(result => {
            if (result) {
                this.notification.openSuccess("Value saved.", NOTIFICATION_DURATION_MS);
            }
            this.changeDetectorRef.markForCheck();
        });
        this.$save_subscription.add($sub);
    }

    saveComponent(component, column, $event) {
        let saved = [];

        let save_me = {id: component.id, type: component.type, attributes: {}};
        if (column === 'start_time') {
            save_me.attributes['start_time'] = $event.toISOString();
        } else if (column === 'end_time') {
            save_me.attributes['end_time'] = $event.toISOString();
        } else if (column === 'name') {
            save_me.attributes['name'] = component.attributes.name;
        }

        let s;
        let mylist = ['start_time', 'end_time', 'name'];

        if (mylist.includes(column) && component && component.type) {
            s = this.api[component.type].obsPatch(save_me).pipe(take(1)).subscribe(result => {
                if (result.data) {
                    this.notification.openSuccess("Component saved.", NOTIFICATION_DURATION_MS);
                }
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    canUnlink(component_id) {
        return this.eventsService.event_state[component_id]?.state !== 'dirty' && this.config.allow_unlink_components &&
            this.userService.canUnlinkComponent();
    }

    unlinkComponent(component) {
        this.componentEventsService.unlinkComponent(component, this.parent_component)
            .pipe(take(1), takeUntil(this.onDestroy)).subscribe(result => {
            if (!result) {
                return;
            }
            if (result === false) {
                this.eventsService.event_state[component.id] = {state: 'error', message: 'Unable to unlink component.'};
                return;
            }
            this.components = this.components.filter(e => e.id !== component.id);
            this.componentDataSource.refresh();
            this.changeDetectorRef.markForCheck();
        });
    }

    updateSort(event) {
        const allowed = ['start', 'end', 'name'];
        if (!allowed.includes(this.sort.active.toLowerCase())) {
            return false;
        }
        this.componentDataSource.sortBy(this.sort);
    }

    updateSearchFilter() {
        const filters: any = this.componentEventsService.getComponentsForComponentFilters(this.parent_component.id,
            this.selected_component_relationship_type);
        if (!this.filter_string) {
            this.componentDataSource.filterBy(filters);
            return;
        }
        this.loading_next = true;
        const allSearchFilters = {or: []};
        allSearchFilters.or.push({op: 'ilike', name: 'name', val: '%' + this.filter_string + '%'});
        if (!this.link_only) {
            const prop_columns = this.property_columns.filter(col => !this.extra_cols.includes(col));
            const constantPropertiesFilters =
                this.componentEventsService.getConstantPropertiesSearchFilter(prop_columns, this.cp_dict, this.filter_string);
            allSearchFilters.or = allSearchFilters.or.concat(constantPropertiesFilters);
        }

        filters.push(allSearchFilters);
        this.componentDataSource.filterBy(filters);
    }

    ngOnDestroy(): void {
        this.$save_subscription = utils.refreshSubscription(this.$save_subscription);
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
