import {Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter} from '@angular/core';

/**For adding e.g. a list of custom fields to a @Component**/
export interface CustomFormFieldConfigurationOptions {
    name: string;
    field_type: 'input' | 'checkbox' | 'div';
    type: 'string' | 'number' | 'boolean' | 'text';
    help: string;
}

export interface CustomFormFieldConfig {
    label?: string;
    name?: string;
    decimal_places?: number
}

/**
 * Component for configuring custom options (e.g. label) for the models selected on a form
 * For options similar to tableMenuFormats but for 1st level config models
 */
@Component({
    selector: 'custom-form-field',
    templateUrl: './custom-form-field.component.html',
    styleUrls: ['./custom-form-field.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomFormFieldComponent implements OnInit {
    @Input() config: CustomFormFieldConfig;
    @Input() title: string;
    @Input() include_options: string[] = ['label'];
    @Input() display_inline = false;
    @Output() configChanged = new EventEmitter<CustomFormFieldComponent>();

    /**Add any needed options to this list. Use the include_options Input() to customise which of these to show**/
    configuration_options: CustomFormFieldConfigurationOptions[] = [
        {
            name: 'label',
            field_type: 'input',
            type: 'string',
            help: 'Enter a custom label for this property'
        },
        {
            name: 'prev',
            field_type: 'div',
            type: 'string',
            help: 'Previous name used for this property'
        },
        {
            name: 'decimal_places',
            field_type: 'input',
            type: 'number',
            help: 'No. of decimal places to show'
        }
    ]
    filtered_options: CustomFormFieldConfigurationOptions[];

    constructor() {
    }

    ngOnInit(): void {
        if (!this.config) {
            this.config = {};
        }
        this.filtered_options = this.getConfigurationOptions();
        this.filtered_options.forEach(opt => {
            if (!this.config.hasOwnProperty(opt.name)) {
                this.config[opt.name] = null;
            }
        })
    }

    getConfigurationOptions() {
        return this.configuration_options.filter(o => this.include_options.includes(o.name));
    }

}
