<div class="page traveler quick-charts">

  <div class="row justify-center quick-charts" style="padding:0 4%;">

    <mat-form-field *ngIf="ready" class="bordered-select form-ctrl-margin-right fixed-height">
      <mat-label>Chart Type</mat-label>
      <ng-container>
        <mat-select [(ngModel)]="chart_type" (selectionChange)="getConfig()" [compareWith]="compare"
                    class="form-mat-select">
          <mat-option *ngFor="let type of chart_types" [value]="type">
            {{type.name}}
          </mat-option>
        </mat-select>
      </ng-container>
    </mat-form-field>

      <select-many-series [(value)]="selected_series"
                          (selectionChange)="selected_series = $event;getConfig();"
                          class="form-ctrl-margin-right"
      >
      </select-many-series>

    <div (cdkDropListDropped)="drop($event)" *ngIf="ready" cdkDropList class="drop-list">
      <ng-container *ngIf="selected_series">
        <div *ngFor="let option of selected_series, let i = index" cdkDrag class="drop-box">
          <div style="display: flex; align-items: center;">
            <series-edit-button (seriesUpdated)="updateSeries($event, option)"
                                [panelClass]="'quick-charts'" [series_id]="option.id"
                                [group]="tabGroups.OPERATIONS">
            </series-edit-button>
            {{option.attributes|nameOrDescription}}
          </div>
          <i class="fa fa-times" (click)="removeSeries(option)"
             style="cursor: pointer;font-size: 1.33em;margin: 0 10px;color:#666666;"></i>
        </div>
      </ng-container>
    </div>
      <div *ngIf="ready">
          <ng-container *ngIf="selected_series && selected_series.length > 0" >
            <ng-container *ngIf="appScope.isUserAdminOnCurrentAccount(appScope.active_account_id)" >
                <wire-button class="fullwidth button-margin" [btnContent]="'Queue Aggregation'"
                             [btnStyle]="'btn-default'"
                             (buttonClicked)="queueAggregations()">
                </wire-button>
            </ng-container>
          </ng-container>
    </div>

  </div>


  <div class="column quick-charts">
    <ng-container *ngIf="!['Multi Line','Multi Area','Multi Bar', 'Pie'].includes(chart_type.name)">
      <ng-container *ngFor="let series of selected_series; let j = index;">
        <div *ngIf="tile_configs[series.id]">
          <h2>{{series.attributes|nameOrDescription}}</h2>
          <page-tile
              [dtp]="dtp.dtp"
              [tile]="tile_configs[series.id]"
              [id]="j"
              class="page-tile no-format off-dashboard">
          </page-tile>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="['Multi Line','Multi Area','Multi Bar', 'Pie'].includes(chart_type.name)">
      <div *ngFor="let config of [configs];"><!--to force refresh-->
        <h2>{{selected_series_names}}</h2>
        <page-tile
            [dtp]="dtp.dtp"
            [tile]="tile_configs"
            class="page-tile no-format off-dashboard">
        </page-tile>
      </div>
    </ng-container>

  </div>
</div>
