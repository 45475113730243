import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {User} from "../_models/users";
import {ApiService} from "../services/api/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {SingleResponse} from '../services/api/response-types';
import {MatTabGroup} from "@angular/material/tabs";
import {ActivatedRoute} from "@angular/router";
import {take, tap} from "rxjs/operators";
import {NotificationService} from "../services/notification.service";

@Component({
    selector: 'my-security',
    templateUrl: 'my-security.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MySecurityComponent implements OnInit {
    days: number;
    show_otp: boolean;
    auth_token: any;
    errors: any[];
    user: any;

    @ViewChild('tab_group') tabGroup: MatTabGroup;

    constructor(private http: HttpClient,
                public appScope: AppScope,
                private headerData: HeaderDataService,
                private api: ApiService,
                private notification: NotificationService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        const ctrl = this;
        this.headerData.title = 'My Security';
        this.appScope.no_header = false;
        this.days = 90;
        this.fetchUser();
    }

    ngAfterViewInit() {
        let tabName = this.activatedRoute.snapshot.queryParams['tab'];
        this.tabGroup.selectedIndex = this.tabGroup._tabs.toArray().findIndex(t => t.textLabel === tabName);
    }

    fetchUser() {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'eq', val: this.appScope.current_user.id}];
        this.appScope.auth_complete.promise.then(() => {
            this.api.users.searchSingle(options).pipe(take(1)).subscribe((response: SingleResponse<User>) => {
                this.user = response.data;
            });
        });
    }

    checkedChanged(event, require_otp_value) {
        event.preventDefault();
        let conf;
        if (!this.user.attributes.require_otp) {
            conf = confirm("Are you sure you want to enable Two Factor Authentication? " +
                "Only your administrator will be able to undo this change.");
            if (conf) {
                this.show_otp = true;
                this.enable_my_2fa();
            }
        } else {
            if (this.checkDisableOTP()) {
                this.notification.openError("User does not have rights to disable 2FA");
                return;
            }
            this.user.attributes.require_otp = require_otp_value
            this.updateUser();
        }
    }

    /**
     * Should the user be prevented from disabling their OTP
     */
    checkDisableOTP() {
        if (this.user.is_super === true || this.user.attributes.role_names.includes("Administrator") ||
            this.user.attributes.role_names.includes("Super_User")) {
            return false; // enable
        }
        return this.user.attributes.require_otp === true;
    }

    enable_my_2fa() {
        if (!this.user.attributes.require_otp) {
            this.http.post('/auth/enable_2fa', {}).pipe(tap(
                response => {
                    this.notification.openSuccess(response['message']);
                    this.fetchUser();
                }
            )).subscribe();
        }
    }

    updateUser() {
        if (this.user.relationships.changed_by) {
            delete this.user.relationships.changed_by;
        }
        if (this.user.relationships.created_by) {
            delete this.user.relationships.created_by;
        }
        this.api.users.obsPatch(this.user).pipe(tap({
            next: () => {
                this.appScope.current_user.require_otp = true;
        }, error: error => {
            console.log('Failed to update user');
        }})).subscribe();
    }

    resetOTPSeed() {
        const ctrl = this;
        this.show_otp = false;
        this.http.get('/auth/reset_otp_seed').pipe(tap({
            next: response => {
                this.show_otp = false;
                console.log('reset_otp success', response);
            }, error: response => {
            console.log('reset_otp failure', response);
        }})).subscribe();
    }

    generateToken() {
        this.http.get('/auth/token/' + this.appScope.current_user.id + '/' + this.days * 24 * 3600).pipe(tap((response: any) => {
            this.appScope.current_user.auth_token_active = true;
            this.auth_token = response.token;
        })).subscribe();
    }

    deactivateToken() {
        const ctrl = this;
        this.http.get('/auth/deactivate_token/' + this.appScope.current_user.id).pipe(tap({
            next: response => {
                this.appScope.current_user.auth_token_active = false;
                console.log('deactivate auth token success', response);
        }, error: response => {
            console.log('deactivate auth token failure', response);
        }})).subscribe();
    }
}
