<div class="pdf-render-content">
    <div class="header-logo-row">
        <div class="header-img">
            <img class="client-logo" src='/admin/myfiles/download/right-logo.png'/>
        </div>
        <div class="title-row">
          <h1>{{pdfHeader}}</h1>
          <div *ngIf="dateInst.dtp" class="print-header-dtp">Production period: from
            <b>{{dateInst.dtp.start | stringDate : {date_only: true} }}</b>
            at <b>{{dateInst.dtp.start | date : 'HH:mm'}}</b>
            to <b>{{dateInst.dtp.end | stringDate : {date_only: true} }}</b>
            at <b>{{dateInst.dtp.end | date : 'HH:mm'}}</b>
          </div>
        </div>
        <div class="header-img">
            <img src="/static/images/wire-logo-black.png"/>
        </div>
    </div>

</div>
