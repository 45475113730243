import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {GenericChartTileConfiguration} from "../../charts/chart-config/generic-chart-tile.configuration";
import {
    ChartTypeArray,
    ChartTypeSeriesChartTypeDict,
    RoundCharts,
    styleKeys
} from "../../charts/chart-config/chart-configuration";
import {BaseComponent} from '../../shared/base.component';
import {RarChartSeriesConfiguration} from "../../charts/chart-config/chart-series.configuration";

@Component({
    selector: 'custom-chart-form',
    templateUrl: 'custom-chart-form.component.html',
    styleUrls: ['custom-chart-form-series-table/custom-chart-form-series-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomChart extends BaseComponent {
    public ready: boolean = false;

    @Input()
    config: GenericChartTileConfiguration;
    chart_type: string;
    chart_types = ChartTypeArray;
    style_keys = styleKeys;
    col_menu_options: string[] = ['size', 'colour'];
    legend_positions = ['bottom', 'right'];
    round_charts = RoundCharts;
    labelOrientationOptions: number[] = [0, 45, 90, -90, -45];
    dateFormatOptions = 0

    constructor(
        public api: ApiService,
        public dateTimePeriodService: DateTimePeriodService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        const ctrl = this;
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            if (ctrl.config.labels === undefined) {
                ctrl.config = GenericChartTileConfiguration.newCustomChartConfig();
            } else {
                if (this.config && this.config.series_list) {
                    this.checkChartType()
                }
            }
            //TODO data migration
            if (this.config.set_range === true) {
                if (this.config.y_min || this.config.y_min == 0) {
                    this.config.y_min_set = true;
                }
                if (this.config.y_max || this.config.y_max == 0) {
                    this.config.y_max_set = true;
                }
                this
                if (this.config.y2_min || this.config.y2_min == 0) {
                    this.config.y2_min_set = true;
                }
                if (this.config.y2_max || this.config.y2_max == 0) {
                    this.config.y2_max_set = true;
                }
                this.config.set_range = false;
            }
            if (!this.config.styles) {
                this.config.styles = {};
            }
            this.changeDetectorRef.markForCheck();
            ctrl.ready = true;
        });
    }

    updateChartType(event) {
        if (this.config.chart_type === 'rar') {
            this.config.series_list = [];
        }
        if (this.config.chart_type === 'stacked bar') {
            this.config.series_list.forEach(sc => sc.type = 'bar');
        }
        if (['gauge', 'donut', 'pie'].includes(this.config.chart_type)) {
            this.config.series_list.forEach(sc => sc.type = this.config.chart_type);
        }
        if (this.config.chart_type === 'custom') {
            this.config.series_list.forEach(sc => {
                if (!Object.keys(ChartTypeSeriesChartTypeDict['custom']).includes(sc.type)) {
                    sc.type = 'line';
                }
            });
        }
    }

    updateAxes(event) {
        if (event.checked === true) {
            this.config.hide_legend = true;
            this.config.hide_tick_labels = true;
        }
        this.changeDetectorRef.markForCheck();
    }

    checkChartType() {
        if (!this.config.chart_type) {
            this.config.chart_type = 'custom';
        }
        this.config.series_list.map(series_config => {
            if (['gauge', 'donut', 'pie'].includes(series_config.type)) {
                this.config.chart_type = series_config.type;
                return;
            }
        })
    }

    setShowAllXTicks(event) {
        if (event.checked) {
            if (!this.config.x_label_rotation) {
                this.config.x_label_rotation = -90;
                this.config.x_label_nowrap = true;
            }
        }
    }

    resetDecimals(event) {
        if (event.checked) {
            this.config.y_decimals = null;
        }
        this.changeDetectorRef.markForCheck();
    }

}
