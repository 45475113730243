<table *ngIf="series && series.view_on_flowchart()"
       class= 'series-rect'
       [ngStyle]="{'background':series.background(), 'color':series.text_colour(),'font-size.px':series.font_size()}"
       [ngClass]="{'selected-series-rect': series.selected()}">
    <tr>
        <td [ngStyle]="{'border-color':series.borders(),'padding':series.padding()}"
            *ngIf="series.data.type==='series' && series.show_status()===true" (dblclick)="void()">
            <span class="fa flag flag--status"
                  [ngClass]="{'fa-check flag--status-fine': series.status() === 1,
            'fa-warning flag--status-warn': series.status() === 2,
            'fa-warning flag--status-action': series.status() === 3}"></span>
        </td>
        <td style="text-align:right;"
            [ngStyle]="{'border-color':series.borders(),'style.padding':series.padding()}"
            class="series-value">
            {{series.display()}}
        </td>
        <td class="edit-series-component" [ngStyle]="{'border-color':series.borders(),'padding':series.padding()}">
            <inline-style-menu class="menu-component"
                               [value_component]="series.data"
                               [tree_position]="series.tree_position">
            </inline-style-menu>
        </td>
    </tr>
</table>

<table *ngIf="group && group.view_on_flowchart" class="series-group" [ngClass]="{'selected-group':groups_selected[group.name]}">
    <ng-container *ngFor="let series of group.series">
    <tr
        *ngIf="series.view_on_flowchart() === true"
        [ngStyle]="{'background':series.background(), 'color':series.text_colour(),'font-size.px':series.font_size()}"
        [ngClass]="{'selected-series-rect' :series.selected()}"
    >

         <td [ngStyle]="{'border-color':series.borders(),'padding':series.padding()}"
            *ngIf="group.show_status===true">
            <span class="fa flag flag--status"
                  [ngClass]="{'fa-check flag--status-fine': series.status() === 1,'fa-warning flag--status-warn': series.status() === 2,
            'fa-warning flag--status-action': series.status() === 3}"></span>
        </td>
        <td style="text-align:right;"
            [ngStyle]="{'border-color':series.borders(),'style.padding':series.padding()}"
            class="series-value">
            {{series.display()}}
        </td>
        <td class="edit-series-component" [ngStyle]="{'border-color':series.borders(),'padding':series.padding()}">
            <inline-style-menu class="menu-component"
                               [value_component]="series.data"
                               [tree_position]="series.tree_position">
            </inline-style-menu>
        </td>
    </tr>
    </ng-container>
</table>
