import {Component, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {ConfigStub} from "../../_typing/config-stub";
import {FormControl} from '@angular/forms';
import {moment} from "../../services/timezone-selector.service";
import {ConstantProperty} from '../../_models/constant-property';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {IDMap, KeyMap, ModelID} from '../../_typing/generic-types';
import {getManyRelationWithIdFilter, getBaseFilter} from "../../services/api/filter_utils";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {
    ComponentFormConfig,
    FormulaPart,
    FormulaPartType,
    ComponentTypeJsonConfig, NameFormula
} from "../../_typing/config/component-form-config";
import {BaseComponent} from "../../shared/base.component";
import {ComponentNameFormulaBuilderService} from "../../services/component-name-formula-builder.service";
import {BeforeSaveAction} from "../../_typing/utils/before-save";
import {ComponentType} from '../../_models/component-type';
import {getPropertyIds} from "../../tables/custom-events-table/utils";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {take, takeUntil} from "rxjs";
import {deepCopy} from '../../lib/utils';


@Component({
    selector: 'component-name-formula-builder',
    templateUrl: './component-name-formula-builder.component.html',
    styleUrls: ['./component-name-formula-builder.component.less'],
    standalone: false
})
export class ComponentNameFormulaBuilderComponent extends BaseComponent {
    private _config: ComponentTypeJsonConfig;
    @Input() componentType: ConfigStub<ComponentType>;

    @Input() set config(config: ComponentTypeJsonConfig) {
        if (!config) config = {};
        this._config = config;
        this.loadForm();
    };

    get config(): ComponentTypeJsonConfig {
        return this._config;
    }

    formulaParts: FormulaPart[] = [];
    dateFormat: string = 'DDMMYYYY';
    selectedConstantProperty: ConstantProperty;
    cpDict: IDMap<ConstantProperty> = {};
    selectedSeparator: string = "_"
    availableSeparators: { symbol: string; title: string }[] = [
        {symbol: ' ', title: 'space'},
        {symbol: '_', title: 'underscore'},
        {symbol: '-', title: 'dash'},
        {symbol: '.', title: 'point'}
    ];
    predefinedDateFormats = ['JULIAN', 'DDMMYY', 'DDMMYYYY', 'YYMMDD', 'YYYYMMDD', 'YYYYMM', 'YYMM', 'MDYY', 'DMYY', 'YYMD'];
    customDateFormat = new FormControl('', [this.momentDateFormatValidator]);
    listOrientation = 'horizontal';

    constantPropertyFilter: any[];
    initFormula: KeyMap<NameFormula>;
    selectedKey: number = 0;

    @Output() configChanged: EventEmitter<ComponentTypeJsonConfig> = new EventEmitter();

    constructor(private propertyData: ConstantPropertyDataService,
                private formulaBuilder: ComponentNameFormulaBuilderService,
                private cdr: ChangeDetectorRef) {
        super();
    }

    loadForm(): void {
        this.constantPropertyFilter = [getManyRelationWithIdFilter('component_types', this.componentType.id),
            {
                or: [getBaseFilter(false, 'is_calculation', 'eq'),
                    getBaseFilter(null, 'is_calculation', 'eq')]
            },
            getBaseFilter('file', 'data_type', 'ne')
        ];
        this.config.name_formulas = this.config.name_formulas || {};
        this.initFormula = deepCopy(this.config.name_formulas);
        this.getProperties();
    }

    formulaSelected(i: number) {
        this.selectedKey = i;
        this.formulaParts = this.config.name_formulas[this.selectedKey] || [];
        this.cdr.markForCheck();
    }

    formulaDeleted(i: number) {
        if (i === this.selectedKey) {
            this.formulaParts = [];
        }
    }

    addFormula() {
        const key = this.getNextKey();
        this.config.name_formulas[key] = [];
        this.formulaSelected(key);
        this.cdr.markForCheck();
    }

    reset(): void {
        this.formulaParts = this.initFormula[this.selectedKey] || [];
        this.emitChange();
    }

    private getProperties(): void {
        this.formulaBuilder.getProperties(this.config.name_formulas)
            .subscribe(result => {
                result.data.forEach(cp => this.cpDict[cp.id] = cp);
                this.cpDict = Object.assign({}, this.cpDict);
            })
    }

    private addToFormula(type: FormulaPartType, content: string): void {
        this.formulaParts.push({type: type, content: content});
        this.updateConfig();
    }

    addDateToFormula(format: string): void {
        this.addToFormula('date', format);
    }

    momentDateFormatValidator(control: FormControl): { [key: string]: any } | null {
        try {
            moment().format(control.value);
            return null;
        } catch (error) {
            return {'invalidDateFormat': true};
        }
    }

    addSeparatorToFormula(separator: string): void {
        this.addToFormula('separator', separator);
    }

    addPropertyToFormula(value: ConstantProperty): void {
        this.addToFormula('constant_property', value.id);
        this.cpDict[value.id] = value;
        this.cdr.markForCheck();
    }

    addTextToFormula(text: string): void {
        this.addToFormula('text', text);
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.formulaParts, event.previousIndex, event.currentIndex);
        this.updateConfig();
    }

    removeItem(index: number): void {
        this.formulaParts.splice(index, 1);
        this.updateConfig();
        this.selectedKey = this.selectDefault();
    }

    formulaString(formula: FormulaPart[]): string {
        return this.formulaBuilder.getFormulaString(formula);
    }

    private emitChange(): void {
        this.configChanged.emit(this.config);
    }

    private updateConfig(): void {
        this.config.name_formulas[this.selectedKey] = this.formulaParts;
        this.emitChange();
    }

    private selectDefault():number{
        const keys: number[] = Object.keys(this.config.name_formulas)?.map(k => Number(k));
        return keys?.length ? Math.min(...keys) : 0;
    }

    getNextKey(): number {
        const keys: number[] = Object.keys(this.config.name_formulas)?.map(k => Number(k));
        return keys?.length ? Math.max(...keys) + 1 : 0;
    }
}
