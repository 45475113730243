<div class="form-dialog event-type-form-dialog">
  <h1 cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane"><span
      [textContent]="solverTemplate && solverTemplate.id ? 'Edit component type: ' + solverTemplate.attributes.name :  'Add component type'"></span>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <!-- Basic Tab -->
      <mat-tab label="Basic" id="basic-tab">
        <solver-runner [solverTemplate]="solverTemplate"></solver-runner>
      </mat-tab>
    </mat-tab-group>
    <date-time-period [immutable]="true" [no_url]="true" class="date-period inline align-bottom"></date-time-period>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
  </div>
</div>
