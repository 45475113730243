import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {GenericChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {
    PlotlyConfigConfiguration,
    PlotlyDataConfiguration,
    PlotlyLayoutConfiguration
} from "../../services/plotly-chart-config-translation.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {take, takeUntil} from "rxjs/operators";
import {BaseComponent} from "../../shared/base.component";
import {BarChartService} from "./bar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.less'],
    providers: [BarChartService],
    standalone: false
})
export class BarChartComponent extends BaseComponent {
    @Input('config') tile_config: GenericChartTileConfiguration;

    data: PlotlyDataConfiguration[];
    layout: PlotlyLayoutConfiguration;
    config: PlotlyConfigConfiguration;

    @ViewChild('plotly_container') plotly_container: ElementRef<HTMLDivElement>;

    constructor(private cps: BarChartService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
        super();
    }

    ngOnInit(): void {
        this.cps.$bar_config_ready.pipe(takeUntil(this.onDestroy))
            .subscribe(chart_config => {
                this.data = chart_config.data;
                this.layout = chart_config.layout;
                this.config = chart_config.config;
            })

        if (this.plotly_container?.nativeElement.clientHeight) {
            this.tile_config.set_size = {height: this.plotly_container.nativeElement.clientHeight}
        }

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.cps.getChart(this.tile_config);
            })

        this.dateInst.dateTimePeriodChanged$.pipe(take(1))
            .subscribe(dtp => {
                this.cps.getChart(this.tile_config);
            })

    }

}
