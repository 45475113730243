import {Component, OnDestroy, OnInit} from "@angular/core";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {ApiService} from "../../services/api/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Subject} from "rxjs";
import {Tile as TileModel} from "../../_models/tile";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'data-exceptions-table-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="sheet_ready" [dtp]="dateInst.dtp" [tile]="tile" class="column-tile no-format"></page-tile>' +
        '</div>',
    standalone: false
})
export class DataExceptionsTableViewComponent implements OnInit, OnDestroy {
    tile: TileModel;
    sheet_ready: boolean;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.tile = new TileModel();
        ctrl.tile.attributes.content = 'data-exceptions-table';
        ctrl.tile.attributes.hide_edit = true;
        ctrl.tile.attributes.parameters = {};
        ctrl.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dateInst.dtp = this.dateTimePeriodService.setDefault(this.dateInst.dtp);
            ctrl.buildHeader();
            this.sheet_ready = true;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Data Exceptions Table';
        ctrl.headerData.show_dtp = true;

    };

}
