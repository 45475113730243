import {Injectable, OnDestroy} from '@angular/core';
import {Series} from '../../_models/series';
import {ApiService} from '../../services/api/api.service';
import {first, map, switchMap, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {SingleResponse} from '../../services/api/response-types';
import {ConstantProperty} from '../../_models/constant-property';
import {ConstantPropertyComponentType} from '../../_models/constant-property-component-type';
import {ComponentType} from '../../_models/component-type';
import {EventType} from '../../_models/event-type';
import {ConstantPropertyEventType} from '../../_models/constant-property-event-type';

export interface CollationSeriesData {
    component_type?: ComponentType;
    event_type?: EventType;
    constant_property: ConstantProperty;
    time_constant_property?: ConstantProperty
}

@Injectable()
export class CollationSeriesTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    $collationDataSubject: Subject<CollationSeriesData> = new Subject<CollationSeriesData>();
    $collationData = this.$collationDataSubject.asObservable();

    constructor(private api: ApiService) {
    }

    getCollationSeries(series: Series) {
        let cccse_id;
        let eccse_id;
        if (series.relationships?.component_constant_collation_series_export?.data?.id) {
            cccse_id = series.relationships.component_constant_collation_series_export.data.id;
        }
        if (series.relationships?.event_constant_collation_series_export?.data?.id) {
            eccse_id = series.relationships.event_constant_collation_series_export.data.id;
        }
        if (cccse_id) {
            this.getComponentConstantCollation(cccse_id).pipe(first(), takeUntil(this.onDestroy))
                .subscribe((row: CollationSeriesData) => {
                    this.$collationDataSubject.next(row);
                })
        } else if (eccse_id) {
            this.getEventConstantCollation(eccse_id).pipe(first(), takeUntil(this.onDestroy))
                .subscribe((row: CollationSeriesData) => {
                    this.$collationDataSubject.next(row);
                })
        }
    }

    getComponentConstantCollation(cccse_id): Observable<CollationSeriesData> {

        return this.api.component_constant_collation_series_export.getById(cccse_id).pipe(
            switchMap(cccse_res => {
                const cp_ct_id = cccse_res.data.relationships.constant_property_component_type.data.id;
                const cp_time_id = cccse_res.data.relationships.time_constant_property.data?.id;
                const $cp_ct = this.api.constant_property_component_type.getById(cp_ct_id);
                const $cp_time = cp_time_id ? this.api.constant_property_light.getById(cp_time_id) : of(null);
                return forkJoin([$cp_ct, $cp_time]);
            }),
            switchMap(([cpct_res, cp_time]: [SingleResponse<ConstantPropertyComponentType>, SingleResponse<ConstantProperty>]) => {
                    const cp_id = cpct_res.data.relationships.constant_property.data.id;
                    const ct_id = cpct_res.data.relationships.component_type.data.id;
                    const $cp = this.api.constant_property_light.getById(cp_id);
                    const $ct = this.api.component_type.getById(ct_id);
                    return forkJoin([$cp, $ct]).pipe(
                        map(([cp, ct]: [SingleResponse<ConstantProperty>, SingleResponse<ComponentType>]) => {
                            let row: CollationSeriesData = {
                                component_type: ct.data,
                                event_type: null,
                                constant_property: cp.data,
                                time_constant_property: cp_time?.data,
                            };
                            return row;
                        })
                    )
                }
            )
        )
    }

    getEventConstantCollation(eccse_id): Observable<CollationSeriesData> {

        return this.api.event_constant_collation_series_export.getById(eccse_id).pipe(
            switchMap(eccse_res => {
                const cp_et_id = eccse_res.data.relationships.constant_property_event_type.data.id;
                const cp_time_id = eccse_res.data.relationships.time_constant_property.data?.id;
                const $cp_et = this.api.constant_property_event_type.getById(cp_et_id);
                const $cp_time = cp_time_id ? this.api.constant_property_light.getById(cp_time_id) : of(null);
                return forkJoin([$cp_et, $cp_time]);
            }),
            switchMap(([cpet_res, cp_time]: [SingleResponse<ConstantPropertyEventType>, SingleResponse<ConstantProperty>]) => {
                    const cp_id = cpet_res.data.relationships.constant_property.data.id;
                    const et_id = cpet_res.data.relationships.event_type.data.id;
                    const $cp = this.api.constant_property_light.getById(cp_id);
                    const $et = this.api.event_type.getById(et_id);
                    return forkJoin([$cp, $et]).pipe(
                        map(([cp, et]: [SingleResponse<ConstantProperty>, SingleResponse<EventType>]) => {
                            let row: CollationSeriesData = {
                                component_type: null,
                                event_type: et.data,
                                constant_property: cp.data,
                                time_constant_property: cp_time?.data,
                            };
                            return row;
                        })
                    )
                }
            )
        )
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
