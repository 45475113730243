import {Injectable} from '@angular/core';
import {IDMap, KeyMap} from "../../_typing/generic-types";
import {Series} from "../../_models/series";
import {NotificationService} from "../../services/notification.service";
import {ConfigStub} from "../../_typing/config-stub";
import {ComponentType} from "../../_models/component-type";
import {snakeCase} from "lodash-es";
import {ReportParameters} from "./type/report-parameters";
import {ConfigColumn, EventConfigColumn} from "../../_typing/config/config-column";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import type {IFilterEmitter} from "../../components/event-column-selector/type/selector-emitter";
import {REPORT_COLUMN_FILTER_TRIM_LENGTH} from "../../shared/globals";

@Injectable({
    providedIn: 'root'
})
export class JsonContextService {

    private configSubject = new BehaviorSubject<ReportParameters>(new ReportParameters());
    config$: Observable<ReportParameters> = this.configSubject.asObservable();

    constructor(private notification: NotificationService) {
    }

    configContextSubjectChanged(config: ReportParameters, context: any) {
        const updatedContext = JSON.stringify(context, null, 2);
        if (updatedContext !== config.jsonContext) {
            this.configSubject.next(new ReportParameters({
                ...config,
                jsonContext: updatedContext
            }));
        }
    }

    configSubjectChanged(config: ReportParameters) {
        this.configSubject.next(config);
    }

    getAlias(name: string): string {
        return snakeCase(name);
    }

    updateAlias(value: string) {
        value = value.trim();
        if (!/^[a-z0-9]+(_[a-z0-9]+)*$/.test(value)) {
            value = this.getAlias(value); // Convert to snake case if not already
        }
        return value.trim();
    }

    getJsonContext(config: ReportParameters) {
        config.jsonContext = "{}";
        try {
            this.updateComponentTypeContext(config);
            this.updateRelationshipContext(this.configSubject.value);
            this.updatePropertyContext(this.configSubject.value);
            this.updateAllRelationshipPropertyContext(this.configSubject.value);
            this.updatePropertyFilters(this.configSubject.value);
            this.updateAllRelationshipPropertyFilters(this.configSubject.value);
            this.updateSeriesJsonContext(this.configSubject.value);
            this.updateFunctionJsonContext(this.configSubject.value);
        } catch (e) {
            this.notification.openError(`There was a problem updating the context for this report ${e}`)
            console.log("JSON error", e)
        }

        return this.configSubject.value;
    }

    mergeContext(config: ReportParameters, newContext: object) {
        let context = JSON.parse(config.jsonContext);
        context = {...context, ...newContext};
        this.configContextSubjectChanged(config, context);
    }

    updateComponentTypeContext(config: ReportParameters) {
        if (!config.selectedComponentType?.id) return;
        const {id, attributes: {name}} = config.selectedComponentType;
        this.mergeContext(config, {
            component_type_id: id,
            type_alias: this.getAlias(name),
            attributes: {name: this.getAlias(name)}
        });
    }

    updateRelationshipContext(config: ReportParameters) {
        const componentTypes = config.selectedComponentRelationshipTypes?.map(({id, attributes: {name}}) => ({
            component_type_id: id,
            type_alias: this.getAlias(name),
            attributes: {name: this.getAlias(name)}
        }));
        this.mergeContext(config, {
            component_types: componentTypes
        });
    }

    updatePropertyContext(config: ReportParameters, aliasDict?: IDMap<string>): void {
        let context = JSON.parse(config.jsonContext);
        context['properties'] = Object.fromEntries(
            config.selectedColumns?.componentType?.map(column => {
                const alias = aliasDict?.[column.id] || this.getAlias(column.title);
                return [column.id, alias];
            })
        );
        this.configContextSubjectChanged(config, context);
    }

    resetContextTypeContext(config: ReportParameters) {
        let context: Object;
        try {
            context = JSON.parse(config.jsonContext);
            delete context['component_types'];
            delete context['properties'];
            delete context['filters'];
        } catch (e) {
            context = {};
        }
        this.configContextSubjectChanged(config, context);
    }

    updateAllRelationshipPropertyContext(config: ReportParameters): void {
        config.selectedComponentRelationshipTypes?.forEach(ct => {
            this._updateRelationshipPropertyContext(config, ct.id);
        })
    }

    private _updateRelationshipPropertyContext(config: ReportParameters, componentTypeId: string, aliasDict?: IDMap<string>): void {
        let context = JSON.parse(config.jsonContext);
        context['component_types'].map(componentType => {
            if (componentType.component_type_id === componentTypeId) {
                componentType.properties = config.selectedColumns.component[componentTypeId]
                    .reduce((accumulator, column: EventConfigColumn) => {
                        accumulator[column.id] = aliasDict?.[column.id] || this.getAlias(column.title);
                        return accumulator;
                    }, {});
            }
        });
        // Convert the context object back to a JSON string with indentation
        this.configContextSubjectChanged(config, context);
    }

    updatePropertyFilters(config: ReportParameters): void {
        let context = JSON.parse(config.jsonContext);
        context['filters'] = this._getColumnsWithFilters(config.selectedColumns?.componentType);

        this.configContextSubjectChanged(config, context);
    }

    private _getColumnsWithFilters(columnArray): KeyMap<string[]> {
        return columnArray?.reduce((accumulator: KeyMap<IFilterEmitter[]>, column: EventConfigColumn) => {
            if (column.filters?.length) {
                accumulator[column.id] = column.filters?.map(value => ({
                    filterAlias: this.getAlias(value).substring(0, REPORT_COLUMN_FILTER_TRIM_LENGTH),
                    filterValue: value
                }))
            }
            return accumulator;
        }, {});
    }

    updateAllRelationshipPropertyFilters(config: ReportParameters): void {
        config.selectedComponentRelationshipTypes?.forEach(ct => {
            this._updateRelationshipPropertyFilters(config, ct.id);
        })
    }

    private _updateRelationshipPropertyFilters(config: ReportParameters, componentTypeId: string): void {
        let context = JSON.parse(config.jsonContext);
        context['component_types'].map(componentType => {
            if (componentType.component_type_id === componentTypeId) {
                componentType.filters = this._getColumnsWithFilters(config.selectedColumns.component[componentTypeId]);

            }
        });

        this.configContextSubjectChanged(config, context);
    }

    updateSeriesJsonContext(config: ReportParameters): any {
        if (!config.series_column_dict) return;
        let seriesContext = {};
        let seriesDict: IDMap<ConfigStub<Series>> = {};
        config.series?.forEach(s => seriesDict[s.id] = s);
        Object.entries(config.series_column_dict).forEach(([id, columns]: [string, string[]]) => {
            const seriesName = seriesDict[id]?.attributes?.name;
            let colArray = '@' + columns.join('@');
            let alias = seriesName.toLowerCase();
            if (columns?.length) {
                seriesContext[seriesName + colArray] = alias;
            }
        })
        this.mergeContext(config, {series: seriesContext});
    }

    updateFunctionJsonContext(config: ReportParameters): any {
        let functionContext = {}
        config.functions?.forEach(f => {
            const functionName = f.attributes?.name;
            let alias = this.getAlias(functionName);
            functionContext[functionName] = alias;
        })
        this.mergeContext(config, {functions: functionContext});
    }

}
