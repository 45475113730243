import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import {combineLatest, Observable, of, takeUntil} from "rxjs";
import {BaseComponent} from '../../shared/base.component';
import {IDMap, ModelID, KeyMap} from '../../_typing/generic-types';
import {SeriesDataService} from "../../services/series_data.service";
import {Series} from '../../_models/series';
import {catchError, map, take, tap} from 'rxjs/operators';
import {ListResponse} from '../../services/api/response-types';
import {NotificationService} from "../../services/notification.service";
import { HttpClient } from "@angular/common/http";
import {FormArray, FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors} from '@angular/forms';
import {FormDialogService} from "../../services/form-dialog.service";
import {
    SeriesCorrectionBase,
    SeriesCorrectionInputData,
    SeriesAdjustmentRequest, SeriesAdjustmentConfig,
    SeriesConstantCorrection
} from '../../_models/jobs/series-correction-job';
import {JobNotificationComponent} from '../../notifications/job-notification/job-notification.component';
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";

@Component({
    selector: 'series-adjustment',
    templateUrl: './series-adjustment.component.html',
    styleUrls: ['./series-adjustment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SeriesAdjustmentComponent extends BaseComponent {
    @Input() config: SeriesAdjustmentConfig;
    adjustmentRequest: Partial<SeriesCorrectionInputData> = {};
    seriesRequestList: SeriesAdjustmentRequest[] = [];
    seriesDict: IDMap<Series> = {};
    form: FormGroup;
    hasPermissions: boolean;
    seriesValues: Record<ModelID, number> = {};
    seriesFindCorrectionFactor: Record<ModelID, number> = {};

    constructor(private dateInst: DateTimeInstanceService,
                private seriesDataService: SeriesDataService,
                private http: HttpClient,
                private notification: NotificationService,
                private fb: FormBuilder,
                private formDialogService: FormDialogService,
                private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        const seriesIds: ModelID[] = this.config.adjustments
            .reduce((acc, seriesAdjustmentConfig) => {
                if (seriesAdjustmentConfig["target"] && !seriesAdjustmentConfig.target_series) {
                    seriesAdjustmentConfig.target_series = seriesAdjustmentConfig["target"];
                }
                return [...acc, seriesAdjustmentConfig.target_series.id, seriesAdjustmentConfig.adjust.id]
            }, []);
        const $series = this.seriesDataService.getSeriesListByIds(seriesIds).pipe(
            take(1), tap((seriesList: ListResponse<Series>) => {
                seriesList.data.forEach((series: Series) => this.seriesDict[series.id] = series);
            })
        );
        combineLatest([this.dateInst.dateTimePeriodChanged$, $series, this.getPermissions(seriesIds)]).pipe(
            takeUntil(this.onDestroy)
        ).subscribe(() => {
            this.setRequestDate();

            this.seriesRequestList = this.config.adjustments.map(adjustment =>
                this.seriesAdjustmentRequest(adjustment)
            );
            this.adjustmentRequest.series_to_adjust = this.seriesRequestList;

            this.createForm();
            if (!this.hasPermissions) {
                this.form.disable();
            }
            this.cdr.markForCheck();
        });
        this.dateInst.dateTimePeriodRefreshed$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(() => {
            this.setRequestDate();
            this.form.markAsDirty();
            this.cdr.markForCheck();
        });
    }

    private setRequestDate(): void {
        this.adjustmentRequest.start_time = this.dateInst.dtp.start.toISOString();
        this.adjustmentRequest.end_time = this.dateInst.dtp.end.toISOString();
    }

    private createForm(): void {
        this.form = this.fb.group({
            adjustments: this.fb.array([]),
            updateCalculations: [false]
        });

        this.seriesRequestList.forEach((data: SeriesAdjustmentRequest) => {
            this.addAdjustment(data);
        });
    }

    private seriesAdjustmentRequest(a): SeriesAdjustmentRequest {
        const base: Partial<SeriesCorrectionBase> = {
            series_id: a.adjust.id,
            comment: "",
            target_series_id: a.target_series.id
        };

        if (a.adjustment_type === 'constant') {
            return {
                ...base,
                adjustment_type: 'constant',
                value: null,
            } as SeriesAdjustmentRequest;
        } else if (a.adjustment_type === 'find_factor' || a.adjustment_type === 'factor') {
            a.adjustment_type = 'find_factor';
            return {
                ...base,
                adjustment_type: 'find_factor',
                target_value: null,
            } as SeriesAdjustmentRequest;
        }
    }

    get adjustments(): FormArray {
        return this.form.get('adjustments') as FormArray;
    }

    public submitTooltip(): string {
        const submitMessage = "Submit adjustments";
        const invalidMessage = "All inputs must be completed";
        const updateMessage = "Please update values before resubmitting";
        return this.form.pristine === true && this.form.status === "VALID" ? updateMessage : this.form.status === "VALID" ?
            submitMessage : invalidMessage;
    }

    private addAdjustment(data: SeriesAdjustmentRequest): void {
        const exists: boolean = this.adjustments.controls.some((control: FormGroup): boolean =>
            control.get('target_series_id').value === data.target_series_id
        );
        if (exists) {
            return;
        }
        this.seriesValues[data.target_series_id] = 0;
        this.getSeriesValue(data.target_series_id).subscribe((seriesValue: number): void => {
            this.seriesValues[data.target_series_id] = seriesValue;
            this.cdr.detectChanges()
        });
        const adjustmentGroup = this.fb.group({
            series_id: [data.series_id, Validators.required],
            target_series_id: [data.target_series_id, Validators.required],
            adjustment_type: [data.adjustment_type, Validators.required],
            comment: [data.comment],
            ...(data.adjustment_type === 'constant' ? { value: [(data as SeriesConstantCorrection).value, Validators.required] } : {}),
            ...(data.adjustment_type === 'find_factor' ? {
                target_value: [data.target_value, Validators.required],
            } : {})
    });
        this.adjustments.push(adjustmentGroup);
    }

    onSubmit(): void {
        if (!this.form.valid) {
            return;
        }
        this.adjustmentRequest.series_to_adjust = this.form.value.adjustments;
        this.adjustmentRequest.update_dependent_calculations = this.form.value.updateCalculations;
        this.http.post('/api/series/bulk-correction', this.adjustmentRequest)
            .pipe(map((data: { job_id: ModelID }): void => {
                this.form.markAsPristine();
                this.cdr.markForCheck();
                this.showJobSuccess(data.job_id);
            }), catchError(error => {
                console.log('SeriesAdjustmentComponent - : ', error);
                this.notification.openError(`Could not apply correction: ${JSON.stringify(error.error.validation_error.body_params)}`);
                return of(error);
            })).subscribe();
    }

    showJobSuccess(jobId: ModelID) {
        this.notification.openDynamicSnackbar(JobNotificationComponent, {jobId: jobId, jobType: "Correction", styleType: 'success'});
    }

    getSeriesValue(seriesId: ModelID): Observable<number> {
        const dateTimePeriod: IDateTimePeriod = this.dateInst.dtp;
        return this.seriesDataService.getSeriesSummary(dateTimePeriod, [seriesId], null, ['Value']).pipe(
            map(data => data[0]['Value']),
            take(1)
        );
    }

    setCorrectionFactor(): void {
        if (!this.form.valid) {
            return;
        }
        this.adjustments.controls.forEach(adjustment => {
            this.seriesFindCorrectionFactor[adjustment.get('target_series_id').value] = adjustment.get("target_value").value /
                this.seriesValues[adjustment.get('target_series_id').value];
        });
    }

    getPermissions(seriesIds: ModelID[]): Observable<any> {
        return this.seriesDataService.getSeriesPermissions(seriesIds).pipe(tap((permissions: KeyMap<string>) => {
            this.hasPermissions = Object.values(permissions).every((permission: string): boolean =>
                permission.includes("apply_correction_factor")
            );
        }));
    }

    openModal(event: any, adjustment: FormGroup) {
        const data_config = {
            component: 'CommentComponent',
            parameters: adjustment.value.comment
        };
        const setComment: (event) => void = (event) => {
            if (!event || event === adjustment.value.comment) { return; }
            adjustment.patchValue({'comment': event});
            this.cdr.markForCheck();
            this.form.markAsDirty();
        };
        const panelClass = 'comment-popup';
        const modalDialog = this.formDialogService.openCustomDialog(event, data_config, panelClass, setComment);
    }

    isFormChanged(): boolean {
        return this.form.dirty;
    }
}
