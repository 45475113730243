import {DEFAULT, LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";

export class SolverTemplateAttributes extends AuditMixinAttributes {
    name: string;
    description?: string;
    assumptions?: string;
    method?: string;
}

export class SolverTemplateRelationships extends AuditMixinRelationships {
    constraints: LIST_RELATIONSHIP_TYPE;
    error_series_type: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    adjusted_series_types: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    lower_bounds_series_type: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    upper_bounds_series_type: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class SolverTemplate extends AuditMixin {
    id: string; //number;
    type: string = 'solver_template';
    attributes: SolverTemplateAttributes;
    relationships: SolverTemplateRelationships;

    constructor() {
        super();
        this.attributes = new SolverTemplateAttributes();
        this.relationships = new SolverTemplateRelationships();
    }
}


export class ConstraintAttributes extends AuditMixinAttributes {
    name: string;
    description?: string;
    assumptions?: string;
    name_formula: string;
}


export class ConstraintRelationships extends AuditMixinRelationships {
    solver_templates: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
}


export class Constraint extends AuditMixin {
    id: string; //number;
    type: 'constraint';
    attributes: ConstraintAttributes;
    relationships: ConstraintRelationships;

    constructor() {
        super();
        this.type = 'constraint';
        this.attributes = new ConstraintAttributes();
        this.relationships = new ConstraintRelationships();
    }
}


export const solverMethods: string[] = ['Nelder-Mead', 'Powell', 'CG', 'BFGS', 'Newton-CG', 'L-BFGS-B', 'TNC', 'COBYLA',
    'SLSQP', 'trust-constr', 'dogleg', 'trust-ncg', 'trust-exact', 'trust-krylov'];
