import {Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, OnDestroy} from '@angular/core';
import {Series, SeriesSeriesTableData, SeriesSeriesData} from '../../_models/series';
import {SeriesDataService} from '../../services/series_data.service';
import {SeriesEstimateFormService} from "./series-estimate-form.service";
import {Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {SeriesType} from '../../_models/series-type';
import {WireComponent} from "../../_models/component";
import {ModelName} from '../../_typing/generic-types';
import {SeriesSeries} from "../../_models/series-series";
import {deepCopy} from '../../lib/utils';
import {UserService} from "../../services/user.service";

@Component({
    selector: 'series-estimate-form',
    templateUrl: './series-estimate-form.component.html',
    styleUrls: ['./series-estimate-form.component.less'],
    providers: [SeriesEstimateFormService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesEstimateFormComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() current_series: Series;
    @Input() component: WireComponent;
    source_series_series: SeriesSeries[];
    target_series_series: SeriesSeries[];
    target_data: SeriesSeriesData[];
    source_data: SeriesSeriesData[];
    series_types: SeriesType[];
    series_type_names: ModelName[];
    selected_series_type: SeriesType;
    can_edit = false;
    permissions: any;
    target_series_filter: any[];
    @Output() sourceSeriesChanged: EventEmitter<Series[]> = new EventEmitter();

    constructor(private seriesData: SeriesDataService,
                private cps: SeriesEstimateFormService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        this.target_series_filter = [{name: 'series_type', op: 'has', val: {name: 'id', op: 'isnot', val: null}}];
        this.seriesData.getSeriesPermissions([this.current_series.id]).subscribe(permissions => {
            this.permissions = permissions;
            this.can_edit = this.userService.canEditSeries(permissions, this.current_series.id);
        });
        this.seriesData.estimateTypesChanged.pipe(take(1)).subscribe(types => {
            this.series_types = types;
            this.getSeriesTypeNames();
        });

        this.cps.$targetData.pipe(takeUntil(this.onDestroy)).subscribe((data: SeriesSeriesTableData) => {
            this.target_data = deepCopy(data.series);
            this.target_series_series = data.series_series;
            this.getSeriesTypeNames();
        });
        this.cps.$sourceData.pipe(takeUntil(this.onDestroy)).subscribe((data: SeriesSeriesTableData) => {
            this.source_data = deepCopy(data.series);
            this.source_series_series = data.series_series;
        });
        this.refresh();
    }

    getSeriesTypeNames() {
        // Filter out series types for which targets already exist for the current_series
        if (this.target_data?.length) {
            this.series_types = this.series_types.filter(stn => {
                return !this.target_data.some(s => s.relationships.series_type.data?.id === stn.id);
            });
        }

        this.series_type_names = this.series_types.map(t => t.attributes.name);
        this.selected_series_type = this.series_types.find(t => t.attributes.name.toLowerCase() === 'forecast')
            || this.series_types[0];

        const target_type_ids = this.target_data?.map(s => s.relationships.series_type.data.id);
        this.target_series_filter = [{name: 'series_type', op: 'has', val: {name: 'id', op: 'isnot', val: null}}];
        if (target_type_ids) {
            this.target_series_filter.push({
                name: 'series_type',
                op: 'has',
                val: {name: 'id', op: 'notin_', val: target_type_ids}
            });
        }
    }

    refresh(): void {
        this.cps.getTargetSeries(this.current_series);
        this.cps.getSourceSeries(this.current_series);
    }

    setSeriesType(series_type_name: ModelName) {
        this.selected_series_type = this.series_types.find(t => t.attributes.name === series_type_name);
    }

    newTargetSeries() {
        this.cps.newTargetSeries(this.component, this.current_series, this.selected_series_type);
    }

    newSourceSeries() {
        this.cps.newSourceSeries(this.component, this.current_series, this.source_data, this.source_series_series);
    }

    saveSourceSeries() {
        this.cps.saveSeriesSeries(this.current_series, 'sources', this.source_data, this.source_series_series)
            .subscribe(() => this.cps.getSourceSeries(this.current_series));
    }

    saveTargetSeries() {
        this.cps.saveSeriesSeries(this.current_series, 'targets', this.target_data, this.target_series_series)
            .subscribe(() => this.cps.getTargetSeries(this.current_series));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
