import {BaseViewModel} from "./base-viewmodel";
import {ProcessViewModel} from "./process-viewmodel";

export class CustomChartViewModel extends BaseViewModel {
    index: number = 0;
    parent_component: ProcessViewModel;

    constructor(customChartData, process, index) {
        super(customChartData)

        //TODO fix this, 'custom_chart' should be .data.type not .type, for consistency with other view models,
        //could be an issue with interfering with the model?
        this.data.type = 'custom_chart';
        this.type = 'custom_chart';
        this.index = index;
        //TODO remove one
        this.parent_component = process;
        this.chart_parent_process = process;

        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }
    }

    height() {
        return 36;
    };

    width() {
        return 36;
    };

    title() {
        if (!this.data.labels.title) {
            return "No title"
        } else {
            return this.data.labels.title;
        }
    };

    x() {
        return this.data.x;
    };

    y() {
        return this.data.y;
    };

}
