import {Injectable, Injector} from '@angular/core';
import {
    WaterfallChartConfiguration,
    UnionChartConfiguration,
    RarChartConfiguration, SeriesChartType, isWaterfallChartConfig, BarChartConfiguration, isBarChartConfiguration
} from "../charts/chart-config/chart-configuration";
import {CategoryChartTileConfiguration} from '../forms/category-chart-form/category-chart-tile.configuration';
import {UnionChartTileConfiguration} from '../charts/chart-config/generic-chart-tile.configuration';
import * as utils from "../lib/utils";
import {PlotType, PlotMarker, Font, Margin} from 'plotly.js-dist-min';
import {uniqueList} from "../lib/utils";
import {Subject} from 'rxjs';
import {ModeBarButtonAny} from "plotly.js";

/**These types are for the object configuration that must be returned to Plotly to make a chart**/
export interface PlotlyDataConfiguration {
    name?: string;
    type: string;
    x: string[] | number[];
    y: string[];
    orientation?: string;
    marker?: Partial<PlotMarker>,
    measure?: string[];
    mode?: string;
    base?: number
    cumsum?: number[];
    connectgaps?: boolean[];
    textposition?: string;
    textangle?: number;
    insidetextanchor?: string;
    constraintext?: string;
    text?: string[];
    hovertext?: any;
    hovertemplate?: any;
    textfont?: Partial<Font>;
    texttemplate?: string;
    legendgroup?: string;
    line?: Partial<{ width: number, color: string, dash: string }>;
    showlegend?: boolean;
    connector?: {
        line?: {
            color?: string;
            width?: number;
        }
    }
    decreasing?: { marker?: { color?: string, line?: string } };
    increasing?: { marker?: { color?: string, line?: string } };
    totals?: { marker?: { color?: string, line?: string } };
    visible?: string;
}

export interface PlotlyAxisOptions {
    type?: string;
    title?: { text?: string, font?: Partial<Font> };
    tickfont?: Partial<Font>,
    categoryarray?: [string[], number[]] | [];
    dtick?: number;
    ticks?: string;
    tickpad?: number;
    tickvals?: string[];
    ticktext?: string[];
    tickmode?: string;
    tickprefix?: string; //Add text/space before the axis label
    tickformat?: string; //Number/date formats etc.
    ticksuffix?: string; //Add text/space after the axis label
    showticksuffix?: string;
    showticklabels?: boolean;
    showline?: boolean; //Show the main axis line
    zeroline?: boolean; //Needs to be set with showline to turn off the base line for the value axis
    showdividers?: boolean;
    margin?: number;
    nticks?: number; //Number of ticks to show
    domain?: number[];
    automargin?: boolean;
    range?: number[]; //Min and max axis values
    constrain?: string; //This seems to need to be set to 'range' for custom range to take effect
    autorange?: boolean;
    autoscale?: boolean;
}

export interface PlotlyLayoutConfiguration {
    annotations?: any;
    title?: {
        text?: string;
        font?: Partial<Font>;
    };
    xaxis?: PlotlyAxisOptions;
    yaxis?: PlotlyAxisOptions;
    autosize?: boolean;
    autoscale?: boolean;
    automargin?: boolean;
    barmode?: string;
    font?: Partial<Font>;
    height?: number;
    legend?: { orientation?: 'h' | 'v'; x?: number; xanchor?: string, y?: number, yanchor?: string, font?: Partial<Font>, traceorder?: 'reversed' | 'normal' };
    margin?: Partial<Margin>;
    pad?: number;
    showlegend?: boolean;
    shapes?: any[];
    waterfallgap?: number;
    waterfallgroupgap?: number;
    width?: number;
}

export interface PlotlyConfigConfiguration {
    displaylogo?: boolean;
    responsive?: boolean;
    scrollZoom?: boolean;
    staticPlot?: boolean;
    modeBarButtonsToAdd?: ModeBarButtonAny[];

}

export interface PlotlyChartConfiguration {
    data: Partial<PlotlyDataConfiguration>[];
    layout?: PlotlyLayoutConfiguration;
    config?: PlotlyConfigConfiguration
}

export interface IChartConfigTranslationService {
    configureChart(config: UnionChartConfiguration): Partial<PlotlyChartConfiguration>;
}

/**The public function in this service accept a configuration file, which includes both configuration options (from tile config)
 *  and data (from GSS) and translates them to a format usable by plotly.js
 */
@Injectable({
    providedIn: 'root'
})
export class PlotlyChartConfigTranslationService {
    public readonly site_font: string;

    constructor() {
        this.site_font = getComputedStyle(document.documentElement).getPropertyValue('--font-size') || '14';
    }

    configureBasePlotlyData(config: UnionChartConfiguration, x, y, key?): Partial<PlotlyDataConfiguration> {
        let data: Partial<PlotlyDataConfiguration> = {
            type: config.type,
            x: x,
            y: y,
            textposition: "outside",
            textfont: config.styles.data_font,
            text: config.text_values?.[key] || null,
            textangle: config.text_orientation?.[key] || 0,
            constraintext: config.styles.data_font > this.site_font ? 'none' : 'both'
        };
        if (key) {
            data.marker = {color: config.colours[key]}
        }
        return data;
    }

    configureBasePlotlyLayout(config: UnionChartConfiguration): Partial<PlotlyLayoutConfiguration> {
        let layout: Partial<PlotlyLayoutConfiguration> = {
            title: {
                text: config.labels?.title,
                font: config.styles.title_font
            },
            font: {
                family: 'Rajdhani'
            },
            autoscale: false,
            waterfallgap: 0.3,
            xaxis: {
                title: {
                    text: config.labels?.x_axis,
                    font: config.styles.x_axis_font
                },
                showline: !config.hidden?.axes,
                zeroline: !config.hidden?.axes,
                showticklabels: !config.hidden?.tick_labels,
                tickfont: config.styles.x_axis_font,
                tickformat: config.orientation === 'h' ? (config.y_axis_format || null) : null,
                tickvals: config.x_ticks?.values || null,
                ticktext: config.x_ticks?.text || null,
                dtick: config.orientation === 'h' ? config.tick_space : null,
                nticks: config.orientation === 'h' ? config.no_ticks : null,
                range: config.orientation === 'h' ? [config.y_min, config.y_max] : [null, null],
                constrain: 'range',
                automargin: true
            },
            yaxis: {
                type: config.y_axis_type,
                title: {
                    text: config.labels?.y_axis,
                    font: config.styles.y_axis_font
                },
                tickfont: config.styles.y_axis_font,
                tickformat: config.orientation === 'v' ? (config.y_axis_format || null) : null,
                showline: !config.hidden?.axes,
                zeroline: !config.hidden?.axes,
                showticksuffix: 'all',
                showticklabels: !config.hidden?.tick_labels,
                ticksuffix: ' ',
                dtick: config.orientation === 'v' ? config.tick_space : null,
                nticks: config.orientation === 'v' ? config.no_ticks : null,
                range: config.orientation === 'v' ? [config.y_min, config.y_max] : [null, null],
                constrain: 'range',
                automargin: true,
            },
            margin: {
                t: 40,
                b: 10
            },
            // pad: 0,
            automargin: false,
            autosize: true,
            showlegend: !config.hidden?.legend,

        }
        return layout;

    }

    configurePlotlyConfig(config: UnionChartConfiguration): PlotlyConfigConfiguration {
        let options: PlotlyConfigConfiguration = {
            displaylogo: false,
            responsive: true
        };
        return options;
    }

    getXValues(config) {
        let x = [];
        if (config.group_values) {
            x.push(config.group_values['single']);
            x.push(config.x_values['single']);
            return x;
        } else {
            return config.x_values['single'];
        }
    }

    translateChartTypes(series_chart_type: SeriesChartType | PlotType): PlotType {
        let type: PlotType = 'scatter';
        //series_chart_type as unknown as PlotType;
        switch (series_chart_type) {
            case "line":
                break;
            case "spc":
                break;
            default:
                type = series_chart_type as unknown as PlotType;
        }
        return type;
    }
}
