import {KeyMap} from "./generic-types";

export enum SeriesConstantPermissions {
    "override_calculations" = "override_calculations",
    "edit_process_data" = "edit_process_data",
    "apply_correction_factor" = "apply_correction_factor",
    "view_process_data" = "view_process_data"
}

export type SeriesPermissions = keyof typeof SeriesConstantPermissions;
