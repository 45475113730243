<div class="page">
  <div class="row medium-height" style="width:70%;">
    <mat-form-field class="inline" style="min-width:unset;width:120px;">
      <label>Year</label>
      <input matInput [(ngModel)]="forecast_year" type="number" name="forecastYear" class="forecast-year">
    </mat-form-field>
    <mat-form-field class="inline" style="flex-grow:3;">
      <label style="white-space: nowrap;">Estimate Type</label>
      <mat-select [(ngModel)]="selected_estimate" name="selected_estimate">
        <mat-option *ngFor="let estimate of estimate_types_list"
                    [value]="estimate"
                    (selectChange)="refreshEstimates()">{{estimate.attributes.name | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="inline" style="flex-grow:2;">
      <label style="white-space: nowrap;">KPI Level</label>
      <mat-select [(ngModel)]="selected_kpis" name="selected_kpis" multiple>
        <mat-option *ngFor="let kpi of kpis"
                    [value]="kpi">{{kpi | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="estimate-input">
    <div class="dashboard-tile">
      <div class="tile-header"><h2>{{selected_estimate?.attributes.name}} : {{forecast_year}}</h2></div>
      <div class="tile-content">
        <estimate-data-sheet *ngIf="input_sheet_ready"
                             [forecastYear]="forecast_year"
                             [selectedEstimate]="selected_estimate"
                             [seriesList]="series_list"
                             [estimateList]="estimate_series"
                             [permissions]="permissions"
                             [kpis]="selected_kpis"
                             [process]="process"
        ></estimate-data-sheet>
      </div>
    </div>
  </div>
</div>
