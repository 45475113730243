<div class="inner-events-table utils-bar" [ngClass]="{'table-min': link_only}">
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="page_size"
                 (page)="loading_next=true"
                 showFirstLastButtons #eventPaginator="matPaginator">
  </mat-paginator>
</div>

<table [dataSource]="eventDataSource" [appLdsRoller]="loading_next"
       class="table inner-component-events-table inner-events-table" [ngClass]="{'table-min': link_only}"
       mat-table matSort>
  <ng-container *ngFor="let col of event_columns;">

    <ng-container *ngIf="col==='Unlink'" matColumnDef="{{col}}">
      <th *matHeaderCellDef mat-header-cell [disabled]>
        {{col | upperfirst | removeUnderscore}}</th>
      <td *matCellDef="let event;" mat-cell class="lock-indicator"
          [matTooltip]="!canUnlink(event.id)? 'Unlink not allowed' : 'Remove this event from ' + component?.attributes.name">
        <i *ngIf="locked_dict[event.id]?.component" class="fa fa-lock lock-indicator-icon"></i>
        <button mat-button class="unlink-event-btn" (click)="unlinkEvent(event)" style="padding:0;"
                [disabled]="!canUnlink(event.id)">
          <i class="fas fa-unlink"></i>
        </button>
      </td>
    </ng-container>

    <!--Comment, Start, End-->
    <ng-container *ngIf="extra_cols.includes(col)" matColumnDef="{{col}}">
      <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[col]"
          (resizable)="format_dict[col].width=$event" [constrainWidth]="config.constrain_width"
          mat-sort-header>{{selected_cols_event_dict[col]?.title || col | upperfirst | removeUnderscore}}
      </th>
      <td *matCellDef="let event;" mat-cell style="text-align: left;"
          [class.disabled]="selected_cols_event_dict[col]?.disabled" [ngStyle]="format_dict[col].style">
        <i *ngIf="locked_dict[event.id][col]" class="fa fa-lock lock-indicator-icon"></i>
        <div *ngIf="col==='comment'" class="row center" style="height:100%;">
          <input #cp_input (change)="saveEvent(event, col, $event)"
                 [(ngModel)]="event.attributes.comment"
                 [matTooltipDisabled]="!customEventsService.event_state[event.id]"
                 [matTooltip]="customEventsService.event_state[event.id]? customEventsService.event_state[event.id].message : ''"
                 [ngClass]="customEventsService.event_state[event.id]? customEventsService.event_state[event.id].state: ''"
                 [disabled]="selected_cols_event_dict[col]?.disabled"
                 tabindex="1"/>
        </div>
        <div *ngIf="col==='start' || col==='end'" class="row center disabled">
          {{event.attributes[col.toLowerCase()] | stringDate}}
        </div>
      </td>
    </ng-container>

    <!--Event Constant Properties-->
    <ng-container *ngIf="event_properties.includes(col) && !extra_cols.includes(col)" matColumnDef="{{col}}">
      <th *matHeaderCellDef mat-header-cell [resizable]="format_dict[col]"
          (resizable)="format_dict[col].width=$event" [constrainWidth]="config.constrain_width"
          >{{selected_cols_event_dict[col]?.title || cp_dict[col]?.attributes.name || col | upperfirst | removeUnderscore}}
      </th>
      <td *matCellDef="let event" class="input-cell" mat-cell style="text-align: left;"
          [matTooltipDisabled]="!customEventsService.event_state[event.id + col]"
          [matTooltip]="customEventsService.event_state[event.id + col]? customEventsService.event_state[event.id + col].message : ''"
          [ngClass]="customEventsService.event_state[event.id + col]? customEventsService.event_state[event.id + col].state: ''"
          [class.disabled]="selected_cols_event_dict[col]?.disabled || cp_dict[col]?.attributes.is_calculation"
          [ngStyle]="format_dict[col].style">
        <ng-container *ngIf="event_constants[event.id]?.hasOwnProperty(col)">
          <generic-constant-field [constant]="event_constants[event.id][col]"
                                  [constant_property]="cp_dict[col]"
                                  (constant_changed)="event_constants[event.id][col].value = $event;"
                                  (change_complete)="saveConstant(event, col, $event)"
                                  [disabled]="cp_dict[col]?.attributes.is_calculation || selected_cols_event_dict[col]?.disabled"
                                  [formats]="selected_cols_event_dict[col]?.format"
                                  class="constant-field"
          ></generic-constant-field>
        </ng-container>
      </td>
    </ng-container>

  </ng-container>
  <tr *matHeaderRowDef="event_columns; sticky: true" mat-header-row></tr>
  <tr *matRowDef="let event; columns: event_columns;" mat-row></tr>
</table>

<div style="position: relative;" class="inner-events-table-status">{{event_status}}</div>
