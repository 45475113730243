<div class="page">
  <section>
    <div class="row hideonprint utils-bar" style="align-items: center;justify-content: space-between;">
      <div class="table-filter">
        <utils-bar [search]="true"
                   (searchValueSubmitted)="filter_string=$event;updateSearchFilter()"
                   (searchCleared)="filter_string='';updateSearchFilter();">
        </utils-bar>
       <account-filter (accountFilterEmitter)="filterByAccount($event)" [allow_blank]="true"></account-filter>
      </div>

      <mat-paginator [pageSizeOptions]="page_size_options.concat(component_types_total)"
                     style="justify-self: flex-end;">
      </mat-paginator>

    </div>

    <div class="table-container">
      <table [dataSource]="dataSource" [matSortActive]="columns[0]"
             class="event-types-table table-striped" mat-table matSort [matSortDirection]="'asc'">
        <ng-container *ngFor="let column of columns;">
          <!--Base Type-->
          <ng-container *ngIf="column==='base_type'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.base_type}}</td>
          </ng-container>

          <!--Name-->
          <ng-container *ngIf="column==='name'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let component_type;" mat-cell style="text-align: left;cursor:pointer;">
              <div (click)="editComponentType(component_type)" class="row center">
                <i class="edit-inline hide-xs"></i>
                <div [textContent]="component_type.attributes.name" style="padding-top:2px;"></div>
              </div>
            </td>
          </ng-container>


          <!--Description-->
          <ng-container *ngIf="column==='description'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.description}}</td>
          </ng-container>

          <!--Constant Properties-->
          <ng-container *ngIf="column==='constant_properties'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let component_type" class="large-cell" mat-cell style="text-align: left;border:none;">
              <constant-property-list [parent]="component_type"></constant-property-list>
            </td>
          </ng-container>

          <!--Json-->
          <ng-container *ngIf="column==='json'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let component_type" mat-cell>
              <pre>{{component_type.attributes.json | json}}</pre>
            </td>
          </ng-container>

          <!--Account Name-->
          <ng-container *ngIf="column==='account'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>{{column | upperfirst | removeUnderscore}}</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.account_name}}</td>
          </ng-container>

          <!--Created On-->
          <ng-container *ngIf="column==='created_on'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Created</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.created_on | stringDate}}</td>
          </ng-container>

          <!--Created By-->
          <ng-container *ngIf="column==='created_by_name'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Created By</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.created_by_name}}</td>
          </ng-container>

          <!--Changed On-->
          <ng-container *ngIf="column==='changed_on'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Changed</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.changed_on| stringDate}}</td>
          </ng-container>

          <!--Changed By-->
          <ng-container *ngIf="column==='changed_by_name'" matColumnDef="{{column}}">
            <th *matHeaderCellDef mat-header-cell>Changed By</th>
            <td *matCellDef="let component_type" mat-cell> {{component_type.attributes.changed_by_name}}</td>
          </ng-container>

        </ng-container>
        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>
    </div>
  </section>
</div>
