import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ComponentType} from "../../_models/component-type";
import {DateTimePeriodService} from '../../services/date-time-period.service';
import {MatTable} from '@angular/material/table';
import {forkJoin, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AppScope} from '../../services/app_scope.service';
import {MatSort} from '@angular/material/sort';
import {concatMap, debounceTime, last, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import * as utils from '../../lib/utils';
import {Component as WireComponent} from '../../_models/component';
import {ConstantProperty} from '../../_models/constant-property';
import {Event as WireEvent} from '../../_models/event';
import {EventComponent} from '../../_models/event-component';
import {EventType} from "../../_models/event-type";
import {OreBody} from "../../_models/ore-body";
import {CustomEventsService} from '../../services/custom-events.service';
import {TileDataService} from '../../services/tile_data.service';
import {CUSTOM_EVENTS_CONFIG} from "../../forms/custom-events-form/custom-events-form.component";
import {CustomEventsDataService} from "./custom-events-data.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {PaginationDataSourceWithAddRow} from "../../services/api/pagination-data-source-with-add-row";
import {
    belongsToEventType,
    filterOutTempEvents,
    getColId,
    getColIds,
    getComponentTypeIds,
    getIdsForAttributeValue,
    getPropertyIds,
    isTempId,
    oreBodyName
} from "./utils";
import {uniq as _uniq} from "lodash-es";
import {ComponentEventsTableService} from "../component-events-table/component-events-table.service";
import {ColumnFormats, ColumnFormatsDict, TableUtilsService} from "../table-utils.service";
import {HandleError, HttpErrorHandler} from "../../services/http-error-handler.service";
import {PropByRelationshipResponse} from '../../data/constant-property-data.service';
import {ComponentTypeByRelationshipResponse} from '../../data/component-type-data.service';
import {ComponentByParentResponse} from '../../data/component-data.service';
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {HeaderDataService} from '../../services/header_data.service';
import {CsvDownloadService} from '../../services/csv-download.service';
import {EventDataService} from '../../data/event-data.service';
import {moment} from "../../services/timezone-selector.service";
import {ConditionalFormattingConfig, ConditionalFormattingService} from "../conditional-formatting.service";
import {IDMap, KeyMap, KeyMapMap, ModelID} from "../../_typing/generic-types";
import {ConstantLockedDict, LockDataService} from "../../services/lock-data.service";
import {GenericConstantApiResponse, GenericField} from "../../_models/api/generic-constant";
import {FormDialogService} from "../../services/form-dialog.service";
import {FilterTableButtonConfig} from "../../components/filter-table-button/filter-table-button.component";
import {CalcGraphStatusService, ConstantCalculationsStatusResponse} from "../../services/api/calcGraphStatus.service";
import {UserService} from "../../services/user.service";
import {User} from "../../_models/users";
import {NotificationService} from "../../services/notification.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {UpperFirstPipe} from "../../shared/pipes";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'custom-events-table',
    templateUrl: './custom-events-table.component.html',
    styleUrls: ['./custom-events-table.component.less', '../component-events-table/component-events-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [TableUtilsService, UpperFirstPipe],
    standalone: false
})
export class CustomEventsTableComponent implements OnInit, AfterViewInit, OnDestroy {
    // region Variables
    // region Private Variables
    column_dict: { [key: string]: EventConfigColumn } = {};
    format_dict: ColumnFormatsDict;
    required_columns: any[] = [];
    title: string;
    datePickerConfig: any;
    buttons: ITileButton[];
    dtp: IDateTimePeriod;
    column_ids: string[];
    can_edit: boolean = false;
    prop_columns: string[] = [];
    component_columns: string[] = [];
    event_type_ids: ModelID[] = [];
    // This is a list of columns which are currently allowed on this component, as well as those in prop_columns and component_columns
    // which are dynamically built
    accounted_for_columns = ['Index', 'Select', 'type', 'start', 'end', 'duration', 'comment', 'source', 'destination', 'changed on', 'changed by'];
    duration_dict: { [key: string]: string } = {};
    page_size_options: number[] = [10, 20, 50, 100, 200];
    table_height: string = 'calc(100% - 40px)';
    events: WireEvent[];
    component_types: ComponentType[];
    component_types_dict: { [key: string]: ComponentType } = {};
    constant_properties: ConstantProperty[];
    event_components: EventComponent[];
    event_components_dict: { [key: string]: string[] } = {};
    old_event_components_dict: { [key: string]: string[] } = {};
    components: WireComponent[] = [];
    components_dict: { [key: string]: WireComponent } = {};
    ore_bodies: OreBody[];
    users: User[];
    et_ct_dict: { [key: string]: string[] };
    cp_dict: IDMap<ConstantProperty>;
    cp_name_dict: { [key: string]: ConstantProperty } = {};
    generic_constants: KeyMap<KeyMap<GenericField>>;
    event_component_t_map: { [key: string]: { [key: string]: any } } = {};
    et_props_list_dict: { [key: string]: string[] } = {};
    event_series: any;
    event_types: EventType[];
    source_ob_filter;
    destination_ob_filter;
    series_ids: any[];
    series_list: any;
    user_search_selections: string[] = [];
    user_search_fields: { name: string; value: string }[] = [];
    filter_button_config: FilterTableButtonConfig;
    user_filters: any;
    query_builder_filters: any; // Used to store the part of user_filters that comes from the quick-edit query builder
    public can_unlock = false;
    etCtQueryDict: KeyMap<KeyMap<any[]>> = {};

    change_list: string[] = []; // {[key:string]:WireEvent};
    selection_dict = {};
    page_size: number = 20;
    events_total: number;
    loading_next = false;
    filter_string: string;
    locked_dict: ConstantLockedDict = {};
    limits_map = {};
    cp_class_dict: { [key: string]: { class: string, message: string } } = {};
    conditional_dict: KeyMapMap<Partial<ColumnFormats>> = {};
    conditions: ConditionalFormattingConfig[];
    dataSource: PaginationDataSourceWithAddRow<WireEvent>;
    auto_filled_fields = ['duration', 'changed_on', 'changed_by'];
    formats: KeyMap<ColumnFormatsDict>;
    // endregion
    // region Private Variables
    private checkLimitsSubject: Subject<any> = new Subject<any>();
    private readonly onDestroy = new Subject<void>();
    private readonly handleError: HandleError;
    private _calculationCurrentlyUpdatingSubscription: Subscription;
    // endregion
    // region Decorators
    @Input() config: CUSTOM_EVENTS_CONFIG;
    @ViewChild('utils_bar') utils_bar;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) custom_events_table: MatTable<any>;
    // endregion
    // endregion
    public isTempId = isTempId;

    constructor(
        private upperFirstPipe: UpperFirstPipe,
        private dateTimePeriodService: DateTimePeriodService,
        private dateInst: DateTimeInstanceService,
        private headerData: HeaderDataService,
        private changeDetectorRef: ChangeDetectorRef,
        public dialog: MatDialog,
        private appScope: AppScope,
        public customEventsService: CustomEventsService,
        public customEventsDataService: CustomEventsDataService,
        public eventDataService: EventDataService,
        private notification: NotificationService,
        private tileData: TileDataService,
        public tableUtils: TableUtilsService,
        public componentEventsTableService: ComponentEventsTableService,
        private CsvExporter: CsvDownloadService,
        httpErrorHandler: HttpErrorHandler,
        private relMapping: RelationshipApiMappingService,
        private conditionalService: ConditionalFormattingService,
        private lockDataService: LockDataService,
        private formDialogService: FormDialogService,
        private calcGraphStatusService: CalcGraphStatusService,
        private userService: UserService
    ) {
        this.handleError = httpErrorHandler.createHandleError('HeroesService');
    }

    ngOnInit(): void {
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'start';

        this.event_type_ids = this.config.event_types?.map(e => e.id);
        if (!this.event_type_ids.length) {
            this.notification.openError('No event type(s) selected', 5000);
            return;
        }

        // todo move datasource initialization outside other methods
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp: IDateTimePeriod) => {
            this.dtp = dtp;
            this.customEventsService.event_state = {};
            this.customEventsService.error_list = [];
            this.emitFilterQuery();
        });

        this.checkLimitsSubject.pipe(takeUntil(this.onDestroy),
            debounceTime(500)
        ).subscribe(to_check => {
            if (this.config.bypass_limits_formatting !== true && this.column_dict[to_check.column]?.type === 'constant_property') {
                const cp: ConstantProperty = this.cp_dict[to_check.column];
                this.componentEventsTableService.getConstantConditionalFormat(to_check.event.id, this.cp_class_dict, cp, this.column_dict[cp.id],
                    this.limits_map, to_check.event.attributes.custom_constants?.[this.cp_dict[to_check.column].attributes.name]);
                this.changeDetectorRef.markForCheck();
            }
        });

        this.config.columns.map((c, index) => {
            this.column_dict[getColId(c)] = {...c, index};
        });
        this.required_columns = getIdsForAttributeValue(this.config.columns, 'require', true);
        this.conditions = this.config.conditional_formats || [];

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            this.dtp = this.dateInst.dtp;
            this.user_search_fields=[];
            this.gatherEventData().pipe(take(1)).subscribe(gatherData => {
                this.column_ids = ['Index', 'Select'].concat(getColIds(this.config.columns)).filter(c => {
                    return this.column_dict[c]?.type === 'attribute' || ['Index', 'Select'].includes(c) ||
                        (this.column_dict[c]?.type === 'constant_property' && this.cp_dict[c]) ||
                        (this.column_dict[c]?.type === 'component_type' && this.component_types_dict[c]);
                });
                this.column_ids = [...new Set(this.column_ids)];
                this.column_ids.forEach(c => {
                    if (this.column_dict[c]?.type === 'constant_property' && this.cp_dict[c]?.attributes.data_type !== 'datetime') {
                        this.user_search_fields.push({name:this.column_dict[c]?.title || this.cp_dict[c]?.attributes.name || c, value:c});
                    } else if (this.column_dict[c]?.type === 'component_type') {
                        this.user_search_fields.push({name:this.column_dict[c]?.title || this.component_types_dict[c]?.attributes.name || c, value:c});
                    } else if (c.toLowerCase() === 'comment') {
                        this.user_search_fields.push({name:this.column_dict[c]?.title || c, value:c});
                    }
                });

                this.checkColumnConfig();
                this.initialiseColumnFormats();
                this.setFilterTableButtonConfig();

                this.paginator.pageSize = this.config.page_size ? this.config.page_size : this.page_size;
                initialQuery.filters = this.getFilter(this.dtp);
                this.dataSource = new PaginationDataSourceWithAddRow<WireEvent>(
                    (query) => this.customEventsDataService.pageEventLight(query),
                    initialQuery,
                    this.paginator,
                    this.sort
                );

                this.customEventsService.event_state = {};
                this.setAfterPageEvent();
            });
        });

        if (this.tileData?.save_content) {
            this.tileData.save_content.pipe(takeUntil(this.onDestroy)).subscribe(event => {
                if (!this.tileData.tile) {
                    return;
                }
                this.tileData.tile.attributes.parameters.columns =
                    this.tableUtils.updateColumnFormats(this.config.columns, this.format_dict);
                this.tableUtils.emitSaveTile(this.tileData);
            });
        }
        fromEvent(window, 'resize').pipe(debounceTime(250), takeUntil(this.onDestroy))
            .subscribe(() => this.table_height = this.tableUtils.setTableHeight(this.utils_bar));
        this.can_unlock = this.userService.canUnlockData();
    }

    ngAfterViewInit() {
        this.table_height = this.tableUtils.setTableHeight(this.utils_bar);
    }

    getFilter(dtp): any[] {
        let filter: any[];
        if (!this.config.constant_property_time) {
            filter = this.customEventsDataService.generateEventsFilter(dtp, this.event_type_ids);
        } else {
            filter = this.customEventsDataService.generateDateFilterConstantProperty(this.config, dtp, this.event_type_ids);
        }

        let qbFilter = this.customEventsService.generateQueryBuilderFilter(this.event_type_ids, this.config.query);
        if (qbFilter && qbFilter.length) {
            filter.push({
                "or": qbFilter
            });
        }
        if (this.user_filters) {
            filter.push(this.user_filters);
        }
        if (this.query_builder_filters) {
            filter.push(this.query_builder_filters);
        }
        return filter;
    }

    emitFilterQuery() {
        this.paginator.pageIndex = 0;
        const filters = this.getFilter(this.dtp);
        this.dataSource.filterBy(filters);
    }

    setAfterPageEvent() {
        const atts = ['start', 'end'];
        this.dataSource.$page.pipe(
            takeUntil(this.onDestroy),
            switchMap((result) => {
                this.events = result.data;
                this.events_total = result.meta.count;

                const event_ids = filterOutTempEvents(result.data).map(v => v.id);
                const constant_properties_ids = getIdsForAttributeValue(this.config.columns, 'type', 'constant_property');

                if (!event_ids || event_ids.length < 1) {
                    return of(null);
                }

                const $eventConstants = this.customEventsDataService.getApiEventConstants(event_ids, constant_properties_ids, atts);
                const $linkedComponents = this.getLinkedComponents(filterOutTempEvents(result.data));
                return forkJoin([$eventConstants, $linkedComponents]).pipe(
                    map(([eventConstants, linkedComponents]) => {
                        this.generic_constants = eventConstants?.data || {};
                        this.locked_dict = this.customEventsService.updateLockedProperties(
                            this.locked_dict, this.events, this.constant_properties, eventConstants, atts
                        );
                        this.customEventsService.mapApiEventConstants(this.events, eventConstants, this.cp_dict);
                        this.event_component_t_map = {};
                        this.old_event_components_dict = {};
                        return eventConstants;
                    })
                );
            }),
            tap((eventConstants: GenericConstantApiResponse) => {
                const custom_condition_ids = this.conditions.map(condition => {
                    if (condition.comparer === 'auto' && condition.column.type === 'constant_property') {
                        return condition.column.id;
                    }
                });
                if (this.config.bypass_limits_formatting !== true) {
                    this.getConstantPropertyEventTypes(this.event_type_ids, this.constant_properties.map(cp => cp.id));
                } else if (this.config.bypass_limits_formatting === true && custom_condition_ids?.length > 0) {
                    this.getConstantPropertyEventTypes(this.event_type_ids, custom_condition_ids);
                }

                this.mapEventComponent(this.events);
                if (getColIds(this.config.columns).includes('duration')) {
                    this.events.forEach(event => this.duration_dict[event.id] = this.customEventsService.calculateDuration(event));
                }
                this.conditionalFormats();
                this.setButtons();
                this.checkEventConstantCalculationsStatus();
                this.changeDetectorRef.markForCheck();
            }),
        ).subscribe();
    }

    gatherEventData() {
        // this fetches all events related data except the events, the events are called separately after we have all
        const ctrl = this;
        const obs_list = [];

        // Get Event Types and the Constant Properties for those types
        obs_list.push(this.eventDataService.getEventTypes(this.config.event_types.map(e => e.id)).pipe(
                tap(response => {
                    this.event_types = response.data;
                    if (this.event_types && this.event_types.length === 1) {
                        this.tileData.setDefaultTitle(this.event_types[0].attributes.name);
                    }
                }),
                concatMap((response) => this.getConstantPropertiesForEventType())
            )
        );
        this.source_ob_filter = this.customEventsService.getOreBodiesFilter(this.event_type_ids, this.dtp, 'source');
        this.destination_ob_filter = this.customEventsService.getOreBodiesFilter(this.event_type_ids, this.dtp, 'destination');

        // Get the Component Types for the selected Event Types
        obs_list.push(this.getEventTypeComponentTypes());

        return forkJoin(obs_list);
    }

    getConstantPropertiesForEventType(): Observable<PropByRelationshipResponse> {
        let property_ids = getPropertyIds(this.config.columns);
        property_ids = this.componentEventsTableService.addTimeFilterProperties(this.config, property_ids);
        return this.customEventsService.getStreamedConstantPropertiesByEventTypeIds(this.event_type_ids, property_ids)
            .pipe(tap((prop_response: PropByRelationshipResponse) => {
                this.constant_properties = prop_response.constant_properties;
                this.cp_dict = prop_response.cp_dict;
                this.et_props_list_dict = prop_response.props_list_dict;
                this.prop_columns = property_ids;
                this.constant_properties.forEach(cp => {
                    this.cp_name_dict[cp.attributes.name] = cp;
                });
                this.mapEventSeries();
            }));
    }

    mapEventSeries() {
        this.event_series = {};
        this.series_ids = [];

        this.event_types.forEach(event_type => {
            // Create dict for which series belong to which event types
            this.event_series[event_type.id] = event_type.relationships.series_list.data.map(series => series.id);
            event_type.relationships.series_list.data.map(series => this.series_ids.push(series.id));
        });
    }

    getEventTypeComponentTypes(): Observable<ComponentTypeByRelationshipResponse> {
        this.et_ct_dict = {};
        const ct_ids = getComponentTypeIds(this.config.columns);
        return forkJoin([this.customEventsService.getComponentTypesByEventTypes(this.event_type_ids, null, ct_ids),
            this.customEventsService.getEventTypeComponentTypeRelationships(this.event_type_ids, ct_ids)])
            .pipe(
                map(([componentTypeResponse, eventTypeComponentTypes]) => {
                    this.component_types = componentTypeResponse.component_types;
                    this.component_types_dict = componentTypeResponse.ct_dict;
                    this.component_columns = [];
                    this.component_columns = ct_ids;
                    this.et_ct_dict = componentTypeResponse.ct_list_dict;
                    this.etCtQueryDict = this.customEventsService.getEvenTypeComponentTypeQueryMap(eventTypeComponentTypes.data);
                    return componentTypeResponse;
                })
            );
    }

    getEventComponents(events): Observable<any> {
        const event_ids = this.events.map(e => e.id).filter(id => id && !id.includes('temp'));
        const ct_ids = this.component_types.map(ct => ct.id);
        return this.customEventsService.getEventComponents(event_ids, ct_ids)
            .pipe(tap(result => {
                this.event_components = result.data;
            }));
    }

    getLinkedComponents(events): Observable<any> {
        const event_ids = this.events.map(e => e.id).filter(id => id && !id.includes('temp'));
        if (!events || events.length < 1) {
            return of(null);
        }
        const $ec = this.getEventComponents(event_ids);
        const ct_ids = this.component_types.map(ct => ct.id);
        const $ec_dict = this.customEventsService.getComponentsByEvent(event_ids, ct_ids)
            .pipe(
                tap((result: ComponentByParentResponse) => {
                    this.components = result.components;
                    this.components_dict = result.component_dict;
                    this.event_components_dict = result.component_list_dict;
                })
            );
        return forkJoin([$ec_dict, $ec]);
    }

    mapEventComponent(events: WireEvent[]): void {
        // The event_component_t_map object is used to hold the component object selected for each component_type on the event
        events.forEach(event => {
            if (!this.event_component_t_map[event.id]) {
                this.event_component_t_map[event.id] = {};
            }
            // For each component_type for the event_type for this event...
            for (let i = 0; i < this.et_ct_dict[event.relationships.event_type.data.id].length; i++) {
                const ct_id = this.et_ct_dict[event.relationships.event_type.data.id][i];
                if (this.event_component_t_map[event.id][ct_id]) {
                    continue;
                }
                this.event_component_t_map[event.id][ct_id] = {id: null, type: 'component', previous_id: null};
            }

            if (!this.event_components_dict[event.id]) {
                return;
            }

            this.event_components_dict?.[event.id]?.forEach(component_id => {
                // get the full_component so that we can find out which component_type it belongs to
                const full_component = this.components_dict[component_id];
                if (!full_component) {
                    return;
                }
                let ct_id = full_component.relationships.component_type.data?.id;
                if (!ct_id) {
                    return;
                }
                if (this.event_component_t_map[event.id][ct_id]) {
                    this.event_component_t_map[event.id][ct_id] = {
                        ...full_component,
                        previous_id: component_id
                    };
                }
            });
            this.old_event_components_dict[event.id] = utils.deepCopy(this.event_components_dict[event.id]);
        });
    }

    updateSelectedEventType($event, event: WireEvent) {
        event.relationships.event_type.data.id = $event.id;
        this.mapEventComponent(this.events);
        this.updateDisabledFormats();
        this.detectChange(event, 'type');
        this.changeDetectorRef.markForCheck();
    }

    updateSelectedOreBody($event, event: WireEvent, column: 'output_ore_body' | 'input_ore_body') {
        event.relationships[column].data = {type: 'ore_body', id: $event.value.id};
        this.detectChange(event, column);
    }

    getConstantPropertyEventTypes(event_type_ids, constant_property_ids?) {
        this.customEventsDataService.getConstantPropertyEventTypes(event_type_ids, constant_property_ids, this.cp_dict)
            .subscribe(result => {
                this.limits_map = result;
                filterOutTempEvents(this.events).forEach(event => {
                    this.constant_properties.forEach(constant_property => {
                        if (event.attributes.custom_constants.hasOwnProperty(constant_property.attributes.name)) {
                            this.componentEventsTableService.getConstantConditionalFormat(event.id, this.cp_class_dict, constant_property, this.column_dict[constant_property.id],
                                this.limits_map, event.attributes.custom_constants[constant_property.attributes.name], false);

                        }
                    });
                });
                this.changeDetectorRef.markForCheck();
            });
    }

    checkColumnConfig(): void {
        const remove_cols = [];
        this.column_ids.forEach(col => {
            if (!(this.accounted_for_columns.includes(col) || this.prop_columns.includes(col) || this.component_columns.includes(col))) {
                this.notification.openError(`Column ${col} is not configured for use on this table. Please remove it from the column list.`, 15000);
                remove_cols.push(this.column_ids.indexOf(col));
                console.log('Removing column ' + utils.deepCopy(col) + '. Please update the configuration for this tile' +
                    'or contact your Administrator to request that this column be added.');
            }
        });
        utils.removeItems(this.column_ids, remove_cols);
    }

    initEmptyRows(repeat = 3): void {
        if (this.events.length < 1) {
            while (repeat > 0) {
                this.addEmptyRow();
                repeat -= 1;
            }
        }
    }

    clearSearch() {
        this.filter_string = null;
        this.user_filters = null;
        this.user_search_selections = [];
        this.emitFilterQuery();
    }

    updateSearchFilter() {
        if (!this.filter_string || this.filter_string === '') {
            // Search clicked with no substring to search on
            this.notification.openError('Please enter text to search on before searching', 2000);
            return;
        }
        if (this.user_search_selections?.length < 1) {
            this.notification.openError('Please select a column to search on before searching', 2000);
            return;
        }
        const string_filters = {or: []};
        if (this.user_search_selections.includes('comment')) {
            ['comment'].forEach(att => {
                string_filters.or.push({op: 'ilike', name: att, val: '%' + this.filter_string + '%'});
            });
        }
        const c_names = this.component_columns.map(c => this.component_types_dict[c]?.attributes.name);

        string_filters.or = string_filters.or.concat(this.customEventsDataService.generateConstantPropertiesSearchFilter(this.prop_columns, this.cp_dict, this.filter_string, this.user_search_selections));
        string_filters.or = string_filters.or.concat(this.customEventsDataService.generateComponentSearchFilter(this.component_columns, this.filter_string, this.user_search_selections));

        this.user_filters = string_filters;
        this.emitFilterQuery();
    }

    updateFilterQuery(qb_filters): void {
        this.query_builder_filters = qb_filters;
        this.emitFilterQuery();
    }

    detectChange(event, column) {
        // TODO Validate by column
        if (!event.id) {
            return;
        }
        if (column === 'end' || column === 'start') {
            this.duration_dict[event.id] = this.customEventsService.calculateDuration(event);
        }
        if (!this.change_list.includes(event.id)) {
            this.change_list.push(event.id);
            this.customEventsService.event_state[event.id] = {
                state: 'dirty',
                message: 'Item changed but not yet saved.'
            };
        }
        this.checkLimits(event, column);
    }

    checkLimits(event, column) {
        this.checkLimitsSubject.next({event: event, column: column});
    }

    updateComponentRelationship(row, column, $event) {
        this.event_component_t_map[row.id][column].id = $event.value.id;

        this.event_components_dict[row.id] = Object.keys(this.event_component_t_map[row.id]).map(key => {
            return this.event_component_t_map[row.id][key].id;
        });

        this.components.push($event.value);
        this.components = _uniq(this.components);
    }

    addEventTypeSeriesListToEvent(event: WireEvent): void {
        event.relationships.series_list = {
            data: this.event_series[event.relationships.event_type.data.id].map(id => ({
                'id': id,
                'type': 'series'
            }))
        };
    }

    addEmptyRow(): void {
        const event: WireEvent = new WireEvent();
        event.id = 'temp_' + utils.guid();
        event.relationships.event_type.data = {id: this.event_types[0].id, type: 'event_type'};
        event.attributes.event_type_name = this.event_types[0].attributes.name;
        const dt = new Date(moment.tz().toISOString());
        dt.setSeconds(0, 0);
        event.attributes.start = dt;
        this.et_props_list_dict[this.event_types[0].id].forEach(prop => event.attributes.custom_constants[prop] = null);
        this.mapEventComponent([event]);
        this.dataSource.addRow(event);
        this.events.push(event);
        this.detectChange(event, 'start');
    }

    save(): void {
        if (!this.change_list) {
            this.notification.openError('No rows to save.', 2000);
            return;
        }
        const patching_obs = [];
        const saving_obs = [];

        for (let i = 0; i < this.change_list.length; i++) {
            const id = this.change_list[i];
            const event = this.events.find(e => e.id == id);

            if (!this.validate(event)) {
                return;
            }

            const event_to_save = utils.deepCopy(event);
            delete event_to_save.attributes.event_type_name;
            delete event_to_save.relationships.event_constants;
            delete event_to_save.relationships.event_components;
            delete event_to_save.attributes.properties_data_map;
            delete event_to_save.attributes.duration;
            delete event_to_save.relationships.components;

            this.addEventTypeSeriesListToEvent(event_to_save);

            if (event_to_save.id.includes("temp_")) {
                let t = this.customEventsService.saveEvent(event_to_save, event)
                    .pipe(concatMap(result => {
                        if (!result.data?.id) {
                            return of(result);
                        }
                        return this.getEventComponentObservable(result, result.data.id, result.temp_id);
                    }));
                saving_obs.push(t);
            } else {
                const t1 = this.customEventsService.patchEvent(event_to_save, null)
                    .pipe(concatMap(result => {
                        return this.getEventComponentObservable(result, event_to_save.id, event_to_save.id);
                    }));
                patching_obs.push(t1);
            }
        }

        this.customEventsService.event_state = {};
        this.customEventsService.error_list = [];

        forkJoin(saving_obs.concat(patching_obs)).subscribe({
            next: (results: { data: any, 'error'?: any, temp_id?: string }[]) => {
                results.forEach(item => {
                    if (item && !item.error) {
                        if (item.temp_id) { // **New items
                            // **Update the Event-Component map to use the created item id
                            const temp_id = item.temp_id;
                            this.event_component_t_map[item.data.id] = utils.deepCopy(this.event_component_t_map[temp_id]);
                            this.locked_dict[item.data.id] = utils.deepCopy(this.locked_dict[temp_id]);
                            this.duration_dict[item.data.id] = this.duration_dict[temp_id];
                            if (this.selection_dict[temp_id]) {
                                this.selection_dict[item.data.id] = true;
                                this.selection_dict[temp_id] = false;
                            }
                            this.change_list.splice(this.change_list.indexOf(temp_id));
                            if (!this.config.bypass_limits_formatting) {
                                this.constant_properties.forEach(cp => {
                                    this.checkLimits(item['data'], cp.id);
                                });
                            }
                        } else {
                            this.change_list.splice(this.change_list.indexOf(item.data.id));
                        }
                    }
                });
                this.conditionalFormats();
                this.changeDetectorRef.markForCheck();
            }, error: (error) => {
                // Since errors get caught in the save and patch pipes, don't think this will ever execute
                console.log('ERROR: CustomEventsTableComponent (patch) ', error);
            }
        });
    }

    getEventComponentObservable(result, event_id, temp_id): Observable<any> {
        if (temp_id !== event_id) {
            this.event_components_dict[event_id] = this.event_components_dict[temp_id];
            this.old_event_components_dict[event_id] = this.old_event_components_dict[temp_id];
        }

        return this.relMapping.saveMany('event_component', 'event', event_id, 'component',
            this.event_components_dict[event_id]?.filter(id => id).map(i => {
                return {id: i};
            }),
            this.old_event_components_dict[event_id]?.filter(id => id).map(i => {
                return {id: i};
            }),
            this.event_components)
            .pipe(
                last(),
                concatMap((response) => {
                    if (!response) {
                        return of(result);
                    }
                    this.old_event_components_dict[event_id] = this.event_components_dict[event_id].slice();
                    return this.getEventComponents(this.events).pipe(map(() => result));
                })
            );
    }

    delete(): void {
        if (!confirm("Are you sure you want to delete this/these event(s)?")) {
            return;
        }
        const deleting_obs = [];
        const deleting_indices = [];
        Object.keys(this.selection_dict).filter(key => this.selection_dict[key] === true)?.forEach(event_id => {
            // Get the indices of the items to delete in the events array
            let index = this.events.map(e => e.id).indexOf(event_id);
            if (index || index === 0) {
                deleting_indices.push(index);
            }
            if (!event_id.includes("temp_")) {
                deleting_obs.push(this.customEventsService.deleteEvent(event_id, index));
            }
        });

        this.customEventsService.event_state = {};
        this.customEventsService.error_list = [];

        forkJoin(deleting_obs).subscribe({
            next: (results) => {
                results.forEach(item => {
                    if (item && item['error']) {
                        // If the item failed to delete from the db then remove if from the list of items to be deleted
                        let i = deleting_indices.indexOf(item['index']);
                        deleting_indices.splice(i); // this is getting a bit meta
                    }
                });
            }, error: (error) => {
                // Since errors get caught in the save and patch pipes, don't think this will ever execute
                console.log('CustomEventsTableComponent - : patch errors', error);
            },
            complete: () => {
                // This removes all items that were successfully deleted from db plus 'new' items that were selected.
                // Error items should remain where they are with orange warning indicator
                utils.removeItems(this.events, deleting_indices);
                this.selection_dict = {};
                this.dataSource.refresh();
            }
        });
    }

    selectEvent(event, checked) {
        this.selection_dict[event.id] = checked.checked;
    }

    selectAll(checked) {
        this.events.forEach(event => {
            this.selection_dict[event.id] = checked.checked;
        });
    }

    getTree(column) {
        this.formDialogService.openConstantPropertyCalcTreeDialog(this.config.event_types[0], column);
    }

    checkLock(event, column) {
        if (!this.can_unlock) return;
        this.lockDataService.getLocks('event', event, this.cp_dict?.[column] || column, this.tileData.tile.id);
    }

    validate(event): boolean {
        let missing = [];
        let allow = true;

        for (let i = 0; i < this.required_columns.length; i++) {
            let column = this.required_columns[i]; // for constant properties

            // Ignore columns that are auto filled in the backend
            if (this.auto_filled_fields.includes(column.id.toLowerCase())) {
                continue;
            }
            // Attribute columns
            if (column.type === 'attribute' && !event.attributes[column]) {
                missing.push(column.id);
                allow = false;
                continue;
            }

            // Custom constant columns
            if (column.type === 'constant_property' && !event.attributes.custom_constants[this.cp_dict[column].attributes.name]) {
                missing.push(this.cp_dict[column].attributes.name);
                allow = false;
                continue;
            }

            // Source and Destination columns
            if (column.id.toLowerCase() === 'source' && !event.relationships.output_ore_body.data?.id) {
                missing.push('source');
                allow = false;
                continue;
            }
            if (column.id.toLowerCase() === 'destination' && !event.relationships.input_ore_body.data?.id) {
                missing.push('destination');
                allow = false;
                continue;
            }

            // Component columns
            if (column.type === 'component_type') {
                if (!this.event_component_t_map[event.id][column.id] || !this.event_component_t_map[event.id][column.id].id) {
                    missing.push(this.component_types_dict[column].attributes.name);
                    allow = false;
                }
            }
        }
        if (allow === false) {
            this.notification.openError(`The following fields are required: ${missing.join(', ')}`);
            this.customEventsService.event_state[event.id] = {
                state: 'error',
                message: 'Required: ' + missing.join(', ')
            };
        }

        if (allow && !this.customEventsService.checkStartAndEndDates(event, 'start', 'end')) {
            allow = false;

            this.customEventsService.event_state[event.id] = {
                state: 'error',
                message: 'End date must be later than start date'
            };
        }
        return allow;
    }

    updateEventConstantCalculations() {
        const allSelectedEventIds = Object.entries(this.selection_dict)
            .filter(([k, v]) => v)
            .map(([k, v]) => k);

        if (!allSelectedEventIds.length) {
            this.notification.openError("No rows selected", 5000);
            return;
        } else if (allSelectedEventIds.some(r => this.change_list.includes(r))) {
            this.notification.openError("Events need to be saved before calculations can run.", 5000);
            return;
        } else {
            this.notification.openSuccess(`Starting update of calculations linked to ${allSelectedEventIds.length} events`, 2000);
            this.customEventsDataService.updateEventConstantCalculations(allSelectedEventIds).subscribe((response) => {
                this.notification.openError(response.message, 5000);
                this.emitFilterQuery();
            });
        }
        this.checkEventConstantCalculationsStatus();
    }

    belongsToEventType(row: WireEvent, col: string, type: 'constant_property' | 'component_type'): boolean {
        return belongsToEventType(row, col, type, this.et_props_list_dict, this.et_ct_dict);
    }

    initialiseColumnFormats() {
        this.format_dict = {};
        this.config.columns.forEach(column => {
            const id = column.type === 'attribute' ? column.id.toLowerCase() : column.id;
            this.format_dict[id] = this.tableUtils.getColumnFormats(column.format);
        });
    }


    conditionalFormats(): void {
        const data = {
            constant_property: {data: this.generic_constants, dict: this.cp_dict},
            component: {data: this.event_component_t_map, dict: this.components_dict}
        };
        this.conditional_dict = this.conditionalService.extractConditions(this.conditions, this.events, this.column_ids,
            data);
        this.consolidateFormats();
    }

    consolidateFormats() {
        this.formats = this.tableUtils.combineStyles(this.format_dict, this.conditional_dict, this.events);
        this.updateDisabledFormats();
    }

    updateDisabledFormats() {
        this.formats = this.tableUtils.updateStylesForDisabled(this.config.columns, this.events, this.cp_dict,
            this.generic_constants, this.formats, this.et_props_list_dict, this.et_ct_dict);
        this.changeDetectorRef.markForCheck();
    }

    getEventDataFileType($event) {
        this.componentEventsTableService.openFileFormatModal($event, this.downloadEventData.bind(this), this.config.download_file_type,
            'event_types', this.event_types.map(e => e.id));
    }

    lockData($event) {
        if (this.events?.length < 1) {
            return;
        }
        const filters = this.getFilter(this.dtp);
        const selected_events = Object.keys(this.selection_dict).filter(item => this.selection_dict[item] === true);
        this.lockDataService.lockData('event', this.config.lock_template.id, this.events_total, filters, selected_events);
    }

    downloadEventData(filetype: string = 'formatted_csv') {
        const filters = this.getFilter(this.dtp);

        let download_title = this.tileData?.tile?.attributes?.title;
        if (!download_title) {
            download_title = this.headerData.title;
        } else {
            if (this.headerData.title) {
                download_title = utils.deepCopy(this.headerData.title).concat("_", download_title);
            }
        }
        let print_title = utils.printDocTitle(download_title, this.dateInst.dtp.start,
            this.dateInst.dtp.end);

        this.CsvExporter.downloadModelData('Event', print_title, filters, this.config.columns, this.cp_dict,
            this.component_types_dict, this.event_type_ids, filetype);
    }

    setFilterTableButtonConfig() {
        this.filter_button_config = {
            cp_dict: this.cp_dict,
            constant_properties: this.constant_properties.filter(cp => getColIds(this.config.columns).includes(cp.id)),
            event_types: this.config.event_types,
            component_types: this.component_types,
            column_dict: this.column_dict,
            time_properties: [this.cp_dict[this.config.start_prop], this.cp_dict[this.config.end_prop]]
        };
    }

    setButtons(): void {
        this.buttons = [
            {
                name: 'Save',
                func: () => this.save(),
                class: 'fa fa-save',
                HoverOverHint: 'Save'
            },
            {
                name: 'Insert',
                func: () => this.addEmptyRow(),
                class: 'fa fa-plus',
                HoverOverHint: 'Add empty row'
            },
            {
                name: 'Update Calculations',
                func: () => this.updateEventConstantCalculations(),
                class: 'fa small fa-repeat hide-xs',
                HoverOverHint: 'Manually re-run event calculations'
            },
            {
                name: 'Download Data',
                func: ($event) => this.getEventDataFileType($event),
                class: 'small fas fa-file-download hide-xs',
                HoverOverHint: 'Download table data as a .csv or .xlsx file'
            }
        ];

        if (this.config.lock_template?.id) {
            if (["create event_lock", 'create component_lock', 'create lock_template_version']
                .every((f: string) => this.appScope.current_user.feature_names.includes(f))) {
                this.buttons.push(
                    {
                        name: 'Lock Data',
                        func: ($event) => this.lockData($event),
                        class: 'small fas fa-lock hide-xs',
                        HoverOverHint: 'Lock data with the selected template'
                    }
                );
            }
        }
        this.buttons.push(
            {
                name: 'Delete Selected Events',
                func: () => this.delete(),
                class: 'fa small fa-trash tile-header-right',
                disabled: !this.config.allow_delete,
                HoverOverHint: this.config.allow_delete ? 'Delete all the selected events in this table' : 'Delete not allowed'
            }
        );
        this.tileData.buttonsChanged.next(this.buttons);
    }

    showChange() {
        this.changeDetectorRef.markForCheck();
    }

    oreBodyNameFunction(value) {
        if (!value) {
            return;
        }
        this.upperFirstPipe = new UpperFirstPipe();
        return this.upperFirstPipe.transform(oreBodyName(value));
    }

    public checkEventConstantCalculationsStatus(): void {
        // Check if there are Dirty Models and poll the API if there are.
        if (this.config.show_calc_update_status) {
            this._calculationCurrentlyUpdatingSubscription =
                this.calcGraphStatusService.startPolling().subscribe((response: ConstantCalculationsStatusResponse) => {
                    this.buttons = this.tableUtils.refreshCalculationStatus(this.buttons, response);
                    this.headerData.hidePageLoader = response.is_updating;

                    if (!response.is_updating) {
                        this._calculationCurrentlyUpdatingSubscription.unsubscribe();
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
