import {Injectable, OnDestroy} from '@angular/core';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from '../../services/api/api.service';
import {Series} from '../../_models/series';
import {first, takeUntil, tap} from 'rxjs/operators';
import {ReplaySubject, Subject, Subscription} from "rxjs";
import {refreshSubscription} from '../../lib/utils';
import {Alert} from '../../_models/alert';
import {User} from "../../_models/users";

@Injectable({
    providedIn: 'root'
})
export class AlertsTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public readonly userListSubject: ReplaySubject<{ [key: string]: User }> = new ReplaySubject<{ [key: string]: User }>(1);

    $alert_users: Subscription;

    constructor(private api: ApiService) {
    }

    getInitialSearchOptions(series_list: Series[]): SearchQueryOptions {
        const options = new SearchQueryOptions()
        options.filters = this.getAlertsForSeriesFilter(series_list);
        options.sort = '-changed_on';
        options.page_number = 1;
        return options;
    }

    getAlertsForSeriesFilter(series_list: Series[]) {
        const filters: any[] = [{
            name: 'series', op: 'has', val:
                {name: 'id', op: 'in', val: series_list.map(s => s.id)}
        }];
        return filters
    }

    getAlertsSearchFilters(filterString) {
        const filters = {or: []};

        filters.or.push({name: 'data_exception_type', op: 'ilike', val: `%${filterString}%`});
        filters.or.push({
            name: 'users',
            op: 'any',
            val: {name: 'name', op: 'ilike', val: `%${filterString}%`}
        });
        return filters
    }

    getAlertsListsDetails(alerts: Alert[]) {
        const user_ids = alerts.reduce((alert_arr, a) => {
            alert_arr = alert_arr.concat(a.relationships.users.data?.map(u => u.id))
            return alert_arr
        }, [])
        this.getUsersList(user_ids);
    }

    getUsersList(user_ids: string[]) {
        const options = new SearchQueryOptions()
        options.filters = [{
            name: 'id', op: 'in', val: user_ids
        }];
        const user_dict = {};
        this.$alert_users = refreshSubscription(this.$alert_users);
        this.$alert_users = this.api.users.searchMany().pipe(
            tap(users => {
                users.data.forEach(u => user_dict[u.id] = u);
                this.userListSubject.next(user_dict);
            }),
            first(), takeUntil(this.onDestroy)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
