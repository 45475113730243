import {Component, ViewEncapsulation} from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {takeUntil} from "rxjs";
import {BaseComponent} from '../../shared/base.component';
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'print-content',
    templateUrl: './print-content.component.html',
    styleUrls: ['./print-content.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PrintContentComponent extends BaseComponent {
    clientLogoSrc: string = '/admin/myfiles/download/right-logo.png';
    wireLogoSrc: string = '/static/images/wire-logo-black.png';
    printing = false;

    constructor(public headerData: HeaderDataService,
                public dateInst: DateTimeInstanceService,
                public appScope: AppScope) {
        super()
        this.headerData.pagePrinting.pipe(takeUntil(this.onDestroy)).subscribe((value => this.printing = value));
    }

    currentDate(): Date {
        return new Date();
    }
}
