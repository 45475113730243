import {Component} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {CommonModule} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatDividerModule} from "@angular/material/divider";
import {MatSelectModule} from "@angular/material/select";
import {
    SelectSearchComponentComponent
} from "../../../components/select-search-api/single/select-search-component.component";
import {
    SelectManySearchBaseComponent
} from "../../../components/select-search-api/many/select-many-search-base.component";
import {SelectSearchComponent} from "../../../components/select-search/select-search.component";
import {SelectManySearchComponent} from "../../../components/select-search/select-many-search.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     MatFormFieldModule,
    //     MatInputModule,
    //     MatDividerModule,
    //     MatSelectModule,
    //     MatButtonModule,
    //     MatIconModule,
    //     MatCheckboxModule,
    //     MatRadioModule,
    //     MatSlideToggleModule,
    //     SelectSearchComponentComponent,
    //     SelectManySearchBaseComponent,
    //     SelectSearchComponent,
    //     SelectManySearchComponent
    // ],
    selector: 'theme-view',
    templateUrl: './theme-view.component.html',
    styleUrls: ['./theme-view.component.less'],
    standalone: false
})
export class ThemeViewComponent {
    primaryPalette = [
        {label: 'A100', value: '#f3f3f3', contrast: 'black'},
        {label: 'A200', value: '#e6e4e1', contrast: 'black'},
        {label: 'A400', value: '#bdbdbd', contrast: 'black'},
        {label: 'A700', value: '#6d6e70', contrast: 'white'},
        {label: '50', value: '#e8e8e9', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '100', value: '#bebebe', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '200', value: '#a1a1a2', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '300', value: '#7b7b7d', contrast: 'white'},
        {label: '400', value: '#5e5e61', contrast: 'white'},
        {label: '500', value: '#3a3a3c', contrast: '#e6e4e1'},
        {label: '600', value: '#343436', contrast: '#e6e4e1'},
        {label: '700', value: '#2c2c2e', contrast: '#e6e4e1'},
        {label: '800', value: '#252527', contrast: '#e6e4e1'},
        {label: '900', value: '#1e1e1f', contrast: '#e6e4e1'},
    ];
    lightPalette = [
        {label: '50', value: '#f5f5f5', contrast: 'black'},
        {label: '100', value: '#e9e9e9', contrast: 'black'},
        {label: '200', value: '#d9d9d9', contrast: 'black'},
        {label: '300', value: '#c4c4c4', contrast: 'black'},
        {label: '400', value: '#a6a6a6', contrast: 'black'},
        {label: '500', value: '#888888', contrast: 'white'},  // Your specified primary color
        {label: '600:', value: '#757575', contrast: 'white'},
        {label: '700', value: '#616161', contrast: 'white'},
        {label: '800', value: '#484848', contrast: 'white'},
        {label: '900', value: '#212121', contrast: 'white'},
        {label: 'A100', value: '#f8f8f8', contrast: 'black'},
        {label: 'A200', value: '#eeeeee', contrast: 'black'},
        {label: 'A400', value: '#bdbdbd', contrast: 'black'},
        {label: 'A700', value: '#616161', contrast: 'white'},
    ];

    accentPalette = [
        {label: '50', value: '#f7f4f0', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '100', value: '#ebe3d9', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '200', value: '#ded1c0', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '300', value: '#d0bfa6', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '400', value: '#c6b193', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '500', value: '#c2b49b', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '600', value: '#bcad93', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '700', value: '#b4a489', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '800', value: '#ac9c7f', contrast: 'white'},
        {label: '900', value: '#9f8c6d', contrast: 'white'}
    ];

    warnPalette = [
        {label: '50', value: '#fbe9e5', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '100', value: '#f5c7bd', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '200', value: '#eea291', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '300', value: '#e77d65', contrast: 'rgba(0, 0, 0, 0.87)'},
        {label: '400', value: '#e16144', contrast: 'white'},
        {label: '500', value: '#d84727', contrast: 'white'},
        {label: '600', value: '#d44023', contrast: 'white'},
        {label: '700', value: '#ce371d', contrast: 'white'},
        {label: '800', value: '#c82f17', contrast: 'white'},
        {label: '900', value: '#bf200e', contrast: 'white'}
    ];

    variablesPalette = [
        {name: 'primary-text', colour: ' #1e1e1f'},
        {name: 'primary-colour', colour: ' #3a3a3c'},
        {name: 'primary-colour-hover', colour: ' #454547'},
        {name: 'dark-disabled', colour: ' #575757'},
        {name: 'form-element-border', colour: ' #6d6e70'},
        {name: 'secondary-color-grey', colour: ' #888888'},
        {name: 'tile-header', colour: ' #7b7b7d'},
        {name: 'table-border-grey', colour: ' #bebebe'},
        {name: 'text-button-hover', colour: ' #bebebe'},
        {name: 'dropdown-selected', colour: ' #c1c1c1'},
        {name: 'secondary-color-grey-hover', colour: ' #cccccc'},
        {name: 'contrast-colour', colour: ' #dbd3d2'},
        {name: 'form-grey', colour: ' #e6e4e1'},
        {name: 'dropdown-hover', colour: ' #F3F3F3'},
        {name: 'off-white', colour: ' #f5f5f5'},
        {name: 'background', colour: ' #f8f8f8'},
        {name: 'date-period-dark', colour: ' #9e8b68'},
        {name: 'date-period', colour: ' #c2b49b'},
        {name: 'date-period-light', colour: ' #d7cfc4'},
        {name: 'date-period-text', colour: ' #6d6e70'},
        {name: 'link', colour: ' white'},
        {name: 'light-grey', colour: ' #dbd3d2'},
        {name: 'login-grey', colour: ' #dbd3d2'},
        {name: 'login-pass', colour: ' #a0958f'},
        {name: 'wire-orange', colour: ' rgb(216, 71, 39)'}, // = #d84727'},
        {name: 'dark-orange', colour: ' darkorange'},
        {name: 'medium-gradient', colour: ' rgba(236, 104, 59, 1)'},
        {name: 'light-medium-gradient', colour: ' rgba(241, 135, 63, 1)'},
        {name: 'red', colour: ' red'},
        {name: 'error', colour: 'red'},
        {name: 'error-text', colour: ' #d10404'},
        {name: 'unfavourable', colour: 'red'},
        {name: 'red-orange', colour: ' rgba(236, 104, 59, 1)'},
        {name: 'favourable', colour: ' rgba(0, 128, 0, 0.9)'},
        {name: 'success', colour: ' rgba(0, 128, 0, 0.9)'},];
}
