import {AfterViewInit, ViewChild, Component, Input, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {
    CdkDragDrop,
    CdkDropList,
    moveItemInArray,
    transferArrayItem,
    CdkDragRelease,
    CdkDragMove,
    CdkDrag
} from '@angular/cdk/drag-drop';
import {forkJoin, Observable, of, Subject} from "rxjs";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {
    FolderEntry,
    isFolderEntry,
    MenuEntry,
    MenuTreeService,
    TreeEntry, DraggableTreeNodeEntry
} from "../../services/menu-tree.service";
import {SessionState} from "../../_models/session-state";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {catchError, takeUntil, tap} from "rxjs/operators";
import {IDMap} from "../../_typing/generic-types";
import {FolderSessionState} from "../../_models/folder-session-state";
import {Folder} from "../../_models/folder";
import {NotificationService} from "../../services/notification.service";
import {BaseComponent} from "../../shared/base.component";
import {DragdropTreeService} from "./dragdrop-tree.service";


@Component({
    selector: 'dragdrop-tree',
    templateUrl: './dragdrop-tree.component.html',
    styleUrls: ['./dragdrop-tree.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DragdropTreeComponent extends BaseComponent implements AfterViewInit {
    @Input() node: DraggableTreeNodeEntry;
    @Input() expandedNodes: IDMap<boolean> = {};

    isDragging: boolean = false;
    editMode: boolean = false;

    @ViewChild(CdkDropList) dropList?: CdkDropList;

    allowDropPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        return this.isDropAllowed(drag, drop);
    };

    constructor(
        public appScope: AppScope,
        private api: ApiService,
        public headerData: HeaderDataService,
        private notification: NotificationService,
        public dragDropService: DragdropTreeService) {
        super();
    }

    ngOnInit() {
        this.headerData.page_edit_toggle.pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.editMode = value;
            });
    }

    ngAfterViewInit() {
        if (this.dropList) {
            this.dragDropService.register(this.dropList);
        }
    }

    isDropAllowed(drag: CdkDrag, drop: CdkDropList) {
        if ((this.dragDropService.currentHoverDropListId === 'top' || drop.id==='top') && drag.data?.type === 1) return false;
        if (this.dragDropService.currentHoverDropListId == null) {
            return true;
        }

        return drop.id === this.dragDropService.currentHoverDropListId;
    }

    dragMoved(event: CdkDragMove<DraggableTreeNodeEntry>) {
        this.dragDropService.dragMoved(event);
    }

    dragReleased(event: CdkDragRelease) {
        this.dragDropService.dragReleased(event);
    }

    onNodeToggled(node) {
        if (this.isDragging) return;
        this.expandedNodes[node.id] = !this.expandedNodes[node.id];
    }

    onNodeHover(node) {
        if (!this.isDragging) return;
        if (!this.expandedNodes[node.id]) {
            setTimeout(() => {
                this.expandedNodes[node.id] = true;
            }, 1000); // Delay expansion for smoother UI
        }
    }

    drop(event: CdkDragDrop<DraggableTreeNodeEntry[]>) {
        const draggedNode = event.item.data;

        if (event.container.id === 'top' && draggedNode.type === 1) return false;
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            const targetNode = event.container.data[event.currentIndex];
            if (targetNode && targetNode.type === 2) {
                this.isDragging = false;
                return;
            }

            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        const $obs: Observable<FolderSessionState | Folder>[] = [];
        for (const [index, item] of event.container.data.entries()) {
            if (isFolderEntry(item)) {
                $obs.push(this.updateFolder(event.container, event.previousContainer, item, index));
            } else {
                $obs.push(this.updateFolderSessionState(event.container, event.previousContainer, item, index));
            }
        }

        forkJoin($obs).pipe(
            catchError(e => {
                return of(e);
            }))
            .subscribe((results) => {
                if (results?.error) {
                    this.notification.openError("There was a problem and the menu order was not saved. Please check the console for details.");
                } else {
                    this.notification.openSuccess("Menu saved.")
                }
            });

        this.isDragging = false; // Reset after drop
    }


    updateFolderSessionState(container, previousContainer, item: MenuEntry, index: number): Observable<FolderSessionState> {

        let fssModel = new FolderSessionState();
        fssModel.attributes.order = index;
        fssModel.id = item.folder_session_state_id;
        if (previousContainer.id === container.id) {
            delete fssModel.relationships;
        } else {
            fssModel.relationships.folder.data = {id: container.id, type: 'folder'};
            delete fssModel.relationships.session_state;
        }
        return this.api.folder_session_state.obsPatch(fssModel);
    }

    updateFolder(container, previousContainer, item: FolderEntry, index: number): Observable<Folder> {

        let folderModel = new Folder();
        folderModel.id = item.id;
        folderModel.attributes.order = index;
        if (previousContainer.id === container.id) {
            delete folderModel.relationships;
        } else {
            folderModel.relationships.parent.data = container.id === 'top' ? null : {id: container.id, type: 'folder'};
            delete folderModel.relationships.session_states;
        }
        return this.api.folder.obsPatch(folderModel);

    }

    onDragStarted(event) {
        this.isDragging = true;
    }

    closeSidenav() {
        if (!this.isDragging) { // Only close if not dragging
            this.appScope.sidenavOpenSubject.next(false);
            this.appScope.sidenav_open = false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
