<div class="form-dialog">
  <h1><span>Edit context component</span>
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <form class="form-section column space-between">

          <ng-container *ngIf="context_config">
            <mat-form-field (mouseenter)="hint='Tile_Title'">
              <mat-label>Title</mat-label>
              <input [(ngModel)]="context_config.title"
                     matInput name="context_title"/>
            </mat-form-field>
            <div class="row" *ngIf="context_config.height !==undefined && context_config.width !==undefined">
              <mat-form-field (mouseenter)="hint='Tile_Title'">
                <mat-label>Width</mat-label>
                <input [(ngModel)]="context_config.width"
                       matInput name="context_width" type="number" step="1" min="50" max="2000"/>
              </mat-form-field>

              <mat-form-field (mouseenter)="hint='Tile_Title'">
                <mat-label>Height</mat-label>
                <input [(ngModel)]="context_config.height"
                       matInput name="context_height" type="number" step="1" min="50" max="1000"/>
              </mat-form-field>
            </div>
            <context-form [config]="context_config.config"></context-form>
          </ng-container>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save">Save</button>

  </div>
</div>
