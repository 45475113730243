import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../../shared/base.component";
import {FieldMap, QueryBuilderConfigType, Rule, RuleSet} from "../../../_typing/query-builder";
import {QueryBuilderFormService} from "../../../services/query-builder-form.service";
import {ComponentTypeFieldOptionService} from "../rule/component-type-field-option.service";
import {EventTypeFieldOptionService} from "../rule/event-type-field-option.service";
import {Observable} from "rxjs";
import {
    COMPONENT_EVENTS_CONFIG
} from "../../../forms/component-events-table-form/component-events-table-form.component";
import {takeUntil} from "rxjs/operators";
import {ModelID} from "../../../_typing/generic-types";
import {isComponentEventsConfig} from "../../../_typing/config/component-events-table";
import {isCustomEventsConfig} from "../../../_typing/config/custom-events-table";

@Component({
    selector: 'validation-builder',
    templateUrl: './validation-builder.component.html',
    styleUrls: ['./validation-builder.component.less', '../../../forms/query-builder-form/query-builder-form.component.less'],
    providers: [QueryBuilderFormService, ComponentTypeFieldOptionService, EventTypeFieldOptionService],
    standalone: false
})

export class ValidationBuilderComponent extends BaseComponent {
    @Input() modelTypeId: ModelID;
    @Input() validationQuery: RuleSet;
    @Output() validationQueryChange: EventEmitter<RuleSet> = new EventEmitter<RuleSet>();

    @Input() set config(config: QueryBuilderConfigType) {
        this._config = config;
        if (config) {
            this.loadBuilder();
        }
    };

    private _config: QueryBuilderConfigType;
    get config(): QueryBuilderConfigType {
        return this._config;
    }

    private readonly defaultQuery: RuleSet = {
        condition: 'and',
        rules: []
    };

    fields: FieldMap;

    constructor(private queryBuilderFormService: QueryBuilderFormService,
                private ctFieldOptionService: ComponentTypeFieldOptionService,
                private etFieldOptionService: EventTypeFieldOptionService) {
        super();
    }

    loadBuilder() {
        let $fieldMap: Observable<FieldMap>;
        if (isComponentEventsConfig(this.config)) {
            $fieldMap = this.ctFieldOptionService.loadComponentType(this.config as COMPONENT_EVENTS_CONFIG, false);
        } else if (isCustomEventsConfig(this.config) && this.modelTypeId) {
            $fieldMap = this.etFieldOptionService.loadEventType(this.config, this.modelTypeId, false);
        }

        $fieldMap.pipe(takeUntil(this.onDestroy)).subscribe(fields => {
            this.fields = {...fields};
            Object.keys(this.fields).map(key => this.addIdtoField(key, this.fields[key]));
            this.validationQuery = this.validationQuery || this.defaultQuery;
        });
    }

    ruleSetChanged() {
        this.validationQueryChange.next(this.validationQuery);
    }

    private addIdtoField(key, value) {
        return Object.assign(value, {value: key});
    }

}
