import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ExportComponentConfig} from "../component-events-table-form/component-events-table-form.component";


@Component({
    selector: 'export-component-dialog',
    templateUrl: './export-component-dialog.component.html',
    styleUrls: ['./export-component-dialog.component.less'],
    standalone: false
})
export class ExportComponentDialogComponent {

    action: string;
    local_data: any;
    supported_export_handlers: string[];

    constructor(
        public dialogRef: MatDialogRef<ExportComponentDialogComponent>,
        // @Optional() is used to prevent error if no data is passed
        @Optional() @Inject(MAT_DIALOG_DATA) public data: ExportComponentConfig
    ) {

        this.local_data = {...data};
        this.action = this.local_data.action;
        this.supported_export_handlers = this.local_data.supported_export_handlers;
    }

    onChangeBulkExport(event: any) {
        this.local_data.supports_bulk_export = event.checked;
    }

    onChangeRowExport(event: any) {
        this.local_data.supports_row_export = event;
    }

    doAction() {
        this.dialogRef.close({event: this.action, data: this.local_data});
    }

    closeDialog() {
        this.dialogRef.close({event: 'Cancel'});
    }

}
