import {PlotType} from 'plotly.js-dist-min';
import {KeyMap, KeyMapArray} from '../../_typing/generic-types';
import {GenericChartTileConfiguration} from "./generic-chart-tile.configuration";
import {Annotations} from "plotly.js";

/**This is supposed to cover a variety of values and formats for any chart type
 *
 * **/

export interface ChartFont {
    family?: string;
    size?: number;
    colour?: string;
}

export interface BaseChartStylesConfiguration {
    x_axis_font?: Partial<ChartFont>;
    y_axis_font?: Partial<ChartFont>;
    group_font?: Partial<ChartFont>;
    data_font?: Partial<ChartFont>;
    title_font?: Partial<ChartFont>;

}

export interface IBaseChartConfiguration {
    /**e.g. added via 'x' in plotly and 'columns array' in c3*/
    key_values: string[]; //An array of id values by which to identify each item (plot/chart_series) in the config dictionaries
    x_values: KeyMapArray<string>;
    y_values: KeyMapArray<string>;
    x_axis_type?: string;
    y_axis_type?: string;
    y_min?: number;
    y_max?: number;
    range_names?: string[];
    colours?: {
        negative?: string;
        positive?: string;
        total?: string;
    };
    hidden?: { axes?: boolean, tick_labels?: boolean, legend?: boolean; };
    hover_template?: string[];
    labels?: {
        title?: string;
        sub_title?: string;
        y_axis?: string;
        x_axis?: string;
    };
    no_ticks?: number;
    orientation?: string;
    show_data_labels?: boolean;
    series_chart_types?: { [key: string]: SeriesChartType | PlotType };
    series_dict?: KeyMap<string>;
    size?: { height?: number; width?: number; };
    styles?: BaseChartStylesConfiguration;
    text_values?: KeyMap<string[]>;
    tick_space?: number;
    type: string;
    text_orientation?: KeyMap<number>;
    y_axis_format?: string;
    x_ticks?: { values: string[]; text?: string[]; }
}

export type BaseChartConfiguration = Partial<IBaseChartConfiguration>;

export type SeriesChartType = 'line' | 'spc' | 'bar' | 'budget';
export const ChartTypeSeriesChartTypeDict = {
    'custom': {line: 'line', area: 'area', bar: 'bar', budget: 'target'},
    'donut': {donut: 'donut'},
    'gauge': {gauge: 'gauge'},
    'pie': {pie: 'pie'},
    'rar': {bar: 'bar', line: 'line'},
    'stacked bar': {bar: 'bar'}
}

export const ChartTypeArray = ['custom', 'donut', 'gauge', 'pie', 'rar', 'stacked bar'] as const;
export type ChartType = typeof ChartTypeArray[number];
export const RoundCharts = ['donut', 'gauge', 'pie'];

export const RarRangeTypeArray = ['the past three months', 'the past seven days', 'yesterday'] as const;
export type RarRangeType = typeof RarRangeTypeArray[number];

export const RarRangeDict = {
    'yesterday': {
        sample_period: 'day',
        group_name: 'yesterday',
        number_of_periods: 1
    },
    'the past seven days': {
        sample_period: 'day',
        group_name: 'the past seven days',
        number_of_periods: 7
    },
    'the past three months': {
        sample_period: 'month',
        group_name: 'the past three months',
        number_of_periods: 3
    }
}
export const styleKeys = ['title_font', 'x_axis_font', 'y_axis_font', 'data_font'];

export interface IWaterfallChartConfiguration extends IBaseChartConfiguration {
    measure_values: string[];
    flux: string[];
    group_values?: KeyMapArray<string>
}

export type WaterfallChartConfiguration = Partial<IWaterfallChartConfiguration>;

export interface IRarChartConfiguration extends IBaseChartConfiguration {
    barmode: string;
    names?: KeyMap<string>;
    connect_gaps: boolean[];
    target_annotations: Partial<Annotations>[];
    line_styles: Partial<{ width: number, color: string, style: string }>
}

export type RarChartConfiguration = Partial<IRarChartConfiguration>;

export interface IBarChartConfiguration extends IBaseChartConfiguration {
    barmode: string;
    names?: KeyMap<string>;
}

export type BarChartConfiguration = Partial<IBarChartConfiguration>;

export type UnionChartConfiguration =
    Partial<WaterfallChartConfiguration>
    | Partial<RarChartConfiguration>
    | Partial<BarChartConfiguration>;

export function isBarChartConfiguration(config: UnionChartConfiguration): config is BarChartConfiguration {
    return config.hasOwnProperty('barmode');
}

export function isWaterfallChartConfig(config: UnionChartConfiguration): config is WaterfallChartConfiguration {
    return (config as WaterfallChartConfiguration).type === 'waterfall';
}

export function isRarChartConfiguration(config: UnionChartConfiguration): config is RarChartConfiguration {
    return (config as RarChartConfiguration).type === 'rar';
}
export function newCustomChartConfig(): GenericChartTileConfiguration {
    return {
        labels: {
            title: null,
            sub_title: null
        },
        chart_type: 'custom',
        hide_comments: true,
        hide_legend: false,
        hide_tick_labels: false,
        hide_axes: false,
        set_range: false,
        y_min: null,
        y_max: null,
        y2_min: null,
        y2_max: null,
        min: null,
        max: null,
        width: null,
        chart_title: null,
        series_list: [{
            axis: 'y',
            colour: null,
            cumulative: false,
            name: null,
            show_limits: false,
            type: 'line',
            line_type: null,
            line_thickness: null,
            range: null,
            sample_period: null,
            custom_legend_name: null,
            vertical_data_labels: true
        }]
    };
}
