import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest} from "rxjs";
import {delay, map} from "rxjs/operators";
import {Socket} from "ngx-socket-io";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {LoadingBarService} from '@ngx-loading-bar/core';
import {OfflineDialogComponent} from "../components/offline-dialog/offline-dialog.component";
import {CheckOnlineStateBridgeService} from "./check-online-state-bridge.service";

/**
 * Polls whether user is online.
 *
 * If detects offline for at least 5 seconds, opens modal `OfflineDialogComponent`
 * alerting the user "Unable to connect to WIRE"
 */
@Injectable({
    providedIn: 'root'
})
export class CheckOnlineStateService {

    private $shouldPoll = new BehaviorSubject<boolean>(true);
    public $isOnline = new BehaviorSubject<boolean>(true);

    private _haveWaitingForStartup = false;

    private _currentState = true;

    constructor(private socket: Socket,
                public overlay: Overlay,
                private loader: LoadingBarService,
                private checkOnlineStateBridgeService: CheckOnlineStateBridgeService) {

        // only start showing dialogs after 5 seconds, otherwise it immediately shows the dialog
        setTimeout(() => this._haveWaitingForStartup = true, 5000);

        this.socket.fromEvent("connect").pipe(
            delay(5000),
            map(() => {
                if (this.$shouldPoll.getValue() && this._haveWaitingForStartup) {
                    this.$isOnline.next(true);
                } else {
                    console.log('ignored poll state for online');
                }
            })
        ).subscribe();

        socket.fromEvent("disconnect").pipe(
            delay(5000),
            map(() => {
                if (this.$shouldPoll.getValue() && this._haveWaitingForStartup) {
                    this.$isOnline.next(false);
                } else {
                    console.log('ignored poll state for offline');
                }
            })
        ).subscribe();

        combineLatest([this.loader.progress$, this.$isOnline])
        .pipe(delay(5000))
        .subscribe(([loaderProgres, isOnline]) => {
            let newState = Boolean(loaderProgres) || isOnline;
            if (newState !== this._currentState) {
                this._currentState = newState;
                this.setState(newState);
            }
        });

        this.checkOnlineStateBridgeService.$disable.subscribe(() => {
            this.disableCheck();
        });
    }

    private overlayRef: OverlayRef;

    setState(state) {
        if (state) {
            console.log('went online, hide overlay');
            if (this.overlayRef) {
                this.overlayRef.dispose();
                this.overlayRef = null;
            }

        } else {
            console.log('went offline, show overlay');

            let config = new OverlayConfig();
            config.positionStrategy = this.overlay.position()
                .global().centerHorizontally().centerVertically();
            config.hasBackdrop = true;
            this.overlayRef = this.overlay.create(config);
            const offlinePortal = new ComponentPortal(OfflineDialogComponent);
            this.overlayRef.attach(offlinePortal);
        }
    }

    enableCheck() {
        this.$shouldPoll.next(true);
    }

    disableCheck() {
        this.$shouldPoll.next(false);
        this.$isOnline.next(true);
    }
}
