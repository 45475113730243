import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../shared/base.component";
import {Group} from "../../_models/group";
import {SessionState} from "../../_models/session-state";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {RelationshipGroupSelectorService} from "./relationship-group-selector.service";
import {takeUntil, tap} from "rxjs/operators";
import {ModelRelationshipNameDict} from "../../services/api/filter_utils";
import {ModelID} from "../../_typing/generic-types";
import {SaveableBase, SaveService} from '../../services/save/save.service';
import {RelationshipPageSelectorService} from "../relationship-page-selector/relationship-page-selector.service";
import {User} from "../../_models/users";

@Component({
    selector: 'relationship-group-selector',
    templateUrl: './relationship-group-selector.component.html',
    styleUrls: ['./relationship-group-selector.component.less'],
    providers: [RelationshipGroupSelectorService],
    standalone: false
})
export class RelationshipGroupSelectorComponent extends SaveableBase {
    @Input() model: User | SessionState;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<Group>;
    applyFilter = this.cps.applyFilter;
    columns = this.cps.columns;
    filter_string = '';
    selected_groups: { id: ModelID }[];
    custom_selected_filters = [];
    reverse_custom_selected_filters = [];
    title = '';

    constructor(private cps: RelationshipGroupSelectorService,
                private saveService: SaveService) {
        super();
        this.cps.dataSourceSubject.pipe(
            tap(ds => {
                this.dataSource = ds;
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.cps.selectedChangedSubject.pipe(
            tap(selected => {
                this.selected_groups = selected || [];
            }),
            takeUntil(this.onDestroy)
        ).subscribe();


        this.cps.saveSubject.pipe(
            tap((errors) => {
                this.publishStatus({
                    key: 'relationship-group-selector',
                    value: errors.length > 0 ? 'error' : 'success',
                    errors: errors
                })
                this.setCanSave(this.cps.hasChanges(this.selected_groups));
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe((refresh_after_save) => {
            this.publishStatus({key: 'relationship-group-selector', value: 'saving', errors: []});
            this.cps.save(this.selected_groups, this.model, refresh_after_save);
        });
        this.saveService.setCanSave(() => this.cps.hasChanges(this.selected_groups));
    }

    ngOnInit(): void {
        if (this.model.type === 'users') {
            this.title = 'Groups assigned to this user';
        } else {
            this.title = 'Groups assigned to this page';
        }
        this.custom_selected_filters = this.cps.generateGroupsByRelationshipFilter(this.model.id, ModelRelationshipNameDict.group[this.model.type]);
        this.reverse_custom_selected_filters = [{not: this.custom_selected_filters[0]}];
        setTimeout(() => {
            this.cps.sort = this.sort;
            this.cps.paginator = this.paginator;
            this.cps.initialise(this.model);
        })
    }

    groupsSelected(event: Group[]) {
        this.selected_groups = event;
        this.cps.groupListChanged(event);
        this.setCanSave(this.cps.hasChanges(this.selected_groups));
    }

    updateSort(event: Sort): void {
        this.cps.updateSort(this.sort)
    }

}
