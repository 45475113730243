import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as utils from "../../../lib/utils";
import {httpParamSerializer} from "../../../lib/utils";
import {DateTimePeriodService} from "../../../services/date-time-period.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ChartAPI, ChartConfiguration, generate, Primitive, TooltipOptions} from "c3";
import {moment} from "../../../services/timezone-selector.service";
import {User} from "../../../_models/users";
import {IDateTimePeriod} from "../../../_typing/date-time-period";
import {RarChartService} from "../../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";

@Component({
    selector: 'user-activity-chart',
    templateUrl: './user-activity-chart.component.html',
    styleUrls: ['./user-activity-chart.component.css'],
    standalone: false
})
export class UserActivityChartComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @ViewChild('chart_anchor') chart_anchor: ElementRef;

    @Input() user: User;

    private chart: ChartAPI;

    constructor(
        private http: HttpClient,
        public dateTimePeriodService: DateTimePeriodService,
        private dateInst: DateTimeInstanceService,
        private ref: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
            this.loadChart(this.user.id, dtp)

        });

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            setTimeout(() => {
                this.loadChart(this.user.id, dtp)
            }, 100);
        });
    }

    loadChart(user_id, dtp: IDateTimePeriod) {
        const ctrl = this;

        let params = {
            'user_id': user_id,
            'start': dtp.start.toISOString(),
            'end': dtp.end.toISOString(),
            'sample_period': dtp.sample_period.name
        };
        this.http.get('/api/UserVisit/Visits' + '?' + httpParamSerializer(params)).subscribe(response => {

            const data: { [time: string]: number } = response['data'][this.user.attributes.name];
            // TODO convert these times to local
            // @ts-ignore
            const times: [string, ...Primitive[]] = Object.keys(data).map(d => new Date(d));
            // @ts-ignore
            const values: [string, ...Primitive[]] = Object.values(data);

            times.unshift('x');
            values.unshift(this.user.attributes.name);

            const config: ChartConfiguration = {
                data: {
                    x: 'x',
                    xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                    columns: [
                        times,
                        values,
                    ]
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: x => {
                                // @ts-ignore
                                return ctrl.dateTimePeriodService.sample_dict[ctrl.dateTimePeriodService.dtp.sample_period.name].format(x, ctrl.dateTimePeriodService)
                            }
                        }
                    },
                    y: {
                        min: 0,
                        label: {text: 'Minutes', position: 'outer-middle'}
                    },
                },
                bindto: this.chart_anchor.nativeElement,
                color: {
                    pattern: ['#F1873F']
                }
            };
            try {
                const zone_name = moment.tz.guess();
                const timezone = moment.tz(zone_name).zoneAbbr();
                config.axis.x.label = {text: zone_name + ' (' + timezone + ')', position: 'outer-center'}
            } catch (e) {
                console.warn('Failed to get timezone', e)
            }

            let custom_tool_tip: TooltipOptions = {
                format: {
                    title: (name, _) => '' + name,
                    // @ts-ignore
                    value: (value, ratio, id, index) => utils.significantNumber(value)
                }
            };
            custom_tool_tip['contents'] = (d, titleFormat, valueFormat, color) => {
                let text;
                let header = '';
                if (d[0] && d[0].x) {
                    // @ts-ignore
                    header = ctrl.dateTimePeriodService.sample_dict[dtp.sample_period.name].format(d[0].x, dtp);
                }
                text = "<table class='c3-tooltip c3-tooltip-custom'><tbody><tr><th colspan='2'>" + header + "</th></tr>";
                d.forEach(s => {
                    text += "<tr class='c3-tooltip-name'>";
                    text += "<td class='value'>" + s.value + "</td></tr>";
                });
                return text + "</tbody></table>";
            };

            config.tooltip = custom_tool_tip;

            if (this.chart) {
                this.chart.destroy();
                this.chart = null;
            }
            this.chart = generate(config);
            this.ref.markForCheck();
        })

    }

    ngOnDestroy() {
        this.chart.destroy();
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
