import {Directive} from '@angular/core';
import {AppScope} from '../services/app_scope.service';

import {ApiService} from "../services/api/api.service";

@Directive({
    selector: '[appUserInteraction]',
    host: {
        '(document:click)': 'userInteractionDetected($event)',
        '(window:keypress)': 'userInteractionDetected($event)',
    },
    standalone: false
})
export class UserInteractionDirective {
    last_sent: any;
    submit_interval = 45; //Submit only every 45 seconds
    bound_func: any; //This is used to store the userInteractionDetected function bound to this so that the add and remove event signatures match

    constructor(private appScope: AppScope,
                private api: ApiService) {
        this.bound_func = this.userInteractionDetected.bind(this);
    }

    ngOnInit() {
        window.addEventListener('scroll', this.bound_func, true); // third parameter
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.bound_func, true);
    }

    submitUserActivity(event) {
        let authenticated: boolean;
        this.appScope.authComplete$.subscribe((authIsComplete: boolean) => {
            authenticated = authIsComplete;
        });
        if (authenticated) {
            this.api.get('/api/UserVisit/Update').subscribe();
        }
    }

    userInteractionDetected(event) {
        if (!this.last_sent) {
            this.submitUserActivity(event);
        } else {
            let now = new Date();
            let diff = (now.getTime() - this.last_sent.getTime()) / (1000);
            if (diff > this.submit_interval) {
                this.submitUserActivity(event);
            }
        }
        this.last_sent = new Date();
    }

}
