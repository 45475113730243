import {Injectable, OnDestroy} from "@angular/core";
import {from, Observable, of, Subject} from "rxjs";
import {httpParamSerializer} from "../lib/utils";
import {ComponentConstantField, ConstantValue, GenericConstantApiResponse} from '../_models/api/generic-constant';
import {ModelAttribute, ModelID} from '../_typing/generic-types';
import { HttpClient } from '@angular/common/http';
import {last, mergeMap} from "rxjs/operators";

/**
 * This class is for shared functions relating to data access on the component_type api
 **/
@Injectable({
    providedIn: 'root'
})
export class GenericConstantDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    saveComponentConstants(component_constants: ComponentConstantField[]) {
        const concurrentRequests = 10;
        const $constants = [];
        component_constants.forEach(cc => {
            if (typeof cc.value === 'string') {
                cc.value = cc.value.trim();
            }
            $constants.push(this.saveComponentConstant(cc.component_id, cc.constant_property_id, cc.value));
        });
        return from($constants).pipe(
            mergeMap(obj => obj, concurrentRequests),
            last());
    }

    saveComponentConstant(component_id: ModelID, property_id: ModelID, value: ConstantValue): Observable<GenericConstantApiResponse> {
        const params = {
            'component_id': component_id,
            'constant_property_id': property_id,
            'value': value
        };
        return this.http.post('/api/constant/component', params);
    }

    saveEventConstant(event_id: ModelID, property_id: ModelID, value: ConstantValue): Observable<GenericConstantApiResponse> {
        const params = {
            'event_id': event_id,
            'constant_property_id': property_id,
            'value': value
        };
        return this.http.post('/api/constant/event', params);
    }

    getComponentConstants(component_ids: ModelID[], constant_properties_ids: ModelID[], attributes?: ModelAttribute[]): Observable<GenericConstantApiResponse> {
        if (!component_ids.length || (constant_properties_ids.length <= 0 && attributes && attributes.length <= 0)) {
            return of({data: {}});
        }
        const params = {
            'component_ids': component_ids,
            'constant_property_ids': constant_properties_ids
        };
        if (attributes) {
            params['attributes'] = attributes;
        }
        const serializedParams = httpParamSerializer(params);
        return this.http.get('/api/constant/component', {params: serializedParams});
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
