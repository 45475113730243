import {BaseSeriesToken} from "./series-token";

export class SeriesSummaryTextToken extends BaseSeriesToken{
    constructor(
        // Full qualifier for this token in the original text (including surround brackets)
        public readonly full_token: string,
        public readonly series_name: string,
        public readonly column_name: string
    ) {
        super(full_token);
    }

    public get data_key(): string {
        return `${this.series_name}@${this.column_name}`
    }

    public static extractTextTokens(text: string): SeriesSummaryTextToken[] {
        // TODO replace all this with [...text.matchAll(/(\[([ \w]+)@([ \w]+)\])/gm)]
        //  needs update to ES2020 compile target (ie. Angular upgrade).
        const regexp = new RegExp(/\[([ \w.,_-]+)@([ \w%.,_-]+)\]/gm);
        let match;

        const tokens = [];

        // index 1: full token
        // index 2: series name
        // index 3: column name
        while ((match = regexp.exec(text)) !== null) {
            let token = new SeriesSummaryTextToken(match[0], match[1], match[2]);
            tokens.push(token);
        }

        return tokens;
    }
}
