import {
    AfterViewInit,
    Directive,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ElementRef
} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {fromEvent, Subject} from 'rxjs';

@Directive({
    selector: '[msDivInfiniteScroll]',
    standalone: false
})
export class DivInfiniteScrollDirective implements OnInit, OnDestroy, AfterViewInit {

    @Input() threshold = '15%';
    @Input() debounceTime = 150;
    @Input() complete!: boolean;
    @Input() items_loaded = 10;
    @Input() total_items: number;
    @Input() item_height = 46;
    @Output() infiniteScroll = new EventEmitter<number>();

    private panel!: Element;
    private thrPx = 0;
    private thrPc = 0;
    private destroyed$ = new Subject<boolean>();

    constructor(private element: ElementRef, private ngZone: NgZone) {
    }

    ngOnInit() {
        this.evaluateThreshold();
    }

    ngAfterViewInit() {
        this.panel = this.element.nativeElement;
        this.registerScrollListener();
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    evaluateThreshold() {
        if (this.threshold.lastIndexOf('%') > -1) {
            this.thrPx = 0;
            this.thrPc = (parseFloat(this.threshold) / 100);

        } else {
            this.thrPx = parseFloat(this.threshold);
            this.thrPc = 0;
        }
    }

    registerScrollListener() {
        fromEvent(this.panel, 'scroll').pipe(
            takeUntil(this.destroyed$),
            debounceTime(this.debounceTime),
            tap((event) => {
                //TODO don't load on scrollup
                this.handleScrollEvent(event);
            })
        ).subscribe();
    }

    handleScrollEvent(event: any) {
        this.ngZone.runOutsideAngular(() => {
            if (this.complete) {
                return;
            }
            const threshold = this.thrPc !== 0 ? (this.panel.clientHeight * this.thrPc) : this.thrPx;
            const scrolledDistance = this.panel.clientHeight + event.target.scrollTop;

            const page = Math.ceil(event.target.scrollTop / (this.item_height * 10));

            if ((event.target.scrollTop + this.panel.clientHeight) >= this.items_loaded * this.item_height) {
                this.ngZone.run(() => this.infiniteScroll.emit(page));
            }
            // if (this.panel.clientHeight >= this.panel.scrollHeight || scrolledDistance >= (this.panel.scrollHeight - threshold)) {
            //     this.ngZone.run(() => this.infiniteScroll.emit());
            // }
        });
    }

}
