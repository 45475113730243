import {ProcessAccess} from "./process-access";

export class ProcessPermissions {
    access: ProcessAccess[] = [];
    permissions: { [feature_name: string]: boolean } = {};

    constructor(processAccesses: ProcessAccess[]) {
        this.access = processAccesses;

        processAccesses.forEach(pa => {
            this.permissions[pa.attributes.feature_name] = true;
        });
    }
}
