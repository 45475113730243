<div class="component-type-conditions">
  <div>
    <ng-container *ngIf="parent.length">
      <h3 class="row" (click)="expanded=!expanded;" style="cursor:pointer;margin-bottom:0;">
        <mat-icon *ngIf="!expanded">arrow_right</mat-icon>
        <mat-icon *ngIf="expanded">arrow_drop_down</mat-icon>
        Edit conditions for component type {{component_type.attributes.name}}
      </h3>
    </ng-container>
    <div class="row column-selector" *ngIf="expanded || !parent.length">
      <div class="draggable-list-container">
        <h2>Selected Columns</h2>
        <mat-form-field [floatLabel]="'never'" class="property-search-input">
          <input [(ngModel)]='selectedColumnFilterValue' matInput placeholder="Search" autocomplete="off">
        </mat-form-field>
        <div #selectedList="cdkDropList"
             id="selectedList"
             (cdkDropListDropped)="drop($event)"
             [cdkDropListConnectedTo]="[availableList]"
             [cdkDropListData]="selectedColumns"
             cdkDropList
             class="draggable-list">

          <div
              *ngFor="let option of selectedColumns | filterByValue: columnFilter.bind(this) : selectedColumnFilterValue"
              cdkDrag [id]="option"
              class="draggable-list-item"
              style="display:flex; justify-content: space-between;">
            <div style="flex: 1.5">{{option| upperfirst}}</div>
            <div style="flex: 1;">
              <input *ngIf="!col_info_dict[option]" [(ngModel)]="col_value_dict[option]"
                     matInput (ngModelChange)="emitEvent()" name="tile_title" style="width: auto"/>
              <mat-checkbox
                  *ngIf="col_info_dict[option] && col_info_dict[option].type == 'component_type'"
                  [(ngModel)]="col_value_dict['[component_type@' + col_info_dict[option].id +']'].is_set"
                  (ngModelChange)="emitEvent()"
                  name="show_header"> Is Set
              </mat-checkbox>
              <input *ngIf="col_info_dict[option] && col_info_dict[option].type == 'constant_property'"
                     matInput
                     [(ngModel)]="col_value_dict['[constant_property@' + col_info_dict[option].id +']']"
                     (ngModelChange)="emitEvent()"
                     [name]="option"/>
            </div>
            <div style="display: flex; flex: 0.5; justify-content: right;">
              <span *ngIf="col_info_dict[option] && col_info_dict[option].type=='component_type'">(CT)</span>
              <span *ngIf="col_info_dict[option] && col_info_dict[option].type=='constant_property'">(CP)</span>
            </div>
          </div>
        </div>
      </div>

      <div class="draggable-list-container">
        <h2>Available Column</h2>
        <mat-form-field [floatLabel]="'never'" class="property-search-input">
          <input [(ngModel)]='availableColumnFilterValue' matInput placeholder="Search" autocomplete="off">
        </mat-form-field>
        <div #availableList="cdkDropList"
             id="availableList"
             (cdkDropListDropped)="drop($event)"
             [cdkDropListConnectedTo]="[selectedList]"
             [cdkDropListData]="available_column_options"
             cdkDropList
             class="draggable-list">
          <div
              *ngFor="let option of available_column_options | filterByValue: columnFilter.bind(this) : availableColumnFilterValue"
              cdkDrag class="draggable-list-item" [id]="option"
              style="display:flex; justify-content: space-between;">
            <div style="flex: 1.5;">
              {{option| upperfirst}}
            </div>
            <div style="flex: 1">
              <input *ngIf="!col_info_dict[option]" [(ngModel)]="col_value_dict[option]"
                     matInput
                     [name]="option" style="width: auto"/>
              <mat-checkbox
                  *ngIf="col_info_dict[option] && col_info_dict[option].type == 'component_type'"
                  [(ngModel)]="col_value_dict['[component_type@' + col_info_dict[option].id +']'].is_set"
                  [name]="option"> Is Set
              </mat-checkbox>
              <input *ngIf="col_info_dict[option] && col_info_dict[option].type == 'constant_property'"
                     matInput
                     [(ngModel)]="col_value_dict['[constant_property@' + col_info_dict[option].id +']']"
                     [name]="option"/>
            </div>
            <div style="display: flex; flex: 0.5; justify-content: right;">
                <span *ngIf="col_info_dict[option]">
                  <span *ngIf="col_info_dict[option].type=='component_type'">(CT)</span>
                  <span *ngIf="col_info_dict[option].type=='constant_property'">(CP)</span>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
