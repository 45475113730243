<div class="component-creator form-dialog">
  <div class="row component-type-select">
    <select-filter
        *ngIf="config.component_types?.length>0"
        (constant_changed)="setUpForm($event)"
        [modelValue]="component_type"
        [options]="config.component_types"
        [filter_compare]="eventsService.compareById"
        [classes]="'bordered-select full-width'"
        label="Please select your Component Type to get started"
        class="select-search">
    </select-filter>
  </div>

  <ng-container *ngIf="component_type">
    <div class="row min-height info" style="text-align: left;margin:0 0 10px 8px;">Please select your configuration for
      creating
      {{name}}s and then click Create.
    </div>
    <div class="row center">
      <div class="column date-time-picker-form-field">
        <label matTooltip="Please select a date if different from today.">Select a different date.</label>
        <date-time-picker (date_changed)="setJulianDay($event)" [input_date]="selected_date" [show_time]="false"
                          [max]="max_date" [min]="min_date"
        ></date-time-picker>
      </div>
      <button class="refresh form-element-align" mat-raised-button color="primary">
        <i (click)="seeCount();" class="fa fa-refresh"
           matTooltip="Click to see the component count for your selected date."></i>
      </button>
      <label class="form-element-align">Julian day: <span style="font-size:1.2rem;">{{selected_julian}}</span></label>
    </div>

    <div class="row center full-width component-type-config">
      <mat-form-field>
        <mat-label>{{name | removeUnderscore | titlecaseForWire}} Abbreviation</mat-label>
        <input [(ngModel)]="batch_type" [disabled]="nameFormulaString" matInput step="1" style="box-sizing:border-box;"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{name | removeUnderscore | titlecaseForWire}} Size</mat-label>
        <input [(ngModel)]="batch_size" [disabled]="nameFormulaString" matInput step="1" style="box-sizing:border-box;" type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Prefix (optional)</mat-label>
        <input [(ngModel)]="prefix" (ngModelChange)="setNameFormulaString()" matInput style="box-sizing:border-box;"/>
      </mat-form-field>
    </div>

    <div class="name-formula" *ngIf="nameFormulaString">
      <h4>The following formula will be used to create {{component_type?.attributes?.name}} components:</h4>
      <div class="name-formula-string">{{nameFormulaString}}</div>
    </div>

    <div class="row end" *ngIf="useManualCountStart()">
      <mat-form-field>
        <mat-label>Count starting at <i class="fa fa-info-circle"
         matTooltip="Input the first value which needs to be included in the new component name"></i></mat-label>
        <input [(ngModel)]="manualCount"  matInput step="1" style="box-sizing:border-box;"/>
      </mat-form-field>
    </div>

    <div class="row end">
      <mat-form-field>
        <mat-label>Number of {{name}}s to generate</mat-label>
        <input [(ngModel)]="no_of_components" matInput max="200" min="1" step="1" style="box-sizing:border-box;"
               type="number"/>
      </mat-form-field>
    </div>

    <div class="row center justify-center">
      <button class="submit-button" (click)="startCreateComponents()"
              [disabled]="!component_type"
              mat-raised-button color="primary">Create
      </button>
    </div>
    <div *ngIf="progress" class="info progress"><i class="fa fa-info-circle"></i> {{progress}}
      <!--  <span *ngIf="saving">{{db_component_count - count}}</span>-->
    </div>
    <div *ngIf="error" class="info error"><i class="fas fa-exclamation-circle"></i> {{error}}</div>


    <!--Table showing today's components-->
    <h3>{{name | upperfirst}}s. Count for
      <span *ngIf="!nameFormulaString" [innerHTML]="selected_date | stringDate: {date_only: true} + ': ' + db_component_count"></span>
      <span *ngIf="nameFormulaString" [innerHTML]="countResetFrequency + ' reset name formula'"></span>
      <i class="fa fa-info-circle" style="margin-left:4px;"
         [matTooltip]="'This count is the total number of ' + component_type.attributes.name +  's created on the selected date. The table below shows up to the last 20 created.'"></i>
    </h3>
    <div *ngIf="components && components.length > 0">
      <table class="table event-type-series-table" style="width:100%;border:1px solid #ccc;">
        <thead>
        <tr>
          <th>Count</th>
          <th>Type</th>
          <th>{{name}} No.</th>
          <th>Created</th>
          <th>Created By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let component of components; let i = index">
          <td>{{i + 1}}</td>
          <td>{{api_base}}</td>
          <td>{{component.attributes.name}}
          </td>
          <td [innerHTML]="component.attributes.created_on | stringDate : {date_only: true}"></td>
          <td>{{component.attributes.created_by_name}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>
