import {Component, OnInit} from '@angular/core';
import {AppScope} from '../../../services/app_scope.service';
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";

@Component({
    selector: 'app-pdf-footer',
    templateUrl: './pdf-footer.component.html',
    styleUrls: ['./pdf-footer.component.css'],
    standalone: false
})
export class PdfFooterComponent implements OnInit {

    constructor(public dateInst: DateTimeInstanceService,
                public appScope: AppScope) {
    }

    ngOnInit(): void {
    }

    currentDate() {
        return new Date();
    }

}
