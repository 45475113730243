import {EventEmitter, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import * as utils from '../lib/utils';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DownloadFormDialogComponent} from "../forms/file-download-form/file_download_form.component";
import {EditHistoryComponent} from "../forms/edit-history.component";
import {AppScope} from './app_scope.service';
import {BehaviorSubject, ReplaySubject, Subject, Observable, throwError} from "rxjs";
import {DateTimePeriodService} from "./date-time-period.service";
import {catchError, takeUntil, tap} from "rxjs/operators";
import {ReportIssueFormDialogComponent} from '../forms/report-issue-form-dialog/report-issue-form-dialog.component';
import {BreadcrumbsService} from "../menu-bars/breadcrumbs/breadcrumbs.service";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {Process} from "../_models/process";
import {NotificationService} from "./notification.service";
import {KeycloakService} from "keycloak-angular";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {SnackbarComponent} from "../notifications/snackbar/snackbar.component";
import {ModelID} from "../_typing/generic-types";
import {MenuItem} from "../menu-bars/header.component";
import {SeriesDataService} from "./series_data.service";
import {
    CalculationErrorNotificationComponent
} from "../notifications/calculation-error-notification/calculation-error-notification.component";
import {JobNotificationComponent} from "../notifications/job-notification/job-notification.component";

interface IButtons {
    name: string;
    func: (params?: any) => any;
    params: any;
    class: string;
    toggle: boolean;
}

type ButtonsType = Partial<IButtons>;

@Injectable({
    providedIn: 'root'
})

export class HeaderDataService {
    buttons: ButtonsType[] = [];
    component_buttons_loaded: boolean = false;

    public _title: string = 'Wire';
    public titleSubject: Subject<string> = new BehaviorSubject('Wire');

    readonly printToPdfClicked: Subject<void> = new Subject<void>();
    toggleGridMode: Subject<boolean> = new Subject<boolean>();
    toggleLayoutMode: Subject<boolean> = new Subject<boolean>();
    page_edit_toggle: ReplaySubject<boolean> = new ReplaySubject(1);
    tile_edit_mode: BehaviorSubject<boolean> = new BehaviorSubject(false); // Tells the page if any tile is being edited (layout)
    page_print_toggle: ReplaySubject<boolean> = new ReplaySubject(1);
    hideWireLogo: ReplaySubject<boolean> = new ReplaySubject(1);
    page_edit_mode: boolean = false;
    public showingCommentPanel: ReplaySubject<{ toggle: boolean, comment_data?: any }> = new ReplaySubject<{
        toggle: false
    }>(1);
    //TODO Move these to AppScope
    readonly dtpReset: EventEmitter<IDateTimePeriod>;
    readonly dtpChanged: EventEmitter<IDateTimePeriod>;

    public refreshVDTNodeSubject: Subject<ModelID[]> = new Subject();

    add_new_page_form: boolean = false;
    add_print: boolean = false;
    add_comment: boolean = false;
    add_upload: boolean = false;
    add_edit: boolean = true;
    show_dtp: boolean = false; // whether a dtp is available on the page
    dtp_visible: boolean = false; // whether a dtp is hidden or not (mobile)
    // finalPath = () => /[^/]*$/.exec(this.appScope.breadcrumbs)[0];
    add_present: boolean = true;
    page_menu_options: any = {
        'insights': 'Insights',
        'settings': 'Settings',
        'my_views': 'My Views'
    }; // defaults

    showing_comments: boolean = false;
    private readonly onDestroy: Subject<void> = new Subject<void>();
    // Emitted by PageViewComponent when printing. Can be used by any components that need to manually adapt
    public pagePrinting: Subject<boolean> = new Subject();
    public pageCommentButtonClicked: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public page_selected = false;
    public hidePageLoader: boolean = false;
    public right_logo: string;
    public refreshTileSubject = new Subject<string>();

    constructor(private http: HttpClient,
                private titleService: Title,
                public dialog: MatDialog,
                private appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,
                private breadcrumbs: BreadcrumbsService,
                private keycloak: KeycloakService,
                private seriesData: SeriesDataService,
                private notification: NotificationService) {
        this.dtpReset = new EventEmitter();
        this.dtpChanged = new EventEmitter();
        this.page_edit_toggle.pipe(takeUntil(this.onDestroy))
            .subscribe((newBool: boolean) => {
                this.page_edit_mode = newBool;
            });
    }

    ngOnInit(): void {
    }

    addDownload() {
        if (this.buttons.find(b => b.name === 'Download')) return;
        this.buttons.push(
            {
                name: 'Download',
                func: this.downloadData.bind(this),
                params: '',
                class: ''
            }
        );
    }

    removeDownload(): void {
        if (!this.buttons) {
            return;
        }
        this.buttons = this.buttons.slice(this.buttons.indexOf(this.buttons.find((b: ButtonsType): boolean => b.name === 'Download')));
    }

    downloadData(): void {
        this.openDownloadDialog();
    }

    addSection(id?) {
        return {
            id: id ? id : null,
            title: null,
            'class': 'medium',
            // height: this.section_height_dict["medium"].value,
            // fixed_height: this.section_height_dict["medium"].fixed,
            cols: 4,
            rows: 4,
            x: null,
            y: null,
            tiles: []
        };
    }

    reset(): void {
        this.buttons = [];
        this.component_buttons_loaded = false;
        this.show_dtp = false;
        this.add_new_page_form = false;
        this.add_comment = false;
        this.add_print = false;
        this.removeDownload();
        this.add_upload = false;
        this.add_edit = false;
        this.breadcrumbs.resetBreadcrumbs();
    }

    setPath(process: Process) {
        this.breadcrumbs.setPath(process);
    }

    setFolder(session) {
        this.breadcrumbs.setFolder(session);
    }

    set title(title: string) {
        this.titleService.setTitle(title);
        this._title = title;
        setTimeout(() => {
            this.titleSubject.next(title);
        });
    }

    get title(): string {
        return this._title;
    }

    public folder_options() {
        if (this.appScope.config_name_map['page_grouping_options']) {
            this.page_menu_options = this.appScope.config_name_map['page_grouping_options'].value;
        }
        return this.page_menu_options;
    }

    buildRightMenu() {
        let menu_items = [];
        let createMenuItem = function (name, link, item_class?, angular_link?, func?) {
            return {
                'name': name,
                'link': link,
                'class': item_class,
                angular_page: angular_link,
                func: func
            };
        };
        if (this.appScope.isNotMobile) {
            menu_items.push(
                createMenuItem('Report Issue',
                    null,
                    'fa fa-unlink', false,
                    this.openReportIssueDialog.bind(this)));
        }
        menu_items.push(
            createMenuItem('Help',
                '/view/help-page',
                'fa fa-question-circle', true));

        const active_account_id = this.appScope.active_account_id;

        const is_admin_on_current_account = this.appScope.isUserAdminOnCurrentAccount(active_account_id);

        let feature_names = this.appScope.current_user.feature_names;

        if (is_admin_on_current_account && feature_names.includes("launch_admin_gui") && this.appScope.isNotMobile) {
            menu_items.push(createMenuItem('Admin Portal', '/admin', 'fa fa-database'));
        }

        if ((is_admin_on_current_account || feature_names.includes('edit users') ||
            feature_names.includes('view manage users') && this.appScope.isNotMobile)) {
            menu_items.push(createMenuItem('Manage Users', '/view/user_list', 'fa fa-users', true));
            menu_items.push(createMenuItem('Register User', '/view/register_user', 'fa fa-user', true));
            menu_items.push(createMenuItem('Access Token', '/view/access_token', 'fa fa-user', true));
        }

        if (this.appScope.current_user.keycloak_user) {
            menu_items.push(new MenuItem({
                name: 'My Account',
                link: this.keycloak.getKeycloakInstance().createAccountUrl(),
                style_class: 'fa fa-users'
            }));
        } else {
            menu_items.push(new MenuItem({
                name: 'My Security',
                link: '/view/my_security',
                style_class: 'fa fa-users',
                angular_page: true
            }));
        }

        // TODO Update the logout method to properly cleanup state
        menu_items.push(new MenuItem({
            name: 'Logout', func: () => {
                this.notification.openInfo("Logging out...");
                this.appScope.logout();
            }, style_class: 'fa fa-sign-out'
        }));

        return {name: this.appScope.current_user.name, items: menu_items};
    }

    isImage(src) {
        return new Promise<boolean>((resolve, reject) => {
            const image: HTMLImageElement = new Image();
            image.onerror = () => resolve(false);
            image.onload = () => resolve(true);
            image.src = src;
        });
    }

    openDownloadDialog(data?): void {
        const config: MatDialogConfig = {};
        if (data) {
            config.data = data;
        }
        config.panelClass = ['default-form-dialog', 'download-form-dialog'];
        const dialogRef = this.dialog.open(DownloadFormDialogComponent, config);
    }

    openReportIssueDialog() {
        const config: MatDialogConfig = {};
        config.data = {"page_title": this.title};
        config.panelClass = 'report-issue-form-dialog';
        const dialogRef = this.dialog.open(ReportIssueFormDialogComponent, config);
    }

    queueAggregations(dtp: IDateTimePeriod, seriesList: string[]): Observable<any> {
        if (!dtp.start || !dtp?.end) {
            this.notification.openError(
                'Please refresh your Time Traveler then try again.',
                10000
            );
        }

        const params = {
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
            series_string: seriesList.join(','),
            override: true,
        };

        const url = '/api/utils/BulkImportHistorianStatusData?' + utils.httpParamSerializer(params);

        return this.http.get(url).pipe(
            tap({
                next: (data: any) => {
                    this.notification.openSuccess('Aggregations updated successfully', 2000);
                },
                error: (error: any) => {
                    const errorMessage = error?.error?.message || 'Failed to update aggregations.';
                    this.notification.openError(errorMessage, 10000);
                },
            }),
        );
    }

    getCalculations(dtp, series_list, sample_period, extra_hours) {
        if (!dtp?.start && dtp?.end) {
            this.notification.openError("Please refresh your Time Traveler then try again.", 10000);
        }
        let p = new Promise((resolve, reject) => {
            if (confirm('Selecting to update calculations will delete and replace all existing calculations in the selected range.')) {
                // TODO replace these with a better deep copy method
                // This is needed for the six day moving average
                // let early_start = Object.assign({}, dtp.start);

                let early_start = utils.deepCopy(dtp.start);
                // let early_start = angular.copy(dtp.start);
                early_start.setDate(early_start.getDate() - 6);

                let extra_end = utils.deepCopy(dtp.end)['addHours'](extra_hours);
                // let extra_end = Object.assign({}, dtp.end)['addHours'](extra_hours);

                let params = {
                    series_list: series_list,
                    deepness: 4,
                    insert_from_time: dtp.start.toISOString(),
                    start: early_start.toISOString(),
                    end: extra_end.toISOString(),
                    insert_data: true,
                    deep: true,
                    sample_period: sample_period,
                    run_dependent_calcs: true,
                    override: true
                };

                this.http.get("/api/GetCalcs" + '?' + utils.httpParamSerializer(params)).pipe(tap({
                    next: data => {
                        const jobId = data['job'];
                        this.showJobSuccess(jobId, "Calculation");
                        this.sendEvent(true, series_list);
                        resolve(data);
                    }, error: error => {
                        if (error === 'Not confirmed.') {
                            this.notification.openError('Failed to update calculations ' + error);
                        } else if (error.status === 423) {
                            this.notification.openError(error.error.message, 10000);
                        } else if (this.checkCalculationError(error, "Error updating calculations.")) {
                            //Handled in checkCalculationError
                        } else {
                            let snack: MatSnackBarRef<SnackbarComponent> = this.notification.openError(
                                `Failed to update calculations error`);
                        }
                        this.sendEvent(false, series_list);
                        reject(error);
                    }
                })).subscribe();

            } else {
                reject("Not confirmed.");
            }
        });
        return p;
    }

    showJobSuccess(jobId: ModelID, jobType: string): void {
        if (!jobType) {
            jobType = "Task";
        }
        this.notification.openDynamicSnackbar(JobNotificationComponent, {jobId: jobId, jobType: jobType, styleType: 'success'});
    }

    checkCalculationError(response: { status: number, error: { error: string } }, msg: string = "Calculation formula error found."): boolean {
        const errorDetails = response.error?.error;
        const regex = /Calculation with name (.+?)'s formula/;
        const match = errorDetails?.match(regex);
        console.log("err", response.status === 422, errorDetails);
        if (response.status === 422 && match?.[1]) {
            this.notification.openDynamicSnackbar(CalculationErrorNotificationComponent, {
                errorMessage: msg,
                errorDetails: errorDetails,
                seriesName: match[1],
                styleType: 'error'
            });

            //Return boolean doesn't need to wait for the subscription, just needs to report whether a calc error was found
            return true;
        }
        return false;
    }

    sendEvent(success: boolean, series_list) {
        (<any>window).dataLayer.push({
            event: 'updatecalculations',
            seriesList: series_list,
            success: success,
            click: 'updated calcs ' + success
        });
        // (<any>window).dataLayer.push({'click': 'updated calcs ' + success});

    }

    // correctionFactor(ev, dtp, series_list)
    correctionFactor(series?): MatDialogRef<EditHistoryComponent> {
        // TODO implement when MatDialog is added
        return this.dialog.open(EditHistoryComponent, {data: {series: series}});
    }

    toggleCommentPanel(toggle, comment_data) {
        this.showing_comments = toggle;
        this.showingCommentPanel.next({toggle: toggle, comment_data: comment_data});
    }

// TODO add constructor to session-state.ts (SessionState type)
    emptyPageJson() {
        return {
            id: null,
            relationships: {
                folders: {data: []},
                default_folder: {data: {}},
                user: {
                    data: {
                        id: this.appScope.current_user.id,
                        type: 'users'
                    }
                }
            },
            attributes: {
                json: {
                    // extracted: true, //Just for safety, can be removed once python migration is run
                    auto_refresh: false,
                    sample_period: 'hour',
                    calendar: this.dateTimePeriodService.defaultCalendar,
                    layout: "tile",
                    print_orientation: "portrait-widescreen",
                    range: 'yesterday',
                    refresh_time: 60,
                    sections: []
                },
                range: 'yesterday',
                report: 'Dashboard',
                download_url: '',
                name: null
            },
            type: 'session_state'
        };
    }
}
