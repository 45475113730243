import {replaceAll} from '../lib/utils';
import {Injectable, OnDestroy} from "@angular/core";
import {HandleError, HttpErrorHandler} from "./http-error-handler.service";
import {catchError, concatMap, first, take, takeUntil, tap} from "rxjs/operators";
import {ApiService} from "./api/api.service";
import {SeriesDataService} from './series_data.service';
import {uniq as _uniq} from "lodash-es";
import {forkJoin, from, Observable, of, Subject} from "rxjs";
import {SearchQueryOptions} from "./api/search-query-options";
import {FormatNumberPipe} from "../shared/pipes";
import {SeriesSummaryTextToken} from "../_models/utils/string-tokens/series-summary-text-token";
import {SeriesSummaries} from "../_models/api/series-summary";
import {SeriesType} from "../_models/series-type";
import {SeriesDataStatusToken} from "../_models/utils/string-tokens/series-data-status-token";
import {SeriesTokenPair} from "../_models/utils/string-tokens/series-token";
import {IDateTimePeriod} from "../_typing/date-time-period";

@Injectable()
export class SeriesStringParser implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private readonly handleError: HandleError;

    constructor(private api: ApiService,
                private seriesData: SeriesDataService,
                private formatNumberPipe: FormatNumberPipe,
                httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('SeriesStringParser');
    }

    private getFormattedValueReplacement(value: any, series_name: string, summary_col: string, decimal_places: null | number) {
        /**
         * Gets formatted values for series-element *
         * *
         * The method has one parts: *
         * 1. Gets formatted value for series element *
         */
        if (!value && value !== 0) {
            return '';
        } else if (isNaN(value)) {
            return this.addClickThroughAnchor((value), series_name);
        } else {
            let decimals: number;
            decimals = (decimal_places || decimal_places === 0) ? decimal_places : 3;
            if (this.seriesData.column_dict && this.seriesData.column_dict[summary_col] && this.seriesData.column_dict[summary_col]
                .contentFilter === 'percentage') {
                return this.addClickThroughAnchor(this.formatNumberPipe.transform(value * 100, decimals), series_name);
            } else {
                return this.addClickThroughAnchor(this.formatNumberPipe.transform(value, decimals), series_name);
            }
        }
    }

    parseSeriesString(text, dtp: IDateTimePeriod): Observable<string> {
        /**
         * Gets series summaries and displays limit breach formatting. *
         * *
         * The method has three parts: *
         * 1. Extracting summary and status tokens from the given text. Then search for series with those tokens. *
         * 2. Generates query for GSS and associated Series objects with tokens. *
         * 3. Formats summaries and statuses onto the given text. *
         */

        if ((typeof text) !== "string") {
            return of(`Invalid text type: ${typeof text}`);
        }

        const summary_tokens = SeriesSummaryTextToken.extractTextTokens(text);
        const summary_series_names = Array.from(new Set(summary_tokens.map(n => n.series_name)));

        if (summary_tokens.length < 1) {
            // No summary data on this text, just return the text;
            return of(text);
        }

        const status_tokens = SeriesDataStatusToken.extractTextTokens(text);
        const series_status_names = Array.from(new Set(status_tokens.map(n => n.series_name)));

        const all_series_names = summary_series_names.concat(series_status_names);

        const $est_types = from(this.seriesData.$estimate_types.promise);

        // Get full series for the requested series
        const query = new SearchQueryOptions();
        query.filters = [
            {name: 'name', op: 'in', val: all_series_names}
        ];
        const $series = this.api.series.searchMany(query);
        const status_token_pairs = [];

        return forkJoin([$est_types, $series]).pipe(concatMap(r => {
            const estimate_types: SeriesType[] = r[0];
            const all_series_list = r[1].data;

            const series_name_map = {};
            const series_id_map = {};
            all_series_list.forEach(series => {
                series_name_map[series.attributes.name] = series;
                series_id_map[series.id] = series;
            });

            const getIdsFromNameList = (series_names: string[]): string[] => {
                /**
                 * For each name in list, get the associated id.
                 */
                const ids: Set<string> = new Set();
                series_names.forEach(name => {
                    const series = series_name_map[name];
                    if (series) {
                        ids.add(series.id);
                    }
                });
                return Array.from(ids);
            };

            status_tokens.forEach(token => {
                const name = token.series_name;
                const series = series_name_map[name];
                const pair = new SeriesTokenPair(token, series);
                status_token_pairs.push(pair);
            });

            const summary_ids = getIdsFromNameList(all_series_names);
            // TODO flag summary series which didn't exist to aid debugging on the tile

            const summary_column_names_set = new Set(summary_tokens.map(n => n.column_name));
            if (status_tokens.length > 1) {
                summary_column_names_set.add('Value');
            }
            const summary_column_names = Array.from(summary_column_names_set);

            const estTypeList = this.seriesData.getEstimateTypesList(summary_column_names, estimate_types);

            return this.seriesData.getSeriesSummary(dtp, _uniq(summary_ids), null, _uniq(summary_column_names), _uniq(estTypeList), null, true).pipe(
                catchError(this.handleError('parseSeriesString', []))
            );
        }), concatMap((summary: SeriesSummaries) => {
            // Flat K/V of all summaries and associated data value.
            const data_set: { [identifier: string]: any } = {};
            summary.forEach(s => {
                const name = s.Name;
                Object.keys(s).forEach(key => {
                    if (['id', 'index'].includes(key)) {
                        return;
                    }
                    data_set[name + '@' + key] = s[key];
                });
            });

            text = this.detectLimits(status_token_pairs, data_set, text);

            // for each summary token, replace full token with value in data set.
            let display_text: string = text;
            summary_tokens.forEach(token => {
                const data_key = token.data_key;
                const value = data_set[data_key];
                const decimal_key = `${token.series_name}@DecimalPlaces`;
                const decimal_value = data_set[decimal_key];
                const value_str = this.getFormattedValueReplacement(value, token.series_name, token.column_name, decimal_value);
                display_text = replaceAll(display_text, token.full_token, value_str);
            });

            return of(display_text);
        }));
    }

    addClickThroughAnchor(text, series) {
        return '<span class="series-element" id="' + series + '">' + text + '</span>';
    }

    simpleParse(text: string) {
        const tokens = SeriesSummaryTextToken.extractTextTokens(text);
        tokens.forEach(token => {
            text = text.replace(token.full_token, '...');
        });
        return text;
    }

    detectLimits(token_pairs: SeriesTokenPair[], data_set: { [key: string]: any }, text: string): string {
        token_pairs.forEach(pair => {
            const series = pair.series;
            const key = pair.token.data_key;
            const value = data_set[key];

            let class_name = 'ok';
            // TODO raise some warning here if there isn't a series for this pair
            //  ie. we couldn't find the series with name.

            if (value === "" || value === null || isNaN(value)) {
                class_name = '';
            }
            if (value > series.attributes.hi || value < series.attributes.low) {
                class_name = 'warning';
            }
            if (value > series.attributes.hihi || value < series.attributes.lowlow) {
                class_name = 'error';
            }
            text = replaceAll(text, pair.token.full_token, `class="${class_name}"`);
        });
        return text;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
