import {Injectable, OnDestroy} from "@angular/core";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {IDObject, ModelID} from '../../_typing/generic-types';
import {BasePaginatedTableService} from "../../tables/base-paginated-table.service";
import {ApiService} from '../../services/api/api.service';
import {GenericDataService} from "../../data/generic-data.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ListResponse} from "../../services/api/response-types";
import {takeUntil, tap} from "rxjs/operators";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {Group} from "../../_models/group";
import {SessionState} from '../../_models/session-state';
import {ModelRelationshipNameDict} from '../../services/api/filter_utils';
import {GroupDataService} from "../../data/group-data.service";
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";

import {User} from "../../_models/users";
import {NotificationService} from "../../services/notification.service";

@Injectable()

export class RelationshipGroupSelectorService extends BasePaginatedTableService<Group> {
    columns = ['name'].concat(this.audit_columns);
    group_list: Group[];
    private original_group_list: Group[] = []; // Track the original state of the group list for change detection

    public readonly selectedRelGroupsSubject: ReplaySubject<{ id: ModelID }[]> = new ReplaySubject(1);

    constructor(private groupDataService: GroupDataService,
                notification: NotificationService,
                relMapping: RelationshipApiMappingService,
                api: ApiService,
                genericData: GenericDataService
    ) {
        super(genericData, api, notification, relMapping);
    }

    initialise(model: SessionState | User) {
        this.this_type = 'group';
        this.getRelationshipMappingRows(model);
        const initialQuery: SearchQueryOptions = this.getInitialSearchOptions(model);
        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<Group>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            tap((result: ListResponse<Group>) => {
                this.group_list = result.data;
                this.dataSourceSubject.next(this.dataSource);
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
    }

    save(selectedGroups:IDObject[], model: SessionState | User, refresh = true) {
        if (this.hasChanges(selectedGroups)) {
            this.upsertModelRelationshipMap(selectedGroups, model, refresh);
        }
    }

    private getInitialSearchOptions(model: SessionState | User): SearchQueryOptions {
        const options = new SearchQueryOptions(this.page_size, 1, 'name');
        options.filters = this.generateGroupsByRelationshipFilter(model.id, ModelRelationshipNameDict.group[model.type]);
        this.initial_filters = options.filters;
        return options;
    }

    generateGroupsByRelationshipFilter(id: ModelID, rel_name) {
        return this.groupDataService.generateGroupsByRelationshipIdFilter(rel_name, id);
    }

    page(query: SearchQueryOptions): Observable<ListResponse<Group>> {
        return super.page(query, 'group');
    }

    groupListChanged(list: Group[]) {
        this.group_list = list;
    }
}
