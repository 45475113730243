<div class="custom-snackbar">
  <div class="message">
      {{ jobType }} in progress. Please click
    <a (click)="snackBarRef.dismiss()" [routerLink]="['/view/jobs/', jobId]">here</a> to view progress.
  </div>

  <div class="action-class">
    <!--    <a mat-raised-button [routerLink]="['/view/jobs/', jobId]">View job details</a>-->
    <button mat-raised-button (click)="snackBarRef.dismiss()" class="snack-bar-dismiss-button">
      <i class="fa fa-times snack-bar-icon" matTooltip="Close" [matTooltipShowDelay]="2000"></i>
    </button>
  </div>
</div>
