import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Component as WireComponent} from '../../_models/component';
import {ComponentType} from '../../_models/component-type';
import {ConstantProperty} from '../../_models/constant-property';
import {IPendingContextConfig} from '../../forms/pending-context-form/pending-context-form.component';
import {PendingContext} from '../../_models/pending-context';
import {
    catchError,
    concatMap,
    exhaustMap,
    finalize,
    first,
    map,
    takeUntil,
    tap
} from 'rxjs/operators';
import {forkJoin, interval, merge, Observable, of, Subject, timer} from 'rxjs';
import {ListResponse} from '../../services/api/response-types';
import {ApiService} from '../../services/api/api.service';
import * as utils from '../../lib/utils';
import {AppScope} from '../../services/app_scope.service';
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {NotificationService} from "../../services/notification.service";
import { HttpStatusCode } from "@angular/common/http";
import {
    EXTENDED_NOTIFICATION_DURATION_MS,
    MAX_POLLING_DURATION_MS,
    NOTIFICATION_DURATION_MS, POLLING_INTERVAL_MS
} from "../../shared/globals";

@Component({
    selector: 'pending-context',
    templateUrl: './pending-context.component.html',
    styleUrls: ['./pending-context.component.less', '../../shared/styles/polling-indicator.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class PendingContextComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constant_properties: ConstantProperty[];
    cp_dict: { [key: string]: ConstantProperty } = {};
    components: WireComponent[];
    component_type: ComponentType;
    component_type_from: ComponentType;
    component_type_to: ComponentType;

    @Input() config: IPendingContextConfig;
    pending: PendingContext;
    validation: { [key: string]: { type?: string, message?: string } } = {};
    ct_id: string;
    ct_from_id: string;
    ct_to_id: string;

    polling: boolean = false;
    pollingStatus: string = 'Processing...'

    constructor(private api: ApiService,
                private notification: NotificationService,
                private propertyDataService: ConstantPropertyDataService) {
    }

    ngOnInit(): void {
        if (!this.config || !this.config.context) {
            this.notification.openError('Please use the tile form to set up the configuration for this tile.');
            return;
        }
        if (this.config.type === 'event_to_component') {
            if (!this.config.context.component_type?.id || !this.config.context.event_type?.id
                || this.config.context.event_type?.constant_properties?.length < 1) {
                this.notification.openError('Configuration for Pending Context tile is incomplete. ');
                return;
            }

            // ct_id is just used for quick access to the component_type id (mostly in the html)
            this.ct_id = this.config.context.component_type.id;

            // Gets the constant property names
            const cp_ids = this.config.context.event_type.constant_properties.map(cp => cp.id);
            this.propertyDataService.getConstantProperties(cp_ids).pipe(first(), takeUntil(this.onDestroy),
                tap((result: ListResponse<ConstantProperty>) => {
                    this.constant_properties = result.data;
                    this.constant_properties.forEach(cp => this.cp_dict[cp.id] = cp);
                })).subscribe();
        } else {
            if (!this.config.context.component_type_from?.id || !this.config.context.component_type_to?.id) {
                this.notification.openError('Configuration for Pending Context tile is incomplete. ');
                return;
            }
            this.ct_from_id = this.config.context.component_type_from.id;
            this.ct_to_id = this.config.context.component_type_to.id;
        }

        this.clearForm();
        if (this.config.component) {
            this.pending.attributes.context.component_type_from.value = this.config.component.attributes.name;
        }

        // Get the component type name for the component field
        this.getComponentsByType().pipe(first(), takeUntil(this.onDestroy)).subscribe();
    }

    getComponentsByType(): Observable<any> {
        const ctrl = this;
        const $obs = [];
        if (ctrl.config.type === 'event_to_component') {
            $obs.push(ctrl.api.component_type.getById(ctrl.config.context.component_type.id).pipe(tap(result => {
                ctrl.component_type = result.data;
            })));
        } else {
            $obs.push(ctrl.api.component_type.getById(ctrl.config.context.component_type_from.id).pipe(tap(result => {
                ctrl.component_type_from = result.data;
            })));
            $obs.push(ctrl.api.component_type.getById(ctrl.config.context.component_type_to.id).pipe(tap(result => {
                ctrl.component_type_to = result.data;
            })));
        }
        return forkJoin($obs);

        // TODO ? do we need to validate the scanned component against our db list or will that be done when processed by the backend
        // let options = new SearchQueryOptions();
        // options.filters = [
        //     {
        //         op: 'eq',
        //         name: 'account_id',
        //         val: this.appScope.active_account_id
        //     },
        //     {
        //         op: 'has',
        //         name: 'component_type',
        //         val: {name: 'id', op: 'eq', val: ctrl.config.context.component_type.id}
        //     }
        // ];
        // concatMap(result => {
        //     let api_base = result.data.attributes.base_type;
        //     return ctrl.api[api_base].searchMany(options).pipe(tap((response: ListResponse<WireComponent>) => {
        //         this.components = response.data;
        //     }));
        // }))
    }

    validate(key, id, value) {
        const validation = this.config.validation[key]?.[id];
        let valid: boolean;

        if (value === undefined || value === null || value === '') {
            this.validation[id] = {type: 'warning', message: 'Missing value.'};
            return;
        }

        // Max and numeric are also enforced in the inputs themselves but just in case;
        if (validation.max_length && value.toString().length > parseInt(validation.max_length)) {
            this.validation[id] = {type: 'error', message: 'Max length property exceeded.'};
            return false;
        }
        if (validation.min_length && value.toString().length < parseInt(validation.min_length)) {
            this.validation[id] = {type: 'error', message: 'Min length property error.'};
            return false;
        }
        if (validation.only_numeric && !this.isNumber(value)) {
            this.validation[id] = {type: 'error', message: 'Value must be a number.'};
            return false;
        }
        if (validation.only_text && this.hasNumber(value.trim())) {
            this.validation[id] = {type: 'error', message: 'Value must not contain numbers.'};
            return false;
        }
        if (validation.must_contain) {
            const contains = validation.must_contain.split(',').map(item => item.trim());
            contains.forEach(item => {
                if (!(value.toString().includes(item || value === item))) {
                    this.validation[id] = {type: 'error', message: 'Value must include ' + utils.deepCopy(item) + '.'};
                    valid = false;
                }
            });
            if (valid === false) {
                return false;
            }
        }
        this.validation[id] = {type: 'valid', message: 'Valid.'};
    }

    private isNumber(myString: string): boolean {
        return /^-?\d+(.\d+)?$/.test(myString);
    }

    private hasNumber(myString) {
        return /\d/.test(myString);
    }

    save() {
        console.log("here");
        let $pollingComplete = new Subject<void>();
        const maxDuration$ = timer(MAX_POLLING_DURATION_MS);
        const msgSuccess = 'Context was processed successfully';
        const $link = this.api.pending_context.obsSave(this.pending).pipe(
            map(result => {
                console.log("re",result);
                this.polling = true;
                this.notification.openSuccess('Processing...', NOTIFICATION_DURATION_MS);
                return result;
            }),
            catchError((err: any, caught: any) => {
                console.error('PendingContextComponent: Item not saved. ', err, caught);
                this.notification.openError(`An error occurred. ${err.message}`, EXTENDED_NOTIFICATION_DURATION_MS, 'hide');
                return of(err);
            })
        )
        $link.pipe(concatMap(result => {
                const pcId = result.data.id;
                return interval(POLLING_INTERVAL_MS).pipe(
                    takeUntil(merge($pollingComplete, maxDuration$)),
                    exhaustMap(result => this.api.pending_context.getById(pcId)),
                    tap((response) => {
                        let msg = response?.data?.attributes?.processing_message;
                        if (msg?.indexOf(msgSuccess) > -1) {
                            this.polling = false;
                            $pollingComplete.next();
                            this.notification.openSuccess('Items successfully linked', NOTIFICATION_DURATION_MS);
                        } else if (msg?.indexOf("Models were not available") > -1) {
                            if (this.pollingStatus.indexOf("Link request created") === -1) {
                                this.notification.openSuccess('Link request created. Items will be linked when the required fields are matched.', 3000);
                            }
                            this.pollingStatus = "Link request created. Items will be linked when the required fields are matched."
                        } else {
                            this.polling = false;
                            $pollingComplete.next();
                            this.notification.openError(`Linking failed. ${msg}`, EXTENDED_NOTIFICATION_DURATION_MS, 'hide');
                        }
                    }),
                    catchError(error => {
                        if (error.status === HttpStatusCode.NotFound) {
                            this.notification.openSuccess('Items successfully linked', NOTIFICATION_DURATION_MS);
                        } else {
                            this.notification.openError(`Linking failed. ${error.message}`, EXTENDED_NOTIFICATION_DURATION_MS, 'hide');
                            console.error(error);
                        }
                        this.polling = false;
                        $pollingComplete.next();
                        return of(error);
                    })
                )
            }),
            finalize(() => {
                console.log("", this.pollingStatus);
                if (this.polling === true) {
                    this.notification.openInfo('No further changes to this task status have been received.', 3000);
                }
                this.polling = false;
                this.pollingStatus = 'Processing...'
                $pollingComplete.complete();
            })).subscribe();
    }

    clearForm() {
        console.log("clear",);
        this.pending = new PendingContext();
        this.pending.attributes.context = utils.deepCopy(this.config.context);
        this.pending.attributes.context_type = utils.deepCopy(this.config.type);
        this.clearValidation();
    }

    clearValidation() {
        if (this.config.type === 'event_to_component') {
            this.validation[this.ct_id] = {};
            this.config.context.event_type.constant_properties.forEach(prop => {
                this.validation[prop.id] = {};
            });
        } else {
            this.validation[this.ct_from_id] = {};
            this.validation[this.ct_to_id] = {};
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
