import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {tap} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Component({
    selector: 'custom-process-view',
    templateUrl: 'custom-process-sheet-view.component.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class CustomProcessViewComponent implements OnInit {
    property_categories: any;
    allProcesses: any[];
    processes: any;
    parent: boolean;
    data: any;
    sheet_ready: boolean;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.headerData.buttons = [];

        const allProcessesPromise =
            this.api.process.searchMany().pipe(tap(response => this.allProcesses = response.data));

        this.route.params.subscribe(params => {
            const category = params.category;

            const processOptions = new SearchQueryOptions();
            processOptions.filters = [{
                name: 'component_type',
                val: {
                    name: 'property_categories',
                    op: 'any', val: {name: 'name', val: category, op: 'eq'}
                }, op: 'has'
            }];

            const propertyCategoryOptions = new SearchQueryOptions();
            propertyCategoryOptions.filters = [{
                name: 'name',
                val: [category],
                op: 'in'
            }];

            const observables = [];
            observables.push(allProcessesPromise);
            observables.push(this.api.process.searchMany(processOptions).pipe(tap(response => this.processes = response.data)));
            observables.push(this.api.property_category.searchMany(propertyCategoryOptions).pipe(
                tap(response => this.property_categories = response.data))
            );

            forkJoin([observables]).subscribe(this.setupEditSheet.bind(this));
        });
    }

    setupEditSheet() {
        this.parent = false;
        this.sheet_ready = true;
    }
}
