import {Injectable} from "@angular/core";
import {ColumnFormatsConfig, ColumnFormatsConfigDict} from "../forms/table-column-menu/table-column-menu.component";
import {deepCopy} from "../lib/utils";
import {IDMap, KeyMap, KeyMapMap} from "../_typing/generic-types";
import {Event as WireEvent, Event} from "../_models/event";
import {Component} from "../_models/component";
import {GenericConstantData, isInvalid} from '../_models/api/generic-constant';
import {ConstantProperty} from '../_models/constant-property';
import {belongsToEventType, getColId, isTempId} from './custom-events-table/utils';
import {TileDataService} from "../services/tile_data.service";
import {ConfigColumn, EventConfigColumn} from "../_typing/config/config-column";

/**
 * Formats that have been parsed from config and with assumptions made. To be directly used in templates.
 */
export interface ColumnFormats {
    allow_fit: boolean;
    allow_resize: boolean;
    decimals: number;
    style: ColumnFontStylesDict;
    width: number | 'auto';
}

export interface ColumnFormatsDict {
    [key: string]: ColumnFormats;
}

export interface ColumnFontStylesDict {
    'font-weight'?: string;
    'font-style'?: string;
    'font-size'?: string;
    'text-align'?: string;
    'text-decoration'?: string;
    'color'?: string;
    'background-color'?: string;
}

@Injectable()
export class TableUtilsService {
    editing: any = false;
    mousemove_handler: any;
    mouseup_handler: any;
    dragging_column: string = null;
    x: number = null;

    constructor() {
    }

    getColumnFormats(column_formats: ColumnFormatsConfig, defaultValues?: ColumnFormatsConfig): ColumnFormats {
        if (!column_formats) return this.getFormatObject(defaultValues);
        let style_obj = this.getSingleStyles(column_formats);
        let width: number | 'auto' = 'auto';
        let resize, allow_fit = false;
        let decimals;
        if (column_formats.resize === true) {
            resize = true;
        }
        if (column_formats.fit_content === true) {
            allow_fit = true;
        }

        if (column_formats.width && resize === true) {
            width = column_formats.width;
        }
        if (column_formats.decimals || column_formats.decimals === 0) {
            decimals = column_formats.decimals;
        }

        return {
            style: style_obj,
            width: width,
            allow_resize: resize,
            decimals: decimals,
            allow_fit: allow_fit
        };
    }

    getFormatObject(defaultValues?: ColumnFormatsConfig): ColumnFormats {
        return {
            style: this.getStyleObject(),
            width: null,
            allow_resize: true,
            decimals: defaultValues?.hasOwnProperty('decimals') ? defaultValues.decimals : 2,
            allow_fit: false
        };
    }

    getStyleObject(): ColumnFontStylesDict {
        return {'font-weight': null, 'font-style': null, 'font-size': null, 'text-decoration': null};
    }

    getStyles(column_formats: ColumnFormatsConfigDict, column: string): ColumnFontStylesDict {
        let style_obj = this.getStyleObject();
        if (column_formats && column_formats[column]) {
            style_obj = this.getSingleStyles(column_formats[column]);
        }
        delete style_obj["font-size"]; // This is not included in conditional formats
        return style_obj;
    }

    getSingleStyles(column_formats: ColumnFormatsConfig): ColumnFontStylesDict {
        let style_obj = this.getStyleObject();

        if (column_formats.bold === true) {
            style_obj["font-weight"] = 'bold';
        }
        if (column_formats.italic === true) {
            style_obj["font-style"] = 'italic';
        }
        if (column_formats.align) {
            style_obj["text-align"] = column_formats.align;
        }
        let text_decor: string = null;
        if (column_formats.underline) {
            text_decor = 'underline';
        }
        if (column_formats.strikethrough) {
            if (text_decor) {
                text_decor += ' ';
            } else {
                text_decor = '';
            }
            text_decor += 'line-through';
        }
        style_obj["text-decoration"] = text_decor;
        if (column_formats.size) {
            style_obj["font-size"] = column_formats.size + "%";
        }
        if (column_formats.colour) {
            style_obj["color"] = column_formats.colour;
        }
        if (column_formats.background_colour) {
            style_obj["background-color"] = column_formats.background_colour;
        }
        return style_obj;
    }

    combineStyles(columns: ColumnFormatsDict, cells: KeyMapMap<Partial<ColumnFormats>>,
                  rows: (Event | Component)[]): KeyMap<ColumnFormatsDict> {
        const formats: KeyMap<ColumnFormatsDict> = {};
        Object.keys(columns).forEach(col => {
            formats[col] = {};
            rows.forEach(row => {
                const col_styles = deepCopy(columns[col].style);
                const styles = Object.assign(deepCopy(columns[col].style), cells[col]?.[row.id]?.style || {});
                formats[col][row.id] = Object.assign(deepCopy(columns[col]), {style: styles});
            });
        });
        return formats;
    }

    getDisabledBackground(): string {
        return getComputedStyle(document.documentElement).getPropertyValue('--disabled');
    }

    /**This function works out which cells are going to be disabled and updates the background colour accordingly
     * As per current user request, disabled background does not override conditional formatting background**/
    updateStylesForDisabled(cols: EventConfigColumn[], rows: (Component | Event)[], cp_dict: IDMap<ConstantProperty>,
                            generic_constants: GenericConstantData, formats: KeyMap<ColumnFormatsDict>,
                            et_cp_dict?: KeyMap<string[]>, et_ct_dict?: KeyMap<string[]>): KeyMap<ColumnFormatsDict> {
        const disabled = this.getDisabledBackground().trim();

        rows.forEach(row => {
            for (let i = 0; i < cols.length; i++) {
                // start, end, prop_id etc
                const col = cols[i];
                const id = getColId(col);

                /**Events can have more than one type so properties and component_types that don't belong are disabled**/
                /**First reset the cell if it was previously set to disabled**/
                if (row.type === 'event') {
                    const et_id = (row as WireEvent).relationships.event_type.data.id;
                    formats[id][row.id].style["background-color"] =
                        this.resetEventRowDisabled(formats[id][row.id].style["background-color"]);
                    if ((col.type === 'constant_property' || col.type === 'component_type') &&
                        !belongsToEventType(row as WireEvent, id, col.type, et_cp_dict, et_ct_dict)) {
                        formats[id][row.id].style["background-color"] =
                            this.disabledIfNotSet(formats[id][row.id].style["background-color"]);
                    }
                    if (id === 'type' && !isTempId(row)) {
                        formats[id][row.id].style["background-color"] = this.disabledIfNotSet(formats[id][row.id].style["background-color"]);
                    }
                }
                if ((col.type === 'constant_property' && cp_dict[id]?.attributes.is_calculation) || col.disabled) {
                    formats[id][row.id].style["background-color"] = this.disabledIfNotSet(formats[id][row.id].style["background-color"]);
                    continue;
                }
                const constant = generic_constants?.[row.id]?.[col.id];
                if (constant && !isInvalid(constant) && constant.locked && !formats[id][row.id].style["background-color"]) {
                    formats[id][row.id].style["background-color"] = this.disabledIfNotSet(formats[id][row.id].style["background-color"]);
                }
            }
        });
        return formats;
    }

    disabledIfNotSet(cell_format) {
        /**Any other background setting will override the grey disabled background**/
        if (cell_format) return cell_format;
        return this.getDisabledBackground().trim() || '#e6e4e1';
    }

    /**Reset event rows that were previously set to disabled, other colours leave as is.**/
    resetEventRowDisabled(current: string): string {
        const disabled = this.getDisabledBackground().trim();
        if (!current || current === disabled) {
            current = undefined;
        }
        return current;
    }

    startDrag(event, column, format_dict) {
        event.stopPropagation();
        if (this.editing === false) {
            return;
        }
        this.dragging_column = deepCopy(column);
        this.x = event.x;
        format_dict[column].width = event.srcElement.parentElement.parentElement.parentElement.clientWidth;
    }

    onMouseMove(event: MouseEvent, format_dict) {
        if (this.dragging_column === null) {
            return;
        }
        let diff = event.x - this.x;
        format_dict[this.dragging_column].width += diff;
        this.x = deepCopy(event.x);
    }

    mouseUp(event: MouseEvent) {
        this.dragging_column = null;
    }

    removeListeners() {
        if (this.mousemove_handler && this.mouseup_handler) {
            this.mousemove_handler();
            this.mouseup_handler();
        }
    }

    saveTile(column_formats, format_dict, tileData, column_formats_key, id?: string) {
        Object.keys(column_formats).forEach(column => {
            if (column_formats[column] && column_formats[column].resize === true
                && format_dict[column]?.width) {
                column_formats[column].width = format_dict[column].width;
            }
        });

        if (tileData.tile) {
            if (id) {
                tileData.tile.attributes.parameters[column_formats_key][id] = column_formats;
            } else {
                tileData.tile.attributes.parameters[column_formats_key] = column_formats;
            }
            tileData.tileChange.next(tileData.tile);
        }
    }

    emitSaveTile(tileData: TileDataService): void {
        tileData.tileChange.next(tileData.tile);
    }

    updateColumnFormats(columns: EventConfigColumn[], format_dict): (ConfigColumn | EventConfigColumn)[] {
        columns.forEach(column => {
            if (column.format?.resize === true
                && format_dict[getColId(column)]?.width) {
                column.format.width = format_dict[getColId(column)]?.width;
            }
        });
        return columns;
    }

    setTableHeight(utils_bar, elRef?: HTMLElement) {
        /*Set the height of the component within the tile so that you can always see the horizontal scroll when present*/
        let offsetHeight = 0;
        if (elRef?.scrollWidth > elRef?.clientWidth) {
            offsetHeight = 16;
        }

        if (utils_bar) {
            offsetHeight += utils_bar.nativeElement.clientHeight + 1;
        }

        return 'calc(100% - ' + (offsetHeight || 42) + 'px)';
    }

    setDescriptorWidth(column, row, format_dict: ColumnFormatsDict, columns) {
        let width = 'calc(' + format_dict[column].width + 'px';
        if (!columns.includes('Name') && column === 'Description') {
            width += " - 40px";
        }
        if (!columns.includes('Name') && column === 'Description' && row['kpi_level'] === 'Level 1') {
            width += " - 60px";
        }
        width += ")";
        return width;
    }

    refreshCalculationStatus(buttons, response): any[] {
        let tileButtons = buttons;
        let calc_status_icon_index: number = tileButtons.findIndex((o) => o.name === 'calc_status');
        if (response.is_updating && calc_status_icon_index < 0) {
            tileButtons.splice(calc_status_icon_index, 0,
                {
                    name: 'calc_status',
                    class: 'fa small fa-bell hide-xs flash-warning-button is-icon',
                    HoverOverHint: response.message,
                    disabled: true
                },
            );
        } else if (!response.is_updating) {
            if (calc_status_icon_index >= 0) {
                // Remove warning icon next to re-run calculations button
                tileButtons.splice(calc_status_icon_index, 1);
            }
        }
        return tileButtons;
    }
}
