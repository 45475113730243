import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {forkJoin, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, finalize, first, switchMap, takeUntil, tap} from "rxjs/operators";
import {SeriesDataService} from "../../services/series_data.service";
import {EventType} from '../../_models/event-type';
import {ConstantProperty} from '../../_models/constant-property';
import {ApiService} from '../../services/api/api.service';
import {OreBodyType} from "../../_models/ore-body-type";
import {ComponentType} from "../../_models/component-type";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {deepCopy, guid} from "../../lib/utils";
import {SearchQueryOptions} from '../../services/api/search-query-options';
import {ListResponse, SingleResponse} from '../../services/api/response-types';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {PaginationDataSourceWithAddRow} from "../../services/api/pagination-data-source-with-add-row";
import {ConstantPropertyTableFormService} from "./constant-property-table-service.service";
import {SaveService} from "../../services/save/save.service";
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {NotificationService} from "../../services/notification.service";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";

export type CONSTANT_PROPERTY_RELATED_TYPE = EventType | ComponentType | OreBodyType;

@Component({
    selector: 'constant-property-table-form',
    templateUrl: './constant-property-table-form.component.html',
    styleUrls: ['./constant-property-table-form.component.less',
        '../../components/series-audit-history/series-audit-history.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConstantPropertyTableFormService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class ConstantPropertyTableFormComponent implements OnInit {
    selected_properties: ConstantProperty[];
    old_selected_properties: ConstantProperty[];
    selected_properties_filter: any[];
    selected_properties_reverse_filter: any[];
    constant_property_parent_list: any[];
    @Input() hide_select_list = false;
    @Output() cp_saved: EventEmitter<{ constant_property: ConstantProperty, new: boolean }> = new EventEmitter();
    @Output() prop_list_changed: EventEmitter<any[]> = new EventEmitter();

    private $property_list_data: Subject<string> = new Subject<string>();
    private readonly onDestroy = new Subject<void>();

    cp_editing_dict = {};
    constant_property_types = ['string', 'float', 'datetime', 'file'];
    constant_property_aggregations = ['total', 'mean', 'count'];

    filter_string = '';
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSourceWithAddRow<ConstantProperty>;
    columns = ['edit', 'name', 'description', 'alias', 'formula', 'is_drop_down_list', 'data_type', 'is_calculation', 'aggregation', 'json'];
    expandedElement: any | null = null;

    constructor(public seriesData: SeriesDataService,
                private api: ApiService,
                private notification: NotificationService,
                private propertyService: ConstantPropertyTableFormService,
                private saveService: SaveService,
                private relMapping: RelationshipApiMappingService) {
    }

    _parent_component: CONSTANT_PROPERTY_RELATED_TYPE;

    get parent_component(): CONSTANT_PROPERTY_RELATED_TYPE {
        return this._parent_component;
    }

    @Input()
    set parent_component(component: CONSTANT_PROPERTY_RELATED_TYPE) {
        this._parent_component = component;
    }

    ngOnInit() {
        const ctrl = this;
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';
        setTimeout(() => {
            this.paginator.pageSize = 10;
            initialQuery.filters = this.propertyService.refreshPropertyQuery(this.parent_component);

            this.dataSource = new PaginationDataSourceWithAddRow<ConstantProperty>(
                (query) => this.page(query),
                initialQuery,
                this.paginator,
                this.sort
            );
        });
        this.$property_list_data.pipe(debounceTime(1000)).subscribe(() => {
            this.emitFilterQuery();
        });
        this.expandedElement = null;

        /**For the dropdown/select list**/
        const options = new SearchQueryOptions();
        options.filters = this.propertyService.refreshPropertyQuery(this.parent_component);
        this.selected_properties_filter = options.filters;
        this.selected_properties_reverse_filter = this.propertyService.getConstantPropertiesForParentReverseFilter(this.parent_component);
        const $props = this.api.constant_property_light.searchMany(options).pipe(tap(result => {
            this.selected_properties = result.data;
            this.old_selected_properties = deepCopy(result.data);
        }))
        const $prop_parent = this.propertyService.getConstantPropertyParentTypes(this.parent_component)
            .pipe(tap(result => {
                this.constant_property_parent_list = result.data;
            }))
        forkJoin([$props, $prop_parent]).pipe(first(), takeUntil(this.onDestroy))
            .subscribe();

        this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe(() => {
            console.log('INFO: Saving constant properties for ' + this.parent_component?.attributes?.name);
            this.saveMany();
        });
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.constant_property_formula_light.searchMany(query).pipe(
            first(), takeUntil(this.onDestroy)
        )
    }

    emitFilterQuery() {
        const filters = this.propertyService.refreshPropertyQuery(this.parent_component);
        this.dataSource.filterBy(filters);
    }

    updateSearchFilter() {
        const filters = this.propertyService.updateSearchFilter(this.parent_component, this.filter_string);
        this.dataSource.filterBy(filters)
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort)
    }

    newCP: ConstantProperty;

    newConstantProperty() {
        if (this.newCP) {
            this.notification.openSuccess('Please complete and save the current New Constant Property.');
            return;
        }
        this.newCP = new ConstantProperty();
        this.newCP.id = 'temp' + guid()
        this.dataSource.addRow(this.newCP);
        this.editConstantProperty(this.newCP);
    }

    removeConstantProperty(constant_property) {
        this.dataSource.clearTempRows();
        this.newCP = null;
    }

    editConstantProperty(constant_property) {
        if (this.cp_editing_dict[constant_property.id]) {
            this.cp_editing_dict[constant_property.id] = false;
            this.expandedElement = null;
            return;
        }
        this.selected_properties.forEach(cp => {
            if (cp.id !== constant_property.id && !cp.id.includes('temp')) {
                this.cp_editing_dict[cp.id] = false;
            }
        });
        if (!constant_property.id.includes('temp')) {
            this.cp_editing_dict[constant_property.id] = true;
        } else {
            this.cp_editing_dict[constant_property.id] = !this.cp_editing_dict[constant_property.id];
        }
    }

    saveConstantProperty(constant_property) {
        const ctrl = this;
        let $upsert;
        const temp_id = constant_property.id;
        delete constant_property.attributes.formula;

        if (!this.validate(constant_property)) return;
        if (temp_id.includes('temp')) {
            $upsert = this.api.constant_property.obsSave(constant_property);
        } else {
            $upsert = this.api.constant_property.obsPatch(constant_property);
        }
        $upsert.pipe(
            switchMap((result: SingleResponse<ConstantProperty>) => {
                let $link: Observable<any>;
                if (temp_id.includes('temp')) {
                    $link = this.getParentConstantPropertyRelationshipObs(result.data).pipe(tap((ct_cp) => {
                    this.notification.openSuccess(`Constant property linked to ${this.parent_component.attributes.name}`, 2000 );
                    this.newCP = null;
                    this.constant_property_parent_list.push(ct_cp.data);
                    this.old_selected_properties.push(result.data);
                }));
                } else {
                    $link = of([]);
                }
                constant_property = result.data;

                ctrl.cp_editing_dict[temp_id] = false;
                ctrl.cp_editing_dict[constant_property.id] = false;
                ctrl.expandedElement = null;
                this.notification.openSuccess('Constant property saved. Table sorted.', 2000);
                return $link;
            }),
            catchError((err: any, caught: any) => {
                const error = err?.error?.errors?.length > 0 && err.error.errors[0].detail ?
                    err.error.errors[0].detail.substring(0, 120) + '...(Please see console).' : '';
                this.notification.openError(`Error saving constant property. ${error}`);

                if (temp_id) {
                    constant_property.id = temp_id;
                }
                return err;
            }),
            first(), takeUntil(this.onDestroy))
            .subscribe(() => this.emitFilterQuery());
    }

    saveMany() {
        const model = this.parent_component.type === 'ore_body_type' ? 'ore_body_type_constant_property' : 'constant_property_' + this.parent_component.type;
        this.relMapping.saveMany(model, this.parent_component.type, this.parent_component.id, 'constant_property',
            this.selected_properties, this.old_selected_properties, this.constant_property_parent_list)
            .pipe(tap((result) => {
                    if (result?.data) {
                        this.constant_property_parent_list.push(result.data)
                    }
                }),
                /**Dont close subscription when form closes (onDestroy), only closes on finalise**/
                finalize(() => {
                    this.constant_property_parent_list = this.constant_property_parent_list.filter(item => {
                        return this.selected_properties.map(cp => cp.id).includes(item.relationships.constant_property.data.id)
                    });
                    this.old_selected_properties = this.selected_properties.slice();
                    console.log('INFO: Sequence complete');
                }))
            .subscribe(() => {
            });
    }

    getParentConstantPropertyRelationshipObs(constant_property: ConstantProperty): Observable<any> {
        let obj: any = this.propertyService.getParentConstantPropertyRelationshipObject(this.parent_component, constant_property);
        const model = this.parent_component.type === 'ore_body_type' ? 'ore_body_type_constant_property' : 'constant_property_' + this.parent_component.type;
        return this.api[model].obsSave(obj).pipe(tap(result => {
            this.selected_properties.push(constant_property)
        }));
    }

    validate(constant_property: ConstantProperty): boolean {
        return this.validateJson(constant_property) && this.validateCalculation(constant_property);
    }

    validateCalculation(constant_property): boolean {
        if (!constant_property.attributes.is_calculation) {
            return true;
        }
        if (constant_property.attributes.name_formula) {
            return true;
        } else {
            this.notification.openError('Constant properties of type calculation must have a formula.', 10000 );
        }
    }

    validateJson(constant_property): boolean {
        if (!constant_property.attributes.is_drop_down_list) {
            return true;
        }
        try {
            constant_property.attributes.json = JSON.parse(constant_property.attributes.json);
            return true;
        } catch (e) {
            const item = constant_property.attributes.json;
            if ((typeof item === "object" || Array.isArray(item)) && item !== null) {
                return true;
            }
            this.notification.openError('Invalid json. Please correct this and try again.', 10000 );
            return false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
