<div class="options-override-row">
  <ng-container *ngIf="!(dataService.isLoading$ | async)">
    <h3 *ngIf="dataService.component_types$.getValue().length <= 0" style="margin-top:6px;">
      No Component Types for selected Event Type!</h3>
    <ng-container *ngIf="dataService.component_types$.getValue().length > 0">
      <mat-form-field class="no-label">
        <mat-select (selectionChange)="setComponentType($event.value)"
                    title="Select component type for column"
                    [(ngModel)]="componentTypeID"
                    [compareWith]="compareWith"
                    placeholder="Select Component Type for column">
          <mat-option *ngFor="let option of dataService.component_types$.getValue()" [value]="option.id">
            {{option.attributes | nameAndDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ngx-skeleton-loader
      *ngIf="dataService.isLoading$ | async" count="1"
      title="Select Event Type to show Component Types"
      [theme]="{'margin-bottom': '0'}"
      class="progress-skeleton"
      animation="pulse"
      appearance="line"
  ></ngx-skeleton-loader>
</div>
