<div class="row column-selector">
  <ng-container *ngIf="!availableColumns || !selectedColumns;else columnsLoaded">
    <div class="stage">
      <div class="dot-flashing"></div>
    </div>
  </ng-container>
  <ng-template #columnsLoaded>
    <div class="draggable-list-container">
      <h2>Selected Columns</h2>
      <option-list-search
          [placeholder]="'Search columns'"
          (inputChanged)="selectedColumnFilterValue=$event"
          (inputCleared)="selectedColumnFilterValue=''"
      ></option-list-search>
      <div
          id="selectedList"
          #selectedList="cdkDropList"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListConnectedTo]="[availableList]"
          [cdkDropListData]="selectedColumns"
          cdkDropList
          class="draggable-list">
        <div
            *ngFor="let column of selectedColumns | filterByValue: columnFilter : selectedColumnFilterValue"
            cdkDrag [cdkDragData]="column" class="draggable-list-item">
          <div [ngStyle]="{'font-weight':column.format?.bold? 'bold' : '',
              'font-style':column.format?.italic? 'italic' : '',
              'font-size': column.format?.size ? column.format.size + '%' : '100%'}"
               class="column-name" cdkDragHandle
               [dblClickCopy]="modelDict[column.id]?.attributes.name">
            <div>{{modelDict[column.id]?.attributes.name | upperfirst}}</div>
          </div>
          <div class="row min-height">
            <ng-container *ngIf="isDropDownList(column.id)">
              <select-many-filter class="select-filter-inline"
                                  [options]="getDropDownListOptions(column.id)"
                                  [modelValue]="selectedFilters[column.id]"
                                  [placeholder]="'Select filter'"
                                  [listType]="'filters'"
                                  [classes]="'fill'"
                                  (constant_changed)="onFiltersChange($event, column.id)"
              ></select-many-filter>
            </ng-container>
            <!--          <ng-container *ngIf="isDateTime(column.id)">-->
            <!--            <select-many-filter class="select-filter-inline"-->
            <!--                [options]="getDropDownListOptions(column.id)"-->
            <!--                [modelValue]="selectedFilters[column.id]"-->
            <!--                [placeholder]="'Select filter'"-->
            <!--                [listType]="'filters'"-->
            <!--                (constant_changed)="onFiltersChange($event, column.id)"-->
            <!--              ></select-many-filter>-->
            <!--          </ng-container>-->
            <ng-container *ngIf="isText(column.id)">
              <input class="text-filters"
                     [ngModel]="selectedFilters[column.id]"
                     matInput
                     (ngModelChange)="onTextFiltersChange($event, column.id)"
              />
            </ng-container>
            <div class="column-title">
              <input
                  #aliasInput
                  [ngModel]="toSnakeCase(column.title)"
                  matInput
                  placeholder="Alias"
                  (ngModelChange)="column.title=$event;onAliasChange($event, column.id)"
                  (keydown)="preventKeys($event)"
                  [matTooltip]="isTextOverflowing(aliasInput) ? aliasValues[column.id] || toSnakeCase(column.title) : ''"
                  name="Alias" style="width: auto" maxlength="30"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column select-remove-buttons">
      <button mat-raised-button color="primary" *ngFor="let btn of selectRemoveButtons">
        <i (click)="moveColumns(btn.type)"
           [class]="'fas fa-angle-double-' + btn.iconDirection"
           [matTooltip]="btn.type + ' all available columns' | upperfirst"
           class="action-icon"></i>
      </button>
    </div>

    <div class="draggable-list-container">
      <h2>Available Columns</h2>
      <option-list-search
          [placeholder]="'Search columns'"
          (inputChanged)="availableColumnFilterValue=$event"
          (inputCleared)="availableColumnFilterValue=''"
      ></option-list-search>

      <div
          id="availableList"
          #availableList="cdkDropList"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListConnectedTo]="[selectedList]"
          [cdkDropListData]="availableColumns"
          cdkDropList
          class="draggable-list">
        <div
            *ngFor="let column of availableColumns | filterByValue: columnFilter : availableColumnFilterValue"
            cdkDrag
            [cdkDragData]="column"
            class="draggable-list-item"
            matTooltip="{{modelDict[column.id]?.description ? modelDict[column.id].description:''}}"
            [dblClickCopy]="modelDict[column.id]?.attributes.name">
          <span>{{modelDict[column.id]?.attributes.name | upperfirst}}</span>
          <span *ngIf="modelDict[column.id]?.attributes.is_calculation" class="column-type">
              (calculation)
          </span>
          <span *ngIf="modelDict[column.id]?.attributes.is_drop_down_list" class="column-type">
            (dropdown)
            </span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
