import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {Series} from '../../_models/series';
import {Subject} from "rxjs";
import {SeriesDataService} from "../../services/series_data.service";
import {takeUntil} from 'rxjs/operators';
import {SeriesTabGroup} from '../../forms/series-form/series-form.component';

@Component({
    selector: 'series-edit-button',
    templateUrl: './series-edit-button.component.html',
    styleUrls: ['./series-edit-button.component.less'],
    standalone: false
})
export class SeriesEditButtonComponent implements OnInit, OnDestroy {
    @Input('full_series') series: Series;
    @Input() series_id: string;
    @Input() panelClass = '';
    @Input() group: SeriesTabGroup = SeriesTabGroup.CONFIGURATION;

    @Output() seriesUpdated: EventEmitter<Series | undefined> = new EventEmitter();
    icon_class = "edit-inline";
    icon_text = "";

    private readonly onDestroy = new Subject<void>();

    constructor(private notification: NotificationService,
                private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        if (this.group == SeriesTabGroup.OPERATIONS || this.group === SeriesTabGroup.REPORTING) {
            this.icon_class = "material-symbols-outlined";
            this.icon_text = "insights";
        }
    }

    editSeries() {
        const ctrl = this;

        if (!this.series && !this.series_id) {
            this.notification.openError("Error opening series of undefined element", 5000);
            return;
        }

        if (!this.series) {
            this.seriesData.getSeriesById(this.series_id).pipe(takeUntil(this.onDestroy)).subscribe(result => {
                if (!result) {
                    this.notification.openError("Series not found", 5000);
                    return;
                }
                this.series = result.data;
                this.upsertSeries();
            })
        } else {
            this.upsertSeries();
        }
    }

    upsertSeries() {
        this.seriesData.upsertSeries(undefined, this.series, null, this.group).afterClosed().subscribe((series) => {
            if (series) {
                this.seriesUpdated.emit(series);
            }
        })
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
