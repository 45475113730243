import {Component, ElementRef, HostListener, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {ComponentPanelStackService} from "./component-panel-stack.service";
import {Component as WireComponent} from '../../_models/component';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api/api.service';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {COMPONENT_EVENTS_CONFIG} from '../../forms/component-events-table-form/component-events-table-form.component';
import {BaseComponent} from '../../shared/base.component';
import {ModelID} from '../../_typing/generic-types';
import {RelatedComponentConfig} from "../../components/component-detail/component-detail.component";

export interface ComponentPanelStackConfig {
    type: string;
    index: number;
    url: string;
    show: boolean;
    data: ComponentPanelStackConfigData;
}

export interface ComponentPanelStackConfigData {
    component_id: ModelID;
    config: COMPONENT_EVENTS_CONFIG;
    tile_id: ModelID;
    parent_url: string;
    previous_component: WireComponent;
    current_component: WireComponent;
}

@Component({
    selector: 'component-panel-stack',
    templateUrl: './component-panel-stack.component.html',
    styleUrls: ['./component-panel-stack.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ComponentPanelStackComponent extends BaseComponent {
    public panels: ComponentPanelStackConfig[] = [];

    component_id: string;
    url: string;
    tile_id: string;
    property_ids: string;
    parent_url: string;

    constructor(private elementRef: ElementRef,
                private panelStackService: ComponentPanelStackService,
                private route: ActivatedRoute,
                private api: ApiService) {
        super();
    }

    @ViewChildren('resizePanel') panelElements: QueryList<ElementRef>;

    @HostListener('document:click', ['$event'])
    public documentClick(event: MouseEvent): void {
        // if ((event.target instanceof HTMLElement &&
        //     (event.target.closest('.panel-stack-container') || event.target.closest('.confirm-dialog'))) ||
        //     !this.panels?.length) return;

        //The events to open the side panel still fire here so do nothing in that case
        if (!this.panels?.length) return;

        if ((event.target instanceof HTMLElement &&
            (event.target.closest('.section-grid')))) {
            this.close();
        }
    }

    ngOnInit(): void {
        this.route.parent.url.pipe(take(1)).subscribe((urlPath) => {
            const url = urlPath[urlPath.length - 1].path;
            this.panelStackService.parent_url = '/view/page_view/' + url;
        })

        this.panelStackService.panelStackUpdated.pipe(
            takeUntil(this.onDestroy)
        ).subscribe((panels) => {
            this.panels = panels;
            if (this.panels.length > 0) {
                setTimeout(() => {
                    this.panels[panels.length - 1].show = true;
                })
            }
        });
        this.loadPanel();
    }

    private loadPanel() {
        this.url = this.route.snapshot.url.map(s => s.path)[0];
        this.route.params.pipe(switchMap(params => {
                this.tile_id = params['tileID']
                const component_id = params['componentID']

                return this.api.tile.getById(this.tile_id).pipe(
                    take(1),
                    tap(result => {
                        const pm: COMPONENT_EVENTS_CONFIG = result?.data?.attributes.parameters as COMPONENT_EVENTS_CONFIG;
                        if (this.url === 'component-detail') {
                            this.addPanel('component-detail', component_id, pm);
                        } else if (this.url === 'component') {
                            this.addPanel('component', null, pm);
                        }
                    }))
            }), takeUntil(this.onDestroy)
        ).subscribe(() => this.panels = this.panelStackService.getPanels());
    }

    private addPanel(type: 'component' | 'component-detail', component_id: ModelID, config: COMPONENT_EVENTS_CONFIG) {
        this.panels = this.panelStackService.getPanels();
        const pre_panel = this.panels[this.panels.length - 1];
        this.panelStackService.push({
            type: type,
            index: (this.panels.length || 0),
            show: false,
            data: {
                component_id: component_id,
                config: config,
                tile_id: this.tile_id,
                previous_component: pre_panel?.type === 'component' ? 'component' : pre_panel?.data?.current_component,
                current_component: null
            }
        })
    }

    loadRelatedComponent(event: RelatedComponentConfig, panel: ComponentPanelStackConfig, replace_url = false) {
        panel.data.current_component = event.current;
        this.panelStackService.loadRelatedComponent(event.related, panel, replace_url);
    }

    back(): void {
        this.panelStackService.back();
    }

    close(): void {
        this.panelStackService.clear();
    }

}
