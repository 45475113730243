import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ColumnFormats} from "../../tables/table-utils.service";
import {FormControl} from "@angular/forms";
import {MatSelect} from "@angular/material/select";
import {OptionListSearchComponent} from "../option-list-search/option-list-search.component";
import {allStringFilter} from "../../lib/utils";

@Component({
    selector: 'select-many-filter',
    templateUrl: './select-many-filter.component.html',
    styleUrls: ['./select-many-filter.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class SelectManyFilterComponent implements OnInit {
    @ViewChild('filterSelect') filterSelect: MatSelect;
    @ViewChild(OptionListSearchComponent) searchComponent!: OptionListSearchComponent;

    filterValue: string = "";
    value: FormControl;
    filteredOptions: any;
    errorMessage: string;

    private isOpen = false;
    private _modelValue: any;

    get modelValue(): any {
        return this._modelValue;
    }

    @Input() set modelValue(value: any) {
        this._modelValue = value;
        this.value = new FormControl({value: this.modelValue, disabled: this.disabled});
        this.changeDetectorRef.markForCheck();
    }

    @Input() listType: string;
    /**A string of classes to add to mat-form-field**/
    @Input() classes: string;
    @Input() filter_compare?: any;
    @Input() app_fit_content: any;
    @Input() conditional_formats: any;
    @Input() formats: ColumnFormats;
    private _disabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
        if (!this.value) return;
        if (value === true) {
            this.value.disable();
        } else {
            this.value.enable();
        }
        this.changeDetectorRef.markForCheck();
    }

    @Input()
    stringFunction: (obj: any, other?: any) => string = (obj) => {
        if (!obj) return "";
        if (obj.name && obj.value) return obj.name;
        return obj?.attributes?.name || obj;
    }
    @Input()
    disableItemFunction: (obj: any) => boolean = (obj) => {
        return false;
    }
    @Input() label: string;
    private _options: any[];
    @Input() set options(options: any[]) {
        this._options = options || [];
        this.filteredOptions = this._options;
    };

    get options(): any[] {
        return this._options;
    }

    @Input() allow_fit: boolean;
    @Input() tab_index: number;
    @Input() placeholder: string;
    @Output() constant_changed: EventEmitter<any> = new EventEmitter();

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.app_fit_content = this.app_fit_content ?? this.modelValue;

        if (!this.allow_fit) {
            this.allow_fit = this.formats?.allow_fit ?? false;
        }
        if (!this.label) {
            this.classes += ' no-label';
        }
    }

    onOpen(value) {
        this.isOpen = value;
        if (value) {
            this.filterSelect.panel.nativeElement.scrollTop = 0;
        }
        this.filteredOptions = this.options;
        this.filterValue = "";
    }

    filterOptionList(filterString: string): void {
        this.filterValue = filterString?.toLowerCase();
        this.filteredOptions = allStringFilter(filterString, this.options);
        if (this.filteredOptions.length === 0) {
            this.errorMessage = `${this.filterValue} does not exist`;
        }
    }

    onClear() {
        this.filterValue = "";
        this.filteredOptions = allStringFilter("", this.options);
    }

    emitConstantChangedComplete(event) {
        const selected = event;
        const hiddenSelected = this.modelValue?.filter(
            value => !allStringFilter(this.stringFunction(value), this.filteredOptions)?.length
        ) || [];
        this.modelValue = [...new Set([...selected, ...hiddenSelected])];
        console.log("modelValue", this.modelValue);
        this.constant_changed.emit(this.modelValue);
        this.changeDetectorRef.markForCheck();
    }

    compareByName(option, value): boolean {
        if (value) {
            return option === value;
        }
        return false;
    }

    getTooltip() {
        const stringValues = (this.modelValue?.map(value => {
            return this.stringFunction(value);
        }));
        return stringValues?.length ? stringValues.join(", ") : "No items selected";
    }

    getPlaceholder() {
        if (this.modelValue?.length > 0) {
            return `(${this.modelValue.length} ${this.listType || ''} selected)`
        } else {
            return this.placeholder || `Please select ${this.listType || ''}`
        }
    }
}
