import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     MatButtonModule
    // ],
    selector: 'wire-button',
    templateUrl: './wire-button.component.html',
    styleUrls: ['./wire-button.component.less'],
    standalone: false
})
export class WireButtonComponent {
  @Input() btnContent: string = '';
  @Input() btnStyle: string = '';

  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  onButtonClicked() {
    this.buttonClicked.emit(true);
  }
}
