import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ConstantProperty} from '../../_models/constant-property';
import {ConstantValue, GenericField, isConstant, isFile, isInvalid} from "../../_models/api/generic-constant";
import {Component as WireComponent} from '../../_models/component';
import {ColumnFormats} from "../../tables/table-utils.service";
import {WireEvent} from "../../_models/event";

@Component({
    selector: 'generic-component-field',
    templateUrl: './generic-component-field.component.html',
    styleUrls: ['./generic-component-field.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GenericComponentFieldComponent implements OnInit {
    @Input() constant: GenericField;
    @Input() constant_property: ConstantProperty;
    @Input() component: WireComponent | WireEvent;
    @Input() disabled: boolean;
    @Input() is_focussed: boolean;
    @Input() auto_class: string;
    @Input() show_sig: boolean = true;
    @Input() tooltip: string;
    @Input() formats: ColumnFormats;
    @Input() defaultValue: ConstantValue = null;
    /**This (conditional_formats) is necessary because text-decoration can not be inherited from non text elements (e.g. div/td)**/
    @Input() conditional_formats: any;
    @Input() placeholder: string = '';
    @Output() relayConstantChangedEmitter: EventEmitter<any> = new EventEmitter();
    @Output() relayChangeCompleteEmitter: EventEmitter<any> = new EventEmitter();
    @Output() relayLockClickedEmitter: EventEmitter<any> = new EventEmitter();

    isConstant = isConstant;
    isFile = isFile;
    isInvalid = isInvalid;

    constructor() {
    }

    ngOnInit(): void {
    }

    relayConstantChanged(values): void {
        if (typeof values === 'string') {
            values = values.trim();
        }
        this.relayConstantChangedEmitter.emit(values);
    }

    relayChangeComplete(values): void {
        if (typeof values === 'string') {
            values = values.trim();
        }
        this.relayChangeCompleteEmitter.emit(values);
    }

    relayLockClicked(): void {
        this.relayLockClickedEmitter.emit({component: this.component, constant: this.constant});
    }
}
