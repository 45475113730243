import {Injectable} from '@angular/core';
import {
    getAccountFilter,
    getManyRelationWithIdsFilter,
    getRelationWithManyIdsFilter
} from "../services/api/filter_utils";
import {AppScope} from '../services/app_scope.service';
import {IDateTimePeriod} from "../_typing/date-time-period";

@Injectable({
    providedIn: 'root'
})
export class OreBodyDataService {

    constructor(private appScope: AppScope) {
    }

    generateOreBodiesByEventTypeFilter(event_type_ids: string[], dtp: IDateTimePeriod, direction: 'source' | 'destination'): any[] {
        let date_filter_logic = {
            or: [{
                and: [{
                    name: 'opening_date',
                    op: 'ge',
                    val: dtp.start
                }, {
                    name: 'opening_date',
                    op: 'lt',
                    val: dtp.end
                }]
            }, {
                and: [{
                    name: 'closing_date',
                    op: 'ge',
                    val: dtp.start
                }, {
                    name: 'closing_date',
                    op: 'lt',
                    val: dtp.end
                }]
            }, {
                and: [{
                    name: 'opening_date',
                    op: 'lt',
                    val: dtp.end
                }, {
                    or: [{
                        name: 'closing_date',
                        op: 'is_',
                        val: null
                    }, {
                        name: 'closing_date',
                        op: 'gt',
                        val: dtp.start
                    }]
                }]
            }]
        };
        let filters = [{
            "and": [
                getAccountFilter(this.appScope.active_account_id),
                {
                    name: 'type',
                    op: 'has',
                    val: {
                        and: [
                            getManyRelationWithIdsFilter('event_types', event_type_ids),
                            {
                                name: 'event_type_ore_body_types', op: 'any', val: {
                                    and: [
                                        getRelationWithManyIdsFilter('event_type', event_type_ids),
                                        {name: direction, op: 'eq', val: true}]
                                }
                            }
                        ]
                    }
                },

            ],
        },
            date_filter_logic
        ];
        return filters;
    }
}
