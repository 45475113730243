<div class="page traveler">
  <section>
    <div class="row" style="justify-content: space-between">
      <div *ngIf="dataSource" class="table-filter">
        <input (keyup)="applyFilter($event.target.value)" placeholder="Search events"/>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="20"
                     length="{{ore_body_types ? ore_body_types.length : 0}}" showFirstLastButtons></mat-paginator>
    </div>

    <div class="table-container">
      <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="columns[0]"
             class="event-types-table" mat-table
             matSort matSortDirection="desc">

        <!--Name-->
        <ng-container matColumnDef="{{columns[0]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[0] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let ore_body_type;" mat-cell
              style="text-align: left;border:none;cursor:pointer;display: flex;align-items: center;">
            <i (click)="editOreBodyType(ore_body_type)" class="edit-inline hide-xs"></i>
            <div [textContent]="ore_body_type.attributes.name" style="padding-top:2px;"></div>
          </td>
        </ng-container>

        <!--Description-->
        <ng-container matColumnDef="{{columns[1]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[1] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let ore_body_type" mat-cell> {{ore_body_type.attributes.description}} </td>
        </ng-container>

        <!--Constant Properties-->
        <ng-container matColumnDef="{{columns[2]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[2] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let ore_body_type" class="large-cell" mat-cell style="text-align: left;border:none;">
            <div>
              <div *ngFor="let property of ore_body_type.relationships.constant_properties?.data">
                <!--              <i (click)="editEventType(element, property)" class="edit-inline hide-xs"></i>-->
                <div [textContent]="cp_dict[property.id].attributes.name" style="cursor:pointer;padding-top:2px;"></div>
              </div>
            </div>
          </td>
        </ng-container>

        <!--Account-->
        <ng-container matColumnDef="{{columns[3]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{columns[3] | upperfirst | removeUnderscore}}</th>
          <td *matCellDef="let ore_body_type" mat-cell> {{ore_body_type.attributes.account_name}} </td>
        </ng-container>


        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>
    </div>
  </section>
</div>
