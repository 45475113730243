<div class="print-header-tile">
  <div class="header-content">
    <div>
      <div class="client-logo-container">
        <img class="client-logo" [src]='clientLogoSrc' [ngStyle]="clientLogoStyles"/>
      </div>
      <div class="report-title"><h1>{{headerData.titleSubject | async}}</h1></div>
      <div class="wire-logo-container">
        <img [src]="wireLogoSrc" [ngStyle]="wireLogoStyles" [hidden]="hideWireLogo"/></div>
    </div>
    <div *ngIf="dateInst.dtp" class="print-header-dtp">Production period: from
      <b>{{dateInst.dtp.start | stringDate : {date_only: true} }}</b>
      at <b>{{dateInst.dtp.start | stringDate : {time_only: true} }}</b>
      to <b>{{dateInst.dtp.end | stringDate : {date_only: true} }}</b>
      at <b>{{dateInst.dtp.end | stringDate : {time_only: true} }}</b>
    </div>
  </div>
</div>
