import {Component, Input} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {combineLatest, Observable, of} from "rxjs";
import {concatMap, first, map, take, takeUntil} from "rxjs/operators";
import {AppScope} from "../services/app_scope.service";
import {BaseComponent} from "../shared/base.component";
import {Series, SeriesLight} from '../_models/series';
import {SeriesDataService} from "../services/series_data.service";
import {isBaseModel, isBaseModelLight, ModelName} from "../_typing/generic-types";
import {ListResponse, SingleResponse} from '../services/api/response-types';
import {DefaultChartConfig} from "./chart-config/default-chart.configuration";
import {BudgetPalette} from "./chart-config/budget-palette";
import {SeriesType} from "../_models/series-type";
import {CommonModule} from "@angular/common";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    // ],
    // providers: [
    //     ApiService,
    //     SeriesDataService
    // ],
    selector: 'default-chart',
    templateUrl: 'default-chart.component.html',
    standalone: false
})
export class DefaultChartComponent extends BaseComponent {

    private _config: DefaultChartConfig;

    @Input()
    set series(series: Series) {
        this.config = {series: series};
    }

    @Input()
    set config(config: DefaultChartConfig) {
        this._config = config;
        if (config?.series && (isBaseModelLight<SeriesLight>(config.series) || isBaseModel<Series>(config.series))) {
            this.getData(config.series);

        } else if (config.series && typeof (config.series) === 'string') {
            this.seriesData.getSeriesLightListByName([config.series]).pipe(first(), takeUntil(this.onDestroy))
                .subscribe(response => {
                    if (!response) return; // query canceled
                    this.getData(response.data[0]);
                });
        }
    }

    get config(): DefaultChartConfig {
        return this._config;
    }

    @Input() hide_cumulative: boolean = false;
    @Input() hide_comments: boolean;
    @Input() show_data_labels: boolean;
    @Input() type: string;

    series_ready: boolean;
    config_series = [];
    labels: { title: string; sub_title?: string; y_axis?: string; y2_axis?: string; };
    chart_config: any = {};
    series_full: Series;
    budget_palette: Partial<BudgetPalette>;

    constructor(public api: ApiService, private appScope: AppScope, private seriesData: SeriesDataService) {
        super();
    }

    ngOnInit(): void {
        let ctrl = this;
        ctrl.series_ready = false;
        ctrl.chart_config = {};

        if (this.appScope.config_name_map.hasOwnProperty("budget_palette")) {
            this.budget_palette = this.appScope.config_name_map.budget_palette.value;
        } else {
            this.budget_palette = {
                "actual_above": "teal",
                "actual_below": "maroon",
                "actual_cum": "darkturquoise",
                "budget": "orangered",
                "budget_cum": "darkorange",
                "budget_class": "dotted",
                "budget_cum_class": "dashed",
                "actual_cum_type": "line",
                "budget_type": "line"
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    addSeries(name: string, axis, type, cumulative, colour, tag, line_type?, show_data_labels = false) {
        return {
            name: name,
            axis: axis,
            type: type,
            show_limits: false,
            cumulative: cumulative,
            colour: colour,
            tag: tag,
            line_type: line_type,
            show_data_labels: show_data_labels
        };
    }

    getData(series: SeriesLight) {
        const ctrl = this;
        if (!series) {
            console.warn('Chart does not have a series assigned.');
            return of(null);
        }
        const series_name = series.attributes.name;

        ctrl.api.series.getById(series.id)
            .pipe(concatMap((response: SingleResponse<Series>) => {
                ctrl.series_full = response.data;
                if (ctrl.series_full.attributes.default_chart === 'Budget Bar' || ctrl.type === 'budget-bar-chart') {
                    return this.getEstimateConfig(series_name);
                } else {
                    //TODO the line chart etc
                    ctrl.config_series = [{
                        name: series_name,
                        axis: 'y',
                        type: this.getChartType(),
                        show_limits: true,
                        cumulative: false,
                        show_data_labels: this.show_data_labels
                    }];
                    return of(ctrl.config_series);
                }

            }), take(1), takeUntil(this.onDestroy))
            .subscribe(() => {
                if (ctrl.series_full.attributes.description) {
                    ctrl.labels = {
                        title: ctrl.series_full.attributes.description,
                        sub_title: ctrl.series_full.attributes.name
                    };
                } else {
                    ctrl.labels = {title: ctrl.series_full.attributes.name};
                }
                ctrl.labels.y_axis = ctrl.config.labels?.y_axis || null;
                ctrl.labels.y2_axis = ctrl.config.labels?.y2_axis || null;

                ctrl.chart_config = {
                    series_list: ctrl.config_series,
                    labels: ctrl.labels,
                    hide_comments: ctrl.hide_comments,
                    budget_palette: ctrl.budget_palette,
                    chart_type: this.series_full.attributes.default_chart,
                    hide_now_line: this.config.hide_now_line
                };
                ctrl.series_ready = true;
            })
    }

    getEstimateConfig(series_name: ModelName): Observable<any> {
        const ctrl = this;
        this.config_series = [];
        this.config_series.push(this.addSeries(series_name, 'y', 'bar', false,
            ctrl.budget_palette.actual_above, 'Actual', undefined, this.show_data_labels));
        if (!this.hide_cumulative) {
            this.config_series.push(this.addSeries(series_name, 'y2',
                this.budget_palette.actual_cum_type ? this.budget_palette.actual_cum_type : 'line',
                true, ctrl.budget_palette.actual_cum, 'Actual (Cum)', undefined, this.show_data_labels));
        }

        let $target_series: Observable<Series | null> = of(null);
        if (this.config.target_series) {
            $target_series = this.api.series.getById(this.config.target_series.id)
                .pipe(map((result: SingleResponse<Series>) => {
                    return result.data;
                }));
        } else if (this.series_full.relationships.target_series?.data?.length && this.config.target_series_type) {
            $target_series = this.seriesData.getTargetSeriesByType(this.series_full.id, this.config.target_series_type.id)
                .pipe(map((result: ListResponse<Series>) => {
                    return result.data[0];
                }));
        } else if (this.series_full.relationships.target_series?.data?.length) {
            $target_series = this.api.series.getById(this.series_full.relationships.target_series?.data[0].id)
                .pipe(map((result: SingleResponse<Series>) => {
                    return result.data;
                }));
        }

        return combineLatest<[Series  | null, SeriesType[]]>([$target_series, this.seriesData.estimateTypesChanged]).pipe(
            map(([target, types]) => {
                if (target) {
                    const typeName = types.find(t => t.id === target.relationships.series_type.data?.id)?.attributes?.name;
                    ctrl.config_series.push(ctrl.addSeries(target.attributes.name, 'y', ctrl.budget_palette.budget_type || 'line', false,
                        ctrl.budget_palette.budget, typeName || 'Budget', ctrl.budget_palette.budget_class, this.show_data_labels));
                    if (!this.hide_cumulative) {
                        ctrl.config_series.push(ctrl.addSeries(target.attributes.name, 'y2', 'line', true,
                            ctrl.budget_palette.budget_cum, (typeName || 'Budget') + ' (Cum)', ctrl.budget_palette.budget_cum_class, this.show_data_labels));
                    }
                }
                return ctrl.config_series;
            }))
    }

    getChartType() {
        let ct = this.series_full.attributes.default_chart?.toLowerCase() || 'line';
        if (ct === 'spc') ct = 'line'
        return ct;
    }
}
