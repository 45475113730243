import {BaseViewModel} from "./base-viewmodel";
import {Component} from "../../../_models/component";
import {ProcessViewModel} from "./process-viewmodel";

export class BaseComponentViewModel extends BaseViewModel {
    //***Inherited by SeriesViewModel and ConstantViewModel***

    tree_position: string = "child";
    is_on_parent: boolean = false;
    parent_component: any; //The component that this series belongs to (process/stream/equipment)
    current_rotate: number = 0;
    data: any;

    constructor(ComponentDataModel, parent_process: ProcessViewModel, parent_component: Component) {
        super(ComponentDataModel);

        this.parent_component = parent_component;
        this.chart_parent_process = parent_process;
//this.data = super(this.data)
        if (this.data.relationships.component.data.id === this.chart_parent_process.data.id) {
            this.tree_position = "parent";
            this.is_on_parent = true;
        }
        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (this.data.attributes.json[this.tree_position] == undefined) {
            this.data.attributes.json[this.tree_position] = {};
        }
        if (!this.data.attributes.json[this.tree_position]['x']) {
            if (this.is_on_parent === true) {
                this.data.attributes.json[this.tree_position]['x'] = this.chart_parent_process.width() / 4;
            } else {
                if (this.parent_component.data.attributes?.base_type === 'stream') {
                    var stream = this.parent_component;
                    if (stream.data.attributes.json.points.length > 1) {
                        this.data.attributes.json[this.tree_position]['x'] = (stream.points[0].x() + stream.points[1].x()) / 2;
                    } else {
                        this.data.attributes.json[this.tree_position]['x'] = (stream.startCoordX() + stream.endCoordX()) / 2;
                    }
                    if (this.data.attributes.json[this.tree_position]['x'] >= this.chart_parent_process.width() - 50) {
                        this.data.attributes.json[this.tree_position]['x'] += -100;
                    }
                } else {
                    this.data.attributes.json[this.tree_position]['x'] = this.parent_component.x() + this.parent_component.width() * 3 / 4;
                }
            }
        }
        if (!this.data.attributes.json[this.tree_position]['y']) {
            if (this.is_on_parent === true) {
                this.data.attributes.json[this.tree_position]['y'] = this.chart_parent_process.height() / 4;
            } else {
                if (this.parent_component.data.attributes?.base_type === 'stream') {
                    let stream = this.parent_component;
                    if (stream.data.attributes.json.points.length > 1) {
                        this.data.attributes.json[this.tree_position]['y'] = (stream.points[0].y() + stream.points[1].y()) / 2;
                    } else {
                        this.data.attributes.json[this.tree_position]['y'] = (stream.startCoordY() + stream.endCoordY()) / 2;
                    }
                    if (this.data.attributes.json[this.tree_position]['y'] >= this.chart_parent_process.height() - 40) {
                        this.data.attributes.json[this.tree_position]['y'] += -40;
                    }
                } else {
                    this.data.attributes.json[this.tree_position]['y'] = this.parent_component.y() + this.parent_component.height() / 2;
                }
            }

        }

        if (this.data.attributes.json[this.tree_position]['text'] == undefined || typeof (this.data.attributes.json[this.tree_position]['text']) == 'string') {
            this.data.attributes.json[this.tree_position]['text'] = {};
        }
        if (!this.data.attributes.json[this.tree_position]['text'].x) {
            this.data.attributes.json[this.tree_position]['text'].x = this.width() / 2;
        }
        if (!this.data.attributes.json[this.tree_position]['text'].y) {
            this.data.attributes.json[this.tree_position]['text'].y = 15;
        }
    }

    x(): number {
        if (this.data.attributes.json[this.tree_position].x < 0) {
            this.data.attributes.json[this.tree_position].x = 50;
        }
        if (this.current_rotate == 90) {
            return this.data.attributes.json[this.tree_position].y
        }
        if (this.current_rotate == 180) {
            return this.data.attributes.json[this.tree_position].x * -1
        }
        if (this.current_rotate == 270) {
            return this.data.attributes.json[this.tree_position].y * -1
        }
        return this.data.attributes.json[this.tree_position].x;
    }

    y(): number {
        if (this.data.attributes.json[this.tree_position].y < 0) {
            this.data.attributes.json[this.tree_position].y = 50;
        }
        if (this.current_rotate == 90) {
            return this.data.attributes.json[this.tree_position].x * -1
        }
        if (this.current_rotate == 180) {
            return this.data.attributes.json[this.tree_position].y * -1
        }
        if (this.current_rotate == 270) {
            return this.data.attributes.json[this.tree_position].x
        }
        return this.data.attributes.json[this.tree_position].y;
    }

    height() {
        return 10;
    }

    width() {
        return 200;
    }

    rotate() {
        if (!this.data.attributes.json[this.tree_position]['text'].rotate) {
            this.data.attributes.json[this.tree_position]['text'].rotate = 0;
        }
        this.current_rotate = this.data.attributes.json[this.tree_position]['text'].rotate;
        return this.data.attributes.json[this.tree_position]['text'].rotate;
    }

    //#region Display---------------------------------------------------------------------------------------------------
    show_status() {
        return this.data.attributes.json[this.tree_position].show_status;
    }

    text_colour() {
        if (!this.data.attributes.json[this.tree_position].font_colour) {
            this.data.attributes.json[this.tree_position].font_colour = "#000";
        }
        return this.data.attributes.json[this.tree_position].font_colour;
    }

    background() {
        if (!this.data.attributes.json[this.tree_position].background) {
            this.data.attributes.json[this.tree_position].background = "#fff";
        }
        return this.data.attributes.json[this.tree_position].background;
    }

    borders() {
        if (!this.data.attributes.json[this.tree_position].borders) {
            return this.background();
        }
        return "#333333"
    }

    padding() {
        if (!this.data.attributes.json[this.tree_position].minimise) {
            return '6px';
        }
        return '0px 2px';
    }

    font_size() {
        if (!this.data.attributes.json[this.tree_position].font_size) {
            this.data.attributes.json[this.tree_position].font_size = 12;
        }
        return this.data.attributes.json[this.tree_position].font_size;
    }

    display() {
        return this.parsedText(this);
    }

    //#endregion Display------------------------------------------------------------------------------------------------

    parsedText(seriesViewModel) {
        var value = seriesViewModel.value();
        if (!value && value !== 0) {
            value = "";
        }
        var name = seriesViewModel.name();
        if (name) {
            if (name.indexOf('@value') > -1 || name.indexOf('@Value') > -1) {
                name = name.replace('@value', value);
                name = name.replace('@Value', value);
            } else {
                name += " " + value;
            }
        }
        return name;
    }
}
