import {KeycloakService} from "keycloak-angular";

/** Gets the realm and keycloak URL for auth redirect
 *
 * Note: This **must** be synchronous - cannot load WIRE before init keycloak
 */
export function getWireConfig() {
    const request = new XMLHttpRequest();
    request.open('GET', '/api/realm_config', false); // `false` makes the request synchronous
    request.send(null);
    return JSON.parse(request.response);
}

export function initKeycloak(keycloak: KeycloakService) {
    let realm_config = getWireConfig();
    const realm = realm_config['realm'];
    const clientId = realm_config['client_id'];
    const keycloak_url = realm_config['url'];

    return () => keycloak.init({
        config: {
            url: keycloak_url,
            realm: realm,
            clientId: clientId,
        },
        initOptions: {
            silentCheckSsoRedirectUri:
                window.location.origin + '/assets/silent-check-sso.html'
        }
    });
}
