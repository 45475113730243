import {Directive, HostListener, Input, OnDestroy} from '@angular/core';
import {FormDialogService} from "../services/form-dialog.service";

@Directive({
    selector: '[appProcessMenu]',
    standalone: true,
    providers: [
        FormDialogService
    ]
})
export class ProcessMenuDirective {
    @Input('appProcessMenu') process: any; //Should have id and type

    @HostListener('contextmenu', ['$event']) processContext(event) {
        if (!this.allowDirective()) return;
        event.preventDefault();
        this.openModal(event);
    }

    constructor(private formDialogService: FormDialogService) {
    }

    allowDirective() {
        if (this.process && this.process.id && this.process.type === 'process') {
            return true;
        }
        return false;
    }

    openModal(event: any) {
        const data_config = {
            component: 'ProcessQuickMenuComponent',
            position: {left: 10, top: 10},
            parameters: {
                process: this.process
            }
        };
        const panelClass = 'series-audit-history-dialog';
        const modalDialog = this.formDialogService.openCustomDialog(event, data_config, panelClass);
    }

}
