import {BaseComponentViewModel} from "./base-component-viewmodel";

export class ConstantViewModel extends BaseComponentViewModel {

    constructor(ConstantDataModel, parent_process, parent_component) {
        super(ConstantDataModel, parent_process, parent_component)
    }

    name() {
        if (!this.data.attributes.json[this.tree_position].name) {
            return this.parent_component.name() + " custom property";
        }

        return this.data.attributes.json[this.tree_position].name;
    };

    value() {
        return this.data.attributes.value;
    };

    view_on_flowchart() {
        if (((this.data.attributes.view_on_flowchart && this.tree_position == "child")
            || (this.data.attributes.view_on_parent_flowchart && this.tree_position == "parent"))
        ) {
            return true;
        }
        return false;
    };
}
