import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {Subject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {ApiService} from "../../../services/api/api.service";
import {SearchQueryOptions} from '../../../services/api/search-query-options';
import {ListResponse} from "../../../services/api/response-types";
import {isInteger as _isInteger} from "lodash-es";
import {deepCopy, isGuid} from "../../../lib/utils";
import {ModelID} from "../../../_typing/generic-types";
import {getAccountFilter, getRelationWithManyIdsFilter} from "../../../services/api/filter_utils";
import {Account} from "../../../_models/account";
import {AppScope} from "../../../services/app_scope.service";
import {CompareWith, StringFunction, ValueFunction} from "../types";

@Component({
    selector: 'select-many-search-component',
    templateUrl: 'select-many-search-base.component.html',
    styleUrls: ['../select-search-api.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SelectManySearchBaseComponent implements OnDestroy {
    private onDestroy: Subject<void> = new Subject();
    private _value: any[];

    @Input() api_model: string = 'component';
    @Input() classes: string;
    @Input() custom_filters: any[];
    @Input() custom_selected_filters: any[];
    @Input() reverse_filter: string[];
    @Input() disabled: boolean;
    @Input() disable_items: boolean;
    @Input() label: string = '';
    @Input() openOnInit: boolean = false;
    @Input() placeholder: string;
    @Input() sort_by: string = 'name';
    @Input() filter_by: string[] = ['name', 'description'];
    @Input() compareFunctionName;
    @Input() noItemsAvailableMessage: string = "";
    @Input() accountId: ModelID;
    @Input() allowedAccountIds: ModelID[]; //Used by option-list-search > account-filter
    @Input() allowAccountSelection: boolean = true;
    /**Defaults for these functions are set in the select-search-api.service class**/
    @Input() stringFunction: StringFunction;
    @Input() valueFunction: ValueFunction;

    @Input()
    set value(value: any[]) {
        if (!this.custom_selected_filters && !value) {
            value = [];
        }
        this._value = value;
    }

    get value(): any[] {
        return this._value;
    }

    @Input() compareWith: CompareWith;

    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectionChangeComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeWithoutChange: EventEmitter<any> = new EventEmitter();
    @Output() itemsAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemsRemoved: EventEmitter<any> = new EventEmitter();
    @Output() initialSelected: EventEmitter<any> = new EventEmitter();

    page_size: number = 10;
    private accountIds: ModelID[];

    constructor(private api: ApiService,
                private appScope: AppScope) {
        if (!this.accountId) {
            this.accountId = this.appScope.active_account_id;
        }
    }

    fetchSelected = (page_number, filterValue, added_items, removed_items, filterByAccounts: boolean): Observable<any> => {
        let ret: Observable<any>;
        const options: SearchQueryOptions = new SearchQueryOptions();
        options.page_number = page_number;
        options.page_size = this.page_size;
        options.sort = this.sort_by;

        const have_ids: boolean = this.value?.every(obj => obj.id) || isGuid(this.value?.[0]) || _isInteger(this.value?.[0]);

        if (this.custom_selected_filters || have_ids) {
            if (this.custom_selected_filters) {
                const selected_ids = added_items?.map(v => v.id);
                const removed_ids = removed_items?.map(v => v.id);
                let updated_filter = deepCopy(this.custom_selected_filters);
                if (selected_ids?.length > 0) {
                    updated_filter.push({name: 'id', op: 'in', val: selected_ids})
                    updated_filter = [{or: updated_filter.flat()}];
                }
                if (removed_ids?.length > 0) {
                    updated_filter.push({not: {name: 'id', op: 'in', val: removed_ids}})
                }
                options.filters = updated_filter;
            } else if (have_ids) {
                const ids: any[] = this.value.map(v => v.id || v);
                options.filters = [{name: 'id', op: 'in', val: ids}];
            }

            if (this.accountIds && filterByAccounts) {
                options.filters.push(getRelationWithManyIdsFilter('account', this.accountIds));
            } else if (this.accountId && filterByAccounts) {
                options.filters.push(getAccountFilter(this.accountId));
            }

            if (filterValue) {
                const att_filters = {or: []};
                this.filter_by.forEach(f => {
                    att_filters.or.push({name: f, op: "ilike", val: `%${filterValue}%`});
                });
                options.filters = options.filters.concat(att_filters);
            }
            ret = this.api[this.api_model].searchMany(options).pipe(map((res: ListResponse<any>) => {
                if (this.custom_selected_filters) {
                    this.initialSelected.emit(res);
                }
                return res;
            }));
        } else {
            /**Really just an error catcher, all values should have an id**/
            ret = of({data: this.value});
        }
        return ret;
    }

    fetchData = (page_number, filterValue, added_items, removed_items): Observable<any> => {
        const options: SearchQueryOptions = new SearchQueryOptions(this.page_size, page_number, this.sort_by);
        let filters: any[] = [];

        if (this.custom_filters) {
            filters = deepCopy(this.custom_filters);
        }

        if (this.value?.length > 0 || this.custom_selected_filters) {
            if (this.custom_selected_filters) {
                let updated_filter = deepCopy(this.custom_filters || []);
                const selected_ids = added_items?.map(v => v.id);
                const removed_ids = removed_items?.map(v => v.id);
                if (removed_ids?.length > 0) {
                    updated_filter.push({name: 'id', op: 'in', val: removed_ids})
                    updated_filter = [{or: updated_filter.flat()}];
                }
                if (selected_ids?.length > 0) {
                    updated_filter.push({not: {name: 'id', op: 'in', val: selected_ids}})
                }
                filters = updated_filter;
            } else {
                /**NOTE: added_items is not relevant in this context - this path will be followed when
                 * value is stored in a config as opposed to the db so value will always reflect what is selected
                 * (i.e. it isn't replaced by paging the db as in if(this.custom_selected_filter)
                 */
                let ids: any[] = this.value.map(v => v.id || v);
                filters = filters.concat({not: {name: 'id', op: 'in', val: ids}});
            }
        }

        if (this.accountIds) {
            filters.push(getRelationWithManyIdsFilter('account', this.accountIds));
        } else if (this.accountId) {
            filters.push(getAccountFilter(this.accountId));
        }

        if (filterValue) {
            const att_filters = {or: []};
            this.filter_by.forEach(f => {
                att_filters.or.push({name: f, op: "ilike", val: `%${filterValue}%`});
            });
            filters = filters.concat(att_filters);
        }

        if (filters.length) {
            options.filters = filters;
        }
        return this.api[this.api_model].searchMany(options).pipe(map((res: ListResponse<any>) => {
            return res;
        }));
    }

    filterByAccount($event) {
        this.accountIds = $event?.map(a => a.id);
    }

    emitEvent(event): void {
        this.value = event;
        this.selectionChange.emit(event);
    }

    emitEventComplete(event): void {
        this.value = event;
        this.selectionChangeComplete.emit(event);
    }

    emitItemsAdded(event): void {
        this.itemsAdded.next(event);
    }

    emitItemsRemoved(event): void {
        this.itemsRemoved.next(event);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
