import {Injectable, OnDestroy} from '@angular/core';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {Observable, Subject} from "rxjs";
import {sortObjectsByProperty} from "../../lib/utils";
import {ConstantProperty} from "../../_models/constant-property";
import {AppScope} from '../../services/app_scope.service';
import {ConstantComponent} from "../../_models/constant-component";
import {ApiService} from "../../services/api/api.service";
import {ComponentConstantByComponentResponse, ComponentDataService} from "../../data/component-data.service";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {ComponentConstantTableData} from './component-constants-table.component';
import {first, map, takeUntil} from "rxjs/operators";

@Injectable()
export class ComponentConstantsTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    component_component_constants_dict: ComponentConstantTableData;

    constructor(private appScope: AppScope,
                private api: ApiService,
                private componentData: ComponentDataService,
                private propertyData: ConstantPropertyDataService) {
    }

    getSelectedComponentsFilter(component_type_id: string) {
        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'component_type',
            op: 'has',
            val: {name: 'id', op: 'eq', val: component_type_id}
        }]
        return options.filters;
    }

    getSelectedConstantProperties(component_type_id: string) {
        return this.propertyData.getPropertiesByRelationshipId(component_type_id, 'component_types')
            .pipe(takeUntil(this.onDestroy), first());
    }

    getComponentConstants(component_ids, properties: ConstantProperty[]): Observable<ComponentConstantTableData> {
        this.component_component_constants_dict = {};
        const $constants = this.componentData.streamComponentConstantsByComponentIds(component_ids).pipe(
            map((response: ComponentConstantByComponentResponse) => {
                component_ids.forEach(component_id => {
                    this.component_component_constants_dict[component_id] = response.component_constants.filter(con => {
                        return response.component_constants_list_dict[component_id]?.includes(con.id)
                    }) || [];
                    this.createConstantsOnComponents(component_id, this.component_component_constants_dict[component_id], properties);
                })

                return this.component_component_constants_dict;
            }))
        return $constants.pipe(takeUntil(this.onDestroy), first());
    }

    private createConstantsOnComponents(component_id: string, component_constants: ConstantComponent[], properties: ConstantProperty[]) {
        /**If no mapping relationship exists, add a new ConstantComponent for data entry**/

        properties.forEach(cp => {
            const constant_property = component_constants.find(cc => cc.relationships.constant_property.data.id === cp.id);
            if (!constant_property) {
                this.component_component_constants_dict[component_id].push(this.newConstantComponent(cp, component_id));
            }
            sortObjectsByProperty(this.component_component_constants_dict[component_id], 'name')
        })
    }

    private newConstantComponent(constant_property: ConstantProperty, comp_id: string) {
        const ctrl = this;
        const constant_component = new ConstantComponent();
        constant_component.attributes.name = constant_property.attributes.name;
        constant_component.relationships.component = {data: {type: 'component', id: comp_id}};
        constant_component.relationships.constant_property = {
            data: {
                type: 'constant_property',
                id: constant_property.id
            }
        }
        return constant_component;
    }

    updateSearchFilter(component_type_id: string, properties: ConstantProperty[], filter_string: string) {
        const filters = this.getSelectedComponentsFilter(component_type_id);
        const atts = ['name', 'description']
        let string_filters = {or: []};
        atts.forEach(att => {
            string_filters.or.push({name: att, op: 'ilike', val: '%' + filter_string + '%'});
        })

        const cp_filters = {
            name: 'constant_components',
            op: 'any',
            val: {
                name: 'constant_property',
                op: 'has',
                val: {name: 'name', op: 'ilike', val: '%' + filter_string + '%'}
            }
        };
        string_filters.or = string_filters.or.concat(cp_filters);

        filters.push(string_filters);
        return filters;
    }


    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
