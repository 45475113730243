<div class="login-wrapper">

  <div class="login-box">
    <div class="img-box">
      <img class="wire-power" src="static/images/wire_powered_logo.png"/>
    </div>

    <user-details *ngIf="!detailsSubmitted && !require_otp" (detailsSubmitted)="checkDetailsSubmitted($event)"></user-details>
    <user-password *ngIf="detailsSubmitted && !require_otp" [username]="username" (requiresOTP)="checkPasswordSubmitted($event)"></user-password>
    <user-otp *ngIf="require_otp" [username]="username" [password]="password"></user-otp>

    <div class="terms-and-conditions">
      <span class="vertical-bar"></span>
        <div class="footer-details">
          <span>
            Find out more about MMS at<a class="info-links" href="https://metalmanagementsolutions.com" target="_blank">metalmanagementsolutions.com</a>
            <br>
            <span>
            By using WIRE you consent to our<a class="info-links"
                                               href="https://docs.google.com/document/d/e/2PACX-1vTDJmM83RJ2cUd3LhVeIJhw07ECp6qL_cV5Z0_ctiH7ZeUBTj68XR5jSv0d6zIJC-y3Xa0WqcGGfwCL/pub"
                                               target="_blank">terms and conditions</a>
              and<a class="info-links"
                    href="https://docs.google.com/document/d/e/2PACX-1vTA3AZlkZh7ibaYhodClWo-JzB3Iz7Wm2yZg_31D26xNEwnwX4Ngz1NwBTdyFB5tLg2-sQqm77ZiGAK/pub"
                    target="_blank">privacy policy</a>
            </span>
          </span>
        </div>
      </div>
  </div>
</div>
