<div class="component-events-form">
  <div class="row full-height">
    <select-search-component (selectionChange)="componentTypeChanged($event)"
                             [api_model]="'component_type'"
                             [value]="config.selected_component_type"
                             [classes]="'fill'"
                             label="Select Component Type"
                             class="select-search">
    </select-search-component>

    <select-many-search-component
        (selectionChange)="componentRelationshipChanged($event)"
        [api_model]="'component_type'"
        [custom_filters]="component_relationship_filters"
        [value]="config.selected_component_relationship_types"
        label="Select Component relationship types"
        class="select-search select-many-search-wrapper">
    </select-many-search-component>
  </div>

  <div class="row full-height">
    <mat-form-field>
      <mat-label>String which name must contain</mat-label>
      <input matInput [(ngModel)]="config.name_must_contain" placeholder="String which name must contain"
             autocomplete="false" style="box-sizing: border-box;"
             [matTooltip]="'Enter a substring'">
    </mat-form-field>
    <select-search-component matTooltip="Select the template used to lockdown component data"
                             (selectionChange)="lockTemplateChanged($event)"
                             [api_model]="'lock_template'"
                             [custom_filters]="lockTemplateFilter"
                             [value]="config.lock_template"
                             [classes]="'fill'"
                             label="Select Lock Template"
                             class="select-search">
    </select-search-component>
  </div>
  <div class="row full-height">
    <select-many-filter
        [label]="'Select Printouts'"
        (constant_changed)="printoutsChanged($event)"
        [modelValue]="selectedPrintouts"
        [options]="printoutOptions"
        [placeholder]="'Select printouts'"
        [classes]="'fill'"
        class="select-search">
    </select-many-filter>
    <mat-form-field>
      <mat-label>Default number of rows to fetch</mat-label>
      <input matInput [(ngModel)]="config.page_size" placeholder="Default number of rows to fetch"
             autocomplete="false" type="number" step="1" min="10" max="500" style="box-sizing: border-box;">
    </mat-form-field>
  </div>

  <div *ngIf="date_properties && date_properties.length > 0" class="row center">
    <mat-checkbox *ngIf="date_properties && date_properties.length > 0"
                  (mouseenter)="hint='Filter Component by Selected Constant Property Time'"
                  [(ngModel)]="config.constant_property_time"
                  name="Filter Component by Selected Constant Property Time"
                  class="form-element-align">Use Constant Property Time
    </mat-checkbox>

    <mat-form-field class="flex-third">
      <mat-label>Select a start date property</mat-label>
      <mat-select [(ngModel)]="config.start_prop">
        <mat-option [value]=""><i>None</i></mat-option>
        <mat-option *ngFor="let prop of date_properties" [value]="prop.id">{{prop.attributes.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-third">
      <mat-label>Select an end date property</mat-label>
      <mat-select [(ngModel)]="config.end_prop">
        <mat-option [value]=""><i>None</i></mat-option>
        <mat-option *ngFor="let prop of date_properties" [value]="prop.id">{{prop.attributes.name}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="options-group">
    <h3>Filter and format options <span *ngIf="config.query">
      <i class="fas fa-info-circle" style="cursor:pointer;margin: 0 10px;"
         matTooltip="WARNING: A custom query is being used that will override these options."></i></span></h3>
    <div>
      <div class="row low-height">
        <mat-checkbox matTooltip="Enter a name to search by before loading the table."
                      [(ngModel)]="config.search_by_name"
                      (change)="updateFilters()"
                      name="search_by_name">Enter search option before load
        </mat-checkbox>
        <mat-checkbox (mouseenter)="hint='show search input'" [(ngModel)]="config.search"
                      name="Show search input">Search
        </mat-checkbox>
        <mat-checkbox (mouseenter)="hint='show printout button'" [(ngModel)]="config.printout"
                      name="Show printout button">Printout
        </mat-checkbox>
        <mat-checkbox matTooltip="Enable row selection" [(ngModel)]="config.enable_row_selection"
                      name="enable_row_selection">Enable row selection
        </mat-checkbox>

      </div>
      <div class="row low-height">
        <mat-checkbox matTooltip="Filter components created within dtp range"
                      [(ngModel)]="config.filter_by_created_on"
                      name="filter_by_created_on">Filter by created on only
        </mat-checkbox>

        <mat-checkbox (mouseenter)="hint='Don\'t show components where Start Time is null'"
                      [(ngModel)]="config.exclude_null_start"
                      name="exclude_null_start">Don't allow null Start Time
        </mat-checkbox>

        <mat-checkbox (mouseenter)="hint='Don\'t show components where End Time is null'"
                      [(ngModel)]="config.exclude_null_end"
                      name="exclude_null_end">Don't allow null End Time
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="config.bypass_date_filters"
                      matTooltip="Bypass the standard start and end date filters"
                      name="bypass_date_filters">Bypass date filters
        </mat-checkbox>
      </div>

      <div class="row low-height">
        <mat-checkbox matTooltip="Show aggregation at bottom" [(ngModel)]="config.show_aggregations"
                      name="show_aggregations">Show aggregations at bottom
        </mat-checkbox>
        <mat-checkbox matTooltip="Show aggregation at top" [(ngModel)]="config.show_aggregations_top"
                      name="show_aggregations">Show aggregations at top
        </mat-checkbox>
        <mat-checkbox matTooltip="Show audit icon for constants cells"
                      [(ngModel)]="config.show_audit"
                      name="show_aggregations">Show audit
        </mat-checkbox>
        <mat-checkbox matTooltip="Add an extra scrollbar to the top of the table when applicable"
                      [(ngModel)]="config.scroll_top"
                      name="top_scrollbar">Add top scrollbar
        </mat-checkbox>
      </div>
      <div class="row low-height">
        <mat-checkbox matTooltip="Allow unlinking of events from components"
                      [(ngModel)]="config.allow_unlink"
                      name="allow_unlink">Allow unlinking events
        </mat-checkbox>
        <mat-checkbox matTooltip="Allow unlinking of components from components"
                      [(ngModel)]="config.allow_unlink_components"
                      name="allow_unlink_components">Allow unlinking components
        </mat-checkbox>
        <mat-checkbox matTooltip="Bypass conditional formatting for out of limits values"
                      [(ngModel)]="config.bypass_limits_formatting"
                      name="bypass_limits_formatting">Disable auto conditional formatting
        </mat-checkbox>
        <mat-checkbox matTooltip="Bypass validation for out of limits values"
                      [(ngModel)]="config.bypass_limits_validation"
                      name="show_status">Bypass limits validation
        </mat-checkbox>
      </div>
      <div class="row low-height">
        <mat-checkbox matTooltip="Show calculation queue status bell icon"
                      [(ngModel)]="config.show_calc_update_status"
                      name="show_status">Show calculation queue status
        </mat-checkbox>
        <mat-checkbox matTooltip="Allow creating components"
                      [(ngModel)]="config.can_create_component"
                      name="can_create_component">Allow creating components
        </mat-checkbox>
      </div>
    </div>
  </div>

  <!-- Download options -->
  <div class="row">
    <mat-form-field>
      <mat-label>Download file type</mat-label>
      <mat-select [(ngModel)]="config.download_file_type">
        <mat-option *ngFor="let option of event_file_download_options" [value]="option.value">
          {{option.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="config.selected_component_type">
    <!--Component Type Property Columns-------------------------------------------------------------------------------->
    <div class="row center">
      <h3 style="margin-bottom:0;">Select the Component Type Property columns

        <mat-checkbox [(ngModel)]="config.constrain_width"
                      matTooltip="Table will be constrained to 100% of the width of the tile.
                  Columns can only be resized within this width (up to a point), but 'fit_content' will override this setting where applicable."
                      style="margin:20px; width: 300px;text-transform: none;">Constrain content to tile width
        </mat-checkbox>
      </h3>
    </div>

    <event-column-selector
        [available_columns]="avail_cols"
        [selected_columns]="config.selected_cols.component_type || []"
        [model_dict]="columnDict"
        [formats_dict]="component_type_formats_dict"
        (columns_changed)="config.selected_cols.component_type=$event.selected_columns;columnsChanged($event, disabled_cols,required_cols)"
        (formats_dict_changed)="config.selected_cols.component_type.format =$event"
    ></event-column-selector>

    <div class="row" style="margin-top:20px;">
      <div class="select-search">
        <select-many-filter
            [label]="'Required Component Type columns'"
            (constant_changed)="updateRequired($event, 'component_type')"
            [filter_compare]="eventsService.compareById"
            [modelValue]="required_cols"
            [options]="config.selected_cols?.component_type"
            [placeholder]="'Please select required columns'"
            [stringFunction]="columnNameFunction"
            [classes]="'fill'"
        >
        </select-many-filter>
      </div>
      <div class="select-search">
        <select-many-filter
            [label]="'Non editable Component Type columns'"
            (constant_changed)="updateDisabled($event, 'component_type')"
            [filter_compare]="eventsService.compareById"
            [modelValue]="disabled_cols"
            [options]="config.selected_cols?.component_type"
            [placeholder]="'Please select disabled columns'"
            [stringFunction]="columnNameFunction"
            [classes]="'fill'"
        >
        </select-many-filter>
      </div>
    </div>

    <ng-container *ngIf="getComponentTypeIds(config.selected_cols?.component_type)?.length">
      <h3 style="margin:30px 0 0;"
          matTooltip="Filter the component type column options by a selected date attribute or property">Adjust
        component type column filters </h3>
      <ng-container *ngFor="let column of config.selected_cols?.component_type">
        <date-property-filter-selector *ngIf="column.type==='component_type'"
                                       [typeId]="column.id"
                                       [typeName]="column.title || column.name"
                                       [propertyDict]="config.component_type_date_filters?.constant_property_time"
                                       [attributeDict]="config.component_type_date_filters?.attribute_time"
                                       [customFilters]="cpDateFilterDict[column.id]"
                                       [attributeOptions]="['start_time', 'end_time']"
                                       (propertyDictChanged)="constantPropertyDateFieldChanged($event)"
                                       (attributeDictChanged)="attributeDateFieldChanged($event)">
        </date-property-filter-selector>
      </ng-container>
    </ng-container>


    <!--Event Type Property Columns-------------------------------------------------------------------------------->
    <h3 style="margin-bottom:0;">Select the Event Type Property columns </h3>

    <event-column-selector
        [available_columns]="avail_event_cols"
        [selected_columns]="config.selected_cols.event || []"
        [model_dict]="columnDict"
        [formats_dict]="event_formats_dict"
        (columns_changed)="config.selected_cols.event=$event.selected_columns;columnsChanged($event, disabled_event_cols,required_event_cols)"
        (formats_dict_changed)="config.selected_cols.event.format =$event"
    ></event-column-selector>

    <div class="row" style="margin-top:20px;">

      <div class="select-search">
        <select-many-filter
            [label]="'Non editable Event Type columns'"
            (constant_changed)="updateDisabled($event, 'event')"
            [filter_compare]="eventsService.compareById"
            [modelValue]="disabled_event_cols"
            [options]="config.selected_cols?.event"
            [placeholder]="'Please select disabled columns'"
            [stringFunction]="columnNameFunction"
            [classes]="'fill'"
        >
        </select-many-filter>
      </div>
    </div>

    <!--Component Relationship Property Columns-------------------------------------------------------------------------------->
    <h3 style="margin-bottom:20px;">Select the Component Relationship Type Property columns </h3>
    <mat-tab-group [disableRipple]="true" class="component-events-form-tabs"
                   *ngIf="config.selected_component_relationship_types">
      <ng-container *ngFor="let ct of config.selected_component_relationship_types">
        <mat-tab [label]="ct.attributes.name">
          <event-column-selector
              [available_columns]="avail_component_cols[ct.id]"
              [selected_columns]="config.selected_cols.component[ct.id] || []"
              [model_dict]="columnDict"
              [formats_dict]="component_formats_dict[ct.id]"
              (columns_changed)="config.selected_cols.component[ct.id]=$event.selected_columns;columnsChanged($event, disabled_component_cols,required_component_cols)"
              (formats_dict_changed)="config.selected_cols.component[ct.id].format =$event"
          ></event-column-selector>

          <div class="row" style="margin-top:20px;">
              <select-many-filter
                  [label]="'Non editable Component Relationship Type columns'"
                  (constant_changed)="updateDisabled($event, 'component', ct.id)"
                  [filter_compare]="eventsService.compareById"
                  [modelValue]="disabled_event_cols"
                  [options]="config.selected_cols?.component[ct.id]"
                  [placeholder]="'Please select disabled columns'"
                  [stringFunction]="columnNameFunction"
                  [classes]="'fill'"
                  class="select-search"
              >
              </select-many-filter>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>


  <!--  Configuration for exporting data to jde -->
  <ng-container>
    <h3 style="margin-bottom:20px;"> Configuration for exporting data to 3rd party
    </h3>
    <button mat-raised-button (click)="openDialog('Add',{})" type="button" color="primary">Add Row</button>
    <table mat-table [dataSource]="config.enable_export_component" #mytable>
      <ng-container matColumnDef="export_name">
        <th mat-header-cell *matHeaderCellDef>Export Name</th>
        <td mat-cell *matCellDef="let row"> {{row.export_name}}</td>
      </ng-container>

      <ng-container matColumnDef="display_text">
        <th mat-header-cell *matHeaderCellDef>Display Text</th>
        <td mat-cell *matCellDef="let row"> {{row.display_text}}</td>
      </ng-container>

      <ng-container matColumnDef="supports_row_export">
        <th mat-header-cell *matHeaderCellDef>Row Export</th>
        <td mat-cell *matCellDef="let row"> {{row.supports_row_export}}</td>
      </ng-container>

      <ng-container matColumnDef="supports_bulk_export">
        <th mat-header-cell *matHeaderCellDef>Bulk Export</th>
        <td mat-cell *matCellDef="let row"> {{row.supports_bulk_export}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action</th>
        <td mat-cell *matCellDef="let row" class="action-link">
          <a (click)="openDialog('Update',row)">Edit</a> |
          <a (click)="openDialog('Delete',row)">Delete</a>
        </td>
      </ng-container>

      <tr mat-header-row
          *matHeaderRowDef="['export_name', 'display_text', 'supports_row_export', 'supports_bulk_export', 'action']"></tr>
      <tr mat-row
          *matRowDef="let row; columns: ['export_name', 'display_text', 'supports_row_export', 'supports_bulk_export', 'action'];"></tr>

    </table>
  </ng-container>
</div>
