import {TStartEnd} from "../event";
import {ModelID} from "../../_typing/generic-types";

export class CommentTableComment {
    id: ModelID;
    comment: string = '';
    duration: string = '';
    start: TStartEnd = '';
    end: TStartEnd = '';
    series: string[] = [];
    session_state: string = '';
    components: string[] = [];
    created_by: string = '';
    changed_by: string = '';
    created_on: TStartEnd;
    changed_on: TStartEnd;

    constructor() {
    }
}
