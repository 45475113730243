import {Injectable} from '@angular/core';
import {IDMap, KeyMap} from "../../_typing/generic-types";
import {Series} from "../../_models/series";
import {TileParameters} from "../../services/tile_data.service";
import {NotificationService} from "../../services/notification.service";
import {ConfigStub} from "../../_typing/config-stub";
import {ComponentType} from "../../_models/component-type";
import {label} from "../../lib/utils";
import {snakeCase} from "lodash-es";
import {ReportParameters} from "./type/report-parameters";
import {ConfigColumn, EventConfigColumn} from "../../_typing/config/config-column";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {REPORT_COLUMN_FILTER_TRIM_LENGTH} from "../../shared/globals";

@Injectable({
    providedIn: 'root'
})
export class JsonContextTagService {

    private configSubject = new BehaviorSubject<ReportParameters>(new ReportParameters());
    config$: Observable<ReportParameters> = this.configSubject.asObservable();

    constructor(private notification: NotificationService) {
    }

    getAlias(name: string): string {
        return snakeCase(name);
    }

    getSeriesTags(config: ReportParameters): KeyMap<KeyMap<string>> {
        let seriesTags: KeyMap<KeyMap<string>> = {}
        let seriesDict: IDMap<ConfigStub<Series>> = {};
        config.series?.forEach(s => seriesDict[s.id] = s);
        Object.entries(config.series_column_dict).forEach(([id, columns]: [string, string[]]) => {
            const seriesName = seriesDict[id]?.attributes?.name;
            const alias = seriesName.toLowerCase();
            seriesTags[seriesName] = {};
            columns.forEach(column => {
                const tag = `<span id="${seriesName}" class="series-element">{{${alias}_${(column.toLowerCase())}}}</span>`;
                seriesTags[seriesName][column] = tag;
            })
        })
        return seriesTags;
    }

    getFunctionTags(config: ReportParameters): KeyMap<string> {
        let functionTags = {}
        config.functions?.forEach(f => {
            const functionName = f.attributes?.name;
            let alias = this.getAlias(functionName);
            functionTags[functionName] = `{{#${alias}}} {{/${alias}}}`;
        })
        return functionTags;

    }

    getComponentTypeTags(componentTypes: ConfigStub<ComponentType>[], columns: EventConfigColumn[] | KeyMap<EventConfigColumn[]>): KeyMap<KeyMap<string>> {
        let componentTypeTags = {}
        componentTypes?.forEach(ct => {
            const componentTypeName = ct.attributes?.name;
            componentTypeTags[componentTypeName] = {};
            let alias = this.getAlias(componentTypeName);
            componentTypeTags[componentTypeName][componentTypeName] = `{{#${alias}}} {{/${alias}}}`;
            if (Array.isArray(columns)) {
                this._getComponentPropertyFilterTags(columns, componentTypeTags[componentTypeName], alias);
            } else {
                Object.values(columns).forEach(columnList => {
                    this._getComponentPropertyFilterTags(columnList, componentTypeTags[componentTypeName], alias)
                })
            }
        })
        return componentTypeTags;
    }

    private _getComponentPropertyFilterTags(columns: EventConfigColumn[], filterTags, alias) {
        columns.map(c => {
            c.filters?.forEach(f => {
                let filterAlias = this.getAlias(f).substring(0, REPORT_COLUMN_FILTER_TRIM_LENGTH);
                let filterTag = `{{#${alias}_${filterAlias}} {{/${alias}_${filterAlias}}}`;
                filterTags[`${label(c.title)} : ${f.substring(0, REPORT_COLUMN_FILTER_TRIM_LENGTH)}`] = filterTag;
            })
        })
    }

    getPropertyTags(selectedColumns: EventConfigColumn[]): KeyMap<string> {
        let propertyTags = {}
        selectedColumns?.forEach(cp => {
            const propertyName = cp.title;
            let alias = this.getAlias(cp.title);
            propertyTags[propertyName] = `{{${alias}}}`;
        })
        return propertyTags;

    }

    getComponentPropertyTags(config: ReportParameters): KeyMap<KeyMap<string>> {
        let propertyTags: KeyMap<KeyMap<string>> = {};
        let componentDict = {};
        config.selectedComponentRelationshipTypes?.forEach(c => componentDict[c.id] = c);
        Object.entries(config.selectedColumns?.component)?.forEach(([id, columns]: [string, EventConfigColumn[]]) => {
            const componentName = componentDict[id]?.attributes?.name;
            propertyTags[componentName] = {};
            columns.forEach(column => {
                const tag = `{{${snakeCase(column.title)}}}`;
                propertyTags[componentName][column.title] = tag;
            })
        })
        return propertyTags;
    }

    copyToClipboard(tagValue: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(tagValue).then(
                () => console.log('Tag copied to clipboard'),
                (err) => console.error('Failed to copy tag: ', err)
            );
        } else {
            // Fallback for older browsers or in case navigator.clipboard is unavailable
            const textArea = document.createElement('textarea');
            textArea.value = tagValue;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                console.log('Tag copied to clipboard');
            } catch (err) {
                console.error('Unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    }
}
