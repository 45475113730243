import {AfterViewInit, Injectable, Input, OnDestroy, OnInit} from '@angular/core';
import {map, takeUntil} from "rxjs/operators";
import {forkJoin, Observable, Subject} from "rxjs";
import {CustomEventsService} from "../../../services/custom-events.service";
import {ConstantProperty} from "../../../_models/constant-property";
import {ComponentType} from "../../../_models/component-type";
import {EventType} from "../../../_models/event-type";
import {ListResponse} from "../../../services/api/response-types";
import {
    COMPONENT_EVENTS_CONFIG, ISelectedColsType
} from "../../../forms/component-events-table-form/component-events-table-form.component";
import {CustomEventsConfig} from "../../../forms/custom-events-form/custom-events-form.component";
import {PivotTileConfig} from "../../../_typing/config/pivot-tile";
import {KeyMap, ModelID} from "../../../_typing/generic-types";
import {EventConfigColumn} from "../../../_typing/config/config-column";
import {Field, FieldMap, Option} from "../../../_typing/query-builder";
import {
    getColIds,
    getComponentTypeIds,
    getEventTypeIds,
    getPropertyIds
} from "../../../tables/custom-events-table/utils";

@Injectable({
    providedIn: 'root'
})
export class FieldOptionService implements OnDestroy {
    readonly onDestroy = new Subject<void>();
    config: COMPONENT_EVENTS_CONFIG | CustomEventsConfig | PivotTileConfig;
    allColumns: boolean = true;

    selectedColumns: ISelectedColsType;
    selectedColsDict: KeyMap<EventConfigColumn>;
    fields: FieldMap;

    constantProperties: ConstantProperty[];
    componentTypes: ComponentType[];
    eventTypes: EventType[];

    constructor(public customEventsService: CustomEventsService,) {
    }


    getDefaultColumn(name, type, value) {
        return (this.allColumns || this.selectedColsDict?.[value]) && ({
            [value]: {
                name: name,
                type: type,
                value: value
            }
        })
    }


    getComponentTypeFields(component_type_id: ModelID, defaultFields: FieldMap, columns: EventConfigColumn[] = []): Observable<FieldMap> {
        const etIds: ModelID[] = getEventTypeIds(columns);
        const cpIds: ModelID[] = getPropertyIds(columns);

        const $componentTypes = this.customEventsService.getComponentTypeComponentTypes(component_type_id)
            .pipe(map(res => {
                this.componentTypes = res.data;
                return this.fieldsFromComponentType(this.componentTypes);
            }));

        const $eventTypes = this.customEventsService.getComponentTypeEventTypes([component_type_id], etIds)
            .pipe(map(res => {
                this.eventTypes = res.data;
                return this.fieldsFromEventType(this.eventTypes);
            }));

        const $properties = this.customEventsService.getComponentTypeProperties([component_type_id], cpIds)
            .pipe(map(res => {
                this.constantProperties = res.data;
                return this.fieldsFromConstantProperty(this.constantProperties);
            }));

        return forkJoin([$properties, $componentTypes, $eventTypes]).pipe(
            map(([propertiesField, componentTypesField, eventTypesField]) => {
                return {...defaultFields, ...propertiesField, ...componentTypesField, ...eventTypesField};
            })
        )
    }

    getEventTypeFields(event_type_id: ModelID, defaultFields: FieldMap, columns: EventConfigColumn[] = []): Observable<FieldMap> {
        const ctIds: ModelID[] = getComponentTypeIds(columns);
        const cpIds: ModelID[] = getPropertyIds(columns);

        const $componentTypes = this.customEventsService.getEventTypeComponentTypes([event_type_id], ctIds)
            .pipe(map(res => {
                this.componentTypes = res.data;
                return this.fieldsFromComponentType(this.componentTypes);
            }));

        const $properties = this.customEventsService.getConstantPropertiesByEventTypeIds([event_type_id], cpIds)
            .pipe(map((res: ListResponse<ConstantProperty>) => {
                this.constantProperties = res.data;
                return this.fieldsFromConstantProperty(this.constantProperties);
            }));

        return forkJoin([$properties, $componentTypes]).pipe(
            map(([propertiesField, componentTypesField]) => {
                return {...defaultFields, ...propertiesField, ...componentTypesField};
            })
        )
    }

    fieldsFromConstantProperty(constantProperties: ConstantProperty[]): FieldMap {
        return constantProperties.reduce((fields, cp) => {
            if (cp.attributes.data_type === 'file') return fields;
            let type = '';
            let options: Option[] = [];
            switch (cp.attributes.data_type) {
                case 'float':
                    type = 'number';
                    break;
                default:
                    type = cp.attributes.data_type;
            }
            if (cp.attributes.is_drop_down_list) {
                type = 'dropdown';
                options = cp.attributes.json.map(option => {
                    return {
                        name: option,
                        value: option
                    };
                });
            }
            fields[`[constant_property@${cp.id}]`] = {
                name: (this.selectedColsDict?.[cp.id]?.title || cp.attributes.name),
                type, ...(type === 'dropdown') && {options: options}
            };
            return fields;
        }, {});
    }

    fieldsFromComponentType(componentTypes: ComponentType[]): FieldMap {
        return componentTypes.reduce((fields, ct) => {
            fields[`[component_type@${ct.id}]`] = {name: ct.attributes.name, type: 'boolean'};
            return fields;
        }, {})
    }

    fieldsFromEventType(eventTypes: EventType[]): FieldMap {
        return eventTypes.reduce((fields, et) => {
            fields[`[event_type@${et.id}]`] = {name: et.attributes.name, type: 'boolean'};
            return fields;
        }, {})
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
