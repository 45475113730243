import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {EventService} from "../../services/event.service";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import * as utils from "../../lib/utils";
import {MatTableDataSource} from "@angular/material/table";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.less', '../../tables/series-table/series-table.component.less'],
    standalone: false
})
export class EventsComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    public events: any;
    public dtp: IDateTimePeriod;
    dataSource: MatTableDataSource<any>;

    columns: string[] = ['Event Type', 'Messsage', 'Factor', 'Affected series',
        'Affected Component', 'Duration', 'Start', 'End'];

    @Input()
    public config: any;

    public period: any;

    constructor(public api: ApiService,
                public eventService: EventService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                public headerData: HeaderDataService) {
    }

    ngOnInit() {
        const ctrl = this;

        ctrl.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            ctrl.period = utils.deepCopy(ctrl.dateTimePeriodService.defaultPeriod);
            ctrl.dtp = ctrl.dateInst.dtp;
            ctrl.getEvents();

        });

        ctrl.dateInst.dateTimePeriodRefreshed$
            .pipe(takeUntil(this.onDestroy))
            .subscribe((dtp) => {
                ctrl.dtp = dtp;
                ctrl.getEvents();
            });
        ctrl.dataSource = new MatTableDataSource<any>([]);
        this.dataSource.filterPredicate = (data, filter) => {
            if (data.attributes.event_type_name.toLowerCase().includes(filter)/* ||
                data.attributes.comment.toLowerCase().includes(filter) ||
                data.attributes.factor.toLowerCase().includes(filter) ||
                data.attributes.series_names.toLowerCase().includes(filter) ||
                data.attributes.component_names.toLowerCase().includes(filter) ||
                data.attributes.start.toLowerCase().includes(filter) ||
                data.attributes.duration.toLowerCase().includes(filter) ||
                data.attributes.end.toLowerCase().includes(filter)*/) {
                return true;
            }
            return false;
        };

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getEvents() {
        const ctrl = this;
        ctrl.eventService.getEvents(ctrl.dtp.start, ctrl.dtp.end).pipe(takeUntil(this.onDestroy)).subscribe(response => {
            ctrl.events = response.data;
            ctrl.dataSource.data = (ctrl.events);
        });
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

}
