import {Injectable} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {EMPTY, from, Observable, of, Subject, throwError} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {catchError, concatMap, map, switchMap, take, takeUntil} from "rxjs/operators";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {AppScope} from "../../services/app_scope.service";
import {COMPONENT_EVENTS_CONFIG} from "../../forms/component-events-table-form/component-events-table-form.component";
import {QueryBuilderService} from "../../services/query_builder.service";
import {ConstantProperty} from "../../_models/constant-property";
import {TileDataService} from "../../services/tile_data.service";
import {FormDialogService} from "../../services/form-dialog.service";

import {getAccountFilter, getRelationWithIdFilter} from "../../services/api/filter_utils";
import {ConstantField, ConstantValue, GenericConstantApiResponse} from "../../_models/api/generic-constant";
import {GenericConstantDataService} from "../../data/generic-constant-data.service";
import {IDMap, KeyMap, ModelID, RESTFilter, ModelWithLimits} from '../../_typing/generic-types';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {NotificationService} from "../../services/notification.service";
import {Component, WireComponent} from "../../_models/component";
import {WireEvent} from "../../_models/event";
import {SolverRunResponse} from "../../components/solver-runner/solver-runner.component";
import {BackgroundJobService, JobPollResponse} from '../../services/background-job.service';
import {ConstantPropertyComponentType} from "../../_models/constant-property-component-type";
import {detectLimits} from "../../lib/utils";
import {EXTENDED_NOTIFICATION_DURATION_MS, NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {FILE_DOWNLOAD_OPTIONS, FileDownloadOption} from "../../_typing/download-filetype";

export interface UpdateComponentConstantCalculationsResponse {
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class ComponentEventsTableService {
    $componentSaved: Subject<{ component: WireComponent, custom_constant_dict: KeyMap<ConstantValue> }> = new Subject();
    $refreshComponentEventsTable: Subject<void> = new Subject();

    constructor(private api: ApiService,
                private http: HttpClient,
                private notification: NotificationService,
                private appScope: AppScope,
                private queryBuilderService: QueryBuilderService,
                private tileData: TileDataService,
                private formDialogService: FormDialogService,
                private genericConstantData: GenericConstantDataService,
                private jobService: BackgroundJobService) {
    }

    updateComponentConstantCalculations(componentIds: string[]): Observable<UpdateComponentConstantCalculationsResponse> {
        return this.http.patch<UpdateComponentConstantCalculationsResponse>('/api/UpdateComponentConstantCalculations', {
            'component_id_list': componentIds
        }).pipe(catchError(({error}) => {
            console.log('error', error);
            if (error && error.message) {
                if (typeof error.message === 'object') {
                    Object.values(error.message).forEach((errorMessage: string) => {
                        this.notification.openError(errorMessage, 5000);
                    });
                }
            }

            return throwError(error);
        }));
    }

    getComponentsFilter(config: COMPONENT_EVENTS_CONFIG, dtp: IDateTimePeriod, search_by_name: string,
                        user_filters: any, query_builder_filters) {
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('component_type', config.selected_component_type.id)
        ];

        if (config.query) {
            options.filters.push(this.queryBuilderService.toJsonQuery(config.query));
        }

        if (config.search_by_name === true) {
            if (!search_by_name) {
                this.notification.openError('Please enter a name to search by in the search box', 10000);
                return;
            }
            options.filters.push(
                {op: 'ilike', name: 'name', val: '%' + search_by_name + '%'}
            );
        }
        if (config.name_must_contain) {
            options.filters.push(
                {op: 'ilike', name: 'name', val: '%' + config.name_must_contain + '%'}
            );
        }
        if (config.filter_by_created_on === true) {
            // let d = moment(this.dateTimePeriodService.dtp.start).add(-5, 'days');
            options.filters.push(
                {op: 'ge', name: 'created_on', val: dtp.start},
                {op: 'le', name: 'created_on', val: dtp.end}
            );
        }
        if (config.exclude_null_start && config.exclude_null_end) {
            options.filters.push({
                and: [
                    {"name": "start_time", "op": "ne", val: null},
                    {"name": "end_time", "op": "ne", val: null}
                ]
            });
        }
        if (config.bypass_date_filters !== true && ((!config?.constant_property_time && (
                config.selected_cols?.component_type.map(item => item.id).includes('start_time') ||
                config.selected_cols?.component_type.map(item => item.id).includes('end_time')
            )) ||
            (config?.constant_property_time && (config.start_prop || config.end_prop)
            ))) {
            let date_query = this.getComponentFilterByConstantValueLogic(config, dtp);
            options.filters.push(date_query);
        }
        if (user_filters) {
            options.filters.push(user_filters);
        }
        if (query_builder_filters) {
            options.filters.push(query_builder_filters);
        }

        return options.filters;

    }

    getApiComponentConstants(component_ids, constant_properties_ids, attributes?: string[]): Observable<GenericConstantApiResponse> {
        return this.genericConstantData.getComponentConstants(component_ids, constant_properties_ids, attributes);
    }

    getQueryWarningTooltip(config: COMPONENT_EVENTS_CONFIG, cp_dict: IDMap<ConstantProperty>): string {
        let tooltip = "";
        if (config.query) {
            tooltip += "This table is using a custom query.";
        }

        if (config.constant_property_time && (config.start_prop || config.end_prop)) {
            tooltip += " This table is filtered by date using start property " +
                (cp_dict[config.start_prop]?.attributes.name || "(Not set)") + " and end property " +
                (cp_dict[config.end_prop]?.attributes.name || "(Not set)");
        }
        tooltip += ". Click to open the table form.";
        return tooltip;
    }

    addTimeFilterProperties(config, prop_ids: string[]): string[] {
        if (config.constant_property_time) {
            if (config.start_prop && !prop_ids.includes(config.start_prop)) {
                prop_ids.push(config.start_prop);
            }
            if (config.end_prop && !prop_ids.includes(config.end_prop)) {
                prop_ids.push(config.end_prop);
            }
        }
        return prop_ids;
    }

    getComponentFilterByConstantValueLogic(config: COMPONENT_EVENTS_CONFIG, dtp: IDateTimePeriod) {
        let date_filter_logic;
        let start, end, dateRanges;
        if (config.constant_property_time && (config.start_prop || config.end_prop)) {
            start = !!config.start_prop;
            end = !!config.end_prop;
            dateRanges = this.getDateFilterConstantProperty(config, dtp);
        } else {
            start = config.selected_cols.component_type.map((item: EventConfigColumn) => item.id).includes('start_time');
            end = config.selected_cols.component_type.map((item: EventConfigColumn) => item.id).includes('end_time');
            dateRanges = this.getDateFilterComponentAttribute(config, dtp);
        }

        const {start_in_range, end_in_range, overlap} = dateRanges;

        if (start && end) {
            date_filter_logic = {or: [start_in_range, end_in_range, overlap]};
        } else if (start) {
            date_filter_logic = start_in_range;
        } else if (end) {
            date_filter_logic = end_in_range;
        }

        return date_filter_logic;
    }

    private getDateFilterComponentAttribute(config: COMPONENT_EVENTS_CONFIG, dtp: IDateTimePeriod) {
        const start_in_range: { or: any[] } = {
            or: [{
                "and": [
                    {"name": "start_time", "op": "ge", "val": dtp.start},
                    {"name": "start_time", "op": "le", "val": dtp.end}
                ]
            }]
        };
        if (!config.exclude_null_start) {
            start_in_range['or'].push({"name": "start_time", "op": 'eq', val: null});
        }
        const end_in_range: { or: any[] } = {
            or: [{
                "and": [
                    {"name": "end_time", "op": "ge", "val": dtp.start},
                    {"name": "end_time", "op": "le", "val": dtp.end}
                ]
            }]
        };
        if (!config.exclude_null_end) {
            end_in_range['or'].push({"name": "end_time", "op": 'eq', val: null});
        }
        // Neither start nor end fall within dtp but dtp falls within custom_constant range
        const overlap = {
            "and": [
                {"name": "start_time", "op": "lt", "val": dtp.start},
                {"name": "end_time", "op": "gt", "val": dtp.end}
            ]
        };

        return {start_in_range, end_in_range, overlap};
    }

    private getDateFilterConstantProperty(config: COMPONENT_EVENTS_CONFIG, dtp: IDateTimePeriod) {
        const start_in_range = {
            "or": [
                // Include component that don't have the Constant
                {
                    "not": {
                        "name": "constant_components",
                        "op": "any",
                        "val": {
                            "name": "constant_property",
                            "op": "has",
                            "val": {"name": "id", "op": "eq", "val": config.start_prop}
                        }
                    }
                },
                // filter component that have the Constant that fall within the start date
                this.generateComponentConstantDateFilter(config.start_prop, dtp)
            ]
        };

        const end_in_range = {
            "or": [
                // Include component that don't have the Constant
                {
                    "not": {
                        "name": "constant_components",
                        "op": "any",
                        "val": {
                            "name": "constant_property",
                            "op": "has",
                            "val": {"name": "id", "op": "eq", "val": config.end_prop}
                        }
                    }
                },
                // filter component that have the Constant that fall within the end date
                this.generateComponentConstantDateFilter(config.end_prop, dtp)
            ]
        };

        // Neither start nor end fall within dtp but dtp falls within custom_constant range
        const overlap = this.generateDtpBetweenDatesFilter(config.start_prop, config.end_prop, dtp);

        return {start_in_range, end_in_range, overlap};
    }

    generateComponentConstantDateFilter(datePropID: ModelID, dtp: IDateTimePeriod, includeNulls: boolean = true) {
        let filter:any = {
            "name": "constant_components",
            "op": "any",
            "val": {
                "and": [
                    {
                        "name": "constant_property",
                        "op": "has",
                        "val": {"name": "id", "op": "eq", "val": datePropID}
                    },
                    {
                        "or": [
                            {
                                "and": [
                                    {"name": "value", "op": "ne", "val": "None"},
                                    {"name": "value_date", "op": "ge", "val": dtp.start},
                                    {"name": "value_date", "op": "le", "val": dtp.end}
                                ]
                            }
                        ]
                    }
                ]
            }
        }
        if (includeNulls) {
            filter.val.and[1].or.push({
                // include component that have the Constant as either null/"None"
                "or": [
                    {"name": "value", "op": 'eq', val: null},
                    {"name": "value", "op": "eq", "val": "None"},
                ]
            });
        }
        return filter;
    }

    generateDtpBetweenDatesFilter(startPropID: ModelID, endPropID: ModelID, dtp: IDateTimePeriod): any {
        return {
            "and": [
                {
                    "name": "constant_components",
                    "op": "any",
                    "val": {
                        "and": [
                            {
                                "name": "constant_property",
                                "op": "has",
                                "val": {"name": "id", "op": "eq", "val": startPropID}
                            },
                            {"name": "value", "op": "ne", "val": "None"},
                            {"name": "value_date", "op": "lt", "val": dtp.start}
                        ]
                    }
                },
                {
                    "name": "constant_components",
                    "op": "any",
                    "val": {
                        "and": [
                            {
                                "name": "constant_property",
                                "op": "has",
                                "val": {"name": "id", "op": "eq", "val": endPropID}
                            },
                            {"name": "value", "op": "ne", "val": "None"},
                            {"name": "value_date", "op": "gt", "val": dtp.end}
                        ]
                    }
                }
            ]
        }
    }

    getComponentsForComponentFilters(first_component_id: string, relationship_component_type): any {
        const options = new SearchQueryOptions();
        const base_type = relationship_component_type.attributes.base_type;
        const ct_id = relationship_component_type.id;
        options.filters = [{
            or: [{
                and: [{
                    name: 'component_component',
                    op: 'any',
                    val: getRelationWithIdFilter('first_component', first_component_id)
                },
                    getRelationWithIdFilter('component_type', ct_id),
                    {name: 'base_type', op: 'eq', val: base_type},
                ]
            }, {
                and: [{
                    name: 'component_component',
                    op: 'any',
                    val: getRelationWithIdFilter('second_component', first_component_id)
                },
                    getRelationWithIdFilter('component_type', ct_id),
                    {name: 'base_type', op: 'eq', val: base_type}
                ]
            }
            ]
        }, {name: 'id', op: 'ne', val: first_component_id}];
        return options.filters;
    }

    getConstantPropertiesSearchFilter(constantPropertiesNames, cp_name_dict, filterString) {
        const constantPropertiesFilters = [];
        constantPropertiesNames.forEach(name => {
            if (cp_name_dict[name]?.attributes.data_type !== 'datetime') {
                constantPropertiesFilters.push({
                    and: [
                        {
                            "name": "constant_components",
                            "op": "any",
                            "val": {
                                "and": [
                                    {
                                        "name": "constant_property",
                                        "op": "has",
                                        "val": {"name": "name", "op": "eq", "val": name}
                                    },
                                    {
                                        "name": "_data",
                                        "op": "ilike",
                                        "val": `%${filterString}%`
                                    }]
                            }
                        }]
                });
            }
        });

        return constantPropertiesFilters;
    }

    getConstantConditionalFormat(item_id, cp_class_dict, cp: ConstantProperty, column: EventConfigColumn, limits_map, value, show_error = true) {
        if (isNaN(value) || !cp) {
            return cp_class_dict;
        }
        let limits = limits_map[cp.id]?.attributes;
        let hi = limits?.hi;
        let hihi = limits?.hihi;
        let low = limits?.low;
        let lowlow = limits?.lowlow;
        if (!((lowlow || lowlow === 0) && (low || low === 0) && (hihi || hihi === 0) && (hi || hi === 0) && (value || value === 0))) {
            return cp_class_dict;
        }
        let value_class = '';
        if (value >= hihi) {
            value_class = 'value-error';
        } else if (value >= hi && value < hihi) {
            value_class = 'value-warning';
        } else if (value <= lowlow) {
            value_class = 'value-error';
        } else if (value <= low && value > lowlow) {
            value_class = 'value-warning';
        } else if (value > low && value < hi && !limits) {
            cp_class_dict[item_id + cp.id] = undefined;
            return cp_class_dict;
        } else if (value > low && value < hi && limits) {
            cp_class_dict[item_id + cp.id] = {class: 'value-ok', message: ''};
            return cp_class_dict;
        }

        let cpName: string = column.title || cp.attributes.description || cp.attributes.name;
        let message: string = `Warning: value ${value} for ${cpName} `;

        if (value_class === "value-warning") {
            if (value >= lowlow && value <= low) {
                message = `${message} is in the low limit range (${lowlow} - ${low})`;
            } else if (value >= hi && value <= hihi) {
                message = `${message} is in the high limit range (${hi} - ${hihi})`;
            }

        } else if (value_class === "value-error") {
            message = `${message} is out of limits (${lowlow} - ${hihi})`;
        }

        cp_class_dict[item_id + cp.id] = {class: value_class, message: message};
        // this.changeDetectorRef.detectChanges();
        if (show_error) {
            this.notification.openError(message, EXTENDED_NOTIFICATION_DURATION_MS, 'Close');
        }
        return cp_class_dict;

    }

    openFileFormatModal(event: any, downloadFunction, default_file_type: FileDownloadOption = 'formatted_csv',
                        relationshipType?: 'event_types' | 'component_types', modelIDs: ModelID[] = []) {
        const data_config = {
            component: 'ComponentEventsDownloadFormatMenu',
            position: {left: 10, top: 10},
            parameters: {
                file_options: FILE_DOWNLOAD_OPTIONS,
                format: default_file_type,
                relationship_type: relationshipType,
                model_ids: modelIDs
            }
        };
        const selectionChanged: (event) => void = (event) => {
            downloadFunction(event.fileType, event.fileConstantPropertyIDs);
        };
        const panelClass = 'events-download-format-menu-dialog';
        const modalDialog = this.formDialogService.openCustomDialog(event, data_config, panelClass, null, selectionChanged);
    }

    push_data_to_client(component_ids: string[], client_exporter_name: string) {
        return this.http.post('api/ExportData', {
            "component_ids": component_ids,
            "client_exporter_name": client_exporter_name
        }).pipe(map((response: any) => response));
    }

    unlinkComponent(component: WireComponent, first_component: WireComponent): Observable<any> {
        return from(this.formDialogService.confirm("Are you sure you want to unlink this component?", "No", "Yes"))
            .pipe(
                switchMap(isConfirmed => {
                    if (!isConfirmed) {
                        return EMPTY;
                    }
                    this.notification.openInfo(`Removing ${component.attributes?.name} from ${first_component.attributes?.name}`, 3000);

                    // TODO replace this logic with the relation upsert API, so all this logic can be handled by the backend.
                    const options = new SearchQueryOptions();
                    options.filters = [{
                        or: [{
                            and: [
                                getRelationWithIdFilter('first_component', first_component.id),
                                getRelationWithIdFilter('second_component', component.id),
                            ]
                        }, {
                            and: [
                                getRelationWithIdFilter('first_component', component.id),
                                getRelationWithIdFilter('second_component', first_component.id),
                            ]
                        }]
                    }, {name: 'id', op: 'ne', val: first_component.id}];
                    return this.api.component_component.searchSingle(options).pipe(concatMap(result => {
                        let component_component = result.data;
                        if (!component_component) {
                            return of(false);
                        }
                        return this.api.component_component.obsDelete(component_component.id);
                    }));
                })
            );
    }

    unlinkEvent(event: WireEvent, component: WireComponent): Observable<any> {
        return from(this.formDialogService.confirm("Are you sure you want to unlink this component?", "No", "Yes"))
            .pipe(
                switchMap(isConfirmed => {
                    if (!isConfirmed) {
                        return EMPTY;
                    }
                    this.notification.openInfo(`Removing event from " ${component.attributes?.name}`, 3000);
                    return this.http.delete('/api/relation/event_component/' + event.id + '/' + component.id);
                }));
    }

    downloadFileFromJobID(jobID: ModelID, filename: string) {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });

        this.http.get('/api/download/job/' + jobID + '/file', {headers: headers, responseType: 'blob'}).subscribe({
            next: (response: any) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(response);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }, error: (error) => {
                this.notification.openError('Failed to download component files: ' + error.reason, 2000);
                console.log('reason for fail', error);
            }
        });
    }

    pollComponentFilesJob(jobID: ModelID): void {
        this.jobService.createJobPoll(jobID)
            .subscribe((completedJob: JobPollResponse) => {
                if (completedJob.status) {
                    const snackBarRef = this.notification.openSuccess('Your file is ready!', undefined, 'Download');
                    snackBarRef.afterDismissed().subscribe(() => {
                        this.downloadFileFromJobID(jobID, completedJob.filename);
                    });
                } else if (!completedJob.processing) {
                    this.notification.openError('Error downloading files: ' + completedJob.message, 10000,);
                }
            });
    }

    downloadComponentFiles = (filters: RESTFilter, constantPropertyIDs: ModelID[], componentIds?: ModelID[]) => {
        let optionToSend;
        if (componentIds.length) {
            optionToSend = 'component_ids=' + encodeURIComponent(JSON.stringify(componentIds));
        } else {
            optionToSend = 'filters=' + encodeURIComponent(JSON.stringify(filters));
        }
        return this.http.get('/api/BulkComponentFileDownload/?'
            + optionToSend
            + '&constant_property_ids='
            + encodeURIComponent(JSON.stringify(constantPropertyIDs)))
            .pipe(take(1))
            .subscribe((resp: { message: string, job: ModelID }) => {
                this.notification.openSuccess('Started file download job', 3000);
                this.pollComponentFilesJob(resp.job);
            });
    }

    // $validateConstantValue = new Subject<ConstantValue>;
    $validateConstantValue = new Subject<{ value: ConstantValue, validated: boolean }>;

    detectComponentConstantLimits($event, value: ConstantValue, limits: Partial<ModelWithLimits<ConstantPropertyComponentType>>, cpColumn: EventConfigColumn, component: Component, validate: boolean): void {
        const shouldValidate = limits?.[cpColumn.id] && validate;
        if (!shouldValidate || (shouldValidate && !detectLimits(limits[cpColumn.id], value).error)) {
            this.$validateConstantValue.next({value: value, validated: false});
            return;
        }

        const config = {
            component: 'LimitsPrompt',
            parameters: {
                limits: limits[cpColumn.id],
                value: value,
                cp_column: cpColumn,
                component: component
            }
        };
        const panelClass = 'prompt-dialog';

        let promptD = this.formDialogService.openCustomDialog($event, config, panelClass);
        promptD.afterClosed().subscribe(result => {
            this.$validateConstantValue.next({value: result, validated: true});
        });
    }
}
