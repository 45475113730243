import {Injectable, OnDestroy} from "@angular/core";
import {concatMap, first, takeUntil, tap} from "rxjs/operators";
import {combineLatest, forkJoin, Subject} from "rxjs";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {SeriesDataService} from "../../services/series_data.service";
import {Series} from '../../_models/series';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from '../../services/api/api.service';
import {GenericChartTileConfiguration} from '../chart-config/generic-chart-tile.configuration';
import {NameOrDescriptionPipe} from "../../shared/pipes";
import {SeriesSummaries} from "../../_models/api/series-summary";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export interface ChartDialogSeriesData {
    estimate_list?: Series[],
    config?: GenericChartTileConfiguration,
    selected_estimate?: Series;
}

@Injectable()

export class ChartDialogService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    series_id: string;
    full_series: Series;
    selected_estimate: Series;
    chart_series_data: ChartDialogSeriesData = {};

    public readonly $seriesSummary: Subject<SeriesSummaries> = new Subject<any>();
    public readonly $chartSeriesData: Subject<ChartDialogSeriesData> = new Subject<ChartDialogSeriesData>();

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private seriesData: SeriesDataService,
                private api: ApiService) {
    }

    getChartData(series_name) {
        const $series = this.api.series.getByName(series_name).pipe(concatMap(result => {
            if (!result) return;
            this.series_id = result.data.id;
            this.full_series = result.data;
            return this.getEstimates(this.series_id).pipe(tap(() => this.getConfig(series_name)));
        }));

        combineLatest([this.dateInst.dateTimePeriodChanged$, $series]).pipe(
            first(), takeUntil(this.onDestroy))
            .subscribe(() => {
                this.$chartSeriesData.next(this.chart_series_data);
            })

    }

    getConfig(series_name) {
        let config: GenericChartTileConfiguration = {
            labels: {title: NameOrDescriptionPipe.prototype.transform(this.full_series.attributes)},
            series_list: [{
                name: series_name,
                type: this.full_series.attributes.default_chart?.toLowerCase() || 'line',
                axis: 'y'
            }]
        }
        if (this.chart_series_data.estimate_list?.length > 0) {
            config.series_list.push({
                name: this.chart_series_data.estimate_list[0].attributes.name,
                type: 'line',
                axis: 'y'
            })
        }
        this.chart_series_data.config = config;
    }

    getEstimates(series_id: string) {
        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'source_series',
            op: 'any',
            val: {name: 'id', op: 'eq', val: series_id}
        }];
        return this.api.series.searchMany(options).pipe(tap(result => {
            this.chart_series_data.estimate_list = result.data;
            this.chart_series_data.selected_estimate = result.data[0];
        }))
    }

    updateConfigEstimate(next_estimate, current_estimate, config) {
        config.series_list = config.series_list.filter(s => s.name !== current_estimate.attributes.name);
        config.series_list.push({
            name: next_estimate.attributes.name,
            type: 'line',
            axis: 'y'
        })

        this.chart_series_data.selected_estimate = next_estimate;
        this.chart_series_data.config = config;
        this.$chartSeriesData.next(this.chart_series_data);
    }

    getSeriesSummary(series_list): any {
       const $summary = this.seriesData.getSeriesSummary(this.dateInst.dtp, series_list, null, ['Min', 'Max', 'Average', 'Value']);
        $summary.pipe(
            tap((response: any[]) => {
                this.$seriesSummary.next(response);
            }),
            first(), takeUntil(this.onDestroy)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
