import {Injectable, OnDestroy} from '@angular/core';
import {
    ConstantPropertyNode, LockVersionDependencyNode, LockVersionDependencyTreeNode,
} from "../../_models/lock-template-version";

import {ReplaySubject, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {uniqueList} from "../../lib/utils";
import {ModelID} from '../../_typing/generic-types';

@Injectable()

export class LockTemplateDependencyTreeService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    applyFilter = new ReplaySubject<string>(1);
    search_string = '';
    tree: LockVersionDependencyTreeNode;
    selected: string[];
    highlightNodes = new Subject<string[]>();
    allDependencies: ModelID[] = [];

    constructor() {

        this.applyFilter.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            takeUntil(this.onDestroy))
            .subscribe(value => {
                this.findNodesFromFilter(value);
            });
    }

    private id = 0;

    findNodesFromFilter(value) {
        this.search_string = value;
        this.selected = [];
        this.searchTree(this.tree, this.search_string);
        this.highlightNodes.next(this.selected);
        return this.selected;
    }

    generateTreeSchema(data: LockVersionDependencyNode): LockVersionDependencyTreeNode {
        this.id = 0;
        this.tree = this.extractNode(data);
        return this.tree;
    }

    private extractNode(node) {
        let children = [];
        this.id++;
        if (this.id > 1000) {
            return node;
        }

        let n: Partial<LockVersionDependencyTreeNode> = {};
        n.attributes = node.attributes;
        n.id = this.id;
        n.name = node.name;

        if (node.event_type_id) {
            n.event_type_id = node.event_type_id;
            n.type = 'event_type';
        } else {
            n.component_type_id = node.component_type_id;
            n.type = 'component_type';
        }

        Object.keys(node).forEach(key => {
            if (key === 'constant_properties') {
                node[key].forEach(cp => {
                    const cp_node: ConstantPropertyNode = cp;
                    cp_node.type = 'constant_property';
                    children.push(cp);
                });
            } else if (['ct_dependencies', 'et_dependencies'].includes(key)) {
                node[key].forEach(dep => {
                    children.push(this.extractNode(dep));
                });
            }
        });

        // Order children by constant_property, then ct_dependencies, then et_dependencies
        n.children = children.sort((a, b) => {
            if (a.type === 'constant_property') {
                return -1;
            }
            if (b.type === 'constant_property') {
                return 1;
            }
            if (a.type === 'component_type') {
                return -1;
            }
            if (b.type === 'component_type') {
                return 1;
            }
            return 0;
        });

        return n;
    }

    searchTree(node, filter): LockVersionDependencyTreeNode[] {
        if (node instanceof Array) {
            for (let i = 0; i < node.length; i++) {
                this.searchTree(node[i], filter);
            }
        } else {
            for (const prop in node) {
                if (prop === 'name' || prop === 'description') {
                    if (filter && node[prop]?.toLowerCase().includes(filter.toLowerCase())) {
                        this.selected.push(node.id);
                    }
                }
                if (node[prop] instanceof Object || node[prop] instanceof Array) {
                    this.searchTree(node[prop], filter);
                }
            }
        }
        return uniqueList(this.selected);
    }

    flattenChildDependencies(): ModelID[] {
        this.allDependencies = [];
        this.flattenChildDependencyTree(this.tree);
        console.log('flattenChildDependencies: ', this.allDependencies);
        return this.allDependencies;
    }

    private flattenChildDependencyTree(node): ModelID[] {
        if (node instanceof Array) {
            for (let i = 0; i < node.length; i++) {
                this.flattenChildDependencyTree(node[i]);
            }
        } else {
            for (const prop in node) {
                if (prop === 'id') {
                    this.allDependencies.push(node.id);
                }
                if (node[prop] instanceof Object || node[prop] instanceof Array) {
                    this.flattenChildDependencyTree(node[prop]);
                }
            }
        }
        return uniqueList(this.allDependencies);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
