<div class="custom-html-container">
    <div *ngIf="safeDisplayText; else placeholder" [innerHTML]="safeDisplayText"></div>
    <ng-template #placeholder>
        <div class="loading-placeholder" [innerHTML]="safePlaceHolder">
            <div class="loading-spinner"></div>
        </div>
    </ng-template>
</div>


