import {Component, OnInit} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {HeaderDataService} from "../services/header_data.service";

@Component({
    selector: 'eventPivot',
    templateUrl: 'event-pivot.component.html',
    standalone: false
})

export class EventPivotComponent implements OnInit {

    pivot_config: any = {
        event_types: "",
        pivot_state: {},
        series_list: []
    };

    constructor(
        private http: HttpClient,
        public dateTimePeriodService: DateTimePeriodService,
        private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.headerData.title = 'Pivot Tables';
        this.headerData.show_dtp = true;
    }
}
