import {Component, Input, ViewEncapsulation} from '@angular/core';
import {
    MatNestedTreeNode,
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatTreeNodeOutlet,
    MatTreeNodeToggle
} from "@angular/material/tree";
import {NgClass, NgIf} from "@angular/common";
import {NestedTreeControl} from "@angular/cdk/tree";
import {FolderEntry, StaticEntry, StaticFolderEntry} from "../../services/menu-tree.service";
import {AppScope} from "../../services/app_scope.service";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'static-menu-tree',
    standalone: true,
    imports: [
        MatNestedTreeNode,
        MatTree,
        MatTreeNode,
        MatTreeNodeDef,
        MatTreeNodeOutlet,
        MatTreeNodeToggle,
        NgIf,
        RouterLink,
        NgClass
    ],
    templateUrl: './static-menu-tree.component.html',
    styleUrl: './static-menu-tree.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class StaticMenuTreeComponent {
    @Input() staticFolders: (StaticFolderEntry | StaticEntry)[];
    treeControl = new NestedTreeControl((node) => node['contents']);

    hasChild = (_: number, node: FolderEntry) => !!node.contents && node.contents.length > 0;

    constructor(private appScope: AppScope) {

    }

    closeSidenav() {
        this.appScope.sidenavOpenSubject.next(false);
        this.appScope.sidenav_open = false;
    }
}
