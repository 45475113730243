import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from "@angular/router";
import {KeycloakCheckLoginGuard} from "./check-login-guard.guard";
import {AppScope} from "../app_scope.service";

export const CheckLoginGuard: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appScope: AppScope = inject(AppScope);
  const auth: KeycloakCheckLoginGuard = inject(KeycloakCheckLoginGuard);
  if (appScope.isKeycloakUser) {
    return auth.isAccessAllowed(route, state);
  }
  appScope.route_before_login = state.url;
  return appScope.afterUserLogin();
};
