import {SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class BaseAuditMixinAttributes {
    changed_by_name?: string;
    changed_by_lastname?: string;
    changed_on?: string;
    created_by_name?: string;
    created_on?: string;
    created_by_lastname?: string;
    changed_by_fk?: string;
}

export class BaseAuditMixinRelationships {
    changed_by: SINGLE_RELATIONSHIP_TYPE;
    created_by: SINGLE_RELATIONSHIP_TYPE;
}

export class BaseAuditMixin {
    attributes: BaseAuditMixinAttributes;
    relationships: BaseAuditMixinRelationships;
}
