<table class="table simple-table">
  <thead>
  <th>Component Type</th>
  <th>Event Type</th>
  <th>Constant Property</th>
  <th>Time Property &nbsp;
    <div class="info" style="display:inline;">
      <i class="fa fa-info-circle"
         matTooltip="If none is selected, the associated component or event's start and end time are used as a default"></i>
    </div>
  </th>
  </thead>
  <tbody>
  <tr *ngIf="collation_data">
    <td>{{collation_data.component_type?.attributes.name}}</td>
    <td>{{collation_data.event_type?.attributes.name}}</td>
    <td>{{collation_data.constant_property?.attributes.name}}</td>
    <td>{{collation_data.time_constant_property?.attributes.name}}</td>
  </tr>
  </tbody>
</table>
