import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {KeyMap, ModelID, ModelName} from "../../_typing/generic-types";
import * as utils from "../../lib/utils";
import {uniqueList, uniqueObjectList} from "../../lib/utils";
import {ConstantProperty} from '../../_models/constant-property';
import {forkJoin, Observable} from 'rxjs';
import {ListResponse} from '../../services/api/response-types';
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {take, takeUntil, tap} from 'rxjs/operators';
import {BaseComponent} from '../../shared/base.component';
import {getColId, getColIds} from "../../tables/custom-events-table/utils";
import {ConstantValue} from '../../_models/api/generic-constant';
import {EventConfigColumn} from "../../_typing/config/config-column";

interface DialogData {
    et_ct_dict: KeyMap<string[]>;
    constant_properties: ModelID[];
    columns: EventConfigColumn[];
    rows: DataRow[];
}

interface DataRow {
    event_type_id: ModelID;
    constant_properties: ModelID[];
    prop_data: KeyMap<ConstantValue>;
    row: number;
    col: number;
    component_type_id: ModelID;
    component_type_name: ModelName;
    current_value: string;
    pasted_value: string;
    include?: boolean;
}

@Component({
    selector: 'event-component-delete-check-dialog',
    templateUrl: './event-component-delete-check-dialog.component.html',
    styleUrls: ['./event-component-delete-check-dialog.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EventComponentDeleteCheckDialogComponent extends BaseComponent {
    component_types: { id: ModelID, name: ModelName }[];
    component_data: any = {};
    event_type_ids: ModelID[];
    event_type_prop_dict: KeyMap<ConstantProperty[]> = {};
    et_ct_dict: KeyMap<ModelID[]>;
    prop_column_dict: KeyMap<ConstantProperty[]> = {};
    column_dict: { [key: string]: EventConfigColumn } = {};

    isBlank = (d: DataRow): boolean => {
        return !d.pasted_value || d.pasted_value === ""
    }

    constructor(private dialogRef: MatDialogRef<EventComponentDeleteCheckDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
                private propertyData: ConstantPropertyDataService,
                private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.component_types = uniqueObjectList(this.dialogData.rows.map(d => {
            return {id: d.component_type_id, name: d.component_type_name}
        }));
        this.component_types.forEach(ct => {
            this.component_data[ct.id] = this.dialogData.rows.filter(d => d.component_type_id === ct.id);
        })
        this.dialogData.columns.map((c, index) => {
            this.column_dict[getColId(c)] = {...c, index};
        });

        this.event_type_ids = uniqueList(this.dialogData.rows.map(d => d.event_type_id));
        this.et_ct_dict = this.dialogData.et_ct_dict;
        const prop_ids = this.dialogData.constant_properties;
        let $cps: Observable<any>[] = [];
        this.event_type_ids.map(et_id => {
            $cps.push(this.propertyData.getUniquePropertiesForEventType(et_id).pipe(
                tap((result: ListResponse<ConstantProperty>) => {
                    const unique_cps = result.data?.filter(cp => prop_ids.includes(cp.id)) || [];
                    this.event_type_prop_dict[et_id] = unique_cps;
                }), take(1), takeUntil(this.onDestroy)))
        })
        forkJoin($cps).subscribe(() => {
            this.component_types.forEach(ct => {
                this.prop_column_dict[ct.id] = [];
                this.event_type_ids.forEach(et => {
                    if (this.belongsToEventType(et, ct.id)) {
                        this.prop_column_dict[ct.id] = this.prop_column_dict[ct.id].concat(this.event_type_prop_dict[et]);
                        this.prop_column_dict[ct.id] = this.getPropColsWithData(ct.id, this.prop_column_dict[ct.id]);
                    }
                })
                this.prop_column_dict[ct.id] = utils.mapOrder(utils.deepCopy(this.prop_column_dict[ct.id]), getColIds(this.dialogData.columns), 'id');
            })
            this.cdr.markForCheck();
        });
    }

    getPropColsWithData(ct_id: ModelID, cp_cols: ConstantProperty[]): ConstantProperty[] {
        const filtered_cols = cp_cols.filter(cp => {
            return !this.component_data[ct_id].every(d => !d.prop_data[cp.id]);
        });
        return filtered_cols;
    }

    belongsToEventType(event_type_id: ModelID, component_type_id: ModelID): boolean {
        return this.et_ct_dict[event_type_id]?.includes(component_type_id);
    }

    includeColumn(event, ct_id: ModelID) {
        this.component_data[ct_id].forEach(d => this.includeCell(event, d));
    }

    includeCell(event, d: DataRow) {
        const d_index = this.dialogData.rows.findIndex(o => o.col === d.col && o.row === d.row);
        if (event) {
            d.include = true;
        } else {
            d.include = false;
        }
    }

    getLinkCount(ct_id: ModelID, type = 'updated') {
        const exclude = this.component_data[ct_id].filter(c => !c.include).length;
        if (type === 'updated') {
            return this.component_data[ct_id].filter(d => d.include && !this.isBlank(d)).length;
        } else {
            return this.component_data[ct_id].filter(d => d.include && this.isBlank(d)).length;
        }
    }

    continuePaste() {
        let data = this.dialogData.rows;
        this.component_types.forEach(ct => {
            this.component_data[ct.id].forEach(d => {
                if (!d.include) {
                    const d_index = this.dialogData.rows.findIndex(o => o.col === d.col && o.row === d.row);
                    data[d_index].pasted_value = d.current_value;
                }
            })
        })
        this.dialogRef.close(data);
    }

    cancelPaste() {
        this.dialogRef.close(false);
    }
}
