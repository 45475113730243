import * as utils from '../../lib/utils';
import {deepCopy, formulaRenderer} from '../../lib/utils';
import * as Handsontable from "handsontable";
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {SeriesDataService} from "../../services/series_data.service";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../../services/notification.service";
import {HotInstance} from "../../services/hot-instance";
import {ListResponse} from "../../services/api/response-types";
import {forkJoin, Observable, Subject} from 'rxjs';
import {first, switchMap, takeUntil, tap, take} from "rxjs/operators";
import {KeyMap, ModelID} from '../../_typing/generic-types';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {SeriesService} from "../../services/models/series.service";
import {Series} from '../../_models/series';

@Component({
    selector: 'series-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class SeriesSheetViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private hot_anchor: ElementRef;

    @ViewChild('hot_anchor') set setHotAnchor(content: ElementRef) {
        this.hot_anchor = content;
    }

    hot: HotInstance;
    @Input()
    series: any;
    @Input()
    process: any;
    val_coords: any;
    schema: any;
    column_list: any[];
    data: any;
    title: string;
    search: string = '';

    engineering_unit: any[];
    event_type: any[];
    series_type: any[];
    users: any[];
    series_light: any[];
    constant_property: any[];
    has_series_type: KeyMap<boolean> = {};

    @Output() refreshInner = new EventEmitter();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private handsontableGenerator: HandsontableGenerator,
                private seriesData: SeriesDataService,
                private http: HttpClient,
                private notification: NotificationService,
                private seriesService: SeriesService) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        this.headerData.add_edit = true;
        this.title = 'Series';
        this.loadPage();
    }

    loadPage() {
        const ctrl = this;
        const promises = [];
        if (!ctrl.series) {
            promises.push(ctrl.api.series.searchMany().pipe(tap(response => {
                ctrl.data = response.data;
            })));
        } else {
            ctrl.data = ctrl.series;
        }
        this.data = this.seriesService.sortBySeriesOrder(this.data);
        promises.push(ctrl.api.engineering_unit.searchMany().pipe(tap(response => ctrl.engineering_unit = response.data)));
        promises.push(ctrl.api.event_type_light.searchMany().pipe(tap(response => ctrl.event_type = response.data)));
        promises.push(ctrl.api.series_type.searchMany().pipe(tap(response => ctrl.series_type = response.data)));
        promises.push(ctrl.api.series_light.searchMany(new SearchQueryOptions(0)).pipe(tap(response => ctrl.series_light = response.data)));

        forkJoin(promises).pipe(
            switchMap((r: ListResponse<any>[]) => {
                const options = this.handsontableGenerator.getUserFilterForItems(ctrl.data);
                return this.api.users.searchMany(options).pipe(tap(users => ctrl.users = users.data));
            }),
            first(), takeUntil(this.onDestroy))
            .subscribe(() => {
                ctrl.createTable();
            });
        ctrl.buildHeader();
    }

    validateCalculation(callback, nameMsg: string, errorMsg: string, formula?: string, unitId?: ModelID) {
        const ctrl = this;
        if (!ctrl.val_coords?.[0]) {
            callback(true);
        }
        const isCalculation = ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'attributes.is_calculation');
        if (!isCalculation) {
            callback(true);
            return;
        }

        const seriesId = ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'id');
        const seriesName = ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'attributes.name');

        let series: Series;
        if (seriesId) {
            series = deepCopy(ctrl.data.find(s => s.id === seriesId));
        } else {
            series = new Series;
            series.attributes.name = seriesName;
        }

        if (formula) {
            series.attributes.name_formula = formula;
        }
        if (unitId) {
            series.relationships.engineering_unit.data = {id: unitId, type: 'engineering_unit'};
        }

        ctrl.CheckCalc(series).pipe(take(1), tap({
            next: (response) => {
                callback(true);
            }, error: (response) => {
                console.log('ERROR SeriesSheetView (validateCalculation): ', response);
                ctrl.notification.openError(errorMsg + response.error.msg);
                callback(false);
            }
        })).subscribe();
    }

    createTable() {
        const ctrl = this;
        const engUnitsLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.engineering_unit);
        const seriesTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_type);
        const seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);
        const eventTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.event_type);
        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.val_coords = null;
        let validateCallback = (value, row, prop) => {
            ctrl.val_coords = [row, prop];
        };
        // @ts-ignore
        Handsontable.hooks.add('beforeValidate', validateCallback, ctrl.hot.instance);

        const engUnitDataSource = ctrl.handsontableGenerator.genLookupDataSource(engUnitsLookups, 'engineering_unit');

        const calcValidator = (value, callback) => {
            const nameMsg = "Please enter a unique name for this series before adding a formula"
            const errorMsg = "Invalid formula entered. "
            ctrl.validateCalculation(callback, nameMsg, errorMsg, value);
        };

        const unitValidator = (value, callback) => {
            const isCalc = ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords?.[0], 'attributes.is_calculation');
            const formula = ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords?.[0], 'attributes.name_formula');
            if (!isCalc || !formula) {
                callback(true);
                return;
            }
            const nameMsg = "Please enter a unique name for this calculation before adding units. ";
            const errorMsg = "Invalid unit for formula. ";
            const unitId = engUnitsLookups.valueLookup[value]
            ctrl.validateCalculation(callback, nameMsg, errorMsg, formula, unitId);
        };

        ctrl.schema = utils.deepCopy(ctrl.seriesData.schema);
        // Since we aren't using an account column, we don't need to deal with this relationship here
        delete ctrl.schema.relationships.account;

        ctrl.column_list = [{
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description',
            width: 300,
        }, {
            type: 'dropdown',
            data: 'attributes.is_calculation',
            title: 'Is Calculation',
            source: [true, false]
        }, {
            data: 'attributes.alias',
            type: 'text',
            title: 'Alias'
        }, {
            data: 'attributes.name_formula',
            type: 'text',
            title: 'Formula',
            validator: calcValidator,
            allowInvalid: true,
            renderer: formulaRenderer,
            width: 300
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesTypeLookups, 'series_type'),
            title: 'Series Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesTypeLookups.source,
            allowInvalid: true,
        }, {
            data: 'attributes.decimal_places',
            title: 'Decimal Places',
            type: 'numeric',
            format: '0',
        }, {
            data: 'attributes.collector_names',
            type: 'text',
            title: 'Collector Names',
            readOnly: true,
            width: 150,
        }, {
            data: 'attributes.alternate_names',
            type: 'text',
            title: 'Alternate Names',
            readOnly: true,
            width: 150,
        }, {
            data: 'attributes.linked_components',
            type: 'text',
            title: 'Linked Components',
            readOnly: true,
            width: 300,
        }, {
            data: 'attributes.specialised_function',
            title: 'Custom Function',
            type: 'checkbox',
        }, {
            data: 'attributes.assumptions',
            title: 'Assumptions',
            type: 'text',
            renderer: formulaRenderer,
        }, {
            data: 'attributes.extra_arguments_string',
            title: 'Extra Arguments',
            width: 200,
        }, {
            data: 'attributes.accumulation',
            title: 'Accumulation',
            type: 'checkbox',
        }, {
            type: 'dropdown',
            data: this.handsontableGenerator.getValueTitleLookup(this.seriesData.chart_types, 'default_chart'),
            source: this.seriesData.chart_types.map(t => t.title),
            title: 'Chart Type',
        }, {
            type: 'dropdown',
            data: 'attributes.kpi_level',
            source: ctrl.seriesData.KPI_levels,
            title: 'KPI Level',
        }, {
            type: 'dropdown',
            data: 'attributes.aggregation',
            source: ctrl.seriesData.aggregation_types,
            title: 'Aggregation',
        }, {
            type: 'dropdown',
            data: 'attributes.sample_period',
            title: 'Sample Period',
            source: ctrl.seriesData.sample_periods,
        }, {
            data: 'attributes.sample_offset',
            title: 'Sample Offset',
            type: 'numeric',
            format: '0',
        }, {
            data: 'attributes.fill_method',
            title: 'Fill Method',
            type: 'dropdown',
            source: Object.keys(ctrl.seriesData.fill_methods),
        }, {
            data: 'attributes.delete_hihilowlow',
            title: 'Delete Out of Limits',
            type: 'checkbox',
        }, {
            data: engUnitDataSource,
            title: 'Unit',
            type: 'autocomplete',
            trimDropdown: false,
            validator: unitValidator,
            strict: true,
            source: engUnitsLookups.source,
            allowInvalid: true,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(eventTypeLookups, 'event_type'),
            title: 'Event Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: eventTypeLookups.source,
            allowInvalid: true,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesLookups, 'weighted_average_series'),
            title: 'Weighted Average',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: true,
        }, {
            data: 'attributes.hihi',
            title: 'Hi Hi',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.hi',
            title: 'Hi',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.lowlow',
            title: 'Low Low',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.low',
            title: 'Low',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.mean',
            title: 'Mean',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.default_value',
            title: 'Default',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.budget',
            title: 'Budget',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By',
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By',
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        const extraAfterChange = (changes: [number, string | number, any, any][], source: string) => {
            // This will fire after every afterChange event, if there is a noticeable performance hit, it can be limited with
            //      if (!['edit', 'CopyPaste.paste'].includes(source)) return;
            // though a few cases might be missing.
            if (changes) {
                changes.forEach(([row, prop, oldValue, newValue]) => {
                    if (prop === 'attributes.is_calculation' && !newValue) {
                        const formulaCol: number = ctrl.hot.instance.propToCol('attributes.name_formula');
                        ctrl.hot.instance.setDataAtCell(row, formulaCol, null);
                    }
                });
            }
            // ctrl.refreshTable();
        };

        ctrl.hot = ctrl.handsontableGenerator.generateTable({
            'series': ctrl.api.series
        }, ctrl.schema, ctrl.column_list, ctrl.hot, extraAfterChange.bind(ctrl));

        ctrl.hot.settings.cells = (row, col) => {
            if (!ctrl.hot.instance) return;
            const cellProperties = {readOnly: false};
            const col_name = this.hot.instance.getColHeader(col).toString();
            const row_data: any = this.hot.instance.getSourceDataAtRow(row);
            if (['Series Type'].includes(col_name) && row_data.id && ctrl.has_series_type[row_data.id]) {
                cellProperties.readOnly = true;
            }

            return cellProperties;
        }
        this.hot.settings.minSpareRows = 1;
        ctrl.hot.settings.contextMenu = {
            callback: utils.gen_row_deletion('id', {
                'series': ctrl.api.series
            }, ctrl.notification, ctrl.hot, (key, options) => {
                // let curr_id;
                const hot = ctrl.hot.instance;

                if (!options || options.length === 0) {
                    this.notification.openError('Error: No option was selected. Please select a row.');
                    return;
                }
                options = options[0];
                if (key === 'delete_row_all') {
                    // TODO MAKE DIALOG
                    if (confirm('Confirm to delete')) {
                        setTimeout(() => {
                            // timeout is used to make sure the menu collapsed before alert is shown
                            const remove_hot_row = curr_id => {
                                hot.alter('remove_row', curr_id);
                                ctrl.refreshTable();
                                // ctrl.hot.instance.render();
                            };
                            let deleted_items = [];
                            const id_map = {};
                            let i = options.start.row;
                            const to_remove_rows = [];
                            for (i; i <= options.end.row; i++) {
                                const curr_id = i;
                                // @ts-ignore
                                const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                                const series = ctrl.data.filter(item => item.id === logical_id)[0];
                                const actual_id = series.id;
                                id_map[actual_id] = curr_id;

                                // @ts-ignore
                                if (curr_id === undefined || curr_id === null || series.id === null) {
                                    remove_hot_row(curr_id);
                                } else {
                                    deleted_items.push(ctrl.http.get('/api/utils/full_series_delete/' + actual_id).subscribe({
                                        next: response => {
                                            // @ts-ignore
                                            remove_hot_row(id_map[response.id]);
                                            ctrl.notification.openSuccess('Deleted ' + actual_id + ' row from server', 3000);
                                        }, error: (ret) => {
                                            ctrl.notification.openError(`Failed to delete row from server, check dependent objects: ${ret.message}`);
                                            return ret;
                                        }
                                    }));
                                }
                            }
                            Promise.all(deleted_items).then(responses => {
                                responses.forEach(response => {
                                    if (response && response.error) {
                                        ctrl.notification.openError('Series not deleted: ' + response.error.message);
                                    } else {
                                        ctrl.notification.openSuccess('Deleted', 3000);
                                    }
                                })
                            })

                        }, 100);
                    }
                }

                if (key === 'edit_series') {
                    const curr_id = options.start.row;
                    const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                    const series = ctrl.data.filter(item => item.id === logical_id)[0];

                    const dialogRef = ctrl.seriesData.upsertSeries(ctrl.process, series);
                    dialogRef.afterClosed().toPromise().then(response => {
                        console.log('edit_series context menu dialog closed, response = ', response);
                        if (response) {
                            let updated_series;
                            if (response.series) {
                                updated_series = response.series;
                            } else {
                                updated_series = response;
                            }

                            Object.keys(series).forEach(key => {
                                series[key] = updated_series[key];
                            });
                            console.log("updated", updated_series);
                            //ctrl.refreshTable()
                            //Prevent error on second edit
                            ctrl.refreshInner.emit();
                        }
                    });
                }
            }),
            items: {
                "delete_row_all": {
                    name: 'Delete Row and Dependencies Permanently'
                },
                "edit_series": {
                    name: 'Edit Series'
                }
            }
        };
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.data.map(s => {
            ctrl.has_series_type[s.id] = s.relationships.series_type.data?.id != null;
            if (!s.attributes.is_calculation) {
                s.attributes.is_calculation = false;
            }
            return s;
        });

        ctrl.hot.instance = new Handsontable(ctrl.hot_anchor.nativeElement, ctrl.hot.settings);
    }

    /**
     * NOTE: this method not only renders the table, it was an attempt to remove the empty rows that are added at the bottom of HOT after interaction.
     * Removes the extra empty rows from the table (apart from the default 3)
     */
    refreshTable() {
        const ctrl = this;
        ctrl.data.map(s => {
            ctrl.has_series_type[s.id] = s.relationships.series_type.data?.id != null;
        })
        ctrl.hot.instance.render();
        ctrl.hot.instance.render();
    }

    CheckCalc(series): Observable<any> {
        const url = this.seriesService.getCalcCheckRequest(series);
        return this.http.get(url);
    }

    save() {
        const ctrl = this;
        if (!this.validate()) return;
        let results = ctrl.hot.save();
        Promise.all(results.deferreds).then(() => {

            results.savePromises.forEach(promise => {
                promise.then(response => {
                    if (ctrl.process) this.api.series_component.save({
                        relationships: {
                            component: {
                                data: {
                                    id: ctrl.process.id,
                                    type: 'component'
                                }
                            }, series: {data: {id: response.data.id, type: 'series'}}
                        }, type: 'series_component'
                    }).then(response => {
                        console.log('Saved the series', response);
                        ctrl.notification.openSuccess("Successfully saved the series", 2000);
                    }, reason => {
                        if (reason && reason.errors && reason.errors.length > 0 && reason.errors[0].detail) {
                            ctrl.notification.openError(reason.errors[0].detail)
                        }
                        console.log('Failed to save series', reason)
                    });
                });
            });
            ctrl.data = results.data;
            ctrl.refreshTable();
        });
    }

    validate(): boolean {
        const sourceData = this.hot.instance.getSourceData();
        let isValid = true;
        for (const item of sourceData) {
            if (item.id && !this.hot.change_ids.includes(item.id)) {
                continue;
            }
            if (!this.seriesService.validateSeries(item)) {
                isValid = false;
                break;
            }
        }
        return isValid;
    }

    /** Opens a dialog for adding a new series to the table */
    newSeries() {
        const ctrl = this;
        ctrl.seriesData.upsertSeries(ctrl.process).afterClosed().subscribe({
            next: (response) => {
                // user closed the dialog box.
                if (response) {
                    let updated_series;
                    if (response.series) {
                        updated_series = response.series;
                    } else {
                        updated_series = response;
                    }
                    ctrl.data.push(updated_series);
                    ctrl.refreshTable();
                } else {
                    return;
                }
            }, error: response => {
                this.notification.openError("Series not saved: " + response);
            }
        });
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    refresh() {
        if (this.process) {
            this.refreshInner.emit();
        } else {
            this.loadPage();
        }
    }

    buildHeader() {
        const ctrl = this;
        if (ctrl.process) {
            ctrl.headerData.title = 'Series List: ' + ctrl.process.attributes.name;
            ctrl.headerData.setPath(ctrl.process);
        } else {
            this.headerData.title = 'Series List';
        }
        let buttons = [
            {name: 'Add Series', func: this.newSeries.bind(this), class: 'icon-add'},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download'},
            {name: 'Refresh', func: this.refresh.bind(this), class: 'icon-refresh', params: {}},
            {name: 'Save', func: this.save.bind(this), class: 'icon-save'}
        ];
        if (this.series && this.headerData.component_buttons_loaded === false) {
            this.headerData.buttons = this.headerData.buttons.concat(buttons);
            this.headerData.component_buttons_loaded = true;
        } else {
            this.headerData.buttons = buttons;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}

function baseTypeRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    if (instance.getDataAtRowProp(row, 'attributes.name')) {
        cellProperties.readOnly = false;
        td.style.background = '#ffffff';
    } else {
        td.style.background = '#EEE';
        cellProperties.readOnly = true;
    }
}
