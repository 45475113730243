<div class="file-upload-box-container" [ngClass]="{'container-file-selected': file}">
  <div class="file-upload-box">
    <label for="theFile" *ngIf="!file">
      <ng-container *ngIf="!file">
        <span class="material-symbols-outlined">upload_file</span>
        <div>{{instruction}}</div>
        <div>or</div>
      </ng-container>
    </label>
    <div class="file-selected" *ngIf="file"><b>Selected: </b>{{file.name}}</div>
    <div class="btn" >Browse</div>
    <input #fileDropInput (change)="handleFileInput($event.target); preview=null" id="theFile" name="file" type="file">
  </div>

</div>
