import {Injectable} from '@angular/core';
import {DateTimePeriodService} from "./date-time-period.service";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {DateTimeInstanceService} from "./date-time-instance.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CachingService {
    private readonly onDestroy = new Subject<void>();
    vdt_context_cache: any = {};
    series_summary_cache: any = {};
    component_events_cache: any = {};
    dtp: IDateTimePeriod;

    constructor(private dateInst: DateTimeInstanceService) {
        this.dateInst.dateTimePeriodChanged$.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.dtp = dateInst.dtp;
        })
    }

    getCachedContextConfig(id) {
        let config: any = {};
        let cached_context = this.vdt_context_cache[id];
        config.selected_series = cached_context.selected_series;
        config.value_only = cached_context.value_only;
        config.series_unit = cached_context.series_unit;
        config.show_unit = cached_context.show_unit;
        config.primary_value = cached_context.primary_value_name;
        return config;
    }

    getCachedContextSummary(id, fav_key) {
        let summary: any = {};
        let cached_context = this.vdt_context_cache[id];
        summary = {
            Sparkline: cached_context.sparkline
        };
        summary[cached_context.primary_value_name] = cached_context.primary_value_value;
        summary[fav_key] = cached_context[fav_key]
        return summary;
    }

    cacheContext(config, summary, est_favourability_mapping) {
        const ctrl = this;
        let cached_context: any = {};
        cached_context.selected_series = config.selected_series;
        cached_context.value_only = config.value_only;
        cached_context.series_unit = config.series_unit;
        cached_context.show_unit = config.show_unit;
        cached_context.primary_value_name = config.primary_value;
        cached_context.primary_value_value = summary[config.primary_value];
        cached_context.sparkline = summary['Sparkline'];
        cached_context[est_favourability_mapping[config.primary_value]] = summary[est_favourability_mapping[config.primary_value]];
        cached_context.id = config.selected_series.id + ctrl.dtp.start + ctrl.dtp.end;
        cached_context.est_favourability_dict = est_favourability_mapping;
        ctrl.vdt_context_cache[cached_context.id] = cached_context;
        return cached_context.id;
    }

    cacheSeriesSummary(key: string, content, replace = false): void {
        key = key + this.dtp.start + this.dtp.end;
        if (!this.series_summary_cache[key] || replace === true) {
            this.series_summary_cache[key] = content;
        }
    }

    //TODO this needs to check that all config values are the same, not usable like this
    getCachedSeriesSummary(key, config?) {
        key = key + this.dtp.start + this.dtp.end;
        console.log('getCachedSeriesSummary: ', this.series_summary_cache[key], config);
        return this.series_summary_cache[key];
    }

    cacheComponentEvents(key, content, properties, replace = false): void {
        if (!this.component_events_cache[key] || replace === true) {
            this.component_events_cache[key] = {events: content, properties: properties};
        }
    }

    getCachedComponentEvents(key) {
        return this.component_events_cache[key];
    }

    clearCache() {
        this.vdt_context_cache = {};
        this.component_events_cache = {};
        this.series_summary_cache = {};
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }
}
