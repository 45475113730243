import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Field, Rule} from "../../../../_typing/query-builder";
import {QueryBuilderFormService} from "../../../../services/query-builder-form.service";
import {takeUntil} from "rxjs/operators";
import {BaseComponent} from "../../../../shared/base.component";
import {KeyMap} from "../../../../_typing/generic-types";

@Component({
    selector: 'query-operator',
    templateUrl: './query-operator.component.html',
    styleUrls: ['./query-operator.component.less'],
    standalone: false
})
export class QueryOperatorComponent extends BaseComponent {
    @Input() rule: Rule;
    @Input() field: Field
    @Output() selectedOperatorChanged: EventEmitter<string> = new EventEmitter();
    operatorMap: KeyMap<string[]>;
    operatorDesc: KeyMap<string>;

    constructor(private queryBuilderFormService: QueryBuilderFormService) {
        super();
        this.operatorMap = this.queryBuilderFormService.operatorMap;
        this.operatorDesc = this.queryBuilderFormService.operatorDesc;
    }


    setSelectedOperator(event: string): any {
        this.selectedOperatorChanged.next(event);
    }
}
