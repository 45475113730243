import {TextViewModel} from "./text-viewmodel";
import {BaseViewModel} from "./base-viewmodel";

export class EquipmentViewModel extends BaseViewModel {
    parent_component: any; //The component that this series belongs to (process/stream/equipment)
    text: TextViewModel;

    private defaultIcon = "/static/icons/custom_icons/mining icon pump.svg";

    constructor(EquipmentDataModel, parent_component) {
        super(EquipmentDataModel)
        this.parent_component = parent_component;

        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (!this.data.attributes.json['x']) {
            this.data.attributes.json['x'] = window.innerWidth / 2;
        }
        if (!this.data.attributes.json['y']) {
            this.data.attributes.json['y'] = window.innerHeight / 2;
        }

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }
        if (!this.data.attributes.json['text'].x) {
            this.data.attributes.json['text'].x = this.width() / 2;
        }
        if (!this.data.attributes.json['text'].y) {
            this.data.attributes.json['text'].y = this.height() - 15;
        }
        if (!this.data.attributes.json['text'].rotate) {
            this.data.attributes.json['text'].rotate = 0;
        }

        if (this.data.attributes.icon == null) {
            this.data.attributes.icon = this.defaultIcon;
        }

        this.text = new TextViewModel(this.data.attributes.json.text);
    }

    x() {
        return this.data.attributes.json.x;
    };

    y() {
        return this.data.attributes.json.y;
    };

    parent() {
        let component_type = this.parent_component.data.attributes?.base_type.toUpperCase();
        return component_type + ": " + this.parent_component.data.attributes.name;
    };

    name() {
        return this.data.attributes.name;
    };

    height() {
        return 80;
    };

    width() {
        return 80;
    };
}
