<div class="row utils-bar comment-events-utils-bar">
  <div class="row table-filter">
    <form (submit)="updateSearchFilter()" class="row">
      <div class="row" style="position:relative;">
        <input [(ngModel)]="filterString" placeholder="Search..." name="search"/>
        <i class="fa fa-times" matTooltip="Clear database search" (click)="filterString='';updateSearchFilter()"></i>
      </div>
      <button type="submit" mat-button class="bordered-button search-button" [disabled]="!filterString">
        <i class="fa fa-search"></i>
      </button>
    </form>
  </div>
  <!--TODO style the paginator to match the site-->
  <mat-paginator showFirstLastButtons></mat-paginator>
</div>

<div class="table-container printout ">
  <table [dataSource]="dataSource" mat-table matSort (matSortChange)="updateSort($event)"
         matSortDirection="desc" class="table-striped simple-table">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
      <ng-container *ngIf="column==='component_type_name'">
        <th *matHeaderCellDef mat-sort-header class="freeze-pane" mat-header-cell>
          {{columnDict[column]}}
        </th>
      </ng-container>
      <ng-container *ngIf="column!=='component_type_name'">
        <th *matHeaderCellDef class="freeze-pane" mat-header-cell>
          {{columnDict[column]}}
        </th>

      </ng-container>
      <ng-container *ngIf="column==='printout_name'">
        <ng-container *matCellDef="let element">
          <td mat-cell class="left">
            <div class="row center">
              <i class="edit-inline hide-xs" (click)="addEditMapper(element)"></i>
              <div *ngIf="!element.attributes?.printout_id">{{element.attributes.printout_name}}</div>
              <div
                  *ngIf="element.attributes?.printout_id">{{element.attributes.printout_id | upperfirst | removeUnderscore}}
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="column==='component_type_name'">
        <td *matCellDef="let element" class="left" mat-cell>
          {{element.attributes.component_type_name}}
        </td>
      </ng-container>
      <ng-container *ngIf="column==='conditions'">
        <td *matCellDef="let element" mat-cell class="left" [ngClass]="{'condition-padding': printoutComponentTypeFlatten[element.id]}">
          <ng-container *ngFor="let item of printoutComponentTypeFlatten[element.id]|keyvalue;">
            <div title="the title">{{item.key}} = {{item.value}}</div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="column==='delete'">
        <td *matCellDef="let element" mat-cell class="center">
          <span class="fa fa-trash" (click)="deleteMapper(element)"></span>
        </td>
      </ng-container>
    </ng-container>

    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns;" mat-row></tr>

  </table>
</div>

