import {Process} from "./process";
import {Stream} from "./stream";
import {Connector} from "./connector";
import {Equipment} from "./equipment";
import {Series} from "./series";
import {SeriesComponent} from "./series-component";
import {Component} from "./component";
import {ConstantComponent} from "./constant-component";

/**
 * Bundle of persisted data retrieved from the server for working with flowcharts.
 *
 * NOTE: should not be used for storing in memory specific data, store it on viewmodel properties instead.
 */
export class FlowchartData {
    parent_process: Process | null;

    // the process that this flowchart was generated for
    process_focus: Process;
    processes: Process[] = [];
    streams: Stream[] = [];
    equipment: Equipment[] = [];
    connectors: Connector[] = [];
    constant_components: ConstantComponent[] = [];
    series_components: SeriesComponent[] = [];
    // FIXME this seems unused
    series_components_empty: SeriesComponent[] = [];
    series_component_map: { [series_component_id: string]: SeriesComponent } = {};
    series_component_atts_dict: { [series_id: string]: Series } = {};
    selected_series: Series[] = [];
    selected_series_ids: string[] = [];

    get components(): Component[] {
        let components: Component[] = [this.process_focus];
        components = components.concat(this.processes);
        components = components.concat(this.equipment);
        components = components.concat(this.streams);
        return components;
    }
}
