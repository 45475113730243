import * as utils from '../lib/utils';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {AppScope} from "../services/app_scope.service";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import {EstimateDialogData, EstimateFormComponent} from "../forms/estimate-form.component";
import {SeriesDataService} from "../services/series_data.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {getRelationWithIdFilter} from "../services/api/filter_utils";
import {NotificationService} from "../services/notification.service";
import {SnackbarComponent} from "../notifications/snackbar/snackbar.component";
import {tap} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Component({
    selector: 'estimate-data-sheet-view',
    templateUrl: 'estimate-data-sheet-view.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EstimateDataSheetViewComponent implements OnInit, OnDestroy {
    input_sheet_ready: boolean;
    estimate_series: { attributes: any, id: string, relationships: any, type: string }[];
    estimate_types_list: { attributes: any, id: string, relationships: any, type: string }[];
    forecast_year: number;
    kpis: string[];
    start: Date;
    end: Date;
    sample_period: number;
    series_list: {
        attributes: {
            description: string,
            event_type_name?: string,
            kpi_level: string,
            name: string
        }, id: string, type: string
    }[];
    permissions: any;
    selected_estimate: { attributes: any, id: string, relationships: any, type: string };
    selected_kpis: string[];
    private loading_snackbar: MatSnackBarRef<SnackbarComponent>;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private appScope: AppScope,
                private notification: NotificationService,
                private dialog: MatDialog,
                private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        this.loading_snackbar = this.notification.openInfo('Loading estimates');

        this.headerData.title = 'Estimates Input';

        this.forecast_year = new Date().getFullYear();
        this.kpis = ['Level 1', 'Level 2', 'Level 3', 'Level 4', '', null];
        this.selected_kpis = ['Level 1', 'Level 2'];

        this.start = new Date();
        this.start = utils.setToHour(this.start, 0);
        this.end = new Date();
        this.end = utils.setToHour(this.end, 23);
        this.sample_period = 2;

        this.appScope.auth_complete.promise.then(() => {
            const observables = [];
            observables.push(this.api.series_type.searchMany().pipe(tap(response => this.estimate_types_list = response.data)));
            observables.push(this.api.series_light.searchMany().pipe(tap(response => this.series_list = response.data)));
            this.buildHeader();
            forkJoin([observables]).subscribe(() => {
                this.permissions = {}; // plantData.permissions;

                this.estimate_types_list.forEach(estimate => {
                    if (estimate.attributes.name === "Forecast") {
                        this.selected_estimate = estimate;
                    }
                });
                this.refreshEstimates();
            });
        });
    }

    ngOnDestroy(): void {
        if (this.loading_snackbar) {
            this.loading_snackbar.dismiss();
        }
    }

    refreshEstimates() {
        this.input_sheet_ready = false;

        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('estimate_type', this.selected_estimate.id)
        ];
        this.api.series.searchMany(options).subscribe(response => {
            this.loading_snackbar.dismiss();
            this.estimate_series = response.data;
            if (this.estimate_series.length === 0) {
                this.notification.openError('No estimates found for process');
            } else {
                this.refreshDates();
            }
        });
    }

    addEstimateForm() {
        const dialogConfig = new MatDialogConfig<EstimateDialogData>();

        dialogConfig.data = {
            selectedEstimateType: this.selected_estimate.attributes.name,
            seriesList: this.series_list,
            estimate: null,
            seriesData: this.seriesData
        };

        dialogConfig.panelClass = ['default-form-dialog', 'estimate-form-dialog'];
        let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(response => {
        });

    }

    refreshDates() {
        this.input_sheet_ready = false;
        setTimeout(() => this.input_sheet_ready = true, 300);
    }

    buildHeader() {
        this.headerData.title = 'Estimates Input';
        this.headerData.buttons = [
            {name: 'Reload Estimates', func: this.refreshEstimates.bind(this), class: 'icon-refresh', params: {}},
            {name: 'Add Estimate', func: this.addEstimateForm.bind(this), class: 'icon-add', params: {}}
        ];
    }
}
