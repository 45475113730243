<div class="function-builder">
  <div class="row">
    <select-many-search-component [label]="'Select functions for report'"
                                  [value]="config.functions"
                                  [api_model]="'custom_function'"
                                  [classes]="'fill'"
                                  (selectionChange)="updateFunctions($event)"
                                  class="flex-half alone"
    >
    </select-many-search-component>
  </div>
</div>

