import * as Handsontable from "handsontable";
import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HotInstance} from "../../services/hot-instance";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {SeriesType} from '../../_models/series-type';
import {MatSnackBar} from "@angular/material/snack-bar";
import {getManyRelationWithIdFilter, getRelationWithIdFilter} from "../../services/api/filter_utils";
import {formulaRenderer} from "../../lib/utils";
import {tap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";

@Component({
    selector: 'estimate-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class EstimateSheetViewComponent implements OnInit {
    private hot_anchor: ElementRef;

    @ViewChild('hot_anchor') set setHotAnchor(content: ElementRef) {
        this.hot_anchor = content;
    }

    hot: HotInstance;
    title: string;
    schema: any;
    column_list: any[];
    search: string;

    targets: any[];
    users: any[];
    series_types: SeriesType[];
    series_light: any[];
    required = ["attributes.name", "relationships.series_type", "relationships.source_series"];
    @Input()
    processID?: string;

    constructor(
        private api: ApiService,
        private headerData: HeaderDataService,
        private seriesData: SeriesDataService,
        private snackbar: MatSnackBar,
        private handsontableGenerator: HandsontableGenerator) {
        this.hot = new HotInstance(this.required);
    }

    source_map = {};

    ngOnInit(): void {
        this.title = 'Target Series';

        const observables: Observable<any>[] = [];
        const options = new SearchQueryOptions();
        options.filters = [
            {name: 'source_series', op: 'ne', val: null}];
        if (this.processID) {
            options.filters.push(getManyRelationWithIdFilter('processes', this.processID));
        }
        observables.push(this.api.series.searchMany(options).pipe(tap(response => {
            this.targets = response.data;
            this.wrapSourceSeries();
        })));

        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));
        observables.push(this.api.series_type.searchMany().pipe(tap(response => this.series_types = response.data)));
        observables.push(this.api.series_light.searchMany(new SearchQueryOptions(0)).pipe(
            tap(response => this.series_light = response.data)));

        forkJoin(observables).subscribe(this.createTable.bind(this));

        this.buildHeader();
    }

    createTable() {
        const seriesTypeLookups = this.handsontableGenerator.gen_lookups(this.series_types);
        const seriesLookups = this.handsontableGenerator.gen_lookups(this.series_light);

        this.schema = {
            id: null,
            type: 'series',
            attributes: {
                is_calculation: null,
                name_formula: null,
                name: null,
                description: null,
                fill_method: null,
                aggregation: null,
                decimal_places: null,
                sample_period: null,
                sample_offset: null,
                created_on: null,
                changed_on: null,
                default_chart: null,
                accumulation: null,
                series_id: null
            },
            relationships: {
                source_series: {data: {type: 'series', id: null}},
                series_type: {data: {type: 'series_type', id: null}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };
        this.column_list = [{
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }, {
            type: 'dropdown',
            data: 'attributes.is_calculation',
            title: 'Is Calculation',
            source: [true, false]
        }, {
            data: this.handsontableGenerator.genLookupDataSource(seriesLookups, 'source_series'),
            title: 'Source Series',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
        }, {
            data: this.handsontableGenerator.genLookupDataSource(seriesTypeLookups, 'series_type'),
            title: 'Series Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesTypeLookups.source,
            allowInvalid: true
        }, {
            data: 'attributes.name_formula',
            type: 'text',
            title: 'Formula',
            allowInvalid: true,
            renderer: formulaRenderer,
            width: 300
        }, {
            type: 'dropdown',
            data: 'attributes.aggregation',
            source: this.seriesData.aggregation_types,
            title: 'Aggregation'
        }, {
            data: 'attributes.decimal_places',
            title: 'Decimal Places',
            type: 'numeric',
            format: '0',
        }, {
            type: 'dropdown',
            data: this.handsontableGenerator.getValueTitleLookup(this.seriesData.chart_types, 'default_chart'),
            source: this.seriesData.chart_types.map(t => t.title),
            title: 'Chart Type',
        }, {
            type: 'dropdown',
            data: 'attributes.sample_period',
            title: 'Sample Period',
            source: this.seriesData.sample_periods
        }, {
            data: 'attributes.sample_offset',
            title: 'Sample Offset',
            type: 'numeric',
            format: '0'
        }, {
            data: 'attributes.fill_method',
            title: 'Fill Method',
            type: 'dropdown',
            source: Object.keys(this.seriesData.fill_methods)
        }, {
            data: 'attributes.accumulation',
            title: 'Accumulation',
            type: 'checkbox'
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        this.hot = this.handsontableGenerator.generateTable(this.api.series, this.schema, this.column_list, this.hot);
        this.hot.ready = true;
        this.hot.settings.data = this.targets;
        this.hot.settings.cells = (row, col) => {
            if (!this.hot.instance) return;
            const cellProperties = {readOnly: false};
            const col_name = this.hot.instance.getColHeader(col).toString();
            const row_data: any = this.hot.instance.getSourceDataAtRow(row);
            if (['Source Series', 'Series Type'].includes(col_name) && row_data.id) {
                cellProperties.readOnly = true;
            }

            return cellProperties;
        }
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    unwrapSourceSeries() {
        //Set it back to multi relationship before saving
        this.targets.forEach(t => {
            if (!t.id) {
                t.relationships.source_series = {data: [{id: t.relationships.source_series?.data?.id, type: 'series'}]};
            } else {
                t.relationships.source_series = {data: this.source_map[t.id]};
            }
        });
    }

    wrapSourceSeries() {
        //Make it a single relationship to show as single cell
        this.targets.map(t => {
            if (!t.id) return;
            this.source_map[t.id] = t.relationships.source_series.data || [{id: null, type: 'series'}];
            t.relationships.source_series = {data: this.source_map[t.id][0]};
        });
    }

    upsertSeriesComponents(results) {
        results.savePromises.forEach(promise => {
            promise.then(response => {
                this.api.series_component.save({
                    relationships: {
                        component: {
                            data: {
                                id: this.processID,
                                type: 'component'
                            }
                        }, series: {data: {id: response.data.id, type: 'series'}}
                    }, type: 'series_component'
                }).then(resp => {
                    console.log('Saved the series', resp);
                    this.snackbar.open("Successfully saved the series", undefined, {duration: 2000});
                }, reason => {
                    if (reason && reason.errors && reason.errors.length > 0 && reason.errors[0].detail) {
                        this.snackbar.open(reason.errors[0].detail, "Hide");
                    }
                    console.log('Failed to save series', reason);
                });
            });
        });
    }

    save() {
        for (let i = 0; i < this.targets.length; i++) {
            const t = this.targets[i];
            if (!t.id && t.attributes?.name && (!t.relationships.source_series?.data?.id || !t.relationships.series_type?.data?.id)) {
                this.snackbar.open('Target series must have a source and a series type: ' + t.attributes.name, 'Hide', {duration: 10000});
                return;
            }
        }

        this.unwrapSourceSeries();
        const results = this.hot.save();
        Promise.all(results.deferreds).then((all) => {
            if (this.processID) {
                this.upsertSeriesComponents(results);
            }
            this.targets = results.data;
            this.wrapSourceSeries();
        }, e => {
            this.wrapSourceSeries();
        });
    }


    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = 'Estimates';
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}}
        ];
    }
}
