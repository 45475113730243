import {Injectable, OnDestroy} from '@angular/core';
import {Series} from "../../_models/series";
import {forkJoin, Observable, Subject} from "rxjs";
import {ApiService} from "../../services/api/api.service";
import {SeriesDataService} from "../../services/series_data.service";
import {
    IChartLabels,
    WaterfallChartTileConfiguration
} from "../chart-config/generic-chart-tile.configuration";
import {ChartConfigTranslationService} from "../../services/chart-config-translation.service";
import {map, mergeMap, takeUntil, tap} from "rxjs/operators";
import {GenericChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {upperfirst, deepCopy, uniqueList} from "../../lib/utils";
import {RarChartSeriesConfiguration} from "../chart-config/chart-series.configuration";
import {CategoryChartTileConfiguration} from "../../forms/category-chart-form/category-chart-tile.configuration";
import {TileDataService} from '../../services/tile_data.service';
import {
    SeriesGetSeriesData
} from "../../_models/api/series-summary";
import {TimezoneSelectorService} from "../../services/timezone-selector.service";
import {SeriesSummarySeries} from "../../_models/api/series-summary";
import {RarRangeDict} from "../chart-config/chart-configuration";
import {moment} from "../../services/timezone-selector.service";
import {Moment} from 'moment-timezone';
import {KeyMap, ModelID} from '../../_typing/generic-types';
import {IDateTimePeriod, ISamplePeriod} from '../../_typing/date-time-period';
import {PlotlyRarChartConfigTranslationService} from "../../services/plotly-rar-chart-config-translation.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {DateTimePeriodService} from '../../services/date-time-period.service';

@Injectable()
export class RarChartService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    dtp: IDateTimePeriod;
    series_full: Series[];
    $rarConfigReadySubject: Subject<any> = new Subject();
    $rarConfigReady = this.$rarConfigReadySubject.asObservable();

    series_config_dict: KeyMap<RarChartSeriesConfiguration> = {};
    series: RarChartSeriesConfiguration;
    ranges: string[];
    target_ids: ModelID[];
    keys: string[];

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private api: ApiService,
                private seriesData: SeriesDataService,
                private tileDataService: TileDataService,
                private chartConfigService: ChartConfigTranslationService,
                private plotlyTranslate: PlotlyRarChartConfigTranslationService,
                private timezoneService: TimezoneSelectorService) {
    }

    getChart(tile_config: any) {
        const config = this.getAdjustedTileConfig(tile_config);
        this.getChartData(config);
    }

    getAdjustedTileConfig(tile_config) {
        let config = deepCopy(tile_config);
        let series_list = [];
        this.series = tile_config.series_list.filter(s => !(s as RarChartSeriesConfiguration).is_target)[0];
        this.ranges = tile_config.series_list.filter(s => !(s as RarChartSeriesConfiguration).is_target).map(g => g.group_name);
        const series_configs = tile_config.series_list.filter(s => !(s as RarChartSeriesConfiguration).is_target);
        series_configs.forEach(sc => {
            sc.key = sc.id + ':' + sc.group_name;
            series_list.push(sc);
        })
        tile_config.series_list.forEach(sc => {
            if (sc.is_target) {
                this.ranges.forEach(r => {
                    let target_config = deepCopy(sc);
                    target_config.range = r;
                    target_config.sample_period = this.dateTimePeriodService.sample_dict[RarRangeDict[r].sample_period];
                    target_config.number_of_periods = RarRangeDict[r].number_of_periods;
                    target_config.key = sc.id + ':' + r;
                    series_list.push(target_config);
                })
            }
            if (!sc.custom_legend_name) sc.custom_legend_name = upperfirst(sc.group_name);
        })
        config.series_list = series_list;
        this.keys = series_list.map(s => s.key);
        return config;
    }

    private getChartData(tile_config: any) {
        this.dtp = this.dateInst.dtp;
        let series_ids = tile_config.series_list.map(series => series.id);
        series_ids = uniqueList(series_ids);
        tile_config.series_list.forEach(c => this.series_config_dict[c.key] = c);
        this.target_ids = uniqueList(tile_config.series_list.filter(s => (s as RarChartSeriesConfiguration).is_target)?.map(t => t.id) || []);

        this.seriesData.getSeriesListByIds(series_ids)
            .pipe(tap(response => {
                    this.series_full = response.data;

                }),
                mergeMap(() => {
                    return this.getSeriesData(tile_config.series_list)
                        .pipe(map((series_data: SeriesGetSeriesData[]) => {
                            return this.mapSeriesSummaryToGroups(this.series_full, series_data)
                        }))
                })
            ).subscribe((group_dict) => {
            this.configureRarChartConfig(tile_config, group_dict)
        })
    }

    private getSeriesData(series_list: RarChartSeriesConfiguration[]): Observable<any> {
        const dtp = this.dateTimePeriodService.getDTP();
        let $gss_data = [];
        const groups = series_list.filter(s => !s.is_target);
        groups.forEach(config_series => {
            let wire_sample_period, start;
            wire_sample_period = config_series.sample_period.wire_sample;
            let no = config_series.number_of_periods;
            const group = config_series.group_name;

            const startHour = this.dateTimePeriodService.calendar_dict[dtp.calendar]?.default_start_hour || this.dateTimePeriodService.defaultStartHour;
            
            let end = moment.tz(this.dateInst.dtp.end, this.timezoneService.active_timezone).startOf('day').add(startHour, 'hours');
            
            if (config_series.group_name === 'the past three months') {
                // For custom calendars, we need to align with their month boundaries
                const calendar = this.dateTimePeriodService.calendar_dict[this.dateInst.dtp.calendar];
                if (calendar?.calendar_periods?.length) {
                    const yesterdayDate = moment.tz(end, this.timezoneService.active_timezone).subtract(24, 'hours');
                    
                    // Find the monthly period that contains yesterday
                    const currentPeriod = calendar.calendar_periods.find(p => {
                        // Only consider month periods
                        if (p.attributes.range !== 'month') return false;
                        
                        const periodStart = moment.tz(p.attributes.start, this.timezoneService.active_timezone);
                        const periodEnd = moment.tz(p.attributes.end, this.timezoneService.active_timezone);
                        return yesterdayDate.isBetween(periodStart, periodEnd, null, '[]');
                    });
                    
                    if (currentPeriod) {
                        // Find the previous month period (to exclude current month)
                        let previousPeriod = null;
                        // Keep looking back until we find a month period
                        let checkPeriod = currentPeriod;
                        while (checkPeriod.relationships.previous_time_period?.data?.id) {
                            const prevPeriod = calendar.calendar_periods.find(
                                p => p.id === checkPeriod.relationships.previous_time_period.data.id
                            );
                            if (prevPeriod.attributes.range === 'month') {
                                previousPeriod = prevPeriod;
                                break;
                            }
                            checkPeriod = prevPeriod;
                        }
                        
                        if (previousPeriod) {
                            // Set end to the end of the previous month period
                            end = moment.tz(previousPeriod.attributes.end, this.timezoneService.active_timezone)
                                .startOf('day').add(startHour, 'hours');
                            
                            // For start date, find two more month periods before that
                            let startPeriod = previousPeriod;
                            let monthsFound = 0;
                            while (startPeriod.relationships.previous_time_period?.data?.id && monthsFound < 2) {
                                const prevPeriod = calendar.calendar_periods.find(
                                    p => p.id === startPeriod.relationships.previous_time_period.data.id
                                );
                                if (prevPeriod.attributes.range === 'month') {
                                    startPeriod = prevPeriod;
                                    monthsFound++;
                                } else {
                                    startPeriod = prevPeriod;
                                }
                            }
                            
                            if (monthsFound === 2) {
                                // Override the normal start calculation to use the custom period start
                                start = moment.tz(startPeriod.attributes.start, this.timezoneService.active_timezone)
                                    .startOf('day').add(startHour, 'hours');
                            }
                        }
                    }
                } else {
                    // For regular calendar months, use yesterday as reference point
                    const yesterdayDate = moment.tz(end, this.timezoneService.active_timezone).subtract(24, 'hours');
                    
                    // Set end to the end of the PREVIOUS month (to exclude current month)
                    end = moment.tz(yesterdayDate, this.timezoneService.active_timezone)
                        .subtract(1, 'month')  // Go back one month first
                        .endOf("month")
                        .startOf('day')
                        .add(startHour, 'hours');
                    
                    // Calculate start by going back 2 more months from the end date
                    start = moment.tz(end, this.timezoneService.active_timezone)
                        .subtract(2, 'months')  // Go back 2 more months to get total 3 months
                        .startOf("month")
                        .startOf('day')
                        .add(startHour, 'hours');
                }
            }
            
            //Remove overlap of yesterday and yesterday from the past seven days
            if (['yesterday', 'the past seven days'].every(r => this.ranges.includes(r)) && config_series.group_name === 'the past seven days') {
                end = end.subtract(24, 'hours')
            }

            // Only calculate start using determineStartFromEndAndPeriod if we haven't already set it (for custom periods)
            if (!start) {
                // Calculate start date based on the end date we just determined
                let dtpWithEnd = deepCopy(dtp);
                dtpWithEnd.end = end.toDate();
                start = this.dateTimePeriodService.determineStartFromEndAndPeriod(dtpWithEnd,
                    config_series.sample_period, no).startOf('day').add(startHour, 'hours');
            }

            $gss_data.push(this.getSeriesDataParams(config_series, start, end, wire_sample_period, group));
            this.target_ids.forEach(t => {
                $gss_data.push(this.getSeriesDataParams(this.series_config_dict[t + ':' + group], start, end, wire_sample_period, group));
            })

        });
        return forkJoin($gss_data).pipe(takeUntil(this.onDestroy));
    }

    private getSeriesDataParams(config_series, start: Moment, end: Moment, sample_period: string, group_name: string): Observable<any> {
        const params = {
            series_list: [config_series.id],
            start: start.toDate().toISOString(),
            end: end.toDate().toISOString(),
            sample_period: sample_period ? sample_period : this.dateTimePeriodService.getDTP().sample_period.wire_sample,
            period_type: this.dateInst.dtp.calendar
        };
        // if month, perform an additional aggregation to get the mean for the month
        if (sample_period === 'month') {
            params['sample_period'] = 'day';
            params['additional_aggregation'] = JSON.stringify({
                sample_period: 'month',
                aggregation: 'mean'
            });
        }

        return this.api.get_series_data(params).pipe(map(result => {
            result.key = config_series.key;
            result.start = start.toDate().toISOString();
            result.end = end.toISOString();
            result.config = deepCopy(config_series);
            return result;
        }));
    }

    private mapSeriesSummaryToGroups(series_list: Series[], series_data: SeriesGetSeriesData[]): KeyMap<SeriesSummarySeries> {
        let series_dict = {};
        series_data.forEach(s => {
            const series = series_list.find(sl => sl.id === s.config.id);
            const name = series.attributes.name;
            s = Object.assign(s, series);
            const data = {data: s.data[name]};
            const missing_values = {missing_values: s.missing_values[name]};
            series_dict[s.key] = Object.assign(s, data, missing_values);
        })
        return series_dict;
    }

    private configureRarChartConfig(tile_config: CategoryChartTileConfiguration, group_dict) {
        const config = this.getRarChartConfig(tile_config, group_dict);
        this.$rarConfigReadySubject.next(config);
    }

    private getRarChartConfig(tile_config: GenericChartTileConfiguration, group_dict: KeyMap<SeriesGetSeriesData>) {
        const series_dict = this.getSeriesDict(group_dict);
        let chart_config: any = {
            key_values: this.keys,
            type: null,
            barmode: 'group',
            x_values: this.getRarTimeSeries(tile_config, group_dict),
            y_values: this.chartConfigService.getYValues(tile_config, group_dict, 'key'),
            labels: this.getLabels(tile_config),
            colours: this.chartConfigService.getSeriesColours(tile_config, 'key'),
            series_chart_types: this.chartConfigService.getSeriesChartTypes(tile_config, 'bar'),
            names: this.chartConfigService.getSeriesNames(tile_config, series_dict, 'key'),
            orientation: tile_config.orientation || 'v',
            styles: this.chartConfigService.getStyles(tile_config),
            text_values: this.chartConfigService.getTextValues(tile_config, group_dict, 'key'),
            text_orientation: this.chartConfigService.getTextOrientation(tile_config, 'key'),
            hidden: this.chartConfigService.getHidden(tile_config),
            no_ticks: tile_config.y_max_ticks_set ? tile_config.y_max_ticks : null,
            tick_space: tile_config.y_spacing_set ? tile_config.y_spacing : null,
            y_axis_format: this.chartConfigService.getYAxisFormat(tile_config),
            x_ticks: this.getXTicks(group_dict),
            range_names: this.ranges,
            target_annotations: {},
            line_styles: this.chartConfigService.getLineStyles(tile_config, 'key')
        }
        const range = this.getRange(tile_config, series_dict);
        chart_config.y_min = range.y_min;
        chart_config.y_max = range.y_max;
        this.combineTargets(chart_config);
        return this.chartConfigService.getConfiguredRarChart(tile_config.library, chart_config);
    }

    private combineTargets(chart_config) {
        const targetsToCombine = this.target_ids.filter(id => this.checkYValuesMatch(id, chart_config));
        targetsToCombine.forEach(tid => {
            Object.entries(chart_config).forEach(([key, value]) => {
                /**key_values, x_values, y_values, line_styles etc **/
                if (Array.isArray(value)) {
                    let modifiedArray = value.map(item => item.includes(tid) ? tid : item);
                    chart_config[key] = uniqueList(modifiedArray);
                } else if (value?.constructor == Object) {
                    let newValue = {};
                    let hasIdKey = false;

                    Object.keys(value).forEach(k => {
                        if (k.includes(tid)) {
                            if (Array.isArray(value[k])) {
                                if (!hasIdKey) {
                                    newValue[tid] = [].concat(value[k]);
                                    hasIdKey = true;
                                } else {
                                    newValue[tid] = newValue[tid].concat(value[k]);
                                }
                            } else {
                                newValue[tid] = value[k]; // Take the value from the first match since all the same
                            }
                        } else {
                            newValue[k] = value[k];
                        }
                    });
                    chart_config[key] = newValue;
                } else {
                    chart_config[key] = value;
                }
            })
        })
    }

    private getSeriesDict(group_dict: KeyMap<SeriesGetSeriesData>): KeyMap<SeriesGetSeriesData> {
        let series_dict = {};
        Object.values(group_dict).forEach(g => {
            series_dict[g.id] = g;
        })
        return series_dict;
    }

    private getXTicks(group_dict: KeyMap<SeriesGetSeriesData>): { values: string[]; text: string[] } {
        let x_ticks = {values: [], text: []};
        let text = [];

        function addText(len: number) {
            for (let i = 0; i <= len; i++) {
                text.push('');
            }
        }

        Object.values(group_dict).forEach(group => {
            if (!group.config.is_target) {
                Object.keys(group.data).forEach(time_stamp => {
                    const sample_period: ISamplePeriod = deepCopy(this.dateTimePeriodService.sample_dict[group.config.sample_period.name]);
                    x_ticks.values = x_ticks.values.concat(sample_period.format(new Date(time_stamp), this.dateTimePeriodService.getDTP()));

                    x_ticks.text = x_ticks.text.concat(sample_period.format(new Date(time_stamp), this.dateTimePeriodService.getDTP()));
                    // x_ticks.values = x_ticks.values.concat(item.end);
                })

                // const no = Math.floor(arr[0].config.number_of_periods / 2);
                // const range = arr[0].config.group_name;
                // console.log('no, arr length, index : ', no, arr.length, x_ticks.values.length - arr.length);
                // addText(arr.length);
                // text[no + (x_ticks.values.length - arr.length)] = range;
            }
        })
        // console.log('RarChartService - getXTicks: ', text);
        // x_ticks.text = text;//['Yesterday', '', '', '', '', 'Past 7 Days', '', '', '', 'Past 3 Months', '']
        return x_ticks;
    }

    private getRarTimeSeries(tile_config, series_dict) {
        let x = {};
        this.keys.forEach(group => {
            x[group] = Object.keys(series_dict[group].data).map(time_stamp => {
                const sample_period: ISamplePeriod = deepCopy(this.dateTimePeriodService.sample_dict[series_dict[group].config.sample_period.name]);
                return sample_period.format(new Date(time_stamp), this.dateTimePeriodService.getDTP());
            })
        });
        return x;
    }

    private getLabels(tile_config): Partial<IChartLabels> {
        let labels = tile_config.labels || {};
        if (!labels.y_axis) {
            labels.y_axis = this.series_full.find(s => s.id === this.series.id)?.attributes.engineering_unit_name || 'No unit';
        }
        return labels;
    }

    private getRange(tile_config: WaterfallChartTileConfiguration, series_dict): { y_max: number, y_min: number } {
        const y_values: number[] = Object.values(series_dict[this.series.id].data).map(n => Number(n));
        let max, min;
        if (!tile_config.y_max && !tile_config.y_min) {
            return {y_min: null, y_max: null};
        }
        return {
            y_min: tile_config.y_min || tile_config.y_min === 0 ? tile_config.y_min : Math.min(...y_values) > 0 ? 0 : Math.min(...y_values),
            y_max: tile_config.y_max || tile_config.y_max === 0 ? tile_config.y_max : Math.max(...y_values)
        };
    }

    private checkYValuesMatch(tid: ModelID, chart_config) {
        const target_values = Object.keys(chart_config.y_values).filter(k => k.includes(tid)).map(k => chart_config.y_values[k]);
        const y_values = [].concat.apply([], target_values);
        return new Set(y_values).size === y_values.length;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
