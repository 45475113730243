<div class="series-html-form">

  <ng-container *ngIf="!isLegacy">
    <series-report-builder *ngIf="allColumns?.columns?.length" [config]="config" (configChanged)="configUpdated($event)" [columns]="allColumns.columns"></series-report-builder>
  </ng-container>

  <ng-container *ngIf="isLegacy">
    <div class="form-section column space-between">
          <textarea [(ngModel)]="htmlString" (input)="htmlStringUpdated($event)"
                    rows="10" style="width:calc(100% - 20px);">
          </textarea>

    </div>

    <!--Easily insert a GSS series tag-->
    <div *ngIf="allColumns" class="row center">
      <div class="select-search" style="flex-grow:2;">
        <select-search-component [label]="'Select a series'"
                                 [value]="insertSeries"
                                 [api_model]="'series_light'"
                                 [classes]="'fill'"
                                 (selectionChange)="addToFormula($event)">
        </select-search-component>
      </div>
      <mat-form-field>
        <mat-label>Select a column</mat-label>
        <mat-select (selectionChange)="addToFormula()" [(value)]="insertColumn">
          <ng-container *ngFor="let column of allColumns.columns">
            <mat-option *ngIf="column.deprecated!==true"
                        [value]="column">
              {{column.title| upperfirst}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row center" style="margin-top:-20px;">
      <input #copy_string (click)="copyToClipboard(copy_string)" [(ngModel)]="gssInsert"
             style="width:100%;margin-right:4px;"/>
      <i (click)="copyToClipboard(copy_string)" class="fa fa-clipboard"
         style="position:relative;right:26px;" title="Copy to clipboard"></i>
    </div>
  </ng-container>
</div>
