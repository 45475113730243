import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {Group} from '../../_models/group';
import {SessionState} from '../../_models/session-state';
import {RelationshipUserSelectorService} from "./relationship-user-selector.service";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {takeUntil, tap} from "rxjs/operators";
import {BaseComponent} from "../../shared/base.component";
import {ModelRelationshipNameDict} from "../../services/api/filter_utils";
import {ModelID} from '../../_typing/generic-types';
import {SaveableBase, SaveService} from '../../services/save/save.service';
import {User} from "../../_models/users";

@Component({
    selector: 'relationship-user-selector',
    templateUrl: './relationship-user-selector.component.html',
    styleUrls: ['./relationship-user-selector.component.less'],
    providers: [RelationshipUserSelectorService],
    standalone: false
})
export class RelationshipUserSelectorComponent extends SaveableBase implements OnInit {
    @Input() model: Group | SessionState;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<User>;
    applyFilter = this.cps.applyFilter;
    columns = this.cps.columns;
    filter_string = '';
    selected_users: { id: ModelID }[];
    custom_selected_filters = [];
    reverse_custom_selected_filters = [];
    title = '';

    constructor(private cps: RelationshipUserSelectorService,
                private saveService: SaveService) {
        super();
        this.cps.dataSourceSubject.pipe(
            tap(ds => {
                this.dataSource = ds;
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.cps.selectedChangedSubject.pipe(
            tap(selected => {
                this.selected_users = selected || [];
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.save();
        });
        this.saveService.setCanSave(() => this.cps.hasChanges(this.selected_users));
    }

    ngOnInit(): void {
        if (this.model.type === 'session_state') {
            this.title = 'Users that can view this page';
        } else {
            this.title = 'Users in this group';
        }
        this.custom_selected_filters = this.cps.generateUsersByRelationshipFilter(this.model.id, ModelRelationshipNameDict.users[this.model.type]);
        this.reverse_custom_selected_filters = [{not: this.custom_selected_filters[0]}];
        setTimeout(() => {
            this.cps.sort = this.sort;
            this.cps.paginator = this.paginator;
            this.cps.initialise(this.model);
        })
    }

    save() {
        this.cps.save(this.selected_users, this.model);
    }

    usersSelected(event: User[]) {
        this.selected_users = event;
        this.cps.userListChanged(event);
        this.setCanSave(this.cps.hasChanges(this.selected_users));
    }

    updateSort(event: Sort): void {
        this.cps.updateSort(this.sort)
    }
}
