import {Component, OnInit} from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import * as utils from "../../lib/utils";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {take, tap} from "rxjs";

export interface RangeOption {
    range: string;
    period: string;
    text: string;
}

@Component({
    selector: 'range-selector',
    templateUrl: './range-selector.component.html',
    styleUrls: ['./range-selector.component.less'],
    standalone: false
})
export class RangeSelectorComponent implements OnInit {

    range_options: RangeOption[] = [{range: 'yesterday', period: 'hour', text: '1d'},
        {range: 'the past seven days', period: 'day', text: '7d'},
        {range: 'the past thirty days', period: 'day', text: '30d'},
        {range: 'this year', period: 'day', text: 'YTD'}];
    selected_range: string;
    selected_period: string;

    constructor(private headerData: HeaderDataService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit(): void {
        this.dateInst.dateTimePeriodChanged$.pipe(take(1), tap(() => {
            this.selected_range = this.dateInst.dtp?.range;
            this.selected_period = this.dateInst.dtp.sample_period.name;

            if (this.selected_range && this.selected_period && !this.selectedRangeOption()) {
                this.range_options.unshift({
                    range: this.selected_range,
                    period: this.selected_period,
                    text: 'TT'
                })
            }
        }))
    }

    selectedRangeOption(): RangeOption {
        return this.range_options.find(o => o.range === this.selected_range && o.period === this.selected_period);
    }

    isCurrent(option: RangeOption): boolean {
        if (this.selected_range === option.range && this.selected_period === option.period) {
            return true;
        }
        return false;
    }

    emitRangeChanged(option: RangeOption) {
        this.selected_range = option.range;
        this.selected_period = option.period;
        this.dateInst.dtp = this.dateTimePeriodService.getDTP(option.range, null, this.dateInst.dtp.calendar);
        this.dateInst.dtp.sample_period = this.dateTimePeriodService.sample_dict[option.period];
        this.dateInst.dateTimePeriodRefreshed$.next(this.dateInst.dtp);
    }
}
