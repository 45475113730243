<div class="component-form-form">

    <select-search-component
        [value]="selected_component_type"
        [api_model]="'component_type'"
        (selectionChange)="componentTypeChanged($event.value)"
        label="Select Component Type"
        [classes]="'fill'"
    class="select-search">
    </select-search-component>

  <div class="row center">
    <component-name-formula-selector [componentType]="config.selected_component_type"
                                     [(selectedNameFormula)]="config.selected_name_formula"
                                     class="row center" style="width:100%;">
    </component-name-formula-selector>
  </div>

  <ng-container *ngIf="config.selected_component_type">
    <!--Component Type Property Columns-------------------------------------------------------------------------------->
    <h3 style="margin-bottom:0;">Select the Component Type Property columns for your form. </h3>

    <event-column-selector
        [available_columns]="avail_cols"
        [selected_columns]="config.selected_cols || []"
        [model_dict]="cpDict"
        [colMenuOptions]="[]"
        [allowDefaults]="true"
        [disabledByDefault]="false"
        (columns_changed)="columnsChanged($event)"
        [panelClass]="'component-form-form'"
    ></event-column-selector>

    <div class="row" style="margin-top:20px;">
        <select-many-filter
            [label]="'Required fields'"
            (constant_changed)="updateRequired($event, 'event')"
            [filter_compare]="compareById"
            [modelValue]="requiredCols"
            [options]="config.selected_cols"
            [placeholder]="'Please select required columns'"
            [stringFunction]="columnNameFunction.bind(this)"
            [classes]="'fill'"
             class="select-search"
        >
        </select-many-filter>
        <select-many-filter
            [label]="'Non editable fields'"
            (constant_changed)="updateDisabled($event, 'event')"
            [filter_compare]="compareById"
            [modelValue]="disabledCols"
            [options]="config.selected_cols"
            [placeholder]="'Please select disabled columns'"
            [stringFunction]="columnNameFunction.bind(this)"
            [classes]="'fill'"
             class="select-search"
        >
        </select-many-filter>
    </div>
  </ng-container>
</div>
