<div class="form-dialog">
  <h1><span>Edit custom chart</span>
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <form class="form-section column space-between">

          <ng-container *ngIf="chart_config">
            <custom-chart-form [config]="chart_config"></custom-chart-form>
          </ng-container>
        </form>
        <!--        <comment-drawer></comment-drawer>-->
      </mat-tab>
    </mat-tab-group>
    <div class="align-bottom">
      <date-time-period [immutable]="true" [no_url]="true" class="date-period inline align-bottom"></date-time-period>
    </div>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save">Save</button>

  </div>
</div>
