<div class="custom-snackbar">
  <div class="message" [innerHTML]="message">
  </div>

  <div class="action-class">
    <div class="confirm-container">
      <button mat-raised-button (click)="snackBarRef.dismiss()" (escToAction)="snackBarRef.dismiss()">{{cancelText}}</button>
      <button class="action-button" mat-raised-button color="warn" (click)="snackBarRef.dismissWithAction()" (enterToAction)="snackBarRef.dismissWithAction()">{{okText}}</button>
    </div>
  </div>
</div>
