import {ConstantPropertyDataType} from "../constant-property";
import {KeyMap, ModelID} from "../../_typing/generic-types";

type AttributeDataType = 'string' | 'datetime';

export type ConstantValue = string | number | null;
export type AttributeValue = string | null;

export abstract class BaseGenericField {
    abstract type: string;
}

export abstract class BaseEditableField extends BaseGenericField {
    editable: boolean;
    locked: boolean;
    data_type: ConstantPropertyDataType;
    created_on?: string;
    changed_on?: string;
}

export class InvalidField extends BaseGenericField {
    type: 'invalid';
    data_type: 'invalid';
    locked?: boolean;
}

export class AttributeField extends BaseEditableField {
    type: 'attribute';
    data_type: AttributeDataType;
    value: AttributeValue;
}

export class ConstantField extends BaseEditableField {
    type: 'constant' = 'constant';
    data_type: ConstantPropertyDataType;
    value: ConstantValue;

    constructor() {
        super();
    }
}

export class FileField extends BaseEditableField {
    type: 'file' = 'file';
    filename?: string;
    mimetype?: string;
    is_set: boolean;

    constructor() {
        super();
    }
}

export type GenericField = AttributeField | ConstantField | InvalidField | FileField;

export function isConstant(constant: GenericField): constant is ConstantField {
    return (constant as ConstantField)?.type === 'constant';
}

export function isFile(constant: GenericField): constant is FileField {
    return (constant as FileField)?.type === 'file';
}

export function isInvalid(constant: GenericField): constant is InvalidField {
    return (constant as InvalidField)?.type === 'invalid';
}

export type GenericConstantData = KeyMap<KeyMap<GenericField>>;

export interface GenericConstantApiResponse {
    data?: GenericConstantData;
}


export class ComponentConstantField {
    constant_property_id: ModelID;
    component_id: ModelID;
    value: ConstantValue;

    constructor(component_id: ModelID, constant_property_id: ModelID, value: ConstantValue) {
        this.component_id = component_id;
        this.constant_property_id = constant_property_id;
        if (typeof value === 'string') {
            this.value = value.trim();
        } else {
            this.value = value;
        }
    }
}
