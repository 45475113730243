import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appFitContent]',
    standalone: false
})
export class FitContentDirective {
    element: HTMLElement
    @Input('appFitContent') value: any;
    @Input('allowFit') allow_fit: boolean;

    constructor(private _el: ElementRef,
                private renderer: Renderer2) {
        this.element = _el.nativeElement;
    }

    ngOnInit() {
        this.calculateWidth();
    }

    calculateWidth() {
        if (!this.allow_fit) return;
        const family = getComputedStyle(this.element).getPropertyValue("font-family");
        const size = getComputedStyle(this.element).getPropertyValue("font-size");
        const weight = getComputedStyle(this.element).getPropertyValue("font-weight");
        const transform = getComputedStyle(this.element).getPropertyValue("text-transform");
        const padding_left = parseInt(getComputedStyle(this.element).getPropertyValue("padding-left"));
        const padding_right = parseInt(getComputedStyle(this.element).getPropertyValue("padding-right"));
        const padding_safety = 4;

        const measure = this.renderer.createElement('div');
        this.renderer.setStyle(measure, 'display', 'inline-block');
        this.renderer.setStyle(measure, 'font-family', family);
        this.renderer.setStyle(measure, 'font-size', size);
        this.renderer.setStyle(measure, 'font-weight', weight);
        this.renderer.setStyle(measure, 'text-transform', transform);
        this.renderer.setStyle(measure, 'visibility', 'hidden');
        this.renderer.setProperty(measure, 'textContent', this.value)
        this.renderer.appendChild(document.body, measure);
        const width = measure.clientWidth + padding_left + padding_right + padding_safety;
        this.renderer.setStyle(this.element, 'min-width', width + 'px');
        this.renderer.removeChild(document.body, measure);
    }
}
