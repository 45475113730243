import {BaseViewModel} from "./base-viewmodel";
import {ProcessViewModel} from "./process-viewmodel";
import {NameOrDescriptionPipe} from "../../../shared/pipes";

export class SeriesSummaryViewModel extends BaseViewModel {
    parent_component: ProcessViewModel;
    index: number = 0;
    //title: string;
    config: any;
    refresh = 0;

    constructor(seriesSummaryData, process, index) {
        super(seriesSummaryData)
        //TODO remove one
        this.parent_component = process;
        this.chart_parent_process = process;
        this.data.type = 'series-summary';
        this.type = 'series-summary';
        this.index = index;
        //this.title = this.data.title;
        this.config = this.data.config;
        this.data.refresh = 0;

        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }
        if (!this.data['height']) {
            if (this.data.config.value_only === true) {
                this.data['height'] = 100;
            } else {
                this.data['height'] = 160;
            }
        }
        if (!this.data['width']) {
            if (this.data.config.value_only === true) {
                this.data['width'] = 200;
            } else {
                this.data['width'] = 260;
            }
        }
    }

    title() {
        if (!this.data.title) {
            return new NameOrDescriptionPipe().transform(this.config.selected_series.attributes);
        }
        return this.data.title;
    }

    do_refresh() {
        return this.data.refresh;
    }

    height() {
        return this.data.height;
    };

    width() {
        return this.data.width;
    };

    x() {
        return this.data.x;
    };

    y() {
        return this.data.y;
    };

}
