import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AccountService} from "../../services/account.service";
import {Observable, Subject} from "rxjs";
import {catchError, concatMap, first, map, takeUntil, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {partition as _partition} from "lodash-es";
import {Account} from "../../_models/account";
import {AppScope} from '../../services/app_scope.service';
import {NotificationService} from "../../services/notification.service";
import {FormControl} from "@angular/forms";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     MatSelectModule,
    //     MatTooltipModule,
    //     ReactiveFormsModule,
    // ],
    // providers: [
    //     NotificationService
    // ],
    selector: 'account-picker',
    templateUrl: './account-picker.component.html',
    styleUrls: ['./account-picker.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AccountPickerComponent implements OnInit, OnDestroy {
    public accounts$: Observable<Account[]>;
    public selected_account_id: FormControl = new FormControl<string>('');
    placeholder: string = 'Loading accounts...';
    private onDestroy: Subject<void> = new Subject();

    constructor(private accountPickerService: AccountService,
                private notification: NotificationService,
                private router: Router,
                private appScope: AppScope) {
    }

    ngOnInit() {
        this.accounts$ = this.accountPickerService.accountsChanged
            .pipe(map((response: Account[]) => {
                this.placeholder = 'Select an account';
                const account_parts = _partition(response, a => !a.view_only);
                return account_parts[0].concat(account_parts[1]);
            }));
        this.accountPickerService.activeAccountChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this.selected_account_id.setValue(response.account_id);
            });
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

    changeAccount(account_id: string) {
        this.accountPickerService.setActiveAccount(account_id).pipe(
            first(),
            concatMap(response => {
                this.notification.openSuccess(response.message, 5000);
                // This link the changing of active account to also switch to the default dashboard. We might want to
                // decouple this in the future to be two separate user actions.
                return this.accountPickerService.getDefaultUserSessionState(account_id, this.appScope.current_user.id)
                    .pipe(first(), tap((defaultUserSessionStateResponse: string) => {
                        this.accountPickerService.default_url = !!defaultUserSessionStateResponse ?
                            `view/page_view/${defaultUserSessionStateResponse}` : this.accountPickerService.default_url;

                        this.router.navigateByUrl(this.accountPickerService.default_url);
                    }));

            }), catchError(error => {
                if (error && error.error && error.error.message) {
                    this.notification.openError(error.error.message);
                } else {
                    console.log('Error selecting account', error);
                    this.notification.openError('Error selecting account');
                }
                return error;
            }), takeUntil(this.onDestroy)).subscribe();
    }
}
