import * as Handsontable from "handsontable";
import {contextMenu} from "handsontable";
import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {NotificationService} from "../services/notification.service";
import {SeriesDataService} from "../services/series_data.service";
import {PlantDataService} from "../services/plant-data/plant_data.service";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {HeaderDataService} from "../services/header_data.service";
import {deepCopy, dayDifference} from '../lib/utils';
import {combineLatest, Subject, Subscription} from "rxjs";
import {TileDataService} from "../services/tile_data.service";
import {UserData} from "../services/user_data.service";
import {AppScope} from "../services/app_scope.service";
import {InputDataService} from "../services/input-data.service";
import {takeUntil, tap} from "rxjs/operators";
import {FlowchartData} from "../_models/flowchart-data";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {moment} from "../services/timezone-selector.service";
import {DateTimeInstanceService} from "../services/date-time-instance.service";
import {SeriesService} from "../services/models/series.service";
import {ITileButton} from "../_typing/tile-button";
import {FontAwesomeIcons} from "../shared/globals";

/**
 * Renderer for value cells in the handsontable.
 * @param instance Reference to the Handsontable instance
 * @param td
 * @param row Row number
 * @param col Column number
 * @param prop <timestamp number>.attributes.value
 * @param value Value of this cell
 * @param cellProperties
 */
function cellRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);

    const logical_id = instance.getDataAtRowProp(row, 'id');
    const time = prop.split('.')[0];
    const series = this.inputDataService.series_list.filter(item => item.id === logical_id)[0];
    const cell_data = series[time];
    if (cell_data !== undefined && series[time].is_missing) {
        td.style.background = '#f5f5f5';
    }
}

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Component({
    selector: 'input-data-sheet',
    templateUrl: 'input-data-sheet.component.html',
    providers: [InputDataService],
    host: {
        '(window:resize)': 'calculateSize($event)', // This gets destroyed when component using this is destroyed
    },
    standalone: false
})
export class InputDataSheetComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private pageRefreshSubscription: Subscription;

    sample_starts: { "day": any[]; "shift": any[]; "hour": any[]; "two_hour": any[]; };
    @ViewChild('input_sheet') input_sheet: ElementRef;

    private _config: { process: any };
    buttons: ITileButton[];
    private permissions: any | {};

    @Input()
    set config(config: any) {
        this._config = config;
    }

    get config() {
        return this._config;
    }

    super_user: any;

    inputSheetSettings: Handsontable.DefaultSettings;
    hotInstance: Handsontable;
    hot_ready: boolean;
    save_allowed: boolean;
    columns: any[];
    window_height: number;

    dtp: IDateTimePeriod;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private notification: NotificationService,
                private seriesService: SeriesService,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private tileData: TileDataService,
                private userData: UserData,
                private appScope: AppScope,
                private inputDataService: InputDataService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
    ) {
    }

    ngOnInit(): void {
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
            this.dtp = dtp;
            this.refreshTable(dtp);
            this.setButtons();
        });

        this.sample_starts = {
            "day": [],
            "shift": [],
            "hour": [],
            "two_hour": []
        };

        this.super_user = this.userData.checkSuperUser(this.appScope.current_user);

        const process = this.config.process;
        const $isoPermissions = this.plantData.getProcessPermissions(process.id).pipe(tap(response => {
        }));
        const $flowsheet = this.plantData.getFlowchartDataForProcess(process.id);
        combineLatest([this.dateTimePeriodService.dtpInitialisedPromise.promise, $isoPermissions, $flowsheet]).pipe(takeUntil(this.onDestroy)).subscribe(data => {
            const process_permissions = data[1];
            this.permissions = process_permissions.permissions;
            const access = process_permissions.access;
            const flowsheet: FlowchartData = data[2];
            this.dtp = this.dateInst.dtp;
            this.inputDataService.initialize(process, access, flowsheet);
            this.refreshTable(this.dtp);

            this.setButtons();
        });
        this.tileData.buttons$.pipe(
            takeUntil(this.onDestroy),
            tap((buttons: ITileButton[]) => {
                this.buttons = buttons;
            })).subscribe();

        this.window_height = this.get_height();

        // Setting default title for tile
        this.tileData.setDefaultTitle(this.config.process.attributes.name);
    }

    ngOnDestroy(): void {
        if (this.hotInstance) {
            this.hotInstance.destroy();
            this.hotInstance = null;
        }

        if (this.pageRefreshSubscription) {
            this.pageRefreshSubscription.unsubscribe();
        }
        if (this.onDestroy) {
            this.onDestroy.unsubscribe();
        }
    }

    audit = (): void => {
        const adminPath: string = '/admin/rawdataaudit';
        window.open(adminPath);
    }

    setButtons(): void {
        this.buttons = [
            this.tileData.createButton('Raw Audit Data', this.audit, FontAwesomeIcons.User, 'Raw audit data'),
            this.tileData.createButton('Save', this.saveAndUpdate, FontAwesomeIcons.FloppyO, 'Save'),
        ];
        // remove clear all button for monthly calendar selection
        if (!this.dtp.range.toLowerCase().includes('month')) {
            const clearAllButton: ITileButton = this.tileData.createButton('Clear All', this.clearAllProcessData, FontAwesomeIcons.Trash, 'Clear All Data');
            if (!this.buttons.some((button: ITileButton): boolean => button.name === 'Clear All')) {
                this.buttons.push(clearAllButton);
            }
        } else {
            this.buttons = this.buttons.filter((button: ITileButton): boolean => button.name !== 'Clear All');
        }

        if (this.permissions.override_calculations) {
            this.buttons.unshift(
                this.tileData.createButton('Update Calculations', this.overrideCalculations, FontAwesomeIcons.Calculator, 'Update calculations')
            );
        }

        if (this.permissions.apply_correction_factor) {
            this.buttons.unshift(
                this.tileData.createButton('Apply correction factor', this.correctionFactor, FontAwesomeIcons.Eraser, 'Apply correction factor')
            );
        }
        this.tileData.updateButtons(this.buttons);
    }

    calculateSize(event) {
        if (this.hot_ready) {
            this.window_height = this.get_height();
            this.hotInstance.render();
            this.hotInstance.render();
        }
    }

    get_height() {
        const container = document.getElementById("input_sheet_div");
        if (!container?.offsetHeight) {
            return window.innerHeight - 350;
        } else {
            return container.offsetHeight;
        }
    }

    overrideCalculations = () => {
        this.getCalculations(null);
    }

    getCalculations(override) {
        let series_list = this.inputDataService.series_list.map(series => series.id);
        this.headerData.getCalculations(this.dtp, series_list, 'hour', 1).then(data => {
            // TODO this could trigger an inconsistent state if the sample period has changed
            this.inputDataService.getInputData(this.dtp);
        }).catch();
    }

    setUpInput() {
        this.save_allowed = this.saveAllowed();

        const schema = deepCopy(this.seriesData.schema);

        schema.attributes.can_edit = null;
        this.columns = [{
            title: 'Description',
            data: 'attributes.description',
            readOnly: true,
            className: "htLeft",
            width: 200,
        }, {
            title: 'Name',
            data: 'attributes.name',
            readOnly: true,
            className: "htLeft",
            width: 200,
        }, {
            title: 'Component',
            data: 'attributes.parent_name',
            readOnly: true,
            className: "htLeft",
            width: 150

        }, {
            title: 'Group',
            data: 'attributes.report_group',
            readOnly: true,
            className: "htLeft",
            width: 150,
        }, {
            title: 'Type',
            data: 'attributes.base_type',
            readOnly: true,
            className: "htLeft",
            width: 100,
        }, {
            title: 'Sample Period',
            data: 'attributes.sample_period',
            readOnly: true,
            className: "htLeft",
            width: 100,
        }
        ];

        const shifts = this.inputDataService.shifts.sort((a, b) => {
            return new Date(a.attributes.start).getTime() - new Date(b.attributes.start).getTime();
        });

        let day = deepCopy(this.dtp.start);

        while (day < this.dtp.end) {
            this.sample_starts.day.push(day);
            day.setHours(0);
            const newDate = new Date(deepCopy(day));
            newDate.setDate(newDate.getDate() + 1);
            day = newDate;
        }

        let date_counter = new Date(this.dtp.start);

        const timezone = this.dateTimePeriodService.selected_timezone;

        if (this.dtp.sample_period.name === 'month') {
            if (this.dtp.calendar && this.dtp.calendar !== 'default') {
                date_counter = this.dateTimePeriodService.getNextMonth(date_counter, this.dtp);
            } else {
                date_counter = this.dateTimePeriodService.addMonth(date_counter);
            }
        } else if (this.dtp.sample_period.name === 'week' && this.dtp.calendar !== 'default') {
            date_counter = this.dateTimePeriodService.getNextWeek(date_counter, this.dtp);
        } else {
            date_counter['addHours'](this.dtp.sample_period.hours);
        }
        let hour_counter = 1;

        let prev_day = null;
        let new_day = null;
        let shift_idx = 0;

        while (date_counter <= this.dtp.end) {

            this.sample_starts.hour.push(new Date(date_counter));

            if (date_counter.getHours() % 2 === 0 || date_counter.getHours() === 0) {
                this.sample_starts.two_hour.push(new Date(date_counter));
            }

            this.columns.push({
                title: this.dtp.sample_period.format(date_counter, this.dtp),
                data: date_counter.getTime() + ".attributes.value",
                type: 'numeric',
                width: 60,
                renderer: cellRenderer.bind(this),
                format: '0.00',
                // readOnly: true,
                className: "htRight"
            });
            const dateCounter: number = date_counter.getTime();
            this.inputDataService.dateCounterKey.push(dateCounter);
            schema[dateCounter] = {
                type: 'raw_data',
                attributes: {
                    value: null,
                    time_stamp: null,
                },
                relationships: {
                    series: {
                        data: {
                            id: null,
                            type: 'series'
                        }
                    }
                }
            };

            prev_day = deepCopy(date_counter);

            if (this.dtp.sample_period.name !== 'month') {
                date_counter['addHours'](this.dtp.sample_period.hours);
            }
            new_day = deepCopy(date_counter);
            if (this.dtp.sample_period.name === 'month') {
                date_counter = moment.tz(date_counter, timezone).add(1, 'month').toDate();
            } else if (prev_day.getDate() !== new_day.getDate()) {
                hour_counter = 0;
            }

            if (shifts[shift_idx] !== undefined) {
                let shift_end = new Date(shifts[shift_idx].attributes.end);
                let shift_start = new Date(shifts[shift_idx].attributes.start);

                if (shift_start < new Date(this.dtp.start)) {
                    shift_start = new Date(this.dtp.start)
                }

                if (shift_end > new Date(this.dtp.end)) {
                    shift_end = new Date(this.dtp.end)['addHours'](this.dtp.sample_period.hours)
                }
                if (shift_end <= date_counter) {

                    this.sample_starts.shift.push(new Date(shifts[shift_idx].attributes.start));

                    shift_idx++;
                }
            }
            hour_counter++;
        }

        const total_cols = 5 + ((new Date(this.dtp.end).getTime() - new Date(this.dtp.start).getTime()) / 3600000);

        this.inputSheetSettings = {
            columns: this.columns,
            minSpareRows: 0,
            sortIndicator: true,
            columnSorting: true,
            fixedColumnsLeft: 0,
            selectionMode: 'range',
            selectState: true,
            renderAllRows: false,
            contextMenu: {
                callback: (key: string, options: contextMenu.Options) => {
                    if (!['edit_series', 'clear_data'].includes(key)) {
                        return;
                    }
                    const hot = this.hotInstance;
                    const visualRow = options[0].start.row;
                    const logical_id = hot.getDataAtRowProp(visualRow, 'id');
                    const series = this.inputDataService.series_list.find(item => item.id === logical_id);
                    key === 'edit_series' ? this.editSeries(series) : this.clearRowSeriesData(series);
                }, items: {
                    "edit_series": {
                        name: 'Edit Series'
                    },
                    "clear_data": {
                        name: 'Clear Row Data'
                    }
                }
            }, afterChange: (changes: [number, string | number, any, any][], source: string) => {
                if (changes) {
                    if (Object.prototype.toString.call(changes[0]) !== '[object Array]') {
                        // @ts-ignore
                        changes = [changes]
                    }
                    changes.forEach(change => {
                        // row, prop, oldVal, newVal
                        if (!this.hot_ready) {
                            return;
                        }
                        const row = change[0];
                        const timestamp = change[1];
                        const value = change[3];
                        const data_row = this.inputDataService.series_name_map[this.hotInstance.getDataAtRowProp(row, "attributes.name")];
                        this.inputDataService.populate_changes(data_row, timestamp, value, this.dtp);
                    });

                }
            },
            data: this.seriesService.sortBySeriesOrder(this.inputDataService.series_data),
            dataSchema: schema,
            dropdownMenu: true,
            maxRows: this.inputDataService.series_data.length,
            viewportColumnRenderingOffset: total_cols,
            // @ts-ignore
            rowHeaders: index => {
                const instance = this.hotInstance;

                if (instance == null) {
                    return index;
                } else {
                    const description = instance.getDataAtRowProp(index, 'attributes.name');
                    if (description) {
                        return description;
                    } else {
                        return instance.getDataAtRowProp(index, 'attributes.name')
                    }
                }
            },
            rowHeaderWidth: 180,
            filters: true,
            cells: (row, col, prop) => {
                if (!this.hotInstance) {
                    return null;
                }
                let cellProperties = {readOnly: false};
                let propstring;
                if (typeof prop === 'string') {
                    propstring = prop;
                } else {
                    // @ts-ignore
                    propstring = new URLSearchParams(prop).toString();
                }
                if (!["attributes.name",
                    "attributes.description",
                    "attributes.parent_name",
                    "attributes.report_group",
                    "attributes.base_type",
                    "attributes.sample_period"].includes(propstring)) {

                    // var row_series = this.hotInput.getDataAtRowProp(row,attributes.name );
                    const visualRowIndex = this.hotInstance.toVisualRow(row);

                    const row_series = this.inputDataService.series_name_map[this.hotInstance.getDataAtRowProp(visualRowIndex, "attributes.name")];
                    // const row_series = this.series_name_map[this.hotInput.getDataAtRowProp(row, "attributes.name")];
                    if (row_series != null) {
                        cellProperties.readOnly = false;
                    }

                } else {
                    cellProperties.readOnly = true;
                }

                return cellProperties;

            }
        };

        if (this.hotInstance) {
            this.hotInstance.updateSettings(this.inputSheetSettings, false);
        } else {
            this.hotInstance = new Handsontable(this.input_sheet.nativeElement, this.inputSheetSettings);
        }
        this.inputDataService.updates = {};
        this.inputDataService.inserts = {};
        this.inputDataService.deletes = {};

        this.hot_ready = true;
    }

    clearAllProcessData: () => void = (): void => {
        this.inputDataService.series_data.forEach((series: any): void => {
            this.inputDataService.dateCounterKey.forEach((key: number): void => {
                series[key.toString()].attributes.value = 0;
            });
        });
    }

    clearRowSeriesData(series: any): void {
        if (this.dtp.range.toLowerCase().includes('month')) {
            const startTime: number = this.dtp.start.getTime();
            const endTime: number = this.dtp.end.getTime();
            this.inputDataService.clearHourlyRawData(series, startTime, endTime);
        }
        this.inputDataService.dateCounterKey.forEach((key: number): void => {
            series[key.toString()].attributes.value = 0;
        });

        const targetSeries: any = this.inputDataService.series_data.find(item => item.id === series.id);
        if (targetSeries) {
            Object.assign(targetSeries, series);
        }
    }

    editSeries(series) {
        let $series_full = this.api.series.getById(series.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;

            const dialogRef = this.seriesData.upsertSeries(this.inputDataService.process, series_full);
            dialogRef.afterClosed().pipe(tap(response => {
                if (response) {
                    let updated_series;
                    if (response.series) {
                        updated_series = response.series;
                    } else {
                        updated_series = response;
                    }
                    Object.keys(updated_series.attributes).forEach(attr => {
                        series.attributes[attr] = updated_series.attributes[attr];
                    });
                    series.relationships = updated_series.relationships;
                }
            })).subscribe();
        });
    }

    saveAndUpdate = (): void => {
        this.hot_ready = false;
        this.inputDataService.saveAll().subscribe({
            next: (amount) => {
                if (amount > 0) {
                    this.hot_ready = true;
                    this.notification.openSuccess('All saved (' + amount + ' changes)', 2000);
                } else {
                    this.notification.openError('No values changed', 2000);
                }
            }, error: reason => this.notification.openInfo(reason)
        });
    }

    correctionFactor = (): void => {
        const dialogRef = this.headerData.correctionFactor();
        dialogRef.afterClosed().subscribe(response => {
                this.inputDataService.getInputData(this.dtp);
            },
        );
    }

    isToday(date) {
        const today = new Date();

        if (typeof date === "string") {
            date = new Date(date);
        }

        return date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate();
    }

    saveAllowed(): boolean {
        if (this.dtp.sample_period.hours > 1) {
            return false;
        }

        if (this.super_user) {
            return true;
        }

        if (this.permissions.edit_process_data) {
            return true;
        }

        if (this.permissions.edit_todays_data) {
            return this.isToday(this.dtp.start) && this.isToday(this.dtp.end);
        }

        return false;
    }

    buildHeader() {
        this.headerData.add_upload = true;
        this.headerData.component_buttons_loaded = true;
    }

    private refreshTable(dtp: IDateTimePeriod): void {
        if (this.pageRefreshSubscription) {
            this.pageRefreshSubscription.unsubscribe();
            this.pageRefreshSubscription = null;
        }

        this.hot_ready = false;

        if (dayDifference(dtp.end, dtp.start) > 5 &&
            dtp.sample_period.hours < 4) {
            if (!confirm("This could take a long time consider increasing the sample period")) {
                return;
            }
        }
        this.pageRefreshSubscription = this.inputDataService.refresh(this.dtp).subscribe(() => {
            this.setUpInput();
        });
    }
}
