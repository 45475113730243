<div [ngClass]="panelClass">
  <constant-field *ngIf="field?.type==='constant_property' && allowValueEntry"
                  [constant_value]="field.default_value"
                  [constant_property]="constantProperty"
                  (constant_changed)="field.default_value = $event;"
                  (change_complete)="field.default_value = $event;"
                  [auto_class]="panelClass"
                  [placeholder]="placeholder"
                  class="constant-field"
  ></constant-field>
  <div *ngIf="field?.type==='constant_property' && !allowValueEntry"
        class="placeholder">{{placeholder}}</div>
</div>
