import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface GenerateTableParseResponse {
    columns: string[];
    errors: { error_message: string, error_code: number, row_data: string[], row_index: number }[];
    warnings: [{ warning_messages: { message: string, code: number }[], row_data: string[], row_index: number }];
    parsed_count: { success: number, warning: number, error: number };
}

export interface GenerateTableParseResponseDialog {
    parse_response: GenerateTableParseResponse;
}

@Component({
    selector: 'src-generate-table-parse-response-dialog',
    templateUrl: './generate-table-parse-response-dialog.component.html',
    styleUrls: ['./generate-table-parse-response-dialog.component.less'],
    encapsulation: ViewEncapsulation.None, // Global Styles
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GenerateTableParseResponseDialogComponent implements OnInit {

    parse_response: GenerateTableParseResponse;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<GenerateTableParseResponseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GenerateTableParseResponseDialog) {
        this.parse_response = data.parse_response;
    }

    ngOnInit(): void {
        this.changeDetectorRef.markForCheck();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
