import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AccountService} from "../../services/account.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {partition as _partition} from "lodash-es";
import {Account} from "../../_models/account";
import {ModelID} from "../../_typing/generic-types";

@Component({
    selector: 'account-filter',
    templateUrl: './account-filter.component.html',
    styleUrls: ['../account-picker/account-picker.component.less', './account-filter.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AccountFilterComponent implements OnInit, OnDestroy {
    public accounts: Account[] = [];
    public selectedAccountIds: string | string[];
    placeholder: string = 'Loading accounts...';
    private _activeAccountId: ModelID;
    @Input() allow_blank: boolean = false;
    @Input() label: string;
    @Input() multiple: boolean = false;
    private _initialAccountIds: ModelID[];
    @Input() set initialAccountIds(initialAccountIds: ModelID[]) {
        this._initialAccountIds = initialAccountIds;
        this._setInitialAccountIds();
    };

    get initialAccountIds(): ModelID[] {
        return this._initialAccountIds;
    }

    @Input() allowedAccountIds: ModelID[];
    @Input() restrictManageUsers: boolean = false;
    @Output() accountFilterEmitter: EventEmitter<any> = new EventEmitter();
    private onDestroy: Subject<void> = new Subject();

    constructor(private accountService: AccountService) {
    }

    ngOnInit() {
        this.accountService.accountsChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this.placeholder = 'Filter by account';
                let accounts = response;
                const account_parts = _partition(accounts, a => !a.view_only);
                accounts = account_parts[0].concat(account_parts[1]);
                if (this.restrictManageUsers) {
                    this._setAccountEditingStatus(accounts);
                } else {
                    this.accounts = accounts;
                }
            });

        this.accountService.activeAccountChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this._activeAccountId = response.account_id;
                this._setInitialAccountIds();

                if (this.allow_blank === true) {
                    this.selectedAccountIds = null;
                }
            });
    }

    private _setInitialAccountIds() {
        if (this._activeAccountId) {
            this.selectedAccountIds = this.multiple ? this.initialAccountIds ? this.initialAccountIds : [this._activeAccountId] : this._activeAccountId;
        } else if (this.multiple) {
            this.selectedAccountIds = this.initialAccountIds;
        }
    }

    private _setAccountEditingStatus(accounts: Account[]) {
        this.accounts = this.accountService.getAccountsStatus(accounts);
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

    changeAccount(account_id: string | string[]) {
        const ctrl = this;
        if (this.multiple) {
            ctrl.accountFilterEmitter.emit(ctrl.accounts.filter(acc => account_id.includes(acc.id)));
        } else {
            ctrl.accountFilterEmitter.emit(ctrl.accounts.find(acc => acc.id === account_id));
        }
    }
}
