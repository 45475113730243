import {Component, OnInit} from '@angular/core';
import {EMPTY, Observable, Subject} from "rxjs";
import {HeaderDataService} from "../../services/header_data.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ApiService} from "../../services/api/api.service";
import {SeriesDataService} from "../../services/series_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {take, takeUntil, tap} from "rxjs/operators";
import {Series} from "../../_models/series";
import {SingleResponse} from '../../services/api/response-types';
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'analysis-tools-view',
    templateUrl: './analysis-tools-view.component.html',
    styleUrls: ['./analysis-tools-view.component.less'],
    standalone: false
})
export class AnalysisToolsViewComponent implements OnInit {
    selected_series: Series;
    estimate_type_id: string;

    private readonly onDestroy = new Subject<void>();

    constructor(private headerData: HeaderDataService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private api: ApiService,
                private seriesData: SeriesDataService,
                public dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dateInst.dtp = this.dateTimePeriodService.getDTP('this month', false);
        })
    }

    ngOnInit(): void {
        const ctrl = this;
        this.headerData.show_dtp = true;
        this.headerData.title = 'Series Analysis Tools';
        const queryParams = this.activatedRoute.snapshot.queryParams;

        if (queryParams.estimate) {
            this.estimate_type_id = queryParams.estimate;
        }
        let $series: Observable<SingleResponse<Series>> | never = EMPTY;
        if (queryParams['series']) {
            $series = ctrl.api.series_light.getById(queryParams['series']).pipe(tap((result: SingleResponse<Series>) => {
                this.selected_series = result.data;
            }));
        }
        $series.pipe(take(1), takeUntil(this.onDestroy)).subscribe();
    }

    updateURL(estimate_id: string = null) {
        const ctrl = this;
        let queryParams: Params = {
            series: ctrl.selected_series.id
        };
        if (estimate_id) {
            queryParams = {
                estimate: estimate_id
            }
        }

        //This updates the url without navigation (by using current activatedRoute)
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // prevents replacing other params (dtp)
                //replaceUrl: true //can be used to replace this route event in browser history rather than adding to history
            });
    }
}
