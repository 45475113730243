import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ComponentPanelStackConfig} from './component-panel-stack.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppScope} from '../../services/app_scope.service';
import {WireComponent} from '../../_models/component';
import {TimezoneSelectorService} from "../../services/timezone-selector.service";

/**Handles panel array and navigation for component side panel**/
@Injectable({
    providedIn: 'root'
})
export class ComponentPanelStackService {
    private panels: ComponentPanelStackConfig[] = [];
    public panelStackUpdated = new Subject<any[]>();
    parent_url: string;

    constructor(private router: Router, private route: ActivatedRoute, private location: Location,
                private timezoneService: TimezoneSelectorService, private appScope: AppScope) {
        this.timezoneService.timezoneClockMin.next(true);
    }

    public loadRelatedComponent(component: WireComponent, panel: ComponentPanelStackConfig, replace_url = false) {
        // if(replace_url){
        //     this.pop();
        //     panel.data.previous_component = null;
        // }
        this.router.navigate([this.parent_url, {outlets: {component_panel: ['component-detail', component.id, panel.data.tile_id]}}],
            {replaceUrl: replace_url, relativeTo: this.route});
    }

    public push(panel: any): void {
        this.panels.push(panel);
        this.panelStackUpdated.next(this.panels);
    }

    public back() {
        if (this.panels.length === 1) {
            this.clear();
        } else {
            this.pop();
            if (this.appScope.previous_url) {
                this.location.replaceState(this.appScope.previous_url);
            }
        }
    }

    private pop(): any {
        const poppedPanel = this.panels.pop();
        this.panelStackUpdated.next(this.panels);
        return poppedPanel;
    }

    public clear(): any {
        this.panels = [];
        this.router.navigate([this.parent_url], {queryParams: this.route.snapshot.queryParams});
    }

    public getPanels(): any[] {
        return this.panels;
    }
}
