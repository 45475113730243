<div class="constant-property-table-form form-paginator">
  <div class="row center">
    <div class="select-search" style="flex-grow:2;">
      <select-many-search-component
          [value]="solverTemplate.relationships.constraints.data"
          (selectionChange)="updateConstraints($event)"
          [classes]="'fill'"
          [api_model]="'constraint'"
          [label]="'Constraints'">
      </select-many-search-component>
    </div>

    <button (click)="newConstraint()" class="bordered-button" mat-button
            style="width:160px;padding-left:10px;"><i class="fa fa-plus" style="margin-right:10px;"></i>Constraint
    </button>
  </div>

  <div class="simple-table-container">
    <div #utils_bar class="row utils-bar">
      <div class="row start table-filter">
        <form (submit)="updateSearchFilter()" class="row">
          <div class="row" style="position:relative;">
            <input [(ngModel)]="filterString" placeholder="Search..." name="search"/>
            <i class="fa fa-times" matTooltip="Clear database search"
               (click)="filterString='';updateSearchFilter()"
            ></i>
          </div>
          <button [disabled]="!filterString" type="search" mat-button class="bordered-button"
                  style="height:30px;margin:0 20px 0 4px;min-width:30px;">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div>
      <mat-paginator [pageSizeOptions]="[5,10,15,20]"
                     showFirstLastButtons
                     style="justify-self: flex-end;">
      </mat-paginator>
    </div>
    <div class="table-container">
      <table class="table constant-properties-table" id="constraints-table"
             mat-table matSort multiTemplateDataRows
             (matSortChange)="updateSort()"
             [dataSource]="dataSource" style="padding-bottom:150px;">
        <ng-container>
          <ng-container matColumnDef="edit">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let constraint" class="icon-cell" mat-cell>
              <i (click)="toggleEdit(constraint)" class="fa fa-edit hideonprint"></i>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>Name</th>
            <td *matCellDef="let constraint" mat-cell>
              <input [(ngModel)]="constraint.attributes.name"
                     [disabled]="!(constraint === currentConstraint)"
                     matInput
                     style="text-align: left;"/>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell>Description</th>
            <td *matCellDef="let constraint" mat-cell>
              <input [(ngModel)]="constraint.attributes.description"
                     [disabled]="!(constraint === currentConstraint)"
                     matInput
                     class="text-input"/>
            </td>
          </ng-container>

          <ng-container matColumnDef="assumptions">
            <th *matHeaderCellDef mat-header-cell>Assumptions</th>
            <td *matCellDef="let constraint" mat-cell>
              <input [(ngModel)]="constraint.attributes.assumptions"
                     [disabled]="!(constraint === currentConstraint)"
                     matInput
                     class="text-input"/>
            </td>
          </ng-container>

          <ng-container matColumnDef="formula">
            <th *matHeaderCellDef mat-header-cell>Formula</th>
            <td *matCellDef="let constraint" mat-cell>
              <div style="display:flex;justify-content:space-between;align-items:center;">
                <div>{{ constraint.attributes.name_formula }}</div>
                <button (click)="expandFormula=!expandFormula;" [disabled]="constraint !== currentConstraint"
                        style="border:none;">
                  <i class="fas fa-plus-square hideonprint formula-container"></i></button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="save">
            <th *matHeaderCellDef mat-header-cell>Save</th>
            <td *matCellDef="let constant_property" mat-cell>
              <i (click)="saveConstraint()"
                 class="fa fa-save hideonprint"></i>
            </td>
          </ng-container>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td *matCellDef="let constraint" [attr.colspan]="columns.length" mat-cell>
            <div [@detailExpand]="constraint === currentConstraint ? 'expanded' : 'collapsed'"
                 class="formula-detail">
              <series-formula-builder *ngIf="expandFormula && constraint === currentConstraint"
                                      [constraint]="constraint"></series-formula-builder>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns;" mat-header-row></tr>

        <tr *matRowDef="let constraint; columns: columns;"
            [ngClass]="{'disabled': !(constraint === currentConstraint)}"
            [class.formula-expanded-row]="expandFormula && (constraint === currentConstraint)"
            mat-row>
        </tr>

        <tr *matRowDef="let constraint; columns: ['expandedDetail'];"
            [class.detail-not-expanded]="!expandFormula || (constraint !== currentConstraint)"
            class="event-detail-row"
            mat-row>
        </tr>
      </table>
    </div>
  </div>
</div>
