import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {Observable, Subject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {first, take, takeUntil, tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {
    openUploadModelFormComponent,
    UploadModelFormData
} from "../../forms/upload-model-form/upload-model-form-dialog.component";
import {FormDialogService} from "../../services/form-dialog.service";
import {ComponentType} from '../../_models/component-type';
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ComponentTypesTableService} from './component-types-table.service';
import {ListResponse} from "../../services/api/response-types";
import {Account} from "../../_models/account";

@Component({
    selector: 'component-types-table',
    templateUrl: 'component-types-table.component.html',
    styleUrls: ['component-types-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None, //Global Styles
    providers: [ComponentTypesTableService],
    standalone: false
})
export class ComponentTypesTableComponent implements OnInit, OnDestroy {
    filter_string = '';
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<ComponentType>;
    page_size_options = [10, 20, 50, 100];
    component_types_total = 0;
    buttons = [];

    account: Account;
    component_types: any[];

    columns: string[] = ["base_type", "name", "description", "constant_properties", "account",
        "created_on", "created_by_name", "changed_on", "changed_by_name"];

    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private changeDetectorRef: ChangeDetectorRef,
                public dialog: MatDialog,
                private formDialogService: FormDialogService,
                private cps: ComponentTypesTableService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';
        setTimeout(() => {
            this.paginator.pageSize = 10;
            initialQuery.filters = this.cps.getComponentTypesFilter();

            this.dataSource = new PaginationDataSource<ComponentType>(
                (query) => this.page(query),
                initialQuery,
                this.paginator,
                this.sort
            );
            this.dataSource.$page.pipe(
                takeUntil(this.onDestroy),
            ).subscribe();
        })
        this.headerData.title = 'Component Types';
        this.buildHeader();
    }


    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.component_type.searchMany(query).pipe(
            first(), takeUntil(this.onDestroy),
            tap(result => {
                this.component_types = result.data;
                this.component_types_total = result.meta.count;
            })
        )
    }

    emitFilterQuery() {
        const filters = this.cps.getComponentTypesFilter(this.account?.id);
        this.dataSource.filterBy(filters);
    }

    filterByAccount(event: Account) {
        this.account = event;
        this.emitFilterQuery();
    }

    updateSearchFilter() {
        const filters = this.cps.updateSearchFilter(this.filter_string, this.account?.id);
        this.dataSource.filterBy(filters)
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort)
    }

    editComponentType(component_type?) {
        const dialogRef = this.formDialogService.editComponentType(component_type, this.component_types);
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.updateSearchFilter();
            }
        });
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Component Types';
        ctrl.headerData.show_dtp = false;
        ctrl.headerData.buttons = [
            {name: 'Add Component Type', func: ctrl.editComponentType.bind(ctrl), class: 'icon-plus'},
            {name: 'Upload Model Definitions', func: ctrl.uploadModels.bind(ctrl), class: 'icon-plus'},
        ];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    uploadModels() {
        const dialogData: UploadModelFormData = {parser_type: 'component'};
        openUploadModelFormComponent(this.dialog, dialogData);
    }
}
