<div class="row auto" style="justify-content: space-between;">
  <div class="info row center" style="padding:0 8px 10px;font-size: 1.1rem;" *ngIf="info">
    <i class="fa fa-info-circle"></i>
    <div style="display:inline;margin-left:10px;" [innerHTML]="info"></div>
  </div>
</div>

<div class="lock-template-version-table">
<div class="row auto" style="justify-content: flex-end;">
  <mat-paginator showFirstLastButtons
                 style="justify-self: flex-end;">
  </mat-paginator>
</div>
<div class="table-container">
  <table [dataSource]="dataSource"
         [matSortActive]="'created_on'"
         (matSortChange)="updateSort($event)"
         class="table simple-table"
         mat-table matSort matSortDirection="asc">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
      <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
        <ng-container>{{column | titlecaseForWire | removeUnderscore}}</ng-container>
      </th>
      <td *matCellDef="let element;"
          mat-cell [ngClass]="{'selected-template-version-row': selected_version===element}"
          style="border:none;">
        <ng-container *ngIf="column==='template'">
          <a (click)="changeSelectedVersion($event, element)"
             style="cursor:pointer;">{{version_template_dict[element.id]?.attributes.name}}</a>
        </ng-container>
        <ng-container
            *ngIf="['created_on', 'changed_on'].includes(column)">{{element.attributes[column] | stringDate }}
        </ng-container>
        <ng-container
            *ngIf="['created_by_name', 'changed_by_name'].includes(column)">{{element.attributes[column]}}
        </ng-container>
        <ng-container
            *ngIf="['account_name'].includes(column)">{{element.attributes.account_name}}
        </ng-container>
        <ng-container *ngIf="['schema'].includes(column)">
          <a (click)="changeSelectedVersion($event, element)"
             style="cursor:pointer;">{{element.attributes.schema.name }}&nbsp;
            (click to view)</a>
        </ng-container>
        <ng-container *ngIf="['unlock'].includes(column)">
          <button mat-button class="unlock-btn table-button"
                  (click)="unlockTemplate(element)"
                  [disabled]="!(canUnlock() && selected_version===element)"
                  matTooltip="Unlock data for {{component_name || 'this event'}} with the selected template version"
                  [matTooltipDisabled]="!(canUnlock() && selected_version===element)">
            <i class="fa fa-unlock"></i></button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container>
      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </ng-container>
  </table>
</div>
</div>