import {ApiService} from '../api/api.service';
import {Injectable} from '@angular/core';
import {AppScope} from '../app_scope.service';
import * as utils from "../../lib/utils";
import {deepCopy, FlatPromise} from "../../lib/utils";
import { HttpClient } from "@angular/common/http";
import {concatMap, map, tap} from "rxjs/operators";
import {forkJoin, Observable, of} from "rxjs";
import {Process} from "../../_models/process";
import {FlowchartData} from "../../_models/flowchart-data";
import {
    get_connectors,
    get_constant_components_for_components,
    get_equipment,
    get_ore_body_groups,
    get_process_children,
    get_series_components_for_components,
    get_series_for_components,
    get_streams
} from "./helper";
import {Series} from "../../_models/series";
import {SearchQueryOptions} from "../api/search-query-options";
import {ProcessPermissions} from "../../_models/process-permissions";
import {
    getAccountFilter,
    getManyRelationWithIdFilter,
    getRelationWithIdFilter,
    getRelationWithNameFilter
} from "../api/filter_utils";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {SeriesDataService} from "../series_data.service";

@Injectable()
export class PlantDataService {
    reports: any[];
    components = [];
    flowSheet = {};
    all_series: any[];
    ore_body_groups: any[];
    series_dict = {};
    process_focus: any = {};

    previous_process_ids = [];
    permissions = {};
    // All components ie streams, equipment and process have been fetched
    // Process focus permissions have been fetched
    // All related series have been and have the local attributes report_group and can_edit resolved

    constructor(private appScope: AppScope,
                private api: ApiService,
                private http: HttpClient,
                private seriesDataService: SeriesDataService) {
        appScope.auth_complete.promise.then(() => {

        });
    }

    getPlants(account_id: string): Observable<any> {
        const options = new SearchQueryOptions();
        options.filters = [{
            "and": [
                getAccountFilter(account_id),
                getRelationWithNameFilter('component_type', 'Plant')
            ],
        }];

        options.ignore_routing = true;

        return this.api.process.searchMany(options).pipe(map(result => result.data));
    }

    setActiveProcess(process) {
        this.process_focus = process;
        const menuProcess = {
            children: null,
            expanded: false,
            id: process.id,
            name: process.attributes.name,
            top: process.relationships?.parent?.data?.id
        };
        if (!this.appScope.processTreeDataDict[process.id]) {
            this.appScope.processTreeDataDict[process.id] = menuProcess;
        }
    }

    /**
     * Replaces getIsolatedPermissions
     * @param process_id
     */
    getProcessPermissions(process_id: string): Observable<ProcessPermissions> {
        const ctrl = this;

        return ctrl.appScope.currentUserValue.pipe(
            concatMap(current_user => {
                const options = new SearchQueryOptions();
                options.filters = [{
                    and: [getRelationWithIdFilter('user', current_user.id), {
                        or: [
                            getRelationWithIdFilter('top_process', process_id),
                            {
                                name: 'top_process',
                                op: 'has',
                                val: getManyRelationWithIdFilter('children', process_id)
                            }]
                    }]
                }];

                return this.api.process_access.searchMany(options).pipe(map(response => {
                    const access = response.data;

                    return new ProcessPermissions(access);
                }));
            })
        );

    }

    /**
     * @deprecated use getProcessPermissions instead
     * @param process_id
     */
    getIsolatedPermissions(process_id) {
        // TODO rework this function to use clean promises and wait for appScope.auth_complete
        const isoPermissions: any = {};
        isoPermissions.all = new FlatPromise();

        const parameters = this.api.prep_q([{
            and: [getRelationWithIdFilter('user', this.appScope.current_user.id), {
                or: [
                    getRelationWithIdFilter('top_process', process_id),
                    {
                        name: 'top_process',
                        op: 'has',
                        val: getManyRelationWithIdFilter('children', process_id)
                    }]
            }]
        }]);

        isoPermissions.access = this.api.process_access.search(parameters);

        isoPermissions.access.subscribe(data => {
            if (!data) {
                // api call was cancelled
                return;
            }
            isoPermissions.permissions = {};
            let index = 0;
            for (index = 0; index < data.data.length; index++) {
                isoPermissions.permissions[data.data[index].attributes.feature_name] = true;
            }
            isoPermissions.all.resolve(isoPermissions);
        });

        return isoPermissions;
    }

    // 1.) Check whether process focus ID = the routeParams processID and refresh accordingly
    // 2.) Get all child processes then.
    // 3.) Get streams for all processes (also get connectors - independent) then...
    // 4.) Get equipment (and other) for all streams and processes then...
    // 5.) Concat all components (process focus, child processes, streams, equipment) then...
    // 6.) Get all series_component objects and all series for all components (7a) then...
    // 7.) Add component name as parent_name attribute to series
    // 8.) Add series_component attributes to the series objects
    // 9.) Check series_light is resolved and resolve all - allDataFetched

    getFlowchartDataForProcess(process_id: string): Observable<FlowchartData> {
        const ctrl = this;

        const $series = this.api.series_light.searchMany().pipe(tap(response => {
            this.all_series = response.data;
        }));
        return ctrl.appScope.currentUserValue.pipe(
            concatMap(currentUser => $series),
            concatMap(all_series => ctrl.api.process.getById(process_id).pipe(
                map(response => response.data))),
            concatMap((process: Process) => ctrl.getFlowchartData(process))
        );
    }

    getSeriesSummary(flowSheet: FlowchartData, dtp: IDateTimePeriod) {
        const ctrl = this;
        let series_list_ids = flowSheet.series_components.filter(
            item => item.attributes.view_on_flowchart || item.attributes.view_on_parent_flowchart).map(
            item => item.relationships.series.data.id
        );

        if (series_list_ids.length > 0) {
            let summary = this.seriesDataService.getSeriesSummary(dtp, series_list_ids, null, ['Value', 'Status']);

            summary.pipe(tap(result => {
                if (!result) {
                    console.warn('PlantDataService: GSS Query cancelled. Replace this call with a subscription.');
                    return;
                }

                flowSheet.series_components.map(series_component => {
                    result.forEach(data => {
                        const series_data = series_component.relationships.series.data;

                        if (series_data != null &&
                            'attributes' in series_data &&
                            series_data.attributes.name === data.Name) {
                            series_data.attributes.value = data['Value'];
                            series_data.attributes.status = data['Status'];
                        }
                    });
                });
            })).subscribe();
            return summary;
        }
    }

    private getFlowchartData(process: Process): Observable<FlowchartData> {
        const ctrl = this;

        const data: FlowchartData = new FlowchartData();
        data.process_focus = process;
        // This temporarily stores series_components returned from the api. data.series_components will be filled after
        // the relationships.series data is filled with the full series
        let series_components = [];

        // Focus process and children
        const process_ids = process.relationships.children.data.map(child => child.id);
        process_ids.push(process.id);

        const $child_processes = get_process_children(ctrl.api, process.id).pipe(tap(response => {
            data.processes = response.data;
        }));
        const $streams = get_streams(ctrl.api, process_ids).pipe(tap(response => {
            data.streams = response.data;
        }));
        const $connectors = get_connectors(ctrl.api, process_ids).pipe(tap(response => {
            data.connectors = response.data;
        }));

        let stream_ids;
        let equipment_ids;

        return forkJoin([$child_processes, $streams, $connectors]).pipe(concatMap(() => {
            stream_ids = data.streams.map(item => item.id);

            return get_equipment(ctrl.api, process_ids.concat(stream_ids)).pipe(tap(response => {
                data.equipment = response.data;
                equipment_ids = data.equipment.map(item => item.id);
            }));
        }), concatMap(() => {

            const component_ids = process_ids.concat(stream_ids).concat(equipment_ids);

            // Fetch all series_components for all components within process_focus
            const $series_components = get_series_components_for_components(ctrl.api, component_ids).pipe(tap(response => {
                series_components = response.data;
            }));

            // Fetch all constant_components for all components within process_focus
            const $constant_components = get_constant_components_for_components(ctrl.api, component_ids).pipe(tap(response => {
                data.constant_components = response.data;
            }));

            // **Fetch all series for all components within process_focus
            const $series = get_series_for_components(ctrl.api, component_ids).pipe(map(response => {
                data.selected_series = response.data;

                data.selected_series.map(series => {
                    data.selected_series_ids.push(series.id);

                    const extractSeries = item => {
                        if (item.relationships.series) {
                            item.relationships.series.data.map(related_series => {
                                if (related_series.id === series.id) {
                                    series.attributes.parent_name = item.attributes.name;
                                }
                            });
                        }
                    };

                    data.processes.concat([data.process_focus]).map(extractSeries);
                    data.streams.map(extractSeries);
                    data.equipment.map(extractSeries);
                });
            }));

            let $ore_body_groups;

            if (data.process_focus.attributes.json && data.process_focus.attributes.json.ore_body_groups && data.process_focus.attributes.json.ore_body_groups.length > 0) {
                $ore_body_groups = get_ore_body_groups(ctrl.api, data.process_focus.attributes.json.ore_body_groups).pipe(map(response => {
                    data.process_focus.attributes.json.ore_body_groups.forEach(json => {
                        response.data.forEach(obg => {
                            if (obg.id === json.data.id) {
                                json.data = obg;
                            }
                        });
                    });
                }));
            } else {
                $ore_body_groups = of([]);
            }

            return forkJoin([$series_components, $constant_components, $series, $ore_body_groups]);
        }), concatMap(() => {

            series_components.forEach(item => {
                if (item.relationships.series.data !== null) {
                    data.series_component_map[item.id] = item;
                } else {
                    data.series_components.push(item);
                }
            });
            data.selected_series.map(series => {
                data.series_component_atts_dict[series.id] = utils.deepCopy(series);
                series.relationships.series_components.data.forEach(sc => {
                    if (data.series_component_map.hasOwnProperty(sc.id)) {
                        const pseudo_series: Series = utils.deepCopy(series);
                        data.series_component_map[sc.id].relationships.series.data = pseudo_series;
                        pseudo_series.attributes.value = null;

                        pseudo_series.attributes.report_group = data.series_component_map[sc.id].attributes.report_group;
                        pseudo_series.attributes.series_order = data.series_component_map[sc.id].attributes.series_order;
                        pseudo_series.attributes.can_edit = data.series_component_map[sc.id].attributes.can_edit;
                        pseudo_series.attributes.latest_value = data.series_component_map[sc.id].attributes.latest_value;

                        data.series_component_map[sc.id].relationships.series.data = pseudo_series;
                        data.series_components.push(data.series_component_map[sc.id]);
                        // for log sheets
                        data.series_component_atts_dict[series.id] = Object.assign(data.series_component_atts_dict[series.id], pseudo_series);
                        // this seems to do the same as the line above, though need it to have can_edit set properly
                        series = utils.deepCopy(pseudo_series);
                    }
                });
                /**This must be too deep a change to be picked up by ref so needs to be set explicitly on data**/
                data.selected_series[data.selected_series.findIndex(s => s.id === series.id)] = series
            });
            return of(data);
        }));
    }

    saveItem(item) {
        let to_save = deepCopy(item);
        delete to_save.relationships;

        if ((to_save.type === 'constant_component' || to_save.type === 'series_component') && !(item.attributes.view_on_flowchart || item.attributes.view_on_parent_flowchart)) {
            return;
        }
        this.api[item.attributes?.base_type || to_save.type].obsPatch(to_save).subscribe();
    }
}
