import {HandleError, HttpErrorHandler} from "../http-error-handler.service";
import {AsyncSubject, Observable, share, Subject} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {ListResponse, SingleResponse} from "./response-types";
import {catchError, map, publishLast, refCount, take, takeUntil, tap, timeout} from "rxjs/operators";
import * as utils from "../../lib/utils";
import {isEmpty as _isEmpty} from "lodash-es";
import {SearchQueryOptions} from "./search-query-options";
import {CacheManager} from "./caching/cacheManager";
import {SearchOptions} from "./searchOptions";
import {isBaseModel, isBaseModelLight} from '../../_typing/generic-types';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Authorization': 'my-auth-token'
    })
};

export class Model<T = any> {
    private readonly handleError: HandleError;
    private readonly onQueryCancel = new Subject<void>();

    /**
     *
     * @param http
     * @param httpErrorHandler
     * @param baseUrl
     * @param modelName
     * @param cacheManager
     * @param cacheName
     * @param shouldCache
     * @param cacheTimeout Time until an cache entry is invalidated (will be lazy updated). Default 12 hours.
     */
    constructor(private http: HttpClient,
                httpErrorHandler: HttpErrorHandler,
                private baseUrl: string,
                public modelName: string,
                private cacheManager: CacheManager,
                private cacheName: string,
                private shouldCache: boolean = false,
                private cacheTimeout: number = 43200000) {
        this.handleError = httpErrorHandler.createHandleError('ApiService');
    }

    cancelActiveQueries() {
        this.onQueryCancel.next();
    }

    getById(id: string, options?: SearchQueryOptions): Observable<SingleResponse<T>> {
        if (!options) {
            options = new SearchQueryOptions();
        }

        let query_builder = this.http.get<SingleResponse<T>>(this.baseUrl + '/' + id);

        if (!(options.ignore_routing === true)) {
            query_builder = query_builder.pipe(
                takeUntil(this.onQueryCancel),
            );
        }

        query_builder = query_builder.pipe(
            catchError(this.handleError<SingleResponse<T>>('getByID ' + this.modelName))
        );

        return query_builder;
    }

    //Call for getting the name for a model stub (singular)
    getName(id: string): Observable<string> {
        return this.getById(id).pipe(
            map(result => {
                if (isBaseModel<T>(result?.data) || isBaseModelLight<T>(result?.data)) {
                    return result.data?.attributes?.name;
                }
                return id;
            }), take(1))
    }

    /**
     * @deprecated Use searchMany or searchSingle instead.
     * @param vars
     * @param options
     */
    search(vars: {} = {}, options: SearchOptions = {}): Observable<ListResponse<T>> {
        // TODO only cache if there was no error
        // TODO add support for caching when passing in vars
        // TODO unsubscribe from cache

        if (this.shouldCache && _isEmpty(vars)) {
            let cachedResponse = this.cacheManager.getCacheEntry(this.cacheName);
            if (!cachedResponse) {
                // if not paged set to 8000
                if (!Object.keys(vars).includes('page[size]')) {
                    vars['page[size]'] = 8000;
                }
                // no entry was found or was outdated, make a new request
                cachedResponse = this.http.get<ListResponse<T>>(this.baseUrl, {params: utils.httpParamSerializer(vars)})
                    .pipe(
                        timeout(this.cacheTimeout),
                        catchError(this.handleError<any[]>('search ' + this.modelName, [])),
                        publishLast(),
                        refCount()
                    );
                this.cacheManager.setCacheEntry(this.cacheName, cachedResponse, this.cacheTimeout);
            }
            return cachedResponse;
        } else {
            // if not paged set to 8000
            if (!Object.keys(vars).includes('page[size]')) {
                vars['page[size]'] = 8000;
            }
            let query_builder = this.http.get<ListResponse<T>>(this.baseUrl, {params: utils.httpParamSerializer(vars)});

            if (!(options.ignore_routing === true)) {
                query_builder = query_builder.pipe(
                    takeUntil(this.onQueryCancel)
                );
            }

            query_builder = query_builder.pipe(
                catchError(this.handleError<ListResponse<T>>('search ' + this.modelName))
            );

            return query_builder;
        }
    }

    searchMany(options?: SearchQueryOptions): Observable<ListResponse<T>> {
        // TODO only cache if there was no error
        // TODO add support for caching when passing in vars
        // TODO unsubscribe from cache
        const default_params = new SearchQueryOptions().getParams();
        if (this.shouldCache && (!options || _isEmpty(options))) {
            let cachedResponse = this.cacheManager.getCacheEntry(this.cacheName);
            if (!cachedResponse) {
                // no entry was found or was outdated, make a new request
                cachedResponse = this.http.get<ListResponse<T>>(this.baseUrl, {params: default_params})
                    .pipe(
                        timeout(this.cacheTimeout),
                        catchError(this.handleError<any[]>('search ' + this.modelName, [])),
                        share({
                            connector: () => new AsyncSubject(), resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false })
                    );
                this.cacheManager.setCacheEntry(this.cacheName, cachedResponse, this.cacheTimeout);
            }
            return cachedResponse;
        } else {
            let query_builder;
            if (!options || _isEmpty(options)) {
                query_builder = this.http.get<ListResponse<T>>(this.baseUrl, {params: default_params});
                query_builder = query_builder.pipe(takeUntil(this.onQueryCancel));
            } else {
                const params = options.getParams();

                query_builder = this.http.get<ListResponse<T>>(this.baseUrl, {params: params});
                if (!(options.ignore_routing === true)) {
                    query_builder = query_builder.pipe(
                        takeUntil(this.onQueryCancel)
                    );
                }
            }

            query_builder = query_builder.pipe(
                catchError(this.handleError<ListResponse<T>>('search ' + this.modelName))
            );

            return query_builder;
        }
    }

    searchSingle(options?: SearchQueryOptions): Observable<SingleResponse<T>> {
        const list = this.searchMany(options);
        let query_builder: Observable<SingleResponse<T>> = list.pipe(
            map(res => ({...res, data: res.data[0], links: (res.data.length && res.data[0]['links'] || res.links)}))
        )
        return query_builder;
    }

    getByName(query_name: string, options: SearchQueryOptions = new SearchQueryOptions): Observable<SingleResponse<T>> {
        options.filters = [{name: 'name', op: 'eq', val: query_name}];
        const list = this.searchMany(options);
        let query_builder: Observable<SingleResponse<T>> = list.pipe(
            map(res => ({...res, data: res.data[0], links: (res.data.length && res.data[0]['links'] || res.links)}))
        )
        return query_builder;
    }

    /**
     * @deprecated Use the search() method instead and use the response.data
     * @param vars
     */
    query(vars?: {}): any {

        // Add safe, URL encoded search parameter if there is a search term
        if (vars == null) {
            vars = {};
        }
        if (!Object.keys(vars).includes('page[size]')) {
            vars['page[size]'] = 8000;
        }
        let resource = this.http.get<any[]>(this.baseUrl, {params: utils.httpParamSerializer(vars)});
        // .pipe(
        //     catchError(this.handleError<any[]>('search ' + this.modelName, []))
        // );

        let resource_promise = resource.toPromise();
        let ngresource: any = [];

        ngresource.$promise = resource_promise;

        resource_promise.then(function (response) {
            // @ts-ignore
            response.data.forEach(function (item) {
                ngresource.push(item)
            })
        }, response => {
            console.error('Failed to fetch query', response);
        });

        return ngresource
    }

    getRelatedMany(id: string, relationship: string): Observable<ListResponse<any>> {
        // TODO test if related still works
        return this.http.get<ListResponse<any>>(this.baseUrl + '/' + id + '/' + relationship)
            .pipe(catchError(this.handleError<ListResponse<any>>('getRelatedMany ' + this.modelName)));
    }

    //////// Save methods //////////

    // TODO remmove the toPromise from these methods
    save(item: any): Promise<any> {
        return this.obsSave(item).toPromise();
    }

    obsSave(item: any): Observable<any> {
        delete item.id;
        return this.http.post<any>(this.baseUrl, {data: item}, httpOptions)
            .pipe(catchError(this.handleError('add ' + this.modelName)),
                tap(() => this.cacheManager.invalidateCache(this.cacheName)));
    }

    delete(id: string): Promise<any> {
        return this.obsDelete(id).toPromise();
    }

    obsDelete(id: string): Observable<any> {
        const url = `${this.baseUrl}/${id}`; // DELETE api/heroes/42
        return this.http.delete(url, httpOptions)
            .pipe(catchError(this.handleError('delete ' + this.modelName)),
                tap(() => this.cacheManager.invalidateCache(this.cacheName)))
    }

    patch(item: any): Promise<any> {
        return this.obsPatch(item).toPromise();
    }

    obsPatch(item: any): Observable<any> {
        return this.http.patch<any>(this.baseUrl + '/' + item.id, {data: item}, httpOptions)
            .pipe(
                catchError(this.handleError('update ' + this.modelName)),
                tap(() => this.cacheManager.invalidateCache(this.cacheName))
            );
    }

}
