import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {from, Subject, Subscription, switchMap, take, takeUntil, tap} from "rxjs";
import {FormDialogService} from '../../services/form-dialog.service';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {ICustomHTMLParameters, TileDataService, TileParameters} from '../../services/tile_data.service';
import {ApiService} from '../../services/api/api.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {refreshSubscription} from '../../lib/utils';
import {IDateTimePeriod} from '../../_typing/date-time-period';
import {SanitiseService} from "../../services/sanitise.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {JsonContextService} from "../../forms/custom-html-form/json-context.service";

@Component({
    selector: 'custom-html',
    templateUrl: './custom-html.component.html',
    styleUrls: [
        "./custom-html.component.less",
        "../../app.component.less",
        "../../shared/styles/shared-table-styles.less",
        "../../shared/styles/quill.less"
    ],
    encapsulation: ViewEncapsulation.ShadowDom,
    standalone: false
})
export class CustomHtmlComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private $loadDisplayText: Subscription | null = null;

    @Input() config: TileParameters;
    @Input() show_header: boolean;

    safeDisplayText: SafeHtml;
    safePlaceHolder: SafeHtml;

    constructor(
        private readonly formDialogService: FormDialogService,
        private readonly dateTimePeriodService: DateTimePeriodService,
        private dateInst: DateTimeInstanceService,
        private readonly elementRef: ElementRef,
        private readonly tileData: TileDataService,
        private api: ApiService,
        private sanitiser: SanitiseService,
        private renderer: Renderer2,
        private jsonContextService:JsonContextService
    ) {
    }

    ngOnInit(): void {
        this.getPlaceholders();

        this.dateInst.dateTimePeriodRefreshed$.pipe(
            takeUntil(this.onDestroy),
            tap(() => {
                this.safeDisplayText = null;
            }),
            switchMap(() => this.getReport())
        ).subscribe();

        from(this.dateTimePeriodService.dtpInitialisedPromise.promise).pipe(
            switchMap(() => this.getReport()),
            take(1)).subscribe();
    }

    private getPlaceholders() {
        if (this.config.hasOwnProperty("paragraph_body")) {
            this.safePlaceHolder = this.sanitiser.htmlToDisplay(this._processPlaceholder(this.config["paragraph_body"]));
        } else {
            this.safePlaceHolder = this.sanitiser.getUnsafeHtml(this.processTemplatePlaceholder(this.config["template"]));
        }
    }

    private getReport() {
        return this.api.getReportTemplate(this.tileData.id, this.dateInst.dtp).pipe(
            tap((response: { html: string, is_legacy: boolean }) => {
                if (response.is_legacy) {
                    this.setParagraphsLegacy(response.html, this.dateInst.dtp);
                } else {
                    this.safeDisplayText = this.sanitiser.getUnsafeHtml(response.html);
                    this.setupSeriesElementListeners();
                }
            })
        )
    }

    private setParagraphsLegacy(text: any, dtp: IDateTimePeriod) {
        this.$loadDisplayText = refreshSubscription(this.$loadDisplayText);
        this.$loadDisplayText = this.tileData.parseForSeriesValues(text, dtp).pipe(takeUntil(this.onDestroy))
            .subscribe((display) => {
                this.safeDisplayText = this.sanitiser.getUnsafeHtml(display);

                const shadowRoot = this.elementRef.nativeElement.shadowRoot;
                if (!shadowRoot) {
                    return;
                }
                setTimeout(() => {
                    const seriesElements = shadowRoot.querySelectorAll('.series-element');
                    seriesElements.forEach(el => {
                        this.renderer.listen(el, 'click', (event) => {
                            this.openChartDialog(event);
                        });
                    });
                });
            });
    }

    setupSeriesElementListeners() {
        //TODO this won't work - needs to be added to the report html with series id
        const shadowRoot = this.elementRef.nativeElement.shadowRoot;
        if (!shadowRoot) {
            return;
        }
        setTimeout(() => {
            const seriesElements = shadowRoot.querySelectorAll('.series-element');
            seriesElements.forEach(el => {
                this.renderer.listen(el, 'click', (event) => {
                    this.openChartDialog(event);
                });
            });
        });
    }

    private _processPlaceholder(text: string): string {
        if (!text) {
            return text;
        }
        const regex = /\[[^\]]*\]/g;
        return text.replace(regex, '...');
    }

    private processTemplatePlaceholder(text: string): string {
        if (!text) {
            return text;
        }
        // Regex to replace {{xxxx}} with ...
        const placeholderRegex = /\{\{[^#\/][^}]*\}\}/g;
        // Regex to replace {{#xxx}} and {{/xxx}} with nothing
        const loopRegex = /\{\{[#/][^}]*\}\}/g;

        return text
            .replace(placeholderRegex, '...')
            .replace(loopRegex, '');
    }

    openChartDialog(event) {
        const dialogRef = this.formDialogService.openChartDialog(event.srcElement.id, this.dateInst.dtp);
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.querySelectorAll('.series-element').forEach(el => {
            el.removeEventListener('click', this.openChartDialog.bind(this));
        });
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
