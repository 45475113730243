<div *ngIf="current_page && !table_view" class="series-audit-history">
  <div class="row center" style="justify-content: space-between;height:30px;">
    <h2>Series Edit History</h2>
    <div class="row"
         style="display: flex;align-items: center;justify-content: space-evenly;font-size:0.8em;">
      <button (click)="page(-1)" [class.disabled]="page_number===0"
              [disabled]="page_number===0"
              [matTooltip]="'Previous'"
              class="btn">
        <i class="fa fa-chevron-left"></i>
      </button>
      <button (click)="page(1)" [class.disabled]="page_number===paged_history.length - 1"
              [disabled]="page_number === paged_history.length - 1"
              [matTooltip]="'Next'"
              class="btn">
        <i class="fa fa fa-chevron-right"></i>
      </button>
    </div>
  </div>
  <div class="labels">
    <h3 *ngIf="series">{{series.attributes | nameOrDescription}}</h3>
    <div class="row">
      <label>Change by: </label>
      <div>{{current_page.changed_by_fk}}</div>
    </div>
    <div class="row">
      <label>Changed on:</label>
      <div>{{current_page.changed_on | stringDate}}</div>
    </div>
  </div>
  <div [innerHTML]="current_page.value_string" class="values">
  </div>
  <div class="page-number">
    <span style="color: #999"> {{page_number + 1}} / {{paged_history.length}}</span>
  </div>
</div>

<div *ngIf="table_view" class="series-audit-history form-paginator">
  <div class="utils-bar" >
    <div *ngIf="dataSource" class="table-filter">
      <input (keyup)="applyFilter($event.target.value)" placeholder="Search series history..."/>
    </div>
    <div>
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="page_size" style="align-items: center;"
                     length="{{data ? data.length : 0}}" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="table-container">
    <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="status"
         class="table striped-table series-audit-history-table" mat-table matSort style="width:100%;" matSortActive="timestamp" matSortDirection="desc">

      <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
        <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
          <ng-container>{{column | upperfirst | removeUnderscore}}</ng-container>
        </th>
        <td *matCellDef="let item;" mat-cell style="text-align: center;height:30px;border:none;">
          <ng-container *ngIf="column === 'timestamp' || column === 'changed_on'">
            <span>{{item[column] | stringDate}}</span>
          </ng-container>
          <ng-container *ngIf="column !== 'date' && column !== 'changed_on' && column !== 'timestamp'" class="row center">
            <span>{{item[column]}}</span>
          </ng-container>
        </td>
        <td *matCellDef="let item;" mat-cell style="text-align: center;height:30px;border:none;">
          <ng-container *ngIf="column === 'comments'">
            <div style="max-height:80px;overflow-y:auto;">
            <div *ngFor="let comment of item.comments">{{comment}}</div>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </table>
  </div>


</div>
