import {Series} from "../../../_models/series";
import { ModelID } from '../../../_typing/generic-types';
import { ConstantProperty } from '../../../_models/constant-property';

export class TreeNode {
    series: Series;
    children?: TreeNode[] = [];
    id?: string;

    get description(): string {
        return this.series.attributes.description;
    }

    get name(): string {
        return this.series.attributes.name
    }
}

export class LockVersionNode {
    attributes: string[];
    model_id: ModelID;
    ct_dependencies: ModelID[];
    constant_properties?: ConstantProperty[]
}
