import {Component, Input} from '@angular/core';
import {GenericChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {ApiService} from '../../services/api/api.service';
import {PlotlyChartConfiguration} from '../../services/plotly-chart-config-translation.service';
import {RarChartService} from "./rar-chart.service";
import {takeUntil, take} from 'rxjs/operators';
import {BaseComponent} from "../../shared/base.component";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

declare var Plotly: any;

@Component({
    selector: 'rar-chart',
    templateUrl: './rar-chart.component.html',
    styleUrls: ['./rar-chart.component.less'],
    providers: [RarChartService],
    standalone: false
})
export class RarChartComponent extends BaseComponent {
    @Input() config: GenericChartTileConfiguration;
    chart: PlotlyChartConfiguration;

    constructor(private api: ApiService,
                private cps: RarChartService,
                private dateInst: DateTimeInstanceService) {
        super();
    }

    ngOnInit() {
        const ctrl = this;
        this.config.library = 'plotly';

        this.cps.$rarConfigReady.pipe(takeUntil(this.onDestroy))
            .subscribe(chart_config => this.loadChart(chart_config));

        this.dateInst.dateTimePeriodInitialised$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.cps.getChart(this.config);
        });
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.cps.getChart(this.config);
        });

    }

    loadChart(chart_config) {
        if (this.config.library === 'plotly') {
            this.chart = chart_config;
        }
    }

    handleLegendClick($event) {
        const traceIndex = $event.curveNumber;
        const traceName = $event.data[traceIndex].name;

        // Toggle the visibility of the annotations linked to the clicked trace
        const annotations = [];
        $event.layout.annotations.forEach(anno => {
            if (anno.name === traceName) {
                //this event fires before trace visibility is set so set to what trace WILL be
                anno.visible = $event.data[traceIndex].visible === 'legendonly' ? true : false;
            }
            annotations.push(anno);
        });
        this.chart.layout = {...this.chart.layout, ...{annotations: annotations}};
    }
}
