import {Injectable, OnDestroy} from '@angular/core';
import {BasePaginatedTableService} from "../base-paginated-table.service";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {Observable} from "rxjs";
import {GroupDataService} from "../../data/group-data.service";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {GenericDataService} from "../../data/generic-data.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {LockTemplateVersion} from "../../_models/lock-template-version";
import {LockTemplate} from "../../_models/lock-template";
import {getBaseFilter, getRelationWithIdFilter} from '../../services/api/filter_utils';

@Injectable({
    providedIn: 'root'
})
export class LockTemplateVersionTableService extends BasePaginatedTableService<LockTemplateVersion> implements OnDestroy {

    dataSource: PaginationDataSource<LockTemplateVersion>;
    api_name = 'lock_template_version';
    columns = ['schema'].concat(this.audit_columns).filter(n => !['created_by_name', 'changed_by_name'].includes(n));
    search_keys = [];

    constructor(private groupDataService: GroupDataService,
                private appScope: AppScope,
                api: ApiService,
                genericData: GenericDataService) {
        super(genericData, api);
    }

    initialise(lock_template: LockTemplate, lock_template_versions?: LockTemplateVersion[]) {
        const initialQuery: SearchQueryOptions = this.getSearchOptions(lock_template.id, lock_template_versions);
        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<LockTemplateVersion>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            tap((result: ListResponse<LockTemplateVersion>) => {
                this.dataSourceSubject.next(this.dataSource);
            }),

            takeUntil(this.onDestroy)).subscribe();
    }

    getSearchOptions(lock_template_id, lock_template_versions?: LockTemplateVersion[]): SearchQueryOptions {
        let version_ids;
        version_ids = lock_template_versions?.map(ltv => ltv.id);
        const options = new SearchQueryOptions(this.page_size, 1, 'changed_on');

        let filters: any[];
        if (!version_ids) {
            filters = [getRelationWithIdFilter('lock_template', lock_template_id)];
        } else {
            filters = [getBaseFilter(version_ids, 'id', 'in')];
        }
        options.filters = filters;
        return options;
    }

    page(query: SearchQueryOptions): Observable<ListResponse<LockTemplateVersion>> {
        return super.page(query, this.api_name);
    }

    refresh(lock_template: LockTemplate, lock_template_versions?: LockTemplateVersion[]) {
        const query: SearchQueryOptions = this.getSearchOptions(lock_template.id, lock_template_versions);
        this.dataSource.filterBy(query.filters);
    }
}
