import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ConstantProperty} from "../../_models/constant-property";
import {ComponentType} from "../../_models/component-type";
import {EventType} from "../../_models/event-type";
import * as utils from "../../lib/utils";
import {
    CONSTANT_PROPERTY_RELATED_TYPE
} from "../../forms/constant-property-table-form/constant-property-table-form.component";
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
    ConstantPropertyTableFormService
} from "../../forms/constant-property-table-form/constant-property-table-service.service";

@Component({
    selector: 'formula-builder',
    templateUrl: './formula-builder.component.html',
    styleUrls: ['../../forms/constant-property-table-form/constant-property-table-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FormulaBuilderComponent implements OnInit, OnDestroy {
    @Input() selected_properties: ConstantProperty[];
    // Drop down list selections and corresponding selected item
    ct_constant_properties_list: { id: any, type: ComponentType; constant_property: ConstantProperty }[] = [];
    @Input() constant_property: ConstantProperty;

    constant_properties_list: ConstantProperty[] = [];
    formula_constant_property: ConstantProperty | {} = {};

    // @Output() submit_formula: EventEmitter<any> = new EventEmitter();
    ob_formula_constant_property: ConstantProperty | {} = {};
    ob_type_constant_properties_list: ConstantProperty[] = [];
    ct_formula_constant_property: { id: any, type: ComponentType; constant_property: ConstantProperty };
    et_constant_properties_list: { id: any, type: EventType; constant_property: ConstantProperty }[] = [];
    et_formula_constant_property: { id: any, type: EventType; constant_property: ConstantProperty };
    ct_constants_list: { id: any, type: ComponentType; constant_property: ConstantProperty }[] = [];
    constant_formula_constant_property: { id: any, type: ComponentType; constant_property: ConstantProperty }
    select_label: string = "properties";
    builder_ready: boolean = false;
    private readonly onDestroy: Subject<void> = new Subject<void>();

    constructor(private propertyService: ConstantPropertyTableFormService) {}

    _parent_component: CONSTANT_PROPERTY_RELATED_TYPE;

    get parent_component(): CONSTANT_PROPERTY_RELATED_TYPE {
        return this._parent_component;
    }

    @Input()
    set parent_component(component: CONSTANT_PROPERTY_RELATED_TYPE) {
        this._parent_component = component;
        if (component) {
            this.select_label += utils.label(utils.deepCopy(component.type));
        }
    }

    ngOnInit(): void {
        this.propertyService.$builder_ready.pipe(
            takeUntil(this.onDestroy),
            tap(ready => {
                if (ready) {
                    this.et_constant_properties_list = this.propertyService.et_constant_properties_list || [];
                    this.ct_constant_properties_list = this.propertyService.ct_constant_properties_list || [];
                    this.ct_constants_list = this.propertyService.ct_constants_list || [];
                    this.constant_properties_list = this.propertyService.constant_properties_list || []
                    this.ob_type_constant_properties_list = this.propertyService.ob_type_constant_properties_list || [];
                    this.builder_ready = true;
                }
            })).subscribe();

            this.propertyService.loadFormulaConstantPropertyLists(this.parent_component, this.selected_properties);
    }

    addToFormula(formula_constant_property, prefix = null) {
        if (formula_constant_property) {
            let new_entry = '';
            let type_prop = formula_constant_property.type?.attributes?.name + "@" + formula_constant_property.constant_property?.attributes?.name;
            if (prefix && (prefix === 'component' || prefix === 'component_type')) {
                new_entry = "[" + prefix + "@" + type_prop + "]";
            } else if (prefix && prefix === 'component@component_type') {
                new_entry = "[component@" + this.parent_component.attributes.name + "@" + type_prop + "]";
            } else {
                if (prefix) {
                    new_entry = "[" + prefix + '@' + formula_constant_property.attributes.name + "]";
                } else {
                    new_entry = "[" + formula_constant_property.attributes.name + "]";
                }
            }
            if (this.constant_property.attributes.name_formula == null) {
                this.constant_property.attributes.name_formula = new_entry;
            } else {
                this.constant_property.attributes.name_formula += " " + new_entry;
            }
        }
    }

    typePropertyString(value) {
        return value?.type?.attributes?.name + " - " + value?.constant_property?.attributes?.name
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
