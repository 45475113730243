import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {Series} from '../../_models/series';
import {forkJoin, of, Subject, Subscription, timer} from "rxjs";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {concatMap, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {SeriesDataService} from "../../services/series_data.service";
import {TreeNode} from "../../components/value-driver-tree/types/tree.node";
import {Tile as TileModel} from '../../_models/tile';
import {AppScope} from '../../services/app_scope.service';
import {AnalysisToolsService} from "./analysis-tools.service";
import {SingleResponse} from '../../services/api/response-types';
import {SeriesType} from "../../_models/series-type";
import {compareById, deepCopy} from '../../lib/utils';
import {ModelName} from '../../_typing/generic-types';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'analysis-tools',
    templateUrl: './analysis-tools.component.html',
    styleUrls: ['./analysis-tools.component.less'],
    providers: [HeaderDataService, AnalysisToolsService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AnalysisToolsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() selected_series: Series;
    @Input() estimate_type_id: string;
    @Output() update_url_emitter = new EventEmitter<any>();
    estimate_type_name: string = 'Plan';

    series_list: Series[];
    config: {
        series_table: TileModel;
        custom_chart: any;
        budget_chart: any;
        model_pivot_tile: any;
        performance_chart: any;
        category_chart: any;
        comments_tile: any;
        data_review_tile: any;
        vdt: any;
    } = {
        series_table: null,
        custom_chart: null,
        budget_chart: null,
        performance_chart: null,
        model_pivot_tile: null,
        comments_tile: null,
        category_chart: null,
        data_review_tile: null,
        vdt: null
    };
    dtp: IDateTimePeriod;
    selected_calculation: any;
    series_full: any[] = [];
    treeNodeMap: { [key: string]: TreeNode } = {};
    vdt_data_ready: boolean = false;
    formula_view: boolean = false;

    $dtpReset: Subscription;
    private readonly onDestroy = new Subject<void>();
    estimate_types: SeriesType[];
    estimate_type: SeriesType;
    series_type_names: ModelName[];
    selected_series_type: SeriesType;
    compareById = compareById;

    constructor(private appScope: AppScope,
                private api: ApiService,
                private seriesData: SeriesDataService,
                public dateInst: DateTimeInstanceService,
                private headerData: HeaderDataService,
                private cps: AnalysisToolsService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.dtpResetSubscribe();

        ctrl.seriesData.estimateTypesChanged.pipe(take(1), tap((result: SeriesType[]) => {
            this.estimate_types = result;
            this.series_type_names = result.map(e => e.attributes.name);
            if (this.estimate_type_id) {
                this.selected_series_type = result.find(est => est.id === this.estimate_type_id);
            } else {
                this.selected_series_type = result.find(est => est.attributes.name === 'Plan');
            }
            if (!this.selected_series_type) {
                this.selected_series_type = this.estimate_types[0];
                this.estimate_type_name = this.estimate_type.attributes.name;
            }
            this.cps.$estimate.next(this.selected_series_type);
        })).subscribe();

        this.tileRefreshSubscribe();
    }

    tileRefreshSubscribe() {
        this.headerData.refreshTileSubject.pipe(switchMap(tileId => {
            const key = Object.keys(this.config).find(k => {
                return this.config[k]?.id == tileId;
            })

            if (key) {
                const copy = deepCopy(this.config[key]);
                this.config[key]=null;
                return timer(100).pipe(tap(() => {
                    this.config[key] = copy;
                }))
            } else {
                return of(null);
            }
        }), takeUntil(this.onDestroy)).subscribe();
    }

    dtpResetSubscribe() {
        this.$dtpReset = this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.headerData.dtpReset.emit(dtp);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selected_series && changes.selected_series.currentValue) {
            this.config.performance_chart = null;
            this.config.category_chart = null;
            this.runAnalysis()
        }
    }

    changeEstimate(series_type_name: ModelName) {
        this.selected_series_type = this.estimate_types.find(t => t.attributes.name === series_type_name);
        this.estimate_type_name = this.selected_series_type.attributes.name;
        this.cps.$estimate.next(this.selected_series_type);
        this.update_url_emitter.next(this.selected_series_type.id); //Subscribed to in view
        this.runAnalysis();
    }

    runAnalysis() {
        this.config.series_table = this.cps.getSeriesDataTileConfig(this.selected_series, this.estimate_type_name);
        this.config.model_pivot_tile = this.cps.getPivotTileConfig(this.selected_series);
        this.config.comments_tile = this.cps.getCommentsConfig(this.selected_series);

        this.api.series.getById(this.selected_series.id).pipe(concatMap((res: SingleResponse<Series>) => {
            const series = res.data;

            let $bc = this.cps.getBudgetChartConfig(this.selected_series).pipe(map(tile => {
                return this.config.budget_chart = tile;
            }));

            let $rpc;
            if (['mean', 'mean_nan'].includes(series.attributes.aggregation) || series.relationships.weighted_average_series.data?.id) {
                $rpc = of(null);
            } else {
                $rpc = this.cps.getRelativePerformanceChartConfig(this.selected_series).pipe(map(tile => {
                    return this.config.performance_chart = tile;
                }))
            }

            let $cc
            if (series.attributes.aggregation !== 'total') {
                $cc = this.cps.getCategoryChartConfig(this.selected_series).pipe(map(tile => {
                    return this.config.category_chart = tile;
                }))
            } else {
                $cc = of(null);
            }

            return forkJoin([$bc, $rpc, $cc])
        })).subscribe();

        this.selected_calculation = this.series_full.find(calc => calc.id === this.selected_series.id);
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }
}
