import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    Output, EventEmitter, ChangeDetectorRef
} from '@angular/core';
import {ConstantProperty} from '../../../_models/constant-property';
import {ColumnFormats} from "../../../tables/table-utils.service";
import {ClearConstantService} from "../../constant-field/clear-constant.service";
import {ConstantField, isInvalid, InvalidField, isConstant, ConstantValue} from "../../../_models/api/generic-constant";

@Component({
    selector: 'generic-constant-field',
    templateUrl: './generic-constant-field.component.html',
    styleUrls: ['./generic-constant-field.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class GenericConstantFieldComponent {
    private _constant: ConstantField | InvalidField;
    @Input() set constant(constant: ConstantField | InvalidField) {
        this._constant = constant;
        if (constant) {
            this.setConstantAttributes();
        }
        this.changeDetectorRef.markForCheck();
    }

    get constant(): ConstantField | InvalidField {
        return this._constant;
    }

    @Input() constant_property: ConstantProperty;
    @Input() disabled: boolean;
    @Input() is_focussed: boolean;
    @Input() auto_class: string;
    @Input() tooltip: string;
    @Input() formats: ColumnFormats;
    @Input() defaultValue: ConstantValue = null;
    /**This (conditional_formats) is necessary because text-decoration can not be inherited from non text elements (e.g. div/td)**/
    @Input() conditional_formats: any;
    @Input() show_sig: boolean = true;
    @Input() placeholder: string = '';
    @Input() allow_fit: boolean;
    @Output() constant_changed: EventEmitter<any> = new EventEmitter();
    @Output() change_complete: EventEmitter<any> = new EventEmitter();
    @Output() lock_clicked: EventEmitter<void> = new EventEmitter();

    locked: boolean;
    invalid: boolean;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    setConstantAttributes(): void {
        if (isInvalid(this.constant)) {
            this.invalid = true;
            return;
        }
        this.isLocked();
    }

    isLocked(): void {
        if (!isConstant(this.constant)) return;
        this.locked = this.constant.locked;
        if (this.locked) {
            this.disabled = true;
        }
    }

    constantChanged(event): void {
        if (!isConstant(this.constant)) return; //Type check
        this.constant.value = event;
        this.constant_changed.emit(this.constant.value);
    }

    changeComplete(event?): void {
        if (!isConstant(this.constant)) return; //Type check
        this.change_complete.emit(this.constant.value);
    }

    constantChangedComplete(event): void {
        /*  Input event emitted when change is complete does not contain the value so using a separate function for
            datetime-picker and mat-select which emits the completed changed value in this case
        */
        this.constantChanged(event);
        this.changeComplete(event);
    }

    lockClicked(): void {
        this.lock_clicked.next();
    }
}
