import * as utils from '../lib/utils';
import {Injectable, Output, Directive} from "@angular/core";
import {ApiService} from "./api/api.service";
import {AppScope} from "./app_scope.service";
import {NotificationService} from "../services/notification.service";
import {ReplaySubject, BehaviorSubject, Subject} from 'rxjs';
import {map, take, takeUntil} from "rxjs/operators";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";

@Injectable()
export class EventTypeService {
    toggleComments = new Subject();

    constructor(private api: ApiService,
                private notification: NotificationService,
                private appScope: AppScope) {
    }

    getEventTypes() {
        const ctrl = this;
        return ctrl.api.event_type.searchMany().pipe(
            take(1),
            map(result => {
                if (!result) return;
                return result;
            })
        );
    }

    getConstantProperties() {
        const ctrl = this;
        return ctrl.api.constant_property_light.searchMany().pipe(
            take(1),
            map(result => {
                if (!result) return;
                return result;
            })
        );
    }

    getOreBodyTypes() {
        const ctrl = this;
        return ctrl.api.ore_body_type.searchMany().pipe(
            take(1),
            map(result => {
                if (!result) return;
                return result;
            })
        )
    }

    getComponentTypes(): any {
        const ctrl = this;
        return ctrl.api.component_type.searchMany().pipe(
            map(result => {
                if (!result) return;
                return result;
            })
        );
    }
}
