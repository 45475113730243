import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LockTemplate} from '../../_models/lock-template';
import {LockDataService} from "../../services/lock-data.service";
import {Component as WireComponent} from "../../_models/component";
import {Event as WireEvent} from "../../_models/event";
import {ConstantProperty} from '../../_models/constant-property';
import {KeyMap, ModelID} from '../../_typing/generic-types';
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {catchError, concatMap, first, take, takeUntil, tap} from 'rxjs/operators';
import {Observable, of, timer} from 'rxjs';
import {LockTemplateVersion} from "../../_models/lock-template-version";
import {GenericDataService} from "../../data/generic-data.service";
import {SingleResponse} from '../../services/api/response-types';
import {BaseComponent} from "../../shared/base.component";
import {MatTabGroup} from "@angular/material/tabs";
import {FormDialogService} from "../../services/form-dialog.service";
import {NotificationService} from "../../services/notification.service";

export interface DialogData {
    lock_template_ids: ModelID[];
    lock_template_versions?: LockTemplateVersion[];
    component?: WireComponent;
    event?: WireEvent;
    lock_version_dict?: KeyMap<ModelID>;
    constant_property?: ConstantProperty;
}

@Component({
    selector: 'lock-template-form-dialog',
    templateUrl: './lock-template-form-dialog.component.html',
    styleUrls: ['./lock-template-form-dialog.component.less'],
    standalone: false
})
export class LockTemplateFormDialogComponent extends BaseComponent implements AfterViewInit {

    lock_templates: LockTemplate[];
    lock_ids: ModelID[];
    lock_version_dict: KeyMap<ModelID>;
    selected_lock_template: LockTemplate;
    component: WireComponent;
    event: WireEvent;
    lock_template_versions: LockTemplateVersion[];
    selected_version: LockTemplateVersion;
    form_title: string = '';
    property_ids: ModelID[];
    property_filter: any[];
    attributes: string[];
    event_attribute_options: string[] = ['start', 'end'];
    component_attribute_options: string[] = ['name', 'start_time', 'end_time'];
    event_type: ModelID;
    component_type: ModelID;
    constant_property: ConstantProperty;
    version_tab_loaded = false;
    disabled = false;
    refresh_id = true;
    @ViewChild('lock_template_tabs') lock_template_tabs: MatTabGroup;

    constructor(private dialogRef: MatDialogRef<LockTemplateFormDialogComponent>,
                private notification: NotificationService,
                private lockDataService: LockDataService,
                private propertyDataService: ConstantPropertyDataService,
                private genericData: GenericDataService,
                private formDialog: FormDialogService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
        super();
    }

    ngOnInit() {
        this.component = this.dialogData.component;
        this.event = this.dialogData.event;
        this.lock_template_versions = this.dialogData.lock_template_versions;
        this.constant_property = this.dialogData.constant_property;
        this.lock_version_dict = this.dialogData.lock_version_dict;
        this.lock_ids = this.lock_version_dict ? Object.keys(this.lock_version_dict) : null;

        if (!this.dialogData.lock_template_ids) {
            this.lock_templates = [new LockTemplate()];
            this.lockTemplateSelected();
        } else {
            this.getLockTemplates();
        }
    }

    getLockTemplates() {
        this.lockDataService.getLockTemplates(this.dialogData.lock_template_ids).pipe(
            tap(result => {
                this.lock_templates = result.data;
                this.lockTemplateSelected();
            }),
            take(1), takeUntil(this.onDestroy)
        ).subscribe();
    }

    ngAfterViewInit() {
        if (this.component || this.event) {
            this.disabled = true;
            setTimeout(() => {
                this.lock_template_tabs.selectedIndex = 1;
            }, 800)
        }
    }

    tabChanged($event) {
        if ($event == 1) {
            this.version_tab_loaded = true;
        }
    }

    lockTemplateSelected(lock_template_id?: ModelID): void {
        this.selected_lock_template = lock_template_id ? this.lock_templates.find(lt => lt.id === lock_template_id) : this.lock_templates[0];

        if (this.selected_lock_template?.relationships.event_type?.data?.id) {
            this.updateConstantPropertyFilter('event_types', this.selected_lock_template.relationships.event_type.data?.id);
        } else {
            this.updateConstantPropertyFilter('component_types', this.selected_lock_template.relationships.component_type.data?.id);
        }
        this.property_ids = this.lockDataService.extractConstantPropertyIds([this.selected_lock_template]);
        this.attributes = this.selected_lock_template.attributes.definition.attributes;
    }

    attributesChanged($event): void {
        this.selected_lock_template.attributes.definition.attributes = $event.value;
    }

    constantPropertiesChanged(event) {
        this.selected_lock_template.attributes.definition.constant_properties = event.map(cp => cp.id || cp);
    }

    templateTypeChanged($event?) {
        if ($event.type === 'event_type') {
            this.selected_lock_template.relationships.event_type = {data: {id: $event.id, type: 'event_type'}};
            this.selected_lock_template.relationships.component_type.data = null;
            this.updateConstantPropertyFilter('event_types', $event.id);
        } else {
            this.selected_lock_template.relationships.component_type = {data: {id: $event.id, type: 'component_type'}};
            this.selected_lock_template.relationships.event_type = {data: null};
            this.updateConstantPropertyFilter('component_types', $event.id);
        }
        // Clear old properties
        this.constantPropertiesChanged([]);
    }

    private updateConstantPropertyFilter(rel_name, rel_id) {
        this.property_filter = this.propertyDataService.generatePropertiesByRelationshipIdsFilter(rel_name, [rel_id]);
    }

    save() {
        let $save: Observable<SingleResponse<LockTemplate>> =
            this.genericData.upsertModel<LockTemplate>('lock_template', this.selected_lock_template);
        $save.pipe(
            tap(() => {
                this.notification.openSuccess('Template saved', 3000 );
                this.dialogRef.close(this.selected_lock_template);
            }),
            catchError(e => {
                this.notification.openError('Unable to save template', 10000 );
                return of(e);
            }),
            first(),
        ).subscribe(() => {
        })
    }

    async unlockTemplate(version: LockTemplateVersion) {
        const versionId = version.id.toString();
        const lockIds = Object.keys(this.lock_version_dict).filter(key => this.lock_version_dict[key] === versionId);
        let model: 'component' | 'event' = version.attributes.schema.component_type_id ? 'component' : 'event';
        if (await this.formDialog.confirm("Are you sure you want to unlock this data?", "No", "Yes, I'm sure")) {
            this.lockDataService.unlock(model, lockIds).pipe(take(1),
                concatMap((result) => {
                    this.lock_template_versions = this.lock_template_versions.filter(v => v.id !== versionId);
                    this.refresh_id = false;
                    return timer(100).pipe(tap(() => {
                        this.refresh_id = true;
                    }), take(1));
                })).subscribe();
        }
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
