<div class="event-component-delete-check-dialog">
  <h1 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor:move;">WARNING!</h1>
  <div class="info row center justify-center">Select the rows you want to update.</div>
  <div class="info row center justify-center">Selected rows will result in links being updated or removed.</div>
  <mat-dialog-content>
    <ng-container *ngFor="let ct of component_types">
      <table class="simple-table" *ngIf="prop_column_dict[ct.id]">
        <thead>
        <tr>
          <th class="ct" [attr.colspan]="3 + (prop_column_dict[ct.id]?.length || 0)">
            <div>{{ct.name}}</div>
          </th>
        </tr>
        <tr style="height:30px;">
          <th class="td-checkbox">
            <mat-checkbox matTooltip="Include this column from pasted data"
                          (change)="includeColumn($event.checked, ct.id)"></mat-checkbox>
          </th>
          <th *ngFor="let cp of prop_column_dict[ct.id]">{{column_dict[cp.id].title || cp.attributes.name}}</th>
          <th class="label">Currently linked to</th>
          <th class="label" [ngClass]="{'link-remove-text': getLinkCount(ct.id, 'removed') || getLinkCount(ct.id)}">Will
            be linked to
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let d of component_data[ct.id]" [ngClass]="{'include': d.include}">
          <td class="td-checkbox">
            <mat-checkbox [ngModel]="d.include" (change)="includeCell($event.checked, d)"
                          matTooltip="Include this value in pasted data"></mat-checkbox>
          </td>
          <td *ngFor="let cp of prop_column_dict[ct.id]">{{d.prop_data[cp.id]}}</td>
          <td>{{d.current_value}}</td>
          <td [ngClass]="{'link-remove-text': d.include}">{{d.include && !d.pasted_value ? 'Link will be removed' : d.pasted_value}}</td>

        </tr>
        </tbody>
      </table>
      <div class="link-info">
        <div class="info row center justify-center error">
          {{getLinkCount(ct.id, 'updated') | matchTitleToCount : 'link':'links'}} will be
          updated
        </div>
        <div
            class="info row center justify-center error">{{getLinkCount(ct.id, 'removed') | matchTitleToCount : 'link':'links'}}
          will be removed
        </div>
      </div>
    </ng-container>


    <div class="row center justify-center buttons">
      <button (click)="continuePaste()"
              class="bordered-button green"
              mat-button
              style="align-self: center;margin-bottom: 14px;height: 40px;width:220px;">Continue
      </button>
      <button (click)="cancelPaste()"
              class="bordered-button grey"
              mat-button
              matTooltip="This will roll back the entire paste"
              style="align-self: center;margin-bottom: 14px;height: 40px;width:220px;">Cancel
      </button>
    </div>
  </mat-dialog-content>
</div>
