import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ComponentModelUploadDataService} from "../component-model-upload-data.service";
import {Subject} from "rxjs";

@Component({
    selector: 'component-model-upload-select-component-type-column',
    templateUrl: './component-model-upload-select-component-type-column.component.html',
    styleUrls: ['../extract-component-options.component.less'],
    standalone: false
})
export class ComponentModelUploadSelectComponentTypeColumnComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() readonly = false;
    @Input() componentTypeID: string;
    @Output() componentTypeIDChange = new EventEmitter<string>();

    compareWith: (a: any, b: any) => boolean;

    constructor(public dataService: ComponentModelUploadDataService) {
        this.compareWith = (a, b) => {
            return a === b;
        };
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setComponentType(componentTypeID: string) {
        this.componentTypeIDChange.next(componentTypeID);
    }
}
