import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CustomDialogComponent, CustomDialogData} from "../../../forms/custom-dialog/custom-dialog.component";
import {UserService} from "../../../services/user.service";
import {DateTimePeriodService} from "../../../services/date-time-period.service";
import {EventService} from "../../../services/event.service";
import {WireComponent} from "../../../_models/component";
import {Series} from "../../../_models/series";
import {NotificationService} from "../../../services/notification.service";
import {NOTIFICATION_DURATION_MS} from "../../../shared/globals";
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";

export interface CommentPromptData {
    prompt_message: string;
    comment_append: string;
    component_list: WireComponent[];
    series_list: Series[];
    ok_text: string;
    cancel_text: string;
}

@Component({
    selector: 'comment-prompt',
    templateUrl: './comment-prompt.component.html',
    styleUrls: ['./comment-prompt.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CommentPromptComponent implements OnInit {
    promptMessage: string = "Please add a comment";
    warningMessage: string = "Comment can not be empty."
    showWarning: boolean = false;
    okText: string = 'OK';
    cancelText: string = 'Cancel';
    seriesList: Series[];
    componentList: WireComponent[];
    commentAppend: string;
    comment: string = "";

    constructor(public dialogRef: MatDialogRef<any>,
                @Inject(MAT_DIALOG_DATA) private dialogData: Partial<CommentPromptData>,
                private dateInst: DateTimeInstanceService,
                private eventService: EventService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.promptMessage = this.dialogData.prompt_message;
        this.commentAppend = this.dialogData.comment_append;
        this.componentList = this.dialogData.component_list;
        this.seriesList = this.dialogData.series_list;
        this.okText = this.dialogData.ok_text || this.okText;
        this.cancelText = this.dialogData.cancel_text || this.cancelText;
        this.dialogRef.disableClose = true;
    }

    ok() {
        if (this.comment.trim() === "") {
            this.showWarning = true;
            return;
        }
        this.addComment();
    }

    addComment() {
        if (!this.comment) {
            return;
        }
        const dtp = this.dateInst.dtp;
        let comment = `${this.comment} ${this.commentAppend}`;
        this.eventService.addInlineComment(comment, dtp.start, dtp.end, this.seriesList, this.componentList)
            .subscribe((result) => {
                    if (!result)  return;
                    this.dialogRef.close(true);
                },
                error => {
                    this.notification.openError("Comment not saved.", NOTIFICATION_DURATION_MS, 'Close');
                });
    }

    cancel() {
        this.dialogRef.close(false)
    }

}
