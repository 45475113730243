<mat-dialog-content class="ore-body-group-form">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Basic">
      <form class="form-section column space-between basic">
        <div class="row">

          <select-search *ngIf="filtered_obgs && filtered_obgs.length > 0"
                         [(value)]="ore_body_group"
                         [classes]="'fill'"
                         [data]="filtered_obgs"
                         [fill_stubs]="true"
                         [label]="'Select an Ore Body Group'"
                         class="left">
          </select-search>

          <select-search (selectChange)="obgtChanged()"
                         *ngIf="selected_obgt"
                         [(value)]="selected_obgt"
                         [classes]="'fill'"
                         [data]="ore_body_group_types"
                         [fill_stubs]="true"
                         [label]="'Select a resource to filter by'"
                         class="right">
          </select-search>
        </div>

        <!--        <mat-divider style="margin:10px;"></mat-divider>-->
        <!--        <div class="row">-->
        <!--          <label>Quick View Ore Bodies</label>-->
        <!--        </div>-->
        <!--        <div class="row">-->
        <!--          <select-search *ngIf="ore_body_group && ore_bodies && ore_bodies.length > 0"-->
        <!--                         [(value)]="selected_ore_body"-->
        <!--                         [classes]="'fill'"-->
        <!--                         [data]="ore_body_group.relationships.ore_bodies.data"-->
        <!--                         [fill_stubs]="true"-->
        <!--                         [label]="'Select an Ore Body to view'"-->
        <!--                         class="left">-->
        <!--          </select-search>-->
        <!--        </div>-->
        <!--changing image not yet supported-->
        <!--        <div class="row">-->
        <!--          <mat-form-field (mouseenter)="hint='Icon'" style="margin-right:0;">-->
        <!--            <label style="display: flex; align-items: center; margin-bottom: -30px;height: 50px;">Select Icon-->
        <!--              <img *ngIf="component_data.src" height="50px" src="{{ component_data.src }}" width="50px"/></label>-->
        <!--            &lt;!&ndash;<label>Select Icon<img src="{{ component_data.attributes.icon }}" width="30px" height="30px"/></label>&ndash;&gt;-->
        <!--            <mat-select (selectionChange)="change(component_data.src)" [(ngModel)]="component_data.src"-->
        <!--                        class="image-select" name="process_icon">-->
        <!--              <mat-option *ngFor="let img of images" [value]="img" class="image-options"-->
        <!--                          style="display:flex;align-items: center;">-->
        <!--                <img height="50px" src="{{ img }}" width="50px"/>-->
        <!--                <span>{{ img.split("/")[img.split("/").length - 1].split('.')[0] }}</span>-->
        <!--              </mat-option>-->
        <!--            </mat-select>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->


      </form>
    </mat-tab>

  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>
