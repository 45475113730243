import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorHandler} from '../http-error-handler.service';
import {Router} from "@angular/router";
import {Model} from "./model";
import {CacheManager} from "./caching/cacheManager";
import {Process} from "../../_models/process";
import {Feature} from "../../_models/feature";
import {ProcessAccess} from "../../_models/process-access";
import {Connector} from "../../_models/connector";
import {Stream} from "../../_models/stream";
import {Series} from "../../_models/series";
import {SeriesComponent} from "../../_models/series-component";
import {ConstantComponent} from "../../_models/constant-component";
import {ConstantPropertyComponentType} from "../../_models/constant-property-component-type";
import {ConstantPropertyEventType} from "../../_models/constant-property-event-type";
import {ConstantProperty} from "../../_models/constant-property";
import {OreBodyType} from "../../_models/ore-body-type";
import {OreBodyGroup} from "../../_models/ore-body-group";
import {OreBodyGroupType} from "../../_models/ore-body-group-type";
import {OreBody} from "../../_models/ore-body";
import {EventType} from "../../_models/event-type";
import {Equipment, EquipmentLight} from "../../_models/equipment";
import {Tile} from "../../_models/tile";
import {ParserConfigTemplate} from "../../_models/parser-config-template";
import {EventTypeOreBodyTypeMap} from "../../_models/event-type-ore-body-type-map";
import {Alert} from "../../_models/alert";
import {RawData} from "../../_models/raw-data";
import {SessionState} from "../../_models/session-state";
import {PendingContext} from "../../_models/pending-context";
import {PrintoutComponentType} from "../../_models/printout-component-type";
import {Event} from "../../_models/event";
import {SeriesSummaries} from "../../_models/api/series-summary";
import {Component} from "../../_models/component";
import {Group} from '../../_models/group';
import {LockTemplate} from "../../_models/lock-template";
import {LockTemplateVersion} from '../../_models/lock-template-version';
import {Printout, PrintoutLight} from "../../_models/utils/printout";
import {UserPreference} from '../../_models/user-preference';
import {SeriesSeries} from "../../_models/series-series";
import {ComponentLock} from '../../_models/component-lock';
import {Constraint, SolverTemplate} from '../../_models/solver';
import {UserModel} from "../../_models/users";
import {Role} from "../../_models/role";
import {DateTimePeriod} from '../../_typing/date-time-period';
import {SeriesCorrectionJob} from "../../_models/jobs/series-correction-job";
import {CustomFunction} from "../../_models/custom-function";
import {ShiftVersion} from "../../_models/shift_version";
import {ShiftType} from "../../_models/shift_type";
import {FolderSessionState} from "../../_models/folder-session-state";
import {Folder} from "../../_models/folder";

@Injectable({
    providedIn: 'root'
})
export class ApiService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private onCancelActiveQueries = new Subject<void>();

    account: Model;
    activity: Model;
    alerts: Model<Alert>;
    audited_raw_data: Model;
    calculation_light: Model;
    collector: Model;
    collector_event: Model;
    comment: Model;
    component: Model<Component>;
    component_component: Model;
    component_constant_collation_series_export: Model;
    component_lock: Model<ComponentLock>;
    component_type: Model;
    component_type_component_type: Model;
    component_type_constant: Model;
    configuration: Model;
    connector: Model<Connector>;
    constant_component: Model<ConstantComponent>;
    constant_component_light: Model;
    constant_property: Model<ConstantProperty>;
    constant_property_light: Model;
    constant_property_formula_light: Model;
    constant_property_component_type: Model<ConstantPropertyComponentType>;
    constant_property_event_type: Model<ConstantPropertyEventType>;
    correction_factor: Model;
    constraint: Model<Constraint>;
    custom_function: Model<CustomFunction>;
    custom_time_period: Model;
    data_exception: Model;
    downtime: Model;
    engineering_unit: Model;
    equipment: Model<Equipment>;
    equipment_light: Model<EquipmentLight>;
    event: Model<Event>;
    event_light: Model;
    event_lock: Model;
    event_component_light: Model;
    event_component: Model;
    event_constant: Model;
    event_constant_collation_series_export: Model;
    event_type: Model<EventType>;
    event_type_light: Model;
    event_type_component_type_map: Model;
    event_type_ore_body_type_map: Model<EventTypeOreBodyTypeMap>;
    feature: Model<Feature>;
    folder: Model<Folder>;
    folder_session_state: Model<FolderSessionState>;
    forecast_calculation: Model;
    group: Model<Group>;
    group_page: Model;
    group_user: Model;
    // TODO add support for the other job types here using a union type.
    job: Model<SeriesCorrectionJob>;
    lock_template: Model<LockTemplate>;
    lock_template_version: Model<LockTemplateVersion>;
    mapper: Model;
    ore_body: Model<OreBody>;
    ore_body_light: Model;
    ore_body_type: Model<OreBodyType>;
    ore_body_type_constant_property: Model;
    ore_body_group: Model<OreBodyGroup>;
    ore_body_group_type: Model<OreBodyGroupType>;
    ore_body_geometry: Model;
    parser_config_template: Model<ParserConfigTemplate>;
    pending_context: Model<PendingContext>;
    period_type: Model;
    printout: Model;
    printout_v2: Model<Printout>;
    printout_light: Model<PrintoutLight>;
    printout_component_type: Model<PrintoutComponentType>;
    process: Model<Process>;
    process_light: Model;
    process_access: Model<ProcessAccess>;
    property_category: Model;
    raw_data: Model<RawData>;
    resource: Model;
    resource_light: Model;
    revision: Model;
    role: Model<Role>;
    schedule: Model;
    series: Model<Series>;
    series_component: Model<SeriesComponent>;
    series_light: Model;
    series_series: Model<SeriesSeries>;
    series_type: Model;
    session_state: Model<SessionState>;
    session_state_light: Model;
    shift: Model;
    shift_type: Model<ShiftType>;
    shift_version: Model<ShiftVersion>;
    solver_template: Model<SolverTemplate>;
    stream: Model<Stream>;
    tile: Model<Tile>;
    transport: Model;
    users: UserModel;
    user_meta: Model;
    user_page: Model;
    user_preference: Model<UserPreference>;

    mapApis: {
        name: string,
        url: string,
        cache: boolean,
        cache_name?: string,
        mapper_name?: string,
        cache_timeout?: number,
        audited?: boolean
    }[] = [
        {name: 'account', url: '/api/account', cache: true, cache_name: 'account'},
        {name: 'activity', url: '/api/activity', cache: false, cache_name: 'activity'},
        {
            name: 'alerts', url: '/api/alerts', cache: false, cache_name: 'alerts'
        },
        {
            name: 'audited_raw_data',
            url: '/api/audited_raw_data',
            cache: false,
            cache_name: 'raw_data',
        },
        {
            name: 'calculation_light',
            url: '/api_min/calculation_light',
            cache: true,
            cache_name: 'calculation_light',
        },
        {
            name: 'collector',
            url: '/api/collector',
            cache: false,
            cache_name: 'collector',
            audited: true
        },
        {
            name: 'collector_event',
            url: '/api/collector_event',
            cache: false,
            cache_name: 'collector_event',
        },
        {
            name: 'comment',
            url: '/api/comment',
            cache: true,
            cache_name: 'comment',
            audited: true
        },
        {
            name: 'component',
            url: '/api/component',
            cache: true,
            cache_name: 'component',
            audited: true
        },
        {
            name: 'component_component',
            url: '/api/component_component',
            cache: true,
            cache_name: 'component_component',
            audited: true
        },
        {
            name: 'component_constant_collation_series_export',
            url: '/api/component_constant_collation_series_export',
            cache: true,
            cache_name: 'component_constant_collation_series_export',
            audited: true
        },
        {
            name: 'component_lock',
            url: '/api/component_lock',
            cache: true,
            cache_name: 'component_lock',
            audited: true
        },
        {
            name: 'component_type',
            url: '/api/component_type',
            cache: true,
            cache_name: 'component_type',
            audited: true
        },
        {
            name: 'component_type_component_type',
            url: '/api/component_type_component_type',
            cache: true,
            cache_name: 'component_type_component_type',
            audited: true
        },
        {
            name: 'component_type_constant',
            url: '/api/component_type_constant',
            cache: true,
            cache_name: 'component_type_constant',
            audited: true
        },
        {
            name: 'configuration',
            url: '/api/configuration',
            cache: true,
            cache_name: 'configuration',
        },
        {
            name: 'connector',
            url: '/api/connector',
            cache: true,
            cache_name: 'connector',
            audited: true
        },
        {
            name: 'constant_component',
            url: '/api/constant_component',
            cache: false,
            cache_name: 'constant_component',
            audited: true
        },
        {
            name: 'constant_component_light',
            url: '/api_min/constant_component_light',
            cache: false,
            cache_name: 'constant_component_light',
            audited: true
        },
        {
            name: 'constant_property',
            url: '/api/constant_property',
            cache: true,
            cache_name: 'constant_property',
            audited: true
        },
        {
            name: 'constant_property_light',
            url: '/api_min/constant_property_light',
            cache: true,
            cache_name: 'constant_property_light',
        },
        {
            name: 'constant_property_formula_light',
            url: '/api_min/constant_property_formula_light',
            cache: true,
            cache_name: 'constant_property_formula_light',
        },
        {
            name: 'constant_property_component_type',
            url: '/api/constant_property_component_type',
            cache: false,
            cache_name: 'constant_property_component_type',
            audited: true
        },
        {
            name: 'constant_property_event_type',
            url: '/api/constant_property_event_type',
            cache: false,
            cache_name: 'constant_property_event_type',
            audited: true
        },
        {
            name: 'constraint',
            url: '/api/constraint',
            cache: true,
            cache_name: 'constraint',
            audited: true
        },
        {
            name: 'correction_factor',
            url: '/api/correction_factor',
            cache: false,
            cache_name: 'correction_factor',
            audited: true
        },
        {
            name: 'custom_time_period',
            url: '/api/custom_time_period',
            cache: true,
            cache_name: 'custom_time_period',
            audited: true
        },
        {
            name: 'custom_function',
            url: '/api/custom_function',
            cache: true,
            cache_name: 'custom_function',
            audited: true
        },
        {name: 'data_exception', url: '/api/data_exception', cache: true, cache_name: 'data_exception'},
        {name: 'downtime', url: '/api/downtime', cache: true, cache_name: 'downtime', audited: true},
        {
            name: 'engineering_unit',
            url: '/api/engineering_unit',
            cache: true,
            cache_name: 'engineering_unit',
        },
        {
            name: 'equipment',
            url: '/api/equipment',
            cache: false,
            cache_name: 'equipment',
            audited: true
        },
        {
            name: 'equipment_light',
            url: '/api_min/equipment_light',
            cache: false,
            cache_name: 'equipment_light',
            audited: true
        },
        {name: 'event', url: '/api/event', cache: true, cache_name: 'event', audited: true},
        {
            name: 'event_light',
            url: '/api_min/event_light',
            cache: true,
            cache_name: 'event_light',
        },
        {
            name: 'event_component_light',
            url: '/api_min/event_component_light',
            cache: true,
            cache_name: 'event_component_light',
        },
        {
            name: 'event_component',
            url: '/api/event_component',
            cache: true,
            mapper_name: 'name',
            cache_name: 'event_component',
            audited: true
        },
        {
            name: 'event_lock',
            url: '/api/event_lock',
            cache: true,
            cache_name: 'event_lock',
            audited: true
        },
        {
            name: 'event_constant',
            url: '/api/event_constant',
            cache: true,
            mapper_name: 'name',
            cache_name: 'event_constant',
            audited: true
        },
        {
            name: 'event_constant_collation_series_export',
            url: '/api/event_constant_collation_series_export',
            cache: true,
            mapper_name: 'name',
            cache_name: 'event_constant_collation_series_export',
            audited: true
        },
        {
            name: 'event_type',
            url: '/api/event_type',
            cache: true,
            mapper_name: 'name',
            cache_name: 'event_type',
            audited: true
        },
        {
            name: 'event_type_light',
            url: '/api_min/event_type_light',
            cache: true,
            cache_name: 'event_type_light'
        },
        {
            name: 'event_type_ore_body_type_map',
            url: '/api/event_type_ore_body_type_map',
            cache: false,
            cache_name: 'event_type_ore_body_type_map'
        },
        {
            name: 'event_type_component_type_map',
            url: '/api/event_type_component_type_map',
            cache: false,
            cache_name: 'event_type_component_type_map'
        },

        {name: 'feature', url: '/api/feature', cache: true, cache_name: 'feature'},
        {
            name: 'folder',
            url: '/api/folder',
            cache: false,
            cache_name: 'folder',
        },
        {
            name: 'folder_session_state',
            url: '/api/folder_session_state',
            cache: false,
            cache_name: 'folder_session_state',
        },
        {
            name: 'forecast_calculation',
            url: '/api/forecast_calculation',
            cache: false,
            cache_name: 'forecast_calculation',
        },
        {
            name: 'group',
            url: '/api/group',
            cache: false,
            cache_name: 'group',
        },
        {
            name: 'group_page',
            url: '/api/group_page',
            cache: false,
            cache_name: 'group_page',
        },
        {
            name: 'group_user',
            url: '/api/group_user',
            cache: false,
            cache_name: 'group_user',
        },
        {name: 'job', url: 'api/job', cache: false},
        {
            name: 'lock_template',
            url: '/api/lock_template',
            cache: true,
            mapper_name: 'name',
            cache_name: 'lock_template',
            audited: true
        },
        {
            name: 'lock_template_version',
            url: '/api/lock_template_version',
            cache: true,
            mapper_name: 'name',
            cache_name: 'lock_template_version',
            audited: true
        },
        {name: 'mapper', url: '/api/mapper', cache: false, cache_name: 'mapper', audited: true},
        {
            name: 'ore_body',
            url: '/api/ore_body',
            cache: false,
            cache_name: 'ore_body',
            audited: true
        },
        {
            name: 'ore_body_light',
            url: '/api_min/ore_body_light',
            cache: true,
            cache_name: 'ore_body_light',
        },
        {
            name: 'ore_body_type_constant_property',
            url: '/api/ore_body_type_constant_property',
            cache: false,
            cache_name: 'ore_body_type_constant_property',
            audited: true
        },
        {
            name: 'ore_body_type',
            url: '/api/ore_body_type',
            cache: false,
            cache_name: 'ore_body_type',
            audited: true
        },
        {
            name: 'ore_body_group',
            url: '/api/ore_body_group',
            cache: false,
            cache_name: 'ore_body_group',
            audited: true
        },
        {
            name: 'ore_body_group_type',
            url: '/api/ore_body_group_type',
            cache: false,
            cache_name: 'ore_body_group_type',
            audited: true
        },
        {
            name: 'ore_body_geometry',
            url: '/api/ore_body_geometry',
            cache: false,
            cache_name: 'ore_body_geometry',
            audited: true
        },
        {
            name: 'user_page',
            url: '/api/user_page',
            cache: false,
            cache_name: 'user_page'

        },
        {
            name: 'parser_config_template',
            url: '/api/parser_config_template',
            cache: false,
            cache_name: 'parser_config_template'

        },
        {
            name: 'pending_context',
            url: '/api/pending_context',
            cache: false,
            cache_name: 'pending_context',
            audited: true
        },
        {
            name: 'period_type',
            url: '/api/period_type',
            cache: true,
            cache_name: 'period_type',
        },
        {
            name: 'process',
            url: '/api/process',
            cache: true,
            cache_name: 'process',
            audited: true
        },
        {
            name: 'printout',
            url: '/api/printout',
            cache: true,
            cache_name: 'printout',
            audited: true
        },
        {
            name: 'printout_v2',
            url: '/api/v2/printout',
            cache: true,
            cache_name: 'printout_v2',
            audited: true
        },
        {
            name: 'printout_light',
            url: '/api/printout_light',
            cache: true,
            cache_name: 'printout_light',
            audited: true
        },
        {
            name: 'printout_component_type',
            url: '/api/printout_component_type',
            cache: true,
            cache_name: 'printout_component_type',
            audited: true
        },
        {
            name: 'process_light',
            url: '/api_min/process_light',
            cache: true,
            cache_name: 'process_light',
            audited: true
        },
        {
            name: 'process_access',
            url: '/api/process_access',
            cache: false,
            cache_name: 'process_access',
            audited: true
        },
        {
            name: 'property_category',
            url: '/api/property_category',
            cache: true,
            cache_name: 'property_category',
            audited: true
        },
        {name: 'raw_data', url: '/api/raw_data', cache: false, cache_name: 'raw_data'},
        {
            name: 'resource',
            url: '/api/resource',
            cache: true,
            cache_name: 'resource',
            audited: true
        },
        {
            name: 'resource_light',
            url: '/api_min/resource_light',
            cache: true,
            cache_name: 'resource_light',
            audited: true
        },
        {
            name: 'revision',
            url: '/api/revision',
            cache: true,
            cache_name: 'revision',
            audited: true
        },
        {name: 'role', url: '/api/role', cache: true, mapper_name: 'name', cache_name: 'role'},
        {name: 'schedule', url: 'api/schedule', cache: true, cache_name: 'schedule'},
        {name: 'series', url: '/api/series', cache: false, cache_name: 'series', audited: true},
        {
            name: 'series_component',
            url: '/api/series_component',
            cache: false,
            cache_name: 'series_component',
            audited: true
        },
        {
            name: 'series_light',
            url: '/api_min/series_light',
            cache: true,
            cache_name: 'series_light',
        },
        {
            name: 'series_series',
            url: '/api/series_series',
            cache: true,
            cache_name: 'series_series',
            audited: true
        },
        {name: 'series_type', url: '/api/series_type', cache: true, cache_name: 'series_type'},
        {
            name: 'session_state',
            url: '/api/session_state',
            cache: true,
            mapper_name: 'report',
            cache_name: 'session_state',
            audited: true
        },
        {
            name: 'session_state_light',
            url: '/api_min/session_state_light',
            cache: true,
            cache_name: 'session_state_light',
        },
        {name: 'shift', url: '/api/shift', cache: true, cache_name: 'shift'},
        {name: 'shift_type', url: '/api/shift_type', cache: true, cache_name: 'shift_type'},
        {name: 'shift_version', url: '/api/shift_version', cache: true, cache_name: 'shift_version'},
        {
            name: 'solver_template',
            url: '/api/solver_template',
            cache: true,
            cache_name: 'solver_template',
            audited: true
        },
        {
            name: 'solver_template_series',
            url: '/api/solver_template_series',
            cache: true,
            cache_name: 'solver_template_series',
            audited: true
        },
        {
            name: 'stream',
            url: '/api/stream',
            cache: false,
            cache_name: 'stream',
            audited: true
        },
        {name: 'transport', url: '/api/transport', cache: false, cache_name: 'transport'},
        {name: 'tile', url: '/api/tile', cache: false, cache_name: 'tile'},
        {
            name: 'user_meta',
            url: '/api/user_meta',
            cache: true,
            cache_name: 'user_meta',
            audited: true
        },
        {
            name: 'user_preference',
            url: '/api/user_preferences',
            cache: true,
            cache_name: 'user_preference',
            audited: true
        },
        {
            name: 'report_template',
            url: '/api/report_template',
            cache: true,
            cache_name: 'report_template',
        },
    ];

    /**
     * Prepares a search queries for the REST API
     *
     * https://github.com/jfinkels/flask-restless/tree/master/docs
     * JSON spec defined at: https://jsonapi.org/format
     *
     * @deprecated Use SearchQueryOptions instead and pass to searchSingle or searchMany respectively
     * */
    prep_q(filters?, extra?, sort   ?: string | null) {
        if (extra == null) {
            extra = {};
        }

        if (filters != null) {
            extra['filter'] = JSON.stringify(filters);
        }
        if (sort != null) {
            extra['sort'] = sort;
        }
        return extra;
    }

    constructor(private http: HttpClient,
                private router: Router,
                private httpErrorHandler: HttpErrorHandler) {
        let cls = this;
        const cacheManager = new CacheManager();
        this.mapApis.forEach(mapapi => {
            const model = new Model(http, httpErrorHandler, mapapi.url, mapapi.name, cacheManager, mapapi.cache_name, mapapi.cache,
                mapapi.cache_timeout);
            cls[mapapi.name] = model;
            this.onCancelActiveQueries.pipe(takeUntil(this.onDestroy)).subscribe(() => {
                model.cancelActiveQueries();
            });
        });
        // Dealing with users separately as they will go through keycloak
        this.users = new UserModel(http, httpErrorHandler, cacheManager);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
        this.onCancelActiveQueries.unsubscribe();
    }

    cancelActiveQueries() {
        this.onCancelActiveQueries.next();
    }

    /**
     * Wrapper for the HttpClient get. This is used so requests can be cancelled from a single place on page navigation.
     * */
    get(url, options?): Observable<any> {
        if (options) {
            return this.http.get(url, options).pipe(takeUntil(this.onCancelActiveQueries));
        }
        return this.http.get(url).pipe(takeUntil(this.onCancelActiveQueries));
    }

    get_series_data(params): Observable<any> {
        return this.get('/api/GetData', {
            params: params
        });
    }

    get_series_summary(params: { [key: string]: any } | string): Observable<SeriesSummaries> {
        if (typeof params === 'string') {
            return this.get('/api/GetSeriesSummary', {
                params: {
                    tile_id: params
                }
            });
        }
        if (!(params.hasOwnProperty('process') ||
            (params.hasOwnProperty('series_list') && params.series_list.length >= 1))) {
            console.warn('didn\'t have series for gss');
            return of([]);
        }
        return this.get('/api/GetSeriesSummary', {
            params: params
        });
    }

    getReportTemplate(tileId: string, dateTimePeriod: DateTimePeriod): Observable<{ html: string }> {
        const seriesQuery = {
            start: dateTimePeriod.start.toISOString(),
            end: dateTimePeriod.end.toISOString(),
            period_type: dateTimePeriod.calendar,
            estimate: ['forecast'],
            sample_period: dateTimePeriod.sample_period.wire_sample,
        };

        return this.get('/api/report_template', {

            params: {
                tile_id: tileId,
                series_query: JSON.stringify(seriesQuery)
            }
        });
    }
}
