<form (ngSubmit)="onDetailsSubmit()" [formGroup]="detailForm" class="column">
    <label>Username or email</label>
    <mat-form-field class="form-input-field no-label">
        <input matInput formControlName="username"
               [class.valid]="formControl.dirty && !formControl.hasError('required')"
               [class.error]="formControl.dirty && formControl.hasError('required')"
               id="username" type="text"/>
      <mat-error *ngIf="formControl.invalid
        && formControl.hasError('required')">
          This field is required
        </mat-error>
    </mat-form-field>

    <div class="buttons">
        <wire-button [btnContent]="'Submit'" [btnStyle]="'btn-default-full'"></wire-button>
    </div>
</form>
