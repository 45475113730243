import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE, DEFAULT} from "./shared-types";

export class FolderAttributes extends AuditMixinAttributes {
    name: string;
    description?: string;
    order: number;
}

export class FolderRelationships extends AuditMixinRelationships {
    session_states: LIST_RELATIONSHIP_TYPE;
    parent: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class Folder extends AuditMixin {
    attributes: FolderAttributes;
    id: string;
    type: string = 'folder';
    relationships: FolderRelationships;

    constructor() {
        super();
        this.attributes = new FolderAttributes();
        this.relationships = new FolderRelationships();
    }
}
