import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {forkJoin, Observable, Subject} from "rxjs";
import {RuleSet} from 'ngx-angular-query-builder';
import {ComponentType} from "../../_models/component-type";
import {first, take, takeUntil, tap} from "rxjs/operators";
import * as utils from "../../lib/utils";
import {ApiService} from '../../services/api/api.service';
import {ConstantProperty} from '../../_models/constant-property';
import {CustomEventsService} from '../../services/custom-events.service';
import {TileDataService} from '../../services/tile_data.service';
import {Component as WireComponent} from "../../_models/component";
import {differenceBy as _differenceBy} from "lodash-es";
import {ExportComponentDialogComponent} from "../export-component-dialog/export-component-dialog.component";
import {MatDialog} from '@angular/material/dialog';
import {MatTable} from '@angular/material/table';
import {ColumnFormatsConfigDict} from '../table-column-menu/table-column-menu.component';
import {ListResponse} from '../../services/api/response-types';
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {ConditionalFormattingConfig} from "../../tables/conditional-formatting.service";
import {ConfigStub} from "../../_typing/config-stub";
import {LockTemplate} from "../../_models/lock-template";
import {getRelationWithAttributeFilter, getRelationWithIdFilter} from "../../services/api/filter_utils";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {CustomEventsFormService} from "../custom-events-form/custom-events-form.service";
import {IDMap, KeyMap, ModelID} from '../../_typing/generic-types';
import {EventColumnSelectorEmitter} from "../../components/event-column-selector/event-column-selector.component";
import {IPendingContextConfig} from "../pending-context-form/pending-context-form.component";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {IComponentTypeNameFormulaConfig} from "../../_typing/config/component-form-config";
import {ComponentNameFormulaBuilderService} from "../../services/component-name-formula-builder.service";
import {BeforeSaveAction} from "../../_typing/utils/before-save";
import {FILE_DOWNLOAD_OPTIONS, FileDownloadOption} from "../../_typing/download-filetype";
import {WireRuleSet} from "../../_typing/query-builder";
import {PrintoutComponentType} from "../../_models/printout-component-type";
import {PrintoutDataService} from "../../data/printout-data.service";
import {Printout} from "../../_models/utils/printout";
import {compareValueToId} from "../../lib/utils";
import {getComponentTypeIds} from "../../tables/custom-events-table/utils";
import {
    AttributeTimeConfig,
    ConfigTypeDateFilter,
    ConstantPropertyTimeConfig
} from "../../_typing/config/config-type-date-filter";
import {ConfigDateFilterService} from "../../services/config-date-filter.service";

export interface ExportComponentConfig {
    export_name: string;
    display_text: string;
    supports_bulk_export: boolean;
    supports_row_export: boolean;
}

export interface ISelectedColsType {
    'component_type': EventConfigColumn[];
    'event': EventConfigColumn[];
    'component': KeyMap<EventConfigColumn[]>;
}

interface RelationshipsAttributes {
    show: boolean;
    unique_property: ModelID;
    pending_context: IPendingContextConfig;
}

interface IComponentEventsConfig extends IComponentTypeNameFormulaConfig {
    columns: any[];
    can_create_component: boolean;
    selected_component_type: ConfigStub<ComponentType>;
    selected_component_relationship_types: ComponentType[];
    component: WireComponent;
    selected_cols: ISelectedColsType;
    relationships: Record<string, Partial<RelationshipsAttributes>>;
    start_prop: string;
    end_prop: string;
    component_type_date_filters: Partial<ConfigTypeDateFilter>;
    printout: boolean;
    search: boolean;
    search_by_name: boolean;
    constant_property_time: boolean;
    name_must_contain: string;
    filter_by_created_on: boolean;
    exclude_null_start: boolean;
    exclude_null_end: boolean;
    bypass_limits_formatting: boolean;
    bypass_date_filters: boolean;
    bypass_limits_validation: boolean;
    allow_unlink: boolean;
    allow_unlink_components: boolean;
    column_formats: ColumnFormatsConfigDict;
    component_column_formats: Record<string, ColumnFormatsConfigDict>;
    event_column_formats: Record<string, ColumnFormatsConfigDict>;
    show_aggregations: boolean;
    show_aggregations_top: boolean;
    show_audit: boolean;
    show_calc_update_status: boolean;
    page_size: number;
    query: RuleSet;
    filter: RuleSet;
    enable_row_selection: boolean;
    enable_export_component: ExportComponentConfig[];
    constrain_width: boolean;
    scroll_top: boolean;
    download_file_type: FileDownloadOption;
    conditional_formats: ConditionalFormattingConfig[];
    lock_template: ConfigStub<LockTemplate>;
    printouts: ModelID[];
    validation: WireRuleSet | null;
}

export type COMPONENT_EVENTS_CONFIG = Partial<IComponentEventsConfig>;

export type ComponentEventsColumnType = ComponentType | ConstantProperty;
export type ComponentEventsConfigColumnKey = 'component_type' | 'event' | 'component';

@Component({
    selector: 'component-events-table-form',
    templateUrl: './component-events-table-form.component.html',
    styleUrls: ['./component-events-table-form.component.less', '../series-table-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CustomEventsFormService],
    standalone: false
})
export class ComponentEventsTableFormComponent implements OnInit, OnDestroy, BeforeSaveAction {
    private readonly onDestroy = new Subject<void>();

    hint: string;
    component_type_stub_schema = {
        id: null,
        attributes: {
            name: '',
            description: null,
            base_type: null
        },
        type: 'component_type'
    };
    supported_export_handlers: string[];

    columnDict: IDMap<ComponentEventsColumnType> = {};
    constantProperties: ConstantProperty[];
    componentTypeComponentTypes: ComponentType[];
    date_properties: ConstantProperty[];
    event_properties: ConstantProperty[];
    component_relationship_filters: any[];

    avail_cols: EventConfigColumn[];
    selected_cols: EventConfigColumn[];
    avail_event_cols: EventConfigColumn[];
    avail_component_cols: KeyMap<EventConfigColumn[]> = {};
    disabled_cols: EventConfigColumn[] = [];
    required_cols: EventConfigColumn[] = [];
    disabled_event_cols: EventConfigColumn[] = [];
    required_event_cols: EventConfigColumn[] = [];
    disabled_component_cols: KeyMap<EventConfigColumn[]> = {};
    required_component_cols: KeyMap<EventConfigColumn[]> = {};
    base_cols = ['name', 'changed_by', 'start_time', 'end_time'];
    base_event_cols = ['start', 'end', 'comment', 'changed_by'];
    non_editable_fields = ["changed_by"];

    component_type_formats_dict: ColumnFormatsConfigDict = {};
    event_formats_dict: ColumnFormatsConfigDict = {};
    component_formats_dict: Record<string, ColumnFormatsConfigDict> = {};
    event_file_download_options: any[];
    dropped = false;
    lockTemplateFilter = [];
    printoutOptions: Printout[] = [];
    selectedPrintouts: Printout[] = [];
    cpDateFilterDict: IDMap<any[]> = {};

    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    /**
     * Note: The naming here might be a bit confusing as this component was added on to iteratively.
     * In general no prefix refers to the top level component/component_type (avail_cols, base_cols, column_formats)
     * selected_cols.component_type refers to the top level component_type selected (cols for components of that type)
     * component_ generally refers the inner component/component_type (e.g. selected_cols.component) - to keep names manageable
     * The top level component_type = component_type, selected_component_type & non_editable_component_cols
     * The inner component_type = component_relationship_type, non_editable_component_relationship_cols
     */
    constructor(private api: ApiService,
                public eventsService: CustomEventsService,
                public dialog: MatDialog,
                private tileData: TileDataService,
                private propertiesData: ConstantPropertyDataService,
                private componentTypeData: ComponentTypeDataService,
                private cps: CustomEventsFormService,
                private changeDetectorRef: ChangeDetectorRef,
                private formulaBuilder: ComponentNameFormulaBuilderService,
                public printoutDataService: PrintoutDataService,
                private configDateFilterService: ConfigDateFilterService
    ) {
    }

    private _config: COMPONENT_EVENTS_CONFIG;

    get config(): COMPONENT_EVENTS_CONFIG {
        return this._config;
    }

    @Input()
    set config(config: COMPONENT_EVENTS_CONFIG) {
        this._config = config;
    }

    ngOnInit() {
        const ctrl = this;
        if (!this.config.selected_cols) {
            this.config.selected_cols = {component_type: [], event: [], component: {}};
        }

        if (!this.config.name_must_contain) {
            this.config.name_must_contain = undefined;
        }
        if (!this.config.enable_export_component) {
            this.config.enable_export_component = [];
        }
        if (!this.config.page_size) {
            this.config.page_size = 20;
        }
        if (!this.config.component_type_date_filters) {
            this.config.component_type_date_filters = new ConfigTypeDateFilter();
        }
        this.config.component_type_date_filters = ConfigTypeDateFilter.checkKeys(this.config.component_type_date_filters);

        this.event_file_download_options = FILE_DOWNLOAD_OPTIONS;

        this.api.get('/api/GetExportDataHandlers').toPromise().then(data => {
            this.supported_export_handlers = data;
        }, error => {
            console.log("ERROR: ComponentEventsTableForm (GetExportDataHandlers), error getting supported data", error);
            this.supported_export_handlers = [];
        });

        if (ctrl.config.selected_component_type?.id) {
            this.componentTypeChanged();
            this.disabled_cols = this.config.selected_cols.component_type.filter(c => c.disabled);
            this.disabled_event_cols = this.config.selected_cols.event.filter(c => c.disabled);
            Object.keys(this.config.selected_cols.component).forEach(id => {
                this.disabled_component_cols[id] = this.config.selected_cols.component[id].filter(c => c.disabled);
            });
            this.required_cols = this.config.selected_cols.component_type.filter(c => c.required);
            this.required_event_cols = this.config.selected_cols.event.filter(c => c.required);
            Object.keys(this.config.selected_cols.component).forEach(id => {
                this.required_component_cols[id] = this.config.selected_cols.component[id].filter(c => c.required);
            });
            this.setComponentTypeConstantPropertyFilter();
        } else {
            ctrl.config.selected_component_relationship_types = null; // Clean up config
            this.updateLockTemplateFilter(null);
            this.getPrintoutOptions(null);
        }

        if (ctrl.config.selected_component_relationship_types) {
            this.componentRelationshipChanged();
        }

    }

    componentTypeChanged($event?) {
        this.avail_cols = null;
        if ($event) {
            this.config.selected_component_type = new ConfigStub<ComponentType>($event.value);
            this.config.lock_template = null;
            this.config.selected_cols.component_type = [];
            this.config.selected_cols.event = [];
            this.config.selected_component_relationship_types = null;
            this.required_cols = [];
            this.disabled_cols = [];
        }
        this.setComponentRelationshipFilters(this.config.selected_component_type.id);
        this.updateLockTemplateFilter(this.config.selected_component_type);
        this.getPrintoutOptions(this.config.selected_component_type);
        // TODO this.setComponentRelationshipFilters(this.config.selected_component_type);
        forkJoin([
            this.getComponentTypeProperties(this.config.selected_component_type),
            this.getEventProperties(this.config.selected_component_type),
            this.getComponentTypeComponentTypes(this.config.selected_component_type.id)
        ])
            .pipe(first(), takeUntil(this.onDestroy))
            .subscribe(() => {
                this.setComponentTypeColumns();
                this.changeDetectorRef.markForCheck();
            });
    }

    lockTemplateChanged(event) {
        this.config.lock_template = new ConfigStub<LockTemplate>(event.value);
    }

    private updateLockTemplateFilter(selected_component_type): void {
        if (selected_component_type) {
            this.lockTemplateFilter = [getRelationWithIdFilter('component_type', selected_component_type.id)];
        } else {
            this.lockTemplateFilter = [getRelationWithAttributeFilter('component_type', null, 'id', 'ne')];
        }
    }

    private getPrintoutOptions(selectedComponentType: ComponentType | ConfigStub<ComponentType>) {
        if (!selectedComponentType) return;
        this.printoutDataService.getPrintouts(selectedComponentType?.id).pipe(
            take(1),
            tap((printouts: Printout[]) => {
                this.printoutOptions = printouts;
                this.selectedPrintouts = this.printoutOptions.filter(p => this.config.printouts?.includes(String(p.id)));
            })
        ).subscribe();
    }

    private getComponentTypeProperties(selected_component_type): Observable<any> {
        return this.propertiesData.getPropertiesByRelationshipId(selected_component_type.id, 'component_types')
            .pipe(tap(props => {
                this.constantProperties = props.data || [];
                this.constantProperties.forEach(cp => this.columnDict[cp.id] = cp);

                this.date_properties = props?.data?.filter(cp => {
                    return cp.attributes.data_type === 'datetime';
                });

            }));
    }

    private getEventProperties(selected_component_type): Observable<ListResponse<ConstantProperty>> {
        const ctrl = this;
        return this.propertiesData.getChildPropertiesByParentIds([selected_component_type.id], 'component_types', 'event_types')
            .pipe(
                tap(result => {
                    this.event_properties = result.data;
                    result.data.forEach(cp => this.columnDict[cp.id] = cp);
                    this.setEventColumns(this.event_properties);
                })
            );
    }

    private getComponentTypeComponentTypes(selectedComponentTypeId: ModelID): Observable<ListResponse<ComponentType>> {
        return this.componentTypeData.getComponentTypesByComponentType(selectedComponentTypeId).pipe(tap(response => {
            this.componentTypeComponentTypes = response.data || [];
        }));
    }

    private setComponentTypeColumns(): void {
        this.avail_cols = null;
        const allCols: ComponentEventsColumnType[] = [...this.constantProperties, ...this.componentTypeComponentTypes];
        this.config.selected_cols.component_type = this.setupColumns(allCols,
            'avail_cols', null,
            this.config.selected_cols?.component_type,
            this.component_type_formats_dict,
            ['name']);
    }

    private setEventColumns(all_event_prop_cols: ConstantProperty[]): void {
        this.avail_event_cols = null;
        this.config.selected_cols.event = this.setupColumns(all_event_prop_cols,
            'avail_event_cols', null,
            this.config.selected_cols.event,
            this.event_formats_dict,
            ['start', 'end']);
    }

    private setFormats(selected_columns: EventConfigColumn[], formats_dict) {
        selected_columns.forEach(col => {
            formats_dict[col.id] = col.format || null;
        });
    }

    componentRelationshipChanged($event?) {
        if ($event) {
            this.config.selected_component_relationship_types = $event;
            this.config.selected_component_relationship_types.forEach(rel => {
                utils.match_schema(rel, this.component_type_stub_schema);
            });
        }
        const $rels: Observable<ListResponse<ConstantProperty>>[] = this.filterComponentRelationshipProperties(
            this.config.selected_component_relationship_types);
        if ($rels.length > 0) {
            forkJoin($rels).pipe(first(), takeUntil(this.onDestroy)).subscribe();
        } else {
            this.avail_component_cols = {};
        }
    }

    setComponentRelationshipFilters(selectedComponentTypeId: ModelID): void {
        this.component_relationship_filters = this.componentTypeData.getComponentRelationshipFilters(selectedComponentTypeId);
    }

    filterComponentRelationshipProperties(rel_component_types): Observable<ListResponse<ConstantProperty>> [] {
        if (!this.config.selected_cols.component) {
            this.config.selected_cols.component = {};
        }

        const $obs: Observable<any>[] = [];
        rel_component_types.forEach(rel => {
            this.avail_component_cols[rel.id] = null;
            $obs.push(this.getComponentRelationshipColumns(rel));
            utils.match_schema(rel, this.component_type_stub_schema);
        });
        return $obs;
    }

    getComponentRelationshipColumns(rel_comp_type): Observable<ListResponse<ConstantProperty>> {
        const ctrl = this;
        return this.propertiesData.getPropertiesByRelationshipId(rel_comp_type.id, 'component_types')
            .pipe(tap(props => {
                ctrl.config.selected_cols.component[rel_comp_type.id] = this.setupColumns(props.data,
                    'avail_component_cols', rel_comp_type.id,
                    ctrl.config.selected_cols.component[rel_comp_type.id],
                    ctrl.component_formats_dict[rel_comp_type.id],
                    ['name']);
            }));

    }

    private setupColumns(allModelCols: ComponentEventsColumnType[], available_columns_key: string, key_id: string,
                         config_selected: EventConfigColumn[], formats_dict, default_selected: string[]): EventConfigColumn[] {
        allModelCols?.forEach(model => this.columnDict[model.id] = model);

        // Remove any columns not still associated with the relationship component_type
        if (config_selected && config_selected.length) {
            config_selected = this.cleanColumns(config_selected, allModelCols,
                available_columns_key === 'avail_event_cols' ? this.base_event_cols : this.base_cols);
        } else {
            config_selected = default_selected.map(item => new EventConfigColumn(item, 'attribute'));
        }
        /**passing in the actual list doesn't work possibly because of the _differenceBy function**/
        let available_columns = this.getAvailableColumns(config_selected, allModelCols);
        this.cps.updateDisabled(available_columns, available_columns);
        this.addBaseColumns(config_selected, available_columns,
            available_columns_key === 'avail_event_cols' ? this.base_event_cols : this.base_cols);

        utils.sortObjectsByFlatProperty(available_columns, 'title');
        if (key_id) {
            this[available_columns_key][key_id] = available_columns;
        } else {
            this[available_columns_key] = available_columns;
        }

        formats_dict = formats_dict || {};
        this.setFormats(config_selected, formats_dict);
        this.changeDetectorRef.markForCheck();
        return config_selected;
    }

    private cleanColumns(selected_cols: EventConfigColumn[], all_cols: ComponentEventsColumnType[],
                         base_list = this.base_cols): EventConfigColumn[] {
        selected_cols = selected_cols.filter(col => {
            return all_cols.map(c => c.id).includes(col.id) || base_list.includes(col.id);
        });

        return selected_cols;
    }

    private getAvailableColumns(selected_cols: EventConfigColumn[], all_cols: ComponentEventsColumnType[]): EventConfigColumn[] {
        return _differenceBy(all_cols, selected_cols, 'id')
            .map(item => {
                return new EventConfigColumn(item, item.type);
            });
    }

    addBaseColumns(config_list: EventConfigColumn[], avail_list: EventConfigColumn[], base_list: any[], id?: string): EventConfigColumn[] {
        base_list.forEach(col => {
            if (!config_list.map(item => item.id).includes(col)) {
                avail_list.unshift(new EventConfigColumn(col, 'attribute'));
            }
        });
        return avail_list;
    }

    columnsChanged(event: EventColumnSelectorEmitter, disabled_cols: EventConfigColumn[], required_cols: EventConfigColumn[]) {
        if (event.selected_columns.filter(c => c.disabled)?.length > disabled_cols?.length) {
            this.addDisabledClass();
        }
        const ids = event.selected_columns.map(s => s.id);
        Object.assign(disabled_cols, event.selected_columns.filter(s => s.disabled));
        for (let i = disabled_cols.length - 1; i >= 0; i -= 1) {
            if (!ids.includes(disabled_cols[i].id)) {
                disabled_cols.splice(i, 1);
            }
        }

        const required_ids = event.selected_columns.map(s => s.id);
        Object.assign(required_cols, event.selected_columns.filter(s => s.required));
        for (let i = required_cols.length - 1; i >= 0; i -= 1) {
            if (!required_ids.includes(required_cols[i].id)) {
                required_cols.splice(i, 1);
            }
        }
        this.setComponentTypeConstantPropertyFilter();
        this.changeDetectorRef.markForCheck();
    }

    updateDisabled(event, key: ComponentEventsConfigColumnKey, id) {
        this.disabled_cols = event;
        if (key === 'component') {
            this.config.selected_cols[key][id] = this.cps.updateDisabled(this.disabled_cols, this.config.selected_cols[key][id]);
        } else {
            this.config.selected_cols[key] = this.cps.updateDisabled(this.disabled_cols, this.config.selected_cols[key]);
        }
    }

    updateRequired(event, key: ComponentEventsConfigColumnKey, id) {
        this.required_cols = event.value || event;
        if (key === 'component') {
            this.config.selected_cols[key][id] = this.cps.updateRequired(this.required_cols, this.config.selected_cols[key][id]);
        } else {
            this.config.selected_cols[key] = this.cps.updateRequired(this.required_cols, this.config.selected_cols[key]);
        }
    }

    private addDisabledClass() {
        this.dropped = true;
        setTimeout(() => {
            this.dropped = false;
        }, 2000);
    }

    updateFilters(): void {
        if (!this.config.search_by_name) {
            this.config.bypass_date_filters = false;
        }
    }

    printoutsChanged($event: Printout[]): void {
        this.config.printouts = $event?.map(p => String(p.id));
        this.selectedPrintouts = this.printoutOptions.filter(opt => $event?.map(p => p.id)?.includes(opt.id));
    }

    constantPropertyDateFieldChanged = ($event: KeyMap<Partial<ConstantPropertyTimeConfig>>) => {
        const componentTypeIds = getComponentTypeIds(this.config.selected_cols?.component_type);
        this.config.component_type_date_filters.constant_property_time = $event;
        this.configDateFilterService.updateConfigDateFilters(this.config.component_type_date_filters, componentTypeIds);
    }

    attributeDateFieldChanged = ($event: KeyMap<Partial<AttributeTimeConfig>>) => {
        const componentTypeIds = getComponentTypeIds(this.config.selected_cols?.component_type);
        this.config.component_type_date_filters.attribute_time = $event;
        this.configDateFilterService.updateConfigDateFilters(this.config.component_type_date_filters, componentTypeIds);
    }

    private setComponentTypeConstantPropertyFilter(): void {
        const componentTypeColumnIds = getComponentTypeIds(this.config.selected_cols?.component_type);
        if (!componentTypeColumnIds?.length) return;
        this.cpDateFilterDict = this.configDateFilterService.getTypeConstantPropertyFilter(componentTypeColumnIds, 'component_types');
    }

    openDialog(action, obj) {
        obj.supported_export_handlers = this.supported_export_handlers;
        obj.action = action;
        const dialogRef = this.dialog.open(ExportComponentDialogComponent, {
            width: '250px',
            data: obj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.event === 'Add') {
                this.addExportComponentConfigData(result.data);
            } else if (result.event === 'Update') {
                this.updateExportComponentConfig(result.data);
            } else if (result.event === 'Delete') {
                this.deleteExportComponentConfig(result.data);
            }
        });
    }

    addExportComponentConfigData(row_obj) {
        let d = new Date();
        this.config.enable_export_component.push({
            export_name: row_obj.export_name,
            display_text: row_obj.display_text,
            supports_bulk_export: row_obj.supports_bulk_export,
            supports_row_export: row_obj.supports_row_export,
        });
        this.table.renderRows();

    }

    updateExportComponentConfig(row_obj) {
        this.config.enable_export_component = this.config.enable_export_component.filter((value, key) => {
            if (value.export_name === row_obj.export_name) {
                value.export_name = row_obj.export_name;
                value.display_text = row_obj.display_text;
                value.supports_bulk_export = row_obj.supports_bulk_export;
                value.supports_row_export = row_obj.supports_row_export;
            }
            return true;
        });
        this.table.renderRows();
    }

    deleteExportComponentConfig(row_obj) {
        this.config.enable_export_component = this.config.enable_export_component.filter((value, key) => {
            return value.export_name !== row_obj.export_name;
        });
        this.table.renderRows();
    }

    beforeSave(): boolean {
        const colCpIds = this.config.selected_cols.component_type.filter(col => col.type === 'constant_property')?.map(c => c.id);
        const requiredCpIds = this.config.selected_cols.component_type.filter(col => col.type === 'constant_property' && col.required)?.map(c => c.id);
        return this.formulaBuilder.checkComponentNameFields(this.config, colCpIds, requiredCpIds, this.columnDict);

    }

    columnNameFunction(obj) {
        if (!obj) return obj;
        return obj?.title || this.columnDict?.[obj.id]?.attributes?.name;
    }


    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    protected readonly compareValueToId = compareValueToId;
    protected readonly getComponentTypeIds = getComponentTypeIds;
}
