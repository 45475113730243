import {ConfigStub} from '../../../_typing/config-stub';
import {ComponentType} from '../../../_models/component-type';
import {TileParameters} from '../../../services/tile_data.service';
import {SelectedColumns} from './selected-component';
import {Series} from "../../../_models/series";
import {BaseModel, KeyMap} from "../../../_typing/generic-types";

export class ReportParameters {
    jsonContext: string = "{}";
    template: string = "";
    selectedComponentType: ConfigStub<ComponentType> = new ConfigStub<ComponentType>(null);
    selectedComponentRelationshipTypes: ComponentType[] = [];
    selectedColumns: SelectedColumns = new SelectedColumns();
    series: ConfigStub<Series>[];
    series_column_dict: KeyMap<string[]>;
    functions: ConfigStub<BaseModel<any>>[];

    constructor(config: Partial<TileParameters> = {}) {
        // Use a mapping object for cleaner assignment
        const mapping: Record<string, keyof ReportParameters> = {
            paragraph_body: 'template',
            selected_cols: 'selectedColumns',
            selected_component_type: 'selectedComponentType',
            selected_component_relationship_types: 'selectedComponentRelationshipTypes',
            series: 'series',
            series_column_dict: 'series_column_dict'
        };

        Object.keys(config).forEach(key => {
            if (mapping[key]) {
                this[mapping[key]] = config[key];
                delete config[key];
            }
        });
        Object.assign(this, config);
    }
}
