import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "../../shared/base.component";
import {ConstantProperty} from "../../_models/constant-property";
import {WireComponent} from "../../_models/component";

export interface ComponentFileUploadDialogData {
    component: WireComponent;
    property: ConstantProperty;
}
export type UploadedFile = {
    name: string;
    lastModifiedDate: Date;
    type: string;
} & File;

@Component({
    selector: 'component-file-upload',
    templateUrl: './component-file-upload-dialog.component.html',
    styleUrls: ['./component-file-upload-dialog.component.less'],
    standalone: false
})
export class ComponentFileUploadDialogComponent extends BaseComponent {

    property: ConstantProperty;
    component: WireComponent;
    isCorrectFormat = true;
    file: UploadedFile = null;
    errorMessage: string;

    constructor(private dialogRef: MatDialogRef<ComponentFileUploadDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: ComponentFileUploadDialogData) {
        super();
        this.property = dialogData.property;
        this.component = dialogData.component;
    }

    ngOnInit() {
        this.errorMessage = '';
    }

    save() {
        if (!this.file) {
            return;
        }
        this.dialogRef.close({file: this.file});
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    changeEvent(e) {
        this.file = e;
    }
}
