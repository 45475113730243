import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppScope} from '../../../services/app_scope.service';
import {takeUntil, tap} from "rxjs/operators";
import {UserPreference} from '../../../_models/user-preference';
import {PaginationDataSource} from "../../../services/api/pagination-data-source";
import {UserPreferenceService} from "../user-preference.service";
import {SessionState} from '../../../_models/session-state';
import {MatPaginator} from "@angular/material/paginator";
import {SaveableBase, SaveService} from '../../../services/save/save.service';
import {User} from "../../../_models/users";

@Component({
    selector: 'user-preference',
    templateUrl: './user-preference.component.html',
    styleUrls: ['./user-preference.component.less'],
    providers: [UserPreferenceService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UserPreferenceComponent extends SaveableBase {
    @Input() user: User;

    user_preferences: UserPreference[];
    columns: string[];
    default_dashboard_options_filter: any[];
    dataSource: PaginationDataSource<UserPreference>;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private appScope: AppScope,
                private cps: UserPreferenceService,
                private saveService: SaveService
    ) {
        super();
        this.cps.dataSourceSubject.pipe(
            tap(ds => {
                this.dataSource = ds;
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.cps.saveSubject.pipe(
            tap((errors) => {
                console.log('User Preferences saved');
                this.publishStatus({
                    key: 'user-preference',
                    value: errors.length > 0 ? 'error' : 'success',
                    errors: errors
                })
                this.setCanSave(this.cps.hasChanges());
            }),
            takeUntil(this.onDestroy)
        ).subscribe();

        this.saveService.getSaveHandle().pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.publishStatus({key: 'user-preference', value: 'saving', errors: []});
            this.cps.save();
        });
    }

    ngOnInit(): void {
        this.columns = this.cps.columns;
        this.default_dashboard_options_filter = this.cps.getAllUserPagesFilter(this.user.id);
        setTimeout(() => {
            this.cps.paginator = this.paginator;
            this.cps.initialise(this.user);
        })

    }

    updateDefaultPage(up: UserPreference, event: SessionState) {
        this.cps.updateDefaultPage(up, event);
        this.setCanSave(this.cps.hasChanges());
    }

    pagesInfoFunction = (obj) => {
        if (obj.attributes) {
            let u = obj.relationships?.user?.data?.id;
            let inner = obj.attributes.visibility === 'public' ? '' : obj.attributes.visibility;
            inner += (u === this.appScope.current_user.id) ? ', me' : '';
            return obj.attributes.name + (inner ? ' (' + inner + ')' : '');
        } else {
            return 'Empty dashboard';
        }
    };


}
