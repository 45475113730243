import {Injectable, Injector} from '@angular/core';
import {
    GenericChartTileConfiguration,
    WaterfallChartTileConfiguration
} from "../charts/chart-config/generic-chart-tile.configuration";
import {gen_xTick_values, fontSizeFromPercent} from "../lib/utils";
import {
    WaterfallChartConfiguration,
    RarChartConfiguration,
    SeriesChartType,
    ChartFont, BaseChartStylesConfiguration, BarChartConfiguration, isBarChartConfiguration
} from "../charts/chart-config/chart-configuration";
import {PlotlyChartConfigTranslationService} from "./plotly-chart-config-translation.service";
import {ChartService} from './chart.service';
import {CategoryChartTileConfiguration} from '../forms/category-chart-form/category-chart-tile.configuration';
import {CONFIG_STUB} from "./tile_data.service";
//import {ChartConfigC3TranslationService} from './chart-config-c3-translation.service';
import {PlotType} from 'plotly.js-dist-min';

export type ChartLibraryType = 'plotly' | 'c3';
import {FormatNumberPipe} from "../shared/pipes";
import {KeyMap} from '../_typing/generic-types';
import {SeriesGetSeriesData, SeriesSummary, SeriesSummarySeries} from "../_models/api/series-summary";
import {PlotlyWaterfallChartConfigTranslationService} from "./plotly-waterfall-chart-config-translation.service";
import {PlotlyRarChartConfigTranslationService} from "./plotly-rar-chart-config-translation.service";
import {PlotlyBarChartConfigTranslationService} from './plotly-bar-chart-config-translation.service';
import {DateTimeInstanceService} from "./date-time-instance.service";

/**This service contains functions which combine data from GSS or GetData with tile config options set by the user
 * to create a library (plotly/c3) ready configuration file for the specified chart type
 */
@Injectable()
export class ChartConfigTranslationService {
    private chartTranslationService: PlotlyBarChartConfigTranslationService | PlotlyWaterfallChartConfigTranslationService
        | PlotlyRarChartConfigTranslationService; // | ChartConfigC3TranslationService;

    constructor(private injector: Injector,
                private formatNumber: FormatNumberPipe,
                private dateInst: DateTimeInstanceService) {
    }

    getSingleXValues(tile_config: WaterfallChartTileConfiguration, series_dict): { [key: string]: string[] } {
        let series = {single: []};
        tile_config.series_list.forEach(s => {
            series.single.push(s.custom_legend_name ? s.custom_legend_name :
                s.use_alias && series_dict[s.series?.id]?.attributes.alias ? series_dict[s.series.id].attributes.alias : s.name || s.series?.attributes.name);
        })
        return series;
    }

    /**Another possibility for getting x_values e.g.
     getTimeSeries(){}
     **/

    getGroupNames(tile_config): { [key: string]: string[] } {
        let groups = {single: []};
        tile_config.series_list.forEach(s => {
            groups.single.push(s.group_name || '');
        })
        return groups;
    }

    getSingleYValues(tile_config: WaterfallChartTileConfiguration, series_dict): { [key: string]: string[] } {
        let y = {single: []};
        tile_config.series_list.forEach(s => {
            y.single.push(series_dict[s.series.id].Value);
        })
        return y;
    }

    getMissingValues(tile_config: GenericChartTileConfiguration, series_dict: KeyMap<SeriesGetSeriesData>): KeyMap<string[]> {
        let y = {};
        tile_config.series_list.forEach(s => {
            y[s.id] = Object.values(series_dict[s.id].missing_values);
        })
        return y;
    }

    getYValues(tile_config: GenericChartTileConfiguration, series_dict: KeyMap<SeriesGetSeriesData>, key = 'id'): KeyMap<string[]> {
        let y = {};
        tile_config.series_list.forEach(s => {
            const id = s[key];
            y[id] = Object.values(series_dict[id].data);
        })
        return y;
    }

    getYKeyValues(series_dict, key: string = 'Value'): KeyMap<string[]> {
        let y = {};
        Object.keys(series_dict).forEach(s => {
            y[s] = series_dict[s].map(i => i[key])
        })
        return y;
    }

    getTimeSeriesValues(tile_config, series_dict: KeyMap<SeriesGetSeriesData>): string[] {
        let times: string[] = [];
        const dtp = this.dateInst.dtp;
        tile_config.series_list.forEach(s => {
            if (!s.id) return;
            ['data', 'missing_values'].forEach(d => {
                Object.keys(series_dict[s.id][d])?.forEach(key => {
                    const time = dtp.sample_period.format(new Date(key), dtp);
                    if (!times.includes(time)) {
                        times.push(time);
                    }
                })
            })
        })
        return times;
    }

    getTextValues(tile_config: GenericChartTileConfiguration, series_dict, key = 'id'): KeyMap<string[]> {
        let text = {};
        let item = [];
        for (let i = 0; i < tile_config.series_list.length; i++) {
            item = [];
            const s = tile_config.series_list[i];
            const id = s[key];
            const series = series_dict[id];
            if (!s.show_data_labels) {
                text[id] = '';
                continue;
            }
            let array = series.data ? Object.values(series.data) : series;
            const att_decimals = series.attributes?.decimal_places;
            array.forEach(d => {
                const decimals = att_decimals || (att_decimals === 0 ? 0 : (series.DecimalPlaces || series.DecimalPlaces === 0 ? 0 : 2));
                const unit = tile_config.show_data_label_units ? ' ' + series.Unit || '' : '';
                item.push(this.formatNumber.transform((d.Value || d.Value === 0 ? d.Value : d), decimals) + unit);
            })
            text[id] = item;
        }
        return text;
    }

    getTextOrientation(tile_config: GenericChartTileConfiguration, key = 'id'): KeyMap<number> {
        let angles = {};
        tile_config.series_list.forEach(series_config => {
            angles[series_config[key]] = series_config.vertical_data_labels ? -90 : 0;
        })
        return angles;
    }

    getYAxisFormat(tile_config: GenericChartTileConfiguration): string {
        if (tile_config.y_decimals) {
            return '.' + tile_config.y_decimals + 'f';
        }
        if (tile_config.y_condensed_numbers) {
            return 's';
        }
        return null;
    }

    getHidden(tile_config): KeyMap<boolean> {
        let hidden = {
            tick_labels: tile_config.hide_tick_labels,
            axes: tile_config.hide_axes,
            legend: tile_config.hide_legend
        };
        return hidden;
    }

    getSeriesColours(tile_config, key = 'id') {
        let colours = {};
        tile_config.series_list.forEach(s => {
            const id = s[key];
            colours[id] = s.colour;
        })
        return colours;
    }

    getStyles(tile_config): KeyMap<ChartFont> {
        let styles: KeyMap<ChartFont> = {};
        const keys = ['x_axis_font', 'y_axis_font', 'data_font', 'title_font'];
        keys.forEach(k => {
            styles[k] = this.getFontSize(tile_config.styles, k);
            styles[k] = Object.assign(styles[k], {color: styles[k].colour || null})
        })
        return styles;
    }

    getLineStyles(tile_config, key = 'id'): KeyMap<any> {
        let styles: KeyMap<any> = {};
        tile_config.series_list.forEach(s => {
            if (s.type !== 'line') return;
            const id = s[key];
            const line_thickness = s.line_thickness === 'normal' ? 2 : s.line_thickness === 'thickness-thick' ? 3 : 1;
            const line_style = s.line_type === 'dotted' ? 'dot' : s.line_type === 'dashed' ? 'dash' : s.line_type === 'dashdot' ? 'dashdot' : 'solid';
            const line_shape = s.line_shape;
            styles[id] = {
                'width': line_thickness,
                'style': line_style,
                'shape': line_shape,
                'markers': !s.hide_markers
            };
        })
        return styles;
    }

    private getFontSize(styles, key) {
        let site_font = getComputedStyle(document.documentElement).getPropertyValue('--font-size') || '14';
        return Object.assign({}, (styles?.[key] || {}),
            styles?.[key]?.size ? {size: fontSizeFromPercent(parseFloat(site_font), styles[key].size)} : {})
    }

    getSeriesChartTypes(tile_config: GenericChartTileConfiguration, default_type: SeriesChartType = 'line'): { [key: string]: SeriesChartType | PlotType } {
        let types = {}
        tile_config.series_list.forEach(s => {
            types[s.key] = s.type || default_type;
        })
        return types;
    }

    getSeriesIdNameDict(tile_config: CategoryChartTileConfiguration): { [key: string]: string } {
        let dict = {};
        tile_config.series_list.map(s => dict[s.series.id + tile_config.series_list.indexOf(s)] = s.series.attributes.name);
        return dict;
    }

    getSeriesNames(tile_config, series_dict: KeyMap<SeriesGetSeriesData | SeriesSummarySeries>, key = 'id'): KeyMap<string> {
        let series = {}
        tile_config.series_list.forEach(s => {
            const id = s[key];
            series[id] = s.custom_legend_name ? s.custom_legend_name :
                s.use_alias && series_dict[id]?.attributes.alias ? series_dict[id].attributes.alias : s.name;
        })
        return series;
    }

    getYMin(tile_config): number {
        return tile_config.y_min_set ? Number(tile_config.y_min) : tile_config.y_max_set ? 0 : null;
    }

    getYMax(tile_config): number {
        return tile_config.y_max_set ? Number(tile_config.y_max) : tile_config.y_min_set ? 0 : null;
    }

    getConfiguredWaterfallChart(library: ChartLibraryType, chart_config: WaterfallChartConfiguration) {
        //this.setLibrary(library, 'waterfall');
        this.chartTranslationService = this.injector.get(PlotlyWaterfallChartConfigTranslationService);
        return this.chartTranslationService.configureChart(chart_config);
    }

    getConfiguredRarChart(library: ChartLibraryType, chart_config: RarChartConfiguration) {
        //this.setLibrary(library, 'category');
        this.chartTranslationService = this.injector.get(PlotlyRarChartConfigTranslationService);
        return this.chartTranslationService.configureChart(chart_config);
    }

    getConfiguredBarChart(library: ChartLibraryType, chart_config: BarChartConfiguration) {
        //this.setLibrary(library, 'stacked bar');
        this.chartTranslationService = this.injector.get(PlotlyBarChartConfigTranslationService);
        return this.chartTranslationService.configureChart(chart_config);
    }

    setLibrary(library_name: ChartLibraryType, chart_type: 'waterfall' | 'category' | 'stacked bar') {
        if (library_name === 'plotly') {
            switch (chart_type) {
                case 'waterfall':
                    this.chartTranslationService = this.injector.get(PlotlyWaterfallChartConfigTranslationService);
                    break;
                case 'category':
                    this.chartTranslationService = this.injector.get(PlotlyRarChartConfigTranslationService);
                    break;
                case 'stacked bar':
                    this.chartTranslationService = this.injector.get(PlotlyBarChartConfigTranslationService);
                    break;
                default:
                    break;
            }
        }
    }
}
