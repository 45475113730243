import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

interface UserPreferenceParameters {
    session_id?: string;
    user_id?: string;
    timezone?: string;
    account_id: string;
}

export class UserPreferenceAttributes extends AuditMixinAttributes {
    timezone?: string;
}

export class UserPreferenceRelationships extends AuditMixinRelationships {
    session_state?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    user: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    account: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;

    constructor({session_id = null, user_id, account_id}: UserPreferenceParameters = null) {
        super();

        this.session_state.data = {id: session_id, type: 'session_state'};
        this.user.data = {id: user_id, type: 'users'};
        this.account.data = {id: account_id, type: 'account'};
    }
}

export class UserPreference extends AuditMixin {
    attributes: UserPreferenceAttributes;
    id: string;
    type: string = 'user_preferences';
    relationships: UserPreferenceRelationships;

    constructor({session_id = null, user_id, account_id}: UserPreferenceParameters) {
        super();
        this.attributes = new UserPreferenceAttributes();
        this.relationships = new UserPreferenceRelationships({
            session_id: session_id,
            user_id: user_id,
            account_id: account_id
        });
    }
}
