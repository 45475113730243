import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../../../services/app_scope.service";
import {ApiService} from "../../../services/api/api.service";
import * as utils from "../../utils";
import {OreBodyGroup} from '../../../_models/ore-body-group';

@Component({
    selector: 'ore-body-group-node',
    templateUrl: 'ore-body-group-node.component.html',
    styleUrls: ['./ore-body-group-node.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OreBodyGroupNodeComponent implements OnInit {
    @Input() item?: any;
    @Input() group_tree_data: any[];

    @Output() itemSelected = new EventEmitter();

    constructor(public appScope: AppScope,
                private api: ApiService) {
    }

    hasChild = (node: OreBodyGroup) => !!node.relationships.children.data && node.relationships.children.data.length > 0;

    hasParent = (node: OreBodyGroup) => !!node.relationships.parent.data;

    ngOnInit() {
    }

    emitSelected(item, event) {
        this.itemSelected.emit({item: item, checked: event.checked});
    }

    getChildren(group) {
        if (!group.expanded) {
            if (group.relationships.children.data && group.relationships.children.data.length > 0) {
                group.relationships.children.data = this.group_tree_data.filter(option => group.relationships.children.data.map(c => c.id).includes(option.id))

            }
            utils.sortObjectsByProperty(group.relationships.children.data, 'name');
            group.expanded = true;
        } else {
            group.expanded = false;
        }
    }
}
