import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {HeaderDataService} from "../services/header_data.service";
import { HttpClient } from "@angular/common/http";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {concatMap, first, takeUntil, tap} from "rxjs/operators";
import {forkJoin, Subject} from "rxjs";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AppScope} from '../services/app_scope.service';
import {NameOrDescriptionPipe} from '../shared/pipes';
import * as utils from '../lib/utils';
import {deepCopy} from '../lib/utils';
import {Tile as TileModel} from "../_models/tile";
import {SingleResponse} from "../services/api/response-types";
import {Series} from '../_models/series';
import {SeriesDataService} from '../services/series_data.service';
import {SeriesTabGroup} from "../forms/series-form/series-form.component";
import {DateTimeInstanceService} from "../services/date-time-instance.service";

@Component({
    selector: 'quick-charts',
    templateUrl: 'quick-charts.component.html',
    styleUrls: ['../../../node_modules/c3/c3.css', './spc-chart.component.less',
        '../components/select-search/select-search.component.less', './charts.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class QuickChartsComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    tabGroups = SeriesTabGroup;
    full_series_list: any[] = [];
    chart_type: { name: string, value: string } = {name: 'Default', value: 'default-chart'};
    config = {
        "hide_comments": false,
        "labels": {
            "sub_title": null,
            "title": null,
        },
        "series_list": [
            {
                "axis": "y",
                "colour": null,
                "cumulative": false,
                "name": null,
                "show_limits": false,
                "type": "line"
            }
        ]
    };
    tile_configs = {};
    chart_types = [
        {name: 'Default', value: 'default-chart'},
        {name: 'SPC', value: 'spc-chart'},
        {name: 'Budget Bar', value: 'budget-bar-chart'},
        // 'Operations', TODO ADD THIS CHART TYPE
        {name: 'Line', value: 'custom-chart'},
        {name: 'Area', value: 'custom-chart'},
        {name: 'Bar', value: 'custom-chart'},
        {name: 'Pie', value: 'custom-chart'},
        {name: 'Multi Line', value: 'custom-chart'},
        {name: 'Multi Area', value: 'custom-chart'},
        {name: 'Multi Bar', value: 'custom-chart'},
    ];

    selected_series: any = [];
    search_series: string[] = [];
    selected_series_names = '';
    forecast: any;
    table_config: any = {};
    configs: any = {};
    chart_config: any;
    ready: boolean = false;
    series_ids: any;

    multi_index: number = 0;

    constructor(private api: ApiService,
                public headerData: HeaderDataService,
                private http: HttpClient,
                private router: Router,
                public dtp: DateTimePeriodService,
                private activatedRoute: ActivatedRoute,
                private appScope: AppScope,
                private seriesData: SeriesDataService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        // TODO why is this component initialized twice on page load?
        ctrl.headerData.title = 'Quick Charts';
        ctrl.selected_series = [];
        const queryParams = this.activatedRoute.snapshot.queryParams;
        if (queryParams['series_list']) {
            forkJoin([this.appScope.auth_complete.promise, this.dtp.dtpInitialisedPromise.promise])
                .pipe(
                    concatMap(() => {
                        this.getConfigFromUrl(queryParams, ctrl.full_series_list);
                        return this.seriesData.getSeriesLightListById(this.search_series).pipe(first(), takeUntil(this.onDestroy))

                    }),
                    first(),
                    takeUntil(this.onDestroy)
                )
                .subscribe(result => {
                    ctrl.selected_series = result?.data;
                    ctrl.getConfig(false);
                    ctrl.ready = true
                })
        } else {
            ctrl.ready = true;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.headerData.show_dtp = true;
            this.headerData.addDownload();
        })
    }

    compare(p1, p2): boolean {
        if (p1 && p2) {
            return p1.name === p2.name;
        }
        return false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    queueAggregations(): void {
        const seriesList = this.selected_series.map(series => series.id);
        this.headerData.queueAggregations(this.dateInst.dtp, seriesList).subscribe();
    }

    getConfigFromUrl(params: { series_list?, chart_type? }, full_series_list) {
        const ctrl = this;
        this.search_series = params['series_list'];

        if (this.search_series) {
            if (!Array.isArray(this.search_series)) {
                this.search_series = [this.search_series]
            }

        }
        let search_chart_type = params['chart_type'];
        if (search_chart_type) {
            const chart_type = JSON.parse(search_chart_type);
            ctrl.chart_type = ctrl.chart_types.find(ct => ct.name === chart_type);
        } else {
            ctrl.chart_type = {name: 'Default', value: 'default-chart'};
        }

    }

    getTile(content, params) {
        let tile = new TileModel()
        tile.attributes.content = content;
        tile.attributes.parameters = params;
        return tile;
    }

    getTiles() {
        if (['Multi Line', 'Multi Area', 'Multi Bar', 'Pie', 'Gauge', 'Donut'].includes(this.chart_type.name)) {
            this.tile_configs = this.getTile(this.chart_type.value, this.configs);
            return;
        }
        this.selected_series.map(series => {
            if (['Default', 'Budget Bar'].includes(this.chart_type.name)) {
                this.tile_configs[series.id] = this.getTile(this.chart_type.value, {series: series})
            } else if (['Line', 'Bar', 'Area', 'SPC'].includes(this.chart_type.name)) {
                this.tile_configs[series.id] = this.getTile(this.chart_type.value, this.configs[series.id]);
            }
        })
    }

    getConfig(updateUrl = true) {
        const ctrl = this;
        if (['Line', 'Bar', 'Area'].includes(ctrl.chart_type.name)) {
            ctrl.selected_series.map(series => {
                ctrl.configs[series.id] = {
                    series_list: [{
                        name: series.attributes.name,
                        axis: 'y',
                        type: ctrl.chart_type.name.toLocaleLowerCase(),
                        show_limits: false,
                        cumulative: false
                    }],
                    labels: {
                        title: series.attributes.description,
                        subtitle: series.attributes.name
                    },
                    chart_type: ctrl.chart_type.name.toLowerCase()
                }
            });
        } else if (['SPC'].includes(ctrl.chart_type.name)) {
            const $obs_list = [];
            //**Limits aren't on series_light so need to be fetched**
            ctrl.selected_series.map(s => {
                const $obs = ctrl.api.series.getById(s.id).pipe(first(), takeUntil(this.onDestroy),
                    tap((s_: SingleResponse<Series>) => {
                        const series = s_.data;
                        ctrl.configs[series.id] = {
                            series_list: [{
                                name: series.attributes.name,
                                axis: 'y',
                                type: 'line',
                                show_limits: false,
                                cumulative: false
                            }],
                            //TODO: is this correct or are the limits something else?
                            limits: {
                                hihi: utils.scaleLimits(series.attributes.hihi, ctrl.dtp, series),
                                hi: utils.scaleLimits(series.attributes.hi, ctrl.dtp, series),
                                low: utils.scaleLimits(series.attributes.low, ctrl.dtp, series),
                                lowlow: utils.scaleLimits(series.attributes.lowlow, ctrl.dtp, series),
                                std: series.attributes.std
                            },
                            labels: {
                                title: series.attributes.description,
                                subtitle: series.attributes.name
                            },
                            chart_type: ctrl.chart_type.name.toLowerCase()
                        }
                    }));
                $obs_list.push($obs);
            });
            if ($obs_list.length < 1) return;
            forkJoin($obs_list).subscribe(() => ctrl.getTiles());

        } else if (['Multi Line', 'Multi Area', 'Multi Bar', 'Pie', 'Gauge', 'Donut'].includes(ctrl.chart_type.name)) {
            //Get header
            this.selected_series_names = ctrl.selected_series.map(
                item => NameOrDescriptionPipe.prototype.transform(item.attributes)).join(', ');
            const config_series_list = ctrl.selected_series.map(item => {
                let chart_type = ctrl.chart_type;
                let config_type = '';
                switch (chart_type.name) {
                    case 'Pie':
                        config_type = 'pie';
                        break;
                    case 'Donut':
                        config_type = 'donut';
                        break;
                    case 'Gauge':
                        config_type = 'gauge';
                        break;
                    default:
                        config_type = ctrl.chart_type.name.toLocaleLowerCase().substr(6);
                        break
                }
                return {
                    name: item.attributes.name,
                    axis: 'y',
                    type: config_type,
                    show_limits: false,
                    cumulative: false
                }
            });
            ctrl.configs = {
                series_list: config_series_list,
                labels: {
                    title: ctrl.chart_type
                }
            }
        }
        if (updateUrl) {
            ctrl.updateURL();
        }
        if (this.chart_type.name === 'SPC') return; //getTiles called inside subscription
        ctrl.getTiles();
    }

    updateURL() {
        const ctrl = this;
        const queryParams: Params = {
            series_list: ctrl.selected_series.map(series => series.id),
            chart_type: JSON.stringify(ctrl.chart_type.name)
        };

        //This updates the url without navigation (by using current activatedRoute)
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // prevents replacing other params (dtp)
                //replaceUrl: true //can be used to replace this route event in browser history rather than adding to history
            });
    }

    drop(event: CdkDragDrop<string[]>) {
        const ctrl = this;
        moveItemInArray(this.selected_series, event.previousIndex, event.currentIndex);
    }

    removeSeries(option) {
        let index = this.selected_series.indexOf(option);
        this.selected_series.splice(index, 1);
        this.updateURL();
    }

    updateSeries(event, series) {
        if (event && series) {
            series = deepCopy(event);
        }
    }
}
