import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {debounceTime, delay, takeUntil, tap} from 'rxjs/operators';
import {CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {SelectSearchApiService} from '../select-search-api.service';
import {deepCopy, uniqueObjectList} from "../../../lib/utils";
import {TOOLTIP_SHOW_DELAY} from "../../../shared/globals";
import {Account} from "../../../_models/account";
import {StringFunction, ValueFunction} from "../types";
import {ModelID} from "../../../_typing/generic-types";

@Component({
    selector: 'select-many-search-api',
    templateUrl: 'select-many-search-api.component.html',
    styleUrls: ['select-many-search-api.component.less', '../../search-api/search-api.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SelectSearchApiService],
    standalone: false
})
export class SelectManySearchApiComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();
    private _value: any[];

    @ViewChild('search') searchTextBox: ElementRef;

    @Input() placeholder: string = 'Search';
    @Input() disabled: boolean = false;
    /**Entire selection element group**/
    @Input() disable_items: boolean = false;
    /**Individual items**/
    @Input() openOnInit: boolean = false;
    @Input() classes: string = 'fill';
    @Input() label: string = '';
    @Input() allowAccountSelection: boolean = true;
    @Input() allowedAccountIds: ModelID[];

    @Input() stringFunction: StringFunction;
    @Input() valueFunction: ValueFunction;
    @Input() compareFunctionName;
    /**Default = 'compareById'**/
    @Input() noItemsAvailableMessage: string = "";
    /**The api call that actually fetches data on the Input() api_model, defined in parent**/
    @Input() fetchData: (index: Number, filterValue: String, items_selected?: any[], items_removed?: any[]) => Observable<any[]>

    /**The api call that fetches selected data, defined in parent**/
    @Input() fetchSelected: (index: Number, filterValue: String, items_selected?: any[], items_removed?: any[]) => Observable<any[]>

    /**
     * Preselect items in the data list.
     * @param value
     */
    @Input()
    set value(value: any[]) {
        this._value = value;
    }

    get value(): any[] {
        return this._value;
    }

    /**
     * For running a function when the selection was changed.
     */
    @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectChangeComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeWithoutChange: EventEmitter<any> = new EventEmitter();
    @Output() itemsAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemsRemoved: EventEmitter<any> = new EventEmitter();
    @Output() accountsChange = new EventEmitter<Account[]>();

    items_added: any[] = [];
    items_removed: any[] = [];
    $options_available: Observable<{ text: string, value: any }[]>;
    $options_selected: Observable<{ text: string, value: any }[]>;
    selectedItems: { text: string, value: any }[] = [];
    availableItems: { text: string, value: any }[] = [];

    initialised: boolean = false;
    tooltipShowDelay = TOOLTIP_SHOW_DELAY;
    searchText = "";

    constructor(public sapi: SelectSearchApiService, private changeDetection: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    menuOpened(): void {
        if (!this.initialised) {
            this.initialised = true;

            this.$options_available = this.sapi.connect();
            this.$options_available.pipe(
                switchMap(list => of(list)),
                tap(list => {
                    this.availableItems = uniqueObjectList(list, 'value');
                })
            ).subscribe(() => this.changeDetection.markForCheck());

            this.$options_selected = this.sapi.connect_selected();
            this.$options_selected.pipe(
                switchMap(list => of(list)),
                tap(list => {
                    this.selectedItems = uniqueObjectList(list, 'value');
                })
            ).subscribe(() => this.changeDetection.markForCheck());

            this.setupItemFunctions();
        }
        this.clearSearch();
    }

    filterOptions(filterString: string) {
        this.searchText = filterString;
        this.sapi.resetList([]);
        this.sapi.resetSelectedList();
        this.getNextBatch();
    }

    menuClosed(): void {
        this.selectChangeComplete.emit(this.value);
        this.sapi.resetList([]);
        this.sapi.resetSelectedList();
    }

    setupItemFunctions(): void {
        /**If value is function is passed in**/
        if (this.stringFunction) {
            this.sapi.stringFunction = this.stringFunction;
        }
        if (this.valueFunction) {
            this.sapi.valueFunction = this.valueFunction;
        }
    }

    updateSearchSelected(): void {
        this.sapi.resetSelectedList();
        this.getNextSelected(1);
    }

    getNextBatch(page?) {
        this.getNextAvailable(page);
        this.getNextSelected(page);
    }

    getNextAvailable(page?): void {
        this.sapi.getNextBatch(this.fetchData, this.searchText, page, this.items_added, this.items_removed);
    }

    getNextSelected(page?): void {
        this.sapi.getNextSelected(this.fetchSelected, this.searchText, page, this.items_added, this.items_removed);
    }

    drop(event: CdkDragDrop<{ text: string, value: any }[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        } else {
            const item = event.previousContainer.data[event.previousIndex];
            this.move(item, event.container, event.previousContainer, event.currentIndex);
        }
    }

    move(item, container, previousContainer, dropped_index?): void {
        const selecting: boolean = previousContainer.id === 'availableList';
        const previousIndex = previousContainer.data.indexOf(item);
        const index = (dropped_index || dropped_index === 0) ? dropped_index : (selecting ? 0 : container.data.length);

        transferArrayItem(
            previousContainer.data,
            container.data,
            previousIndex,
            index
        );

        if (selecting) {
            this.selectValue(item.value);
        } else {
            this.removeValue(item.value);
        }

        this.checkBatch(previousContainer);
    }

    removeValue(value): void {
        this.value = this.value?.filter(item => this.valueFilter(item, value));
        this.items_removed.push(value);
        this.itemsRemoved.next(this.items_removed);
        this.items_added = this.items_added.filter(item => this.valueFilter(item, value));
        this.updateRemovedSelected();
        this.sapi.availableTotal += 1;
        this.sapi.selectedTotal -= 1;
        this.selectChange.emit(deepCopy(this.value));
    }

    selectValue(value): void {
        if (!this.value) {
            this.value = [];
        }
        this.value.push(value);
        this.items_added.push(value);
        this.itemsAdded.next(this.items_added);
        this.items_removed = this.items_removed.filter(item => this.valueFilter(item, value));
        this.updateRemovedSelected();
        this.sapi.availableTotal -= 1;
        this.sapi.selectedTotal += 1;
        this.selectChange.emit(this.value);
    }

    updateRemovedSelected(): void {
        this.sapi.itemsAdded.next(this.items_added);
        this.sapi.itemsRemoved.next(this.items_removed);
    }

    valueFilter(item, value): boolean {
        // Please leave excluded type equivalent here (==) since values can be int or string
        return item.id != value.id && item != value.id;
    }


    checkBatch(previousContainer): void {
        /**Make sure the next batch loads if user selects enough items to clear scroll bar**/
        const container_height = previousContainer.element.nativeElement.closest('.select-many-list-container').clientHeight;
        if ((previousContainer.data.length * this.sapi.item_height) > container_height) {
            return;
        }
        if (previousContainer.id === 'availableList') {
            this.sapi.resetList();
            this.getNextAvailable();
        } else {
            this.sapi.resetSelectedList();
            this.getNextSelected();
        }
    }

    onAccountsChanged($event) {
        this.accountsChange.emit($event);
        this.filterOptions(this.searchText);
    }

    clearSearch(): void {
        this.filterOptions("");
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
