import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportParameters} from "../type/report-parameters";
import {SeriesColumn} from "../../../services/series_data.service";
import {IDMap, ModelName} from "../../../_typing/generic-types";
import {ConfigStub} from "../../../_typing/config-stub";
import {Series} from "../../../_models/series";
import {difference} from "lodash";
import {TOOLTIP_SHOW_DELAY} from "../../../shared/globals";
import {JsonContextService} from "../json-context.service";
import {takeUntil} from "rxjs";
import {BaseComponent} from "../../../shared/base.component";

@Component({
    selector: 'series-report-builder',
    templateUrl: './series-report-builder.component.html',
    styleUrls: ['./series-report-builder.component.less'],
    standalone: false
})
export class SeriesReportBuilderComponent extends BaseComponent {

    private _config: ReportParameters;

    @Input() set config(config: ReportParameters) {
        this._config = config;
    }

    get config(): ReportParameters {
        return this._config;
    }

    @Output() configChanged = new EventEmitter<ReportParameters>();
    @Input() columns: SeriesColumn[];

    columnOptions: string[];
    private seriesDict: IDMap<ConfigStub<Series>> = {};

    constructor(private jsonContextService: JsonContextService) {
        super();
    }

    ngOnInit() {
        this.columnOptions = this.columns.map(c => c.name);
        this.jsonContextService.config$.pipe(takeUntil(this.onDestroy)).subscribe((config) => {
            this.config = Object.assign(this.config, config);
            this.config.series_column_dict = this.config.series_column_dict || {};
        });
    }

    updateSeries(event) {
        this._updateSeriesDict(event);
        this.config.series = ConfigStub.getConfigStubs<Series>(event);
        this._deleteUnusedKeys();
        this.configUpdated();
    }

    private _updateSeriesDict(series) {
        //this.config.series_column_dict = this.config.series_column_dict || {};
        series.forEach(s => {
            this.seriesDict[s.id] = s;
            this.config.series_column_dict[s.id] = this.config.series_column_dict[s.id] || [];
        });
    }

    private _deleteUnusedKeys() {
        const seriesIs: ModelName[] = this.config.series?.map(s => s.id);
        const seriesKeys: ModelName[] = Object.keys(this.config.series_column_dict);
        const oldKeys = difference(seriesKeys, seriesIs);
        oldKeys.forEach(k => delete this.config.series_column_dict[k]);
    }

    updateSeriesColumns(series, event) {
        console.log("series",series, event);
        this.config.series_column_dict[series.id] = event || [];
        console.log("", this.config);
        this.configUpdated();
    }

    configUpdated() {
        this.jsonContextService.configSubjectChanged(this.config);
        this.configChanged.emit(this.config);
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
