import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {WireComponent} from '../../_models/component';
import {ComponentType} from '../../_models/component-type';
import {ComponentPanelStackService} from "../../modals/component-panel-stack/component-panel-stack.service";
import {RelatedComponentConfig} from "../component-detail/component-detail.component";
import {ComponentPanelStackConfigData} from '../../modals/component-panel-stack/component-panel-stack.component';
import {ConfigStub} from '../../_typing/config-stub';
import {ComponentFormConfig} from "../../_typing/config/component-form-config";
import {KeyMap} from '../../_typing/generic-types';
import {ConstantValue} from '../../_models/api/generic-constant';
import {deepCopy} from '../../lib/utils';
import {ComponentEventsTableService} from "../../tables/component-events-table/component-events-table.service";

@Component({
    selector: 'new-component',
    templateUrl: './new-component.component.html',
    styleUrls: ['./new-component.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class NewComponentComponent implements OnInit {

    @Input() data: ComponentPanelStackConfigData;
    @Output() loadNewComponent = new EventEmitter<RelatedComponentConfig>();
    component: WireComponent;
    config: ComponentFormConfig;
    show_component_link = false;
    att_cols = ['name', 'start_time', 'end_time'];

    constructor(private panelStackService: ComponentPanelStackService,
                private componentEventsService: ComponentEventsTableService) {
    }

    ngOnInit(): void {
        this.config = {
            selected_component_type: new ConfigStub<ComponentType>(this.data.config.selected_component_type),
            selected_cols: this.data.config.selected_cols.component_type.filter(c => c.type === 'constant_property' ||
                this.att_cols.includes(c.id.toLowerCase())),
            group_columns: true,
            selected_name_formula: this.data.config.selected_name_formula
        };
    }

    showComponentLink(event: { component: WireComponent, custom_constant_dict: KeyMap<ConstantValue> }): void {
        this.component = event.component;
        this.show_component_link = true;
    }

    hideComponentLink(): void {
        this.component = null;
        this.show_component_link = false;
    }

    loadDetails() {
        this.loadNewComponent.next({current: undefined, related: this.component});
    }

    refreshTable() {
        this.componentEventsService.$refreshComponentEventsTable.next();
    }
}
