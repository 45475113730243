import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {getRelationWithIdFilter} from "../services/api/filter_utils";
import {forkJoin} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
    selector: 'constant-series-data',
    templateUrl: 'constant_series_data.component.html',
    styleUrls: ['series-component-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ConstantSeriesDataComponent implements OnInit {
    @Input() series: any;
    @Input() series_permissions: any;

    disabled: boolean = true;
    page_number: number = 1;
    page_size: number = 5;
    permissions: any = {};
    series_data: any;

    no_results: boolean = false;

    constructor(private api: ApiService) {
    }

    ngOnInit() {
        this.series_permissions[this.series.id].map((permission) => {
            this.permissions[permission] = true;
        });

        this.fetchData();
    }

    fetchData() {
        this.no_results = false;
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithIdFilter('series', this.series.id)
        ];
        options.sort = '-time_stamp';
        options.page_size = this.page_size;
        options.page_number = this.page_number;
        this.api.raw_data.searchMany(options).pipe(tap((result) => {
            if (!result) { return; }

            if (result.data.length === 0) {
                this.no_results = true;
            } else {
                this.series_data = result.data;
            }
        })).subscribe();
    }

    page(dir) {
        this.page_number = this.page_number + (dir * 1);
        this.fetchData();
    }

    showPrevious() {
        return this.page_number !== 1;
    }

    changeDate(event, item) {
        item.flag = 'update';
    }

    addRow() {
        let time: Date = new Date();
        time.setMinutes(0);
        time.toISOString();

        let new_raw_data = {type: 'raw_data', attributes: {time_stamp: time, value: null},
            relationships: {series: {data: {id: this.series.id, type: 'series'}}}};
        this.series_data.unshift(new_raw_data);
        this.no_results = false;
    }
    save() {
        let observables = [];
        this.series_data.forEach(function (row) {
            if (row.flag) {
                observables.push(this.dataChange(row));
            }
        });

        forkJoin([observables]).subscribe( () => {
            this.series_data = this.series_data.filter( (del) => {
                return del.flag !== 'deleted';
            });
        });
    }
}
