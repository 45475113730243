<div class="series-formula-builder">
  <div class="row center">
    <div class="select-search" style="margin-right:0;">
      <select-search-component [label]="'Select a series'"
                               [api_model]="'series_light'"
                               [value]="insert_series"
                               [classes]="'fill'"
                               (selectionChange)="insert_series=$event.value">
      </select-search-component>
    </div>

    <button (click)="addToFormula()"
            class="btn-addtotext" style="height:28px;width:28px;" title="Add to formula">
    </button>
  </div>
  <div class="row" style="margin-top:-10px;">
      <textarea [(ngModel)]="constraint.attributes.name_formula" name="name_formula"
                rows="3" style="flex-grow:2;">
      </textarea>
  </div>

</div>
