import {Component, OnInit} from '@angular/core';
import {HeaderDataService} from '../../services/header_data.service';

@Component({
    selector: 'lock-template-table-view',
    templateUrl: './lock-template-table-view.component.html',
    styleUrls: ['./lock-template-table-view.component.less'],
    standalone: false
})
export class LockTemplateTableViewComponent implements OnInit {

    constructor(private headerData: HeaderDataService) {
    }

    ngOnInit(): void {
        this.buildHeader();
    }

    buildHeader() {
        this.headerData.title = 'Lock Template Tables';
        this.headerData.show_dtp = false;
    };
}
