import {UserFormComponent} from '../forms/user-form/user-form.component';
import {Component, EventEmitter, inject, Input, Output, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {User} from "../_models/users";
import {CommonModule} from "@angular/common";
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {StringDatePipe} from "../shared/pipes";
import {FormsModule} from "@angular/forms";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     MatTableModule,
    //     MatSortModule,
    //     MatCheckboxModule,
    //     StringDatePipe,
    //     FormsModule
    // ],
    selector: 'user-list',
    templateUrl: 'user-list.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UserListComponent {
    public dialog: MatDialog = inject(MatDialog);

    @Input() can_edit: boolean;
    @Input() user_meta_dict: any = {};
    @Input() dataSource: MatTableDataSource<any>;
    @Input() id: string = '';

    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFilter: EventEmitter<string> = new EventEmitter<string>();
    @Output() onUserChanged: EventEmitter<User> = new EventEmitter<User>();

    filterValue: string = "";
    columns: any[] = [
        {name: 'edit', title: 'edit'},
        {name: 'name', title: 'Name'},
        {name: 'middle_name', title: 'Middle Name'},
        {name: 'last_name', title: 'Last Name'},
        {name: 'alias', title: 'Alias'},
        {name: 'email', title: 'Email'},
        {name: 'created_on', title: 'Date Registered'},
        {name: 'user_activity', title: 'User Activity'},
        {name: 'email_disabled', title: 'Email Disabled'},
        {name: 'sms', title: 'Sms'},
        {name: 'sms_disabled', title: 'Sms Disabled'},
        {name: 'role_names', title: 'Roles'},
        {name: 'restricted_access', title: 'Restricted'},
        {name: 'active', title: 'Active'}];

    displayedColumns: string[] = ['edit', 'Name', 'Middle Name', 'Last Name', 'Alias', 'Email', 'Date Registered', 'User Activity', 'Email Disabled', 'Sms', 'Sms Disabled', 'Roles', 'Restricted', 'Active'];

    applySort(sort: any) {
        this.onSort.emit(sort);
    }

    applyFilter(filterValue: any) {
        this.onFilter.emit(filterValue);
    }

    openDialog(user: User): void {
        const dialogConfig = new MatDialogConfig<User>();
        dialogConfig.data = user;
        dialogConfig.panelClass = ['default-form-dialog', 'user-form-dialog'];
        this.dialog.open(UserFormComponent, dialogConfig);
    }

    userChanged(user: User) {
        this.onUserChanged.emit(user);
    }
}

export interface Role {
    id: string;
    attributes: {
        name?: string,
        feature_names?: string[]
    };
    relationships?: {
        account: {
            data: {
                id: string,
                type: "account"
            }
        }
    };
}
