import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../services/notification.service";

export interface DialogData {
    form_result: string;
    config: any;
}

@Component({
    selector: 'custom-chart-form-dialog',
    templateUrl: './custom-chart-form-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomChartFormDialogComponent implements OnInit {
    chart_config: any;
    showing_hints: boolean;
    hint: string;

    constructor(private dialogRef: MatDialogRef<CustomChartFormDialogComponent>,
                private notification: NotificationService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
    }

    ngOnInit() {
        this.chart_config = this.dialogData.config;

        if (!this.chart_config) {
            this.chart_config = {
                labels: {
                    title: null,
                    sub_title: null,
                    y_axis: null,
                    y2_axis: null
                },
                hide_comments: false,
                series_list: [{
                    axis: 'y',
                    colour: null,
                    cumulative: null,
                    name: null,
                    show_limits: false,
                    type: 'line',
                    vertical_data_labels: true
                }]
            };
        }
    }

    save() {
        if (this.chart_config.series_list && this.chart_config.series_list.length > 0 && this.chart_config.series_list[0].name) {

        } else {
            this.notification.openError('Chart config is insufficient to create a chart.');
            return;
        }
        this.dialogRef.close(this.chart_config);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
