import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../services/api/api.service";
import {SeriesDataService} from "../services/series_data.service";
import {FormControl} from "@angular/forms";
import * as utils from '../lib/utils';
import {AppScope} from '../services/app_scope.service';
import {ColumnFormatsConfigDict} from './table-column-menu/table-column-menu.component';

@Component({
    selector: 'series-table-form',
    templateUrl: './series-table-form.component.html',
    standalone: false
})
export class SeriesTableFormComponent implements OnInit {
    full_series_list: any[];
    selected_series: any[] = [];
    columnsNormal = new FormControl();
    selected_process: any;
    hint: string;
    estimate_types: any[];

    all_columns: any;
    preset_colours: string[];
    col_menu_options: string[] = ['bold', 'abbreviate', 'italic', 'size', 'resize', 'fit_content', 'colour', 'decimals', 'align'];
    private colFilter: any;

    constructor(public seriesData: SeriesDataService,
                private api: ApiService,
                private appScope: AppScope) {
    }

    private _config: {
        series_list?: [{
            attributes: {
                description: string,
                event_type_name: any,
                kpi_level: any,
                name: string
            },
            "id": any,
            "type": any
        }],
        process?: {
            attributes: {
                description: string,
                name: string
            },
            id: any,
            type: string
        },
        kpis?: any[],
        columns: string[],
        normal_columns?: string[], // legacy column, to be phased out
        detail_columns?: string[], // legacy column, to be phased out
        mobile_columns?: string[],
        column_text?: {},
        estimate_type?: any,
        show_comments?: boolean,
        adjust_row_height?: boolean,
        column_formats?: ColumnFormatsConfigDict
        constrain_width?: boolean;
    };

    get config() {
        return this._config;
    }

    @Input()
    set config(config) {
        this._config = config;
        if (!this._config.column_formats) {
            this._config.column_formats = {};
        }
        //Legacy code
        if (!this._config.columns) {
            this._config.columns = [];
            if (this._config.normal_columns) {
                this._config.normal_columns.forEach(item => {
                    if (!this._config.columns.includes(item)) {
                        this._config.columns.push(item);
                    }
                })
            }
            if (this._config.detail_columns) {
                this._config.detail_columns.forEach(item => {
                    if (!this._config.columns.includes(item)) {
                        this._config.columns.push(item);
                    }
                })
            }
        }
        //Also legacy
        if (this._config.estimate_type && !Array.isArray(this._config.estimate_type)) {
            this._config.estimate_type = [this._config.estimate_type];
        }
        //this.updateSeriesTableJson();
    }

    ngOnInit() {
    }

    ngOnChanges() {
        // Check for both inputs
        if (this.config) {
            this.updateSeriesTableJson();
        }
    }

    updateSeriesTableJson() {
        const ctrl = this;
        if (ctrl.full_series_list === undefined) {
            // TODO take this as an input instead, as this causes a duplicate request
            ctrl.api.series_light.searchMany().toPromise().then((response) => {
                ctrl.full_series_list = response.data;
                if (ctrl.config.series_list) {
                    ctrl.selected_series = ctrl.full_series_list.filter(series =>
                        ctrl.config.series_list.map(item => item.id).includes(series.id));
                }
            });
        }

        if (ctrl.full_series_list && ctrl.config.series_list) {
            ctrl.selected_series = ctrl.full_series_list.filter(series =>
                ctrl.config.series_list.map(item => item.id).includes(series.id));
        }

        if (ctrl.config.process) {
            ctrl.selected_process = ctrl.config.process;
        }
    }

    selectionChange(event, type) {
        if (type === 'process') {
            if (this.selected_process) {
                this.config.process.id = this.selected_process.id;
                this.config.process.attributes.description = this.selected_process.attributes.description;
                this.config.process.attributes.name = this.selected_process.attributes.name;
                this.config.process.type = this.selected_process.type;

                this.config.series_list = [{
                    attributes: {
                        description: null,
                        event_type_name: null,
                        kpi_level: null,
                        name: null
                    },
                    "id": null,
                    "type": null
                }];

                this.selected_series = [];
            }

        }
        if (type === 'series') {
            if (this.config.series_list) {
                // @ts-ignore
                this.config.series_list = this.selected_series.map(
                    (series) => {
                        return {
                            attributes: {
                                description: series.attributes.description,
                                event_type_name: series.attributes.event_type_name,
                                kpi_level: series.attributes.kpi_level,
                                name: series.attributes.name.attributes,
                                base_type: series.attributes.base_type
                            },
                            id: series.id,
                            type: series.type
                        }
                    }
                );
            }
            this.config.process.id = "";
            this.selected_process = utils.deepCopy(this.config.process);
        }
    }

}
