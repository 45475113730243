import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import './../node_modules/pivottable/dist/pivot';
import './../node_modules/pivottable/dist/c3_renderers';
import './../node_modules/pivottable/dist/d3_renderers';
import './../node_modules/pivottable/dist/export_renderers';

import {environment} from './app/environments/environment';
import {AppModule} from './app/app.module';
// import {bootstrapApplication} from "@angular/platform-browser";
// import {AppComponent} from "./app/app.component";
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
// import {AppRoutingModule} from "./app/sub-modules/app-routing.module";
// import {AppScope} from "./app/services/app_scope.service";

// platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    // bootstrap angular1
}).catch(err => console.error(err));

// Use this once all COMPONENTS, DIRECTIVES and PIPES are standalone
// bootstrapApplication(AppComponent, {
//     providers: [
//         AppScope,
//         importProvidersFrom(AppRoutingModule),
//         {
//             provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
//             useValue: {
//                 subscriptSizing: 'dynamic'
//             }
//         }
//     ]
// });
