import {Component, Input, OnDestroy, OnInit, ViewEncapsulation, Renderer2} from '@angular/core';
import {EventService} from "../services/event.service";
import {TileDataService} from "../services/tile_data.service";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AppScope} from '../services/app_scope.service';
import {HeaderDataService} from "../services/header_data.service";
import {Series} from '../_models/series';
import * as utils from "../lib/utils";
import {Tile as TileModel} from "../_models/tile";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {DateTimeInstanceService} from "../services/date-time-instance.service";

@Component({
    selector: 'comment-drawer',
    templateUrl: './comment-drawer.component.html',
    styleUrls: ['./comment-drawer.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [DateTimeInstanceService],
    standalone: false
})
export class CommentDrawerComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    show_drawer: boolean = false;
    newComment: string = '';
    hovering_events: any[] = [];
    @Input() show_dtp: boolean = true;

    tile: TileModel = null;
    tileDtp: IDateTimePeriod;
    tileData_subscriptions: string[] = [];
    series: Series;

    constructor(private eventService: EventService,
                public tileData: TileDataService,
                private dateTimePeriodService: DateTimePeriodService,
                public dateInst: DateTimeInstanceService,
                public appScope: AppScope,
                public headerData: HeaderDataService,
                private renderer: Renderer2) {

        this.dateInst.dateTimePeriodChanged$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.tileData.comment.start = dtp.start;
            this.tileData.comment.end = dtp.end;
            this.setDtp();
        });

        this.headerData.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dateInst.dtp = dtp;
            this.tileDtp = this.tile?.attributes.custom_dtp ? this.dateTimePeriodService.constructDtp(this.tile.attributes) : dtp;
        });

        this.appScope.mentionSelected.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.replaceComment(value);
        })

        this.headerData.showingCommentPanel.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            if (value.comment_data) {
                this.tileData = value.comment_data.tileData;
                this.eventService = value.comment_data.eventService;
                if (value.comment_data.tileData.tile) {
                    this.tile = value.comment_data.tileData.tile;
                    //this.setDtp();
                }
                if (this.tileData && this.tileData.tile && !this.tileData_subscriptions.includes(this.tileData.tile.id)) {
                    this.tileData.commentIconHover.pipe(takeUntil(this.onDestroy)).subscribe(value => this.commentIconHover(value));
                    this.tileData.commentIconLeave.pipe(takeUntil(this.onDestroy)).subscribe(() => this.commentIconLeave());
                    this.tileData_subscriptions.push(this.tileData.tile.id)
                }
                if (this.eventService) {
                    this.series = this.eventService.series_list[0];
                    this.eventService.eventAdded.pipe(takeUntil(this.onDestroy)).subscribe(value => this.newComment = '');
                }
            }
        })
    }

    ngOnInit() {
    }

    setDtp() {
        this.tileDtp = utils.deepCopy(this.dateInst.dtp);
        // console.log('tileDtp: ', this.tileDtp.start);
        // console.log('comment: ', this.tileData.comment.start, this.tileData.comment.end);
        if (this.tileData?.comment?.start && this.tileData?.comment?.end) {
            this.dateInst.dtp.start = this.tileData.comment.start;
            this.dateInst.dtp.end = this.tileData.comment.end;
            if (this.dateInst.dtp.start.getTime() !== this.dateInst.dtp.end.getTime()) {
                this.tileDtp.start = this.dateInst.dtp.start;
                this.tileDtp.end = this.dateInst.dtp.end;
            }
        }
    }

    replaceComment(value) {
        let pre = this.newComment.substring(0, value.index);
        let sub = this.newComment.substr(value.index, this.newComment.length);
        this.newComment = pre + sub.replace('@' + value.search_string, '@' + value.user.attributes.username);
    }

    emitToggleComments() { //when clicking comment button on this component
        this.eventService.setShowComments(this.show_drawer);
    }

    saveComment() {
        this.tileData.addCommentClicked.next(this.newComment);
    }

    commentHover(event, index) {
        this.tileData.commentHover.next({"event": event, "index": index});
    }

    commentLeave(event, index) {
        this.tileData.commentLeave.next({"event": event, "index": index});
    }

    commentIconHover(value) {
        value.map((event) => {
            this.hovering_events.push(event.id)
        })
    }

    commentIconLeave() {
        this.hovering_events = [];
    }

    textAreaAdjust(event) {
        this.renderer.setStyle(event.target, 'height', (event.target.scrollHeight) + "px");
    }

    newDate(input) {
        return new Date(input);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
