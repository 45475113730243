import {Component, ComponentAttributes, ComponentRelationships} from "./component";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";
import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";

export class EquipmentAttributes extends ComponentAttributes<'equipment'> {
    icon: string;
}

export class EquipmentAttributesLight extends AuditMixinAttributes {
    name: string;
    description: string;
    base_type: string;
    custom_series: any;
    start_time?: string;
    end_time?: string;
}

export class EquipmentRelationships extends ComponentRelationships {
    component: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export class EquipmentRelationshipsLight extends AuditMixinRelationships {
    equipment: LIST_RELATIONSHIP_TYPE;
    series: LIST_RELATIONSHIP_TYPE;
    series_components: LIST_RELATIONSHIP_TYPE;
    revision: SINGLE_RELATIONSHIP_TYPE;
}

export class Equipment extends Component {
    attributes: EquipmentAttributes;
    relationships: EquipmentRelationships;

    constructor() {
        super();
        this.attributes.base_type = 'equipment';
        this.type = 'equipment';
        this.attributes = new EquipmentAttributes();
        this.relationships = new EquipmentRelationships();
    }
}

export class EquipmentLight extends AuditMixin {
    attributes: EquipmentAttributesLight;
    id: string;
    relationships: EquipmentRelationshipsLight;
    type: string;

    constructor() {
        super();
        this.attributes.base_type = 'equipment';
        this.type = 'equipment';
        this.attributes = new EquipmentAttributes();
        this.relationships = new EquipmentRelationshipsLight();
    }
}
