<div class="column context-tile-form">
  <div class="row center">
    <div class="column flex-third" >
      <select-search-component [label]="'Series'"
                               [classes]="'fill'"
                               [api_model]="'series_light'"
                               [value]="config.selected_series"
                               (selectionChange)="config.selected_series=$event.value"
                               class="select-search full-width">
      </select-search-component>
      <div *ngIf="config.chart_type==='custom-chart' && series_changed===true" class="reminder">Please remember to
        update series in the chart configuration section.
      </div>
    </div>

    <mat-form-field class="flex-third">
      <mat-label>Chart type</mat-label>
      <mat-select (selectionChange)="updateChartParams()"
                  [(value)]="config.chart_type">
        <mat-option *ngFor="let chart of chart_types"
                    [value]="chart.value">
          {{chart.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <select-search-component
        [label]="'Shift type'"
        [api_model]="'shift_type'"
        (selectionChange)="config.shift_type_id =$event?.value?.id"
        [value]="config.shift_type_id" class="select-search flex-third"
        [allow_none]="true"
        [compareFunctionName]="'compareValueToId'"
        [classes]="'fill'"
    >
    </select-search-component>
  </div>

  <div class="row center">

      <mat-form-field *ngIf="!!columns">
        <mat-label class="primary-value">Primary Value</mat-label>
        <mat-select [(value)]="config.primary_value">
          <mat-option *ngFor="let column of columns.all"
                      [matTooltip]="col_dict[column] ? col_dict[column].description : ''"
                      [value]="column">
            {{col_dict[column].title}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="columns && col_dict">
        <mat-form-field *ngIf="columns">
          <mat-label>Value 2</mat-label>
          <mat-select [(value)]="config.secondary_val_1">
            <mat-option *ngFor="let column of columns.all"
                        [matTooltip]="col_dict[column] ? col_dict[column].description : ''"
                        [value]="column">
              {{col_dict[column].title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="columns">
          <mat-label>Value 3</mat-label>
          <mat-select [(value)]="config.secondary_val_2">
            <mat-option *ngFor="let column of columns.all"
                        [matTooltip]="col_dict[column] ? col_dict[column].description : ''"
                        [value]="column">
              {{col_dict[column].title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>

    <div class="row center low-height">
      <mat-checkbox [(ngModel)]="config.value_only" name="value_only"
                    class="form-element-align flex-third alone">Show only primary value
      </mat-checkbox>

      <mat-checkbox [(ngModel)]="config.show_unit" name="show_header" class="form-element-align flex-third">Show unit
        ({{config.series_unit}})
      </mat-checkbox>
    </div>


  <ng-container *ngIf="config.chart_type==='custom-chart' && config.chart_config">
    <div class="chart-config-border">
      <h4>Custom chart configuration</h4>
      <custom-chart-form [config]="config.chart_config"></custom-chart-form>
    </div>
  </ng-container>

</div>
