import {Series} from "../../series";

export abstract class BaseSeriesToken {
    protected constructor(
        // Full qualifier for this token in the original text
        public readonly full_token: string
    ) {
    }

    public abstract get data_key(): string;
}

export class SeriesTokenPair {
    constructor(public readonly token: BaseSeriesToken,
                public readonly series?: Series) {
    }
}
