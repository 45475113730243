import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import * as utils from '../lib/utils';
import {SeriesDataService} from "../services/series_data.service";
import {catchError, map, take} from "rxjs/operators";
import {Observable} from "rxjs";
import {NotificationService} from "../services/notification.service";
import {OptionalSnackbarParams} from "../_typing/notification";

export interface EstimateDialogData {
    estimate: any;
    // estimateTypes: any;
    selectedEstimateType: string;
    seriesList: any;
    seriesData: SeriesDataService;
}

@Component({
    selector: 'EstimateForm',
    templateUrl: 'estimate-form.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EstimateFormComponent implements OnInit {
    fillMethods: any;
    samplePeriods: any;
    aggregationTypes: any;
    estimateTypes: any;
    seriesData: SeriesDataService;
    seriesList: any;
    estimate: any;
    estimateTypeName: string = 'Unselected';
    selectedEstimateType: any;
    selectedSeries: any;
    searchTerm: string = '';
    showing_hints: boolean = false;
    hint: string = 'Name';

    constructor(private api: ApiService,
                private dialog: MatDialog,
                private notification: NotificationService,
                public dialogRef: MatDialogRef<EstimateFormComponent>,
                @Inject(MAT_DIALOG_DATA) public data: EstimateDialogData) {
    }

    ngOnInit(): void {
        // TODO deepCopy some of these fields to prevent date from being displayed on table before it is saved.
        const estimate_schema = {
            "attributes": {
                "base_type": "estimate",
                "description": "",
                "name": "",
                "fill_method": "Constant",
                "sample_period": "month",
                "aggregation": null,
                "allow_edit": null,
                "positive_variance": true
            },
            "relationships": {
                "estimate_type": {
                    "data": null
                },
                "parent": {
                    "data": {
                        "id": null,
                        "type": "series"
                    }
                },
            },
            "type": "estimate"
        };

        this.seriesData = this.data.seriesData;

        this.fillMethods = Object.keys(this.seriesData.fill_methods);
        this.samplePeriods = this.seriesData.sample_period_names;
        this.aggregationTypes = this.seriesData.aggregation_types;
        this.estimateTypes = this.seriesData.estimate_types_list;
        if (this.estimateTypes.length === 0) {
            console.warn('Could not find any EstimateTypes. None configured for this account.');
        }

        this.seriesList = this.data.seriesList;
        this.selectedEstimateType = this.estimateTypes.find(item => item.attributes.name === this.data.selectedEstimateType);
        if (this.selectedEstimateType) {
            this.estimateTypeName = this.selectedEstimateType.attributes.name;
            estimate_schema.relationships.estimate_type.data = {
                "id": this.selectedEstimateType.id,
                "type": "estimate_type"
            };
        } else {
            this.notification.openError(`Could not find an EstimateType with name: ${this.data.selectedEstimateType}. Please make sure one has been configured for this account.`);
            this.dialogRef.close();
            return;
        }

        this.searchTerm = '';

        if (this.seriesList.length === 1) {
            this.selectedSeries = this.seriesList[0];
        }

        if (!this.data.estimate) {
            this.estimate = utils.deepCopy(estimate_schema);
        } else {
            this.api.series.getById(this.data.estimate.id).pipe(take(1)).subscribe(est => {
                this.estimate = est.data;
                this.selectedSeries = this.estimate.relationships.parent.data;
            });
        }

    }

    /**
     * Called when the selected Series is changed.
     * // TODO confirm the following statement
     * As attributes for the current series might have changed, the changes are copied over to the new selected series.
     */
    seriesSelected() {
        this.estimate.attributes.name = this.selectedSeries.attributes.name + "_F";
        if (this.selectedSeries.attributes.description) {
            this.estimate.attributes.description = `${this.selectedSeries.attributes.description} ${this.selectedEstimateType.attributes.name}`;
        } else {
            this.estimate.attributes.description = `${this.selectedSeries.attributes.name} ${this.selectedEstimateType.attributes.name}`;
        }
    }

    /**
     * Called when the selected Estimate Type is changed
     */
    estimateTypeSelected() {
        if (this.estimate.attributes.descript1ion) {
            this.estimate.attributes.description = this.estimate.attributes.description.replace(this.estimateTypeName,
                this.selectedEstimateType.attributes.name);
            this.estimateTypeName = this.selectedEstimateType.attributes.name
        }
    }

    save(): void {
        let new_estimate: Observable<any>;
        if (this.estimate.hasOwnProperty('id')) {
            new_estimate = this.api.series.obsPatch(this.estimate);
        } else {
            this.estimate.relationships.estimate_type.data.id = this.selectedEstimateType.id;
            this.estimate.relationships.parent.data.id = this.selectedSeries.id;
            this.estimate.attributes.series_id = this.selectedSeries.id;

            new_estimate = this.api.series.obsSave(this.estimate);
        }
        new_estimate.pipe(map((response): OptionalSnackbarParams => {
            this.close(response);
            return { message: 'Estimate Saved.', duration: 2000 };
        }), catchError((response): Observable<OptionalSnackbarParams> => {
            throw new Error(`EstimateForm (save) \n Estimate Not Saved: ${response.message}`);
        })).subscribe({
                next: (response: OptionalSnackbarParams): void => {
                    this.notification.openSuccess(response.message, response.duration);
                },
                error: (err) => {
                    this.notification.openError(err);
                }
            }
        );

    }

    close(newEstimate?: any) {
        this.dialogRef.close(newEstimate);
    }

    fillMethodChange() {
    }

    samplePeriodChanged() {
    }

    aggregationTypeChanged() {
    }

    nameChanged() {
    }

    descriptionChanged() {
    }
}
