import {Injectable, OnDestroy} from '@angular/core';
import {DateTimePeriodService} from "./date-time-period.service";
import {ReplaySubject, Subject} from "rxjs";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {takeUntil, tap} from "rxjs/operators";

@Injectable()
export class DateTimeInstanceService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    /** Emits once each time the DateTimePeriodService resets for account or configuration changes */
    public dateTimePeriodInitialised$: ReplaySubject<IDateTimePeriod> = new ReplaySubject<IDateTimePeriod>(1);

    /** Emits for every change on the Time Traveler (DateTimePeriodComponent) */
    public dateTimePeriodChanged$: ReplaySubject<IDateTimePeriod> = new ReplaySubject<IDateTimePeriod>(1);

    /** Emits only when the user clicks the refresh button on the Time Traveler (DateTimePeriodComponent) */
    public dateTimePeriodRefreshed$: Subject<IDateTimePeriod> = new Subject<IDateTimePeriod>();

    /** A DateTimePeriod object representing the current state of the Time Traveler */
    public dtp: IDateTimePeriod;

    /** A boolean indicating when the Time Traveler (dtp) has changed but the user has not clicked refresh
     * (so actual dtp and page data are out of sync). This is used when the component doesn't want to run a full
     * refresh on each change, but other functions on that component need the latest dtp to run */
    public hasChanges = false;

    constructor(private dtpService: DateTimePeriodService) {
        //From DatePeriodService
        this.dtpService.dtpInitialised$.pipe(
            takeUntil(this.onDestroy),
            tap((dtp: IDateTimePeriod) => {
                this.dtp = dtp;
                this.dateTimePeriodInitialised$.next(this.dtp);
                this.emitDateTimePeriodChanged(this.dtp);
            })).subscribe();

        this.dateTimePeriodChanged$.pipe(takeUntil(this.onDestroy)).subscribe((dtp: IDateTimePeriod) => {
            //console.log('DateTimePeriod Instance value changed: ', dtp);
            this.hasChanges = true;
            this.dtp = dtp;
        });

        //From DatePeriodComponent
        this.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp: IDateTimePeriod) => {
            this.hasChanges = false;
            this.dtp = dtp;
        });

    }

    emitDateTimePeriodRefreshed(dtp: IDateTimePeriod) {
        //console.log("Refreshing dtp from instance.")
        this.dateTimePeriodRefreshed$.next(dtp);
    }

    emitDateTimePeriodChanged(dtp: IDateTimePeriod) {
        this.dateTimePeriodChanged$.next(dtp);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
