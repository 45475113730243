import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation, OnDestroy, HostListener
} from '@angular/core';
import {Subject, Subscription, take} from "rxjs";
import {ApiService} from '../../services/api/api.service';
import {takeUntil, debounceTime} from "rxjs/operators";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {SeriesDataService} from "../../services/series_data.service";
import * as utils from "../../lib/utils";
import {NameOrDescriptionPipe} from "../../shared/pipes";
import {AppScope} from "../../services/app_scope.service";
import {TileDataService} from '../../services/tile_data.service';
import {ContextService, SeriesSummaryConfig} from '../../services/context.service';
import {NotificationService} from "../../services/notification.service";
import {CdkDragMove} from "@angular/cdk/drag-drop";
import {CachingService} from "../../services/caching.service";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesSummaryViewModel} from "./series-summary-view-model";
import {FormDialogService} from "../../services/form-dialog.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'series-summary',
    templateUrl: './series-summary.component.html',
    styleUrls: ['./series-summary.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [ContextService],
    standalone: false
})
export class SeriesSummaryComponent implements OnInit, OnDestroy {
    @Input() config: SeriesSummaryConfig;
    @Input() show_header: boolean;
    @Input() title: string = '';
    @Input() save_content: Subject<any>;
    private _refresh = 0;
    @Input() set refresh(value: number) {
        if (this._refresh !== value) {
            this.reset();
        }
        this._refresh = value;
    }

    get refresh(): number {
        return this._refresh
    }

    editing: any = false;
    font_size: number = 3;
    font_units: string = 'vw';
    menu_visible: boolean = false;
    dtp: IDateTimePeriod;
    summary: any;
    selected_columns: any;
    styles: { [key: string]: any } = {};
    model_index_map = {
        'secondary_value1': 'secondary_val_2',
        'secondary_value2': 'secondary_val_2'
    };

    @ViewChild('series_summary_tile', {static: true}) summary_tile: ElementRef;
    @ViewChild('series_summary_svg') summary_SVG: ElementRef;
    @ViewChild('font_context_menu') font_context_menu: ElementRef;
    @ViewChild('second') second: ElementRef;
    red: string = 'red';
    green: string = 'green';
    category_colour: string = 'default';
    viewBox = {scale_w: null, scale_h: null};
    context_model: any; // {title: any, primary_value: any, sparkline: any};
    dragging: boolean = false;
    lastMouseCoords: any;
    selected_model: any;
    offset_height = 0;

    private $series_summary: Subscription;
    private chart_resized = new Subject<void>();
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private renderer: Renderer2,
                private appScope: AppScope,
                private tileData: TileDataService,
                private headerData: HeaderDataService,
                public contextService: ContextService,
                public seriesData: SeriesDataService,
                public notification: NotificationService,
                private cache: CachingService,
                private formDialogService: FormDialogService) {
    }

    ngOnInit() {
        const ctrl = this;

        this.reset();
        this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.cache.clearCache();
            ctrl.getSummary();
        });
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.cache.clearCache();
            ctrl.getSummary();
        });
        this.tileData.editing.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            this.editing = newBool;
            if (newBool === false) {
                this.menu_visible = false;
                this.deselectAll();
            }
        });
        this.headerData.page_edit_toggle.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            if (!this.tileData?.tile) {
                // Flowcharts
                this.editing = newBool;
            }
            if (newBool === false) {
                this.menu_visible = false;
                this.editing = false;
            }
        });
        this.tileData?.save_content?.pipe(takeUntil(this.onDestroy)).subscribe(event => {
            this.getContainerSize();
            if (ctrl.config.chart_config) {
                ctrl.tileData.tileResize.next(ctrl.config.chart_config['set_size']);
            }
        });

        //Send resize event to generic chart after chart is resized manually
        this.chart_resized.pipe(takeUntil(this.onDestroy),
            debounceTime(500)).subscribe(() => {
            if (this.config.chart_type === 'custom-chart') {
                this.getContainerSize();
                this.tileData.tileResize.next(this.config.chart_config['set_size']);
            }
        })
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        const ctrl = this;
        this.getStyleDict();
        if (ctrl.config.chart_type !== 'custom-chart') return;

        setTimeout(() => {
            this.getContainerSize();
            ctrl.tileData.tileResize.next(ctrl.config.chart_config['set_size']);
        }, 300);
    }

    getContainerSize() {
        const ctrl = this;
        let viewHeight = this.summary_tile.nativeElement.clientHeight;
        let viewWidth = this.summary_tile.nativeElement.clientWidth;
        this.viewBox = {scale_w: viewWidth, scale_h: viewHeight};
        if (this.context_model && this.context_model.custom_chart && this.config.chart_type === 'custom-chart') {
            ctrl.config.chart_config['set_size'] = {
                height: (this.viewBox.scale_h * this.context_model.custom_chart.height() / 100) - this.offset_height - 18,
                width: (this.viewBox.scale_w * this.context_model.custom_chart.width() / 100) - 18
            }
        }
    }

    reset() {
        const ctrl = this;
        if (!this.config.selected_series) {
            return
        }
        if (!this.config.svg) {
            this.config.svg = {};
        }
        this.config = this.contextService.setDefaults(this.config);
        this.selected_columns = this.contextService.getSelectedColumns(this.config);

        if (ctrl.tileData.tile && ctrl.tileData.tile.attributes.category) {
            ctrl.category_colour = ctrl.tileData.tile.attributes.category.colour
        }
        if (!ctrl.title) {
            ctrl.title = new NameOrDescriptionPipe().transform(ctrl.config.selected_series.attributes);
        }

        if (this.tileData.tile && this.tileData.tile.attributes.show_header) {
            this.offset_height = 30; //chart padding
            ctrl.tileData.setDefaultTitle(ctrl.title);
        } else {
            this.offset_height = 30; //est header height
        }

        const colours = ctrl.contextService.getContextColours();
        ctrl.red = colours.red;
        ctrl.green = colours.green;

    }

    getSummary() {
        const ctrl = this;
        this.$series_summary = utils.refreshSubscription(this.$series_summary);
        this.$series_summary = ctrl.contextService.getSeriesSummary(ctrl.config, ctrl.selected_columns, ctrl.dtp)
            .pipe(takeUntil(this.onDestroy)).subscribe(result => {
                ctrl.summary = result[0];
                ctrl.cache.cacheSeriesSummary(ctrl.config.selected_series.id, ctrl.summary, true)
                ctrl.createContextModel();
            })

    }

    createContextModel() {
        const ctrl = this;
        ctrl.getContainerSize();
        this.context_model = new SeriesSummaryViewModel(ctrl.config, this.viewBox, this.offset_height);
        this.getStyleDict();
        this.chart_resized.next();
    }

    updateElementsLocation(deltaX, deltaY, model) {
        //let context = this.context_model;
        this.context_model.updateSelectedTitleLocation(deltaX, deltaY, model);
        // context.updateSelectedContextsLocation(deltaX, deltaY);

    };

    //For dragging processes, text, series, groups, constants, equipment
    onDragging(event, el) {
        let element = event.source.getRootElement();
        let rotate = '0';
        if (typeof el.rotate === "function") {
            rotate = el.rotate();
        }
        element.style.transform = 'rotate(' + rotate + 'deg)';
        if (this.editing) {
            this.dragging = true;
            this.onDragStarted(el);
            let lastMouseCoords = this.lastMouseCoords;
            let curCoords = this.translateCoordinates(event.event);
            let deltaX = curCoords.x - lastMouseCoords.x;
            let deltaY = curCoords.y - lastMouseCoords.y;
            this.updateElementsLocation(deltaX, deltaY, el);
            this.lastMouseCoords = curCoords;
        } else {
            this.notification.openError('Please use edit mode to drag', 5000);
        }
    }

    dragMove(dragHandle: HTMLElement, element, event: CdkDragMove<any>) {
        if (this.editing) {
            this.dragging = true;
            let lastMouseCoords = this.lastMouseCoords;
            let curCoords = this.translateCoordinates(event.event);
            let deltaX = curCoords.x - lastMouseCoords.x;
            let deltaY = curCoords.y - lastMouseCoords.y;
            this.updateElementsSize(deltaX, deltaY, element);
            this.lastMouseCoords = curCoords;
            this.chart_resized.next();
        } else {
            this.notification.openError('Please use edit mode to drag',);
        }
    }

    updateElementsSize(deltaX, deltaY, el) {
        el.data.w += deltaX;
        el.data.h += deltaY;
    }

    // Translate the coordinates so they are relative to the svg element.
    translateCoordinates(event) {
        let svg = this.summary_SVG.nativeElement;
        let pt = svg.createSVGPoint();
        pt.x = event.x / this.viewBox.scale_w * 100;
        pt.y = event.y / this.viewBox.scale_h * 100;
        return ({x: pt.x, y: pt.y});
    };

    processDown(event, el) {
        event.stopPropagation();
        this.lastMouseCoords = this.translateCoordinates(event);
    }

    onDragStarted(el) {
        if (!el.selected()) {
            this.deselectAll();
            el.select();
        }
    }

    elementClicked(event, element) {
        event.stopPropagation();
        if (!this.editing) return;

        if (this.dragging === true) {
            this.dragging = false;
            return;
        }
        if (event.shiftKey === false && event.ctrlKey === false) {
            this.deselectAll();
        }
        element.select();
    }

    deselectAll() {
        if (!this.context_model) return;

        let keys = this.context_model.model_keys;
        keys.forEach(key => {
            if (this.context_model[key]) {
                this.context_model[key].deselect();
            }
        })
    }

    setFontSize(event) {
        this.selected_model.styles.font_size = event + this.font_units;
    }

    setColour(event, style) {
        this.selected_model.styles.colour = event;
    }

    setStyle(event, style) {
        event.stopPropagation();
        this.selected_model.styles[style] = !this.selected_model.styles[style];
    }

    getStyleDict() {
        if (!this.context_model) return;
        this.context_model.model_keys.forEach(model_name => {
            if (this.context_model?.hasOwnProperty(model_name))
                this.styles[model_name] = this.getStyles(this.context_model[model_name], this.model_index_map[model_name] || model_name);
        })
        this.adjustFontSizeForPrint();
    }

    getStyles(model, index_text = '') {
        if (!model.styles) return;
        let colour;
        if (!model.styles.colour) {
            let fav = this.summary[this.contextService.est_favourability_mapping[this.config[index_text]]];

            if (fav === true) {
                colour = this.green;
            } else if (fav === false) {
                colour = this.red
            }
            if (colour) {
                model.styles.colour = colour;
            } else {
                model.styles.colour = '#000000';
            }
        }
        let cursor = this.editing ? 'move' : 'default';
        let font_size = model.styles.font_size.includes('vw') ? this.convertPXToVW(model.styles.font_size.replace('vw', '')) : model.styles.font_size;
        return {
            'font-size': font_size,
            'font-weight': model.styles.bold ? 'bold' : 'normal',
            'text-decoration': model.styles.underline ? 'underline' : 'none',
            'font-style': model.styles.italic ? 'italic' : 'normal',
            'border-width': model.styles.border ? '1px' : 0,
            'background-color': model.styles.background,
            'fill': model.styles.colour,
            'cursor': cursor
        }
    }

    convertPXToVW(px) {
        let page: Element = this.summary_tile.nativeElement.closest("div.page.printing-mode");
        let width = document.documentElement.clientWidth;
        if (page) {
            width = page.clientWidth;
        }
        return (px * width) / 100;
    }

    adjustFontSizeForPrint() {
        const texts = document.querySelectorAll(".series-summary-tile svg text");
        const printHeight = window.innerHeight; // Approximate print height

        texts.forEach(text => {
            const vhValue = parseFloat(getComputedStyle(text).fontSize) / printHeight * 100;
            text['style'].fontSize = `${(vhValue / 100) * printHeight}px`;
        });
    }


    contextMenu(event, model): void {
        if (this.editing) {
            let params = {
                selected_model: null,
                origin: null,
                original_models: {},
                font_size: null,
                menu_visible: false
            };

            event.stopPropagation();
            event.preventDefault();
            this.selected_model = model;
            params.selected_model = model;
            params.original_models[params.selected_model.type] = utils.deepCopy(params.selected_model);
            if (!['custom-chart', 'sparkline'].includes(model.data.type)) {
                params.font_size = parseFloat(params.selected_model.styles.font_size.replace(this.font_units, ''));
            }
            this.openModal(event, params);
        } else {
            this.notification.openError('Please use edit mode to change font styles', 3000);
        }
    };

    openModal(event: any, parameters: any) {
        const data_config = {
            component: 'SeriesSummaryComponent',
            parameters: parameters,
            position: {top: 2, left: 60},
            show_close: true
        };

        const setStyles: (event) => void = (event) => {
            if (event.style === 'font-size') {
                this.setFontSize(event.event);
            } else if (event.style === 'colour') {
                this.setColour(event.event, event.style)
            } else {
                this.setStyle(event.event, event.style)
            }
        }
        const panelClass = 'series-summary-menu-dialog';
        const modalDialog = this.formDialogService.openCustomDialog(event, data_config, panelClass, setStyles);
        modalDialog.afterClosed().subscribe(result => {
            this.getStyleDict();
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
