import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {combineLatest, of, Subject} from "rxjs";
import {debounceTime, filter, map, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {PlantDataService} from "../services/plant-data/plant_data.service";
import {AppScope} from "../services/app_scope.service";
import {ApiService} from "../services/api/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {
    FolderEntry,
    MenuTreeService,
    StaticEntry,
    StaticFolderEntry, TreeEntry
} from "../services/menu-tree.service";
import {AccountService} from "../services/account.service";
import {UserData} from "../services/user_data.service";
import {ActivationEnd, Router} from "@angular/router";
import {getBaseFilter, getAccountFilter, getRelationWithNameFilter} from "../services/api/filter_utils";
import {deepCopy} from "../lib/utils";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {SessionState} from "../_models/session-state";

@Component({
    selector: 'sidenavigation',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['./side-nav.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class SideNavigation implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    events: string[] = [];
    opened: boolean;
    reports: any[];
    plants: any[];
    selected_plant: any;
    user: any;
    restricted: boolean;

    dataSource = new MatTreeNestedDataSource();
    topLevelFolderNode: Partial<FolderEntry>;
    menu_groups: { [key: string]: string };
    mobile: boolean = false;
    show_plant: boolean = false;

    selected_account_id: string;
    process_search: string = '';
    search_results: any[] = [];
    session_search: string = '';
    session_results: any[] = [];
    session_data: { [key: string]: SessionState[] };
    sidenav_selected_item: string; //For current session_state id
    isDragging: boolean = false;
    editMode: boolean = false;
    private processSearchSubject: Subject<string> = new Subject<string>();
    private sessionSearchSubject: Subject<string> = new Subject<string>();
    staticFolders: (StaticFolderEntry | StaticEntry)[];
    pinnedItems: (StaticFolderEntry | StaticEntry)[];

    constructor(
        public plantData: PlantDataService,
        public appScope: AppScope,
        private api: ApiService,
        public headerData: HeaderDataService,
        private menuTreeService: MenuTreeService,
        private accountService: AccountService,
        private userData: UserData,
        private router: Router) {

        this.router.events.pipe(
            filter(event => event instanceof ActivationEnd),
            takeUntil(this.onDestroy))
            .subscribe((event: ActivationEnd) => {
                this.sidenav_selected_item = event.snapshot.url[event.snapshot.url.length - 1]?.path;
            })
    }


    ngOnInit() {
        const ctrl = this;
        this.appScope.auth_complete.promise.then(() => {
            ctrl.restricted = ctrl.appScope.current_user.restricted_access;
            ctrl.user = ctrl.appScope.current_user;
            ctrl.mobile = !ctrl.appScope.isNotMobile;

            ctrl.menu_groups = this.headerData.folder_options();
            const $session_states = this.menuTreeService.sessionStatesChanged.pipe(
                takeUntil(this.onDestroy), tap((result) => {
                    this.session_data = result;
                })).subscribe();

            const folders_source = this.menuTreeService.menuTreeChanged;
            const active_account_source = this.accountService.activeAccountChanged;

            combineLatest([folders_source, active_account_source])
                .pipe(
                    takeUntil(this.onDestroy),
                    map(([folders, active_account]) => {
                        return {folders, active_account};
                    }))
                .subscribe(response => {
                    const folders = response.folders;
                    const active_account = response.active_account;

                    if (!active_account) {
                        // the active account has not been determined yet
                        return;
                    }
                    if (ctrl.restricted) {
                        ctrl.buildRestrictedMenu(folders, active_account.account_id);
                    } else {
                        this.buildMenu(folders, active_account.account_id);
                    }
                });

            this.accountService.activeAccountChanged.pipe(takeUntil(this.onDestroy)).subscribe((active_account) => {
                ctrl.selected_account_id = active_account.account_id;
                ctrl.plantData.process_focus = null; //TODO Check this on url paste if using authguards
                this.session_search = '';
                this.session_results = [];

                ctrl.plantData.getPlants(ctrl.selected_account_id)
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe(plants => {
                        this.plants = plants;
                        ctrl.appScope.plants = plants;
                        ctrl.appScope.selected_plant = plants[0];
                        ctrl.plants = plants;
                        ctrl.selected_plant = ctrl.appScope.selected_plant;
                        plants.forEach(p => this.appScope.processTreeDataDict[p.id] = this.appScope.getProcessDictItem(p, true))
                    });

                this.search_results = [];
                this.process_search = '';

                const processLightOptions = new SearchQueryOptions();
                processLightOptions.ignore_routing = true;
                processLightOptions.filters = [
                    getAccountFilter(ctrl.selected_account_id)
                ];

                const processOptions = new SearchQueryOptions();
                processOptions.ignore_routing = true;
                processOptions.filters = [
                    getRelationWithNameFilter('component_type', 'ReportTree'),
                    getAccountFilter(active_account.account_id)
                ];

                this.api.process.searchMany(processOptions).pipe(takeUntil(this.onDestroy)).subscribe(response => {
                    ctrl.reports = response.data;
                    ctrl.reports.map(item => {
                        item.is_report = true;
                        this.appScope.processTreeDataDict[item.id] = this.appScope.getProcessDictItem(item, true)
                    });
                });

                this.menuTreeService.refresh();
            });

            this.appScope.sidenavOpenSubject.pipe(takeUntil(this.onDestroy), tap(value => {
                if(value && this.editMode){
                    this.menuTreeService.refresh();
                }
            })).subscribe();
        });

        this.processSearchSubject.pipe(
            takeUntil(this.onDestroy),
            debounceTime(300),
            switchMap(search_string => {
                if (this.process_search.length < 1) {
                    this.search_results = [];
                    return of([]);
                }
                let options = new SearchQueryOptions();
                options.filters = [{
                    and: [getAccountFilter(this.selected_account_id),
                        {or: [getBaseFilter(`%${search_string}%`, 'name', 'ilike'), getBaseFilter(`%${search_string}`, 'description', 'ilike')]}
                    ]
                }]
                return this.api.process_light.searchMany(options).pipe(take(1), tap(result => {
                    result.data.forEach(p => {
                        const item = this.appScope.getProcessDictItem(p, false);
                        if (this.search_results.map(s => s.id).indexOf(item.id) < 0) {
                            this.search_results.push(item)
                        }
                    })
                    this.search_results = this.search_results.slice(0, 50);
                }))
            })).subscribe();

        this.sessionSearchSubject.pipe(
            takeUntil(this.onDestroy),
            debounceTime(300)
        ).subscribe(search_string => {
            if (this.session_search.length < 1 || !this.selected_account_id || !this.session_data) {
                this.session_results = [];
                return;
            }
            this.session_results = deepCopy(this.session_data[this.selected_account_id]);
            this.session_results = this.session_results.filter(item => {
                return item.attributes.name?.toLowerCase().indexOf(this.session_search.toLowerCase()) > -1;
            }).sort((a, b) => {
                return a.attributes.name > b.attributes.name ? 1 : -1;
            });
            this.session_results = this.session_results.slice(0, 50);
        });

        this.headerData.page_edit_toggle.pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.editMode = value;
            });

    }

    buildRestrictedMenu(account_folders, active_account_id: string) {
        if (this.user.json && this.user.json.length > 0) {
            const folder_name: string = this.menu_groups['my_views'] ? this.menu_groups['my_views'] : 'My Views';

            let folder = new StaticFolderEntry(folder_name);
            this.user.json.forEach(item => {
                let static_entry = new StaticEntry(item.name, item.url);
                folder.contents.push(static_entry);
            });
            if (!account_folders[active_account_id]) {
                account_folders[active_account_id] = [];
            }

            account_folders[active_account_id].unshift(folder);
        }

        this._setTopLevelNode(account_folders[active_account_id]);
    }

    buildMenu(account_folders, active_account_id: string) {
        const ctrl = this;

        if (!this.restricted) {
            if (!this.staticFolders?.length) {
                this.staticFolders = [];
            }
            if (!this.staticFolders['built']) {
                let folder = new StaticFolderEntry(
                    ctrl.menu_groups['insights'] ? ctrl.menu_groups['insights'] : 'Insights',
                    this.buildStatic('insights'));
                this.staticFolders.push(folder);

                if (!ctrl.mobile) {
                    folder = new StaticFolderEntry(
                        ctrl.menu_groups['internal controls'] ? ctrl.menu_groups['internal controls'] : 'Internal controls',
                        this.buildStatic('internal controls'));
                    this.staticFolders.push(folder);

                    folder = new StaticFolderEntry(
                        ctrl.menu_groups['wire modules'] ? ctrl.menu_groups['wire modules'] : 'Wire modules',
                        [
                            new StaticFolderEntry(
                                ctrl.menu_groups['mining module'] ? ctrl.menu_groups['mining module'] : 'mining module',
                                this.buildStatic('mining module')),
                            new StaticFolderEntry(
                                ctrl.menu_groups['water balance module'] ? ctrl.menu_groups['water balance module'] : 'water balance module',
                                this.buildStatic('water balance module'))
                        ]
                    );

                    this.staticFolders.push(folder);

                    folder = new StaticFolderEntry(
                        ctrl.menu_groups['configuration'] ? ctrl.menu_groups['configuration'] : 'Configuration',
                        this.buildStatic('configuration'));
                    this.staticFolders.push(folder);

                    folder = new StaticFolderEntry(
                        ctrl.menu_groups['planning'] ? ctrl.menu_groups['planning'] : 'Planning',
                        this.buildStatic('planning'));
                    this.staticFolders.push(folder);

                    this.staticFolders['built'] = true;
                }
            }
        }

        let pinnedEntry = new StaticEntry('All Pages', '/view/page_list_view');
        this.pinnedItems = [pinnedEntry];
        this._setTopLevelNode(account_folders[active_account_id]);
    }

    private _setTopLevelNode(folders: TreeEntry[]) {
        this.topLevelFolderNode = {
            contents: folders,
            menu_entry_count: folders?.length,
            type: 0,
            id: 'top'
        };
    }

    buildStatic(folder) {
        const ctrl = this;
        let contents = [];
        switch (folder) {
            case 'insights' :
                contents.push(new StaticEntry('Quick Charts', '/view/quick_charts/'));
                if (!ctrl.mobile) {
                    contents.push(
                        new StaticEntry('Analysis Tools', '/view/analysis_tools'),
                        new StaticEntry('Value Driver Trees', '/view/value_driver_trees/'),
                        new StaticEntry('Data Exceptions', '/view/data_exceptions/'),
                        new StaticEntry(this.appScope.clientDict("component-events-side-menu"), '/view/component_events/'),
                        new StaticEntry(this.appScope.clientDict('ore-body-view'), '/view/ore-body-group-view/')
                    );
                }
                break;
            case 'internal controls' :
                contents.push(
                    new StaticEntry('Audit History', '/view/audit_history/'),
                    new StaticEntry('Automated Raw Data Collection Events', '/view/collection_events/'),
                    new StaticEntry('Background Jobs', '/view/jobs'),
                    new StaticEntry('Calculation Validation Check', '/view/calculation_check/'),
                    new StaticEntry('Component Template Check', '/view/validate_component_templates/'),
                    new StaticEntry('Lock Templates', '/view/lock_template_table_view/'),
                    new StaticEntry('Model Pivot Tables', '/view/event_pivot'),
                    new StaticEntry('Process Access', '/view/process_access_sheet/'),
                    new StaticEntry('User Permission Insights', '/view/audit_pivot/')
                );
                break;
            case 'mining module' :
                contents.push(
                    new StaticEntry('Equipment List', '/view/equipment_sheet/'),
                    new StaticEntry(this.appScope.clientDict('ore-bodies-side-menu'), '/view/ore_body_sheet/'),
                    new StaticEntry('Event Types', '/view/event_types/'),
                    new StaticEntry(this.appScope.clientDict("component-types-side-menu"), '/view/component_types/'),
                    new StaticEntry(this.appScope.clientDict('ore-body-types-side-menu'), '/view/ore_body_types/'),
                    new StaticEntry(this.appScope.clientDict('ore-body-view'), '/view/ore-body-group-view/')
                );
                break;
            case 'water balance module' :
                contents.push(
                    new StaticEntry('Water Balance Scenario', '/view/water_balance/'),
                    new StaticEntry('Water Bodies', '/view/custom_process_sheet/WaterBody/'),
                );
                break;
            case 'configuration' :
                contents.push(
                    new StaticEntry(this.appScope.clientDict("component-events-side-menu"), '/view/component_events/'),
                    new StaticEntry('Default Shift Times', '/view/shift_sheet/'),
                    new StaticEntry('Data Source Mapping Table', '/view/collector_mapper_sheet/'),
                    new StaticEntry('Event Types', '/view/event_types/'),
                    new StaticEntry('Process List', '/view/edit_process/'),
                    new StaticEntry('Process Streams', '/view/edit_streams/'),
                    new StaticEntry('Report Configuration', '/view/edit_series_components/'),
                    new StaticEntry('Series List', '/view/edit_series/'),
                    new StaticEntry('Estimates', '/view/estimate_sheet/'),
                    new StaticEntry('Solver Templates', '/view/solver_templates/'),
                );
                break;
            case 'planning' :
                contents.push(
                    new StaticEntry(this.appScope.clientDict("single-component-events-side-menu"), '/view/single_component_events/'),
                    new StaticEntry('Targets / Estimates', '/view/estimate_sheet/'),
                    new StaticEntry('Recovery Grade Forecast (beta)', '/view/recovery_grade_forecast/'),
                );
                break;
        }

        return contents;
    }

    searchProcesses() {
        this.processSearchSubject.next(this.process_search);
    }

    searchSessionStates() {
        this.sessionSearchSubject.next(this.session_search);
    }

    closeSidenav() {
        if (!this.isDragging) { // Only close if not dragging
            this.appScope.sidenavOpenSubject.next(false);
            this.appScope.sidenav_open = false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
