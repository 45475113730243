<div class="page">
  <section>
    <div class="row" style="justify-content: space-between">
      <div class="table-filter">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select (selectionChange)="codeFilterChange($event)" multiple>
            <mat-option *ngFor="let filter of message_filters | async" [value]="filter.code">
              {{filter.message}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
      </div>
    </div>

    <div class="table-container">
      <table [dataSource]="dataSource"
             class="table calculation-check-table" mat-table>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element"> {{summary[element.type][element.type_id].name}} </td>
        </ng-container>
        <ng-container matColumnDef="property">
          <th mat-header-cell *matHeaderCellDef>Property</th>
          <td mat-cell *matCellDef="let element"> {{summary.constant_properties[element.property_id].name}} </td>
        </ng-container>
        <ng-container matColumnDef="messages">
          <th mat-header-cell *matHeaderCellDef>Problems</th>
          <td mat-cell *matCellDef="let element">
            <ul>
              <li *ngFor="let m of element.error_messages">{{m.message}}</li>
            </ul>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </section>
</div>
