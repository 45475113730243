<select-many-search-api
    [fetchData]="fetchData"
    [fetchSelected]="fetchSelected"
    (selectChange)="emitEvent($event)"
    (selectChangeComplete)="emitEventComplete($event)"
    (itemsAdded)="emitItemsAdded($event)"
    (itemsRemoved)="emitItemsRemoved($event)"
    (accountsChange)="filterByAccount($event)"
    [allowAccountSelection]="allowAccountSelection"
    [allowedAccountIds]="allowedAccountIds"
    (closeWithoutChange)="closeWithoutChange.emit()"
    [openOnInit]="openOnInit"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [disable_items]="disable_items"
    [stringFunction]="stringFunction"
    [compareFunctionName]="compareFunctionName"
    [value]="value"
    [classes]="classes"
    [label]="label"
    style="min-width:inherit;max-width:inherit;">
</select-many-search-api>
