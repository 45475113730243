<div class="registration-wrapper">
  <div class="container form-dialog register-form">
    <form class="form-section column space-between">
          <div class="row">
            <mat-form-field>
              <mat-label>Days before expiring</mat-label>
              <input matInput type="number" step="any" id="days_before_expiry" [formControl]="days" name="days"/>
            </mat-form-field>
          </div>

         <div class="row form-field">
            <mat-checkbox *ngIf="appScope.current_user" id="auth_token" class="self-center"
                          [checked]="appScope.current_user.auth_token_active"
                          [disabled]="false">Auth Token active
            </mat-checkbox>
          </div>

          <div class="row form-field">
            <button mat-button (click)="generateToken()" class="bordered-button">Create</button>
            <button mat-button (click)="deactivateToken()" class="bordered-button">Remove</button>
          </div>
          <div *ngIf="auth_token" class="row form-field">
            <label>Please copy the token below. Wire does not store a copy.</label>
          </div>
          <div *ngIf="auth_token" class="row">
              <div style="word-break: break-all;" ><strong>{{auth_token}}</strong> </div>
          </div>
        </form>
  </div>
</div>
