import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CollationSeriesData, CollationSeriesTableService} from "./collation-series-table.service";
import {Series} from '../../_models/series';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'collation-series-table',
    templateUrl: './collation-series-table.component.html',
    styleUrls: ['./collation-series-table.component.less'],
    providers: [CollationSeriesTableService],
    standalone: false
})
export class CollationSeriesTableComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() series: Series;
    //@Output() collation_type: EventEmitter<boolean> = new EventEmitter<boolean>();

    collation_data: CollationSeriesData;

    constructor(private cps: CollationSeriesTableService) {
    }

    ngOnInit(): void {
        this.cps.$collationData.pipe(takeUntil(this.onDestroy))
            .subscribe(row => {
                this.collation_data = row;
                //this.collation_type.emit(true);
            });
        this.cps.getCollationSeries(this.series);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
