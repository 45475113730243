import {Pipe, PipeTransform} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {
    abbreviateNumber,
    dateFormattingConfig,
    decimalNumber,
    significantNumber,
    sortObjectsByProperty,
    stringDate,
    thousandsSeparate, upperfirst
} from '../lib/utils';
import {AppScope} from '../services/app_scope.service';
import {capitalize, map} from "lodash-es";
import {DomSanitizer} from "@angular/platform-browser";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {snakeCase} from "lodash-es";

@Pipe({
    name: 'matchTitleToCount',
    standalone: false
})
export class MatchTitleToCount implements PipeTransform {
    //Returns all lowercase except first letter, to only affect first letter use | upperfirst
    transform(value: any, single: string, plural: string): any {
        if (value === 1 && single) {
            return value + ' ' + single;
        } else {
            return value + ' ' + plural;
        }
    }
}

/**Some Angular pipes:
 {{ value_expression | uppercase }}
 Transforms text to all upper case.

 {{ value_expression | titlecase }}
 Capitalizes the first letter of each word and transforms the rest of the word to lower case.

 {{ value_expression | upperfirst }}
 Capitalizes the first letter and transforms the rest of the word to lower case.
 **/

@Pipe({
    name: 'capitalise',
    standalone: false
})
export class CapitalisePipe implements PipeTransform {
    // Returns all lowercase except first letter, to only affect first letter use | upperfirst
    transform(value: any): any {
        return capitalize(value);
    }
}

@Pipe({
    name: 'nameOrDescription',
    standalone: false
})
export class NameOrDescriptionPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) return value;
        return value.description ? value.description : value.name;
    }
}

@Pipe({
    name: 'nameAndDescription',
    standalone: false
})
export class NameAndDescriptionPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value.description) {
            return value.description + ' - ' + value.name;
        } else {
            return value.name;
        }
    }
}


@Pipe({
    name: 'titlecaseForWire',
    standalone: false
})
export class TitlecaseForWirePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return value;
        }
        if (value === 'mms' || value === 'rar') {
            return value.toUpperCase();
        }
        return map(value.split(" "), capitalize).join(" ");
    }
}

@Pipe({
    name: 'removeUnderscore',
    standalone: false
})
export class RemoveUnderscorePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return;
        }
        return value.replace(/_/g, " ");
    }
}

@Pipe({
    name: 'stringDate',
    standalone: false
})
export class StringDatePipe implements PipeTransform {
    transform(value: any, args?: dateFormattingConfig) {
        if (!value) {
            return;
        }
        return stringDate(value, args);
    }
}

@Pipe({
    name: 'abbreviateNumber',
    standalone: false
})
export class AbbreviateNumber implements PipeTransform {
    transform(value: any, unit: any): any {
        return abbreviateNumber(value, unit);
    }
}

@Pipe({
    name: 'formatNumber',
    standalone: false
})
export class FormatNumberPipe implements PipeTransform {
    private percentPipe = new PercentagePipe()

    transform(value: any, decimals?: number, show_sig?: boolean, precision?: any, show_full?: boolean, element?: any, columnName?: string): any {
        if (!value && value !== 0) {
            return value;
        }
        if (show_full) {
            return thousandsSeparate(parseFloat(value));
        }
        if (columnName) {
            value = this.percentPipe.transform(value, columnName, false);
        }

        /**User has selected a column specific decimal setting on a table**/
        if (decimals || decimals === 0) {
            return decimalNumber(value, decimals);
        }
        /**The decimal places attribute set on a series and returned either on the series object or in GSS**/
        if (element?.attributes?.decimal_places ?? false) {
            return decimalNumber(value, element.attributes.decimal_places);
        }
        if (element?.['DecimalPlaces'] ?? false) {
            return decimalNumber(value, element['DecimalPlaces']);
        }

        /**User has 'toggled' significant numbers (backward compatibility)**/
        if (!show_sig === false) {
            return significantNumber(value, precision);
        }
        return thousandsSeparate(parseFloat(value));
    }
}

@Pipe({
    name: 'significantNumbers',
    standalone: false
})
export class SignificantNumberPipe implements PipeTransform {
    transform(value: any, show_sig?: boolean, precision?: any): any {
        if (!show_sig === false) {
            return significantNumber(value, precision);
        }
        return value;
    }
}

@Pipe({
    name: 'percentage',
    standalone: false
})
export class PercentagePipe implements PipeTransform {

    transform(value: any, name: string, show_sig?: boolean, precision?: any): any {
        // If showing a percentage column then return significanNumber of <value * 100>
        if (!name || !value || isNaN(Number(value))) {
            return value;
        }
        if (name.toString().includes('%') && value !== undefined && value !== null) {
            return significantNumber(value * 100, precision);
        }
        //Otherwise return as normal for significantNumber pipe
        if (!show_sig === false) {
            return significantNumber(value, precision);
        }
        return value;
    }
}

@Pipe({
    name: 'thousandsSeparate',
    standalone: false
})
export class ThousandsSeparatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return thousandsSeparate(value);
    }
}

@Pipe({
    name: 'SafeURI',
    standalone: false
})
export class SafeURIPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {
    }

    transform(value: any): any {
        if (!value) {
            return value;
        }
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}

@Pipe({
    name: 'upperfirst',
    standalone: false
})
export class UpperFirstPipe implements PipeTransform {
    //Just capitalises the first letter, leaves the rest as is
    transform(value: any): any {
        return upperfirst(value);
    }
}


@Pipe({
    name: 'FilterByName',
    standalone: false
})
export class FilterByNamePipe implements PipeTransform {
    transform(list: any, args?: any): any {
        if (!args) {
            return list;
        }
        return list.filter(
            item => item.attributes.name.toLowerCase().startsWith(args.toLowerCase()) === true
        );
    }
}

@Pipe({
    name: 'clientTranslator',
    standalone: false
})
export class ClientTranslatorPipe implements PipeTransform {
    constructor(private appScope: AppScope) {
    }

    public transform(value: any, args?: any): any {
        return this.appScope.clientDict(value);
    }
}

@Pipe({
    name: 'componentPrintouts',
    standalone: false
})
export class ComponentPrintoutsPipe implements PipeTransform {
    constructor(private http: HttpClient) {

    }

    transform(componentId: string, printoutIds?: string[]): Observable<any[]> {
        const params = {
            context: JSON.stringify({component_id: componentId})
        };

        const searchParams = new URLSearchParams(params);

        return this.http.get<any>('/api/component_printout/' + componentId).pipe(
            rxjsMap(response => response.data),
            rxjsMap(printouts => {
                if (printoutIds) {
                    printouts = printouts.filter(p => printoutIds.includes(String(p.id)));
                }
                if (!printouts.length) return [{name: 'No printouts available', url: ''}];
                sortObjectsByProperty(printouts, 'column_order', null, true);
                return printouts.map(printout => ({
                    name: printout.attributes.name,
                    url: '/api/GetPdf/' + printout.id + '?' + searchParams.toString()
                }));
            })
        );
    }
}

@Pipe({
    name: 'filterByValue',
    standalone: false
})
export class FilterByValuePipe implements PipeTransform {
    transform(items: any[], filterFn: (item: any, value: any, index?) => boolean, filterValue: any): any {
        if (!items || !filterFn) {
            return items;
        }
        return items.filter((item, index) => filterFn(item, filterValue, index));
    }
}

@Pipe({
    name: 'arrayMap',
    standalone: false
})
export class ArrayMapPipe implements PipeTransform {
    transform(items: any[], mapper: (item: any, index?: number, array?: any[]) => any): any[] {
        return items.map(mapper);
    }
}

@Pipe({
    name: 'sortObjectByArray',
    standalone: false
})
export class SortObjectByArrayPipe implements PipeTransform {
    transform(records: Array<any>, args?: any): any {
        const order = args;
        return records.sort(function (a, b) {
            return order.indexOf(a.key) - order.indexOf(b.key);
        });
    }
}

@Pipe({
    name: 'dateTimePeriod',
    standalone: false
})
export class DateTimePeriodPipe implements PipeTransform {
    transform(dtp: IDateTimePeriod) {
        try {
            return dtp.date_string();
        } catch {
            console.warn('Failed to transform dtp with type ' + dtp.constructor.name);
            return 'invalid dtp';
        }
    }
}

@Pipe({
    name: 'snakeCase',
    standalone: false
})
export class SnakeCasePipe implements PipeTransform {
    transform(value: string): string {
        return snakeCase(value);
    }
}

@Pipe({ name: "snakeCaseToTitleCase", standalone: false })
export class SnakeCaseToTitleCasePipe implements PipeTransform {
    transform(value: string | null | undefined): string {
        if (!value) return '';
        return value
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
}
