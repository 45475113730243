import * as Handsontable from "handsontable";
import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    OnDestroy,
    AfterViewInit
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HotInstance} from "../../services/hot-instance";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {forkJoin, Observable, Subject, Subscription} from "rxjs";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {Event as WireEvent} from "../../_models/event";
import * as utils from "../../lib/utils";
import {catchError, map, switchMap, takeUntil, tap, first} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {MatSort} from "@angular/material/sort";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {Equipment} from '../../_models/equipment';
import {MatTabsModule} from "@angular/material/tabs";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {PivotViewComponent} from "../pivot-view/pivot-view.component";
import {HistoryTableComponent} from "../history-table/history-table.component";
import {LdsRollerDirective} from "../../directives/lds-roller.directive";
import {CommonModule} from "@angular/common";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     MatTabsModule,
    //     FormsModule,
    //     MatButtonModule,
    //     MatPaginatorModule,
    //     PivotViewComponent,
    //     HistoryTableComponent,
    //     LdsRollerDirective,
    // ],
    // providers: [ApiService, HeaderDataService, HandsontableGenerator],
    selector: 'equipment-sheet-view',
    templateUrl: 'equipment-sheet-view.component.html',
    styleUrls: ['../handson-sheet.less'],
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class EquipmentSheetViewComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private handsontableGenerator: HandsontableGenerator) {
    }
    private readonly onDestroy = new Subject<void>();
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<Equipment>;

    hot: any;
    title: string;
    data: any;
    search: string;
    component_types: any;
    revisions: any;
    @Input() equipment: any;
    @Input() components: any;
    schema: any;
    column_list: any[];

    series_light: any[];
    users: any[];

    page_size: number = 20;
    page_size_options = [10, 20, 50, 100, 200];
    equipment_total: number;
    filter_string: string = '';
    filter_warning: string;
    loading: boolean = true;

    $page: Subscription;
    observables$: Observable<any>[];

    ngOnInit(): void {
        this.hot = new HotInstance();
        this.title = 'Equipment';

        // Filter for resource types
        const componentTypeOptions = new SearchQueryOptions();
        componentTypeOptions.filters = [{
            val: 'equipment',
            name: 'base_type',
            op: 'eq'
        }];

        this.observables$ = [];
        this.observables$.push(this.api.component_type.searchMany(componentTypeOptions).pipe(
            tap(response => this.component_types = response.data))
        );

        this.observables$.push(this.api.revision.searchMany().pipe(tap(response => this.revisions = response.data)));

        if (this.equipment == null) {
            this.observables$.push(this.api.equipment.searchMany().pipe(tap(response => this.data = response.data)));
        } else {
            this.data = this.equipment;
        }

        if (this.components == null) {
            this.observables$.push(this.api.component.searchMany().pipe(tap(response => this.components = response.data)));
        }

        this.observables$.push(this.api.series_light.searchMany().pipe(tap(response => this.series_light = response.data)));


    }

    ngAfterViewInit(): void { // New lifecycle hook
        forkJoin([this.observables$]).subscribe(() => {
            this.createDataSource();
        });
        this.buildHeader();
    }

    createDataSource() {
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';
        this.paginator.pageSize = this.page_size;
        this.paginator.pageIndex = 0;
        initialQuery.filters = [];

        this.dataSource = new PaginationDataSource<Equipment>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.$page = utils.refreshSubscription(this.$page);
        this.$page = this.dataSource.$page.pipe(takeUntil(this.onDestroy),
            catchError(err => {
                this.loading = false;
                return err;
            }))
            .subscribe((response: ListResponse<WireEvent>) => {
                this.hot['new_rows'] = [];
                this.paginator.length = response.meta.count;
                this.loading = false;
                this.createTable();
            });
    }

    page(query: SearchQueryOptions): Observable<any> {
        this.loading = true;
        return this.api.equipment.searchMany(query).pipe(
            map(response => {
                this.data = response.data;
                this.equipment_total = response.meta.count;
                return response;
            }),
            switchMap((r: ListResponse<Equipment>) => {
                const options = this.handsontableGenerator.getUserFilterForItems(r.data);
                return this.api.users.searchMany(options).pipe(tap(users => this.users = users.data));
            }),
            first(), takeUntil(this.onDestroy)
        );
    }

    createTable() {
        let componentTypeLookups = this.handsontableGenerator.gen_lookups(this.component_types);
        let revisionLookups = this.handsontableGenerator.gen_lookups(this.revisions, item => item.attributes.name);
        let componentLookups = this.handsontableGenerator.gen_lookups(this.components);

        this.schema = {
            id: null,
            type: 'equipment',
            attributes: {
                base_type: 'equipment',
                code: null,
                name: null,
                description: null,
                created_on: null,
                changed_on: null,
                icon: null,
                custom_series: {},
                custom_constants: {}
            },
            relationships: {
                revision: {data: {id: null, type: 'revision'}},
                component: {data: {id: null, type: 'process'}},
                component_type: {data: {id: null, type: 'component_type'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        this.column_list = [{
            data: 'attributes.code',
            type: 'text',
            title: 'Code'
        }, {
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }];

        this.column_list = this.column_list.concat([{
            data: this.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.icon',
            readOnly: false,
            title: 'Icon'
        }, {
            data: this.handsontableGenerator.genLookupDataSource(componentLookups, 'component'),
            title: 'Component',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }
        ]);

        this.hot = this.handsontableGenerator.generateTable(this.api.equipment, this.schema, this.column_list, this.hot);

        this.setSort();

        this.hot.ready = true;
        this.hot.settings.data = this.data;

        if (this.hot.instance) {
            this.hot.instance.updateSettings(this.hot.settings, false);
        } else {
            this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
        }

    }

    save() {
        let results = this.hot.save();
        this.data = results.data;
    }

    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = 'Equipment';
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}}
        ];
    }

    updateSearchFilter() {
        let filters: any[] = [];
        const string_filters = {or: []};
        ['name', 'description'].forEach(att => {
            string_filters.or.push({op: 'ilike', name: att, val: '%' + this.filter_string + '%'});
        });

        filters.push(string_filters);
        this.loading = true;
        this.dataSource.filterBy(filters);
    }

    setSort() {
        const allowed = ['name', 'description'];
        this.hot.settings.beforeColumnSort = (currentSort, destinationSort) => {
            let sort;
            this.filter_warning = '';
            if (destinationSort?.length) {
                const col_index = destinationSort[0]?.column;
                const col = this.hot.instance.getColHeader(col_index);
                sort = col?.toString().toLowerCase().replace('*', '');
                if (!allowed.includes(sort)) {
                    this.filter_warning = "Only the current page has been sorted.";
                    return true;
                }
            }

            let sortChange = new MatSort();
            sortChange.active = sort;
            sortChange.direction = destinationSort[0]?.sortOrder;
            this.loading = true;
            this.dataSource.sortBy(sortChange);
            return false; // If not using this causes problems/confusion we'll need to manually keep track of the current sort.
        };
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
