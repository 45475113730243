<!--Template used by CommentTableComponent and PageCommentTableComponent-->
<div class="row utils-bar comment-events-utils-bar">
  <div class="row table-filter">

      <utils-bar [search]="config.search"
                 (searchValueSubmitted)="filterString=$event;updateSearchFilter()"
                 (searchCleared)="filterString='';updateSearchFilter()"
      >
      </utils-bar>
  </div>
  <!--TODO style the paginator to match the site-->
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="10"
                 showFirstLastButtons></mat-paginator>
</div>

<div class="table-container">
  <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="columnIds[1]"
         class="comment-events-table" mat-table matSort (matSortChange)="updateSort($event)"
         matSortDirection="desc">

    <ng-container *ngFor="let column of columnIds;" [matColumnDef]="column">
      <th *matHeaderCellDef mat-sort-header
          class="freeze-pane" mat-header-cell
          [resizable]="formatDict[column]" (resizable)="formatDict[column].width=$event">
        <ng-container *ngIf="column !== 'select'">
          {{columns[column]?.title || column | upperfirst | removeUnderscore}}
        </ng-container>
        <ng-container *ngIf="column==='select'" (click)="$event.stopPropagation()">
          <mat-checkbox (change)="selectAll($event)" (click)="$event.stopPropagation()"
                        matTooltip="Select/Clear all"
                        [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY"></mat-checkbox>
        </ng-container>
      </th>

      <ng-container *ngIf="customTemplateColumns.includes(column) else defaultTemplate">
        <!--Select-->
        <ng-container *ngIf="column==='select'" (click)="$event.stopPropagation()">
          <td (click)="$event.stopPropagation()" *matCellDef="let element;" class="select-column" mat-cell
              style="text-align:center;" [ngClass]="{'error': errorBank.errorDict?.[element.id] }">
            <mat-checkbox (change)="selectComment(element, $event)"
                          [checked]="rowsSelectionStatus[element.id]"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="['start','end'].includes(column)">
          <td *matCellDef="let element" mat-cell
              [ngStyle]="formatDict[column]?.style">
            <date-time-picker (date_changed)="element[column]=$event;saveComment(element, column, $event)"
                              [disabled]="columnDict?.[column]?.disabled"
                              [default]="dtp.start"
                              [input_date]="element[column]"
                              [ngStyle]="formatDict[column]?.style"
            ></date-time-picker>
          </td>
        </ng-container>
        <ng-container *ngIf="['created_on','changed_on'].includes(column)">
          <td *matCellDef="let element" mat-cell
              [ngStyle]="formatDict[column]?.style"> {{element[column] | stringDate}}
          </td>
        </ng-container>
        <ng-container *ngIf="column==='comment'">
          <td *matCellDef="let element" mat-cell [ngStyle]="formatDict[column]?.style">
            <input (change)="saveComment(element, column, $event)"
                   [(ngModel)]="element[column]"
                   [disabled]="columnDict?.[column]?.disabled"/>
          </td>
        </ng-container>
      </ng-container>

      <ng-template #defaultTemplate>
        <td *matCellDef="let element" mat-cell [ngStyle]="formatDict[column]?.style"> {{element[column]}}</td>
      </ng-template>
    </ng-container>

    <tr *matHeaderRowDef="columnIds; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columnIds;" mat-row></tr>
  </table>
</div>
