import {Injectable, OnDestroy} from '@angular/core';
import {BasePaginatedTableService} from "../base-paginated-table.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {combineLatest, Observable, of, ReplaySubject} from "rxjs";
import {GroupDataService} from "../../data/group-data.service";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {GenericDataService} from "../../data/generic-data.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {concatMap, first, takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {ConstantProperty} from '../../_models/constant-property';
import {EventType} from '../../_models/event-type';
import {ComponentType} from '../../_models/component-type';
import {EventDataService} from "../../data/event-data.service";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {ModelID} from '../../_typing/generic-types';
import {LockDataService} from "../../services/lock-data.service";
import {LockTemplate, LockTemplateTableData} from '../../_models/lock-template';

@Injectable({
    providedIn: 'root'
})
export class LockTemplateTableService extends BasePaginatedTableService<LockTemplate> implements OnDestroy {

    page_size = 10;
    total: number;
    page_size_options = [5, 10, 20, 50];
    page_index = 0;
    sort: MatSort;
    paginator: MatPaginator;
    dataSource: PaginationDataSource<LockTemplate>;
    api_name = 'lock_template';
    search_keys = ['name'];
    lock_template_data: LockTemplateTableData = {lock_templates: null, constant_properties: []};

    public readonly lockTemplateDataSubject: ReplaySubject<LockTemplateTableData> =
        new ReplaySubject<LockTemplateTableData>(1);

    constructor(private groupDataService: GroupDataService,
                private appScope: AppScope,
                private propertyDataService: ConstantPropertyDataService,
                private eventDataService: EventDataService,
                private componentTypeDataService: ComponentTypeDataService,
                private lockDataService: LockDataService,
                api: ApiService,
                genericData: GenericDataService) {
        super(genericData, api);
    }

    initialise() {
        const initialQuery: SearchQueryOptions = this.getInitialSearchOptions();
        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<LockTemplate>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            concatMap((result: ListResponse<LockTemplate>) => {
                this.lock_template_data.lock_templates = result.data;
                this.total = result.meta.count;
                this.lockTemplateDataSubject.next(this.lock_template_data);
                return this.getRelatedData(result.data);
            }),

            takeUntil(this.onDestroy)).subscribe();
    }

    getInitialSearchOptions(): SearchQueryOptions {
        const options = new SearchQueryOptions(this.page_size, this.page_index + 1, 'name');
        options.filters = [];
        return options;
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return super.page(query, this.api_name);
    }

    private getRelatedData(data: LockTemplate[]) {
        let $type;
        let event_type_ids = this.lockDataService.extractTemplateTypeIds(data, 'event_type');
        let component_type_ids = this.lockDataService.extractTemplateTypeIds(data, 'component_type');

        return combineLatest([this.getProperties(data), this.getEventTypes(event_type_ids), this.getComponentTypes(component_type_ids)]).pipe(
            first(),
            takeUntil(this.onDestroy)
        );
    }

    getEventTypes(event_type_ids: ModelID[]): Observable<ListResponse<EventType>> {
        if (event_type_ids?.length < 1) return of(null);
        return this.eventDataService.getEventTypes(event_type_ids)
            .pipe(tap((result: ListResponse<EventType>) => {
                this.lock_template_data.event_types = result.data;
                this.lockTemplateDataSubject.next(this.lock_template_data);
            }));
    }

    getComponentTypes(component_type_ids: ModelID[]): Observable<ListResponse<ComponentType>> {
        if (component_type_ids?.length < 1) return of(null);
        return this.componentTypeDataService.getComponentTypes(component_type_ids)
            .pipe(tap((result: ListResponse<ComponentType>) => {
                this.lock_template_data.component_types = result.data;
                this.lockTemplateDataSubject.next(this.lock_template_data);
            }));
    }

    getProperties(data: LockTemplate[]): Observable<any> {
        const prop_ids = this.lockDataService.extractConstantPropertyIds(data);
        if (prop_ids?.length < 1) return of(null);

        return this.propertyDataService.getConstantProperties(prop_ids)
            .pipe(tap((result: ListResponse<ConstantProperty>) => {
                this.lock_template_data.constant_properties = result.data;
                this.lockTemplateDataSubject.next(this.lock_template_data);
            }));
    }
}
