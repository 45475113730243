
<div class="row">
  <div class="select-search" (mouseenter)="hint='Process'">
    <div (mouseenter)="hint='Series'">
        <select-search-component
            [value]="config.process"
            [classes]="'fill'"
            [api_model]="'process_light'"
            (selectionChange)="processChange($event)"
            [label]="'Select a process'">
        </select-search-component>
    </div>
  </div>
  <div class="tip-box">
    <div class="tip" [ngClass]="{'showing': hint=='Process'}">
      <label>Process</label>
      <div>
        The process for which you'd like to review data.
      </div>
    </div>
    </div>
</div>
