import * as moment_ from 'moment';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {Subject} from "rxjs";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {takeUntil} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {SeriesDataService} from "../../services/series_data.service";
import {FormDialogService} from "../../services/form-dialog.service";
import {NotificationService} from "../../services/notification.service";

export const moment = moment_["default"];

@Component({
    selector: 'value-driver-tree-table',
    templateUrl: 'value-driver-tree-table.component.html',
    styleUrls: ['value-driver-tree-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ValueDriverTreeTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public calculations: any = [];
    public columns: string[];
    dataSource: MatTableDataSource<any>;
    page_size: number = 10;
    status: any;
    highlight_index: number;
    private sort;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService, public headerData: HeaderDataService,
                public dialog: MatDialog, private seriesData: SeriesDataService,
                private notification: NotificationService,
                private formDialogService: FormDialogService) {
    }

    private _tableData: any;

    get tableData(): any {
        return this._tableData;
    }

    @Input() set tableData(value: any) {
        this._tableData = value;
        if (value && value.length > 0) {
            this.getTable();
        }
    }

    private _mouseoverNode: any;

    get mouseoverNode(): any {
        return this._mouseoverNode;
    }

    @Input() set mouseoverNode(value: any) {
        this._mouseoverNode = value;
        this.showInTable(this._mouseoverNode);
    }

    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    ngOnInit(): void {
        this.columns = ['depth', 'name', 'description', 'type', 'formula', 'assumptions', 'parent'];
    }

    getTable() {
        const ctrl = this;

        ctrl.tableData.map((item) => {
            item['name'] = item.series.attributes ? item.series.attributes.name : item.series;
            item['description'] = item.series.attributes ? item.series.attributes.description : item.series;
            item['formula'] = item.series.attributes ? item.series.attributes.description_formula : null;
            item['depth'] = item.depth;
            item['type'] = item.series.attributes.base_type;
            item['assumptions'] = item.series.attributes ? item.series.attributes.assumptions : null;
            item['parent'] = item.parent ? item.parent.attributes.name : null;
            item['index'] = item.index;
            item['parent_index'] = item.parent_index;
        });
        const uniqueRows = Array.from(new Set(ctrl.tableData.map(row => row.name)))
            .map(name => {
                let items = ctrl.tableData.filter(row => row.name === name);
                let item = items[0];
                item.depth = items.map(i => i.depth);
                item.parent = items.map(i => i.parent);
                item.parent_index = items.map(i => i.parent_index);
                return item
            })

        this.dataSource = new MatTableDataSource(uniqueRows);
        this.dataSource.filterPredicate = (data, filter) => {
            if (data.name.toLowerCase().includes(filter)
                || data.description.toLowerCase().includes(filter)
                || data.formula.toLowerCase().includes(filter)
                || data.type.toLowerCase().includes(filter)
                || data.depth.includes(filter)
                || data.parent.toLowerCase().includes(filter)
            ) {
                return true;
            }
            return false;
        };
        this.dataSource.paginator = this.paginator;

    }

    highlight(row, index) {
        this.highlight_index = row.parent_index[index];

        //If the parent is the same, use the lower index
        let dup_index = row.parent.indexOf(row.parent[index])
        if (dup_index > -1 && dup_index < index) {
            this.highlight_index = row.parent_index[dup_index];
        }
    }

    showInTable(node) {
        if (node) {
            let row = this.tableData.find(row => {
                return row.name === node.data.series.attributes.name
            });
            this.highlight_index = row?.index;
        } else {
            this.highlight_index = null;
        }
    }

    openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name);
    }

    editSeries(element) {
        const ctrl = this;
        //This type is series.attributes.base_type
        let $series_full = this.api.series.getById(element.series.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            ctrl.seriesData.upsertSeries(null, series_full).afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                    element['name'] = updated_series.attributes.name;
                    element['description'] = updated_series.attributes.description;
                    element['formula'] = updated_series.attributes.description_formula;
                    ctrl.notification.openSuccess( "Series saved. Click refresh to run validation again");
                }
            })
        })
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
