import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from "../../shared/base.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {KeyMap, ModelAttribute} from "../../_typing/generic-types";
import {takeUntil, tap} from "rxjs/operators";
import {LockTemplateVersionTableService} from "./lock-template-version-table.service";
import {LockTemplateVersion} from "../../_models/lock-template-version";
import {LockTemplate} from '../../_models/lock-template';
import {UserService} from "../../services/user.service";

@Component({
    selector: 'lock-template-version-table',
    templateUrl: './lock-template-version-table.component.html',
    styleUrls: ['./lock-template-version-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [LockTemplateVersionTableService],
    standalone: false
})
export class LockTemplateVersionTableComponent extends BaseComponent implements OnInit {
    @Input() lock_template: LockTemplate;
    @Input() lock_templates: LockTemplate[];
    @Input() lock_template_versions: LockTemplateVersion[];
    @Input() lock_ids;
    @Input() component_name: ModelAttribute;
    @Output() relayUnlockTemplate: EventEmitter<LockTemplateVersion> = new EventEmitter();
    selected_version: LockTemplateVersion;
    version_template_dict: KeyMap<LockTemplate> = {};
    @Output() selectedVersionChanged: EventEmitter<LockTemplateVersion> = new EventEmitter<LockTemplateVersion>();
    @Output() selectedTemplateChanged: EventEmitter<LockTemplate> = new EventEmitter<LockTemplate>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<LockTemplateVersion>;
    columns: string[];
    info: string;

    constructor(private cps: LockTemplateVersionTableService,
                private userService: UserService) {
        super();

        this.cps.dataSourceSubject.pipe(
            tap(ds => {
                this.dataSource = ds;
                if (this.dataSource?.data && this.lock_template_versions?.length) {
                    this.dataSource.data.forEach(tv => {
                        this.version_template_dict[tv.id] = this.lock_templates.find(t => t.id == tv.relationships.lock_template.data.id);
                    });
                    this.changeSelectedVersion(null, this.dataSource.data[0]);
                }
                this.setInfoMessage();
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
    }

    ngOnInit(): void {
        this.columns = this.cps.columns;
        if (this.lock_template_versions?.length) {
            this.columns.splice(1, 0, 'template');
            this.columns.push('unlock');
        }

        setTimeout(() => {
            this.cps.sort = this.sort;
            this.cps.paginator = this.paginator;
            this.cps.initialise(this.lock_template, this.lock_template_versions);
        });
    }

    setInfoMessage() {
        let msg = [];
        if (!this.dataSource.data?.length) {
            msg.push("A new template version will only be created once your data has been locked.");
        } else {
            if (this.dataSource.data.length > 1) {
                msg.push("Please select a lock template version to view its dependencies. <u>More than one may apply.</u>");
            }
            if (this.lock_ids?.length) {
                msg.push('Please check all dependencies before unlocking ' + (this.component_name || 'this event')
                    + ' with the selected template version.');
            }
        }
        if (msg.length) {
            this.info = msg.join('<br/>');
        }
    }

    changeSelectedVersion($event, element) {
        $event?.stopPropagation();
        this.selected_version = element;
        this.selectedVersionChanged.emit(element);
        if (!this.version_template_dict?.[element.id]) {
            return;
        }
        this.selectedTemplateChanged.emit(this.version_template_dict[element.id]);
    }

    unlockTemplate(version: LockTemplateVersion) {
        this.relayUnlockTemplate.next(version);
    }

    canUnlock() {
        return this.userService.canUnlockData();
    }

    updateSort(event) {
        this.cps.updateSort(this.sort);
    }
}
