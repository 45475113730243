import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AppScope} from '../app_scope.service';
import {map, take} from 'rxjs/operators';
import {AccountService} from '../account.service';
import {TimezoneSelectorService} from "../timezone-selector.service";

export const UrlGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appScope: AppScope = inject(AppScope);
  const router: Router = inject(Router);
  const accountService: AccountService = inject(AccountService);
  const timezoneService: TimezoneSelectorService = inject(TimezoneSelectorService);

  return appScope.currentUserValue.pipe(take(1), map((user) => {
    if (appScope.current_user.default_dashboard_id) {
        router.navigate(['view', 'page_view', appScope.current_user.default_dashboard_id]);
    } else {
        router.navigate(['view', 'page_list_view']);
    }
    accountService.refresh();
    timezoneService.refresh();
    return true;
  }));
};
