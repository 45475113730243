import {Injectable, OnDestroy} from '@angular/core';
import {SeriesDataService} from "../../services/series_data.service";
import {Series, SeriesSeriesTableData, SeriesSeriesData} from '../../_models/series';
import {catchError, Observable, of, Subject, forkJoin, combineLatest} from 'rxjs';
import {map, take, takeUntil, tap, concatMap, last} from 'rxjs/operators';
import {Component} from "../../_models/component";
import {SeriesType} from '../../_models/series-type';
import {stub} from "../../lib/utils";
import {ListResponse} from "../../services/api/response-types";
import {SeriesSeries} from "../../_models/series-series";
import {ErrorBankService} from "../../services/error-bank.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {KeyMap} from "../../_typing/generic-types";

@Injectable({
    providedIn: 'root'
})
export class SeriesEstimateFormService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public $targetData: Subject<SeriesSeriesTableData> = new Subject();
    public $sourceData: Subject<SeriesSeriesTableData> = new Subject();
    source_series: SeriesSeries[];
    target_series: SeriesSeries[];
    target_data: SeriesSeriesData[];
    source_data: SeriesSeriesData[];

    constructor(private seriesData: SeriesDataService,
                private errorBank: ErrorBankService,
                private snackbar: MatSnackBar) {
    }

    getTargetSeries(series: Series): void {
        combineLatest(this.seriesData.getSeriesSeriesData(series.id), this.seriesData.getTargetSeries(series.id))
            .pipe(take(1),
                catchError((errors) => {
                    console.log('ERROR: SeriesEstimatesFormService (getEstimates) \n', errors);
                    return of(errors);
                }),
                takeUntil(this.onDestroy)).subscribe(([series, series_series]) => {
            this.target_data = series;
            this.target_series = series_series.data;
            this.target_series.forEach(ss => {
                this.target_data.find(s => s.id === ss.relationships.target_series.data.id).positive_variance = ss.attributes.positive_variance;
            })
            this.$targetData.next({series: this.target_data, series_series: this.target_series});
        });
    }

    getSourceSeries(series: Series): void {
        combineLatest(this.seriesData.getSeriesSeriesData(series.id, 'target_series'), this.seriesData.getSourceSeries(series.id))
            .pipe(
                take(1),
                catchError((errors) => {
                    console.log('ERROR: SeriesEstimatesFormService (getEstimates) \n', errors);
                    return of(errors);
                }),
                takeUntil(this.onDestroy)).subscribe(([series, series_series]) => {
            this.source_data = series;
            this.source_series = series_series.data;
            this.source_series.forEach(ss => {
                this.source_data.find(s => s.id === ss.relationships.source_series.data.id).positive_variance = ss.attributes.positive_variance || true;
            })
            this.$sourceData.next({series: this.source_data, series_series: this.source_series});
        });
    }

    newTargetSeries(component: Component, source_series: Series, series_type: SeriesType): void {
        const ctrl = this;
        let series = new Series();
        if (series_type) {
            series.relationships.series_type.data = stub<SeriesType>(series_type);
        }
        series.attributes.name = source_series.attributes.name + '_' + (series_type.attributes.abbreviation || series_type.attributes.name);
        series.attributes.description = (source_series.attributes.description || source_series.attributes.name) + ' ' + (series_type.attributes.name);
        const seriesForm = this.seriesData.upsertSeries(component, series, source_series);
        seriesForm.afterClosed().pipe(take(1)).subscribe(() => this.getTargetSeries(source_series));
    }

    newSourceSeries(component: Component, target_series: Series, new_series: SeriesSeriesData[], mapping_list: SeriesSeries[]): void {
        const ctrl = this;
        let series = new Series();
        const seriesForm = this.seriesData.upsertSeries(component, series);
        seriesForm.afterClosed().pipe(take(1), concatMap(series => {
            return this.seriesData.saveSourceSeries(target_series, series.series, true)
        })).subscribe(() => this.getSourceSeries(target_series));
    }

    saveSeriesSeries(series: Series, type: string, new_series: SeriesSeriesData[], mapping_list: SeriesSeries[]): Observable<any> {
        //Only available for new series - otherwise through estimates form
        let $series_series: Observable<any>;
        if (type === 'targets') {
            $series_series = this.seriesData.saveManyTargetSeries(series, this.target_data || [],
                new_series || [], mapping_list || [])
        } else {
            $series_series = this.seriesData.saveManySourceSeries(series, this.source_data || [],
                new_series || [], mapping_list || [])
        }

        $series_series.pipe(
            tap(res => console.log(res)),
            take(1),
            catchError(e => {
                console.log('Caught error in saveSeriesSource: ',);
                this.snackbar.open('Error saving the sources for this series: ' + e, "Hide", {duration: 10000});
                return of(e)
            }));
        return $series_series;
    }


    updateTargetSeries(series: Series, source_series: Series): void {
        const ctrl = this;
        const seriesForm = this.seriesData.upsertSeries(null, series);
        seriesForm.afterClosed().pipe(take(1)).subscribe(() => this.getTargetSeries(source_series));
    }

    updateSourceSeries(series: Series, target_series: Series): void {
        const ctrl = this;
        const seriesForm = this.seriesData.upsertSeries(null, series);
        seriesForm.afterClosed().pipe(take(1)).subscribe(() => this.getTargetSeries(target_series));
    }

    updateSeriesSeries(series_series) {
        this.seriesData.saveSeriesSeries(series_series).pipe(take(1))
            .subscribe(() => this.snackbar.open("Variance saved.", null, {duration: 3000}));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
