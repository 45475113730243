import {OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export function takeUntilDestroyed<T>(componentInstance: OnDestroy) {
  const destroy$: Subject<void> = new Subject<void>();
  const originalOnDestroy = componentInstance.ngOnDestroy;

  if (!originalOnDestroy) {
    throw new Error(
      `${componentInstance.constructor.name} is using takeUntilDestroyed but does not implement OnDestroy`
    );
  }

  componentInstance.ngOnDestroy = () => {
    originalOnDestroy.apply(componentInstance);
    destroy$.next();
    destroy$.complete();
  };

  return function (source$) {
    return source$.pipe(takeUntil(destroy$));
  };
}
