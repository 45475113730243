import {BehaviorSubject, Observable, Subject} from "rxjs";
import {scan, take, takeUntil} from "rxjs/operators";
import {Injectable, OnDestroy} from "@angular/core";

@Injectable()
/**
 **This service is stateful.
 **/

export class SelectSearchApiService implements OnDestroy {
    private onDestroy: Subject<void> = new Subject();

    constructor() {
    }

    loadNext: Boolean = true;
    loadNextSelected: Boolean = true;
    loadingAvailable: Boolean = true;
    loadingSelected: Boolean = true;
    page_number: number = 1;
    page_number_selected: number = 1;
    page_size: number = 10;
    options_available: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    options_selected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    clear: boolean = false;
    clear_selected: boolean = false;
    keep_selected: boolean = true;


    itemsAdded: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    itemsRemoved: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    availableTotal: number = 0;
    selectedTotal: number = 0;

    // TODO Note: just adding full scroll height is not enough as user can then scroll very quickly and be outside the
    // bounds of the next page. Needs full positional indexing
    item_height: number = 46;
    pages: number = 1;

    /**Whether to include (filter) the selected list when typing in the search box**/

    mapFunction(curr) {
        if (!curr) {
            return;
        }
        return curr.map(item => ({
            text: this.stringFunction(item),
            value: this.valueFunction(item),
        }));
    }

    stringFunction(obj: any): string {
        if (!obj) {
            return;
        }
        let name_: string = '';
        let descrip_: string = '';

        if (obj.attributes && obj.attributes.name !== undefined) {
            name_ = obj.attributes.name;
        }

        if (obj.attributes?.description) {
            descrip_ = '-' + obj.attributes.description;
        }
        return name_ + descrip_;
    }

    valueFunction(obj: any): any {
        return obj;
    }

    connect(mapFunction = this.mapFunction.bind(this)): Observable<any[]> {
        return this.options_available.asObservable().pipe(
            scan((acc: any, curr: any[]) => {
                let list: any[] = [];
                let curr_option$ = mapFunction(curr);
                if (!this.clear) {
                    acc = this.filterAvailable(acc);
                    list = [...acc, ...curr_option$];
                } else {
                    this.clear = false;
                    list = [...curr_option$];
                }
                return list;
            }, []),
            takeUntil(this.onDestroy)
        );
    }

    connect_selected(mapFunction = this.mapFunction.bind(this)) {
        return this.options_selected.asObservable().pipe(
            scan((acc: any, curr: any[]) => {
                let list = [];
                let curr_option$ = mapFunction(curr);
                if (!this.clear_selected) {
                    list = [...acc, ...curr_option$];
                } else {
                    this.clear_selected = false;
                    list = [...curr_option$];
                }
                return list;
            }, []),
            takeUntil(this.onDestroy)
        );
    }

    filterAvailable(curr_list: any[]): any[] {
        const added: any[] = this.itemsAdded.getValue();
        const removed: any[] = this.itemsRemoved.getValue();

        if (added) {
            curr_list = curr_list.filter(i => {
                return !added.map(a => a.id || a).includes(i.value?.id || i.id || i.value || i);
            });
        }
        if (removed) {
            const items_removed = this.mapFunction(removed);
            curr_list.push(...items_removed);
        }
        return curr_list;
    }

    resetList(init_value: any[] = []): void {
        this.page_number = 1;
        this.clear = true;
        this.options_available.next(init_value);
        this.loadNext = true;
    }

    resetSelectedList(): void {
        this.page_number_selected = 1;
        this.clear_selected = true;
        this.options_selected.next([]);
        this.loadNextSelected = true;
    }

    getNextBatch(fetchData, filterValue, page: number = this.page_number, items_added?, items_removed?): void {
        this.loadingAvailable = true;

        fetchData(page, filterValue, items_added, items_removed).pipe(take(1)).subscribe((data): void => {
            this.page_number += 1;
            this.options_available.next(data.data);
            if (!data.data.length) {
                this.loadNext = false;
            }
            this.loadingAvailable = false;
            this.availableTotal = data.meta?.count || 0;
        });
    }

    getNextSelected(fetchSelected, filterValue, page: number = this.page_number_selected, items_added?, items_removed?, filterByAccount: boolean = true) {
        if (this.keep_selected) {
            filterValue = null;
            filterByAccount = false;
        }

        this.loadingSelected = true;

        fetchSelected(page, filterValue, items_added, items_removed, filterByAccount).pipe(take(1)).subscribe((data) => {
            this.page_number_selected += 1;
            this.options_selected.next(data.data);
            if (!data.data.length) {
                this.loadNextSelected = false;
            }
            this.loadingSelected = false;
            this.selectedTotal = data.meta?.count || 0;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
