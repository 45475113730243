<div class="date-property-filter-selector form-ctrl-margin-bottom">
  <div class="row min-height label-row"><label>For {{typeName}}:</label></div>
  <div class="row center medium-height justify-space cp-date-row">
    <mat-checkbox [ngModel]="propertyDict[typeId]?.enabled"
                  (ngModelChange)="constantPropertyDateFieldChanged($event, 'enabled', typeId)"
                  name="Filter by Selected Constant Property Time">
      Use constant property time:
    </mat-checkbox>
    <div class="row medium-height justify-space">
      <select-search-component
          [api_model]="'constant_property_light'"
          [custom_filters]="customFilters"
          [value]="getConstantPropertyDateFieldValue('start_prop', typeId)"
          [disabled]="!propertyDict[typeId]?.enabled"
          (selectionChange)="constantPropertyDateFieldChanged($event?.value, 'start_prop', typeId)"
          [label]="'Select a start date property'"
          [compareFunctionName]="'compareValueToId'"
          [allow_none]="true"
          [classes]="'fill'"
          class="select-search">
      </select-search-component>

      <select-search-component
          [api_model]="'constant_property_light'"
          [custom_filters]="customFilters"
          [value]="getConstantPropertyDateFieldValue('end_prop', typeId)"
          [disabled]="!propertyDict[typeId]?.enabled"
          (selectionChange)="constantPropertyDateFieldChanged($event?.value, 'end_prop', typeId)"
          [label]="'Select an end date property'"
          [allow_none]="true"
          [compareFunctionName]="'compareValueToId'"
          [classes]="'fill'"
          class="select-search">
      </select-search-component>
    </div>
  </div>
  <div class="row center medium-height justify-space attribute-date-row">
    <mat-checkbox [ngModel]="attributeDict[typeId]?.enabled"
                  (ngModelChange)="attributeDateFieldChanged($event, 'enabled', typeId)"
                  name="Filter by Selected Attribute Time">
      Use attribute time:
    </mat-checkbox>
    <mat-form-field class="no-label">
      <mat-select [ngModel]="attributeDict[typeId]?.attribute"
                  (selectionChange)="attributeDateFieldChanged($event, 'attribute', typeId)"
                  [disabled]="!attributeDict[typeId]?.enabled">
        <mat-option *ngFor="let option of attributeOptions"
                    [value]="option">{{option  | removeUnderscore | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
