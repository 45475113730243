import {Directive, Output, EventEmitter, HostListener, ElementRef, Input} from '@angular/core';
import {NotificationService} from "../services/notification.service";
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[dblClickCopy]',
    standalone: false
})
export class DblClickCopyDirective {
    private readonly onDestroy = new Subject<void>();
    @Input('dblClickCopy') text: string;
    @Input() debounceTime = 250;
    @Output() debounceClick = new EventEmitter();
    element: Element;
    isSingleClick: Boolean = true;
    private $clicks = new Subject();

    constructor(elm: ElementRef,
                private notification: NotificationService) {
        this.element = elm.nativeElement;
    }

    ngOnInit() {
        this.$clicks.pipe(debounceTime(this.debounceTime),
            takeUntil(this.onDestroy))
            .subscribe(e => {
                if(this.isSingleClick) {
                    this.debounceClick.emit(e);
                }
            });
    }

     ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        this.isSingleClick = true;
        event.preventDefault();
        event.stopPropagation();
        if(event.target.name === 'dblClickCopy' && this.text){
            //For buttons
            this.copyStringToClipboard(this.text);
        } else {
            //For anchor tags or other elements that already have a click event
            //This waits to see if dblClick was invoked first
            this.$clicks.next(event);
        }
    }

    @HostListener('dblclick', ['$event'])
    onDblClick(event) {
        this.isSingleClick = false;
        if (this.text || this.text=='0') {
            this.copyStringToClipboard(this.text);
            return;
        }
        const selection = getSelection();
        const range = document.createRange();

        range.selectNodeContents(this.element);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');

        this.notification.openSuccess("Copied " + range + " to your clipboard.", 3000);
        console.log(`Copied ${range} to clipboard!`);
    }

    copyStringToClipboard(str) {
        let el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('visibility', 'hidden');
        el.setAttribute('readonly', '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.notification.openSuccess("Copied " + str + " to your clipboard.", 3000);
        console.log(`Copied ${str} to clipboard!`);
    }
}
