<div class="row" *ngIf="content==='budget-bar-chart'">
  <mat-form-field>
    <mat-label>Y Axis Label</mat-label>
    <input [(ngModel)]="config.labels.y_axis" matInput>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Y2 Axis Label</mat-label>
    <input [(ngModel)]="config.labels.y2_axis" matInput>
  </mat-form-field>
</div>
<div class="row full-width">
  <select-search-component [label]="'Series'"
                           [classes]="'fill'"
                           [api_model]="'series_light'"
                           [value]="config.series"
                           (selectionChange)="seriesSelected($event.value)"
                           class="select-search">
  </select-search-component>
  <div class="mat-hint">The series for which you want to view this chart. The type of chart you see will depend on your
    selected value for the 'default_chart' property for the series.
  </div>

  <select-search-component *ngIf="content==='budget-bar-chart'"
                           [label]="'Select target series'"
                           [classes]="'fill'"
                           [api_model]="'series_light'"
                           [disabled]="!target_series_filter"
                           [custom_filters]="target_series_filter"
                           [value]="config.target_series"
                           (selectionChange)="config.target_series=$event.value"
                           class="select-search">
  </select-search-component>
  <div class="mat-hint"></div>
</div>
<div class="row min-height center space-between">
  <mat-checkbox (mouseenter)="hint='Hide_Comments'"
                [(ngModel)]="config.hide_comments" name="hide_comments">Hide comments
  </mat-checkbox>

  <mat-checkbox [(ngModel)]="config.show_data_labels"
                name="show_data_labels">Show Data Labels
  </mat-checkbox>

  <mat-checkbox *ngIf="content==='budget-bar-chart'"
                [(ngModel)]="config.hide_cumulative"
                class="self-center"
                matTooltip="Chose to hide the comment lines shown on the chart"
                name="hide_cumulative">Hide Cumulative
  </mat-checkbox>
  <mat-checkbox [(ngModel)]="config.hide_now_line"
                class="self-center">
    Hide Now Label
  </mat-checkbox>
</div>
