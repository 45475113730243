<mat-form-field [ngClass]="classes" class="select-search-api override-hide-placeholder">
  <mat-label *ngIf="label && !(classes && classes.includes('inline'))">{{label}}</mat-label>
  <label *ngIf="label && (classes && classes.includes('inline'))">{{label}}</label>
  <mat-label *ngIf="!label"></mat-label>
  <mat-select #matSelect (openedChange)="openedChange($event)"
              (selectionChange)="emitSelect($event)"
              [(ngModel)]="value"
              [compareWith]="compareWith"
              [disabled]="disabled"
              [placeholder]="placeholder"
              (infiniteScroll)="getNextBatch()" [complete]="!sapi.loadNext" msInfiniteScroll
              panelClass="select-search-dropdown-panel"
  >

    <mat-select-trigger [dblClickCopy]="value?.attributes | nameAndDescription" [appProcessMenu]="value"
                        *ngIf="value?.attributes">{{value?.attributes | nameOrDescription}}</mat-select-trigger>
    <div class="select-container">
      <option-list-search (keydown.space)="$event.stopPropagation()"
                          [placeholder]="placeholder || 'Search'"
                          (inputChanged)="filterOptions($event)"
                          (inputCleared)="clearSearch($event)"
                          [allowAccountSelection]="allowAccountSelection"
                          [accountIds]="allowedAccountIds"
                          [selectElementRef]="matSelect"
                          (relayAccountsSelected)="onAccountsChanged($event)"
                          [delay]="300">
      </option-list-search>

      <mat-option class="no-results" *ngIf="loadedItems.length == 0 && !sapi.loadNext">{{noItemsAvailableMessage ? noItemsAvailableMessage : 'No results found!'}}</mat-option>
      <mat-option *ngIf="value?.attributes"
                  [value]="value"
                  class="with-account-row mat-selected">
        <div class="select-item-name" [dblClickCopy]="value?.attributes | nameAndDescription"
              style="color: #666666;">{{value.attributes | nameAndDescription}}</div>
        <div class="account-name select-item-account-name" style="justify-content: center;">Selected</div>
      </mat-option>
      <mat-option *ngIf="allow_none" [value]="" style="color: #666666;">(none)</mat-option>
      <mat-option *ngFor="let option of loadedItems" [dblClickCopy]="option['text']"
                  [style.font-weight]="value?.id==option.value?.id ? 'bold' : 'normal'"
                  [value]="option['value']"
                  [matTooltip]="option['text']"
                  [matTooltipShowDelay]="tooltipShowDelay"
                  class="with-account-row">
        <div class="select-item-name">{{option.text}}</div>
        <div *ngIf="option.value?.attributes?.account_name && value?.id!==option.value?.id" class="account-name">
          {{option.value.attributes.account_name}}</div>
      </mat-option>

      <mat-option *ngIf="!loadedItems || sapi.loadingAvailable" class="option-loading">
        <div class="stage">
          <div class="dot-flashing"></div>
        </div>
      </mat-option>


    </div>
  </mat-select>
</mat-form-field>
