import { HttpParams } from "@angular/common/http";
import * as utils from "../../lib/utils";

/**
 * Builder object for queries to the REST interface.
 *
 * https://github.com/jfinkels/flask-restless/tree/master/docs
 * JSON spec defined at: https://jsonapi.org/format
 */
interface ISearchQueryOptions {
    filters: any[];
    sort: string;
    page_size: number;
    page_number: number;
    ignore_routing: boolean;

    getParams(): HttpParams;
}

export type OptionalSearchQueryOptions = Partial<ISearchQueryOptions>;

export class SearchQueryOptions implements OptionalSearchQueryOptions {
    filters: any[];
    sort: string;
    page_size: number;
    page_number: number;
    include: string;
    // should this query continue if the current page changes
    ignore_routing: boolean = false;
    // Other
    readonly params: { [key: string]: any } = {};

    constructor(page_size?: number, page_number?: number, sort?: string) {
        this.page_size = page_size;
        this.page_number = page_number;
        this.sort = sort;
    }

    public getParams(): HttpParams {
        // let query = '';

        if (this.filters != null) {
            this.params['filter'] = JSON.stringify(this.filters);
        }
        if (this.sort != null) {
            this.params['sort'] = this.sort;
        }
        if (this.page_number) {
            this.params['page[number]'] = this.page_number;
        }
        if (this.include) {
            this.params['include'] = this.include;
        }
        // TODO: Fix this
        // This was added to allow for clients who do not have paginated series to see all of their data
        // without being limited by the default page size. Client had created 18000 series
        this.params['page[size]'] = this.page_size ?? 25000;
        return utils.httpParamSerializer(this.params);
    }
}
