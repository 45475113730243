import {Injectable} from '@angular/core';
import {Series} from "../../_models/series";
import {Observable, Subject} from "rxjs";
import {ApiService} from "../../services/api/api.service";
import {SeriesDataService} from "../../services/series_data.service";
import {ChartConfigTranslationService} from "../../services/chart-config-translation.service";
import {FormatNumberPipe} from "../../shared/pipes";
import {GenericChartTileConfiguration} from "../chart-config/generic-chart-tile.configuration";
import {map, mergeMap, tap} from "rxjs/operators";
import {GetSeriesData, SeriesGetSeriesData} from "../../_models/api/series-summary";
import {BarChartConfiguration} from "../chart-config/chart-configuration";
import {KeyMap} from '../../_typing/generic-types';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Injectable({
    providedIn: 'root'
})
export class BarChartService {
    dtp: IDateTimePeriod;
    series_full: Series[];
    $bar_config_ready_subject: Subject<any> = new Subject();
    $bar_config_ready = this.$bar_config_ready_subject.asObservable();

    constructor(private dateInst: DateTimeInstanceService,
                private api: ApiService,
                private seriesData: SeriesDataService,
                private chartConfigService: ChartConfigTranslationService,
                private formatNumber: FormatNumberPipe) {

    }

    getChart(tile_config: GenericChartTileConfiguration) {
        this.getChartData(tile_config);
    }

    private getChartData(tile_config: GenericChartTileConfiguration) {
        this.dtp = this.dateInst.dtp;
        const series_ids = tile_config.series_list.map(series => series.id);
        this.seriesData.getSeriesListByIds(series_ids)
            .pipe(tap(response => {
                    this.series_full = response.data;
                }),
                mergeMap(() => {
                    return this.getSeriesData(series_ids).pipe(map((data: GetSeriesData) => {
                        return this.seriesData.mapSeriesToSeriesData(this.series_full, data)
                    }))
                })
            ).subscribe((series_dict: KeyMap<SeriesGetSeriesData>) => {
            this.configureBarChartConfig(tile_config, series_dict)
        })
    }

    private getSeriesData(series_ids: string[]): Observable<GetSeriesData> {
        const params = {
            series_list: series_ids,
            start: this.dtp.start.toISOString(),
            end: this.dtp.end.toISOString(),
            sample_period: this.dtp.sample_period.wire_sample,
            period_type: this.dtp.calendar
        };
        return this.api.get_series_data(params);
    }

    private configureBarChartConfig(tile_config: GenericChartTileConfiguration, series_dict: KeyMap<SeriesGetSeriesData>) {
        const chart_config: BarChartConfiguration = {
            type: 'bar',
            key_values: tile_config.series_list.map(s => s.id),
            x_values: {times: this.chartConfigService.getTimeSeriesValues(tile_config, series_dict)},
            y_values: this.chartConfigService.getYValues(tile_config, series_dict),
            names: this.chartConfigService.getSeriesNames(tile_config, series_dict),
            orientation: tile_config.orientation || 'h',
            barmode: tile_config.chart_type === 'stacked bar' ? 'stack' : 'group',
            colours: this.chartConfigService.getSeriesColours(tile_config),
            styles: this.chartConfigService.getStyles(tile_config),
            labels: tile_config.labels,
            y_min: this.chartConfigService.getYMin(tile_config),
            y_max: this.chartConfigService.getYMax(tile_config),
            text_values: this.chartConfigService.getTextValues(tile_config, series_dict),
            text_orientation: this.chartConfigService.getTextOrientation(tile_config),
            no_ticks: tile_config.y_max_ticks_set ? tile_config.y_max_ticks : null,
            tick_space: tile_config.y_spacing_set ? tile_config.y_spacing : null,
            y_axis_format: this.chartConfigService.getYAxisFormat(tile_config)
        }
        const plot = this.chartConfigService.getConfiguredBarChart('plotly', chart_config);
        this.$bar_config_ready_subject.next(plot);
    }


}
