<div class="copy-tile-form form-dialog">
  <h1>Select tile destination</h1>
  <div class="row low-height">
    <mat-checkbox [(ngModel)]="byReference"
                  (change)="byReferenceChanged($event)"
                  [disabled]="selectedPageId===currentPageId"
                  matTooltip="Any changes to one tile will be reflected on the other. Tile can only be copied by reference to a different Page.">
      Create a reference to this tile
    </mat-checkbox>
  </div>
  <div class="row">
    <select-search-component
        [api_model]="'session_state_light'"
        [value]="selectedPage"
        (selectionChange)="selectedPageChanged($event);"
        [classes]="'fill'"
        [label]="'Page'"
        [filter_by]="['name']"
        [matTooltip]="'Select the page to which you would like to copy this tile.'"
        [disabled]="selectedPageId===currentPageId"
        class="select-search">
    </select-search-component>

    <mat-checkbox (change)="selectCurrentPage($event)"
                  class="self-center flex-third alone"
                  [disabled]="byReference"
                  name="default_dashboard" style="width:180px;">Current page
    </mat-checkbox>
  </div>

  <div class="stage-item stage" [hidden]="!fetchingPageInfo">
    <div class="dot-flashing"></div>
  </div>

  <div class="action-buttons">
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button class="bordered-button save" (click)="copyTile()" [disabled]="!selectedPageId"
            [ngClass]="{'disabled': !selectedPageId}">Copy
    </button>
  </div>
</div>