<div class="form-dialog component-form-tile">
  <div class="row warning info" *ngIf="component?.id && !config.component">
    You are now editing an EXISTING {{name}}. Click 'Apply changes' to edit or 'Clear' to add a new {{name}}.
  </div>
  <div class="row min-height justify-end">
    <label class="required-info" [ngClass]="{'existing':component?.id && !config.component}">* Required fields</label>
  </div>
  <div class="row auto attributes-container" *ngIf="component && dtp">
    <mat-form-field class="component-form-name" style="margin:0;">
      <mat-label class="label-required">{{name_column?.title || name_column?.column_name || 'Name'}}
        <span style="font-weight: normal;">{{autoName ? ' (This field will auto-populate)' : ''}}</span>
      </mat-label>
      <input [ngModel]="autoName ? nameFormulaString : component.attributes.name" (ngModelChange)="autoName ? nameFormulaString : component.attributes.name = $event"
             [disabled]="(component.id && name_column?.disabled) || generic_field_dict?.name?.locked || !canEditData() || autoName"
             matInput name="name"
             placeholder="">
    </mat-form-field>

    <div *ngIf="start_column" class="component-form-time">
      <mat-label [ngClass]="{'label-required':start_column.required}">Start time</mat-label>
      <date-time-picker
          *ngIf="dtp"
          (date_changed)="component.attributes.start_time = $event;revalidateField($event, start_column)"
          [default]="dtp.start"
          [input_date]="component?.attributes.start_time"
          [set_default]="false"
          [disabled]="(start_column.disabled && component.id) || generic_field_dict?.start_time?.locked || !canEditData()"
          [ngClass]="{'missing-required':missing.start_time}"
      ></date-time-picker>
    </div>
    <div *ngIf="end_column" class="component-form-time">
      <mat-label [ngClass]="{'label-required':end_column.required}">End time</mat-label>
      <date-time-picker
          (date_changed)="component.attributes.end_time = $event;revalidateField($event, end_column)"
          [default]="dtp.end"
          [input_date]="component?.attributes.end_time"
          [set_default]="false"
          [disabled]="(end_column.disabled && component.id) || generic_field_dict?.end_time?.locked || !canEditData()"
          [ngClass]="{'missing-required':missing.end_time}"
      ></date-time-picker>
    </div>
  </div>

  <div *ngIf="constant_properties && custom_constant_dict !== undefined && dtp" class="component-constants">

    <div *ngIf="config.group_columns && editable_cols?.length" (click)="show_editable=!show_editable"
         class="column-group">
      <div>Editable columns
        <span class="material-symbols-outlined arrow" matTooltip="Expand row">
        {{show_editable ? 'arrow_drop_down' : 'arrow_right'}}</span></div>
    </div>
    <ng-container *ngIf="!config.group_columns || show_editable">
      <ng-container *ngFor="let prop of editable_cols">
        <ng-container [ngTemplateOutlet]="constantElement"
                      [ngTemplateOutletContext]="{ $implicit: prop, disabled_col: false}"></ng-container>
      </ng-container>
    </ng-container>


    <div *ngIf="config.group_columns && disabled_cols?.length" (click)="show_disabled=!show_disabled"
         class="column-group">
      <div>Non-editable columns
        <span class="material-symbols-outlined arrow" matTooltip="Expand row">
        {{show_disabled ? 'arrow_drop_down' : 'arrow_right'}}</span></div>
    </div>
    <ng-container *ngIf="!config.group_columns || show_disabled">
      <ng-container *ngFor="let prop of disabled_cols">
        <ng-container [ngTemplateOutlet]="constantElement"
                      [ngTemplateOutletContext]="{ $implicit: prop, disabled_col: true}"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #constantElement let-prop let-disabled_col="disabled_col">
      <div class="constant-input-element"
           [ngClass]="{'missing-required':missing[prop.id]}"
           *ngIf="custom_constant_dict.hasOwnProperty(prop.id) && prop_ids.includes(prop.id)">
        <label
            [ngClass]="{'label-required':prop.required && !cpDict[prop.id].attributes.is_calculation}">
          {{prop.title || prop.column_name || cpDict[prop.id]?.attributes.name | removeUnderscore | upperfirst}}</label>
        <generic-component-field
            [constant_property]="cpDict[prop.id]"
            [constant]="generic_field_dict[prop.id]"
            [component]="component"
            (relayConstantChangedEmitter)="custom_constant_dict[prop.id] = $event;revalidateField($event, prop)"
            (relayChangeCompleteEmitter)="changeComplete($event, prop.id)"
            [disabled]="!canEditData() || disabled_col"
            [show_sig]="false"
            [defaultValue]="prop.default_value"
        ></generic-component-field>
      </div>
    </ng-template>
  </div>

  <button (click)="save()"
          *ngIf="!component?.id"
          [ngClass]="{'disabled': !component?.attributes?.name && !isNameComplete()}"
          [disabled]="!component?.attributes?.name && !isNameComplete()"
          [style.cursor]="((component?.attributes?.name && !component?.id) || isNameComplete())? 'pointer': 'not-allowed'"
          class="big-btn save"
          mat-raised-button
          style="width:220px;">Save
  </button>
  <button (click)="save()"
          *ngIf="component?.id"
          [ngClass]="{'disabled': !component?.attributes?.name}"
          [disabled]="!component?.attributes?.name"
          [style.cursor]="(component?.attributes?.name && component?.id)? 'pointer': 'not-allowed'"
          [matTooltip]="'Apply changes to the existing ' + name"
          class="big-btn save"
          mat-raised-button
          style="align-self: center;margin-bottom: 14px;height: 40px;width:220px;">Apply changes
  </button>
  <button (click)="clearForm()"
          *ngIf="!config.component"
          class="big-btn clear"
           mat-stroked-button
          >Clear
  </button>

</div>
