<div class="row" style="justify-content: space-between;">
  <select-many-event-types
      [value]="config.event_type_ids"
      [compareFunctionName]="'compareById'"
      (selectionChange)="updateConfig($event, 'event');"
      class="select-many-search-wrapper"
  ></select-many-event-types>

  <select-many-component-types
      [value]="config.component_type_ids"
      [compareFunctionName]="'compareById'"
      (selectionChange)="updateConfig($event, 'component_type');"
      class="select-many-search-wrapper"
  ></select-many-component-types>

</div>
<div class="row">
  <select-many-series
      [value]="config.series_list"
      (selectionChange)="updateConfig($event, 'series')"
      class="select-many-search-wrapper"
  ></select-many-series>
</div>

<ng-container *ngIf="pivot_tile_type && pivot_tile_type=='pivot-tile-ng'">
  <div class="row">
    <select-many-search-component
        [api_model]="'ore_body_light'"
        [value]="config.ore_bodies_list"
        [account_id]="account_id"
        [label]="'Select Ore Bodies'"
        (selectionChange)="updateConfig($event, 'ore_body');"
        class="select-search"
    ></select-many-search-component>
  </div>
</ng-container>

<ng-container *ngIf="config.component_type_ids?.length || config.event_type_ids?.length">
  <div class="row low-height form-ctrl-margin-top form-ctrl-margin-right">
    <mat-checkbox [(ngModel)]="config.bypass_date_filters" [disabled]="disableBypassDateFilters()">Bypass date
      filters <span class="info normal">OR</span>
    </mat-checkbox>
  </div>

  <ng-container *ngFor="let component_type_id of config.component_type_ids">
    <date-property-filter-selector
        [typeId]="component_type_id"
        [typeName]="component_type_names[component_type_id]"
        [propertyDict]="config.constant_property_time"
        [attributeDict]="config.attribute_time"
        [customFilters]="cp_date_filter[component_type_id]"
        (propertyDictChanged)="constantPropertyDateFieldChanged($event)"
        (attributeDictChanged)="attributeDateFieldChanged($event)">
    </date-property-filter-selector>
  </ng-container>

  <ng-container *ngFor="let event_type_id of config.event_type_ids">
    <date-property-filter-selector
        [typeId]="event_type_id"
        [typeName]="event_type_names[event_type_id]"
        [propertyDict]="config.constant_property_time"
        [attributeDict]="config.attribute_time"
        [customFilters]="cp_date_filter[event_type_id]"
        (propertyDictChanged)="constantPropertyDateFieldChanged($event)"
        (attributeDictChanged)="attributeDateFieldChanged($event)">
    </date-property-filter-selector>
  </ng-container>
</ng-container>

<ng-container *ngIf="!pivot_tile_type || (pivot_tile_type && pivot_tile_type!=='pivot-tile-ng')">
  <div class="row low-height">
    <mat-checkbox [(ngModel)]="config.hide_totals_rows"
                  [ngModelOptions]="{standalone:true}" style="align-self: center; min-width: unset; flex-grow: 0;">
      Hide totals row
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_totals_columns"
                  [ngModelOptions]="{standalone:true}" style="align-self: center; min-width: unset; flex-grow: 0;">
      Hide totals column
    </mat-checkbox>
  </div>
</ng-container>

<ng-container
    *ngIf="(!pivot_tile_type || pivot_tile_type==='pivot-tile') && (config.event_type_ids || config.component_type_ids)">
  <h3 style="margin-top:2em;">Enter custom options for Constant Properties
    <div class="info description">If there are custom properties for which you want to add custom titles or decimal
      places, please select them here.
    </div>
  </h3>
  <div class="row">
    <select-many-constant-properties [value]="customised_property_ids"
                                     [custom_filters]="cp_filter"
                                     (selectionChange)="propertiesChanged($event)"
                                     [compareWith]="'compareValueToId'"
                                     class="select-many-search-wrapper">
    </select-many-constant-properties>
  </div>

  <div class="row center form-margin-right" *ngFor="let cp_id of customised_property_ids">
    <custom-form-field *ngIf="config.property_customisations"
                       [config]="config.property_customisations[cp_id]"
                       (configChanged)="config.property_customisations[cp_id] = $event"
                       [title]="cp_name_dict[cp_id]"
                       [include_options]="['label','decimal_places']"
                       [display_inline]="true"
                       style="width:100%;">
    </custom-form-field>
    <div class="row-info" style="margin-top:14px;" [hidden]="!isSelected(cp_id)"
         matTooltip="This field is selected as a row or column in your current pivot configuration.">Selected
    </div>
  </div>
</ng-container>
