import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {GenericConstantApiResponse} from "../_models/api/generic-constant";
import {GenericConstantDataService} from '../data/generic-constant-data.service';
import {ModelAttribute, ModelID} from "../_typing/generic-types";

@Injectable({
    providedIn: 'root'
})
export class ComponentFieldService {

    constructor(private http: HttpClient, private genericConstantData: GenericConstantDataService) {
    }

    getFields(component_ids: ModelID[], fields: ModelID[], attributes?: ModelAttribute[]): Observable<GenericConstantApiResponse> {
        return this.genericConstantData.getComponentConstants(component_ids, fields, attributes);
    }

    uploadFile(model_name: string, object_id: string, field: string, file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`/api/upload/${model_name}/${object_id}/${field}`, formData).subscribe((response) => {
            console.log('uploaded file success', response);
        }, error => {
            console.log('uploaded file error\n', error);
        });
    }

}
