import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {difference as _difference} from "lodash-es";
import {forkJoin, of, ReplaySubject, Subject} from "rxjs";
import * as _ from "lodash-es";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {KeyMap} from "../../_typing/generic-types";
import {ComponentType} from "../../_models/component-type";
import {ConstantProperty} from "../../_models/constant-property";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {concatMap, take, tap} from "rxjs/operators";

export interface DialogData {
    form_result: string;
    name: string;
    title: string;
    tile: any;
    dtp: IDateTimePeriod;
}

@Component({
    selector: 'component-type-conditions',
    templateUrl: 'component-type-conditions.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class ComponentTypeConditionsComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() component_types_map: KeyMap<ComponentType> = {};
    @Input() component_type_component_types: any[] = [];

    private _conditions: any;
    @Input() parent: any[] = [];

    @Input()
    set conditions(conditions: any) {
        this._conditions = conditions;
        this.col_value_dict = {...this.col_value_dict, ...conditions};
        this.conditionsLoaded$.next(conditions);
    }

    get conditions(): any {
        return this._conditions;
    }

    conditionsLoaded$: ReplaySubject<any> = new ReplaySubject<any>(1);

    @Output() conditionsChange = new EventEmitter();
    @Output() conditionsChanged = new EventEmitter();

    private _component_type: any;
    @Input()
    set component_type(component_type: any) {
        this._component_type = component_type;
        this.updateComponentTypeConfig();
    }

    get component_type(): any {
        return this._component_type;
    }

    constant_properties_map: KeyMap<ConstantProperty> = {};
    col_value_dict = {}
    col_info_dict: any = {};
    available_column_options: string[] = [];

    private _previousValue: any;
    component_columns: any[] = ['name']


    component_types: any[] = [];
    linked_components_names: any[] = [];
    selected_component_type_cp_names: any[] = [];
    selectedColumns: any[] = [];
    availableColumnFilterValue: string = '';
    selectedColumnFilterValue: string = '';
    itemIndexMap: KeyMap<number> = {}; //Stores original index of item when the lists are filtered

    expanded: boolean = false;

    constructor(private propertyData: ConstantPropertyDataService) {
    }

    ngOnInit(): void {
        this.available_column_options = _difference(this.component_columns, this.selectedColumns);
        this.updateComponentTypeConfig();
        this._previousValue = {...this.conditions};
    }

    getColKey(key) {
        let match = key.match(/\[(.+?)@(.+?)\]/);
        if (match) {
            if (match[1] === 'component_type') {
                return this.component_types_map[match[2]].attributes.name;
            } else if (match[1] === 'constant_property') {
                return this.constant_properties_map[match[2]].attributes.name;
            }
        }
        return key;
    }

    loadConditions(conditions) {
        for (let key in conditions) {
            let name = this.getColKey(key)
            if (!this.selectedColumns.includes(name)) {
                this.selectedColumns.push(name)
            }
        }
        this._previousValue = this.getValue();
        this.available_column_options = _difference(this.available_column_options, this.selectedColumns);
    }

    getValue() {
        let value = {};
        this.selectedColumns.forEach(col => {
            if (this.col_info_dict[col]) {
                let key = '[' + this.col_info_dict[col].type + '@' + this.col_info_dict[col].id + ']';
                value[key] = this.col_value_dict[key];
            } else {
                value[col] = this.col_value_dict[col];
            }
        });
        return value;
    }

    emitEvent() {
        let value = this.getValue()
        let prev = this._previousValue;
        this._previousValue = value;

        // dont create a new object, use same object
        for (var attr in this.conditions) delete this.conditions[attr]
        value = Object.assign(this.conditions, value)
        this.conditionsChanged.emit({value, prev});
    }

    updateComponentTypeConfig() {
        this.linked_components_names = [];
        this.col_info_dict = {};
        if (this.component_type) {
            forkJoin([this.updateLinkedComponentType(), this.updateConstantProperties()])
                .pipe(concatMap(() => {
                    return this.conditionsLoaded$.pipe(tap(() => {
                        this.loadConditions(this.conditions);
                    }))
                }))
                .subscribe(() => {
                    this.setColDefaults();
                    this.updateColumns();

                })
        }

    }

    updateLinkedComponentType() {
        const linked_components_id = this.component_type_component_types.map(ct_ct => {
            if (ct_ct.relationships.first_component_type.data.id === this.component_type.id)
                return ct_ct.relationships.second_component_type.data.id;
            else if (ct_ct.relationships.second_component_type.data.id === this.component_type.id)
                return ct_ct.relationships.first_component_type.data.id;
            return '';
        }).filter(id => id)
            .filter(id => !this.parent.find(p => p.id === id));

        linked_components_id.forEach(id => {
            this.col_info_dict[this.component_types_map[id].attributes.name] = this.component_types_map[id];
        })
        this.linked_components_names = linked_components_id.map(id => this.component_types_map[id].attributes.name)
        this.linked_components_names.sort();
        return of([]);
    }

    updateConstantProperties() {
        return this.propertyData.getPropertiesByRelationshipId(this.component_type.id, 'component_types', null, null, ['string']).pipe(
            tap(result => {
                const selected_component_type_cp = (result?.data || []);
                selected_component_type_cp.forEach(cp => {
                    this.col_info_dict[cp.attributes.name] = cp;
                });

                this.selected_component_type_cp_names = selected_component_type_cp.map(cp => cp.attributes.name)
                this.selected_component_type_cp_names.sort();
                selected_component_type_cp.map(cp => this.constant_properties_map[cp.id] = cp);
            }), take(1)
        )

    }

    setColDefaults() {
        let columns = [...this.available_column_options, ...this.linked_components_names, ...this.selected_component_type_cp_names]
        for (let c in columns) {
            if (!this.col_value_dict[columns[c]]) {
                if (!this.col_info_dict[columns[c]]) {
                    this.col_value_dict[columns[c]] = ''
                } else if (this.col_info_dict[columns[c]].type == 'component_type' &&
                    !this.col_value_dict['[component_type@' + this.col_info_dict[columns[c]].id + ']']) {
                    this.col_value_dict['[component_type@' + this.col_info_dict[columns[c]].id + ']'] = {
                        is_set: false,
                        conditions: {}
                    }
                } else if (this.col_info_dict[columns[c]].type == 'constant_property' &&
                    !this.col_value_dict['[constant_property@' + this.col_info_dict[columns[c]].id + ']']) {
                    this.col_value_dict['[constant_property@' + this.col_info_dict[columns[c]].id + ']'] = ''
                } else {
                    this.col_value_dict[columns[c]] = ''
                }

            }
        }
    }

    updateColumns() {
        const ctrl = this;
        const property_columns = [];
        if (ctrl.component_type) {
            ctrl.linked_components_names.forEach((const_prop) => {
                property_columns.push(const_prop);
                //Only add it to list of available (aka hidden) columns
                if (!ctrl.available_column_options.includes(const_prop) && !ctrl.selectedColumns.includes(const_prop)) {
                    ctrl.available_column_options.push(const_prop);
                }
            });

            ctrl.selected_component_type_cp_names.forEach((const_prop) => {
                property_columns.push(const_prop);
                //Only add it to list of available (aka hidden) columns
                if (!ctrl.available_column_options.includes(const_prop) && !ctrl.selectedColumns.includes(const_prop)) {
                    ctrl.available_column_options.push(const_prop);
                }
            });
        }

        //Remove cols that aren't in either the new properties list or the base event columns list
        //New ones have already been added
        ctrl.selectedColumns = ctrl.selectedColumns.filter(col => {
            return property_columns.includes(col) || ctrl.component_columns.includes(col)
        });

        ctrl.available_column_options = ctrl.available_column_options.filter(col => {
            return property_columns.includes(col) || ctrl.component_columns.includes(col);
        });
    }


    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        } else {
            // use item.index set by columnFilter instead of event.previousIndex which reflects index of item in the displayed list
            // and may not be the actual index in the container.data list
            let name: string = event.item.element.nativeElement.id;
            let prevIndex: number = this.itemIndexMap[name] || event.previousIndex;

            transferArrayItem(event.previousContainer.data,
                event.container.data,
                prevIndex,
                event.currentIndex);

            this.itemIndexMap[name] = event.currentIndex;
            this.emitEvent();
        }
    }

    columnFilter(item: string, value: string, index: number): boolean {
        this.itemIndexMap[item] = index;
        return item.toLowerCase().includes(value.toLowerCase());
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
