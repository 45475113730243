import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Series} from '../../_models/series';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {TileDataService} from "../../services/tile_data.service";
import {WaterfallChartTileConfiguration} from "../../charts/chart-config/generic-chart-tile.configuration";
import {ConfigStub} from "../../_typing/config-stub";
import {styleKeys} from "../../charts/chart-config/chart-configuration";

@Component({
    selector: 'waterfall-chart-form',
    templateUrl: './waterfall-chart-form.component.html',
    styleUrls: ['./waterfall-chart-form.component.less', '../series-table-form.component.less',
        '../custom-chart-form/custom-chart-form-series-table/custom-chart-form-series-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WaterfallChartFormComponent implements OnInit {
    @Input() config: WaterfallChartTileConfiguration;

    selected_series: Series[] | ConfigStub<Series>[];
    col_menu_options: string[] = ['size', 'colour'];
    style_keys = styleKeys;
    colour_keys = {
        'positive_colour': 'Increasing colour',
        'negative_colour': 'Decreasing colour',
        'total_colour': 'Total colour'
    };

    constructor(private tileDataService: TileDataService) {
    }

    ngOnInit(): void {
        this.setDefaults();
        this.selected_series = this.config.series_list.map(s => s.series);
    }

    changeColour($event, key) {
        this.config[key] = $event
    }

    setDefaults() {
        if (!this.config.styles) {
            this.config.styles = {};
        }
    }

    updateSeriesConfig(event: Series[]) {
        this.selected_series = event;
        const selected_ids = this.selected_series.map((s: Series) => s.id);
        this.config.series_list = this.config.series_list.filter(s => {
            return selected_ids.includes(s.series?.id);
        })
        const ids = this.config.series_list.map(s => s.series.id);
        this.selected_series.forEach(s => {
            if (!ids.includes(s.id)) {
                this.config.series_list.push({
                    series: new ConfigStub<Series>(s),
                    measure: 'relative',
                    use_alias: false,
                    custom_legend_name: null,
                    show_data_labels: true,
                    show_flux_labels: true
                })
            }
        })
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
}
