import {Injectable} from '@angular/core';
import {ConfigStub} from "../../_typing/config-stub";
import {ConstantProperty} from "../../_models/constant-property";
import {ComponentType} from "../../_models/component-type";
import {CUSTOM_EVENTS_CONFIG} from "./custom-events-form.component";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {differenceBy as _differenceBy} from "lodash-es";
import {Subject} from 'rxjs/internal/Subject';
import {SeriesDataService} from "../../services/series_data.service";
import {first, takeUntil, tap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";
import {EventType} from '../../_models/event-type';
import {ConstantPropertyDataService} from '../../data/constant-property-data.service';
import {ComponentTypeDataService} from '../../data/component-type-data.service';
import {KeyMap} from '../../_typing/generic-types';
import {NotificationService} from "../../services/notification.service";
import * as utils from '../../lib/utils';
import {sortObjectsByProperty} from '../../lib/utils';
import {ListResponse} from "../../services/api/response-types";
import {EventDataService} from "../../data/event-data.service";
import {EventConfigColumn} from "../../_typing/config/config-column";

@Injectable({
    providedIn: 'root'
})
export class CustomEventsFormService {
    private readonly onDestroy = new Subject<void>();
    public readonly $column_options: Subject<{
        available_column_options: EventConfigColumn[];
        required_columns: string[];
        col_info_dict: KeyMap<ConstantProperty | ComponentType>;
        date_properties: ConstantProperty[];
        col_id_name_map: { [key: string]: string };
    }> = new Subject();

    available_column_options: EventConfigColumn[];
    required_fields: string[] = ['Start', 'Type']; //Fields required by wire (not necessarily by user)

    constant_properties: ConstantProperty[] = [];
    component_types: ComponentType[] = [];
    col_info_dict: KeyMap<ConstantProperty | ComponentType> = {};
    date_properties?: ConstantProperty[];

    col_id_name_map: { [key: string]: string } = {};

    constructor(private seriesData: SeriesDataService,
                private propertyDataService: ConstantPropertyDataService,
                private componentTypeDataService: ComponentTypeDataService,
                private eventDataService: EventDataService,
                private notification: NotificationService) {
    }

    initialiseConfig(config: CUSTOM_EVENTS_CONFIG): CUSTOM_EVENTS_CONFIG {
        /**Sets defaults or missing items on the config json and update available_column_options**/
        this.available_column_options = [];
        if (!config.columns) {
            config.columns = [];
        }
        if (!config.page_size) {
            config.page_size = 20;
        }
        // if (!config.constant_properties) {
        //     config.constant_properties = [];
        // }
        // if (!config.component_types) {
        //     config.component_types = [];
        // }
        if (!config.column_names) {
            config.column_names = {};
        }
        if (!config.required_columns) {
            config.required_columns = [];
        }

        this.getColumnsForEventType(config);
        return config;
    }

    getColumnsForEventType(config: CUSTOM_EVENTS_CONFIG) {
        const ctrl = this;
        ctrl.col_info_dict = {};
        let event_types: ConfigStub<EventType>[] = config.event_types;
        if (!event_types) {
            ctrl.updateColumns(config);
            return;
        }
        const $properties = this.propertyDataService.getConstantPropertiesByRelationshipIds(event_types.map(et => et.id), 'event_types')
            .pipe(takeUntil(this.onDestroy),
                tap(response => {
                    if (response.data.length >= 0) {
                        ctrl.constant_properties = response.data;
                        sortObjectsByProperty(ctrl.constant_properties, 'name');
                        response.data.map(elem => {
                            ctrl.col_info_dict[elem.id] = elem;
                        })
                        ctrl.date_properties = response.data.filter(cp => cp.attributes.data_type === 'datetime');
                    }
                }))


        const $component_types = this.componentTypeDataService.getComponentTypesByRelationshipIds(event_types.map(et => et.id), 'event_types')
            .pipe(takeUntil(this.onDestroy),
                tap(response => {
                    if (response.data.length >= 0) {
                        ctrl.component_types = response.data;

                        sortObjectsByProperty(ctrl.component_types, 'name');
                        response.data.map(elem => {
                            ctrl.col_info_dict[elem.id] = elem;
                        })
                    }
                })
            )

        forkJoin([$properties, $component_types])
            .pipe(first(), takeUntil(this.onDestroy)).subscribe((response) => {
            ctrl.updateColumns(config);
        });
    }

    getEventTypes(event_type_names): Observable<ListResponse<EventType>> {
        return this.eventDataService.getEventTypesByName(event_type_names);
    }

    private updateColumns(config): void {
        const ctrl = this;
        const model_columns: (ConstantProperty | ComponentType)[] = [];
        this.getAttributesColumns().forEach(col => {
            this.col_id_name_map[col.id] = col.id;
        })

        if (!config.columns || config.columns.length < 1) {
            config.columns = [new EventConfigColumn('Start', 'attribute')];
        }

        //Available columns should include all base columns that aren't in ordered columns list
        //Constant_property columns are added to options after data fetched via api in forkJoin below
        this.available_column_options = _differenceBy(this.getAttributesColumns(), config.columns, 'id');

        //'Type' must be included for tables with more than 1 event_type
        if (config.event_types && config.event_types.length > 1) {
            const type = config.columns.find(col => col.id === 'Type');
            if (type) {
                config.columns.find(col => col.id === 'Type').disabled = false;
            } else {
                let type_col = new EventConfigColumn('Type', 'attribute');
                type_col.required = true;
                type_col.disabled = true;
                config.columns.push(type_col);
            }
            this.available_column_options = this.available_column_options.filter(col => col.id !== 'Type');
        }

        if (config.event_types?.length > 0) {
            ctrl.constant_properties.forEach((const_prop) => {
                model_columns.push(const_prop);
                this.col_id_name_map[const_prop.id] = const_prop.attributes.name;
                //Only add it to list of available columns
                if (!ctrl.available_column_options.map(col => col.id).includes(const_prop.id) &&
                    !config.columns.map(c => c.id).includes(const_prop.id)) {
                    ctrl.available_column_options.push(new EventConfigColumn(const_prop, 'constant_property'));
                }
            });
            ctrl.component_types.forEach((comp_type) => {
                model_columns.push(comp_type);
                this.col_id_name_map[comp_type.id] = comp_type.attributes.name;
                if (!ctrl.available_column_options.map(col => col.id).includes(comp_type.id) &&
                    !config.columns.map(c => c.id).includes(comp_type.id)) {
                    ctrl.available_column_options.push(new EventConfigColumn(comp_type, 'component_type'))
                }
            });
        }

        //Remove cols that aren't in either the new properties list or the base event columns list
        //New ones have already been added
        config.columns = config.columns.filter(col => {
            return model_columns.map(c => c.id).includes(col.id) || ctrl.seriesData.event_columns.includes(col.id)
        });

        ctrl.available_column_options = ctrl.available_column_options.filter(col => {
            return model_columns.map(c => c.id).includes(col.id) || ctrl.seriesData.event_columns.includes(col.id)
        });
        this.available_column_options = this.updateDisabled(this.available_column_options, this.available_column_options);
        utils.sortObjectsByFlatProperty(this.available_column_options, 'title');

        ctrl.required_fields.forEach(field => {
            if (!config.required_columns.includes(field)) {
                config.required_columns.push(field);
            }
            if (config.columns.find(col => col.id === field)) {
                config.columns.find(col => col.id === field).required = true;
            }
        });

        this.emitColumnOptions();
    };

    private emitColumnOptions(): void {
        this.$column_options.next({
            available_column_options: this.available_column_options,
            required_columns: this.required_fields,
            col_info_dict: this.col_info_dict,
            date_properties: this.date_properties,
            col_id_name_map: this.col_id_name_map
        });
    }

    columnDropped(event: CdkDragDrop<string[]>, config: CUSTOM_EVENTS_CONFIG, index_dict: KeyMap<number>): CUSTOM_EVENTS_CONFIG {
        const id = event.item.element.nativeElement.id
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            // use item.index set by columnFilter instead of event.previousIndex which reflects index of item in the displayed list
            // and may not be the actual index in the container.data list
            let prevIndex = index_dict[event.item.element.nativeElement.id] || event.previousIndex;

            if (event.container.id === 'availableList') {
                config.required_columns = config.required_columns?.filter(item => item !== event.item.element.nativeElement.id);
            }
            if (event.container.id === 'availableList' && this.required_fields.includes(event.item.element.nativeElement.id)) {
                if (event.item.element.nativeElement.id === 'Start') {
                    this.notification.openSuccess('Start date will be automatically set to the current date at the time a new event is created.', 3000);
                } else {
                    this.notification.openError('Base required columns can not be hidden.');
                    return;
                }
            }

            transferArrayItem(event.previousContainer.data,
                event.container.data,
                prevIndex,
                event.currentIndex);
        }


        // update objects reference so that filter pipe is called
        this.available_column_options = Object.assign([], this.available_column_options);
        this.available_column_options = this.updateDisabled(this.available_column_options, this.available_column_options);
        config.columns = Object.assign([], config.columns);

        this.emitColumnOptions();
        return config;
    }

    updateRequired(event, columns): EventConfigColumn[] {
        const ids = event.map(e => e.id);
        columns.forEach(c => {
            if (ids.includes(c.id)) {
                c.required = true;
            } else {
                c.required = false;
            }
        })
        return columns;
    }

    updateDisabled(values, columns): EventConfigColumn[] {
        const ids = values.map(e => e.id);
        columns.forEach(c => {
            if (ids.includes(c.id)) {
                c.disabled = true;
            } else {
                c.disabled = false;
            }
        })
        return columns;
    }

    private getAttributesColumns(): EventConfigColumn[] {
        const attribute_columns: EventConfigColumn[] = [];
        this.seriesData.event_columns.forEach(id => {
            attribute_columns.push(new EventConfigColumn(id, 'attribute'));
        })
        return attribute_columns;
    }

}
