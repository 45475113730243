import {Component, Input, ViewEncapsulation} from '@angular/core';
import {
    IPendingContextConfig,
    PendingContextComponentConfig
} from "../pending-context-form/pending-context-form.component";
import {COMPONENT_EVENTS_CONFIG} from "../component-events-table-form/component-events-table-form.component";
import {BaseComponent} from "../../shared/base.component";
import {ComponentType, isComponentType} from "../../_models/component-type";
import {EventType} from "../../_models/event-type";
import {KeyMap} from "../../_typing/generic-types";
import {EventDataService} from "../../data/event-data.service";
import {take, takeUntil} from 'rxjs/operators';
import {compareValueToId} from "../../lib/utils";
import {forkJoin} from "rxjs";
import {ComponentTypeService} from "../../services/models/component-type.service";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";

@Component({
    selector: 'component-side-panel-form',
    templateUrl: './component-side-panel-form.component.html',
    styleUrls: ['./component-side-panel-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ComponentSidePanelFormComponent extends BaseComponent {
    private _config: COMPONENT_EVENTS_CONFIG;
    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;

    get config(): COMPONENT_EVENTS_CONFIG {
        return this._config;
    }

    @Input()
    set config(config: COMPONENT_EVENTS_CONFIG) {
        if (config?.selected_component_type?.id !== this._config?.selected_component_type?.id) {
            this._config = config;
            this.componentTypeChanged()
        } else if (config?.selected_component_relationship_types?.map(r => r.id) !==
            this._config?.selected_component_relationship_types?.map(r => r.id)) {
            this._config = config;
            this.relationshipsChanged();
        }
        this._config = config;
    }

    pending_context_config: KeyMap<IPendingContextConfig> = {};
    show_linked_details: KeyMap<boolean> = {};
    linked_event_types: EventType[];
    compareValueToId = compareValueToId;
    componentType: ComponentType;

    constructor(private eventData: EventDataService,
                private componentTypeService: ComponentTypeService) {
        super();
    }

    ngOnInit() {
        if (!this.config.relationships) {
            this.config.relationships = {};
        }
    }

    componentTypeChanged() {
        const componentTypeId = this.config.selected_component_type.id;
        const $componentType = this.componentTypeService.getComponentType(componentTypeId)
        forkJoin([$componentType,
            this.eventData.getEventTypesByRelationshipIds([componentTypeId], 'component_types')
        ])
            .pipe(take(1), takeUntil(this.onDestroy))
            .subscribe(([componentType, result]) => {
                this.componentType = componentType.data;
                this.linked_event_types = result.data || [];
                this.linked_event_types?.forEach(ret => {
                    this.setLinkedItemDetails(ret);
                })
            })
        this.relationshipsChanged();
    }

    relationshipsChanged() {
        this.config.selected_component_relationship_types?.forEach(rct => {
            this.setLinkedItemDetails(rct)
        })
    }

    setUnique(ret: EventType, $event) {
        this.config.relationships[ret.id].unique_property = $event.value.id;
    }

    eventOptions(obj) {
        if (!obj) return;
        return obj.title || obj.id;
    }

    showLinkedItemDetails(rct: ComponentType | EventType, show = true) {
        this.show_linked_details[rct.id] = show;
    }

    setLinkedItemDetails(rct: ComponentType | EventType): void {
        let config = {
            show: false,
            unique_property: null,
            pending_context: null
        };

        if (isComponentType(rct)) {
            config.pending_context = new PendingContextComponentConfig();
            [
                {key: 'component_type_from', val: this.config.selected_component_type.id},
                {key: 'component_type_to', val: rct.id}
            ].forEach(item => {
                config.pending_context.context[item.key].id = item.val;
                config.pending_context.context[item.key].value = null;
                this.setCustomFieldConfig(config.pending_context, 'validation', item.key, item.val);
                this.setCustomFieldConfig(config.pending_context, 'customisation', item.key, item.val);
            })
        } else {
            //For later?
            // config.pending_context = new PendingContextEventConfig();
            // config.pending_context.context.component_type = {id: this.config.selected_component_type.id, value: null};
            // config.pending_context.context.event_type = {id: rct.id, value: null}
        }

        if (!this.config.relationships) {
            this.config.relationships = {};
        }
        if (!this.config.relationships[rct.id]) {
            this.config.relationships[rct.id] = config;
        }
    }

    setCustomFieldConfig(config, parent_key: 'validation' | 'customisation', key, id) {
        if (!config[parent_key][key]) {
            config[parent_key][key] = {};
        }
        if (!config[parent_key][key][id]) {
            config[parent_key][key][id] = {};
        }
    }

}
