<div class="page traveler value-driver-tree-view">
  <div class="row justify-center" style=" ">
    <div style="">
      <select-search-component (selectionChange)="selected_calc = $event.value;updateURL()"
                               [api_model]="'series_light'"
                               [value]="selected_calc"
                               [classes]="'fill bordered-select'"
                               label="Select key value driver"
                               class="select-search form-mat-select"
      >
      </select-search-component>
    </div>
    <mat-slide-toggle [(ngModel)]="formula_view" class="formula-view-toggle">Switch
      to {{formula_view ? 'Value view' : 'Formula view'}}
    </mat-slide-toggle>

    <button (click)="updateCalculations()" [disabled]="!selected_calc">
      <i [class]="'fa small fa-calculator hide-xs hideonprint'" matTooltip='Update all calculations in this tree'></i>
    </button>
  </div>

  <value-driver-tree *ngIf="vdt_data_ready===true"
                     [updateCalculationsRequest]="updateCalculationsSubject.asObservable()"
                     [formula_view]="formula_view"
                     [selected_calculation]="selected_calc"
                     [series_full]="series_full"
                     [treeNodeMap]="treeNodeMap"
                     [is_tile]="false"
  >
  </value-driver-tree>
</div>
