import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {ModelAttribute, ModelID} from '../_typing/generic-types';
import {getManyRelationWithIdFilter, getRelationWithIdFilter} from '../services/api/filter_utils';
import {GenericDataService} from "./generic-data.service";
import {SessionState} from "../_models/session-state";
import {ListResponse, SingleResponse} from '../services/api/response-types';
import {Section} from "../services/tile_data.service";

@Injectable({
    providedIn: 'root'
})
export class PageDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public pageSectionsUpdated: Subject<Section[]> = new Subject();

    constructor(private genericData: GenericDataService) {
    }

    generateFilteredPagesFilter(filter: string, keys: ModelAttribute[]) {
        return this.genericData.generateFilteredModelFilter(filter, keys);
    }

    generateAllUserPagesFilter(user_id: ModelID): any[] {
        return [{
            or: [
                this.generatePagesByOwnerId(user_id)[0],
                this.generatePagesByRelationshipIdFilter('users', user_id)[0],
                this.generatePagesByRelationshipByUserIdFilter('groups', user_id)[0]
            ]
        }
        ]
    }

    generatePagesByRelationshipIdFilter(rel_name: string, rel_value: string) {
        return this.genericData.generateModelsByRelationshipIdFilter(rel_name, rel_value);
    }

    /**Gets pages for mmRelationship intermediate by user_id
     * e.g. get all pages for all groups assigned to a given user
     * Returns ListResponse<SessionState>
     * @param rel_name: name of the intermediate model
     * @param user_id: id of the user for which to get 'rel_name's by which to get pages
     * e.g. Get all properties for event_types where event_type is linked to given component_type ids
     */
    generatePagesByRelationshipByUserIdFilter(rel_name: string, user_id: string) {
        return [
            {
                name: rel_name,
                op: 'any',
                val: {
                    and: [getManyRelationWithIdFilter('users', user_id)]
                }
            }];
    }

    generatePagesByOwnerId(user_id: string) {
        return [getRelationWithIdFilter('user', user_id)]
    }

    savePage(page: SessionState): Observable<SingleResponse<SessionState>> {
        return this.genericData.upsertModel<SessionState>('session_state', page);
    }

    getPages(ids: ModelID): Observable<ListResponse<SessionState>> {
        return this.genericData.getModelByIds<SessionState>('session_state', ids);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
