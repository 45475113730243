import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {EditFlowchartComponent} from "../edit_component";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api/api.service";
import {ComponentConstantsTableService} from "../../tables/component-constants-table/component-constants-table.service";
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../shared/base.component';

@Component({
    selector: 'flowchart-process-form',
    templateUrl: 'flowchart-process-form.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ComponentConstantsTableService],
    standalone: false
})

export class FlowchartProcessFormComponent extends BaseComponent {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event: EventEmitter<any> = new EventEmitter();
    @Output() close_event: EventEmitter<any> = new EventEmitter();

    efc: EditFlowchartComponent;
    hint: string;
    showing_hints: any;
    connector_positions: any = {};
    linked_page_title: string;

    constructor(private plantData: PlantDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private http: HttpClient,
                private componentConstantTableService: ComponentConstantsTableService) {
        super();
    }

    ngOnInit(): void {
        this.efc = new EditFlowchartComponent(this.flowchart, this.component, this.plantData, this.seriesData, this.api,
            this.http, this.componentConstantTableService);

        if (this.component.is_parent === true && this.component.connectors) {
            this.component.connectors.forEach(c => {
                let position = 'top'
                if (c.x() === this.component.width()) {
                    position = 'right';
                } else if (c.y() === this.component.height()) {
                    position = 'bottom';
                } else if (c.x() === 0) {
                    position = 'left';
                }
                this.connector_positions[c.data.id] = {connector: c, x: c.x(), y: c.y(), position: position}
            })
        }
        if (this.efc.component_data.attributes.json?.pages?.length) {
            this.linked_page_title = this.efc.component_data.attributes.json.pages[0].title;
        }

        this.efc.loadForm();
        this.efc.saveComplete.pipe(takeUntil(this.onDestroy)).subscribe((saved: boolean) => {
            this.save_event.emit(this.efc.component_view);
        })
    }

    holdConnectors() {
        if (this.component.is_parent === true && this.component.connectors) {
            Object.keys(this.connector_positions).forEach(key => {
                let c = this.connector_positions[key];
                if (c.position === 'top') {
                    c.connector.data.attributes.json.parent.percent = c.x / this.component.perimeter() * 100;
                } else if (c.position === 'right') {
                    c.connector.data.attributes.json.parent.percent = (this.component.width() + c.y) / this.component.perimeter() * 100;
                } else if (c.position === 'bottom') {
                    c.connector.data.attributes.json.parent.percent = ((this.component.perimeter() - this.component.height() - c.x)) / this.component.perimeter() * 100;
                } else if (c.position === 'left') {
                    c.connector.data.attributes.json.parent.percent = (this.component.perimeter() - c.y) / this.component.perimeter() * 100;
                }
            })
        }
    }

    pageSelected($event) {
        if (!$event.value?.id) return;
        this.linked_page_title = $event.value.attributes?.name;

        //For now, only allow 1
        this.efc.component_data.attributes.json.pages = [];
        this.efc.component_data.attributes.json.pages[0] = {
            id: $event.value.id,
            title: $event.value.attributes?.name || 'relevant page'
        };
    }

    pageTitleChanged() {
        if (this.efc.component_data.attributes.json.pages.length) {
            this.efc.component_data.attributes.json.pages[0].title = this.linked_page_title;
        }
    }

    save() {
        this.efc.save();
    }

    close(): void {
        this.close_event.emit();
    }
}
