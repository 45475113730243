import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from "../../services/api/api.service";
import {first, map, takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SeriesDetailTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    readonly dataReady = new Subject();
    columns = ['base_type', 'sample_period', 'engineering_unit_name', 'aggregation', 'default_chart', 'fill_method',
        'decimal_places', 'hihi', 'hi', 'lowlow', 'low'];
    column_name_map = {
        engineering_unit_name: 'unit'
    }
    title_columns = ['status', 'name', 'description'];

    constructor(private api: ApiService) {
    }

    fetchSeries(ids) {
        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'in', val: ids}];
        this.api.series.searchMany(options).pipe(
            first(),
            map(data => {
                data.data.forEach(s => {
                    s['Name'] = s.attributes.name;
                    s['Description'] = s.attributes.description
                });
                return data;
            }),
            takeUntil(this.onDestroy)
        ).subscribe(result => {
            this.dataReady.next(result.data);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
