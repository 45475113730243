import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class PrintoutComponentTypeAttributes extends AuditMixinAttributes {
    printout_id?: string
    conditions?: any = {}
    //hybrid properties:
    printout_name: string;
    component_type_name: string;
}

export class PrintoutComponentTypeRelationships extends AuditMixinRelationships {
    component_type: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    printout: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

/**
 * NOTE: model not fleshed out as it does not seem to be used.
 */
export class PrintoutComponentType extends AuditMixin {
    id: string;
    attributes: PrintoutComponentTypeAttributes;
    relationships: PrintoutComponentTypeRelationships;
    type: string = 'printout_component_type';

    constructor() {
        super();
        this.attributes = new PrintoutComponentTypeAttributes();
        this.relationships = new PrintoutComponentTypeRelationships();
    }
}
