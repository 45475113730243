<div #context_tile [ngStyle]="{'border-color':category_colour}" class="context-tile">
  <ng-container *ngIf="!is_vdt">
    <ng-container *ngIf="!config.selected_series">
      Please select a series for this tile.
    </ng-container>
    <ng-container *ngIf="show_header !==true">
      <div *ngIf="title" class="title"><span>{{title}}</span></div>
      <div *ngIf="!title && config.selected_series" class="title"
           title="{{config.selected_series.attributes | nameOrDescription}}">{{config.selected_series.attributes | nameOrDescription}}
      </div>
      <div *ngIf="config.labels?.sub_title" class="sub-title">{{config.labels?.sub_title}}</div>
    </ng-container>
    <!--"Full" is for showing primary and secondary values-->
    <div [ngClass]="{'full': !config.value_only}" class="context-layout">
      <div
          [ngStyle]="{'margin-top': show_header ===true ? '10px' : '0','margin-bottom': show_header===true ? '-10px' : '0'}"
          class="primary-value primary-tile">
        <span *ngIf="summary">{{summary[config.primary_value] | formatNumber:summary['DecimalPlaces']:true:null:false}}</span>
        <span *ngIf="config.show_unit && config.series_unit">&nbsp;{{config.series_unit}}</span>
      </div>
      <div #context_content class="row context-content">
        <ng-container *ngIf="summary">
          <generic-chart #generic *ngIf="config.chart_type==='custom-chart'" [config]="config.chart_config"
                         [style.max-height]="content_height + 'px'" class="chart">
          </generic-chart>
          <sparkline *ngIf="(!config.chart_type || config.chart_type==='sparkline') &&  summary"
                     [data_points]="summary.Sparkline"
                     [height]="500"
                     [series_name]="config.selected_series.attributes.name"
                     class="chart"></sparkline>
        </ng-container>
        <div class="primary-vdt">
          <div #primary_value class="primary-value">
          <span *ngIf="summary"
                [ngClass]="{'favourable': summary[est_favourability_mapping[config.primary_value]]===true,
                'unfavourable': !summary[est_favourability_mapping[config.primary_value]]}">
            {{summary[config.primary_value] | formatNumber:summary['DecimalPlaces']:true:null:false}}</span>
            <span *ngIf="config.show_unit && config.series_unit">{{config.series_unit}}</span>

          </div>
        </div>

        <div *ngIf="summary && !config.value_only" class="secondary-values column">
          <div>{{seriesData.column_dict[config.secondary_val_1]?.abbr ?
              seriesData.column_dict[config.secondary_val_1].abbr : config.secondary_val_1 | upperfirst}}:
            <span
                [ngClass]="{'favourable': summary[est_favourability_mapping[config.secondary_val_1]]===true,
                'unfavourable': !summary[est_favourability_mapping[config.secondary_val_1]]}">{{summary[config.secondary_val_1] | formatNumber:summary['DecimalPlaces']:true:null:false}}</span>

          </div>
          <div>{{seriesData.column_dict[config.secondary_val_2]?.abbr ?
              seriesData.column_dict[config.secondary_val_2].abbr : config.secondary_val_2 | upperfirst}}:
            <span
                [ngClass]="{'favourable': summary[est_favourability_mapping[config.secondary_val_2]]===true,
                'unfavourable': !summary[est_favourability_mapping[config.secondary_val_2]]}">{{summary[config.secondary_val_2] | formatNumber:summary['DecimalPlaces']:true:null:false}}</span>

          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="is_vdt" style="height:100%;width:100%;" class="vdt-container"
       [ngClass]="{'box-favourable': summary && summary[est_favourability_mapping[config.primary_value]]===true,
                'box-unfavourable': summary && !summary[est_favourability_mapping[config.primary_value]]}">
    <div class="vdt-title"
         title="{{config.selected_series.attributes | nameOrDescription}}">{{config.selected_series.attributes | nameOrDescription}}
    </div>
    <div class="row vdt-box" *ngIf="summary">
      <sparkline [data_points]="summary.Sparkline"
                 [series_name]="config.selected_series.attributes.name"
                 class="vdt-sparkline"
      ></sparkline>
      <div class="vdt-value" style="visibility:hidden;" [dblClickCopy]="summary[config.primary_value]"
           [matTooltip]="summary[config.primary_value]"
           [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY">
        {{summary[config.primary_value] | formatNumber:summary['DecimalPlaces']:true:null:false}}
        <span *ngIf="config.show_unit && config.series_unit">{{config.series_unit}}</span>
      </div>
    </div>
  </div>
</div>
