<div class="form-dialog">
  <h1>Upload File
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <form class="form-section column">

          <drop-file-selector style="width:100%;" [file]="file"
                              (selectionChanged)="handleFileInput($event)">
          </drop-file-selector>

          <div class="row center">
            <label class="inline" style="min-width:100px;">Collector</label>
            <select-search-component [api_model]="'collector'"
                                     [value]="collector"
                                     (selectionChange)="collector=$event.value"
                                     [classes]="'fill'"
                                     class="select-search">
            </select-search-component>
          </div>

          <div class="row">
            <mat-checkbox [(ngModel)]="override" name="override">Override</mat-checkbox>

            <mat-checkbox [(ngModel)]="add_mappers" name="add_mappers">Add Mappers</mat-checkbox>

            <mat-checkbox [(ngModel)]="add_series" name="add_series">Create Series</mat-checkbox>

            <mat-checkbox [(ngModel)]="row_major" name="row_major">Row Major</mat-checkbox>

            <mat-checkbox [(ngModel)]="non_time_series" name="non_time_series">Tick For Data That is Not Time Series
              Data
            </mat-checkbox>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content><!--/mat-dialog-content-->
  <div class="action-buttons">
    <button class="btn-close" (click)="closeDialog()"> Close</button>
    <button class="btn-save" (click)="uploadData()" [disabled]="!(fileToUpload && collector)"> Upload</button>
  </div>
</div>
