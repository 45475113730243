import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription, takeUntil} from "rxjs";
import {BaseComponent} from "../shared/base.component";
import {HeaderDataService} from "../services/header_data.service";
import {Process} from "../_models/process";
import {ApiService} from "../services/api/api.service";
import {PlantDataService} from "../services/plant-data/plant_data.service";


@Component({
    selector: 'estimate-sheet-focus-view',
    templateUrl: 'estimate-sheet-focus-view.component.html',
    standalone: false
})
export class EstimateSheetFocusViewComponent extends BaseComponent {
    process: Process;
    params_subscription: Subscription;
    processID: string;

    constructor(private headerData: HeaderDataService,
                private api: ApiService,
                private plantData: PlantDataService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            this.processID = params.processID;
            this.refresh();
        });
    }

    refresh() {
        this.api.process.getById(this.processID).subscribe(resp => {
            this.process = resp.data;
            this.plantData.setActiveProcess(this.process);
            this.headerData.title = 'Estimates List: ' + this.process.attributes.name;
            this.headerData.setPath(this.process);
        });
    }
}
