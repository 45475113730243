import {Component, Input} from '@angular/core';
import {BaseComponent} from "../../shared/base.component";
import {TileParameters} from "../../services/tile_data.service";
import {Series} from '../../_models/series';
import {getManyRelationWithIdFilter} from "../../services/api/filter_utils";

@Component({
    selector: 'default-chart-form',
    templateUrl: './default-chart-form.component.html',
    styleUrls: ['./default-chart-form.component.less'],
    standalone: false
})
export class DefaultChartFormComponent extends BaseComponent {
    @Input() content: 'budget-bar-chart' | 'default-chart';

    @Input()
    set config(config: Partial<TileParameters>) {
        this._config = config;
        if (!this.config.labels) {
            this.config.labels = {};
        }
        this.setTargetFilter();
    }

    get config(): Partial<TileParameters> {
        return this._config;
    }

    private _config: Partial<TileParameters>;
    public hint: string = 'Name';
    public target_series_filter: any;

    constructor() {
        super();
    }

    seriesSelected(series: Series) {
        this.config.series = series;
        this.setTargetFilter();
    }

    setTargetFilter() {
        if (!this.config?.series?.id) return;
        this.target_series_filter = [getManyRelationWithIdFilter('source_series', this.config.series.id)];
    }
}
