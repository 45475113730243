import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {BaseComponent} from '../../shared/base.component';

export type ComponentStatusOptions = 'saving' | 'success' | 'error';
export type ComponentStatusSubject = { key: string, value: ComponentStatusOptions, errors: string[] };

export interface Saveable {
    $componentStatus: Subject<ComponentStatusSubject>;

    getStatusObservable(): Observable<ComponentStatusSubject>;

    publishStatus(status: ComponentStatusSubject): void;
}

/***Base class for any component that wants to implement the Saveable interface
 * This provides a handle to the parent of the component to subscribe to the child's save status
 * and a function to emit the subject status
 */
export abstract class SaveableBase extends BaseComponent implements Saveable {
    $componentStatus = new ReplaySubject<ComponentStatusSubject>(1);
    $canSave: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() {
        super();
    }

    getStatusObservable() {
        return this.$componentStatus.asObservable();
    }

    publishStatus(status: ComponentStatusSubject): void {
        this.$componentStatus.next(status);
    }

    setCanSave(canSave: boolean) {
        this.$canSave.next(canSave);
        this.canSave()
    }

    canSave(): boolean {
        return this.$canSave.getValue();
    }
}

/**
 * Services for handling save events with hierarchies of components.
 *
 * Simply allows the parent component to tell child components that it wants them to save their state.
 */
@Injectable()
export class SaveService {
    private canSaveCheck: () => boolean = () => true;

    private saveSubject: Subject<boolean> = new Subject<boolean>();

    public getSaveHandle() {
        return this.saveSubject.asObservable();
    }

    public emitSave(refresh = true): void {
        this.saveSubject.next(refresh);
    }

    public setCanSave(canSaveCheck: () => boolean): void {
        this.canSaveCheck = canSaveCheck;
    }

    // Method to check if save is allowed, used to deactivate save button if no changes were made
    public canSave(): boolean {
        return this.canSaveCheck();
    }
}
