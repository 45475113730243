import {Injectable} from '@angular/core';
import {AppScope} from '../../services/app_scope.service';
import {ReplaySubject} from "rxjs";
import {Process} from "../../_models/process";

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbsService {

    breadcrumbs: string[] = [];
    public readonly breadcrumbsChanged: ReplaySubject<string[]> = new ReplaySubject<string[]>()

    constructor(private appScope: AppScope) {
    }

    resetBreadcrumbs() {
        this.breadcrumbsChanged.next([]);
    }

    setPath(process: Process) {
        this.breadcrumbs = [];
        if (this.appScope.process_tree_data) {
            let prev = this.appScope.process_tree_data.find(p => p.id === process.relationships.parent.data?.id);
            let path = process.attributes.name;
            while (prev) {
                path = prev.attributes.name + ' > ' + path;
                if (prev.attributes.parent_id) {
                    prev = this.appScope.process_tree_data.find(p => p.id === prev.attributes.parent_id);
                } else {
                    prev = null;
                }
            }
            this.breadcrumbs.push(path);
        }
        this.breadcrumbsChanged.next(this.breadcrumbs);
    }

    setFolder(session) {
        this.breadcrumbs = [];
        if (this.appScope.folder_dict && this.appScope.folder_dict[session.relationships.folders?.data?.[0]?.id]) {
            for (let i = 0; i < session.relationships.folders.data.length; i++) {
                let path = '';
                let is_default = false;
                let folder = this.appScope.folder_dict[session.relationships.folders.data[i].id];
                if (session.relationships.default_folder.data?.id === folder.folder.id) is_default = true;
                while (folder) {
                    path = folder.folder.attributes.name + ' > ' + path;
                    folder = folder.parent;
                }
                if (is_default) {
                    this.breadcrumbs.unshift(path?.slice(0, -2));
                } else {
                    this.breadcrumbs.push(path?.slice(0, -2));
                }
            }
        }
        this.breadcrumbsChanged.next(this.breadcrumbs);
    }
}
