import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {TileParameters} from "../../../services/tile_data.service";
import {BehaviorSubject, catchError, first, forkJoin, Observable, of, switchMap, takeUntil, tap} from "rxjs";
import {EventConfigColumn} from "../../../_typing/config/config-column";
import {KeyMap} from "../../../_typing/generic-types";
import {ConstantProperty} from "../../../_models/constant-property";
import {ComponentTypeStubSchema} from "../type/component-type";
import {ReportParameters} from "../type/report-parameters";
import {CustomEventsService} from "../../../services/custom-events.service";
import {ConstantPropertyDataService} from "../../../data/constant-property-data.service";
import {ComponentTypeDataService} from "../../../data/component-type-data.service";
import {JsonContextService} from "../json-context.service";
import {ConfigStub} from "../../../_typing/config-stub";
import {ComponentType} from "../../../_models/component-type";
import {SelectedColumns} from "../type/selected-component";
import {IColumnSelectorEmitter, IFilterEmitter} from "../../../components/event-column-selector/type/selector-emitter";
import {IAliasProperty} from "../type/constant-property-mapper";
import {ListResponse} from "../../../services/api/response-types";
import {BaseComponent} from "../../../shared/base.component";
import {match_schema, sortObjectsByFlatProperty} from "../../../lib/utils";

@Component({
    selector: 'component-report-builder',
    templateUrl: './component-report-builder.component.html',
    styleUrls: ['./component-report-builder.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ComponentReportBuilderComponent extends BaseComponent {
    @Output() configChange: EventEmitter<TileParameters> = new EventEmitter<TileParameters>();

    isComponentTypeCollapsed = false;
    isComponentRelationshipTypeCollapsed = true;
    private isRelationshipLoadingSubject = new BehaviorSubject<boolean>(false);
    isRelationshipLoading$ = this.isRelationshipLoadingSubject.asObservable();

    private isComponentTypeLoadingSubject = new BehaviorSubject<boolean>(false);
    isComponentTypeLoading$ = this.isComponentTypeLoadingSubject.asObservable();

    availableColumns: EventConfigColumn[] = [];
    availableComponentColumns: KeyMap<EventConfigColumn[]> = {};

    componentRelationshipFilters: any[];
    constantProperties: ConstantProperty[];
    dateProperties: ConstantProperty[];
    constantPropertyDict: Record<string, ConstantProperty> = {};
    aliasDict: Record<string, string> = {};

    componentTypeStubSchema: ComponentTypeStubSchema;
    apiModel: string;

    private _config: ReportParameters;
    get config(): ReportParameters {
        return this._config
    }

    @Input()
    set config(config: ReportParameters) {
        this._config = config;
    }

    constructor(
        protected customEventsService: CustomEventsService,
        private _constantPropertyDataService: ConstantPropertyDataService,
        private _componentTypeDataService: ComponentTypeDataService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _jsonContextService: JsonContextService
    ) {
        super();
    }

    ngOnInit(): void {
        this.isComponentTypeLoadingSubject.next(true);
        this.componentTypeStubSchema = new ComponentTypeStubSchema();
        this.apiModel = this.componentTypeStubSchema.type;

        if (this.config.selectedComponentType?.id) {
            this.componentTypeChanged().pipe(first()).subscribe();
        } else {
            this.config.selectedComponentRelationshipTypes = null; // Clean up config
            this.isRelationshipLoadingSubject.next(false);
            this.isComponentTypeLoadingSubject.next(false);
        }

        this._jsonContextService.config$.pipe(takeUntil(this.onDestroy)).subscribe((config) => {
            this.config = {...this.config, ...config};
        });
    }

    onConfigChange() {
        this._jsonContextService.configSubjectChanged(this.config);
        this.configChange.emit(this.config);
    }

    toggleComponentTypeCollapsed() {
        this.isComponentTypeCollapsed = !this.isComponentTypeCollapsed;
    }

    private _resetSelectedComponentType(value): void {
        this.config.selectedComponentType = new ConfigStub<ComponentType>(value);
        this.config.selectedColumns = new SelectedColumns();
        this.config.selectedComponentRelationshipTypes = null;
        this.onConfigChange();
    }

    componentTypeChanged($event?): Observable<any> {
        this.isComponentTypeLoadingSubject.next(true);
        this.isRelationshipLoadingSubject.next(true);
        this.availableColumns = null;
        if ($event) {
            this._resetSelectedComponentType($event);
        }
        return this.getComponentTypeProperties(this.config.selectedComponentType).pipe(
            first(),
            switchMap(() => {
                this.setComponentRelationshipFilters(this.config.selectedComponentType);
                if ($event) {
                    this.onConfigChange();
                }
                this.isComponentTypeLoadingSubject.next(false);
                this.isRelationshipLoadingSubject.next(false);
                if (this.config.selectedComponentRelationshipTypes) {
                    return this.componentRelationshipChanged();
                } else {
                    this.isRelationshipLoadingSubject.next(false);
                    return of(null); // Return an empty observable if no relationship types
                }
            }),
            tap(() => this.isComponentTypeLoadingSubject.next(false)),
            takeUntil(this.onDestroy));
    }

    setComponentRelationshipFilters(selectedComponentType: ConfigStub<ComponentType>): void {
        this.componentRelationshipFilters = this._componentTypeDataService.getComponentRelationshipFilters(selectedComponentType.id);
    }

    getFilters(componentTypeId?: string) {
        let filterKeys: EventConfigColumn[];
        if (componentTypeId) {
            filterKeys = this.config.selectedColumns?.component[componentTypeId];
        } else {
            filterKeys = this.config.selectedColumns?.componentType;
        }

        return filterKeys.reduce((acc, col) => {
            acc[col.id] = col.filters;
            return acc;
        }, {});
    }

    onFiltersChange($event: Record<string, string[]>, componentTypeId?: string) {
        const columnId = Object.keys($event)?.[0];
        if (componentTypeId && this.config.selectedColumns.component[componentTypeId]?.find(column => column.id === columnId)) {
            this.config.selectedColumns.component[componentTypeId].find(column => column.id === columnId).filters = $event[columnId];
        } else {
            this.config.selectedColumns.componentType.find(column => column.id === columnId).filters = $event[columnId];
        }
        this.onConfigChange();
    }

    onPropertyAliasChange($event: IAliasProperty, componentTypeId?: string) {
        this.onConfigChange();
    }

    private getComponentTypeProperties(selectedComponentType: ConfigStub<ComponentType>): Observable<any> {
        return this._constantPropertyDataService.getPropertiesByRelationshipId(selectedComponentType.id, 'component_types')
            .pipe(
                tap(({data}: ListResponse<ConstantProperty>) => {
                    this.constantProperties = data;
                    this.constantPropertyDict = Object.fromEntries(data.map(constantProperty => [constantProperty.id, constantProperty]));

                    this.dateProperties = data.filter(constantProperty => constantProperty.attributes.data_type === 'datetime');

                    this.setComponentTypeColumns(data);
                    this._changeDetectorRef.markForCheck();
                }),
                catchError(error => {
                    console.error('Error fetching component type properties:', error);
                    return of({data: []} as ListResponse<ConstantProperty>); // Return an empty observable on error
                })
            );
    }

    private setComponentTypeColumns(constantProperties: ConstantProperty[]): void {
        this.availableColumns = [];
        this.config.selectedColumns.componentType = this.setupColumns(
            constantProperties,
            'availableColumns', null,
            this.config.selectedColumns.componentType);
    }

    private setupColumns(constantProperties: ConstantProperty[], availableColumnsKey: string, keyId: string,
                         configSelected: EventConfigColumn[]): EventConfigColumn[] {
        // Update constant property dictionary
        constantProperties.forEach(constantProperty => this.constantPropertyDict[constantProperty.id] = constantProperty);

        // Clean up selected columns
        configSelected = this.cleanColumns(configSelected || [], constantProperties);

        // Get available columns and sort them
        const availableColumns = this.getAvailableColumns(configSelected, constantProperties);
        sortObjectsByFlatProperty(availableColumns, 'title');

        // Assign available columns to the appropriate key
        this[availableColumnsKey] = keyId ? {
            ...this[availableColumnsKey],
            [keyId]: availableColumns
        } : availableColumns;

        // Initialise formats dictionary if not provided
        // formatsDict = formatsDict || {};
        // this.setFormats(configSelected, formatsDict);
        this._changeDetectorRef.markForCheck();
        return configSelected;
    }

    private cleanColumns(selectedColumns: EventConfigColumn[], constantProperies: ConstantProperty[]): EventConfigColumn[] {
        selectedColumns = selectedColumns.filter(column => {
            return constantProperies.map(constantProperty => constantProperty.id).includes(column.id);
        });

        return selectedColumns;
    }

    private getAvailableColumns(selectedColumns: EventConfigColumn[] = [], constantProperties: ConstantProperty[] = []): EventConfigColumn[] {
        const selectedIds = new Set(selectedColumns.map(column => column.id));
        return constantProperties
            .filter(property => !selectedIds.has(property.id))
            .map(item => new EventConfigColumn(item, 'constant_property'));
    }

    componentRelationshipChanged($event?: ComponentType[]): Observable<any> {
        this.isRelationshipLoadingSubject.next(true);
        if ($event) {
            this.config.selectedComponentRelationshipTypes = $event.map(relatedComponentType => {
                match_schema(relatedComponentType, this.componentTypeStubSchema);
                return relatedComponentType;
            });
        }
        this.onConfigChange();
        const relationships$: Observable<ListResponse<ConstantProperty>>[] = this.filterComponentRelationshipProperties(
            this.config.selectedComponentRelationshipTypes
        );

        if (relationships$.length > 0) {
            return forkJoin(relationships$).pipe(
                first(),
                tap(() => {
                    if ($event) {
                        this.onConfigChange();
                    }
                    this.isRelationshipLoadingSubject.next(false);
                }),
                takeUntil(this.onDestroy)
            );
        } else {
            this.availableComponentColumns = {};
            this.isRelationshipLoadingSubject.next(false);
            return of(null); // Return an empty observable if no relationships
        }
    }

    filterComponentRelationshipProperties(relatedComponentTypes: ConfigStub<ComponentType>[]): Observable<ListResponse<ConstantProperty>> [] {
        this.config.selectedColumns.component ||= {};

        return relatedComponentTypes.map(relatedComponentType => {
            this.availableComponentColumns[relatedComponentType.id] = null;
            match_schema(relatedComponentType, this.componentTypeStubSchema);
            return this.getComponentRelationshipColumns(relatedComponentType);
        });
    }

    private _updateComponentColumns(componentTypeId: string, constantProperties: ConstantProperty[]): void {
        this.config.selectedColumns.component[componentTypeId] = this.setupColumns(
            constantProperties,
            'availableComponentColumns',
            componentTypeId,
            this.config.selectedColumns.component[componentTypeId],
        );
    }

    getComponentRelationshipColumns(relatedComponentType: ConfigStub<ComponentType>): Observable<ListResponse<ConstantProperty>> {
        return this._constantPropertyDataService.getPropertiesByRelationshipId(relatedComponentType.id, 'component_types')
            .pipe(
                tap((constantProperties: ListResponse<ConstantProperty>) => {
                    this._updateComponentColumns(relatedComponentType.id, constantProperties.data);
                })
            );
    }

    columnsChanged($event: Partial<IColumnSelectorEmitter>, componentTypeId?: string): void {
        const selectedColumns = $event.selectedColumns; // Store selected columns in a variable
        if (componentTypeId) {
            this.config.selectedColumns.component[componentTypeId] = selectedColumns;
        } else {
            this.config.selectedColumns.componentType = selectedColumns;
        }
        this.onConfigChange();
        this._changeDetectorRef.markForCheck();
    }

    onComponentRelationshipChanged($event: ComponentType[]): void {
        this.componentRelationshipChanged($event).pipe(
            first() // Ensure it completes
        ).subscribe(() => {
            this.isComponentTypeLoadingSubject.next(false);
        });
    }

    onComponentTypeSelectionChange($event): void {
        this.config.selectedComponentType = new ConfigStub<ComponentType>($event.value);
        this.onConfigChange();
        this.componentTypeChanged($event.value).pipe(first()).subscribe();
    }

}
