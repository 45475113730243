import {Component, Inject, OnInit, ViewEncapsulation, ComponentRef} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

export interface SnackbarData {
    message: string;
    action: string;
    contains_duration: boolean;
    component_ref: ComponentRef<any>
}

@Component({
    selector: 'snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class SnackbarComponent implements OnInit {
    message: string;
    actionText: string;
    hasDuration: boolean = false;
    constructor(public snackBarRef: MatSnackBarRef<any>, @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {
        this.actionText = data.action;
        this.message = this.data.message;
        this.hasDuration = this.data.contains_duration || false;
    }

    ngOnInit(): void {
        console.log('SnackbarComponent - ngOnInit: ', this.data);
    }
}
