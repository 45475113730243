import {Component, Input, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {NotificationComponentBase} from '../../_typing/notification';
import {ModelID} from '../../_typing/generic-types';
import {Router} from "@angular/router";

@Component({
    selector: 'job-notification',
    templateUrl: './job-notification.component.html',
    styleUrls: ['./job-notification.component.less'],
    standalone: false
})
export class JobNotificationComponent extends NotificationComponentBase {
    jobId: ModelID;
    jobType: string;

    constructor(public snackBarRef: MatSnackBarRef<any>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any,
                private router: Router) {
        super(snackBarRef, data);
        this.jobId = data.jobId;
        this.jobType = data.jobType ?? "Task";
    }

    viewJobDetails(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(['view', 'jobs', this.jobId]);
    }
}
