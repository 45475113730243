import {Directive, HostListener, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[escToAction]',
    standalone: false
})
export class EscToActionDirective {
    @Output() escToAction = new EventEmitter<void>();

  constructor() {}

  @HostListener('document:keydown.esc', ['$event'])
  handleEscKey(event: KeyboardEvent) {
      event.preventDefault();
      this.escToAction.emit();
  }
}
