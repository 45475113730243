import * as utils from '../lib/utils';
import {Injectable, OnDestroy} from '@angular/core';
import {AppScope} from './app_scope.service';
import {ActivatedRoute} from '@angular/router';
import {forkJoin, Subject, ReplaySubject, combineLatest} from "rxjs";
import {takeUntil, tap, concatMap, take, distinctUntilChanged, map} from 'rxjs/operators';
import {ApiService} from "./api/api.service";
import {Location} from "@angular/common";
import {moment_timezone, TimezoneSelectorService} from './timezone-selector.service';
import {moment} from './timezone-selector.service';
import * as moment_ from "moment";

import {
    Calendar,
    DateTimePeriod, IDateTimePeriod,
    IRelativeDtpConfig, OptionalCalendar,
    OptionalSamplePeriod,
    RelativeDtp, SamplePeriod
} from "../_typing/date-time-period";
import {NotificationService} from "./notification.service";
import {deepCopy} from '../lib/utils';
import {SearchQueryOptions} from "./api/search-query-options";
import {getAccountFilter} from "./api/filter_utils";
import {CustomTimePeriod, CustomTimePeriodRange, ICustomTimePeriod} from "../_models/custom-time-period";
import {ShiftVersion} from "../_models/shift_version";
import {ShiftType} from "../_models/shift_type";
import {IDMap, KeyMap} from "../_typing/generic-types";
import {DEFAULT_CALENDAR_NAME} from "../shared/globals";

let milliseconds_cache = {};
let seconds_cache = {};
let minutes_cache = {};
let hours_cache = {};
let days_cache = {};
let months_cache = {};

interface DateRange {
    range: string;
    start: string;
    end: string;
}

@Injectable()
export class DateTimePeriodService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    range_dict: Record<string, IDateTimePeriod>;
    relative_range_dict: Record<string, IDateTimePeriod>;

    //Promise that completes when the service has its first dtp value (when required prerequisites have been met)
    readonly dtpInitialisedPromise: utils.FlatPromise;
    dtpInitialised$ = new ReplaySubject<IDateTimePeriod>(1);

    calendars: OptionalCalendar[];
    calendar_dict: Record<string, OptionalCalendar> = {};
    time_periods: ICustomTimePeriod[];
    sample_periods: OptionalSamplePeriod[];
    ranges: IDateTimePeriod[];
    relative_ranges: IDateTimePeriod[];
    private dtp: IDateTimePeriod;
    defaultPeriod: OptionalSamplePeriod;
    sample_dict: Record<string, OptionalSamplePeriod>;
    sample_hours_dict: Record<number, OptionalSamplePeriod>;
    defaultStartHour: number = 6;

    // Only refresh click
    readonly dtpReset: Subject<IDateTimePeriod>;
    defaultRange: any;
    defaultCalendar: string = 'default';
    defaultShiftLength: number;
    selected_timezone: string;
    /**
     * Was the dtp read from a URL.
     */
    read_dtp_from_url: boolean;
    private search_dtp: any;
    private account_id: any;

    public show_calendar: boolean = true;
    public show_timespan: boolean = true;
    public show_time: boolean = true;
    public changed_not_reset = false;

    private _shiftSampleDict: IDMap<SamplePeriod> = {};
    private _currentShiftVersionDict: IDMap<ShiftVersion> = {};
    private _shiftVersions: ShiftVersion[];
    private _shiftRangeDict: KeyMap<IDateTimePeriod> = {};
    shiftNameIdDict: KeyMap<number> = {};

    constructor(private appScope: AppScope,
                private activatedRoute: ActivatedRoute,
                private location: Location,
                private notification: NotificationService,
                private api: ApiService,
                private timezoneSelectorService: TimezoneSelectorService,
    ) {
        this.dtpInitialisedPromise = new utils.FlatPromise();

        // Check url for why this timeout is set
        setTimeout(() => {
            combineLatest(
                this.timezoneSelectorService.activeTimezoneChanged,
                this.appScope.configDictChanged
            ).pipe(
                map(([timezoneChange, configChange]) => ({timezoneChange, configChange})),
                distinctUntilChanged((prev: { timezoneChange, configChange }, curr: {
                    timezoneChange,
                    configChange
                }) => {
                    return this.checkStateChanges(prev, curr);
                }),
                takeUntil(this.onDestroy),
                concatMap(() => {
                    this.account_id = this.appScope.active_account_id;
                    this.selected_timezone = this.timezoneSelectorService.active_timezone;
                    this.reset();
                    this.search_dtp = this.activatedRoute.snapshot.queryParams['dtp'];
                    this.read_dtp_from_url = this.search_dtp !== undefined;
                    const options: SearchQueryOptions = new SearchQueryOptions();
                    options.filters = [getAccountFilter(this.account_id)];
                    return forkJoin([this.api.period_type.searchMany(options), this.api.custom_time_period.searchMany(options),
                        this.api.shift_type.searchMany(options), this.api.shift_version.searchMany()])
                        .pipe(take(1), tap(([periodTypes, timePeriods, shiftTypes, shiftVersions]) => {
                                this.setCustomShifts(shiftTypes?.data, shiftVersions?.data);
                                this.setCustomCalendars(periodTypes, timePeriods);
                                this.dtp = this.getDTP(null, true);
                                console.log("Got dtp and custom calendars", this.calendar_dict);
                                if (!this.dtp) {
                                    console.error('Failed to get DTP!');
                                }
                                this.dtpInitialised$.next(this.dtp);
                                this.dtpInitialisedPromise.resolve(this.dtp);
                            }),
                        )
                })).subscribe();
        });
    }

    checkStateChanges(prev: { timezoneChange, configChange }, curr: { timezoneChange, configChange }): boolean {
        let changed = false;
        if (prev.timezoneChange.timezone !== curr.timezoneChange.timezone) {
            changed = true;
        }
        if (this.account_id !== this.appScope.active_account_id) {
            changed = true;
        }
        return !changed;
    }

    getDTP(range?: string, init?: boolean, calendar?: string): IDateTimePeriod {
        const DTP = this;
        // TODO this may have to be updated when making dtp immutable???
        // Only headerData dtp component should use url

        if (this.search_dtp && init === true) {
            let dtp = JSON.parse(this.search_dtp);

            if (!dtp.calendar || !DTP.calendar_dict[dtp.calendar]) {
                dtp.calendar = DTP.defaultCalendar;
            }
            let url_dtp = utils.deepCopy(DTP.calendar_dict[dtp.calendar].ranges_dict[DTP.defaultRange]);

            if (dtp.range) { // set defaults from range
                if (dtp.range !== 'custom') {
                    url_dtp = utils.deepCopy(DTP.calendar_dict[dtp.calendar].ranges_dict[dtp.range]) || url_dtp;
                }
            }
            if (dtp.start && dtp.end) { // set custom start and end if there **Overrides range
                url_dtp['start'] = new Date(dtp.start);
                url_dtp['end'] = new Date(dtp.end);
                if (dtp.range === 'custom' ||
                    url_dtp.start.getTime() !== DTP.calendar_dict[dtp.calendar].ranges_dict[dtp.range]?.start.getTime()) {
                    url_dtp.range = 'custom';
                }
            }

            if (dtp.sample_period) {
                url_dtp.sample_period = utils.deepCopy(DTP.sample_dict[dtp.sample_period.name]);
            }
            url_dtp = DTP.validateDTP(url_dtp);
            return url_dtp;
        }

        if (!calendar) {
            calendar = DTP.defaultCalendar;
        }
        if (!DTP.calendar_dict[calendar]) {
            calendar = DTP.defaultCalendar;
        }
        if (!range) {
            range = this.defaultRange;
        }

        /// TODO immutable
        const tmp_dtp = utils.deepCopy(DTP.calendar_dict[calendar].ranges_dict[range]);

        if (!tmp_dtp) {
            console.error('Failed to get DTP for range', range, 'range dict', DTP.range_dict);
        }

        return DTP.validateDTP(tmp_dtp);

    }

    getRelativeDTP(config: IRelativeDtpConfig) {
        const DTP = this;
        const rel_dtp: RelativeDtp = new RelativeDtp(config, DTP.dtp, this.sample_dict[config.sample_period]);
        return DTP.validateDTP(rel_dtp);
    }

    validateDTP(dateTimePeriod: IDateTimePeriod): IDateTimePeriod {
        const getClientMineUTCOffset = this.getClientMineUTCOffset(new Date()) * -1;
        const mineToday = new Date()['addHours'](getClientMineUTCOffset);
        const dtpStart = new Date(deepCopy(dateTimePeriod.start))['addHours'](getClientMineUTCOffset);

        if ((dateTimePeriod.range === 'this month' || dateTimePeriod.range === 'this full month') &&
            mineToday.getDate() === dtpStart.getDate()) {
            const diffInHours = (dateTimePeriod.end.valueOf() - dateTimePeriod.start.valueOf()) / 1000 / 60 / 60;
            if (diffInHours < 24 && diffInHours >= 0) {
                dateTimePeriod = this.getDTP('last month', null, dateTimePeriod.calendar);
                console.log("Warning, no full days for this month");
                this.notification.openError('Please Note: no full days have been completed for this month yet. Range set to "Last month"');
            }
        }

        const this_week_date_diff = (new Date().valueOf() - dateTimePeriod.start.valueOf()) / (3600 * 1000);
        if (dateTimePeriod.range === 'this week' && (this_week_date_diff / dateTimePeriod.sample_period.hours) < 1) {
            this.notification.openError('There is currently no data for "This week" at your selected sample period. Setting to previous week. Please see console for details.');
            console.log("You have selected a range for which a single sample period has not yet passed, or, your time zone " +
                "is ahead of mine time and so there is currently no data available for your selected " +
                "date range based on UCT dates. This warning could also be coming from one of your tiles using a custom date range set" +
                " to 'This week'.");
            dateTimePeriod = this.getDTP('last week', null, dateTimePeriod.calendar);

        }

        if (dateTimePeriod.range === 'today' && new Date().valueOf() < this.range_dict['today'].start.valueOf()) {
            // This happens when someone in a timezone ahead of mine time tries to view today before mine today has started
            dateTimePeriod = this.getDTP('since yesterday', null, dateTimePeriod.calendar);
            this.notification.openError('There is currently no data for "Today". Range set to "Since Yesterday". Please see console for details.');
            console.log("Your time zone is ahead of mine time and so there is currently no data available for your selected" +
                "date range based on UCT dates. One of your tiles could also be using a custom date range set to 'Today'.");
        }

        if (new Date(dateTimePeriod.start).valueOf() >= new Date(dateTimePeriod.end).valueOf() && dateTimePeriod.range === 'custom') {
            console.log('End date is less than or equal to start date');
            if (dateTimePeriod.sample_period && dateTimePeriod.sample_period.hours) {
                dateTimePeriod.end = utils.deepCopy(dateTimePeriod.start).addHours(dateTimePeriod.sample_period.hours);
            } else {
                dateTimePeriod.end = utils.deepCopy(dateTimePeriod.start).addHours(1);
            }
            // // FIXME Prevent update to url when custom date is fixed (within a component)
            this.location.replaceState(location.pathname + '?dtp=' + JSON.stringify(dateTimePeriod));
        }

        return dateTimePeriod;
    }

    setDefault(dtp) {
        const ctrl = this;
        if (this.read_dtp_from_url !== true) {
            if (this.appScope.config_name_map['date_period'] && this.appScope.config_name_map['date_period'].value.default_range) {
                dtp = this.getDTP(this.appScope.config_name_map['date_period'].value.default_range);
            } else {
                dtp = this.getDTP('since yesterday');
            }
        }
        return dtp;
    }

    /** Generate default time-ranges for the time-traveller and Custom Date Period on tiles, using `base` as the end
     * of the last full production day
     * Note: a "production day" is assumed to start on the default_start_hour on the date_period config of the
     * given account
     *
     * Detailed description of the time ranges in [Design Doc]{@link https://app.clickup.com/7272257/v/dc/6xxu1-4864/6xxu1-9162}
     */

    getRanges(startHour = this.defaultStartHour): IDateTimePeriod[] {
        const ranges = [
            'today', 'this full day', 'this shift', 'yesterday', 'yesterday by half shift', 'since yesterday', 'the past two days', 'two days ago',
            'the past seven days', 'since the past seven days',
            'week', 'this week', 'this full week', 'next week', 'last week',
            'month', 'this month', 'this full month', 'next month', 'last month', 'the past thirty days', 'the next thirty days',
            'quarter', 'this quarter', 'this full quarter', 'next quarter', 'last quarter',
            'year', 'this year', 'this full year', 'next year', 'last year'
        ];
        return ranges.map(r => this.calculateDateRange(r, startHour));
    }

    private calculateDateRange(range: string, startHour: number): DateTimePeriod {
        const timezone = this.selected_timezone;
        const currentTimeInTimezone = moment.tz(moment(), timezone).startOf('hour');
        const startOfWeek = parseInt(this.appScope.config_name_map.week_start.value, 10);
        let startDate: moment.Moment, endDate: moment.Moment;
        let samplePeriod: OptionalSamplePeriod;
        let parent: string;
        let isParent: boolean;

        switch (range) {
            case 'today':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                endDate = currentTimeInTimezone;
                samplePeriod = this.sample_dict['hour'];
                break;

            case 'this full day':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(1, 'day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['hour'];
                break;

            case 'yesterday':
                startDate = moment.tz(currentTimeInTimezone, timezone).subtract(1, 'day').startOf('day').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['hour'];
                break;

            case 'yesterday by half shift':
                startDate = moment.tz(currentTimeInTimezone, timezone).subtract(1, 'day').startOf('day').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict[this._getHalfShiftPeriod(this.defaultShiftLength / 2)];
                break;

            case 'this shift':
                const dates = this._getCurrentShift(startHour, this.defaultShiftLength);
                startDate = dates.startDate;
                endDate = dates.endDate;
                samplePeriod = this.sample_dict['hour'];
                break;

            case 'since yesterday':
            case 'since the past seven days':
                const daysAgoSince = range === 'since yesterday' ? 1 : 7;
                startDate = moment.tz(currentTimeInTimezone, timezone).subtract(daysAgoSince, 'day').startOf('day').add(startHour, 'hours');
                endDate = currentTimeInTimezone;
                samplePeriod = range === 'since yesterday' ? this.sample_dict['hour'] : this.sample_dict['day'];
                break;

            case 'two days ago':
                startDate = moment.tz(currentTimeInTimezone, timezone).subtract(2, 'day').startOf('day').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).subtract(1, 'day').startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['hour'];
                break;

            case 'the past two days':
            case 'the past seven days':
            case 'the past thirty days':
                const daysAgo = range === 'the past two days' ? 2 : range === 'the past seven days' ? 7 : 30;
                startDate = moment.tz(currentTimeInTimezone, timezone).subtract(daysAgo, 'day').startOf('day').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = range === 'the past two days' ? this.sample_dict['hour'] : range === 'the past seven days' ?
                    this.sample_dict['shift'] : this.sample_dict['day'];
                break;

            case 'the next thirty days':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).add(30, 'day').startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                break;

            case 'week':
                if (!startOfWeek) {
                    throw new Error('Start of week is not defined');
                }
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('week').day(startOfWeek).add(startHour, 'hours');
                if (startDate.isAfter(currentTimeInTimezone)) {
                    startDate.subtract(1, 'week');
                }
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                isParent = true;
                break;
            case 'this week':
                if (!startOfWeek) {
                    throw new Error('Start of week is not defined');
                }
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('week').day(startOfWeek).add(startHour, 'hours');
                if (startDate.isAfter(currentTimeInTimezone)) {
                    startDate.subtract(1, 'week');
                }
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'week';
                break;

            case 'this full week':
                if (!startOfWeek) {
                    throw new Error('Start of week is not defined');
                }
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('week').day(startOfWeek).add(startHour, 'hours');
                if (startDate.isAfter(currentTimeInTimezone)) {
                    startDate.subtract(1, 'week');
                }
                endDate = startDate.clone().add(1, 'week');
                samplePeriod = this.sample_dict['day'];
                parent = 'week';
                break;
            case 'next week':
                if (!startOfWeek) {
                    throw new Error('Start of week is not defined');
                }
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('week').day(startOfWeek).add(1, 'week').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('week').day(startOfWeek).add(2, 'week').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'week';
                break;
            case 'last week':
                if (!startOfWeek) {
                    throw new Error('Start of week is not defined');
                }
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('week').day(startOfWeek).add(startHour, 'hours');
                if (startDate.isAfter(currentTimeInTimezone)) {
                    startDate.subtract(1, 'week');
                }
                startDate.subtract(1, 'week');
                endDate = startDate.clone().add(1, 'week');
                samplePeriod = this.sample_dict['day'];
                parent = 'week';
                break;

            case 'month':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                isParent = true;
                break;
            case 'this month':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'month';
                break;

            case 'this full month':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(1, 'month').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'month';
                break;

            case 'next month':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(1, 'month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(2, 'month').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'month';
                break;
            case 'last month':
                startDate = moment.tz(currentTimeInTimezone, timezone).subtract(1, 'month').startOf('month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('month').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'month';
                break;

            case 'quarter':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                isParent = true;
                break;
            case 'this quarter':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['day'];
                parent = 'quarter';
                break;

            case 'this full quarter':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(3, 'month').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'quarter';
                break;

            case 'next quarter':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(3, 'month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(6, 'month').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'quarter';
                break;

            case 'last quarter':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(-3, 'month').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('quarter').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'quarter';
                break;

            case 'year':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                isParent = true;
                break;
            case 'this year':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'year';
                break;

            case 'this full year':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(1, 'year').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'year';
                break;

            case 'next year':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(1, 'year').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(2, 'year').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'year';
                break;
            case 'last year':
                startDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(-1, 'year').add(startHour, 'hours');
                endDate = moment.tz(currentTimeInTimezone, timezone).startOf('year').add(startHour, 'hours');
                samplePeriod = this.sample_dict['month'];
                parent = 'year';
                break;
            default:
                throw new Error('Invalid range');
        }

        return new DateTimePeriod(range, startDate.tz('UTC').toDate(), endDate.tz('UTC').toDate(), samplePeriod, 'default', parent, isParent);
    }

    private setCustomCalendars(periodTypes, timePeriods) {
        const calendar_results = periodTypes.data;
        this.time_periods = timePeriods.data;
        calendar_results.forEach(calendar => {
            const default_start_hour = calendar.attributes.default_start_hour ?? this.defaultStartHour;
            const cal_ranges: IDateTimePeriod[] = this.getRanges(default_start_hour);
            let cal_range_dict = {};
            cal_ranges.forEach(cal => cal_range_dict[cal.range] = cal);
            Object.keys(cal_range_dict).map(key => cal_range_dict[key].calendar = calendar.attributes.name);
            this.calendars.push(new Calendar(calendar.id, calendar.attributes.name,
                calendar.attributes.description, [], cal_range_dict, default_start_hour, calendar.attributes.account_name,
                calendar.relationships.account.data.id));
        });
        this._updateCalendarDict();
    }

    private setCustomShifts(shiftTypes: ShiftType[], shiftVersions: ShiftVersion[]): void {
        if (!shiftTypes.length || !shiftVersions.length) {
            return;
        }
        this._shiftVersions = shiftVersions;

        const timezone = this.selected_timezone;
        const currentTimeInTimezone = moment.tz(moment(), timezone).startOf('hour');

        const shiftVersionDict = {};
        shiftVersions.forEach(v => shiftVersionDict[v.id] = v);
        this._currentShiftVersionDict = this._getCurrentShiftVersionDict(shiftVersions);
        this._updateSamplePeriodDict();

        this._addShiftDefaults();

        shiftTypes.forEach(st => {
            const shiftLength = this._currentShiftVersionDict[st.id].attributes.shift_length;
            const startTime = this._currentShiftVersionDict[st.id].attributes.start_time;
            const startHour = new Date(`1970-01-01T${startTime}`).getUTCHours();

            this.shiftNameIdDict[st.attributes.name.toLowerCase()] = st.id;

            this._shiftSampleDict[st.id] = new SamplePeriod(st.attributes.name.toLowerCase(), shiftLength, shiftLength * 3600 + 's', this.sample_dict['shift'].format, null, 'shift');

            this._shiftRangeDict['half ' + st.attributes.name] = new DateTimePeriod('half ' + st.attributes.name.toLowerCase(),
                moment.tz(currentTimeInTimezone, timezone).subtract(1, 'day').startOf('day').add(startHour, 'hours').tz('UTC').toDate(),
                moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours').tz('UTC').toDate(),
                this.sample_dict[this._getHalfShiftPeriod(shiftLength / 2)], 'default', 'yesterday by half shift', false);

            let dates = this._getCurrentShift(startHour, shiftLength);
            this._shiftRangeDict['this ' + st.attributes.name] = new DateTimePeriod('this ' + st.attributes.name.toLowerCase(),
                dates.startDate.tz('UTC').toDate(),
                dates.endDate.tz('UTC').toDate(),
                this.sample_dict['hour'], 'default', 'this shift', false);
        })
        this.sample_periods = this.sample_periods.concat(Object.values(this._shiftSampleDict));
        this.ranges = this.ranges.concat(Object.values(this._shiftRangeDict));
        this._updateSamplePeriodDict();
        this._updateRangeDict();

    }

    private _addShiftDefaults(): void {
        const timezone = this.selected_timezone;
        const currentTimeInTimezone = moment.tz(moment(), timezone).startOf('hour');
        const hourFormat = this.sample_dict['shift'].format;
        const shiftSampleIndex = this.sample_periods.findIndex(sp => sp.name === 'shift');
        const halfShiftRangeIndex = this.ranges.findIndex(sp => sp.range === 'yesterday by half shift');
        const thisShiftRangeIndex = this.ranges.findIndex(sp => sp.range === 'this shift');

        const shiftHeader = Object.assign(this.sample_dict['shift'], {is_parent: true}); //new SamplePeriod('shift', this.defaultShiftLength, this.defaultShiftLength * 3600 + 's', hourFormat, null, null, true);
        this.sample_periods.splice(shiftSampleIndex, 1, shiftHeader);

        let halfShiftHeader = Object.assign(this.range_dict['yesterday by half shift'], {is_parent: true});
        this.ranges.splice(halfShiftRangeIndex, 1, halfShiftHeader);

        let thisShiftHeader = Object.assign(this.range_dict['this shift'], {is_parent: true});
        this.ranges.splice(thisShiftRangeIndex, 1, thisShiftHeader);

        this._shiftSampleDict['default shift'] = new SamplePeriod('default shift', this.defaultShiftLength, this.defaultShiftLength * 3600 + 's', hourFormat, null, 'shift');

        this._shiftRangeDict['half default shift'] = new DateTimePeriod('half default shift',
            moment.tz(currentTimeInTimezone, timezone).subtract(1, 'day').startOf('day').add(this.defaultStartHour, 'hours').tz('UTC').toDate(),
            moment.tz(currentTimeInTimezone, timezone).startOf('day').add(this.defaultStartHour, 'hours').tz('UTC').toDate(),
            this.sample_dict[this._getHalfShiftPeriod(this.defaultShiftLength / 2)], 'default', 'yesterday by half shift', false);

        let dates = this._getCurrentShift(this.defaultStartHour, this.defaultShiftLength);
        this._shiftRangeDict['this default shift'] = new DateTimePeriod('this default shift',
            dates.startDate.tz('UTC').toDate(),
            dates.endDate.tz('UTC').toDate(),
            this.sample_dict['hour'], 'default', 'this shift', false);
    }

    private _getCurrentShift(startHour, shiftLength): { startDate: any, endDate: any } {
        const timezone = this.selected_timezone;
        const currentTimeInTimezone = moment.tz(moment(), timezone).startOf('hour');

        let startDate = moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour + 24, 'hours');
        let endDate = moment.tz(startDate, timezone).add(shiftLength, 'hours');

        let counter = 0, maxIterations = 1000;  // Maximum iteration threshold
        while (!(startDate.isSameOrBefore(currentTimeInTimezone) && endDate.isSameOrAfter(currentTimeInTimezone))) {
            // Move to the next shift period
            startDate.add(-shiftLength, 'hours');
            endDate.add(-shiftLength, 'hours');

            if (endDate <= currentTimeInTimezone && startDate <= currentTimeInTimezone || counter >= maxIterations) {
                break;
            }

            counter++;
        }
        return {startDate: startDate, endDate: endDate};

    }

    private _getCurrentShiftVersionDict(shiftVersions: ShiftVersion[], timeToCompareTo?): IDMap<ShiftVersion> {
        const timezone = this.selected_timezone;
        if (!timeToCompareTo) {
            timeToCompareTo = moment.tz(moment(), timezone).startOf('hour');
        }

        let shiftVersionDict = {};
        let currentShiftVersionDict: IDMap<ShiftVersion> = {};
        shiftVersions.forEach(v => {
            const dt = moment.tz(v.attributes?.implementation_date, timezone).startOf('hour');
            if (dt.isSameOrBefore(timeToCompareTo) && (!shiftVersionDict[v.relationships?.shift_type?.data?.id] || dt.isSameOrAfter(shiftVersionDict[v.relationships?.shift_type?.data?.id]))) {
                shiftVersionDict[v.relationships?.shift_type?.data?.id] = dt;
                currentShiftVersionDict[v.relationships?.shift_type?.data?.id] = v;
            }
        });
        return currentShiftVersionDict;
    }

    private _getHalfShiftPeriod(length): string {
        switch (length) {
            case 1:
                return 'hour';
                break;
            case 2:
                return 'two_hour';
                break;
            case 3:
                return 'three_hour';
                break;
            case 4:
                return 'four_hour';
                break;
            case 6:
                return 'six_hour';
                break;
            case 8:
                return 'eight_hour';
                break;
            case 12:
                return 'twelve_hourhour';
                break;
            default:
                return 'hour';
        }
    }

    getRelativeDatePeriod(dtp, range) {
        const DTP = this;

        const base = {
            start: new Date(dtp.end), // Child dates will be relative to end date of parent
            end: new Date(dtp.end)
        };

        this.relative_ranges = this.getRanges();
        this.relative_range_dict = {};
        this.relative_ranges.map(item => {
            this.relative_range_dict[item.range] = item;
        });
        return this.relative_range_dict[range];
    }

    determineStartFromEndAndPeriod(dtp, sample_period: OptionalSamplePeriod, number_of_periods: number) {
        const ctrl = this;
        const timezone = this.timezoneSelectorService.active_timezone
        let start = moment.tz(deepCopy(dtp.end), timezone);
        let duration = moment.duration(number_of_periods * sample_period.hours, 'hours');
        const defaultStartHour = this.calendar_dict?.[dtp.calendar]?.default_start_hour || this.defaultStartHour;
        start = start.subtract(duration);
        if (sample_period.name === 'month') {
            start = moment.tz(deepCopy(dtp.end), timezone).subtract(number_of_periods, 'months');
            start = start.startOf("month");
            start = start.set('hour', defaultStartHour);
        }

        return start;

    }

    private getCustomRanges(calendar: OptionalCalendar) {
        const calendarPeriods = this.time_periods.filter(period => {
            return period.relationships.period_type.data.id === calendar.id;
        })

        calendar.calendar_periods = calendarPeriods;
        this._setCustomRange(calendar, 'week', 'day');
        this._setCustomRange(calendar, 'month', 'day');
        this._setCustomRange(calendar, 'quarter', 'month');
        calendar.ranges_dict['this quarter'].sample_period = this.sample_dict['day'];
        this._setCustomRange(calendar, 'year', 'month');

        calendar.ranges = Object.values(calendar.ranges_dict);
    }

    private _setCustomRange(calendar: OptionalCalendar, rangeName: CustomTimePeriodRange, samplePeriodName: string) {
        const timezone = this.selected_timezone;
        const currentTimeInTimezone = moment.tz(moment(), timezone).startOf('hour');
        let start, end, previous_start, following_end;
        const periods = calendar.calendar_periods.filter(period => period.attributes.range === rangeName);

        periods.forEach(range => {
            if (moment.tz(range.attributes.start, timezone).isSameOrBefore(currentTimeInTimezone) &&
                moment.tz(range.attributes.end, timezone).isAfter(currentTimeInTimezone)) {
                end = moment.tz(range.attributes.end, timezone);
                start = moment.tz(range.attributes.start, timezone);
                previous_start = calendar.calendar_periods.find(period =>
                    range.relationships.previous_time_period.data.id === period.id)?.attributes?.start;
                following_end = calendar.calendar_periods.find(period => {
                    return period.relationships.previous_time_period?.data?.id === range.id;
                })?.attributes?.end;
            }
        });

        const startHour = calendar.default_start_hour ?? this.defaultStartHour;
        const rangeWithStartHour = (rangeName) => {
            return moment.tz(this.range_dict[rangeName].start, timezone).startOf('day').add(startHour, 'hours').toDate();
        }
        start = start ? start.tz('UTC').toDate() : rangeWithStartHour("this full " + rangeName);
        end = end ? end.tz('UTC').toDate() : moment.tz(this.range_dict["this full " + rangeName].end, timezone).startOf('day').add(startHour, 'hours').toDate();
        previous_start = previous_start ? moment.tz(previous_start, timezone).toDate() : rangeWithStartHour("last " + rangeName);
        following_end = following_end ? moment.tz(following_end, timezone).toDate() : rangeWithStartHour("next " + rangeName);

        let rangeKey = "this " + rangeName;
        calendar.ranges_dict[rangeKey] = new DateTimePeriod(
            rangeKey,
            start,
            moment.tz(currentTimeInTimezone, timezone).startOf('day').add(startHour, 'hours').tz('UTC').toDate(),
            this.sample_dict[samplePeriodName],
            calendar.name,
            this.range_dict[rangeKey].parent,
            this.range_dict[rangeKey].is_parent);

        rangeKey = "this full " + rangeName;
        calendar.ranges_dict[rangeKey] = new DateTimePeriod(
            rangeKey,
            start,
            end,
            this.sample_dict[samplePeriodName],
            calendar.name,
            this.range_dict[rangeKey].parent,
            this.range_dict[rangeKey].is_parent);

        rangeKey = "last " + rangeName;
        calendar.ranges_dict[rangeKey] = new DateTimePeriod(
            rangeKey,
            previous_start,
            start,
            this.sample_dict[samplePeriodName],
            calendar.name,
            this.range_dict[rangeKey].parent,
            this.range_dict[rangeKey].is_parent);

        rangeKey = "next " + rangeName;
        calendar.ranges_dict[rangeKey] = new DateTimePeriod(
            rangeKey,
            end,
            following_end,
            this.sample_dict[samplePeriodName],
            calendar.name,
            this.range_dict[rangeKey].parent,
            this.range_dict[rangeKey].is_parent);
    }

    setPeriod(dtp, period) {
        dtp.sample_period = utils.deepCopy(this.sample_dict[period]);

    }

    setToStart(dtp) {
        dtp.start = this.setToDefaultStartHour(this.defaultStartHour, dtp.start);
        dtp.end = this.setToDefaultStartHour(this.defaultStartHour, dtp.end);
        return dtp;
    }

    private setToMonthStart(dt) {
        // Check if time, in the client's timezone, is on a month-end (i.e. end of a full period)
        // const month_start = this.calendar_dict[this.dtp.calendar]?.month_start_day() || 1;
        const month_start = this.dtp?.calendar ? this.calendar_dict[this.dtp?.calendar]?.month_start_day() : 1;
        let start_hour = (this.calendar_dict[this.dtp?.calendar]?.default_start_hour || this.defaultStartHour);
        let start = utils.deepCopy(dt);
        // Convert the date-and-time to be the same as the date-and-time on the mine
        const tz_offset = this.getClientMineUTCOffset(dt);
        start.addHours(-tz_offset);
        if (start.getDate() === month_start && start.getHours() < start_hour) {
            // If 'dt' is on the month_start day but before the default_start_hour then it must be reset to the
            // previous month
            start.setDate(-1);
        }
        start.setDate(month_start);
        start.setHours(0, 0, 0);
        // Set the hour to the default_start_hour
        start.addHours(start_hour);
        // Finally, convert the date-and-time back to the client's timezone
        start.addHours(tz_offset);
        return start;
    }

    changePeriod(dtp: IDateTimePeriod) {
        if (dtp.sample_period.name === 'day') {
            // first check if the time is already on the default start hour
            if (!((dtp.start.getHours() - this.getClientUTCOffset(dtp.start)) % 24 !== this.defaultStartHour % 24) ||
                !((dtp.end.getHours() - this.getClientUTCOffset(dtp.end)) % 24 !== this.defaultStartHour % 24)) {
                dtp = this.setToStart(dtp);
            }
            if ((Math.round(dtp.end.valueOf() - dtp.start.valueOf())) / (1000 * 60 * 60 * 24) < 1) {
                this.notification.openError("No full day available. Setting start to previous week.", 5000);
                dtp.start = utils.deepCopy(dtp.end);
                dtp.start.setDate(dtp.start.getDate() - (1));
            }
        }

        if (dtp.sample_period.name === 'week' && dtp.calendar === DEFAULT_CALENDAR_NAME && !['this full week', 'last week'].includes(dtp.range)) {
            const week_start_value: number = parseInt(this.appScope.config_name_map.week_start.value);
            // Calculate the closest start of a week to the dtp start time
            let nearest_week_start = utils.deepCopy(dtp.start);
            // Correct date-and-time for the client machine's timezone
            nearest_week_start.addHours(-this.getClientMineUTCOffset(nearest_week_start));
            const weekday_in_mine_tz = nearest_week_start.getDay();
            const actual_week_start_day = week_start_value + Math.floor(this.defaultStartHour / 24);
            if (weekday_in_mine_tz <= actual_week_start_day) {
                nearest_week_start.addHours(-(weekday_in_mine_tz + 7 - week_start_value) * 24);
            } else {
                nearest_week_start.addHours(-(weekday_in_mine_tz - week_start_value) * 24);
            }
            // Reset timezone to client's timezone
            nearest_week_start.addHours(this.getClientMineUTCOffset(nearest_week_start));
            this.setToDefaultStartHour(this.defaultStartHour, nearest_week_start);
            if (((dtp.end.valueOf() - nearest_week_start.valueOf()) / (1000 * 3600 * 24)) < 7) {
                this.notification.openError("No full week available. Setting start to previous week.", 5000);
                dtp.end = nearest_week_start;
                dtp.start = utils.deepCopy(nearest_week_start).addHours(-7 * 24);
            } else {
                dtp.start = nearest_week_start;
                if (['this full month', 'last month', 'this year'].includes(dtp.range)) {
                    dtp.end = moment.tz(dtp.end, this.selected_timezone).startOf('week').day(week_start_value);
                    if (dtp.end.valueOf() < this.range_dict[dtp.range].end.valueOf()) {
                        dtp.end = moment.tz(dtp.end, this.selected_timezone).add(7, 'days').toDate();
                    }
                }
            }
        }

        if (dtp.sample_period.name === 'week' && dtp.calendar !== DEFAULT_CALENDAR_NAME && !['this full week', 'last week'].includes(dtp.range)) {
            const weekLimits = this._getWeekLimitsForCalendar(dtp);
            if (weekLimits.start) {
                dtp.start = weekLimits.start;
            }
            if (weekLimits.end) {
                dtp.end = weekLimits.end;
            }
        }
        const start_hour = this.getDefaultStartHour(dtp);

        if (dtp.sample_period.name === 'month' && dtp.calendar === DEFAULT_CALENDAR_NAME && !['this full month', 'last month', 'this quarter'].includes(dtp.range)) {
            // First check if the end time is on the start of a month
            const month_start = dtp?.calendar ? this.calendar_dict[dtp?.calendar]?.month_start_day() : 1;
            const tz_corrected_end_time = utils.deepCopy(dtp.end).addHours(-1 * this.getClientMineUTCOffset(dtp.end));
            if (!(tz_corrected_end_time.getDate() === month_start && tz_corrected_end_time.getHours() === start_hour)) {
                dtp.end = this.setToMonthStart(dtp.end);
            }
            const preceding_month_end = this.subtractMonth(utils.deepCopy(dtp.end));
            // if the dtp.start is after the preceding month-end, then the original time-period was shorter than a month
            if (dtp.start > preceding_month_end) {
                this.notification.openError("No full month available. Setting start to previous month.", 5000);
                dtp.start = preceding_month_end;
            } else {
                dtp.start = this.setToMonthStart(dtp.start);
            }
        }

        const currentTimeInTimezone = moment.tz(moment(), this.selected_timezone).startOf('hour');
        if (dtp.sample_period.name === 'month' && dtp.range === 'this quarter') {
            const endDay = this.range_dict["this full quarter"].end;
            if (moment.tz(dtp.end, this.selected_timezone).isBefore(endDay)) {
                this.notification.openError("No full quarter available. Please check your selected date range.", 5000);
            }
        }

        if (dtp.sample_period.parent === "shift") {
            const startHour = this.getStartHourForShiftType(dtp);
            dtp.start = moment.tz(dtp.start, this.selected_timezone).startOf('day').add(startHour, 'hours').tz('UTC').toDate();
            dtp.end = moment.tz(dtp.end, this.selected_timezone).startOf('day').add(startHour, 'hours').tz('UTC').toDate();
        }
        return dtp;
    }

    getStartHourForShiftType(dtp: IDateTimePeriod): number {
        const shiftTypeId = this.shiftNameIdDict?.[dtp.sample_period.name]
        const shiftVersion = this._getCurrentShiftVersionDict(this._shiftVersions, dtp.start)[shiftTypeId];
        return shiftVersion ? new Date(`1970-01-01T${shiftVersion.attributes.start_time}`).getUTCHours() : this.defaultStartHour;
    }

    private _getWeekLimitsForCalendar(dtp: IDateTimePeriod): { start: Date, end: Date } {
        const startTimeInTimezone = moment.tz(moment(dtp.start), this.selected_timezone).startOf('hour');
        const endTimeInTimezone = moment.tz(moment(dtp.end), this.selected_timezone).startOf('hour');
        const currentCalendar = this.calendars.find(calendar => calendar.name === dtp.calendar);
        const calendarWeeks = currentCalendar.calendar_periods.filter(period => period.attributes.range === 'week');

        const nearestWeekStart = calendarWeeks.find(week => {
            return moment.tz(week.attributes.start, this.selected_timezone).isSameOrBefore(startTimeInTimezone) &&
                moment.tz(week.attributes.end, this.selected_timezone).isAfter(startTimeInTimezone)
        });

        const nearestWeekEnd = calendarWeeks.find(week => {
            return moment.tz(week.attributes.start, this.selected_timezone).isBefore(endTimeInTimezone) &&
                moment.tz(week.attributes.end, this.selected_timezone).isSameOrAfter(endTimeInTimezone)
        });

        return {start: nearestWeekStart?.attributes.start, end: nearestWeekEnd?.attributes.end};
    }

    deconstructDtp(object: any, dtp: IDateTimePeriod) {
        object.range = dtp.range;
        object.calendar = dtp.calendar;
        object.sample_period = dtp.sample_period.name;
        object.start = dtp.start.toISOString();
        object.end = dtp.end.toISOString();
        return object;
    }

    constructDtp(object: any) {
        let newDateTimePeriod: IDateTimePeriod;
        if (object.range && object.calendar && object.range !== 'custom') {
            newDateTimePeriod = utils.deepCopy(this.calendar_dict[object.calendar].ranges_dict[object.range]);
        } else {
            const calendar: string = object.calendar;
            newDateTimePeriod = this.getDTP(undefined, undefined, calendar);
        }
        if (object.sample_period) {
            newDateTimePeriod.sample_period = utils.deepCopy(this.sample_dict[object.sample_period]);
            newDateTimePeriod = this.changePeriod(newDateTimePeriod);
        }
        if (object.start && object.end && object.range && object.range === 'custom') {
            newDateTimePeriod.start = new Date(object.start);
            newDateTimePeriod.end = new Date(object.end);
            newDateTimePeriod.range = 'custom';
        }
        return this.validateDTP(newDateTimePeriod);
    }

    getClientUTCOffset(dt) {
        return -1 * moment_.tz.zone(moment_.tz.guess(true)).utcOffset(dt) / 60;
    }

    getMineUTCOffset(dt) {
        let mine_timezone = this.appScope.config_name_map['timezone'].value;
        return -1 * moment_.tz.zone(mine_timezone).utcOffset(dt) / 60;
    }

    getClientMineUTCOffset(dt) {
        return this.getClientUTCOffset(dt) - this.getMineUTCOffset(dt);
    }

    /**
     * Converts the given time's start hour to the default_start_hour, where the time is in the client machine's timezone
     */
    setToDefaultStartHour(start_hour: number, dt) {
        let client_utc_offset = this.getClientUTCOffset(dt);
        let mine_utc_offset = this.getMineUTCOffset(dt);
        let tz_correction = client_utc_offset - mine_utc_offset;

        let date_corrected = utils.deepCopy(dt).addHours(-tz_correction).setHours(0, 0, 0);
        let date_uncorrected = utils.deepCopy(dt).setHours(0, 0, 0);
        dt.setHours(start_hour);
        dt.addHours(tz_correction);
        if (date_corrected > date_uncorrected) {
            // case where the dt in the mine_tz is on the day after the client_tz
            dt.addHours(24);
            return dt;
        } else if (date_corrected < date_uncorrected) {
            // case where the dt in the mine_tz is on the day before the client_tz
            dt.addHours(-24);
            return dt;
        } else {
            return dt;
        }
    }

    getDefaultStartHour(dtp: IDateTimePeriod): number {
        const startHour = this.calendar_dict[dtp.calendar]?.default_start_hour;
        return startHour ?? this.defaultStartHour;
    }

    private reset() {
        let DTP = this;

        milliseconds_cache = {};
        seconds_cache = {};
        minutes_cache = {};
        hours_cache = {};
        days_cache = {};
        months_cache = {};

        const base = {
            start: new Date(),
            end: new Date()
        };
        const datePeriodConfig = DTP.appScope.config_name_map.date_period.value;

        DTP.defaultRange = datePeriodConfig.default_range;
        DTP.defaultStartHour = datePeriodConfig.default_start_hour;
        DTP.defaultShiftLength = Math.abs(datePeriodConfig.default_shift_length || 8);
        if (isNaN(DTP.defaultShiftLength)) {
            DTP.defaultShiftLength = 8;
        }

        // TODO move out this formatting functions and use the 'dtp' arg and not the global 'DTP'.
        let hour_format = function (d, dtp, dates) {
            let cached_value = hours_cache[d];
            if (!cached_value) {
                const temp_date = moment_timezone(d);
                const month = temp_date.format('MMM');
                const day = temp_date.format('DD');
                const hour = temp_date.format('HH');
                // if (hour == '24') hour = '00';

                // TODO reimplement what this was (need for generic charts at least)
                if ((Math.round(dtp.end - dtp.start)) / (1000 * 60 * 60) >= 24 && dtp.start.getMonth() != dtp.end.getMonth()) {
                    cached_value = month + " " + day + "T" + hour;
                } else {
                    cached_value = day + "T" + hour;
                    hours_cache[d] = cached_value;
                }
            }
            return cached_value;
        };
        let millisecond_format = function (d, dtp, dates) {
            let cached_value = milliseconds_cache[d];
            if (!cached_value) {
                let temp_date = moment_timezone(d);
                let month = temp_date.format('MMM');
                const milliseconds = d.getMilliseconds();
                const seconds = d.getSeconds();
                const minutes = d.getMinutes();
                let day = temp_date.format('DD');

                let hour = temp_date.format('HH');
                cached_value = `${day}T${hour}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}.${String(milliseconds).padStart(2, "0")}`;
                milliseconds_cache[d] = cached_value;
            }
            return cached_value;
        };
        let second_format = function (d, dtp, dates) {
            let cached_value = seconds_cache[d];
            if (!cached_value) {
                let temp_date = moment_timezone(d);
                let month = temp_date.format('MMM');
                const seconds = d.getSeconds();
                const minutes = d.getMinutes();
                let day = temp_date.format('DD');

                let hour = temp_date.format('HH');
                cached_value = day + "T" + hour + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0");
                seconds_cache[d] = cached_value;
            }
            return cached_value;
        };
        let minute_format = function (d, dtp, dates) {
            let cached_value = minutes_cache[d];
            if (!cached_value) {
                let temp_date = moment_timezone(d);
                let month = temp_date.format('MMM');
                const minutes = d.getMinutes();
                let day = temp_date.format('DD');

                let hour = temp_date.format('HH');
                cached_value = day + "T" + hour + ":" + String(minutes).padStart(2, "0");
                minutes_cache[d] = cached_value;
            }
            return cached_value;
        };
        let day_format = function (d, dtp, dates, format_only = false) {
            let cached_value = days_cache[d];
            if (!cached_value) {
                let temp_date = moment_timezone(d);
                if (!format_only) {
                    temp_date.subtract(1, 'day');
                }
                cached_value = temp_date.format('DD MMM');
                days_cache[d] = cached_value;
            }
            return cached_value;
        };
        let month_format = function (d, dtp, dates, format_only = false) {
            let cached_value = months_cache[d];
            if (!cached_value) {
                let temp_date = moment_timezone(d);
                if (!format_only) {
                    temp_date.subtract(2, 'day');
                }
                cached_value = temp_date.format('MMM YYYY');
                months_cache[d] = cached_value;
            }
            return cached_value;
        };

        // The server looks for the word 'points' to determine if it should return unaggregated data
        DTP.sample_periods = [
            new SamplePeriod('points', 1 / 3600, '1200P', millisecond_format, 'minutes'),
            new SamplePeriod('second', 1 / 3600, '1200P', second_format, 'minutes'),
            new SamplePeriod('minute', 1 / 60, '60s', minute_format, 'minutes'),
            new SamplePeriod('5 minute', 5 / 60, '300s', minute_format, 'minutes'),
            new SamplePeriod('10 minute', 10 / 60, '600s', minute_format, 'minutes'),
            new SamplePeriod('15 minute', 15 / 60, '900s', minute_format, 'minutes'),
            new SamplePeriod('30 minute', 30 / 60, '1800s', minute_format, 'minutes'),
            new SamplePeriod('hour', 1, '3600s', hour_format),
            new SamplePeriod('two_hour', 2, '7200s', hour_format),
            new SamplePeriod('three_hour', 3, '10800s', hour_format),
            new SamplePeriod('four_hour', 4, '14400s', hour_format),
            new SamplePeriod('six_hour', 6, '21600s', hour_format),
            new SamplePeriod('eight_hour', 8, '28800s', hour_format),
            new SamplePeriod('twelve_hour', 12, '28800s', hour_format),
            new SamplePeriod('shift', DTP.defaultShiftLength, DTP.defaultShiftLength * 3600 + 's', hour_format),
            new SamplePeriod('day', 24, 'day', day_format),
            new SamplePeriod('week', 168, 'week', day_format),
            new SamplePeriod('month', 720, 'month', month_format)];

        this._updateSamplePeriodDict();

        DTP.defaultPeriod = DTP.sample_hours_dict[datePeriodConfig.default_period];

        DTP.ranges = this.getRanges();

        this._updateRangeDict();

        DTP.calendars = [];
        DTP.calendars.push(new Calendar(null, DTP.defaultCalendar, "Normal Gregorian calendar", DTP.ranges, DTP.range_dict, null));

    }

    private _updateSamplePeriodDict() {
        this.sample_dict = {};
        this.sample_hours_dict = {};
        this.sample_periods.map(item => {
            this.sample_dict[item.name] = item;
            if (!this.sample_hours_dict[item.hours]) {
                this.sample_hours_dict[item.hours] = item; // get sample period by hours number
            }
        });
    }

    private _updateRangeDict() {
        this.range_dict = {};
        this.ranges.map(item => {
            this.range_dict[item.range] = item;
        });
    }

    private _updateCalendarDict() {
        this.calendars.forEach(calendar => {
            calendar.ranges_dict = Object.assign({}, this.range_dict, calendar.ranges_dict);
            calendar.ranges = deepCopy(Object.values(calendar.ranges_dict));
        })
        this.calendar_dict = {};
        this.calendars.map(item => {
            if (item.id) {
                this.getCustomRanges(item);
            }
            this.calendar_dict[item.name] = item;
        });
    }

    getTimezoneUTCOffset(dt) {
        return moment.tz.zone(this.timezoneSelectorService.active_timezone).utcOffset(dt) / -60
    }

    getClientTimezoneOffset(dt) {
        return this.getClientUTCOffset(dt) - this.getTimezoneUTCOffset(dt);
    }

    setMomentDate(i: {
        date: number,
        month: number,
        year: number
    }, dtp: IDateTimePeriod, existingHour?: number, existingMinutes?: number) {
        const timezone = this.timezoneSelectorService.active_timezone;
        if (typeof i === 'string' || !i.year) {
            let momentI;
            if (typeof i === 'string') {
                momentI = moment.tz(i, 'UTC');
            } else {
                /**This only seems to happen when the user makes a typing change small enough that the browser doesn't
                 * interpret there as being a change due to the client browser - current timezone offset.
                 * The change event then registers a javascript data rather than a moment.tz date and converts the date
                 * section to the previous or following day (depending on the browser timezone and current time/hours of
                 * the input date being set)**/
                const tzOffset = this.getTimezoneUTCOffset(i);
                const client = this.getClientUTCOffset(i);
                const clientTimezonePolarity = client >=0 ? 1 : -1;
                const revertToSelectedDate = new Date(deepCopy(i))['addHours'](24 * clientTimezonePolarity)['addHours'](tzOffset);
                momentI = moment.tz(revertToSelectedDate, 'UTC')
            }
            i = {year: momentI.year(), month: momentI.month(), date: momentI.date()}
        }
        const date = i.date;
        const month = i.month;
        const year = i.year;
        const hour = existingHour || this.getDefaultStartHour(dtp);
        const minutes = existingMinutes || 0;
        return moment.tz({year, month: month, day: date, hour, minutes}, timezone);
    }

    setMomentTime(evt, dt: Date) {
        /**Get the string portion of the input date (dt) with the selected hours added on. This creates a string
         representation of our date in the user SELECTED timezone**/
        const tz_string_date = utils.stringDate(dt, {date_only: true}) + ' ' + evt.target.value;
        /**Javascript assumes that the string date is in LOCAL time when it creates the date so get the offset between
         * local and selected timezone and add those hours to the date**/
        const offset = this.getTimezoneOffsetDiff(dt) / 60;
        dt = new Date(tz_string_date)['addHours'](offset);
        return dt;
    }

    // setMomentTime(evt, current) {
    //     let time = moment(evt.target.value, 'HH:mm');
    //     let changed_date = new Date(current);
    //     changed_date.setHours(time.get('hour'), time.get('minute'));
    //     return changed_date;
    //
    // }

    getTimezoneOffsetDiff(dt) {
        const selectedOffset = moment_timezone(dt).utcOffset(); // offset of the user-selected timezone
        const localOffset = new Date(dt).getTimezoneOffset() * -1; // offset of the user's computer
        let diffInMinutes = selectedOffset - localOffset;
        return diffInMinutes * -1;
    }

    addMonth(dt): Date {
        let d = moment_timezone.tz(dt, this.selected_timezone).add(1, 'M');
        return d.toDate()
    }

    getNextMonth(dt, dtp: IDateTimePeriod): Date | null {
        const periods: ICustomTimePeriod[] = this.calendar_dict[dtp.calendar].calendar_periods;
        const current: ICustomTimePeriod = periods.find(p => {
            let currentMoment = moment_timezone.tz(dt, this.selected_timezone);
            let startMoment = moment_timezone.tz(p.attributes.start, this.selected_timezone);
            let endMoment = moment_timezone.tz(p.attributes.end, this.selected_timezone);
            return startMoment.isSameOrBefore(currentMoment) && endMoment.isAfter(currentMoment) && p.attributes.range === 'month';
        })
        if (!current) {
            return this.addMonth(deepCopy(dt));
        }
        const next: ICustomTimePeriod = periods.find(p => p.id === current.relationships.next_time_period.data?.id);

        if (next) {
            return moment_timezone.tz(next.attributes.start, this.selected_timezone).toDate();
        } else {
            let dNext = this.addMonth(deepCopy(dt));
            if (moment_timezone.tz(dNext, this.selected_timezone).isSameOrBefore(moment_timezone.tz(dtp.end, this.selected_timezone))) {
                return dNext;
            }
        }
        return dtp.end;
    }

    getCurrentWeekStartDay(dt: any, dtp: IDateTimePeriod) {
        const current: ICustomTimePeriod = this._getCurrentWeekPeriod(dt, dtp);
        if (!current) {
            return this.appScope.config_name_map.week_start.value
        }
        return moment_timezone.tz(current.attributes.start, this.selected_timezone).day();
    }

    private _getCurrentWeekPeriod(dt: any, dtp: IDateTimePeriod) {
        const periods: ICustomTimePeriod[] = this.calendar_dict[dtp.calendar]?.calendar_periods?.filter(cp => cp.attributes.range === 'week');
        const current: ICustomTimePeriod = periods.find(p => {
            let currentMoment = moment_timezone.tz(dt, this.selected_timezone);
            let startMoment = moment_timezone.tz(p.attributes.start, this.selected_timezone);
            let endMoment = moment_timezone.tz(p.attributes.end, this.selected_timezone);
            return startMoment.isSameOrBefore(currentMoment) && endMoment.isAfter(currentMoment);
        })
        return current;
    }

    getNextWeek(dt: any, dtp: IDateTimePeriod, returnEnd: boolean = true) {
        const periods: ICustomTimePeriod[] = this.calendar_dict[dtp.calendar]?.calendar_periods?.filter(cp => cp.attributes.range === 'week');
        const current: ICustomTimePeriod = this._getCurrentWeekPeriod(dt, dtp);
        let dNext;
        if (!current) {
            dNext = moment_timezone.tz(deepCopy(dt), this.selected_timezone).add(7, 'days').toDate();
        } else {
            const next: ICustomTimePeriod = periods.find(p => p.id === current.relationships.next_time_period.data?.id);
            if (next) {
                dNext = moment_timezone.tz(next.attributes.start, this.selected_timezone).toDate();
            } else {
                dNext = moment_timezone.tz(deepCopy(dt), this.selected_timezone).add(7, 'days').toDate();
            }
        }
        if (moment_timezone.tz(dNext, this.selected_timezone).isSameOrBefore(moment_timezone.tz(dtp.end, this.selected_timezone)) || returnEnd === false) {
            return dNext;
        } else {
            return dtp.end;
        }
    }

    subtractMonth(dt) {
        // First correct dt to mine tz
        dt.addHours(-1 * this.getClientMineUTCOffset(dt));
        // force the date into the previous month
        dt.setDate(-1);
        // Adjust back to the original tz
        dt.addHours(this.getClientMineUTCOffset(dt));
        dt = this.setToMonthStart(dt);
        return dt;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
