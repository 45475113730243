import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {Constraint, SolverTemplate} from '../../_models/solver';
import {ApiService} from "../../services/api/api.service";
import {PaginationDataSourceWithAddRow} from "../../services/api/pagination-data-source-with-add-row";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {getBaseFilter} from "../../services/api/filter_utils";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Observable} from "rxjs";
import {ListResponse, SingleResponse} from "../../services/api/response-types";
import {take} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormDialogService} from "../../services/form-dialog.service";
import {GenericDataService} from "../../data/generic-data.service";

@Component({
    selector: 'constraint-table-form',
    templateUrl: './constraint-table-form.component.html',
    styleUrls: ['./constraint-table-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class ConstraintTableFormComponent {
    private _solverTemplate: SolverTemplate;
    @Input() set solverTemplate(template: SolverTemplate) {
        if (template && this._solverTemplate) {
            this._solverTemplate = template;
            this.updateSearchFilter();
        }
        this._solverTemplate = template;
    }

    get solverTemplate(): SolverTemplate {
        return this._solverTemplate;
    };

    dataSource: PaginationDataSourceWithAddRow<Constraint>;
    filterString: string = '';
    expandFormula: boolean = false;
    currentConstraint: Constraint;
    columns: string[] = ['edit', 'name', 'description', 'assumptions', 'formula', 'save'];

    // @ViewChild('tableContainer', { read: ViewContainerRef }) tableContainer: ViewContainerRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        // private viewContainerRef: ViewContainerRef,
        private api: ApiService,
        private snackBar: MatSnackBar,
        private formDialog: FormDialogService,
        private genericData: GenericDataService
    ) {
    }

    ngOnInit() {
        let initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';

        initialQuery.filters = this.getFilterQuery()
        setTimeout(() => {
            this.paginator.pageSize = 10;
            this.dataSource = new PaginationDataSourceWithAddRow<Constraint>(
                (query) => this.page(query),
                initialQuery,
                this.paginator,
                this.sort
            );
            //this.dataSource.data = [];
        });
    }

    page(query: SearchQueryOptions): Observable<ListResponse<Constraint>> {
        return this.api.constraint.searchMany(query).pipe(take(1));
    }

    getFilterQuery() {
        let constraintIDs = this.solverTemplate.relationships.constraints.data.map(rel => rel.id);
        let filters = [getBaseFilter(constraintIDs, 'id', 'in')];
        if (this.filterString) {
            filters.push(getBaseFilter(this.filterString, 'name', 'ilike'))
        }
        return filters;
    }

    updateSearchFilter() {
        const filters = this.getFilterQuery();
        this.dataSource.filterBy(filters);
    }

    openEditingActive() {
        this.snackBar.open('Please finish editing the current constraint before adding a new one!', 'hide', {'duration': 10000});
    }

    removeConstraint(constraint: Constraint) {
        this.dataSource.data = this.dataSource.data.filter(con => con !== constraint);
        if (constraint.id) {
            constraint.relationships.solver_templates.data = constraint.relationships.solver_templates.data.filter(
                s => s !== this.solverTemplate);
            this.api.constraint.obsPatch(constraint).pipe(take(1)).subscribe({
                next: (resp: SingleResponse<Constraint>) => {
                    this.snackBar.open('Constraint removed', 'hide', {'duration': 10000});
                }, error: err => {
                    this.snackBar.open('Failed to remove constraint', 'hide', {'duration': 10000});
                    console.log(err);
                }
            });
        }
    }

    async toggleEdit(constraint: Constraint) {
        if (this.currentConstraint) {
            if (!this.currentConstraint.id) {
                if (await this.formDialog.confirm) {
                    this.removeConstraint(this.currentConstraint);
                }
            } else if (this.currentConstraint === constraint) {
                // stop editing this constraint
                this.currentConstraint = null;
            } else {
                // edit is already active for another constraint
                this.openEditingActive();
            }
        } else {
            // start editing this constraint
            this.currentConstraint = constraint;
        }
    }

    /** Create empty constraint object and add to dataSource
     */
    newConstraint() {
        if (this.currentConstraint) {
            this.openEditingActive();
            return;
        }

        let constraint: Constraint = new Constraint();
        constraint.attributes = {name: null, description: null, assumptions: null, name_formula: null};
        constraint.relationships.solver_templates = {data: [{id: this.solverTemplate.id, type: 'solver_template'}]};
        this.dataSource.addRow(constraint);
        this.currentConstraint = constraint;
    }

    /** Save `this.currentConstraint` and switch-off edit- & formula-builder mode
     */
    saveConstraint() {
        console.log('ConstraintTableFormComponent - saveConstraint: ');
        this.genericData.upsertModel<Constraint>('constraint', this.currentConstraint)
            .pipe(take(1)).subscribe({
            next: (resp: SingleResponse<Constraint>) => {
                this.currentConstraint = null;
                this.expandFormula = false;
                this.solverTemplate.relationships.constraints.data.push({type: 'constraint', id: resp.data.id});
                this.snackBar.open('Constraint saved successfully', null, {duration: 3000});
            }, error: err => {
                const msg = err.error?.errors ? err.error?.errors[0].detail : 'Failed to save constraint';
                this.snackBar.open(msg, 'Hide', {duration: 10000});
            }
        });
    }

    /** Add / remove an existing constraints to the solver template. solverTemplate saved on the parent
     * @param event selection from a select-many-search
     */
    updateConstraints(event) {
        let constraints: { type: string, id: string }[] = [];
        event.forEach(item => {
            constraints.push({type: item.type, id: item.id});
        });
        this.solverTemplate.relationships.constraints.data = constraints;
    }

    updateSort() {
        this.dataSource.sortBy(this.sort);
    }

}
