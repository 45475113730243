<button mat-button [matMenuTriggerFor]="inline_menu" class="mat-menu-button">
  <i [style.color]="value_component.attributes.json[tree_position].font_colour" class="fa fa-edit"></i>
</button>
<mat-menu #inline_menu="matMenu" class="inline-style-menu">
  <table st-table="rowCollection" id="value_component_table"
         class="table table-sortable table-striped  table-bordered table-hover" (click)="$event.stopPropagation()">
    <thead>
    <tr>
      <th style="width:35%;text-align:left;">Text</th>
      <th>Show</th>
      <th style="width:20%;text-align:left;">Report Group</th>
      <th *ngIf="value_component.type==='series_component'">Show Status</th>
      <th><i class="fas fa-border-all" matTooltip="Borders"></i></th>
      <th><i class="fa fa-text-height" matTooltip="Font size"></i></th>
      <th><i class="fa fa-paint-brush" matTooltip="Font colour"></i></th>
      <th><i class="fas fa-brush" matTooltip="Background color"></i></th>
      <th>Min</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td><input [(ngModel)]="value_component.attributes.json[tree_position].name" name="name"
                 style="text-align: left;"/></td>
      <td *ngIf="tree_position == 'parent'" style="text-align: center;">
        <mat-checkbox [(ngModel)]="value_component.attributes.view_on_parent_flowchart" aria-label="Show on flowchart"
                      style="width:30px;"></mat-checkbox>
      </td>
      <td *ngIf="tree_position == 'child'" style="text-align: center;">
        <mat-checkbox [(ngModel)]="value_component.attributes.view_on_flowchart" aria-label="Show on flowchart"
                      style="width:30px;"></mat-checkbox>
      </td>

      <td><input [(ngModel)]="value_component.attributes.report_group" name="report_group"
                 style="text-align: left;width:120px;"/></td>

      <td *ngIf="value_component.type==='series_component'" style="text-align: center;">
        <mat-checkbox [(ngModel)]="value_component.attributes.json[tree_position].show_status" aria-label="Show status"
                      style="width:30px;"></mat-checkbox>
      </td>
      <td style="text-align: center;">
        <mat-checkbox [(ngModel)]="value_component.attributes.json[tree_position].borders" aria-label="Borders"
                      style="width:30px;"></mat-checkbox>
      </td>
      <td><input [(ngModel)]="value_component.attributes.json[tree_position].font_size" type="number"/></td>
      <td style="min-width: 60px; min-height:20px;text-align:center;">
        <colour-palette [(value)]="value_component.attributes.json[tree_position].font_colour"
                        [palette]="1"
                        [show_text]="true"
                        class="no-label">
        </colour-palette>
      </td>
      <td style="min-width: 60px; min-height:20px;text-align:center;">
        <colour-palette [(value)]="value_component.attributes.json[tree_position].background"
                        [palette]="1"
                        [show_text]="true"
                        class="no-label">
        </colour-palette>
      </td>
      <td style="text-align: center;">
        <mat-checkbox [(ngModel)]="value_component.attributes.json[tree_position].minimise" aria-label="Borders"
                      style="width:20px;"></mat-checkbox>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="action-buttons">
    <button (click)="closeMenu()" class="btn-save">Done</button>
  </div>
</mat-menu>
