<div class="value-driver-tree-form row center">
  <select-search-component (selectionChange)="updateSelectedCalc($event)"
                           [api_model]="'series_light'"
                           [custom_filters]="calculation_list_filter"
                           [value]="config.selected_calculation"
                           [classes]="'fill'"
                           label="Select Calculation"
                           class="select-search">
  </select-search-component>
  <mat-checkbox [(ngModel)]="config.formula_view" class="form-element-align" style="align-self: center;">
    Formula View
  </mat-checkbox>
</div>
