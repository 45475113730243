import {BaseViewModel} from "./base-viewmodel";
import {ProcessViewModel} from "./process-viewmodel";

export class ImageViewModel extends BaseViewModel {
    index: number;
    parent_component: ProcessViewModel;

    constructor(imageData, process, index: number = null) {
        super(imageData);
        this.data.type = 'image';
        this.index = index;
        this.parent_component = process;

        if (!this.data['height']) {
            this.data.height = null;
        }
        if (!this.data['width']) {
            this.data.width = 80;
        }
        if (!this.data['src']) {
            this.data.src = '';
        }
        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }
        if (!this.data['constrain_proportions']) {
            this.data['constrain_proportions'] = true;
        }
    }

    title() {
        if (!this.data.title) {
            return "No title"
        } else {
            return this.data.title;
        }
    };

    x() {
        return this.data.x;
    };

    y() {
        return this.data.y;
    };

    height() {
        return this.data.height;
    };

    width() {
        return this.data.width;
    };

    constrain_proportions() {
        return this.data.constrain_proportions;
    }

    src() {
        return this.data.src;
    };
};
