<p *ngIf="error && error.length > 0">error</p>
<p *ngIf="!had_file">Please select a file to upload.</p>
<ng-container *ngIf="had_file">
  <div style="display: flex; flex-direction: column">
    <ng-container *ngIf="required_configurations.length > 0">
      <h2>Required configurations</h2>
      <div class="row auto required-configurations">
        <ul>
          <li *ngFor="let c of required_configurations">{{c}}</li>
        </ul>
      </div>
    </ng-container>

    <h2>Header associations</h2>

    <div class="row">
      <mat-form-field title="Trim this amount of rows from the top of the csv file.">
        <mat-label>Skip Rows</mat-label>
        <input [ngModel]="temp_options.skip_rows"
               [ngModelOptions]="{standalone: true}"
               [min]="0" matInput
               (ngModelChange)="setSkipRows($event)"
               type="number">
      </mat-form-field>

      <mat-checkbox class="self-center"
          [checked]="temp_options.use_column_indexes"
          (change)="setUseColumnIndexes($event.checked)"
          title="Named columns expect header names in first row.&#013;Index headers expect data in the first row.">
        Use column indexes
      </mat-checkbox>
    </div>

    <div class="row auto">
      <h3 style="flex: 1">Column Headers</h3>
      <h3 style="flex: 2">Associated Parser Feature</h3>
      <h3 style="flex: 2">Optional Override</h3>
    </div>

    <div *ngFor="let header of header_associations" class="row medium-height">
      <div style="flex: 1; display: flex; align-items: center; overflow: hidden" [title]="header.source" class="column-header" >
        {{header.source}}
      </div>
      <div class="row medium-height" style="flex: 2">
        <mat-form-field class="no-label">
          <mat-select (selectionChange)="updateHeaders()" [(ngModel)]="header.target_type" placeholder="Associated feature">
            <mat-option *ngFor="let option of header.available_options" [value]="option">
              {{DESCRIPTIONS[option]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row medium-height" style="flex: 2">
        <mat-form-field *ngIf="header.target_type == HEADER_TYPES.GROUP" class="no-label full-width">
          <mat-select (selectionChange)="setGroupType(header, $event.value)" [ngModel]="header.override_id"
                      placeholder="*Specify group type">
            <mat-option *ngFor="let option of ore_body_group_types" [value]="option.id">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div style="display: flex; width: 100%">
          <select-search
              style="flex: 1"
              *ngIf="header.target_type == HEADER_TYPES.PROPERTY"
              [placeholder]="'Map to alternative Constant Property'"
              [compareWith]="compareValueIds"
              [stringFunction]="property_string_function"
              (selectChange)="setPropertyOverride(header, $event)"
              [value]="header"
              [data]="constant_properties"
              [data_schema]="property_data_schema"
          >
          </select-search>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="temp_options.group_types.length > 0">
    <div style="display: flex; flex-direction: column;">
      <div class="row">
        <h2>Ore Body Group hierarchy</h2>
      </div>
      <div class="row">
        <div (cdkDropListDropped)="groupListDrop($event)" cdkDropList class="example-list">
          <div *ngFor="let group of temp_options.group_types" cdkDrag class="example-box">{{group[0]}} [with
            type {{ore_body_group_type_ids[group[1]]}}]
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="ore_body_types">
    <div class="row">
      <h2>Default Ore Body Type</h2>
    </div>
    <div *ngIf="ore_body_types && ore_body_types.length > 0" class="row">
      <mat-form-field>
        <mat-label>*Select default Ore Body Type</mat-label>
        <mat-select (selectionChange)="setDefaultOreBodyType($event.value)"
                    [value]="temp_options.default_ore_body_type">
          <mat-option *ngFor="let obt of ore_body_types" [value]="obt.attributes.name">
            {{obt.attributes.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!ore_body_types || ore_body_types.length < 1" class="row">
      <p style="margin: 0">
        Please create a default Ore Body type for this model.</p>
    </div>
  </ng-container>
  <!--  TODO drag and drop for group names -->
</ng-container>
