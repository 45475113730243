import {Injectable} from '@angular/core';
import {SeriesDataService} from "./series_data.service";
import {DateTimePeriodService} from './date-time-period.service';
import {AppScope} from './app_scope.service';
import {ApiService} from './api/api.service';
import {from, Observable, Subject} from 'rxjs';
import {concatMap, first, map, takeUntil} from "rxjs/operators";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {ModelID} from "../_typing/generic-types";

interface ISeriesSummaryConfig {
    selected_series: any,
    value_only: boolean,
    chart_type: string,
    primary_value: string,
    secondary_val_1: string,
    secondary_val_2: string,
    chart_config: any,
    show_unit: boolean,
    show_header: boolean,
    series_unit: string,
    shift_type_id?: number,
    labels: { sub_title?: string },
    cache: boolean //TODO write cache for extended context tile (ie. not just VDT contexts),
    svg: any
}

export type SeriesSummaryConfig = Partial<ISeriesSummaryConfig>

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    est_favourability_mapping: {};
    dtp: IDateTimePeriod;
    context: any = {};
    private readonly onDestroy = new Subject<void>();

    constructor(
        private seriesData: SeriesDataService,
        private dateTimePeriodService: DateTimePeriodService,
        private appScope: AppScope,
        private api: ApiService) {

        const ctrl = this;

        this.seriesData.$estimate_types.promise.then(response => {
            ctrl.est_favourability_mapping = ctrl.seriesData.est_favourability_dict;
        });
    }

    setDefaults(config) {
        const ctrl = this;
        //Set value defaults
        if (!config.primary_value) {
            config.primary_value = 'Value'
        }
        if (!config.secondary_val_1) {
            config.secondary_val_1 = 'Forecast'
        }
        if (!config.secondary_val_2) {
            config.secondary_val_2 = 'MTD'
        }
        return config;
    }

    getSelectedColumns(config) {
        let selected_columns = [config.primary_value, config.secondary_val_1, config.secondary_val_2];

        if (!config.chart_type || config.chart_type === 'sparkline') {
            selected_columns.push('Sparkline')
        }
        selected_columns.push('Unit');
        selected_columns.push('DecimalPlaces');
        return selected_columns;
    }

    getSeriesSummary(config, selected_columns, dtp: IDateTimePeriod): Observable<any> {
        const ctrl = this;
        let params = {};
        let calendar = null;
        if (dtp && dtp.calendar) {
            calendar = dtp.calendar
        }
        return from(this.seriesData.$estimate_types.promise).pipe(
            concatMap(list => {
                    let estTypeList = this.seriesData.getEstimateTypesList(selected_columns, list);
                    const $summary = this.seriesData.getSeriesSummary(dtp, [config.selected_series.id], null, selected_columns, estTypeList, config.shift_type_id)
                    return $summary.pipe(first(), map(result => {
                        if (!result || !result[0]) return result;
                        selected_columns.forEach(col => {
                            if (!result[0][col]) {
                                result[0][col] = '-';
                            }
                        })
                        return result;
                    }))
                }
            ), takeUntil(this.onDestroy))
    };

    setFontSize(config, significantNumber) {
        //**This logic is being overridden by use of sigNumber pipe so removing unit in length calc for now**
        //**and adding sigNumber calc **
        let unit_len = 0;
        if (config.show_unit && config.series_unit) {
            unit_len = config.series_unit.length;
        }
        if (significantNumber) {
            let length = significantNumber.toString().length + unit_len;
            if (length > 3) {
                let value = 100 - ((length - 2) * 12); //This is pretty rough, for value driver trees
                if (value < 30) {
                    value = 30;
                }
                return value;
            }
        }
        return null;
    }

    getContextColours() {
        const ctrl = this;
        let red: string = 'red';
        let green: string = 'green';
        if (ctrl.appScope.config_name_map.hasOwnProperty('palette2')) {
            if (ctrl.appScope.config_name_map.palette2.value.find(colour => colour.name == 'Green')) {
                green = ctrl.appScope.config_name_map.palette2.value.find(colour => colour.name == 'Green').colour
            }
            if (ctrl.appScope.config_name_map.palette2.value.find(colour => colour.name == 'Red')) {
                red = ctrl.appScope.config_name_map.palette2.value.find(colour => colour.name == 'Red').colour
            }
        }
        return {red: red, green: green}
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
