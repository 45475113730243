<div class="component-side-panel-form">

  <h3>Name Formula</h3>
  <div class="row center">
    <component-name-formula-selector [componentType]="config.selected_component_type"
                                     [(selectedNameFormula)]="config.selected_name_formula"
                                     class="row center" style="width:100%;">
    </component-name-formula-selector>
  </div>

  <mat-divider></mat-divider>
  <h3>Default Values for New Components</h3>
  <ng-container *ngFor="let col of config.selected_cols?.component_type">
    <div *ngIf="col.type==='constant_property'" class="row low-height center">
        <label class="inline" [matTooltip]="col.title || col.name"
        [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY">{{col.title || col.name}}</label>
        <default-value-field
            [field]="col"
            [panelClass]="'component-form-form'"
            class="default-value-field">
        </default-value-field>
    </div>
  </ng-container>

  <mat-divider class="form-ctrl-margin-top"></mat-divider>
  <h3>Linked Components</h3>
  <ng-container *ngFor="let rct of config.selected_component_relationship_types">
    <div *ngIf="config.relationships[rct.id]">
      <div class="row" style="align-items: center;">
        <button class="expand-relationship" (click)="showLinkedItemDetails(rct, !show_linked_details[rct.id])">
        <span class="material-symbols-outlined arrow" matTooltip="Expand linked items">
              {{show_linked_details[rct.id] ? 'arrow_drop_down' : 'arrow_right'}}</span>
          <span>{{rct.attributes.name}}</span>
        </button>
        <mat-checkbox [(ngModel)]="config.relationships[rct.id].show"
                      (ngModelChange)="showLinkedItemDetails(rct, config.relationships[rct.id].show)">Show pending
          context
          in side panel
        </mat-checkbox>
      </div>
      <ng-container *ngIf="show_linked_details[rct.id] && config.relationships[rct.id]">
        <pending-context-form [config]="config.relationships[rct.id].pending_context" [static]="true"
                              style="padding:10px;">
        </pending-context-form>
      </ng-container>

    </div>
  </ng-container>

  <mat-divider></mat-divider>
  <h3>Linked Events</h3>
  <div *ngFor="let ret of linked_event_types" style="margin-bottom:20px;">
    <div class="row">
      <button class="expand-relationship" (click)="showLinkedItemDetails(ret, !show_linked_details[ret.id])">
        <span class="material-symbols-outlined arrow" matTooltip="Expand linked items">
              {{show_linked_details[ret.id] ? 'arrow_drop_down' : 'arrow_right'}}</span>
        <span>{{ret.attributes.name}}</span>
      </button>
      <!--      <mat-checkbox [(ngModel)]="config.relationships[ret.id].show">Show in side panel</mat-checkbox>-->
    </div>
    <ng-container *ngIf="show_linked_details[ret.id]">
      <div class="row">
        <select-search style="flex-grow:1;margin-right:20px;"
                       (selectChange)="setUnique(ret, $event)"
                       [data]="config.selected_cols.event"
                       [disabled]="!config.selected_cols.event?.length"
                       [value]="config.relationships[ret.id].unique_property"
                       [compareWith]="compareValueToId"
                       [stringFunction]="eventOptions"
                       label="Select unique identifier column">
        </select-search>
        <div class="spacer"></div>
      </div>
    </ng-container>
  </div>
</div>
