<div class="form-dialog">
  <h1>Edit Text
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">

      <mat-tab label="Edit Paragraph">
        <div class="form-section column space-between">
          <input #imageUpload accept="image/*" style="display:none" type="file">
          <quill-editor #my_quill #quillEditorComponent (click)="adjustTooltip($event)"
                        [(ngModel)]="paragraph"
                        [modules]="options" class="text-editing">
          </quill-editor>

        </div>

        <!--Easily insert a GSS series tag-->
        <div *ngIf="all_columns" class="row center" style="margin-top:16px;">
          <div (mouseenter)="hint='formula_series'" class="select-search" style="flex-grow:2">
            <select-search-component [label]="'Select a series'"
                                     [api_model]="'series_light'"
                                     [(value)]="insert_series"
                                     [classes]="'fill'"
                                     (selectionChange)="addToFormula($event)"
            >
            </select-search-component>
          </div>
          <mat-form-field>
            <mat-label>Select a column</mat-label>
            <mat-select (selectionChange)="addToFormula()" [(value)]="insert_column">
              <ng-container *ngFor="let column of all_columns.columns">
                <mat-option *ngIf="column.deprecated!==true"
                            [value]="column">
                  {{column.title| upperfirst}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row center" style="margin-top:-20px;">
          <input #copy_string (click)="copyToClipboard(copy_string)" [(ngModel)]="gss_insert"
                 style="width:100%;margin-right:4px;"/>
          <i (click)="copyToClipboard(copy_string)" class="fa fa-clipboard"
             style="position:relative;right:26px;" title="Copy to clipboard"></i>
        </div>

      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button (click)="save()" class="btn-save">Save</button>
  </div>
</div>
