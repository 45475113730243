import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {NotificationService} from "../services/notification.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {WIRE_FORMATS} from "../date-time/date-time-period.component";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {ConstantComponent} from "../_models/constant-component";
import {CustomEventsService} from "../services/custom-events.service";

@Component({
    selector: 'component-constant-table',
    templateUrl: 'component-constant-table.component.html',
    styleUrls: ['series-component-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: WIRE_FORMATS },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    ],
    standalone: false
})

export class ComponentConstantTableComponent implements OnInit {
    @Input() component: any;
    @Input() constant_property_dict: any;

    @Input()
    set component_constants(constant_components: ConstantComponent[]) {
        if (Array.isArray(constant_components)) {
            constant_components.sort((a, b) => {
                return (a.attributes.name < b.attributes.name) ? -1 : (a.attributes.name > b.attributes.name) ? 1 : 0;
            })
        }
        this.component_constants_ = constant_components
    }

    get component_constants(): ConstantComponent[] {
        return this.component_constants_
    };

    @Input() all_series: any;
    @Input() flowchart: any;
    @Input() showHints: any;

    private component_constants_: ConstantComponent[] = null;
    tree_position: string = 'child';
    saving: boolean = false;

    constructor(private api: ApiService, private notification: NotificationService, public dateTimePeriodService: DateTimePeriodService,
                private customEventsService: CustomEventsService) {
        //this.selectChange = new EventEmitter();
    }

    ngOnInit(): void {
        const ctrl = this;
        if (ctrl.component.is_parent) {
            ctrl.tree_position = 'parent';
        } else {
            ctrl.tree_position = 'child';
        }
    }

    deleteConstantComponent(item) {
        const ctrl = this;
        if (item.hasOwnProperty('id')) {
            if (!confirm("Please confirm to delete this constant component.")) {
                return;
            }
            let delete_item = ctrl.api.constant_component.delete(item.id);
            delete_item.then(function (ret) {
                    item.id = null;
                    item.attributes.value = null;
                    if (ctrl.flowchart) {
                        ctrl.flowchart.deleteConstantComponents([item.id]);
                    }
                }, function (ret) {
                }
            );
        }
    };

    addConstantComponent(component_constant) {
        const ctrl = this;
        let property_name = component_constant.attributes.name;
        //ctrl.component.attributes.custom_constants[property_name] = constant_component.attributes.value; //keeping state?
        ctrl.saving = true;

        if (!component_constant.id) {
            var new_constant_component = ctrl.api.constant_component.save(component_constant);
            new_constant_component.then(result => {
                this.notification.openSuccess("Constant property value saved.", 2000);
                new_constant_component = result.data;
                component_constant.id = new_constant_component['id'];
                //ctrl.component.relationships.constant_components.data.push(constant_component);
                if (ctrl.flowchart) {
                    ctrl.flowchart.addConstantComponent(component_constant, ctrl.flowchart.parent_process, ctrl.component); //chart_process here = the viewmodel of the process
                }
                ctrl.saving = false;
            });
        } else {
            delete component_constant.attributes.data_type
            delete component_constant.attributes.name;
            let new_constant_component = ctrl.api.constant_component.patch(component_constant);
            new_constant_component.then((result) => {
                ctrl.saving = false;
                //ctrl.updateComponent(constant_component);
                this.notification.openSuccess("Constant property value saved.", 2000);
            }).catch(error => console.log(error));
        }
    };

    constant_component_schema(component_constant) {
        const ctrl = this;
        return {
            id: null,
            attributes: {
                value: null,
                json: {}
            },
            relationships: {
                component: {data: {type: 'component', id: ctrl.component.id}},
                constant_property: {
                    data: {
                        type: 'constant_property',
                        id: component_constant.relationships.constant_property.data.id
                    }
                }
            },
            type: 'constant_component'
        }
    };


}
