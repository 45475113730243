import {Injectable} from '@angular/core';
import {fromEvent, Observable} from "rxjs";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {
    ComponentTypeFormDialogComponent
} from "../forms/component-type-form-dialog/component-type-form-dialog.component";
import {OreBodyTypeFormDialogComponent} from "../forms/ore-body-type-form-dialog/ore-body-type-form-dialog.component";
import {ChartDialog} from "../charts/chart-dialog/chart-dialog.component";
import {concatMap, filter, takeUntil, tap, map} from "rxjs/operators";
import {PageViewFormComponent} from "../forms/page-view-form/page-view-form.component";
import {HeaderDataService} from './header_data.service';
import {CustomDialogComponent} from "../forms/custom-dialog/custom-dialog.component";
import {Series} from '../_models/series';
import {Process} from '../_models/process';
import {Group} from '../_models/group';
import {SessionState} from '../_models/session-state';
import {
    UserGroupPageFormDialogComponent
} from "../forms/user-group-page-form-dialog/user-group-page-form-dialog.component";
import {ModelID, KeyMap} from '../_typing/generic-types';
import {LockTemplateFormDialogComponent} from "../forms/lock-template-form-dialog/lock-template-form-dialog.component";
import {
    ConstantPropertyCalcTreeDialogComponent
} from "../forms/constant-property-calc-tree-dialog/constant-property-calc-tree-dialog.component";
import {ComponentType} from "../_models/component-type";
import {EventType} from "../_models/event-type";
import {ConfigStub} from '../_typing/config-stub';
import {
    EventComponentDeleteCheckDialogComponent
} from '../forms/event-component-delete-check-dialog/event-component-delete-check-dialog.component';
import {Component} from '../_models/component';
import {Event} from "../_models/event";
import {LockTemplateVersion} from "../_models/lock-template-version";
import {ConstantProperty} from '../_models/constant-property';
import {User} from "../_models/users";
import {IDateTimePeriod} from "../_typing/date-time-period";
import {NotificationService} from "./notification.service";
import {MatSnackBarRef, MatSnackBarDismiss} from "@angular/material/snack-bar";
import {
    CommentPromptComponent,
    CommentPromptData
} from "../components/snackbar/comment-prompt/comment-prompt.component";
import {ConfirmSnackbarComponent} from "../notifications/snackbar/confirm-snackbar/confirm-snackbar.component";
import {DateTimeInstanceService} from "./date-time-instance.service";
import {CopyTileFormDialogComponent} from "../forms/copy-tile-form-dialog/copy-tile-form-dialog.component";

interface ICurrentValue {
    id: string;
}

export interface IFileOptions {
    title: string;
    value: string;
}

interface IParameterAttributes {
    custom_filters: any;
    component_types: string[];
    comment_append: string;
    current_value: ICurrentValue;
    font_size: string;
    file_options: IFileOptions[];
    format: string;
    menu_visible: boolean;
    model: any;
    origin: any;
    original_models: any;
    selected_model: any;
    series: Series;
    series_permission_ids: ModelID[];
    process: Process;
    value: string | number;
    width: string | number;
}

type OptionalParameterAttributes = Partial<IParameterAttributes>;

interface IPosition {
    top: number;
    left: number;
    max_right: number;
    adjust_left: number;
}

type OptionalPosition = Partial<IPosition>;

interface ICustomDialogConfig {
    component: string;
    id: string;
    parameters: OptionalParameterAttributes;
    position: OptionalPosition;
}

export type CustomDialogConfig = Partial<ICustomDialogConfig>;


/**
 Repeated code in opening form dialogs
 **/

@Injectable()
export class FormDialogService {
    public chart_dialog_ref: MatDialogRef<ChartDialog>;
    private showingCommentDrawer: boolean = false;

    constructor(public dialog: MatDialog,
                private headerData: HeaderDataService,
                private notification: NotificationService,
                private dateInst: DateTimeInstanceService) {
    }

    async confirm(message: string, cancelText?: string, okText?: string): Promise<boolean> {
        const confirmRef: MatSnackBarRef<ConfirmSnackbarComponent> = this.notification.openConfirm(message, cancelText, okText);
        return await confirmRef.afterDismissed()
            .pipe(map((result: MatSnackBarDismiss) => {
                return result.dismissedByAction;
            })).toPromise();
    }


    editComponentType(component_type?, component_types_list?): MatDialogRef<ComponentTypeFormDialogComponent> {
        const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
        dialogConfig.data = {
            component_type: component_type ? component_type : {},
            component_types_list: component_types_list
        };
        dialogConfig.panelClass = ['default-form-dialog', 'event-type-form-dialog'];
        return this.dialog.open(ComponentTypeFormDialogComponent, dialogConfig);
    }

    editOreBodyType(ore_body_type?) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            ore_body_type: ore_body_type
        };
        dialogConfig.panelClass = ['default-form-dialog', 'ore-body-type-form-dialog'];
        return this.dialog.open(OreBodyTypeFormDialogComponent, dialogConfig);

    }

    resizeWithCommentDrawerSubscribe(): void {
        this.headerData.showingCommentPanel.pipe(
            takeUntil(this.chart_dialog_ref.afterClosed()),
            // Extra check, don't subscribe unless chart_dialog is open
            filter(value => this.chart_dialog_ref !== undefined)
        ).subscribe(value => {
            this.showingCommentDrawer = value.toggle;
            if (value.toggle === true && this.chart_dialog_ref) {
                this.chart_dialog_ref.updatePosition({left: '10px'});
                this.chart_dialog_ref.updateSize('65%');
            } else if (this.chart_dialog_ref) {
                this.chart_dialog_ref.updatePosition({left: undefined});
                this.chart_dialog_ref.updateSize('1200px');
            }
        });
    }

    openChartDialog(series_name: string, dtp: IDateTimePeriod = this.dateInst.dtp) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {config: {series_name: series_name, dtp: dtp}};
        dialogConfig.hasBackdrop = false;
        dialogConfig.disableClose = false;
        dialogConfig.panelClass = 'default-chart-dialog';
        this.chart_dialog_ref = this.dialog.open(ChartDialog, dialogConfig);

        // Since hasBackdrop can't be changed once dialog is open...
        this.chart_dialog_ref.afterOpened().pipe(
            concatMap(() => {
                return fromEvent(document, 'click').pipe(
                    tap(value => {
                        let target: Element = value.target as Element;
                        if (this.showingCommentDrawer || target.closest('mat-dialog-container') !== null
                            || ['close-comments-button', 'close-comments-button-icon'].includes(target.id)) {
                            return;
                        }

                        this.chart_dialog_ref.close();
                    }));
            }),
            takeUntil(this.chart_dialog_ref.afterClosed())).subscribe();

        this.resizeWithCommentDrawerSubscribe();
        return this.chart_dialog_ref;
    }

    openNewPageDialog(): MatDialogRef<PageViewFormComponent> {
        const session_state = this.headerData.emptyPageJson();
        return this.dialog.open(PageViewFormComponent, {
            data: session_state,
            panelClass: 'page-form-dialog'
        });
    }

    openCustomDialog(event: any, config: CustomDialogConfig, panel_class: string,
                     setStyles?: (event) => void, selectionChanged?: (event) => void): MatDialogRef<CustomDialogComponent> {
        event?.stopPropagation();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = "custom-dialog";
        dialogConfig.backdropClass = 'transparent-backdrop';
        dialogConfig.disableClose = false;
        dialogConfig.data = {
            positionRelativeToElement: event?.target,
            config: config,
            setStyles: setStyles,
            selectionChanged: selectionChanged
        };
        dialogConfig.panelClass = panel_class;
        return this.dialog.open(CustomDialogComponent, dialogConfig);
    }

    openCommentPrompt(config: Partial<CommentPromptData>) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = "comment-prompt-dialog";
        dialogConfig.backdropClass = 'transparent-backdrop';
        dialogConfig.disableClose = false;
        dialogConfig.data = config;
        dialogConfig.panelClass = 'prompt-dialog';
        return this.dialog.open(CommentPromptComponent, dialogConfig);
    }

    openUserGroupPageFormDialog(model: User | Group | SessionState): MatDialogRef<UserGroupPageFormDialogComponent> {
        const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
        dialogConfig.data = {model: model};
        dialogConfig.panelClass = ['default-form-dialog', 'user-form-dialog'];
        return this.dialog.open(UserGroupPageFormDialogComponent, dialogConfig);
    }

    openLockTemplateFormDialog(lock_template_ids?: ModelID[], lock_template_versions?: LockTemplateVersion[], component?: Component,
                               event?: Event, lock_version_dict?: KeyMap<ModelID>, property?: ConstantProperty | string):
        MatDialogRef<LockTemplateFormDialogComponent> {
        const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
        dialogConfig.data = {
            lock_template_ids: lock_template_ids, lock_template_versions: lock_template_versions,
            component: component, event: event, lock_version_dict: lock_version_dict, constant_property: property
        };
        dialogConfig.panelClass = ['default-form-dialog', 'user-form-dialog'];
        return this.dialog.open(LockTemplateFormDialogComponent, dialogConfig);
    }

    openConstantPropertyCalcTreeDialog(component_or_event: Partial<ComponentType> | ConfigStub<EventType>,
                                       constant_property_id: ModelID): MatDialogRef<ConstantPropertyCalcTreeDialogComponent> {
        const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
        dialogConfig.data = {component_or_event: component_or_event, constant_property_id: constant_property_id};
        dialogConfig.panelClass = ['default-form-dialog', 'user-form-dialog'];
        return this.dialog.open(ConstantPropertyCalcTreeDialogComponent, dialogConfig);
    }

    eventComponentDeleteCheck(data_to_replace): MatDialogRef<EventComponentDeleteCheckDialogComponent> {
        return this.dialog.open(EventComponentDeleteCheckDialogComponent, {
            data: data_to_replace,
            panelClass: 'page-form-dialog',
            disableClose: true
        });
    }

    openCopyTileForm(tileId: ModelID, sessionStateId: ModelID): MatDialogRef<CopyTileFormDialogComponent> {
        return this.dialog.open(CopyTileFormDialogComponent, {
            data: {tileId: tileId, sessionStateId: sessionStateId},
            panelClass: 'copy-tile-form-dialog',
            disableClose: true
        });

    }
}
