import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Group} from '../../_models/group';
import {SessionState} from '../../_models/session-state';
import {SaveableBase, SaveService} from "../../services/save/save.service";
import {User} from "../../_models/users";
import {MatTabGroup} from "@angular/material/tabs";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {ConfirmSnackbarComponent} from "../../notifications/snackbar/confirm-snackbar/confirm-snackbar.component";
import {NotificationService} from "../../services/notification.service";

export interface DialogData {
    model: User | Group | SessionState;
}

@Component({
    selector: 'user-group-page-form-dialog',
    templateUrl: './user-group-page-form-dialog.component.html',
    styleUrls: ['./user-group-page-form-dialog.component.less'],
    providers: [SaveService],
    standalone: false
})

export class UserGroupPageFormDialogComponent implements OnInit {

    model: User | Group | SessionState;
    form_title: string = '';

    constructor(private dialogRef: MatDialogRef<UserGroupPageFormDialogComponent>,
                private saveService: SaveService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
    }

    ngOnInit() {
        this.model = this.dialogData.model;
    }

    save() {
        this.saveService.emitSave();
        // this.dialogRef.close(this.model);
    }

    canSave(): boolean {
        return this.saveService.canSave()
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
