<ng-container *ngIf="node">

  <div class="drag-drop-tree"
       [ngClass]="{'top':node?.id==='top'}"
       [style.display]="node?.id==='top' || expandedNodes?.[node.id || node.title] ? 'block' : 'none'">

    <ng-container>
      <div
          (cdkDropListDropped)="drop($event)"
          [cdkDropListConnectedTo]="dragDropService.dropLists"
          [cdkDropListEnterPredicate]="allowDropPredicate"
          [id]="node.id"
          [cdkDropListData]="node.contents"
          [cdkDropListDisabled]="!editMode"
          cdkDropList
          class="draggable-list">

        <div *ngFor="let child of node.contents; let i = index"
             cdkDrag
             [cdkDragData]="child"
             (cdkDragStarted)="onDragStarted($event)"
             [cdkDragDisabled]=""
             [cdkDragPreviewClass]="'folderDragPreview'"
             (cdkDragMoved)="dragMoved($event)"
             (cdkDragReleased)="dragReleased($event)"
             class="draggable-list-item mat-tree-node"
             [ngClass]="{'folder':child.type===0}">

          <ng-container *ngIf="child.type === 0 && child.menu_entry_count">
            <div class="parent-folder-node">
              <div *ngIf="editMode" class="drag-handle" cdkDragHandle>☰</div>
              <button class="folder-toggle"
                      (click)="onNodeToggled(child)">
                <i [ngClass]="expandedNodes[child.id || child.title] ? 'chevron-up' : 'chevron-down'"></i>
                <span *ngIf="child.type === 0">{{child.folder.attributes.name}}</span>
              </button>
            </div>

            <dragdrop-tree [node]="child" [expandedNodes]="expandedNodes">
            </dragdrop-tree>
          </ng-container>


          <div class="menu-node row" *ngIf="child.type===1"
               [style.display]="child.visible ? 'flex' : 'none'">
            <div *ngIf="editMode" class="drag-handle" cdkDragHandle>☰</div>
            <a [routerLink]="!isDragging ? 'view/page_view/' + child.session_state.id : null"
               (click)="!isDragging && closeSidenav()">{{child.session_state.attributes.name}}</a>
          </div>

        </div>


      </div>
    </ng-container>

  </div>
</ng-container>