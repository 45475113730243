import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "../audit-mixin";
import {SeriesCorrectionJob} from "./series-correction-job";

export abstract class JobAttributes<INPUT_SCHEMA, OUTPUT_SCHEMA> extends AuditMixinAttributes {
    input_data?: INPUT_SCHEMA ;
    output_data?: OUTPUT_SCHEMA;
    errors?: any;
    abstract type: string;
    status: "pending" | "processing" | "completed" | "error";
}

export abstract class JobRelationships extends AuditMixinRelationships {
}

// TODO review REST respond for the details here
export abstract class Job<INPUT_SCHEMA, OUTPUT_SCHEMA, ATTRIBUTES extends JobAttributes<INPUT_SCHEMA, OUTPUT_SCHEMA>> extends AuditMixin {
    attributes: ATTRIBUTES;
    id: number;
    type: string = 'job';
    relationships: JobRelationships;
}

export type GenericJob = SeriesCorrectionJob // | currently no others defined

export function isSeriesCorrectionJob(job:GenericJob): job is SeriesCorrectionJob {
    return (job as SeriesCorrectionJob)?.attributes?.type === 'series_correction';
}
