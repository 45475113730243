<div #series_summary_tile [ngStyle]="{'border-color':category_colour, 'editing-tile': editing}"
     class="series-summary-tile"
>
  <svg #series_summary_svg *ngIf="summary && context_model && viewBox.scale_h" [attr.height]="'100%'"
       [attr.viewBox]="'0 0 100% 100%'" [attr.width]="'100%'" style="position:relative;"
       xmlns:xhtml="http://www.w3.org/1999/xhtml"
       (click)="deselectAll()"
  >
    <!--Title/header text -->
    <text (click)="elementClicked($event, context_model.title)"
          (cdkDragMoved)="onDragging($event, context_model.title)"
          (contextmenu)="contextMenu($event, context_model.title)"
          (mousedown)="processDown($event, context_model.title)"
          *ngIf="show_header !==true"
          [attr.transform]="'rotate(' + context_model.title.rotate() + ')'"
          [attr.x]="context_model.title.x() + '%'"
          [attr.y]="context_model.title.y() + '%'"
          [ngStyle]="styles['title']"
          [ngClass]="{'selected' : context_model.title.selected()}"
          cdkDrag style="transform:none !important;"
          text-anchor="start">
      {{title}}
    </text>

    <g>
      <!--Sparkline-->
      <ng-container *ngIf="(config.chart_type==='sparkline' || !config.chart_type) && summary.Sparkline">
        <foreignObject
            (click)="elementClicked($event, context_model.sparkline)"
            (contextmenu)="contextMenu($event, context_model.sparkline)"
            (cdkDragMoved)="onDragging($event, context_model.sparkline)"
            (mousedown)="processDown($event, context_model.sparkline)"
            [attr.height]="(context_model.sparkline?.height() || 0) + '%'"
            [attr.width]="(context_model.sparkline?.width() || 0) + '%'"
            [attr.x]="(context_model.sparkline?.x() || 0) + '%'"
            [attr.y]="(context_model.sparkline?.y() || 0)+ '%'"
            [ngStyle]="{'border' : editing ? '1px dotted #666' : 'none'}"
            [ngClass]="{'selected' : context_model.sparkline?.selected()}"
            [cdkDragDisabled]="!editing"
            cdkDrag class="sparkline"
            style="transform:none !important;position:relative;"
        >
          <xhtml:sparkline *ngIf="(!config.chart_type || config.chart_type==='sparkline') &&  summary"
                           [data_points]="summary.Sparkline"
                           [height]="viewBox.scale_h * context_model.sparkline?.height()/100"
                           [series_name]="config.selected_series.attributes.name"
                           class="chart" style="width:100%;">

          </xhtml:sparkline>
          <xhtml:i #dragHandleCorner (cdkDragMoved)="dragMove(dragHandleCorner, context_model.sparkline, $event)"
                   (mousedown)="processDown($event, context_model.sparkline)"
                   *ngIf="editing"
                   cdkDrag
                   class="dragHandle corner fas fa-external-link-square-alt">
          </xhtml:i>
        </foreignObject>
      </ng-container>

      <!--Custom Chart-->
      <ng-container *ngIf="config.chart_type==='custom-chart'">
        <foreignObject
            (click)="elementClicked($event, context_model.custom_chart)"
            (contextmenu)="contextMenu($event, context_model.custom_chart)"
            (cdkDragMoved)="onDragging($event, context_model.custom_chart)"
            (mousedown)="processDown($event, context_model.custom_chart)"
            [attr.height]="(viewBox.scale_h * context_model.custom_chart?.height()/100) - context_model.offset_percent + 'px'"
            [attr.max-height]="(viewBox.scale_h * context_model.custom_chart?.height()/100) - context_model.offset_percent + 'px'"
            [attr.width]="(viewBox.scale_w * context_model.custom_chart?.width()/100) + 'px'"
            [attr.x]="(context_model.custom_chart?.x() || 0 )+ '%'"
            [attr.y]="(context_model.custom_chart?.y() || 0) + '%'"
            [ngStyle]="{'border' : editing ? '1px dotted #666' : 'none'}"
            [ngClass]="{'selected' : context_model.custom_chart?.selected()}"
            [cdkDragDisabled]="!editing"
            cdkDrag class="custom-chart"
            style="transform:none !important;position:absolute;"
        >
          <xhtml:generic-chart #generic [config]="config.chart_config"
                               class="chart">
          </xhtml:generic-chart>
          <xhtml:i #dragHandleCorner (cdkDragMoved)="dragMove(dragHandleCorner, context_model.custom_chart, $event)"
                   (mousedown)="processDown($event, context_model.custom_chart)"
                   *ngIf="editing"
                   cdkDrag
                   class="dragHandle corner fas fa-external-link-square-alt">
          </xhtml:i>
        </foreignObject>
      </ng-container>

      <!--Primary Value Text-->
      <text (click)="elementClicked($event, context_model.primary_value)"
            (cdkDragMoved)="onDragging($event, context_model.primary_value)"
            (contextmenu)="contextMenu($event, context_model.primary_value)"
            (mousedown)="processDown($event, context_model.primary_value)"
            [attr.transform]="'rotate(' + context_model.primary_value.rotate() + ')'"
            [attr.x]="context_model.primary_value.x() + '%'"
            [attr.y]="context_model.primary_value.y() + '%'"
            [ngStyle]="styles['primary_value']"
            cdkDrag
            style="transform:none !important;"
            text-anchor="end">
        {{summary[config.primary_value] | formatNumber:summary['DecimalPlaces']:true:null:false: null: config.primary_value}}
        <ng-container *ngIf="config.show_unit && summary['Unit']">&nbsp;{{summary['Unit']}}</ng-container>
      </text>

      <!--Secondary Values-->
      <ng-container *ngIf="!config.value_only">
        <text (click)="elementClicked($event, context_model.secondary_value1_title)" #second
              (cdkDragMoved)="onDragging($event, context_model.secondary_value1_title)"
              (contextmenu)="contextMenu($event, context_model.secondary_value1_title)"
              (mousedown)="processDown($event, context_model.secondary_value1_title)"
              [attr.transform]="'rotate(' + context_model.secondary_value1_title.rotate() + ')'"
              [attr.x]="context_model.secondary_value1_title.x() + '%'"
              [attr.y]="context_model.secondary_value1_title.y() + '%'"
              [ngStyle]="styles['secondary_value1_title']"
              [ngClass]="{'selected' : context_model.secondary_value1_title.selected()}"
              alignment-baseline="middle" cdkDrag
              style="transform:none !important;"
              text-anchor="end">
          {{seriesData.column_dict[config.secondary_val_1]?.abbr ?
            seriesData.column_dict[config.secondary_val_1].abbr : config.secondary_val_1 | upperfirst}}:
        </text>
        <text (click)="elementClicked($event, context_model.secondary_value1)"
              (cdkDragMoved)="onDragging($event, context_model.secondary_value1)"
              (contextmenu)="contextMenu($event, context_model.secondary_value1)"
              (mousedown)="processDown($event, context_model.secondary_value1)"
              [attr.transform]="'rotate(' + context_model.secondary_value1.rotate() + ')'"
              [attr.x]="context_model.secondary_value1.x() + '%'"
              [attr.y]="context_model.secondary_value1.y() + '%'"
              [ngStyle]="styles['secondary_value1']"
              [ngClass]="{'selected' : context_model.secondary_value1.selected()}"
              alignment-baseline="middle" cdkDrag
              style="transform:none !important;"
              text-anchor="end">
          {{summary[config.secondary_val_1] | formatNumber:summary['DecimalPlaces']:true:null:false: null: config.secondary_val_1}}
        </text>

        <text (click)="elementClicked($event, context_model.secondary_value2_title)"
              (cdkDragMoved)="onDragging($event, context_model.secondary_value2_title)"
              (contextmenu)="contextMenu($event, context_model.secondary_value2_title)"
              (mousedown)="processDown($event, context_model.secondary_value2_title)"
              [attr.transform]="'rotate(' + context_model.secondary_value2_title.rotate() + ')'"
              [attr.x]="context_model.secondary_value2_title.x() + '%'"
              [attr.y]="context_model.secondary_value2_title.y() + '%'"
              [ngStyle]="styles['secondary_value2_title']"
              [ngClass]="{'selected' : context_model.secondary_value2_title.selected()}"
              alignment-baseline="middle" cdkDrag
              style="transform:none !important;"
              text-anchor="end">
          {{seriesData.column_dict[config.secondary_val_2]?.abbr ?
            seriesData.column_dict[config.secondary_val_2].abbr : config.secondary_val_2 | upperfirst}}:
        </text>

        <text (click)="elementClicked($event, context_model.secondary_value2)"
              (cdkDragMoved)="onDragging($event, context_model.secondary_value2)"
              (contextmenu)="contextMenu($event, context_model.secondary_value2)"
              (mousedown)="processDown($event, context_model.secondary_value2)"
              [attr.transform]="'rotate(' + context_model.secondary_value2.rotate() + ')'"
              [attr.x]="context_model.secondary_value2.x() + '%'"
              [attr.y]="context_model.secondary_value2.y() + '%'"
              [ngStyle]="styles['secondary_value2']"
              [ngClass]="{'selected' : context_model.secondary_value2.selected()}"
              alignment-baseline="middle" cdkDrag
              style="transform:none !important;"
              text-anchor="end">
          {{summary[config.secondary_val_2] | formatNumber:summary['DecimalPlaces']:true:null:false: null: config.secondary_val_2}}
        </text>
      </ng-container>

    </g>
  </svg>

</div>
