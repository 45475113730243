import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import * as utils from "../../../lib/utils";
import {DateTimePeriodService} from "../../../services/date-time-period.service";
import {ApiService} from "../../../services/api/api.service";
import {ChartSeriesConfiguration} from "../../../charts/chart-config/chart-series.configuration";
import {SeriesDataService} from "../../../services/series_data.service";
import {Subject} from "rxjs";
import {first, takeUntil} from 'rxjs/operators';
import {ChartType, ChartTypeSeriesChartTypeDict} from "../../../charts/chart-config/chart-configuration";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {GenericChartTileConfiguration} from "../../../charts/chart-config/generic-chart-tile.configuration";
import {getManyRelationWithIdFilter, getRelationWithManyIdsFilter} from "../../../services/api/filter_utils";
import {KeyMap} from "../../../_typing/generic-types";
import {ConfigStub} from "../../../_typing/config-stub";
import {Series} from "../../../_models/series";

@Component({
    selector: 'custom-chart-form-series-table',
    templateUrl: './custom-chart-form-series-table.component.html',
    styleUrls: ['./custom-chart-form-series-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomChartFormSeriesTableComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();

    @Input() series_list: ChartSeriesConfiguration[];
    @Output() series_listChange = new EventEmitter<ChartSeriesConfiguration[]>();
    private _chart_type: ChartType = 'custom';
    @Input() set chart_type(chart_type: ChartType) {
        this._chart_type = chart_type;
    };

    get chart_type(): ChartType {
        return this._chart_type;
    }

    targetSeriesFilter: KeyMap<any[]> = {};
    expanded: Array<boolean>;

    sample_periods: any[];
    ranges: any[];
    public hint: string;

    public readonly axes = ['y', 'y2'];
    public readonly chart_type_dict = ChartTypeSeriesChartTypeDict;

    public series_name_dict = {};

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private seriesData: SeriesDataService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    matSelectCompare(option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    ngOnInit() {
        const ctrl = this;
        this.expanded = new Array(50).fill(false);

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            ctrl.sample_periods = utils.deepCopy(ctrl.dateTimePeriodService.sample_periods);
            ctrl.sample_periods = ctrl.sample_periods.filter(period => {
                return ["day", "month", "hour", "week"].includes(period.name)
            });

            ctrl.ranges = utils.deepCopy(ctrl.dateTimePeriodService.ranges);
            this.changeDetectorRef.markForCheck();
        });

        if (ctrl.series_list?.length > 0) {
            this.getInitValues();
        } else {
            this.series_name_dict = {};
        }

        this.changeDetectorRef.markForCheck();
    }

    getInitValues() {
        this.seriesData.getSeriesLightListByName(this.series_list.map(s => s.name)).pipe(
            first(),
            takeUntil(this.onDestroy)
        ).subscribe(result => {
            result.data.map(s => this.series_name_dict[s.attributes.name] = s);
            this.changeDetectorRef.markForCheck();
        });
    }

    addNameToConfig(series_config: ChartSeriesConfiguration, event): void {
        this.series_name_dict[event.attributes.name] = event;
        series_config.name = event.attributes.name;
        series_config.id = event.id;
        this.updateTargetFilters();
        this.changeDetectorRef.markForCheck();
    }

    addTargetToConfig(series_config: ChartSeriesConfiguration, event: Series): void {
        series_config.target_series = Object.assign(series_config.target_series || {}, new ConfigStub<Series>(event));
    }

    addColourToTarget(series_config: ChartSeriesConfiguration, event: string): void {
        series_config.target_series.actual_below = event;
    }

    updateTargetFilters(): void {
        this.series_list.forEach(s => {
            if (s.type === 'budget') {
                const sId = this.series_name_dict[s.name].id
                this.targetSeriesFilter[sId] = [getManyRelationWithIdFilter('source_series', sId)];
            }
        })

    }

    removeRow(index): void {
        const ctrl = this;
        let removed = ctrl.series_list.splice(index, 1);

        ctrl.series_listChange.emit(ctrl.series_list);
        this.changeDetectorRef.markForCheck();
    }

    changeRange(event, config_i, series_config): void {
        let ctrl = this;
        ctrl.series_list[config_i].sample_period = utils.deepCopy(ctrl.dateTimePeriodService.range_dict[event.value]?.sample_period);
        series_config.sample_period = utils.deepCopy(ctrl.dateTimePeriodService.range_dict[event.value]?.sample_period);

        ctrl.series_listChange.emit(ctrl.series_list);
        this.changeDetectorRef.markForCheck();
    }

    checkPeriod(event, config_i): void {
        console.warn("checkPeriod not implemented");
        console.log('CustomChart - checkPeriod: ', event, config_i);
    }

    addChartSeries(): void {
        const add_config = GenericChartTileConfiguration.newCustomChartSeriesConfig(this.chart_type);
        this.series_list.push(add_config);
        this.series_listChange.emit(this.series_list);
        this.changeDetectorRef.markForCheck();
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
