<div class="report-builder-form">
  <div class="row">
    <select-search-component
        (selectionChange)="onComponentTypeSelectionChange($event)"
        [api_model]="apiModel"
        [value]="config.selectedComponentType"
        [classes]="'fill'"
        class="select-search"
        label="Select Component Type">
    </select-search-component>
    <select-many-search-component
        (selectionChange)="onComponentRelationshipChanged($event)"
        [api_model]="apiModel"
        [custom_filters]="componentRelationshipFilters"
        [value]="config.selectedComponentRelationshipTypes"
        [classes]="'fill'"
        class="select-search"
        label="Select Component relationship types">
    </select-many-search-component>
  </div>
  <ng-container *ngIf="config.selectedComponentType?.id">
    <!--Component Type Property Columns-------------------------------------------------------------------------------->
    <ng-container *ngIf="isComponentTypeLoading$ | async; else columnSelector">
      <div class="loading-container">
        <div class="loading-text">Loading Component Type Columns
          <span class="dots"></span>
        </div>
      </div>
    </ng-container>
    <ng-template #columnSelector>
      <h3>
      <mat-icon class="mat-icon mat-icon-no-color" aria-hidden="true"
                (click)="toggleComponentTypeCollapsed()"
                [matTooltip]="isComponentTypeCollapsed ? 'Expand Column Selector' : 'Collapse Column Selector'">{{isComponentTypeCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}}
      </mat-icon>
      Select the Component Type Property columns </h3>
      <div class="sliding-container" [class.collapsed]="isComponentTypeCollapsed">
        <column-selector
            [availableColumns]="availableColumns"
            [selectedColumns]="config.selectedColumns.componentType || []"
            [modelDict]="constantPropertyDict"
            [selectedFilters]="getFilters()"
            (columnsChanged)="columnsChanged($event)"
            (filtersValueChanged)="onFiltersChange($event)"
            (aliasValueChanged)="onPropertyAliasChange($event)">
        </column-selector>
      </div>
    </ng-template>
    <!--Component Relationship Property Columns-------------------------------------------------------------------------------->
    <ng-container *ngIf="isRelationshipLoading$ | async; else columnSelectorRelationship">
      <div class="loading-container">
        <div class="loading-text">Loading Relationship Columns
          <span class="dots"></span>
        </div>
      </div>
    </ng-container>
    <ng-template #columnSelectorRelationship>
      <h3>
      <mat-icon class="mat-icon mat-icon-no-color" aria-hidden="true"
                (click)="isComponentRelationshipTypeCollapsed=!isComponentRelationshipTypeCollapsed;"
                [matTooltip]="isComponentRelationshipTypeCollapsed ? 'Expand Column Selector' : 'Collapse Column Selector'">{{isComponentRelationshipTypeCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}}
      </mat-icon>
      Select the Component Relationship Type Property columns </h3>

      <mat-tab-group [disableRipple]="true" class="report-builder-form-tabs"
                     *ngIf="config.selectedComponentRelationshipTypes">
        <ng-container *ngFor="let componentType of config.selectedComponentRelationshipTypes">
          <mat-tab [label]="componentType.attributes.name">
            <column-selector
                [availableColumns]="availableComponentColumns[componentType.id]"
                [selectedColumns]="config.selectedColumns.component[componentType.id] || []"
                [modelDict]="constantPropertyDict"
                [selectedFilters]="getFilters(componentType.id)"
                (columnsChanged)="columnsChanged($event, componentType.id)"
                (filtersValueChanged)="onFiltersChange($event, componentType.id)"
                (aliasValueChanged)="onPropertyAliasChange($event, componentType.id)"
            ></column-selector>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-template>
  </ng-container>
</div>
