<div #filterDialogContainer class="filter-builder-dialog query-builder">
  <h1 mat-dialog-title
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle>Filters</h1>
  <mat-dialog-content>
    <query-builder-form class="form-dialog "
                        [isFilterBuilder]="true"
                        [filter_constant_properties]="constant_properties"
                        [filter_cp_dict]="cp_dict"
                        [filter_component_type]="component_type"
                        [filter_event_type_component_types]="component_types"
                        [filter_event_types]="event_types"
                        [selected_cols_dict]="selected_cols_dict"
                        [query]="filterQuery"
                        (queryChange)="onQueryChange($event)">
    </query-builder-form>
    <button class="btn-close text-btn clear-filter" mat-button (click)="clearFilter()" [disableRipple]="true">
      Clear filter
    </button>
  </mat-dialog-content>

  <div class="action-buttons">
    <button class="btn-close" (click)="onClose()">Close</button>
    <button class="btn-save" [mat-dialog-close]="{query: filterData.query, refresh:true}" [disabled]="applyDisabled">
      Apply
    </button>
  </div>
</div>
