import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Papa} from "ngx-papaparse";
import {ModelUploadOptions} from "../upload-model-form-dialog.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ApiService} from "../../../services/api/api.service";
import {first, takeUntil, tap} from "rxjs/operators";
import {combineLatest, ReplaySubject, Subject} from "rxjs";
import {OreBodyType} from "../../../_models/ore-body-type";

export class OreBodyModelOptions extends ModelUploadOptions {
    group_types: string[][] = [];
    property_headers: string[][] = [];
    point_headers: string[] = null;
    // header that lists the ore body name
    ore_body_header: string;
    // optional header that lists the associated direct ore body type for this ore body
    ore_body_type_header: string;
    /*
     ore body type to use for new resources if no ore_body_type_header is given or to use for parent ore body type
     for new ore body types created from ore_body_type_header
    */
    default_ore_body_type: string;
    skip_rows: number = 0;
    use_column_indexes: boolean = false;
}

class HeaderType {
    // NOTE: renaming any of these values will break templates that use them
    // TODO add order and constant key with description to these.

    // Header keys

    // single use headers
    public readonly POINT_X: string = 'Point X';
    public readonly POINT_Y: string = 'Point Y';
    public readonly POINT_Z: string = 'Point Z';
    public readonly ORE_BODY_NAME: string = '*Ore Body Name';
    public readonly ORE_BODY_TYPE: string = 'Ore Body Type';

    // multiple use headers
    public readonly UNUSED: string = 'Ignore this column';
    public readonly GROUP: string = '*Ore Body Group';
    public readonly PROPERTY: string = 'Property';

    public static descriptions(): { [key: string]: string } {
        const ht = new HeaderType();
        const d: any = {};
        d[ht.POINT_X] = 'Point X';
        d[ht.POINT_Y] = 'Point Y';
        d[ht.POINT_Z] = 'Point Z';
        d[ht.ORE_BODY_NAME] = '*Ore Body Name';
        d[ht.ORE_BODY_TYPE] = 'Ore Body Type';
        d[ht.UNUSED] = 'Ignore this column';
        d[ht.GROUP] = '*Ore Body Group';
        d[ht.PROPERTY] = 'Property';
        return d;
    }
}

export class HeaderAssociation {
    source: string;
    target_type: string;
    override_id: string;
    available_options: string[];
}

/**
 * All mutations should change the current options object, either directly or by first mutating the header associations
 * and then updating the options from the header associations.
 */
@Component({
    selector: 'extract-ore-body-options',
    templateUrl: './extract-ore-body-options.component.html',
    styleUrls: ['./extract-ore-body-options.component.less'],
    standalone: false
})
export class ExtractOreBodyOptionsComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    // instantiate this constant class rather make properties static to make it work in the template.
    public readonly HEADER_TYPES = new HeaderType();
    public readonly POINT = [
        this.HEADER_TYPES.POINT_X,
        this.HEADER_TYPES.POINT_Y,
        this.HEADER_TYPES.POINT_Z];

    // headers that may at most have one association
    readonly SINGLE_HEADERS: string[] = [
        this.HEADER_TYPES.POINT_X,
        this.HEADER_TYPES.POINT_Y,
        this.HEADER_TYPES.POINT_Z,
        this.HEADER_TYPES.ORE_BODY_NAME,
        this.HEADER_TYPES.ORE_BODY_TYPE,
    ];

    // headers that can have multiple column associations
    readonly MULTI_HEADERS = [
        this.HEADER_TYPES.UNUSED,
        this.HEADER_TYPES.GROUP,
        this.HEADER_TYPES.PROPERTY,
    ];

    readonly DESCRIPTIONS: { [key: string]: string } = HeaderType.descriptions();

    header_associations: HeaderAssociation[] = [];
    had_file: boolean = null;
    // List of available Constant Properties. Used for add overrides
    constant_properties: { id: string, name: string }[] = [];
    property_data_schema = ['name'];
    // actions still required to make configuration valid
    required_configurations: string[] = [];

    readonly UNUSED_ID = '__unused__';
    ore_body_types: OreBodyType[];

    error: string = '';

    // current options state, only used for displaying values in template
    temp_options: OreBodyModelOptions = new OreBodyModelOptions();

    // subject containing data from the current file
    fileDataSubject: Subject<string[][]> = new ReplaySubject(1);

    // the collections of properties to be used to parse the given file
    optionsSubject: Subject<OreBodyModelOptions> = new ReplaySubject(1);

    @Input()
    set options(value: ModelUploadOptions) {
        if (value) {
            this.optionsSubject.next(value as OreBodyModelOptions);
        }
    }

    @Output() optionsChange: EventEmitter<OreBodyModelOptions> = new EventEmitter();

    // List of available OreBodyGroupTypes.
    // these two properties are used for changing the order of select Ore Body Group Type
    ore_body_group_types: { id: string, name: string }[] = [];
    ore_body_group_type_ids: { [id: string]: string } = {};

    @Output()
    preview: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    set file(file) {
        if (!file) {
            this.optionsSubject.next(new OreBodyModelOptions());
            this.had_file = false;
            return;
        }

        this.papa.parse(file, {
            preview: 50,
            complete: results => {
                try {
                    let data = results.data;
                    const previewData = data.slice(0, 5);
                    this.preview.next(previewData);
                    this.fileDataSubject.next(data);
                    this.had_file = true;
                } catch {
                    this.preview.next(null);
                    this.error = 'Failed to parse the file. Please provide a valid csv file.';
                }
            },
            error: error => {
                console.warn('error parsing file\n', error);
            }
        });
    }

    constructor(private papa: Papa,
                private api: ApiService,
    ) {
        this.optionsSubject.next(new OreBodyModelOptions());
    }

    ngOnInit(): void {
        const $s = [];

        $s.push(this.optionsSubject);
        $s.push(this.fileDataSubject);

        $s.push(this.api.constant_property_light.searchMany().pipe(tap(response => {
            this.constant_properties = response.data.map(r => {
                return {id: r.id, name: r.attributes.name};
            });
            this.constant_properties.unshift({id: this.UNUSED_ID, name: 'Create if needed'});
        })));

        $s.push(this.api.ore_body_group_type.searchMany().pipe(tap(response => {
            this.ore_body_group_types = response.data.map(r => {
                return {id: r.id, name: r.attributes.name};
            });
            this.ore_body_group_type_ids = {};
            this.ore_body_group_types.forEach(t => this.ore_body_group_type_ids[t.id] = t.name);
        })));

        combineLatest($s).pipe(takeUntil(this.onDestroy)).subscribe((r: any) => {
            const options: OreBodyModelOptions = r[0] as OreBodyModelOptions;
            let file_data: string[][] = r[1] as string[][];

            if (options.skip_rows) {
                file_data = file_data.slice(options.skip_rows);
            }
            let headers: string[];
            if (options.use_column_indexes) {
                // generate the index headers from the max row length
                const max_row = file_data.reduce((max, curr) => max > curr.length ? max : curr.length, 0);
                headers = Array.from(Array(max_row).keys()).map(i => '' + i);
            } else {
                // extract the name headers from the file data
                headers = file_data[0];
            }
            if (this.header_associations.length === 0) {
                // generate HeaderAssociations from the given options and column headers
                this.header_associations = this.generateHeaderAssociations(options, headers);
                this.refreshAvailableOptions(this.header_associations);
            }
            this.temp_options = options;
            if (this.isOptionsValid(options)) {
                this.optionsChange.next(options);
            } else {
                this.optionsChange.next(null);
            }
        });

        this.api.ore_body_type.searchMany().pipe(takeUntil(this.onDestroy)).subscribe(response => {
            this.ore_body_types = response.data;
        });

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    private generateHeaderAssociations(options: OreBodyModelOptions, headers: string[]): HeaderAssociation[] {
        // first generate unassociated headers
        const header_associations: HeaderAssociation[] = headers.map(header => {
            return {
                source: header.trim(),
                target_type: this.HEADER_TYPES.UNUSED,
                available_options: this.MULTI_HEADERS.concat(this.SINGLE_HEADERS).sort(),
                override_id: this.UNUSED_ID,
            };
        });

        // then map associations based on options
        if (options.group_types) {
            options.group_types.forEach(gt => {
                const header = header_associations.find(h => h.source == gt[0]);
                if (header) {
                    header.target_type = this.HEADER_TYPES.GROUP;
                    header.override_id = gt[1];
                }
            });
        }
        if (options.property_headers) {
            options.property_headers.forEach(ph => {
                // ph either [source, id] or [source]
                const header = header_associations.find(h => h.source == ph[0]);
                if (header) {
                    header.target_type = this.HEADER_TYPES.PROPERTY;
                    if (ph[1]) {
                        header.override_id = ph[1];
                    }
                }
            });
        }
        if (options.ore_body_header) {
            const header = header_associations.find(h => h.source == options.ore_body_header);
            if (header) {
                header.target_type = this.HEADER_TYPES.ORE_BODY_NAME;
            }
        }
        if (options.ore_body_type_header) {
            const header = header_associations.find(h => h.source == options.ore_body_type_header);
            if (header) {
                header.target_type = this.HEADER_TYPES.ORE_BODY_TYPE;
            }
        }
        if (options.point_headers) {
            options.point_headers.forEach((ph, i) => {
                const header = header_associations.find(h => h.source == ph);
                if (header) {
                    header.target_type = this.POINT[i];
                }
            });
        }

        return header_associations;
    }

    /**
     * Refresh the options that is available to each header association.
     */
    private refreshAvailableOptions(header_associations: HeaderAssociation[]) {
        const all_current_assigned = new Set(header_associations.map(h => h.target_type));
        const remaining_single_headers = this.SINGLE_HEADERS.filter(h => !all_current_assigned.has(h));
        // update available options relative to each header
        header_associations.forEach(h => {
            h.available_options = Array.from(new Set(this.MULTI_HEADERS.concat(remaining_single_headers).concat([h.target_type]))).sort();
        });
    }

    /**
     * Evaluate if the options is valid and emit the null or valid options to optionsChange.
     * @param options
     */
    private isOptionsValid(options: OreBodyModelOptions): boolean {
        let had_no_points = options.point_headers === null;
        let had_all_points = options.point_headers && options.point_headers.length == 3 &&
            options.point_headers.every(p => p != null);

        let had_valid_points = had_no_points || had_all_points;
        let had_ore_body_name_header = options.ore_body_header != null;
        let had_min_ore_body_groups = options.group_types && options.group_types.length >= 1 &&
            options.group_types.every(gt => {
                return gt[1] != this.UNUSED_ID;
            });
        let had_default_ore_body_type = options.default_ore_body_type != null;

        this.required_configurations = [];

        if (had_default_ore_body_type && had_ore_body_name_header && had_valid_points && options.group_types.length > 0) {
            return true;
        } else {
            if (!had_ore_body_name_header) {
                this.required_configurations.push('Associate Ore Body Name to a column');
            }
            if (!had_min_ore_body_groups) {
                this.required_configurations.push('Associate at least one column to an Ore Body Group');
            }
            if (!had_default_ore_body_type) {
                this.required_configurations.push('Choose a default Ore Body Type');
            }
            if (!had_valid_points) {
                this.required_configurations.push('Associate all 3 coordinate (X,Y,Z) columns or none');
            }
            console.log('had invalid options', options);
            return false;
        }
    }

    /**
     * Generate the model options from this header associations
     */
    private updateTempOptionsFromHeaderAssociations(header_associations: HeaderAssociation[], options: OreBodyModelOptions) {
        const all_current_assigned = new Set(header_associations.map(h => h.target_type));

        // extract group headers
        // TODO only overwrite the existing group headers if their string set is different
        const group_headers = header_associations.filter(h => {
            return h.target_type == this.HEADER_TYPES.GROUP;
        }).map(h => [h.source, h.override_id]);
        // TODO only overwrite if the groups in the set has changed
        options.group_types = group_headers;

        // extract the ore body name header
        const had_ore_body_name = all_current_assigned.has(this.HEADER_TYPES.ORE_BODY_NAME);
        if (had_ore_body_name) {
            options.ore_body_header = header_associations
                .find(h => h.target_type == this.HEADER_TYPES.ORE_BODY_NAME).source;
        }

        /*
        * extract point headers
        * - check if have all 3 properties selected
        *   - get unique set of all types
        *   - check if length is 3
        * */
        const point_headers = [this.HEADER_TYPES.POINT_X, this.HEADER_TYPES.POINT_Y, this.HEADER_TYPES.POINT_Z];
        const had_no_points = point_headers.every(h => !all_current_assigned.has(h));

        if (had_no_points) {
            options.point_headers = null;
        } else {
            options.point_headers = point_headers.map(ph => {
                const h = header_associations.find(h => h.target_type == ph);
                return h ? h.source : null;
            });
        }

        // extract property headers and optional overrides
        const property_headers = this.header_associations.filter(h => {
            return h.target_type == this.HEADER_TYPES.PROPERTY;
        }).map(h => {
            if (h.override_id != this.UNUSED_ID) {
                return [h.source, h.override_id];
            } else {
                return [h.source];
            }
        });
        options.property_headers = property_headers;

        // extract optional ore body type header
        const ore_body_type_header = this.header_associations.find(h => {
            return h.target_type == this.HEADER_TYPES.ORE_BODY_TYPE;
        });
        if (ore_body_type_header) {
            options.ore_body_type_header = ore_body_type_header.source;
        } else {
            options.ore_body_type_header = null;
        }
    }

    groupListDrop(event: CdkDragDrop<string[]>) {
        this.optionsSubject.pipe(first()).subscribe(options => {
            moveItemInArray(options.group_types, event.previousIndex, event.currentIndex);
            this.optionsSubject.next(options);
        });
    }

    /* Select search help method */
    property_string_function = (value) => {
        return value.name;
    }

    /* Select search help method */
    compareValueIds = (a: any, b: HeaderAssociation) => {
        try {
            return a.id == b.override_id;
        } catch {
            return a == b;
        }
    }

    selectValueFunction = (obj) => {
        return obj.id;
    }

    /* Select search help method */
    setPropertyOverride(header: HeaderAssociation, change) {
        if (change.value.id === this.UNUSED_ID) {
            header.override_id = this.UNUSED_ID;
        } else {
            header.override_id = change.value.id;
        }
        if (change.value.id !== header.override_id) {
            this.updateHeaders();
        }
    }

    setGroupType(header: HeaderAssociation, group_type_id: string) {
        header.override_id = group_type_id;
        this.updateHeaders();
    }

    setUseColumnIndexes(use_column_indexes: boolean) {
        this.optionsSubject.pipe(first()).subscribe(options => {
            options.use_column_indexes = !!use_column_indexes;
            this.optionsSubject.next(options);
        });
    }

    setSkipRows(skip_rows: number) {
        this.optionsSubject.pipe(first()).subscribe(options => {
            options.skip_rows = skip_rows;
            this.optionsSubject.next(options);
        });
    }

    setDefaultOreBodyType(obt_name: string) {
        this.optionsSubject.pipe(first()).subscribe(options => {
            options.default_ore_body_type = obt_name;
            this.optionsSubject.next(options);
        });
    }

    /**
     * Update options if some of the header_associations have changed.
     */
    updateHeaders() {
        this.optionsSubject.pipe(first()).subscribe(options => {
            this.updateTempOptionsFromHeaderAssociations(this.header_associations, options);
            this.optionsSubject.next(options);
        });
    }
}
