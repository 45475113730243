<div class="row center">
  <mat-form-field>
    <mat-label>Chart Title</mat-label>
    <input matInput [(ngModel)]="config.labels.title" style="box-sizing: border-box;">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Y Axis Title</mat-label>
    <input matInput [(ngModel)]="config.labels.y_axis" style="box-sizing: border-box;">
  </mat-form-field>

</div>
<div class="row center">
  <mat-form-field>
    <mat-label style="width:50px;">Y Min:</mat-label>
    <input [(ngModel)]="config.y_min" matInput style="box-sizing: border-box;">
  </mat-form-field>
  <mat-form-field>
    <mat-label style="width:50px;">Y Max:</mat-label>
    <input [(ngModel)]="config.y_max" matInput style="box-sizing: border-box;">
  </mat-form-field>
</div>
<div class="row medium-height">
  <mat-checkbox [(ngModel)]="config.group_series" class="spacer">Group data</mat-checkbox>
  <mat-checkbox [(ngModel)]="config.show_data_label_units" class="spacer">Show data label units</mat-checkbox>
  <mat-checkbox [(ngModel)]="config.show_flux_label_units" class="spacer">Show flux units</mat-checkbox>
</div>

<div class="row center" style="justify-content: space-between;">
  <div class="row center spacer" *ngFor="let key of style_keys">
    <label style="margin-right:10px;">{{key | removeUnderscore | upperfirst}}: </label>
    <table-column-menu (formatsChanged)="config.styles[key]=$event"
                       [item]="config.styles[key]"
                       [options]="col_menu_options"
                       class="inline">
    </table-column-menu>
  </div>
</div>

<div class="row center height-spacer" style="justify-content: space-between;">
  <div class="row center spacer" *ngFor="let item of colour_keys | keyvalue">
    <label style="margin-right:10px;">{{item.value}}</label>
    <colour-palette [(value)]="config[item.key]"
                    (selectChange)="changeColour($event, item.key)"
                    palette="2"
                    show_text="true"
                    [use_picker]="true">
    </colour-palette>
  </div>
  <div class="spacer"></div>
</div>

<div class="row">
  <select-many-series
      [value]="selected_series"
      [classes]="'fill'"
      (selectionChange)="updateSeriesConfig($event);"
      class="select-search">
  </select-many-series>
</div>

<div class="custom-chart-series-table">
<h3>Drag to reorder series</h3>
<div #selectedList="cdkDropList"
     (cdkDropListDropped)="drop($event)"
     [cdkDropListData]="config.series_list"
     cdkDropList
     class="draggable-list">

  <table class="simple-table waterfall-chart-form-table">
    <thead>
    <tr style="text-align: left;">
      <th style="padding:10px;">Series</th>
      <th style="padding:10px;">Direction</th>
      <th style="padding:10px;">Label</th>
      <th style="padding:10px;">Alias</th>
      <th style="padding:10px;">Group</th>
      <th style="padding:10px;">Data Labels</th>
      <th style="padding:10px;">Flux</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let series_config of config.series_list" cdkDrag
        class="waterfall-chart-form-row">
      <td>
        <div [dblClickCopy]="series_config.series.attributes.name"
             style="font-weight:600;">{{series_config.series.attributes | nameOrDescription}}
        </div>
      </td>
      <td>
        <mat-form-field class="no-label">
          <mat-select [(ngModel)]="series_config.measure" placeholder="Measure" >
            <mat-option [value]="'relative'">Relative</mat-option>
            <mat-option [value]="'total'">Total</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <input matInput [(ngModel)]="series_config.custom_legend_name"
               [placeholder]="series_config.series.attributes | nameOrDescription"
               [disabled]="series_config.use_alias===true"
               style="margin-right:20px;box-sizing:border-box;"/>
      </td>
      <td style="width:80px;">
        <mat-checkbox [(ngModel)]="series_config.use_alias"
                      [disabled]="series_config.custom_legend_name?.length > 0"
                      style="min-width:unset;">Use alias
        </mat-checkbox>
      </td>
      <td style="">
        <input matInput [(ngModel)]="series_config.group_name"
               style="margin-right:20px;box-sizing:border-box;"/>
      </td>
      <td style="width:80px;">
        <mat-checkbox [(ngModel)]="series_config.show_data_labels"
                      style="min-width:unset;">Show
        </mat-checkbox>

        </td>
        <td style="width:80px;">
          <mat-checkbox [(ngModel)]="series_config.show_flux_labels"
                        style="min-width:unset;">Show
          </mat-checkbox>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row center" style="font-weight: bold;">

    </div>
  </div>
</div>
