import {Directive, HostListener, Renderer2, Input, ChangeDetectorRef} from '@angular/core';
import {QueryList, ElementRef} from '@angular/core';

@Directive({
    selector: '[appResizable]',
    standalone: false
})
export class ResizableDirective {
    private resizing = false;
    private resizable = false

    @Input('appResizable') panels: QueryList<ElementRef>;

    constructor(private renderer: Renderer2) {
    }

    @HostListener('mousedown', ['$event'])
    onMousedown(event: MouseEvent) {
        const rect = this.panels?.last.nativeElement.getBoundingClientRect();
        if (rect && (event.clientX - rect.x < 10 && rect.x - event.clientX > -10) && event.button === 0) {
            const p = this.panels?.last.nativeElement.parentElement;
            this.renderer.setStyle(p, 'width', '100vw');
            this.resizing = true;
        }
    }

    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        if (this.resizing) {
            this.panels.forEach((panel: ElementRef) => {
                this.renderer.setStyle(panel.nativeElement, 'width', `${window.innerWidth - event.clientX}px`);
            });
        }
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseup(event: MouseEvent) {
        this.resizing = false;
        const p = this.panels?.last.nativeElement.parentElement;
        this.renderer.setStyle(p, 'width', 'auto');
        event.stopPropagation()
    }
}
