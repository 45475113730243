<header *ngIf="appScope.isAuthenticated$ | async" class="toolbar-header">

  <ng-container *ngIf="headerData.show_dtp">
    <date-time-period class="hideonprint date-period"></date-time-period>
  </ng-container>

  <div [class.no-dtp]="!headerData.show_dtp" class="page-title row">
    <h1>{{headerData.titleSubject | async}}</h1>

    <div class="buttons row">
      <ng-container *ngIf="appScope.isNotMobile">
        <button #button mat-button [matMenuTriggerFor]="pageMenu" #pageMenuTrigger="matMenuTrigger" class="icon-menu"
                style="z-index:1050" matTooltip="Click to view page menu">
          <i class="fas fa-bars" style="font-size: 20px;"></i>
        </button>

        <mat-menu #pageMenu="matMenu" backdropClass="header-menu-backdrop" [hidden]="headerData.buttons.length <= 0"
                  class="tree-node-menu dark">
          <ng-container *ngFor="let button of headerData.buttons">
            <button (click)="button.func(button.params)" *ngIf="!button.toggle"
                    [textContent]="button.name" mat-menu-item>
            </button>
            <mat-slide-toggle (change)="button.func(button.params)" *ngIf="button.toggle"
                              [checked]="button.params.value"
                              class="mat-mdc-menu-item" labelPosition="before">{{button.name}}</mat-slide-toggle>
          </ng-container>

          <ng-container *ngIf="!restricted_access">

          <button (click)="openEditForm()" *ngIf="headerData.add_edit" class="hide-xs" mat-menu-item>
            Edit History
          </button>
        </ng-container>

        <mat-slide-toggle (change)="togglePresent()" *ngIf="headerData.add_present"
                          class="mat-mdc-menu-item" labelPosition="before">Toggle Presentation Mode
        </mat-slide-toggle>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="!restricted_access">

        <button (click)="printToPdf()" *ngIf="headerData.add_print" class="hide-xs"
                style="color:white !important;padding-left:10px;"
                mat-button matTooltip="Print to PDF"><i class="fas fa-print"
                                                        style="font-size: 18px;padding-left:0;"></i>
        </button>

        <button (click)="uploadData()" *ngIf="headerData.add_upload" class="icon-file-upload"
                mat-button matTooltip="Upload">
          <mat-icon>cloud_upload</mat-icon>
        </button>

        <button (click)="pageCommentButtonClicked()" *ngIf="headerData.add_comment"
                class="icon-comment-invert hideonprint"
                [matTooltip]="headerData.showing_comments && headerData.page_selected=== true ? 'This page is selected for commenting.':'Add comment to page.'"
                [ngClass]="{'show-drawer': headerData.showing_comments && headerData.page_selected === true}">
        </button>

      </ng-container>

    </div>
  </div>

</header>
