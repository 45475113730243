<table class="simple-table">
  <thead>
  <tr>
    <th>Formula</th>
    <th *ngIf="!isEmpty(nameFormulas)">Delete</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let formula of nameFormulas | keyvalue;" [ngClass]="{'selected-formula':formula.key===selectedKey}">
    <td class="select-formula" matTooltip="Click to select" (click)="selectFormula(formula.key)">{{ nameString(formula.value) }}</td>
    <td class="delete-formula">
      <button class="text-btn" (click)="removeItem(formula.key)"><i class="fa fa-times"></i></button>
    </td>
  </tr>
  <tr *ngIf="isEmpty(nameFormulas)">
    <td>No formulas to show. Start adding formula parts to create a name generating formula.</td>
  </tr>
  </tbody>
</table>
