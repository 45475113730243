<div class="upload-model-container form-dialog">
  <div class="column">
    <div class="column" *ngIf="error_json" style="width:100%;">
      <h4>Errors</h4>
      <div style="" class="upload-model-errors">
        <pre [innerHTML]="error_json"></pre>
      </div>
      <br>
    </div>

    <div class="row" *ngIf="config.selected_templates?.length > 0">
      <mat-form-field class="select-template">
        <mat-label>Select template</mat-label>
        <mat-select [(ngModel)]="selected_template"
                    (selectionChange)="templateSelected($event.value)"
                    name="selected_templates"
                    [compareWith]="compareById">
          <mat-option *ngFor="let template of config.selected_templates" [value]="template">
            {{template.attributes.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <drop-file-selector [file]="file"
                        (selectionChanged)="fileSelected($event)">
    </drop-file-selector>

    <div class="column center" style="margin-top:10px;">
      <div class="info">Requirements</div>
      <div>File type: CSV UTF-8 (Comma delimited) (*.csv)</div>
      <div>Date format: YYYY/MM/DD</div>
      <div>First row of the CSV to be the header row</div>
    </div>

    <div class="row auto inline preview-table-container" *ngIf="preview">
      <h4>Preview</h4>
      <div>
        <table class="preview-table">
          <tr *ngFor="let r of preview">
            <td *ngFor="let c of r" style="white-space: nowrap;">{{c}}</td>
          </tr>
        </table>
      </div>
      <br>
    </div>

    <!--These components are hidden only so that they can extract file data and match template-->
    <h3 [hidden]="!(config.model_selected && file)" style="align-self: start;cursor:pointer;"
        (click)="show_options = !show_options;">
      {{show_options ? 'Hide ' : 'Show '}}options
      <i class="fa" [ngClass]="show_options ? 'fa-caret-up':'fa-caret-down'"
         style="margin-left:10px;font-size:1.4rem;"
         matTooltip="Toggle options"></i>
    </h3>
    <div [hidden]="!show_options" class="options-container">
      <extract-event-options [hidden]="false"
                             [readonly]="true"
                             *ngIf="config.model_selected.parser_type==='event'"
                             [file]="file"
                             (preview)="preview=$event"
      ></extract-event-options>
      <extract-component-options [hidden]="false"
                                 [readonly]="true"
                                 *ngIf="config.model_selected.parser_type==='component' && file"
                                 [file]="file"
                                 (preview)="preview=$event"
      ></extract-component-options>
    </div>
    <!--End hidden-->

    <div class="row center justify-center">
      <button mat-raised-button
              (click)="uploadData()"
              [disabled]="!file || !selected_template || uploading"
              color="primary"
      >Upload
      </button>
      <button mat-stroked-button
              (click)="clearTile()"
              [disabled]="!file && !selected_template && uploading"

      >Clear
      </button>
    </div>
  </div>
</div>
