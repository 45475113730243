import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppScope} from "../../services/app_scope.service";
import {NotificationService} from "../../services/notification.service";

export interface IColumnFormatsConfig {
    bold: boolean;
    italic: boolean;
    abbreviate: boolean;
    size: number;
    resize: boolean;
    fit_content: boolean;
    width: number | 'auto';
    colour: string;
    background_colour: string;
    underline: boolean;
    strikethrough: boolean;
    aggregation_type: string;
    align: string;
    decimals: number;
}

export type ColumnFormatsConfig = Partial<IColumnFormatsConfig>;
export type ColumnFormatsConfigDict = Record<string, ColumnFormatsConfig>;

@Component({
    selector: 'table-column-menu',
    templateUrl: './table-column-menu.component.html',
    styleUrls: ['./table-column-menu.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TableColumnMenuComponent implements OnInit, OnChanges {
    @Input() item: ColumnFormatsConfig;
    @Input() options: string[] = ['bold', 'italic', 'abbreviate', 'size', 'resize', 'width', 'colour', 'align']; // Defaults
    @Output() formatsChanged: EventEmitter<ColumnFormatsConfig> = new EventEmitter();
    @Input() default_values: ColumnFormatsConfigDict;
    preset_colours: string[];
    rows: Record<number, boolean> = {1: false, 2: false, 3: false};

    formats: ColumnFormatsConfig;

    constructor(private notification: NotificationService,
                private appScope: AppScope) {
    }

    showNestedMenu(menu: MatMenuTrigger) {
        menu.openMenu();
    }

    ngOnInit(): void {
        if (!this.item || !Object.keys(this.item).length) {
            this.item = this.defaultConfig();
            this.formatChanged();
        }

        this.preset_colours = this.appScope.config_name_map?.palette1?.value?.map(item => item.colour) || [];

        this.rows[1] = this.options.some(x => ['bold', 'italic', 'fit_content', 'size', 'resize', 'width', 'colour', 'background_colour',
            'underline', 'strikethrough', 'aggregations', 'decimals'].includes(x));
        this.rows[2] = this.options.some(x => ['align', 'size', 'abbreviate'].includes(x));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.item) {
            this.item = this.defaultConfig();
        }
    }

    defaultConfig() {
        let config = {};
        this.options.forEach(opt => {
            config[opt] = undefined;
            if (['bold', 'italic', 'abbreviate', 'resize', 'fit_content', 'underline', 'strikethrough'].includes(opt)) {
                config[opt] = false;
            }
            if (opt === 'size') {
                config[opt] = 100;
            }
            if (opt === 'aggregation_type') {
                config[opt] = 'total';
            }
            if (this.default_values && this.default_values[opt]) {
                config[opt] = this.default_values[opt];
            }
        });
        return config;
    }

    formatColumn(prop) {
        if (['bold', 'italic', 'abbreviate', 'resize', 'fit_content', 'underline', 'strikethrough'].includes(prop)) {
            this.item[prop] = !this.item[prop];
        }
        if ((prop === 'fit_content' || prop === 'resize') && !this.item[prop]) {
            this.item.width = null;
        }

        if (prop === 'colour' || prop === 'background_colour') {
            if (this.item.hasOwnProperty(prop) && this.item[prop]) {
                this.item[prop] = undefined;
            } else {
                this.item[prop] = 'black'; // initial colour
            }
        }
        if (!this.item['size']) {
            this.item['size'] = 100;
        }
        if (prop === 'plus') {
            if (this.item['size'] >= 150) {
                this.notification.openError('Upper font size limit reached', 2000);
            } else {
                this.item['size'] = this.item['size'] + 10;
            }
        }
        if (prop === 'minus') {
            if (this.item['size'] <= 70) {
                this.notification.openError('Lower font size limit reached', 2000);
            } else {
                this.item['size'] = this.item['size'] - 10;
            }

        }
        this.formatChanged();
    }

    formatChanged(): void {
        this.formatsChanged.next(this.item);
    }

    setAlignment(alignment) {
        if (this.item['align'] === alignment) {
            delete this.item['align'];
        } else {
            this.item['align'] = alignment;
        }
        this.formatsChanged.emit(this.item);
    }

    setDecimals(item, direction) {
        // Upper and lower limits
        if (!this.item['decimals']) {
            this.item['decimals'] = 0;
        }
        if (this.item['decimals'] === 0 && direction === -1 ||
            this.item['decimals'] === 10 && direction === 1) {
            this.notification.openError('Limit reached.');
            return;
        }
        this.item['decimals'] += direction;
        this.formatsChanged.emit(this.item);
    }
}
