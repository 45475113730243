<mat-tab-group (selectedIndexChange)="initTabDatasource($event)" disableRipple class="dialog-table" mat-stretch-tabs="false">
  <ng-container *ngIf="dataSource && dataSource !== undefined">
    <ng-container *ngFor="let tab of tabs">
      <mat-tab [label]="tab | upperfirst | removeUnderscore">
        <div class="row utils-bar" style="justify-content: space-between">
          <div *ngIf="dataSource[tab]" class="table-filter">
            <input (keyup.enter)="applyFilter(tab, $event.target.value)" placeholder="Search"/>
          </div>

          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="20"
                         length="{{dataSource[tab] ? dataSource[tab].length : 0}}" showFirstLastButtons></mat-paginator>
        </div>

        <div class="table-container">
          <table *ngIf="dataSource[tab]!==undefined" [dataSource]="dataSource[tab]" [matSortActive]="columns[0]"
                 class="data-exceptions-table table-striped" mat-table matSort matSortDirection="desc">
            <ng-container *ngFor="let column of columns;">
              <ng-container [ngSwitch]="true" matColumnDef="{{column}}">

                <!--Name-->
                <ng-container *ngSwitchCase="column==='name'">
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element;" mat-cell
                      style="text-align: left;border:none;cursor:pointer;display: flex;align-items: center;">
                    <!--<i (click)="editComponentType(component_type)" class="edit-inline hide-xs"></i>-->
                    <div [textContent]="element.attributes.name" style="padding-top:2px;"></div>
                  </td>
                </ng-container>

                <!--Alerts-->
                <ng-container *ngSwitchCase="column==='alert'">
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element" class="large-cell" mat-cell style="text-align: left;border:none;">
                    <div [textContent]="alerts_dict[element.relationships['alert']?.id]?.attributes.name"
                         style="cursor:pointer;padding-top:2px;"></div>
                  </td>
                </ng-container>

                <!--Series-->
                <ng-container *ngSwitchCase="column==='series'">
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element" class="large-cell d-flex" mat-cell
                      style="text-align: left;border:none;">
                    <div class="series-column">
                      <i (click)="editSeries(element)" class="edit-inline hide-xs"></i>
                    </div>

                  </td>
                </ng-container>

                <!--Check boxes-->
                <ng-container *ngSwitchCase="column==='is_muted'">
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element;" class="checkbox-cell" mat-cell>
                    <mat-checkbox [(ngModel)]="element.attributes[column]" [disabled]="!can_edit"></mat-checkbox>
                  </td>
                </ng-container>

                <!--Start/End -->
                <ng-container *ngSwitchCase="column==='start' || column==='end'">
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element" mat-cell> {{element.attributes[column] | stringDate}} </td>
                </ng-container>

                <!--Changed on -->
                <ng-container *ngSwitchCase="column==='changed_on'">
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element" mat-cell> {{element.attributes[column] | stringDate}} </td>
                </ng-container>

                <!--Case Else -->
                <ng-container *ngSwitchDefault>
                  <th *matHeaderCellDef mat-header-cell mat-sort-header>{{column | upperfirst | removeUnderscore}}</th>
                  <td *matCellDef="let element" mat-cell> {{element.attributes[column]}} </td>
                </ng-container>
              </ng-container>

            </ng-container>
            <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: columns;" mat-row></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell empty-row-cell" colspan="100%">No data.</td>
            </tr>
          </table>
        </div>

      </mat-tab>
    </ng-container>
  </ng-container>
</mat-tab-group>
