import {interval, Observable, Subject} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {Injectable, OnDestroy} from "@angular/core";
import {switchMap} from "rxjs/operators";
import {HeaderDataService} from "../header_data.service";

export interface ConstantCalculationsStatusResponse {
    is_updating: boolean;
    message: string;
}

@Injectable({
    providedIn: 'root'
})

export class CalcGraphStatusService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    constructor(private http: HttpClient, private headerData: HeaderDataService) {
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getConstantCalculationsStatus(): Observable<ConstantCalculationsStatusResponse> {
        return this.http.get<ConstantCalculationsStatusResponse>('/api/GetConstantCalculationsStatus');
    }
    startPolling(): Observable<ConstantCalculationsStatusResponse>  {

         return interval(5000).pipe(
            switchMap(() => this.getConstantCalculationsStatus())
            );
    }
}
