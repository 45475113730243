import {Component, Input} from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {TileParameters} from '../../services/tile_data.service';
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'print-footer-tile',
    templateUrl: './print-footer-tile.component.html',
    styleUrls: ['./print-footer-tile.component.less'],
    standalone: false
})
export class PrintFooterTileComponent {
    @Input() config: TileParameters;

    constructor(public headerData: HeaderDataService,
                public dateInst: DateTimeInstanceService,
                public appScope: AppScope) {
    }


    currentDate(): Date {
        return new Date();
    }
}
