<div class="custom-snackbar">
  <div class="message" [innerHTML]="message">
  </div>

  <div class="action-class">
    <button mat-raised-button *ngIf="!!data.action" (click)="snackBarRef.dismissWithAction()">{{ data.action }}</button>
    <button mat-flat-button *ngIf="!data.action && !hasDuration" (click)="snackBarRef.dismiss()"
            class="snack-bar-dismiss-button">
      <i class="fa fa-times snack-bar-icon" matTooltip="Close" [matTooltipShowDelay]="2000"></i>
    </button>
  </div>
</div>
