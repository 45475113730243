<div class="custom-html-form">
  <ng-container >
    <div class="info">
       <span *ngIf="!isLegacy">Please use the 'Edit Text' option in this page's edit mode to update the HTML template</span>
       <span *ngIf="isLegacy">Please use the 'Template Editor' tab to update the HTML template</span>
      <span *ngIf="!tileId && !isLegacy">, or click 'Build Report' to build a new context report</span>.
    </div>
  </ng-container>

  <ng-container *ngIf="isLegacy">
    <mat-tab-group [disableRipple]="true" class="custom-html-form-tab-group">
      <mat-tab [label]="'Select series'">
        <series-html-form [(config)]="config" (configChange)="relayConfigChange($event)"
                          [(htmlString)]="config.paragraph_body"></series-html-form>
      </mat-tab>
      <mat-tab [label]="'Select components'">
        <component-report-builder [(config)]="config" (configChange)="relayConfigChange($event)"></component-report-builder>
      </mat-tab>
      <mat-tab [label]="'Select functions'">
        <function-builder [config]="config"></function-builder>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

</div>