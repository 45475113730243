<div class="row">
  <select-many-component-types
      [value]="config.component_types"
      (selectionChange)="simplifyConfig($event)" class="select-many-search-wrapper">
  </select-many-component-types>

  <select-search
      [disabled]="from_selection?.length < 1"
      [(value)]="config.default_type"
      [data]="from_selection || []"
      label="Select Default Type" class="select-search">
  </select-search>
</div>
<div class="row">
  <mat-form-field>
    <mat-label>Allow entries in advance up to (days)</mat-label>
    <input matInput [(ngModel)]="config.days_in_advance" type="number" min="1" step="1"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Allow entries in arrears up to (days)</mat-label>
    <input matInput [(ngModel)]="config.days_in_arrears" type="number" min="1" step="1"/>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Prefix (optional)</mat-label>
    <input matInput [(ngModel)]="config.prefix"/>
  </mat-form-field>
</div>

<ng-container *ngFor="let ct of config.component_types">
  <div class="options-group">
    <h3>{{ct.attributes.name}}</h3>
    <div class="column">
      <div class="row start full-width" *ngIf="config.component_type_config_map[ct.id]">
        <mat-form-field>
          <mat-label>Abbreviation</mat-label>
          <input matInput [(ngModel)]="config.component_type_config_map[ct.id].abbr"
                 [disabled]="isFormulaSelected(ct.id)"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Size</mat-label>
          <input matInput [(ngModel)]="config.component_type_config_map[ct.id].size" type="number"
                 [disabled]="isFormulaSelected(ct.id)"/>
        </mat-form-field>
      </div>
      <div class="row min-height">OR</div>
      <div class="row center full-width">
        <component-name-formula-selector [componentType]="ct"
                                         [selectedNameFormula]="config.component_type_config_map?.[ct.id]?.formula"
                                         (selectedNameFormulaChange)="setSelectedFormula(ct, $event)"
                                         [fixedOnly]="true"
                                         [label]="'Formula option for ' + ct.attributes?.name"
                                         class="full-width">
        </component-name-formula-selector>
      </div>
      <div class="row center full-width">
        <mat-form-field class="flex-third">
          <mat-label>Count format (leading zeros)</mat-label>
          <input matInput type="number" step="1" min="0" max="10"
                 [ngModel]="config.component_type_config_map?.[ct.id]?.leading_zeros"
                 (ngModelChange)="setCountFormat($event, ct.id)"
                 [disabled]="!isFormulaSelected(ct.id)"/>
        </mat-form-field>
        <mat-form-field class="flex-third">
          <mat-label>Reset count</mat-label>
          <mat-select [ngModel]="config.component_type_config_map?.[ct.id]?.count_reset_frequency"
                      (ngModelChange)="setCountFrequency($event, ct.id)"
                      [disabled]="!isFormulaSelected(ct.id)">>
            <mat-option [value]="'daily'">Daily</mat-option>
            <mat-option [value]="'yearly'">Yearly</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox class="flex-third form-element-align"
                      [ngModel]="config.component_type_config_map?.[ct.id]?.automatic_count"
                      (ngModelChange)="setAutoCount($event, ct.id)">Automatic count value
        </mat-checkbox>
      </div>
    </div>
  </div>
</ng-container>
