import {ApiService} from "../../services/api/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, ViewChild} from "@angular/core";
import {SeriesDataService} from "../../services/series_data.service";
import * as utils from "../../lib/utils";
import {deepCopy, stub} from "../../lib/utils";
import { HttpClient } from "@angular/common/http";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {HeaderDataService} from "../../services/header_data.service";
import {catchError, concatMap, map, take, takeUntil, tap} from "rxjs/operators";
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {Series} from "../../_models/series";
import {EventType} from "../../_models/event-type";
import {EngineeringUnit} from "../../_models/engineering-unit";
import {SeriesType} from "../../_models/series-type";
import {ListResponse, SingleResponse} from '../../services/api/response-types';
import {FormDialogService} from "../../services/form-dialog.service";
import {ErrorBankService} from "../../services/error-bank.service";
import {BaseComponent} from '../../shared/base.component';
import {SeriesSeries} from "../../_models/series-series";
import {SeriesComponent} from '../../_models/series-component';
import {KeyMap} from '../../_typing/generic-types';
import {Process} from '../../_models/process';
import {ComponentDataService} from '../../data/component-data.service';
import {NotificationService} from "../../services/notification.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {SeriesService} from "../../services/models/series.service";
import {ConfirmSnackbarComponent} from "../../notifications/snackbar/confirm-snackbar/confirm-snackbar.component";

export interface SeriesDialogData {
    series: Series;
    component: any;
    seriesData: SeriesDataService;
    tab: SeriesTabGroup;
    dtp: IDateTimePeriod;
    source_series: Series[];
}

export enum SeriesTabGroup {
    CONFIGURATION,
    REPORTING,
    OPERATIONS
}

export enum SeriesConfigurationTabs {
    DETAILS = 0,
    CHART = 1,
    LINKS = 2,
}

export enum SeriesOperationTabs {
    ANALYSIS = 0,
    EXCEPTIONS = 1
}

export enum SeriesReportingTabs {
    CORRECTION = 0,
    AUDIT = 1,
}

@Component({
    selector: 'series-form',
    templateUrl: 'series-form.component.html',
    providers: [DateTimePeriodService, ErrorBankService],
    standalone: false
})
export class SeriesFormComponent extends BaseComponent {

    series: Series;
    component: any;
    source_series: Series[];
    series_series: SeriesSeries[];
    has_series_type = false;
    positive_variance = true;
    seriesData: SeriesDataService;
    showing_hints: boolean = false;
    hint: string = 'Name';
    disabled: boolean = false;
    checkCalc: string = "none";
    checkCalcMessage: string = "";
    formula_series: any;
    dtp: IDateTimePeriod;
    permissions: any = {};
    fill_methods = [];
    loading: boolean = true;
    applying: boolean = false;
    series_components: any[];
    sc_process_dict: KeyMap<Process> = {};
    applied_series: Series;
    constant_properties: any[] = [];
    event_types: EventType[] = [];
    series_component: any;
    spc_config: any;

    tooltips: { [key: string]: string } = {};
    engineering_units: EngineeringUnit[] = [];
    series_type_list: SeriesType[] = [];
    show_collation_type = false;
    current_tab: number = SeriesConfigurationTabs.DETAILS;
    tab_group: SeriesTabGroup = SeriesTabGroup.CONFIGURATION;
    tabGroups = SeriesTabGroup;
    seriesConfigurationTabs = SeriesConfigurationTabs;
    seriesReportingTabs = SeriesReportingTabs;
    initialised = false;
    $series_subscription: Subscription;

    @ViewChild('series_tabs') series_tabs: MatTabGroup;


    constructor(public api: ApiService,
                public dialogRef: MatDialogRef<SeriesFormComponent>,
                public http: HttpClient,
                @Inject(MAT_DIALOG_DATA) public data: SeriesDialogData,
                private notification: NotificationService,
                public dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private headerData: HeaderDataService,
                private formDialogService: FormDialogService,
                private errorBank: ErrorBankService,
                private componentData: ComponentDataService,
                private seriesService: SeriesService) {
        super();
    }

    ngOnInit(): void {
        this.series = this.data.series;
        this.seriesData = this.data.seriesData;
        this.component = this.data.component;
        this.source_series = deepCopy(this.data.source_series);
        this.tab_group = this.data.tab || SeriesTabGroup.CONFIGURATION;

        let $series: Observable<Series>;
        if (this.series.id) {
            $series = this.api.series.getById(this.series.id).pipe(
                map((result: SingleResponse<Series>) => {
                    this.series = result.data;
                    return this.series;
                }),
                take(1)
            );
        } else {
            $series = of(this.series);
        }
        this.$series_subscription = utils.refreshSubscription(this.$series_subscription);
        this.$series_subscription = $series.pipe(tap(() => {
                delete this.series.relationships.estimates;
            }
        )).subscribe(() => {
            if (!this.series.attributes.is_calculation) {
                this.series.attributes.is_calculation = false;
            }

            if (this.tab_group === SeriesTabGroup.CONFIGURATION) {
                this.getSeriesDetails();
            }
        });

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dtp = utils.deepCopy(this.dateInst.dtp);
            if (this.data.dtp) {
                this.dateInst.dtp = utils.deepCopy(this.data.dtp);
            }
        });

        this.dateInst.dateTimePeriodChanged$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
        });

    }

    getSeriesDetails() {
        this.api.engineering_unit.searchMany().pipe(takeUntil(this.onDestroy)).subscribe(response => {
            this.engineering_units = response.data;
        });
        this.seriesData.estimateTypesChanged.pipe(tap((types: SeriesType[]) => {
            this.series_type_list = types;
        }), take(1)).subscribe();
        this.fill_methods = Object.keys(this.seriesData.fill_methods).filter(v=>v != null);

        this.refreshSeriesDetails();
        this.initialised === true;
    }

    refreshSeriesDetails() {
        if (!this.series.id) return;
        this.seriesData.getSeriesPermissions([this.series.id]).subscribe(permissions => {
            this.permissions = permissions;
        });
        if (this.series.relationships.source_series.data?.length) {
            this.seriesData.getSourceSeries(this.series.id).pipe(takeUntil(this.onDestroy))
                .subscribe((result: ListResponse<SeriesSeries>) => {
                    this.series_series = result.data;
                });
        }
        this.has_series_type = this.series.relationships.series_type.data?.id != null;
        const sc_ids = this.series.relationships.series_components.data?.map(sc => sc.id);
        if (sc_ids) {
            const $sc = this.componentData.getSeriesComponents(sc_ids).pipe(tap(result => {
                this.series_components = result.data;
            }))
            const $processes = this.componentData.getProcessesBySeriesComponentIds(sc_ids);
            forkJoin($sc, $processes).pipe(tap(([series_components, processes]) => {
                series_components.data.forEach(sc => {
                    const process = processes.data.find(p => p.id === sc.relationships.component.data.id);
                    this.sc_process_dict[sc.id] = process;
                })
            })).pipe(takeUntil(this.onDestroy)).subscribe();
        }

        this.spc_config = this.getSPCConfig();
        this.showCollationType();
    }

    tabGroupChange($event) {
        this.tab_group = $event;
        if ($event === 0 && this.initialised === false) {
            this.getSeriesDetails();
        }
    }

    showCollationType() {
        if (this.series.relationships?.component_constant_collation_series_export?.data?.id ||
            this.series.relationships?.event_constant_collation_series_export?.data?.id) {
            this.show_collation_type = true;
        }
    }

    getSPCConfig() {
        return {
            series_list: [{
                name: this.series.attributes.name,
                axis: 'y',
                type: 'line',
                show_limits: false,
                cumulative: false
            }],
            // TODO: is this correct or are the limits something else?
            limits: {
                hihi: this.series.attributes.hihi,
                hi: this.series.attributes.hi,
                low: this.series.attributes.low,
                lowlow: this.series.attributes.lowlow
            },
            labels: {
                title: this.series.attributes.description,
                subtitle: this.series.attributes.name
            },
            chart_type: 'spc'
        };
    }

    saveSeriesComponent(sc) {
        //For saving whether editing a series on a particular component is enabled (via the Links tab)
        delete sc['result'];
        this.api.series_component.patch(sc).then(result => {
            sc['result'] = 'updated';
        }, result => {
            sc['result'] = 'error updating';
        });
    }

    addSeriesComponent(series: Series): Observable<SingleResponse<SeriesComponent>> {
        let $series_component = of(null);
        //Save only - new series
        if (this.component?.id && !this.series.id && series?.id) {
            $series_component = this.api.series_component.obsSave({
                relationships: {
                    component: {
                        data: {
                            id: this.component.id,
                            type: 'component'
                        }
                    }, series: {data: {id: series.id, type: 'series'}}
                }, type: 'series_component'
            }).pipe(catchError(e => {
                this.errorBank.addError('SeriesForm', 'SaveSeriesComponent', 'obsSave',
                    'Error saving the component for this series.', e);
                return of(e)
            }), tap(result => this.series_component = result.data));
        }
        return $series_component;
    }

    saveSeriesSeries(saved_series: Series): Observable<any> {
        //Only available for new series - otherwise through estimates form
        let $series_series: Observable<(SingleResponse<SeriesSeries> | null)> = of(null);
        if (this.series.id) return $series_series;
        if (saved_series && this.source_series) {
            $series_series = this.seriesData.saveManySourceSeries(saved_series, [], this.source_series || [], this.series_series || [])
        }
        $series_series.pipe(
            catchError(e => {
                console.log('Caught error in saveSeriesSource: ',);
                this.errorBank.addError('SeriesForm', 'SaveSeriesSource', 'save/patch',
                    'Error saving the target series for this series.', e);
                return of(e)
            }));
        return $series_series;
    }

    async save() {
        const p = this.apply();
        p.then(result => {
            let response = {series_component: null, series: this.series}
            if (!result.series_component && this.series_component) {
                response['series_component'] = this.series_component;
            }
            this.dialogRef.close(response);
        }, reason => {
            if (reason?.error?.errors && reason.error.errors.length > 0 && reason.error.errors[0].detail) {
                console.log('SeriesFormComponent - error: ', reason.error);
                this.notification.openError(reason.error.errors[0].title);
            } else {
                this.notification.openError('Failed to save the series.');
            }
            console.log('Reason for failing to save:', reason);
        });
    }

    saveOrApply(function_name) {
        const ctrl = this;
        if (!this.seriesService.validateSeries(this.series)) return;

        if (this.source_series?.length && !this.series.relationships.series_type.data?.id) {
            this.notification.openError('Series with one or more Source series must have a Series Type selected.');
            return;
        }
        if (this.series.attributes.is_calculation) {
            let obs$ = this.calcValidator();
            obs$.subscribe({
                next: (result) => {
                    this[function_name]();
                }, error: (reject) => {
                    console.log('Incorrect formula: ', reject);
                    this.notification.openError('Please correct your formula before saving.');
                    return;
                }
            });
        } else {
            this[function_name]();
            setTimeout(() => this.reassignSeries(), 1000);
        }
    }

    async apply() {
        const confirm = await this.formDialogService.confirm(
            'Please note: "Save" and "Apply" will push the attribute changes you have made into production.');
        if (!confirm) {
            return;
        }

        this.applying = true;
        this.dialogRef.disableClose = this.applying;

        if (this.series.id) {
            delete this.series.relationships['series_list'];
            delete this.series.relationships['parents'];
            delete this.series.relationships['children'];
            delete this.series.relationships['source_series'];
            delete this.series.relationships['target_series'];
        }
        this.series.relationships.series_type.data = utils.stub<SeriesType>(this.series.relationships.series_type.data);
        return this.seriesData.saveSeries(this.series).pipe(
            concatMap((response: SingleResponse<Series>) => {
                let $series_component = this.addSeriesComponent(response.data);
                let $series_series = this.saveSeriesSeries(response.data);
                return forkJoin($series_component, $series_series).pipe(map(() => response));
            }),
            catchError(e => {
                this.errorBank.addError('SeriesForm', 'Save series', 'save/patch',
                    'Error saving this series.', e);
                return of(e)
            }),
            tap((response) => {
                if (this.errorBank.errors.length) {
                    this.notification.openError("Some errors occurred: " + this.errorBank.getMessageString());
                }
                this.series = response.data;
                this.refreshSeriesDetails();
                this.applying = false;
                this.dialogRef.disableClose = this.applying;
                this.applied_series = utils.deepCopy(this.series);
            })
        ).toPromise();
    }

    addToFormula(formula_series) {
        this.checkCalcMessage = "";
        this.checkCalc = "";
        if (formula_series) {
            if (this.series.attributes.name_formula == null) {
                this.series.attributes.name_formula = "[" + formula_series.attributes.name + "]";
            } else {
                this.series.attributes.name_formula += " [" + formula_series.attributes.name + "]";
            }
        }
    }

    calcValidator(): Observable<any> {
        const getUrl = this.seriesService.getCalcCheckRequest(this.series);

        return this.http.get(getUrl).pipe(map((response) => {
                this.checkCalcMessage = response['msg'];
                if (response['msg'].indexOf("WARNING") > -1) {
                    this.checkCalc = "warning";
                } else {
                    this.checkCalc = "ok";
                }
            }), catchError((reject) => {
                this.checkCalcMessage = reject.error.msg;
                this.checkCalc = "error";
                throw new Error(reject.error.msg);
            })
        );
    }

    overrideCalculations() {
        const ctrl = this;
        this.getCalculations();
    }

    getCalculations() {
        this.headerData.getCalculations(this.dtp, [this.series.id], 'hour', 1).then(data => {
        }).catch();
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
    };

    engineeringUnitChanged(obj) {
        this.series.relationships.engineering_unit.data = stub<EngineeringUnit>(obj)
    }

    onTabClick(event: MatTabChangeEvent) {
        this.current_tab = event.index;
    }

    onCloseClick(): void {
        let confirmNotification: MatSnackBarRef<ConfirmSnackbarComponent>;
        confirmNotification = this.notification.openConfirm('You have unsaved changes. Click OK to discard them, or Cancel to go back.');
        confirmNotification.onAction().subscribe((): void => {
            this.dialogRef.close();
        });
    }

    showAudit() {
        return this.series?.id && (this.tab_group === this.tabGroups.CONFIGURATION &&
            this.current_tab === this.seriesConfigurationTabs.DETAILS);
    }

    reassignSeries() {
        this.series = Object.assign({}, this.series);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
