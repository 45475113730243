<h2 *ngIf="!show_component_link">Add new {{config.selected_component_type?.attributes?.name || 'component'}}</h2>
<h2 *ngIf="show_component_link && component" class="row center"
    style="justify-content: space-between;">
  <div><i class="fa fa-refresh" matTooltip="Refresh table" style="font-size:1.2rem;color:#3a3a3c;cursor:pointer;"
          (click)="refreshTable()"></i></div>
  <div>{{component.attributes?.name}}</div>
  <div (click)="loadDetails()" style="font-weight: normal;margin-left:10px;font-size:1.1rem;cursor:pointer;">
    View details >
  </div>
</h2>
<component-form *ngIf="config"
                [config]="config"
                (component_saved)="showComponentLink($event)"
                (component_cleared)="hideComponentLink();">
</component-form>
