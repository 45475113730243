import * as utils from '../lib/utils';
import {moment} from "../services/timezone-selector.service";
import {ICustomTimePeriod} from "../_models/custom-time-period";

// region private types
interface IFromToType {
    duration: number;
    key: string;
}

export interface IDateTimePeriod {
    range: string;
    start: Date;
    end: Date;
    sample_period: OptionalSamplePeriod;
    calendar: string;
    parent?: string,
    is_parent?: boolean

    date_string(): string;
}

export interface IRelativeDtp extends IDateTimePeriod {
    config: IRelativeDtpConfig;
    parent_dtp: IDateTimePeriod;
    sample_period: OptionalSamplePeriod;
}

export interface ISamplePeriod {
    name: string;
    hours: number;
    wire_sample: string;
    readonly seconds: number;
    show: string;
    parent: string;
    is_parent: boolean;

    format(date: Date, dtp: DateTimePeriod, dates?: Date[], format_only?: boolean): string;
}

interface ICalendar {
    id: any;
    name: string;
    description: string;
    ranges: any[];
    ranges_dict: any;
    default_start_hour: number;
    account_name: string;
    account_id: any;
    calendar_periods: ICustomTimePeriod[];

    month_start_day(): number;
}

export type OptionalDateTimePeriod = Partial<IDateTimePeriod>;
export type OptionalCalendar = Partial<ICalendar>;
export type OptionalSamplePeriod = Partial<ISamplePeriod>;

// endregion

export class SamplePeriod implements OptionalSamplePeriod {
    public get seconds(): number {
        return this.hours * 3600;
    }

    constructor(public readonly name: string,
                public readonly hours: number,
                public readonly wire_sample: string,
                public readonly format: (date: Date, dtp: DateTimePeriod, dates?: Date[], format_only?: boolean) => string,
                public readonly show?: string,
                public readonly parent?: string,
                public readonly is_parent?: boolean) {
    }
}

export class DateTimePeriod implements IDateTimePeriod {

    constructor(public range: string,
                public start: Date,
                public end: Date,
                public sample_period: OptionalSamplePeriod,
                public calendar: string = 'default',
                public parent?: string,
                public is_parent?: boolean) {
    }

    date_string() {
        return utils.stringDate(this.start) + " to " + utils.stringDate(this.end);
    }
}

export interface IRelativeDtpConfig {
    from: IFromToType;
    to: IFromToType;
    relative_to: string;
    sample_period: string;
}

export class RelativeDtp extends DateTimePeriod implements IRelativeDtp {
    constructor(public config: IRelativeDtpConfig,
                public parent_dtp: DateTimePeriod,
                public sample_period) {

        super(parent_dtp.range, parent_dtp.start, parent_dtp.end, parent_dtp.sample_period, parent_dtp.calendar);

        let relative_to;
        switch (this.config.relative_to) {
            case 'start':
                relative_to = new Date(parent_dtp.start);
                break;
            case 'end':
                relative_to = new Date(parent_dtp.end);
                break;
            case 'today':
                relative_to = new Date();
                relative_to.setSeconds(0, 0);
                break;
        }
        this.start = moment(relative_to).add(config.from.duration * -1, config.from.key).toDate();
        this.end = moment(relative_to).add(config.to.duration * -1, config.to.key).toDate();
        this.range = 'custom';
        this.sample_period = sample_period;

    }

    date_string(): string {
        const rdc: IRelativeDtpConfig = this.config;
        return `From ${rdc.from.duration} ${rdc.from.key}(s) ago to ${+rdc.to.duration} ${rdc.to.key}(s) ago relative to ${rdc.relative_to}`;
    }
}

export class Calendar implements OptionalCalendar {

    constructor(public id: any,
                public name: string,
                public description: string,
                public ranges: any[],
                public ranges_dict: any,
                public default_start_hour?: number,
                public account_name?: string,
                public account_id?: any) {
    }

    month_start_day(): number {
        const start = this.ranges_dict?.['this month']?.start
        if (start) {
            return new Date(start).getDate();
        } else {
            return 1;
        }
    }
}
