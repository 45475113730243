<svg #flow_chart_svg
     (click)="elementClicked($event, chart.parent_process)"
     (contextmenu)="contextMenu($event, chart.parent_process)" (mousedown)="startRectangle($event)"
     (mousemove)="drawRectangle($event)"

     (mouseup)="endRectangle()"
     [attr.height]="chart.parent_process.height()"
     [attr.viewBox]="chart.left_offset() +' ' +chart.top_offset() +' ' + chart.parent_process.width()/chart.scale() +' ' + chart.parent_process.height()/chart.scale()"
     [attr.width]="chart.parent_process.width()"
     [ngClass]="{'edit-mode':chart.editmode, 'stream-mode':chart.streammode}"
     class="draggable-container"
     id="flow_chart_svg"
     style="cursor:default;"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xhtml="http://www.w3.org/1999/xhtml">
  <!--ng-click="mouseClick($event)"-->
  <!-- ng-style="{ 'height': chart.parent_process.height(), 'width': chart.parent_process.width() }" -->
  <defs>
    <marker id='head' markerHeight='16'
            markerUnits="userSpaceOnUse" markerWidth='16'
            orient="auto" refX='21'
            refY='8'>
      <!-- triangle pointing right (+x) -->
      <path d="M0,0 L0,16 L12,8 z" fill="red"/>
    </marker>
  </defs>
  <defs>
    <marker id='origin' markerHeight='16'
            markerUnits="userSpaceOnUse" markerWidth='16'
            orient="auto" refX='-13'
            refY='8'>
      <!-- triangle pointing right (+x) -->
      <path d="M0,0 L0,16 L12,8 z" fill="red"/>
    </marker>
  </defs>
  <!--
        <path d='M0,0 V4 L2,2 Z' fill="red"/>-->
  <defs>
    <linearGradient
        id="processBackgroundGradient"
        spreadMethod="pad"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
    >
      <stop
          offset="0"
          stop-color="#56aaff"
          stop-opacity="0"
      />
      <stop
          offset="0.63934"
          stop-color="#d0d0e5"
          stop-opacity="0"
      />
    </linearGradient>
  </defs>
  <defs>
    <pattern height="5" id="smallGrid" patternUnits="userSpaceOnUse" width="5">
      <path d="M 5 0 L 0 0 0 5" fill="none" stroke="gray" stroke-width="0.5"/>
    </pattern>
    <pattern height="50" id="grid" patternUnits="userSpaceOnUse" width="50">
      <rect fill="url(#smallGrid)" height="50" width="50"/>
      <path d="M 50 0 L 0 0 0 50" fill="none" stroke="gray" stroke-width="1"/>
    </pattern>
  </defs>

  <rect *ngIf="chart.gridShown()" fill="url(#grid)" height="100%" width="100%"></rect>
  <!--ng-show="chart.streammode"-->
  <text *ngIf="chart.streammode"
        class="stream-mode-indicator"
        x="20"
        y="20"
  >stream mode
  </text>
  <!--ng-mousedown="connectorMouseDown($event, chart.parent_process, connector, $index, true)"-->

  <ng-container *ngIf="chart.streammode || chart.connectorsShown()">
    <g
        *ngFor="let connector of chart.parent_process.connectors"
        class="connector input-connector"
    >

      <circle
          (cdkDragEnded)="draggingConnectorEnded($event, connector)"
          (cdkDragMoved)="onDraggingConnector($event, chart.parent_process, connector)"
          (contextmenu)="contextMenu($event, connector)"
          (mousedown)="processDown($event, connector)"
          (mouseenter)="mouseEnter(connector)"
          (mouseleave)="mouseLeave('connector')"
          [attr.class]="connector.selected() && 'selected-connector' || connector == mouseOver.connector && 'mouseover-connector-circle' || chart.streammode && connector.connectorClass() "
          [attr.cx]="connector.x()-chart.parent_process.x()"
          [attr.cy]="connector.y()-chart.parent_process.y()" cdkDrag
          r="40"
      ></circle>
      <text
          [attr.x]="connector.x()"
          [attr.y]="connector.y()"
          alignment-baseline="middle"
          text-anchor="start"
      >
        {{connector.name()}}
      </text>
      <text [attr.x]="connector.hover_text_x(40)"
            [attr.y]="connector.hover_text_y(40)"
            class="outside-stream"
      >{{connector.hover_text()}}</text>
      <!--text-anchor="{{connector.hover_text_anchor()}}"-->
    </g>
  </ng-container>

  <!--****NOTE:mousedown used instead of dragstarted because of the available mouse pointer coords***-->
  <g (cdkDragEnded)="onDragEnded($event)"
     (cdkDragMoved)="onDragging($event, process)"
     (mousedown)="processDown($event, process)"
     *ngFor="let process of chart.processes"
     cdkDrag
     class="process"
  >
    <rect
        (click)="elementClicked($event, process)"
        (contextmenu)="contextMenu($event, process)"
        [attr.class]="process.highlighted() && 'highlighted-process-rect' ||
            process.selected() && 'selected-process-rect' || 'process-rect'"
        [attr.height]="process.height()"
        [attr.width]="process.width()"
        [attr.x]="process.x()"
        [attr.y]="process.y()"
        fill="url(#processBackgroundGradient)"
        rx="10"
        ry="10"
    >
    </rect>
    <ng-container *ngIf="process.is_parent===false && process.image && process.image.src() ">
      <g (cdkDragEnded)="onDragEnded($event);"
         (cdkDragMoved)="onDragging($event,process.image)"
         (contextmenu)="contextMenu($event, process.image)"
         (mousedown)="processDown($event, process.image)"
         (mouseenter)="modelMouseEnter($event, process.image)"
         (mouseleave)="modelMouseLeave($event, process.image)"
         (dblclick)="navigateToPage($event, process)"
         [attr.class]="process.image.selected() && 'selected-image' || 'image'"
         cdkDrag
      >
        <rect
            [attr.height]="process.image.height()"
            [attr.width]="process.image.width()"
            [attr.x]="process.image.x()"
            [attr.y]="process.image.y()"
            fill="transparent"
            rx="8"
        >
        </rect>
        <image
            [attr.height]="process.image.height()"
            [attr.width]="process.image.width()"
            [attr.x]="process.image.x()"
            [attr.xlink:href]="process.image.src()"
            [attr.y]="process.image.y()"
        ></image>
      </g>
    </ng-container>

    <g [attr.transform]="'translate(' + process.x() + ', ' + process.y() + ')'">
      <text
          (cdkDragEnded)="onDragEnded($event)"
          (cdkDragMoved)="onDragging($event, process.text)"
          (click)="elementClicked($event, process.text)"
          (mousedown)="processDown($event, process.text)"
          [attr.class]="process.text.selected() && 'selected-process-text' || process.selected() &&
            'selected-process-text' || (process == mouseOver.process && 'mouseover-process-text')||
            (process.text == mouseOver.process-text && 'mouseover-process-text'  || 'process-text')"
          [attr.font-size]="process.fontsize()"
          [attr.x]="process.text.x()"
          [attr.y]="process.text.y()"
          alignment-baseline="middle"
          cdkDrag
          text-anchor="middle"
      >
        {{process.data.attributes.name}}
      </text>
    </g>

    <ng-container
        *ngIf="chart.streammode || chart.connectorsShown()">

      <g *ngFor="let connector of process.connectors"
         class="connector input-connector">

        <circle
            (cdkDragEnded)="draggingConnectorEnded($event, connector)"
            (cdkDragMoved)="onDraggingConnector($event, process, connector)"
            (contextmenu)="contextMenu($event, connector)"
            (mousedown)="processDown($event, connector)"
            (mouseenter)="mouseEnter(connector)"
            (mouseleave)="mouseLeave('connector')"
            [attr.class]="connector.selected() && 'selected-connector' || connector == mouseOver.connector && 'mouseover-connector-circle' || chart.streammode && connector.connectorClass() || 'connector-circle'"
            [attr.cx]="connector.x() + process.x()"
            [attr.cy]="connector.y() + process.y()" [attr.r]="connectorSize"
            cdkDrag
        >
        </circle>

        <text
            [attr.class]="connector.text.selected() && 'selected-connector-name' ||
                    connector.selected() && 'selected-connector-name' ||(connector == mouseOver.connector &&
                    'mouseover-connector-name' || 'connector-name')"
            [attr.x]="connector.text.x()"

            [attr.y]="connector.text.y()"
            alignment-baseline="middle"
            text-anchor="start"
        >
          {{connector.name()}}
        </text>

        <text [attr.x]="connector.x() + process.x() + 15" [attr.y]="connector.y() + process.y() - 10"
              cdkDrag class="outside-stream">{{connector.hover_text()}}</text>
      </g>
    </ng-container>
  </g>

  <g (cdkDragEnded)="onDragEnded($event)" *ngFor="let equipment of chart.equipment"
     [attr.x]="equipment.x()"
     (cdkDragMoved)="onDragging($event, equipment)"
     (contextmenu)="contextMenu($event, equipment)"
     (mousedown)="processDown($event, equipment)"
     (mouseenter)="modelMouseEnter($event, equipment)"
     (mouseleave)="modelMouseLeave($event, equipment)"
     [attr.y]="equipment.y()"
     cdkDrag
     class="equipment"
  >

    <rect
        (click)="elementClicked($event, equipment)"
        [attr.class]="equipment.highlighted() && 'highlighted-equipment-rect' ||
        equipment == mouseOverEquipment && 'highlighted-equipment-rect' ||
        equipment.selected() && 'selected-equipment-rect' ||
        equipment == mouseOverEquipment && 'mouseover-equipment' || 'equipment-rect'"
        [attr.height]="equipment.height()"
        [attr.width]="equipment.width()"
        [attr.x]="equipment.x()"
        [attr.y]="equipment.y()"
        fill="url(#processBackgroundGradient)"
        rx="10"
        ry="10"
    >
    </rect>

    <image
        [attr.height]="80"
        [attr.width]="80"
        [attr.x]="equipment.x() + equipment.width()/2 -40"
        [attr.xlink:href]="equipment.data.attributes.icon"
        [attr.y]="equipment.y()"></image>

    <text
        (cdkDragEnded)="onDragEnded($event)"
        (cdkDragMoved)="onDragging($event, equipment.text)"
        (click)="elementClicked($event, equipment.text)"
        (dblclick)="textDblClick($event, equipment.text)"
        (mousedown)="processDown($event, equipment.text)"
        [attr.class]="equipment.text.selected() && 'selected-equipment-name' ||
            equipment.selected() && 'selected-equipment-name' ||equipment == mouseOverEquipment && 'mouseover-equipment-name' ||
            'equipment-name'"
        [attr.transform]="'rotate(' + equipment.text.rotate() + ')'"
        [attr.x]="equipment.x() + equipment.text.x()"
        [attr.y]="equipment.y() + equipment.text.y()"
        alignment-baseline="middle"
        cdkDrag
        text-anchor="middle"
    >{{equipment.name()}}
    </text>
    <text
        [attr.x]="equipment.width()/2"
        alignment-baseline="middle"
        class="parent_component"
        text-anchor="middle"
        y="15"
    >{{equipment.parent()}}
    </text>
  </g>

  <g *ngFor="let custom_chart of chart.parent_process.custom_charts" class="chart">
    <g (cdkDragEnded)="onDragEnded($event);"
       (cdkDragMoved)="onDragging($event,custom_chart)"
       (click)="openCustomChartDialog(custom_chart.data);"
       (contextmenu)="contextMenu($event, custom_chart)"
       (mousedown)="processDown($event, custom_chart)"
       [attr.class]="custom_chart.selected() && 'selected-custom-chart' || 'custom-chart'"
       cdkDrag
    >
      <rect
          [attr.height]="custom_chart.height()"
          [attr.width]="custom_chart.width()"
          [attr.x]="custom_chart.x()"
          [attr.y]="custom_chart.y()"
          rx="8"
      >
      </rect>
      <text
          [attr.x]="custom_chart.x() + 8"
          [attr.y]="custom_chart.y() + 25"
      >&#xf080;
      </text>

      <text [attr.x]="custom_chart.x() + 40"
            [attr.y]="custom_chart.y() + 22"
            class="title"
      >{{custom_chart.title()}}</text>
    </g>

  </g>

  <g *ngFor="let obg of chart.parent_process.ore_body_groups" class="ore-body-group">
    <g (cdkDragEnded)="onDragEnded($event);"
       (cdkDragMoved)="onDragging($event,obg)"
       (contextmenu)="contextMenu($event, obg)"
       (mousedown)="processDown($event, obg)"
       [attr.class]="obg.selected() && 'selected-ore-body-group' || 'ore-body-group'"
       cdkDrag
    >
      <image
          [attr.height]="obg.height()"
          [attr.width]="obg.width()"
          [attr.x]="obg.x()"
          [attr.xlink:href]="obg.src()"
          [attr.y]="obg.y() + 10"
      ></image>

      <text [attr.width]="obg.width()"
            [attr.y]="obg.y() + 22"
            [attr.x]="obg.x() + (obg.width() / 2)"
            class="ore-body-group-name"
            text-anchor="middle"
      >{{obg.title()}}
      </text>

      <rect
          (click)="elementClicked($event, obg)"
          [attr.class]="obg.highlighted() && 'highlighted-ore-body-group-rect' ||
        obg == mouseOver['ore-body-group'] && 'highlighted-ore-body-group-rect' ||
        obg.selected() && 'selected-ore-body-group-rect' ||
        obg == mouseOver['ore-body-group'] && 'mouseover-ore-body-group' || 'ore-body-group-rect'"
          [attr.height]="obg.height()"
          [attr.width]="obg.width()"
          [attr.x]="obg.x()"
          [attr.y]="obg.y()"
          fill="url(#processBackgroundGradient)"
          rx="8"
      >
      </rect>


    </g>

  </g>

  <g *ngFor="let image of chart.parent_process.images" class="image">
    <g (cdkDragEnded)="onDragEnded($event);"
       (cdkDragMoved)="onDragging($event,image)"
       (contextmenu)="contextMenu($event, image)"
       (mousedown)="processDown($event, image)"
       [attr.class]="image.selected() && 'selected-image' || 'image'"
       cdkDrag
    >
      <rect
          [attr.height]="image.height()"
          [attr.width]="image.width()"
          [attr.x]="image.x()"
          [attr.y]="image.y()"
          fill="transparent"
          rx="8"
      >
      </rect>
      <image
          [attr.height]="image.height()"
          [attr.width]="image.width()"
          [attr.x]="image.x()"
          [attr.xlink:href]="image.src()"
          [attr.y]="image.y()"
      ></image>

      <text [attr.x]="image.x()  + 40"
            [attr.y]="image.y() + image.height() + 22"
            class="title"
      >{{image.title()}}</text>
    </g>

  </g>

  <g *ngFor="let context of chart.parent_process.contexts" class="context">
    <g (cdkDragEnded)="onDragEnded($event);"
       (cdkDragMoved)="onDragging($event,context)"
       (contextmenu)="contextMenu($event, context)"
       (mousedown)="processDown($event, context)"
       [attr.class]="context.selected() && 'selected-context' || 'context'"
       [attr.x]="context.x()+'px'"
       [attr.y]="context.y()"
       cdkDrag
    >
      <rect
          [attr.height]="context.height()"
          [attr.width]="context.width()"
          [attr.x]="context.x()"
          [attr.y]="context.y()"
          rx="8"
      >
      </rect>
      <ng-container *ngIf="context.data.type==='context'">
        <foreignObject [attr.height]="context.height()"
                       [attr.width]="context.width()"
                       [attr.x]="context.x()"
                       [attr.y]="context.y()"
                       class="context">
          <xhtml:div [ngStyle]="{height:context.height() + 'px', width:context.width() + 'px'}">
            <xhtml:context (contextmenu)="contextMenu($event, context)"
                           [config]="context.config" [title]="context.title()"
                           style="height:100%;width:100%;">
            </xhtml:context>
          </xhtml:div>
        </foreignObject>
      </ng-container>

      <ng-container *ngIf="context.data.type==='series-summary'">
        <foreignObject [attr.height]="context.height()"
                       [attr.width]="context.width()"
                       [attr.x]="context.x()"
                       [attr.y]="context.y()"
                       class="series-summary">
          <xhtml:div [ngStyle]="{height:context.height() + 'px', width:context.width() + 'px'}">
            <xhtml:series-summary (contextmenu)="contextMenu($event, context)"
                                  [config]="context.config" [title]="context.title()"
                                  [refresh]="context.do_refresh()"
                                  style="height:100%;width:100%;">
            </xhtml:series-summary>
          </xhtml:div>
        </foreignObject>
      </ng-container>
    </g>
  </g>

  <g *ngFor="let group of _object.keys(chart.report_groups)" class="series-group">

    <!--Table series components-->
    <g (cdkDragEnded)="onDragEnded($event)"
       (cdkDragMoved)="onDragging($event, chart.report_groups[group].series[0], chart.report_groups[group])"
       (click)="groupClicked($event)"
       (contextmenu)="$event.stopPropagation()"
       (mousedown)="seriesGroupMouseDown($event, chart.report_groups[group])"
       (mouseenter)="modelMouseEnter($event, chart.report_groups[group].series[0])"
       (mouseleave)="modelMouseLeave($event, chart.report_groups[group].series[0])"
       *ngIf="(chart.report_groups[group].series.length > 1 && chart.report_groups[group].name !== null)"
       [attr.x]="chart.report_groups[group].x"
       [attr.y]="chart.report_groups[group].y"
       cdkDrag
    >
      <foreignObject *ngIf="chart.report_groups[group].view_on_flowchart === true"
                     [attr.height]="chart.report_groups[group].series.length*40"
                     [attr.x]="chart.report_groups[group].x"
                     [attr.y]="chart.report_groups[group].y"
                     width="260">
        <xhtml:flowchart-series-component
            [group]="chart.report_groups[group]"
            [groups_selected]="chart.selectedGroups">
        </xhtml:flowchart-series-component>
      </foreignObject>

    </g>

    <ng-container *ngIf="(chart.report_groups[group].series.length<=1 || chart.report_groups[group].name == null)">
      <g
          *ngFor="let series of chart.report_groups[group].series"
          class="series"
          text-anchor="middle"

      >
        <foreignObject (cdkDragEnded)="onDragEnded($event)"
                       (cdkDragMoved)="onDragging($event, series, chart.report_groups[group])"
                       (click)="elementClicked($event, series)"
                       (contextmenu)="$event.stopPropagation()"
                       (contextmenu)="contextMenu($event, process)"
                       (dblclick)="seriesDblClick($event, series)"
                       (mousedown)="processDown($event, series)"
                       (mouseenter)="modelMouseEnter($event, series)"
                       (mouseleave)="modelMouseLeave($event, series)"
                       *ngIf="series.view_on_flowchart() === true"
                       [attr.transform]="'rotate(' + series.rotate() + ')'"
                       [attr.x]="series.x()"
                       [attr.y]="series.y()"
                       cdkDrag
                       height="10"
                       width="260">
          <xhtml:flowchart-series-component [series]="series">
          </xhtml:flowchart-series-component>
        </foreignObject>

      </g>
    </ng-container>

  </g>

  <g *ngFor="let constant of chart.constants" class="constant">

    <foreignObject (cdkDragEnded)="onDragEnded($event)"
                   (cdkDragMoved)="onDragging($event, constant)" (dblclick)="seriesDblClick($event, constant)"
                   (mousedown)="processDown($event, constant)"
                   (mouseenter)="modelMouseEnter($event, constant)"
                   (mouseleave)="modelMouseLeave($event, constant)"
                   *ngIf="constant.view_on_flowchart()"
                   [attr.transform]="'rotate(' + constant.rotate() + ')'"
                   [attr.x]="constant.x()"
                   [attr.y]="constant.y()"
                   cdkDrag
                   height="10"
                   width="260">
      <xhtml:flowchart-series-component [series]="constant">
      </xhtml:flowchart-series-component>
    </foreignObject>
    <!--<xhtml:table-->
    <!--[ngClass]="{'selected-series-rect':constant.selected(), 'mouseover-series-rect': constant == chart.mouseOverConstant , 'series-rect'}"-->
    <!--style="{'background': constant.background(), 'color': constant.text_colour(), 'font-size.px':constant.font_size()}">-->
    <!--<tr>-->
    <!--<td style="{'border-color':constant.borders(),'padding':constant.padding()}">{{constant.name()}}</td>-->
    <!--<td style="{'border-color':constant.borders(),'padding':constant.padding(), 'text-align':'right','border-right':'none'}" class="series-value">-->
    <!--{{constant.value()}}-->
    <!--</td>-->
    <!--<td style="{'border-color':constant.borders(), 'border-left':'none','padding':constant.padding()}">-->
    <!--<inline-style-menu class="menu-component"-->
    <!--[value_component]="constant.data"-->
    <!--[tree_position]="constant.tree_position">-->
    <!--</inline-style-menu>-->
    <!--</td>-->
    <!--</tr>-->
    <!--</xhtml:table>-->
    <!--</foreignObject>-->
  </g>

  <g *ngFor="let stream of chart.streams" class="stream">
    <path
        (cdkDragEnded)="onStreamDragEnded($event, stream)"
        (cdkDragMoved)="onStreamDragging($event, stream)"
        (click)="elementClicked($event, stream)"
        (contextmenu)="contextMenu($event, stream)"
        (mousedown)="onStreamDown($event, stream)"
        [attr.class]="stream.highlighted() && 'highlighted-stream-line' || stream.selected() &&
                'selected-stream-line' || (stream == mouseOverStream && 'mouseover-stream-line' )|| 'stream-line'"
        [attr.d]="stream.genPath()"
        [attr.fill]="stream.colour()"
        [attr.stroke]="stream.colour()"
        cdkDrag
        class="stream-line"
        marker-end='url(#head)'
        marker-start='url(#origin)'
    >
    </path>

    <text
        (cdkDragEnded)="onDragEnded($event)"
        (cdkDragMoved)="onDragging($event, stream.text)"
        (dblclick)="textDblClick($event, stream.text)"
        (mousedown)="processDown($event, stream.text)"
        [attr.class]="stream.text.selected() && 'selected-stream-name' ||
            stream.selected() && 'selected-stream-name' ||(stream == mouseOverStream && 'mouseover-stream-name'
            || 'stream-name')"
        [attr.fill]="stream.colour()"
        [attr.stroke]="stream.colour()"
        [attr.transform]="'rotate(' + stream.text.rotate() + ')'"
        [attr.x]='stream.text.x()'
        [attr.y]='stream.text.y()'
        [attr.font-size]="stream.fontsize()"
        alignment-baseline="middle"
        cdkDrag
        text-anchor="middle"
    >{{stream.name()}}</text>

    <g *ngIf="chart.streammode || chart.connectorsShown()">
      <circle
          (cdkDragMoved)="onDragging($event, point)"
          (click)="elementClicked($event, point)"
          (contextmenu)="contextMenu($event, point)"
          (mousedown)="processDown($event, point)"
          *ngFor="let point of stream.points"
          [attr.class]="!point.selected() && 'line-point' || point.selected() && 'selected-stream-line'"
          [attr.cx]="point.x()"
          [attr.cy]="point.y()"
          cdkDrag
          r="3"
      >
      </circle>
    </g>

    <circle
        [attr.class]="stream.selected() && 'selected-stream-endpoint' || (stream == mouseOverStream && 'mouseover-stream-endpoint' || 'stream-endpoint')"
        [attr.cx]="stream.startCoordX()"
        [attr.cy]="stream.startCoordY()"
        r="1"
    >
    </circle>

    <circle
        [attr.class]="stream.selected() && 'selected-stream-endpoint' || (stream == mouseOverStream && 'mouseover-stream-endpoint' || 'stream-endpoint')"
        [attr.cx]="stream.endCoordX()"
        [attr.cy]="stream.endCoordY()"
        r="1"
    >
    </circle>
  </g>

  <g *ngIf="draggingStream">

    <path
        [attr.d]="genDraggingPath(dragPoint1, dragTangent1, dragPoint2, dragTangent2)"
        class="dragging-stream dragging-stream-line"
    >
    </path>

    <circle
        [attr.cx]="dragPoint1.x"
        [attr.cy]="dragPoint1.y"
        class="dragging-stream dragging-stream-endpoint"
        r="4"
    >
    </circle>

    <circle
        [attr.cx]="dragPoint2.x"
        [attr.cy]="dragPoint2.y"
        class="dragging-stream dragging-stream-endpoint"
        r="4"
    >
    </circle>
  </g>

  <rect
      *ngIf="dragSelecting"
      [attr.height]="dragSelectionRect.height"
      [attr.width]="dragSelectionRect.width"
      [attr.x]="dragSelectionRect.x"
      [attr.y]="dragSelectionRect.y"
      class="drag-selection-rect"
  >
  </rect>

</svg>
