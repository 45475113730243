import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

export interface ConfirmSnackbarData {
    message: string;
    isOpen: boolean;
    cancelText: string;
    okText: string;
}

@Component({
    selector: 'confirm-snackbar',
    templateUrl: './confirm-snackbar.component.html',
    styleUrls: ['./confirm-snackbar.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class ConfirmSnackbarComponent {
    isOpen: boolean = false;
    message: string;
    cancelText: string;
    okText: string;
    constructor(public snackBarRef: MatSnackBarRef<ConfirmSnackbarComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: ConfirmSnackbarData) {
        this.message = this.data.message;
        this.isOpen = data.isOpen;
        this.cancelText = data.cancelText || 'Cancel';
        this.okText = data.okText || 'Ok';
    }
    ngOnInit(): void {
    }
}
