<div class="timezone-selector-trigger">
  <img src='/static/icons/date-period_1.png'
       class="date-period-icon"
       [matTooltip]="active_timezone"
       [matMenuTriggerFor]="timezoneSelectorMenu"/>
</div>


<mat-menu #timezoneSelectorMenu="matMenu" class="timezone-selector" [ngClass]="'timezone-selector'">
  <button (click)="changeTimezone(option.value)" *ngFor="let option of timezone_options">
    <div>{{option.alias ? option.alias : option.name}} ({{option.offset}})</div>
    <div *ngIf="option.value===active_timezone" class="active-account-indicator ng-star-inserted"> Active</div>
  </button>
</mat-menu>


<div *ngIf="show_clock" class="timezone-clock" [ngClass]="{'clock-min' : clock_min }"
     [matTooltip]="clock_min ? 'Show time in selected timezone of ' + active_timezone : 'Time in selected timezone: ' + active_timezone">
  <div *ngIf="!clock_min">{{clock | date: 'd MMM HH:mm (z)' : active_timezone_offset_string}}</div>
  <div>
    <i (click)="toggleTimezoneClock(true)" *ngIf="!clock_min" class="fa fa-caret-up"></i>
    <i (click)="toggleTimezoneClock(!clock_min)" class="fa fa-clock"></i>
  </div>
</div>
