import {Directive, Output, EventEmitter, HostListener, ElementRef, Input} from '@angular/core';
import {NotificationService} from "../services/notification.service";
import {fromEvent, Subject, timer} from 'rxjs';
import {debounceTime, takeUntil, switchMap} from 'rxjs/operators';

@Directive({
    selector: '[hoverDelay]',
    standalone: false
})
export class HoverDelayDirective {
    private readonly onDestroy = new Subject<void>();
    @Input() hoverDelay = 300;
    element: Element;
    @Output() mouseIn = new EventEmitter<void>();
    @Output() mouseOut = new EventEmitter<void>();

    constructor(el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        fromEvent(this.element, 'mouseenter').pipe(
            switchMap(() =>
                timer(this.hoverDelay).pipe(
                    takeUntil(fromEvent(this.element, 'mouseleave'))
                )
            ),
            takeUntil(this.onDestroy)
        ).subscribe(() => this.mouseIn.next());

        fromEvent(this.element, 'mouseleave').pipe(
            debounceTime(100),
            takeUntil(this.onDestroy)
        ).subscribe(() => this.mouseOut.next());
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
