import {Injectable, OnDestroy} from '@angular/core';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {forkJoin, of, Subject, Subscription} from "rxjs";
import {first, map, takeUntil, tap} from "rxjs/operators";
import {ApiService} from '../../services/api/api.service';
import {ComponentType} from "../../_models/component-type";
import * as utils from "../../lib/utils";
import {AppScope} from '../../services/app_scope.service';
import {Series} from '../../_models/series';
import {OreBodyType} from '../../_models/ore-body-type';
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {ComponentTypeByRelationshipResponse, ComponentTypeDataService} from "../../data/component-type-data.service";
import {getAccountFilter, getManyRelationWithIdFilter, getRelationWithIdFilter} from "../../services/api/filter_utils";
import {ModelID} from "../../_typing/generic-types";

export interface LinkedData {
    series?: Series[],
    series_dict?: { [key: string]: Series },
    ore_body_types?: OreBodyType[],
    obt_dict?: { [key: string]: OreBodyType },
    component_types?: ComponentType[],
    ct_dict?: { [key: string]: ComponentType },
    ct_props_list_dict?: { [key: string]: string[] },
    et_props_list_dict?: { [key: string]: string[] }
    et_ct_list_dict?: { [key: string]: string[] }
}

@Injectable()
export class EventTypesTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    $linkedDataSubject: Subject<LinkedData> = new Subject<LinkedData>();
    $linkedData = this.$linkedDataSubject.asObservable();

    $linkedTypes: Subscription;

    linked_data: LinkedData = {
        series: null,
        series_dict: {},
        ore_body_types: null,
        obt_dict: {},
        component_types: null,
        ct_dict: {},
        et_props_list_dict: {},
        et_ct_list_dict: {}
    };

    constructor(private api: ApiService, private appScope: AppScope,
                private propData: ConstantPropertyDataService,
                private componentTypesData: ComponentTypeDataService) {
    }

    getEventTypesFilter(accountId?: ModelID) {
        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'name',
            op: 'notin',
            val: ['event', 'comment', 'downtime', 'Event', 'Comment', 'Downtime']
        }];
        if (accountId) options.filters.push(getAccountFilter(accountId));
        return options.filters;
    }

    updateSearchFilter(filter_string: string,accountId?: ModelID) {
        const filters = this.getEventTypesFilter(accountId);
        const atts = ['name'];
        const string_filters = {or: []};
        atts.forEach(att => {
            string_filters.or.push({op: 'ilike', name: att, val: '%' + filter_string + '%'});
        });

        filters.push(string_filters);
        return filters;
    }

    refreshLinkedTypes(event_types) {
        const ctrl = this;
        this.$linkedTypes = utils.refreshSubscription(this.$linkedTypes);
        const event_type_ids = event_types.map(et => et.id);
        this.$linkedTypes = forkJoin([this.getSeries(event_type_ids),
            this.getOreBodyTypes(event_type_ids), this.getComponentTypes(event_type_ids)])
            .subscribe(() => {
                this.$linkedDataSubject.next(this.linked_data);
            });
    }

    getOreBodyTypes(event_type_ids): any {
        const ctrl = this;
        const options = new SearchQueryOptions();

        options.filters = [
            {name: 'event_types', op: 'any', val: {name: 'id', op: 'in', val: event_type_ids}}
        ];
        return ctrl.api.ore_body_type.searchMany(options).pipe(
            map(result => {
                if (!result) {
                    return;
                }
                this.linked_data.ore_body_types = result.data;
                result.data.forEach(ob => this.linked_data.obt_dict[ob.id] = ob);
                this.$linkedDataSubject.next(this.linked_data);
                return result;
            }),
            first(), takeUntil(this.onDestroy)
        );
    }

    getSeries(event_type_ids): any {
        const ctrl = this;
        const options = new SearchQueryOptions();

        options.filters = [
            {name: 'event_type', op: 'has', val: {name: 'id', op: 'in', val: event_type_ids}}
        ];
        return ctrl.api.series_light.searchMany(options).pipe(
            map(result => {
                if (!result) {
                    return;
                }
                this.linked_data.series = result.data;
                result.data.forEach(ob => this.linked_data.series_dict[ob.id] = ob);
                this.$linkedDataSubject.next(this.linked_data);
                return result;
            }),
            first(), takeUntil(this.onDestroy)
        );
    }

    getComponentTypes(event_type_ids): any {
        if (event_type_ids?.length < 1) return of(null);
        return this.componentTypesData.streamComponentTypesByRelationshipIds(event_type_ids, 'event_types')
            .pipe(tap((result: ComponentTypeByRelationshipResponse) => {
                this.linked_data.component_types = result.component_types;
                this.linked_data.et_ct_list_dict = result.ct_list_dict;
                this.linked_data.ct_dict = result.ct_dict;
                this.$linkedDataSubject.next(this.linked_data);
            }));
    }

    getSingleEventTypeFilter(id: ModelID) {
        return [getRelationWithIdFilter('event_type', id)];
    }

    getManyEventTypeFilter(id: ModelID) {
        return [getManyRelationWithIdFilter('event_types', id)];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
