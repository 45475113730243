import {KeyMap} from "./generic-types";
import {CustomEventsConfig} from "../forms/custom-events-form/custom-events-form.component";
import {COMPONENT_EVENTS_CONFIG} from "../forms/component-events-table-form/component-events-table-form.component";
import {PivotTileConfig} from "./config/pivot-tile";

export type Query = RuleSet | Rule;

export enum ValidationCompareType {
    'input' = 'Input',
    'field' = 'Another Field',
}
type ValidationCompareTypeKeys = keyof typeof ValidationCompareType;
export interface ValidatorRule {
    field_value: any;
    field_name:string;
    op: string;
    value: any;
    value_name:string;
    data_type:string;
    allow_null_compare: boolean;
    value_compare_to: ValidationCompareTypeKeys;
}

export class Rule {
    field: string;
    operator: string;
    value?: any;  // Optional value property
    value_compare_to?: ValidationCompareTypeKeys;
    allow_null_compare?: boolean;

    constructor(field?: string, operator?: string, value?: any) {
        this.field = field;
        this.operator = operator;
        this.value = value;
    }
}

export type WireRuleSet = RuleSet;

export class RuleSet {
    condition: 'and' | 'or';
    rules: Query[];

    constructor() {
        this.condition = 'and';
        this.rules = [];
    }
}

// Type guard to check if a Query is a RuleSet
export function isRuleSet(item): item is RuleSet {
    return (item as RuleSet)?.condition !== undefined;
}

// Type guard to check if a Query is a Rule
export function isRule(item): item is Rule {
    return item && 'field' in item;
}

export type FieldMap = KeyMap<Field>;

export interface Field {
    name: string;
    value: string;
    type: string;
    options?: Option[];
}

export interface Option {
    name: string;
    value: any;
}

export type QueryBuilderConfigType = CustomEventsConfig | COMPONENT_EVENTS_CONFIG | PivotTileConfig;

export  interface ValidationConstant {
        value?:string | number | Date;
        data_type?:string;
}