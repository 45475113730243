import {Component, OnDestroy, OnInit} from "@angular/core";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {ApiService} from "../../services/api/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Subject, Subscription, take, timer} from "rxjs";
import {Tile as TileModel} from "../../_models/tile";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {switchMap, takeUntil, tap} from "rxjs/operators";

@Component({
    selector: 'series-table-focus-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="sheet_ready" [dtp]="dateInst.dtp" [tile]="tile" class="column-tile"></page-tile>' +
        '</div>',
    standalone: false
})
export class SeriesTableFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    tile: TileModel;
    sheet_ready: boolean;
    process: any;

    params_subscription: Subscription;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            this.sheet_ready = false;
            let p = ctrl.api.process.getById(params.processID).toPromise().then(response => {
                if (!response) return;
                ctrl.process = response.data
            });

            p.then(() => {
                this.plantData.setActiveProcess(this.process);
                ctrl.buildHeader();
                ctrl.tile = new TileModel();
                ctrl.tile.attributes.content = 'series-table';
                ctrl.tile.attributes.hide_edit = true;
                ctrl.tile.attributes.parameters = {
                    show_comments: true,
                    process: ctrl.process,
                    series_list: [],
                    columns: ['Status', 'Name', 'Description', 'Value', 'Forecast', 'MTD', "MTD Forecast"],
                    mobile_columns: ['Description', 'Value', 'MTD'],
                    title: ctrl.process.attributes.name
                };
                this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe(() => {
                    this.dateInst.dtp = this.dateTimePeriodService.setDefault(this.dateInst.dtp);
                });
                this.sheet_ready = true
            });
        });

        this.headerData.refreshTileSubject.pipe(switchMap(id => {
            this.sheet_ready = false;
            return timer(100).pipe(tap(() => {
                this.sheet_ready = true;
            }))
        }), takeUntil(this.onDestroy)).subscribe();
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    addSeries() {
        this.seriesData.upsertSeries(this.process).afterClosed().subscribe((series) => {
            this.dateInst.emitDateTimePeriodRefreshed(this.dateInst.dtp)
        })
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Series Table: ' + ctrl.process.attributes.name;
        ctrl.headerData.setPath(ctrl.process);
        ctrl.headerData.show_dtp = true;

        ctrl.headerData.buttons = [
            {name: "Add", func: this.addSeries.bind(ctrl), params: {ctrl: this}, class: 'icon-add'}
        ];
        ctrl.headerData.addDownload();
    };

}
