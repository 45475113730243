import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Series} from '../../_models/series';
import {AlertsTableService} from "./alerts-table.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Observable, Subject} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {first, takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../../services/api/response-types";
import {ApiService} from '../../services/api/api.service';
import {Alert} from '../../_models/alert';
import {PaginationDataSource} from '../../services/api/pagination-data-source';

@Component({
    selector: 'alerts-table',
    templateUrl: './alerts-table.component.html',
    styleUrls: ['./alerts-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [AlertsTableService],
    standalone: false
})
export class AlertsTableComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() series_list: Series[];

    filter_string = '';
    page_size = 1;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<Alert>;
    columns = ['data_exception_type', 'check_hi_hi', 'check_low_low', 'data_exception', 'users', 'is_disabled'];
    user_dict: any = {};
    series_dict: any;

    constructor(private cps: AlertsTableService,
                private api: ApiService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        const initialQuery: SearchQueryOptions = this.cps.getInitialSearchOptions(this.series_list);
        setTimeout(() => {
            this.paginator.pageSize = this.page_size;
            this.dataSource = new PaginationDataSource<Alert>(
                (query) => this.page(query),
                initialQuery,
                this.paginator,
                this.sort
            );

            this.dataSource.$page.pipe(tap((result: ListResponse<Alert>) => {
                    this.cps.getAlertsListsDetails(result.data)
                }),
                first(), takeUntil(this.onDestroy)).subscribe();
        })

        this.cps.userListSubject.pipe(takeUntil(this.onDestroy)).subscribe(dict => {
            this.user_dict = dict;
        })

    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.alerts.searchMany(query).pipe(
            first(), takeUntil(this.onDestroy)
        )
    }

    emitFilterQuery() {
        const filters = this.cps.getAlertsForSeriesFilter(this.series_list);
        this.dataSource.filterBy(filters);
    }

    updateSearchFilter() {
        // this.dataSource.filterBy(filters)
        let filters: any = this.cps.getAlertsForSeriesFilter(this.series_list);
        if (!this.filter_string) {
            this.dataSource.filterBy(filters);
            return
        }
        filters = filters.concat(this.cps.getAlertsSearchFilters(this.filter_string));
        this.dataSource.filterBy(filters);
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort)
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
