import {ComponentType} from "../_models/component-type";
import {Injectable, OnDestroy} from "@angular/core";
import {from, Observable, of, Subject} from "rxjs";
import {ApiService} from "../services/api/api.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {finalize, first, map, mergeMap, takeUntil} from "rxjs/operators";
import {ListResponse, SingleResponse} from "../services/api/response-types";
import {uniqueObjectList} from "../lib/utils";
import {BaseModel, ModelAttribute, ModelID} from "../_typing/generic-types";
import {BaseFlaskFilter, getManyRelationWithIdFilter} from "../services/api/filter_utils";

export interface ObjectListByRelationship<T> {
    object_list: T[];
    object_dict: Record<string, T>;
    relationship_object_list_dict: Record<string, string[]>;
}

/**
 * This class is for shared functions relating to data access on the component_type api
 **/
@Injectable({
    providedIn: 'root'
})
export class GenericDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService) {
    }

    /**Streams component_types by rel_ids
     * Returns an object of type ComponentTypeByParentResponse
     * @param rel_ids:string[]
     * @param rel_name:string
     */
    streamObjectListByRelationshipIds<T>(rel_ids: string[], rel_name: string, api_model: string, ids?: string[], names?: string[],
                                         filters?: any[], sort?: string, map_type: 'any' | 'has' = 'any'): Observable<ObjectListByRelationship<T> | any> {
        const ctrl = this;
        let object_list = [];
        let object_dict = {};
        let relationship_object_list_dict = {};

        const $objs: Observable<T>[] = [];
        rel_ids.forEach(id => {
            const options = new SearchQueryOptions();
            options.filters = [
                {name: rel_name, op: map_type, val: {name: 'id', op: 'eq', val: id}}
            ];
            if (ids && ids.length > 0) {
                options.filters.push({name: 'id', op: 'in', val: ids});
            }
            if (names && names.length > 0) {
                options.filters.push({name: 'name', op: 'in', val: names});
            }
            if (filters && filters.length > 0) {
                options.filters = options.filters.concat(filters);
            }
            if (sort) options.sort = sort;

            $objs.push(this.api[api_model].searchMany(options).pipe(
                map((result: ListResponse<ComponentType>) => {
                    if (!result) return;
                    object_list = uniqueObjectList([...object_list, ...result.data]);
                    relationship_object_list_dict[id] = result.data.map(obj => obj.id);
                    result.data.forEach(ob => object_dict[ob.id] = ob);
                    return {
                        object_list: object_list,
                        object_dict: object_dict,
                        relationship_object_list_dict: relationship_object_list_dict
                    };
                }),
                first(), takeUntil(this.onDestroy)
            ));
        })
        if ($objs.length < 1) return of(null);

        return from($objs).pipe(mergeMap(obj => obj, 20),
            finalize(() => {
            }))

    }

    generateFilteredModelFilter(filter_string: string, keys: ModelAttribute[]): any {
        /**
         * Used for sending a filtered search query when the user has typed in the 'search/filter field'.
         keys is the list of model attributes against which the filter should be applied
         e.g. look for string: 'CIL' in keys: [name, description]
         **/
        let filters: { or: BaseFlaskFilter[] } = {or: []};
        keys.forEach(key => {
            filters.or.push({name: key, op: 'ilike', val: `%${filter_string}%`});
        })
        return filters;
    }

    generateModelsByRelationshipIdFilter(rel_name: string, rel_value: string) {
        return [getManyRelationWithIdFilter(rel_name, rel_value)];
    }

    upsertModel<T>(api_name: string, data: BaseModel<T>): Observable<SingleResponse<T>> {
        let $save
        if (data.id) {
            $save = this.api[api_name].obsPatch(data);
        } else {
            $save = this.api[api_name].obsSave(data);
        }
        return $save;
    }

    getModelByIds<T>(api_name: string, ids: ModelID): Observable<ListResponse<T>> {
        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'id',
            op: 'in',
            val: ids
        }];
        return this.api[api_name].searchMany(options);
    }

    getModelById<T>(api_name: string, id: ModelID): Observable<SingleResponse<T>> {
        return this.api[api_name].getById(id);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
