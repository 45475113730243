import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {FileUploadType} from "../_models/api/file-upload-type";
import { HttpClient } from "@angular/common/http";
import {map, shareReplay} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ModelFileFieldService {

    private cachedFileTypes$: Observable<FileUploadType[]>;

    constructor(private http: HttpClient) {
    }

    getValidFileUploadTypes(): Observable<FileUploadType[]> {
        if (!this.cachedFileTypes$) {
            this.cachedFileTypes$ = this.http.get<FileUploadType[]>('/api/valid-upload-types').pipe(
                map((response: any) => response.data),
                shareReplay(1)
            );
        }
        return this.cachedFileTypes$;
    }

    uploadFile(model_name: string, object_id: string, field: string, file: File) {
        console.log('ModelFileFieldService - uploadFile: ');
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`/api/upload/${model_name}/${object_id}/${field}`, formData);
    }

    downloadFile(model_name: string, object_id: string, field: string): Observable<Blob> {
        const httpOptions = {'responseType'  : 'arraybuffer' as 'json'};
        return this.http.get<Blob>(`/api/download/${model_name}/${object_id}/${field}`, httpOptions);
    }

    deleteFile(object_id: string, field: string) {
        return this.http.delete(`/api/constant/component/${object_id}/${field}`);
    }
}
