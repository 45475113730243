import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {deepCopy} from "../../../lib/utils";

export interface DialogData {
    form_result: string;
    config: { paragraph_body: any };
}

@Component({
    selector: 'custom-html-text-form',
    templateUrl: './custom-html-text-form.component.html',
    styleUrls: ['./custom-html-text-form.component.less'],
    standalone: false
})
export class CustomHtmlTextFormComponent implements OnInit {
    showing_hints: boolean = false;
    customText: string;

    constructor(private dialogRef: MatDialogRef<CustomHtmlTextFormComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
        this.customText = deepCopy(this.dialogData.config.paragraph_body);
    }

    ngOnInit() {

    }

    save() {
        this.dialogRef.close({paragraph_body: this.customText});
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
