<div class="form-dialog">
  <h1>{{session_id ? 'Edit Page' : 'Add New Page'}} {{title}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints, 'show-audit-mixin': data.id}">
    <audit-mixin-indicator *ngIf="data.id" [model]="data"></audit-mixin-indicator>
    <mat-tab-group (selectedTabChange)="tabChange($event)" [disableRipple]="true">
      <mat-tab label="Friendly">
        <form class="form-section column space-between">
          <div class="row full-height">
            <mat-form-field (mouseenter)="hint='Tile_Title'">
              <mat-label>Title</mat-label>
              <input [(ngModel)]="json.name" matInput name="title" style="box-sizing: border-box;">
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Layout'">
              <mat-label>Layout</mat-label>
              <mat-select [(ngModel)]="json.json.layout" name="layout">
                <mat-option *ngFor="let layout of layouts"
                            [value]="layout">{{layout | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Orientation'">
              <mat-label>Print Orientation</mat-label>
              <mat-select [(ngModel)]="json.json.print_orientation" name="print_orientation">
                <mat-option value="portrait">Portrait</mat-option>
                <mat-option value="portrait-widescreen">Portrait Widescreen</mat-option>
                <mat-option value="landscape">Landscape</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row full-height">
              <select-many-groups  *ngIf="!session_id"
                  (selectionChangeComplete)="groupsSelected($event)"
                  [value]="[]"
                  [classes]="'fill'"
                  [label]="'Select Groups'" class="select-search select-many-search-wrapper">
              </select-many-groups>

            <mat-form-field (mouseenter)="hint='Layout'">
              <mat-label>Folders</mat-label>
              <mat-select #folderOptionSelect [(ngModel)]="data.relationships.folders.data" name="selected_folders"
                          multiple
                          [compareWith]="matSelectCompare"
                          (selectionChange)="getDefaultFolderOptions()">
                <option-list-search
                    #folderContentSearch
                    [placeholder]="'Search tiles'"
                    (inputChanged)="filterFolders($event)"
                    [selectElementRef]="folderOptionSelect"
                    (inputCleared)="resetFolderOptions()"
                ></option-list-search>
                <mat-option *ngFor="let folder of filteredFolderOptions"
                            [value]="folder.folder">{{folder.name}} {{folder.parent ? '(' + folder.parent.name + ')' : ''}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field matTooltip="The default path to this dashboard that will show in breadcrumbs."
                            (mouseenter)="hint='Default Folder'">
              <mat-label>Default Folder (Path)</mat-label>
              <mat-select [(ngModel)]="data.relationships.default_folder.data" name="selected_folder"
                          [compareWith]="matSelectCompare">
                <mat-option *ngFor="let folder of default_folder_options"
                            [value]="folder.folder">{{folder.name}}
                  ({{folder.parent ? '(' + folder.parent.name + ')' : ''}})
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox *ngIf="user_preference && session_id" (mouseenter)="hint='Set this as my default dashboard'"
                          [(ngModel)]="is_default_dashboard"
                          class="self-center"
                          name="default_dashboard" style="width:180px;">Default Dashboard
            </mat-checkbox>

          </div>

          <!--<form class="form-section column space-between">-->

          <div class="row full-height">
            <mat-form-field (mouseenter)="hint='Calendar'">
              <mat-label>Calendar</mat-label>
              <mat-select [(ngModel)]="json.json.calendar" name="calendar">
                <ng-container *ngFor="let calendar of calendars">
                  <mat-option
                      *ngIf="calendar.account_id === appScope.active_account_id || calendar.name === dateTimeService.defaultCalendar"
                      [value]="calendar.name">
                    <span>{{calendar.name | removeUnderscore | upperfirst}}</span>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>

              <select-filter *ngIf="ranges?.length"
                             [label]="'Range'"
                             (constant_changed)="json.range=$event"
                             [modelValue]="json.range"
                             [classes]="'fill'"
                             [options]="ranges" class="select-search">
              </select-filter>
              <div class="mat-hint">The default date range for data on this dashboard.</div>

              <select-filter *ngIf="samplePeriods?.length"
                             [label]="'Sample Period'"
                             (constant_changed)="json.json.sample_period=$event"
                             [modelValue]="json.json.sample_period"
                             [classes]="'fill'"
                             [options]="samplePeriods" class="select-search">
              </select-filter>
              <div class="mat-hint">The default sample period for data on this dashboard.</div>


          </div>

          <div class="row medium-height">
            <mat-checkbox (mouseenter)="hint='Auto Refresh'" [(ngModel)]="json.json.auto_refresh"
                          class="self-center"
                          name="auto_refresh">Auto Refresh
            </mat-checkbox>

            <mat-form-field (mouseenter)="hint='Refresh_Interval'" style="display: flex">
              <mat-label>Refresh Interval</mat-label>

              <input [(ngModel)]="json.json.refresh_time" matInput max="720" min="30" name="refresh_interval"
                     style="width: fit-content" type="number"> <span> Minutes</span>

            </mat-form-field>
            <div *ngIf="!canHideLogo()" class="spacer"></div>
            <mat-checkbox *ngIf="canHideLogo()" [(ngModel)]="json.json.hide_wire_logo"
                          class="self-center"
                          name="hide_mms_logo" style="width:180px;">Hide WIRE logo on print
            </mat-checkbox>

          </div>

          <div class="row full-height">
            <mat-form-field (mouseenter)="hint='DownloadUrl'">
              <label>Download URL</label>
              <input [(ngModel)]="json.url" matInput name="download_url">
              <mat-hint>The download url of the excel version of this report. (Only Admin can add files)</mat-hint>
            </mat-form-field>
          </div>
        </form>
      </mat-tab>

      <mat-tab *ngIf="json_ready && userService.isMMSUser()" label="Advanced">
        <ng-template matTabContent>
          <form class="form-section row ">
            <div class="row stretch">
              <p *ngIf="json_stringify_error === true">JSON was not ready for parsing. Please close this dialog,
                wait for the page to finish loading and open this dialog again.</p>
              <textarea *ngIf="json_stringify_error === false"
                        [(ngModel)]="json_string"
                        style="width:500px;height:300px;"
                        class="full" name="jsontext">
                    </textarea>
            </div>
          </form>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <div class="audit-text">
    <div *ngIf="session_id">Changed on {{data.attributes.changed_on | stringDate}}
      by {{data.attributes.changed_by_name}}
    </div>
  </div>
  <div class="action-buttons">
    <button (click)="onCloseClick()" [disabled]="disableActionButtons" class="btn-close">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button (click)="save()" [disabled]="disableActionButtons" class="btn-save">Save</button>
  </div>
</div>
