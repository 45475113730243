import {Injectable, OnDestroy} from '@angular/core';
import {forkJoin, Observable, Subject} from "rxjs";
import {Series} from "../../_models/series";
import {first, takeUntil, tap} from 'rxjs/operators';
import {ListResponse} from '../../services/api/response-types';
import {
    SeriesDataSeriesGroupDict,
    SeriesDataTableConfig,
    SeriesDataTableHeaderRows,
    SeriesGroupConfig
} from "../../forms/series-data-table-form/series-data-table-form.component";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {SeriesDataService} from "../../services/series_data.service";
import {SeriesDataTableData} from "../../_typing/components/series-data-table";
import {TimePeriod} from "../../_typing/components/time-period";
import {KeyMap, ModelID} from "../../_typing/generic-types";
import {MatTableDataSource} from "@angular/material/table";


@Injectable()
export class SeriesDataTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public readonly dataReady: Subject<SeriesDataTableData> = new Subject<SeriesDataTableData>();

    table_data: SeriesDataTableData = {
        series_list: [],
        series_data: null,
        series_missing: null,
        series_summary: null,
        group_dict: {}
    };
    dtp: IDateTimePeriod;

    constructor(private seriesDataService: SeriesDataService) {

    }

    getTimes(dtp: IDateTimePeriod, dateTimes: string[]): KeyMap<TimePeriod> {
        let previousPeriodEnd: string | null = null;
        const times: KeyMap<TimePeriod> = {};
        dateTimes.forEach((dateTime: string) => {
            let date_counter: Date = new Date(dateTime);
            if (dtp.sample_period.name.toLowerCase() === 'month') {
                date_counter.setMonth(date_counter.getMonth() - 1);
                if (date_counter < dtp.start || dateTimes.length === 1) {
                    date_counter = dtp.start;
                }
            } else {
                date_counter['addHours'](dtp.sample_period.hours * -1);
            }
            const periodStart: string = previousPeriodEnd || date_counter.toISOString();
            previousPeriodEnd = dateTime;
            times[dateTime] = {
                period_start: periodStart,
                period_end: dateTime,
                title: dtp.sample_period.format(new Date(dateTime), dtp)
            };
        });
        return times;
    }

    getSeriesData(config: SeriesDataTableConfig, dtp: IDateTimePeriod) {
        const list = this.seriesList(config);
        const series_ids = list.map(s => s.id);
        this.table_data.group_dict = this.getGroupDict(config, list);
        const join = [this.getSeriesBySeriesIds(series_ids), this.getData(dtp, series_ids, config.gss?.shift_type_id)];
        if (config.gss?.columns?.length > 0 && ['top', 'bottom'].includes(config.show_gss)) {
            join.push(this.getSeriesSummary(dtp, series_ids, config.gss.columns, config.gss?.estimate_type, config.gss?.shift_type_id))
        }
        forkJoin(join).pipe(first(), takeUntil(this.onDestroy)).subscribe()
    }

    getGroupDict(config: SeriesDataTableConfig, seriesList: Partial<SeriesGroupConfig>[]): SeriesDataSeriesGroupDict {
        let groupDict: SeriesDataSeriesGroupDict = {}
        if (config.header_rows?.custom_groups) {
            seriesList.forEach(s => {
                const group = s.group || "";
                groupDict[group] = (groupDict[group] || []).concat([s]);
            })
            return groupDict;
        }
        let processDict = {};
        config.process_list.forEach(p => processDict[p.id] = p.attributes.name);
        Object.keys(config.process_series_map).forEach(k => groupDict[processDict[k]] = config.process_series_map[k]);
        return groupDict;
    }

    seriesList(config: SeriesDataTableConfig): Partial<SeriesGroupConfig>[] {
        const list = Object.values(config.process_series_map)
            .reduce((prev: Partial<SeriesGroupConfig>[], curr: Partial<SeriesGroupConfig>[]) => prev = prev.concat(curr), []);
        return list;
    }

    private getSeriesBySeriesIds(series_ids: string[]): Observable<ListResponse<Series>> {
        return this.seriesDataService.getSeriesListByIds(series_ids).pipe(first(), takeUntil(this.onDestroy),
            tap(result => {
                this.table_data.series_list = result.data;
                this.dataReady.next(this.table_data);
            }))
    }

    private getParams(dtp: IDateTimePeriod, series_ids: string[]): any {
        return {
            series_list: series_ids,
            start: dtp.start.toISOString(),
            return_type: 'json',
            end: dtp.end.toISOString(),
            format: 'records',
            deepness: 2,
            columns: ['Value'],
            sample_period: dtp.sample_period.name,
            period_type: dtp.calendar
        }
    }

    private getData(dtp: IDateTimePeriod, series_ids: string[], shiftTypeId: number): Observable<any> {
        return this.seriesDataService.getSeriesData(dtp, series_ids, null, [], shiftTypeId, 'records')
            .pipe(tap(result => {
                this.table_data.series_data = result;

                this.table_data.series_data = {};
                result.data.forEach(s => {
                    this.table_data.series_data[s.index] = s;
                })

                this.table_data.series_missing = {};
                result.missing_values.forEach(s => {
                    this.table_data.series_missing[s.index] = s;
                });
                this.dataReady.next(this.table_data);
            }));
    }

    private getSeriesSummary(dtp: IDateTimePeriod, series_ids: string[], columns: string[], estimateTypes: string[], shiftTypeId?: number): Observable<any> {
        return this.seriesDataService.getSeriesSummary(dtp, series_ids, null, columns, estimateTypes, shiftTypeId)
            .pipe(tap(result => {
                this.table_data.series_summary = {};
                result.forEach(s => {
                    this.table_data.series_summary[s.ID] = s;
                });
                this.dataReady.next(this.table_data);
            }));
    }

    public showTimeColumn(config) {
        /**This function is just for working out where to display the time column since all header_rows are optional**/
        let dict: SeriesDataTableHeaderRows = {process: config.header_rows.process || config.header_rows.custom_groups};
        /**Just make the variable names shorter to work with8*/
        const [process, custom_groups, alias, unit, name, description, edit] = ['process', 'custom_groups', 'alias', 'unit', 'name', 'description', 'edit'].map(i => {
            return config.header_rows[i]
        })
        if (!(process || custom_groups)) {
            dict['name'] = true;
        }
        if (!(process || custom_groups || name || description)) {
            dict['alias'] = true;
        }
        if (!(process || custom_groups || name || description || alias)) {
            dict['unit'] = true;
        }
        if (!(process || custom_groups || name || description || alias || unit)) {
            dict['edit'] = true;
        }
        return dict;
    }


    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
