import {Directive, HostListener, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[enterToAction]',
    standalone: false
})
export class EnterToActionDirective {
    @Output() enterToAction = new EventEmitter<void>();

    constructor() {
    }

    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKey(event: KeyboardEvent) {
        event.preventDefault();
        this.enterToAction.emit();
    }
}
