import { ModelID } from "../../../_typing/generic-types";

interface ComponentTypeStubSchemaAttributes {
    name: string;
    description: string;
    base_type: string;
  }

  export class ComponentTypeStubSchema {
    id: ModelID = '';
    type: string = 'component_type';
    attributes: ComponentTypeStubSchemaAttributes = {
      name: '',
      description: '',
      base_type: ''
    };
  }
