import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from "rxjs";
import {SessionState} from '../../_models/session-state';
import {take} from 'rxjs/operators';
import {TileDataService} from "../../services/tile_data.service";
import {EventService} from '../../services/event.service';
import {ConfigColumn} from "../../_typing/config/config-column";
import {differenceBy} from "lodash-es";
import {ConfigStub} from '../../_typing/config-stub';
import {Series} from '../../_models/series';
import {Process} from '../../_models/process';
import {HeaderDataService} from "../../services/header_data.service";
import {EventBaseType} from '../../_models/event';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {deepCopy} from "../../lib/utils";
import {EventColumnSelectorEmitter} from "../../components/event-column-selector/event-column-selector.component";

export interface CommentsTableConfig {
    series_list: [],
    process_list: [],
    event_type: {},
    session_state_list: ConfigStub<SessionState>[];
    base_type: EventBaseType;
    columns: ConfigColumn[];
    search: boolean;
    filter_by_created_on: boolean;
    match_range: boolean;
    allow_delete: boolean;
    enable_row_selection: boolean;
}

@Component({
    selector: 'comments-table-form',
    templateUrl: './comments-table-form.component.html',
    styleUrls: ['./comments-table-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CommentsTableFormComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    hint: string;
    availableColumns: ConfigColumn[];
    private _editableColumns = ['start', 'end', 'comment'];
    selectedColumns: ConfigColumn[];
    disabledColumnOptions = deepCopy(this._editableColumns);
    disabledColumns: string[];

    constructor(private tileData: TileDataService, private eventService: EventService, private headerData: HeaderDataService) {
    }

    private _base_type: EventBaseType;

    @Input() set base_type(base_type: EventBaseType) {
        if (base_type !== this._base_type) {
            this._base_type = base_type;
            this.initialise();
        }
        this._base_type = base_type;
    };

    get base_type(): EventBaseType {
        return this._base_type
    }

    private _config: CommentsTableConfig;

    get config(): CommentsTableConfig {
        return this._config
    }

    @Input()
    set config(config: CommentsTableConfig) {
        this._config = config;
    }

    private _processes: any;

    get processes() {
        return this._processes
    }

    @Input()
    set processes(processes: any[]) {
        this._processes = processes;
    }

    private _series: any;

    get series() {
        return this._series
    }

    @Input()
    set series(series: any[]) {
        this._series = series;
    }

    private _session_states: any;

    get session_states() {
        return this._session_states
    }

    @Input()
    set session_states(session_states: SessionState[]) {
        this._session_states = session_states;
    }

    @Input() current_session_id: string;

    ngOnInit() {
        //this.initialise();
    }

    initialise() {
        if (!this.config.session_state_list) {
            this.config.session_state_list = [];
        }
        let col_options = this.eventService.comment_columns.filter(c => {
            return this.base_type === 'event' ? c.id !== 'session_state' : !['series', 'component'].includes(c.id)
        }).map(c => Object.assign({}, c, {disabled: true}));

        if (!this.config.columns) {
            this.config.columns = col_options;
        }
        this.config.columns.forEach(c => {
            if (!c.format) {
                c.format = {}
            }
        })

        this.disabledColumns = this.config.columns.filter(c => c.disabled !== false && this.disabledColumnOptions.includes(c.id)).map(c => c.id);


        if (this.config.search === undefined) {
            this.config.search = true;
        }
        this.availableColumns = differenceBy(col_options, this.config.columns, 'id');
        this.eventService.base_type_events.pipe(take(1)).subscribe(result => {
            this.config.event_type = new ConfigStub(result.commentType);
        });
        this.selectedColumns = deepCopy(this.config.columns);
    }

    updateCurrentSessionState(event) {
        let selected = this.config.session_state_list.map(ss => ss.id).includes(this.current_session_id);
        if (event.checked && !selected) {
            this.config.session_state_list = this.config.session_state_list.concat([{
                id: this.current_session_id,
                type: 'session_state',
                attributes: {name: this.headerData.title, base_type: 'session_state'}
            }]);
        } else if (!event.checked && selected) {
            this.config.session_state_list = this.config.session_state_list.filter(ss => ss.id !== this.current_session_id);
        }
    }

    isCurrentPageSelected() {
        return this.config.session_state_list.map(ss => ss.id).includes(this.current_session_id);
    }

    updateSeries(event) {
        this.config.series_list = event.map(e => new ConfigStub<Series>(e));
    }

    updatePages(event) {
        this.config.session_state_list = event.map(e => new ConfigStub<SessionState>(e));
    }

    updateProcesses(event) {
        this.config.process_list = event.map(e => new ConfigStub<Process>(e));
    }

    updateFilterConfig(event: MatCheckboxChange, key?: string): void {
        if (event.checked) {
            if (key === 'match_range') {
                this.config.filter_by_created_on = false;
            } else {
                this.config.match_range = false;
            }
        }
    }

    columnsChanged(event: Partial<EventColumnSelectorEmitter>) {
        const newCols: string[] = differenceBy(event.selected_columns, this.config.columns, 'id').map(c => c.id);
        this.selectedColumns = event.selected_columns;
        this.availableColumns = event.available_columns;
        this.config.columns = deepCopy(this.selectedColumns);
        this.disabledColumnOptions = this.config.columns.filter(c => this._editableColumns.includes(c.id)).map(c => c.id);
        this.updateDisabled([...newCols, ...this.disabledColumns]);
    }

    updateDisabled(event) {
        this.config.columns.map(c => c.disabled = event.includes(c.id));
        this.disabledColumns = this.config.columns.filter(c => c.disabled !== false && this.disabledColumnOptions.includes(c.id)).map(c => c.id);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
