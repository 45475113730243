import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {detectLimits, getLimitsPrompt} from "../../../lib/utils";
import {ModelID, ModelName, ModelWithLimits} from "../../../_typing/generic-types";
import {MatDialogRef} from "@angular/material/dialog";
import {CustomDialogComponent} from "../../../forms/custom-dialog/custom-dialog.component";
import {ConstantValue} from "../../../_models/api/generic-constant";
import {UserService} from "../../../services/user.service";
import {WireComponent} from "../../../_models/component";
import {FormDialogService} from "../../../services/form-dialog.service";
import {NotificationService} from "../../../services/notification.service";
import {EventConfigColumn} from "../../../_typing/config/config-column";

@Component({
    selector: 'constant-limits-prompt',
    templateUrl: './constant-limits-prompt.component.html',
    styleUrls: ['./constant-limits-prompt.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ConstantLimitsPromptComponent implements OnInit {
    @Input() dialogRef: MatDialogRef<CustomDialogComponent>;
    @Input() limits: ModelWithLimits<any>;
    @Input() value: ConstantValue;
    @Input() okText: string = 'OK';
    @Input() cancelText: string = 'Cancel';
    @Input() cpColumn: EventConfigColumn;
    @Input() component: WireComponent;
    newValue: ConstantValue;
    promptMessage: string;
    showWarning: boolean = false;
    warningMessage: string = "Your input value must be within the given limits."
    warningCount: number = 0;
    inputType: 'text' | 'number' = 'number';
    commentDialogOpen: boolean = false;

    constructor(private userService: UserService,
                private notification: NotificationService,
                private formDialogService: FormDialogService) {
    }

    ngOnInit(): void {
        this.setPromptMessage();
        this.dialogRef.disableClose = true;
    }

    setPromptMessage() {
        this.promptMessage = `${this.cpColumn.title} ${getLimitsPrompt(this.limits, true)}`;
    }


    validateValue(): void {
        if ((!this.newValue && this.newValue !== 0) || isNaN(Number(this.newValue))) {
            this.warn();
            return;
        }
        if (detectLimits(this.limits, this.newValue).error) {
            if (!this.userService.isAdminUser()) {
                this.warn();
                return;
            } else {
                this.askForComment();
                return;
            }
        }
        this.dialogRef.close(this.newValue);
    }

    private warn() {
        this.showWarning = true;
        this.warningCount += 1;
        if (this.warningCount >= 3) {
            this.notification.openWarning("Please contact your administrator to set an out-of-limits value.")
            this.warningCount = 0;
        }
    }

    askForComment() {
        const addToComment: string = `\n#${this.cpColumn.title} (${this.cpColumn.id})`;
        const config = {
            prompt_message: "Please enter a reason for entering an out-of-limits value.",
            comment_append: addToComment,
            component_list: [this.component]
        };

        const commentDialog = this.formDialogService.openCommentPrompt(config);
        this.commentDialogOpen = true;
        commentDialog.afterClosed().subscribe(result => {
            this.commentDialogOpen = false;
            if (result === false) {
                //Prompt was cancelled so allow user to reenter a value
                return;
            }
            this.dialogRef.close(this.newValue);
        });
    }

    ok() {
        if(this.commentDialogOpen) return;
        this.validateValue();
    }

    cancel() {
        if(this.commentDialogOpen) return;
        this.dialogRef.close(false)
    }
}
