<div class="option-list-search">
  <input #searchInput
         (keydown)="handleKeydown($event)"
         (input)="relayInput()"
         [(ngModel)]="filterString"
         [placeholder]="placeholder"
         (keydown.space)="$event.stopPropagation()"
         (click)="$event.stopPropagation()"
  />

  <account-filter *ngIf="allowAccountSelection" (accountFilterEmitter)="filterByAccount($event)"
                  [multiple]="true" [allowedAccountIds]="accountIds"></account-filter>

  <mat-icon (click)="relayCleared();$event.stopPropagation();"
            [ngClass]="{'clear-disabled': filterString.length < 1}"
            class="search-clear" matTooltip="Clear search" [matTooltipShowDelay]="TOOLTIP_SHOW_DELAY">clear
  </mat-icon>
</div>
