import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import { HttpClient } from "@angular/common/http";
import {HeaderDataService} from "../../services/header_data.service";
import {MatDialog} from "@angular/material/dialog";
import {SeriesDataService} from "../../services/series_data.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {forkJoin, Observable, Subject} from "rxjs";
import {map, switchMap, takeUntil, tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {FormDialogService} from "../../services/form-dialog.service";
import {NotificationService} from "../../services/notification.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {getBaseFilter} from "../../services/api/filter_utils";
import {PaginationDataSourceWithAddRow} from "../../services/api/pagination-data-source-with-add-row";
import {Event, Event as WireEvent} from "../../_models/event";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {Series} from "../../_models/series";
import {ListResponse} from "../../services/api/response-types";
import {KeyMap} from "../../_typing/generic-types";

@Component({
    selector: 'calculationCheckTable',
    templateUrl: 'calculation-check-table.component.html',
    standalone: false
})
export class CalculationCheckTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    private readonly onDestroy = new Subject<void>();

    public calculations: any = [];
    public calcColumns: string[] = ['calculation', 'description', 'name_formula', 'status', 'message'];
    dataSource: PaginationDataSource<Series>;
    pageSize: number = 10;
    pageSizeOptions: number[] = [10, 20, 50, 100, 200];
    status: any;
    statusDict: KeyMap<string> = {};
    messageDict: KeyMap<string> = {};

    constructor(private api: ApiService,
                private http: HttpClient,
                public headerData: HeaderDataService,
                public dialog: MatDialog,
                private seriesData: SeriesDataService,
                private notification: NotificationService,
                private formDialogService: FormDialogService,) {
    }

    ngOnInit(): void {
        this.headerData.title = 'Calculation Validation';
        this.buildHeader();


    }

    ngAfterViewInit() {
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'name';
        this.paginator.pageSize = this.pageSize;
        initialQuery.filters = this.getCalcsFilter();

        this.dataSource = new PaginationDataSource<Series>(
            (query) => this.pageCalculations(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            switchMap(() => {
                return this.getChecks();
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
    }

    private getCalcsFilter(): any[] {
        return [getBaseFilter(true, 'is_calculation')];
    }


    pageCalculations(query: SearchQueryOptions): Observable<ListResponse<Series>> {
        return this.api.calculation_light.searchMany(query).pipe(tap(listResponse => {
            this.calculations = listResponse.data;
        }));
    }

    refreshCalcs(extraFilters:any[]) {
        this.paginator.pageIndex = 0;
        let filters = this.getCalcsFilter();
        if(extraFilters?.length){
            filters = filters.concat(extraFilters)
        }
        this.dataSource.filterBy(filters);
    }

    getChecks(): Observable<any> {
        const ctrl = this;
        let $promises = [];
        this.calculations.forEach(function (calc) {
            let engineering_unit_id = null;
            if (calc.relationships.engineering_unit.data != null) {
                engineering_unit_id = calc.relationships.engineering_unit.data.id;
            }
            // TODO rework this request to use the utils.httpParamSerializer
            $promises.push(ctrl.http.get(
                    '/api/CheckCalc?name_formula=' + encodeURIComponent(calc.attributes.name_formula) + '&engineering_unit_id='
                    + engineering_unit_id + '&series_id=' + calc.id
                ).pipe(tap({
                    next: (response: { status: boolean | null, msg: null }) => {
                        if (response.status === true) {
                            ctrl.statusDict[calc.id] = "Valid";
                            ctrl.messageDict[calc.id] = response.msg;
                        } else if (response.status === false) {
                            calc.status = "Invalid";
                            calc.message = response.msg;
                        }
                    }, error: reject => {
                        ctrl.statusDict[calc.id] = "Invalid";
                        ctrl.messageDict[calc.id] = reject.error.msg;
                        console.log('Calculation invalid (400) error handled.');
                        return reject;
                    }, complete: () => {
                        ctrl.dataSource.data = ctrl.calculations;
                    }
                }))
            );
        });

        return forkJoin($promises);
    }

    getRowColor(calc: Series, col) {
        if (col === 'message') {
            if (this.statusDict[calc.id] === "Invalid") {
                return '#f4bebc';
            } else {
                if (this.messageDict[calc.id] !== undefined) {
                    if (this.messageDict[calc.id].includes("WARNING")) {
                        return '#ffe4bb';
                    } else {
                        return '#a9c6a6';
                    }
                }
            }
        }
    }

    applyFilter(filterValue: any) {
        let userFilters = {or: []};
        ['name', 'description', 'formula'].forEach(att => {
            userFilters.or.push({op: 'ilike', name: att, val: '%' + filterValue + '%'});
        });
        this.refreshCalcs([userFilters])
    }

    openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name);
    }

    editSeries(element) {
        let $series_full = this.api.series.getById(element.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            this.seriesData.upsertSeries(null, series_full).afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                    element.attributes['calculation'] = updated_series.attributes.name;
                    element.attributes['description'] = updated_series.attributes.description;
                    element.attributes['name_formula'] = updated_series.attributes.name_formula;
                    this.notification.openSuccess("Series saved. Click refresh to run validation again",);
                }
            });
        });
    }

    buildHeader() {
        this.headerData.title = 'Validate Calculations';
        this.headerData.buttons = [
            {name: 'Refresh', func: this.refreshCalcs.bind(this), class: 'icon-refresh', params: {}}
        ];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
