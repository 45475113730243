<div class="options-override-row">
  <ng-container *ngIf="!(dataService.isLoading$ | async)">
    <!--TODO Add eventemitter to select-search-component for no data found-->
    <!--    <h3 *ngIf="dataService.constant_properties$.getValue().length <= 0">-->
    <!--      No Constant Properties for selected Component Type!</h3>-->
    <select-search-component
        (selectionChange)="setConstantProperty($event.value)"
        [api_model]="'constant_property_light'"
        [custom_filters]="dataService.component_type_property_filter || templateService.property_filter"
        [value]="constantPropertyID"
        [compareFunctionName]="'compareValueToId'"
        [placeholder]="'Select Constant Property for column'"
        [classes]="'fill'"
        [disabled]="readonly"
        style="width:100%;"
    ></select-search-component>
  </ng-container>
  <ngx-skeleton-loader
      *ngIf="dataService.isLoading$ | async" count="1"
      title="Select Component Type to show Constant Properties"
      [theme]="{'margin-bottom': '0'}"
      class="progress-skeleton"
      animation="pulse"
      appearance="line"
  ></ngx-skeleton-loader>
</div>
