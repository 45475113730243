import {Component, Inject, QueryList, ViewChildren, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {cloneDeep as _cloneDeep} from "lodash-es";
import {ApiService} from "../../services/api/api.service";
import {combineLatest, forkJoin, Observable, of, Subscription} from "rxjs";
import {catchError, concatMap, filter, first, map, mergeMap, takeUntil, tap} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import {AppScope} from '../../services/app_scope.service';
import {SaveableBase, SaveService} from "../../services/save/save.service";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Alert} from "../../_models/alert";
import {UserPreference} from "../../_models/user-preference";
import {KeyMap, ModelID} from '../../_typing/generic-types';
import {BaseComponent} from '../../shared/base.component';
import {create_stubs, refreshSubscription} from "../../lib/utils";
import {User, UserJson} from "../../_models/users";
import {SingleResponse} from "../../services/api/response-types";
import {NotificationService} from "../../services/notification.service";
import {deepCopy} from "../../lib/utils";
import {getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {UserService} from "../../services/user.service";
import {Role} from "../../_models/role";

export interface DialogData {
    name: string;
}

export enum UserFormTabs {
    BASIC = 0,
    ACCESS = 1,
    GROUPS = 2,
    PAGES = 3,
    RESTRICTED = 4,
    PREFERENCES = 5
}


@Component({
    selector: 'user-form',
    templateUrl: 'user-form.component.html',
    styleUrls: ['user-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SaveService, DateTimePeriodService],
    standalone: false
})
export class UserFormComponent extends BaseComponent {

    current_tab: number = UserFormTabs.BASIC;
    tab_status: KeyMap<boolean> = {};
    tabs = UserFormTabs;
    pages: any[] = [];
    user: User;
    // show: boolean;
    series_list: any[] = [];
    roles_list: any[] = [];
    alerts_list: Alert[] = [];
    showing_hints: boolean = false;
    isSaving: boolean = false;
    roleFilters: any[];
    userAccountIds: ModelID[];
    userRoles: Role[];

    hint: string = 'Name';

    // featureprocess_map: {} = {};
    restrictedUserViews: any[] = [];

    canDisableOTP: boolean = false;
    customRestrictedPages: { name: string, url: any } = {name: null, url: null};
    customRestrictedPagesList: UserJson[] = [];

    user_preferences: UserPreference;
    invalid: boolean = false;

    $save: Subscription;
    @ViewChildren('saveable') saveableChildren: QueryList<SaveableBase>;

    constructor(public api: ApiService,
                public appScope: AppScope,
                public dialogRef: MatDialogRef<UserFormComponent>,
                private notification: NotificationService,
                public http: HttpClient,
                @Inject(MAT_DIALOG_DATA) public data: User,
                private saveService: SaveService,
                private userService: UserService) {
        super();
        this.user = deepCopy(data);

        for (const tab in UserFormTabs) {
            if (!isNaN(Number(tab))) {
                this.tab_status[tab] = false;
            }
        }
    }

    ngOnInit(): void {
        this.setCanDisableOTP();
        this.api.users.getById(this.user.id).pipe(concatMap(user => {
            const sources = [];
            this.user = user.data;
            if (this.user.attributes.restricted_access === true && this.user.attributes.json) {
                this.customRestrictedPagesList = this.user.attributes.json;
            }
            this.userAccountIds = this.userService.getUserAccountIds(this.user);
            this.setRoleFilters();

            // full list of series
            sources.push(this.api.series_light.searchMany().pipe(takeUntil(this.onDestroy), tap(response => {
                this.series_list = response.data;
            })));

            // full list of roles
            sources.push(this.api.role.searchMany().pipe(tap((result) => {
                this.roles_list = result.data;
                this.userRoles = this.roles_list.filter(role => this.user.relationships.roles.data?.map(r => r.id)?.includes(role.id));
            })));

            // full list of alerts
            sources.push(this.api.alerts.searchMany().pipe(tap(response => {
                this.alerts_list = response.data;
            })));

            return forkJoin(sources)
        }), takeUntil(this.onDestroy)).subscribe();
    }

    rolesChanged($event) {
        this.userRoles = $event;
        this._validateRolesAndAccounts();
    }

    private _validateRolesAndAccounts(showNotification: boolean = true) {
        if (!(this.userService.validateUserAccounts(this.userAccountIds) && this.userService.validateUserRoles(this.userAccountIds, this.userRoles, showNotification))) {
            this.invalid = true;
            return;
        }
        this.invalid = false;
    }

    setRoleFilters() {
        this.roleFilters = [getRelationWithManyIdsFilter('account', this.userAccountIds)];
    }

    accountsChanged($event) {
        this.userAccountIds = $event.map(a => a.id);
        if (this.userAccountIds?.length) {
            this.userRoles = this.userService.updateDefaultRoleForAllAccounts(this.userAccountIds, this.userRoles, this.roles_list);
        }
        this.setRoleFilters();
        this._validateRolesAndAccounts(false);
    }

    private _rolesOrAccountsChanged(): boolean {
        return this.user.relationships.accounts.data?.map(a => a.id) !== this.userAccountIds ||
            this.user.relationships.roles.data?.map(r => r.id) !== this.userRoles.map(r => r.id);
    }

    onTabClick($event: MatTabChangeEvent) {
        this.current_tab = $event.index;
        this.tab_status[$event.index] = true;
    }

    private _hasChanges(): boolean {
        return (JSON.stringify(this.data) !== JSON.stringify(this.user) || this._rolesOrAccountsChanged())
            && !this.invalid;
    }

    canSave() {
        let saveableChild: boolean = false;
        this.saveableChildren?.forEach((child, index) => {
            if (child.canSave()) {
                saveableChild = true;
                return;
            }
        })
        return !this.isSaving && (this._hasChanges() || saveableChild);
    }

    save() {
        this.$save = refreshSubscription(this.$save);
        this.$save = this.saveUser(false).pipe(
            takeUntil(this.onDestroy))
            .subscribe((result => {
                if (result) {
                    this.onCloseClick();
                }
            }));
    }

    apply() {
        this.$save = refreshSubscription(this.$save);
        this.$save = this.saveUser().pipe(tap((result => {
        }))).subscribe();
    }

    private saveUser(refresh_inner: boolean = true): Observable<any> {
        let status_message: string;

        if (this.user.attributes.restricted_access) {
            if (this.customRestrictedPagesList.length > 0) {
                this.user.attributes.json = this.customRestrictedPagesList;
            }
        }

        delete this.user.relationships.changed_by;
        delete this.user.relationships.created_by;
        delete this.user.relationships.groups;
        delete this.user.relationships.pages;
        delete this.user.relationships.default_dashboards;

        this.user.relationships.accounts.data = this.userAccountIds.map(accountId => {
            return {id: accountId, type: 'account'}
        });
        this.user.relationships.roles.data = deepCopy(this.userRoles);
        create_stubs(this.user, 'roles');

        const $userPatch = this._hasChanges() ? this.api.users.obsPatch(this.user) : of({data: this.user})
        return $userPatch.pipe(
            // If user saves successfully, initiate save on other components if required
            concatMap((user_result: SingleResponse<User> | { data: User }) => {
                console.log('User saved: success ');
                status_message = 'User: saved';
                this.user = Object.assign({}, user_result.data);
                this.data = deepCopy(this.user);
                /***Initiating inner save. Child components will publish their status as 'saving'***/
                this.saveService.emitSave(refresh_inner);
                /***Subscribe to child component componentStatus ReplaySubject, filtered for success/error status***/
                return this.getChildSaveStatus(user_result?.data, status_message).pipe(
                    mergeMap(msg => {
                        this.notification.openSuccess(msg, 10000);
                        return msg;
                    }));
            }),
            catchError(e => {
                status_message += 'User: error';
                this.isSaving = false;
                let errorMessage = e.statusText === "Forbidden" ? "Sorry, you cannot save change to this user as you do not have the correct account access." : "A problem occurred updating the user";
                this.notification.openError(errorMessage, 10000);
                return of(null);
            })
        );
    }

    getChildSaveStatus(user_result: User, status_message): Observable<string> {
        if (this.saveableChildren.length < 1) {
            return of(status_message);
        }

        const obs: Observable<any>[] = [of(user_result)];
        this.saveableChildren.forEach((child, index) => {
            obs.push(
                child.getStatusObservable().pipe(
                    filter(status => status.value === 'success' || status.value === 'error'),
                    first(),
                    tap((status) => {
                        if (status.value === 'error') {
                            status_message += '<div>The following error(s) occurred in ' + status.key + ':</div></br>';
                            status.errors.forEach(e => {
                                status_message += '<div>' + e.substring(0, 120) + '...</div></br>';
                            });
                        }
                    })
                )
            );
        });

        return combineLatest(obs).pipe(map(() => status_message));
    }

    alertsFunction = (obj) => {
        if (obj && obj.relationships && obj.relationships.series && obj.relationships.series.data) {
            let alert_series = this.series_list.find(series => obj && obj.relationships.series.data.id === series.id);
            return alert_series.attributes.description ? alert_series.attributes.description : alert_series.attributes.name;
        } else {
            return 'Empty alert'; // check alerts in admin portal, alerts need to have a series assigned to them
        }
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    clear() {
        this.restrictedUserViews = [];
        this.customRestrictedPagesList = [];
    }

    removeView(index) {
        this.customRestrictedPagesList.splice(index, 1);
    }

    addPage() {
        const ctrl = this;
        if (!this.user.attributes.restricted_access) {
            this.notification.openError('Please tick off the "Restricted access" to make the user a restricted access user');
        }

        if (this.user.attributes.restricted_access) {
            if (this.customRestrictedPages.url == null || this.customRestrictedPages.name == null) {
                this.notification.openError('Please add a page name and URL');
            } else {
                this.customRestrictedPagesList.push(_cloneDeep(this.customRestrictedPages));
                this.customRestrictedPages.url = null;
                this.customRestrictedPages.name = null;
            }
        }
    }


    setCanDisableOTP() {
        // If "require_otp" is currently true then an Admin can disable it, otherwise user must use MySecurity page to enable
        if (this.user.attributes.require_otp === true && (this.user.attributes.is_super === true ||
            this.user.attributes.role_names.includes("Administrator") ||
            this.user.attributes.role_names.includes("Super_User"))) {
            this.canDisableOTP = true;
        } // Otherwise false set on declaration
    }
}
