import * as Handsontable from "handsontable";
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit, Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator, HOTLookup} from "../../services/handsontable-generator.service";
import {HeaderDataService} from "../../services/header_data.service";
import * as utils from '../../lib/utils';
import {deepCopy, uniqueList} from '../../lib/utils';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {SeriesDataService} from "../../services/series_data.service";
import {TileDataService} from "../../services/tile_data.service";
import {catchError, concatMap, finalize, first, map, switchMap, takeUntil, tap, take, delay} from "rxjs/operators";
import {forkJoin, Observable, of, Subject, Subscription, EMPTY} from "rxjs";
import {HotInstance} from "../../services/hot-instance";
import {AppScope} from '../../services/app_scope.service';
import {EventType} from '../../_models/event-type';
import {OreBody} from '../../_models/ore-body';
import {NotificationService} from "../../services/notification.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {CustomEventsService} from "../../services/custom-events.service";
import {ListResponse} from "../../services/api/response-types";
import {Component as WireComponent} from "../../_models/component";
import {Event as WireEvent} from "../../_models/event";
import {groupBy as _groupBy, difference as _difference, uniq as _uniq} from "lodash-es";
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {PaginationDataSource} from '../../services/api/pagination-data-source';
import {CustomEventsDataService} from '../../tables/custom-events-table/custom-events-data.service';
import {PropByRelationshipResponse} from "../../data/constant-property-data.service";
import {ConstantProperty} from '../../_models/constant-property';
import {ComponentType} from "../../_models/component-type";
import {CUSTOM_EVENTS_CONFIG} from "../../forms/custom-events-form/custom-events-form.component";
import {IDMap} from '../../_typing/generic-types';
import {ComponentTypeByRelationshipResponse} from "../../data/component-type-data.service";
import {
    getAccountFilter,
    getManyRelationWithIdsFilter,
    getRelationWithIdFilter,
    getRelationWithManyIdsFilter,
} from "../../services/api/filter_utils";
import {FormDialogService} from "../../services/form-dialog.service";
import {EventDataService} from "../../data/event-data.service";
import {ConstantLockedDict, LockDataService} from "../../services/lock-data.service";
import {getColIds, getPropertyIds, getColId, getComponentTypeIds} from '../../tables/custom-events-table/utils';
import {KeyMap} from '../../_typing/generic-types';
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

export interface HOTDataCoords {
    startRow: number;
    startCol: number;
    endRow: number;
    endCol: number;
}

@Component({
    selector: 'event-sheet',
    templateUrl: 'event-sheet-view.component.html',
    styleUrls: ['../handson-sheet.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EventSheetViewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('event_anchor') el: ElementRef;
    @ViewChild('utils_bar') utilsBar: ElementRef;
    @ViewChild('scroll_area') scrollArea: ElementRef;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: PaginationDataSource<WireEvent>;

    @Input() config: CUSTOM_EVENTS_CONFIG;
    allow_edit: boolean;
    column_list: any[];
    constant_properties: any;
    cp_name_dict: { [key: string]: string[] } = {};
    cp_id_dict: IDMap<ConstantProperty> = {};
    component_types_dict: IDMap<ComponentType> = {};
    equipment: any[];
    component_types: any[];
    component_type_map: any = {};
    components: any[];
    data: any;
    dtp: IDateTimePeriod;
    event_prop_dict: { [key: string]: string[] } = {};
    event_series: any;
    etNameDict: IDMap<Partial<EventType>> = {};
    hot: HotInstance;
    source_dest_map: { [key: string]: { source_obs: OreBody[], destination_obs: OreBody[] } } = {};
    ore_bodies: OreBody[];
    inputLookups: { [key: string]: { source: any; valueLookup: {}; idLookup: {}; } } = {};
    outputLookups: { [key: string]: { source: any; valueLookup: {}; idLookup: {}; } } = {};
    non_editable_columns: any[];
    hidden_columns: any[];
    required_columns: any[] = [];
    componentLookups: HOTLookup = {};
    schema: any;
    series_ids: any[];
    series_list: any;
    series_permissions: any;
    seriesList: any;
    single: boolean;
    title: string;
    datePickerConfig: any;
    users: any[];
    event_type_list: any[];
    buttons: { name: string; func: () => any; params?: {}; class: string; HoverOverHint: string; }[];
    ec_to_save: any = [];
    ct_name_dict: KeyMap<string> = {};
    event_ct_component_map: {
        [key: string]: { [key: string]: WireComponent | { id: string | null, type: 'component' } }
    } = {};

    current_sort;
    continue_paste = true;
    @ViewChild('event_container') event_container: ElementRef;

    private readonly onDestroy = new Subject<void>();
    saving: boolean = false;
    page_size: number = 20;
    page_size_options = [10, 20, 50, 100, 200];
    events_total;
    filter_string: string = '';
    filter_warning: string;
    id_title_map: { [key: string]: string } = {};
    title_id_map: { [key: string]: string } = {};
    etCtQueryDict: KeyMap<KeyMap<any[]>> = {};

    locked_dict: ConstantLockedDict = {};
    row_locked_dict: KeyMap<boolean> = {};
    et_ct_dict: KeyMap<string[]>;
    $page: Subscription;
    private masterTable: HTMLElement | null = null;
    private topClone: HTMLElement | null = null;
    private scrollListener: (() => void) | null = null;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private handsontableGenerator: HandsontableGenerator,
                private dateTimePeriod: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private seriesData: SeriesDataService,
                private tileData: TileDataService,
                private appScope: AppScope,
                private notification: NotificationService,
                private customEventsService: CustomEventsService,
                private customEventsData: CustomEventsDataService,
                private eventDataService: EventDataService,
                private lockDataService: LockDataService,
                private formDialogService: FormDialogService,
                private renderer: Renderer2) {
        this.hot = new HotInstance(['attributes.start']);
    }

    @HostListener('window:resize', ['$event']) // for window scroll events
    onResize(event) {
        this.resizeUpdate();
    }

    resizeUpdate() {
        const utilsHeight = this.utilsBar?.nativeElement?.clientHeight;
        const scrollArea = this.scrollArea?.['targetRef']?.nativeElement;
        if (scrollArea && utilsHeight) {
            this.renderer.setStyle(scrollArea, 'height', `calc(100% - ${utilsHeight}px)`);
        }
        if (this.hot.instance) {
            this.hot.instance.render();
        }
    }

    ngOnInit(): void {
        this.dateInst.dateTimePeriodRefreshed$.pipe(
            takeUntil(this.onDestroy)
        ).subscribe((dtp) => {
            this.dtp = dtp;
            let filters = this.getEventQuery();
            this.dataSource.filterBy(filters);
            this.createTable();
        });

        this.dateInst.dateTimePeriodChanged$.pipe(take(1), concatMap((dtp: IDateTimePeriod) => {

            if (this.config.column_widths) {
                this.handsontableGenerator.col_widths = utils.deepCopy(this.config.column_widths);
            }
            this.dtp = this.dateInst.dtp;

            this.series_list = this.seriesList;
            this.hidden_columns = this.config.hidden_columns;
            this.single = this.config.event_types.length === 1;
            this.etNameDict = {};
            this.title = 'Custom Events';

            this.datePickerConfig = this.setDatePickerConfig(this.dtp);

            const obs_list = [];

            let date_filter_logic = {
                or: [{
                    and: [{name: 'opening_date', op: 'ge', val: this.dtp.start}, {
                        name: 'opening_date',
                        op: 'lt',
                        val: this.dtp.end
                    }]
                }, {
                    and: [{name: 'closing_date', op: 'ge', val: this.dtp.start}, {
                        name: 'closing_date',
                        op: 'lt',
                        val: this.dtp.end
                    }]
                }, {
                    and: [{
                        name: 'opening_date',
                        op: 'lt',
                        val: this.dtp.start
                    }, {
                        or: [{name: 'closing_date', op: 'eq', val: null},
                            {name: 'closing_date', op: 'gt', val: this.dtp.start}
                        ]
                    }]
                }]
            };

            // Get all ore bodies having an ore_body_type that is associated with the selected event_types
            obs_list.push(this.api.ore_body_light.search(
                this.api.prep_q([{
                    "and": [getAccountFilter(this.appScope.active_account_id), {
                        name: 'type',
                        op: 'has',
                        val: {
                            name: 'event_types',
                            op: 'any',
                            val: {name: 'id', op: 'in', val: this.config.event_types.map(et => et.id)}
                        }
                    }],
                }, date_filter_logic])
            ).pipe(map(response => {
                this.ore_bodies = response.data;
            })));

            const options = new SearchQueryOptions();
            options.filters = [{name: 'id', op: 'in', val: this.config.event_types.map(et => et.id)}];
            obs_list.push(this.api.event_type.searchMany(options)
                .pipe(concatMap(response => {
                    this.event_type_list = response.data;
                    let et_dict: IDMap<EventType> = {};
                    this.event_type_list.map(et => et_dict[et.id] = et);
                    const cp_ids = getPropertyIds(this.config.columns);

                    return this.customEventsService.getStreamedConstantPropertiesByEventTypeIds(this.event_type_list.map(et => et.id), cp_ids)
                        .pipe(tap((prop_response: PropByRelationshipResponse) => {
                            this.cp_name_dict = {};
                            this.event_prop_dict = {};
                            this.constant_properties = prop_response.constant_properties;
                            this.cp_id_dict = prop_response.cp_dict;
                            this.constant_properties.forEach(cp => this.cp_name_dict[cp.attributes.name] = cp.id);
                            Object.keys(prop_response.props_list_dict).forEach(key => {
                                this.event_prop_dict[et_dict[key].attributes.name.trim()] = prop_response.props_list_dict[key].map(id => this.cp_id_dict[id].attributes.name);
                            });
                        }));
                }))
            );

            const ct_ids = (this.config.columns.filter(col => col.type === 'component_type') || []).map(ct => ct.id);
            obs_list.push(this.customEventsService.getComponentTypesByEventTypes(this.config.event_types.map(et => et.id), null, ct_ids)
                .pipe(
                    tap((result: ComponentTypeByRelationshipResponse) => {
                        this.component_types = result.component_types;
                        this.component_types_dict = result.ct_dict;
                        this.et_ct_dict = result.ct_list_dict;
                        this.component_types.forEach(ct => {
                            this.ct_name_dict[ct.id] = ct.attributes.name;
                        })
                    })
                )
            );
            obs_list.push(this.customEventsService.getEventTypeComponentTypeRelationships(this.config.event_types.map(et => et.id), ct_ids).pipe(
                tap(eventTypeComponentTypes => {
                    this.etCtQueryDict = this.customEventsService.getEvenTypeComponentTypeQueryMap(eventTypeComponentTypes.data);
                })
            ))

            return forkJoin(obs_list).pipe(concatMap(response => {
                return this.api.event_type_ore_body_type_map.search(this.api.prep_q(
                    [{
                        and: [
                            getRelationWithManyIdsFilter('event_type', uniqueList(this.event_type_list.map(et => et.id))),
                            getRelationWithManyIdsFilter('ore_body_type', uniqueList(this.ore_bodies.map(ob => ob.relationships.type.data.id)))
                        ]
                    }], {}
                )).pipe(tap(result => {
                    this.non_editable_columns = [];
                    this.config.columns.map(col => {
                        const col_id = getColId(col);
                        this.id_title_map[col_id] = col.title || (col.type === 'constant_property' ? this.cp_id_dict[col.id]?.attributes.name :
                            (col.type === 'component_type' ? this.component_types_dict[col.id]?.attributes.name : col.id));
                        if (col.disabled) {
                            this.non_editable_columns.push(col_id)
                        }
                    });

                    this.source_dest_map = {};
                    //This should be simplified if we decide to only allow one Event Type per table
                    this.source_dest_map['all'] = {source_obs: [], destination_obs: []};
                    this.config.event_types.forEach(et => {
                        const etn = et.attributes.name
                        this.source_dest_map[etn] = {source_obs: [], destination_obs: []};

                        this.ore_bodies.forEach(ob => {
                            let m = result.data.find(item => item.relationships.ore_body_type.data.id === ob.relationships.type.data.id
                                && item.relationships.event_type.data.id === et.id);
                            if (m && m.attributes.destination && !this.source_dest_map[etn].destination_obs.includes(ob)) {
                                this.source_dest_map['all'].destination_obs.push(ob);
                                this.source_dest_map[etn].destination_obs.push(ob);
                            }
                            if (m && m.attributes.source && !this.source_dest_map[etn].source_obs.includes(ob)) {
                                this.source_dest_map['all'].source_obs.push(ob);
                                this.source_dest_map[etn].source_obs.push(ob);
                            }
                        });
                    });
                }));
            }))
        }))
            .subscribe(() => {
                this.etNameDict = {};
                this.event_series = {};
                this.series_ids = [];

                this.event_type_list.forEach((item: {
                        attributes: {
                            base_type: string,
                            changed_by_name: string,
                            changed_on: string,
                            created_by_name: string,
                            created_on: string,
                            hide_end: false
                            icon: any,
                            linked_components: string[],
                            name: string,
                            severity: any,
                            update_function: string
                        },
                        id: string,
                        relationships: any
                    }) => {
                        if (this.config.event_types.map(et => et.id).includes(item.id)) {
                            this.etNameDict[item.attributes.name.trim()] = item;
                            this.event_series[item.attributes.name.trim()] = item.relationships.series_list.data.map(series => series.id);
                            item.relationships.series_list.data.map(series => this.series_ids.push(series.id));

                        }
                    }
                );

                const ore_body_func = function (item) {
                    if (item.attributes.full_path_names[0]) {
                        return item.attributes.full_path_names[0];
                    } else {
                        return item.attributes.name;
                    }
                };
                // Currently only 'all' is being used (07/01/2021)
                this.inputLookups['all'] = this.handsontableGenerator.gen_lookups(this.source_dest_map['all'].destination_obs, ore_body_func, true);
                this.outputLookups['all'] = this.handsontableGenerator.gen_lookups(this.source_dest_map['all'].source_obs, ore_body_func, true);
                Object.keys(this.source_dest_map).forEach(sd => {
                    this.inputLookups[sd] = this.handsontableGenerator.gen_lookups(this.source_dest_map[sd].destination_obs, ore_body_func, true);
                    this.outputLookups[sd] = this.handsontableGenerator.gen_lookups(this.source_dest_map[sd].source_obs, ore_body_func, true);
                });

                if (this.series_ids.length > 0) {
                    this.seriesData.getSeriesPermissions(this.series_ids).subscribe(data => {
                        this.series_permissions = data;
                        this.allow_edit = false;
                        this.series_ids.forEach(id => {
                            if (!this.series_permissions[id]) {
                                this.allow_edit = true;
                            } else if (this.series_permissions[id].includes('edit_process_data') ||
                                this.series_permissions[id].includes('edit_todays_data')) {
                                this.allow_edit = true;
                            }
                        });
                    });
                } else {
                    this.allow_edit = true;
                }

                this.resetDataSource();
            });

    }


    resetDataSource() {
        const initialQuery = new SearchQueryOptions();
        initialQuery.page_number = 1;
        initialQuery.sort = 'start';
        this.paginator.pageSize = this.config.page_size ? this.config.page_size : this.page_size;
        this.paginator.pageIndex = 0;
        initialQuery.filters = this.getEventQuery();
        this.dataSource = new PaginationDataSource<WireEvent>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.$page = utils.refreshSubscription(this.$page);
        this.$page = this.dataSource.$page.pipe(
            switchMap(response => this.afterPage(response)),
            takeUntil(this.onDestroy),
            catchError(err => {
                this.saving = false;
                return err;
            }))
            .subscribe((response: ListResponse<WireEvent>) => {
                this.hot['new_rows'] = [];
                this.paginator.length = response.meta.count;
                this.saving = false;
                this.createTable();
                this.resizeUpdate();
                this.hot.instance.render();
            });
    }

    ngAfterViewInit() {
        if (this.config.event_types && this.config.event_types.length === 1) {
            this.tileData.setDefaultTitle(this.config.event_types[0].attributes.name);
        }
        this.setButtons();
    }

    setDatePickerConfig(dtp: IDateTimePeriod, limits = true
    ) {
        let format = {
            autoClose: false,
            use24hour: true
        };
        if (limits === true) {
            format['minDate'] = dtp.start;
            format['maxDate'] = dtp.end;
        }
        return format;
    }

    createTable() {
        // const userLookup = this.handsontableGenerator.gen_lookups(this.users, item => item.attributes.name);
        const eventTypeLookup = this.handsontableGenerator.gen_lookups(this.event_type_list, item => item.attributes.name);

        this.schema = {
            id: null,
            type: 'event', //***
            attributes: {
                base_type: 'event',
                comment: null,
                start: null,
                end: null,
                created_on: null,
                changed_on: null,
                event_type_name: null,
                custom_constants: {}
            },
            relationships: {
                event_type: {data: {id: null, type: 'event_type'}},
                series_list: {data: []},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}},
                input_ore_body: {data: {id: null, type: 'ore_body'}},
                output_ore_body: {data: {id: null, type: 'ore_body'}}

            }
        };

        let is_readonly = col => {
            if (this.non_editable_columns) {
                return this.non_editable_columns.includes(col);
            }
            return false;
        };

        this.column_list = [
            {
                data: 'attributes.start',
                title: this.id_title_map['start'] || 'Start',
                name: 'start',
                width: 160,
                defaultDate: this.dtp.start,
                allowEmpty: false,
                readOnly: is_readonly('start'),
                renderer: 'hot_date_renderer',
                editor: false,
                type: 'date',
                custom_type: 'date'
            }
        ];
        if (getColIds(this.config.columns).includes('end')) {
            this.column_list.push({
                data: 'attributes.end',
                type: 'date',
                title: this.id_title_map['end'] || 'End',
                name: 'end',
                width: 160,
                defaultDate: this.dtp.end,
                allowEmpty: false,
                readOnly: is_readonly('end'),
                renderer: 'hot_date_renderer',
                editor: false,
                custom_type: 'date'
            });

            this.column_list.push({
                data: (row, value) => {
                    if (row && row['attributes']) {
                        if (row.attributes.start && row.attributes.end) {
                            let rHours, rMin, rSec;
                            if (row.attributes.start < row.attributes.end) {
                                //@ts-ignore
                                const dur_ms = (new Date(row.attributes.end)) - (new Date(row.attributes.start));

                                const hours = Math.floor(dur_ms / 3600000);
                                const minutes = Math.floor(dur_ms % 3600000 / 60000);
                                const seconds = dur_ms / 1000 - hours * 3600 - minutes * 60;
                                rHours = (hours >= 10) ? hours : "0" + hours;
                                rMin = (minutes >= 10) ? minutes : "0" + minutes;
                                rSec = (seconds >= 10) ? seconds : "0" + seconds;
                            } else {
                                rHours = '00';
                                rMin = '00';
                                rSec = '00';
                            }

                            return rHours + ':' + rMin + ':' + rSec;
                        }
                    }
                },
                title: this.id_title_map['duration'] || 'Duration',
                name: 'duration',
                readOnly: true
            });
        }

        if (this.single) {
            this.schema.attributes.event_type_name = this.config.event_types[0].attributes.name;
            this.schema.relationships.event_type.data.id = this.event_type_list[0].id;
        }

        this.column_list.push({
            data: this.handsontableGenerator.genLookupDataSource(eventTypeLookup, 'event_type'),
            title: this.id_title_map['type'] || 'Type',
            name: 'type',
            type: 'autocomplete',
            source: eventTypeLookup.source,
            strict: true
        });

        this.component_types.forEach(ct => {
            if (this.componentLookups[ct.id]) {
                let new_column =
                    {
                        data: this.handsontableGenerator.genLookupDataSource(this.componentLookups[ct.id], ct.id),
                        title: this.id_title_map[ct.id] || ct.attributes.name,
                        name: ct.id,
                        renderer: 'infinite_scroller',
                        readOnly: is_readonly(ct.attributes.name)
                    };
                this.column_list.push(new_column);
            }
        });
        this.unwrapComponents();

        this.constant_properties.forEach(item => {
            //TODO add date as well
            this.schema.attributes.custom_constants[item.attributes.name.trim()] = null;
            const data_type_map = {
                'string': 'text',
                'float': 'numeric',
                'datetime': 'date',
                'calculation': 'numeric'
            };
            let new_column;
            if (item.attributes.data_type === 'float') {
                new_column = {
                    data: 'attributes.custom_constants.' + item.attributes.name,
                    title: this.id_title_map[item.id] || item.attributes.name,
                    name: item.id,
                    type: data_type_map[item.attributes.data_type],
                    format: "0.000"
                };
            } else {
                new_column = {
                    data: 'attributes.custom_constants.' + item.attributes.name,
                    title: this.id_title_map[item.id] || item.attributes.name,
                    name: item.id,
                    type: data_type_map[item.attributes.data_type],
                };
                if (new_column.type === 'date') {
                    new_column.defaultDate = this.dtp.start;
                    new_column.renderer = 'hot_date_renderer';
                    editor: false;
                    new_column.custom_type = 'date';
                }
            }
            new_column.readOnly = is_readonly(item.id) || item.attributes.is_calculation;
            if (item.attributes.is_drop_down_list) {
                new_column.type = 'autocomplete';
                new_column.source = item.attributes.json;
                new_column.strict = true;
            }
            this.column_list.push(new_column);
        });

        if (this.source_dest_map['all'].destination_obs && this.source_dest_map['all'].destination_obs.length > 0) {
            this.column_list.push({
                data: this.handsontableGenerator.genLookupDataSource(this.inputLookups['all'], 'input_ore_body'),
                // TODO: figure out how to filter by event_type name when selected
                source: this.inputLookups['all'].source,
                title: this.id_title_map['destination'] || 'Destination',
                name: 'destination',
                type: 'autocomplete',
                trimDropdown: false,
                allowEmpty: true,
                readOnly: is_readonly('destination')
            });
        }

        if (this.source_dest_map['all'].source_obs && this.source_dest_map['all'].source_obs.length > 0) {
            this.column_list.push({
                data: this.handsontableGenerator.genLookupDataSource(this.outputLookups['all'], 'output_ore_body'),
                source: this.outputLookups['all'].source,
                title: this.id_title_map['source'] || 'Source',
                name: 'source',
                type: 'autocomplete',
                trimDropdown: false,
                allowEmpty: true,
                readOnly: is_readonly('source')
            });
        }

        this.column_list = this.column_list.concat([
            {
                data: 'attributes.comment',
                type: 'text',
                title: this.id_title_map['comment'] || 'Comment',
                name: 'comment',
                readOnly: is_readonly('comment'),
                className: "htLeft"
            },
            {
                data: 'attributes.changed_by_name',
                readOnly: true,
                title: this.id_title_map['changed_by'] || 'Changed By',
                name: 'changed_by',
            },
            {
                data: 'attributes.changed_on',
                readOnly: true,
                title: this.id_title_map['changed_on'] || 'Changed On',
                name: 'changed_on',
                type: 'date',
                renderer: 'date_formatter'
            }
        ]);

        this.column_list.forEach(col => {
            this.id_title_map[col.name] = col.title;
            this.title_id_map[col.title] = col.name;
        })

        if (this.config.column_widths) {
            this.column_list.forEach(col => {
                if (this.config.column_widths) {
                    col.width = [this.config.column_widths[col.name]];
                }
            });
        }

        this.required_columns = [];
        if (!this.single) {
            this.required_columns.push('type');
        }

        if (this.config.columns && this.config.columns.length >= 1) {
            this.column_list = utils.mapOrder(utils.deepCopy(this.column_list), getColIds(this.config.columns), 'name');
            this.column_list = this.column_list.filter(col => {
                return (getColIds(this.config.columns).includes(col.name) || this.required_columns.includes(col.name));
            });
        }

        this.hot = this.handsontableGenerator.generateTable(this.api.event, this.schema, this.column_list, this.hot /*should prevent new
         HotInstances from being created the whole time*/, null, null, this.row_locked_dict);
        this.hot.settings.rowHeaders = false;
        this.hot.settings.columnSorting = true;
        this.hot.settings.readOnly = !this.allow_edit;
        this.hot.settings.manualColumnResize = true;
        this.hot.settings.minSpareRows = 0;
        this.hot.settings.required_keys = this.required_columns;
        this.setSort();

        this.hot.settings.cells = (row, col, prop) => {
            if (this.hot.instance) {
                const column = this.hot.instance.getColHeader(col).toString();
                const ct = this.component_types.find(component_type => component_type.id === this.title_id_map[column]);
                const colIndex = this.hot.instance.getColHeader().indexOf(this.id_title_map['type']);
                const eventTypeName = this.hot.instance.getDataAtCell(row, colIndex);
                const eventTypeId = this.etNameDict?.[eventTypeName]?.id;

                if (ct && !this.single) {
                    if (eventTypeId && this.et_ct_dict[eventTypeId]?.includes(ct.id)) {
                        return {readOnly: is_readonly(ct.attributes.name)}
                    } else {
                        return {readOnly: true};

                    }
                }
                let key = prop?.toString().replace("attributes.", "").replace("custom_constants.", "");
                let id = this.hot.instance.getDataAtRowProp(row, 'id');
                if (!this.single && prop.toString().indexOf('custom_constants') > -1) {
                    let cellProperties = {readOnly: true};
                    let custom_constant = prop.toString().replace('attributes.custom_constants.', '');
                    if (eventTypeName && this.event_prop_dict[eventTypeName].includes(custom_constant) && is_readonly(this.cp_name_dict[custom_constant]) === false
                        && ((id && key && this.locked_dict[id]?.[key] !== true) || !id)) {
                        cellProperties.readOnly = false;
                    }
                    return cellProperties;
                }
                if (!this.single && this.hot.instance.getColHeader(col) === 'Type') {
                    let cellProperties = {readOnly: this.single ? is_readonly('type') : false};
                    const dataItem = this.hot.instance.getSourceDataAtRow(row);
                    const isExistingItem = dataItem && dataItem['id'];
                    if (!this.single && isExistingItem) {
                        cellProperties.readOnly = true;
                    }
                    return cellProperties;
                }

                if (id && key && this.locked_dict[id]?.[key] === true) {
                    let cellProperties = {readOnly: true};
                    return cellProperties;
                }
            }
        };
        this.setupInfiniteScroll();

        if (!this.config.allow_delete) {
            this.hot.settings.contextMenu = {
                callback: null,
                items: {"not_allowed": {name: 'Delete not allowed'}}
            };
        }
        this.hot.ready = true;
        this.hot.settings.data = this.data;
        this.hot.settings.width = '100%';
        this.hot.settings.height = '100%';

        const element = this.el.nativeElement;
        if (this.hot.instance) {
            this.hot.instance.updateSettings(this.hot.settings, false);
        } else {
            this.hot.instance = new Handsontable(element, this.hot.settings);
        }
        if (this.current_sort) {
            this.hot.instance.getPlugin('columnSorting').sort(this.current_sort);
        }
        if (this.events_total < 1) {
            this.insert();
        }

        this.addScrollEventHook();
    }

    addScrollEventHook() {
        this.masterTable = document.querySelector('.ht_master .wtHolder');
        this.topClone = document.querySelector('.ht_clone_top .wtHolder');

        if (this.masterTable && this.topClone) {
            this.scrollListener = () => {
                if (this.topClone) {
                    this.topClone.scrollLeft = this.masterTable!.scrollLeft;
                }
            };

            this.masterTable.addEventListener('scroll', this.scrollListener);
        }
    }

    afterPage(response: ListResponse<WireEvent>): Observable<any> {
        this.saving = true;

        const event_ids = this.data.map(e => e.id);
        const constant_properties_ids = this.constant_properties.map(v => v.id);
        const atts = ['start', 'end'];
        const $eventConstants = this.customEventsData.getApiEventConstants(event_ids, constant_properties_ids, atts);
        const $eventComponents = this.customEventsData.getEventComponents(this.data.map(e => e.id));

        return forkJoin([$eventConstants, $eventComponents]).pipe(
            tap(([eventConstants, eventComponents]) => {
                this.customEventsService.mapApiEventConstants(this.data, eventConstants, this.cp_id_dict);
                this.locked_dict = this.customEventsService.updateLockedProperties({}, this.data, this.constant_properties, eventConstants, atts)
                this.row_locked_dict = this.lockDataService.getRowLocked(this.data, this.locked_dict);
                this.mapEventComponents(this.data, eventComponents.data)
            }),
            map(() => response),
            switchMap((response) => {
                this.components = [];
                let component_obs = [];
                this.component_type_map = {};
                const event_ids = response.data.map(e => e.id);
                this.component_types.forEach(ct => {
                    const ops = new SearchQueryOptions();
                    // Get at least the components for the currently viewed events
                    ops.filters = [
                        getRelationWithIdFilter('component_type', ct.id)
                    ];
                    if (event_ids.length < 2000) {
                        ops.filters.push(getManyRelationWithIdsFilter('events', event_ids));
                    } else {
                        ops.filters.push({
                            name: "events",
                            op: "any",
                            val: {and: this.getEventQuery()}
                        });
                    }
                    component_obs.push(this.api.component.searchMany(ops).pipe(
                        tap(comp_results => {
                            if (!comp_results.data) {
                                return of([]);
                            }
                            this.components = this.components.concat(comp_results.data);
                            this.component_type_map[ct.id] = comp_results.data;
                            this.componentLookups[ct.id] = this.handsontableGenerator.gen_lookups(
                                this.component_type_map[ct.id], null, true);

                        })
                    ));
                });
                if (component_obs.length > 0) {
                    return forkJoin(component_obs).pipe(map(results => {
                        if (!results) {
                            console.log('WARN: Event sheet view (page): No components corresponding to the selected Event Types ');
                            return
                        }
                        this.customEventsService.mapEventComponentTypes(this.data, this.event_ct_component_map, this.component_type_map,
                            this.component_types, this.components, this.ct_name_dict);
                        return response;
                    }));
                } else {
                    return of(response);
                }
            }));
    }

    page(query: SearchQueryOptions): Observable<any> {
        this.saving = true;
        return this.api.event_light.searchMany(query).pipe(
            takeUntil(this.onDestroy),
            tap(response => {
                this.data = response.data;
                this.events_total = response.meta.count;
            })
        );
    }

    mapEventComponents(events, eventComponents) {
        let event_components_dict = _groupBy(eventComponents, 'relationships.event.data.id');
        events.forEach(event => {
            let components = event_components_dict[event.id] || [];
            event.relationships.components = {
                // @ts-ignore
                'data': components.map(component => ({
                        id: component.relationships.component.data.id,
                        type: component.relationships.component.data.type
                    })
                )
            };
        });
    }

    getEventQuery(): any[] {
        let filters: any[] = [];
        const ev_query = [this.eventDataService.generateEventDateFilter(this.dtp.start, this.dtp.end),
            getRelationWithManyIdsFilter('event_type', this.config.event_types.map(et => et.id))];

        return ev_query;
    }

    updateSearchFilter() {
        let filters = this.getEventQuery();
        const string_filters = {or: []};
        ['comment'].forEach(att => {
            string_filters.or.push({op: 'ilike', name: att, val: '%' + this.filter_string + '%'});
        });

        string_filters.or = string_filters.or.concat(this.customEventsData.generateConstantPropertiesSearchFilter(this.constant_properties.map(cp => cp.id), this.cp_name_dict, this.filter_string));
        string_filters.or = string_filters.or.concat(this.customEventsData.generateComponentSearchFilter(this.component_types.map(cp => cp.id), this.filter_string));

        filters.push(string_filters);
        this.dataSource.filterBy(filters);
    }

    setSort() {
        const allowed = ['start', 'end', 'comment'];
        this.hot.settings.beforeColumnSort = (currentSort, destinationSort) => {
            let sort;
            this.filter_warning = '';
            if (destinationSort?.length) {
                const col_index = destinationSort[0]?.column;
                const col = this.hot.instance.getColHeader(col_index) as string;
                if (!col) {
                    return;
                }
                sort = this.title_id_map[col].toString().toLowerCase().replace('*', '');
                if (!allowed.includes(sort)) {
                    this.current_sort = destinationSort;
                    this.filter_warning = "Only the current page has been sorted.";
                    return true;
                }
            }

            this.current_sort = null;
            let sortChange = new MatSort();
            sortChange.active = sort;
            sortChange.direction = destinationSort[0]?.sortOrder;
            this.dataSource.sortBy(sortChange);
            // return false; // If not using this causes problems/confusion we'll need to manually keep track of the current sort.
        };

    }

    unwrapComponents() {
        this.component_types.forEach(ct => {
            if (this.component_type_map[ct.id] && this.component_type_map[ct.id].length > 0) {
                this.schema.relationships[ct.id] = {
                    data: {
                        id: null,
                        type: 'component'
                    }
                };
            }
        });

        this.data.forEach(item => {
            this.ec_to_save.forEach(ec => {
                if (ec.ec.relationships.event.data.id === item.id) {
                    this.event_ct_component_map[item.id][ec.ct] = this.components.find(c => c.id === ec.ec.relationships.component.data.id);

                    item.relationships[ec.ct] = {
                        id: ec.ec.relationships.component.data.id,
                        type: 'component'
                    };
                }
            });

            this.component_types.forEach(ct => {
                if (!this.event_ct_component_map[item.id]) {
                    return;
                }
                if (!this.event_ct_component_map[item.id][ct.id]) {
                    this.event_ct_component_map[item.id][ct.id] = {id: null, type: 'component'};
                }
                item.relationships[ct.id] = {
                    data: {
                        id: this.event_ct_component_map[item.id][ct.id].id || null,
                        type: this.event_ct_component_map[item.id][ct.id].type || 'component'
                    }
                };
            });
        });

        this.ec_to_save = [];
    }

    wrapComponents(item) {
        // Don't bother with items that aren't being saved/updated
        if (item.id !== null && item.hasOwnProperty('id') && !this.hot.change_ids.includes(item.id)) {
            return;
        }
        if (!item.id) {
            item.temp_id = utils.guid();
        }
        this.component_types.forEach(ct => {
            if (item?.relationships && item.relationships[ct.id]?.data) {
                this.customEventsService.setEventComponentToSave(item, ct, this.ec_to_save, this.event_ct_component_map);
            }
            delete this.schema.relationships[ct.id];
        });
    }

    setupInfiniteScroll() {
        this.hot.settings.beforeKeyDown = (event) => {
            if (![8, 46].includes(event.keyCode)) {
                return;
            }
            const range = this.hot.instance.getSelectedRange()[0];
            let coords = this.getCoordsFromAutofill(range.from, range.to);
            // Fill empty array of array (mimic pasted data)
            const pasted = Array.from({length: coords.endRow - coords.startRow + 1}, () =>
                Array.from({length: coords.endCol - coords.startCol + 1}, () => null));
            this.beforePaste(pasted, coords);
        }

        this.hot.settings.beforeAutofill = (selectionData, sourceRange, targetRange, direction) => {
            let coords = this.getCoordsFromAutofill(selectionData, sourceRange);
            this.beforePaste(targetRange, coords);
        }

        this.hot.settings.beforePaste = (pasted, config) => {
            this.beforePaste(pasted, config[0]);
        }

        this.hot.settings.afterPaste = (pasted, config) => {
            if (!this.continue_paste) {
            } else {
                this.getByName(pasted, config[0]);
            }
        };
        this.hot.settings.afterAutofill = (selectionData, sourceRange, targetRange, direction) => {
            if (!this.continue_paste) {
            } else {
                let coords = this.getCoordsFromAutofill(selectionData, sourceRange);
                this.getByName(targetRange, coords);
            }
        };
        this.hot.settings.afterOnCellMouseDown = (event, coords) => {
            this.setCustomRendererCallbacks(event, coords);
        };
    }

    private getCoordsFromAutofill(selectionData, sourceRange): HOTDataCoords {
        return {
            startRow: selectionData.row,
            startCol: selectionData.col,
            endRow: sourceRange.row,
            endCol: sourceRange.col
        };
    }

    private beforePaste(pasted, coords: HOTDataCoords) {
        // Copy the original data and change_ids in case of a rollback
        const data_pre_paste = utils.deepCopy(this.data);
        const change_ids = utils.deepCopy(this.hot.change_ids);
        const rows = this.getPastedComponentTypeRows(pasted, coords);
        // If continue_paste then no component changes were found
        if (this.continue_paste) {
            return true;
        }
        let $check: Observable<boolean>;
        if (!this.config.allow_delete) {
            this.notification.openError("Deleting components from events is not allowed.", 10000);
            $check = of(false).pipe(delay(100));
        } else {
            $check = this.showEventComponentDeleteCheck(rows, pasted);
        }

        $check.pipe(take(1)).subscribe((continue_paste: boolean) => {
            if (continue_paste) {
                // Adjust the config coords manually to include endRow and endCol boundaries, then call after paste function (getByName)
                const endRow = coords.startRow + pasted.length - 1;
                const endCol = coords.startCol + pasted[0].length - 1;
                const totalCols = this.hot.instance.countCols();
                coords = Object.assign(coords, {endCol: endCol > totalCols ? totalCols : endCol})

                this.getByName(pasted, coords);
            } else {
                this.resetDataBeforePaste(data_pre_paste, change_ids)
            }
        });
    }

    private resetDataBeforePaste(data_pre_paste, change_ids) {
        this.data = utils.deepCopy(data_pre_paste);
        this.hot.instance.loadData(this.data);
        const current_ids = this.hot.change_ids;
        this.hot.change_ids = _difference(current_ids, change_ids);
    }

    private getPastedComponentTypeRows(pasted, config: HOTDataCoords) {
        this.continue_paste = true;
        let i = utils.deepCopy(config.startCol);
        const p_cols = pasted[0].length + config.startCol;
        const p_rows = pasted.length + config.startRow;
        const ct_ids = getComponentTypeIds(this.config.columns);
        let rows = [];
        for (let c = config.startCol; c < p_cols; c++) {
            const column = this.hot.instance.getColHeader(c).toString();

            if (ct_ids.includes(this.title_id_map[column])) {
                for (let r = config.startRow; r < p_rows; r++) {
                    let prop_data = {};
                    const event_type_id = this.single ? this.event_type_list[0].id : this.hot.instance.getDataAtRowProp(r, 'relationships.event_type')?.data?.id;
                    this.constant_properties.forEach(cp => {
                        prop_data[cp.id] = this.hot.instance.getDataAtRowProp(r, 'attributes.custom_constants.' + cp.attributes.name)
                    });
                    const current_val = this.hot.instance.getDataAtCell(r, c);
                    const pasted_val = pasted[r - config.startRow]?.[c - config.startCol];
                    let row = {
                        event_type_id: event_type_id,
                        prop_data: prop_data,
                        row: (r - config.startRow),
                        col: (c - config.startCol),
                        component_type_id: this.title_id_map[column],
                        component_type_name: column,
                        current_value: current_val,
                        pasted_value: pasted_val,
                        include: false
                    };
                    rows.push(row);
                    if (current_val && current_val !== pasted_val) {
                        this.continue_paste = false;
                    }
                }
            }
        }
        return rows;
    }

    showEventComponentDeleteCheck(rows, pasted): Observable<boolean> {
        const data = {
            et_ct_dict: this.et_ct_dict,
            constant_properties: getPropertyIds(this.config.columns),
            columns: this.config.columns,
            rows: rows
        }
        return this.formDialogService.eventComponentDeleteCheck(data).afterClosed().pipe(map(result => {
            if (result === false) {
                return false;
            }
            result.forEach(d => {
                pasted[d.row][d.col] = d.pasted_value;
            })
            return true;
        }));
    }

    setCustomRendererCallbacks(event, coords) {
        if (this.hot.instance.getCellMeta(coords.row, coords.col)?.readOnly) {
            return;
        }
        const column = this.hot.instance.getColHeader(coords.col).toString();
        const data_type = this.column_list[coords.col].custom_type;

        let dialogConfig;
        if (data_type === 'date') {
            dialogConfig = this.handsontableGenerator.genDatePickerCallback(event, coords, this.hot, this.data, this.dtp);
        } else {
            const ct = this.component_types.find(component_type => component_type.id === this.title_id_map[column]);
            if (!ct) {
                return;
            }
            const eventTypeId = this.single ? this.event_type_list[0].id : this.hot.instance.getDataAtRowProp(coords.row, 'relationships.event_type')?.data?.id;
            dialogConfig = this.handsontableGenerator.genScrollerCallback(event, coords, this.hot, this.data, this.componentLookups[ct.id],
                this.component_type_map[ct.id], ct.id, 'component', this.etCtQueryDict?.[eventTypeId]?.[ct.id], this.components, this.current_sort);

        }

        if (dialogConfig) {
            dialogConfig.beforeClosed().pipe(take(1)).subscribe(() => {
                const gridsterElement = document.querySelector('gridster');
                if (gridsterElement) {
                    gridsterElement.removeAttribute('aria-hidden');
                }
            })
            // const modalDialog = this.matDialog.open(CustomDialogComponent, dialogConfig);
            return false;
        }
    }

    getByName(pasted, config: HOTDataCoords) {
        const $obs = [];
        this.component_types.forEach(ct => {
            const from_pasted =
                this.handsontableGenerator.getFiltersFromPasted(pasted, config, this.hot, this.componentLookups[ct.id],
                    [this.id_title_map[ct.id]]);
            if (!from_pasted?.options) {
                if (this.componentLookups?.[ct.id]) {
                    this.handsontableGenerator.matchLookupData(this.hot, this.data, this.componentLookups[ct.id], ct.id,
                        from_pasted?.paste_map);
                }
                return;
            }
            $obs.push(this.api.component.searchMany(from_pasted.options).pipe(
                tap(result => {
                    this.component_type_map[ct.id] = _uniq(this.component_type_map[ct.id].concat(result.data));
                    this.components = _uniq(this.components.concat(result.data));
                    this.componentLookups[ct.id] = this.handsontableGenerator.gen_lookups(this.component_type_map[ct.id], null, true);
                    this.handsontableGenerator.matchLookupData(this.hot, this.data, this.componentLookups[ct.id], ct.id,
                        from_pasted.paste_map);
                    const col_index = this.hot.instance.getColHeader().indexOf(this.id_title_map[ct.id]);
                    this.hot.settings.columns[col_index].data =
                        this.handsontableGenerator.genLookupDataSource(this.componentLookups[ct.id], ct.id);
                })));
        });
        const ids = this.hot.change_ids;
        if ($obs.length < 1) {
            return;
        }

        this.saving = true;
        forkJoin($obs).pipe(first(), takeUntil(this.onDestroy)).subscribe(() => {
            this.saving = false;
            this.hot.change_ids = ids;
            this.hot.instance.updateSettings(this.hot.settings, false);
            this.saving = false;
        });
    }

    save() {
        // delete new items that are empty from the change (new_rows) array
        this.hot['new_rows'] = this.handsontableGenerator.checkEmptyRows(this.hot);
        const sourceData = this.hot.instance.getSourceData();
        let validate: boolean = true;

        sourceData.forEach(item => {
            // make the current time the default for start time the 'Start' column was not included in the table
            if (item?.attributes && !item.attributes.start && !getColIds(this.config.columns).includes('start')) {
                item.attributes.start = new Date();
            }
            // Null-check on start & end time here since `new Date(null)` produces '1970-01-01'
            if (item?.attributes && item.attributes.start) {
                item.attributes.start = new Date(item.attributes.start).toISOString();
            }
            if (item?.attributes && item.attributes.end) {
                item.attributes.end = new Date(item.attributes.end).toISOString();
            }

            if (item && item['attributes'] && this.etNameDict[item.attributes.event_type_name] != null) {
                const event_type = this.etNameDict[item.attributes.event_type_name];
                item.relationships.event_type.data = {id: event_type.id, type: 'event_type'};
                if (item.relationships['series_list']) {
                    item.relationships.series_list.data = event_type.relationships.series_list.data.map(item2 => ({
                        'id': item2.id,
                        'type': 'series'
                    }));
                    this.schema.relationships.series_list.data = event_type.relationships.series_list.data.map(item2 => ({
                        'id': null,
                        'type': 'series'
                    }));
                }
            }
            if (!this.customEventsService.checkStartAndEndDates(item, 'start', 'end', this.hot.change_ids)) {
                validate = false;
                return false;
            }
            this.wrapComponents(item);
            this.validateRelationships(item);
        });

        if (!validate) {
            return false;
        }

        let results = this.hot.save();
        this.saving = true;
        forkJoin(results.deferreds).pipe(
            concatMap(() => {
                this.data = results.data;
                this.ec_to_save = this.customEventsService.updateNewEventComponentIds(this.data, this.ec_to_save,
                    this.components, this.event_ct_component_map);

                return this.customEventsService.fetchAndReplaceComponentEvents(this.ec_to_save).pipe(
                    catchError(error => {
                        this.notification.openError(`Error updating linked components: ${error}`);
                        console.log('ERROR: EventSheetView (save), error updating linked components:', error);
                        this.ec_to_save = [];
                        return of(error);
                    })
                );
            }),
            catchError(e => {
                console.log('ERROR: EventSheetView (save): ' + e, this.data, this.ec_to_save);
                return of(e);
            }),
            finalize(() => {
                this.unwrapComponents();
                this.saving = false;
                this.hot.instance.render();
            })
        ).subscribe();
    }

    validateRelationships(item) {
        // Don't bother with items that aren't being saved/updated
        if (item.id !== null && !this.hot.change_ids.includes(item.id)) {
            return;
        }
        Object.keys(item.relationships).forEach(rel => {
            if (item.relationships[rel].data?.id === '' ||
                (item.relationships[rel].data && item.relationships[rel].data.id === undefined)) {
                item.relationships[rel].data.id = null;
            }
        });
    }

    download() {
        this.hot.download();
    }

    insert(amount = 1) {
        if (this.hot.instance) {
            this.hot.instance.alter('insert_row', this.hot.instance.getData().length, amount);
        }
        this.data.map(item => {
            if (item.id === null) {
                item.temp_id = utils.guid();
            }
        });
    }

    buildHeader() {
        this.headerData.title = 'Custom Events';
        if (this.headerData.component_buttons_loaded === false) {
            this.headerData.buttons = this.headerData.buttons.concat([
                {name: 'Save', func: this.save.bind(this), class: 'icon-save'},
                {name: 'Download', func: this.download.bind(this), class: 'icon-download'}
            ]);
            this.headerData.component_buttons_loaded = true;
        }
    }

    setButtons() {
        this.buttons = [{
            name: 'Save',
            func: () => this.save(),
            class: 'fa fa-save',
            HoverOverHint: 'Save'
        }, {
            name: 'Download',
            func: () => this.download(),
            class: 'fa fa-download',
            HoverOverHint: 'Download'
        }, {
            name: 'Insert',
            func: () => this.insert(),
            class: 'fa fa-plus',
            HoverOverHint: 'Add empty row'
        }];
        this.tileData.buttonsChanged.next(this.buttons);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();

        if (this.masterTable && this.scrollListener) {
            this.masterTable.removeEventListener('scroll', this.scrollListener);
        }
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
