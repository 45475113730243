<div *ngIf="constant" class="lock-indicator">
  <i *ngIf="locked" (click)="lockClicked()" style="cursor:pointer;" class="fa fa-lock lock-indicator-icon"></i>
  <constant-field *ngIf="!invalid"
                  [tooltip]="constant.value"
                  [constant_property]="constant_property"
                  [constant_value]="constant.value"
                  [auto_class]="auto_class"
                  [formats]="formats"
                  (constant_changed)="constantChanged($event);"
                  (change_complete)="changeComplete($event);"
                  [matTooltipShowDelay]="1000"
                  [show_sig]="show_sig"
                  [defaultValue]="defaultValue"
                  [disabled]="disabled">

  </constant-field>

  <ng-container *ngIf="invalid">
    <div class="invalid-field">
      <div>
        <i [matTooltip]="'This property is not relevant to this item.'" class="fa fa-info-circle"></i>&nbsp;Invalid
        property
      </div>
    </div>
  </ng-container>
</div>
