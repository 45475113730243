<div class="page traveler">
  <div class="row justify-center center">
    <div style="width:460px;">
      <select-search-component
          (selectionChange)="selected_series=$event.value;updateURL()"
          [value]="selected_series"
          [api_model]="'series_light'"
          [classes]="'fill bordered-select'"
          label="Select a calculation to analyse"
          style="max-width:460px;">
      </select-search-component>
    </div>
  </div>
  <div>
    <analysis-tools *ngIf="selected_series" [selected_series]="selected_series" [estimate_type_id]="estimate_type_id"
                    (update_url_emitter)="updateURL($event)"></analysis-tools>
  </div>
</div>
