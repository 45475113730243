<div class="page">
  <section>
    <div class="row utils-bar" style="justify-content: space-between">
      <div *ngIf="dataSource" class="table-filter">
        <input (keyup)="applyFilter($event.target.value)" placeholder="Search events"/>
      </div>

    <!--TODO style the paginator to match the site-->
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="10"
                   length="{{collectorEvents ? collectorEvents.length : 0}}" showFirstLastButtons></mat-paginator>

    </div>
    <div class="table-container">
      <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="columns[1]"
             class="collection-events-table table-striped" mat-table
             matSort matSortDirection="desc">
        <!--Collector Name-->
        <ng-container matColumnDef="{{columns[0]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[0]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.collector.attributes.name}} </td>
        </ng-container>
        <!--Execution Time-->
        <ng-container matColumnDef="{{columns[1]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[1]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.execution_time | date: "medium"}} </td>
        </ng-container>
        <!--Start Time-->
        <ng-container matColumnDef="{{columns[2]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[2]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.min_date_collected | date: "medium"}} </td>
        </ng-container>
        <!--End Time-->
        <ng-container matColumnDef="{{columns[3]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[3]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.max_date_collected | date: "medium"}} </td>
        </ng-container>
        <!--Message-->
        <ng-container matColumnDef="{{columns[4]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[4]}}</th>
          <td *matCellDef="let event" mat-cell>
            <!-- The message has to use innerHTML for if the message contains a hyperlink to a file -->
            <p [innerHTML]="event.attributes.message" class="large-cell">
            </p>
          </td>
        </ng-container>
        <!--Status-->
        <ng-container matColumnDef="{{columns[5]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[[5]]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.status}} </td>
        </ng-container>
        <!--Errors-->
        <ng-container matColumnDef="{{columns[6]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[6]}}</th>
          <td *matCellDef="let event" mat-cell>
            <p class="large-cell">
              {{event.attributes.errors}}
            </p>
          </td>
        </ng-container>
        <!--Entries collected-->
        <ng-container matColumnDef="{{columns[7]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[7]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.entries_collected}} </td>
        </ng-container>
        <!--User / Collector responsible for collection event-->
        <ng-container matColumnDef="{{columns[8]}}">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> {{columns[8]}}</th>
          <td *matCellDef="let event" mat-cell> {{event.attributes.created_by_name}} </td>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>
    </div>

  </section>
</div>
