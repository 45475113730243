import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ApiService} from "../../services/api/api.service";
import {CustomHTMLParameters, TileDataService, TileParameters} from "../../services/tile_data.service";
import * as utils from "../../lib/utils";
import {SeriesDataService} from "../../services/series_data.service";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {AppScope} from "../../services/app_scope.service";
import {MenuTreeService} from "../../services/menu-tree.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Tile as TileModel, BeforeSaveAction} from "../../_models/tile";
import {take, takeUntil, tap} from 'rxjs/operators';
import {Subject} from "rxjs";
import {SessionState} from '../../_models/session-state';
import {NotificationService} from "../../services/notification.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {ConfirmSnackbarComponent} from "../../notifications/snackbar/confirm-snackbar/confirm-snackbar.component";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {ModelID} from "../../_typing/generic-types";
import {TileContentType} from "../../_typing/config/tile";
import {MatSelect} from "@angular/material/select";
import {ReportParameters} from "../custom-html-form/type/report-parameters";
import {UserService} from "../../services/user.service";

export interface DialogData {
    form_result: string;
    name: string;
    title: string;
    tile: TileModel;
    dtp: IDateTimePeriod;
    tab: string;
    session_id: string;
    grid_parameters: { is_title: boolean };
}

@Component({
    selector: 'page-tile-form',
    templateUrl: 'page-tile-form.html',
    styleUrls: ['./page-tile-form.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [DateTimeInstanceService],
    standalone: false
})

export class PageTileFormComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public tile: TileModel;
    public show: boolean;
    public showing_hints: boolean = false;
    public hint: string = 'Name';
    public json: TileModel;
    public grid_parameters: { is_title: boolean } = {is_title: false};
    public title: string;
    public is_relative_dtp = false;
    public showSeriesSelectForm: boolean = false;
    public tile_class: string;
    public name_description_map: any = {};
    public selected_series: any[] = [];
    public tileOptions: TileContentType[] = []
    name_list: any = [];
    processes: any;
    full_series_list: any;

    selected_process: any = [];
    tile_categories: any[];
    current_tab: number = 0;
    session_id: string;
    session_state_list: SessionState[];
    tileFilterString: string = "";

    pivotIds: ModelID[];
    pivotModel: string;

    @ViewChild('tile_form_tabs') tile_form_tabs: MatTabGroup;
    @ViewChild('tileContentSelect') tileContentSelect: MatSelect;

    @ViewChildren('beforeSaveAction')
    private beforeSaveChildren: QueryList<BeforeSaveAction>;

    disableActionButtons: boolean = false;

    constructor(private api: ApiService,
                protected seriesData: SeriesDataService,
                private dialogRef: MatDialogRef<PageTileFormComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
                public tileData: TileDataService,
                private menuService: MenuTreeService,
                public appScope: AppScope,
                private notification: NotificationService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                public userService: UserService) {
    }

    ngOnInit(): void {
        this.tile = this.dialogData.tile;
        this.grid_parameters = this.dialogData.grid_parameters;
        this.session_id = this.dialogData.session_id; // Used in HTML as well

        if (this.tile) {
            // TODO check if this is necessary
            this.tile = utils.deepCopy(this.tile);
        } else {
            this.tile = new TileModel();
            this.tile.relationships.session_states.data = [{id: this.session_id, type: 'session_state'}];
        }
        this.resetTileOptions();
        this.json = utils.deepCopy(this.tile);
        if (!this.json.attributes.content) {
            this.contentChange();
        }
        // this.show = false;
        this.title = this.dialogData.title;

        this.dateTimePeriodService.dtpInitialised$.pipe(tap((dtp) => {
            this.dateInst.dtp = utils.deepCopy(this.dialogData.dtp);
            this.json.attributes = this.dateTimePeriodService.deconstructDtp(this.json.attributes, this.dateInst.dtp);
        })).subscribe();

        this.tile_categories = this.getTileCategories();

        this.menuService.sessionStatesChanged.pipe(takeUntil(this.onDestroy)).subscribe(response => {
            this.session_state_list = response[this.appScope.active_account_id];
        });

        if (this.json.attributes.content === 'default-chart') {
            this.showSeriesSelectForm = true;
        }
        if (this.tile.attributes.content === 'series-table') {
            this.selected_process = this.tile.attributes.parameters.process;
            this.selected_series = this.tile.attributes.parameters.series_list;
        }

        if (this.json.attributes.relative_dtp != null) {
            this.toggleRelativeDtp({checked: true}, true);
        }
        if (this.json.attributes.content === 'pivot-tile') {
            this.updatePivotParams(this.json.attributes.parameters);
        }

        if (this.dialogData.tab) {
            setTimeout(() => {
                this.goToTab(this.tile_form_tabs, this.dialogData.tab);
            }, 1000);
        }
    }

    private getTileCategories() {
        return this.appScope.config_name_map['tile_categories'] ?
            this.appScope.config_name_map['tile_categories'].value : [];
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    updateJson(event) {
        try {
            this.json.attributes.parameters = JSON.parse(event);
        } catch (e) {
        }
    }

    updateJsonContext(value: TileParameters) {
        try {
            this.json.attributes.parameters = value;
        } catch (error) {
            console.error('Invalid JSON:', error);
            // The form control will be marked as invalid due to the validator
        }
    }

    updatePivotParams(event): void {
        this.json.attributes.parameters = event;
        this.pivotIds = event.event_type_ids?.length ? event.event_type_ids : event.component_type_ids;
        this.pivotModel = event.event_type_ids?.length ? 'event_type_light' : 'component_type';
    }

    onUpdateJsonContextChange(newJsonContext: any) {
        this.updateJsonContext(newJsonContext);
    }

    contentChange($event?) {
        this.json.attributes.show_header = true;
        this.json.attributes.parameters = {};
        switch (this.json.attributes.content) {
            case 'budget-bar-chart':
                this.json.attributes.parameters = {};
                this.tile_class = 'third';
                break;
            case 'category-chart':
                this.json.attributes.parameters = {
                    library: 'c3',
                    labels: {
                        title: '',
                        sub_title: '',
                        y_axis: '',
                        x_axis: '',
                    },
                    series_list: [{
                        name: null,
                        series_id: null,
                        type: 'category',
                        sample_period: null,
                        number_of_periods: null,
                    }]
                };
                this.tile_class = 'third';
                break;
            case 'custom-chart':
                this.json.attributes.parameters = {
                    labels: {
                        title: null,
                        sub_title: null,
                        y_axis: null,
                        y2_axis: null

                    },
                    hide_comments: false,
                    series_list: [{
                        axis: 'y',
                        colour: null,
                        cumulative: null,
                        name: null,
                        show_limits: false,
                        type: 'line',
                        range: null,
                        custom_legend_name: undefined,
                        vertical_data_labels: true
                    }]
                };
                this.tile_class = 'third';
                break;
            case 'custom':
                this.json.attributes.show_header = true;
                this.json.attributes.parameters = new CustomHTMLParameters();
                this.tile_class = 'third';
                break;
            case 'comparison-chart':
                this.json.attributes.parameters = {
                    labels: {
                        title: null,
                        sub_title: null
                    },
                    hide_comments: true,
                    colour: null,
                    type: 'scatter',
                    chart_type: 'comparison',
                    series_list: [{
                        axis: 'x',
                        name: null
                    }, {
                        axis: 'y',
                        name: null
                    }],
                    statistics: {
                        averages: true,
                        std_dev: true,
                        pooled: true,
                        correlation_coeff: true
                    },
                    diagonal_line: true,
                    regression_line: 'linear'
                };
                break;
            case 'comment-tile':
                this.json.attributes.parameters = {
                    series_list: [],
                    process_list: [],
                    session_state_list: [],
                    event_type: null
                };
                this.tile_class = 'full';
                break;
            case 'component-creator':
                this.json.attributes.parameters = {
                    component_types: undefined
                };
                this.tile_class = 'two-thirds';
                break;
            case 'component-form':
                this.json.attributes.parameters = {
                    selected_component_type: undefined,
                    selected_cols: null
                };
                this.tile_class = 'two-thirds';
                break;
            case 'component-events-table':
                this.json.attributes.parameters = {
                    selected_component_type: null,
                    selected_cols: {component_type: [], event: [], component: {}},
                    printout: false,
                    search: true,
                    constant_property_time: false,
                    name_must_contain: undefined
                };
                this.tile_class = 'full';
                break;
            case 'custom-events':
                this.json.attributes.parameters = {
                    estimate_type: "Forecast",
                    non_editable_columns: [],
                    column_order: [], // this.seriesData.event_columns,
                    event_type_names: null,
                    column_formats: {}
                };
                this.tile_class = 'full';
                break;
            case 'custom-events-table':
                this.json.attributes.parameters = {
                    estimate_type: "Forecast",
                    non_editable_columns: [],
                    column_order: [],
                    event_type_names: null,
                    column_formats: {},
                    columns: []
                };
                this.tile_class = 'full';
                break;
            case 'data-exceptions-table':
                this.json.attributes.parameters = {};
                this.tile_class = 'full';
                break;
            case 'default-chart':
                this.json.attributes.parameters = {};
                this.tile_class = 'third';
                break;
            case 'events':
                this.json.attributes.parameters = {
                    estimate_type: "Forecast",
                    title: "Events"
                };
                break;
            case 'flowchart':
                this.json.attributes.parameters = {
                    process: null,
                };
                break;
            case 'input-sheet':
                this.json.attributes.parameters = {
                    "process": null
                };
                this.tile_class = 'full';
                break;
            case 'julian-date':
                this.json.attributes.title = 'Current Julian Date';
                this.json.attributes.parameters = {
                    font_size: 6
                };
                break;
            case 'log-sheet':
                this.tile_class = 'full';
                this.json.attributes.parameters = {
                    columns: [],
                    hidden_columns: []
                };
                break;
            case 'model-pivot-tile':
                this.json.attributes.parameters = {
                    model_type: "",
                    pivot_state: {},
                    series_list: [],
                    component_list: []
                };
                this.tile_class = 'full';
                break;
            case 'ore-body-events':
                this.json.attributes.parameters = {
                    estimate_type: "Forecast",
                    non_editable_columns: [],
                    column_order: [],
                    event_type_names: null,
                    column_formats: {}
                };
                this.tile_class = 'full';
                break;
            case 'page-comment-table':
                this.json.attributes.parameters = {
                    series_list: [],
                    process_list: [],
                    session_state_list: [],
                    event_type: null
                };
                this.tile_class = 'full';
                break;
            case 'paragraph':
                this.json.attributes.show_header = false;
                this.json.attributes.parameters = {paragraph_body: {}};
                this.tile_class = 'third';
                break;
            case 'pending-context':
                this.json.attributes.parameters = {
                    type: 'event_to_component',
                    context: {
                        component_type: {id: null, value: null},
                        event_type: {
                            id: null, value: null,
                            constant_properties: [],
                            unique_constant_property: null
                        }
                    },
                    customisation: {
                        component_type: {},
                        constant_properties: {}
                    },
                    validation: {
                        component_type: {},
                        constant_properties: {}
                    }
                };
                this.tile_class = 'half';
                break;
            case 'pivot-tile':
                this.json.attributes.parameters = {
                    event_types: "",
                    pivot_state: {},
                    series_list: [],
                    component_type_ids: []
                };
                this.tile_class = 'full';
                break;
            case 'pivot-tile-ng':
                this.json.attributes.parameters = {
                    event_types: "",
                    pivot_state: {},
                    series_list: [],
                    ore_bodies_list: []
                };
                this.tile_class = 'full';
                break;
            case 'series-table':
                this.json.attributes.parameters = {
                    series_list: [{
                        attributes: {
                            description: null,
                            event_type_name: null,
                            kpi_level: null,
                            name: null
                        },
                        "id": null,
                        "type": null
                    }],

                    process: {
                        attributes: {
                            description: "",
                            name: ""
                        },
                        id: "",
                        type: "process"
                    },

                    kpis: [],
                    normal_columns: this.seriesData.columnsDefault,

                    detail_columns: [],

                    mobile_columns: this.seriesData.columnsSmall,

                    column_text: {},
                    estimate_type: "Forecast",
                };
                this.tile_class = 'full';
                break;
            case 'series-adjustment':
                this.json.attributes.parameters = {
                    adjustments: [],
                };
                this.tile_class = 'full';
                break;
            case 'series-data-table':
                this.json.attributes.parameters = {
                    process_list: [],
                    process_series_map: {},
                    header_rows: {alias: true, process: true, unit: true},
                    column_formats: {}
                };
                this.tile_class = 'full';
                break;
            case 'series-summary':
                this.json.attributes.parameters = {};
                this.tile_class = 'third';
                break;
            case 'spc-chart':
                this.json.attributes.parameters = {
                    labels: {
                        title: null,
                        sub_title: null,
                        y_axis: null,
                        y2_axis: null
                    },
                    hide_comments: false,
                    series_list: [{
                        axis: 'y',
                        colour: null,
                        cumulative: null,
                        name: null,
                        show_limits: false,
                        type: 'line',
                        range: null,
                        custom_legend_name: undefined
                    }],
                    chart_type: 'spc',
                    three_sigma: null
                };
                this.tile_class = 'third';
                break;
            case 'upload-model':
                this.json.attributes.parameters = {
                    model_selected: null,
                    templates: [],
                    default_template: null,
                    update_only: true
                };
                this.tile_class = 'full';
                break;
            case 'value-driver-tree':
                this.json.attributes.parameters = {
                    selected_calculation: null,
                    formula_view: false,
                    state: {pan: "translate3d(17px, -216px, 0px)"} // default pan to show initial boxes within tile
                };
                this.tile_class = 'full';
                break;
            case 'waterfall-chart':
                this.json.attributes.parameters = {
                    library: 'plotly',
                    series_list: [],
                    labels: {},
                    chart_type: 'waterfall',
                    positive_colour: 'Green',
                    negative_colour: 'Red',
                    total_colour: 'Blue'
                };
                this.tile_class = 'two-thirds';
                break;
            case 'weather-widget':
                this.json.attributes.parameters = {
                    title: 'Weather',
                };
                this.tile_class = 'third';
                break;
            default:
                this.json.attributes.parameters = this.tileData.getDefaultTileParameters();
                this.json.attributes.content = this.json.attributes.content || this.tileData.getDefaultTileContent();
                this.tile_class = 'third';
        }
        console.log("json", this.json.attributes.parameters);
    }

    toggleRelativeDtp(event, init = false) {
        if (event.checked) {
            this.is_relative_dtp = true;
            this.json.attributes.custom_dtp = true;
            if (init === true) {
                return;
            }
            this.json.attributes.relative_dtp = {
                from: {duration: 1, key: 'day'},
                to: {duration: 0, key: 'day'},
                relative_to: 'start',
                sample_period: 'hour'
            };
        } else {
            this.is_relative_dtp = false;
            this.json.attributes.custom_dtp = false;
        }
    }

    save = (): void => {
        const SaveMessage = 'Please note: "Save" will push the attribute changes you have made into production.';
        let saveNotification: MatSnackBarRef<ConfirmSnackbarComponent>;
        saveNotification = this.notification.openConfirm(SaveMessage);
        saveNotification.onAction().subscribe((): void => {
            const allTrue = this.beforeSaveChildren.toArray().every(component => component.beforeSave() !== false);
            if (!(allTrue && this.validate())) {
                return;
            }
            if (!this.is_relative_dtp) {
                this.json.attributes.relative_dtp = null;
            }
            this.json.attributes = this.dateTimePeriodService.deconstructDtp(this.json.attributes, this.dateInst.dtp);
            this.tileData.saveTile(this.json).pipe(take(1)).subscribe({
                next: result => {
                    this.dialogRef.close({tile: result.data, options: {class: this.tile_class}});
                }, error: (err) => {
                    console.log('ERROR: PageTileForm (save) \n Tile Not Saved: ', err);
                }
            });
        });
    }

    validate() {
        switch (this.json.attributes.content) {
            case 'default-chart':
                if (!this.json.attributes.parameters.series) {
                    this.notification.openError('please select a series', 5000);
                    return false;
                }
                return true;
            case 'value-driver-tree':
                if (!this.json.attributes.parameters.selected_calculation) {
                    this.notification.openError('please select a calculation series', 5000);
                    return false;
                }
                return true;
            case 'spc-chart':
                if (!this.json.attributes.parameters.three_sigma) {
                    this.notification.openError('3 Sigma cannot be empty', 5000);
                    return false;
                }
                return true;
            case 'upload-model':
                if (!this.json.attributes.parameters.model_selected?.parser_type) {
                    this.notification.openError('please select a  Model Type before saving', 5000);
                    return false;
                }
                return true;
            default:
                return true;
        }
    }

    onCloseClick(): void {
        let confirmNotification: MatSnackBarRef<ConfirmSnackbarComponent>;
        confirmNotification = this.notification.openConfirm('Please note: "Close" will not save any changes you have made.');
        confirmNotification.onAction().subscribe((): void => {
            this.dialogRef.close();
        });
    }

    goToTab(tabGroup: MatTabGroup, tab) {
        let index = 0;
        tabGroup._tabs.forEach(t => {
            if (t.textLabel === tab) {
                index = tabGroup._tabs.toArray().indexOf(t);
            }
        });
        tabGroup.selectedIndex = index;
    }

    tabChanged(event: MatTabChangeEvent) {
        this.current_tab = event.index;
        switch (event.index) {
            case 0:
                break;
            case 1:
                this.json = utils.deepCopy(this.json);
                break;
            default:
                break;
        }
    }

    filterTiles(tileFilterString: string) {
        const filterString = tileFilterString.toLowerCase();
        this.tileOptions = this.tileData.content_types.filter(ct =>
            ct.name.toLowerCase().includes(filterString) ||
            ct.value.toLowerCase().includes(filterString)
        );
    }

    resetTileOptions() {
        this.tileOptions = this.tileData.content_types;
        this.tileFilterString = "";
    }

    filterCategories(tileFilterString: string) {
        const filterString = tileFilterString.toLowerCase();
        this.tile_categories = this.getTileCategories().filter(ct =>
            ct.name.toLowerCase().includes(filterString) ||
            ct.colour.toLowerCase().includes(filterString)
        );
    }

    resetCategoryOptions() {
        this.tile_categories = this.getTileCategories();
        this.tileFilterString = "";
    }

    parametersChanged(event: ReportParameters) {
        this.json.attributes.parameters = event;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
