import * as moment_ from 'moment';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Observable, Subject} from "rxjs";
import {first, takeUntil} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {AppScope} from '../../services/app_scope.service';
import {SearchQueryOptions} from '../../services/api/search-query-options';
import {SeriesDataService} from '../../services/series_data.service';
import {PaginationDataSource} from '../../services/api/pagination-data-source';
import {ListResponse} from '../../services/api/response-types';
import {getAccountFilter} from "../../services/api/filter_utils";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export const moment = moment_["default"];


export enum DataExceptionTableTab {
    OK = 'OK',
    ALERT = 'ALERT',
    ALL = 'all',
    OPEN = 'open',
    UNDER_REVIEW = 'under_review',
    ACTIONED = 'actioned',
    CLOSED = 'closed',
    FALSE_POSITIVE = 'false_positive',
}

@Component({
    selector: 'data-exceptions-table',
    templateUrl: 'data-exceptions-table.component.html',
    styleUrls: ['data-exceptions-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DataExceptionsTableComponent implements OnInit, OnDestroy {
    @ViewChildren(MatSort) sorters = new QueryList<MatSort>();
    @ViewChildren(MatPaginator) paginators = new QueryList<MatPaginator>();

    page_ready: boolean = false;

    columns: string[] = ["series", "start", "end", "message", "initial_breach_value", "max_breach_value",
        "state", "is_muted", "changed_on", "changed_by_name", "account_name"];

    searchColumns: string[] = ['message', 'state', 'account_name'];

    dtp: IDateTimePeriod;
    tabs: string[] = [
        DataExceptionTableTab.ALL,
        DataExceptionTableTab.OK,
        DataExceptionTableTab.ALERT,
        DataExceptionTableTab.OPEN,
        DataExceptionTableTab.UNDER_REVIEW,
        DataExceptionTableTab.ACTIONED,
        DataExceptionTableTab.CLOSED,
        DataExceptionTableTab.FALSE_POSITIVE
    ];
    dataSource: { [tab in string]: PaginationDataSource<any> } = {};
    can_edit: boolean = false;

    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private headerData: HeaderDataService,
                public dialog: MatDialog,
                private seriesData: SeriesDataService,
                private appScope: AppScope) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.headerData.title = 'Data Exceptions';
        ctrl.buildHeader();
        ctrl.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.refreshDataSource();
        });
        ctrl.dateTimePeriodService.dtpInitialisedPromise.promise.then((dtp) => {
            ctrl.dtp = ctrl.dateInst.dtp;
            ctrl.initTabDatasource(0);
        });
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.data_exception.searchMany(query).pipe(
            takeUntil(this.onDestroy)
        )
    }

    initTabDatasource(tabIndex: number) {
        const ctrl = this;
        const tab = ctrl.tabs[tabIndex];
        if (!ctrl.dataSource[tab]) {
            const initialQuery = new SearchQueryOptions();
            initialQuery.page_number = 1;
            initialQuery.sort = 'start';
            initialQuery.filters = ctrl.generateDataFilter(tab);
            const paginator = ctrl.paginators.get(tabIndex);
            paginator.pageSize = 20;
            ctrl.dataSource[tab] = new PaginationDataSource(
                (query) => ctrl.page(query),
                initialQuery,
                paginator
            );
            ctrl.dataSource[tab].$page.pipe(first()).subscribe(_ => {
                const initialized = [...ctrl.tabs.keys()].filter(i => ctrl.dataSource[ctrl.tabs[i]])
                const sorterIndex = initialized.indexOf(tabIndex);
                ctrl.dataSource[tab].sorter = ctrl.sorters.get(sorterIndex);

            });
        }
    }

    generateDataFilter(tab, searchKey?) {
        const ctrl = this;
        const filters: object[] = [
            {
                "or": [
                    {
                        "and": [{name: 'start', op: 'ge', val: ctrl.dtp.start}, {
                                name: 'start',
                                op: 'le',
                                val: ctrl.dtp.end
                            }]
                    }, {
                        "and": [{name: 'end', op: 'ge', val: ctrl.dtp.start}, {
                                name: 'end',
                                op: 'le',
                                val: ctrl.dtp.end
                            }]
                    }
                ]
            }, getAccountFilter(ctrl.appScope.active_account_id)
        ];
        if (tab !== DataExceptionTableTab.ALL) {
            filters.push({
                'or': [
                    { name: 'state', op: 'eq', val: tab },
                    { name: 'action_taken', op: 'eq', val: tab }
                ]
            });
        }
        let searchFilters;
        if (searchKey) {
            searchFilters = {
                or: ctrl.searchColumns.map(col => {
                    return { name: col, op: 'ilike', val: `%${searchKey}%`};
                })
            };
        }
        if (searchFilters) {
            filters.push(searchFilters);
        }
        return filters;
    }

    editSeries(element) {
        const ctrl = this;
        const series_id = element.relationships.series.data.id;
        const series_type = element.relationships.series.data.type;

        //This series type is correct (relationships stubs have the correct type)
        let $series_full = ctrl.api.series.getById(series_id).toPromise();

        $series_full.then(result => {
            if (!result) return;

            let series_full = result.data;
            ctrl.seriesData.upsertSeries(null, series_full).afterClosed().subscribe((series) => {
                if (series) {
                    ctrl.dateInst.dateTimePeriodRefreshed$.next(ctrl.dtp);
                }
            })
        })
    }

    buildHeader() {
        const ctrl = this;
        //ctrl.headerData.title = 'Data Exceptions';
        //ctrl.headerData.show_dtp = false;
    }

    refreshDataSource(selectTab?, searchKey?) {
        const ctrl = this;
        ctrl.tabs.forEach(tab => {
            if ((selectTab && tab == selectTab || !selectTab) && ctrl.dataSource[tab]) {
                const filters = ctrl.generateDataFilter(tab, searchKey);
                this.dataSource[tab].filterBy(filters);
            }
        });
    }

    applyFilter(tab, filterValue) {
        const ctrl = this;
        const searchKey = filterValue.trim().toLowerCase();
        ctrl.refreshDataSource(tab, searchKey);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
