import {Component, ViewChild} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {take, takeUntil, tap} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {ListResponse} from "../../services/api/response-types";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {getAccountFilter} from "../../services/api/filter_utils";
import {AppScope} from "../../services/app_scope.service";
import {SolverTemplate} from "../../_models/solver";
import {RESTFilter} from "../../_typing/generic-types";
import {SolverTemplateFormComponent} from "../../forms/solver-template-form/solver-template-form.component";
import {SolverFormComponent} from '../../forms/solver-form/solver-form.component';

@Component({
    selector: 'solver-template-table',
    templateUrl: './solver-template-table.component.html',
    styleUrls: ['./solver-template-table.component.less'],
    standalone: false
})
export class SolverTemplateTableComponent {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    filterString = '';
    dataSource: PaginationDataSource<SolverTemplate>;
    pageSizeOptions = [10, 20, 50, 100];
    numSolverTemplates = 0;
    buttons = [];
    solverTemplates: SolverTemplate[];
    columns: string[] = ["name", "description", "account", "created_on", "created_by_name", "changed_on", "changed_by_name"];

    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private appScope: AppScope,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.buildHeader();

        // ToDo: setTimeout feels like a hack. ngAfterViewInit is supposed to be the answer, but it isn't working
        //  because the console then complains that the datasource has changed after init
        setTimeout(() => {
            const initialQuery = new SearchQueryOptions();
            initialQuery.page_number = 1;
            initialQuery.sort = 'name';
            initialQuery.filters = this.getBaseFilters();
            this.paginator.pageSize = 10;
            this.dataSource = new PaginationDataSource<SolverTemplate>(
                (query) => this.page(query),
                initialQuery,
                this.paginator,
                this.sort
            );
            this.dataSource.$page.pipe(takeUntil(this.onDestroy)).subscribe();
        }, 1000);
    }

    getBaseFilters(): RESTFilter {
        return [getAccountFilter(this.appScope.active_account_id)];
    }

    page(query: SearchQueryOptions): Observable<ListResponse<any>> {
        return this.api.solver_template.searchMany(query).pipe(
            take(1), tap(result => {
                this.solverTemplates = result.data;
                this.numSolverTemplates = result.meta.count;
            })
        );
    }

    updateSearchFilter() {
        let filters = this.getBaseFilters();
        const atts = ['name', 'description'];
        let string_filters = {or: []};
        atts.forEach(att => {
            string_filters.or.push({op: 'ilike', name: att, val: '%' + this.filterString + '%'});
        });

        // ToDo: Constraint eqn filters, maybe?
        // const cp_filters = this.constantPropertiesSearchFilter(filter_string);
        // string_filters.or = string_filters.or.concat(cp_filters);

        filters.push(string_filters);
        this.dataSource.filterBy(filters);
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort);
    }

    editSolverTemplate(solverTemplate: SolverTemplate) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {solverTemplate: solverTemplate};
        dialogConfig.panelClass = ['default-form-dialog'];
        let dialogRef = this.dialog.open(SolverTemplateFormComponent, dialogConfig);
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.updateSearchFilter();
            }
        });
    }

    openRunSolver(solverTemplate) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {solverTemplate: solverTemplate};
        dialogConfig.panelClass = ['default-form-dialog'];
        let dialogRef = this.dialog.open(SolverFormComponent, dialogConfig);
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            // if (result) {
            //     this.updateSearchFilter();
            // }
        });
    }

    buildHeader() {
        this.headerData.title = 'Solver Templates';
        this.headerData.show_dtp = false;
        this.headerData.buttons = [{
            name: 'Add Solver Template',
            func: this.editSolverTemplate.bind(this),
            class: 'icon-plus'
        }];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
