import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../shared/base.component";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {IDMap} from "../../_typing/generic-types";

/**
 * Utils bar
 *
 * This bar above any table that provides a search box (and potentially a search before load option)
 *
 * **Inputs:**
 * - `search`: Default = true, can be set to false by users on some tables
 *
 * **Outputs:**
 * - `searchValueSubmitted`: Emits the user-entered filter string
 * - `searchCleared`: Emits when the 'clear input' is clicked
 * --explanations for other emitters...
 */
@Component({
    selector: 'utils-bar',
    templateUrl: 'utils-bar.component.html',
    styleUrls: ['utils-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UtilsBarComponent extends BaseComponent {
    @Input() searchByName: boolean;
    @Output() searchByNameValueSubmitted: EventEmitter<string> = new EventEmitter();
    @Input() search: boolean;
    @Output() searchValueChanged: EventEmitter<string> = new EventEmitter();
    @Output() searchCleared: EventEmitter<void> = new EventEmitter();
    @Output() searchValueSubmitted: EventEmitter<string> = new EventEmitter();
    @Output() searchSelectionsChanged: EventEmitter<string[]> = new EventEmitter();

    @Input() userSearchFields: string[];
    @Input() sortByArray: string[];
    userSearchSelections: string[];
    searchByNameValue: string;
    searchValue: string;

    constructor() {
        super();
    }

    ngOnInit() {
        if (this.userSearchFields && !this.sortByArray) {
            this.sortByArray = Object.values(this.userSearchFields).sort();
        }
    }

    relaySearchByNameSubmitted() {
        this.searchByNameValueSubmitted.next(this.searchByNameValue);
    }

    relaySearchCleared() {
        this.searchValue = "";
        this.searchCleared.next();
    }

    relaySearchValueChanged() {
        this.searchValueChanged.next(this.searchValue);

    }

    relaySearchSubmitted() {
        this.searchValueSubmitted.next(this.searchValue);

    }

    relaySearchSelectionsChanged(event) {
        this.userSearchSelections = event;
        this.searchSelectionsChanged.next(this.userSearchSelections);
    }

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;
}
