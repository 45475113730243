<div class="date-picker">
  <div class="row center" style="position: relative" [class.disabled]="disabled" [style]="styles">
    <div class="row center now-button-part" *ngIf="!input_date && !hide_now_button && !disabled">
      <button class="now-button"
              [matTooltip]="'Current time'"
              (click)="setToNow()">Now
      </button>
    </div>
    <div class="row center date-part">
      <input matInput
             (dateInput)="inputDate($event);"
             (dateChange)="changeDate($event);"
             (click)="hide_now_button=true"
             (blur)="hide_now_button=!nowButton"
             [matDatepicker]="startPicker"
             [max]="max"
             [min]="min"
             [value]="input_date"
             [placeholder]="placeholder"
             [disabled]="disabled"
             [class.disabled]="disabled"
             class="datetime"
             name="start"
             tabindex="-1">
      <mat-datepicker-toggle [for]="startPicker" class="date-picker-toggle" matSuffix>
        <i [hidden]="disabled" class="fas fa-caret-down" matDatepickerToggleIcon></i>
      </mat-datepicker-toggle>
      <mat-datepicker #startPicker [startAt]="input_date ? input_date : default"
                      [dateClass]="dateClass" panelClass="date-time-picker" (opened)="opened($event)">
        <mat-datepicker-actions>
          <button mat-button style="background:#c2b49b;" (click)="setToNow()">Now</button>
          <button mat-button style="background:#dbd3d2;" (click)="clearDateTime()">Clear</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </div>
    <div *ngIf="show_time !==false" class="row center time-part">
      <input #timePicker
             (change)="changeTime($event)"
             [value]="input_date | stringDate : {time_only:true}"
             [disabled]="disabled"
             [class.disabled]="disabled"
             class="datetime time"
             name="start_time"
             tabindex="-1"
             type='time'
      >
    </div>
  </div>
</div>
