<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Properties">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.name" matInput name="name" placeholder="">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Description</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.description" matInput name="description" placeholder="">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='Name'}" class="tip">
                <label>Name</label>
                <div>
                  The name of the stream.
                </div>
              </div>
              <div [ngClass]="{'showing': hint=='Description'}" class="tip">
                <label>Description</label>
                <div>
                  A more lengthy description of the stream.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Code</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.code" matInput name="code" placeholder="">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='Code'}" class="tip">
                <label>Code</label>
                <div>
                  An alpha-numerical code that sets the sort order of a group of streams.
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </mat-tab>
    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Display">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <div class="flex-half alone">
              <mat-form-field>
                <mat-label>Font Size</mat-label>
                <input [(ngModel)]="efc.component_data.attributes.json.fontsize" matInput max="32" name="font_size"
                       type="number">
              </mat-form-field>

              <colour-palette
                  [(value)]="efc.component_data.attributes.json.colour"
                  [palette]="1"
                  [show_text]="true"
                  [label]="'Stream Colour'">
              </colour-palette>
            </div>
          </div>
          <div class="tip-box" style="flex-grow:1;">
            <div [ngClass]="{'showing': hint=='FontSize'}" class="tip">
              <label>Font Size</label>
              <div>
                The size of the font displayed for this stream in the flowchart. (A maximum of 32px is allowed.)
              </div>
            </div>
          </div>
        </div>

      </form>
    </mat-tab>

    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Relationships">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <mat-form-field (mouseenter)="hint='StreamType'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Stream Type</mat-label>
              <mat-select
                  (selectionChange)="efc.fillConstantTemplate(efc.component_data.relationships.component_type.data)"
                  [(ngModel)]="efc.component_data.relationships.component_type.data"
                  name="stream_type">
                <mat-option *ngFor="let component_type of efc.component_types" [value]="component_type">
                  {{component_type.attributes.name}} - {{component_type.attributes.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='StreamType'}" class="tip">
                <label>Stream Type</label>
                <div>
                  This will be used to identify the template of series types that are applicable for this stream.
                  After creating the stream, the 'Fill series template' button can be used to add these series types to
                  this process.
                </div>
              </div>

              <div [ngClass]="{'showing': hint=='FillSeriesTemplate'}" class="tip">
                <label>Fill Series Template</label>
                <div>
                  This will be used to identify the template of series types that are applicable for this stream.
                  After creating the stream, the 'Fill series template' button can be used to add these series types to
                  this stream.
                </div>
              </div>
            </div>
          </div>

          <ng-container>
            <h4>Custom Properties</h4>
            <component-constant-table [constant_property_dict]="efc.constant_property_dict"
                                      [component]="efc.component_data"
                                      [component_constants]="efc.constant_components"
                                      [flowchart]="efc.chart"
                                      class="row">
            </component-constant-table>
          </ng-container>

          <h4>Series</h4>
          <div class="row end">
            <select-many-search [(value)]="efc.selected_series"
                                [classes]="'fill'"
                                [data]="efc.plantData.all_series"
                                [fill_stubs]="true"
                                [label]="'Select a series'"
                                class="select-search">
            </select-many-search>

            <div class="row end">
              <button (click)='efc.addSeries()' class="icon" matTooltip="Add to stream">
                <i class="fa fa-arrow-down"></i></button>
              <button (click)="efc.showSeriesForm()" class="icon-new" matTooltip="Add new series">
              </button>
            </div>
          </div>

          <h4 *ngIf="efc.series_permissions" style="margin-top:20px;">Constant Series Data </h4>

          <series-component-table *ngIf="efc.component_data"
                                  [all_series]="efc.plantData.all_series"
                                  [component]="efc.component_data"
                                  [deleted_component_ids]="efc.deleted_component_ids"
                                  [flowchart]="efc.chart"
                                  class="row">
          </series-component-table>

          <ng-container *ngIf="efc.series_permissions">
            <div *ngFor="let seriesComponent of efc.component_data.relationships.series_components.data;"
                 style="min-width:650px;">
              <ng-container *ngIf="seriesComponent.relationships.series.data && (
                                seriesComponent.relationships.series.data.attributes.fill_method=='Constant'
                                || seriesComponent.relationships.series.data.attributes.fill_method=='Constant Monthly Flatten')
                                && efc.series_permissions[seriesComponent.relationships.series.data.id].indexOf('view_process_data') > -1">

                <div class="row">
                  <label
                      (click)="seriesComponent.relationships.series.data.hide_constants=!seriesComponent.relationships.series.data.hide_constants"
                      style="color:gray; text-decoration: underline;padding-top:20px;cursor:pointer;">
                    {{seriesComponent.relationships.series.data.attributes.name}}
                    <i [ngClass]="{'fa-angle-up': !seriesComponent.relationships.series.data.hide_constants, 'fa-angle-down': seriesComponent.relationships.series.data.hide_constants}"
                       aria-label="Hide data values"
                       class="fa fa-angle-up"
                       matTtooltip="Hide data values" role="button" style="border:none; margin-left:12px;" tabindex="0">
                    </i></label>
                </div>
                <constant-series-data *ngIf="!seriesComponent.relationships.series.data.hide_constants"
                                      [series]="seriesComponent.relationships.series.data"
                                      [series_permissions]="efc.series_permissions">
                </constant-series-data>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>
