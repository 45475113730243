import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE} from "./shared-types";

export type ConstantPropertyDataType = 'float' | 'string' | 'datetime' | 'series' | 'file';

export class ConstantPropertyAttributes extends AuditMixinAttributes {
    name: string | null;
    description: string | null;
    alias: string | null;
    name_formula: string | null;
    aggregation: 'total' | 'mean' | 'count';
    is_drop_down_list: boolean;
    is_calculation: boolean;
    data_type: ConstantPropertyDataType = 'string';
    file_type: string;
    json: any | null;
}

export class ConstantPropertyRelationships extends AuditMixinRelationships {
    event_types: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    component_types: LIST_RELATIONSHIP_TYPE;
}

export class ConstantProperty extends AuditMixin {
    attributes: ConstantPropertyAttributes;
    id: string;
    type: string = 'constant_property';
    relationships: ConstantPropertyRelationships;

    constructor() {
        super();
        this.attributes = new ConstantPropertyAttributes();
        this.relationships = new ConstantPropertyRelationships();
    }
}

export function isConstantProperty(property): property is ConstantProperty {
    return property?.type === 'constant_property';
}
