import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import * as utils from "../../../lib/utils";
import {
    IRarChartSeriesConfiguration,
    RarChartSeriesConfiguration
} from "../../../charts/chart-config/chart-series.configuration";
import {SeriesDataService} from "../../../services/series_data.service";
import {Subject} from "rxjs";
import {take, takeUntil} from 'rxjs/operators';
import {RarRangeTypeArray} from "../../../charts/chart-config/chart-configuration";
import {Series, SeriesLight} from '../../../_models/series';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {GenericChartTileConfiguration} from "../../../charts/chart-config/generic-chart-tile.configuration";
import {getManyRelationWithIdFilter} from '../../../services/api/filter_utils';
import {deepCopy} from "../../../lib/utils";
import {ListResponse} from "../../../services/api/response-types";
import {DateTimePeriodService} from '../../../services/date-time-period.service';

@Component({
    selector: 'rar-chart-form-series-table',
    templateUrl: './rar-chart-form-series-table.component.html',
    styleUrls: ['./rar-chart-form-series-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RarChartFormSeriesTableComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();

    @Input() series_list: RarChartSeriesConfiguration[];
    @Output() series_listChange: EventEmitter<Partial<IRarChartSeriesConfiguration>[]> = new EventEmitter<RarChartSeriesConfiguration[]>();

    series: Series;
    target_series: Series[];
    target_series_filter: any[];
    expanded: Array<boolean>;
    selected_groups: string[];
    sample_periods: any[];
    ranges: any[];

    public hint: string;

    public readonly period_number_options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    public readonly rar_group_types = RarRangeTypeArray;

    public series_dict = {};

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private seriesData: SeriesDataService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    matSelectCompare(option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    }

    ngOnInit() {
        const ctrl = this;
        this.expanded = new Array(50).fill(false);

        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.selected_groups = this.series_list.map((s: Partial<IRarChartSeriesConfiguration>) => s.group_name);
            ctrl.sample_periods = utils.deepCopy(ctrl.dateTimePeriodService.sample_periods);
            ctrl.sample_periods = ctrl.sample_periods.filter(period => {
                return ["day", "month", "hour", "week"].includes(period.name);
            });

            ctrl.ranges = utils.deepCopy(ctrl.dateTimePeriodService.ranges);
            this.changeDetectorRef.markForCheck();
        });

        if (ctrl.series_list?.length) {
            this.getInitValues();
        } else {
            this.series_dict = {};
            // Add the 3 default groups - yesterday, past 7 days and past 3 months
            this.selected_groups = deepCopy(this.rar_group_types);
            this.updateGroups(this.selected_groups);
        }

        this.changeDetectorRef.markForCheck();
    }

    getInitValues() {
        const series_id = this.series_list.filter(s => !s.is_target)?.[0].id;
        this.seriesData.getSeriesLightListById(this.series_list.map(s => s.id)).pipe(
            take(1),
            takeUntil(this.onDestroy)
        ).subscribe((result: ListResponse<SeriesLight>) => {
            result.data.map(s => this.series_dict[s.id] = s);
            this.series = this.series_dict[series_id];
            this.createTargetsFilter();
            this.changeDetectorRef.markForCheck();
        });
    }

    applySeriesToConfigList($event) {
        this.series = $event;
        this.series_list.forEach((s: Partial<IRarChartSeriesConfiguration>) => {
            this.addNameToConfig(s, $event);
        });
        this.series_list = this.series_list.filter(s => !s.is_target);
        this.createTargetsFilter();
        this.fetchTargets();
        this.series_listChange.emit(this.series_list);
    }

    updateGroups(event) {
        const filtered_list = this.series_list.filter(item => event.includes(item.group_name) || item.is_target);
        const new_groups = event.filter(newGroup => !this.series_list.some(item => item.group_name === newGroup));

        const new_items = GenericChartTileConfiguration.newRarChartSeriesListConfig(new_groups, this.series, this.dateTimePeriodService);

        this.series_list = [...filtered_list, ...new_items];
        this.series_listChange.emit(this.series_list);
    }

    createTargetsFilter() {
        this.target_series_filter = [getManyRelationWithIdFilter('source_series', this.series.id)];
    }

    fetchTargets() {
        this.seriesData.getSeriesBySource(this.series.id).pipe(takeUntil(this.onDestroy), take(1))
            .subscribe(result => {
                this.target_series = result.data;
                this.setTargets(this.target_series);
            });
    }

    getTargets() {
        return this.series_list.filter(s => s.is_target);
    }

    setTargets(event) {
        const filtered_list = this.series_list.filter(s => (!s.is_target || event.map(t => t.id).includes(s.id)));
        const new_targets = event.filter(newTarget => !this.series_list.some(item => item.is_target && item.id === newTarget.id));

        const new_items = new_targets.map(target => {
            return GenericChartTileConfiguration.newRarTargetSeriesConfig(target, this.dateTimePeriodService) as RarChartSeriesConfiguration;
        });
        this.series_list = [...filtered_list, ...new_items];
        this.series_listChange.emit(this.series_list);
    }

    private addNameToConfig(series_config, event) {
        this.series_dict[event.id] = event;
        series_config.name = event.attributes.name;
        series_config.id = event.id;
        this.changeDetectorRef.markForCheck();
    }

    removeRow(index) {
        const ctrl = this;
        let removed = ctrl.series_list.splice(index, 1);

        ctrl.series_listChange.emit(ctrl.series_list);
        this.changeDetectorRef.markForCheck();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
