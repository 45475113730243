import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import { HttpClient } from "@angular/common/http";
import {catchError, map, take} from "rxjs/operators";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'generate-auth-token',
    templateUrl: './generate-auth-token.component.html',
    styleUrls: ['./generate-auth-token.component.less'],
    standalone: false
})
export class GenerateAuthTokenComponent implements OnInit {
    days: FormControl<number>;
    auth_token: string;

    constructor(private http: HttpClient,
                public appScope: AppScope,
                private headerData: HeaderDataService,
                private notification: NotificationService) {
    }

     ngOnInit(): void {
        this.headerData.title = 'Access Token';
        this.appScope.no_header = false;
        this.days = new FormControl<number>(90);
    }

    generateToken(): void {
        this.http.get('/auth/token/' + this.appScope.current_user.id + '/' + this.days.value * 24 * 3600).subscribe((response: any) => {
            this.appScope.current_user.auth_token_active = true;
            this.auth_token = response.token;
        });
    }

    deactivateToken(): void {
        this.http.get('/auth/deactivate_token/' + this.appScope.current_user.id).pipe(map((response: string) => {
            this.appScope.current_user.auth_token_active = false;
            this.auth_token = '';
            this.notification.openSuccess(response);
            console.log('deactivate auth token success', response);
        }), catchError( (err) => {
            console.log('deactivate auth token failure', err);
            return err;
        })).subscribe();
    }

}
