<form (ngSubmit)="onOTPSubmit()" [formGroup]="otpForm" class="column">
    <label>One Time Pass</label>
    <mat-form-field [ngClass]="'form-input-field'" appearance="fill">
<!--        <mat-label>Please enter your one time password</mat-label>-->
        <input matInput formControlName="otp"
               placeholder="Please enter your one time password"
               [ngClass]="formControl.dirty ? !formControl.hasError('required') ? 'valid': 'error' : ''"
               id="otp" type="text"/>
      <mat-error *ngIf="formControl.invalid
        && formControl.hasError('required')">
          This field is required
        </mat-error>
    </mat-form-field>
    <div class="buttons">
      <wire-button [btnContent]="'Log In'" [btnStyle]="'btn-default-full'"></wire-button>
    </div>
</form>
