<div style="padding:36px;">
  <section style="margin:0 auto">
    <div class="mat-mdc-dialog-container" style="height:600px;width:928px;">
      <div class="mdc-dialog__container">
        <div class="mat-mdc-dialog-surface mdc-dialog__surface">
          <div class="form-dialog tile-form-dialog">
            <h1>Dialog Header
              <div>Show hints</div>
            </h1>
            <mat-dialog-content>
              <mat-tab-group mat-stretch-tabs="false">
                <mat-tab label="Friendly">
                  <form class="form-section column space-between">
                    <div class="row">

              <select-filter [label]="'Range'"
                             [modelValue]="'Select filter'"
                             [classes]="'fill'"
                             [options]="['Select filter']" class="select-search">
              </select-filter>

              <select-filter  [label]="'Range'"
                             [modelValue]="'Select filter'"
                             [classes]="'fill'"
                             [options]="['Select filter']" class="select-search">
              </select-filter>

                    </div>
                    <div class="row">
                      <select-search-component
                          [value]=""
                          [classes]="'fill bordered-select'"
                          [api_model]="'process_light'"
                          [label]="'Select search component'"
                          class="select-search">
                      </select-search-component>


                      <select-many-search-component
                          [value]=""
                          [classes]="'fill'"
                          [api_model]="'process_light'"
                          [label]="'Select many search component'"
                          class="select-search">
                      </select-many-search-component>

                    </div>

                    <div class="row">
                      <select-many-search-component
                          [value]=""
                          [classes]="'fill'"
                          [api_model]="'process_light'"
                          [label]="'Select many search component'"
                          class="select-search">
                      </select-many-search-component>

                      <select-many-series
                          [value]=""
                          [classes]="'fill'"
                          [label]="'Select many series'"
                          class="select-search select-many-search-wrapper">
                      </select-many-series>
                    </div>
                    <div class="row">
                      <select-search-component
                          [value]=""
                          [classes]="'fill bordered-select'"
                          [api_model]="'process_light'"
                          [label]="'Select search component'"
                          class="select-search">
                      </select-search-component>


                      <select-many-search-component
                          [value]=""
                          [classes]="'fill'"
                          [api_model]="'process_light'"
                          [label]="'Select many search component'"
                          class="select-search">
                      </select-many-search-component>

                      <select-many-series
                          [value]=""
                          [classes]="'fill'"
                          [label]="'Select many series'"
                          class="select-search select-many-search-wrapper">
                      </select-many-series>
                    </div>
                    <div class="row">

                      <select-search-component
                          [value]=""
                          [classes]="'fill bordered-select'"
                          [api_model]="'process_light'"
                          [label]="'Select search component'"
                          class="select-search">
                      </select-search-component>
                      <mat-form-field>
                        <mat-label>Input</mat-label>
                        <input matInput placeholder="Placeholder">
                      </mat-form-field>

                    </div>
                    <div class="row">

                      <select-search-component
                          [value]=""
                          [classes]="'fill bordered-select'"
                          [api_model]="'process_light'"
                          [label]="'Select search component'"
                          class="select-search">
                      </select-search-component>

                    </div>

                  </form>
                </mat-tab>
              </mat-tab-group>
            </mat-dialog-content>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section>
    <h2>Form Fields</h2>
    <h3>Input Fields</h3>
    <div>Use the "no-label" class on the mat-form-field element to remove padding where the label normally sits -
      works with or without an empty mat-label element.
    </div>
    <div class="row" style="justify-content: space-evenly;margin-bottom:20px;border:1px dashed #ccc;">
      <mat-form-field>
        <mat-label>Input</mat-label>
        <input matInput placeholder="Placeholder">
      </mat-form-field>

      <mat-form-field>
        <mat-label></mat-label>
        <input matInput placeholder="Placeholder" [value]="'empty mat-label, no class'">
      </mat-form-field>

      <mat-form-field class="no-label">
        <mat-label></mat-label>
        <input matInput placeholder="Placeholder" [value]="'empty mat-label, no-label class'">
      </mat-form-field>

      <mat-form-field class="no-label">
        <input matInput placeholder="Placeholder" [value]="'no-label class'">
      </mat-form-field>

    </div>

    <mat-divider></mat-divider>
    <h3>Select Fields</h3>
    <div>mat-select elements that appear outside .form-dialog need to have the "bordered-select" class applied to
      create the wire select look
    </div>
    <div class="row" style="justify-content: space-evenly;margin-bottom:20px;border:1px dashed #ccc;">
      <mat-form-field class="bordered-select">
        <mat-label>Select</mat-label>
        <mat-select placeholder="Please select">
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="bordered-select">
        <mat-label></mat-label>
        <mat-select placeholder="Please select">
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="bordered-select no-label">
        <mat-label></mat-label>
        <mat-select placeholder="Please select" [value]="'option1'">
          <mat-option value="option1">no-label class</mat-option>
          <mat-option value="option2">Option 2</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="no-label">
        <mat-select placeholder="Please select">
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>
    <h3>Select Search Api Fields</h3>
    <div>select-search-api elements that appear outside .form-dialog need to have the "bordered-select" class added to
      their class list ([classes]="'fill bordered-select'".
      This is not necessary (or available) for select-many-search-component which is actually a mat-menu-trigger button.
    </div>
    <div class="row" style="justify-content: space-evenly;margin-bottom:20px;border:1px dashed #ccc;">
      <select-search-component
          [value]=""
          [classes]="'fill bordered-select'"
          [api_model]="'process_light'"
          [label]="'Select a process'">
      </select-search-component>

      <select-search-component
          [value]=""
          [classes]="'fill bordered-select'"
          [api_model]="'process_light'">
      </select-search-component>

      <select-many-search-component
          [value]=""
          [classes]="'fill'"
          [api_model]="'process_light'"
          [label]="'Select a process'">
      </select-many-search-component>

      <select-many-search-component
          [value]=""
          [classes]="'fill'"
          [api_model]="'process_light'">
      </select-many-search-component>
    </div>

    <mat-divider></mat-divider>
    <h3>Select Search Fields</h3>
    <div>select-search elements that appear outside .form-dialog need to have the "bordered-select" class added to their
      class list ([classes]="'fill bordered-select'".

    </div>
    <div class="row" style="justify-content: space-evenly;margin-bottom:20px;border:1px dashed #ccc;">
      <select-search
          [value]=""
          [classes]="'fill bordered-select'"
          [data]=""
          [label]="'Select a process'">
      </select-search>

      <select-search
          [value]=""
          [classes]="'fill bordered-select'">
      </select-search>

      <select-many-search
          [value]=""
          [classes]="'fill bordered-select'"
          [label]="'Select a process'">
      </select-many-search>

      <select-many-search
          [value]=""
          [classes]="'fill bordered-select'">
      </select-many-search>
    </div>

    <mat-form-field>
      <mat-label>Textarea</mat-label>
      <textarea matInput></textarea>
    </mat-form-field>
  </section>


  <section>
    <div class="example-label">Basic</div>
    <div class="example-button-row">
      <button mat-button>Basic</button>
      <button mat-button color="primary">Primary</button>
      <button mat-button color="accent">Accent</button>
      <button mat-button color="warn">Warn</button>
      <button mat-button disabled>Disabled</button>
      <a mat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Raised</div>
    <div class="example-button-row">
      <button mat-raised-button>Basic</button>
      <button mat-raised-button color="primary">Primary</button>
      <button mat-raised-button color="accent">Accent</button>
      <button mat-raised-button color="warn">Warn</button>
      <button mat-raised-button disabled>Disabled</button>
      <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Stroked</div>
    <div class="example-button-row">
      <button mat-stroked-button>Basic</button>
      <button mat-stroked-button color="primary">Primary</button>
      <button mat-stroked-button color="accent">Accent</button>
      <button mat-stroked-button color="warn">Warn</button>
      <button mat-stroked-button disabled>Disabled</button>
      <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Flat</div>
    <div class="example-button-row">
      <button mat-flat-button>Basic</button>
      <button mat-flat-button color="primary">Primary</button>
      <button mat-flat-button color="accent">Accent</button>
      <button mat-flat-button color="warn">Warn</button>
      <button mat-flat-button disabled>Disabled</button>
      <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Class form-element-button</div>
    <div class="example-button-row">
      <button mat-button color="primary" class="form-element-button">Basic</button>
      <button mat-raised-button color="primary" class="form-element-button">Raised</button>
      <button mat-stroked-button color="primary" class="form-element-button">Stroked</button>
      <button mat-flat-button color="primary" class="form-element-button">Flat</button>
      <button mat-raised-button disabled class="form-element-button">Disabled</button>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Icon</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon">
            <mat-icon>bookmark</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="warn" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab disabled aria-label="Example icon button with a heart icon">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Mini FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>plus_one</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>

  <div class="container">
    <h1>Theme Showcase</h1>
    <section>
      <div class="row" style="justify-content: space-evenly;width:1400px;">
        <div style="padding:0 20px;">
          <h2>Constant Colours Palette</h2>
          <div *ngFor="let colour of variablesPalette"
               style="width:300px;height:40px;display:flex;align-items: center;justify-content: space-between;margin-bottom:5px;">
            <div>{{colour.name}}, {{colour.colour}}</div>
            <div style="height:100%;width:100px;" [ngStyle]="{'background-color':colour.colour}"></div>
          </div>
        </div>
        <div>
          <h2>Primary Palette</h2>
          <div *ngFor="let color of primaryPalette.slice().reverse()" class="color-box"
               [ngStyle]="{'background-color': color.value, 'color': color.contrast}">
            <span>{{color.label}} {{color.value}}</span>
          </div>
        </div>
        <div>
          <h2>Light Palette</h2>
          <div *ngFor="let color of lightPalette.slice().reverse()" class="color-box"
               [ngStyle]="{'background-color': color.value, 'color': color.contrast}">
            <span>{{color.label}}</span>
          </div>
        </div>
        <div>
          <h2>Accent Palette</h2>
          <div *ngFor="let color of accentPalette.slice().reverse()" class="color-box"
               [ngStyle]="{'background-color': color.value, 'color': color.contrast}">
            <span>{{color.label}}</span>
          </div>
        </div>
        <div>
          <h2>Warn Palette</h2>
          <div *ngFor="let color of warnPalette.slice().reverse()" class="color-box"
               [ngStyle]="{'background-color': color.value, 'color': color.contrast}">
            <span>{{color.label}}</span>
          </div>
        </div>
      </div>
    </section>

    <div class="row">
      <section style="flex-grow:1;">
        <h2>Typography</h2>
        <h1>Heading 1 HEADING 1</h1>
        <h2>Heading 2 HEADING 2</h2>
        <h3>Heading 3 HEADING 3</h3>
        <h4>Heading 4 HEADING 4</h4>
        <h5>Heading 5 HEADING 5</h5>
        <h6>Heading 6 HEADING 6</h6>
        <p>This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </section>


    </div>

    <section>
      <h2>Other Components</h2>
      <mat-checkbox>Checkbox</mat-checkbox>
      <mat-radio-group>
        <mat-radio-button value="1">Radio 1</mat-radio-button>
        <mat-radio-button value="2">Radio 2</mat-radio-button>
      </mat-radio-group>
      <mat-slide-toggle>Slide Toggle</mat-slide-toggle>
    </section>
  </div>
</div>
<section>

<!--  <h2>Variables.less Colours</h2>-->
<!--  <div class="row" style="margin-bottom:20px;">-->
<!--    <div style="font-weight:bold;background: #3a3a3c; margin-left:20px;width:160px;height:40px;color:#f5f5f5;">-->
<!--      @off-white:-->
<!--      #f5f5f5-->
<!--    </div>-->
<!--    <div style="font-weight:bold;background: #3a3a3c;margin-left:20px;width:160px;height:40px;color:#dbd3d2;">-->
<!--      @contrast-colour: #dbd3d2-->
<!--    </div>-->
<!--    <div style="font-weight:bold;background: #3a3a3c;margin-left:20px;width:160px;height:40px;color:#e6e4e1;">-->
<!--      @form-grey:-->
<!--      #e6e4e1-->
<!--    </div>-->
<!--  </div>-->


</section>
