import {Directive, Injectable, OnDestroy} from "@angular/core";
import {ApiService} from "./api/api.service";
import {AppScope} from "./app_scope.service";
import {NotificationService} from "./notification.service";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {SessionState} from '../_models/session-state';
import {first, map, shareReplay, switchMap, takeUntil, tap} from "rxjs/operators";
import {SearchQueryOptions} from "./api/search-query-options";
import {EventType} from '../_models/event-type';
import {EventDataService} from '../data/event-data.service';
import {Comment as WireComment} from '../_models/comment';
import {ConfigColumn} from "../_typing/config/config-column";
import {EventBaseType} from "../_models/event";
import {Component} from "../_models/component";
import {Series} from "../_models/series";
import {isEmptyObject} from "jquery";
import {ConfigStub} from "../_typing/config-stub";

@Directive()
@Injectable()
export class EventService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    event_type_list: any[] = [];
    base_types = ['event', 'comment', 'downtime', 'correction_factor'];
    //NOTE: attribute type for series, component and session_state refers to the fact that it is a flat column ie.the
    //column is 'Series' not an actual series object as with Cps, Cts, Ets
    comment_columns: ConfigColumn[] = [
        {id: 'comment', type: 'attribute', title: 'Comment'},
        {id: 'start', type: 'attribute', title: 'Start'},
        {id: 'duration', type: 'attribute', title: 'Duration'},
        {id: 'end', type: 'attribute', title: 'End'},
        {id: 'series', type: 'attribute', title: 'Series'},
        {id: 'component', type: 'attribute', title: 'Component'},
        {id: 'session_state', type: 'attribute', title: 'Page'},
        {id: 'created_by', type: 'attribute', title: 'Created by'},
        {id: 'changed_by', type: 'attribute', title: 'Changed by'},
        {id: 'created_on', type: 'attribute', title: 'Created on'},
        {id: 'changed_on', type: 'attribute', title: 'Changed on'}
    ];

    private base_types$: Observable<{ eventType: EventType, commentType: EventType }>;
    eventType: any;
    commentType: any;
    series_list: any[] = [];
    events: any[];
    show_comments: BehaviorSubject<boolean>;

    eventAdded = new Subject();
    toggleComments = new Subject();

    constructor(private api: ApiService,
                private notification: NotificationService,
                private appScope: AppScope,
                private eventDataService: EventDataService) {
        this.show_comments = new BehaviorSubject<boolean>(false);
    }

    get base_type_events() {
        if (!this.base_types$) {
            this.base_types$ = this.requestBaseTypes().pipe(
                shareReplay(1)
            );
        }
        return this.base_types$;
    }

    private requestBaseTypes() {
        const options = new SearchQueryOptions();
        options.filters = [{
            and: [{name: 'base_type', op: 'in', val: ['event', 'comment']},
                {name: 'name', op: 'in', val: ['Event', 'event', 'Comment', 'comment']}]
        }];
        return this.api.event_type_light.searchMany(options).pipe(
            takeUntil(this.onDestroy),
            first(),
            map(response => {
                const event_types = response.data;
                let ret = {eventType: null, commentType: null};
                event_types.map(type => {
                    if (type.attributes.base_type === "event") {
                        this.event_type_list.push(type);
                        if (type.attributes.name === "Event") {
                            this.eventType = type;
                            ret.eventType = type;
                        }
                    }
                    if (type.attributes.base_type === "comment") {
                        if (type.attributes.name === "Comment" || type.attributes.name === "comment") {
                            this.commentType = type;
                            ret.commentType = type;
                        }
                    }
                });
                return ret;
            }));
    }

    public getShowComments(): Observable<boolean> {
        return this.show_comments.asObservable();
    }

    public setShowComments(newValue: boolean): void {
        this.show_comments.next(newValue);
    }

    getEvents(start?: Date, end?: Date, series_list?, components?, session_state_list?: SessionState[]): Observable<any> {
        return this.eventDataService.getEventsByBaseType('event', start, end, series_list, components, session_state_list);
    }

    getEventsByBaseType(base_type: EventBaseType, start: Date, end: Date, series_list?, components?, session_state_list?: SessionState[]): Observable<any> {
        return this.eventDataService.getEventsByBaseType(base_type, start, end, series_list, components, session_state_list);
    }

    getEventsByBaseTypeQuery(base_type: EventBaseType, options: SearchQueryOptions) {
        options.filters.push()
        return this.eventDataService.getEventsByBaseTypeQuery(base_type, options);
    }

    getEventsFilter(start: Date, end: Date, seriesList?, components?, sessionStateList?: ConfigStub<SessionState>[], matchRange: boolean = false, filterByCreatedOn: boolean = false) {
        let dateFilters: any;
        if (filterByCreatedOn) {
            dateFilters = this.eventDataService.generateCreatedOnDateFilter(start, end);
        } else if (matchRange) {
            dateFilters = this.eventDataService.generateWithinRangeEventDateFilter(start, end);
        }
        return this.eventDataService.generateEventsFilter(start, end, seriesList, components, sessionStateList, dateFilters);
    }

    getSessionComments(start: Date, end: Date, session: SessionState): Observable<any> {
        return this.eventDataService.getCommentsBySessionId(start, end, session);
    }

    addInlineComment(comment, start, end, series_list?:Series[], component_list?:Component[]): Observable<any> {
        if (!(series_list && series_list?.[0]) && !(component_list && component_list?.[0])) {
            this.notification.openWarning("Please select a series or component for this event.");
            return of(null);
        }
        if (series_list && !Array.isArray(series_list)) {
            series_list = [series_list];
        }
        if (!start) {
            this.notification.openWarning("Please select a time period for this event.");
            return of(null);
        } else if (!end) {
            this.notification.openWarning("Please select a time period for this event.");
            return of(null);
        } else if (!comment) {
            this.notification.openWarning("Please add a comment.");
            return of(null);
        } else {
            return this.base_type_events.pipe(
                switchMap(result => {
                    let com = new WireComment()
                    com.attributes.start = start;
                    com.attributes.end = end;
                    com.attributes.comment = comment;
                    com.relationships.event_type.data = result.commentType;

                    com.relationships.series_list.data = series_list?.map((series) => {
                        return {'id': series.id, type: 'series'};
                    }) || [];
                    com.relationships.components.data = component_list?.map((component) => {
                        return {'id': component.id, type: component.type};
                    }) || [];
                    return this.eventDataService.addEvent(com).pipe(
                        tap((item) => {
                            this.notification.openSuccess("Comment saved.", 3000);
                            this.eventAdded.next(item['data']);
                        }));
                })
            );
        }
    }


    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
