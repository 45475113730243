import {AuditMixin, AuditMixinAttributes, AuditMixinRelationships} from "./audit-mixin";
import {DEFAULT, LIST_RELATIONSHIP_TYPE, SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export type EventBaseType = 'event' | 'comment' | 'downtime' | 'correction_factor';

export class EventAttributes extends AuditMixinAttributes {
    start?: string | Date;
    end?: string | Date;
    base_type?: string;
    duration?: string;
    duration_string?: string;
    comment?: string;
    custom_constants?: { [key: string]: any } = {};
    component_names?: string[];
    event_type_name?: string;
    properties_data_map?: any;
    start_locked?: boolean;
    end_locked?: boolean;
}

export class IEventAttributes extends AuditMixinAttributes {
    start: TStartEnd;
    end: TStartEnd;
    base_type: string;
    duration: string;
    duration_string: string;
    comment: string;
    custom_constants: { [key: string]: any };
    component_names: string[];
    event_type_name: string;
    properties_data_map: any;
    start_locked: boolean;
    end_locked: boolean;
}

export class EventRelationships extends AuditMixinRelationships {
    series_list: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    components?: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    event_type?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    event_constants?: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    event_components?: LIST_RELATIONSHIP_TYPE = DEFAULT.LIST;
    input_ore_body?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
    output_ore_body?: SINGLE_RELATIONSHIP_TYPE = DEFAULT.SINGLE;
}

export type TStartEnd = string | Date;


export class Event extends AuditMixin {
    attributes: EventAttributes;
    id: string;
    type: string = 'event';
    relationships: EventRelationships;

    constructor() {
        super();
        this.attributes = new EventAttributes();
        this.relationships = new EventRelationships();
    }
}

export class WireEvent extends Event {
}
