/**
 * This type exists to we can easily find usages of list of ids throughout the codebase.
 */
export type IDList = string[];

/**
 * This type exists to we can easily find usages of model ids (e.g. process.id) throughout the codebase.
 */
export type ModelID = string;

/**
 * This type exists to we can easily find usages of model names (e.g. process.attributes.name) throughout the codebase.
 */
export type ModelName = string;

/**
 * String representing the name of an attributes on a model.
 */
export type ModelAttribute = string;

/**
 * String representing the name of a specific relationship on a model.
 */
export type RelationshipName = string;

/**
 * This type should be used for maps to where the key does not represent an model ID.
 *
 * Ie. This is the generic version of IDMap but for arrays.
 */
export interface KeyMapArray<T> {
    [id_key: string]: T[]
}

/**
 * This type should be used for maps to where the key does not represent an model ID.
 *
 * Ie. This is the generic version of IDMap.
 */
export interface KeyMap<T> {
    [id_key: string]: T;
}

/**When we have a dictionary of dictionaries, e.g.generic constants, column formats etc.**/
export interface KeyMapMap<T> {
    [id_key: string]: { [id_key: string]: T };
}

/**
 * This type exists to we can easily find usages of map ids to db models throughout the codebase.
 * */
export interface IDMap<T> {
    [id_key: string]: T;
}

export interface IDObject {
    id: ModelID;
}

/**This represents the relationship stub of any WIRE model**/
export interface Stub<T> {
    id: string;
    type: string;
}

/**This represents the base type of any WIRE model**/
export interface BaseModel<T> {
    id: string;
    attributes: any;
    relationships: any;
    type: string;
}

/**This represents the base type of any WIRE model**/
export interface BaseModelLight<T> {
    id: string;
    attributes?: any;
    relationships?: any;
    type: string;
}

export function isBaseModel<T>(object): object is BaseModel<T> {
    return ['attributes', 'relationships', 'id', 'type'].every(k => Object.keys(object as BaseModel<T>).includes(k));
}

export function isBaseModelLight<T>(object): object is BaseModelLight<T> {
    return ['id', 'type'].every(k => Object.keys(object as BaseModelLight<T>).includes(k)) &&
        ['attributes', 'relationships'].some(k => Object.keys(object as BaseModelLight<T>).includes(k));
}

export interface ModelWithLimitsAttributes {
    hi: number;
    hihi: number;
    low: number;
    lowlow: number;
}

export interface ModelWithLimits<T> extends BaseModelLight<T> {
    attributes: Partial<ModelWithLimitsAttributes>;
}

export interface IRESTFilterItemBase {
    name: string;
    op: string;
    val: any;
}

interface IAndFilterItem {
    and: IRESTFilterItemBase[];
}

interface IOrFilterItem {
    or: IRESTFilterItemBase[];
}

interface INotFilterItem {
    not: IRESTFilterItemBase;
}

interface IRESTFilterItem extends IRESTFilterItemBase, IAndFilterItem, IOrFilterItem, INotFilterItem {
}

export type RESTFilter = Partial<IRESTFilterItem>[];
