import {SINGLE_RELATIONSHIP_TYPE} from "./shared-types";

export class RawDataAttributes {
    time_stamp: string;
    value: number;
}

export class RawDataRelationships {
  series: SINGLE_RELATIONSHIP_TYPE;
}

export class RawData {
    attributes: RawDataAttributes;
    relationships: RawDataRelationships;
    type: 'raw_data';
    id: string;

    constructor(seriesId?: string, timeStamp?: string, value?: number) {
        this.relationships.series.data.id = seriesId;
        this.attributes.time_stamp = timeStamp;
        this.attributes.value = value;
    }
}
