import {Injectable, OnDestroy} from "@angular/core";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {Observable, Subject} from 'rxjs';
import {ComponentType} from "../../_models/component-type";
import {ListResponse, SingleResponse} from "../api/response-types";
import {ModelID} from "../../_typing/generic-types";

@Injectable({
    providedIn: 'root'
})
export class ComponentTypeService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private componentTypeData: ComponentTypeDataService) {
    }

    getComponentTypes(ids: ModelID[]): Observable<ListResponse<ComponentType>> {
        return this.componentTypeData.getComponentTypes(ids);
    }

    getComponentType(id: ModelID): Observable<SingleResponse<ComponentType>> {
        return this.componentTypeData.getComponentType(id);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
