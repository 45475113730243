import * as Handsontable from "handsontable";
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api/api.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HotInstance} from "../../services/hot-instance";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {forkJoin, Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
    selector: 'collector-mapper-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class CollectorMapperSheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    title: string;
    search: string;
    schema: any;
    column_list: any;

    collectors: any[];
    mappers: any[];
    mapper_aggregation_methods = ['total', 'mean', 'latest_value', 'difference', 'mean_excluding_zeros', 'mean_excluding_negatives', 'mean_greater_than_1', 'time_weighted_average'];
    series_light: any[];
    users: any[];

    constructor(private headerData: HeaderDataService,
                private api: ApiService,
                private handsontableGenerator: HandsontableGenerator) {
        this.hot = new HotInstance(["attributes.source_name"]);
    }

    ngOnInit(): void {
        this.title = 'Collector Mappers';

        const observables: Observable<any>[] = [];
        observables.push(this.api.collector.searchMany().pipe(tap(response => this.collectors = response.data)));
        observables.push(this.api.mapper.searchMany(new SearchQueryOptions(0)).pipe(tap(response => this.mappers = response.data)));
        observables.push(this.api.series_light.searchMany(new SearchQueryOptions(0)).pipe(tap(response => this.series_light = response.data)));
        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));

        forkJoin(observables).subscribe(this.genTable.bind(this));

        this.buildHeader();
    }

    formatJson() {
        if (this.mappers) {
            this.mappers.forEach(mapper => {
                if (mapper.attributes.json) {
                    try {
                        mapper.attributes.json = JSON.parse(mapper.attributes.json);
                    } catch (e) {
                    }
                    mapper.attributes.json = JSON.stringify(mapper.attributes.json);
                }
            });
        }
    }

    genTable() {
        this.formatJson();
        let seriesLookups = this.handsontableGenerator.gen_lookups(this.series_light);
        let collectorLookups = this.handsontableGenerator.gen_lookups(this.collectors);

        this.schema = {
            id: null,
            type: 'mapper',
            attributes: {
                source_name: null,
                pre_process: null,
                send_alerts: null,
                save_opsdata: null,
                create_events: null,
                latest_date: null,
                invert_signal: null,
                priority: null,
                interpolated: null,
                aggregation_method: null,
                high_frequency_collection: null,
                json: null
            }, relationships: {
                series: {data: {type: 'series', id: null}},
                collector: {data: {type: 'collector', id: null}}
            }
        };

        this.column_list = [{
            data: 'attributes.source_name',
            title: 'Name'
        }, {
            data: this.handsontableGenerator.genLookupDataSource(seriesLookups, 'series'),
            title: 'Series',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: true
        }, {
            data: this.handsontableGenerator.genLookupDataSource(collectorLookups, 'collector'),
            title: 'Collector',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: collectorLookups.source,
            allowInvalid: true
        }, {
            data: 'attributes.pre_process',
            title: 'PreProcess'
        }, {
            data: 'attributes.latest_date',
            title: 'Latest Date',
            readOnly: true,
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.invert_signal',
            title: 'Invert Signal',
            type: 'checkbox'
        }, {
            data: 'attributes.save_opsdata',
            title: 'Save Operational Data',
            type: 'checkbox'
        }, {
            data: 'attributes.create_events',
            title: 'Create Events',
            type: 'checkbox'
        }, {
            data: 'attributes.send_alerts',
            title: 'Invert Signal',
            type: 'checkbox'
        }, {
            data: 'attributes.priority',
            title: 'Priority'
        }, {
            data: 'attributes.interpolated',
            title: 'Interpolate ',
            type: 'checkbox'
        }, {
            data: 'attributes.aggregation_method',
            title: 'Historian aggregation method ',
            type: 'dropdown',
            source: this.mapper_aggregation_methods,
        }, {
            data: 'attributes.high_frequency_collection',
            title: 'High Frequency Collection',
            type: 'checkbox'
        }, {
            data: 'attributes.json',
            title: 'JSON',
            type: 'text',
            wordWrap: true,
            width: 300
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        this.hot = this.handsontableGenerator.generateTable(this.api.mapper, this.schema, this.column_list, this.hot);
        this.hot.ready = true;
        this.hot.settings.data = this.mappers;
        this.hot.settings.rowHeaders = false;
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    save() {
        let results = this.hot.save();
        this.mappers = results.data;
    }

    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = 'Mappers';
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}}
        ];
    }
}
