import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {SeriesDataService} from "../../services/series_data.service";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {RuleSet} from 'ngx-angular-query-builder';
import {ColumnFormatsConfig, ColumnFormatsConfigDict} from '../table-column-menu/table-column-menu.component';
import {ConstantProperty} from "../../_models/constant-property";
import {TileDataService} from "../../services/tile_data.service";
import {ConditionalFormattingConfig} from "../../tables/conditional-formatting.service";
import {ComponentType} from "../../_models/component-type";
import {ConfigStub, ConfigStubType} from "../../_typing/config-stub";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {ComponentTypeDataService} from "../../data/component-type-data.service";
import {CustomEventsFormService} from "./custom-events-form.service";
import {EventType} from '../../_models/event-type';
import {IDMap, KeyMap} from '../../_typing/generic-types';
import {deepCopy} from "../../lib/utils";
import {LockTemplate} from '../../_models/lock-template';
import {getRelationWithAttributeFilter, getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {FILE_DOWNLOAD_OPTIONS, FileDownloadOption} from "../../_typing/download-filetype";
import {WireRuleSet} from "../../_typing/query-builder";

export function isAttributeColumn(object): object is string {
    return typeof object === 'string';
}

export type CustomEventsConfig = Partial<CUSTOM_EVENTS_CONFIG>;
export type CUSTOM_EVENTS_CONFIG = {
    columns: EventConfigColumn[];
    // constant_properties: ConfigStub<ConstantProperty>[];
    // component_types: ConfigStub<ComponentType>[];
    hidden_columns?: string[];
    required_columns?: string[];
    event_type_ids?: string[],
    event_type_names?: string[],
    event_types?: ConfigStub<EventType>[];
    non_editable_columns?: string[],
    column_widths?: string[]
    column_names?: { [key: string]: string };
    query?: { [key: string]: RuleSet | null };
    column_formats?: ColumnFormatsConfigDict;
    bypass_limits_formatting?: boolean;
    constant_property_time?: boolean;
    start_prop?: string;
    end_prop?: string;
    allow_delete?: boolean;
    show_calc_update_status?: boolean;
    include_cp_nulls?: boolean;
    constrain_width?: boolean;
    download_file_type?: FileDownloadOption;
    page_size?: number
    conditional_formats?: ConditionalFormattingConfig[];
    lock_template?: ConfigStub<LockTemplate>
    validation?: IDMap<WireRuleSet | null>;
};

@Component({
    selector: 'custom-events-form',
    templateUrl: './custom-events-form.component.html',
    styleUrls: ['../series-table-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomEventsFormService],
    standalone: false
})
export class CustomEventsFormComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() content_type: string;
    @Input() config: CUSTOM_EVENTS_CONFIG;

    available_column_options: EventConfigColumn[];
    required_fields: string[] = ['Start', 'Type']; //Fields required by wire (not necessarily by user)
    non_editable_fields = ['Duration', 'Changed On', 'Changed By'];

    col_menu_options: string[] = ['bold', 'italic', 'size', 'resize', 'fit_content', 'colour', 'decimals', 'align'];
    default_format_values: ColumnFormatsConfig = {resize: true};

    column_formats?: ColumnFormatsConfig;
    selected_types: ConfigStubType<EventType>[] = [];
    col_info_dict: any = {};
    date_properties: ConstantProperty[];
    event_file_download_options: any[];
    dropped = false;

    availableColumnFilterValue: string = '';
    selectedColumnFilterValue: string = '';
    index_dict: KeyMap<number> = {};
    lock_template_filter: any[] = [];

    disabled_columns: any[];
    required_columns: any[];
    col_id_name_map: { [key: string]: string };
    formats_dict: ColumnFormatsConfigDict = {};

    constructor(protected seriesData: SeriesDataService,
                private tileData: TileDataService,
                private cps: CustomEventsFormService) {
    }

    ngOnInit() {
        this.cps.$column_options.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(options => {
            this.available_column_options = options.available_column_options;
            this.required_fields = options.required_columns;
            this.col_info_dict = options.col_info_dict;
            this.date_properties = options.date_properties;
            this.col_id_name_map = options.col_id_name_map;
            this.columnsChanged();
            this.updateLockTemplateFilter();
        });

        if (this.config.event_types) {
            this.selected_types = this.config.event_types;
        }

        if (this.config.event_type_names && !this.config.event_types) {
            this.cps.getEventTypes(this.config.event_type_names).subscribe(result => {
                this.selected_types = result.data;
                this.config.event_types = ConfigStub.getConfigStubs<EventType>(this.selected_types);
                this.config = this.cps.initialiseConfig(this.config);
            })
        } else {
            this.config = this.cps.initialiseConfig(this.config);
        }
        this.event_file_download_options = FILE_DOWNLOAD_OPTIONS;
    }

    updateSelected(event, single_selected?) {
        const ctrl = this;
        ctrl.selected_types = event;
        if (single_selected) {
            ctrl.selected_types = [single_selected];
            if (!this.non_editable_fields.includes('Type')) {
                this.non_editable_fields.push('Type');
            } else {
                this.non_editable_fields.splice(this.non_editable_fields.indexOf('Type'));
            }
        }
        ctrl.config.event_types = ConfigStub.getConfigStubs<EventType>(ctrl.selected_types);
        ctrl.cps.getColumnsForEventType(this.config);
    }

    resetConstantPropertyTime(event) {
        if (event === false) {
            this.config.start_prop = null;
            this.config.end_prop = null;
            this.config.include_cp_nulls = false;
        }
    }

    lockTemplateChanged(event) {
        this.config.lock_template = new ConfigStub<LockTemplate>(event.value);
    }

    private updateLockTemplateFilter(): void {
        const ids = this.config.event_types?.map(e => e.id);
        if (!ids) {
            this.lock_template_filter = [getRelationWithAttributeFilter('event_type', null, 'id', 'ne')];
            return;
        }
        this.lock_template_filter = [getRelationWithManyIdsFilter('event_type', ids)];
    }

    drop(event: CdkDragDrop<string[]>) {
        this.dropped = true;
        this.config = this.cps.columnDropped(event, this.config, deepCopy(this.index_dict));
        if (event.previousContainer !== event.container) {
            if (event.container.id === 'selectedList') {
                (this.config.non_editable_columns || []).push(event.container.data[event.currentIndex]);
                setTimeout(() => {
                    this.dropped = false;
                }, 2000);
            } else {
                this.dropped = false;
            }
        }
    }

    columnsChanged() {
        this.required_columns = this.config.columns.filter(c => c.required);
        this.disabled_columns = this.config.columns.filter(c => c.disabled);
        this.config.columns.forEach(col => {
            this.formats_dict[col.id] = col.format || null;
        })
    }

    updateRequired(event) {
        this.required_columns = event;
        this.config.columns = this.cps.updateRequired(this.required_columns, this.config.columns);
    }

    updateDisabled(event) {
        this.disabled_columns = event;
        this.config.columns = this.cps.updateDisabled(this.disabled_columns, this.config.columns);
    }

    columnFilter(item, value, index): boolean {
        // store item index for use in determine actual position in the items list
        this.index_dict[item.id] = index;
        return this.col_id_name_map[item.id]?.toLowerCase().includes(value.toLowerCase());
    }

    columnNameFunction(column) {
        if (!column) return column;
        return `${column?.title || this.col_id_name_map?.[column.id]} ${column.required === true ? '*' : ''}`;
    }

    disableRequiredFunction(column): boolean {
        if (!column) return false;
        return (this.col_id_name_map?.[column.id] === 'Type' && (this.selected_types && this.selected_types.length > 1));
    }

    disableItemFunction(column): boolean {
        if (!column) return false;
        return this.non_editable_fields?.includes(column.id) || (this.col_id_name_map?.[column.id] === 'Type' && (this.selected_types && this.selected_types.length === 1));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
