import {Component, OnDestroy, OnInit,} from '@angular/core';
import {Subject} from "rxjs";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {TreeNode} from "../../components/value-driver-tree/types/tree.node";
import {SeriesLight} from "../../_models/series";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ListResponse} from '../../services/api/response-types';
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'value-driver-tree-view',
    templateUrl: './value-driver-tree-view.component.html',
    standalone: false
})
export class ValueDriverTreeViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    selected_calc: any;
    series_full: any[] = [];
    treeNodeMap: { [key: string]: TreeNode } = {};
    vdt_data_ready: boolean = false;
    formula_view: boolean = false;
    updateCalculationsSubject: Subject<void> = new Subject();

    constructor(private headerData: HeaderDataService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private seriesData: SeriesDataService,
                private notification: NotificationService) {
    }

    ngOnInit() {
        const ctrl = this;

        ctrl.headerData.show_dtp = true;
        this.headerData.title = 'Value Driver Trees';
        const queryParams = this.activatedRoute.snapshot.queryParams;
        if (queryParams['calc_list']) {
            this.seriesData.getSeriesLightListById([queryParams['calc_list']])
                .subscribe(((series: ListResponse<SeriesLight>) => {
                    if (series?.data) {
                        this.selected_calc = series.data[0];
                        this.vdt_data_ready = true;
                    } else {
                        this.notification.openError("Unable to find series", 10000);
                    }
                }));
        } else {
            this.vdt_data_ready = true;
        }
    }

    updateURL() {
        const ctrl = this;
        const queryParams: Params = {
            calc_list: ctrl.selected_calc.id
        };

        // This updates the url without navigation (by using current activatedRoute)
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // prevents replacing other params (dtp)
                //replaceUrl: true //can be used to replace this route event in browser history rather than adding to history
            });
    }

    updateCalculations() {
        this.updateCalculationsSubject.next();
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

}
