import {Component, OnInit} from '@angular/core';
import {CheckOnlineStateBridgeService} from "../../services/check-online-state-bridge.service";

@Component({
    selector: 'offline-dialog',
    templateUrl: './offline-dialog.component.html',
    styleUrls: ['./offline-dialog.component.less'],
    standalone: false
})
export class OfflineDialogComponent implements OnInit {

    constructor(private checkOnlineStateBridgeService: CheckOnlineStateBridgeService) {
    }

    ngOnInit(): void {
    }

    hideOfflineMessages() {
        this.checkOnlineStateBridgeService.$disable.next();
    }
}
