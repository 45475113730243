<div class="account-select dark">
  <mat-select #select_handle (selectionChange)="changeAccount($event.value)"
              [formControl]="selected_account_id" [placeholder]="placeholder"
              [disableOptionCentering]="true"
              [panelClass]="'tree-node-menu dark list-below-select-panel full-height-select-panel'"
              class="account-picker-select">
    @for (account of accounts$ | async; track account) {
      <mat-option
          [disabled]="account.view_only"
          [matTooltipDisabled]="!account.view_only"
          [style.color]="'lightgray' ? account.view_only : ''"
          [value]="account.id"
          class="with-active-indicator"
          matTooltip="You don't have access to this account">
      <span class="account-picker-row">
        <div>{{account.attributes.name}}</div>
      </span>
      </mat-option>
    }
  </mat-select>
</div>
