<div id="solver-template" class="row">
  <select-search-component class="select-search"
                           (selectionChange)="onSelectSolverTemplate($event)"
                           [label]="'Select a Solver Template'"
                           [api_model]="'solver_template'"
                           [value]="solverTemplate"
                           [classes]="'fill'">
  </select-search-component>
</div>
<div id="adjusted-type" class="row" *ngIf="solverTemplate">
  <select-search-component class="select-search"
                           (selectionChange)="adjustedSeriesType=$event.value"
                           [label]="'Select an Adjusted Series Type'"
                           [api_model]="'series_type'"
                           [custom_filters]="adjustedTypesFilter"
                           [value]="adjustedSeriesType"
                           [classes]="'fill'">
  </select-search-component>
</div>

<div class="row" style="justify-content: space-between;">
  <button mat-button class="bordered-button solver-button"
          *ngIf="solverTemplate && adjustedSeriesType && !displayData"
          (click)="runSolver()"
          [disabled]="!(solverTemplate && adjustedSeriesType)">Run Solver
  </button>
  <!-- Leaving a duplicate button here in case it becomes a UAT to re-run the solver with different data e.g. with different errors -->
  <button mat-button class="bordered-button solver-button"
          *ngIf="displayData" (click)="runSolver()">Re-run Solver
  </button>

  <button mat-button class="bordered-button green solver-button" *ngIf="displayData" (click)="saveSolvedData()">Save
    Results
  </button>
</div>

<div class="stage-item stage" *ngIf="running">
  <div class="dot-flashing"></div>
</div>

<solver-data-table class="solver-data-table" *ngIf="displayData" [data]="displayData"></solver-data-table>
