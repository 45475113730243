import * as Handsontable from "handsontable";
import * as utils from "../lib/utils";
import {Model} from "./api/model";
import {PaginationDataSource} from './api/pagination-data-source';

export class HotInstance {
    instance: Handsontable;
    window_height = window.innerHeight - 240;
    resource: Model;
    change_ids: string[];
    handson_id: string;
    save: () => { deferreds: Promise<any>[]; data: any; savePromises: Promise<any>[]; errors: any; };
    query_handler: (pageNumber: number, pageSize?: number, filters?: object) => Promise<any[]>;
    settings: any;
    ready: Boolean;
    never_searched: Boolean;
    original_data: any[];
    source_data: any[];
    column_list: any[];
    account: any; //This is not the site Active Account, this is the "filter table by" account

    last_visible_row: number = -1;
    page_size: number;
    current_page: number = 0;
    query_lookup: { attributes: string[], relationships: { [relationship: string]: string[] }};
    active_filters: object;
    dataSource: PaginationDataSource<any>;
    // TODO series component, process access, schedule have different required_keys, set them when invoked

    constructor(public required_keys: string[] = ["attributes.name"]) {
        this.handson_id = utils.guid();
        // @ts-ignore
        Handsontable.renderers.registerRenderer('date_formatter', utils.dRenderer);
        // @ts-ignore
        Handsontable.renderers.registerRenderer('infinite_scroller', utils.hotInfiniteScrollRenderer);
        // @ts-ignore
        Handsontable.renderers.registerRenderer('hot_date_renderer', utils.hotDateRenderer);

    }

    onResize(event) {
        const ctrl = this;
        if (ctrl.ready) {
            ctrl.window_height = window.innerHeight - 240;

            ctrl.instance.render();
            // ctrl.instance.render();
        }
    }

    toCSVString() {
        const instance = this.instance;
        const headers = instance.getColHeader();

        let csv = ""; //"sep=;\n"
        //@ts-ignore
        csv += headers.join(",") + "\n";

        for (let i = 0; i < instance.countRows(); i++) {
            const row = [];
            //@ts-ignore
            for (let h in headers) {
                //@ts-ignore
                const prop = instance.colToProp(h);
                //@ts-ignore
                const value = instance.getDataAtRowProp(i, prop);
                row.push(value)
            }

            csv += row.join(",");
            csv += "\n";
        }

        return csv;
    }

    download(filename?) {
        const csv = this.toCSVString();
        if (filename == null) {
            filename = 'wire.csv'
        }
        const link = document.createElement("a");
        link.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(csv));
        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    clear() {
        const hotInstance = this;

        if (hotInstance.query_lookup && hotInstance.active_filters) {
            delete hotInstance.active_filters;
            hotInstance.source_data = [];
            hotInstance.current_page = 0;
            hotInstance.query(hotInstance.current_page);
            return;
        }

        if (!hotInstance.never_searched) {
            hotInstance.instance.loadData(hotInstance.original_data);
            if (this.account) {
                this.filter_account(this.account);
            }
            hotInstance.instance.render();
            hotInstance.instance.render();
        }
    }

    filter(search) {
        const hotInstance = this;

        if (hotInstance.query_lookup) {
            return hotInstance.queryFilter(search);
        }

        if (hotInstance.never_searched) {
            hotInstance.original_data = utils.deepCopy(hotInstance.instance.getSourceData())
        }
        hotInstance.never_searched = false;

        const countSourceRows = hotInstance.instance.countSourceRows() - hotInstance.settings.minSpareRows - 1;
        const hidden_rows = [];
        const show_rows = [];
        const show_data = [];
        for (let row = 0; row <= countSourceRows; row++) {
            let hide_row = true;
            for (let col = 0; col <= hotInstance.column_list.length; col++) {
                const val = hotInstance.instance.getSourceDataAtCell(row, col);
                if (('' + val).toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    hide_row = false;
                }
            }

            if (hide_row) {
                hidden_rows.push(row)
            } else {
                show_rows.push(row);
                show_data.push(hotInstance.instance.getSourceDataAtRow(row))
            }

        }

        hotInstance.instance.loadData(show_data);
        hotInstance.instance.render();
        hotInstance.instance.render();
    }

    filter_account(account) {
        const ctrl = this;
        ctrl.account = account;

        if (ctrl.query_lookup) {
            ctrl.current_page = 0;
            return ctrl.query(ctrl.current_page);
        }

        if (!account) {
            this.clear();
            return;
        }

        if (ctrl.never_searched) {
            ctrl.original_data = utils.deepCopy(ctrl.instance.getSourceData());
        }
        ctrl.never_searched = false;

        ctrl.instance.loadData(ctrl.original_data);
        const countSourceRows = ctrl.instance.countSourceRows() - ctrl.settings.minSpareRows - 1;
        const hidden_rows = [];
        const show_rows = [];
        const show_data = [];
        for (let row = 0; row <= countSourceRows; row++) {
            let hide_row = true;
            const val = ctrl.instance.getSourceDataAtRow(row);
            if (val['attributes'] && val['attributes'].account_name && val['attributes'].account_name === account.attributes.name) {
                hide_row = false;
            } else {
                hide_row = true;
            }

            if (hide_row) {
                hidden_rows.push(row)
            } else {
                show_rows.push(row);
                show_data.push(ctrl.instance.getSourceDataAtRow(row))
            }
        }

        ctrl.instance.loadData(show_data);
        ctrl.instance.render();
        ctrl.instance.render();
    }

    queryFilter(search) {
        // TODO: implement column-specific filtering
        const ctrl = this;
        if (search) {
            ctrl.current_page = 0;
            const filters = [];
            for (const attr of ctrl.query_lookup.attributes) {
                filters.push({name: attr, op: 'ilike', val: `%${search}%`});
            }
            for (const [relationship, fields] of Object.entries(ctrl.query_lookup.relationships)) {
                const sub_filters = [];
                for (const field of fields) {
                    sub_filters.push({name: field, op: 'ilike', val: `%${search}%`});
                }
                if (sub_filters.length > 1) {
                    filters.push({ name: relationship, op: 'has', val: { or: sub_filters }});
                } else {
                    filters.push({ name: relationship, op: 'has', val: sub_filters[0] });
                }
            }
            ctrl.active_filters = { or: filters };
            ctrl.source_data = [];
            ctrl.query(ctrl.current_page);
            return;
        }

        ctrl.clear();
    }

    query(page) {
        const ctrl = this;
        ctrl.ready = false;
        const filters = [];
        if (ctrl.account) {
            const account = ctrl.account;
            filters.push({ name: 'account', op: 'has', val: {
                name: 'name', op: 'eq', val: account.attributes.name
            }});
        }
        if (ctrl.active_filters) {
            filters.push(ctrl.active_filters);
        }

        ctrl.query_handler(page, ctrl.page_size, filters).then(nextData => {
            if (nextData.length) {
                ctrl.current_page = page;
                ctrl.source_data = ctrl.source_data.concat(nextData);
            } else if(page === 0) {
                ctrl.source_data = [];
            }
            ctrl.instance.loadData(utils.deepCopy(ctrl.source_data));
            ctrl.instance.render();
            ctrl.ready = true;
        });
    }

    getNextPage() {
        const ctrl = this;

        if (ctrl.ready &&
            (ctrl.current_page + 2) * ctrl.page_size > ctrl.source_data.length &&
            (ctrl.current_page + 1) * ctrl.page_size <= ctrl.source_data.length
        ) {
            const nextPage = ctrl.current_page + 1;
            ctrl.query(nextPage);
        }
    }

    initializeInstance(container) {
        // this saves original data clean of updates from handsontable
        this.source_data = utils.deepCopy(this.settings.data);

        this.instance = new Handsontable(container, this.settings);
    }
     filterPage(search, hot) {
        const ctrl = hot;
        const filters = ctrl.updateSearchFilter(search);
        ctrl.dataSource.filterBy(filters)
    }
}
