<div class="component-name-formula-builder full-width">
  <div class="row justify-end medium-height">
    <button mat-button class="text-btn text-btn-right" (click)="addFormula()" [disabled]="formulaParts?.length ===0">
      + Add another formula
    </button>
  </div>
  <div class="row" style="clear:both;">
    <div class="row center flex-half">
      <mat-form-field>
        <mat-label>Select or enter a date format</mat-label>
        <mat-select [(ngModel)]="dateFormat">
          <mat-option *ngFor="let format of predefinedDateFormats" [value]="format">{{format}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!--        <div *ngIf="dateFormat === 'Custom format'">-->
      <!--          <input [formControl]="customDateFormat" placeholder="Enter custom date format">-->
      <!--        </div>-->
      <button (click)="addDateToFormula(dateFormat === 'customFormat'?customDateFormat.value:dateFormat)"
              class="btn-addtotext form-element-align" title="Add to formula">
      </button>
    </div>
    <div *ngIf="customDateFormat.errors?.invalidDateFormat">Invalid Date Format</div>

    <div class="row center flex-half">
      <mat-form-field>
        <mat-label>Choose your formula item separator</mat-label>
        <mat-select [(ngModel)]="selectedSeparator">
          <mat-option *ngFor="let separator of availableSeparators"
                      [value]="separator.symbol">{{separator.symbol + ' (' + separator.title + ')'}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="addSeparatorToFormula(selectedSeparator)"
              class="btn-addtotext form-element-align" title="Add to formula"></button>
    </div>
  </div>
  <div class="row center">
    <div class="row center flex-half">
      <select-search-component
          [value]="selectedConstantProperty"
          [api_model]="'constant_property_light'"
          (selectionChange)="selectedConstantProperty=$event.value"
          [custom_filters]="constantPropertyFilter"
          label="Select one or more Constant Properties"
          [classes]="'fill'"
          class="select-search">
      </select-search-component>
      <button (click)="addPropertyToFormula(selectedConstantProperty)" [disabled]="!selectedConstantProperty"
              class="btn-addtotext form-element-align" style="height:26px;width:26px;"
              matTooltip="Add Constant Property to formula"></button>
    </div>

    <div class="row center flex-half">
      <mat-form-field>
        <mat-label>Add fixed text</mat-label>
        <input matInput #textInput type="text" placeholder="Freeform text">
      </mat-form-field>
      <button (click)="addTextToFormula(textInput.value); textInput.value=''"
              class="btn-addtotext form-element-align" style="height:26px;width:26px;"
              matTooltip="Add fixed text to formula"></button>
    </div>
  </div>

  <h3 class="row">
    <div>Formula:</div>
    <div>
      <button mat-flat-button class="text-btn"
              (click)="listOrientation=listOrientation==='horizontal'?'vertical':'horizontal';"
              matTooltip="Change formula list orientation">
        <span *ngIf="listOrientation==='horizontal'" class="material-symbols-outlined">swap_vert</span>
        <span *ngIf="listOrientation==='vertical'" class="material-symbols-outlined">swap_horiz</span>
      </button>
      <button mat-flat-button class="text-btn" (click)="reset()" matTooltip="Start over">
        <span class="material-symbols-outlined">refresh</span>
      </button>
    </div>
  </h3>


  <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListOrientation]="listOrientation" class="formula-list"
       [ngClass]="listOrientation">
    <div *ngFor="let part of formulaParts; let i = index" cdkDrag class="formula-item" [ngClass]="part.type">
      <div *ngIf="part.type !== 'constant_property'">{{part.content}}</div>

      <div *ngIf="part.type === 'constant_property'">
        <div class="stage-item stage" [ngClass]="{'item-loaded':cpDict[part.content]}">
          <div class="dot-flashing"></div>
        </div>
        {{(cpDict[part.content]?.attributes) | nameOrDescription}}
      </div>
      <button (click)="removeItem(i)">X</button>
    </div>
  </div>

  <component-name-formula-table [(nameFormulas)]="config.name_formulas"
                                [selectedKey]="selectedKey"
                                [cpDict]="cpDict"
                                (formulaDeleted)="formulaDeleted($event)"
                                (formulaSelected)="formulaSelected($event)">

  </component-name-formula-table>

</div>
