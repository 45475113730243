<div class="select-menu-panel" style="padding: 0; width:100%;height:auto;min-height:unset;">
  <div (click)="$event.stopPropagation();">
    <div class="row search" style="padding-bottom:0;">
      <mat-form-field style="width:100%;" floatLabel="never" class="override-hide-placeholder">

        <input #search autocomplete="off" matInput [placeholder]="searchTextboxControl.value? '': placeholder"
               style="box-sizing: border-box;border:none;border-bottom:1px solid grey;"
               (keydown.space)="$event.stopPropagation()" [formControl]="searchTextboxControl">
        <i class="fa fa-times fa-2x" matTooltip="Clear search" mat-icon-button
           aria-label="Clear" style="position: absolute;right: 10px;top: 1px;color: grey;font-size:26px;"
           (click)="clearSearch();">
        </i>
      </mat-form-field>
    </div>


    <div class="row_container" style="width:100%;">
      <div class="select-many-list-container" style="height:auto;border:none;"
           [items_loaded]="availableItems?.length" [total_items]="sapi.availableTotal" [threshold]="'50%'"
           (infiniteScroll)="getNextAvailable()" [complete]="!sapi.loadNext" msDivInfiniteScroll>

        <div *ngFor="let option of availableItems" cdkDrag class="select-many-item"
             [cdkDragDisabled]="true" style="border-bottom:1px solid #ccc;">
          <button *ngIf="action" class="action" (click)="relayAction(option, action)"
                  [disabled]="disabled || disable_items">
            <i [ngClass]="action.icon_class"></i></button>
          <div [matTooltip]="option['text']" [matTooltipShowDelay]="tooltipShowDelay">
            <div class="option-text" [dblClickCopy]="option.text">{{option.text}}</div>
            <div *ngIf="option.value?.attributes?.account_name"
                 class="account-name select-item-account-name"
            >{{option.value.attributes.account_name.trim()}}</div>
          </div>
          <button *ngIf="allow_navigate" class="move" (click)="select(option)"
                  [disabled]="disabled || disable_items" style="margin-left:20px;margin-right:0;">
            <i class="fa fa-caret-right"></i></button>
        </div>
        <div class="no-results" [style]="'padding:10px;'" *ngIf="availableItems.length == 0 && !sapi.loadNext">No results
          found!
        </div>

        <div class="stage" [style]="'padding:20px 0;'" [hidden]="!sapi.loadingAvailable">
          <div class="dot-flashing"></div>
        </div>
      </div>
    </div>

  </div>
</div>
