<div class="lock-tree-container" tabindex="1">
  <div class="row medium-height search-bar">
    <div *ngIf="dataSource" class="table-filter">
      <input (keyup)="cps.applyFilter.next($event.target.value)" [value]="search_string" placeholder="Search tree"/>
    </div>

    <button mat-button class="bordered-button" (click)="toggleFullTree()">
      <span *ngIf="isExpanded">Close all</span>
      <span *ngIf="!isExpanded">Expand all</span>
    </button>
  </div>

  <mat-tree #lockTree [dataSource]="dataSource" [treeControl]="treeControl" class="lock-tree">
    <mat-tree-node *matTreeNodeDef="let node" [id]="node.id">
      <div class="tree-node-border"></div>
      <li>
        <div class="mat-tree-node">
          <ng-container [ngTemplateOutlet]="lockTemplateNode"
                        [ngTemplateOutletContext]="{ $implicit: node}"></ng-container>
        </div>
      </li>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [id]="node.id">
      <li>
        <div class="mat-tree-node">
          <div class="horizontal-border"></div>
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon>
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <ng-container [ngTemplateOutlet]="lockTemplateNode"
                        [ngTemplateOutletContext]="{ $implicit: node}"></ng-container>
        </div>
        <ul class="tree-nested-node-list">
          <div *ngIf="treeControl.isExpanded(node)" class="cover">
            <div class="vertical-border"></div>
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </ul>
      </li>

    </mat-nested-tree-node>
  </mat-tree>
</div>

<ng-template #lockTemplateNode let-node>
  <ng-container *ngIf="node.is_parent">{{node.name}}</ng-container>
  <ng-container *ngIf="node.type==='constant_property'"
                [ngTemplateOutlet]="constantProperty"
                [ngTemplateOutletContext]="{ $implicit: node}"></ng-container>
  <ng-container *ngIf="node.type==='component_type'"
                [ngTemplateOutlet]="componentType"
                [ngTemplateOutletContext]="{ $implicit: node}"></ng-container>
  <ng-container *ngIf="node.type==='event_type'"
                [ngTemplateOutlet]="eventType"
                [ngTemplateOutletContext]="{ $implicit: node}"></ng-container>
</ng-template>

<ng-template #constantProperty let-node>
  <div class="constant-property-node" [ngClass]="{'highlight-node' : selected.includes(node.id)}">
    <div class="row min-height node-header">
      <div class="label">Property: {{node.name}}</div>
      <div class="header-content"><i class="fa" [ngClass]="prop_expanded[node.id]? 'fa-minus':'fa-plus'"
                                     (click)="prop_expanded[node.id]=!prop_expanded[node.id]"></i>
      </div>
    </div>
    <div class="node-content" *ngIf="prop_expanded[node.id]">
      <div><label>Data type:</label> {{node.data_type}}</div>
      <div><label>Aggregation:</label> {{node.aggregation}}</div>
      <div *ngIf="node.is_calculation"><label>Formula:</label> {{node.name_formula}}</div>
    </div>
  </div>
</ng-template>


<ng-template #componentType let-node>
  <div class="component-type-node" [ngClass]="{'highlight-node' : selected.includes(node.id)}">

    <div class="row min-height node-header">
      <div class="label">{{node.name | upperfirst}} dependencies</div>
    </div>
    <div class="node-content" *ngIf="prop_expanded['ct'+node.id]">
      <div><label>Attributes: </label> {{node.attributes.join(', ')}}</div>
    </div>
  </div>
</ng-template>

<ng-template #eventType let-node>
  <div style="padding:10px;font-weight:bold;font-size:1.1rem;">{{node.name | upperfirst}} dependencies</div>
</ng-template>
