import {Injectable} from '@angular/core';
import * as utils from "../lib/utils";
import {ApiService} from "./api/api.service";
import {NotificationService} from "../services/notification.service";
import {catchError} from "rxjs/operators";
import {HttpErrorHandler} from "./http-error-handler.service";
import {ConstantProperty} from "../_models/constant-property";
import {ComponentType} from "../_models/component-type";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {EventConfigColumn} from "../_typing/config/config-column";
import {IDMap} from '../_typing/generic-types';
import {Series} from "../_models/series";

interface ComponentDataCsvRequestParams {
    start: string;
    format: string;
    end: string;
    component_type_id: string;
    constant_properties_ids?: string[];
}

@Injectable({
    providedIn: 'root'
})
export class CsvDownloadService {

    constructor(
        public api: ApiService,
        private notification: NotificationService,
        private httpErrorHandler: HttpErrorHandler,
        private http: HttpClient
    ) {
    }

    downloadComponentDataCsv(start, end, component_type_id, constantPropertiesIds: string[] = []) {
        const requestParams: ComponentDataCsvRequestParams = {start, end, component_type_id, format: 'csv'};

        if (constantPropertiesIds.length) {
            requestParams.constant_properties_ids = constantPropertiesIds;
        }

        return this.api.get('/api/DownloadComponentData/', {
                responseType: 'blob',
                params: utils.httpParamSerializer(requestParams),
                observe: 'response',
            }
        ).pipe(catchError(this.httpErrorHandler.parseErrorBlob)).subscribe(response => {
            const contentDispositionHeader = response.headers.get('content-disposition');
            let fileName: string;
            if (contentDispositionHeader) {
                const parts: string[] = contentDispositionHeader.split(';');
                fileName = parts.length > 1 ? parts[1].split('=')[1] : 'componentData.csv';
            } else {
                fileName = 'componentData.csv';
            }
            const blob = new Blob([response.body], {type: 'application/csv'});
            const href = window.URL.createObjectURL(blob);
            this.downloadCsv(href, fileName);
        }, error => {
            if (error.status === 500) {
                this.notification.openError("Server error", 2000);
            } else {
                this.notification.openError('An error occurred. ' + error.message, 5000);
            }
            console.log('error', error);
        });
    }

    downloadModelData(model_name: string, filename: string, filters: {
                          [key: string]: any
                      }, columns: EventConfigColumn[],
                      cp_dict: IDMap<ConstantProperty>, ct_dict: IDMap<ComponentType>,
                      event_types: string[] = [], filetype: string = 'formatted_csv') {

        let cp_map: { [key: string]: { [key: string]: string | number } } = {};
        let ct_map: { [key: string]: string } = {};
        let columns_renamed: string[] = [];
        let column_map: {
            [key: string]: string | { [key: string]: string } | {
                [key: string]: { [key: string]: string | number }
            }
        } = {};

        columns.forEach((col) => {
            let title;
            if (col.type === 'attribute') {
                // Hybrid Property Columns (i.e. not CPs or Component Types)
                title = col.title || col.id;
                column_map[col.id] = title;
            } else if (col.type === 'component_type') {
                // component type column
                title = col.title || ct_dict[col.id]?.attributes.name || col.id;
                ct_map[title] = ct_dict[col.id].attributes.name;
            } else if (col.type === 'constant_property') {
                // Constant Properties
                title = col.title || cp_dict[col.id]?.attributes.name || col.id;
                cp_map[title] = {'name': cp_dict[col.id].attributes.name};
                if (col.format?.decimals || col.format?.decimals===0) {
                    cp_map[title]['decimals'] = col.format.decimals;
                }
            }
            columns_renamed.push(title);
        });
        column_map['constant_properties'] = cp_map;
        column_map['component_types'] = ct_map;

        let get_url = '/api/utils/download_model?filename=' + filename + '&filters=' +
            encodeURIComponent(JSON.stringify(filters)) + '&columns=' +
            encodeURIComponent(JSON.stringify(columns_renamed)) + '&column_map=' +
            encodeURIComponent(JSON.stringify(column_map)) + '&model_name=' +
            model_name + '&filetype=' + filetype;

        if (!!event_types.length) {
            get_url += '&event_types=' + encodeURIComponent(JSON.stringify(event_types));
        }
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });

        this.http.get(get_url, {headers: headers, responseType: 'blob'}).subscribe({
            next: (response: any) => {
                if (!response || response.status_code === 204) {
                    this.notification.openError('No Data Found for the selected time range and filters', 2000);
                } else {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(response);
                    link.download = filename + "." + (filetype.includes('_csv') ? 'csv' : 'xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },
            error: (errors) => {
                this.notification.openError('Failed to Generate CSV: ' + errors.reason, 2000);
                console.log('reason for fail', errors);
            }
        })
    }

    downloadCsv(href, title: string, extension: '.csv' | '.xlsx' = '.csv') {
        const a = document.createElement("a");
        a.href = href;
        a.download = title + extension;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
