import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserData} from "../../../services/user_data.service";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {WireButtonComponent} from "../../../shared/buttons/wire-button/wire-button.component";

export interface IUserDetails {
    successfulSubmit: boolean;
    userName: string;
}

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule,
    //     ReactiveFormsModule,
    //     MatFormFieldModule,
    //     MatInputModule,
    //     WireButtonComponent
    // ],
    // providers: [
    //     UserData
    // ],
    selector: 'user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.less'],
    standalone: false
})

export class UserDetailsComponent {
    detailForm: FormGroup;
    returnUrl: string;
    @Output() detailsSubmitted: EventEmitter<IUserDetails> = new EventEmitter<IUserDetails>();

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private userData: UserData) {
    }

    ngOnInit(): void {
        this.detailForm = this.formBuilder.group({
            username: ['', Validators.required],
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    get formControl() {
        return this.detailForm.controls.username;
    }

    onDetailsSubmit(): void {
        if (this.detailForm.invalid) {
            this.detailForm.markAllAsTouched();
            return;
        }
        this.userData.getUserMigrationStatus(this.formControl.value).subscribe((value: { migrated: boolean }): void => {
            this.detailsSubmitted.emit(
                {successfulSubmit: true, userName: this.detailForm.controls.username.value}
            );
        });
    }
}
