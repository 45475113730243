<form [formGroup]="bulkDownloadForm" class="component-events-download-container">
    <label style="font-size:1.2rem;">Select download type</label>
    <div class="row space-above">
        <mat-radio-group formControlName="selectedFileType" class="radio-group">
            <mat-radio-button *ngFor="let fileFormat of fileDownloadTypes" [value]="fileFormat.value">
                {{fileFormat.display}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <mat-form-field *ngIf="!!bdForm.selectedFileType.value && bdForm.selectedFileType.value !== 'files'" class="bordered-select space-above" >
        <mat-label>Select formatting to apply</mat-label>
        <mat-select formControlName="selectedFileFormat"
                    class="page-field-select"
                    style="margin: 0 0 20px;">
          <mat-option *ngFor="let option of filteredFileOptions" [value]="option.value">
            {{option.title}}
          </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="!!bdForm.selectedFileType.value && bdForm.selectedFileType.value === 'files'" class="row space-above" >
        <select-many-constant-properties
                [value]="fileConstantProperties"
                [custom_filters]="filePropertyFilter"
                label="Select file Constant Properties to download">
        </select-many-constant-properties>
    </div>
    <div class="row btn-action">
        <div class="btn-download" (click)="downloadClicked()">Download</div>
    </div>
</form>
