import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingBarService} from "@ngx-loading-bar/core";
import {
    NavigationEnd,
    NavigationError,
    ActivationStart, Router
} from "@angular/router";
import {HeaderDataService} from "./services/header_data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ApiService} from "./services/api/api.service";
import {DateTimePeriodService} from "./services/date-time-period.service";
import {AppScope} from "./services/app_scope.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class AppComponent implements OnDestroy, OnInit {
    private readonly onDestroy = new Subject<void>();

    showing_comments_panel: boolean = false;
    loadingBarColour:string = 'darkorange'; //fallback;
    currentTheme: string;

    constructor(public loader: LoadingBarService,
                private router: Router,
                public headerData: HeaderDataService,
                public dateTimePeriodService: DateTimePeriodService,
                public appScope: AppScope,
                private api: ApiService,
    ) {
        this.appScope.isNotMobile = this.detectMobile();
        if (!this.detectChrome() && this.appScope.isNotMobile) {
            // this.notification.open("This application was intended to run in a Chrome browser. " +
            //     "We highly recommend that you download chrome from https://www.google.com/chrome/" , 'Hide');
        }
        this.loadingBarColour = getComputedStyle(document.documentElement).getPropertyValue('--wire-orange');
        this.appScope.currentTheme.pipe(takeUntil(this.onDestroy)).subscribe((theme: string) => {
            this.currentTheme = theme;
        })

        this.router.events.pipe(takeUntil(this.onDestroy)).subscribe((event) => {

            if (event instanceof ActivationStart && event.snapshot.outlet === 'primary') {
                headerData.reset();
                dateTimePeriodService.read_dtp_from_url = false;
                dateTimePeriodService.show_timespan = true;
                dateTimePeriodService.show_time = true;
                this.api.cancelActiveQueries();
            }

            if (event instanceof NavigationEnd) {
                this.appScope.navigate_inside = true;
                (<any>window).dataLayer.push({
                    event: 'pageview',
                    page: {
                        path: event.url,
                        title: 'Custom pageview event'
                    }
                });
                this.appScope.previous_url = this.appScope.current_url;
                this.appScope.current_url = event.urlAfterRedirects;
            }

            if (event instanceof NavigationError) {
            }
        });
    }

    ngOnInit(): void {
        this.headerData.showingCommentPanel.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.showing_comments_panel = value.toggle;
            this.appScope.resizeWindowEvent(1000);
        });
    }

    detectChrome() {
        return !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
    }

    detectMobile() {
        return !(/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
