import { Component } from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";

@Component({
    selector: 'main-sidenav',
    templateUrl: './main-sidenav.component.html',
    styleUrls: ['./main-sidenav.component.less'],
    standalone: false
})
export class MainSidenavComponent {
  finishedLoading: boolean;
  sidenav_mode: string = 'over';

  constructor(public headerData: HeaderDataService,
              public appScope: AppScope) {
  }
  ngOnInit(): void {
  }
}
