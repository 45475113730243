<form (ngSubmit)="onPasswordSubmit()" [formGroup]="passwordForm" class="column">
    <label>Password</label>
    <mat-form-field class="form-input-field no-label">
        <div class="input-suffix">
        <input matInput formControlName="password"
               [ngClass]="formControl.dirty ? !formControl.hasError('required') ? 'valid': 'error' : ''"
              id="password" [type]="!hidePassword ? 'password' : 'text'"/>
        <button class="icon-button" type="button" matSuffix mat-icon-button aria-label="hidePassword" (click)="hidePassword = !hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        </div>
        <mat-error *ngIf="formControl.invalid
            && formControl.hasError('required')">
            This field is required
        </mat-error>
    </mat-form-field>
    <div class="row" style="justify-content: end; min-height: 1rem">
      <button label="Forgot Password" type="button" (click)="onSubmitReset()" class="forgot-password">
        Forgot Password?</button>
    </div>
    <div class="password-button buttons">
        <wire-button [btnContent]="'Log In'" [btnStyle]="'btn-default-full'"></wire-button>
    </div>
</form>
