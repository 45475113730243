import {Injectable} from '@angular/core';
import {ColumnFontStylesDict, ColumnFormats, TableUtilsService} from './table-utils.service';
import {ColumnFormatsConfig} from "../forms/table-column-menu/table-column-menu.component";
import {Component as WireComponent} from '../_models/component';
import {Event as WireEvent} from '../_models/event';
import {KeyMapMap} from "../_typing/generic-types";

export interface ConditionalFormattingConfig {
    column: { id: string, name: string, type: string };
    comparer: string;
    value?: any;
    upper?: any;
    lower?: any;
    formula?: string;
    named_format?: string;
    format?: ColumnFormatsConfig,
    colour_scale?: { min: { value: number, colour: string }, mid: { value: number, colour: string }, max: { value: number, colour: string } }
}

export interface ConditionalFormattingData {
    [model_type: string]: { data: { [key: string]: { [key: string]: any } | {} }; dict: { [key: string]: any } };
}

export interface RowColConditionalFormat {
    format: ColumnFontStylesDict;
    condition: ConditionalFormattingConfig;
}

@Injectable({
    providedIn: 'root'
})
export class ConditionalFormattingService {

    named_formats: { [key: string]: ColumnFormatsConfig }
        = {
        ok: {"background_colour": "green"},
        warn: {"background_colour": "orange"},
        error: {"background_colour": "red"},
        ok_text: {
            "colour": "green",
            "bold": true
        },
        warn_text: {
            "colour": "orange",
            "bold": true
        },
        error_text: {
            "colour": "red",
            "bold": true
        },
    };

    constructor(private tableUtils: TableUtilsService) {
    }

    /**Compares data values from a component with the list of conditions for that component**/
    extractConditions(conditions: ConditionalFormattingConfig[], rows: WireEvent[] | WireComponent[], columns: string[],
                      data: ConditionalFormattingData): KeyMapMap<Partial<ColumnFormats>> {
        const dict: KeyMapMap<Partial<ColumnFormats>> = {};
        try {
            conditions.forEach(condition => {
                //FIXME This needs deeper migration to update the conditional formatting config
                if (!condition.column.id) condition.column.id = condition.column.name;
                if (!columns.includes(condition.column.id)) return;
                rows.forEach(row => {
                    if (this.isConditionMet(condition, row, condition.column, data[condition.column.type])) {
                        /**Apply conditional formatting on top of column formatting**/
                        const column = condition.named_format ? condition.named_format : condition.column.name;
                        const format = condition.named_format ? this.named_formats[column] : condition.format;
                        const styles: ColumnFontStylesDict = this.tableUtils.getStyles({[column]: format}, column);
                        if (!dict[condition.column.id]) {
                            dict[condition.column.id] = {};
                        }
                        dict[condition.column.id][row.id] = {
                            style: styles
                        }
                    }
                })
            });
        } catch (e) {
            console.log("WARNING: ConditionalFormattingService (extractConditions), error extracting conditions ", e);
        }
        return dict;
    }

    isConditionMet(condition, row, column, data) {
        /**Extract the value from the data based on whether the data belongs to the row model object (e.g. component.attributes.name/start),
         * or to a related property from data:ConditionalFormattingData e.g. from the component_constant_dict[component.id][property_name]
         */
        let value;
        if (data) {
            value = data.data[row.id]?.[column.id]?.value;
        } else {
            value = column.name ? row.attributes[column.name.toLowerCase()] : row.attributes[column.name];
        }
        if (!isNaN(condition.value)) {
            condition.value = parseFloat(condition.value)
        }
        switch (true) {
            case (condition.comparer === 'empty') :
                return !value && value !== 0;
            case (condition.comparer === 'not_empty') :
                return value || value === 0;
            case (condition.comparer === '=') :
                return value == condition.value;
            case (condition.comparer === '!=')  :
                return value != condition.value;
            case (condition.comparer === '<') :
                return value < condition.value;
            case (condition.comparer === '<=')  :
                return value <= condition.value;
            case(condition.comparer === '>')  :
                return value > condition.value;
            case(condition.comparer === '>=')  :
                return value >= condition.value;
            case (condition.comparer === '<>')  :
                return value > condition.lower && value < condition.upper;
            case (condition.comparer === '><')  :
                return value < condition.lower || value > condition.upper;
            case (condition.comparer.includes('!_case_includes'))  :
                return !value?.includes(condition.value);
            case (condition.comparer.includes('case_includes'))  :
                return value?.includes(condition.value)
            case(condition.comparer.includes('!_includes'))  :
                return !value?.toString().toLowerCase().includes(condition.value?.toLowerCase());
            case(condition.comparer.includes('includes')) :
                return value?.toString().toLowerCase().includes(condition.value?.toLowerCase());
            default:
                return false;
        }
        return false;
    }
}
