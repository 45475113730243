<div class="panel-stack-container" [appResizable]="panelElements || []">
  <ng-container *ngFor="let panel of panels; let i = index">
    <div class="panel" [ngClass]="{'show':panel.show}" #resizePanel>
      <div class="panel-resize-handle"></div>
      <div class="back-link">
        <a (click)="back()">< Back
          to {{panel.data?.previous_component === 'component' ? 'new component form' : panel.data?.previous_component?.attributes?.name || 'dashboard'}}</a>
      </div>
      <new-component *ngIf="panel.type === 'component'"
                     [data]="panel.data"
                     (loadNewComponent)="loadRelatedComponent($event, panel, true)"></new-component>
      <component-detail *ngIf="panel.type === 'component-detail'"
                        [data]="panel.data"
                        (loadRelatedComponent)="loadRelatedComponent($event, panel)">
      </component-detail>
    </div>
  </ng-container>
  <button *ngIf="panels?.length" class="close-panel" (click)="close()"><i class="material-icons">close</i>
  </button>
</div>
