<div class="form-dialog" style="min-width:890px;">
  <h1>Edit {{title}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <flowchart-process-form (close_event)="onCloseClick()"
                          (save_event)="save($event)"
                          *ngIf="component.data.type === 'process'"
                          [component]="component"
                          [dialog]=true
                          [flowchart]="flowchart"
                          [show_hints]="showHints">

  </flowchart-process-form>
  <flowchart-stream-form (close_event)="onCloseClick()"
                         (save_event)="save($event)"
                         *ngIf="component.data.type === 'stream'"
                         [component]="component"
                         [dialog]=true
                         [flowchart]="flowchart"
                         [show_hints]="showHints">

  </flowchart-stream-form>
  <flowchart-equipment-form (close_event)="onCloseClick()"
                            (save_event)="save($event)"
                            *ngIf="component.data.type === 'equipment'"
                            [component]="component"
                            [dialog]=true
                            [flowchart]="flowchart"
                            [show_hints]="showHints">

  </flowchart-equipment-form>
  <flowchart-image-form (close_event)="onCloseClick()"
                        (save_event)="save($event)"
                        *ngIf="component.data.type === 'image'"
                        [component]="component"
                        [dialog]=true
                        [flowchart]="flowchart"
                        [show_hints]="showHints">

  </flowchart-image-form>

  <ore-body-group-form (close_event)="onCloseClick()"
                       (save_event)="component.data=$event;save($event)"
                       *ngIf="component.data.type === 'ore_body_group'"
                       [component]="component"
                       [dialog]=true
                       [flowchart]="flowchart"
                       [show_hints]="showHints">

  </ore-body-group-form>
</div>
