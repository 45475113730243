<div class="column">
  <div class="row" style="justify-content: space-between;">
    <range-selector style="padding: 20px 20px 0 80px;flex-grow:1;"></range-selector>
    <mat-form-field *ngIf="chart_component!=='custom-chart' && estimate_list?.length > 0"
                    style="margin:10px 10px 0 0;">
      <mat-label>Select estimate</mat-label>
      <mat-select (selectionChange)="changeEstimate($event)" [ngModel]="selected_estimate">
        <mat-option *ngFor="let estimate of estimate_list"
                    [value]="estimate">{{estimate.attributes | nameOrDescription}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="position: relative;">
    <generic-chart *ngIf="data_ready && config" [config]="config" class="chart"></generic-chart>
  </div>

</div>
<div class="comment-button">
  <button
      (click)="headerData.toggleCommentPanel(!headerData.showing_comments, {tileData:tileData, eventService:eventService})"
      [ngClass]="{'show-drawer': headerData.showing_comments}"
      class="icon-comment">
  </button>
</div>
