import {Directive, HostListener, EventEmitter, Renderer2, OnInit, OnDestroy, ElementRef, Input} from '@angular/core';
import {AppScope} from '../services/app_scope.service';
import * as utils from '../lib/utils';
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SeriesAuditHistoryComponent} from "../components/series-audit-history/series-audit-history.component";
import ClickEvent = JQuery.ClickEvent;
import {ApiService} from '../services/api/api.service';
import {FormDialogService} from "../services/form-dialog.service";
import {Series} from '../_models/series';
import {SearchQueryOptions} from '../services/api/search-query-options';

@Directive({
    selector: '[appSeriesAudit]',
    standalone: false
})
export class SeriesAuditHistoryDirective implements OnDestroy {

    @Input('appSeriesAudit') series: any; //Should have id and attributes
    @Input() series_name: string;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private formDialogService: FormDialogService) {

    }

    openModal(event: any) {
        const data_config = {
            component: 'SeriesAuditHistoryComponent',
            parameters: {
                series: this.series
            }
        }
        const panelClass = 'series-audit-history-dialog';
        const modalDialog = this.formDialogService.openCustomDialog(event, data_config, panelClass);
    }

    @HostListener('click', ['$event']) seriesSelected(event) {
        this.getSeries(event);
    }

    getSeries(event) {
        if (!this.series || !this.series.id) {
            console.log("name ", this.series_name)
            const options = new SearchQueryOptions();
            options.filters = [{
                'op': 'eq', 'name': 'name', val: this.series_name
            }];
            this.api.series.searchSingle(options).pipe(take(1))
                .subscribe(result => {
                    this.series = result.data;
                    this.openModal(event);
                })
        } else {
            this.openModal(event);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }


}
