import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import * as utils from '../../lib/utils';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Sort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {TileDataService} from "../../services/tile_data.service";
import {SeriesColumn, SeriesDataService} from "../../services/series_data.service";
import {intersection as _intersection, difference as _difference} from "lodash-es";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {concatMap, first, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {forkJoin, Observable, of, Subject, Subscription} from "rxjs";
import {HeaderDataService} from "../../services/header_data.service";
import {AppScope} from "../../services/app_scope.service";
import {EventService} from "../../services/event.service";
import {MatTableDataSource} from "@angular/material/table";
import {TableUtilsService} from "../table-utils.service";
import {FormDialogService} from "../../services/form-dialog.service";
import {SeriesDetailTableService} from "../series-detail-table/series-detail-table.service";
import {ComponentDataService} from '../../data/component-data.service';
import {NotificationService} from "../../services/notification.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {SeriesTableConfig} from "../../_typing/config/series-table-config";
import {SeriesSummary} from "../../_models/api/series-summary";
import {KeyMap} from "../../_typing/generic-types";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    selector: 'series-table',
    templateUrl: 'series-table.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [TableUtilsService, SeriesDetailTableService],
    standalone: false
})
export class SeriesTableComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    series_events: any;
    access: { all: { promise: Promise<any> }, permissions: any };
    seriesPermissions: any;
    override_calc_allowed: boolean = true;
    hovering_events: any[] = [];
    series_map: any = {};
    series_ids: string[] = [];
    est_favourability_mapping: any = {};
    favourabilityFormats: any
    series_details;

    get config() {
        return this._config;
    }

    @ViewChild('series_table', {static: true}) series_table: ElementRef;
    statusColumns: string[] = [];
    extraColumns: string[] = [];
    customColumns: string[];
    detailColumns: string[] = this.seriesDetailsService.columns;
    show_series_details: boolean = false;
    dataSource: MatTableDataSource<any>;
    summary: any[];
    summarySorted: any[];
    significant_numbers: boolean = true;
    refreshSubscription: Subscription;

    columnOptions: SeriesColumn[];
    trendColumn: string = "Trend";
    col_dict: any;
    format_dict: any;
    hide_on_print: any;
    private buttons: ITileButton[];
    private _config: SeriesTableConfig;
    isSticky: KeyMap<boolean> = {};

    constructor(private dateTimePeriod: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                public tileData: TileDataService,
                private seriesData: SeriesDataService,
                private eventService: EventService,
                public appScope: AppScope,
                public dialog: MatDialog,
                public plantData: PlantDataService,
                private headerData: HeaderDataService,
                public tableUtils: TableUtilsService,
                private notification: NotificationService,
                private formDialogService: FormDialogService,
                private seriesDetailsService: SeriesDetailTableService,
                private componentData: ComponentDataService) {
    }

    @Input()
    set config(config: SeriesTableConfig) {
        if (!config.columns) {
            config.columns = [];
            if (config.normal_columns) {
                config.normal_columns.forEach(item => {
                    if (!config.columns.includes(item)) {
                        config.columns.push(item);
                    }
                });
            }
            if (config.detail_columns) {
                config.detail_columns.forEach(item => {
                    if (!config.columns.includes(item)) {
                        config.columns.push(item);
                    }
                });
            }
        }
        if (!config.mobile_columns) {
            config.mobile_columns = this.seriesData.columnsSmall;
        }
        // TODO test that this isn't needed
        config.columns = config.columns.filter((comment) => comment !== 'Comments');
        this.setupColumns(config);
        if (this._config && config.series_list.map(series => series.id) !== this._config.series_list.map(series => series.id)) {
            this._config = config;
            this.refresh(this.dateInst.dtp);
        }
        this._config = config;
    }

    ngAfterViewInit(): void {
        // Setting default title for tile
        if (this.config.process?.id) {
            this.tileData.setDefaultTitle(this.config.process.attributes.name);
        }
    }

    ngOnDestroy(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }
        this.tableUtils.removeListeners();
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    ngOnInit(): void {

        forkJoin([this.dateTimePeriod.dtpInitialisedPromise.promise]).subscribe(() => {
            this.refresh(this.dateInst.dtp);
        });
        this.seriesData.$estimate_types.promise.then(response => {
            this.est_favourability_mapping = this.seriesData.est_favourability_dict;
        });

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy))
            .subscribe((dtp) => {
                this.refresh(dtp);
            });

        // TODO add these based on whether comment panel is open
        this.tileData.addCommentClicked.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.saveComment(value));
        this.tileData.commentHover.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.commentHover(value));
        this.tileData.commentLeave.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.commentLeave(value));

        if (this.tileData?.save_content) {
            this.tileData.save_content.pipe(takeUntil(this.onDestroy)).subscribe(params => {
                this.tableUtils.saveTile(params.column_formats, this.format_dict, this.tileData, 'column_formats');
            });
        }

        this.hide_on_print = ['Status'];
        if (this.config && this.config.process && this.config.process.id) {
            this.access = this.plantData.getIsolatedPermissions(this.config.process.id);
            this.access.all.promise.then(response => {
                this.access = response;
                this.setButtons();
            });

        } else if (this.config && this.config.series_list && this.config.series_list.length > 0) {
            this.seriesData.getSeriesPermissions(this.config.series_list.map((series) => series.id)).subscribe(
                result => {
                    this.seriesPermissions = result;
                    this.config.series_list.forEach(series => {
                        if (!this.seriesPermissions[series.id] ||
                            (this.seriesPermissions[series.id] && this.seriesPermissions[series.id].includes('override_calculations') === false)) {
                            this.override_calc_allowed = false;
                        }
                    });
                    this.setButtons();
                });
        } else {
            this.setButtons();
        }
    }

    toggleSeriesDetails() {
        this.show_series_details = !this.show_series_details;
        if (!this.show_series_details) {
            this.setupColumns(this.config);
            this.dataSource.data = this.summarySorted;
            return;
        }
        this.columns = this.statusColumns.filter(col => this.config.columns.includes(col)).concat(this.detailColumns);
    }

    setButtons() {
        this.buttons = [];
        if (this.config.process) {
            this.buttons.push({
                name: 'Open Logsheet',
                func: '/view/log_sheet/' + this.config.process.id,
                link: true,
                params: {},
                class: 'fa small fa-flask  hide-xs',
                HoverOverHint: 'Log sheet'
            });
        }

        this.buttons.push({
                name: 'Toggle Decimals',
                func: () => this.showDecimals(),
                params: {},
                class: 'fa small fa-percent',
                HoverOverHint: 'Show/remove number formats'
            }
        );

        this.buttons.push({
                name: 'Toggle Series Details',
                func: () => this.toggleSeriesDetails(),
                params: {},
                class: 'fa fa-info',
                HoverOverHint: 'Toggle series details'
            }
        );

        if ((this.access && this.access.permissions && this.access.permissions.override_calculations) ||
            (this.seriesPermissions && this.override_calc_allowed)) {

            this.buttons.push({
                name: 'Update Calculations',
                func: () => this.overrideCalculations(),
                params: {},
                class: 'fa fa-calculator hide-xs',
                HoverOverHint: 'Update calculations'
            });

        }
        this.tileData.buttonsChanged.next(this.buttons);
    }

    sortData(sort: Sort) {
        const data = this.summary.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }
        const isAsc = sort.direction === 'asc';

        if (this.show_series_details) {
            const sorted = [...this.series_details].sort((a, b) => {
                return utils.compare(a.attributes[sort.active], b.attributes[sort.active], isAsc);
            });
            this.dataSource.data = data.sort((a, b) => {
                /**Sort the dataSource by the sorted series_details**/
                return (sorted.map(d => d.id)).findIndex(d => a.ID === d) -
                    (sorted.map(d => d.id)).findIndex(d => b.ID === d);
            });
        } else {
            this.dataSource.data = data.sort((a, b) => {
                return utils.compare(a[sort.active], b[sort.active], isAsc);
            });
        }
    }

    showDecimals() {
        this.significant_numbers = !this.significant_numbers;
    }

    makeMap(series_ids): Observable<any> {
        return this.seriesData.getSeriesLightListById(series_ids).pipe(tap(response => {
            if (!response) {
                return;
            }
            response.data.map(series => {
                this.series_map[series.id] = series;
            });
        }));
    }

    refresh(dtp: IDateTimePeriod) {
        let sample_period = '';
        if (['month', 'months', 'monthly', 'shift', 'shifts', 'shiftly'].includes(dtp.sample_period.name)) {
            sample_period = dtp.sample_period.name;
        } else {
            sample_period = dtp.sample_period.seconds.toString() + 's';
        }
        let params = {
            return_type: 'json', format: 'records',
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
            sample_period: sample_period,
            period_type: dtp.calendar,
        };
        const shiftTypeId = this.config.shift_type_id || this.seriesData.getShiftTypeFromDtp(dtp);
        if (shiftTypeId) {
            params['shift_type_id'] = shiftTypeId;
        }

        if (this.config.columns) {
            params['columns'] = this.getColumnListParam();
        }

        if (this.config.estimate_type) {
            params['estimate'] = this.config.estimate_type;
        }
        const processId = this.config.process?.id;
        const seriesList = this.config.series_list?.map(s => s.id);
        // if (this.config.process && this.config.process.id) {
        //     params['process'] = this.config.process.id;
        // }
        // if (this.config.series_list && this.config.series_list.length > 0 && this.config.series_list[0].id) {
        //     params['series_list'] = this.config.series_list.map((item) => item.id);
        // }
        try {
            if (this.refreshSubscription) {
                this.refreshSubscription.unsubscribe();
                this.refreshSubscription = null;
            }
            this.refreshSubscription = this.seriesData.getSeriesSummary(dtp, seriesList, processId, this.getColumnListParam(), this.config.estimate_type, this.config.shift_type_id)
                .pipe(
                    concatMap((response: any[]) => {
                        this.summary = response;
                        this.summarySorted = response;
                        this.series_ids = this.summary.map(s => s.ID);

                        return this.makeMap(this.series_ids).pipe(tap(() => {
                            this.summary.map(series => {
                                series.kpi_level = this.series_map[series.ID] ? this.series_map[series.ID].attributes.kpi_level : '';
                            });
                            this.summary = this.summary.filter(series => {
                                return !this.config.kpis
                                    || this.config.kpis.length === 0
                                    || this.config.kpis.includes(series.kpi_level);
                            });

                            this.dataSource = new MatTableDataSource(this.summarySorted);
                            this.getFavourabilityFormats();
                            this.getTrends();
                            // this.sortData({active: "", direction: "desc"});
                        }));
                    }),
                    concatMap(() => this.getEvents())
                ).subscribe({
                    next: () => {
                    },
                    error: (reject): void => {
                        console.log("ERROR: series-table (refresh)", reject);
                        const calcErrorMsg = 'Unable to load table due to a calculation formula error.'
                        if (!this.headerData.checkCalculationError(reject, calcErrorMsg)) {
                            if (reject.error?.message || reject.error?.errors) {
                                this.notification.openError(reject.error?.message || reject.error.errors);
                            }
                        }

                    }
                });
        } catch (e) {
        }
    }

    private getColumnListParam() {
        let columnList = [];
        const shouldAddColumn = (columnName): boolean => {
            return !columnList.includes(columnName) && !this.config.columns.includes(columnName);
        }
        this.config.columns.forEach(columnName => {
            const fullColumn = this.getColumnFromColumnName(columnName);
            //If using favourability colours on actuals, make sure the estimate columns are being requested
            const estType = this.config.actuals_favourability_estimate_dict?.[columnName];
            if (estType) {
                const estColumnName = fullColumn.name.replace('@', estType);
                if (shouldAddColumn(estColumnName)) {
                    columnList.push(estColumnName);
                }
            }

            //If trend column, make sure the estimate and actual columns are being requested
            if (columnName.includes(this.trendColumn)) {
                const actualColumnName = fullColumn.name.replace(this.trendColumn, "").trim();
                if (shouldAddColumn(actualColumnName)) {
                    columnList.push(actualColumnName)
                }
                const estColumnName = fullColumn.name.replace(this.trendColumn, "").trim()
                if (shouldAddColumn(estColumnName)) {
                    columnList.push(estColumnName)
                }
            }
        })
        return this.config.columns.concat(columnList);
    }

    getFavourabilityFormats() {
        this.favourabilityFormats = {};
        this.dataSource.data.forEach((row: SeriesSummary) => {
            const bypassNormalFormatting = this.config.bypass_estimate_formatting;
            this.favourabilityFormats[row.ID] = {};
            for (let columnName of this.extraColumns) {
                if (bypassNormalFormatting) {
                    this.favourabilityFormats[row.ID][columnName] = undefined;
                } else {
                    this.favourabilityFormats[row.ID][columnName] = row[this.est_favourability_mapping[columnName]];
                }

                const estType = this.config.actuals_favourability_estimate_dict?.[columnName];
                if (!estType) continue;

                const fullColumn = this.getColumnFromColumnNameAndEstimate(columnName, estType);
                const estColumnName = fullColumn.name.replace('@', estType);
                const favourabilityColName = this.est_favourability_mapping[estColumnName];
                const value = row[favourabilityColName];

                if (favourabilityColName !== undefined) {
                    this.favourabilityFormats[row.ID][columnName] = value;
                }
            }
        })
    }

    private getColumnFromColumnNameAndEstimate(columnName: string, estType: string): SeriesColumn {
        return this.columnOptions.find(c => c.original === columnName && c.estimate_name === estType);
    }

    private getColumnFromColumnName(columnName: string): SeriesColumn {
        return this.columnOptions.find(c => c.name === columnName);
    }

    private getTrends() {
        this.extraColumns.forEach(columnName => {
            if (columnName.includes(this.trendColumn)) {
                const trend = this.getColumnFromColumnName(columnName)?.estimate_name;
                if (!trend) return;
                const estColumn = columnName.replace(this.trendColumn, "").trim();
                const actualColumn = estColumn.replace(trend, "").trim();
                this.dataSource.data.forEach((row: SeriesSummary) => {
                    if (row[columnName] === null || row[estColumn] === null) {
                        row[columnName] = null;
                    } else {
                        const sum = (row[estColumn] - row[actualColumn]);
                        const direction = sum === 0 ? null : sum > 0 ? 'positive' : 'negative';
                        row[columnName] = direction;
                        if (!this.config.bypass_estimate_formatting) {
                            this.favourabilityFormats[row.ID][columnName] = row[this.est_favourability_mapping[estColumn]];
                        }
                    }
                })
            }
        })
    }

    overrideCalculations() {
        let seriesList: any[]; // array of series id's

        // TODO add dialog confirm
        let doOverride = () => {
            this.headerData.getCalculations(this.dateInst.dtp, seriesList, 'hour', 1).then(response => {
                this.refresh(this.dateInst.dtp);
            }).catch();
        };
        if (this.config.series_list && this.config.series_list.length > 0 && this.config.series_list[0].id) {
            seriesList = this.config.series_list.map(series => series.id);
            doOverride();
        } else {
            this.componentData.getSeriesComponentsByComponentIds(this.config.process.id, 'process').pipe(
                take(1),
                tap((response => {
                    if (!response) {
                        return;
                    }
                    seriesList = response.data?.map(sc => sc.relationships.series.data?.id);
                    doOverride();
                }))).subscribe();
        }
    }

    editSeries(element) {
        const ctrl = this;

        if (!element || !element.ID) {
            this.notification.openError("Error opening series of undefined element");
            return;
        }

        if (!this.series_map[element.ID]) {
            this.notification.openError(`Could not find series for editing from ${element.ID}`);
            return;
        }

        this.seriesData.upsertSeries(this.config.process, this.series_map[element.ID]).afterClosed().subscribe((series) => {
            if (series) {
                let updated_series;
                if (series.series) {
                    updated_series = series.series;
                } else {
                    updated_series = series;
                }
                if (!this.series_map[updated_series.id]) {
                    this.series_map[updated_series.id] = updated_series;
                }
                this.series_map[updated_series.id].attributes = updated_series.attributes;
                this.dateInst.emitDateTimePeriodRefreshed(this.dateInst.dtp);
            }
        });
    }

    openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name, this.dateInst.dtp);
    }

    columns = [];

    setupColumns(config) {
        const ctrl = this;
        let newColumns, allColumns;
        if (this.appScope.isNotMobile) {
            newColumns = config.columns;
        } else {
            newColumns = config.mobile_columns;
        }
        const columns = this.seriesData.fillColumns(config.estimate_type);
        this.columnOptions = columns.columns;
        allColumns = columns.all;

        const statusColumns = ['Name', 'Status', 'Description', 'Alias'];

        this.statusColumns = _intersection(newColumns, statusColumns);
        this.extraColumns = _difference(newColumns, statusColumns);
        this.customColumns = _difference(this.extraColumns, allColumns);

        this.col_dict = this.seriesData.column_dict;
        this.initialiseColumnFormats(config, newColumns);
        if (config.show_comments) {
            this.columns = config.columns.concat('Comments');
        } else {
            this.columns = config.columns;
        }
        this.isSticky = this._getSeriesColumnIdentifier(this.columns);
    }

    initialiseColumnFormats(config, columns) {
        this.format_dict = {};
        //Done once instead of during change detection
        const col_dict_cols = Object.keys(this.col_dict);
        columns.forEach(column => {
            let title = column;
            //This check is done further down anyway but have left it in for the warning message
            if (!col_dict_cols.includes(column)) {
                console.warn('SeriesTableComponent.setupColumns: missing col_dict entry for ' + column);
            } else {
                title = this.col_dict[column].title;
            }
            if (config.column_formats && config.column_formats[column]) {
                if (config.column_formats[column].abbreviate === true && this.seriesData.column_dict[column] && this.seriesData.column_dict[column].abbr) {
                    title = this.seriesData.column_dict[column].abbr;
                }
            }
            this.format_dict[column] = this.tableUtils.getColumnFormats(config.column_formats?.[column]);
            this.format_dict[column].title = title;
        });
    }

    setComment(e, series, time) {
        this.tileData.comment.series = this.series_map[series.ID];
        this.tileData.comment.start = this.dateInst.dtp.start;
        this.tileData.comment.end = this.dateInst.dtp.end;
    }

    private _getSeriesColumnIdentifier(identifierColumns: string[]): KeyMap<boolean> {
        let columns = identifierColumns.map(c => c.toLowerCase());
        let isSticky = {'Name': true, 'Alias': false, 'Description': false};
        const isFirst = (columns: string[], firstColumn: string): boolean => {
            return columns.every(c => columns.includes(c)) && columns.every((c) => columns.indexOf(firstColumn) < columns.indexOf(c))
        }
        isSticky.Name = columns.includes("name");
        isSticky.Alias = (columns.includes("alias") && !columns.includes("name")) || isFirst(["name", "alias"], "alias");
        isSticky.Description = columns.includes("description") && !(columns.includes("name") || columns.includes("alias"));
        return isSticky;
    }

    $save: Subscription;

    saveComment(comment) {
        const ctrl = this;
        this.$save = utils.refreshSubscription(this.$save);
        this.$save = this.eventService.addInlineComment(comment, this.tileData.comment.start, this.tileData.comment.end,
            [this.tileData.comment.series]).pipe(
            switchMap((new_comment) => {
                if (!new_comment) {
                    return of(null);
                }
                return this.getEvents();
            }),
            first(), takeUntil(this.onDestroy)
        ).subscribe();
    }

    commentIconHover(events) {
        this.tileData.commentIconHover.next(events);
    }

    commentIconLeave() {
        this.tileData.commentIconLeave.next();
    }

    commentHover(value) {
        value.event.relationships.series_list.data.forEach((series) => {
            this.hovering_events.push(series.id);
        });
    }

    commentLeave(value) {
        this.hovering_events = [];
    }

    toggleComments(show) {
        this.headerData.toggleCommentPanel(true, {tileData: this.tileData, eventService: this.eventService});
        this.eventService.setShowComments(show);
    }

    private getEvents(): Observable<any> {
        const ctrl = this;
        this.series_events = {};
        return this.eventService.getEvents(new Date(this.dateInst.dtp.start), new Date(this.dateInst.dtp.end),
            this.summary.map((series) => this.series_map[series.ID]), null)
            .pipe(
                tap(data => {
                    this.tileData.events = data.data;
                    data.data.forEach(event => {
                        event.relationships.series_list.data.forEach(series => {
                            if (this.series_events[series.id]) {
                                this.series_events[series.id].push(event);
                            } else {
                                this.series_events[series.id] = [event];
                            }
                        });
                    });
                }),
                first(), takeUntil(this.onDestroy)
            );
    }


    setDescriptorWidth(column, row) {
        let width = 'calc(' + this.format_dict[column].width + 'px';
        if (!this.statusColumns.includes('Name') && column === 'Description') {
            width += " - 40px";
        }
        if (!this.statusColumns.includes('Name') && column === 'Description' && row['kpi_level'] === 'Level 1') {
            width += " - 60px"
        }
        width += ")"
        return width;
    }
}
