import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from "keycloak-angular";
import {AppScope} from "../app_scope.service";

// export class CheckLoginGuard extends KeycloakAuthGuard {
// ToDo to be changed back to the previous name after keycloak_migration
@Injectable()
export class KeycloakCheckLoginGuard extends KeycloakAuthGuard {

    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        private appScope: AppScope
    ) {
        super(router, keycloak);
    }

    async isAccessAllowed(route:  ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            // attempt to login in - never redirect back to the login page from keycloak
            this.appScope.route_before_login = (!state.url.includes('view/login')) ? state.url : '/';
            this.appScope.keycloak_redirect = window.location.origin + this.appScope.route_before_login;

            const redirectUri = this.appScope.keycloak_redirect;
            await this.keycloak.login({redirectUri: redirectUri});
        }
        if (this.authenticated) {
            // if login successful, update appContext
            this.appScope.afterUserLogin().subscribe(res => this.authenticated = res);
        }
        return this.authenticated;
    }
}
//
// export const KeycloakCheckLoginGuard: CanActivateFn =
//     (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
//     const appScope: AppScope = inject(AppScope);
//     const keycloak: KeycloakService = inject(KeycloakService);
//     const router: Router = inject(Router);
//
//     const kc_login = new KeycloakCheckLoginGuardClass(router, keycloak, appScope);
//     return from(kc_login.isAccessAllowed(route, state));
// };
