import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {ComponentType} from "../../_models/component-type";
import {forkJoin, of, Subject, Subscription, Observable} from "rxjs";
import {Component as WireComponent, COMPONENT_BASE_TYPES, COMPONENT_BASE_TYPE_ARRAY} from "../../_models/component";
import {ApiService} from "../../services/api/api.service";
import {catchError, takeUntil, tap, concatMap, map, take} from "rxjs/operators";
import {ListResponse, SingleResponse} from "../../services/api/response-types";
import {CustomEventsService} from '../../services/custom-events.service';
import {ConstantProperty} from '../../_models/constant-property';
import {ClearConstantService} from "../constant-field/clear-constant.service";
import {
    ConstantValue,
    ComponentConstantField,
    GenericConstantApiResponse,
    GenericField, isConstant, isFile, FileField, ConstantField
} from "../../_models/api/generic-constant";
import {GenericConstantDataService} from "../../data/generic-constant-data.service";
import {KeyMap, ModelID} from '../../_typing/generic-types';
import {ConfigColumn} from "../../_typing/config/config-column";
import {NotificationService} from "../../services/notification.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {HandleError, HttpErrorHandler} from "../../services/http-error-handler.service";
import {getAttributeIds} from "../../tables/custom-events-table/utils";
import {UserService} from '../../services/user.service';
import {ComponentEventsTableService} from "../../tables/component-events-table/component-events-table.service";
import {ComponentFormConfig, FormulaPart, NameFormula} from "../../_typing/config/component-form-config";
import {ComponentNameFormulaBuilderService} from "../../services/component-name-formula-builder.service";
import {deepCopy, refreshSubscription} from "../../lib/utils";
import {EXTENDED_NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Component({
    selector: 'component-form',
    templateUrl: './component-form.component.html',
    styleUrls: ['./component-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [ClearConstantService],
    standalone: false
})
export class ComponentFormComponent implements OnInit {
    @Input() config: ComponentFormConfig;
    @Output() component_saved: EventEmitter<{ component: WireComponent; custom_constant_dict: KeyMap<ConstantValue> }> =
        new EventEmitter<{ component: WireComponent, custom_constant_dict: KeyMap<ConstantValue> }>();
    @Output() component_cleared: EventEmitter<void> = new EventEmitter<void>();
    private readonly onDestroy: Subject<void> = new Subject<void>();
    private readonly handleError: HandleError;

    component_type: ComponentType;
    $component_type: Subscription;
    constant_properties: ConstantProperty[];
    cpDict: Record<string, ConstantProperty> = {};
    custom_constant_dict: KeyMap<ConstantValue> = {};
    generic_field_dict: KeyMap<GenericField> = {};

    name: string;
    components: WireComponent[] = [];
    component: WireComponent;
    prop_ids: string[];
    dtp: IDateTimePeriod;

    api_base: COMPONENT_BASE_TYPES = 'component';
    base_types = COMPONENT_BASE_TYPE_ARRAY;
    name_column: ConfigColumn;
    start_column: ConfigColumn;
    end_column: ConfigColumn;
    editable_cols: ConfigColumn[];
    disabled_cols: ConfigColumn[];
    show_editable: boolean = true;
    show_disabled: boolean = false;
    missing: KeyMap<string> = {};

    autoName = false;
    nameFormula: FormulaPart[] = [];
    nameFormulaString: string;
    defaultValueDict: KeyMap<ConstantValue> = {};
    nameFormulaOptions: KeyMap<NameFormula> = {};

    constructor(private api: ApiService,
                private notification: NotificationService,
                private dateInst: DateTimeInstanceService,
                private eventsService: CustomEventsService,
                private clearConstantService: ClearConstantService,
                private constantDataService: GenericConstantDataService,
                private changeDetectorRef: ChangeDetectorRef,
                private userService: UserService,
                private componentEventsService: ComponentEventsTableService,
                private nameBuilder: ComponentNameFormulaBuilderService,
                private customEventsService: CustomEventsService,
                httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ComponentForm');
    }

    ngOnInit(): void {
        this.dateInst.dateTimePeriodChanged$.pipe(take(1)).subscribe(() => {
            this.dtp = this.dateInst.dtp;
        })

        if (!this.config || !this.config.selected_component_type) {
            this.notification.openError("Please select a Component Type for this form.", 10000);
            return;
        }

        this.setUpForm();
    }

    setUpForm() {
        this.api_base = this.config.selected_component_type.attributes.base_type as COMPONENT_BASE_TYPES;
        this.name = this.config.selected_component_type.attributes.name;
        this.components = [];
        this.name_column = this.config.selected_cols.find(c => c.id === 'name');
        this.start_column = this.config.selected_cols.find(c => c.id === 'start_time');
        this.end_column = this.config.selected_cols.find(c => c.id === 'end_time');
        this.prop_ids = this.config.selected_cols.map(item => {
            if (!this.component?.id || !item.disabled) {
                return item.id;
            }
        }).filter(item => !['name', 'start_time', 'end_time'].includes(item));
        this.setDefaultValueDict();

        // Get full component_type from config id
        let $component_type_obs = this.api.component_type.getById(this.config.selected_component_type.id)
            .pipe(tap(result => {
                    this.component_type = result.data;
                })
            );

        let $properties_obs = this.eventsService.getComponentTypeProperties(
            [this.config.selected_component_type.id], this.prop_ids)
            .pipe(tap((result: ListResponse<ConstantProperty>) => {
                    this.constant_properties = result.data;
                    this.constant_properties.forEach(cp => this.cpDict[cp.id] = cp);
                })
            );

        this.$component_type = refreshSubscription(this.$component_type);
        this.$component_type = forkJoin([$component_type_obs, $properties_obs]).pipe(take(1), takeUntil(this.onDestroy),
            concatMap(() => {
                this.resetNameFormula();
                if (this.config.component) {
                    this.component = this.config.component;
                    this.custom_constant_dict = {};
                    return this.getCustomConstants();
                } else {
                    this.clearForm();
                    this.setupFileConstants();
                    return of(null);
                }
            }))
            .subscribe(() => {
                this.groupColumns();
            });
    }

    getNameFormulaString(): string {
        this.nameFormulaString = this.nameBuilder.getNameString(this.nameFormula, this.cpDict);
        return this.nameFormulaString;
    }

    private resetNameFormula() {
        this.nameFormulaOptions = this.component_type.attributes.json?.name_formulas || {};
        let selectedFormula = this.nameFormulaOptions[this.config.selected_name_formula?.key] || this.config.selected_name_formula?.formula;
        if (selectedFormula && !this.config.component) {
            this.autoName = true;
            this.nameFormula = this.nameBuilder.getInitialFormula(deepCopy(selectedFormula), this.defaultValueDict);
            this.getNameFormulaString();
        }
    }

    private groupColumns(): void {
        /**On side panel for new components, all properties should be editable by default**/
        const isDisabled = c => {
            return (c.disabled && this.component?.id) || (c.disabled && c.default_value) ||
            this.generic_field_dict[c.id]?.locked || this.cpDict[c.id]?.attributes.is_calculation
        }
        this.editable_cols = this.config.selected_cols.filter(c => !isDisabled(c));
        this.disabled_cols = this.config.selected_cols.filter(c => isDisabled(c));
    }

    private getCustomConstants(): Observable<GenericConstantApiResponse> {
        const atts = getAttributeIds(this.config.selected_cols);
        return this.constantDataService.getComponentConstants([this.component.id], this.prop_ids, atts).pipe(
            tap((result: GenericConstantApiResponse) => {
                this.generic_field_dict = result.data?.[this.component.id];
                Object.keys(result.data?.[this.component.id])?.forEach(key => {
                    const item = result.data?.[this.component.id][key];
                    if (isConstant(item)) {
                        this.custom_constant_dict[key] = item.value;
                    }
                    if (isFile(item)) {
                        this.custom_constant_dict[key] = item.filename;
                    }
                });
            })
        );
    }

    private setupFileConstants(): void {
        this.config.selected_cols.filter(c => this.cpDict[c.id]).forEach(c => {
            if (this.cpDict[c.id]?.attributes.data_type === 'file') {
                this.generic_field_dict[c.id] = new FileField();
            } else {
                this.generic_field_dict[c.id] = new ConstantField();
                this.generic_field_dict[c.id].data_type = this.cpDict[c.id]?.attributes.data_type;
            }
        })
    }

    changeComplete(event: ConstantValue, property_id: ModelID) {
        if (this.cpDict[property_id].attributes.data_type === 'string' && event) {
            this.custom_constant_dict[property_id] = event.toString().trimRight();
        } else {
            this.custom_constant_dict[property_id] = event;
        }
        if (this.autoName) {
            this.nameFormula = this.nameBuilder.updateValue(this.nameFormula, event, property_id);
            this.getNameFormulaString();
        }
        this.changeDetectorRef.markForCheck();
    }

    canEditData() {
        return this.component?.id ? this.userService.canEditComponent(this.api_base) : this.userService.canCreateComponent(this.api_base);
    }

    save() {
        if (!this.validate()) return;
        if (this.autoName) {
            this.component.attributes.name = this.nameFormulaString;
        }
        let $sav_obs;
        this.component.attributes.name = this.component.attributes.name.trim();
        if (!this.component.id) {
            $sav_obs = this.api[this.component.attributes.base_type].obsSave(this.component);
        } else {
            $sav_obs = this.api[this.component.attributes.base_type].obsPatch(this.component);
        }
        $sav_obs.pipe(
            concatMap((component: SingleResponse<WireComponent>) => {
                const component_id = component.data?.id;
                let component_constants = [];

                Object.keys(this.custom_constant_dict).forEach(prop_id => {
                    if ((this.custom_constant_dict[prop_id] || this.custom_constant_dict[prop_id] === 0) &&
                        this.cpDict[prop_id]?.attributes.data_type !== 'file') {
                        component_constants.push(new ComponentConstantField(component_id, prop_id, this.custom_constant_dict[prop_id]));
                    }
                })

                if (component_constants?.length) {
                    return this.constantDataService.saveComponentConstants(component_constants).pipe(map(() => component.data));
                }
                return of(component.data);
            }),
            tap((component: WireComponent) => {
                this.component_saved.next({component: component, custom_constant_dict: this.custom_constant_dict});

                this.componentEventsService.$componentSaved.next({
                    component: component,
                    custom_constant_dict: this.custom_constant_dict
                });
                this.component = component;
                this.notification.openSuccess(`Component saved successfully. Please clear the form before creating a new ${this.component_type.attributes.name}.`, EXTENDED_NOTIFICATION_DURATION_MS);
            }),
            takeUntil(this.onDestroy),
            catchError(this.handleError<SingleResponse<WireComponent>>('Save component'))
        ).subscribe();
    }

    validate(): boolean {
        let allow_save = true;
        this.missing = {};
        this.config.selected_cols.filter(c => this.isRequired(c)).forEach(c => {
            if (((c.type === 'constant_property' || this.cpDict[c.id]) && !this.custom_constant_dict[c.id] && this.custom_constant_dict[c.id] !== 0) ||
                (c.id === 'start_time' && !this.component.attributes.start_time) ||
                (c.id === 'end_time' && !this.component.attributes.end_time)) {
                this.missing[c.id] = c.title || c.column_name || c.name;
                allow_save = false;
            }
        })
        if (!allow_save) {
            this.notification.openError("The following required items are missing: " + Object.values(this.missing).join(", "))
        }
        if (!this.customEventsService.checkStartAndEndDates(this.component, 'start_time', 'end_time')) {
            allow_save = false;
        }
        return allow_save;
    }

    isRequired(c: ConfigColumn) {
        const isCalculation = this.cpDict[c.id]?.attributes?.is_calculation;
        const isNew = !this.component?.id;
        return c.required && !isCalculation && (!c.disabled || isNew)
    }

    isNameComplete(): boolean {
        return this.autoName && this.nameFormula.filter(f => !f.value).length === 0;
    }

    revalidateField($event, field: ConfigColumn) {
        this.missing[field.id] = $event ? null : (field.title || field.column_name || this.cpDict[field.id]?.attributes.name);
    }

    clearForm() {
        this.component = new WireComponent(this.api_base as COMPONENT_BASE_TYPES);
        this.custom_constant_dict = {};
        this.component.relationships.component_type = {data: {id: this.component_type.id, type: 'component_type'}};
        this.constant_properties.forEach((cp: ConstantProperty) => {
            this.custom_constant_dict[cp.id] = this.defaultValueDict[cp.id];
        });
        this.clearConstantService.clearConstants();
        this.config.component = null;
        this.resetNameFormula();
        this.component_cleared.next();
        this.missing = {};
    }

    private setDefaultValueDict(): void {
        this.config.selected_cols.forEach(col => {
            if (col.default_value || col.default_value === 0) {
                this.defaultValueDict[col.id] = col.default_value;
            }
        })
    }
}
