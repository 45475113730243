import {Injectable} from "@angular/core";
import {BasePaginatedTableService} from "../../tables/base-paginated-table.service";
import {ApiService} from '../../services/api/api.service';
import {GenericDataService} from "../../data/generic-data.service";
import {UserDataService} from '../../data/user-data.service';
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {ModelRelationshipNameDict} from "../../services/api/filter_utils";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ListResponse} from "../../services/api/response-types";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {takeUntil, tap} from "rxjs/operators";
import {Observable, ReplaySubject} from "rxjs";
import {Group} from "../../_models/group";
import {SessionState} from '../../_models/session-state';
import {IDObject, ModelID, RelationshipName} from '../../_typing/generic-types';
import {User} from "../../_models/users";
import {NotificationService} from "../../services/notification.service";

@Injectable()

export class RelationshipUserSelectorService extends BasePaginatedTableService<User> {
    columns = ['name', 'alias'].concat(this.audit_columns);
    search_keys = ['name', 'alias'];
    user_list: User[];

    public readonly selectedRelUsersSubject: ReplaySubject<{ id: ModelID }[]> = new ReplaySubject(1);

    constructor(private userDataService: UserDataService,
                notification: NotificationService,
                relMapping: RelationshipApiMappingService,
                api: ApiService,
                genericData: GenericDataService
    ) {
        super(genericData, api, notification, relMapping);
    }

    initialise(model: Group | SessionState) {
        this.this_type = 'users';
        this.getRelationshipMappingRows(model);

        const initialQuery: SearchQueryOptions = this.getInitialSearchOptions(model);
        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<User>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            tap((result: ListResponse<User>) => {
                this.user_list = result.data;
                this.dataSourceSubject.next(this.dataSource);
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
    }

    save(selectedUserList: IDObject[], model: Group | SessionState) {
        if (this.hasChanges(selectedUserList)) {
            this.upsertModelRelationshipMap(selectedUserList, model);
        }
    }

    private getInitialSearchOptions(model: Group | SessionState): SearchQueryOptions {
        const options = new SearchQueryOptions(this.page_size, 1, 'name');
        options.filters = this.generateUsersByRelationshipFilter(model.id, ModelRelationshipNameDict.users[model.type]);
        this.initial_filters = options.filters;
        return options;
    }

    refreshDataSource(model: Group | SessionState) {
        const filters = this.generateUsersByRelationshipFilter(model.id, ModelRelationshipNameDict.users[model.type]);
        this.dataSource.filterBy(filters);
    }

    generateUsersByRelationshipFilter(model_id: ModelID, rel_name: RelationshipName) {
        return this.userDataService.generateUsersByRelationshipIdFilter(rel_name, model_id);
    }

    page(query: SearchQueryOptions): Observable<ListResponse<User>> {
        return super.page(query, 'users');
    }

    userListChanged(list: User[]) {
        this.user_list = list;
    }

}
