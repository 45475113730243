import {Injectable, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {ModelAttribute} from '../_typing/generic-types';
import {GenericDataService} from './generic-data.service';

@Injectable({
    providedIn: 'root'
})
export class UserDataService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constructor(private genericData: GenericDataService) {

    }

    generateFilteredUsersFilter(filter: string, keys: ModelAttribute[]) {
        return this.genericData.generateFilteredModelFilter(filter, keys);
    }

    generateUsersByRelationshipIdFilter(rel_name: string, rel_value: string) {
        return this.genericData.generateModelsByRelationshipIdFilter(rel_name, rel_value);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
