import {Component, EventEmitter, Input, Output} from '@angular/core';
import {KeyMap, ModelID} from "../../../../_typing/generic-types";
import {Field, FieldMap, QueryBuilderConfigType, Rule, ValidationCompareType} from "../../../../_typing/query-builder";
import {BaseComponent} from "../../../../shared/base.component";
import {QueryBuilderFormService} from "../../../../services/query-builder-form.service";

@Component({
    selector: 'query-value',
    templateUrl: './query-value.component.html',
    styleUrls: ['./query-value.component.less'],
    standalone: false
})
export class QueryValueComponent extends BaseComponent {

    @Input() modelTypeId: ModelID;
    private _operator: string;
    @Input() set operator(operator: string) {
        if (this._operator) {
            this.compareBlank = this.operatorDesc?.[operator]?.toLowerCase().includes('blank');
        }
        this._operator = operator;
    };

    get operator(): string {
        return this._operator;
    }

    @Input() rule: Rule;
    @Input() readonly fields: FieldMap;
    private _selectedField: Field;
    @Input() set selectedField(selectedField: Field) {
        this._selectedField = selectedField;
        if (selectedField) {
            this.getFieldsByType();
        }
    };

    get selectedField(): Field {
        return this._selectedField;
    }

    readonly compareTypeDict = {
        string: ['input'],
        number: ['input'],
        date: ['input', 'field'],
        datetime: ['input', 'field'],
        category: ['input'],
        boolean: ['input'],
        dropdown: ['input']
    };
    readonly fieldsByTypeDict = {
        string: ['string', 'category'],
        number: ['number'],
        date: ['date', 'datetime'],
        datetime: ['date', 'datetime'],
        category: ['string', 'category'],
        boolean: ['boolean'],
        dropdown: ['string', 'number', 'category']
    }
    fieldsByType = {};
    operatorDesc: KeyMap<string>;
    compareBlank: boolean

    constructor(private queryBuilderFormService: QueryBuilderFormService) {
        super();
    }

    ngOnInit() {
        if (!this.rule.value_compare_to) {
            this.rule.value_compare_to = 'input';
        }
        this.getFieldsByType();
        this.operatorDesc = this.queryBuilderFormService.operatorDesc;
        this.compareBlank = this.operatorDesc[this.operator]?.toLowerCase().includes('blank');
    }

    getFieldsByType() {
        Object.keys(this.fieldsByTypeDict).forEach(type => {
            this.fieldsByType[type] = Object.values(this.fields).filter(f => {
                return this.fieldsByTypeDict[type].includes(f.type) && this.selectedField?.value !== f.value;
            })
        })
    }

    onChangeCompareType($event) {
        this.rule.value_compare_to = $event;
    }

    protected readonly ValidationCompareType = ValidationCompareType;
}
