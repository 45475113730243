import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AppScope} from "../../services/app_scope.service";

@Component({
    selector: 'flowchart-series-component',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'flowchart_series_component.component.html',
    styleUrls: ['flowchart.less'],
    standalone: false
})

export class FlowchartSeriesComponentComponent implements OnInit {
    @Input() group: any;
    @Input() groups_selected: any = {};
    @Input() series: any;

    constructor(public appScope: AppScope,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
    }
}
