import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {solverMethods, SolverTemplate} from '../../_models/solver';
import {SeriesType} from '../../_models/series-type';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import { HttpClient } from "@angular/common/http";
import {BaseComponent} from '../../shared/base.component';


export interface SolverDialogData {
    solverTemplate: SolverTemplate;
}

@Component({
    selector: 'solver-form',
    templateUrl: './solver-form.component.html',
    styleUrls: ['../solver-template-form/solver-template-form.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolverFormComponent extends BaseComponent {
    solverTemplate: SolverTemplate;
    solverMethods: string[] = solverMethods;
    storeType: SeriesType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SolverDialogData,
        private api: ApiService,
        public dialogRef: MatDialogRef<SolverFormComponent>,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private dateTimePeriodService: DateTimePeriodService
    ) {
        super();
        this.solverTemplate = this.data.solverTemplate;
    }

    ngOnInit() {
        this.solverTemplate = this.data.solverTemplate;
    }

    onCloseClick(): void {
        this.dialogRef.close(this.solverTemplate);
    }
}
