/**This class type is for saving the stub of an object in the form config (json)
 * It 'throws' out all attributes/relationships not defined by the interfaces below
 */

export interface IConfigStubAttributes {
    name: string;
    description: string;
    alias: string;
    base_type: string;
}

export interface IConfigStub {
    id: string;
    type: string;
    attributes: Partial<IConfigStubAttributes>;
}

export class ConfigStubAttributes<T> implements Partial<IConfigStubAttributes> {
    name: string;
    description?: string;
    alias?: string;
    base_type: string;

    constructor(atts) {
        this.name = atts?.name;
        this.description = atts?.description;
        if (atts?.alias) this.alias = atts?.alias;
        if (atts?.base_type) this.base_type = atts?.base_type;
    }
}

export class ConfigStub<T> implements Partial<IConfigStub> {
    id: string = '';
    type: string = '';
    attributes: Partial<IConfigStubAttributes> = {};

    constructor(obj?) {
        this.id = obj?.id || '';
        this.type = obj?.type || '';
        this.attributes = new ConfigStubAttributes<T>(obj?.attributes || {});
    }

    static getConfigStubs<T>(obj_list: ConfigStubType<T>[]): ConfigStub<T>[] {
        return obj_list.map((obj: ConfigStub<T>) => new ConfigStub(obj));
    }
}

export type ConfigStubType<T> = T | ConfigStub<T>;
