import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {EventType} from '../../_models/event-type';
import {ApiService} from '../../services/api/api.service';
import {first} from "rxjs/operators";
import {OreBodyType} from '../../_models/ore-body-type';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {EventTypeOreBodyTypeMap} from "../../_models/event-type-ore-body-type-map";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {uniqueList} from "../../lib/utils";
import {getRelationWithManyIdsFilter} from "../../services/api/filter_utils";

@Component({
    selector: 'source-destination-table-form',
    templateUrl: './source-destination-table-form.component.html',
    styleUrls: ['./source-destination-table-form.component.less'],
    standalone: false
})
export class SourceDestinationTableFormComponent implements OnInit {
    @Output() mapping_changed: EventEmitter<{ event_type: EventType, ore_body_type: OreBodyType, source: boolean, destination: boolean }> = new EventEmitter();
    @Input() event_types: EventType[];
    @Input() ore_body_types: OreBodyType[];
    @Input() pivot: boolean;
    ready: boolean = false;
    source: boolean = false;
    destination: boolean = false;
    table_data: { [key: string]: EventTypeOreBodyTypeMap }; // {ore_body_type: OreBodyType, event_type: EventType, source: boolean, destination: boolean, mapping_id: string;}[]

    private readonly onDestroy = new Subject<void>();

    constructor(private notification: NotificationService,
                private api: ApiService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.event_types && this.ore_body_types) {
            this.ready = false;
            this.setTable();
        }
    }

    ngOnInit() {
        const ctrl = this;

    }

    refreshMappingData() {
        const ctrl = this;
        const options = new SearchQueryOptions();
        options.filters = [
            getRelationWithManyIdsFilter('event_type', uniqueList(this.event_types.map(et => et.id))),
            getRelationWithManyIdsFilter('ore_body_type', uniqueList(this.ore_body_types.map(obt => obt.id)))
        ];
        this.api.event_type_ore_body_type_map.searchMany(options).pipe(first()).subscribe(results => {
            ctrl.mapTable(results.data);
        });
    }

    setTable() {
        const ctrl = this;
        ctrl.table_data = {};
        ctrl.refreshMappingData();
    }

    mapTable(data: EventTypeOreBodyTypeMap[]) {
        const ctrl = this;
        data.forEach(item => {
            let combo_id = item.relationships.event_type.data.id + item.relationships.ore_body_type.data.id;
            ctrl.table_data[combo_id] = item;
        });
        ctrl.ready = true;
    }

    submitChange(event: MatCheckboxChange, event_type: EventType, ore_body_type: OreBodyType, type: string) {
        let combo_id = event_type.id + ore_body_type.id;
        const item = this.table_data[combo_id];
        if (!item) {
            console.warn('No mapping found for Event Type ' + event_type + ' and Ore Body Type ' + ore_body_type);
            return;
        }

        if (type === 'source') {
            item.attributes.source = event.checked;
        } else {
            item.attributes.destination = event.checked;
        }
        this.api.event_type_ore_body_type_map.patch(item).then(result => {
            if (result) {
                this.notification.openSuccess("Mapping saved.", 2000);
            }
        }, error => {
            console.log('Error saving event-type-ore-body-type-map : ', error);
            this.notification.openError("Error saving this Mapping: " + error);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
