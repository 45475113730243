import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {KeyMap, ModelID} from "../../_typing/generic-types";
import {ConstantProperty} from "../../_models/constant-property";
import {ComponentType} from "../../_models/component-type";
import {EventConfigColumn} from "../../_typing/config/config-column";
import {ColumnFormatsConfig, ColumnFormatsConfigDict} from "../../forms/table-column-menu/table-column-menu.component";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {BaseComponent} from "../../shared/base.component";

export interface EventColumnSelectorEmitter {
    available_columns: EventConfigColumn[];
    selected_columns: EventConfigColumn[];
    new_column_id: string | ModelID;
}

@Component({
    selector: 'event-column-selector',
    templateUrl: './event-column-selector.component.html',
    styleUrls: ['./event-column-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventColumnSelectorComponent extends BaseComponent {
    @Input() model_dict: KeyMap<ConstantProperty | ComponentType>
    @Input() available_columns: EventConfigColumn[];
    @Input() selected_columns: EventConfigColumn[] = [];
    @Input() formats_dict: ColumnFormatsConfigDict = {};
    @Input() allowDefaults: boolean = false;
    @Input() disabledByDefault: boolean = true;

    @Output() columns_changed = new EventEmitter<Partial<EventColumnSelectorEmitter>>();
    @Output() formats_dict_changed = new EventEmitter<ColumnFormatsConfigDict>();

    private _colMenuOptions = ['bold', 'italic', 'size', 'resize', 'fit_content', 'colour', 'background_colour', 'aggregations', 'decimals', 'align'];
    @Input() set colMenuOptions(options: string[]) {
        if (!options) {
            this._colMenuOptions = ['bold', 'italic', 'size', 'resize', 'fit_content', 'colour', 'background_colour', 'aggregations', 'decimals', 'align'];
        } else {
            this._colMenuOptions = options;
        }
        //this.changeDetectorRef.markForCheck();
    }

    get colMenuOptions(): string[] {
        return this._colMenuOptions;
    }

    default_format_values: ColumnFormatsConfig = {resize: true};
    availableColumnFilterValue: string = '';
    selectedColumnFilterValue: string = '';

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    moveColumns(move: 'select' | 'remove'): void {
        if (move === 'select') {
            this.selected_columns = this.selected_columns.concat(this.available_columns);
            //Implement if needed: this.avail_cols.map(col => this.addNonEditableDefault('selectedList', col));
            this.available_columns = [];
        } else {
            this.available_columns = this.available_columns.concat(this.selected_columns)
            this.selected_columns = [];
        }

        this.columns_changed.emit({available_columns: this.available_columns, selected_columns: this.selected_columns});
        this.changeDetectorRef.markForCheck();
    }

    drop(event: CdkDragDrop<EventConfigColumn[]>): void {
        let addedColumnId: string | ModelID;
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        } else {
            // use item.index set by columnFilter instead of event.previousIndex which reflects index of item in the displayed list
            // and may not be the actual index in the container.data list
            let prevIndex = event.item.data.index || event.previousIndex;

            event.item.data.disabled = this.disabledByDefault;
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                prevIndex,
                event.currentIndex);
            addedColumnId = event.container.data[event.currentIndex]['id']
        }

        // update objects reference so that filter pipe is called
        this.available_columns = Object.assign([], this.available_columns);
        this.selected_columns = Object.assign([], this.selected_columns);
        this.columns_changed.emit({
            available_columns: this.available_columns,
            selected_columns: this.selected_columns,
            new_column_id: addedColumnId
        });
        this.changeDetectorRef.markForCheck();
    }

    formatsChanged(event) {
        this.formats_dict_changed.emit(event);
        this.changeDetectorRef.markForCheck();
    }

    columnFilter(item, value, index): boolean {
        // store item index for use in determine actual position in the items list
        item.index = index;
        if (item.type === 'attribute') {
            return item.id.toLowerCase().includes(value.toLowerCase()) || item.title?.toLowerCase().includes(value.toLowerCase());
        }
        if (this.model_dict?.[item.id]) {
            return this.model_dict[item.id].attributes.name.toLowerCase().includes(value.toLowerCase());
        }
    }
}
