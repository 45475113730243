<div class="row" style="justify-content: space-between;">
  <h2>{{title | removeUnderscore}}</h2>
  <div *ngIf="selected_pages" style="width:300px;">
    <select-many-pages
        (selectionChangeComplete)="pagesSelected($event)"
        [value]="selected_pages"
        [custom_selected_filters]="custom_selected_filters"
        [custom_filters]="reverse_custom_selected_filters"
        [filter_by]="['name']"
        [label]="'Select Pages'">
    </select-many-pages>
  </div>
</div>
<div class="row end space-between medium-height">
  <div *ngIf="dataSource" class="table-filter">
    <input (keyup)="applyFilter.next($event.target.value)" [value]="filter_string" placeholder="Search pages"/>
  </div>
  <mat-paginator showFirstLastButtons
                 style="justify-self: flex-end;">
  </mat-paginator>
</div>
<div class="table-container">
  <table [dataSource]="dataSource"
         [matSortActive]="'name'"
         (matSortChange)="updateSort($event)"
         class="table user-table"
         mat-table matSort matSortDirection="asc">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
      <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
        <ng-container>{{column | titlecaseForWire | removeUnderscore}}</ng-container>
      </th>
      <td *matCellDef="let element;"
          mat-cell
          style="text-align: left;border:none;">
        <ng-container
            *ngIf="['page_link'].includes(column)">
          <a [routerLink]="'/view/page_view/' + element.id" target="_blank">View page</a>
        </ng-container>
        <ng-container
            *ngIf="['created_on', 'changed_on'].includes(column)">{{element.attributes[column] | stringDate }}
        </ng-container>
        <ng-container
            *ngIf="!['page_link', 'created_on', 'changed_on'].includes(column)">{{element.attributes[column]}}
        </ng-container>

      </td>
    </ng-container>

    <ng-container>
      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </ng-container>
  </table>
</div>
