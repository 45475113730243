 <mat-tree [dataSource]="staticFolders" [treeControl]="treeControl" class="menu sidenav-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li *ngIf="node.type === 3" class="mat-tree-node menu-node"><!--static link-->
        <a [routerLink]="node.link"
           (click)="closeSidenav()">{{node.title}}</a>
      </li>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="static-folder">
      <li>
        <div *ngIf="node.type === 2"
             class="mat-tree-node parent-folder-node" matTreeNodeToggle>
          <button class="folder-toggle" matTreeNodeToggle>
            <i [ngClass]="treeControl.isExpanded(node) ? 'chevron-up' : 'chevron-down'"></i>
            <span *ngIf="node.type === 2">{{node.title}}</span>
          </button>
        </div>
        <ul [class.menu-hidden]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>