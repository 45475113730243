import {Injectable, OnDestroy} from "@angular/core";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {IDObject, ModelID} from '../../_typing/generic-types';
import {UserDataService} from '../../data/user-data.service';
import {PageDataService} from '../../data/page-data.service';
import {BasePaginatedTableService} from "../../tables/base-paginated-table.service";
import {ApiService} from '../../services/api/api.service';
import {GenericDataService} from "../../data/generic-data.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ListResponse} from "../../services/api/response-types";
import {takeUntil, tap} from "rxjs/operators";
import {PaginationDataSource} from "../../services/api/pagination-data-source";
import {Group} from "../../_models/group";
import {SessionState} from '../../_models/session-state';
import {ModelRelationshipNameDict} from '../../services/api/filter_utils';
import {RelationshipApiMappingService} from "../../data/relationship-api-mapping.service";
import {User} from "../../_models/users";
import {NotificationService} from "../../services/notification.service";
@Injectable()
export class RelationshipPageSelectorService extends BasePaginatedTableService<SessionState> {
    columns = ['name'].concat(this.audit_columns);
    page_list: SessionState[];
    search_keys = ['name'];
    private original_page_list: SessionState[] = []; // Track the original state of the page list for change detection

    public readonly selectedRelPagesSubject: ReplaySubject<{ id: ModelID }[]> = new ReplaySubject(1);

    constructor(private pageDataService: PageDataService,
                notification: NotificationService,
                relMapping: RelationshipApiMappingService,
                api: ApiService,
                genericData: GenericDataService
    ) {
        super(genericData, api, notification, relMapping);
    }

    initialise(model: Group | User, by_group_user: boolean = false, by_owner: boolean = false) {
        this.this_type = 'session_state';
        if (!(by_group_user || by_owner)) {
            this.getRelationshipMappingRows(model);
        }
        if (by_owner) {
            this.columns = ['name', 'page_link'].concat(this.audit_columns);
        }

        const initialQuery: SearchQueryOptions = this.getInitialSearchOptions(model, by_group_user, by_owner);
        this.paginator.pageSize = this.page_size;
        this.dataSource = new PaginationDataSource<SessionState>(
            (query) => this.page(query),
            initialQuery,
            this.paginator,
            this.sort
        );
        this.dataSource.$page.pipe(
            tap((result: ListResponse<SessionState>) => {
                this.page_list = result.data;
                this.original_page_list = [...result.data];
                this.dataSourceSubject.next(this.dataSource);
            }),
            takeUntil(this.onDestroy)
        ).subscribe();
    }

    public save(selectedPages: IDObject[], model, refresh = true) {
        if (this.hasChanges(selectedPages)) {
            this.upsertModelRelationshipMap(selectedPages, model, refresh);
        }
    }

    private getInitialSearchOptions(model: Group | User, by_group_user: boolean, by_owner: boolean): SearchQueryOptions {
        const options = new SearchQueryOptions(this.page_size, 1, 'name');
        if (by_group_user) {
            options.filters = this.generatePagesByGroupUserIdFilter(model.id);
        } else if (by_owner) {
            options.filters = this.generatePagesByOwnerId(model.id);
        } else {
            options.filters = this.generatePagesByRelationshipFilter(model.id, ModelRelationshipNameDict.session_state[model.type]);
        }
        this.initial_filters = options.filters;
        return options;
    }

    refreshDataSource(model: Group | SessionState) {
        const filters = this.generatePagesByRelationshipFilter(model.id, ModelRelationshipNameDict.session_state[model.type]);
        this.dataSource.filterBy(filters);
    }

    generatePagesByRelationshipFilter(id: ModelID, rel_name) {
        return this.pageDataService.generatePagesByRelationshipIdFilter(rel_name, id);
    }

    generatePagesByGroupUserIdFilter(id: ModelID) {
        return this.pageDataService.generatePagesByRelationshipByUserIdFilter('groups', id);
    }

    generatePagesByOwnerId(id: ModelID) {
        return this.pageDataService.generatePagesByOwnerId(id);
    }

    page(query: SearchQueryOptions): Observable<ListResponse<SessionState>> {
        return super.page(query, 'session_state')
    }

    pageListChanged(list: SessionState[]) {
        this.page_list = list;
    }

}
