import { Component, OnInit } from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import {HeaderDataService} from "../../services/header_data.service";

@Component({
    selector: 'src-forbidden-view',
    templateUrl: './forbidden-view.component.html',
    styleUrls: ['./forbidden-view.component.css'],
    standalone: false
})
export class ForbiddenViewComponent implements OnInit {

    constructor(private appScope: AppScope,
                private headerData: HeaderDataService) {
    }

    ngOnInit() {
      this.headerData.title = 'Missing authorization';
      this.headerData.add_new_page_form = true;
    }
}
