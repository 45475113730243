<div class="page traveler">
  <div class="row center form-dialog audit-history-table">
    <select-many-filter
        [label]="'Select Audited Models'"
        (constant_changed)="onModelSelect($event)"
        [filter_compare]="modelCompare"
        [modelValue]="selected_models"
        [options]="auditedModelOptions"
        [placeholder]="'Select audit models'"
        [classes]="'fill'"
        class="audit-history-series-select"
    >
    </select-many-filter>
    <button (click)="selected_models=[]" matTooltip="Clear selected audit models" mat-raised-button
            class="clear-button form-element-align" color="primary">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <history-table [resourceList]="selected_models"></history-table>
</div>
