import {Component, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "../services/api/api.service";
import {AppScope} from "../services/app_scope.service";
import {HeaderDataService} from "../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {PlantDataService} from "../services/plant-data/plant_data.service";
import {EventService} from "../services/event.service";
import {UserData} from "../services/user_data.service";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {forkJoin, from, Subject, timer, take, of} from "rxjs";
import {concatMap, finalize, switchMap, takeUntil, tap} from "rxjs/operators";
import {Tile as TileModel} from "../_models/tile";
import {DateTimeInstanceService} from "../services/date-time-instance.service";

@Component({
    selector: 'input-data-sheet-focus-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="input_sheet_ready" [dtp]="dateInst.dtp" [tile]="tile" (tileChange)="tileChange($event)" class="column-tile tile-dtp"></page-tile>' +
        '</div>',
    standalone: false
})
export class InputDataSheetFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    tile: TileModel;
    // refresh: boolean;
    process: any;
    super_user: boolean;
    input_sheet_ready: boolean;
    events_ready: boolean;

    constructor(private api: ApiService,
                private appScope: AppScope,
                private headerData: HeaderDataService,
                private route: ActivatedRoute,
                private plantData: PlantDataService,
                private eventService: EventService,
                private userData: UserData,
                private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService) {
    }

    ngOnInit(): void {
        this.route.params.pipe(takeUntil(this.onDestroy), switchMap(params => {
            this.input_sheet_ready = false;
            this.headerData.buttons = [];

            this.tile = new TileModel();
            this.tile.attributes.content = 'input-sheet';
            this.tile.attributes.hide_edit = true;

            const observables = [];
            observables.push(this.api.process.getById(params.processID).pipe(tap(response => {
                return this.process = response.data;
            })));
            //observables.push(from(this.dateTimePeriodService.dtpInitialisedPromise.promise));
            observables.push(this.dateInst.dateTimePeriodInitialised$.pipe(take(1)));

            return forkJoin(observables).pipe(tap(() => {
                // this.access = this.plantData.getIsolatedPermissions(this.process.id);
                this.plantData.setActiveProcess(this.process);
                this.dateInst.dtp = this.dateTimePeriodService.setDefault(this.dateInst.dtp);

                this.tile.attributes.parameters = {
                    process: this.process,
                    // title: this.process.attributes.description ? this.process.attributes.description : this.process.attributes.name
                };
                this.userRefresh();
                this.eventsRefresh();

                this.buildHeader();

                this.super_user = this.userData.checkSuperUser(this.appScope.current_user);

                this.input_sheet_ready = true;
            }));
        })).subscribe();

            this.headerData.refreshTileSubject.pipe(switchMap(id => {
                this.input_sheet_ready = false;
                return timer(100).pipe(tap(() => {
                    this.input_sheet_ready = true;
                }))
            }), takeUntil(this.onDestroy)).subscribe();
        }

        ngOnDestroy()
    :
        void {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }

        userRefresh()
        {
            this.userData.report = "Input" + this.process.id;
        }

        eventsRefresh()
        {
            // TODO not sure where 'series_list' comes from
            // TODO implement this method
            // FIXME this will do nothing as the events are not subscribed to
            this.eventService.getEvents(this.dateInst.dtp.start, this.dateInst.dtp.end, null, [this.process]);
            this.events_ready = false;

            setTimeout(() => {
                this.events_ready = true;
            }, 1000);
        }

        refreshDates()
        {
            // TODO replace refresh with migrated version of refreshing
            this.eventsRefresh();
        }

        buildHeader()
        {
            this.headerData.title = 'Data Review:' + this.process.attributes.name;
            this.headerData.setPath(this.process);
            this.headerData.show_dtp = true;
        }

    }
