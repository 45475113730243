import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {MatSelect} from "@angular/material/select";
import {refreshSubscription} from "../../lib/utils";
import {BaseComponent} from "../../shared/base.component";
import {Account} from "../../_models/account";
import {ModelID} from "../../_typing/generic-types";

@Component({
    selector: 'option-list-search',
    templateUrl: './option-list-search.component.html',
    styleUrls: ['./option-list-search.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OptionListSearchComponent extends BaseComponent {

    constructor() {
        super();
    }

    @Input() placeholder: string = "Search";
    @Input() delay: number = 0;
    @Input() selectElementRef!: MatSelect;
    @Output() inputChanged = new EventEmitter<string>();
    @Output() inputCleared = new EventEmitter<void>();
    @Output() relayAccountsSelected = new EventEmitter<Account[]>();
    @Input() allowAccountSelection: boolean = false;
    @Input() accountIds: ModelID[]
    @ViewChild('searchInput') searchInput!: ElementRef; // Reference to the input element

    private inputSubject = new Subject<string>(); // Subject to handle input events
    filterString: string = "";
    allowedInputKeys: string[] = ['ArrowDown', 'ArrowUp', 'Enter'];
    $optionChanges: Subscription;

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;

    ngOnInit() {
        this.inputSubject.pipe(
            debounceTime(this.delay) // Apply the debounce time
        ).subscribe(value => {
            this.inputChanged.next(value);
        });
        this.openedChangeSubscribe();
    }

    openedChangeSubscribe() {
        if (this.selectElementRef) {
            this.addPanelClass('with-search');

            this.selectElementRef.openedChange.pipe(
                takeUntil(this.onDestroy)
            ).subscribe((e: boolean) => {
                if (e === true) {
                    this.focusInput();
                    this.optionsListSubscribe();
                } else {
                    this.relayCleared();
                    this.$optionChanges = refreshSubscription(this.$optionChanges);
                }
            });
        }
    }

    optionsListSubscribe() {
        this.$optionChanges = refreshSubscription(this.$optionChanges);
        this.$optionChanges = this.selectElementRef.options.changes.pipe(
            debounceTime(this.delay) // Apply the debounce time
        ).subscribe(list => {
            if (list.length > 0) {
                this.selectElementRef._keyManager.setFirstItemActive();
            }
        });
    }

    handleKeydown(event) {
        if (this.selectElementRef && this.isValidInput(event.key)) {
            event.preventDefault();
            // Pass focus back to the mat-select element
            if (event.key === 'ArrowDown') {
                this.selectElementRef.focus();
            } else if (event.key === 'Enter') {
                this.selectElementRef._keyManager.activeItem._selectViaInteraction();
            }
        }
    }

    isValidInput(key): boolean {
        return this.allowedInputKeys.includes(key);
    }

    filterByAccount($event) {
        this.relayAccountsSelected.emit($event);
    }

    relayInput() {
        this.inputSubject.next(this.filterString);
    }

    relayCleared() {
        this.filterString = "";
        this.inputCleared.next();
    }

    focusInput() {
        this.filterString = "";
        this.searchInput.nativeElement.focus();
    }

    private addPanelClass(newClass: string) {
        if (this.selectElementRef) {
            const currentClasses = this.selectElementRef.panelClass;

            if (typeof currentClasses === 'string') {
                // If it's a string, split it into an array
                const classArray = currentClasses.split(' ').filter(c => c.trim() !== '');
                this.selectElementRef.panelClass = [...classArray, newClass];
            } else if (Array.isArray(currentClasses)) {
                // If it's already an array, just add the new class
                this.selectElementRef.panelClass = [...currentClasses, newClass];
            } else {
                // If it's undefined or null, start a new array
                this.selectElementRef.panelClass = [newClass];
            }
        }
    }

    ngOnDestroy() {
        this.relayCleared();
    }
}
