import {ChangeDetectorRef, NgZone, Pipe, PipeTransform} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {Observable} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {map, tap} from "rxjs/operators";
import {ModelID, ModelName} from "../../_typing/generic-types";

@Pipe({name: "getName", standalone: true, pure: true})
export class GetNamePipe implements PipeTransform {
    constructor(private api: ApiService,
                private ref: ChangeDetectorRef,
                private zone: NgZone) {
    }

    transform(value: ModelID | null, model: string): Observable<ModelName> | null {
        if (!value) {
            return;
        }

        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'eq', val: value}];
        return this.api[model].getName(value).pipe(
            map((name: ModelName) => {
                return name?.replace(/_/g, " ");
            }),
            tap((response: ModelName) => {
                this.zone.run(() => {
                    this.ref.markForCheck();
                });
            }));
    }
}
