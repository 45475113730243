<div *ngIf="breadcrumbs?.length>0" class="breadcrumbs">
  <span matTooltip="Doublc click path to copy" [dblClickCopy]="breadcrumbs[0]">{{breadcrumbs[0]}}</span>
  <div *ngIf="breadcrumbs.length>1" class="alt">
    <i [matMenuTriggerFor]="menu" class="fas fa-list-alt" matTooltip="Alternate paths"></i>
    <mat-menu #menu="matMenu" class="breadcrumbs-menu">
      <div *ngFor="let bc of breadcrumbs | slice:1" matTooltip="Doublc click path to copy"
           [dblClickCopy]="bc">{{bc}}</div>
    </mat-menu>
  </div>
  <i (click)="breadcrumbs=[]" class="fa fa-times" matTooltip="Hide"></i>
</div>
