import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'pivottable/dist/pivot';
import '../../../../node_modules/pivottable/dist/c3_renderers';
import '../../../../node_modules/pivottable/dist/d3_renderers';
import '../../../../node_modules/pivottable/dist/export_renderers';
import {HotInstance} from "../../services/hot-instance";
import {CommonModule} from "@angular/common";

@Component({
    // standalone: true,
    // imports: [
    //     CommonModule
    // ],
    selector: 'pivot-view',
    templateUrl: 'pivot-view.component.html',
    encapsulation: ViewEncapsulation.None //  Styles
    ,
    standalone: false
})
export class PivotViewComponent implements OnInit, AfterViewInit {
    @ViewChild('pivot_out') el: ElementRef;

    @Input()
    hot: HotInstance;
    titles;
    rows;
    $: any;

    ngOnInit(): void {
        this.titles = this.hot.settings.columns.map(function (col) {
            return col.title;
        });
        this.rows = this.hot.instance.getData()
            .map(item => {
                let row = {};
                this.titles.map((title, index) => row[title] = item[index]);
                return row;
            });

    }

    ngAfterViewInit() {
        const ctrl = this;
        // @ts-ignore
        $(this.el.nativeElement).pivotUI(
            this.rows, {
                renderers: $.extend(
                    // @ts-ignore
                    $.pivotUtilities.renderers,
                    // @ts-ignore
                    $.pivotUtilities.c3_renderers,
                    // @ts-ignore
                    $.pivotUtilities.d3_renderers,
                    // @ts-ignore
                    $.pivotUtilities.export_renderers
                ),
                hiddenAttributes: [""],
                aggregatorName: 'Count',
                menuLimit: 1000
            }).show();
    }
}
