import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import * as utils from "../../../lib/utils";
import {DateTimePeriodService} from '../../../services/date-time-period.service';
import {ChartDialogSeriesData, ChartDialogService} from '../chart-dialog.service';
import {Subject} from 'rxjs';
import {Series} from '../../../_models/series';
import {TileDataService} from '../../../services/tile_data.service';
import {EventService} from '../../../services/event.service';
import {HeaderDataService} from '../../../services/header_data.service';
import {takeUntil} from 'rxjs/operators';
import {GenericChartTileConfiguration} from "../../chart-config/generic-chart-tile.configuration";
import {SeriesSummary} from '../../../_models/api/series-summary';
import {IDateTimePeriod} from "../../../_typing/date-time-period";
import {DateTimeInstanceService} from "../../../services/date-time-instance.service";

@Component({
    selector: 'custom-chart',
    templateUrl: 'custom-chart.component.html',
    providers: [DateTimePeriodService, ChartDialogService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomChartComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() series_names: any;
    @Input() chart_component: string = '';
    @Input() dtp: IDateTimePeriod;
    estimate_list: Series[]
    selected_estimate: Series;
    config: GenericChartTileConfiguration;
    showing_comments: boolean = false;
    summary: SeriesSummary | [] = [];
    data_ready = false;

    constructor(public tileData: TileDataService,
                public eventService: EventService,
                public headerData: HeaderDataService,
                public dateTimePeriodService: DateTimePeriodService,
                public dateInst: DateTimeInstanceService,
                private cps: ChartDialogService) {

        if (this.headerData.showing_comments) {
            this.headerData.toggleCommentPanel(false, undefined)
        }
    }

    ngOnInit(): void {
        const ctrl = this;
        this.cps.$chartSeriesData.pipe(
            takeUntil(this.onDestroy)
        ).subscribe((result: ChartDialogSeriesData) => {
            this.config = result.config;
            this.estimate_list = result.estimate_list;
            this.selected_estimate = result.selected_estimate;
            if (this.series_names.length > 1 && this.chart_component === 'custom-chart') {
                for (let i = 1; i <= this.series_names.length; i++) {
                    this.config.series_list.push({name: this.series_names[i]})
                }
            }
            this.data_ready = true;
        })
        ctrl.dateInst.dateTimePeriodChanged$.subscribe(() => {
            console.log('CustomChartComponent dtpChanged: ',);
            if (this.dtp) {
                this.dateInst.dtp = this.dtp;
            }
            this.cps.getChartData(this.series_names[0]);
        })
        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
            console.log('CustomChartComponent dtpReset: ', dtp.start);
            this.dateInst.dtp = dtp;
        })
    }

    changeEstimate($event) {
        this.data_ready = false;
        const config = utils.deepCopy(this.config);
        this.config = null;
        this.cps.updateConfigEstimate($event.value, this.selected_estimate, config);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
