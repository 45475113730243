import {Injectable} from '@angular/core';
import {AppScope} from './app_scope.service';
import {CurrentUser} from '../_models/current-user';
import {COMPONENT_BASE_TYPES} from "../_models/component";
import {KeyMap, ModelID} from '../_typing/generic-types';
import {environment} from "../environments/environment";
import {Role} from "../_models/role";
import {NotificationService} from "./notification.service";
import {User} from "../_models/users";

@Injectable()
export class UserService {
    public mmsEmailDom = '@metalmanagementsolutions.com';
    public mmsAdminEmail = 'a@a.com';

    private manageUsers = ['Administrator', 'Manage Users'];
    private admin = ['Super_User', 'Administrator'];
    private editComponent = ['create constant_component', 'edit constant_component', 'delete constant_component'];
    private createComponent = ['create constant_component'];
    private deleteLock = ['delete component_lock', 'delete event_lock'];

    constructor(private appScope: AppScope,
                private notification: NotificationService) {
    }

    canUnlockData(user = this.appScope.current_user): boolean {
        return this.admin.some(u => user.role_names.includes(u)) ||
            this.deleteLock.some(u => user.feature_names.includes(u));
    }

    canCreateComponent(type: COMPONENT_BASE_TYPES, user = this.appScope.current_user): boolean {
        return this.admin.some(u => user.role_names.includes(u)) ||
            (this.createComponent.concat(['create ' + type])).some(u => user.feature_names.includes(u));
    }

    canEditComponent(type: COMPONENT_BASE_TYPES, user = this.appScope.current_user): boolean {
        return this.admin.some(u => user.role_names.includes(u)) ||
            (this.editComponent.concat(['edit ' + type])).some(u => user.feature_names.includes(u));
    }

    canUnlinkEvent(user = this.appScope.current_user): boolean {
        return (this.admin.concat(['Delete Component-event Delinking'])).some(u => user.role_names.includes(u)) ||
            ['delete event_component'].some(u => user.feature_names.includes(u));
    }

    canUnlinkComponent(user = this.appScope.current_user): boolean {
        return this.admin.some(u => user.role_names.includes(u)) ||
            ['delete component_component'].some(u => user.feature_names.includes(u));
    }

    canEditSeries(seriesPermissions: KeyMap<string[]>, series_id: ModelID, user = this.appScope.current_user): boolean {
        return this.admin.some(u => user.role_names.includes(u)) ||
            seriesPermissions?.[series_id]?.some(p => p.includes('edit_process_data'));
    }

    canManageUsers(user = this.appScope.current_user, accountId: ModelID = this.appScope.active_account_id): boolean {
        return this.manageUsers.some(u => user.roles.filter(r => r.account === accountId)?.map(r => r.name)?.includes(u));
    }

    isAdminUser(user = this.appScope.current_user, accountId: ModelID = this.appScope.active_account_id): boolean {
        return this.admin.some(u => user.roles.filter(r => r.account === accountId)?.map(r => r.name)?.includes(u)) || user.is_super;
    }

    isMMSUser(user = this.appScope.current_user): boolean {
        const email = user.email;
        return email.trim().endsWith(this.mmsEmailDom) || (this.mmsAdminEmail === email && !environment.production);
    }

    get current_user(): CurrentUser {
        return this.appScope.current_user;
    }

    validateUserRoles(accountIds: ModelID[], roles: Role[], showNotification: boolean = true): boolean {
        for (const accountId of accountIds) {
            // Check if this accountId has the "User" role
            const hasUserRole = roles.some(role =>
                (role.attributes.name === 'User' &&
                    role.relationships?.account?.data?.id === accountId) || accountId == this.appScope.WIREBaseConfigId
            );

            // If an accountId is found without the "User" role, stop and show a notification
            if (!hasUserRole) {
                if (showNotification) {
                    const accountName = this.appScope.accounts.find(a => a.id === accountId)?.attributes?.name;
                    this.notification.openError(`The "User" role is required for account ${accountName}`);
                }
                return false; // Stop as soon as we find one invalid account
            }
        }

        return true; // All accountIds have the "User" role
    }

    getUserRole(accountId: ModelID, roles: Role[]) {
        return roles.find(role => role.attributes.name === 'User' && role.relationships.account.data.id === accountId);
    }

    validateUserAccounts(accountIds: ModelID[]): boolean {
        if (!accountIds?.length) {
            this.notification.openError('User must be assigned to at least one account.');
            return false;
        }
        return true;
    }

    updateDefaultRoleForAllAccounts(accountIds: ModelID[], userRoles: Role[], allRoles: Role[]): Role[] {
        /**add User role for selected accounts where not present**/
        accountIds.forEach(accountId => {
            const role = userRoles.find(r => r.relationships?.account?.data?.id === accountId
                && r.attributes.name === 'User')
            if (!role) {
                userRoles.push((this.getUserRole(accountId, allRoles)));
            }
        })

        /**Remove all roles for unselected accounts**/
        const filteredRoles = userRoles.filter(role =>
            accountIds.includes(role.relationships?.account?.data?.id)
        );
        return filteredRoles;
    }

    getUserAccountIds(user: User) {
        const userAccount = user.relationships.account.data?.id;
        let userAccountIds = user.relationships.accounts.data?.map(a => a.id);
        if (!userAccountIds?.length) {
            userAccountIds = [userAccount];
        } else if (!userAccountIds.includes(userAccount)) {
            userAccountIds.push(userAccount)
        }
        return userAccountIds;
    }
}
