<style>
  .model-pivot .icon {
      background-color: unset !important;
      width: 16px !important;
      height: 16px !important;
  }
</style>

<div #pivotAnchor (window:resize)="buildPivot()"
     class="hide-pivot-totals-columns hide-pivot-totals-rows model-pivot">
</div>
