<div class="comparison-chart-form">
  <div class="row center">
    <mat-form-field>
      <mat-label>Sub Title</mat-label>
      <input matInput [(ngModel)]="config.labels.sub_title">
    </mat-form-field>
    <mat-form-field>
      <mat-label>X Axis Label</mat-label>
      <input matInput [(ngModel)]="config.labels.x_axis">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Y Axis Label</mat-label>
      <input matInput [(ngModel)]="config.labels.y_axis">
    </mat-form-field>
  </div>

  <div class="row center justify-space">
    <mat-form-field class="flex-half alone">
      <mat-label>Type</mat-label>
      <mat-select [(value)]="config.type">
        <mat-option *ngFor="let type of types" [value]="type">
          {{type | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <colour-palette
        palette="2"
        show_text="false"
        [(value)]="config.colour"
        [label]="'Colour'">
    </colour-palette>

    <mat-checkbox [(ngModel)]="config.hide_comments" class="form-element-align">Hide Comments</mat-checkbox>
  </div>

  <div class="row" *ngIf="config.series_list!==undefined">
    <div class="select-search">
      <select-search *ngIf="name_list.length>0"
                     [value]="getValue(config.series_list[0].name)"
                     [fill_stubs]="true"
                     [label]="'Series X'"
                     [data]="series_light_full"
                     (selectChange)="addNameToConfig(config.series_list[0], $event, 'x')"
      ></select-search>
    </div>
    <div class="select-search">
      <select-search *ngIf="name_list.length>0"
                     [value]="getValue(config.series_list[1].name)"
                     [fill_stubs]="true"
                     [label]="'Series Y'"
                     [data]="series_light_full"
                     (selectChange)="addNameToConfig(config.series_list[1], $event, 'y')"
      ></select-search>
    </div>
  </div>

  <div>
    <mat-label>Show statistics</mat-label>
  </div>

  <div class="row min-height list">
    <mat-checkbox [(ngModel)]="config.statistics.averages" class="self-center">Averages</mat-checkbox>
    <mat-checkbox [(ngModel)]="config.statistics.std_dev" class="self-center">Standard deviations</mat-checkbox>
  </div>

  <div class="row min-height list">
    <mat-checkbox [(ngModel)]="config.statistics.pooled" class="self-center" style="flex:50;">Pooled average/Standard
      deviation
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.statistics.correlation_coeff" class="self-center" style="flex:50;">Correlation
      coefficient
    </mat-checkbox>
  </div>

  <div class="row min-height list" style="margin-bottom:16px;">
    <mat-checkbox [(ngModel)]="config.diagonal_line" class="self-center">Show diagonal line</mat-checkbox>
  </div>

  <mat-form-field class="flex-half">
    <mat-label>Regression line</mat-label>
    <mat-select [(value)]="config.regression_line">
      <mat-option *ngFor="let type of regression_types" [value]="type">
        {{type | titlecase}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
