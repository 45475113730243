<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}" class="image-form">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Properties">
      <div class="column">
        <div class="row medium-height">
          <mat-form-field (mouseenter)="hint='Name'" style="flex-basis:45%;flex-grow:0;">
            <mat-label>Title</mat-label>
            <input [(ngModel)]="component_data.title" matInput name="title" placeholder="">
            <mat-hint>The text that shows when hovering over the image.</mat-hint>
          </mat-form-field>

        </div>

        <div class="row center">
          <mat-form-field>
            <mat-label style="display: flex; ">Select Icon
              <img *ngIf="component_data.src" style="margin-top:-18px;height:50px;width:50px;"
                   src="{{ component_data.src }}"/></mat-label>

            <mat-select [(ngModel)]="component_data.src"
                        class="image-select" name="process_icon">
              <mat-option *ngFor="let img of images" [value]="img" class="image-options"
                          style="display:flex;align-items: center;">
                <img height="50px" src="{{ img }}" width="50px"/>
                <span>{{img.split("/")[img.split("/").length - 1].split('.')[0]}}</span>
              </mat-option>
            </mat-select>
            <mat-hint> Select an image from the available list of previously uploaded images.</mat-hint>
          </mat-form-field>


          <div style="width:10%; text-align:center;margin-top: 30px;font-weight:bold;">OR</div>

          <div class="flex-grow-1 form-element-align">
            <label class="image-upload-container btn btn-bwm" style="width:100%;">
            <input #imageUpload (change)="processFile()"
                   accept="image/*"
                   style="width:inherit;"
                   type="file">
            </label>
          </div>

        </div>
      </div>
    </mat-tab>
    <mat-tab label="Display">
      <div class="column">

        <div class="row" style="width:300px; margin-top: 20px;">
          <mat-form-field class="inline">
            <label>Width</label>
            <input (change)="getNatural('width')" [(ngModel)]="component_data.width" [max]="1000"
                   [min]="10"
                   [step]="10"
                   matInput
                   name="width"
                   style="width:100px;"
                   type="number">
          </mat-form-field>
          <mat-form-field class="inline">
            <label>Height</label>
            <input (change)="getNatural('height')" [(ngModel)]="component_data.height" [max]="1000"
                   [min]="10"
                   [step]="10"
                   matInput
                   name="height"
                   style="width:100px;"
                   type="number">
          </mat-form-field>

          <mat-checkbox [(ngModel)]="component_data.constrain_proportions">Constrain proportions</mat-checkbox>
        </div>

        <img #selectedImage
             [src]="component_data.src"
             [style.height]="component_data.height + 'px'"
             [style.width]="component_data.width + 'px'"
             style="border: 1px dashed orange; box-sizing:content-box;"
        />
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>
