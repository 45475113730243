import {ChangeDetectorRef, NgZone, Pipe, PipeTransform} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {map, Observable, tap} from "rxjs";
import {User} from "../../_models/users";

@Pipe({name: "getUser", standalone: true, pure: false})
export class GetUserPipe implements PipeTransform {
    constructor(private api: ApiService,
                private ref: ChangeDetectorRef,
                private zone: NgZone) {
    }

    transform(value: string | null, ...args): Observable<User> | null {
        if (!value) {
            return;
        }

        const options = new SearchQueryOptions();
        options.filters = [{name: 'id', op: 'eq', val: value}];
        return this.api.users.searchSingle(options).pipe(
            map(response => response.data),
            tap(response => {
                //console.log('GetUserPipe - : ', response);
                this.zone.run(() => {
                    this.ref.markForCheck();
                });
            }));
    }
}
