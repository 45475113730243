<mat-form-field *ngIf="accounts" class="account-select" [ngClass]="{'no-label': !label}">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-select #select_handle (selectionChange)="changeAccount($event.value)"
              [value]="selectedAccountIds" [placeholder]="placeholder"
              [multiple]="multiple">
    <mat-option *ngIf="allow_blank===true" [value]="null" style="color:#999;">Clear</mat-option>
    <mat-option *ngFor="let account of accounts"
                [disabled]="account.view_only || (allowedAccountIds?.length && !allowedAccountIds?.includes(account.id))"
                [matTooltipDisabled]="!account.view_only"
                [style.color]="'lightgray' ? account.view_only : ''"
                [value]="account.id"
                matTooltip="You don't have access to this account">
      <div class="account-picker-row">
        <div>{{account.attributes.name}}</div>
        <div *ngIf="select_handle.panelOpen && selectedAccountIds == account.id && !multiple"
             class="active-account-indicator">
          Selected
        </div>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
