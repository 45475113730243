import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../shared/base.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormDialogService} from "../../services/form-dialog.service";
import {first, takeUntil, tap} from "rxjs/operators";
import {LockTemplateTableService} from "../lock-template-table-view/lock-template-table.service";
import {LockTemplate} from "../../_models/lock-template";
import {IDMap} from '../../_typing/generic-types';
import {ConstantProperty} from '../../_models/constant-property';
import {EventType} from '../../_models/event-type';
import {
    LockTemplateFormDialogComponent
} from "../../forms/lock-template-form-dialog/lock-template-form-dialog.component";
import {HeaderDataService} from "../../services/header_data.service";
import {ComponentType} from "../../_models/component-type";

@Component({
    selector: 'lock-template-table',
    templateUrl: './lock-template-table.component.html',
    styleUrls: ['./lock-template-table.component.less'],
    providers: [LockTemplateTableService],
    standalone: false
})
export class LockTemplateTableComponent extends BaseComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    applyFilter = this.cps.applyFilter;
    dataSource: LockTemplate[];
    columns = ['edit', 'name', 'description', 'event_type', 'component_type', 'attributes', 'constant_properties'].concat(this.cps.audit_columns);
    filter_string = '';
    constant_property_dict: IDMap<ConstantProperty> = {};
    event_type_dict: IDMap<EventType> = {};
    component_type_dict: IDMap<ComponentType> = {};

    constructor(public cps: LockTemplateTableService,
                private dialog: MatDialog,
                private headerData: HeaderDataService,
                private formDialogService: FormDialogService) {
        super();
        this.cps.lockTemplateDataSubject.pipe(
            tap(ds => {
                this.dataSource = ds.lock_templates;
                const constant_properties = ds.constant_properties;
                constant_properties?.forEach(cp => this.constant_property_dict[cp.id] = cp);
                const event_types = ds.event_types;
                event_types?.forEach(et => this.event_type_dict[et.id] = et);
                const component_types = ds.component_types;
                component_types?.forEach(ct => this.component_type_dict[ct.id] = ct);
            }),
            takeUntil(this.onDestroy)
        ).subscribe()
    }

    ngOnInit(): void {
        this.setButtons();
        setTimeout(() => {
            this.cps.sort = this.sort;
            this.cps.paginator = this.paginator;
            this.cps.initialise();
        })

    }

    updateSort(event) {
        this.cps.updateSort(this.sort)
    }

    openDialog(template: LockTemplate): void {
        const dialogRef: MatDialogRef<LockTemplateFormDialogComponent> =
            this.formDialogService.openLockTemplateFormDialog(template ? [template.id] : null);
        dialogRef.afterClosed().pipe(first()).subscribe(() => this.cps.refreshData())
    }

    setButtons(): void {
        this.headerData.buttons = [
            {name: 'Add Lock Template', func: this.openDialog.bind(this), class: 'icon-plus'},
            {name: 'Refresh Table', func: this.cps.refreshData.bind(this), class: 'icon-refresh'}
        ];
    }
}
