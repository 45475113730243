<div class="row utils-bar" [style]="'justify-content: space-between'">
  <div *ngIf="dataSource" class="table-filter">
    <input (keyup)="applyFilter($event.target.value)" [value]="filterValue" placeholder="Search users"/>
  </div>
</div>
<div class="table-container">
  <table [dataSource]="dataSource"
         [id]="id"
         [matSortActive]="'name'"
         (matSortChange)="applySort($event)" class="table table-striped user-table" mat-table matSort matSortDirection="asc">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column.title">
      <th *matHeaderCellDef [mat-sort-header]="column.name" mat-header-cell>
        <ng-container *ngIf="column.title === 'edit'"></ng-container>
        <ng-container *ngIf="column.title !== 'edit'">{{column.title}}</ng-container>
      </th>
      <td *matCellDef="let user;"
          [style]=" {'text-align':['restricted_access','active','sms_disabled','email_disabled'].includes(column.name) ? 'center' : 'left' }"
          mat-cell
          style="text-align: left;border:none;">
        <ng-container *ngIf="column.title === 'edit'">
          <i (click)="openDialog(user)" *ngIf="can_edit" class="fa fa-edit" style="cursor:pointer;"></i>
        </ng-container>
        <ng-container
            *ngIf="['restricted_access','active','sms_disabled','email_disabled'].includes(column.name)">
          <mat-checkbox (change)="userChanged(user)" [(ngModel)]="user.attributes[column.name]"
                        [disabled]="!can_edit || (column.name === 'require_otp' && !user.attributes[column.name]) "></mat-checkbox>
        </ng-container>
        <ng-container
            *ngIf="!['restricted_access','active','sms_disabled','email_disabled','edit'].includes(column.name)">
          <ng-container
              *ngIf="column.name==='created_on'">{{user.attributes[column.name] | stringDate }}
          </ng-container>
          <ng-container
              *ngIf="column.name==='user_activity'">
            <ul [style]="'list-style:none;padding:0;white-space: nowrap;'">
              <li>Last seen: {{user_meta_dict[user.id]?.attributes.last_seen | stringDate }}</li>
              <li>Last login: {{user_meta_dict[user.id]?.attributes.last_login | stringDate }}</li>
              <li>Last 2FA: {{user_meta_dict[user.id]?.attributes.last_2fa_activation | stringDate }}</li>
              <li>Last pwd
                reset: {{user_meta_dict[user.id]?.attributes.last_password_reset | stringDate }}</li>
            </ul>
          </ng-container>
          <ng-container
              *ngIf="!['created_on'].includes(column.name)">{{user.attributes[column.name]}}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container>
      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </ng-container>
  </table>
</div>
