import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {PlantDataService} from "../../services/plant-data/plant_data.service";
import {Subject, Subscription} from "rxjs";
import {concatMap, switchMap, takeUntil, tap} from "rxjs/operators";
import {BaseComponent} from "../../shared/base.component";
import {FlowchartData} from "../../_models/flowchart-data";

@Component({
    selector: 'stream-sheet-focus-view',
    templateUrl: 'stream-sheet-focus-view.component.html',
    standalone: false
})
export class StreamSheetFocusViewComponent extends BaseComponent {
    sheet_ready: boolean = false;
    flowSheet: FlowchartData;
    streams: any;
    processes: any;

    process: any;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private plantData: PlantDataService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.params_subscription = this.route.params.pipe(switchMap(params => {
            this.sheet_ready = false;
            this.headerData.buttons = [];
            let processId = params.processID;
            return this.api.process.getById(processId).pipe(concatMap(result => {
                this.process = result.data;
                this.plantData.setActiveProcess(this.process);
                return this.plantData.getFlowchartDataForProcess(processId).pipe(tap(flowchart_data => {
                    this.flowSheet = flowchart_data;
                    this.setupEditSheet();
                    this.buildHeader();
                }))
            }), takeUntil(this.onDestroy))
        })).subscribe();
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
        super.ngOnDestroy();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Streams: ' + ctrl.process.attributes.name;
        ctrl.headerData.setPath(ctrl.process);
    };

    setupEditSheet() {
        const ctrl = this;
        ctrl.streams = ctrl.flowSheet.streams;
        // FIXME what is this ctrl.plantData.flowSheet.ctrl referring to?
        // @ts-ignore
        ctrl.processes = [ctrl.flowSheet.process_focus].concat(ctrl.plantData.flowSheet.ctrl);
        ctrl.sheet_ready = true;
    };
}
