<div class="form-dialog">
  <h1><span>{{form_title}}</span>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Groups" *ngIf="model.type !== 'group'">
        <ng-template matTabContent>
          <relationship-group-selector [model]="model"></relationship-group-selector>
        </ng-template>
      </mat-tab>
      <mat-tab label="Users" *ngIf="model.type !== 'user'">
        <ng-template matTabContent>
          <relationship-user-selector [model]="model"></relationship-user-selector>
        </ng-template>
      </mat-tab>
      <mat-tab label="Pages" *ngIf="model.type !== 'session_state'">
        <ng-template matTabContent>
          <relationship-page-selector [model]="model"></relationship-page-selector>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="save()" class="btn-save" [disabled]="!canSave()">Save</button>
  </div>
</div>
