import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserData} from "../../../services/user_data.service";
import {AppScope} from "../../../services/app_scope.service";

export interface IUserPassword {
  requiresOTP: boolean;
  password: string;
}

@Component({
    selector: 'user-password',
    templateUrl: './user-password.component.html',
    styleUrls: ['./user-password.component.less'],
    standalone: false
})
export class UserPasswordComponent {
  passwordForm: FormGroup;
  returnUrl: string;
  hidePassword: boolean;
  @Input() username: string;
  @Output() requiresOTP: EventEmitter<IUserPassword> = new EventEmitter<IUserPassword>();

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private userData: UserData,
              private appScope: AppScope) {
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get formControl() {
    return this.passwordForm.controls.password;
  }

  onPasswordSubmit() {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }
    const vars = {
        email: this.username,
        password: this.formControl.value,
        token: ''
    };

    this.userData.attemptLogin(vars).subscribe({
      next: (): void => {},
      error: (err: {requiresOTP: boolean}) => {
          if (err.requiresOTP) {
              this.requiresOTP.emit({requiresOTP: err.requiresOTP, password: this.formControl.value});
          }
      }
    });
  }

  onSubmitReset(): void {
    this.appScope.noticeBoardOpenSubject.next(true);
    this.appScope.userName = this.username;
  }
}
