<div class="page">
  <mat-tab-group disableRipple class="dialog-table" mat-stretch-tabs="false">
    <mat-tab label="Table" [hidden]="!hot || !hot.ready">
      <div *ngIf="hot && hot.ready && hot.instance" class="row utils-bar justify-start"
           style="justify-content: flex-start">
        <div class="row table-filter">
          <utils-bar #utils_bar
                     [search]="true"
                     (searchValueSubmitted)="search=$event;hot.filter(search)"
                     (searchCleared)="hot.clear();search=''">
          </utils-bar>
          <account-filter (accountFilterEmitter)="hot.filter_account($event)" [allow_blank]="true"></account-filter>
        </div>
      </div>
      <div class="md-padding">
        <div (window:resize)="hot.onResize($event)" [style.height.px]="hot.window_height">
          <div #hot_anchor [id]="hot.handson_id" class="hot-template-anchor">
          </div>
        </div>
      </div>
      <!--</ng-template>-->
    </mat-tab>

    <mat-tab *ngIf="hot && hot.ready && hot.instance" label="Pivot">
      <ng-template matTabContent>
        <!--TODO make height dynamic?-->
        <pivot-view [hot]="hot"></pivot-view>
      </ng-template>
    </mat-tab>
    <!--<mat-tab *ngIf="hot && hot.ready && hot.instance"-->
    <mat-tab *ngIf="hot && hot.ready && hot.instance"
             label="History">
      <ng-template matTabContent>
        <div style="width: 100%; overflow:auto;">
          <!--TODO make height dynamic?-->
          <history-table [hot]="hot"></history-table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
