<style>
</style>

<ng-container *ngIf="item">
  <mat-list-item>
    <div class='node-wrapper row'>
      <div (click)="getChildren(item);"
           [ngClass]="{'expanded' : item.expanded, 'top-level': !hasParent(item), 'has-child':hasChild(item)}"
           class="fas fa-caret-right"></div>
      <mat-checkbox (change)="emitSelected(item, $event)"
                    [checked]="item.checked"></mat-checkbox>
      <div (click)="getChildren(item);" style="color:black;">{{item.attributes.name}}</div>
    </div>
  </mat-list-item>

  <ng-container *ngIf="item.expanded">
    <mat-list [hidden]="!item.expanded" flex>
      <ng-container *ngFor="let childItem of item.relationships.children.data">
        <ore-body-group-node (itemSelected)="emitSelected($event.item, $event)"
                             [group_tree_data]="group_tree_data"
                             [item]="childItem"></ore-body-group-node>
      </ng-container>
    </mat-list>
  </ng-container>
</ng-container>
