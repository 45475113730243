import {ModelID} from '../../_typing/generic-types';

/**Dictionary to keep track of relationship names on a particular model, based on the type of the relationship
 * usually this is just the type name for single relationships or type name + 's' for many relationships**/
export const ModelRelationshipNameDict = {
    'session_state': {
        group: 'groups',
        users: 'users',
        mapping: {
            group: {
                type: 'group_page',
                name: 'group',
                upsert_api: 'api/upsert/group-page',
                delete_api: 'api/delete/group-page'
            },
            users: {
                type: 'user_page',
                name: 'user',
                upsert_api: 'api/upsert/user-page',
                delete_api: 'api/delete/user-page'
            },
            self: 'page'
        }
    },
    'users': {
        session_state: 'pages',
        group: 'groups',
        mapping: {
            group: {
                type: 'group_user',
                name: 'group',
                upsert_api: 'api/upsert/group-user',
                delete_api: 'api/delete/group-user'
            },
            session_state: {
                type: 'user_page',
                name: 'page',
                upsert_api: 'api/upsert/user-page',
                delete_api: 'api/delete/user-page'
            },
            self: 'user'
        }
    },
    'group': {
        session_state: 'pages',
        users: 'users',
        mapping: {
            users: {
                type: 'group_user',
                name: 'user',
                'upsert_api': 'api/upsert/group-user',
                'delete_api': 'api/delete/group-user'
            },
            session_state: {
                type: 'group_page',
                name: 'page',
                'upsert_api': 'api/upsert/group-page',
                'delete_api': 'api/delete/group-page'
            },
            self: 'group'
        }
    },
    'constant_property': {
        component_type: 'component_types',
        event_type: 'event_types'
    }
}

export interface BaseFlaskFilter {
    op: string,
    name: string,
    val: string | string[]
}

export function getBaseFilter(value: string | string[] | number | number[] | boolean,
                              attribute: string, operator = 'eq') {
    /**
     * Base filter.
     */
    return {
        op: operator,
        name: attribute,
        val: value
    };
}

export function getListFilter(attribute: string, values: any[]) {
    return getBaseFilter(values, attribute, 'in');
}

export function getRelationWithAttributeFilter(relation_name: string, relation_value: string | string[], attribute: string, operator = 'eq') {
    /**
     * Base filter for to-one resources.
     */
    return {
        op: 'has',
        name: relation_name,
        val: {
            'op': operator, 'name': attribute, 'val': relation_value
        }
    };
}

export function getRelationWithIdFilter(relation_name: string, relation_id: string) {
    /*
    * Get filter JSON for a to-one resource with a relation name 'relation_name' which has an id of 'relation_id'.
    *
    * Note: this is only tested where the column sits on the resource itself.
    * */
    return getRelationWithAttributeFilter(relation_name, relation_id, 'id');
}

export function getRelationWithManyIdsFilter(relation_name: string, relation_ids: string[]) {
    /**
     * Find all resources with a to-one relation to 'relation_name' with the given ids.
     *
     * This is very similar to the getRelatedMany, these queries should likely be rewritten to not have to provide a list.
     *
     * Use caution where the relation_ids list might be very large.
     */
    return getRelationWithAttributeFilter(relation_name, relation_ids, 'id', 'in');
}

export function getRelationComponentWithManyIdsFilter(relation_ids: string[]) {
    return getRelationWithManyIdsFilter('component', relation_ids);
}

export function getRelationWithNameFilter(relation_name: string, name_value: string) {
    return getRelationWithAttributeFilter(relation_name, name_value, 'name');
}

export function getAccountFilter(account_id: string) {
    return getRelationWithIdFilter('account', account_id);
}

export function getManyRelationWithAttributeFilter(relation_name: string, relation_value: string | string[] | number | number[], attribute: string, operator = 'eq') {
    /**
     * Base filter for to-many resources.
     */
    return {
        op: 'any',
        name: relation_name,
        val: {
            'op': operator, 'name': attribute, 'val': relation_value
        }
    };
}

export function getManyRelationWithIdFilter(relation_name: string, relation_id: ModelID) {
    return getManyRelationWithAttributeFilter(relation_name, relation_id, 'id');
}

export function getManyRelationWithIdsFilter(relation_name: string, relation_ids: string[]) {
    /*
    * Same as getRelatedWithIdFilter except when the relationship is through a many-many mapping table.
    * */
    return getManyRelationWithAttributeFilter(relation_name, relation_ids, 'id', 'in');
}

export function getManyRelationWithNamesFilter(relation_name: string, relation_names: string[]) {
    return getManyRelationWithAttributeFilter(relation_name, relation_names, 'name', 'in');
}
