import {AuditMixin, AuditMixinAttributes} from "./audit-mixin";

export type PENDING_CONTEXT_TYPES = 'component_to_component' | 'event_to_component';
export type PENDING_EVENT_COMPONENT_CONTEXT = {
    component_type?: { id: string, value?: any },
    event_type?: {
        id: string;
        constant_properties?: { id: string, value?: any }[];
        unique_constant_property: string;
        value?: any;
    }
};

export type PENDING_COMPONENT_COMPONENT_CONTEXT = {
    component_type_from?: { id: string, value?: any },
    component_type_to?: { id: string, value?: any },
};

export class PendingContextAttributes extends AuditMixinAttributes {
    context_type: PENDING_CONTEXT_TYPES = 'event_to_component';
    context: PENDING_COMPONENT_COMPONENT_CONTEXT & PENDING_EVENT_COMPONENT_CONTEXT;
    processing_message: string;
}

export class PendingContext extends AuditMixin {
    attributes: PendingContextAttributes;
    id: string;
    type: string = 'pending_context';

    constructor() {
        super();
        this.attributes = new PendingContextAttributes();
    }
}
