import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {EventModelUploadDataService} from "../event-model-upload-data.service";
import {EventModelOptions} from "../extract-event-options.component";
import {combineLatest, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ConstantProperty} from "../../../../_models/constant-property";
import {UploadModelTemplateService} from "../../upload-model-template.service";

@Component({
    selector: 'event-model-upload-select-unique-property',
    templateUrl: './event-model-upload-select-unique-property.component.html',
    styleUrls: ['../extract-event-options.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventModelUploadSelectUniquePropertyComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    constantPropertyID: string;
    @Input() readonly = false;
    @Output() constantPropertyIDChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() selectedConstantProperties: EventEmitter<string[]> = new EventEmitter<string[]>();

    available_properties: ConstantProperty[] = [];

    /* Select search help method */
    compareValueIds: (a: { id: string }, b: string) => boolean;

    constructor(public dataService: EventModelUploadDataService,
                private changeDetectorRef: ChangeDetectorRef,
                public templateService: UploadModelTemplateService) {
        this.compareValueIds = (a, b) => {
            return a.id === b;
        };
    }

    ngOnInit(): void {
        const s$ = [];
        s$.push(this.dataService.options$);

        combineLatest(s$).pipe(takeUntil(this.onDestroy)).subscribe((r: any) => {
            const options = r[0] as EventModelOptions;

            if (!options) {
                this.available_properties = [];
                this.constantPropertyID = null;
            } else {
                this.constantPropertyID = options.unique_property;
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setConstantProperty(value) {
        if (value) {
            this.constantPropertyID = value.id;
            this.constantPropertyIDChange.next(value.id);
        } else {
            this.constantPropertyID = null;
            this.constantPropertyIDChange.next(null);
        }
        this.changeDetectorRef.markForCheck();
    }
}
