import {Directive, Injectable, OnDestroy} from '@angular/core';
import {ApiService} from "./api/api.service";
import { HttpClient } from "@angular/common/http";
import {NavigationStart, Router, RouterEvent} from "@angular/router";
import * as utils from "../lib/utils";
import {FlatPromise} from "../lib/utils";
import {BehaviorSubject, firstValueFrom, forkJoin, Observable, of, ReplaySubject, Subject, takeUntil} from "rxjs";
import {catchError, filter, map, tap, take} from 'rxjs/operators';
import {CurrentUser} from "../_models/current-user";
import {MatomoTracker} from "ngx-matomo-client";
import {FolderEntry} from "./menu-tree.service";
import {default_translation} from "../default-translation";
import {CheckOnlineStateService} from "./check-online-state.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {IDMap, KeyMap, ModelID} from '../_typing/generic-types';
import {default_config_map} from '../default-configuration';
import {KeycloakService} from "keycloak-angular";
import {environment} from "../environments/environment";
import {Process} from "../_models/process";

interface IIdValue {
    id: string;
    value: any;
}

@Directive()
@Injectable({
    providedIn: 'root'
})
export class AppScope implements OnDestroy {
    private readonly onDestroy: Subject<void> = new Subject<void>();
    auth_complete: FlatPromise;
    route_before_login: string;
    keycloak_redirect: string;
    sidenav_open: boolean = false;

    public noticeBoardOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public noticeBoardOpen$: Observable<boolean> = this.noticeBoardOpenSubject.asObservable();
    current_url: string;
    previous_url: string;

    public sidenavOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.sidenav_open);
    public sidenavOpen$: Observable<boolean> = this.sidenavOpenSubject.asObservable();

    private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

    private authCompleteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public authComplete$: Observable<boolean> = this.authCompleteSubject.asObservable();

    // Replaces auth_complete and
    private currentUserSubject: Subject<any> = new ReplaySubject<any>(1);

    public get currentUserValue(): Observable<CurrentUser> {
        return this.currentUserSubject.asObservable();
    }
    private currentThemeSubject = new BehaviorSubject<string>('wire-light-theme');
    currentTheme = this.currentThemeSubject.asObservable();

    /*Can be used instead of auth_complete when only config settings are needed*/
    public configDictChanged: ReplaySubject<KeyMap<any>> = new ReplaySubject(1);
    isKeycloakUser: boolean;
    userName: string;
    isNotMobile: boolean;
    config_data: any;
    config_name_map: Record<string, IIdValue> = {};
    client_config_dict: Record<string, string>;
    current_user: CurrentUser;
    page_title: string;
    mobile_sidenav: any[] = [];
    no_header: boolean;
    errors: string[];
    public active_account_id: any;
    public accounts: any;
    public WIREBaseConfigId: ModelID;
    plants: any[]; // list of top level plants for the site/client
    selected_plant: any;
    company: any;
    process_tree_data: any;
    processTreeDataDict: IDMap<{
        id: ModelID;
        name: string;
        children: ModelID[];
        expanded: boolean;
        top: boolean;
    }> = {};
    noticeBoardReminder: Date;
    folder_dict: Record<string, FolderEntry> = {};

    at_who: boolean;
    at_who_coords: { top: number, left: number } = {top: 0, left: 0};
    mentionEmit: Subject<string> = new Subject<string>();
    mentionSelected: Subject<any> = new Subject<any>();
    at_who_index: number;

    navigate_inside: boolean = false;

    // region Legacy variables
    features: any[];
    // endregion

    constructor(private api: ApiService,
                private http: HttpClient,
                private router: Router,
                private matomoTracker: MatomoTracker,
                private checkOnlineStateService: CheckOnlineStateService,
                protected readonly keycloak: KeycloakService) {
        this.auth_complete = new FlatPromise();
    }

    /**
     * Enable check-online poll, setup matomo connection, and set user-related data on the AppContext
     * */
    afterUserLogin(): Observable<boolean> {
        return this.http.get('/auth/check_login').pipe(
            map((data: CurrentUser): boolean => {
                this.current_user = data;
                // Subscribe to checkOnline poll
                this.checkOnlineStateService.enableCheck();
                // Matomo Setup
                this.matomoTracker.setUserId(this.current_user.id);
                this.matomoTracker.setDocumentTitle('ngx-Matomo Test');

                const sources: Observable<any>[] = [];
                sources.push(this.api.configuration.searchMany().pipe(tap(response => {
                    this.config_data = response.data;
                    this.config_name_map = default_config_map;
                    this.refreshConfigurationSettings();
                })));

                forkJoin(sources).subscribe(() => {
                    this.authCompleteSubject.next(true);
                    this.isAuthenticatedSubject.next(true);
                    if (!this.current_user.keycloak_user) {
                        if (this.noticeBoardReminder) {
                            const currentDate: Date = new Date();
                            if (this.noticeBoardReminder >= currentDate) {
                                this.noticeBoardOpenSubject.next(false);
                            } else {
                                this.noticeBoardOpenSubject.next(true);
                            }
                        } else {
                            this.noticeBoardOpenSubject.next(true);
                        }
                    }
                    this.auth_complete.resolve(this.current_user);
                    this.currentUserSubject.next(this.current_user);
                    (<any>window).gtag('set', {'userId': this.current_user.id});
                });
                return true;
            }), catchError((err) => {
                this.authCompleteSubject.next(false);
                this.isAuthenticatedSubject.next(false);
                console.log('Rejected');
                this.current_user = null;
                // Not entirely what the case of error would be here
                this.router.navigate(['view', 'login']);
                return of(err !== null);
            }));
    }

    isUserAdminOnCurrentAccount(account_id: String) {
        for (const role of this.current_user.roles) {
            const role_account_id = role['account'];
            if (role_account_id === account_id && role['name'] === 'Administrator') {
                return true;
            }
        }
        return false;

    }


    logout() {
        return firstValueFrom(this.http.get('/auth/logout')).finally(() => {
            console.log('legacy logout done');
            // NOTE: this logout seems to also do an immediate windows.location redirect, so waiting on the promise seems pointless.
            return this.keycloak.logout();
        });
    }

    refreshConfigurationSettings(account_id?: string) {
        let config_name_map = {};
        const data = account_id ?
            this.config_data.filter(c => c.relationships.account.data.id === account_id) : utils.deepCopy(this.config_data);
        data.map(item => {
            config_name_map[item.attributes.name] = {id: item.id, value: item.attributes.value};
        });
        /**Leave base config properties in place, just overwrite 'duplicate' properties with those from the current account**/
        Object.assign(this.config_name_map, config_name_map);

        this.client_config_dict = default_translation;
        if (this.config_name_map.client_config_dict) {
            const client_translations = this.config_name_map.client_config_dict.value;
            Object.assign(this.client_config_dict, client_translations);
        }
        this.configDictChanged.next(this.config_name_map);
    }

    clientDict(value) {
        if (this.client_config_dict[value.toLowerCase()]) {
            return this.client_config_dict[value.toLowerCase()];
        } else {
            return value;
        }
    }

    resizeWindowEvent(timeout: number = 500) {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, timeout);
    }

    closeDialogOnNavigate(dialog: MatDialog, dialogRef: MatDialogRef<any>) {
        this.router.events
            .pipe(
                filter((event: NavigationStart) => event instanceof NavigationStart),
                filter(() => !!dialog),
                take(1)
            )
            .subscribe(() => {
                dialogRef.close();
            });
    }

    toggleSideNav(): void {
        this.sidenav_open = !this.sidenav_open;
        this.sidenavOpenSubject.next(this.sidenav_open);
    }

    setNoticeBoardCountdown(countDownTime: number) {
        const currentDate: Date = new Date();
        this.noticeBoardReminder = new Date();
        this.noticeBoardReminder.setDate(currentDate.getDate() + (countDownTime / 24));
    }

    getProcessDictItem(p: Process, top: boolean) {
        return {id: p.id, name: p.attributes.name, children: null, expanded: false, top: top};
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
