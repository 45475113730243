export const default_translation = {
    "ore-body-view": "Ore Body Explorer",
    "select-ore-body-group-type": "Select a resource to filter by",
    "ore-body-types-side-menu": "Ore Body Types",
    "ore-bodies-side-menu": "Ore Bodies",
    "component-types-side-menu": "Component Types",
    'ore-body-mass-balance-col-name': 'Name',
    'ore-body-mass-balance-col-initial': 'Initial',
    'ore-body-mass-balance-col-inputs': 'Inputs',
    'ore-body-mass-balance-col-outputs': 'Outputs',
    'ore-body-mass-balance-col-content': 'Content',
    'component-events-side-menu': 'Component Events',
    'single-component-events-side-menu': 'Lookup Component Events'
};
