import {Injectable, OnDestroy} from "@angular/core";
import * as utils from "../../lib/utils";
import {map, switchMap, take} from "rxjs/operators";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from "../../services/api/api.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Series} from '../../_models/series';
import {Tile as TileModel, TileAttributes, DefaultTileAttributes} from "../../_models/tile";
import {SeriesType} from '../../_models/series-type';
import {ListResponse} from "../../services/api/response-types";

@Injectable()
export class AnalysisToolsService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    baseTile: TileAttributes = new DefaultTileAttributes();

    $estimate: ReplaySubject<SeriesType> = new ReplaySubject<SeriesType>(1);

    constructor(private api: ApiService,
                public dateTimePeriodService: DateTimePeriodService) {
    }

    getSeriesDataTileConfig(series: Series, estimate_type: string): any {
        let tile = new TileModel();
        tile.attributes = {...this.baseTile, ...tile.attributes};
        tile.attributes.title = series.attributes.description;
        tile.attributes.content = 'series-table';
        tile.id = utils.guid();

        tile.attributes.parameters = {
            "title": series.attributes.description ? series.attributes.description : series.attributes.name,
            "series_list": [
                {
                    "attributes": {
                        "description": series.attributes.description,
                        "event_type_name": estimate_type,
                        "kpi_level": null,
                        "name": series.attributes.name
                    },
                    "id": series.id,
                    "type": "series"
                }
            ],
            "process": null,
            "kpis": [],
            "normal_columns": [
                "Status",
                "Name",
                "Description",
                "Value",
                estimate_type,
                "MTD",
                "MTD " + estimate_type
            ],
            "detail_columns": [],
            "mobile_columns": [
                "Description",
                "Value",
                estimate_type
            ],
            "column_text": {},
            "estimate_type": [
                estimate_type
            ],
            "column_formats": {
                [estimate_type + " Variance %"]: {
                    "bold": false,
                    "italic": false,
                    "abbreviate": true,
                    "size": 110,
                    "resize": true,
                    "width": 116
                },
                [estimate_type + " Variance"]: {
                    "bold": false,
                    "italic": false,
                    "abbreviate": true,
                    "size": 100,
                    "resize": true,
                    "width": 111
                },
                ["MTD " + estimate_type + " Variance %"]: {
                    "bold": false,
                    "italic": false,
                    "abbreviate": true,
                    "size": 110,
                    "resize": true,
                    "width": 108
                },
                ["MTD " + estimate_type]: {
                    "bold": false,
                    "italic": false,
                    "abbreviate": true,
                    "size": 100,
                    "resize": true,
                    "width": 126
                },
                "MTD": {
                    "bold": true,
                    "italic": false,
                    "abbreviate": false,
                    "size": 150,
                    "resize": true,
                    "width": 101
                },
                [estimate_type]: {
                    "bold": false,
                    "italic": false,
                    "abbreviate": false,
                    "size": 100,
                    "resize": true,
                    "width": 99
                },
                "Name": {
                    "bold": false,
                    "italic": false,
                    "abbreviate": false,
                    "size": 100,
                    "resize": true,
                    "width": "auto"
                },
                "Description": {
                    "bold": false,
                    "italic": false,
                    "abbreviate": false,
                    "size": 150,
                    "resize": true,
                    "width": 511
                },
                "Value": {
                    "bold": true,
                    "italic": false,
                    "abbreviate": false,
                    "size": 150,
                    "resize": true,
                    "width": 113
                }
            },
            "columns": [
                "Name",
                "Description",
                "Value",
                estimate_type,
                estimate_type + " Variance",
                estimate_type + " Variance %",
                "MTD",
                "MTD " + estimate_type,
                "MTD " + estimate_type + " Variance %"
            ]
        };
        return tile;
    }

    getRelativePerformanceChartConfig(series: Series): Observable<any> {
        let tile = new TileModel();
        tile.attributes = {...this.baseTile, ...tile.attributes};
        tile.attributes.content = "custom-chart";
        tile.attributes.title = "Relative Performance (actual vs interpolated data)"
        tile.id = utils.guid();

        tile.attributes.parameters = {
            "labels": {
                "title": null,
                "sub_title": null,
                "y_axis": null,
                "y2_axis": null
            },
            "hide_comments": false,
            "series_list": [
                {
                    "axis": "y",
                    "colour": "#1f3c74",
                    "cumulative": false,
                    "name": series.attributes.name,
                    "show_limits": false,
                    "type": "line",
                    "line_type": "solid",
                    "line_thickness": "normal",
                    "range": null,
                    "sample_period": null
                }
            ],
            "size": {}
        };

        let estimate_series_name;
        return this.$estimate.pipe(take(1),
            switchMap((estimate_type: SeriesType) => {
                const options = new SearchQueryOptions()
                options.filters = [
                    {name: 'source_series', op: 'any', val: {name: 'id', op: 'eq', val: series.id}},
                    {name: 'series_type', op: 'has', val: {name: 'id', op: 'eq', val: estimate_type.id}}];
                return this.api.series.searchMany(options).pipe(map((result: ListResponse<Series>) => {
                    if (result?.data[0]) {
                        estimate_series_name = result.data[0].attributes.name;
                        if (estimate_series_name) {
                            tile.attributes.parameters.series_list.push({
                                "axis": "y",
                                "colour": "#4286f4",
                                "cumulative": null,
                                "name": estimate_series_name,
                                "show_limits": false,
                                "type": "line",
                                "range": null,
                                "line_type": "dashed"
                            },)
                        }
                    }
                    return tile;
                }))
            }))

    }

    getBudgetChartConfig(series: Series) {
        let tile = new TileModel();
        tile.attributes = {...this.baseTile, ...tile.attributes};
        tile.attributes.content = "budget-bar-chart";
        tile.attributes.title = "Performance vs Target MTD"
        tile.id = utils.guid();

        return this.$estimate.pipe(take(1), map((estimate_type: SeriesType) => {
            tile.attributes.parameters = {
                "series": {
                    "id": series.id,
                    "type": series.attributes.base_type,
                    "attributes": {
                        "name": series.attributes.name,
                        "description": series.attributes.description,
                        "base_type": series.attributes.base_type,
                        "kpi_level": null,
                        "event_type_name": null
                    }
                },
                "target_series_type": estimate_type
            }
            return tile;

        }))
    }

    getPivotTileConfig(series: Series) {
        let tile = new TileModel();
        tile.attributes = {...this.baseTile, ...tile.attributes};
        tile.attributes.content = "model-pivot-tile";
        tile.attributes.title = "Attribute meta data"
        tile.id = utils.guid();

        tile.attributes.parameters = {
            "model_type": "series",
            "pivot_state": {
                "localeStrings": {
                    "renderError": "An error occurred rendering the PivotTable results.",
                    "computeError": "An error occurred computing the PivotTable results.",
                    "uiRenderError": "An error occurred rendering the PivotTable UI.",
                    "selectAll": "Select All",
                    "selectNone": "Select None",
                    "tooMany": "(too many to list)",
                    "filterResults": "Filter values",
                    "apply": "Apply",
                    "cancel": "Cancel",
                    "totals": "Totals",
                    "vs": "vs",
                    "by": "by"
                },
                "derivedAttributes": {},
                "hiddenAttributes": [
                    ""
                ],
                "hiddenFromAggregators": [],
                "hiddenFromDragDrop": [],
                "menuLimit": 1000,
                "cols": [],
                "rows": [
                    "type",
                    "Name",
                    "Description",
                    "Sample period",
                    "Collector names",
                    "Name formula",
                    "Description formula",
                    "Specialised function",
                    "Extra arguments string",
                    "Parent name",
                    "Linked components",
                    "Can edit",
                    "Created on",
                    "Created by name",
                    "Changed by name",
                    "Changed on",
                    "View on flowchart"
                ],
                "vals": [],
                "rowOrder": "key_a_to_z",
                "colOrder": "key_a_to_z",
                "exclusions": {},
                "inclusions": {},
                "unusedAttrsVertical": 85,
                "autoSortUnusedAttrs": false,
                "showUI": false,
                "sorters": {},
                "aggregatorName": "Count",
                "inclusionsInfo": {},
                "rendererName": "Table",
                "renderers": {}
            },
            "series_list": [
                {
                    "id": series.id,
                    "type": "series",
                    "attributes": {
                        "name": series.attributes.name,
                        "description": series.attributes.description,
                        "base_type": "series",
                        "kpi_level": null,
                        "event_type_name": null
                    }
                }
            ],
            "component_list": []
        };
        return tile;
    }

    getCategoryChartConfig(series: Series): Observable<any> {
        let tile = new TileModel();
        tile.attributes = {...this.baseTile, ...tile.attributes};
        tile.attributes.content = "category-chart";
        tile.attributes.title = "Performance across different time periods"
        tile.id = utils.guid();

        tile.attributes.parameters = {
            "labels": {
                "title": "",
                "sub_title": "",
                "y_axis": "",
                "x_axis": ""
            },
            "series_list": [
                {
                    "name": series.attributes.name,
                    "series_id": series.id,
                    "type": "category",
                    "sample_period": {
                        "name": "week",
                        "hours": 168,
                        "wire_sample": "week",
                        "seconds": 604800
                    },
                    "number_of_periods": 4,
                    "color": "#54bf7d",
                    "chart_type": "bar"
                },
                {
                    "name": series.attributes.name,
                    "series_id": series.id,
                    "type": "category",
                    "sample_period": {
                        "name": "day",
                        "hours": 24,
                        "wire_sample": "day",
                        "seconds": 86400
                    },
                    "number_of_periods": 7,
                    "chart_type": "bar",
                    "color": "#333"
                }
            ]
        };

        let estimate_series;
        return this.$estimate.pipe(take(1),
            switchMap((estimate_type: SeriesType) => {
                const options = new SearchQueryOptions()
                options.filters = [
                    {name: 'source_series', op: 'any', val: {name: 'id', op: 'eq', val: series.id}},
                    {name: 'series_type', op: 'has', val: {name: 'id', op: 'eq', val: estimate_type.id}}];
                return this.api.series.searchMany(options).pipe(map((result: ListResponse<Series>) => {
                    if (result?.data[0]) {
                        estimate_series = result.data[0];
                        tile.attributes.parameters.series_list = tile.attributes.parameters.series_list.concat([{
                            "name": estimate_series.attributes.name,
                            "series_id": estimate_series.id,
                            "type": "category",
                            "sample_period": {
                                "name": "day",
                                "hours": 24,
                                "wire_sample": "day",
                                "seconds": 86400
                            },
                            "number_of_periods": 7,
                            "chart_type": "bar",
                            "color": "#666"
                        },
                            {
                                "name": estimate_series.attributes.name,
                                "series_id": estimate_series.id,
                                "type": "category",
                                "sample_period": {
                                    "name": "week",
                                    "hours": 168,
                                    "wire_sample": "week",
                                    "seconds": 604800
                                },
                                "number_of_periods": 4,
                                "color": "#666",
                                "chart_type": "bar"
                            }])
                    }
                    return tile;
                }))
            }))
    }

    getCommentsConfig(series: Series) {
        let tile = new TileModel();
        tile.attributes = {...this.baseTile, ...tile.attributes};
        tile.attributes.content = "comment-tile";
        tile.attributes.title = "Comments"
        tile.id = utils.guid();
        tile.attributes.parameters = {
            "series_list": [series],
            "process_list": [],
        }
        return tile;
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }
}
