import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {MatDialog} from "@angular/material/dialog";
import {DateTimePeriodService} from "../services/date-time-period.service";
import {HeaderDataService} from "../services/header_data.service";
import {UploadFormDialogComponent} from "../forms/upload-form/upload-form.component";
import {EditHistoryComponent} from "../forms/edit-history.component";
import {ApiService} from "../services/api/api.service";
import {PresentationService} from "../services/presentation.service";
import {forkJoin, from} from "rxjs";

export class MenuItem {
    public readonly name: string;
    public readonly link?: string = null;
    public readonly style_class?: string;
    public readonly angular_page?: boolean = false;
    public readonly func?: any;

    constructor(init?: MenuItem) {
        Object.assign(this, init);
    }
}

export interface IMenu {
    name: string;
    items: MenuItem[];
    class: string;
}

export type Menu = Partial<IMenu>;

@Component({
    selector: 'header-menu',
    templateUrl: 'header.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['header.component.less'],
    standalone: false
})

export class HeaderComponent implements OnInit {
    restricted_access: any;
    user: any;
    launch_admin_gui: any;
    session: any;
    @Output()
    finishedLoading: EventEmitter<boolean> = new EventEmitter();

    constructor(public headerData: HeaderDataService,
                public dialog: MatDialog,
                public appScope: AppScope,
                public dateTimePeriodService: DateTimePeriodService,
                public api: ApiService,
                private presentationService: PresentationService) {
    }

    ngOnInit(): void {
        forkJoin([from([this.dateTimePeriodService.dtpInitialisedPromise.promise,
            this.appScope.auth_complete.promise])]).subscribe(() => {

            this.headerData.isImage('/admin/myfiles/download/right-logo.png').then((test) => {
                if (test === true) {
                    this.headerData.right_logo = '/admin/myfiles/download/right-logo.png';
                } else {
                    this.headerData.right_logo = '/static/images/right-logo1.png';
                }
            });
            this.finishedLoading.emit(true);
        });

    }

    pageCommentButtonClicked() {
        this.headerData.pageCommentButtonClicked.next(!(this.headerData.showing_comments && this.headerData.page_selected));
    }

    printToPdf(): void {
        this.headerData.printToPdfClicked.next();
    }

    uploadData(): void {
        const dialogRef = this.dialog.open(UploadFormDialogComponent, {panelClass: 'upload-form-dialog'});
    }

    openEditForm() {
        const dialogRef = this.dialog.open(EditHistoryComponent, {panelClass: 'edit-history-form-dialog'});
    }

    togglePresent() {
        this.presentationService.togglePresent();
    }
}
