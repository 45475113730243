import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import * as c3 from "c3";
import * as d3 from "d3";
import {gen_graph_id} from "../../lib/utils";

@Component({
    selector: 'water-balance-chart',
    templateUrl: './water-balance-chart.component.html',
    styleUrls: ['../../../../node_modules/c3/c3.css', '../charts.less', './water-balance-chart.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WaterBalanceChartComponent implements OnInit, AfterViewInit {
    private chart_anchor: ElementRef;

    @ViewChild('chart_anchor') set setChartAnchor(content: ElementRef) {
        this.chart_anchor = content;
    }

    id_tag: string;
    @Input()
    config: any;
    @Input()
    columns: any;

    constructor() {
    }

    ngOnInit() {
        const ctrl = this;

        const graph_id: string = gen_graph_id(32);
        ctrl.id_tag = "#" + graph_id;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showGraph()
        }, 200);
    }

    showGraph() {
        const ctrl = this;
        ctrl.config.chart = c3.generate({
            size: {width: 800},
            data: {
                x: 'time',
                columns: ctrl.columns,
                axes: {
                    in_flow_actual: 'y2',
                    in_flow_mfcast: 'y2',
                    out_flow_actual: 'y2',
                    out_flow_mfcast: 'y2',
                    level_actual: 'y',
                    level_mfcast: 'y'
                },
                type: 'bar',
                types: {
                    in_flow_actual: 'line',
                    in_flow_mfcast: 'line',
                    out_flow_actual: 'line',
                    out_flow_mfcast: 'line'
                }
            },
            zoom: {
                enabled: true
            },
            grid: {
                y: {
                    lines: [{
                        value: 0
                    }]
                },
                x: {
                    lines: [{
                        value: new Date(),
                        text: 'NOW'
                    }]
                }
            },
            padding: {
                top: 20
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        fit: true,
                        rotate: 40,
                        format: '%e-%b, %Hh'
                    }
                },
                y: {
                    label: {
                        text: 'Level %',
                        position: 'outer-middle'
                    },
                    tick: {
                        // @ts-ignore
                        format: function (v, id, i, j) {
                            return d3.format(",.0f")(v);
                        }
                    },
                    show: true,
                    min: 0,
                    max: 100
                },
                y2: {
                    label: {
                        text: 'Flow Rate m^3',
                        position: 'outer-middle'
                    },
                    tick: {
                        // @ts-ignore
                        format: function (v, id, i, j) {
                            return d3.format(",.0f")(v);
                        }
                    },
                    show: true
                }
            },
            tooltip: {
                grouped: true,
                // @ts-ignore
                format: function (v, id, i, j) {
                    return d3.format(",.2f")(v);
                }
            },
            color: {
                pattern: ['#fe5b00', '#fe9103', '#1457f4', '#59ceff', '#c30d02', '#f44144']
            },
            bindto: this.chart_anchor.nativeElement
        });
    };

}
