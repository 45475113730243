import {BaseViewModel} from "./base-viewmodel";

export class OreBodyGroupViewModel extends BaseViewModel {
    index: number;

    //parent_component: ProcessViewModel;

    constructor(oreBodyGroupData, process, index: number = null) {
        super(oreBodyGroupData);
        this.data.type = 'ore_body_group';
        this.index = index;
        //this.parent_component = process;

        if (!this.data['height']) {
            this.data.height = 80;
        }
        if (!this.data['width']) {
            this.data.width = 80;
        }
        if (!this.data['src']) {
            this.data.src = '/static/icons/custom_icons/mining icon pit.svg';
        }
        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }
        //this.data.data is the actual model object for OBG (attributes, relationships, id, type etc)
        if (!this.data['data']) {
            this.data['data'] = {attributes: {name: ''}};
        }
        if (!this.data['constrain_proportions']) {
            this.data['constrain_proportions'] = true;
        }
    }

    title() {
        try {
            return this.data.data.attributes.name;
        } catch {
            return '';
        }
    };

    x() {
        return this.data.x;
    };

    y() {
        return this.data.y;
    };

    height() {
        return this.data.height;
    };

    width() {
        return this.data.width;
    };

    constrain_proportions() {
        return this.data.constrain_proportions;
    }

    src() {
        return this.data.src;
    };
};
