import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserData} from "../../services/user_data.service";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {FormDialogService} from "../../services/form-dialog.service";
import {Router} from "@angular/router";
import {MenuTreeService} from "../../services/menu-tree.service";
import {takeUntil, tap, first} from "rxjs/operators";
import {Subject, from, forkJoin} from "rxjs";
import {SessionState} from '../../_models/session-state';
import {SearchQueryOptions} from '../../services/api/search-query-options';
import {ListResponse} from '../../services/api/response-types';
import {User} from "../../_models/users";

@Component({
    selector: 'page-list-view',
    templateUrl: './page-list-view.component.html',
    styleUrls: ['./page-list-view.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PageListViewComponent implements OnInit {
    private readonly onDestroy = new Subject<void>();
    user: any;
    restricted_access: boolean;
    actual_restricted_dashboards: any;
    all_dashboards: SessionState[];
    users: User[];
    user_dict: any = {};
    users_ready: boolean;

    constructor(public userData: UserData,
                public appScope: AppScope,
                private api: ApiService,
                private headerData: HeaderDataService,
                private formDialogService: FormDialogService,
                private router: Router,
                private menuTreeService: MenuTreeService) {
    }

    ngOnInit() {
        forkJoin([from([this.appScope.auth_complete.promise])]).subscribe(() => {
            this.buildHeader();
            this.loadDashboards();
        });
    }

    loadDashboards() {
        // session_state takes longer, but we need the full session for relationships and properties
        let options = new SearchQueryOptions();
        let and_filter = [
            {op: 'eq', name: 'report', val: 'Dashboard'},
            {op: 'ne', name: 'visibility', val: 'private'}
        ];
        // ToDo: Test and remove
        if (!this.appScope.current_user.email.endsWith('metalmanagementsolutions.com')) {
            and_filter.push({op: 'ne', name: 'visibility', val: 'mms'});
        }
        options.filters = [{
            or: [{and: and_filter},
                {op: 'has', name: 'user', val: {name: 'id', op: 'eq', val: this.appScope.current_user.id}}]
        }];
        this.api.session_state.searchMany(options).pipe(
            first(), takeUntil(this.onDestroy), tap((result: ListResponse<SessionState>) => {
                this.all_dashboards = result.data;
            })).subscribe();

        this.restricted_access = this.appScope.current_user.restricted_access;

        this.api.users.searchMany().subscribe(response => {
            this.users = response.data;
            this.users.forEach(user => {
                this.user_dict[user.id] = user;
            });
            this.users_ready = true;
        });
        if (this.restricted_access) {
            let $restricted_dashboards = this.userData.getRestrictedDashboards().subscribe((response) => {
                this.actual_restricted_dashboards = response.data;
            });
        }
    }

    buildHeader() {
        const ctrl = this;
        this.headerData.title = 'All Pages';
        ctrl.headerData.buttons.push(...[
            {
                name: 'New Page',
                func: ctrl.openNewPageForm.bind(ctrl),
                params: 'current',
                class: 'icon-edit hide-xs'
            }]);
    }

    openNewPageForm() {
        const ctrl = this;
        const dialogRef = this.formDialogService.openNewPageDialog();

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.menuTreeService.refresh();
                if (result?.id) {
                    this.router.navigateByUrl('/view/page_view/' + result.id);
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
