<div class="colour-palette" *ngIf="!use_picker">
  <mat-form-field class="bordered-select" [ngClass]="{'no-label':!label}">
    <mat-label>{{label}}</mat-label>
    <mat-select [disabled]="!paletteColours || paletteColours.length < 1" [(ngModel)]="value" style="background:white;"
                name="color" aria-label="Colour picker"
                (selectionChange)="emitSelect($event)"
    panelClass="colour-picker-panel">
      <mat-select-trigger *ngIf="value=='#fff'">
        <!--        <div style="height:12px;width:12px;margin-left:10px;background-color:#fff;border:1px solid black; display:inline-block;">-->
        <span [style.background-color]="'white'" class="colour-block" style="border:1px solid black;"></span>
        <span *ngIf="show_text">White</span>
        <!--        </div>-->
      </mat-select-trigger>
      <mat-select-trigger *ngIf="value!=='#fff'">
        <span [style.background-color]="value" class="colour-block"></span>
        <span *ngIf="show_text" class="show-text">{{palette_dict[value]}}</span>
      </mat-select-trigger>
      <mat-option value="#fff">
        <div [style.background-color]="'white'" class="colour-block" style="border:1px solid black;"></div>
        <div *ngIf="show_text">White</div>
      </mat-option>
      <mat-option *ngFor="let colour of paletteColours" [value]="colour.colour">
        <div [style.background-color]="colour.colour" class="colour-block"></div>
        <div *ngIf="show_text"> {{colour.name}}</div>
      </mat-option>
    </mat-select>

  </mat-form-field>
</div>

<ng-container *ngIf="use_picker===true">
  <button class="colour-picked" mat-button [style.background]="value"
          [matTooltip]="'Please click to select a custom colour'"
          [matMenuTriggerFor]="colourMenu"
          (click)="$event.stopPropagation();">
  </button>
  <mat-menu #colourMenu="matMenu" class="column-menu aggregation-menu">
    <div class="color-picker-menu-container color-picker-container">
      <span [(colorPicker)]="value"
            [style.background]="value"
            [cpPresetColors]="preset_colours"
            (colorPickerChange)="emitSelect($event)"
            [cpToggle]="true"
            [cpDialogDisplay]="'inline'"
      ></span>
    </div>
  </mat-menu>

</ng-container>
