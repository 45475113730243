<div class="form-dialog">
  <h1>Download
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content>

    <mat-tab-group [disableRipple]="true" [(selectedIndex)]="selectedTabIndex"
                   (selectedTabChange)="clearTabData($event)">
      <mat-tab label="Instructions">
        <div>
          <h2>Here you can download Series and Component data. The details of which are defined here.</h2>

          <h2>What is Series Data?</h2>
          <p [ngStyle]="{'font-weight':600, 'font-size': 'larger'}">
            Series, or time series, data is all collated data that represents LIMS, SCADA & Historian data from various
            Collectors, tags,
            CSV uploads, user inputs and other sources.
          </p>
          <h2>How to download Series and Process data</h2>

          <p [ngStyle]="{'font-weight':600, 'font-size': 'larger'}">Click on the Series Data Tab, select the series
            which
            represents your data, and click on download button. This will download data according to the current start
            and end times on the WIRE time traveller. The data will also be aggregated to the Period (hour, day, week,
            etc.) in the time traveller as well.</p>

          <h2>What is Component Data?</h2>

          <p [ngStyle]="{'font-weight':600, 'font-size': 'larger'}">

            Component Data compromises data that is collated from various sources, constructed and designed to represent
            real life resources,
            equipment and processes.

          </p>

          <h2>How to download Component data</h2>

          <p [ngStyle]="{'font-weight':600, 'font-size': 'larger'}">

            Click on the Component Data tab, select the component type you'd like to download data for,
            and the properties which you'd like to include in the data set. This will download data according to the
            current start
            and end times on the WIRE time traveller, and it will include all component in this time range in a CSV
            file. Data for components can also be downloaded via the component-events-table tile.

          </p>


        </div>
      </mat-tab>
      <mat-tab label="Series Data">
        <form class="form-section column stretch">
          <p [ngStyle]="{'font-weight':600,'font-size': 'larger'}">Select a process to download associated process data,
            alternatively select specific series below </p>


          <div class="row center">
            <select-search-component [value]="selected_data.process"
                                     [api_model]="'process_light'"
                                     (selectionChange)="selected_data.process=$event.value"
                                     [label]="'Select a process'"
                                     [classes]="'fill'"
                                     class="select-search">
            </select-search-component>

            <div class="form-element-align"
                 style="text-align: center;font-weight: bold;padding:0 10px; justify-self: center;">or
            </div>

            <select-many-series [value]="selected_series"
                                (selectionChange)="selected_series=$event"
                                [label]="'Select series'"
                                [classes]="'fill'"
                                class="select-search"
                                style="height:53px;">
            </select-many-series>


          </div>
        </form>
      </mat-tab>

      <mat-tab label="Component Data">
        <form class="form-section column stretch">
          <select-search *ngIf="componentTypes.length"
                         (selectChange)="selectComponentType($event.value.id)"
                         [(value)]="selectedComponentType"
                         [data]="componentTypes"
                         label="Select Component Type">
          </select-search>

          <mat-checkbox
              *ngIf="selectedComponentType"
              [(ngModel)]="selectAllConstantProperties"
              name="selectAllConstantProperties" style="margin-bottom: 20px;">
            Select All Constant Properties for the Component Type
          </mat-checkbox>

          <div class="select-search" style="flex-grow:2;position: relative;" *ngIf="selectedComponentType">

            <select-many-search *ngIf="allComponentTypeConstantProperties.length>0"
                                [(value)]="selectedConstantProperties"
                                [classes]="'fill'"
                                [data]="allComponentTypeConstantProperties"
                                [disabled]="selectAllConstantProperties"
                                label="Select Constant Properties">

            </select-many-search>

          </div>
        </form>
      </mat-tab>

    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button *ngIf="selectedTabIndex=== 1" (click)="downloadData()" class="btn"><i
        class="fas fa-long-arrow-alt-down"></i>Download
    </button>
    <button *ngIf="selectedTabIndex=== 2" (click)="downloadComponentTypeData()" class="btn"><i
        class="fas fa-long-arrow-alt-down"></i>Download csv
    </button>
  </div>
</div>
