import {Injectable, OnDestroy} from "@angular/core";

import ExcelJS from 'exceljs';
import {CsvDownloadService} from "./csv-download.service";
import {
    SeriesDataSeriesGroupDict,
    SeriesDataTableConfig, SeriesGroupConfig
} from "../forms/series-data-table-form/series-data-table-form.component";
import * as utils from "../lib/utils";
import {IDMap, KeyMap} from "../_typing/generic-types";
import {TimePeriod} from "../_typing/components/time-period";
import {Series} from "../_models/series";
import {FileDownloadOption} from "../_typing/download-filetype";
import {SeriesDataData} from "../_typing/components/series-data-table";
import {SeriesSummary} from "../_models/api/series-summary";
import {upperfirst} from "../lib/utils";
import {ColumnFormatsConfigDict} from "../forms/table-column-menu/table-column-menu.component";
import {FormatNumberPipe} from "../shared/pipes";
import {DateTimeInstanceService} from "./date-time-instance.service";

@Injectable()
export class SeriesDataTableDownloadService {
    constructor(private dateInst: DateTimeInstanceService,
                private downloadService: CsvDownloadService) {
    }

    private config: SeriesDataTableConfig;
    private groupList: string[];
    private groupDict: SeriesDataSeriesGroupDict;
    private seriesDict: IDMap<SeriesGroupConfig>;
    private times: string[];
    private seriesData: SeriesDataData;
    private seriesSummary: IDMap<SeriesSummary>;
    private formatDict: ColumnFormatsConfigDict;
    private fileType: FileDownloadOption;

    private setOptions(config: SeriesDataTableConfig, groupList: string[], groupDict: SeriesDataSeriesGroupDict, seriesDict: IDMap<SeriesGroupConfig>,
                       times: string[], seriesData: SeriesDataData, seriesSummary: IDMap<SeriesSummary>, formatDict: ColumnFormatsConfigDict, fileType: FileDownloadOption) {
        this.config = config;
        this.groupList = groupList;
        this.groupDict = groupDict;
        this.seriesDict = seriesDict;
        this.times = times;
        this.seriesData = seriesData;
        this.seriesSummary = seriesSummary;
        this.formatDict = formatDict;
        this.fileType = fileType;
    }

    createTableLayoutExcelJS(config: SeriesDataTableConfig, groupList: string[], groupDict: SeriesDataSeriesGroupDict, seriesDict: IDMap<SeriesGroupConfig>,
                             times: string[], seriesData: SeriesDataData, seriesSummary: IDMap<SeriesSummary>, formatDict: ColumnFormatsConfigDict, fileType: FileDownloadOption): void {
        const dtp = this.dateInst.dtp;

        //Easier to work with between functions as this service is provided to series-data-table
        this.setOptions(config, groupList, groupDict, seriesDict, times, seriesData, seriesSummary, formatDict, fileType);

        // Initialize ExcelJS workbook and worksheet
        const wb: ExcelJS.Workbook = new ExcelJS.Workbook();
        let ws: ExcelJS.Worksheet = wb.addWorksheet('Sheet1');

        const headers = this.getExportHeaders();
        headers.forEach((header, index) => {
            ws.addRow(header);
        });
        const gssRows = this.getExportSeriesSummary();
        let gssHeaders = [];
        const start = this.config.show_gss === 'top' ? headers.length + 1 : headers.length + this.times.length;

        if (this.seriesSummary && this.config.show_gss === 'top') {
            gssRows.forEach(row => ws.addRow(row));
        }

        for (let i = start; i < start + this.config.gss?.columns.length; i++) {
            gssHeaders.push(i);
        }


        const seriesFormatDict: KeyMap<string> = {};
        // Data rows
        this.times.forEach(time => {
            const dataRow: (string | number)[] = this.fileType.includes('formatted') ? [dtp.sample_period.format(new Date(time), dtp)] : [time];
            let index = 1;
            this.groupList.forEach(group => {
                this.groupDict[group].forEach(series => {
                    let value = this.seriesData[time][this.seriesDict[series.id]?.attributes?.name];
                    index += 1;
                    if (this.times.indexOf(time) < 1) {
                        if (this.fileType.includes('formatted')) {
                            const format = this.formatNumber(0, this.formatDict[series.id]?.decimals, this.seriesDict[series.id]);
                            seriesFormatDict[index] = String(format);
                        }
                    }
                    //For csv we have to change the actual values, not just pass in the format
                    if (this.fileType === 'formatted_csv' && !isNaN(Number(value))) {
                        value = this.formatNumber(Number(value), this.formatDict[series.id]?.decimals, this.seriesDict[series.id]);
                    }
                    dataRow.push(value);
                });
            });
            ws.addRow(dataRow);
        });

        if (this.seriesSummary && this.config.show_gss === 'bottom') {
            gssRows.forEach(row => ws.addRow(row));
        }

        ws = this.getExportFormats(headers, ws, seriesFormatDict, gssHeaders);

        this.exportTable(wb);
    }

    private getExportHeaders(): string[][] {
        const blank = "";
        const header1 = ["Time"];
        const header2 = [blank];
        const header3 = [blank];
        const header4 = [blank];
        // Process headers
        if (this.config.header_rows.process || this.config.header_rows.custom_groups) {
            this.groupList.forEach(group => {
                header1.push(group);
                const groupLength = this.groupDict[group]?.length || 1;
                if (groupLength > 1) {
                    for (let i = 1; i < groupLength; i++) {
                        header1.push(blank);
                    }
                }
            });
        }

        if (this.config.header_rows.name || this.config.header_rows.description) {
            this.groupList.forEach(group => {
                this.groupDict[group].forEach(series => {
                    if (this.config.header_rows.name) {
                        header2.push(this.seriesDict[series.id]?.attributes.name || blank);
                    }
                    if (this.config.header_rows.description) {
                        header2.push(this.seriesDict[series.id]?.attributes.description || blank);
                    }
                });
            });
        }

        if (this.config.header_rows.alias) {
            this.groupList.forEach(group => {
                this.groupDict[group].forEach(series => {
                    header3.push(this.seriesDict[series.id]?.attributes.alias || blank);
                });
            });
        }

        if (this.config.header_rows.unit) {
            this.groupList.forEach(group => {
                this.groupDict[group].forEach(series => {
                    header4.push(this.seriesDict[series.id]?.attributes.engineering_unit_name || blank);
                });
            });
        }

        // Add headers to the worksheet
        const headers = [header1, header2, header3, header4];
        const filteredHeaders = headers.filter(header => {
            return header.length > 0 && !(header.length === 1 && header[0] === blank);
        });
        return filteredHeaders;
    }

    private getExportSeriesSummary(): string[][] {
        let dataRows = [];
        this.config.gss?.columns.forEach(column => {
            let dataRow = [upperfirst(column)];
            this.groupList.forEach(group => {
                this.groupDict[group].forEach(series => {
                    let value = this.seriesSummary[series.id][column] || ""
                    if (this.fileType === 'formatted_csv' && !isNaN(Number(value))) {
                        value = this.formatNumber(Number(value), this.formatDict[series.id]?.decimals, this.seriesDict[series.id]);
                    }
                    dataRow.push(value)
                });
            });
            dataRows.push(dataRow);
        })
        return dataRows;
    }

    private getExportFormats(headers, ws: ExcelJS.Worksheet, seriesFormatDict: KeyMap<string>, gssHeaders: number[]): ExcelJS.Worksheet {
        const headerRowCount = Object.values(this.config.header_rows).filter(hr => hr === true).length - (this.config.header_rows.edit ? 1 : 0);

        // Apply cell merges after rows are added
        if (this.config.header_rows.process || this.config.header_rows.custom_groups) {
            let currentColumn = 2;
            this.groupList.forEach(group => {
                const groupLength = this.groupDict[group]?.length || 1;
                if (groupLength > 1) {
                    ws.mergeCells(1, currentColumn, 1, currentColumn + groupLength - 1); // Merge cells in the first row
                }
                currentColumn += groupLength;
            });
        }
        ws.mergeCells(1, 1, headerRowCount, 1); //The 'Time' cell

        ws.eachRow((row, i) => {
            const isHeader = i <= headerRowCount;
            row.eachCell((cell, c) => {
                const isGssHeader = gssHeaders.includes(Number(i)) && c === 1;
                const alignCenter = (this.config.header_rows.process || this.config.header_rows.custom_groups) && i === 1 && c > 1;
                const alignMiddle = i <= headerRowCount && c === 1;
                cell.font = {
                    name: 'Arial',
                    size: 12,
                    bold: isHeader || isGssHeader,
                };
                cell.alignment = {
                    vertical: alignMiddle ? 'middle' : undefined,
                    horizontal: alignCenter ? 'center' : undefined
                }
                if (!isHeader && c > 1) {
                    row.getCell(c).numFmt = seriesFormatDict[c];
                }
            });
        });


        // Auto-fit columns to content
        ws.columns.forEach(column => {
            let maxLength = 0;
            column.eachCell({includeEmpty: true}, cell => {
                const cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                }
            });
            column.width = maxLength + 2; // Add some extra space
        });

        return ws;
    }

    async exportTable(wb) {
        const dtp = this.dateInst.dtp;
        const docTitle: string = utils.printDocTitle('SeriesDataTable', dtp.start, dtp.end);

        const buffer = await (this.fileType.includes('csv') ? wb.csv.writeBuffer() : wb.xlsx.writeBuffer());
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const extension = this.fileType.includes('_csv') ? '.csv' : '.xlsx';
        this.downloadService.downloadCsv(url, docTitle, extension);
    }

    formatNumber(value: number, decimals, series?: SeriesGroupConfig): number {
        if (isNaN(value)) {
            return value;
        }
        let formatted = utils.thousandsSeparate(new FormatNumberPipe().transform(value, decimals, true, 3, false, series));

        if (!formatted && formatted !== 0) return value;
        formatted = formatted.replace(',', '');
        return formatted;
    }
}