<table st-table="rowCollection" id="series_component_table"
       class="table table-sortable table-striped  table-bordered table-hover"
       style="margin-top:10px; ">
  <thead>
  <tr>
    <th>Series</th>
    <th>Text</th>
    <th style="text-align:center;">Show</th>
    <th>Report Group</th>
  </tr>
  </thead>
  <tbody *ngFor="let series_component of component.relationships.series_components.data">
  <tr>
    <td>
      <div style="width:300px;min-height:30px;height: 20px;align-items: center;" class="row">
        <i class="fa fa-trash hideonprint" (click)="deleteSeriesComponent(series_component)"
           style="margin-right:10px;"></i>
        <i class="fa fa-edit hideonprint" (click)="showSeriesForm(series_component)" style="margin-right:10px;"></i>
        <div class="select-search" style="flex-grow:2" (mouseenter)=" hint='select_series'">
          <select-search-component class="select-search"
                                   (selectionChange)="series_component.relationships.series.data.id=$event.value"
                                   [label]="''"
                                   [api_model]="'series_light'"
                                   [value]="series_component.relationships.series.data.id"
                                   [compareFunctionName]="'compareValueToId'">
          </select-search-component>

        </div>
      </div>
    </td>
    <td><input matInput [(ngModel)]="series_component.attributes.json[tree_position].name"
               style="text-align: left;width:120px;"/></td>

    <td style="text-align: center;" *ngIf="tree_position == 'parent'">
      <mat-checkbox style="width:20px;" [(ngModel)]="series_component.attributes.view_on_parent_flowchart"
                    aria-label="Show on flowchart"></mat-checkbox>
    </td>
    <td style="text-align: center;" *ngIf="tree_position == 'child'">
      <mat-checkbox style="width:20px;" [(ngModel)]="series_component.attributes.view_on_flowchart"
                    aria-label="Show on flowchart"></mat-checkbox>
    </td>


    <td><input matInput [(ngModel)]="series_component.attributes.report_group" style="text-align: left;width:120px;"/>
    </td>
  </tr>
  </tbody>
</table>
