import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {PlantDataService} from "../services/plant-data/plant_data.service";
import * as utils from "../lib/utils";
import {MenuTreeService} from "../services/menu-tree.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {getManyRelationWithIdFilter, getRelationWithIdFilter} from "../services/api/filter_utils";
import {take, tap} from "rxjs/operators";
import {ApiService} from "../services/api/api.service";
import {ModelID} from "../_typing/generic-types";

export interface TreeNodeEntry {
    link: string;
    name: string;
    action: () => void;
}

@Component({
    selector: 'treenode',
    templateUrl: 'tree_node.component.html',
    styleUrls: ['./side-nav.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TreeNodeComponent implements OnInit {
    _item: any;

    get item() {
        return this._item;
    }

    @Input() set item(item: any) {
        this._item = item;
        this.getItemMenu();
    }

    menu_entries: TreeNodeEntry[] = [];
    loadingChildren: ModelID;

    constructor(public plantData: PlantDataService,
                public appScope: AppScope,
                private menuService: MenuTreeService,
                private api: ApiService) {
    }

    ngOnInit() {
    }

    getItemMenu() {
        if (this.appScope.isNotMobile) {
            if (this.item) {
                this.menu_entries = this.menuService.getProcessMenu(this.item.id, this.closeSidenav.bind(this));
            } else {
                this.menu_entries = [];
            }
        } else {
            this.menu_entries = [
                {link: '/view/log_sheet/' + this.item.id, action: this.closeSidenav.bind(this), name: "Log Sheet  "}
            ];
        }
    }

    closeSidenav() {
        this.appScope.sidenavOpenSubject.next(false);
        this.appScope.sidenav_open = false;
    }

    getChildren(process) {
        if (!process.expanded) {
            if(!this.appScope.processTreeDataDict[process.id]){
                this.appScope.processTreeDataDict[process.id] = process;
            }
            if (!this.appScope.processTreeDataDict[process.id]?.children) {
                let loading = true;
                let options = new SearchQueryOptions()
                options.filters = [getRelationWithIdFilter('parent', process.id)];
                //Prevent flash of loading dots if api call is super quick
                setTimeout(() => {
                    if (loading === true) {
                        this.loadingChildren = process.id;
                    }
                }, 100);
                this.api.process_light.searchMany(options).pipe(take(1), tap(result => {
                    loading = false;
                    this.loadingChildren = null;
                    result.data.forEach(p => {
                        this.appScope.processTreeDataDict[p.id] = this.appScope.getProcessDictItem(p, false);
                        process.expanded = true;
                    })
                    this.appScope.processTreeDataDict[process.id].children = result.data.map(p => p.id);
                })).subscribe();
            } else {
                process.expanded = true;
            }
        } else {
            process.expanded = false;
        }
    }
}
