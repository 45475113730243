import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EventType} from "../../_models/event-type";
import * as utils from "../../lib/utils";
import {AppScope} from '../../services/app_scope.service';
import {EventTypeService} from '../../services/event_type.service';
import {NotificationService} from "../../services/notification.service";
import {Subject} from 'rxjs';
import {MatTabGroup} from "@angular/material/tabs";
import {OreBodyType} from "../../_models/ore-body-type";
import {SaveService} from '../../services/save/save.service';

export interface OreBodyTypeDialogData {
    ore_body_type: any;
}

@Component({
    selector: 'ore-body-type-form',
    templateUrl: './ore-body-type-form-dialog.component.html',
    styleUrls: ['./ore-body-type-form-dialog.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SaveService],
    standalone: false
})

export class OreBodyTypeFormDialogComponent implements OnInit {
    ore_body_type: OreBodyType;
    event_types_list: EventType[];
    saved_ore_body_type: OreBodyType;

    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                public dialogRef: MatDialogRef<OreBodyTypeFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: OreBodyTypeDialogData,
                private appScope: AppScope,
                private notification: NotificationService,
                private eventTypeService: EventTypeService,
                private saveService: SaveService) {

        this.ore_body_type = this.data.ore_body_type;

        if (this.ore_body_type && this.ore_body_type.id) {
        } else {
            this.ore_body_type = new OreBodyType();
        }
    }

    ngOnInit() {
        const ctrl = this;
        this.eventTypeService.getEventTypes().subscribe(result => {
            this.event_types_list = result.data;
            if (this.ore_body_type && this.ore_body_type.id) {
                utils.fill_object_relations([this.ore_body_type], this.event_types_list, 'event_types');
            }
        });

        // Keep a copy to pass back to the table on close without save
        this.saved_ore_body_type = utils.deepCopy(this.ore_body_type);
    }

    goToTab(tabGroup: MatTabGroup, tab) {
        tabGroup.selectedIndex = tab;
    }

    save() {
        if (this.validateForm() === false) {
            return;
        }
        this.apply();
        // this.onCloseClick();
    }

    validateForm(): boolean {
        if (!this.ore_body_type.attributes.name) {
            this.notification.openError("Please enter a name for this Ore Body. ");
            return false;
        }
        return true;
    }

    apply() {
        const ctrl = this;
        let $obs;
        let data = utils.deepCopy(this.ore_body_type);
        OreBodyType.cleanup(data);

        //FIXME
        delete this.ore_body_type.relationships.constant_properties;
        if (this.ore_body_type.id) {
            $obs = this.api.ore_body_type.patch(data);
        } else {
            $obs = this.api.ore_body_type.save(data);
        }
        $obs.then(result => {
            if (result) {
                this.ore_body_type.id = result.data.id;

                //this allows table to reset event_type if user clicks apply then close and/or close without saving
                this.saved_ore_body_type = utils.deepCopy(this.ore_body_type);
                this.notification.openSuccess("Ore Body Type saved. ", 2000);
            }
            /**For constant_properties**/
            this.saveService.emitSave();
        }, error => {
            console.error('Error saving/updating ore body type changes:\n', error);
            ctrl.notification.openError("Error saving this Ore Body: " + error);
        });
    }

    onCloseClick(): void {
        this.dialogRef.close(this.saved_ore_body_type);
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
