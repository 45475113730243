<div class="series-data-table-form">
  <div class="options-group">
    <h3>Table header options
    </h3>
    <div class="column full-width" style="align-items: flex-start;padding-bottom:14px;margin-right:20px;">
      <mat-checkbox [(ngModel)]="config.header_rows.process"
                    (change)="updateGroups($event, 'process')"
                    name="process_groups" style="padding:4px 0;">Show PROCESS GROUPS row
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.header_rows.custom_groups"
                    (change)="updateGroups($event, 'custom')"
                    name="custom_groups" style="padding:4px 0;">Show CUSTOM GROUPS row
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.header_rows.alias"
                    name="alias" style="padding:4px 0;">Show series ALIAS row
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.header_rows.unit"
                    name="unit" style="padding:4px 0;">Show series UNIT row
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.header_rows.name"
                    name="name" [disabled]="config.header_rows.description"
                    style="padding:4px 0;"
                    matTooltip="Please select either name or description">Show series NAME row
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.header_rows.description"
                    name="description" [disabled]="config.header_rows.name"
                    style="padding:4px 0;"
                    matTooltip="Please select either name or description">Show series DESCRIPTION row
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="config.header_rows.edit"
                    name="alias" style="padding:4px 0;">Show EDIT series row
      </mat-checkbox>

    </div>
  </div>
  <div class="row">
    <mat-form-field style="font-size:1rem;min-width: calc(50% - 20px);max-width: 50%;">
      <mat-label>Table Orientation</mat-label>
      <mat-select [(ngModel)]="config.orientation">
        <mat-option [value]="'vertical'">Vertical</mat-option>
        <mat-option [value]="'horizontal'">Horizontal</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <select-many-search-component
        [api_model]="'process_light'"
        (selectionChange)="processSelected($event)"
        [value]="config.process_list"
        [classes]="'fill'"
        [label]="'Select Processes'"
        class="select-search flex-half alone">
    </select-many-search-component>
  </div>

  <!--Series for selected processes----------------------------------------------------------->
  <ng-container *ngIf="config.process_list">
    <ng-container *ngFor="let process of config.process_list">
      <div class="series-data-form options-group">
        <h3>{{process.attributes.name | capitalise}}</h3>
        <div class="row start">
          <select-many-search-component *ngIf="process_filters[process.id]"
                                        [api_model]="'series_light'"
                                        (selectionChange)="seriesSelected($event, process.id)"
                                        [custom_filters]="process_filters[process.id]"
                                        [value]="config.process_series_map[process.id]"
                                        [classes]="'fill'"
                                        [label]="'Select Series for Process ' + (process.attributes.name | capitalise)"
                                        class="flex-half">
          </select-many-search-component>

          <div class="process-series-container flex-half">
            <div class="row drop-box-labels">
              <label class="flex-half">Series</label>
              <label class="flex-half">Groups</label>
            </div>
            <div #selectedList="cdkDropList"
                 (cdkDropListDropped)="drop($event)"
                 [cdkDropListData]="config.process_series_map[process.id]"
                 cdkDropList
                 class="process-series-list">

              <div *ngFor="let item of config.process_series_map[process.id]" cdkDrag
                   class="process-series-box">
                <div class="flex-half">
                  <div [ngStyle]="{'font-weight': config.column_formats[item.id]?.bold? 'bold' : '',
          'font-style': config.column_formats[item.id]?.italic? 'italic' : '',
          'font-size': config.column_formats[item.id]?.size ? config.column_formats[item.id]?.size + '%' : '100%'}"
                       matTooltip="{{item.attributes | nameOrDescription}}"
                  >{{item.attributes | nameOrDescription}}
                  </div>

                </div>
                <div class="row center flex-half">
                  <input [(ngModel)]="item.group" style="flex-grow: 1" [disabled]="!config.header_rows.custom_groups">
                  <div>
                    <button [matMenuTriggerFor]="columnMenu" class="formatting-button" mat-button
                            matTooltip="Format column" matTooltipPosition="above"
                            style="margin:0;background-color:#8b8c8e;">
                      <i class="fa fa-bars"></i>
                    </button>

                    <mat-menu #columnMenu="matMenu" class="column-menu dark series-formatting-menu">
                      <table-column-menu [item]="config.column_formats[item.id]"
                                         [options]="col_menu_options"
                                         [default_values]="{decimals:null}"
                                         (formatsChanged)="config.column_formats[item.id]=$event"></table-column-menu>
                    </mat-menu>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </ng-container>

    <h2 style="margin-top:40px;">Series Summary Columns</h2>
    <div class="row flex-half alone">
      <mat-form-field>
        <mat-label>Show Series Summary values</mat-label>
        <mat-select [(ngModel)]="config.show_gss">
          <mat-option [value]="'none'">Don't show</mat-option>
          <mat-option [value]="'top'">Top / Start</mat-option>
          <mat-option [value]="'bottom'">Bottom / End</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="column series-table-form">
      <series-summary-column-selector [config]="config.gss" [include_descriptor_columns]="false"
                                      [disabled]="!config.show_gss || config.show_gss==='none'"
                                      (columnsChanged)="config.gss = $event;"></series-summary-column-selector>
    </div>
  </ng-container>

</div>
