import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../services/api/api.service";
import {deepCopy, guid} from "../../lib/utils";
import {concatMap, map, take} from "rxjs/operators";
import {GridItem, Section, TileDataService} from "../../services/tile_data.service";
import {NotificationService} from "../../services/notification.service";
import {ModelID} from "../../_typing/generic-types";
import {Tile} from "../../_models/tile";
import {EXTENDED_NOTIFICATION_DURATION_MS} from "../../shared/globals";
import {forkJoin, Observable, of} from "rxjs";
import {SessionState} from "../../_models/session-state";
import {HeaderDataService} from "../../services/header_data.service";
import {PageDataService} from "../../data/page-data.service";
import {SingleResponse} from "../../services/api/response-types";

@Component({
    selector: 'copy-tile-form-dialog',
    templateUrl: './copy-tile-form-dialog.component.html',
    styleUrls: ['copy-tile-form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CopyTileFormDialogComponent {
    tile: Tile;
    page: SessionState;
    section: Section;

    selectedPageId: ModelID;
    currentPageId: ModelID;
    selectedPage: SessionState;
    fetchingPageInfo: boolean = false;
    byReference: boolean = false;

    constructor(private api: ApiService,
                private dialogRef: MatDialogRef<CopyTileFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: { tileId: ModelID, sessionStateId: ModelID },
                private tileData: TileDataService,
                private pageDataService: PageDataService,
                private headerData: HeaderDataService,
                private notification: NotificationService) {
        const id = dialogData.tileId;
        this.currentPageId = dialogData.sessionStateId;
        if (!id || !this.currentPageId) return;

        forkJoin([this.api.tile.getById(id), this.api.session_state.getById(this.currentPageId)]).pipe(take(1))
            .subscribe(([tile, sessionState]) => {
                this.tile = tile.data;
                this.page = sessionState.data;
                this.findSection();
            })

    }

    findSection() {
        this.page.attributes.json.sections.forEach(s => {
            if (s.tiles.find(t => t.id === this.tile.id)) {
                this.section = s;
            }
        })
    }

    selectCurrentPage(event) {
        if (event.checked) {
            this.selectedPageId = this.currentPageId;
            this.getSelectedSession();
        } else {
            this.selectedPageId = null;
            this.selectedPage = null;
        }
    }

    selectedPageChanged(event) {
        this.fetchingPageInfo = true;
        this.selectedPageId = event.value.id;
        this.getSelectedSession();
    }

    getSelectedSession() {
        if (this.selectedPageId === this.currentPageId) {
            this.selectedPage = deepCopy(this.page);
            this.fetchingPageInfo = false;
        } else {
            this.api.session_state.getById(this.selectedPageId).pipe(take(1))
                .subscribe(sessionState => {
                    this.selectedPage = sessionState.data;
                    this.fetchingPageInfo = false;
                })
        }
    }

    byReferenceChanged(event) {
        if (event.value && this.selectedPageId === this.currentPageId) {
            this.selectedPageId = null;
            this.selectedPage = null;
        }
    }

    copyTile() {
        let newTile: Tile = deepCopy(this.tile);
        let $saveTile: Observable<Tile>;
        const tileSessionStateRelationship = [{id: this.selectedPageId, type: 'session_state'}]
        if (this.byReference) {
            newTile.relationships.session_states.data = (newTile.relationships.session_states.data || []).concat(tileSessionStateRelationship);
            $saveTile = of(newTile);
        } else {
            delete newTile.id;
            newTile.attributes.title = `Copy of ${this.tile.attributes.title || this.tile.attributes.content}`;
            newTile.relationships.session_states.data = tileSessionStateRelationship;
            $saveTile = this.tileData.saveTile(newTile).pipe(map(tile => tile.data));
        }

        $saveTile.pipe(take(1),
            concatMap((savedTile: Tile) => {
                let newSection = this.headerData.addSection(guid());
                newSection.cols = this.section.cols;
                newSection.rows = this.section.rows;

                let gridTile: GridItem = {
                    id: savedTile.id,
                    parent_id: newSection.id,
                    x: 0,
                    y: 0
                };

                newSection.tiles.push(gridTile);
                this.selectedPage.attributes.json.sections.push(newSection);
                this.selectedPage.relationships.tiles.data.push({type: 'tile', id: savedTile.id});
                return this.pageDataService.savePage(this.selectedPage);
            })).subscribe({
            next: result => {
                if (this.selectedPageId === this.currentPageId) {
                    this.pageDataService.pageSectionsUpdated.next(this.selectedPage.attributes.json.sections);
                }
                this.notification.openSuccess("Tile copied successfully")
                this.dialogRef.close();
            }, error: (err) => {
                this.notification.openError("There was an error copying this tile. ", EXTENDED_NOTIFICATION_DURATION_MS, "Close")
                console.log('ERROR: CopyTileFormDialog (copyTile) \n Tile Not Copied: ', err);
            }
        });


    }

    close() {
        this.dialogRef.close();
    }
}
