import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {AppScope} from '../../services/app_scope.service';
import * as utils from '../../lib/utils';
import {BehaviorSubject, from, Observable, of, Subject} from "rxjs";
import {debounceTime, first, map, scan, take, takeUntil, tap} from "rxjs/operators";
import {ScrollableDirective} from '../../directives/mention.directive';
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {TileDataService} from "../../services/tile_data.service";
import {SelectSearchApiService} from "../select-search-api/select-search-api.service";
import {User} from "../../_models/users";

@Component({
    selector: 'at-who',
    templateUrl: './at-who.component.html',
    styleUrls: ['./at-who.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SelectSearchApiService],
    standalone: false
})
export class AtWhoComponent implements OnInit, AfterViewInit, OnDestroy {
    users: User[];
    filtered_users: User[];
    searchQuery: string = '';
    list_index: number = 0;

    options$: Observable<any[]>;
    search_string = '';
    @ViewChild('user_list') user_list: ElementRef;
    @ViewChild(ScrollableDirective) list: ScrollableDirective;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                public appScope: AppScope,
                private renderer: Renderer2,
                public sapi: SelectSearchApiService,
    ) {

        this.options$ = this.sapi.connect(this.mapFunction);
        this.options$.pipe(
            tap(list => {
                this.filtered_users = list;
            })
        ).subscribe();
    }

    mapFunction(curr) {
        return curr;
    };

    ngOnInit() {
        //FIXME Add a separate emitter for filter_string so that we can add a delay on typing
        this.appScope.mentionEmit.pipe(takeUntil(this.onDestroy)
        ).subscribe(string => {
            this.doSearch(string);
        });
    }

    ngAfterViewInit() {
        this.list.scrollTop = 0;
    }

    doSearch(string) {
        const ctrl = this;

        if (string === "select") { //Enter or tab
            this.addWho(this.filtered_users[this.list_index]);
        } else if (string === "ArrowUp") {
            if (this.list_index === 0) {
                this.list_index = this.filtered_users.length - 1;
            } else {
                this.list_index -= 1;
            }
            if (this.list_index === this.filtered_users.length - 1) {
                this.list.scrollTop = (this.filtered_users.length - 4) * 48;
            } else {
                this.list.scrollTop = (this.list_index - 3) * 48
            }
        } else if (string === "ArrowDown") {
            if (this.list_index === this.filtered_users.length - 1) {
                this.list_index = 0;
            } else {
                this.list_index += 1;
            }
            if (this.list_index > 3) {
                this.list.scrollTop = (this.list_index - 3) * 48;
            } else {
                this.list.scrollTop = 0;
            }
        } else if (string === "") { //reset
            this.searchQuery = "";
            this.list_index = 0;
            this.filtered_users = utils.deepCopy(ctrl.users);
        } else {
            this.sapi.page_number = 1;
            this.sapi.clear = true;
            this.sapi.options_available.next([]);
            this.sapi.loadNext = true;
            this.list_index = 0;
            this.searchQuery = string.substr(1, string.length);
            this.sapi.getNextBatch(this.fetchData, this.searchQuery);
        }
    }

    getNextBatch() {
        this.sapi.getNextBatch(this.fetchData, this.searchQuery);
    }

    fetchData = (page_number, filterValue): Observable<any> => {
        const ctrl = this;
        const options = new SearchQueryOptions();
        options.page_number = page_number
        options.page_size = this.sapi.page_size;
        options.sort = 'name';
        let filters = [];
        if (filterValue) {
            filters.push({
                or: [
                    {"name": "username", "op": "ilike", "val": `%${filterValue}%`},
                    {"name": "name", "op": "ilike", "val": `%${filterValue}%`},
                    {"name": "alias", "op": "ilike", "val": `%${filterValue}%`},
                    {"name": "last_name", "op": "ilike", "val": `%${filterValue}%`}
                ]
            });
        }
        if (filters.length) {
            options.filters = filters;
        }
        return ctrl.api.users.searchMany(options).pipe(first(), takeUntil(this.onDestroy), map(response => {
            if (!response) return of([]);
            ctrl.users = response.data;
            return response;
        }))


    }

    addWho(user) {
        this.appScope.mentionSelected.next({
            user: user,
            search_string: this.searchQuery,
            index: this.appScope.at_who_index
        });
    }

    updateCurrent(user) {
        this.list_index = this.filtered_users.indexOf(user);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
