import Handsontable from "handsontable";
import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {AppScope} from "../../services/app_scope.service";
import {HandsontableGenerator} from "../../services/handsontable-generator.service";
import {HeaderDataService} from "../../services/header_data.service";
import {HotInstance} from "../../services/hot-instance";
import { SearchQueryOptions } from "../../services/api/search-query-options";
import {tap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";

@Component({
    selector: 'process-sheet-view',
    templateUrl: '../handson-sheet.template.html',
    encapsulation: ViewEncapsulation.None // Global Styles
    ,
    standalone: false
})
export class ProcessSheetViewComponent implements OnInit {
    @ViewChild('hot_anchor') hot_anchor: ElementRef;

    hot: HotInstance;
    @Input() parent: any;
    // TODO add setters to these inputs to detect when they have changed
    @Input() processes: any;
    @Input() allProcesses: any;
    title: string;
    revisions: any;
    data: any;
    search: string;

    users: any[];
    series_light: any[];
    component_types: any[];
    schema: any;
    column_list: any[];

    constructor(private api: ApiService,
                private appScope: AppScope,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        if (this.parent) {
            this.appScope.page_title = 'Processes: ' + this.parent.attributes.name;
            this.headerData.setPath(this.parent);
        } else {
            this.appScope.page_title = "Processes";
        }
        this.title = 'Processes';

        const observables: Observable<any>[] = [];
        observables.push(this.api.revision.searchMany().pipe(
            tap(response => this.revisions = response.data)));

        // TODO validate that the processess and allProcesses works over all 4 permutation of data being passed in
        if (this.processes == null) {
            observables.push(this.api.process.searchMany().pipe(
                tap(response => {
                    this.data = response.data;
                    this.allProcesses = response.data;
                })));
        } else {
            this.data = this.processes;
        }

        if (this.allProcesses == null) {
            this.allProcesses = this.data;
        }

        const componentTypeOptions = new SearchQueryOptions();
        componentTypeOptions.filters = [{
            name: 'base_type',
            op: 'eq',
            val: 'process'
        }];
        observables.push(this.api.component_type.searchMany(componentTypeOptions).pipe(
            tap(response => this.component_types = response.data)));

        observables.push(this.api.users.searchMany().pipe(tap(response => this.users = response.data)));
        observables.push(this.api.series_light.searchMany().pipe(tap(response => this.series_light = response.data)));

        forkJoin(observables).subscribe(this.createTable.bind(this));

        this.buildHeader();
    }

    createTable() {
        const componentTypeLookups = this.handsontableGenerator.gen_lookups(this.component_types, item => item.attributes.name);
        const revisionLookups = this.handsontableGenerator.gen_lookups(this.revisions, item => item.attributes.name);
        const userLookup = this.handsontableGenerator.gen_lookups(this.users, item => item.attributes.name);

        const processLookups = this.handsontableGenerator.gen_lookups(this.allProcesses);
        const seriesLookups = this.handsontableGenerator.gen_lookups(this.series_light);

        this.schema = {
            id: null,
            type: 'process',
            attributes: {
                base_type: 'process',
                code: null,
                name: null,
                description: null,
                icon: null,
                created_on: null,
                changed_on: null,
                custom_series: {},
                custom_constants: {}
            },
            relationships: {
                parent: {data: {id: null, type: 'process'}},
                revision: {data: {id: null, type: 'revision'}},
                component_type: {
                    data: {id: null, type: 'component_type'},
                    created_by: {data: {id: null, type: 'users'}},
                    changed_by: {data: {id: null, type: 'users'}}
                }
            }
        };

        if (this.parent) {
            this.schema.relationships.parent.data = {id: this.parent.id, type: 'process'};
        }

        this.column_list = [{
            data: 'attributes.code',
            type: 'text',
            title: 'Code'
        }, {
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }];

        if (!this.parent) {
            this.column_list.push({
                data: this.handsontableGenerator.genLookupDataSource(processLookups, 'parent'),
                title: 'Parent',
                type: 'autocomplete',
                trimDropdown: false,
                strict: true,
                source: processLookups.source,
                allowInvalid: false
            });
        }

        this.column_list = this.column_list.concat([{
            data: this.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: this.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.icon',
            title: 'Icon',
            renderer: coverRenderer
        }, {
            data: 'attributes.created_by_name',
            readOnly: true,
            title: 'Created By'
        }, {
            data: 'attributes.changed_by_name',
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }]);

        this.hot = this.handsontableGenerator.generateTable(this.api.process, this.schema, this.column_list, this.hot);
        this.hot.ready = true;
        this.hot.settings.data = this.data;
        this.hot.instance = new Handsontable(this.hot_anchor.nativeElement, this.hot.settings);
    }

    save() {
        const results = this.hot.save();
        this.data = results.data;
    }

    download() {
        this.hot.download();
    }

    buildHeader() {
        this.headerData.title = this.appScope.page_title;
        this.headerData.buttons = [
            {name: 'Save', func: this.save.bind(this), class: 'icon-save', params: {}},
            {name: 'Download', func: this.download.bind(this), class: 'icon-download', params: {}}
        ];
    }
}

function coverRenderer(instance, td, row, col, prop, value, cellProperties) {
    let escaped = Handsontable.helper.stringify(value);
    let img;

    if (escaped.indexOf('/') === 0) {
        img = document.createElement('IMG');
        img.src = value;
        img.height = 40;
        img.width = 40;

        Handsontable.dom.empty(td);
        td.appendChild(img);
    } else {
        // render as text
        Handsontable.renderers.TextRenderer.apply(this, arguments);
    }
    return td;
}
