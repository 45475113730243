import {Injectable, OnDestroy} from "@angular/core";
import {Subject, from, Subscription, of, Observable} from "rxjs";
import {takeUntil, take, map, tap, first} from "rxjs/operators";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {ApiService} from '../../services/api/api.service';
import {EventService} from "../../services/event.service";
import {refreshSubscription} from "../../lib/utils";
import {CommentsTableConfig} from "../../forms/comments-table-form/comments-table-form.component";
import {HeaderDataService} from "../../services/header_data.service";
import {ListResponse} from "../../services/api/response-types";
import {Series} from '../../_models/series';
import {getRelationWithManyIdsFilter} from "../../services/api/filter_utils";
import {KeyMap} from '../../_typing/generic-types';
import {ConfigColumn} from "../../_typing/config/config-column";
import {getColIds} from '../../tables/custom-events-table/utils';
import {CommentTableComment} from "../../_models/api/comment-table";
import {Comment} from "../../_models/comment";
import {PageDataService} from "../../data/page-data.service";
import {SessionState} from '../../_models/session-state';
import {Event as WireEvent, EventBaseType} from "../../_models/event";
import EventBase = JQuery.EventBase;
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

@Injectable()

export class CommentsTableService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    full_series_list: any[];
    session_state_dict: { [key: string]: string } = {};
    config: any;
    eventsSubscription: Subscription;
    seriesSubscription: Subscription;

    public readonly dataReady: Subject<Series[]> = new Subject<Series[]>();

    constructor(private api: ApiService,
                private dateInst: DateTimeInstanceService,
                private eventService: EventService,
                private headerData: HeaderDataService,
                private pageData: PageDataService) {
    }

    getInitialSearchOptions(config, base_type?: EventBaseType): SearchQueryOptions {
        const options = new SearchQueryOptions();
        options.filters = this.getEventsFilter(config, base_type);
        options.page_number = 1;
        return options;
    }

    getSeries(config: CommentsTableConfig) {
        this.seriesSubscription = refreshSubscription(this.seriesSubscription);
        this.seriesSubscription = this.getSeriesList(config).pipe(tap(series_list => {
                this.full_series_list = series_list;
                this.dataReady.next(this.full_series_list);
            }),
            first(), takeUntil(this.onDestroy))
            .subscribe();
    }

    getSeriesList(config): Observable<any> {
        const ctrl = this;
        const options = new SearchQueryOptions();
        let full_series_list = config.series_list || [];
        const process_ids = [];
        if (config.process_list.length > 0) {
            config.process_list.map(process => {
                process_ids.push(process.id);
            });
            options.filters = [getRelationWithManyIdsFilter('component', process_ids)];

            return this.api.series_component.searchMany(options)
                .pipe(first(), takeUntil(this.onDestroy),
                    map(result => {
                        if (!result) {
                            return full_series_list;
                        }
                        this.full_series_list = full_series_list.concat(result.data.map(sc => sc.relationships.series.data));
                        return this.full_series_list;
                    }));
        } else {
            this.full_series_list = full_series_list;
            return of(full_series_list);
        }
    }

    getEventsFilter(config:CommentsTableConfig, base_type?: EventBaseType) {
        const dtp = this.dateInst.dtp;
        const series = base_type === 'comment' ? null : this.full_series_list;
        const components = base_type === 'comment' ? null : config.process_list;
        const pages = base_type === 'comment' ? config.session_state_list : null;
        return this.eventService.getEventsFilter(dtp.start, dtp.end, series, components, pages, config.match_range, config.filter_by_created_on);
    }

    pageComments(options: SearchQueryOptions): Observable<ListResponse<CommentTableComment>> {
        options.include = 'session_state';
        return this.eventService.getEventsByBaseTypeQuery('comment', options).pipe(map(listResponse => {
            listResponse.included?.map(ss => this.session_state_dict[ss.id] = ss.attributes.name);
            return this.mapColumnData(listResponse);
        }));
    }

    pageEvents(options: SearchQueryOptions): Observable<ListResponse<CommentTableComment>> {
        return this.eventService.getEventsByBaseTypeQuery('event', options).pipe(map(listResponse => {
            return this.mapColumnData(listResponse);
        }));
    }

    defineColumns(config: CommentsTableConfig, base_type?: EventBaseType): KeyMap<ConfigColumn> {
        let col_dict = {};
        let default_cols = this.config.columns || this.eventService.comment_columns;
        if (base_type !== 'comment') {
            default_cols = default_cols.filter(c => c.id !== 'session_state')
        } else {
            default_cols = default_cols.filter(c => !['series_list', 'process_list'].includes(c.id))
        }
        default_cols.map(c => col_dict[c.id] = c);
        return col_dict;
    }

    mapColumnData(lr: ListResponse<Comment>): ListResponse<CommentTableComment> {
        const comments: CommentTableComment[] = [];
        lr.data.map((event): void => {
            let comment = new CommentTableComment();
            comment.id = event.id;
            comment.comment = event.attributes.comment;
            comment.duration = event.attributes.duration_string;
            comment.start = event.attributes.start;
            comment.end = event.attributes.end;
            comment.series = event.attributes.series_names;
            comment.session_state = this.session_state_dict[event.relationships.session_state?.data?.id] || '';
            comment.components = event.attributes.component_names;
            comment.created_by = event.attributes.created_by_name;
            comment.changed_by = event.attributes.changed_by_name;
            comment.created_on = event.attributes.created_on;
            comment.changed_on = event.attributes.changed_on;
            comments.push(comment)
        });
        return {data: comments, links: lr.links, meta: lr.meta, jsonapi: lr.jsonapi};
    }

    getSearchFilters(filterString) {
        const filters = {or: []};

        filters.or.push({name: 'comment', op: 'ilike', val: `%${filterString}%`});
        filters.or.push({
            name: 'series_list',
            op: 'any',
            val: {name: 'name', op: 'ilike', val: `%${filterString}%`}
        });
        filters.or.push({
            name: 'components',
            op: 'any',
            val: {name: 'name', op: 'ilike', val: `%${filterString}%`}
        });
        filters.or.push({
            name: 'created_by',
            op: 'has',
            val: {name: '_name', op: 'ilike', val: `%${filterString}%`}
        });
        return filters;
    }

    getPageSearchFilters(filterString) {
        const filters = {or: []};

        filters.or.push({name: 'comment', op: 'ilike', val: `%${filterString}%`});
        filters.or.push({
            name: 'session_state',
            op: 'has',
            val: {name: 'name', op: 'ilike', val: `%${filterString}%`}
        });
        filters.or.push({
            name: 'created_by',
            op: 'has',
            val: {name: '_name', op: 'ilike', val: `%${filterString}%`}
        });
        return filters;

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
