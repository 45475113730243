<style>
  .handsontable table thead th {
    white-space: pre-line;
  }

  .handsontable .relative {
    position: relative;
    text-align: left;
  }

  .hidden {
    display: none;
  }

  .ht_master .wtHolder {
    overflow: hidden;
  }

  .event-anchor:hover .ht_master .wtHolder {
    overflow: auto;
  }
</style>

<div style="position:relative;height:100%;">
  <div #utils_bar class="row hideonprint utils-bar">
    <div class="row justify-start table-filter" >
    <utils-bar [search]="true"
               (searchValueSubmitted)="filter_string=$event;updateSearchFilter()"
               (searchCleared)="filter_string='';updateSearchFilter();"
    >
    </utils-bar>
    <button mat-flat-button class="search-button" matTooltip="Insert new row" (click)="insert()">
      <i class="fa fa-plus"></i>
    </button>
    <div *ngIf="filter_warning" class="row hideonprint warning info" style="padding: 4px;">
      {{filter_warning}}
    </div>
    </div>
    <mat-paginator [pageSizeOptions]="page_size_options.concat(events_total)"
                   showFirstLastButtons
                   style="justify-self: flex-end;">
    </mat-paginator>
  </div>

  <div #scroll_area class="scroll-area" overlay-scrollbars [defer] [options]="{scrollbars: {autoHide: 'leave'}}"
       style="position:relative;">
    <div #event_anchor *ngIf="hot" id="{{hot.handson_id}}" class="event-anchor"></div>
  </div>

  <h4 *ngIf="!config.event_types?.length">
    Please select an event type.
  </h4>

  <div [ngStyle]="{'display': saving? 'flex' : 'none'}" class="app-loading">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
