import {Injectable} from '@angular/core';
import {KeyMap, ModelID, ModelName} from '../_typing/generic-types';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, concatMap, first, map, take, tap} from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import {NotificationService} from "./notification.service";
import {LockTemplate} from '../_models/lock-template';
import {Component, isComponent} from "../_models/component";
import {Event} from "../_models/event";
import {SearchQueryOptions} from "./api/search-query-options";
import {getBaseFilter} from "./api/filter_utils";
import {ApiService} from './api/api.service';
import {ListResponse} from './api/response-types';
import {ConstantProperty, isConstantProperty} from '../_models/constant-property';
import {LockTemplateVersion} from '../_models/lock-template-version';
import {
    LockTemplateDependencyTreeService
} from "../menu-bars/lock-template-dependency-tree/lock-template-dependency-tree.service";
import {HeaderDataService} from "./header_data.service";
import {httpParamSerializer, label} from "../lib/utils";
import {FormDialogService} from "./form-dialog.service";
import {EventLock} from "../_models/event-lock";
import {ComponentLock} from "../_models/component-lock";
import {capitalize} from "lodash-es";


export interface LockRequest {
    filters?: any;
    model_type: 'event' | 'component';
    lock_template_id: ModelID;
    model_ids?: ModelID[];
}

export interface FindLocksResponse {
    event: { string: { string: { event_locks: number[], component_locks: number[] } } };
    component: { string: { string: { event_locks: number[], component_locks: number[] } } };
}

export type ConstantLockedDict = Record<string, Record<string, boolean>>;

@Injectable({
    providedIn: 'root'
})
export class LockDataService {
    private lock_request_api = '/api/lockdown';

    constructor(private http: HttpClient,
                private api: ApiService,
                private formDialog: FormDialogService,
                private lockTreeService: LockTemplateDependencyTreeService,
                private headerData: HeaderDataService,
                private notification: NotificationService) {
    }

    async lockData(model_type: 'event' | 'component', lock_template_id: ModelID, count: number, filters?: any, model_ids?: ModelID[], component_type?: ModelName) {
        if (model_ids?.length > 0) {
            count = model_ids.length;
        }
        const confirmResult = await this.formDialog.confirm(this.confirmLock(model_type, count, component_type), "Cancel", "OK");

        if (!confirmResult) {
            return;
        }

        let lock_request_data: LockRequest = {
            filters: JSON.stringify(filters),
            model_type: model_type,
            lock_template_id: lock_template_id.toString(),
            model_ids: model_ids
        };
        // if model ids are defined, do not pass through filters
        if (model_ids && model_ids.length > 0) {
            delete lock_request_data['filters'];
        }
        this.lockEventOrComponentModelData(lock_request_data, model_ids);

    }

    confirmLock(model_type: 'event' | string, count: number, component_type: ModelName) {
        const name = model_type === 'event' ? 'event' : label(capitalize(component_type));
        let confirm_lock_msg = "<div>You are about to lock down " + count + " " + name + (count > 1 ? "s " : " ") +
            " and their linked properties. You will need to contact servicedesk@metalmanagementsolutions.com should you need to undo this.</div>";

        return confirm_lock_msg;
    }

    private lockEventOrComponentModelData(lock_request_data: LockRequest, event_ids?: ModelID[]) {
        lock_request_data.model_ids = event_ids;
        this.sendLockRequest(lock_request_data);
    }

    private sendLockRequest(lock_request_data: LockRequest) {
        this.http.post(this.lock_request_api, lock_request_data)
            .pipe(
                first(),
                tap(response => {
                    console.log('LockDataService - : ', response);
                    this.notification.openSuccess('A request to lock data has been made for this data set. ' +
                        'Please check the status of the data locking for further information.', 5000);
                }),
                catchError(e => {
                    console.log('ERROR: LockDataService (sendLockRequest)', e);
                    this.notification.openError('Error locking data.', 10000);
                    return of(e);
                })
            ).subscribe();
    }

    public extractConstantPropertyIds(lock_templates: LockTemplate[]): ModelID[] {
        return lock_templates.reduce((cps, d) => {
            cps = cps.concat(d.attributes.definition.constant_properties);
            return cps;
        }, []);
    }

    public extractTemplateTypeIds(lock_templates: LockTemplate[], type_name: 'event_type' | 'component_type'): ModelID[] {
        return lock_templates.map((d: LockTemplate) => d.relationships[type_name]?.data?.id);
    }

    getRowLocked(lockedInstances: Component[] | Event[], locked_dict: ConstantLockedDict): KeyMap<boolean> {
        const any_locked_dict = {};
        lockedInstances.forEach((c: Component | Event) => {
            any_locked_dict[c.id] = Object.keys(locked_dict[c.id]).reduce((val, key) => {
                return val === true ? true : locked_dict[c.id][key];
            }, false);
        });
        return any_locked_dict;
    }

    getLockTemplates(lock_template_ids: ModelID[]): Observable<ListResponse<LockTemplate>> {
        const options = new SearchQueryOptions();
        options.filters = [getBaseFilter(lock_template_ids, 'id', 'in')];
        return this.api.lock_template.searchMany(options);
    }

    private getLock(model_type: 'event' | 'component', model_ids: ModelID[], constant_properties_ids: ModelID[] = [],
                    attributes: string[] = []): Observable<FindLocksResponse> {
        const params = {
            model_type: model_type,
            component_ids: model_ids,
            event_ids: model_ids,
            constant_property_ids: constant_properties_ids,
            attributes: attributes
        };
        const serializedParams: HttpParams = httpParamSerializer(params);
        return this.http.get('/api/find_lock', {params: serializedParams}) as Observable<FindLocksResponse>;
    }

    getLocks(model_type: 'component' | 'event', model: Component | Event, property: string | ConstantProperty, tile_id: ModelID): void {
        let propId: ModelID, attribute: string;
        if (isConstantProperty(property)) {
            propId = property.id;
        } else {
            attribute = property;
        }
        let lock_version_dict: KeyMap<ModelID> = {};
        let componentLockIds;
        let eventLockIds;
        this.getLock(model_type, [model.id], propId ? [propId] : [],
            attribute ? [attribute] : [])
            .pipe(
                concatMap((locks: FindLocksResponse) => {
                    if (model_type === 'component') {
                        componentLockIds = locks.component[model.id]?.[propId]?.component_locks
                            || locks.component[model.id]?.[attribute]?.component_locks;
                        eventLockIds = locks.component[model.id]?.[propId]?.event_locks
                            || locks.component[model.id]?.[attribute]?.event_locks;
                    } else {
                        componentLockIds = locks.event[model.id]?.[propId]?.component_locks
                            || locks.event[model.id]?.[attribute]?.component_locks;
                        eventLockIds = locks.event[model.id]?.[propId]?.event_locks
                            || locks.event[model.id]?.[attribute]?.event_locks;
                    }
                    if (!componentLockIds.length && !eventLockIds.length) {
                        this.notification.openError("No lock associated with " +
                            (isComponent(model) ? " component " + model.attributes.name : "this event ")
                            + " can be found.");
                        return of(null);
                    }
                    let lockQueries$: Observable<ListResponse<EventLock | ComponentLock>>[] = [];
                    if (eventLockIds.length) {
                        let eventOptions = new SearchQueryOptions();
                        eventOptions.filters = [getBaseFilter(eventLockIds, 'id', 'in')];
                        lockQueries$.push(this.api.event_lock.searchMany(eventOptions));
                    }
                    if (componentLockIds.length) {
                        let componentOptions = new SearchQueryOptions();
                        componentOptions.filters = [getBaseFilter(componentLockIds, 'id', 'in')];
                        lockQueries$.push(this.api.component_lock.searchMany(componentOptions));
                    }
                    return forkJoin(lockQueries$);
                }),
                map((eventAndComponentLocks: ListResponse<EventLock | ComponentLock>[]) => {
                    let resultingLocks: ListResponse<EventLock | ComponentLock>;
                    eventAndComponentLocks.forEach((lockResponse: ListResponse<EventLock | ComponentLock>) => {
                        if (!resultingLocks) {
                            resultingLocks = lockResponse;
                        } else {
                            resultingLocks.data = resultingLocks.data.concat(lockResponse.data);
                            resultingLocks.meta.count += lockResponse.meta.count;
                        }
                    });
                    return resultingLocks;
                }),
                concatMap(result => {
                    let version_ids = [];
                    result.data.forEach(r => {
                        version_ids.push(r.relationships.lock_template_version.data.id);
                        lock_version_dict[r.id] = r.relationships.lock_template_version.data.id;
                    });
                    const inner_options = new SearchQueryOptions();
                    inner_options.filters = [getBaseFilter(version_ids, 'id', 'in')];
                    return this.api.lock_template_version.searchMany(inner_options);
                }),
                take(1), concatMap(response => {
                    if (!response) {
                        return of(null);
                    }
                    let modelId = !isConstantProperty(property) && ['name', 'start', 'end'].includes(property) ? model.id : null
                    let versions = this.filterVersionsByProperty(response.data, property,);
                    if (!versions.length) {
                        let text = isConstantProperty(property) ? "property " + property.attributes.name : "attribute " + property
                        this.notification.openWarning("A lock template version with " + text + " could not be found." +
                            " Please check the template version you'd like to unlock.", 10000);
                        versions = response.data;
                    }
                    const lock_template_ids = versions?.map(v => v.relationships.lock_template.data.id);
                    return this.formDialog.openLockTemplateFormDialog(lock_template_ids, versions,
                        isComponent(model) ? model : null,
                        !isComponent(model) ? model : null,
                        lock_version_dict, property)
                        .afterClosed().pipe(tap(() => {
                            this.headerData.refreshTileSubject.next(tile_id);
                        }));
                }))
            .subscribe();

    }

    filterVersionsByProperty(versions: LockTemplateVersion[], property: ConstantProperty | string, modelId?: ModelID): LockTemplateVersion[] {
        const filter: string = isConstantProperty(property) ? property.attributes.name : property;
        return versions.filter(v => {
            const tree = this.lockTreeService.generateTreeSchema(v.attributes.schema);
            if (v.attributes.schema.attributes?.includes(filter) || this.lockTreeService.findNodesFromFilter(filter)?.length
                || (modelId && this.lockTreeService.flattenChildDependencies().includes(modelId))) {
                return true;
            }
        });
    }

    public unlock(model_type: 'component' | 'event', lock_ids: ModelID[]): Observable<any> {
        this.notification.openInfo("Unlocking data. Please be patient...", 5000);
        let $obs = [];
        let errors = [];
        let model_api = model_type + '_lock';
        lock_ids.forEach(id => {
            $obs.push(this.api[model_api].obsDelete(id).pipe(
                catchError(e => {
                    errors.push('Error deleting lock ' + id);
                    return of(e);
                })
            ));
        });
        return forkJoin($obs).pipe(tap(() => {
            if (errors.length) {
                this.notification.openError("Some errors occurred. Please see console for details.");
            } else {
                this.notification.openSuccess("Unlock successful.");
            }
        }));
    }
}
