<ng-container *ngIf="event_types && event_types.length > 0 && ore_body_types && ore_body_types.length > 0">

  <table *ngIf="ready && pivot!==true" class="source-destination-table">
    <thead>
    <tr>
      <th>Ore Body Type <i class="fas fa-chevron-right"></i></th>
      <td *ngFor="let ore_body_type of ore_body_types" colspan="2"
          style="text-align: center;">{{ore_body_type.attributes.name}}</td>
    </tr>
    <tr>
      <th style="text-align: left;">Event Type <i class="fas fa-chevron-down"></i></th>
      <ng-container *ngFor="let ore_body_type of ore_body_types">
        <th>Source</th>
        <th>Destination</th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let event_type of event_types">
      <td>{{event_type.attributes.name}}</td>
      <ng-container *ngFor="let ore_body_type of ore_body_types">
        <td class="source">
          <ng-container *ngIf="table_data[event_type.id + ore_body_type.id]">
            <mat-checkbox (change)="submitChange($event, event_type, ore_body_type, 'source')"
                          [checked]="table_data[event_type.id + ore_body_type.id].attributes.source"></mat-checkbox>
          </ng-container>
        </td>
        <td class="destination">
          <ng-container *ngIf="table_data[event_type.id + ore_body_type.id]">
            <mat-checkbox (change)="submitChange($event, event_type, ore_body_type, 'destination')"
                          [checked]="table_data[event_type.id + ore_body_type.id].attributes.destination"></mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>

  <table *ngIf="ready && pivot===true" class="source-destination-table">
    <thead>
    <tr>
      <th>Event Type <i class="fas fa-chevron-right"></i></th>
      <td *ngFor="let event_type of event_types" colspan="2"
          style="text-align: center;">{{event_type.attributes.name}}</td>
    </tr>
    <tr>
      <th style="text-align: left;">Ore Body Type <i class="fas fa-chevron-down"></i></th>
      <ng-container *ngFor="let event_type of event_types">
        <th>Source</th>
        <th>Destination</th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let ore_body_type of ore_body_types">
      <td>{{ore_body_type.attributes.name}}</td>
      <ng-container *ngFor="let event_type of event_types">
        <td class="source">
          <ng-container *ngIf="table_data[event_type.id + ore_body_type.id]">
            <mat-checkbox (change)="submitChange($event, event_type, ore_body_type, 'source')"
                          [checked]="table_data[event_type.id + ore_body_type.id].attributes.source"></mat-checkbox>
          </ng-container>
        </td>
        <td class="destination">
          <ng-container *ngIf="table_data[event_type.id + ore_body_type.id]">
            <mat-checkbox (change)="submitChange($event, event_type, ore_body_type, 'destination')"
                          [checked]="table_data[event_type.id + ore_body_type.id].attributes.destination"></mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</ng-container>
