import {Injectable, OnDestroy} from "@angular/core";
import {Observable, of, ReplaySubject, Subject} from "rxjs";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {PaginationDataSource} from "../services/api/pagination-data-source";
import {Group} from "../_models/group";
import {ApiService} from "../services/api/api.service";
import {SearchQueryOptions} from "../services/api/search-query-options";
import {catchError, debounceTime, distinctUntilChanged, finalize, first, takeUntil, tap} from "rxjs/operators";
import {ListResponse} from "../services/api/response-types";
import {GenericDataService} from "../data/generic-data.service";
import {SessionState} from "../_models/session-state";
import {getRelationWithIdFilter, ModelRelationshipNameDict} from "../services/api/filter_utils";
import {IDObject, ModelID} from "../_typing/generic-types";
import {RelationshipApiMappingService} from "../data/relationship-api-mapping.service";
import {User} from "../_models/users";
import {NotificationService} from "../services/notification.service";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {SnackbarComponent} from "../notifications/snackbar/snackbar.component";
import {deepCopy} from "../lib/utils";

@Injectable({
    providedIn: 'root'
})
export class BasePaginatedTableService<T> implements OnDestroy {
    public readonly onDestroy = new Subject<void>();
    notificationRef: MatSnackBarRef<SnackbarComponent>;
    applyFilter = new ReplaySubject<string>(1);
    filter_string = '';
    page_size = 10;
    sort: MatSort;
    paginator: MatPaginator;
    dataSource: PaginationDataSource<T>;
    can_edit: boolean;
    base_columns = ['name', 'description'];
    audit_columns = ['created_on', 'created_by_name', 'changed_on', 'changed_by_name', 'account_name'];
    search_keys = ['name', 'description'];
    initial_filters: any;

    this_type: string;
    rel_mapping_stub_list = [];
    selected_rel_mapping: IDObject[];
    originalSelection: IDObject[];

    public readonly selectedChangedSubject: ReplaySubject<{ id: ModelID }[]> = new ReplaySubject(1);

    public readonly dataSourceSubject: ReplaySubject<PaginationDataSource<T>> = new ReplaySubject<PaginationDataSource<T>>(1);

    public readonly saveSubject: Subject<string[]> = new Subject();

    constructor(private genericData: GenericDataService,
                public api: ApiService,
                private notification?: NotificationService,
                private relMapping?: RelationshipApiMappingService) {

        this.applyFilter.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            takeUntil(this.onDestroy))
            .subscribe(value => {
                this.filter_string = value;
                this.updateSearchFilter(this.filter_string);
            });
    }

    page(query: SearchQueryOptions, api_name): Observable<ListResponse<any>> {
        return this.api[api_name].searchMany(query).pipe(
            first(), takeUntil(this.onDestroy)
        )
    }

    refreshData() {
        this.updateSort(null);
    }

    generateFilteredModelFilter(filter_string: string): any[] {
        return [this.genericData.generateFilteredModelFilter(filter_string, this.search_keys)];
    }

    updateSearchFilter(filter_string) {
        let filters: any = this.initial_filters || [];
        if (!filter_string) {
            this.dataSource.filterBy(filters);
            return;
        }
        filters = filters.concat(this.generateFilteredModelFilter(filter_string));
        this.dataSource.filterBy(filters);
    }

    updateSort(event) {
        this.dataSource.sortBy(this.sort)
    }

    getRelationshipMappingRows(model: Group | SessionState | User) {
        /**Gets the mapping table relationship rows - used by select-many-search-api**/
        const model_name = ModelRelationshipNameDict[this.this_type].mapping[model.type].name;
        const api_name = ModelRelationshipNameDict[this.this_type].mapping[model.type].type;

        const options = new SearchQueryOptions()
        options.filters = [getRelationWithIdFilter(model_name, model.id)];
        this.api[api_name].searchMany(options).pipe(
            first())
            .subscribe(result => {
                const self_name = ModelRelationshipNameDict[this.this_type].mapping.self;
                this.selected_rel_mapping = result.data.map(u => {
                    return {id: u.relationships[self_name].data.id}
                });
                this.originalSelection = deepCopy(this.selected_rel_mapping);
                this.selectedChangedSubject.next(this.selected_rel_mapping);
                this.rel_mapping_stub_list = result.data.map(u => {
                    return {[self_name + '_id']: u.relationships[self_name].data.id, [model_name + '_id']: model.id}
                });
            })
    }

    hasChanges(selectedList: IDObject[]): boolean {
        // Compare current selectedList with the originalSelection to ensure there are changes before save to prevent removing relationships unnecessarily
        return JSON.stringify(this.originalSelection) !== JSON.stringify(selectedList);
    }

    upsertModelRelationshipMap(selectedList: IDObject[], rel_model, refresh = true) {
        let objectsUpdated: boolean = true;
        const self_name = ModelRelationshipNameDict[this.this_type].mapping.self;
        const rel_model_key = ModelRelationshipNameDict[this.this_type].mapping[rel_model.type].name;
        const new_rel_list = selectedList.map(u => {
            return {[self_name + '_id']: u.id.toString(), [rel_model_key + '_id']: rel_model.id.toString()}
        })

        const errors = [];
        this.relMapping.saveManyCustomApi(self_name,
            this.rel_mapping_stub_list, new_rel_list,
            ModelRelationshipNameDict[this.this_type].mapping[rel_model.type].upsert_api,
            ModelRelationshipNameDict[this.this_type].mapping[rel_model.type].delete_api)
            .pipe(
                catchError(e => {
                    errors.push(e);
                    console.log("ERROR: BasePaginatedTableService (upsertModelRelationshipMap for " + this.this_type + ")", e);
                    this.notification.openError("Error updating " + rel_model.type + " for " + this.this_type + ".", 10000);
                    return of(e);
                }),
                tap(result => {
                    if (result) {
                        if (typeof result === 'object') {
                            this.rel_mapping_stub_list.push(result);
                        } else {
                            objectsUpdated = false;
                        }
                    }
                }),
                finalize(() => {
                    this.rel_mapping_stub_list = this.rel_mapping_stub_list.filter(item => {
                        return selectedList.map(u => u.id.toString()).includes(item[self_name + '_id'].toString())
                    });
                    this.selected_rel_mapping = selectedList.map(u => {
                        return {id: u.id.toString()};
                    });
                    this.selectedChangedSubject.next(this.selected_rel_mapping);
                    this.originalSelection = deepCopy(this.selected_rel_mapping);
                    if (errors.length < 1) {
                        if (!this.notification.isOpen) {
                            /**Don't show this snackbar if there is already an open one**/
                            if (objectsUpdated) {
                                this.notificationRef = this.notification.openSuccess("Save successful.", 3000);
                            } else {
                                this.notificationRef = this.notification.openInfo("No objects to save.", 3000);
                            }
                            this.notificationRef.afterDismissed().subscribe(() => {
                                this.notification.isOpen = false;
                            });
                        }
                        this.saveSubject.next(errors);
                    } else {
                        this.notification.openError("There were errors saving " + this.this_type + ".", 3000);
                        this.saveSubject.next(errors);
                    }
                    if (refresh) {
                        this.updateSort(this.sort);
                    }

                }))
            .subscribe(() => {
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
