import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AuditMixin} from "../../_models/audit-mixin";

@Component({
    selector: 'audit-mixin',
    templateUrl: './audit-mixin.component.html',
    styleUrls: ['./audit-mixin.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AuditMixinComponent implements OnInit {

    @Input() model: AuditMixin & { id: string; type: string };

    constructor() {
    }

    ngOnInit(): void {
    }

}
