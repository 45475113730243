<select-search-api *ngIf="selector!=='search-api'"
                   [fetchData]="fetchData"
                   (selectionChange)="emitEvent($event)"
                   (closeWithoutChange)="closeWithoutChange.emit()"
                   (showUpdate)="showUpdate.emit()"
                   (accountsChange)="filterByAccount($event)"
                   [openOnInit]="openOnInit"
                   [placeholder]="placeholder"
                   [allowAccountSelection]="allowAccountSelection"
                   [allowedAccountIds]="allowedAccountIds"
                   [disabled]="disabled"
                   [compareFunctionName]="compareFunctionName"
                   [stringFunction]="stringFunction"
                   [value]="value"
                   [init_value]="init_value"
                   [allow_none]="allow_none"
                   [label]="label"
                   [classes]="classes"
                   [noItemsAvailableMessage]="noItemsAvailableMessage"
                   style="min-width:inherit;max-width:inherit;">
</select-search-api>
<search-api *ngIf="selector==='search-api'"
            [fetchData]="fetchData"
            (selectionChange)="emitEvent($event)"
            (closeWithoutChange)="closeWithoutChange.emit()"
            (showUpdate)="showUpdate.emit()"
            (actionClicked)="actionClicked.emit($event)"
            [openOnInit]="openOnInit"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [allow_navigate]="allow_navigate"
            [compareFunctionName]="compareFunctionName"
            [stringFunction]="stringFunction"
            [value]="value"
            [init_value]="init_value"
            [label]="label"
            [classes]="classes"
            [action]="action"
            style="min-width:inherit;max-width:inherit;">
</search-api>
