import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {catchError, first, takeUntil, tap} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import * as utils from "../../lib/utils";
import {MatTabGroup} from '@angular/material/tabs';
import {ComponentType} from '../../_models/component-type';
import {SingleResponse} from '../../services/api/response-types';
import {SaveService} from "../../services/save/save.service";
import {NotificationService} from "../../services/notification.service";

export interface ComponentTypeDialogData {
    component_type: any;
    component_types_list: ComponentType[] | undefined;
}

@Component({
    selector: 'component-type-form',
    templateUrl: './component-type-form-dialog.component.html',
    styleUrls: ['./component-type-form-dialog.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SaveService],
    standalone: false
})

export class ComponentTypeFormDialogComponent implements OnInit {

    saved_component_type: ComponentType;
    component_type: ComponentType;
    ct_id: string;
    base_types = ['component', 'process', 'equipment', 'stream', 'part', 'resource'];
    error: String;
    component_type_schema = {
        id: null,
        type: 'component_type',
        attributes: {
            name: null,
            description: null,
            base_type: 'component'
        }
    };

    constant_components: any;
    private readonly onDestroy = new Subject<void>();
    page_size = 5;
    complete = false;

    constructor(private api: ApiService,
                public dialogRef: MatDialogRef<ComponentTypeFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ComponentTypeDialogData,
                public dialog: MatDialog,
                private notification: NotificationService,
                private saveService: SaveService) {

        this.ct_id = this.data.component_type?.id;
    }

    component_options_filter: any;
    selected_components_filter: any;


    ngOnInit() {
        const ctrl = this;

        this.component_type = utils.deepCopy(this.component_type_schema);
        this.component_options_filter = [{
            or: [{
                and: [{name: 'component_type', op: 'eq', val: null},
                    {name: 'base_type', op: 'eq', val: this.component_type.attributes.base_type}
                ]
            }]
        }];
        if (this.ct_id) {
            this.selected_components_filter = [{
                name: 'component_type',
                op: 'has',
                val: {name: 'id', op: 'eq', val: this.ct_id}
            }];
            const $ct = this.api.component_type.getById(this.ct_id).pipe(
                first(), takeUntil(this.onDestroy))
                .subscribe({
                    next: (ct_result) => {
                        this.component_type = ct_result.data;
                    },
                    error: (err) => {
                        console.log('ERROR: Component Type Form Dialog (init)', err);
                    }
                });
        }

        // Keep a copy to pass back to the table on close without save
        this.saved_component_type = utils.deepCopy(this.component_type);
    }

    save() {
        this.saveComponentType().pipe(tap(result => {
            this.onCloseClick();
        }), catchError(err => err)).subscribe();
    }

    apply() {
        this.saveComponentType().subscribe();
    }

    saveComponentType(): Observable<any> {
        let $promise;
        if (this.component_type.id) {
            $promise = this.api.component_type.obsPatch(this.component_type);
        } else {
            $promise = this.api.component_type.obsSave(this.component_type);
        }

        return $promise.pipe(first(), takeUntil(this.onDestroy),
            tap((result: SingleResponse<ComponentType>) => {
                if (result) {
                    this.component_type.id = result.data.id;
                    /** this allows table to reset component_type if user clicks apply then close and/or close without saving */
                    this.saved_component_type = utils.deepCopy(this.component_type);
                    this.notification.openSuccess('Component type saved.', 2000);
                }
                /**For constant_properties**/
                this.saveService.emitSave();

                // TODO Save new_components, currently view only of selected components
            }), catchError(err => {
                console.log('error');
                return err;
            })
        );
    }

    goToOreBodyTab(tabGroup: MatTabGroup) {
        tabGroup.selectedIndex = 3;
    }

    onCloseClick(): void {
        this.dialogRef.close(this.saved_component_type);
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
