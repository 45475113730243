import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {TileDataService} from "../../services/tile_data.service";
import {SeriesDataTableService} from "./series-data-table.service";
import {
    SeriesDataSeriesGroupDict,
    SeriesDataTableConfig,
    SeriesDataTableHeaderRows, SeriesGroupConfig
} from "../../forms/series-data-table-form/series-data-table-form.component";
import {concatMap, takeUntil, tap} from 'rxjs/operators';
import {of, Subject} from "rxjs";
import {ColumnFormatsDict, TableUtilsService} from "../table-utils.service";
import {FormDialogService} from "../../services/form-dialog.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {SeriesColumn, SeriesDataService} from "../../services/series_data.service";
import {HeaderDataService} from "../../services/header_data.service";
import {IDMap, KeyMap, ModelID} from "../../_typing/generic-types";
import {SeriesConstantPermissions, SeriesPermissions} from "../../_typing/series-constant-permissions";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";
import {TOOLTIP_SHOW_DELAY} from "../../shared/globals";
import {ComponentEventsTableService} from "../component-events-table/component-events-table.service";
import {SeriesDataTableDownloadService} from "../../services/series-data-table-download.service";
import {TimePeriod} from "../../_typing/components/time-period";
import {FileDownloadOption} from "../../_typing/download-filetype";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    selector: 'series-data-table',
    templateUrl: './series-data-table.component.html',
    styleUrls: ['./series-data-table.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SeriesDataTableService],
    standalone: false
})
export class SeriesDataTableComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    @Input() config: SeriesDataTableConfig;
    timeDict: KeyMap<TimePeriod> = {};
    series_dict: IDMap<SeriesGroupConfig> = {};
    series_data: any = {};
    series_missing: any = {};
    series_summary: any;
    seriesIds: ModelID[];
    groupDict: SeriesDataSeriesGroupDict = {};
    groupList: string[];

    dtp: IDateTimePeriod;
    format_dict: ColumnFormatsDict = {};
    header_row_count: number;
    show_time_header_dict: SeriesDataTableHeaderRows = {};
    gssColumnDict: KeyMap<SeriesColumn> = {};
    private seriesPermissions: SeriesPermissions;
    private buttons: ITileButton[];
    tileReady: boolean = false;
    showVertical: boolean = true;
    columns: string[];
    timeColumns: string[];
    gssColumns: string[];
    isSticky: { name: boolean, alias: boolean, description: boolean } = {
        'name': true,
        'alias': false,
        'description': false
    };

    protected readonly TOOLTIP_SHOW_DELAY = TOOLTIP_SHOW_DELAY;

    constructor(private datetimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private tileData: TileDataService,
                private tableUtils: TableUtilsService,
                private cps: SeriesDataTableService,
                private formDialogService: FormDialogService,
                private seriesDataService: SeriesDataService,
                public componentEventsTableService: ComponentEventsTableService,
                private headerData: HeaderDataService,
                private downloadDataService: SeriesDataTableDownloadService) {
    }

    ngOnInit() {
        this.showVertical = this.config.orientation !== 'horizontal';
        this.header_row_count = Object.values(this.config.header_rows).filter(hr => hr === true).length;
        this.showTimeColumn();
        this._getSeriesColumnIdentifier(['name', 'alias', 'description'].filter(c => this.config.header_rows[c]))
        this.seriesDataService.baseColumns.forEach(c => this.gssColumnDict[c.name] = c);

        this.cps.dataReady.pipe((concatMap((result) => {
            result.series_list.forEach(s => this.series_dict[s.id] = s);
            this.seriesIds = result.series_list.map(s => s.id);
            this.series_data = result.series_data || {};
            this.groupDict = result.group_dict || {};
            this.groupList = Object.keys(this.groupDict);
            this.series_missing = result.series_missing || {};
            this.series_summary = result.series_summary || {};
            this.timeDict = this.cps.getTimes(this.dtp, Object.keys(this.series_data));
            this._setColumns();
            if (!this.seriesIds?.length) return of(null);

            return this.seriesDataService.getSeriesPermissions(this.seriesIds).pipe(tap(permissions => {
                this.seriesPermissions = permissions;
                this.setButtons();
            }));
        }))).subscribe(() => this.tileReady = true);


        this.datetimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dtp = this.dateInst.dtp;
            this.cps.getSeriesData(this.config, this.dtp);
        });

        this.dateInst.dateTimePeriodRefreshed$.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            this.cps.getSeriesData(this.config, this.dtp);
        });
        this.initialiseColumnFormats();
    }

    private _setColumns() {
        this.columns = ['edit', 'name', 'description', 'alias', 'unit'];
        this.columns = this.columns.filter(r => this.config.header_rows[r]);
        if (this.config.header_rows.process || this.config.header_rows.custom_groups) {
            this.columns.unshift('group');
        }
        this.gssColumns = this.config.gss?.columns;
        if (this.gssColumns && this.config.show_gss === 'top') {
            this.columns = this.columns.concat(this.gssColumns);
        }
        this.timeColumns = Object.keys(this.series_data);
        this.columns = this.columns.concat(this.timeColumns)
        if (this.gssColumns && this.config.show_gss === 'bottom') {
            this.columns = this.columns.concat(this.gssColumns);
        }
    }

    private _getSeriesColumnIdentifier(identifierColumns: string[]): KeyMap<boolean> {
        let columns = identifierColumns.map(c => c.toLowerCase());
        let isSticky = {'name': true, 'alias': false, 'description': false};
        const isFirst = (columns: string[], firstColumn: string): boolean => {
            return columns.every(c => columns.includes(c)) && columns.every((c) => columns.indexOf(firstColumn) < columns.indexOf(c))
        }
        isSticky.name = columns.includes("name");
        isSticky.alias = (columns.includes("alias") && !columns.includes("name")) || isFirst(["name", "alias"], "alias");
        isSticky.description = columns.includes("description") && !(columns.includes("name") || columns.includes("alias"));
        return isSticky;
    }

    public isEmpty: (obj) => boolean = (obj) => Object.keys(obj).length === 0;

    private initialiseColumnFormats() {
        this.cps.seriesList(this.config)?.forEach(series => {
            this.format_dict[series.id] = this.tableUtils.getColumnFormats(this.config.column_formats?.[series.id], {decimals: null});
        });

        if (!this.config.gss?.column_formats) {
            return;
        }
        Object.keys(this.config.gss.column_formats).forEach(column => {
            this.format_dict[column] = this.tableUtils.getColumnFormats(this.config.gss.column_formats?.[column]);
        });
    }

    private showTimeColumn() {
        this.show_time_header_dict = this.cps.showTimeColumn(this.config);
    }

    public openChartDialog(series_name): void {
        const dialogRef = this.formDialogService.openChartDialog(series_name, this.dateInst.dtp);
    };

    overrideCalculations() {
        this.headerData.getCalculations(this.dateInst.dtp, this.seriesIds, 'hour', 1).then(response => {
            this.cps.getSeriesData(this.config, this.dtp);
        }).catch();


    }

    private allSeriesAllowOverrideCalcs(): boolean {
        return Object.values(this.seriesPermissions).every(permissions => {
                return permissions.includes(SeriesConstantPermissions.override_calculations)
            }
        );
    }

    downloadTableData(fileType: FileDownloadOption = 'formatted_xlsx'): void {
        this.downloadDataService.createTableLayoutExcelJS(this.config, this.groupList, this.groupDict, this.series_dict, Object.keys(this.timeDict), this.series_data, this.series_summary, this.format_dict, fileType);
    }

    getEventDataFileType($event) {
        this.componentEventsTableService.openFileFormatModal($event, this.downloadTableData.bind(this));
    }

    setButtons() {
        this.buttons = [];

        this.buttons.push({
                name: 'Update Calculations',
                func: () => this.overrideCalculations(),
                params: {},
                class: 'fa fa-calculator hide-xs',
                disabled: !this.allSeriesAllowOverrideCalcs(),
                HoverOverHint: this.allSeriesAllowOverrideCalcs() ? 'Update calculations' : 'You do not have permissions to override calculations on all of these series.'
            },
            {
                name: 'Download Table Data',
                func: ($event) => this.getEventDataFileType($event),
                class: 'small fas fa-file-download hide-xs',
                HoverOverHint: 'Download table data as a .csv or .xlsx file'
            });
        this.tileData.buttonsChanged.next(this.buttons);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
