import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as utils from '../../lib/utils'
import {DateTimePeriodService} from "../../services/date-time-period.service";
import { HttpClient } from '@angular/common/http';
import {OreBody} from "../../_models/ore-body";
import {EventType} from '../../_models/event-type';
import {EventTypeService} from '../../services/event_type.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {ApiService} from "../../services/api/api.service";
import {SearchQueryOptions} from "../../services/api/search-query-options";
import {IDateTimePeriod} from "../../_typing/date-time-period";
import {RarChartService} from "../../charts/rar-chart/rar-chart.service";
import {DateTimeInstanceService} from "../../services/date-time-instance.service";

export type OreBodySummaryData = {
    [et_name: string]: {
        col_name: {
            prop_name: number | null
        }
    }
};

@Component({
    selector: 'ore-body-summary-table',
    templateUrl: './ore-body-summary-table.component.html',
    styleUrls: ['./ore-body-summary-table.component.less'],
    standalone: false
})
export class OreBodySummaryTableComponent implements OnInit, OnDestroy {
    tables: string[] = ['source', 'destination'];
    dtp: IDateTimePeriod;
    displayed: any = {source: true, destination: true, input: true, output: true};
    summary_data: {
        input: OreBodySummaryData,
        output: OreBodySummaryData,
    };
    summary_columns: [{
        key: string, description: string, data: [{ // input/output
            key: string, description: string, data: [{ // event type name
                key: string, description: string // column name (Value, MTD, etc.)
            }]
        }]
    }];
    cps: string[] = [];
    col_keys: string[] = [];

    event_types: EventType[];
    selected_event_type: EventType;
    selected_estimate_event_type: EventType;
    private $event_type_subject: Subscription;
    private readonly onDestroy = new Subject<void>();

    constructor(private dateTimePeriodService: DateTimePeriodService,
                private dateInst: DateTimeInstanceService,
                private http: HttpClient,
                private api: ApiService,
                private eventTypeService: EventTypeService,
                private notification: NotificationService) {

        this.eventTypeService.getEventTypes().pipe(take(1)).subscribe(result => {
            this.event_types = result['data'];
            this.filterEventTypes();
        });

        this.dateInst.dateTimePeriodRefreshed$
            .pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.dtp = dtp;
                this.refreshData();
            });
    }

    _ore_body: OreBody;

    get ore_body(): OreBody {
        return this._ore_body;
    }

    @Input()
    set ore_body(ore_body: OreBody) {
        if (ore_body && this._ore_body) {
            this._ore_body = ore_body;
            this.reset();
            this.filterEventTypes();
        }
        this._ore_body = ore_body;
    }

    keyLength = (obj) => Object.keys(obj).length;

    next = (obj) => obj[Object.keys(obj)[0]];

    ngOnInit(): void {
        this.dateTimePeriodService.dtpInitialisedPromise.promise.then(() => {
            this.dtp = this.dateInst.dtp;
        })
    }

    filterEventTypes() {
        const ctrl = this;

        if (this.$event_type_subject) {
            this.$event_type_subject.unsubscribe();
            this.$event_type_subject = null;
        }

        const options = new SearchQueryOptions();
        options.filters = [{
            name: 'ore_body_types',
            op: 'any',
            val: {name: 'id', op: 'eq', val: ctrl.ore_body.relationships.type.data.id}
        }];
        this.$event_type_subject = ctrl.api.event_type.searchMany(options).subscribe(response => {
            if (response.data && response.data[0]) {
                const event_type = this.event_types.find(et => et.id === response.data[0].id);
                if (event_type) {
                    ctrl.selected_event_type = event_type;
                }
            }
        })
    }

    getOreBodySummary() {
        const ctrl = this;
        if (!this.selected_event_type) {
            this.notification.openError('Please select an Event Type');
            return;
        } else if (!this.selected_estimate_event_type) {
            this.notification.openError('Please select an Estimate Event Type');
            return;
        }
        this.refreshData();
    }

    refreshData() {
        const ctrl = this;
        if (!this.selected_event_type || !this.selected_estimate_event_type) return;

        let params = {
            ore_body_id: this.ore_body.id,
            event_type_id: this.selected_event_type.id,
            estimate_event_type_id: this.selected_estimate_event_type.id,
            start: ctrl.dtp.start.toISOString(),
            end: ctrl.dtp.end.toISOString(),
            sample_period: ctrl.dtp.sample_period.hours
        };

        this.reset();
        ctrl.http.get("api/OreBody/Summary" + '?' + utils.httpParamSerializer(params)).toPromise().then(data => {
            this.summary_data = data['data'];
            this.summary_columns = data['columns'];
            this.preFormat();
            this.cps = data['rows'];
        }, error => {
            console.log('Error - : ', error);
        });
    }

    reset() {
        this.summary_data = null;
        this.summary_columns = null;
        this.cps = null;
        this.col_keys = [];
    }

    preFormat() {
        // This function just gets the total number of columns within input/output - used for colspan
        const event_keys = Object.keys(this.next(this.summary_data)).map(key => key);
        const columns = {};
        event_keys.forEach(key => {
            columns[key] = Object.keys(this.next(this.summary_data)[key]).map(key => key);
            this.col_keys = this.col_keys.concat(columns[key]);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
