<div style="background:white;">
  <div class="comment-header">
    <label>Comments</label>
    <button (click)="headerData.toggleCommentPanel(false, null)" class="close-panel" id="close-comments-button">
      <mat-icon class="search-clear"> clear      </mat-icon>
    </button>
  </div>

  <div *ngIf="tileData" [ngClass]="{'tile-dtp': button_class === true}" class="comment-drawer">
    <div [ngClass]="{'no-dtp': tileData.show_comment_dtp===false}">
      <div class="comment-align">
        <h3 class="h3-inline">New Comment: </h3>
        <div>
          <label *ngIf="tileData.tileTitle">{{tileData.title}}</label>
          <label *ngIf="!tileData.tileTitle">{{tile.content}}</label>
          <textarea (keyup)="textAreaAdjust($event)" [(ngModel)]="newComment" appMention
                    id="comment_area" placeholder="Type to add a comment. Type @ to mention someone."
                    rows="2"
                    style="overflow:hidden" tabindex="0">
      </textarea>
        </div>
        <button (click)="saveComment()" class="btn-save-comment"></button>
      </div>
      <div class="action-buttons">
        <button (click)="saveComment()" class="btn-save">Submit Comment</button>
      </div>

      <div class="comment-info">
        <ng-container *ngIf="tileData.comment">
          <ng-container *ngIf="tileData.comment.series">
            <label class="row"><b>Series:</b> {{tileData.comment.series.attributes | nameOrDescription}}</label>
          </ng-container>
          <ng-container *ngIf="tileData.comment.session_state">
            <label class="row"><b>Page:</b> {{tileData.comment.session_state.attributes | nameOrDescription}}</label>
          </ng-container>
          <label *ngIf="tileData.comment.start" class="row"><b>Time: </b>{{tileData.comment.start  | stringDate}}
            - {{tileData.comment.end  | stringDate}}</label>
        </ng-container>
      </div>

      <date-time-period *ngIf="tileData.show_comment_dtp !== false && show_dtp !== false"
                   [immutable]="true" [no_url]="true"
                   [showTimespan]="false"
                   class="date-period wrap comment-dtp"></date-time-period>
    </div>
    <div class="comment-list-container">
      <!--    <label *ngIf="tileData && tileData.events">Comments for {{tileData.tileTitle ? tileData.title : tile.content}}</label>-->
      <ul class="comment-list">
        <li (mouseenter)="commentHover(event, tileData.events.indexOf(event))"
            (mouseleave)="commentLeave(event, tileData.events.indexOf(event))"
            *ngFor="let event of tileData.events"
        >
          <div [ngClass]="{'shadow': hovering_events.includes(event?.id)}" class="comment-inner">
            <div class="comment-by">{{event.attributes.created_by_name}}</div>
            <div class="comment-on">{{event.attributes.changed_on | stringDate}}</div>
            <div class="comment-text">{{event.attributes.comment}}</div>
            <div *ngIf="event.attributes.series_names && event.attributes.series_names.length> 0"
                 class="series-list comment-list-series">
              Applies to:
              <ul>
                <li *ngFor="let series of event.attributes.series_names" [matTooltip]="series">{{series}}</li>
              </ul>
            </div>
            <div class="comment-period">
              {{event.attributes.start | stringDate}}
              - {{event.attributes.end | stringDate}}
            </div>
          </div>

        </li>
      </ul>

      <ng-container
          *ngIf="series && series.relationships.event_constant_collation_series_export?.data && dateInst.dtp">
        <label class="comment-info" style="padding:10px 0 0 0;border-top: 2px ridge;">Series Collation Events</label>
        <constant-property-events *ngIf="tileDtp" [series]="series"
                                  [dtp]="tileDtp">

        </constant-property-events>
      </ng-container>
    </div>

  </div>
</div>

<at-who [ngStyle]="{'display': appScope.at_who===true? 'block': 'none'}" class="at-who-container"
        id="at_who">
</at-who>
