import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AppScope} from '../../../services/app_scope.service';

export interface ConfigType {
    selected_model: any;
    origin: any;
    original_models: any;
    font_size: number;
    menu_visible: boolean;
}

@Component({
    selector: 'series-summary-menu',
    templateUrl: './series-summary-menu.component.html',
    styleUrls: ['./series-summary-menu.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SeriesSummaryMenuComponent implements OnInit {
    @Output() paramsChanged = new EventEmitter();
    draggable = true;

    constructor(private appScope: AppScope) {
    }

    private _config: ConfigType;

    get config(): ConfigType {
        return this._config
    }

    @Input()
    set config(config: ConfigType) {
        this._config = config;
        if (config) {
        }
    }

    ngOnInit(): void {
    }

    setFontSize(event) {
        this.paramsChanged.emit({style: 'font-size', event: this.config.font_size});
    }

    setStyle(event, style) {
        this.paramsChanged.emit({style: style, event: event});
    }
}
