import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {ModelName} from '../../_typing/generic-types';
import {DateTimePeriodService} from '../../services/date-time-period.service';
import {GenericChartTileConfiguration} from '../chart-config/generic-chart-tile.configuration';
import {ChartDialogService} from './chart-dialog.service';
import {HeaderDataService} from '../../services/header_data.service';
import {Series} from '../../_models/series';
import {first} from 'rxjs/operators';
import {ApiService} from "../../services/api/api.service";
import {IDateTimePeriod} from "../../_typing/date-time-period";

export type ChartDialogConfig = {
    series_name?: ModelName,
    dtp?: IDateTimePeriod
} & GenericChartTileConfiguration;

export interface DialogData {
    form_result: string;
    chart_component?: string;
    config?: ChartDialogConfig;
}

@Component({
    selector: 'chart-dialog',
    templateUrl: 'chart-dialog.component.html',
    providers: [DateTimePeriodService, ChartDialogService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ChartDialog {
    series_names: any;
    series_list: string[];
    series: Series;
    chart_component: string = '';
    config: ChartDialogConfig;
    dtp: IDateTimePeriod;
    showing_comments: boolean = false;
    current_tab: number = 0;
    data_ready = false;

    constructor(public dialogRef: MatDialogRef<ChartDialog>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public headerData: HeaderDataService,
                public dateTimePeriodService: DateTimePeriodService,
                private api: ApiService) {

        this.config = this.data.config;
        if (this.data.chart_component && this.data.chart_component === 'custom-chart') {
            /**Used by flowcharts**/
            this.chart_component = this.data.chart_component;
            this.series_names = this.data.config.series_list.map(s => s.name);
            this.data_ready = true;
        } else if (this.data.config?.series_name) {
            this.series_names = [this.data.config.series_name];
        }

        this.dtp = this.config.dtp;

        if (this.series_names?.length === 1) {
            this.api.series.getByName(this.series_names[0]).pipe(
                first()
            ).subscribe(s => this.series = s.data);
        }
    }


    onCloseClick(): void {
        this.dialogRef.close();
    }
}
