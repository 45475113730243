import {sumBy} from "lodash-es";

export type Vector3D = [number, number, number];

export const Vector3D_ORIGIN: Vector3D = [0, 0, 0];

export class Vector3DOnPlane {
    /**
     * Perform a counterclockwise rotation of 90 degrees for the given vector.
     * Solution adapted from https://en.wikipedia.org/wiki/Rotation_matrix#Common_rotations
     */
    static rotate90(v: Vector3D): Vector3D {
        const x = v[0];
        const y = v[1];
        const new_v: Vector3D = [-y, x, v[2]];
        return new_v;
    }

    static bulkRotate90(vectors: Vector3D[]): Vector3D[] {
        return vectors.map(v => this.rotate90(v));
    }

    /**
     * Find the center point between all the given points.
     *
     * Solution adapted from: https://math.stackexchange.com/a/1801878
     */
    static findPolygonCentre(points: Vector3D[]): Vector3D {
        if (points.length < 0) {
            return [0, 0, 0];
        }
        const sum_x = sumBy(points, point => point[0]);
        const sum_y = sumBy(points, point => point[1]);
        const x_c = sum_x / points.length;
        const y_c = sum_y / points.length;
        return [x_c, y_c, 0];
    }

    /**
     * Rotate a polygon by 90 clockwise degrees around the given origin point.
     *
     * When rotating multiple separate polygons, this function should be called for each of them, since they will all have
     * their own unique new origin after rotation.
     */
    static rotateClockwisePolygonAroundPoint(points: Vector3D[], origin: Vector3D): [Vector3D[], Vector3D] {
        const rotated_points: Vector3D[] = this.bulkRotate90(points);
        // find the new origin.
        const new_origin = this.findPolygonCentre(rotated_points);
        // find the displacement from the previous to the new position.
        const displacement: Vector3D = [origin[0] - new_origin[0], origin[1] - new_origin[1], 0];
        // apply the displacement on the polygon to get it back in it's original position.
        const displaced_points: Vector3D[] = rotated_points.map(point => [point[0] + displacement[0], point[1] + displacement[1], point[2]]);
        return [displaced_points, displacement];
    }

    static rotateClockwisePolygonAroundSelf(points: Vector3D[]): [Vector3D[], Vector3D] {
        const centre_point = this.findPolygonCentre(points);
        return this.rotateClockwisePolygonAroundPoint(points, centre_point);
    }

    static bulkTranslatePolygon(points: Vector3D[], from: Vector3D, to: Vector3D): Vector3D[] {
        const displacement: Vector3D = [from[0] - to[0], from[1] - to[1], from[2] - to[2]];
        const displaced_points = points.map(point => this.displacePoint(point, displacement));
        return displaced_points;
    }

    static displacePoint(point: Vector3D, displacement: Vector3D): Vector3D {
        return [point[0] + displacement[0], point[1] + displacement[1], point[2] + displacement[2]];
    }

    static bulkDisplacePoints(points: Vector3D[], displacement: Vector3D): Vector3D[] {
        return points.map(point => this.displacePoint(point, displacement));
    }
}
