export type SINGLE_RELATIONSHIP_TYPE = { data: { id: string, type: string } | null };

export type LIST_RELATIONSHIP_TYPE = { data: { id: string, type: string }[] };

export type LIST_RELATIONSHIP_TYPE_INT = { data: { id: number, type: string }[] };

export class DEFAULT {
    public static get LIST() {
        return {data: []};
    }

    public static get SINGLE() {
        return {data: null}
    }
}

export class ModelRelationCleanup {
    // remove any value that is not a type or id key

    public static List(o) {
        if (!o) return;
        if (!o.data) return;
        o.data.forEach(item => this.cleanItem(item))
    }

    public static Single(o) {
        if (!o) return;
        if (!o.data) return;
        this.cleanItem(o.data);
    }

    static cleanItem(item) {
        if (!item) return;
        const keys = Object.keys(item);
        const remove = keys.filter(k => !['id', 'type'].includes(k));
        for (let key of remove) {
            delete item[key];
        }
    }

}
