<div class="custom-snackbar calc-error-message">
  <div>
    <div class="message">{{errorMessage}}</div>
    <div class="message font-normal detail">Error details: {{errorDetails}}</div>
    <div class="message font-normal">Click <a (click)="editSeries()">here</a> to edit the calculation.</div>
  </div>


  <div class="action-class">
    <button mat-raised-button (click)="snackBarRef.dismiss()" class="snack-bar-dismiss-button">
      <i class="fa fa-times snack-bar-icon" matTooltip="Close" [matTooltipShowDelay]="2000"></i>
    </button>
  </div>
</div>

