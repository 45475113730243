import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ConfigColumn} from "../../_typing/config/config-column";
import {ConstantProperty} from "../../_models/constant-property";
import {BaseComponent} from "../../shared/base.component";
import {Observable, of} from "rxjs";
import {ConstantPropertyDataService} from "../../data/constant-property-data.service";
import {take, tap} from "rxjs/operators";
import {SingleResponse} from "../../services/api/response-types";

@Component({
    selector: 'default-value-field',
    templateUrl: './default-value-field.component.html',
    styleUrls: ['./default-value-field.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DefaultValueFieldComponent extends BaseComponent {
    @Input() field: ConfigColumn;
    @Input() constantProperty: ConstantProperty;
    placeholder = 'Enter a default value'
    allowValueEntry: boolean = false;

    constructor(private propertyData: ConstantPropertyDataService) {
        super();
    }

    ngOnInit() {
        if (this.field.type !== 'constant_property') return; //Will possibly allow attributes in future
        this.getProperty();
    }

    getProperty() {
        let $constantProperty: Observable<SingleResponse<ConstantProperty> | null>;
        if (!this.constantProperty) {
            $constantProperty = this.propertyData.getPropertyLightById(this.field.id)
                .pipe(tap(result => {
                    this.constantProperty = result?.data
                }))
        } else {
            $constantProperty = of(null);
        }
        $constantProperty.pipe(take(1))
            .subscribe(() => this.setupPage());
    }

    setupPage() {
        if (!['datetime', 'file'].includes(this.constantProperty?.attributes.data_type)) {
            this.allowValueEntry = true;
        }
        if (this.constantProperty?.attributes.is_drop_down_list) {
            this.placeholder = 'Select a default value';
        }
        if (!this.allowValueEntry) {
            this.placeholder = 'Defaults not allowed'
        }
    }
}
