<table class="estimates_table simple-table">
  <thead>
  <tr>
    <th style="width:30px;"></th>
    <th *ngIf="type==='targets'">Type</th>
    <th>Name</th>
    <th>Current Value</th>
    <th>Positive Variance</th>
  </tr>
  </thead>
  <tbody style="text-align: center;">
  <ng-container *ngFor="let sd_series of series_data; let i = index;">
    <tr *ngIf="!isUnsaved(sd_series)">
      <td style="width:30px;text-align:center;padding:0 2px;">
        <button style="background:transparent;border:none;" *ngIf="type==='targets'"
                (click)="updateTargetSeries(sd_series)" [disabled]="isUnsaved(sd_series)">
          <i class="fa fa-edit"></i>
        </button>
        <button style="background:transparent;border:none;" *ngIf="type!=='targets'"
                (click)="updateSourceSeries(sd_series)" [disabled]="isUnsaved(sd_series)">
          <i class="fa fa-edit"></i>
        </button>
      </td>
      <td *ngIf="type==='targets'">{{sd_series.attributes.series_type_name}} </td>

      <td title="{{sd_series.attributes.name}}">
        <span>{{sd_series.attributes | nameAndDescription}}</span>
      </td>
      <td style="text-align: center;">{{sd_series.current_value}} </td>
      <td style="text-align: center;">
        <mat-checkbox [(ngModel)]="sd_series.positive_variance"
                      (change)="saveSeriesSeries(sd_series)"
                      [disabled]="isUnsaved(sd_series)"
                      matTooltip="Show positive variance (actual is less than target) as favourable"></mat-checkbox>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>
