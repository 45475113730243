<div class="component-constants-table-component form-paginator">
  <div class="row space-between utils-bar">
      <utils-bar [search]="true"
                 (searchValueSubmitted)="filter_string=$event;updateSearchFilter()"
                 (searchCleared)="filter_string='';updateSearchFilter();">
      </utils-bar>

    <mat-paginator [pageSizeOptions]="page_size_options"
                   style="justify-self: flex-end;">
    </mat-paginator>

  </div>

  <div style="position:relative;display:block;height:100%;overflow-y:auto;">
    <div *ngIf="!component_type">No component type selected</div>
    <table mat-table [dataSource]="dataSource" style="width:100%;" class="component-constants-table">
      <ng-container matColumnDef="component_name">
        <td *matCellDef="let component" mat-cell style="width:100%">
          <div class="component-constants" *ngIf="component_dict[component.id] && cp_dict">
            <label>{{component_dict[component.id].attributes.name}}</label>
            <component-constant-table
                *ngIf="component_component_constants_dict[component.id]?.length > 0"
                [component]="component"
                [component_constants]="component_component_constants_dict[component.id]"
                [constant_property_dict]="cp_dict"
                class="row auto constant-component-table">
            </component-constant-table>
            <div class="stage" *ngIf="!component_component_constants_dict[component.id]">
              <div class="dot-flashing"></div>
            </div>
          </div>

        </td>
      </ng-container>
      <tr *matRowDef="let row; columns: ['component_name'];" mat-row></tr>
    </table>

  </div>
</div>
