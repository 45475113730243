import {Injectable} from '@angular/core';
import {
    SeriesDataSeriesGroupDict,
    SeriesDataTableConfig,
    SeriesGroupConfig
} from "./series-data-table-form.component";
import {IConfigStub} from "../../_typing/config-stub";

@Injectable()
export class SeriesDataTableFormService {
    process_filters: { [key: string]: any[] } = {};

    constructor() {
    }

    setDefaults(config: SeriesDataTableConfig): SeriesDataTableConfig {
        if (!config.column_formats) {
            config.column_formats = {};
        }
        if(!config.orientation){
            config.orientation = 'vertical';
        }
        return config;
    }

    getSeriesLightFilters(processes) {
        processes.forEach(process => {
            if (!this.process_filters[process.id]) {
                this.process_filters[process.id] = [{
                    name: 'series_components',
                    op: 'any',
                    val: {name: 'component', op: 'has', val: {name: 'id', op: 'eq', val: process.id}}
                }]
            }
        })
        return this.process_filters;

    }

    getProcessSeriesMap(processes: IConfigStub[], process_series_map: SeriesDataSeriesGroupDict): SeriesDataSeriesGroupDict {
        processes.forEach(p => {
            if (!process_series_map[p.id]) {
                process_series_map[p.id] = [];
            }
        })
        const keys = Object.keys(process_series_map)?.filter(key => !processes.map(p => p.id).includes(key));
        keys.forEach(key => delete process_series_map[key]);
        return process_series_map;
    }
}
