import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AppScope} from "../app_scope.service";
import {AccountService} from '../account.service';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, take} from "rxjs/operators";
import {ApiService} from '../api/api.service';
import {NotificationService} from "../notification.service";
import { HttpStatusCode } from "@angular/common/http";

// @Injectable()
// export class PageViewGuard implements CanActivate {
//
//     constructor(private appScope: AppScope, private api: ApiService,
//                 private router: Router, private accountService: AccountService,
//                 private notification: NotificationService) {
//     }
//
//     canActivate(route, state): Observable<boolean> {
//         let $accountRelationship;
//         let account_id;
//         if (this.appScope.navigate_inside === true) {
//             return of(true);
//         }
//         if (route.params.sessionID) {
//             $accountRelationship = this.api.session_state.getById(route.params.sessionID);
//         }
//
//         return this.appScope.currentUserValue.pipe(take(1),
//             concatMap(() => this.api.session_state.getById(route.params.sessionID)),
//             map((result) => {
//                 // TODO check possibilities of results returned by this after group-user-page feature
//                     if (result['data'] && result['data'].relationships.account.data.id &&
//                         !(this.appScope.current_user.restricted_access &&
//                             !this.appScope.current_user.dashboards.includes(route.params.sessionID))
//                     ) {
//                         account_id = result['data'].relationships.account.data.id;
//                     } else {
//                         this.notification.openError('You dont have access to this page. Redirecting.');
//                         if (this.appScope.current_user.restricted_access) {
//                             this.router.navigate(['view', 'forbidden']);
//                         } else {
//                             this.router.navigate(['view', 'page_view', this.appScope.current_user.default_dashboard_id]);
//                         }
//                         return false;
//                     }
//                 }
//                 , error => {
//                     console.log('You dont have access to this page: ');
//                     this.notification.openError('You dont have access to this page. Redirecting.');
//                     this.router.navigate(['view', 'forbidden']);
//                     return false;
//                 }
//             ),
//             concatMap(() => {
//                 if (account_id !== this.appScope.active_account_id) {
//                     return this.accountService.setActiveAccount(account_id).pipe(map(() => true))
//                 } else {
//                     return of(true);
//                 }
//             }),
//             catchError((err) => {
//                 console.log('caughtError: ', err, err.status === 404);
//                 if (err.status === 404) {
//                     console.log("Page not found: ", route.params.sessionID);
//                     this.router.navigate(['view', 'notfound']);
//                 } else {
//                     console.log("Page forbidden: ", route.params.sessionID);
//                     this.router.navigate(['view', 'forbidden']);
//                 }
//                 return of(false);
//             })
//         );
//     }
// }


export const PageViewGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const appScope: AppScope = inject(AppScope);
    const router: Router = inject(Router);
    const api: ApiService = inject(ApiService);
    const accountService: AccountService = inject(AccountService);
    const notification: NotificationService = inject(NotificationService);

    let $accountRelationship;
    let account_id;
    if (appScope.navigate_inside === true) {
        return of(true);
    }
    if (route.params.sessionID) {
        $accountRelationship = api.session_state.getById(route.params.sessionID);
    }

    return appScope.currentUserValue.pipe(take(1),
        concatMap(() => api.session_state.getById(route.params.sessionID)),
        map((result) => {
            // TODO check possibilities of results returned by this after group-user-page feature
                if (result['data'] && result['data'].relationships.account.data.id &&
                    !(appScope.current_user.restricted_access &&
                        !appScope.current_user.dashboards.includes(route.params.sessionID))
                ) {
                    account_id = result['data'].relationships.account.data.id;
                } else {
                    notification.openError('You dont have access to this page. Redirecting.');
                    if (appScope.current_user.restricted_access) {
                        router.navigate(['view', 'forbidden']);
                    } else {
                        router.navigate(['view', 'page_view', appScope.current_user.default_dashboard_id]);
                    }
                    return false;
                }
            }),
        concatMap(() => {
            if (account_id !== appScope.active_account_id) {
                return accountService.setActiveAccount(account_id).pipe(map(() => true))
            } else {
                return of(true);
            }
        }),
        catchError((err) => {
            console.log('caughtError: ', err, err.status === HttpStatusCode.NotFound);
            if (err.status === HttpStatusCode.NotFound) {
                console.log("Page not found: ", route.params.sessionID);
                router.navigate(['view', 'notfound']);
            } else {
                notification.openError('You dont have access to this page. Redirecting.');
                console.log("Page forbidden: ", route.params.sessionID);
                router.navigate(['view', 'forbidden']);
            }
            return of(false);
        })
    );
};
