import {AfterViewInit, Component, Input} from '@angular/core';
import {TileDataService} from "../../services/tile_data.service";
import {takeUntil} from "rxjs/operators";
import {BaseComponent} from "../../shared/base.component";
import {HeaderDataService} from "../../services/header_data.service";
import {ModelID} from "../../_typing/generic-types";
import {FormDialogService} from "../../services/form-dialog.service";
import {ITileButton} from "../../_typing/tile-button";

@Component({
    selector: 'page-tile-menu',
    templateUrl: './page-tile-menu.component.html',
    styleUrls: ['./page-tile-menu.component.less'],
    standalone: false
})
export class PageTileMenuComponent extends BaseComponent implements AfterViewInit {
    @Input() tileContent: string;
    @Input() tileId: ModelID;
    @Input() sessionStateId: ModelID;
    editMode: boolean = false;
    buttons: ITileButton[];

    constructor(private tileData: TileDataService,
                private headerData: HeaderDataService,
                private formDialogService: FormDialogService) {
        super();
        this.headerData.page_edit_toggle.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.editMode = value;
        })
    }

    ngAfterViewInit(): void {
        this.tileData.buttonsChanged.pipe(takeUntil(this.onDestroy)).subscribe(new_buttons => {
            setTimeout(() => this.buttons = new_buttons);
        });
    }

    refreshTileClicked() {
        this.headerData.refreshTileSubject.next(this.tileId);
    }

    copyTileClicked() {
        this.formDialogService.openCopyTileForm(this.tileId, this.sessionStateId)
    }
}
