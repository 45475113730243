<div class="upload-model-container form-dialog ">
  <h1>Upload Model Definitions</h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Upload Models">
        <form class="form-section column">
          <div class="row">
            <mat-form-field>
              <mat-label>Model Type</mat-label>
              <mat-select *ngIf="model_selection_options.length>0"
                          [(ngModel)]="model_selected" (ngModelChange)="preview=null; changedSelectedModel()"
                          name="model_selected"
                          placeholder="Select a model type">
                <mat-option *ngFor="let model of model_selection_options" [value]="model">
                  {{model.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <drop-file-selector style="width:100%;" [file]="file"
                              (selectionChanged)="handleFileInput($event)">
          </drop-file-selector>
          <div class="requirements-container">
            <h4 class="info">Requirements</h4>
            <p class=" requirements">File type: CSV UTF-8 (Comma delimited) (*.csv)<br>
              Date format: YYYY/MM/DD<br>
              First row of the CSV to be the header row</p>
          </div>

          <div class="row inline" *ngIf="error_json" style="flex-direction: column">
            <h4>Errors</h4>
            <div style="width: 100%; overflow-x:auto;">
              <pre [innerHTML]="error_json"></pre>
            </div>
            <br>
          </div>

          <div  *ngIf="preview">
            <h4>Preview</h4>
            <div class="preview-container" >
              <table class="preview-table">
                <tr *ngFor="let r of preview">
                  <td *ngFor="let c of r" style="white-space: nowrap;">{{c}}</td>
                </tr>
              </table>
            </div>
            <br>
          </div>

          <!-- TODO reenable this if we want users to disable the override of existing models -->
          <!-- <div class="row">-->
          <!--   <mat-checkbox [(ngModel)]="override" name="override">Override</mat-checkbox>-->
          <!-- </div>-->

          <div *ngIf="model_selected && file" class="row" style="flex-direction: column">
            <hr>
            <upload-model-form-template
                style="width: 100%;"
                [model_type]="model_selected.parser_type"
            ></upload-model-form-template>
            <hr>
            <!-- Add model specific components here for extracting options -->
            <ng-container [ngSwitch]="model_selected.parser_type">
              <extract-ore-body-options
                  [(options)]="upload_options"
                  *ngSwitchCase="'orebody'"
                  [file]="file"
                  (preview)="preview=$event"
                  style="width: 100%"
              ></extract-ore-body-options>
              <!-- TODO enable emitting template options to the form -->
              <extract-event-options
                  *ngSwitchCase="'event'"
                  [file]="file"
                  (preview)="preview=$event"
                  style="width: 100%"
              ></extract-event-options>
              <extract-component-options
                  *ngSwitchCase="'component'"
                  [file]="file"
                  (preview)="preview=$event"
                  style="width: 100%"
              ></extract-component-options>
              <p *ngSwitchDefault>Invalid model upload type selected.</p>
            </ng-container>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <div class="action-buttons">
    <button (click)="closeDialog()" class="btn-close"> Close</button>
    <button
        (click)="uploadData()"
        [disabled]="!file || !model_selected || (model_selected.require_options && upload_options == null) || uploading"
        class="btn-save"
    >Upload
    </button>
  </div>
</div>
