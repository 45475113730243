<div *ngIf="pending && pending.attributes.context" class="pending-context column form-dialog">
    <div *ngIf="polling" class="polling-container" [matTooltip]="pollingStatus">
        <div class="polling-indicator"></div>
    </div>
    <ng-container *ngIf="config.type==='event_to_component' && constant_properties && component_type">
        <div class="row">
            <mat-form-field>
                <mat-label>{{
                        config.customisation?.component_type[ct_id]?.label ?
                            config.customisation.component_type[ct_id].label : (component_type.attributes.name)
                    }}
                </mat-label>
                <input matInput [(ngModel)]="pending.attributes.context.component_type.value"
                       (ngModelChange)="validate('component_type', ct_id, pending.attributes.context.component_type.value)"
                       [ngClass]="validation[ct_id]?.type ? validation[ct_id].type: ''"
                />
                <mat-hint style="display:block;" class="info" [ngClass]="validation[ct_id]?.type ? validation[ct_id].type: ''">
                    {{ validation[ct_id]?.message ? validation[ct_id].message : '' }}
                </mat-hint>
            </mat-form-field>
        </div>

        <ng-container *ngFor="let cp of pending.attributes.context.event_type.constant_properties">
            <div class="row">
                <mat-form-field>
                    <mat-label>
                        {{
                            config.customisation?.constant_properties[cp.id]?.label ?
                                config.customisation.constant_properties[cp.id]?.label : cp_dict[cp.id].attributes.name
                        }}
                    </mat-label>
                    <input matInput [(ngModel)]="cp.value"
                           (ngModelChange)="validate('constant_properties', cp.id, cp.value)"
                           [ngClass]="validation[cp.id] ? validation[cp.id].type: ''"/>
                    <mat-hint style="display:block;" class="info" [ngClass]="validation[cp.id]?.type ? validation[cp.id].type: ''">
                        {{ validation[cp.id]?.message ? validation[cp.id].message : '' }}
                    </mat-hint>
                </mat-form-field>
            </div>

        </ng-container>

    </ng-container>
    <ng-container *ngIf="config.type==='component_to_component' && component_type_from && component_type_to">
        <div class="row">
            <mat-form-field>
                <mat-label>{{
                        config.customisation?.component_type_from[ct_from_id]?.label ?
                            config.customisation.component_type_from[ct_from_id].label : component_type_from.attributes.name
                    }}
                </mat-label>
                <input matInput [(ngModel)]="pending.attributes.context.component_type_from.value"
                       (ngModelChange)="validate('component_type_from', ct_from_id, pending.attributes.context.component_type_from.value)"
                       [ngClass]="validation[ct_from_id]?.type ? validation[ct_from_id].type: ''"
                />
                <mat-hint style="display:block;" class="info" [ngClass]="validation[ct_from_id]?.type ? validation[ct_from_id].type: ''">
                    {{ validation[ct_from_id]?.message ? validation[ct_from_id].message : '' }}
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <mat-label>{{
                        config.customisation?.component_type_to[ct_to_id]?.label ?
                            config.customisation.component_type_to[ct_to_id].label : component_type_to.attributes.name
                    }}
                </mat-label>
                <input matInput [(ngModel)]="pending.attributes.context.component_type_to.value"
                       (ngModelChange)="validate('component_type_to', ct_to_id, pending.attributes.context.component_type_to.value)"
                       [ngClass]="validation[ct_to_id]?.type ? validation[ct_to_id].type: ''"
                />
                <mat-hint style="display:block;" class="info" [ngClass]="validation[ct_to_id]?.type ? validation[ct_to_id].type: ''">
                    {{ validation[ct_to_id]?.message ? validation[ct_to_id].message : '' }}
                </mat-hint>
            </mat-form-field>
        </div>
    </ng-container>

    <div class="pending-context-button-container">
        <button class="bordered-button big-btn save" [disabled]="polling" mat-button (click)="save()">Save</button>
        <button class="bordered-button big-btn clear" mat-button (click)="clearForm()" [disableRipple]="true">Clear</button>
    </div>
</div>
