  <mat-sidenav-container *ngIf="appScope.isAuthenticated$ | async" class="sidenav-container" [ngClass]="{'sidenav-open':appScope.sidenav_open}">
    <mat-sidenav #sidenav (opened)="appScope.sidenavOpenSubject.next(true)" [opened]="appScope.sidenavOpen$ | async" mode="{{sidenav_mode}}"
                 (closed)="appScope.sidenavOpenSubject.next(false)">
      <sidenavigation></sidenavigation>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{'visible-dtp': headerData.dtp_visible}">
      <header-menu (finishedLoading)="finishedLoading = $event"
                   [ngClass]="{'sidenav-side': sidenav_mode==='side'}"></header-menu>

      <notifications notifications style="display:none;"></notifications>

      <router-outlet *ngIf="finishedLoading && appScope.current_user"></router-outlet>
      <breadcrumbs></breadcrumbs>

    </mat-sidenav-content>

  </mat-sidenav-container>
