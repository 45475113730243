<div class="chart-dialog form-dialog">
  <h1 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor:move;">
    <ng-container *ngIf="series && series.attributes && !config?.labels?.title">
      {{series.attributes | nameAndDescription}}
    </ng-container>
    <ng-container *ngIf="config && config.labels && config.labels.title">
      {{config.labels.title}}
    </ng-container>
  </h1>

  <mat-dialog-content [ngClass]="{'comment-drawer-open': headerData.showing_comments}">
    <mat-tab-group [disableRipple]="true" (selectedTabChange)="current_tab =$event.index">
      <mat-tab label="Chart">
        <ng-template matTabContent>
          <custom-chart [series_names]="series_names" [dtp]="dtp" [chart_component]="chart_component"
                        class="chart-dialog-chart"></custom-chart>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="dtp && series?.id" label="Analysis Tools">
        <ng-template matTabContent>
          <analysis-tools [selected_series]="series"></analysis-tools>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="series?.id" label="Exceptions">
        <ng-template matTabContent>
          <alerts-table [series_list]="[series]"></alerts-table>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="series?.id" label="Audit History">
        <ng-template matTabContent>
          <series-audit-history [series]="series" [table_view]="true"></series-audit-history>
        </ng-template>
      </mat-tab>

    </mat-tab-group>

    <date-time-period *ngIf="current_tab!==0"
                 [immutable]="true"
                 [no_url]="true"
                 class="date-period inline align-bottom">
    </date-time-period>

  </mat-dialog-content>

  <div class="action-buttons">
    <button class="btn-close" (click)="onCloseClick()">Close</button>
  </div>

</div>
